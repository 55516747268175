import {onlyIfForm} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  ProcessDropshipFormShape,
  processDropshipFormSelector,
  doneProcessingDropshipments,
} from '../DropshipConfigForm/ProcessDropshipForm/processDropshipFormFunctions.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'

function ProcessDropshipModal({form}) {
  const errorCount = form.errors.length
  const completedCount = form.completedOrderNumbers.length
  const preventClose = !(!form.isProcessing && errorCount > 0)

  return (
    <ConfirmModal
      title="Processing Orders"
      onCancel={preventClose ? () => doneProcessingDropshipments() : null}
      cancelText="Close Window"
      isSaving={form.isProcessing}
      preventClose={preventClose}
      noFooter={preventClose}
    >
      {form.isProcessing ? (
        <div className="loading align-center margin-top-40 margin-bottom-50">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-10">
            {completedCount} of {form.orderNumbers.length}
          </strong>
        </div>
      ) : (
        <div>
          <PluralBlock count={completedCount}>
            <p className="fs-02 align-center margin-bottom-25">
              <span>
                <Count />
              </span>{' '}
              <Plural s="order was" p="orders were" /> processed
            </p>
          </PluralBlock>
          {errorCount > 0 && (
            <div className="panel panel--error margin-bottom-15">
              <PluralBlock count={errorCount}>
                <p className="margin-bottom-10">
                  <strong>
                    <Count /> <Plural word="order" /> had errors:
                  </strong>
                </p>
              </PluralBlock>
              <ul className="list square">
                {form.errors.map((error) => (
                  <li className="fs-n0" key={error.orderNumber}>
                    <strong>{error.orderNumber}</strong>
                    <br />
                    <div className="error fs-n0">{error.errorMessage}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </ConfirmModal>
  )
}

ProcessDropshipModal.propTypes = {
  form: ProcessDropshipFormShape.isRequired,
}

export default onlyIfForm(ProcessDropshipModal, (state) => {
  const form = processDropshipFormSelector(state)

  return form && form.showProcessingModal ? form : null
})
