import RefreshButton from '../../../../../common/components/List/RefreshButton.js'
import {refreshOrderListAndCounts} from '../../../../OrderListPage/orderListActions.js'

export default function RefreshOrders() {
  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint="Refresh the order list"
    >
      <RefreshButton onClick={() => refreshOrderListAndCounts()} />
    </li>
  )
}
