import {Component} from 'react'

import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from '../ProductCreateStrategy/ReconcileForm.js'
import EditForm from '../ProductCreateStrategy/EditForm.js'
import actions from '../../../../redux/actions/ui/dataImport/strategies/productUpdateStrategy.js'

export default class ProductUpdateStrategy extends Component {
  render() {
    return (
      <BaseStrategy
        {...this.props}
        actions={actions}
        EditForm={EditForm}
        ReconcileForm={ReconcileForm}
      />
    )
  }
}

ProductUpdateStrategy.title = actions.title
