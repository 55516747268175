import moment from 'moment'

export default function parseTime(time, format = 'hh:mm A') {
  const date = moment(time, format)

  return {
    hours: date.hour(),
    minutes: date.minute(),
    seconds: date.second(),
  }
}
