import {createSelector} from 'reselect'

import {RETURN_ORDER_SINGLE_URI_COMPONENT} from '../../common/constants/ReturnOrders.js'
import {formsSelector} from '../../store.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  returnOrdersSelector,
  canUseReturnOrdersSelector,
} from '../../redux/selectors/data/returnOrders.js'

export const RETURN_ORDER_DETAIL_FORM = 'RETURN_ORDER_DETAIL_FORM'

export function returnOrderDetailFormSelector(state) {
  return formsSelector(state)[RETURN_ORDER_DETAIL_FORM]
}

export const returnOrderDetailReferenceIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === RETURN_ORDER_SINGLE_URI_COMPONENT &&
      pathComponents[1]
    ) {
      return pathComponents[1]
    }

    return null
  },
)

export function returnOrderDetailURLSelector(state, {referenceID}) {
  return `#/${RETURN_ORDER_SINGLE_URI_COMPONENT}/${encodeURIComponent(
    referenceID,
  )}`
}

export const returnOrderDetailHashSelector = createSelector(
  returnOrderDetailReferenceIDSelector,
  canUseReturnOrdersSelector,
  (referenceID, canUseReturnOrders) => {
    if (!canUseReturnOrders || !referenceID) {
      return null
    }

    const url = returnOrderDetailURLSelector(null, {referenceID})

    return url
  },
)

export const returnOrderDetailLabelIDSelector = createSelector(
  returnOrderDetailReferenceIDSelector,
  returnOrdersSelector,
  (referenceID, returnOrders) => {
    const returnOrder = returnOrders[referenceID]

    if (returnOrder && returnOrder.label_infos.length) {
      return returnOrder.label_infos[0]
    }

    return null
  },
)
