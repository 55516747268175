import PropTypes from 'prop-types'
import classNames from 'classnames'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import Select from '../../Common/Select.js'
import Checkbox from '../../Common/Checkbox.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
  shipperFormSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'
import ShipperOptions from '../../../../data/shipperOptions.js'
import {dispatch, getState, useSelector} from '../../../../store.js'
import api from '../../../../common/api.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import {setShipper} from '../../../../data/shippers.js'
import {
  goToShipperSettings,
  updateForm,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {isPresent} from '../../../../common/utils.js'
import {useFedExAuthSelector} from '../../../../data/company.js'
import {showFedExAuthModal} from '../FedExAuthModal.js'

export function errorsSelector(shipper) {
  const useFedExAuth = useFedExAuthSelector(getState())

  const errors = {}

  if (!isPresent(shipper.accountNumber)) {
    errors.accountNumber = 'Account number is required'
  }

  if (!useFedExAuth && !isPresent(shipper.meterNumber)) {
    errors.meterNumber = 'Meter number is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    accountNumber: vendorConfig.account_number || '',
    meterNumber: vendorConfig.meter_number || '',
    smartPostHub: vendorConfig.smartpost_hub || '',
    use_metric_units: vendorConfig.use_metric_units || false,
    has_priority_alert: vendorConfig.has_priority_alert || false,
    isSaving: false,
  }
}

export async function saveShipperFedEx(shipperID) {
  const {
    name,
    hidden_shipping_methods,
    accountNumber,
    meterNumber,
    smartPostHub,
    use_metric_units,
    has_priority_alert,
  } = shipperFormSelector(getState(), {
    shipperID,
  })
  const useFedExAuth = useFedExAuthSelector(getState())

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    smartpost_hub: smartPostHub,
    use_metric_units,
    has_priority_alert,
  }

  if (!useFedExAuth) {
    params.account_number = accountNumber
    params.meter_number = meterNumber
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await (shipperID !== NEW_ID
      ? api.put(`/shipper/${shipperID}/`, params)
      : api.post('/shipper/', {...params, vendor: FEDEX}))

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error while saving shipper.',
      },
      err,
    )

    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

export default function FedExForm({form, cancel, updateForm}) {
  const {
    id,
    accountNumber,
    meterNumber,
    smartPostHub,
    use_metric_units,
    has_priority_alert,
    isSaving,
  } = form

  const errors = useSelector((state) => errorsOfChangedSelector(state, {form}))
  const hasErrors = useSelector((state) => hasErrorsSelector(state, {form}))
  const useFedExAuth = useSelector(useFedExAuthSelector)

  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-7 columns">
        {(!useFedExAuth || id !== NEW_ID) && (
          <fieldset>
            <ul className="form-list margin-bottom-0">
              {id !== NEW_ID && <Nickname shipperID={id} />}
              {useFedExAuth ? (
                <>
                  <li className="form-list-item fs-00 margin-top-10">
                    <strong>Account Number:</strong> {accountNumber}
                  </li>
                  <li className="form-list-item fs-00 margin-top-5 padding-bottom-15 divider--bottom">
                    <ButtonPrimary
                      alt
                      size="sm"
                      onClick={() =>
                        showFedExAuthModal(id === NEW_ID ? undefined : id)
                      }
                    >
                      {id === NEW_ID ? 'Create' : 'Reauthorize'}
                    </ButtonPrimary>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={classNames(
                      'form-list-item bigger margin-bottom-20',
                      {error: errors.accountNumber},
                      {'margin-top-20 divider--top': id !== NEW_ID},
                    )}
                  >
                    <label htmlFor="id_fedex_acct_no">
                      Account Number<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="fedex_acct_no"
                      id="id_fedex_acct_no"
                      value={accountNumber}
                      onChange={(event) =>
                        updateForm(id, {accountNumber: event.target.value})
                      }
                    />
                    {errors.accountNumber && (
                      <small className="error error-message">
                        {errors.accountNumber}
                      </small>
                    )}
                  </li>
                  <li
                    className={classNames(
                      'form-list-item bigger margin-bottom-20',
                      {
                        error: errors.meterNumber,
                      },
                    )}
                  >
                    <label htmlFor="id_fedex_meter_no">
                      Meter Number<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      name="fedex_meter_no"
                      id="id_fedex_meter_no"
                      value={meterNumber}
                      onChange={(event) =>
                        updateForm(id, {meterNumber: event.target.value})
                      }
                    />
                    {errors.meterNumber && (
                      <small className="error error-message">
                        {errors.meterNumber}
                      </small>
                    )}
                  </li>
                </>
              )}
              <li className="form-list-item bigger margin-bottom-20">
                <Select
                  label="Default Hub"
                  value={smartPostHub}
                  onChange={(event) =>
                    updateForm(id, {smartPostHub: event.target.value})
                  }
                >
                  <option value="">No Default</option>
                  {ShipperOptions.fedex.hub.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.display}
                    </option>
                  ))}
                </Select>
              </li>
              <li className="form-list-item bigger margin-bottom-20">
                <Checkbox
                  label="Has FedEx Priority Alert® Enabled"
                  id="has_priority_alert"
                  checked={has_priority_alert}
                  onChange={() =>
                    updateForm(id, {has_priority_alert: !has_priority_alert})
                  }
                />
              </li>
              {id !== NEW_ID && <ShippingMethods shipperID={id} />}
              <li className="form-list-item bigger margin-top-20 margin-bottom-30 divider--top">
                <dl className="list margin-bottom-15">
                  <dt className="list__title fs-00">
                    Do you want to convert to metric units when creating labels?
                  </dt>
                  <dd className="list__item fs-n0">
                    In Ordoro, weight and dimensions are displayed (and stored)
                    in imperial units. We can, however, convert weights and
                    dimensions to metric units while requesting rates and labels
                    from FedEx. Selecting the following checkbox will make this
                    conversion happen behind-the-scenes (units will still be
                    displayed in imperial).
                  </dd>
                </dl>
                <Checkbox
                  label="Convert to metric units"
                  id="use_metric_units"
                  checked={use_metric_units}
                  onChange={() =>
                    updateForm(id, {use_metric_units: !use_metric_units})
                  }
                />
              </li>
              <li className="list__item list__item--no-style">
                <ButtonPrimary
                  isLoading={isSaving}
                  isDisabled={hasErrors}
                  onClick={() => saveShipperFedEx(id)}
                >
                  {id !== NEW_ID ? 'Save' : 'Create'}
                </ButtonPrimary>
                <ButtonSecondary onClick={() => cancel()}>
                  Cancel
                </ButtonSecondary>
              </li>
            </ul>
          </fieldset>
        )}
        {useFedExAuth && id == NEW_ID && (
          <li className="form-list-item fs-00 margin-top-5">
            <ButtonPrimary
              onClick={() => showFedExAuthModal(id === NEW_ID ? undefined : id)}
            >
              Add a FedEx Account
            </ButtonPrimary>
          </li>
        )}
      </div>

      <div className="medium-5 columns">
        <div className="alert margin-bottom-10 alert--standard">
          {id === NEW_ID ? (
            <dl className="list">
              <dt className="list__title fs-00 lh-md margin-bottom-5">
                Need help authorizing your FedEx account?
              </dt>
              <dd className="list__item fs-n0 margin-bottom-0">
                <a
                  className="btn--link mid meta-ups-setup-help"
                  href="https://support.ordoro.com/fedex-setup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get step-by-step instructions →
                </a>
              </dd>
            </dl>
          ) : (
            <Archive shipperID={id} />
          )}
        </div>
        <p className="fs-n1 lh-md op-75">
          FedEx service marks are owned by Federal Express Corporation and are
          used by permission.
        </p>
      </div>
    </div>
  )
}

FedExForm.propTypes = {
  form: ShipperFormShape,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
