import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import analytics from '../../common/analytics/index.js'
import loadScript from '../../common/loadScript.js'
import {isInTrialSelector} from '../../data/company.js'
import {usePostMessage} from '../../common/components/PostMessage.js'

const MODAL_FORM = 'FEATURE_LOCKED_MODAL'

const CALENDAR_LINKS = {
  dustin: 'https://meetings.hubspot.com/dustin236?embed=true',
  danny:
    'https://meetings.hubspot.com/daniel-greenup/inventory-management-module-consultation?embed=true',
  matt: 'https://meetings.hubspot.com/matt-omohundro/matt-round-robin-test?embed=true',
  brian:
    'https://meetings.hubspot.com/brian-everett/inventory-management-module-consult?embed=true',
}

export async function showFeatureLockedModal(featureName) {
  setForm(MODAL_FORM, {meetingBookSucceeded: null})

  if (featureName) {
    await analytics.trackEvent(`${featureName}_feature_lock`)
  }
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function onIFrameMessage(message) {
  if (!window.MeetingsEmbedCode) {
    return
  }

  if (!window.MeetingsEmbedCode.isHubSpotOrigin(message.origin)) {
    return
  }

  if (!message.data) {
    return
  }

  updateModalForm({meetingBookSucceeded: message.data.meetingBookSucceeded})
}

function FeatureLockedModal({form}) {
  const ref = useRef()
  const isInTrial = useSelector(isInTrialSelector)

  useEffect(() => {
    loadScript(
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
    )
  }, [])

  usePostMessage(onIFrameMessage)

  return (
    <ConfirmModal
      title="Get the Inventory Management Module"
      modalSize="lg"
      onCancel={() => closeModal()}
      cancelText={form.meetingBookSucceeded ? 'All Done' : 'Close'}
    >
      <div className="row">
        <div className="medium-10 columns medium-centered">
          <p className="fs-01 margin-bottom-5 align-center">
            <strong>Set Yourself Up for Success</strong>
          </p>
          <p className="fs-01 margin-bottom-20 align-center">
            The Inventory Management Module is a very powerful Ordoro add-on.
            Schedule time with one of our Product Experts to make sure it’s the
            right fit for your business.
          </p>
          {form.meetingBookSucceeded === false && (
            <p className="fs-01 margin-bottom-20 alert alert--error">
              Something happened during scheduling the appointment. Try again
              later.
            </p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="medium-12 columns">
          <div
            ref={ref}
            className="meetings-iframe-container meetings-iframe-container-650-height"
            data-src={isInTrial ? CALENDAR_LINKS.brian : CALENDAR_LINKS.danny}
          ></div>
        </div>
      </div>
    </ConfirmModal>
  )
}

FeatureLockedModal.propTypes = {
  form: PropTypes.shape({
    meetingBookSucceeded: PropTypes.bool,
  }),
}

export default onlyIfForm(FeatureLockedModal, modalFormSelector)
