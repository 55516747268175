import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {isPresent} from '../../common/utils.js'
import useStable from '../../common/useStable.js'

function setupBars(data, setBars) {
  if (!data) {
    setBars([])

    return
  }

  const total = data.buckets.reduce((prev, {value}) => prev + value, 0)

  if (total === 0) {
    setBars([])

    return
  }

  setBars(
    data.buckets.map((bucket) => ({
      ...bucket,
      width: `${Math.round((bucket.value / total) * 100)}%`,
    })),
  )
}

export default function HorizontalBarGraph({data, isCurrency, max}) {
  data = useStable(data)
  const [bars, setBars] = useState([])

  useEffect(() => {
    setupBars(data, setBars)
  }, [data])

  return (
    <>
      <p className="graph-loading" />
      {bars.slice(0, max || undefined).map((bar) => (
        <dl
          className="list--horiz-bar-graph hide-for-loading"
          key={`${bar.key} ${bar.name}`}
        >
          <dt className="list__title list__title--horiz-bar-graph">
            <strong>{bar.name}</strong>
            <span className="text--value-bar-chart">
              {' – '}
              {isCurrency ? (
                <Currency value={bar.value} />
              ) : (
                <Quantity value={bar.value} />
              )}
              {isPresent(bar.average) && (
                <span className="unbold">
                  {' ('}
                  {isCurrency ? (
                    <Currency value={bar.average} />
                  ) : (
                    <Quantity value={bar.average} />
                  )}
                  {' average)'}
                </span>
              )}
            </span>
          </dt>
          <dd className="list__item list__item--horiz-bar-graph wrap--bar-graph">
            <div className="fill--bar-graph" style={{width: bar.width}} />
          </dd>{' '}
          <dd className="list__item list__item--horiz-bar-graph percentage">
            {bar.width}
          </dd>
        </dl>
      ))}

      {bars.length === 0 && (
        <dl className="list--horiz-bar-graph list--horiz-bar-graph-none align-center hide-for-loading">
          <dt className="list__title lh-md margin-bottom-5 make-medium-dark-grey">
            <strong>No data to display for this graph</strong>
          </dt>
          <dd className="list__item list__item--sm make-medium-grey">
            Adjust the filters or date range and try again
          </dd>
        </dl>
      )}
    </>
  )
}

HorizontalBarGraph.propTypes = {
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
  }),
  isCurrency: PropTypes.bool,
  max: PropTypes.number,
}
