import PropTypes from 'prop-types'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default function EditForm(props) {
  const data = props.data
  const updatePayload = (payloadDelta) =>
    props.updatePayload(data.index, payloadDelta)

  return (
    <BaseEditForm className="medium-5 columns" {...props}>
      <Row>
        <TextInput
          value={data.payload.sku}
          errorMessage={data.errors.sku}
          name="sku"
          label="SKU"
          className="medium-12"
          onChange={(value) => updatePayload({sku: value})}
        />
      </Row>
      {!data.payload.remove_child_sku && (
        <>
          <Row>
            <TextInput
              value={data.payload.add_child_sku}
              errorMessage={data.errors.add_child_sku}
              name="add_child_sku"
              label="Add Child SKU"
              className="medium-12"
              onChange={(value) => updatePayload({add_child_sku: value})}
            />
          </Row>
          <Row>
            <TextInput
              value={data.payload.quantity}
              errorMessage={data.errors.quantity}
              name="quantity"
              label="Quantity"
              className="medium-8"
              onChange={(value) => updatePayload({quantity: value})}
            />
          </Row>
        </>
      )}
      {!data.payload.add_child_sku && (
        <Row>
          <TextInput
            value={data.payload.remove_child_sku}
            errorMessage={data.errors.remove_child_sku}
            name="remove_child_sku"
            label="Remove Child SKU"
            className="medium-12"
            onChange={(value) => updatePayload({remove_child_sku: value})}
          />
        </Row>
      )}
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
