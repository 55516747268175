import PropTypes from 'prop-types'

import {
  useDropshippingSelector,
  useInventoryAllocationSelector,
  usePresetsSelector,
} from '../../../../data/company.js'
import {
  ALLOCATE_ORDER,
  APPLY_TAGS,
  APPLY_PRESETS,
  ASSIGN_TO_WAREHOUSE,
  MARK_AS_SHIPPED,
  DROPSHIP_TO,
} from '../../../../common/constants/Rules.js'
import {useSelector} from '../../../../store.js'
import {ruleActionTypeSelector, setRuleActionType} from '../rulesFunctions.js'

export default function ActionSelect({ruleID, index}) {
  const useInventoryAllocation = useSelector(useInventoryAllocationSelector)
  const selected = useSelector((state) =>
    ruleActionTypeSelector(state, {ruleID, index}),
  )
  const useDropshipping = useSelector(useDropshippingSelector)
  const usePresets = useSelector(usePresetsSelector)

  return (
    <select
      className="select inline-block margin-right-10 margin-bottom-0"
      name="action-type"
      value={selected}
      onChange={(event) => setRuleActionType(ruleID, index, event.target.value)}
    >
      {useInventoryAllocation && (
        <option value={ALLOCATE_ORDER}>Allocate order</option>
      )}
      <option value={APPLY_TAGS}>Apply tag</option>
      {usePresets && <option value={APPLY_PRESETS}>Apply preset</option>}
      <option value={ASSIGN_TO_WAREHOUSE}>Assign to warehouse</option>
      {useDropshipping && <option value={DROPSHIP_TO}>Dropship to</option>}
      <option value={MARK_AS_SHIPPED}>Mark as shipped</option>
    </select>
  )
}

ActionSelect.propTypes = {
  ruleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
}
