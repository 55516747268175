import PropTypes from 'prop-types'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import OrderNumberOrCount from '../../../common/components/OrderNumberOrCount.js'
import {
  applyShipFrom,
  labelInfoIDForOrderAndLabelTypeSelector,
} from '../../../data/labelInfos/index.js'
import {
  updateOrderWarehouse,
  orderSelector,
  orderWarehouseIDSelector,
} from '../../../data/orders.js'
import Select from '../../../common/components/Select.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../../data/warehouses.js'

export const MODAL_FORM = 'EDIT_ORDER_WAREHOUSE_MODAL'

export async function showEditOrderWarehouseModal(orderNumbers) {
  const firstWarehouseID = orderWarehouseIDSelector(getState(), {
    orderNumber: orderNumbers[0],
  })

  setForm(MODAL_FORM, {
    orderNumbers,
    warehouseID: firstWarehouseID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export async function saveOrderWarehouse(orderNumber, warehouseID) {
  const order = orderSelector(getState(), {orderNumber})

  if (order.alternate_ship_from) {
    await updateOrderWarehouse(orderNumber, warehouseID)
  } else {
    const labelInfoID = labelInfoIDForOrderAndLabelTypeSelector(getState(), {
      orderNumber,
      labelType: 'shipping',
    })

    await applyShipFrom(labelInfoID, warehouseID)
  }
}

export async function saveOrderWarehouses() {
  const {orderNumbers, warehouseID} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    await Promise.all(
      orderNumbers.map((orderNumber) =>
        saveOrderWarehouse(orderNumber, warehouseID),
      ),
    )

    showMessageToast('Warehouse have been updated')

    closeModal()
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
      isSaving: false,
    })
  }
}

function EditOrderWarehouseModal({form}) {
  const warehouses = useSelector(warehousesSortedByNameSelector)

  return (
    <ConfirmModal
      title="Edit Warehouse"
      confirmText="Save"
      cancelText="Cancel"
      modalSize="x-sm"
      isSaving={form.isSaving}
      onConfirm={() => saveOrderWarehouses()}
      onCancel={() => closeModal()}
      error={form.serverError}
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style fs-n0 text--md-grey">
          <strong>
            Editing <OrderNumberOrCount orderNumbers={form.orderNumbers} />
          </strong>
        </li>
        <li className="list__item--form list__item--no-style flex">
          <Select
            value={form.warehouseID}
            onChange={(warehouseID) =>
              updateModalForm({warehouseID: Number(warehouseID)})
            }
          >
            {warehouses.map((warehouse) => (
              <option key={warehouse.id} value={warehouse.id}>
                {getWarehouseName(warehouse)}
              </option>
            ))}
          </Select>
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditOrderWarehouseModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    warehouseID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditOrderWarehouseModal, modalFormSelector)
