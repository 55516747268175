function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Copy Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export default async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    console.error('Copy Async: Could not copy text: ', err)
  }
}
