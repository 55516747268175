import {openModal} from '../../ui/index.js'

export const BASE = 'ordoro/ui/modal/exportInsuranceHistoryModal'
export const SET_START_DATE = `${BASE}/SET_START_DATE`
export const SET_END_DATE = `${BASE}/SET_END_DATE`
export const SET_IS_REQUESTING = `${BASE}/SET_IS_REQUESTING`
export const SET_SERVER_ERROR = `${BASE}/SET_SERVER_ERROR`
export const REQUEST_EXPORT = `${BASE}/REQUEST_EXPORT`

export const MODAL_NAME = 'EXPORT_INSURANCE_HISTORY_MODAL'

export function setStartDate(startDate) {
  return {
    type: SET_START_DATE,
    payload: startDate,
  }
}

export function setEndDate(endDate) {
  return {
    type: SET_END_DATE,
    payload: endDate,
  }
}

export function setIsRequesting(payload) {
  return {
    type: SET_IS_REQUESTING,
    payload,
  }
}

export function setServerError(payload) {
  return {
    type: SET_SERVER_ERROR,
    payload,
  }
}

export function showExportInsuranceHistoryModal() {
  return openModal(MODAL_NAME)
}

export function requestExport() {
  return {
    type: REQUEST_EXPORT,
  }
}
