import loadScript from '../common/loadScript.js'
import {STRIPE_PUBLIC_KEY} from '../common/constants/index.js'

const Stripe = {
  init() {
    return loadScript('https://js.stripe.com/v2/').then(() => {
      window.Stripe.setPublishableKey(STRIPE_PUBLIC_KEY)

      Stripe.card = window.Stripe.card
    })
  },

  card: null,
}

export default Stripe

export function createToken(params) {
  return new Promise((resolve, reject) => {
    Stripe.card.createToken(params, (status, response) => {
      if (response.error) {
        reject(response.error)
      } else {
        resolve(response.id)
      }
    })
  })
}
