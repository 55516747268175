import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {OrderShape} from '../../../../../common/PropTypes.js'
import {removeTagFromOrders} from '../../../../../data/orderTags.js'
import {hasOrderManageTagOnOrderPermissionSelector} from '../../../../../data/me.js'
import AssignedTags from '../../../../../common/components/List/AssignedTags/index.js'

export function OrderTags({order, hasOrderManageTagOnOrderPermission}) {
  return (
    <AssignedTags
      tags={order.tags}
      hasManageTagOnOrderPermission={hasOrderManageTagOnOrderPermission}
      onRemove={(tag) => removeTagFromOrders(tag.id, [order.order_number])}
    />
  )
}

OrderTags.propTypes = {
  order: OrderShape.isRequired,
  hasOrderManageTagOnOrderPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    hasOrderManageTagOnOrderPermission:
      hasOrderManageTagOnOrderPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(OrderTags)
