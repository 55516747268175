import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_TASK, UPDATE_TASKS} from '../../actions/data/tasks.js'

export default function reducer(state = INITIAL_STATE.data.tasks, action = {}) {
  if (action.type === SET_TASK) {
    const task = action.payload
    return {
      ...state,
      [task.id]: task,
    }
  }

  if (action.type === UPDATE_TASKS) {
    return {
      ...state,
      ...action.payload.reduce(
        (prev, task) => ({
          ...prev,
          [task.id]: task,
        }),
        {},
      ),
    }
  }
  return state
}
