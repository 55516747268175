import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  getState,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../../common/constants/SettingsPanels.js'
import {EBAY} from '../../../../common/constants/CartVendorOptions.js'
import FormattedDate from '../../../../common/components/FormattedDate.js'
import {redirect} from '../../../../common/location.js'
import {tokenExpirationSelector} from '../../../../data/carts.js'
import FormList from '../../Common/FormList.js'
import VendorForm from './VendorForm.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  return baseCartErrorsSelector(state, {formName})
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return baseNewCartParamsSelector(state, {vendor: EBAY})
  }

  return baseEditCartParamsSelector(getState(), {cartID})
}

export function saveParamsSelector(state, {formName}) {
  return baseSaveParamsSelector(state, {formName})
}

export function authorize(
  formName,
  redirectURL = `/app${SALES_CHANNEL_SETTINGS_LINK}`,
) {
  const form = formSelector(getState(), {formName})
  const params = {
    redirect: redirectURL,
  }

  if (form.id !== NEW_ID) {
    params.cartID = form.id
  }

  return redirect('', ['verde', 'ebay', 'start'], params)
}

function Ebay({form, formName}) {
  const isNew = form.id === NEW_ID
  const {tokenExpiration, isExpired} = useSelector((state) =>
    tokenExpirationSelector(state, {cartID: form.id}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <div className="margin-top-15">
              <AuthorizeMessage formName={formName} />
            </div>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName}>
                <div
                  className={classNames(
                    'helper-text fs-00 lh-md margin-bottom-10',
                    {
                      'alert alert--error full-border error': isExpired,
                    },
                  )}
                >
                  <strong>
                    {'The authentication token for this cart '}
                    {isExpired ? 'expired' : 'will expire'}
                    {' on '}
                    <FormattedDate value={tokenExpiration} format="L" />
                  </strong>{' '}
                </div>
              </ReauthorizeButton>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Ebay"
          href="https://support.ordoro.com/ebay-setup-with-ordoro/"
        />
      </div>
    </VendorForm>
  )
}

Ebay.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(Ebay, setUpCartForm)
