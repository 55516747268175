import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  integrationsHaveLoadedSelector,
  paymentIntegrationsSelector,
} from '../../../data/integrations.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {
  editIDSelector,
  paymentIntegrationOptionsSelector,
} from '../../../redux/selectors/ui/settings/payments/index.js'
import {
  IntegrationShape,
  SelectOptionsShape,
} from '../../../common/PropTypes.js'
import SettingsPanel from '../SettingsPanel.js'
import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export function Payments({
  integrations,
  editID,
  hasLoaded,
  hasPermission,
  paymentIntegrationOptions,
}) {
  return (
    <SettingsPanel
      header="Payment Settings"
      hasPermission={hasPermission}
      subHeader={
        <div>
          Process payments in Ordoro by connecting a Stripe account here.
        </div>
      }
    >
      {hasLoaded && (
        <div className="row">
          <div className="medium-12 columns">
            <ul className="list--settings-wrap medium-12 columns">
              {integrations.map((integration) => (
                <EditForm
                  key={integration.id}
                  integration={integration}
                  editID={editID}
                />
              ))}
              {paymentIntegrationOptions.length && (
                <NewForm
                  paymentIntegrationOptions={paymentIntegrationOptions}
                  editID={editID}
                />
              )}
            </ul>
          </div>
        </div>
      )}
    </SettingsPanel>
  )
}

Payments.propTypes = {
  integrations: PropTypes.arrayOf(IntegrationShape).isRequired,
  editID: PropTypes.string,
  hasLoaded: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  paymentIntegrationOptions: SelectOptionsShape.isRequired,
}

function mapStateToProps(state) {
  return {
    integrations: paymentIntegrationsSelector(state),
    editID: editIDSelector(state),
    hasLoaded: integrationsHaveLoadedSelector(state),
    hasPermission: settingsPermissionsSelector(state).payments,
    paymentIntegrationOptions: paymentIntegrationOptionsSelector(state),
  }
}

export default connect(mapStateToProps)(Payments)
