import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import * as uploadActions from '../../redux/actions/ui/dataImport/upload.js'

export class FileUpload extends Component {
  constructor(props) {
    super(props)

    this.boundOnDrop = (event) => this.onDrop(event)
    this.boundOnDragOver = (event) => this.onDragOver(event)
    this.boundOnDragEnter = (event) => this.onDragEnter(event)
    this.boundOnDragLeave = (event) => this.onDragLeave(event)
    this.boundOnChange = (event) => this.onChange(event)
  }

  onDrop(event) {
    event.preventDefault()

    const files = event.dataTransfer.files

    this.first = this.second = false

    if (this.props.isDragHover) {
      this.props.setIsDragHover(false)
    }

    if (files.length) {
      this.props.onFiles(files)
    }

    return false
  }

  onDragOver(event) {
    event.preventDefault()

    var effect

    try {
      effect = event.dataTransfer.effectAllowed
    } catch (err) {
      // nothing
    }

    event.dataTransfer.dropEffect =
      effect === 'move' || effect === 'linkMove' ? 'move' : 'copy'

    if (!this.props.isDragHover) {
      this.props.setIsDragHover(true)
    }

    return false
  }

  onDragEnter(event) {
    event.preventDefault()

    // So a dragenter is fired for each child of the parent that this
    // dragenter is attached to, so we have to track when we are decending
    // into children and suppress events from firing for them. In the end,
    // we will only get dragenter once when entering the parent.
    if (this.first) {
      this.second = true

      return true
    }

    this.first = true

    return false
  }

  onDragLeave(event) {
    event.preventDefault()

    // See dragEnter() for a reason why we are doing this. Ultimately we
    // will only get one dragleave event when leaving the parent.
    if (this.second) {
      this.second = false
    } else if (this.first) {
      this.first = false
    }

    if (this.first || this.second) {
      return true
    }

    if (this.props.isDragHover) {
      this.props.setIsDragHover(false)
    }

    return false
  }

  onChange(event) {
    const files = event.target.files

    if (files.length) {
      this.props.onFiles(files)
    }
  }

  render() {
    const className = classNames('dropzone centered-text margin-bottom-10', {
      error: !!this.props.error,
      'drag-hover': this.props.isDragHover,
      uploading: this.props.isProcessingCSV,
    })

    const error = this.props.error ? (
      <small className="error error-message margin-top-10">
        {this.props.error}
      </small>
    ) : null

    return (
      <dl
        className={className}
        onDrop={this.boundOnDrop}
        onDragOver={this.boundOnDragOver}
        onDragEnter={this.boundOnDragEnter}
        onDragLeave={this.boundOnDragLeave}
      >
        <dt className="dropzone-header margin-bottom-0">
          <span className="icon icon-upload xl make-block" aria-hidden="true" />
          <span className="dz-text">Drag and drop a CSV file</span>
        </dt>
        <dd className="margin-bottom-0">
          <span className="dz-text">or</span>
          <label
            className="file-upload-wrapper inline-text-button hyperlink margin-bottom-0 dz-text"
            htmlFor="id_data_import_csv"
          >
            <span> select a file from your computer</span>
            <input
              type="file"
              name="files[]"
              id="id_import_files"
              onChange={this.boundOnChange}
            />
          </label>
          {error}
        </dd>
      </dl>
    )
  }
}

FileUpload.propTypes = {
  isDragHover: PropTypes.bool,
  setIsDragHover: PropTypes.func.isRequired,
  isProcessingCSV: PropTypes.bool,
  onFiles: PropTypes.func.isRequired,
  error: PropTypes.string,
}

function mapStateToProps(state) {
  const dataImport = state.ui.dataImport

  return {
    isDragHover: dataImport.upload.isDragHover,
    isProcessingCSV: dataImport.isProcessingCSV,
    error: dataImport.upload.error,
  }
}

const mapDispatchToProps = {
  ...uploadActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
