import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {selectSKU, isSelectedSelector} from '../productListFunctions.js'

export function SelectCell({sku, isSelected}) {
  return (
    <td className="table__td table__td--order-checkbox">
      <input
        type="checkbox"
        className="checkbox"
        checked={isSelected}
        onClick={(event) => selectSKU(sku, !isSelected, event.shiftKey)}
        onChange={() => {
          /* React wants us to have this, but we use onClick */
        }}
      />
    </td>
  )
}

SelectCell.propTypes = {
  sku: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {sku}) {
  return {
    sku,
    isSelected: isSelectedSelector(state, {sku}),
  }
}

export default connect(mapStateToProps)(SelectCell)
