import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ButtonRefresh({title, loading, onClick, className}) {
  return (
    <button
      type="button"
      title={title || 'Refresh'}
      className={classNames(
        'btn btn--primary btn--primary-alt btn--refresh',
        {'btn--processing': loading},
        className,
      )}
      disabled={loading}
      onClick={onClick}
    >
      <span className="i-refresh" aria-hidden="true" />
    </button>
  )
}

ButtonRefresh.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}
