import {useEffect, useState} from 'react'

export default function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    function onWindowResize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', onWindowResize)

    return () => window.removeEventListener('resize', onWindowResize)
  })

  return size
}

export function useElementSize({ref, margin, deps}) {
  margin = margin || {top: 0, right: 0, bottom: 0, left: 0}
  deps = deps || []
  const [size, setSize] = useState({width: 0, height: 0})
  useWindowSize() // causes a re-render on window resize

  useEffect(() => {
    if (!ref.current) {
      setSize({width: 0, height: 0})

      return
    }

    const cs = getComputedStyle(ref.current)

    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight)
    const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom)

    const borderX =
      parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth)
    const borderY =
      parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth)

    setSize({
      width:
        ref.current.offsetWidth -
        margin.left -
        margin.right -
        paddingX -
        borderX,
      height:
        ref.current.offsetHeight -
        margin.top -
        margin.bottom -
        paddingY -
        borderY,
    })
  }, [
    ref.current && ref.current.offsetWidth,
    ref.current && ref.current.offsetHeight,
    ...deps,
  ])

  return size
}
