import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {useSelector} from '../../store.js'
import {setExpandedPOIDs, setExpandMode} from './purchaseOrderListActions.js'
import {
  expandModeSelector,
  expandedPOIDsSelector,
} from './purchaseOrderListSelectors.js'

export default function POExpandAll() {
  const expandMode = useSelector(expandModeSelector)
  const expandedPOCount = useSelector(expandedPOIDsSelector).length

  const shouldCollapse =
    expandedPOCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={shouldCollapse ? 'Collapse all POs' : 'Expand all POs'}
    >
      <ExpandAllButton
        className="meta-polistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          setExpandedPOIDs([])
          setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          setExpandedPOIDs([])
          setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}
