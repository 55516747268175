import PropTypes from 'prop-types'

import {
  getState,
  onlyIfForm,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {addOrderComment} from '../../../data/orders.js'

const MODAL_FORM = 'ADD_ORDER_COMMENT_MODAL_FORM'

export function showAddOrderCommentModal(orderNumber) {
  setForm(MODAL_FORM, {
    orderNumber,
    comment: '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function close() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function saveOrderComment() {
  try {
    updateModalForm({isSaving: true})
    const {orderNumber, comment} = modalFormSelector(getState())

    await addOrderComment(orderNumber, comment)

    showMessageToast(`Comment was successfully added to order ${orderNumber}.`)
    close()
  } catch (err) {
    updateModalForm({
      serverError: `Error creating order comment: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function AddOrderCommentModal({form}) {
  return (
    <ConfirmModal
      title="Add a comment to this order"
      confirmText="Add a Comment"
      cancelText="Cancel"
      onConfirm={() => saveOrderComment()}
      onCancel={() => close()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>{form.orderNumber}</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Comment"
            rows="10"
            id="comment"
            value={form.comment}
            onChange={(value) => updateModalForm({comment: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

AddOrderCommentModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(AddOrderCommentModal, modalFormSelector)
