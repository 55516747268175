import PropTypes from 'prop-types'

import {useSelector} from '../../../store.js'
import api from '../../../common/api.js'
import {AMAZON_SFP} from '../../../common/constants/ShipperNames.js'
import {setShipper, hasAmazonSFPSelector} from '../../../data/shippers.js'
import {showGlobalError} from '../../GlobalErrorMessage.js'
import FormListItem from '../Common/FormListItem.js'
import Link from '../../../common/components/Link/Link.js'

export async function setupAmazonSFP() {
  try {
    const params = {
      vendor: AMAZON_SFP,
    }

    const {json} = await api.post('/shipper/', params)

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error setting up Amazon shipper.',
      },
      err,
    )
  }
}

export default function AmazonSFPSetup({className}) {
  const hasAmazonSFP = useSelector(hasAmazonSFPSelector)

  if (hasAmazonSFP) {
    return null
  }

  return (
    <FormListItem className={className}>
      <div className="fs-00 margin-bottom-5">
        <strong className="margin-right-3">Amazon Buy Shipping</strong>
        <Link
          className="btn v-align-middle"
          href="https://support.ordoro.com/how-do-i-use-amazon-seller-fulfilled-prime-to-ship-my-amazon-prime-orders/"
          newWindow
        >
          <span
            className="icon icon-support icon-block fs-n0"
            aria-hidden="true"
          />
        </Link>
      </div>
      <button
        className="btn btn--primary btn--primary-ol btn--sm setup-shipper"
        type="button"
        onClick={() => setupAmazonSFP()}
      >
        Enable Amazon Buy Shipping
      </button>
    </FormListItem>
  )
}

AmazonSFPSetup.propTypes = {
  className: PropTypes.string,
}
