import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'
import pick from 'lodash/pick.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import {TAG_FILTER_OR} from '../../../../common/constants/Tags.js'
import {clearTagFilterPanelSearch} from './TagFilterPanel.js'
import {userSettingsSelector} from '../../../../data/me.js'
import {clearFilterPanelSearch} from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  navigateProductList,
  updateProductFilters,
  updateCategory,
  updateSearchText,
  updateProductCartIDs,
  updateProductSupplierIDs,
  updateProductWarehouseIDs,
  updateFulfillmentChannelFilters,
  updateProductTagFilters,
  updateProductUntagged,
  updateProductTagFilterBy,
  updateProductExcludeTags,
  updateQuantity,
  updateQuantityComparator,
  cleanProductQuery,
  productListQueryToProductQuery,
} from '../../productListFunctions.js'
import deferPromise from '../../../../common/deferPromise.js'
import {ensureNumber, ensureTroolean} from '../../../../common/ensure.js'
import {
  DEFAULT_QUANTITY_COMPARATOR,
  API_PRODUCT_BOOLEAN_FILTERS,
  API_TO_URL_BOOLEAN_FILTERS,
  PRODUCT_BOOLEAN_FILTERS,
  PRODUCT_FULFILLMENT_CHANNELS,
} from '../../../../common/constants/Products.js'
import {clearStatusFilterPanelSearch} from './FiltersPanel.js'

const MODAL_FORM = 'PRODUCT_LIST_FILTER_MODAL'

export const FILTERS_PANEL = 'filters'
export const SALES_CHANNEL_FILTER_PANEL = 'sales_channel'
export const SUPPLIER_FILTER_PANEL = 'supplier'
export const WAREHOUSE_FILTER_PANEL = 'warehouse'
export const TAG_FILTER = 'tag'
export const QUANTITY_FILTER = 'quantity'
export const MISC_FILTER_PANEL = 'misc'
const DEFAULT_PRODUCT_LIST_FILTER_ORDER = [
  FILTERS_PANEL,
  SALES_CHANNEL_FILTER_PANEL,
  SUPPLIER_FILTER_PANEL,
  WAREHOUSE_FILTER_PANEL,
  TAG_FILTER,
  // QUANTITY_FILTER,
  MISC_FILTER_PANEL,
]

export async function showProductListFilterModal({query = {}} = {}) {
  const {updates} = await showProductListFilterModalPrime({
    query,
    singleRun: true,
  })

  return await navigateProductList(updates)
}

export async function showProductQueryFilterModal({
  query = {},
  singleRun = true,
} = {}) {
  const {originalForm, updates} = await showProductListFilterModalPrime({
    query,
    singleRun,
  })

  if (!updates) {
    return query
  }

  const productQuery = cleanProductQuery(
    productListQueryToProductQuery({...originalForm, ...updates}),
  )

  delete productQuery.sort
  delete productQuery.limit
  delete productQuery.offset

  return productQuery
}

export async function showProductListFilterModalPrime({
  query = {},
  singleRun = true,
  deferredPromise = deferPromise(),
  originalForm,
} = {}) {
  const form = {
    searchText: query.search || '',
    category: query.category || '',
    ...API_PRODUCT_BOOLEAN_FILTERS.reduce((prev, key) => {
      prev[API_TO_URL_BOOLEAN_FILTERS[key]] = ensureTroolean(query[key])

      return prev
    }, {}),
    ...PRODUCT_FULFILLMENT_CHANNELS.reduce((prev, key) => {
      prev[key] = ensureTroolean(query[key])

      return prev
    }, {}),
    cartID: query.sales_channel || [],
    supplierID: query.supplier || [],
    warehouseID: query.warehouse_id || [],
    tags: query.tag || [],
    untaggedSelected: query.untagged || false,
    tagFilterOperator: query.tag_filter_by || TAG_FILTER_OR,
    exclude_tags: query.exclude_tags || [],
    quantity: ensureNumber(query.quantity),
    quantity_comparator:
      query.quantity_comparator || DEFAULT_QUANTITY_COMPARATOR,
  }

  setForm(MODAL_FORM, {
    singleRun,
    deferredPromise,
    originalForm: originalForm || {...form},
    ...form,
  })

  clearFilterPanelSearch(SALES_CHANNEL_FILTER_PANEL)
  clearFilterPanelSearch(SUPPLIER_FILTER_PANEL)
  clearFilterPanelSearch(WAREHOUSE_FILTER_PANEL)
  clearTagFilterPanelSearch()
  clearStatusFilterPanelSearch()

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(updates) {
  const {originalForm, deferredPromise} = modalFormSelector(getState())

  deferredPromise.resolve({originalForm, updates})

  removeForm(MODAL_FORM)
}

export function clearFilters() {
  const {singleRun, deferredPromise, originalForm} =
    modalFormSelector(getState())

  // re-setup modal form with most of the pieces reused
  // reuse deferredPromise so that any awaits from the original call to show... will resolve
  // reuse originalForm so applyFilter() has the correct thing to compare against
  // reuse singleRun because that is part of the context
  showProductListFilterModalPrime({
    singleRun,
    deferredPromise,
    originalForm,
  })
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector() {
  const errors = {}

  return errors
}

export async function applyFilter() {
  const {
    originalForm,
    searchText,
    category,
    cartID,
    supplierID,
    warehouseID,
    tags,
    untaggedSelected,
    tagFilterOperator,
    exclude_tags,
    quantity,
    quantity_comparator,
    ...form
  } = modalFormSelector(getState())

  const filters = pick(form, PRODUCT_BOOLEAN_FILTERS)
  const fulfillmentChannelFilters = pick(form, PRODUCT_FULFILLMENT_CHANNELS)
  let updates = {}

  if (searchText !== originalForm.searchText) {
    updates = {...updates, ...updateSearchText(searchText)}
  }

  if (category !== originalForm.category) {
    updates = {...updates, ...updateCategory(category)}
  }

  if (!isEqual(filters, pick(originalForm, PRODUCT_BOOLEAN_FILTERS))) {
    updates = {...updates, ...updateProductFilters(filters)}
  }

  if (
    !isEqual(
      fulfillmentChannelFilters,
      pick(originalForm, PRODUCT_FULFILLMENT_CHANNELS),
    )
  ) {
    updates = {
      ...updates,
      ...updateFulfillmentChannelFilters(fulfillmentChannelFilters),
    }
  }

  if (!isEqual(cartID, originalForm.cartID)) {
    updates = {...updates, ...updateProductCartIDs(cartID)}
  }

  if (!isEqual(supplierID, originalForm.supplierID)) {
    updates = {...updates, ...updateProductSupplierIDs(supplierID)}
  }

  if (!isEqual(warehouseID, originalForm.warehouseID)) {
    updates = {...updates, ...updateProductWarehouseIDs(warehouseID)}
  }

  if (!isEqual(tags, originalForm.tags)) {
    updates = {...updates, ...updateProductTagFilters(tags)}
  }

  if (untaggedSelected !== originalForm.untaggedSelected) {
    updates = {...updates, ...updateProductUntagged(untaggedSelected)}
  }

  if (tagFilterOperator !== originalForm.tagFilterOperator) {
    updates = {...updates, ...updateProductTagFilterBy(tagFilterOperator)}
  }

  if (!isEqual(exclude_tags, originalForm.exclude_tags)) {
    updates = {...updates, ...updateProductExcludeTags(exclude_tags)}
  }

  if (quantity !== originalForm.quantity) {
    updates = {...updates, ...updateQuantity(quantity)}
  }

  if (quantity_comparator !== originalForm.quantity_comparator) {
    updates = {...updates, ...updateQuantityComparator(quantity_comparator)}
  }

  closeModal(updates)
}

export const productListFilterOrderSelector = createSelector(
  (state) => userSettingsSelector(state).product_list_filter_order,
  (list) => {
    if (!list) {
      return DEFAULT_PRODUCT_LIST_FILTER_ORDER
    }

    // remove any items that have been removed from the default list
    list = list.filter((item) =>
      DEFAULT_PRODUCT_LIST_FILTER_ORDER.includes(item),
    )

    // if the list aren't the same size then there are new items in the default list
    // that need to be added to the list
    if (list.length !== DEFAULT_PRODUCT_LIST_FILTER_ORDER.length) {
      const newItems = DEFAULT_PRODUCT_LIST_FILTER_ORDER.filter(
        (item) => !list.includes(item),
      )

      list.push(...newItems)
    }

    return list
  },
)
