import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  BOM_LINK_TYPE,
  PRODUCT_LINK_TYPE,
} from '../../../common/constants/Products.js'
import {PRODUCT_DETAIL_PAGE} from '../../../common/constants/Pages.js'
import api from '../../../common/api.js'
import {
  PluralBlock,
  Count,
  IfPlural,
  IfSingle,
  Plural,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  ensureProductsLoaded,
  productSelector,
  productComponentsSelector,
  linkedBOMSKUsSelector,
  isKitSelector,
  isBOMSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  updateProductDetailForm,
  navigateToProductDetails,
} from '../../ProductDetailPage/productDetailActions.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'

export const REMOVE_COMPONENT_MODAL = 'REMOVE_COMPONENT_MODAL'

export function showRemoveComponentModal(
  sku,
  componentSKUs,
  linkType = PRODUCT_LINK_TYPE,
) {
  setForm(REMOVE_COMPONENT_MODAL, {
    sku,
    componentSKUs,
    linkType,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(REMOVE_COMPONENT_MODAL, ...args)
}

export function closeModal() {
  removeForm(REMOVE_COMPONENT_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[REMOVE_COMPONENT_MODAL]
}

export async function removeComponent(sku, child_sku, linkType) {
  const url = `/product/${encodeURIComponent(sku)}/${
    linkType === BOM_LINK_TYPE ? 'manufacturing' : 'kit_component'
  }/`

  await api.delete(url, {
    child_sku,
  })
}

export async function removeComponents() {
  try {
    const {sku, componentSKUs, linkType} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    for (let componentSKU of componentSKUs) {
      await removeComponent(sku, componentSKU, linkType)
    }

    const bomSKU = linkedBOMSKUsSelector(getState(), {sku})[0]

    await ensureProductsLoaded(
      linkType === BOM_LINK_TYPE && bomSKU ? [sku, bomSKU.sku] : [sku],
      {refresh: true, type: 'all'},
    )

    const isKit = isKitSelector(getState(), {sku})
    const isBOM = isBOMSelector(getState(), {sku})

    if (
      !isKit &&
      !isBOM &&
      currentPageSelector(getState()) === PRODUCT_DETAIL_PAGE
    ) {
      navigateToProductDetails(sku)
    }

    closeModal()

    updateProductDetailForm({
      selectedKitComponentSKUs: [],
      selectedBOMComponentSKUs: [],
    })

    showMessageToast(
      plural(componentSKUs.length)`Removed ${
        linkType === BOM_LINK_TYPE ? 'BOM' : 'kit'
      } component${['s']}`,
    )
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function RemoveComponentModal({form}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: form.sku}),
  )
  const components = useSelector((state) =>
    productComponentsSelector(state, {sku: form.sku}),
  )
  const firstSKU = form.componentSKUs[0]
  const firstComponent = components.find(({sku}) => sku === firstSKU)
  const parentProductName = product.name
  const firstComponentProductName = firstComponent ? firstComponent.name : ''
  const typeTitle = form.linkType === BOM_LINK_TYPE ? 'BOM' : 'Kit'
  const typeStr = form.linkType === BOM_LINK_TYPE ? 'BOM' : 'kit'
  const willRemoveAllComponents =
    form.componentSKUs.length === components.length

  return (
    <ConfirmModal
      title={plural(form.componentSKUs)`Remove ${typeTitle} Component${['s']}`}
      modalSize="sm"
      onConfirm={() => removeComponents()}
      onCancel={() => closeModal()}
      confirmText="Yes"
      cancelText="No"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <PluralBlock array={form.componentSKUs}>
        <div className="fs-00 text--md-grey">
          <strong>Parent {typeTitle}:</strong>
        </div>
        <div className="fs-01">
          <strong>{parentProductName}</strong>
        </div>
        <div className="fs-00 divider--bottom">
          <span>{form.sku}</span>
        </div>
        <div className="fs-01 lh-md margin-bottom-10">
          Are you sure you want to remove{' '}
          <strong>
            <IfSingle>
              {firstComponentProductName} ({form.componentSKUs[0]})
            </IfSingle>
            <IfPlural>
              <Count /> products
            </IfPlural>
          </strong>{' '}
          from this {typeStr}?
        </div>
        {willRemoveAllComponents && form.linkType === PRODUCT_LINK_TYPE && (
          <div className="alert alert--warning">
            Deleting <Plural p="these kit components" s="this kit component" />{' '}
            will zero out the Physical On Hand quantities for this Kit product.
          </div>
        )}
      </PluralBlock>
    </ConfirmModal>
  )
}

RemoveComponentModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    componentSKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
    linkType: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(RemoveComponentModal, modalFormSelector)
