import fpMerge from 'lodash/fp/merge.js'
import isEmpty from 'lodash/isEmpty.js'
import get from 'lodash/get.js'
import keyBy from 'lodash/keyBy.js'
import {lruMemoize, createSelector} from 'reselect'
import fpSet from 'lodash/fp/set.js'
import fpUnset from 'lodash/fp/unset.js'
import fpFlow from 'lodash/fp/flow.js'
import head from 'lodash/head.js'
import omit from 'lodash/omit.js'
import set from 'lodash/set.js'
import difference from 'lodash/difference.js'
import isString from 'lodash/isString.js'
import isArray from 'lodash/isArray.js'
import sortBy from 'lodash/sortBy.js'

import {
  getState,
  setForm,
  setFormValue,
  updateFormObject,
  removeFormValue,
  formsSelector,
} from '../store.js'
import api from '../common/api.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {AMAZON_SFP, UPS} from '../common/constants/ShipperNames.js'
import {PRESET_TEMPLATES} from '../common/constants/LabelConfig.js'
import renderTemplate from '../common/renderTemplate.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'
import {shipperSelector} from './shippers.js'
import {
  updateLabelConfig,
  updateLabelInfo,
  applyShipFrom,
  labelConfigSelector,
  orderNumberFromLabelConfigSelector,
  referenceIDFromLabelConfigSelector,
  getMaxParcelCount,
  departedShippingMethodSelector,
  validShippersSelector,
  rateForMethodSelector,
  labelShippingMethodNameSelector,
  labelUseRateShoppingSelector,
  saveLabelInfo,
} from './labelInfos/index.js'
import {getRates} from './labelInfos/rateRequest.js'
import {showMessageToast} from '../ordoro/Header/Toast/index.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {
  canIgnoreAmazonPrimeShipperRestrictionSelector,
  canShipAllAmazonWithAmazonSelector,
} from '../data/company.js'
import {
  orderSelector,
  isPrimeOrderSelector,
  isAmazonOrderSelector,
} from './orders.js'
import {returnOrderSelector} from '../redux/selectors/data/returnOrders.js'
import userflow from '../common/analytics/userflow.js'
import apiverson from '../common/apiverson.js'

export const PRESETS = 'PRESETS'
export const PRESET_VERSION = '2'

export function setPresets(presets) {
  setForm(PRESETS, keyBy(presets, 'id'))
}

export function setPreset(preset) {
  setFormValue(PRESETS, preset.id, preset)
}

export function updatePreset(presetID, updates) {
  updateFormObject(PRESETS, presetID, updates)
}

export function unsetPreset(presetID) {
  removeFormValue(PRESETS, presetID)
}

export function setPresetName(presetID, name) {
  updatePreset(presetID, {name})
}

export function updatePresetLabelConfig(presetID, updates) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset) {
    return
  }

  updatePreset(presetID, {
    body: {
      ...preset.body,
      labelConfig: {
        ...preset.body.labelConfig,
        ...updates,
      },
    },
  })
}

export function setPresetProperties(presetID, properties) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset) {
    return
  }

  updatePreset(presetID, {
    body: {
      ...preset.body,
      properties,
    },
  })
}

export function toggleProperty(presetID, propertyPath) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset) {
    return
  }

  const properties = preset.body.properties.filter(
    (path) => path !== propertyPath,
  )

  if (!preset.body.properties.includes(propertyPath)) {
    properties.push(propertyPath)
  }

  setPresetProperties(presetID, properties)
}

export function enableProperty(presetID, propertyPath) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset || preset.body.properties.includes(propertyPath)) {
    return
  }

  setPresetProperties(presetID, [...preset.body.properties, propertyPath])
}

export function disableProperty(presetID, propertyPath) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset || !preset.body.properties.includes(propertyPath)) {
    return
  }

  const properties = preset.body.properties.filter(
    (path) => path !== propertyPath,
  )

  setPresetProperties(presetID, properties)
}

export function editPreset(toPresetID, fromPresetID) {
  const preset = presetSelector(getState(), {presetID: fromPresetID})

  setPreset({
    ...preset,
    id: toPresetID,
    body: {
      ...preset.body,
      version: PRESET_VERSION,
    },
  })
}

export function createBlankPreset({id, labelConfig, properties}) {
  setPreset({
    id,
    name: '',
    body: {
      version: PRESET_VERSION,
      labelConfig,
      properties,
    },
  })
}

export async function getPresets() {
  try {
    const presets = await fetchAllAPI('/preset/', 'preset')

    setPresets(presets)

    userflow.updateGroup({
      presets_count: presets.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error loading presets.',
        details: err.message,
      },
      err,
    )
  }
}

export async function applyPreset(labelInfoID, presetID) {
  const orderNumber = orderNumberFromLabelConfigSelector(getState(), {
    labelInfoID,
  })

  if (labelUseRateShoppingSelector(getState(), {labelInfoID})) {
    try {
      updateLabelInfo(labelInfoID, {rates_loading: true})

      await apiverson.post(
        `/order/${encodeURIComponent(orderNumber)}/shipment_preset/${presetID}`,
        {also_fetch_rates: true},
      )
    } finally {
      updateLabelInfo(labelInfoID, {rates_loading: false})
    }

    return
  }

  const preset = presetSelector(getState(), {presetID})
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})
  const presetLabelConfig = getPresetAsPartialLabelConfig(preset)
  const validShippers = validShippersSelector(getState(), {labelInfoID})

  // if preset sets shipper but the shipper doesn't exist then return early
  if (get(preset, ['body', 'properties'], []).includes('shipper_id')) {
    if (!getPresetHasValidShipper(preset, validShippers)) {
      return
    }
  }

  // if preset is empty return early
  if (isEmpty(preset) || isEmpty(presetLabelConfig)) {
    return
  }

  const originalShippingMethodName = labelShippingMethodNameSelector(
    getState(),
    {
      labelInfoID,
    },
  )
  const referenceID = referenceIDFromLabelConfigSelector(getState(), {
    labelInfoID,
  })
  const shipperType = presetShipperTypeSelector(getState(), {presetID})
  const shippingMethodKey = `${shipperType}__shipping_method`
  const shippingMethod = presetLabelConfig[shippingMethodKey]
  const isPrimeOrder = isPrimeOrderSelector(getState(), {orderNumber})
  const isAmazonOrder = isAmazonOrderSelector(getState(), {orderNumber})
  const canIgnoreAmazonPrimeShipperRestriction =
    canIgnoreAmazonPrimeShipperRestrictionSelector(getState())
  const canShipAllAmazonWithAmazon =
    canShipAllAmazonWithAmazonSelector(getState())

  // Amazon specific restrictions
  if (presetLabelConfig.shipper_id && !canIgnoreAmazonPrimeShipperRestriction) {
    if (shipperType !== AMAZON_SFP && isPrimeOrder) {
      showMessageToast(
        `The preset (${preset.name}) is incompatible with this Amazon Seller Fulfilled Prime order (${orderNumber})`,
      )

      return
    }

    if (
      shipperType === AMAZON_SFP && // must be trying to apply a SFP shipper preset
      !isPrimeOrder && // prime orders can use SFP presets
      !(isAmazonOrder && canShipAllAmazonWithAmazon) // non-prime amazon orders (with flag) can use SFP presets
    ) {
      // all other orders can't use a SFP shipper preset
      showMessageToast(
        `The Amazon Seller Fulfilled Prime preset (${preset.name}) is incompatible with this non-Prime order (${orderNumber})`,
      )

      return
    }
  }

  // Don't change shipping method until we're sure its in the rates
  if (shippingMethod) {
    delete presetLabelConfig[shippingMethodKey]
  }

  // We will support changing the warehouse from a preset for now
  // We need to stop it though
  let warehouseID
  if (presetLabelConfig.warehouse_id) {
    warehouseID = presetLabelConfig.warehouse_id

    delete presetLabelConfig.warehouse_id
  }

  // create a new label config from label config and preset
  const mergedLabelConfig = mergePresetIntoLabelConfig(
    labelConfig,
    presetLabelConfig,
    {
      order: orderNumber ? orderSelector(getState(), {orderNumber}) : null,
      returnOrder: referenceID
        ? returnOrderSelector(getState(), {referenceID})
        : null,
    },
  )

  const packages = mergedLabelConfig.packages || []
  const maxParcelCount = getMaxParcelCount(shipperType)

  // if packages were in the preset then ensure we don't exceed max for shipper
  if (packages.length > maxParcelCount) {
    mergedLabelConfig.packages = packages.slice(0, maxParcelCount)
  }

  // If we are changing shipping method from UPS First Class Air (01)
  if (
    shippingMethod &&
    shippingMethod !== '01' &&
    shipperType === UPS &&
    labelConfig.ups__shipping_method === '01'
  ) {
    // need to reset box shapes since only UPS First Class Air can have unique box shapes
    const firstBoxShape = get(mergedLabelConfig, 'packages.0.ups__box_shape')
    mergedLabelConfig.packages.forEach(
      (parcel) => (parcel.ups__box_shape = firstBoxShape),
    )
  }

  // apply changes to state
  updateLabelConfig(labelInfoID, mergedLabelConfig)

  // because we still support it, update warehouse if changed
  if (warehouseID) {
    await applyShipFrom(labelInfoID, warehouseID)
  } else {
    // otherwise update rates
    await getRates([labelInfoID])
  }

  // if shipping method changed (remember, we waited to apply it)
  if (shippingMethod) {
    const rateForMethod = rateForMethodSelector(getState(), {
      labelInfoID,
      shippingMethod,
    })

    // if we have a rate for the new shipping method
    if (rateForMethod) {
      // apply it so it can be used
      updateLabelConfig(labelInfoID, {
        [shippingMethodKey]: shippingMethod,
        [`${shipperType}__rate_key`]: rateForMethod.key,
      })
    } else {
      // otherwise set special state so we can inform the user
      // that we couldn't apply the shipping method
      updateLabelInfo(labelInfoID, {
        departed_shipping_method: originalShippingMethodName,
      })
    }

    // save the shipping method change
    await saveLabelInfo(labelInfoID)
  }
}

export async function savePreset({id, name, body}) {
  if (!body.version) {
    body.version = PRESET_VERSION
  }

  const {json} = id
    ? await api.put(`/preset/${id}`, {name, body})
    : await api.post('/preset/', {name, body})

  setPreset(json)

  return json
}

export function presetsSelector(state) {
  return formsSelector(state)[PRESETS] || presetsSelector.default
}
presetsSelector.default = {}

export const presetCountSelector = createSelector(
  presetsSelector,
  (presets) => Object.keys(presets).length,
)

export function presetSelector(state, {presetID}) {
  return presetsSelector(state)[presetID]
}

export function presetsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PRESETS]
}

export function getPresetsSelector(state, presetIDs = []) {
  return presetIDs.reduce((prev, presetID) => {
    const preset = presetSelector(state, {presetID})

    if (preset) {
      prev.push(preset)
    }

    return prev
  }, [])
}

export const sortedPresetsSelector = createSelector(
  presetsSelector,
  (presets) => sortBy(presets, 'created'),
)

export const presetOptionsSelector = createSelector(
  sortedPresetsSelector,
  (presets) =>
    presets.map((preset) => ({
      value: preset.id,
      display: preset.name,
      entity: preset,
      nouns: getNounsFromValue(
        `${preset.name} ${preset.body.properties.join(' ')}`,
      ),
    })),
)

export const presetOptionTokensSelector = createSelector(
  presetOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function presetShipperTypeSelector(state, {presetID}) {
  const preset = presetSelector(state, {presetID})

  const shipperID = get(preset, 'body.labelConfig.shipper_id')

  if (!shipperID) {
    return null
  }

  const shipper = shipperSelector(state, {shipperID})

  return shipper ? shipper.vendor : null
}

export function isMultiboxPresetSelector(state, {presetID}) {
  const preset = presetSelector(state, {presetID})

  return get(preset, 'body.labelConfig.packages', []).length > 1
}

export function getPresetHasValidShipper(preset, validShippers) {
  const validShipperIDs = validShippers.map((shipper) => shipper.id)
  const presetShipperID = get(preset.body.labelConfig, 'shipper_id')
  return validShipperIDs.includes(presetShipperID)
}

export const unavailableLabelConfigsSelector = lruMemoize(
  (state, {labelInfoIDs}) =>
    labelInfoIDs.filter((labelInfoID) =>
      departedShippingMethodSelector(state, {labelInfoID}),
    ),
)

export function unavailableShippingMethodSelector(state, {labelInfoIDs}) {
  const labelInfoID = head(
    unavailableLabelConfigsSelector(state, {labelInfoIDs}),
  )
  if (!labelInfoID) {
    return null
  }
  return departedShippingMethodSelector(state, {labelInfoID})
}

export function getPresetAsPartialLabelConfig(preset) {
  if (isEmpty(preset)) {
    return {}
  }

  const {properties, labelConfig} = preset.body

  const packagesProperties = properties.filter((path) => path.match(/packages/))

  const remainingProperties = difference(properties, packagesProperties)

  return {
    ...remainingProperties.reduce(
      (prev, path) => set(prev, path, get(labelConfig, path)),
      {},
    ),
    ...expandPackagesFromProperties(packagesProperties, labelConfig),
  }
}

export function expandPackagesFromProperties(properties, labelConfig) {
  if (properties.length === 0) {
    return {}
  }

  properties = properties.map((path) => path.split('.')[1])

  return {
    packages: labelConfig.packages.map((parcel) =>
      properties.reduce((prev, path) => {
        prev[path] = parcel[path]

        return prev
      }, {}),
    ),
  }
}

export function mergePresetIntoLabelConfig(
  labelConfig,
  presetLabelConfig,
  {order, returnOrder},
) {
  const merged = fpMerge(labelConfig, presetLabelConfig)

  if (presetLabelConfig.shipper_ids) {
    merged.shipper_ids = presetLabelConfig.shipper_ids
  }

  if (presetLabelConfig.packages) {
    const lastPackagesIndex = labelConfig.packages.length - 1

    merged.packages = presetLabelConfig.packages.map((presetParcel, index) => {
      const parcel =
        labelConfig.packages[
          index > lastPackagesIndex ? lastPackagesIndex : index
        ]

      return {
        ...parcel,
        ...presetParcel,
      }
    })
  }

  return renderTemplateProperties(merged, {
    order: returnOrder
      ? {
          reference_id: returnOrder.reference_id,
          order_number: returnOrder.original_order_number,
          lines: returnOrder.lines.map((line) => ({
            product_name: line.product_name,
            sku: line.sku,
            quantity: line.expected_quantity,
          })),
        }
      : order,
  })
}

export function presetParamsSelector(state, {presetID}) {
  const preset = presetSelector(state, {presetID})

  return {
    ...preset,
    id: undefined,
    body: {
      ...preset.body,
      labelConfig: omit(preset.body.labelConfig, [
        'rates',
        'order_id',
        'label_type',
        'error_message',
        'rates_loading',
        'rates_updated',
        'departed_shipping_method',
        'additional_options_open',
        'shipping_address',
      ]),
    },
  }
}

export function presetTemplateStateSelector() {
  return {
    order: {
      order_number: 'M-SAMPLE-ORDER-ID-1',
      reference_id: 'RMA-SAMPLE-ORDER-ID-1',
      lines: [
        {sku: 'sku001', product_name: 'Product 001', quantity: 1},
        {sku: 'sku002', product_name: 'Product 002', quantity: 2},
        {sku: 'sku003', product_name: 'Product 003', quantity: 3},
      ],
    },
  }
}

// Find template properties, extract true property name, run template, assign to true property
export function renderTemplateProperties(config, state = {}) {
  const override = []

  function handleTemplates(possibleTemplate, key, pathTo) {
    if (!isString(key)) {
      return
    }

    const [, property, dataType, type] =
      key.match(/^(.*)__(str|num|bool)__(template|template_ref)$/) || []

    possibleTemplate =
      type === 'template'
        ? possibleTemplate
        : PRESET_TEMPLATES[possibleTemplate] || 'template not found'

    if (property) {
      let value = renderTemplate(possibleTemplate, state)

      if (dataType === 'num') {
        value = Number(value)
      }
      if (dataType === 'bool') {
        value = value.toLowerCase() === 'true'
      }

      override.push(fpSet([...pathTo, property], value))
      override.push(fpUnset([...pathTo, key]))
    }
  }

  function handleConfigProperty(key) {
    const value = config[key]

    if (['packages', 'customs_info'].includes(key) && isArray(value)) {
      value.forEach((subObject, index) =>
        Object.keys(subObject).forEach((subObjectKey) =>
          handleTemplates(subObject[subObjectKey], subObjectKey, [key, index]),
        ),
      )
    } else {
      handleTemplates(value, key, [])
    }
  }

  Object.keys(config).forEach(handleConfigProperty)

  return override.length ? fpFlow(override)(config) : config
}
