import {useEffect} from 'react'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {showInventoryMgmtModuleModal} from '../Modals/InventoryMgmtModuleModal.js'

import {useSelector} from '../../store.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import {
  useQuickShipSelector,
  usesAppsSelector,
  usesInventorySelector,
} from '../../data/company.js'
import {integrationIssuesSelector} from '../../redux/selectors/ui/dashboard.js'
import {
  friendlyNameSelector,
  hasQuickAnalyticsPermissionSelector,
  hasSettingsPermissionSelector,
} from '../../data/me.js'
import {allowCartSyncModalSelector} from '../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {showQuickShipModal} from '../OrderListPage/Modals/QuickShipModal/quickShipModalFunctions.js'
import ActionItems from './ActionItems.js'
import Modals from './Modals/index.js'
import Salutation from './Salutation.js'
import SyncStatus from './SyncStatus.js'
import QuickAnalytics from './QuickAnalytics.js'
import {hasDashboardLoadedSelector} from './dashboardFunctions.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'
import {hasWelcomeTabSelector} from '../Header/headerSelectors.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'

export default function DashboardPage() {
  useEffect(() => {
    document.title = 'Dashboard'
  }, [])

  const customerName = useSelector(friendlyNameSelector)
  const integrationIssues = useSelector(integrationIssuesSelector)
  const allowCartSyncModal = useSelector(allowCartSyncModalSelector)
  const hasSettingsPermission = useSelector(hasSettingsPermissionSelector)
  const hasQuickAnalyticsPermission = useSelector(
    hasQuickAnalyticsPermissionSelector,
  )
  const hasDashboardLoaded = useSelector(hasDashboardLoadedSelector)
  const usesInventory = useSelector(usesInventorySelector)
  const useQuickShip = useSelector(useQuickShipSelector)
  const usesApps = useSelector(usesAppsSelector)
  const hasWelcomeTab = useSelector(hasWelcomeTabSelector)

  return (
    <div className="wrap--row wrap--dashboard-content">
      <Modals />
      <div className="medium-12 columns">
        <div className="wrap--row padding-top-40">
          {!hasWelcomeTab && (
            <div className="medium-9 medium-centered columns">
              <h2 className="fs-04 align-center margin-bottom-20">
                <Salutation name={customerName} />
              </h2>
            </div>
          )}
          {!hasDashboardLoaded && (
            <div className="wrap--list-loading">
              <div className="wrap--full-cover">
                <div className="spinner--list-loading" />
              </div>
            </div>
          )}
        </div>
        {hasDashboardLoaded && (
          <div className="wrap--row margin-top-20">
            {hasQuickAnalyticsPermission && <QuickAnalytics />}
            <div
              className={
                hasQuickAnalyticsPermission
                  ? 'medium-3 columns padding-left-30 end outer-wrap--action-items'
                  : 'medium-5 medium-centered columns'
              }
            >
              {allowCartSyncModal && <SyncStatus />}

              <ActionItems />

              {hasSettingsPermission && integrationIssues.length !== 0 && (
                <div className="panel panel--dashboard panel--warning flex-item">
                  <div className="panel__header panel__header--dashboard">
                    <h3 className="subheader subheader--dashboard fs-00">
                      Integration Issues
                    </h3>
                  </div>
                  <div className="panel__body">
                    <ul className="list square">
                      {integrationIssues.map(
                        ({id, name, message, link, onClick}) => (
                          <li
                            key={id}
                            className="list__item list__item--action-item fs-00"
                          >
                            {link ? (
                              <LinkButton
                                className="btn--link darker v-align-top"
                                href={link}
                              >
                                <strong>{name}</strong> <span>{message}</span>
                              </LinkButton>
                            ) : onClick ? (
                              <ButtonLink onClick={onClick}>
                                <strong>{name}</strong> <span>{message}</span>
                              </ButtonLink>
                            ) : (
                              <>
                                <strong>{name}</strong> <span>{message}</span>
                              </>
                            )}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {useQuickShip && (
                <div className="panel panel--dashboard panel--dashboard-quickship flex-item">
                  <div className="panel__header panel__header--dashboard flex--justify">
                    <h3 className="subheader--dashboard fs-00">QUICK SHIP</h3>
                  </div>
                  <div className="panel__body">
                    <p className="fs-00 lh-md margin-bottom-15">
                      Get a one-off shipping label with just a ship-from address
                      and a ship-to address.
                    </p>
                    <div>
                      <ButtonPrimary
                        size="sm lh-lg w-100"
                        onClick={() => showQuickShipModal()}
                      >
                        Create a Quick Ship Label
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              )}
              {!usesInventory &&
                (usesApps ? (
                  <div className="panel panel--dashboard panel--dashboard-quickship flex-item">
                    <div className="panel__header panel__header--dashboard flex--justify">
                      <h3 className="subheader--dashboard fs-00">
                        Get The Inventory App
                      </h3>
                    </div>
                    <div className="panel__body">
                      <p className="fs-00 lh-md margin-bottom-15">
                        Upgrade your account and access to all of our advanced
                        inventory management features like kitting, POs, RMAs,
                        Bill of Materials, Dropshipping, and more!
                      </p>
                      <div className="margin-bottom-10">
                        <ButtonPrimary
                          alt
                          className="lh-lg w-100 meta-EnableIMM_dashboard_sidebar"
                          size="sm"
                          onClick={() => showUpgradeModal()}
                        >
                          Enable Inventory App
                        </ButtonPrimary>
                      </div>
                      <div>
                        <ButtonPrimary
                          isOutlined
                          className="lh-lg w-100 meta-TalktoExpert-IMM_dashboard_sidebar"
                          size="sm"
                          onClick={() => showFeatureLockedModal('inventory')}
                        >
                          Talk to an Expert
                        </ButtonPrimary>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="panel panel--dashboard panel--dashboard-quickship flex-item">
                    <div className="panel__header panel__header--dashboard flex--justify">
                      <h3 className="subheader--dashboard fs-00">
                        Get the Inventory Management Module
                      </h3>
                    </div>
                    <div className="panel__body">
                      <p className="fs-00 lh-md margin-bottom-15">
                        Upgrade your account and access to all of our advanced
                        inventory management features like kitting, POs, RMAs,
                        Bill of Materials, Dropshipping, and more!
                      </p>
                      <div className="margin-bottom-10">
                        <ButtonPrimary
                          alt
                          className="lh-lg w-100 meta-EnableIMM_dashboard_sidebar"
                          size="sm"
                          onClick={() => showInventoryMgmtModuleModal()}
                        >
                          Enable Inventory Management
                        </ButtonPrimary>
                      </div>
                      <div>
                        <ButtonPrimary
                          isOutlined
                          className="lh-lg w-100 meta-TalktoExpert-IMM_dashboard_sidebar"
                          size="sm"
                          onClick={() => showFeatureLockedModal('inventory')}
                        >
                          Talk to an Expert
                        </ButtonPrimary>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
