import {KIT_VIEW_PARENTS} from '../../common/constants/Abode.js'

export const MOCK_ABODE_LABEL_DEFAULTS = {
  layout: 'fullpage',
  format: 'pdf',
  docs: ['packing'],
  show_logo_on_label: false,
  debug_logo_placement: false,
  break_page_on_order: false,
  label_left_always: false,
  label_right_always: false,
  utc_offset: 0,
  // label_width: 0,
  // label_height: 0,
  // label_top_margin: 0,
  // label_left_margin: 0,
  enable_envelope: false,
  packing_list_sort: 'sku',
  // packing_list_layout: null,
  show_bill_to: false,
  show_price: false,
  show_barcode: false,
  show_packing_list_images: false,
  packing_list_kit_view_mode: KIT_VIEW_PARENTS,
  show_ordoro_product_name: false,
  show_sibling_order_quantities: false,
  show_pack_serial_numbers: false,
  show_warehouse_location: false,
  show_customer_notes: false,
  show_packing_list_details: false,
  show_zero_quantity_lines: false,
  show_shipping_info: false,
  highlight_pack_line_qty: false,
  show_pack_sku_barcode: false,
  pick_list_sort: 'sku',
  // pick_list_layout: null,
  show_pick_list_images: false,
  show_pick_serial_numbers: false,
  pick_list_kit_view_mode: KIT_VIEW_PARENTS,
  show_pick_list_total_items: false,
  show_pick_list_total_skus: false,
  show_pick_list_total_orders: false,
  show_order_tray: false,
  highlight_pick_line_qty: false,
  show_pick_list_poh: false,
  show_pick_sku_barcode: false,
  mock: true,
}

export const MOCK_ABODE_RENDER_PACKING_LIST_PDF = JSON.stringify([
  'Layout',
  {
    width: '8.5in',
    height: '11in',
    nodes: [{id: 'page', width: '100%', height: '100%'}],
  },
  ['page', ['PackingListNew', {line_items: {$var: 'state.order.lines'}}]],
])
export const MOCK_ABODE_RENDER_PACKING_LIST_HTML = JSON.stringify([
  'PackingListNew',
  {line_items: {$var: 'state.order.lines'}},
])

const LABEL_PROPS = {
  layout: 'thermal',
  order: {$var: 'state.order'},
  packing_list: {$var: 'state.packing_list'},
  company: {$var: 'state.company'},
  show_logo_on_label: true,
  debug_logo_placement: false,
}
export const MOCK_ABODE_RENDER_LABEL_OLD_PDF = JSON.stringify([
  'Layout',
  {
    width: '8.5in',
    height: '11in',
    nodes: [{id: 'page', width: '100%', height: 'inherit'}],
    paginate: ['page'],
  },
  ['page', ['Label', {...LABEL_PROPS, layout: 'fullpage'}]],
])
export const MOCK_ABODE_RENDER_LABEL_OLD_HTML = JSON.stringify([
  'Label',
  {...LABEL_PROPS, layout: 'fullpage'},
])
export const MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_PDF = JSON.stringify([
  'Layout',
  {
    width: '4in',
    height: '6in',
    nodes: [{id: 'page', width: '100%', height: 'inherit'}],
    paginate: ['page'],
  },
  ['page', ['Label', LABEL_PROPS]],
])
export const MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_HTML = JSON.stringify([
  'Label',
  LABEL_PROPS,
])

const PACKING_LIST_PROPS = {
  layout: 'fullpage',
  order: {$var: 'state.order'},
  products: {$var: 'state.products'},
  kit_graph_by_sku: {$var: 'state.kit_graph_by_sku'},
  linked_orders: {$var: 'state.linked_orders'},
  packing_list: {$var: 'state.packing_list'},
  packing_list_logo: {
    '$func.getPackingListLogo': [{var: 'state.packing_list'}],
  },
  company: {$var: 'state.company'},
  tray_number: 22,
  packing_list_sort: 'sku',
  show_barcode: true,
  show_packing_list_images: true,
  packing_list_kit_view_mode: 'KIT_VIEW_PARENTS',
  show_ordoro_product_name: true,
  show_sibling_order_quantities: true,
  show_zero_quantity_lines: true,
  show_order_tray: true,
  show_pack_sku_barcode: true,
}
export const MOCK_ABODE_RENDER_PACKING_LIST_OLD_PDF = JSON.stringify([
  'Layout',
  {
    width: '8.5in',
    height: '11in',
    nodes: [{id: 'page', width: '100%', height: 'inherit'}],
    paginate: ['page'],
  },
  ['page', ['PackingList', PACKING_LIST_PROPS]],
])
export const MOCK_ABODE_RENDER_PACKING_LIST_OLD_HTML = JSON.stringify([
  'PackingList',
  PACKING_LIST_PROPS,
])

const PICK_LIST_PROPS = {
  layout: 'fullpage',
  orders: {$var: 'state.orders'},
  products: {$var: 'state.products'},
  kit_graph_by_sku: {$var: 'state.kit_graph_by_sku'},
  company: {$var: 'state.company'},
  pick_list_sort: 'sku',
  show_pick_list_images: true,
  pick_list_kit_view_mode: 'KIT_VIEW_PARENTS',
  show_order_tray: true,
  show_pick_list_poh: true,
  show_pick_sku_barcode: true,
}
export const MOCK_ABODE_RENDER_PICK_LIST_OLD_PDF = JSON.stringify([
  'Layout',
  {
    width: '8.5in',
    height: '11in',
    nodes: [{id: 'page', width: '100%', height: 'inherit'}],
    paginate: ['page'],
  },
  ['page', ['PickList', PICK_LIST_PROPS]],
])
export const MOCK_ABODE_RENDER_PICK_LIST_OLD_HTML = JSON.stringify([
  'PickList',
  PICK_LIST_PROPS,
])

export const MOCK_ABODE_RENDER_OPTIONS = [
  {
    value: MOCK_ABODE_RENDER_PACKING_LIST_PDF,
    display: 'Packing List (pdf, new)',
    mock_type: 'RENDER_LAYOUT_PACKING_LIST',
    render: MOCK_ABODE_RENDER_PACKING_LIST_PDF,
  },
  {
    value: MOCK_ABODE_RENDER_PACKING_LIST_HTML,
    display: 'Packing List (html, new)',
    mock_type: 'RENDER_LAYOUT_PACKING_LIST',
    render: MOCK_ABODE_RENDER_PACKING_LIST_HTML,
  },
  {
    value: MOCK_ABODE_RENDER_PACKING_LIST_OLD_PDF,
    display: 'Packing List (pdf)',
    render: MOCK_ABODE_RENDER_PACKING_LIST_OLD_PDF,
    mock_type: 'RENDER_LAYOUT_PACKING_LIST',
  },
  {
    value: MOCK_ABODE_RENDER_PACKING_LIST_OLD_HTML,
    display: 'Packing List (html)',
    render: MOCK_ABODE_RENDER_PACKING_LIST_OLD_HTML,
    mock_type: 'RENDER_LAYOUT_PACKING_LIST',
  },
  {
    value: MOCK_ABODE_RENDER_PICK_LIST_OLD_PDF,
    display: 'Pick List (pdf)',
    render: MOCK_ABODE_RENDER_PICK_LIST_OLD_PDF,
    mock_type: 'RENDER_LAYOUT_PICK_LIST',
  },
  {
    value: MOCK_ABODE_RENDER_PICK_LIST_OLD_HTML,
    display: 'Pick List (html)',
    render: MOCK_ABODE_RENDER_PICK_LIST_OLD_HTML,
    mock_type: 'RENDER_LAYOUT_PICK_LIST',
  },
  {
    value: MOCK_ABODE_RENDER_LABEL_OLD_PDF,
    display: 'Label (fullpage, pdf)',
    render: MOCK_ABODE_RENDER_LABEL_OLD_PDF,
    mock_type: 'RENDER_LAYOUT_LABEL',
  },
  {
    value: MOCK_ABODE_RENDER_LABEL_OLD_HTML,
    display: 'Label (fullpage, html)',
    render: MOCK_ABODE_RENDER_LABEL_OLD_HTML,
    mock_type: 'RENDER_LAYOUT_LABEL',
  },
  {
    value: MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_PDF,
    display: 'Label (thermal, pdf)',
    render: MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_PDF,
    mock_type: 'RENDER_LAYOUT_LABEL',
  },
  {
    value: MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_HTML,
    display: 'Label (thermal, html)',
    render: MOCK_ABODE_RENDER_LABEL_THERMAL_OLD_HTML,
    mock_type: 'RENDER_LAYOUT_LABEL',
  },
]

export const MOCK_ABODE_RENDER_DEFAULTS = {
  mock_type: 'RENDER_LAYOUT_PACKING_LIST',
  render: MOCK_ABODE_RENDER_PACKING_LIST_PDF,
}
