import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {WALMART} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import Checkbox from '../../Common/Checkbox.js'
import Link from '../../../../common/components/Link/Link.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function isOldAuthMethod(cart) {
  return (
    cart && !!cart.vendor_config.consumer_id && !cart.vendor_config.client_id
  )
}

// can have v2 only (none)
// can have v3 only (new/old_v3)
// can upgrade to v3 from v2 (old_v2)
export function canUpgradeAuthMethodSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const cart = cartSelector(getState(), {cartID: form.id})
  const isNew = form.id === NEW_ID

  return !isNew && isOldAuthMethod(cart)
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!form.showNewAuthCreds && !isPresent(form.consumerID)) {
    errors.consumerID = 'Consumer ID is required'
    errors.preventSave = true
  }

  if (!form.showNewAuthCreds && !isPresent(form.privateKey)) {
    errors.privateKey = 'Private Key is required'
    errors.preventSave = true
  }

  if (form.showNewAuthCreds && !isPresent(form.clientID)) {
    errors.clientID = 'Client ID is required'
    errors.preventSave = true
  }

  if (form.showNewAuthCreds && !isPresent(form.clientSecret)) {
    errors.clientSecret = 'Client Secret is required'
    errors.preventSave = true
  }

  if (form.id === NEW_ID && !form.hasAcknowledged) {
    errors.hasAcknowledged = 'Must acknowledge'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: WALMART}),
      showNewAuthCreds: true,
      clientID: '',
      clientSecret: '',
      consumerID: '',
      privateKey: '',
      hasAcknowledged: false,
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    showNewAuthCreds: !isOldAuthMethod(cart),
    clientID: cart.vendor_config.client_id || '',
    clientSecret: cart.vendor_config.client_secret || '',
    consumerID: cart.vendor_config.consumer_id || '',
    privateKey: cart.vendor_config.private_key || '',
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError, showNewAuthCreds} = formSelector(state, {formName})
  const errors = {}

  if (!showNewAuthCreds && serverError.param === 'consumer_id') {
    errors.consumerID = serverError.error_message
  } else if (!showNewAuthCreds && serverError.param === 'private_key') {
    errors.privateKey = serverError.error_message
  } else if (showNewAuthCreds && serverError.param === 'client_id') {
    errors.clientID = serverError.error_message
  } else if (showNewAuthCreds && serverError.param === 'client_secret') {
    errors.clientSecret = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    client_id: form.clientID,
    client_secret: form.clientSecret,
    consumer_id: form.consumerID,
    private_key: form.privateKey,
  }
}

function Walmart({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )
  const canUpgradeAuthMethod = useSelector((state) =>
    canUpgradeAuthMethodSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          {canUpgradeAuthMethod && (
            <FormListItem>
              <Checkbox
                id="show_new_auth_creds"
                label="Use v3 authentication"
                checked={form.showNewAuthCreds || false}
                onChange={(checked) =>
                  updateForm(formName, {
                    showNewAuthCreds: checked,
                  })
                }
              />
            </FormListItem>
          )}
          {form.showNewAuthCreds ? (
            <>
              <FormListItem className="divider--top">
                <TextInput
                  name="client_id"
                  label="Client ID"
                  required
                  placeholder="00112233-4455-6677-8899-aabbccddeeff"
                  value={form.clientID || ''}
                  errorMessage={form.hasChanged_clientID && errors.clientID}
                  onChange={(event) =>
                    updateForm(formName, {
                      clientID: event.target.value,
                      hasChanged_clientID: true,
                    })
                  }
                />
              </FormListItem>
              <FormListItem>
                <TextInput
                  type="password"
                  name="client_secret"
                  label="Client Secret"
                  required
                  value={form.clientSecret || ''}
                  errorMessage={
                    form.hasChanged_clientSecret && errors.clientSecret
                  }
                  onChange={(event) =>
                    updateForm(formName, {
                      clientSecret: event.target.value,
                      hasChanged_clientSecret: true,
                    })
                  }
                />
              </FormListItem>
            </>
          ) : (
            <>
              <FormListItem className="divider--top">
                <TextInput
                  name="consumer_id"
                  label="Consumer ID"
                  required
                  placeholder="00112233-4455-6677-8899-aabbccddeeff"
                  value={form.consumerID || ''}
                  errorMessage={form.hasChanged_consumerID && errors.consumerID}
                  onChange={(event) =>
                    updateForm(formName, {
                      consumerID: event.target.value,
                      hasChanged_consumerID: true,
                    })
                  }
                />
              </FormListItem>
              <FormListItem>
                <TextInput
                  type="password"
                  name="private_key"
                  label="Private Key"
                  required
                  value={form.privateKey || ''}
                  errorMessage={form.hasChanged_privateKey && errors.privateKey}
                  onChange={(event) =>
                    updateForm(formName, {
                      privateKey: event.target.value,
                      hasChanged_privateKey: true,
                    })
                  }
                />
              </FormListItem>
            </>
          )}
          {isNew && (
            <FormListItem>
              <Checkbox
                id="acknowledge"
                label="I’m approved to sell on Walmart"
                checked={form.hasAcknowledged || false}
                onChange={(checked) =>
                  updateForm(formName, {
                    hasAcknowledged: checked,
                  })
                }
              />
            </FormListItem>
          )}
          {isNew && (
            <FormListItem className="divider--top align-center">
              <p className="fs-n0 margin-bottom-10">
                {'Not yet approved? '}
                <Link
                  href="https://marketplace.walmart.com/apply/?id=00161000010cMpN"
                  newWindow
                >
                  <strong>Go apply →</strong>
                </Link>
              </p>
            </FormListItem>
          )}
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Walmart"
          href="https://support.ordoro.com/walmart-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

Walmart.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    clientID: PropTypes.string.isRequired,
    hasChanged_clientID: PropTypes.bool,
    clientSecret: PropTypes.string.isRequired,
    hasChanged_clientSecret: PropTypes.bool,
    consumerID: PropTypes.string.isRequired,
    hasChanged_consumerID: PropTypes.bool,
    privateKey: PropTypes.string.isRequired,
    hasChanged_privateKey: PropTypes.bool,
    showNewAuthCreds: PropTypes.bool.isRequired,
    hasAcknowledged: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Walmart, setUpCartForm)
