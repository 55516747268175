import {useEffect} from 'react'
import cookies from 'cookies-js'

import {dispatch, useSelector} from '../store.js'
import copyTextToClipboard from '../common/copyTextToClipboard.js'
import {
  globalErrorSummarySelector,
  globalErrorDetailsSelector,
} from '../redux/selectors/ui/index.js'
import {showMessageToast} from './Header/Toast/index.js'
import {setGlobalError} from '../redux/actions/ui/index.js'

export function showGlobalError(...args) {
  dispatch(setGlobalError(...args))
}

export function getErrorCookie() {
  const errorCookie = cookies.get('error-cookie')

  if (errorCookie) {
    let error

    try {
      error = JSON.parse(errorCookie)
    } catch (err) {
      // noop
    }

    cookies.expire('error-cookie')

    if (error) {
      showGlobalError(
        {
          summary: 'An error was encountered processing your request.',
          details: error.message,
        },
        error,
      )
    }
  }
}

export default function GlobalErrorMessage() {
  const summary = useSelector(globalErrorSummarySelector)
  const details = useSelector(globalErrorDetailsSelector)

  useEffect(() => {
    getErrorCookie()
  }, [])

  if (!summary) {
    return null
  }

  return (
    <div className="modal modal--sm modal--global-error meta-modal-name-global-error">
      <div className="modal__header">
        <div className="wrap--row">
          <div className="medium-12 columns padding-right-0 flex--justify">
            <h3 className="margin-bottom-0 margin-top-0 error">
              An Error Occurred
            </h3>
            <button
              className="btn btn--link no-underline btn--close error"
              onClick={() => showGlobalError(null)}
            >
              <span className="i--close fs-01" />
            </button>
          </div>
        </div>
      </div>
      <div className="modal__content margin-bottom-30">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <p className="error margin-bottom-15">
              <strong>{summary}</strong>
            </p>
            {!!details && (
              <>
                <div className="wrap--global-error-raw fs-n0 margin-bottom-20">
                  <div>Details:</div>
                  {details}
                </div>
                <button
                  className="btn btn--link btn--copy-pasta no-underline fs-n0"
                  type="button"
                  onClick={() => {
                    copyTextToClipboard(
                      `${summary}\n-----\nDetails:\n${details}`,
                    )
                    showMessageToast('Error message copied to clipboard')
                  }}
                >
                  <strong>Copy to clipboard</strong>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
