import {getSticky, setSticky} from '../common/stickyStore.js'
import {OPEN_MODAL} from './actions/ui/index.js'
import {SET_FORM, UPDATE_FORM} from './actions/ui/forms.js'
import {updateForm} from '../store.js'

export const stickyFormMiddleware = (store) => (next) => (action) => {
  if (
    action &&
    (action.type === SET_FORM ||
      (action.type === OPEN_MODAL && action.payload.meta.form))
  ) {
    const formKey = action.payload.key
    const stickyForm = getSticky(formKey)

    if (stickyForm) {
      // Prevent sticky value from overriding value in setForm action by listing in
      // `stickyPropsPrevent`.
      // Like `setForm(FORM, {thing: 'really want it'}, {stickyPropsPrevent: ['thing']})`
      for (const property of action.meta.stickyPropsPrevent || []) {
        delete stickyForm[property]
      }

      if (Object.keys(stickyForm).length > 0) {
        if (action.type === SET_FORM) {
          action.payload.form = {...action.payload.form, ...stickyForm}
        } else if (action.type === OPEN_MODAL) {
          // Need to wait for current dispatch to finish, otherwise, the update will happen before the set does
          setTimeout(() => store.dispatch(updateForm(formKey, stickyForm)), 0)
        }
      }
    }
  }

  if (action && action.type === UPDATE_FORM && action.meta.stickyProps) {
    const formKey = action.payload.key
    const stickyForm = getSticky(formKey) || {}

    for (const property of action.meta.stickyProps) {
      const value = action.payload.form[property]

      stickyForm[property] = value
    }

    setSticky(formKey, stickyForm)
  }

  return next(action)
}
