import {useSelector} from '../../store.js'
import Footer from '../../common/components/List/Footer/index.js'
import {setLimit, setOffset} from './batchListActions.js'
import {
  isLoadingSelector,
  batchListQuerySelector,
  countSelector,
} from './batchListSelectors.js'

export default function BatchListFooter() {
  const {limit, offset} = useSelector(batchListQuerySelector)
  const isLoading = useSelector(isLoadingSelector)
  const count = useSelector(countSelector)

  return (
    <Footer
      isLoading={isLoading}
      limit={limit}
      offset={offset}
      count={count}
      setLimit={setLimit}
      setOffset={setOffset}
      preventResetOffset
    />
  )
}
