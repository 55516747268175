import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, select, put} from 'redux-saga/effects'

import {ReturnOrderLineShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {linesSelector} from '../../../redux/selectors/data/returnOrders.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

const MODAL_FORM = 'UPDATE_RETURN_ORDER_LINE_NOTES_MODAL_FORM'
export const SHOW_UPDATE_RETURN_ORDER_LINE_NOTES_MODAL =
  'SHOW_UPDATE_RETURN_ORDER_LINE_NOTES_MODAL'
export const UPDATE_RETURN_ORDER_LINE_NOTES = 'UPDATE_RETURN_ORDER_LINE_NOTES'

export function showUpdateReturnOrderLineNotesModal(referenceID, lineID) {
  return {
    type: SHOW_UPDATE_RETURN_ORDER_LINE_NOTES_MODAL,
    payload: {referenceID, lineID},
  }
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function updateReturnOrderLineNotes() {
  return {
    type: UPDATE_RETURN_ORDER_LINE_NOTES,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* showUpdateReturnOrderLineNotesModalWorker({
  payload: {referenceID, lineID},
}) {
  const lines = yield select(linesSelector, {referenceID})
  const line = lines.find(({line_id}) => line_id === lineID)

  yield put(
    setForm(MODAL_FORM, {
      referenceID,
      lineID,
      line,
      lineNotes: line.line_notes || '',
      isSaving: false,
      serverError: null,
    }),
  )
}

export function* updateReturnOrderLineNotesWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, lineID, lineNotes} = yield select(modalFormSelector)

    const {json} = yield call(
      apiverson.put,
      `/return_order/${encodeURIComponent(referenceID)}/line/${lineID}`,
      {line_notes: lineNotes},
    )

    yield put(setReturnOrder(json))

    yield call(showMessageToast, `RMA line notes were updated`)

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error setting RMA line notes: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function UpdateReturnOrderLineNotesModal({form, ...props}) {
  return (
    <ConfirmModal
      title="Update RMA Line Notes"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => props.updateReturnOrderLineNotes()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>{form.line.sku}</strong>
            <div>{form.line.product_name}</div>
          </div>
        </li>
        <li>
          <TextArea
            label="Line Notes"
            rows="10"
            id="line_notes"
            value={form.lineNotes}
            onChange={(value) => props.updateModalForm({lineNotes: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

UpdateReturnOrderLineNotesModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    line: ReturnOrderLineShape.isRequired,
    lineNotes: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  updateReturnOrderLineNotes: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  updateReturnOrderLineNotes,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(UpdateReturnOrderLineNotesModal),
  modalFormSelector,
)
