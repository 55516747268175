import PropTypes from 'prop-types'

import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'

import ActionSelect from './ActionSelect.js'
import WarehouseSelect from './WarehouseSelect.js'
import {setRuleAssignToWarehouse} from '../rulesFunctions.js'
import {useSelector} from '../../../../store.js'
import {warehouseNameSelector} from '../../../../data/warehouses.js'

export default function AssignToWarehouse({
  id,
  index,
  action: {
    data: {warehouse_id},
  },
  isEditing,
}) {
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID: warehouse_id}),
  )

  if (!isEditing) {
    if (warehouse_id === null) {
      return null
    }

    return (
      <div>
        <strong className="margin-right-5">Assign to warehouse</strong>
        <ul className="list list--tags-lg inline-block">
          <li className="list__item--inline rule-criteria">
            <strong className="fs-00">{warehouseName}</strong>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="inline-block">
      <div className="inline-block v-align-middle margin-bottom-10">
        <ActionSelect ruleID={id} index={index} />
      </div>
      <div className="inline-block v-align-middle margin-bottom-10">
        <strong className="op-50 lh-xl v-align-middle margin-right-10">
          →
        </strong>
        <WarehouseSelect
          selectedWarehouseID={warehouse_id}
          onChange={(warehouseID) => {
            setRuleAssignToWarehouse(id, index, warehouseID)
          }}
        />
      </div>
    </div>
  )
}

AssignToWarehouse.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
}
