import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Icon({iconClassName, hint}) {
  return (
    <div
      className="list__item--line-detail inline-block tooltip--top"
      data-hint={hint}
    >
      <span className={classNames('fs-n1', iconClassName)} aria-hidden="true" />
    </div>
  )
}

Icon.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
}
