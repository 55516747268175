import {useSelector} from 'react-redux'

import ListItemsCount from '../../../../common/components/List/ListItemsCount.js'
import {
  countSelector,
  orderListQuerySelector,
} from '../../../OrderListPage/orderListSelectors.js'

export default function OrderListItemsCount() {
  const {limit, offset} = useSelector(orderListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <ListItemsCount
      limit={limit}
      offset={offset}
      count={count}
      thing="Orders"
    />
  )
}
