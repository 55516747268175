import {createSelector} from 'reselect'
import reduce from 'lodash/reduce.js'
import isEmpty from 'lodash/isEmpty.js'
import sortBy from 'lodash/sortBy.js'

import {NEW_ID} from '../../../../../common/constants/index.js'
import {isPresent} from '../../../../../common/utils.js'
import {
  AMAZON_SHIPPER,
  PITNEY,
  PITNEY_MERCHANT,
  SHIPPER_OPTIONS,
  VISIBLE_USPS,
} from '../../../../../common/constants/ShipperNames.js'
import {
  canUsePitneySelector,
  canUsePitneyMerchantSelector,
  canUseVisibleUSPSSelector,
  canUseAmazonShipperSelector,
} from '../../../../../data/company.js'
import {activeShippersSelector} from '../../../../../data/shippers.js'
import formsSelectors from './forms/index.js'

export const shippersSettingsSelector = createSelector(
  (state) => state.ui.settings,
  ({shippers}) => shippers,
)

export function shipperFormSelector(state, {shipperID}) {
  return state.ui.settings.shippers.forms[shipperID]
}

export const sortedFormsSelector = createSelector(
  (state) => state.ui.settings.shippers.forms,
  (forms) => sortBy(forms, 'id'),
)

export const newFormSelector = createSelector(
  (state) => state.ui.settings.shippers.forms,
  (forms) => forms.new,
)

export const newShipperOptionsSelector = createSelector(
  activeShippersSelector,
  canUsePitneySelector,
  canUsePitneyMerchantSelector,
  canUseVisibleUSPSSelector,
  canUseAmazonShipperSelector,
  (
    shippers,
    canUsePitney,
    canUsePitneyMerchant,
    canUseVisibleUSPS,
    canUseAmazonShipper,
  ) => {
    let options = SHIPPER_OPTIONS

    const hasPitney = !!shippers.find((shipper) => shipper.vendor === PITNEY)

    if (
      hasPitney ||
      !canUsePitney ||
      canUsePitneyMerchant ||
      canUseVisibleUSPS
    ) {
      options = options.filter((option) => option.value !== PITNEY)
    }

    if (!canUsePitneyMerchant) {
      options = options.filter((option) => option.value !== PITNEY_MERCHANT)
    }

    const hasVisibleUSPS = !!shippers.find(
      (shipper) => shipper.vendor === VISIBLE_USPS,
    )

    if (
      hasVisibleUSPS ||
      !canUseVisibleUSPS ||
      canUsePitney ||
      canUsePitneyMerchant
    ) {
      options = options.filter((option) => option.value !== VISIBLE_USPS)
    }

    if (!canUseAmazonShipper) {
      options = options.filter((option) => option.value !== AMAZON_SHIPPER)
    }

    return options
  },
)

export function errorsSelector(state, {form}) {
  const errors = {}

  if (form.id !== NEW_ID && !isPresent(form.name)) {
    errors.name = 'Nickname is required'
  }

  if (form.vendor) {
    return {
      ...errors,
      ...formsSelectors[form.vendor].errorsSelector(form),
    }
  }

  return errors
}

export function hasChangedSelector(state, {form}) {
  const hasChanged = state.ui.settings.shippers.hasChanged

  if (form.id) {
    return hasChanged[form.id] || {}
  }

  return {}
}

export function errorsOfChangedSelector(state, {form}) {
  const errors = errorsSelector(state, {form})
  const hasChanged = hasChangedSelector(state, {form})

  return reduce(
    errors,
    (e, value, key) => {
      if (hasChanged[key]) {
        e[key] = value
      }

      return e
    },
    {},
  )
}

export function hasErrorsSelector(state, {form}) {
  const errors = errorsSelector(state, {form})

  return !isEmpty(errors)
}

export const shippersHashSelector = createSelector(
  shippersSettingsSelector,
  ({editID}) => {
    if (editID) {
      return `#/settings/shipper/${editID}`
    }

    return '#/settings/shipper'
  },
)
