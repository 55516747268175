import {takeEvery, put, call} from 'redux-saga/effects'

import {logError} from '../../../../common/error.js'
import {closeModal} from '../../../actions/ui/index.js'
import {viewDocument} from '../labelViewForm.js'
import {
  VIEW_PICK_PACK_DOCUMENT,
  setErrorMessage,
} from '../../../actions/ui/modals/printPickPackModal.js'

export function* viewPickPackDocumentWorker(action) {
  try {
    yield put(setErrorMessage(null))

    const pdfLink = action.payload
    yield call(viewDocument, pdfLink)

    yield put(closeModal())
  } catch (err) {
    const errorMessage = `Could not mark documents as printed. ${err.message}`
    logError(err, errorMessage)
    yield put(setErrorMessage(errorMessage, err))
  }
}

export default function* printPickPackModal() {
  yield takeEvery(VIEW_PICK_PACK_DOCUMENT, viewPickPackDocumentWorker)
}
