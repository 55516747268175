export const SingleAddressLayouts = [
  {value: '2_5x0_75', display: '2.5" x 0.75"'},
  {value: '3_5x1', display: '3.5" x 1"'},
  {value: '3_5x1_125', display: '3.5" x 1.125"'},
]

export const MultipleAddressLayouts = [
  {value: '30up', display: '30-up', labelsPerPage: 30},
  {value: '10up', display: '10-up', labelsPerPage: 10},
  {value: '6up', display: '6-up', labelsPerPage: 6},
]
