export const ORDER_ALTERNATE_SHIP_FROM_ADDRESS = Symbol(
  'ORDER_ALTERNATE_SHIP_FROM_ADDRESS',
)
export const ORDER_SHIPPING_ADDRESS = Symbol('ORDER_SHIPPING_ADDRESS')
export const ORDER_BILLING_ADDRESS = Symbol('ORDER_BILLING_ADDRESS')
export const RETURN_CUSTOMER_ADDRESS = Symbol('RETURN_CUSTOMER_ADDRESS')
export const RETURN_TO_ADDRESS = Symbol('RETURN_TO_ADDRESS')
export const BULK_RETURN_TO_ADDRESS = Symbol('BULK_RETURN_TO_ADDRESS')
export const CREATE_ORDER_SHIPPING_ADDRESS = Symbol(
  'CREATE_ORDER_SHIPPING_ADDRESS',
)
export const CREATE_ORDER_BILLING_ADDRESS = Symbol(
  'CREATE_ORDER_BILLING_ADDRESS',
)
export const CREATE_RETURN_ORDER_CUSTOMER_ADDRESS = Symbol(
  'CREATE_RETURN_ORDER_CUSTOMER_ADDRESS',
)
export const CREATE_RETURN_ORDER_OTHER_ADDRESS = Symbol(
  'CREATE_RETURN_ORDER_OTHER_ADDRESS',
)
export const QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS = Symbol(
  'QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS',
)
export const QUICK_SHIP_SHIPPING_ADDRESS = Symbol('QUICK_SHIP_SHIPPING_ADDRESS')
export const EDIT_WAREHOUSE_ADDRESS = Symbol('EDIT_WAREHOUSE_ADDRESS')
export const EDIT_SUPPLIER_ADDRESS = Symbol('EDIT_SUPPLIER_ADDRESS')

export const ERROR_ADDRESS_STATUS = 'error'
export const UNVALIDATED_ADDRESS_STATUS = 'unvalidated'
export const WARNING_ADDRESS_STATUS = 'warning'
export const VALIDATED_ADDRESS_STATUS = 'validated'
export const ADDRESS_STATUS_CHOICES = [
  ERROR_ADDRESS_STATUS,
  UNVALIDATED_ADDRESS_STATUS,
  WARNING_ADDRESS_STATUS,
  VALIDATED_ADDRESS_STATUS,
]
export const ADDRESS_STATUS_DISPLAYS = {
  [ERROR_ADDRESS_STATUS]: 'Invalid Addresses',
  [WARNING_ADDRESS_STATUS]: 'Addresses with Warnings',
  [VALIDATED_ADDRESS_STATUS]: 'Valid Addresses',
}
export const ADDRESS_STATUS_OPTIONS = ADDRESS_STATUS_CHOICES.map((value) => ({
  value,
  display: ADDRESS_STATUS_DISPLAYS[value],
}))
