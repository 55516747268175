import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  defaultWarehouseSelector,
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../../../data/warehouses.js'
import {useSelector} from '../../../../store.js'

export default function WarehouseSelect({onChange, selectedWarehouseID}) {
  const defaultWarehouse = useSelector(defaultWarehouseSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)

  useEffect(() => {
    if (!selectedWarehouseID && defaultWarehouse) {
      onChange(defaultWarehouse.id)
    }
  }, [defaultWarehouse])

  return (
    <select
      className="select"
      id="warehouse_select"
      value={selectedWarehouseID || ''}
      onChange={(event) => onChange(Number(event.target.value))}
    >
      {warehouses.map((warehouse) => (
        <option value={warehouse.id} key={warehouse.id}>
          {getWarehouseName(warehouse)}
        </option>
      ))}
    </select>
  )
}

WarehouseSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedWarehouseID: PropTypes.number,
}
