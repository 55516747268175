import {createSelector} from 'reselect'

export const formsSelector = createSelector(
  (state) => state.ui || {},
  ({forms}) => forms || {},
)

export function formSelector(state, {key}) {
  const forms = formsSelector(state)

  return forms[key]
}
