import {createSelector} from 'reselect'

import {NEW_ID} from '../../../../common/constants/index.js'

export function returnOrderCodesSettingsSelector(state) {
  return state.ui.settings.returnOrderCodes
}

export const returnOrderCodesHashSelector = createSelector(
  returnOrderCodesSettingsSelector,
  ({currentReturnOrderCodeID, isNew}) => {
    if (isNew) {
      return `#/settings/return_order_codes/${NEW_ID}`
    }
    if (currentReturnOrderCodeID) {
      return `#/settings/return_order_codes/${currentReturnOrderCodeID}`
    }

    return '#/settings/return_order_codes'
  },
)
