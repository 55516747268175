import moment from 'moment'

import {mapShipperToTrackingCarrier} from '../../../../../common/constants/ShipperNames.js'
import apiverson from '../../../../../common/apiverson.js'
import {isPresent, isNumeric} from '../../../../../common/utils.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'
import baseStrategy from './baseStrategy.js'

export default class trackingStrategy extends baseStrategy {
  static title = 'Create Tracking Numbers'
  static requiredColumns = ['order_number', 'tracking_number', 'carrier_name']

  static getExampleRows() {
    return () =>
      apiverson
        .get('/order/', {limit: 5, status: 'shipped'})
        .then(({json: data}) => {
          const rows = [
            [
              'Order Number *Req',
              'Shipment Date',
              'Tracking Number *Req',
              'Carrier Name *Req',
              'Shipping Method',
              'Cost',
              'Notify Ship To',
              'Notify Bill To',
              'Notify Cart',
            ],
          ]

          data.order.forEach((order) => {
            const shippingInfo = order.shipping_info || {}

            rows.push([
              order.order_number,
              shippingInfo.ship_date,
              shippingInfo.tracking_number,
              shippingInfo.carrier_name,
              shippingInfo.shipping_method,
              shippingInfo.cost,
              'false',
              'false',
              'false',
            ])
          })

          return rows
        })
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      order_number: find(/^order.number$/i),
      ship_date: find(/^ship(ment)?.date$/i),
      tracking_number: find(/^tracking.number$/i),
      carrier_name: find(/^carrier(.name)?$/i),
      shipping_method: find(/^shipping.method$/i),
      cost: find(/^cost$/i),
      notify_ship_to: find(/^(email|notify).ship.to$/i),
      notify_bill_to: find(/^(email|notify).bill.to$/i),
      notify_cart: find(/^notify.cart$/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static formatShipDate(shipDate) {
    if (!shipDate) {
      return undefined
    }

    const shipMoment = moment(new Date(shipDate))
    if (shipMoment.isValid()) {
      return shipMoment.format()
    }

    return shipDate
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()
    const notifyShipTo = this.toBoolean(row[columnsByName.notify_ship_to])
    const notifyBillTo = this.toBoolean(row[columnsByName.notify_bill_to])
    const notifyCart = this.toBoolean(row[columnsByName.notify_cart])
    const shipperName = row[columnsByName.carrier_name] || ''
    const carrierName = mapShipperToTrackingCarrier(shipperName) || ''

    data.payload = {
      order_number: row[columnsByName.order_number],
      ship_date: this.formatShipDate(row[columnsByName.ship_date]),
      tracking_number: row[columnsByName.tracking_number],
      carrier_name: carrierName,
      custom_shipper: carrierName === 'other' ? shipperName || 'other' : '',
      shipping_method: row[columnsByName.shipping_method],
      cost: Number(row[columnsByName.cost]) || undefined,
      notify_ship_to:
        columnsByName.notify_ship_to !== -1 ? notifyShipTo : undefined,
      notify_bill_to:
        columnsByName.notify_bill_to !== -1 ? notifyBillTo : undefined,
      notify_cart: columnsByName.notify_cart !== -1 ? notifyCart : undefined,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'order_number', 'Order Number is required')

    if (!isPresent(payload.tracking_number)) {
      errors.tracking_number = 'Tracking Number is required'
    } else {
      delete errors.tracking_number
    }

    if (!isPresent(payload.carrier_name)) {
      errors.carrier_name = 'Carrier Name is required'
    } else {
      delete errors.carrier_name
    }

    if (!(isNumeric(payload.cost) || payload.cost === undefined)) {
      errors.cost = 'Cost must be a number'
    } else {
      delete errors.cost
    }

    if (payload.ship_date && !moment(new Date(payload.ship_date)).isValid()) {
      errors.ship_date = 'Invalid date'
    } else {
      delete errors.ship_date
    }

    return errors
  }

  static save(data) {
    return () => {
      const orderNumber = encodeURIComponent(data.payload.order_number)
      const url = `/order/${encodeURIComponent(orderNumber)}/shipping_info/`

      if (data.payload.carrier_name === 'other') {
        data.payload.carrier_name = data.payload.custom_shipper || 'other'
      }

      delete data.payload.custom_shipper
      delete data.payload.order_number

      return apiverson.post(url, data.payload)
    }
  }
}
