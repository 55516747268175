import {useMemo} from 'react'
import subDays from 'date-fns/subDays'

import DatePicker from '../../../../../common/components/DatePicker.js'
import {useSelector} from '../../../../../store.js'
import {
  errorsSelector,
  updateUPSAuthForm,
  upsAuthFormSelector,
} from './UPSAuthForm.js'
import TextInput from '../../../../../common/components/TextInput.js'

export default function InvoiceForm() {
  const {now, ninetyDaysAgo} = useMemo(() => {
    const now = new Date()

    return {
      now,
      ninetyDaysAgo: subDays(now, 90),
    }
  }, [])
  const form = useSelector(upsAuthFormSelector)
  const errors = useSelector(errorsSelector)

  return (
    <div>
      <div className="alert alert--warning margin-bottom-20">
        <dl className="list margin-bottom-5">
          <dt className="list__title fs-n0 margin-bottom-15">
            Notes on selecting which invoice to use:
          </dt>
          <dd className="list__item margin-bottom-10">
            <ol className="margin-left-15 margin-bottom-0">
              <li className="list__item fs-n0 margin-bottom-10">
                Use an invoice received within the past three weeks
              </li>
              <li className="list__item fs-n0 margin-bottom-10">
                If you have not received an invoice within the past three weeks,
                use one from the past 45 days
              </li>
              <li className="list__item fs-n0 margin-bottom-0">
                If you have not received an invoice within the past 45 days, use
                one from the past 90 days
              </li>
            </ol>
          </dd>
          <dd className="list__item margin-bottom-0">
            <strong>
              <a
                className="fs-n0"
                href="https://support.ordoro.com/setting-up-ups-in-ordoro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </strong>{' '}
            →
          </dd>
        </dl>
      </div>
      <div className="form-list-item bigger margin-bottom-20">
        <TextInput
          label="Invoice Number"
          id="name_invoice_number"
          value={form.invoiceNumber}
          onChange={(invoiceNumber) =>
            updateUPSAuthForm({invoiceNumber, hasChanged_invoiceNumber: true})
          }
          errorMessage={form.hasChanged_invoiceNumber && errors.invoiceNumber}
          required
        />
      </div>
      <div className="form-list-item bigger margin-bottom-10">
        <DatePicker
          label="Invoice Date"
          id="name_invoice_date"
          name="name_invoice_date"
          minDate={ninetyDaysAgo}
          maxDate={now}
          onChange={(date) =>
            updateUPSAuthForm({invoiceDate: date, hasChanged_invoiceDate: true})
          }
          selected={form.invoiceDate}
          errorMessage={form.hasChanged_invoiceDate && errors.invoiceDate}
          required
        />
      </div>
      <div className="form-list-item bigger margin-bottom-20">
        <TextInput
          label="Control ID (if present on invoice)"
          id="control_id"
          value={form.controlID}
          onChange={(controlID) => updateUPSAuthForm({controlID})}
        />
      </div>
      <div className="form-list-item bigger margin-bottom-20">
        <TextInput
          label="Total charges from this period"
          id="invoice_charges"
          value={form.invoiceAmount}
          onChange={(invoiceAmount) =>
            updateUPSAuthForm({invoiceAmount, hasChanged_invoiceAmount: true})
          }
          errorMessage={form.hasChanged_invoiceAmount && errors.invoiceAmount}
          required
        />
      </div>
    </div>
  )
}
