import format from 'date-fns/format'
import omit from 'lodash/omit.js'

import {Address, pickAddress} from '../../../../common/address.js'
import apiverson from '../../../../common/apiverson.js'
import {getRealDate} from '../../../../common/date.js'
import {
  areAnyWarehousesConfiguredSelector,
  defaultWarehouseSelector,
  setWarehouse,
  warehouseAddressSelector,
} from '../../../../data/warehouses.js'
import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  getState,
} from '../../../../store.js'
import {
  setOrderStateAndEnsureProductsLoaded,
  orderShippingAddressSelector,
  shipFromAddressSelector,
  orderSelector,
} from '../../../../data/orders.js'
import {waitForOrderToBeCreated} from '../../../CreateOrderPage/createOrderActions.js'
import {
  createLabelImageDisabledSelector,
  hasLabelSelector,
  insufficientBalanceSelector,
  labelErrorsSelector,
  labelInfoIDForOrderAndLabelTypeSelector,
  labelLoadingSelector,
  labelShipperIDSelector,
} from '../../../../data/labelInfos/index.js'
import {showPostageBalanceModal} from '../../../LabelConfig/Modals/PostageBalanceModal.js'
import {createLabelImages} from '../../../../data/labelInfos/labelRequest.js'
import {supplierAddressSelector} from '../../../../data/suppliers.js'
import {COMMUNICATION_DROPSHIPMENT} from '../../../../common/constants/Suppliers.js'
import {isAddressComplete} from '../../../../common/utils.js'
import {SHIPPED} from '../../../../common/constants/Orders.js'
import {setInitialStateOfBusiness} from '../../../../data/company.js'
import {autoCreatePitneyShipper} from '../../../../data/shippers.js'
import api from '../../../../common/api.js'

export const MODAL_FORM = 'QUICK_SHIP_MODAL'
export const QS_LOADING = 'QS_LOADING'
export const QS_SHIP_FROM_PANEL = 'QS_SHIP_FROM_PANEL'
export const QS_SHIP_TO_PANEL = 'QS_SHIP_TO_PANEL'
export const QS_CREATE_LABEL_PANEL = 'QS_CREATE_LABEL_PANEL'
export const QS_PRINT_LABEL_PANEL = 'QS_PRINT_LABEL_PANEL'
export const QS_ALTERNATE_SHIP_FROM_ADDRESS = 'QS_ALTERNATE_SHIP_FROM_ADDRESS'
export const QS_SETUP_WAREHOUSE = 'QS_SETUP_WAREHOUSE'

export function showQuickShipModal() {
  const warehouse = defaultWarehouseSelector(getState())
  const areAnyWarehousesConfigured =
    areAnyWarehousesConfiguredSelector(getState())

  if (!warehouse) {
    return
  }

  setForm(MODAL_FORM, {
    currentPanel: QS_SHIP_FROM_PANEL,
    alternate_ship_from_address: areAnyWarehousesConfigured
      ? new Address()
      : new Address(warehouse.address),
    shipFromType: areAnyWarehousesConfigured
      ? 'warehouse_id'
      : QS_SETUP_WAREHOUSE,
    warehouseID: warehouse.id,
    supplierID: null,
    shipping_address: new Address(),
    orderNumber: null,
    isSaving: false,
    serverError: null,
  })
}

export function updateQuickShipForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function quickShipLabelInfoIDSelector(state) {
  const {orderNumber} = modalFormSelector(state)

  if (!orderNumber) {
    return null
  }

  return labelInfoIDForOrderAndLabelTypeSelector(state, {
    orderNumber,
    labelType: 'shipping',
  })
}

export function quickShipHasLabelSelector(state) {
  const labelInfoID = quickShipLabelInfoIDSelector(state)

  return labelInfoID ? hasLabelSelector(getState(), {labelInfoID}) : false
}

export function quickShipIsShippedSelector(state) {
  const {orderNumber} = modalFormSelector(state)

  if (!orderNumber) {
    return false
  }

  const order = orderSelector(state, {orderNumber})

  return order ? order.status === SHIPPED : false
}

export function quickShipNoLabelToCreateSelector(state) {
  return quickShipHasLabelSelector(state) || quickShipIsShippedSelector(state)
}

export function quickShipFromAddressSelector(state) {
  const {
    shipFromType,
    warehouseID,
    supplierID,
    alternate_ship_from_address,
    orderNumber,
  } = modalFormSelector(state)

  if (orderNumber) {
    return shipFromAddressSelector(state, {orderNumber})
  }

  if (shipFromType === 'warehouse_id') {
    return warehouseAddressSelector(state, {warehouseID})
  } else if (shipFromType === 'supplier_id') {
    return supplierAddressSelector(getState(), {
      supplierID,
      type: COMMUNICATION_DROPSHIPMENT,
    })
  } else if (shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS) {
    return alternate_ship_from_address
  }
}

export function quickShipToAddressSelector(state) {
  const {shipping_address, orderNumber} = modalFormSelector(state)

  if (orderNumber) {
    return orderShippingAddressSelector(state, {orderNumber})
  } else {
    return shipping_address
  }
}

export function errorsSelector(state) {
  const errors = {}
  const {
    currentPanel,
    shipFromType,
    warehouseID,
    supplierID,
    alternate_ship_from_address,
    shipping_address,
    orderNumber,
  } = modalFormSelector(state)

  if (
    (!warehouseID && shipFromType === 'warehouse_id') ||
    (!supplierID && shipFromType === 'supplier_id') ||
    (!isAddressComplete(alternate_ship_from_address, {
      name_or_company: true,
      name: false,
      state: false,
      zip: false,
    }) &&
      shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS) ||
    (!isAddressComplete(alternate_ship_from_address, {
      phone: true,
      email: true,
    }) &&
      shipFromType === QS_SETUP_WAREHOUSE)
  ) {
    errors.shipFrom = 'Ship From is required'
  }

  if (errors.shipFrom && currentPanel === QS_SHIP_FROM_PANEL) {
    errors.preventSave = true
  }

  if (
    !isAddressComplete(shipping_address, {
      name_or_company: true,
      name: false,
      state: false,
      zip: false,
    })
  ) {
    errors.shipTo = 'Ship To is required'
  }

  if (errors.shipTo && currentPanel === QS_SHIP_TO_PANEL) {
    errors.preventSave = true
  }

  if (orderNumber) {
    const labelInfoID = quickShipLabelInfoIDSelector(state)

    const isDisabled = createLabelImageDisabledSelector(state, {
      labelInfoID,
    })
    const labelErrors = labelErrorsSelector(state, {labelInfoID})

    const noLabelToCreate = quickShipNoLabelToCreateSelector(state)

    if (!noLabelToCreate) {
      if (labelErrors.length) {
        errors.createLabel = labelErrors.length ? labelErrors : ''
      }

      if (isDisabled && currentPanel === QS_CREATE_LABEL_PANEL) {
        errors.preventSave = true
      }
    }
  }

  return errors
}

export function isLoadingSelector(state) {
  const {currentPanel, isSaving, orderNumber} = modalFormSelector(state)

  if (isSaving) {
    return true
  }

  if (currentPanel === QS_CREATE_LABEL_PANEL && orderNumber) {
    const labelInfoID = quickShipLabelInfoIDSelector(state)

    return labelLoadingSelector(state, {labelInfoID})
  }
}

export function nextQuickShipPanel() {
  const {currentPanel} = modalFormSelector(getState())

  if (currentPanel === QS_SHIP_FROM_PANEL) {
    processShipFrom()
  } else if (currentPanel === QS_SHIP_TO_PANEL) {
    createQuickOrder()
  } else if (currentPanel === QS_CREATE_LABEL_PANEL) {
    createQuickLabel()
  } else {
    closeModal()
  }
}

export async function processShipFrom() {
  const {alternate_ship_from_address, shipFromType, warehouseID} =
    modalFormSelector(getState())

  try {
    updateQuickShipForm({isSaving: true, serverError: null})

    if (shipFromType === QS_SETUP_WAREHOUSE && warehouseID) {
      const {json} = await api.put(
        `/warehouse/${warehouseID}/`,
        omit(pickAddress(alternate_ship_from_address), ['company', 'fax']),
      )

      setWarehouse(json)

      await autoCreatePitneyShipper()

      await setInitialStateOfBusiness()

      updateQuickShipForm({
        shipFromType: 'warehouse_id',
        alternate_ship_from_address: new Address(),
      })
    }

    updateQuickShipForm({currentPanel: QS_SHIP_TO_PANEL})
  } catch (err) {
    updateQuickShipForm({serverError: err.message || err.error_message})
  } finally {
    updateQuickShipForm({isSaving: false})
  }
}

export async function createQuickOrder() {
  const {
    alternate_ship_from_address,
    shipFromType,
    warehouseID,
    supplierID,
    shipping_address,
  } = modalFormSelector(getState())
  const date = getRealDate()

  try {
    updateQuickShipForm({isSaving: true, serverError: null})

    const params = {
      order_id: format(date, 'yyyyMMdd-HHmmss'),
      order_date: date.toISOString(),
      shipping_address: pickAddress(shipping_address),
      billing_address: pickAddress(shipping_address),
    }

    if (shipFromType === 'warehouse_id') {
      params.warehouse_id = warehouseID
    } else if (shipFromType === 'supplier_id') {
      const address = supplierAddressSelector(getState(), {
        supplierID,
        type: COMMUNICATION_DROPSHIPMENT,
      })
      params.alternate_ship_from_address = pickAddress(address)
    } else if (shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS) {
      params.alternate_ship_from_address = pickAddress(
        alternate_ship_from_address,
      )
    }

    const {
      json: {parent_order_number: initialParentOrderNumber},
    } = await apiverson.post('/order/', params)

    const order = await waitForOrderToBeCreated(initialParentOrderNumber)
    const orderNumber = order.order_number

    await setOrderStateAndEnsureProductsLoaded(order)

    updateQuickShipForm({currentPanel: QS_CREATE_LABEL_PANEL, orderNumber})
  } catch (err) {
    updateQuickShipForm({serverError: err.message || err.error_message})
  } finally {
    updateQuickShipForm({isSaving: false})
  }
}

export async function createQuickLabel() {
  if (
    quickShipHasLabelSelector(getState()) ||
    quickShipIsShippedSelector(getState())
  ) {
    updateQuickShipForm({currentPanel: QS_PRINT_LABEL_PANEL})

    return
  }

  try {
    updateQuickShipForm({isSaving: true, serverError: null})

    const labelInfoID = quickShipLabelInfoIDSelector(getState())
    const insufficientBalance = insufficientBalanceSelector(getState(), {
      labelInfoID,
    })

    if (insufficientBalance) {
      const shipperID = labelShipperIDSelector(getState(), {labelInfoID})

      showPostageBalanceModal(shipperID)

      return
    }

    await createLabelImages([labelInfoID], {displayProcessLabelModal: false})

    if (quickShipHasLabelSelector(getState())) {
      updateQuickShipForm({currentPanel: QS_PRINT_LABEL_PANEL})
    }
  } catch (err) {
    updateQuickShipForm({serverError: err.message || err.error_message})
  } finally {
    updateQuickShipForm({isSaving: false})
  }
}
