import {useEffect, useMemo} from 'react'
import classNames from 'classnames'

import {
  formSelector,
  getState,
  updateForm,
  useAutoForm,
  useSelector,
} from '../../../../store.js'
import {filterOptions} from '../../../../common/tokenizeOptions.js'
import {showEditTagModal} from '../../../../common/components/Modal/EditTagModal.js'
import {
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_OR,
  TAG_TYPE__ORDER,
} from '../../../../common/constants/Tags.js'
import {showDeleteTagsModal} from '../../../../common/components/Modal/DeleteTagsModal.js'
import {
  orderTagOptionsSelector,
  orderTagOptionTokensSelector,
} from '../../../../data/orderTags.js'
import {
  modalFormSelector,
  updateModalForm,
} from './orderListFilterModalFunctions.js'
import {hasOrderManageTagPermissionSelector} from '../../../../data/me.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import Radio from '../../../../common/components/Radio.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'

const formName = 'FILTER_PANEL__TAG'

function filter(formName, options, optionTokens) {
  const form = formSelector(getState(), {formName})

  if (!form) {
    return
  }

  if (!form.text) {
    updateForm(formName, {
      results: [...options],
      hasFoundNothing: false,
    })

    return
  }

  const results = filterOptions(form.text, optionTokens)

  updateForm(formName, {
    results: results,
    hasFoundNothing: results.length === 0,
  })
}

export function clearTagFilterPanelSearch() {
  updateForm(formName, {text: ''})
}

export default function TagFilterPanel() {
  const form = useAutoForm(formName, {
    text: '',
    results: [],
    hasFoundNothing: false,
    showAll: false,
  })
  const options = useSelector(orderTagOptionsSelector)
  const optionTokens = useSelector(orderTagOptionTokensSelector)
  const modalForm = useSelector(modalFormSelector)
  const hasManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const selected = modalForm.orderTagFilters
  const excluded = modalForm.exclude_tags
  const totalSelected = selected.length + excluded.length

  useEffect(() => {
    filter(formName, options, optionTokens)
  }, [form.text, options])

  const [optionsToShow, hiddenSelected] = useMemo(
    () =>
      form.results.reduce(
        (prev, option, index) => {
          if (form.showAll || index < 6) {
            prev[0].push(option)
          } else if (
            selected.includes(option.entity.text) ||
            excluded.includes(option.entity.text)
          ) {
            prev[1].push(option.entity.text)
          }
          return prev
        },
        [[], []],
      ),
    [form.results, form.showAll, selected, excluded],
  )

  return (
    <div className="wrap--modal-filters wrap--modal-filters-tag">
      <div className="wrap__modal-subheader margin-bottom-25 flex--justify">
        <div className="flex">
          <h4 className="fs-01 lh-sm uppercase margin-top-0 margin-bottom-0 flex--justify-col margin-right-10">
            <span>
              <strong>Tags</strong>
              {totalSelected > 0 && (
                <PluralBlock count={totalSelected}>
                  <span className="lowercase text--blue margin-left-5">
                    (<Count /> <Plural word="filter" p="s" /> selected)
                  </span>
                </PluralBlock>
              )}
            </span>
          </h4>
        </div>
        {options.length > 6 && (
          <div className="wrap--filter-search w-35">
            <label
              className="label--input-prefix label--search"
              htmlFor="filters_tag_search"
            >
              <input
                className="input--search input--lighter inline-block v-align-middle"
                id="filters_tag_search"
                type="text"
                placeholder="Search for a tag..."
                value={form.text}
                onChange={(event) =>
                  updateForm(formName, {text: event.target.value})
                }
              />
            </label>
          </div>
        )}
      </div>
      {options.length > 0 && (
        <div className="flex margin-bottom-30">
          <div className="wrap--filter-operators margin-right-20 margin-bottom-0">
            <div className="fs-00">
              <strong>Filtering Logic for Tags</strong>
            </div>
            <div className="flex margin-top-15">
              {options.length > 1 && (
                <>
                  <strong className="fs-00 margin-right-10">Has:</strong>
                  <div className="margin-right-15">
                    <Radio
                      mode="fancy"
                      id="id_tags-any"
                      value={TAG_FILTER_OR}
                      checked={modalForm.tagFilterOperator === TAG_FILTER_OR}
                      onChange={() =>
                        updateModalForm({tagFilterOperator: TAG_FILTER_OR})
                      }
                      label="Any"
                    />
                  </div>
                  <div className="margin-right-15">
                    <Radio
                      mode="fancy"
                      id="id_tags-all"
                      value={TAG_FILTER_AND}
                      checked={modalForm.tagFilterOperator === TAG_FILTER_AND}
                      onChange={() =>
                        updateModalForm({tagFilterOperator: TAG_FILTER_AND})
                      }
                      label="All"
                    />
                  </div>
                  <div>
                    <Radio
                      mode="fancy"
                      id="id_tags-only"
                      value={TAG_FILTER_ONLY}
                      checked={modalForm.tagFilterOperator === TAG_FILTER_ONLY}
                      onChange={() =>
                        updateModalForm({tagFilterOperator: TAG_FILTER_ONLY})
                      }
                      label="Only"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {excluded.length > 0 ? (
            <PluralBlock array={excluded}>
              <div className="alert alert--warning align-center margin-bottom-0 flex--justify-col">
                <p className="fs-00 lh-md margin-bottom-0">
                  <strong>
                    You’ve excluded <Count /> <Plural word="tag" /> from your
                    order filter results.
                  </strong>{' '}
                  Orders that have{' '}
                  <Plural s="this tag" p="any of the excluded tags" /> applied
                  to them will not appear in the filtered list.
                </p>
              </div>
            </PluralBlock>
          ) : (
            <div className="alert alert--standard align-center margin-bottom-0 flex--justify-col">
              <p className="fs-00 lh-md margin-bottom-3">
                <strong>
                  Want to exclude certain tags from filtered results?
                </strong>
              </p>
              <p className="fs-n0 lh-md margin-bottom-0">
                Hover over a tag and click on the{' '}
                <span className="fs-01 i-hide i--v-align" aria-hidden="true" />{' '}
                icon to prevent it from appearing in the filtered list.
              </p>
            </div>
          )}
        </div>
      )}

      <div className="wrap--untagged-filter margin-bottom-30">
        <label
          className="label--fancy label--fancy-tag label--fancy-untagged flex"
          htmlFor="untagged"
          style={{borderColor: 'rgb(153, 153, 153)'}}
        >
          <Checkbox
            mode="fancy"
            id="untagged"
            checked={modalForm.untaggedSelected}
            onChange={() =>
              updateModalForm({untaggedSelected: !modalForm.untaggedSelected})
            }
            disabled={selected.length > 0}
          />
          <div className="label__text--fancy flex--justify-col">
            <div>
              <span className="label__pre-text">Untagged Orders</span>
            </div>
          </div>
        </label>
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {optionsToShow.map((option) => {
          const isSelected = selected.includes(option.entity.text)
          const isExcluded = excluded.includes(option.entity.text)
          return (
            <li
              key={option.value}
              className="list__item list__item--modal-filters"
            >
              <label
                className={classNames(
                  'label--fancy label--fancy-tag flex--justify',
                  {
                    disabled: modalForm.untaggedSelected,
                  },
                )}
                htmlFor={`tag_${option.value}`}
                style={
                  isSelected
                    ? {
                        borderColor: option.entity.color,
                        outlineColor: option.entity.color,
                        outlineWidth: '2px',
                        borderStyle: 'solid',
                        outlineStyle: 'solid',
                      }
                    : isExcluded
                      ? {
                          borderColor: '#ff0000',
                          outlineColor: '#ff0000',
                          outlineWidth: '2px',
                          borderStyle: 'solid',
                          outlineStyle: 'solid',
                        }
                      : {
                          borderColor: option.entity.color,
                        }
                }
              >
                <div className="flex w-70">
                  <Checkbox
                    mode="fancy"
                    id={`tag_${option.value}`}
                    checked={isSelected || isExcluded}
                    onChange={() => {
                      if (isSelected || !isExcluded) {
                        // toggle included tag
                        const orderTagFilters = selected.filter(
                          (name) => name !== option.entity.text,
                        )

                        if (orderTagFilters.length === selected.length) {
                          orderTagFilters.push(option.entity.text)
                        }

                        updateModalForm({orderTagFilters})
                      } else {
                        // remove exclude tag (can only add exclude tag in x button)
                        const exclude_tags = excluded.filter(
                          (name) => name !== option.entity.text,
                        )

                        updateModalForm({exclude_tags})
                      }
                    }}
                    disabled={modalForm.untaggedSelected}
                    indeterminate={isExcluded}
                  />
                  <div className="label__text--fancy flex--justify-col">
                    <div>
                      <span className="label__pre-text">{option.display}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames('wrap--tag-buttons flex--justify-col', {
                    'w-25': hasManageTagPermission,
                  })}
                >
                  <div className="flex--justify">
                    <button
                      className="btn btn--link no-underline lh-sm"
                      type="button"
                      title="Exclude tag"
                      onClick={() => {
                        // toggle exclude tag and remove include tag (if any)
                        const exclude_tags = excluded.filter(
                          (name) => name !== option.entity.text,
                        )
                        const orderTagFilters = selected.filter(
                          (name) => name !== option.entity.text,
                        )

                        if (exclude_tags.length === excluded.length) {
                          exclude_tags.push(option.entity.text)
                        }

                        updateModalForm({exclude_tags, orderTagFilters})
                      }}
                    >
                      <span
                        className={classNames('fs-01', {
                          'i-show': isExcluded,
                          'i-hide': !isExcluded,
                        })}
                        aria-hidden="true"
                      />
                    </button>
                    {hasManageTagPermission && (
                      <>
                        <button
                          className="btn btn--link no-underline"
                          type="button"
                          title="Edit tag"
                          onClick={() =>
                            showEditTagModal(TAG_TYPE__ORDER, option.value)
                          }
                        >
                          <span
                            className="i-pencil fs-00"
                            aria-hidden="true"
                          ></span>
                        </button>
                        <button
                          className="btn btn--link no-underline"
                          type="button"
                          title="Delete tag"
                          onClick={() =>
                            showDeleteTagsModal(TAG_TYPE__ORDER, [option.value])
                          }
                        >
                          <span
                            className="i-trash fs-00"
                            aria-hidden="true"
                          ></span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </label>
            </li>
          )
        })}
        {form.text !== '' && form.hasFoundNothing && (
          <li className="list__item list__item--modal-filters list__item--modal-filters-no-results fs-01">
            <strong>No tags match the current search terms.</strong>
          </li>
        )}
      </ul>
      {form.results.length > 6 && (
        <div className="wrap--filter-expand-btn">
          <button
            className="btn btn--link no-underline"
            type="button"
            onClick={() => updateForm(formName, {showAll: !form.showAll})}
          >
            <span
              className="i--expand fs-01 lh-0 v-align-middle margin-right-3"
              aria-hidden="true"
            />
            <span className="fs-n0">
              {form.showAll ? 'Show Less' : 'Show All'}
            </span>
            {hiddenSelected.length > 0 && (
              <PluralBlock array={hiddenSelected}>
                <span className="fs-n0 text--blue margin-left-5">
                  (<Count /> hidden <Plural p="filters are" s="filter is" />{' '}
                  selected)
                </span>
              </PluralBlock>
            )}
          </button>
        </div>
      )}
    </div>
  )
}
