import PropTypes from 'prop-types'
import {splitOz} from '../../../../common/weight.js'
import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueWeightDisplay({condition}) {
  const option = getConditionOption(condition)

  const split = splitOz(option.getValue(condition))

  return (
    <strong className="rule-criteria">
      <span>
        {split.lb} lb {split.oz} oz
      </span>
    </strong>
  )
}

ConditionValueWeightDisplay.propTypes = {
  condition: PropTypes.object.isRequired,
}
