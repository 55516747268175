import PropTypes from 'prop-types'

import {TagsShape} from '../../../PropTypes.js'

import Tag from './Tag.js'

export default function AssignedTags({
  tags,
  className,
  hasManageTagOnOrderPermission,
  onRemove,
}) {
  return (
    <ul className={`list list--tags ${className || ''}`}>
      {tags.map((tag) => (
        <Tag
          key={tag.id}
          tag={tag}
          onRemove={hasManageTagOnOrderPermission ? onRemove : null}
        />
      ))}
    </ul>
  )
}

AssignedTags.propTypes = {
  tags: TagsShape.isRequired,
  className: PropTypes.string,
  hasManageTagOnOrderPermission: PropTypes.bool,
  onRemove: PropTypes.func,
}
