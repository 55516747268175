import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'

export function Dropdown({
  is = 'div',
  label,
  dropdown,
  children,
  className,
  buttonClassName,
  buttonLabelClassName,
  right,
  currentDropdown,
  noInnerScroll,
  dispatch: _dispatch,
  ...props
}) {
  const Component = is || 'div'

  const isOpen = currentDropdown === dropdown

  return (
    <Component
      className={classNames('wrap--dropdown', {open: isOpen}, className)}
      data-dropdown={dropdown}
      {...props}
    >
      <button
        className={classNames('btn btn--dropdown', buttonClassName)}
        type="button"
      >
        <span className={classNames('btn__text', buttonLabelClassName)}>
          {label}
        </span>
      </button>
      <div
        className={classNames('wrap--inner-dropdown', {
          'wrap--inner-dropdown-right': right,
        })}
      >
        <ul
          className={classNames('list--dropdown', {
            'list--dropdown-no-inner-scroll': noInnerScroll,
          })}
        >
          {isOpen && children}
        </ul>
      </div>
    </Component>
  )
}

Dropdown.propTypes = {
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  label: PropTypes.node.isRequired,
  dropdown: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonLabelClassName: PropTypes.string,
  right: PropTypes.bool,
  currentDropdown: PropTypes.string,
  noInnerScroll: PropTypes.bool,
  dispatch: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    currentDropdown: currentDropdownSelector(state),
  }
}

export default connect(mapStateToProps)(Dropdown)
