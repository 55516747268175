import {AWAITING_FULFILLMENT} from '../../../../common/constants/Orders.js'
import {
  allSupplierOptionsSelector,
  supplierOptionTokensSelector,
} from '../../../../data/suppliers.js'
import {useDropshippingSelector} from '../../../../data/company.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  modalFormSelector,
  SUPPLIER_FILTER_PANEL,
  updateModalForm,
} from './poListFilterModalFunctions.js'

export default function SupplierFilterPanel() {
  const form = useSelector(modalFormSelector)
  const supplierOptions = useSelector(allSupplierOptionsSelector)
  const useDropshipping = useSelector(useDropshippingSelector)
  const supplierOptionTokens = useSelector(supplierOptionTokensSelector)

  if (
    !(
      form.status !== AWAITING_FULFILLMENT &&
      useDropshipping &&
      supplierOptions.length > 0
    )
  ) {
    return null
  }

  return (
    <FilterPanel
      id={SUPPLIER_FILTER_PANEL}
      label="Suppliers"
      searchPlaceholder="Search for a supplier filter..."
      options={supplierOptions}
      optionTokens={supplierOptionTokens}
      selected={form.supplier_id}
      onSelect={(option) => {
        const supplier_id = form.supplier_id.filter((id) => id !== option.value)

        if (supplier_id.length === form.supplier_id.length) {
          supplier_id.push(option.value)
        }

        updateModalForm({supplier_id})
      }}
    />
  )
}
