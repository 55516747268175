import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'
import {SelectOptionsShape} from '../../../common/PropTypes.js'
import Forms from './Forms/index.js'

export default class NewForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      vendor: props.paymentIntegrationOptions[0].value,
    }
  }

  componentDidUpdate() {
    const {vendor} = this.state
    const {paymentIntegrationOptions} = this.props

    if (!paymentIntegrationOptions.find(({value}) => value === vendor)) {
      this.setState({vendor: paymentIntegrationOptions[0].value})
    }
  }

  setVendor = (event) => {
    this.setState({vendor: event.target.value})
  }

  render() {
    const {editID, paymentIntegrationOptions} = this.props
    const {vendor} = this.state

    const Form = vendor && Forms[vendor]
    const isExpanded = editID === NEW_ID

    return (
      <li
        className={classNames(
          'list__item--settings-wrap list__item--add-item-wrap clearfix',
          {expanded: isExpanded},
        )}
      >
        <div className="medium-12 columns button-wrap">
          <p>
            <a
              href={`#/settings/payments/${NEW_ID}`}
              title="Add an integration"
              className="inline-text-button no-underline"
            >
              <span
                className="icon icon-add icon-block x-sm margin-right-5"
                aria-hidden="true"
              />
              <span>Add an integration</span>
            </a>
          </p>
        </div>
        {isExpanded && (
          <div className="settings-list-item-form-wrap padding-top-5 clear-both">
            <fieldset>
              <ul className="form-list margin-bottom-0 margin-left-15">
                <li className="form-list-item margin-bottom-0">
                  <label htmlFor="integration_type" className="margin-bottom-5">
                    Select your integration:
                  </label>
                  <select
                    className="select bigger-select margin-bottom-0"
                    id="integration_type"
                    value={vendor}
                    onChange={this.setVendor}
                  >
                    {paymentIntegrationOptions.map(({value, display}) => (
                      <option key={value} value={value}>
                        {display}
                      </option>
                    ))}
                  </select>
                </li>
              </ul>
              {Form && <Form isNew />}
            </fieldset>
          </div>
        )}
      </li>
    )
  }
}

NewForm.propTypes = {
  editID: PropTypes.string,
  paymentIntegrationOptions: SelectOptionsShape.isRequired,
}
