import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  BOM_LINK_TYPE,
  PRODUCT_LINK_TYPE,
} from '../../../common/constants/Products.js'
import api from '../../../common/api.js'
import {plural} from '../../../common/components/Plural.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {
  getProduct,
  productSelector,
  productComponentsSelector,
  linkedBOMSKUsSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'

export const PRODUCT_COMPONENT_MODEL = 'PRODUCT_COMPONENT_MODEL'

export function showProductComponentModal(
  sku,
  componentSKUs = [],
  quantity = 1,
  linkType = PRODUCT_LINK_TYPE,
) {
  setForm(PRODUCT_COMPONENT_MODEL, {
    sku,
    componentSKUs,
    componentSKU: null,
    quantity: String(quantity),
    linkType,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(PRODUCT_COMPONENT_MODEL, ...args)
}

export function closeModal() {
  removeForm(PRODUCT_COMPONENT_MODEL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[PRODUCT_COMPONENT_MODEL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({quantity, componentSKUs, componentSKU}) => {
    const errors = {}

    if (!isPresent(quantity)) {
      errors.quantity = 'Quantity is required'
      errors.preventSave = true
    } else if (!isPositiveNumeric(quantity)) {
      errors.quantity = 'Quantity must be a positive number'
      errors.preventSave = true
    }

    if (componentSKUs.length === 0 && !componentSKU) {
      errors.componentSKU = 'Component is required'
      errors.preventSave = true
    }

    return errors
  },
)

export async function updateComponent(sku, child_sku, quantity, linkType) {
  const url = `/product/${encodeURIComponent(sku)}/${
    linkType === BOM_LINK_TYPE ? 'manufacturing' : 'kit_component'
  }/`

  await api.put(url, {
    child_sku,
    quantity,
  })
}

export async function addComponent(sku, child_sku, quantity, linkType) {
  const url = `/product/${encodeURIComponent(sku)}/${
    linkType === BOM_LINK_TYPE ? 'manufacturing' : 'kit_component'
  }/`

  await api.post(url, {
    child_sku,
    quantity,
  })
}

export async function saveComponents({addAnother} = {}) {
  try {
    let {sku, componentSKUs, componentSKU, quantity, linkType} =
      modalFormSelector(getState())
    quantity = Number(quantity)

    updateModalForm({isSaving: true, serverError: null})

    for (let componentSKU of componentSKUs) {
      await updateComponent(sku, componentSKU, quantity, linkType)
    }

    if (componentSKU) {
      await addComponent(sku, componentSKU, quantity, linkType)
    }

    await getProduct(sku)

    const bomSKU = linkedBOMSKUsSelector(getState(), {sku})[0]

    if (bomSKU) {
      await getProduct(bomSKU.sku)
    }

    closeModal()

    showMessageToast(
      componentSKU
        ? `Added ${linkType === BOM_LINK_TYPE ? 'BOM' : 'kit'} component`
        : plural(componentSKUs.length)`Updated ${
            linkType === BOM_LINK_TYPE ? 'BOM' : 'kit'
          } component quantit${['ies', 'y']}`,
    )

    if (addAnother) {
      showProductComponentModal(sku, componentSKUs, quantity, linkType)
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ProductComponentModal({form}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: form.sku}),
  )
  const components = useSelector((state) =>
    productComponentsSelector(state, {sku: form.sku}),
  )
  const newComponentProduct = useSelector((state) =>
    productSelector(state, {sku: form.componentSKU}),
  )
  const errors = useSelector(errorsSelector)

  const firstComponent = components.find(
    ({sku}) => sku === form.componentSKUs[0],
  )

  const isNewComponent = form.componentSKUs.length === 0
  const typeTitle = form.linkType === BOM_LINK_TYPE ? 'BOM' : 'Kit'

  return (
    <ConfirmModal
      title={`${isNewComponent ? 'Add' : 'Edit'} ${typeTitle} Component`}
      modalSize="sm"
      onConfirm={() => saveComponents()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      MiddleButtons={
        isNewComponent
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => saveComponents({addAnother: true})}
              >
                Create and Add Another
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div className="fs-00 text--md-grey">
        <strong>Parent {typeTitle}:</strong>
      </div>
      <div className="fs-01">
        <strong>{product.name}</strong>
      </div>
      <div className="fs-00 divider--bottom">
        <span>{form.sku}</span>
      </div>
      <ul className="list list--no-style">
        {isNewComponent ? (
          <li className="list__item--form list__item--no-style fs-00 list__item--product-filter-stacked margin-bottom-5">
            <label
              className="fs-n0 margin-bottom-1"
              htmlFor="COMPONENT_QUANTITY_MODAL_PRODUCT_SEARCH__button"
            >
              Component SKU
            </label>
            {form.componentSKU ? (
              <ButtonLink onClick={() => updateModalForm({componentSKU: null})}>
                <strong className="fs-01">{newComponentProduct.name}</strong>{' '}
                <span className="unbold">({form.componentSKU})</span>
              </ButtonLink>
            ) : (
              <ProductFilter
                dropdown="COMPONENT_QUANTITY_MODAL_PRODUCT_SEARCH"
                excludedSKUs={components.map(({sku}) => sku)}
                onSelect={(product) =>
                  updateModalForm({componentSKU: product.sku})
                }
                autoFocus
              />
            )}
          </li>
        ) : form.componentSKUs.length === 1 ? (
          <li className="list__item--form list__item--no-style fs-00">
            <div className="fs-00 text--md-grey">
              <strong>Updating qty needed for 1 component:</strong>
            </div>
            <div className="fs-01 margin-bottom-5">
              <strong>{firstComponent.name}</strong>
            </div>
          </li>
        ) : (
          <li className="list__item--form list__item--no-style fs-00 margin-bottom-5">
            <strong>
              Updating qty needed for {form.componentSKUs.length} components
            </strong>
          </li>
        )}
        <li className="list__item--form list__item--no-style fs-00 margin-top-25 margin-bottom-3">
          <label className="fs-n0" htmlFor="requested_quantity">
            Quantity
          </label>
        </li>
        <li className="list__item list__item--form">
          <NumberInput
            value={form.quantity}
            onChange={(quantity) =>
              updateModalForm({quantity: String(quantity)})
            }
            min={0}
            autoFocus={!!form.componentSKU || !isNewComponent}
          />
          {errors.quantity && (
            <small className="error">{errors.quantity}</small>
          )}
        </li>
      </ul>
    </ConfirmModal>
  )
}

ProductComponentModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    componentSKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
    componentSKU: PropTypes.string,
    quantity: PropTypes.string.isRequired,
    linkType: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ProductComponentModal, modalFormSelector)
