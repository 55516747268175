import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NotifyCartAboutTrackingDeleteCheckbox from '../../../common/components/Order/NotifyCartAboutTrackingDeleteCheckbox.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {deleteLabel} from '../../../data/orders.js'
import {refreshOrderList} from '../orderListActions.js'

export const MODAL_FORM = 'CONFIRM_LABEL_DELETE_MODAL_FORM'

export function showConfirmLabelDeleteModal(orderNumber, labelType) {
  setForm(MODAL_FORM, {
    orderNumber,
    labelType,
    isSaving: false,
    serverError: null,
    notifyCart: false,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)
  return forms[MODAL_FORM]
}

export async function confirm() {
  try {
    const {orderNumber, labelType, notifyCart} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await deleteLabel(orderNumber, labelType, notifyCart)

    showMessageToast('Label was successfully deleted.')

    closeModal()

    refreshOrderList()
  } catch (err) {
    updateModalForm({
      serverError: `Error deleting label: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

function ConfirmLabelDeleteModal({form}) {
  const labelName = form.labelType === 'shipping' ? 'Shipping' : 'Return'

  return (
    <ConfirmModal
      title={`Delete ${labelName} Label`}
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-0">
        <strong>Are you sure you want to delete this label?</strong>
      </p>
      <NotifyCartAboutTrackingDeleteCheckbox
        orderNumbers={[form.orderNumber]}
        labelType={form.labelType}
        checked={form.notifyCart}
        onChange={() => updateModalForm({notifyCart: !form.notifyCart})}
      />
    </ConfirmModal>
  )
}

ConfirmLabelDeleteModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    labelType: PropTypes.string.isRequired,
    notifyCart: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default formConnect(
  connect()(ConfirmLabelDeleteModal),
  modalFormSelector,
)
