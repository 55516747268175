import HeaderDropdown from '../../common/components/List/HeaderDropdown.js'
import DropdownItemButton from '../../common/components/Dropdown/DropdownItemButton.js'
import {showCreatePOModal} from '../ProductListPage/Modals/CreatePOModal/createPOModalActions.js'
import {useSelector} from '../../store.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {TAG_TYPE__PO} from '../../common/constants/Tags.js'
import {hasPOManageTagPermissionSelector} from '../../data/me.js'
import {
  REPORT_EXPORT_GOODS_RECEIPT,
  REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
} from '../../common/constants/Reports.js'
import {showEditReportModal} from '../ReportsPage/Modals/EditReportModal.js'

export default function Actions() {
  const hasPOManageTagPermission = useSelector(hasPOManageTagPermissionSelector)

  return (
    <>
      <li className="inline-block v-align-middle fs-n0 divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => showCreatePOModal([])}
        >
          Order More Products
        </button>
      </li>
      {hasPOManageTagPermission && (
        <li className="inline-block fs-n0 margin-left-10">
          <button
            className="btn btn--action v-align-base"
            onClick={() => showEditTagModal(TAG_TYPE__PO)}
          >
            Create Tag
          </button>
        </li>
      )}
      <HeaderDropdown
        label="Other Actions"
        className="margin-left-10"
        dropdown="DROPDOWN_PO_LIST_OTHER_ACTIONS"
      >
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
              singleRun: true,
            })
          }
        >
          Export PO Info
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_GOODS_RECEIPT,
              singleRun: true,
            })
          }
        >
          Export PO Goods Receipts
        </DropdownItemButton>
      </HeaderDropdown>
    </>
  )
}
