import {
  DHL_ECOMMERCE,
  FEDEX,
  NEWGISTICS,
} from '../../../common/constants/ShipperNames.js'
import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [DHL_ECOMMERCE, FEDEX, NEWGISTICS]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [
        `${shipperType}__${
          shipperType === FEDEX ? 'dangerous_goods_option' : 'dangerous_goods'
        }`,
      ]
    : []
}

export default function DangerousGoods() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="Dangerous Goods"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty={
        shipperType === FEDEX ? 'dangerous_goods_option' : 'dangerous_goods'
      }
    />
  )
}
