import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {saveShipperNewgistics} from '../../../../redux/actions/ui/settings/shippers/forms/newgistics.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function NewgisticsForm({
  form: {id, merchantID, clientFacilityID, ngsFacilityID, isSaving},
  hasErrors,
  errors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.merchantID},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="id_newgistics_merchant_id">
                Merchant ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="newgistics_merchant_id"
                id="id_newgistics_merchant_id"
                value={merchantID}
                onChange={(event) =>
                  props.updateForm(id, {merchantID: event.target.value})
                }
              />
              {errors.merchantID && (
                <small className="error error-message">
                  {errors.merchantID}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.clientFacilityID,
              })}
            >
              <label htmlFor="id_newgistics_client_facility_id">
                Client Facility ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="newgistics_client_facility_id"
                id="id_newgistics_client_facility_id"
                value={clientFacilityID}
                onChange={(event) =>
                  props.updateForm(id, {clientFacilityID: event.target.value})
                }
              />
              {errors.clientFacilityID && (
                <small className="error error-message">
                  {errors.clientFacilityID}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.ngsFacilityID,
              })}
            >
              <label htmlFor="id_newgistics_ngs_facility_id">
                NGS Facility ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="newgistics_ngs_facility_id"
                id="id_newgistics_ngs_facility_id"
                value={ngsFacilityID}
                onChange={(event) =>
                  props.updateForm(id, {ngsFacilityID: event.target.value})
                }
              />
              {errors.ngsFacilityID && (
                <small className="error error-message">
                  {errors.ngsFacilityID}
                </small>
              )}
            </li>
            {id !== NEW_ID && <ShippingMethods shipperID={id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                onClick={() => props.saveShipperNewgistics(id)}
                isDisabled={hasErrors}
                isLoading={isSaving}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      <div className="medium-5 columns">
        <fieldset>
          <div className="alert margin-bottom-10 alert--standard">
            {id === NEW_ID ? (
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help setting up your PB Standard account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  Please contact{' '}
                  <a href="mailto:support@ordoro.com">support@ordoro.com</a> to
                  have this special shipper enabled in your account.
                  <div>
                    <a
                      className="btn--link mid meta-ups-setup-help"
                      href="https://support.ordoro.com/how-do-i-set-up-newgistics-in-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info →
                    </a>
                  </div>
                </dd>
              </dl>
            ) : (
              <Archive shipperID={id} />
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

NewgisticsForm.propTypes = {
  form: ShipperFormShape,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveShipperNewgistics: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
  }
}

const mapDispatchToProps = {
  saveShipperNewgistics,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewgisticsForm)
