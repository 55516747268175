import PropTypes from 'prop-types'
import {Component} from 'react'
import moment from 'moment'

export default class Salutation extends Component {
  constructor(props) {
    super(props)
    this.state = {hour: moment().hour()}
    this.updateTime = this.updateTime.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.updateTime, 1000 * 60 * 5)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  updateTime() {
    this.setState({hour: moment().hour()})
  }

  greeting() {
    const {hour} = this.state

    if (4 < hour && hour < 12) {
      return 'Good morning'
    } else if (12 <= hour && hour < 18) {
      return 'Good afternoon'
    } else if ((18 <= hour && hour < 24) || hour < 4) {
      return 'Good evening'
    }

    return 'Hello'
  }

  render() {
    return (
      <span>
        {this.greeting()}, {this.props.name}.
      </span>
    )
  }
}

Salutation.propTypes = {
  name: PropTypes.string.isRequired,
}
