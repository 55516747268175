import {departedShippingMethodSelector} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function Departed() {
  const {labelInfoID} = useLabelConfigContext()
  const departedShippingMethod = useSelector((state) =>
    departedShippingMethodSelector(state, {
      labelInfoID,
    }),
  )

  if (!departedShippingMethod) {
    return null
  }

  return (
    <div className="margin-top-3 lh-sm">
      <small className="make-medium-dark-grey">
        <em>
          {departedShippingMethod} is not available with the current
          configuration.
        </em>
      </small>
    </div>
  )
}
