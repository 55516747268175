import {useEffect, useState, useMemo} from 'react'
import format from 'date-fns/format'
import setDateParts from 'date-fns/set'
import isValid from 'date-fns/isValid'

import DatePicker from '../../../common/components/DatePicker.js'
import parseTime from '../../../common/parseTime.js'
import TimeInput from '../../../common/components/TimeInput.js'
import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {AMAZON_SHIPPER} from '../../../common/constants/ShipperNames.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [AMAZON_SHIPPER]
const LABEL_PROPERTY_PICKUP_START_DATE = 'pickup_start_date'
const LABEL_PROPERTY_PICKUP_END_DATE = 'pickup_end_date'

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [LABEL_PROPERTY_PICKUP_START_DATE, LABEL_PROPERTY_PICKUP_END_DATE]
    : []
}

export default function PickupDateRange() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)
  const config = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes: VALID_SHIPPER_TYPES,
  })

  const pickup_start_date = config[LABEL_PROPERTY_PICKUP_START_DATE]
  const pickup_end_date = config[LABEL_PROPERTY_PICKUP_END_DATE]

  const [isInitial, setIsInitial] = useState(true)
  const [pickupDate, setPickupDate] = useState(null)
  const [pickupTime, setPickupTime] = useState('8:00 AM')
  const [closeTime, setCloseTime] = useState('5:30 PM')
  const pickupStartDate = useMemo(
    () => (pickup_start_date ? new Date(pickup_start_date) : null),
    [pickup_start_date],
  )
  const pickupEndDate = useMemo(
    () => (pickup_end_date ? new Date(pickup_end_date) : null),
    [pickup_end_date],
  )
  const [now] = useState(new Date())

  useEffect(() => {
    if (!isValid(pickupStartDate)) {
      setPickupDate(null)
      setPickupTime('8:00 AM')
      setCloseTime('5:30 PM')

      return
    }

    setPickupDate(pickupStartDate)
    setPickupTime(format(pickupStartDate, 'h:mm a'))
    setCloseTime(format(pickupEndDate, 'h:mm a'))
  }, [pickupStartDate, pickupEndDate])

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false)

      return
    }

    if (!pickupDate || !isValid(pickupDate)) {
      if (pickupStartDate || pickupEndDate) {
        updateLabelConfig(labelInfoID, {
          [LABEL_PROPERTY_PICKUP_START_DATE]: null,
          [LABEL_PROPERTY_PICKUP_END_DATE]: null,
        })

        onChange()
      }

      return
    }

    updateLabelConfig(labelInfoID, {
      [LABEL_PROPERTY_PICKUP_START_DATE]: setDateParts(
        pickupDate,
        parseTime(pickupTime),
      ).toISOString(),
      [LABEL_PROPERTY_PICKUP_END_DATE]: setDateParts(
        pickupDate,
        parseTime(closeTime),
      ).toISOString(),
    })

    onChange()
  }, [pickupDate, pickupTime, closeTime])

  if (!VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  const disabled = isLabelEnableMode && !isLabelPropertyEnabled

  return (
    <li
      className={className`list__item--shipping-options margin-bottom-15 ${formatMetaClassName(
        labelProperty,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <div>
          <label
            className="label--bold"
            htmlFor={`${labelInfoID}__expected_service_offering_date`}
          >
            Pickup Date
          </label>
          <DatePicker
            id={`${labelInfoID}__expected_service_offering_date`}
            onChange={(date) => setPickupDate(date)}
            selected={pickupDate}
            minDate={now}
            disabled={disabled}
          />
        </div>
        {pickupDate && (
          <div className="list flex flex-wrap">
            <div
              className={className`list__item--shipping-options margin-bottom-0 margin-top-10 ${
                !isLabelEnableMode && 'margin-right-10'
              }`}
            >
              <label className="label--bold" htmlFor="id_earliest_time">
                Earliest Time
              </label>
              <TimeInput
                id="earliest_time"
                value={pickupTime}
                onBlur={(time) => setPickupTime(time)}
                disabled={disabled}
              />
            </div>
            <div className="list__item--shipping-options margin-bottom-0 margin-top-10">
              <label className="label--bold" htmlFor="id_latest_time">
                Latest Time
              </label>
              <TimeInput
                id="latest_time"
                value={closeTime}
                onBlur={(time) => setCloseTime(time)}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </div>
      <EnableLabelProperty labelProperty={labelProperty} />
    </li>
  )
}
