import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import sum from 'lodash/sum.js'

import {formsSelector} from '../../../store.js'
import {
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
} from '../../../common/constants/ShipperNames.js'
import {isNumeric} from '../../../common/utils.js'
import {activeShippersSelector} from '../../../data/shippers.js'
import {accountBalanceSelector} from '../../../data/account.js'
import {shipperBalancesSelector} from '../../../data/shipperBalances.js'
import {
  BULK_LABEL_ID,
  labelShipperIDSelector,
  labelShipperTypeSelector,
  labelInfosFromLabelInfoIDsSelector,
  labelLoadingSelector,
  selectedShippingRateCostSelector,
  ratesLoadingSelector,
  labelErrorsSelector,
  createLabelImageDisabledSelector,
  includeInsuranceSelector,
  insuredValueSelector,
  getInsuranceCost,
  insuranceCostSelector,
} from '../../../data/labelInfos/index.js'
import {pendingLabelsSelector} from '../../OrderListPage/ActionPanel/orderActionPanelFunctions.js'

const balanceShipperTypes = [ENDICIA, PITNEY, PITNEY_MERCHANT, VISIBLE_USPS]

export const BULK_LABEL_CONFIG_FORM = 'BULK_LABEL_CONFIG_FORM'

export function bulkLabelConfigFormSelector(state) {
  return formsSelector(state)[BULK_LABEL_CONFIG_FORM]
}

export function bulkShowShipFromSelector(state) {
  return get(bulkLabelConfigFormSelector(state), 'showShipFrom')
}

export function bulkShipFromValueSelector(state) {
  return get(bulkLabelConfigFormSelector(state), 'shipFromValue')
}

export function bulkShipFromLoadingSelector(state) {
  return get(bulkLabelConfigFormSelector(state), 'loading__shipFrom')
}

export function bulkReturnToAddressSelector(state) {
  return get(bulkLabelConfigFormSelector(state), 'returnToAddress')
}

export const singleShipperTypeForBalanceSelector = createSelector(
  (state) => bulkLabelConfigFormSelector(state).showShippingConfiguration,
  (state) => labelShipperTypeSelector(state, {labelInfoID: BULK_LABEL_ID}),
  (state, {labelInfoIDs}) =>
    labelInfoIDs.map((labelInfoID) =>
      labelShipperTypeSelector(state, {labelInfoID}),
    ),
  (showShippingConfiguration, shipperType, shipperTypes) => {
    if (showShippingConfiguration) {
      if (balanceShipperTypes.includes(shipperType)) {
        return shipperType
      }

      if (shipperType) {
        return null
      }
    }

    return shipperTypes.find((vendor) => balanceShipperTypes.includes(vendor))
  },
)

export const singleShipperIDForBalanceSelector = createSelector(
  activeShippersSelector,
  singleShipperTypeForBalanceSelector,
  (shippers, shipperType) => {
    if (!shipperType) {
      return null
    }

    const shipper = shippers.find((s) => s.vendor === shipperType)

    if (shipper) {
      return shipper.id
    }

    return null
  },
)

export const bulkBalanceSelector = createSelector(
  activeShippersSelector,
  shipperBalancesSelector,
  accountBalanceSelector,
  singleShipperTypeForBalanceSelector,
  singleShipperIDForBalanceSelector,
  (shippers, shipperBalances, accountBalance, shipperType, shipperID) => {
    if (!shipperID) {
      return null
    }

    if (shipperType === ENDICIA && shipperBalances[shipperID]) {
      return shipperBalances[shipperID].postage_balance
    }

    if (shipperType === PITNEY_MERCHANT && shipperBalances[shipperID]) {
      return shipperBalances[shipperID].balance
    }

    if (
      [PITNEY, VISIBLE_USPS].includes(shipperType) &&
      isNumeric(accountBalance)
    ) {
      return accountBalance
    }

    return null
  },
)

export const bulkCanAddPostageSelector = createSelector(
  singleShipperTypeForBalanceSelector,
  singleShipperIDForBalanceSelector,
  (shipperType, shipperID) => {
    if (!shipperID) {
      return false
    }

    return [ENDICIA, PITNEY, VISIBLE_USPS].includes(shipperType)
  },
)

export const bulkCostByShipperIDSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    labelInfoIDs.reduce((prev, labelInfoID) => {
      const cost = selectedShippingRateCostSelector(state, {labelInfoID}) || 0
      const shipperID = labelShipperIDSelector(state, {labelInfoID})

      if (shipperID) {
        if (prev[shipperID]) {
          prev[shipperID] += cost
        } else {
          prev[shipperID] = cost
        }
      }

      return prev
    }, {}),
)

export const insufficientBalanceSelector = createSelector(
  bulkBalanceSelector,
  bulkCostByShipperIDSelector,
  singleShipperIDForBalanceSelector,
  (balance, bulkCostByShipperID, shipperID) => {
    if (!shipperID) {
      return false
    }

    if (balance === null) {
      return null
    }

    const cost = bulkCostByShipperID[shipperID] || 0

    return cost > balance
  },
)

export const bulkErrorsSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    labelInfoIDs.reduce((prev, labelInfoID) => {
      const shipperType = labelShipperTypeSelector(state, {labelInfoID})

      prev.push(...labelErrorsSelector(state, {labelInfoID, shipperType}))

      return prev
    }, []),
)

export function bulkErrorsWithLabelInfoIDSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.reduce((prev, labelInfoID) => {
    const shipperType = labelShipperTypeSelector(state, {labelInfoID})
    const errors = labelErrorsSelector(state, {labelInfoID, shipperType})

    if (errors.length) {
      prev.push([labelInfoID, errors])
    }

    return prev
  }, [])
}

export const bulkErrorCountSelector = createSelector(
  bulkErrorsSelector,
  (errors) => errors.length,
)

export const hasBulkErrorsSelector = createSelector(
  bulkErrorCountSelector,
  (errorCount) => errorCount > 0,
)

export const pendingBulkInsuranceCostSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => labelShipperTypeSelector(state, {labelInfoID: BULK_LABEL_ID}),
  (state) => insuredValueSelector(state, {labelInfoID: BULK_LABEL_ID}),
  (labelInfoIDs, shipperType, insuredValue) =>
    labelInfoIDs.length * getInsuranceCost(true, shipperType, insuredValue),
)

export const bulkInsuranceCostSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    sum(
      labelInfoIDs.map((labelInfoID) => {
        const shipperType = labelShipperTypeSelector(state, {labelInfoID})

        return insuranceCostSelector(state, {labelInfoID, shipperType})
      }),
    ),
)

export const bulkIncludeInsuranceSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    labelInfoIDs.some((labelInfoID) =>
      includeInsuranceSelector(state, {labelInfoID}),
    ),
)

export const bulkCostSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    labelInfoIDs.reduce(
      (total, labelInfoID) =>
        total + (selectedShippingRateCostSelector(state, {labelInfoID}) || 0),
      0,
    ),
)

export const bulkCreateLabelImagesDisabledSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (state) => bulkLabelConfigFormSelector(state).showShippingConfiguration,
  (state) => bulkLabelConfigFormSelector(state).showWeight,
  (state) => bulkLabelConfigFormSelector(state).showDimensions,
  (state) => bulkLabelConfigFormSelector(state).showPresets,
  (state) => bulkLabelConfigFormSelector(state).showShipFrom,
  (
    labelInfoIDs,
    state,
    showShippingConfiguration,
    showWeight,
    showDimensions,
    showPresets,
    showShipFrom,
  ) => {
    if (
      showShippingConfiguration ||
      showWeight ||
      showDimensions ||
      showPresets ||
      showShipFrom
    ) {
      return true
    }
    return !!labelInfoIDs.find((labelInfoID) => {
      return createLabelImageDisabledSelector(state, {labelInfoID})
    })
  },
)

export const bulkRatesLoadingSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (state) => bulkLabelConfigFormSelector(state).loading__shippingConfiguration,
  (state) => bulkLabelConfigFormSelector(state).loading__weight,
  (state) => bulkLabelConfigFormSelector(state).loading__dimensions,
  (state) => bulkLabelConfigFormSelector(state).loading__shipFrom,
  (
    labelInfoIDs,
    state,
    shippingConfigurationLoading,
    weightLoading,
    dimensionsLoading,
    shipFromLoading,
  ) => {
    if (
      shippingConfigurationLoading ||
      weightLoading ||
      dimensionsLoading ||
      shipFromLoading
    ) {
      return false
    }
    return !!labelInfoIDs.find((labelInfoID) =>
      ratesLoadingSelector(state, {labelInfoID}),
    )
  },
)

export const labelsLoadedCountSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    labelInfoIDs.filter(
      (labelInfoID) => !labelLoadingSelector(state, {labelInfoID}),
    ).length,
)

export const ratesLoadedCountSelector = createSelector(
  labelInfosFromLabelInfoIDsSelector,
  (labelInfos) => labelInfos.filter((label) => !label.rates_loading).length,
)

export const bulkPendingLabelsSelector = createSelector(
  pendingLabelsSelector,
  (state, {labelInfoIDs}) => labelInfoIDs,
  (pendingLabels, labelInfoIDs) =>
    pendingLabels.filter((labelInfoID) => labelInfoIDs.includes(labelInfoID)),
)

export const bulkLabelsLoadingSelector = createSelector(
  bulkPendingLabelsSelector,
  labelsLoadedCountSelector,
  (pendingLabels, labelsLoadedCount) =>
    pendingLabels.length - labelsLoadedCount > 0,
)
