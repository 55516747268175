import AddMOCommentModal from './AddMOCommentModal.js'
import CreateMOModal from './CreateMOModal.js'
import DeleteMOLinesModal from './DeleteMOLinesModal.js'
import EditMOLineModal from './EditMOLineModal.js'
import EditMOParamModal from './EditMOParamModal.js'
import ExportMOModal from './ExportMOModal.js'
import FinishMOModal from './FinishMOModal.js'
import UpdateMOStatusModal from './UpdateMOStatusModal.js'

export default function Modals() {
  return (
    <>
      <AddMOCommentModal />
      <CreateMOModal />
      <DeleteMOLinesModal />
      <EditMOLineModal />
      <EditMOParamModal />
      <ExportMOModal />
      <FinishMOModal />
      <UpdateMOStatusModal />
    </>
  )
}
