import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {CHANNELADVISOR} from '../../../../common/constants/CartVendorOptions.js'
import requests from '../../../../common/requests.js'
import {setCart, cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import DistributionCenterCodes from '../DistributionCenterCodes.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.profileID)) {
    errors.profileID = 'Profile ID is required'
    errors.preventSave = true
  }

  for (let i = 0; i < form.distribution_center_codes.length; i++) {
    if (!isPresent(form.distribution_center_codes[i])) {
      errors[`distribution_center_codes__${i}`] =
        'A distribution code is required'
      errors.preventSave = true
    }
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: CHANNELADVISOR}),
      profileID: '',
      distribution_center_codes: [],
      hasChanged__distribution_center_codes: [],
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    profileID: cart.vendor_config.profile_id,
    distribution_center_codes:
      cart.vendor_config.distribution_center_codes || [],
    hasChanged__distribution_center_codes: (
      cart.vendor_config.distribution_center_codes || []
    ).map(() => false),
  }
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    distribution_center_codes: form.distribution_center_codes || [],
  }
}

export async function authorize(formName) {
  const form = formSelector(getState(), {formName})
  const params = {
    profileID: form.profileID,
  }

  if (form.id !== NEW_ID) {
    params.cartID = form.id
  }

  try {
    const json = await requests.post('/verde/channel_advisor/authorize', params)

    setCart(json)
  } catch (err) {
    updateForm(formName, {
      serverError: err,
    })
  }
}

function ChannelAdvisor({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <>
              <FormListItem className="divider--top">
                <TextInput
                  name="channeladvisor_profile_id"
                  label="Profile ID"
                  required
                  value={form.profileID || ''}
                  errorMessage={form.hasChanged_profileID && errors.profileID}
                  onChange={(event) =>
                    updateForm(formName, {
                      profileID: event.target.value,
                      hasChanged_profileID: true,
                    })
                  }
                />
              </FormListItem>
              <AuthorizeMessage formName={formName} />
            </>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName}>
                <strong>Profile ID:</strong>
                <div className="margin-bottom-10">{form.profileID}</div>
              </ReauthorizeButton>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <DistributionCenterCodes formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="ChannelAdvisor"
          href="https://support.ordoro.com/channel-advisor-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

ChannelAdvisor.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    profileID: PropTypes.string.isRequired,
    hasChanged_profileID: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(ChannelAdvisor, setUpCartForm)
