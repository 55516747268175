import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'

import {ErrorsShape, SelectOptionsShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import {Address} from '../../../common/address.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import Select from '../../../common/components/Select.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../common/components/Button/ButtonSecondary.js'
import {setForm} from '../../../store.js'

import AddressForm from './AddressForm.js'
import ValidationPanel from './ValidationPanel.js'
import {
  EDIT_ADDRESS_MODAL,
  EditAddressFormShape,
  saveAddress,
  removeModalForm,
  modalFormSelector,
  errorsSelector,
  addressCopyOptionsSelector,
  updateAddressFromID,
  updateModalForm,
  setAddressFromParse,
  resetAddress,
  markChanged,
} from './EditAddressFunctions.js'
import {PRIVACY_MASK} from '../../../common/analytics/index.js'

export function showEditAddressModal(options = {}) {
  if (!options.address || isEmpty(options.address)) {
    options.address = new Address()
  }

  setForm(EDIT_ADDRESS_MODAL, {
    hasAddressShortcuts: options.hasAddressShortcuts || false,
    willValidate: options.willValidate || false,
    modalTitle: options.title || 'Edit Address',
    confirmText: options.confirmText || 'Save',
    ...options.address,
    originalAddress: {...options.address},
    addressFreeText: '',
    onSave: options.onSave,
    toastMessage:
      options.toastMessage !== undefined
        ? options.toastMessage
        : 'Address was successfully updated.',
    hasChange: false,
    ...(options.markAllChanged ? markChanged(options.address, true) : null),
    required: options.required || [],
    include: options.include || [],
    isSaving: false,
    serverError: null,
  })
}

function EditAddressModal({form, errors, addressCopyOptions}) {
  return (
    <ConfirmModal
      title={form.modalTitle}
      modalSize="lg"
      isSaving={form.isSaving}
      confirmText={form.confirmText}
      cancelText="Cancel"
      onConfirm={() => saveAddress()}
      onCancel={() => removeModalForm()}
      error={form.serverError}
      isDisabled={!isEmpty(errors)}
    >
      <div className="row">
        <div className="columns medium-8">
          <AddressForm form={form} errors={errors} />
        </div>
        <div className="medium-4 columns">
          <div className="divider--left lg">
            {form.willValidate && <ValidationPanel />}
            {form.hasAddressShortcuts && (
              <dl
                className={`list ${
                  form.willValidate ? 'divider--top margin-top-25' : ''
                }`}
              >
                <dt className="list__title fs-00">
                  <span className="margin-right-3">Address Shortcuts</span>
                </dt>
                <dd className="list__item">
                  <Select
                    value=""
                    className="w-100 border-box"
                    onChange={(value) => {
                      const [, type, id] =
                        value.match(/^(supplier_id|warehouse_id)__(\d+)$/) || []

                      if (type && id) {
                        updateAddressFromID(type, Number(id))
                      }
                    }}
                  >
                    {addressCopyOptions.map(
                      ({value, display, disabled}, index) => (
                        <option
                          key={disabled ? `disabled_${index}` : value}
                          value={value}
                          disabled={disabled}
                        >
                          {display}
                        </option>
                      ),
                    )}
                  </Select>
                </dd>
              </dl>
            )}
            <dl
              className={`list ${
                form.willValidate || form.hasAddressShortcuts
                  ? 'divider--top margin-top-25'
                  : ''
              }`}
            >
              <dt className="list__title fs-00">
                <span className="margin-right-3">Paste Address</span>
                <a
                  className="inline-text-button no-underline v-align-middle"
                  href="https://support.ordoro.com/pasted-address-lines/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    className="icon icon-support icon-block fs-n0"
                    aria-hidden="true"
                  />
                </a>
              </dt>
              <dd className="list__item">
                <TextArea
                  className={`textarea textarea--mh-auto ${PRIVACY_MASK}`}
                  rows="7"
                  value={form.addressFreeText}
                  onChange={(value) =>
                    updateModalForm({
                      addressFreeText: value,
                    })
                  }
                />
              </dd>
              <dd className="list__item">
                <ButtonPrimary
                  className="margin-right-5"
                  size="x-sm"
                  alt
                  onClick={() => setAddressFromParse()}
                  isDisabled={!form.addressFreeText}
                >
                  Update Form
                </ButtonPrimary>
                <ButtonSecondary size="x-sm" onClick={() => resetAddress()}>
                  Reset
                </ButtonSecondary>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
}

EditAddressModal.propTypes = {
  form: EditAddressFormShape.isRequired,
  errors: ErrorsShape.isRequired,
  addressCopyOptions: SelectOptionsShape.isRequired,
}

function mapStateToProps(state) {
  return {
    errors: errorsSelector(state),
    addressCopyOptions: addressCopyOptionsSelector(state),
  }
}

export default formConnect(
  connect(mapStateToProps)(EditAddressModal),
  modalFormSelector,
)
