import PropTypes from 'prop-types'

import {
  currentPageNumberSelector,
  pageNumbersSelector,
} from '../../../../redux/selectors/ui/pagination.js'
import PageNumber from './PageNumber.js'

export default function PageNumberControl({
  isLoading,
  limit,
  offset,
  count,
  setOffset,
}) {
  if (isLoading) {
    return null
  }

  const currentPageNumber = currentPageNumberSelector(null, {offset, limit})
  const pageNumbers = pageNumbersSelector(null, {limit, count, offset})

  return (
    <ul className="list--inline list--pagination">
      {pageNumbers.map((pageNumber, index) => (
        <PageNumber
          key={`${index}-${pageNumber}`}
          pageNumber={pageNumber}
          active={currentPageNumber === pageNumber}
          onClick={() => {
            const newOffset = (pageNumber - 1) * limit

            setOffset(newOffset)
          }}
        />
      ))}
    </ul>
  )
}

PageNumberControl.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
}
