import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import find from 'lodash/find.js'
import reduce from 'lodash/reduce.js'
import get from 'lodash/get.js'
import isBefore from 'date-fns/isBefore'

import {
  AMAZON_SFP,
  CANADA_POST,
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
  USPS_SHIPPERS,
  UPS,
  FEDEX,
} from '../common/constants/ShipperNames.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import apiverson from '../common/apiverson.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {shipperBalancesSelector} from './shipperBalances.js'
import {setEndiciaAccountError} from '../redux/actions/ui/index.js'
import {
  currencySymbolSelector,
  useFedExAuthSelector,
  useOrdoroShippersSelector,
} from '../data/company.js'
import {accountBalanceSelector} from './account.js'
import {
  getState,
  dispatch,
  setForm,
  updateForm,
  formsSelector,
} from '../store.js'
import {defaultWarehouseSelector} from './warehouses.js'
import {getNounsFromValue, tokenizeOptions} from '../common/tokenizeOptions.js'
import userflow from '../common/analytics/userflow.js'

export const SHIPPERS = 'SHIPPERS'

export function setShippers(shippers) {
  setForm(SHIPPERS, keyBy(shippers, 'id'))
}

export function setShipper(shipper) {
  updateForm(SHIPPERS, {[shipper.id]: shipper})
}

export async function getShippers() {
  try {
    const shippers = await fetchAllAPI('/shipper', 'shipper', {all: true})

    setShippers(shippers)

    const activeShippers = activeShippersSelector(getState())

    userflow.updateGroup({
      shipper_count: activeShippers.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting shippers.',
        details: err.message || err.error_message,
      },
      err,
    )

    setShippers([])
  }
}

export const shippersSelector = createSelector(
  formsSelector,
  (forms) => forms[SHIPPERS] || shippersSelector.default,
)
shippersSelector.default = {}

export function shippersHaveLoadedSelector(state) {
  return !!formsSelector(state)[SHIPPERS]
}

export function shipperSelector(state, {shipperID}) {
  return shippersSelector(state)[shipperID]
}

export function getShipperName(shipper) {
  return shipper ? shipper.name : ''
}

export function shipperNameSelector(state, {shipperID}) {
  return getShipperName(shipperSelector(state, {shipperID}))
}

export function isArchivedSelector(state, {shipperID}) {
  return !!get(shipperSelector(state, {shipperID}), ['archive_date'])
}

export function canArchiveSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  if (!shipper) {
    return false
  }

  if (USPS_SHIPPERS.includes(shipper.vendor)) {
    return false
  }

  return true
}

export function shipperTypeSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  return shipper ? shipper.vendor : null
}

export const shippersSortedByIDSelector = createSelector(
  shippersSelector,
  (shippers) =>
    sortBy(shippers, ['id']).filter(
      // We don't want usps shippers that are archived
      ({vendor, archive_date}) =>
        !(USPS_SHIPPERS.includes(vendor) && !!archive_date),
    ),
)

export const activeShippersSelector = createSelector(
  shippersSortedByIDSelector,
  (shippers) => shippers.filter((shipper) => !shipper.archive_date),
)

export const activeShipperIDsSelector = createSelector(
  activeShippersSelector,
  (activeShippers) => activeShippers.map((shipper) => shipper.id),
)

export const approvedShippersSelector = createSelector(
  activeShippersSelector,
  (shippers) => shippers,
)

export const shipperOptionsSelector = createSelector(
  approvedShippersSelector,
  (shippers) =>
    shippers.map((shipper) => ({
      value: shipper.id,
      display: shipper.name,
      entity: shipper,
      type: SHIPPERS,
      nouns: [...getNounsFromValue(shipper.name), shipper.vendor],
    })),
)

export const shipperOptionTokensSelector = createSelector(
  shipperOptionsSelector,
  (shipperOptions) => tokenizeOptions(shipperOptions),
)

export const needsShipperSelector = createSelector(
  shippersSelector,
  shippersHaveLoadedSelector,
  (shippers, shippersHaveLoaded) =>
    shippersHaveLoaded && Object.keys(shippers).length === 0,
)

export const needsUSPSShipperSelector = createSelector(
  shippersSelector,
  useOrdoroShippersSelector,
  (shippers, useOrdoroShippers) =>
    useOrdoroShippers &&
    !find(
      shippers,
      (shipper) =>
        shipper.archive_date === null &&
        [ENDICIA, PITNEY, PITNEY_MERCHANT, VISIBLE_USPS].includes(
          shipper.vendor,
        ),
    ),
)

export const hasAmazonSFPSelector = createSelector(
  shippersSelector,
  shippersHaveLoadedSelector,
  (shippers, hasLoadedShippers) => {
    if (!hasLoadedShippers) {
      return true
    }

    return !!find(shippers, (shipper) => shipper.vendor === AMAZON_SFP)
  },
)

export const hasChargeableShipperAccountSelector = createSelector(
  shippersSelector,
  (shippers) =>
    reduce(
      shippers,
      (prev, {vendor}) => prev || [PITNEY, VISIBLE_USPS].includes(vendor),
      false,
    ),
)

export function balanceSelector(state, {shipperID}) {
  const shipperType = shipperTypeSelector(state, {shipperID})
  const shipperBalances = shipperBalancesSelector(state)
  const accountBalance = accountBalanceSelector(state, {shipperID})

  if (shipperType === ENDICIA) {
    return (
      shipperBalances[shipperID] && shipperBalances[shipperID].postage_balance
    )
  }
  if (shipperType === PITNEY_MERCHANT) {
    return shipperBalances[shipperID] && shipperBalances[shipperID].balance
  }
  if ([PITNEY, VISIBLE_USPS].includes(shipperType)) {
    return accountBalance
  }
  return null
}

export function getCanAddPostage(shipperType) {
  return [ENDICIA, PITNEY, VISIBLE_USPS].includes(shipperType)
}

export function autoFillPostageConfigSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  const config =
    shipper && shipper.autorefill_config ? shipper.autorefill_config : {}

  return {
    isEnabled: config.enabled || false,
    lowerThreshold: config.lower_threshold || 0,
    incrementAmount: config.increment_amount || 0,
  }
}

export const hasCanadaPostShipperSelector = createSelector(
  shippersSelector,
  (shippers) => !!find(shippers, {vendor: CANADA_POST}),
)

export function defaultFedExSmartPostHubSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  return get(shipper, 'vendor_config.smartpost_hub') || null
}

// temp_use_fedex_auth
export function isShipperNewFedExAuthSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  return !!get(shipper, ['vendor_config', 'child_key'])
}

export function getShipperCurrencySymbol(shipperType, currencySymbol) {
  if ([ENDICIA, PITNEY, PITNEY_MERCHANT, VISIBLE_USPS].includes(shipperType)) {
    return '$'
  }

  return currencySymbol
}

export function shipperCurrencySymbolSelector(state, {shipperID, shipperType}) {
  shipperType = shipperType || shipperTypeSelector(state, {shipperID})
  const currencySymbol = currencySymbolSelector(state)

  return getShipperCurrencySymbol(shipperType, currencySymbol)
}

export function canAutoCreatePitneyShipperSelector(state) {
  // has fully setup US default warehouse
  const warehouse = defaultWarehouseSelector(state)

  if (
    !warehouse ||
    !warehouse.is_configured_for_shipping ||
    warehouse.address.country !== 'US'
  ) {
    return false
  }

  // has no USPS shipper
  return needsUSPSShipperSelector(state)
}

export async function autoCreatePitneyShipper() {
  if (!canAutoCreatePitneyShipperSelector(getState())) {
    return
  }

  await apiverson.post('/carrier/pitney')

  await getShippers()
}

const ERROR_CODES = [
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '12103',
  '12133',
  '12505',
  '12515',
  '12525',
]

export async function checkEndiciaStatus() {
  const shippers = activeShippersSelector(getState())

  dispatch(setEndiciaAccountError(null))

  const shipper = shippers.find(({vendor}) => vendor === ENDICIA)

  if (!shipper) {
    return
  }

  try {
    await apiverson.get(`/shipper/${shipper.id}/balance`)
  } catch (err) {
    const message = err.error_message || err.message
    const match = message.match(/\(Status: (\d+)\)$/)

    const errorCode = match && match[1]

    if (ERROR_CODES.includes(errorCode)) {
      dispatch(setEndiciaAccountError(message))
    }
  }
}

export function usesDiscountedRatesSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  return get(shipper, 'vendor_config.uses_discounted_rates') || false
}

export function upsTokenExpirationSelector(state, {shipperID}) {
  const shipper = shipperSelector(state, {shipperID})

  if (!shipper || shipper.vendor !== UPS) {
    return null
  }

  const tokenExpiration =
    shipper.vendor_config.refresh_token_expires ||
    (!shipper.vendor_config.refresh_token && shipper.vendor_config.expires) ||
    null

  const isExpired = tokenExpiration
    ? isBefore(new Date(tokenExpiration), new Date())
    : true

  return {tokenExpiration, isExpired}
}

export function fedExInitialAuthNeededSelector(state, {shipperID}) {
  const useFedExAuth = useFedExAuthSelector(state)

  if (!useFedExAuth) {
    return null
  }

  const shipper = shipperSelector(state, {shipperID})

  if (!shipper || shipper.vendor !== FEDEX) {
    return null
  }

  // once they have a child_key then they are forever authorized as far as we can tell
  // otherwise force "right now" date for expiration
  const tokenExpiration = shipper.vendor_config.child_key
    ? null
    : new Date().toISOString()

  return {tokenExpiration, isExpired: !!tokenExpiration}
}

export function tokenExpirationSelector(state, {shipperID}) {
  return (
    upsTokenExpirationSelector(state, {shipperID}) ||
    fedExInitialAuthNeededSelector(state, {shipperID}) || {
      tokenExpiration: null,
      isExpired: false,
    }
  )
}

export function shipperIntegrationIssuesSelector(state) {
  const shippers = activeShippersSelector(state)

  return shippers
    .map((shipper) => ({
      shipper,
      tokenExpiration: tokenExpirationSelector(state, {shipperID: shipper.id}),
    }))
    .filter(({tokenExpiration: {isExpired}}) => isExpired)
    .map(({shipper}) => ({
      id: shipper.id,
      type: 'shipper',
      name: shipper.name,
      message: 'needs to be reauthorized',
      errorType: 'needs_reauth',
      link: `#/settings/shipper/${shipper.id}/edit`,
    }))
}
