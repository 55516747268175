import {createContext, useCallback, useContext, useRef, useState} from 'react'
import PropTypes from 'prop-types'

export const MOVE_BEFORE = 'MOVE_BEFORE'
export const MOVE_AFTER = 'MOVE_AFTER'

export const DragAndDropContext = createContext()
export const DraggableContext = createContext()

export function useDragAndDropContext() {
  return useContext(DragAndDropContext)
}

export function useDraggableContext() {
  return useContext(DraggableContext)
}

export function DragAndDropList({onMove, children}) {
  const [drag, setDrag] = useState(null)

  const onMoveInternal = useCallback((id, nearID, where) => {
    if (onMove) {
      onMove(id, nearID, where)
    }
  }, [])

  return (
    <DragAndDropContext.Provider value={{drag, setDrag, onMoveInternal}}>
      {children}
    </DragAndDropContext.Provider>
  )
}

DragAndDropList.propTypes = {
  onMove: PropTypes.func,
  children: PropTypes.node,
}

function onDragStart(event, setDrag, ref, id, dragIndex) {
  setDrag({id, index: dragIndex})

  const box = ref.current.getBoundingClientRect()

  event.dataTransfer.setDragImage(
    ref.current,
    event.clientX - box.x,
    event.clientY - box.y,
  )
}

function onDragOver(event, setDrag, drag, hoverID, hoverIndex, onMoveInternal) {
  // onDragStart didn't fire property
  if (!drag) {
    return
  }

  event.preventDefault()

  const {id, index: dragIndex} = drag

  // if we are still dragging over the same row
  if (hoverID === id) {
    return
  }

  if (dragIndex < hoverIndex) {
    // moving down (lower in list, to a higher index)
    onMoveInternal(id, hoverID, MOVE_AFTER)
  } else if (dragIndex > hoverIndex) {
    // moving up (higher in list, to a lower index)
    onMoveInternal(id, hoverID, MOVE_BEFORE)
  }

  if (dragIndex !== hoverIndex) {
    // if we got here then we moved
    // the new drag index is the index of the row we hovered over
    setDrag({id, index: hoverIndex})
  }
}

export function DragHandle({className = '', ...props}) {
  const ref = useRef()
  const {setDrag} = useDragAndDropContext()
  const {id, draggableRef, uiIndex} = useDraggableContext()

  return (
    <div
      ref={ref}
      role="presentation"
      className={`${className} drag-handle`}
      onDragStart={(event) =>
        onDragStart(event, setDrag, draggableRef, id, uiIndex)
      }
      onDragEnd={() => {
        setDrag(null)
      }}
      draggable="true"
      {...props}
    >
      <div className="i-drag-handle fs-00 lh-sm" />
      <div className="i-drag-handle fs-00 lh-sm" />
    </div>
  )
}

DragHandle.propTypes = {
  className: PropTypes.string,
}

export function Draggable({
  El = 'div',
  className = '',
  id,
  uiIndex,
  children,
  ...props
}) {
  const draggableRef = useRef()
  const {drag, setDrag, onMoveInternal} = useDragAndDropContext()

  return (
    <DraggableContext.Provider value={{draggableRef, id, uiIndex}}>
      <El
        ref={draggableRef}
        className={`${className} draggable ${
          !!drag && drag.index === uiIndex ? 'dragging' : ''
        }`}
        id={id}
        onDragOver={(event) =>
          onDragOver(event, setDrag, drag, id, uiIndex, onMoveInternal)
        }
        onDragEnter={(event) => event.preventDefault()}
        onDrop={(event) => event.preventDefault()}
        {...props}
      >
        {children}
      </El>
    </DraggableContext.Provider>
  )
}

Draggable.propTypes = {
  El: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  uiIndex: PropTypes.number.isRequired,
  children: PropTypes.node,
}
