import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {
  CartShape,
  DataImportDataShape,
  DataImportColumnsToDataMapShape,
} from '../../../../common/PropTypes.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import Checkbox from '../../../../common/components/Form/Checkbox.js'
import {cartsSortedByNameSelector} from '../../../../data/carts.js'
import BaseEditForm from '../BaseEditForm.js'

export class EditForm extends Component {
  static propTypes = {
    data: DataImportDataShape.isRequired,
    updatePayload: PropTypes.func.isRequired,
    cartsSortedByName: PropTypes.arrayOf(CartShape).isRequired,
    columnsToDataMap: DataImportColumnsToDataMapShape.isRequired,
  }

  onCartLinkChange = (value) => {
    this.updatePayload({cart_id: value})
  }

  onSKUChange = (value) => {
    this.updatePayload({sku: value})
  }

  onMaxExportQtyChange = (value) => {
    this.updatePayload({max_export_qty: value})
  }

  onFulfillmentChannelChange = (value) => {
    this.updatePayload({fulfillment_channel: value})
  }

  onFulfillmentLatencyChange = (value) => {
    this.updatePayload({fulfillment_latency: value})
  }

  onSyncChange = (value) => {
    this.updatePayload({sync: value})
  }

  updatePayload = (payloadDelta) => {
    this.props.updatePayload(this.props.data.index, payloadDelta)
  }

  renderCartOptions() {
    const list = [{id: -1, name: ''}, ...this.props.cartsSortedByName]

    return list.map((cart) => (
      <option key={cart.id} value={cart.id}>
        {cart.name}
      </option>
    ))
  }

  render() {
    const {data, columnsToDataMap} = this.props

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <Select
            value={data.payload.cart_id}
            errorMessage={data.errors.cart_id}
            name="cart_id"
            label="Sales Channel"
            className="columns medium-12 wrap--full-select"
            selectClassName="full-width"
            onChange={this.onCartLinkChange}
          >
            {this.renderCartOptions()}
          </Select>
        </Row>
        <Row>
          <TextInput
            value={data.payload.sku}
            errorMessage={data.errors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.max_export_qty || ''}
            errorMessage={data.errors.max_export_qty}
            name="max_export_qty"
            label="Max Export Quantity"
            className="medium-12"
            onChange={this.onMaxExportQtyChange}
            disabled={columnsToDataMap.max_export_qty === -1}
          />
          <TextInput
            value={data.payload.min_export_qty || ''}
            errorMessage={data.errors.min_export_qty}
            name="min_export_qty"
            label="Min Export Quantity"
            className="medium-12"
            onChange={(value) => this.updatePayload({min_export_qty: value})}
            disabled={columnsToDataMap.min_export_qty === -1}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.percent_export_qty || ''}
            errorMessage={data.errors.percent_export_qty}
            name="percent_export_qty"
            label="Percent Export Quantity"
            className="medium-6"
            onChange={(value) =>
              this.updatePayload({percent_export_qty: value})
            }
            disabled={columnsToDataMap.percent_export_qty === -1}
          />
          <TextInput
            value={data.payload.reserve_export_qty || ''}
            errorMessage={data.errors.reserve_export_qty}
            name="reserve_export_qty"
            label="Reserve Export Quantity"
            className="medium-6"
            onChange={(value) =>
              this.updatePayload({reserve_export_qty: value})
            }
            disabled={columnsToDataMap.reserve_export_qty === -1}
          />
        </Row>
        <Row>
          <Select
            value={data.payload.fulfillment_channel}
            errorMessage={data.errors.fulfillment_channel}
            name="fulfillment_channel"
            label="Amazon Fulfillment Channel"
            className="columns medium-12 wrap--full-select"
            selectClassName="full-width"
            onChange={this.onFulfillmentChannelChange}
            disabled={columnsToDataMap.fulfillment_channel === -1}
          >
            <option value="" />
            <option value="DEFAULT">DEFAULT (FBM)</option>
            <option value="AMAZON_NA">AMAZON_NA (FBA US)</option>
            <option value="AMAZON_CA">AMAZON_CA (FBA CA)</option>
            <option value="AMAZON_EU">AMAZON_UK (FBA UK)</option>
          </Select>
        </Row>
        <Row>
          <TextInput
            value={data.payload.fulfillment_latency || ''}
            errorMessage={data.errors.fulfillment_latency}
            name="fulfillment_latency"
            label="Fulfillment Latency"
            className="medium-12"
            onChange={this.onFulfillmentLatencyChange}
            disabled={columnsToDataMap.fulfillment_latency === -1}
          />
        </Row>
        <Row>
          <div className="medium-12 columns margin-top-5 flex--justify">
            <Checkbox
              checked={data.payload.sync}
              errorMessage={data.errors.sync}
              className="columns column--reset margin-right-3"
              name="sync"
              label="Writeback Inventory"
              onChange={this.onSyncChange}
              disabled={columnsToDataMap.sync === -1}
            />
          </div>
        </Row>
      </BaseEditForm>
    )
  }
}

function mapStateToProps(state) {
  return {
    cartsSortedByName: cartsSortedByNameSelector(state),
    columnsToDataMap: state.ui.dataImport.columnsToDataMap,
  }
}

export default connect(mapStateToProps)(EditForm)
