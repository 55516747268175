import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import {ReturnOrderLineShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {returnOrderSelector} from '../../../redux/selectors/data/returnOrders.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {returnOrderCodesSortedByCodeSelector} from '../../../redux/selectors/data/returnOrderCodes.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'

const MODAL_FORM = 'ASSIGN_RETURN_ORDER_LINE_CODE_MODAL_FORM'
export const ASSIGN_RETURN_ORDER_LINE_CODE = 'ASSIGN_RETURN_ORDER_LINE_CODE'

export function showAssignReturnOrderLineCodeModal(
  referenceID,
  lineIDs,
  code = null,
) {
  return setForm(MODAL_FORM, {
    referenceID,
    lineIDs,
    code,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function assignReturnOrderLineCodes() {
  return {
    type: ASSIGN_RETURN_ORDER_LINE_CODE,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* assignReturnOrderLineCode(referenceID, lineID, code) {
  const {json} = yield call(
    apiverson.put,
    `/return_order/${encodeURIComponent(referenceID)}/line/${lineID}`,
    {code},
  )

  yield put(setReturnOrder(json))
}

export function* assignReturnOrderLineCodeWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, lineIDs, code} = yield select(modalFormSelector)
    const lineCount = lineIDs.length

    yield all(
      lineIDs.map((lineID) =>
        call(assignReturnOrderLineCode, referenceID, lineID, code),
      ),
    )

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${lineCount} RMA Line${lineCount !== 1 ? 's were' : ' was'} updated`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error emailing RMA: ${err.message || err.error_message}`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function AssignReturnOrderLineCodeModal({form, codes, firstLine, ...props}) {
  const lineCount = form.lineIDs.length

  return (
    <ConfirmModal
      title="Assign Return Code"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => props.assignReturnOrderLineCodes()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {lineCount === 1 && (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              <strong>{firstLine.product_name}</strong>
            </div>
            <div className="fs-00">{firstLine.sku}</div>
          </li>
        )}
        <li className="list__item--form list__item--no-style fs-01">
          Select a return code for {lineCount !== 1 ? lineCount : '1'} line
          {lineCount !== 1 ? 's' : ''}:
        </li>
        <li>
          <label htmlFor="__unset__">
            <input
              className="margin-right-7"
              type="radio"
              name="return_order_line_code"
              id="__unset__"
              value="__unset__"
              checked={form.code === null}
              onChange={() => props.updateModalForm({code: null})}
            />
            Do not apply return code
          </label>
        </li>
        {codes.map((code) => (
          <li key={code.code}>
            <label htmlFor={code.code}>
              <input
                className="margin-right-7"
                type="radio"
                name="return_order_line_code"
                id={code.code}
                value={code.code}
                checked={code.code === form.code}
                onChange={() => props.updateModalForm({code: code.code})}
              />
              {code.code}{' '}
              {code.description && (
                <span className="unbold">({code.description})</span>
              )}
            </label>
          </li>
        ))}
      </ul>
    </ConfirmModal>
  )
}

AssignReturnOrderLineCodeModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    code: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  firstLine: ReturnOrderLineShape.isRequired,
  assignReturnOrderLineCodes: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  const returnOrder = returnOrderSelector(state, {
    referenceID: form.referenceID,
  })
  const firstLine = returnOrder.lines.find(
    ({line_id}) => line_id === form.lineIDs[0],
  )

  return {
    codes: returnOrderCodesSortedByCodeSelector(state),
    firstLine,
  }
}

const mapDispatchToProps = {
  assignReturnOrderLineCodes,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(AssignReturnOrderLineCodeModal),
  modalFormSelector,
)
