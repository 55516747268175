import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
const MODAL_FORM = 'EDIT_MOCK_BARCODE_VALUES_FORM'

export function showEditMockBarcodeValuesModal() {
  setForm(MODAL_FORM, {
    valuesText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockBarcodeValuesModal({form}) {
  const query = useSelector(querySelector)
  const values = ensureArray(query.value)

  useEffect(() => {
    updateModalForm({
      valuesText: values.join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Barcode Values"
      onConfirm={() => {
        const values = form.valuesText
          .replace(/[,]/g, '\n')
          .split('\n')
          .filter((value) => !!value)
          .map((value) => value.trim())

        let texts = ensureArray(query.text)

        while (values.length !== texts.length) {
          if (texts.length < 1) {
            break
          }

          if (values.length > texts.length) {
            texts.push(texts[texts.length - 1])
          } else {
            texts.pop()
          }
        }

        mockAbodeUpdateResourceQuery({
          value: values,
          text: texts,
        })

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock Barcode Values</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Barcode Values"
            rows="10"
            id="values"
            value={form.valuesText || ''}
            onChange={(value) => updateModalForm({valuesText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockBarcodeValuesModal.propTypes = {
  form: PropTypes.shape({
    valuesText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockBarcodeValuesModal, modalFormSelector)
