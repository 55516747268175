import {useEffect, useState} from 'react'

import ConfigSelect from '../ConfigSelect.js'
import ShipperOption from './ShipperOption.js'

import {
  updateShipperID,
  labelShipperIDSelector,
  validShipperIDsSelector,
  onLabelPropertyValueChange,
} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export const LABEL_PROPERTY_SHIPPER_ID = 'shipper_id'

export default function ShipperSelect() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const [resetShipper, setResetShipper] = useState(false)
  const shipperID = useSelector((state) =>
    labelShipperIDSelector(state, {labelInfoID}),
  )
  const shipperIDs = useSelector((state) =>
    validShipperIDsSelector(state, {labelInfoID}),
  )

  useEffect(() => {
    if (!shipperIDs.includes(shipperID) && shipperIDs.length) {
      if (shipperID) {
        setResetShipper(true)
      }
      updateShipperID(labelInfoID, shipperIDs[0])
    }
  }, [shipperID, shipperIDs])

  useEffect(() => {
    if (!shipperID) {
      return
    }

    onLabelPropertyValueChange(
      labelInfoID,
      shipperType,
      LABEL_PROPERTY_SHIPPER_ID,
    )
  }, [shipperID])

  return (
    <ConfigSelect
      value={shipperID}
      className="list__item--shipping-shipperIDs flex-grow"
      label="Carrier"
      required
      onChange={(value) => {
        updateShipperID(labelInfoID, Number(value))
        setResetShipper(false)
      }}
      noEmptyOption
      labelProperty={LABEL_PROPERTY_SHIPPER_ID}
      errorMessage={
        resetShipper ? 'Selected Carrier Archived, Resetting to Default' : null
      }
    >
      {shipperIDs.map((id) => (
        <ShipperOption shipperID={id} key={id} />
      ))}
    </ConfigSelect>
  )
}
