import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import Dimensions from '../../../common/components/Dimensions.js'
import {
  lengthSelector,
  heightSelector,
  widthSelector,
} from '../../../data/products.js'
import {showProductDimensionsModal} from '../Modals/ProductDimensionsModal.js'

export function DimensionsCell({sku, length, height, width, ...props}) {
  return (
    <td className="table__td table__td--product-list-dims">
      <ButtonLink
        className={classNames({darkest: length || width || height})}
        onClick={() =>
          props.showProductDimensionsModal([sku], length, height, width)
        }
      >
        <Dimensions length={length} height={height} width={width} />
      </ButtonLink>
    </td>
  )
}

DimensionsCell.propTypes = {
  sku: PropTypes.string.isRequired,
  length: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  showProductDimensionsModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {sku}) {
  return {
    length: lengthSelector(state, {sku}),
    height: heightSelector(state, {sku}),
    width: widthSelector(state, {sku}),
  }
}

const mapDispatchToProps = {
  showProductDimensionsModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsCell)
