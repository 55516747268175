import PropTypes from 'prop-types'

import AdditionalOptions from './AdditionalOptions.js'
import ShipFrom from './Fields/ShipFrom.js'
import PackingList from './Fields/PackingList.js'
import Notify from './Fields/Notify.js'
import Weight from './Fields/Weight.js'
import PackagingWeight from './Fields/PackagingWeight.js'
import SingleDimensions from './Fields/Dimensions/SingleDimensions.js'
import AttachCustomsInfo from './SingleLabelConfigForm/AttachCustomsInfo.js'
import ReferenceNumber from './Fields/ReferenceNumber.js'
import DeliveryConfirmation from './Fields/DeliveryConfirmation.js'
import CertificateOrigin from './Fields/CertificateOrigin.js'
import SaturdayDelivery from './Fields/SaturdayDelivery.js'
import DirectDelivery from './Fields/UPS/DirectDelivery.js'
import AdditionalHandling from './Fields/UPS/AdditionalHandling.js'
import DeclaredValue from './Fields/DeclaredValue.js'
import ResidentialAddress from './Fields/ResidentialAddress.js'
import DryIceWeight from './Fields/DryIceWeight.js'
import ShipperRelease from './Fields/UPS/ShipperRelease.js'
import Payor from './Fields/Payor.js'
import DutiesPayor from './Fields/DutiesPayor.js'
import MailInnovations from './Fields/UPS/MailInnovations.js'
import USPSEndorsement from './Fields/UPS/USPSEndorsement.js'
import ReasonForExport from './Fields/ReasonForExport.js'
import CostCenter from './Fields/UPS/CostCenter.js'
import PackageBillType from './Fields/PackageBillType.js'
import AlternateReturnToAddress from './Fields/AlternateReturnToAddress.js'
import AttachHazmatInfo from './SingleLabelConfigForm/AttachHazmatInfo.js'
import TaxInfo from './Fields/TaxInfo.js'
import EnableNotifications from './Fields/EnableNotifications.js'
import CarrierPickup from './Fields/AmazonSFP/CarrierPickup.js'
import IncludeImporterOfRecord from './SingleLabelConfigForm/IncludeImporterOfRecord.js'
import ForwardDate, {
  PICKUP_DATE_VALID_SHIPPER_TYPES,
  SHIP_DATE_VALID_SHIPPER_TYPES,
} from './Fields/ForwardDate.js'
import PickupDateRange from './Fields/PickupDateRange.js'
import DangerousGoods from './Fields/DangerousGoods.js'
import ETDService from './Fields/FedEx/ETDService.js'
import HoldAtLocation from './Fields/HoldAtLocation.js'
import HoldForPickup from './Fields/Endicia/HoldForPickup.js'
import ServiceEndorsement from './Fields/ServiceEndorsement.js'
import NonstandardDay from './Fields/DHL/NonstandardDay.js'
import NonstandardContents from './Fields/DHL/NonstandardContents.js'
import FederalTaxID from './Fields/DHL/FederalTaxID.js'
import StateTaxID from './Fields/DHL/StateTaxID.js'
import SmartPostIndicia from './Fields/FedEx/SmartPostIndicia.js'
import SmartPostHub from './Fields/FedEx/SmartPostHub.js'
import AlcoholShipmentLicense from './Fields/FedEx/AlcoholShipmentLicense.js'
import SignatureRequired from './Fields/DHL/SignatureRequired.js'
import IsDutiable from './Fields/DHL/IsDutiable.js'
import ForeignTradeRegulation from './Fields/DHL/ForeignTradeRegulation.js'
import ContentsType from './Fields/Endicia/ContentsType.js'
import NondeliveryOption from './Fields/NondeliveryOption.js'
import ServiceOption from './Fields/CanadaPost/ServiceOption.js'
import MailingZipCode from './Fields/MailingZipCode.js'
import DeliveryInstructions from './Fields/FedEx/DeliveryInstructions.js'
import InvoiceNumber from './Fields/InvoiceNumber.js'
import PurchaseOrderNumber from './Fields/PurchaseOrderNumber.js'
import DepartmentNumber from './Fields/DepartmentNumber.js'
import InsuredValue from './Fields/InsuredValue.js'
import ReturnReason from './Fields/ReturnReason.js'
import Description from './Fields/Description.js'
import DryIceRegulationSet from './Fields/DryIceRegulationSet.js'
import SubPackageType from './Fields/SubPackageType.js'
import PharmacyDelivery from './Fields/PharmacyDelivery.js'
import PriorityAlert from './Fields/PriorityAlert.js'
import LabelType from './Fields/LabelType.js'
import {useLabelConfigContext} from './LabelConfigContext.js'
import ETDPreshipmentDocs from './Fields/ETDPreshipmentDocs.js'
import AncillaryEndorsement from './Fields/AncillaryEndorsement.js'

export default function OldLabelConfigForm({
  showUnSetupPackingList,
  showBillTo,
}) {
  const {shipperType} = useLabelConfigContext()
  if (!shipperType) {
    return null
  }

  return (
    <div>
      <ul className="list list--shipping-options margin-bottom-15">
        <li className="list__item--no-style">
          <ul className="list flex flex-wrap flex-wrap-margin-10">
            <Weight />
            <SingleDimensions />
          </ul>
        </li>
        <ShipFrom />
        <AlternateReturnToAddress />
        <PackingList showUnSetupPackingList={showUnSetupPackingList} />
        <ResidentialAddress />
      </ul>
      <AdditionalOptions>
        <ul className="list list--shipping-options">
          <Notify showBillTo={showBillTo} />
          <AttachCustomsInfo />
          <AttachHazmatInfo />
          <TaxInfo />
          <IncludeImporterOfRecord />
          <ETDPreshipmentDocs />

          {/* Checkboxes */}
          <li className="list__item--shipping-options">
            <ul className="list list--shipping-options flex flex-wrap flex-wrap-margin-15 flex-grow">
              <EnableNotifications />
              <CarrierPickup />
              <ETDService />
              <CertificateOrigin />
              <SaturdayDelivery />
              <HoldAtLocation />
              <HoldForPickup />
              <SignatureRequired />
              <IsDutiable />
              <ShipperRelease />
              <AdditionalHandling />
              <DirectDelivery />
              <PharmacyDelivery />
            </ul>
          </li>

          {/* Date */}
          <ForwardDate
            label="Ship Date"
            labelProperty="ship_date"
            validShipperTypes={SHIP_DATE_VALID_SHIPPER_TYPES}
          />
          <ForwardDate
            label="Pickup Date"
            labelProperty="pickup_date"
            validShipperTypes={PICKUP_DATE_VALID_SHIPPER_TYPES}
          />
          <PickupDateRange />

          {/* Weight */}
          <PackagingWeight />
          <DryIceWeight />
          <DryIceRegulationSet />

          {/* Currency */}
          <DeclaredValue />
          <InsuredValue />

          {/* Selects */}
          <DangerousGoods />
          <DeliveryConfirmation />
          <ServiceEndorsement />
          <NonstandardDay />
          <NonstandardContents />
          <Payor />
          <DutiesPayor />
          <SmartPostIndicia />
          <SmartPostHub />
          <AncillaryEndorsement />
          <AlcoholShipmentLicense />
          <ForeignTradeRegulation />
          <ContentsType />
          <ReasonForExport />
          <NondeliveryOption />
          <ServiceOption />
          <PackageBillType />
          <SubPackageType />
          <LabelType />
          <PriorityAlert />

          {/* Text */}
          <FederalTaxID />
          <StateTaxID />
          <MailInnovations />
          <CostCenter />
          <USPSEndorsement />
          <MailingZipCode />
          <DeliveryInstructions />
          <InvoiceNumber />
          <PurchaseOrderNumber />
          <DepartmentNumber />
          <ReturnReason />
          <Description />
          <ReferenceNumber />
        </ul>
      </AdditionalOptions>
    </div>
  )
}

OldLabelConfigForm.propTypes = {
  showUnSetupPackingList: PropTypes.bool,
  showBillTo: PropTypes.bool,
}
