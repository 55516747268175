import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {
  MODAL_NAME,
  SET_INSTRUCTIONS,
  SET_IS_SAVING,
  SET_SERVER_ERROR,
} from '../../../actions/ui/modals/editInstructionsModal.js'

const MODAL_STATE = INITIAL_STATE.ui.modals.editInstructionsModal

function poID(state = MODAL_STATE.poID, action = {}) {
  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.poID
  }

  return state
}

function instructions(state = MODAL_STATE.instructions, action = {}) {
  if (action.type === SET_INSTRUCTIONS) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.instructions || ''
  }

  return state
}

function isSaving(state = MODAL_STATE.isSaving, action = {}) {
  if (action.type === SET_IS_SAVING) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return MODAL_STATE.isSaving
  }

  return state
}

function serverError(state = MODAL_STATE.serverError, action = {}) {
  if (action.type === SET_SERVER_ERROR) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return MODAL_STATE.serverError
  }

  return state
}

export default combineReducers({
  poID,
  instructions,
  isSaving,
  serverError,
})
