import {NEW_ID} from '../../../../../../common/constants/index.js'
import {stringifyURL} from '../../../../../../common/querystring.js'
import {navigate} from '../../../../ui/index.js'

export const BASE = 'ordoro/ui/settings/shippers/forms/amazonShipper'
export const SAVE_SHIPPER_AMAZON_SHIPPER = `${BASE}/SAVE_SHIPPER_AMAZON_SHIPPER`

export function authorize(id) {
  const params = {
    shipperID: id && id !== NEW_ID ? id : undefined,
  }

  return navigate(stringifyURL('/verde/amazon_shipper/start', params))
}

export function saveShipperAmazonShipper(shipperID) {
  return {
    type: SAVE_SHIPPER_AMAZON_SHIPPER,
    payload: shipperID,
  }
}
