import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import formConnect from '../../common/formConnect.js'
import {OrderShape} from '../../common/PropTypes.js'
import {useInventoryAllocationSelector} from '../../data/company.js'
import {orderSelector} from '../../data/orders.js'
import Modals from '../OrderListPage/Modals/index.js'
import ListHeader from '../iverson/components/OrderListPage/ListHeader/index.js'
import ActionPanel from '../OrderListPage/ActionPanel/index.js'
import OrderTags from '../iverson/components/OrderListPage/Fields/OrderTags.js'
import OrderAllocationIcon from '../iverson/components/OrderListPage/Fields/OrderAllocationIcon.js'
import Details from './Details.js'
import OrderNotFound from './OrderNotFound.js'
import {OrderDetailFormShape} from './orderDetailActions.js'
import {
  orderDetailFormSelector,
  orderDetailOrderNumberSelector,
} from './orderDetailSelectors.js'
import {
  hasOrderManageRevisionPermissionSelector,
  hasOrderPermissionSelector,
} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {showOrderRevisionModal} from '../OrderListPage/Modals/OrderRevisionModal.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'

export function OrderDetailPage({
  form,
  order,
  orderNumber,
  useInventoryAllocation,
  hasOrderPermission,
  hasOrderManageRevisionPermission,
}) {
  useEffect(() => {
    document.title = orderNumber
      ? `${orderNumber} - Order Details`
      : 'Order Details'
  }, [orderNumber])

  if (!hasOrderPermission) {
    return <NoPermissionsPage />
  }

  if (form.notFound) {
    return <OrderNotFound orderNumber={orderNumber} />
  }

  if (!order) {
    return (
      <div className="margin-top-40">
        <div className="loading align-center">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
            Loading...
          </strong>
        </div>
      </div>
    )
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <ListHeader />
            <hr className="hr--detail-page margin-top-3" />
            {order.has_revision && (
              <div className="alert alert--warning align-center margin-top-10 margin-bottom-15">
                <p className="fs-01 margin-bottom-0 v-align-middle">
                  <span
                    className="i-exclamation-triangle text--warning-orange fs-03 lh-sm margin-right-7 v-align-middle"
                    aria-hidden="true"
                  />
                  <strong className="inline-block v-align-middle margin-right-10">
                    This order has been modified outside of Ordoro
                  </strong>
                  {hasOrderManageRevisionPermission && (
                    <ButtonPrimary
                      size="sm"
                      isOutlined
                      onClick={() => showOrderRevisionModal([orderNumber])}
                    >
                      Manage Revisions
                    </ButtonPrimary>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-8 columns">
            <div>
              <div className="margin-bottom-25">
                <h2 className="page__title page__title--inner inline-block v-align-middle margin-right-15">
                  <strong>{orderNumber}</strong>
                </h2>
                {useInventoryAllocation && (
                  <div className="inline-block">
                    <OrderAllocationIcon
                      allocationStatus={order.allocation_status}
                      className="fs-00"
                    />
                  </div>
                )}
                {order.tags.length !== 0 && (
                  <div
                    className={classNames('', {
                      'inline-block v-align-middle': !useInventoryAllocation,
                      'margin-top-5': useInventoryAllocation,
                    })}
                  >
                    <OrderTags order={order} />
                  </div>
                )}
              </div>
              <Details />
            </div>
          </div>
          <div className="medium-4 columns">
            <ActionPanel />
          </div>
        </div>
      </div>
    </div>
  )
}

OrderDetailPage.propTypes = {
  form: OrderDetailFormShape.isRequired,
  order: OrderShape,
  orderNumber: PropTypes.string,
  useInventoryAllocation: PropTypes.bool.isRequired,
  hasOrderPermission: PropTypes.bool.isRequired,
  hasOrderManageRevisionPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const orderNumber = orderDetailOrderNumberSelector(state)

  return {
    order: orderSelector(state, {orderNumber}),
    orderNumber,
    useInventoryAllocation: useInventoryAllocationSelector(state),
    hasOrderPermission: hasOrderPermissionSelector(state),
    hasOrderManageRevisionPermission:
      hasOrderManageRevisionPermissionSelector(state),
  }
}

export default formConnect(
  connect(mapStateToProps)(OrderDetailPage),
  orderDetailFormSelector,
)
