import {useEffect} from 'react'

import {RETURN_ORDER_PLURAL_URI_COMPONENT} from '../../common/constants/ReturnOrders.js'
import LabelConfig from '../LabelConfig/index.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  hasShippingInfoSelector,
  returnOrderSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {needsWarehouseSelector} from '../../data/warehouses.js'
import {needsShipperSelector} from '../../data/shippers.js'

import Modals from '../ReturnOrderListPage/Modals/index.js'
import ActionsForSelected from '../ReturnOrderListPage/ActionsForSelected.js'
import HasLabelPane from '../ReturnOrderListPage/ActionPanel/HasLabelPane.js'
import ReturnOrderSpeedbumps from '../ReturnOrderListPage/ActionPanel/ReturnOrderSpeedbumps.js'
import {
  returnOrderDetailReferenceIDSelector,
  returnOrderDetailFormSelector,
  returnOrderDetailLabelIDSelector,
} from './returnOrderDetailSelectors.js'
import {ReturnOrderDetailFormShape} from './returnOrderDetailActions.js'
import Details from './Details.js'
import {
  labelInfosSelector,
  validShipperIDsSelector,
} from '../../data/labelInfos/index.js'
import {hasReturnOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {
  hasUseReturnOrdersFeatureSelector,
  useRMALabelsSelector,
} from '../../data/company.js'
import {onlyIfForm, useSelector} from '../../store.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'

function ReturnOrderDetailPage({form}) {
  const referenceID = useSelector(returnOrderDetailReferenceIDSelector)
  const labelInfos = useSelector(labelInfosSelector)
  let labelInfoIDThatCanHaveLabel = useSelector(
    returnOrderDetailLabelIDSelector,
  )
  labelInfoIDThatCanHaveLabel = labelInfos[labelInfoIDThatCanHaveLabel]
    ? labelInfoIDThatCanHaveLabel
    : null

  const hasShippingInfo = useSelector((state) =>
    hasShippingInfoSelector(state, {
      referenceID: referenceID,
    }),
  )
  const needsWarehouse = useSelector(needsWarehouseSelector)
  const needsShipper = useSelector(needsShipperSelector)
  const returnOrder = useSelector((state) =>
    returnOrderSelector(state, {referenceID: referenceID}),
  )
  const noValidShippers = useSelector((state) =>
    labelInfoIDThatCanHaveLabel
      ? validShipperIDsSelector(state, {
          labelInfoID: labelInfoIDThatCanHaveLabel,
        }).length === 0
      : false,
  )
  const hasReturnOrderPermission = useSelector(hasReturnOrderPermissionSelector)
  const hasUseReturnOrdersFeature = useSelector(
    hasUseReturnOrdersFeatureSelector,
  )
  const useRMALabels = useSelector(useRMALabelsSelector)

  useEffect(() => {
    document.title = referenceID ? `${referenceID} - RMA` : 'RMA'
  }, [referenceID])

  if (!hasUseReturnOrdersFeature) {
    return null
  }

  if (!hasReturnOrderPermission) {
    return <NoPermissionsPage />
  }

  if (!referenceID) {
    return null
  }

  if (form.isLoading) {
    return (
      <div className="margin-top-40">
        <div className="loading align-center">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
            Loading...
          </strong>
        </div>
      </div>
    )
  }

  if (!returnOrder) {
    return (
      <dl className="list--empty-content">
        <dt className="fs-02 margin-bottom-10">
          <span>RMA ‘{referenceID}’ could not be found</span>
        </dt>
      </dl>
    )
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <li className="inline-block v-align-base">
                  <a
                    href={`#/${RETURN_ORDER_PLURAL_URI_COMPONENT}`}
                    className="btn btn--action"
                    title="Back to returns"
                  >
                    &#8592; Returns
                  </a>
                </li>
                <ActionsForSelected referenceIDs={[referenceID]} />
              </ul>
            </div>
            <hr className="hr--detail-page margin-top-3" />
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-8 columns">
            <Details key={referenceID} />
          </div>
          <div className="medium-4 columns">
            <div className="panel--actions panel--order-actions margin-top-20">
              <ul className="list--tabs flex-wrap clearfix">
                <li className="list__item--tab active">
                  <button className="btn btn--tab" type="button">
                    Return Label
                  </button>
                </li>
              </ul>
              {!useRMALabels ? (
                <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
                  <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
                    <p className="align-center fs-01 lh-md  margin-bottom-15">
                      <strong>Create Return Labels for Your Customers!</strong>
                    </p>
                    <p className="fs-00 lh-md margin-bottom-15">
                      A great returns experience goes a long way for customer
                      satisfaction and retention.
                    </p>

                    <p className="fs-00 lh-lg margin-bottom-20">
                      To gain access to Return Labels, you need to be on the{' '}
                      <strong>Premium Plan of the Shipping App</strong>.
                    </p>

                    <div>
                      <ButtonPrimary
                        size="x-md"
                        className="meta--feature-lock-rma-label-panel"
                        alt
                        onClick={() => showUpgradeModal()}
                      >
                        Manage Your Apps
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              ) : needsShipper || needsWarehouse || noValidShippers ? (
                <ReturnOrderSpeedbumps
                  needsWarehouse={needsWarehouse}
                  needsShipper={needsShipper}
                  noValidShippers={noValidShippers}
                />
              ) : hasShippingInfo ? (
                <HasLabelPane referenceIDs={[referenceID]} />
              ) : (
                <LabelConfig
                  labelInfoIDs={
                    labelInfoIDThatCanHaveLabel
                      ? [labelInfoIDThatCanHaveLabel]
                      : []
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ReturnOrderDetailPage.propTypes = {
  form: ReturnOrderDetailFormShape.isRequired,
}

export default onlyIfForm(ReturnOrderDetailPage, returnOrderDetailFormSelector)
