import {useEffect} from 'react'

import {dispatch, useSelector} from '../../store.js'
import {ORDER_DETAIL_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  setupOrderDetailForm,
  removeOrderDetailForm,
  loadOrderDetailOrder,
} from './orderDetailActions.js'
import {DETAIL_MODE} from '../../common/constants/index.js'
import {orderListQuerySelector} from '../OrderListPage/orderListSelectors.js'

export default function OrderDetailLocationListener() {
  const query = useSelector(orderListQuerySelector)

  useEffect(() => {
    if (query.mode !== DETAIL_MODE || !query.orderNumber) {
      return
    }

    dispatch(setCurrentPage(ORDER_DETAIL_PAGE))

    setupOrderDetailForm()

    loadOrderDetailOrder()

    return () => removeOrderDetailForm()
  }, [query.mode, query.orderNumber])

  return null
}
