import {useEffect} from 'react'

import {usesInventorySelector} from '../../data/company.js'
import {
  refreshProductList,
  hasSelectedSKUsSelector,
  productListQuerySelector,
} from './productListFunctions.js'
import ProductListItemsCount from './ProductListItemsCount.js'
import RefreshProducts from './RefreshProducts.js'
import ExpandAll from './ExpandAll.js'
import ProductSort from './ProductSort.js'
import Actions from './Actions.js'
import ActionsForSelected from './ActionsForSelected.js'
import Pro from './Pro/index.js'
import Basic from './Basic/index.js'
import Modals from './Modals/index.js'
import ProductListOmniBar from './ProductListOmniBar.js'
import {hasProductPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {useSelector} from '../../store.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showProductListFilterModal} from './Modals/ProductListFilterModal/productListFilterModalFunctions.js'

export default function ProductListPage() {
  useEffect(() => {
    document.title = 'Products'
  }, [])

  const query = useSelector(productListQuerySelector)
  const hasSelectedSKUs = useSelector(hasSelectedSKUsSelector)
  const usesInventory = useSelector(usesInventorySelector)
  const hasProductPermission = useSelector(hasProductPermissionSelector)

  useEffect(() => {
    refreshProductList()
  }, [query])

  if (!hasProductPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="padding-top-10 padding-bottom-10 flex--justify">
              <ul className="list list--no-style list--product-top-bar">
                <ProductListItemsCount />
                <RefreshProducts />
                <ExpandAll />
                <li className="inline-block divider--left">
                  <ButtonPrimary
                    size="xx-sm"
                    isOutlined
                    onClick={() => showProductListFilterModal({query})}
                  >
                    <span
                      className="i-filters fs-00 lh-0 v-align-middle margin-right-5"
                      aria-hidden="true"
                    />
                    <span className="fs-n1 v-align-middle">Filters</span>
                  </ButtonPrimary>
                </li>
                <ProductSort />
                {hasSelectedSKUs ? <ActionsForSelected /> : <Actions />}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <ProductListOmniBar />
          </div>
        </div>
        {usesInventory ? <Pro /> : <Basic />}
      </div>
    </div>
  )
}
