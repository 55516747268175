import PropTypes from 'prop-types'

export default function Unusable({children, ids, showDeselect, deselect}) {
  if (ids.length === 0) {
    return null
  }

  return (
    <li className="list__item list__item--mb0-last fs-n1">
      <span className="margin-right-5">{children}</span>
      {showDeselect && (
        <button
          className="btn btn--link v-align-base"
          type="button"
          onClick={() => deselect(ids)}
        >
          Deselect
        </button>
      )}
    </li>
  )
}

Unusable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  showDeselect: PropTypes.bool.isRequired,
  deselect: PropTypes.func.isRequired,
}
