import PropTypes from 'prop-types'

import {PACK_MODE} from '../../../common/constants/PackingOrderModal.js'
import {PACK_SHIP_PAGE} from '../../../common/constants/Pages.js'
import {
  PluralBlock,
  IfSingle,
  IfPlural,
  Count,
} from '../../../common/components/Plural.js'
import {resetPackPanel} from './packPanelActions.js'
import {useSelector} from '../../../store.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'

export default function PackPanelHeader({
  orderNumbers,
  mode,
  verifiedLineCount,
  totalLineCount,
}) {
  const currentPage = useSelector(currentPageSelector)

  if (currentPage === PACK_SHIP_PAGE) {
    return (
      <div className="row padding-top-20">
        <div className="medium-12 columns">
          <ul className="list list--no-style flex--justify margin-bottom-25">
            <li className="fs-02 lh-md inline-block margin-right-5 margin-bottom-0">
              <span>{verifiedLineCount}</span>/<span>{totalLineCount}</span>{' '}
              Lines {mode === PACK_MODE ? 'Verified' : 'Picked'}
            </li>
            <li className="inline-block margin-bottom-0">
              <button
                className="btn btn--primary btn--primary-ol btn--x-sm  meta-verify-order-modal-reset-button margin-bottom-0"
                type="button"
                title={`Reset the ${
                  mode === PACK_MODE ? 'verified' : 'picked'
                } quantities`}
                onClick={() => resetPackPanel()}
              >
                Reset Lines
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="row padding-top-20">
      <div className="medium-12 columns">
        <div className="flex--justify margin-bottom-25">
          <PluralBlock array={orderNumbers}>
            <p className="fs-02 lh-md margin-bottom-0">
              <IfSingle>
                <strong>{orderNumbers[0]}</strong>
              </IfSingle>
              <IfPlural>
                <Count /> orders
              </IfPlural>
            </p>
          </PluralBlock>
          <ul className="list list--no-style">
            <li className="fs-02 lh-md inline-block margin-right-5 margin-bottom-0">
              <span>{verifiedLineCount}</span>/<span>{totalLineCount}</span>{' '}
              Lines {mode === PACK_MODE ? 'Verified' : 'Picked'}
            </li>
            <li className="inline-block">
              <button
                className="btn btn--link lighter no-underline meta-verify-order-modal-reset-button"
                type="button"
                title={`Reset the ${
                  mode === PACK_MODE ? 'verified' : 'picked'
                } quantities`}
                onClick={() => resetPackPanel()}
              >
                <span className="i-refresh fs-02 lh-md v-align-baseline" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

PackPanelHeader.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  mode: PropTypes.string.isRequired,
  verifiedLineCount: PropTypes.number.isRequired,
  totalLineCount: PropTypes.number.isRequired,
}
