import {useCallback, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {PackLineShape} from '../../../common/PropTypes.js'
import AudioFile from '../../../common/components/AudioFile.js'
import {hasOrderForceVerifyPackingPermissionSelector} from '../../../data/me.js'
import successSoundFilePath from '../../../../images/success-01.mp3'
import {forceVerifyLine} from './packPanelActions.js'
import {useSelector} from '../../../store.js'

export default function PackLine({
  line: {
    path,
    name,
    sku,
    upc,
    location,
    orderedQuantity,
    verifiedQuantity,
    level,
    hasChildren,
    orderLineIDSets,
  },
  status,
}) {
  const [highlight, setHighlight] = useState(false)
  const [prevVerifiedQuantity, setPrevVerifiedQuantity] =
    useState(verifiedQuantity)
  const refs = useRef({highlightTimeout: null})
  const hasOrderForceVerifyPackingPermission = useSelector(
    hasOrderForceVerifyPackingPermissionSelector,
  )
  const startHighlightAnimation = useCallback(() => {
    // The weird dance here is to make sure that the highlight class gets
    // removed and added again so that the animation starts over from the
    // beginning if it is in the middle of running
    setTimeout(() => {
      setHighlight(false)

      setTimeout(() => {
        setHighlight(true)
      }, 0)

      clearTimeout(refs.current.highlightTimeout)

      refs.current.highlightTimeout = setTimeout(() => {
        setHighlight(false)
      }, 1000)
    }, 0)
  }, [])
  useEffect(() => {
    if (prevVerifiedQuantity < verifiedQuantity) {
      startHighlightAnimation()
    }

    setPrevVerifiedQuantity(verifiedQuantity)
  }, [verifiedQuantity])
  useEffect(() => {
    return () => clearTimeout(refs.current.highlightTimeout)
  }, [])

  const serialNumbers = orderLineIDSets.reduce((prev, {serialNumbers}) => {
    prev.push(...serialNumbers)
    return prev
  }, [])

  return (
    <tr
      className={classNames('tr--scan-success', {
        in: highlight,
        'tr--parent-sku': level === 0,
        'tr--has-children': level === 0 && hasChildren,
        'tr--child-sku': level > 0,
      })}
    >
      <td className="table__td">
        <dl
          className={classNames('list', {
            'list--parent-sku': level === 0,
            'list--child-sku': level > 0,
          })}
          style={{marginLeft: `${level * 1}rem`}}
        >
          {(highlight || status === 'exiting') && (
            <AudioFile src={successSoundFilePath} />
          )}
          <dt className="list__title">{name}</dt>
          <dd className="list__item">SKU: {sku}</dd>
          {upc && <dd className="list__item">UPC: {upc}</dd>}
          {!upc && <dd className="list__item">No UPC set for this product</dd>}
          {location && <dd className="list__item">Location: {location}</dd>}
          {serialNumbers.length > 0 && (
            <dd className="list__item">
              Serial Numbers: {serialNumbers.join(', ')}
            </dd>
          )}
        </dl>
      </td>
      <td className="table__td align-center">
        <strong
          className={classNames('lh-md', {
            'fs-02': level == 0,
            'fs-01': level > 0,
          })}
        >
          {orderedQuantity}
        </strong>
      </td>
      <td className="table__td table__td--verified overflow-hidden relative border-left--light align-center">
        <strong
          className={classNames('lh-md verified-quantity', {
            'fs-02': level == 0,
            'fs-01': level > 0,
          })}
        >
          {status === 'exiting' ? orderedQuantity : verifiedQuantity}
        </strong>
        {hasOrderForceVerifyPackingPermission && (
          <button
            className="btn btn--link btn--force-verify lighter no-underline"
            type="button"
            title="Force-verify that this line item has been packed correctly"
            onClick={() => forceVerifyLine(path)}
          >
            <span className="i-check-circle fs-01 lh-0" />
          </button>
        )}
      </td>
    </tr>
  )
}

PackLine.propTypes = {
  line: PackLineShape.isRequired,
  status: PropTypes.string.isRequired,
}
