import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function SettingsNavItem({
  href,
  isActive,
  currentSettingsPanel,
  resource,
  title,
  disabled,
}) {
  if (disabled) {
    return null
  }

  isActive =
    isActive === undefined ? currentSettingsPanel === resource : isActive
  href = href === undefined ? `#/settings/${resource}` : href

  return (
    <dd className={classNames('list__item--side-nav', {disabled})}>
      <a
        className={classNames('link--side-nav', {
          'link--side-nav-active': isActive,
          disabled,
        })}
        href={href}
        title={title}
        onClick={(event) => disabled && event.preventDefault()}
      >
        <span>{title}</span>
      </a>
    </dd>
  )
}

SettingsNavItem.propTypes = {
  href: PropTypes.string,
  isActive: PropTypes.bool,
  resource: PropTypes.string,
  currentSettingsPanel: PropTypes.string,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}
