import {showUpgradeModal} from '../../Modals/UpgradeModal.js'

export default function AccountingFeaturePage() {
  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
      <div
        className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-02 lh-md  margin-bottom-15">
        <strong>Accounting is not enabled in your account.</strong>
      </p>
      <p className="fs-01 margin-bottom-20">
        To access our QuickBooks Online integration, you need to upgrade the
        Shipping App to the Premium plan or add the Inventory or Dropship App.
      </p>
      <div className="margin-bottom-10">
        <button
          className="btn btn--primary btn--primary-alt btn--md fs-01"
          onClick={() => showUpgradeModal()}
        >
          <strong>Manage Your Apps</strong>
        </button>
      </div>
    </div>
  )
}
