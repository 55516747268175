import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import Loading from '../../common/components/List/Loading.js'
import {refreshMOList} from './moListActions.js'
import {moListQuerySelector, isLoadingSelector} from './moListSelectors.js'
import MOListFooter from './MOListFooter.js'
import Table from './Table.js'

function List({params, isLoading}) {
  useEffect(() => {
    refreshMOList()
  }, [params])

  return (
    <>
      {isLoading ? (
        <div className="medium-12 columns list--empty-content margin-top-40 margin-bottom-40">
          <Loading />
        </div>
      ) : (
        <div className="medium-12 columns">
          <Table />
          <MOListFooter />
        </div>
      )}
    </>
  )
}

List.propTypes = {
  params: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    params: moListQuerySelector(state),
    isLoading: isLoadingSelector(state),
  }
}

export default connect(mapStateToProps)(List)
