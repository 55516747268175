export const BASE = 'ordoro/data/packingLists'
export const SET_PACKING_LISTS = `${BASE}/SET_PACKING_LISTS`
export const FETCH_PACKING_LISTS = `${BASE}/FETCH_PACKING_LISTS`
export const INIT_PACKING_LISTS = `${BASE}/INIT_PACKING_LISTS`

export function setPackingLists(value) {
  return {
    type: SET_PACKING_LISTS,
    payload: value,
  }
}

export function fetchPackingLists() {
  return {
    type: FETCH_PACKING_LISTS,
  }
}
