import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'

const MODAL_FORM = 'EDIT_MOCK_ADDRESS_IDS_FORM'

export function showEditMockAddressIDsModal() {
  setForm(MODAL_FORM, {
    addressIDsText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockAddressIDsModal({form}) {
  const query = useSelector(querySelector)
  const addressIDs = ensureArray(query.addressIDs)

  useEffect(() => {
    updateModalForm({
      addressIDsText: addressIDs.join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Reference IDs"
      onConfirm={() => {
        const addressIDs = form.addressIDsText
          .replace(/[, ]/g, '\n')
          .split('\n')
          .filter((addressID) => !!addressID)

        mockAbodeUpdateResourceQuery({
          addressIDs: addressIDs,
        })

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock Address IDs</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Address IDs"
            rows="10"
            id="address_ids"
            value={form.addressIDsText || ''}
            onChange={(value) => updateModalForm({addressIDsText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockAddressIDsModal.propTypes = {
  form: PropTypes.shape({
    addressIDsText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockAddressIDsModal, modalFormSelector)
