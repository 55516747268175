import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {
  MODAL_NAME,
  SET_COMMENT,
  SET_IS_ADDING,
  SET_SERVER_ERROR,
} from '../../../actions/ui/modals/addPurchaseOrderCommentModal.js'

const MODAL_STATE = INITIAL_STATE.ui.modals.addPurchaseOrderCommentModal

function poID(state = MODAL_STATE.poID, action = {}) {
  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.poID
  }

  return state
}

function comment(state = MODAL_STATE.comment, action = {}) {
  if (action.type === SET_COMMENT) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return MODAL_STATE.comment
  }

  return state
}

function isAdding(state = MODAL_STATE.isAdding, action = {}) {
  if (action.type === SET_IS_ADDING) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return MODAL_STATE.isAdding
  }

  return state
}

function serverError(state = MODAL_STATE.serverError, action = {}) {
  if (action.type === SET_SERVER_ERROR) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return MODAL_STATE.serverError
  }

  return state
}

export default combineReducers({
  poID,
  comment,
  isAdding,
  serverError,
})
