import {useEffect} from 'react'

export default function PostMessage() {
  return null
}

export function usePostMessage(onMessage, win = window) {
  useEffect(() => {
    function localOnMessage(event) {
      if (onMessage) {
        onMessage(event)
      }
    }

    win.addEventListener('message', localOnMessage)

    return () => win.removeEventListener('message', localOnMessage)
  }, [])
}
