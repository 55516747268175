import PropTypes from 'prop-types'

import {TagShape} from '../../../PropTypes.js'
import {tagColors} from '../../../colors.js'
import className from '../../../className.js'

export default function Tag({tag, onRemove}) {
  return (
    <li
      className={className`list__item--inline list__item--tag tooltip--top ${
        tag.pending && 'op-30'
      }`}
      style={tagColors(tag.color)}
      data-hint={tag.text || tag.name}
    >
      <strong className="tag-name inline-block v-align-middle">
        {tag.text || tag.name}
      </strong>
      {onRemove && (
        <button
          className="btn btn--remove-tag"
          type="button"
          onClick={() => onRemove(tag)}
          disabled={tag.pending}
        >
          <span className="i--close" aria-hidden="true" />
        </button>
      )}
    </li>
  )
}

Tag.propTypes = {
  tag: TagShape.isRequired,
  onRemove: PropTypes.func,
}
