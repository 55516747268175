export default function NoPermissionsPage() {
  return (
    <div className="row margin-top-30 padding-top-30">
      <div className="medium-10 medium-centered columns align-center">
        <div
          className="i-lock-closed fs-05 op-30 lh-sm margin-bottom-0"
          aria-hidden="true"
        />
        <h3 className="fs-03 lh-md margin-top-5 margin-bottom-5">
          <strong>You do not have permission to access this feature.</strong>
        </h3>
        <p className="fs-02">To gain access, contact your administrator.</p>
      </div>
    </div>
  )
}
