import PropTypes from 'prop-types'

import Radio from '../../../common/components/Radio.js'
import {
  REPORT_EXPORT_PRODUCTS,
  REPORT_EXPORT_PRODUCTS_SUPPLIERS,
} from '../../../common/constants/Reports.js'
import {reportSelector} from '../../../data/reports.js'
import {formSelector, getState, updateForm, useForm} from '../../../store.js'
import {ensureArrayOfNumbers} from '../../../common/ensure.js'
import {cleanProductQuery} from '../../ProductListPage/productListFunctions.js'
import ProductFilters from './ProductFilters.js'

/**
 * Take saved report params and transform them into a useable shape for querying.
 * It also translates old params from before we switched this report to use the
 * GET /product query param shape.
 *
 * @param {object} params object of params, either the old shape or one that
 *  mirrors GET /product query params
 * @returns {object} query object used by GET /product
 */
function translateParamsProductListQuery(params) {
  const productQuery = {
    ...params,
  }

  if (params.warehouse_ids) {
    productQuery.warehouse_id = ensureArrayOfNumbers(params.warehouse_ids)
  }

  return cleanProductQuery(productQuery)
}

export function setupExportProductsForm(params = {}) {
  let {
    reportID,
    productQuery = {},
    queryTotalCount = null,
    selectedSKUs = [],
  } = params

  let {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: productQuery}

  productQuery = translateParamsProductListQuery(reportParams)

  delete productQuery.sort
  delete productQuery.limit
  delete productQuery.offset

  const querySelection = selectedSKUs.length ? 'selection' : 'query'

  return {
    querySelection,
    productQuery,
    queryTotalCount,
    selectedSKUs,
  }
}

export function exportProductsErrorsSelector() {
  return {}
}

export function exportProductsPayloadSelector(state, {formName}) {
  const {reportType, productQuery, selectedSKUs, querySelection} = formSelector(
    state,
    {formName},
  )

  let params = productQuery

  if (querySelection === 'selection') {
    params = {sku: selectedSKUs}

    if (productQuery.warehouse_id && productQuery.warehouse_id.length) {
      params.warehouse_id = productQuery.warehouse_id
    }
  }

  return {
    type: reportType,
    params,
  }
}

export default function ExportProductsForm({formName}) {
  const form = useForm(formName)

  return (
    <ul className="list list--no-style">
      <li className="list__item--form padding-bottom-20">
        <p className="fs-00 margin-top-20 margin-bottom-10">
          <strong>Type of product export:</strong>
        </p>
        {form.reportID ? (
          <div className="margin-bottom-10">
            <strong>
              {form.reportType === REPORT_EXPORT_PRODUCTS
                ? 'Product and Inventory Information'
                : 'Product Supplier Assignments'}
            </strong>
          </div>
        ) : (
          <>
            <div className="margin-bottom-10">
              <Radio
                mode="fancy"
                className="margin-left-n03"
                id="warehouse_export"
                label="Product and Inventory Information"
                checked={form.reportType === REPORT_EXPORT_PRODUCTS}
                onChange={() =>
                  updateForm(formName, {
                    reportType: REPORT_EXPORT_PRODUCTS,
                  })
                }
              />
            </div>
            <Radio
              mode="fancy"
              className="margin-left-n03"
              id="supplier_export"
              label="Product Supplier Assignments"
              checked={form.reportType === REPORT_EXPORT_PRODUCTS_SUPPLIERS}
              onChange={() =>
                updateForm(formName, {
                  reportType: REPORT_EXPORT_PRODUCTS_SUPPLIERS,
                })
              }
            />
          </>
        )}
      </li>
      <ProductFilters formName={formName} />
    </ul>
  )
}

ExportProductsForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
