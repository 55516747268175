import isEmpty from 'lodash/isEmpty.js'

import {
  AMAZON,
  AMAZON_CA,
  AMAZON_UK,
  WALMART,
} from '../../../../../common/constants/CartVendorOptions.js'
import api from '../../../../../common/api.js'
import {
  isPresent,
  isPositiveInteger,
  isNumeric,
} from '../../../../../common/utils.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'

import {cartsSelector, cartsByNameSelector} from '../../../../../data/carts.js'
import baseStrategy from './baseStrategy.js'

export default class productCartBridgeStrategy extends baseStrategy {
  static title = 'Update Product/Sales Channel Bridge'
  static requiredColumns = ['sku', 'cart']

  static getExampleRows() {
    return () =>
      api.get('/product/', {limit: 10}).then(({json: data}) => {
        const rows = [
          [
            'SKU *Req',
            'Sales Channel Name *Req',
            'Amazon Fulfillment Channel',
            'Fulfillment Latency',
            'Max Export Quantity',
            'Min Export Quantity',
            'Percent Export Quantity',
            'Reserve Export Quantity',
            'Writeback Inventory',
          ],
        ]

        data.product.forEach((product) => {
          product.carts.forEach((cart) => {
            const extraInfo = cart.extra_info[0] || {}
            rows.push([
              product.sku,
              cart.name,
              extraInfo.fulfillment_channel,
              extraInfo.fulfillment_latency,
              cart.max_export_qty,
              cart.min_export_qty,
              cart.percent_export_qty,
              cart.reserve_export_qty,
              cart.sync,
            ])
          })
        })

        return rows
      })
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      cart: find(/^((sales.channel)|(cart))(.name)?$/i),
      fulfillment_channel: find(/(amazon)?fulfillment.channel/i),
      fulfillment_latency: find(/(fulfillment.latency)|(leadtime.to.ship)/i),
      max_export_qty: find(/max.export.(quantity|qty)/i),
      min_export_qty: find(/min.export.(quantity|qty)/i),
      percent_export_qty: find(/percent.export.(quantity|qty)/i),
      reserve_export_qty: find(/reserve.export.(quantity|qty)/i),
      sync: find(/(writeback.inventory)|(sync)/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const cartsByName = cartsByNameSelector(getState())
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          cartsByName,
        )
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData(row, columnsByName, cartsByName) {
    const data = this.getNewData()
    const cart = cartsByName[row[columnsByName.cart]] || {id: '-1'}
    const fulfillmentChannel = row[columnsByName.fulfillment_channel]
    const sync = this.toBoolean(row[columnsByName.sync])

    data.payload = {
      sku: row[columnsByName.sku],
      cart_id: cart.id,
      fulfillment_channel: fulfillmentChannel
        ? fulfillmentChannel.toUpperCase()
        : '',
      fulfillment_latency: row[columnsByName.fulfillment_latency] || undefined,
      max_export_qty: row[columnsByName.max_export_qty] || undefined,
      min_export_qty: row[columnsByName.min_export_qty] || undefined,
      percent_export_qty: row[columnsByName.percent_export_qty] || undefined,
      reserve_export_qty: row[columnsByName.reserve_export_qty] || undefined,
      sync: columnsByName.sync !== -1 ? sync : undefined,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    if (!isPresent(payload.sku)) {
      errors.sku = 'SKU is required'
    } else {
      delete errors.sku
    }

    if (payload.cart_id === '-1') {
      errors.cart_id = 'Select a valid sales channel'
    } else {
      delete errors.link
    }

    if (
      isPresent(payload.fulfillment_latency) &&
      !isPositiveInteger(payload.fulfillment_latency)
    ) {
      errors.fulfillment_latency =
        'Fulfillment Latency must be a positive number or empty'
    } else {
      delete errors.fulfillment_latency
    }

    if (
      isPresent(payload.max_export_qty) &&
      !isPositiveInteger(payload.max_export_qty)
    ) {
      errors.max_export_qty =
        'Max Export Qty must be a positive number or empty'
    } else {
      delete errors.max_export_qty
    }

    if (
      isPresent(payload.min_export_qty) &&
      !isPositiveInteger(payload.min_export_qty)
    ) {
      errors.min_export_qty =
        'Min Export Qty must be a positive number or empty'
    } else {
      delete errors.min_export_qty
    }

    if (
      isPresent(payload.percent_export_qty) &&
      !isPositiveInteger(payload.percent_export_qty)
    ) {
      errors.percent_export_qty = 'Percent Export Qty must be a positive number'
    } else if (
      isPresent(payload.percent_export_qty) &&
      Number(payload.percent_export_qty) > 100
    ) {
      errors.percent_export_qty =
        'Percent Export Qty must be less than to equal to 100'
    } else {
      delete errors.percent_export_qty
    }

    if (
      isPresent(payload.reserve_export_qty) &&
      !isPositiveInteger(payload.reserve_export_qty)
    ) {
      errors.reserve_export_qty =
        'Reserve Export Qty must be a positive number or empty'
    } else {
      delete errors.reserve_export_qty
    }

    return errors
  }

  static save(data) {
    return (dispatch, getState) => {
      const {
        ui: {
          dataImport: {columnsToDataMap},
        },
      } = getState()
      const carts = cartsSelector(getState())
      const {payload} = data
      const url = `/product/${encodeURIComponent(payload.sku)}/cart/${
        payload.cart_id
      }`
      const vendor = carts[payload.cart_id].vendor
      const params = {}

      if (columnsToDataMap.max_export_qty !== -1) {
        params.max_export_qty = isNumeric(payload.max_export_qty)
          ? Number(payload.max_export_qty)
          : null
      }

      if (columnsToDataMap.min_export_qty !== -1) {
        params.min_export_qty = isNumeric(payload.min_export_qty)
          ? Number(payload.min_export_qty)
          : null
      }

      if (columnsToDataMap.percent_export_qty !== -1) {
        params.percent_export_qty = isNumeric(payload.percent_export_qty)
          ? Number(payload.percent_export_qty)
          : 100
      }

      if (columnsToDataMap.reserve_export_qty !== -1) {
        params.reserve_export_qty = isNumeric(payload.reserve_export_qty)
          ? Number(payload.reserve_export_qty)
          : 0
      }

      if (
        [AMAZON, AMAZON_CA, AMAZON_UK].includes(vendor) &&
        (columnsToDataMap.fulfillment_channel !== -1 ||
          columnsToDataMap.fulfillment_latency !== -1)
      ) {
        params.amazon_extra_info = {}

        if (columnsToDataMap.fulfillment_channel !== -1) {
          params.amazon_extra_info.fulfillment_channel =
            payload.fulfillment_channel
        }

        if (columnsToDataMap.fulfillment_latency !== -1) {
          params.amazon_extra_info.fulfillment_latency = isNumeric(
            payload.fulfillment_latency,
          )
            ? Number(payload.fulfillment_latency)
            : null
        }
      }

      if (vendor === WALMART && columnsToDataMap.fulfillment_latency !== -1) {
        params.walmart_extra_info = {
          fulfillment_latency: isNumeric(payload.fulfillment_latency)
            ? Number(payload.fulfillment_latency)
            : null,
        }
      }

      if (columnsToDataMap.sync !== -1) {
        params.sync = payload.sync
      }

      if (isEmpty(params)) {
        return Promise.resolve()
      }

      return api.put(url, params)
    }
  }
}
