import PropTypes from 'prop-types'
import {useEffect} from 'react'

import parseAddress from '../../../common/parseAddress.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
const MODAL_FORM = 'EDIT_MOCK_ADDRESS_DATA_FORM'

export function showEditMockAddressDataModal() {
  setForm(MODAL_FORM, {
    addressDataText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockAddressDataModal({form}) {
  const query = useSelector(querySelector)
  const addressData = JSON.parse(query.addressData || '[]')

  useEffect(() => {
    const address = addressData[0]

    updateModalForm({
      addressDataText: address
        ? `${address.name}
${address.company}
${address.street1}
${address.street2}
${address.city}
${address.state}
${address.zip}
${address.country}`
        : '',
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Address Data"
      onConfirm={() => {
        mockAbodeUpdateResourceQuery({
          addressData: JSON.stringify([parseAddress(form.addressDataText)]),
        })

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input A Mock Address</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Address Data"
            rows="10"
            id="address_ids"
            value={form.addressDataText || ''}
            onChange={(value) => updateModalForm({addressDataText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockAddressDataModal.propTypes = {
  form: PropTypes.shape({
    addressDataText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockAddressDataModal, modalFormSelector)
