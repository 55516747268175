import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'

export function returnOrderCodesSelector(state) {
  return state.data.returnOrderCodes
}

export function returnOrderCodeSelector(state, {returnOrderCodeID}) {
  return get(returnOrderCodesSelector(state), returnOrderCodeID)
}

export const returnOrderCodesByCodeSelector = createSelector(
  returnOrderCodesSelector,
  (returnOrderCodes) => keyBy(returnOrderCodes, 'code'),
)

export const returnOrderCodesSortedByCodeSelector = createSelector(
  returnOrderCodesByCodeSelector,
  (returnOrderCodesByCode) =>
    Object.keys(returnOrderCodesByCode)
      .sort()
      .map((name) => returnOrderCodesByCode[name]),
)
