import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ListItem({children, className}) {
  return (
    <div className={classNames('list__item--order-data', className)}>
      {children}
    </div>
  )
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
