import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {APIKeyShape} from '../../../common/PropTypes.js'

import {apiKeyEditIDSelector} from '../../../redux/selectors/ui/settings/apiKeys.js'

import Form from './Form.js'

export function EditForm({apiKey, editID}) {
  const editLink = `/settings${apiKey._link}`
  const isExpanded = apiKey.id === editID

  return (
    <li
      id={editLink}
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
      })}
    >
      <strong className="settings-list-item-title medium-5 small-8 columns">
        {apiKey.name}
      </strong>
      <span className="medium-2 small-4 columns right-aligned-text right">
        {editLink && (
          <a
            type="button"
            className="inline-text-button settings-list-item-button"
            href={`#${editLink}`}
          >
            <span
              className="icon icon-edit icon-block x-sm margin-right-2"
              aria-hidden="true"
            />
            <span className="button-text">Edit</span>
          </a>
        )}
      </span>
      {isExpanded && <Form apiKeyID={apiKey.id} apiKey={apiKey} />}
    </li>
  )
}

EditForm.propTypes = {
  apiKey: APIKeyShape.isRequired,
  editID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

function mapStateToProps(state) {
  return {
    editID: apiKeyEditIDSelector(state),
  }
}

export default connect(mapStateToProps)(EditForm)
