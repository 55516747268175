import PropTypes from 'prop-types'
import {useCallback} from 'react'

import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default function EditItemForm({
  dataIndex,
  itemIndex,
  items,
  errors,
  updatePayload,
}) {
  const item = items[itemIndex]

  const onChange = useCallback(
    (updates) => {
      const newItems = [...items]

      newItems[itemIndex] = {
        ...item,
        ...updates,
      }

      updatePayload(dataIndex, {items: newItems})
    },
    [dataIndex, itemIndex, items, item],
  )

  return (
    <div className="wrap--edit-order-item">
      <Row>
        <TextInput
          value={item.sku || ''}
          errorMessage={errors.sku}
          name={`${itemIndex}_sku`}
          label="SKU"
          className="medium-12"
          onChange={(sku) => onChange({sku})}
        />
      </Row>
      <Row>
        <TextInput
          value={item.unit_price || ''}
          errorMessage={errors.unit_price}
          name={`${itemIndex}_unit_price`}
          label="Supplier Unit Cost"
          className="medium-6"
          onChange={(unit_price) => onChange({unit_price})}
        />
        <TextInput
          value={item.quantity || ''}
          errorMessage={errors.quantity}
          name={`${itemIndex}_quantity`}
          label="Quantity"
          className="medium-6"
          onChange={(quantity) => onChange({quantity})}
        />
      </Row>
      <Row>
        <TextInput
          value={item.discount_amount || ''}
          errorMessage={errors.discount_amount}
          name={`${itemIndex}_discount_amount`}
          label="Discount"
          className="medium-6"
          onChange={(discount_amount) => onChange({discount_amount})}
        />
      </Row>
    </div>
  )
}

EditItemForm.propTypes = {
  dataIndex: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  itemIndex: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
