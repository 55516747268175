import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {WarehouseShape} from '../../../common/PropTypes.js'
import {WAREHOUSE_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import {
  getIsFBAWarehouse,
  defaultWarehouseSelector,
} from '../../../data/warehouses.js'
import Form from './Form.js'
import {
  warehousesFormSelector,
  errorsSelector,
  saveWarehouse,
} from './warehousesFunctions.js'

export default function EditForm({warehouse}) {
  const form = useSelector(warehousesFormSelector)
  const errors = useSelector(errorsSelector)
  const defaultWarehouse = useSelector(defaultWarehouseSelector) || {}

  const isFBAWarehouse = getIsFBAWarehouse(warehouse)
  const isExpanded = warehouse.id === form.id
  const editLink = `${WAREHOUSE_SETTINGS_LINK}/${warehouse.id}`
  const isOriginalDefault = warehouse.id === defaultWarehouse.id

  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
      })}
    >
      <strong
        className={classNames(
          'settings-list-item-title medium-5 small-8 columns end',
          {
            default:
              warehouse.is_default_location &&
              warehouse.is_configured_for_shipping,
            'not-configured':
              !warehouse.is_configured_for_shipping && !isFBAWarehouse,
          },
        )}
      >
        <span className="make-block warehouse-name-wrap">
          <span
            className="icon warehouse-status-icon x-sm margin-right-5"
            aria-hidden="true"
          />
          <span>{warehouse.address.name}</span>
        </span>
        {!warehouse.is_configured_for_shipping && !isFBAWarehouse && (
          <div className="margin-top-10 fs-00 unbold">
            <span className="make-block">
              <span className="make-block margin-bottom-10">
                In order to create valid shipping labels, shipping companies
                need the phone number, email address and street address for the
                location you&rsquo;ll be shipping from.
              </span>
              <span className="make-block">
                This info will be used to calculate shipping rates and will show
                up as the return address on the shipping label.
              </span>
            </span>
            <a
              className="btn btn--primary btn--sm margin-top-10 margin-bottom-0"
              href={editLink}
            >
              Configure Warehouse
            </a>
          </div>
        )}
        {warehouse.is_configured_for_shipping &&
          warehouse.is_default_location && (
            <span className="make-block default-callout">
              <span>DEFAULT</span>
            </span>
          )}
      </strong>
      {warehouse.is_configured_for_shipping && (
        <div>
          <span className="settings-list-item-desc warehouse-address medium-5 hide-for-small columns">
            <span className="make-block">{warehouse.address.street1}</span>
            <span className="make-block">{warehouse.address.street2}</span>
            <span className="make-block">
              {warehouse.address.city}
              {warehouse.address.city && warehouse.address.state ? ', ' : ''}
              {warehouse.address.state} {warehouse.address.zip}
            </span>
            <span className="make-block">{warehouse.address.country}</span>
          </span>
          <span className="medium-2 small-4 columns right-aligned-text right">
            <a
              type="button"
              className="inline-text-button settings-list-item-button"
              href={editLink}
            >
              <span
                className="icon icon-edit icon-block x-sm margin-right-2"
                aria-hidden="true"
              />
              <span className="button-text">Edit</span>
            </a>
          </span>
        </div>
      )}
      {isFBAWarehouse && (
        <div>
          <span className="settings-list-item-desc warehouse-address medium-5 hide-for-small columns">
            <span className="make-block">FBA Warehouse</span>
          </span>
          <span className="medium-2 small-4 columns right-aligned-text right">
            <a
              type="button"
              className="inline-text-button settings-list-item-button"
              href={editLink}
            >
              <span
                className="icon icon-edit icon-block x-sm margin-right-2"
                aria-hidden="true"
              />
              <span className="button-text">Edit</span>
            </a>
          </span>
        </div>
      )}
      {isExpanded && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {errors.serverError && (
              <div className="wrap--row margin-bottom-25">
                <div className="medium-7 columns">
                  <div className="alert alert--error full-border error-message">
                    <strong className="fs-00">{errors.serverError}</strong>
                  </div>
                </div>
              </div>
            )}
            <Form
              isOriginalDefault={isOriginalDefault}
              isFBAWarehouse={isFBAWarehouse}
            />
            <div className="clear-left">
              <div className="medium-5 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  disabled={errors.preventSave || form.isSaving}
                  onClick={() => saveWarehouse()}
                >
                  Save
                </button>
                <a
                  href={WAREHOUSE_SETTINGS_LINK}
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </li>
  )
}

EditForm.propTypes = {
  warehouse: WarehouseShape.isRequired,
}
