import {useEffect} from 'react'

import {
  updateLabelConfig,
  labelConfigSelector,
  referenceNumberErrorMessageSelector,
  isLabelPropertyEnabledSelector,
  onLabelPropertyValueChange,
} from '../../../data/labelInfos/index.js'
import {getState, useSelector} from '../../../store.js'
import {
  PRESET_TEMPLATES,
  PRESET_TEMPLATE__ORDER_NUMBER,
  PRESET_TEMPLATE__PRODUCT_NAMES,
  PRESET_TEMPLATE__PRODUCT_SKUS,
  PRESET_TEMPLATE__REFERENCE_ID,
} from '../../../common/constants/LabelConfig.js'
import renderTemplate from '../../../common/renderTemplate.js'
import ConfigSelect from './ConfigSelect.js'
import {presetTemplateStateSelector} from '../../../data/presets.js'
import TextInput from '../../../common/components/TextInput.js'
import {
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  DHL,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY_CBDS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {
  EMPTY_DEPENDANT_PROPERTIES_FUNC,
  formatMetaClassName,
} from './useLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'reference_number'
const LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF =
  'reference_number__str__template_ref'
const VALID_SHIPPER_TYPES = [
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  DHL,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY_CBDS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
]
const VALID_LABEL_PROPERTIES = [
  LABEL_PROPERTY,
  LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF,
]

function labelPropertiesFunc(shipperType, labelInfoID) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  const properties = []

  if (VALID_SHIPPER_TYPES.includes(shipperType)) {
    properties.push(LABEL_PROPERTY)

    if (labelConfig[LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF]) {
      properties.push(LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF)
    }
  }

  return properties
}

function ReferenceNumberTemplates() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const presetTemplateState = useSelector(presetTemplateStateSelector)
  const config = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const isReferenceNumberPropertyEnabled = useSelector((state) =>
    isLabelPropertyEnabledSelector(state, {
      labelInfoID,
      labelProperty: LABEL_PROPERTY,
    }),
  )

  const reference_number = config.reference_number || ''
  const reference_number__str__template_ref =
    config[LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF] || ''

  useEffect(() => {
    onLabelPropertyValueChange(labelInfoID, shipperType, LABEL_PROPERTY)
  }, [reference_number__str__template_ref])

  return (
    <>
      <ConfigSelect
        className="margin-bottom-5"
        label="Reference Number"
        value={reference_number__str__template_ref}
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [LABEL_PROPERTY_REFERENCE_NUMBER__STR__TEMPLATE_REF]: value,
          })
        }}
        noEmptyOption
        labelProperty={LABEL_PROPERTY}
        validShipperTypes={VALID_SHIPPER_TYPES}
        labelPropertiesFunc={labelPropertiesFunc}
        validLabelProperties={VALID_LABEL_PROPERTIES}
        dependantPropertiesFunc={EMPTY_DEPENDANT_PROPERTIES_FUNC}
      >
        <option value={PRESET_TEMPLATE__ORDER_NUMBER}>Order ID</option>
        <option value={PRESET_TEMPLATE__REFERENCE_ID}>RMA ID</option>
        <option value={PRESET_TEMPLATE__PRODUCT_NAMES}>Product Names</option>
        <option value={PRESET_TEMPLATE__PRODUCT_SKUS}>Product SKUs</option>
        <option value="">Static Value</option>
      </ConfigSelect>
      {isReferenceNumberPropertyEnabled && (
        <li
          className={className`list__item--shipping-options btm-offset-7 ${formatMetaClassName(
            LABEL_PROPERTY,
          )}`}
        >
          {reference_number__str__template_ref ? (
            <div>
              example:{' '}
              {renderTemplate(
                PRESET_TEMPLATES[reference_number__str__template_ref],
                presetTemplateState,
              )}
            </div>
          ) : (
            <TextInput
              onChange={(reference_number) =>
                updateLabelConfig(labelInfoID, {
                  reference_number,
                })
              }
              value={reference_number}
            />
          )}
        </li>
      )}
    </>
  )
}

export default function ReferenceNumber() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const error = useSelector((state) =>
    referenceNumberErrorMessageSelector(state, {labelInfoID, shipperType}),
  )

  if (isLabelEnableMode) {
    return <ReferenceNumberTemplates />
  }

  return (
    <ConfigTextInput
      label="Reference Number"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      errorMessage={error}
    />
  )
}
