import PropTypes from 'prop-types'
import sub from 'date-fns/sub'
import startOfHour from 'date-fns/startOfHour'
import startOfDay from 'date-fns/startOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import intervalToDuration from 'date-fns/intervalToDuration'

import Checkbox from '../../../common/components/Checkbox.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import {updateForm, useForm} from '../../../store.js'
import FormattedDate from '../../../common/components/FormattedDate.js'
import {
  REPORT_INTERVAL_DAY,
  REPORT_INTERVAL_HOUR,
  REPORT_INTERVAL_MONTH,
  REPORT_INTERVAL_WEEK,
} from '../../../common/constants/Reports.js'
import {getRealDate} from '../../../common/date.js'

const funcsByType = {
  [REPORT_INTERVAL_HOUR]: {
    startOf: startOfHour,
    subDate: (date, hours) =>
      sub(date, intervalToDuration({start: 0, end: hours * 60 * 60 * 1000})),
  },
  [REPORT_INTERVAL_DAY]: {
    startOf: startOfDay,
    subDate: (date, days) =>
      sub(
        date,
        intervalToDuration({start: 0, end: days * 24 * 60 * 60 * 1000}),
      ),
  },
  [REPORT_INTERVAL_WEEK]: {
    startOf: startOfWeek,
    subDate: (date, weeks) =>
      sub(
        date,
        intervalToDuration({start: 0, end: weeks * 7 * 24 * 60 * 60 * 1000}),
      ),
  },
  [REPORT_INTERVAL_MONTH]: {
    startOf: startOfMonth,
    subDate: (date, months) => sub(date, {months}),
  },
}

export function visualizeInterval(intervalType, intervalAmount, intervalTrunc) {
  const now = getRealDate()
  intervalAmount = Number(intervalAmount)
  const {startOf, subDate} = funcsByType[intervalType] || {}

  if (!startOf || isNaN(intervalAmount) || intervalAmount <= 0) {
    return [now.toISOString(), now.toISOString()]
  }

  const start = subDate(now, intervalAmount)

  if (intervalTrunc) {
    return [startOf(start).toISOString(), startOf(now).toISOString()]
  } else {
    return [start.toISOString(), now.toISOString()]
  }
}

export default function IntervalSummary({formName, errors}) {
  const form = useForm(formName)

  if (!form.interval_type) {
    return null
  }

  const [visualStartDateString, visualEndDateString] = visualizeInterval(
    form.interval_type,
    form.interval_amount,
    form.interval_trunc,
  )

  return (
    <>
      <li className="list__item--form list__item--no-style">
        {errors.interval_amount && (
          <small className="error error-message">
            {errors.interval_amount}
          </small>
        )}
      </li>
      <li className="list__item--form list__item--no-style wrap--v-align-inner">
        <Checkbox
          label={`Truncate to start of ${form.interval_type}`}
          id="interval_trunc"
          checked={form.interval_trunc}
          onChange={(interval_trunc) => updateForm(formName, {interval_trunc})}
        />
      </li>
      <li className="list__item--form list__item--no-style alert alert--standard margin-bottom-15">
        <div className="fs-n0 lh-md">
          If run right now, the report would include dates from{' '}
          <strong>
            <FormattedDate
              value={visualStartDateString}
              format="MMM D, YYYY [at] h:mma"
            />
          </strong>{' '}
          up to{' '}
          <strong>
            <FormattedDate
              value={visualEndDateString}
              format="MMM D, YYYY [at] h:mma"
            />
          </strong>
          .
        </div>
      </li>
    </>
  )
}

IntervalSummary.propTypes = {
  formName: PropTypes.string.isRequired,
  errors: ErrorsShape.isRequired,
}
