import {CSSTransition, TransitionGroup} from 'react-transition-group'

import {
  GoodsReceiptModalFormShape,
  forceReceiveAll,
} from './goodsReceiptModalFunctions.js'
import GoodsReceiptLine from './GoodsReceiptLine.js'

export default function GoodsReceiptLines({form}) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="table__th table__th--top-border th-width-40">
            Product Name/SKU
          </th>
          <th className="table__th table__th--top-border th-width-20 align-center">
            Ordered
          </th>
          <th className="table__th table__th--top-border border-left--light th-width-20 align-center">
            Remaining
          </th>
          <th className="table__th table__th--verified table__th--top-border overflow-hidden relative border-left--light th-width-20 align-center">
            <div className="inline-block">Received</div>
            <button
              className="btn btn--link btn--force-verify lighter no-underline"
              type="button"
              title="Force-verify all lines on this goods receipt"
              onClick={() => forceReceiveAll()}
            >
              <span className="i-check-circle fs-01 lh-0" />
            </button>
          </th>
        </tr>
      </thead>
      <TransitionGroup
        component="tbody"
        className="table__tbody--lines table__tbody--goods-receipt-modal table__tbody--v-align-middle"
      >
        {form.lines.map((line, index) => (
          <CSSTransition
            key={index}
            classNames="success-fade"
            timeout={{exit: 1000}}
            unmountOnExit
          >
            {() => (
              <GoodsReceiptLine
                line={line}
                index={index}
                autoFocus={
                  form.goodsReceiptItemID
                    ? form.goodsReceiptItemID === line.goodsReceiptItemID
                    : false
                }
              />
            )}
          </CSSTransition>
        ))}
      </TransitionGroup>
    </table>
  )
}

GoodsReceiptLines.propTypes = {
  form: GoodsReceiptModalFormShape.isRequired,
}
