import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SelectOptionsShape, SelectOptionTokens} from '../../common/PropTypes.js'
import FilterTagFilter from '../../common/components/List/FilterTagFilter/index.js'
import {hasMOManageTagPermissionSelector} from '../../data/me.js'
import {
  moTagOptionsSelector,
  moTagOptionTokensSelector,
} from '../../data/moTags.js'
import {
  createAndApplyTagToMOs,
  setTags,
  setUntagged,
  setTagFilterBy,
} from './moListActions.js'
import {
  selectedReferenceIDsSelector,
  moListQuerySelector,
} from './moListSelectors.js'

function MOTagFiltersDropdown({
  referenceIDs,
  moTagOptions,
  moTagOptionTokens,
  moTagFilters,
  untaggedSelected,
  hasMOManageTagPermission,
  filterOperator,
}) {
  return (
    <FilterTagFilter
      dropdown="FILTER_MO_TAG_FILTER"
      selectedTags={moTagFilters}
      tagOptions={moTagOptions}
      tagOptionTokens={moTagOptionTokens}
      untaggedSelected={untaggedSelected}
      filterOperator={filterOperator}
      hasManageTagPermission={hasMOManageTagPermission}
      willApplyCreatedTag={referenceIDs.length !== 0}
      onCreate={() => createAndApplyTagToMOs(referenceIDs)}
      onSelect={(tag) => {
        const tags = moTagFilters.filter((name) => name !== tag.name)

        if (!moTagFilters.includes(tag.name)) {
          tags.push(tag.name)
        }

        setTags(tags)
      }}
      onToggleUntaggedFilter={(isSelected) => setUntagged(isSelected)}
      onTagFilterOperatorChange={(operator) => setTagFilterBy(operator)}
    />
  )
}

MOTagFiltersDropdown.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  moTagOptions: SelectOptionsShape.isRequired,
  moTagOptionTokens: SelectOptionTokens.isRequired,
  moTagFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  untaggedSelected: PropTypes.bool.isRequired,
  hasMOManageTagPermission: PropTypes.bool.isRequired,
  filterOperator: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  const params = moListQuerySelector(state)

  return {
    referenceIDs: selectedReferenceIDsSelector(state),
    moTagOptions: moTagOptionsSelector(state),
    moTagOptionTokens: moTagOptionTokensSelector(state),
    moTagFilters: params.tags,
    untaggedSelected: params.untagged,
    hasMOManageTagPermission: hasMOManageTagPermissionSelector(state),
    filterOperator: params.tagFilterBy,
  }
}

export default connect(mapStateToProps)(MOTagFiltersDropdown)
