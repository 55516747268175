import {combineReducers} from 'redux'
import reduce from 'lodash/reduce.js'

import composeReducers from '../../../utils/composeReducers.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'
import {
  SET_IS_SAVING,
  SET_SHIPPER_ID,
  SET_SERVER_ERROR,
  SET_FORM,
  UPDATE_FORM,
} from '../../../actions/ui/modals/endiciaCreditCardModal.js'

function isSaving(
  state = INITIAL_STATE.ui.modals.endiciaCreditCardModal.isSaving,
  action = {},
) {
  if (action.type === SET_IS_SAVING) {
    return action.payload
  }
  return state
}

function shipperID(
  state = INITIAL_STATE.ui.modals.endiciaCreditCardModal.shipperID,
  action = {},
) {
  if (action.type === SET_SHIPPER_ID) {
    return action.payload
  }
  return state
}

function serverError(
  state = INITIAL_STATE.ui.modals.endiciaCreditCardModal.serverError,
  action = {},
) {
  if (action.type === SET_SERVER_ERROR) {
    return action.payload
  }
  return state
}

function form(
  state = INITIAL_STATE.ui.modals.endiciaCreditCardModal.form,
  action = {},
) {
  if (action.type === UPDATE_FORM) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}

function hasChanged(
  state = INITIAL_STATE.ui.modals.endiciaCreditCardModal.hasChanged,
) {
  return state
}

function handleUpdateForm(state, action = {}) {
  if (action.type === UPDATE_FORM) {
    return {
      ...state,
      form: {
        ...state.form,
        ...action.payload,
      },
      hasChanged: {
        ...state.hasChanged,
        ...reduce(
          action.payload,
          (prev, value, key) => {
            prev[key] = true

            return prev
          },
          {},
        ),
      },
    }
  }

  if (action.type === SET_FORM) {
    return {
      ...state,
      form: action.payload,
      hasChanged: {},
    }
  }

  return state
}

const reducer = combineReducers({
  isSaving,
  shipperID,
  serverError,
  form,
  hasChanged,
})

export default composeReducers(handleUpdateForm, reducer)
