import PropTypes from 'prop-types'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import OrderNumberOrCount from '../../../common/components/OrderNumberOrCount.js'
import {
  labelInfoIDsForOrdersAndLabelTypesSelector,
  maxWeightSelector,
  updateParcel,
} from '../../../data/labelInfos/index.js'
import {getBulkRates} from '../../LabelConfig/BulkLabelConfigForm/bulkLabelConfigActions.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import WeightInput from '../../../common/components/Form/WeightInput.js'

export const MODAL_FORM = 'EDIT_ORDER_WEIGHT_MODAL'

export async function showEditOrderWeightModal(orderNumbers) {
  const labelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(getState(), {
    orderNumbers,
    labelTypes: ['shipping', 'return'],
  })

  const weight = maxWeightSelector(getState(), {labelInfoIDs})

  setForm(MODAL_FORM, {
    orderNumbers,
    weight,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export async function saveWeight() {
  const {orderNumbers, weight} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    const allLabelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(
      getState(),
      {
        orderNumbers,
        labelTypes: ['shipping', 'return'],
      },
    )

    allLabelInfoIDs.map((labelInfoID) => updateParcel(labelInfoID, 0, {weight}))

    showMessageToast('Weight have been updated')

    closeModal()

    await refreshOrderListAndCounts()

    await getBulkRates(allLabelInfoIDs)

    await getRates(allLabelInfoIDs)
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
      isSaving: false,
    })
  }
}

function EditOrderWeightModal({form}) {
  return (
    <ConfirmModal
      title="Edit Weight"
      confirmText="Save"
      cancelText="Cancel"
      modalSize="x-sm"
      isSaving={form.isSaving}
      onConfirm={() => saveWeight()}
      onCancel={() => closeModal()}
      error={form.serverError}
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style fs-n0 text--md-grey">
          <strong>
            Editing <OrderNumberOrCount orderNumbers={form.orderNumbers} />
          </strong>
        </li>
        <li className="list__item--form list__item--no-style flex">
          <WeightInput
            idLb="id_weight-lb"
            idOz="id_weight-oz"
            weightOz={form.weight}
            setWeightOz={(weight) => updateModalForm({weight})}
            onEnterKeyPress={() => saveWeight()}
            showScaleControl
            autoFocus
            delay={0}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditOrderWeightModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    weight: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditOrderWeightModal, modalFormSelector)
