import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  formSelector,
  onlyIfForm,
} from '../../../store.js'
import {cartVendorsByName} from '../../../common/constants/CartVendorOptions.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {getAuthorizeMessage} from '../SalesChannels/AuthorizeMessage.js'

export const MODAL_FORM = 'REAUTHORIZE_CART_MODAL'

export async function showReauthorizeCartModal(formName) {
  const cartForm = formSelector(getState(), {formName})

  setForm(MODAL_FORM, {
    formName,
    vendorName: get(cartVendorsByName[cartForm.vendor], 'displayName', ''),
    authorize: cartForm.authorize,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function reauthorizeCart() {
  const {authorize, formName} = modalFormSelector(getState())

  try {
    authorize(formName)
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
    })
  }
}

function ReauthorizeCartModal({form}) {
  return (
    <ConfirmModal
      title="Reauthorize sales channel"
      modalSize="sm"
      confirmText="Reauthorize Cart"
      onConfirm={() => reauthorizeCart()}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div className="row">
        <div className="medium-12 columns">
          <p className="margin-top-10 margin-bottom-10">
            {getAuthorizeMessage(form.vendorName)}
          </p>
        </div>
      </div>
    </ConfirmModal>
  )
}

ReauthorizeCartModal.propTypes = {
  form: PropTypes.shape({
    formName: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    authorize: PropTypes.func.isRequired,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(ReauthorizeCartModal, modalFormSelector)
