import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'

import noProductImageURL from '../../../images/no-product-img-01.gif'

export default function PopoverImage({src, alt}) {
  const hasPlaceholderImage = !src
  src = src || noProductImageURL

  const [iconSRC, setIconSRC] = useState()

  useEffect(() => {
    setIconSRC(
      src && src.match(/abode.*ordoro/) ? `${src}?width=46&height=46` : src,
    )
  }, [src])

  const [realSRC, setRealSRC] = useState()
  const [hasLoaded, setHasLoaded] = useState(false)

  return (
    <div
      className="wrap--product-thumb-popover margin-right-7"
      onMouseOver={() => {
        setRealSRC(src)
      }}
      onFocus={() => {
        /* noop */
      }}
    >
      <div className="wrap--product-thumb">
        <img className="img--product-thumb" src={iconSRC} alt={alt} />
      </div>
      {!hasPlaceholderImage && (
        <div
          className={`wrap--product-popover ${
            !hasLoaded ? 'img--product-popover-loading' : ''
          }`}
        >
          <img
            className="img--product-popover"
            src={realSRC}
            alt={alt}
            onLoad={() => {
              if (realSRC && !hasLoaded) {
                setHasLoaded(true)
              }
            }}
          />
          {!hasLoaded && (
            <div className="loading align-center">
              <span className="spinner--lg v-align-middle center-everything" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

PopoverImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}
