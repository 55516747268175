import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {WAYFAIR} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.client_id)) {
    errors.client_id = 'Client ID is required'
    errors.preventSave = true
  }

  if (!isPresent(form.client_secret)) {
    errors.client_secret = 'Client Secret is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: WAYFAIR}),
      client_id: '',
      client_secret: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    client_id: cart.vendor_config.client_id,
    client_secret: cart.vendor_config.client_secret,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'client_id') {
    errors.client_id = serverError.error_message
  } else if (serverError.param === 'client_secret') {
    errors.client_secret = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    client_id: form.client_id,
    client_secret: form.client_secret,
  }
}

function Wayfair({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="client_id"
              label="Client ID"
              required
              value={form.client_id || ''}
              errorMessage={form.hasChanged_client_id && errors.client_id}
              onChange={(event) =>
                updateForm(formName, {
                  client_id: event.target.value,
                  hasChanged_client_id: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              type="password"
              name="client_secret"
              label="Client Secret"
              required
              value={form.client_secret || ''}
              errorMessage={
                form.hasChanged_client_secret && errors.client_secret
              }
              onChange={(event) =>
                updateForm(formName, {
                  client_secret: event.target.value,
                  hasChanged_client_secret: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        {isNew ? (
          <div className="alert alert--warning alert--lg">
            <dl className="list">
              <dt className="list__title fs-01 lh-md margin-bottom-10">
                HEADS UP!
              </dt>
              <dd className="list__item fs-00 margin-bottom-10">
                <strong>
                  Wayfair requires an integration check and a dry run for every
                  account you connect to Ordoro.
                </strong>{' '}
              </dd>
              <dd className="list__item fs-00 margin-bottom-10">
                Contact our Support Team (
                <a
                  href="mailto:support@ordoro.com"
                  className="btn--link v-align-base mid"
                >
                  support@ordoro.com
                </a>
                ) and let us know your Wayfair Integration Manager’s email so we
                can coordinate with them.
              </dd>
              <dd className="list__item fs-00 margin-bottom-0">
                <a
                  className="btn--link mid"
                  href="https://support.ordoro.com/how-do-i-find-my-wayfair-api-keys/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Additional info →
                </a>
              </dd>
            </dl>
          </div>
        ) : (
          <AuthorizeHelper
            vendorName="Wayfair"
            href="https://support.ordoro.com/how-do-i-find-my-wayfair-api-keys/"
          />
        )}
      </div>
    </VendorForm>
  )
}

Wayfair.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    client_id: PropTypes.string.isRequired,
    hasChanged_client_id: PropTypes.bool,
    client_secret: PropTypes.string.isRequired,
    hasChanged_client_secret: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Wayfair, setUpCartForm)
