import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import {
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'mailing_zip_code'
const VALID_SHIPPER_TYPES = [ENDICIA, PITNEY, PITNEY_MERCHANT, PITNEY_PRESORT]

export default function MailingZipCode() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigTextInput
      label="Mailing Post Office Zip Code"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
