import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Dimensions from '../../../../../../../common/components/Dimensions.js'
import {
  shippedDimensionsSelector,
  showShippedDimensionsSelector,
  isShippedMultiboxSelector,
} from '../../../../../../../data/orders.js'
import LabelItem from './LabelItem.js'

function ShippedDimensions({showDimensions, dimensions, isMultibox}) {
  const values = showDimensions
    ? isMultibox
      ? {blankText: 'Multiple'}
      : dimensions
    : {blankText: 'N/A'}
  return (
    <LabelItem label="Dimensions">
      <Dimensions {...values} />
    </LabelItem>
  )
}

ShippedDimensions.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    length: PropTypes.number,
  }),
  isMultibox: PropTypes.bool.isRequired,
  showDimensions: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    showDimensions: showShippedDimensionsSelector(state, {
      orderNumber,
      packagesIndex: 0,
    }),
    dimensions: shippedDimensionsSelector(state, {
      orderNumber,
      packagesIndex: 0,
    }),
    isMultibox: isShippedMultiboxSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(ShippedDimensions)
