import {combineReducers} from 'redux'

import * as actions from '../../../actions/ui/dataImport/index.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'
import upload from './upload.js'
import data from './data.js'

function current(state = INITIAL_STATE.ui.dataImport.current, action = {}) {
  if (action.type === actions.SET_CURRENT) {
    return action.payload
  }

  return state
}

function columns(state = INITIAL_STATE.ui.dataImport.columns, action = {}) {
  if (action.type === actions.SET_COLUMNS) {
    return action.payload
  }

  return state
}

function rows(state = INITIAL_STATE.ui.dataImport.rows, action = {}) {
  if (action.type === actions.SET_ROWS) {
    return action.payload
  }

  return state
}

function isProcessingCSV(
  state = INITIAL_STATE.ui.dataImport.isProcessingCSV,
  action = {},
) {
  if (action.type === actions.SET_IS_PROCESSING_CSV) {
    return action.payload
  }

  return state
}

function isValidatingData(
  state = INITIAL_STATE.ui.dataImport.isValidatingData,
  action = {},
) {
  if (action.type === actions.SET_IS_VALIDATING_DATA) {
    return action.payload
  }

  return state
}

function isSaving(state = INITIAL_STATE.ui.dataImport.isSaving, action = {}) {
  if (action.type === actions.SET_IS_SAVING) {
    return action.payload
  }

  return state
}

function stopBlock(state = INITIAL_STATE.ui.dataImport.stopBlock, action = {}) {
  if (action.type === actions.SET_STOP_BLOCK) {
    return action.payload
  }

  return state
}

function columnsToDataMap(
  state = INITIAL_STATE.ui.dataImport.columnsToDataMap,
  action = {},
) {
  if (action.type === actions.SET_COLUMNS_TO_DATA_MAP) {
    return action.payload
  }

  return state
}

function columnErrors(
  state = INITIAL_STATE.ui.dataImport.columnErrors,
  action = {},
) {
  if (action.type === actions.SET_COLUMN_ERRORS) {
    return action.payload
  }

  return state
}

function currentDataIndex(
  state = INITIAL_STATE.ui.dataImport.currentDataIndex,
  action = {},
) {
  if (action.type === actions.SET_CURRENT_DATA_INDEX) {
    return action.payload
  }

  return state
}

function willShowOnlyErrors(
  state = INITIAL_STATE.ui.dataImport.willShowOnlyErrors,
  action = {},
) {
  if (action.type === actions.SET_WILL_SHOW_ONLY_ERRORS) {
    return action.payload
  }

  return state
}

function willStopSavingOnErrors(
  state = INITIAL_STATE.ui.dataImport.willStopSavingOnErrors,
  action = {},
) {
  if (action.type === actions.SET_WILL_STOP_SAVING_ON_ERRORS) {
    return action.payload
  }

  return state
}

const reducer = combineReducers({
  upload,
  data,
  current,
  columns,
  rows,
  isProcessingCSV,
  isValidatingData,
  isSaving,
  stopBlock,
  columnsToDataMap,
  columnErrors,
  currentDataIndex,
  willShowOnlyErrors,
  willStopSavingOnErrors,
})

export default reducer
