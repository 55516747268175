import PropTypes from 'prop-types'

import {getState, useSelector, formSelector, useForm} from '../../../store.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {saveSalesChannel} from './salesChannelsActions.js'
import {cartFormErrorsSelector} from './salesChannelsSelectors.js'

async function confirm(formName) {
  await saveSalesChannel(formName)

  const {serverError} = formSelector(getState(), {formName})

  if (!serverError) {
    window.location = SALES_CHANNEL_SETTINGS_LINK
  }
}

export default function Buttons({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  if (!form) {
    return null
  }

  return (
    <div className="wrap--row padding-top-20">
      <div className="medium-12 columns">
        <ButtonPrimary
          size="std"
          isDisabled={errors.preventSave || form.isSaving}
          isLoading={form.isSaving}
          onClick={() => confirm(formName)}
        >
          {form.id === NEW_ID ? 'Add Sales Channel' : 'Save'}
        </ButtonPrimary>
        <a className="btn btn--secondary" href={SALES_CHANNEL_SETTINGS_LINK}>
          Cancel
        </a>
      </div>
    </div>
  )
}

Buttons.propTypes = {
  formName: PropTypes.string.isRequired,
}
