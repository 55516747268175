import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {getState, setForm, updateForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'
import {fetchAllApiverson} from '../common/fetchAll.js'
import {TAG_TYPE__ORDER} from '../common/constants/Tags.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {
  allOrdersSelector,
  clearPendingTagAfterError,
  markTagPending,
} from './orders.js'
import {refreshOrderList} from '../ordoro/OrderListPage/orderListActions.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'
import userflow from '../common/analytics/userflow.js'

export const ORDER_TAGS = 'ORDER_TAGS'

export function setOrderTags(orderTags) {
  setForm(ORDER_TAGS, keyBy(orderTags, 'id'))
}

export function updateOrderTag(orderTag) {
  updateForm(ORDER_TAGS, {[orderTag.id]: orderTag})
}

export function removeOrderTag(orderTag) {
  const orderTags = {...orderTagsSelector(getState())}

  delete orderTags[orderTag.id]

  setForm(ORDER_TAGS, orderTags)
}

export function orderTagsSelector(state) {
  return formsSelector(state)[ORDER_TAGS] || orderTagsSelector.default
}
orderTagsSelector.default = {}

export function orderTagSelector(state, {orderTagID}) {
  return orderTagsSelector(state)[orderTagID]
}

export function orderTagByTextSelector(state, {text}) {
  const foundTag = Object.values(orderTagsSelector(state)).find(
    (tag) => tag.text === text,
  )

  return foundTag || null
}

export const orderTagsSortedByNameSelector = createSelector(
  orderTagsSelector,
  (orderTags) => sortBy(orderTags, ({text}) => text.toLowerCase()),
)

export const orderTagOptionsSelector = createSelector(
  orderTagsSortedByNameSelector,
  (tags) =>
    tags.map((tag) => ({
      value: tag.id,
      display: tag.text,
      entity: tag,
      type: TAG_TYPE__ORDER,
      nouns: getNounsFromValue(tag.text),
    })),
)

export const orderTagOptionTokensSelector = createSelector(
  orderTagOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function orderTagsHaveLoadedSelector(state) {
  return !!formsSelector(state)[ORDER_TAGS]
}

export async function getOrderTags() {
  try {
    const orderTags = await fetchAllApiverson('/tag', 'tag')

    setOrderTags(orderTags)

    userflow.updateGroup({
      order_tag_count: orderTags.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting order tags.',
        details: err.error_message || err.message,
      },
      err,
    )

    setOrderTags([])
  }
}

export async function saveOrderTag(text, color, id) {
  const params = {text: text.trim(), color}

  const {json} = id
    ? await apiverson.put(`/tag/${id}`, params)
    : await apiverson.post('/tag', params)

  updateOrderTag(json)

  if (id) {
    await refreshOrderList()
  }

  return json
}

export async function ensureTagExists(text, color) {
  // note: We don't want to force a color change. We should let customers keep
  // whatever tag colors they want
  const tag = orderTagByTextSelector(getState(), {text})

  if (tag) {
    return tag
  }

  const newTag = await saveOrderTag(text, color)

  return newTag
}

export async function deleteOrderTag(tagID) {
  const tag = orderTagSelector(getState(), {orderTagID: tagID})

  await apiverson.delete(`/tag/${tag.id}`)

  removeOrderTag(tag)
}

export async function addTagToOrders(orderTagID, orderNumbers) {
  const orders = allOrdersSelector(getState())
  const tag = orderTagSelector(getState(), {orderTagID})

  if (!tag) {
    return
  }

  const orderNumbersWithoutTag = orderNumbers.filter(
    (orderNumber) =>
      !get(orders, [orderNumber, 'tags'], []).find((t) => t.id === tag.id),
  )

  orderNumbersWithoutTag.map((orderNumber) =>
    markTagPending(orderNumber, tag, true),
  )

  try {
    await apiverson.post(`/bulk/order/tag/${tag.id}`, {
      add_to_orders: orderNumbersWithoutTag,
    })
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error adding order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    orderNumbersWithoutTag.map((orderNumber) =>
      clearPendingTagAfterError(orderNumber, tag.id, true),
    )
  }
}

export async function removeTagFromOrders(orderTagID, orderNumbers) {
  const orders = allOrdersSelector(getState())
  const tag = orderTagSelector(getState(), {orderTagID})

  if (!tag) {
    return
  }

  const orderNumbersWithTag = orderNumbers.filter((orderNumber) =>
    get(orders, [orderNumber, 'tags'], []).find((t) => t.id === tag.id),
  )

  orderNumbersWithTag.map((orderNumber) =>
    markTagPending(orderNumber, tag, false),
  )

  try {
    await apiverson.post(`/bulk/order/tag/${tag.id}`, {
      remove_from_orders: orderNumbersWithTag,
    })
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error removing order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    orderNumbersWithTag.map((orderNumber) =>
      clearPendingTagAfterError(orderNumber, tag.id, false),
    )
  }
}
