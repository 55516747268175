import {useEffect} from 'react'

import {useSelector} from '../../../../store.js'
import {getShipperName} from '../../../../data/shippers.js'
import Select from '../../../../common/components/Select.js'
import {
  updateModalForm,
  modalFormSelector,
  pickupShippersSelector,
} from './pickupModalFunctions.js'

export default function ShipperSelect() {
  const {shipperID} = useSelector(modalFormSelector)
  const shippers = useSelector(pickupShippersSelector)

  useEffect(() => {
    if (shippers.length === 0) {
      return
    }

    if (!shippers.find(({id}) => id === shipperID)) {
      updateModalForm({shipperID: shippers[0].id}, {stickyProps: ['shipperID']})
    }
  }, [shippers, shipperID])

  return (
    <li className="list__item--form list__item--no-style margin-bottom-15">
      <label htmlFor="id_carrier_acct">Carrier</label>
      {shippers.length > 1 && (
        <Select
          className="select--modal input--lg margin-bottom-0"
          id="id_carrier_acct"
          onChange={(shipperID) =>
            updateModalForm(
              {shipperID: Number(shipperID)},
              {stickyProps: ['shipperID']},
            )
          }
          value={shipperID}
        >
          {shippers.map(({id, name}) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>
      )}
      {shippers.length === 1 && (
        <div className="fs-00 text--md-grey">{getShipperName(shippers[0])}</div>
      )}
    </li>
  )
}
