import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

export default class Radio extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    alwaysShowChildren: PropTypes.bool,
  }

  onChange = (event) => this.props.onChange(event.target.value)

  render() {
    const name = this.props.name
    const errorMessage = this.props.errorMessage
    const allClassName = classNames(`container_${name}`, this.props.className, {
      error: !!errorMessage,
    })

    return (
      <div className={allClassName}>
        <label
          htmlFor={`id_${name}`}
          className={classNames({'label--disabled': this.props.disabled})}
        >
          <input
            type="radio"
            className="margin-left-3 margin-right-5 margin-bottom-0"
            name={name}
            id={`id_${name}`}
            onChange={this.onChange}
            checked={this.props.checked}
            disabled={this.props.disabled}
            value={this.props.value}
          />
          <span className={classNames({'op-50': this.props.disabled})}>
            {this.props.label}
          </span>
        </label>
        {errorMessage && (
          <small className="error error-message">{errorMessage}</small>
        )}
        {(this.props.checked || this.props.alwaysShowChildren) &&
          this.props.children && (
            <div className="wrap--nested-form">{this.props.children}</div>
          )}
      </div>
    )
  }
}
