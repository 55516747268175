import PropTypes from 'prop-types'
import {useCallback} from 'react'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default function EditForm({data, ...props}) {
  const updatePayload = useCallback(
    (payloadDelta) => {
      props.updatePayload(data.index, payloadDelta)
    },
    [data.index],
  )

  return (
    <BaseEditForm className="medium-5 columns" {...props}>
      <Row>
        <TextInput
          value={data.payload.sku}
          errorMessage={data.errors.sku}
          name="sku"
          label="SKU"
          className="medium-12"
          onChange={(value) => updatePayload({sku: value})}
        />
      </Row>
      {!data.payload.remove_tag && (
        <Row>
          <TextInput
            value={data.payload.add_tag}
            errorMessage={data.errors.add_tag}
            name="add_tag"
            label="Add Tag"
            className="medium-12"
            onChange={(value) => updatePayload({add_tag: value})}
          />
        </Row>
      )}
      {!data.payload.add_tag && (
        <Row>
          <TextInput
            value={data.payload.remove_tag}
            errorMessage={data.errors.remove_tag}
            name="remove_tag"
            label="Remove Tag"
            className="medium-12"
            onChange={(value) => updatePayload({remove_tag: value})}
          />
        </Row>
      )}
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
