import format from 'date-fns/format'

export function parseAnyTime(time) {
  // Get raw pieces of time: hour (may contain minutes), minutes and am/pm
  // Every thing is optional so we get 12:00 AM by default
  let [
    ,
    hourString = '00',
    minuteString = '00',
    secondString = '00',
    meridiem,
  ] = time.match(/^(\d+)?[:]?(\d+)?[:]?(\d+)?[ ]?(am|pm)?/i)

  // Hour might contain minutes so break out the last two digits
  if (hourString.length > 2 && hourString.length <= 4) {
    ;[, hourString, minuteString] = hourString.match(/(\d+)(\d{2})$/)
  } else if (hourString.length > 4) {
    ;[, hourString, minuteString, secondString] =
      hourString.match(/(\d+)(\d{2})(\d{2})$/)
  }

  // Hour needs to be adjusted to 0-24 scale
  let hours = Number(hourString)
  meridiem = meridiem && meridiem.toUpperCase()
  if (hours < 12 && meridiem === 'PM') {
    hours += 12
  } else if (hours === 12 && meridiem === 'AM') {
    hours = 0
  }

  if (hours > 23) {
    hours = 0
  }

  let minutes = Number(minuteString)

  if (minutes > 59) {
    minutes = 0
  }

  let seconds = Number(secondString)

  if (seconds > 59) {
    seconds = 0
  }

  return {
    hours,
    minutes,
    seconds,
    totalSeconds: hours * 3600 + minutes * 60 + seconds,
  }
}

export default function formatTime(
  time,
  {formatStr = 'h:mm aa', roundMinutes, roundSeconds} = {},
) {
  let {hours, minutes, seconds} = parseAnyTime(time)

  if (roundMinutes !== undefined) {
    roundMinutes = Number(roundMinutes) || 1
    roundSeconds = undefined

    if (seconds >= 30) {
      minutes += 1
    }

    minutes = Math.round(minutes / roundMinutes) * roundMinutes
    seconds = 0
  }

  if (roundSeconds !== undefined) {
    roundSeconds = Number(roundSeconds) || 60
    seconds = Math.round(seconds / roundSeconds) * roundSeconds

    if (seconds > 59) {
      minutes += 1
      seconds = 0
    }
  }

  let totalSeconds = hours * 3600 + minutes * 60 + seconds

  hours = Math.floor(totalSeconds / 3600)
  totalSeconds %= 3600

  minutes = Math.floor(totalSeconds / 60)
  totalSeconds %= 60

  seconds = Math.floor(totalSeconds)

  if (hours > 23) {
    hours = 0
    minutes = 0
    seconds = 0
  }

  const date = new Date()

  date.setHours(hours, minutes, seconds, 0)

  // format it
  return format(date, formatStr)
}
