import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {endiciaAccountErrorSelector} from '../../redux/selectors/ui/index.js'

export function EndiciaAccountError({endiciaAccountError}) {
  if (!endiciaAccountError) {
    return null
  }

  return (
    <div className="full-width wrap--endicia-suspended wrap--endicia-header">
      <div className="row">
        <dl className="list--endicia-suspended centered-text">
          <dd className="margin-bottom-0">
            <span
              className="icon icon-block icon-lock x-sm margin-right-3"
              aria-hidden="true"
            />{' '}
            <strong>Your USPS account has been suspended.</strong>
            {' To unlock it, please '}
            <a
              className="link--endicia-suspended"
              href="http://support.ordoro.com/what-to-do-when-your-endicia-account-gets-suspended/"
              target="_blank"
              rel="noopener noreferrer"
            >
              follow these instructions
            </a>
            .
          </dd>
        </dl>
      </div>
    </div>
  )
}

EndiciaAccountError.propTypes = {
  endiciaAccountError: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    endiciaAccountError: endiciaAccountErrorSelector(state),
  }
}

export default connect(mapStateToProps)(EndiciaAccountError)
