import PropTypes from 'prop-types'

import {formSelector, getState} from '../../../store.js'
import ProductFilters from './ProductFilters.js'
import {reportSelector} from '../../../data/reports.js'
import {cleanProductQuery} from '../../ProductListPage/productListFunctions.js'

export function setupExportProductsSalesChannelsForm(params = {}) {
  let {
    reportID,
    productQuery = {},
    queryTotalCount = null,
    selectedSKUs = [],
  } = params

  let {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: productQuery}

  productQuery = cleanProductQuery(reportParams)

  delete productQuery.sort
  delete productQuery.limit
  delete productQuery.offset

  const querySelection = selectedSKUs.length ? 'selection' : 'query'

  return {
    querySelection,
    productQuery,
    queryTotalCount,
    selectedSKUs,
  }
}

export function exportProductsSalesChannelsErrorsSelector() {
  return {}
}

export function exportProductsSalesChannelsPayloadSelector(state, {formName}) {
  const {reportType, productQuery, selectedSKUs, querySelection} = formSelector(
    state,
    {formName},
  )

  let params = productQuery

  if (querySelection === 'selection') {
    params = {sku: selectedSKUs}
  }

  return {
    type: reportType,
    params,
  }
}

export default function ExportProductsSalesChannelsForm({formName}) {
  return (
    <ul className="list list--no-style">
      <li className="list__item--form divider--bottom margin-bottom-15 padding-bottom-20">
        <div className="margin-top-10">
          Would you like to download a CSV file with data about your products
          and how they relate to their respective sales channels?
        </div>
      </li>
      <ProductFilters formName={formName} />
    </ul>
  )
}

ExportProductsSalesChannelsForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
