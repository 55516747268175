import PropTypes from 'prop-types'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  if (_.isEmpty(props.columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-10" {...props}>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.sku}
          errorMessage={props.columnErrors.sku}
          name="sku"
          label="SKU"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.add_child_sku}
          errorMessage={props.columnErrors.add_child_sku}
          name="add_child_sku"
          label="Add Child SKU"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.remove_child_sku}
          errorMessage={props.columnErrors.remove_child_sku}
          name="remove_child_sku"
          label="Remove Child SKU"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.quantity}
          errorMessage={props.columnErrors.quantity}
          name="quantity"
          label="Quantity"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
