import {sanitize} from '../../../common/sanitize.js'
import {TaskShape} from '../../../common/PropTypes.js'
import {getTaskStatus} from '../../../redux/selectors/data/tasks.js'

export default function TaskStatus({task}) {
  return (
    <div
      className="activity-message"
      dangerouslySetInnerHTML={{
        __html: sanitize(
          getTaskStatus(task).replace(
            'An error occurred. Please contact Ordoro support.',
            '',
          ),
        ),
      }}
    />
  )
}

TaskStatus.propTypes = {
  task: TaskShape.isRequired,
}
