import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {updateForm} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {shipperFormSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import SettingsCheckbox from '../../Common/Checkbox.js'
import {dispatch, getState, useSelector} from '../../../../store.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import ShipperOptions from '../../../../data/shipperOptions.js'
import PrettyNumber from '../../../../common/components/PrettyNumber.js'
import {shipperSelector} from '../../../../data/shippers.js'
import {isNumeric} from '../../../../common/utils.js'

function shippingMethodsSelector(state, {shipperID}) {
  const {vendor, hidden_shipping_methods} = shipperFormSelector(state, {
    shipperID,
  })
  const shipper = shipperSelector(state, {shipperID})

  const alternativeShippingMethods =
    vendor === UPS ? ['mail_innovations'] : null

  let shippingMethods = get(ShipperOptions, [vendor, 'shipping_method'])

  if (!shippingMethods || shippingMethods.length === 0) {
    return []
  }

  if (alternativeShippingMethods) {
    shippingMethods = alternativeShippingMethods.reduce((prev, namespace) => {
      const otherShippingMethods = get(ShipperOptions, [
        vendor,
        `shipping_method_${namespace}`,
      ])
      prev = [
        ...prev,
        ...otherShippingMethods.map((option) => ({
          ...option,
          value: `${namespace}__${option.value}`,
        })),
      ]

      return prev
    }, shippingMethods)
  }

  return Object.values(
    shippingMethods.reduce((prev, {value, display, transaction_fee}) => {
      const obj = prev[display] || {
        values: [],
        display,
        transaction_fee: isNumeric(transaction_fee)
          ? Number(transaction_fee)
          : transaction_fee
            ? shipper.transaction_fee
            : 0,
        included: true,
      }

      obj.values.push(value)
      obj.included =
        obj.included === false
          ? false
          : !hidden_shipping_methods.includes(value)

      prev[display] = obj

      return prev
    }, {}),
  )
}

function toggleAllHiddenShippingMethods(shipperID) {
  const {hidden_shipping_methods} = shipperFormSelector(getState(), {shipperID})
  const shippingMethods = shippingMethodsSelector(getState(), {shipperID})

  dispatch(
    updateForm(shipperID, {
      hidden_shipping_methods:
        hidden_shipping_methods.length !== 0
          ? []
          : shippingMethods.reduce(
              (prev, {values}) => [...prev, ...values],
              [],
            ),
    }),
  )
}

function toggleHiddenShippingMethods(shipperID, values) {
  const {hidden_shipping_methods} = shipperFormSelector(getState(), {shipperID})

  const newList = hidden_shipping_methods.filter((sm) => !values.includes(sm))

  if (hidden_shipping_methods.length === newList.length) {
    newList.push(...values)
  }

  dispatch(updateForm(shipperID, {hidden_shipping_methods: newList}))
}

export default function ShippingMethods({shipperID}) {
  const {hidden_shipping_methods} = useSelector((state) =>
    shipperFormSelector(state, {shipperID}),
  )
  let shippingMethods = useSelector((state) =>
    shippingMethodsSelector(state, {shipperID}),
  )

  if (shippingMethods.length === 0) {
    return null
  }

  return (
    <li className="form-list-item bigger margin-top-20 margin-bottom-20 divider--top">
      <dl className="list margin-bottom-15">
        <dt className="list__title fs-00">
          Which shipping methods would you like displayed?
        </dt>
        <dd className="list__item fs-n0">
          Deselect shipping methods that you DO NOT want to appear in shipping
          rate lists while creating labels.
        </dd>
      </dl>
      <ButtonPrimary
        isOutlined
        className="btn--x-sm margin-bottom-15"
        onClick={() => toggleAllHiddenShippingMethods(shipperID)}
      >
        {hidden_shipping_methods.length === 0 ? 'Deselect all' : 'Select all'}
      </ButtonPrimary>
      <ul className="list">
        {shippingMethods.map(
          ({values, display, included, transaction_fee}, index) => (
            <li
              className="list__item--no-style wrap--label-unbold margin-bottom-10"
              key={index}
            >
              <div>
                <SettingsCheckbox
                  id={`${shipperID}__${index}`}
                  label={transaction_fee ? `${display}*` : display}
                  checked={included}
                  onChange={() =>
                    toggleHiddenShippingMethods(shipperID, values)
                  }
                />
              </div>
              {transaction_fee ? (
                <div className="margin-top-1 margin-bottom-7">
                  <em className="fs-n1 lh-sm text--md-grey">
                    *A{' '}
                    <strong>
                      <PrettyNumber value={transaction_fee * 100} digits={2} />%
                      convenience fee
                    </strong>{' '}
                    will be charged for these labels.{' '}
                    <div>
                      <a
                        href="https://support.ordoro.com/how-does-the-first-class-mail-transaction-fee-work/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>{' '}
                      →
                    </div>
                  </em>
                </div>
              ) : null}
            </li>
          ),
        )}
      </ul>
    </li>
  )
}

ShippingMethods.propTypes = {
  shipperID: PropTypes.number.isRequired,
}
