import PropTypes from 'prop-types'

import ButtonLink from '../../../../../common/components/Button/ButtonLink.js'
import {showParentOrderModal} from '../../../../OrderListPage/Modals/ParentOrderModal.js'

export default function ParentOrder({orderNumber, parentOrderNumber}) {
  return (
    <>
      <div>
        <strong>Parent Order:</strong> <span>{parentOrderNumber}</span>
      </div>
      <ButtonLink onClick={() => showParentOrderModal(orderNumber)}>
        View details
      </ButtonLink>
    </>
  )
}

ParentOrder.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  parentOrderNumber: PropTypes.string.isRequired,
}
