import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'

import {useSelector, useAutoForm} from '../../../../store.js'
import DropdownItem from '../../../../common/components/Dropdown/DropdownItem.js'
import {useZropdown} from '../../../../common/components/Zropdown.js'
import FilterDropdown from '../../../../common/components/FilterDropdown.js'
import {
  presetOptionsSelector,
  presetOptionTokensSelector,
} from '../../../../data/presets.js'
import {currentDropdownSelector} from '../../../../redux/selectors/ui/index.js'

import {
  PresetsDropdownItem,
  filterPresets,
} from '../../../LabelConfig/SingleLabelConfigForm/PresetsDropdown.js'

export default function PresetSelect({
  dropdown,
  label,
  hasErrors,
  onChange,
  excludePresetIDs,
}) {
  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
  })

  const presetOptions = useSelector(presetOptionsSelector)
  const presetOptionTokens = useSelector(presetOptionTokensSelector)

  useEffect(() => {
    filterPresets(dropdown, presetOptions, presetOptionTokens, excludePresetIDs)
  }, [presetOptions, excludePresetIDs])

  const buttonRef = useRef()

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onChange: () =>
        filterPresets(
          dropdown,
          presetOptions,
          presetOptionTokens,
          excludePresetIDs,
        ),
      onSelect: (option) => onChange(option.entity.id),
    })

  const currentDropdown = useSelector(currentDropdownSelector)

  return (
    <div
      className={`wrap-outer--tag-dropdown-with-filter ${
        currentDropdown === dropdown ? 'open' : ''
      }`}
      data-dropdown={dropdown}
    >
      <button
        ref={buttonRef}
        className="btn btn--dropdown btn--filter"
        type="button"
      >
        <span className={`btn__text ${hasErrors ? 'error-message' : ''}`}>
          {label}
        </span>
      </button>
      <FilterDropdown
        dropdown={dropdown}
        form={form}
        onChangeInternal={onChangeInternal}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
        placeholder="Search Presets"
        includeSearchInput
        parentRef={buttonRef}
      >
        <>
          {form.results.map((option, index) => (
            <PresetsDropdownItem
              key={option.entity.id}
              preset={option.entity}
              applyPreset={() => onSelectInternal(option)}
              isSelected={index === form.selectedIndex}
            />
          ))}
          {form.hasFoundNothing && (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              No presets match your search.
            </li>
          )}
          {presetOptions.length === 0 && (
            <>
              <li className="list__item--dropdown list__item--dropdown-empty align-center">
                You haven’t created any presets, yet!
              </li>
              <DropdownItem>
                <li className="list__item list__item--dropdown list__item--dropdown-last">
                  <a href="https://support.ordoro.com/how-to-create-shipping-label-presets/">
                    <button
                      className="btn--link list__link list__link--dropdown"
                      type="button"
                    >
                      Learn how to set one up
                    </button>
                  </a>
                </li>
              </DropdownItem>
            </>
          )}
        </>
      </FilterDropdown>
    </div>
  )
}

PresetSelect.propTypes = {
  dropdown: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  excludePresetIDs: PropTypes.arrayOf(PropTypes.number),
}
