import PropTypes from 'prop-types'
import {Component} from 'react'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onSKUChange = (value) => updatePayload({sku: value})
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-5 columns" {...this.props}>
        <Row>
          <TextInput
            value={data.payload.sku}
            label="SKU"
            name="sku"
            className="medium-12"
            errorMessage={data.errors.sku}
            onChange={this.onSKUChange}
          />
        </Row>
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
