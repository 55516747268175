import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {
  INITIATED_STATUS,
  ACTIVE_STATUS,
  FINISHED_STATUS,
  CANCELLED_STATUS,
  MO_STATUS_DISPLAY,
} from '../../../common/constants/MOs.js'
import {PluralBlock, Plural, plural} from '../../../common/components/Plural.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setMO} from '../../../data/mos.js'
import {refreshMOList} from '../moListActions.js'

const MODAL_FORM = 'UPDATE_MO_STATUS_MODAL'

export function showUpdateMOStatusModal(
  referenceIDs,
  status = INITIATED_STATUS,
) {
  setForm(MODAL_FORM, {
    referenceIDs,
    status,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export async function updateMOStatus(referenceID, status) {
  const {json} = await apiverson.put(
    `/manufacturing_order/${encodeURIComponent(referenceID)}`,
    {status},
  )

  setMO(json)
}

export async function updateMOsStatus() {
  try {
    updateModalForm({isSaving: true})

    const {referenceIDs, status} = modalFormSelector(getState())
    const count = referenceIDs.length

    await Promise.all(
      referenceIDs.map((referenceID) => updateMOStatus(referenceID, status)),
    )

    showMessageToast(
      plural(count)`${count} MFG Order${['s were', ' was']} updated.`,
    )

    closeModal()

    await refreshMOList()
  } catch (err) {
    updateModalForm({
      serverError: `Error setting MFG Order status: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function UpdateMOStatusModal({form}) {
  const count = form.referenceIDs.length

  return (
    <ConfirmModal
      title="Update MFG Order Status"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => updateMOsStatus()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <PluralBlock count={count}>
        <ul className="list list--no-style">
          {count === 1 && (
            <li className="list__item--form list__item--no-style divider--bottom">
              <div className="fs-01">
                <strong>{form.referenceIDs[0]}</strong>
              </div>
            </li>
          )}
          <li className="list__item--form list__item--no-style fs-01">
            Update the status for <Plural p={count} s="this" /> MFG Order
            <Plural />:
          </li>
          {[
            INITIATED_STATUS,
            ACTIVE_STATUS,
            FINISHED_STATUS,
            CANCELLED_STATUS,
          ].map((status) => (
            <li className="list__item--no-style" key={status}>
              <label className="fs-00" htmlFor={status}>
                <input
                  className="margin-right-7"
                  type="radio"
                  name="return_order_line_code"
                  id={status}
                  value={status}
                  checked={status === form.status}
                  onChange={() => updateModalForm({status})}
                />
                {MO_STATUS_DISPLAY[status]}
              </label>
            </li>
          ))}
          {form.status === CANCELLED_STATUS && (
            <li className="list__item--no-style">
              <div className="alert alert--standard margin-bottom-0">
                <div>
                  <strong>
                    Heads up — cancelling a MFG order does not affect inventory.
                  </strong>
                </div>
                If you’ve restocked any manufactured products associated with{' '}
                <Plural p="these" s="this" /> MFG <Plural word="order" />, the
                inventory values WILL NOT be reset.
              </div>
            </li>
          )}
        </ul>
      </PluralBlock>
    </ConfirmModal>
  )
}

UpdateMOStatusModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default formConnect(UpdateMOStatusModal, modalFormSelector)
