import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SelectOptionsShape, SelectOptionTokens} from '../../common/PropTypes.js'
import ManageTagFilter from '../../common/components/ManageTagFilter.js'
import {TAG_TYPE__PRODUCT} from '../../common/constants/Tags.js'
import {
  hasProductManageTagPermissionSelector,
  hasProductManageTagOnProductPermissionSelector,
} from '../../data/me.js'
import {
  productTagOptionsSelector,
  productTagOptionTokensSelector,
  addTagToProducts,
  removeTagFromProducts,
} from '../../data/productTags.js'
import {
  createAndApplyTagToProducts,
  productTagQuantityMapSelector,
} from './productListFunctions.js'

function AddProductTagsDropdown({
  skus,
  productTagOptions,
  productTagOptionTokens,
  tagQuantityMap,
  hasProductManageTagPermission,
  hasProductManageTagOnProductPermission,
}) {
  if (!hasProductManageTagOnProductPermission) {
    return null
  }

  return (
    <li className="inline-block margin-left-10">
      <ManageTagFilter
        dropdown="APPLY_PRODUCT_TAG_FILTER"
        onSelect={(tag) => {
          if (tagQuantityMap[tag.id] === 'all') {
            removeTagFromProducts(tag.id, skus)
          } else {
            addTagToProducts(tag.id, skus)
          }
        }}
        tagOptions={productTagOptions}
        tagOptionTokens={productTagOptionTokens}
        tagQuantityMap={tagQuantityMap}
        hasManageTagPermission={hasProductManageTagPermission}
        tagType={TAG_TYPE__PRODUCT}
        onCreateAndApplyClick={() => createAndApplyTagToProducts(skus)}
      />
    </li>
  )
}

AddProductTagsDropdown.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.string).isRequired,
  productTagOptions: SelectOptionsShape.isRequired,
  productTagOptionTokens: SelectOptionTokens.isRequired,
  tagQuantityMap: PropTypes.object.isRequired,
  hasProductManageTagPermission: PropTypes.bool.isRequired,
  hasProductManageTagOnProductPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {skus}) {
  return {
    productTagOptions: productTagOptionsSelector(state),
    productTagOptionTokens: productTagOptionTokensSelector(state),
    tagQuantityMap: productTagQuantityMapSelector(state, {skus}),
    hasProductManageTagPermission: hasProductManageTagPermissionSelector(state),
    hasProductManageTagOnProductPermission:
      hasProductManageTagOnProductPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(AddProductTagsDropdown)
