import PropTypes from 'prop-types'
import {Component} from 'react'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default class ReconcileForm extends Component {
  constructor(props) {
    super(props)

    this.onChange = (value, name) => this.props.updateColumnMapping(value, name)
  }

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-12" {...this.props}>
        <Row>
          <div className="medium-12 columns">
            <h5>Order Info</h5>
          </div>
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.order_id}
            errorMessage={this.props.columnErrors.order_id}
            name="order_id"
            label="Order ID"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.order_date}
            errorMessage={this.props.columnErrors.order_date}
            name="order_date"
            label="Order Date"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.grand_total}
            errorMessage={this.props.columnErrors.grand_total}
            name="grand_total"
            label="Grand Total"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.notes_from_customer}
            errorMessage={this.props.columnErrors.notes_from_customer}
            name="notes_from_customer"
            label="Notes From Customer"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.product_amount}
            errorMessage={this.props.columnErrors.product_amount}
            name="product_amount"
            label="Product Amount"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.tax_amount}
            errorMessage={this.props.columnErrors.tax_amount}
            name="tax_amount"
            label="Tax Amount"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.discount_amount}
            errorMessage={this.props.columnErrors.discount_amount}
            name="discount_amount"
            label="Discount Amount"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.shipping_amount}
            errorMessage={this.props.columnErrors.shipping_amount}
            name="shipping_amount"
            label="Shipping Amount"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.shipping_type}
            errorMessage={this.props.columnErrors.shipping_type}
            name="shipping_type"
            label="Shipping Type"
            className="medium-5"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5>Shipping Address</h5>
          </div>
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_name}
            errorMessage={this.props.columnErrors.ship_name}
            name="ship_name"
            label="Name"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_company}
            errorMessage={this.props.columnErrors.ship_company}
            name="ship_company"
            label="Company"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_phone}
            errorMessage={this.props.columnErrors.ship_phone}
            name="ship_phone"
            label="Phone"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_email}
            errorMessage={this.props.columnErrors.ship_email}
            name="ship_email"
            label="Email"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_street1}
            errorMessage={this.props.columnErrors.ship_street1}
            name="ship_street1"
            label="Street1"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_street2}
            errorMessage={this.props.columnErrors.ship_street2}
            name="ship_street2"
            label="Street2"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_city}
            errorMessage={this.props.columnErrors.ship_city}
            name="ship_city"
            label="City"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_state}
            errorMessage={this.props.columnErrors.ship_state}
            name="ship_state"
            label="State"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_zip}
            errorMessage={this.props.columnErrors.ship_zip}
            name="ship_zip"
            label="Zip"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_country}
            errorMessage={this.props.columnErrors.ship_country}
            name="ship_country"
            label="Country"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5>Billing Address</h5>
          </div>
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_name}
            errorMessage={this.props.columnErrors.bill_name}
            name="bill_name"
            label="Name"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_company}
            errorMessage={this.props.columnErrors.bill_company}
            name="bill_company"
            label="Company"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_phone}
            errorMessage={this.props.columnErrors.bill_phone}
            name="bill_phone"
            label="Phone"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_email}
            errorMessage={this.props.columnErrors.bill_email}
            name="bill_email"
            label="Email"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_street1}
            errorMessage={this.props.columnErrors.bill_street1}
            name="bill_street1"
            label="Street1"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_street2}
            errorMessage={this.props.columnErrors.bill_street2}
            name="bill_street2"
            label="Street2"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_city}
            errorMessage={this.props.columnErrors.bill_city}
            name="bill_city"
            label="City"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_state}
            errorMessage={this.props.columnErrors.bill_state}
            name="bill_state"
            label="State"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_zip}
            errorMessage={this.props.columnErrors.bill_zip}
            name="bill_zip"
            label="Zip"
            className="medium-5"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.bill_country}
            errorMessage={this.props.columnErrors.bill_country}
            name="bill_country"
            label="Country"
            className="medium-5"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5>Order Lines</h5>
          </div>
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.product_sku}
            errorMessage={this.props.columnErrors.product_sku}
            name="product_sku"
            label="SKU"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.product_name}
            errorMessage={this.props.columnErrors.product_name}
            name="product_name"
            label="Name"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.item_price}
            errorMessage={this.props.columnErrors.item_price}
            name="item_price"
            label="Price"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.quantity}
            errorMessage={this.props.columnErrors.quantity}
            name="quantity"
            label="Quantity"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.line_discount_amount}
            errorMessage={this.props.columnErrors.discount_amount}
            name="line_discount_amount"
            label="Discount"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.total_price}
            errorMessage={this.props.columnErrors.total_price}
            name="total_price"
            label="Total Price"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.selected_option}
            errorMessage={this.props.columnErrors.selected_option}
            name="selected_option"
            label="Notes"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.product_serial_numbers}
            errorMessage={this.props.columnErrors.product_serial_numbers}
            name="product_serial_numbers"
            label="Serial Numbers"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
          </div>
        </Row>
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
