import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  updateParcel,
  parcelSelector,
  isMultiboxSelector,
  updateLabelProperties,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {useSelector} from '../../../store.js'
import {UPS} from '../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import {LABEL_PROPERTY_DRY_ICE_WEIGHT} from './DryIceWeight.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'packages[0].dry_ice_regulation_set'
const VALID_SHIPPER_TYPES = [UPS]

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_DRY_ICE_WEIGHT]
}

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType) ? [LABEL_PROPERTY] : []
}

export default function DryIceRegulationSet({packagesIndex}) {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  packagesIndex = packagesIndex || 0

  let {
    dry_ice_regulation_set: dryIceRegulationSet,
    dry_ice_weight: dryIceWeight,
  } = useSelector((state) =>
    parcelSelector(state, {labelInfoID, packagesIndex}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  dryIceWeight = Number(dryIceWeight) || 0

  useEffect(() => {
    if (dryIceWeight > 0 || !isLabelEnableMode) {
      return
    }

    updateLabelProperties(labelInfoID, labelPropertiesFunc(shipperType), false)
  }, [dryIceWeight, isLabelEnableMode])

  return (
    <ConfigSelect
      label="Dry Ice Regulation"
      value={dryIceRegulationSet || ''}
      onChange={(value) => {
        updateParcel(labelInfoID, packagesIndex, {
          dry_ice_regulation_set: value,
        })

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      dependantPropertiesFunc={dependantPropertiesFunc}
      shipperOptionsProperty="dry_ice_regulation_set"
      noEmptyOption
      hide={isMultibox || dryIceWeight <= 0}
    />
  )
}

DryIceRegulationSet.propTypes = {
  packagesIndex: PropTypes.number,
}
