import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

import loadScript from '../../../common/loadScript.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import {verifyUser} from '../../../redux/actions/ui/userValidity.js'
import TextInput from '../../../common/components/TextInput.js'
import {
  isValidatingUserSelector,
  isUserValidSelector,
} from '../../../redux/selectors/ui/userValidity.js'
import {dispatch, useSelector} from '../../../store.js'
import {NET_ZERO_TOKENIZATION} from '../../../common/constants/index.js'

export const NetZeroCreditCardFormShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  hasChanged_name: PropTypes.bool,
  isCardComplete: PropTypes.bool.isRequired,
  cardForm: PropTypes.object,
  serverError: PropTypes.string,
  cardResultError: PropTypes.string,
})

async function loadNetZero(onCardForm) {
  if (!window.HostedTokenization) {
    await loadScript(NET_ZERO_TOKENIZATION.SCRIPT_URL)
  }

  const tokenizationSourceKey = NET_ZERO_TOKENIZATION.SOURCE_KEY
  const hostedTokenization = new window.HostedTokenization(
    tokenizationSourceKey,
  )

  const cardForm = hostedTokenization.create('card-form')

  onCardForm(cardForm)
}

function useNetZero(parentEl, cardForm, onCardForm, onCardFormEvent) {
  useEffect(() => {
    loadNetZero(onCardForm)
  }, [])

  useEffect(() => {
    if (cardForm && parentEl) {
      cardForm.mount(parentEl, {fields: {zip: true}})

      cardForm.on('change', onCardFormEvent)
      cardForm.on('input', onCardFormEvent)

      cardForm.setStyles({
        container: 'padding: 1.25rem 0; color: #444',
        card: 'background: #fff; margin: 0; border-radius: 0.25rem; border: 1px solid #666666;box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px; height: 1.6875rem; min-height: 1.6875rem; padding: 0 0.3rem; font-size: 0.8125rem; color: #444; width: 96%; margin-bottom: 1.2rem ',
        expiryContainer:
          'display: inline-block; background: #fff; margin: 0; border-radius: 0.25rem; border: 1px solid #666666; box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px; height: 1.6875rem; min-height: 1.6875rem; padding: 0 0.3rem; font-size: 0.8125rem; color: #444; vertical-align: top; margin-right: .5rem;  ',
        expiryMonth:
          'border: 0; background: transparent; height: 1.45rem; font-size: 0.8125rem; color: #444  ',
        expirySeparator: 'border: 0; font-size: 0.8125rem; color: #444 ',
        expiryYear: 'border: 0; font-size: 0.8125rem; color: #444',
        cvv2: 'background: #fff; margin: 0; border-radius: 0.25rem; border: 1px solid #666666;box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px; height: 1.6875rem; min-height: 1.6875rem; padding: 0 0.3rem; font-size: 0.8125rem; color: #444; margin-right: .5rem ',
        avsZip:
          'background: #fff; margin: 0; border-radius: 0.25rem; border: 1px solid #666666;box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px; height: 1.6875rem; min-height: 1.6875rem; padding: 0 0.3rem; font-size: 0.8125rem; color: #444',
        labels:
          'font-size: 13px; font-family: Helvetica, sans-serif; color: #444; min-width: 7rem',
        // These are the styles used for the labels when they are floating.
        floatingLabelsPlaceholder:
          'font-size: 13px; font-family: Helvetica, sans-serif; top: -21px; color: #444;  min-width: 7rem',
        // These are the styles used for floating labels when the input is empty and they're functioning as placeholders.
        labelType: 'floating',
      })
    }
  }, [cardForm, parentEl])
}

export default function NetZeroCreditCardForm({
  form,
  errors,
  updateForm,
  onCardFormEvent,
}) {
  const [parentEl, setParentEl] = useState()
  const isValidatingUser = useSelector(isValidatingUserSelector)
  const isUserValid = useSelector(isUserValidSelector)

  useEffect(() => {
    dispatch(verifyUser())
  }, [])

  useNetZero(
    parentEl,
    form.cardForm,
    (cardForm) => updateForm({cardForm}),
    onCardFormEvent,
  )

  if (isValidatingUser) {
    return (
      <div className="loading align-center margin-top-20 margin-bottom-20">
        <span className="list-processing animate-spin v-align-middle" />
      </div>
    )
  }

  if (!isUserValid) {
    return (
      <div className="alert alert--error alert--lg full-border align-center margin-top-20 margin-bottom-20">
        <div
          className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-10"
          aria-hidden="true"
        />

        <p className="fs-00 error margin-bottom-5">
          <strong>There was a ReCAPTCHA verification error.</strong>
        </p>
        <p className="fs-00 error margin-bottom-0">
          Please contact Ordoro Support for help.
        </p>
      </div>
    )
  }

  return (
    <ul className="list list--form margin-bottom-10">
      <li className="list__item--form flex">
        <div className="w-75">
          <TextInput
            id="billing_name"
            label="Name on Card"
            required
            value={form.name}
            onChange={(value) =>
              updateForm({
                name: value,
                hasChanged_name: true,
              })
            }
            errorMessage={form.hasChanged_name && errors.name}
          />
        </div>
      </li>
      <li className="list__item--form divider--top margin-top-15">
        <div className="w-75 wrap--stripe-element">
          <label htmlFor="cart_element" data-form={form.cardForm}>
            Credit Card Info<span className="required">*</span>
          </label>
          <div
            ref={(node) => setParentEl(node)}
            id="net_zero_parent_element"
          ></div>
        </div>
      </li>
    </ul>
  )
}

NetZeroCreditCardForm.propTypes = {
  form: NetZeroCreditCardFormShape.isRequired,
  errors: ErrorsShape.isRequired,
  updateForm: PropTypes.func.isRequired,
  onCardFormEvent: PropTypes.func.isRequired,
}
