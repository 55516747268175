import isString from 'lodash/isString.js'
import isPlainObject from 'lodash/isPlainObject.js'
import uniq from 'lodash/uniq.js'

export default function className(strings, ...args) {
  let out = []
  for (const string of strings) {
    out.push(...string.split(/\s+/).filter((v) => v))

    if (args.length) {
      let arg = args.shift()

      if (Array.isArray(arg)) {
        out.push(...arg.filter((v) => !!v))
      } else if (isString(arg)) {
        arg.trim()
        if (arg) {
          out.push(arg)
        }
      } else if (isPlainObject(arg)) {
        Object.entries(arg).forEach(([key, value]) => {
          if (value) {
            out.push(key)
          }
        })
      }
    }
  }

  out = uniq(out)

  return out.join(' ')
}
