import PropTypes from 'prop-types'
import {useRef} from 'react'
import round from 'lodash/round.js'

import {HK_CANCEL} from '../../constants/HotKeys.js'
import {triggerHotKey} from '../HotKeys.js'
import {isNumeric, isPresent} from '../../utils.js'
import useAutoFocus from '../../hooks/useAutoFocus.js'

function isInBounds(value, min = -Infinity, max = Infinity) {
  return value <= max && value >= min
}

function increment({value, onIncrement, onChange, min, max}) {
  const newValue = round(Number(value || '0') + 1, 3)

  if (isNumeric(newValue) && isInBounds(newValue, min, max)) {
    if (onIncrement) {
      onIncrement(newValue)
    } else if (onChange) {
      onChange(newValue)
    }
  }
}

function decrement({value, onDecrement, onChange, min, max}) {
  const newValue = round(Number(value || '0') - 1, 3)

  if (isNumeric(newValue) && isInBounds(newValue, min, max)) {
    if (onDecrement) {
      onDecrement(newValue)
    } else if (onChange) {
      onChange(newValue)
    }
  }
}

export default function NumberInput({
  id,
  label,
  labelClassName,
  value,
  isInvalid,
  onChange,
  onIncrement,
  onDecrement,
  onBlur,
  onEnterKeyPress,
  min,
  max,
  autoFocus,
  required,
  disabled,
  containerClassName,
  className,
  errorMessage,
}) {
  const elementRef = useRef()
  isInvalid = (isInvalid || !!errorMessage) && !disabled

  useAutoFocus(elementRef, autoFocus)

  return (
    <>
      {label && id && (
        <label className={labelClassName} htmlFor={id}>
          <span>{label}</span>
          {required ? <span className="required">*</span> : null}
        </label>
      )}
      <div className={`flex ${containerClassName || ''}`}>
        <input
          type="text"
          id={id || ''}
          className={`input--x-sm input--w-suffix margin-bottom-0 ${
            isInvalid ? 'input--error' : ''
          } ${className || ''}`}
          value={isPresent(value) ? value : ''}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'ArrowUp') {
              increment({value, onIncrement, onChange, min, max})
            }
            if (event.key === 'ArrowDown') {
              decrement({value, onDecrement, onChange, min, max})
            }
            if (event.key === 'Enter' && onEnterKeyPress) {
              onEnterKeyPress()
            }
            if (event.key === 'Escape') {
              triggerHotKey(HK_CANCEL)
            }
          }}
          onBlur={onBlur ? (event) => onBlur(event.target.value) : null}
          disabled={disabled}
          style={{
            width: `${(value || '').toString().length * 0.45 + 1}rem`,
            minWidth: '2rem',
          }}
          ref={elementRef}
        />
        <div className="wrap--suffix-group flex--col">
          <button
            className="btn btn--suffix btn--arrow-up flex-1"
            type="button"
            onClick={() => increment({value, onIncrement, onChange, min, max})}
            tabIndex="-1"
            disabled={disabled}
          />
          <button
            className="btn btn--suffix btn--arrow-down flex-1"
            type="button"
            onClick={() => decrement({value, onDecrement, onChange, min, max})}
            tabIndex="-1"
            disabled={disabled}
          />
        </div>
      </div>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

NumberInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
}
