import {useSelector, onlyIfAutoForm} from '../../../store.js'
import {cartsHaveLoadedSelector} from '../../../data/carts.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'

import SettingsPanel from '../SettingsPanel.js'
import CartList from './CartList.js'
import {setupSalesChannelsForm} from './salesChannelsActions.js'

function SalesChannels() {
  const cartsHaveLoaded = useSelector(cartsHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).saleschannels

  return (
    <SettingsPanel
      header="Sales Channel Settings"
      hasPermission={hasPermission}
    >
      <div className="row">{cartsHaveLoaded && <CartList />}</div>
    </SettingsPanel>
  )
}

export default onlyIfAutoForm(SalesChannels, setupSalesChannelsForm)
