import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {batchSelector} from '../../../data/batches.js'
import FormattedDate from '../../../common/components/FormattedDate.js'
import {userSelector} from '../../../data/users.js'

export const MODAL_FORM = 'LATEST_BATCH_ACTIONS_MODAL'

export function showLatestBatchActionsModal({referenceID}) {
  setForm(MODAL_FORM, {
    referenceID,
  })
}

export function updateModalForm(...args) {
  return updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function Action({action}) {
  const user = useSelector((state) =>
    userSelector(state, {userID: action.user_id}),
  )

  return (
    <tr key={action.id}>
      <td className="table__td table__td--sm">
        <div
          className={classNames('outer-wrap--batch-action-status', {
            error: !action.success && action.complete,
            'action-in-progress': !action.complete,
          })}
        >
          {!action.success && action.complete && (
            <span
              className="i--error fs-n0 lh-sm v-align-middle margin-right-1"
              aria-hidden="true"
            />
          )}
          <strong className="fs-n0 lh-sm  v-align-middle wrap--batch-action-status">
            {action.complete
              ? action.success
                ? 'Completed'
                : 'Failed'
              : 'In-Progress'}
          </strong>{' '}
          <span className="fs-n0 capitalize v-align-middle lh-sm">
            {' '}
            – {action.action_type}
          </span>
        </div>
        {action.errors.length > 0 && (
          <ul className="list list--square error">
            {action.errors.map((error, index) => (
              <li className="list__item fs-n1 lh-md margin-top-5" key={index}>
                {error}
              </li>
            ))}
          </ul>
        )}
      </td>
      <td className="table__td table__td--sm">
        <span className="fs-n0">
          <FormattedDate
            value={action.created_date}
            format="MMM D, YYYY [at] h:mma"
          />
        </span>
      </td>
      <td className="table__td table__td--sm">
        <div className="fs-n0">{user.name}</div>
        <em className="fs-n1 text--lt-grey">{user.email}</em>
      </td>
    </tr>
  )
}

Action.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    action_type: PropTypes.string.isRequired,
    created_date: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
}

function LatestBatchActionsModal({form}) {
  const batch = useSelector((state) =>
    batchSelector(state, {referenceID: form.referenceID}),
  )

  if (!batch) {
    return null
  }

  return (
    <ConfirmModal
      title="Latest Batch Actions"
      modalSize="lg"
      onCancel={() => closeModal()}
      cancelText="Close"
    >
      <p className="fs-01 lh-md margin-bottom-10">
        <strong>{form.referenceID}</strong>
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--sm w-50">Action Status</th>
            <th className="table__th table__th--sm w-25">Date Started</th>
            <th className="table__th table__th--sm w-25">User</th>
          </tr>
        </thead>
        <tbody className="table__tbody--lines">
          {batch.latest_actions.map((action) => (
            <Action key={action.id} action={action} />
          ))}
        </tbody>
      </table>
    </ConfirmModal>
  )
}

LatestBatchActionsModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
  }).isRequired,
}

export default onlyIfForm(LatestBatchActionsModal, modalFormSelector)
