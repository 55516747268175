import Footer from '../../common/components/List/Footer/index.js'
import {useSelector} from '../../store.js'
import {setLimit, setOffset} from './purchaseOrderListActions.js'
import {
  isLoadingSelector,
  poListQuerySelector,
  countSelector,
} from './purchaseOrderListSelectors.js'

export default function POListFooter() {
  const isLoading = useSelector(isLoadingSelector)
  const {limit, offset} = useSelector(poListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <Footer
      isLoading={isLoading}
      limit={limit}
      offset={offset}
      count={count}
      setLimit={setLimit}
      setOffset={setOffset}
      preventResetOffset
    />
  )
}
