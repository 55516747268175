import PropTypes from 'prop-types'

import {CommentShape} from '../../../PropTypes.js'
import User from './User.js'
import {
  BULK_UPDATE_APPLY_PRESETS_COMMENT,
  BULK_UPDATE_WAREHOUSE_COMMENT,
  BULK_UPDATE_WEIGHT_COMMENT,
  BULK_UPDATE_LABEL_INFO_CONFIG_COMMENT,
  BULK_UPDATE_DIMENSIONS_COMMENT,
  BULK_UPDATE_SHIP_DATE_COMMENT,
  BULK_UPDATE_PACKING_LIST_COMMENT,
} from '../../../constants/Comments.js'
import {Plural, PluralBlock} from '../../Plural.js'
import Weight from '../../Weight.js'
import Dimensions from '../../Dimensions.js'
import FormattedDate from '../../FormattedDate.js'
import Currency from '../../Currency.js'

function ApplyPresetComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      <PluralBlock array={action.presets}>
        Task started to bulk apply <Plural word="preset" />:{' '}
        {action.presets.join(', ')}
      </PluralBlock>
    </dd>
  )
}

ApplyPresetComment.propTypes = {
  action: PropTypes.shape({
    presets: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

function UpdateWarehouseComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      Task started to bulk update ship from: {action.warehouse_name}
    </dd>
  )
}

UpdateWarehouseComment.propTypes = {
  action: PropTypes.shape({
    warehouse_name: PropTypes.string.isRequired,
  }).isRequired,
}

function UpdateWeightComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      Task started to bulk update weight: <Weight value={action.weight} />
    </dd>
  )
}

UpdateWeightComment.propTypes = {
  action: PropTypes.shape({
    weight: PropTypes.number.isRequired,
  }).isRequired,
}

function UpdateLabelInfoConfigComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      <div>Task started to bulk update the shipping label configuration:</div>
      <ul className="list list--square margin-top-1 margin-bottom-3">
        <li className="list__item fs-n1 lh-sm margin-bottom-1">
          Shipper: {action.shipper_name}
        </li>
        <li className="list__item fs-n1 lh-sm margin-bottom-1">
          Package Type: {action.box_shape_name}
        </li>
        {action.include_insurance && (
          <li className="list__item fs-n1 lh-sm margin-bottom-0">
            Insurance: <Currency value={action.insured_value} />
          </li>
        )}
        {action.is_mail_innovations && <li>Mail Innovations</li>}
      </ul>
    </dd>
  )
}

UpdateLabelInfoConfigComment.propTypes = {
  action: PropTypes.shape({
    shipper_name: PropTypes.string.isRequired,
    box_shape_name: PropTypes.string.isRequired,
    include_insurance: PropTypes.bool,
    insured_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_mail_innovations: PropTypes.bool,
  }).isRequired,
}

function UpdateDimensionsComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      Task started to bulk update dimensions:{' '}
      <Dimensions
        height={action.height}
        length={action.length}
        width={action.width}
      />
    </dd>
  )
}

UpdateDimensionsComment.propTypes = {
  action: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    length: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
}

function UpdateShipDateComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      Task started to bulk update ship date:{' '}
      <FormattedDate value={action.ship_date} />
    </dd>
  )
}

UpdateShipDateComment.propTypes = {
  action: PropTypes.shape({
    ship_date: PropTypes.string.isRequired,
  }).isRequired,
}

function UpdatePackingListComment({action}) {
  return (
    <dd className="list__item--order-data list__item--timeline-point">
      Task started to bulk update profile: {action.packing_list_name}
    </dd>
  )
}

UpdatePackingListComment.propTypes = {
  action: PropTypes.shape({
    packing_list_name: PropTypes.string.isRequired,
  }).isRequired,
}

export default function BulkUpdateComment({
  comment: {date, user, actions = []},
}) {
  return (
    <dl className="list--order-data list--timeline-point">
      <dt className="list__item--order-data list__title--timeline-point">
        <FormattedDate value={date} format="LT" />
      </dt>
      {actions.map((action, index) =>
        action.type === BULK_UPDATE_APPLY_PRESETS_COMMENT ? (
          <ApplyPresetComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_WAREHOUSE_COMMENT ? (
          <UpdateWarehouseComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_WEIGHT_COMMENT ? (
          <UpdateWeightComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_LABEL_INFO_CONFIG_COMMENT ? (
          <UpdateLabelInfoConfigComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_DIMENSIONS_COMMENT ? (
          <UpdateDimensionsComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_SHIP_DATE_COMMENT ? (
          <UpdateShipDateComment key={index} action={action} />
        ) : action.type === BULK_UPDATE_PACKING_LIST_COMMENT ? (
          <UpdatePackingListComment key={index} action={action} />
        ) : (
          <dd
            key={index}
            className="list__item--order-data list__item--timeline-point"
          >
            {action.type}
          </dd>
        ),
      )}
      <dd className="list__item--order-data list__item--timeline-point">
        {user && <User email={user} />}
      </dd>
    </dl>
  )
}

BulkUpdateComment.propTypes = {
  comment: CommentShape,
}
