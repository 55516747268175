import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {WarehouseShape} from '../PropTypes.js'
import MultiSelect from './Form/MultiSelect.js'
import {
  warehousesSortedByNameSelector,
  getWarehouseName,
} from '../../data/warehouses.js'

function WarehouseMultiSelect({warehouseIDs, scope, onChange, warehouses}) {
  scope = scope || 'warehouse_multi_select'

  return (
    <MultiSelect
      scope={scope}
      options={warehouses.map((warehouse) => ({
        value: warehouse.id,
        display: getWarehouseName(warehouse),
      }))}
      selected={warehouseIDs}
      onChange={(selected) => onChange(selected)}
    />
  )
}

WarehouseMultiSelect.propTypes = {
  warehouseIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  scope: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  warehouses: PropTypes.arrayOf(WarehouseShape).isRequired,
}

function mapStateToProps(state) {
  return {
    warehouses: warehousesSortedByNameSelector(state),
  }
}

export default connect(mapStateToProps)(WarehouseMultiSelect)
