import PropTypes from 'prop-types'
import NumberInput from '../../../common/components/Form/NumberInput.js'

import {updateForm, useForm, useSelector} from '../../../store.js'
import FormListItem from '../Common/FormListItem.js'
import Link from '../../../common/components/Link/Link.js'
import {cartFormErrorsSelector} from './salesChannelsSelectors.js'

export default function AmazonFulfillmentLatencyInput({className, formName}) {
  const {fulfillmentLatency, hasChanged_fulfillmentLatency} = useForm(formName)
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  const id = `${formName}_fulfillmentLatency`
  return (
    <FormListItem className={className}>
      <div className="inline-block margin-right-20">
        <label className="fs-00 margin-bottom-5" htmlFor={id}>
          <span className="margin-right-3">Fulfillment Latency</span>
          <Link
            className="btn no-underline v-align-middle"
            href="https://support.ordoro.com/how-do-i-update-the-amazon-fulfillment-latency-in-ordoro/"
            newWindow
          >
            <span
              className="icon icon-support icon-block fs-n0"
              aria-hidden="true"
            />
          </Link>
        </label>
        <div className="flex">
          <NumberInput
            id={id}
            value={fulfillmentLatency}
            onChange={(value) =>
              updateForm(formName, {
                fulfillmentLatency: String(value),
                hasChanged_fulfillmentLatency: true,
              })
            }
          />
          <span className="input__unit inline-block">Days</span>
        </div>
      </div>
      {hasChanged_fulfillmentLatency && errors.fulfillmentLatency && (
        <small className="error error-message">
          {errors.fulfillmentLatency}
        </small>
      )}
    </FormListItem>
  )
}

AmazonFulfillmentLatencyInput.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
