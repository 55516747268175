import {pendingBulkInsuranceCostSelector} from '../../BulkLabelConfigForm/bulkLabelConfigSelectors.js'
import {
  updateLabelConfig,
  includeInsuranceSelector,
  insuredValueSelector,
  canHaveInsuranceSelector,
} from '../../../../data/labelInfos/index.js'

import Insurance from './Insurance.js'
import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function BulkInsurance({labelInfoIDs}) {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {labelInfoID}),
  )
  const insuredValue = useSelector((state) =>
    insuredValueSelector(state, {labelInfoID}),
  )
  const insuranceCost = useSelector((state) =>
    pendingBulkInsuranceCostSelector(state, {labelInfoIDs}),
  )
  const canHaveInsurance = useSelector((state) =>
    canHaveInsuranceSelector(state, {labelInfoID, shipperType}),
  )

  if (!canHaveInsurance) {
    return null
  }

  return (
    <ul className="list margin-top-10">
      <Insurance
        includeInsurance={includeInsurance}
        toggle={() => {
          updateLabelConfig(labelInfoID, {
            include_insurance: !includeInsurance,
          })
        }}
        insuredValue={insuredValue}
        setInsuredValue={(value) => {
          updateLabelConfig(labelInfoID, {insured_value: value})
        }}
        insuranceCost={insuranceCost}
      />
    </ul>
  )
}

BulkInsurance.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
