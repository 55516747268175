import {combineReducers} from 'redux'

import INITIAL_STATE from '../INITIAL_STATE.js'
import {VIEW_TASKS, CLEAR_EXPORTS} from '../../actions/ui/tasks.js'

function lastViewedTime(
  state = INITIAL_STATE.ui.tasks.lastViewedTime,
  action = {},
) {
  if (action.type === VIEW_TASKS) {
    return new Date().toISOString()
  }
  return state
}

function exportsClearedTime(
  state = INITIAL_STATE.ui.tasks.exportsClearedTime,
  action = {},
) {
  if (action.type === CLEAR_EXPORTS) {
    return new Date().toISOString()
  }
  return state
}

export default combineReducers({
  lastViewedTime,
  exportsClearedTime,
})
