import PropTypes from 'prop-types'
import {useEffect} from 'react'
import cloneDeep from 'lodash/cloneDeep.js'
import isEqual from 'lodash/isEqual.js'
import isEmpty from 'lodash/isEmpty.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {Address} from '../../../common/address.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {LabelConfigShape, LabelInfoIDShape} from '../../../common/PropTypes.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  importerOfRecordErrorsSelector,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'

export const MODAL_FORM = 'IMPORTER_OF_RECORD_MODAL_FORM'

export function showImporterOfRecordModal(labelInfoID, shipperType) {
  const savedConfig = cloneDeep(labelConfigSelector(getState(), {labelInfoID}))

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    savedConfig,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export async function confirm() {
  const {labelInfoID, savedConfig} = modalFormSelector(getState())
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  closeModal()

  if (!isEqual(savedConfig, labelConfig)) {
    showMessageToast('Importer of Record was successfully updated.')

    await getRates([labelInfoID])
  }
}

export async function cancel() {
  const {labelInfoID, savedConfig} = modalFormSelector(getState())

  closeModal()

  updateLabelConfig(labelInfoID, savedConfig)

  await getRates([labelInfoID])
}

const TIN_TYPES = [
  {value: 'BUSINESS_NATIONAL', display: 'Business National'},
  {value: 'BUSINESS_STATE', display: 'Business State'},
  {value: 'BUSINESS_UNION', display: 'Business Union'},
  {value: 'PERSONAL_NATIONAL', display: 'Personal National'},
  {value: 'PERSONAL_STATE', display: 'Personal State'},
]

function ImporterOfRecordModal({form}) {
  const {labelInfoID, shipperType} = form
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const errors = useSelector((state) =>
    importerOfRecordErrorsSelector(state, {labelInfoID, shipperType}),
  )

  const importer_of_record = labelConfig.importer_of_record || {
    address: {...new Address(), country: 'US'},
    tin: {
      number: '',
      tin_type: TIN_TYPES[0].value,
    },
    account_number: '',
  }

  useEffect(() => {
    if (!labelConfig.importer_of_record) {
      updateLabelConfig(form.labelInfoID, {
        importer_of_record,
      })
    }
  }, [labelConfig.importer_of_record])

  return (
    <ConfirmModal
      title="Edit Importer of Record"
      modalSize="sm"
      onConfirm={() => confirm()}
      onCancel={() => cancel()}
      confirmText="Save"
      cancelText="Cancel"
      isDisabled={!isEmpty(errors)}
    >
      <p className="alert alert--standard margin-bottom-25">
        <span className="fs-n0 lh-md">
          An importer of record is the entity or individual who is responsible
          for all entry documents required by CBP (Customs Border Protection)
          and for the product classification and payment of duties, as well as
          any other import obligations.
        </span>
      </p>
      <ul className="list list--form">
        <li className="list__item--form flex--justify">
          <div className="wrap--input-half">
            <label htmlFor="address__company" className="label--bold">
              Company Name
            </label>
            <input
              id="address__company"
              type="text"
              value={importer_of_record.address.company}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      company: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__company && (
              <small className="error">{errors.address__company}</small>
            )}
          </div>
          <div className="wrap--input-half">
            <label htmlFor="address__name" className="label--bold">
              Contact Name
            </label>
            <input
              id="address__name"
              type="text"
              value={importer_of_record.address.name}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      name: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__name && (
              <small className="error">{errors.address__name}</small>
            )}
          </div>
        </li>
        <li className="list__item--form">
          <label htmlFor="address__street1" className="label--bold">
            Street 1
          </label>
          <input
            id="address__street1"
            type="text"
            value={importer_of_record.address.street1}
            onChange={(event) =>
              updateLabelConfig(form.labelInfoID, {
                importer_of_record: {
                  ...importer_of_record,
                  address: {
                    ...importer_of_record.address,
                    street1: event.target.value,
                  },
                },
              })
            }
          />
          {errors.address__street1 && (
            <small className="error">{errors.address__street1}</small>
          )}
        </li>
        <li className="list__item--form">
          <label htmlFor="address__street2" className="label--bold">
            Street 2
          </label>
          <input
            id="address__street2"
            type="text"
            value={importer_of_record.address.street2}
            onChange={(event) =>
              updateLabelConfig(form.labelInfoID, {
                importer_of_record: {
                  ...importer_of_record,
                  address: {
                    ...importer_of_record.address,
                    street2: event.target.value,
                  },
                },
              })
            }
          />
          {errors.address__street2 && (
            <small className="error">{errors.address__street2}</small>
          )}
        </li>
        <li className="list__item--form flex--justify">
          <div className="wrap--input-half">
            <label htmlFor="address__city" className="label--bold">
              City
            </label>
            <input
              id="address__city"
              type="text"
              value={importer_of_record.address.city}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      city: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__city && (
              <small className="error">{errors.address__city}</small>
            )}
          </div>
          <div className="wrap--input-eighth">
            <label htmlFor="address__state" className="label--bold">
              State
            </label>
            <input
              id="address__state"
              type="text"
              value={importer_of_record.address.state}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      state: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__state && (
              <small className="error">{errors.address__state}</small>
            )}
          </div>
          <div className="wrap--input-third">
            <label htmlFor="address__zip" className="label--bold">
              Zipcode
            </label>
            <input
              id="address__zip"
              type="text"
              value={importer_of_record.address.zip}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      zip: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__zip && (
              <small className="error">{errors.address__zip}</small>
            )}
          </div>
        </li>
        <li className="list__item--form flex--justify">
          <div className="wrap--input-half">
            <label htmlFor="address__country" className="label--bold">
              Country
            </label>
            <input
              id="address__country"
              type="text"
              value={importer_of_record.address.country}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      country: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__country && (
              <small className="error">{errors.address__country}</small>
            )}
          </div>
        </li>
        <li className="list__item--form flex--justify">
          <div className="wrap--input-half">
            <label htmlFor="address__phone" className="label--bold">
              Phone
            </label>
            <input
              id="address__phone"
              type="text"
              value={importer_of_record.address.phone}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    address: {
                      ...importer_of_record.address,
                      phone: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.address__phone && (
              <small className="error">{errors.address__phone}</small>
            )}
          </div>
        </li>
      </ul>
      <ul className="list list--form">
        <li className="list__item--form flex--justify divider--top margin-top-20">
          <div className="wrap--input-half">
            <label htmlFor="account_number" className="label--bold">
              Carrier Account Number
            </label>
            <input
              id="account_number"
              type="text"
              value={importer_of_record.account_number}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    account_number: event.target.value,
                  },
                })
              }
            />
            {errors.account_number && (
              <small className="error">{errors.account_number}</small>
            )}
          </div>
        </li>
        <li className="list__item--form flex--justify divider--top margin-top-20">
          <div className="wrap--input-half">
            <label htmlFor="tin__tin_type" className="label--bold">
              Tax ID Type
            </label>
            <select
              id="tin__tin_type"
              className="select w-100 border-box"
              value={importer_of_record.tin.tin_type}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    tin: {
                      ...importer_of_record.tin,
                      tin_type: event.target.value,
                    },
                  },
                })
              }
            >
              {TIN_TYPES.map(({value, display}) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
            </select>
          </div>
          <div className="wrap--input-half">
            <label htmlFor="tin__number" className="label--bold">
              Tax ID Number
            </label>
            <input
              id="tin__number"
              type="text"
              value={importer_of_record.tin.number}
              onChange={(event) =>
                updateLabelConfig(form.labelInfoID, {
                  importer_of_record: {
                    ...importer_of_record,
                    tin: {
                      ...importer_of_record.tin,
                      number: event.target.value,
                    },
                  },
                })
              }
            />
            {errors.tin__number && (
              <small className="error">{errors.tin__number}</small>
            )}
          </div>
        </li>
      </ul>
    </ConfirmModal>
  )
}

ImporterOfRecordModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    savedConfig: LabelConfigShape.isRequired,
  }).isRequired,
}

export default onlyIfForm(ImporterOfRecordModal, modalFormSelector)
