import apiverson from '../../../../../../common/apiverson.js'
import {showGlobalError} from '../../../../../../ordoro/GlobalErrorMessage.js'
import {getShippers} from '../../../../../../data/shippers.js'

export const BASE = 'ordoro/ui/settings/shippers/forms/pitney'
export const SAVE_SHIPPER_PITNEY = `${BASE}/SAVE_SHIPPER_PITNEY`

export function saveShipperPitney(shipperID) {
  return {
    type: SAVE_SHIPPER_PITNEY,
    payload: shipperID,
  }
}

export async function activatePitneyShipper() {
  try {
    await apiverson.post('/carrier/pitney')

    await getShippers()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error activating USPS account.',
        details: err.message || err.error_message,
      },
      err,
    )
  }
}
