import RefreshButton from '../../common/components/List/RefreshButton.js'
import {refreshPurchaseOrderList} from './purchaseOrderListActions.js'

export default function RefreshPOs() {
  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint="Refresh the PO list"
    >
      <RefreshButton onClick={() => refreshPurchaseOrderList()} />
    </li>
  )
}
