import {
  labelConfigSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {useSelector} from '../../../store.js'
import {
  AMAZON_SFP,
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../../common/constants/ShipperNames.js'
import ShipperOptions from '../../../data/shipperOptions.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [
  AMAZON_SFP,
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__delivery_confirmation`]
    : []
}

export function fedexDeliveryConfirmationOptionsSelector(state, {labelInfoID}) {
  const shipperOptions = ShipperOptions[FEDEX]
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  const isResidential = !!labelConfig.recipient_address_is_residential

  if (isResidential) {
    return shipperOptions.delivery_confirmation
  }

  return shipperOptions.delivery_confirmation.filter(
    (option) => option.value !== 'INDIRECT',
  )
}

export function deliveryConfirmationOptionsSelector(
  state,
  {labelInfoID, shipperType},
) {
  const shipperOptions = ShipperOptions[shipperType]

  if (shipperType === FEDEX) {
    return fedexDeliveryConfirmationOptionsSelector(state, {labelInfoID})
  }

  return shipperOptions.delivery_confirmation
}

export default function DeliveryConfirmation() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const options = useSelector((state) =>
    deliveryConfirmationOptionsSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ConfigSelect
      label="Delivery Confirmation"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      noEmptyOption={AMAZON_SFP === shipperType}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      options={options}
    />
  )
}
