import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../../data/labelInfos/index.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {getState, useSelector} from '../../../../store.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_ATTACH_CUSTOMS_INFO} from '../../SingleLabelConfigForm/AttachCustomsInfo.js'
import {LABEL_PROPERTY_SHIPPER_ID} from '../ShipperSelect/index.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'etd_service'
const VALID_SHIPPER_TYPES = [FEDEX]
const VALID_LABEL_PROPERTIES = [LABEL_PROPERTY]

function labelPropertiesFunc(shipperType, labelInfoID) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  return VALID_SHIPPER_TYPES.includes(shipperType) &&
    labelConfig.attach_customs_info
    ? [LABEL_PROPERTY]
    : []
}

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
}

export default function ETDService() {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const config = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  return (
    <ConfigCheckbox
      label="FedEx® Electronic Trade Documents Service"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      labelPropertiesFunc={labelPropertiesFunc}
      validLabelProperties={VALID_LABEL_PROPERTIES}
      validShipperTypes={VALID_SHIPPER_TYPES}
      hide={!config.attach_customs_info}
      dependantPropertiesFunc={dependantPropertiesFunc}
    />
  )
}
