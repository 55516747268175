import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  isInTrialSelector,
  trialEndDateSelector,
  usesAppsSelector,
} from '../../data/company.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'
import {currentDateSelector} from '../CurrentDateListener.js'
import {useSelector} from '../../store.js'

export default function TrialNotice() {
  const isInTrial = useSelector(isInTrialSelector)
  const trialEndDate = useSelector(trialEndDateSelector)
  const currentDate = useSelector(currentDateSelector)
  const usesApps = useSelector(usesAppsSelector)

  if (!isInTrial || trialEndDate === null) {
    return null
  }

  const daysUntilOverTrial = differenceInCalendarDays(trialEndDate, currentDate)

  return (
    <div className="outer-wrap--callout-trial">
      <div className="wrap--callout-trial">
        {daysUntilOverTrial < 1 ? (
          <strong className="label__callout label__callout--trial">
            Your trial has ended.
          </strong>
        ) : (
          <strong className="label__callout label__callout--trial">
            Trial:{' '}
            {daysUntilOverTrial === 1
              ? 'Last day!'
              : `${daysUntilOverTrial} days left`}
          </strong>
        )}
      </div>
      <div className="wrap--callout-trial">
        <ButtonPrimary
          className="btn--primary-demo"
          size="sm"
          onClick={() => showUpgradeModal()}
        >
          {usesApps ? 'Manage Your Apps' : 'Select a Plan'}
        </ButtonPrimary>
      </div>
    </div>
  )
}
