import PropTypes from 'prop-types'

import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'

import ActionSelect from './ActionSelect.js'
import SupplierSelect from './SupplierSelect.js'
import {setRuleDropshipTo} from '../rulesFunctions.js'
import {useSelector} from '../../../../store.js'
import {supplierNameSelector} from '../../../../data/suppliers.js'

export default function DropshipTo({
  id,
  index,
  action: {
    data: {supplier_id},
  },
  isEditing,
}) {
  const supplierName = useSelector((state) =>
    supplierNameSelector(state, {supplierID: supplier_id}),
  )

  if (!isEditing) {
    if (supplier_id === null) {
      return null
    }

    return (
      <div>
        <strong className="margin-right-5">Dropship to supplier</strong>
        <ul className="list list--tags-lg inline-block">
          <li className="list__item--inline rule-criteria">
            <strong className="fs-00">{supplierName}</strong>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="inline-block">
      <div className="inline-block v-align-middle margin-bottom-10">
        <ActionSelect ruleID={id} index={index} />
      </div>
      <div className="inline-block v-align-middle margin-bottom-10">
        <strong className="op-50 lh-xl v-align-middle margin-right-10">
          →
        </strong>
        <SupplierSelect
          selectedSupplierID={supplier_id}
          onChange={(supplierID) => {
            setRuleDropshipTo(id, index, supplierID)
          }}
        />
      </div>
    </div>
  )
}

DropshipTo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
}
