import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {setExpandedReferenceIDs, setExpandMode} from './moListActions.js'
import {
  expandModeSelector,
  expandedReferenceIDsSelector,
} from './moListSelectors.js'

function MOExpandAll({expandMode, expandedMOCount}) {
  const shouldCollapse =
    expandedMOCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={
        shouldCollapse ? 'Collapse all MFG Orders' : 'Expand all MFG Orders'
      }
    >
      <ExpandAllButton
        className="meta-rmalistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          setExpandedReferenceIDs([])
          setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          setExpandedReferenceIDs([])
          setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}

MOExpandAll.propTypes = {
  expandMode: PropTypes.string.isRequired,
  expandedMOCount: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {
    expandMode: expandModeSelector(state),
    expandedMOCount: expandedReferenceIDsSelector(state).length,
  }
}

export default connect(mapStateToProps)(MOExpandAll)
