import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {navigate} from '../../../redux/actions/ui/index.js'
import {HotKeyConnect} from '../HotKeys.js'

function LinkButton({
  children,
  className,
  hotKey,
  href,
  target,
  mode = 'link',
  isOutlined,
  size,
  alt,
  navigate: doNavigate,
  ...props
}) {
  const link = (
    <a
      className={`btn btn--${mode} ${alt ? 'btn--primary-alt' : ''} ${
        isOutlined ? 'btn--primary-ol' : ''
      } ${size ? `btn--${size}` : ''} ${className || ''}`}
      href={href}
      target={target}
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  )

  if (hotKey) {
    return (
      <HotKeyConnect
        code={hotKey}
        func={() => {
          if (target) {
            window.open(href, target)
          } else {
            doNavigate(href)
          }
        }}
      >
        {link}
      </HotKeyConnect>
    )
  }

  return link
}

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hotKey: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  mode: PropTypes.string,
  size: PropTypes.string,
  isOutlined: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  alt: PropTypes.bool,
}

const mapDispatchToProps = {
  navigate,
}

export default connect(null, mapDispatchToProps)(LinkButton)
