import {onlyIfAutoForm, useSelector} from '../../../store.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {warehousesHaveLoadedSelector} from '../../../data/warehouses.js'
import SettingsPanel from '../SettingsPanel.js'
import List from './List.js'
import {setupWarehousesForm} from './warehousesFunctions.js'

export function Warehouses() {
  const hasLoaded = useSelector(warehousesHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).warehouse
  return (
    <SettingsPanel
      header="Warehouse Settings"
      subHeader={
        <div>
          Setup warehouse locations if you handle the shipping of all or some of
          your products in-house.
        </div>
      }
      hasPermission={hasPermission}
    >
      <div className="row">{hasLoaded && <List />}</div>
    </SettingsPanel>
  )
}

export default onlyIfAutoForm(Warehouses, setupWarehousesForm)
