import {openModal, closeModal} from '../../ui/index.js'

export const MODAL_NAME = 'CONFIRM_ARCHIVE_PRODUCT_MODAL'

export function showConfirmArchiveProductModal({skus, willArchive}) {
  return openModal(MODAL_NAME, {skus, willArchive})
}

export function confirm(isConfirmed) {
  return closeModal({isConfirmed})
}
