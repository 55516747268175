import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  Count,
  IfPlural,
  IfSingle,
  plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import {removeRuleFromList} from '../Rules/rulesFunctions.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'

const MODAL_FORM = 'DELETE_RULES_MODAL_FORM'

export function showDeleteRulesModal(ruleIDs) {
  setForm(MODAL_FORM, {
    ruleIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deleteRules() {
  try {
    updateModalForm({isSaving: true})

    const {ruleIDs} = modalFormSelector(getState())

    await Promise.all(ruleIDs.map((ruleID) => removeRuleFromList(ruleID)))

    showMessageToast(
      plural(ruleIDs)`${Count} Rule${['s were', ' was']} deleted`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error deleting rule: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

function DeleteRulesModal({form}) {
  return (
    <ConfirmModal
      title={plural(form.ruleIDs)`Delete Rule${['s']}`}
      onConfirm={() => deleteRules()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <PluralBlock array={form.ruleIDs}>
        <IfPlural>
          Delete <Count /> rules?
        </IfPlural>
        <IfSingle>Delete this rule?</IfSingle>
      </PluralBlock>
    </ConfirmModal>
  )
}

DeleteRulesModal.propTypes = {
  form: PropTypes.shape({
    ruleIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(DeleteRulesModal, modalFormSelector)
