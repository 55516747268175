import {useEffect} from 'react'
import PropTypes from 'prop-types'

export default function WindowFocusListener({onFocus, onBlur}) {
  useEffect(() => {
    const handleFocus = (event) => {
      onFocus(event)
    }

    const handleBlur = (event) => {
      onBlur(event)
    }

    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [])

  return null
}

WindowFocusListener.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}
