import PropTypes from 'prop-types'

import {
  labelConfigSelector,
  labelInfoIDForOrderAndLabelTypeSelector,
} from '../../data/labelInfos/index.js'
import SingleLabelConfigForm from '../LabelConfig/SingleLabelConfigForm/index.js'
import {useSelector} from '../../store.js'

export default function LabelConfigPanel({orderNumber, labelType}) {
  const labelInfoID = useSelector((state) =>
    labelInfoIDForOrderAndLabelTypeSelector(state, {
      orderNumber,
      labelType,
    }),
  )
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  const labelConfigIsReady = Object.keys(labelConfig).length !== 0

  if (!labelConfigIsReady) {
    return null
  }

  return <SingleLabelConfigForm labelInfoID={labelInfoID} />
}

LabelConfigPanel.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  labelType: PropTypes.string.isRequired,
}
