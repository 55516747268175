import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {
  RETURN_ORDER_STATUS_OPTIONS,
  ALL_STATUS,
} from '../../common/constants/ReturnOrders.js'
import {
  TAG_FILTER_OR,
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_NOT,
} from '../../common/constants/Tags.js'
import OmniBar from '../../common/components/List/OmniBar.js'
import {TagsShape} from '../../common/PropTypes.js'

import {
  setSearchText,
  setStatus,
  setTags,
  setUntagged,
  setTagFilterBy,
} from './returnOrderListActions.js'
import {returnOrderTagsSortedByNameSelector} from '../../data/returnOrderTags.js'
import {
  searchTextSelector,
  statusSelector,
  returnOrderTagFiltersSelector,
  returnOrderUntaggedFilterSelector,
  returnOrderTagFilterBySelector,
} from './returnOrderListSelectors.js'

export const SEARCH_TYPE = 'search'
export const STATUS_TYPE = 'status'
export const TAG_TYPE = 'tag'
export const TAG_AND_TYPE = 'tag_and'
export const TAG_ONLY_TYPE = 'tag_only'
export const TAG_NOT_TYPE = 'tag_not'
const TAG_TYPES = {
  [TAG_FILTER_OR]: TAG_TYPE,
  [TAG_FILTER_AND]: TAG_AND_TYPE,
  [TAG_FILTER_ONLY]: TAG_ONLY_TYPE,
  [TAG_FILTER_NOT]: TAG_NOT_TYPE,
}
export const UNTAGGED_TYPE = 'untagged'

export function buildValue(props) {
  const {tags, tagFilters, tagFilterBy, untaggedFilter, searchText, status} =
    props
  const list = []

  RETURN_ORDER_STATUS_OPTIONS.forEach(({value, display}) => {
    if (value !== status) {
      return
    }

    list.push({
      type: STATUS_TYPE,
      label: display,
      value: status,
    })
  })

  const tagType = TAG_TYPES[tagFilterBy]
  tags.forEach((tag) => {
    if (!tagFilters.includes(tag.name)) {
      return
    }

    list.push({
      type: tagType,
      label: tag.name,
      value: `${tagType}:${tag.id}`,
      color: tag.color,
      id: tag.id,
    })
  })

  if (untaggedFilter) {
    list.push({
      type: UNTAGGED_TYPE,
      label: 'Untagged',
      value: 'untagged',
    })
  }

  if (searchText) {
    list.push({
      type: SEARCH_TYPE,
      label: searchText,
      value: searchText,
    })
  }

  return list
}

export function processStatusFilterValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
  props,
) {
  if (!filterType.match(/^status$/i)) {
    return false
  }

  const statusMatch = RETURN_ORDER_STATUS_OPTIONS.find(({value, display}) =>
    `${value} ${display}`.match(RegExp(filterValue, 'i')),
  )

  if (statusMatch) {
    props.setStatus(statusMatch.value)

    outcome.matched = true
  }

  return true
}

export function processTagFilterValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
  props,
) {
  if (
    !(filterType.match(/^(tag)[s]?$/i) && ['', '='].includes(filterOperator))
  ) {
    return false
  }

  const {tags, tagFilters} = props

  const matchedTags = tags.filter((t) => {
    if (filterOperator === '=') {
      if (t.id === Number(filterValue)) {
        return true
      }
      if (t.name === filterValue) {
        return true
      }
    } else {
      if (`${t.name}`.match(RegExp(filterValue, 'i'))) {
        return true
      }
    }

    return false
  })

  if (matchedTags.length) {
    props.setTags([...tagFilters, ...matchedTags.map(({name}) => name)])
    props.setUntagged(false)

    outcome.matched = true
  }

  return true
}

export function processUntaggedValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
  props,
) {
  if (!filterValue.match(/^untag(ged)?$/i)) {
    return false
  }

  props.setUntagged(true)
  props.setTags([])

  outcome.matched = true

  return true
}

export function processTagFilterByValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
  props,
) {
  if (
    !(
      filterType.match(/^(tag.filter(ing)?.logic|tfl)$/i) &&
      ['', '='].includes(filterOperator)
    )
  ) {
    return false
  }

  const filterBy = filterValue.match(/^(and|all)$/i)
    ? TAG_FILTER_AND
    : filterValue.match(/^only$/i)
      ? TAG_FILTER_ONLY
      : filterValue.match(/^not$/i)
        ? TAG_FILTER_NOT
        : TAG_FILTER_OR

  props.setTagFilterBy(filterBy)

  outcome.matched = true

  return true
}

export function processSearchTextFilterValue(inputValue, outcome, props) {
  props.setSearchText(inputValue)

  outcome.matched = true

  return true
}

export function processInputValue(inputValue, props) {
  if (!inputValue) {
    return ''
  }

  let [, filterType = '', filterOperator = '', filterValue] = inputValue.match(
    /^(?:(.*)?:([=><-])?)?(.*)$/,
  )
  const outcome = {matched: false}

  filterType = filterType.trim()
  filterValue = filterValue.trim()

  processStatusFilterValue(
    filterType,
    filterOperator,
    filterValue,
    outcome,
    props,
  ) ||
    processTagFilterValue(
      filterType,
      filterOperator,
      filterValue,
      outcome,
      props,
    ) ||
    processUntaggedValue(
      filterType,
      filterOperator,
      filterValue,
      outcome,
      props,
    ) ||
    processTagFilterByValue(
      filterType,
      filterOperator,
      filterValue,
      outcome,
      props,
    ) ||
    processSearchTextFilterValue(inputValue, outcome, props)

  if (outcome.matched) {
    return ''
  }

  return inputValue
}

export function removeValue(value, props) {
  const {tagFilters} = props

  if (value.type === STATUS_TYPE) {
    props.setStatus(ALL_STATUS)
  } else if (
    [TAG_TYPE, TAG_AND_TYPE, TAG_ONLY_TYPE, TAG_NOT_TYPE].includes(value.type)
  ) {
    props.setTags(tagFilters.filter((name) => name !== value.label))
  } else if (value.type === UNTAGGED_TYPE) {
    props.setUntagged(false)
  } else if (value.type === SEARCH_TYPE) {
    props.setSearchText('')
  }
}

export function clearAll(props) {
  props.setStatus(ALL_STATUS)
  props.setTags([])
  props.setUntagged(false)
  props.setTagFilterBy(TAG_FILTER_OR)
  props.setSearchText('')
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  }
}

function ReturnOrderListOmniBar(props) {
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setValue(buildValue(props))
  }, [
    props.tags,
    props.tagFilters,
    props.searchText,
    props.status,
    props.untaggedFilter,
    props.tagFilterBy,
    inputValue,
  ])

  return (
    <OmniBar
      placeholder="Enter an RMA ID or Product SKU..."
      inputValue={inputValue}
      value={value}
      onRemoveValue={(removedValue) => removeValue(removedValue, props)}
      onClear={() => clearAll(props)}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        setInputValue(processInputValue(inputValue, props))
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}

ReturnOrderListOmniBar.propTypes = {
  tags: TagsShape.isRequired,
  tagFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  untaggedFilter: PropTypes.bool.isRequired,
  tagFilterBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  setUntagged: PropTypes.func.isRequired,
  setTagFilterBy: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    tags: returnOrderTagsSortedByNameSelector(state),
    tagFilters: returnOrderTagFiltersSelector(state),
    untaggedFilter: returnOrderUntaggedFilterSelector(state),
    tagFilterBy: returnOrderTagFilterBySelector(state),
    searchText: searchTextSelector(state),
    status: statusSelector(state),
  }
}

const mapDispatchToProps = {
  setSearchText,
  setStatus,
  setTags,
  setUntagged,
  setTagFilterBy,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderListOmniBar)
