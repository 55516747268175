import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import omit from 'lodash/omit.js'

import {progressSelector} from '../../../redux/selectors/ui/dataImport/progress.js'
import Form from '../../../common/components/Form/index.js'

class BaseEditForm extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    deleteData: PropTypes.func.isRequired,
    saveCurrent: PropTypes.func.isRequired,
    queueNextData: PropTypes.func.isRequired,
    queuePreviousData: PropTypes.func.isRequired,
    progress: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    errorMessage: PropTypes.string,
    hasDataErrors: PropTypes.bool,
    isOnFirst: PropTypes.bool,
    isOnLast: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.queueNextData = () => this.props.queueNextData()
    this.queuePreviousData = () => this.props.queuePreviousData()
  }

  render() {
    const className = classNames('wrap--edit-form', this.props.className)

    return (
      <div className="row">
        <div className="medium-12 columns">
          <div className={className}>
            <div className="form__header--edit row">
              <div className="flex--justify">
                <button
                  className="inline-icon-button middle-aligned margin-bottom-0"
                  onClick={this.queuePreviousData}
                  disabled={this.props.isOnFirst}
                >
                  <span className="icon icon-left sm" aria-hidden="true" />
                </button>
                <p className="form__counter--edit">
                  <strong>
                    {this.props.progress.reviewList.currentIndex + 1}
                  </strong>
                  <span> of </span>
                  <strong>{this.props.progress.reviewList.data.length}</strong>
                </p>
                <button
                  className="inline-icon-button middle-aligned margin-bottom-0"
                  onClick={this.queueNextData}
                  disabled={this.props.isOnLast}
                >
                  <span className="icon icon-right sm" aria-hidden="true" />
                </button>
              </div>
            </div>
            <Form errorMessage={this.props.errorMessage}>
              {this.props.children}
              <li className="form-list-item row">
                <div className="medium-12 columns margin-top-5">
                  <button
                    className="btn btn--primary btn--sm"
                    onClick={this.props.saveCurrent}
                    disabled={this.props.hasDataErrors}
                  >
                    Save Entry
                  </button>
                  <button
                    className="btn btn--secondary btn--sm"
                    onClick={this.props.deleteData}
                  >
                    Delete Entry
                  </button>
                </div>
              </li>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const dataImport = state.ui.dataImport
  const data = dataImport.data[dataImport.currentDataIndex]
  const progress = progressSelector(state)
  const isOnFirst = progress.reviewList.currentIndex === 0
  const isOnLast =
    progress.reviewList.currentIndex === progress.reviewList.data.length - 1

  return {
    progress,
    index: data.index,
    errorMessage: data.errors.server || data.errors.payload,
    hasDataErrors: !isEmpty(omit(data.errors, 'server')),
    isOnFirst,
    isOnLast,
  }
}

export default connect(mapStateToProps)(BaseEditForm)
