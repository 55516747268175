import {showCreateBatchModal} from './Modals/CreateBatchModal.js'

export default function Actions() {
  return (
    <>
      <li className="inline-block v-align-middle fs-n0 divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => showCreateBatchModal()}
        >
          Create a Batch
        </button>
      </li>
    </>
  )
}
