import {getState, useSelector} from '../../../../../store.js'
import {LabelInfoIDsShape} from '../../../../../common/PropTypes.js'
import {
  presetSelector,
  sortedPresetsSelector,
} from '../../../../../data/presets.js'
import {
  toggleShowPresets,
  setPresetID,
  applyPresetBulk,
} from '../../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../../bulkLabelConfigSelectors.js'
import Select from '../../../../../common/components/Select.js'
import UpdateOrdersButton from '../UpdateOrdersButton.js'
import Warning from './Warning.js'
import className from '../../../../../common/className.js'
import {formatMetaClassName} from '../../../Fields/useLabelProperty.js'
import {HotKeyConnect} from '../../../../../common/components/HotKeys.js'
import {HK_APPLY_PRESET_REGEX} from '../../../../../common/constants/HotKeys.js'
import {showMessageToast} from '../../../../Header/Toast/index.js'

function hotKeyApplyBulkPreset(presetID, labelInfoIDs) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset) {
    return
  }

  applyPresetBulk(labelInfoIDs, presetID)

  showMessageToast(
    `Applied preset (${preset.name}) to ${labelInfoIDs.length} orders`,
  )
}

export default function BulkPresets({labelInfoIDs}) {
  const form = useSelector(bulkLabelConfigFormSelector)
  const presets = useSelector(sortedPresetsSelector)

  return (
    <dd className="list__item meta-labelconfigform-bulk-preset">
      <HotKeyConnect
        code={HK_APPLY_PRESET_REGEX}
        func={(presetID) => hotKeyApplyBulkPreset(presetID, labelInfoIDs)}
      />
      <label htmlFor="bulk_preset">
        <input
          type="checkbox"
          id="bulk_preset"
          onChange={() => toggleShowPresets()}
          checked={form.showPresets}
        />
        <span className="margin-left-5">Presets</span>
      </label>
      {form.showPresets && (
        <div className="wrap--nested-form">
          <div className="list--shipping-options">
            <Select
              className={className`list__item--shipping-options w-100 flex-grow ${formatMetaClassName(
                'preset',
              )}`}
              onChange={(value) => setPresetID(value)}
              options={[
                {value: '', display: ''},
                ...presets.map((preset) => ({
                  value: preset.id,
                  display: preset.name,
                })),
              ]}
              value={form.presetID}
            />
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              disabled={!form.presetID}
              onClick={() => applyPresetBulk(labelInfoIDs, form.presetID)}
              loading={form.loading__presets}
            />
            <Warning labelInfoIDs={labelInfoIDs} />
          </div>
        </div>
      )}
    </dd>
  )
}

BulkPresets.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
