import PropTypes from 'prop-types'

import {getState, setForm, updateForm, removeForm} from '../../store.js'
import {MO_SINGLE_URI_COMPONENT} from '../../common/constants/MOs.js'
import handleListSelection from '../../common/handleListSelection.js'
import apiverson from '../../common/apiverson.js'
import {showGlobalError} from '../../ordoro/GlobalErrorMessage.js'
import {setMOStateAndEnsureProductsLoaded, moSelector} from '../../data/mos.js'
import {
  moDetailFormSelector,
  moDetailReferenceIDSelector,
  MO_DETAIL_FORM,
} from './moDetailSelectors.js'

export const MODetailFormShape = PropTypes.shape({
  selectedLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoading: PropTypes.bool.isRequired,
})

export function navigateToMODetailPage(referenceID) {
  window.location.hash = `#/${MO_SINGLE_URI_COMPONENT}/${encodeURIComponent(
    referenceID,
  )}`
}

export function setupMODetailForm() {
  setForm(MO_DETAIL_FORM, {
    selectedLineIDs: [],
    isLoading: true,
  })
}

export function updateMODetailForm(updates, meta) {
  updateForm(MO_DETAIL_FORM, updates, meta)
}

export function removeMODetailForm() {
  removeForm(MO_DETAIL_FORM)
}

export async function getCurrentDetailMO() {
  const referenceID = moDetailReferenceIDSelector(getState())

  try {
    if (!referenceID) {
      return
    }

    const mo = moSelector(getState(), {referenceID})

    updateMODetailForm({isLoading: !mo})

    const {json} = await apiverson.get(
      `/manufacturing_order/${encodeURIComponent(referenceID)}`,
    )

    await setMOStateAndEnsureProductsLoaded(json)
  } catch (err) {
    if (!err.response || err.response.status !== 404) {
      showGlobalError(
        {
          summary: `There was an error loading MFG Order Details for ${referenceID}.`,
          details: err.message,
        },
        err,
      )
    }
  }

  updateMODetailForm({isLoading: false})
}

export function handleMOLinesListSelection(lineID, isSelected, isShiftKey) {
  const referenceID = moDetailReferenceIDSelector(getState())
  const mo = moSelector(getState(), {referenceID})
  const form = moDetailFormSelector(getState())

  if (!referenceID || !mo || !form) {
    return
  }

  updateMODetailForm({
    selectedLineIDs: handleListSelection({
      value: lineID,
      isSelected,
      isShiftKey,
      selected: form.selectedLineIDs,
      list: mo.lines.map(({id}) => id),
    }),
  })
}
