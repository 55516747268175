import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Checkbox from '../../../../common/components/Form/Checkbox.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'

import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'

export class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onLinkChange = (value) => updatePayload({link: value})
    this.onSKUChange = (value) => updatePayload({sku: value})
    this.onSupplierSKUChange = (value) => updatePayload({supplier_sku: value})
    this.onPriceChange = (value) => updatePayload({supplier_price: value})
    this.onMinOrderQtyChange = (value) => updatePayload({min_order_qty: value})
    this.onSupplierLeadTimeChange = (value) =>
      updatePayload({supplier_lead_time: value})
    this.onIsDefaultChange = (value) => updatePayload({is_default: value})
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <Select
            value={data.payload.link}
            errorMessage={data.errors.link}
            name="link"
            label="Supplier"
            className="columns medium-12 wrap--full-select"
            selectClassName="full-width"
            onChange={this.onLinkChange}
          >
            <option value="-1"></option>
            {this.props.suppliersSortedByName.map((supplier) => (
              <option key={supplier._link} value={supplier._link}>
                {getSupplierName(supplier)}
              </option>
            ))}
          </Select>
        </Row>
        <Row>
          <TextInput
            value={data.payload.sku}
            errorMessage={data.errors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.supplier_sku || ''}
            errorMessage={data.errors.supplier_sku}
            name="supplier_sku"
            label="Supplier SKU"
            className="medium-12"
            onChange={this.onSupplierSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.supplier_price || ''}
            errorMessage={data.errors.supplier_price}
            name="supplier_price"
            label="Supplier Unit Cost"
            className="medium-6"
            onChange={this.onPriceChange}
          />
          <TextInput
            value={data.payload.min_order_qty || ''}
            errorMessage={data.errors.min_order_qty}
            name="min_order_qty"
            label="Min Order Quantity"
            className="medium-6"
            onChange={this.onMinOrderQtyChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.supplier_lead_time || ''}
            errorMessage={data.errors.supplier_lead_time}
            name="supplier_lead_time"
            label="Lead Time (Days)"
            className="medium-6"
            onChange={this.onSupplierLeadTimeChange}
          />
          <Checkbox
            checked={!!data.payload.is_default}
            errorMessage={data.errors.is_default}
            name="is_default"
            label="Default Supplier"
            className="medium-6 columns"
            onChange={this.onIsDefaultChange}
          />
        </Row>
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
  suppliersSortedByName: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    suppliersSortedByName: suppliersSortedByNameSelector(state),
  }
}

export default connect(mapStateToProps)(EditForm)
