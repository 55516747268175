import PropTypes from 'prop-types'
import {Component} from 'react'

import {OrderShape} from '../../../../../../common/PropTypes.js'
import Line from './Line.js'

export default class OrderLines extends Component {
  static propTypes = {
    order: OrderShape.isRequired,
    isExpanded: PropTypes.bool.isRequired,
  }

  render() {
    const count = this.props.isExpanded ? this.props.order.lines.length : 0
    const lines = this.props.order.lines.slice(0, count)

    return (
      <ul className="list--line">
        {lines.map((line) => (
          <Line
            key={line.link}
            orderNumber={this.props.order.order_number}
            line={line}
          />
        ))}
      </ul>
    )
  }
}
