import ModalPortal from '../../../common/components/ModalPortal.js'
import ArchiveShipperModal from '../Shippers/ArchiveShipperModal.js'
import ArchiveUserModal from '../Users/ArchiveUserModal.js'
import CreatePresetBarcodePDFModal from '../../LabelConfig/Modals/CreatePresetBarcodePDFModal.js'
import DeleteRulesModal from './DeleteRulesModal.js'
import ExportUSPSTransactionsModal from '../Shippers/ExportUSPSTransactionsModal.js'
import FedExAuthModal from '../Shippers/FedExAuthModal.js'
import ResetPasswordModal from '../Users/ResetPasswordModal.js'
import UPSRegistrationCompleteModal from '../Shippers/Forms/UPSForm/UPSRegistrationCompleteModal.js'
import UPSRegistrationModal from '../Shippers/Forms/UPSForm/UPSRegistrationModal.js'
import WarehouseAddressLabelModal from '../Warehouses/WarehouseAddressLabelModal.js'

export default function Modals() {
  return (
    <>
      <ModalPortal>
        <ExportUSPSTransactionsModal />
      </ModalPortal>
      <ArchiveShipperModal />
      <ArchiveUserModal />
      <CreatePresetBarcodePDFModal />
      <DeleteRulesModal />
      <FedExAuthModal />
      <ResetPasswordModal />
      <UPSRegistrationCompleteModal />
      <UPSRegistrationModal />
      <WarehouseAddressLabelModal />
    </>
  )
}
