import PropTypes from 'prop-types'
import classNames from 'classnames'
import BaseTextArea from '../TextArea.js'

export default function TextArea({
  name,
  id,
  className,
  errorMessage,
  rows,
  onChange,
  ...props
}) {
  const allClassName = classNames(`columns container_${name}`, className, {
    error: !!errorMessage,
  })
  rows = rows || 10

  return (
    <div className={allClassName}>
      <BaseTextArea
        id={id || `id_${name}`}
        name={name}
        onChange={(value) => onChange(value, name)}
        rows={rows}
        {...props}
      />
    </div>
  )
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.any,
}
