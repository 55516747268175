import PropTypes from 'prop-types'
import {useCallback, useEffect, useMemo, useRef} from 'react'
import {getState, updateForm, useAutoForm, useSelector} from '../../../store.js'
import {
  useZropdown,
  useZropdownElement,
} from '../../../common/components/Zropdown.js'
import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'
import FilterDropdown from '../../../common/components/FilterDropdown.js'
import {
  shipperIDsSelector,
  updateLabelConfig,
  validShippersSelector,
} from '../../../data/labelInfos/index.js'
import {SHIPPERS, shipperNameSelector} from '../../../data/shippers.js'
import {
  filterOptions,
  getNounsFromValue,
  tokenizeOptions,
} from '../../../common/tokenizeOptions.js'
import {ShipperShape} from '../../../common/PropTypes.js'
import className from '../../../common/className.js'
import Checkbox from '../../../common/components/Checkbox.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {
  Count,
  IfZero,
  IfPluralNotZero,
  IfSingle,
  Plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import useStable from '../../../common/useStable.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const SHIPPERS_FILTER__SELECT_ALL = 'SHIPPERS_FILTER__SELECT_ALL'
export const LABEL_PROPERTY_SHIPPER_IDS = 'shipper_ids'

function filterShippers(dropdown, shipperOptions, shipperOptionTokens, text) {
  try {
    if (!text) {
      updateForm(dropdown, {
        results: shipperOptions,
        hasFoundNothing: false,
      })

      return
    }

    const results = filterOptions(text, shipperOptionTokens)

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

function onShipperSelected(labelInfoID, onChange, option) {
  const shipper_ids = shipperIDsSelector(getState(), {labelInfoID})

  const shipperIDs = shipper_ids.filter((id) => option.entity.id !== id)

  if (shipperIDs.length === shipper_ids.length) {
    shipperIDs.push(option.entity.id)
  }

  updateLabelConfig(labelInfoID, {shipper_ids: shipperIDs})

  if (onChange) {
    onChange()
  }
}

function toggleAllShippers(labelInfoID, onChange, option) {
  const shipper_ids = shipperIDsSelector(getState(), {labelInfoID})
  const allShipperIDs = option.entity.map(({id}) => id)

  let shipperIDs = allShipperIDs.filter((id) => shipper_ids.includes(id))

  if (shipperIDs.length === allShipperIDs.length) {
    shipperIDs = []
  } else {
    shipperIDs = allShipperIDs
  }

  updateLabelConfig(labelInfoID, {shipper_ids: shipperIDs})

  if (onChange) {
    onChange()
  }
}

function Item({option, selected, disabled, isFocused, onSelect}) {
  const ref = useRef()

  useZropdownElement({ref, isSelected: isFocused})

  return (
    <li ref={ref} className="list__item list__item--dropdown">
      <span
        className={className`label--selectable label--selectable-pt-pb-0 flex--justify-nowrap margin-bottom-0 ${{
          'list__link--dropdown-arrow-focus': isFocused,
          disabled,
        }}`}
      >
        <label
          className="flex--justify-nowrap flex-grow flex--jc-left mw-unset align-items-center margin-bottom-0"
          htmlFor={`shipper__${option.entity.id}`}
          data-dropdown-prevent-close
        >
          <span>
            <Checkbox
              className="fs-00 v-align-middle"
              id={`shipper__${option.entity.id}`}
              checked={selected}
              onClick={() => {
                if (!disabled) {
                  onSelect(option)
                }
              }}
            />
          </span>
          <span
            className={className`label--selectable__text label__carrier-logo fs-n1 lh-sm unbold ${option.entity.vendor}`}
          >
            {option.display}
          </span>
        </label>
      </span>
    </li>
  )
}

Item.propTypes = {
  option: PropTypes.shape({
    display: PropTypes.string.isRequired,
    entity: ShipperShape.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  canEdit: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
}

function SelectAll({option, selected, indeterminate, isFocused, onSelect}) {
  const ref = useRef()

  useZropdownElement({ref, isSelected: isFocused})

  return (
    <li
      ref={ref}
      className="list__item list__item--dropdown list__item--dropdown-header list__item--dropdown-header-pkg-type"
    >
      <span
        className={className`label--selectable label--selectable-pt-pb-0 flex--justify-nowrap margin-bottom-0 ${{
          'list__link--dropdown-arrow-focus': isFocused,
        }}`}
      >
        <label
          className="flex--justify-nowrap flex-grow flex--jc-left mw-unset align-items-center  margin-bottom-0"
          htmlFor="shippers_filter_select_all"
          data-dropdown-prevent-close
        >
          <span>
            <Checkbox
              className="fs-00 v-align-middle"
              id="shippers_filter_select_all"
              checked={selected}
              indeterminate={indeterminate}
              onClick={() => onSelect(option)}
            />
          </span>
          <span className="label--selectable__text lh-sm margin-left-10">
            Select {selected && !indeterminate ? 'None' : 'All'}
          </span>
        </label>
      </span>
    </li>
  )
}

SelectAll.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    entity: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
}

export default function ShippersFilter({autoFocus}) {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const dropdown = `SHIPPERS_FILTER_${labelInfoID}`
  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
    hasChanged: false,
  })
  const localOnChange = useCallback(() => {
    updateForm(dropdown, {hasChanged: true})
  }, [])
  const validShippers = useStable(
    useSelector((state) => validShippersSelector(state, {labelInfoID})),
  )
  const validShipperOptions = useMemo(() => {
    const options = []

    for (const shipper of validShippers) {
      options.push({
        value: shipper.id,
        display: shipper.name,
        entity: shipper,
        type: SHIPPERS,
        nouns: [...getNounsFromValue(shipper.name), shipper.vendor],
      })
    }

    if (options.length > 1) {
      options.unshift({
        value: SHIPPERS_FILTER__SELECT_ALL,
        display: 'select all',
        entity: options.map(({entity}) => entity),
        type: SHIPPERS_FILTER__SELECT_ALL,
        nouns: new Set(
          options.reduce((prev, {nouns}) => {
            prev.push(...nouns)
            return prev
          }, []),
        ),
      })
    }

    return options
  }, [validShippers])
  const validShipperOptionTokens = useMemo(
    () => tokenizeOptions(validShipperOptions),
    [validShipperOptions],
  )
  const shipperIDs = useSelector((state) =>
    shipperIDsSelector(state, {labelInfoID}),
  )
  const firstShipperName = useSelector((state) =>
    shipperNameSelector(state, {shipperID: shipperIDs[0]}),
  )
  const selectedCount = useMemo(
    () => validShippers.filter(({id}) => shipperIDs.includes(id)),
    [validShippers, shipperIDs],
  ).length

  useEffect(() => {
    filterShippers(
      dropdown,
      validShipperOptions,
      validShipperOptionTokens,
      form && form.text,
    )
  }, [validShipperOptions, form && form.text])

  const buttonRef = useRef()

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onSelect: (option) => {
        if (option.type === SHIPPERS) {
          onShipperSelected(labelInfoID, localOnChange, option)
        } else {
          toggleAllShippers(labelInfoID, localOnChange, option)
        }
      },
      autoFocus,
    })

  const currentDropdown = useSelector(currentDropdownSelector)

  useEffect(() => {
    if (!currentDropdown && form.hasChanged) {
      updateForm(dropdown, {hasChanged: false})

      if (onChange) {
        onChange()
      }
    }
  }, [currentDropdown])

  useLabelProperty({
    labelProperty: LABEL_PROPERTY_SHIPPER_IDS,
  })

  return (
    <li
      className={className`list__item--shipping-options list__item--shipping-options-rs flex-grow ${formatMetaClassName(
        LABEL_PROPERTY_SHIPPER_IDS,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <label className="label--bold" htmlFor={dropdown}>
          <span>Carrier</span>
          <span className="required">*</span>
        </label>
        <div
          className={`wrap-outer--tag-dropdown-with-filter ${
            currentDropdown === dropdown ? 'open' : ''
          }`}
          data-dropdown={dropdown}
        >
          <button
            id={dropdown}
            ref={buttonRef}
            className="btn btn--dropdown btn--filter btn--filter-w-border w-100"
            type="button"
          >
            <span className="btn__text flex">
              <span className="btn__text--no-overflow txt-overflow-ellipsis">
                <span className="txt-overflow-ellipsis">
                  <span>
                    <PluralBlock array={shipperIDs}>
                      <IfZero>
                        <em>Select a carrier...</em>
                      </IfZero>
                      <IfSingle>{firstShipperName}</IfSingle>
                      <IfPluralNotZero>
                        <Count /> <Plural word="carrier" />
                      </IfPluralNotZero>
                    </PluralBlock>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <FilterDropdown
            dropdown={dropdown}
            form={form}
            onChangeInternal={onChangeInternal}
            onConfirm={onConfirm}
            onUp={onUp}
            onDown={onDown}
            placeholder="Search your carriers"
            includeSearchInput
            parentRef={buttonRef}
          >
            {form.results.map((option, index) =>
              option.type === SHIPPERS ? (
                <Item
                  key={option.value}
                  option={option}
                  selected={shipperIDs.includes(option.entity.id)}
                  isFocused={index === form.selectedIndex}
                  onSelect={onSelectInternal}
                />
              ) : (
                <SelectAll
                  key={option.value}
                  option={option}
                  selected={selectedCount > 0}
                  indeterminate={
                    option.entity.length !== selectedCount &&
                    selectedCount !== 0
                  }
                  isFocused={index === form.selectedIndex}
                  onSelect={onSelectInternal}
                />
              ),
            )}
            {form.hasFoundNothing && (
              <li className="list__item--dropdown list__item--dropdown-empty align-center">
                No carrier match your search.
              </li>
            )}
          </FilterDropdown>
        </div>
      </div>
      <EnableLabelProperty labelProperty={LABEL_PROPERTY_SHIPPER_IDS} />
    </li>
  )
}

ShippersFilter.propTypes = {
  autoFocus: PropTypes.bool,
}
