import {OrderShape} from '../../../../../../common/PropTypes.js'

import {
  getIsManuallyTracked,
  getIsMarkedAsShipped,
} from '../../../../../../data/orders.js'

import TrackingStatus from '../../Fields/TrackingStatus.js'
import TrackingLink from '../../Fields/TrackingLink.js'
import CompactShippedRateDetails from './Fields/CompactShippedRateDetails.js'

export default function ShippedSummary({order}) {
  return (
    <div>
      {!getIsManuallyTracked(order) && !getIsMarkedAsShipped(order) && (
        <CompactShippedRateDetails orderNumber={order.order_number} />
      )}
      <div className="word-break">
        <span className="fs-n1 margin-right-5">
          <TrackingLink shippingInfo={order.shipping_info} />
        </span>
        <span className="fs-n1">
          <TrackingStatus shippingInfo={order.shipping_info} />
        </span>
      </div>
    </div>
  )
}

ShippedSummary.propTypes = {
  order: OrderShape.isRequired,
}
