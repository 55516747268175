import INITIAL_STATE from '../INITIAL_STATE.js'
import {USER_VALIDITY} from '../../actions/ui/userValidity.js'

export default function userValidity(
  state = INITIAL_STATE.ui.userValidity,
  action = {},
) {
  if (action.type === USER_VALIDITY) {
    return action.payload.userValidity
  }

  return state
}
