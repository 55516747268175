import ShipperSelect from './ShipperSelect.js'

export default function AmazonSFPPickup() {
  return (
    <div>
      <h3 className="fs-01">
        <strong>Pickup Info</strong>
      </h3>
      <ShipperSelect />
      <div className="list__item alert alert--neutral fs-n0 lh-md margin-top-10 margin-bottom-10 w-75">
        <div className="margin-bottom-10">
          <strong>
            Amazon Shipping is currently a pickup-only service. Your shipment
            requires a scheduled pickup to be processed.
          </strong>
        </div>
        <div>
          <em>
            Clicking the “Schedule” button will take you to Amazon Seller
            Central to initiate the process.
          </em>
        </div>
      </div>
    </div>
  )
}
