import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../../data/labelInfos/index.js'
import ShipperOptions from '../../../../data/shipperOptions.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {useSelector} from '../../../../store.js'
import {DHL} from '../../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const noSignature = ShipperOptions.dhl.signature_options.find(
  ({display}) => display === 'No Signature Required',
)

const LABEL_PROPERTY = 'dhl__signature_service'
const VALID_SHIPPER_TYPES = [DHL]

export default function SignatureRequired() {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const signatureIsRequired = useSelector(
    (state) =>
      labelConfigSelector(state, {labelInfoID})[LABEL_PROPERTY] !==
      noSignature.value,
  )

  return (
    <ConfigCheckbox
      label="Signature Required"
      className="inline-block"
      checked={signatureIsRequired}
      onChange={(checked) => {
        const signatureService = checked ? null : noSignature.value

        updateLabelConfig(labelInfoID, {
          [LABEL_PROPERTY]: signatureService,
        })

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
