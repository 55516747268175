import PropTypes from 'prop-types'
import {useMemo} from 'react'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../store.js'
import api from '../../common/api.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  usesAppsSelector,
  inventoryAppSelector,
  dropshipAppSelector,
  shipAppSelector,
  isInTrialSelector,
  getEstimatedAppPrice,
  underContractSelector,
} from '../../data/company.js'
import {showCreditCardModal} from './CreditCardModal.js'
import {needsCreditCardSelector} from '../../data/creditCard.js'
import {showMessageToast} from '../Header/Toast/index.js'
import analytics from '../../common/analytics/index.js'
import {join} from '../../common/components/Join.js'
import {
  APP_DISPLAY,
  APP_LONG_DISPLAY,
  APP_PRICES,
  DROPSHIP_APP_ADVANCED,
  DROPSHIP_APP_PREMIUM,
  INVENTORY_APP_ADVANCED,
  INVENTORY_APP_PREMIUM,
  SHIP_APP_ADVANCED,
  SHIP_APP_ESSENTIALS,
  SHIP_APP_PREMIUM,
} from '../../common/constants/Apps.js'
import {showOldUpgradeModal} from './OldUpgradeModal.js'
import className from '../../common/className.js'
import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {plural} from '../../common/components/Plural.js'
import {showScheduleExpertModal} from './ScheduleExpertModal.js'

const MODAL_FORM = 'UPGRADE_MODAL'
export const SHIP_APP_PANEL = 'ship_app_panel'
export const DROPSHIP_APP_PANEL = 'dropship_app_panel'
export const INVENTORY_APP_PANEL = 'inventory_app_panel'

export async function showUpgradeModal({
  panel = null,
  selectedShipApp,
  selectedInventoryApp,
  selectedDropshipApp,
} = {}) {
  const usesApps = usesAppsSelector(getState())

  if (!usesApps) {
    return showOldUpgradeModal()
  }

  setForm(MODAL_FORM, {
    selectedShipApp: selectedShipApp || shipAppSelector(getState()),
    selectedInventoryApp:
      selectedInventoryApp || inventoryAppSelector(getState()),
    selectedDropshipApp: selectedDropshipApp || dropshipAppSelector(getState()),
    panel,
    serverError: null,
    isSaving: false,
  })

  await analytics.trackEvent('upgrade_modal')
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function directToCreditCardModalSelector(state) {
  const {selectedShipApp, selectedDropshipApp, selectedInventoryApp} =
    modalFormSelector(state)
  const needsCreditCard = needsCreditCardSelector(state)
  const isInTrial = isInTrialSelector(state)

  const estimatedAppPrice = getEstimatedAppPrice(
    selectedShipApp,
    selectedDropshipApp,
    selectedInventoryApp,
  )

  // if free then no
  if (estimatedAppPrice <= 0) {
    return false
  }

  // if in trial then no
  if (isInTrial) {
    return false
  }

  return needsCreditCard
}

export async function confirm() {
  const {selectedShipApp, selectedDropshipApp, selectedInventoryApp} =
    modalFormSelector(getState())
  const shipApp = shipAppSelector(getState())
  const dropshipApp = dropshipAppSelector(getState())
  const inventoryApp = inventoryAppSelector(getState())

  const directToCreditCardModal = directToCreditCardModalSelector(getState())

  const appsToAdd = [
    selectedShipApp !== shipApp && selectedShipApp,
    selectedDropshipApp !== dropshipApp && selectedDropshipApp,
    selectedInventoryApp !== inventoryApp && selectedInventoryApp,
  ].filter((app) => app)

  const appsToRemove = [
    selectedShipApp !== shipApp && shipApp,
    selectedDropshipApp !== dropshipApp && dropshipApp,
    selectedInventoryApp !== inventoryApp && inventoryApp,
  ].filter((app) => app)

  if (directToCreditCardModal) {
    showCreditCardModal()
  } else if (appsToAdd.length || appsToRemove.length) {
    try {
      updateModalForm({serverError: null, isSaving: true})

      for (const app of appsToRemove) {
        await api.delete(`/company/apps/${app}/`)
      }

      if (appsToAdd.length) {
        await api.patch('/company/apps/', {
          apps: appsToAdd.map((app) => ({app})),
        })
      }

      closeModal()

      showMessageToast(
        plural(appsToAdd)`You’ve enabled ${join(
          appsToAdd.map((app) => APP_LONG_DISPLAY[app]),
        )} app${['s']}`,
      )
    } catch (err) {
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      })
    }
  } else {
    closeModal()
  }
}

const UpgradeModalFormShape = PropTypes.shape({
  selectedShipApp: PropTypes.string,
  selectedInventoryApp: PropTypes.string,
  selectedDropshipApp: PropTypes.string,
  panel: PropTypes.string,
  serverError: PropTypes.string,
  isSaving: PropTypes.bool.isRequired,
})

function FeatureCheck({children}) {
  return (
    <dd className="list__item--pricing-summary flex">
      <span
        className="i-check fs-01 lh-lg v-align-middle margin-right-7"
        aria-hidden="true"
      ></span>
      <strong className="fs-00 lh-md">{children}</strong>
    </dd>
  )
}

FeatureCheck.propTypes = {
  children: PropTypes.node,
}

function ShipAppPanel({form}) {
  return (
    <>
      <div className="row margin-bottom-30">
        <div className="medium-8 medium-centered columns align-center">
          <div className="margin-bottom-10">
            <ButtonLink
              size="sm"
              onClick={() =>
                updateModalForm({
                  panel: null,
                })
              }
            >
              ← Back to Apps
            </ButtonLink>
          </div>
          <p className="fs-03 lh-md margin-bottom-20">
            <strong>Select a plan for the Shipping App:</strong>
          </p>
        </div>
      </div>
      <div className="row flex wrap--pricing-summary wrap--pricing-summary-shipping margin-top-25">
        <div className="medium-4 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-10 ${
              form.selectedShipApp === SHIP_APP_ESSENTIALS
                ? 'plan-selected'
                : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-shipping-essential align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ESSENTIALS
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <strong className="fs-05 lh-sm">FREE</strong>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_ESSENTIALS}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_ESSENTIALS,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp == SHIP_APP_ESSENTIALS ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Essentials'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>100 Labels/mo*</FeatureCheck>
            <FeatureCheck>
              Lowest Rates from Ordoro Discounted Carriers
            </FeatureCheck>
            <FeatureCheck>1 Sales Channel</FeatureCheck>
            <FeatureCheck>1 Ship-From Location</FeatureCheck>
            <FeatureCheck>1 User</FeatureCheck>
            <FeatureCheck>Barcode Scanning/Pack Verification</FeatureCheck>
            <FeatureCheck>Logos on Labels</FeatureCheck>
            <FeatureCheck>Quick Ship</FeatureCheck>
            <FeatureCheck>Return Labels</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_ESSENTIALS}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_ESSENTIALS,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp === SHIP_APP_ESSENTIALS ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Essentials'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
          <p className="fs-n1 lh-md align-center text--lt-grey margin-bottom-40">
            <em>
              *Surpassing 100 labels/mo will result in a temporary account lock.
              You can choose to upgrade or wait until the next billing cycle.
            </em>
          </p>
        </div>
        <div className="medium-4 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-10 ${
              form.selectedShipApp === SHIP_APP_ADVANCED ? 'plan-selected' : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-shipping-advanced align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ADVANCED
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">
                  <Quantity value={APP_PRICES[SHIP_APP_ADVANCED]} />
                </strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_ADVANCED,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp === SHIP_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>3,000 Labels/mo**</FeatureCheck>
            <FeatureCheck>
              Lowest Rates from Ordoro Discounted Carriers
            </FeatureCheck>
            <FeatureCheck>5 Sales Channels</FeatureCheck>
            <FeatureCheck>5 Ship-From Locations</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Barcode Scanning/Pack Verification</FeatureCheck>
            <FeatureCheck>Logos on Labels</FeatureCheck>
            <FeatureCheck>Quick Ship</FeatureCheck>
            <FeatureCheck>Return Labels</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Automation Rules and Presets</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>Batch Processing (beta)</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_ADVANCED,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp === SHIP_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
          <p className="fs-n1 lh-md align-center text--lt-grey margin-bottom-40">
            <em>
              **Exceeding 3,000 labels/mo will incur a $30 charge for every
              additional 1,000 labels.
            </em>
          </p>
        </div>
        <div className="medium-4 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-40 ${
              form.selectedShipApp === SHIP_APP_PREMIUM ? 'plan-selected' : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-shipping-premium align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                PREMIUM
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">
                  <Quantity value={APP_PRICES[SHIP_APP_PREMIUM]} />
                </strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_PREMIUM}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_PREMIUM,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp === SHIP_APP_PREMIUM ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Premium'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>Unlimited Monthly Labels</FeatureCheck>
            <FeatureCheck>
              Lowest Rates from Ordoro Discounted Carriers
            </FeatureCheck>
            <FeatureCheck>
              Use Your Own Carrier Accounts (starting at $30/mo)
            </FeatureCheck>
            <FeatureCheck>Unlimited Sales Channels</FeatureCheck>
            <FeatureCheck>Unlimited Ship-From Locations</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Barcode Scanning/Pack Verification</FeatureCheck>
            <FeatureCheck>Logos on Labels</FeatureCheck>
            <FeatureCheck>Quick Ship</FeatureCheck>
            <FeatureCheck>Return Labels and Management</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Automation Rules and Presets</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>Batch Processing (beta)</FeatureCheck>
            <FeatureCheck>QuickBooks Online Integration</FeatureCheck>
            <FeatureCheck>API Access</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-green"
                size="sm"
                alt
                disabled={form.selectedShipApp === SHIP_APP_PREMIUM}
                onClick={() =>
                  updateModalForm({
                    selectedShipApp: SHIP_APP_PREMIUM,
                    panel: SHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedShipApp === SHIP_APP_PREMIUM ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Premium'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
      </div>
    </>
  )
}

ShipAppPanel.propTypes = {
  form: UpgradeModalFormShape.isRequired,
}

function InventoryAppPanel({form}) {
  return (
    <>
      <div className="row margin-bottom-30">
        <div className="medium-8 medium-centered columns align-center">
          <div className="margin-bottom-10">
            <ButtonLink
              size="sm"
              onClick={() =>
                updateModalForm({
                  panel: null,
                })
              }
            >
              ← Back to Apps
            </ButtonLink>
          </div>
          <p className="fs-03 lh-md margin-bottom-20">
            <strong>Select a plan for the Inventory App:</strong>
          </p>
        </div>
      </div>
      <div className="row flex wrap--pricing-summary wrap--pricing-summary-inv margin-top-25">
        <div className="medium-4 medium-offset-2 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-10 ${
              form.selectedInventoryApp === INVENTORY_APP_ADVANCED
                ? 'plan-selected'
                : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title list__title--pricing-summary-inventory-advanced align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ADVANCED
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">
                  <Quantity value={APP_PRICES[INVENTORY_APP_ADVANCED]} />
                </strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt"
                size="sm"
                alt
                disabled={form.selectedInventoryApp === INVENTORY_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedInventoryApp: INVENTORY_APP_ADVANCED,
                    panel: INVENTORY_APP_PANEL,
                  })
                }
              >
                {form.selectedInventoryApp === INVENTORY_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>1,000 Orders/mo*</FeatureCheck>
            <FeatureCheck>Unlimited Warehouses</FeatureCheck>
            <FeatureCheck>Unlimited Sales Channels</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Kitting and Bundling</FeatureCheck>
            <FeatureCheck>Automation Rules</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>QuickBooks Online Integration</FeatureCheck>
            <FeatureCheck>Warehouse Transfers</FeatureCheck>
            <FeatureCheck>API Access</FeatureCheck>
            <FeatureCheck>Allocation</FeatureCheck>
            <FeatureCheck>Returns Management</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt"
                size="sm"
                alt
                disabled={form.selectedInventoryApp === INVENTORY_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedInventoryApp: INVENTORY_APP_ADVANCED,
                    panel: INVENTORY_APP_PANEL,
                  })
                }
              >
                {form.selectedInventoryApp === INVENTORY_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
        <div className="medium-4 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-40 ${
              form.selectedInventoryApp === INVENTORY_APP_PREMIUM
                ? 'plan-selected'
                : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-inventory-premium align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                PREMIUM
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">
                  <Quantity value={APP_PRICES[INVENTORY_APP_PREMIUM]} />
                </strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt"
                size="sm"
                alt
                disabled={form.selectedInventoryApp === INVENTORY_APP_PREMIUM}
                onClick={() =>
                  updateModalForm({
                    selectedInventoryApp: INVENTORY_APP_PREMIUM,
                    panel: INVENTORY_APP_PANEL,
                  })
                }
              >
                {form.selectedInventoryApp === INVENTORY_APP_PREMIUM ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Premium'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>1,000 Orders/mo*</FeatureCheck>
            <FeatureCheck>Unlimited Warehouses</FeatureCheck>
            <FeatureCheck>Unlimited Sales Channels</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Kitting and Bundling</FeatureCheck>
            <FeatureCheck>Automation Rules</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>QuickBooks Online Integration</FeatureCheck>
            <FeatureCheck>Warehouse Transfers</FeatureCheck>
            <FeatureCheck>API Access</FeatureCheck>
            <FeatureCheck>Allocation</FeatureCheck>
            <FeatureCheck>Returns Management</FeatureCheck>
            <FeatureCheck>Purchase Orders</FeatureCheck>
            <FeatureCheck>Bill of Materials</FeatureCheck>
            <FeatureCheck>Manufacturing Orders</FeatureCheck>
            <FeatureCheck>Custom Forecasting Report</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt"
                size="sm"
                alt
                disabled={form.selectedInventoryApp === INVENTORY_APP_PREMIUM}
                onClick={() =>
                  updateModalForm({
                    selectedInventoryApp: INVENTORY_APP_PREMIUM,
                    panel: INVENTORY_APP_PANEL,
                  })
                }
              >
                {form.selectedInventoryApp === INVENTORY_APP_PREMIUM ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Premium'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
      </div>
      <div className="row margin-bottom-30">
        <div className="medium-8 medium-centered columns align-center">
          <p className="fs-n1 lh-md text--lt-grey margin-bottom-40">
            <em>
              *Exceeding 1,000 orders/mo will incur a $100 charge for every
              additional 1,000 orders.
            </em>
          </p>
        </div>
      </div>
    </>
  )
}

InventoryAppPanel.propTypes = {
  form: UpgradeModalFormShape.isRequired,
}

function DropshipAppPanel({form}) {
  return (
    <>
      <div className="row margin-bottom-30">
        <div className="medium-8 medium-centered columns align-center">
          <div className="margin-bottom-10">
            <ButtonLink
              size="sm"
              onClick={() =>
                updateModalForm({
                  panel: null,
                })
              }
            >
              ← Back to Apps
            </ButtonLink>
          </div>
          <p className="fs-03 lh-md margin-bottom-20">
            <strong>Select a plan for the Dropshipping App:</strong>
          </p>
        </div>
      </div>
      <div className="row flex wrap--pricing-summary wrap--pricing-summary-dropshipping margin-top-25">
        <div className="medium-4 medium-offset-2 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-10 ${
              form.selectedDropshipApp === DROPSHIP_APP_ADVANCED
                ? 'plan-selected'
                : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-dropshipping-advanced align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ADVANCED
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">
                  <Quantity value={APP_PRICES[DROPSHIP_APP_ADVANCED]} />
                </strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-purp"
                size="sm"
                alt
                disabled={form.selectedDropshipApp === DROPSHIP_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedDropshipApp: DROPSHIP_APP_ADVANCED,
                    panel: DROPSHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedDropshipApp === DROPSHIP_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
            <FeatureCheck>1,000 Dropshipments/mo*</FeatureCheck>
            <FeatureCheck>Unlimited Sales Channels</FeatureCheck>
            <FeatureCheck>Unlimited Dropship Locations</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Order Routing</FeatureCheck>
            <FeatureCheck>Automation Rules</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>QuickBooks Online Integration</FeatureCheck>
            <FeatureCheck>API Access</FeatureCheck>
            <FeatureCheck>Kitting and Bundling</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-purp"
                size="sm"
                alt
                disabled={form.selectedDropshipApp === DROPSHIP_APP_ADVANCED}
                onClick={() =>
                  updateModalForm({
                    selectedDropshipApp: DROPSHIP_APP_ADVANCED,
                    panel: DROPSHIP_APP_PANEL,
                  })
                }
              >
                {form.selectedDropshipApp === DROPSHIP_APP_ADVANCED ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
          <p className="fs-n1 lh-md text--lt-grey align-center margin-bottom-40">
            <em>
              *Exceeding 1,000 dropshipments/mo will incur a $100 charge for
              every additional 1,000 dropshipments.
            </em>
          </p>
        </div>
        <div className="medium-4 columns">
          <dl
            className={`list--pricing-summary w-100 margin-bottom-40 ${
              form.selectedDropshipApp === DROPSHIP_APP_PREMIUM
                ? 'plan-selected'
                : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-dropshipping-premium align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                PREMIUM
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <strong className="fs-04 lh-sm">Contact Us</strong>
              </div>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-purp"
                size="sm"
                alt
                disabled={form.selectedDropshipApp === DROPSHIP_APP_PREMIUM}
                onClick={() => showScheduleExpertModal()}
              >
                Talk to an Expert
              </ButtonPrimary>
            </dd>
            <FeatureCheck>Custom Monthly Dropshipments</FeatureCheck>
            <FeatureCheck>Unlimited Sales Channels</FeatureCheck>
            <FeatureCheck>Unlimited Dropship Locations</FeatureCheck>
            <FeatureCheck>Unlimited Users</FeatureCheck>
            <FeatureCheck>Email Support</FeatureCheck>
            <FeatureCheck>Phone Support</FeatureCheck>
            <FeatureCheck>Order Routing</FeatureCheck>
            <FeatureCheck>Automation Rules</FeatureCheck>
            <FeatureCheck>Advanced Analytics</FeatureCheck>
            <FeatureCheck>QuickBooks Online Integration</FeatureCheck>
            <FeatureCheck>API Access</FeatureCheck>
            <FeatureCheck>Kitting and Bundling</FeatureCheck>
            <FeatureCheck>Supplier Feeds</FeatureCheck>
            <FeatureCheck>Vendor Portals</FeatureCheck>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100 btn--primary-alt-purp"
                size="sm"
                alt
                disabled={form.selectedDropshipApp === DROPSHIP_APP_PREMIUM}
                onClick={() => showScheduleExpertModal()}
              >
                Talk to an Expert
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
      </div>
    </>
  )
}

DropshipAppPanel.propTypes = {
  form: UpgradeModalFormShape,
}

function AppsPanel({form}) {
  const isInTrial = useSelector(isInTrialSelector)
  const shipApp = useSelector(shipAppSelector)
  const dropshipApp = useSelector(dropshipAppSelector)
  const inventoryApp = useSelector(inventoryAppSelector)
  const underContract = useSelector(underContractSelector)

  const appCount =
    (shipApp ? 1 : 0) + (dropshipApp ? 1 : 0) + (inventoryApp ? 1 : 0)

  const selectedAppCount =
    (form.selectedShipApp ? 1 : 0) +
    (form.selectedDropshipApp ? 1 : 0) +
    (form.selectedInventoryApp ? 1 : 0)

  const canChangeApps = !underContract

  return (
    <>
      <div className="row flex flex--reset-sm">
        <div className="medium-4 columns flex">
          <div
            className={className`wrap--app-pricing-summary wrap--app-pricing-summary-shipping ${
              !form.selectedShipApp && 'wrap--app-pricing-summary-disabled'
            } margin-top-50 margin-bottom-30`}
          >
            <div className="wrap--app-icon wrap--app-icon-pricing">
              <svg
                className="icon--app icon--app-shipping icon--app-shipping-01"
                viewBox="0 0 150 130"
              >
                <path
                  className="icon__bg-fill--app"
                  d="M102.72,16.95l27.72,48.05-27.72,48.06h-55.44l-27.72-48.06,27.72-48.05h55.44ZM116.25,65l-4.91-8.51h-53.06l5.91-10.23h41.25l-9.82-17.01h-41.25l-20.62,35.75,4.91,8.51h53.05l-5.9,10.24h-41.25l9.81,17.01h41.25l20.63-35.76Z"
                />
                <path
                  className="icon__hexagon--app"
                  d="M112.5,0l37.5,65-37.5,65.01H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                />
                <polygon
                  className="icon__name--app"
                  points="111.34 56.49 116.25 65 95.62 100.75 54.37 100.75 44.56 83.74 64.19 83.74 85.35 83.74 85.81 83.74 91.71 73.5 58.28 73.5 39.68 73.5 38.66 73.5 33.75 65 54.37 29.25 95.62 29.25 105.44 46.26 85.81 46.26 65.04 46.26 64.19 46.26 58.28 56.49 111.34 56.49"
                />
              </svg>
            </div>
            <dl className="list list--app-pricing-summary margin-bottom-0">
              <dt className="list__title fs-02 lh-md align-center margin-bottom-10">
                SHIPPING
              </dt>
              {form.selectedShipApp ? (
                <>
                  <dd className="list__item align-center margin-bottom-30">
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  </dd>
                  <dd className="list__item align-center margin-bottom-5">
                    <div className="fs-00 lh-sm margin-bottom-5">
                      Current Plan:
                    </div>
                    <div>
                      <strong className="fs-01 lh-sm">
                        {APP_DISPLAY[form.selectedShipApp]}
                      </strong>{' '}
                      <span className="fs-01 lh-sm">
                        (
                        {APP_PRICES[form.selectedShipApp] ? (
                          <>
                            <Currency
                              value={APP_PRICES[form.selectedShipApp]}
                              currencySymbol="$"
                            />
                            /mo
                          </>
                        ) : (
                          'FREE'
                        )}
                        )
                      </span>
                    </div>
                  </dd>
                  {isInTrial && (
                    <dd className="list__item--pricing-summary align-center margin-bottom-10">
                      <button
                        className="btn btn--primary btn--primary-ol btn--x-sm"
                        onClick={() =>
                          updateModalForm({
                            selectedShipApp: form.selectedShipApp,
                            panel: SHIP_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        Change Plan
                      </button>
                    </dd>
                  )}
                  {selectedAppCount > 1 && !shipApp && (
                    <dd className="list__item--pricing-summary align-center margin-top-0">
                      <button
                        className="btn btn--link fs-n0"
                        onClick={() =>
                          updateModalForm({
                            selectedShipApp: null,
                            panel: null,
                          })
                        }
                      >
                        Don’t enable app on save
                      </button>
                    </dd>
                  )}
                </>
              ) : (
                <dd className="list__item align-center margin-top-20 margin-bottom-30">
                  <div className="alert alert--app-disabled">
                    <div className="margin-bottom-15">
                      <strong className="fs-00 lh-x-md">
                        Ship orders accurately and quickly across all channels.
                      </strong>
                    </div>
                    <div>
                      <button
                        className="btn btn--primary btn--primary-ol btn--sm"
                        onClick={() =>
                          updateModalForm({
                            panel: SHIP_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        View Plans
                      </button>
                    </div>
                  </div>
                </dd>
              )}
            </dl>
          </div>
        </div>
        <div className="medium-4 columns flex">
          <div
            className={className`wrap--app-pricing-summary wrap--app-pricing-summary-inv ${
              !form.selectedInventoryApp && 'wrap--app-pricing-summary-disabled'
            } margin-top-50 margin-bottom-30`}
          >
            <div className="wrap--app-icon wrap--app-icon-pricing">
              <svg
                className="icon--app icon--app-inventory icon--app-inventory-01"
                viewBox="0 0 150 130"
              >
                <g>
                  <polygon
                    className="icon__bg-fill--app"
                    points="19.56 65 47.28 16.95 102.72 16.95 130.44 65 102.72 113.05 47.28 113.05 19.56 65"
                  />
                  <path
                    className="icon__hexagon--app"
                    d="M112.5,0l37.5,65-37.5,65H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                  />
                </g>
                <polygon
                  className="icon__name--app"
                  points="95.63 46.26 95.63 29.27 95.62 29.25 54.37 29.25 54.37 29.26 54.37 46.26 66.5 46.26 66.5 83.74 54.37 83.74 54.37 100.74 54.37 100.75 95.62 100.75 95.63 100.73 95.63 83.74 83.5 83.74 83.5 46.26 95.63 46.26"
                />
              </svg>
            </div>
            <dl className="list list--app-pricing-summary margin-bottom-0">
              <dt className="list__title fs-02 lh-md align-center margin-bottom-10">
                INVENTORY
              </dt>
              {form.selectedInventoryApp ? (
                <>
                  <dd className="list__item align-center margin-bottom-30">
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  </dd>
                  <dd className="list__item align-center margin-bottom-5">
                    <div className="fs-00 lh-sm margin-bottom-5">
                      Current Plan:
                    </div>
                    <div>
                      <strong className="fs-01 lh-sm">
                        {APP_DISPLAY[form.selectedInventoryApp]}
                      </strong>{' '}
                      <span className="fs-01 lh-sm">
                        (
                        <Currency
                          value={APP_PRICES[form.selectedInventoryApp]}
                          currencySymbol="$"
                        />
                        /mo)
                      </span>
                    </div>
                  </dd>
                  {isInTrial && (
                    <dd className="list__item--pricing-summary align-center margin-bottom-10">
                      <button
                        className="btn btn--primary btn--primary-ol btn--x-sm"
                        onClick={() =>
                          updateModalForm({
                            selectedInventoryApp: form.selectedInventoryApp,
                            panel: INVENTORY_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        Change Plan
                      </button>
                    </dd>
                  )}
                  {selectedAppCount > 1 && !inventoryApp && (
                    <dd className="list__item--pricing-summary align-center margin-top-0">
                      <button
                        className="btn btn--link fs-n0"
                        onClick={() =>
                          updateModalForm({
                            selectedInventoryApp: null,
                            panel: null,
                          })
                        }
                      >
                        Don’t enable app on save
                      </button>
                    </dd>
                  )}
                </>
              ) : (
                <dd className="list__item align-center margin-top-20 margin-bottom-30">
                  <div className="alert alert--app-disabled">
                    <div className="margin-bottom-15">
                      <strong className="fs-00 lh-x-md">
                        Precisely track your inventory in all stores and
                        warehouses.
                      </strong>
                    </div>
                    <div>
                      <button
                        className="btn btn--primary btn--primary-ol btn--sm"
                        onClick={() =>
                          updateModalForm({
                            panel: INVENTORY_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        View Plans
                      </button>
                    </div>
                  </div>
                </dd>
              )}
            </dl>
          </div>
        </div>
        <div className="medium-4 columns flex">
          <div
            className={className`wrap--app-pricing-summary wrap--app-pricing-summary-dropshipping ${
              !form.selectedDropshipApp && 'wrap--app-pricing-summary-disabled'
            } margin-top-50 margin-bottom-30`}
          >
            <div className="wrap--app-icon wrap--app-icon-pricing">
              <svg
                className="icon--app icon--app-dropshipping icon--app-dropshipping-01"
                viewBox="0 0 150 130"
              >
                <path
                  className="icon__bg-fill--app"
                  d="M102.72,16.95l27.73,48.05-27.73,48.04h-55.44l-27.72-48.04,27.72-48.05h55.44ZM96.62,65.01l-10.81-18.74h-19.93v37.48h19.93l10.81-18.73Z"
                />
                <polygon
                  className="icon__bg-fill--app"
                  points="85.81 46.26 96.62 65.01 85.81 83.74 65.88 83.74 65.88 46.26 85.81 46.26"
                />
                <path
                  className="icon__hexagon--app"
                  d="M112.5,0l37.5,65.01-37.5,64.99H37.5L0,65.01,37.5,0h75ZM130.45,65.01l-27.73-48.05h-55.44l-27.72,48.05,27.72,48.04h55.44l27.73-48.04Z"
                />
                <path
                  className="icon__name--app"
                  d="M95.34,29.25h-46.75v71.5s15.24,0,15.24,0h31.5l20.63-35.75-20.63-35.75ZM85.52,83.73h-19.93v-37.47h19.93l10.81,18.74-10.81,18.74Z"
                />
              </svg>
            </div>
            <dl className="list list--app-pricing-summary margin-bottom-0">
              <dt className="list__title fs-02 lh-md align-center margin-bottom-10">
                DROPSHIPPING
              </dt>
              {form.selectedDropshipApp ? (
                <>
                  <dd className="list__item align-center margin-bottom-30">
                    <strong className="label__callout label__callout--app fs-00">
                      App Enabled
                    </strong>
                  </dd>
                  <dd className="list__item align-center margin-bottom-5">
                    <div className="fs-00 lh-sm margin-bottom-5">
                      Current Plan:
                    </div>
                    <div>
                      <strong className="fs-01 lh-sm">
                        {APP_DISPLAY[form.selectedDropshipApp]}
                      </strong>
                      {form.selectedDropshipApp === DROPSHIP_APP_ADVANCED && (
                        <>
                          {' '}
                          <span className="fs-01 lh-sm">
                            (
                            <Currency
                              value={APP_PRICES[form.selectedDropshipApp]}
                              currencySymbol="$"
                            />
                            /mo)
                          </span>
                        </>
                      )}
                    </div>
                  </dd>
                  {isInTrial && (
                    <dd className="list__item--pricing-summary align-center margin-bottom-10">
                      <button
                        className="btn btn--primary btn--primary-ol btn--x-sm"
                        onClick={() =>
                          updateModalForm({
                            selectedDropshipApp: form.selectedDropshipApp,
                            panel: DROPSHIP_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        Change Plan
                      </button>
                    </dd>
                  )}
                  {selectedAppCount > 1 && !dropshipApp && (
                    <dd className="list__item--pricing-summary align-center margin-top-0">
                      <button
                        className="btn btn--link fs-n0"
                        onClick={() =>
                          updateModalForm({
                            selectedDropshipApp: null,
                            panel: null,
                          })
                        }
                      >
                        Don’t enable app on save
                      </button>
                    </dd>
                  )}
                </>
              ) : (
                <dd className="list__item align-center margin-top-20 margin-bottom-30">
                  <div className="alert alert--app-disabled">
                    <div className="margin-bottom-15">
                      <strong className="fs-00 lh-x-md">
                        Consolidate and automate all your dropship orders.
                      </strong>
                    </div>
                    <div>
                      <button
                        className="btn btn--primary btn--primary-ol btn--sm"
                        onClick={() =>
                          updateModalForm({
                            panel: DROPSHIP_APP_PANEL,
                          })
                        }
                        disabled={!canChangeApps}
                      >
                        View Plans
                      </button>
                    </div>
                  </div>
                </dd>
              )}
            </dl>
          </div>
        </div>
      </div>
      {(appCount > 1 || !isInTrial || underContract) && (
        <div className="row">
          <div className="medium-8 columns medium-centered">
            <p className="fs-n0 lh-sm text--md-grey align-center margin-bottom-0">
              <em>
                To remove or manage an app that’s been enabled, please reach out
                to <strong>support@ordoro.com</strong>.
              </em>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

AppsPanel.propTypes = {
  form: UpgradeModalFormShape,
  directToCreditCardModal: PropTypes.bool.isRequired,
  shouldChangeApps: PropTypes.bool.isRequired,
}

function UpgradeModal({form}) {
  const shipApp = useSelector(shipAppSelector)
  const dropshipApp = useSelector(dropshipAppSelector)
  const inventoryApp = useSelector(inventoryAppSelector)
  const directToCreditCardModal = useSelector(directToCreditCardModalSelector)

  const shouldChangeApps = useMemo(() => {
    return (
      shipApp !== form.selectedShipApp ||
      dropshipApp !== form.selectedDropshipApp ||
      inventoryApp !== form.selectedInventoryApp
    )
  }, [
    shipApp,
    form.selectedShipApp,
    dropshipApp,
    form.selectedDropshipApp,
    inventoryApp,
    form.selectedInventoryApp,
  ])

  return (
    <ConfirmModal
      title="Manage Your Apps"
      modalSize="x-lg"
      onConfirm={
        shouldChangeApps || directToCreditCardModal ? () => confirm() : null
      }
      onCancel={() => closeModal()}
      confirmText={
        directToCreditCardModal
          ? 'Add a Credit Card'
          : shouldChangeApps
            ? 'Save'
            : 'OK'
      }
      error={form.serverError}
      isSaving={form.isSaving}
      cancelText="Close"
      RightButtons={() => (
        <ButtonPrimary
          isOutlined
          size="sm"
          onClick={() => showScheduleExpertModal()}
        >
          Talk to an Expert
        </ButtonPrimary>
      )}
    >
      {!form.panel ? (
        <AppsPanel
          form={form}
          directToCreditCardModal={directToCreditCardModal}
          shouldChangeApps={shouldChangeApps}
        />
      ) : form.panel === SHIP_APP_PANEL ? (
        <ShipAppPanel form={form} />
      ) : form.panel === INVENTORY_APP_PANEL ? (
        <InventoryAppPanel form={form} />
      ) : form.panel === DROPSHIP_APP_PANEL ? (
        <DropshipAppPanel form={form} />
      ) : null}
    </ConfirmModal>
  )
}

UpgradeModal.propTypes = {
  form: UpgradeModalFormShape,
}

export default onlyIfForm(UpgradeModal, modalFormSelector)
