import {takeEvery, put, call, select} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import {closeModal} from '../../../actions/ui/index.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import {
  EDIT_INSTRUCTIONS,
  setIsSaving,
  setServerError,
} from '../../../actions/ui/modals/editInstructionsModal.js'
import {
  poIDSelector,
  instructionsSelector,
} from '../../../selectors/ui/modals/editInstructionsModal.js'
import {setPO} from '../../../../data/pos.js'

export function* editInstructionsModalWorker() {
  try {
    yield put(setServerError(null))
    yield put(setIsSaving(true))

    const poID = yield select(poIDSelector)
    const instructions = yield select(instructionsSelector)
    const params = {instructions}

    const {json} = yield call(
      api.put,
      `/purchase_order/${encodeURIComponent(poID)}`,
      params,
    )

    yield call(setPO, json)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `Edited instructions for purchase order ${poID}`,
    )
  } catch (err) {
    yield put(
      setServerError(`Error editing instructions for PO: ${err.message}`),
    )
  } finally {
    yield put(setIsSaving(false))
  }
}

export default function* editInstructionsModal() {
  yield takeEvery(EDIT_INSTRUCTIONS, editInstructionsModalWorker)
}
