import PropTypes from 'prop-types'

export default function Dimensions({length, height, width, blankText}) {
  blankText = blankText || 'Not configured'

  return (
    <>
      {!!length && length}
      {!!(length && (width || height)) && ' x '}
      {!!width && width}
      {!!(width && height) && ' x '}
      {!!height && height}
      {length || width || height ? ' in' : <em>{blankText}</em>}
    </>
  )
}

Dimensions.propTypes = {
  length: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  blankText: PropTypes.string,
}
