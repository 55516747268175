import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import BaseConfirmModal from './BaseConfirmModal.js'
import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  confirm,
} from '../../../redux/actions/ui/modals/confirmDeallocateModal.js'
import {confirmDeallocateOrdersDeallocatableOrderCountSelector} from '../../../redux/selectors/ui/modals/confirmDeallocateModal.js'

class ConfirmDeallocateModal extends Component {
  static propTypes = {
    deallocatableOrderCount: PropTypes.number.isRequired,
    orderCount: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  render() {
    const {deallocatableOrderCount, orderCount, isSaving} = this.props

    return (
      <BaseConfirmModal
        title={`Deallocate ${orderCount > 1 ? 'Orders' : 'Order'}`}
        modalName={MODAL_NAME}
        isSaving={isSaving}
        onConfirm={this.props.confirm}
        onCancel={() => this.props.closeModal()}
      >
        <p className="fs-01 lh-md margin-bottom-0">
          Are you sure you want to deallocate stock for{' '}
          <strong>
            {deallocatableOrderCount}{' '}
            {deallocatableOrderCount > 1
              ? `of the selected ${orderCount} orders`
              : 'order'}
          </strong>
          ?
        </p>
      </BaseConfirmModal>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  orderCount: (state) => state.ui.modals.confirmDeallocateModal.orders.length,
  isSaving: (state) => state.ui.modals.confirmDeallocateModal.isSaving,
  deallocatableOrderCount:
    confirmDeallocateOrdersDeallocatableOrderCountSelector,
})

const mapDispatchToProps = {
  confirm,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeallocateModal)
