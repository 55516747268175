import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {productsSelector} from '../../data/products.js'
import {
  analyticsFormSelector,
  productFiltersSelector,
} from './analyticsSelectors.js'

export const topSellersSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_item_financials__top_sellers'),
  (state) => get(analyticsFormSelector(state), 'order_item_financials__all'),
  (topSellersData, allData) => {
    if (!topSellersData || !allData) {
      return null
    }

    return {
      buckets: topSellersData.map((bucket) => ({
        name: bucket.product_name,
        sku: bucket.sku,
        number_of_orders: bucket.total_orders,
        number_of_units_sold: bucket.total_products_purchased,
        total_sales: bucket.total_product_sales,
        total_product_discounts: bucket.total_product_discounts,
        total_cost: bucket.total_product_costs,
        total_profit:
          bucket.total_product_sales -
          bucket.total_product_costs -
          bucket.total_product_discounts,
      })),
      totalUnits: get(allData, [0, 'total_products_purchased'], 0),
      hitTotal: topSellersData.length,
    }
  },
)

export const productSearchSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_item_financials__product_search'),
  productFiltersSelector,
  productsSelector,
  (topSellersData, skus, products) => {
    if (!topSellersData) {
      return null
    }

    return {
      buckets: skus.map((sku) => {
        const bucket = topSellersData.find((bucket) => bucket.sku === sku) || {
          product_name: products[sku] ? products[sku].name : '-',
          total_orders: 0,
          total_products_purchased: 0,
          total_product_sales: 0,
          total_product_discounts: 0,
          total_product_costs: 0,
        }

        return {
          name: bucket.product_name,
          sku,
          number_of_orders: bucket.total_orders,
          number_of_units_sold: bucket.total_products_purchased,
          total_sales: bucket.total_product_sales,
          total_product_discounts: bucket.total_product_discounts,
          total_cost: bucket.total_product_costs,
          total_profit:
            bucket.total_product_sales -
            bucket.total_product_costs -
            bucket.total_product_discounts,
        }
      }),
    }
  },
)

export function productExportToCSVParamsSelector(state, {type, date}) {
  if (type === 'topSellers') {
    return {
      headers: [
        'name',
        'sku',
        'number_of_orders',
        'number_of_units_sold',
        'total_sales$',
        'total_product_discounts$',
        'total_cost$',
        'total_profit$',
      ],
      data: topSellersSelector(state).buckets.map((bucket) => ({
        name: bucket.name,
        sku: bucket.sku,
        number_of_orders: bucket.number_of_orders,
        number_of_units_sold: bucket.number_of_units_sold,
        total_sales$: bucket.total_sales,
        total_product_discounts$: bucket.total_product_discounts,
        total_cost$: bucket.total_cost,
        total_profit$: bucket.total_profit,
      })),
      fileName: `${date}_Highest_Selling_Products.csv`,
      toastMessage: 'Highest Selling Products CSV was exported',
    }
  } else if (type === 'productSearch') {
    return {
      headers: [
        'name',
        'sku',
        'number_of_orders',
        'number_of_units_sold',
        'total_sales$',
        'total_product_discounts$',
        'total_cost$',
        'total_profit$',
      ],
      data: productSearchSelector(state).buckets.map((bucket) => ({
        name: bucket.name,
        sku: bucket.sku,
        number_of_orders: bucket.number_of_orders,
        number_of_units_sold: bucket.number_of_units_sold,
        total_sales$: bucket.total_sales,
        total_product_discounts$: bucket.total_product_discounts,
        total_cost$: bucket.total_cost,
        total_profit$: bucket.total_profit,
      })),
      fileName: `${date}_Product_Performance.csv`,
      toastMessage: 'Product Performance CSV was exported',
    }
  }

  return null
}
