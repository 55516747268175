import loadScript from '../loadScript.js'
import {
  GOOGLE_TAG_MANAGER_CONTAINER_ID,
  IS_PROD_ENV,
} from '../constants/index.js'

let dataLayer = null

export default {
  setup() {
    if (!dataLayer || !IS_PROD_ENV) {
      return
    }

    window.googleDataLayer = dataLayer

    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })

    return loadScript(
      `https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_CONTAINER_ID}&l=googleDataLayer`,
    )
  },

  identify(params) {
    if (!IS_PROD_ENV) {
      return
    }

    if (!dataLayer) {
      dataLayer = [params]

      this.setup()
    } else {
      dataLayer.push(params)
    }
  },

  track(params) {
    if (!dataLayer) {
      return
    }

    dataLayer.push(params)
  },
}
