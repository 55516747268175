import {setForm, updateForm, removeForm} from '../forms.js'

export const BASE = 'ordoro/ui/settings/apiKeys'
export const API_KEYS_FORM = `${BASE}/API_KEYS_FORM`
export const SAVE_API_KEY = `${BASE}/SAVE_API_KEY`
export const ARCHIVE_API_KEY = `${BASE}/ARCHIVE_API_KEY`

export function setAPIKeyForm(apiKeyID, form) {
  return setForm(`${API_KEYS_FORM}/${apiKeyID}`, form)
}

export function updateAPIKeyForm(apiKeyID, update) {
  return updateForm(`${API_KEYS_FORM}/${apiKeyID}`, update)
}

export function removeAPIKeyForm(apiKeyID) {
  return removeForm(`${API_KEYS_FORM}/${apiKeyID}`)
}

export function saveAPIKey(apiKeyID) {
  return {
    type: SAVE_API_KEY,
    payload: {apiKeyID},
  }
}

export function archiveAPIKey(apiKeyID) {
  return {
    type: ARCHIVE_API_KEY,
    payload: {apiKeyID},
  }
}
