import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import get from 'lodash/get.js'

import {NO_WAREHOUSE_SELECTED} from '../../../../common/constants/CreateInventoryTransferModal.js'

import {isNumeric} from '../../../../common/utils.js'
import {
  CREATE_INVENTORY_TRANSFER_MODAL,
  formatCreateInventoryTransferModalRowFormID,
} from './createInventoryTransferModalActions.js'
import {
  productSelector,
  productWarehouseSelector,
} from '../../../../data/products.js'
import {formsSelector} from '../../../../redux/selectors/ui/forms.js'

export const createInventoryTransferModalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[CREATE_INVENTORY_TRANSFER_MODAL],
)

export function createInventoryTransferModalRowFormSelector(state, {sku}) {
  const forms = formsSelector(state)

  return forms[formatCreateInventoryTransferModalRowFormID(sku)]
}

export function rowsSelector(state) {
  const form = createInventoryTransferModalFormSelector(state)

  if (!form) {
    return []
  }

  return form.skus.map((sku) =>
    createInventoryTransferModalRowFormSelector(state, {sku}),
  )
}

function getRowErrors(state, row, sku) {
  if (!row) {
    return {}
  }

  const errors = {}
  const product = productSelector(state, {sku})

  if (!isNumeric(row.quantity) || row.quantity <= 0) {
    errors.quantity = 'Enter a positive number'
  }

  if (product && product.archive_date !== null) {
    errors.product = 'This is an archived product'
  }

  return errors
}

function getRowWarnings(state, row, sku) {
  if (!row) {
    return {}
  }

  const warnings = {}
  const sendingWarehouseID = sendingWarehouseIDSelector(state)

  const productWarehouseInventory = productWarehouseSelector(state, {
    sku,
    warehouseID: sendingWarehouseID,
  })

  if (
    isNumeric(row.quantity) &&
    productWarehouseInventory &&
    row.quantity > productWarehouseInventory.available
  ) {
    warnings.quantity = 'This will result in negative warehouse inventory'
  }

  return warnings
}

export const sendingWarehouseIDSelector = createSelector(
  createInventoryTransferModalFormSelector,
  (form) => get(form, 'sendingWarehouseID', NO_WAREHOUSE_SELECTED),
)

export const receivingWarehouseIDSelector = createSelector(
  createInventoryTransferModalFormSelector,
  (form) => get(form, 'receivingWarehouseID', NO_WAREHOUSE_SELECTED),
)

export const warehouseSelectionIsValidSelector = createSelector(
  sendingWarehouseIDSelector,
  receivingWarehouseIDSelector,
  (sendingWarehouseID, receivingWarehouseID) => {
    return (
      sendingWarehouseID !== NO_WAREHOUSE_SELECTED &&
      receivingWarehouseID !== NO_WAREHOUSE_SELECTED &&
      sendingWarehouseID !== receivingWarehouseID
    )
  },
)

export function errorsSelector(state, {sku}) {
  const row = createInventoryTransferModalRowFormSelector(state, {sku})

  return getRowErrors(state, row, sku)
}

export function warningsSelector(state, {sku}) {
  const row = createInventoryTransferModalRowFormSelector(state, {sku})

  return getRowWarnings(state, row, sku)
}

export function allRowErrorsSelector(state) {
  const form = createInventoryTransferModalFormSelector(state)

  if (!form) {
    return null
  }

  return form.skus.map((sku) => errorsSelector(state, {sku}))
}

export const hasErrorsSelector = createSelector(
  warehouseSelectionIsValidSelector,
  rowsSelector,
  allRowErrorsSelector,
  (warehouseSelectionIsValid, rows, allRowErrors) =>
    !warehouseSelectionIsValid ||
    rows.length < 1 ||
    allRowErrors.reduce(
      (prev, rowErrors) => prev || !isEmpty(rowErrors),
      false,
    ),
)

export function inventoryTransferParamsSelector(state) {
  const form = createInventoryTransferModalFormSelector(state)

  const params = {
    sending_warehouse_id: get(form, 'sendingWarehouseID'),
    receiving_warehouse_id: get(form, 'receivingWarehouseID'),
    transfer_notes: get(form, 'transfer_notes'),
    lines: form.skus.map((sku) => {
      const row = createInventoryTransferModalRowFormSelector(state, {sku})

      return {
        sku,
        quantity: get(row, 'quantity'),
        line_notes: get(row, 'line_notes'),
      }
    }),
  }

  return params
}
