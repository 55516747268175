import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isAfter from 'date-fns/isAfter'
import sub from 'date-fns/sub'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'
import {createSelector} from 'reselect'

import {formSelector, getState, updateForm} from '../../store.js'
import {getResourceIntoObject} from '../AnalyticsPage/analyticsActions.js'
import {companySelector, useAnalyticsSelector} from '../../data/company.js'
import uranus from '../../common/uranus.js'
import {currentDateSelector} from '../CurrentDateListener.js'
import {getRealDate, getTimeZone} from '../../common/date.js'
import {getTotalRevenue} from '../AnalyticsPage/financialSelectors.js'
import {SHIPPER_NAMES_BY_TYPE} from '../../data/shipperOptions.js'
import {ensureProductsLoaded, productsSelector} from '../../data/products.js'

export const QUICK_ANALYTICS = 'QUICK_ANALYTICS'

export function setQuickAnalyticsForm() {
  const companyMightBeTooNew = companyMightBeTooNewSelector(getState())

  return {
    formName: QUICK_ANALYTICS,
    initialForm: {
      dateRange: '7 days',
      isLoading: true,
      serverError: null,
      analyticsReady: !companyMightBeTooNew,
      data: {},
      lastSuccessfulDataSync: null,
    },
  }
}

export function updateQuickAnalyticsForm(updates, meta) {
  return updateForm(QUICK_ANALYTICS, updates, meta)
}

export function quickAnalyticsFormSelector(state) {
  return formSelector(state, {formName: QUICK_ANALYTICS})
}

export function companyMightBeTooNewSelector(state) {
  const {created} = companySelector(state)

  return isAfter(new Date(created), sub(new Date(), {days: 1}))
}

export const quickAnalyticsParamsSelector = createSelector(
  quickAnalyticsFormSelector,
  currentDateSelector,
  (form, today) => {
    today = today || getRealDate()

    let [amount, type] = form ? form.dateRange.split(' ') : '7 days'
    amount = Number(amount)

    return {
      start_date: sub(startOfDay(today), {[type]: amount}),
      end_date: endOfDay(today),
      timezone: getTimeZone(),
    }
  },
)

export const analyticsLinkParamsSelector = createSelector(
  quickAnalyticsParamsSelector,
  ({start_date, end_date}) => {
    return {
      startDate: start_date.toISOString(),
      endDate: end_date.toISOString(),
    }
  },
)

export const revenueDataSelector = createSelector(
  (state) =>
    get(
      quickAnalyticsFormSelector(state),
      'data.order_financials__order_placed_date',
    ),
  (data) => {
    if (!data) {
      return null
    }

    const lines = ['Total Revenue']

    const revenueOverTime = {
      totals: lines.reduce((totals, title) => {
        totals[title] = 0
        return totals
      }, {}),
      lines,
    }

    revenueOverTime.buckets = data.map((bucket) => {
      const date = parse(bucket.order_placed_date, 'yyyy-MM-dd', new Date())

      const newBucket = {
        key_as_string: bucket.order_placed_date,
        key: +date,
        doc_count: bucket.total_orders,
      }

      newBucket['Total Revenue'] = getTotalRevenue(bucket)

      lines.forEach((line) => {
        revenueOverTime.totals[line] += newBucket[line]
      })

      return newBucket
    })

    revenueOverTime.orderCount = data.reduce(
      (prev, {total_orders}) => prev + total_orders,
      0,
    )

    revenueOverTime.averageOrderRevenue =
      revenueOverTime.totals['Total Revenue'] /
      (revenueOverTime.orderCount || 1)

    return revenueOverTime
  },
)

export const shippingDataSelector = createSelector(
  (state) =>
    get(quickAnalyticsFormSelector(state), 'data.order_financials__shipping'),
  (data) => {
    if (!data) {
      return null
    }

    const lines = ['Shipped Orders', 'Total Shipping Cost']

    const revenueOverTime = {
      totals: lines.reduce((totals, title) => {
        totals[title] = 0
        return totals
      }, {}),
      lines,
    }

    revenueOverTime.buckets = data.reduce((prev, bucket) => {
      if (!bucket.shipped_date) {
        return prev
      }

      const date = parse(bucket.shipped_date, 'yyyy-MM-dd', new Date())

      const newBucket = {
        key_as_string: bucket.shipped_date,
        key: +date,
        doc_count: bucket.total_orders,
      }

      newBucket['Shipped Orders'] = bucket.total_orders
      newBucket['Total Shipping Cost'] = bucket.total_label_costs

      lines.forEach((line) => {
        revenueOverTime.totals[line] += newBucket[line]
      })

      prev.push(newBucket)

      return prev
    }, [])

    return revenueOverTime
  },
)

export const shippingCostsByCarrierSelector = createSelector(
  (state) =>
    get(
      quickAnalyticsFormSelector(state),
      'data.order_financials__shipping_vendor',
    ),
  (data) => {
    if (!data) {
      return null
    }

    const shippingCostsByCarrier = data.reduce((prev, bucket) => {
      const shipperType = bucket.shipping_vendor

      if (!shipperType) {
        return prev
      }

      const name = SHIPPER_NAMES_BY_TYPE[shipperType] || shipperType
      const value = bucket.total_label_costs
      const count = bucket.total_orders

      prev[name] = prev[name]
        ? {
            name,
            value: prev[name].value + value,
            count: prev[name].count + count,
          }
        : {
            name,
            value,
            count,
          }

      prev[name].average = prev[name].value / (prev[name].count || 1)

      return prev
    }, {})

    return {
      buckets: sortBy(shippingCostsByCarrier, 'value').reverse(),
    }
  },
)

export const shippingCountsByCarrierSelector = createSelector(
  shippingCostsByCarrierSelector,
  (shippingCostsByCarrier) => {
    if (!shippingCostsByCarrier) {
      return null
    }

    return {
      buckets: sortBy(
        shippingCostsByCarrier.buckets.map(({name, count}) => ({
          name,
          value: count,
        })),
        'value',
      ).reverse(),
    }
  },
)

export const topProductSelector = createSelector(
  (state) =>
    get(
      quickAnalyticsFormSelector(state),
      'data.order_item_financials__top_seller',
    ),
  productsSelector,
  (data, products) => {
    if (!data || !products) {
      return null
    }

    const topProducts = data.reduce((prev, bucket) => {
      const product = products[bucket.sku]

      if (!product) {
        return prev
      }

      prev.push({
        ...bucket,
        product,
      })

      return prev
    }, [])

    return topProducts.length ? topProducts[0] : null
  },
)

let refreshQuickAnalyticsToken
export async function refreshQuickAnalytics() {
  const form = quickAnalyticsFormSelector(getState())

  if (!form) {
    return
  }

  if (!useAnalyticsSelector(getState())) {
    updateQuickAnalyticsForm({
      data: {},
      analyticsReady: false,
      isLoading: false,
    })

    return
  }

  const token = (refreshQuickAnalyticsToken = {})
  const {start_date, end_date, timezone} =
    quickAnalyticsParamsSelector(getState())
  const companyMightBeTooNew = companyMightBeTooNewSelector(getState())

  updateQuickAnalyticsForm({
    isLoading: true,
  })

  try {
    const params = {
      start_date: format(start_date, 'yyyy-MM-dd'),
      end_date: format(end_date, 'yyyy-MM-dd'),
      timezone,
    }

    const {json} = await uranus.get('/meta')

    updateQuickAnalyticsForm({
      lastSuccessfulDataSync: json.last_successful_data_sync,
    })

    const data = {}
    await Promise.all([
      getResourceIntoObject(
        data,
        'order_financials__order_placed_date',
        '/v2/order_financials',
        {...params, group_by: ['order_placed_date']},
      ),
      getResourceIntoObject(
        data,
        'order_financials__shipping',
        '/v2/order_financials',
        {
          ...params,
          group_by: ['shipped_date'],
        },
      ),
      getResourceIntoObject(
        data,
        'order_financials__shipping_vendor',
        '/v2/order_financials',
        {
          ...params,
          group_by: ['shipping_vendor', 'shipping_method'],
          order_by: ['-total_label_costs'],
        },
      ),
      getResourceIntoObject(
        data,
        'order_item_financials__top_seller',
        '/v2/order_item_financials',
        {
          ...params,
          group_by: ['sku', 'product_name'],
          order_by: ['-total_product_sales'],
          limit: 1,
        },
      ),
    ])

    if (token !== refreshQuickAnalyticsToken) {
      return
    }

    const analyticsReady = !(
      !form.analyticsReady &&
      !Object.values(data).reduce((prev, d) => prev || d.length > 0, false) &&
      companyMightBeTooNew
    )

    if (!analyticsReady) {
      setTimeout(() => refreshQuickAnalytics(), 60 * 1000)
    }

    await ensureProductsLoaded(
      (data.order_item_financials__top_seller || []).map(({sku}) => sku),
    )

    updateQuickAnalyticsForm({
      data,
      analyticsReady,
      isLoading: false,
    })
  } catch (err) {
    updateQuickAnalyticsForm({
      serverError: err.message || err.error_message,
      isLoading: false,
    })
  }
}
