import apiverson from '../../../../../common/apiverson.js'
import baseStrategy from './baseStrategy.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

export default class bomStrategy extends baseStrategy {
  static title = 'Add Orders To Batch'
  static requiredColumns = ['reference_id', 'order_number']

  static getExampleRows() {
    return async () => {
      const {json: data} = await apiverson.get('/order', {
        limit: 20,
        in_batch: true,
      })
      const rows = [['Order Number *Req', 'Batch ID *Req']]

      for (const order of data.order) {
        rows.push([order.order_number, order.batch_reference_id])
      }

      return rows
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      order_number: find(/order/i),
      reference_id: find(/batch|reference/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return () => {
      const dataByID = {}

      for (let i = 0; i < rows.length; i++) {
        const data = this.transformRowToData(rows[i], columnsToDataMap)
        const reference_id = data.payload.reference_id
        const order_number = data.payload.order_number

        dataByID[reference_id] = dataByID[reference_id] || new Set()

        dataByID[reference_id].add(order_number)
      }

      return Object.entries(dataByID).map(
        ([reference_id, order_numbers], index) => {
          const data = {
            ...this.getNewData(),
            index,
            payload: {
              reference_id,
              order_numbers: [...order_numbers].filter((v) => v),
            },
          }

          data.errors = this.determineDataErrors(data)

          return data
        },
      )
    }
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()

    data.payload = {
      order_number: row[columnsByName.order_number],
      reference_id: row[columnsByName.reference_id],
    }

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    if (payload.order_numbers.length === 0) {
      errors.order_numbers = 'Add at least one order number'
    } else {
      delete errors.order_numbers
    }

    this.required(payload, errors, 'reference_id', 'Batch ID is required')

    return errors
  }

  static save(data) {
    return () => {
      return apiverson.put(
        `/batch/${encodeURIComponent(data.payload.reference_id)}/orders`,
        {
          order_numbers: data.payload.order_numbers,
        },
      )
    }
  }
}
