import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import api from '../../../common/api.js'
import {showMessageToast} from '../../Header/Toast/index.js'

export const MODAL_FORM = 'RESET_PASSWORD_MODAL'

export function showResetPasswordModal(email) {
  setForm(MODAL_FORM, {
    email,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function sendPasswordResetRequest() {
  try {
    const {email} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await api.post('/password_reset/', {email})

    closeModal()

    showMessageToast(`Sent password reset email to ${email}`)
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ResetPasswordModal({form}) {
  return (
    <ConfirmModal
      title="Send Password Reset Request"
      modalSize="sm"
      onConfirm={() => sendPasswordResetRequest()}
      onCancel={() => closeModal()}
      confirmText="Send"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      preventInnerScroll
    >
      Do you want to send a password reset email to{' '}
      <strong>{form.email}</strong>?
    </ConfirmModal>
  )
}

ResetPasswordModal.propTypes = {
  form: PropTypes.shape({
    email: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ResetPasswordModal, modalFormSelector)
