import {
  labelConfigSelector,
  shipperVendorConfigPropertySelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {FEDEX} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {useSelector} from '../../../store.js'
import ConfigButton from './ConfigButton.js'
import {showHoldAtLocationModal} from '../Modals/HoldAtLocationModal.js'
import {formatNumber} from '../../../common/components/PrettyNumber.js'

const LABEL_PROPERTY = 'hold_at_location'
const VALID_SHIPPER_TYPES = [FEDEX]

export function holdAtLocationIDPropertyFunc(shipperType) {
  return `${shipperType}__hold_at_location_id`
}

export function holdAtLocationOptionsPropertyFunc(shipperType) {
  return `${shipperType}__hold_at_location_options`
}

export function holdAtLocationDisplay(location) {
  if (!location) {
    return ''
  }

  return `${location.address.company} (${formatNumber(
    location.distance.value,
    1,
  )} ${location.distance.units})`
}

export default function HoldAtLocation() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()

  // temp_use_fedex_auth
  const [hasFedExNewCred] = useSelector((state) =>
    shipperVendorConfigPropertySelector(state, {
      labelInfoID,
      shipperType,
      property: 'child_key',
    }),
  )

  const labelProperty = hasFedExNewCred
    ? holdAtLocationIDPropertyFunc(shipperType)
    : LABEL_PROPERTY

  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const locationID = labelConfig[labelProperty]
  const options =
    labelConfig[holdAtLocationOptionsPropertyFunc(shipperType)] || []
  const selectedOption = options.find(({id}) => locationID === id)

  return hasFedExNewCred ? (
    <ConfigButton
      label="Hold at Location"
      onClick={showHoldAtLocationModal}
      labelProperty={labelProperty}
      validShipperTypes={VALID_SHIPPER_TYPES}
    >
      {selectedOption ? holdAtLocationDisplay(selectedOption) : 'Edit'}
    </ConfigButton>
  ) : (
    <ConfigCheckbox
      label="Hold at Location"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
