import {useEffect} from 'react'

import {
  updateLabelConfig,
  labelConfigSelector,
  referenceNumberErrorMessageSelector,
  labelShippersSelector,
} from '../../../../data/labelInfos/index.js'
import {getState, useSelector} from '../../../../store.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import ConfigTextInput from '../ConfigTextInput.js'
import {LABEL_PROPERTY_IS_MAIL_INNOVATIONS} from './MailInnovations.js'
import {LABEL_PROPERTY_SHIPPER_ID} from '../ShipperSelect/index.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'cost_center'
const VALID_SHIPPER_TYPES = [UPS]

function labelPropertiesFunc(shipperType, labelInfoID) {
  const {is_mail_innovations} = labelConfigSelector(getState(), {labelInfoID})

  return is_mail_innovations && VALID_SHIPPER_TYPES.includes(shipperType)
    ? [LABEL_PROPERTY]
    : []
}

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_IS_MAIL_INNOVATIONS]
}

function defaultCostCenterSelector(state, {labelInfoID}) {
  const shippers = labelShippersSelector(state, {labelInfoID})

  return shippers.reduce((prev, {vendor, vendor_config}) => {
    if (VALID_SHIPPER_TYPES.includes(vendor) && !prev) {
      prev = vendor_config.cost_center
    }

    return prev
  }, null)
}

const VALID_LABEL_PROPERTIES = [LABEL_PROPERTY]

export default function CostCenter() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const defaultCostCenter = useSelector((state) =>
    defaultCostCenterSelector(state, {labelInfoID}),
  )
  const error = useSelector((state) =>
    referenceNumberErrorMessageSelector(state, {labelInfoID, shipperType}),
  )

  const cost_center = labelConfig.cost_center
  const is_mail_innovations = labelConfig.is_mail_innovations || false

  useEffect(() => {
    if (is_mail_innovations && defaultCostCenter && cost_center === undefined) {
      updateLabelConfig(labelInfoID, {
        cost_center: defaultCostCenter,
      })

      onChange()
    }
  }, [is_mail_innovations, defaultCostCenter, cost_center])

  return (
    <ConfigTextInput
      label="Mail Innovations Cost Center"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      errorMessage={error}
      hide={!is_mail_innovations}
      labelPropertiesFunc={labelPropertiesFunc}
      dependantPropertiesFunc={dependantPropertiesFunc}
      validLabelProperties={VALID_LABEL_PROPERTIES}
    />
  )
}
