import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty.js'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {setProduct, saveProduct, nameSelector} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshProductList} from '../productListFunctions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const SAVE_PRODUCT_COST = 'SAVE_PRODUCT_COST'
export const PRODUCT_COST_MODAL = 'PRODUCT_COST_MODAL'

export function showProductCostModal(sku, cost) {
  return setForm(PRODUCT_COST_MODAL, {
    sku,
    cost: String(cost) || '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PRODUCT_COST_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PRODUCT_COST_MODAL)
}

export function saveProductCost() {
  return {
    type: SAVE_PRODUCT_COST,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCT_COST_MODAL],
)

export const errorsSelector = createSelector(modalFormSelector, ({cost}) => {
  const errors = {}

  if (!isPresent(cost)) {
    errors.cost = 'Cost is required'
  } else if (!isPositiveNumeric(cost)) {
    errors.cost = 'Cost must be a positive number'
  }

  return errors
})

export function* saveProductCostWorker() {
  try {
    const {sku, cost} = yield select(modalFormSelector)
    const params = {
      cost: Number(cost),
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const product = yield call(saveProduct, sku, params)

    yield call(setProduct, product)

    yield put(closeModal())

    yield call(showMessageToast, 'Saved product cost')

    yield call(refreshProductList)
  } catch (err) {
    yield put(updateModalForm({serverError: err.message || err.error_message}))
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function ProductCostModal({form, errors, productName, ...props}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Edit Weighted Avg Unit Cost"
      modalSize="sm"
      onConfirm={() => props.saveProductCost()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <div className="fs-01">
        <strong>{productName}</strong>
      </div>
      <div className="fs-00 margin-bottom-15">
        <span>SKU:</span> {form.sku}
      </div>
      <CurrencyInput
        className="biggie margin-bottom-0"
        width="sm"
        value={form.cost}
        onChange={(cost) =>
          props.updateForm(PRODUCT_COST_MODAL, {cost: `${cost}`})
        }
        errorMessage={errors.cost}
        autoFocus
      />
    </ConfirmModal>
  )
}

ProductCostModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    cost: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  productName: PropTypes.string.isRequired,
  saveProductCost: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    errors: errorsSelector(state),
    productName: nameSelector(state, {sku: form.sku}),
  }
}

const mapDispatchToProps = {
  saveProductCost,
  closeModal,
  updateForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ProductCostModal),
  modalFormSelector,
)
