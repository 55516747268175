import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'
import kebabCase from 'lodash/kebabCase.js'

import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'
import ButtonPrimary from './ButtonPrimary.js'

function ButtonPrimaryDropdown({
  title,
  children,
  dropdownID,
  currentDropdown,
  className,
  isLoading,
  onClick,
}) {
  return (
    <div
      className={classNames(
        'wrap--dropdown wrap--btn-group',
        `meta-buttonprimarydropdown-${kebabCase(dropdownID)}`,
        className,
        {open: currentDropdown === dropdownID},
      )}
    >
      <ButtonPrimary
        onClick={onClick}
        className={classNames({'btn--primary-w-dropdown': !isLoading})}
        isLoading={isLoading}
      >
        {title}
      </ButtonPrimary>
      {!isLoading && (
        <ButtonPrimary
          className="btn--primary-dropdown"
          isLoading={isLoading}
          data-dropdown={dropdownID}
        />
      )}
      <div
        className="wrap--inner-dropdown wrap--inner-dropdown-right"
        data-dropdown={dropdownID}
      >
        <ul className="list--dropdown">{children}</ul>
      </div>
    </div>
  )
}

ButtonPrimaryDropdown.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dropdownID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  currentDropdown: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  currentDropdown: currentDropdownSelector(state),
})

export default connect(mapStateToProps)(ButtonPrimaryDropdown)
