import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import {isPresent} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import api from '../../../common/api.js'
import TextInput from '../../../common/components/TextInput.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {updatePurchaseOrder} from '../purchaseOrderListActions.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const MODAL_FORM = 'PO_ID_MODAL_FORM'
export const SAVE_PO_ID = 'SAVE_PO_ID'

export function showPOIDModal(poID) {
  return setForm(MODAL_FORM, {
    poID,
    poIDEdit: poID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function savePOID() {
  return {
    type: SAVE_PO_ID,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export const poIDModalErrorsSelector = createSelector(
  modalFormSelector,
  (form) => {
    const errors = {}

    if (!form) {
      return errors
    }

    if (!isPresent(form.poIDEdit)) {
      errors.poIDEdit = 'PO ID is required'
    }

    return errors
  },
)

export function* savePOIDWorker() {
  try {
    yield put(updateModalForm({isSaving: true, serverError: null}))

    const {poID, poIDEdit} = yield select(modalFormSelector)

    if (poID === poIDEdit.trim()) {
      yield put(closeModal())

      return
    }

    const params = {po_id: poIDEdit.trim()}

    const {json: purchaseOrder} = yield call(
      api.put,
      `/purchase_order/${encodeURIComponent(poID)}`,
      params,
    )

    yield put(closeModal())

    yield call(updatePurchaseOrder, poID, purchaseOrder)
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error changing PO ID: ${
          err.message || err.error_message
        }`,
        isSaving: false,
      }),
    )
  }
}

function POIDModal({form, errors, ...props}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Edit Purchase Order ID"
      modalSize="sm"
      onConfirm={() => props.savePOID()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <TextInput
        className="margin-bottom-0"
        value={form.poIDEdit}
        onChange={(value) => props.updateModalForm({poIDEdit: value})}
        errorMessage={errors.poIDEdit}
        autoFocus
      />
    </ConfirmModal>
  )
}

POIDModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    poIDEdit: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  errors: ErrorsShape.isRequired,
  savePOID: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: modalFormSelector(state),
    errors: poIDModalErrorsSelector(state),
  }
}

const mapDispatchToProps = {
  savePOID,
  updateModalForm,
  closeModal,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(POIDModal),
  modalFormSelector,
)
