export const BASE = 'ordoro/data/commonAppMessage'
export const SET_APP_MESSAGES = `${BASE}/SET_APP_MESSAGES`
export const SET_DISMISSED_APP_MESSAGE_IDS = `${BASE}/SET_DISMISSED_APP_MESSAGE_IDS`
export const GET_COMMON_APP_MESSAGE = `${BASE}/GET_COMMON_APP_MESSAGE`
export const DISMISS_APP_MESSAGE = `${BASE}/DISMISS_APP_MESSAGE`

export function setAppMessages(appMessages) {
  return {
    type: SET_APP_MESSAGES,
    payload: appMessages,
  }
}

export function setDismissedAppMessageIDs(appMessageIDs) {
  return {
    type: SET_DISMISSED_APP_MESSAGE_IDS,
    meta: {
      sticky: SET_DISMISSED_APP_MESSAGE_IDS,
    },
    payload: appMessageIDs,
  }
}

export function getCommonAppMessage() {
  return {
    type: GET_COMMON_APP_MESSAGE,
  }
}

export function dismissAppMessage(appMessageID) {
  return {
    type: DISMISS_APP_MESSAGE,
    payload: {appMessageID},
  }
}
