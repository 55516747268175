import {useEffect} from 'react'

import {dispatch, useSelector} from '../../store.js'
import {CREATE_ORDER_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  setupCreateOrderForm,
  removeCreateOrderForm,
} from './createOrderActions.js'
import {
  createOrderLocationSelector,
  cloneFromOrderNumberSelector,
} from './createOrderSelectors.js'

export default function CreateOrderLocationListener() {
  const location = useSelector(createOrderLocationSelector)
  const cloneFromOrderNumber = useSelector(cloneFromOrderNumberSelector)
  const isThisPage = !!location

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    dispatch(setCurrentPage(CREATE_ORDER_PAGE))

    setupCreateOrderForm()

    return () => removeCreateOrderForm()
  }, [isThisPage, cloneFromOrderNumber])

  return null
}
