const BASE = 'ordoro/ui/verifyUser'
export const USER_VALIDITY = `${BASE}/USER_VALIDITY`
export const VERIFY_USER = `${BASE}/VERIFY_USER`

export function setUserValidity(userValidity) {
  return {
    type: USER_VALIDITY,
    payload: {userValidity},
  }
}

export function verifyUser() {
  return {
    type: VERIFY_USER,
  }
}
