import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import ForwardDate from '../../Fields/ForwardDate.js'
import {setShowShipDate, applyShipDate} from '../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../bulkLabelConfigSelectors.js'
import UpdateOrdersButton from './UpdateOrdersButton.js'

export default function BulkShipDate({labelInfoIDs}) {
  const form = useSelector(bulkLabelConfigFormSelector)

  return (
    <dd className="list__item">
      <label htmlFor="bulk_ship_date">
        <input
          type="checkbox"
          id="bulk_ship_date"
          onChange={() => setShowShipDate(!form.showShipDate)}
          checked={form.showShipDate}
        />
        <span className="margin-left-5">Ship Date</span>
      </label>
      {form.showShipDate && (
        <div className="wrap--nested-form">
          <ul className="list--shipping-options">
            <ForwardDate label="Ship Date" labelProperty="ship_date" />
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              onClick={() => applyShipDate(labelInfoIDs)}
              loading={form.loading__shipDate}
            />
          </ul>
        </div>
      )}
    </dd>
  )
}

BulkShipDate.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
