import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import {userSettingsSelector} from '../../../../data/me.js'
import {batchListQuerySelector} from '../../batchListSelectors.js'
import {
  navigateBatchList,
  updateActive,
  updateSearchText,
} from '../../batchListActions.js'

const MODAL_FORM = 'BATCH_LIST_FILTER_MODAL'

export const STATUS_FILTER_PANEL = 'status'
const DEFAULT_BATCH_LIST_FILTER_ORDER = [STATUS_FILTER_PANEL]

export function showBatchListFilterModal({clear} = {}) {
  const {active, searchText} = batchListQuerySelector(getState())

  setForm(MODAL_FORM, {
    searchText: clear ? '' : searchText,
    active: clear ? true : active,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(modalFormSelector, () => {
  const errors = {}

  return errors
})

export async function applyFilter() {
  const {searchText, active} = modalFormSelector(getState())
  const query = batchListQuerySelector(getState())

  const updates = {
    ...(searchText !== query.searchText
      ? updateSearchText(searchText)
      : undefined),
    ...(active !== query.active ? updateActive(active) : undefined),
  }

  if (Object.keys(updates).length !== 0) {
    navigateBatchList(updates)
  }

  closeModal()
}

export const batchListFilterOrderSelector = createSelector(
  (state) => userSettingsSelector(state).batch_list_filter_order,
  (list) => {
    if (!list) {
      return DEFAULT_BATCH_LIST_FILTER_ORDER
    }

    // remove any items that have been removed from the default list
    list = list.filter((item) => DEFAULT_BATCH_LIST_FILTER_ORDER.includes(item))

    // if the list aren't the same size then there are new items in the default list
    // that need to be added to the list
    if (list.length !== DEFAULT_BATCH_LIST_FILTER_ORDER.length) {
      const newItems = DEFAULT_BATCH_LIST_FILTER_ORDER.filter(
        (item) => !list.includes(item),
      )

      list.push(...newItems)
    }

    return list
  },
)
