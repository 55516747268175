import PropTypes from 'prop-types'
import {useRef, useEffect} from 'react'

import cn from '../className.js'
import useAutoFocus from '../hooks/useAutoFocus.js'

export default function Checkbox({
  checked,
  indeterminate,
  autoFocus,
  onChange,
  onClick,
  errorMessage,
  className,
  labelClassName,
  id,
  label,
  disabled,
  mode,
  helpLink,
  helpTitle,
  required,
  ...props
}) {
  className = className || ''
  labelClassName = labelClassName || ''

  const checkbox = useRef(null)
  useEffect(() => {
    checkbox.current.indeterminate = !!indeterminate
  }, [indeterminate])

  useAutoFocus(checkbox, autoFocus)

  const helpLinkAnchor = helpLink ? (
    <a
      className="btn btn--link lh-sm no-underline light margin-left-3"
      href={helpLink}
      target="_blank"
      rel="noopener noreferrer"
      title={helpTitle}
    >
      <span className="i-help fs-01 lh-sm"></span>
    </a>
  ) : null

  if (mode === 'fancy') {
    const input = (
      <input
        className={`checkbox--fancy ${className}`}
        type="checkbox"
        id={id}
        ref={checkbox}
        onChange={onChange ? (event) => onChange(!checked, event) : () => {}}
        onClick={onClick ? (event) => onClick(!checked, event) : undefined}
        checked={checked}
        disabled={disabled}
      />
    )

    return (
      <>
        {label ? (
          <label className={`label--fancy flex ${labelClassName}`} htmlFor={id}>
            {input}
            <div className="label__text--fancy flex--justify-col">
              <div>
                <span className="label__pre-text">{label}</span>
              </div>
            </div>
            {helpLinkAnchor}
          </label>
        ) : (
          input
        )}
        {errorMessage && (
          <small className="error error-message">{errorMessage}</small>
        )}
      </>
    )
  }

  if (mode === 'switch') {
    return (
      <div className="wrap--switch flex">
        <input
          id={id}
          ref={checkbox}
          className="switch--fancier"
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange ? (event) => onChange(!checked, event) : () => {}}
          onClick={onClick ? (event) => onClick(!checked, event) : undefined}
        />
        <label htmlFor={id} className="switch__background--fancier" />
      </div>
    )
  }

  const hasLabel = label && id
  const input = (
    <input
      className={`${className} ${
        hasLabel ? 'margin-right-5 margin-bottom-0' : ''
      }`}
      id={id}
      ref={checkbox}
      type="checkbox"
      onChange={onChange ? (event) => onChange(!checked, event) : () => {}}
      onClick={onClick ? (event) => onClick(!checked, event) : undefined}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  )

  return (
    <>
      {hasLabel ? (
        <label
          className={cn`${labelClassName} ${{
            flex: helpLink,
          }}`}
          htmlFor={id}
        >
          {input}
          <span className={cn`${{'op-50': disabled}}`}>{label}</span>
          {required ? <span className="required">*</span> : null}
          {helpLinkAnchor}
        </label>
      ) : (
        input
      )}
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  mode: PropTypes.string,
  helpLink: PropTypes.string,
  helpTitle: PropTypes.string,
}
