import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {setForm, updateForm, formsSelector, getState} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import userflow from '../common/analytics/userflow.js'

export const USERS = 'USERS'

export function usersSelector(state) {
  return formsSelector(state)[USERS] || usersSelector.default
}
usersSelector.default = {}

export function usersHaveLoadedSelector(state) {
  return !!formsSelector(state)[USERS]
}

export function userSelector(state, {userID}) {
  return usersSelector(state)[userID]
}

export const usersSortedByNameSelector = createSelector(
  usersSelector,
  (users) => sortBy(users, 'name'),
)

export const activeUsersSortedByNameSelector = createSelector(
  usersSortedByNameSelector,
  (users) =>
    users.filter(
      (user) => user.is_active && !user.is_api_user && !user.archive_date,
    ),
)

export const userCountSelector = createSelector(
  activeUsersSortedByNameSelector,
  (users) => users.length,
)

export function setUsers(users) {
  setForm(USERS, keyBy(users, 'id'))
}

export function setUser(user) {
  updateForm(USERS, {[user.id]: user})
}

export async function getUsers() {
  try {
    const users = await fetchAllAPI('/user/', 'user')

    setUsers(users)

    const user_count = userCountSelector(getState())

    userflow.updateGroup({
      user_count,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting users.',
        details: err.message || err.error_message,
      },
      `Error getting users. ${err.error_message || err.message}`,
      err,
    )

    setUsers([])
  }
}
