import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../../data/labelInfos/index.js'
import ConfigSelect from '../ConfigSelect.js'
import {getState, useSelector} from '../../../../store.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_IS_MAIL_INNOVATIONS} from './MailInnovations.js'
import {LABEL_PROPERTY_SHIPPER_ID} from '../ShipperSelect/index.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [UPS]

function uspsEndorsementTemplate(shipperType) {
  return `${shipperType}__usps_endorsement`
}
const VALID_LABEL_PROPERTIES = VALID_SHIPPER_TYPES.map((shipperType) =>
  uspsEndorsementTemplate(shipperType),
)

function labelPropertiesFunc(shipperType, labelInfoID) {
  const {is_mail_innovations} = labelConfigSelector(getState(), {labelInfoID})

  return is_mail_innovations && VALID_SHIPPER_TYPES.includes(shipperType)
    ? [uspsEndorsementTemplate(shipperType)]
    : []
}

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_IS_MAIL_INNOVATIONS]
}

export default function USPSEndorsement() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType, labelInfoID)
  const {is_mail_innovations: isMailInnovations} = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  return (
    <ConfigSelect
      label="USPS Endorsement"
      noEmptyOption
      hide={!isMailInnovations}
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      dependantPropertiesFunc={dependantPropertiesFunc}
      validLabelProperties={VALID_LABEL_PROPERTIES}
      shipperOptionsProperty="usps_endorsement"
    />
  )
}
