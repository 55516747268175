import PropTypes from 'prop-types'
import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {NEW_ID} from '../../../common/constants/SettingsPanels.js'
import {EDIT_WAREHOUSE_ADDRESS} from '../../../common/constants/AddressTypes.js'
import TextInput from '../../../common/components/TextInput.js'
import Checkbox from '../../../common/components/Checkbox.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showPasteAddressModal} from '../../../common/components/Modal/PasteAddressModal.js'
import {showWarehouseAddressLabelModal} from './WarehouseAddressLabelModal.js'
import {
  warehousesFormSelector,
  errorsSelector,
  updateWarehousesForm,
} from './warehousesFunctions.js'

export default function Form({isOriginalDefault, isFBAWarehouse}) {
  const form = useSelector(warehousesFormSelector)
  const errors = useSelector(errorsSelector)

  return (
    <ul className="form-list medium-7 columns margin-left-0">
      <li className="form-list-item margin-bottom-15 row">
        <div className="medium-12 columns">
          <TextInput
            label="Warehouse Name (must be two words)"
            required
            id="warehouse_name"
            value={form.name}
            onChange={(name) =>
              updateWarehousesForm({name, hasChanged_name: true})
            }
            errorMessage={
              (form.hasChanged_name || form.id !== NEW_ID) && errors.name
            }
          />
        </div>
      </li>
      {!isFBAWarehouse && (
        <>
          <li className="form-list-item row margin-bottom-20">
            <div className="medium-12 columns">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() =>
                  showPasteAddressModal({
                    addressType: EDIT_WAREHOUSE_ADDRESS,
                    address: {
                      name: form.name,
                      street1: form.street1,
                      street2: form.street2,
                      city: form.city,
                      state: form.state,
                      zip: form.zip,
                      country: form.country,
                    },
                  })
                }
              >
                Paste Address
              </ButtonPrimary>
            </div>
          </li>
          <li className="form-list-item margin-bottom-15 row">
            <div className="medium-12 columns">
              <TextInput
                label="Street Address 1"
                required
                id="warehouse_street1"
                value={form.street1}
                onChange={(street1) =>
                  updateWarehousesForm({street1, hasChanged_street1: true})
                }
                errorMessage={form.hasChanged_street1 && errors.street1}
              />
            </div>
          </li>
          <li className="form-list-item margin-bottom-15 row">
            <div className="medium-12 columns">
              <TextInput
                label="Street Address 2"
                id="warehouse_street2"
                value={form.street2}
                onChange={(street2) => updateWarehousesForm({street2})}
              />
            </div>
          </li>
          <li className="form-list-item margin-bottom-15 row">
            <div className="medium-6 columns">
              <TextInput
                label="City"
                required
                id="warehouse_city"
                value={form.city}
                onChange={(city) =>
                  updateWarehousesForm({city, hasChanged_city: true})
                }
                errorMessage={form.hasChanged_city && errors.city}
              />
            </div>
            <div className="medium-2 columns">
              <TextInput
                label="State"
                required
                id="warehouse_state"
                value={form.state}
                onChange={(state) =>
                  updateWarehousesForm({state, hasChanged_state: true})
                }
                errorMessage={form.hasChanged_state && errors.state}
              />
            </div>
            <div className="medium-4 columns">
              <TextInput
                label="Zipcode"
                required
                id="warehouse_zip"
                value={form.zip}
                onChange={(zip) =>
                  updateWarehousesForm({zip, hasChanged_zip: true})
                }
                errorMessage={form.hasChanged_zip && errors.zip}
              />
            </div>
          </li>
          <li className="form-list-item margin-bottom-5 row">
            <div className="medium-6 columns">
              <TextInput
                label="Country"
                required
                id="warehouse_country"
                value={form.country}
                onChange={(country) =>
                  updateWarehousesForm({country, hasChanged_country: true})
                }
                errorMessage={form.hasChanged_country && errors.country}
              />
            </div>
          </li>
          <li className="form-list-item row">
            <div className="medium-12 columns">
              <hr />
            </div>
          </li>
          <li className="form-list-item margin-bottom-15 row">
            <div className="medium-6 columns">
              <TextInput
                label="Phone"
                required
                id="warehouse_phone"
                value={form.phone}
                onChange={(phone) =>
                  updateWarehousesForm({phone, hasChanged_phone: true})
                }
                errorMessage={form.hasChanged_phone && errors.phone}
              />
            </div>
          </li>
          <li className="form-list-item margin-bottom-5 row">
            <div className="medium-7 columns">
              <TextInput
                label="Email"
                required
                id="warehouse_email"
                value={form.email}
                onChange={(email) =>
                  updateWarehousesForm({email, hasChanged_email: true})
                }
                errorMessage={form.hasChanged_email && errors.email}
              />
            </div>
          </li>
          <li className="form-list-item row">
            <div className="medium-12 columns">
              <hr />
            </div>
          </li>
          <li className="form-list-item row margin-bottom-20">
            <div className="medium-12 columns">
              <label className="unbold-label" htmlFor="make_default">
                <Checkbox
                  id="make_default"
                  className="margin-bottom-0 margin-right-5"
                  disabled={isOriginalDefault}
                  onChange={(is_default_location) =>
                    updateWarehousesForm({is_default_location})
                  }
                  checked={form.is_default_location}
                />
                <span className={classNames({'op-50': isOriginalDefault})}>
                  Make this the default warehouse
                </span>
              </label>
            </div>
          </li>
          {form.id !== NEW_ID && (
            <li className="form-list-item row margin-bottom-20">
              <div className="medium-12 columns">
                <ButtonPrimary
                  isOutlined
                  size="sm"
                  onClick={() => showWarehouseAddressLabelModal(form.id)}
                >
                  Print Address Labels
                </ButtonPrimary>
              </div>
            </li>
          )}
        </>
      )}
    </ul>
  )
}

Form.propTypes = {
  isOriginalDefault: PropTypes.bool,
  isFBAWarehouse: PropTypes.bool,
}
