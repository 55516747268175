import PropTypes from 'prop-types'

import {LabelInfoIDShape} from '../../../../../../../common/PropTypes.js'
import {showMultiboxModal} from '../../../../../../OrderListPage/Modals/MultiboxModal/index.js'

export default function PackageType({
  labelInfoID,
  shipperType,
  boxShape,
  isMultibox,
  configureIndividualBoxShape,
}) {
  return (
    <li className="list__item--order-data list__item--shipping-info">
      <strong className="margin-right-3">Package Type:</strong>
      {isMultibox && configureIndividualBoxShape ? (
        !(labelInfoID && shipperType) ? (
          <button
            className="btn btn--link v-align-base"
            type="button"
            onClick={() => showMultiboxModal(labelInfoID, shipperType)}
          >
            <em>Multiple</em>
          </button>
        ) : (
          <em>Multiple</em>
        )
      ) : boxShape ? (
        <span>{boxShape}</span>
      ) : (
        <em>None selected</em>
      )}
    </li>
  )
}

PackageType.propTypes = {
  labelInfoID: LabelInfoIDShape,
  shipperType: PropTypes.string,
  boxShape: PropTypes.string,
  isMultibox: PropTypes.bool.isRequired,
  configureIndividualBoxShape: PropTypes.bool.isRequired,
}
