import PropTypes from 'prop-types'
import {Component} from 'react'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onSKUChange = (value) => updatePayload({sku: value})
    this.onAddChildSKUChange = (value) => updatePayload({add_child_sku: value})
    this.onRemoveChildSKUChange = (value) =>
      updatePayload({remove_child_sku: value})
    this.onQuantityChange = (value) => updatePayload({quantity: value})
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-5 columns" {...this.props}>
        <Row>
          <TextInput
            value={data.payload.sku}
            errorMessage={data.errors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        {!data.payload.remove_child_sku && (
          <>
            <Row>
              <TextInput
                value={data.payload.add_child_sku}
                errorMessage={data.errors.add_child_sku}
                name="add_child_sku"
                label="Add Child SKU"
                className="medium-12"
                onChange={this.onAddChildSKUChange}
              />
            </Row>
            <Row>
              <TextInput
                value={data.payload.quantity}
                errorMessage={data.errors.quantity}
                name="quantity"
                label="Quantity"
                className="medium-8"
                onChange={this.onQuantityChange}
              />
            </Row>
          </>
        )}
        {!data.payload.add_child_sku && (
          <Row>
            <TextInput
              value={data.payload.remove_child_sku}
              errorMessage={data.errors.remove_child_sku}
              name="remove_child_sku"
              label="Remove Child SKU"
              className="medium-12"
              onChange={this.onRemoveChildSKUChange}
            />
          </Row>
        )}
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
