import {useEffect} from 'react'
import get from 'lodash/get.js'

import {
  labelConfigSelector,
  onLabelPropertyValueChange,
  packagesSelector,
  updateLabelConfig,
  updateParcel,
} from '../../../../data/labelInfos/index.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import {getState, useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'
import {shipperOptionSelector} from '../../../../data/shipperOptions.js'
import {getRates} from '../../../../data/labelInfos/rateRequest.js'

export const LABEL_PROPERTY_IS_MAIL_INNOVATIONS = 'is_mail_innovations'
const VALID_SHIPPER_TYPES = [UPS]

export async function updateIsMailInnovations(
  labelInfoID,
  shipperType,
  isMailInnovations,
) {
  try {
    const shipperOptions = shipperOptionSelector(getState(), {shipperType})

    const defaultBoxShape = isMailInnovations
      ? shipperOptions.box_shape_mail_innovations
      : shipperOptions.box_shape

    updateLabelConfig(labelInfoID, {
      is_mail_innovations: isMailInnovations,
    })

    const packages = packagesSelector(getState(), {labelInfoID})

    packages.forEach((_, index) =>
      updateParcel(labelInfoID, index, {
        [`${shipperType}__box_shape`]: get(defaultBoxShape, '0.value', null),
      }),
    )

    await getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

export default function MailInnovations() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const {is_mail_innovations} = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  useEffect(() => {
    onLabelPropertyValueChange(
      labelInfoID,
      shipperType,
      LABEL_PROPERTY_IS_MAIL_INNOVATIONS,
    )
  }, [is_mail_innovations])

  return (
    <ConfigCheckbox
      label="Mail Innovations"
      onChange={(checked) => {
        updateIsMailInnovations(labelInfoID, shipperType, checked)

        onChange()
      }}
      labelProperty={LABEL_PROPERTY_IS_MAIL_INNOVATIONS}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
