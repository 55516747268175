import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import Select from '../../../../common/components/Form/Select.js'
import {saveShipperDHL} from '../../../../redux/actions/ui/settings/shippers/forms/dhl.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function DHLForm({
  form: {id, paymentAccountNumber, paymentCountryCode, isSaving},
  hasErrors,
  errors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.paymentAccountNumber},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="id_dhl_acct_no">
                Payment Account Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="dhl_acct_no"
                id="id_dhl_acct_no"
                value={paymentAccountNumber}
                onChange={(event) =>
                  props.updateForm(id, {
                    paymentAccountNumber: event.target.value,
                  })
                }
              />
              {errors.paymentAccountNumber && (
                <small className="error error-message">
                  {errors.paymentAccountNumber}
                </small>
              )}
            </li>
            <li className="form-list-item bigger margin-bottom-20">
              <label htmlFor="id_dhl_country_code">Payment Country Code</label>
              <Select
                name="dhl_country_code"
                value={paymentCountryCode}
                onChange={(value) =>
                  props.updateForm(id, {paymentCountryCode: value})
                }
              >
                <option value="">Use “From” Address</option>
                <option value="US">US</option>
                <option value="CA">CA</option>
              </Select>
            </li>
            {id !== NEW_ID && <ShippingMethods shipperID={id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                isLoading={isSaving}
                isDisabled={hasErrors}
                onClick={() => props.saveShipperDHL(id)}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      <div className="medium-5 columns">
        <fieldset>
          <div className="alert margin-bottom-10 alert--standard">
            {id === NEW_ID ? (
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help authorizing your DHL account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  <a
                    className="btn--link mid meta-ups-setup-help"
                    href="https://support.ordoro.com/how-do-i-set-up-dhl-in-ordoro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get step-by-step instructions →
                  </a>
                </dd>
              </dl>
            ) : (
              <Archive shipperID={id} />
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

DHLForm.propTypes = {
  form: ShipperFormShape,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveShipperDHL: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
  }
}

const mapDispatchToProps = {
  saveShipperDHL,
}

export default connect(mapStateToProps, mapDispatchToProps)(DHLForm)
