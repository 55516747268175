export const TIME_ZONES = [
  {country_code: 'CI', name: 'Africa/Abidjan', utc_offset: '+00:00'},
  {country_code: 'GH', name: 'Africa/Accra', utc_offset: '+00:00'},
  {country_code: 'ET', name: 'Africa/Addis_Ababa', utc_offset: '+03:00'},
  {country_code: 'DZ', name: 'Africa/Algiers', utc_offset: '+01:00'},
  {country_code: 'ER', name: 'Africa/Asmara', utc_offset: '+03:00'},
  {country_code: 'ML', name: 'Africa/Bamako', utc_offset: '+00:00'},
  {country_code: 'CF', name: 'Africa/Bangui', utc_offset: '+01:00'},
  {country_code: 'GM', name: 'Africa/Banjul', utc_offset: '+00:00'},
  {country_code: 'GW', name: 'Africa/Bissau', utc_offset: '+00:00'},
  {country_code: 'MW', name: 'Africa/Blantyre', utc_offset: '+02:00'},
  {country_code: 'CG', name: 'Africa/Brazzaville', utc_offset: '+01:00'},
  {country_code: 'BI', name: 'Africa/Bujumbura', utc_offset: '+02:00'},
  {country_code: 'EG', name: 'Africa/Cairo', utc_offset: '+02:00'},
  {country_code: 'MA', name: 'Africa/Casablanca', utc_offset: '+01:00'},
  {country_code: 'ES', name: 'Africa/Ceuta', utc_offset: '+01:00'},
  {country_code: 'GN', name: 'Africa/Conakry', utc_offset: '+00:00'},
  {country_code: 'SN', name: 'Africa/Dakar', utc_offset: '+00:00'},
  {country_code: 'TZ', name: 'Africa/Dar_es_Salaam', utc_offset: '+03:00'},
  {country_code: 'DJ', name: 'Africa/Djibouti', utc_offset: '+03:00'},
  {country_code: 'CM', name: 'Africa/Douala', utc_offset: '+01:00'},
  {country_code: 'EH', name: 'Africa/El_Aaiun', utc_offset: '+01:00'},
  {country_code: 'SL', name: 'Africa/Freetown', utc_offset: '+00:00'},
  {country_code: 'BW', name: 'Africa/Gaborone', utc_offset: '+02:00'},
  {country_code: 'ZW', name: 'Africa/Harare', utc_offset: '+02:00'},
  {country_code: 'ZA', name: 'Africa/Johannesburg', utc_offset: '+02:00'},
  {country_code: 'SS', name: 'Africa/Juba', utc_offset: '+03:00'},
  {country_code: 'UG', name: 'Africa/Kampala', utc_offset: '+03:00'},
  {country_code: 'SD', name: 'Africa/Khartoum', utc_offset: '+02:00'},
  {country_code: 'RW', name: 'Africa/Kigali', utc_offset: '+02:00'},
  {country_code: 'CD', name: 'Africa/Kinshasa', utc_offset: '+01:00'},
  {country_code: 'NG', name: 'Africa/Lagos', utc_offset: '+01:00'},
  {country_code: 'GA', name: 'Africa/Libreville', utc_offset: '+01:00'},
  {country_code: 'TG', name: 'Africa/Lome', utc_offset: '+00:00'},
  {country_code: 'AO', name: 'Africa/Luanda', utc_offset: '+01:00'},
  {country_code: 'CD', name: 'Africa/Lubumbashi', utc_offset: '+02:00'},
  {country_code: 'ZM', name: 'Africa/Lusaka', utc_offset: '+02:00'},
  {country_code: 'GQ', name: 'Africa/Malabo', utc_offset: '+01:00'},
  {country_code: 'MZ', name: 'Africa/Maputo', utc_offset: '+02:00'},
  {country_code: 'LS', name: 'Africa/Maseru', utc_offset: '+02:00'},
  {country_code: 'SZ', name: 'Africa/Mbabane', utc_offset: '+02:00'},
  {country_code: 'SO', name: 'Africa/Mogadishu', utc_offset: '+03:00'},
  {country_code: 'LR', name: 'Africa/Monrovia', utc_offset: '+00:00'},
  {country_code: 'KE', name: 'Africa/Nairobi', utc_offset: '+03:00'},
  {country_code: 'TD', name: 'Africa/Ndjamena', utc_offset: '+01:00'},
  {country_code: 'NE', name: 'Africa/Niamey', utc_offset: '+01:00'},
  {country_code: 'MR', name: 'Africa/Nouakchott', utc_offset: '+00:00'},
  {country_code: 'BF', name: 'Africa/Ouagadougou', utc_offset: '+00:00'},
  {country_code: 'BJ', name: 'Africa/Porto-Novo', utc_offset: '+01:00'},
  {country_code: 'ST', name: 'Africa/Sao_Tome', utc_offset: '+00:00'},
  {country_code: 'LY', name: 'Africa/Tripoli', utc_offset: '+02:00'},
  {country_code: 'TN', name: 'Africa/Tunis', utc_offset: '+01:00'},
  {country_code: 'NA', name: 'Africa/Windhoek', utc_offset: '+02:00'},
  {country_code: 'US', name: 'America/Adak', utc_offset: '-10:00'},
  {country_code: 'US', name: 'America/Anchorage', utc_offset: '-09:00'},
  {country_code: 'AI', name: 'America/Anguilla', utc_offset: '-04:00'},
  {country_code: 'AG', name: 'America/Antigua', utc_offset: '-04:00'},
  {country_code: 'BR', name: 'America/Araguaina', utc_offset: '-03:00'},
  {
    country_code: 'AR',
    name: 'America/Argentina/Buenos_Aires',
    utc_offset: '-03:00',
  },
  {
    country_code: 'AR',
    name: 'America/Argentina/Catamarca',
    utc_offset: '-03:00',
  },
  {country_code: 'AR', name: 'America/Argentina/Cordoba', utc_offset: '-03:00'},
  {country_code: 'AR', name: 'America/Argentina/Jujuy', utc_offset: '-03:00'},
  {
    country_code: 'AR',
    name: 'America/Argentina/La_Rioja',
    utc_offset: '-03:00',
  },
  {country_code: 'AR', name: 'America/Argentina/Mendoza', utc_offset: '-03:00'},
  {
    country_code: 'AR',
    name: 'America/Argentina/Rio_Gallegos',
    utc_offset: '-03:00',
  },
  {country_code: 'AR', name: 'America/Argentina/Salta', utc_offset: '-03:00'},
  {
    country_code: 'AR',
    name: 'America/Argentina/San_Juan',
    utc_offset: '-03:00',
  },
  {
    country_code: 'AR',
    name: 'America/Argentina/San_Luis',
    utc_offset: '-03:00',
  },
  {country_code: 'AR', name: 'America/Argentina/Tucuman', utc_offset: '-03:00'},
  {country_code: 'AR', name: 'America/Argentina/Ushuaia', utc_offset: '-03:00'},
  {country_code: 'AW', name: 'America/Aruba', utc_offset: '-04:00'},
  {country_code: 'PY', name: 'America/Asuncion', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Atikokan', utc_offset: '-05:00'},
  {country_code: 'BR', name: 'America/Bahia', utc_offset: '-03:00'},
  {country_code: 'MX', name: 'America/Bahia_Banderas', utc_offset: '-06:00'},
  {country_code: 'BB', name: 'America/Barbados', utc_offset: '-04:00'},
  {country_code: 'BR', name: 'America/Belem', utc_offset: '-03:00'},
  {country_code: 'BZ', name: 'America/Belize', utc_offset: '-06:00'},
  {country_code: 'CA', name: 'America/Blanc-Sablon', utc_offset: '-04:00'},
  {country_code: 'BR', name: 'America/Boa_Vista', utc_offset: '-04:00'},
  {country_code: 'CO', name: 'America/Bogota', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Boise', utc_offset: '-07:00'},
  {country_code: 'CA', name: 'America/Cambridge_Bay', utc_offset: '-07:00'},
  {country_code: 'BR', name: 'America/Campo_Grande', utc_offset: '-04:00'},
  {country_code: 'MX', name: 'America/Cancun', utc_offset: '-05:00'},
  {country_code: 'VE', name: 'America/Caracas', utc_offset: '-04:00'},
  {country_code: 'GF', name: 'America/Cayenne', utc_offset: '-03:00'},
  {country_code: 'KY', name: 'America/Cayman', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Chicago', utc_offset: '-06:00'},
  {country_code: 'MX', name: 'America/Chihuahua', utc_offset: '-07:00'},
  {country_code: 'CR', name: 'America/Costa_Rica', utc_offset: '-06:00'},
  {country_code: 'CA', name: 'America/Creston', utc_offset: '-07:00'},
  {country_code: 'BR', name: 'America/Cuiaba', utc_offset: '-04:00'},
  {country_code: 'CW', name: 'America/Curacao', utc_offset: '-04:00'},
  {country_code: 'GL', name: 'America/Danmarkshavn', utc_offset: '+00:00'},
  {country_code: 'CA', name: 'America/Dawson', utc_offset: '-07:00'},
  {country_code: 'CA', name: 'America/Dawson_Creek', utc_offset: '-07:00'},
  {country_code: 'US', name: 'America/Denver', utc_offset: '-07:00'},
  {country_code: 'US', name: 'America/Detroit', utc_offset: '-05:00'},
  {country_code: 'DM', name: 'America/Dominica', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Edmonton', utc_offset: '-07:00'},
  {country_code: 'BR', name: 'America/Eirunepe', utc_offset: '-05:00'},
  {country_code: 'SV', name: 'America/El_Salvador', utc_offset: '-06:00'},
  {country_code: 'CA', name: 'America/Fort_Nelson', utc_offset: '-07:00'},
  {country_code: 'BR', name: 'America/Fortaleza', utc_offset: '-03:00'},
  {country_code: 'CA', name: 'America/Glace_Bay', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Goose_Bay', utc_offset: '-04:00'},
  {country_code: 'TC', name: 'America/Grand_Turk', utc_offset: '-05:00'},
  {country_code: 'GD', name: 'America/Grenada', utc_offset: '-04:00'},
  {country_code: 'GP', name: 'America/Guadeloupe', utc_offset: '-04:00'},
  {country_code: 'GT', name: 'America/Guatemala', utc_offset: '-06:00'},
  {country_code: 'EC', name: 'America/Guayaquil', utc_offset: '-05:00'},
  {country_code: 'GY', name: 'America/Guyana', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Halifax', utc_offset: '-04:00'},
  {country_code: 'CU', name: 'America/Havana', utc_offset: '-05:00'},
  {country_code: 'MX', name: 'America/Hermosillo', utc_offset: '-07:00'},
  {
    country_code: 'US',
    name: 'America/Indiana/Indianapolis',
    utc_offset: '-05:00',
  },
  {country_code: 'US', name: 'America/Indiana/Knox', utc_offset: '-06:00'},
  {country_code: 'US', name: 'America/Indiana/Marengo', utc_offset: '-05:00'},
  {
    country_code: 'US',
    name: 'America/Indiana/Petersburg',
    utc_offset: '-05:00',
  },
  {country_code: 'US', name: 'America/Indiana/Tell_City', utc_offset: '-06:00'},
  {country_code: 'US', name: 'America/Indiana/Vevay', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Indiana/Vincennes', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Indiana/Winamac', utc_offset: '-05:00'},
  {country_code: 'CA', name: 'America/Inuvik', utc_offset: '-07:00'},
  {country_code: 'CA', name: 'America/Iqaluit', utc_offset: '-05:00'},
  {country_code: 'JM', name: 'America/Jamaica', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Juneau', utc_offset: '-09:00'},
  {
    country_code: 'US',
    name: 'America/Kentucky/Louisville',
    utc_offset: '-05:00',
  },
  {
    country_code: 'US',
    name: 'America/Kentucky/Monticello',
    utc_offset: '-05:00',
  },
  {country_code: 'BQ', name: 'America/Kralendijk', utc_offset: '-04:00'},
  {country_code: 'BO', name: 'America/La_Paz', utc_offset: '-04:00'},
  {country_code: 'PE', name: 'America/Lima', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Los_Angeles', utc_offset: '-08:00'},
  {country_code: 'SX', name: 'America/Lower_Princes', utc_offset: '-04:00'},
  {country_code: 'BR', name: 'America/Maceio', utc_offset: '-03:00'},
  {country_code: 'NI', name: 'America/Managua', utc_offset: '-06:00'},
  {country_code: 'BR', name: 'America/Manaus', utc_offset: '-04:00'},
  {country_code: 'MF', name: 'America/Marigot', utc_offset: '-04:00'},
  {country_code: 'MQ', name: 'America/Martinique', utc_offset: '-04:00'},
  {country_code: 'MX', name: 'America/Matamoros', utc_offset: '-06:00'},
  {country_code: 'MX', name: 'America/Mazatlan', utc_offset: '-07:00'},
  {country_code: 'US', name: 'America/Menominee', utc_offset: '-06:00'},
  {country_code: 'MX', name: 'America/Merida', utc_offset: '-06:00'},
  {country_code: 'US', name: 'America/Metlakatla', utc_offset: '-09:00'},
  {country_code: 'MX', name: 'America/Mexico_City', utc_offset: '-06:00'},
  {country_code: 'PM', name: 'America/Miquelon', utc_offset: '-03:00'},
  {country_code: 'CA', name: 'America/Moncton', utc_offset: '-04:00'},
  {country_code: 'MX', name: 'America/Monterrey', utc_offset: '-06:00'},
  {country_code: 'UY', name: 'America/Montevideo', utc_offset: '-03:00'},
  {country_code: 'MS', name: 'America/Montserrat', utc_offset: '-04:00'},
  {country_code: 'BS', name: 'America/Nassau', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/New_York', utc_offset: '-05:00'},
  {country_code: 'CA', name: 'America/Nipigon', utc_offset: '-05:00'},
  {country_code: 'US', name: 'America/Nome', utc_offset: '-09:00'},
  {country_code: 'BR', name: 'America/Noronha', utc_offset: '-02:00'},
  {
    country_code: 'US',
    name: 'America/North_Dakota/Beulah',
    utc_offset: '-06:00',
  },
  {
    country_code: 'US',
    name: 'America/North_Dakota/Center',
    utc_offset: '-06:00',
  },
  {
    country_code: 'US',
    name: 'America/North_Dakota/New_Salem',
    utc_offset: '-06:00',
  },
  {country_code: 'GL', name: 'America/Nuuk', utc_offset: '-03:00'},
  {country_code: 'MX', name: 'America/Ojinaga', utc_offset: '-07:00'},
  {country_code: 'PA', name: 'America/Panama', utc_offset: '-05:00'},
  {country_code: 'CA', name: 'America/Pangnirtung', utc_offset: '-05:00'},
  {country_code: 'SR', name: 'America/Paramaribo', utc_offset: '-03:00'},
  {country_code: 'US', name: 'America/Phoenix', utc_offset: '-07:00'},
  {country_code: 'TT', name: 'America/Port_of_Spain', utc_offset: '-04:00'},
  {country_code: 'HT', name: 'America/Port-au-Prince', utc_offset: '-05:00'},
  {country_code: 'BR', name: 'America/Porto_Velho', utc_offset: '-04:00'},
  {country_code: 'PR', name: 'America/Puerto_Rico', utc_offset: '-04:00'},
  {country_code: 'CL', name: 'America/Punta_Arenas', utc_offset: '-03:00'},
  {country_code: 'CA', name: 'America/Rainy_River', utc_offset: '-06:00'},
  {country_code: 'CA', name: 'America/Rankin_Inlet', utc_offset: '-06:00'},
  {country_code: 'BR', name: 'America/Recife', utc_offset: '-03:00'},
  {country_code: 'CA', name: 'America/Regina', utc_offset: '-06:00'},
  {country_code: 'CA', name: 'America/Resolute', utc_offset: '-06:00'},
  {country_code: 'BR', name: 'America/Rio_Branco', utc_offset: '-05:00'},
  {country_code: 'BR', name: 'America/Santarem', utc_offset: '-03:00'},
  {country_code: 'CL', name: 'America/Santiago', utc_offset: '-04:00'},
  {country_code: 'DO', name: 'America/Santo_Domingo', utc_offset: '-04:00'},
  {country_code: 'BR', name: 'America/Sao_Paulo', utc_offset: '-03:00'},
  {country_code: 'GL', name: 'America/Scoresbysund', utc_offset: '-01:00'},
  {country_code: 'US', name: 'America/Sitka', utc_offset: '-09:00'},
  {country_code: 'BL', name: 'America/St_Barthelemy', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/St_Johns', utc_offset: '-03:30'},
  {country_code: 'KN', name: 'America/St_Kitts', utc_offset: '-04:00'},
  {country_code: 'LC', name: 'America/St_Lucia', utc_offset: '-04:00'},
  {country_code: 'VI', name: 'America/St_Thomas', utc_offset: '-04:00'},
  {country_code: 'VC', name: 'America/St_Vincent', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Swift_Current', utc_offset: '-06:00'},
  {country_code: 'HN', name: 'America/Tegucigalpa', utc_offset: '-06:00'},
  {country_code: 'GL', name: 'America/Thule', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Thunder_Bay', utc_offset: '-05:00'},
  {country_code: 'MX', name: 'America/Tijuana', utc_offset: '-08:00'},
  {country_code: 'CA', name: 'America/Toronto', utc_offset: '-05:00'},
  {country_code: 'VG', name: 'America/Tortola', utc_offset: '-04:00'},
  {country_code: 'CA', name: 'America/Vancouver', utc_offset: '-08:00'},
  {country_code: 'CA', name: 'America/Whitehorse', utc_offset: '-07:00'},
  {country_code: 'CA', name: 'America/Winnipeg', utc_offset: '-06:00'},
  {country_code: 'US', name: 'America/Yakutat', utc_offset: '-09:00'},
  {country_code: 'CA', name: 'America/Yellowknife', utc_offset: '-07:00'},
  {country_code: 'AQ', name: 'Antarctica/Casey', utc_offset: '+11:00'},
  {country_code: 'AQ', name: 'Antarctica/Davis', utc_offset: '+07:00'},
  {country_code: 'AQ', name: 'Antarctica/DumontDUrville', utc_offset: '+10:00'},
  {country_code: 'AU', name: 'Antarctica/Macquarie', utc_offset: '+10:00'},
  {country_code: 'AQ', name: 'Antarctica/Mawson', utc_offset: '+05:00'},
  {country_code: 'AQ', name: 'Antarctica/McMurdo', utc_offset: '+12:00'},
  {country_code: 'AQ', name: 'Antarctica/Palmer', utc_offset: '-03:00'},
  {country_code: 'AQ', name: 'Antarctica/Rothera', utc_offset: '-03:00'},
  {country_code: 'AQ', name: 'Antarctica/Syowa', utc_offset: '+03:00'},
  {country_code: 'AQ', name: 'Antarctica/Troll', utc_offset: '+00:00'},
  {country_code: 'AQ', name: 'Antarctica/Vostok', utc_offset: '+06:00'},
  {country_code: 'SJ', name: 'Arctic/Longyearbyen', utc_offset: '+01:00'},
  {country_code: 'YE', name: 'Asia/Aden', utc_offset: '+03:00'},
  {country_code: 'KZ', name: 'Asia/Almaty', utc_offset: '+06:00'},
  {country_code: 'JO', name: 'Asia/Amman', utc_offset: '+02:00'},
  {country_code: 'RU', name: 'Asia/Anadyr', utc_offset: '+12:00'},
  {country_code: 'KZ', name: 'Asia/Aqtau', utc_offset: '+05:00'},
  {country_code: 'KZ', name: 'Asia/Aqtobe', utc_offset: '+05:00'},
  {country_code: 'TM', name: 'Asia/Ashgabat', utc_offset: '+05:00'},
  {country_code: 'KZ', name: 'Asia/Atyrau', utc_offset: '+05:00'},
  {country_code: 'IQ', name: 'Asia/Baghdad', utc_offset: '+03:00'},
  {country_code: 'BH', name: 'Asia/Bahrain', utc_offset: '+03:00'},
  {country_code: 'AZ', name: 'Asia/Baku', utc_offset: '+04:00'},
  {country_code: 'TH', name: 'Asia/Bangkok', utc_offset: '+07:00'},
  {country_code: 'RU', name: 'Asia/Barnaul', utc_offset: '+07:00'},
  {country_code: 'LB', name: 'Asia/Beirut', utc_offset: '+02:00'},
  {country_code: 'KG', name: 'Asia/Bishkek', utc_offset: '+06:00'},
  {country_code: 'BN', name: 'Asia/Brunei', utc_offset: '+08:00'},
  {country_code: 'RU', name: 'Asia/Chita', utc_offset: '+09:00'},
  {country_code: 'MN', name: 'Asia/Choibalsan', utc_offset: '+08:00'},
  {country_code: 'LK', name: 'Asia/Colombo', utc_offset: '+05:30'},
  {country_code: 'SY', name: 'Asia/Damascus', utc_offset: '+02:00'},
  {country_code: 'BD', name: 'Asia/Dhaka', utc_offset: '+06:00'},
  {country_code: 'TL', name: 'Asia/Dili', utc_offset: '+09:00'},
  {country_code: 'AE', name: 'Asia/Dubai', utc_offset: '+04:00'},
  {country_code: 'TJ', name: 'Asia/Dushanbe', utc_offset: '+05:00'},
  {country_code: 'CY', name: 'Asia/Famagusta', utc_offset: '+02:00'},
  {country_code: 'PS', name: 'Asia/Gaza', utc_offset: '+02:00'},
  {country_code: 'PS', name: 'Asia/Hebron', utc_offset: '+02:00'},
  {country_code: 'VN', name: 'Asia/Ho_Chi_Minh', utc_offset: '+07:00'},
  {country_code: 'HK', name: 'Asia/Hong_Kong', utc_offset: '+08:00'},
  {country_code: 'MN', name: 'Asia/Hovd', utc_offset: '+07:00'},
  {country_code: 'RU', name: 'Asia/Irkutsk', utc_offset: '+08:00'},
  {country_code: 'TR', name: 'Asia/Istanbul', utc_offset: '+03:00'},
  {country_code: 'ID', name: 'Asia/Jakarta', utc_offset: '+07:00'},
  {country_code: 'ID', name: 'Asia/Jayapura', utc_offset: '+09:00'},
  {country_code: 'IL', name: 'Asia/Jerusalem', utc_offset: '+02:00'},
  {country_code: 'AF', name: 'Asia/Kabul', utc_offset: '+04:30'},
  {country_code: 'RU', name: 'Asia/Kamchatka', utc_offset: '+12:00'},
  {country_code: 'PK', name: 'Asia/Karachi', utc_offset: '+05:00'},
  {country_code: 'NP', name: 'Asia/Kathmandu', utc_offset: '+05:45'},
  {country_code: 'RU', name: 'Asia/Khandyga', utc_offset: '+09:00'},
  {country_code: 'IN', name: 'Asia/Kolkata', utc_offset: '+05:30'},
  {country_code: 'RU', name: 'Asia/Krasnoyarsk', utc_offset: '+07:00'},
  {country_code: 'MY', name: 'Asia/Kuala_Lumpur', utc_offset: '+08:00'},
  {country_code: 'MY', name: 'Asia/Kuching', utc_offset: '+08:00'},
  {country_code: 'KW', name: 'Asia/Kuwait', utc_offset: '+03:00'},
  {country_code: 'MO', name: 'Asia/Macau', utc_offset: '+08:00'},
  {country_code: 'RU', name: 'Asia/Magadan', utc_offset: '+11:00'},
  {country_code: 'ID', name: 'Asia/Makassar', utc_offset: '+08:00'},
  {country_code: 'PH', name: 'Asia/Manila', utc_offset: '+08:00'},
  {country_code: 'OM', name: 'Asia/Muscat', utc_offset: '+04:00'},
  {country_code: 'CY', name: 'Asia/Nicosia', utc_offset: '+02:00'},
  {country_code: 'RU', name: 'Asia/Novokuznetsk', utc_offset: '+07:00'},
  {country_code: 'RU', name: 'Asia/Novosibirsk', utc_offset: '+07:00'},
  {country_code: 'RU', name: 'Asia/Omsk', utc_offset: '+06:00'},
  {country_code: 'KZ', name: 'Asia/Oral', utc_offset: '+05:00'},
  {country_code: 'KH', name: 'Asia/Phnom_Penh', utc_offset: '+07:00'},
  {country_code: 'ID', name: 'Asia/Pontianak', utc_offset: '+07:00'},
  {country_code: 'KP', name: 'Asia/Pyongyang', utc_offset: '+09:00'},
  {country_code: 'QA', name: 'Asia/Qatar', utc_offset: '+03:00'},
  {country_code: 'KZ', name: 'Asia/Qostanay', utc_offset: '+06:00'},
  {country_code: 'KZ', name: 'Asia/Qyzylorda', utc_offset: '+05:00'},
  {country_code: 'SA', name: 'Asia/Riyadh', utc_offset: '+03:00'},
  {country_code: 'RU', name: 'Asia/Sakhalin', utc_offset: '+11:00'},
  {country_code: 'UZ', name: 'Asia/Samarkand', utc_offset: '+05:00'},
  {country_code: 'KR', name: 'Asia/Seoul', utc_offset: '+09:00'},
  {country_code: 'CN', name: 'Asia/Shanghai', utc_offset: '+08:00'},
  {country_code: 'SG', name: 'Asia/Singapore', utc_offset: '+08:00'},
  {country_code: 'RU', name: 'Asia/Srednekolymsk', utc_offset: '+11:00'},
  {country_code: 'TW', name: 'Asia/Taipei', utc_offset: '+08:00'},
  {country_code: 'UZ', name: 'Asia/Tashkent', utc_offset: '+05:00'},
  {country_code: 'GE', name: 'Asia/Tbilisi', utc_offset: '+04:00'},
  {country_code: 'IR', name: 'Asia/Tehran', utc_offset: '+03:30'},
  {country_code: 'BT', name: 'Asia/Thimphu', utc_offset: '+06:00'},
  {country_code: 'JP', name: 'Asia/Tokyo', utc_offset: '+09:00'},
  {country_code: 'RU', name: 'Asia/Tomsk', utc_offset: '+07:00'},
  {country_code: 'MN', name: 'Asia/Ulaanbaatar', utc_offset: '+08:00'},
  {country_code: 'CN', name: 'Asia/Urumqi', utc_offset: '+06:00'},
  {country_code: 'RU', name: 'Asia/Ust-Nera', utc_offset: '+10:00'},
  {country_code: 'LA', name: 'Asia/Vientiane', utc_offset: '+07:00'},
  {country_code: 'RU', name: 'Asia/Vladivostok', utc_offset: '+10:00'},
  {country_code: 'RU', name: 'Asia/Yakutsk', utc_offset: '+09:00'},
  {country_code: 'MM', name: 'Asia/Yangon', utc_offset: '+06:30'},
  {country_code: 'RU', name: 'Asia/Yekaterinburg', utc_offset: '+05:00'},
  {country_code: 'AM', name: 'Asia/Yerevan', utc_offset: '+04:00'},
  {country_code: 'PT', name: 'Atlantic/Azores', utc_offset: '-01:00'},
  {country_code: 'BM', name: 'Atlantic/Bermuda', utc_offset: '-04:00'},
  {country_code: 'ES', name: 'Atlantic/Canary', utc_offset: '+00:00'},
  {country_code: 'CV', name: 'Atlantic/Cape_Verde', utc_offset: '-01:00'},
  {country_code: 'FO', name: 'Atlantic/Faroe', utc_offset: '+00:00'},
  {country_code: 'PT', name: 'Atlantic/Madeira', utc_offset: '+00:00'},
  {country_code: 'IS', name: 'Atlantic/Reykjavik', utc_offset: '+00:00'},
  {country_code: 'GS', name: 'Atlantic/South_Georgia', utc_offset: '-02:00'},
  {country_code: 'SH', name: 'Atlantic/St_Helena', utc_offset: '+00:00'},
  {country_code: 'FK', name: 'Atlantic/Stanley', utc_offset: '-03:00'},
  {country_code: 'AU', name: 'Australia/Adelaide', utc_offset: '+09:30'},
  {country_code: 'AU', name: 'Australia/Brisbane', utc_offset: '+10:00'},
  {country_code: 'AU', name: 'Australia/Broken_Hill', utc_offset: '+09:30'},
  {country_code: 'AU', name: 'Australia/Darwin', utc_offset: '+09:30'},
  {country_code: 'AU', name: 'Australia/Eucla', utc_offset: '+08:45'},
  {country_code: 'AU', name: 'Australia/Hobart', utc_offset: '+10:00'},
  {country_code: 'AU', name: 'Australia/Lindeman', utc_offset: '+10:00'},
  {country_code: 'AU', name: 'Australia/Lord_Howe', utc_offset: '+10:30'},
  {country_code: 'AU', name: 'Australia/Melbourne', utc_offset: '+10:00'},
  {country_code: 'AU', name: 'Australia/Perth', utc_offset: '+08:00'},
  {country_code: 'AU', name: 'Australia/Sydney', utc_offset: '+10:00'},
  {country_code: '', name: 'Etc/GMT', utc_offset: '+00:00'},
  {country_code: '', name: 'Etc/GMT-0', utc_offset: '+00:00'},
  {country_code: '', name: 'Etc/GMT-1', utc_offset: '+01:00'},
  {country_code: '', name: 'Etc/GMT-10', utc_offset: '+10:00'},
  {country_code: '', name: 'Etc/GMT-11', utc_offset: '+11:00'},
  {country_code: '', name: 'Etc/GMT-12', utc_offset: '+12:00'},
  {country_code: '', name: 'Etc/GMT-13', utc_offset: '+13:00'},
  {country_code: '', name: 'Etc/GMT-14', utc_offset: '+14:00'},
  {country_code: '', name: 'Etc/GMT-2', utc_offset: '+02:00'},
  {country_code: '', name: 'Etc/GMT-3', utc_offset: '+03:00'},
  {country_code: '', name: 'Etc/GMT-4', utc_offset: '+04:00'},
  {country_code: '', name: 'Etc/GMT-5', utc_offset: '+05:00'},
  {country_code: '', name: 'Etc/GMT-6', utc_offset: '+06:00'},
  {country_code: '', name: 'Etc/GMT-7', utc_offset: '+07:00'},
  {country_code: '', name: 'Etc/GMT-8', utc_offset: '+08:00'},
  {country_code: '', name: 'Etc/GMT-9', utc_offset: '+09:00'},
  {country_code: '', name: 'Etc/GMT+0', utc_offset: '+00:00'},
  {country_code: '', name: 'Etc/GMT+1', utc_offset: '-01:00'},
  {country_code: '', name: 'Etc/GMT+10', utc_offset: '-10:00'},
  {country_code: '', name: 'Etc/GMT+11', utc_offset: '-11:00'},
  {country_code: '', name: 'Etc/GMT+12', utc_offset: '-12:00'},
  {country_code: '', name: 'Etc/GMT+2', utc_offset: '-02:00'},
  {country_code: '', name: 'Etc/GMT+3', utc_offset: '-03:00'},
  {country_code: '', name: 'Etc/GMT+4', utc_offset: '-04:00'},
  {country_code: '', name: 'Etc/GMT+5', utc_offset: '-05:00'},
  {country_code: '', name: 'Etc/GMT+6', utc_offset: '-06:00'},
  {country_code: '', name: 'Etc/GMT+7', utc_offset: '-07:00'},
  {country_code: '', name: 'Etc/GMT+8', utc_offset: '-08:00'},
  {country_code: '', name: 'Etc/GMT+9', utc_offset: '-09:00'},
  {country_code: '', name: 'Etc/GMT0', utc_offset: '+00:00'},
  {country_code: '', name: 'Etc/UTC', utc_offset: '+00:00'},
  {country_code: 'NL', name: 'Europe/Amsterdam', utc_offset: '+01:00'},
  {country_code: 'AD', name: 'Europe/Andorra', utc_offset: '+01:00'},
  {country_code: 'RU', name: 'Europe/Astrakhan', utc_offset: '+04:00'},
  {country_code: 'GR', name: 'Europe/Athens', utc_offset: '+02:00'},
  {country_code: 'RS', name: 'Europe/Belgrade', utc_offset: '+01:00'},
  {country_code: 'DE', name: 'Europe/Berlin', utc_offset: '+01:00'},
  {country_code: 'SK', name: 'Europe/Bratislava', utc_offset: '+01:00'},
  {country_code: 'BE', name: 'Europe/Brussels', utc_offset: '+01:00'},
  {country_code: 'RO', name: 'Europe/Bucharest', utc_offset: '+02:00'},
  {country_code: 'HU', name: 'Europe/Budapest', utc_offset: '+01:00'},
  {country_code: 'DE', name: 'Europe/Busingen', utc_offset: '+01:00'},
  {country_code: 'MD', name: 'Europe/Chisinau', utc_offset: '+02:00'},
  {country_code: 'DK', name: 'Europe/Copenhagen', utc_offset: '+01:00'},
  {country_code: 'IE', name: 'Europe/Dublin', utc_offset: '+01:00'},
  {country_code: 'GI', name: 'Europe/Gibraltar', utc_offset: '+01:00'},
  {country_code: 'GG', name: 'Europe/Guernsey', utc_offset: '+00:00'},
  {country_code: 'FI', name: 'Europe/Helsinki', utc_offset: '+02:00'},
  {country_code: 'IM', name: 'Europe/Isle_of_Man', utc_offset: '+00:00'},
  {country_code: 'TR', name: 'Europe/Istanbul', utc_offset: '+03:00'},
  {country_code: 'JE', name: 'Europe/Jersey', utc_offset: '+00:00'},
  {country_code: 'RU', name: 'Europe/Kaliningrad', utc_offset: '+02:00'},
  {country_code: 'UA', name: 'Europe/Kiev', utc_offset: '+02:00'},
  {country_code: 'RU', name: 'Europe/Kirov', utc_offset: '+03:00'},
  {country_code: 'PT', name: 'Europe/Lisbon', utc_offset: '+00:00'},
  {country_code: 'SI', name: 'Europe/Ljubljana', utc_offset: '+01:00'},
  {country_code: 'GB', name: 'Europe/London', utc_offset: '+00:00'},
  {country_code: 'LU', name: 'Europe/Luxembourg', utc_offset: '+01:00'},
  {country_code: 'ES', name: 'Europe/Madrid', utc_offset: '+01:00'},
  {country_code: 'MT', name: 'Europe/Malta', utc_offset: '+01:00'},
  {country_code: 'AX', name: 'Europe/Mariehamn', utc_offset: '+02:00'},
  {country_code: 'BY', name: 'Europe/Minsk', utc_offset: '+03:00'},
  {country_code: 'MC', name: 'Europe/Monaco', utc_offset: '+01:00'},
  {country_code: 'RU', name: 'Europe/Moscow', utc_offset: '+03:00'},
  {country_code: 'CY', name: 'Europe/Nicosia', utc_offset: '+02:00'},
  {country_code: 'NO', name: 'Europe/Oslo', utc_offset: '+01:00'},
  {country_code: 'FR', name: 'Europe/Paris', utc_offset: '+01:00'},
  {country_code: 'ME', name: 'Europe/Podgorica', utc_offset: '+01:00'},
  {country_code: 'CZ', name: 'Europe/Prague', utc_offset: '+01:00'},
  {country_code: 'LV', name: 'Europe/Riga', utc_offset: '+02:00'},
  {country_code: 'IT', name: 'Europe/Rome', utc_offset: '+01:00'},
  {country_code: 'RU', name: 'Europe/Samara', utc_offset: '+04:00'},
  {country_code: 'SM', name: 'Europe/San_Marino', utc_offset: '+01:00'},
  {country_code: 'BA', name: 'Europe/Sarajevo', utc_offset: '+01:00'},
  {country_code: 'RU', name: 'Europe/Saratov', utc_offset: '+04:00'},
  {country_code: 'UA', name: 'Europe/Simferopol', utc_offset: '+03:00'},
  {country_code: 'MK', name: 'Europe/Skopje', utc_offset: '+01:00'},
  {country_code: 'BG', name: 'Europe/Sofia', utc_offset: '+02:00'},
  {country_code: 'SE', name: 'Europe/Stockholm', utc_offset: '+01:00'},
  {country_code: 'EE', name: 'Europe/Tallinn', utc_offset: '+02:00'},
  {country_code: 'AL', name: 'Europe/Tirane', utc_offset: '+01:00'},
  {country_code: 'RU', name: 'Europe/Ulyanovsk', utc_offset: '+04:00'},
  {country_code: 'UA', name: 'Europe/Uzhgorod', utc_offset: '+02:00'},
  {country_code: 'LI', name: 'Europe/Vaduz', utc_offset: '+01:00'},
  {country_code: 'VA', name: 'Europe/Vatican', utc_offset: '+01:00'},
  {country_code: 'AT', name: 'Europe/Vienna', utc_offset: '+01:00'},
  {country_code: 'LT', name: 'Europe/Vilnius', utc_offset: '+02:00'},
  {country_code: 'RU', name: 'Europe/Volgograd', utc_offset: '+03:00'},
  {country_code: 'PL', name: 'Europe/Warsaw', utc_offset: '+01:00'},
  {country_code: 'HR', name: 'Europe/Zagreb', utc_offset: '+01:00'},
  {country_code: 'UA', name: 'Europe/Zaporozhye', utc_offset: '+02:00'},
  {country_code: 'CH', name: 'Europe/Zurich', utc_offset: '+01:00'},
  {country_code: '', name: 'Factory', utc_offset: '+00:00'},
  {country_code: '', name: 'GMT', utc_offset: '+00:00'},
  {country_code: 'MG', name: 'Indian/Antananarivo', utc_offset: '+03:00'},
  {country_code: 'IO', name: 'Indian/Chagos', utc_offset: '+06:00'},
  {country_code: 'CX', name: 'Indian/Christmas', utc_offset: '+07:00'},
  {country_code: 'CC', name: 'Indian/Cocos', utc_offset: '+06:30'},
  {country_code: 'KM', name: 'Indian/Comoro', utc_offset: '+03:00'},
  {country_code: 'TF', name: 'Indian/Kerguelen', utc_offset: '+05:00'},
  {country_code: 'SC', name: 'Indian/Mahe', utc_offset: '+04:00'},
  {country_code: 'MV', name: 'Indian/Maldives', utc_offset: '+05:00'},
  {country_code: 'MU', name: 'Indian/Mauritius', utc_offset: '+04:00'},
  {country_code: 'YT', name: 'Indian/Mayotte', utc_offset: '+03:00'},
  {country_code: 'RE', name: 'Indian/Reunion', utc_offset: '+04:00'},
  {country_code: 'WS', name: 'Pacific/Apia', utc_offset: '+13:00'},
  {country_code: 'NZ', name: 'Pacific/Auckland', utc_offset: '+12:00'},
  {country_code: 'PG', name: 'Pacific/Bougainville', utc_offset: '+11:00'},
  {country_code: 'NZ', name: 'Pacific/Chatham', utc_offset: '+12:45'},
  {country_code: 'FM', name: 'Pacific/Chuuk', utc_offset: '+10:00'},
  {country_code: 'CL', name: 'Pacific/Easter', utc_offset: '-06:00'},
  {country_code: 'VU', name: 'Pacific/Efate', utc_offset: '+11:00'},
  {country_code: 'KI', name: 'Pacific/Enderbury', utc_offset: '+13:00'},
  {country_code: 'TK', name: 'Pacific/Fakaofo', utc_offset: '+13:00'},
  {country_code: 'FJ', name: 'Pacific/Fiji', utc_offset: '+12:00'},
  {country_code: 'TV', name: 'Pacific/Funafuti', utc_offset: '+12:00'},
  {country_code: 'EC', name: 'Pacific/Galapagos', utc_offset: '-06:00'},
  {country_code: 'PF', name: 'Pacific/Gambier', utc_offset: '-09:00'},
  {country_code: 'SB', name: 'Pacific/Guadalcanal', utc_offset: '+11:00'},
  {country_code: 'GU', name: 'Pacific/Guam', utc_offset: '+10:00'},
  {country_code: 'US', name: 'Pacific/Honolulu', utc_offset: '-10:00'},
  {country_code: 'KI', name: 'Pacific/Kiritimati', utc_offset: '+14:00'},
  {country_code: 'FM', name: 'Pacific/Kosrae', utc_offset: '+11:00'},
  {country_code: 'MH', name: 'Pacific/Kwajalein', utc_offset: '+12:00'},
  {country_code: 'MH', name: 'Pacific/Majuro', utc_offset: '+12:00'},
  {country_code: 'PF', name: 'Pacific/Marquesas', utc_offset: '-09:30'},
  {country_code: 'UM', name: 'Pacific/Midway', utc_offset: '-11:00'},
  {country_code: 'NR', name: 'Pacific/Nauru', utc_offset: '+12:00'},
  {country_code: 'NU', name: 'Pacific/Niue', utc_offset: '-11:00'},
  {country_code: 'NF', name: 'Pacific/Norfolk', utc_offset: '+11:00'},
  {country_code: 'NC', name: 'Pacific/Noumea', utc_offset: '+11:00'},
  {country_code: 'AS', name: 'Pacific/Pago_Pago', utc_offset: '-11:00'},
  {country_code: 'PW', name: 'Pacific/Palau', utc_offset: '+09:00'},
  {country_code: 'PN', name: 'Pacific/Pitcairn', utc_offset: '-08:00'},
  {country_code: 'FM', name: 'Pacific/Pohnpei', utc_offset: '+11:00'},
  {country_code: 'PG', name: 'Pacific/Port_Moresby', utc_offset: '+10:00'},
  {country_code: 'CK', name: 'Pacific/Rarotonga', utc_offset: '-10:00'},
  {country_code: 'MP', name: 'Pacific/Saipan', utc_offset: '+10:00'},
  {country_code: 'PF', name: 'Pacific/Tahiti', utc_offset: '-10:00'},
  {country_code: 'KI', name: 'Pacific/Tarawa', utc_offset: '+12:00'},
  {country_code: 'TO', name: 'Pacific/Tongatapu', utc_offset: '+13:00'},
  {country_code: 'UM', name: 'Pacific/Wake', utc_offset: '+12:00'},
  {country_code: 'WF', name: 'Pacific/Wallis', utc_offset: '+12:00'},
  {country_code: '', name: 'UTC', utc_offset: '+00:00'},
]

export const TIME_ZONE_OPTIONS = TIME_ZONES.map(({name, utc_offset}) => ({
  value: name,
  display: `${name} (${utc_offset})`,
}))

export const TIME_ZONES_BY_NAME = TIME_ZONES.reduce((prev, timeZone) => {
  prev[timeZone.name] = timeZone

  return prev
}, {})
