import PropTypes from 'prop-types'
import {Component} from 'react'

import TextInput from '../../../../common/components/Form/TextInput.js'
import TextArea from '../../../../common/components/Form/TextArea.js'
import Row from '../../../../common/components/Form/Row.js'

export default class EditOrderLine extends Component {
  onChange = (update) => {
    const dataIndex = this.props.dataIndex
    const lineIndex = this.props.lineIndex
    const lines = [...this.props.lines]

    lines[lineIndex] = {
      ...lines[lineIndex],
      ...update,
    }

    this.props.updatePayload(dataIndex, {lines})
  }

  onSKUChange = (value) => {
    this.onChange({product_sku: value})
  }
  onNameChange = (value) => {
    this.onChange({product_name: value})
  }
  onItemPriceChange = (value) => {
    this.onChange({item_price: value})
  }
  onQuantityChange = (value) => {
    this.onChange({quantity: value})
  }
  onTotalPriceChange = (value) => {
    this.onChange({total_price: value})
  }
  onSelectedOptionChange = (value) => {
    this.onChange({selected_option: value})
  }

  render() {
    const index = this.props.lineIndex
    const line = this.props.lines[index]
    const errors = this.props.errors

    return (
      <div key={index} className="wrap--edit-order-line">
        <Row>
          <TextInput
            value={line.product_sku}
            errorMessage={errors.product_sku}
            name={`${index}_product_sku`}
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={line.product_name}
            errorMessage={errors.product_name}
            name={`${index}_product_name`}
            label="Name"
            className="medium-12"
            onChange={this.onNameChange}
          />
        </Row>
        <Row>
          <TextInput
            value={line.item_price}
            errorMessage={errors.item_price}
            name={`${index}_item_price`}
            label="Price"
            className="medium-6"
            onChange={this.onItemPriceChange}
          />
          <TextInput
            value={line.quantity}
            errorMessage={errors.quantity}
            name={`${index}_quantity`}
            label="Quantity"
            className="medium-6"
            onChange={this.onQuantityChange}
          />
        </Row>
        <Row>
          <TextInput
            value={line.discount_amount}
            errorMessage={errors.discount_amount}
            name={`${index}_discount_amount`}
            label="Discount"
            className="medium-6"
            onChange={(value) => {
              this.onChange({discount_amount: value})
            }}
          />
          <TextInput
            value={line.total_price}
            errorMessage={errors.total_price}
            name={`${index}_total_price`}
            label="Total Price"
            className="medium-6"
            onChange={this.onTotalPriceChange}
          />
        </Row>
        <Row>
          <TextArea
            value={line.selected_option}
            errorMessage={errors.selected_option}
            name={`${index}_selected_option`}
            rows="3"
            label="Notes"
            className="medium-12"
            onChange={this.onSelectedOptionChange}
          />
        </Row>
        <Row>
          <TextArea
            value={line.product_serial_numbers}
            errorMessage={errors.product_serial_numbers}
            name={`${index}_product_serial_numbers`}
            rows="1"
            label="Serial Numbers (for multiple, separate with line breaks)"
            className="medium-12"
            onChange={(value) => this.onChange({product_serial_numbers: value})}
          />
        </Row>
      </div>
    )
  }
}

EditOrderLine.propTypes = {
  dataIndex: PropTypes.number.isRequired,
  lines: PropTypes.array.isRequired,
  lineIndex: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
