import isEmpty from 'lodash/isEmpty.js'

import {getState} from '../../../../../store.js'
import {
  isPresent,
  isPositiveNumeric,
  isNumeric,
} from '../../../../../common/utils.js'
import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'
import {usesInventorySelector} from '../../../../../data/company.js'
import {
  warehousesSelector,
  warehousesByNameSelector,
} from '../../../../../data/warehouses.js'

export default class inventoryStrategy extends baseStrategy {
  static title = 'Update Inventory'
  static requiredColumns = ['sku', 'warehouse']

  static getExampleRows() {
    return (dispatch, getState) => {
      const warehouses = warehousesSelector(getState())
      const usesInventory = usesInventorySelector(getState())

      return api.get('/product/', {limit: 5}).then(({json: data}) => {
        const rows = [
          usesInventory
            ? [
                'SKU *Req',
                'Warehouse *Req',
                'Location in Warehouse',
                'Physical On Hand',
                'On Hand Adjustment',
                'Low Stock Threshold',
              ]
            : ['SKU *Req', 'Warehouse *Req', 'Location in Warehouse'],
        ]

        data.product.forEach((product) => {
          product.warehouses.forEach((warehouse) => {
            rows.push(
              usesInventory
                ? [
                    product.sku,
                    warehouses[warehouse.id].address.name,
                    warehouse.location_in_warehouse,
                    warehouse.on_hand,
                    0,
                    warehouse.low_stock_threshold,
                  ]
                : [
                    product.sku,
                    warehouses[warehouse.id].address.name,
                    warehouse.location_in_warehouse,
                  ],
            )
          })
        })

        return rows
      })
    }
  }

  static guessAtColumnDataMap(columns) {
    const usesInventory = usesInventorySelector(getState())

    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/sku/i),
      warehouse: find(/^warehouse$/i),
      location_in_warehouse: find(/location.in.warehouse/i),
    }

    if (usesInventory) {
      columnsToDataMap.on_hand = find(/on.hand$/i)
      columnsToDataMap.adjustment = find(/adjustment/i)
      columnsToDataMap.low_stock_threshold = find(/low.stock.threshold/i)
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const warehousesByName = warehousesByNameSelector(getState())
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          warehousesByName,
        )
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData(row, columnsByName, warehousesByName) {
    const data = this.getNewData()
    const warehouse = warehousesByName[row[columnsByName.warehouse]] || {
      id: '-1',
    }

    data.payload = {
      sku: row[columnsByName.sku],
      id: warehouse.id,
      location_in_warehouse: row[columnsByName.location_in_warehouse],
      on_hand: row[columnsByName.on_hand],
      adjustment: row[columnsByName.adjustment],
      low_stock_threshold: row[columnsByName.low_stock_threshold],
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    if (payload.id === '-1') {
      errors.id = 'Select a valid warehouse'
    } else {
      delete errors.id
    }

    if (
      isPresent(payload.low_stock_threshold) &&
      !isPositiveNumeric(payload.low_stock_threshold)
    ) {
      errors.low_stock_threshold = 'Threshold must be a positive number'
    } else {
      delete errors.low_stock_threshold
    }

    if (
      isPresent(payload.on_hand) &&
      isNumeric(payload.adjustment) &&
      Number(payload.adjustment) !== 0
    ) {
      errors.on_hand = 'On Hand can’t be set with Adjustment'
    } else {
      delete errors.on_hand
    }

    if (isPresent(payload.adjustment) && !isNumeric(payload.adjustment)) {
      errors.adjustment = 'Adjustment must be a number'
    } else {
      delete errors.adjustment
    }

    return errors
  }

  static save(data) {
    return () => {
      const {
        sku,
        id,
        location_in_warehouse,
        low_stock_threshold,
        adjustment,
        on_hand,
      } = data.payload
      const url = `/product/${encodeURIComponent(sku)}/warehouse/${id}/`

      const params = {}

      if (location_in_warehouse !== undefined) {
        params.location_in_warehouse = location_in_warehouse
      }

      if (isPositiveNumeric(low_stock_threshold)) {
        params.low_stock_threshold = Number(low_stock_threshold)
      }

      if (isNumeric(adjustment) && Number(adjustment) !== 0) {
        params.adjustment = Number(adjustment)
      }

      if (isNumeric(on_hand) && !params.adjustment) {
        params.on_hand = Number(on_hand)
      }

      if (isEmpty(params)) {
        return Promise.resolve()
      }

      return api.put(url, params)
    }
  }
}
