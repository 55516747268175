import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'

import Form from './Form.js'
import {useSelector} from '../../../store.js'
import {
  suppliersFormSelector,
  errorsSelector,
  saveSupplier,
} from './suppliersFunctions.js'

export default function NewForm() {
  const form = useSelector(suppliersFormSelector)
  const errors = useSelector(errorsSelector)
  const isNew = NEW_ID === form.id

  return (
    <li className="settings-list-item add-item clearfix expanded">
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={`#/settings/supplier/${NEW_ID}`}
            title="Add a supplier"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Add a supplier</span>
          </a>
        </p>
      </div>
      {isNew && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {errors.serverError && (
              <small className="error error-message">
                {errors.serverError}
              </small>
            )}
            <Form errors={errors} isOriginalDefault={false} />
            <div className="clear-left">
              <div className="medium-5 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  disabled={errors.preventSave || form.isSaving}
                  onClick={saveSupplier}
                >
                  Save
                </button>
                <a
                  href="#/settings/supplier"
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </li>
  )
}
