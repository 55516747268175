import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {saveShipperEndicia} from '../../../../redux/actions/ui/settings/shippers/forms/endicia.js'
import {showEndiciaCreditCardModal} from '../../../../redux/actions/ui/modals/endiciaCreditCardModal.js'
import {endiciaAccountErrorSelector} from '../../../../redux/selectors/ui/index.js'
import {hasErrorsSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import PostageBalance from './PostageBalance.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function EndiciaForm({
  form: {id, isSaving},
  hasErrors,
  endiciaAccountError,
  ...props
}) {
  if (id === NEW_ID) {
    return null
  }

  return (
    <div className="settings-list-item-form-wrap settings-list-item-form-usps clear-both">
      <fieldset>
        <ul className="form-list medium-5 columns margin-bottom-0">
          <Nickname shipperID={id} />
          <ShippingMethods shipperID={id} />
          <li className="list__item list__item--no-style divider--bottom padding-bottom-20">
            <ButtonPrimary
              className="btn--primary-altm"
              isLoading={isSaving}
              isDisabled={hasErrors}
              onClick={() => props.saveShipperEndicia(id)}
            >
              Update Nickname
            </ButtonPrimary>
          </li>
        </ul>
      </fieldset>
      <fieldset className="margin-bottom-0">
        {!endiciaAccountError && (
          <div className="medium-5 columns">
            <PostageBalance shipperID={id} />
          </div>
        )}
        {!!endiciaAccountError && (
          <div className="medium-7 columns">
            <div className="wrap--endicia-suspended">
              <dl className="list--endicia-suspended">
                <dt>Your USPS account has been suspended.</dt>
                <dd className="margin-bottom-0">
                  {'To unlock it, please '}
                  <a
                    className="link--endicia-suspended"
                    href="http://support.ordoro.com/what-to-do-when-your-endicia-account-gets-suspended/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    follow these instructions
                  </a>
                  {'.'}
                </dd>
              </dl>
            </div>
          </div>
        )}
      </fieldset>
      <div className="medium-5 columns postage-wrap">
        <div className="postage-add margin-top-0">
          <ButtonPrimary
            size="sm"
            isOutlined
            onClick={() => props.showEndiciaCreditCardModal(id)}
          >
            Update Credit Card
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

EndiciaForm.propTypes = {
  form: ShipperFormShape,
  endiciaAccountError: PropTypes.string,
  hasErrors: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  saveShipperEndicia: PropTypes.func.isRequired,
  showEndiciaCreditCardModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    endiciaAccountError: endiciaAccountErrorSelector(state),
    hasErrors: hasErrorsSelector(state, {form}),
  }
}

const mapDispatchToProps = {
  saveShipperEndicia,
  showEndiciaCreditCardModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(EndiciaForm)
