import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'

import {filterOptions} from '../../../tokenizeOptions.js'
import {SelectOptionsShape, SelectOptionTokens} from '../../../PropTypes.js'
import {updateForm, useAutoForm, useSelector} from '../../../../store.js'
import FilterDropdown from '../../FilterDropdown.js'
import {useZropdown} from '../../Zropdown.js'
import {currentDropdownSelector} from '../../../../redux/selectors/ui/index.js'
import Item from './Item.js'
import FilterOperator from './FilterOperator.js'
import UntaggedItem from './UntaggedItem.js'

function filterTags(dropdown, tagOptions, tagOptionTokens, text) {
  try {
    const untaggedOption = {
      entity: {},
      type: 'untagged',
    }

    if (!text) {
      updateForm(dropdown, {
        results: [untaggedOption, ...tagOptions],
        hasFoundNothing: false,
      })

      return
    }

    const results = filterOptions(text, tagOptionTokens)

    updateForm(dropdown, {
      results: [untaggedOption, ...results],
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

export default function FilterTagFilter({
  dropdown,
  selectedTags,
  tagOptions,
  tagOptionTokens,
  untaggedSelected,
  filterOperator,
  hasManageTagPermission,
  willApplyCreatedTag,
  onCreate,
  onSelect,
  onTagFilterOperatorChange,
  onToggleUntaggedFilter,
  autoFocus,
}) {
  dropdown = dropdown || 'FILTER_TAG_FILTER'

  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
  })

  useEffect(() => {
    filterTags(dropdown, tagOptions, tagOptionTokens, form && form.text)
  }, [tagOptions, form && form.text])

  const buttonRef = useRef()

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onSelect: (option) => {
        if (option.type === 'untagged') {
          if (selectedTags.length === 0) {
            onToggleUntaggedFilter(!untaggedSelected)
          }
        } else if (!untaggedSelected) {
          onSelect(option.entity)
        }
      },
      autoFocus,
    })

  const currentDropdown = useSelector(currentDropdownSelector)

  return (
    <li className="inline-block wrap--outer-tag-filters margin-left-10">
      <div
        className={`wrap-outer--tag-dropdown-with-filter ${
          currentDropdown === dropdown ? 'open' : ''
        }`}
        data-dropdown={dropdown}
      >
        <button
          id={dropdown}
          ref={buttonRef}
          className="btn btn--dropdown btn--filter"
          type="button"
        >
          <span className="btn__text">Tags</span>
        </button>
        <FilterDropdown
          dropdown={dropdown}
          form={form}
          onChangeInternal={onChangeInternal}
          onConfirm={onConfirm}
          onUp={onUp}
          onDown={onDown}
          placeholder="Search tags"
          includeSearchInput
          parentRef={buttonRef}
          additionalHeader={
            tagOptions.length === 0 ? null : (
              <>
                <FilterOperator
                  filterOperator={filterOperator}
                  tagCount={tagOptions.length}
                  onTagFilterOperatorChange={onTagFilterOperatorChange}
                />
              </>
            )
          }
          footer={
            onCreate && hasManageTagPermission ? (
              <div className="list__item--dropdown list__item--dropdown-last">
                <button
                  className="btn--link list__link list__link--dropdown"
                  type="button"
                  onClick={() => onCreate()}
                  disabled={untaggedSelected}
                >
                  {willApplyCreatedTag ? (
                    <span>Create and apply a new tag</span>
                  ) : (
                    <span>Create a new tag</span>
                  )}
                </button>
              </div>
            ) : null
          }
        >
          {tagOptions.length === 0 ? (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              You haven’t created any tags, yet!
            </li>
          ) : (
            <>
              <UntaggedItem
                selected={untaggedSelected}
                disabled={selectedTags.length > 0}
                onToggleUntaggedFilter={onToggleUntaggedFilter}
                isFocused={form.selectedIndex === 0}
              />
              {form.results.slice(1).map((option, index) => (
                <Item
                  key={option.value}
                  option={option}
                  selected={selectedTags.includes(option.display)}
                  disabled={untaggedSelected}
                  canEdit={hasManageTagPermission}
                  isFocused={index + 1 === form.selectedIndex}
                  onSelect={onSelectInternal}
                />
              ))}
              {form.hasFoundNothing && (
                <li className="list__item--dropdown list__item--dropdown-empty align-center">
                  No tags match your search.
                </li>
              )}
            </>
          )}
        </FilterDropdown>
      </div>
    </li>
  )
}

FilterTagFilter.propTypes = {
  dropdown: PropTypes.string.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagOptions: SelectOptionsShape.isRequired,
  tagOptionTokens: SelectOptionTokens.isRequired,
  untaggedSelected: PropTypes.bool.isRequired,
  filterOperator: PropTypes.string.isRequired,
  hasManageTagPermission: PropTypes.bool.isRequired,
  willApplyCreatedTag: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTagFilterOperatorChange: PropTypes.func.isRequired,
  onToggleUntaggedFilter: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
}
