import {openModal} from '../../ui/index.js'

export const MODAL_NAME = 'PRINT_PICK_PACK_MODAL'
export const PRINT_PICK_PACK_MODAL_FORM = 'PRINT_PICK_PACK_MODAL_FORM'

export const BASE = 'ordoro/ui/modals/printPickPackModal'
export const VIEW_PICK_PACK_DOCUMENT = `${BASE}/VIEW_PICK_PACK_DOCUMENT`
export const SET_ERROR_MESSAGE = `${BASE}/SET_ERROR_MESSAGE`

export function showPrintPickPackModal(orderNumbers) {
  return openModal(MODAL_NAME, {orderNumbers})
}

export function viewPickPackDocument(abodePickPackLink) {
  return {
    type: VIEW_PICK_PACK_DOCUMENT,
    payload: abodePickPackLink,
  }
}

export function setErrorMessage(errorMessage) {
  return {
    type: SET_ERROR_MESSAGE,
    payload: errorMessage,
  }
}
