import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import countBy from 'lodash/countBy.js'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import {
  postAndSetOrderResponse,
  ordersSelector,
  getExpectedRestoreStatus,
} from '../../../data/orders.js'
import {
  Count,
  IfPlural,
  IfSingle,
  Plural,
  plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {buildPath} from '../../../common/querystring.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {ORDER_STATII} from '../../../common/constants/Orders.js'

export const MODAL_FORM = 'RESTORE_ORDER_MODAL'

export async function showRestoreOrderModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export const restoreOrderStatusCountsSelector = createSelector(
  (state) =>
    ordersSelector(state, {
      orderNumbers: modalFormSelector(state).orderNumbers,
    }),
  (orders) => countBy(orders, getExpectedRestoreStatus),
)

export async function restoreOrders() {
  const {orderNumbers} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    await Promise.all(
      orderNumbers.map((orderNumber) =>
        postAndSetOrderResponse(buildPath(['order', orderNumber, 'uncancel'])),
      ),
    )

    showMessageToast(
      plural(orderNumbers)`${Count} ${[
        'orders were',
        'order was',
      ]} successfully restored.`,
    )

    closeModal()

    await refreshOrderListAndCounts()
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
      isSaving: false,
    })
  }
}

function RestoreOrderModal({form}) {
  const restoreOrderStatusCounts = useSelector(restoreOrderStatusCountsSelector)

  const status = Object.keys(restoreOrderStatusCounts)[0]
  const displayName = get(
    ORDER_STATII.find(({slug}) => slug === status),
    'name',
  )

  return (
    <ConfirmModal
      title={plural(form.orderNumbers)`Restore Order${['s']}`}
      modalSize="sm"
      onConfirm={() => restoreOrders()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
    >
      <PluralBlock array={form.orderNumbers}>
        <p className="fs-01 margin-bottom-5">
          Are you sure you want to restore{' '}
          <Plural s="this order" p="these orders" />?
        </p>
        <IfSingle>
          <p>Doing so will move it back to the {displayName} status.</p>
        </IfSingle>
        <IfPlural>
          <>
            <p className="fs-01 margin-bottom-10">
              Doing so will move each one back to a previous status:
            </p>
            <ul className="list list--square margin-bottom-0">
              {Object.entries(restoreOrderStatusCounts).map(
                ([status, statusCount]) => {
                  const displayName = get(
                    ORDER_STATII.find(({slug}) => slug === status),
                    'name',
                  )
                  return (
                    <li className="list__item fs-00" key={status}>
                      {statusCount} will move to the {displayName} status
                    </li>
                  )
                },
              )}
            </ul>
          </>
        </IfPlural>
      </PluralBlock>
    </ConfirmModal>
  )
}

RestoreOrderModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(RestoreOrderModal, modalFormSelector)
