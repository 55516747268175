import {useEffect} from 'react'

import {ReturnOrderListFormShape} from '../../common/PropTypes.js'
import {returnOrderListFormSelector} from './returnOrderListSelectors.js'

import Modals from './Modals/index.js'
import ReturnOrderListItemsCount from './ReturnOrderListItemsCount.js'
import RefreshReturnOrders from './RefreshReturnOrders.js'
import ReturnOrderExpandAll from './ReturnOrderExpandAll.js'
import ReturnOrderSort from './ReturnOrderSort.js'
import ReturnOrderTagFiltersDropdown from './ReturnOrderTagFiltersDropdown.js'
import Actions from './Actions.js'
import ActionsForSelected from './ActionsForSelected.js'
import List from './List.js'
import ReturnOrderListOmniBar from './ReturnOrderListOmniBar.js'
import ReturnOrderStatusDropdown from './ReturnOrderStatusDropdown.js'
import {onlyIfForm, useSelector} from '../../store.js'
import {hasReturnOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {hasUseReturnOrdersFeatureSelector} from '../../data/company.js'

function ReturnOrderListPage({form}) {
  useEffect(() => {
    document.title = 'Returns/RMAs'
  }, [])

  const hasReturnOrderPermission = useSelector(hasReturnOrderPermissionSelector)
  const hasUseReturnOrdersFeature = useSelector(
    hasUseReturnOrdersFeatureSelector,
  )

  if (!hasUseReturnOrdersFeature) {
    return null
  }

  if (!hasReturnOrderPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <ReturnOrderListItemsCount />
                <RefreshReturnOrders />
                <ReturnOrderExpandAll />
                <li className="inline-block fs-n0 v-align-middle divider--left">
                  <strong>Filter:</strong>
                </li>
                <ReturnOrderStatusDropdown />
                <ReturnOrderTagFiltersDropdown />
                <ReturnOrderSort />
                {form.selectedReferenceIDs.length ? (
                  <ActionsForSelected
                    referenceIDs={form.selectedReferenceIDs}
                  />
                ) : (
                  <Actions />
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <ReturnOrderListOmniBar />
          </div>
        </div>
        <div className="wrap--row margin-top-10">
          <List />
        </div>
      </div>
    </div>
  )
}

ReturnOrderListPage.propTypes = {
  form: ReturnOrderListFormShape.isRequired,
}

export default onlyIfForm(ReturnOrderListPage, returnOrderListFormSelector)
