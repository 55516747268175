import {useState} from 'react'
import isEqual from 'lodash/isEqual.js'
import uniq from 'lodash/uniq.js'

import {
  PURCHASE_ORDER_STATII,
  PURCHASE_ORDER_STATUS_NAMES,
  SENT_STATUS,
} from '../../common/constants/PurchaseOrders.js'
import OmniBar, {parseInputValue} from '../../common/components/List/OmniBar.js'
import {
  setStatus,
  setSearchText,
  navigateToPOListPage,
  updatePOExcludeTags,
  updatePOTagFilters,
  updatePOUntagged,
  navigatePOList,
  setPOTagFilters,
  setPOUntagged,
  setPOExcludeTags,
  setPOTagFilterBy,
  updateStatus,
  setSupplierFilters,
  setWarehouseFilters,
  removeSupplierFilter,
  removeWarehouseFilter,
} from './purchaseOrderListActions.js'
import {poListQuerySelector} from './purchaseOrderListSelectors.js'
import {getState, useSelector} from '../../store.js'
import {poTagsSortedByNameSelector} from '../../data/poTags.js'
import {
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_OR,
} from '../../common/constants/Tags.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../data/suppliers.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../data/warehouses.js'

export const SEARCH_TYPE = 'search'
export const STATUS_TYPE = 'status'
export const TAG_TYPE = 'tag'
export const TAG_AND_TYPE = 'tag_and'
export const TAG_ONLY_TYPE = 'tag_only'
const TAG_TYPES = {
  [TAG_FILTER_OR]: TAG_TYPE,
  [TAG_FILTER_AND]: TAG_AND_TYPE,
  [TAG_FILTER_ONLY]: TAG_ONLY_TYPE,
}
export const UNTAGGED_TYPE = 'untagged'
export const EXCLUDE_TAGS_TYPE = 'exclude_tags'
export const SUPPLIER_TYPE = 'supplier'
export const WAREHOUSE_TYPE = 'warehouse'

export function poOmniBarFilterListSelector(state) {
  const cache = poOmniBarFilterListSelector.cache
  const poTags = poTagsSortedByNameSelector(state)
  const suppliers = suppliersSortedByNameSelector(state)
  const warehouses = warehousesSortedByNameSelector(state)
  const query = poListQuerySelector(state)

  if (
    cache &&
    isEqual(poTags, cache.poTags) &&
    isEqual(suppliers, cache.suppliers) &&
    isEqual(warehouses, cache.warehouses) &&
    query === cache.query
  ) {
    return cache.list
  }

  poOmniBarFilterListSelector.cache = {
    poTags,
    suppliers,
    warehouses,
    query,
  }

  const list = []

  for (const s of query.status) {
    list.push({
      type: STATUS_TYPE,
      label: PURCHASE_ORDER_STATUS_NAMES[s],
      value: s,
    })
  }

  const tagType = TAG_TYPES[query.tag_filter_by]
  for (const tag of poTags) {
    if (!query.tags.includes(tag.name)) {
      continue
    }

    list.push({
      type: tagType,
      label: tag.name,
      value: `${tagType}:${tag.id}`,
      color: tag.color,
      name: tag.name,
    })
  }

  if (query.untagged) {
    list.push({
      type: UNTAGGED_TYPE,
      label: 'Untagged',
      value: 'untagged',
    })
  }

  for (const tag of poTags) {
    if (!query.exclude_tags.includes(tag.name)) {
      continue
    }

    list.push({
      type: EXCLUDE_TAGS_TYPE,
      label: tag.name,
      value: `${EXCLUDE_TAGS_TYPE}:${tag.id}`,
      color: tag.color,
      name: tag.name,
    })
  }

  suppliers.forEach((supplier) => {
    if (!query.supplier_id.includes(supplier.id)) {
      return
    }

    list.push({
      type: SUPPLIER_TYPE,
      label: getSupplierName(supplier),
      value: `${SUPPLIER_TYPE}:${supplier.id}`,
      id: supplier.id,
    })
  })

  warehouses.forEach((warehouse) => {
    if (!query.warehouse_id.includes(warehouse.id)) {
      return
    }

    list.push({
      type: WAREHOUSE_TYPE,
      label: getWarehouseName(warehouse),
      value: `${WAREHOUSE_TYPE}:${warehouse.id}`,
      id: warehouse.id,
    })
  })

  if (query.searchText) {
    list.push({
      type: SEARCH_TYPE,
      label: query.searchText,
      value: query.searchText,
    })
  }

  poOmniBarFilterListSelector.cache.list = list

  return list
}

export const PROCESS_VALUE_FUNCTIONS = {
  processStatusFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^status$/i) && ['', '-', '!'].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const statusMatch = filterValue.match(/^sent$/i)
      ? PURCHASE_ORDER_STATII.find(({slug}) => slug === SENT_STATUS) // So unsent does match on `sent`
      : PURCHASE_ORDER_STATII.find(({slug, name}) =>
          `${slug} ${name}`.match(RegExp(filterValue, 'i')),
        )

    if (statusMatch) {
      const status = poListQuerySelector(getState()).status.filter(
        (s) => s !== statusMatch.slug,
      )

      if (!['-', '!'].includes(filterOperator)) {
        status.push(statusMatch.slug)
      }

      outcome.hash = setStatus(status)
    }

    return outcome
  },

  processTagFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag)[s]?$/i) &&
        ['', '=', '-', '!', '!='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const poTags = poTagsSortedByNameSelector(getState())

    const matchedTags = poTags.filter((t) => {
      if (['=', '!='].includes(filterOperator)) {
        if (t.id === Number(filterValue)) {
          return true
        }
        if (t.name === filterValue) {
          return true
        }
      } else {
        if (`${t.name}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    const negate = ['!', '-', '!='].includes(filterOperator)

    if (matchedTags.length) {
      const {tags, exclude_tags} = poListQuerySelector(getState())
      let updates = updatePOUntagged(false)

      if (negate) {
        updates = {
          ...updates,
          ...updatePOExcludeTags(
            uniq([...exclude_tags, ...matchedTags.map(({name}) => name)]),
          ),
        }
      } else {
        updates = {
          ...updates,
          ...updatePOTagFilters(
            uniq([...tags, ...matchedTags.map(({name}) => name)]),
          ),
        }
      }

      outcome.hash = navigatePOList(updates)
    }

    return outcome
  },

  processUntaggedValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (!filterValue.match(/^untag(ged)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = navigatePOList({
      ...updatePOUntagged(true),
      ...updatePOTagFilters([]),
    })

    return outcome
  },

  processTagFilterByValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag.filter(ing)?.logic|tfl)$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const filterBy = filterValue.match(/^(and|all)$/i)
      ? TAG_FILTER_AND
      : filterValue.match(/^only$/i)
        ? TAG_FILTER_ONLY
        : TAG_FILTER_OR

    outcome.hash = setPOTagFilterBy(filterBy)

    return outcome
  },

  processSupplierFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^supplier[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const suppliers = suppliersSortedByNameSelector(getState())
    const query = poListQuerySelector(getState())

    const matchedSuppliers = suppliers.filter((s) => {
      if (filterOperator === '=') {
        if (s.id === Number(filterValue)) {
          return true
        }
        if (s.address.company === filterValue) {
          return true
        }
      } else {
        if (
          `${s.address.company} ${s.address.city} ${s.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedSuppliers.length) {
      outcome.hash = setSupplierFilters([
        ...query.supplier_id,
        ...matchedSuppliers.map(({id}) => id),
      ])
    }

    return outcome
  },

  processWarehouseFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^warehouse[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const warehouses = warehousesSortedByNameSelector(getState())
    const query = poListQuerySelector(getState())

    const matchedWarehouses = warehouses.filter((w) => {
      if (filterOperator === '=') {
        if (w.id === Number(filterValue)) {
          return true
        }
        if (getWarehouseName(w) === filterValue) {
          return true
        }
      } else {
        if (
          `${w.address.name} ${w.address.company} ${w.address.city} ${w.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedWarehouses.length) {
      outcome.hash = setWarehouseFilters([
        ...query.warehouse_id,
        ...matchedWarehouses.map(({id}) => id),
      ])
    }

    return outcome
  },

  // Put new functions before this function
  processSearchTextFilterValue(inputValue) {
    return {stop: true, hash: setSearchText(inputValue)}
  },
  // Do not put functions after this function
}

export function processInputValue(inputValue, setInputValue) {
  if (!inputValue) {
    setInputValue('')

    return
  }

  const [, filterType, filterOperator, filterValue] =
    parseInputValue(inputValue)

  let hash
  for (const processFilterValue of Object.values(PROCESS_VALUE_FUNCTIONS)) {
    const outcome = processFilterValue(
      inputValue,
      filterType,
      filterOperator,
      filterValue,
    )

    if (outcome.stop) {
      hash = outcome.hash
      break
    }
  }

  if (hash) {
    setInputValue('')

    return hash
  }

  return
}

export function removeValue(value) {
  if (value.type === STATUS_TYPE) {
    const {status} = poListQuerySelector(getState())
    return setStatus(status.filter((s) => s !== value.value))
  } else if ([TAG_TYPE, TAG_AND_TYPE, TAG_ONLY_TYPE].includes(value.type)) {
    const {tags} = poListQuerySelector(getState())
    return setPOTagFilters(tags.filter((name) => name !== value.name))
  } else if (value.type === UNTAGGED_TYPE) {
    return setPOUntagged(false)
  } else if (value.type === EXCLUDE_TAGS_TYPE) {
    const {exclude_tags} = poListQuerySelector(getState())
    return setPOExcludeTags(exclude_tags.filter((name) => name !== value.name))
  } else if (value.type === SUPPLIER_TYPE) {
    return removeSupplierFilter(value.id)
  } else if (value.type === WAREHOUSE_TYPE) {
    return removeWarehouseFilter(value.id)
  } else if (value.type === SEARCH_TYPE) {
    return setSearchText('')
  }
}

export function clearAll() {
  return navigateToPOListPage(updateStatus([]))
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  }
}

export default function POOmniBar() {
  const [inputValue, setInputValue] = useState('')
  const list = useSelector(poOmniBarFilterListSelector)

  return (
    <OmniBar
      placeholder="Enter an Purchase Order ID or Product SKU..."
      inputValue={inputValue}
      value={list}
      onRemoveValue={(removedValue) => removeValue(removedValue)}
      onClear={() => clearAll()}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        processInputValue(inputValue, setInputValue)
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}
