import classNames from 'classnames'

import {
  updateLabelConfig,
  packagingWeightSelector,
} from '../../../data/labelInfos/index.js'

import WeightInput from '../../../common/components/Form/WeightInput.js'
import {useSelector} from '../../../store.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {
  AMAZON_SFP,
  DHL,
  FEDEX,
  PITNEY_CBDS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
} from '../../../common/constants/ShipperNames.js'
import useLabelProperty, {
  EMPTY_DEPENDANT_PROPERTIES_FUNC,
  formatMetaClassName,
} from './useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'packaging_weight'
const VALID_SHIPPER_TYPES = [
  AMAZON_SFP,
  DHL,
  FEDEX,
  PITNEY_CBDS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
]

export default function PackagingWeight() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const packagingWeight = useSelector((state) =>
    packagingWeightSelector(state, {labelInfoID}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY,
    validShipperTypes: VALID_SHIPPER_TYPES,
    dependantPropertiesFunc: EMPTY_DEPENDANT_PROPERTIES_FUNC,
  })

  if (!VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  return (
    <li
      className={classNames(
        'list__item--shipping-options btm-offset-7 meta-labelconfigform-container-packagingweight',
      )}
    >
      <div className="wrap--edit-preset-form-input">
        <label
          className="label--bold"
          htmlFor="labelconfigform-packaging-weight-lb"
        >
          Packaging Weight
        </label>
        <WeightInput
          idLb="labelconfigform-packaging-weight-lb"
          weightOz={packagingWeight}
          setWeightOz={(value) => {
            updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

            onChange()
          }}
          metaClassName={formatMetaClassName(LABEL_PROPERTY)}
          disabled={isLabelEnableMode && !isLabelPropertyEnabled}
        />
      </div>
      <EnableLabelProperty labelProperty={LABEL_PROPERTY} />
    </li>
  )
}
