import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {DateShape} from '../../common/PropTypes.js'
import {usesInventorySelector} from '../../data/company.js'
import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {exportToCSV} from './analyticsActions.js'
import {
  isLoadingAnalyticsSelector,
  startDateSelector,
  endDateSelector,
} from './analyticsSelectors.js'
import {
  revenueOverTimeSelector,
  costsOverTimeSelector,
  revenueAndCostsSelector,
  revenueByStateSelector,
  revenueByCartSelector,
  revenueBySupplierSelector,
} from './financialSelectors.js'
import Panel from './Panel.js'
import Header from './Header.js'
import Filters from './Filters.js'
import CartFilter from './CartFilter.js'
import DateFilter from './DateFilter.js'
import CartActiveFilters from './CartActiveFilters.js'
import SingleValuePane from './SingleValuePane.js'
import MoneyOverTimePane from './MoneyOverTimePane.js'
import RevenueAndCostsStatement from './RevenueAndCostsStatement.js'
import RevenueByStateMap from './RevenueByStateMap.js'
import HorizontalBarPane from './HorizontalBarPane.js'

export function FinancialPanel({
  usesInventory,
  revenueOverTime,
  costsOverTime,
  revenueAndCosts,
  revenueByState,
  revenueByCart,
  revenueBySupplier,
  isLoading,
  startDate,
  endDate,
}) {
  return (
    <Panel>
      <Header>Financial Stats</Header>
      <Filters>
        <CartFilter />
        <DateFilter />
      </Filters>
      <CartActiveFilters />
      <div>
        <div className="row margin-top-25">
          <div className="medium-4 columns">
            <SingleValuePane title="Order Count" isLoading={isLoading}>
              {!isLoading && revenueOverTime && (
                <Quantity value={revenueOverTime.orderCount} />
              )}
            </SingleValuePane>
          </div>
          <div className="medium-4 columns end">
            <SingleValuePane
              title="Average Order Revenue"
              isLoading={isLoading}
            >
              {!isLoading && revenueOverTime && (
                <Currency value={revenueOverTime.averageOrderRevenue} />
              )}
            </SingleValuePane>
          </div>
        </div>
        <MoneyOverTimePane
          title="Revenue"
          isLoading={isLoading}
          data={revenueOverTime}
          startDate={startDate}
          endDate={endDate}
          onExport={() => exportToCSV('revenueOverTime')}
        />
        <MoneyOverTimePane
          title="Costs"
          isLoading={isLoading}
          data={costsOverTime}
          startDate={startDate}
          endDate={endDate}
          onExport={() => exportToCSV('costsOverTime')}
        />
        <RevenueAndCostsStatement
          isLoading={isLoading}
          revenueAndCosts={revenueAndCosts}
          onExport={() => exportToCSV('revenueAndCosts')}
        />
        <RevenueByStateMap
          isLoading={isLoading}
          revenueByState={revenueByState}
          onExport={() => exportToCSV('revenueByState')}
        />
        <div className="row">
          <div className="medium-6 columns">
            <HorizontalBarPane
              isLoading={isLoading}
              title="Revenue by Sales Channel"
              data={revenueByCart}
              isCurrency
              onExport={() => exportToCSV('revenueByCart')}
            />
          </div>
          {usesInventory && (
            <div className="medium-6 columns">
              <HorizontalBarPane
                isLoading={isLoading}
                title="Revenue by Supplier"
                data={revenueBySupplier}
                isCurrency
                onExport={() => exportToCSV('revenueBySupplier')}
              />
            </div>
          )}
        </div>
      </div>
    </Panel>
  )
}

FinancialPanel.propTypes = {
  usesInventory: PropTypes.bool.isRequired,
  revenueOverTime: PropTypes.shape({
    orderCount: PropTypes.number,
    averageOrderRevenue: PropTypes.number,
  }),
  costsOverTime: PropTypes.shape({}),
  revenueAndCosts: PropTypes.shape({}),
  revenueByState: PropTypes.shape({}),
  revenueByCart: PropTypes.shape({}),
  revenueBySupplier: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
  startDate: DateShape,
  endDate: DateShape,
}

function mapStateToProp(state) {
  return {
    usesInventory: usesInventorySelector(state),
    revenueOverTime: revenueOverTimeSelector(state),
    costsOverTime: costsOverTimeSelector(state),
    revenueAndCosts: revenueAndCostsSelector(state),
    revenueByState: revenueByStateSelector(state),
    revenueByCart: revenueByCartSelector(state),
    revenueBySupplier: revenueBySupplierSelector(state),
    isLoading: isLoadingAnalyticsSelector(state),
    startDate: startDateSelector(state),
    endDate: endDateSelector(state),
  }
}

export default connect(mapStateToProp)(FinancialPanel)
