import PropTypes from 'prop-types'

import {FormattedRealDate} from '../../../../../common/components/FormattedDate.js'
import {formatDate, formatDateTimeAndZone} from '../../../../../common/date.js'

export default function ShipByDate({date, isAmazonDate}) {
  const {format, timeZone} = isAmazonDate
    ? {format: formatDate, timeZone: 'America/Los_Angeles'}
    : {format: formatDateTimeAndZone}

  return <FormattedRealDate value={date} format={format} timeZone={timeZone} />
}

ShipByDate.propTypes = {
  date: PropTypes.string.isRequired,
  isAmazonDate: PropTypes.bool,
}
