import {useSelector} from '../../store.js'
import {CREATE_ORDER_SHIPPING_ADDRESS} from '../../common/constants/AddressTypes.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showPasteAddressModal} from '../../common/components/Modal/PasteAddressModal.js'
import {updateCreateOrderFormShippingInfo} from './createOrderActions.js'
import {createOrderFormSelector} from './createOrderSelectors.js'
import AddressFilter from '../../common/components/AddressFilter.js'
import {PRIVACY_MASK} from '../../common/analytics/index.js'

export default function ShippingInfo() {
  const {shippingInfo} = useSelector(createOrderFormSelector)

  return (
    <div className="medium-4 columns wrap--shipto">
      <div className="panel panel--form-wrap">
        <div className="panel__header flex--justify">
          <h3 className="fs-01">Shipping Address</h3>
          <ButtonPrimary
            isOutlined
            size="x-sm"
            onClick={() =>
              showPasteAddressModal({
                addressType: CREATE_ORDER_SHIPPING_ADDRESS,
                address: shippingInfo,
              })
            }
          >
            Paste Address
          </ButtonPrimary>
        </div>
        <div className="panel__body">
          <ul className="list list--form margin-bottom-5">
            <li className="list__item--form">
              <AddressFilter
                label="Name"
                value={shippingInfo.name || ''}
                onSelect={(address) =>
                  updateCreateOrderFormShippingInfo({...address})
                }
                onChange={(name) => updateCreateOrderFormShippingInfo({name})}
                dropdown="CREATE_ORDER_SHIPPING_ADDRESS"
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_shipto_company">
                Company
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_shipto_company"
                value={shippingInfo.company || ''}
                onChange={(event) =>
                  updateCreateOrderFormShippingInfo({
                    company: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_shipto_email">
                Email
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_shipto_email"
                value={shippingInfo.email || ''}
                onChange={(event) =>
                  updateCreateOrderFormShippingInfo({
                    email: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_shipto_phone">
                Phone
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_shipto_phone"
                value={shippingInfo.phone || ''}
                onChange={(event) =>
                  updateCreateOrderFormShippingInfo({
                    phone: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_shipto_street1">
                Street 1
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_shipto_street1"
                value={shippingInfo.street1 || ''}
                onChange={(event) =>
                  updateCreateOrderFormShippingInfo({
                    street1: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_shipto_street2">
                Street 2
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_shipto_street2"
                value={shippingInfo.street2 || ''}
                onChange={(event) =>
                  updateCreateOrderFormShippingInfo({
                    street2: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form flex--justify">
              <div className="wrap--input-two-thirds">
                <label className="label--bold" htmlFor="id_shipto_city">
                  City
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_shipto_city"
                  value={shippingInfo.city || ''}
                  onChange={(event) =>
                    updateCreateOrderFormShippingInfo({
                      city: event.target.value,
                    })
                  }
                />
              </div>
              <div className="wrap--input-third">
                <label className="label--bold" htmlFor="id_shipto_state">
                  State
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_shipto_state"
                  value={shippingInfo.state || ''}
                  onChange={(event) =>
                    updateCreateOrderFormShippingInfo({
                      state: event.target.value,
                    })
                  }
                />
              </div>
            </li>
            <li className="list__item--form flex--justify">
              <div className="wrap--input-half">
                <label className="label--bold" htmlFor="id_shipto_zip">
                  Zipcode
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_shipto_zip"
                  value={shippingInfo.zip || ''}
                  onChange={(event) =>
                    updateCreateOrderFormShippingInfo({
                      zip: event.target.value,
                    })
                  }
                />
              </div>

              <div className="wrap--input-half">
                <label className="label--bold" htmlFor="id_shipto_country">
                  Country
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_shipto_country"
                  value={shippingInfo.country || ''}
                  onChange={(event) =>
                    updateCreateOrderFormShippingInfo({
                      country: event.target.value,
                    })
                  }
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
