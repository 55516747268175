import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {HK_VERIFY_CONTENTS} from '../../../common/constants/HotKeys.js'
import {
  SCANNED_CODE_NOT_IN_ORDER,
  PRODUCT_OVERSCANNED,
  PACK_MODE,
} from '../../../common/constants/PackingOrderModal.js'
import BarcodeScannerListener from '../../../common/components/BarcodeScannerListener.js'
import {
  PluralBlock,
  IfSingle,
  IfPlural,
  Plural,
  Count,
} from '../../../common/components/Plural.js'
import {
  packPanelBarcodeScanned,
  resetPackPanel,
  verifyContents,
  setupPackPanelForm,
  setupPackPanel,
  updatePackPanelForm,
  finishSerialNumberScan,
} from './packPanelActions.js'
import {
  allLinesVerifiedSelector,
  packingOrderScanResultSelector,
  packingOrderScannedCodeSelector,
  totalLineCountSelector,
  verifiedLineCountSelector,
  packPanelIsLoadingSelector,
  PACK_PANEL_NEEDS_SERIAL_NUMBER,
  PACK_PANEL_DUPLICATE_SERIAL_NUMBER,
  packPanelFormSelector,
  SCANNED_CODE_SERIAL_NUMBER_IS_SKU,
} from './packPanelSelectors.js'
import verifiedSoundFilePath from '../../../../images/verified-01.mp3'
import PackError from './PackError.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import useStable from '../../../common/useStable.js'
import {onlyIfAutoForm, useSelector} from '../../../store.js'
import ProductOverscanError from './ProductOverscanError.js'
import PackPanelHeader from './PackPanelHeader.js'
import PackLines from './PackLines.js'
import AudioFile from '../../../common/components/AudioFile.js'
import Quantity from '../../../common/components/Quantity.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import FocusAlert from '../../../common/components/FocusAlert.js'

function PackPanel({orderNumbers, mode, onScan, inModal}) {
  orderNumbers = useStable(orderNumbers)
  const allLinesVerified = useSelector(allLinesVerifiedSelector)
  const scanResult = useSelector(packingOrderScanResultSelector)
  const scannedCode = useSelector(packingOrderScannedCodeSelector)
  const verifiedLineCount = useSelector(verifiedLineCountSelector)
  const totalLineCount = useSelector(totalLineCountSelector)
  const isLoading = useSelector(packPanelIsLoadingSelector)
  const {
    scannedSerialNumber,
    serialNumberNeed,
    serialNumbersForKits,
    serialNumberFocusIndex,
  } = useSelector(packPanelFormSelector)

  useEffect(() => {
    setupPackPanel(orderNumbers)
  }, [orderNumbers])

  return (
    <div className="wrap--row">
      <FocusAlert className="padding-top-10 padding-bottom-20">
        {isLoading ? (
          <div className="loading align-center">
            <span className="list-processing animate-spin v-align-middle" />
          </div>
        ) : scanResult === SCANNED_CODE_NOT_IN_ORDER ? (
          <PackError>
            <PluralBlock array={orderNumbers}>
              <strong>
                “{scannedCode}” isn’t associated with{' '}
                <Plural p="these orders" s="this order" />.
              </strong>
            </PluralBlock>
          </PackError>
        ) : scanResult === PRODUCT_OVERSCANNED ? (
          <ProductOverscanError />
        ) : scanResult === PACK_PANEL_DUPLICATE_SERIAL_NUMBER ? (
          <PackError>
            <strong>
              “{scannedSerialNumber}” has already been already used.
            </strong>
          </PackError>
        ) : scanResult === SCANNED_CODE_SERIAL_NUMBER_IS_SKU ? (
          <PackError>
            <strong>
              “{scannedSerialNumber}” looks like the product SKU and/or UPC.
            </strong>
          </PackError>
        ) : (
          <div>
            <div>
              <BarcodeScannerListener
                onScan={(scannedCode) => {
                  packPanelBarcodeScanned(scannedCode)

                  if (onScan) {
                    onScan(scannedCode)
                  }
                }}
                capturePaste
              />
              {scanResult === PACK_PANEL_NEEDS_SERIAL_NUMBER ? (
                serialNumbersForKits ? (
                  <div className="wrap--kit-serial-numbers">
                    <p className="fs-02 margin-bottom-25 margin-top-0 align-center">
                      <PluralBlock
                        count={serialNumberNeed.reduce(
                          (prev, {quantity}) => prev + quantity,
                          0,
                        )}
                      >
                        <strong>
                          Serial <Plural word="Number" /> Required for These Kit
                          Components
                        </strong>
                      </PluralBlock>
                    </p>
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="table__th table__th--top-border w-1 v-align-middle">
                            &nbsp;
                          </th>
                          <th className="table__th table__th--top-border w-35 v-align-middle">
                            Product Name/SKU
                          </th>
                          <th className="table__th table__th--top-border w-30 v-align-middle align-center">
                            Serial Numbers Remaining
                          </th>
                          <th className="table__th table__th--top-border w-25 v-align-middle align-right padding-right-0">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table__tbody--lines table__tbody--serial-numbers table__tbody--packing-modal table__tbody--v-align-middle">
                        {serialNumberNeed.map(
                          ({name, sku, quantity}, index) => {
                            const selected = index === serialNumberFocusIndex
                            return (
                              <tr
                                key={sku}
                                className={`tr--product-sn ${
                                  selected ? 'tr--product-sn-selected' : ''
                                }`}
                              >
                                <td className="table__td">
                                  <input
                                    className="margin-bottom-0 "
                                    type="checkbox"
                                    checked={selected}
                                    onChange={() =>
                                      updatePackPanelForm({
                                        serialNumberFocusIndex: index,
                                      })
                                    }
                                  />
                                </td>
                                <td className="table__td">
                                  <div className="fs-01 lh-md margin-bottom-3">
                                    <strong>{name}</strong>
                                  </div>
                                  <div className="fs-n0 lh-md unbold">
                                    <strong>SKU:</strong> {sku}
                                  </div>
                                </td>
                                <td className="table__td align-center">
                                  <strong className="fs-02">
                                    <Quantity value={quantity} />
                                  </strong>
                                </td>
                                <td className="table__td align-right">
                                  {selected && (
                                    <div className="alert alert--global-dk alert--sm align-center">
                                      <strong className="fs-n0">
                                        Scan Serial Number Barcode
                                      </strong>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                          },
                        )}
                      </tbody>
                    </table>
                    {!inModal && (
                      <p className="align-center">
                        <ButtonLink onClick={() => finishSerialNumberScan()}>
                          Skip
                        </ButtonLink>
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="alert alert--standard margin-top-20 margin-bottom-20 align-center w-85 margin-auto">
                    <p className="fs-n0 margin-bottom-10 margin-top-15">
                      <strong className="text--blue uppercase">
                        Serial Number Required
                      </strong>
                    </p>
                    {serialNumberNeed.map(({name, sku, quantity}, index) => (
                      <dl className="list margin-bottom-30" key={index}>
                        <dt className="list__title margin-bottom-10">
                          <div className="fs-02 lh-md margin-bottom-3">
                            {name}
                          </div>
                          <div className="fs-00 lh-md unbold">
                            <strong>SKU:</strong> {sku}
                          </div>
                        </dt>
                        <dd className="fs-04 text--black">
                          <span className="side-to-side-right op-30">→</span>{' '}
                          <strong>Scan Serial Number Barcode</strong>{' '}
                          <span className="side-to-side-left op-30">←</span>
                        </dd>
                        <PluralBlock count={quantity}>
                          <dd className="fs-00 lh-sm text--md-grey">
                            Serial numbers remaining to scan: <Count />
                          </dd>
                        </PluralBlock>
                      </dl>
                    ))}
                    {!inModal && (
                      <p>
                        <ButtonLink onClick={() => finishSerialNumberScan()}>
                          Skip
                        </ButtonLink>
                      </p>
                    )}
                  </div>
                )
              ) : !allLinesVerified ? (
                <>
                  <PackPanelHeader
                    orderNumbers={orderNumbers}
                    mode={mode}
                    verifiedLineCount={verifiedLineCount}
                    totalLineCount={totalLineCount}
                  />
                  <div className="row padding-bottom-25">
                    <div className="medium-12 columns">
                      <PackLines />
                    </div>
                  </div>
                </>
              ) : (
                <div className="wrap--scan-alert">
                  <AudioFile src={verifiedSoundFilePath} />
                  <div className="alert alert--success alert--lg align-center margin-bottom-10">
                    <div
                      className="i-check-circle fs-05 op-50 text--green lh-sm margin-bottom-0"
                      aria-hidden="true"
                    />
                    <PluralBlock array={orderNumbers}>
                      <p className="fs-02 lh-md margin-bottom-10">
                        <span>{verifiedLineCount}</span>/
                        <span>{totalLineCount}</span> lines were{' '}
                        {mode === PACK_MODE ? 'verified' : 'picked'} for{' '}
                        <IfSingle>
                          order <strong>{orderNumbers[0]}</strong>
                        </IfSingle>
                        <IfPlural>
                          <Count /> orders
                        </IfPlural>
                        .
                      </p>
                    </PluralBlock>
                    <div className="align-center">
                      <ButtonPrimary
                        className="meta-verify-order-verify-contents-button"
                        disabled={!allLinesVerified}
                        onClick={() => verifyContents()}
                        hotKey={HK_VERIFY_CONTENTS}
                      >
                        Mark as “Contents{' '}
                        {mode === PACK_MODE ? 'Verified' : 'Picked'}”
                      </ButtonPrimary>
                    </div>
                  </div>
                  <div className="align-center">
                    <button
                      className="btn btn--link lighter no-underline margin-bottom-0 meta-verify-order-restart-button"
                      type="button"
                      title={`Reset the ${
                        mode === PACK_MODE ? 'verified' : 'picked'
                      } quantities`}
                      onClick={() => resetPackPanel()}
                    >
                      <span className="i-refresh fs-01 lh-sm v-align-middle inline-block margin-right-1" />
                      <span className="fs-00">Restart</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </FocusAlert>
    </div>
  )
}

PackPanel.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  mode: PropTypes.string.isRequired,
  onScan: PropTypes.func,
  inModal: PropTypes.bool,
}

export default onlyIfAutoForm(PackPanel, setupPackPanelForm)
