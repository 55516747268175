import PropTypes from 'prop-types'

import {NEW_ID} from '../../../../common/constants/index.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {navigate} from '../../../../common/location.js'
import {closeCreatePOModal} from './createPOModalActions.js'

export default function SetupModal({needsWarehouse, needsSupplier}) {
  const needs = [
    ...(needsWarehouse ? ['a warehouse'] : []),
    ...(needsSupplier ? ['a supplier'] : []),
  ]

  return (
    <ConfirmModal
      title="Setup Required"
      modalSize="sm"
      confirmText="Take me there"
      cancelText="Cancel"
      onConfirm={() => {
        closeCreatePOModal()

        if (needsWarehouse) {
          navigate(['settings', 'warehouses', NEW_ID])
        } else if (needsSupplier) {
          navigate(['settings', 'supplier', NEW_ID])
        }
      }}
      onCancel={() => closeCreatePOModal()}
    >
      {'You need to set up '}
      {needs.join(' and ')}
      {' before you can order more products.'}
    </ConfirmModal>
  )
}

SetupModal.propTypes = {
  needsWarehouse: PropTypes.bool.isRequired,
  needsSupplier: PropTypes.bool.isRequired,
}
