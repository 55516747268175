import {createSelector} from 'reselect'

import {formsSelector} from '../../store.js'
import {getLeafComponents} from '../../common/kitGraph.js'
import {stringifyURL} from '../../common/querystring.js'
import {ORDER_SINGLE_URI_COMPONENT} from '../../common/constants/Orders.js'
import {useInventoryAllocationSelector} from '../../data/company.js'
import {
  canUseOrdersSelector,
  allOrdersSelector,
  orderSelector,
  getOrdersActions,
  orderLinesSelector,
} from '../../data/orders.js'
import {canUseReturnOrdersSelector} from '../../redux/selectors/data/returnOrders.js'
import {cartsSelector} from '../../data/carts.js'
import {hasOrderManageRevisionPermissionSelector} from '../../data/me.js'
import {
  orderListFormSelector,
  orderListHashBuilder,
  orderListQuerySelector,
} from '../OrderListPage/orderListSelectors.js'
import {DETAIL_MODE} from '../../common/constants/index.js'

export const ORDER_DETAIL_FORM = 'ORDER_DETAIL_FORM'

export function orderDetailFormSelector(state) {
  return formsSelector(state)[ORDER_DETAIL_FORM]
}

export function orderDetailOrderNumberSelector(state) {
  const query = orderListQuerySelector(state)

  if (query.mode !== DETAIL_MODE) {
    return null
  }

  return query.orderNumber
}

export const orderDetailHashSelector = createSelector(
  (state) => canUseOrdersSelector(state),
  orderDetailOrderNumberSelector,
  (canUseOrders, orderNumber) => {
    if (!canUseOrders || !orderNumber) {
      return null
    }

    return stringifyURL(
      `#/${ORDER_SINGLE_URI_COMPONENT}/${encodeURIComponent(orderNumber)}`,
    )
  },
)

export function orderDetailOrderNotFoundSelector(state) {
  const {notFound} = orderDetailFormSelector(state) || {}

  return !!notFound
}

export function selectedOrderLineIDsSelector(state) {
  const {selectedOrderLineIDs} = orderDetailFormSelector(state) || {}

  return selectedOrderLineIDs || []
}

export function isLoadingExpandedLineSelector(state, {orderLineID}) {
  const {isLoadingExpandedByOrderLineID} = orderDetailFormSelector(state)

  return isLoadingExpandedByOrderLineID[orderLineID] || false
}

export function isExpandedLineSelector(state, {orderLineID}) {
  const {expandedOrderLineIDs} = orderDetailFormSelector(state)

  return expandedOrderLineIDs.includes(orderLineID)
}

export function createLeafProductsSelector(sku) {
  return createSelector(
    (state) => orderDetailFormSelector(state).kitGraphBySKU,
    (kitGraphBySKU) => {
      const kitGraph = kitGraphBySKU[sku]

      if (!kitGraph) {
        return []
      }

      return getLeafComponents(sku, kitGraph)
    },
  )
}

export function orderLineIDsBySKUSelector(state, {sku}) {
  const orderNumber = orderDetailOrderNumberSelector(state)
  const order = orderSelector(state, {orderNumber})

  if (!order) {
    return []
  }

  return order.lines.filter((line) => line.sku === sku).map(({id}) => id)
}

export const orderDetailOrderSelector = createSelector(
  orderDetailOrderNumberSelector,
  (state) => allOrdersSelector(state),
  (orderNumber, orders) => (orderNumber && orders[orderNumber]) || null,
)

export function orderDetailStatusSelector(state) {
  const order = orderDetailOrderSelector(state)

  if (!order) {
    return null
  }

  return order.status
}

export const orderDetailOrderActionsSelector = createSelector(
  orderDetailOrderSelector,
  (state) => canUseReturnOrdersSelector(state),
  cartsSelector,
  useInventoryAllocationSelector,
  hasOrderManageRevisionPermissionSelector,
  (
    order,
    canUseReturnOrders,
    carts,
    useInventoryAllocation,
    hasOrderManageRevisionPermission,
  ) =>
    getOrdersActions(
      [order],
      canUseReturnOrders,
      carts,
      useInventoryAllocation,
      hasOrderManageRevisionPermission,
    ),
)

// THIS IS DUMB PLEASE STOP, just use browser back button
export function previousOrderListStatusSelector(state) {
  const orderListForm = orderListFormSelector(state) || {}
  const orderStatus = orderDetailStatusSelector(state)

  return (
    orderListForm.cachedOrderListStatus_DO_NOT_USE ||
    (orderStatus ? [orderStatus] : [])
  )
}

export function orderListPageURLSelector(state) {
  return orderListHashBuilder({
    status: previousOrderListStatusSelector(state),
  })
}

export function getOrderDetailURL(orderNumber) {
  return [[ORDER_SINGLE_URI_COMPONENT, orderNumber]]
}

export const areAllLinesSelectedSelector = createSelector(
  (state) => {
    const orderNumber = orderDetailOrderNumberSelector(state)

    return orderLinesSelector(state, {orderNumber})
  },
  selectedOrderLineIDsSelector,
  (orderLines, selected) =>
    orderLines.length > 0 && orderLines.length === selected.length,
)

export const areIndeterminateLinesSelectedSelector = createSelector(
  areAllLinesSelectedSelector,
  selectedOrderLineIDsSelector,
  (allSelected, selected) => !allSelected && selected.length > 0,
)
