import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import find from 'lodash/find.js'
import zip from 'lodash/zip.js'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'

import {formSelector} from '../../../store.js'
import {
  orderLineSelector,
  orderSelector,
  ordersProductKitsSelector,
  ordersSelector,
} from '../../../data/orders.js'
import {PACK_MODE} from '../../../common/constants/PackingOrderModal.js'

export const PACK_PANEL_FORM = 'PACK_PANEL_FORM'
export const PACK_PANEL_GENERAL_ERROR = 'PACK_PANEL_GENERAL_ERROR'
export const PACK_PANEL_BARCODE_SCANNED = 'PACK_PANEL_BARCODE_SCANNED'
export const PACK_PANEL_NEEDS_SERIAL_NUMBER = 'PACK_PANEL_NEEDS_SERIAL_NUMBER'
export const PACK_PANEL_DUPLICATE_SERIAL_NUMBER =
  'PACK_PANEL_DUPLICATE_SERIAL_NUMBER'
export const SCANNED_CODE_SERIAL_NUMBER_IS_SKU =
  'SCANNED_CODE_SERIAL_NUMBER_IS_SKU'

export function packPanelFormSelector(state) {
  return formSelector(state, {formName: PACK_PANEL_FORM})
}

export const packingOrderNumbersSelector = createSelector(
  packPanelFormSelector,
  ({orderNumbers} = {}) => orderNumbers || [],
)

export const packingModeSelector = createSelector(
  packPanelFormSelector,
  ({mode} = {}) => mode,
)

export const packingOrderScannedCodeSelector = createSelector(
  packPanelFormSelector,
  ({scannedCode} = {}) => scannedCode,
)

export const packingOrderScanResultSelector = createSelector(
  packPanelFormSelector,
  ({scanResult} = {}) => scanResult,
)

export const packPanelLinesWithCountsSelector = createSelector(
  packPanelFormSelector,
  ({linesWithCounts} = {}) =>
    linesWithCounts || packPanelLinesWithCountsSelector.default,
)
packPanelLinesWithCountsSelector.default = []

export const packingOrderOverscannedComponentsSelector = createSelector(
  packPanelFormSelector,
  ({overscannedComponents} = {}) => overscannedComponents,
)

export const packPanelIsLoadingSelector = createSelector(
  packPanelFormSelector,
  ({isLoading} = {}) => !!isLoading,
)

export const includeKitParentsSelector = createSelector(
  packPanelFormSelector,
  ({includeKitParents} = {}) => !!includeKitParents,
)

export function packingOrdersSelector(state) {
  const orderNumbers = packingOrderNumbersSelector(state)
  return ordersSelector(state, {orderNumbers})
}

export function packingOrderProductKitsSelector(state) {
  const orderNumbers = packingOrderNumbersSelector(state)
  return ordersProductKitsSelector(state, {orderNumbers})
}

export const packingOrderLinesSelector = createSelector(
  packingOrdersSelector,
  (orders) =>
    orders
      ? orders.reduce(
          (prev, order) => [
            ...prev,
            ...order.lines.map((line) => ({
              ...line,
              orderNumber: order.order_number,
            })),
          ],
          [],
        )
      : [],
)

function calculateAdjustedTotalKitQuantities(
  orderLineID,
  orderNumber,
  kit,
  quantity,
  path,
) {
  const adjusted = {
    path,
    sku: kit.sku,
    kitQuantity: kit.quantity,
    orderedQuantity: kit.quantity * quantity,
    verifiedQuantity: 0,
  }

  adjusted.orderLineIDSets = [
    {
      orderNumber,
      orderLineID,
      quantity: adjusted.orderedQuantity,
      serialNumbers: [],
    },
  ]

  if (kit.children && kit.children.length > 0) {
    adjusted.children = kit.children.map((childKit, index) =>
      calculateAdjustedTotalKitQuantities(
        orderLineID,
        orderNumber,
        childKit,
        adjusted.orderedQuantity,
        `${path}.children[${index}]`,
      ),
    )
  }

  return adjusted
}

export const orderedQuantitiesSelector = createSelector(
  packingOrderLinesSelector,
  packingOrderProductKitsSelector,
  (orderLines, productKits) => {
    if (isEmpty(productKits)) {
      return {}
    }

    return orderLines.map(({id, quantity, orderNumber}, index) => {
      const productKit = productKits[index]
      return calculateAdjustedTotalKitQuantities(
        id,
        orderNumber,
        productKit,
        quantity,
        `[${index}]`,
      )
    })
  },
)

export function ordersWarehouseIDSelector(state) {
  const orderNumbers = packingOrderNumbersSelector(state)

  const order = orderSelector(state, {orderNumber: orderNumbers[0]})

  return get(order, 'warehouse.id')
}

export function initialLinesWithCountsSelector(state) {
  const packingOrderProductKits = packingOrderProductKitsSelector(state)
  const orderedQuantities = orderedQuantitiesSelector(state)
  const ordersWarehouseID = ordersWarehouseIDSelector(state)
  const mode = packingModeSelector(state)
  const includeKitParents = includeKitParentsSelector(state)

  let productKitsWithCounts = zip(
    packingOrderProductKits,
    orderedQuantities,
  ).map(([productKit, orderedQuantity]) => {
    return mergeChildren(productKit, orderedQuantity, ordersWarehouseID)
  })

  if (mode === PACK_MODE) {
    return productKitsWithCounts
  }

  // only leaf nodes
  if (!includeKitParents) {
    productKitsWithCounts = flattenOnlyLeafNodes(productKitsWithCounts)
  }

  // combine common skus in to a single line
  const lookup = {}
  for (const productKit of productKitsWithCounts) {
    const collected = lookup[productKit.sku]

    if (!collected) {
      lookup[productKit.sku] = {...productKit}
    } else {
      lookup[productKit.sku] = mergeQuantities(collected, productKit)
    }
  }

  const lines = Object.values(lookup)

  lines.forEach((line, index) => reNumberPath(line, `[${index}]`))

  return lines
}

function flattenOnlyLeafNodes(lines, index = 0) {
  const flattenLines = []

  for (const line of lines) {
    let {children, ...rest} = line

    if (children && children.length > 0) {
      flattenLines.push(...flattenOnlyLeafNodes(children, index))
    } else {
      flattenLines.push({...rest, path: `[${index}]`})

      index += 1
    }
  }

  return flattenLines
}

function mergeQuantities(collected, productKit) {
  collected.orderedQuantity += productKit.orderedQuantity
  collected.verifiedQuantity += productKit.verifiedQuantity

  collected.orderLineIDSets = productKit.orderLineIDSets.reduce(
    (prev, orderLineIDSet) => {
      let lineSet = prev.find(
        ({orderLineID}) => orderLineID === orderLineIDSet.orderLineID,
      )

      if (!lineSet) {
        lineSet = {...orderLineIDSet}
        prev.push(lineSet)
      } else {
        lineSet.quantity += orderLineIDSet.quantity
      }

      return prev
    },
    collected.orderLineIDSets,
  )

  for (let index = 0; index < (collected.children || []).length; index++) {
    collected.children[index] = mergeQuantities(
      collected.children[index],
      productKit.children[index],
    )
  }

  return collected
}

function reNumberPath(obj, newPathStart) {
  obj.path = obj.path.replace(/^\[\d+\]/, newPathStart)

  for (let index = 0; index < (obj.children || []).length; index++) {
    reNumberPath(obj.children[index], newPathStart)
  }
}

function mergeChildren(productKit, orderedQuantity, warehouseID) {
  const merged = {
    ...orderedQuantity,
    ...productKit,
  }

  if (warehouseID) {
    const warehouse = get(merged, 'warehouses', []).find(
      ({warehouse_id}) => warehouseID === warehouse_id,
    )
    merged.location = get(warehouse, 'location_in_warehouse') || null
  }

  merged.upc = merged.upc || null

  if (productKit.children && orderedQuantity.children) {
    merged.children = zip(productKit.children, orderedQuantity.children).map(
      ([childProductKit, childOrderQuantity]) =>
        mergeChildren(childProductKit, childOrderQuantity, warehouseID),
    )
  }

  return merged
}

function flattenNestedChildren(lines) {
  const flattenLines = []

  for (const line of lines) {
    let {children, ...rest} = line

    flattenLines.push(rest)

    if (children) {
      flattenLines.push(...flattenNestedChildren(children))
    }
  }

  return flattenLines
}

export function markAndSortLines(lines, sort = {property: 'none'}, level = 0) {
  let newLines = []

  for (const line of lines) {
    let {children, ...rest} = line

    rest.level = level
    rest.hasChildren = !!children

    newLines.push(rest)

    if (children) {
      rest.children = markAndSortLines(children, sort, level + 1)
    }
  }

  if (sort.property !== 'none') {
    newLines = sortBy(newLines, [sort.property, 'sku'])
  }

  if (sort.direction === '-') {
    newLines.reverse()
  }

  return newLines
}

export function getFlattenedLines(lines, sort) {
  lines = markAndSortLines(lines, sort)

  return flattenNestedChildren(lines)
}

export const packingOrderLinesFlattenedForDisplaySelector = createSelector(
  packPanelLinesWithCountsSelector,
  (state) => (packPanelFormSelector(state) || {}).sortListBy || 'location',
  (linesWithCounts, sortListBy) => {
    const [, direction, property] = sortListBy.match(/([-])?(.*)/)

    let lines = getFlattenedLines(linesWithCounts, {
      property,
      direction,
    })

    lines = lines.filter((line) => line.verifiedQuantity < line.orderedQuantity)

    return lines
  },
)

export const totalLineCountSelector = createSelector(
  packPanelLinesWithCountsSelector,
  (lines) => lines.length,
)

export const verifiedLineCountSelector = createSelector(
  packPanelLinesWithCountsSelector,
  (lines) =>
    lines.filter((line) => line.verifiedQuantity >= line.orderedQuantity)
      .length,
)

export const allLinesVerifiedSelector = createSelector(
  totalLineCountSelector,
  verifiedLineCountSelector,
  (total, verified) => total === verified,
)

export function scannedCodeMatchesLine(scannedCode, line) {
  return line.sku === scannedCode || line.upc === scannedCode
}

export const scannedProductSelector = createSelector(
  packingOrderScannedCodeSelector,
  packPanelLinesWithCountsSelector,
  (scannedCode, linesWithCounts) => {
    const flattenedLines = getFlattenedLines(linesWithCounts)
    const productLine = find(flattenedLines, (line) =>
      scannedCodeMatchesLine(scannedCode, line),
    )

    return {
      name: productLine.name,
      sku: productLine.sku,
    }
  },
)

export const overscannedProductsSelector = createSelector(
  packingOrderOverscannedComponentsSelector,
  packPanelLinesWithCountsSelector,
  (overscannedComponents, linesWithCounts) => {
    const flattenedLines = getFlattenedLines(linesWithCounts)
    const overscannedProducts = Object.entries(overscannedComponents).map(
      ([sku, counts]) => {
        const matchingLine = find(flattenedLines, (line) => {
          return line.sku === sku
        })
        return {
          name: matchingLine.name,
          sku: matchingLine.sku,
          ...counts,
        }
      },
    )

    return overscannedProducts
  },
)

export function serialNumbersUpdatesSelector(state) {
  const {linesWithCounts} = packPanelFormSelector(state)

  function organizeSerialNumbersUpdates(line, byOrderNumber) {
    line.orderLineIDSets.forEach(
      ({serialNumbers, orderNumber, orderLineID}) => {
        const orderLine = orderLineSelector(state, {orderNumber, orderLineID})

        serialNumbers.forEach((serialNumber) => {
          const ordUpdate = byOrderNumber[orderNumber] || {
            orderNumber,
            lines: {},
          }

          byOrderNumber[orderNumber] = ordUpdate

          const update = ordUpdate.lines[orderLineID] || {
            orderLineID,
            serialNumbers: orderLine.product_serial_numbers || [],
          }

          ordUpdate.lines[orderLineID] = update

          if (!update.serialNumbers.includes(serialNumber)) {
            update.serialNumbers.push(serialNumber)
          }
        })
      },
    )
    ;(line.children || []).forEach((child) =>
      organizeSerialNumbersUpdates(child, byOrderNumber),
    )
  }

  const updates = Object.values(
    linesWithCounts.reduce((prev, line) => {
      organizeSerialNumbersUpdates(line, prev)

      return prev
    }, {}),
  ).map((ordUpdate) => ({...ordUpdate, lines: Object.values(ordUpdate.lines)}))

  return updates
}
