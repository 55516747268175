import keyBy from 'lodash/keyBy.js'
import {put, call, takeEvery} from 'redux-saga/effects'

import {fetchAllAPI} from '../../../common/fetchAll.js'
import {setGlobalError} from '../../actions/ui/index.js'
import {updateHasLoaded} from '../../actions/data/hasLoaded.js'
import {
  GET_PRINT_CONFIGS,
  setPrintConfigs,
} from '../../actions/data/printConfigs.js'

export function* getPrintConfigsWorker() {
  try {
    const printConfigs = yield call(
      fetchAllAPI,
      '/print_config/',
      'print_config',
    )

    yield put(setPrintConfigs(keyBy(printConfigs, 'id')))
  } catch (err) {
    yield put(
      setGlobalError(
        `Error getting printConfigs. ${err.error_message || err.message}`,
        err,
      ),
    )
  }

  yield put(updateHasLoaded({printConfigs: true}))
}

export default function* printConfigsSaga() {
  yield takeEvery(GET_PRINT_CONFIGS, getPrintConfigsWorker)
}
