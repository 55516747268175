import PropTypes from 'prop-types'

import FormattedDate from '../../../../../common/components/FormattedDate.js'

export default function ShipDate({date}) {
  return (
    <span>
      <FormattedDate value={date} format="MMM D, YYYY" />
      {' at '}
      <FormattedDate value={date} format="h:mma" />
    </span>
  )
}

ShipDate.propTypes = {
  date: PropTypes.string.isRequired,
}
