import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import {createSelector} from 'reselect'
import classNames from 'classnames'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {plural} from '../../../common/components/Plural.js'
import apiverson from '../../../common/apiverson.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import TextArea from '../../../common/components/TextArea.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import {
  ensureMO,
  setMOStateAndEnsureProductsLoaded,
  moLinesSelector,
} from '../../../data/mos.js'
import {
  setProduct,
  ensureLinkedSKUsLoaded,
  productSelector,
  productBOMComponentsSelector,
  getProductName,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'

const EDIT_MO_LINE_MODAL = 'EDIT_MO_LINE_MODAL'

export function showEditMOLineModal(
  referenceID,
  lineIDs = [],
  autoFocusOn = null,
) {
  const lines = moLinesSelector(getState(), {referenceID})

  autoFocusOn =
    autoFocusOn || (lineIDs.length === 0 ? 'sku' : 'requested_quantity')

  const firstMOLine = lines.find(({id}) => id === lineIDs[0])

  setForm(EDIT_MO_LINE_MODAL, {
    referenceID,
    lineIDs,
    autoFocusOn,
    isSingleInputEdit: lineIDs.length > 1,
    requested_quantity: String(get(firstMOLine, 'requested_quantity') || 1),
    internal_notes: get(firstMOLine, 'internal_notes') || '',
    manufacturer_notes: get(firstMOLine, 'manufacturer_notes') || '',
    sku: get(firstMOLine, 'sku') || null,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(EDIT_MO_LINE_MODAL, ...args)
}

export function closeModal() {
  removeForm(EDIT_MO_LINE_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[EDIT_MO_LINE_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({requested_quantity, sku}) => {
    const errors = {}

    if (!isNumeric(requested_quantity)) {
      errors.requested_quantity = 'Requested Quantity must be a number'
      errors.preventSave = true
    }

    if (!isPresent(requested_quantity)) {
      errors.requested_quantity = 'Requested Quantity is required'
      errors.preventSave = true
    }

    if (!isPresent(sku)) {
      errors.sku = 'Product is required'
      errors.preventSave = true
    }

    return errors
  },
)

export function updatedParamsSelector(state) {
  const {
    lineIDs,
    requested_quantity,
    internal_notes,
    manufacturer_notes,
    sku,
    autoFocusOn,
    isSingleInputEdit,
  } = modalFormSelector(state)

  const params = {}

  if (!isSingleInputEdit || autoFocusOn === 'requested_quantity') {
    params.requested_quantity = Number(requested_quantity)
  }

  if (!isSingleInputEdit || autoFocusOn === 'internal_notes') {
    params.internal_notes = internal_notes || ''
  }

  if (!isSingleInputEdit || autoFocusOn === 'manufacturer_notes') {
    params.manufacturer_notes = manufacturer_notes || ''
  }

  if (lineIDs.length === 0) {
    params.sku = sku
  }

  return params
}

export async function addProduct(product, input) {
  setProduct(product)

  updateModalForm({[input]: product.sku})
}

export async function updateMOLine({addAnother} = {}) {
  try {
    const {referenceID, lineIDs} = modalFormSelector(getState())
    const params = updatedParamsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    let mo

    const responses = await Promise.all(
      lineIDs.length === 0
        ? [
            apiverson.post(
              `/manufacturing_order/${encodeURIComponent(referenceID)}/line`,
              params,
            ),
          ]
        : lineIDs.map((lineID) =>
            apiverson.put(
              `/manufacturing_order/${encodeURIComponent(
                referenceID,
              )}/line/${lineID}`,
              params,
            ),
          ),
    )

    // We need to get the final mo shape and we don't know which promise that is, so if we have
    // multiple requests then just ask for the mo again
    mo =
      responses.length === 1
        ? responses[0].json
        : await ensureMO(referenceID, {reload: true})

    await setMOStateAndEnsureProductsLoaded(mo)

    closeModal()

    showMessageToast(
      lineIDs.length === 0
        ? 'Added MFG order line.'
        : plural(lineIDs.length)`Saved MFG order line${['s']}.`,
    )

    if (addAnother) {
      showEditMOLineModal(referenceID)
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditMOLineModal({form}) {
  const errors = useSelector(errorsSelector)
  const product = useSelector((state) =>
    productSelector(state, {sku: form.sku}),
  )
  const bomComponents = useSelector((state) =>
    productBOMComponentsSelector(state, {sku: form.sku}),
  )

  return (
    <ConfirmModal
      title={`${form.lineIDs.length === 0 ? 'Add' : 'Edit'} MFG Order Line`}
      modalSize="sm"
      onConfirm={() => updateMOLine()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      MiddleButtons={
        form.lineIDs.length === 0
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => updateMOLine({addAnother: true})}
              >
                Create and Add Another
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {form.lineIDs.length === 0 ? (
          <li className="list__item list__item--form list__item--product-filter-stacked">
            <label
              className="fs-n0 margin-bottom-1"
              htmlFor="EDIT_MO_LINE_MODAL_TO_PRODUCT_SEARCH__input"
            >
              Finished SKU
            </label>
            {form.sku ? (
              <ButtonLink
                className="darker"
                id="EDIT_MO_LINE_MODAL_TO_PRODUCT_SEARCH__input"
                onClick={() => updateModalForm({sku: null})}
              >
                <strong className="fs-01">{getProductName(product)}</strong>{' '}
                <div className="unbold fs-n0">{form.sku}</div>
                <div className="fs-n0 lh-md text--lt-grey margin-bottom-5">
                  Edit
                </div>
              </ButtonLink>
            ) : (
              <ProductFilter
                queryParams={{manufactured: true}}
                dropdown="EDIT_MO_LINE_MODAL_TO_PRODUCT_SEARCH"
                onSelect={(product) => {
                  updateModalForm({sku: product.sku})

                  ensureLinkedSKUsLoaded([product.sku])
                }}
                autoFocus
              />
            )}
          </li>
        ) : form.lineIDs.length === 1 ? (
          <li className="list__item--form list__item--no-style">
            <div className="fs-01 lh-md margin-bottom-10">
              <div>
                <strong className="fs-n0">Finished SKU</strong>
              </div>
              <strong className="fs-01">{getProductName(product)}</strong>{' '}
              <div className="unbold fs-n0">{form.sku}</div>
            </div>
          </li>
        ) : (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              <strong>Updating {form.lineIDs.length} Lines</strong>
            </div>
          </li>
        )}

        {product && form.lineIDs.length <= 1 && (
          <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
            {bomComponents.length ? (
              <dl className="list divider--left">
                <dt className="list__title--order-data fs-n1 margin-bottom-7">
                  Components
                </dt>
                <dd className="list__item">
                  <ul className="list list--square">
                    {bomComponents.map((component) => (
                      <li
                        className="list__item list__item--no-style flex--justify divider--bottom"
                        key={component.id}
                      >
                        <div className="fs-n1 lh-md">
                          <strong>{component.name}</strong>
                          <div>{component.sku}</div>
                        </div>
                        <div className="fs-n1 lh-md">{component.quantity}</div>
                      </li>
                    ))}
                  </ul>
                </dd>
              </dl>
            ) : (
              <div>
                <span className="spinner--sm v-align-middle" />
                <strong className="inline-block v-align-middle fs-00 op-75 margin-left-5">
                  Loading...
                </strong>
              </div>
            )}
          </li>
        )}

        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'requested_quantity') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-top-25 margin-bottom-3">
              <label className="fs-n0" htmlFor="requested_quantity">
                Requested Quantity
              </label>
            </li>
            <li
              className={classNames('list__item list__item--form', {
                'divider--bottom padding-bottom-15': form.lineIDs.length === 1,
              })}
            >
              <NumberInput
                id="requested_quantity"
                value={form.requested_quantity}
                onChange={(value) =>
                  updateModalForm({requested_quantity: String(value)})
                }
                isInvalid={!!errors.requested_quantity}
                autoFocus={
                  form.autoFocusOn === 'requested_quantity' ||
                  (form.lineIDs.length === 0 && !!form.sku)
                }
                min={0}
              />
              {errors.requested_quantity && (
                <small className="error">{errors.requested_quantity}</small>
              )}
            </li>
          </>
        )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'manufacturer_notes') && (
          <li className="list__item list__item--form margin-top-25">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="7"
              label="Notes for Manufacturer"
              id="manufacturer_notes"
              value={form.manufacturer_notes}
              onChange={(value) => updateModalForm({manufacturer_notes: value})}
              errorMessage={errors.manufacturer_notes}
              autoFocus={form.autoFocusOn === 'manufacturer_notes'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'internal_notes') && (
          <li className="list__item list__item--form margin-top-25">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="7"
              label="Internal Notes"
              id="internal_notes"
              value={form.internal_notes}
              onChange={(value) => updateModalForm({internal_notes: value})}
              errorMessage={errors.internal_notes}
              autoFocus={form.autoFocusOn === 'internal_notes'}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditMOLineModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    requested_quantity: PropTypes.string.isRequired,
    internal_notes: PropTypes.string.isRequired,
    manufacturer_notes: PropTypes.string.isRequired,
    sku: PropTypes.string,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditMOLineModal, modalFormSelector)
