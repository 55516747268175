import PropTypes from 'prop-types'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {updateForm, useForm} from '../../../store.js'
import DatePicker from '../../../common/components/DatePicker.js'

export default function DateRange({formName, errors}) {
  const form = useForm(formName)

  if (!(form.singleRun && !form.interval_type)) {
    return null
  }

  return (
    <li className="list__item--form list__item--no-style flex">
      <div>
        <DatePicker
          label="Start"
          labelClassName="clearfix"
          id="report_start_date"
          onChange={(date) => updateForm(formName, {start_date: date})}
          selected={form.start_date}
          startDate={form.start_date}
          endDate={form.end_date}
          errorMessage={errors.start_date}
        />
      </div>
      <div className="margin-left-15">
        <DatePicker
          label="End"
          labelClassName="clearfix"
          id="report_end_date"
          onChange={(date) => updateForm(formName, {end_date: date})}
          selected={form.end_date}
          startDate={form.start_date}
          endDate={form.end_date}
          errorMessage={errors.end_date}
        />
      </div>
    </li>
  )
}

DateRange.propTypes = {
  formName: PropTypes.string.isRequired,
  errors: ErrorsShape.isRequired,
}
