import {createSelector} from 'reselect'
import head from 'lodash/head.js'

import {sortedTasksSelector} from '../data/tasks.js'

export const hasUnreadTasksSelector = createSelector(
  (state) => state.ui.tasks.lastViewedTime,
  sortedTasksSelector,
  (lastViewedTime, tasks) => {
    const latest = head(
      tasks.map((task) => new Date(task.updated)).sort((a, b) => b - a),
    )
    return new Date(lastViewedTime).getTime() < new Date(latest).getTime()
  },
)
