import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import {isPresent} from '../../../common/utils.js'
import {
  DHL,
  DHL_ECOMMERCE,
  PITNEY,
  PITNEY_MERCHANT,
  UPS,
} from '../../../common/constants/ShipperNames.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import Select from '../../../common/components/Select.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  taxInfoPropertySelector,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ShipperOptions from '../../../data/shipperOptions.js'

const TAX_INFO_MODAL = 'TAX_INFO_MODAL'

export function showTaxInfoModal(labelInfoID, shipperType) {
  const labelConfig = labelConfigSelector(getState(), {
    labelInfoID,
  })
  const property = taxInfoPropertySelector(getState(), {
    labelInfoID,
    shipperType,
  })

  setForm(TAX_INFO_MODAL, {
    labelInfoID,
    shipperType,
    property,
    tax_id_number: labelConfig[`${property}__tax_id_number`] || '',
    tax_id_type: labelConfig[`${shipperType}__${property}__tax_id_type`] || '',
    tax_issuer_country: labelConfig[`${property}__tax_issuer_country`] || '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(TAX_INFO_MODAL, ...args)
}

export function closeModal() {
  removeForm(TAX_INFO_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[TAX_INFO_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({tax_id_number, tax_id_type, tax_issuer_country, shipperType}) => {
    const errors = {}

    if (isPresent(tax_id_number) && !isPresent(tax_id_type)) {
      errors.tax_id_type = 'Tax ID Type is required'
      errors.preventSave = true
    }

    if (
      [DHL, DHL_ECOMMERCE, PITNEY, PITNEY_MERCHANT].includes(shipperType) &&
      isPresent(tax_id_number) &&
      !isPresent(tax_issuer_country)
    ) {
      errors.tax_issuer_country = 'Tax Issuer Country is required'
      errors.preventSave = true
    }

    return errors
  },
)

function updatedParamsSelector(state) {
  const form = modalFormSelector(state)
  const {shipperType} = form
  const labelConfig = labelConfigSelector(state, {
    labelInfoID: form.labelInfoID,
  })
  const property = form.property
  const otherProperty = [DHL, UPS].includes(shipperType)
    ? property === 'sender_tax_info'
      ? 'receiver_tax_info'
      : 'sender_tax_info'
    : null

  const updates = {}

  if (labelConfig[`${property}__tax_id_number`] !== form.tax_id_number) {
    updates[`${property}__tax_id_number`] = form.tax_id_number

    if (otherProperty) {
      updates[`${otherProperty}__tax_id_number`] = undefined
    }
  }

  if (
    labelConfig[`${shipperType}__${property}__tax_id_type`] !== form.tax_id_type
  ) {
    updates[`${shipperType}__${property}__tax_id_type`] = form.tax_id_type

    if (otherProperty) {
      updates[`${shipperType}__${otherProperty}__tax_id_type`] = undefined
    }
  }

  if (
    [DHL, DHL_ECOMMERCE, PITNEY, PITNEY_MERCHANT].includes(shipperType) &&
    labelConfig[`${property}__tax_issuer_country`] !== form.tax_issuer_country
  ) {
    updates[`${property}__tax_issuer_country`] = form.tax_issuer_country

    if (otherProperty) {
      updates[`${otherProperty}__tax_issuer_country`] = undefined
    }
  }

  return updates
}

export async function updateTaxInfo() {
  try {
    const form = modalFormSelector(getState())
    const params = updatedParamsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    if (Object.keys(params).length) {
      updateLabelConfig(form.labelInfoID, params)

      await getRates([form.labelInfoID], {justSaveLabelConfig: true})

      showMessageToast('Update Tax Info')
    }

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function TaxInfoModal({form}) {
  const errors = useSelector(errorsSelector)
  const options = ShipperOptions[form.shipperType].tax_id_type || []

  const needsPropertySelect = [DHL, UPS].includes(form.shipperType)
  const needsCountryInput = [
    DHL,
    DHL_ECOMMERCE,
    PITNEY,
    PITNEY_MERCHANT,
  ].includes(form.shipperType)

  return (
    <ConfirmModal
      title="Edit Tax Info"
      modalSize="sm"
      onConfirm={() => updateTaxInfo()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {needsPropertySelect && (
          <li className="list__item list__item--form">
            <Select
              label="Tax Payor"
              id="property"
              value={form.property}
              onChange={(property) => updateModalForm({property})}
              errorMessage={errors.property}
              autoFocus
            >
              <option value="sender_tax_info">Sender</option>
              <option value="receiver_tax_info">Receiver</option>
            </Select>
          </li>
        )}
        <li className="list__item list__item--form">
          <TextInput
            label="Tax ID"
            id="tax_id_number"
            className="margin-bottom-0"
            value={form.tax_id_number}
            onChange={(tax_id_number) => updateModalForm({tax_id_number})}
            errorMessage={errors.tax_id_number}
            autoFocus={!needsPropertySelect}
          />
        </li>
        <li className="list__item list__item--form">
          <Select
            label="Tax ID Type"
            id="tax_id_type"
            value={form.tax_id_type}
            onChange={(tax_id_type) => updateModalForm({tax_id_type})}
            errorMessage={errors.tax_id_type}
          >
            <option value="" />
            {options.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </Select>
        </li>
        {needsCountryInput && (
          <li className="list__item list__item--form">
            <TextInput
              label="Tax Issuer Country"
              id="tax_issuer_country"
              className="margin-bottom-0"
              value={form.tax_issuer_country}
              onChange={(tax_issuer_country) =>
                updateModalForm({tax_issuer_country})
              }
              errorMessage={errors.tax_issuer_country}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

TaxInfoModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    tax_id_number: PropTypes.string.isRequired,
    tax_id_type: PropTypes.string.isRequired,
    tax_issuer_country: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(TaxInfoModal, modalFormSelector)
