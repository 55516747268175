import keyBy from 'lodash/keyBy.js'

export const AMAZON = 'amazon'
export const AMAZON_CA = 'amazon_ca'
export const AMAZON_UK = 'amazon_uk'
export const BIGCOMMERCE = 'bigcommerce'
export const CHANNELADVISOR = 'channeladvisor'
export const CUSTOM_INTEGRATION = 'custom_integration'
export const CSV_FILE = 'csv_file'
export const EBAY = 'ebay'
export const ECWID = 'ecwid'
export const ETSY = 'etsy'
export const JET = 'jet'
export const MAGENTO = 'magento'
export const MAGENTO_V2 = 'magento_v2'
export const MANUAL = 'manual'
export const ORDORO_VENDOR_CONNECT = 'ordoro_vendor_connect'
export const OTHER = 'other'
export const REVERB = 'reverb'
export const SHIP_STATION = 'shipstation'
export const SHOPIFY = 'shopify'
export const SHOPSITE = 'shopsite'
export const SPS_COMMERCE = 'sps_commerce'
export const SQUARE = 'square'
export const SQUARESPACE = 'squarespace'
export const STRIPE = 'stripe'
export const THREEDCART = 'threedcart'
export const VENDOR_PORTAL = 'vendor_portal'
export const VOLUSION = 'volusion_v1'
export const WALMART = 'walmart'
export const WAYFAIR = 'wayfair'
export const WOOCOMMERCE = 'woocommerce'

export const cartVendorOptions = [
  {value: AMAZON, display: 'Amazon'},
  {value: AMAZON_CA, display: 'AmazonCA'},
  {value: AMAZON_UK, display: 'AmazonUK'},
  {value: BIGCOMMERCE, display: 'BigCommerce'},
  {value: CHANNELADVISOR, display: 'ChannelAdvisor'},
  {value: CUSTOM_INTEGRATION, display: 'Custom Integration'},
  {value: EBAY, display: 'Ebay'},
  {value: ECWID, display: 'Ecwid'},
  {value: ETSY, display: 'Etsy'},
  {value: JET, display: 'Jet'},
  {value: MAGENTO, display: 'Magento'},
  {value: MAGENTO_V2, display: 'Magento v2.x'},
  {value: ORDORO_VENDOR_CONNECT, display: 'Vendor Connect'},
  {value: OTHER, display: 'Other'},
  {value: REVERB, display: 'Reverb'},
  {value: SHIP_STATION, display: 'ShipStation'},
  {value: SHOPIFY, display: 'Shopify'},
  {value: SHOPSITE, display: 'Shopsite'},
  {value: SPS_COMMERCE, display: 'SPS Commerce'},
  {value: SQUARE, display: 'Square'},
  {value: SQUARESPACE, display: 'Squarespace'},
  {value: STRIPE, display: 'Stripe Relay', isBeta: true},
  {value: THREEDCART, display: '3dcart'},
  {value: VENDOR_PORTAL, display: 'Vendor Portal'},
  {value: VOLUSION, display: 'Volusion'},
  {value: WALMART, display: 'Walmart'},
  {value: WAYFAIR, display: 'Wayfair'},
  {value: WOOCOMMERCE, display: 'WooCommerce'},
]

export const cartVendorsByName = keyBy(cartVendorOptions, 'value')

export const cartVendorsSorted = cartVendorOptions.map((cart) => cart.value)

export const salesChannelSettingsLink = '#/settings/saleschannels'

export const FULFILLMENT_CHANNEL_FBM = 'FULFILLMENT_CHANNEL_FBM'
export const FULFILLMENT_CHANNEL_FBA = 'FULFILLMENT_CHANNEL_FBA'
export const AUTH_TYPE_KEY = 'AUTH_TYPE_KEY'
export const AUTH_TYPE_USER = 'AUTH_TYPE_USER'
export const AUTH_TYPE_ACCESS_TOKEN = 'AUTH_TYPE_ACCESS_TOKEN'

export const CARTS_WITHOUT_ORDER_REVISIONS = [SHOPSITE]
