import PropTypes from 'prop-types'

import {getState, onlyIfAutoForm, formSelector} from '../../../../store.js'
import {isEmptyValue, isPositiveNumeric} from '../../../../common/utils.js'
import {
  NEW_ID,
  SALES_CHANNEL_SETTINGS_LINK,
} from '../../../../common/constants/SettingsPanels.js'
import {AMAZON_UK} from '../../../../common/constants/CartVendorOptions.js'
import {redirect} from '../../../../common/location.js'
import {cartSelector} from '../../../../data/carts.js'
import FormList from '../../Common/FormList.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import AmazonFulfillmentLatencyInput from '../AmazonFulfillmentLatencyInput.js'
import AmazonSFPSetup from '../AmazonSFPSetup.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (
    !(
      isEmptyValue(form.fulfillmentLatency) ||
      isPositiveNumeric(form.fulfillmentLatency)
    )
  ) {
    errors.fulfillmentLatency = 'Value must be a positive number or blank'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: AMAZON_UK}),
      fulfillmentLatency: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    fulfillmentLatency: String(cart.vendor_config.fulfillment_latency || ''),
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'fulfillment_latency') {
    errors.fulfillmentLatency = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const fulfillmentLatency = isPositiveNumeric(form.fulfillmentLatency)
    ? Number(form.fulfillmentLatency)
    : null

  return {
    ...baseSaveParamsSelector(state, {formName}),
    fulfillment_latency: fulfillmentLatency,
  }
}

export function authorize(
  formName,
  redirectURL = `/app${SALES_CHANNEL_SETTINGS_LINK}`,
) {
  const params = {
    redirect: redirectURL,
  }
  return redirect('', ['verde', 'amazon_sp', 'start'], params)
}

function AmazonUK({form, formName}) {
  const isNew = form.id === NEW_ID

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew ? (
            <>
              <AuthorizeMessage formName={formName} />
              <li className="list__item--form fs-n0 margin-bottom-10">
                <em>
                  Also, in compliance with{' '}
                  <a
                    href="https://support.ordoro.com/amazon-data-protection-policy-compliance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon’s Data Protection Policy
                  </a>
                  , some Amazon order data will be removed from your Ordoro
                  account after 30 days.
                </em>
              </li>
            </>
          ) : (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName} />
              <AmazonFulfillmentLatencyInput
                className="divider--top"
                formName={formName}
              />
              <AmazonSFPSetup className="divider--top" formName={formName} />
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Amazon UK"
          href="https://support.ordoro.com/amazon-uk-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

AmazonUK.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(AmazonUK, setUpCartForm)
