import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  updateFormObject,
  addFormArrayElement,
  removeFormArrayElement,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import api from '../../../common/api.js'
import {
  PRODUCT_LINK_TYPE,
  BOM_LINK_TYPE,
} from '../../../common/constants/Products.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Radio from '../../../common/components/Radio.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  getProduct,
  productsSelector,
  getProductName,
  linkedBOMSKUsSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {useKittingSelector} from '../../../data/company.js'
import {hasMOFeatureSelector} from '../../../data/mos.js'

export const SETUP_PRODUCT_COMPONENT_MODAL = 'SETUP_PRODUCT_COMPONENT_MODAL'

export function showSetupProductComponentModal(sku) {
  const useKitting = useKittingSelector(getState())

  setForm(SETUP_PRODUCT_COMPONENT_MODAL, {
    sku,
    linkType: useKitting ? PRODUCT_LINK_TYPE : BOM_LINK_TYPE,
    components: [],
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(SETUP_PRODUCT_COMPONENT_MODAL, ...args)
}

export function updateComponent(index, updates) {
  updateFormObject(
    SETUP_PRODUCT_COMPONENT_MODAL,
    ['components', index],
    updates,
  )
}

export function addComponent() {
  addFormArrayElement(SETUP_PRODUCT_COMPONENT_MODAL, 'components', {
    sku: null,
    quantity: '1',
  })
}

export function initializeComponent(index, product) {
  updateComponent(index, {
    sku: product.sku,
  })
}

export function removeComponent(index) {
  removeFormArrayElement(SETUP_PRODUCT_COMPONENT_MODAL, 'components', index)
}

export function closeModal() {
  removeForm(SETUP_PRODUCT_COMPONENT_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[SETUP_PRODUCT_COMPONENT_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({components}) => {
    const errors = {}

    components.map((component, index) => {
      if (!isPresent(component.quantity)) {
        errors[`${index}__quantity`] = 'Quantity is required'
        errors.preventSave = true
      } else if (!isPositiveNumeric(component.quantity)) {
        errors[`${index}__quantity`] = 'Quantity must be a positive number'
        errors.preventSave = true
      }
    })

    if (components.length === 0) {
      errors.components = 'Please add at least one component'
      errors.preventSave = true
    }

    return errors
  },
)

export async function setupProductComponents() {
  try {
    const {linkType, sku, components} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const url = `/product/${encodeURIComponent(sku)}/${
      linkType === BOM_LINK_TYPE ? 'manufacturing' : 'kit_component'
    }/`

    for (let component of components) {
      await api.post(url, {
        child_sku: component.sku,
        quantity: Number(component.quantity),
      })
    }

    await getProduct(sku)

    const bomSKU = linkedBOMSKUsSelector(getState(), {sku})[0]

    if (bomSKU) {
      await getProduct(bomSKU.sku)
    }

    closeModal()

    showMessageToast(
      `This product is now a ${linkType === BOM_LINK_TYPE ? 'BOM.' : 'kit.'}`,
    )
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function SetupProductComponentModal({form}) {
  const errors = useSelector(errorsSelector)
  const productsBySKU = useSelector(productsSelector)
  const useKitting = useSelector(useKittingSelector)
  const hasMOFeature = useSelector(hasMOFeatureSelector)
  const product = productsBySKU[form.sku]
  const typeName = form.linkType === BOM_LINK_TYPE ? 'BOM' : 'Kit'

  return (
    <ConfirmModal
      title={`Convert Product to a ${typeName}`}
      modalSize="md"
      onConfirm={() => setupProductComponents()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <p className="fs-01">
        <strong>
          Convert “{getProductName(product)}” to{' '}
          {useKitting && hasMOFeature
            ? 'which type of product'
            : `a ${typeName}`}
          ?
        </strong>
      </p>
      {useKitting && hasMOFeature && (
        <div className="flex margin-bottom-25 divider--bottom">
          <div className="margin-right-20">
            <label className="fs-01" htmlFor="type_kit">
              <Radio
                id="type_kit"
                name="linkType"
                className="margin-right-5"
                value={PRODUCT_LINK_TYPE}
                checked={form.linkType === PRODUCT_LINK_TYPE}
                onClick={() => updateModalForm({linkType: PRODUCT_LINK_TYPE})}
              />
              Kit <span className="unbold">(On-Demand)</span>
            </label>
          </div>
          <div>
            <label className="fs-01" htmlFor="type_bom">
              <Radio
                id="type_bom"
                name="linkType"
                className="margin-right-5"
                value={BOM_LINK_TYPE}
                checked={form.linkType === BOM_LINK_TYPE}
                onClick={() => updateModalForm({linkType: BOM_LINK_TYPE})}
              />
              BOM <span className="unbold">(Pre-Built)</span>
            </label>
          </div>
        </div>
      )}
      <p className="fs-01 margin-bottom-15">
        <strong>Build Out Your {typeName}</strong>
      </p>
      <table className="table fs-00">
        <thead>
          <tr className="fs-n0">
            <th className="table__th table__th--md w-45">Component Name</th>
            <th className="table__th table__th--md w-35">SKU</th>
            <th className="table__th table__th--md w-15">Qty Needed</th>
            <th className="table__th table__th--md">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="table__tbody--lines">
          {form.components.length === 0 && (
            <tr>
              <td className="table__td table__td--md align-center" colSpan="4">
                <div className="fs-00 text--md-grey margin-top-15 margin-bottom-15">
                  <em>
                    This {form.linkType === BOM_LINK_TYPE ? 'BOM' : 'kit'}{' '}
                    doesn’t have any product components yet.
                  </em>
                </div>
              </td>
            </tr>
          )}

          {form.components.map((component, index) => (
            <tr key={index}>
              <td className="table__td table__td--md">
                {component.sku ? (
                  <strong>
                    {getProductName(productsBySKU[component.sku])}
                  </strong>
                ) : (
                  <ProductFilter
                    dropdown={`PRODUCT_COMPONENT_PRODUCT_FILTER__${index}`}
                    excludedSKUs={form.components.map(({sku}) => sku)}
                    onSelect={(product) => initializeComponent(index, product)}
                    autoFocus
                  />
                )}
              </td>
              <td className="table__td table__td--md">{component.sku}</td>
              <td className="table__td table__td--md">
                <NumberInput
                  value={component.quantity}
                  onChange={(quantity) =>
                    updateComponent(index, {quantity: String(quantity)})
                  }
                  min={0}
                  autoFocus={!!component.sku}
                />
                {errors[`${index}__quantity`] && (
                  <small className="error">
                    {errors[`${index}__quantity`]}
                  </small>
                )}
              </td>
              <td className="table__td table__td--md align-right">
                <ButtonLink
                  className="no-underline"
                  title="Remove component"
                  onClick={() => removeComponent(index)}
                >
                  <span className="i-trash fs-00" aria-hidden="true"></span>
                </ButtonLink>
              </td>
            </tr>
          ))}
          <tr>
            <td className="table__td padding-left-0 align-center" colSpan="4">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() => addComponent()}
              >
                Add a Product
              </ButtonPrimary>
            </td>
          </tr>
        </tbody>
      </table>
    </ConfirmModal>
  )
}

SetupProductComponentModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    linkType: PropTypes.string.isRequired,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
      }),
    ),
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(SetupProductComponentModal, modalFormSelector)
