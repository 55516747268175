import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {PICK_MODE} from '../../../common/constants/PackingOrderModal.js'
import {
  setForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {plural} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import PackPanel from '../PackPanel/index.js'
import {
  packPanelFormSelector,
  PACK_PANEL_BARCODE_SCANNED,
} from '../PackPanel/packPanelSelectors.js'
import useToggleTimer from '../../../common/hooks/useToggleTimer.js'

export const MODAL_FORM = 'PACKING_ORDER_MODAL'

export function showPickOrdersModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
  })
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

function PickOrdersModal({form}) {
  const [animateSuccess, startSuccessAnimation] = useToggleTimer()
  const packPanelForm = useSelector(packPanelFormSelector) || {}

  return (
    <ConfirmModal
      title={plural(form.orderNumbers)`Pick Order${['s']}`}
      className="modal--lg modal--scan-mode meta-verify-order-modal"
      onCancel={() => closeModal()}
      cancelText="Cancel"
      badgeAnimate={animateSuccess}
      badgeContent={
        packPanelForm.scanResult === PACK_PANEL_BARCODE_SCANNED
          ? 'Scan Successful'
          : null
      }
      helpLink="https://support.ordoro.com/how-to-use-barcode-scanning-to-reduce-packing-errors/"
      helpMetaClass="meta-verify-order-support-link"
    >
      <PackPanel
        orderNumbers={form.orderNumbers}
        mode={PICK_MODE}
        onScan={() => startSuccessAnimation()}
        inModal
      />
    </ConfirmModal>
  )
}

PickOrdersModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default onlyIfForm(PickOrdersModal, modalFormSelector)
