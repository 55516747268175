import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isArray from 'lodash/isArray.js'

import {
  planSelector,
  showFeatureLocksSelector,
  usePermissionsSelector,
  usesAPIKeysFeatureFlagSelector,
} from './company.js'
import {PRO_PLAN, ENTERPRISE_PLAN} from '../common/constants/Plans.js'
import {HOT_KEY_COMBOS} from '../common/constants/HotKeys.js'
import {formsSelector, getState, setForm, updateForm} from '../store.js'
import delay from '../common/delay.js'
import analytics from '../common/analytics/index.js'
import api from '../common/api.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {debouncePromise} from '../common/debounce.js'

export const ME = 'ME'

export function setMe(user) {
  setForm(ME, user)
}

export function loadMe() {
  setMe(window.ORDORO_BOOTSTRAP.me)
}

export function updateMe(updates) {
  updateForm(ME, updates)
}

export function meSelector(state) {
  return formsSelector(state)[ME] || meSelector.default
}
meSelector.default = window.ORDORO_BOOTSTRAP.me

export const emailSelector = createSelector(meSelector, ({email}) => email)

export const permissionsSelector = createSelector(
  meSelector,
  ({permissions}) => permissions,
)

export function isSuperuserSelector(state) {
  return !!meSelector(state).is_superuser
}

export const userCompanyIDSelector = createSelector(
  meSelector,
  (me) => me.company_id,
)

export function friendlyNameSelector(state) {
  try {
    const {name} = meSelector(state)

    // probably first name, but who knows
    const firstName = name.trim().split(' ')[0]
    if (firstName.length > 0) {
      return firstName
    }
  } catch (error) {
    return 'Friend'
  }

  return 'Friend'
}

export function hasPermission(permission, permissions, usePermissions) {
  if (!usePermissions) {
    return true
  }

  permission = isArray(permission) ? permission : permission.split('.')

  const {hasPermission} = permission.reduce(
    (prev, key) => {
      if (prev.hasPermission === false) {
        return prev
      }

      const path = [...prev.path, key]
      const permissionThing = get(permissions, path)

      if (permissionThing === undefined) {
        return {
          ...prev,
          path,
        }
      }

      return {
        hasPermission: !!permissionThing,
        path,
      }
    },
    {path: [], hasPermission: true},
  )

  return hasPermission === true
}

export const canManageUserPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('can_manage_user', permissions, usePermissions),
)

export const hasProductPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product', permissions, usePermissions),
)

export const hasProductImportFromCartPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product.import_from_cart', permissions, usePermissions),
)

export const hasProductWritebackInventoryPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product.writeback_inventory', permissions, usePermissions),
)

export const hasProductManageTagPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product.manage_tag', permissions, usePermissions),
)

export const hasProductManageTagOnProductPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product.manage_tag_on_product', permissions, usePermissions),
)

export const hasProductUpdateInventoryPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('product.update_inventory', permissions, usePermissions),
)

export const hasOrderPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order', permissions, usePermissions),
)

export const hasOrderImportFromCartPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.import_from_cart', permissions, usePermissions),
)

export const hasOrderForceVerifyPackingPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.force_verify_packing', permissions, usePermissions),
)

export const hasOrderCreateLabelWithoutContentsVerifiedTagPermissionSelector =
  createSelector(
    permissionsSelector,
    (state) => usePermissionsSelector(state),
    (permissions, usePermissions) =>
      hasPermission(
        'order.create_label_without_contents_verified_tag',
        permissions,
        usePermissions,
      ),
  )

export const hasOrderManageTagPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.manage_tag', permissions, usePermissions),
)

export const hasOrderManageTagOnOrderPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.manage_tag_on_order', permissions, usePermissions),
)

export const hasOrderManageRevisionPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.manage_revision', permissions, usePermissions),
)

export const hasOrderFinancialsPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('order.financials', permissions, usePermissions),
)

export const hasPurchaseOrderPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('purchase_order', permissions, usePermissions),
)

export const hasPOManageTagPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('purchase_order.manage_tag', permissions, usePermissions),
)

export const hasPOManageTagOnPOPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission(
      'purchase_order.manage_tag_on_purchase_order',
      permissions,
      usePermissions,
    ),
)

export const hasAnalyticsPanelPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (state, {panel}) => panel,
  (permissions, usePermissions, panel) =>
    hasPermission(`analytics.${panel}`, permissions, usePermissions),
)

export const hasAnalyticsFinancialPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('analytics.financial', permissions, usePermissions),
)

export const hasAnalyticsShippingPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('analytics.shipping', permissions, usePermissions),
)

export const hasAnalyticsInventoryPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('analytics.inventory', permissions, usePermissions),
)

export const hasAnalyticsProductPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('analytics.product', permissions, usePermissions),
)

export const hasAnalyticsCustomerPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('analytics.customer', permissions, usePermissions),
)

export const hasAnalyticsPermissionSelector = createSelector(
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
  hasAnalyticsInventoryPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  hasAnalyticsCustomerPermissionSelector,
  (
    hasFinancialPermission,
    hasShippingPermission,
    hasInventoryPermission,
    hasProductPermission,
    hasCustomerPermission,
  ) =>
    hasFinancialPermission ||
    hasShippingPermission ||
    hasInventoryPermission ||
    hasProductPermission ||
    hasCustomerPermission,
)

export const hasQuickAnalyticsPermissionSelector = createSelector(
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  (hasFinancialPermission, hasShippingPermission, hasProductPermission) =>
    hasFinancialPermission || hasShippingPermission || hasProductPermission,
)

export const hasSettingsPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('settings', permissions, usePermissions),
)

export const hasReportsPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('reports', permissions, usePermissions),
)

export const hasReturnOrderPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('return_order', permissions, usePermissions),
)

export const hasReturnOrderManageTagPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('return_order.manage_tag', permissions, usePermissions),
)

export const hasReturnOrderManageTagOnReturnOrderPermissionSelector =
  createSelector(
    permissionsSelector,
    (state) => usePermissionsSelector(state),
    (permissions, usePermissions) =>
      hasPermission(
        'return_order.manage_tag_on_return_order',
        permissions,
        usePermissions,
      ),
  )

export const hasMOPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('manufacturing_order', permissions, usePermissions),
)

export const hasMOManageTagPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission(
      'manufacturing_order.manage_tag',
      permissions,
      usePermissions,
    ),
)

export const hasMOManageTagOnMOPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission(
      'manufacturing_order.manage_tag_on_manufacturing_order',
      permissions,
      usePermissions,
    ),
)

export const hasBatchPermissionSelector = createSelector(
  permissionsSelector,
  (state) => usePermissionsSelector(state),
  (permissions, usePermissions) =>
    hasPermission('batch', permissions, usePermissions),
)

export const hasAPIKeyFeatureSelector = createSelector(
  (state) => planSelector(state),
  (state) => usesAPIKeysFeatureFlagSelector(state),
  (plan, usesAPIKeys) =>
    [PRO_PLAN, ENTERPRISE_PLAN].includes(plan) || !!usesAPIKeys,
)

export const usesAPIKeysSelector = createSelector(
  hasAPIKeyFeatureSelector,
  canManageUserPermissionSelector,
  (hasAPIKeyFeature, canManageUser) => hasAPIKeyFeature && canManageUser,
)

export const showAPIKeysNavSelector = createSelector(
  canManageUserPermissionSelector,
  hasAPIKeyFeatureSelector,
  (state) => showFeatureLocksSelector(state),
  (canManageUserPermission, hasAPIKeyFeature, showFeatureLocks) =>
    canManageUserPermission && (hasAPIKeyFeature || showFeatureLocks),
)

export function userComboKeysSelector() {
  return HOT_KEY_COMBOS
}

export const userSettingsSelector = createSelector(
  meSelector,
  ({settings}) => settings,
)

export function userSettingSelector(state, {setting, defaultValue}) {
  const settings = userSettingsSelector(state)

  return get(settings, setting, defaultValue)
}

/**
[
  {
    display: 'Morders',
    url: '#/order?status=shipped&orderTagFilters=%2Ftag%2F4015',
    index: 3,
  },
  {id: 'products', display: 'Things'},
  {id: 'returns', hide: true},
  {id: 'pos', index: 1},
  {display: 'Ecwidalytics', url: '#/analytics/shipping?cartFilters=24204'},
]
 */
export function userTabsSelector(state) {
  return userSettingSelector(state, {
    setting: 'tabs',
    defaultValue: [],
  })
}

export async function refreshMe() {
  try {
    const {json} = await api.get('/user/me/')

    setMe(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error refreshing current user.',
        details: err.error_message || err.message,
      },
      err,
    )
  }
}

export async function logout() {
  try {
    analytics.logout()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error trying to log out.',
        details: err.error_message || err.message,
      },
      err,
    )
  }

  // Wait a bit in case analytics.logout() needs some extra time
  await delay(50)

  window.location.href = '/logout'
}

export function updateUserSettings(updates) {
  const settings = userSettingsSelector(getState())

  updateMe({settings: {...settings, ...updates}})
}

export async function saveUserSettings() {
  const token = {}
  saveUserSettings.token = token

  try {
    const me = meSelector(getState())
    const settings = userSettingsSelector(getState())

    if (!me.fake_user) {
      const {json} = await api.put(`/user/${me.id}`, {settings})

      if (token === saveUserSettings.token) {
        updateMe(json)
      }
    }
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error saving user settings',
        details: err.error_message || err.message,
      },
      err,
    )
  }
}

export const debouncedSaveUserSettings = debouncePromise(saveUserSettings, 1500)
