import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {orderSupplierNameSelector} from '../../../../../data/orders.js'

function OrderSupplier({supplierName}) {
  return <span>{supplierName}</span>
}

OrderSupplier.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  supplierName: PropTypes.string,
}

const mapStateToProps = (state, {orderNumber}) => ({
  supplierName: orderSupplierNameSelector(state, {orderNumber}),
})

export default connect(mapStateToProps)(OrderSupplier)
