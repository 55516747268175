import PropTypes from 'prop-types'
import {Component} from 'react'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default class ReconcileForm extends Component {
  constructor(props) {
    super(props)

    this.onChange = (value, name) => this.props.updateColumnMapping(value, name)
  }

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-10" {...this.props}>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.sku}
            errorMessage={this.props.columnErrors.sku}
            name="sku"
            label="SKU"
            className="medium-6"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.supplier}
            errorMessage={this.props.columnErrors.supplier}
            name="supplier"
            label="Supplier"
            className="medium-6"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.supplier_sku}
            errorMessage={this.props.columnErrors.supplier_sku}
            name="supplier_sku"
            label="Supplier SKU"
            className="medium-6"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.supplier_price}
            errorMessage={this.props.columnErrors.supplier_price}
            name="supplier_price"
            label="Supplier Unit Cost"
            className="medium-6"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.is_default}
            errorMessage={this.props.columnErrors.is_default}
            name="is_default"
            label="Default Supplier"
            className="medium-6"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.min_order_qty}
            errorMessage={this.props.columnErrors.min_order_qty}
            name="min_order_qty"
            label="Min Order Quantity"
            className="medium-6"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.supplier_lead_time}
            errorMessage={this.props.columnErrors.supplier_lead_time}
            name="supplier_lead_time"
            label="Lead Time"
            className="medium-6"
            onChange={this.onChange}
          />
        </Row>
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
