import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import startCase from 'lodash/startCase.js'

import {
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
} from '../../../../../common/constants/ShipperNames.js'
import {ShippingInfoShape} from '../../../../../common/PropTypes.js'
import {shipperTypeSelector} from '../../../../../data/shippers.js'

function getTrackingMessage(status) {
  if (status === 'manifest') {
    return 'Label Created'
  }

  return startCase(status)
}

function TrackingStatus({shippingInfo, shipperType}) {
  if (!shipperType) {
    return null
  }
  if (
    ![
      PITNEY,
      ENDICIA,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      VISIBLE_USPS,
      UPS,
    ].includes(shipperType)
  ) {
    return null
  }
  if (!shippingInfo.status) {
    return null
  }

  return (
    <li className="list__item--order-data">
      <strong className="margin-right-3">Tracking Status:</strong>
      {getTrackingMessage(shippingInfo.status)}
    </li>
  )
}

TrackingStatus.propTypes = {
  shipperType: PropTypes.string.isRequired,
  shippingInfo: ShippingInfoShape.isRequired,
}

function mapStateToProps(state, props) {
  const shipperID = props.shippingInfo.carrier && props.shippingInfo.carrier.id

  return {
    shipperType: shipperTypeSelector(state, {shipperID}) || '',
  }
}

export default connect(mapStateToProps)(TrackingStatus)
