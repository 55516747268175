import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {RETURN_ORDER_STATUS_OPTIONS} from '../../common/constants/ReturnOrders.js'
import HeaderDropdown from '../../common/components/List/HeaderDropdown.js'
import DropdownItemButton from '../../common/components/Dropdown/DropdownItemButton.js'
import {setStatus} from './returnOrderListActions.js'
import {statusSelector} from './returnOrderListSelectors.js'

const DROPDOWN = 'DROPDOWN_FILTER_STATUS'

function ReturnOrderStatusDropdown({status, ...props}) {
  return (
    <HeaderDropdown label="Status" dropdown={DROPDOWN}>
      {RETURN_ORDER_STATUS_OPTIONS.map(({value, display}) => (
        <DropdownItemButton
          key={value}
          value={value}
          selectedValue={status}
          onClick={() => {
            if (status !== value) {
              props.setStatus(value)
            }
          }}
        >
          {display}
        </DropdownItemButton>
      ))}
    </HeaderDropdown>
  )
}

ReturnOrderStatusDropdown.propTypes = {
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    status: statusSelector(state),
  }
}

const mapDispatchToProps = {
  setStatus,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderStatusDropdown)
