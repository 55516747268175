import {
  updateLabelConfig,
  invoiceNumberErrorMessageSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {CANADA_POST, FEDEX} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'invoice_number'
const VALID_SHIPPER_TYPES = [CANADA_POST, FEDEX]

export default function InvoiceNumber() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const error = useSelector((state) =>
    invoiceNumberErrorMessageSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ConfigTextInput
      label="Invoice Number"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      errorMessage={error}
    />
  )
}
