import {ShippingInfoShape} from '../../../../../common/PropTypes.js'

export default function TrackingLink({shippingInfo}) {
  if (!shippingInfo.tracking_url) {
    return <span>{shippingInfo.tracking_number}</span>
  }

  return (
    <a
      href={shippingInfo.tracking_url}
      className="btn btn--link v-align-base"
      target="_blank"
      rel="noopener noreferrer"
    >
      {shippingInfo.tracking_number}
    </a>
  )
}

TrackingLink.propTypes = {
  shippingInfo: ShippingInfoShape.isRequired,
}
