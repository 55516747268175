import classNames from 'classnames'

import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import WeightInput from '../../../../common/components/Form/WeightInput.js'
import {
  updateParcel,
  parcelSelector,
  validateWeight,
} from '../../../../data/labelInfos/index.js'
import {setShowWeight, applyWeight} from '../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../bulkLabelConfigSelectors.js'
import UpdateOrdersButton from './UpdateOrdersButton.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function BulkWeight({labelInfoIDs}) {
  const {labelInfoID} = useLabelConfigContext()
  const form = useSelector(bulkLabelConfigFormSelector)
  const weight = useSelector(
    (state) =>
      parcelSelector(state, {labelInfoID, packagesIndex: 0}).weight || 0,
  )
  const error = validateWeight(weight)

  return (
    <dd className="list__item">
      <label htmlFor="bulk_weight">
        <input
          type="checkbox"
          id="bulk_weight"
          onChange={() => setShowWeight(!form.showWeight)}
          checked={form.showWeight}
        />
        <span className="margin-left-5">Weight</span>
      </label>
      {form.showWeight && (
        <div className="wrap--nested-form">
          <ul className="list--shipping-options">
            <li
              className={classNames(
                'list__item--shipping-options margin-bottom-0',
              )}
            >
              <WeightInput
                idLb="bulk-labelconfigform-weight-lb"
                weightOz={weight}
                setWeightOz={(weightOz) =>
                  updateParcel(labelInfoID, 0, {weight: weightOz})
                }
                showScaleControl
              />
            </li>

            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              disabled={weight === null || !!error}
              onClick={() => applyWeight(labelInfoIDs)}
              loading={form.loading__weight}
            />
          </ul>
        </div>
      )}
    </dd>
  )
}

BulkWeight.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
