import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase.js'
import isEmpty from 'lodash/isEmpty.js'

import handleListSelection from '../../common/handleListSelection.js'
import {ProductShape} from '../../common/PropTypes.js'
import PopoverImage from '../../common/components/PopoverImage.js'
import Currency from '../../common/components/Currency.js'
import Checkbox from '../../common/components/Checkbox.js'
import {
  preferCartOrderLineNamesSelector,
  usesInventorySelector,
  useInventoryAllocationSelector,
  showImgsOrderDetailSelector,
} from '../../data/company.js'
import {
  orderLineShippabilitySelector,
  orderLineShippabilityHintSelector,
  orderLineIsDropshipHintSelector,
  orderLineAllocationSelector,
  orderLineAllocationHintSelector,
} from '../../data/orders.js'
import {productSelector} from '../../data/products.js'
import {
  hasOrderFinancialsPermissionSelector,
  hasProductPermissionSelector,
} from '../../data/me.js'
import LineAllocationIcon from '../iverson/components/OrderListPage/Fields/LineAllocationIcon.js'
import {getProductDetailURL} from '../ProductDetailPage/productDetailSelectors.js'
import {showEditOrderLineModal} from '../OrderListPage/Modals/EditOrderLineModal.js'
import {showConfirmOrderLineDeleteModal} from '../OrderListPage/Modals/ConfirmOrderLineDeleteModal.js'
import {
  OrderDetailFormShape,
  updateOrderDetailForm,
  resetRowSelection,
} from './orderDetailActions.js'
import {orderDetailFormSelector} from './orderDetailSelectors.js'
import KitGraphLeaves from './KitGraphLeaves.js'
import {useSelector} from '../../store.js'

function ProductRow({
  orderNumber,
  line,
  readOnly,
  orderLineIDs,
  usesInventory,
  useInventoryAllocation,
  lineAllocationStatus,
  allocationHint,
  shippability,
  shippabilityHint,
  isDropshipHint,
  product,
  preferCartOrderLineNames,
  hasProductPermission,
  showImgsOrderDetail,
  form,
}) {
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )
  // when line is removed from order, cleanup selection on component unmount
  useEffect(() => () => resetRowSelection(line.id), [])

  if (!product) {
    return null
  }

  const productName =
    preferCartOrderLineNames && line.order_line_product_name
      ? line.order_line_product_name
      : product.name

  return (
    <tr>
      {!readOnly && (
        <td className="table__td">
          <Checkbox
            className="margin-bottom-0"
            checked={form.selectedOrderLineIDs.includes(line.id)}
            onClick={(checked, event) =>
              updateOrderDetailForm({
                selectedOrderLineIDs: handleListSelection({
                  value: line.id,
                  isSelected: checked,
                  isShiftKey: event.shiftKey,
                  selected: form.selectedOrderLineIDs,
                  list: orderLineIDs,
                }),
              })
            }
          />
        </td>
      )}
      <td
        className={classNames('table__td', {
          'wrap--shippability': usesInventory,
          ...(!isEmpty(shippability) && {
            [kebabCase(shippability.shippability)]:
              usesInventory && !useInventoryAllocation,
            [kebabCase(lineAllocationStatus)]: useInventoryAllocation,
            dropshippable: shippability.is_dropship && !useInventoryAllocation,
          }),
        })}
      >
        <div className="flex">
          {useInventoryAllocation && (
            <LineAllocationIcon
              allocationStatus={lineAllocationStatus}
              allocationHint={allocationHint}
              isDropship={shippability.is_dropship}
              isDropshipHint={isDropshipHint}
              className="inline-block fs-n0 margin-right-3 compact"
            />
          )}
          {usesInventory && !useInventoryAllocation && (
            <div
              className="list__item--line-detail inline-block tooltip--top"
              data-hint={shippabilityHint}
            >
              <span className="i--shippability fs-n1" aria-hidden="true" />
            </div>
          )}
          {showImgsOrderDetail && (
            <div>
              <PopoverImage src={product.image_url} alt={productName} />
            </div>
          )}
          <div>
            {hasProductPermission ? (
              <dl className="list">
                <dt className="list__title fs-n1 lh-md line-detail margin-bottom-0 wk--no-copypasta">
                  <a
                    className="link--line-detail wk--copypasta-hover"
                    href={getProductDetailURL(product.sku)}
                  >
                    <strong className="block">{productName}</strong>
                  </a>
                  {product.sku && (
                    <div className="unbold">
                      <span>SKU:</span>{' '}
                      <span className="wk--copypasta">{product.sku}</span>
                    </div>
                  )}
                  {product.upc && (
                    <div className="unbold">
                      <span>UPC:</span>{' '}
                      <span className="wk--copypasta">{product.upc}</span>
                    </div>
                  )}
                </dt>
              </dl>
            ) : (
              <dl className="list">
                <dt className="list__title fs-n1 lh-md line-detail margin-bottom-0">
                  {productName}
                </dt>
                <dd className="list__item fs-n1 lh-md margin-bottom-0">
                  {product.sku}
                </dd>
              </dl>
            )}
            {product.kit_components.length > 0 && (
              <KitGraphLeaves line={line} product={product} />
            )}
          </div>
        </div>
      </td>
      <td className="table__td">
        {line.details ? (
          <>
            <div className="fs-n1">
              <div>
                <strong className="text--lt-grey">Notes</strong>
              </div>
              <div className="fs-n1 ws-pre-wrap">{line.details}</div>
            </div>
            {!readOnly && (
              <button
                className="btn btn--link fs-n1 v-align-base"
                type="button"
                onClick={() =>
                  showEditOrderLineModal(orderNumber, [line.id], 'details')
                }
              >
                <span>Edit</span>
              </button>
            )}
          </>
        ) : !readOnly ? (
          <button
            className="btn btn--link fs-n1 v-align-base"
            type="button"
            onClick={() =>
              showEditOrderLineModal(orderNumber, [line.id], 'details')
            }
          >
            <span>Add a note</span>
          </button>
        ) : (
          ''
        )}
        {line.product_serial_numbers.length > 0 ? (
          <>
            <div className="fs-n1 divider--top sm">
              <div>
                <strong className="text--lt-grey">
                  Serial Number{line.product_serial_numbers.length > 1 && 's'}
                </strong>
              </div>
              <div>{line.product_serial_numbers.join(', ')}</div>
            </div>
            {!readOnly && (
              <button
                className="btn btn--link fs-n1 v-align-base"
                type="button"
                onClick={() =>
                  showEditOrderLineModal(
                    orderNumber,
                    [line.id],
                    'product_serial_numbers',
                  )
                }
              >
                <span>Edit</span>
              </button>
            )}
          </>
        ) : !readOnly ? (
          <div className="divider--top sm">
            <button
              className="btn btn--link fs-n1 v-align-base"
              type="button"
              onClick={() =>
                showEditOrderLineModal(
                  orderNumber,
                  [line.id],
                  'product_serial_numbers',
                )
              }
            >
              <span>Add serial number(s)</span>
            </button>
          </div>
        ) : (
          ''
        )}
      </td>
      <td className="table__td align-right">
        {!readOnly ? (
          <button
            className="btn btn--link darker fs-n1 v-align-base"
            type="button"
            onClick={() =>
              showEditOrderLineModal(orderNumber, [line.id], 'quantity')
            }
          >
            <strong>{line.quantity}</strong>
          </button>
        ) : (
          <span className="fs-n1">{line.quantity}</span>
        )}
      </td>
      {usesInventory && (
        <td className="table__td align-right">
          <span className="fs-n1">{product.total_available}</span>
        </td>
      )}
      {hasOrderFinancialsPermission && (
        <>
          <td
            className={classNames('table__td align-right', {
              'op-30': line.quantity <= 0,
            })}
          >
            {!readOnly ? (
              <button
                className="btn btn--link darker fs-n1 v-align-base"
                type="button"
                onClick={() =>
                  showEditOrderLineModal(orderNumber, [line.id], 'item_price')
                }
              >
                {line.quantity <= 0 ? (
                  <span>--</span>
                ) : (
                  <Currency value={line.total_price / (line.quantity || 1)} />
                )}
              </button>
            ) : (
              <span className="fs-n1">
                {line.quantity <= 0 ? (
                  <span>--</span>
                ) : (
                  <Currency value={line.total_price / (line.quantity || 1)} />
                )}
              </span>
            )}
          </td>
          <td className="table__td align-right">
            {!readOnly ? (
              <button
                className="btn btn--link darker fs-n1  v-align-base"
                type="button"
                onClick={() =>
                  showEditOrderLineModal(
                    orderNumber,
                    [line.id],
                    'discount_amount',
                  )
                }
              >
                <Currency value={line.discount_amount} />
              </button>
            ) : (
              <span className="fs-n1">
                <Currency value={line.discount_amount} />
              </span>
            )}
          </td>
          <td className="table__td align-right">
            {!readOnly ? (
              <button
                className="btn btn--link darker fs-n1 v-align-base"
                type="button"
                onClick={() =>
                  showEditOrderLineModal(orderNumber, [line.id], 'total_price')
                }
              >
                <Currency value={line.total_price} />
              </button>
            ) : (
              <span className="fs-n1">
                <Currency value={line.total_price} />
              </span>
            )}
          </td>
        </>
      )}
      <td className="table__td align-right">
        {!readOnly && (
          <button
            className="btn btn--link no-underline"
            type="button"
            title="Remove line from order"
            onClick={() =>
              showConfirmOrderLineDeleteModal(orderNumber, [line.id])
            }
          >
            <span className="i-trash fs-00" aria-hidden="true" />
          </button>
        )}
      </td>
    </tr>
  )
}

ProductRow.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  line: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  orderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,

  preferCartOrderLineNames: PropTypes.bool.isRequired,
  usesInventory: PropTypes.bool.isRequired,
  useInventoryAllocation: PropTypes.bool.isRequired,
  allocationHint: PropTypes.string,
  lineAllocationStatus: PropTypes.string,
  shippability: PropTypes.object,
  shippabilityHint: PropTypes.string,
  isDropshipHint: PropTypes.string,
  product: ProductShape,
  hasProductPermission: PropTypes.bool.isRequired,
  showImgsOrderDetail: PropTypes.bool.isRequired,
  form: OrderDetailFormShape.isRequired,
}

function mapStateToProps(state, {orderNumber, line: {id, sku}}) {
  return {
    preferCartOrderLineNames: preferCartOrderLineNamesSelector(state),
    usesInventory: usesInventorySelector(state),
    useInventoryAllocation: useInventoryAllocationSelector(state),
    lineAllocationStatus: orderLineAllocationSelector(state, {
      orderNumber,
      orderLineID: id,
    }),
    allocationHint: orderLineAllocationHintSelector(state, {
      orderNumber,
      orderLineID: id,
    }),
    shippability: orderLineShippabilitySelector(state, {
      orderNumber,
      orderLineID: id,
    }),
    shippabilityHint: orderLineShippabilityHintSelector(state, {
      orderNumber,
      orderLineID: id,
    }),
    isDropshipHint: orderLineIsDropshipHintSelector(state, {
      orderNumber,
      orderLineID: id,
    }),
    product: productSelector(state, {sku}),
    hasProductPermission: hasProductPermissionSelector(state),
    showImgsOrderDetail: showImgsOrderDetailSelector(state),
    form: orderDetailFormSelector(state),
  }
}

export default connect(mapStateToProps)(ProductRow)
