import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {getState, setForm, updateForm, formsSelector} from '../store.js'
import api from '../common/api.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {TAG_TYPE__PO} from '../common/constants/Tags.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'
import {refreshPurchaseOrderList} from '../ordoro/PurchaseOrderListPage/purchaseOrderListActions.js'
import {patchPOTag, posSelector} from './pos.js'
import userflow from '../common/analytics/userflow.js'

export const PO_TAGS = 'PO_TAGS'

export function setPOTags(poTags) {
  setForm(PO_TAGS, keyBy(poTags, 'id'))
}

export function updatePOTag(poTag) {
  updateForm(PO_TAGS, {[poTag.id]: poTag})
}

export function removePOTag(poTag) {
  const poTags = {...poTagsSelector(getState())}

  delete poTags[poTag.id]

  setForm(PO_TAGS, poTags)
}

export function poTagsSelector(state) {
  return formsSelector(state)[PO_TAGS] || poTagsSelector.default
}
poTagsSelector.default = {}

export function poTagSelector(state, {poTagID}) {
  return poTagsSelector(state)[poTagID]
}

export const poTagsSortedByNameSelector = createSelector(
  poTagsSelector,
  (poTags) => sortBy(poTags, ({name}) => name.toLowerCase()),
)

export const poTagOptionsSelector = createSelector(
  poTagsSortedByNameSelector,
  (tags) =>
    tags.map((tag) => ({
      value: tag.id,
      display: tag.name,
      entity: tag,
      type: TAG_TYPE__PO,
      nouns: getNounsFromValue(tag.name),
    })),
)

export const poTagOptionTokensSelector = createSelector(
  poTagOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function poTagsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PO_TAGS]
}

export async function getPOTags() {
  try {
    const poTags = await fetchAllAPI('/purchase_order/tag/', 'tag')

    setPOTags(poTags)

    userflow.updateGroup({
      po_tag_count: poTags.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting purchase order tags.',
        details: err.error_message || err.message,
      },
      err,
    )

    setPOTags([])
  }
}

export async function savePOTag(name, color, id) {
  const params = {name: name.trim(), color}
  const originalTag = id ? poTagSelector(getState(), {poTagID: id}) : null

  const {json} = id
    ? await api.put(
        `/purchase_order/tag/${encodeURIComponent(originalTag.name)}`,
        params,
      )
    : await api.post('/purchase_order/tag', params)

  updatePOTag(json)

  if (id) {
    await refreshPurchaseOrderList()
  }

  return json
}

export async function deletePOTag(tagID) {
  const tag = poTagSelector(getState(), {poTagID: tagID})

  await api.delete(`/purchase_order/tag/${encodeURIComponent(tag.name)}`)

  removePOTag(tag)
}

export async function addTagToPOs(poTagID, poIDs) {
  const pos = posSelector(getState())
  const tag = poTagSelector(getState(), {poTagID})

  const poIDsWithoutTag = poIDs.filter(
    (poID) => !get(pos, [poID, 'tags'], []).find((t) => t.id === poTagID),
  )

  poIDsWithoutTag.map((poID) => patchPOTag(poID, tag, true))

  try {
    await Promise.all(
      poIDsWithoutTag.map((poID) =>
        api.post(
          `/purchase_order/${encodeURIComponent(poID)}/tag/${encodeURIComponent(
            tag.name,
          )}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error adding purchase order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    poIDsWithoutTag.map((poID) => patchPOTag(poID, tag, false))
  }
}

export async function removeTagFromPOs(poTagID, poIDs) {
  const pos = posSelector(getState())
  const tag = poTagSelector(getState(), {poTagID})

  const poIDsWithTag = poIDs.filter((poID) =>
    get(pos, [poID, 'tags'], []).find((t) => t.id === poTagID),
  )

  poIDsWithTag.map((poID) => patchPOTag(poID, tag, false))

  try {
    await Promise.all(
      poIDsWithTag.map((poID) =>
        api.delete(
          `/purchase_order/${encodeURIComponent(poID)}/tag/${encodeURIComponent(
            tag.name,
          )}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error removing purchase order tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    poIDsWithTag.map((poID) => patchPOTag(poID, tag, true))
  }
}
