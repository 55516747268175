import PropTypes from 'prop-types'

import {useSelector} from '../../../store.js'
import Select from '../Common/Select.js'
import {cartVendorOptionsSelector} from '../../../data/carts.js'

export default function NewVendorSelect({className, value, onChange}) {
  const cartVendorOptions = useSelector(cartVendorOptionsSelector)

  return (
    <Select
      className={className}
      label="Sales Channel"
      name="cart_type"
      required
      value={value || ''}
      onChange={(event) => onChange(event.target.value)}
    >
      <option value="" disabled>
        Choose one to connect to...
      </option>
      {cartVendorOptions.map((vendor) => (
        <option key={vendor.value} value={vendor.value}>
          {vendor.display}
          {vendor.isBeta ? ' (beta)' : ''}
        </option>
      ))}
    </Select>
  )
}

NewVendorSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
