import PropTypes from 'prop-types'

import {CommentShape} from '../../../PropTypes.js'
import {
  STRIPE_PAYMENT_COMMENT,
  ADDRESS_COMMENT,
  BULK_UPDATE_COMMENT,
  BATCH_CREATE_DOCUMENT_COMMENT,
  BATCH_SORT_COMMENT,
} from '../../../constants/Comments.js'

import Comment from './Comment.js'
import StripeComment from './StripeComment.js'
import AddressComment from './AddressComment.js'
import BulkUpdateComment from './BulkUpdateComment.js'
import BatchCreateDocumentComment from './BatchCreateDocumentComment.js'
import BatchSortComment from './BatchSortComment.js'

export default function CommentGroup({day, comments}) {
  return (
    <div className="wrap--timeline-day">
      <h4 className="subheader--timeline-day">{day}</h4>
      {comments.map((comment, index) => {
        const key = `${comment.date}-${index}`

        if (comment.type === STRIPE_PAYMENT_COMMENT) {
          return <StripeComment key={key} comment={comment} />
        }

        if (comment.type === ADDRESS_COMMENT) {
          return <AddressComment key={key} comment={comment} />
        }

        if (comment.type === BULK_UPDATE_COMMENT) {
          return <BulkUpdateComment key={key} comment={comment} />
        }

        if (comment.type === BATCH_CREATE_DOCUMENT_COMMENT) {
          return <BatchCreateDocumentComment key={key} comment={comment} />
        }

        if (comment.type === BATCH_SORT_COMMENT) {
          return <BatchSortComment key={key} comment={comment} />
        }

        return <Comment key={key} comment={comment} />
      })}
    </div>
  )
}

CommentGroup.propTypes = {
  day: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(CommentShape).isRequired,
}
