import PropTypes from 'prop-types'
import pick from 'lodash/pick.js'

import {
  REPORTS_BY_TYPE,
  REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
  REPORT_EXPORT_PURCHASE_ORDER_DETAIL,
} from '../../../common/constants/Reports.js'
import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import {suppliersSortedByNameSelector} from '../../../data/suppliers.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'
import {warehousesSortedByNameSelector} from '../../../data/warehouses.js'
import {
  DEFAULT_STATUS,
  STATUS_FILTERS,
} from '../../../common/constants/PurchaseOrders.js'
import WarehouseMultiSelect from '../../../common/components/WarehouseMultiSelect.js'
import SupplierMultiSelect from '../../../common/components/SupplierMultiSelect.js'
import Select from '../../../common/components/Select.js'

export const NO_SHIPPER = 'No Shipper'

export function setupExportPOsForm(params = {}) {
  let {reportID} = params
  params = pick(params, ['poStatus', 'warehouseIDs', 'supplierIDs'])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  return {
    poStatus: reportParams.status || DEFAULT_STATUS,
    warehouseIDs: reportParams.warehouse_ids || [],
    supplierIDs: reportParams.supplier_ids || [],
    ...params,
  }
}

export function exportPOsErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportPOsPayloadSelector(state, {formName}) {
  const {reportType, poStatus, warehouseIDs, supplierIDs} = formSelector(
    state,
    {formName},
  )

  const payload = {
    type: reportType,
    params: {
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }

  if (poStatus !== 'all') {
    payload.params.status = poStatus
  }

  if (warehouseIDs.length) {
    payload.params.warehouse_ids = warehouseIDs
  }

  if (supplierIDs.length) {
    payload.params.supplier_ids = supplierIDs
  }

  return payload
}

export default function ExportPOsForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportPOsErrorsSelector(state, {formName}),
  )
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const suppliers = useSelector(suppliersSortedByNameSelector)

  const needsWarehouseSelect = warehouses.length > 1
  const needsSupplierSelect = suppliers.length > 1

  return (
    <ul className="list list--no-style">
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--form list__item--no-style">
        <Select
          label="Status"
          id="po_status"
          value={form.poStatus}
          onChange={(poStatus) => updateForm(formName, {poStatus})}
        >
          {STATUS_FILTERS.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
          <option value="all">All</option>
        </Select>
      </li>
      {form.reportID ? (
        <li className="list__item--form list__item--no-style">
          <p className="fs-00 margin-top-20 margin-bottom-10">
            <strong>
              Level of Detail:{' '}
              {form.reportType === REPORT_EXPORT_PURCHASE_ORDER_SUMMARY
                ? 'Order Summary'
                : 'Order Detail'}
            </strong>
          </p>
        </li>
      ) : (
        <li className="list__item--form list__item--no-style margin-bottom-15 padding-bottom-20">
          <Select
            label="Level of Detail"
            id="detail_level"
            value={form.reportType}
            onChange={(reportType) => updateForm(formName, {reportType})}
          >
            {[
              REPORTS_BY_TYPE[REPORT_EXPORT_PURCHASE_ORDER_SUMMARY],
              REPORTS_BY_TYPE[REPORT_EXPORT_PURCHASE_ORDER_DETAIL],
            ].map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </Select>
        </li>
      )}
      {(needsWarehouseSelect || needsSupplierSelect) && (
        <li className="list__item--no-style divider--top">
          <div className="row flex">
            {needsWarehouseSelect && (
              <div
                className={`medium-6 columns ${
                  needsSupplierSelect ? 'divider--right' : ''
                }`}
              >
                <p className="fs-01 margin-bottom-10">
                  <strong>Warehouses:</strong>
                </p>
                <WarehouseMultiSelect
                  scope="export_po_warehouse"
                  warehouseIDs={form.warehouseIDs}
                  onChange={(selected) =>
                    updateForm(formName, {
                      warehouseIDs: selected,
                    })
                  }
                />
              </div>
            )}
            {needsSupplierSelect && (
              <div className="medium-6 columns">
                <p className="fs-01 margin-bottom-10">
                  <strong>Suppliers:</strong>
                </p>
                <SupplierMultiSelect
                  scope="export_po_supplier"
                  supplierIDs={form.supplierIDs}
                  onChange={(selected) =>
                    updateForm(formName, {
                      supplierIDs: selected,
                    })
                  }
                />
              </div>
            )}
          </div>
        </li>
      )}
    </ul>
  )
}

ExportPOsForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
