import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import NumberInput from '../../../../common/components/Form/NumberInput.js'
import {
  getProductForInventoryTransferCreate,
  removeSKUFromCreateInventoryTransfer,
  setCreateInventoryTransferModalRowForm,
  updateCreateInventoryTransferModalRowForm,
  removeCreateInventoryTransferModalRowForm,
} from './createInventoryTransferModalActions.js'
import {
  createInventoryTransferModalRowFormSelector,
  errorsSelector,
  warningsSelector,
  sendingWarehouseIDSelector,
  receivingWarehouseIDSelector,
} from './createInventoryTransferModalSelectors.js'
import {
  productSelector,
  productWarehouseSelector,
} from '../../../../data/products.js'
import {
  ErrorsShape,
  ProductShape,
  ProductWarehouseShape,
} from '../../../../common/PropTypes.js'
import {isNumeric} from '../../../../common/utils.js'

function ProductRow({
  sku,
  row,
  autoFocus,
  product,
  errors,
  warnings,
  fromWarehouseInventory,
  toWarehouseInventory,
  ...props
}) {
  useEffect(() => {
    props.setCreateInventoryTransferModalRowForm(sku, {
      quantity: '1',
      line_notes: '',
      serverError: null,
    })

    return () => props.removeCreateInventoryTransferModalRowForm(sku)
  }, [])

  useEffect(() => {
    if (!product && !(row && row.serverError)) {
      props.getProductForInventoryTransferCreate(sku)
    }
  }, [product, row])

  if (!row) {
    return null
  }

  const deleteRow = (
    <td className="table__td align-right">
      <button
        type="button"
        className="btn btn--link no-underline"
        onClick={() => props.removeSKUFromCreateInventoryTransfer(sku)}
      >
        <span className="i-trash fs-00" aria-hidden="true" />
      </button>
    </td>
  )

  if (row.serverError) {
    return (
      <tr>
        <td className="table__td" colSpan="7">
          {row.serverError}
        </td>
        {deleteRow}
      </tr>
    )
  }

  if (!product) {
    return (
      <tr>
        <td className="table__td" colSpan="7">
          Loading...
        </td>
        {deleteRow}
      </tr>
    )
  }

  const {name} = product

  const fromPOH =
    fromWarehouseInventory &&
    fromWarehouseInventory.physical_on_hand - Number(row.quantity)
  const toPOH =
    toWarehouseInventory &&
    toWarehouseInventory.physical_on_hand + Number(row.quantity)

  return (
    <tr>
      <td className="table__td">
        <strong className="word-breaker">{name}</strong>
        <div className="word-breaker">{sku}</div>
        {errors.product && (
          <div className="fs-n2 error error-message align-center margin-top-5">
            {errors.product}
          </div>
        )}
      </td>
      <td className="table__td align-center">
        <strong>
          {fromWarehouseInventory ? fromWarehouseInventory.committed : '—'}
        </strong>
      </td>
      <td className="table__td align-center">
        <strong>{isNumeric(fromPOH) ? fromPOH : '—'}</strong>
      </td>
      <td className="table__td">
        <div className="flex--center">
          <NumberInput
            id="id_product_quantity"
            value={row.quantity}
            onChange={(value) =>
              props.updateCreateInventoryTransferModalRowForm(sku, {
                quantity: value,
              })
            }
            onIncrement={(value) =>
              props.updateCreateInventoryTransferModalRowForm(sku, {
                quantity: value,
              })
            }
            onDecrement={(value) =>
              props.updateCreateInventoryTransferModalRowForm(sku, {
                quantity: value,
              })
            }
            isInvalid={!!errors.quantity}
            min={0}
            autoFocus={autoFocus}
          />
        </div>
        {errors.quantity && (
          <div className="fs-n2 error error-message align-center margin-top-5">
            {errors.quantity}
          </div>
        )}
        {warnings.quantity && (
          <div className="fs-n2 text--warning align-center margin-top-5">
            {warnings.quantity}
          </div>
        )}
      </td>
      <td className="table__td align-center">
        <strong>
          {toWarehouseInventory ? toWarehouseInventory.committed : '—'}
        </strong>
      </td>
      <td className="table__td align-center">
        <strong>{isNumeric(toPOH) ? toPOH : '—'}</strong>
      </td>
      <td className="table__td">
        <div className="relative">
          <input
            type="text"
            className="input--text input--med-h margin-bottom-0"
            value={row.line_notes}
            onChange={(event) => {
              props.updateCreateInventoryTransferModalRowForm(sku, {
                line_notes: event.target.value,
              })
            }}
          />
        </div>
        {errors.line_notes && (
          <div className="fs-n2 error error-message align-center margin-top-5">
            {errors.line_notes}
          </div>
        )}
      </td>
      {deleteRow}
    </tr>
  )
}

ProductRow.propTypes = {
  sku: PropTypes.string.isRequired,
  row: PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    line_notes: PropTypes.string.isRequired,
    serverError: PropTypes.string,
  }),
  autoFocus: PropTypes.bool.isRequired,
  fromWarehouseInventory: ProductWarehouseShape,
  toWarehouseInventory: ProductWarehouseShape,
  product: ProductShape,
  errors: ErrorsShape.isRequired,
  warnings: ErrorsShape.isRequired,
  setCreateInventoryTransferModalRowForm: PropTypes.func.isRequired,
  updateCreateInventoryTransferModalRowForm: PropTypes.func.isRequired,
  removeCreateInventoryTransferModalRowForm: PropTypes.func.isRequired,
  getProductForInventoryTransferCreate: PropTypes.func.isRequired,
  removeSKUFromCreateInventoryTransfer: PropTypes.func.isRequired,
}

function mapStateToProps(state, {sku}) {
  const sendingWarehouseID = sendingWarehouseIDSelector(state)
  const receivingWarehouseID = receivingWarehouseIDSelector(state)

  return {
    product: productSelector(state, {sku}),
    fromWarehouseInventory: productWarehouseSelector(state, {
      sku,
      warehouseID: sendingWarehouseID,
    }),
    toWarehouseInventory: productWarehouseSelector(state, {
      sku,
      warehouseID: receivingWarehouseID,
    }),
    row: createInventoryTransferModalRowFormSelector(state, {sku}),
    errors: errorsSelector(state, {sku}),
    warnings: warningsSelector(state, {sku}),
  }
}

const mapDispatchToProps = {
  setCreateInventoryTransferModalRowForm,
  updateCreateInventoryTransferModalRowForm,
  removeCreateInventoryTransferModalRowForm,
  getProductForInventoryTransferCreate,
  removeSKUFromCreateInventoryTransfer,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductRow)
