import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty.js'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {setProduct, saveProduct, nameSelector} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshProductList} from '../productListFunctions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const SAVE_PRODUCT_PRICE = 'SAVE_PRODUCT_PRICE'
export const PRODUCT_PRICE_MODAL = 'PRODUCT_PRICE_MODAL'

export function showProductPriceModal(sku, price) {
  return setForm(PRODUCT_PRICE_MODAL, {
    sku,
    price: String(price) || '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PRODUCT_PRICE_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PRODUCT_PRICE_MODAL)
}

export function saveProductPrice() {
  return {
    type: SAVE_PRODUCT_PRICE,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCT_PRICE_MODAL],
)

export const errorsSelector = createSelector(modalFormSelector, ({price}) => {
  const errors = {}

  if (!isPresent(price)) {
    errors.price = 'Price is required'
  } else if (!isPositiveNumeric(price)) {
    errors.price = 'Price must be a positive number'
  }

  return errors
})

export function* saveProductPriceWorker() {
  try {
    const {sku, price} = yield select(modalFormSelector)
    const params = {
      price: Number(price),
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const product = yield call(saveProduct, sku, params)

    yield call(setProduct, product)

    yield put(closeModal())

    yield call(showMessageToast, 'Saved product price')

    yield call(refreshProductList)
  } catch (err) {
    yield put(updateModalForm({serverError: err.message || err.error_message}))
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function ProductPriceModal({form, errors, productName, ...props}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Edit Manual Order Price"
      modalSize="sm"
      onConfirm={() => props.saveProductPrice()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <div className="fs-01">
        <strong>{productName}</strong>
      </div>
      <div className="fs-00 margin-bottom-15">
        <span>SKU:</span> {form.sku}
      </div>
      <CurrencyInput
        className="biggie margin-bottom-0"
        width="sm"
        value={form.price}
        onChange={(price) =>
          props.updateForm(PRODUCT_PRICE_MODAL, {price: `${price}`})
        }
        errorMessage={errors.price}
        autoFocus
      />
    </ConfirmModal>
  )
}

ProductPriceModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  productName: PropTypes.string.isRequired,
  saveProductPrice: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    errors: errorsSelector(state),
    productName: nameSelector(state, {sku: form.sku}),
  }
}

const mapDispatchToProps = {
  saveProductPrice,
  closeModal,
  updateForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ProductPriceModal),
  modalFormSelector,
)
