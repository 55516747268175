import {useEffect} from 'react'

import {useSelector, onlyIfForm} from '../../store.js'
import {usePurchaseOrdersSelector} from '../../data/company.js'
import {poListFormSelector} from './purchaseOrderListSelectors.js'

import Modals from './Modals/index.js'
import POListItemsCount from './POListItemsCount.js'
import RefreshPOs from './RefreshPOs.js'
import POExpandAll from './POExpandAll.js'
import POSort from './POSort.js'
import Actions from './Actions.js'
import ActionsForSelected from './ActionsForSelected.js'
import List from './List.js'
import POOmniBar from './POOmniBar.js'
import POFeaturePage from './POFeaturePage.js'
import {hasPurchaseOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {POListFormShape} from './purchaseOrderListActions.js'
import {showPOListFilterModal} from './Modals/POListFilterModal/poListFilterModalFunctions.js'

function PurchaseOrderListPage({form}) {
  useEffect(() => {
    document.title = 'Purchase Orders'
  }, [])

  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const hasPurchaseOrderPermission = useSelector(
    hasPurchaseOrderPermissionSelector,
  )

  if (!usePurchaseOrders) {
    return <POFeaturePage />
  }

  if (!hasPurchaseOrderPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <POListItemsCount />
                <RefreshPOs />
                <POExpandAll />
                <li className="inline-block divider--left">
                  <button
                    className="btn btn--primary btn--xx-sm btn--primary-ol"
                    type="button"
                    onClick={() => showPOListFilterModal()}
                  >
                    <span
                      className="i-filters fs-00 lh-0 v-align-middle margin-right-5"
                      aria-hidden="true"
                    />
                    <span className="fs-n1 v-align-middle">Filters</span>
                  </button>
                </li>
                <POSort />
                {form.selectedPOIDs.length ? (
                  <ActionsForSelected />
                ) : (
                  <Actions />
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns ">
            <POOmniBar />
          </div>
        </div>
        <List />
      </div>
    </div>
  )
}

PurchaseOrderListPage.propTypes = {
  form: POListFormShape,
}

export default onlyIfForm(PurchaseOrderListPage, poListFormSelector)
