import INITIAL_STATE from '../INITIAL_STATE.js'
import {
  SET_PRINT_CONFIGS,
  UPDATE_PRINT_CONFIG,
} from '../../actions/data/printConfigs.js'

export default function reducer(
  state = INITIAL_STATE.data.printConfigs,
  action = {},
) {
  if (action.type === SET_PRINT_CONFIGS) {
    return action.payload
  }

  if (action.type === UPDATE_PRINT_CONFIG) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    }
  }

  return state
}
