import {useEffect} from 'react'
import analytics from '../../../common/analytics/index.js'

export default function APIKeysFeaturePage() {
  useEffect(() => {
    analytics.trackEvent('api_keys_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
      <div
        className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-02 lh-md  margin-bottom-15">
        <strong>API access is not enabled in your account.</strong>
      </p>
      <p className="fs-01 margin-bottom-20">
        To get this feature,{' '}
        <a
          className="btn--link mid border-underline meta--feature-lock-settings-api"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          reach out to us
        </a>
        .
      </p>
      <div className="margin-bottom-10">
        <a
          className="btn btn--primary btn--primary-alt btn--md meta--feature-lock-settings-api"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Talk to a Product Expert
        </a>
      </div>
    </div>
  )
}
