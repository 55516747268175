import {getState, updateForm, formSelector} from '../../../store.js'
import api from '../../../common/api.js'
import {NEW_ID} from '../../../common/constants/SettingsPanels.js'
import {setCart} from '../../../data/carts.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {DASHBOARD_PAGE} from '../../../common/constants/Pages.js'

export const SETTINGS_SALES_CHANNELS = 'SETTINGS_SALES_CHANNELS'

export function setupSalesChannelsForm() {
  return {
    formName: SETTINGS_SALES_CHANNELS,
    initialForm: {
      newVendor: '',
      isSaving: false,
      serverError: null,
    },
  }
}

export function updateSalesChannelsForm(...args) {
  updateForm(SETTINGS_SALES_CHANNELS, ...args)
}

export async function saveSalesChannel(formName) {
  const cartForm = formSelector(getState(), {formName})

  if (!cartForm) {
    return
  }

  const isNew = cartForm.id === NEW_ID

  if (isNew && cartForm.authorize) {
    const currentPage = currentPageSelector(getState())

    await cartForm.authorize(
      formName,
      currentPage === DASHBOARD_PAGE ? '/app/dashboard' : undefined,
    )

    return
  }

  if (!cartForm.saveParamsSelector) {
    return
  }

  try {
    const params = cartForm.saveParamsSelector(getState(), {formName})

    if (isNew) {
      params.type = cartForm.vendor
    }

    updateForm(formName, {
      serverError: null,
      isSaving: true,
    })

    const {json} = await (isNew
      ? api.post('/cart/', params)
      : api.put(`/cart/${cartForm.id}/`, params))

    setCart(json)

    showMessageToast(
      `${isNew ? 'Added' : 'Updated'} sales channel successfully.`,
    )
  } catch (err) {
    updateForm(formName, {
      serverError: err,
    })
  }

  updateForm(formName, {
    isSaving: false,
  })
}
