import {showHazmatModal} from '../../OrderListPage/Modals/HazmatModal.js'
import {
  canAddHazmatItemSelector,
  canHaveHazMatItemsSelector,
  hazmatItemsSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {showRemoveHazmatItemModal} from '../../OrderListPage/Modals/RemoveHazmatItemModal.js'
import EnableLabelProperty from '../Fields/EnableLabelProperty.js'
import useLabelProperty from '../Fields/useLabelProperty.js'
import {UPS} from '../../../common/constants/ShipperNames.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY_HAZMAT_ITEMS = 'packages[0].hazmat_items'
const VALID_SHIPPER_TYPES = [UPS]

export default function AttachHazmatInfo() {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()
  const hazmatItems = useSelector((state) =>
    hazmatItemsSelector(state, {labelInfoID, packageIndex: 0}),
  )
  const canAddHazmatItem = useSelector((state) =>
    canAddHazmatItemSelector(state, {
      labelInfoID,
      packageIndex: 0,
      shipperType,
    }),
  )
  const canHaveHazMatItems = useSelector((state) =>
    canHaveHazMatItemsSelector(state, {labelInfoID, shipperType}),
  )

  useLabelProperty({
    labelProperty: LABEL_PROPERTY_HAZMAT_ITEMS,
    validShipperTypes: VALID_SHIPPER_TYPES,
  })

  if (!VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  if (!canHaveHazMatItems) {
    return null
  }

  return (
    <li
      className={className`list__item--shipping-options margin-bottom-15 ${
        !isLabelEnableMode && 'divider--top divider--bottom'
      }`}
    >
      <div className="wrap--edit-preset-form-input">
        <div className="fs-n0 lh-sm margin-bottom-7">
          <strong>Hazardous Materials Declarations</strong>
        </div>
        <ul className="list list--square margin-bottom-0">
          {hazmatItems.map((hazmatItem, index) => (
            <li className="list__item lh-sm margin-bottom-5" key={index}>
              <span className="fs-n0 margin-right-7">
                {hazmatItem.proper_shipping_name || 'Unknown Hazmat Item'} (
                {hazmatItem.quantity || 0})
              </span>
              <button
                className="btn btn--link inline-block fs-n1 v-align-base divider--right sm"
                type="button"
                onClick={() =>
                  showHazmatModal(labelInfoID, shipperType, 0, index)
                }
              >
                Edit
              </button>
              <button
                className="btn btn--link no-underline inline-block fs-n1 v-align-base"
                type="button"
                onClick={() => showRemoveHazmatItemModal(labelInfoID, 0, index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
        {canAddHazmatItem && (
          <div>
            <button
              className="btn btn--link inline-block fs-n1 v-align-base"
              type="button"
              onClick={() => showHazmatModal(labelInfoID, shipperType, 0)}
            >
              Add a declaration
            </button>
          </div>
        )}
      </div>
      <EnableLabelProperty labelProperty={LABEL_PROPERTY_HAZMAT_ITEMS} />
    </li>
  )
}
