import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {
  PRODUCT_DETAIL_WAREHOUSES_PANEL,
  PRODUCT_DETAIL_PANELS,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../common/constants/Products.js'
import {directKitParentsSelector} from '../../data/directKitParents.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  productSelector,
  productWarehousesSelector,
  productSuppliersSelector,
  productCartsSelector,
  productKitComponentsSelector,
  productBOMComponentsSelector,
  canUseProductsSelector,
} from '../../data/products.js'
import {formsSelector} from '../../redux/selectors/ui/forms.js'

export const PRODUCT_DETAIL = 'PRODUCT_DETAIL'

export const productDetailFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCT_DETAIL],
)

export function getProductDetailURL(sku, panel) {
  return `#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(sku)}${
    panel ? `/${panel}` : ''
  }`
}

export function productDetailURLSelector(state, {sku, panel}) {
  return getProductDetailURL(sku, panel)
}

export const productDetailLocationSelector = createSelector(
  locationSelector,
  (location) => {
    const {
      pathComponents: [resource],
    } = location

    const isThisPage = resource === PRODUCT_SINGLE_URI_COMPONENT

    if (!isThisPage) {
      return null
    }

    return location
  },
)
export const skuSelector = createSelector(
  productDetailLocationSelector,
  (location) => {
    if (!location) {
      return null
    }

    return location.pathComponents[1] || null
  },
)

export const currentPanelSelector = createSelector(
  productDetailLocationSelector,
  (location) => {
    if (!location) {
      return null
    }

    const panel = location.pathComponents[2]

    return PRODUCT_DETAIL_PANELS.includes(panel)
      ? panel
      : PRODUCT_DETAIL_WAREHOUSES_PANEL
  },
)

export const productDetailHashSelector = createSelector(
  skuSelector,
  currentPanelSelector,
  (state) => canUseProductsSelector(state),
  (sku, currentPanel, canUseProducts) => {
    if (!canUseProducts || !sku) {
      return null
    }

    const hash = `#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(sku)}`

    if (currentPanel && currentPanel !== PRODUCT_DETAIL_WAREHOUSES_PANEL) {
      return `${hash}/${currentPanel}`
    }

    return hash
  },
)

export function selectedWarehouseIDsSelector(state) {
  return get(productDetailFormSelector(state), 'selectedWarehouseIDs', [])
}

export function selectedSupplierIDsSelector(state) {
  return get(productDetailFormSelector(state), 'selectedSupplierIDs', [])
}

export function selectedCartIDsSelector(state) {
  return get(productDetailFormSelector(state), 'selectedCartIDs', [])
}

export function selectedKitComponentSKUsSelector(state) {
  return get(productDetailFormSelector(state), 'selectedKitComponentSKUs', [])
}

export function selectedBOMComponentSKUsSelector(state) {
  return get(productDetailFormSelector(state), 'selectedBOMComponentSKUs', [])
}

export const hasLoadedProductDetailPageSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return !!productSelector(state, {sku})
  },
  (state) => {
    const sku = skuSelector(state)

    return !!directKitParentsSelector(state, {sku})
  },
  (hasProduct, hasKitGraph) => hasProduct && hasKitGraph,
)

export const productWarehouseIDsSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return productWarehousesSelector(state, {sku})
  },
  (productWarehouses) => productWarehouses.map(({id}) => id),
)

export const productSupplierIDsSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return productSuppliersSelector(state, {sku})
  },
  (productSuppliers) => productSuppliers.map(({id}) => id),
)

export const productCartIDsSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return productCartsSelector(state, {sku})
  },
  (productCarts) => productCarts.map(({id}) => id),
)

export const kitComponentsSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return productKitComponentsSelector(state, {sku})
  },
  (productKitComponents) => productKitComponents,
)

export const kitComponentSKUsSelector = createSelector(
  kitComponentsSelector,
  (kitComponents) => kitComponents.map(({sku}) => sku),
)

export const bomComponentsSelector = createSelector(
  (state) => {
    const sku = skuSelector(state)

    return productBOMComponentsSelector(state, {sku})
  },
  (productBOMComponents) => productBOMComponents,
)

export const bomComponentSKUsSelector = createSelector(
  bomComponentsSelector,
  (bomComponents) => bomComponents.map(({sku}) => sku),
)

export const areAllWarehousesSelectedSelector = createSelector(
  productWarehouseIDsSelector,
  selectedWarehouseIDsSelector,
  (productWarehouseIDs, selected) =>
    productWarehouseIDs.length > 0 &&
    productWarehouseIDs.length === selected.length,
)

export const areIndeterminateWarehousesSelectedSelector = createSelector(
  areAllWarehousesSelectedSelector,
  selectedWarehouseIDsSelector,
  (allSelected, selectedWarehouseIDs) =>
    !allSelected && selectedWarehouseIDs.length > 0,
)

export const areAllSuppliersSelectedSelector = createSelector(
  productSupplierIDsSelector,
  selectedSupplierIDsSelector,
  (productSupplierIDs, selected) =>
    productSupplierIDs.length > 0 &&
    productSupplierIDs.length === selected.length,
)

export const areIndeterminateSuppliersSelectedSelector = createSelector(
  areAllSuppliersSelectedSelector,
  selectedSupplierIDsSelector,
  (allSelected, selectedSupplierIDs) =>
    !allSelected && selectedSupplierIDs.length > 0,
)

export const areAllCartsSelectedSelector = createSelector(
  productCartIDsSelector,
  selectedCartIDsSelector,
  (productCartIDs, selected) =>
    productCartIDs.length > 0 && productCartIDs.length === selected.length,
)

export const areIndeterminateCartsSelectedSelector = createSelector(
  areAllCartsSelectedSelector,
  selectedCartIDsSelector,
  (allSelected, selectedCartIDs) => !allSelected && selectedCartIDs.length > 0,
)

export const areAllKitComponentsSelectedSelector = createSelector(
  kitComponentSKUsSelector,
  selectedKitComponentSKUsSelector,
  (kitComponentSKUs, selected) =>
    kitComponentSKUs.length > 0 && kitComponentSKUs.length === selected.length,
)

export const areIndeterminateKitComponentsSelectedSelector = createSelector(
  areAllKitComponentsSelectedSelector,
  selectedKitComponentSKUsSelector,
  (allSelected, selectedKitComponentSKUs) =>
    !allSelected && selectedKitComponentSKUs.length > 0,
)

export const areAllBOMComponentsSelectedSelector = createSelector(
  bomComponentSKUsSelector,
  selectedBOMComponentSKUsSelector,
  (bomComponentSKUs, selected) =>
    bomComponentSKUs.length > 0 && bomComponentSKUs.length === selected.length,
)

export const areIndeterminateBOMComponentsSelectedSelector = createSelector(
  areAllBOMComponentsSelectedSelector,
  selectedBOMComponentSKUsSelector,
  (allSelected, selectedBOMComponentSKUs) =>
    !allSelected && selectedBOMComponentSKUs.length > 0,
)
