import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {getServerInfo} from '../../redux/actions/data/serverInfo.js'

export class ListenToServerInfo extends Component {
  componentDidMount() {
    this.listen()
  }

  componentWillUnmount() {
    this.stop = true
  }

  listen() {
    if (this.stop) {
      return
    }

    this.props.getServerInfo()

    setTimeout(() => this.listen(), 60000)
  }

  render() {
    return null
  }
}

ListenToServerInfo.propTypes = {
  getServerInfo: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  getServerInfo,
}

export default connect(null, mapDispatchToProps)(ListenToServerInfo)
