import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'

const MODAL_FORM = 'EDIT_MOCK_BARCODE_TEXTS_FORM'

export function showEditMockBarcodeTextsModal() {
  setForm(MODAL_FORM, {
    textsText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockBarcodeTextsModal({form}) {
  const query = useSelector(querySelector)
  useEffect(() => {
    updateModalForm({
      textsText: ensureArray(query.text).join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Barcode Texts"
      onConfirm={() => {
        const texts = form.textsText
          .replace(/[,]/g, '\n')
          .split('\n')
          .filter((text) => !!text)
          .map((text) => text.trim())

        const values = ensureArray(query.value)

        while (values.length !== texts.length) {
          if (texts.length < 1) {
            break
          }

          if (values.length > texts.length) {
            texts.push(texts[texts.length - 1])
          } else {
            texts.pop()
          }
        }

        mockAbodeUpdateResourceQuery({text: texts})

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock Barcode Texts</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Barcode Texts"
            rows="10"
            id="texts"
            value={form.textsText || ''}
            onChange={(value) => updateModalForm({textsText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockBarcodeTextsModal.propTypes = {
  form: PropTypes.shape({
    textsText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockBarcodeTextsModal, modalFormSelector)
