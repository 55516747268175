import {useSelector} from '../../../store.js'
import {usePresetsSelector} from '../../../data/company.js'
import SettingsPanel from '../SettingsPanel.js'
import PresetsFeaturePage from './PresetsFeaturePage.js'

export default function Presets() {
  const usePresets = useSelector(usePresetsSelector)

  if (!usePresets) {
    return <PresetsFeaturePage />
  }

  return (
    <SettingsPanel
      header="Shipping Presets"
      subHeader={
        <div>
          Spending a ton of time filling out shipping information to create
          labels? Preset it and forget it with{' '}
          <a
            href="https://support.ordoro.com/how-to-create-shipping-label-presets/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shipping Presets
          </a>
          , a feature allowing you to set, save, and apply a preset that
          auto-populates shipping parameters to orders individually or in bulk.
        </div>
      }
    >
      <div className="row">
        <div className="medium-8 columns">
          <img
            className="screenshot w-100"
            src="https://d5317a4084cb2c704676-1b363405a2121a59c7e368bec7f5e127.ssl.cf2.rackcdn.com/__settings-images/create-preset-sm-01.gif"
            alt="animated gif of preset creation process"
          />
        </div>
        <div className="medium-4 columns">
          <dl className="list">
            <dt className="list__title fs-01 lh-sm margin-bottom-15">
              How They Work
            </dt>
            <dd className="fs-00 margin-bottom-15">
              Choose from a variety shipping specifics — such as shipping
              carrier, method, ship date, and product dimensions — save them as
              a preset, and apply them to orders in bulk to create triple-digit
              batches of labels in only a couple of clicks.
            </dd>
            <dd className="fs-00 margin-bottom-15">
              Let’s say you constantly ship an expensive item through Priority
              Mail, always purchasing shipping insurance for it to cover any
              liability. Instead of inputting shipping information for every
              one-off order, set up a preset — select USPS and Priority Mail as
              carrier and method, enable shipping insurance, plug in any
              additional information like a ship-from address, name the preset,
              and save it.
            </dd>
            <dd className="fs-00 margin-bottom-15">
              Once your order arrives, click your new preset, apply it, and hit
              the “Create Label” button to process labels for every order en
              masse.
            </dd>
          </dl>
        </div>
      </div>
    </SettingsPanel>
  )
}
