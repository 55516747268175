import {CommentShape} from '../../../PropTypes.js'
import User from './User.js'
import FormattedDate from '../../FormattedDate.js'
import Join from '../../Join.js'

export default function BatchCreateDocumentComment({
  comment: {date, user, docs, printer_name},
}) {
  const parts = []
  if (docs.includes('label')) {
    parts.push('shipping label')
  }
  if (docs.includes('packing')) {
    parts.push('packing list')
  }
  if (docs.includes('pick')) {
    parts.push('pick list')
  }

  return (
    <dl className="list--order-data list--timeline-point">
      <dt className="list__item--order-data list__title--timeline-point">
        <FormattedDate value={date} format="LT" />
      </dt>
      <dd className="list__item--order-data list__item--timeline-point">
        Task started to create a <Join array={parts} /> document.
      </dd>
      {printer_name && (
        <dd className="list__item--order-data list__item--timeline-point">
          The document will then be InstaPrinted from this printer:{' '}
          {printer_name}
        </dd>
      )}
      <dd className="list__item--order-data list__item--timeline-point">
        {user && <User email={user} />}
      </dd>
    </dl>
  )
}

BatchCreateDocumentComment.propTypes = {
  comment: CommentShape,
}
