import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  estimatedDeliveryDateSelector,
  deliveredDateSelector,
} from '../../../../../../../data/orders.js'

import LabelItem from './LabelItem.js'
import ShipDate from '../../../Fields/ShipDate.js'

function ShipEstimatedOrDeliveredDate({estimatedDeliveryDate, deliveredDate}) {
  if (!estimatedDeliveryDate && !deliveredDate) {
    return null
  }

  return (
    <LabelItem
      label={deliveredDate ? 'Delivered Date' : 'Estimated Delivery Date'}
    >
      <ShipDate date={deliveredDate || estimatedDeliveryDate} />
    </LabelItem>
  )
}

ShipEstimatedOrDeliveredDate.propTypes = {
  estimatedDeliveryDate: PropTypes.string,
  deliveredDate: PropTypes.string,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    estimatedDeliveryDate: estimatedDeliveryDateSelector(state, {
      orderNumber,
    }),
    deliveredDate: deliveredDateSelector(state, {
      orderNumber,
    }),
  }
}

export default connect(mapStateToProps)(ShipEstimatedOrDeliveredDate)
