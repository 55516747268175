import PropTypes from 'prop-types'
import {useEffect, useMemo} from 'react'
import classNames from 'classnames'

import {
  productTagOptionsSelector,
  productTagOptionTokensSelector,
  productTagsHaveLoadedSelector,
} from '../../../../data/productTags.js'
import {useSelector} from '../../../../store.js'
import {getConditionOption} from './conditionalOptions.js'
import ManageTagFilter from '../../../../common/components/ManageTagFilter.js'
import {TAG_TYPE__PRODUCT} from '../../../../common/constants/Tags.js'
import {hasProductManageTagPermissionSelector} from '../../../../data/me.js'
import {showEditTagModal} from '../../../../common/components/Modal/EditTagModal.js'

export default function ConditionValueProductTagSelect({
  condition,
  ruleID,
  index,
  error,
  onChange,
}) {
  const option = getConditionOption(condition)
  const valueParam = option.getValueParam(condition)
  const value = option.getValue(condition)

  const productTagOptions = useSelector(productTagOptionsSelector)
  const productTagOptionTokens = useSelector(productTagOptionTokensSelector)
  const hasProductManageTagPermission = useSelector(
    hasProductManageTagPermissionSelector,
  )
  const productTagsHaveLoaded = useSelector(productTagsHaveLoadedSelector)

  const tagQuantityMap = useMemo(
    () =>
      productTagOptions.reduce((prev, option) => {
        prev[option.entity.id] = option.entity.id === value ? 'all' : 'none'

        return prev
      }, {}),
    [value, productTagOptions],
  )

  useEffect(() => {
    // If someone deletes a tag that is yet-to-be-saved on a rule, then default it.
    if (
      valueParam === 'id' &&
      productTagOptions.length > 0 &&
      (!value ||
        (productTagsHaveLoaded &&
          !productTagOptions.find((option) => option.entity.id === value)))
    ) {
      onChange(productTagOptions[0].value)
    }
  }, [value, valueParam, productTagOptions, productTagsHaveLoaded])

  if (valueParam === 'id' && value === null) {
    return null
  }

  return (
    <div className={classNames('flex-item', {error})}>
      {valueParam === 'id' ? (
        <>
          <ManageTagFilter
            label="Select a tag"
            dropdown={`RULE_PRODUCT_TAG_FILTER__${ruleID}__${index}`}
            buttonClassName="fs-00"
            onSelect={(tag) => onChange(tag.id)}
            tagOptions={productTagOptions}
            tagOptionTokens={productTagOptionTokens}
            tagQuantityMap={tagQuantityMap}
            hasManageTagPermission={hasProductManageTagPermission}
            tagType={TAG_TYPE__PRODUCT}
            onCreateAndApplyClick={() =>
              showEditTagModal(TAG_TYPE__PRODUCT, null, {
                addProductTagToRuleOnChange: onChange,
              })
            }
            isSingleSelect
            hasErrors={!!error}
          />
          {error && <small className="error error-message">{error}</small>}
        </>
      ) : (
        <input
          className="input--h-29 margin-bottom-0"
          type="text"
          name="value"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      )}
    </div>
  )
}

ConditionValueProductTagSelect.propTypes = {
  condition: PropTypes.object.isRequired,
  ruleID: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
}
