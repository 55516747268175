import INITIAL_STATE from '../INITIAL_STATE.js'

import {OPEN_MODAL, CLOSE_MODAL} from '../../actions/ui/index.js'

export default function modal(state = INITIAL_STATE.ui.modal, action = {}) {
  if (action.type === OPEN_MODAL) {
    return {
      ...state,
      current: action.payload.modalName,
    }
  }

  if (action.type === CLOSE_MODAL) {
    if (action.payload.meta && action.payload.meta.isCancelled) {
      return {
        queue: [],
        current: null,
      }
    }

    return {
      ...state,
      current: null,
    }
  }

  return state
}
