import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import api from '../../../common/api.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {setPO} from '../../../data/pos.js'

export const MODAL_FORM = 'CANCEL_PO_MODAL'

export function showCancelPOModal(poIDs, shouldRestore = false) {
  setForm(MODAL_FORM, {
    poIDs,
    shouldRestore,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function cancelPO(id, shouldRestore) {
  const {json} = await api.post(
    `/purchase_order/${encodeURIComponent(id)}/${
      shouldRestore ? 'uncancel' : 'cancel'
    }/`,
  )

  setPO(json)
}

export async function cancelPOs() {
  const {poIDs, shouldRestore} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    const poCount = poIDs.length

    await Promise.all(poIDs.map((id) => cancelPO(id, shouldRestore)))

    showMessageToast(
      `${poCount} PO${poCount !== 1 ? 's were' : ' was'} ${
        shouldRestore ? 'restored' : 'cancelled'
      }`,
    )

    closeModal()

    await refreshPurchaseOrderList()
  } catch (err) {
    updateModalForm({
      serverError: `Error ${shouldRestore ? 'restoring' : 'canceling'} PO: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function CancelPOModal({form}) {
  const poCount = form.poIDs.length
  const verb = form.shouldRestore ? 'Restore' : 'Cancel'

  return (
    <ConfirmModal
      title={`${verb} Purchase Order`}
      onConfirm={() => cancelPOs()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      {verb} {poCount} PO{poCount !== 1 ? 's' : ''}?
    </ConfirmModal>
  )
}

CancelPOModal.propTypes = {
  form: PropTypes.shape({
    poIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    shouldRestore: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(CancelPOModal, modalFormSelector)
