import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {RETURN_ORDER_STATUS_DISPLAY} from '../../common/constants/ReturnOrders.js'
import {AddressShape, TagsShape} from '../../common/PropTypes.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import Address from '../../common/components/Order/Address.js'
import Timeline from '../../common/components/Details/Timeline/index.js'
import {
  showRMAReturnToAddressModal,
  showRMAReturnCustomerAddressModal,
} from '../../redux/actions/data/returnOrders.js'
import {
  createReturnOrderSelector,
  createReturnOrderOriginalOrderNumberSelector,
  createReturnOrderStatusSelector,
  returnToAddressSelector,
  createReturnOrderCreatedDateSelector,
  createReturnOrderUpdatedDateSelector,
  createReturnOrderCustomerAddressSelector,
  createReturnOrderCommentGroupsSelector,
  createReturnOrderCustomerNotesSelector,
  createReturnOrderInternalNotesSelector,
  createReturnOrderTagsSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {showAddReturnOrderCommentModal} from '../ReturnOrderListPage/Modals/AddReturnOrderCommentModal.js'
import {showUpdateReturnOrderCustomerNotesModal} from '../ReturnOrderListPage/Modals/UpdateReturnOrderCustomerNotesModal.js'
import {showUpdateReturnOrderInternalNotesModal} from '../ReturnOrderListPage/Modals/UpdateReturnOrderInternalNotesModal.js'
import ReturnOrderTags from '../ReturnOrderListPage/ReturnOrderTags.js'
import {returnOrderDetailReferenceIDSelector} from './returnOrderDetailSelectors.js'
import Lines from './Lines.js'
import RMAShippedInfo from './RMAShippedInfo.js'

export function Details({
  referenceID,
  originalOrderNumber,
  returnOrderStatus,
  returnToAddress,
  createdDate,
  updatedDate,
  customerAddress,
  customerNotes,
  internalNotes,
  commentGroupsSelector,
  tags,
  ...props
}) {
  return (
    <>
      <div className="flex margin-bottom-25">
        <h2 className="page__title page__title--inner inline-block v-align-middle margin-right-15">
          <strong>{referenceID}</strong>
        </h2>
        {tags.length !== 0 && (
          <div className="inline-block v-align-middle">
            <ReturnOrderTags referenceID={referenceID} tags={tags} />
          </div>
        )}
      </div>
      <div className="flex">
        <div className="medium-3 columns wrap__list--order-data padding-left-0">
          <dl className="list list--order-data">
            <dt className="list__title--order-data">RMA Summary</dt>
            <dd className="list__item--order-data">
              <strong>Created: </strong>
              <span className="fs-n1">
                <FormattedDate value={createdDate} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={createdDate} format="h:mma" />
              </span>
            </dd>
            <dd className="list__item--order-data">
              <strong>Updated: </strong>
              <span className="fs-n1">
                <FormattedDate value={updatedDate} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={updatedDate} format="h:mma" />
              </span>
            </dd>
            <dd className="list__item--order-data">
              <strong>Status: </strong>
              <span className="fs-n1">
                {RETURN_ORDER_STATUS_DISPLAY[returnOrderStatus]}
              </span>
            </dd>
            {originalOrderNumber && (
              <dd className="list__item--order-data">
                <strong>Originating Order ID:</strong>{' '}
                <LinkButton
                  className="v-align-base"
                  href={`#/order/${encodeURIComponent(originalOrderNumber)}`}
                >
                  {originalOrderNumber}
                </LinkButton>
              </dd>
            )}
          </dl>
          <dl className="list list--order-data with-top-divider">
            <dt className="list__title--order-data">Customer Notes</dt>
            {customerNotes ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{customerNotes}</div>
                <ButtonLink
                  className="meta-returnorderdetail-button-edit-customer-note"
                  onClick={() =>
                    props.showUpdateReturnOrderCustomerNotesModal(referenceID)
                  }
                >
                  Edit
                </ButtonLink>
              </dd>
            ) : (
              <dd className="list__item--order-data">
                <ButtonLink
                  className="meta-returnorderdetail-button-add-customer-note"
                  onClick={() =>
                    props.showUpdateReturnOrderCustomerNotesModal(referenceID)
                  }
                >
                  Add note
                </ButtonLink>
              </dd>
            )}
          </dl>
          <dl className="list list--order-data with-top-divider">
            <dt className="list__title--order-data">Internal Notes</dt>
            {internalNotes ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{internalNotes}</div>
                <ButtonLink
                  className="meta-returnorderdetail-button-edit-internal-note"
                  onClick={() =>
                    props.showUpdateReturnOrderInternalNotesModal(referenceID)
                  }
                >
                  Edit
                </ButtonLink>
              </dd>
            ) : (
              <dd className="list__item--order-data">
                <ButtonLink
                  className="meta-returnorderdetail-button-add-internal-note"
                  onClick={() =>
                    props.showUpdateReturnOrderInternalNotesModal(referenceID)
                  }
                >
                  Add note
                </ButtonLink>
              </dd>
            )}
          </dl>
        </div>
        <div className="medium-4 columns wrap__list--order-data">
          <Address
            title="Return From"
            address={customerAddress}
            onEdit={() => showRMAReturnCustomerAddressModal(referenceID)}
          />
          <div className="with-top-divider" />
          <Address
            title="Return To"
            address={returnToAddress}
            onEdit={() => showRMAReturnToAddressModal(referenceID)}
            noPhone
            noEmail
          />
        </div>
        <RMAShippedInfo />
      </div>
      <hr className="hr--detail-page" />
      <Lines />
      <hr className="hr--detail-page" />
      <Timeline
        commentGroupsSelector={commentGroupsSelector}
        readOnly={false}
        addComment={() => props.showAddReturnOrderCommentModal(referenceID)}
      />
    </>
  )
}

Details.propTypes = {
  referenceID: PropTypes.string.isRequired,
  originalOrderNumber: PropTypes.string,
  returnOrderStatus: PropTypes.string.isRequired,
  returnToAddress: AddressShape.isRequired,
  createdDate: PropTypes.string.isRequired,
  updatedDate: PropTypes.string.isRequired,
  customerAddress: AddressShape.isRequired,
  customerNotes: PropTypes.string,
  internalNotes: PropTypes.string,
  tags: TagsShape.isRequired,
  commentGroupsSelector: PropTypes.func.isRequired,
  showUpdateReturnOrderCustomerNotesModal: PropTypes.func.isRequired,
  showUpdateReturnOrderInternalNotesModal: PropTypes.func.isRequired,
  showAddReturnOrderCommentModal: PropTypes.func.isRequired,
}

function mapStateToProps(_state) {
  const referenceID = returnOrderDetailReferenceIDSelector(_state)
  const returnOrderSelector = createReturnOrderSelector(referenceID)
  const returnOrderStatusSelector =
    createReturnOrderStatusSelector(returnOrderSelector)
  const returnOrderOriginalOrderNumberSelector =
    createReturnOrderOriginalOrderNumberSelector(returnOrderSelector)
  const createdDateSelector =
    createReturnOrderCreatedDateSelector(returnOrderSelector)
  const updatedDateSelector =
    createReturnOrderUpdatedDateSelector(returnOrderSelector)
  const customerAddressSelector =
    createReturnOrderCustomerAddressSelector(returnOrderSelector)
  const commentGroupsSelector =
    createReturnOrderCommentGroupsSelector(returnOrderSelector)
  const customerNotesSelector =
    createReturnOrderCustomerNotesSelector(returnOrderSelector)
  const internalNotesSelector =
    createReturnOrderInternalNotesSelector(returnOrderSelector)
  const tagsSelector = createReturnOrderTagsSelector(returnOrderSelector)

  return (state) => ({
    referenceID,
    originalOrderNumber: returnOrderOriginalOrderNumberSelector(state),
    returnOrderStatus: returnOrderStatusSelector(state),
    returnToAddress: returnToAddressSelector(state, {referenceID}),
    createdDate: createdDateSelector(state),
    updatedDate: updatedDateSelector(state),
    customerAddress: customerAddressSelector(state),
    customerNotes: customerNotesSelector(state),
    internalNotes: internalNotesSelector(state),
    tags: tagsSelector(state),
    commentGroupsSelector,
  })
}

const mapDispatchToProps = {
  showUpdateReturnOrderCustomerNotesModal,
  showUpdateReturnOrderInternalNotesModal,
  showAddReturnOrderCommentModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
