import PropTypes from 'prop-types'
import classNames from 'classnames'

import Dropdown from '../Dropdown/index.js'

export default function HeaderDropdown({
  label,
  dropdown,
  noInnerScroll,
  className,
  children,
  right,
}) {
  return (
    <Dropdown
      label={label}
      dropdown={dropdown}
      is="li"
      className={classNames('inline-block margin-left-10', className)}
      buttonClassName="btn--filter"
      noInnerScroll={noInnerScroll}
      right={right}
    >
      {children}
    </Dropdown>
  )
}

HeaderDropdown.propTypes = {
  label: PropTypes.node.isRequired,
  dropdown: PropTypes.string.isRequired,
  noInnerScroll: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  right: PropTypes.bool,
}
