import colors from 'color'

export function isColor(color) {
  try {
    colors(color)
  } catch (err) {
    return false
  }

  return true
}

export function tagColors(color) {
  let colorObject
  try {
    colorObject = colors(color)
  } catch (err) {
    colorObject = colors('maroon')
  }

  return colorObject.isDark()
    ? {
        color: 'rgba(255, 255, 255, 0.9)',
        borderColor: 'transparent',
        backgroundColor: colorObject.rgb().string(),
      }
    : {
        color: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.3)',
        backgroundColor: colorObject.rgb().string(),
      }
}
