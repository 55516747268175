import PropTypes from 'prop-types'
import {useState} from 'react'
import cn from '../className.js'
import WindowFocusListener from './WindowFocusListener.js'
import {userSettingSelector} from '../../data/me.js'
import {useSelector} from '../../store.js'

export default function FocusAlert({className, children}) {
  const [needsFocus, setNeedsFocus] = useState(false)
  const showFocusAlert = useSelector((state) =>
    userSettingSelector(state, {
      setting: 'show_focus_alert',
      defaultValue: true,
    }),
  )

  return (
    <>
      {showFocusAlert && (
        <WindowFocusListener
          onFocus={() => setNeedsFocus(false)}
          onBlur={() => setNeedsFocus(true)}
        />
      )}
      {needsFocus && showFocusAlert ? (
        <div className={cn`wrap--scan-alert ${className}`}>
          <div className="alert alert--warning-lt alert--lg align-center margin-bottom-0">
            <div
              className="i-exclamation-triangle fs-04 op-50 lh-sm margin-bottom-5"
              aria-hidden="true"
            />
            <p className="fs-01 lh-md margin-bottom-0">
              <strong>
                Barcode scans will not register unless Ordoro is the active tab
                in your browser
              </strong>
            </p>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  )
}

FocusAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
