import PropTypes from 'prop-types'
import {useEffect} from 'react'
import isEqual from 'lodash/isEqual.js'

import {
  formsSelector,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../store.js'
import {CALL_UP_PAGE} from '../../common/constants/Pages.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {navigate} from '../../common/location.js'
import UPSAuthForm, {
  UPS_AUTH_FORM,
} from '../settings/Shippers/Forms/UPSForm/UPSAuthForm.js'
import {isLoadingSelector} from '../../redux/selectors/ui/index.js'

export const CALL_UP_URI_COMPONENT = 'call_up'

export function setupCallUpForm() {
  setForm(CALL_UP_PAGE, {})
}

export function updateCallUpForm(updates, meta) {
  updateForm(CALL_UP_PAGE, updates, meta)
}

export function removeCallUpForm() {
  removeForm(CALL_UP_PAGE)
}

export function callUpFormSelector(state) {
  return formsSelector(state)[CALL_UP_PAGE]
}

export function callUpQuerySelector(state) {
  callUpQuerySelector.cache = callUpQuerySelector.cache || {result: {}}
  const cache = callUpQuerySelector.cache

  const location = locationSelector(state)

  if (location.pathComponents[0] !== CALL_UP_URI_COMPONENT) {
    return cache.result
  }

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  const {query} = location

  const result = {
    context: query.context ? JSON.parse(query.context) : {},
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export function applyCallUpContext(context) {
  if (!context || Object.keys(context).length === 0) {
    return
  }

  updateCallUpForm({context})

  return navigate([CALL_UP_URI_COMPONENT])
}

export function callParentContext(func, args = []) {
  if (!window.top) {
    return
  }

  window.top.postMessage({type: CALL_UP_PAGE, func, args})
}

function CallUpPage({form}) {
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    document.title = 'Call Up'
  }, [])

  useEffect(() => {
    if (isLoading) {
      return
    }

    callParentContext('onCallUpLoaded')
  }, [isLoading])

  if (!form.context || isLoading) {
    return null
  }

  return (
    <div>{form.context.component === UPS_AUTH_FORM && <UPSAuthForm />}</div>
  )
}

CallUpPage.propTypes = {
  form: PropTypes.shape({
    context: PropTypes.shape({
      component: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default onlyIfForm(CallUpPage, callUpFormSelector)
