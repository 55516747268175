import '../common/libs.js'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'

import {IS_LOCAL_ENV} from '../common/constants/index.js'
import ErrorBoundary from '../common/components/ErrorBoundary.js'
import {setBootstrapData} from '../redux/actions/index.js'
import configureStore from '../redux/configureOrdoroStore.js'

import App from './App.js'

export function start() {
  window.store = configureStore({debug: IS_LOCAL_ENV})

  window.store.dispatch(setBootstrapData(window.ORDORO_BOOTSTRAP))

  const body = document.querySelector('body')

  const app = document.createElement('div')
  app.classList.add('root')
  body.insertBefore(app, body.firstChild)
  const root = createRoot(app)

  root.render(
    <ErrorBoundary>
      <Provider store={window.store}>
        <App />
      </Provider>
    </ErrorBoundary>,
  )
}
