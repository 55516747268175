import PropTypes from 'prop-types'
import {Component} from 'react'

import TextInput from '../../../../common/components/Form/TextInput.js'
import Checkbox from '../../../../common/components/Form/Checkbox.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import {
  MANUAL_TRACKING_SHIPPER_NAMES,
  toSnake,
} from '../../../../common/constants/ShipperNames.js'
import BaseEditForm from '../BaseEditForm.js'

export default class EditForm extends Component {
  componentDidMount() {
    this.handleCarrierName()
  }

  componentDidUpdate() {
    this.handleCarrierName()
  }

  onOrderNumber = (value) => this.updatePayload({order_number: value})
  onShipmentDate = (value) => this.updatePayload({ship_date: value})
  onCostChanged = (value) => this.updatePayload({cost: value})
  onTrackingNumberChanged = (value) =>
    this.updatePayload({tracking_number: value})
  onCarrierNameChanged = (value) => this.updatePayload({carrier_name: value})
  onCustomShipperChanged = (value) =>
    this.updatePayload({custom_shipper: value})
  onShippingMethodChanged = (value) =>
    this.updatePayload({shipping_method: value})
  onNotifyShipToChanged = (value) => this.updatePayload({notify_ship_to: value})
  onNotifyBillToChanged = (value) => this.updatePayload({notify_bill_to: value})
  onNotifyCart = (value) => this.updatePayload({notify_cart: value})

  updatePayload = (payloadDelta) => {
    this.props.updatePayload(this.props.data.index, payloadDelta)
  }

  handleCarrierName() {
    // FIXME: Stop sweeping laziness and apathy under the rug
    // https://github.com/ordoro/ordoro/issues/4775
    const shipper = this.props.data.payload.carrier_name
    if (shipper !== toSnake(shipper)) {
      this.updatePayload({carrier_name: toSnake(shipper)})
    }
  }

  renderOptions() {
    const options = [{value: '', display: ''}, ...MANUAL_TRACKING_SHIPPER_NAMES]

    return options.map(({display, value}) => (
      <option key={value} value={value}>
        {display}
      </option>
    ))
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <TextInput
            value={data.payload.order_number}
            errorMessage={data.errors.order_number}
            name="order_number"
            label="Order Number"
            className="medium-12"
            onChange={this.onOrderNumber}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.ship_date}
            errorMessage={data.errors.ship_date}
            name="ship_date"
            label="Shipment Date"
            className="medium-8"
            onChange={this.onShipmentDate}
          />
          <TextInput
            value={data.payload.cost || ''}
            errorMessage={data.errors.cost}
            name="cost"
            label="Cost"
            className="medium-4"
            onChange={this.onCostChanged}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.tracking_number}
            errorMessage={data.errors.tracking_number}
            name="tracking_number"
            label="Tracking Number"
            className="medium-12"
            onChange={this.onTrackingNumberChanged}
          />
        </Row>
        <Row>
          <Select
            value={data.payload.carrier_name}
            errorMessage={data.errors.carrier_name}
            name="carrier_name"
            label="Carrier Name"
            className="columns medium-6 wrap--full-select"
            selectClassName="full-width"
            onChange={this.onCarrierNameChanged}
          >
            {this.renderOptions()}
          </Select>
          {data.payload.carrier_name === 'other' && (
            <TextInput
              value={data.payload.custom_shipper}
              errorMessage={data.errors.custom_shipper}
              name="custom_shipper"
              label="Custom Carrier"
              className="medium-6"
              onChange={this.onCustomShipperChanged}
            />
          )}
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_method}
            errorMessage={data.errors.shipping_method}
            name="shipping_method"
            label="Shipment Method"
            className="medium-6"
            onChange={this.onShippingMethodChanged}
          />
        </Row>
        <Row>
          <div className="medium-12 columns margin-top-5 flex--justify">
            <Checkbox
              checked={data.payload.notify_ship_to}
              errorMessage={data.errors.notify_ship_to}
              className="columns column--reset margin-right-3"
              name="notify_ship_to"
              label="Notify Ship To"
              onChange={this.onNotifyShipToChanged}
            />
            <Checkbox
              checked={data.payload.notify_bill_to}
              errorMessage={data.errors.notify_bill_to}
              className="columns column--reset margin-right-3"
              name="notify_bill_to"
              label="Notify Bill To"
              onChange={this.onNotifyBillToChanged}
            />
            <Checkbox
              checked={data.payload.notify_cart}
              errorMessage={data.errors.notify_cart}
              className="column--reset"
              name="notify_cart"
              label="Notify Cart"
              onChange={this.onNotifyCart}
            />
          </div>
        </Row>
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
