import {useEffect} from 'react'
import {createSelector} from 'reselect'
import {CardElement, Elements, useElements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  dispatch,
  useSelector,
  onlyIfForm,
} from '../../store.js'
import {isPresent} from '../../common/utils.js'
import {STRIPE_PUBLIC_KEY} from '../../common/constants/index.js'
import {STARTER_PLAN, EXPRESS_PLAN} from '../../common/constants/Plans.js'
import api from '../../common/api.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  planSelector,
  hasCreditCardSelector,
  isVendorConnectSelector,
  isInTrialSelector,
  canNotReactivateSelector,
  setCompany,
  canInputPromoCodeSelector,
  usePaymentAccountsForCCSelector,
} from '../../data/company.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {showReactivationLockedModal} from './ReactivationLockedModal.js'
import deferPromise from '../../common/deferPromise.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {CALL_UP_PAGE} from '../../common/constants/Pages.js'
import {verifyUser} from '../../redux/actions/ui/userValidity.js'
import TextInput from '../../common/components/TextInput.js'
import {
  isValidatingUserSelector,
  isUserValidSelector,
} from '../../redux/selectors/ui/userValidity.js'
import {
  createPaymentAccount,
  STRIPE_PA,
  stripePaymentAccountSelector,
  updatePaymentAccount,
} from '../../data/paymentAccounts.js'

const MODAL_FORM = 'CREDIT_CARD_MODAL'

export function showCreditCardModal() {
  const deferredPromise = deferPromise()

  setForm(MODAL_FORM, {
    name: '',
    promo_code: '',
    stripe: null,
    isCardComplete: false,
    isSaving: false,
    serverError: null,
    deferredPromise,
    stripePromise: null,
  })

  return deferredPromise.promise
}

export async function determineCreditCardNeed() {
  const currentPage = currentPageSelector(getState())

  if (currentPage === CALL_UP_PAGE) {
    return false
  }

  const needsCreditCardModal = needsCreditCardModalSelector(getState())
  const canNotReactivate = canNotReactivateSelector(getState())

  if (canNotReactivate) {
    showReactivationLockedModal()

    return
  }

  if (needsCreditCardModal) {
    await showCreditCardModal()
  }
}

export function needsTrialNoticeSelector(state) {
  const plan = planSelector(state)

  if (plan !== EXPRESS_PLAN) {
    return false
  }

  const isInTrial = isInTrialSelector(state)

  if (!isInTrial) {
    return false
  }

  const hasCreditCard = hasCreditCardSelector(state)

  return !hasCreditCard
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(hasCreditCard = false) {
  const form = modalFormSelector(getState())

  removeForm(MODAL_FORM)

  form.deferredPromise.resolve(hasCreditCard)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({name, isCardComplete}) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
      errors.preventSave = true
    }

    if (!isCardComplete) {
      errors.preventSave = true
    }

    return errors
  },
)

export const needsCreditCardModalSelector = createSelector(
  hasCreditCardSelector,
  planSelector,
  isVendorConnectSelector,
  isInTrialSelector,
  (hasCreditCard, plan, isVendorConnect, isInTrial) =>
    !hasCreditCard && plan !== STARTER_PLAN && !isVendorConnect && !isInTrial,
)

export async function saveCreditCard() {
  const {name, promo_code, stripePromise, elements} =
    modalFormSelector(getState())

  updateModalForm({isSaving: true, serverError: null})

  try {
    const stripe = await stripePromise

    const cardElement = elements.getElement(CardElement)
    const response = await stripe.createToken(cardElement, {name})

    if (response.error) {
      throw response.error
    }

    if (!response.token) {
      throw new Error('Unable to add credit card')
    }

    const token = response.token.id

    if (usePaymentAccountsForCCSelector(getState())) {
      const stripePaymentAccount = stripePaymentAccountSelector(getState())

      if (stripePaymentAccount) {
        await updatePaymentAccount(stripePaymentAccount.id, {
          stripe_config: {token},
        })
      } else {
        await createPaymentAccount({
          payment_backend: STRIPE_PA,
          payment_type: 'credit-card',
          token,
        })
      }
    } else {
      let {json: company} = await api.put('/company/credit_card/', {
        token,
      })

      if (promo_code) {
        const {json} = await api.put('/company', {
          source: JSON.stringify({...company.source, promo_code}),
        })

        company = json
      }

      setCompany(company)
    }

    showMessageToast('Credit card updated')

    closeModal(true)
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function CreditCardForm({form}) {
  const elements = useElements()
  const errors = useSelector(errorsSelector)

  useEffect(() => {
    updateModalForm({elements})
  }, [elements])

  const isValidatingUser = useSelector(isValidatingUserSelector)
  const isUserValid = useSelector(isUserValidSelector)
  const canInputPromoCode = useSelector(canInputPromoCodeSelector)

  useEffect(() => {
    dispatch(verifyUser())
  }, [])

  if (isValidatingUser) {
    return (
      <div className="loading align-center margin-top-20 margin-bottom-20">
        <span className="list-processing animate-spin v-align-middle" />
      </div>
    )
  }

  if (!isUserValid) {
    return (
      <div className="alert alert--error alert--lg full-border align-center margin-top-20 margin-bottom-20">
        <div
          className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-10"
          aria-hidden="true"
        />

        <p className="fs-00 error margin-bottom-5">
          <strong>There was a ReCAPTCHA verification error.</strong>
        </p>
        <p className="fs-00 error margin-bottom-0">
          Please contact Ordoro Support for help.
        </p>
      </div>
    )
  }

  return (
    <ul className="list list--form margin-bottom-10">
      <li className="list__item--form flex">
        <div className="w-75">
          <TextInput
            id="billing_name"
            label="Name on Card"
            required
            value={form.name}
            onChange={(value) =>
              updateModalForm({
                name: value,
                hasChanged_name: true,
              })
            }
            errorMessage={form.hasChanged_name && errors.name}
          />
        </div>
      </li>
      <li className="list__item--form">
        <div className="w-75 wrap--stripe-element">
          <label htmlFor="cart_element">
            Credit Card Info<span className="required">*</span>
          </label>
          <CardElement
            onChange={(event) => {
              updateModalForm({
                isCardComplete: event.complete,
              })
            }}
          />
        </div>
      </li>
      {canInputPromoCode && (
        <li className="list__item--form flex">
          <div>
            <TextInput
              id="promo_code"
              label="Promo Code"
              value={form.promo_code}
              onChange={(value) =>
                updateModalForm({
                  promo_code: value,
                })
              }
              errorMessage={errors.promo_code}
            />
          </div>
        </li>
      )}
    </ul>
  )
}

function CreditCardModal({form}) {
  const errors = useSelector(errorsSelector)
  const needsCreditCardModal = useSelector(needsCreditCardModalSelector)
  const hasCreditCard = useSelector(hasCreditCardSelector)
  const needsTrialNotice = useSelector(needsTrialNoticeSelector)

  useEffect(() => {
    updateModalForm({
      stripePromise: loadStripe(STRIPE_PUBLIC_KEY),
    })
  }, [])

  return (
    <ConfirmModal
      title={`${hasCreditCard ? 'Update' : 'Add'} Subscription Credit Card`}
      modalSize="sm"
      onConfirm={() => saveCreditCard()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
      preventClose={needsCreditCardModal}
    >
      {needsTrialNotice && (
        <div className="alert alert--warning margin-bottom-20">
          <p className="fs-n0 margin-bottom-0">
            When your 15-day trial has completed, the card you enter will be
            billed on a monthly basis for your Ordoro subscription fee. You will
            not be charged any monthly Ordoro fees if you cancel before the
            trial has concluded.
          </p>
        </div>
      )}
      <Elements stripe={form.stripePromise}>
        <CreditCardForm form={form} />
      </Elements>
    </ConfirmModal>
  )
}

export default onlyIfForm(CreditCardModal, modalFormSelector)
