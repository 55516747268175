import {
  PRODUCT_SORT_OPTIONS,
  PRODUCT_SORT_OPTIONS_BASIC,
} from '../../common/constants/Products.js'
import Sort from '../../common/components/List/Sort.js'
import {usesInventorySelector} from '../../data/company.js'
import {productListQuerySelector, setSort} from './productListFunctions.js'
import {useSelector} from '../../store.js'

export default function ProductSort() {
  const usesInventory = useSelector(usesInventorySelector)
  const query = useSelector(productListQuerySelector)

  const sortOptions = usesInventory
    ? PRODUCT_SORT_OPTIONS
    : PRODUCT_SORT_OPTIONS_BASIC

  return (
    <Sort
      items={sortOptions}
      value={query.sort}
      onChange={(value) => setSort(value)}
    />
  )
}
