import {useEffect, useState} from 'react'
import isEqual from 'lodash/isEqual.js'

export default function useStable(data) {
  const [stableData, setStableData] = useState(data)

  useEffect(() => {
    if (!isEqual(stableData, data)) {
      setStableData(data)
    }
  }, [data])

  return stableData
}
