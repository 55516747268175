import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {OrderShape, CartShape} from '../../common/PropTypes.js'
import {isNumeric} from '../../common/utils.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import {
  AWAITING_FULFILLMENT,
  ORDER_STATUS_BY_SLUG,
} from '../../common/constants/Orders.js'
import OrderData from '../iverson/components/OrderListPage/OrderListRow/OrderData/index.js'
import OrderListAddress from '../iverson/components/OrderListPage/Fields/OrderListAddress.js'
import ParentOrder from '../iverson/components/OrderListPage/Fields/ParentOrder.js'
import Currency from '../../common/components/Currency.js'
import {useInventoryAllocationSelector} from '../../data/company.js'
import {
  orderSelector,
  getIsAnUnsplitOrder,
  getIsCancelled,
  isReadOnlyOrderSelector,
  isPrimeOrderSelector,
  createOrderCommentGroupsSelector,
  orderAddressCanBeEditedSelector,
  getDeliverByDate,
  orderCartSelector,
  grandTotalSelector,
  getShipByDate,
  showOrderShippingAddressModal,
  showOrderBillingAddressModal,
  getBatchOrderErrors,
  isAmazonOrderSelector,
} from '../../data/orders.js'
import {showAddOrderCommentModal} from '../OrderListPage/Modals/AddOrderCommentModal.js'
import {
  showOrderCustomerNotesModal,
  showOrderInternalNotesModal,
} from '../OrderListPage/Modals/OrderTextFieldModal.js'
import DeliverByDate from '../iverson/components/OrderListPage/Fields/DeliverByDate.js'
import ProductInfo from './ProductInfo.js'
import Timeline from '../../common/components/Details/Timeline/index.js'
import {orderDetailOrderNumberSelector} from './orderDetailSelectors.js'
import ShipByDate from '../iverson/components/OrderListPage/Fields/ShipByDate.js'
import {useSelector} from '../../store.js'
import {hasOrderFinancialsPermissionSelector} from '../../data/me.js'
import {buildBatchDetailURL} from '../BatchListPage/batchListSelectors.js'
import Link from '../../common/components/Link/Link.js'
import {orderListHashBuilder} from '../OrderListPage/orderListSelectors.js'

function Details({
  order,
  cart,
  grandTotal,
  isPrimeOrder,
  orderAddressCanBeEdited,
  orderHasBeenSplit,
  readOnly,
  orderCommentGroupsSelector,
}) {
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )
  const isAmazonOrder = useSelector((state) =>
    isAmazonOrderSelector(state, {orderNumber: order.order_number}),
  )

  if (!order) {
    return null
  }

  const isCancelled = getIsCancelled(order)
  const deliverByDate = getDeliverByDate(order)
  const shipByDate = getShipByDate(order)
  const batchDetailURL = order.batch_reference_id
    ? buildBatchDetailURL(order.batch_reference_id)
    : null
  const batchErrors = getBatchOrderErrors(order)

  return (
    <div>
      <div className="wrap--row flex">
        <div className="medium-4 columns wrap__list--order-data padding-left-0">
          <dl className="list list--order-data">
            <dt className="list__title--order-data">Order Summary</dt>
            <dd className="list__item--order-data">
              <strong className="margin-right-3">Ordered:</strong>
              <span>
                <FormattedDate
                  value={order.order_placed_date}
                  format="MMM D, YYYY"
                />
                {' at '}
                <FormattedDate value={order.order_placed_date} format="h:mma" />
              </span>
            </dd>

            <dd className="list__item--order-data">
              <strong className="margin-right-3">Status:</strong>
              <span>{ORDER_STATUS_BY_SLUG[order.status].name}</span>
            </dd>
            <dd className="list__item--order-data">
              <strong className="margin-right-3">Sales Channel:</strong>
              <span>{cart && cart.name}</span>
            </dd>
            {order.financial.credit_card_issuer && (
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Credit Card Issuer:</strong>
                <span>{order.financial.credit_card_issuer}</span>
              </dd>
            )}
            {shipByDate && (
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Ship By:</strong>
                <span>
                  <ShipByDate date={shipByDate} isAmazonDate={isAmazonOrder} />
                </span>
              </dd>
            )}
            {deliverByDate && (
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Deliver By:</strong>
                <span>
                  <DeliverByDate
                    date={deliverByDate}
                    isAmazonDate={isAmazonOrder}
                  />
                </span>
              </dd>
            )}
            {hasOrderFinancialsPermission && (
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Total:</strong>
                {isNumeric(grandTotal) ? (
                  <Currency value={grandTotal} />
                ) : (
                  <span>--</span>
                )}
              </dd>
            )}
          </dl>
          {orderHasBeenSplit && (
            <dl className="list list--order-data list--order-items with-top-divider">
              <dd className="list__item--order-data">
                <ParentOrder
                  orderNumber={order.order_number}
                  parentOrderNumber={order.parent_order_number}
                />
              </dd>
            </dl>
          )}
          {order.batch_reference_id && (
            <dl className="list list--order-data list--order-items divider--top mid">
              <dt className="list__title--order-data">Batch Info</dt>
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Batch ID:</strong>
                <span>
                  <Link href={batchDetailURL}>{order.batch_reference_id}</Link>
                </span>
              </dd>
              {batchErrors.length > 0 && (
                <dd className="list__item--order-data error">
                  <div>
                    <strong>Batch Action Errors:</strong>
                  </div>
                  <ul className="list list--square">
                    {batchErrors.map((error, index) => (
                      <li
                        className="list__item fs-n1 margin-top-3 margin-bottom-0"
                        key={index}
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                </dd>
              )}
            </dl>
          )}
          <dl className="list list--order-data list--order-items divider--top mid">
            <dt className="list__title--order-data">Customer Notes</dt>
            {order.notes_from_customer ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{order.notes_from_customer}</div>
                {!readOnly && (
                  <button
                    className="btn btn--link meta-orderdetail-button-edit-customer-note"
                    type="button"
                    onClick={() =>
                      showOrderCustomerNotesModal(order.order_number)
                    }
                  >
                    Edit
                  </button>
                )}
              </dd>
            ) : (
              <dd className="list__item--order-data">
                {!readOnly && (
                  <button
                    className="btn btn--link meta-orderdetail-button-add-customer-note"
                    type="button"
                    onClick={() =>
                      showOrderCustomerNotesModal(order.order_number)
                    }
                  >
                    Add note
                  </button>
                )}
              </dd>
            )}
          </dl>{' '}
          <dl className="list list--order-data list--order-items divider--top mid">
            <dt className="list__title--order-data">Internal Notes</dt>
            {order.internal_notes ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{order.internal_notes}</div>
                {!readOnly && (
                  <button
                    className="btn btn--link meta-orderdetail-button-edit-customer-note"
                    type="button"
                    onClick={() =>
                      showOrderInternalNotesModal(order.order_number)
                    }
                  >
                    Edit
                  </button>
                )}
              </dd>
            ) : (
              <dd className="list__item--order-data">
                {!readOnly && (
                  <button
                    className="btn btn--link meta-orderdetail-button-add-customer-note"
                    type="button"
                    onClick={() =>
                      showOrderInternalNotesModal(order.order_number)
                    }
                  >
                    Add notes
                  </button>
                )}
              </dd>
            )}
          </dl>
        </div>
        <div className="medium-4 columns wrap__list--order-data">
          <OrderListAddress
            title="Ship-to"
            addressType="shipping"
            address={order.shipping_address}
            onEdit={() => showOrderShippingAddressModal(order.order_number)}
            canEdit={!isPrimeOrder && !readOnly && orderAddressCanBeEdited}
          />
          {order.similar_open_addresses_count > 0 && (
            <div className="divider--top">
              <a
                className="btn btn--link fs-n1 block"
                href={orderListHashBuilder({
                  status: [AWAITING_FULFILLMENT],
                  similarAddressTo: order.order_number,
                })}
              >
                Show orders with similar Ship-To (
                {order.similar_open_addresses_count - 1})
              </a>
            </div>
          )}
          <div className="with-top-divider" />
          <OrderListAddress
            title="Bill-to"
            addressType="billing"
            address={order.billing_address}
            onEdit={() => showOrderBillingAddressModal(order.order_number)}
            canEdit={!readOnly && orderAddressCanBeEdited}
          />
        </div>
        <div className="medium-4 columns wrap__list--order-data">
          <OrderData
            orderNumber={order.order_number}
            isExpanded
            parentView="OrderDetail"
          />
        </div>
      </div>
      <hr className="hr--detail-page" />
      <div className="wrap--row">
        <dl className="list list--order-data">
          <dt className="list__title--order-data margin-bottom-10">
            <span className="margin-right-5">Order Contents</span>
          </dt>
          <dd className="list__item--order-data">
            <ProductInfo
              orderNumber={order.order_number}
              readOnly={readOnly || isCancelled}
            />
          </dd>
        </dl>
        <hr className="hr--detail-page" />
      </div>
      <div className="wrap--row">
        <Timeline
          commentGroupsSelector={orderCommentGroupsSelector}
          addComment={() => showAddOrderCommentModal(order.order_number)}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

Details.propTypes = {
  order: OrderShape,
  cart: CartShape,
  grandTotal: PropTypes.number.isRequired,
  isPrimeOrder: PropTypes.bool.isRequired,
  orderHasBeenSplit: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  orderAddressCanBeEdited: PropTypes.bool.isRequired,
  orderCommentGroupsSelector: PropTypes.func.isRequired,
  useInventoryAllocation: PropTypes.bool.isRequired,
}

function makeMapStateToProps(state) {
  const orderNumber = orderDetailOrderNumberSelector(state)
  const order = orderSelector(state, {orderNumber})

  const orderCommentGroupsSelector = createOrderCommentGroupsSelector((state) =>
    orderSelector(state, {orderNumber}),
  )

  return {
    order,
    cart: orderCartSelector(state, {orderNumber}),
    grandTotal: grandTotalSelector(state, {orderNumber}),
    isPrimeOrder: isPrimeOrderSelector(state, {orderNumber}),
    orderHasBeenSplit: !getIsAnUnsplitOrder(order),
    readOnly: isReadOnlyOrderSelector(state, {orderNumber}),
    orderAddressCanBeEdited: orderAddressCanBeEditedSelector(state, {
      orderNumber,
    }),
    orderCommentGroupsSelector,
    useInventoryAllocation: useInventoryAllocationSelector(state),
  }
}

export default connect(makeMapStateToProps)(Details)
