import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import {usesInventorySelector} from '../../../../data/company.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

class ReconcileForm extends Component {
  constructor(props) {
    super(props)

    this.onChange = (value, name) => this.props.updateColumnMapping(value, name)
  }

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-10" {...this.props}>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.sku}
            errorMessage={this.props.columnErrors.sku}
            name="sku"
            label="SKU"
            className="medium-7"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.warehouse}
            errorMessage={this.props.columnErrors.warehouse}
            name="warehouse"
            label="Warehouse"
            className="medium-5 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.location_in_warehouse}
            errorMessage={this.props.columnErrors.location_in_warehouse}
            name="location_in_warehouse"
            label="Location in Warehouse"
            className="medium-7"
            onChange={this.onChange}
          />
          {this.props.usesInventory && (
            <ReconcileSelect
              columns={this.props.columns}
              value={this.props.columnsToDataMap.on_hand}
              errorMessage={this.props.columnErrors.on_hand}
              name="on_hand"
              label="Physical On Hand"
              className="medium-5 end"
              onChange={this.onChange}
            />
          )}
        </Row>
        {this.props.usesInventory && (
          <Row>
            <ReconcileSelect
              columns={this.props.columns}
              value={this.props.columnsToDataMap.low_stock_threshold}
              errorMessage={this.props.columnErrors.low_stock_threshold}
              name="low_stock_threshold"
              label="Low Stock Threshold"
              className="medium-7"
              onChange={this.onChange}
            />
            <ReconcileSelect
              columns={this.props.columns}
              value={this.props.columnsToDataMap.adjustment}
              errorMessage={this.props.columnErrors.adjustment}
              name="adjustment"
              label="On Hand Adjustment"
              className="medium-5 end"
              onChange={this.onChange}
            />
          </Row>
        )}
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
  usesInventory: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    usesInventory: usesInventorySelector(state),
  }
}

export default connect(mapStateToProps)(ReconcileForm)
