import sanitizeHtml from 'sanitize-html'
import merge from 'lodash/merge.js'

export function sanitize(text, overrideDefaults = {}) {
  return sanitizeHtml(
    text,
    merge(
      {
        allowedTags: [
          'h3',
          'h4',
          'h5',
          'h6',
          'blockquote',
          'p',
          'a',
          'ul',
          'ol',
          'nl',
          'li',
          'b',
          'i',
          'strong',
          'em',
          'strike',
          'code',
          'hr',
          'br',
          'div',
          'table',
          'thead',
          'caption',
          'tbody',
          'tr',
          'th',
          'td',
          'pre',
          'img',
          'span',
        ],
        allowedAttributes: {
          a: ['href', 'name', 'target'],
          img: ['src'],
          iframe: ['src', 'width', 'height', 'frameborder'],
        },
      },
      overrideDefaults,
    ),
  )
}
