import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get.js'

import {useForm} from '../../../store.js'
import {cartVendorsByName} from '../../../common/constants/CartVendorOptions.js'
import FormListItem from '../Common/FormListItem.js'

export function getAuthorizeMessage(vendorName) {
  return `You will be directed to your ${vendorName} admin to authorize Ordoro to use your ${vendorName} data to import products and orders and to save inventory information.`
}

export default function AuthorizeMessage({className, formName}) {
  const form = useForm(formName)
  const vendorName = get(cartVendorsByName[form.vendor], 'displayName', '')

  return (
    <FormListItem className={classNames('fs-n0 margin-bottom-10', className)}>
      <em>{getAuthorizeMessage(vendorName)}</em>
    </FormListItem>
  )
}

AuthorizeMessage.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
