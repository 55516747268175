import {useEffect, useState} from 'react'

import {isNumeric} from '../../../common/utils.js'
import {
  getPrintNodeData,
  printEnabledSelector,
  isLoadingAccountSelector,
  printerOptionsSelector,
  updatePrintNodeConfig,
} from '../../../data/printNode.js'
import {
  hasPrintNodeAPIKeySelector,
  hasUseReturnOrdersFeatureSelector,
} from '../../../data/company.js'
import {hasCanadaPostShipperSelector} from '../../../data/shippers.js'
import {
  setLabelPrinterID,
  setAdditionalDocsPrinterID,
  setCanadaPostDocsPrinterID,
  setPackingListPrinterID,
  setAddressPrinterID,
  setBarcodePrinterID,
  setRMALabelPrinterID,
  labelPrinterIDSelector,
  additionalDocsPrinterIDSelector,
  canadaPostDocsPrinterIDSelector,
  packingListPrinterIDSelector,
  addressPrinterIDSelector,
  barcodePrinterIDSelector,
  rmaLabelPrinterIDSelector,
} from '../../../data/printerSettings.js'

import Select from '../Common/Select.js'
import SettingsPanel from '../SettingsPanel.js'
import PrintNodeHelp from './PrintNodeHelp.js'
import EditPrintNodeAPIKey from './EditPrintNodeAPIKey.js'
import {useSelector} from '../../../store.js'

export default function Printing() {
  const isLoadingAccount = useSelector(isLoadingAccountSelector)
  const hasAPIKey = useSelector(hasPrintNodeAPIKeySelector)
  const printEnabled = useSelector(printEnabledSelector)
  const labelPrinterID = useSelector(labelPrinterIDSelector)
  const additionalDocsPrinterID = useSelector(additionalDocsPrinterIDSelector)
  const canadaPostDocsPrinterID = useSelector(canadaPostDocsPrinterIDSelector)
  const packingListPrinterID = useSelector(packingListPrinterIDSelector)
  const addressPrinterID = useSelector(addressPrinterIDSelector)
  const barcodePrinterID = useSelector(barcodePrinterIDSelector)
  const rmaLabelPrinterID = useSelector(rmaLabelPrinterIDSelector)
  const printerOptions = useSelector(printerOptionsSelector)
  const hasCanadaPostShipper = useSelector(hasCanadaPostShipperSelector)
  const hasUseReturnOrdersFeature = useSelector(
    hasUseReturnOrdersFeatureSelector,
  )

  const [printEnabledLocal, setPrintEnabledLocal] = useState(false)

  useEffect(() => {
    setPrintEnabledLocal(printEnabled)
  }, [printEnabled])

  useEffect(() => {
    if (hasAPIKey) {
      getPrintNodeData()
    }
  }, [])

  return (
    <SettingsPanel
      header="Printing Preferences"
      subHeader={
        <div>
          <p className="fs-01 margin-bottom-20">
            Configure our direct-to-printer feature —{' '}
            <strong>InstaPrint</strong> — to connect all of the printers on your
            network to Ordoro for streamlined, one-click printing.
          </p>
          <hr className="margin-bottom-0" />
        </div>
      }
    >
      <div className="row margin-bottom-10">
        <div className="medium-7 columns">
          <div className="flex align-items-center">
            <label
              className="fs-02 flex align-items-center margin-right-7 margin-bottom-0"
              htmlFor="enable_instaprint"
            >
              <input
                id="enable_instaprint"
                className="checkbox v-align-middle margin-bottom-0 margin-right-7"
                type="checkbox"
                checked={printEnabledLocal}
                onChange={(event) => {
                  const print_enabled = event.target.checked

                  setPrintEnabledLocal(print_enabled)

                  updatePrintNodeConfig({print_enabled})
                }}
              />
              <span className="v-align-middle">Enable InstaPrint</span>
            </label>
            <a
              className="btn btn--link no-underline lighter"
              href="https://support.ordoro.com/how-to-set-up-the-direct-to-printer-feature-in-ordoro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="flex i-help fs-02 lh-xl" />
            </a>
          </div>
        </div>
      </div>
      {printEnabledLocal && !hasAPIKey && <PrintNodeHelp />}

      {isLoadingAccount && printEnabledLocal && hasAPIKey && (
        <div className="row">
          <div className="medium-6 medium-centered columns">
            <div className="loading margin-top-25 margin-bottom-30">
              <span className="list-processing animate-spin v-align-middle" />
              <strong className="inline-block v-align-middle fs-02 op-75 margin-left-10">
                Loading printers...
              </strong>
            </div>
          </div>
        </div>
      )}

      {!isLoadingAccount && printEnabledLocal && hasAPIKey && (
        <div>
          <EditPrintNodeAPIKey />
          <div className="row">
            <div className="medium-12 columns">
              <hr className="margin-bottom-30" />
              <h3 className="list__title fs-01 lh-sm margin-bottom-30">
                Assign Printers
              </h3>
            </div>
          </div>
          <div className="row flex margin-bottom-30">
            <div className="medium-4 columns">
              <Select
                label="Shipping Label Printer"
                name="label-printer-id"
                value={labelPrinterID}
                onChange={({target: {value}}) =>
                  setLabelPrinterID(isNumeric(value) ? Number(value) : '')
                }
                className="select select--tall margin-bottom-10 w-100"
              >
                <option value="">Select a printer...</option>
                {printerOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="medium-5 columns end divider--left flex--justify-col">
              <div>
                <p className="fs-n1 text--md-grey margin-bottom-10">
                  Shipping labels that you InstaPrint from the Shipping Label
                  panel (on the side of the Orders pages) will be sent to this
                  printer.
                </p>
                <p className="alert alert--standard alert--sm margin-bottom-0">
                  <strong className="fs-n1">
                    Pick/pack lists attached to labels will be also be printed
                    from this printer.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row flex margin-bottom-30">
            <div className="medium-4 columns">
              <Select
                label="Pick/Pack List Printer"
                name="packing-list-printer-id"
                value={packingListPrinterID}
                onChange={({target: {value}}) =>
                  setPackingListPrinterID(isNumeric(value) ? Number(value) : '')
                }
                className="select select--tall margin-bottom-10 w-100"
              >
                <option value="">Select a printer...</option>
                {printerOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="medium-5 columns end divider--left flex--justify-col">
              <p className="fs-n1 text--md-grey margin-bottom-0">
                Pick or packing lists that you InstaPrint from the “Print”
                dropdown (at the top of the Orders pages) will be sent to this
                printer.
              </p>
            </div>
          </div>
          {hasUseReturnOrdersFeature && (
            <div className="row flex margin-bottom-30">
              <div className="medium-4 columns">
                <Select
                  label="RMA Return Label Printer"
                  name="rma-label-printer-id"
                  value={rmaLabelPrinterID}
                  onChange={({target: {value}}) =>
                    setRMALabelPrinterID(isNumeric(value) ? Number(value) : '')
                  }
                  className="select select--tall margin-bottom-10 w-100"
                >
                  <option value="">Select a printer...</option>
                  {printerOptions.map(({value, display}) => (
                    <option key={value} value={value}>
                      {display}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="medium-5 columns end divider--left flex--justify-col">
                <p className="fs-n1 text--md-grey margin-bottom-0">
                  Return labels that you InstaPrint from the RMA Label panel (on
                  the side of the Returns pages) will be sent to this printer.
                </p>
              </div>
            </div>
          )}
          <div className="row flex margin-bottom-30">
            <div className="medium-4 columns">
              <Select
                label="Additional Documents Printer"
                name="packing-list-printer-id"
                value={additionalDocsPrinterID}
                onChange={({target: {value}}) =>
                  setAdditionalDocsPrinterID(
                    isNumeric(value) ? Number(value) : '',
                  )
                }
                className="select select--tall margin-bottom-10 w-100"
              >
                <option value="">Select a printer...</option>
                {printerOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="medium-5 columns end divider--left flex--justify-col">
              <p className="fs-n1 text--md-grey margin-bottom-0">
                Additional Documents that you InstaPrint from the Shipping Label
                panel (on the side of the Orders pages) will be sent to this
                printer.
              </p>
            </div>
          </div>
          {hasCanadaPostShipper && (
            <div className="row flex margin-bottom-30">
              <div className="medium-4 columns">
                <Select
                  label="Canada Post Documents Printer"
                  name="packing-list-printer-id"
                  value={canadaPostDocsPrinterID}
                  onChange={({target: {value}}) =>
                    setCanadaPostDocsPrinterID(
                      isNumeric(value) ? Number(value) : '',
                    )
                  }
                  className="select select--tall margin-bottom-10 w-100"
                >
                  <option value="">Select a printer...</option>
                  {printerOptions.map(({value, display}) => (
                    <option key={value} value={value}>
                      {display}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="medium-5 columns end divider--left flex--justify-col">
                <p className="fs-n1 text--md-grey margin-bottom-0">
                  Additional Canada Post Documents that you InstaPrint from the
                  Shipping Label panel (on the side of the Orders pages) will be
                  sent to this printer.
                </p>
              </div>
            </div>
          )}
          <div className="row flex margin-bottom-30">
            <div className="medium-4 columns">
              <Select
                label="Address Label Printer"
                name="address-printer-id"
                value={addressPrinterID}
                onChange={({target: {value}}) =>
                  setAddressPrinterID(isNumeric(value) ? Number(value) : '')
                }
                className="select select--tall margin-bottom-10 w-100"
              >
                <option value="">Select a printer...</option>
                {printerOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="medium-5 columns end divider--left flex--justify-col">
              <p className="fs-n1 text--md-grey margin-bottom-0">
                Address label sheets that you InstaPrint from the “Print”
                dropdown (at the top of the Orders pages) will be sent to this
                printer.
              </p>
            </div>
          </div>
          <div className="row flex margin-bottom-30">
            <div className="medium-4 columns">
              <Select
                label="Barcode Label Printer"
                name="barcode-printer-id"
                value={barcodePrinterID}
                onChange={({target: {value}}) =>
                  setBarcodePrinterID(isNumeric(value) ? Number(value) : '')
                }
                className="select select--tall margin-bottom-10 w-100"
              >
                <option value="">Select a printer...</option>
                {printerOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
            <div className="medium-5 columns end divider--left flex--justify-col">
              <p className="fs-n1 text--md-grey margin-bottom-0">
                Product barcode label sheets that you InstaPrint from the “Print
                Barcode Label” modal will be sent to this printer.
              </p>
            </div>
          </div>
        </div>
      )}
    </SettingsPanel>
  )
}
