import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {hasBulkErrorsSelector} from '../bulkLabelConfigSelectors.js'
import ErrorMessage from './ErrorMessage.js'

export default function BulkErrors({labelInfoIDs}) {
  const hasErrors = useSelector((state) =>
    hasBulkErrorsSelector(state, {labelInfoIDs}),
  )

  if (!hasErrors) {
    return null
  }

  return (
    <div className="alert alert--error alert--tally">
      <dl className="list inner-wrap__panel--actions inner-wrap__panel--errors">
        <dt className="error-message fs-n0 margin-bottom-0">
          Errors were detected with these orders:
        </dt>
        {labelInfoIDs.map((labelInfoID) => (
          <ErrorMessage labelInfoID={labelInfoID} key={labelInfoID} />
        ))}
      </dl>
    </div>
  )
}

BulkErrors.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
