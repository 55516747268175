import {createSelector} from 'reselect'

import {cartsSortedByNameSelector} from '../../../../data/carts.js'

export const amazonCartOptionsSelector = createSelector(
  cartsSortedByNameSelector,
  (carts) =>
    carts
      .filter((cart) => cart.vendor.match(/amazon/))
      .map((cart) => ({value: cart.id, display: cart.name})),
)
