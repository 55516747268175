import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'

import formatTime from '../formatTime.js'

export default function TimeInput({
  className,
  onChange,
  onBlur,
  value,
  roundMinutes,
  ...props
}) {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value)
    }
  }, [value])

  return (
    <input
      className={`input--md ${className || ''}`}
      type="text"
      value={localValue}
      onChange={(event) => {
        setLocalValue(event.target.value)

        if (onChange) {
          onChange(event.target.value)
        }
      }}
      onBlur={(event) => {
        if (onBlur) {
          const newValue = formatTime(event.target.value, {roundMinutes})

          setLocalValue(newValue)

          onBlur(newValue)
        }
      }}
      {...props}
    />
  )
}

TimeInput.propTypes = {
  className: PropTypes.string,
  roundMinutes: PropTypes.string,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}
