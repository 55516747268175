import PropTypes from 'prop-types'
import {useMemo, useEffect} from 'react'

import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'
import {TAG_TYPE__ORDER} from '../../../../common/constants/Tags.js'
import ManageTagFilter from '../../../../common/components/ManageTagFilter.js'
import {
  orderTagOptionsSelector,
  orderTagOptionTokensSelector,
  orderTagsHaveLoadedSelector,
} from '../../../../data/orderTags.js'
import {hasOrderManageTagPermissionSelector} from '../../../../data/me.js'
import ActionSelect from './ActionSelect.js'
import {useSelector} from '../../../../store.js'
import {createAndApplyTagToRule, setRuleApplyTag} from '../rulesFunctions.js'

export default function EditTag({
  id,
  index,
  action: {
    data: {
      tag_ids: [tagID],
    },
  },
  errors,
}) {
  const orderTagOptions = useSelector(orderTagOptionsSelector)
  const orderTagOptionTokens = useSelector(orderTagOptionTokensSelector)
  const hasOrderManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const orderTagsHaveLoaded = useSelector(orderTagsHaveLoadedSelector)

  const tagQuantityMap = useMemo(
    () =>
      orderTagOptions.reduce((prev, option) => {
        prev[option.entity.id] = option.entity.id === tagID ? 'all' : 'none'

        return prev
      }, {}),
    [tagID, orderTagOptions],
  )

  useEffect(() => {
    // If someone deletes a tag that is yet-to-be-saved on a rule, then clear it.
    // There is an API check that prevent users from deleting tags saved on rules.
    if (
      tagID &&
      orderTagsHaveLoaded &&
      !orderTagOptions.find((option) => option.entity.id === tagID)
    ) {
      setRuleApplyTag(id, index, null)
    }
  }, [tagID, orderTagOptions, orderTagsHaveLoaded])

  return (
    <div className="inline-block">
      <div className="inline-block v-align-middle margin-bottom-10">
        <ActionSelect ruleID={id} index={index} />
      </div>
      <div className="inline-block v-align-middle margin-bottom-10 margin-right-10">
        <strong className="op-50 v-align-middle margin-right-10">→</strong>
        <div className="wrap--rules-tag-dropdown inline-block">
          <ManageTagFilter
            label="Select a tag"
            dropdown={`RULE_ORDER_TAG_FILTER__${id}__${index}`}
            buttonClassName="fs-00"
            onSelect={(tag) => setRuleApplyTag(id, index, tag.id)}
            tagOptions={orderTagOptions}
            tagOptionTokens={orderTagOptionTokens}
            tagQuantityMap={tagQuantityMap}
            hasManageTagPermission={hasOrderManageTagPermission}
            tagType={TAG_TYPE__ORDER}
            onCreateAndApplyClick={() => createAndApplyTagToRule(id, index)}
            isSingleSelect
            hasErrors={!!errors.action}
          />
        </div>
      </div>
    </div>
  )
}

EditTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
}
