import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import formConnect from '../../common/formConnect.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {setForm} from '../../redux/actions/ui/forms.js'
import {formsSelector} from '../../redux/selectors/ui/forms.js'

export const UNRECOVERABLE_ERROR_MODAL = 'UNRECOVERABLE_ERROR_MODAL'

export function showUnrecoverableErrorModal(error) {
  return setForm(UNRECOVERABLE_ERROR_MODAL, {error})
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[UNRECOVERABLE_ERROR_MODAL],
)

function UnrecoverableErrorModal({form}) {
  return (
    <ConfirmModal
      title="Unrecoverable Error Encountered"
      modalSize="md"
      onConfirm={() => window.location.reload()}
      confirmText="Reload Ordoro"
      preventClose
      isGlobalError
    >
      <table className="table">
        <tbody className="table__tbody--global-error">
          {(form.error.message || form.error.error_message) && (
            <tr>
              <td className="table__td align-right">
                <strong className="fs-00 error">Message:</strong>
              </td>
              <td className="table__td">
                <pre className="fs-00 lh-md ws-pre-wrap error">
                  {form.error.message || form.error.error_message}
                </pre>
              </td>
            </tr>
          )}
          {Object.keys(form.error)
            .filter(
              (key) => !['message', 'error_message', 'stack'].includes(key),
            )
            .map((key) => (
              <tr key={key}>
                <td className="table__td align-right">
                  <strong className="fs-00 capitalize error">{key}:</strong>
                </td>
                <td className="table__td">
                  <pre className="fs-00 lh-md ws-pre-wrap error">
                    {JSON.stringify(form.error[key], null, 2)}
                  </pre>
                </td>
              </tr>
            ))}
          {form.error.stack && (
            <tr>
              <td className="table__td align-right">
                <strong className="fs-00 error">Stack:</strong>
              </td>
              <td className="table__td">
                <pre className="fs-00 lh-md ws-pre-wrap error">
                  {form.error.stack}
                </pre>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </ConfirmModal>
  )
}

UnrecoverableErrorModal.propTypes = {
  form: PropTypes.shape({
    error: PropTypes.object.isRequired,
  }).isRequired,
}

export default formConnect(
  connect()(UnrecoverableErrorModal),
  modalFormSelector,
)
