import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {BATCH_PLURAL_URI_COMPONENT} from '../constants/Batches.js'
import {MO_PLURAL_URI_COMPONENT} from '../constants/MOs.js'
import {ANALYTICS_URI_COMPONENT} from '../constants/Analytics.js'
import {logError} from '../error.js'
import {navigate, setLocation} from '../../redux/actions/ui/index.js'
import {
  PRODUCT_PLURAL_URI_COMPONENT,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../constants/Products.js'
import {
  PURCHASE_ORDER_PLURAL_URI_COMPONENT,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
} from '../constants/PurchaseOrders.js'
import {REPORTS_PAGE_URI_COMPONENT} from '../../ordoro/ReportsPage/reportsPageSelectors.js'
import {
  ORDER_PLURAL_URI_COMPONENT,
  ORDER_SINGLE_URI_COMPONENT,
} from '../constants/Orders.js'
import {DASHBOARD_URI_COMPONENT} from '../../ordoro/DashboardPage/dashboardFunctions.js'
import {WELCOME_URI_COMPONENT} from '../constants/index.js'

let hashFromStateChangeQueue = []
let lastNavigateTime = 0
const NAV_COMPRESS_TIMEOUT = 500

let history = []
const NAV_LOOP_TIMEOUT = 1000
function addToHistory(hash, time) {
  history.unshift({hash, time})

  history = history.slice(0, 4)
}

export function __clear_history__() {
  history = []
}

export function areWeLoopNavigating(hash, now) {
  // Record this navigation in a stack
  addToHistory(hash, now)

  // we haven't navigated enough to know
  if (history.length < 4) {
    return false
  }

  // too much time has passed since the last 4 navigations
  if (history[3].time + NAV_LOOP_TIMEOUT < now) {
    return false
  }

  // if the navigation is flipping between hash A and hash B
  // history[0].hash === A
  // history[1].hash === B
  // history[2].hash === A
  // history[3].hash === B
  return (
    history[0].hash === history[2].hash && history[1].hash === history[3].hash
  )
}

const NO_HASH_UPDATE_REG_EXP = RegExp(
  `^#/(${[
    MO_PLURAL_URI_COMPONENT,
    ANALYTICS_URI_COMPONENT,
    BATCH_PLURAL_URI_COMPONENT,
    PRODUCT_PLURAL_URI_COMPONENT,
    PRODUCT_SINGLE_URI_COMPONENT,
    PURCHASE_ORDER_PLURAL_URI_COMPONENT,
    PURCHASE_ORDER_SINGLE_URI_COMPONENT,
    REPORTS_PAGE_URI_COMPONENT,
    ORDER_PLURAL_URI_COMPONENT,
    ORDER_SINGLE_URI_COMPONENT,
    WELCOME_URI_COMPONENT,
    DASHBOARD_URI_COMPONENT,
  ].join('|')})`,
)

export class HashListener extends Component {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.handleHashChange({newURL: window.location.href})

    window.addEventListener('hashchange', this.onHashChange)
  }

  componentDidUpdate() {
    this.handleStateChange(this.props)
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  onHashChange = (event) => {
    this.handleHashChange({newURL: event.newURL || window.location.href})
  }

  handleHashChange({newURL}) {
    // the real hash changed

    // get the hash
    const urlParts = newURL.split('#')

    // format it consistently
    const hash = `#${urlParts[1] || ''}`

    // we need to know
    const now = Date.now()

    // if we have been on this hash and the previous hash before in a short time
    if (areWeLoopNavigating(hash, now)) {
      logError(new Error('Loop Navigation Experienced'), undefined, {history})

      return
    }

    // if this hash change happened from a state change
    if (hashFromStateChangeQueue.includes(hash)) {
      // remove the hash from the queue
      hashFromStateChangeQueue = hashFromStateChangeQueue.filter(
        (h) => h !== hash,
      )

      // update location state
      this.props.setLocation(hash)

      // don't call navigate because navigate() can cause a state change
      // and a state change is how we got here in this if statement
      return
    }

    // remember when we last called navigate()
    // because the navigate() call might cause state changes in different ticks,
    // so the hash selector will be called multiple times,
    // each time forming the hash more correctly than the previous time
    lastNavigateTime = now

    // possibly cause state changes
    this.props.navigate(hash)
  }

  handleStateChange({hash}) {
    // the hash selector has created a different hash than previous state

    // ignore falsy hashes
    // it was probably built from incomplete state like a detail page loading without an ID
    // a more complete hash is probably going to follow this
    if (!hash) {
      return
    }

    // The new way is to not push state to hash automatically
    if (hash.match(NO_HASH_UPDATE_REG_EXP)) {
      return
    }

    // if we have already navigated the browser to this hash then we are done
    if (hash.replace(/\/$/, '') === window.location.hash.replace(/\/$/, '')) {
      return
    }

    // if state hash change happened within NAV_COMPRESS_TIMEOUT
    if (lastNavigateTime + NAV_COMPRESS_TIMEOUT > Date.now()) {
      // override history because we want this hash instead of what was there
      window.history.replaceState({}, '', hash)
    } else {
      // this is a real change

      // remember this hash change from state for the eventual hashchange event that fires
      hashFromStateChangeQueue.push(hash)

      // make the change to the real hash
      window.location.hash = hash
    }
  }

  render() {
    return null
  }
}

function mapStateToProp(state, {hashSelector}) {
  return {
    hash: hashSelector(state),
  }
}

const mapDispatchToProp = {
  navigate,
  setLocation,
}

export default connect(mapStateToProp, mapDispatchToProp)(HashListener)
