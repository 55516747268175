import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {formConnectAuto} from '../../common/formConnect.js'
import {setProductDetailForm} from './productDetailActions.js'
import {
  skuSelector,
  hasLoadedProductDetailPageSelector,
} from './productDetailSelectors.js'

import Modals from '../ProductListPage/Modals/index.js'
import ProductDetailLocationListener from './ProductDetailLocationListener.js'
import Controls from './Controls.js'
import Header from './Header.js'
import TabbedPanels from './TabbedPanels.js'
import Sidebar from './Sidebar.js'
import {hasProductPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'

export function ProductDetailPage({
  sku,
  hasLoadedProductDetailPage,
  hasProductPermission,
}) {
  useEffect(() => {
    document.title = `Products - ${sku}`
  }, [sku])

  if (!hasProductPermission) {
    return <NoPermissionsPage />
  }

  return (
    <>
      <ProductDetailLocationListener />
      <Modals />
      {hasLoadedProductDetailPage ? (
        <div className="wrap--container">
          <Controls />
          <div className="wrap--row flex">
            <div className="medium-9 columns padding-right-0">
              <Header />
              <TabbedPanels />
            </div>
            <div className="medium-3 columns flex">
              <Sidebar />
            </div>
          </div>
        </div>
      ) : (
        <div className="margin-top-40">
          <div className="loading align-center">
            <span className="list-processing animate-spin v-align-middle" />
            <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
              Loading...
            </strong>
          </div>
        </div>
      )}
    </>
  )
}

ProductDetailPage.propTypes = {
  sku: PropTypes.string,
  hasLoadedProductDetailPage: PropTypes.bool.isRequired,
  hasProductPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const sku = skuSelector(state)

  return {
    sku,
    hasLoadedProductDetailPage: hasLoadedProductDetailPageSelector(state),
    hasProductPermission: hasProductPermissionSelector(state),
  }
}

export default formConnectAuto(
  connect(mapStateToProps)(ProductDetailPage),
  setProductDetailForm,
)
