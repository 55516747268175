import PropTypes from 'prop-types'

import {getState, updateForm} from '../../store.js'
import {PRODUCT_SINGLE_URI_COMPONENT} from '../../common/constants/Products.js'
import {getDirectKitParents} from '../../data/directKitParents.js'
import {getProduct} from '../../data/products.js'
import {navigate} from '../../common/location.js'
import {PRODUCT_DETAIL, skuSelector} from './productDetailSelectors.js'

export const ProductDetailFormShape = PropTypes.shape({
  selectedWarehouseIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedSupplierIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedCartIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedKitComponentSKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedBOMComponentSKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export function navigateToProductDetails(sku, panel) {
  return navigate([
    PRODUCT_SINGLE_URI_COMPONENT,
    sku,
    ...(panel ? [panel] : []),
  ])
}

export function setProductDetailForm() {
  return {
    formName: PRODUCT_DETAIL,
    initialForm: {
      selectedWarehouseIDs: [],
      selectedSupplierIDs: [],
      selectedCartIDs: [],
      selectedKitComponentSKUs: [],
      selectedBOMComponentSKUs: [],
      productAnalyticsReturnLink: '',
    },
  }
}

export function updateProductDetailForm(...args) {
  updateForm(PRODUCT_DETAIL, ...args)
}

export async function loadCurrentProduct() {
  const sku = skuSelector(getState())

  if (sku) {
    await getProduct(sku)

    await getDirectKitParents(sku)
  }
}
