import {printerOptionsSelector} from './printNode.js'
import {formsSelector, setForm, updateForm} from '../store.js'

export const PRINTER_SETTINGS_FORM = 'PRINTER_SETTINGS_FORM'

export function setupPrinterSettingsForm() {
  setForm(PRINTER_SETTINGS_FORM, {})
}

export function printerSettingsFormSelector(state) {
  return (
    formsSelector(state)[PRINTER_SETTINGS_FORM] ||
    printerSettingsFormSelector.default
  )
}
printerSettingsFormSelector.default = {}

export function labelPrinterIDSelector(state) {
  const {labelPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === labelPrinterID)
    ? labelPrinterID
    : ''
}

export function additionalDocsPrinterIDSelector(state) {
  const {additionalDocsPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === additionalDocsPrinterID)
    ? additionalDocsPrinterID
    : ''
}

export function canadaPostDocsPrinterIDSelector(state) {
  const {canadaPostDocsPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === canadaPostDocsPrinterID)
    ? canadaPostDocsPrinterID
    : ''
}

export function packingListPrinterIDSelector(state) {
  const {packingListPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === packingListPrinterID)
    ? packingListPrinterID
    : ''
}

export function addressPrinterIDSelector(state) {
  const {addressPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === addressPrinterID)
    ? addressPrinterID
    : ''
}

export function barcodePrinterIDSelector(state) {
  const {barcodePrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === barcodePrinterID)
    ? barcodePrinterID
    : ''
}

export function rmaLabelPrinterIDSelector(state) {
  const {rmaLabelPrinterID} = printerSettingsFormSelector(state)
  const printerOptions = printerOptionsSelector(state)

  return printerOptions.find(({value}) => value === rmaLabelPrinterID)
    ? rmaLabelPrinterID
    : ''
}

export function setLabelPrinterID(labelPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {labelPrinterID},
    {stickyProps: ['labelPrinterID']},
  )
}

export function setAdditionalDocsPrinterID(additionalDocsPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {additionalDocsPrinterID},
    {stickyProps: ['additionalDocsPrinterID']},
  )
}

export function setCanadaPostDocsPrinterID(canadaPostDocsPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {canadaPostDocsPrinterID},
    {stickyProps: ['canadaPostDocsPrinterID']},
  )
}

export function setPackingListPrinterID(packingListPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {packingListPrinterID},
    {stickyProps: ['packingListPrinterID']},
  )
}

export function setAddressPrinterID(addressPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {addressPrinterID},
    {stickyProps: ['addressPrinterID']},
  )
}

export function setBarcodePrinterID(barcodePrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {barcodePrinterID},
    {stickyProps: ['barcodePrinterID']},
  )
}

export function setRMALabelPrinterID(rmaLabelPrinterID) {
  updateForm(
    PRINTER_SETTINGS_FORM,
    {rmaLabelPrinterID},
    {stickyProps: ['rmaLabelPrinterID']},
  )
}
