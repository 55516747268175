import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isEqual from 'lodash/isEqual.js'
import format from 'date-fns/format'
import endOfDay from 'date-fns/endOfDay'
import isDateEqual from 'date-fns/isEqual'
import subMonths from 'date-fns/subMonths'
import startOfDay from 'date-fns/startOfDay'

import {getTimeZone} from '../../common/date.js'
import {ensureArray, ensureArrayOfNumbers} from '../../common/ensure.js'
import {stringifyURL} from '../../common/querystring.js'
import {formsSelector} from '../../store.js'
import {
  FINANCIAL_PANEL,
  SHIPPING_PANEL,
  CUSTOMER_PANEL,
  PRODUCT_PANEL,
  ANALYTICS_URI_COMPONENT,
  ANALYTICS_PANELS,
  DEFAULT_PANEL,
  DEFAULT_DATE_TYPE,
  INVENTORY_PANEL,
} from '../../common/constants/Analytics.js'
import {
  hasAnalyticsCustomerPermissionSelector,
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsInventoryPermissionSelector,
  hasAnalyticsPanelPermissionSelector,
  hasAnalyticsPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
} from '../../data/me.js'
import {cartsSortedByNameSelector} from '../../data/carts.js'
import {
  suppliersSortedByNameSelector,
  getSupplierName,
  allSuppliersSortedByNameSelector,
} from '../../data/suppliers.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  showFeatureLocksSelector,
  useAnalyticsSelector,
} from '../../data/company.js'
import {financialExportToCSVParamsSelector} from './financialSelectors.js'
import {productExportToCSVParamsSelector} from './productSelectors.js'
import {shippingExportToCSVParamsSelector} from './shippingSelectors.js'

export const ANALYTICS = 'ANALYTICS'

export const canUseAnalyticsSelector = createSelector(
  hasAnalyticsPermissionSelector,
  useAnalyticsSelector,
  (hasPermission, hasAnalyticsFeature) => hasPermission && hasAnalyticsFeature,
)

export function showAnalyticsNavSelector(state) {
  const showFeatureLocks = showFeatureLocksSelector(state)
  const hasAnalyticsFeature = useAnalyticsSelector(state)
  const hasAnalyticsPermission = hasAnalyticsPermissionSelector(state)

  return hasAnalyticsPermission && (hasAnalyticsFeature || showFeatureLocks)
}

export const analyticsFormSelector = createSelector(
  formsSelector,
  (forms) => forms[ANALYTICS],
)

export function analyticsPanelSelector(state) {
  const {pathComponents} = locationSelector(state)
  const panel = pathComponents[1] || DEFAULT_PANEL
  const hasPermission = hasAnalyticsPanelPermissionSelector(state, {panel})

  if (
    hasPermission &&
    pathComponents[0] === ANALYTICS_URI_COMPONENT &&
    ANALYTICS_PANELS.includes(panel)
  ) {
    return panel
  }

  return null
}

export function analyticsQuerySelector(state) {
  analyticsQuerySelector.cache = analyticsQuerySelector.cache || {}
  const cache = analyticsQuerySelector.cache

  const {query} = locationSelector(state)

  if (query === cache.query) {
    return cache.result
  }

  cache.query = query

  const result = {
    cartFilters: ensureArrayOfNumbers(query.cartFilters),
    supplierFilters: ensureArrayOfNumbers(query.supplierFilters),
    productFilters: ensureArray(query.productFilters),
    startDate: query.startDate ? new Date(query.startDate) : null,
    endDate: query.endDate ? new Date(query.endDate) : null,
    dateType: query.dateType || DEFAULT_DATE_TYPE,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export function cartFiltersSelector(state) {
  cartFiltersSelector.cache = cartFiltersSelector.cache || {}
  const cache = cartFiltersSelector.cache

  const {cartFilters} = analyticsQuerySelector(state)

  if (isEqual(cache.result, cartFilters)) {
    return cache.result
  }

  cache.result = cartFilters

  return cartFilters
}

export function supplierFiltersSelector(state) {
  supplierFiltersSelector.cache = supplierFiltersSelector.cache || {}
  const cache = supplierFiltersSelector.cache

  const {supplierFilters} = analyticsQuerySelector(state)

  if (isEqual(cache.result, supplierFilters)) {
    return cache.result
  }

  cache.result = supplierFilters

  return supplierFilters
}

export function productFiltersSelector(state) {
  productFiltersSelector.cache = productFiltersSelector.cache || {}
  const cache = productFiltersSelector.cache

  const {productFilters} = analyticsQuerySelector(state)

  if (isEqual(cache.result, productFilters)) {
    return cache.result
  }

  cache.result = productFilters

  return productFilters
}

export function startDateSelector(state) {
  startDateSelector.cache = startDateSelector.cache || {}
  const cache = startDateSelector.cache

  const {startDate} = analyticsQuerySelector(state)

  if (isDateEqual(cache.result, startDate)) {
    return cache.result
  }

  cache.result = startDate

  return startDate
}

export function endDateSelector(state) {
  endDateSelector.cache = endDateSelector.cache || {}
  const cache = endDateSelector.cache

  const {endDate} = analyticsQuerySelector(state)

  if (isDateEqual(cache.result, endDate)) {
    return cache.result
  }

  cache.result = endDate

  return endDate
}

export function dateTypeSelector(state) {
  dateTypeSelector.cache = dateTypeSelector.cache || {}
  const cache = dateTypeSelector.cache

  const {dateType} = analyticsQuerySelector(state)

  if (isEqual(cache.result, dateType)) {
    return cache.result
  }

  cache.result = dateType

  return dateType
}

export function isLoadingAnalyticsSelector(state) {
  return !!get(analyticsFormSelector(state), 'isLoading')
}

export function isProductSearchLoadingSelector(state) {
  return !!get(analyticsFormSelector(state), 'isProductSearchLoading')
}

function formatDate(filter) {
  return `${format(new Date(filter.start_date), 'yyyy-MM-dd')}_${format(
    new Date(filter.end_date),
    'yyyy-MM-dd',
  )}`
}

export function exportToCSVParamsSelector(state, {type}) {
  const params = queryParamsSelector(state)
  const date = formatDate(params)

  return {
    ...financialExportToCSVParamsSelector(state, {type, date}),
    ...shippingExportToCSVParamsSelector(state, {type, date}),
    ...productExportToCSVParamsSelector(state, {type, date}),
  }
}

export function missingSKUsSelector(state) {
  const {productFilters} = analyticsQuerySelector(state)
  const topSellersData = get(
    analyticsFormSelector(state),
    'order_item_financials__product_search',
  )

  return productFilters.reduce((prev, sku) => {
    if (!topSellersData.find((bucket) => bucket.sku === sku)) {
      prev.push(sku)
    }

    return prev
  }, [])
}

export const filterableCartsSelector = createSelector(
  cartFiltersSelector,
  cartsSortedByNameSelector,
  (cartFilters, allCarts) =>
    allCarts.map(({id, name}) => ({
      value: id,
      display: name,
      isSelected: cartFilters.includes(id),
    })),
)

export const allCartsSelectedSelector = createSelector(
  cartsSortedByNameSelector,
  cartFiltersSelector,
  (carts, cartFilters) =>
    carts.length > 0 && carts.length === cartFilters.length,
)

export const indeterminateCartsSelectedSelector = createSelector(
  allCartsSelectedSelector,
  cartFiltersSelector,
  (allSelected, cartFilters) => !allSelected && cartFilters.length > 0,
)

export const activeCartFiltersSelector = createSelector(
  cartFiltersSelector,
  cartsSortedByNameSelector,
  (cartFilters, allCarts) =>
    cartFilters.map((id) => ({
      value: id,
      display: get(
        allCarts.find((cart) => cart.id === id),
        'name',
        '',
      ),
    })),
)

export const filterableSuppliersSelector = createSelector(
  supplierFiltersSelector,
  allSuppliersSortedByNameSelector,
  (supplierFilters, allSuppliers) =>
    allSuppliers.map((supplier) => ({
      value: supplier.id,
      display: getSupplierName(supplier),
      isSelected: supplierFilters.includes(supplier.id),
    })),
)

export const allSuppliersSelectedSelector = createSelector(
  suppliersSortedByNameSelector,
  supplierFiltersSelector,
  (suppliers, supplierFilters) =>
    suppliers.length > 0 && suppliers.length === supplierFilters.length,
)

export const indeterminateSuppliersSelectedSelector = createSelector(
  allSuppliersSelectedSelector,
  supplierFiltersSelector,
  (allSelected, supplierFilters) => !allSelected && supplierFilters.length > 0,
)

export const activeSupplierFiltersSelector = createSelector(
  supplierFiltersSelector,
  allSuppliersSortedByNameSelector,
  (supplierFilters, allSuppliers) =>
    supplierFilters.map((id) => ({
      value: id,
      display: getSupplierName(
        allSuppliers.find((supplier) => supplier.id === id),
      ),
    })),
)

export const analyticsHashParamsSelector = createSelector(
  analyticsPanelSelector,
  analyticsQuerySelector,
  (state, props) => props && props.panel,
  (
    currentPanel,
    {
      cartFilters,
      supplierFilters,
      productFilters,
      startDate,
      endDate,
      dateType,
    },
    panel,
  ) => {
    const params = {
      cartFilters,
      supplierFilters,
      productFilters,
      startDate: startDate ? startDate.toISOString() : undefined,
      endDate: endDate ? endDate.toISOString() : undefined,
    }

    // panel is only set if building from panelLinkSelector
    panel = panel || currentPanel

    if (panel === SHIPPING_PANEL && DEFAULT_DATE_TYPE !== dateType) {
      params.dateType = dateType
    }

    return params
  },
)

export function analyticsHashBuilder(panel, params) {
  const hash =
    panel === DEFAULT_PANEL
      ? `#/${ANALYTICS_URI_COMPONENT}`
      : `#/${ANALYTICS_URI_COMPONENT}/${panel}`

  return stringifyURL(hash, params)
}

export const analyticsHashSelector = createSelector(
  analyticsPanelSelector,
  analyticsHashParamsSelector,
  analyticsHashBuilder,
)

export const panelLinkSelector = createSelector(
  (state, {panel}) => panel,
  analyticsHashParamsSelector,
  analyticsHashBuilder,
)

export const defaultDateRangeSelector = createSelector(
  () => format(new Date(), 'yyyy-MM-dd'), // update selector every day
  () => ({
    startDate: startOfDay(subMonths(new Date(), 1)).toISOString(),
    endDate: endOfDay(new Date()).toISOString(),
  }),
)

export const defaultPanelSelector = createSelector(
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
  hasAnalyticsInventoryPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  hasAnalyticsCustomerPermissionSelector,
  (
    hasAnalyticsFinancialPermission,
    hasAnalyticsShippingPermission,
    hasAnalyticsInventoryPermission,
    hasAnalyticsProductPermission,
    hasAnalyticsCustomerPermission,
  ) =>
    hasAnalyticsFinancialPermission
      ? FINANCIAL_PANEL
      : hasAnalyticsShippingPermission
        ? SHIPPING_PANEL
        : hasAnalyticsInventoryPermission
          ? INVENTORY_PANEL
          : hasAnalyticsProductPermission
            ? PRODUCT_PANEL
            : hasAnalyticsCustomerPermission
              ? CUSTOMER_PANEL
              : null,
)

export const defaultAnalyticsHashSelector = createSelector(
  defaultPanelSelector,
  defaultDateRangeSelector,
  analyticsHashBuilder,
)

export function queryParamsSelector(state) {
  const panel = analyticsPanelSelector(state)
  const {
    cartFilters,
    supplierFilters,
    productFilters,
    startDate,
    endDate,
    dateType,
  } = analyticsQuerySelector(state)

  const filters = {}

  if (
    [FINANCIAL_PANEL, SHIPPING_PANEL, CUSTOMER_PANEL, PRODUCT_PANEL].includes(
      panel,
    )
  ) {
    filters.cart_id = cartFilters
  }

  if ([PRODUCT_PANEL].includes(panel)) {
    filters.supplier_id = supplierFilters
    filters.sku = productFilters
  }

  if (
    [FINANCIAL_PANEL, SHIPPING_PANEL, CUSTOMER_PANEL, PRODUCT_PANEL].includes(
      panel,
    )
  ) {
    if (startDate) {
      filters.start_date = startDate.toISOString()
    }
    if (endDate) {
      filters.end_date = endOfDay(endDate).toISOString()
    }
    filters.timezone = getTimeZone()
  }

  if ([SHIPPING_PANEL].includes(panel)) {
    filters.date_type = dateType
  }

  return filters
}
