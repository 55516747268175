export function getStickyKey(key) {
  return `sticky:${key}:${window.ORDORO_BOOTSTRAP.company.id}:${window.ORDORO_BOOTSTRAP.me.id}`
}

export function clearSticky(key) {
  localStorage.removeItem(getStickyKey(key))
}

export function getSticky(key) {
  const stickyString = localStorage.getItem(getStickyKey(key))

  try {
    return JSON.parse(stickyString || '{"value": null}').value
  } catch (err) {
    return null
  }
}

export function setSticky(key, value) {
  localStorage.setItem(getStickyKey(key), JSON.stringify({value}))
}
