import HeaderDropdown from '../../common/components/List/HeaderDropdown.js'
import DropdownItemButton from '../../common/components/Dropdown/DropdownItemButton.js'
import {showListOnAmazonModal} from '../../redux/actions/ui/modals/listOnAmazonModal.js'
import {
  usesInventorySelector,
  canTransferInventorySelector,
  usePurchaseOrdersSelector,
} from '../../data/company.js'
import {
  weightSelector,
  lengthSelector,
  heightSelector,
  widthSelector,
  isArchivedSelector,
  canListOnAmazonSelector,
  isAllBOMsSelector,
} from '../../data/products.js'
import {showCreateInventoryTransferModal} from './Modals/CreateInventoryTransferModal/createInventoryTransferModalActions.js'
import {showCreatePOModal} from './Modals/CreatePOModal/createPOModalActions.js'
import {showArchiveProductModal} from './Modals/ArchiveProductModal.js'
import {showProductWeightModal} from './Modals/ProductWeightModal.js'
import {showProductDimensionsModal} from './Modals/ProductDimensionsModal.js'
import {showBarcodeLabelModal} from './Modals/BarcodeLabelModal.js'
import {showMarkNeedsSyncModal} from './Modals/MarkNeedsSyncModal.js'
import {
  selectedSKUsSelector,
  firstSelectedSKUSelector,
  productListQueryParamsSelector,
  countSelector,
} from './productListFunctions.js'
import {showProductSupplierModal} from './Modals/ProductSupplierModal.js'

import AddProductTagsDropdown from './AddProductTagsDropdown.js'
import {showProductTextFieldModal} from './Modals/ProductTextFieldModal.js'
import {dispatch, useSelector} from '../../store.js'
import {hasMOFeatureSelector} from '../../data/mos.js'
import {hasMOPermissionSelector} from '../../data/me.js'
import {showCreateMOModal} from '../MOListPage/Modals/CreateMOModal.js'
import {useSuppliersSelector} from '../../data/suppliers.js'
import {
  REPORT_EXPORT_PRODUCTS,
  REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
} from '../../common/constants/Reports.js'
import {showEditReportModal} from '../ReportsPage/Modals/EditReportModal.js'

export default function ActionsForSelected() {
  const sku = useSelector(firstSelectedSKUSelector)

  const selectedSKUs = useSelector(selectedSKUsSelector)
  const firstSelectedWeight = useSelector((state) =>
    weightSelector(state, {sku}),
  )
  const firstSelectedLength = useSelector((state) =>
    lengthSelector(state, {sku}),
  )
  const firstSelectedHeight = useSelector((state) =>
    heightSelector(state, {sku}),
  )
  const firstSelectedWidth = useSelector((state) => widthSelector(state, {sku}))
  const firstSelectedIsArchived = useSelector((state) =>
    isArchivedSelector(state, {sku}),
  )
  const canListOnAmazon = useSelector(canListOnAmazonSelector)
  const usesInventory = useSelector(usesInventorySelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const useSuppliers = useSelector(useSuppliersSelector)
  const canTransferInventory = useSelector(canTransferInventorySelector)
  const hasMOFeature = useSelector(hasMOFeatureSelector)
  const hasMOPermission = useSelector(hasMOPermissionSelector)
  const isAllBOMs = useSelector((state) =>
    isAllBOMsSelector(state, {skus: selectedSKUs}),
  )
  const productQuery = useSelector(productListQueryParamsSelector)
  const queryTotalCount = useSelector(countSelector)

  return (
    <>
      {usePurchaseOrders && (
        <li className="inline-block v-align-middle fs-n0 divider--left">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showCreatePOModal(selectedSKUs)}
          >
            Order More
          </button>
        </li>
      )}
      <AddProductTagsDropdown skus={selectedSKUs} />
      <HeaderDropdown
        label="Actions"
        dropdown="DROPDOWN_PRODUCT_LIST_OTHER_ACTIONS_FOR_SELECTED"
        right
      >
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PRODUCTS,
              singleRun: true,
              productQuery,
              queryTotalCount,
              selectedSKUs,
            })
          }
        >
          Export SKU Inventory Info
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
              singleRun: true,
              productQuery,
              queryTotalCount,
              selectedSKUs,
            })
          }
        >
          Export Product/Sales Channel Bridge
        </DropdownItemButton>
        {useSuppliers && (
          <DropdownItemButton
            onClick={() => showProductSupplierModal(selectedSKUs)}
          >
            Set Supplier/Dropshipper
          </DropdownItemButton>
        )}
        <DropdownItemButton
          onClick={() =>
            dispatch(showProductWeightModal(selectedSKUs, firstSelectedWeight))
          }
        >
          Set Weight
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            dispatch(
              showProductDimensionsModal(
                selectedSKUs,
                firstSelectedLength,
                firstSelectedHeight,
                firstSelectedWidth,
              ),
            )
          }
        >
          Set Dimensions
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showProductTextFieldModal({
              skus: selectedSKUs,
              value: false,
              title: 'Require Serial Numbers?',
              display: 'Require serial numbers during packing verification',
              apiProp: 'has_serial_numbers',
              inputType: 'checkbox',
            })
          }
        >
          Set Require Serial Numbers
        </DropdownItemButton>
        {canTransferInventory && (
          <DropdownItemButton
            onClick={() =>
              dispatch(showCreateInventoryTransferModal(selectedSKUs))
            }
          >
            Transfer Inventory
          </DropdownItemButton>
        )}
        {canListOnAmazon && usesInventory && (
          <DropdownItemButton
            onClick={() => dispatch(showListOnAmazonModal(selectedSKUs))}
          >
            List on Amazon
          </DropdownItemButton>
        )}
        {usesInventory && (
          <DropdownItemButton
            onClick={() => showMarkNeedsSyncModal(selectedSKUs)}
          >
            Mark as Needs Sync
          </DropdownItemButton>
        )}
        {isAllBOMs && hasMOFeature && hasMOPermission && (
          <DropdownItemButton
            onClick={() => showCreateMOModal({skus: selectedSKUs})}
          >
            Initiate a MFG Order
          </DropdownItemButton>
        )}
        <DropdownItemButton onClick={() => showBarcodeLabelModal(selectedSKUs)}>
          Print Barcodes
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            dispatch(
              showArchiveProductModal(selectedSKUs, !firstSelectedIsArchived),
            )
          }
        >
          {firstSelectedIsArchived ? 'Unarchive' : 'Archive'}
        </DropdownItemButton>
      </HeaderDropdown>
    </>
  )
}
