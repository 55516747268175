import keyBy from 'lodash/keyBy.js'
import {put, call, select, takeEvery} from 'redux-saga/effects'

import {fetchAllApiverson} from '../../../common/fetchAll.js'
import {setGlobalError} from '../../actions/ui/index.js'
import {updateHasLoaded} from '../../actions/data/hasLoaded.js'
import {
  GET_RETURN_ORDER_CODES,
  setReturnOrderCodes,
} from '../../actions/data/returnOrderCodes.js'
import {hasUseReturnOrdersFeatureSelector} from '../../../data/company.js'

export function* getReturnOrderCodesWorker() {
  try {
    if (!(yield select(hasUseReturnOrdersFeatureSelector))) {
      return
    }

    const returnOrderCodes = yield call(
      fetchAllApiverson,
      '/return_order/code/',
      'code',
    )

    yield put(setReturnOrderCodes(keyBy(returnOrderCodes, 'id')))
  } catch (err) {
    yield put(
      setGlobalError(
        `Error getting return codes. ${err.error_message || err.message}`,
        err,
      ),
    )
  } finally {
    yield put(updateHasLoaded({returnOrderCodes: true}))
  }
}

export default function* returnOrderCodesSaga() {
  yield takeEvery(GET_RETURN_ORDER_CODES, getReturnOrderCodesWorker)
}
