import {useEffect, useState} from 'react'
import classNames from 'classnames'

import {
  updateAPIKey,
  isLoadingAccountSelector,
  updatePrintNode,
} from '../../../data/printNode.js'
import {printNodeAPIKeySelector} from '../../../data/company.js'
import {useSelector} from '../../../store.js'

export default function APIKeyInput() {
  const apiKey = useSelector(printNodeAPIKeySelector)
  const isLoadingAccount = useSelector(isLoadingAccountSelector)

  const [localAPIKey, setLocalAPIKey] = useState((value) => value || '')

  useEffect(() => {
    setLocalAPIKey(apiKey)
  }, [apiKey])

  return (
    <div>
      <input
        id="printnode_api_key"
        type="text"
        className="input--med-h input--90 margin-bottom-10"
        value={localAPIKey}
        onChange={(event) => setLocalAPIKey(event.target.value)}
      />
      <div>
        <button
          className={classNames('btn btn--primary btn--md', {
            'btn--processing': isLoadingAccount,
          })}
          type="submit"
          onClick={() => updateAPIKey(localAPIKey)}
        >
          Save API Key
        </button>
        <button
          className="btn btn--secondary btn--md inline-block"
          onClick={() => {
            setLocalAPIKey(apiKey)

            updatePrintNode({isEditingAPIKey: false})
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}
