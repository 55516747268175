import {getState, setForm, updateForm, removeForm} from '../../store.js'
import delay from '../../common/delay.js'
import apiverson from '../../common/apiverson.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {
  defaultHashParamsSelector,
  DEFAULT_REPORTS_LIMIT,
  DEFAULT_REPORTS_PANEL,
  formatReportRowFormName,
  hasInProgressTaskSelector,
  reportRowFormSelector,
  reportsActivityQuerySelector,
  reportsPanelSelector,
  REPORTS_PAGE_FORM,
  REPORTS_PAGE_URI_COMPONENT,
  REPORTS_PANELS,
  REPORTS_PANEL_ACTIVITY,
} from './reportsPageSelectors.js'
import {navigate} from '../../common/location.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import api from '../../common/api.js'

export function setupReportsPageForm() {
  setForm(REPORTS_PAGE_FORM, {
    tasks: [],
    taskCount: 0,
    isRequestingTasks: false,
  })
}

export function setupReportsPageRowForm(state, {report}) {
  return {
    formName: formatReportRowFormName(report.id),
    initialForm: {
      runs: [],
      isLoading: true,
      watchLock: null,
    },
  }
}

export function updateReportsPageForm(updates, meta) {
  updateForm(REPORTS_PAGE_FORM, updates, meta)
}

export function removeReportsPageForm() {
  removeForm(REPORTS_PAGE_FORM)
}

export function updateReportsPageRowForm(reportID, updates, meta) {
  return updateForm(formatReportRowFormName(reportID), updates, meta)
}

export function navigateToReportsPage(query = {}) {
  const defaultQuery = defaultHashParamsSelector(getState())

  query = {...defaultQuery, ...query}
  const panel = query.panel
  delete query.panel

  const pathComponents = [REPORTS_PAGE_URI_COMPONENT]

  if (panel && panel !== DEFAULT_REPORTS_PANEL) {
    pathComponents.push(panel)
  }

  return navigate(pathComponents, query)
}

export function navigateReports(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (pathComponents[0] !== REPORTS_PAGE_URI_COMPONENT) {
    return
  }

  const panel = updates.panel || pathComponents[1]
  delete updates.panel

  if (panel === DEFAULT_REPORTS_PANEL || !REPORTS_PANELS.includes(panel)) {
    pathComponents = [pathComponents[0]]
  } else {
    pathComponents = [pathComponents[0], panel]
  }

  query = {...query, ...updates}

  return navigate(pathComponents, query)
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updatePanel(panel) {
  if (!REPORTS_PANELS.includes(panel)) {
    panel = DEFAULT_REPORTS_PANEL
  }

  return {
    panel,
    ...resetOffset(),
  }
}

export function setPanel(panel) {
  return navigateReports(updatePanel(panel))
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_REPORTS_LIMIT
  }

  if (limit === DEFAULT_REPORTS_LIMIT) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updateReportsPageForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigateReports(updates)
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigateReports(updateOffset(offset))
}

export async function refreshReportRuns(reportID, watchLock) {
  const {json} = await apiverson.get(`/report/${reportID}/run?limit=3`)

  updateReportsPageRowForm(reportID, {
    runs: json.report_run,
  })

  await watchReportRuns(reportID, watchLock)
}

export async function getReportRuns(reportID) {
  try {
    updateReportsPageRowForm(reportID, {
      isLoading: true,
    })

    await refreshReportRuns(reportID)
  } catch (err) {
    showGlobalError(
      {
        summary: `There was an error loading recent reports for ${reportID}.`,
        details: err.message,
      },
      err,
    )
  }

  updateReportsPageRowForm(reportID, {isLoading: false})
}

export async function startReportRun(reportID) {
  try {
    updateReportsPageRowForm(reportID, {
      isLoading: true,
    })

    await apiverson.post(`/report/${reportID}/run`)

    await refreshReportRuns(reportID)
  } catch (err) {
    showGlobalError(
      {
        summary: `There was an error starting report for ${reportID}.`,
        details: err.message,
      },
      err,
    )
  }

  updateReportsPageRowForm(reportID, {isLoading: false})
}

export async function watchReportRuns(
  reportID,
  watchLock,
  watchDelay = 10 * 1000,
) {
  // establish new lock (empty object) if lock was not passed in
  watchLock = watchLock || {}

  try {
    const rowForm = reportRowFormSelector(getState(), {reportID})

    // if no form (navigated away) or lock exists and doesn't match then return early
    if (
      !rowForm ||
      (rowForm.watchLock !== null && rowForm.watchLock !== watchLock)
    ) {
      return
    }

    const allComplete = rowForm.runs.reduce(
      (prev, {complete}) => (!prev ? false : complete),
      true,
    )

    if (!allComplete) {
      // set lock
      updateReportsPageRowForm(reportID, {watchLock})

      await delay(watchDelay)

      // pass lock around so it can be passed back into this function
      await refreshReportRuns(reportID, watchLock)
    } else {
      // clear lock if set (it might not be)
      updateReportsPageRowForm(reportID, {watchLock: null})

      // and do nothing
    }
  } catch (err) {
    showGlobalError(
      {
        summary: `There was an error polling recent reports for ${reportID}.`,
        details: err.message,
      },
      err,
    )
    // something bad happened so clear the lock (it might not be set)
    updateReportsPageRowForm(reportID, {watchLock: null})
  }
}

export async function refreshReportActivity({silent = false} = {}) {
  const panel = reportsPanelSelector(getState())

  if (panel !== REPORTS_PANEL_ACTIVITY) {
    return
  }

  const token = {}
  refreshReportActivity.token = token

  const params = reportsActivityQuerySelector(getState())

  try {
    updateReportsPageForm({
      isRequestingTasks: !silent,
      serverError: null,
    })

    const {json} = await api.get('/new_task', {
      table_key: 'export_task',
      ...params,
    })

    if (token !== refreshReportActivity.token) {
      return
    }

    updateReportsPageForm({tasks: json.task, taskCount: json.count})

    const hasInProgressTask = hasInProgressTaskSelector(getState())

    if (hasInProgressTask) {
      setTimeout(() => refreshReportActivity({silent: true}), 30 * 1000)
    }
  } catch (err) {
    updateReportsPageForm({serverError: err.message || err.error_message})
  } finally {
    updateReportsPageForm({isRequestingTasks: false})
  }
}
