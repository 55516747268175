import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function LineAllocationIcon({
  allocationStatus,
  allocationHint,
  isDropship,
  isDropshipHint,
  className,
}) {
  if (!allocationStatus) {
    return null
  }

  return (
    <div
      className={classNames('wrap--order-allocation', className, {
        allocated: allocationStatus === 'allocated',
        'partially-allocated': allocationStatus === 'partially_allocated',
        unallocated: allocationStatus === 'unallocated',
        overallocated: allocationStatus === 'overallocated',
      })}
    >
      <div className="tooltip--top" data-hint={allocationHint}>
        <div className="i--allocation" aria-hidden="true" />
      </div>
      {isDropship && (
        <div className="margin-right-3">
          <div className="tooltip--top" data-hint={isDropshipHint}>
            <div className="i-truck" aria-hidden="true" />
          </div>
        </div>
      )}
    </div>
  )
}

LineAllocationIcon.propTypes = {
  allocationHint: PropTypes.string.isRequired,
  allocationStatus: PropTypes.string,
  isDropship: PropTypes.bool.isRequired,
  isDropshipHint: PropTypes.string.isRequired,
  className: PropTypes.string,
}
