import {useEffect} from 'react'

import {
  updateLabelConfig,
  labelConfigSelector,
  onLabelPropertyValueChange,
} from '../../../../data/labelInfos/index.js'
import ConfigSelect from '../ConfigSelect.js'
import {getState, useSelector} from '../../../../store.js'
import {DHL} from '../../../../common/constants/ShipperNames.js'
import ConfigTextInput from '../ConfigTextInput.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER = 'internal_transaction_number'

const VALID_SHIPPER_TYPES = [DHL]

function foreignTradeRegulationTemplate(shipperType) {
  return `${shipperType}__foreign_trade_regulation`
}
const FOREIGN_TRADE_REGULATION_VALID_LABEL_PROPERTIES = VALID_SHIPPER_TYPES.map(
  (shipperType) => foreignTradeRegulationTemplate(shipperType),
)

function foreignTradeRegulationLabelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [foreignTradeRegulationTemplate(shipperType)]
    : []
}

const INTERNAL_TRANSACTION_NUMBER_VALID_LABEL_PROPERTIES = [
  LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER,
]

function internalTransactionNumberLabelPropertiesFunc(
  shipperType,
  labelInfoID,
) {
  const foreignTradeRegulation = labelConfigSelector(getState(), {labelInfoID})[
    foreignTradeRegulationLabelPropertiesFunc(shipperType)
  ]

  const properties = !foreignTradeRegulation
    ? [LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER]
    : []

  return properties
}

export default function ForeignTradeRegulation() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = foreignTradeRegulationLabelPropertiesFunc(shipperType)

  const foreignTradeRegulation = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID})[labelProperty] || '',
  )

  useEffect(() => {
    onLabelPropertyValueChange(
      labelInfoID,
      shipperType,
      LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER,
    )
  }, [foreignTradeRegulation])

  return (
    <>
      <ConfigSelect
        label="Foreign Trade Regulation"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [labelProperty]: value,
          })

          onChange()
        }}
        labelProperty={labelProperty}
        labelPropertiesFunc={foreignTradeRegulationLabelPropertiesFunc}
        validShipperTypes={VALID_SHIPPER_TYPES}
        shipperOptionsProperty="foreign_trade_regulation"
        validLabelProperties={FOREIGN_TRADE_REGULATION_VALID_LABEL_PROPERTIES}
      />
      {VALID_SHIPPER_TYPES.includes(shipperType) && (
        <li className="list__item--shipping-options wrap--edit-preset-modal-hide-item margin-bottom-5">
          <a
            className="btn btn--link fs-n1"
            href="https://mydhl.express.dhl/hn/en/shipment/foreign-trade-regulation.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about FTR
          </a>
        </li>
      )}
      <ConfigTextInput
        label="Internal Transaction Number"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER]: value,
          })

          onChange()
        }}
        labelProperty={LABEL_PROPERTY_INTERNAL_TRANSACTION_NUMBER}
        labelPropertiesFunc={internalTransactionNumberLabelPropertiesFunc}
        validLabelProperties={
          INTERNAL_TRANSACTION_NUMBER_VALID_LABEL_PROPERTIES
        }
        validShipperTypes={VALID_SHIPPER_TYPES}
        hide={foreignTradeRegulation !== ''}
      />
    </>
  )
}
