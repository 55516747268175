import PropTypes from 'prop-types'

import Currency from './Currency.js'
import TextInput from './TextInput.js'

export default function CurrencyInput({
  className,
  labelClassName,
  label,
  id,
  width,
  errorMessage,
  ...props
}) {
  return (
    <>
      {label && id && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <div className="flex">
        <div className="wrap--input-prefix flex--justify-col">
          <strong className="fs-n1 text--md-grey">
            <Currency />
          </strong>
        </div>
        <TextInput
          className={`input--text input--med-h ${
            width ? `input--${width}` : ''
          } input--w-prefix ${className || ''}`}
          id={id}
          {...props}
        />
      </div>
      {errorMessage && <small className="error">{errorMessage}</small>}
    </>
  )
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  width: PropTypes.string,
  errorMessage: PropTypes.string,
}
