import {all, takeEvery, call, put, select, fork} from 'redux-saga/effects'

import {
  closeModal,
  openModal,
  setGlobalError,
} from '../../../actions/ui/index.js'
import {
  CONFIRM,
  SHOW_MODAL,
  MODAL_NAME,
  setOrders,
  setIsSaving,
} from '../../../actions/ui/modals/confirmDeallocateModal.js'
import {confirmDeallocateOrdersSelector} from '../../../selectors/ui/modals/confirmDeallocateModal.js'
import {
  deallocatableOrdersSelector,
  postAndSetOrderResponse,
} from '../../../../data/orders.js'
import {refreshOrderListAndCounts} from '../../../../ordoro/OrderListPage/orderListActions.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'

export const DEALLOCATE_ORDER = `ordoro/data/orders/DEALLOCATE_ORDER`

export function deallocateOrders(orderNumbers) {
  return {
    type: DEALLOCATE_ORDER,
    payload: {orderNumbers},
  }
}

export function* deallocateOrdersWorker({payload: {orderNumbers}}) {
  try {
    const orders = yield select(deallocatableOrdersSelector, {orderNumbers})
    yield all(
      orders.map((order) =>
        call(postAndSetOrderResponse, `${order.link}/deallocate`),
      ),
    )
    yield fork(refreshOrderListAndCounts)

    const orderString = orderNumbers.length > 1 ? 'orders were' : 'order was'
    yield call(
      showMessageToast,
      `${orderNumbers.length} ${orderString} successfully deallocated.`,
    )
  } catch (error) {
    if (orderNumbers.length === 1) {
      yield put(
        setGlobalError(
          {
            summary: 'Error deallocating order.',
            details: error.message,
          },
          error,
        ),
      )
    } else {
      yield put(
        setGlobalError(
          {
            summary: 'Error deallocating orders.',
            details: error.message,
          },
          error,
        ),
      )
    }
  }
}

export function* confirmWorker() {
  const orders = yield select(confirmDeallocateOrdersSelector)

  yield put(setIsSaving(true))
  yield call(
    deallocateOrdersWorker,
    deallocateOrders(orders.map(({order_number}) => order_number)),
  )
  yield put(setIsSaving(false))
  yield put(closeModal())
}

export function* showModalWorker(action) {
  const {payload: orders} = action

  yield put(setOrders(orders))
  yield put(setIsSaving(false))
  yield put(openModal(MODAL_NAME))
}

export default function* confirmDeallocateModal() {
  yield all([
    takeEvery(CONFIRM, confirmWorker),
    takeEvery(SHOW_MODAL, showModalWorker),
  ])
}
