export default function Loading() {
  return (
    <div className="wrap--outer-list-loading">
      <div className="wrap--full-cover">
        <div className="wrap--list-loading">
          <div className="wrap--full-cover">
            <div className="spinner--list-loading" />
          </div>
        </div>
      </div>
    </div>
  )
}
