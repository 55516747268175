import {EDIT_SUPPLIER_ADDRESS} from '../../../common/constants/AddressTypes.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showPasteAddressModal} from '../../../common/components/Modal/PasteAddressModal.js'
import FormList from '../Common/FormList.js'
import FormListItem from '../Common/FormListItem.js'
import TextInput from '../Common/TextInput.js'
import Checkbox from '../Common/Checkbox.js'
import Select from '../Common/Select.js'
import ShippingMethodMaps from './ShippingMethodMaps.js'
import {useSelector} from '../../../store.js'
import {nonFBAWarehouseOptionsSelector} from '../../../data/warehouses.js'
import {
  updateSuppliersForm,
  suppliersFormSelector,
  errorsSelector,
  isFBASupplierFormSelector,
} from './suppliersFunctions.js'
import {
  COMMUNICATION_EMAIL,
  COMMUNICATION_NONE,
} from '../../../common/constants/Suppliers.js'
import {
  useDropshippingSelector,
  usePurchaseOrdersSelector,
} from '../../../data/company.js'

export default function Form() {
  const isFBASupplier = useSelector(isFBASupplierFormSelector)
  const form = useSelector(suppliersFormSelector)
  const errors = useSelector(errorsSelector)
  const warehouseOptions = useSelector(nonFBAWarehouseOptionsSelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const useDropshipping = useSelector(useDropshippingSelector)

  return (
    <>
      <FormList className="medium-7 columns margin-left-0 margin-bottom-10">
        <FormListItem>
          <TextInput
            label="Supplier Name"
            required
            name="supplier_company"
            value={form.company}
            onChange={(event) =>
              updateSuppliersForm({
                company: event.target.value,
                hasChanged_company: true,
              })
            }
            errorMessage={form.hasChanged_company && errors.company}
          />
        </FormListItem>
        {!isFBASupplier && (
          <>
            <FormListItem>
              <TextInput
                label="Contact Name"
                name="supplier_name"
                value={form.name}
                onChange={(event) =>
                  updateSuppliersForm({name: event.target.value})
                }
                errorMessage={errors.name}
              />
            </FormListItem>
            <FormListItem>
              <TextInput
                label="Phone Number"
                name="supplier_phone"
                value={form.phone}
                onChange={(event) =>
                  updateSuppliersForm({phone: event.target.value})
                }
                errorMessage={errors.phone}
              />
            </FormListItem>
            <FormListItem>
              <TextInput
                label="Fax Number"
                name="supplier_fax"
                value={form.fax}
                onChange={(event) =>
                  updateSuppliersForm({fax: event.target.value})
                }
                errorMessage={errors.fax}
              />
            </FormListItem>
            <FormListItem className="margin-top-25 margin-bottom-20">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() =>
                  showPasteAddressModal({
                    addressType: EDIT_SUPPLIER_ADDRESS,
                    address: {
                      name: form.name,
                      street1: form.street1,
                      street2: form.street2,
                      city: form.city,
                      state: form.state,
                      zip: form.zip,
                      country: form.country,
                    },
                  })
                }
              >
                Paste Address
              </ButtonPrimary>
            </FormListItem>
            <FormListItem>
              <TextInput
                label="Street Address 1"
                name="supplier_street1"
                value={form.street1}
                onChange={(event) =>
                  updateSuppliersForm({street1: event.target.value})
                }
                errorMessage={errors.street1}
              />
            </FormListItem>
            <FormListItem>
              <TextInput
                label="Street Address 2"
                name="supplier_street2"
                value={form.street2}
                onChange={(event) =>
                  updateSuppliersForm({street2: event.target.value})
                }
                errorMessage={errors.street2}
              />
            </FormListItem>
            <FormListItem className="row margin-bottom-15">
              <div className="medium-6 columns">
                <TextInput
                  label="City"
                  name="supplier_city"
                  value={form.city}
                  onChange={(event) =>
                    updateSuppliersForm({city: event.target.value})
                  }
                  errorMessage={errors.city}
                />
              </div>
              <div className="medium-2 small-6 columns">
                <TextInput
                  label="State"
                  name="supplier_state"
                  value={form.state}
                  onChange={(event) =>
                    updateSuppliersForm({state: event.target.value})
                  }
                  errorMessage={errors.state}
                />
              </div>
              <div className="medium-4 small-6 columns end">
                <TextInput
                  label="Zipcode"
                  name="supplier_zip"
                  value={form.zip}
                  onChange={(event) =>
                    updateSuppliersForm({zip: event.target.value})
                  }
                  errorMessage={errors.zip}
                />
              </div>
            </FormListItem>
            <FormListItem className="row margin-bottom-15">
              <div className="medium-6 columns">
                <TextInput
                  label="Country"
                  name="supplier_country"
                  value={form.country}
                  onChange={(event) =>
                    updateSuppliersForm({country: event.target.value})
                  }
                  errorMessage={errors.country}
                />
              </div>
            </FormListItem>
            <FormListItem className="margin-bottom-20">
              <TextInput
                label="Custom Text"
                name="supplier_reference_number"
                value={form.referenceNumber}
                onChange={(event) =>
                  updateSuppliersForm({
                    referenceNumber: event.target.value,
                  })
                }
                errorMessage={errors.referenceNumber}
              />
            </FormListItem>

            {usePurchaseOrders && (
              <>
                <FormListItem className="bigger divider--top margin-top-15 margin-bottom-15">
                  <Select
                    label="Communication Method for Requesting POs"
                    value={form.po_routing_channel}
                    onChange={(event) =>
                      updateSuppliersForm({
                        po_routing_channel: event.target.value,
                      })
                    }
                  >
                    <option value={COMMUNICATION_EMAIL}>Send Email</option>
                    <option value={COMMUNICATION_NONE}>None</option>
                  </Select>
                </FormListItem>
                {form.po_routing_channel === COMMUNICATION_EMAIL && (
                  <FormListItem>
                    <TextInput
                      label="Email Address for POs"
                      required
                      name="po_routing_address"
                      value={form.po_routing_address}
                      onChange={(event) =>
                        updateSuppliersForm({
                          po_routing_address: event.target.value,
                        })
                      }
                      errorMessage={errors.po_routing_address}
                    />
                  </FormListItem>
                )}
              </>
            )}

            {useDropshipping && (
              <>
                <FormListItem className="bigger divider--top margin-top-15 margin-bottom-15">
                  <Select
                    label="Communication Method for Requesting Dropshipments"
                    value={form.dropshipment_routing_channel}
                    onChange={(event) =>
                      updateSuppliersForm({
                        dropshipment_routing_channel: event.target.value,
                      })
                    }
                  >
                    <option value={COMMUNICATION_EMAIL}>Send Email</option>
                    <option value={COMMUNICATION_NONE}>None</option>
                  </Select>
                </FormListItem>
                {form.dropshipment_routing_channel === COMMUNICATION_EMAIL && (
                  <FormListItem>
                    <TextInput
                      label="Email Address for Dropshipments"
                      required
                      name="dropshipment_routing_address"
                      value={form.dropshipment_routing_address}
                      onChange={(event) =>
                        updateSuppliersForm({
                          dropshipment_routing_address: event.target.value,
                        })
                      }
                      errorMessage={errors.dropshipment_routing_address}
                    />
                  </FormListItem>
                )}

                <FormListItem className="bigger divider--top margin-top-15 margin-bottom-15">
                  <p className="fs-00 margin-bottom-10">
                    <strong>Dropship Request Display Options</strong>
                  </p>
                  <div className="flex">
                    <Checkbox
                      label="Show Ship To email in automatic dropshipment emails"
                      unboldLabel
                      id="automatically_show_ship_to_email"
                      checked={form.automatically_show_ship_to_email}
                      onChange={() =>
                        updateSuppliersForm({
                          automatically_show_ship_to_email:
                            !form.automatically_show_ship_to_email,
                        })
                      }
                    />
                    <div
                      className="tooltip--top"
                      data-hint="This default setting can be overridden when manually dropshipping an order"
                    >
                      <span
                        className="i-help op-50 fs-01 lh-0 margin-left-3"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <Checkbox
                      label="Show supplier unit cost in automatic dropshipment emails"
                      unboldLabel
                      id="automatically_show_supplier_price"
                      checked={form.automatically_show_supplier_price}
                      onChange={() =>
                        updateSuppliersForm({
                          automatically_show_supplier_price:
                            !form.automatically_show_supplier_price,
                        })
                      }
                    />
                    <div
                      className="tooltip--top"
                      data-hint="This default setting can be overridden when manually dropshipping an order"
                    >
                      <span
                        className="i-help op-50 fs-01 lh-0 margin-left-3"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <Checkbox
                    label="Use product name from sales channel"
                    unboldLabel
                    id="dropship_prefer_cart_order_line_names"
                    checked={form.dropship_prefer_cart_order_line_names}
                    onChange={() =>
                      updateSuppliersForm({
                        dropship_prefer_cart_order_line_names:
                          !form.dropship_prefer_cart_order_line_names,
                      })
                    }
                  />
                </FormListItem>

                <FormListItem className="bigger divider--top margin-top-15 margin-bottom-15">
                  <Select
                    label="Linked Warehouse"
                    value={form.warehouse_id}
                    onChange={(event) =>
                      updateSuppliersForm({
                        warehouse_id: event.target.value,
                      })
                    }
                  >
                    <option value="">Select a warehouse...</option>
                    {warehouseOptions.map(({value, display}) => (
                      <option key={value} value={value}>
                        {display}
                      </option>
                    ))}
                  </Select>
                </FormListItem>
              </>
            )}
            <FormListItem className="divider--bottom margin-bottom-15 padding-bottom-0" />
          </>
        )}
      </FormList>
      {useDropshipping && (
        <div className="row">
          <div className="medium-12 columns">
            <div>
              <ul className="list--form medium-12 columns">
                <ShippingMethodMaps
                  form={form}
                  errors={errors}
                  isFBASupplier={isFBASupplier}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
