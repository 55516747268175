import ConfirmCloseSetupModal from './ConfirmCloseSetupModal.js'
import OrderModals from '../../OrderListPage/Modals/index.js'

export default function Modals() {
  return (
    <>
      <ConfirmCloseSetupModal />
      <OrderModals />
    </>
  )
}
