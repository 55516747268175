import {useEffect} from 'react'
import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isEqual from 'lodash/isEqual.js'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import startOfDay from 'date-fns/startOfDay'
import subMonths from 'date-fns/subMonths'
import endOfDay from 'date-fns/endOfDay'

import {skuSelector} from './productDetailSelectors.js'
import {
  formSelector,
  getState,
  updateForm,
  useAutoForm,
  useSelector,
} from '../../store.js'
import {getResource} from '../AnalyticsPage/analyticsActions.js'
import ValueOverTimeGraph from './ValueOverTimeGraph.js'
import DatePicker from '../../common/components/DatePicker.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {navigate} from '../../common/location.js'
import {
  PRODUCT_DETAIL_ANALYTICS_PANEL,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../common/constants/Products.js'
import {updateProductDetailForm} from './productDetailActions.js'
import {buildPath} from '../../common/querystring.js'
import {currentDateSelector} from '../CurrentDateListener.js'
import {isoDateTimeFormat} from '../../common/date.js'

const PRODUCT_ANALYTICS = 'PRODUCT_ANALYTICS'

export function productAnalyticsQuerySelector(state) {
  productAnalyticsQuerySelector.cache =
    productAnalyticsQuerySelector.cache || {}
  const cache = productAnalyticsQuerySelector.cache

  const {query} = locationSelector(state)

  if (query === cache.query) {
    return cache.result
  }

  cache.query = query

  const result = {
    startDate: query.startDate ? new Date(query.startDate) : null,
    endDate: query.endDate ? new Date(query.endDate) : null,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export const productAnalyticsDefaultLinkSelector = createSelector(
  skuSelector,
  currentDateSelector,
  (sku, currentDate) =>
    '#' +
    buildPath(
      [PRODUCT_SINGLE_URI_COMPONENT, sku, PRODUCT_DETAIL_ANALYTICS_PANEL],
      {
        startDate: startOfDay(subMonths(currentDate, 1)).toISOString(),
        endDate: endOfDay(currentDate).toISOString(),
      },
    ),
)

export const productSalesOverTimeSelector = createSelector(
  (state) => get(formSelector(state, {formName: PRODUCT_ANALYTICS}), 'data'),
  (data) => {
    if (!data) {
      return null
    }

    const lines = ['Units Sold', 'Revenue', 'Discount Amount']

    const productSalesOverTime = {
      totals: lines.reduce((totals, title) => {
        totals[title] = 0
        return totals
      }, {}),
      lines,
    }

    productSalesOverTime.buckets = data.map((bucket) => {
      const date = parse(bucket.order_placed_date, 'yyyy-MM-dd', new Date())

      const newBucket = {
        key_as_string: bucket.order_placed_date,
        key: +date,
        doc_count: bucket.total_orders,
        Revenue: bucket.total_product_sales,
        'Discount Amount': bucket.total_product_discounts,
        'Units Sold': bucket.total_products_purchased,
      }

      lines.forEach((line) => {
        productSalesOverTime.totals[line] += newBucket[line]
      })

      return newBucket
    })

    return productSalesOverTime
  },
)

async function requestData(sku, startDate, endDate) {
  updateForm(PRODUCT_ANALYTICS, {isLoading: true})

  const params = {sku}
  params.start_date = isoDateTimeFormat(startDate)
  params.end_date = isoDateTimeFormat(endDate)

  const data = await getResource('/v2/order_item_financials', {
    ...params,
    group_by: ['order_placed_date'],
  })

  updateForm(PRODUCT_ANALYTICS, {data, isLoading: false})
}

export function navigateProductAnalytics(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (pathComponents[0] !== PRODUCT_SINGLE_URI_COMPONENT) {
    return
  } else {
    query = {...query, ...updates}
  }

  return navigate(pathComponents, query)
}

export function updateDate(date, key) {
  date = new Date(date)

  return {[key]: isValid(date) ? date.toISOString() : undefined}
}

export function setDateRangeFilters(startDate, endDate) {
  return navigateProductAnalytics({
    ...updateDate(startDate, 'startDate'),
    ...updateDate(endDate, 'endDate'),
  })
}

const lines = [
  {title: 'Units Sold', graph: true},
  {title: 'Revenue', currency: true},
  {title: 'Discount Amount', currency: true},
]

export default function ProductAnalytics() {
  const form = useAutoForm(PRODUCT_ANALYTICS, {
    data: null,
    isLoading: false,
  })
  const {startDate, endDate} = useSelector(productAnalyticsQuerySelector)
  const sku = useSelector(skuSelector)
  const productSalesOverTime = useSelector(productSalesOverTimeSelector)
  const location = useSelector(locationSelector)

  useEffect(() => {
    // store this panels link info in the parent form so we can navigate back
    updateProductDetailForm({
      productAnalyticsReturnLink: `#${buildPath(
        location.pathComponents,
        location.query,
      )}`,
    })
  }, [location])

  useEffect(() => {
    if (!sku || !startDate || !endDate) {
      return
    }

    requestData(sku, startDate, endDate)
  }, [sku, startDate, endDate])

  useEffect(() => {
    // if we came to product analytics without date filters
    if (startDate && endDate) {
      return
    }

    const now = new Date()

    // this will cause an extra navigation, so try not to get here
    setDateRangeFilters(startOfDay(subMonths(now, 1)), endOfDay(now))
  }, [startDate, endDate])

  if (!startDate || !endDate) {
    return null
  }

  return (
    <>
      <div className="flex">
        <div className="margin-right-30">
          <DatePicker
            label="Start Date"
            id="id_start_date"
            onChange={(date) => setDateRangeFilters(date, endDate)}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div>
          <DatePicker
            label="End Date"
            id="id_end_date"
            onChange={(date) => setDateRangeFilters(startDate, date)}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      <ValueOverTimeGraph
        data={productSalesOverTime}
        startDate={startDate}
        endDate={endDate}
        lines={lines}
        isLoading={form.isLoading}
      />
    </>
  )
}
