import PropTypes from 'prop-types'
import classNames from 'classnames'

import {TagShape} from '../../../PropTypes.js'
import {showEditTagModal} from '../../Modal/EditTagModal.js'
import {showDeleteTagsModal} from '../../Modal/DeleteTagsModal.js'
import Checkbox from '../../Checkbox.js'

export default function Item({
  option,
  selected,
  disabled,
  canEdit,
  isFocused,
  onSelect,
}) {
  return (
    <li className="list__item list__item--dropdown">
      <span
        className={classNames(
          'label--selectable label--tag label--selectable-pt-pb-0 flex--justify-nowrap margin-bottom-0',
          {
            'list__link--dropdown-arrow-focus': isFocused,
            disabled,
          },
        )}
        style={{borderColor: option.entity.color}}
      >
        <label
          className="flex--justify-nowrap flex-grow flex--jc-left margin-bottom-0 margin-right-5"
          htmlFor={`tag__${option.entity.id}`}
          data-dropdown-prevent-close
        >
          <span>
            <Checkbox
              className="fs-00 v-align-middle"
              id={`tag__${option.entity.id}`}
              checked={selected}
              onClick={() => {
                if (!disabled) {
                  onSelect(option)
                }
              }}
            />
          </span>
          <span className="margin-left-5">{option.display}</span>
        </label>
        {canEdit && (
          <span className="wrap--tag-buttons">
            <span className="wrap--tag-edit">
              <button
                className="btn btn--link no-underline"
                type="button"
                title="Edit tag"
                onClick={(event) => {
                  event.stopPropagation()

                  showEditTagModal(option.type, option.entity.id)
                }}
                disabled={disabled}
              >
                <span className="i-pencil fs-00" aria-hidden="true" />
              </button>
            </span>
            <button
              className="btn btn--link no-underline"
              type="button"
              title="Delete tag"
              onClick={(event) => {
                event.stopPropagation()

                showDeleteTagsModal(option.type, [option.entity.id])
              }}
              disabled={disabled}
            >
              <span className="i-trash fs-00" aria-hidden="true" />
            </button>
          </span>
        )}
      </span>
    </li>
  )
}

Item.propTypes = {
  option: PropTypes.shape({
    display: PropTypes.string.isRequired,
    entity: TagShape.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  canEdit: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
}
