import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showFeatureLockedModal} from '../../Modals/FeatureLockedModal.js'
import {
  INVENTORY_APP_PANEL,
  showUpgradeModal,
} from '../../Modals/UpgradeModal.js'
import {showInventoryMgmtModuleModal} from '../../Modals/InventoryMgmtModuleModal.js'
import {usesAppsSelector} from '../../../data/company.js'
import {useSelector} from '../../../store.js'

export default function Marketing() {
  const usesApps = useSelector(usesAppsSelector)
  return (
    <div className="alert alert--feature-lock alert--lg margin-top-43 margin-bottom-40">
      <div className="wrap--inv-mgmt-icon">
        <svg
          className="icon--pricing icon--pricing-inv-mgmt"
          id="icon_pricing_inv_mgmt"
          data-name="icon--pricing-inv-mgmt"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1102.63 839.02"
        >
          <g className="icon--warehouse-boxes">
            <path
              className="icon--fill icon--fill-light-grey"
              d="M0,247.13v54.55H61.34V839H218.61V342.47H901.9V839H1055V301.67h47.62V246.58L551.93,0Zm1073.6,25.54H1026V810H930.89V313.47H189.54V810H90.3V272.67H29v-6.75L551.89,31.78,1073.6,265.39Z"
            />
            <path
              className="icon--fill icon--fill-light-grey"
              d="M311.64,224.46H791v29H311.64Z"
            />
            <path
              className="icon--fill icon--fill-light-grey"
              d="M417.13,151.88H685.48v29H417.13Z"
            />
            <path
              className="icon--fill icon--fill-light-grey"
              d="M605.86,388.41h-195V595H285.73v235.5H816.89V595H691.72V388.41Zm-83,29h54V478l-3-1.73-22.59,10.92-22.1-10.7-6.33,3.22ZM397.74,624h54v60.61l-3-1.74-22.62,10.92-22.07-10.66-6.33,3.19ZM536,801.49H314.73V624h54V733.47l35.64-18L426.15,726l21.3-10.3,33.27,19.47V624H536v177.5ZM439.9,595V417.43h54V527l35.62-18,21.81,10.54,21.27-10.27,33.27,19.47V417.44h56.88V595H439.91ZM731,624h56.85V801.5H566.6V624h52.45V733.48l35.61-18L676.48,726l21.29-10.3L731,735.19Zm-29,0v60.6l-3-1.74-22.59,10.92-22.09-10.66-6.33,3.2V624h54Z"
            />
          </g>
        </svg>
      </div>
      {usesApps ? (
        <>
          <p className="fs-01 lh-md margin-bottom-15 align-center">
            <strong>Looking for our inventory management features?</strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-25 align-center">
            Add our <strong>Inventory App</strong> to your account and gain
            access to the following:
          </p>
        </>
      ) : (
        <>
          <p className="fs-01 lh-md margin-bottom-15 align-center">
            <strong>Looking for more inventory management features?</strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-25 align-center">
            Upgrade your account with our{' '}
            <strong>Inventory Management Module</strong> to gain access to the
            following:
          </p>
        </>
      )}
      <ul className="list square margin-bottom-25">
        <li className="list__item fs-00">
          <a
            className="btn btn--link darkest border-underline"
            href="https://support.ordoro.com/how-kitting-bundling-can-be-used-in-ordoro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Kitting</span>
          </a>
        </li>
        <li className="list__item fs-00">
          <a
            className="btn btn--link darkest border-underline"
            href="https://support.ordoro.com/how-to-create-and-manage-purchase-orders/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Purchase Orders</span>
          </a>
        </li>
        <li className="list__item fs-00">
          <a
            className="btn btn--link darkest border-underline"
            href="https://support.ordoro.com/returns-workflow/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>RMAs</span>
          </a>
        </li>
        <li className="list__item fs-00">
          <a
            className="btn btn--link darkest border-underline"
            href="https://support.ordoro.com/how-can-i-create-a-bill-of-materials-bom/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Bill of Materials</span>
          </a>
        </li>
        {!usesApps && (
          <li className="list__item fs-00">
            <a
              className="btn btn--link darkest border-underline"
              href="https://support.ordoro.com/how-to-set-up-products-for-dropshipping/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Dropshipping</span>
            </a>
          </li>
        )}
      </ul>

      <div className="margin-bottom-20">
        {usesApps ? (
          <ButtonPrimary
            size="x-md w-100 ws-normal"
            className="meta--feature-lock-product-list"
            alt
            onClick={() => showUpgradeModal({panel: INVENTORY_APP_PANEL})}
          >
            Enable Inventory
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            className="w-100 ws-normal meta-EnableIMM_productlist_sidebar"
            size="x-md"
            onClick={() => showInventoryMgmtModuleModal()}
          >
            Enable Inventory Management
          </ButtonPrimary>
        )}
      </div>
      <div>
        <ButtonPrimary
          isOutlined
          className="w-100 meta-TalktoExpert-IMM_productlist_sidebar"
          size="x-md"
          onClick={() => showFeatureLockedModal('inventory')}
        >
          Talk to an Expert
        </ButtonPrimary>
      </div>
    </div>
  )
}
