import PropTypes from 'prop-types'

export default function InternalNotesPopOver({internalNotes}) {
  if (!internalNotes) {
    return null
  }

  return (
    <div className="wrap--pop-over">
      <div
        className="icon--pop-over op-50 i-internal-note fs-00"
        aria-hidden="true"
      />
      <dl className="pop-over pop-over--right">
        <dt className="pop-over__title">Internal Notes</dt>
        <dd className="pop-over__body">
          <p className="fs-n1 lh-md margin-bottom-0">{internalNotes}</p>
        </dd>
      </dl>
    </div>
  )
}

InternalNotesPopOver.propTypes = {
  internalNotes: PropTypes.string,
}
