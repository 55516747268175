import _ from 'lodash'

import {isPresent} from '../../../../../common/utils.js'
import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

export default class addressStrategy extends baseStrategy {
  static title = 'Create Addresses'

  static getExampleRows() {
    return () =>
      api.get('/address/', {limit: 5}).then(({json: data}) => {
        const rows = [
          [
            'Name',
            'Company',
            'Phone',
            'Fax',
            'Email',
            'Street1',
            'Street2',
            'City',
            'State',
            'Zip',
            'Country',
            'Reference Number',
          ],
        ]

        data.address.forEach((address) => {
          rows.push([
            address.name,
            address.company,
            address.phone,
            address.fax,
            address.email,
            address.street1,
            address.street2,
            address.city,
            address.state,
            address.zip,
            address.country,
            address.reference_number,
          ])
        })

        return rows
      })
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()

    data.payload = {
      name: row[columnsByName.name],
      company: row[columnsByName.company],
      phone: row[columnsByName.phone],
      fax: row[columnsByName.fax],
      email: row[columnsByName.email],
      street1: row[columnsByName.street1],
      street2: row[columnsByName.street2],
      city: row[columnsByName.city],
      state: row[columnsByName.state],
      zip: row[columnsByName.zip],
      country: row[columnsByName.country],
      reference_number: row[columnsByName.reference_number],
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      name: find(/name/i),
      company: find(/company/i),
      phone: find(/phone/i),
      fax: find(/fax/i),
      email: find(/email/i),
      street1: find(/street1/i),
      street2: find(/street2/i),
      city: find(/city/i),
      state: find(/state/i),
      zip: find(/zip/i),
      country: find(/country/i),
      reference_number: find(/reference.number/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    if (_.every(payload, (value) => !isPresent(value))) {
      errors.payload = 'Address can not be completely empty'
    } else {
      delete errors.payload
    }

    return errors
  }

  static save(data) {
    return () => api.post('/address', data.payload)
  }
}
