import PropTypes from 'prop-types'
import classNames from 'classnames'

import {FULFILLMENT_CHANNEL_FBA} from '../../../common/constants/CartVendorOptions.js'
import {PRODUCT_DETAIL_SALES_CHANNELS_PANEL} from '../../../common/constants/Products.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import {getProductDetailURL} from '../../ProductDetailPage/productDetailSelectors.js'
import AdditionalInfo from './AdditionalInfo/index.js'

export default function WritebackCellItem({
  sku,
  item: {
    value,
    display,
    quantity,
    isSynced,
    hasLinkedWarehouses,
    amazonFulfillmentChannel,
  },
  usesInventory,
}) {
  return (
    <AdditionalInfo.Item>
      {usesInventory ? (
        <AdditionalInfo.ItemDisplay>
          <LinkButton
            className={classNames('unbold', {
              darkest: isSynced,
            })}
            href={getProductDetailURL(sku, PRODUCT_DETAIL_SALES_CHANNELS_PANEL)}
          >
            {isSynced ? display : <em>{display}</em>}
          </LinkButton>
          {!hasLinkedWarehouses ? (
            <LinkButton
              className="unbold"
              href={`#/settings/saleschannels/${value}/edit`}
            >
              <em>(Inventory sync disabled)</em>
            </LinkButton>
          ) : isSynced ? (
            <div className="text--lt-grey unbold fs-n2">
              {quantity === null ? (
                <em>(Multi-location)</em>
              ) : (
                <>(Writeback qty: {quantity})</>
              )}
            </div>
          ) : (
            <div className="text--lt-grey unbold fs-n2">
              <em>(Inventory sync disabled)</em>
            </div>
          )}
          {amazonFulfillmentChannel && (
            <div
              className={classNames(
                'label__callout  inline-block margin-right-5 margin-top-3',
                {
                  'label__callout--amazon-fba':
                    amazonFulfillmentChannel === FULFILLMENT_CHANNEL_FBA,
                },
                {
                  'label__callout--amazon-fbm':
                    amazonFulfillmentChannel !== FULFILLMENT_CHANNEL_FBA,
                },
              )}
            >
              <strong>
                {amazonFulfillmentChannel === FULFILLMENT_CHANNEL_FBA
                  ? 'FBA'
                  : 'FBM'}
              </strong>
            </div>
          )}
        </AdditionalInfo.ItemDisplay>
      ) : (
        <AdditionalInfo.ItemDisplay>
          <span className="unbold">{display}</span>
        </AdditionalInfo.ItemDisplay>
      )}
    </AdditionalInfo.Item>
  )
}

WritebackCellItem.propTypes = {
  sku: PropTypes.string.isRequired,
  item: PropTypes.shape({
    value: PropTypes.number.isRequired,
    display: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    isSynced: PropTypes.bool.isRequired,
    hasLinkedWarehouses: PropTypes.bool.isRequired,
    inSync: PropTypes.bool.isRequired,
    amazonFulfillmentChannel: PropTypes.string,
  }).isRequired,
  usesInventory: PropTypes.bool.isRequired,
}
