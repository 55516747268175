import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import {AddressShape, SelectOptionsShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Select from '../../../common/components/Select.js'
import RowAddress from '../../../common/components/List/RowAddress.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  warehouseAddressSelector,
  nonFBAWarehouseOptionsSelector,
} from '../../../data/warehouses.js'
import {
  getSupplierName,
  supplierAddressSelector,
  suppliersSortedByNameSelector,
} from '../../../data/suppliers.js'
import {COMMUNICATION_PO} from '../../../common/constants/Suppliers.js'
import {savePO, setPO} from '../../../data/pos.js'

export const SAVE_PO_RESTOCK_LOCATION = 'SAVE_PO_RESTOCK_LOCATION'
export const PO_RESTOCK_LOCATION_MODAL = 'PO_RESTOCK_LOCATION_MODAL'
export const ID_TYPE_WAREHOUSE = 'warehouse'
export const ID_TYPE_SUPPLIER = 'supplier'

export function showPORestockLocationModal({poID, id, idType}) {
  return setForm(PO_RESTOCK_LOCATION_MODAL, {
    poID,
    id,
    idType,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PO_RESTOCK_LOCATION_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PO_RESTOCK_LOCATION_MODAL)
}

export function savePORestockLocation() {
  return {
    type: SAVE_PO_RESTOCK_LOCATION,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PO_RESTOCK_LOCATION_MODAL],
)

export function* savePORestockLocationWorker() {
  try {
    const {poID, id, idType} = yield select(modalFormSelector)

    const params =
      idType === ID_TYPE_WAREHOUSE ? {warehouse: id} : {supplier: id}

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const po = yield call(savePO, poID, params)

    yield call(setPO, po)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `Saved purchase order restock ${
        idType === ID_TYPE_WAREHOUSE ? 'to' : 'from'
      } location`,
    )

    yield call(refreshPurchaseOrderList)
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function PORestockLocationModal({form, address, options, ...props}) {
  return (
    <ConfirmModal
      title={`Edit PO ${
        form.idType === ID_TYPE_WAREHOUSE
          ? 'Receiving Address'
          : 'Supplier Address'
      }`}
      modalSize="sm"
      onConfirm={() => props.savePORestockLocation()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        <div className="fs-01">
          <strong>{form.poID}</strong>
        </div>
      </div>
      <dl className="list list--order-data">
        <dt className="list__title--order-data">
          {form.idType === ID_TYPE_WAREHOUSE ? 'Restock To' : 'Supplier'}
        </dt>
        <dd className="list__item--order-data margin-bottom-5">
          <Select
            value={form.id}
            onChange={(id) => props.updateModalForm({id: Number(id)})}
          >
            {options.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </Select>
        </dd>
        <dd className="list__item--order-data wrap--no-neg-margin">
          <RowAddress address={address} />
        </dd>
      </dl>
    </ConfirmModal>
  )
}

PORestockLocationModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    id: PropTypes.number,
    idType: PropTypes.oneOf([ID_TYPE_WAREHOUSE, ID_TYPE_SUPPLIER]).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  address: AddressShape.isRequired,
  options: SelectOptionsShape.isRequired,
  savePORestockLocation: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    address:
      form.idType === ID_TYPE_WAREHOUSE
        ? warehouseAddressSelector(state, {warehouseID: form.id})
        : supplierAddressSelector(state, {
            supplierID: form.id,
            type: COMMUNICATION_PO,
          }),
    options:
      form.idType === ID_TYPE_WAREHOUSE
        ? nonFBAWarehouseOptionsSelector(state)
        : suppliersSortedByNameSelector(state).map((supplier) => ({
            value: supplier.id,
            display: getSupplierName(supplier),
          })),
  }
}

const mapDispatchToProps = {
  savePORestockLocation,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(PORestockLocationModal),
  modalFormSelector,
)
