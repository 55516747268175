import {combineReducers} from 'redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {NAVIGATE} from '../../../actions/ui/index.js'

function currentReturnOrderCodeID(state = null, action = {}) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    if (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'return_order_codes' &&
      pathComponents[2] &&
      pathComponents[2] !== NEW_ID
    ) {
      return Number(pathComponents[2]) || null
    }

    return null
  }

  return state
}

function isNew(state = false, action = {}) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    return (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'return_order_codes' &&
      pathComponents[2] === NEW_ID
    )
  }

  return state
}

export default combineReducers({
  currentReturnOrderCodeID,
  isNew,
})
