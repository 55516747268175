import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty.js'
import pick from 'lodash/pick.js'

import {NEW_ID} from '../../../../common/constants/index.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import {
  setupForm,
  goToShipperSettings,
  updateForm,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  errorsOfChangedSelector,
  shipperFormSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import PostageBalance from './PostageBalance.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'
import {dispatch, getState, useSelector} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import verde from '../../../../common/verde.js'
import {setShipper} from '../../../../data/shippers.js'
import api from '../../../../common/api.js'
import Select from '../../Common/Select.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (shipper.nsaType && !isPresent(shipper.carrier_id)) {
    errors.carrier_id = 'Carrier ID is required'
  }

  if (shipper.id !== NEW_ID && !shipper.showAuthPanel) {
    return errors
  }

  if (!isPresent(shipper.username)) {
    errors.username = 'Username is required'
  }

  if (!isPresent(shipper.password)) {
    errors.password = 'Password is required'
  }

  return errors
}

export function hasSaveErrorsSelector(state, {form}) {
  const errors = {}

  if (form.id !== NEW_ID && !isPresent(form.name)) {
    errors.name = 'Nickname is required'
  }

  return !isEmpty(errors)
}

export function hasAuthErrorsSelector(state, {form}) {
  const errors = pick(errorsSelector(form), [
    'username',
    'password',
    'carrier_id',
  ])

  return !isEmpty(errors)
}

export function setupFormSelector(state, {shipper}) {
  const vendorConfig = shipper.vendor_config || {}
  const nsaType =
    vendorConfig.domestic_carrier_id && vendorConfig.international_carrier_id
      ? 'both'
      : vendorConfig.domestic_carrier_id
        ? 'domestic'
        : vendorConfig.international_carrier_id
          ? 'international'
          : ''

  return {
    id: shipper.id,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name,
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    username: '',
    password: '',
    nsaType,
    carrier_id:
      nsaType === 'domestic'
        ? vendorConfig.domestic_carrier_id
        : nsaType === 'international'
          ? vendorConfig.international_carrier_id
          : nsaType
            ? vendorConfig.domestic_carrier_id ||
              vendorConfig.international_carrier_id
            : '',
    pitney_merchant_use_ordoro_developer_keys:
      !!vendorConfig.pitney_merchant_use_ordoro_developer_keys,
    isSaving: false,
    serverError: null,
    showAuthPanel: false,
  }
}

function getCarrierIDParams(shipperID) {
  const {nsaType, carrier_id, pitney_merchant_use_ordoro_developer_keys} =
    shipperFormSelector(getState(), {shipperID})

  const params = {}

  if (pitney_merchant_use_ordoro_developer_keys) {
    return params
  }

  if (nsaType === 'domestic') {
    params.domestic_carrier_id = carrier_id
    params.international_carrier_id = null
  } else if (nsaType === 'international') {
    params.domestic_carrier_id = null
    params.international_carrier_id = carrier_id
  } else if (nsaType === 'both') {
    params.domestic_carrier_id = carrier_id
    params.international_carrier_id = carrier_id
  } else {
    params.domestic_carrier_id = null
    params.international_carrier_id = null
  }

  return params
}

export async function saveShipperPitneyMerchant(shipperID) {
  if (shipperID === NEW_ID) {
    return
  }

  const {name, hidden_shipping_methods} = shipperFormSelector(getState(), {
    shipperID,
  })

  const params = {
    name,
    hidden_shipping_methods,
    ...getCarrierIDParams(shipperID),
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await api.put(`/shipper/${shipperID}/`, params)

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    dispatch(
      updateForm(shipperID, {
        serverError: err.error_message || err.message,
      }),
    )
  } finally {
    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

export async function authShipperPitneyMerchant(shipperID) {
  const {username, password, pitney_merchant_use_ordoro_developer_keys} =
    shipperFormSelector(getState(), {shipperID})

  const params = {
    shipperID: shipperID === NEW_ID ? undefined : shipperID,
    username,
    password,
    pitney_merchant_use_ordoro_developer_keys,
    ...(shipperID === NEW_ID ? getCarrierIDParams(shipperID) : {}),
  }

  dispatch(updateForm(shipperID, {isSaving: true, serverError: null}))

  try {
    const {json} = await verde.post('/pitney/auth', params)

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    dispatch(
      updateForm(shipperID, {
        isSaving: false,
        serverError: err.error_message || err.message,
      }),
    )
  }
}

function NSAFormElements({form, updateForm}) {
  const errors = useSelector((state) => errorsOfChangedSelector(state, {form}))

  if (form.pitney_merchant_use_ordoro_developer_keys) {
    return null
  }

  return (
    <>
      <li className="form-list-item bigger margin-top-20 margin-bottom-20">
        <Select
          label="Negotiated Service Agreement"
          id="nsa_type"
          value={form.nsaType}
          onChange={(event) =>
            updateForm(form.id, {nsaType: event.target.value})
          }
        >
          <option value="">None</option>
          <option value="domestic">Domestic</option>
          <option value="international">International</option>
          <option value="both">Both</option>
        </Select>
      </li>
      {form.nsaType && (
        <li
          className={classNames('form-list-item bigger margin-bottom-20', {
            error: errors.carrier_id,
          })}
        >
          <label htmlFor="id_carrier_id">
            Carrier ID<span className="required">*</span>
          </label>
          <input
            type="text"
            id="id_carrier_id"
            value={form.carrier_id}
            onChange={(event) =>
              updateForm(form.id, {carrier_id: event.target.value})
            }
          />
          {errors.carrier_id && (
            <small className="error error-message">{errors.carrier_id}</small>
          )}
        </li>
      )}
    </>
  )
}

NSAFormElements.propTypes = {
  form: ShipperFormShape.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function PitneyMerchantAuthForm({form, cancel, updateForm}) {
  const errors = useSelector((state) => errorsOfChangedSelector(state, {form}))
  const hasAuthErrors = useSelector((state) =>
    hasAuthErrorsSelector(state, {form}),
  )

  return (
    <form
      className="margin-top-20"
      onSubmit={(event) => {
        event.preventDefault()

        authShipperPitneyMerchant(form.id)
      }}
    >
      <ul className="form-list medium-5 margin-bottom-0">
        <li
          className={classNames('form-list-item bigger margin-bottom-20', {
            error: errors.username,
          })}
        >
          <label htmlFor="id_username">
            Username<span className="required">*</span>
          </label>
          <input
            type="text"
            id="id_username"
            value={form.username}
            onChange={(event) =>
              updateForm(form.id, {username: event.target.value})
            }
          />
          {errors.username && (
            <small className="error error-message">{errors.username}</small>
          )}
        </li>
        <li
          className={classNames('form-list-item bigger margin-bottom-20', {
            error: errors.password,
          })}
        >
          <label htmlFor="id_password">
            Password<span className="required">*</span>
          </label>
          <input
            type="password"
            id="id_password"
            value={form.password}
            onChange={(event) =>
              updateForm(form.id, {password: event.target.value})
            }
          />
          {errors.password && (
            <small className="error error-message">{errors.password}</small>
          )}
        </li>
        <li className="form-list-item bigger margin-bottom-20">
          <label htmlFor="pitney_merchant_use_ordoro_developer_keys_no">
            Referred by
          </label>
          <div className="flex wrap--label-unbold margin-top-10">
            <div className="margin-right-25">
              <label htmlFor="pitney_merchant_use_ordoro_developer_keys_no">
                <input
                  type="radio"
                  className="margin-bottom-0 margin-right-5 v-align-middle"
                  id="pitney_merchant_use_ordoro_developer_keys_no"
                  name="pitney_merchant_use_ordoro_developer_keys"
                  checked={!form.pitney_merchant_use_ordoro_developer_keys}
                  onChange={() =>
                    updateForm(form.id, {
                      pitney_merchant_use_ordoro_developer_keys: false,
                    })
                  }
                />
                <span className="v-align-middle">Pitney Bowes</span>
              </label>
            </div>
            <div>
              <label htmlFor="pitney_merchant_use_ordoro_developer_keys_yes">
                <input
                  type="radio"
                  className="margin-bottom-0 margin-right-5 v-align-middle"
                  id="pitney_merchant_use_ordoro_developer_keys_yes"
                  name="pitney_merchant_use_ordoro_developer_keys"
                  checked={form.pitney_merchant_use_ordoro_developer_keys}
                  onChange={() =>
                    updateForm(form.id, {
                      pitney_merchant_use_ordoro_developer_keys: true,
                    })
                  }
                />
                <span className="v-align-middle">Ordoro</span>
              </label>
            </div>
          </div>
        </li>
        {form.id === NEW_ID && (
          <NSAFormElements form={form} updateForm={updateForm} />
        )}
        {form.serverError && (
          <div className="error error-message margin-bottom-20">
            {form.serverError}
          </div>
        )}
        <li className="list__item list__item--no-style">
          <ButtonPrimary
            type="submit"
            isLoading={form.isSaving}
            isDisabled={hasAuthErrors}
          >
            {form.id !== NEW_ID ? 'Reauthorize' : 'Authorize'}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => {
              setupForm(form.id)

              cancel()
            }}
          >
            Cancel
          </ButtonSecondary>
        </li>
      </ul>
    </form>
  )
}

PitneyMerchantAuthForm.propTypes = {
  form: ShipperFormShape.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default function PitneyMerchantForm({form, cancel, updateForm}) {
  const hasSaveErrors = useSelector((state) =>
    hasSaveErrorsSelector(state, {form}),
  )

  if (form.id !== NEW_ID) {
    return (
      <div className="settings-list-item-form-wrap settings-list-item-form-usps clear-both">
        <fieldset>
          <ul className="form-list medium-5 columns margin-bottom-0">
            <Nickname shipperID={form.id} />
            <NSAFormElements form={form} updateForm={updateForm} />
            <ShippingMethods shipperID={form.id} />
            <li className="list__item list__item--no-style divider--bottom padding-bottom-20">
              <ButtonPrimary
                className="btn--primary-alt"
                isLoading={form.isSaving}
                isDisabled={hasSaveErrors}
                onClick={() => saveShipperPitneyMerchant(form.id)}
              >
                Save
              </ButtonPrimary>
            </li>
          </ul>
        </fieldset>
        <fieldset className="margin-bottom-0">
          <div className="medium-12 columns">
            <PostageBalance shipperID={form.id} />
            {form.showAuthPanel ? (
              <PitneyMerchantAuthForm
                form={form}
                cancel={cancel}
                updateForm={updateForm}
              />
            ) : (
              <ButtonPrimary
                onClick={() => updateForm(form.id, {showAuthPanel: true})}
                className="margin-top-20"
              >
                Reauthorize
              </ButtonPrimary>
            )}
          </div>
        </fieldset>
      </div>
    )
  }

  return (
    <div className="medium-12 columns">
      <PitneyMerchantAuthForm
        form={form}
        cancel={cancel}
        updateForm={updateForm}
      />
    </div>
  )
}

PitneyMerchantForm.propTypes = {
  form: ShipperFormShape.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
