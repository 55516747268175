import useAnimateToFollow from './useAnimateToFollow.js'

export default function useMapChild({ref, onChild}) {
  return useAnimateToFollow((prevValue) => {
    if (!ref.current || !prevValue) {
      return []
    }

    const currentValue = [...ref.current.childNodes].map(onChild)

    return prevValue.length !== currentValue.length ||
      prevValue.reduce(
        (prev, value, index) => prev || value !== currentValue[index],
        false,
      )
      ? currentValue
      : prevValue
  })
}
