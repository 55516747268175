import {all} from 'redux-saga/effects'

import settings from './settings/index.js'
import modals from './modals/index.js'
import labelViewForm from './labelViewForm.js'
import userValidity from './userValidity.js'
import packShipPage from './packShipPage/index.js'
import returnOrderList from '../../../ordoro/ReturnOrderListPage/returnOrderListSagas.js'
import {purchaseOrderListModalSagas} from '../../../ordoro/PurchaseOrderListPage/Modals/index.js'
import productListModals from '../../../ordoro/ProductListPage/Modals/modalsSagas.js'

export default function* uiSaga() {
  yield all([
    settings(),
    modals(),
    productListModals(),
    labelViewForm(),
    userValidity(),
    packShipPage(),
    returnOrderList(),
    purchaseOrderListModalSagas(),
  ])
}
