import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Loading from '../../../common/components/List/Loading.js'
import {isLoadingSelector} from '../productListFunctions.js'
import ProductListFooter from '../ProductListFooter.js'
import Table from './Table.js'

export function Pro({isLoading}) {
  return (
    <>
      {!isLoading && (
        <>
          <div className="wrap--row margin-top-10">
            <div className="medium-12 columns">
              <Table />
            </div>
          </div>
          <div className="wrap--row wrap--product-footer wrap--product-footer-inner-scroll">
            <div className="medium-12 columns">
              <ProductListFooter />
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <div className="wrap--row margin-top-10">
          <div className="medium-12 columns list--empty-content margin-top-40 margin-bottom-40">
            <Loading />
          </div>
        </div>
      )}
    </>
  )
}

Pro.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(state),
  }
}

export default connect(mapStateToProps)(Pro)
