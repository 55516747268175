import PropTypes from 'prop-types'
import className from '../../../common/className.js'

export default function Tabs({children, smallerBottomMargin}) {
  return (
    <ul
      className={className`list--tabs flex-wrap clearfix ${{
        'margin-bottom-20': !smallerBottomMargin,
      }}`}
    >
      {children}
    </ul>
  )
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  smallerBottomMargin: PropTypes.bool.isRequired,
}
