import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import startOfDay from 'date-fns/startOfDay'

export const exportInsuranceHistoryModalSelector = createSelector(
  (state) => state.ui.modals.exportInsuranceHistoryModal,
  (exportInsuranceHistoryModal) => exportInsuranceHistoryModal,
)

export const startDateSelector = createSelector(
  exportInsuranceHistoryModalSelector,
  ({startDate}) => startDate,
)

export const endDateSelector = createSelector(
  exportInsuranceHistoryModalSelector,
  ({endDate}) => endDate,
)

export const errorsSelector = createSelector(
  startDateSelector,
  endDateSelector,
  (startDate, endDate) => {
    const errors = {}

    if (!startDate) {
      errors.startDate = 'Date is required'
    }

    if (!endDate) {
      errors.endDate = 'Date is required'
    }

    return errors
  },
)

export const hasErrorsSelector = createSelector(
  errorsSelector,
  (errors) => !isEmpty(errors),
)

export const exportTaskParamsSelector = createSelector(
  startDateSelector,
  endDateSelector,
  (startDate, endDate) => {
    const task = {
      type: 'export_billing_insurance',
      params: {
        start_date: startOfDay(startDate).toISOString(),
        end_date: startOfDay(endDate).toISOString(),
      },
    }

    return task
  },
)
