import {createSelector} from 'reselect'

import {formsSelector} from '../../store.js'
import {MO_SINGLE_URI_COMPONENT} from '../../common/constants/MOs.js'
import {canUseMOsSelector} from '../../data/mos.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'

export const MO_DETAIL_FORM = 'MO_DETAIL_FORM'

export function moDetailFormSelector(state) {
  return formsSelector(state)[MO_DETAIL_FORM]
}

export const moDetailReferenceIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (pathComponents[0] === MO_SINGLE_URI_COMPONENT && pathComponents[1]) {
      return pathComponents[1]
    }

    return null
  },
)

export function moDetailURLSelector(state, {referenceID}) {
  return `#/${MO_SINGLE_URI_COMPONENT}/${encodeURIComponent(referenceID)}`
}

export const moDetailHashSelector = createSelector(
  moDetailReferenceIDSelector,
  canUseMOsSelector,
  (referenceID, canUseMOs) => {
    if (!canUseMOs || !referenceID) {
      return null
    }

    const url = moDetailURLSelector(null, {referenceID})

    return url
  },
)
