import PropTypes from 'prop-types'
import classNames from 'classnames'
import {useSelector} from 'react-redux'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {
  cartIntegrationBridgeSelector,
  cartSelector,
  getCartName,
} from '../../../data/carts.js'
import {useForm} from '../../../store.js'
import {
  CIBShape,
  showEditCartIntegrationBridgeModal,
} from '../Modals/EditCartIntegrationBridgeModal.js'

function CartIntegrationRow({cib}) {
  const cart = useSelector((state) =>
    cartSelector(state, {cartID: cib.cart_id}),
  )

  return (
    <tr>
      <td className="table__td table__td--sm v-align-middle">
        <strong className={classNames('fs-no', {'op-50': !cib.active})}>
          {getCartName(cart)}
        </strong>
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        {cib.active ? (
          <div className="i-check fs-01 text--green" aria-hidden="true" />
        ) : (
          <div className="fs-n0 op-50">—</div>
        )}
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        {cib.active && cib.config.export_tax_as_line_item ? (
          <div className="i-check fs-01 text--green" aria-hidden="true" />
        ) : (
          <div className="fs-n0 op-50">—</div>
        )}
      </td>
    </tr>
  )
}

CartIntegrationRow.propTypes = {
  cib: CIBShape.isRequired,
}

export default function CartIntegrationBridgeConfig({className, formName}) {
  const {id} = useForm(formName)
  const cib = useSelector((state) =>
    cartIntegrationBridgeSelector(state, {integrationIDs: [id]}),
  )

  return (
    <div className={`margin-top-25 ${className}`}>
      <p className="fs-00 margin-bottom-5">
        <strong>
          Select which sales channels export order data to QuickBooks
        </strong>
      </p>
      <div className="margin-bottom-15">
        <ButtonPrimary
          isOutlined
          size="x-sm"
          onClick={() =>
            showEditCartIntegrationBridgeModal({integrationIDs: [id]})
          }
        >
          Edit Sales Channel Exports
        </ButtonPrimary>
      </div>
      <table className="table table--multi-location margin-top-5">
        <thead>
          <tr>
            <th className="table__th table__th--sm fs-n1 text--md-grey w-30">
              Sales Channel
            </th>
            <th className="table__th table__th--sm fs-n1 text--md-grey w-40 align-center">
              Export Order Data to QuickBooks
            </th>
            <th className="table__th table__th--sm fs-n1 text--md-grey w-30 align-center">
              <span className="margin-right-3">Export Tax as a Line Item</span>
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody--multi-location">
          {cib.map((cib, index) => (
            <CartIntegrationRow key={index} cib={cib} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

CartIntegrationBridgeConfig.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
