import PropTypes from 'prop-types'

import {getState, onlyIfAutoForm} from '../../../../store.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../../common/constants/SettingsPanels.js'
import {STRIPE} from '../../../../common/constants/CartVendorOptions.js'
import {redirect} from '../../../../common/location.js'
import FormList from '../../Common/FormList.js'
import VendorForm from './VendorForm.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  return baseCartErrorsSelector(state, {formName})
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return baseNewCartParamsSelector(state, {vendor: STRIPE})
  }

  return baseEditCartParamsSelector(getState(), {cartID})
}

export function saveParamsSelector(state, {formName}) {
  return baseSaveParamsSelector(state, {formName})
}

export function authorize(
  formName,
  redirectURL = `/app${SALES_CHANNEL_SETTINGS_LINK}`,
) {
  const params = {
    redirect: redirectURL,
  }

  return redirect('', ['verde', 'stripe', 'start'], params)
}

function Stripe({form, formName}) {
  const isNew = form.id === NEW_ID

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <div className="margin-top-15">
              <AuthorizeMessage formName={formName} />
            </div>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName} />
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Stripe"
          href="https://support.ordoro.com/stripe-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

Stripe.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(Stripe, setUpCartForm)
