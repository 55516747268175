import Checkbox from '../../../../common/components/Checkbox.js'

import PreviousScanFormList from './PreviousScanFormList.js'
import {useSelector} from '../../../../store.js'
import {
  groupByZipcodeSelector,
  isRequestingSummedSelector,
  scanFormModalFormSelector,
  scanFormWarehouseFormsSelector,
  setInductionZipcode,
  setWarehouseIsSelected,
  usesInductionZipcodeSelector,
} from './scanFormModalFunctions.js'

export default function WarehouseSelect() {
  const {shipperID} = useSelector(scanFormModalFormSelector)
  const warehouseForms = useSelector(scanFormWarehouseFormsSelector)
  const isAnyRequesting = useSelector(isRequestingSummedSelector)
  const groupByZipcode = useSelector(groupByZipcodeSelector)
  const usesInductionZipcode = useSelector(usesInductionZipcodeSelector)

  const selectedCount = warehouseForms.reduce(
    (prev, {isSelected}) => (isSelected ? prev + 1 : prev),
    0,
  )
  const allSelected = selectedCount === warehouseForms.length
  const someSelected =
    selectedCount !== 0 && selectedCount < warehouseForms.length

  if (warehouseForms.length === 0) {
    return null
  }

  if (warehouseForms.length === 1) {
    const {isRequesting, prevScanForms, error, warehouseID, inductionZipcode} =
      warehouseForms[0]

    return (
      <>
        {usesInductionZipcode && (
          <>
            <label htmlFor="inductionZipcode">Induction Zipcode</label>
            <input
              type="text"
              id="inductionZipcode"
              className="input--xl"
              onChange={(event) =>
                setInductionZipcode(shipperID, warehouseID, event.target.value)
              }
              value={inductionZipcode}
            />
          </>
        )}

        {isRequesting && !error && <em className="fs-n0">Fetching form...</em>}
        {prevScanForms.length > 0 && (
          <div className="fs-n0">
            <div className="margin-bottom-10">
              <strong className="v-align-base margin-right-3">
                Previously Created Forms
              </strong>
            </div>
            <PreviousScanFormList prevScanForms={prevScanForms} />
          </div>
        )}
        {error && <div className="error fs-n2">{error}</div>}
      </>
    )
  }

  return (
    <>
      <div className="flex--justify">
        <p className="fs-01 margin-bottom-5 flex--justify-col">
          <strong>Select which locations need forms</strong>
        </p>
        {groupByZipcode && (
          <div className="alert alert--standard w-50 margin-bottom-25">
            <p className="fs-n1 margin-bottom-0">
              Only the address info for a zipcode’s first listed warehouse will
              appear on SCAN forms.
            </p>
          </div>
        )}
      </div>

      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--sm w-2">
              <Checkbox
                className="margin-bottom-0"
                checked={allSelected || someSelected}
                indeterminate={someSelected}
                disabled={isAnyRequesting}
                onChange={() =>
                  warehouseForms.forEach(({warehouseID}) =>
                    setWarehouseIsSelected(
                      shipperID,
                      warehouseID,
                      allSelected ? false : true,
                    ),
                  )
                }
              />
            </th>
            <th className="table__th table__th--sm w-30">Warehouse(s)</th>
            {groupByZipcode && (
              <th className="table__th table__th--sm w-20">Zipcode</th>
            )}
            {usesInductionZipcode && (
              <th className="table__th table__th--sm w-20">
                Induction Zipcode
              </th>
            )}
            <th className="table__th table__th--sm w-40">
              Previously Created Forms
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody--lines">
          {warehouseForms.map(
            ({
              warehouseID,
              names,
              zipcode,
              isSelected,
              isRequesting,
              prevScanForms,
              error,
              inductionZipcode,
            }) => (
              <tr key={warehouseID}>
                <td className="table__td">
                  <Checkbox
                    className="margin-bottom-0"
                    checked={isSelected}
                    disabled={isAnyRequesting}
                    onChange={() =>
                      setWarehouseIsSelected(
                        shipperID,
                        warehouseID,
                        !isSelected,
                      )
                    }
                  />
                </td>
                <td className="table__td">
                  <ul className="list--no-style">
                    {names.map((name, index) => (
                      <li
                        key={index}
                        className="list__item fs-n1 lh-sm list__item--mb0-last"
                      >
                        <strong>{name}</strong>
                      </li>
                    ))}
                  </ul>
                </td>
                {groupByZipcode && (
                  <td className="table__td">
                    <span className="fs-n1">{zipcode}</span>
                  </td>
                )}
                {usesInductionZipcode && (
                  <td className="table__td">
                    <input
                      type="text"
                      className="margin-bottom-0"
                      onChange={(event) =>
                        setInductionZipcode(
                          shipperID,
                          warehouseID,
                          event.target.value,
                        )
                      }
                      value={inductionZipcode}
                    />
                  </td>
                )}
                <td className="table__td">
                  {isRequesting && !error && (
                    <div className="margin-bottom-10">
                      <strong>
                        <em className="fs-n1">Fetching forms...</em>
                      </strong>
                    </div>
                  )}
                  {error && (
                    <div className="alert alert--sm alert--error full-border margin-bottom-10">
                      <div className="error fs-n2">{error}</div>
                    </div>
                  )}
                  <PreviousScanFormList prevScanForms={prevScanForms} />
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </>
  )
}
