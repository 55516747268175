import {takeEvery} from 'redux-saga/effects'

import {APPLY_LOCATION_HREF} from '../actions/ui/index.js'

function applyLocationHrefWorker(action) {
  window.location.href = action.payload
}

export default function* locationHref() {
  yield takeEvery(APPLY_LOCATION_HREF, applyLocationHrefWorker)
}
