import PropTypes from 'prop-types'
import pick from 'lodash/pick.js'

import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import Radio from '../../../common/components/Radio.js'
import Checkbox from '../../../common/components/Checkbox.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'

export function setupExportSKUSalesForm(params = {}) {
  let {reportID} = params
  params = pick(params, ['skuDateType', 'by_cart'])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  return {
    skuDateType: reportParams.date_type || 'ordered_date',
    by_cart: reportParams.by_cart || false,
    ...params,
  }
}

export function exportSKUSalesErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportSKUSalesPayloadSelector(state, {formName}) {
  const {reportType, by_cart, skuDateType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {
      date_type: skuDateType,
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }

  if (by_cart) {
    payload.params.by_cart = by_cart
  }

  return payload
}

export default function ExportSKUSalesForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportSKUSalesErrorsSelector(state, {formName}),
  )

  return (
    <ul className="list">
      <li className="list__item--form flex margin-bottom-15">
        <div className="margin-right-20">
          <Radio
            mode="fancy"
            label="Ordered Date"
            id="ordered_date"
            checked={form.skuDateType === 'ordered_date'}
            onChange={() => updateForm(formName, {skuDateType: 'ordered_date'})}
          />
        </div>
        <div>
          <Radio
            mode="fancy"
            label="Shipped Date"
            id="ship_date"
            checked={form.skuDateType === 'ship_date'}
            onChange={() => updateForm(formName, {skuDateType: 'ship_date'})}
          />
        </div>
      </li>
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--no-style fs-01 margin-bottom-10">
        <strong>CSV Formatting Options:</strong>
      </li>
      <li className="list__item--no-style">
        <Checkbox
          className="margin-left-n03 wrap--label-unbold"
          id="by_cart"
          label="Group products by sales channel"
          checked={form.by_cart}
          onChange={() =>
            updateForm(
              formName,
              {
                by_cart: !form.by_cart,
              },
              {stickyProps: ['by_cart']},
            )
          }
        />
      </li>
    </ul>
  )
}

ExportSKUSalesForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
