import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import get from 'lodash/get.js'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'
import classNames from 'classnames'

import api from '../../../common/api.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {productsSelector, getProductName} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  itemsSelector,
  poItemSKUSelector,
  supplierIDSelector,
  ensurePO,
  setPO,
} from '../../../data/pos.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {getInitialSupplierValues} from '../../ProductListPage/Modals/CreatePOModal/createPOModalActions.js'

export const EDIT_PO_ITEM_MODAL = 'EDIT_PO_ITEM_MODAL'
export const SHOW_EDIT_PO_ITEM_MODAL = 'SHOW_EDIT_PO_ITEM_MODAL'
export const UPDATE_PO_ITEM = 'UPDATE_PO_ITEM'

export function showEditPOItemModal(poID, poItemIDs = [], autoFocusOn = null) {
  return {
    type: SHOW_EDIT_PO_ITEM_MODAL,
    payload: {
      poID,
      poItemIDs,
      autoFocusOn,
    },
  }
}

export function updateModalForm(...args) {
  return updateForm(EDIT_PO_ITEM_MODAL, ...args)
}

export function closeModal() {
  return removeForm(EDIT_PO_ITEM_MODAL)
}

export function updatePOItem({addAnother} = {}) {
  return {
    type: UPDATE_PO_ITEM,
    payload: {addAnother},
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[EDIT_PO_ITEM_MODAL],
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({poItemIDs, sku, quantity, unit_price, discount_amount}) => {
    const errors = {}

    if (poItemIDs.length === 0 && !sku) {
      errors.sku = 'Product is required'
    }

    if (!isNumeric(quantity)) {
      errors.quantity = 'Quantity must be a number'
    }

    if (!isPresent(quantity)) {
      errors.quantity = 'Quantity is required'
    }

    if (!isNumeric(unit_price)) {
      errors.unit_price = 'Supplier Unit Cost must be a number'
    }

    if (!isPresent(unit_price)) {
      errors.unit_price = 'Supplier Unit Cost is required'
    }

    if (!isNumeric(discount_amount)) {
      errors.discount_amount = 'Discount must be a number'
    }

    if (!isPresent(discount_amount)) {
      errors.discount_amount = 'Discount is required'
    }

    return errors
  },
)

export function* showEditPOItemWorker({
  payload: {poID, poItemIDs, autoFocusOn},
}) {
  const poItems = yield select(itemsSelector, {poID})

  autoFocusOn = autoFocusOn || (poItemIDs.length === 0 ? 'sku' : 'quantity')

  const firstPOItem = poItems.find(({id}) => id === poItemIDs[0])

  yield put(
    setForm(EDIT_PO_ITEM_MODAL, {
      poID,
      poItemIDs,
      autoFocusOn,
      isSingleInputEdit: poItemIDs.length > 1,
      sku: null,
      quantity: String(get(firstPOItem, 'quantity') || 1),
      unit_price: String(get(firstPOItem, 'unit_price') || 0),
      discount_amount: String(get(firstPOItem, 'discount_amount') || 0),
      isSaving: false,
      serverError: null,
    }),
  )
}

export function* updateSinglePOItem(poID, poItemID, params) {
  const {json: purchaseOrder} = poItemID
    ? yield call(
        api.put,
        `/purchase_order/${encodeURIComponent(poID)}/line/${poItemID}/`,
        params,
      )
    : yield call(
        api.post,
        `/purchase_order/${encodeURIComponent(poID)}/line/`,
        params,
      )

  yield call(setPO, purchaseOrder)
}

export function* updatePOItemWorker({payload: {addAnother}}) {
  try {
    const {
      poID,
      poItemIDs,
      sku,
      quantity,
      unit_price,
      discount_amount,
      autoFocusOn,
      isSingleInputEdit,
    } = yield select(modalFormSelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const params = {}

    if (poItemIDs.length === 0) {
      params.sku = sku
    }

    if (!isSingleInputEdit || autoFocusOn === 'quantity') {
      params.quantity = Number(quantity)
    }

    if (!isSingleInputEdit || autoFocusOn === 'unit_price') {
      params.unit_price = Number(unit_price)
    }

    if (!isSingleInputEdit || autoFocusOn === 'discount_amount') {
      params.discount_amount = Number(discount_amount)
    }

    yield all(
      poItemIDs.length === 0
        ? [call(updateSinglePOItem, poID, null, params)]
        : poItemIDs.map((poItemID) =>
            call(updateSinglePOItem, poID, poItemID, params),
          ),
    )

    yield call(ensurePO, poID, {reload: true})

    yield put(closeModal())

    yield call(
      showMessageToast,
      poItemIDs.length === 0
        ? 'Added PO item'
        : `Saved PO item${poItemIDs.length === 1 ? '' : 's'}`,
    )

    if (addAnother) {
      yield put(showEditPOItemModal(poID))
    }
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function EditPOItemModal({
  form,
  errors,
  firstItemSKU,
  productsBySKU,
  supplierID,
  ...props
}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title={
        form.poItemIDs.length === 0 ? 'Add PO Item' : 'Edit PO Item Values'
      }
      modalSize="sm"
      onConfirm={() => props.updatePOItem()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      MiddleButtons={
        form.poItemIDs.length === 0
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={hasErrors || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => props.updatePOItem({addAnother: true})}
              >
                Create and Add Another
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {form.poItemIDs.length === 0 ? (
          <>
            <li className="list__item--form list__item--no-style list__item--product-filter-stacked divider--bottom">
              {form.sku ? (
                <ButtonLink
                  className="darker align-left"
                  onClick={() => props.updateModalForm({sku: null})}
                >
                  <div className="lh-md margin-bottom-3">
                    <strong className="fs-01 margin-right-5">
                      {getProductName(productsBySKU[form.sku])}
                    </strong>
                    <span className="unbold">({form.sku})</span>
                  </div>
                  <div className="fs-n0 lh-md text--lt-grey">Edit</div>
                </ButtonLink>
              ) : (
                <ProductFilter
                  label="Select a Product to Add"
                  dropdown="EDIT_PO_ITEM_MODAL_PRODUCT_FILTER"
                  onSelect={(product) => {
                    const {quantity, unitPrice} = getInitialSupplierValues(
                      product,
                      supplierID,
                    )

                    props.updateModalForm({
                      sku: product.sku,
                      quantity,
                      unit_price: unitPrice,
                    })
                  }}
                  autoFocus={form.autoFocusOn === 'sku'}
                />
              )}
            </li>
          </>
        ) : (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              {form.poItemIDs.length === 1 ? (
                <>
                  <strong>Updating 1 item:</strong>
                  <div>{firstItemSKU}</div>
                </>
              ) : (
                <strong>Updating {form.poItemIDs.length} Items</strong>
              )}
            </div>
          </li>
        )}

        {(!form.isSingleInputEdit || form.autoFocusOn === 'quantity') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
              <label className="fs-00" htmlFor="quantity">
                Quantity
              </label>
            </li>
            <li
              className={classNames('list__item list__item--form', {
                'divider--bottom padding-bottom-15':
                  form.poItemIDs.length === 1,
              })}
            >
              <NumberInput
                id="quantity"
                value={form.quantity}
                onChange={(value) =>
                  props.updateModalForm({
                    quantity: `${value}`,
                  })
                }
                isInvalid={!!errors.quantity}
                autoFocus={
                  !!(form.sku && form.autoFocusOn === 'sku') ||
                  form.autoFocusOn === 'quantity'
                }
                min={0}
              />
              {errors.quantity && (
                <small className="error">{errors.quantity}</small>
              )}
            </li>
          </>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'unit_price') && (
          <li className="list__item list__item--form">
            <CurrencyInput
              label="Supplier Unit Cost"
              className="biggie margin-bottom-0"
              width="sm"
              id="unit_price"
              value={form.unit_price}
              onChange={(value) =>
                props.updateModalForm({unit_price: `${value}`})
              }
              errorMessage={errors.unit_price}
              autoFocus={form.autoFocusOn === 'unit_price'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'discount_amount') && (
          <li className="list__item list__item--form">
            <CurrencyInput
              label="Discount"
              className="biggie margin-bottom-0"
              width="sm"
              id="discount_amount"
              value={form.discount_amount}
              onChange={(value) =>
                props.updateModalForm({discount_amount: `${value}`})
              }
              errorMessage={errors.discount_amount}
              autoFocus={form.autoFocusOn === 'discount_amount'}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditPOItemModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    poItemIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    sku: PropTypes.string,
    quantity: PropTypes.string.isRequired,
    unit_price: PropTypes.string.isRequired,
    discount_amount: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  firstItemSKU: PropTypes.string.isRequired,
  productsBySKU: PropTypes.object.isRequired,
  supplierID: PropTypes.number.isRequired,
  updatePOItem: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  const firstItemID = form.poItemIDs[0]

  return {
    errors: errorsSelector(state),
    firstItemSKU: poItemSKUSelector(state, {
      poID: form.poID,
      poItemID: firstItemID,
    }),
    productsBySKU: productsSelector(state),
    supplierID: supplierIDSelector(state, {poID: form.poID}),
  }
}

const mapDispatchToProps = {
  updatePOItem,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(EditPOItemModal),
  modalFormSelector,
)
