import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

export default class Form extends Component {
  renderHeaderError() {
    if (!this.props.errorMessage) {
      return null
    }

    return (
      <li className="form-list-item row error-block">
        <div className="medium-12 columns">
          <p className="fs-00 margin-bottom-0">{this.props.errorMessage}</p>
        </div>
      </li>
    )
  }

  render() {
    const className = classNames('form-list columns', this.props.className)

    return (
      <ul className={className}>
        {this.renderHeaderError()}
        {this.props.children}
      </ul>
    )
  }
}

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
}
