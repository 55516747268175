import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {stringifyURL} from '../../../../common/querystring.js'
import {ensureArray} from '../../../../common/ensure.js'
import {PACK_SHIP_PAGE_FORM} from '../../../actions/ui/packShipPage/index.js'
import {canUsePackShipSelector} from '../../../../data/company.js'
import {
  allOrdersSelector,
  getOrderNumbersThatCanHaveShippingLabels,
  getOrderNumbersWithShippingLabels,
  getOrderNumbersThatCanHaveReturnLabels,
  getOrderNumbersWithReturnLabels,
  getIsContentsVerified,
} from '../../../../data/orders.js'
import {locationSelector} from '../location.js'

export function packShipPageFormSelector(state) {
  return state.ui.forms[PACK_SHIP_PAGE_FORM]
}

export const packShipOrderNumbersSelector = createSelector(
  locationSelector,
  ({query}) => ensureArray(query.orderNumbers),
)

export const indexSelector = createSelector(
  locationSelector,
  ({query}) => Number(query.index) || 0,
)

export const packShipErrorSelector = createSelector(
  packShipPageFormSelector,
  ({error} = {}) => error,
)

export const packShipIsLoadingSelector = createSelector(
  packShipPageFormSelector,
  ({isLoading} = {}) => isLoading === true,
)

export const packShipLabelTypeSelector = createSelector(
  packShipPageFormSelector,
  ({labelType} = {}) => labelType || 'shipping',
)

export const currentPackShipOrderNumberSelector = createSelector(
  packShipOrderNumbersSelector,
  indexSelector,
  (orderNumbers, index) => orderNumbers[index],
)

export const currentPackShipOrderSelector = createSelector(
  currentPackShipOrderNumberSelector,
  allOrdersSelector,
  (orderNumber, orders) => get(orders, orderNumber, null),
)

export const hashParamsSelector = createSelector(
  packShipOrderNumbersSelector,
  indexSelector,
  (orderNumbers, index) => ({
    index: index > 0 ? index : undefined,
    orderNumbers: orderNumbers.length ? orderNumbers : undefined,
  }),
)

export const packShipPageHashSelector = createSelector(
  hashParamsSelector,
  canUsePackShipSelector,
  (params, canUsePackShipSelector) => {
    if (!canUsePackShipSelector) {
      return null
    }

    let hash = '#/pack_ship'

    return stringifyURL(hash, params)
  },
)

export const backURLSelector = createSelector(
  hashParamsSelector,
  indexSelector,
  (params, index) => {
    if (index < 1) {
      return null
    }

    params = {
      ...params,
      index: index - 1,
    }

    return stringifyURL('#/pack_ship', params)
  },
)

export function cleanStartURLSelector() {
  return '#/pack_ship'
}

export const nextURLSelector = createSelector(
  hashParamsSelector,
  packShipOrderNumbersSelector,
  indexSelector,
  (params, orderNumbers, index) => {
    if (index >= orderNumbers.length - 1) {
      return null
    }

    params = {
      ...params,
      index: index + 1,
    }

    return stringifyURL('#/pack_ship', params)
  },
)

export const currentPackShipOrderNeedsContentsVerifiedSelector = createSelector(
  currentPackShipOrderSelector,
  (order) => !getIsContentsVerified(order),
)

export const verifiedOrderCountSelector = createSelector(
  packShipOrderNumbersSelector,
  allOrdersSelector,
  (orderNumbers, orders) =>
    orderNumbers.reduce((prev, orderNumber) => {
      const order = orders[orderNumber]

      if (getIsContentsVerified(order)) {
        return prev + 1
      }

      return prev
    }, 0),
)

export const currentPackShipOrderNeedsLabelSelector = createSelector(
  currentPackShipOrderSelector,
  packShipLabelTypeSelector,
  (order, labelType) => {
    if (!order) {
      return false
    }

    const ordersThatCan =
      labelType === 'shipping'
        ? getOrderNumbersThatCanHaveShippingLabels([order], [])
        : getOrderNumbersThatCanHaveReturnLabels([order], [])

    return ordersThatCan.length === 1
  },
)

export const currentPackShipOrderNeedsToPrintSelector = createSelector(
  currentPackShipOrderSelector,
  packShipLabelTypeSelector,
  (order, labelType) => {
    if (!order) {
      return false
    }

    const ordersThatNeed =
      labelType === 'shipping'
        ? getOrderNumbersWithShippingLabels([order], [])
        : getOrderNumbersWithReturnLabels([order], [])

    return ordersThatNeed.length === 1
  },
)
