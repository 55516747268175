import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import XHR from '../XHR.js'
import {
  determineIsRequesting,
  setIsRequesting,
} from '../../redux/actions/ui/index.js'

export class XHRListener extends Component {
  static propTypes = {
    determineIsRequesting: PropTypes.func.isRequired,
    setIsRequesting: PropTypes.func.isRequired,
  }

  componentDidMount() {
    XHR.subscribe(this.onRequest)

    const requests = this.filterRequests(XHR.getActiveRequests())
    this.props.setIsRequesting(requests.length > 0)
  }

  componentWillUnmount() {
    XHR.unsubscribe(this.onRequest)

    this.props.setIsRequesting(false)
  }

  onRequest = (requests) => {
    this.props.determineIsRequesting(this.filterRequests(requests).length)
  }

  filterRequests(requests) {
    return requests.filter(
      ({url, method}) =>
        !(url.match(/^http.*\/company\//) && method === 'get') &&
        url !== '/info.json' &&
        !url.match(/^http.*\/v3\/order\/counts.*created_after/) &&
        !url.match(/^http.*\/order\/.*created_after/) &&
        !url.match(/^http.*\/(product|address)\/\?.*&search/),
    )
  }

  render() {
    return null
  }
}

const mapDispatchToProp = {
  determineIsRequesting,
  setIsRequesting,
}

export default connect(null, mapDispatchToProp)(XHRListener)
