import {NEW_ID} from '../../../../../../common/constants/index.js'

export function errorsSelector() {
  return {}
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
  }
}
