import PropTypes from 'prop-types'

import {AddressShape} from '../../PropTypes.js'
import {PRIVACY_MASK} from '../../analytics/index.js'

export default function RowAddress({address, noPhone, noEmail}) {
  if (!address) {
    return null
  }

  const {street1, street2, city, state, zip, country, phone, email} = address

  return (
    <ul className={`list--no-style list--order-data ${PRIVACY_MASK}`}>
      {street1 && (
        <li className="list__item--order-data">
          <div className="inline-block--compact-addr">{street1}</div>
          {street2 && (
            <div className="inline-block--compact-addr prefix--comma">
              {street2}
            </div>
          )}
        </li>
      )}
      {(city || state || zip) && (
        <li className="list__item--order-data">
          <div className="inline-block--compact-addr margin-right">
            {city && <span>{city}</span>}
            {city && (state || zip) ? ', ' : ''}
            {state && <span>{state}</span>}
            {state && zip ? ' ' : ''}
            {zip && <span>{zip}</span>}
          </div>
        </li>
      )}
      {country && <li className="list__item--order-data">{country}</li>}
      {!noPhone && phone && (
        <li className="list__item--order-data hide--compact-addr">{phone}</li>
      )}
      {!noEmail && email && (
        <li className="list__item--order-data hide--compact-addr">{email}</li>
      )}
    </ul>
  )
}

RowAddress.propTypes = {
  address: AddressShape,
  noPhone: PropTypes.bool,
  noEmail: PropTypes.bool,
}
