import PropTypes from 'prop-types'
import classNames from 'classnames'

import {ErrorsShape} from '../../../common/PropTypes.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import BaseCheckbox from '../../../common/components/Checkbox.js'
import FormListItem from '../Common/FormListItem.js'
import TextInput from '../Common/TextInput.js'
import Select from '../Common/Select.js'
import {
  addNewShippingMapping,
  getShippingMappingError,
  removeShippingMapping,
  SupplierFormShape,
  updateShippingMapping,
  updateSuppliersForm,
} from './suppliersFunctions.js'

export default function ShippingMethodMaps({form, errors, isFBASupplier}) {
  return (
    <FormListItem className="bigger divider--bottom margin-top-25 margin-bottom-15">
      <p className="fs-00 margin-bottom-5">
        <strong>Supplier Shipping Mappings</strong>
      </p>
      <p className="fs-00 w-65 text--md-grey margin-bottom-20">
        Map requested shipping methods from your sales channel to
        {isFBASupplier
          ? ' one of three available FBA shipping methods.'
          : ' the supplier’s carriers, carrier accounts, and shipping methods (if applicable).'}
      </p>
      <table
        className={classNames(
          'table table--multi-location table--supplier-mappings margin-top-5',
          {
            'w-65': isFBASupplier,
          },
        )}
      >
        <thead>
          <tr>
            <th
              className={classNames(
                'table__th table__th--sm fs-n1 text--md-grey',
                {
                  'w-40': isFBASupplier,
                  'w-25': !isFBASupplier,
                },
              )}
            >
              Requested Shipping Method
            </th>
            <th className="table__th table__th--sm fs-n1 text--md-grey w-5">
              &nbsp;
            </th>
            {isFBASupplier ? (
              <th className="table__th table__th--sm fs-n1 text--md-grey w-40">
                FBA Shipping Method
              </th>
            ) : (
              <>
                <th className="table__th table__th--sm fs-n1 text--md-grey">
                  Supplier Carrier Name
                </th>
                <th className="table__th table__th--sm fs-n1 text--md-grey">
                  Supplier Carrier Account
                </th>
                <th className="table__th table__th--sm fs-n1 text--md-grey">
                  Supplier Shipping Method
                </th>
              </>
            )}
            <th className="table__th table__th--sm fs-n1 text--md-grey w-1">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody--multi-location">
          <tr>
            <td className="table__td table__td--sm v-align-middle">
              <strong>Any Requested Shipping Method from Sales Channel</strong>
            </td>
            <td className="table__td table__td--sm align-center v-align-middle">
              <strong className="fs-01 v-align-middle text--lt-grey">→</strong>
            </td>
            {isFBASupplier ? (
              <td className="table__td table__td--sm v-align-middle">
                <Select
                  tall={false}
                  width={100}
                  value={form.default_shipping_method}
                  onChange={(event) =>
                    updateSuppliersForm({
                      default_shipping_method: event.target.value,
                    })
                  }
                >
                  <option value="">Select a default...</option>
                  <option value="Expedited">Expedited</option>
                  <option value="Priority">Priority</option>
                  <option value="Standard">Standard</option>
                </Select>
              </td>
            ) : (
              <>
                <td className="table__td table__td--sm v-align-middle">
                  <TextInput
                    id="default_shipping_carrier"
                    tall={false}
                    value={form.default_shipping_carrier}
                    onChange={(event) =>
                      updateSuppliersForm({
                        default_shipping_carrier: event.target.value,
                      })
                    }
                  />
                </td>
                <td className="table__td table__td--sm v-align-middle">
                  <TextInput
                    id="default_shipping_account"
                    tall={false}
                    value={form.default_shipping_account}
                    onChange={(event) =>
                      updateSuppliersForm({
                        default_shipping_account: event.target.value,
                      })
                    }
                  />
                </td>
                <td className="table__td table__td--sm v-align-middle">
                  <TextInput
                    id="default_shipping_method"
                    tall={false}
                    value={form.default_shipping_method}
                    onChange={(event) =>
                      updateSuppliersForm({
                        default_shipping_method: event.target.value,
                      })
                    }
                  />
                </td>
              </>
            )}
            <td className="table__td table__td--sm v-align-middle">&nbsp;</td>
          </tr>
          {form.shipping_method_maps.map(
            (
              {
                active,
                requested_shipping_method,
                mapped_shipping_method,
                mapped_shipping_carrier,
                mapped_shipping_account,
              },
              index,
            ) => (
              <tr key={index}>
                <td className="table__td table__td--sm v-align-middle">
                  <div className="flex">
                    <div className="flex--justify-col flex-grow-0">
                      <BaseCheckbox
                        className="margin-bottom-0 margin-right-10"
                        checked={active}
                        onChange={() =>
                          updateShippingMapping(index, 'active', !active)
                        }
                      />
                    </div>
                    <div className="flex-grow-1">
                      <TextInput
                        id={`shipping_method_map_requested_shipping_method_${index}`}
                        tall={false}
                        value={requested_shipping_method}
                        disabled={!active}
                        onChange={(event) =>
                          updateShippingMapping(
                            index,
                            'requested_shipping_method',
                            event.target.value,
                          )
                        }
                        errorMessage={getShippingMappingError(
                          form,
                          errors,
                          index,
                        )}
                      />
                    </div>
                  </div>
                </td>
                <td className="table__td table__td--sm align-center v-align-middle">
                  <strong className="fs-01 v-align-middle text--lt-grey">
                    →
                  </strong>
                </td>
                {isFBASupplier ? (
                  <td className="table__td table__td--sm v-align-middle">
                    <Select
                      tall={false}
                      width={100}
                      value={mapped_shipping_method}
                      disabled={!active}
                      onChange={(event) =>
                        updateShippingMapping(
                          index,
                          'mapped_shipping_method',
                          event.target.value,
                        )
                      }
                    >
                      <option value="Expedited">Expedited</option>
                      <option value="Priority">Priority</option>
                      <option value="Standard">Standard</option>
                    </Select>
                  </td>
                ) : (
                  <>
                    <td className="table__td table__td--sm v-align-middle">
                      <TextInput
                        id={`shipping_method_map_mapped_shipping_carrier_${index}`}
                        tall={false}
                        placeholder={form.default_shipping_carrier}
                        value={mapped_shipping_carrier || ''}
                        disabled={!active}
                        onChange={(event) =>
                          updateShippingMapping(
                            index,
                            'mapped_shipping_carrier',
                            event.target.value,
                          )
                        }
                      />
                    </td>
                    <td className="table__td table__td--sm v-align-middle">
                      <TextInput
                        id={`shipping_method_map_mapped_shipping_account_${index}`}
                        tall={false}
                        placeholder={form.default_shipping_account}
                        value={mapped_shipping_account || ''}
                        disabled={!active}
                        onChange={(event) =>
                          updateShippingMapping(
                            index,
                            'mapped_shipping_account',
                            event.target.value,
                          )
                        }
                      />
                    </td>
                    <td className="table__td table__td--sm v-align-middle">
                      <TextInput
                        id={`shipping_method_map_mapped_shipping_method_${index}`}
                        tall={false}
                        placeholder={form.default_shipping_method}
                        value={mapped_shipping_method}
                        disabled={!active}
                        onChange={(event) =>
                          updateShippingMapping(
                            index,
                            'mapped_shipping_method',
                            event.target.value,
                          )
                        }
                      />
                    </td>
                  </>
                )}
                <td className="table__td table__td--sm align-center v-align-middle">
                  <ButtonLink
                    className="no-underline"
                    title="Remove Mapping"
                    onClick={() => removeShippingMapping(index)}
                  >
                    <span className="i-trash fs-00" aria-hidden="true"></span>
                  </ButtonLink>
                </td>
              </tr>
            ),
          )}
          <tr>
            <td
              className="table__td table__td--sm v-align-middle align-center"
              colSpan="6"
            >
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() => addNewShippingMapping()}
              >
                Add Mapping
              </ButtonPrimary>
            </td>
          </tr>
        </tbody>
      </table>
    </FormListItem>
  )
}

ShippingMethodMaps.propTypes = {
  form: SupplierFormShape.isRequired,
  errors: ErrorsShape.isRequired,
  isFBASupplier: PropTypes.bool,
}
