import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Helper({
  type = 'standard',
  title,
  className,
  children,
}) {
  return (
    <div className={classNames('alert', className, `alert--${type}`)}>
      <dl className="list">
        {title && (
          <dt className="list__title fs-00 lh-md margin-bottom-5">{title}</dt>
        )}
        <dd className="list__item fs-n0 margin-bottom-0">{children}</dd>
      </dl>
    </div>
  )
}

Helper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}
