import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {OrderFinancialShape, OrderLineShape} from '../../common/PropTypes.js'
import Currency from '../../common/components/Currency.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {usesInventorySelector} from '../../data/company.js'
import {
  orderLinesSelector,
  financialInfoSelector,
  orderLineIDsSelector,
} from '../../data/orders.js'
import {showEditOrderLineModal} from '../OrderListPage/Modals/EditOrderLineModal.js'
import {showConfirmOrderLineDeleteModal} from '../OrderListPage/Modals/ConfirmOrderLineDeleteModal.js'
import {showOrderTextFieldModal} from '../OrderListPage/Modals/OrderTextFieldModal.js'
import {
  OrderDetailFormShape,
  updateOrderDetailForm,
} from './orderDetailActions.js'
import {
  orderDetailFormSelector,
  areAllLinesSelectedSelector,
  areIndeterminateLinesSelectedSelector,
} from './orderDetailSelectors.js'

import ProductRow from './ProductRow.js'
import {useSelector} from '../../store.js'
import {hasOrderFinancialsPermissionSelector} from '../../data/me.js'

function ProductInfo({
  orderNumber,
  readOnly,
  form,
  lines,
  financialInfo,
  usesInventory,
  areAllLinesSelected,
  areIndeterminateLinesSelected,
  orderLineIDs,
}) {
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )
  const baseColSpan =
    4 +
    (readOnly ? 0 : 1) +
    (usesInventory ? 1 : 0) +
    (hasOrderFinancialsPermission ? 3 : 0)

  return (
    <table className="table">
      <thead>
        <tr>
          {!readOnly && (
            <th className="table__th table__th--sm w-1">
              <Checkbox
                className="margin-bottom-0"
                checked={areAllLinesSelected}
                indeterminate={areIndeterminateLinesSelected}
                onClick={(checked) =>
                  updateOrderDetailForm({
                    selectedOrderLineIDs: checked ? orderLineIDs : [],
                  })
                }
              />
            </th>
          )}
          <th className="table__th table__th--sm th-width-25">Product</th>
          <th className="table__th table__th--sm th-width-15">
            {readOnly || form.selectedOrderLineIDs.length === 0 ? (
              'Addl Details'
            ) : (
              <ButtonLink
                className="darker lh-inherit align-right"
                title="Bulk Update Addl Details"
                onClick={() =>
                  showEditOrderLineModal(
                    orderNumber,
                    form.selectedOrderLineIDs,
                    'details',
                  )
                }
              >
                Addl Details
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--sm th-width-15 align-right">
            {readOnly || form.selectedOrderLineIDs.length === 0 ? (
              'Ordered Qty'
            ) : (
              <ButtonLink
                className="darker lh-inherit align-right"
                title="Bulk Update Ordred Qty"
                onClick={() =>
                  showEditOrderLineModal(
                    orderNumber,
                    form.selectedOrderLineIDs,
                    'quantity',
                  )
                }
              >
                Ordered Qty
              </ButtonLink>
            )}
          </th>
          {usesInventory && (
            <th className="table__th table__th--sm th-width-10 align-right">
              Available
            </th>
          )}
          {hasOrderFinancialsPermission && (
            <>
              <th className="table__th table__th--sm th-width-10 align-right">
                {readOnly || form.selectedOrderLineIDs.length === 0 ? (
                  'Item Price'
                ) : (
                  <ButtonLink
                    className="darker lh-inherit align-right"
                    title="Bulk Update Item Price"
                    onClick={() =>
                      showEditOrderLineModal(
                        orderNumber,
                        form.selectedOrderLineIDs,
                        'item_price',
                      )
                    }
                  >
                    Item Price
                  </ButtonLink>
                )}
              </th>
              <th className="table__th table__th--sm th-width-10 align-right">
                {readOnly || form.selectedOrderLineIDs.length === 0 ? (
                  'Discount'
                ) : (
                  <ButtonLink
                    className="darker lh-inherit align-right"
                    title="Bulk Update Discount"
                    onClick={() =>
                      showEditOrderLineModal(
                        orderNumber,
                        form.selectedOrderLineIDs,
                        'discount_amount',
                      )
                    }
                  >
                    Discount
                  </ButtonLink>
                )}
              </th>
              <th className="table__th table__th--sm th-width-10 align-right">
                {readOnly || form.selectedOrderLineIDs.length === 0 ? (
                  'Total Price'
                ) : (
                  <ButtonLink
                    className="darker lh-inherit align-right"
                    title="Bulk Update Total Price"
                    onClick={() =>
                      showEditOrderLineModal(
                        orderNumber,
                        form.selectedOrderLineIDs,
                        'total_price',
                      )
                    }
                  >
                    Total Price
                  </ButtonLink>
                )}
              </th>
            </>
          )}
          <th className="table__th table__th--sm w-1">
            {form.isLoadingLines ? (
              <div className="loading align-center">
                <span className="spinner--x-sm v-align-middle" />
              </div>
            ) : !readOnly && form.selectedOrderLineIDs.length > 0 ? (
              <ButtonLink
                className="no-underline"
                title="Remove Lines"
                onClick={() =>
                  showConfirmOrderLineDeleteModal(
                    orderNumber,
                    form.selectedOrderLineIDs,
                  )
                }
              >
                <span className="i-trash fs-00" aria-hidden="true"></span>
              </ButtonLink>
            ) : (
              ''
            )}
          </th>
        </tr>
      </thead>
      <tbody className="table__tbody table__tbody--lines">
        {lines.map((line) => (
          <ProductRow
            key={line.link}
            line={line}
            orderNumber={orderNumber}
            readOnly={readOnly}
            orderLineIDs={orderLineIDs}
          />
        ))}
        <tr>
          {!readOnly && (
            <td
              className="table__td table__td--product-search"
              colSpan={baseColSpan}
            >
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() => showEditOrderLineModal(orderNumber)}
              >
                Add a Product
              </ButtonPrimary>
            </td>
          )}
        </tr>
      </tbody>
      {hasOrderFinancialsPermission && (
        <tbody className="table__tbody table__tbody--totals">
          <tr>
            <td
              className="table__td fs-00 align-right"
              colSpan={baseColSpan - 2}
            >
              <strong>Product Total</strong>
            </td>
            <td className="table__td fs-00" colSpan="2">
              <strong>
                <Currency value={financialInfo.product_amount} />
              </strong>
            </td>
          </tr>
          <tr>
            <td
              className="table__td fs-00 align-right v-align-middle"
              colSpan={baseColSpan - 2}
            >
              <strong>Discount</strong>
            </td>
            <td className="table__td fs-00" colSpan="2">
              {!readOnly ? (
                <button
                  className="btn btn--link darker fs-00  v-align-middle"
                  type="button"
                  onClick={() =>
                    showOrderTextFieldModal({
                      orderNumber,
                      value: financialInfo.discount_amount,
                      display: 'Discount',
                      apiProp: 'discount_amount',
                      inputType: 'currency',
                      isRequired: true,
                    })
                  }
                >
                  <Currency value={financialInfo.discount_amount} />
                </button>
              ) : (
                <strong>
                  <Currency value={financialInfo.discount_amount} />
                </strong>
              )}
            </td>
          </tr>
          <tr>
            <td
              className="table__td fs-00 align-right v-align-middle"
              colSpan={baseColSpan - 2}
            >
              <strong>Shipping and Handling</strong>
            </td>
            <td className="table__td fs-00" colSpan="2">
              {!readOnly ? (
                <button
                  className="btn btn--link darker fs-00  v-align-middle"
                  type="button"
                  onClick={() =>
                    showOrderTextFieldModal({
                      orderNumber,
                      value: financialInfo.shipping_amount,
                      display: 'Shipping and Handling',
                      apiProp: 'shipping_amount',
                      inputType: 'currency',
                      isRequired: true,
                    })
                  }
                >
                  <Currency value={financialInfo.shipping_amount} />
                </button>
              ) : (
                <strong>
                  <Currency value={financialInfo.shipping_amount} />
                </strong>
              )}
            </td>
          </tr>
          <tr>
            <td
              className="table__td fs-00 align-right v-align-middle"
              colSpan={baseColSpan - 2}
            >
              <strong>Tax</strong>
            </td>
            <td className="table__td fs-00" colSpan="2">
              {!readOnly ? (
                <button
                  className="btn btn--link darker fs-00 v-align-middle"
                  type="button"
                  onClick={() =>
                    showOrderTextFieldModal({
                      orderNumber,
                      value: financialInfo.tax_amount,
                      display: 'Tax',
                      apiProp: 'tax_amount',
                      inputType: 'currency',
                      isRequired: true,
                    })
                  }
                >
                  <Currency value={financialInfo.tax_amount} />
                </button>
              ) : (
                <strong>
                  <Currency value={financialInfo.tax_amount} />
                </strong>
              )}
            </td>
          </tr>
          <tr>
            <td
              className="table__td table__td--grand-total fs-00 align-right"
              colSpan={baseColSpan - 2}
            >
              <strong>Grand Total</strong>
            </td>
            <td className="table__td table__td--grand-total fs-00" colSpan="2">
              <strong>
                <Currency value={financialInfo.grand_total} />
              </strong>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  )
}

ProductInfo.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,

  form: OrderDetailFormShape.isRequired,
  lines: PropTypes.arrayOf(OrderLineShape).isRequired,
  financialInfo: OrderFinancialShape.isRequired,
  usesInventory: PropTypes.bool.isRequired,
  areAllLinesSelected: PropTypes.bool.isRequired,
  areIndeterminateLinesSelected: PropTypes.bool.isRequired,
  orderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    form: orderDetailFormSelector(state),
    lines: orderLinesSelector(state, {orderNumber}),
    financialInfo: financialInfoSelector(state, {orderNumber}),
    usesInventory: usesInventorySelector(state),
    areAllLinesSelected: areAllLinesSelectedSelector(state),
    areIndeterminateLinesSelected: areIndeterminateLinesSelectedSelector(state),
    orderLineIDs: orderLineIDsSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(ProductInfo)
