import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'

import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import formConnect from '../../../common/formConnect.js'
import {addProducts, archive, unarchive} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshProductList} from '../productListFunctions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const ARCHIVE_PRODUCT_MODAL = 'ARCHIVE_PRODUCT_MODAL'
export const ARCHIVE_PRODUCT = 'ARCHIVE_PRODUCT'

export function showArchiveProductModal(skus, willArchive) {
  return setForm(ARCHIVE_PRODUCT_MODAL, {
    skus,
    willArchive,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(ARCHIVE_PRODUCT_MODAL, ...args)
}

export function closeModal() {
  return removeForm(ARCHIVE_PRODUCT_MODAL)
}

export function archiveProduct() {
  return {
    type: ARCHIVE_PRODUCT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[ARCHIVE_PRODUCT_MODAL],
)

export function* archiveProductWorker() {
  try {
    const {skus, willArchive} = yield select(modalFormSelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const products = yield all(
      skus.map((sku) => call(willArchive ? archive : unarchive, sku)),
    )

    yield call(addProducts, products)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `${skus.length} ${
        skus.length > 1 ? 'products' : 'product'
      } successfully ${willArchive ? 'archived' : 'unarchived'}!`,
    )

    yield call(refreshProductList)
  } catch (err) {
    yield put(updateModalForm({serverError: err.message || err.error_message}))
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

export function ArchiveProductModal({
  form: {skus, willArchive, isSaving, serverError},
  ...props
}) {
  const label = skus.length > 1 ? 'products' : 'product'
  const action = willArchive ? 'Archive' : 'Unarchive'

  const title =
    skus.length > 1
      ? `${action} ${skus.length} products`
      : `${action} ${skus[0]}`

  return (
    <ConfirmModal
      title={title}
      modalSize="sm"
      onConfirm={() => props.archiveProduct()}
      onCancel={() => props.closeModal()}
      confirmText={action}
      cancelText="Cancel"
      isSaving={isSaving}
      error={serverError}
    >
      {willArchive
        ? `The ${label} will be made inactive, but you can always unarchive ${
            skus.length > 1 ? 'them' : 'it'
          } later.`
        : `The ${label} will be made active again.`}
    </ConfirmModal>
  )
}

ArchiveProductModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    willArchive: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  archiveProduct: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: modalFormSelector(state),
  }
}

const mapDispatchToProps = {
  archiveProduct,
  closeModal,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ArchiveProductModal),
  modalFormSelector,
)
