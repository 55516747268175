import PropTypes from 'prop-types'
import pick from 'lodash/pick.js'

import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import Radio from '../../../common/components/Radio.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'

export function setupExportFulfillmentSpeedForm(params = {}) {
  let {reportID} = params
  params = pick(params, ['fsrDateType', 'non_shipping_hours'])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  return {
    fsrDateType: reportParams.date_type || 'created',
    non_shipping_hours: reportParams.non_shipping_hours || 0,
    ...params,
  }
}

export function exportFulfillmentSpeedErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportFulfillmentSpeedPayloadSelector(state, {formName}) {
  const {reportType, fsrDateType, non_shipping_hours} = formSelector(state, {
    formName,
  })

  const payload = {
    type: reportType,
    params: {
      date_type: fsrDateType,
      non_shipping_hours,
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }

  return payload
}

export default function ExportFulfillmentSpeedForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportFulfillmentSpeedErrorsSelector(state, {formName}),
  )

  return (
    <ul className="list list--no-style">
      <li className="list__item--form flex margin-bottom-15">
        <div className="margin-right-20">
          <Radio
            mode="fancy"
            label="Order Imported Date"
            id="created"
            checked={form.fsrDateType === 'created'}
            onChange={() => updateForm(formName, {fsrDateType: 'created'})}
          />
        </div>
        <div>
          <Radio
            mode="fancy"
            label="Order Placed Date"
            id="placed_date"
            checked={form.fsrDateType === 'placed_date'}
            onChange={() => updateForm(formName, {fsrDateType: 'placed_date'})}
          />
        </div>
      </li>
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--form margin-bottom-15">
        <p className="fs-00 lh-md margin-bottom-10">
          <strong>Length of Fulfillment Week</strong>
        </p>
        <div className="flex">
          <div className="margin-right-20">
            <Radio
              mode="fancy"
              label="7 Days"
              id="non_shipping_hours_0"
              checked={form.non_shipping_hours === 0}
              onChange={() => updateForm(formName, {non_shipping_hours: 0})}
            />
          </div>
          <div className="margin-right-20">
            <Radio
              mode="fancy"
              label="6 Days"
              id="non_shipping_hours_24"
              checked={form.non_shipping_hours === 24}
              onChange={() => updateForm(formName, {non_shipping_hours: 24})}
            />
          </div>
          <div>
            <Radio
              mode="fancy"
              label="5 Days"
              id="non_shipping_hours_48"
              checked={form.non_shipping_hours === 48}
              onChange={() => updateForm(formName, {non_shipping_hours: 48})}
            />
          </div>
        </div>
      </li>
    </ul>
  )
}

ExportFulfillmentSpeedForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
