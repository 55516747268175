import PropTypes from 'prop-types'

import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import {showRestoreOrderModal} from '../../../../../OrderListPage/Modals/RestoreOrderModal.js'

export default function CancelledSummary({orderNumber}) {
  return (
    <div className="fs-n1">
      <ButtonLink
        className="v-align-base"
        onClick={() => showRestoreOrderModal([orderNumber])}
      >
        Restore order
      </ButtonLink>
    </div>
  )
}

CancelledSummary.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
