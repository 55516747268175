import PropTypes from 'prop-types'

import {
  removeFormArrayElement,
  updateFormObject,
  useSelector,
} from '../../../../store.js'
import {
  MODAL_FORM,
  errorsSelector,
  pickupUPSContainerTypesSelector,
  pickupUPSServiceTypesSelector,
} from './pickupModalFunctions.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'

export default function Piece({piece, index, canDelete}) {
  const containerTypes = useSelector(pickupUPSContainerTypesSelector)
  const serviceTypes = useSelector(pickupUPSServiceTypesSelector)
  const errors = useSelector(errorsSelector)

  return (
    <tr>
      <td className="table__td">
        <select
          className="select select--modal w-100 margin-bottom-0"
          value={piece[`${UPS}__containerType`]}
          onChange={(event) => {
            updateFormObject(MODAL_FORM, ['pieces', index], {
              [`${UPS}__containerType`]: event.target.value,
            })
          }}
        >
          {containerTypes.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      </td>
      <td className="table__td">
        <select
          className="select select--modal w-100 margin-bottom-0"
          value={piece[`${UPS}__serviceType`]}
          onChange={(event) => {
            updateFormObject(MODAL_FORM, ['pieces', index], {
              [`${UPS}__serviceType`]: event.target.value,
            })
          }}
        >
          {serviceTypes.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      </td>
      <td className="table__td">
        <input
          type="text"
          className="input--text input--h-29 w-100 margin-bottom-0"
          value={piece.destinationCountry}
          onChange={(event) => {
            updateFormObject(MODAL_FORM, ['pieces', index], {
              destinationCountry: event.target.value,
            })
          }}
        />
        {errors[`pieces__${index}__destinationCountry`] && (
          <small className="error error-message">
            {errors[`pieces__${index}__destinationCountry`]}
          </small>
        )}
      </td>
      <td className="table__td">
        <input
          type="text"
          className="input--text input--x-sm input--h-29 make-inline-block margin-bottom-0"
          value={piece.quantity}
          onChange={(event) => {
            updateFormObject(MODAL_FORM, ['pieces', index], {
              quantity: event.target.value,
            })
          }}
        />
        {errors[`pieces__${index}__quantity`] && (
          <small className="error error-message">
            {errors[`pieces__${index}__quantity`]}
          </small>
        )}
      </td>
      <td className="table__td v-align-middle">
        <button
          className="btn btn--link no-underline"
          type="button"
          title="Remove package group"
          onClick={() => removeFormArrayElement(MODAL_FORM, ['pieces'], index)}
          disabled={!canDelete}
        >
          <span className="i-trash fs-01" aria-hidden="true" />
        </button>
      </td>
    </tr>
  )
}

Piece.propTypes = {
  index: PropTypes.number.isRequired,
  piece: PropTypes.shape({
    containerType: PropTypes.string.isRequired,
    serviceType: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    destinationCountry: PropTypes.string.isRequired,
  }).isRequired,
  canDelete: PropTypes.bool.isRequired,
}
