import PropTypes from 'prop-types'
import classNames from 'classnames'

import ExportCSVButton from './ExportCSVButton.js'
import HorizontalBarGraph from './HorizontalBarGraph.js'

export default function HorizontalBarPane({
  isLoading,
  title,
  data,
  isCurrency,
  onExport,
}) {
  return (
    <div
      className={classNames('panel panel--analytics clearfix', {
        loading: isLoading,
      })}
    >
      <div className="panel__header panel__header--analytics flex--justify">
        <h3 className="subheader subheader--analytics">{title}</h3>
        {onExport && data && <ExportCSVButton onExport={onExport} />}
      </div>
      <div className="medium-12 columns">
        <HorizontalBarGraph data={data} isCurrency={isCurrency} />
      </div>
    </div>
  )
}

HorizontalBarPane.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  isCurrency: PropTypes.bool,
  onExport: PropTypes.func,
}
