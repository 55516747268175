import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {progressSelector} from '../../redux/selectors/ui/dataImport/progress.js'

class Summary extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    totalErrored: PropTypes.number.isRequired,
    totalDeleted: PropTypes.number.isRequired,
    totalSaved: PropTypes.number.isRequired,
    totalUnsaved: PropTypes.number.isRequired,
  }

  render() {
    return (
      <ul className="list--import-count">
        <li className="list__item--import-count">
          <strong>{this.props.totalUnsaved}</strong> entries remaining
        </li>
        <li className="list__item--import-count">
          <strong>{this.props.totalSaved}</strong> entries saved
        </li>
        <li className="list__item--import-count">
          <strong>{this.props.totalErrored}</strong> errors
        </li>
        <li className="list__item--import-count">
          <strong>{this.props.totalDeleted}</strong> deletions
        </li>
      </ul>
    )
  }
}

function mapStateToProps(state) {
  const progress = progressSelector(state)
  return {
    data: state.ui.dataImport.data,
    totalErrored: progress.totalErrored,
    totalDeleted: progress.totalDeleted,
    totalSaved: progress.totalSaved,
    totalUnsaved: progress.totalUnsaved,
  }
}

export default connect(mapStateToProps)(Summary)
