import {useEffect} from 'react'
import analytics from '../../common/analytics/index.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {usesAppsSelector} from '../../data/company.js'
import {useSelector} from '../../store.js'

export default function SuppliersFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)
  useEffect(() => {
    analytics.trackEvent('suppliers_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
      <div
        className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-02 lh-md  margin-bottom-15">
        <strong>Seamlessly Manage Your Suppliers</strong>
      </p>
      {usesApps ? (
        <p className="fs-01 lh-lg margin-bottom-25">
          To gain access to our{' '}
          <a
            className="btn btn--link darker border-underline v-align-base"
            href="https://support.ordoro.com/set-up-your-suppliers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>supplier management</span>
          </a>{' '}
          features, you need to be on the{' '}
          <strong>Premium Plan of our Inventory App</strong> or add the{' '}
          <strong>Dropshipping App</strong> to your account.
        </p>
      ) : (
        <p className="fs-01 lh-lg margin-bottom-25">
          Upgrade your account with our{' '}
          <strong>Inventory Management Module</strong> to gain access to{' '}
          <a
            className="btn btn--link darker border-underline v-align-base"
            href="https://support.ordoro.com/set-up-your-suppliers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>supplier management</span>
          </a>{' '}
          and other inventory features.
        </p>
      )}

      <div className="margin-bottom-10">
        {usesApps ? (
          <ButtonPrimary
            size="x-md"
            className="meta--feature-lock-suppliers"
            alt
            onClick={() => showUpgradeModal()}
          >
            Manage Your Apps
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            size="x-md"
            className="meta--feature-lock-suppliers"
            alt
            onClick={() => showFeatureLockedModal('inventory')}
          >
            Get the Inventory Management Module
          </ButtonPrimary>
        )}
      </div>
    </div>
  )
}
