import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {MODAL_NAME} from '../../../actions/ui/modals/listOnAmazonModal.js'

function skus(
  state = INITIAL_STATE.ui.modals.listOnAmazonModal.skus,
  action = {},
) {
  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.skus
  }

  return state
}

export default combineReducers({
  skus,
})
