import PropTypes from 'prop-types'

import FormListItem from '../Common/FormListItem.js'
import {showReauthorizeCartModal} from '../Modals/ReauthorizeCartModal.js'

export default function ReauthorizeButton({children, formName}) {
  return (
    <FormListItem className="divider--top">
      <div className="fs-00 margin-bottom-3">
        {children || <strong>Account Authentication</strong>}
      </div>
      <button
        type="button"
        className="btn btn--primary btn--primary-ol btn--sm reauthorize"
        onClick={() => showReauthorizeCartModal(formName)}
      >
        Reauthorize
      </button>
    </FormListItem>
  )
}

ReauthorizeButton.propTypes = {
  children: PropTypes.node,
  formName: PropTypes.string.isRequired,
}
