export const BASE = 'ordoro/data/apiKeys'
export const SET_API_KEYS = `${BASE}/SET_API_KEYS`
export const UPDATE_API_KEY = `${BASE}/UPDATE_API_KEY`
export const GET_API_KEYS = `${BASE}/GET_API_KEYS`

export function setAPIKeys(apiKeys) {
  return {
    type: SET_API_KEYS,
    payload: apiKeys,
  }
}

export function updateAPIKey(apiKey) {
  return {
    type: UPDATE_API_KEY,
    payload: apiKey,
  }
}

export function getAPIKeys() {
  return {
    type: GET_API_KEYS,
  }
}
