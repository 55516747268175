export default function handleListSelection({
  value,
  isSelected,
  isShiftKey,
  selected,
  list,
  previousValue,
}) {
  if (isShiftKey) {
    let indexOfNextToLastSelected =
      previousValue !== undefined ? list.indexOf(previousValue) : -1

    indexOfNextToLastSelected =
      indexOfNextToLastSelected !== -1
        ? indexOfNextToLastSelected
        : list.indexOf(selected[selected.length - 1])

    const indexOfLastSelected = list.indexOf(value)

    const increasingNumber = indexOfNextToLastSelected < indexOfLastSelected

    const toToggle = []
    for (
      let i = indexOfNextToLastSelected;
      increasingNumber ? i <= indexOfLastSelected : i >= indexOfLastSelected;
      i = i + (increasingNumber ? 1 : -1)
    ) {
      if (list[i]) {
        toToggle.push(list[i])
      } else {
        break
      }
    }

    return [
      ...selected.filter((s) => !toToggle.includes(s)),
      ...(isSelected ? toToggle : []),
    ]
  }

  const newSelected = selected.filter((p) => p !== value)

  if (isSelected) {
    newSelected.push(value)
  }

  return newSelected
}
