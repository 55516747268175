import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {clear} from '../../redux/actions/ui/dataImport/index.js'

import StrategiesList from './StrategiesList.js'
import FileUpload from './FileUpload.js'
import Overview from './Overview.js'

class Header extends Component {
  renderStartOver() {
    return (
      <button
        type="button"
        className="btn btn--link fs-n0 margin-bottom-15"
        onClick={this.props.clear}
      >
        Restart
      </button>
    )
  }

  renderInitialSplash() {
    return (
      <div className="row margin-top-20">
        <div className="medium-8 medium-centered columns">
          <StrategiesList strategyClasses={this.props.strategyClasses} />
          <FileUpload onFiles={this.props.processFiles} />
          <p className="centered-text">
            <button
              className="create-example-file inline-text-button make-smaller margin-bottom-0 meta-csv-button-downloadexample"
              onClick={this.props.createExampleFile}
            >
              Download an example file
            </button>
          </p>
        </div>
      </div>
    )
  }

  render() {
    const hasColumns = this.props.columns.length !== 0

    const overview = (
      <Overview
        confirmColumns={this.props.confirmColumns}
        saveAll={this.props.saveAll}
        queueNextData={this.props.queueNextData}
        stop={this.props.stop}
      />
    )

    const children = hasColumns ? overview : this.renderInitialSplash()

    return (
      <div className="meta-csv-div-header margin-top-20">
        <div className="row">
          <div className="medium-8 medium-centered align-center columns">
            <h2 className="list-title align-center margin-bottom-10">
              <span>Data Import</span>
            </h2>
            {hasColumns && this.renderStartOver()}
          </div>
        </div>
        {children}
      </div>
    )
  }
}

Header.propTypes = {
  strategyClasses: PropTypes.array.isRequired,
  processFiles: PropTypes.func.isRequired,
  createExampleFile: PropTypes.func.isRequired,
  confirmColumns: PropTypes.func.isRequired,
  saveAll: PropTypes.func.isRequired,
  queueNextData: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  clear: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    columns: state.ui.dataImport.columns,
  }
}

const mapDispatchToProps = {
  clear,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
