import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'

import {
  SHIPPER_NAMES_BY_TYPE,
  SHIPPING_METHOD_NAME_BY_TYPE_AND_CODE,
} from '../../data/shipperOptions.js'
import {analyticsFormSelector} from './analyticsSelectors.js'

export const summaryStatsSelector = createSelector(
  (state) => get(analyticsFormSelector(state), 'order_financials__shipping'),
  (shippingData) => {
    if (!shippingData) {
      return null
    }

    const {totalCosts, totalCount} = shippingData.reduce(
      (prev, bucket) => {
        if (!bucket.shipping_vendor) {
          return prev
        }

        prev.totalCosts += bucket.total_label_costs
        prev.totalCount += bucket.total_orders

        return prev
      },
      {totalCosts: 0, totalCount: 0},
    )

    return {
      totalCosts,
      totalCount,
      averageCost: totalCosts / (totalCount || 1),
    }
  },
)

export const shippingCostsByCarrierSelector = createSelector(
  (state) => get(analyticsFormSelector(state), 'order_financials__shipping'),
  (shippingData) => {
    if (!shippingData) {
      return null
    }

    const shippingCostsByCarrier = shippingData.reduce((prev, bucket) => {
      const shipperType = bucket.shipping_vendor

      if (!shipperType) {
        return prev
      }

      const name = SHIPPER_NAMES_BY_TYPE[shipperType] || shipperType
      const value = bucket.total_label_costs
      const count = bucket.total_orders

      prev[name] = prev[name]
        ? {
            name,
            value: prev[name].value + value,
            count: prev[name].count + count,
          }
        : {
            name,
            value,
            count,
          }

      prev[name].average = prev[name].value / (prev[name].count || 1)

      return prev
    }, {})

    return {
      buckets: sortBy(shippingCostsByCarrier, 'value').reverse(),
    }
  },
)

export const shippingCostsByCarrierAsCollectionSelector = createSelector(
  shippingCostsByCarrierSelector,
  (shippingCostsByCarrier) =>
    shippingCostsByCarrier.buckets.map((bucket) => ({
      carrier: bucket.name,
      costs$: bucket.value,
      count: bucket.count,
      average$: bucket.average,
    })),
)

export const shippingCostsByMethodSelector = createSelector(
  (state) => get(analyticsFormSelector(state), 'order_financials__shipping'),
  (shippingData) => {
    if (!shippingData) {
      return null
    }

    const shippingCostsByMethod = shippingData.reduce((prev, bucket) => {
      const shipperType = bucket.shipping_vendor
      const methodCode = bucket.shipping_method
      const value = bucket.total_label_costs
      const count = bucket.total_orders

      if (!shipperType || !methodCode) {
        return prev
      }
      const name = SHIPPER_NAMES_BY_TYPE[shipperType] || shipperType
      const method = get(
        SHIPPING_METHOD_NAME_BY_TYPE_AND_CODE,
        [shipperType, methodCode],
        methodCode,
      )

      prev.push({
        name: method.indexOf(name) === 0 ? method : `${name} ${method}`,
        value,
        count,
        average: value / (count || 1),
      })

      return prev
    }, [])

    return {
      buckets: sortBy(shippingCostsByMethod, 'value').reverse(),
    }
  },
)

export const shippingCostsByMethodAsCollectionSelector = createSelector(
  shippingCostsByMethodSelector,
  (shippingCostsByMethod) =>
    shippingCostsByMethod.buckets.map((bucket) => ({
      method: bucket.name,
      costs$: bucket.value,
      count: bucket.count,
      average$: bucket.average,
    })),
)

export const shippingCountsByCarrierSelector = createSelector(
  shippingCostsByCarrierSelector,
  (shippingCostsByCarrier) => {
    if (!shippingCostsByCarrier) {
      return null
    }

    return {
      buckets: sortBy(
        shippingCostsByCarrier.buckets.map(({name, count}) => ({
          name,
          value: count,
        })),
        'value',
      ).reverse(),
    }
  },
)

export const shippingCountsByCarrierAsCollectionSelector = createSelector(
  shippingCountsByCarrierSelector,
  (shippingCountsByCarrier) =>
    shippingCountsByCarrier.buckets.map((bucket) => ({
      carrier: bucket.name,
      order_count: bucket.value,
    })),
)

export const shippingFulfillmentTimeSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__fulfillment_latency'),
  (fulfillmentLatencyData) => {
    if (!fulfillmentLatencyData) {
      return null
    }

    const buckets = fulfillmentLatencyData.reduce((prev, bucket) => {
      const fulfillmentLatency = bucket.fulfillment_latency

      if (!fulfillmentLatency) {
        return prev
      }

      prev[fulfillmentLatency] = bucket.total_orders

      return prev
    }, {})

    const shippingFulfillmentTime = {
      buckets: [
        {
          key: 'less_24_hours',
          name: 'Within 24 Hours',
          value: buckets['less_24_hours'] || 0,
        },
        {
          key: '24_to_48_hours',
          name: 'Between 24 & 48 Hours',
          value: buckets['24_to_48_hours'] || 0,
        },
        {
          key: '48_to_72_hours',
          name: 'Between 48 & 72 Hours',
          value: buckets['48_to_72_hours'] || 0,
        },
        {
          key: 'over_72_hours',
          name: 'More Than 72 Hours',
          value: buckets['over_72_hours'] || 0,
        },
      ],
    }

    return shippingFulfillmentTime
  },
)

export function shippingExportToCSVParamsSelector(state, {type, date}) {
  if (type === 'shippingCostsByCarrier') {
    return {
      headers: ['carrier', 'costs$', 'count', 'average$'],
      data: shippingCostsByCarrierAsCollectionSelector(state),
      fileName: `${date}_Shipping-Costs-By-Carrier.csv`,
      toastMessage: 'Shipping Costs by Carrier CSV Exported',
    }
  } else if (type === 'shippingCostsByMethod') {
    return {
      headers: ['method', 'costs$', 'count', 'average$'],
      data: shippingCostsByMethodAsCollectionSelector(state),
      fileName: `${date}_Shipping-Costs-By-Method.csv`,
      toastMessage: 'Shipping Costs by Method CSV Exported',
    }
  } else if (type === 'shippingCountsByCarrier') {
    return {
      headers: ['carrier', 'order_count'],
      data: shippingCountsByCarrierAsCollectionSelector(state),
      fileName: `${date}_Order-Counts-By-Carrier.csv`,
      toastMessage: 'Order Counts by Carrier CSV Exported',
    }
  }

  return null
}
