import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {OrderShape} from '../../../../../../common/PropTypes.js'
import Currency, {isTBD} from '../../../../../../common/components/Currency.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import {
  shippedShipperNameSelector,
  shippedShippingMethodSelector,
  shippedBoxShapeNameSelector,
  isShippedMultiboxSelector,
  shippedConfigureIndividualBoxShapeSelector,
  shippedShipperCurrencySymbolSelector,
} from '../../../../../../data/orders.js'
import TrackingStatus from '../../Fields/TrackingStatus.js'
import TrackingLink from '../../Fields/TrackingLink.js'
import ShipDate from '../../Fields/ShipDate.js'
import RequestedShippingMethod from './Fields/RequestedShippingMethod.js'
import LabelItem from './Fields/LabelItem.js'
import ListItem from './Fields/ListItem.js'
import ShipFrom from './Fields/ShipFrom.js'
import AssignedWarehouse from './Fields/AssignedWarehouse.js'
import PackageType from './Fields/PackageType.js'
import ShippedWeight from './Fields/ShippedWeight.js'
import ShippedDimensions from './Fields/ShippedDimensions.js'
import ShippedDeliveryConfirmation from './Fields/ShippedDeliveryConfirmation.js'
import PreconfiguredPackingList from './Fields/PreconfiguredPackingList.js'
import ShippedInsuranceItem from './Fields/ShippedInsuranceItem.js'
import ShipEstimatedOrDeliveredDate from './Fields/ShipEstimatedOrDeliveredDate.js'
import Payors from './Fields/Payors.js'

function ShippingLabel({
  orderNumber,
  order,
  isCancelled,
  isExpanded,
  requestedShippingMethod,
  parentView,
  showShippingLabelTab,
  deleteShippingLabel,
  shipperName,
  shippingMethod,
  boxShape,
  isMultibox,
  configureIndividualBoxShape,
  shipperCurrencySymbol,
}) {
  return (
    <div>
      <h4 className="list__title--order-data">Shipping Label Created</h4>
      <ul className="list--order-data list--no-style">
        <li className="list__item--order-data">
          <strong className="margin-right-3">Shipped:</strong>
          <ShipDate date={order.shipping_info.ship_date} />
        </li>
        <ShipFrom orderNumber={orderNumber} />
        <AssignedWarehouse orderNumber={orderNumber} />
        <li className="list__item--order-data">
          <strong className="margin-right-3">Tracking:</strong>
          <TrackingLink shippingInfo={order.shipping_info} />
        </li>
        <TrackingStatus shippingInfo={order.shipping_info} />
        <ShipEstimatedOrDeliveredDate orderNumber={orderNumber} />
        {shippingMethod && (
          <dd className="list__item--order-data list__item--shipping-info">
            <ul className="list--order-data list--no-style">
              <LabelItem label="Carrier">{shipperName}</LabelItem>
              <LabelItem label="Shipping Method">{shippingMethod}</LabelItem>
              <PackageType
                boxShape={boxShape}
                isMultibox={isMultibox}
                configureIndividualBoxShape={configureIndividualBoxShape}
              />
            </ul>
          </dd>
        )}
        <li className="list__item--order-data">
          <strong className="margin-right-3">Cost:</strong>
          <span>
            {isTBD(order.shipping_info.cost) ? (
              'TBD'
            ) : (
              <Currency
                value={order.shipping_info.cost}
                currencySymbol={shipperCurrencySymbol}
              />
            )}
          </span>
        </li>
        <ShippedInsuranceItem orderNumber={orderNumber} />
      </ul>
      {isExpanded && (
        <dd className="list__item--order-data list__item--shipping-info">
          <ul className="list--order-data list--no-style">
            <ShippedWeight orderNumber={orderNumber} />
            <ShippedDimensions orderNumber={orderNumber} />
            <RequestedShippingMethod method={requestedShippingMethod} />
            <ShippedDeliveryConfirmation order={order} />
            <PreconfiguredPackingList orderNumber={orderNumber} />
            <Payors
              orderNumber={orderNumber}
              shippingInfo={order.shipping_info}
            />
          </ul>
        </dd>
      )}
      {parentView !== 'PackShipPage' && (
        <ListItem className="margin-top-5">
          <ButtonLink onClick={showShippingLabelTab} className="divider--right">
            View shipping label
          </ButtonLink>
          {!isCancelled && (
            <ButtonLink onClick={deleteShippingLabel}>Delete</ButtonLink>
          )}
        </ListItem>
      )}
    </div>
  )
}

ShippingLabel.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  order: OrderShape.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  requestedShippingMethod: PropTypes.string,
  parentView: PropTypes.string,
  showShippingLabelTab: PropTypes.func.isRequired,
  deleteShippingLabel: PropTypes.func.isRequired,
  shipperName: PropTypes.string,
  shippingMethod: PropTypes.string,
  boxShape: PropTypes.string,
  isMultibox: PropTypes.bool.isRequired,
  configureIndividualBoxShape: PropTypes.bool.isRequired,
  shipperCurrencySymbol: PropTypes.string.isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    shipperName: shippedShipperNameSelector(state, {orderNumber}),
    shippingMethod: shippedShippingMethodSelector(state, {orderNumber}),
    boxShape: shippedBoxShapeNameSelector(state, {orderNumber}),
    isMultibox: isShippedMultiboxSelector(state, {orderNumber}),
    configureIndividualBoxShape: shippedConfigureIndividualBoxShapeSelector(
      state,
      {orderNumber},
    ),
    shipperCurrencySymbol: shippedShipperCurrencySymbolSelector(state, {
      orderNumber,
    }),
  }
}

export default connect(mapStateToProps)(ShippingLabel)
