import PropTypes from 'prop-types'

import {NEW_ID} from '../../../common/constants/index.js'

export default function ReturnOrderSpeedbumps({
  needsWarehouse,
  needsShipper,
  noValidShippers,
}) {
  if (!needsWarehouse && !needsShipper && !noValidShippers) {
    return null
  }

  return (
    <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
      <p className="align-center fs-01 lh-lg margin-top-5 margin-bottom-0">
        <strong>
          Return labels can not be created until the following has been
          completed:
        </strong>
      </p>
      {needsWarehouse ? (
        <div className="alert alert--lg alert--warning-lt align-center margin-top-20 speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md"
                href="#/settings/warehouse"
                title="Configure your return to address"
              >
                Add a Return To Address
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline"
                href="#/settings/warehouse"
                title="Configure your return to address"
              >
                Plug in a return to or warehouse location to calculate shipping
                rates for your labels &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : needsShipper ? (
        <div className="alert alert--lg alert--warning-lt margin-top-20 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure at least one carrier account"
              >
                Add a Carrier Account
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure at least one carrier account"
              >
                Connect a carrier/shipper account from USPS, UPS, FedEx or
                Canada Post &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : noValidShippers ? (
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure a different carrier account"
              >
                Add a Compatible Carrier Account
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline lh-initial"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure a different carrier account"
              >
                Connect a different carrier/shipper account to create labels for
                this type of RMA &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : null}
    </div>
  )
}

ReturnOrderSpeedbumps.propTypes = {
  needsWarehouse: PropTypes.bool,
  needsShipper: PropTypes.bool,
  noValidShippers: PropTypes.bool,
}
