import PropTypes from 'prop-types'
import {useMemo} from 'react'

import {LabelInfoIDShape} from '../../../../common/PropTypes.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import WeightInput from '../../../../common/components/Form/WeightInput.js'
import DimensionsInput from '../../../../common/components/Form/DimensionsInput.js'
import {
  updateParcel,
  updateWeight,
  removeParcel,
  labelConfigSelector,
  configureIndividualBoxShapeSelector,
  boxShapeSelector,
  parcelSelector,
  boxShapeOptionsSelector,
  dimensionsAreRequired,
} from '../../../../data/labelInfos/index.js'
import ShipperOptions from '../../../../data/shipperOptions.js'
import {formatMetaClassName} from '../../../LabelConfig/Fields/useLabelProperty.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../../LabelConfig/LabelConfigContext.js'

export default function Row({
  labelInfoID,
  shipperType,
  packagesIndex,
  onChange,
}) {
  const {isLabelEnableMode} = useLabelConfigContext()
  const parcel = useSelector((state) =>
    parcelSelector(state, {labelInfoID, packagesIndex}),
  )
  const config = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const configureIndividualBoxShape = useSelector((state) =>
    configureIndividualBoxShapeSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const boxShape = useSelector((state) =>
    boxShapeSelector(state, {labelInfoID, shipperType, packagesIndex}),
  )
  const boxShapeOptions = useSelector((state) =>
    boxShapeOptionsSelector(state, {labelInfoID, shipperType}),
  )
  const showDimensions = useMemo(
    () =>
      isLabelEnableMode ||
      dimensionsAreRequired([shipperType], {
        [shipperType]: [boxShape],
      }),
    [isLabelEnableMode, shipperType, boxShape],
  )

  return (
    <tr>
      <td className="table__td v-align-middle divider--right">
        <strong className="fs-01 margin-left-5">{packagesIndex + 1}</strong>
      </td>
      {configureIndividualBoxShape && (
        <td className="table__td">
          <select
            className="select w-7rem margin-bottom-0"
            value={boxShape}
            onChange={(event) => {
              updateParcel(labelInfoID, packagesIndex, {
                [`${shipperType}__box_shape`]: event.target.value,
              })

              onChange()
            }}
          >
            {boxShapeOptions.map(({display, value}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </td>
      )}
      <td className="table__td">
        <WeightInput
          idLb="labelconfigform-weight-lb"
          weightOz={parcel.weight}
          setWeightOz={(weight) =>
            updateWeight(labelInfoID, packagesIndex, weight)
          }
          metaClassName={formatMetaClassName('weight')}
          allowZero={false}
          showScaleControl
        />
      </td>
      <td className="table__td">
        {showDimensions ? (
          <DimensionsInput
            length={parcel.length}
            width={parcel.width}
            height={parcel.height}
            setLength={(length) => {
              updateParcel(labelInfoID, packagesIndex, {length})

              onChange()
            }}
            setWidth={(width) => {
              updateParcel(labelInfoID, packagesIndex, {width})

              onChange()
            }}
            setHeight={(height) => {
              updateParcel(labelInfoID, packagesIndex, {height})

              onChange()
            }}
            errorMessage={''}
            hasLengthError={false}
            hasWidthError={false}
            hasHeightError={false}
            warningMessage={''}
          />
        ) : (
          'N/A'
        )}
      </td>
      <td className="table__td">
        <div className="flex">
          <NumberInput
            id="dry-ice-weight"
            value={parcel.dry_ice_weight}
            onChange={(dryIceWeight) => {
              updateParcel(labelInfoID, packagesIndex, {
                dry_ice_weight: dryIceWeight,
              })

              onChange()
            }}
          />
          <span className="input__unit">oz</span>
        </div>
        {shipperType === UPS && parcel.dry_ice_weight > 0 && (
          <div className="divider--top">
            <select
              className="select w-100"
              value={parcel.dry_ice_regulation_set}
              onChange={(event) => {
                updateParcel(labelInfoID, packagesIndex, {
                  dry_ice_regulation_set: event.target.value,
                })

                onChange()
              }}
            >
              {ShipperOptions.ups.dry_ice_regulation_set.map(
                ({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ),
              )}
            </select>
          </div>
        )}
      </td>
      <td className="table__td">
        <div className="flex">
          <span className="input__unit fs-n1 margin-left-0">$</span>
          <NumberInput
            id="declared_value_id"
            value={parcel[`${shipperType}__declared_value`] || ''}
            onChange={(declaredValue) => {
              updateParcel(labelInfoID, packagesIndex, {
                [`${shipperType}__declared_value`]: declaredValue,
              })

              onChange()
            }}
          />
        </div>
      </td>
      {shipperType === UPS && (
        <td className="table__td">
          <div>
            <label
              className="unbold margin-bottom-0"
              htmlFor={`additional_handling_${packagesIndex}`}
            >
              <input
                type="checkbox"
                className="margin-right-5 margin-bottom-0"
                id={`additional_handling_${packagesIndex}`}
                checked={parcel.additional_handling || false}
                onChange={(event) => {
                  updateParcel(labelInfoID, packagesIndex, {
                    additional_handling: event.target.checked,
                  })

                  onChange()
                }}
              />
              <span className="fs-n1">Add’l Handling</span>
            </label>
          </div>
        </td>
      )}
      <td className="table__td">
        <button
          className="btn btn--link no-underline"
          type="button"
          title="Remove line from order"
          onClick={() => removeParcel(labelInfoID, packagesIndex)}
          disabled={config.packages.length < 2}
        >
          <span className="i-trash fs-01" aria-hidden="true" />
        </button>
      </td>
    </tr>
  )
}

Row.propTypes = {
  packagesIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  labelInfoID: LabelInfoIDShape.isRequired,
  shipperType: PropTypes.string.isRequired,
}
