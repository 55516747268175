import EditMockAddressDataModal from './EditMockAddressDataModal.js'
import EditMockAddressIDsModal from './EditMockAddressIDsModal.js'
import EditMockBarcodeTextsModal from './EditMockBarcodeTextsModal.js'
import EditMockBarcodeValuesModal from './EditMockBarcodeValuesModal.js'
import EditMockMOsModal from './EditMockMOsModal.js'
import EditMockOrdersModal from './EditMockOrdersModal.js'
import EditMockProfilesModal from './EditMockProfilesModal.js'
import EditMockRMAsModal from './EditMockRMAsModal.js'

export default function Modals() {
  return (
    <>
      <EditMockAddressDataModal />
      <EditMockAddressIDsModal />
      <EditMockBarcodeTextsModal />
      <EditMockBarcodeValuesModal />
      <EditMockMOsModal />
      <EditMockOrdersModal />
      <EditMockProfilesModal />
      <EditMockRMAsModal />
    </>
  )
}
