import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase.js'

import {setCurrent} from '../../redux/actions/ui/dataImport/index.js'
import {
  usesInventorySelector,
  hasUseMOsFeatureSelector,
  useKittingSelector,
  useManualTrackingSelector,
  usePurchaseOrdersSelector,
  useDropshippingSelector,
} from '../../data/company.js'
import {hasProductUpdateInventoryPermissionSelector} from '../../data/me.js'

import AddressStrategy from './strategies/AddressStrategy/index.js'
import BatchStrategy from './strategies/BatchStrategy/index.js'
import BOMStrategy from './strategies/BOMStrategy/index.js'
import SupplierSKUStrategy from './strategies/SupplierSKUStrategy/index.js'
import SupplierStrategy from './strategies/SupplierStrategy/index.js'
import ProductArchiveStrategy from './strategies/ProductArchiveStrategy/index.js'
import ProductUnarchiveStrategy from './strategies/ProductUnarchiveStrategy/index.js'
import ProductCartBridgeStrategy from './strategies/ProductCartBridgeStrategy/index.js'
import ProductCreateStrategy from './strategies/ProductCreateStrategy/index.js'
import ProductImageStrategy from './strategies/ProductImageStrategy/index.js'
import ProductUpdateStrategy from './strategies/ProductUpdateStrategy/index.js'
import ProductFulfillmentStrategy from './strategies/ProductFulfillmentStrategy/index.js'
import ProductTagStrategy from './strategies/ProductTagStrategy/index.js'
import PurchaseOrderStrategy from './strategies/PurchaseOrderStrategy/index.js'
import RemoveSupplierSKUStrategy from './strategies/RemoveSupplierSKUStrategy/index.js'
import TrackingStrategy from './strategies/TrackingStrategy/index.js'
import KitStrategy from './strategies/KitStrategy/index.js'
import InventoryStrategy from './strategies/InventoryStrategy/index.js'
import OrderStrategy from './strategies/OrderStrategy/index.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {canUseDataImportSelector} from '../../redux/selectors/ui/dataImport/index.js'
import {useSuppliersSelector} from '../../data/suppliers.js'
import {canUseBatchesSelector} from '../../data/batches.js'

const STRATEGY_CLASSES = [
  ProductCreateStrategy,
  ProductUpdateStrategy,
  PurchaseOrderStrategy,
  OrderStrategy,
  TrackingStrategy,
  InventoryStrategy,
  SupplierSKUStrategy,
  RemoveSupplierSKUStrategy,
  KitStrategy,
  BatchStrategy,
  BOMStrategy,
  SupplierStrategy,
  AddressStrategy,
  ProductArchiveStrategy,
  ProductUnarchiveStrategy,
  ProductFulfillmentStrategy,
  ProductCartBridgeStrategy,
  ProductImageStrategy,
  ProductTagStrategy,
]

const strategyClassesSelector = createSelector(
  usesInventorySelector,
  hasProductUpdateInventoryPermissionSelector,
  hasUseMOsFeatureSelector,
  useKittingSelector,
  usePurchaseOrdersSelector,
  useDropshippingSelector,
  useManualTrackingSelector,
  useSuppliersSelector,
  canUseBatchesSelector,
  (
    usesInventory,
    hasProductUpdateInventoryPermission,
    hasUseMOsFeature,
    useKitting,
    usePurchaseOrders,
    useDropshipping,
    useManualTracking,
    useSuppliers,
    canUseBatches,
  ) => {
    let strategyClasses = STRATEGY_CLASSES

    if (!hasProductUpdateInventoryPermission) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== InventoryStrategy,
      )
    }

    if (!usesInventory) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) =>
          ![BOMStrategy, ProductFulfillmentStrategy].includes(strategyClass),
      )
    }

    if (!useKitting) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== KitStrategy,
      )
    }

    if (!usePurchaseOrders) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== PurchaseOrderStrategy,
      )
    }

    if (!useSuppliers) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) =>
          ![
            SupplierSKUStrategy,
            RemoveSupplierSKUStrategy,
            SupplierStrategy,
          ].includes(strategyClass),
      )
    }

    if (!hasUseMOsFeature) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== BOMStrategy,
      )
    }

    if (!useManualTracking) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== TrackingStrategy,
      )
    }

    if (!canUseBatches) {
      strategyClasses = strategyClasses.filter(
        (strategyClass) => strategyClass !== BatchStrategy,
      )
    }

    return strategyClasses
  },
)

function DataImport(props) {
  const CurrentStrategyClass = props.strategyClasses.find(
    (strategyClass) => strategyClass.title === props.currentStrategy,
  )

  useEffect(() => {
    if (!CurrentStrategyClass) {
      props.setCurrent(props.strategyClasses[0].title)
    }
  }, [CurrentStrategyClass, props.strategyClasses])

  useEffect(() => {
    document.title = 'Data Import'
  }, [])

  if (!props.canUseDataImport) {
    return <NoPermissionsPage />
  }

  if (!CurrentStrategyClass) {
    return null
  }

  return (
    <div
      className={classNames(
        'meta-csv-div-dataimport',
        `meta-csv-strategy-${kebabCase(props.currentStrategy)}`,
      )}
    >
      <CurrentStrategyClass strategyClasses={props.strategyClasses} />
    </div>
  )
}

DataImport.propTypes = {
  currentStrategy: PropTypes.string.isRequired,
  strategyClasses: PropTypes.array.isRequired,
  canUseDataImport: PropTypes.bool.isRequired,
  setCurrent: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    currentStrategy: state.ui.dataImport.current,
    strategyClasses: strategyClassesSelector(state),
    canUseDataImport: canUseDataImportSelector(state),
  }
}

const mapDispatchToProps = {
  setCurrent,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataImport)
