import {useSelector} from '../../store.js'
import SelectAllButton from '../../common/components/List/SelectAllButton.js'
import {setSelectedReferenceIDs} from './batchListActions.js'
import {
  referenceIDListSelector,
  indeterminateSelectedSelector,
  allSelectedSelector,
} from './batchListSelectors.js'

export default function BatchSelectAllButton() {
  const referenceIDList = useSelector(referenceIDListSelector)
  const indeterminate = useSelector(indeterminateSelectedSelector)
  const checked = useSelector(allSelectedSelector)

  return (
    <SelectAllButton
      checked={checked}
      indeterminate={indeterminate}
      onChange={(value) =>
        setSelectedReferenceIDs(value ? referenceIDList : [])
      }
    />
  )
}
