import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, select, put} from 'redux-saga/effects'

import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

const MODAL_FORM = 'ADD_RETURN_ORDER_COMMENT_MODAL_FORM'
export const ADD_RETURN_ORDER_COMMENT = 'ADD_RETURN_ORDER_COMMENT'

export function showAddReturnOrderCommentModal(referenceID) {
  return setForm(MODAL_FORM, {
    referenceID,
    comment: '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function addReturnOrderComment() {
  return {
    type: ADD_RETURN_ORDER_COMMENT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* addReturnOrderCommentWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, comment} = yield select(modalFormSelector)

    const {json} = yield call(
      apiverson.post,
      `/return_order/${encodeURIComponent(referenceID)}/comment`,
      {comment},
    )

    yield put(setReturnOrder(json))

    yield call(showMessageToast, `RMA comment was added`)

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error creating RMA comment: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function AddReturnOrderCommentModal({form, ...props}) {
  return (
    <ConfirmModal
      title="Add RMA Comment"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => props.addReturnOrderComment()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>{form.referenceID}</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Comment"
            rows="10"
            id="comment"
            value={form.comment}
            onChange={(value) => props.updateModalForm({comment: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

AddReturnOrderCommentModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  addReturnOrderComment: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  addReturnOrderComment,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(AddReturnOrderCommentModal),
  modalFormSelector,
)
