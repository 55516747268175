import {delay, call, fork, put, take, select} from 'redux-saga/effects'

import api from '../../../common/api.js'
import {
  TASK_POLL_DELAY,
  NEW_TASK_CREATION_DELAY,
} from '../../../common/constants/Tasks.js'

import {handlePollingError} from '../../actions/ui/index.js'
import {getAllTasks} from '../../actions/data/tasks.js'
import {
  CHECK_RUNNING_TASKS,
  START_CHECKING_FOR_RUNNING_TASKS,
  setIsRunningTasks,
} from '../../actions/data/isRunningTasks.js'

import {
  isRunningTasksSelector,
  isRunningExportSelector,
} from '../../selectors/data/isRunningTasks.js'

export function* fetchIsRunningTasks() {
  try {
    const res = yield call(api.get, '/activity/counts/')
    yield put(setIsRunningTasks(res.json.running))
  } catch (err) {
    yield put(
      handlePollingError(`Error fetching running tasks: ${err.message}`, err),
    )
  }
}

function* pollRunningTasks() {
  while (true) {
    yield call(fetchIsRunningTasks)

    const isRunningTasks = yield select(isRunningTasksSelector)
    const isRunningExport = yield select(isRunningExportSelector)

    if (isRunningExport) {
      yield fork(getAllTasks)
    } else if (!isRunningTasks && !isRunningExport) {
      yield fork(getAllTasks)
      return
    }

    yield delay(TASK_POLL_DELAY)
  }
}

export function* checkForRunningTasksWorker() {
  yield take(START_CHECKING_FOR_RUNNING_TASKS)

  while (true) {
    try {
      yield take(CHECK_RUNNING_TASKS)

      // Indicate to the user that we're checking the running tasks
      yield put(setIsRunningTasks(true))

      // Wait for API to mark any newly created tasks as running
      yield delay(NEW_TASK_CREATION_DELAY)

      yield pollRunningTasks()
    } catch (err) {
      yield put(
        handlePollingError(`Error updating running tasks: ${err.message}`, err),
      )
    }
  }
}

export default function* isRunningTasksSaga() {
  yield fork(checkForRunningTasksWorker)
}
