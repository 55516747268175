import PropTypes from 'prop-types'
import {useEffect} from 'react'
import format from 'date-fns/format'

import {
  setForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  updateForm,
  getState,
  dispatch,
} from '../../../../../store.js'
import ConfirmModal from '../../../../../common/components/Modal/ConfirmModal.js'
import {buildPath} from '../../../../../common/querystring.js'
import {CALL_UP_URI_COMPONENT} from '../../../../CallUpPage/index.js'
import {usePostMessage} from '../../../../../common/components/PostMessage.js'
import {UPS_AUTH_FORM} from './UPSAuthForm.js'
import {showEditAddressModal} from '../../../../Modals/EditAddressModal/index.js'
import {companySelector} from '../../../../../data/company.js'
import formatTime from '../../../../../common/formatTime.js'
import verde from '../../../../../common/verde.js'
import {setShipper, shipperSelector} from '../../../../../data/shippers.js'
import {goToShipperSettings} from '../../../../../redux/actions/ui/settings/shippers/index.js'
import {showMessageToast} from '../../../../Header/Toast/index.js'
import delay from '../../../../../common/delay.js'
import {showUPSRegistrationCompleteModal} from './UPSRegistrationCompleteModal.js'
import {redirect} from '../../../../../common/location.js'
import {
  clearSticky,
  getSticky,
  setSticky,
} from '../../../../../common/stickyStore.js'
import {showUpgradeModal} from '../../../../Modals/UpgradeModal.js'

const MODAL_FORM = 'UPS_REGISTRATION_MODAL'

export function showUPSRegistrationModal(shipperID) {
  const {registerContext} = getSticky('ups_auth_params') || {}
  clearSticky('ups_auth_params')

  const shipper = shipperSelector(getState(), {shipperID})
  const shipperNumber =
    (shipper && shipper.vendor_config.shipper_number) || null

  setForm(MODAL_FORM, {
    shipperID,
    shipperNumber,
    existingAccount: false,
    preventSave: false,
    isSaving: false,
    iframeIsLoading: false,
    serverError: null,
    registerContext: null,
    loadContext: {id: shipperID, ...registerContext},
  })
}

export function updateUPSRegistrationModal(updates, meta) {
  updateForm(MODAL_FORM, updates, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function registerParamsSelector(state) {
  const {
    registerContext: {
      id,
      username,
      password,
      address,
      existingAccount,
      shipperNumber,
      hasReceivedInvoice,
      invoiceNumber,
      invoiceDate,
      invoiceAmount,
      controlID,
      pickupInfoCode,
      pickupInfoLocation,
      pickupInfoStartDate,
      pickupInfoEarliestTime,
      pickupInfoPreferredTime,
      pickupInfoLatestTime,
      pickupInfoDayOfWeek,
      pickupInfoSameAsBilling,
      pickupInfoAddress,
      threatMetrixID,
      isBusinessAccount,
      createUPSProfile,
    },
  } = modalFormSelector(state)
  const company = companySelector(state)

  return {
    companyName: address.company,
    ...(createUPSProfile
      ? {
          username,
          password,
        }
      : undefined),
    address: {
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
    },
    contact: {
      name: `${address.given_name} ${address.family_name}`.trim(),
      givenName: address.given_name,
      familyName: address.family_name,
      title: address.title,
      email: address.email,
      phone: address.phone,
    },
    shipperNumber: existingAccount ? shipperNumber.trim() : undefined,
    invoice: hasReceivedInvoice
      ? {
          number: invoiceNumber,
          date: format(invoiceDate, 'yyyyMMdd'),
          currencyCode: company.shipper_currency,
          amount: invoiceAmount,
          controlID: controlID || undefined,
        }
      : undefined,
    isBusinessAccount,
    pickupInfo:
      !existingAccount || (id && !shipperNumber)
        ? {
            code: pickupInfoCode,
            ...(pickupInfoCode !== '08'
              ? {
                  location: pickupInfoLocation,
                  earliestTime: formatTime(pickupInfoEarliestTime, {
                    formatStr: 'HHmmss',
                  }),
                  preferredTime: formatTime(pickupInfoPreferredTime, {
                    formatStr: 'HHmmss',
                  }),
                  latestTime: formatTime(pickupInfoLatestTime, {
                    formatStr: 'HHmmss',
                  }),
                  startDate: format(pickupInfoStartDate, 'yyyyMMdd'),
                  dayOfWeek:
                    pickupInfoCode === '99'
                      ? Object.entries(pickupInfoDayOfWeek)
                          .filter(([_, value]) => value)
                          .map(([key]) => `0${key}`)
                      : undefined,
                }
              : undefined),
            ...((address) => ({
              companyName: address.company,
              address: {
                street1: address.street1,
                street2: address.street2,
                city: address.city,
                state: address.state,
                zip: address.zip,
                country: address.country,
              },
              contact: {
                name: `${address.given_name} ${address.family_name}`.trim(),
                email: address.email,
                phone: address.phone,
                title: address.title,
              },
            }))(pickupInfoSameAsBilling ? address : pickupInfoAddress),
          }
        : undefined,
    threatMetrixID: threatMetrixID || undefined,
  }
}

export function onMessage(event) {
  if (!event.data || !event.data.func) {
    return
  }

  const {func, args = []} = event.data

  if (func === 'editAddress') {
    editAddress(...args)
  } else if (func === 'updateUPSRegistrationModal') {
    updateUPSRegistrationModal(...args)
  } else if (func === 'onCallUpLoaded') {
    onCallUpLoaded(...args)
  } else if (func === 'receiveRegisterContext') {
    receiveRegisterContext(...args)
  } else if (func === 'showUpgradeModal') {
    showUpgradeModal(...args)
  }
}

function editAddress(form) {
  showEditAddressModal({
    address: form.address,
    willValidate: !!form.address.validation,
    hasAddressShortcuts: true,
    onSave: (address) => {
      window.postMessage({
        func: 'updateUPSAuthForm',
        args: [
          {
            address,
            pickupInfoAddress: form.pickupInfoSameAsBilling
              ? address
              : form.pickupInfoAddress,
          },
        ],
      })
    },
    markAllChanged: true,
    required: [
      'title',
      'given_name',
      'family_name',
      'company',
      'street1',
      'city',
      'state',
      'zip',
      'country',
      'phone',
    ],
    include: ['title', 'given_name', 'family_name'],
  })
}

function onCallUpLoaded() {
  const {loadContext} = modalFormSelector(getState())

  window.postMessage({
    func: 'setupUPSAuthForm',
    args: [loadContext],
  })

  updateUPSRegistrationModal({iframeIsLoading: false})
}

function requestContextForRegister() {
  window.postMessage({func: 'sendContextForRegister'})
}

function receiveRegisterContext(registerContext) {
  updateUPSRegistrationModal({registerContext})

  upsRegister()
}

function handleAddressError(err) {
  const {registerContext} = modalFormSelector(getState())

  if (
    !err.response ||
    !err.response.json ||
    (!err.response.json.billingAddressCandidates &&
      !err.response.json.pickupAddressCandidates)
  ) {
    return {registerContext, serverError: ''}
  }

  const billingAddressCandidates = err.response.json.billingAddressCandidates
  const pickupAddressCandidates = err.response.json.pickupAddressCandidates

  const {address, pickupInfoSameAsBilling, pickupInfoAddress} = registerContext

  const addressType =
    billingAddressCandidates || pickupInfoSameAsBilling ? 'billing' : 'pickup'

  const addressCandidates = billingAddressCandidates || pickupAddressCandidates

  const validation = {
    status: 'validated',
    additional_text: null,
    suggested: addressCandidates.map((candidate) => ({
      zip: candidate.PostalCode,
      city: candidate.City,
      state: candidate.State,
      country_code: candidate.CountryCode,
    })),
  }

  // we are just patching the context, we don't need to signal state with an update
  if (billingAddressCandidates || pickupInfoSameAsBilling) {
    registerContext.address = {...address, validation}
  }

  // we are just patching the context, we don't need to signal state with an update
  if (pickupAddressCandidates || pickupInfoSameAsBilling) {
    registerContext.pickupInfoAddress = {...pickupInfoAddress, validation}
  }

  return {
    registerContext,
    serverError:
      addressType === 'billing'
        ? 'Invalid Billing Address'
        : 'Invalid Pickup Address',
  }
}

export async function upsRegister() {
  try {
    const {shipperID, registerContext} = modalFormSelector(getState())
    const params = registerParamsSelector(getState())
    const shipper = shipperSelector(getState(), {shipperID})

    updateUPSRegistrationModal({
      serverError: null,
      isSaving: true,
    })

    const task =
      !shipper || !shipper.vendor_config.access_token ? 'auth' : 'account'

    const {json} =
      task === 'account'
        ? await verde.post('/ups/account', {
            shipper_id: shipperID,
            params,
          })
        : {json: {}}

    if (json.id) {
      setShipper(json)

      dispatch(goToShipperSettings())

      updateUPSRegistrationModal({
        isSaving: false,
      })

      if (params.shipperNumber) {
        showMessageToast('UPS account registered with Ordoro')
      } else {
        await delay(300)

        showUPSRegistrationCompleteModal()
      }

      closeModal()
    } else {
      // store params for resending after auth is finished
      setSticky('ups_auth_params', {registerContext})

      redirect(null, ['verde', 'ups', 'start'], {
        uses_discounted_rates: !params.shipperNumber,
        shipper_number: params.shipperNumber,
      })
    }
  } catch (err) {
    const {registerContext, serverError} = handleAddressError(err)

    updateUPSRegistrationModal({
      serverError: serverError || err.error_message || err.message,
      isSaving: false,
      registerContext: null,
      loadContext: registerContext,
    })
  }
}

function UPSRegistrationModal({form}) {
  usePostMessage(onMessage)

  const verb =
    form.shipperID && !form.shipperNumber
      ? 'Finalize'
      : form.shipperID
        ? 'Reauthorize'
        : form.existingAccount
          ? 'Authorize'
          : 'Create'

  useEffect(() => {
    if (!form.isSaving) {
      updateUPSRegistrationModal({iframeIsLoading: true})
    }
  }, [form.isSaving])

  return (
    <ConfirmModal
      title={`${verb} a UPS Account`}
      modalSize="lg"
      onConfirm={() => requestContextForRegister()}
      onCancel={() => closeModal()}
      confirmText={verb}
      cancelText="Close"
      isSaving={form.isSaving}
      isDisabled={form.preventSave}
      error={form.serverError}
    >
      {form.isSaving ? (
        <div className="loading align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle margin-right-10" />
          <em className="fs-02 lh-md v-align-middle op-50">
            <strong>Sending data to UPS...</strong>
          </em>
        </div>
      ) : (
        <>
          {form.iframeIsLoading && (
            <div className="loading align-center margin-top-30 margin-bottom-30">
              <span className="list-processing animate-spin v-align-middle margin-right-10" />
              <em className="fs-02 lh-md v-align-middle op-50">
                <strong>Loading...</strong>
              </em>
            </div>
          )}
          <iframe
            title="UPS Registration"
            src={
              '/#' +
              buildPath([CALL_UP_URI_COMPONENT], {
                context: JSON.stringify({component: UPS_AUTH_FORM}),
              })
            }
            style={{border: 0, minHeight: '40rem', width: '100%'}}
          />
        </>
      )}
    </ConfirmModal>
  )
}

UPSRegistrationModal.propTypes = {
  form: PropTypes.shape({
    shipperID: PropTypes.number,
    shipperNumber: PropTypes.string,
    existingAccount: PropTypes.bool.isRequired,
    preventSave: PropTypes.bool,
    registerContext: PropTypes.object,
    isSaving: PropTypes.bool.isRequired,
    iframeIsLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(UPSRegistrationModal, modalFormSelector)
