import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {setZuckCompanyID} from '../../redux/actions/ui/index.js'
import {isSuperuserSelector, userCompanyIDSelector} from '../../data/me.js'
import {
  planSelector,
  deactivatedDateSelector,
  companyIDSelector,
} from '../../data/company.js'
import {zuckCompanyIDSelector} from '../../redux/selectors/ui/index.js'

import FormattedDate from '../../common/components/FormattedDate.js'

class ZuckBanner extends Component {
  state = {
    isEditing: false,
    inputValue: '',
  }

  componentDidMount() {
    this.manageZuckClass()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSuperuser !== this.props.isSuperuser) {
      this.manageZuckClass()
    }
  }

  manageZuckClass() {
    const body = document.querySelector('body')

    if (this.props.isSuperuser) {
      // Need to add this class to allow for styling in Iverson order list.
      body.classList.add('zuck')
    } else {
      body.classList.remove('zuck')
    }
  }

  save() {
    const {userCompanyID} = this.props
    const inputValue = Number(this.state.inputValue)

    if (!inputValue || inputValue === userCompanyID) {
      this.props.setZuckCompanyID(null)
    } else {
      this.props.setZuckCompanyID(inputValue)
    }

    this.setState({isEditing: false})
  }

  render() {
    const {inputValue, isEditing} = this.state
    const {isSuperuser, plan, deactivatedDate, companyID, zuckCompanyID} =
      this.props

    if (!isSuperuser) {
      return null
    }

    return (
      <div className="full-width zuck-mode">
        <div className="row">
          <dl className="margin-bottom-0 margin-left-0 centered-text">
            <dt>
              {isEditing && (
                <span>
                  {'# '}
                  <input
                    ref={(input) => {
                      this.inputRef = input
                    }}
                    type="text"
                    className="input--md input--inline margin-bottom-0"
                    id="zuck"
                    value={inputValue}
                    onChange={(event) => {
                      this.setState({inputValue: event.target.value})
                    }}
                    onBlur={() => this.save()}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.save()
                      }
                    }}
                  />
                </span>
              )}
              {!isEditing && (
                <button
                  className="btn btn--link btn--zuck no-underline"
                  onClick={() => {
                    this.setState(
                      {
                        isEditing: true,
                        inputValue: zuckCompanyID || '',
                      },
                      () => {
                        this.inputRef.focus()
                        this.inputRef.select()
                      },
                    )
                  }}
                >
                  {`#${zuckCompanyID || companyID}`}
                </button>
              )}
              <span className="op-75">
                {' – '}
                <span className="make-uppercase">{plan}</span>
              </span>
              {deactivatedDate && (
                <span className="op-75 make-normal">
                  {' ('}Deactivated{' '}
                  <FormattedDate value={deactivatedDate} format="llll" />)
                </span>
              )}
            </dt>
          </dl>
        </div>
      </div>
    )
  }
}

ZuckBanner.propTypes = {
  isSuperuser: PropTypes.bool.isRequired,
  plan: PropTypes.string.isRequired,
  deactivatedDate: PropTypes.string,
  companyID: PropTypes.number.isRequired,
  zuckCompanyID: PropTypes.number,
  userCompanyID: PropTypes.number.isRequired,
  setZuckCompanyID: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  companyID: companyIDSelector(state),
  isSuperuser: isSuperuserSelector(state),
  plan: planSelector(state),
  deactivatedDate: deactivatedDateSelector(state),
  zuckCompanyID: zuckCompanyIDSelector(state),
  userCompanyID: userCompanyIDSelector(state),
})

const mapDispatchToProps = {
  setZuckCompanyID,
}

export default connect(mapStateToProps, mapDispatchToProps)(ZuckBanner)
