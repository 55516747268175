import PropTypes from 'prop-types'

import Pane from './Pane.js'

export default function TablePane({
  isLoading,
  title,
  hasFilters,
  children,
  canExport,
  onExport,
}) {
  return (
    <Pane
      title={title}
      isLoading={isLoading}
      canExport={canExport}
      onExport={onExport}
    >
      {!isLoading && (
        <table className="table--analytics">
          {children || (
            <tbody>
              <tr>
                <td>
                  <dl className="list list--horiz-bar-graph centered-text hide-for-loading">
                    <dt className="list__item margin-bottom-0 make-medium-dark-grey">
                      <strong>No data to display for this table</strong>
                    </dt>
                    {hasFilters && (
                      <dd className="list__item list__item--sm make-medium-grey">
                        Adjust the filters or date range and try again
                      </dd>
                    )}
                  </dl>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </Pane>
  )
}

TablePane.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  hasFilters: PropTypes.bool,
  children: PropTypes.node,
  canExport: PropTypes.bool,
  onExport: PropTypes.func,
}
