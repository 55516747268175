import PropTypes from 'prop-types'

export default function CustomerNotePopOver(props) {
  const {notesFromCustomer} = props

  return (
    <div className="wrap--pop-over">
      <div
        className="icon--pop-over op-50 i-notepad-01 fs-00"
        aria-hidden="true"
      />
      <dl className="pop-over pop-over--below">
        <dt className="pop-over__title">Customer Notes</dt>
        <dd className="pop-over__body">
          <p className="fs-n1 lh-md margin-bottom-0">{notesFromCustomer}</p>
        </dd>
      </dl>
    </div>
  )
}

CustomerNotePopOver.propTypes = {
  notesFromCustomer: PropTypes.string.isRequired,
}
