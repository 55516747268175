import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  usesInventorySelector,
  hasUseReturnOrdersFeatureSelector,
  hasUseMOsFeatureSelector,
  useAnalyticsSelector,
} from '../../../data/company.js'
import {canUseReportsPageSelector} from '../../ReportsPage/reportsPageSelectors.js'
import Checkbox from '../Common/Checkbox.js'

function permissionSectionsSelector(state) {
  return [
    {
      value: 'order',
      display: 'Orders',
      children: [
        {value: 'import_from_cart', display: 'Import from sales channels'},
        {
          value: 'force_verify_packing',
          display: 'Allow force verification in pick/pack modal',
        },
        {
          value: 'create_label_without_contents_verified_tag',
          display:
            'Allow shipping label creation without Contents Verified tag',
        },
        {value: 'manage_tag', display: 'Create and edit tags'},
        {
          value: 'manage_tag_on_order',
          display: 'Add and remove tags on orders',
        },
        {
          value: 'manage_revision',
          display: 'Accept and deny order revisions',
        },
        {
          value: 'financials',
          display: 'View and edit order financials',
        },
      ],
    },
    ...(hasUseReturnOrdersFeatureSelector(state)
      ? [
          {
            value: 'return_order',
            display: 'Return Orders',
            children: [
              {value: 'manage_tag', display: 'Create and edit tags'},
              {
                value: 'manage_tag_on_return_order',
                display: 'Add and remove tags on return orders',
              },
            ],
          },
        ]
      : []),
    {
      value: 'product',
      display: 'Products',
      children: [
        {value: 'import_from_cart', display: 'Import from sales channels'},
        {value: 'manage_tag', display: 'Create and edit tags'},
        {
          value: 'manage_tag_on_product',
          display: 'Add and remove tags on products',
        },
        ...(usesInventorySelector(state)
          ? [
              {
                value: 'writeback_inventory',
                display: 'Write inventory back to sales channels',
              },
              {
                value: 'update_inventory',
                display: 'Update inventory on products',
              },
            ]
          : []),
      ],
    },
    ...(usesInventorySelector(state)
      ? [
          {
            value: 'purchase_order',
            display: 'POs',
            children: [
              {value: 'manage_tag', display: 'Create and edit tags'},
              {
                value: 'manage_tag_on_purchase_order',
                display: 'Add and remove tags on purchase orders',
              },
            ],
          },
        ]
      : []),
    ...(hasUseMOsFeatureSelector(state)
      ? [
          {
            value: 'manufacturing_order',
            display: 'MFG Orders',
            children: [
              {value: 'manage_tag', display: 'Create and edit tags'},
              {
                value: 'manage_tag_on_manufacturing_order',
                display: 'Add and remove tags on MFG orders',
              },
            ],
          },
        ]
      : []),
    ...(useAnalyticsSelector(state)
      ? [
          {
            value: 'analytics',
            display: 'Analytics',
            children: [
              {value: 'financial', display: 'View financial stats'},
              {value: 'shipping', display: 'View shipping stats'},
              {value: 'inventory', display: 'View inventory stats'},
              {value: 'product', display: 'View product stats'},
              {value: 'customer', display: 'View customer stats'},
            ],
          },
        ]
      : []),
    ...(canUseReportsPageSelector(state)
      ? [{value: 'reports', display: 'Reports'}]
      : []),
    {value: 'settings', display: 'Settings'},
  ]
}

const PermissionShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ),
})

function Permission({
  permission: {value, display, children},
  permissions,
  ...props
}) {
  const defaultPerms = children
    ? children.reduce((prev, child) => {
        prev[child.value] = true
        return prev
      }, {})
    : true

  const perms =
    children && permissions[value] === true ? defaultPerms : permissions[value]

  return (
    <li className="form-list-item bigger padding-bottom-0 padding-left-0 margin-bottom-0 margin-top-15">
      <Checkbox
        id={`permission_${value}`}
        label={display}
        checked={permissions[value] !== false}
        onChange={(isChecked) => {
          const newPerms = children && isChecked ? defaultPerms : isChecked
          props.updatePermissions({[value]: newPerms})
        }}
      >
        {perms &&
          children &&
          children.map((child) => (
            <Checkbox
              key={child.value}
              id={`permission_${value}_${child.value}`}
              label={child.display}
              checked={perms[child.value] !== false}
              onChange={(checked) =>
                props.updatePermissions({
                  [value]: {...perms, [child.value]: checked},
                })
              }
            />
          ))}
      </Checkbox>
    </li>
  )
}

Permission.propTypes = {
  permission: PermissionShape.isRequired,
  permissions: PropTypes.object.isRequired,
  updatePermissions: PropTypes.func.isRequired,
}

function Permissions({permissionSections, ...props}) {
  return (
    <div className="medium-7 columns">
      <p className="divider--top fs-00 margin-bottom-10">
        <strong>Sections and features this user can access:</strong>
      </p>
      <div className="list--form">
        {permissionSections.map((permission) => (
          <div
            key={permission.value}
            className="list__item--form margin-bottom-10"
          >
            <Permission permission={permission} {...props} />
          </div>
        ))}
      </div>
    </div>
  )
}

Permissions.propTypes = {
  permissions: PropTypes.object.isRequired,
  updatePermissions: PropTypes.func.isRequired,
  permissionSections: PropTypes.arrayOf(PermissionShape).isRequired,
}

function mapStateToProps(state) {
  return {
    permissionSections: permissionSectionsSelector(state),
  }
}

export default connect(mapStateToProps)(Permissions)
