import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import omitBy from 'lodash/omitBy.js'
import mapValues from 'lodash/mapValues.js'
import uniq from 'lodash/uniq.js'
import isEqual from 'lodash/isEqual.js'

import {
  TAG_TYPE__PRODUCT,
  TAG_FILTER_OR,
  TAG_FILTER_OPTIONS,
} from '../../common/constants/Tags.js'
import {stringifyURL} from '../../common/querystring.js'
import {
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_QUANTITY_COMPARATOR,
  DEFAULT_EXPAND_MODE,
  IS_ACTIVE_FILTER,
  IS_LOW_INVENTORY_FILTER,
  IS_KIT_FILTER,
  IS_KIT_COMPONENT_FILTER,
  IS_BOM_FILTER,
  IS_BOM_COMPONENT_FILTER,
  IS_DROPSHIP_FILTER,
  NEEDS_SYNC_FILTER,
  IS_TO_BE_SHIPPED_FILTER,
  IS_OVERSOLD_FILTER,
  IS_REORDERED_FILTER,
  INVENTORY_SYNC_SUSPENDED,
  PRODUCT_SORT_OPTIONS,
  PRODUCT_SORT_OPTIONS_BASIC,
  COMPARATORS,
  PRODUCT_PLURAL_URI_COMPONENT,
  PRODUCT_SINGLE_URI_COMPONENT,
  PRODUCT_BOOLEAN_FILTERS,
  IS_PRODUCT_FBM,
  IS_PRODUCT_FBA,
  PRODUCT_FULFILLMENT_CHANNELS,
} from '../../common/constants/Products.js'
import {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  canUseProductsSelector,
  productsSelector,
  addProducts,
  ensureLinkedSKUsLoaded,
} from '../../data/products.js'
import {productTagsSortedByNameSelector} from '../../data/productTags.js'
import {
  ensureArray,
  ensureArrayOfNumbers,
  ensureArrayOrUndefined,
  ensureNumber,
  ensureTroolean,
  nullParamCast,
} from '../../common/ensure.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {navigate} from '../../common/location.js'
import {PRODUCT_LIST_PAGE} from '../../common/constants/Pages.js'
import api from '../../common/api.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {getState, removeForm, setForm, updateForm} from '../../store.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {DETAIL_MODE, LIST_MODE} from '../../common/constants/index.js'
import {usesInventorySelector} from '../../data/company.js'
import {showGlobalError} from '../GlobalErrorMessage.js'

const PRODUCT_LIST = 'PRODUCT_LIST'

export function setupProductListForm() {
  setForm(PRODUCT_LIST, {
    isLoading: false,
    count: 0,
    skuList: [],
    selectedSKUs: [],
    expandedSKUs: [],
    expandMode: DEFAULT_EXPAND_MODE,
    isWarehouseListExpanded: false,
  })
}

export function updateProductListForm(updates, meta) {
  updateForm(PRODUCT_LIST, updates, meta)
}

export function removeProductListForm() {
  removeForm(PRODUCT_LIST)
}

export function navigateToProductListPage(query = {}) {
  const defaultQuery = defaultHashParamsSelector(getState())

  const pathComponents = [PRODUCT_SINGLE_URI_COMPONENT]

  return navigate(pathComponents, {
    ...defaultQuery,
    ...query,
  })
}

export function navigateProductList(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (
    ![PRODUCT_PLURAL_URI_COMPONENT, PRODUCT_SINGLE_URI_COMPONENT].includes(
      pathComponents[0],
    )
  ) {
    return
  }

  pathComponents = [PRODUCT_SINGLE_URI_COMPONENT]

  query = handleOldFulfillmentShape(query)
  query = {...query, ...updates}

  return navigate(pathComponents, query)
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updateProductFilters(filterUpdates) {
  filterUpdates = Object.entries(filterUpdates).reduce((prev, [key, value]) => {
    if (PRODUCT_BOOLEAN_FILTERS.includes(key)) {
      prev[key] = value === true ? true : value === false ? false : undefined
    }

    return prev
  }, {})

  return {
    ...filterUpdates,
    ...resetOffset(),
  }
}

export function setProductFilters(filterUpdates) {
  return navigateProductList(updateProductFilters(filterUpdates))
}

export function updateProductSupplierIDs(supplierIDs) {
  return {
    supplierID: ensureArrayOrUndefined(supplierIDs),
    ...resetOffset(),
  }
}

export function setProductSupplierIDs(supplierIDs) {
  return navigateProductList(updateProductSupplierIDs(supplierIDs))
}

export function removeProductSupplierID(supplierID) {
  const {supplier} = productListQuerySelector(getState())

  return setProductSupplierIDs(supplier.filter((id) => id !== supplierID))
}

export function updateProductWarehouseIDs(warehouseIDs) {
  return {
    warehouseID: ensureArrayOrUndefined(warehouseIDs),
    ...resetOffset(),
  }
}

export function setProductWarehouseIDs(warehouseIDs) {
  return navigateProductList(updateProductWarehouseIDs(warehouseIDs))
}

export function removeProductWarehouseID(warehouseID) {
  const {warehouse_id} = productListQuerySelector(getState())

  return setProductWarehouseIDs(warehouse_id.filter((id) => id !== warehouseID))
}

export function updateFulfillmentChannelFilters(filterUpdates) {
  filterUpdates = Object.entries(filterUpdates).reduce((prev, [key, value]) => {
    if (PRODUCT_FULFILLMENT_CHANNELS.includes(key)) {
      prev[key] = value === true ? true : undefined
    }

    return prev
  }, {})

  return {
    ...filterUpdates,
    ...resetOffset(),
  }
}

export function setFulfillmentChannelFilters(filterUpdates) {
  return navigateProductList(updateFulfillmentChannelFilters(filterUpdates))
}

export function updateProductCartIDs(cartIDs) {
  return {
    cartID: ensureArrayOrUndefined(cartIDs),
    ...resetOffset(),
  }
}

export function setProductCartIDs(cartIDs) {
  return navigateProductList(updateProductCartIDs(cartIDs))
}

export function removeProductCartID(cartID) {
  const {sales_channel} = productListQuerySelector(getState())

  return setProductCartIDs(sales_channel.filter((id) => id !== cartID))
}

export function updateCategory(category) {
  return {
    category: (category || '').trim() || undefined,
    ...resetOffset(),
  }
}

export function setCategory(category) {
  return navigateProductList(updateCategory(category))
}

export function updateQuantity(quantity) {
  return {
    quantity: ensureNumber(quantity) || undefined,
    ...resetOffset(),
  }
}

export function setQuantity(quantity) {
  return navigateProductList(updateQuantity(quantity))
}

export function updateQuantityComparator(quantityComparator) {
  const comparator = COMPARATORS.find(({value}) => value === quantityComparator)

  return {
    quantity_comparator:
      comparator && comparator.value !== DEFAULT_QUANTITY_COMPARATOR
        ? comparator.value
        : undefined,
    ...resetOffset(),
  }
}

export function setQuantityComparator(quantityComparator) {
  return navigateProductList(updateQuantityComparator(quantityComparator))
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_PER_PAGE
  }

  if (limit === DEFAULT_PER_PAGE) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updateProductListForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigateProductList(updates)
}

export function updateSort(sort) {
  const usesInventory = usesInventorySelector(getState())

  const options = usesInventory
    ? PRODUCT_SORT_OPTIONS
    : PRODUCT_SORT_OPTIONS_BASIC

  if (!options.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  if (sort === DEFAULT_SORT) {
    sort = undefined
  }

  return {
    sort,
    ...resetOffset(),
  }
}

export function setSort(sort) {
  const updates = updateSort(sort)

  updateProductListForm(
    {
      sticky__sort: updates.sort,
    },
    {stickyProps: ['sticky__sort']},
  )

  return navigateProductList(updates)
}

export function updateSearchText(searchText) {
  return {
    searchText: (searchText || '').trim() || undefined,
    ...resetOffset(),
  }
}

export function setSearchText(searchText) {
  return navigateProductList(updateSearchText(searchText))
}

export function updateProductTagFilters(tags) {
  tags = tags || []

  if (!Array.isArray(tags)) {
    tags = [tags]
  }

  if (tags.length === 0) {
    tags = undefined
  } else {
    tags = uniq(tags)
  }

  return {
    tags,
    ...resetOffset(),
  }
}

export function setProductTagFilters(tags) {
  return navigateProductList(updateProductTagFilters(tags))
}

export function removeProductTagFilter(tagName) {
  const {tag} = productListQuerySelector(getState())

  return setProductTagFilters(tag.filter((name) => name !== tagName))
}

export function updateProductUntagged(untagged) {
  return {
    untagged: untagged ? true : undefined,
    ...resetOffset(),
  }
}

export function setProductUntagged(untagged) {
  return navigateProductList(updateProductUntagged(untagged))
}

export function updateProductTagFilterBy(tagFilterBy) {
  if (!TAG_FILTER_OPTIONS.find(({value}) => tagFilterBy === value)) {
    tagFilterBy = TAG_FILTER_OR
  }

  if (tagFilterBy === TAG_FILTER_OR) {
    tagFilterBy = undefined
  }

  return {
    tagFilterBy,
    ...resetOffset(),
  }
}

export function setProductTagFilterBy(tag_filter_by) {
  return navigateProductList(updateProductTagFilterBy(tag_filter_by))
}

export function updateProductExcludeTags(exclude_tags) {
  return {
    exclude_tags: uniq(ensureArrayOrUndefined(exclude_tags)),
    ...resetOffset(),
  }
}

export function setProductExcludeTags(exclude_tags) {
  return navigateProductList(updateProductExcludeTags(exclude_tags))
}

export function removeProductExcludeTag(tagName) {
  const {exclude_tags} = productListQuerySelector(getState())

  return setProductExcludeTags(exclude_tags.filter((name) => name !== tagName))
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigateProductList(updateOffset(offset))
}

function setQueryResults({count, limit, offset, product}) {
  updateProductListForm({
    skuList: product.map(({sku}) => sku),
    count,
    currentPage: offset / limit + 1,
    selectedSKUs: [],
    expandedSKUs: [],
  })
}

export function setExpandedSKUs(expandedSKUs) {
  updateProductListForm({
    expandedSKUs,
  })
}

export function toggleExpanded(sku) {
  const expandedSKUs = expandedSKUsSelector(getState())

  const skus = expandedSKUs.filter((p) => p !== sku)

  if (!expandedSKUs.includes(sku)) {
    skus.push(sku)
  }

  setExpandedSKUs(skus)
}

export function setExpandMode(expandMode) {
  updateProductListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

export function setIsWarehouseListExpanded(isWarehouseListExpanded) {
  updateProductListForm({isWarehouseListExpanded})
}

export function selectSKU(sku, isSelected, isShiftKey) {
  const selectedSKUs = selectedSKUsSelector(getState())

  if (isShiftKey) {
    const skuList = skuListSelector(getState())
    const indexOfNextToLastRowSelected = skuList.indexOf(
      selectedSKUs[selectedSKUs.length - 1],
    )
    const indexOfLastRowSelected = skuList.indexOf(sku)

    const min = Math.min(indexOfNextToLastRowSelected, indexOfLastRowSelected)
    const max = Math.max(indexOfNextToLastRowSelected, indexOfLastRowSelected)

    const toToggle = []
    for (let i = min; i <= max; i++) {
      if (skuList[i]) {
        toToggle.push(skuList[i])
      }
    }

    setSelectedSKUs([
      ...selectedSKUs.filter((s) => !toToggle.includes(s)),
      ...(isSelected ? toToggle : []),
    ])
  } else {
    const skus = selectedSKUs.filter((s) => s !== sku)

    if (isSelected) {
      skus.push(sku)
    }

    setSelectedSKUs(skus)
  }
}

export function setSelectedSKUs(selectedSKUs) {
  updateProductListForm({
    selectedSKUs,
  })
}

export async function refreshProductList() {
  try {
    const currentPage = currentPageSelector(getState())
    if (currentPage !== PRODUCT_LIST_PAGE) {
      return
    }

    const token = (refreshProductList.requestToken = {})

    updateProductListForm({isLoading: true})

    const params = productListQueryParamsSelector(getState())
    const {json} = await api.get('/product/', params)

    if (token !== refreshProductList.requestToken) {
      return
    }

    const products = json.product

    addProducts(products)

    setQueryResults(json)

    await ensureLinkedSKUsLoaded(products.map(({sku}) => sku))

    updateProductListForm({isLoading: false})
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error querying products.',
        details: err.message || err.error_message,
      },
      err,
    )

    updateProductListForm({isLoading: false})
  }
}

export function createAndApplyTagToProducts(skus) {
  showEditTagModal(TAG_TYPE__PRODUCT, null, {
    addTagToSKUs: skus,
  })
}

export function productListFormSelector(state) {
  return state.ui.forms[PRODUCT_LIST]
}

export const isLoadingSelector = createSelector(
  productListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const skuListSelector = createSelector(
  productListFormSelector,
  ({skuList} = {}) => skuList || [],
)

export const countSelector = createSelector(
  productListFormSelector,
  ({count} = {}) => count || 0,
)

export const selectedSKUsSelector = createSelector(
  productListFormSelector,
  ({selectedSKUs} = {}) => selectedSKUs || [],
)

export function isSelectedSelector(state, {sku}) {
  const selectedSKUs = selectedSKUsSelector(state)

  return selectedSKUs.includes(sku)
}

export const hasSelectedSKUsSelector = createSelector(
  selectedSKUsSelector,
  (selectedSKUs) => selectedSKUs.length > 0,
)

export const expandModeSelector = createSelector(
  productListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedSKUsSelector = createSelector(
  productListFormSelector,
  ({expandedSKUs} = {}) => expandedSKUs || [],
)

export function getIsProductRowExpanded(expandedSKUs, expandMode, sku) {
  if (expandMode === EXPAND_MODE_COLLAPSED) {
    return expandedSKUs.includes(sku)
  }
  if (expandMode === EXPAND_MODE_EXPANDED) {
    return !expandedSKUs.includes(sku)
  }
  return false
}

export function createIsProductRowExpandedSelector(sku) {
  return createSelector(
    expandedSKUsSelector,
    expandModeSelector,
    (expandedSKUs, expandMode) =>
      getIsProductRowExpanded(expandedSKUs, expandMode, sku),
  )
}

export const isExpandedSelector = createSelector(
  expandedSKUsSelector,
  expandModeSelector,
  (state, {sku}) => sku,
  (expandedSKUs, expandMode, sku) =>
    getIsProductRowExpanded(expandedSKUs, expandMode, sku),
)

export const isWarehouseListExpandedSelector = createSelector(
  productListFormSelector,
  ({isWarehouseListExpanded} = {}) => isWarehouseListExpanded || false,
)

function handleOldFulfillmentShape(query) {
  // we used to have fulfillment channel array
  // but really we just have fba/fbm booleans so just have them
  // that is what the api wants and we can handle that easier
  if (!query.fulfillmentChannel) {
    return query
  }

  const fulfillmentChannel = ensureArray(query.fulfillmentChannel)

  return {
    ...query,
    fba: fulfillmentChannel.includes('FULFILLMENT_CHANNEL_FBA')
      ? true
      : undefined,
    fbm: fulfillmentChannel.includes('FULFILLMENT_CHANNEL_FBM')
      ? true
      : undefined,
    fulfillmentChannel: undefined,
  }
}

export function productListQuerySelector(state) {
  const cache = productListQuerySelector.cache

  const location = locationSelector(state)

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  let {
    query,
    pathComponents: [resource, sku, panel],
  } = location

  sku = !!sku && resource === PRODUCT_SINGLE_URI_COMPONENT ? sku : null

  query = handleOldFulfillmentShape(query)

  const result = {
    mode:
      resource === PRODUCT_PLURAL_URI_COMPONENT ||
      (resource === PRODUCT_SINGLE_URI_COMPONENT && !sku)
        ? LIST_MODE
        : resource === PRODUCT_SINGLE_URI_COMPONENT
          ? DETAIL_MODE
          : null,
    sku,
    panel,
    ...productListQueryToProductQuery(query),
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}
productListQuerySelector.cache = {}

export function productListQueryToProductQuery(query) {
  return {
    search: query.searchText || '',
    category: query.category || '',
    active: ensureTroolean(query[IS_ACTIVE_FILTER]),
    kit: ensureTroolean(query[IS_KIT_FILTER]),
    kit_component: ensureTroolean(query[IS_KIT_COMPONENT_FILTER]),
    manufactured: ensureTroolean(query[IS_BOM_FILTER]),
    manufacturing_component: ensureTroolean(query[IS_BOM_COMPONENT_FILTER]),
    dropship: ensureTroolean(query[IS_DROPSHIP_FILTER]),
    needs_sync: ensureTroolean(query[NEEDS_SYNC_FILTER]),
    to_be_shipped: ensureTroolean(query[IS_TO_BE_SHIPPED_FILTER]),
    oversold: ensureTroolean(query[IS_OVERSOLD_FILTER]),
    reordered: ensureTroolean(query[IS_REORDERED_FILTER]),
    inventory_sync_suspended: ensureTroolean(query[INVENTORY_SYNC_SUSPENDED]),
    low_inventory: ensureTroolean(query[IS_LOW_INVENTORY_FILTER]),
    fba: ensureTroolean(query[IS_PRODUCT_FBA]),
    fbm: ensureTroolean(query[IS_PRODUCT_FBM]),
    supplier: ensureArrayOfNumbers(query.supplierID),
    warehouse_id: ensureArrayOfNumbers(query.warehouseID),
    sales_channel: ensureArrayOfNumbers(query.cartID),
    tag: ensureArray(query.tags),
    untagged: ensureTroolean(query.untagged) || false,
    tag_filter_by: query.tagFilterBy || TAG_FILTER_OR,
    exclude_tags: ensureArray(query.exclude_tags),
    quantity: ensureNumber(query.quantity),
    quantity_comparator:
      query.quantity_comparator || DEFAULT_QUANTITY_COMPARATOR,
    sort: query.sort || DEFAULT_SORT,
    limit: Number(query.limit) || DEFAULT_PER_PAGE,
    offset: Number(query.offset) || 0,
  }
}

export function cleanProductQuery(query) {
  return {
    search: (query.search && query.search.trim()) || undefined,
    category: (query.category && query.category.trim()) || undefined,
    active: nullParamCast(query.active),
    kit: nullParamCast(query.kit),
    kit_component: nullParamCast(query.kit_component),
    manufactured: nullParamCast(query.manufactured),
    manufacturing_component: nullParamCast(query.manufacturing_component),
    dropship: nullParamCast(query.dropship),
    needs_sync: nullParamCast(query.needs_sync),
    to_be_shipped: nullParamCast(query.to_be_shipped),
    oversold: nullParamCast(query.oversold),
    reordered: nullParamCast(query.reordered),
    inventory_sync_suspended: nullParamCast(query.inventory_sync_suspended),
    low_inventory: nullParamCast(query.low_inventory),
    fba: nullParamCast(query.fba),
    fbm: nullParamCast(query.fbm),
    supplier: ensureArrayOrUndefined(query.supplier),
    warehouse_id: ensureArrayOrUndefined(query.warehouse_id),
    sales_channel: ensureArrayOrUndefined(query.sales_channel),
    sort: query.sort,
    tag: ensureArrayOrUndefined(query.tag),
    untagged: query.untagged || undefined,
    tag_filter_by:
      query.tag_filter_by !== TAG_FILTER_OR ? query.tag_filter_by : undefined,
    exclude_tags: ensureArrayOrUndefined(query.exclude_tags),
    limit: query.limit,
    offset: query.offset,
  }
}

export function productListQueryParamsSelector(state) {
  const query = productListQuerySelector(state)

  return cleanProductQuery(query)
}

function hashBuilder(params, canUseProducts) {
  if (!canUseProducts) {
    return null
  }

  let hash = `#/${PRODUCT_SINGLE_URI_COMPONENT}`

  return stringifyURL(hash, params)
}

export const productListHashSelector = createSelector(
  productListQuerySelector,
  (state) => canUseProductsSelector(state),
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  productListFormSelector,
  ({sticky__limit, sticky__sort} = {}) => ({
    [IS_ACTIVE_FILTER]: true,
    limit: sticky__limit !== DEFAULT_PER_PAGE ? sticky__limit : undefined,
    sort: sticky__sort !== DEFAULT_SORT ? sticky__sort : undefined,
  }),
)

export const defaultProductListHashSelector = createSelector(
  defaultHashParamsSelector,
  (state) => canUseProductsSelector(state),
  hashBuilder,
)

export const allSelectedSelector = createSelector(
  skuListSelector,
  selectedSKUsSelector,
  (skuList, selected) =>
    skuList.length > 0 && skuList.length === selected.length,
)

export const firstSelectedSKUSelector = createSelector(
  selectedSKUsSelector,
  (selectedSKUs) => selectedSKUs[0],
)

export const isFilteredSelector = createSelector(
  productListQueryParamsSelector,
  (queryParams) =>
    !isEmpty(
      omitBy(
        queryParams,
        (value, key) =>
          ['sort', 'limit', 'offset'].includes(key) ||
          (key !== 'active' && value === undefined) ||
          (key === 'active' && !!value),
      ),
    ),
)

export const productTagQuantityMapSelector = createSelector(
  (state, {skus}) => skus,
  (state) => productsSelector(state),
  (state) => productTagsSortedByNameSelector(state),
  (skus, products, productTags) => {
    const productTagQuantityMap = productTags.reduce(
      (prev, tag) => ({
        ...prev,
        [tag.id]: 0,
      }),
      {},
    )

    skus.forEach((sku) => {
      const product = products[sku]

      if (!product) {
        return
      }

      product.tags.forEach((tag) => {
        productTagQuantityMap[tag.id] += 1
      })
    })

    return mapValues(productTagQuantityMap, (selectedQuantity) => {
      const diff = skus.length - selectedQuantity
      if (diff === 0) {
        return 'all'
      } else if (diff === skus.length) {
        return 'none'
      }

      return 'some'
    })
  },
)
