import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {SHIP_STATION} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.api_key)) {
    errors.api_key = 'API Key is required'
    errors.preventSave = true
  }

  if (!isPresent(form.api_secret)) {
    errors.api_secret = 'API Secret is required'
    errors.preventSave = true
  }

  if (!isPresent(form.order_tag_id)) {
    errors.order_tag_id = 'Order Tag ID is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: SHIP_STATION}),
      api_key: '',
      api_secret: '',
      order_tag_id: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    api_key: cart.vendor_config.api_key,
    api_secret: cart.vendor_config.api_secret,
    order_tag_id: String(cart.vendor_config.order_tag_id),
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param) {
    errors[serverError.param] = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    api_key: form.api_key,
    api_secret: form.api_secret,
    order_tag_id: form.order_tag_id,
  }
}

function ShipStation({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="api_key"
              label="API Key"
              required
              value={form.api_key || ''}
              errorMessage={form.hasChanged_api_key && errors.api_key}
              onChange={(event) =>
                updateForm(formName, {
                  api_key: event.target.value,
                  hasChanged_api_key: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="api_secret"
              label="API Secret"
              required
              value={form.api_secret || ''}
              errorMessage={form.hasChanged_api_secret && errors.api_secret}
              onChange={(event) =>
                updateForm(formName, {
                  api_secret: event.target.value,
                  hasChanged_api_secret: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="order_tag_id"
              label="Order Tag ID"
              required
              value={form.order_tag_id || ''}
              errorMessage={form.hasChanged_order_tag_id && errors.order_tag_id}
              onChange={(event) =>
                updateForm(formName, {
                  order_tag_id: event.target.value,
                  hasChanged_order_tag_id: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
    </VendorForm>
  )
}

ShipStation.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    api_key: PropTypes.string.isRequired,
    hasChanged_api_key: PropTypes.bool,
    api_secret: PropTypes.string.isRequired,
    hasChanged_api_secret: PropTypes.bool,
    order_tag_id: PropTypes.string.isRequired,
    hasChanged_order_tag_id: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(ShipStation, setUpCartForm)
