import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {progressSelector} from '../../redux/selectors/ui/dataImport/progress.js'

class Progress extends Component {
  render() {
    const className = classNames('list--progress clearfix', {
      'on-6': this.props.progress.isDone,
    })

    return (
      <dl className={className}>
        <dt className="list__title--progress">Import Progress</dt>
        <dd className="list__item--progress progress progress-bar">
          <div className="meter" style={{width: this.props.progress.width}} />
        </dd>
      </dl>
    )
  }
}

Progress.propTypes = {
  progress: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    progress: progressSelector(state),
  }
}

export default connect(mapStateToProps)(Progress)
