import PropTypes from 'prop-types'

import {
  ALLOCATE_ORDER,
  APPLY_TAGS,
  APPLY_PRESETS,
  ASSIGN_TO_WAREHOUSE,
  DROPSHIP_TO,
  MARK_AS_SHIPPED,
} from '../../../../common/constants/Rules.js'
import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'

import AllocateOrder from './AllocateOrder.js'
import ApplyTag from './ApplyTag.js'
import ApplyPreset from './ApplyPreset.js'
import AssignToWarehouse from './AssignToWarehouse.js'
import MarkAsShipped from './MarkAsShipped.js'
import DropshipTo from './DropshipTo.js'

export default function RuleAction(props) {
  const {
    action: {type},
  } = props

  if (type === APPLY_TAGS) {
    return <ApplyTag {...props} />
  }
  if (type === APPLY_PRESETS) {
    return <ApplyPreset {...props} />
  }
  if (type === ASSIGN_TO_WAREHOUSE) {
    return <AssignToWarehouse {...props} />
  }
  if (type === MARK_AS_SHIPPED) {
    return <MarkAsShipped {...props} />
  }
  if (type === ALLOCATE_ORDER) {
    return <AllocateOrder {...props} />
  }
  if (type === DROPSHIP_TO) {
    return <DropshipTo {...props} />
  }

  return null
}

RuleAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
}
