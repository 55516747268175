import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {
  setForm,
  updateForm,
  formsSelector,
  getState,
  removeFormValue,
} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import api from '../common/api.js'
import {NEW_RULE_ID} from '../common/constants/Rules.js'
import userflow from '../common/analytics/userflow.js'

export const RULES = 'RULES'

export function rulesSelector(state) {
  return formsSelector(state)[RULES] || rulesSelector.default
}
rulesSelector.default = {}

export function rulesHaveLoadedSelector(state) {
  return !!formsSelector(state)[RULES]
}

export function ruleSelector(state, {ruleID}) {
  return rulesSelector(state)[ruleID]
}

export const rulesSortedByIndexSelector = createSelector(
  rulesSelector,
  (rules) => sortBy(rules, 'index'),
)

export function setRules(rules) {
  setForm(RULES, keyBy(rules, 'id'))
}

export function setRule(rule) {
  updateForm(RULES, {[rule.id]: rule})
}

export function removeRule(ruleID) {
  removeFormValue(RULES, [ruleID])
}

export async function getRules() {
  try {
    const rules = await fetchAllAPI('/rule/', 'rule')

    setRules(rules)

    userflow.updateGroup({
      rule_count: rules.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting rules.',
        details: err.message || err.error_message,
      },
      `Error getting rules. ${err.error_message || err.message}`,
      err,
    )

    setRules([])
  }
}

export async function deleteRule(ruleID) {
  try {
    await api.delete(`/rule/${ruleID}/`)

    removeRule(ruleID)

    return true
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error deleting rule.',
        details: err.message || err.error_message,
      },
      err,
    )

    return false
  }
}

export async function saveRule(rule) {
  const ruleData = {
    active: rule.active,
    latch: rule.latch,
    condition: rule.condition,
    action: rule.action,
    internal_notes: rule.internal_notes,
  }

  const {json} =
    rule.id === NEW_RULE_ID
      ? await api.post('/rule/', ruleData)
      : await api.put(`/rule/${rule.id}/`, ruleData)

  setRule(json)

  return json
}

export async function reorderRules(newIndices) {
  try {
    const {
      json: {rule: rules},
    } = await api.put('/rule/reorder/', {new_indices: newIndices})

    for (var i = 0; i < rules.length; i++) {
      var updatedRule = rules[i]

      setRule(updatedRule)
    }
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error updating rules.',
        details: err.message || err.error_message,
      },
      err,
    )
  }

  return null
}

export async function toggleActive(ruleID) {
  try {
    const rule = ruleSelector(getState(), {ruleID})

    const toggled = !rule.active

    await saveRule({...rule, active: toggled})

    return toggled
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error toggling rule.',
      },
      err,
    )
  }
}
