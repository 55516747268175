import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  confirm,
} from '../../../redux/actions/ui/modals/confirmExcessiveFulfillmentLatencyModal.js'
import BaseConfirmModal from './BaseConfirmModal.js'

function ConfirmExcessiveFulfillmentLatencyModal(props) {
  return (
    <BaseConfirmModal
      title="Lag Time Exception Required"
      modalName={MODAL_NAME}
      onConfirm={props.confirm}
      onCancel={() => props.closeModal()}
      confirmText="Got it"
      cancelText=""
    >
      <p className="fs-01 margin-bottom-15">
        <strong>Don’t Get Penalized by Walmart!</strong>
      </p>
      <p className="margin-bottom-15">
        You need to{' '}
        <a
          href="https://marketplace.walmart.com/knowledgebase/articles/File_Download/Request-Lag-Time-Exceptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>request a lag time exception</strong>
        </a>{' '}
        with Walmart for any product with an extended fulfillment latency (2
        days or more).
      </p>
      <p className="margin-bottom-15">
        Ordoro will still set your fulfillment latency to whatever you desire,
        just know that you might get penalized if you do not request the
        exception!
      </p>
      <p className="margin-bottom-0">
        <a
          href="https://marketplace.walmart.com/knowledgebase/articles/File_Download/Request-Lag-Time-Exceptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Learn more</strong>
        </a>{' '}
        <span className="op-50">→</span>
      </p>
    </BaseConfirmModal>
  )
}

ConfirmExcessiveFulfillmentLatencyModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

ConfirmExcessiveFulfillmentLatencyModal.modalName = MODAL_NAME

const mapDispatchToProps = {
  confirm,
  closeModal,
}

export default connect(
  null,
  mapDispatchToProps,
)(ConfirmExcessiveFulfillmentLatencyModal)
