import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import {updateForm} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  shipperFormSelector,
  errorsOfChangedSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import TextInput from '../../Common/TextInput.js'

function Nickname({form: {id, name}, errors, ...props}) {
  return (
    <li className="form-list-item bigger">
      <TextInput
        label="Account Nickname"
        name="shipper_nickname"
        value={name}
        onChange={(event) => props.updateForm(id, {name: event.target.value})}
        errorMessage={errors.name}
        required
      />
    </li>
  )
}

Nickname.propTypes = {
  shipperID: PropTypes.number.isRequired,
  form: ShipperFormShape.isRequired,
  errors: ErrorsShape.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {shipperID}) {
  const form = shipperFormSelector(state, {shipperID})

  return {
    form,
    errors: errorsOfChangedSelector(state, {form}),
  }
}

const mapDispatchToProps = {
  updateForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nickname)
