import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setMO} from '../../../data/mos.js'

const MODAL_FORM = 'ADD_MO_COMMENT_MODAL_FORM'
export const ADD_MO_COMMENT = 'ADD_MO_COMMENT'

export function showAddMOCommentModal(referenceID) {
  setForm(MODAL_FORM, {
    referenceID,
    comment: '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function close() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function addMOComment() {
  try {
    updateModalForm({isSaving: true})

    const {referenceID, comment} = modalFormSelector(getState())

    const {json} = await apiverson.post(
      `/manufacturing_order/${encodeURIComponent(referenceID)}/comment`,
      {comment},
    )

    setMO(json)

    showMessageToast('MFG Order comment was added.')

    close()
  } catch (err) {
    updateModalForm({
      serverError: `Error creating MFG Order comment: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function AddMOCommentModal({form}) {
  return (
    <ConfirmModal
      title="Add MFG Order Comment"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => addMOComment()}
      onCancel={() => close()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>{form.referenceID}</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Comment"
            rows="10"
            id="comment"
            value={form.comment}
            onChange={(value) => updateModalForm({comment: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

AddMOCommentModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default formConnect(AddMOCommentModal, modalFormSelector)
