import PropTypes from 'prop-types'
import pick from 'lodash/pick.js'

import {
  REPORTS_BY_TYPE,
  REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
  REPORT_EXPORT_DELIVERY_STATUS_DETAIL,
} from '../../../common/constants/Reports.js'
import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import {shipperOptionsSelector} from '../../../data/shippers.js'
import MultiSelect from '../../../common/components/Form/MultiSelect.js'
import Radio from '../../../common/components/Radio.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'
import {PITNEY, UPS} from '../../../common/constants/ShipperNames.js'

export const NO_SHIPPER = 'No Shipper'

export function setupExportDeliveryStatusForm(params = {}) {
  let {reportID, shipperIDs} = params
  params = pick(params, [])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  shipperIDs = shipperIDs || reportParams.shippers || []

  return {
    shipperIDs,
    showCarriers: shipperIDs.length !== 0,
    ...params,
  }
}

export function exportDeliveryStatusErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportDeliveryStatusPayloadSelector(state, {formName}) {
  const {reportType, shipperIDs} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }
  if (shipperIDs.length > 0) {
    payload.params.shippers = shipperIDs
  }

  return payload
}

export default function ExportDeliveryStatusForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportDeliveryStatusErrorsSelector(state, {formName}),
  )
  const shipperOptions = useSelector((state) =>
    shipperOptionsSelector(state).filter(({entity}) =>
      [PITNEY, UPS].includes(entity.vendor),
    ),
  )

  return (
    <ul className="list">
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--form list__item--no-style">
        <label htmlFor="carrier_all">Carrier</label>
        <div className="flex margin-top-7">
          <div className="margin-right-20">
            <Radio
              mode="fancy"
              label="All"
              id="carrier_all_carriers"
              checked={!form.showCarriers}
              onChange={() =>
                updateForm(formName, {showCarriers: false, shipperIDs: []})
              }
            />
          </div>
          <div>
            <Radio
              mode="fancy"
              label="Specific Carrier Types"
              id="carrier_specific_carriers"
              checked={form.showCarriers}
              onChange={() =>
                updateForm(formName, {showCarriers: true, shipperIDs: []})
              }
            />
          </div>
        </div>
        {form.showCarriers && (
          <div className="margin-top-15 margin-bottom-20">
            <MultiSelect
              scope="export_delivery_status_carriers"
              options={shipperOptions}
              selected={form.shipperIDs}
              onChange={(shipperIDs) => updateForm(formName, {shipperIDs})}
              canDeselectAll={false}
              canSelectAll={false}
            />
          </div>
        )}
      </li>
      {form.reportID ? (
        <li className="list__item--form list__item--no-style">
          <p className="fs-00 margin-top-20 margin-bottom-10">
            <strong>
              Level of Detail:{' '}
              {form.reportType === REPORT_EXPORT_DELIVERY_STATUS_SUMMARY
                ? 'Order Summary'
                : 'Order Detail'}
            </strong>
          </p>
        </li>
      ) : (
        <li className="list__item--form list__item--no-style divider--bottom margin-bottom-15 padding-bottom-20">
          <label htmlFor="id_detail_level">Level of Detail</label>
          <select
            className="select w-100 margin-bottom-0"
            id="id_detail_level"
            value={form.reportType}
            onChange={(event) =>
              updateForm(formName, {reportType: event.target.value})
            }
          >
            {[
              REPORTS_BY_TYPE[REPORT_EXPORT_DELIVERY_STATUS_SUMMARY],
              REPORTS_BY_TYPE[REPORT_EXPORT_DELIVERY_STATUS_DETAIL],
            ].map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </li>
      )}
    </ul>
  )
}

ExportDeliveryStatusForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
