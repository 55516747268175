import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {isNumeric} from '../../../common/utils.js'
import {plural} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  getState,
  setForm,
  updateForm,
  setFormValue,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {setMO, moSelector} from '../../../data/mos.js'
import {getProductName, productSelector} from '../../../data/products.js'
import {refreshMOList} from '../moListActions.js'

export const FINISH_MO_MODAL_FORM = 'FINISH_MO_MODAL_FORM'

export async function showFinishMOModal(referenceID, lineIDs) {
  const mo = moSelector(getState(), {referenceID})

  setForm(FINISH_MO_MODAL_FORM, {
    referenceID,
    lineIDs,
    ...mo.lines.reduce((prev, line) => {
      if (lineIDs.includes(line.id)) {
        prev[`lines__${line.id}__toBeFinishedQuantity`] = '0'
      }

      return prev
    }, {}),
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(FINISH_MO_MODAL_FORM, updates)
}

export function setModalFormValue(path, value) {
  setFormValue(FINISH_MO_MODAL_FORM, path, value)
}

export function removeModalForm() {
  removeForm(FINISH_MO_MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[FINISH_MO_MODAL_FORM]
}

export const errorsSelector = createSelector(modalFormSelector, (form) => {
  const errors = {}

  if (!form) {
    return errors
  }

  form.lineIDs.forEach((lineID) => {
    const toBeFinishedQuantity = form[`lines__${lineID}__toBeFinishedQuantity`]

    if (toBeFinishedQuantity && !isNumeric(toBeFinishedQuantity)) {
      errors[`lines__${lineID}__toBeFinishedQuantity`] =
        'Quantity must be a number'
      errors.preventSave = true
    }
  })

  return errors
})

export async function finishMOLine(referenceID, lineID, stats) {
  const form = modalFormSelector(getState())
  const params = {}

  const toBeFinishedQuantity =
    Number(form[`lines__${lineID}__toBeFinishedQuantity`]) || 0

  if (toBeFinishedQuantity !== 0) {
    params.amount = toBeFinishedQuantity
  }

  if (Object.keys(params).length === 0) {
    return
  }

  const {json} = await apiverson.post(
    `/manufacturing_order/${encodeURIComponent(
      referenceID,
    )}/line/${lineID}/finish`,
    params,
  )

  setMO(json)

  stats.count = stats.count + 1
}

export async function finishMO() {
  try {
    updateModalForm({isSaving: true})

    const {referenceID, lineIDs} = modalFormSelector(getState())
    const stats = {count: 0}

    await Promise.all(
      lineIDs.map((lineID) => finishMOLine(referenceID, lineID, stats)),
    )

    showMessageToast(
      plural(stats.count)`${stats.count} MFG order line${[
        's were',
        ' was',
      ]} finished.`,
    )

    removeModalForm()

    if (stats.count !== 0) {
      await refreshMOList()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error finishing MFG order line: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function Line({lineID, autoFocus}) {
  const form = useSelector(modalFormSelector)
  const errors = useSelector(errorsSelector)
  const mo = useSelector((state) =>
    moSelector(state, {referenceID: form.referenceID}),
  )
  const line = mo.lines.find(({id}) => id === lineID)
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )

  if (!line) {
    return null
  }

  return (
    <tr key={lineID}>
      <td className="table__td">
        <div className="fs-01 margin-bottom-7">
          <strong>{getProductName(product)}</strong>
        </div>
        <div className="fs-n0 margin-bottom-0">{line.sku}</div>
      </td>
      <td className="table__td align-center">
        <div className="fs-01">
          <strong>{line.requested_quantity}</strong>
        </div>
      </td>
      <td className="table__td border-left--light align-center">
        <div className="inline-block">
          <NumberInput
            className="margin-bottom-0"
            value={form[`lines__${lineID}__toBeFinishedQuantity`]}
            onChange={(value) =>
              updateModalForm({
                [`lines__${lineID}__toBeFinishedQuantity`]: String(value),
              })
            }
            autoFocus={autoFocus}
          />
          {errors[`lines__${lineID}__toBeFinishedQuantity`] && (
            <small className="error">
              {errors[`lines__${lineID}__toBeFinishedQuantity`]}
            </small>
          )}
        </div>
        <div className="fs-n2 text--lt-grey margin-top-5">
          (Previous: <span>{line.finished_quantity}</span>)
        </div>
      </td>
    </tr>
  )
}

Line.propTypes = {
  lineID: PropTypes.number.isRequired,
  autoFocus: PropTypes.bool,
}

function FinishMOModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Receive/Restock Finished Products"
      modalSize="lg"
      onConfirm={() => finishMO()}
      onCancel={() => removeModalForm()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <>
        <p className="fs-01 margin-bottom-20">
          <strong>Products in MFG Order</strong>
        </p>
        <table className="table">
          <thead>
            <tr>
              <th className="table__th table__th--sm w-30">Finished SKU</th>
              <th className="table__th table__th--sm w-20 align-center">
                Requested Qty
              </th>
              <th className="table__th table__th--sm w-20 border-left--light align-center">
                Finished Qty
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody--lines">
            {form.lineIDs.map((lineID, index) => (
              <Line key={lineID} lineID={lineID} autoFocus={index === 0} />
            ))}
          </tbody>
        </table>
      </>
    </ConfirmModal>
  )
}

FinishMOModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(FinishMOModal, modalFormSelector)
