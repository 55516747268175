import PropTypes from 'prop-types'
import format from 'date-fns/format'
import omit from 'lodash/omit.js'
import debounce from 'lodash/debounce.js'

import {
  getState,
  setForm,
  updateForm,
  setFormValue,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {getRealDate} from '../../../common/date.js'
import {isPresent} from '../../../common/utils.js'
import apiverson from '../../../common/apiverson.js'
import {ORDER_LIST_PAGE} from '../../../common/constants/Pages.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {refreshOrderListAndCounts} from '../../OrderListPage/orderListActions.js'
import {getRelatedBatches, setBatch} from '../../../data/batches.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  refreshBatchList,
  navigateToBatchDetailPage,
} from '../batchListActions.js'
import {
  Count,
  IfPlural,
  IfSingle,
  PluralBlock,
} from '../../../common/components/Plural.js'
import {updateOrder} from '../../../data/orders.js'
import Quantity from '../../../common/components/Quantity.js'
import {stringIt} from '../../../common/querystring.js'

export const MODAL_FORM = 'CREATE_BATCH_MODAL'

export async function showCreateBatchModal({
  orderNumbers = [],
  orderListQueryParams = null,
  orderListCount = 0,
  movingFromBatch = false,
} = {}) {
  const date = getRealDate()

  setForm(MODAL_FORM, {
    referenceID: format(date, 'yyyyMMdd-HHmmss'),
    referenceIDIsDuplicated: false,
    internalNotes: '',
    orderNumbersInActiveBatches: [],
    orderNumbersInArchivedBatches: [],
    orderNumbersNotInBatches: [],
    orderListQueryParams:
      orderNumbers.length === 0 && orderListQueryParams
        ? omit(orderListQueryParams, ['limit', 'offset'])
        : null,
    orderListCount,
    movingFromBatch,
    isLoading: true,
    isSaving: false,
    serverError: null,
  })

  await checkOrderNumbers(orderNumbers)

  updateModalForm({isLoading: false})

  await checkBatchReferenceID()
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function setModalFormValue(path, value) {
  return setFormValue(MODAL_FORM, path, value)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function errorsSelector(state) {
  const {referenceID, referenceIDIsDuplicated} = modalFormSelector(state)
  const errors = {}

  if (!isPresent(referenceID)) {
    errors.referenceID = 'Reference ID is required'
    errors.preventSave = true
  } else if (referenceIDIsDuplicated) {
    errors.referenceID = 'Reference ID is already in use'
    errors.preventSave = true
  }

  return errors
}

export async function checkBatchReferenceID() {
  const token = {}
  checkBatchReferenceID.token = token

  const {referenceID} = modalFormSelector(getState())

  if (!referenceID) {
    updateModalForm({referenceIDIsDuplicated: false})

    return
  }

  try {
    updateModalForm({isSaving: true, serverError: null})

    await apiverson.head(`/batch/${encodeURIComponent(referenceID)}`)

    if (token !== checkBatchReferenceID.token) {
      return
    }

    updateModalForm({referenceIDIsDuplicated: true})
  } catch (err) {
    if (token !== checkBatchReferenceID.token) {
      return
    }

    if (err.response && err.response.status === 404) {
      updateModalForm({referenceIDIsDuplicated: false})
    } else {
      updateModalForm({
        serverError: `Error checking batch reference ID: ${
          err.message || err.error_message
        }`,
      })
    }
  } finally {
    if (token === checkBatchReferenceID.token) {
      updateModalForm({isSaving: false})
    }
  }
}

const debounceCheckBatchReferenceID = debounce(checkBatchReferenceID, 500)

export async function checkOrderNumbers(orderNumbers) {
  if (orderNumbers.length === 0) {
    return
  }

  try {
    const batchByOrderNumber = await getRelatedBatches(orderNumbers)

    const orderNumbersNotInBatches = []
    const orderNumbersInArchivedBatches = []
    const orderNumbersInActiveBatches = []
    for (const orderNumber of orderNumbers) {
      const batch = batchByOrderNumber[orderNumber]

      if (!batch) {
        orderNumbersNotInBatches.push(orderNumber)
      } else if (batch.archived_date) {
        orderNumbersInArchivedBatches.push(orderNumber)
      } else {
        orderNumbersInActiveBatches.push(orderNumber)
      }
    }

    updateModalForm({
      orderNumbersInArchivedBatches,
      orderNumbersNotInBatches,
      orderNumbersInActiveBatches,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
    })
  }
}

export async function createBatch({goToBatchDetails} = {}) {
  try {
    const {
      referenceID,
      internalNotes,
      orderNumbersNotInBatches,
      orderNumbersInActiveBatches,
      orderListQueryParams,
    } = modalFormSelector(getState())

    const orderNumbers = [
      ...orderNumbersNotInBatches,
      ...orderNumbersInActiveBatches,
    ]

    updateModalForm({isSaving: true, serverError: null})

    const {json: batch} = await apiverson.post('/batch', {
      reference_id: referenceID,
      internal_notes: internalNotes,
      ...(orderListQueryParams
        ? {criteria: stringIt(orderListQueryParams)}
        : orderNumbers.length
          ? {order_numbers: orderNumbers}
          : undefined),
    })

    setBatch(batch)

    orderNumbers.map((orderNumber) =>
      updateOrder(orderNumber, {batch_reference_id: referenceID}),
    )

    showMessageToast('Batch was successfully created.')

    closeModal()

    if (goToBatchDetails) {
      navigateToBatchDetailPage(batch.reference_id)
    } else if (currentPageSelector(getState()) === ORDER_LIST_PAGE) {
      await refreshOrderListAndCounts()
    } else {
      await refreshBatchList()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error creating batch: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

function CreateBatchModal({form}) {
  const errors = useSelector(errorsSelector)
  const totalToBeAdded =
    form.orderNumbersInActiveBatches.length +
    form.orderNumbersNotInBatches.length +
    form.orderListCount
  const initialOrderCount =
    totalToBeAdded + form.orderNumbersInArchivedBatches.length
  const canCreateBatch = totalToBeAdded > 0 || initialOrderCount === 0

  return (
    <ConfirmModal
      title="Create a New Batch"
      modalSize="sm-md"
      confirmText="Create Batch"
      onConfirm={canCreateBatch ? () => createBatch() : null}
      preventHotKeyConfirm
      MiddleButtons={
        canCreateBatch
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isLoading}
                isLoading={form.isSaving}
                onClick={() => createBatch({goToBatchDetails: true})}
              >
                Create and View Batch
              </ButtonPrimary>
            )
          : null
      }
      cancelText={canCreateBatch ? 'Cancel' : 'Close'}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave || form.isLoading}
      error={form.serverError}
    >
      {form.isLoading ? (
        <div className="align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle" />
        </div>
      ) : (
        <>
          {form.orderListQueryParams && totalToBeAdded > 0 && (
            <PluralBlock count={totalToBeAdded}>
              <div className="divider--bottom margin-bottom-20">
                <p className="fs-01 lh-md margin-bottom-0">
                  <strong>
                    Let’s {form.movingFromBatch ? 'move' : 'add'} all filtered
                    orders (<Count />) to a new batch.
                  </strong>
                </p>
                {form.movingFromBatch && (
                  <p className="fs-n1 lh-md margin-top-5 margin-bottom-0">
                    <em>
                      Please note that orders in an archived batch cannot be
                      moved.
                    </em>
                  </p>
                )}
              </div>
            </PluralBlock>
          )}
          {!form.orderListQueryParams && initialOrderCount > 0 && (
            <div
              className={
                canCreateBatch ? 'divider--bottom margin-bottom-30' : ''
              }
            >
              {canCreateBatch && (
                <PluralBlock count={initialOrderCount}>
                  <p className="fs-01 lh-md margin-bottom-15">
                    <strong>
                      <IfPlural>
                        <Quantity value={totalToBeAdded} /> of the <Count />{' '}
                        selected orders
                      </IfPlural>
                      <IfSingle>The selected order</IfSingle> can be assigned to
                      this new batch.
                    </strong>
                  </p>
                </PluralBlock>
              )}
              <PluralBlock array={form.orderNumbersInArchivedBatches}>
                {!canCreateBatch ? (
                  <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                    <span className="i--error fs-01 lh-sm margin-right-3 v-align-middle" />
                    <IfPlural>
                      All selected orders are assigned to archived batches
                    </IfPlural>
                    <IfSingle>
                      This order is assigned to an archived batch
                    </IfSingle>{' '}
                    and can’t be reassigned.
                  </p>
                ) : form.orderNumbersInArchivedBatches.length ? (
                  <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                    <span className="i--error fs-01 lh-sm margin-right-3 v-align-middle" />
                    <IfPlural>
                      <Count /> orders are assigned to archived batches
                    </IfPlural>
                    <IfSingle>
                      1 order is assigned to an archived batch
                    </IfSingle>{' '}
                    and can’t be reassigned.
                  </p>
                ) : null}
              </PluralBlock>
              {form.orderNumbersInActiveBatches.length > 0 && (
                <PluralBlock array={form.orderNumbersInActiveBatches}>
                  <p className="fs-00 alert alert--warning margin-bottom-10">
                    <span className="i-exclamation-triangle text--warning-orange fs-01 lh-sm v-align-middle margin-right-5" />
                    <IfPlural>
                      <Count /> orders are assigned to other batches
                    </IfPlural>
                    <IfSingle>1 order is assigned to another batch</IfSingle>{' '}
                    and will be reassigned to this batch.
                  </p>
                </PluralBlock>
              )}
            </div>
          )}

          {canCreateBatch && (
            <div className="row">
              <div className="medium-9 columns">
                <ul className="list list--no-style margin-bottom-30">
                  <li className="list__item margin-bottom-20">
                    <TextInput
                      label="Batch ID"
                      id="reference_id"
                      className="input--med-h"
                      value={form.referenceID}
                      onChange={(value) => {
                        updateModalForm({referenceID: value})

                        debounceCheckBatchReferenceID()
                      }}
                      onBlur={() => debounceCheckBatchReferenceID.flush()}
                      errorMessage={errors.referenceID}
                    />
                  </li>
                  <li className="list__item margin-bottom-0">
                    <TextArea
                      className="fs-n0 lh-lg"
                      label="Notes for Batch"
                      id="internal_notes"
                      value={form.internalNotes}
                      onChange={(value) =>
                        updateModalForm({internalNotes: value})
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </ConfirmModal>
  )
}

CreateBatchModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    referenceIDIsDuplicated: PropTypes.bool.isRequired,
    internalNotes: PropTypes.string.isRequired,
    orderNumbersInActiveBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    orderNumbersInArchivedBatches: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    orderNumbersNotInBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    orderListQueryParams: PropTypes.object,
    orderListCount: PropTypes.number.isRequired,
    movingFromBatch: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(CreateBatchModal, modalFormSelector)
