import {connect} from 'react-redux'

import {
  ORDER_LIST_PAGE,
  ORDER_DETAIL_PAGE,
} from '../../common/constants/Pages.js'
import {PageShape} from '../../common/PropTypes.js'
import OrderListPage from './components/OrderListPage/index.js'
import Modals from '../OrderListPage/Modals/index.js'

function Iverson({currentPage}) {
  return (
    <div id="iverson-page">
      <Modals />
      {[ORDER_DETAIL_PAGE, ORDER_LIST_PAGE].includes(currentPage) && (
        <OrderListPage />
      )}
    </div>
  )
}

Iverson.propTypes = {
  currentPage: PageShape.isRequired,
}

function mapStateToProps(state) {
  return {
    currentPage: state.ui.currentPage,
  }
}

export default connect(mapStateToProps)(Iverson)
