import {useEffect} from 'react'
import reduce from 'lodash/reduce.js'
import kebabCase from 'lodash/kebabCase.js'

import {
  getUnfulfilledOrderNumbers,
  getOrderNumbersThatCanHaveShippingLabels,
  getOrderNumbersWithShippingLabels,
  getArchivedOrderNumbers,
  getCancelledOrderNumbers,
  getDropshippedOrderNumbers,
  getMarkAsShippedOrderNumbers,
  getManuallyTrackedOrderNumbers,
  getMergedOrderNumbers,
  getOrderNumbersThatCanHaveReturnLabels,
  getOrderNumbersWithReturnLabels,
  getIsPrimeOrderNumbers,
  getIsNotPrimeOrderNumbers,
  getContainsMixOfPrimeAndNotPrimeOrders,
  getIsPrimeWithoutReturnLabelOrderNumbers,
  needsAmazonSFPSelector,
  showDropshipNavSelector,
} from '../../../data/orders.js'
import {
  orderDetailOrderNumberSelector,
  orderDetailOrderNotFoundSelector,
} from '../../OrderDetailPage/orderDetailSelectors.js'
import {
  SHIPPING_TAB,
  RETURN_TAB,
  DROPSHIP_TAB,
  BATCH_DETAILS_TAB,
  setOrderActionPanelCurrentTab,
  setupOrderActionPanelForm,
  orderActionPanelCurrentTabSelector,
  shippingPendingLabelsOrderNumbersSelector,
  returnPendingLabelsOrderNumbersSelector,
} from './orderActionPanelFunctions.js'
import {needsWarehouseSelector} from '../../../data/warehouses.js'
import {needsShipperSelector} from '../../../data/shippers.js'
import {needsSupplierSelector} from '../../../data/suppliers.js'
import {usePresetsSelector} from '../../../data/company.js'

import Tabs from './Tabs.js'
import Tab from './Tab.js'
import IdlePanel from './IdlePanel.js'
import ShippingTab from './ShippingTab.js'
import ReturnTab from './ReturnTab.js'
import DropshipTab from './DropshipTab.js'
import {
  activeOrdersSelector,
  batchReferenceIDSelector,
  selectedOrderNumbersSelector,
  visibleOrderNumbersSelector,
} from '../orderListSelectors.js'
import {onlyIfAutoForm, useSelector} from '../../../store.js'
import className from '../../../common/className.js'
import BatchDetailsTab from './BatchDetailsTab.js'
import {canUseBatchesSelector} from '../../../data/batches.js'

function ActionPanel() {
  const activeOrders = useSelector(activeOrdersSelector)
  const pendingShippingLabelOrderNumbers = useSelector(
    shippingPendingLabelsOrderNumbersSelector,
  )
  const pendingReturnLabelOrderNumbers = useSelector(
    returnPendingLabelsOrderNumbersSelector,
  )
  const isOrderDetailView = !!useSelector(orderDetailOrderNumberSelector)
  const orderDetailOrderNotFound = useSelector(orderDetailOrderNotFoundSelector)
  const currentTab = useSelector(orderActionPanelCurrentTabSelector)
  const visibleOrderNumbers = useSelector(visibleOrderNumbersSelector)
  const selectedOrderNumbers = useSelector(selectedOrderNumbersSelector)
  const needsWarehouse = useSelector(needsWarehouseSelector)
  const needsShipper = useSelector(needsShipperSelector)
  const needsSupplier = useSelector(needsSupplierSelector)
  const needsAmazonSFP = useSelector((state) =>
    needsAmazonSFPSelector(state, {orders: activeOrders}),
  )
  const showDropshipNav = useSelector(showDropshipNavSelector)
  const usePresets = useSelector(usePresetsSelector)
  const canUseBatches = useSelector(canUseBatchesSelector)
  const batchReferenceID = useSelector(batchReferenceIDSelector)

  const showBatchDetailsTab =
    canUseBatches && !!batchReferenceID && !isOrderDetailView
  const unfulfilledOrderNumbers = getUnfulfilledOrderNumbers(
    activeOrders,
    pendingShippingLabelOrderNumbers,
  )
  const archivedOrderNumbers = getArchivedOrderNumbers(activeOrders)
  const cancelledOrderNumbers = getCancelledOrderNumbers(activeOrders)
  const mergedOrderNumbers = getMergedOrderNumbers(activeOrders)
  const orderNumbersThatCanHaveShippingLabels =
    getOrderNumbersThatCanHaveShippingLabels(
      activeOrders,
      pendingShippingLabelOrderNumbers,
    )
  const orderNumbersWithShippingLabels = getOrderNumbersWithShippingLabels(
    activeOrders,
    pendingShippingLabelOrderNumbers,
  )
  const dropshippedOrderNumbers = getDropshippedOrderNumbers(activeOrders)
  const markAsShippedOrderNumbers = getMarkAsShippedOrderNumbers(activeOrders)
  const manuallyTrackedOrderNumbers =
    getManuallyTrackedOrderNumbers(activeOrders)
  const orderNumbersWithReturnLabels = getOrderNumbersWithReturnLabels(
    activeOrders,
    pendingReturnLabelOrderNumbers,
  )
  const orderNumbersThatCanHaveReturnLabels =
    getOrderNumbersThatCanHaveReturnLabels(
      activeOrders,
      pendingReturnLabelOrderNumbers,
    )
  const isPrimeOrderNumbers = getIsPrimeOrderNumbers(activeOrders)
  const isNotPrimeOrderNumbers = getIsNotPrimeOrderNumbers(activeOrders)
  const containsMixOfPrimeAndNotPrimeOrders =
    getContainsMixOfPrimeAndNotPrimeOrders(activeOrders)
  const isPrimeWithoutReturnLabelOrderNumbers =
    getIsPrimeWithoutReturnLabelOrderNumbers(activeOrders)

  useEffect(() => {
    // reset tab when all orders have been unselected
    if (selectedOrderNumbers.length === 0) {
      setOrderActionPanelCurrentTab(SHIPPING_TAB)
    }
  }, [selectedOrderNumbers.length])

  useEffect(() => {
    // reset from batch tab if batch tab isn't available
    if (!showBatchDetailsTab && currentTab === BATCH_DETAILS_TAB) {
      setOrderActionPanelCurrentTab(SHIPPING_TAB)
    }
  }, [showBatchDetailsTab])

  if (!isOrderDetailView) {
    if (showBatchDetailsTab && selectedOrderNumbers.length === 0) {
      return <BatchDetailsTab referenceID={batchReferenceID} />
    }
    if (visibleOrderNumbers.length === 0) {
      return null
    }
    if (selectedOrderNumbers.length === 0) {
      return <IdlePanel />
    }
  }

  if (isOrderDetailView && orderDetailOrderNotFound) {
    return null
  }

  const orderNumberClassNames = reduce(
    {
      unfulfilledOrderNumbers,
      archivedOrderNumbers,
      cancelledOrderNumbers,
      mergedOrderNumbers,
      orderNumbersThatCanHaveShippingLabels,
      orderNumbersWithShippingLabels,
      dropshippedOrderNumbers,
      markAsShippedOrderNumbers,
      manuallyTrackedOrderNumbers,
      orderNumbersWithReturnLabels,
      orderNumbersThatCanHaveReturnLabels,
      isPrimeOrderNumbers,
      isNotPrimeOrderNumbers,
      containsMixOfPrimeAndNotPrimeOrders,
      isPrimeWithoutReturnLabelOrderNumbers,
    },
    (prev, orderNumbers, key) => {
      if (orderNumbers.length > 0) {
        return [
          ...prev,
          `meta-actionpanel-has-${kebabCase(key).replace(
            'order-numbers',
            'orders',
          )}`,
        ]
      }
      return prev
    },
    [],
  )

  return (
    <div
      className={className`wrap--action-panel meta-actionpanel ${orderNumberClassNames}`}
    >
      {!isOrderDetailView && (
        <div className="alert alert--standard margin-bottom-15">
          <h4 className="fs-01 lh-sm margin-top-0 margin-bottom-0 align-center">
            <strong>
              {selectedOrderNumbers.length === 1
                ? `${selectedOrderNumbers[0]} Selected`
                : `${selectedOrderNumbers.length} Orders Selected`}
            </strong>
          </h4>
        </div>
      )}
      <div className="panel--actions panel--order-actions">
        <Tabs smallerBottomMargin={usePresets}>
          {showBatchDetailsTab && (
            <Tab
              active={currentTab === BATCH_DETAILS_TAB}
              onClick={() => setOrderActionPanelCurrentTab(BATCH_DETAILS_TAB)}
            >
              Batch
            </Tab>
          )}
          <Tab
            active={currentTab === SHIPPING_TAB}
            onClick={() => setOrderActionPanelCurrentTab(SHIPPING_TAB)}
          >
            Shipping Label
          </Tab>
          <Tab
            active={currentTab === RETURN_TAB}
            onClick={() => setOrderActionPanelCurrentTab(RETURN_TAB)}
          >
            Return Label
          </Tab>
          {showDropshipNav && (
            <Tab
              active={currentTab === DROPSHIP_TAB}
              onClick={() => setOrderActionPanelCurrentTab(DROPSHIP_TAB)}
            >
              Dropship
            </Tab>
          )}
        </Tabs>
        <div>
          {currentTab === BATCH_DETAILS_TAB && showBatchDetailsTab ? (
            <BatchDetailsTab referenceID={batchReferenceID} />
          ) : currentTab === SHIPPING_TAB ? (
            <ShippingTab
              needsShipper={needsShipper}
              needsWarehouse={needsWarehouse}
              needsAmazonSFP={needsAmazonSFP}
              isOrderDetailView={isOrderDetailView}
              unfulfilledOrderNumbers={unfulfilledOrderNumbers}
              archivedOrderNumbers={archivedOrderNumbers}
              cancelledOrderNumbers={cancelledOrderNumbers}
              mergedOrderNumbers={mergedOrderNumbers}
              orderNumbersThatCanHaveShippingLabels={
                orderNumbersThatCanHaveShippingLabels
              }
              orderNumbersWithShippingLabels={orderNumbersWithShippingLabels}
              markAsShippedOrderNumbers={markAsShippedOrderNumbers}
              manuallyTrackedOrderNumbers={manuallyTrackedOrderNumbers}
              isPrimeOrderNumbers={isPrimeOrderNumbers}
              isNotPrimeOrderNumbers={isNotPrimeOrderNumbers}
              containsMixOfPrimeAndNotPrimeOrders={
                containsMixOfPrimeAndNotPrimeOrders
              }
            />
          ) : currentTab === RETURN_TAB ? (
            <ReturnTab
              needsShipper={needsShipper}
              needsWarehouse={needsWarehouse}
              isOrderDetailView={isOrderDetailView}
              archivedOrderNumbers={archivedOrderNumbers}
              cancelledOrderNumbers={cancelledOrderNumbers}
              mergedOrderNumbers={mergedOrderNumbers}
              orderNumbersThatCanHaveReturnLabels={
                orderNumbersThatCanHaveReturnLabels
              }
              orderNumbersWithReturnLabels={orderNumbersWithReturnLabels}
              isPrimeWithoutReturnLabelOrderNumbers={
                isPrimeWithoutReturnLabelOrderNumbers
              }
            />
          ) : currentTab === DROPSHIP_TAB ? (
            <DropshipTab
              needsSupplier={needsSupplier}
              isOrderDetailView={isOrderDetailView}
              unfulfilledOrderNumbers={unfulfilledOrderNumbers}
              archivedOrderNumbers={archivedOrderNumbers}
              cancelledOrderNumbers={cancelledOrderNumbers}
              mergedOrderNumbers={mergedOrderNumbers}
              orderNumbersWithShippingLabels={orderNumbersWithShippingLabels}
              dropshippedOrderNumbers={dropshippedOrderNumbers}
              markAsShippedOrderNumbers={markAsShippedOrderNumbers}
              manuallyTrackedOrderNumbers={manuallyTrackedOrderNumbers}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default onlyIfAutoForm(ActionPanel, setupOrderActionPanelForm)
