import {all, takeEvery} from 'redux-saga/effects'

import CustomsModal from '../../OrderListPage/Modals/CustomsModal/index.js'
import ImporterOfRecordModal from '../../OrderListPage/Modals/ImporterOfRecordModal.js'
import InsuranceModal from '../../OrderListPage/Modals/InsuranceModal.js'
import MultiboxModal from '../../OrderListPage/Modals/MultiboxModal/index.js'

import AddReturnOrderCommentModal, {
  ADD_RETURN_ORDER_COMMENT,
  addReturnOrderCommentWorker,
} from './AddReturnOrderCommentModal.js'
import AssignReturnOrderLineCodeModal, {
  ASSIGN_RETURN_ORDER_LINE_CODE,
  assignReturnOrderLineCodeWorker,
} from './AssignReturnOrderLineCodeModal.js'
import CancelReturnOrdersModal, {
  CANCEL_RETURN_ORDERS,
  cancelReturnOrdersWorker,
} from './CancelReturnOrdersModal.js'
import CreateReturnOrderModal from './CreateReturnOrderModal/index.js'
import DeleteReturnOrderLabelsModal, {
  DELETE_RETURN_ORDER_LABELS,
  deleteReturnOrderLabelsWorker,
} from './DeleteReturnOrderLabelsModal.js'
import DeleteReturnOrderLinesModal, {
  DELETE_RETURN_ORDER_LINES,
  deleteReturnOrderLinesWorker,
} from './DeleteReturnOrderLinesModal.js'
import EditAdditionalOptionsModal from '../../LabelConfig/Modals/EditAdditionalOptionsModal.js'
import EditRMALineModal from './EditRMALineModal.js'
import EmailReturnOrdersModal, {
  EMAIL_RETURN_ORDERS,
  emailReturnOrdersWorker,
} from './EmailReturnOrdersModal.js'
import HoldAtLocationModal from '../../LabelConfig/Modals/HoldAtLocationModal.js'
import ReceiveReturnOrderModal, {
  SHOW_RECIEVE_RETURN_ORDER_MODAL,
  RECEIVE_RETURN_ORDER,
  showRecieveReturnOrderModalWorker,
  receiveReturnOrderWorker,
} from './ReceiveReturnOrderModal/index.js'
import TaxInfoModal from '../../LabelConfig/Modals/TaxInfoModal.js'
import UpdateReturnOrderCustomerNotesModal, {
  SHOW_UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL,
  UPDATE_RETURN_ORDER_CUSTOMER_NOTES,
  showUpdateReturnOrderCustomerNotesModalWorker,
  updateReturnOrderCustomerNotesWorker,
} from './UpdateReturnOrderCustomerNotesModal.js'
import UpdateReturnOrderInternalNotesModal, {
  SHOW_UPDATE_RETURN_ORDER_INTERNAL_NOTES_MODAL,
  UPDATE_RETURN_ORDER_INTERNAL_NOTES,
  showUpdateReturnOrderInternalNotesModalWorker,
  updateReturnOrderInternalNotesWorker,
} from './UpdateReturnOrderInternalNotesModal.js'
import UpdateReturnOrderLineNotesModal, {
  SHOW_UPDATE_RETURN_ORDER_LINE_NOTES_MODAL,
  UPDATE_RETURN_ORDER_LINE_NOTES,
  showUpdateReturnOrderLineNotesModalWorker,
  updateReturnOrderLineNotesWorker,
} from './UpdateReturnOrderLineNotesModal.js'
import UpdateReturnOrdersStatusModal, {
  UPDATE_RETURN_ORDERS_STATUS,
  updateReturnOrdersStatusWorker,
} from './UpdateReturnOrdersStatusModal.js'
import ETDPreshipmentDocsModal from '../../LabelConfig/Modals/ETDPreshipmentDocsModal.js'

export default function Modals() {
  return (
    <>
      <AddReturnOrderCommentModal />
      <AssignReturnOrderLineCodeModal />
      <CancelReturnOrdersModal />
      <CreateReturnOrderModal />
      <CustomsModal />
      <ImporterOfRecordModal />
      <DeleteReturnOrderLabelsModal />
      <DeleteReturnOrderLinesModal />
      <EditAdditionalOptionsModal />
      <EditRMALineModal />
      <EmailReturnOrdersModal />
      <ETDPreshipmentDocsModal />
      <HoldAtLocationModal />
      <InsuranceModal />
      <MultiboxModal />
      <ReceiveReturnOrderModal />
      <TaxInfoModal />
      <UpdateReturnOrderCustomerNotesModal />
      <UpdateReturnOrderInternalNotesModal />
      <UpdateReturnOrderLineNotesModal />
      <UpdateReturnOrdersStatusModal />
    </>
  )
}

export function* returnOrderListModalsSaga() {
  yield all([
    takeEvery(ADD_RETURN_ORDER_COMMENT, addReturnOrderCommentWorker),
    takeEvery(ASSIGN_RETURN_ORDER_LINE_CODE, assignReturnOrderLineCodeWorker),
    takeEvery(CANCEL_RETURN_ORDERS, cancelReturnOrdersWorker),
    takeEvery(DELETE_RETURN_ORDER_LABELS, deleteReturnOrderLabelsWorker),
    takeEvery(DELETE_RETURN_ORDER_LINES, deleteReturnOrderLinesWorker),
    takeEvery(EMAIL_RETURN_ORDERS, emailReturnOrdersWorker),
    takeEvery(
      SHOW_RECIEVE_RETURN_ORDER_MODAL,
      showRecieveReturnOrderModalWorker,
    ),
    takeEvery(RECEIVE_RETURN_ORDER, receiveReturnOrderWorker),
    takeEvery(
      SHOW_UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL,
      showUpdateReturnOrderCustomerNotesModalWorker,
    ),
    takeEvery(
      UPDATE_RETURN_ORDER_CUSTOMER_NOTES,
      updateReturnOrderCustomerNotesWorker,
    ),
    takeEvery(
      SHOW_UPDATE_RETURN_ORDER_INTERNAL_NOTES_MODAL,
      showUpdateReturnOrderInternalNotesModalWorker,
    ),
    takeEvery(
      UPDATE_RETURN_ORDER_INTERNAL_NOTES,
      updateReturnOrderInternalNotesWorker,
    ),
    takeEvery(
      SHOW_UPDATE_RETURN_ORDER_LINE_NOTES_MODAL,
      showUpdateReturnOrderLineNotesModalWorker,
    ),
    takeEvery(UPDATE_RETURN_ORDER_LINE_NOTES, updateReturnOrderLineNotesWorker),
    takeEvery(UPDATE_RETURN_ORDERS_STATUS, updateReturnOrdersStatusWorker),
  ])
}
