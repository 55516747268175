import PropTypes from 'prop-types'
import classNames from 'classnames'

import WeightInput from '../../../../common/components/Form/WeightInput.js'
import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueWeightInput({
  condition,
  error,
  onChange,
}) {
  const option = getConditionOption(condition)

  return (
    <div className={classNames('flex-item', {error})}>
      <WeightInput
        weightOz={option.getValue(condition)}
        setWeightOz={(weight) => onChange(weight)}
        metaClassName="meta-rule-input-weight"
        allowZero
      />
      {error && <small className="error error-message">{error}</small>}
    </div>
  )
}

ConditionValueWeightInput.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
}
