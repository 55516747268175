import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector, createStructuredSelector} from 'reselect'

import SelectAllButton from '../../common/components/List/SelectAllButton.js'
import {setSelectedReferenceIDs} from './returnOrderListActions.js'
import {
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  allSelectedSelector,
} from './returnOrderListSelectors.js'

export function ReturnOrderSelectAllButton({
  checked,
  referenceIDList,
  indeterminate,
  ...props
}) {
  return (
    <SelectAllButton
      checked={checked}
      indeterminate={indeterminate}
      onChange={(value) =>
        props.setSelectedReferenceIDs(value ? referenceIDList : [])
      }
    />
  )
}

ReturnOrderSelectAllButton.propTypes = {
  setSelectedReferenceIDs: PropTypes.func.isRequired,
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  indeterminate: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  referenceIDList: referenceIDListSelector,
  indeterminate: createSelector(
    allSelectedSelector,
    selectedReferenceIDsSelector,
    (allSelected, selectedReferenceIDs) =>
      !allSelected && selectedReferenceIDs.length > 0,
  ),
  checked: allSelectedSelector,
})

const mapDispatchToProps = {
  setSelectedReferenceIDs,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderSelectAllButton)
