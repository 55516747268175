import PropTypes from 'prop-types'

import {tagColors} from '../colors.js'
import {
  TAG_TYPE__MO,
  TAG_TYPE__ORDER,
  TAG_TYPE__PO,
  TAG_TYPE__PRODUCT,
  TAG_TYPE__RETURN_ORDER,
} from '../constants/Tags.js'
import {useSelector} from '../../store.js'
import {orderTagSelector} from '../../data/orderTags.js'
import {returnOrderTagSelector} from '../../data/returnOrderTags.js'
import {productTagSelector} from '../../data/productTags.js'
import {moTagSelector} from '../../data/moTags.js'
import {poTagSelector} from '../../data/poTags.js'

export default function TagBlock({tagID, tagType, onRemove}) {
  const tag = useSelector((state) =>
    tagType === TAG_TYPE__ORDER
      ? orderTagSelector(state, {orderTagID: tagID})
      : tagType === TAG_TYPE__RETURN_ORDER && tagID
        ? returnOrderTagSelector(state, {
            returnOrderTagID: tagID,
          })
        : tagType === TAG_TYPE__PRODUCT && tagID
          ? productTagSelector(state, {
              productTagID: tagID,
            })
          : tagType === TAG_TYPE__MO && tagID
            ? moTagSelector(state, {
                moTagID: tagID,
              })
            : tagType === TAG_TYPE__PO && tagID
              ? poTagSelector(state, {
                  poTagID: tagID,
                })
              : null,
  )

  if (!tag) {
    return null
  }

  return (
    <li
      className="list__item--inline list__item--tag"
      style={tagColors(tag.color)}
    >
      <strong className="tag-name inline-block v-align-middle">
        {tagType === TAG_TYPE__ORDER ? tag.text : tag.name}
      </strong>
      {onRemove && (
        <button
          className="btn btn--remove-tag"
          type="button"
          onClick={() => onRemove(tag)}
        >
          <span className="i--close" aria-hidden="true" />
        </button>
      )}
    </li>
  )
}

TagBlock.propTypes = {
  tagID: PropTypes.number.isRequired,
  tagType: PropTypes.oneOf([
    TAG_TYPE__ORDER,
    TAG_TYPE__RETURN_ORDER,
    TAG_TYPE__PRODUCT,
    TAG_TYPE__MO,
    TAG_TYPE__PO,
  ]).isRequired,
  onRemove: PropTypes.func,
}
