import PropTypes from 'prop-types'
import classNames from 'classnames'

import {panelLinkSelector} from './analyticsSelectors.js'
import {useSelector} from '../../store.js'

export default function SideNavLink({title, name, icon, isActive}) {
  const href = useSelector((state) => panelLinkSelector(state, {panel: name}))

  return (
    <li
      className={classNames('side-nav__item', {
        'side-nav__item--active': isActive,
      })}
    >
      <a className="side-nav__link" title={title} href={href}>
        <span
          className={classNames('icon x-sm margin-right-3', icon)}
          aria-hidden="true"
        />{' '}
        <span>{title}</span>
      </a>
    </li>
  )
}

SideNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}
