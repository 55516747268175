import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {onlyIfAutoForm, useSelector} from '../../../store.js'
import {useRulesSelector} from '../../../data/company.js'
import {getRules, rulesHaveLoadedSelector} from '../../../data/rules.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'

import SettingsPanel from '../SettingsPanel.js'
import RulesFeaturePage from './RulesFeaturePage.js'
import List from './List.js'
import {setupSettingsRulesForm} from './rulesFunctions.js'

function Rules() {
  const rulesHaveLoaded = useSelector(rulesHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).rules
  const useRules = useSelector(useRulesSelector)

  useEffect(() => {
    getRules()
  }, [])

  if (!useRules) {
    return <RulesFeaturePage />
  }

  return (
    <SettingsPanel
      header="Automation Rules"
      hasPermission={hasPermission}
      subHeader={
        <div>
          <p className="fs-01 margin-bottom-10 small-screen-hide">
            <span className="inline-block v-align-middle">
              Use this icon{' '}
              <span className="inline-block margin-left-3 margin-right-3 v-align-middle op-30">
                <span className="i-drag-handle block fs-00 lh-sm" />
                <span className="i-drag-handle block fs-00 lh-sm" />
              </span>{' '}
              to drag and reorder your rules. They will be applied in order from
              top to bottom.{' '}
            </span>
          </p>
          <p className="fs-01 margin-bottom-0 small-screen-hide">
            For more info on how to create automation rules, check out this
            handy{' '}
            <a
              href="https://support.ordoro.com/how-do-i-setup-automation-rules-in-ordoro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              support article
            </a>
            .
          </p>
        </div>
      }
    >
      {rulesHaveLoaded ? (
        <List />
      ) : (
        <div className="medium-12 columns align-center">
          <div className="loading align-center margin-top-30">
            <span className="spinner--md v-align-middle margin-right-7" />
            <span className="fs-01 lh-md v-align-middle">Loading...</span>
          </div>
        </div>
      )}
    </SettingsPanel>
  )
}

Rules.propTypes = {
  form: PropTypes.shape({
    isAddingNew: PropTypes.bool,
    indices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default onlyIfAutoForm(Rules, setupSettingsRulesForm)
