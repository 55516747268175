import {createStore, applyMiddleware, compose} from 'redux'
import {thunk} from 'redux-thunk'

import migrateStickies from './migrateStickies.js'
import rootReducer from './reducers/index.js'

export default function configureStore({
  initialState = {},
  extraEnhancer,
} = {}) {
  let enhancer = applyMiddleware(thunk)

  if (extraEnhancer) {
    enhancer = compose(enhancer, extraEnhancer)
  }

  const store = createStore(rootReducer, initialState, enhancer)

  migrateStickies(store)

  return store
}
