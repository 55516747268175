import find from 'lodash/find.js'

import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_INVENTORY_GRAPH} from '../../actions/data/productKits.js'

export default function reducer(
  state = INITIAL_STATE.data.productKits,
  action = {},
) {
  if (action.type === SET_INVENTORY_GRAPH) {
    const {payload: kitGraph} = action
    const {nodes, edges} = kitGraph

    const skus = nodes.map(({id}) => id)
    const dests = new Set(edges.map(({dest}) => dest))
    const top = find(skus, (sku) => !dests.has(sku))

    const skuMappings = {...state.skuMappings}
    skus.forEach((sku) => {
      skuMappings[sku] = top
    })

    const kitGraphs = {
      ...state.kitGraphs,
      [top]: kitGraph,
    }

    return {
      ...state,
      skuMappings,
      kitGraphs,
    }
  }

  return state
}
