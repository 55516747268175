import PropTypes from 'prop-types'
import {useEffect, useRef, useState} from 'react'
import reactDom from 'react-dom'
import {select} from 'd3-selection'
import {scaleTime, scaleLinear} from 'd3-scale'
import {axisBottom, axisLeft} from 'd3-axis'
import {line, area, curveStepAfter} from 'd3-shape'
import startOfDay from 'date-fns/startOfDay'
import parse from 'date-fns/parse'
import addDays from 'date-fns/addDays'
import dateFormat from 'date-fns/format'

import {DateShape} from '../../common/PropTypes.js'
import Currency, {formatCurrency} from '../../common/components/Currency.js'
import {useElementSize} from '../../common/components/useWindowSize.js'
import {useSelector} from '../../store.js'
import {currencySymbolSelector} from '../../data/company.js'
import useBoundingBox from '../../common/components/useBoundingBox.js'

const MARGIN = {
  top: 20,
  right: 30,
  bottom: 30,
  left: 80,
}

function addTooltips(buckets, lines, x, width, height, setToolTip, group) {
  let xRangeWidth = width / (buckets.length - 1)
  xRangeWidth = xRangeWidth > 1 ? xRangeWidth : 1

  group
    .selectAll('.region')
    .data(buckets.slice(0, buckets.length - 1))
    .enter()
    .append('rect')
    .attr('class', 'region')
    .attr('x', (d) => x(d.date))
    .attr('width', xRangeWidth)
    .attr('y', 0)
    .attr('height', height)
    .on('mouseover', (event, d) => onToolTipMouseOver(setToolTip, event, d))
    .on('mouseout', (event, d) => onToolTipMouseOut(setToolTip, event, d))
}

function onToolTipMouseOver(setToolTip, event, d) {
  const x = Number(event.target.getAttribute('x'))
  const width = Number(event.target.getAttribute('width'))
  setToolTip((toolTip) => ({
    ...toolTip,
    x: x + width + 14,
    y: 0,
    d,
  }))
}

function onToolTipMouseOut(setToolTip) {
  setToolTip((toolTip) => ({...toolTip, d: null}))
}

function cloneBucketForDate(date, originalBucket) {
  return {
    ...originalBucket,
    key: date.toISOString(),
    key_as_string: dateFormat(date, 'yyyy-MM-dd'),
    date,
  }
}

function renderGraph(
  data,
  width,
  height,
  startDate,
  endDate,
  groupElement,
  setToolTip,
  margin,
  currencySymbol,
) {
  if (!groupElement) {
    return
  }

  const group = select(groupElement)
  group.html('')

  if (!data) {
    setToolTip((toolTip) => ({...toolTip, d: null}))

    return
  }

  if (data.buckets.length === 0) {
    return
  }

  if (height <= 0 || width <= 0) {
    return
  }

  const x = scaleTime().range([0, width])

  const y = scaleLinear().range([height, 0])

  const xAxis = axisBottom().scale(x).ticks(5).tickSize(-height).tickPadding(5)

  const yAxis = axisLeft()
    .scale(y)
    .tickSize(-width)
    .ticks(5)
    .tickFormat((value) => formatCurrency(value, currencySymbol))
    .tickPadding(10)

  group.attr('transform', `translate(${margin.left},${margin.top})`)

  const yMax = data.buckets.reduce((prev, d) => {
    d.date = parse(d.key_as_string, 'yyyy-MM-dd', new Date())

    data.lines.forEach((title) => {
      prev = Math.max(prev, d[title])
    })

    return prev
  }, 10)

  startDate = startOfDay(startDate)
  endDate = startOfDay(endDate)

  let current = new Date(startDate)
  const buckets = []
  const emptyBucket = data.lines.reduce((prev, title) => {
    prev[title] = 0

    return prev
  }, {})

  let dataIndex = 0
  while (current <= endDate) {
    const currentBucket = data.buckets[dataIndex]

    if (currentBucket && currentBucket.date <= current) {
      buckets.push(currentBucket)

      dataIndex += 1
    } else {
      buckets.push(cloneBucketForDate(current, emptyBucket))
    }

    current = addDays(current, 1)
  }

  buckets.push(cloneBucketForDate(current, buckets[buckets.length - 1]))

  x.domain([startDate, current])
  y.domain([0, yMax])

  group
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0, ${height})`)
    .call(xAxis)

  group.append('g').attr('class', 'y axis').call(yAxis)

  data.lines.forEach((title, index) => {
    const graphLine = line()
      .x((d) => x(d.date))
      .y((d) => y(d[title]))
      .curve(curveStepAfter)

    const graphArea = area()
      .x((d) => x(d.date))
      .y0(height)
      .y1((d) => y(d[title]))
      .curve(curveStepAfter)

    group
      .append('path')
      .datum(buckets)
      .attr('class', `line lc-0${index + 1}`)
      .attr('d', graphLine)

    group
      .append('path')
      .datum(buckets)
      .attr('class', `area ac-0${index + 1}`)
      .attr('d', graphArea)
  })

  addTooltips(
    buckets,
    data.lines,
    x,
    width,
    height,
    setToolTip,
    group,
    currencySymbol,
  )
}

function ToolTip({data, rootX, rootY, x, y, d}) {
  if (!data || !d) {
    return null
  }

  const stats = data.lines.map((title) => ({
    title,
    value: d[title],
  }))

  return reactDom.createPortal(
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        boxSizing: 'border-box',
        top: `${rootY + y}px`,
        left: `${rootX + x}px`,
      }}
    >
      <div className="popover-wrapper">
        <div className="popover popover--line-graph">
          <div className="arrow"></div>
          <div className="inner">
            <p className="title chart-popover-title">
              <strong>{dateFormat(d.date, 'E P')}</strong>
            </p>
            <div className="content">
              <ul className="list margin-left-0 margin-bottom-0 clearfix">
                {stats.map(({title, value}) => (
                  <li
                    key={title}
                    className="list__item list__item--graph-popover"
                  >
                    <dl className="list--graph-text">
                      <dt className="list__item--graph-title">${title}</dt>
                      <dd className="list__item--graph-value">
                        <Currency value={value} />
                      </dd>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#dropdown-root'),
  )
}

ToolTip.propTypes = {
  data: PropTypes.shape({
    lines: PropTypes.array.isRequired,
  }),
  rootX: PropTypes.number.isRequired,
  rootY: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  d: PropTypes.shape({
    date: DateShape.isRequired,
  }),
}

export default function MoneyOverTimeGraph({data, startDate, endDate}) {
  const margin = MARGIN
  const [toolTip, setToolTip] = useState({
    x: 0,
    y: 0,
    d: null,
  })
  const currencySymbol = useSelector(currencySymbolSelector)

  const tipRef = useRef(null)
  const groupRef = useRef(null)
  const ref = useRef(null)
  const boundingBox = useBoundingBox({ref})
  const {width, height} = useElementSize({
    ref,
    margin,
    deps: [!data],
  })

  useEffect(() => {
    renderGraph(
      data,
      width,
      height,
      startDate,
      endDate,
      groupRef.current,
      setToolTip,
      margin,
      currencySymbol,
    )
  }, [data, width, height, groupRef.current, tipRef.current, currencySymbol])

  return (
    <div className="flex">
      <div className="medium-9 columns padding-right-0">
        <div className="wrap wrap--graph wrap--line-graph" ref={ref}>
          <svg
            width={width + margin.left + margin.right}
            height={height + margin.top + margin.bottom}
          >
            <g ref={groupRef}></g>
          </svg>
          <ToolTip
            data={data}
            rootX={boundingBox.x + margin.left}
            rootY={boundingBox.y + margin.top}
            x={toolTip.x}
            y={toolTip.y}
            d={toolTip.d}
          />
        </div>
      </div>
      {data && (
        <div className="medium-3 columns subdue-for-loading">
          <ul className="list--totals list--revenue-totals">
            {data.lines.map((title, index) => (
              <li
                className="list__item list__item--graph-total"
                key={`${index}-${title}`}
              >
                <span
                  className={`legend make-inline-block legend-0${index + 1}`}
                />{' '}
                <dl className="list--graph-text">
                  <dt className="list__item--graph-title">{title}</dt>
                  <dd className="list__item--graph-value">
                    <Currency value={data.totals[title]} />
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      )}
      {data && data.buckets.length === 0 && (
        <div className="overlay overlay--no-data">
          <dl className="list list--no-data">
            <dt className="list__title margin-bottom-0">
              <strong>No order data to display</strong>
            </dt>
            <dd className="list__item list__item--sm">
              Adjust the filters or date range and try again
            </dd>
          </dl>
        </div>
      )}
    </div>
  )
}

MoneyOverTimeGraph.propTypes = {
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        date: DateShape,
      }),
    ).isRequired,
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
    totals: PropTypes.shape({}).isRequired,
  }),
  startDate: DateShape,
  endDate: DateShape,
}
