import {createContext, useContext} from 'react'

const LabelConfigContext = createContext({
  labelInfoID: null,
  onChange: null,
  shipperType: null,
  isLabelEnableMode: false,
})

export default LabelConfigContext

export function useLabelConfigContext() {
  return useContext(LabelConfigContext)
}
