import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

import {UserShape, UserFormShape} from '../../../common/PropTypes.js'
import Permissions from './Permissions.js'
import {showResetPasswordModal} from './ResetPasswordModal.js'
import {showArchiveUserModal} from './ArchiveUserModal.js'

export default class EditForm extends Component {
  static propTypes = {
    generalError: PropTypes.string.isRequired,
    user: UserShape.isRequired,
    userForm: UserFormShape.isRequired,
    isSaving: PropTypes.bool.isRequired,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    hasErrors: PropTypes.bool.isRequired,
    isChangingPassword: PropTypes.bool.isRequired,
    meID: PropTypes.number.isRequired,
    canManageUser: PropTypes.bool.isRequired,
    usePermissions: PropTypes.bool.isRequired,
    updateUserForm: PropTypes.func.isRequired,
    updatePermissions: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    toggleChangePassword: PropTypes.func.isRequired,
  }

  onNameChange = (event) =>
    this.props.updateUserForm({name: event.target.value})
  onEmailChange = (event) =>
    this.props.updateUserForm({email: event.target.value})
  onPasswordChange = (event) =>
    this.props.updateUserForm({password: event.target.value})
  onPasswordConfirmChange = (event) =>
    this.props.updateUserForm({passwordConfirm: event.target.value})

  render() {
    const {
      generalError,
      user,
      userForm,
      isSaving,
      errors,
      hasErrors,
      isChangingPassword,
      meID,
      canManageUser,
      usePermissions,
      saveUser,
      toggleChangePassword,
    } = this.props
    let editLink
    let isExpanded = false

    if (canManageUser || meID === user.id) {
      editLink = `#/settings${user._link}`
      isExpanded = user._link === userForm.link
    }

    return (
      <li
        className={classNames('settings-list-item clearfix', {
          expanded: isExpanded,
        })}
      >
        <strong className="settings-list-item-title medium-5 small-8 columns">
          {user.name}
        </strong>
        <span className="settings-list-item-desc medium-5 hide-for-small columns">
          {user.email}
        </span>
        <span className="medium-2 small-4 columns right-aligned-text right">
          {editLink && (
            <a
              type="button"
              className="inline-text-button settings-list-item-button"
              href={editLink}
            >
              <span
                className="icon icon-edit icon-block x-sm margin-right-2"
                aria-hidden="true"
              />
              <span className="button-text">Edit</span>
            </a>
          )}
        </span>
        {isExpanded && (
          <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
            <fieldset>
              {generalError && (
                <div className="row">
                  <ul className="medium-5 columns error-list">
                    <li>{generalError}</li>
                  </ul>
                </div>
              )}
              <ul className="form-list medium-5 columns margin-left-0">
                <li
                  className={classNames(
                    'form-list-item bigger margin-bottom-20',
                    {error: errors.name},
                  )}
                >
                  <label htmlFor="user_name">
                    Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    value={userForm.name}
                    onChange={this.onNameChange}
                  />
                  {errors.name && (
                    <small className="error error-message">{errors.name}</small>
                  )}
                </li>
                <li
                  className={classNames(
                    'form-list-item bigger margin-bottom-25',
                    {error: errors.email},
                  )}
                >
                  <label htmlFor="user_email">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    name="user_email"
                    value={userForm.email}
                    onChange={this.onEmailChange}
                  />
                  {errors.email && (
                    <small className="error error-message">
                      {errors.email}
                    </small>
                  )}
                </li>
                {usePermissions && (
                  <li className="form-list-item margin-bottom-20">
                    <label htmlFor="permission_can_manage_user">
                      <input
                        type="checkbox"
                        className="margin-bottom-0 margin-right-3"
                        id="permission_can_manage_user"
                        checked={userForm.permissions.can_manage_user}
                        onChange={(event) =>
                          this.props.updatePermissions({
                            can_manage_user: event.target.checked,
                          })
                        }
                        disabled={!(canManageUser && meID !== user.id)}
                      />
                      <span
                        className={classNames({
                          'op-50': !(canManageUser && meID !== user.id),
                        })}
                      >
                        Admin
                      </span>
                    </label>
                  </li>
                )}
                {meID === user.id ? (
                  <>
                    <li className="form-list-item margin-bottom-20">
                      <button
                        type="button"
                        className="btn btn--primary btn--primary-ol btn--sm"
                        onClick={toggleChangePassword}
                      >
                        <strong>Change password</strong>
                      </button>
                    </li>
                    {isChangingPassword && (
                      <li
                        className={classNames(
                          'form-list-item bigger margin-bottom-20',
                          {error: errors.password},
                        )}
                      >
                        <label htmlFor="user_password">
                          Password<span className="required">*</span>
                        </label>
                        <input
                          type="password"
                          name="user_password"
                          value={userForm.password}
                          onChange={this.onPasswordChange}
                        />
                        {errors.password && (
                          <small className="error error-message">
                            {errors.password}
                          </small>
                        )}
                      </li>
                    )}
                    {isChangingPassword && (
                      <li
                        className={classNames(
                          'form-list-item bigger margin-bottom-15',
                          {error: errors.passwordConfirm},
                        )}
                      >
                        <label htmlFor="user_passwordConfirm">
                          Retype New Password<span className="required">*</span>
                        </label>
                        <input
                          type="password"
                          name="user_passwordConfirm"
                          value={userForm.passwordConfirm}
                          onChange={this.onPasswordConfirmChange}
                        />
                        {errors.passwordConfirm && (
                          <small className="error error-message">
                            {errors.passwordConfirm}
                          </small>
                        )}
                      </li>
                    )}
                  </>
                ) : (
                  <li className="form-list-item margin-bottom-20">
                    <button
                      type="button"
                      className="btn btn--primary btn--primary-ol btn--sm"
                      onClick={() => showResetPasswordModal(user.email)}
                    >
                      <strong>Reset password</strong>
                    </button>
                  </li>
                )}
                {usePermissions && canManageUser && meID !== user.id && (
                  <li className="form-list-item margin-bottom-20">
                    <button
                      type="button"
                      className="btn btn--primary btn--primary-ol btn--sm"
                      onClick={() => showArchiveUserModal(user.id)}
                    >
                      <strong>Archive User</strong>
                    </button>
                  </li>
                )}
              </ul>
              {usePermissions && canManageUser && meID !== user.id && (
                <div className="clearfix clear-both">
                  <Permissions
                    permissions={userForm.permissions}
                    updatePermissions={this.props.updatePermissions}
                  />
                </div>
              )}
              <div className="clearfix clear-both">
                <div className="medium-7 columns">
                  <div className="divider--top padding-top-20">
                    <button
                      type="button"
                      className={classNames('btn btn--primary', {
                        'btn--disabled': hasErrors,
                        'btn--processing': isSaving,
                      })}
                      disabled={hasErrors || isSaving}
                      onClick={saveUser}
                    >
                      Save
                    </button>
                    <a
                      href="#/settings/user"
                      className="btn btn--secondary"
                      type="button"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        )}
      </li>
    )
  }
}
