import PropTypes from 'prop-types'
import {useCallback} from 'react'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default function EditForm(props) {
  const data = props.data
  const updatePayload = useCallback(
    (payloadDelta) => props.updatePayload(data.index, payloadDelta),
    [data.index],
  )
  const updateOrderNumber = useCallback(
    (order_numbers, order_number, index) => {
      order_numbers = [...order_numbers]
      order_numbers[index] = order_number

      updatePayload({order_numbers})
    },
    [updatePayload],
  )

  return (
    <BaseEditForm className="medium-5 columns" {...props}>
      <Row>
        <TextInput
          value={data.payload.reference_id}
          errorMessage={data.errors.reference_id}
          name="reference_id"
          label="Batch ID"
          className="medium-12"
          onChange={(value) => updatePayload({reference_id: value})}
        />
      </Row>
      {data.payload.order_numbers.map((order_number, index) => (
        <Row key={index}>
          <TextInput
            value={order_number}
            name="order_number"
            label="Order Number"
            className="medium-12"
            onChange={(value) =>
              updateOrderNumber(data.payload.order_numbers, value, index)
            }
          />
        </Row>
      ))}
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
