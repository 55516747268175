import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'

const MODAL_FORM = 'EDIT_MOCK_MOS_FORM'

export function showEditMockMOsModal() {
  setForm(MODAL_FORM, {
    referenceIDsText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockMOsModal({form}) {
  const query = useSelector(querySelector)
  const referenceIDs = ensureArray(query.reference_id)

  useEffect(() => {
    updateModalForm({
      referenceIDsText: referenceIDs.join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Reference IDs"
      onConfirm={() => {
        const referenceIDs = form.referenceIDsText
          .replace(/[, ]/g, '\n')
          .split('\n')
          .filter((referenceID) => !!referenceID)

        mockAbodeUpdateResourceQuery({
          reference_id: referenceIDs,
        })

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock MFG Order Reference Numbers</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="MFG Order Reference Numbers"
            rows="10"
            id="reference_ids"
            value={form.referenceIDsText || ''}
            onChange={(value) => updateModalForm({referenceIDsText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockMOsModal.propTypes = {
  form: PropTypes.shape({
    referenceIDsText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockMOsModal, modalFormSelector)
