import classNames from 'classnames'

import {useSelector} from '../../store.js'
import {tabsSelector} from './headerSelectors.js'

export default function NavTabs() {
  const tabs = useSelector(tabsSelector)

  return (
    <div className="wrap--main-nav-links">
      {tabs.map((tab) => (
        <a
          className={classNames('main-nav__link', {active: tab.selected})}
          title={tab.display}
          href={tab.url}
          key={tab.display}
        >
          <span>{tab.display}</span>
          {!!tab.attention && (
            <span className="main-nav__count margin-left-4">
              {tab.attention}
            </span>
          )}
        </a>
      ))}
    </div>
  )
}
