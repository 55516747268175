import {dispatch, useSelector} from '../../../../../store.js'
import {HK_PACK_SHIP_START} from '../../../../../common/constants/HotKeys.js'
import ButtonLink from '../../../../../common/components/Button/ButtonLink.js'
import HeaderDropdown from '../../../../../common/components/List/HeaderDropdown.js'
import {navigateToPackShip} from '../../../../../redux/actions/ui/packShipPage/index.js'
import {canUsePackShipSelector} from '../../../../../data/company.js'
import {showPickOrdersModal} from '../../../../PackShipPage/Modals/PickOrdersModal.js'
import {showUpgradeModal} from '../../../../Modals/UpgradeModal.js'
import {selectedOrderNumbersSelector} from '../../../../OrderListPage/orderListSelectors.js'

export default function BarcodeDropdown() {
  const canUsePackShip = useSelector(canUsePackShipSelector)
  const selectedOrderNumbers = useSelector(selectedOrderNumbersSelector)

  if (!canUsePackShip) {
    return (
      <li
        className="inline-block tooltip--top"
        data-hint="Barcode scanning available by upgrading your plan"
      >
        <ButtonLink
          className="no-underline meta-orderlistheader-button-scan-verify-disabled"
          onClick={() => showUpgradeModal()}
        >
          <span
            className="i-barcode-05 fs-02 lh-0 v-align-middle margin-left-1 op-30"
            aria-hidden="true"
          />
        </ButtonLink>
      </li>
    )
  }

  return (
    <HeaderDropdown
      className="margin-left-0-imp"
      label={
        <span
          className="i-barcode-05 fs-02 lh-0 op-75 v-align-middle margin-left-1"
          aria-hidden="true"
        />
      }
      dropdown="BARCODE_DROPDOWN"
    >
      {selectedOrderNumbers.length > 0 && (
        <li className="list__item list__item--dropdown">
          <ButtonLink
            className="list__link--dropdown"
            onClick={() => showPickOrdersModal(selectedOrderNumbers)}
          >
            PICK Items
          </ButtonLink>
        </li>
      )}
      <li className="list__item list__item--dropdown">
        <ButtonLink
          className="list__link--dropdown"
          onClick={() => dispatch(navigateToPackShip(selectedOrderNumbers))}
          hotKey={HK_PACK_SHIP_START}
        >
          PACK Items (Verify Contents)
        </ButtonLink>
      </li>
    </HeaderDropdown>
  )
}
