export function hasClass(element, className, stopAtClassName) {
  while (element) {
    if (element.classList.contains(className)) {
      return true
    }

    if (stopAtClassName && element.classList.contains(stopAtClassName)) {
      break
    }

    element = element.parentElement
  }

  return false
}
