import {createSelector} from 'reselect'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {companyIDSelector} from '../../../data/company.js'

export function packingListsSelector(state) {
  return state.data.packingLists
}

export function packingListSelector(state, {packingListID}) {
  return packingListsSelector(state)[packingListID]
}

export function getPackingListName(packingList) {
  return get(packingList, 'name')
}

export function packingListNameSelector(state, {packingListID}) {
  const packingList = packingListSelector(state, {packingListID})

  return getPackingListName(packingList)
}

export const defaultPackingListSelector = createSelector(
  packingListsSelector,
  (packingLists) =>
    Object.values(packingLists).find((packingList) => packingList.is_default),
)

export const defaultPackingListIDSelector = createSelector(
  defaultPackingListSelector,
  (packingList) => (packingList ? packingList.id : null),
)

export function needsPackingListLogoSelector(state) {
  const logoURL = packingListLogoURLSelector(state, {
    packingListID: defaultPackingListIDSelector(state),
  })

  return !logoURL
}

export const sortedPackingListsByIDSelector = createSelector(
  packingListsSelector,
  (packingLists) =>
    sortBy(packingLists, 'id').filter(
      (packingList) => !packingList.archive_date,
    ),
)

export const atLeastOneLogoHasBeenSetupSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) =>
    !!packingLists.find((packingList) => getPackingListLogoURL(packingList)),
)

export const firstPackingListSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) => packingLists[0],
)

export function getPackingListLogoURL({logo_hash, _linklogo}, companyID) {
  if (logo_hash) {
    return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_ABODE_URL}/image/${companyID}/${logo_hash}?width=300&type=png`
  }

  if (_linklogo) {
    return _linklogo
  }

  return null
}

export const packingListOptionsSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) =>
    packingLists.map((packingList) => ({
      value: packingList.id,
      display: packingList.name,
    })),
)

export function packingListLogoURLSelector(state, {packingListID}) {
  const packingList = packingListSelector(state, {packingListID})
  const companyID = companyIDSelector(state)

  if (!packingList) {
    return null
  }

  return getPackingListLogoURL(packingList, companyID)
}

export function packingListsWithLogoURLsSelector(state) {
  const packingLists = sortedPackingListsByIDSelector(state)
  const companyID = companyIDSelector(state)

  return packingLists.map((packingList) => ({
    ...packingList,
    logoURL: getPackingListLogoURL(packingList, companyID),
  }))
}
