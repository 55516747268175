import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {usePermissionsSelector} from '../../../data/company.js'
import {canManageUserPermissionSelector, meSelector} from '../../../data/me.js'
import {
  activeUsersSortedByNameSelector,
  usersHaveLoadedSelector,
} from '../../../data/users.js'
import {
  setupForEdit,
  setupForAdding,
  saveUser,
  updateUserForm,
  updatePermissions,
  toggleChangePassword,
} from '../../../redux/actions/ui/settings/users.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
  topLevelErrorSelector,
} from '../../../redux/selectors/ui/settings/users.js'
import {
  UsersShape,
  ErrorsShape,
  UserFormShape,
} from '../../../common/PropTypes.js'
import SettingsPanel from '../SettingsPanel.js'

import List from './List.js'

export function Users(props) {
  const {
    hasLoaded,
    users,
    generalError,
    editLink,
    isNew,
    userForm,
    isSaving,
    isChangingPassword,
    errors,
    hasErrors,
    meID,
    canManageUser,
    usePermissions,
  } = props

  return (
    <SettingsPanel header="User Settings">
      <div className="row">
        {hasLoaded && (
          <List
            users={users}
            generalError={generalError}
            editLink={editLink}
            isNew={isNew}
            userForm={userForm}
            isSaving={isSaving}
            isChangingPassword={isChangingPassword}
            errors={errors}
            hasErrors={hasErrors}
            meID={meID}
            canManageUser={canManageUser}
            usePermissions={usePermissions}
            updateUserForm={props.updateUserForm}
            updatePermissions={props.updatePermissions}
            setupForEdit={props.setupForEdit}
            setupForAdding={props.setupForAdding}
            saveUser={props.saveUser}
            toggleChangePassword={props.toggleChangePassword}
          />
        )}
      </div>
    </SettingsPanel>
  )
}

Users.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  users: UsersShape.isRequired,
  generalError: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  userForm: UserFormShape.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  meID: PropTypes.number.isRequired,
  canManageUser: PropTypes.bool.isRequired,
  usePermissions: PropTypes.bool.isRequired,
  updateUserForm: PropTypes.func.isRequired,
  updatePermissions: PropTypes.func.isRequired,
  setupForEdit: PropTypes.func.isRequired,
  setupForAdding: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  toggleChangePassword: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    hasLoaded: usersHaveLoadedSelector(state),
    users: activeUsersSortedByNameSelector(state),
    generalError: topLevelErrorSelector(state, props),
    editLink: state.ui.settings.users.editLink,
    isNew: state.ui.settings.users.isNew,
    userForm: state.ui.settings.users.userForm,
    isSaving: state.ui.settings.users.isSaving,
    isChangingPassword: state.ui.settings.users.isChangingPassword,
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
    meID: meSelector(state).id,
    canManageUser: canManageUserPermissionSelector(state),
    usePermissions: usePermissionsSelector(state),
  }
}

const mapDispatchToProps = {
  updateUserForm,
  updatePermissions,
  setupForEdit,
  setupForAdding,
  saveUser,
  toggleChangePassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
