import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase.js'
import get from 'lodash/get.js'

import {OrderLineShape} from '../../../../../../common/PropTypes.js'
import {
  PRODUCT_DETAIL_KITTING_PANEL,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../../../../../common/constants/Products.js'

import {
  preferCartOrderLineNamesSelector,
  usesInventorySelector,
  useInventoryAllocationSelector,
} from '../../../../../../data/company.js'
import {
  getOrderLineShippabilityHint,
  orderLineAllocationHintSelector,
  orderLineIsDropshipHintSelector,
} from '../../../../../../data/orders.js'
import {productSelector} from '../../../../../../data/products.js'
import {hasProductPermissionSelector} from '../../../../../../data/me.js'

import Icon from './Icon.js'
import LineAllocationIcon from '../../Fields/LineAllocationIcon.js'
import LinkButton from '../../../../../../common/components/Link/LinkButton.js'

class Line extends Component {
  static propTypes = {
    orderNumber: PropTypes.string.isRequired,
    line: OrderLineShape.isRequired,
    preferCartOrderLineNames: PropTypes.bool.isRequired,
    usesInventory: PropTypes.bool.isRequired,
    hasProductPermission: PropTypes.bool.isRequired,
    shippabilityHint: PropTypes.string,
    isDropshipHint: PropTypes.string,
    useInventoryAllocation: PropTypes.bool.isRequired,
    allocationHint: PropTypes.string,
    isKit: PropTypes.bool.isRequired,
  }

  renderDetails() {
    const {hasProductPermission, line, preferCartOrderLineNames, isKit} =
      this.props

    const details = (
      <div>
        <strong className="block">
          {preferCartOrderLineNames && line.order_line_product_name
            ? line.order_line_product_name
            : line.product_name}
        </strong>
        <span className="unbold block">{line.sku}</span>
        {line.details && <em className="block">{line.details}</em>}
      </div>
    )

    return (
      <div>
        {hasProductPermission ? (
          <a
            className="link--line-detail inline-block"
            href={`#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(
              line.sku,
            )}`}
          >
            {details}
          </a>
        ) : (
          details
        )}
        {isKit && (
          <div className="margin-top-3">
            {hasProductPermission ? (
              <LinkButton
                className="label__callout label__callout--grey no-underline"
                href={`#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(
                  line.sku,
                )}/${PRODUCT_DETAIL_KITTING_PANEL}`}
              >
                <strong>KIT</strong>
              </LinkButton>
            ) : (
              <strong>KIT</strong>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const className = classNames('list__item--line', {
      [kebabCase(this.props.line.shippability.shippability)]:
        this.props.usesInventory && !this.props.useInventoryAllocation,
      [kebabCase(this.props.line.line_allocation_status)]:
        this.props.useInventoryAllocation,
      dropshippable:
        this.props.line.shippability.is_dropship &&
        !this.props.useInventoryAllocation,
    })

    return (
      <li className={className}>
        <dl className="list--line-details flex--justify-nowrap">
          <dt className="list__title--line-detail flex">
            {this.props.useInventoryAllocation && (
              <LineAllocationIcon
                allocationStatus={this.props.line.line_allocation_status}
                allocationHint={this.props.allocationHint}
                isDropship={this.props.line.shippability.is_dropship}
                isDropshipHint={this.props.isDropshipHint}
                className="inline-block fs-n0 margin-right-3 compact"
              />
            )}
            {this.props.usesInventory && !this.props.useInventoryAllocation && (
              <Icon
                iconClassName="i--shippability"
                hint={this.props.shippabilityHint}
              />
            )}
            {this.renderDetails()}
          </dt>
          <dd className="list__item--line-detail list__item--line-qty flex-shrink-0">
            <strong>{this.props.line.quantity}</strong>
          </dd>
        </dl>
      </li>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    preferCartOrderLineNames: preferCartOrderLineNamesSelector(state),
    usesInventory: usesInventorySelector(state),
    hasProductPermission: hasProductPermissionSelector(state),
    shippabilityHint: getOrderLineShippabilityHint(state, props.line),
    allocationHint: orderLineAllocationHintSelector(state, {
      orderNumber: props.orderNumber,
      orderLineID: props.line.id,
    }),
    isDropshipHint: orderLineIsDropshipHintSelector(state, {
      orderNumber: props.orderNumber,
      orderLineID: props.line.id,
    }),
    isKit:
      get(productSelector(state, {sku: props.line.sku}), 'kit_components', [])
        .length > 0,
    useInventoryAllocation: useInventoryAllocationSelector(state),
  }
}

export default connect(mapStateToProps)(Line)
