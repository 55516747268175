import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  HK_PACK_SHIP_BACK,
  HK_PACK_SHIP_NEXT,
} from '../../common/constants/HotKeys.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {navigate} from '../../redux/actions/ui/index.js'
import {
  packShipOrderNumbersSelector,
  indexSelector,
  backURLSelector,
  nextURLSelector,
  verifiedOrderCountSelector,
} from '../../redux/selectors/ui/packShipPage/index.js'

function NextBackCounter({
  orderNumbers,
  index,
  backURL,
  nextURL,
  verifiedOrderCount,
  ...props
}) {
  if (orderNumbers.length < 2) {
    return null
  }

  return (
    <div className="wrap--row margin-top-10">
      <div className="medium-6 medium-centered columns align-center">
        <ul className="list list--no-style">
          <li className="list__item--inline-block fs-01 v-align-top margin-right-20 margin-bottom-0">
            <ButtonPrimary
              isOutlined
              className="btn--x-sm"
              isDisabled={!backURL}
              onClick={() => props.navigate(backURL)}
              hotKey={HK_PACK_SHIP_BACK}
            >
              ◀
            </ButtonPrimary>
          </li>
          <li className="list__item--inline-block fs-01 v-align-top margin-right-20 margin-bottom-0">
            <div className="lh-sm">
              <strong>
                {index + 1} of {orderNumbers.length} Orders
              </strong>
            </div>
            <div className="fs-00">({verifiedOrderCount} verified)</div>
          </li>
          <li className="list__item--inline-block v-align-top fs-01 margin-bottom-0">
            <ButtonPrimary
              isOutlined
              className="btn--x-sm"
              isDisabled={!nextURL}
              onClick={() => props.navigate(nextURL)}
              hotKey={HK_PACK_SHIP_NEXT}
            >
              ▶
            </ButtonPrimary>
          </li>
        </ul>
      </div>
    </div>
  )
}

NextBackCounter.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  backURL: PropTypes.string,
  nextURL: PropTypes.string,
  verifiedOrderCount: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    orderNumbers: packShipOrderNumbersSelector(state),
    index: indexSelector(state),
    backURL: backURLSelector(state),
    nextURL: nextURLSelector(state),
    verifiedOrderCount: verifiedOrderCountSelector(state),
  }
}

const mapDispatchToProps = {
  navigate,
}

export default connect(mapStateToProps, mapDispatchToProps)(NextBackCounter)
