import get from 'lodash/get.js'
import ShipperOptions from '@ordoro/shipper-options'

import {UPS} from '../common/constants/ShipperNames.js'

export default ShipperOptions

export const SHIPPER_NAMES_BY_TYPE = Object.keys(ShipperOptions).reduce(
  (prev, shipperType) => {
    const shipperOption = ShipperOptions[shipperType]

    prev[shipperType] = shipperOption.display_name

    return prev
  },
  {},
)

export const SHIPPING_METHOD_NAME_BY_TYPE_AND_CODE = Object.keys(
  ShipperOptions,
).reduce((prev, shipperType) => {
  const shipperOption = ShipperOptions[shipperType]

  prev[shipperType] = get(shipperOption, 'shipping_method', []).reduce(
    (prev1, {value, display}) => {
      prev1[value] = display

      return prev1
    },
    {},
  )

  return prev
}, {})

export function shipperOptionSelector(state, {shipperType}) {
  return ShipperOptions[shipperType]
}

export function shipperDisplayNameSelector(state, {shipperType}) {
  const shipperOption = shipperOptionSelector(state, {shipperType})

  return shipperOption ? shipperOption.display_name : shipperType
}

export function shippingMethodSelector(state, {shipperType, methodCode}) {
  const shipperOption = shipperOptionSelector(state, {shipperType})

  if (!shipperOption) {
    return null
  }

  const shippingMethods =
    shipperType === UPS
      ? [
          ...shipperOption.shipping_method,
          ...shipperOption.shipping_method_mail_innovations,
        ]
      : shipperOption.shipping_method

  return shippingMethods.find(({value}) => methodCode === value)
}

export function shippingMethodNameSelector(state, {shipperType, methodCode}) {
  const method = shippingMethodSelector(state, {shipperType, methodCode})

  return method ? method.display : methodCode
}

export function boxShapesSelector(state, {shipperType}) {
  const shipperOption = shipperOptionSelector(state, {shipperType})

  if (shipperType === UPS) {
    return [
      ...(shipperOption.box_shape_mail_innovations || []),
      ...(shipperOption.box_shape || []),
    ]
  }

  return shipperOption.box_shape || []
}

export function boxShapeOptionSelector(state, {shipperType, boxShape}) {
  const boxShapes = boxShapesSelector(state, {shipperType})

  return boxShapes.find(({value}) => value === boxShape)
}

export function boxShapeNameSelector(state, {shipperType, boxShape}) {
  return (
    get(boxShapeOptionSelector(state, {shipperType, boxShape}), 'display') ||
    null
  )
}
