import PropTypes from 'prop-types'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase.js'

import {OrderShape} from '../../../../../common/PropTypes.js'
import Currency from '../../../../../common/components/Currency.js'
import FormattedDate from '../../../../../common/components/FormattedDate.js'

import {
  getIsAnUnsplitOrder,
  getIsMerged,
  getDeliverByDate,
  getShipByDate,
  showOrderShippingAddressModal,
  getBatchOrderErrors,
  isAmazonOrderSelector,
} from '../../../../../data/orders.js'
import OrderListAddress from '../Fields/OrderListAddress.js'
import OrderTags from '../Fields/OrderTags.js'
import ParentOrder from '../Fields/ParentOrder.js'
import ShippabilityIcon from '../Fields/ShippabilityIcon.js'
import OrderStatus from '../Fields/OrderStatus.js'
import OrderCart from '../Fields/OrderCart.js'

import {
  showOrderCustomerNotesModal,
  showOrderInternalNotesModal,
} from '../../../../OrderListPage/Modals/OrderTextFieldModal.js'
import {showAddOrderCommentModal} from '../../../../OrderListPage/Modals/AddOrderCommentModal.js'
import OrderData from './OrderData/index.js'
import OrderLines from './OrderLines/index.js'
import DeliverByDate from '../Fields/DeliverByDate.js'
import ShipByDate from '../Fields/ShipByDate.js'
import {useSelector} from '../../../../../store.js'
import {hasOrderFinancialsPermissionSelector} from '../../../../../data/me.js'
import {toggleExpanded} from '../../../../OrderListPage/orderListActions.js'
import {buildBatchDetailURL} from '../../../../BatchListPage/batchListSelectors.js'
import Link from '../../../../../common/components/Link/Link.js'
import {orderListHashBuilder} from '../../../../OrderListPage/orderListSelectors.js'
import {AWAITING_FULFILLMENT} from '../../../../../common/constants/Orders.js'

export function ExpandedRow({
  showHeader,
  isExpanded,
  isSelected,
  order,
  orderNumber,
  status,
  isPrimeOrder,
  orderAddressCanBeEdited,
  selectOrder,
  useInventoryAllocation,
}) {
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )
  const isAmazonOrder = useSelector((state) =>
    isAmazonOrderSelector(state, {orderNumber}),
  )
  const orderHasBeenSplit = !getIsAnUnsplitOrder(order)

  const orderHasBeenMerged = getIsMerged(order)
  const deliverByDate = getDeliverByDate(order)
  const shipByDate = getShipByDate(order)
  const batchDetailURL = order.batch_reference_id
    ? buildBatchDetailURL(order.batch_reference_id)
    : null
  const batchErrors = getBatchOrderErrors(order)

  return (
    <div className="wrap--expanded-order">
      {!showHeader && <hr className="hr--order-header margin-top-0" />}
      <div className="wrap--row margin-top-10 flex">
        <div className="medium-7 columns wrap--order-header">
          {showHeader && (
            <div>
              <dl className="list list--order-header">
                <dd className="list__item--order-header list__item--order-checkbox margin-bottom-0">
                  <input
                    className="inline-block checkbox--order"
                    type="checkbox"
                    checked={isSelected}
                    onChange={selectOrder}
                  />
                </dd>
                <dt className="list__title--order-header margin-bottom-0">
                  <a
                    className="link--order-header"
                    href={`#/order/${encodeURIComponent(orderNumber)}`}
                  >
                    {orderNumber}
                  </a>
                </dt>
              </dl>
              {order.tags.length > 0 && (
                <dl className="list list--order-header">
                  <dd className="list__item--order-data">
                    <OrderTags order={order} />
                  </dd>
                </dl>
              )}
              <hr className="hr--order-header margin-bottom-10" />
            </div>
          )}
          <div className="wrap--row flex">
            <div className="medium-7 columns wrap__list--order-data flex">
              {useInventoryAllocation || (
                <ShippabilityIcon
                  shippability={order.shippability}
                  className="flex"
                />
              )}
              <div className="flex-grow w-100">
                <dl
                  className={classNames(
                    'list list--order-data list--order-summary',
                    useInventoryAllocation
                      ? null
                      : kebabCase(order.shippability),
                  )}
                >
                  <dt className="list__title--order-data">Order Summary</dt>
                  <dd className="list__item--order-data">
                    <strong className="margin-right-3">Ordered:</strong>
                    <span>
                      <FormattedDate
                        value={order.order_placed_date}
                        format="MMM D, YYYY"
                      />
                      {' at '}
                      <FormattedDate
                        value={order.order_placed_date}
                        format="h:mma"
                      />
                    </span>
                  </dd>
                  {batchDetailURL && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Batch:</strong>
                      <span>
                        <Link className="v-align-base" href={batchDetailURL}>
                          {order.batch_reference_id}
                        </Link>
                      </span>
                    </dd>
                  )}
                  {status.length !== 1 && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Status:</strong>
                      <span>
                        <OrderStatus order={order} />
                      </span>
                    </dd>
                  )}
                  <dd className="list__item--order-data">
                    <strong className="margin-right-3">Sales Channel:</strong>
                    <OrderCart orderNumber={orderNumber} />
                  </dd>
                  {order.financial.credit_card_issuer && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">
                        Credit Card Issuer:
                      </strong>
                      <span>{order.financial.credit_card_issuer}</span>
                    </dd>
                  )}
                  {shipByDate && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Ship By:</strong>
                      <span>
                        <ShipByDate
                          date={shipByDate}
                          isAmazonDate={isAmazonOrder}
                        />
                      </span>
                    </dd>
                  )}
                  {deliverByDate && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Deliver By:</strong>
                      <span>
                        <DeliverByDate
                          date={deliverByDate}
                          isAmazonDate={isAmazonOrder}
                        />
                      </span>
                    </dd>
                  )}
                  {hasOrderFinancialsPermission && (
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Total:</strong>
                      <span>
                        <Currency value={order.financial.grand_total} />
                      </span>
                    </dd>
                  )}
                </dl>

                {orderHasBeenSplit && (
                  <dl className="list list--order-data list--order-items divider--top ">
                    <dd className="list__item--order-data">
                      <ParentOrder
                        orderNumber={order.order_number}
                        parentOrderNumber={order.parent_order_number}
                      />
                    </dd>
                  </dl>
                )}
                {batchDetailURL && (
                  <dl className="list list--order-data list--order-items divider--top mid">
                    <dt className="list__title--order-data">Batch Info</dt>
                    <dd className="list__item--order-data">
                      <strong className="margin-right-3">Batch ID:</strong>
                      <span>
                        <Link href={batchDetailURL}>
                          {order.batch_reference_id}
                        </Link>
                      </span>
                    </dd>
                    {batchErrors.length > 0 && (
                      <dd className="list__item--order-data error">
                        <div>
                          <strong>Batch Action Errors:</strong>
                        </div>
                        <ul className="list list--square">
                          {batchErrors.map((error, index) => (
                            <li
                              className="list__item fs-n1 margin-top-3 margin-bottom-0"
                              key={index}
                            >
                              {error}
                            </li>
                          ))}
                        </ul>
                      </dd>
                    )}
                  </dl>
                )}
                <dl className="list list--order-data list--order-items divider--top divider--bottom mid">
                  <dt className="list__title--order-data">Order Contents</dt>
                  <dd className="list__item--order-data list__item--additional-info">
                    <OrderLines order={order} isExpanded={isExpanded} />
                  </dd>
                </dl>

                {!showHeader && order.tags.length > 0 && (
                  <dl className="list list--order-data list--order-items divider--bottom mid">
                    <dt className="list__title--order-data">Tags</dt>
                    <dd className="list__item--order-data">
                      <OrderTags order={order} />
                    </dd>
                  </dl>
                )}

                <dl className="list list--order-data list--order-items divider--bottom mid">
                  <dt className="list__title--order-data">Customer Notes</dt>
                  {order.notes_from_customer ? (
                    <dd className="list__item--order-data">
                      <div className="ws-pre-wrap">
                        {order.notes_from_customer}
                      </div>
                      {!orderHasBeenMerged && (
                        <button
                          className="btn btn--link meta-orderlistrow-button-edit-customer-note"
                          type="button"
                          onClick={() =>
                            showOrderCustomerNotesModal(orderNumber)
                          }
                        >
                          Edit
                        </button>
                      )}
                    </dd>
                  ) : (
                    <dd className="list__item--order-data">
                      {!orderHasBeenMerged && (
                        <button
                          className="btn btn--link meta-orderlistrow-button-add-customer-note"
                          type="button"
                          onClick={() =>
                            showOrderCustomerNotesModal(orderNumber)
                          }
                        >
                          Add note
                        </button>
                      )}
                    </dd>
                  )}
                </dl>
                <dl className="list list--order-data list--order-items divider--bottom mid">
                  <dt className="list__title--order-data">Internal Notes</dt>
                  {order.internal_notes ? (
                    <dd className="list__item--order-data">
                      <div className="ws-pre-wrap">{order.internal_notes}</div>
                      {!orderHasBeenMerged && (
                        <button
                          className="btn btn--link meta-orderlistrow-button-edit-customer-note"
                          type="button"
                          onClick={() =>
                            showOrderInternalNotesModal(orderNumber)
                          }
                        >
                          Edit
                        </button>
                      )}
                    </dd>
                  ) : (
                    <dd className="list__item--order-data">
                      {!orderHasBeenMerged && (
                        <button
                          className="btn btn--link meta-orderlistrow-button-add-customer-note"
                          type="button"
                          onClick={() =>
                            showOrderInternalNotesModal(orderNumber)
                          }
                        >
                          Add note
                        </button>
                      )}
                    </dd>
                  )}
                </dl>
                <div className="list__item--order-data fs-n1 flex--justify">
                  {!orderHasBeenMerged ? (
                    <button
                      className="btn btn--link"
                      type="button"
                      onClick={() => showAddOrderCommentModal(orderNumber)}
                    >
                      Add a comment
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    className="btn btn--link no-underline"
                    type="button"
                    onClick={() => toggleExpanded(orderNumber)}
                  >
                    <span className="i--expand fs-01 lh-0" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="medium-5 columns wrap__list--order-data flex">
              <div>
                <OrderListAddress
                  className="list--order-shipto"
                  title="Ship-to"
                  addressType="shipping"
                  address={order.shipping_address}
                  onEdit={() =>
                    showOrderShippingAddressModal(order.order_number)
                  }
                  showAll={isExpanded}
                  canEdit={
                    !isPrimeOrder &&
                    !orderHasBeenMerged &&
                    orderAddressCanBeEdited
                  }
                />
                {order.similar_open_addresses_count > 0 && (
                  <div className="divider--top">
                    <a
                      className="btn btn--link fs-n1 block"
                      href={orderListHashBuilder({
                        status: [AWAITING_FULFILLMENT],
                        similarAddressTo: order.order_number,
                      })}
                    >
                      Show orders with similar Ship-To (
                      {order.similar_open_addresses_count - 1})
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="medium-5 columns wrap__list--order-data padding-right-0">
          <OrderData
            orderNumber={orderNumber}
            isExpanded={isExpanded}
            parentView="ExpandedRow"
          />
        </div>
      </div>
    </div>
  )
}

ExpandedRow.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  order: OrderShape.isRequired,
  orderNumber: PropTypes.string.isRequired,
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrimeOrder: PropTypes.bool.isRequired,
  orderAddressCanBeEdited: PropTypes.bool.isRequired,
  selectOrder: PropTypes.func.isRequired,
  useInventoryAllocation: PropTypes.bool.isRequired,
}

export default ExpandedRow
