import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function DropdownItem({children, last, ...props}) {
  return (
    <li
      className={classNames('list__item list__item--dropdown', {
        'list__item--dropdown-last': last,
      })}
      {...props}
    >
      {children}
    </li>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.node,
  last: PropTypes.bool,
}
