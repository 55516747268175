import PropTypes from 'prop-types'

import {OrderShape} from '../../../../../../common/PropTypes.js'
import {
  SHIPPER_NAMES,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  ENDICIA,
} from '../../../../../../common/constants/ShipperNames.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import Currency from '../../../../../../common/components/Currency.js'
import {shippedShippingMethodSelector} from '../../../../../../data/orders.js'
import ShipDate from '../../Fields/ShipDate.js'
import {showEditTrackingNumberModal} from '../../../../../OrderListPage/Modals/EditTrackingNumberModal.js'
import {showDeleteTrackingModal} from '../../../../../OrderListPage/Modals/DeleteTrackingModal.js'
import AssignedWarehouse from './Fields/AssignedWarehouse.js'
import ListItem from './Fields/ListItem.js'
import LabelItem from './Fields/LabelItem.js'
import ShipEstimatedOrDeliveredDate from './Fields/ShipEstimatedOrDeliveredDate.js'
import {useSelector} from '../../../../../../store.js'
import {useManualTrackingSelector} from '../../../../../../data/company.js'

export default function ManualTracking({isCancelled, order, currencySymbol}) {
  const useManualTracking = useSelector(useManualTrackingSelector)
  const shippingMethodName = useSelector((state) =>
    shippedShippingMethodSelector(state, {orderNumber: order.order_number}),
  )
  const carrierName = order.shipping_info.carrier_name
  const shipperCurrencySymbol = [
    PITNEY,
    PITNEY_MERCHANT,
    PITNEY_PRESORT,
    ENDICIA,
    'usps',
  ].includes(carrierName)
    ? '$'
    : currencySymbol

  return (
    <div className="list__item--order-data list__item--shipping-info margin-bottom-15">
      <h4 className="list__title--order-data">Tracking Info Added</h4>
      <ul className="list--order-data list--no-style">
        <LabelItem label="Shipped">
          <ShipDate date={order.shipping_info.ship_date} />
        </LabelItem>
        <ShipEstimatedOrDeliveredDate orderNumber={order.order_number} />
        <LabelItem label="Tracking">
          {order.shipping_info.tracking_number}
        </LabelItem>
        <LabelItem label="Cost">
          <Currency
            value={order.shipping_info.cost}
            currencySymbol={shipperCurrencySymbol}
          />
        </LabelItem>
        <LabelItem label="Carrier">
          {SHIPPER_NAMES[carrierName] || carrierName}
        </LabelItem>
        <LabelItem label="Shipping Method">{shippingMethodName}</LabelItem>
        <AssignedWarehouse orderNumber={order.order_number} />
        {!isCancelled && (
          <ListItem>
            {useManualTracking && (
              <ButtonLink
                onClick={() => showEditTrackingNumberModal(order.order_number)}
                className="divider--right"
              >
                Edit tracking info
              </ButtonLink>
            )}
            <ButtonLink
              onClick={() => showDeleteTrackingModal([order.order_number])}
            >
              Delete
            </ButtonLink>
          </ListItem>
        )}
      </ul>
    </div>
  )
}

ManualTracking.propTypes = {
  isCancelled: PropTypes.bool.isRequired,
  order: OrderShape.isRequired,
  currencySymbol: PropTypes.string.isRequired,
}
