import get from 'lodash/get.js'

import {getState, useSelector} from '../../../store.js'
import {
  DHL,
  DHL_ECOMMERCE,
  PITNEY,
  PITNEY_MERCHANT,
  UPS,
} from '../../../common/constants/ShipperNames.js'
import {
  labelConfigSelector,
  attachCustomsInfoSelector,
  taxInfoErrorMessageSelector,
} from '../../../data/labelInfos/index.js'
import {showTaxInfoModal} from '../Modals/TaxInfoModal.js'
import {LABEL_PROPERTY_ATTACH_CUSTOMS_INFO} from '../SingleLabelConfigForm/AttachCustomsInfo.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import ShipperOptions from '../../../data/shipperOptions.js'
import ConfigButton from './ConfigButton.js'

const TAX_ID_NUMBER = 'tax_id_number'
const TAX_ISSUER_COUNTRY = 'tax_issuer_country'
const TAX_ID_TYPE = 'tax_id_type'
const SENDER_TAX_INFO = 'sender_tax_info'
const RECEIVER_TAX_INFO = 'receiver_tax_info'
const VALID_DHL_SHIPPER_TYPES = [DHL, DHL_ECOMMERCE]
const VALID_SHIPPER_TYPES = [
  PITNEY,
  PITNEY_MERCHANT,
  ...VALID_DHL_SHIPPER_TYPES,
  UPS,
]

function taxIDNumberTemplate(property) {
  return `${property}__${TAX_ID_NUMBER}`
}
function taxIDTypeTemplate(shipperType, property) {
  return `${shipperType}__${property}__${TAX_ID_TYPE}`
}
function taxIssuerCountryTemplate(property) {
  return `${property}__${TAX_ISSUER_COUNTRY}`
}

const VALID_LABEL_PROPERTIES = [SENDER_TAX_INFO, RECEIVER_TAX_INFO].reduce(
  (prev, property) => [
    ...prev,
    taxIDNumberTemplate(property),
    ...VALID_SHIPPER_TYPES.map((shipperType) =>
      taxIDTypeTemplate(shipperType, property),
    ),
    taxIssuerCountryTemplate(property),
  ],
  [],
)

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
}

function labelPropertiesFunc(shipperType, labelInfoID) {
  const attachCustomsInfo = attachCustomsInfoSelector(getState(), {labelInfoID})

  return attachCustomsInfo && VALID_SHIPPER_TYPES.includes(shipperType)
    ? [
        taxIDNumberTemplate(SENDER_TAX_INFO),
        taxIDTypeTemplate(shipperType, SENDER_TAX_INFO),
        taxIssuerCountryTemplate(SENDER_TAX_INFO),
        ...(VALID_DHL_SHIPPER_TYPES.includes(shipperType)
          ? [
              taxIDNumberTemplate(RECEIVER_TAX_INFO),
              taxIDTypeTemplate(shipperType, RECEIVER_TAX_INFO),
              taxIssuerCountryTemplate(RECEIVER_TAX_INFO),
            ]
          : []),
      ]
    : []
}

export default function TaxInfo() {
  const {labelInfoID, shipperType} = useLabelConfigContext()

  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )

  const property =
    VALID_DHL_SHIPPER_TYPES.includes(shipperType) &&
    labelConfig[taxIDNumberTemplate(RECEIVER_TAX_INFO)]
      ? RECEIVER_TAX_INFO
      : SENDER_TAX_INFO

  const labelProperty = taxIDNumberTemplate(property)

  const options = get(ShipperOptions, [shipperType, 'tax_id_type'], [])
  const attachCustomsInfo =
    useSelector((state) => attachCustomsInfoSelector(state, {labelInfoID})) ||
    false
  const taxInfoErrorMessage = useSelector((state) =>
    taxInfoErrorMessageSelector(state, {labelInfoID}),
  )

  const taxID = labelConfig[labelProperty] || ''
  const taxIDType = labelConfig[taxIDTypeTemplate(shipperType, property)] || ''
  const taxIDTypeOption = options.find(({value}) => value === taxIDType)
  const taxIssuerCountry = labelConfig[taxIssuerCountryTemplate(property)] || ''
  const hasCountry =
    taxIssuerCountry &&
    [DHL, DHL_ECOMMERCE, PITNEY, PITNEY_MERCHANT].includes(shipperType)

  return (
    <ConfigButton
      label="Tax Info"
      onClick={showTaxInfoModal}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      dependantPropertiesFunc={dependantPropertiesFunc}
      validLabelProperties={VALID_LABEL_PROPERTIES}
      errorMessage={taxInfoErrorMessage}
      hide={!attachCustomsInfo}
    >
      {taxID
        ? `${
            property === SENDER_TAX_INFO ? 'Sender' : 'Receiver'
          } ID: ${taxID}${
            taxIDTypeOption ? ` - ${taxIDTypeOption.display}` : ''
          }${hasCountry ? ` - ${taxIssuerCountry}` : ''}`
        : 'Edit'}
    </ConfigButton>
  )
}
