import 'whatwg-fetch'
import {stringify} from './querystring.js'

export default function abode(url, options = {}) {
  return fetch(formatAbodeURL(url), {credentials: 'include', ...options})
}

abode.json = async (...args) => {
  const res = await abode(...args)

  return res.json()
}

export function formatAbodeURL(url, params) {
  return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_ABODE_URL}${url}${
    params ? `?${stringify(params)}` : ''
  }`
}
