import {useSelector} from 'react-redux'

import {isReadOnlyOrderSelector} from '../../../../../data/orders.js'
import {showConfirmMarkAsShippedModal} from '../../../../OrderListPage/Modals/ConfirmMarkAsShippedModal.js'
import {showSendInvoiceModal} from '../../../../OrderListPage/Modals/SendInvoiceModal.js'
import {showConfirmOrderCancelModal} from '../../../../OrderListPage/Modals/ConfirmOrderCancelModal.js'
import {showConfirmTrackingWritebackModal} from '../../../../OrderListPage/Modals/ConfirmTrackingWritebackModal.js'
import {initiateReturn} from '../../../../ReturnOrderListPage/Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import AddOrderTagsDropdown from './AddOrderTagsDropdown.js'
import PrintDropdown from './PrintDropdown.js'
import {showSplitOrderModal} from '../../../../OrderListPage/Modals/SplitOrderModal.js'
import {showCreateChargeModal} from '../../../../../common/components/Modal/CreateChargeModal.js'
import {
  orderDetailOrderActionsSelector,
  orderDetailOrderNumberSelector,
  orderListPageURLSelector,
} from '../../../../OrderDetailPage/orderDetailSelectors.js'
import {showRestoreOrderModal} from '../../../../OrderListPage/Modals/RestoreOrderModal.js'
import {showMarkAsAwaitingFulfillmentModal} from '../../../../OrderListPage/Modals/MarkAsAwaitingFulfillmentModal.js'
import {
  attemptToAllocateSelectedOrders,
  cloneSelectedOrder,
  deallocateSelectedOrders,
} from '../../../../OrderListPage/orderListActions.js'

export default function OrderDetailControls() {
  const orderNumber = useSelector(orderDetailOrderNumberSelector)
  const isReadOnly = useSelector((state) =>
    isReadOnlyOrderSelector(state, {orderNumber}),
  )
  const orderListPageURL = useSelector(orderListPageURLSelector)
  const orderActions = useSelector(orderDetailOrderActionsSelector)

  return (
    <ul className="list list--no-style">
      <li className="inline-block v-align-base">
        <a
          href={orderListPageURL}
          className="btn btn--action"
          title="Back to orders"
        >
          &#8592; Orders
        </a>
      </li>
      <PrintDropdown
        selectedOrderNumbers={[orderNumber]}
        orderActions={orderActions}
        className="divider--left"
      />
      {!isReadOnly && <AddOrderTagsDropdown />}
      {orderActions.attempt_to_allocate && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => attemptToAllocateSelectedOrders()}
          >
            Allocate
          </button>
        </li>
      )}
      {orderActions.deallocate && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => deallocateSelectedOrders()}
          >
            Deallocate
          </button>
        </li>
      )}
      {orderActions.mark_as_shipped && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showConfirmMarkAsShippedModal([orderNumber])}
          >
            Mark as Shipped
          </button>
        </li>
      )}
      {orderActions.mark_as_awaiting_fulfillment && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showMarkAsAwaitingFulfillmentModal([orderNumber])}
          >
            Mark as Awaiting Fulfillment
          </button>
        </li>
      )}
      {orderActions.split && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showSplitOrderModal(orderNumber)}
          >
            Split Order
          </button>
        </li>
      )}
      {orderActions.clone && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => cloneSelectedOrder()}
          >
            Clone Order
          </button>
        </li>
      )}
      {orderActions.create_charge && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showCreateChargeModal([orderNumber])}
          >
            Process Payment
          </button>
        </li>
      )}
      {orderActions.send_invoice && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showSendInvoiceModal(orderNumber)}
          >
            Send Invoice
          </button>
        </li>
      )}
      {orderActions.initiate_return && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => initiateReturn(orderNumber)}
          >
            Initiate a Return (RMA)
          </button>
        </li>
      )}
      {orderActions.retry_tracking_writeback && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showConfirmTrackingWritebackModal([orderNumber])}
          >
            Retry Tracking Writeback
          </button>
        </li>
      )}
      {orderActions.cancel && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showConfirmOrderCancelModal([orderNumber])}
          >
            Cancel Order
          </button>
        </li>
      )}
      {orderActions.restore && (
        <li className="inline-block v-align-base margin-left-15">
          <button
            className="btn btn--action"
            type="button"
            onClick={() => showRestoreOrderModal([orderNumber])}
          >
            Restore Order
          </button>
        </li>
      )}
    </ul>
  )
}
