import PropTypes from 'prop-types'

import {OrderShape} from '../../../../../../common/PropTypes.js'

import Currency, {isTBD} from '../../../../../../common/components/Currency.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import TrackingStatus from '../../Fields/TrackingStatus.js'
import TrackingLink from '../../Fields/TrackingLink.js'
import ListItem from './Fields/ListItem.js'

export default function ReturnLabel({
  order,
  isCancelled,
  showReturnLabelTab,
  deleteReturnLabel,
}) {
  return (
    <div className="divider--top">
      <h4 className="list__title--order-data">Return Label Created</h4>
      <ul className="list--order-data list--no-style">
        <li className="list__item--order-data list__item--shipping-info">
          <strong className="margin-right-3">Tracking:</strong>
          <TrackingLink shippingInfo={order.return_shipping_info} />
        </li>
        <TrackingStatus shippingInfo={order.return_shipping_info} />
        <li className="list__item--order-data">
          <strong className="margin-right-3">Cost:</strong>
          <span>
            {isTBD(order.return_shipping_info.cost) ? (
              'TBD'
            ) : (
              <Currency value={order.return_shipping_info.cost} />
            )}
          </span>
        </li>
        <ListItem className="margin-top-5">
          <ButtonLink onClick={showReturnLabelTab} className="divider--right">
            View return label
          </ButtonLink>
          {!isCancelled && (
            <ButtonLink onClick={deleteReturnLabel}>Delete</ButtonLink>
          )}
        </ListItem>
      </ul>
    </div>
  )
}

ReturnLabel.propTypes = {
  order: OrderShape.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  showReturnLabelTab: PropTypes.func.isRequired,
  deleteReturnLabel: PropTypes.func.isRequired,
}
