import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import Checkbox from '../../common/components/Checkbox.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {
  setCartFilters,
  handleAnalyticsCartListSelection,
} from './analyticsActions.js'
import {
  filterableCartsSelector,
  allCartsSelectedSelector,
  indeterminateCartsSelectedSelector,
} from './analyticsSelectors.js'

const DROPDOWN = 'ANALYTICS_CART_FILTER'

export function CartFilter({
  currentDropdown,
  carts,
  allCartsSelected,
  indeterminateCartsSelected,
}) {
  return (
    <li
      className={classNames(
        'list__item--inline-block list__item--dropdown-wrap dropdown margin-left-0 flex align-items-center',
        {open: currentDropdown === DROPDOWN},
      )}
      data-dropdown={DROPDOWN}
    >
      <div>
        <button
          className="btn--dropdown-toggle btn--filter-toggle"
          type="button"
        >
          <span className="super-small-screen-hide">Filter by</span> Sales
          Channel
        </button>
        <ul className="list--dd-menu list--filters" data-dropdown-prevent-close>
          <li className="list__item--filter list__item--filter-select-all">
            <label className="label--filter margin-bottom-0">
              <Checkbox
                className="margin-bottom-0 margin-right-5 make-inline-block"
                checked={allCartsSelected}
                indeterminate={indeterminateCartsSelected}
                onChange={(checked) =>
                  setCartFilters(checked ? carts.map(({value}) => value) : [])
                }
              />
              <span className="label__text--filter">
                {allCartsSelected ? 'Deselect All' : 'Select All'}
              </span>
            </label>
          </li>
          {carts.map((cart) => (
            <li className="list__item--filter" key={cart.value}>
              {/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: 0 */}
              <label
                className="label--filter margin-bottom-0"
                htmlFor={`analytics_cart_${cart.value}`}
                role="checkbox"
                tabIndex="0"
                aria-checked={cart.isSelected}
                onClick={(event) =>
                  handleAnalyticsCartListSelection(
                    cart.value,
                    !cart.isSelected,
                    event.shiftKey,
                  )
                }
                onKeyPress={() =>
                  handleAnalyticsCartListSelection(
                    cart.value,
                    !cart.isSelected,
                    event.shiftKey,
                  )
                }
              >
                <Checkbox
                  id={`analytics_cart_${cart.value}`}
                  className="margin-bottom-0 margin-right-5 make-inline-block"
                  checked={cart.isSelected}
                  tabIndex="-1"
                />
                <span
                  className="label__text--filter"
                  style={{userSelect: 'none'}}
                >
                  {cart.display}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

CartFilter.propTypes = {
  currentDropdown: PropTypes.string,
  carts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  allCartsSelected: PropTypes.bool.isRequired,
  indeterminateCartsSelected: PropTypes.bool.isRequired,
}

function mapStateToProp(state) {
  return {
    currentDropdown: currentDropdownSelector(state),
    carts: filterableCartsSelector(state),
    allCartsSelected: allCartsSelectedSelector(state),
    indeterminateCartsSelected: indeterminateCartsSelectedSelector(state),
  }
}

export default connect(mapStateToProp)(CartFilter)
