import PropTypes from 'prop-types'

import {
  getState,
  dispatch,
  setForm,
  updateForm,
  removeForm,
} from '../../store.js'
import {RETURN_ORDER_SINGLE_URI_COMPONENT} from '../../common/constants/ReturnOrders.js'
import {ProductShape} from '../../common/PropTypes.js'
import apiverson from '../../common/apiverson.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {
  setReturnOrder,
  setReturnOrderStateAndEnsureProductsLoaded,
} from '../../redux/actions/data/returnOrders.js'
import {navigate} from '../../common/location.js'
import {
  returnOrderSelector,
  returnOrderWarehouseIDSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {
  RETURN_ORDER_DETAIL_FORM,
  returnOrderDetailReferenceIDSelector,
} from './returnOrderDetailSelectors.js'

export const ReturnOrderDetailFormShape = PropTypes.shape({
  selectedLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAddingProduct: PropTypes.bool.isRequired,
  tempProduct: ProductShape,
  afterAddingProduct: PropTypes.bool.isRequired,
})

export function navigateToRMADetailPage(referenceID) {
  return navigate([RETURN_ORDER_SINGLE_URI_COMPONENT, referenceID])
}

export function setupReturnOrderDetailForm() {
  setForm(RETURN_ORDER_DETAIL_FORM, {
    selectedLineIDs: [],
    isLoading: true,
    isAddingProduct: false,
    tempProduct: null,
    afterAddingProduct: false,
  })
}

export function updateReturnOrderDetailForm(updates, meta) {
  updateForm(RETURN_ORDER_DETAIL_FORM, updates, meta)
}

export function removeReturnOrderDetailForm() {
  removeForm(RETURN_ORDER_DETAIL_FORM)
}

export async function loadCurrentReturnOrder() {
  const referenceID = returnOrderDetailReferenceIDSelector(getState())

  if (!referenceID) {
    return
  }

  try {
    const returnOrder = returnOrderSelector(getState(), {referenceID})

    updateReturnOrderDetailForm({
      isLoading: !returnOrder,
    })

    const {json} = await apiverson.get(
      `/return_order/${encodeURIComponent(referenceID)}`,
    )

    await setReturnOrderStateAndEnsureProductsLoaded(json)
  } catch (err) {
    if (!err.response || err.response.status !== 404) {
      showGlobalError(
        {
          summary: `There was an error loading RMA Details for ${referenceID}.`,
          details: err.message,
        },
        err,
      )
    }
  }

  updateReturnOrderDetailForm({isLoading: false})
}

export async function addReturnOrderDetailLine(product) {
  const referenceID = returnOrderDetailReferenceIDSelector(getState())
  const warehouseID = returnOrderWarehouseIDSelector(getState(), {
    referenceID,
  })

  updateReturnOrderDetailForm({tempProduct: product, isAddingProduct: false})

  try {
    const {json} = await apiverson.post(
      `/return_order/${encodeURIComponent(referenceID)}/line`,
      {
        sku: product.sku,
        total_price: product.price,
        expected_quantity: 1,
        restock_warehouse_id: warehouseID || undefined,
      },
    )

    dispatch(setReturnOrder(json))
  } catch (err) {
    showGlobalError(
      {
        summary: `There was an error adding a line to RMA Details for ${referenceID}.`,
        details: err.message,
      },
      err,
    )
  }

  updateReturnOrderDetailForm({
    isAddingProduct: false,
    tempProduct: null,
    afterAddingProduct: true,
  })
}
