import PropTypes from 'prop-types'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  getState,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  hasCreditCardSelector,
  isInTrialSelector,
  sourceSelector,
  enableCompanyFeature,
} from '../../data/company.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showCreditCardModal} from './CreditCardModal.js'
import api from '../../common/api.js'
import {PREMIUM_PLAN, PRO_PLAN} from '../../common/constants/Plans.js'
import {showMessageToast} from '../Header/Toast/index.js'
import CalendarScheduler from '../../common/components/CalendarScheduler.js'

const MODAL_FORM = 'INVENTORY_MGMT_MODULE_MODAL'

export async function showInventoryMgmtModuleModal(initialForm = {}) {
  setForm(MODAL_FORM, {
    meetingBookSucceeded: null,
    showCalendar: false,
    isSaving: false,
    serverError: null,
    ...initialForm,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

async function enableIMM() {
  try {
    const hasCreditCard = hasCreditCardSelector(getState())

    if (!hasCreditCard) {
      closeModal()

      const gotCreditCard = await showCreditCardModal()

      if (!gotCreditCard) {
        return
      }

      showInventoryMgmtModuleModal()
    }

    updateModalForm({
      isSaving: true,
      serverError: null,
    })

    await api.post('/company/plan/', {
      plan: PRO_PLAN,
    })

    const source = sourceSelector(getState())

    await api.put('/company/', {
      source: JSON.stringify({...source, new_plan: PREMIUM_PLAN}),
    })

    await enableCompanyFeature('disable_inventory_writeback')

    closeModal()

    showMessageToast('The Inventory Management Module has been enabled!')
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function InventoryMgmtModuleModal({form}) {
  const isInTrial = useSelector(isInTrialSelector)

  return (
    <ConfirmModal
      title={
        form.showCalendar
          ? 'Talk to an Expert'
          : 'Enable the Inventory Management Module'
      }
      modalSize="lg"
      confirmText={form.showCalendar ? 'Take Me Back' : 'Let’s Do This!'}
      cancelText={form.meetingBookSucceeded ? 'All Done' : 'Close'}
      onConfirm={() =>
        form.showCalendar ? updateModalForm({showCalendar: false}) : enableIMM()
      }
      onCancel={() => closeModal()}
      MiddleButtons={() =>
        form.showCalendar ? null : (
          <ButtonPrimary
            isOutlined
            className="margin-right-10"
            onClick={() => updateModalForm({showCalendar: true})}
            isLoading={form.isSaving}
          >
            Talk to a Product Expert
          </ButtonPrimary>
        )
      }
      error={form.serverError}
      isSaving={form.isSaving}
    >
      {form.showCalendar ? (
        <>
          <div className="row">
            <div className="medium-10 columns medium-centered">
              <p className="fs-01 margin-bottom-5 align-center">
                <strong>Set Yourself Up for Success</strong>
              </p>
              <p className="fs-01 margin-bottom-20 align-center">
                The Inventory Management Module is a very powerful Ordoro
                add-on. Schedule time with one of our Product Experts to learn
                how it will fit in with your specific business needs.
              </p>

              <p className="fs-01 margin-bottom-20 align-center">
                Schedule time with one of our Product Experts to learn how
                Inventory Writeback will work with your business before it’s
                enabled.
              </p>

              {form.meetingBookSucceeded === false && (
                <p className="fs-01 margin-bottom-20 alert alert--error">
                  Something happened during scheduling the appointment. Try
                  again later.
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <CalendarScheduler
                onMessage={({meetingBookSucceeded}) =>
                  updateModalForm({meetingBookSucceeded})
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="medium-10 columns">
              <p className="fs-01 margin-bottom-5">
                <strong>
                  Ready to take full control of your stock levels?
                </strong>
              </p>
              <p className="fs-01 margin-bottom-25">
                Sweet! You’ll get instant access to all of our advanced
                inventory management features which include kitting, POs, RMAs,
                Bill of Materials, Dropshipping, and more!
              </p>
              <div className="alert alert--standard">
                <p className="fs-00 lh-md margin-bottom-5">
                  <strong>
                    Please note, that by enabling this module, your Ordoro
                    subscription fee will start at $499 per month.
                  </strong>
                </p>

                <p className="fs-00 lh-md margin-bottom-0">
                  {isInTrial ? (
                    <em>
                      You won’t be a charged until your 15-day free trial has
                      ended.
                    </em>
                  ) : (
                    <em>
                      This new rate will not take affect until your next billing
                      cycle.
                    </em>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </ConfirmModal>
  )
}

InventoryMgmtModuleModal.propTypes = {
  form: PropTypes.shape({
    meetingBookSucceeded: PropTypes.bool,
    showCalendar: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(InventoryMgmtModuleModal, modalFormSelector)
