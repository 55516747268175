import {all, takeEvery, put, call, select} from 'redux-saga/effects'

import {X_DELIVERY} from '../../../../../../common/constants/ShipperNames.js'
import {NEW_ID} from '../../../../../../common/constants/index.js'
import api from '../../../../../../common/api.js'
import {setGlobalError} from '../../../../../actions/ui/index.js'
import {setShipper} from '../../../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../../actions/ui/settings/shippers/index.js'
import {shipperFormSelector} from '../../../../../selectors/ui/settings/shippers/index.js'
import {SAVE_SHIPPER_X_DELIVERY} from '../../../../../actions/ui/settings/shippers/forms/xDelivery.js'

export function* saveShipperXDeliveryWorker({payload: shipperID}) {
  const {name, username, password} = yield select(shipperFormSelector, {
    shipperID,
  })

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    username,
    password,
  }

  yield put(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} =
      shipperID !== NEW_ID
        ? yield call(api.put, `/shipper/${shipperID}/`, params)
        : yield call(api.post, '/shipper/', {...params, vendor: X_DELIVERY})

    yield put(goToShipperSettings())

    yield call(setShipper, json)
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'There was an error while saving shipper.',
        },
        err,
      ),
    )

    yield put(updateForm(shipperID, {isSaving: false}))
  }
}

export default function* xDeliverySaga() {
  yield all([takeEvery(SAVE_SHIPPER_X_DELIVERY, saveShipperXDeliveryWorker)])
}
