import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {useSelector} from '../../store.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {
  productSelector,
  productBOMComponentsSelector,
} from '../../data/products.js'
import {MOLineShape} from '../../data/mos.js'
import {showDeleteMOLinesModal} from '../MOListPage/Modals/DeleteMOLinesModal.js'
import {showEditMOLineModal} from '../MOListPage/Modals/EditMOLineModal.js'
import {showFinishMOModal} from '../MOListPage/Modals/FinishMOModal.js'
import {handleMOLinesListSelection} from './moDetailActions.js'
import PopoverImage from '../../common/components/PopoverImage.js'

export default function Line({line, referenceID, selectedLineIDs}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  const bomComponents = useSelector((state) =>
    productBOMComponentsSelector(state, {sku: line.sku}),
  )

  // filter out this line from selected lineIDs if removed
  useEffect(() => {
    return () => {
      handleMOLinesListSelection(line.id, false, false)
    }
  }, [])

  if (!product) {
    return null
  }

  const isDisabled = bomComponents.length === 0

  return (
    <tr className="table__tr--return-orders">
      <td className="table__td">
        <Checkbox
          className="margin-bottom-0"
          checked={selectedLineIDs.includes(line.id)}
          onClick={(checked, event) =>
            handleMOLinesListSelection(line.id, checked, event.shiftKey)
          }
          disabled={isDisabled}
        />
      </td>
      <td className="table__td">
        <dl className="list">
          <dt className="list__title fs-n1 lh-md line-detail margin-bottom-0 flex">
            <PopoverImage src={product.image_url} alt={product.name} />
            <a
              className="btn--link darker"
              href={`#/product/${encodeURIComponent(line.sku)}`}
            >
              <strong className="fs-00 margin-bottom-3 block">
                {product.name}
              </strong>
              <span className="block unbold">SKU: {line.sku}</span>
              {product.upc && (
                <span className="block unbold">UPC: {product.upc}</span>
              )}
            </a>
          </dt>
        </dl>
      </td>
      <td className="table__td">
        {bomComponents.length ? (
          <ul className="list list--square text--md-grey">
            {bomComponents.map((component) => (
              <li
                className="list__item list__item--no-style flex--justify divider--bottom"
                key={component.id}
              >
                <div className="fs-n1 flex lh-md w-85">
                  <PopoverImage
                    src={component.image_url}
                    alt={component.name}
                  />
                  <div>
                    <a
                      className="btn--link darker"
                      href={`#/product/${encodeURIComponent(component.sku)}`}
                    >
                      <strong>{component.name}</strong>
                      <div>{component.sku}</div>
                    </a>
                  </div>
                </div>
                <div className="fs-n1 lh-md">{component.quantity}</div>
              </li>
            ))}
          </ul>
        ) : null}
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          onClick={() =>
            showEditMOLineModal(referenceID, [line.id], 'requested_quantity')
          }
          disabled={isDisabled}
        >
          <strong>{line.requested_quantity}</strong>
        </ButtonLink>
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          onClick={() => showFinishMOModal(referenceID, [line.id])}
          disabled={isDisabled}
        >
          {line.finished_quantity}
        </ButtonLink>
      </td>
      <td className="table__td">
        {line.manufacturer_notes ? (
          <>
            <div className="fs-n1 ws-pre-wrap">{line.manufacturer_notes}</div>
            <ButtonLink
              className="fs-n1 meta-modetail-button-edit-manufacturer-notes"
              onClick={() =>
                showEditMOLineModal(
                  referenceID,
                  [line.id],
                  'manufacturer_notes',
                )
              }
              disabled={isDisabled}
            >
              Edit
            </ButtonLink>
          </>
        ) : (
          <ButtonLink
            className="fs-n1 meta-modetail-button-add-manufacturer-notes"
            onClick={() =>
              showEditMOLineModal(referenceID, [line.id], 'manufacturer_notes')
            }
            disabled={isDisabled}
          >
            Add note
          </ButtonLink>
        )}
      </td>
      <td className="table__td">
        {line.internal_notes ? (
          <>
            <div className="fs-n1 ws-pre-wrap">{line.internal_notes}</div>
            <ButtonLink
              className="fs-n1 meta-modetail-button-edit-internal-notes"
              onClick={() =>
                showEditMOLineModal(referenceID, [line.id], 'internal_notes')
              }
              disabled={isDisabled}
            >
              Edit
            </ButtonLink>
          </>
        ) : (
          <ButtonLink
            className="fs-n1 meta-modetail-button-add-internal-notes"
            onClick={() =>
              showEditMOLineModal(referenceID, [line.id], 'internal_notes')
            }
            disabled={isDisabled}
          >
            Add note
          </ButtonLink>
        )}
      </td>
      <td className="table__td align-right">
        <ButtonLink
          title="Remove line from MFG Order"
          className="no-underline"
          onClick={() => showDeleteMOLinesModal(referenceID, [line.id])}
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

Line.propTypes = {
  line: MOLineShape.isRequired,
  referenceID: PropTypes.string.isRequired,
  selectedLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
}
