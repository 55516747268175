import ButtonLink from '../../common/components/Button/ButtonLink.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import RowAddress from '../../common/components/List/RowAddress.js'
import {
  canEditIDSelector,
  dateCreatedSelector,
  statusDisplaySelector,
  shippingMethodSelector,
  estimatedDeliveryDateSelector,
  paymentMethodSelector,
  instructionsSelector,
  warehouseIDSelector,
  supplierIDSelector,
  isPOLockedSelector,
  poSelector,
} from '../../data/pos.js'
import {
  getWarehouseName,
  warehouseAddressSelector,
  warehouseSelector,
} from '../../data/warehouses.js'
import {
  getSupplierName,
  supplierSelector,
  supplierAddressSelector,
} from '../../data/suppliers.js'
import {poIDSelector} from './poDetailSelectors.js'
import {showPOIDModal} from '../PurchaseOrderListPage/Modals/POIDModal.js'
import {showPOTextFieldModal} from '../PurchaseOrderListPage/Modals/POTextFieldModal.js'
import {
  showPORestockLocationModal,
  ID_TYPE_WAREHOUSE,
  ID_TYPE_SUPPLIER,
} from '../PurchaseOrderListPage/Modals/PORestockLocationModal.js'
import {COMMUNICATION_PO} from '../../common/constants/Suppliers.js'
import {dispatch, useSelector} from '../../store.js'
import {POTags} from '../PurchaseOrderListPage/POTags.js'

export default function Header() {
  const poID = useSelector(poIDSelector)
  const po = useSelector((state) => poSelector(state, {poID}))
  const warehouseID = useSelector((state) => warehouseIDSelector(state, {poID}))
  const supplierID = useSelector((state) => supplierIDSelector(state, {poID}))
  const canEditID = useSelector((state) => canEditIDSelector(state, {poID}))
  const dateCreated = useSelector((state) => dateCreatedSelector(state, {poID}))
  const statusDisplay = useSelector((state) =>
    statusDisplaySelector(state, {poID}),
  )
  const shippingMethod = useSelector((state) =>
    shippingMethodSelector(state, {poID}),
  )
  const estimatedDeliveryDate = useSelector((state) =>
    estimatedDeliveryDateSelector(state, {poID}),
  )
  const paymentMethod = useSelector((state) =>
    paymentMethodSelector(state, {poID}),
  )
  const instructions = useSelector((state) =>
    instructionsSelector(state, {poID}),
  )
  const warehouse = useSelector((state) =>
    warehouseSelector(state, {warehouseID}),
  )
  const supplier = useSelector((state) => supplierSelector(state, {supplierID}))
  const warehouseAddress = useSelector((state) =>
    warehouseAddressSelector(state, {warehouseID}),
  )
  const supplierAddress = useSelector((state) =>
    supplierAddressSelector(state, {
      supplierID,
      type: COMMUNICATION_PO,
    }),
  )
  const isPOLocked = useSelector((state) => isPOLockedSelector(state, {poID}))

  return (
    <>
      <div className="flex">
        <h2 className="page__title page__title--inner margin-right-5">
          <strong>{poID}</strong>
        </h2>
        {canEditID && (
          <ButtonLink
            className="no-underline"
            onClick={() => dispatch(showPOIDModal(poID))}
          >
            <span
              className="i-pencil i--v-align fs-01 lh-sm"
              aria-hidden="true"
            />
          </ButtonLink>
        )}
      </div>
      {po.tags.length !== 0 && (
        <div className="inline-block v-align-middle">
          <POTags poID={poID} tags={po.tags} />
        </div>
      )}
      <div className="flex margin-top-25 margin-bottom-30">
        <div className="medium-4 columns wrap__list--order-data padding-left-0">
          <dl className="list list--order-data">
            <dt className="list__title--order-data">PO Summary</dt>
            <dd className="list__item--order-data fs-n0">
              <strong>Created: </strong>
              <span className="fs-n0">
                <FormattedDate
                  value={dateCreated}
                  format="MMM D, YYYY [at] h:mma"
                />
              </span>
            </dd>
            <dd className="list__item--order-data fs-n0">
              <strong>Status: </strong>
              <span className="fs-n0">{statusDisplay}</span>
            </dd>
            <dd className="list__item--order-data fs-n0">
              <strong>Shipping Method: </strong>
              <ButtonLink
                className={`v-align-base${shippingMethod ? ' darker' : ''}`}
                onClick={() =>
                  dispatch(
                    showPOTextFieldModal({
                      poID,
                      value: shippingMethod,
                      display: 'Shipping Method',
                      apiProp: 'shipping_method',
                    }),
                  )
                }
                disabled={isPOLocked}
              >
                {shippingMethod || <em>None specified</em>}
              </ButtonLink>
            </dd>
            <dd className="list__item--order-data fs-n0">
              <strong>Estimated Delivery: </strong>
              <ButtonLink
                className={`v-align-base${
                  estimatedDeliveryDate ? ' darker' : ''
                }`}
                onClick={() =>
                  dispatch(
                    showPOTextFieldModal({
                      poID,
                      value: estimatedDeliveryDate,
                      display: 'Estimated Delivery Date',
                      apiProp: 'estimated_delivery_date',
                      inputType: 'date',
                    }),
                  )
                }
                disabled={isPOLocked}
              >
                {estimatedDeliveryDate ? (
                  <FormattedDate
                    value={estimatedDeliveryDate}
                    format="MMM D, YYYY"
                  />
                ) : (
                  <em>None specified</em>
                )}
              </ButtonLink>
            </dd>
            <dd className="list__item--order-data fs-n0">
              <strong>Payment Method: </strong>
              <ButtonLink
                className={`v-align-base${paymentMethod ? ' darker' : ''}`}
                onClick={() =>
                  dispatch(
                    showPOTextFieldModal({
                      poID,
                      value: paymentMethod,
                      display: 'Payment Method',
                      apiProp: 'payment_method',
                    }),
                  )
                }
                disabled={isPOLocked}
              >
                {paymentMethod || <em>None specified</em>}
              </ButtonLink>
            </dd>
          </dl>
          <dl className="list list--order-data with-top-divider">
            <dt className="list__title--order-data">Instructions</dt>
            <dd className="list__item--order-data fs-n0">
              {instructions && (
                <div className="ws-pre-wrap">{instructions}</div>
              )}
              <ButtonLink
                className="v-align-base"
                onClick={() =>
                  dispatch(
                    showPOTextFieldModal({
                      poID,
                      value: instructions,
                      display: 'Instructions',
                      apiProp: 'instructions',
                      inputType: 'textarea',
                    }),
                  )
                }
                disabled={isPOLocked}
              >
                {instructions ? 'Edit' : 'Add instructions'}
              </ButtonLink>
            </dd>
          </dl>
        </div>
        {supplier && (
          <div className="medium-4 columns wrap__list--order-data">
            <dl className="list list--order-data">
              <dt className="list__title--order-data">Supplier</dt>
              <dd className="list__item--order-data fs-n0">
                <strong>{getSupplierName(supplier)}</strong>
              </dd>
              <dd className="list__item--order-data fs-n0 wrap--no-neg-margin">
                <RowAddress address={supplierAddress} />
              </dd>
              <dd className="list__item--order-data fs-n0">
                <ButtonLink
                  className="v-align-base"
                  onClick={() =>
                    dispatch(
                      showPORestockLocationModal({
                        poID,
                        id: supplier.id,
                        idType: ID_TYPE_SUPPLIER,
                      }),
                    )
                  }
                  disabled={isPOLocked}
                >
                  Edit
                </ButtonLink>
              </dd>
            </dl>
          </div>
        )}
        {warehouse && (
          <div className="medium-4 columns wrap__list--order-data">
            <dl className="list list--order-data">
              <dt className="list__title--order-data">Receiving Warehouse</dt>
              <dd className="list__item--order-data fs-n0 margin-bottom-0">
                <strong>{getWarehouseName(warehouse)}</strong>
              </dd>
              <dd className="list__item--order-data fs-n0 wrap--no-neg-margin">
                <RowAddress address={warehouseAddress} noPhone noEmail />
              </dd>
              <dd className="list__item--order-data fs-n0">
                <ButtonLink
                  className="v-align-base"
                  onClick={() =>
                    dispatch(
                      showPORestockLocationModal({
                        poID,
                        id: warehouse.id,
                        idType: ID_TYPE_WAREHOUSE,
                      }),
                    )
                  }
                  disabled={isPOLocked}
                >
                  Edit
                </ButtonLink>
              </dd>
            </dl>
          </div>
        )}
      </div>
    </>
  )
}
