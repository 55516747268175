import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'
import isAfter from 'date-fns/isAfter'

import {getState, setForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'
import {formatAbodeURL} from '../common/abode.js'
import {CommentShape} from '../common/PropTypes.js'
import {hasBatchPermissionSelector} from '../data/me.js'
import {hasUseBatchesFeatureSelector} from '../data/company.js'
import {batchReferenceIDsFromOrdersSelector} from './orders.js'
import {refreshBatchSummaries} from '../ordoro/BatchListPage/batchListActions.js'
import {printerNameSelector} from './printNode.js'
import {dateTimeFormat} from '../common/date.js'

export const BATCHES = 'BATCHES'

export const BatchShape = PropTypes.shape({
  status: PropTypes.string.isRequired,
  reference_id: PropTypes.string.isRequired,
  created_date: PropTypes.string.isRequired,
  updated_date: PropTypes.string.isRequired,
  internal_notes: PropTypes.string,
  order_numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  comments: PropTypes.arrayOf(CommentShape).isRequired,
})

export function setBatches(batches) {
  setForm(BATCHES, keyBy(batches, 'reference_id'))
}

export function setBatch(batch) {
  const batches = batchesSelector(getState())

  setForm(BATCHES, {...batches, [batch.reference_id]: batch})
}

export const batchesSelector = createSelector(
  formsSelector,
  (forms) => forms[BATCHES] || batchesSelector.default,
)
batchesSelector.default = {}

export const batchesByIDSelector = createSelector(
  batchesSelector,
  (batchesByReferenceID) =>
    Object.values(batchesByReferenceID).reduce((prev, batch) => {
      prev[batch.id] = batch

      return prev
    }, {}),
)

export function batchSelector(state, {referenceID}) {
  return batchesSelector(state)[referenceID]
}

export const createBatchSelector = (referenceID) =>
  createSelector(batchesSelector, (batches) => batches[referenceID])

export const canUseBatchesSelector = createSelector(
  hasBatchPermissionSelector,
  hasUseBatchesFeatureSelector,
  (hasPermission, hasUseBatchesFeature) =>
    hasPermission && hasUseBatchesFeature,
)

export function commentsSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  return get(batch, 'comments', [])
}

export function parseComment(comment) {
  let obj = null

  try {
    obj = JSON.parse(comment.text)
  } catch (err) {
    // noop
  }

  if (obj !== null) {
    return {...comment, text: '', ...obj}
  }

  return comment
}

export function getCommentGroups(batch) {
  const comments = get(batch, 'comments', [])
  const daysIndex = {}

  return sortBy(comments, 'date')
    .reverse()
    .map(parseComment)
    .reduce((days, comment) => {
      const day = dateTimeFormat(comment.date)

      if (daysIndex[day]) {
        daysIndex[day].comments.push(comment)
      } else {
        daysIndex[day] = {
          day,
          comments: [comment],
        }

        days.push(daysIndex[day])
      }

      return days
    }, [])
}

export const createBatchCommentGroupsSelector = (batchSelector) =>
  createSelector(batchSelector, (batch) => getCommentGroups(batch))

export function batchInternalNotesSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  return get(batch, 'internal_notes')
}

export function batchOrderNumbersSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  return get(batch, 'order_numbers')
}

export function batchCreatedDateSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  return get(batch, 'created_date')
}

export function batchUpdatedDateSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  return get(batch, 'updated_date')
}

export function batchHasActionErrorSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  const latestAction = get(batch, 'latest_actions.0')

  return latestAction
    ? (latestAction.complete && latestAction.success === false) ||
        latestAction.errors.length > 0
    : false
}

export async function ensureBatch(referenceID, {reload} = {}) {
  let batch = batchSelector(getState(), {referenceID})

  if (!reload && batch) {
    return batch
  }

  const {json} = await apiverson.get(
    `/batch/${encodeURIComponent(referenceID)}`,
  )

  setBatch(json)

  return json
}

export function batchLLPPDFDocumentsSelector(state, {referenceID}) {
  const batch = batchSelector(state, {referenceID})

  let hasLabel = false
  let hasPack = false
  let hasPick = false

  return sortBy(
    get(batch, 'documents', []).filter(
      (document) => document.document_type === 'composited_lpp',
    ),
    'created_date',
  )
    .reverse()
    .reduce((prev, document) => {
      let added = false
      const name = document.document_name
      const partTypes =
        name === 'lpp.pdf'
          ? ['labels', 'packing', 'pick']
          : name
              .toLowerCase()
              .replace(/(-list|\.pdf)/g, '')
              .split(/[-_]/)
              .reduce((prev, type) => {
                if (['labels', 'packing', 'pick'].includes(type)) {
                  prev.push(type)
                } else if (type === 'label') {
                  prev.push('labels')
                }

                return prev
              }, [])

      if (partTypes.includes('labels')) {
        if (!hasLabel) {
          hasLabel = true

          if (!added) {
            prev.push({
              ...document,
              hasLabel: true,
            })

            added = true
          }
        }
      }

      if (partTypes.includes('packing')) {
        if (!hasPack && !partTypes.includes('labels')) {
          hasPack = true

          if (!added) {
            prev.push({
              ...document,
              hasPacking: true,
            })

            added = true
          }
        }
      }

      if (partTypes.includes('pick')) {
        if (!hasPick && !partTypes.includes('labels')) {
          hasPick = true

          if (!added) {
            prev.push({
              ...document,
              hasPick: true,
            })

            added = true
          }
        }
      }

      return prev
    }, [])
}

export function printURLSelector(state, {referenceIDs}) {
  return formatAbodeURL('/batch', {reference_id: referenceIDs})
}

export async function receivedBatchUpdate(batchPartials) {
  const batchesByID = batchesByIDSelector(getState())

  const referenceIDs = batchPartials
    .filter(
      ({id, updated}) =>
        batchesByID[id] &&
        isAfter(new Date(updated), new Date(batchesByID[id].updated_date)),
    )
    .map(({id}) => batchesByID[id].reference_id)

  if (referenceIDs.length === 0) {
    return
  }

  await Promise.all(
    referenceIDs.map((referenceID) => ensureBatch(referenceID, {reload: true})),
  )

  await refreshBatchSummaries()
}

export function receivedBatchUpdateThrottled(
  {id: batchID, updated},
  timeout = 1000,
) {
  const func = receivedBatchUpdateThrottled

  if (func.queue[batchID]) {
    func.queue[batchID].updated = updated
  } else {
    func.queue[batchID] = {id: batchID, updated}
  }

  if (func.promise) {
    return func.promise
  }

  func.promise = new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const batchPartials = Object.values(func.queue)
        func.queue = {}
        func.promise = null

        await receivedBatchUpdate(batchPartials)
      } catch (err) {
        reject(err)
      }

      resolve()
    }, timeout)
  })

  return func.promise
}
receivedBatchUpdateThrottled.queue = {}
receivedBatchUpdateThrottled.promise = null

export function batchArchiveCountSelector(state, {referenceIDs}) {
  const batchesByReferenceID = batchesSelector(state)

  let count = 0

  for (const referenceID of referenceIDs) {
    const batch = batchesByReferenceID[referenceID]

    if (batch && batch.archived_date) {
      count += 1
    }
  }

  return count
}

export async function getRelatedBatches(orderNumbers) {
  const referenceIDs = batchReferenceIDsFromOrdersSelector(getState(), {
    orderNumbers,
  })

  if (referenceIDs.length === 0) {
    return {}
  }

  const {
    json: {batch: batches},
  } = await apiverson.get('/batch', {
    reference_id: referenceIDs,
  })

  return orderNumbers.reduce((prev, orderNumber) => {
    const batch = batches.find((batch) =>
      batch.order_numbers.includes(orderNumber),
    )

    if (batch) {
      prev[orderNumber] = batch
    }

    return prev
  }, {})
}

export async function startBatchPrintTask(
  referenceID,
  document,
  printerID,
  hasLabel,
) {
  await apiverson.post(`/batch/${encodeURIComponent(referenceID)}/action`, {
    params: {
      action: {type: 'mult-stage-processing'},
      actions: [
        {
          type: 'send_to_printer',
          stop_on_error: true,
          data: {
            content_type: 'pdf_uri',
            printer_id: printerID,
            content_url: document.cache_url,
          },
        },
        ...(hasLabel
          ? [
              {
                type: 'endpoint',
                data: {
                  method: 'post',
                  url_parts: ['mark_as_printed'],
                },
              },
            ]
          : []),
      ],
    },
    comment: `Task started to InstaPrint the document from this printer: ${printerNameSelector(
      getState(),
      {printerID},
    )}`,
  })
}

export async function startBatchMarkAsPrintedTask(referenceID) {
  await apiverson.post(`/batch/${encodeURIComponent(referenceID)}/action`, {
    params: {
      action: {type: 'mult-stage-processing'},
      actions: [
        {
          type: 'endpoint',
          data: {
            method: 'post',
            url_parts: ['mark_as_printed'],
          },
        },
      ],
    },
    comment: 'Task started to mark orders as printed',
  })
}
