import PropTypes from 'prop-types'

export default function NumberInput({text, id, ...props}) {
  return (
    <div className="flex-item">
      <label className="label--bold label--sm" htmlFor={id}>
        {text}
      </label>
      <div className="flex">
        <input
          className="input--x-sm inline-block"
          type="number"
          id={id}
          {...props}
        />
        <span className="input__unit inline-block">in</span>
      </div>
    </div>
  )
}

NumberInput.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
}
