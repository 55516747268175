import {dispatch, useSelector} from '../../store.js'
import ButtonAction from '../../common/components/Button/ButtonAction.js'
import {showCreatePOModal} from '../ProductListPage/Modals/CreatePOModal/createPOModalActions.js'
import AddProductTagsDropdown from '../ProductListPage/AddProductTagsDropdown.js'
import {showCreateInventoryTransferModal} from '../ProductListPage/Modals/CreateInventoryTransferModal/createInventoryTransferModalActions.js'
import {showBarcodeLabelModal} from '../ProductListPage/Modals/BarcodeLabelModal.js'
import {
  exportInventoryLog,
  isArchivedSelector,
  isKitSelector,
  isBOMSelector,
} from '../../data/products.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {showArchiveProductModal} from '../ProductListPage/Modals/ArchiveProductModal.js'
import {showSetupProductComponentModal} from '../ProductListPage/Modals/SetupProductComponentModal.js'
import {
  usesInventorySelector,
  canTransferInventorySelector,
  showFeatureLocksSelector,
  usePurchaseOrdersSelector,
  useKittingSelector,
} from '../../data/company.js'
import {skuSelector} from './productDetailSelectors.js'
import {hasMOFeatureSelector} from '../../data/mos.js'
import {hasMOPermissionSelector} from '../../data/me.js'
import {showCreateMOModal} from '../MOListPage/Modals/CreateMOModal.js'

export default function Controls() {
  const sku = useSelector(skuSelector)
  const isArchived = useSelector((state) => isArchivedSelector(state, {sku}))
  const canTransferInventory = useSelector(canTransferInventorySelector)
  const usesInventory = useSelector(usesInventorySelector)
  const useKitting = useSelector(useKittingSelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const showFeatureLocks = useSelector(showFeatureLocksSelector)
  const hasMOFeature = useSelector(hasMOFeatureSelector)
  const hasMOPermission = useSelector(hasMOPermissionSelector)
  const isKit = useSelector((state) => isKitSelector(state, {sku}))
  const isBOM = useSelector((state) => isBOMSelector(state, {sku}))
  const canConvert = hasMOFeature || useKitting

  return (
    <div className="wrap--row">
      <div className="medium-12 columns">
        <div className="wrap--list-header flex--justify">
          <ul className="list list--no-style">
            <li className="inline-block v-align-base divider--right">
              <a
                href="#/products"
                className="btn btn--action"
                title="Back to products"
              >
                &#8592; Products
              </a>
            </li>
            {(showFeatureLocks || usePurchaseOrders) && (
              <li className="inline-block fs-n0 v-align-middle margin-right-5">
                {!usePurchaseOrders && (
                  <span
                    className="i-lock-closed fs-01 v-align-middle lh-lg no-underline op-30 margin-right-1"
                    aria-hidden="true"
                  />
                )}
                <ButtonAction
                  className={`${usePurchaseOrders ? '' : 'op-50'}`}
                  onClick={() =>
                    usePurchaseOrders
                      ? showCreatePOModal([sku])
                      : showFeatureLockedModal('po')
                  }
                >
                  Order More
                </ButtonAction>
              </li>
            )}
            {hasMOPermission && isBOM && (showFeatureLocks || hasMOFeature) && (
              <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
                {!hasMOFeature && (
                  <span
                    className="i-lock-closed fs-01 v-align-middle lh-lg no-underline op-30 margin-right-1"
                    aria-hidden="true"
                  />
                )}
                <ButtonAction
                  className={`${hasMOFeature ? '' : 'op-50'}`}
                  onClick={() =>
                    hasMOFeature
                      ? showCreateMOModal({skus: [sku]})
                      : showFeatureLockedModal('mo')
                  }
                >
                  Initiate a MFG Order
                </ButtonAction>
              </li>
            )}
            <AddProductTagsDropdown skus={[sku]} />
            {(showFeatureLocks || canTransferInventory) && (
              <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
                {!canTransferInventory && (
                  <span
                    className="i-lock-closed fs-01 v-align-middle lh-lg no-underline op-30 margin-right-1"
                    aria-hidden="true"
                  />
                )}
                <ButtonAction
                  className={`${canTransferInventory ? '' : 'op-50'}`}
                  onClick={() =>
                    canTransferInventory
                      ? dispatch(showCreateInventoryTransferModal([sku]))
                      : showFeatureLockedModal('inventory')
                  }
                >
                  Transfer Inventory
                </ButtonAction>
              </li>
            )}
            <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
              <ButtonAction onClick={() => showBarcodeLabelModal([sku])}>
                Print Barcodes
              </ButtonAction>
            </li>
            {(showFeatureLocks || usesInventory) && (
              <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
                {!usesInventory && (
                  <span
                    className="i-lock-closed fs-01 v-align-middle lh-lg no-underline op-30 margin-right-1"
                    aria-hidden="true"
                  />
                )}
                <ButtonAction
                  className={`${usesInventory ? '' : 'op-50'}`}
                  onClick={() =>
                    usesInventory
                      ? exportInventoryLog(sku)
                      : showFeatureLockedModal('inventory')
                  }
                >
                  Export Inventory Log
                </ButtonAction>
              </li>
            )}
            {(showFeatureLocks || canConvert) && !isKit && !isBOM && (
              <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
                {!canConvert && (
                  <span
                    className="i-lock-closed fs-01 v-align-middle lh-lg no-underline op-30 margin-right-1"
                    aria-hidden="true"
                  />
                )}
                <ButtonAction
                  className={`${canConvert ? '' : 'op-50'}`}
                  onClick={() =>
                    canConvert
                      ? showSetupProductComponentModal(sku)
                      : showFeatureLockedModal('mo')
                  }
                >
                  Convert to{' '}
                  {[useKitting ? 'Kit' : '', hasMOFeature ? 'BOM' : '']
                    .filter((v) => v)
                    .join('/')}
                </ButtonAction>
              </li>
            )}
            <li className="inline-block fs-n0 v-align-middle margin-left-15 margin-right-5">
              <ButtonAction
                onClick={() =>
                  dispatch(showArchiveProductModal([sku], !isArchived))
                }
              >
                {isArchived ? 'Unarchive' : 'Archive'}
              </ButtonAction>
            </li>
          </ul>
        </div>
        <hr className="hr--detail-page margin-top-3" />
      </div>
    </div>
  )
}
