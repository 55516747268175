import PropTypes from 'prop-types'

import {
  orderAlternateShipFromAddressSelector,
  orderWarehouseNameSelector,
} from '../../../../../../../data/orders.js'
import {warehouseCountSelector} from '../../../../../../../data/warehouses.js'

import LabelItem from './LabelItem.js'
import ButtonLink from '../../../../../../../common/components/Button/ButtonLink.js'
import {useSelector} from '../../../../../../../store.js'
import {showEditOrderWarehouseModal} from '../../../../../../OrderListPage/Modals/EditOrderWarehouseModal.js'

export default function AssignedWarehouse({orderNumber}) {
  const warehouseName = useSelector((state) =>
    orderWarehouseNameSelector(state, {orderNumber}),
  )
  const warehouseCount = useSelector(warehouseCountSelector)
  const alternateShipFromAddress = useSelector((state) =>
    orderAlternateShipFromAddressSelector(state, {orderNumber}),
  )

  if (!alternateShipFromAddress || warehouseCount === 1) {
    return null
  }

  return (
    <LabelItem label="Assigned To">
      <ButtonLink onClick={() => showEditOrderWarehouseModal([orderNumber])}>
        {warehouseName}
      </ButtonLink>
    </LabelItem>
  )
}

AssignedWarehouse.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
