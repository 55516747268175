import PropTypes from 'prop-types'

import {closeModal, navigate} from '../../../redux/actions/ui/index.js'
import {hasPrintNodeAPIKeySelector} from '../../../data/company.js'
import {
  scalesSelector,
  hasPrintNodeScaleSelector,
  isReadingScaleSelector,
  readScale,
} from '../../../data/printNode.js'
import {dispatch, useSelector} from '../../../store.js'
import className from '../../className.js'
import ButtonPrimary from '../Button/ButtonPrimary.js'
import {HK_READ_SCALE} from '../../constants/HotKeys.js'

export default function ScaleControl({onChange}) {
  const hasPrintNodeScale = useSelector(hasPrintNodeScaleSelector)
  const hasPrintNodeAPIKey = useSelector(hasPrintNodeAPIKeySelector)
  const hasScales = useSelector(scalesSelector).length !== 0
  const isReadingScale = useSelector(isReadingScaleSelector)

  if (!hasPrintNodeScale) {
    return null
  }

  if (!hasScales || !hasPrintNodeAPIKey) {
    return (
      <ButtonPrimary
        isOutlined
        size="xx-sm"
        className="margin-bottom-5 meta-usb-scale-setup"
        onClick={() => {
          dispatch(closeModal())

          dispatch(navigate('#/settings/scales'))
        }}
        hotKey={HK_READ_SCALE}
      >
        <span
          className="i-scales fs-01 lh-sm v-align-middle"
          aria-hidden="true"
        />
        {!hasPrintNodeAPIKey && <span className="btn__text--scale">SETUP</span>}
        {hasPrintNodeAPIKey && !hasScales && (
          <span className="btn__text--scale">NO SCALE</span>
        )}
      </ButtonPrimary>
    )
  }

  return (
    <ButtonPrimary
      alt
      size="xx-sm"
      className={className`margin-bottom-5 meta-usb-scale ${
        isReadingScale && 'btn--processing'
      }`}
      onClick={async (event) => {
        if (event) {
          event.preventDefault()
        }

        const selectedScaleWeight = await readScale()

        if (selectedScaleWeight !== null) {
          onChange(selectedScaleWeight)
        }
      }}
      disabled={isReadingScale}
      title={isReadingScale ? 'Reading weight...' : 'Read weight from scale'}
      hotKey={HK_READ_SCALE}
    >
      <span
        className="i-scales fs-01 lh-sm v-align-middle"
        aria-hidden="true"
      />
      <span className="btn__text--scale">WEIGH</span>
    </ButtonPrimary>
  )
}

ScaleControl.propTypes = {
  onChange: PropTypes.func.isRequired,
}
