import PropTypes from 'prop-types'
import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'
import {
  CurrentPackingListShape,
  ErrorsShape,
} from '../../../common/PropTypes.js'
import {
  SETTINGS_PROFILES_URL,
  PROFILES_GENERAL,
} from '../../../redux/actions/ui/settings/packingLists/index.js'
import Form from './Form.js'

export default function NewForm(props) {
  const {
    currentPackingList,
    isNew,
    selectedProfileTab,
    isSaving,
    isUploading,
    logoURL,
    errors,
    hasErrors,
    updateCurrentPackingList,
    savePackingList,
    handleLogoFile,
  } = props

  return (
    <li
      className={classNames(
        'settings-list-item add-item add-sales-channel clearfix',
        {expanded: isNew},
      )}
    >
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={`${SETTINGS_PROFILES_URL}/${PROFILES_GENERAL}/${NEW_ID}`}
            title="Create a packing list"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Create a new profile</span>
          </a>
        </p>
      </div>
      {isNew && (
        <Form
          currentPackingList={currentPackingList}
          isNew={isNew}
          selectedProfileTab={selectedProfileTab}
          isSaving={isSaving}
          isUploading={isUploading}
          logoURL={logoURL}
          errors={errors}
          hasErrors={hasErrors}
          updateCurrentPackingList={updateCurrentPackingList}
          savePackingList={savePackingList}
          handleLogoFile={handleLogoFile}
        />
      )}
    </li>
  )
}

NewForm.propTypes = {
  currentPackingList: CurrentPackingListShape.isRequired,
  isNew: PropTypes.bool.isRequired,
  selectedProfileTab: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  logoURL: PropTypes.string,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateCurrentPackingList: PropTypes.func.isRequired,
  savePackingList: PropTypes.func.isRequired,
  handleLogoFile: PropTypes.func.isRequired,
}
