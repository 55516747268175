import Quantity from '../../common/components/Quantity.js'
import {useSelector} from 'react-redux'
import Currency from '../../common/components/Currency.js'
import {PRODUCT_PANEL} from '../../common/constants/Analytics.js'
import {showImgsProductDetailSelector} from '../../data/company.js'
import {hasProductPermissionSelector} from '../../data/me.js'
import {analyticsHashBuilder} from '../AnalyticsPage/analyticsSelectors.js'
import {getProductDetailURL} from '../ProductDetailPage/productDetailSelectors.js'
import {
  analyticsLinkParamsSelector,
  quickAnalyticsFormSelector,
  topProductSelector,
} from './quickAnalyticsFunctions.js'

export default function ProductStats() {
  const form = useSelector(quickAnalyticsFormSelector)
  const analyticsLinkParams = useSelector(analyticsLinkParamsSelector)
  const topProduct = useSelector(topProductSelector)
  const showImgsProductDetail = useSelector(showImgsProductDetailSelector)
  const hasProductPermission = useSelector(hasProductPermissionSelector)

  return (
    <div className="row flex">
      <div className="medium-12 columns flex">
        <div className="panel panel--analytics panel--full-width-graph clearfix w-100 ">
          <div className="panel__header panel__header--analytics flex--justify">
            <h3 className="subheader subheader--analytics fs-01">
              Product Stats
            </h3>
            <a
              href={analyticsHashBuilder(PRODUCT_PANEL, analyticsLinkParams)}
              className="btn btn--link fs-00 lh-x-md"
            >
              View All →
            </a>
          </div>
          {form.isLoading ? (
            <div className="loading">
              <p className="graph-loading" />
            </div>
          ) : !topProduct ? (
            <div
              className="wrap wrap--graph wrap--line-graph margin-top-25 margin-bottom-25 margin-left-25"
              style={{minHeight: '5rem', position: 'relative'}}
            >
              <dl className="list--horiz-bar-graph list--horiz-bar-graph-none list--sparkline-none align-center hide-for-loading">
                <dt className="list__title fs-01 margin-bottom-0">
                  <strong>No data to display for this date range</strong>
                </dt>
                <dd className="list__item fs-00">
                  Adjust your dates and try again
                </dd>
              </dl>
            </div>
          ) : (
            <div className="padding-top-20 padding-bottom-20">
              <div className="wrap--row">
                <div className="medium-12 columns">
                  <div className="flex divider--bottom padding-bottom-20 margin-bottom-25 flex--reset-sm">
                    {showImgsProductDetail && topProduct.product.image_url && (
                      <div className="wrap--product-img margin-right-15">
                        <img
                          src={topProduct.product.image_url}
                          alt={topProduct.product.name}
                        />
                      </div>
                    )}
                    <div className="flex--justify-col flex--reset-sm">
                      <dl className="list">
                        <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                          Top-Selling Product
                        </dt>
                        <dd className="list__item fs-04 lh-sm flex margin-bottom-0">
                          <div className="lh-sm-md">
                            {hasProductPermission ? (
                              <a
                                className="btn btn--link darker"
                                href={getProductDetailURL(
                                  topProduct.product.sku,
                                )}
                              >
                                <strong>{topProduct.product.name}</strong>
                              </a>
                            ) : (
                              <strong>{topProduct.product.name}</strong>
                            )}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap--row flex flex--reset-sm">
                <div className="medium-4 columns divider--right">
                  <dl className="list">
                    <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                      Order Count
                    </dt>
                    <dd className="list__item fs-04 lh-sm margin-bottom-0">
                      <strong>
                        <Quantity value={topProduct.total_orders} />
                      </strong>
                    </dd>
                  </dl>
                </div>
                <div className="medium-3 columns divider--right">
                  <dl className="list">
                    <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                      Units Sold
                    </dt>
                    <dd className="list__item fs-04 lh-sm margin-bottom-0">
                      <strong>
                        <Quantity value={topProduct.total_products_purchased} />
                      </strong>
                    </dd>
                  </dl>
                </div>
                <div className="medium-5 columns">
                  <dl className="list">
                    <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                      Total Revenue from Product
                    </dt>

                    <dd className="list__item fs-04 lh-sm margin-bottom-0">
                      <strong>
                        <Currency value={topProduct.total_product_sales} />
                      </strong>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
