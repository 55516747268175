import PropTypes from 'prop-types'

import {
  getState,
  onlyIfAutoForm,
  formSelector,
  updateForm,
} from '../../../../store.js'
import {isEmptyValue, isPositiveNumeric} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {AMAZON} from '../../../../common/constants/CartVendorOptions.js'
import {redirect} from '../../../../common/location.js'
import {cartSelector} from '../../../../data/carts.js'
import FormList from '../../Common/FormList.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import AmazonFulfillmentLatencyInput from '../AmazonFulfillmentLatencyInput.js'
import AmazonSFPSetup from '../AmazonSFPSetup.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'
import Select from '../../../../common/components/Select.js'

export const MARKETPLACE_IDS = {
  US: 'ATVPDKIKX0DER',
  UK: 'A1F83G8C2ARO7P',
  CA: 'A2EUQ1WTGCTBG2',
  MX: 'A1AM78C64UM0Y8',
}
const MARKETPLACE_ID_OPTIONS = Object.entries(MARKETPLACE_IDS).map(
  ([key, value]) => ({value, display: key}),
)
const MARKETPLACE_REGIONS = Object.entries(MARKETPLACE_IDS).reduce(
  (prev, [key, value]) => {
    prev[value] = key
    return prev
  },
  {},
)

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (
    !(
      isEmptyValue(form.fulfillmentLatency) ||
      isPositiveNumeric(form.fulfillmentLatency)
    )
  ) {
    errors.fulfillmentLatency = 'Value must be a positive number or blank'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: AMAZON}),
      fulfillmentLatency: '',
      marketplace_id: MARKETPLACE_IDS.US,
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    fulfillmentLatency: String(cart.vendor_config.fulfillment_latency || ''),
    marketplace_id: cart.vendor_config.marketplace_id || MARKETPLACE_IDS.US,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'fulfillment_latency') {
    errors.fulfillmentLatency = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  const fulfillmentLatency = isPositiveNumeric(form.fulfillmentLatency)
    ? Number(form.fulfillmentLatency)
    : null

  return {
    ...baseSaveParamsSelector(state, {formName}),
    fulfillment_latency: fulfillmentLatency,
  }
}

export function authorize(formName) {
  const {marketplace_id} = formSelector(getState(), {formName})

  const params = {
    marketplace_id,
  }

  return redirect('', ['verde', 'amazon_sp', 'start'], params)
}

function Amazon({form, formName}) {
  const isNew = form.id === NEW_ID

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <>
              <li className="list__item--form fs-n0 margin-bottom-10">
                <Select
                  className="w-5rem"
                  label="Marketplace"
                  id="marketplace_id"
                  value={form.marketplace_id}
                  onChange={(marketplace_id) =>
                    updateForm(formName, {marketplace_id})
                  }
                  options={MARKETPLACE_ID_OPTIONS}
                ></Select>
              </li>
              <AuthorizeMessage formName={formName} />
              <li className="list__item--form fs-n0 margin-bottom-10">
                <em>
                  Also, in compliance with{' '}
                  <a
                    href="https://support.ordoro.com/amazon-data-protection-policy-compliance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Amazon’s Data Protection Policy
                  </a>
                  , some Amazon order data will be removed from your Ordoro
                  account after 30 days.
                </em>
              </li>
            </>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName}>
                <div className="lh-sm margin-top-5 margin-bottom-10">
                  <strong className="fs-00">Marketplace: </strong>
                  {MARKETPLACE_REGIONS[form.marketplace_id]}
                </div>
              </ReauthorizeButton>
              <AmazonFulfillmentLatencyInput
                className="divider--top"
                formName={formName}
              />
              <AmazonSFPSetup className="divider--top" />
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Amazon"
          href="https://support.ordoro.com/amazon-us-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

Amazon.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    marketplace_id: PropTypes.string.isRequired,
  }),
}

export default onlyIfAutoForm(Amazon, setUpCartForm)
