import {useEffect} from 'react'
import classNames from 'classnames'

import {getShipperBalanceThrottled} from '../../../data/shipperBalances.js'
import {showPostageBalanceModal} from '../Modals/PostageBalanceModal.js'
import {isEmptyValue} from '../../../common/utils.js'
import {
  labelBalanceSelector,
  canAddPostageSelector,
  insufficientBalanceSelector,
  labelShipperSelector,
} from '../../../data/labelInfos/index.js'

import Currency from '../../../common/components/Currency.js'
import {useSelector} from '../../../store.js'
import {SHIPPERS_WITH_POSTAGE_BALANCES} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function Balance() {
  const {labelInfoID} = useLabelConfigContext()
  const shipper = useSelector((state) =>
    labelShipperSelector(state, {labelInfoID}),
  )
  const balance = useSelector((state) =>
    labelBalanceSelector(state, {labelInfoID}),
  )
  const insufficientBalance = useSelector((state) =>
    insufficientBalanceSelector(state, {labelInfoID}),
  )
  const canAddPostage = useSelector((state) =>
    canAddPostageSelector(state, {labelInfoID}),
  )
  const isShipperWithBalance = shipper
    ? SHIPPERS_WITH_POSTAGE_BALANCES.includes(shipper.vendor)
    : false

  useEffect(() => {
    if (shipper) {
      getShipperBalanceThrottled(shipper.id)
    }
  }, [shipper, labelInfoID])

  if (!isShipperWithBalance) {
    return null
  }

  return (
    <small
      className={classNames('fs-n1 wrap--usps-balance padding-top-1', {
        'error-message': insufficientBalance,
      })}
    >
      {insufficientBalance && (
        <span
          className="i--error fs-00 lh-sm v-align-middle margin-right-3"
          aria-hidden="true"
        />
      )}
      {isEmptyValue(balance) ? (
        <span className="text--lt-grey margin-top-3">
          <span className="spinner--x-sm v-align-middle" />{' '}
          <em className="v-align-middle">Loading balance...</em>
        </span>
      ) : canAddPostage ? (
        <button
          className="btn btn--link btn--balance mid v-align-base"
          type="button"
          onClick={() => showPostageBalanceModal(shipper.id)}
        >
          <strong>Balance: </strong>
          <Currency value={balance} currencySymbol="$" />
        </button>
      ) : (
        <span className="mid v-align-base">
          <strong>Balance: </strong>
          <Currency value={balance} currencySymbol="$" />
        </span>
      )}
    </small>
  )
}
