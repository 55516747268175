import {createSelector} from 'reselect'

import {STRIPE_PAYMENTS} from '../../../../../common/constants/Integrations.js'

export const editIDSelector = createSelector(
  (state) => state.ui.settings.payments,
  ({editID}) => editID,
)

export function paymentIntegrationOptionsSelector() {
  return [{value: STRIPE_PAYMENTS, display: 'Stripe'}]
}

export const paymentsHashSelector = createSelector(editIDSelector, (editID) => {
  if (editID) {
    return `#/settings/payments/${editID}`
  }

  return '#/settings/payments'
})
