import {createSelector} from 'reselect'
import {sortedTasksSelector} from './tasks.js'

export function isRunningTasksSelector(state) {
  return state.data.isRunningTasks
}

export const isRunningExportSelector = createSelector(
  sortedTasksSelector,
  (tasks) =>
    !!tasks.find(
      (task) => task.table_key === 'export_task' && task.complete === false,
    ),
)
