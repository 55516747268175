import {useMemo} from 'react'

import {useSelector} from '../../../store.js'
import {TAG_TYPE__ORDER} from '../../../common/constants/Tags.js'
import ManageTagFilter from '../../../common/components/ManageTagFilter.js'
import {
  orderTagOptionsSelector,
  orderTagOptionTokensSelector,
  orderTagsSelector,
} from '../../../data/orderTags.js'
import {
  hasOrderManageTagPermissionSelector,
  hasOrderManageTagOnOrderPermissionSelector,
} from '../../../data/me.js'
import {createAndApplyTagToNewOrder, toggleTag} from '../createOrderActions.js'
import {createOrderTagIDsSelector} from '../createOrderSelectors.js'

export default function TagsDropdown() {
  const tagIDs = useSelector(createOrderTagIDsSelector)
  const orderTags = useSelector(orderTagsSelector)
  const orderTagOptions = useSelector(orderTagOptionsSelector)
  const orderTagOptionTokens = useSelector(orderTagOptionTokensSelector)
  const tagQuantityMap = useMemo(
    () =>
      Object.keys(orderTags).reduce((prev, tagID) => {
        prev[tagID] = tagIDs.includes(Number(tagID)) ? 'all' : 'none'

        return prev
      }, {}),
    [orderTags, tagIDs],
  )
  const hasOrderManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const hasOrderManageTagOnOrderPermission = useSelector(
    hasOrderManageTagOnOrderPermissionSelector,
  )

  if (!hasOrderManageTagOnOrderPermission) {
    return null
  }

  return (
    <ManageTagFilter
      dropdown="APPLY_CREATE_ORDER_TAG_FILTER"
      onSelect={(tag) => toggleTag(tag.id, tagQuantityMap[tag.id] !== 'all')}
      tagOptions={orderTagOptions}
      tagOptionTokens={orderTagOptionTokens}
      tagQuantityMap={tagQuantityMap}
      hasManageTagPermission={hasOrderManageTagPermission}
      tagType={TAG_TYPE__ORDER}
      onCreateAndApplyClick={() => createAndApplyTagToNewOrder()}
    />
  )
}
