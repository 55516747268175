import PropTypes from 'prop-types'

import {isNumeric} from '../../../common/utils.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {showMultiboxModal} from '../../OrderListPage/Modals/MultiboxModal/index.js'
import {
  updateParcel,
  parcelSelector,
  isMultiboxSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {FEDEX, UPS} from '../../../common/constants/ShipperNames.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export const LABEL_PROPERTY_DRY_ICE_WEIGHT = 'packages[0].dry_ice_weight'
function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [LABEL_PROPERTY_DRY_ICE_WEIGHT]
    : []
}
const VALID_SHIPPER_TYPES = [FEDEX, UPS]

export default function DryIceWeight({packagesIndex}) {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  packagesIndex = packagesIndex || 0

  let {dry_ice_weight: dryIceWeight} = useSelector((state) =>
    parcelSelector(state, {labelInfoID, packagesIndex}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  dryIceWeight = isNumeric(dryIceWeight) ? dryIceWeight : ''

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_DRY_ICE_WEIGHT,
    labelPropertiesFunc,
    validShipperTypes: VALID_SHIPPER_TYPES,
  })

  const hide = !VALID_SHIPPER_TYPES.includes(shipperType)

  if (hide) {
    return null
  }

  return (
    <li
      className={className`list__item--shipping-options ${formatMetaClassName(
        LABEL_PROPERTY_DRY_ICE_WEIGHT,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <div className="flex">
          <label className="label--bold" htmlFor="dry-ice-weight">
            Dry Ice Weight (oz)
          </label>
          {isMultibox && (
            <button
              className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
              type="button"
              onClick={() => showMultiboxModal(labelInfoID, shipperType)}
            >
              <em>(Multiple)</em>
            </button>
          )}
        </div>
        <NumberInput
          id="dry-ice-weight"
          value={isMultibox ? '' : dryIceWeight}
          disabled={
            isMultibox || (isLabelEnableMode && !isLabelPropertyEnabled)
          }
          onChange={(value) => {
            updateParcel(labelInfoID, packagesIndex, {dry_ice_weight: value})

            onChange()
          }}
        />
      </div>
      <EnableLabelProperty labelProperty={LABEL_PROPERTY_DRY_ICE_WEIGHT} />
    </li>
  )
}

DryIceWeight.propTypes = {
  packagesIndex: PropTypes.number,
}
