import isEmpty from 'lodash/isEmpty.js'
import {createSelector} from 'reselect'

import {hasSettingsPermissionSelector} from '../../../../data/me.js'

export const dataImportSelector = (state) => state.ui.dataImport

export const saveableIndexesSelector = createSelector(
  (state) => state.ui.dataImport.data,
  (allData) =>
    allData.reduce((sum, data, index) => {
      if (!data.isSaved && !data.isDeleted && isEmpty(data.errors)) {
        sum.push(index)
      }

      return sum
    }, []),
)

export const canUseDataImportSelector = createSelector(
  hasSettingsPermissionSelector,
  (hasPermission) => hasPermission,
)
