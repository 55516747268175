import {useEffect} from 'react'
import analytics from '../../../common/analytics/index.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showFeatureLockedModal} from '../../Modals/FeatureLockedModal.js'
import {usesAppsSelector} from '../../../data/company.js'
import {useSelector} from '../../../store.js'
import {
  DROPSHIP_APP_PANEL,
  showUpgradeModal,
} from '../../Modals/UpgradeModal.js'

export default function DropshipFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)

  useEffect(() => {
    analytics.trackEvent('dropship_feature_lock')
  }, [])

  return (
    <>
      <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
          <p className="align-center fs-01 lh-md  margin-bottom-15">
            <strong>Enjoy Hands-Free Dropshipping</strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-20">
            Automatically route orders, and meticulously track your inventory
            across all of your suppliers. Dropshipping has never been easier!
          </p>
          {usesApps ? (
            <p className="fs-00 lh-lg margin-bottom-20">
              To gain access to our{' '}
              <a
                className="btn btn--link darker border-underline v-align-base"
                href="https://support.ordoro.com/how-to-set-up-products-for-dropshipping/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Dropshipping</span>
              </a>{' '}
              features, you need to add the <strong>Dropshipping App</strong> to
              your account.
            </p>
          ) : (
            <p className="fs-00 lh-lg margin-bottom-20">
              Upgrade your account with our{' '}
              <strong>Inventory Management Module</strong> to gain access to{' '}
              <a
                className="btn btn--link darker border-underline v-align-base"
                href="https://support.ordoro.com/how-to-set-up-products-for-dropshipping/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Dropshipping</span>
              </a>{' '}
              and other supplier features.
            </p>
          )}

          <div>
            {usesApps ? (
              <ButtonPrimary
                size="x-md"
                className="meta--feature-lock-dropshipping"
                alt
                onClick={() => showUpgradeModal({panel: DROPSHIP_APP_PANEL})}
              >
                Enable Dropshipping
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                size="x-md"
                className="meta--feature-lock-dropshipping"
                alt
                onClick={() => showFeatureLockedModal('inventory')}
              >
                Get the Inventory Management Module
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
