import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {HotKeyConnect} from './HotKeys.js'
import {HK_CANCEL} from '../constants/HotKeys.js'
import {searchForAttribute} from '../dom.js'

export default function FocusListener({attrs, onFocusLost}) {
  attrs = attrs || []

  useEffect(() => {
    const onClickHandle = (event) => {
      const nodes = attrs
        .map((attr) =>
          searchForAttribute(event.target, ...Object.entries(attr)[0]),
        )
        .filter((n) => n)

      if (nodes.length === 0) {
        onFocusLost()
      }
    }

    document.addEventListener('click', onClickHandle, false)

    return () => document.removeEventListener('click', onClickHandle)
  }, [])

  useEffect(() => {
    const onFocusHandle = (event) => {
      const nodes = attrs
        .map((attr) =>
          searchForAttribute(event.target, ...Object.entries(attr)[0]),
        )
        .filter((n) => n)

      if (nodes.length === 0) {
        onFocusLost()
      }
    }

    document.addEventListener('focus', onFocusHandle, true)

    return () => document.removeEventListener('focus', onFocusHandle, true)
  }, [])

  return <HotKeyConnect code={HK_CANCEL} func={() => onFocusLost()} />
}

FocusListener.propTypes = {
  attrs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFocusLost: PropTypes.func.isRequired,
}
