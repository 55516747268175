import ListItemsCount from '../../common/components/List/ListItemsCount.js'
import {plural} from '../../common/components/Plural.js'
import {useSelector} from '../../store.js'
import {
  productListQuerySelector,
  countSelector,
} from './productListFunctions.js'

export default function ProductListItemsCount() {
  const {limit, offset} = useSelector(productListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <li className="inline-block margin-right-3">
      <ListItemsCount
        limit={limit}
        offset={offset}
        count={count}
        thing={plural(count)`Product${['s']}`}
      />
    </li>
  )
}
