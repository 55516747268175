import {
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  PITNEY_CBDS,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../../../common/constants/ShipperNames.js'

import AmazonSFPForm from './AmazonSFPForm.js'
import AmazonShipperForm from './AmazonShipperForm.js'
import AustraliaPostForm from './AustraliaPostForm.js'
import CanadaPostForm from './CanadaPostForm.js'
import PitneyCBDSForm from './PitneyCBDSForm.js'
import DHLForm from './DHLForm.js'
import DHLEcommerceForm from './DHLEcommerceForm.js'
import EndiciaForm from './EndiciaForm.js'
import FedExForm from './FedExForm.js'
import NewgisticsForm from './NewgisticsForm.js'
import PitneyForm from './PitneyForm.js'
import PitneyMerchantForm from './PitneyMerchantForm.js'
import PitneyPresortForm from './PitneyPresortForm.js'
import SendleForm from './SendleForm.js'
import UPSForm from './UPSForm/index.js'
import VisibleUSPSForm from './VisibleUSPSForm.js'
import XDeliveryForm from './XDeliveryForm.js'

export default {
  [AMAZON_SFP]: AmazonSFPForm,
  [AMAZON_SHIPPER]: AmazonShipperForm,
  [AUSTRALIA_POST]: AustraliaPostForm,
  [CANADA_POST]: CanadaPostForm,
  [PITNEY_CBDS]: PitneyCBDSForm,
  [DHL]: DHLForm,
  [DHL_ECOMMERCE]: DHLEcommerceForm,
  [ENDICIA]: EndiciaForm,
  [FEDEX]: FedExForm,
  [NEWGISTICS]: NewgisticsForm,
  [PITNEY]: PitneyForm,
  [PITNEY_MERCHANT]: PitneyMerchantForm,
  [PITNEY_PRESORT]: PitneyPresortForm,
  [SENDLE]: SendleForm,
  [UPS]: UPSForm,
  [VISIBLE_USPS]: VisibleUSPSForm,
  [X_DELIVERY]: XDeliveryForm,
}
