import PropTypes from 'prop-types'
import {useMemo} from 'react'
import isNil from 'lodash/isNil.js'

import {LabelInfoIDShape} from '../../../../../../common/PropTypes.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import FormattedRateCost from '../../../../../../common/components/FormattedRateCost.js'

import EditWeight from './Fields/EditWeight.js'
import EditDimensions from './Fields/EditDimensions.js'
import RequestedShippingMethod from './Fields/RequestedShippingMethod.js'
import ListItem from './Fields/ListItem.js'
import LabelItem from './Fields/LabelItem.js'
import ShipFrom from './Fields/ShipFrom.js'
import PackageType from './Fields/PackageType.js'
import InsuranceItem from './Fields/InsuranceItem.js'
import PreconfiguredPackingList from './Fields/PreconfiguredPackingList.js'
import {toggleExpanded} from '../../../../../OrderListPage/orderListActions.js'
import AssignedWarehouse from './Fields/AssignedWarehouse.js'
import {useSelector} from '../../../../../../store.js'
import {
  configureIndividualBoxShapeSelector,
  isMultiboxSelector,
  labelErrorsSelector,
  labelWarningsSelector,
  makeBoxShapeNameSelector,
  makeCostSelector,
  makeLabelShipperNameSelector,
  makeShippingMethodNameSelector,
  labelShipperTypeSelector,
} from '../../../../../../data/labelInfos/index.js'
import {shipperCurrencySymbolSelector} from '../../../../../../data/shippers.js'
import LabelConfigContext from '../../../../../LabelConfig/LabelConfigContext.js'

export default function NoShippingLabel({
  orderNumber,
  labelInfoID,
  requestedShippingMethod,
  isCancelled,
  isExpanded,
  parentView,
  showShippingLabelTab,
}) {
  const labelShipperNameSelector = useMemo(
    () => makeLabelShipperNameSelector(),
    [],
  )
  const boxShapeSelector = useMemo(() => makeBoxShapeNameSelector(), [])
  const costSelector = useMemo(() => makeCostSelector(), [])
  const shippingMethodNameSelector = useMemo(
    () => makeShippingMethodNameSelector(),
    [],
  )

  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )

  const hasBoth = !!(labelInfoID && shipperType)

  const configureIndividualBoxShape = useSelector((state) =>
    configureIndividualBoxShapeSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const shipperName = useSelector((state) =>
    labelShipperNameSelector(state, {labelInfoID}),
  )
  const boxShape = useSelector((state) =>
    hasBoth
      ? boxShapeSelector(state, {labelInfoID, shipperType, packagesIndex: 0})
      : '',
  )
  const cost = useSelector((state) => costSelector(state, {labelInfoID}))
  const shippingMethodName = useSelector((state) =>
    hasBoth
      ? shippingMethodNameSelector(state, {labelInfoID, shipperType})
      : '',
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )
  const shipperCurrencySymbol = useSelector((state) =>
    shipperCurrencySymbolSelector(state, {
      labelInfoID,
    }),
  )
  const shippingLabelErrors = useSelector((state) =>
    hasBoth ? labelErrorsSelector(state, {labelInfoID}) : [],
  )
  const shippingLabelWarnings = useSelector((state) =>
    hasBoth ? labelWarningsSelector(state, {labelInfoID, shipperType}) : [],
  )

  const hasShippingLabelErrors = shippingLabelErrors.length > 0
  const hasShippingLabelWarnings = shippingLabelWarnings.length > 0
  const errorsAndWarnings = [...shippingLabelErrors, ...shippingLabelWarnings]
  const errorLevel =
    (hasShippingLabelErrors && 'error') ||
    (hasShippingLabelWarnings && 'warning') ||
    ''

  if (!hasBoth) {
    return null
  }

  return (
    <LabelConfigContext.Provider value={{labelInfoID}}>
      <div>
        <div className="flex">
          <div className="flex-grow">
            <h4 className={`list__title--order-data ${errorLevel}`}>
              {hasShippingLabelErrors && (
                <span
                  className="i--error fs-00 lh-md v-align-middle margin-right-3"
                  aria-hidden="true"
                />
              )}
              {!hasShippingLabelErrors && hasShippingLabelWarnings && (
                <span
                  className="i--validation fs-00 lh-md v-align-middle margin-right-3"
                  aria-hidden="true"
                />
              )}
              <span className="margin-right-5">Shipping Info</span>

              {(hasShippingLabelErrors || hasShippingLabelWarnings) && (
                <button
                  className="btn btn--link v-align-base unbold"
                  type="button"
                  onClick={() => toggleExpanded(orderNumber)}
                >
                  {isExpanded ? 'Hide errors' : 'View errors'}
                </button>
              )}
            </h4>

            {isExpanded &&
              (hasShippingLabelErrors || hasShippingLabelWarnings) && (
                <div
                  className={`panel panel--${errorLevel} panel--sm margin-top-5 margin-bottom-10`}
                >
                  <ul className="list--order-data square">
                    {errorsAndWarnings.map((error, key) => (
                      <li
                        key={key}
                        className={`list__item--${errorLevel} fs-n2 lh-md`}
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            <ul className="list--order-data list--no-style">
              <EditDimensions />
              <EditWeight />
              <RequestedShippingMethod method={requestedShippingMethod} />
              <ShipFrom label="Shipping From" orderNumber={orderNumber} />
              <AssignedWarehouse orderNumber={orderNumber} />
              {!shippingMethodName && <InsuranceItem />}
              <PreconfiguredPackingList orderNumber={orderNumber} />
            </ul>

            {shippingMethodName && (
              <div className="list__item--order-data list__item--shipping-info">
                <ul className="list--order-data list--no-style">
                  <LabelItem label="Carrier">{shipperName}</LabelItem>
                  <LabelItem label="Shipping Method">
                    {shippingMethodName}
                  </LabelItem>
                  <PackageType
                    labelInfoID={labelInfoID}
                    shipperType={shipperType}
                    boxShape={boxShape}
                    isMultibox={isMultibox}
                    configureIndividualBoxShape={configureIndividualBoxShape}
                  />
                  <InsuranceItem />
                  {!isNil(cost) && (
                    <LabelItem label="Estimated Cost">
                      <FormattedRateCost
                        cost={cost}
                        currencySymbol={shipperCurrencySymbol}
                      />
                    </LabelItem>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        {!isCancelled && parentView !== 'OrderDetail' && (
          <ListItem className="margin-top-5">
            <ButtonLink onClick={showShippingLabelTab}>
              Create a shipping label
            </ButtonLink>
          </ListItem>
        )}
      </div>
    </LabelConfigContext.Provider>
  )
}

NoShippingLabel.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  labelInfoID: LabelInfoIDShape,
  isCancelled: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  parentView: PropTypes.string,
  requestedShippingMethod: PropTypes.string,
  showShippingLabelTab: PropTypes.func.isRequired,
}
