import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {orderWarehouseNameSelector} from '../../../../../data/orders.js'

function OrderWarehouse({warehouseName}) {
  return <span>{warehouseName}</span>
}

OrderWarehouse.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  warehouseName: PropTypes.string,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    warehouseName: orderWarehouseNameSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(OrderWarehouse)
