import PropTypes from 'prop-types'

import {OrderShape} from '../../../../../../common/PropTypes.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'

import {showEditTrackingNumberModal} from '../../../../../OrderListPage/Modals/EditTrackingNumberModal.js'
import {showDeleteTrackingModal} from '../../../../../OrderListPage/Modals/DeleteTrackingModal.js'
import ShipDate from '../../Fields/ShipDate.js'
import ListItem from './Fields/ListItem.js'
import LabelItem from './Fields/LabelItem.js'
import AssignedWarehouse from './Fields/AssignedWarehouse.js'
import {useSelector} from '../../../../../../store.js'
import {useManualTrackingSelector} from '../../../../../../data/company.js'

export default function MarkedAsShipped({order, isCancelled}) {
  const useManualTracking = useSelector(useManualTrackingSelector)

  return (
    <div className="list__item--order-data list__item--shipping-info margin-bottom-15">
      <h4 className="list__title--order-data">Marked As Shipped</h4>

      <ul className="list--order-data list--no-style">
        {order.shipping_info.ship_date && (
          <LabelItem label="On">
            <ShipDate date={order.shipping_info.ship_date} />
          </LabelItem>
        )}
        <AssignedWarehouse orderNumber={order.order_number} />
        {!isCancelled && (
          <ListItem>
            {useManualTracking && (
              <ButtonLink
                onClick={() => showEditTrackingNumberModal(order.order_number)}
                className="divider--right"
              >
                Edit tracking info
              </ButtonLink>
            )}
            <ButtonLink
              onClick={() => showDeleteTrackingModal([order.order_number])}
            >
              Delete
            </ButtonLink>
          </ListItem>
        )}
      </ul>
    </div>
  )
}

MarkedAsShipped.propTypes = {
  order: OrderShape.isRequired,
  isCancelled: PropTypes.bool.isRequired,
}
