import {useEffect} from 'react'

import {hasPrintNodeAPIKeySelector} from '../../data/company.js'
import {setupPrintNode, getPrintNodeData} from '../../data/printNode.js'
import {useSelector} from '../../store.js'

const POLL_INTERVAL = 30 * 1000

async function refreshPrintNode(hasPrintNodeAPIKey, signal) {
  if (!hasPrintNodeAPIKey || signal.stop) {
    setupPrintNode()

    return
  }

  await getPrintNodeData()

  setTimeout(() => refreshPrintNode(hasPrintNodeAPIKey, signal), POLL_INTERVAL)
}

export default function PrintNodeListener() {
  const hasPrintNodeAPIKey = useSelector(hasPrintNodeAPIKeySelector)

  useEffect(() => {
    const signal = {stop: false}

    setupPrintNode()

    refreshPrintNode(hasPrintNodeAPIKey, signal)

    return () => {
      signal.stop = true
    }
  }, [hasPrintNodeAPIKey])

  return null
}
