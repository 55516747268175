import {useEffect} from 'react'

import {useSelector, onlyIfForm} from '../../store.js'
import {MOListFormShape} from './moListActions.js'
import {moListFormSelector} from './moListSelectors.js'
import {hasMOFeatureSelector} from '../../data/mos.js'
import Modals from './Modals/index.js'
import MOListItemsCount from './MOListItemsCount.js'
import RefreshMOs from './RefreshMOs.js'
import MOExpandAll from './MOExpandAll.js'
import MOSort from './MOSort.js'
import MOTagFiltersDropdown from './MOTagFiltersDropdown.js'
import Actions from './Actions.js'
import ActionsForSelected from './ActionsForSelected.js'
import List from './List.js'
import MOListOmniBar from './MOListOmniBar.js'
import MOStatusDropdown from './MOStatusDropdown.js'
import MOFeaturePage from './MOFeaturePage.js'
import {hasMOPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'

function MOListPage({form}) {
  useEffect(() => {
    document.title = 'Manufacturing Orders'
  }, [])

  const hasMOFeature = useSelector(hasMOFeatureSelector)
  const hasMOPermission = useSelector(hasMOPermissionSelector)

  if (!hasMOFeature) {
    return <MOFeaturePage />
  }

  if (!hasMOPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <MOListItemsCount />
                <RefreshMOs />
                <MOExpandAll />
                <li className="inline-block fs-n0 v-align-middle divider--left">
                  <strong>Filter:</strong>
                </li>
                <MOStatusDropdown />
                <MOTagFiltersDropdown />
                <MOSort />
                {form.selectedReferenceIDs.length ? (
                  <ActionsForSelected
                    referenceIDs={form.selectedReferenceIDs}
                  />
                ) : (
                  <Actions />
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <MOListOmniBar />
          </div>
        </div>
        <div className="wrap--row margin-top-10">
          <List />
        </div>
      </div>
    </div>
  )
}

MOListPage.propTypes = {
  form: MOListFormShape.isRequired,
}

export default onlyIfForm(MOListPage, moListFormSelector)
