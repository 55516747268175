import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import Checkbox from '../../common/components/Checkbox.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {
  setSupplierFilters,
  handleAnalyticsSupplierListSelection,
} from './analyticsActions.js'
import {
  filterableSuppliersSelector,
  allSuppliersSelectedSelector,
  indeterminateSuppliersSelectedSelector,
} from './analyticsSelectors.js'

const DROPDOWN = 'ANALYTICS_SUPPLIER_FILTER'

export function SupplierFilter({
  currentDropdown,
  suppliers,
  allSuppliersSelected,
  indeterminateSuppliersSelected,
}) {
  return (
    <li
      className={classNames(
        'list__item--inline-block list__item--dropdown-wrap margin-left-5 dropdown',
        {open: currentDropdown === DROPDOWN},
      )}
      data-dropdown={DROPDOWN}
    >
      <button className="btn--dropdown-toggle btn--filter-toggle" type="button">
        <span className="super-small-screen-hide">Filter by</span> Supplier
      </button>
      <ul className="list--dd-menu list--filters" data-dropdown-prevent-close>
        <li className="list__item--filter list__item--filter-select-all">
          <label className="label--filter margin-bottom-0">
            <Checkbox
              className="margin-bottom-0 margin-right-5 make-inline-block"
              checked={allSuppliersSelected}
              indeterminate={indeterminateSuppliersSelected}
              onChange={(checked) =>
                setSupplierFilters(
                  checked ? suppliers.map(({value}) => value) : [],
                )
              }
            />
            <span className="label__text--filter">
              {allSuppliersSelected ? 'Deselect All' : 'Select All'}
            </span>
          </label>
        </li>
        {suppliers.map((supplier) => (
          <li className="list__item--filter" key={supplier.value}>
            {/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: 0 */}
            <label
              className="label--filter margin-bottom-0"
              role="checkbox"
              tabIndex="0"
              aria-checked={supplier.isSelected}
              onClick={(event) =>
                handleAnalyticsSupplierListSelection(
                  supplier.value,
                  !supplier.isSelected,
                  event.shiftKey,
                )
              }
              onKeyPress={() =>
                handleAnalyticsSupplierListSelection(
                  supplier.value,
                  !supplier.isSelected,
                  event.shiftKey,
                )
              }
            >
              <Checkbox
                className="margin-bottom-0 margin-right-5 make-inline-block"
                checked={supplier.isSelected}
                tabIndex="-1"
              />
              <span
                className="label__text--filter"
                style={{userSelect: 'none'}}
              >
                {supplier.display}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </li>
  )
}

SupplierFilter.propTypes = {
  currentDropdown: PropTypes.string,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  allSuppliersSelected: PropTypes.bool.isRequired,
  indeterminateSuppliersSelected: PropTypes.bool.isRequired,
}

function mapStateToProp(state) {
  return {
    currentDropdown: currentDropdownSelector(state),
    suppliers: filterableSuppliersSelector(state),
    allSuppliersSelected: allSuppliersSelectedSelector(state),
    indeterminateSuppliersSelected:
      indeterminateSuppliersSelectedSelector(state),
  }
}

export default connect(mapStateToProp)(SupplierFilter)
