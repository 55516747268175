import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'
import isNil from 'lodash/isNil.js'

import FormattedRateCost from '../../../../../../../common/components/FormattedRateCost.js'
import ButtonLink from '../../../../../../../common/components/Button/ButtonLink.js'

import {
  shippedShippingMethodSelector,
  shippedShipperTypeSelector,
  shippedCostSelector,
  shippedShipperCurrencySymbolSelector,
} from '../../../../../../../data/orders.js'

import {setSelectedOrderNumbers} from '../../../../../../OrderListPage/orderListActions.js'
import {
  SHIPPING_TAB,
  setOrderActionPanelCurrentTab,
} from '../../../../../../OrderListPage/ActionPanel/orderActionPanelFunctions.js'

function CompactShippedRateDetails({
  orderNumber,
  shipperType,
  shippingMethod,
  cost,
  shipperCurrencySymbol,
}) {
  const showLabelTab = () => {
    setOrderActionPanelCurrentTab(SHIPPING_TAB)
    setSelectedOrderNumbers([orderNumber])
  }

  if (!shippingMethod) {
    return (
      <div>
        <ButtonLink className="v-align-base fs-n1" onClick={showLabelTab}>
          Create a label
        </ButtonLink>
      </div>
    )
  }

  return (
    <div>
      <ButtonLink className="darker v-align-base" onClick={showLabelTab}>
        <span
          className={classNames(
            'inline-block v-align-top selected-shipper',
            `selected-shipper--${shipperType}`,
          )}
        />
        <span className="fs-n1">{shippingMethod}</span>
        {!isNil(cost) && (
          <span>
            <span className="fs-n1"> - </span>
            <span className="fs-n1">
              <FormattedRateCost
                cost={cost}
                currencySymbol={shipperCurrencySymbol}
              />
            </span>
          </span>
        )}
      </ButtonLink>
    </div>
  )
}

CompactShippedRateDetails.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  shipperType: PropTypes.string,
  shippingMethod: PropTypes.string,
  cost: PropTypes.number,
  shipperCurrencySymbol: PropTypes.string.isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    shipperType: shippedShipperTypeSelector(state, {orderNumber}),
    shippingMethod: shippedShippingMethodSelector(state, {orderNumber}),
    cost: shippedCostSelector(state, {orderNumber}),
    shipperCurrencySymbol: shippedShipperCurrencySymbolSelector(state, {
      orderNumber,
    }),
  }
}

export default connect(mapStateToProps)(CompactShippedRateDetails)
