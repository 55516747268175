import PropTypes from 'prop-types'
import pick from 'lodash/pick.js'

import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import Checkbox from '../../../common/components/Checkbox.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'
import {
  REPORT_EXPORT_LOW_INVENTORY,
  REPORT_LOW_INVENTORY_REPORT,
} from '../../../common/constants/Reports.js'

export function setupExportLowInventoryForm(params = {}) {
  let {reportID} = params
  params = pick(params, ['include_kit_skus'])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  return {
    include_kit_skus: reportParams.include_kit_skus || false,
    ...params,
  }
}

export function exportLowInventoryErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportLowInventoryPayloadSelector(state, {formName}) {
  const {singleRun, include_kit_skus} = formSelector(state, {
    formName,
  })

  const payload = {
    type: singleRun ? REPORT_EXPORT_LOW_INVENTORY : REPORT_LOW_INVENTORY_REPORT,
    params: {
      ...dateFilterPayloadParamsSelector(state, {formName}),
      include_kit_skus: include_kit_skus,
    },
  }

  return payload
}

export default function ExportLowInventoryForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportLowInventoryErrorsSelector(state, {formName}),
  )

  return (
    <ul className="list">
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--no-style fs-01 margin-bottom-10">
        <strong>CSV Formatting Options:</strong>
      </li>
      <li className="list__item--no-style">
        <Checkbox
          className="margin-left-n03 wrap--label-unbold"
          id="include_kit_skus"
          label="Include Kit SKUs in report"
          checked={form.include_kit_skus}
          onChange={() =>
            updateForm(
              formName,
              {
                include_kit_skus: !form.include_kit_skus,
              },
              {stickyProps: ['include_kit_skus']},
            )
          }
        />
      </li>
    </ul>
  )
}

ExportLowInventoryForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
