import {useState} from 'react'

import {useSelector} from '../../store.js'
import OmniBar, {parseInputValue} from '../../common/components/List/OmniBar.js'
import {
  navigateToBatchListPage,
  setActive,
  setSearchText,
} from './batchListActions.js'
import {batchListQuerySelector} from './batchListSelectors.js'

export const SEARCH_TYPE = 'search'
export const ACTIVE_TYPE = 'active'

export function batchOmniBarFilterListSelector(state) {
  const {active, searchText} = batchListQuerySelector(state)

  if (
    batchOmniBarFilterListSelector.cache &&
    active === batchOmniBarFilterListSelector.cache.active &&
    searchText === batchOmniBarFilterListSelector.cache.searchText
  ) {
    return batchOmniBarFilterListSelector.cache.list
  }

  batchOmniBarFilterListSelector.cache = {
    active,
    searchText,
  }

  const list = []

  if (active !== null) {
    list.push({
      type: ACTIVE_TYPE,
      label: active ? 'Active' : 'Archived',
      value: active,
    })
  }

  if (searchText) {
    list.push({
      type: SEARCH_TYPE,
      label: searchText,
      value: searchText,
    })
  }

  batchOmniBarFilterListSelector.cache.list = list

  return list
}

export const PROCESS_VALUE_FUNCTIONS = {
  processActiveFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    const [, negate, value] =
      filterValue.match(/^([-]|not |un)?(active|archived)$/i) || []

    if (!value) {
      return outcome
    }

    outcome.stop = true

    let active = value === 'active' ? true : false
    active = negate ? !active : active

    outcome.hash = setActive(active)

    return outcome
  },
  // Put new functions before this function
  processSearchTextFilterValue(inputValue) {
    return {stop: true, hash: setSearchText(inputValue)}
  },
  // Do not put functions after this function
}

export function processInputValue(inputValue, setInputValue) {
  if (!inputValue) {
    setInputValue('')

    return
  }

  const [, filterType, filterOperator, filterValue] =
    parseInputValue(inputValue)

  let hash
  for (const processFilterValue of Object.values(PROCESS_VALUE_FUNCTIONS)) {
    const outcome = processFilterValue(
      inputValue,
      filterType,
      filterOperator,
      filterValue,
    )

    if (outcome.stop) {
      hash = outcome.hash
      break
    }
  }

  if (hash) {
    setInputValue('')

    return hash
  }

  return
}

export function removeValue(value) {
  if (value.type === ACTIVE_TYPE) {
    return setActive(null)
  } else if (value.type === SEARCH_TYPE) {
    return setSearchText('')
  }
}

export function clearAll() {
  return navigateToBatchListPage()
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  }
}

export default function BatchListOmniBar() {
  const [inputValue, setInputValue] = useState('')
  const list = useSelector(batchOmniBarFilterListSelector)

  return (
    <OmniBar
      placeholder="Enter an Batch ID or Product SKU..."
      inputValue={inputValue}
      value={list}
      onRemoveValue={(removedValue) => removeValue(removedValue)}
      onClear={() => clearAll()}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        processInputValue(inputValue, setInputValue)
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}
