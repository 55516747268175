import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../store.js'
import formConnect from '../../common/formConnect.js'
import {isPresent} from '../../common/utils.js'
import {ErrorsShape} from '../../common/PropTypes.js'
import api from '../../common/api.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../common/components/TextInput.js'
import {setWarehouse, warehouseSelector} from '../../data/warehouses.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {autoCreatePitneyShipper} from '../../data/shippers.js'
import {showWarehouseCartLocationNoticeModal} from '../settings/Modals/WarehouseCartLocationNoticeModal.js'
import {setInitialStateOfBusiness} from '../../data/company.js'
import {MODAL_FORM as EDIT_CART_LOCATION_WAREHOUSES_MODAL} from '../settings/Modals/EditCartLocationWarehousesModal.js'

const WAREHOUSE_MODAL = 'WAREHOUSE_MODAL'

export function showWarehouseModal({warehouseID, openedFrom} = {}) {
  const warehouse = warehouseSelector(getState(), {warehouseID})

  setForm(WAREHOUSE_MODAL, {
    warehouseID,
    openedFrom,
    name: (warehouse && warehouse.address.name) || '',
    phone: (warehouse && warehouse.address.phone) || '',
    email: (warehouse && warehouse.address.email) || '',
    street1: (warehouse && warehouse.address.street1) || '',
    street2: (warehouse && warehouse.address.street2) || '',
    city: (warehouse && warehouse.address.city) || '',
    state: (warehouse && warehouse.address.state) || '',
    zip: (warehouse && warehouse.address.zip) || '',
    country: (warehouse && warehouse.address.country) || '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(WAREHOUSE_MODAL, ...args)
}

export function closeModal() {
  removeForm(WAREHOUSE_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[WAREHOUSE_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({name, phone, email, street1, city, state, zip, country}) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
      errors.preventSave = true
    } else if (name.split(' ').filter((v) => v).length < 2) {
      errors.name = 'Name needs to be two words'
      errors.preventSave = true
    }

    if (!isPresent(phone)) {
      errors.phone = 'Phone is required'
      errors.preventSave = true
    }

    if (!isPresent(email)) {
      errors.email = 'Email is required'
      errors.preventSave = true
    }

    if (!isPresent(street1)) {
      errors.street1 = 'Street 1 is required'
      errors.preventSave = true
    }

    if (!isPresent(city)) {
      errors.city = 'City is required'
      errors.preventSave = true
    }

    if (!isPresent(state)) {
      errors.state = 'State is required'
      errors.preventSave = true
    }

    if (!isPresent(zip)) {
      errors.zip = 'Zip is required'
      errors.preventSave = true
    }

    if (!isPresent(country)) {
      errors.country = 'Country is required'
      errors.preventSave = true
    }

    return errors
  },
)

export async function saveWarehouse() {
  const {
    warehouseID,
    name,
    phone,
    email,
    street1,
    street2,
    city,
    state,
    zip,
    country,
    openedFrom,
  } = modalFormSelector(getState())

  updateModalForm({isSaving: true, serverError: null})

  try {
    const params = {
      name,
      phone,
      email,
      street1,
      street2,
      city,
      state,
      zip,
      country,
    }

    const {json} = warehouseID
      ? await api.put(`/warehouse/${warehouseID}/`, params)
      : await api.post('/warehouse/', params)

    setWarehouse(json)

    await autoCreatePitneyShipper()

    showMessageToast(`Warehouse ${warehouseID ? 'updated' : 'created'}`)

    closeModal()

    if (!warehouseID && openedFrom !== EDIT_CART_LOCATION_WAREHOUSES_MODAL) {
      showWarehouseCartLocationNoticeModal()
    }

    if (openedFrom === 'setup') {
      await setInitialStateOfBusiness()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function WarehouseModal({form, errors}) {
  return (
    <ConfirmModal
      title={
        form.openedFrom === 'setup'
          ? 'Add a Ship-From Address'
          : `${form.warehouseID ? 'Update' : 'Create'} Warehouse`
      }
      modalSize="sm"
      onConfirm={() => saveWarehouse()}
      onCancel={() => closeModal()}
      confirmText={form.warehouseID ? 'Save' : 'Create'}
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--form margin-bottom-10">
        <li className="list__item--form">
          <TextInput
            id="name"
            label="Warehouse Name (must be two words)"
            required
            value={form.name}
            onChange={(value) =>
              updateModalForm({name: value, hasChanged_name: true})
            }
            errorMessage={form.hasChanged_name && errors.name}
          />
        </li>

        <li className="list__item--form">
          <TextInput
            id="street1"
            label="Street Address 1"
            required
            value={form.street1}
            onChange={(value) =>
              updateModalForm({street1: value, hasChanged_street1: true})
            }
            errorMessage={form.hasChanged_street1 && errors.street1}
          />
        </li>
        <li className="list__item--form">
          <TextInput
            id="street2"
            label="Street Address 2"
            value={form.street2}
            onChange={(value) => updateModalForm({street2: value})}
          />
        </li>
        <li className="list__item--form flex--justify">
          <div className="wrap--input-half">
            <TextInput
              id="city"
              label="City"
              required
              value={form.city}
              onChange={(value) =>
                updateModalForm({city: value, hasChanged_city: true})
              }
              errorMessage={form.hasChanged_city && errors.city}
            />
          </div>
          <div className="wrap--input-eighth">
            <TextInput
              id="state"
              label="State"
              required
              value={form.state}
              onChange={(value) =>
                updateModalForm({state: value, hasChanged_state: true})
              }
              errorMessage={form.hasChanged_state && errors.state}
            />
          </div>
          <div className="wrap--input-third">
            <TextInput
              id="zip"
              label="Zip"
              required
              value={form.zip}
              onChange={(value) =>
                updateModalForm({zip: value, hasChanged_zip: true})
              }
              errorMessage={form.hasChanged_zip && errors.zip}
            />
          </div>
        </li>
        <li className="list__item--form flex">
          <div className="wrap--input-half">
            <TextInput
              id="country"
              label="Country"
              required
              value={form.country}
              onChange={(value) =>
                updateModalForm({country: value, hasChanged_country: true})
              }
              errorMessage={form.hasChanged_country && errors.country}
            />
          </div>
        </li>
        <li className="list__item--form flex divider--top">
          <div className="wrap--input-third">
            <TextInput
              id="phone"
              label="Phone"
              required
              value={form.phone}
              onChange={(value) =>
                updateModalForm({phone: value, hasChanged_phone: true})
              }
              errorMessage={form.hasChanged_phone && errors.phone}
            />
          </div>
        </li>
        <li className="list__item--form flex">
          <div className="wrap--input-two-thirds">
            <TextInput
              id="email"
              label="Email"
              required
              value={form.email}
              onChange={(value) =>
                updateModalForm({email: value, hasChanged_email: true})
              }
              errorMessage={form.hasChanged_email && errors.email}
            />
          </div>
        </li>
      </ul>
    </ConfirmModal>
  )
}

WarehouseModal.propTypes = {
  form: PropTypes.shape({
    warehouseID: PropTypes.number,
    openedFrom: PropTypes.string,
    name: PropTypes.string.isRequired,
    hasChanged_name: PropTypes.bool,
    phone: PropTypes.string.isRequired,
    hasChanged_phone: PropTypes.bool,
    email: PropTypes.string.isRequired,
    hasChanged_email: PropTypes.bool,
    street1: PropTypes.string.isRequired,
    hasChanged_street1: PropTypes.bool,
    street2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    hasChanged_city: PropTypes.bool,
    state: PropTypes.string.isRequired,
    hasChanged_state: PropTypes.bool,
    zip: PropTypes.string.isRequired,
    hasChanged_zip: PropTypes.bool,
    country: PropTypes.string.isRequired,
    hasChanged_country: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
}

function mapStateToProps(state) {
  return {
    errors: errorsSelector(state),
  }
}

export default formConnect(
  connect(mapStateToProps)(WarehouseModal),
  modalFormSelector,
)
