import {
  updateParcel,
  dimensionsSelector,
  isMultiboxSelector,
  dimensionsErrorMessageSelector,
  dimensionHasError,
  dimensionsWarningMessageSelector,
  showDimensionsSelector,
  labelPropertiesSelector,
  multiboxShipperTypesSelector,
} from '../../../../data/labelInfos/index.js'
import {showMultiboxModal} from '../../../OrderListPage/Modals/MultiboxModal/index.js'

import DimensionsInput from '../../../../common/components/Form/DimensionsInput.js'
import EnableLabelProperty from '../EnableLabelProperty.js'
import {getState, useSelector} from '../../../../store.js'
import useLabelProperty, {formatMetaClassName} from '../useLabelProperty.js'
import className from '../../../../common/className.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LENGTH_LABEL_PROPERTY = 'packages[0].length'
const WIDTH_LABEL_PROPERTY = 'packages[0].width'
const HEIGHT_LABEL_PROPERTY = 'packages[0].height'
const VALID_LABEL_PROPERTIES = [
  LENGTH_LABEL_PROPERTY,
  WIDTH_LABEL_PROPERTY,
  HEIGHT_LABEL_PROPERTY,
]

function labelPropertiesFunc() {
  return VALID_LABEL_PROPERTIES
}

export function isLabelIncludesDimensionsSelector(state, {labelInfoID}) {
  const properties = labelPropertiesSelector(state, {labelInfoID})

  return (
    properties.includes(LENGTH_LABEL_PROPERTY) &&
    properties.includes(WIDTH_LABEL_PROPERTY) &&
    properties.includes(HEIGHT_LABEL_PROPERTY)
  )
}

export default function SingleDimensions() {
  const {labelInfoID, onChange, isLabelEnableMode} = useLabelConfigContext()

  const labelProperty = LENGTH_LABEL_PROPERTY

  const {length, width, height} = useSelector((state) =>
    dimensionsSelector(state, {
      labelInfoID,
    }),
  )
  const showDimensions = useSelector((state) =>
    showDimensionsSelector(state, {labelInfoID}),
  )
  const warningMessage = useSelector((state) =>
    dimensionsWarningMessageSelector(state, {labelInfoID}),
  )
  const errorMessage = useSelector((state) =>
    dimensionsErrorMessageSelector(state, {labelInfoID}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validLabelProperties: VALID_LABEL_PROPERTIES,
  })

  if (!showDimensions && !isMultibox && !isLabelEnableMode) {
    return null
  }

  const disabled = isMultibox || (isLabelEnableMode && !isLabelPropertyEnabled)
  const hasLengthError = dimensionHasError(length)
  const hasWidthError = dimensionHasError(width)
  const hasHeightError = dimensionHasError(height)

  const inputs = (
    <DimensionsInput
      length={isMultibox ? '' : length}
      width={isMultibox ? '' : width}
      height={isMultibox ? '' : height}
      setLength={(length) => {
        updateParcel(labelInfoID, 0, {length})
        onChange()
      }}
      setWidth={(width) => {
        updateParcel(labelInfoID, 0, {width})
        onChange()
      }}
      setHeight={(height) => {
        updateParcel(labelInfoID, 0, {height})
        onChange()
      }}
      errorMessage={errorMessage}
      hasLengthError={hasLengthError}
      hasWidthError={hasWidthError}
      hasHeightError={hasHeightError}
      warningMessage={warningMessage}
      disabled={disabled}
    />
  )

  return (
    <li
      className={className`list__item--shipping-options btm-offset-7 ${{
        error: !!errorMessage && !disabled,
      }} ${formatMetaClassName(labelProperty)}`}
    >
      <div className="wrap--edit-preset-form-input">
        <div className="flex">
          <label className="label--bold" htmlFor="dimensions_length">
            Dimensions
          </label>
          {isMultibox && (
            <button
              className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
              type="button"
              onClick={() => {
                const [shipperType] = multiboxShipperTypesSelector(getState(), {
                  labelInfoID,
                })
                showMultiboxModal(labelInfoID, shipperType)
              }}
            >
              <em>(Multiple)</em>
            </button>
          )}
        </div>
        {inputs}
      </div>
      <EnableLabelProperty
        checkedSelector={(state) =>
          isLabelIncludesDimensionsSelector(state, {labelInfoID})
        }
        labelProperty={labelProperty}
      />
    </li>
  )
}
