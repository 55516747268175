import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import set from 'date-fns/set'
import format from 'date-fns/format'

import {getRealDate} from '../../../../common/date.js'
import {
  defaultWarehouseSelector,
  needsWarehouseSelector,
  warehouseSelector,
} from '../../../../data/warehouses.js'
import {
  formsSelector,
  getState,
  removeForm,
  setForm,
  updateForm,
} from '../../../../store.js'
import {
  activeShipperIDsSelector,
  isShipperNewFedExAuthSelector,
  shippersSelector,
} from '../../../../data/shippers.js'
import {
  companyFeaturesSelector,
  useSchedulePickupSelector,
} from '../../../../data/company.js'
import {
  AMAZON_SFP,
  FEDEX,
  SHIPPER_NAMES,
  UPS,
} from '../../../../common/constants/ShipperNames.js'
import parseTime from '../../../../common/parseTime.js'
import {
  isPositiveInteger,
  isPositiveNumeric,
  isPresent,
} from '../../../../common/utils.js'
import ShipperOptions from '../../../../data/shipperOptions.js'
import apiverson from '../../../../common/apiverson.js'
import {showMessageToast} from '../../../Header/Toast/index.js'

export const MODAL_FORM = 'PICKUP_MODAL'

export function updateModalForm(form, meta) {
  updateForm(MODAL_FORM, form, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function setPickupAddressSameAsAccount(pickupAddressSameAsAccount) {
  updateModalForm(
    {
      pickupAddressSameAsAccount,
    },
    {stickyProps: ['pickupAddressSameAsAccount']},
  )
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const form = modalFormSelector(state)
  const errors = {}
  const {vendor} = selectedShipperSelector(state)

  if (!form.pickupDate) {
    errors.pickupDate = 'Pickup date is required'
  }

  if (vendor === UPS) {
    form.pieces.forEach((piece, index) => {
      if (!isPresent(piece.destinationCountry)) {
        errors[`pieces__${index}__destinationCountry`] = 'Country is required'
      }

      if (!isPositiveInteger(piece.quantity)) {
        errors[`pieces__${index}__quantity`] =
          'Quantity must be a positive number'
      }

      if (!isPresent(piece.quantity)) {
        errors[`pieces__${index}__quantity`] = 'Quantity is required'
      }
    })
  }

  if (vendor === FEDEX) {
    if (!isPositiveInteger(form.quantity)) {
      errors.quantity = 'Quantity must be a positive number'
    }

    if (!isPresent(form.quantity)) {
      errors.quantity = 'Quantity is required'
    }

    if (!isPositiveNumeric(form.totalWeight)) {
      errors.totalWeight = 'Weight must be a positive number'
    }

    if (!isPresent(form.totalWeight)) {
      errors.totalWeight = 'Weight is required'
    }
  }

  if (Object.keys(errors).length) {
    errors.preventSave = true
  }

  return errors
}

export const selectedShipperSelector = createSelector(
  shippersSelector,
  modalFormSelector,
  (shippers, form) => shippers[form.shipperID] || {},
)

export const pickupShippersSelector = createSelector(
  shippersSelector,
  activeShipperIDsSelector,
  companyFeaturesSelector,
  (shippers, activeShipperIDs, features) =>
    activeShipperIDs.reduce((prev, shipperID) => {
      const shipper = shippers[shipperID]

      // What can brown do for you? Make my job complicated. Fuck you UPS.
      // Too bad you don't get to read our source code and all comments are
      // minimized out. EAT SHIT, I'm full.
      if (shipper.vendor === UPS && features.use_ups_pickup) {
        prev.push(shipper)
      }

      if ([FEDEX, AMAZON_SFP].includes(shipper.vendor)) {
        prev.push(shipper)
      }

      return prev
    }, []),
)

export const canSchedulePickupSelector = createSelector(
  pickupShippersSelector,
  needsWarehouseSelector,
  useSchedulePickupSelector,
  (shippers, needsWarehouse, useSchedulePickup) =>
    useSchedulePickup && shippers.length > 0 && !needsWarehouse,
)

export function pickupFedExCarrierCodesSelector() {
  return ShipperOptions.fedex.pickup_carrier_code
}

export function pickupFedExServiceCategoriesSelector() {
  return ShipperOptions.fedex.pickup_service_category
}

export function pickupFedExPickupDateTypesSelector() {
  return ShipperOptions.fedex.pickup_date_type
}

export function pickupUPSContainerTypesSelector() {
  return ShipperOptions.ups.pickup_container_type
}

export function pickupUPSServiceTypesSelector() {
  return ShipperOptions.ups.shipping_method
}

export function defaultPieceFormSelector(state) {
  const containerTypes = pickupUPSContainerTypesSelector(state)
  const serviceTypes = pickupUPSServiceTypesSelector(state)

  return {
    containerType: containerTypes[0].value,
    serviceType: serviceTypes[0].value,
    quantity: '1',
    destinationCountry: 'US',
  }
}

export function upsParamsSelector(state) {
  const {
    warehouseID,
    pickupAddressSameAsAccount,
    hasOverweightPackages,
    pieces,
  } = modalFormSelector(state)

  const {
    address: {country},
  } = warehouseSelector(state, {warehouseID})

  return {
    warehouse_id: Number(warehouseID),
    pickup_address_same_as_account: pickupAddressSameAsAccount,
    overweight_packages: hasOverweightPackages,
    shipper_country: country,
    pickup_pieces: pieces.map((piece) => ({
      container_type: piece[`${UPS}__containerType`],
      service_type: piece[`${UPS}__serviceType`],
      destination_country: piece.destinationCountry,
      quantity: Number(piece.quantity),
    })),
  }
}

export function fedExParamsSelector(state) {
  let {
    shipperID,
    warehouseID,
    pickupAddressSameAsAccount,
    [`${FEDEX}__selectedCarrierCode`]: selectedCarrierCode,
    [`${FEDEX}__selectedServiceCategory`]: selectedServiceCategory,
    [`${FEDEX}__selectedPickupDateType`]: selectedPickupDateType,
    quantity,
    remarks,
    totalWeight,
    isInternational,
    commodityDescription,
  } = modalFormSelector(state)
  const isShipperNewFedExAuth = isShipperNewFedExAuthSelector(state, {
    shipperID,
  })

  const params = {
    carrier_code: selectedCarrierCode,
    quantity: Number(quantity),
    remarks: remarks || 'None specified',
    total_weight: Number(totalWeight),
    domestic_or_international: isInternational ? 'INTERNATIONAL' : 'DOMESTIC',
    commodity_description: commodityDescription,
  }

  if (isShipperNewFedExAuth) {
    params.pickup_date_type = selectedPickupDateType
    pickupAddressSameAsAccount = false
  } else {
    params.service_category = selectedServiceCategory
  }

  params.pickup_address_same_as_account = pickupAddressSameAsAccount

  if (!pickupAddressSameAsAccount) {
    params.warehouse_id = Number(warehouseID)
  }

  return params
}

export function scheduleParamsSelector(state) {
  const {shipperID, pickupDate, pickupTime, closeTime} =
    modalFormSelector(state)

  const pickupTimeParsed = parseTime(pickupTime)
  const closeTimeParsed = parseTime(closeTime)

  const pickupDateTime = set(pickupDate, pickupTimeParsed).toISOString()
  const closeDateTime = set(pickupDate, closeTimeParsed).toISOString()

  return {
    shipper_id: Number(shipperID),
    pickup_datetime: pickupDateTime,
    close_time: closeDateTime,
    ...shipperParamsSelector(state),
  }
}

export function shipperParamsSelector(state) {
  const {vendor} = selectedShipperSelector(state)

  if (vendor === UPS) {
    return upsParamsSelector(state)
  }
  if (vendor === FEDEX) {
    return fedExParamsSelector(state)
  }

  return {}
}

export function getConfirmationNumber(vendor, pickupResponse = {}) {
  const pickupNumber = pickupResponse.pickup_number || ''

  if (vendor === UPS) {
    return pickupNumber
  }
  if (vendor === FEDEX) {
    const location = get(pickupResponse, 'carrier_options.location') || ''

    return `${location}${pickupNumber}`
  }

  return ''
}

export function setPickupShipperID(shipperID) {
  updateModalForm({shipperID}, {stickyProps: ['shipperID']})
}

export function showPickupModal() {
  const shippers = pickupShippersSelector(getState())
  const defaultWarehouse = defaultWarehouseSelector(getState())
  const fedExCarrierCodes = pickupFedExCarrierCodesSelector(getState())
  const fedExServiceCategories =
    pickupFedExServiceCategoriesSelector(getState())

  if (!shippers.length || !defaultWarehouse) {
    return
  }

  const date = getRealDate()

  setForm(MODAL_FORM, {
    shipperID: shippers[0].id,
    warehouseID: defaultWarehouse.id,
    pickupDate: date,
    pickupTime: '8:00 AM',
    closeTime: '5:00 PM',
    pickupAddressSameAsAccount: false,
    [`${FEDEX}__selectedCarrierCode`]: fedExCarrierCodes[0].value,
    [`${FEDEX}__selectedServiceCategory`]: fedExServiceCategories[0].value,
    quantity: '1',
    remarks: '',
    totalWeight: '1',
    isInternational: false,
    commodityDescription: '',
    hasOverweightPackages: false,
    pieces: [defaultPieceFormSelector(getState())],
    isSaving: false,
    serverError: null,
  })
}

export async function schedulePickup() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {vendor} = selectedShipperSelector(getState())

    if (vendor === AMAZON_SFP) {
      window.open('https://ship.amazon.com/pickup', '_blank')

      closeModal()

      return
    }

    const params = scheduleParamsSelector(getState())

    const {json} = await apiverson.post(`/pickup/${vendor}`, params)

    const carrier = SHIPPER_NAMES[vendor]
    const confirmationNumber = getConfirmationNumber(vendor, json)

    showMessageToast(
      `Please note this confirmation number: ${confirmationNumber}`,
      {
        header: `A ${carrier} pickup has been successfully scheduled for ${format(
          new Date(json.pickup_datetime),
          'P p',
        )}.`,
        mustConfirm: true,
      },
    )

    closeModal()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}
