import {Component} from 'react'

import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from '../ProductArchiveStrategy/ReconcileForm.js'
import EditForm from '../ProductArchiveStrategy/EditForm.js'
import actions from '../../../../redux/actions/ui/dataImport/strategies/productUnarchiveStrategy.js'

export default class ProductUnarchiveStrategy extends Component {
  render() {
    return (
      <BaseStrategy
        {...this.props}
        actions={actions}
        EditForm={EditForm}
        ReconcileForm={ReconcileForm}
      />
    )
  }
}

ProductUnarchiveStrategy.title = actions.title
