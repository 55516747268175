import {navigate, openModal} from '../../../ui/index.js'

export const BASE = 'ordoro/ui/settings/shippers'
export const SET_FORM = `${BASE}/SET_FORM`
export const UPDATE_FORM = `${BASE}/UPDATE_FORM`
export const SET_FORMS = `${BASE}/SET_FORMS`
export const SETUP_FORMS = `${BASE}/SETUP_FORMS`
export const SETUP_FORM = `${BASE}/SETUP_FORM`
export const SET_NEW_SHIPPER_VENDOR = `${BASE}/SET_NEW_SHIPPER_VENDOR`

export const EXPORT_USPS_TRANSACTIONS_MODAL = `${BASE}/EXPORT_USPS_TRANSACTIONS_MODAL`
export const EXPORT_USPS_TRANSACTIONS = `${BASE}/EXPORT_USPS_TRANSACTIONS`

export function setForm(link, form) {
  return {
    type: SET_FORM,
    payload: {
      link,
      form,
    },
  }
}

export function updateForm(link, form) {
  return {
    type: UPDATE_FORM,
    payload: {
      link,
      form,
    },
  }
}

export function setForms(forms) {
  return {
    type: SET_FORMS,
    payload: forms,
  }
}

export function setupForms() {
  return {
    type: SETUP_FORMS,
  }
}

export function setupForm(shipperID) {
  return {
    type: SETUP_FORM,
    payload: {shipperID},
  }
}

export function setNewShipperVendor(vendor) {
  return {
    type: SET_NEW_SHIPPER_VENDOR,
    payload: vendor,
  }
}

export function goToShipperSettings() {
  return navigate('#/settings/shipper')
}

export function cancel() {
  return goToShipperSettings()
}

export function showExportUSPSTransactionsModal() {
  return openModal(EXPORT_USPS_TRANSACTIONS_MODAL, {
    form: {
      key: EXPORT_USPS_TRANSACTIONS_MODAL,
      startDate: null,
      errorDate: null,
      isRequesting: false,
      serverError: null,
    },
  })
}

export function exportUSPSTransactions() {
  return {
    type: EXPORT_USPS_TRANSACTIONS,
  }
}
