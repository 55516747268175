export const TASK_ORDER = [
  'force_import_products_from_cart',
  'import_orders_from_cart',
  'import_fba_inventory',
  'export_products_to_cart',
]

export const VISIBLE_ACTIVITY_COUNT = 5

export const TASK_POLL_DELAY = 30 * 1000
export const NEW_TASK_CREATION_DELAY = 500
