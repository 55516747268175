import PropTypes from 'prop-types'

import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import {showRestoreOrderModal} from '../../../../../OrderListPage/Modals/RestoreOrderModal.js'
import ListItem from './Fields/ListItem.js'

export default function Cancelled({orderNumber}) {
  return (
    <div className="divider--bottom">
      <h4 className="list__title--order-data">Cancelled</h4>
      <ListItem>
        <ButtonLink onClick={() => showRestoreOrderModal([orderNumber])}>
          Restore order
        </ButtonLink>
      </ListItem>
    </div>
  )
}

Cancelled.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
