import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  setInstructions,
  editInstructions,
} from '../../../redux/actions/ui/modals/editInstructionsModal.js'
import {
  instructionsSelector,
  isSavingSelector,
  serverErrorSelector,
} from '../../../redux/selectors/ui/modals/editInstructionsModal.js'

import BaseConfirmModal from './BaseConfirmModal.js'

function EditInstructionsModal({
  instructions,
  isSaving,
  serverError,
  ...props
}) {
  return (
    <BaseConfirmModal
      title="Edit Purchase Order Instructions"
      modalName={MODAL_NAME}
      isSaving={isSaving}
      confirmText="Save"
      onConfirm={props.editInstructions}
      cancelText="Cancel"
      onCancel={() => props.closeModal()}
    >
      {serverError && (
        <dl className="list list--error error-message margin-bottom-15">
          <dd className="fs-n0 margin-bottom-0">{serverError}</dd>
        </dl>
      )}
      <div className="row">
        <div className="medium-12 columns">
          <textarea
            className="textarea-md"
            rows="10"
            value={instructions}
            onChange={(event) => props.setInstructions(event.target.value)}
          />
        </div>
      </div>
    </BaseConfirmModal>
  )
}

EditInstructionsModal.propTypes = {
  instructions: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
  setInstructions: PropTypes.func.isRequired,
  editInstructions: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

EditInstructionsModal.modalName = MODAL_NAME

function mapStateToProps(state) {
  return {
    instructions: instructionsSelector(state),
    isSaving: isSavingSelector(state),
    serverError: serverErrorSelector(state),
  }
}

const mapDispatchToProps = {
  setInstructions,
  editInstructions,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditInstructionsModal)
