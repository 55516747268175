import PropTypes from 'prop-types'

import DimensionInput from './DimensionInput.js'

export default function DimensionsInput({
  id,
  length,
  setLength,
  hasLengthError,
  width,
  setWidth,
  hasWidthError,
  height,
  setHeight,
  hasHeightError,
  errorMessage,
  warningMessage,
  autoFocus,
  disabled,
  onEnterKeyPress,
}) {
  id = id || 'dimensions_length'
  // Don't default to showing invalid error in BulkDimensions
  const hasAllDimensions = !!length && !!width && !!height

  return (
    <div>
      <div className="flex flex-wrap">
        <DimensionInput
          id={id}
          label="x"
          value={length}
          onChange={setLength}
          hasError={hasLengthError}
          autoFocus={autoFocus}
          disabled={disabled}
          onEnterKeyPress={onEnterKeyPress}
        />
        <DimensionInput
          label="x"
          value={width}
          onChange={setWidth}
          hasError={hasWidthError}
          disabled={disabled}
          onEnterKeyPress={onEnterKeyPress}
        />
        <DimensionInput
          label="in"
          value={height}
          onChange={setHeight}
          hasError={hasHeightError}
          disabled={disabled}
          onEnterKeyPress={onEnterKeyPress}
        />
      </div>
      {hasAllDimensions && errorMessage && !disabled && (
        <pre className="wrap--error-wb lh-sm">
          <small className="error margin-top-0">{errorMessage}</small>
        </pre>
      )}
      {hasAllDimensions && !errorMessage && warningMessage && !disabled && (
        <pre className="wrap--error-wb lh-sm">
          <small className="warning margin-top-0">{warningMessage}</small>
        </pre>
      )}
    </div>
  )
}

DimensionsInput.propTypes = {
  id: PropTypes.string,

  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  errorMessage: PropTypes.string,
  hasLengthError: PropTypes.bool.isRequired,
  hasWidthError: PropTypes.bool.isRequired,
  hasHeightError: PropTypes.bool.isRequired,
  warningMessage: PropTypes.string,

  setLength: PropTypes.func.isRequired,
  setWidth: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  onEnterKeyPress: PropTypes.func,
}
