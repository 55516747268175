import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'

export function printConfigsSelector(state) {
  return state.data.printConfigs
}

export function printConfigSelector(state, {printConfigID}) {
  return get(printConfigsSelector(state), printConfigID)
}

export const printConfigsByNameSelector = createSelector(
  printConfigsSelector,
  (printConfigs) => keyBy(printConfigs, 'name'),
)

export const printConfigsSortedByNameSelector = createSelector(
  printConfigsByNameSelector,
  (printConfigsByName) =>
    Object.keys(printConfigsByName)
      .sort()
      .map((name) => printConfigsByName[name])
      .filter((printConfig) => !printConfig.archive_date),
)
