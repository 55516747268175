import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select, fork} from 'redux-saga/effects'

import {SupplierShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {getProduct, removeSupplier} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {updateProductDetailForm} from '../../ProductDetailPage/productDetailActions.js'
import {getSupplierName, supplierSelector} from '../../../data/suppliers.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const REMOVE_PRODUCT_SUPPLIER = 'REMOVE_PRODUCT_SUPPLIER'
export const REMOVE_PRODUCT_SUPPLIER_MODAL = 'REMOVE_PRODUCT_SUPPLIER_MODAL'

export function showRemoveProductSupplierModal(sku, supplierIDs) {
  return setForm(REMOVE_PRODUCT_SUPPLIER_MODAL, {
    sku,
    supplierIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(REMOVE_PRODUCT_SUPPLIER_MODAL, ...args)
}

export function closeModal() {
  return removeForm(REMOVE_PRODUCT_SUPPLIER_MODAL)
}

export function removeProductSupplier() {
  return {
    type: REMOVE_PRODUCT_SUPPLIER,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[REMOVE_PRODUCT_SUPPLIER_MODAL],
)

export function* removeProductSupplierWorker() {
  try {
    const {sku, supplierIDs} = yield select(modalFormSelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    yield all(
      supplierIDs.map((supplierID) => call(removeSupplier, supplierID, sku)),
    )

    yield fork(getProduct, sku)

    yield put(closeModal())

    yield call(updateProductDetailForm, {selectedSupplierIDs: []})

    yield call(
      showMessageToast,
      `Removed supplier${supplierIDs.length === 1 ? '' : 's'} from product`,
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function RemoveProductSupplierModal({form, supplier, ...props}) {
  return (
    <ConfirmModal
      title={`Remove Supplier${
        form.supplierIDs.length === 1 ? '' : 's'
      } From Product`}
      modalSize="sm"
      onConfirm={() => props.removeProductSupplier()}
      onCancel={() => props.closeModal()}
      confirmText="Yes"
      cancelText="No"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 margin-bottom-0">
        Are you sure you want to remove{' '}
        {form.supplierIDs.length === 1
          ? getSupplierName(supplier)
          : `${form.supplierIDs.length} suppliers`}{' '}
        from this product?
      </p>
    </ConfirmModal>
  )
}

RemoveProductSupplierModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    supplierIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  supplier: SupplierShape.isRequired,
  removeProductSupplier: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    supplier: supplierSelector(state, {supplierID: form.supplierIDs[0]}),
  }
}

const mapDispatchToProps = {
  removeProductSupplier,
  closeModal,
  updateForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(RemoveProductSupplierModal),
  modalFormSelector,
)
