import {useSelector} from '../../../store.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  isLabelPropertyEnabledSelector,
} from '../../../data/labelInfos/index.js'
import {showEditAddressModal} from '../../Modals/EditAddressModal/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {
  FEDEX,
  PITNEY,
  PITNEY_MERCHANT,
  UPS,
} from '../../../common/constants/ShipperNames.js'
import {EMPTY_DEPENDANT_PROPERTIES_FUNC} from './useLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'use_alternate_return_to_address'
function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [LABEL_PROPERTY, 'alternate_return_to_address']
    : []
}
const VALID_SHIPPER_TYPES = [FEDEX, PITNEY, PITNEY_MERCHANT, UPS]

export default function AlternateReturnToAddress() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const {use_alternate_return_to_address, alternate_return_to_address} =
    useSelector((state) => labelConfigSelector(state, {labelInfoID}))
  const isLabelPropertyEnabled = useSelector((state) =>
    isLabelPropertyEnabledSelector(state, {
      labelInfoID,
      labelProperty: LABEL_PROPERTY,
    }),
  )
  const disabled = isLabelEnableMode && !isLabelPropertyEnabled

  return (
    <>
      <ConfigCheckbox
        label="Use Alternate Return Address"
        checked={use_alternate_return_to_address}
        onChange={(checked) => {
          updateLabelConfig(labelInfoID, {
            use_alternate_return_to_address: checked,
          })

          onChange({justSaveLabelConfig: true})
        }}
        labelProperty={LABEL_PROPERTY}
        labelPropertiesFunc={labelPropertiesFunc}
        validShipperTypes={VALID_SHIPPER_TYPES}
        dependantPropertiesFunc={EMPTY_DEPENDANT_PROPERTIES_FUNC}
      />

      {VALID_SHIPPER_TYPES.includes(shipperType) &&
        use_alternate_return_to_address && (
          <div
            className={className`wrap--edit-preset-no-divider-bottom margin-top-7 divider--bottom ${
              disabled && 'wrap--edit-preset-disabled'
            }`}
          >
            <label
              className="label--bold lh-sm margin-bottom-3"
              htmlFor="return_to"
            >
              Return To
            </label>
            <div className="fs-n0 lh-sm margin-bottom-3">
              {alternate_return_to_address ? (
                alternate_return_to_address.company ||
                alternate_return_to_address.name
              ) : (
                <em>No address specified</em>
              )}
            </div>
            <button
              id="return_to"
              className="btn btn--link margin-bottom-0 fs-n1 block"
              type="button"
              onClick={() =>
                showEditAddressModal({
                  hasAddressShortcuts: true,
                  address: alternate_return_to_address,
                  toastMessage:
                    'Alternate Return To Address was successfully updated.',
                  onSave: async (address) => {
                    updateLabelConfig(labelInfoID, {
                      alternate_return_to_address: address,
                    })

                    onChange()
                  },
                })
              }
            >
              Edit
            </button>
          </div>
        )}
    </>
  )
}
