import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'

import {
  getState,
  updateForm,
  formsSelector,
  useSelector,
  useAutoForm,
} from '../../../store.js'
import {filterOptions} from '../../../common/tokenizeOptions.js'
import {PresetShape, LabelInfoIDShape} from '../../../common/PropTypes.js'
import DropdownItem from '../../../common/components/Dropdown/DropdownItem.js'
import {useZropdown} from '../../../common/components/Zropdown.js'
import FilterDropdown from '../../../common/components/FilterDropdown.js'
import {
  applyPreset,
  presetOptionsSelector,
  presetOptionTokensSelector,
  presetSelector,
} from '../../../data/presets.js'
import {showDeletePresetModal} from '../Modals/DeletePresetModal.js'
import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'
import {showEditPresetModal} from '../Modals/EditPresetModal/index.js'
import {HotKeyConnect} from '../../../common/components/HotKeys.js'
import {HK_APPLY_PRESET_REGEX} from '../../../common/constants/HotKeys.js'
import {showMessageToast} from '../../Header/Toast/index.js'

export function PresetsDropdownItem({preset, applyPreset, isSelected}) {
  return (
    <DropdownItem>
      <div
        className={`wrap--preset-item flex--justify-nowrap label--selectable label--selectable-pt-pb-0 margin-bottom-0 ${
          isSelected ? 'list__link--dropdown-arrow-focus' : ''
        }`}
      >
        <button
          className="btn--link list__link--dropdown list__link--preset"
          type="button"
          onClick={() => applyPreset(preset.id)}
        >
          <span className="btn__text">{preset.name}</span>
        </button>
        <span className="wrap--preset-buttons">
          <button
            className="btn btn--link no-underline margin-right-3"
            type="button"
            title="Edit Preset"
            onClick={() => showEditPresetModal({presetID: preset.id})}
          >
            <span className="i-pencil fs-00" aria-hidden="true" />
          </button>
          <button
            className="btn btn--link no-underline"
            type="button"
            title="Delete Preset"
            onClick={() => showDeletePresetModal(preset.id)}
          >
            <span className="i-trash fs-00" aria-hidden="true" />
          </button>
        </span>
      </div>
    </DropdownItem>
  )
}

PresetsDropdownItem.propTypes = {
  preset: PresetShape.isRequired,
  applyPreset: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
}

export function filterPresets(
  dropdown,
  presetOptions,
  presetOptionTokens,
  excludePresetIDs = [],
) {
  try {
    const form = formsSelector(getState())[dropdown]

    if (!form) {
      return
    }

    if (!form.text) {
      updateForm(dropdown, {
        results: [
          ...presetOptions.filter(
            ({entity}) => !excludePresetIDs.includes(entity.id),
          ),
        ],
        hasFoundNothing: false,
      })

      return
    }

    const results = filterOptions(form.text, presetOptionTokens).filter(
      ({entity}) => !excludePresetIDs.includes(entity.id),
    )

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

function hotKeyApplyPreset(presetID, labelInfoID) {
  const preset = presetSelector(getState(), {presetID})

  if (!preset) {
    return
  }

  applyPreset(labelInfoID, presetID)

  showMessageToast(`Applied preset (${preset.name}) to order`)
}

export default function PresetsDropdown({
  labelInfoID,
  dropdown,
  hasErrors,
  excludePresetIDs,
}) {
  dropdown = dropdown || 'PRESETS_DROPDOWN'

  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
  })

  const presetOptions = useSelector(presetOptionsSelector)
  const presetOptionTokens = useSelector(presetOptionTokensSelector)

  useEffect(() => {
    filterPresets(dropdown, presetOptions, presetOptionTokens, excludePresetIDs)
  }, [presetOptions])

  const buttonRef = useRef()

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onChange: () =>
        filterPresets(
          dropdown,
          presetOptions,
          presetOptionTokens,
          excludePresetIDs,
        ),
      onSelect: (option) => applyPreset(labelInfoID, option.entity.id),
    })

  const currentDropdown = useSelector(currentDropdownSelector)

  return (
    <div
      className={`wrap-outer--tag-dropdown-with-filter wrap--dropdown-presets ${
        currentDropdown === dropdown ? 'open' : ''
      }`}
      data-dropdown={dropdown}
    >
      <HotKeyConnect
        code={HK_APPLY_PRESET_REGEX}
        func={(presetID) => hotKeyApplyPreset(presetID, labelInfoID)}
      />
      <button
        ref={buttonRef}
        className="btn btn--dropdown btn--filter"
        type="button"
      >
        <span className={`btn__text ${hasErrors ? 'error-message' : ''}`}>
          Presets
        </span>
      </button>
      <FilterDropdown
        dropdown={dropdown}
        form={form}
        onChangeInternal={onChangeInternal}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
        placeholder="Search Presets"
        includeSearchInput
        parentRef={buttonRef}
        footer={
          <div className="list__item list__item--dropdown list__item--dropdown-last">
            <button
              className="btn--link list__link list__link--dropdown list__link--preset-save"
              type="button"
              onClick={() => showEditPresetModal({labelInfoID})}
            >
              Create a Shipping Preset
            </button>
          </div>
        }
      >
        <>
          {form.results.map((option, index) => (
            <PresetsDropdownItem
              key={option.entity.id}
              preset={option.entity}
              applyPreset={() => onSelectInternal(option)}
              isSelected={index === form.selectedIndex}
            />
          ))}
          {form.hasFoundNothing && (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              No presets match your search.
            </li>
          )}
          {presetOptions.length === 0 && (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              You haven’t created any presets, yet!
            </li>
          )}
        </>
      </FilterDropdown>
    </div>
  )
}

PresetsDropdown.propTypes = {
  labelInfoID: LabelInfoIDShape.isRequired,
  dropdown: PropTypes.string,
  hasErrors: PropTypes.bool,
  excludePresetIDs: PropTypes.arrayOf(PropTypes.number),
}
