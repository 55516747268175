import {useEffect} from 'react'
import analytics from '../../../common/analytics/index.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import {usesAppsSelector} from '../../../data/company.js'
import {useSelector} from '../../../store.js'

export default function RulesFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)
  useEffect(() => {
    analytics.trackEvent('rules_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
      <div
        className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-02 lh-md  margin-bottom-15">
        <strong>Automation Rules are not enabled in your account.</strong>
      </p>
      {usesApps ? (
        <p className="fs-01 margin-bottom-20">
          To access this feature, you need to upgrade the Shipping App to the
          Advanced or Premium plan.
        </p>
      ) : (
        <p className="fs-01 margin-bottom-20">
          To access this feature, you need to upgrade your plan.
        </p>
      )}
      <div className="margin-bottom-10">
        <button
          className="btn btn--primary btn--primary-alt btn--md fs-01"
          onClick={() => showUpgradeModal()}
        >
          <strong>Upgrade Your Plan</strong>
        </button>
      </div>
    </div>
  )
}
