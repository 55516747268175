import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  FULFILLMENT_CHANNEL_FBA,
  FULFILLMENT_CHANNEL_FBM,
} from '../../common/constants/CartVendorOptions.js'
import {TagsShape} from '../../common/PropTypes.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ProductTags from '../ProductListPage/ProductTags.js'
import {
  showImgsProductDetailSelector,
  usesInventorySelector,
} from '../../data/company.js'
import {
  nameSelector,
  imageURLSelector,
  tagsSelector,
  isArchivedSelector,
  isKitSelector,
  isBOMSelector,
  fulfillmentChannelCountsSelector,
} from '../../data/products.js'
import {skuSelector} from './productDetailSelectors.js'
import {showProductTextFieldModal} from '../ProductListPage/Modals/ProductTextFieldModal.js'

export function Header({
  sku,
  name,
  imageURL,
  tags,
  isArchived,
  isKit,
  isBOM,
  usesInventory,
  fulfillmentChannelCounts,
  showImgsProductDetail,
}) {
  return (
    <div className="flex margin-bottom-25">
      {showImgsProductDetail && imageURL && (
        <div className="wrap--product-img">
          <img src={imageURL} alt="product" />
        </div>
      )}
      <div className="wrap--product-title">
        <div className="flex">
          <h2 className="page__title page__title--inner wrap--product-name margin-right-5">
            <strong>{name}</strong>
          </h2>
          <ButtonLink
            className="no-underline"
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: name,
                display: 'Name',
                apiProp: 'name',
                isRequired: true,
              })
            }
          >
            <span
              className="i-pencil i--v-align fs-01 lh-sm"
              aria-hidden="true"
            />
          </ButtonLink>
        </div>
        <h3 className="fs-01 margin-top-5 margin-bottom-0">
          <strong>SKU:</strong> {sku}
        </h3>
        <ul className="list list--no-style flex margin-top-3">
          {isArchived && (
            <li className="list__item margin-bottom-0">
              <div className="label__callout label__callout--lg label__callout--grey inline-block margin-right-5">
                <strong>ARCHIVED</strong>
              </div>
            </li>
          )}
          {usesInventory && isKit && (
            <li className="list__item margin-bottom-0">
              <div className="label__callout label__callout--lg label__callout--grey inline-block margin-right-5">
                <strong>KIT</strong>
              </div>
            </li>
          )}
          {usesInventory && isBOM && (
            <li className="list__item margin-bottom-0">
              <div className="label__callout label__callout--lg label__callout--grey inline-block margin-right-5">
                <strong>BOM</strong>
              </div>
            </li>
          )}
          {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA] > 0 && (
            <li className="list__item margin-bottom-0">
              <div className="label__callout label__callout--lg label__callout--amazon-fba no-underline margin-right-5">
                <strong>
                  FBA
                  {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA] > 1
                    ? ` (${fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA]})`
                    : ''}
                </strong>
              </div>
            </li>
          )}
          {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM] > 0 && (
            <li className="list__item margin-bottom-0">
              <div className="label__callout label__callout--lg label__callout--amazon-fbm no-underline margin-right-5">
                <strong>
                  FBM
                  {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM] > 1
                    ? ` (${fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM]})`
                    : ''}
                </strong>
              </div>
            </li>
          )}
        </ul>
        <ProductTags className="margin-top-3" sku={sku} tags={tags} />
      </div>
    </div>
  )
}

Header.propTypes = {
  sku: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  tags: TagsShape.isRequired,
  isKit: PropTypes.bool.isRequired,
  isBOM: PropTypes.bool.isRequired,
  isArchived: PropTypes.bool.isRequired,
  usesInventory: PropTypes.bool.isRequired,
  fulfillmentChannelCounts: PropTypes.shape({
    [FULFILLMENT_CHANNEL_FBA]: PropTypes.number.isRequired,
    [FULFILLMENT_CHANNEL_FBM]: PropTypes.number.isRequired,
  }).isRequired,
  showImgsProductDetail: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const sku = skuSelector(state)

  return {
    sku,
    name: nameSelector(state, {sku}),
    imageURL: imageURLSelector(state, {sku}),
    tags: tagsSelector(state, {sku}),
    isArchived: isArchivedSelector(state, {sku}),
    isKit: isKitSelector(state, {sku}),
    isBOM: isBOMSelector(state, {sku}),
    usesInventory: usesInventorySelector(state),
    fulfillmentChannelCounts: fulfillmentChannelCountsSelector(state, {sku}),
    showImgsProductDetail: showImgsProductDetailSelector(state),
  }
}

export default connect(mapStateToProps)(Header)
