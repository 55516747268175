import PropTypes from 'prop-types'
import {numberToEnglish} from '../numbers.js'

export default function Quantity({
  value,
  options: {toEnglish, ...options} = {},
}) {
  let number = Number(value || 0)

  return (
    <span>
      {toEnglish
        ? numberToEnglish(number)
        : number.toLocaleString('en-us', options)}
    </span>
  )
}

Quantity.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.object,
}
