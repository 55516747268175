import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {isPresent} from '../../../common/utils.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {showProductTextFieldModal} from '../Modals/ProductTextFieldModal.js'
import {asinSelector, productUPCSelector} from '../../../data/products.js'
import {productDetailURLSelector} from '../../ProductDetailPage/productDetailSelectors.js'
import {getState} from '../../../store.js'
import {productListQuerySelector} from '../productListFunctions.js'

export function SKUCell({upc, productDetailURL, sku, asin}) {
  return (
    <td className="table__td table__td--product-list-sku">
      <LinkButton className="darkest" href={productDetailURL}>
        {sku}
      </LinkButton>
      <div className="wrap--product-addl-info-item">
        <strong>UPC: </strong>
        <ButtonLink
          className={classNames('v-align-base', {darkest: isPresent(upc)})}
          onClick={() => {
            const {searchText} = productListQuerySelector(getState())

            showProductTextFieldModal({
              sku,
              value: upc,
              display: 'UPC',
              apiProp: 'upc',
              needsProductListRefresh: !!searchText,
            })
          }}
        >
          <span>{isPresent(upc) ? upc : <em>Not set</em>}</span>
        </ButtonLink>
      </div>
      <div className="wrap--product-addl-info-item">
        <strong>ASIN: </strong>
        <ButtonLink
          className={classNames('v-align-base', {darkest: isPresent(asin)})}
          onClick={() => {
            const {searchText} = productListQuerySelector(getState())

            showProductTextFieldModal({
              sku,
              value: asin,
              display: 'ASIN',
              apiProp: 'asin',
              needsProductListRefresh: !!searchText,
            })
          }}
        >
          <span>{isPresent(asin) ? asin : <em>Not set</em>}</span>
        </ButtonLink>
      </div>
    </td>
  )
}

SKUCell.propTypes = {
  sku: PropTypes.string.isRequired,
  productDetailURL: PropTypes.string.isRequired,
  upc: PropTypes.string,
  asin: PropTypes.string,
}

function mapStateToProps(state, {sku}) {
  return {
    productDetailURL: productDetailURLSelector(state, {sku}),
    upc: productUPCSelector(state, {sku}),
    asin: asinSelector(state, {sku}),
  }
}

export default connect(mapStateToProps)(SKUCell)
