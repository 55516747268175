import PropTypes from 'prop-types'
import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {HK_CREATE_LABEL} from '../../../common/constants/HotKeys.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {
  labelLoadingSelector,
  labelShipperIDSelector,
  selectedShippingRateCostSelector,
  hasLabelErrorsSelector,
  hasLabelWarningsSelector,
  createLabelImageDisabledSelector,
  insufficientBalanceSelector,
  labelShipperTypeSelector,
} from '../../../data/labelInfos/index.js'
import {createLabelImages} from '../../../data/labelInfos/labelRequest.js'

import Total from '../Total.js'
import Rates from './Rates/index.js'
import Balance from './Balance.js'
import InsuranceNotice from './InsuranceNotice.js'
import {showPostageBalanceModal} from '../Modals/PostageBalanceModal.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {shipperCurrencySymbolSelector} from '../../../data/shippers.js'

export default function Summary({
  showCreateButton = true,
  showErrors,
  showInsuranceFull,
}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const hasErrors = useSelector((state) =>
    hasLabelErrorsSelector(state, {labelInfoID}),
  )
  const hasWarnings = useSelector((state) =>
    hasLabelWarningsSelector(state, {labelInfoID}),
  )
  const labelLoading = useSelector((state) =>
    labelLoadingSelector(state, {labelInfoID}),
  )
  const disabled = useSelector((state) =>
    createLabelImageDisabledSelector(state, {labelInfoID}),
  )
  const cost = useSelector((state) =>
    selectedShippingRateCostSelector(state, {labelInfoID}),
  )
  const shipperCurrencySymbol = useSelector((state) =>
    shipperCurrencySymbolSelector(state, {shipperType}),
  )
  const insufficientBalance = useSelector((state) =>
    insufficientBalanceSelector(state, {labelInfoID}),
  )
  const shipperID = useSelector((state) =>
    labelShipperIDSelector(state, {labelInfoID}),
  )

  return (
    <fieldset
      className={classNames(
        'fieldset--shipping-options fieldset--shipping-summary',
        {
          error: hasErrors,
          warning: hasWarnings && !hasErrors,
        },
      )}
    >
      <Rates showErrors={showErrors} />
      <InsuranceNotice showInsuranceFull={showInsuranceFull} />
      <div className="flex">
        <div className="flex-grow">
          <div className="flex--justify">
            <div className="flex--justify-col margin-bottom-15">
              <Total
                cost={cost}
                disabled={disabled}
                currencySymbol={shipperCurrencySymbol}
              />
              <Balance />
              {insufficientBalance && (
                <pre className="wrap--error-wb lh-sm">
                  <small className="error margin-top-0">
                    Additional funds required.
                  </small>
                </pre>
              )}
            </div>
            <div className="flex--justify-col margin-bottom-15">
              {showCreateButton && (
                <ButtonPrimary
                  className="meta-labelconfigform-button-createlabel"
                  onClick={(event) => {
                    event && event.preventDefault()

                    if (insufficientBalance) {
                      showPostageBalanceModal(shipperID)
                    } else {
                      createLabelImages([labelInfoID])
                    }
                  }}
                  isDisabled={disabled}
                  isLoading={labelLoading}
                  hotKey={HK_CREATE_LABEL}
                >
                  Create Label
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}

Summary.propTypes = {
  showCreateButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  showInsuranceFull: PropTypes.bool,
}
