import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  if (isEmpty(props.columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-10" {...props}>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.sku}
          errorMessage={props.columnErrors.sku}
          name="sku"
          label="SKU"
          className="medium-6"
          onChange={props.updateColumnMapping}
        />
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.supplier}
          errorMessage={props.columnErrors.supplier}
          name="supplier"
          label="Supplier"
          className="medium-6"
          onChange={props.updateColumnMapping}
        />
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
