import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {formsSelector, useAutoForm} from '../store.js'

// This function lets you decouple the watching of the form for existence and use of it
// We can watch the form here and then only let the Component display if it exists
// The Component can then use selectors that use the data in the form without worrying about it's existence
export default function formConnect(Component, formSelector) {
  return formConnectCreator(Component, (state) => ({form: formSelector(state)}))
}

export function formConnectCreator(Component, mapStateToProps) {
  function FormBarrier(props) {
    if (!props.form) {
      return null
    }

    return <Component {...props} />
  }

  FormBarrier.propTypes = {
    form: PropTypes.object,
  }

  return connect(mapStateToProps)(FormBarrier)
}

export function formConnectAuto(Component, init) {
  function FormBarrier({initialForm, ...props}) {
    useAutoForm(props.formName, initialForm)

    if (!props.form) {
      return null
    }

    return <Component {...props} />
  }

  FormBarrier.propTypes = {
    formName: PropTypes.string.isRequired,
    initialForm: PropTypes.object,
    form: PropTypes.object,
  }

  function mapStateToProps(_state, props) {
    const {formName, initialForm, ...additionalProps} = init(_state, props)

    const formSelector = createSelector(
      formsSelector,
      (forms) => forms[formName],
    )

    return (state) => ({
      formName,
      initialForm,
      form: formSelector(state),
      ...additionalProps,
    })
  }

  return connect(mapStateToProps)(FormBarrier)
}
