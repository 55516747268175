import {CommentShape} from '../../../PropTypes.js'
import User from './User.js'
import FormattedDate from '../../FormattedDate.js'
import Join from '../../Join.js'

export default function BatchSortComment({comment: {date, user, sorts}}) {
  return (
    <dl className="list--order-data list--timeline-point">
      <dt className="list__item--order-data list__title--timeline-point">
        <FormattedDate value={date} format="LT" />
      </dt>
      <dd className="list__item--order-data list__item--timeline-point">
        Task started to sort batch by{' '}
        <Join
          array={sorts}
          separator1=" then by "
          separator2=" then finally by "
        />
      </dd>
      <dd className="list__item--order-data list__item--timeline-point">
        {user && <User email={user} />}
      </dd>
    </dl>
  )
}

BatchSortComment.propTypes = {
  comment: CommentShape,
}
