import {getState, setForm, updateForm, formsSelector} from '../store.js'
import api from '../common/api.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const DIRECT_KIT_PARENTS = 'DIRECT_KIT_PARENTS'

export function setDirectKitParents(sku, directKitParents) {
  if (formsSelector(getState())[DIRECT_KIT_PARENTS]) {
    updateForm(DIRECT_KIT_PARENTS, {[sku]: directKitParents})
  } else {
    setForm(DIRECT_KIT_PARENTS, {[sku]: directKitParents})
  }
}

export function directKitParentsAllSelector(state) {
  return (
    formsSelector(state)[DIRECT_KIT_PARENTS] ||
    directKitParentsAllSelector.default
  )
}
directKitParentsAllSelector.default = {}

export function directKitParentsSelector(state, {sku}) {
  return (
    directKitParentsAllSelector(state)[sku] || directKitParentsSelector.default
  )
}
directKitParentsSelector.default = []

export function isKitComponentSelector(state, {sku}) {
  return directKitParentsSelector(state, {sku}).length > 0
}

export function directKitParentsHaveLoadedSelector(state, {sku}) {
  return !!directKitParentsAllSelector(state)[sku]
}

export async function getDirectKitParents(sku) {
  try {
    const {json: kitGraph} = await api.get(
      `/product/${encodeURIComponent(sku)}/kit_graph/`,
      {
        components_type: 'direct_ancestors_only',
      },
    )

    const directKitParents = kitGraph.edges
      .map(({source: parent_sku, weight}) => ({
        ...kitGraph.nodes.find(({id}) => id === parent_sku),
        quantityInParent: weight,
      }))
      .filter((node) => node.product_type !== 'bom')

    setDirectKitParents(sku, directKitParents)
  } catch (err) {
    showGlobalError(
      {
        summary: `Error getting kit graph for ${sku}.`,
        details: err.error_message || err.message,
      },
      err,
    )
  }
}
