import {takeEvery, put, select, call} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import {checkRunningTasks} from '../../../actions/data/isRunningTasks.js'
import {closeModal} from '../../../actions/ui/index.js'
import {
  REQUEST_EXPORT,
  setIsRequesting,
  setServerError,
} from '../../../actions/ui/modals/exportInsuranceHistoryModal.js'
import {exportTaskParamsSelector} from '../../../selectors/ui/modals/exportInsuranceHistoryModal.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'

export function* requestExportWorker() {
  const params = yield select(exportTaskParamsSelector)

  yield put(setIsRequesting(true))

  yield put(setServerError(null))

  try {
    yield call(api.post, '/new_task', params)

    yield put(checkRunningTasks())

    yield put(closeModal())

    yield call(
      showMessageToast,
      'A CSV file is being generated and can be downloaded from the activity menu.',
    )
  } catch (err) {
    yield put(
      setServerError(
        `Error creating export task: ${err.error_message || err.message}`,
      ),
    )
  }

  yield put(setIsRequesting(false))
}

export default function* exportInsuranceHistoryModal() {
  yield takeEvery(REQUEST_EXPORT, requestExportWorker)
}
