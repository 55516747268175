import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import Currency from '../../common/components/Currency.js'
import {
  itemsSelector,
  poItemIDsSelector,
  productTotalSelector,
  discountAmountSelector,
  shippingAmountSelector,
  taxAmountSelector,
  grandTotalSelector,
  isPOLockedSelector,
} from '../../data/pos.js'
import {showEditPOItemModal} from '../PurchaseOrderListPage/Modals/EditPOItemModal.js'
import {showRemovePOItemModal} from '../PurchaseOrderListPage/Modals/RemovePOItemModal.js'
import {showPOTextFieldModal} from '../PurchaseOrderListPage/Modals/POTextFieldModal.js'
import {POFormShape, updatePODetailForm} from './poDetailActions.js'
import {
  poIDSelector,
  poDetailFormSelector,
  areAllItemsSelectedSelector,
  areIndeterminateItemsSelectedSelector,
} from './poDetailSelectors.js'
import Item from './Item.js'

export function ItemsPanel({
  poID,
  items,
  poItemIDs,
  form,
  areAllItemsSelected,
  areIndeterminateItemsSelected,
  productTotal,
  discountAmount,
  shippingAmount,
  taxAmount,
  grandTotal,
  isPOLocked,
  ...props
}) {
  return (
    <table className="table fs-00">
      <thead>
        <tr className="fs-n0">
          <th className="table__th table__th--md th-width-1">
            <Checkbox
              className="margin-bottom-0"
              checked={areAllItemsSelected}
              indeterminate={areIndeterminateItemsSelected}
              onClick={(checked) =>
                updatePODetailForm({
                  selectedItemIDs: checked ? poItemIDs : [],
                })
              }
              disabled={isPOLocked}
            />
          </th>
          <th className="table__th table__th--md">Product Name/SKU</th>
          <th className="table__th table__th--md">Supplier SKU</th>
          <th className="table__th table__th--md align-right">
            {form.selectedItemIDs.length === 0 ? (
              'Ordered Qty'
            ) : (
              <ButtonLink
                className="darker lh-inherit align-right"
                title="Bulk Update Ordered Qty"
                onClick={() =>
                  props.showEditPOItemModal(
                    poID,
                    form.selectedItemIDs,
                    'quantity',
                  )
                }
              >
                Ordered Qty
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--md align-right">Received Qty</th>
          <th className="table__th table__th--md align-right">Phys On Hand</th>
          <th className="table__th table__th--md align-right">
            Total Committed
          </th>
          <th className="table__th table__th--md align-right">
            Total PO Reordered
          </th>
          <th className="table__th table__th--md align-right">
            {form.selectedItemIDs.length === 0 ? (
              'Supplier Unit Cost'
            ) : (
              <ButtonLink
                className="darker lh-inherit align-right"
                title="Bulk Update Supplier Unit Cost"
                onClick={() =>
                  props.showEditPOItemModal(
                    poID,
                    form.selectedItemIDs,
                    'unit_price',
                  )
                }
              >
                Supplier Unit Cost
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--md">Lead Time</th>
          <th className="table__th table__th--md align-right">
            {form.selectedItemIDs.length === 0 ? (
              'Discount'
            ) : (
              <ButtonLink
                className="darker lh-inherit align-right"
                title="Bulk Update Discount"
                onClick={() =>
                  props.showEditPOItemModal(
                    poID,
                    form.selectedItemIDs,
                    'discount_amount',
                  )
                }
              >
                Discount
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--md align-right">Total</th>
          <th className="table__th table__th--md align-right">
            {form.selectedItemIDs.length === 0 ? (
              ''
            ) : (
              <ButtonLink
                className="no-underline"
                title="Remove supplier"
                onClick={() =>
                  props.showRemovePOItemModal(poID, form.selectedItemIDs)
                }
              >
                <span className="i-trash fs-00" aria-hidden="true"></span>
              </ButtonLink>
            )}
          </th>
        </tr>
      </thead>
      <tbody className="table__tbody table__tbody--lines">
        {items.map((item) => (
          <Item
            key={item.id}
            poID={poID}
            poItemID={item.id}
            poItemIDs={poItemIDs}
            form={form}
            isPOLocked={isPOLocked}
          />
        ))}
        {!isPOLocked && (
          <tr>
            <td className="table__td table__td--product-search" colSpan="13">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() => props.showEditPOItemModal(poID)}
                autoFocus={form.afterAddingProduct}
              >
                Add a Product
              </ButtonPrimary>
            </td>
          </tr>
        )}
      </tbody>
      <tbody className="table__tbody table__tbody--totals">
        <tr>
          <td className="table__td fs-00 align-right" colSpan="11">
            <strong>Product Total</strong>
          </td>
          <td className="table__td fs-00" colSpan="2">
            <strong>
              <Currency value={productTotal} />
            </strong>
          </td>
        </tr>
        <tr>
          <td
            className="table__td fs-00 align-right v-align-middle"
            colSpan="11"
          >
            <strong>Discount</strong>
          </td>
          <td className="table__td" colSpan="2">
            <ButtonLink
              className="fs-00 v-align-base darker"
              onClick={() =>
                props.showPOTextFieldModal({
                  poID,
                  value: discountAmount,
                  display: 'Discount Amount',
                  apiProp: 'discount_amount',
                  inputType: 'currency',
                  isRequired: true,
                })
              }
              disabled={isPOLocked}
            >
              <Currency value={discountAmount} />
            </ButtonLink>
          </td>
        </tr>
        <tr>
          <td
            className="table__td fs-00 align-right v-align-middle"
            colSpan="11"
          >
            <strong>Shipping and Handling</strong>
          </td>
          <td className="table__td" colSpan="2">
            <ButtonLink
              className="fs-00 v-align-base darker"
              onClick={() =>
                props.showPOTextFieldModal({
                  poID,
                  value: shippingAmount,
                  display: 'Shipping Amount',
                  apiProp: 'shipping_amount',
                  inputType: 'currency',
                  isRequired: true,
                })
              }
              disabled={isPOLocked}
            >
              <Currency value={shippingAmount} />
            </ButtonLink>
          </td>
        </tr>
        <tr>
          <td
            className="table__td fs-00 align-right v-align-middle"
            colSpan="11"
          >
            <strong>Tax</strong>
          </td>
          <td className="table__td" colSpan="2">
            <ButtonLink
              className="fs-00 v-align-base darker"
              onClick={() =>
                props.showPOTextFieldModal({
                  poID,
                  value: taxAmount,
                  display: 'Tax Amount',
                  apiProp: 'tax_amount',
                  inputType: 'currency',
                  isRequired: true,
                })
              }
              disabled={isPOLocked}
            >
              <Currency value={taxAmount} />
            </ButtonLink>
          </td>
        </tr>
        <tr>
          <td
            className="table__td table__td--grand-total fs-00 align-right"
            colSpan="11"
          >
            <strong>Grand Total</strong>
          </td>
          <td className="table__td table__td--grand-total fs-00" colSpan="2">
            <strong>
              <Currency value={grandTotal} />
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

ItemsPanel.propTypes = {
  poID: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  poItemIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  form: POFormShape.isRequired,
  areAllItemsSelected: PropTypes.bool.isRequired,
  areIndeterminateItemsSelected: PropTypes.bool.isRequired,
  productTotal: PropTypes.number.isRequired,
  discountAmount: PropTypes.number.isRequired,
  shippingAmount: PropTypes.number.isRequired,
  taxAmount: PropTypes.number.isRequired,
  grandTotal: PropTypes.number.isRequired,
  isPOLocked: PropTypes.bool.isRequired,
  showEditPOItemModal: PropTypes.func.isRequired,
  showRemovePOItemModal: PropTypes.func.isRequired,
  showPOTextFieldModal: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const poID = poIDSelector(state)

  return {
    poID,
    items: itemsSelector(state, {poID}),
    poItemIDs: poItemIDsSelector(state, {poID}),
    form: poDetailFormSelector(state),
    areAllItemsSelected: areAllItemsSelectedSelector(state),
    areIndeterminateItemsSelected: areIndeterminateItemsSelectedSelector(state),
    productTotal: productTotalSelector(state, {poID}),
    discountAmount: discountAmountSelector(state, {poID}),
    shippingAmount: shippingAmountSelector(state, {poID}),
    taxAmount: taxAmountSelector(state, {poID}),
    grandTotal: grandTotalSelector(state, {poID}),
    isPOLocked: isPOLockedSelector(state, {poID}),
  }
}

const mapDispatchToProps = {
  showEditPOItemModal,
  showRemovePOItemModal,
  showPOTextFieldModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsPanel)
