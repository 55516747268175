import {useRef, useEffect, useState} from 'react'

export default function useAnimateToFollow(getValue) {
  const animateRef = useRef()
  const internalValue = useRef()
  const [value, setValue] = useState(getValue())

  function getValueInternal() {
    const currentValue = getValue(internalValue.current)

    if (currentValue !== internalValue.current) {
      internalValue.current = currentValue
      setValue(currentValue)
    }

    animateRef.current = requestAnimationFrame(getValueInternal)
  }

  useEffect(() => {
    internalValue.current = value
    animateRef.current = requestAnimationFrame(getValueInternal)

    return () => cancelAnimationFrame(animateRef.current)
  }, [])

  return value
}
