import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {setForm, updateForm, formsSelector, removeFormValue} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {QUICK_BOOKS, STRIPE_PAYMENTS} from '../common/constants/Integrations.js'

export const INTEGRATIONS = 'INTEGRATIONS'

export function integrationsSelector(state) {
  return formsSelector(state)[INTEGRATIONS] || integrationsSelector.default
}
integrationsSelector.default = {}

export function integrationsHaveLoadedSelector(state) {
  return !!formsSelector(state)[INTEGRATIONS]
}

export function integrationSelector(state, {integrationID}) {
  return integrationsSelector(state)[integrationID]
}

export const integrationsSortedByNameSelector = createSelector(
  integrationsSelector,
  (integrations) => sortBy(integrations, 'name'),
)

export const accountingIntegrationsSelector = createSelector(
  integrationsSortedByNameSelector,
  (integrations) =>
    integrations.filter((integration) => integration.vendor === QUICK_BOOKS),
)

export const paymentIntegrationsSelector = createSelector(
  integrationsSortedByNameSelector,
  (integrations) =>
    integrations.filter(
      (integration) => integration.vendor === STRIPE_PAYMENTS,
    ),
)

export const paymentIntegrationIDSelector = createSelector(
  paymentIntegrationsSelector,
  (integrations) => (integrations.length ? integrations[0].id : null),
)

export const isPaymentsSetupSelector = createSelector(
  paymentIntegrationIDSelector,
  (integrationID) => !!integrationID,
)

export const stripeUserIDSelector = createSelector(
  paymentIntegrationsSelector,
  (integrations) =>
    integrations.length ? integrations[0].vendor_config.stripe_user_id : null,
)

export function setIntegrations(integrations) {
  setForm(INTEGRATIONS, keyBy(integrations, 'id'))
}

export function setIntegration(integration) {
  updateForm(INTEGRATIONS, {[integration.id]: integration})
}

export function removeIntegration(integrationID) {
  removeFormValue(INTEGRATIONS, [integrationID])
}

export async function getIntegrations() {
  try {
    const integrations = await fetchAllAPI('/integration/', 'integration')

    setIntegrations(integrations)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting integrations.',
        details: err.message || err.error_message,
      },
      `Error getting integrations. ${err.error_message || err.message}`,
      err,
    )

    setIntegrations([])
  }
}
