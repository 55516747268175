import PropTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst.js'
import difference from 'lodash/difference.js'

import {getUTCOffset} from '../../../../common/date.js'
import {closeModal} from '../../../../redux/actions/ui/index.js'
import {
  orderNumbersShippedWithAdditionalDocumentsSelector,
  orderNumbersShippedWithAmazonSFPGroundSelector,
  orderNumbersShippedWithCanadaPostSelector,
} from '../../../../data/orders.js'
import {
  abodeLinkSelector,
  additionalDocumentsLinkSelector,
  canadaPostDocumentsLinkSelector,
  atLeastOneSplitOrderSelector,
} from '../../../../redux/selectors/ui/labelViewForm.js'
import {
  labelPrinterIDSelector,
  additionalDocsPrinterIDSelector,
  canadaPostDocsPrinterIDSelector,
} from '../../../../data/printerSettings.js'
import {
  LABEL_VIEW_ABODE_FORM,
  viewAndPrintDocument,
} from '../../../../redux/actions/ui/labelViewForm/index.js'

import {
  HK_PRINT_TO_TAB_A,
  HK_PRINT_TO_TAB_B,
  HK_PRINT_TO_TAB_C,
  HK_SEND_TO_PRINTER_B,
  HK_SEND_TO_PRINTER_C,
} from '../../../../common/constants/HotKeys.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../../../common/components/Link/LinkButton.js'
import ErrorMessage from '../../../../common/components/ErrorMessage.js'
import ButtonSendToPrinter from '../../../../common/components/Button/ButtonSendToPrinter.js'

import AbodeForm from './AbodeForm.js'
import {dispatch, useSelector} from '../../../../store.js'
import {Count, PluralBlock} from '../../../../common/components/Plural.js'

export default function LabelViewForm({
  orderNumbers,
  labelType,
  docTypes,
  fromModal,
  showSingleOrderNumber,
}) {
  labelType = labelType === 'return' ? 'return' : 'shipping'
  docTypes = docTypes || ['label', 'pick', 'pack']

  const orderNumbersShippedWithCanadaPost = useSelector((state) =>
    orderNumbersShippedWithCanadaPostSelector(state, {orderNumbers}),
  )
  const orderNumbersNotShippedWithCanadaPost = difference(
    orderNumbers,
    orderNumbersShippedWithCanadaPost,
  )
  const orderNumbersShippedWithAdditionalDocuments = useSelector((state) =>
    orderNumbersShippedWithAdditionalDocumentsSelector(state, {orderNumbers}),
  )

  const loading = useSelector((state) => state.ui.labelViewForm.loading)
  const error = useSelector((state) => state.ui.labelViewForm.error)
  const abodeLink = useSelector((state) =>
    abodeLinkSelector(state, {
      formName: LABEL_VIEW_ABODE_FORM,
      orderNumbers: orderNumbersNotShippedWithCanadaPost,
      labelType,
      utcOffset: getUTCOffset(),
      docTypes,
    }),
  )
  const additionalDocumentsLink = useSelector((state) =>
    additionalDocumentsLinkSelector(state, {
      orderNumbers: orderNumbersShippedWithAdditionalDocuments,
      labelType,
    }),
  )
  const canadaPostDocumentsLink = useSelector((state) =>
    canadaPostDocumentsLinkSelector(state, {
      orderNumbers: orderNumbersShippedWithCanadaPost,
      labelType,
    }),
  )
  const numberShippedWithAdditionalDocuments =
    orderNumbersShippedWithAdditionalDocuments.length
  const numberShippedWithCanadaPost = orderNumbersShippedWithCanadaPost.length
  const numberNotShippedWithCanadaPost =
    orderNumbersNotShippedWithCanadaPost.length
  const firstOrderNumber = orderNumbersNotShippedWithCanadaPost[0] || ''
  const atLeastOneSplitOrder = useSelector((state) =>
    atLeastOneSplitOrderSelector(state, {orderNumbers}),
  )
  const labelPrinterID = useSelector(labelPrinterIDSelector)
  const additionalDocsPrinterID = useSelector(additionalDocsPrinterIDSelector)
  const canadaPostDocsPrinterID = useSelector(canadaPostDocsPrinterIDSelector)
  const shipperIsAmazonSFP =
    useSelector((state) =>
      orderNumbersShippedWithAmazonSFPGroundSelector(state, {orderNumbers}),
    ).length > 0

  return (
    <div className="meta-labelviewform fieldset--scroll-wrap padding-left-0">
      <div className="inner-wrap__panel--actions inner-wrap__abode">
        {numberNotShippedWithCanadaPost > 0 && (
          <fieldset className="fieldset--shipping-options fieldset--view-pdfs">
            <dl className="list">
              <dt className="list__title--label-success lh-md">
                <PluralBlock count={numberNotShippedWithCanadaPost}>
                  {showSingleOrderNumber &&
                    numberNotShippedWithCanadaPost === 1 && (
                      <span>Label for {firstOrderNumber} can be printed </span>
                    )}
                  {(!showSingleOrderNumber ||
                    numberNotShippedWithCanadaPost > 1) && (
                    <span>
                      <Count /> labels can be printed
                    </span>
                  )}
                </PluralBlock>
              </dt>
              {shipperIsAmazonSFP && (
                <dd className="list__item alert alert--neutral fs-n0 lh-md margin-top-10 margin-bottom-10">
                  <div className="margin-bottom-10">
                    <strong>
                      Amazon Shipping is currently a pickup-only service. Your
                      shipment requires a scheduled pickup to be processed.
                    </strong>
                  </div>
                  <div className="margin-bottom-15">
                    Use the following link and log in with your Amazon Seller
                    Central credentials.{' '}
                  </div>
                  <LinkButton
                    className="btn--primary no-underline"
                    isOutlined
                    size="sm"
                    href="https://ship.amazon.com/pickup"
                    target="_blank"
                  >
                    <strong>Schedule a pickup with Amazon →</strong>
                  </LinkButton>
                </dd>
              )}
            </dl>
            <div>
              <AbodeForm
                formName={LABEL_VIEW_ABODE_FORM}
                includeSplitQuantitiesCheckbox={atLeastOneSplitOrder}
                docTypes={docTypes}
                fromModal={fromModal}
                allowPrintConfigs
              />
              <ErrorMessage className="alert alert--error full-border lg-text margin-bottom-15">
                {error}
              </ErrorMessage>
              <div className="flex">
                <div>
                  <ButtonPrimary
                    onClick={() => dispatch(viewAndPrintDocument(abodeLink))}
                    className="margin-right-10 margin-bottom-5"
                    isLoading={loading}
                    isDisabled={!abodeLink}
                    hotKey={HK_PRINT_TO_TAB_A}
                  >
                    View/Print
                  </ButtonPrimary>
                </div>
                <div>
                  <ButtonSendToPrinter
                    className="margin-bottom-5"
                    title={`${upperFirst(labelType)} Label`}
                    pdfLink={abodeLink}
                    printerID={labelPrinterID}
                    documentCount={numberNotShippedWithCanadaPost}
                    onClick={({canPrint}) =>
                      fromModal && !canPrint && dispatch(closeModal())
                    }
                  />
                </div>
              </div>
            </div>
          </fieldset>
        )}
        {!!canadaPostDocumentsLink && (
          <fieldset className="fieldset--shipping-options fieldset--view-pdfs">
            <dl className="list margin-bottom-15">
              <dt className="list__title--label-success lh-md margin-bottom-10">
                <span>{numberShippedWithCanadaPost}</span>
                {' Canada Post label'}
                {numberShippedWithCanadaPost > 1 ? 's' : ''}
                {' can be printed'}
              </dt>
            </dl>
            <div className="flex">
              <div>
                <LinkButton
                  href={canadaPostDocumentsLink}
                  className="btn--md margin-right-10 margin-bottom-10"
                  target="_blank"
                  mode="primary"
                  hotKey={HK_PRINT_TO_TAB_B}
                >
                  View/Print
                </LinkButton>
              </div>
              <div>
                <ButtonSendToPrinter
                  className="margin-bottom-5"
                  title="Canada Post Document"
                  pdfLink={canadaPostDocumentsLink}
                  hotKey={HK_SEND_TO_PRINTER_B}
                  printerID={canadaPostDocsPrinterID}
                  documentCount={numberShippedWithCanadaPost}
                  onClick={({canPrint}) =>
                    fromModal && !canPrint && dispatch(closeModal())
                  }
                />
              </div>
            </div>
          </fieldset>
        )}
        {!!additionalDocumentsLink && (
          <fieldset className="fieldset--shipping-options fieldset--view-pdfs">
            <dl className="list margin-bottom-15">
              <dt className="list__title--label-success lh-md margin-bottom-10">
                <span>{numberShippedWithAdditionalDocuments}</span>
                {' additional document'}
                {numberShippedWithAdditionalDocuments > 1 ? 's' : ''}
                {' must also be printed'}
              </dt>
              <dd className="list__item fs-n1 lh-lg op-75">
                These documents are needed for orders going to international or
                military addresses
              </dd>
            </dl>
            <div className="flex">
              <div>
                <LinkButton
                  href={additionalDocumentsLink}
                  className="btn--md margin-right-10 margin-bottom-10"
                  target="_blank"
                  mode="primary"
                  hotKey={HK_PRINT_TO_TAB_C}
                >
                  View/Print
                </LinkButton>
              </div>
              <div>
                <ButtonSendToPrinter
                  className="margin-bottom-10"
                  title="Additional Document"
                  pdfLink={additionalDocumentsLink}
                  hotKey={HK_SEND_TO_PRINTER_C}
                  printerID={additionalDocsPrinterID}
                  documentCount={numberShippedWithAdditionalDocuments}
                  onClick={({canPrint}) =>
                    fromModal && !canPrint && dispatch(closeModal())
                  }
                />
              </div>
            </div>
          </fieldset>
        )}
      </div>
    </div>
  )
}

LabelViewForm.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  showSingleOrderNumber: PropTypes.bool,
  fromModal: PropTypes.bool,
  labelType: PropTypes.string,
  docTypes: PropTypes.arrayOf(PropTypes.string),
}
