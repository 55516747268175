import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  setExpandedSKUs,
  setExpandMode,
  expandModeSelector,
  expandedSKUsSelector,
} from './productListFunctions.js'

function ExpandAll({expandMode, expandedProductCount}) {
  const shouldCollapse =
    expandedProductCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={
        shouldCollapse ? 'Collapse all products' : 'Expand all products'
      }
    >
      <ExpandAllButton
        className="meta-productlistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          setExpandedSKUs([])
          setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          setExpandedSKUs([])
          setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}

ExpandAll.propTypes = {
  expandMode: PropTypes.string.isRequired,
  expandedProductCount: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  expandMode: expandModeSelector(state),
  expandedProductCount: expandedSKUsSelector(state).length,
})

export default connect(mapStateToProps)(ExpandAll)
