import PropTypes from 'prop-types'
import classNames from 'classnames'

import DropdownItem from './DropdownItem.js'

export default function DropdownItemButton({
  selectedValue,
  value,
  children,
  last,
  onClick,
}) {
  return (
    <DropdownItem last={last}>
      <button
        type="button"
        className={classNames('btn--link list__link--dropdown', {
          selected: value !== null && value === selectedValue,
        })}
        onClick={() => onClick(value)}
      >
        {children}
      </button>
    </DropdownItem>
  )
}

DropdownItemButton.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  last: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
