import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

export default class productTagStrategy extends baseStrategy {
  static title = 'Manage Product Tags'
  static requiredColumns(columnsToDataMap) {
    const columns = ['sku']

    if (columnsToDataMap.remove_tag === -1 && columnsToDataMap.add_tag === -1) {
      columns.push('add_tag')
      columns.push('remove_tag')
    }

    if (columnsToDataMap.remove_tag === -1 && columnsToDataMap.add_tag !== -1) {
      columns.push('add_tag')
    }

    return columns
  }

  static getExampleRows() {
    return () =>
      api.get('/product/', {limit: 10}).then(({json: data}) => {
        const rows = [['SKU *Req', 'Add Tag', 'Remove Tag']]

        data.product.forEach((product) => {
          product.tags.forEach((tag) => {
            rows.push([product.sku, tag.name, ''])
          })
        })

        if (rows.length === 1) {
          rows.push(['fake_sku_1', 'fake_tag_to_add_1', ''])
          rows.push(['fake_sku_1', 'fake_tag_to_add_2', ''])
          rows.push(['fake_sku_2', '', 'fake_tag_to_remove_1'])
        }

        return rows
      })
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      add_tag: find(/^((add|update|create).)?tag$/i),
      remove_tag: find(/^(remove|delete).tag$/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()
    const add_tag = row[columnsByName.add_tag]
    const remove_tag = row[columnsByName.remove_tag] || ''

    data.payload = {
      sku: row[columnsByName.sku],
      add_tag: !remove_tag ? add_tag : '',
      remove_tag,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    if (payload.remove_tag) {
      delete errors.add_tag
    } else {
      this.required(payload, errors, 'add_tag', 'Add Tag is required')
    }

    return errors
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const name = encodeURIComponent(
        data.payload.add_tag || data.payload.remove_tag,
      )
      const url = `/product/${sku}/tag/${name}`

      return data.payload.add_tag ? api.post(url) : api.delete(url)
    }
  }
}
