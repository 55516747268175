import PropTypes from 'prop-types'
import uniq from 'lodash/uniq.js'

import {getState, setForm, updateForm, removeForm} from '../../store.js'
import {
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  MO_SORT_OPTIONS,
  MO_STATII,
  MO_PLURAL_URI_COMPONENT,
} from '../../common/constants/MOs.js'
import {
  TAG_TYPE__MO,
  TAG_FILTER_OR,
  TAG_FILTER_OPTIONS,
} from '../../common/constants/Tags.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import apiverson from '../../common/apiverson.js'
import handleListSelection from '../../common/handleListSelection.js'
import {MO_LIST_PAGE} from '../../common/constants/Pages.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {setMOsStateAndEnsureProductsLoaded} from '../../data/mos.js'
import {navigate} from '../../common/location.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  MO_LIST_FORM,
  moListFormSelector,
  moListQuerySelector,
  queryParamsSelector,
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  expandedReferenceIDsSelector,
} from './moListSelectors.js'

export const MOListFormShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReferenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedReferenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandMode: PropTypes.string.isRequired,
})

export function setupMOListForm() {
  setForm(MO_LIST_FORM, {
    isLoading: false,
    count: 0,
    referenceIDList: [],
    selectedReferenceIDs: [],
    expandedReferenceIDs: [],
    expandMode: DEFAULT_EXPAND_MODE,
  })
}

export function updateMOListForm(updates, meta) {
  updateForm(MO_LIST_FORM, updates, meta)
}

export function removeMOListForm() {
  removeForm(MO_LIST_FORM)
}

export function navigateToMOListPage(query) {
  return navigate([MO_PLURAL_URI_COMPONENT], query)
}

export function navigateMOList(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (pathComponents[0] !== MO_PLURAL_URI_COMPONENT) {
    return
  } else {
    query = {...query, ...updates}
  }

  return navigate(pathComponents, query)
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updateStatus(status) {
  if (!MO_STATII.find(({slug}) => status === slug)) {
    status = DEFAULT_STATUS
  }

  if (status === DEFAULT_STATUS) {
    status = undefined
  }

  return {
    status,
    ...resetOffset(),
  }
}

export function setStatus(status) {
  return navigateMOList(updateStatus(status))
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_PER_PAGE
  }

  if (limit === DEFAULT_PER_PAGE) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updateMOListForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigateMOList(updates)
}

export function updateSort(sort) {
  if (!MO_SORT_OPTIONS.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  if (sort === DEFAULT_SORT) {
    sort = undefined
  }

  return {
    sort,
    ...resetOffset(),
  }
}

export function setSort(sort) {
  const updates = updateSort(sort)

  updateMOListForm(
    {
      sticky__sort: updates.sort,
    },
    {stickyProps: ['sticky__sort']},
  )

  return navigateMOList(updates)
}

export function updateSearchText(searchText) {
  searchText = (searchText || '').trim() || undefined

  return {
    searchText,
    ...resetOffset(),
  }
}

export function setSearchText(searchText) {
  return navigateMOList(updateSearchText(searchText))
}

export function updateTags(tags) {
  tags = tags || []

  if (!Array.isArray(tags)) {
    tags = [tags]
  }

  if (tags.length === 0) {
    tags = undefined
  } else {
    tags = uniq(tags)
  }

  return {
    tags,
    ...resetOffset(),
  }
}

export function setTags(tags) {
  return navigateMOList(updateTags(tags))
}

export function removeMOTagFilter(tagName) {
  const {tags} = moListQuerySelector(getState())

  return setTags(tags.filter((name) => name !== tagName))
}

export function updateUntagged(untagged) {
  if (!untagged) {
    untagged = undefined
  }

  return {
    untagged,
    ...resetOffset(),
  }
}

export function setUntagged(untagged) {
  return navigateMOList(updateUntagged(untagged))
}

export function updateTagFilterBy(tagFilterBy) {
  if (!TAG_FILTER_OPTIONS.find(({value}) => tagFilterBy === value)) {
    tagFilterBy = TAG_FILTER_OR
  }

  if (tagFilterBy === TAG_FILTER_OR) {
    tagFilterBy = undefined
  }

  return {
    tagFilterBy,
    ...resetOffset(),
  }
}

export function setTagFilterBy(tagFilterBy) {
  return navigateMOList(updateTagFilterBy(tagFilterBy))
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigateMOList(updateOffset(offset))
}

export function setExpandedReferenceIDs(expandedReferenceIDs) {
  updateMOListForm({
    expandedReferenceIDs,
  })
}

export function setExpandMode(expandMode) {
  updateMOListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

export function setSelectedReferenceIDs(selectedReferenceIDs) {
  updateMOListForm({
    selectedReferenceIDs,
  })
}

export function createAndApplyTagToMOs(referenceIDs) {
  showEditTagModal(TAG_TYPE__MO, null, {
    addTagToMOReferenceIDs: referenceIDs,
  })
}

export async function refreshMOList() {
  try {
    const currentPage = currentPageSelector(getState())
    if (currentPage !== MO_LIST_PAGE) {
      return
    }

    updateMOListForm({isLoading: true})

    const params = queryParamsSelector(getState())
    const {json} = await apiverson.get('/manufacturing_order/', params)

    await setMOsStateAndEnsureProductsLoaded(json.manufacturing_order)

    updateQueryResults(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error querying Manufacturing Orders.',
        details: err.message || err.error_message,
      },
      err,
    )
  }

  updateMOListForm({isLoading: false})
}

export function updateQueryResults({count, manufacturing_order}) {
  const {selectedReferenceIDs, expandedReferenceIDs} =
    moListFormSelector(getState())
  const referenceIDList = manufacturing_order.map((mo) => mo.reference_id)

  updateMOListForm({
    referenceIDList,
    count,
    selectedReferenceIDs: selectedReferenceIDs.filter((referenceID) =>
      referenceIDList.includes(referenceID),
    ),
    expandedReferenceIDs: expandedReferenceIDs.filter((referenceID) =>
      referenceIDList.includes(referenceID),
    ),
  })
}

export async function moListSelectReferenceID(
  referenceID,
  isSelected,
  isShiftKey,
) {
  const selectedReferenceIDs = selectedReferenceIDsSelector(getState())
  const referenceIDList = referenceIDListSelector(getState())

  setSelectedReferenceIDs(
    handleListSelection({
      value: referenceID,
      isSelected,
      isShiftKey,
      selected: selectedReferenceIDs,
      list: referenceIDList,
    }),
  )
}

export async function deselectMOs(referenceIDs) {
  const selectedReferenceIDs = selectedReferenceIDsSelector(getState())

  setSelectedReferenceIDs(
    selectedReferenceIDs.filter((id) => !referenceIDs.includes(id)),
  )
}

export async function moListToggleExpanded(referenceID) {
  const expandedReferenceIDs = expandedReferenceIDsSelector(getState())

  const referenceIDs = expandedReferenceIDs.filter((p) => p !== referenceID)

  if (!expandedReferenceIDs.includes(referenceID)) {
    referenceIDs.push(referenceID)
  }

  setExpandedReferenceIDs(referenceIDs)
}
