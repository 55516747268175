import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'

export const INITIATED_STATUS = 'initiated'
export const ACTIVE_STATUS = 'active'
export const FINISHED_STATUS = 'finished'
export const CANCELLED_STATUS = 'cancelled'
export const ALL_STATUS = 'all'

export const MO_STATUS_DISPLAY = {
  [INITIATED_STATUS]: 'Initiated',
  [ACTIVE_STATUS]: 'Active',
  [FINISHED_STATUS]: 'Finished',
  [CANCELLED_STATUS]: 'Cancelled',
  [ALL_STATUS]: 'All',
}

export const MO_STATII = [
  {
    name: MO_STATUS_DISPLAY[INITIATED_STATUS],
    slug: INITIATED_STATUS,
    badge: 'I',
  },
  {
    name: MO_STATUS_DISPLAY[ACTIVE_STATUS],
    slug: ACTIVE_STATUS,
    badge: 'A',
  },
  {
    name: MO_STATUS_DISPLAY[FINISHED_STATUS],
    slug: FINISHED_STATUS,
    badge: 'F',
  },
  {
    name: MO_STATUS_DISPLAY[CANCELLED_STATUS],
    slug: CANCELLED_STATUS,
    badge: 'C',
  },
  {
    name: MO_STATUS_DISPLAY[ALL_STATUS],
    slug: ALL_STATUS,
    badge: 'A',
  },
]

export const STATUS_FILTERS = [
  {
    value: INITIATED_STATUS,
    display: MO_STATUS_DISPLAY[INITIATED_STATUS],
  },
  {
    value: ACTIVE_STATUS,
    display: MO_STATUS_DISPLAY[ACTIVE_STATUS],
  },
  {
    value: FINISHED_STATUS,
    display: MO_STATUS_DISPLAY[FINISHED_STATUS],
  },
  {
    value: CANCELLED_STATUS,
    display: MO_STATUS_DISPLAY[CANCELLED_STATUS],
  },
  {value: ALL_STATUS, display: MO_STATUS_DISPLAY[ALL_STATUS]},
]

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_STATUS = INITIATED_STATUS
export const DEFAULT_SORT = '-updated_date'
export const DEFAULT_PER_PAGE = 10

export const MO_SORT_OPTIONS = [
  {value: 'reference_id', display: 'MFG Order ID (A→Z)'},
  {value: '-reference_id', display: 'MFG Order ID (Z→A)'},
  {value: 'status', display: 'Status'},
  {value: '-status', display: 'Status (Reversed)'},
  {value: 'warehouse_name', display: 'Warehouse Name (A→Z)'},
  {value: '-warehouse_name', display: 'Warehouse Name (Z→A)'},
  {value: '-created_date', display: 'Date Created (Newest)'},
  {value: 'created_date', display: 'Date Created (Oldest)'},
  {value: '-updated_date', display: 'Date Updated (Newest)'},
  {value: 'updated_date', display: 'Date Updated (Oldest)'},
]

export const MO_PLURAL_URI_COMPONENT = 'mos'
export const MO_SINGLE_URI_COMPONENT = 'mo'
