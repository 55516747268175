import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  CONDITIONS_JOINED_WITH_ALL,
  CONDITIONS_JOINED_WITH_ANY,
} from '../../../common/constants/Rules.js'
import {RuleShape} from '../../../common/PropTypes.js'
import RuleCondition from './RuleCondition/index.js'
import RuleAction from './RuleAction/index.js'
import {useSelector} from '../../../store.js'
import {ruleActionsSelector} from './rulesFunctions.js'
import {
  getConditions,
  getConditionsJoiner,
} from './RuleCondition/conditionalOptions.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'

export default function DisplayRule({rule, editRule}) {
  const actions = useSelector((state) =>
    ruleActionsSelector(state, {ruleID: rule.id}),
  )
  const actionCount = actions.filter((action) => !action.hide).length
  const conditionJoiner = getConditionsJoiner(rule)
  const conditions = getConditions(rule)

  return (
    <div className="medium-9 small-7 columns padding-left-0">
      <div className="wrap--rule-notes w-75">
        <div>
          <strong className="fs-00">Rule Description/Notes</strong>
        </div>
        {rule.internal_notes && (
          <div className="ws-pre-wrap fs-n0">{rule.internal_notes}</div>
        )}
        <ButtonLink className="fs-n0" onClick={() => editRule()}>
          {rule.internal_notes ? 'Edit' : 'Add note'}
        </ButtonLink>
      </div>
      <div
        className={classNames('wrap--rule-statement margin-top-5', {
          on: rule.active,
        })}
      >
        <div className="fs-00 margin-bottom-20">
          <span className="list__title--rule-condition read-only">WHEN</span>
          <strong className="lh-md">An order is created</strong>
        </div>
        <div className="margin-bottom-20">
          <span className="list__title--rule-condition read-only margin-top-2">
            IF
          </span>
          <ul className="list--no-style inline-block">
            {conditions.length > 1 && (
              <li className="list__item--multiple-subhead fs-00">
                <span>
                  {conditionJoiner === CONDITIONS_JOINED_WITH_ALL && 'All'}
                  {conditionJoiner === CONDITIONS_JOINED_WITH_ANY &&
                    'Any'} of the following conditions are met:
                </span>
              </li>
            )}
            {conditions.map((condition, index) => (
              <li
                className={classNames('fs-00', {
                  'list__item--square margin-top-5': conditions.length > 1,
                })}
                key={index}
              >
                <RuleCondition
                  ruleID={rule.id}
                  index={index}
                  condition={condition}
                  isEditing={false}
                />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span
            className={classNames('list__title--rule-condition read-only', {
              'margin-top-2': actionCount > 1,
              'margin-top-4': actionCount === 1,
            })}
          >
            THEN
          </span>
          <ul className="list--no-style inline-block">
            {actionCount > 1 && (
              <li className="list__item--multiple-subhead fs-00">
                Take the following actions:
              </li>
            )}
            {actions.map((action, index) =>
              action.hide ? null : (
                <li
                  className={classNames('fs-00', {
                    'list__item--square margin-top-5': actionCount > 1,
                  })}
                  key={index}
                >
                  <RuleAction
                    id={rule.id}
                    index={index}
                    action={action}
                    isEditing={false}
                  />
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

DisplayRule.propTypes = {
  rule: RuleShape,
  editRule: PropTypes.func.isRequired,
}
