import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, select, put} from 'redux-saga/effects'

import {
  FULFILLMENT_CHANNEL_FBM,
  FULFILLMENT_CHANNEL_FBA,
} from '../../../common/constants/CartVendorOptions.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {
  getFulfillmentChannelFromVendor,
  cartVendorSelector,
} from '../../../data/carts.js'
import {
  productCartFulfillmentLatencySelector,
  saveProductCartExtraInfo,
} from '../../../data/products.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

const AMAZON_FULFILLMENT_MODAL_FORM = 'AMAZON_FULFILLMENT_MODAL_FORM'
export const UPDATE_AMAZON_FULFILLMENT = 'UPDATE_AMAZON_FULFILLMENT'

export function showAmazonFulfillmentModal(sku, cartID, amazonFulfillment) {
  return setForm(AMAZON_FULFILLMENT_MODAL_FORM, {
    sku,
    cartID,
    amazonFulfillment,
    originalAmazonFulfillment: amazonFulfillment,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(AMAZON_FULFILLMENT_MODAL_FORM, updates)
}

export function closeModal() {
  return removeForm(AMAZON_FULFILLMENT_MODAL_FORM)
}

function updateAmazonFulfillment() {
  return {
    type: UPDATE_AMAZON_FULFILLMENT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[AMAZON_FULFILLMENT_MODAL_FORM],
)

export function* updateAmazonFulfillmentWorker() {
  try {
    const {sku, cartID, amazonFulfillment, originalAmazonFulfillment} =
      yield select(modalFormSelector)

    if (amazonFulfillment === originalAmazonFulfillment) {
      yield put(closeModal())

      return
    }

    const isFBA = amazonFulfillment === FULFILLMENT_CHANNEL_FBA
    const cartVendor = yield select(cartVendorSelector, {cartID})
    const fulfillment_latency = yield select(
      productCartFulfillmentLatencySelector,
      {sku, cartID},
    )

    const params = {
      fulfillment_channel: isFBA
        ? getFulfillmentChannelFromVendor(cartVendor)
        : 'DEFAULT',
      fulfillment_latency: !isFBA ? fulfillment_latency : null,
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    yield call(saveProductCartExtraInfo, sku, cartID, params)

    yield call(showMessageToast, 'Fulfillment channel status was updated')

    yield put(closeModal())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error setting fulfillment channel: ${
          err.message || err.error_message
        }`,
        isSaving: false,
      }),
    )
  }
}

function AmazonFulfillmentModal({form, ...props}) {
  return (
    <ConfirmModal
      title="Edit Fulfillment Channel Status"
      confirmText="Update"
      cancelText="Cancel"
      onConfirm={() => props.updateAmazonFulfillment()}
      onCancel={() => props.closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div className="alert alert--warning margin-bottom-20">
        <dl className="list">
          <dt className="list__title fs-00 margin-bottom-5">
            Friendly Reminder
          </dt>
          <dd className="list__item fs-n0 margin-bottom-5">
            Always update your Amazon fulfillment channel status here in Ordoro{' '}
            <strong>BEFORE</strong> updating it in your Amazon account.
          </dd>
          <dd className="list__item fs-n0 margin-bottom-0">
            <a
              className="margin-right-3"
              href="https://support.ordoro.com/what-are-amazon-ghost-listings-and-how-to-address-them/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Learn why this is important</strong>
            </a>
            →
          </dd>
        </dl>
      </div>
      <p className="fs-01 margin-bottom-20">
        What is the fulfillment channel status of this product?
      </p>
      <ul className="list list--form">
        <li className="list__item margin-bottom-20">
          <label className="flex">
            <input
              type="radio"
              className="margin-right-7 margin-bottom-0"
              value={FULFILLMENT_CHANNEL_FBA}
              checked={form.amazonFulfillment === FULFILLMENT_CHANNEL_FBA}
              onChange={() =>
                props.updateModalForm({
                  amazonFulfillment: FULFILLMENT_CHANNEL_FBA,
                })
              }
            />
            <span>
              <span className="block lh-sm margin-bottom-5">
                Fulfillment by Amazon (FBA)
              </span>
              <span className="unbold fs-n1 text--md-grey block">
                Ordoro <strong>WILL NOT</strong> update inventory to the Amazon
                sales channel.
              </span>
            </span>
          </label>
        </li>
        <li className="list__item">
          <label className="flex">
            <input
              type="radio"
              className="margin-right-7 margin-bottom-0"
              value={FULFILLMENT_CHANNEL_FBM}
              checked={form.amazonFulfillment === FULFILLMENT_CHANNEL_FBM}
              onChange={() =>
                props.updateModalForm({
                  amazonFulfillment: FULFILLMENT_CHANNEL_FBM,
                })
              }
            />
            <span>
              <span className="block lh-sm margin-bottom-5">
                Fulfillment by Merchant (FBM)
              </span>
              <span className="unbold fs-n1 text--md-grey block">
                Ordoro <strong>WILL</strong> update inventory to the Amazon
                sales channel. Please read the big yellow box before selecting
                this option.
              </span>
            </span>
          </label>
        </li>
      </ul>
    </ConfirmModal>
  )
}

AmazonFulfillmentModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    cartID: PropTypes.number.isRequired,
    amazonFulfillment: PropTypes.string.isRequired,
    originalAmazonFulfillment: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  updateAmazonFulfillment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  updateAmazonFulfillment,
  updateModalForm,
  closeModal,
}

export default formConnect(
  connect(null, mapDispatchToProps)(AmazonFulfillmentModal),
  modalFormSelector,
)
