import {put, call, select, takeEvery} from 'redux-saga/effects'

import {NEW_ID} from '../../../../common/constants/index.js'
import api from '../../../../common/api.js'
import {getUsers} from '../../../../data/users.js'
import {navigate} from '../../../actions/ui/index.js'
import {apiKeyEditFormSelector} from '../../../selectors/ui/settings/apiKeys.js'
import {updateAPIKey} from '../../../actions/data/apiKeys.js'
import {
  SAVE_API_KEY,
  ARCHIVE_API_KEY,
  updateAPIKeyForm,
} from '../../../actions/ui/settings/apiKeys.js'

export function* saveAPIKeyWorker({payload: {apiKeyID}}) {
  const {name} = yield select(apiKeyEditFormSelector, {apiKeyID})

  const params = {
    name,
  }

  yield put(updateAPIKeyForm(apiKeyID, {serverError: null, isSaving: true}))

  try {
    const {json} =
      apiKeyID === NEW_ID
        ? yield call(api.post, '/api_key/', params)
        : yield call(api.put, `/api_key/${apiKeyID}`, params)

    yield put(updateAPIKey(json))

    yield call(getUsers)

    if (apiKeyID === NEW_ID) {
      yield put(navigate(`#/settings/api_key/${json.id}/`))
    } else {
      yield put(navigate('#/settings/api_key'))
    }
  } catch (err) {
    yield put(
      updateAPIKeyForm(apiKeyID, {
        serverError: err.message || err.error_message,
      }),
    )
  }

  yield put(updateAPIKeyForm(apiKeyID, {isSaving: false}))
}

export function* archiveAPIKeyWorker({payload: {apiKeyID}}) {
  yield put(updateAPIKeyForm(apiKeyID, {archiveError: null, isArchiving: true}))

  try {
    const {json} = yield call(api.delete, `/api_key/${apiKeyID}`)

    yield put(navigate('#/settings/api_key'))

    yield put(updateAPIKey(json))

    yield put(updateAPIKeyForm(apiKeyID, {showArchiveConfirm: false}))
  } catch (err) {
    yield put(
      updateAPIKeyForm(apiKeyID, {
        archiveError: err.message || err.error_message,
      }),
    )
  }

  yield put(updateAPIKeyForm(apiKeyID, {isArchiving: false}))
}

export default function* apiKeysSaga() {
  yield takeEvery(SAVE_API_KEY, saveAPIKeyWorker)
  yield takeEvery(ARCHIVE_API_KEY, archiveAPIKeyWorker)
}
