import PropTypes from 'prop-types'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
} from '../../../store.js'
import {orderSelector} from '../../../data/orders.js'
import {
  Count,
  Plural,
  plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {buildPath} from '../../../common/querystring.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import NotifyCartAboutTrackingDeleteCheckbox from '../../../common/components/Order/NotifyCartAboutTrackingDeleteCheckbox.js'
import {deleteTracking} from './DeleteTrackingModal.js'
import apiverson from '../../../common/apiverson.js'
import {
  labelInfoIDsForOrdersAndLabelTypesSelector,
  resetShipFrom,
} from '../../../data/labelInfos/index.js'

export const MODAL_FORM = 'MARK_AS_AWAITING_FULFILLMENT_MODAL'

export async function showMarkAsAwaitingFulfillmentModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    notifyCart: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export async function deleteDropshipment(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})

  if (!order.dropshipping_info.requested_date) {
    return
  }

  await apiverson.delete(buildPath(['order', orderNumber, 'dropship']))
}

export async function markAwaitingFulfillment() {
  try {
    const {orderNumbers, notifyCart} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await Promise.all(
      orderNumbers.map(async (orderNumber) => {
        await Promise.all([
          deleteTracking(orderNumber, notifyCart),
          deleteDropshipment(orderNumber),
        ])
      }),
    )

    const labelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(
      getState(),
      {
        orderNumbers,
        labelTypes: ['shipping', 'return'],
      },
    )
    await resetShipFrom(labelInfoIDs)

    closeModal()

    showMessageToast(
      plural(orderNumbers)`${Count} ${[
        'orders were',
        'order was',
      ]} successfully marked as awaiting fulfillment.`,
    )

    await refreshOrderListAndCounts()
  } catch (err) {
    updateModalForm({
      serverError: `Could not mark orders as awaiting fulfillment. ${err.message}`,
      isSaving: false,
    })
  }
}

function MarkAsAwaitingFulfillmentModal({form}) {
  return (
    <ConfirmModal
      title="Mark as Awaiting Fulfillment"
      modalSize="sm"
      onConfirm={() => markAwaitingFulfillment()}
      onCancel={() => closeModal()}
      error={form.serverError}
      isSaving={form.isSaving}
    >
      <PluralBlock array={form.orderNumbers}>
        <p>
          <strong>
            Are you sure you want to mark{' '}
            <Plural s="this order" p="these orders" /> as Awaiting Fulfillment?
          </strong>
        </p>
        <p className="margin-bottom-0">
          This will delete any shipping labels, tracking information, or
          dropshipment requests associated with{' '}
          <Plural s="this order" p="these orders" />.
        </p>
        <NotifyCartAboutTrackingDeleteCheckbox
          orderNumbers={form.orderNumbers}
          labelType="shipping"
          checked={form.notifyCart}
          onChange={(notifyCart) => updateModalForm({notifyCart})}
        />
      </PluralBlock>
    </ConfirmModal>
  )
}

MarkAsAwaitingFulfillmentModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    notifyCart: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(MarkAsAwaitingFulfillmentModal, modalFormSelector)
