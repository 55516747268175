import AddressFilter from '../../../../common/components/AddressFilter.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {showPasteAddressModal} from '../../../../common/components/Modal/PasteAddressModal.js'
import TextInput from '../../../../common/components/TextInput.js'
import {QUICK_SHIP_SHIPPING_ADDRESS} from '../../../../common/constants/AddressTypes.js'
import {setFormValue, useForm} from '../../../../store.js'
import {MODAL_FORM, updateQuickShipForm} from './quickShipModalFunctions.js'

export default function ShipToPanel() {
  const form = useForm(MODAL_FORM)

  return (
    <>
      <p className="fs-02 lh-md margin-bottom-25">
        <strong>Where are you shipping to?</strong>
      </p>

      <div className="row">
        <div className="medium-9 columns">
          <ul className="list list--no-style list--manual-address">
            <li className="list__item fs-n1 margin-bottom-15">
              <ButtonPrimary
                isOutlined
                size="x-sm"
                onClick={() =>
                  showPasteAddressModal({
                    addressType: QUICK_SHIP_SHIPPING_ADDRESS,
                    address: form.shipping_address,
                  })
                }
              >
                Paste Address
              </ButtonPrimary>
            </li>
            <li className="list__item list__item--form margin-top-5 margin-bottom-10">
              <AddressFilter
                label="Name"
                required={
                  !form.shipping_address.company ||
                  !!(
                    form.shipping_address.name && form.shipping_address.company
                  )
                }
                value={form.shipping_address.name || ''}
                onSelect={(address) =>
                  updateQuickShipForm({shipping_address: {...address}})
                }
                onChange={(name) =>
                  setFormValue(MODAL_FORM, ['shipping_address', 'name'], name)
                }
                dropdown="QS_SHIPPING_ADDRESS"
              />
            </li>
            <li className="list__item list__item--form">
              <TextInput
                label="Company"
                required={!form.shipping_address.name}
                className="margin-bottom-0"
                id="shipping_address__company"
                value={form.shipping_address.company}
                onChange={(value) =>
                  setFormValue(
                    MODAL_FORM,
                    ['shipping_address', 'company'],
                    value,
                  )
                }
                fsExclude
              />
            </li>
            <li className="list__item list__item--form">
              <TextInput
                label="Phone"
                className="margin-bottom-0"
                id="shipping_address__phone"
                value={form.shipping_address.phone}
                onChange={(value) =>
                  setFormValue(MODAL_FORM, ['shipping_address', 'phone'], value)
                }
                fsExclude
              />
            </li>
            <li className="list__item list__item--form">
              <TextInput
                label="Email"
                className="margin-bottom-0"
                id="shipping_address__email"
                value={form.shipping_address.email}
                onChange={(value) =>
                  setFormValue(MODAL_FORM, ['shipping_address', 'email'], value)
                }
                fsExclude
              />
            </li>
            <li className="list__item list__item--form">
              <TextInput
                label="Street 1"
                required
                className="margin-bottom-0"
                id="shipping_address__street1"
                value={form.shipping_address.street1}
                onChange={(value) =>
                  setFormValue(
                    MODAL_FORM,
                    ['shipping_address', 'street1'],
                    value,
                  )
                }
                fsExclude
              />
            </li>
            <li className="list__item list__item--form">
              <TextInput
                label="Street 2"
                className="margin-bottom-0"
                id="shipping_address__street2"
                value={form.shipping_address.street2}
                onChange={(value) =>
                  setFormValue(
                    MODAL_FORM,
                    ['shipping_address', 'street2'],
                    value,
                  )
                }
                fsExclude
              />
            </li>
            <li className="list__item list__item--form flex--justify">
              <div className="wrap--input-half">
                <TextInput
                  label="City"
                  required
                  id="shipping_address__city"
                  value={form.shipping_address.city}
                  onChange={(value) =>
                    setFormValue(
                      MODAL_FORM,
                      ['shipping_address', 'city'],
                      value,
                    )
                  }
                  fsExclude
                />
              </div>
              <div className="wrap--input-eighth">
                <TextInput
                  label="State"
                  id="shipping_address__state"
                  value={form.shipping_address.state}
                  onChange={(value) =>
                    setFormValue(
                      MODAL_FORM,
                      ['shipping_address', 'state'],
                      value,
                    )
                  }
                  fsExclude
                />
              </div>
              <div className="wrap--input-third">
                <TextInput
                  label="Zip"
                  id="shipping_address__zip"
                  value={form.shipping_address.zip}
                  onChange={(value) =>
                    setFormValue(MODAL_FORM, ['shipping_address', 'zip'], value)
                  }
                  fsExclude
                />
              </div>
            </li>
            <li className="list__item list__item--form flex--justify">
              <div className="wrap--input-half">
                <TextInput
                  required
                  label="Country"
                  id="shipping_address__country"
                  value={form.shipping_address.country}
                  onChange={(value) =>
                    setFormValue(
                      MODAL_FORM,
                      ['shipping_address', 'country'],
                      value,
                    )
                  }
                  fsExclude
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
