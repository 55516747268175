import {useSelector} from '../../../store.js'
import {cartsSortedByNameSelector} from '../../../data/carts.js'

import EditCart from './EditCart.js'
import NewCart from './NewCart.js'

export default function CartList() {
  const carts = useSelector(cartsSortedByNameSelector)

  return (
    <div className="medium-12 columns">
      <ul className="list list--settings">
        {carts.map((cart) => (
          <EditCart key={cart.id} cart={cart} />
        ))}
        <NewCart />
      </ul>
    </div>
  )
}
