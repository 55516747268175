import AddBatchCommentModal from './AddBatchCommentModal.js'
import AddToBatchModal from './AddToBatchModal.js'
import ArchiveBatchModal from './ArchiveBatchModal.js'
import BatchListFilterModal from './BatchListFilterModal/index.js'
import BulkUpdateModal from './BulkUpdateModal.js'
import CreateBatchModal from './CreateBatchModal.js'
import CreateLabelsModal from './CreateLabelsModal.js'
import EditBatchParamModal from './EditBatchParamModal.js'
import LatestBatchActionsModal from './LatestBatchActionsModal.js'
import RefreshRatesModal from './RefreshRatesModal.js'
import RemoveFromBatchModal from './RemoveFromBatchModal.js'
import SortBatchModal from './SortBatchModal.js'

export default function Modals() {
  return (
    <>
      <AddBatchCommentModal />
      <AddToBatchModal />
      <ArchiveBatchModal />
      <BatchListFilterModal />
      <BulkUpdateModal />
      <CreateBatchModal />
      <CreateLabelsModal />
      <EditBatchParamModal />
      <LatestBatchActionsModal />
      <RefreshRatesModal />
      <RemoveFromBatchModal />
      <SortBatchModal />
    </>
  )
}
