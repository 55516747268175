import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {returnOrderStatusSelector} from '../../redux/selectors/data/returnOrders.js'
import {showUpdateReturnOrdersStatusModal} from './Modals/UpdateReturnOrdersStatusModal.js'
import AddReturnOrderTagsDropdown from './AddReturnOrderTagsDropdown.js'

export function ActionsForSelected({
  referenceIDs,
  firstReturnOrderStatus,
  ...props
}) {
  return (
    <>
      <li className="inline-block fs-n0 v-align-middle divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() =>
            props.showUpdateReturnOrdersStatusModal(
              referenceIDs,
              firstReturnOrderStatus,
            )
          }
        >
          Update Status
        </button>
      </li>
      <AddReturnOrderTagsDropdown referenceIDs={referenceIDs} />
    </>
  )
}

ActionsForSelected.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  firstReturnOrderStatus: PropTypes.string.isRequired,
  showUpdateReturnOrdersStatusModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {referenceIDs}) {
  return {
    firstReturnOrderStatus: returnOrderStatusSelector(state, {
      referenceID: referenceIDs[0],
    }),
  }
}

const mapDispatchToProps = {
  showUpdateReturnOrdersStatusModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionsForSelected)
