import {updateForm} from '../forms.js'

export function setShowOrderTray(formName, showOrderTray) {
  return updateForm(formName, {showOrderTray}, {stickyProps: ['showOrderTray']})
}

export function setShowPackingList(formName, showPackingList) {
  return updateForm(
    formName,
    {showPackingList},
    {stickyProps: ['showPackingList']},
  )
}

export function setPackingListSort(formName, packingListSort) {
  return updateForm(
    formName,
    {packingListSort},
    {stickyProps: ['packingListSort']},
  )
}

export function setShowPickList(formName, showPickList) {
  return updateForm(formName, {showPickList}, {stickyProps: ['showPickList']})
}

export function setPickListSort(formName, pickListSort) {
  return updateForm(formName, {pickListSort}, {stickyProps: ['pickListSort']})
}

export function setTemplateID(formName, templateID) {
  return updateForm(formName, {templateID}, {stickyProps: ['templateID']})
}

export function setShowPrice(formName, showPrice) {
  return updateForm(formName, {showPrice}, {stickyProps: ['showPrice']})
}

export function setShowBarcode(formName, showBarcode) {
  return updateForm(formName, {showBarcode}, {stickyProps: ['showBarcode']})
}

export function setShowLogoOnLabel(formName, showLogoOnLabel) {
  return updateForm(
    formName,
    {showLogoOnLabel},
    {stickyProps: ['showLogoOnLabel']},
  )
}

export function setLabelPosition(formName, labelPosition) {
  return updateForm(formName, {labelPosition}, {stickyProps: ['labelPosition']})
}

export function setShowFullPage(formName, showFullPage) {
  return updateForm(formName, {showFullPage}, {stickyProps: ['showFullPage']})
}

export function setBreakPageOnOrder(formName, breakPageOnOrder) {
  return updateForm(
    formName,
    {breakPageOnOrder},
    {stickyProps: ['breakPageOnOrder']},
  )
}

export function setShowPackingListImages(formName, showPackingListImages) {
  return updateForm(
    formName,
    {showPackingListImages},
    {stickyProps: ['showPackingListImages']},
  )
}

export function setShowPickListImages(formName, showPickListImages) {
  return updateForm(
    formName,
    {showPickListImages},
    {stickyProps: ['showPickListImages']},
  )
}

export function setShowPickListTotalItems(formName, showPickListTotalItems) {
  return updateForm(
    formName,
    {showPickListTotalItems},
    {stickyProps: ['showPickListTotalItems']},
  )
}

export function setShowPickListTotalSKUs(formName, showPickListTotalSKUs) {
  return updateForm(
    formName,
    {showPickListTotalSKUs},
    {stickyProps: ['showPickListTotalSKUs']},
  )
}

export function setShowPickListTotalOrders(formName, showPickListTotalOrders) {
  return updateForm(
    formName,
    {showPickListTotalOrders},
    {stickyProps: ['showPickListTotalOrders']},
  )
}

export function setHighlightPickLineQty(formName, highlight_pick_line_qty) {
  return updateForm(
    formName,
    {highlight_pick_line_qty},
    {stickyProps: ['highlight_pick_line_qty']},
  )
}

export function setPackingListKitViewMode(formName, packingListKitViewMode) {
  return updateForm(
    formName,
    {packingListKitViewMode},
    {stickyProps: ['packingListKitViewMode']},
  )
}

export function setShowSiblingOrderQuantities(
  formName,
  showSiblingOrderQuantities,
) {
  return updateForm(
    formName,
    {showSiblingOrderQuantities},
    {stickyProps: ['showSiblingOrderQuantities']},
  )
}

export function setShowZeroQuantityLines(formName, showZeroQuantityLines) {
  return updateForm(
    formName,
    {showZeroQuantityLines},
    {stickyProps: ['showZeroQuantityLines']},
  )
}

export function setHighlightPackLineQty(formName, highlight_pack_line_qty) {
  return updateForm(
    formName,
    {highlight_pack_line_qty},
    {stickyProps: ['highlight_pack_line_qty']},
  )
}

export function setShowPickListPOH(formName, show_pick_list_poh) {
  return updateForm(
    formName,
    {show_pick_list_poh},
    {stickyProps: ['show_pick_list_poh']},
  )
}

export function setShowPackSKUBarcode(formName, show_pack_sku_barcode) {
  return updateForm(
    formName,
    {show_pack_sku_barcode},
    {stickyProps: ['show_pack_sku_barcode']},
  )
}

export function setShowPickSKUBarcode(formName, show_pick_sku_barcode) {
  return updateForm(
    formName,
    {show_pick_sku_barcode},
    {stickyProps: ['show_pick_sku_barcode']},
  )
}

export function setShowPackingListDetails(formName, show_packing_list_details) {
  return updateForm(
    formName,
    {show_packing_list_details},
    {stickyProps: ['show_packing_list_details']},
  )
}

export function setShowShippingInfo(formName, showShippingInfo) {
  return updateForm(
    formName,
    {showShippingInfo},
    {stickyProps: ['showShippingInfo']},
  )
}

export function setShowWarehouseLocation(formName, showWarehouseLocation) {
  return updateForm(
    formName,
    {showWarehouseLocation},
    {stickyProps: ['showWarehouseLocation']},
  )
}

export function setShowBillTo(formName, showBillTo) {
  return updateForm(formName, {showBillTo}, {stickyProps: ['showBillTo']})
}

export function setShowPackSerialNumbers(formName, showPackSerialNumbers) {
  return updateForm(
    formName,
    {showPackSerialNumbers},
    {stickyProps: ['showPackSerialNumbers']},
  )
}

export function setShowPickSerialNumbers(formName, showPickSerialNumbers) {
  return updateForm(
    formName,
    {showPickSerialNumbers},
    {stickyProps: ['showPickSerialNumbers']},
  )
}

export function setShowCustomerNotes(formName, showCustomerNotes) {
  return updateForm(
    formName,
    {showCustomerNotes},
    {stickyProps: ['showCustomerNotes']},
  )
}

export function setPickListKitViewMode(formName, pickListKitViewMode) {
  return updateForm(
    formName,
    {pickListKitViewMode},
    {stickyProps: ['pickListKitViewMode']},
  )
}

export function setUseCustomLayoutSettings(formName, useCustomLayoutSettings) {
  return updateForm(
    formName,
    {useCustomLayoutSettings},
    {stickyProps: ['useCustomLayoutSettings']},
  )
}

export function setPrintConfigID(formName, printConfigID) {
  return updateForm(formName, {printConfigID}, {stickyProps: ['printConfigID']})
}

export function setSize(formName, size) {
  return updateForm(formName, {size}, {stickyProps: ['size']})
}
