import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import RefreshButton from '../../common/components/List/RefreshButton.js'
import {refreshReturnOrderList} from './returnOrderListActions.js'

function RefreshReturnOrders({...props}) {
  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint="Refresh the RMA list"
    >
      <RefreshButton onClick={() => props.refreshReturnOrderList()} />
    </li>
  )
}

RefreshReturnOrders.propTypes = {
  refreshReturnOrderList: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  refreshReturnOrderList,
}

export default connect(null, mapDispatchToProps)(RefreshReturnOrders)
