import {
  AWAITING_FULFILLMENT,
  SHIPPED,
  DROPSHIPMENT_REQUESTED,
  CANCELLED,
  ARCHIVED,
  MERGED,
} from './Orders.js'

export const DATE_CREATED = 'created'
export const DATE_DROPSHIPPED = 'dropshipped'
export const DATE_ORDERED = 'ordered'
export const DATE_SHIPPED = 'shipped'
export const DATE_DELIVER_BY = 'deliver_by_date'
export const DATE_SHIP_BY = 'ship_by_date'

export const DATE_DROPSHIPPED_OPTION = {
  value: DATE_DROPSHIPPED,
  display: 'Dropshipped Date',
}

export const DATE_ORDERED_OPTION = {
  value: DATE_ORDERED,
  display: 'Ordered Date',
}

export const DATE_SHIPPED_OPTION = {
  value: DATE_SHIPPED,
  display: 'Shipped Date',
}

export const DATE_DELIVER_BY_OPTION = {
  value: DATE_DELIVER_BY,
  display: 'Deliver By Date',
}

export const DATE_SHIP_BY_OPTION = {
  value: DATE_SHIP_BY,
  display: 'Ship By Date',
}

export const DATE_CREATED_OPTION = {
  value: DATE_CREATED,
  display: 'Created Date',
}

export const DEFAULT_DATE_RANGE_TYPE = DATE_ORDERED

export const DATE_RANGE_FILTERS = [
  DATE_CREATED,
  DATE_ORDERED,
  DATE_DELIVER_BY,
  DATE_SHIP_BY,
  DATE_SHIPPED,
  DATE_DROPSHIPPED,
]

export const DATE_RANGE_FILTER_OPTIONS = [
  DATE_CREATED_OPTION,
  DATE_ORDERED_OPTION,
  DATE_DELIVER_BY_OPTION,
  DATE_SHIP_BY_OPTION,
  DATE_SHIPPED_OPTION,
  DATE_DROPSHIPPED_OPTION,
]

export const DEFAULT_DATE_RANGE_FILTER_OPTIONS = {
  [AWAITING_FULFILLMENT]: DATE_ORDERED,
  [SHIPPED]: DATE_SHIPPED,
  [DROPSHIPMENT_REQUESTED]: DATE_DROPSHIPPED,
  [CANCELLED]: DATE_ORDERED,
  [ARCHIVED]: DATE_ORDERED,
  [MERGED]: DATE_ORDERED,
}
