import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Footer from '../../common/components/List/Footer/index.js'
import {setLimit, setOffset} from './moListActions.js'
import {
  isLoadingSelector,
  moListQuerySelector,
  countSelector,
} from './moListSelectors.js'

export function MOListFooter({isLoading, limit, offset, count}) {
  return (
    <Footer
      isLoading={isLoading}
      limit={limit}
      offset={offset}
      count={count}
      setLimit={setLimit}
      setOffset={setOffset}
      preventResetOffset
    />
  )
}

MOListFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  const params = moListQuerySelector(state)

  return {
    isLoading: isLoadingSelector(state),
    limit: params.limit,
    offset: params.offset,
    count: countSelector(state),
  }
}

export default connect(mapStateToProps)(MOListFooter)
