import PropTypes from 'prop-types'

import {updateForm, useForm, useSelector} from '../../../store.js'
import TextInput from '../Common/TextInput.js'
import FormListItem from '../Common/FormListItem.js'
import {cartFormErrorsSelector} from './salesChannelsSelectors.js'

export default function DistributionCenterCodes({className, formName}) {
  const {distribution_center_codes, hasChanged__distribution_center_codes} =
    useForm(formName)
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <FormListItem className={`divider--top ${className || ''}`}>
      <label className="fs-00 margin-bottom-7" htmlFor="add_dist_center_code">
        Distribution Center Codes
      </label>
      {distribution_center_codes.map((distributionCenterCode, index) => (
        <div className="flex margin-bottom-15" key={index}>
          <TextInput
            className="margin-right-5"
            placeholder="A Distribution Center Code"
            value={distributionCenterCode}
            errorMessage={
              hasChanged__distribution_center_codes[index]
                ? errors[`distribution_center_codes__${index}`]
                : undefined
            }
            onChange={(event) => {
              const codes = [...distribution_center_codes]
              codes[index] = event.target.value
              const hasChanged__codes = [
                ...hasChanged__distribution_center_codes,
              ]
              hasChanged__codes[index] = true

              updateForm(formName, {
                distribution_center_codes: codes,
                hasChanged__distribution_center_codes: hasChanged__codes,
              })
            }}
          />
          <button
            className="btn btn--link no-underline"
            type="button"
            onClick={() =>
              updateForm(formName, {
                distribution_center_codes: distribution_center_codes.filter(
                  (_, i) => i !== index,
                ),
                hasChanged__distribution_center_codes:
                  hasChanged__distribution_center_codes.filter(
                    (_, i) => i !== index,
                  ),
              })
            }
          >
            <span className="i-trash fs-01" aria-hidden="true" />
          </button>
        </div>
      ))}
      <div>
        <button
          type="button"
          className="btn btn--primary btn--primary-ol btn--sm reauthorize"
          id="add_dist_center_code"
          onClick={() =>
            updateForm(formName, {
              distribution_center_codes: [...distribution_center_codes, ''],
            })
          }
        >
          {distribution_center_codes.length === 0
            ? 'Add a distribution center code'
            : 'Add another one'}
        </button>
      </div>
    </FormListItem>
  )
}

DistributionCenterCodes.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
