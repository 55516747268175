import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {
  SET_PRODUCT_COUNT,
  MODAL_NAME,
} from '../../../actions/ui/modals/confirmDropshipProductModal.js'

function productCount(
  state = INITIAL_STATE.ui.modals.confirmDropshipProductModal.productCount,
  action = {},
) {
  if (action.type === SET_PRODUCT_COUNT) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.productCount
  }

  return state
}

export default combineReducers({
  productCount,
})
