import get from 'lodash/get.js'
import format from 'date-fns/format'
import {createSelector} from 'reselect'
import PropTypes from 'prop-types'

import {
  getState,
  dispatch,
  setForm,
  updateForm,
  setFormValue,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import {AddressShape} from '../../../../common/PropTypes.js'
import {RETURN_ORDER_SINGLE_URI_COMPONENT} from '../../../../common/constants/ReturnOrders.js'
import apiverson from '../../../../common/apiverson.js'
import {getRealDate} from '../../../../common/date.js'
import {isPresent, isNumeric} from '../../../../common/utils.js'
import {pickAddress} from '../../../../common/address.js'
import {
  updateOrders,
  orderSelector,
  getSupplierID,
} from '../../../../data/orders.js'
import {navigate} from '../../../../common/location.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {ensureProductsLoaded} from '../../../../data/products.js'
import {setReturnOrderStateAndEnsureProductsLoaded} from '../../../../redux/actions/data/returnOrders.js'
import {
  defaultWarehouseSelector,
  warehouseAddressSelector,
} from '../../../../data/warehouses.js'
import {supplierAddressSelector} from '../../../../data/suppliers.js'
import {refreshReturnOrderList} from '../../returnOrderListActions.js'
import {COMMUNICATION_DROPSHIPMENT} from '../../../../common/constants/Suppliers.js'

export const CreateReturnOrderModalFormShape = PropTypes.shape({
  referenceID: PropTypes.string.isRequired,
  customerAddress: AddressShape.isRequired,
  returnToType: PropTypes.string.isRequired,
  warehouseID: PropTypes.number.isRequired,
  supplierID: PropTypes.number,
  otherAddress: AddressShape.isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
    }),
  ).isRequired,
  editCustomerAddress: PropTypes.bool.isRequired,
  originalOrderNumber: PropTypes.string,
  referenceIDIsDuplicated: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
})

export const MODAL_FORM = 'CREATE_RETURN_ORDER_MODAL'
export const INITIATE_RETURN = 'INITIATE_RETURN'
export const OTHER_ADDRESS = 'OTHER_ADDRESS'

export function showCreateReturnOrderModal(form) {
  setForm(MODAL_FORM, {
    referenceID: get(form, 'referenceID', ''),
    customerAddress: {
      name: get(form, 'customerAddress.name') || '',
      company: get(form, 'customerAddress.company') || '',
      email: get(form, 'customerAddress.email') || '',
      phone: get(form, 'customerAddress.phone') || '',
      street1: get(form, 'customerAddress.street1') || '',
      street2: get(form, 'customerAddress.street2') || '',
      city: get(form, 'customerAddress.city') || '',
      zip: get(form, 'customerAddress.zip') || '',
      state: get(form, 'customerAddress.state') || '',
      country: get(form, 'customerAddress.country') || '',
    },
    returnToType: get(form, 'returnToType'),
    warehouseID: get(form, 'warehouseID'),
    supplierID: get(form, 'supplierID'),
    otherAddress: {
      name: '',
      company: '',
      email: '',
      phone: '',
      street1: '',
      street2: '',
      city: '',
      zip: '',
      state: '',
      country: '',
    },
    customerNotes: '',
    internalNotes: '',
    lines: get(form, 'lines', []),
    editCustomerAddress: !get(form, 'customerAddress.street1'),
    originalOrderNumber: form.originalOrderNumber,
    referenceIDIsDuplicated: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateCreateReturnOrderModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function setCreateReturnOrderModalFormValue(path, value) {
  setFormValue(MODAL_FORM, path, value)
}

export function updateCreateReturnOrderFormLine(index, updates) {
  const form = createReturnOrderModalFormSelector(getState()) || {}
  const lines = [...form.lines]

  lines[index] = {...lines[index], ...updates}

  updateCreateReturnOrderModalForm({lines})
}

export function removeCreateReturnOrderModalForm() {
  removeForm(MODAL_FORM)
}

export function createReturnOrderModalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export const createReturnOrderModalErrorsSelector = createSelector(
  createReturnOrderModalFormSelector,
  (form) => {
    const errors = {}

    if (!form) {
      return errors
    }

    if (form.referenceIDIsDuplicated) {
      errors.referenceID = 'RMA ID already exists'
      errors.preventSave = true
    }

    if (!isPresent(form.referenceID)) {
      errors.referenceID = 'RMA ID is required'
      errors.preventSave = true
    }

    form.lines
      .filter(({isSelected}) => isSelected)
      .forEach(({sku, expectedQuantity}, index) => {
        if (!isPresent(sku)) {
          errors[`lines__${index}__sku`] = 'Product selection is required'
          errors.preventSave = true
        }

        if (!isPresent(expectedQuantity)) {
          errors[`lines__${index}__expectedQuantity`] = 'Quantity is required'
          errors.preventSave = true
        } else if (!isNumeric(expectedQuantity)) {
          errors[`lines__${index}__expectedQuantity`] =
            'Quantity must be a number'
          errors.preventSave = true
        }
      })

    if (
      !form.customerAddress.street1 ||
      !form.customerAddress.city ||
      (['US', 'CA', 'AU'].includes(form.customerAddress.country) &&
        !form.customerAddress.state) ||
      !form.customerAddress.zip ||
      !form.customerAddress.country
    ) {
      errors.preventSave = true
    }

    if (
      form.returnToType === OTHER_ADDRESS &&
      (!form.otherAddress.street1 ||
        !form.otherAddress.city ||
        (['US', 'CA', 'AU'].includes(form.otherAddress.country) &&
          !form.otherAddress.state) ||
        !form.otherAddress.zip ||
        !form.otherAddress.country)
    ) {
      errors.preventSave = true
    }

    return errors
  },
)

export function createReturnOrderParamsSelector(state) {
  const {
    referenceID,
    originalOrderNumber,
    customerAddress,
    otherAddress,
    returnToType,
    warehouseID,
    supplierID,
    customerNotes,
    internalNotes,
    lines,
  } = createReturnOrderModalFormSelector(state)
  const warehouseAddress = warehouseID
    ? warehouseAddressSelector(state, {warehouseID})
    : null
  const supplierAddress = supplierID
    ? supplierAddressSelector(state, {
        supplierID,
        type: COMMUNICATION_DROPSHIPMENT,
      })
    : null

  return {
    reference_id: referenceID,
    original_order_number: originalOrderNumber,
    customer_address: customerAddress,
    warehouse_id: warehouseID,
    return_to_address:
      returnToType === OTHER_ADDRESS
        ? otherAddress
        : returnToType === 'warehouse_id'
          ? pickAddress(warehouseAddress)
          : returnToType === 'supplier_id'
            ? pickAddress(supplierAddress)
            : null,
    customer_notes: customerNotes,
    internal_notes: internalNotes,
    lines: lines
      .filter(({isSelected}) => isSelected)
      .map(
        ({
          sku,
          expectedQuantity,
          returnCode,
          restockWarehouseID,
          totalPrice,
          product_serial_numbers,
        }) => ({
          sku,
          expected_quantity: Number(expectedQuantity),
          code: returnCode,
          restock_warehouse_id: restockWarehouseID,
          total_price: Number(totalPrice),
          product_serial_numbers: product_serial_numbers
            .split(/[ \n\t]+/)
            .filter((v) => v),
        }),
      ),
  }
}

export async function initiateReturn(orderNumber) {
  const form = {
    returnToType: 'warehouse_id',
    warehouseID: get(defaultWarehouseSelector(getState()), 'id'),
  }

  if (orderNumber) {
    const order = orderSelector(getState(), {orderNumber})
    form.warehouseID = get(order, 'warehouse.id') || form.warehouseID
    form.supplierID = getSupplierID(order)

    form.originalOrderNumber = orderNumber
    form.referenceID = orderNumber
    form.customerAddress = {
      ...order.shipping_address,
    }
    form.returnToType = form.supplierID ? 'supplier_id' : form.returnToType
    form.lines = order.lines.map((line) => ({
      sku: line.sku,
      quantityInOrder: line.quantity,
      expectedQuantity: String(line.quantity),
      totalPrice: line.total_price,
      isSelected: false,
      returnCode: '',
      restockWarehouseID: form.warehouseID,
      product_serial_numbers: line.product_serial_numbers.join('\n'),
    }))

    await ensureProductsLoaded(form.lines.map(({sku}) => sku))
  } else {
    const date = getRealDate()

    form.referenceID = format(date, 'yyyyMMdd-HHmmss')
  }

  showCreateReturnOrderModal(form)

  await checkReferenceID()
}

export async function checkReferenceID() {
  const {referenceID} = createReturnOrderModalFormSelector(getState())

  if (!referenceID) {
    updateCreateReturnOrderModalForm({referenceIDIsDuplicated: false})

    return
  }

  try {
    updateCreateReturnOrderModalForm({isSaving: true, serverError: null})

    await apiverson.get(`/return_order/RMA-${encodeURIComponent(referenceID)}`)

    updateCreateReturnOrderModalForm({referenceIDIsDuplicated: true})
  } catch (err) {
    if (err.response && err.response.status === 404) {
      updateCreateReturnOrderModalForm({referenceIDIsDuplicated: false})
    } else {
      updateCreateReturnOrderModalForm({
        serverError: `Error checking RMA reference ID: ${
          err.message || err.error_message
        }`,
      })
    }
  }

  updateCreateReturnOrderModalForm({isSaving: false})
}

export function addProductToCreateReturnOrder() {
  const form = createReturnOrderModalFormSelector(getState())

  updateCreateReturnOrderModalForm({
    lines: [
      ...form.lines,
      {
        sku: null,
        quantityInOrder: null,
        totalPrice: '0',
        expectedQuantity: '1',
        isSelected: true,
        returnCode: '',
        restockWarehouseID: form.warehouseID,
      },
    ],
  })
}

export function initializeCreateReturnOrderLine(index, product) {
  updateCreateReturnOrderFormLine(index, {
    sku: product.sku,
    quantityInOrder: null,
    totalPrice: product.price.toFixed(2),
    expectedQuantity: '1',
    product_serial_numbers: '',
  })
}

export async function createReturnOrder({goToRMA} = {}) {
  try {
    updateCreateReturnOrderModalForm({isSaving: true, serverError: null})

    const params = createReturnOrderParamsSelector(getState())

    const {json} = await apiverson.post('/return_order/', params)

    await setReturnOrderStateAndEnsureProductsLoaded(json)

    if (goToRMA) {
      navigate([
        RETURN_ORDER_SINGLE_URI_COMPONENT,
        encodeURIComponent(json.reference_id),
      ])
    } else {
      dispatch(refreshReturnOrderList())
    }

    removeCreateReturnOrderModalForm()

    showMessageToast('RMA was created')

    if (params.original_order_number) {
      await updateOrders([params.original_order_number])
    }
  } catch (err) {
    if (
      err.message.match(
        'duplicate key value violates unique constraint "return_order_company_id_reference_id_key"',
      )
    ) {
      updateCreateReturnOrderModalForm({
        referenceIDIsDuplicated: true,
        isSaving: false,
      })
    } else {
      updateCreateReturnOrderModalForm({
        serverError: `Error creating RMA: ${err.message || err.error_message}`,
        isSaving: false,
      })
    }
  }
}
