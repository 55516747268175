import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import round from 'lodash/round.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import DatePicker from '../../../common/components/DatePicker.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshOrderList} from '../orderListActions.js'
import {
  saveFinancialInfo,
  saveOrder,
  orderSelector,
  financialInfoSelector,
} from '../../../data/orders.js'

export const ORDER_TEXT_FIELD_MODAL = 'ORDER_TEXT_FIELD_MODAL'

export function showOrderCustomerNotesModal(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})

  showOrderTextFieldModal({
    orderNumber,
    value: order.notes_from_customer,
    display: 'Customer Notes',
    apiProp: 'notes_from_customer',
    inputType: 'textarea',
    SubComponent() {
      return (
        <div className="alert alert--standard align-center margin-top-10">
          <p className="fs-n1 lh-md margin-bottom-5">
            <strong>
              Any text entered above will appear on the order’s packing list.
            </strong>
          </p>
          <p className="fs-n1 lh-md margin-bottom-0">
            If you want to communicate internally with other members of your
            team, consider leaving an internal note or a timeline comment
            instead.
          </p>
        </div>
      )
    },
  })
}

export function showOrderInternalNotesModal(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})

  showOrderTextFieldModal({
    orderNumber,
    value: order.internal_notes,
    display: 'Internal Notes',
    apiProp: 'internal_notes',
    inputType: 'textarea',
  })
}

export function showOrderTextFieldModal({
  orderNumber,
  value,
  display,
  apiProp,
  isRequired,
  needsOrderListRefresh,
  inputType,
  SubComponent,
}) {
  setForm(ORDER_TEXT_FIELD_MODAL, {
    orderNumber,
    apiProp,
    value:
      inputType === 'date'
        ? value
          ? new Date(value)
          : null
        : isPresent(value)
          ? value
          : '',
    display,
    isRequired: !!isRequired,
    needsOrderListRefresh: !!needsOrderListRefresh,
    inputType: inputType || 'text',
    isSaving: false,
    serverError: null,
    SubComponent,
  })
}

export function updateModalForm(...args) {
  return updateForm(ORDER_TEXT_FIELD_MODAL, ...args)
}

export function closeModal() {
  return removeForm(ORDER_TEXT_FIELD_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[ORDER_TEXT_FIELD_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired, inputType}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
    }

    if (inputType === 'currency' && !isNumeric(value)) {
      errors.value = `${display} must be a number`
    }

    return errors
  },
)

export async function saveOrderTextField() {
  try {
    const {
      orderNumber,
      value,
      display,
      apiProp,
      needsOrderListRefresh,
      inputType,
    } = modalFormSelector(getState())

    const params = {
      [apiProp]:
        inputType === 'date'
          ? value
            ? value.toISOString()
            : null
          : inputType === 'currency'
            ? Number(value)
            : value,
    }

    updateModalForm({isSaving: true, serverError: null})

    if (
      ['discount_amount', 'shipping_amount', 'tax_amount'].includes(apiProp)
    ) {
      const financialInfo = financialInfoSelector(getState(), {orderNumber})
      financialInfo[apiProp] = params[apiProp]

      params.grand_total = round(
        financialInfo.product_amount +
          financialInfo.shipping_amount +
          financialInfo.tax_amount -
          financialInfo.discount_amount,
        2,
      )

      await saveFinancialInfo(orderNumber, params)
    } else {
      await saveOrder(orderNumber, params)
    }

    closeModal()

    showMessageToast(`Saved order ${display.toLowerCase()}`)

    if (needsOrderListRefresh) {
      await refreshOrderList()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function OrderTextFieldModal({form}) {
  const errors = useSelector(errorsSelector)
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title={`Edit Order ${form.display}`}
      modalSize="sm"
      onConfirm={() => saveOrderTextField()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        <div className="fs-01">
          <strong>{form.orderNumber}</strong>
        </div>
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'date' ? (
        <DatePicker
          id="order_param_modal"
          selected={form.value}
          onChange={(value) =>
            updateModalForm({
              value,
            })
          }
        />
      ) : form.inputType === 'currency' ? (
        <CurrencyInput
          className="biggie margin-bottom-0"
          width="sm"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : null}
      {form.SubComponent && <form.SubComponent form={form} />}
    </ConfirmModal>
  )
}

OrderTextFieldModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    value: PropTypes.any,
    display: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['text', 'textarea', 'date', 'currency'])
      .isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    SubComponent: PropTypes.func,
  }).isRequired,
}

export default onlyIfForm(OrderTextFieldModal, modalFormSelector)
