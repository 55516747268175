import {createSelector} from 'reselect'

export const serverInfoSelector = createSelector(
  (state) => state.data,
  ({serverInfo}) => serverInfo,
)

export const needsAppReloadSelector = createSelector(
  serverInfoSelector,
  ({needsAppReload}) => needsAppReload,
)
