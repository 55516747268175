import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import take from 'lodash/take.js'
import parse from 'date-fns/parse'

import states from '../../common/usStates.json'
import {
  allSuppliersSortedByNameSelector,
  getSupplierName,
} from '../../data/suppliers.js'
import {cartsSortedByNameSelector} from '../../data/carts.js'
import {analyticsFormSelector} from './analyticsSelectors.js'

export function getTotalRevenue(data) {
  return (
    data.total_product_sales +
    data.total_order_shipping_and_handling_amount +
    data.total_order_tax_amount -
    (data.total_order_discount_amount + data.total_product_discounts)
  )
}

export const costsOverTimeSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__order_placed_date'),
  (data) => {
    if (!data) {
      return null
    }

    const lines = ['Total Costs', 'Product Costs', 'Shipping Costs']

    const costsOverTime = {
      totals: lines.reduce((totals, title) => {
        totals[title] = 0
        return totals
      }, {}),
      lines,
    }

    costsOverTime.buckets = data.map((bucket) => {
      const date = parse(bucket.order_placed_date, 'yyyy-MM-dd', new Date())

      const newBucket = {
        key_as_string: bucket.order_placed_date,
        key: +date,
        doc_count: bucket.total_orders,
        'Product Costs': bucket.total_product_costs,
        'Shipping Costs': bucket.total_label_costs,
      }

      newBucket['Total Costs'] =
        newBucket['Product Costs'] + newBucket['Shipping Costs']

      lines.forEach((line) => {
        costsOverTime.totals[line] += newBucket[line]
      })

      return newBucket
    })

    return costsOverTime
  },
)

export const costsOverTimeAsCollectionSelector = createSelector(
  costsOverTimeSelector,
  (costsOverTime) =>
    costsOverTime.buckets.map((bucket) => ({
      date: bucket.key_as_string,
      cost_total$: bucket['Total Costs'],
      cost_product$: bucket['Product Costs'],
      cost_shipping$: bucket['Shipping Costs'],
    })),
)

export const revenueOverTimeSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__order_placed_date'),
  (data) => {
    if (!data) {
      return null
    }

    const lines = [
      'Total Revenue',
      'Products Value',
      'S & H',
      'Tax',
      'Discounts',
    ]

    const revenueOverTime = {
      totals: lines.reduce((totals, title) => {
        totals[title] = 0
        return totals
      }, {}),
      lines,
    }

    revenueOverTime.buckets = data.map((bucket) => {
      const date = parse(bucket.order_placed_date, 'yyyy-MM-dd', new Date())

      const newBucket = {
        key_as_string: bucket.order_placed_date,
        key: +date,
        doc_count: bucket.total_orders,
        'Products Value': bucket.total_product_sales,
        'S & H': bucket.total_order_shipping_and_handling_amount,
        Tax: bucket.total_order_tax_amount,
        Discounts:
          bucket.total_order_discount_amount + bucket.total_product_discounts,
      }

      newBucket['Total Revenue'] = getTotalRevenue(bucket)

      lines.forEach((line) => {
        revenueOverTime.totals[line] += newBucket[line]
      })

      return newBucket
    })

    revenueOverTime.orderCount = data.reduce(
      (prev, {total_orders}) => prev + total_orders,
      0,
    )

    revenueOverTime.averageOrderRevenue =
      revenueOverTime.totals['Total Revenue'] /
      (revenueOverTime.orderCount || 1)

    return revenueOverTime
  },
)

export const revenueOverTimeAsCollectionSelector = createSelector(
  revenueOverTimeSelector,
  (revenueOverTime) =>
    revenueOverTime.buckets.map((bucket) => ({
      date: bucket.key_as_string,
      rev_total$: bucket['Total Revenue'],
      rev_product$: bucket['Products Value'],
      rev_shipping$: bucket['S & H'],
      rev_tax$: bucket.Tax,
      rev_discount$: bucket.Discounts,
    })),
)

export const revenueAndCostsSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__order_status'),
  (data) => {
    if (!data) {
      return null
    }

    return {
      Unshipped: flattenAndSumStats(
        data
          .filter(({order_status}) =>
            ['awaiting_fulfillment', 'dropshipment_requested'].includes(
              order_status,
            ),
          )
          .reduce((prev, data) => {
            if (!prev) {
              prev = {}
            }

            for (const key in data) {
              prev[key] = (prev[key] || 0) + data[key]
            }
            return prev
          }, null),
      ),
      Shipped: flattenAndSumStats(
        data.find(({order_status}) => order_status === 'shipped'),
      ),
    }
  },
)

export function flattenAndSumStats(data) {
  const summaryData = {
    'Total Revenue': 0,
    'Product Total': 0,
    'S & H': 0,
    Tax: 0,
    Discounts: 0,
    'Total Costs': 0,
    'Product Costs': 0,
    'Shipping Costs': 0,
    'Total Margins': 0,
  }

  if (!data) {
    return summaryData
  }

  summaryData['Product Total'] = data.total_product_sales
  summaryData['S & H'] = data.total_order_shipping_and_handling_amount
  summaryData.Tax = data.total_order_tax_amount
  summaryData.Discounts =
    data.total_order_discount_amount + data.total_product_discounts
  summaryData['Product Costs'] = data.total_product_costs
  summaryData['Shipping Costs'] = data.total_label_costs

  summaryData['Total Revenue'] = getTotalRevenue(data)

  summaryData['Total Costs'] =
    summaryData['Product Costs'] + summaryData['Shipping Costs']

  summaryData['Total Margins'] =
    summaryData['Total Revenue'] - summaryData['Total Costs']

  return summaryData
}

export const revenueAndCostsAsCollectionSelector = createSelector(
  revenueAndCostsSelector,
  (revenueAndCosts) =>
    Object.keys(revenueAndCosts).map((type) => ({
      type,
      rev_total$: revenueAndCosts[type]['Total Revenue'],
      rev_product$: revenueAndCosts[type]['Product Total'],
      rev_shipping$: revenueAndCosts[type]['S & H'],
      rev_tax$: revenueAndCosts[type].Tax,
      rev_discount$: revenueAndCosts[type].Discounts,
      cost_total$: revenueAndCosts[type]['Total Costs'],
      cost_product$: revenueAndCosts[type]['Product Costs'],
      cost_shipping$: revenueAndCosts[type]['Shipping Costs'],
      margins$: revenueAndCosts[type]['Total Margins'],
    })),
)

export const revenueByStateSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__ship_to_state'),
  (data) => {
    if (!data) {
      return null
    }

    const revenueByState = {}

    revenueByState.buckets = data.reduce((list, bucket) => {
      if (!bucket.ship_to_state) {
        return list
      }

      const newBucket = {
        key: bucket.ship_to_state.toUpperCase(),
      }

      if (states[newBucket.key]) {
        newBucket.value = getTotalRevenue(bucket)
        newBucket.fullName = states[newBucket.key]

        list.push(newBucket)
      }

      return list
    }, [])

    revenueByState.buckets.sort((a, b) => {
      if (a.value > b.value) {
        return -1
      } else if (a.value === b.value) {
        return 0
      }
      return 1
    })

    revenueByState.topStates = take(revenueByState.buckets, 5)

    return revenueByState
  },
)

export const revenueByStateAsCollectionSelector = createSelector(
  revenueByStateSelector,
  (revenueByState) =>
    revenueByState.buckets.map((bucket) => ({
      state: bucket.key,
      rev$: bucket.value,
    })),
)

export const revenueByCartSelector = createSelector(
  (state) => get(analyticsFormSelector(state), 'order_financials__cart_id'),
  cartsSortedByNameSelector,
  (data, carts) => {
    if (!data) {
      return null
    }

    const revenueByCart = {}

    revenueByCart.buckets = data.map((bucket) => ({
      key: bucket.cart_id,
      value: getTotalRevenue(bucket),
    }))

    revenueByCart.buckets.sort((a, b) => {
      if (a.value > b.value) {
        return -1
      } else if (a.value === b.value) {
        return 0
      }
      return 1
    })

    revenueByCart.buckets.forEach((d) => {
      const cart = carts.find(({id}) => id === d.key)

      d.name = cart ? cart.name : 'Deleted Sales Channel'
    })

    return revenueByCart
  },
)

export const revenueByCartAsCollectionSelector = createSelector(
  revenueByCartSelector,
  (revenueByCart) =>
    revenueByCart.buckets.map((bucket) => ({
      channel: bucket.name,
      rev$: bucket.value,
    })),
)

export const revenueBySupplierSelector = createSelector(
  (state) =>
    get(
      analyticsFormSelector(state),
      'order_item_financials__product_supplier_id',
    ),
  allSuppliersSortedByNameSelector,
  (data, suppliers) => {
    if (!data) {
      return null
    }

    const revenueBySupplier = {}

    revenueBySupplier.buckets = data
      .filter(({product_supplier_id}) => product_supplier_id)
      .map((bucket) => ({
        key: bucket.product_supplier_id,
        value: bucket.total_product_sales - bucket.total_product_discounts,
      }))

    revenueBySupplier.buckets.sort((a, b) => {
      if (a.value > b.value) {
        return -1
      } else if (a.value === b.value) {
        return 0
      }
      return 1
    })

    revenueBySupplier.buckets.forEach((d) => {
      const supplier = suppliers.find(({id}) => id === d.key)

      d.name = supplier ? getSupplierName(supplier) : 'Deleted Supplier'
    })

    return revenueBySupplier
  },
)

export const revenueBySupplierAsCollectionSelector = createSelector(
  revenueBySupplierSelector,
  (revenueBySupplier) =>
    revenueBySupplier.buckets.map((bucket) => ({
      supplier: bucket.name,
      rev$: bucket.value,
    })),
)

export function financialExportToCSVParamsSelector(state, {type, date}) {
  if (type === 'revenueOverTime' || type === 'costsOverTime') {
    const costs = costsOverTimeAsCollectionSelector(state)
    const revenue = revenueOverTimeAsCollectionSelector(state)

    return {
      headers: [
        'date',
        'rev_total$',
        'rev_product$',
        'rev_shipping$',
        'rev_tax$',
        'rev_discount$',
        'cost_total$',
        'cost_product$',
        'cost_shipping$',
      ],
      data: costs.map((bucket, index) => ({
        ...bucket,
        ...revenue[index],
      })),
      fileName: `${date}_Revenue-and-Costs-Over-Time.csv`,
      toastMessage: 'Revenue and Costs Over Time CSV was exported',
    }
  } else if (type === 'revenueAndCosts') {
    return {
      headers: [
        'type',
        'rev_total$',
        'rev_product$',
        'rev_shipping$',
        'rev_tax$',
        'rev_discount$',
        'cost_total$',
        'cost_product$',
        'cost_shipping$',
        'margins$',
      ],
      data: revenueAndCostsAsCollectionSelector(state),
      fileName: `${date}_Revenue-and-Costs.csv`,
      toastMessage: 'Revenue and Costs Statement CSV was exported',
    }
  } else if (type === 'revenueByState') {
    return {
      headers: ['state', 'rev$'],
      data: revenueByStateAsCollectionSelector(state),
      fileName: `${date}_Revenue-by-State.csv`,
      toastMessage: 'Revenue by State CSV was exported',
    }
  } else if (type === 'revenueByCart') {
    return {
      headers: ['channel', 'rev$'],
      data: revenueByCartAsCollectionSelector(state),
      fileName: `${date}_Revenue-by-Sales-Channel.csv`,
      toastMessage: 'Revenue by Sales Channel CSV was exported',
    }
  } else if (type === 'revenueBySupplier') {
    return {
      headers: ['supplier', 'rev$'],
      data: revenueBySupplierAsCollectionSelector(state),
      fileName: `${date}_Revenue-by-Supplier.csv`,
      toastMessage: 'Revenue by Supplier CSV was exported',
    }
  }

  return null
}
