import {useMemo} from 'react'
import isEmpty from 'lodash/isEmpty.js'

import HeaderDropdown from '../../../../../common/components/List/HeaderDropdown.js'
import {showConfirmMarkAsShippedModal} from '../../../../OrderListPage/Modals/ConfirmMarkAsShippedModal.js'
import {showSendInvoiceModal} from '../../../../OrderListPage/Modals/SendInvoiceModal.js'
import {showConfirmOrderCancelModal} from '../../../../OrderListPage/Modals/ConfirmOrderCancelModal.js'
import {showConfirmTrackingWritebackModal} from '../../../../OrderListPage/Modals/ConfirmTrackingWritebackModal.js'
import {initiateReturn} from '../../../../ReturnOrderListPage/Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import OrderSelectAllButton from './OrderSelectAllButton.js'
import OrderSort from './OrderSort.js'
import ExpandAll from './ExpandAll.js'
import RefreshOrders from './RefreshOrders.js'
import BarcodeDropdown from './BarcodeDropdown.js'
import AddOrderTagsDropdown from './AddOrderTagsDropdown.js'
import PrintDropdown from './PrintDropdown.js'
import {navigateToBatchListPage} from '../../../../BatchListPage/batchListActions.js'
import {showSplitOrderModal} from '../../../../OrderListPage/Modals/SplitOrderModal.js'
import {showOrderListFilterModal} from '../../../../OrderListPage/Modals/OrderListFilterModal/orderListFilterModalFunctions.js'
import {showOrderRevisionModal} from '../../../../OrderListPage/Modals/OrderRevisionModal.js'
import {dispatch} from '../../../../../store.js'
import {showCreateChargeModal} from '../../../../../common/components/Modal/CreateChargeModal.js'
import {useSelector} from 'react-redux'
import {showRestoreOrderModal} from '../../../../OrderListPage/Modals/RestoreOrderModal.js'
import {showMarkAsAwaitingFulfillmentModal} from '../../../../OrderListPage/Modals/MarkAsAwaitingFulfillmentModal.js'
import {showEditOrderWeightModal} from '../../../../OrderListPage/Modals/EditOrderWeightModal.js'
import {showMergeOrdersModal} from '../../../../OrderListPage/Modals/MergeOrdersModal.js'
import {
  activeOrderNumbersSelector,
  batchReferenceIDSelector,
  countSelector,
  orderListQueryParamsSelector,
  selectedOrderActionsSelector,
  selectedOrderNumbersSelector,
  summarizeOrderDateRangeParams,
} from '../../../../OrderListPage/orderListSelectors.js'
import {
  attemptToAllocateSelectedOrders,
  cloneSelectedOrder,
  deallocateSelectedOrders,
  editDimensionsSelectedOrders,
} from '../../../../OrderListPage/orderListActions.js'
import {
  AWAITING_FULFILLMENT,
  SHIPPED,
} from '../../../../../common/constants/Orders.js'
import {showEditReportModal} from '../../../../ReportsPage/Modals/EditReportModal.js'
import {REPORT_EXPORT_ORDERS} from '../../../../../common/constants/Reports.js'
import {showPrintPickPackModal} from '../../../../../redux/actions/ui/modals/printPickPackModal.js'
import {showAddressLabelModal} from '../../../../OrderListPage/Modals/AddressLabelModal.js'
import {BatchActions} from './FilterControls.js'

export default function OrderListControls() {
  const selectedOrderNumbers = useSelector(selectedOrderNumbersSelector)
  const orderActions = useSelector(selectedOrderActionsSelector)
  const activeOrderNumbers = useSelector(activeOrderNumbersSelector)
  const orderQuery = useSelector(orderListQueryParamsSelector)
  const queryTotalCount = useSelector(countSelector)
  const {start_date, end_date} = useMemo(
    () => summarizeOrderDateRangeParams(orderQuery),
    [orderQuery],
  )
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const status = orderQuery.status || []

  const orderString = selectedOrderNumbers.length > 1 ? 'Orders' : 'Order'

  return (
    <ul className="list list--no-style list--orders-top-action-bar">
      <li className="inline-block v-align-middle margin-right-7">
        <OrderSelectAllButton />
      </li>
      <li className="inline-block margin-right-3">
        <strong className="list-items-count fs-n0 lh-sm">
          <span>{selectedOrderNumbers.length}</span>
          <span> {orderString} Selected</span>
        </strong>
      </li>
      <RefreshOrders />
      <ExpandAll />
      {(status.length === 0 ||
        status.includes(AWAITING_FULFILLMENT) ||
        status.includes(SHIPPED)) && <BarcodeDropdown />}
      <li className="inline-block divider--left">
        <button
          className="btn btn--primary btn--xx-sm btn--primary-ol"
          type="button"
          onClick={() => showOrderListFilterModal({query: orderQuery})}
        >
          <span
            className="i-filters fs-00 lh-0 v-align-middle margin-right-5"
            aria-hidden="true"
          />
          <span className="fs-n1 v-align-middle">Filters</span>
        </button>
      </li>
      <OrderSort />
      <HeaderDropdown
        label="Actions"
        className="divider--left"
        dropdown="DROPDOWN_ORDER_LIST_ORDER_ACTIONS"
      >
        {batchReferenceID && (
          <BatchActions orderNumbers={selectedOrderNumbers} />
        )}
        <li className="list__item list__item--dropdown list__item--dropdown-header">
          <strong>ACTIONS FOR SELECTED ORDERS</strong>
        </li>
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showEditReportModal({
                reportType: REPORT_EXPORT_ORDERS,
                singleRun: true,
                start_date,
                end_date,
                orderQuery,
                queryTotalCount,
                selectedOrderNumbers,
              })
            }
          >
            Export Order Info
          </button>
        </li>
        {orderActions.attempt_to_allocate && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => attemptToAllocateSelectedOrders()}
            >
              Allocate
            </button>
          </li>
        )}
        {orderActions.deallocate && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => deallocateSelectedOrders()}
            >
              Deallocate
            </button>
          </li>
        )}
        {orderActions.mark_as_shipped && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showConfirmMarkAsShippedModal(activeOrderNumbers)}
            >
              Mark as Shipped
            </button>
          </li>
        )}
        {orderActions.mark_as_awaiting_fulfillment && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() =>
                showMarkAsAwaitingFulfillmentModal(activeOrderNumbers)
              }
            >
              Mark as Awaiting Fulfillment
            </button>
          </li>
        )}
        {orderActions.edit_dimensions && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => editDimensionsSelectedOrders()}
            >
              Edit Dimensions
            </button>
          </li>
        )}
        {orderActions.edit_weight && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showEditOrderWeightModal(activeOrderNumbers)}
            >
              Edit Weight
            </button>
          </li>
        )}
        {orderActions.split && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showSplitOrderModal(activeOrderNumbers[0])}
            >
              Split {orderString}
            </button>
          </li>
        )}
        {orderActions.merge && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showMergeOrdersModal(activeOrderNumbers)}
            >
              Merge {orderString}
            </button>
          </li>
        )}
        {orderActions.clone && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => cloneSelectedOrder()}
            >
              Clone {orderString}
            </button>
          </li>
        )}
        {orderActions.create_charge && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showCreateChargeModal(selectedOrderNumbers)}
            >
              Process Payment
            </button>
          </li>
        )}
        {orderActions.send_invoice && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showSendInvoiceModal(selectedOrderNumbers[0])}
            >
              Send Invoice
            </button>
          </li>
        )}
        {orderActions.initiate_return && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => initiateReturn(selectedOrderNumbers[0])}
            >
              Initiate a Return (RMA)
            </button>
          </li>
        )}
        {orderActions.retry_tracking_writeback && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() =>
                showConfirmTrackingWritebackModal(selectedOrderNumbers)
              }
            >
              Retry Tracking Writeback
            </button>
          </li>
        )}
        {orderActions.manage_revision && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showOrderRevisionModal(selectedOrderNumbers)}
            >
              Manage Revisions
            </button>
          </li>
        )}
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() => dispatch(showPrintPickPackModal(activeOrderNumbers))}
          >
            Pick/Pack Lists
          </button>
        </li>
        {orderActions.address_labels && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown meta-printdropdown-button-address-label-modal"
              type="button"
              onClick={() => showAddressLabelModal(selectedOrderNumbers)}
            >
              Address Labels
            </button>
          </li>
        )}
        {orderActions.restore && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showRestoreOrderModal(activeOrderNumbers)}
            >
              Restore {orderString}
            </button>
          </li>
        )}
        {orderActions.cancel && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showConfirmOrderCancelModal(selectedOrderNumbers)}
            >
              Cancel {orderString}
            </button>
          </li>
        )}
        {!batchReferenceID && (
          <BatchActions orderNumbers={selectedOrderNumbers} />
        )}
        {isEmpty(orderActions) && (
          <li className="list__item list__item--dropdown">
            <div className="btn--link list__link--dropdown">
              {selectedOrderNumbers.length > 1
                ? 'There are no actions that can be taken on all of these orders at the same time.'
                : 'There is no action that can be taken on this order.'}
            </div>
          </li>
        )}
      </HeaderDropdown>
      <AddOrderTagsDropdown />
      <PrintDropdown
        selectedOrderNumbers={selectedOrderNumbers}
        orderActions={orderActions}
        className="margin-left-3"
      />
      <li className="inline-block divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => navigateToBatchListPage()}
        >
          Batches
        </button>
      </li>
    </ul>
  )
}
