import {all, takeEvery, put, call, select} from 'redux-saga/effects'

import api from '../../../../../../common/api.js'
import {NEW_ID} from '../../../../../../common/constants/index.js'
import {setGlobalError} from '../../../../../actions/ui/index.js'
import {setShipper} from '../../../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../../actions/ui/settings/shippers/index.js'
import {shipperFormSelector} from '../../../../../selectors/ui/settings/shippers/index.js'
import {SAVE_SHIPPER_AMAZON_SHIPPER} from '../../../../../actions/ui/settings/shippers/forms/amazonShipper.js'

export function* saveShipperAmazonShipperWorker({payload: shipperID}) {
  if (shipperID === NEW_ID) {
    return
  }

  const {name, hidden_shipping_methods} = yield select(shipperFormSelector, {
    shipperID,
  })

  const params = {
    name,
    hidden_shipping_methods,
  }

  yield put(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = yield call(api.put, `/shipper/${shipperID}/`, params)

    yield put(goToShipperSettings())

    yield call(setShipper, json)
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'There was an error while saving shipper.',
        },
        err,
      ),
    )
  } finally {
    yield put(updateForm(shipperID, {isSaving: false}))
  }
}

export default function* amazonShipperSaga() {
  yield all([
    takeEvery(SAVE_SHIPPER_AMAZON_SHIPPER, saveShipperAmazonShipperWorker),
  ])
}
