import POSelectAllButton from './POSelectAllButton.js'
import {poIDListSelector} from './purchaseOrderListSelectors.js'

import Row from './Row.js'
import EmptyList from './EmptyList.js'
import {useSelector} from '../../store.js'

export default function Table() {
  const poIDList = useSelector(poIDListSelector)

  return (
    <table className="table table--zebra-list table--product-list">
      <thead>
        <tr className="table__tr">
          <th className="table__th table__th--sm v-align-middle no-border table__th--order-checkbox">
            <POSelectAllButton />
          </th>
          <th className="table__th table__th--sm th-width-20 v-align-middle no-border">
            Purchase Order
          </th>
          <th className="table__th table__th--sm th-width-20 v-align-middle no-border">
            Contents
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            Receiving Warehouse
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            Supplier
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            Last Updated
          </th>
          <th className="table__th table__th--sm th-width-10 v-align-middle no-border">
            Est. Delivery
          </th>
          <th className="table__th table__th--sm v-align-middle no-border" />
        </tr>
      </thead>
      <tbody className="table__tbody--full-border">
        {poIDList.map((poID) => (
          <Row key={poID} poID={poID} />
        ))}
        {poIDList.length === 0 && <EmptyList colSpan="7" />}
      </tbody>
    </table>
  )
}
