import PropTypes from 'prop-types'

import {AddressShape} from '../../../../../../../common/PropTypes.js'
import ButtonLink from '../../../../../../../common/components/Button/ButtonLink.js'
import AddressValidationIcon from '../../../../../../../common/components/Order/AddressValidationIcon.js'
import CustomerNotePopOver from '../../../Fields/CustomerNotePopOver.js'
import {showOrderAddressModal} from '../../../../../../../data/orders.js'
import {PRIVACY_MASK} from '../../../../../../../common/analytics/index.js'

export default function CompactAddress({
  orderNumber,
  notes,
  addressType,
  address,
  canEdit,
}) {
  const noAddress =
    !address.company && !address.name && !address.city && !address.street1

  const addressBlock = (
    <>
      {noAddress && (
        <div className="fs-n1">
          <em>No address specified</em>
        </div>
      )}
      {!noAddress && (
        <div>
          <div className="fs-n1">{address.name || address.company}</div>
          <div className="flex fs-n1">
            <AddressValidationIcon address={address} />
            <div className="inline-block lh-md">
              {address.city && `${address.city}, `} {address.state}{' '}
              {address.country}
            </div>
          </div>
        </div>
      )}
    </>
  )

  return (
    <div className={`flex ${PRIVACY_MASK}`}>
      {canEdit ? (
        <div className="margin-right-5">
          <ButtonLink
            className="darker"
            title="Edit this address"
            onClick={() => showOrderAddressModal(orderNumber, addressType)}
          >
            {addressBlock}
          </ButtonLink>
        </div>
      ) : (
        <div className="margin-right-5">{addressBlock}</div>
      )}
      {notes && <CustomerNotePopOver notesFromCustomer={notes} />}
    </div>
  )
}

CompactAddress.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  notes: PropTypes.string,
  addressType: PropTypes.symbol.isRequired,
  address: AddressShape.isRequired,
  canEdit: PropTypes.bool.isRequired,
}
