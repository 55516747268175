import {useRef} from 'react'
import classNames from 'classnames'

import {skuListSelector} from '../productListFunctions.js'
import ProductSelectAllButton from '../ProductSelectAllButton.js'
import EmptyList from '../EmptyList.js'
import Row from './Row.js'
import {
  hasTrialIMMSelector,
  showImgsProductListSelector,
} from '../../../data/company.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../../data/warehouses.js'
import useMapChild from '../../../common/components/useMapChild.js'
import {useSelector} from '../../../store.js'
import {
  allowCartSyncModalSelector,
  showCartSyncModal,
} from '../../Modals/CartSyncModal/cartSyncModalFunctions.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {useSuppliersSelector} from '../../../data/suppliers.js'

export default function Table() {
  const skuList = useSelector(skuListSelector)
  const showImgsProductList = useSelector(showImgsProductListSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const allowCartSyncModal = useSelector(allowCartSyncModalSelector)
  const hasTrialIMM = useSelector(hasTrialIMMSelector)
  const useSuppliers = useSelector(useSuppliersSelector)

  const firstRowRef = useRef()
  const tableRef = useRef()

  const widths = [
    ...useMapChild({
      ref: firstRowRef,
      onChild: (el) => el.getBoundingClientRect().width,
    }),
  ]

  return (
    <div
      ref={tableRef}
      className={classNames('wrap--product-list-inner-scroll', {
        'margin-right-0': skuList.length === 0,
      })}
    >
      {skuList.length > 0 && (
        <div className="flex table table--zebra-list table--product-list table-inner-scroll--header">
          <div
            className="table__th table__th--sm v-align-middle no-border table__th--order-checkbox margin-auto-all"
            style={{width: widths.shift()}}
          >
            <ProductSelectAllButton />
          </div>
          <div
            className={`table__th table__th--sm table__th--product-list-name th-width-15 v-align-middle no-border margin-auto-all ${
              showImgsProductList ? 'img-enabled' : ''
            }`}
            style={{width: widths.shift()}}
          >
            Product
          </div>
          <div
            className="table__th table__th--sm th-width-10 v-align-middle no-border margin-auto-all"
            style={{width: widths.shift()}}
          >
            SKU
          </div>
          {useSuppliers && (
            <div
              className="table__th table__th--sm th-width-10 v-align-middle no-border margin-auto-all"
              style={{width: widths.shift()}}
            >
              Default Supplier
            </div>
          )}
          <div
            className="table__th table__th--sm th-width-8 v-align-middle no-border margin-auto-all"
            style={{width: widths.shift()}}
          >
            Weight
          </div>
          <div
            className="table__th table__th--sm th-width-8 v-align-middle no-border margin-auto-all"
            style={{width: widths.shift()}}
          >
            Pkg Dims
          </div>
          <div
            className="table__th table__th--sm th-width-15 v-align-middle no-border margin-auto-all"
            style={{width: widths.shift()}}
          >
            Sales Channels
          </div>
          <div
            className="table__th table__th--sm th-width-40 padding-left-0 padding-right-0 v-align-middle no-border margin-auto-all"
            style={{width: widths.shift()}}
          >
            Inventory Levels{' '}
            <span className="unbold">
              (
              {warehouses.length > 1
                ? `${warehouses.length} warehouses`
                : getWarehouseName(warehouses[0])}
              )
            </span>
            {allowCartSyncModal && hasTrialIMM && (
              <span className="alert alert--warning alert--sm inline-block margin-left-10">
                <span
                  className="i-exclamation-triangle fs-00 lh-sm v-align-middle text--warning"
                  aria-hidden="true"
                />{' '}
                <ButtonLink
                  className="mid v-align-base meta-Writeback-Disabled_Product-List"
                  onClick={() => showCartSyncModal()}
                >
                  <strong className="fs-n1 lh-md">Writeback is disabled</strong>
                </ButtonLink>
              </span>
            )}
          </div>
          <div
            className="table__th table__th--sm v-align-middle no-border padding-left-3 padding-right-3 th-width-3 margin-auto-all"
            style={{width: widths.shift()}}
          />
        </div>
      )}
      <table
        className={classNames(
          'table table--zebra-list table--product-list table--has-inventory',
          {
            'margin-top-20': skuList.length === 0,
          },
        )}
      >
        <tbody
          className={classNames('table__tbody--full-border', {
            'table__tbody--border-top-transparent': skuList.length > 0,
          })}
        >
          {skuList.map((sku, index) => (
            <Row ref={index === 0 ? firstRowRef : null} key={sku} sku={sku} />
          ))}
          {skuList.length === 0 && <EmptyList colSpan="9" />}
        </tbody>
      </table>
    </div>
  )
}
