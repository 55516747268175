import {useSelector} from '../../../../../store.js'
import {LabelInfoIDsShape} from '../../../../../common/PropTypes.js'
import {
  shippingMethodSelector,
  labelShipperIDSelector,
  boxShapeSelector,
  labelShipperTypeSelector,
} from '../../../../../data/labelInfos/index.js'
import BulkInsurance from '../../../Fields/Insurance/BulkInsurance.js'
import BulkMailInnovations from '../../../Fields/UPS/BulkMailInnovations.js'
import {
  setShowShippingConfiguration,
  applyShippingConfiguration,
} from '../../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../../bulkLabelConfigSelectors.js'
import UpdateOrdersButton from '../UpdateOrdersButton.js'
import BulkShipperSelect from './BulkShipperSelect.js'
import BulkPackageType from './BulkPackageType.js'
import BulkRates from './BulkRates.js'
import BulkPickupDate from './BulkPickupDate.js'
import {useLabelConfigContext} from '../../../LabelConfigContext.js'

export default function BulkShippingConfiguration({labelInfoIDs}) {
  const {labelInfoID} = useLabelConfigContext()
  const form = useSelector(bulkLabelConfigFormSelector)
  const shipperID = useSelector((state) =>
    labelShipperIDSelector(state, {labelInfoID}),
  )
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const boxShape = useSelector((state) =>
    boxShapeSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )
  const shippingMethod = useSelector((state) =>
    shippingMethodSelector(state, {
      labelInfoID,
    }),
  )
  const loading = form.loading__shippingConfiguration
  const disabled = !(shipperID && boxShape && shippingMethod) && !loading

  return (
    <dd className="list__item">
      <label htmlFor="bulk_shipping_config">
        <input
          type="checkbox"
          id="bulk_shipping_config"
          checked={form.showShippingConfiguration}
          onChange={() => {
            setShowShippingConfiguration(!form.showShippingConfiguration)
          }}
        />
        <span className="margin-left-5">Shipping Configuration</span>
      </label>
      {form.showShippingConfiguration && (
        <div className="wrap--nested-form">
          <div className="list--shipping-options">
            <BulkShipperSelect labelInfoIDs={labelInfoIDs} />
            <BulkPackageType />
            <BulkInsurance labelInfoIDs={labelInfoIDs} />
            <BulkMailInnovations />
            <BulkPickupDate />
            <BulkRates labelInfoIDs={labelInfoIDs} />
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              disabled={disabled}
              onClick={() => applyShippingConfiguration(labelInfoIDs)}
              loading={loading}
            />
          </div>
        </div>
      )}
    </dd>
  )
}

BulkShippingConfiguration.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
