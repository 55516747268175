import {useEffect, useState} from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import classNames from 'classnames'

import {
  PACK_MODE,
  PICK_MODE,
} from '../../../common/constants/PackingOrderModal.js'
import PackLine from './PackLine.js'
import Select from '../../../common/components/Select.js'
import {setupPackPanel, updatePackPanelForm} from './packPanelActions.js'
import {
  packingOrderLinesFlattenedForDisplaySelector,
  packPanelFormSelector,
} from './packPanelSelectors.js'
import {useSelector} from '../../../store.js'
import Checkbox from '../../../common/components/Checkbox.js'

export default function PackLines() {
  const [previousID, setPreviousID] = useState(null)
  const {sortListBy, includeKitParents, orderNumbers, mode} =
    useSelector(packPanelFormSelector) || {}
  const packingOrderLines = useSelector(
    packingOrderLinesFlattenedForDisplaySelector,
  )
  const id = orderNumbers.join('')

  useEffect(() => {
    setPreviousID(id)
  }, [id])

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="table__th table__th--top-border v-align-middle th-width-60">
            <div className="flex align-items-center">
              <div>
                <span className="lh-0 margin-right-10">Product Name/SKU</span>
              </div>
              <div
                className={classNames('flex', {
                  'alert alert--neutral alert--sm flex-1 align-items-center':
                    mode === PICK_MODE,
                })}
              >
                <Select
                  className="select--lt margin-bottom-0"
                  value={sortListBy}
                  onChange={(sortListBy) =>
                    updatePackPanelForm(
                      {sortListBy},
                      {stickyProps: ['sortListBy']},
                    )
                  }
                >
                  <option value="none">Sort: Default</option>
                  <option value="location">Sort: Location (A→Z)</option>
                  <option value="-location">Sort: Location (Z→A)</option>
                  <option value="sku">Sort: SKU (A→Z)</option>
                  <option value="-sku">Sort: SKU (Z→A)</option>
                  <option value="name">Sort: Name (A→Z)</option>
                  <option value="-name">Sort: Name (Z→A)</option>
                  <option value="upc">Sort: UPC (A→Z)</option>
                  <option value="-upc">Sort: UPC (Z→A)</option>
                  <option value="-orderedQuantity">Sort: Qty (Highest)</option>
                  <option value="orderedQuantity">Sort: Qty (Lowest)</option>
                </Select>
                {mode === PICK_MODE && (
                  <div className="margin-left-10">
                    <Checkbox
                      label="Include Kit Parents"
                      className="v-align-middle margin-bottom-0"
                      labelClassName="margin-bottom-0 flex align-items-center"
                      id="include_kit_parents"
                      checked={includeKitParents}
                      onChange={(checked) => {
                        updatePackPanelForm(
                          {includeKitParents: checked, isLoading: true},
                          {stickyProps: ['includeKitParents']},
                        )

                        setupPackPanel(orderNumbers)
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </th>
          <th className="table__th table__th--top-border th-width-20 v-align-middle align-center">
            Qty Ordered
          </th>
          <th className="table__th table__th--top-border border-left--light th-width-20 v-align-middle align-center">
            Qty {mode === PACK_MODE ? 'Verified' : 'Picked'}
          </th>
        </tr>
      </thead>
      <TransitionGroup
        key={id}
        component="tbody"
        className="table__tbody--lines table__tbody--packing-modal table__tbody--v-align-middle"
      >
        {packingOrderLines
          .filter((line) => line.verifiedQuantity < line.orderedQuantity)
          .map((line) => (
            <CSSTransition
              key={`${id}${line.path}`}
              classNames="success-fade"
              timeout={{exit: 1000}}
              unmountOnExit
              exit={previousID === id}
            >
              {(status) => <PackLine line={line} status={status} />}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </table>
  )
}
