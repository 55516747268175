import {createSelector} from 'reselect'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

import {EXPORT_USPS_TRANSACTIONS_MODAL} from '../../../../actions/ui/settings/shippers/index.js'
import {formsSelector} from '../../forms.js'

export const exportUSPSTransactionsModalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[EXPORT_USPS_TRANSACTIONS_MODAL],
)

export const exportUSPSTransactionsModalErrorsSelector = createSelector(
  exportUSPSTransactionsModalFormSelector,
  ({startDate, endDate}) => {
    const errors = {}

    if (startDate && endDate && startDate > endDate) {
      errors.startDate = 'Date must be before end date'
    }

    return errors
  },
)

export function exportUSPSTransactionsTaskParamsSelector(state) {
  const {startDate, endDate} = exportUSPSTransactionsModalFormSelector(state)

  const task = {
    type: 'export_postage_account',
    params: {
      ...(startDate
        ? {
            start_date: startOfDay(startDate).toISOString(),
          }
        : {}),
      ...(endDate
        ? {
            end_date: endOfDay(endDate).toISOString(),
          }
        : {}),
    },
  }

  return task
}
