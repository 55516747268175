import PropTypes from 'prop-types'

import {HotKeyConnect} from '../HotKeys.js'

export default function ButtonAction({children, className, hotKey, ...props}) {
  const button = (
    <button
      className={`btn btn--action v-align-base ${className || ''}`}
      {...props}
    >
      {children}
    </button>
  )

  if (hotKey) {
    return (
      <HotKeyConnect code={hotKey} func={props.onClick}>
        {button}
      </HotKeyConnect>
    )
  }

  return button
}

ButtonAction.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hotKey: PropTypes.string,
  onClick: PropTypes.func,
}
