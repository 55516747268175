import AmazonFulfillmentModal from './AmazonFulfillmentModal.js'
import ArchiveProductModal from './ArchiveProductModal.js'
import BarcodeLabelModal from './BarcodeLabelModal.js'
import CreateInventoryTransferModal from './CreateInventoryTransferModal/index.js'
import CreateMOModal from '../../MOListPage/Modals/CreateMOModal.js'
import CreatePOModal from './CreatePOModal/index.js'
import CreateSupplierModal from './CreateSupplierModal.js'
import EditProductCartModal from './EditProductCartModal.js'
import EditProductSupplierModal from './EditProductSupplierModal.js'
import EditProductWarehouseModal from './EditProductWarehouseModal.js'
import FulfillmentLatencyModal from './FulfillmentLatencyModal.js'
import MarkNeedsSyncModal from './MarkNeedsSyncModal.js'
import ProductComponentModal from './ProductComponentModal.js'
import ProductCostModal from './ProductCostModal.js'
import ProductDimensionsModal from './ProductDimensionsModal.js'
import ProductPriceModal from './ProductPriceModal.js'
import ProductSupplierModal from './ProductSupplierModal.js'
import ProductTextFieldModal from './ProductTextFieldModal.js'
import ProductWeightModal from './ProductWeightModal.js'
import RemoveComponentModal from './RemoveComponentModal.js'
import RemoveProductSupplierModal from './RemoveProductSupplierModal.js'
import SetupProductComponentModal from './SetupProductComponentModal.js'
import UpdateDefaultSupplierModal from './UpdateDefaultSupplierModal.js'

export default function Modals() {
  return (
    <>
      <AmazonFulfillmentModal />
      <ArchiveProductModal />
      <BarcodeLabelModal />
      <CreateInventoryTransferModal />
      <CreateMOModal />
      <CreatePOModal />
      <CreateSupplierModal />
      <EditProductCartModal />
      <EditProductSupplierModal />
      <EditProductWarehouseModal />
      <FulfillmentLatencyModal />
      <MarkNeedsSyncModal />
      <ProductComponentModal />
      <ProductCostModal />
      <ProductDimensionsModal />
      <ProductPriceModal />
      <ProductSupplierModal />
      <ProductTextFieldModal />
      <ProductWeightModal />
      <RemoveComponentModal />
      <RemoveProductSupplierModal />
      <SetupProductComponentModal />
      <UpdateDefaultSupplierModal />
    </>
  )
}
