import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {defaultPackingListIDFromOrderNumberSelector} from '../../../../../../../data/orders.js'
import {packingListNameSelector} from '../../../../../../../redux/selectors/data/packingLists.js'
import {labelConfigSelector} from '../../../../../../../data/labelInfos/index.js'

import LabelItem from './LabelItem.js'
import {useSelector} from '../../../../../../../store.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'

export default function PreconfiguredPackingList({orderNumber}) {
  const {labelInfoID} = useLabelConfigContext() || {}

  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const shippingPackingListID = useSelector((state) =>
    defaultPackingListIDFromOrderNumberSelector(state, {
      orderNumber,
    }),
  )

  const packingListID = labelInfoID
    ? get(labelConfig, 'packing_list_id')
    : shippingPackingListID

  const packingListName = useSelector((state) =>
    packingListNameSelector(state, {packingListID}),
  )

  if (!packingListName) {
    return null
  }

  return <LabelItem label="Profile">{packingListName}</LabelItem>
}

PreconfiguredPackingList.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
