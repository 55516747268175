import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'

import {stringifyURL, buildPath} from '../../common/querystring.js'
import {isPresent} from '../../common/utils.js'
import {ensureTroolean, nullParamCast} from '../../common/ensure.js'
import {
  ORDER_SINGLE_URI_COMPONENT,
  BATCH_SORT,
} from '../../common/constants/Orders.js'
import {
  BATCH_PLURAL_URI_COMPONENT,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
} from '../../common/constants/Batches.js'
import {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {formsSelector} from '../../store.js'
import {canUseBatchesSelector} from '../../data/batches.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'

export const BATCH_LIST_FORM = 'BATCH_LIST_FORM'

export function batchListFormSelector(state) {
  return formsSelector(state)[BATCH_LIST_FORM]
}

export const referenceIDListSelector = createSelector(
  batchListFormSelector,
  ({referenceIDList} = {}) => referenceIDList || [],
)

export function batchListQuerySelector(state) {
  batchListQuerySelector.cache = batchListQuerySelector.cache || {}
  const cache = batchListQuerySelector.cache

  const location = locationSelector(state)

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  const result = {
    searchText: location.query.searchText || '',
    active: ensureTroolean(location.query.active),
    sort: location.query.sort || DEFAULT_SORT,
    limit: Number(location.query.limit) || DEFAULT_PER_PAGE,
    offset: Number(location.query.offset) || 0,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export const isLoadingSelector = createSelector(
  batchListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const countSelector = createSelector(
  batchListFormSelector,
  ({count} = {}) => count || 0,
)

export const expandModeSelector = createSelector(
  batchListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedReferenceIDsSelector = createSelector(
  batchListFormSelector,
  ({expandedReferenceIDs} = {}) => expandedReferenceIDs || [],
)

export const selectedReferenceIDsSelector = createSelector(
  batchListFormSelector,
  ({selectedReferenceIDs} = {}) => selectedReferenceIDs || [],
)

export const allSelectedSelector = createSelector(
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  (referenceIDList, selected) =>
    referenceIDList.length > 0 && referenceIDList.length === selected.length,
)

export const indeterminateSelectedSelector = createSelector(
  allSelectedSelector,
  selectedReferenceIDsSelector,
  (allSelected, selectedReferenceIDs) =>
    !allSelected && selectedReferenceIDs.length > 0,
)

export const firstSelectedReferenceIDSelector = createSelector(
  selectedReferenceIDsSelector,
  (selectedReferenceIDs) => selectedReferenceIDs[0],
)

function hashBuilder(params, canUseBatches) {
  if (!canUseBatches) {
    return null
  }

  let hash = `#/${BATCH_PLURAL_URI_COMPONENT}`

  return stringifyURL(hash, params)
}

export const batchListHashSelector = createSelector(
  batchListQuerySelector,
  (state) => canUseBatchesSelector(state),
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  batchListFormSelector,
  ({sticky__limit, sticky__sort} = {}) => ({
    limit: sticky__limit !== DEFAULT_PER_PAGE ? sticky__limit : undefined,
    sort: sticky__sort !== DEFAULT_SORT ? sticky__sort : undefined,
    active: true,
  }),
)

export const defaultBatchListHashSelector = createSelector(
  defaultHashParamsSelector,
  (state) => canUseBatchesSelector(state),
  hashBuilder,
)

export function queryParamsSelector(state) {
  const {active, searchText, sort, limit, offset} =
    batchListQuerySelector(state)

  return {
    active: nullParamCast(active),
    search: searchText.trim() || undefined,
    sort,
    limit,
    offset,
  }
}

export const createIsSelectedSelector = (referenceID) =>
  createSelector(selectedReferenceIDsSelector, (selectedReferenceIDs) => {
    return selectedReferenceIDs.includes(referenceID)
  })

export const createIsExpandedSelector = (referenceID) =>
  createSelector(
    expandedReferenceIDsSelector,
    expandModeSelector,
    (expandedReferenceIDs, expandMode) => {
      if (expandMode === EXPAND_MODE_COLLAPSED) {
        return expandedReferenceIDs.includes(referenceID)
      }
      if (expandMode === EXPAND_MODE_EXPANDED) {
        return !expandedReferenceIDs.includes(referenceID)
      }
      return false
    },
  )

export function getBatchDetailURL(referenceID, params = {}) {
  return [
    [ORDER_SINGLE_URI_COMPONENT],
    {batch_reference_id: [referenceID], sort: BATCH_SORT, ...params},
  ]
}

export function buildBatchDetailURL(referenceID, params = {}) {
  return '#' + buildPath(...getBatchDetailURL(referenceID, params))
}

export const createDetailURLSelector = (referenceID) => () =>
  buildBatchDetailURL(referenceID)

export const isFilteredSelector = createSelector(
  batchListQuerySelector,
  ({searchText}) => isPresent(searchText),
)
