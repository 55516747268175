import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'

import {SelectOptionValue} from '../../PropTypes.js'
import BaseModal, {Footer, Content, ButtonSecondary} from './BaseModal/index.js'

import {closeModal} from '../../../redux/actions/ui/index.js'

import {
  MODAL_NAME,
  PRINT_PICK_PACK_MODAL_FORM,
  viewPickPackDocument,
} from '../../../redux/actions/ui/modals/printPickPackModal.js'

import AbodeForm from '../../../ordoro/iverson/components/LabelViewForm/AbodeForm.js'
import {packingListPrinterIDSelector} from '../../../data/printerSettings.js'
import {
  abodePickPackLinkSelector,
  atLeastOneSplitOrderSelector,
  pickOrPackIsSelectedSelector,
} from '../../../redux/selectors/ui/labelViewForm.js'

import ButtonPrimary from '../Button/ButtonPrimary.js'
import ButtonSendToPrinter from '../Button/ButtonSendToPrinter.js'
import ErrorMessage from '../ErrorMessage.js'

class PrintPickPackModal extends Component {
  static propTypes = {
    docTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    abodePickPackLink: PropTypes.string.isRequired,
    viewPickPackDocument: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    orderNumbers: PropTypes.array.isRequired,
    atLeastOneSplitOrder: PropTypes.bool.isRequired,
    pickOrPackIsSelected: PropTypes.bool.isRequired,
    packingListPrinterID: SelectOptionValue.isRequired,
  }

  static modalName = MODAL_NAME

  render() {
    const isDisabled =
      !this.props.abodePickPackLink || !this.props.pickOrPackIsSelected

    return (
      <BaseModal
        modalName={MODAL_NAME}
        title="Print Pick/Pack Lists"
        className="modal--md"
      >
        <Content>
          <AbodeForm
            formName={PRINT_PICK_PACK_MODAL_FORM}
            docTypes={this.props.docTypes}
            fromModal
            includeSplitQuantitiesCheckbox={this.props.atLeastOneSplitOrder}
            allowPrintConfigs
          />
          {this.props.errorMessage && (
            <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
          )}
          {!this.props.pickOrPackIsSelected && (
            <ErrorMessage>
              Choose which type of list you would like to print from the
              Document Options
            </ErrorMessage>
          )}
        </Content>
        <Footer>
          <div className="flex">
            <div className="margin-right-10">
              <ButtonPrimary
                onClick={() =>
                  this.props.viewPickPackDocument(this.props.abodePickPackLink)
                }
                isDisabled={isDisabled}
              >
                View/Print
              </ButtonPrimary>
            </div>
            <div className="margin-right-10">
              <ButtonSendToPrinter
                className="margin-bottom-5"
                title="Pick/Pack List"
                pdfLink={this.props.abodePickPackLink}
                printerID={this.props.packingListPrinterID}
                documentCount={this.props.orderNumbers.length}
                onClick={({canPrint}) => !canPrint && this.props.closeModal()}
                isDisabled={isDisabled}
              />
            </div>
            <div>
              <ButtonSecondary
                title="Close"
                onClick={() => this.props.closeModal()}
              />
            </div>
          </div>
        </Footer>
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  const utcOffset = moment().utcOffset()
  const docTypes = ['pick', 'pack']

  return {
    docTypes,
    errorMessage: state.ui.modals.printPickPackModal.errorMessage,
    orderNumbers: state.ui.modals.printPickPackModal.orderNumbers,
    abodePickPackLink: abodePickPackLinkSelector(state, {
      formName: PRINT_PICK_PACK_MODAL_FORM,
      orderNumbers: state.ui.modals.printPickPackModal.orderNumbers,
      utcOffset,
      docTypes,
    }),
    atLeastOneSplitOrder: atLeastOneSplitOrderSelector(state, {
      orderNumbers: state.ui.modals.printPickPackModal.orderNumbers,
    }),
    pickOrPackIsSelected: pickOrPackIsSelectedSelector(state, {
      formName: PRINT_PICK_PACK_MODAL_FORM,
    }),
    packingListPrinterID: packingListPrinterIDSelector(state),
  }
}

const mapDispatchToProps = {
  viewPickPackDocument,
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintPickPackModal)
