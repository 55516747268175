import {combineReducers} from 'redux'

import * as actions from '../../../actions/ui/dataImport/data.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'
import actionMatchesBase from '../../../utils/actionMatchesBase.js'

function index(state = 0) {
  return state
}

function isSaved(state = false, action = {}) {
  if (action.type === actions.SET_IS_SAVED) {
    return action.payload
  }

  return state
}

function isDeleted(state = false, action = {}) {
  if (action.type === actions.SET_IS_DELETED) {
    return action.payload
  }

  return state
}

function errors(state = {}, action = {}) {
  if (action.type === actions.SET_ERRORS) {
    return action.payload
  }

  return state
}

function payload(state = {}, action = {}) {
  if (action.type === actions.SET_PAYLOAD) {
    return action.payload
  }

  return state
}

const data = combineReducers({
  index,
  isSaved,
  isDeleted,
  errors,
  payload,
})

export default function reducer(
  state = INITIAL_STATE.ui.dataImport.data,
  action = {},
) {
  if (action.type === actions.SET_DATA) {
    return action.payload
  }

  if (actionMatchesBase(action, actions.BASE)) {
    const idx = action.index

    return [
      ...state.slice(0, idx),
      data(state[idx], action),
      ...state.slice(idx + 1),
    ]
  }

  return state
}
