import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {MODAL_NAME} from '../../../actions/ui/modals/confirmArchiveProductModal.js'

export default function reducer(
  state = INITIAL_STATE.ui.modals.confirmArchiveProductModal,
  action = {},
) {
  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    const {skus, willArchive} = action.payload.meta

    return {
      skus,
      willArchive,
    }
  }

  return state
}
