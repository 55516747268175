import {useSelector} from 'react-redux'

import ButtonRefresh from '../../../../common/components/Button/ButtonRefresh.js'
import {refreshOrderListAndCounts} from '../../../OrderListPage/orderListActions.js'
import {newOrderCountSelector} from '../../../../data/orderCounts.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'
import {isLoadingSelector} from '../../../OrderListPage/orderListSelectors.js'

export default function NewOrdersAlert() {
  const count = useSelector(newOrderCountSelector)
  const isLoading = useSelector(isLoadingSelector)

  if (count === 0) {
    return null
  }

  return (
    <PluralBlock count={count}>
      <div className="alert alert--standard align-center margin-top-10">
        <p className="fs-01 margin-bottom-0 v-align-middle">
          <strong className="inline-block v-align-middle margin-right-10">
            <Count /> new <Plural s="order is" p="orders are" /> ready to be
            added
          </strong>
          <ButtonRefresh
            title="Refresh list"
            loading={isLoading}
            onClick={() => refreshOrderListAndCounts()}
          />
        </p>
      </div>
    </PluralBlock>
  )
}
