import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import {PRIVACY_MASK} from '../../analytics/index.js'

export default class TextInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    noColumns: PropTypes.bool,
    fsExclude: PropTypes.bool,
    helperIcon: PropTypes.bool,
    helperLink: PropTypes.string,
  }

  onChange = (event) => {
    this.props.onChange(event.target.value, this.props.name)
  }

  render() {
    const name = this.props.name || this.props.id
    const id = this.props.id || `id_${name}`
    const errorMessage = this.props.errorMessage
    const allClassName = classNames(`container_${name}`, this.props.className, {
      error: !!errorMessage,
      columns: !this.props.noColumns,
    })

    return (
      <div className={allClassName}>
        <div className="flex">
          <label htmlFor={id}>
            <span>{this.props.label}</span>
            {this.props.required ? <span className="required">*</span> : null}
          </label>
          {this.props.helperIcon ? (
            <a
              className="btn btn--link no-underline lighter margin-left-3"
              href={this.props.helperLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="i-help fs-01 lh-sm i--lh-sm-md" />
            </a>
          ) : null}
        </div>
        <input
          type={this.props.type || 'text'}
          className={this.props.fsExclude ? PRIVACY_MASK : ''}
          name={name}
          id={id}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onBlur={() => this.props.onBlur && this.props.onBlur()}
          value={this.props.value}
          disabled={this.props.disabled}
        />
        {errorMessage ? (
          <small className="error error-message">{errorMessage}</small>
        ) : null}
      </div>
    )
  }
}
