import {
  warehouseOptionsSelector,
  warehouseOptionTokensSelector,
} from '../../../../data/warehouses.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  modalFormSelector,
  updateModalForm,
  WAREHOUSE_FILTER_PANEL,
} from './poListFilterModalFunctions.js'

export default function WarehouseFilterPanel() {
  const form = useSelector(modalFormSelector)
  const warehouseOptions = useSelector(warehouseOptionsSelector)
  const warehouseOptionTokens = useSelector(warehouseOptionTokensSelector)

  return (
    <FilterPanel
      id={WAREHOUSE_FILTER_PANEL}
      label="Warehouses"
      searchPlaceholder="Search for a warehouse filter..."
      options={warehouseOptions}
      optionTokens={warehouseOptionTokens}
      selected={form.warehouse_id}
      onSelect={(option) => {
        const warehouse_id = form.warehouse_id.filter(
          (id) => id !== option.value,
        )

        if (warehouse_id.length === form.warehouse_id.length) {
          warehouse_id.push(option.value)
        }

        updateModalForm({warehouse_id})
      }}
    />
  )
}
