import {useSelector} from '../../../store.js'
import {
  canHaveMultiboxSelector,
  packageCountSelector,
} from '../../../data/labelInfos/index.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showMultiboxModal} from '../../OrderListPage/Modals/MultiboxModal/index.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function MultiboxButton() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const packageCount = useSelector((state) =>
    packageCountSelector(state, {labelInfoID}),
  )
  const canHaveMultibox = useSelector((state) =>
    canHaveMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  if (!canHaveMultibox) {
    return null
  }

  return (
    <li className="list__item--shipping-options">
      <ButtonPrimary
        isOutlined
        size="sm"
        onClick={() => showMultiboxModal(labelInfoID, shipperType)}
      >
        {packageCount === 1 ? 'Add multiple packages' : 'Manage Multibox'}
      </ButtonPrimary>
    </li>
  )
}
