import {takeLatest, call, select, put} from 'redux-saga/effects'

import verde from '../../../common/verde.js'
import loadScript from '../../../common/loadScript.js'
import {
  VALID_USER,
  INVALID_USER,
  VALIDATING_USER,
  USER_VALIDITY_UNKNOWN,
} from '../../../common/constants/User.js'
import {setGlobalError} from '../../actions/ui/index.js'
import {VERIFY_USER, setUserValidity} from '../../actions/ui/userValidity.js'
import {prevalidatedCompanySelector} from '../../../data/company.js'
import {userValiditySelector} from '../../selectors/ui/userValidity.js'

export function onReCaptchaReady() {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready((err) => {
      if (err) {
        reject(err)
      } else {
        resolve()
      }
    })
  })
}

export function getPublicKey() {
  return window.ORDORO_BOOTSTRAP.env.RECAPTCHA_PUBLIC_KEY
}

export function getToken(recaptchaPublicKey, action = 'pappy') {
  return window.grecaptcha.execute(recaptchaPublicKey, {action})
}

export async function loadRecaptcha(action) {
  const recaptchaPublicKey = getPublicKey()

  await loadScript(
    `https://www.google.com/recaptcha/api.js?render=${recaptchaPublicKey}`,
  )

  await onReCaptchaReady()

  const token = await getToken(recaptchaPublicKey, action)

  return token
}

export function* verifyUserWorker() {
  const userValidity = yield select(userValiditySelector)

  if (userValidity === VALID_USER) {
    return
  }

  const prevalidatedCompany = yield select(prevalidatedCompanySelector)

  if (prevalidatedCompany) {
    yield put(setUserValidity(VALID_USER))

    return
  }

  try {
    yield put(setUserValidity(VALIDATING_USER))

    const token = yield call(loadRecaptcha)

    const {json} = yield call(verde.post, '/recaptcha/verify', {token})

    yield put(
      setUserValidity(
        json.success && json.score > 0.5 ? VALID_USER : INVALID_USER,
      ),
    )
  } catch (err) {
    yield put(
      setGlobalError(
        `Error valdating user. ${err.message || err.error_message}`,
        err,
      ),
    )

    yield put(setUserValidity(USER_VALIDITY_UNKNOWN))
  }
}

export default function* verifyUserSaga() {
  yield takeLatest(VERIFY_USER, verifyUserWorker)
}
