import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

export default class ReconcileSelect extends Component {
  constructor(props) {
    super(props)

    this.onChange = (event) =>
      this.props.onChange(event.target.value, this.props.name)
  }

  renderOptions() {
    const selectOptions = this.props.columns.map((column, index) => (
      <option key={index} value={index}>
        {column}
      </option>
    ))

    selectOptions.unshift(<option key={-1} value={-1} />)

    return selectOptions
  }

  render() {
    const name = this.props.name
    const errorMessage = this.props.errorMessage
    const disabled = !errorMessage && this.props.value === -1
    const allClassName = classNames('columns', this.props.className, {
      error: !!errorMessage,
    })
    const arrowClassName = classNames(
      'make-inline-block make-medium-grey margin-right-5 margin-left-5',
      {
        'op-50': disabled,
      },
    )
    const labelClassName = classNames('make-inline-block', {
      'op-50': disabled,
    })

    return (
      <div className={allClassName}>
        <select
          className="make-inline-block select bigger-select"
          name={name}
          id={`id_${name}`}
          value={this.props.value}
          onChange={this.onChange}
        >
          {this.renderOptions()}
        </select>
        <span className={arrowClassName}>&#10142;</span>
        <label className={labelClassName} htmlFor={`id_${name}`}>
          {this.props.label}
        </label>
        {errorMessage ? (
          <small className="error error-message">{errorMessage}</small>
        ) : null}
      </div>
    )
  }
}

ReconcileSelect.propTypes = {
  columns: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
