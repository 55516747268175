import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import api from '../../../common/api.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import {lbToOz, ozToLb} from '../../../common/weight.js'
import {PRODUCT_LIST_PAGE} from '../../../common/constants/Pages.js'
import WeightInput from '../../../common/components/Form/WeightInput.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  setProduct,
  productSelector,
  hasNoProductsSelector,
} from '../../../data/products.js'
import {refreshProductList} from '../productListFunctions.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {addCreateOrderFormProductInfoLine} from '../../CreateOrderPage/createOrderActions.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {navigate} from '../../../common/location.js'
import {PRODUCT_SINGLE_URI_COMPONENT} from '../../../common/constants/Products.js'
import Checkbox from '../../../common/components/Checkbox.js'

export const MODAL_FORM = 'CREATE_PRODUCT_MODAL'

export async function showCreateProductModal({addToCreateOrder} = {}) {
  addToCreateOrder = addToCreateOrder || false

  setForm(MODAL_FORM, {
    sku: '',
    hasChanged_sku: false,
    name: '',
    hasChanged_name: false,
    upc: '',
    weight: 0,
    length: '',
    width: '',
    height: '',
    has_serial_numbers: false,
    skuAlreadyExists: false,
    addToCreateOrder,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function errorsSelector(state) {
  const {skuAlreadyExists, sku, name, weight, length, width, height} =
    modalFormSelector(state)
  const errors = {}

  if (!isPresent(sku)) {
    errors.sku = 'SKU is required'
    errors.preventSave = true
  }
  if (skuAlreadyExists) {
    errors.sku = 'A product with this SKU already exists'
    errors.preventSave = true
  }

  if (!isPresent(name)) {
    errors.name = 'Name is required'
    errors.preventSave = true
  }

  if (!isPositiveNumeric(weight)) {
    errors.weight = 'Weight must be a positive number'
    errors.preventSave = true
  }

  if (isPresent(length) && !isPositiveNumeric(length)) {
    errors.length = 'Length must be a positive number'
    errors.preventSave = true
  }

  if (isPresent(width) && !isPositiveNumeric(width)) {
    errors.width = 'Width must be a positive number'
    errors.preventSave = true
  }

  if (isPresent(height) && !isPositiveNumeric(height)) {
    errors.height = 'Height must be a positive number'
    errors.preventSave = true
  }

  return errors
}

export async function setSKU(sku) {
  const requestToken = {}
  setSKU.requestToken = requestToken

  try {
    const product = productSelector(getState(), {sku})
    if (product) {
      updateModalForm({sku, hasChanged_sku: true, skuAlreadyExists: true})
      return
    }

    updateModalForm({sku, hasChanged_sku: true, skuAlreadyExists: false})

    const {
      json: {count},
    } = await api.get('/product', {sku})

    if (setSKU.requestToken === requestToken && count > 0) {
      updateModalForm({skuAlreadyExists: true})
    }
  } catch (err) {
    // don't need to do anything here
  }
}

export async function createProduct({goToProductDetails} = {}) {
  try {
    updateModalForm({serverError: null, isSaving: true})

    const {
      sku,
      name,
      upc,
      weight,
      length,
      width,
      height,
      has_serial_numbers,
      addToCreateOrder,
    } = modalFormSelector(getState())

    const {json} = await api.post('/product', {
      sku,
      name,
      upc,
      weight,
      length,
      width,
      height,
      has_serial_numbers,
    })

    setProduct(json)

    closeModal()

    showMessageToast('New product created')

    if (goToProductDetails) {
      navigate([PRODUCT_SINGLE_URI_COMPONENT, json.sku])
    } else if (currentPageSelector(getState()) === PRODUCT_LIST_PAGE) {
      await refreshProductList()
    }

    if (addToCreateOrder) {
      addCreateOrderFormProductInfoLine(sku)
    }
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isSaving: false,
    })
  }
}

function CreateProductModal({form}) {
  const errors = useSelector(errorsSelector)
  const hasNoProducts = useSelector(hasNoProductsSelector)

  const hasAllDimensions = !errors.length && !errors.width && !errors.height
  const dimensionsError = errors.length || errors.width || errors.height

  return (
    <ConfirmModal
      title="Create a Product"
      modalSize="sm-md"
      className="meta-createproductmodal"
      confirmText={
        form.addToCreateOrder ? 'Create and Add to Order' : 'Create Product'
      }
      onConfirm={() => createProduct()}
      MiddleButtons={
        form.addToCreateOrder
          ? null
          : () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => createProduct({goToProductDetails: true})}
              >
                Create and View Product
              </ButtonPrimary>
            )
      }
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      {form.addToCreateOrder && hasNoProducts && (
        <div className="alert alert--warning-lt alert--lg align-center margin-bottom-20">
          <div
            className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-5"
            aria-hidden="true"
          />
          <p className="fs-00 margin-bottom-0 lh-md">
            <strong>
              You must have at least one product in our system before you can
              create a manual order.
            </strong>
          </p>
        </div>
      )}
      <div className="row">
        <div className="medium-9 columns">
          <ul className="list list--form">
            <li className="list__item--form">
              <label htmlFor="id_sku">
                SKU<span className="required">*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id="id_sku"
                value={form.sku}
                onChange={(event) => setSKU(event.target.value)}
              />
              {errors.sku && form.hasChanged_sku && (
                <small className="error error-message">{errors.sku}</small>
              )}
            </li>
            <li className="list__item--form">
              <label htmlFor="id_name">
                Name<span className="required">*</span>
              </label>
              <input
                className="input--text"
                type="text"
                id="id_name"
                value={form.name}
                onChange={(event) =>
                  updateModalForm({
                    name: event.target.value,
                    hasChanged_name: true,
                  })
                }
              />
              {errors.name && form.hasChanged_name && (
                <small className="error error-message">{errors.name}</small>
              )}
            </li>
            <li className="list__item--form">
              <label htmlFor="id_name">Barcode / UPC</label>
              <input
                className="input--text"
                type="text"
                id="id_upc"
                value={form.upc}
                onChange={(event) => updateModalForm({upc: event.target.value})}
              />
            </li>
            <li className="list__item--form modal-weight-input margin-bottom-10">
              <label htmlFor="id_weight-lb">Weight</label>
              <WeightInput
                idLb="id_weight-lb"
                idOz="id_weight-oz"
                weightOz={lbToOz(form.weight)}
                setWeightOz={(weightOz) =>
                  updateModalForm({weight: ozToLb(weightOz)})
                }
                allowZero
              />
              {errors.weight && (
                <small className="error error-message">{errors.weight}</small>
              )}
            </li>
            <li className="list__item--form modal-dimensions-input">
              <label htmlFor="id_dimensions">Package Dimensions</label>
              <div className="flex flex-wrap">
                <div className="flex margin-bottom-5">
                  <NumberInput
                    id="length"
                    value={form.length}
                    isInvalid={!!errors.length}
                    onChange={(value) => updateModalForm({length: value})}
                    onIncrement={(value) =>
                      updateModalForm({length: `${value}`})
                    }
                    onDecrement={(value) =>
                      updateModalForm({length: `${value}`})
                    }
                    min={0}
                  />
                  <span className="input__unit inline-block">x</span>
                </div>
                <div className="flex margin-bottom-5">
                  <NumberInput
                    id="width"
                    value={form.width}
                    isInvalid={!!errors.width}
                    onChange={(value) => updateModalForm({width: value})}
                    onIncrement={(value) =>
                      updateModalForm({width: `${value}`})
                    }
                    onDecrement={(value) =>
                      updateModalForm({width: `${value}`})
                    }
                    min={0}
                  />
                  <span className="input__unit inline-block">x</span>
                </div>
                <div className="flex margin-bottom-5">
                  <NumberInput
                    id="height"
                    value={form.height}
                    isInvalid={!!errors.height}
                    onChange={(value) => updateModalForm({height: value})}
                    onIncrement={(value) =>
                      updateModalForm({height: `${value}`})
                    }
                    onDecrement={(value) =>
                      updateModalForm({height: `${value}`})
                    }
                    min={0}
                  />
                  <span className="input__unit inline-block">in</span>
                </div>
              </div>
              {!hasAllDimensions && dimensionsError && (
                <small className="error error-message">{dimensionsError}</small>
              )}
            </li>
            <li className="list__item--form">
              <Checkbox
                id="has_serial_numbers"
                label="Has Serial Numbers"
                checked={form.has_serial_numbers}
                onChange={(checked) =>
                  updateModalForm({has_serial_numbers: checked})
                }
              />
            </li>
          </ul>
        </div>
      </div>
    </ConfirmModal>
  )
}

CreateProductModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    hasChanged_sku: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    hasChanged_name: PropTypes.bool.isRequired,
    upc: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    length: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    has_serial_numbers: PropTypes.bool.isRequired,
    addToCreateOrder: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(CreateProductModal, modalFormSelector)
