import AddressFilter from '../../../../common/components/AddressFilter.js'
import ButtonLink from '../../../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {showPasteAddressModal} from '../../../../common/components/Modal/PasteAddressModal.js'
import Select from '../../../../common/components/Select.js'
import TextInput from '../../../../common/components/TextInput.js'
import {QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS} from '../../../../common/constants/AddressTypes.js'
import {COMMUNICATION_DROPSHIPMENT} from '../../../../common/constants/Suppliers.js'
import {navigate} from '../../../../common/location.js'
import {
  activeNonFBASupplierOptionsSelector,
  supplierAddressSelector,
} from '../../../../data/suppliers.js'
import {
  nonFBAWarehouseOptionsSelector,
  warehouseAddressSelector,
} from '../../../../data/warehouses.js'
import {setFormValue, useForm, useSelector} from '../../../../store.js'
import {
  MODAL_FORM,
  QS_ALTERNATE_SHIP_FROM_ADDRESS,
  updateQuickShipForm,
  closeModal,
  QS_SETUP_WAREHOUSE,
} from './quickShipModalFunctions.js'

export default function ShipFromPanel() {
  const form = useForm(MODAL_FORM)
  const warehouseOptions = useSelector(nonFBAWarehouseOptionsSelector)
  const supplierOptions = useSelector(activeNonFBASupplierOptionsSelector)
  const shipFromAddress = useSelector((state) =>
    form.shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS
      ? null
      : form.shipFromType === 'supplier_id'
        ? supplierAddressSelector(state, {
            supplierID: form.supplierID,
            type: COMMUNICATION_DROPSHIPMENT,
          })
        : warehouseAddressSelector(state, {warehouseID: form.warehouseID}),
  )
  const shipFromOptions =
    form.shipFromType === QS_SETUP_WAREHOUSE
      ? []
      : [
          {value: QS_ALTERNATE_SHIP_FROM_ADDRESS, display: 'Other address'},
          ...(warehouseOptions.length > 0
            ? [{disabled: true, display: '--- Warehouses ---'}]
            : []),
          ...warehouseOptions.map(({value, display}) => ({
            value: `warehouse_id__${value}`,
            display,
          })),
          ...(supplierOptions.length > 0
            ? [{disabled: true, display: '--- Suppliers ---'}]
            : []),
          ...supplierOptions.map(({value, display}) => ({
            value: `supplier_id__${value}`,
            display,
          })),
        ]

  return (
    <>
      <p className="fs-02 lh-md margin-bottom-10">
        <strong>Where are you shipping from?</strong>
      </p>
      {form.shipFromType === QS_SETUP_WAREHOUSE ? (
        <>
          <p className="fs-01 lh-md margin-bottom-15">
            Please provide a real, physical address so we can correctly
            calculate shipping costs for your Quick Ship label.
          </p>
          <p className="fs-01 lh-md margin-bottom-25">
            The address you enter will also become your default Warehouse
            address (which you can change later in Settings).
          </p>
        </>
      ) : (
        <p className="fs-01 lh-md margin-bottom-25">
          Select an existing Ship From address or choose “Other address” to
          enter a new one.
        </p>
      )}

      <ul className="list list--no-style margin-top-10">
        <li className="list__item fs-00 margin-bottom-5">
          <strong></strong>
        </li>
        {shipFromOptions.length > 0 && (
          <li className="list__item list__item--form margin-bottom-10">
            <Select
              value={
                form.shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS
                  ? QS_ALTERNATE_SHIP_FROM_ADDRESS
                  : `${form.shipFromType}__${
                      form.shipFromType === 'supplier_id'
                        ? form.supplierID
                        : form.warehouseID
                    }`
              }
              onChange={(value) => {
                if (value === QS_ALTERNATE_SHIP_FROM_ADDRESS) {
                  updateQuickShipForm({
                    shipFromType: QS_ALTERNATE_SHIP_FROM_ADDRESS,
                  })

                  return
                }

                const [, type, id] =
                  value.match(/^(supplier_id|warehouse_id)__(\d+)$/) || []

                if (type === 'supplier_id' && id) {
                  updateQuickShipForm({
                    shipFromType: type,
                    supplierID: Number(id),
                  })
                } else if (type === 'warehouse_id' && id) {
                  updateQuickShipForm({
                    shipFromType: type,
                    warehouseID: Number(id),
                  })
                }
              }}
            >
              {shipFromOptions.map(({value, display, disabled}, index) => (
                <option
                  key={disabled ? `disabled_${index}` : value}
                  value={value}
                  disabled={disabled}
                >
                  {display}
                </option>
              ))}
            </Select>
          </li>
        )}
        {shipFromAddress && shipFromAddress.city && shipFromAddress.street1 ? (
          <li className="list__item list__item--form">
            <ul className="list list--no-style">
              <li className="list__item fs-n0 margin-bottom-0">
                {shipFromAddress.street1}
              </li>
              {shipFromAddress.street2 && (
                <li className="list__item fs-n0 margin-bottom-0">
                  {shipFromAddress.street2}
                </li>
              )}
              <li className="list__item fs-n0 margin-bottom-0">
                {`${shipFromAddress.city}${
                  shipFromAddress.state || shipFromAddress.zip ? ',' : ''
                } ${shipFromAddress.state} ${shipFromAddress.zip}`.trim()}
              </li>
              {shipFromAddress.country && (
                <li className="list__item fs-n0 margin-bottom-0">
                  {shipFromAddress.country}
                </li>
              )}
            </ul>
          </li>
        ) : form.shipFromType === 'supplier_id' ? (
          <li className="list__item list__item--form margin-top-10 fs-00">
            <em>No street address has been entered for this supplier.</em>
            <div className="margin-bottom-5">
              <ButtonLink
                className="hyperlink"
                onClick={() => {
                  closeModal()

                  navigate(['settings', 'supplier', form.supplierID])
                }}
              >
                Add an address
              </ButtonLink>{' '}
              <span className="op-50">→</span>
            </div>
          </li>
        ) : null}
      </ul>
      {[QS_SETUP_WAREHOUSE, QS_ALTERNATE_SHIP_FROM_ADDRESS].includes(
        form.shipFromType,
      ) && (
        <div className="row">
          <div className="medium-9 columns">
            <ul className="list list--no-style list--manual-address margin-top-5 margin-bottom-10">
              <li className="list__item fs-n1 margin-top-15 margin-bottom-15">
                <ButtonPrimary
                  isOutlined
                  size="x-sm"
                  onClick={() =>
                    showPasteAddressModal({
                      addressType: QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS,
                      address: form.alternate_ship_from_address,
                    })
                  }
                >
                  Paste Address
                </ButtonPrimary>
              </li>
              <li className="list__item list__item--form">
                <AddressFilter
                  label="Name"
                  required={
                    form.shipFromType === QS_SETUP_WAREHOUSE ||
                    !form.alternate_ship_from_address.company ||
                    !!(
                      form.alternate_ship_from_address.name &&
                      form.alternate_ship_from_address.company
                    )
                  }
                  value={form.alternate_ship_from_address.name || ''}
                  onSelect={(address) =>
                    updateQuickShipForm({
                      alternate_ship_from_address: {...address},
                    })
                  }
                  onChange={(name) =>
                    setFormValue(
                      MODAL_FORM,
                      ['alternate_ship_from_address', 'name'],
                      name,
                    )
                  }
                  dropdown="QS_ALTERNATE_SHIP_FROM_ADDRESS"
                />
              </li>
              {form.shipFromType === QS_ALTERNATE_SHIP_FROM_ADDRESS && (
                <li className="list__item list__item--form">
                  <TextInput
                    label="Company"
                    required={!form.alternate_ship_from_address.name}
                    className="margin-bottom-0"
                    id="alternate_ship_from_address__company"
                    value={form.alternate_ship_from_address.company}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'company'],
                        value,
                      )
                    }
                  />
                </li>
              )}
              <li className="list__item list__item--form flex--justify">
                <div className="wrap--input-half">
                  <TextInput
                    label="Phone"
                    required={form.shipFromType === QS_SETUP_WAREHOUSE}
                    className="margin-bottom-0"
                    id="alternate_ship_from_address__phone"
                    value={form.alternate_ship_from_address.phone}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'phone'],
                        value,
                      )
                    }
                  />
                </div>
                <div className="wrap--input-half">
                  <TextInput
                    label="Email"
                    required={form.shipFromType === QS_SETUP_WAREHOUSE}
                    className="margin-bottom-0"
                    id="alternate_ship_from_address__email"
                    value={form.alternate_ship_from_address.email}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'email'],
                        value,
                      )
                    }
                  />
                </div>
              </li>
              <li className="list__item list__item--form">
                <TextInput
                  label="Street 1"
                  required
                  className="margin-bottom-0"
                  id="alternate_ship_from_address__street1"
                  value={form.alternate_ship_from_address.street1}
                  onChange={(value) =>
                    setFormValue(
                      MODAL_FORM,
                      ['alternate_ship_from_address', 'street1'],
                      value,
                    )
                  }
                />
              </li>
              <li className="list__item list__item--form">
                <TextInput
                  label="Street 2"
                  className="margin-bottom-0"
                  id="alternate_ship_from_address__street2"
                  value={form.alternate_ship_from_address.street2}
                  onChange={(value) =>
                    setFormValue(
                      MODAL_FORM,
                      ['alternate_ship_from_address', 'street2'],
                      value,
                    )
                  }
                />
              </li>
              <li className="list__item list__item--form flex--justify">
                <div className="wrap--input-half">
                  <TextInput
                    label="City"
                    required
                    id="alternate_ship_from_address__city"
                    value={form.alternate_ship_from_address.city}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'city'],
                        value,
                      )
                    }
                  />
                </div>
                <div className="wrap--input-eighth">
                  <TextInput
                    label="State"
                    required={form.shipFromType === QS_SETUP_WAREHOUSE}
                    id="alternate_ship_from_address__state"
                    value={form.alternate_ship_from_address.state}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'state'],
                        value,
                      )
                    }
                  />
                </div>
                <div className="wrap--input-third">
                  <TextInput
                    label="Zip"
                    required={form.shipFromType === QS_SETUP_WAREHOUSE}
                    id="alternate_ship_from_address__zip"
                    value={form.alternate_ship_from_address.zip}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'zip'],
                        value,
                      )
                    }
                  />
                </div>
              </li>
              <li className="list__item list__item--form flex--justify">
                <div className="wrap--input-half">
                  <TextInput
                    label="Country"
                    required
                    id="alternate_ship_from_address__country"
                    value={form.alternate_ship_from_address.country}
                    onChange={(value) =>
                      setFormValue(
                        MODAL_FORM,
                        ['alternate_ship_from_address', 'country'],
                        value,
                      )
                    }
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}
