import PropTypes from 'prop-types'

import Currency, {isTBD} from './Currency.js'

export default function FormattedRateCost({cost, currencySymbol}) {
  return (
    <span>
      {isTBD(cost) ? (
        'TBD'
      ) : (
        <Currency value={cost} currencySymbol={currencySymbol} />
      )}
    </span>
  )
}

FormattedRateCost.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currencySymbol: PropTypes.string.isRequired,
}
