import PropTypes from 'prop-types'

import {shipFromAddressSelector} from '../../../../../../../data/orders.js'
import {useSelector} from '../../../../../../../store.js'

import LabelItem from './LabelItem.js'

export default function ShipFrom({orderNumber, label = 'Shipped From'}) {
  const shipFrom = useSelector((state) =>
    shipFromAddressSelector(state, {orderNumber}),
  )

  return (
    <LabelItem label={label}>{shipFrom.name || shipFrom.company}</LabelItem>
  )
}

ShipFrom.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  label: PropTypes.string,
}
