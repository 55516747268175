import {useEffect} from 'react'

import {useSelector, dispatch} from '../../store.js'
import {DASHBOARD_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {DASHBOARD_URI_COMPONENT} from './dashboardFunctions.js'
import {hasWelcomeTabSelector} from '../Header/headerSelectors.js'

export default function DashboardLocationListener() {
  const {
    pathComponents: [resource],
  } = useSelector(locationSelector)
  const hasWelcomeTab = useSelector(hasWelcomeTabSelector)

  // if no route is supplied then they land on dashboard page (if they don't want the welcome tab)
  const isThisPage =
    resource === DASHBOARD_URI_COMPONENT || (!hasWelcomeTab && !resource)

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    dispatch(setCurrentPage(DASHBOARD_PAGE))
  }, [isThisPage])

  return null
}
