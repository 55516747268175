import PropTypes from 'prop-types'
import {useCallback} from 'react'
import classNames from 'classnames'

import {LabelInfoIDShape} from '../../../../common/PropTypes.js'
import {currencySymbolSelector} from '../../../../data/company.js'
import {
  customsLineSelector,
  customsLineErrorsSelector,
  customsLineWarningsSelector,
  maxLinesSelector,
  updateCustomsLine,
  removeCustomsLine,
} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'

export default function Line({
  labelInfoID,
  shipperType,
  customsInfoIndex,
  canHaveHarmonizationCode,
  canHaveHarmonizationCodeCountry,
  canHaveSKU,
}) {
  const {
    description,
    quantity,
    weightLB,
    weightOZ,
    value,
    sku,
    harmonizationCode,
    harmonizationCodeCountry,
    country,
  } = useSelector((state) =>
    customsLineSelector(state, {labelInfoID, customsInfoIndex}),
  )
  const currencySymbol = useSelector((state) => currencySymbolSelector(state))
  const errors = useSelector((state) =>
    customsLineErrorsSelector(state, {
      labelInfoID,
      customsInfoIndex,
      shipperType,
    }),
  )
  const warnings = useSelector((state) =>
    customsLineWarningsSelector(state, {
      labelInfoID,
      customsInfoIndex,
      shipperType,
    }),
  )
  const maxLines = useSelector((state) =>
    maxLinesSelector(state, {labelInfoID, shipperType}),
  )

  const update = useCallback(
    (updates) => updateCustomsLine(labelInfoID, customsInfoIndex, updates),
    [labelInfoID, customsInfoIndex],
  )

  return (
    <tr className={classNames({'tr--error': customsInfoIndex + 1 > maxLines})}>
      <td className="table__td">
        <textarea
          className="textarea textarea--customs margin-bottom-0"
          value={description}
          onChange={(event) => update({description: event.target.value})}
        />
        {errors.description && (
          <small className="error error-message">{errors.description}</small>
        )}
        {warnings.description && (
          <small className="error error-message">{warnings.description}</small>
        )}
      </td>
      <td className="table__td">
        <input
          type="text"
          className="input--text input--x-sm margin-bottom-0"
          value={quantity}
          onChange={(event) => update({quantity: event.target.value})}
        />
        {errors.quantity && (
          <small className="error error-message">{errors.quantity}</small>
        )}
      </td>
      <td className="table__td">
        <input
          type="text"
          className="input--text input--x-sm make-inline-block margin-bottom-7"
          value={weightLB}
          onChange={(event) => update({weightLB: event.target.value})}
        />{' '}
        <span className="inline-block fs-n1 margin-right-10">lb</span>
        <input
          type="text"
          className="input--text input--x-sm make-inline-block margin-bottom-7"
          value={weightOZ}
          onChange={(event) => update({weightOZ: event.target.value})}
        />{' '}
        <span className="inline-block fs-n1">oz</span>
        {errors.weight && (
          <small className="error error-message">{errors.weight}</small>
        )}
      </td>
      <td className="table__td">
        <strong className="inline-block fs-n1 margin-right-3">
          {currencySymbol}
        </strong>
        <input
          type="text"
          className="input--text input--x-sm make-inline-block margin-bottom-0"
          value={value}
          onChange={(event) => update({value: event.target.value})}
        />
        {errors.value && (
          <small className="error error-message">{errors.value}</small>
        )}
      </td>
      {canHaveSKU && (
        <td className="table__td">
          <input
            type="text"
            className="input--text input--md make-inline-block margin-bottom-0"
            value={sku}
            onChange={(event) => update({sku: event.target.value})}
          />
          {errors.sku && (
            <small className="error error-message">{errors.sku}</small>
          )}
        </td>
      )}
      {canHaveHarmonizationCode && (
        <td className="table__td">
          <input
            type="text"
            className="input--text input--md make-inline-block margin-bottom-0"
            value={harmonizationCode}
            onChange={(event) =>
              update({harmonizationCode: event.target.value})
            }
          />
          {errors.harmonizationCode && (
            <small className="error error-message">
              {errors.harmonizationCode}
            </small>
          )}
        </td>
      )}
      {canHaveHarmonizationCodeCountry && (
        <td className="table__td">
          <input
            type="text"
            className="input--text input--x-sm make-inline-block margin-bottom-0"
            value={harmonizationCodeCountry}
            onChange={(event) =>
              update({harmonizationCodeCountry: event.target.value})
            }
          />
          {errors.harmonizationCodeCountry && (
            <small className="error error-message">
              {errors.harmonizationCodeCountry}
            </small>
          )}
        </td>
      )}
      <td className="table__td">
        <input
          type="text"
          className="input--text input--x-sm make-inline-block margin-bottom-0"
          value={country}
          onChange={(event) => update({country: event.target.value})}
        />
        {errors.country && (
          <small className="error error-message">{errors.country}</small>
        )}
      </td>
      <td className="table__td">
        <button
          className="btn btn--link no-underline"
          type="button"
          title="Remove line from order"
          onClick={() => removeCustomsLine(labelInfoID, customsInfoIndex)}
        >
          <span className="i-trash fs-01" aria-hidden="true" />
        </button>
      </td>
    </tr>
  )
}

Line.propTypes = {
  labelInfoID: LabelInfoIDShape.isRequired,
  shipperType: PropTypes.string.isRequired,
  customsInfoIndex: PropTypes.number.isRequired,
  canHaveHarmonizationCode: PropTypes.bool.isRequired,
  canHaveHarmonizationCodeCountry: PropTypes.bool.isRequired,
  canHaveSKU: PropTypes.bool.isRequired,
}
