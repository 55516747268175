import PropTypes from 'prop-types'
import classNames from 'classnames'

import ExportCSVButton from './ExportCSVButton.js'
import ValueByStateGraph from './ValueByStateGraph.js'

export default function RevenueByStateMap({
  isLoading,
  revenueByState,
  onExport,
}) {
  return (
    <div className="row">
      <div className="medium-12 columns">
        <div
          className={classNames('panel panel--analytics clearfix', {
            loading: isLoading,
          })}
        >
          <div className="panel__header panel__header--analytics flex--justify">
            <h3 className="subheader subheader--analytics">Revenue by State</h3>
            {revenueByState && <ExportCSVButton onExport={onExport} />}
          </div>
          <ValueByStateGraph data={revenueByState} />
        </div>
      </div>
    </div>
  )
}

RevenueByStateMap.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  revenueByState: PropTypes.shape({}),
  onExport: PropTypes.func.isRequired,
}
