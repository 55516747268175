import {
  cartOptionsSelector,
  cartOptionTokensSelector,
} from '../../../../data/carts.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from './FilterPanel.js'
import {
  modalFormSelector,
  SALES_CHANNEL_FILTER_PANEL,
  updateModalForm,
} from './orderListFilterModalFunctions.js'

export default function SalesChannelFilterPanel() {
  const form = useSelector(modalFormSelector)
  const cartOptions = useSelector(cartOptionsSelector)
  const cartOptionTokens = useSelector(cartOptionTokensSelector)

  return (
    <FilterPanel
      id={SALES_CHANNEL_FILTER_PANEL}
      label="Sales Channels"
      searchPlaceholder="Search for a sales channel filter..."
      options={cartOptions}
      optionTokens={cartOptionTokens}
      selected={form.cartFilters}
      onSelect={(option) => {
        const cartFilters = form.cartFilters.filter((id) => id !== option.value)

        if (cartFilters.length === form.cartFilters.length) {
          cartFilters.push(option.value)
        }

        updateModalForm({cartFilters})
      }}
    />
  )
}
