import PropTypes from 'prop-types'

import {
  labelInfoIDsThatCanHaveReturnLabelsSelector,
  validShipperIDsSelector,
} from '../../../data/labelInfos/index.js'
import LabelConfig from '../../LabelConfig/index.js'
import LabelViewForm from '../../iverson/components/LabelViewForm/index.js'
import UnusableWarnings from '../../../common/components/List/ActionPanel/UnusableWarnings.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ShippingSpeedbumps from './ShippingSpeedbumps.js'
import ReturnOrderPanel from './ReturnOrderPanel.js'
import {canUseReturnOrdersSelector} from '../../../redux/selectors/data/returnOrders.js'
import {deselectOrders} from '../../OrderListPage/orderListActions.js'
import {useSelector} from '../../../store.js'
import {useReturnLabelsSelector} from '../../../data/company.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'

export default function ReturnTab({
  needsShipper,
  needsWarehouse,
  isOrderDetailView,
  orderNumbersThatCanHaveReturnLabels,
  orderNumbersWithReturnLabels,
  archivedOrderNumbers,
  cancelledOrderNumbers,
  mergedOrderNumbers,
  isPrimeWithoutReturnLabelOrderNumbers,
}) {
  const labelInfoIDsThatCanHaveReturnLabels = useSelector((state) =>
    labelInfoIDsThatCanHaveReturnLabelsSelector(state, {
      orderNumbers: orderNumbersThatCanHaveReturnLabels,
    }),
  )
  const canUseReturnOrders = useSelector(canUseReturnOrdersSelector)
  const useReturnLabels = useSelector(useReturnLabelsSelector)
  const noValidShippers = useSelector(
    (state) =>
      validShipperIDsSelector(state, {
        labelInfoIDs: labelInfoIDsThatCanHaveReturnLabels,
      }).length === 0,
  )

  if (canUseReturnOrders) {
    return <ReturnOrderPanel />
  }

  if (!useReturnLabels) {
    return (
      <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
          <p className="align-center fs-01 lh-md  margin-bottom-15">
            <strong>Create Return Labels for Your Customers!</strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-15">
            A great returns experience goes a long way for customer satisfaction
            and retention.
          </p>

          <p className="fs-00 lh-lg margin-bottom-20">
            To gain access to Return Labels, you need to add the{' '}
            <strong>Shipping App</strong> to your account.
          </p>

          <div>
            <ButtonPrimary
              size="x-md"
              className="meta--feature-lock-rma-label-panel"
              alt
              onClick={() => showUpgradeModal()}
            >
              Manage Your Apps
            </ButtonPrimary>
          </div>
        </div>
      </div>
    )
  }

  if (needsShipper || needsWarehouse || noValidShippers) {
    return (
      <ShippingSpeedbumps
        needsShipper={needsShipper}
        needsWarehouse={needsWarehouse}
        noValidShippers={noValidShippers}
      />
    )
  }

  const singleOrderReference = isOrderDetailView ? 'The order' : '1'
  const allWarningMessages = {
    returnLabelled: {
      ids: orderNumbersWithReturnLabels,
      singleMessage: `${singleOrderReference} already has a return label`,
      pluralMessage: `${orderNumbersWithReturnLabels.length} already have return labels`,
    },
    archived: {
      ids: archivedOrderNumbers,
      singleMessage: `${singleOrderReference} is an archived order`,
      pluralMessage: `${archivedOrderNumbers.length} are archived orders`,
    },
    cancelled: {
      ids: cancelledOrderNumbers,
      singleMessage: `${singleOrderReference} was cancelled`,
      pluralMessage: `${cancelledOrderNumbers.length} are cancelled orders`,
    },
    merged: {
      ids: mergedOrderNumbers,
      singleMessage: `${singleOrderReference} is a merged order`,
      pluralMessage: `${mergedOrderNumbers.length} are merged orders`,
    },
    isPrime: {
      ids: isPrimeWithoutReturnLabelOrderNumbers,
      singleMessage: `${singleOrderReference} is an Amazon Prime order`,
      pluralMessage: `${isPrimeWithoutReturnLabelOrderNumbers.length} are Amazon Prime orders`,
    },
  }

  if (orderNumbersThatCanHaveReturnLabels.length > 0) {
    const singleWarningTitle = 'A return label will not be created for 1 order'
    const pluralWarningTitle =
      'Return labels will not be created for some orders'
    const warningMessages = [
      allWarningMessages.returnLabelled,
      allWarningMessages.archived,
      allWarningMessages.cancelled,
      allWarningMessages.merged,
      allWarningMessages.isPrime,
    ]

    return (
      <div>
        <UnusableWarnings
          warningMessages={warningMessages}
          singleTitle={singleWarningTitle}
          pluralTitle={pluralWarningTitle}
          showDeselect={!isOrderDetailView}
          deselect={deselectOrders}
        />
        <LabelConfig labelInfoIDs={labelInfoIDsThatCanHaveReturnLabels} />
      </div>
    )
  }

  const singleWarningTitle = isOrderDetailView
    ? "This order can't have a return label created for it"
    : '1 order can not have a return label created for it'
  const pluralWarningTitle =
    'Some orders can not have return labels created for them'
  const warningMessages = [
    allWarningMessages.archived,
    allWarningMessages.cancelled,
    allWarningMessages.merged,
    allWarningMessages.isPrime,
  ]
  return (
    <div>
      <UnusableWarnings
        warningMessages={warningMessages}
        singleTitle={singleWarningTitle}
        pluralTitle={pluralWarningTitle}
        showDeselect={!isOrderDetailView}
        deselect={deselectOrders}
      />
      <LabelViewForm
        orderNumbers={orderNumbersWithReturnLabels}
        labelType="return"
      />
    </div>
  )
}

ReturnTab.propTypes = {
  needsWarehouse: PropTypes.bool.isRequired,
  needsShipper: PropTypes.bool.isRequired,
  isOrderDetailView: PropTypes.bool.isRequired,
  orderNumbersThatCanHaveReturnLabels: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  orderNumbersWithReturnLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  archivedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelledOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  mergedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrimeWithoutReturnLabelOrderNumbers: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
}
