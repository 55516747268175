import PropTypes from 'prop-types'
import {Component} from 'react'

export default class LabelItem extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
  }

  render() {
    return (
      <li className="list__item--order-data list__item--shipping-info">
        <strong className="margin-right-3">{this.props.label}:</strong>
        {this.props.children ? (
          <span>{this.props.children}</span>
        ) : (
          <em>None selected</em>
        )}
      </li>
    )
  }
}
