import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../../common/constants/SettingsPanels.js'
import {SHOPIFY} from '../../../../common/constants/CartVendorOptions.js'
import {shopifyNoShopInputSelector} from '../../../../data/company.js'
import {
  cartSelector,
  hasMultiLocationsSelector,
} from '../../../../data/carts.js'
import {redirect} from '../../../../common/location.js'
import TextInput from '../../Common/TextInput.js'
import Checkbox from '../../Common/Checkbox.js'
import Helper from '../../Common/Helper.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import Link from '../../../../common/components/Link/Link.js'
import VendorForm from './VendorForm.js'
import AuthorizeMessage from '../AuthorizeMessage.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import ReauthorizeButton from '../ReauthorizeButton.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})
  const shopifyNoShopInput = shopifyNoShopInputSelector(state)

  if (!form) {
    return errors
  }

  if (!shopifyNoShopInput && !isPresent(form.shopURL)) {
    errors.shopURL = 'URL is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: SHOPIFY}),
      shopURL: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    shopURL: cart.vendor_config.shop_url,
    locations: cart.vendor_config.locations,
    // use_shopify_notify_customer defaults to true but hasn't been backfilled
    use_shopify_notify_customer:
      cart.vendor_config.use_shopify_notify_customer === undefined
        ? true
        : cart.vendor_config.use_shopify_notify_customer,
    syncs_shipped_orders: !!cart.vendor_config.syncs_shipped_orders,
    discount_codes_as_tags: !!cart.vendor_config.discount_codes_as_tags,
    discount_codes_as_customer_note:
      !!cart.vendor_config.discount_codes_as_customer_note,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'shop_url') {
    errors.shopURL = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    locations: form.locations,
    use_shopify_notify_customer: form.use_shopify_notify_customer,
    syncs_shipped_orders: form.syncs_shipped_orders,
    discount_codes_as_tags: form.discount_codes_as_tags,
    discount_codes_as_customer_note: form.discount_codes_as_customer_note,
  }
}

export function authorize(
  formName,
  redirectURL = `/app${SALES_CHANNEL_SETTINGS_LINK}`,
) {
  if (shopifyNoShopInputSelector(getState())) {
    return redirect('apps.shopify.com', ['ordoro'])
  }

  const form = formSelector(getState(), {formName})
  const params = {
    shop: form.shopURL.replace(/^https?:\/\//, '').split('/')[0],
    redirect: redirectURL,
  }

  if (form.id !== NEW_ID) {
    params.cartID = form.id
  }

  return redirect('', ['verde', 'shopify', 'start'], params)
}

function Shopify({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )
  const hasMultiLocations = !!useSelector((state) =>
    hasMultiLocationsSelector(state, {cartID: form.id}),
  )
  const shopifyNoShopInput = useSelector(shopifyNoShopInputSelector)

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {isNew && (
            <>
              {!shopifyNoShopInput && (
                <FormListItem className="divider--top">
                  <TextInput
                    name="shopify_store_name"
                    label="Store URL"
                    required
                    placeholder="awesome-store.myshopify.com"
                    value={form.shopURL || ''}
                    errorMessage={form.hasChanged_shopURL && errors.shopURL}
                    onChange={(event) =>
                      updateForm(formName, {
                        shopURL: event.target.value,
                        hasChanged_shopURL: true,
                      })
                    }
                  />
                </FormListItem>
              )}
              <AuthorizeMessage formName={formName} />
            </>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <ReauthorizeButton formName={formName}>
                <div className="lh-sm margin-top-5">
                  <strong className="fs-00">Shopify Store URL:</strong>
                </div>
                <div className="margin-bottom-5">
                  <a
                    href={form.shopURL}
                    className="fs-00 btn--link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {form.shopURL}
                  </a>
                </div>
              </ReauthorizeButton>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <FormListItem className="divider--top">
                <div className="wrap--v-align-inner margin-bottom-10">
                  <Checkbox
                    id="shopify_use_shopify_notify_customer"
                    name="shopify_use_shopify_notify_customer"
                    label="Have Shopify Email Customer When their Order Ships"
                    checked={form.use_shopify_notify_customer}
                    onChange={(checked) =>
                      updateForm(formName, {
                        use_shopify_notify_customer: checked,
                      })
                    }
                  />
                </div>
                <div className="wrap--v-align-inner margin-bottom-10">
                  <Checkbox
                    id="discount_codes_as_tags"
                    name="discount_codes_as_tags"
                    label="Apply Discount Codes as Order Tags"
                    checked={form.discount_codes_as_tags}
                    onChange={(checked) =>
                      updateForm(formName, {
                        discount_codes_as_tags: checked,
                      })
                    }
                  />
                </div>
                <div className="wrap--v-align-inner margin-bottom-10">
                  <Checkbox
                    id="discount_codes_as_customer_note"
                    name="discount_codes_as_customer_note"
                    label="Add Discount Codes to Customer Note Field"
                    checked={form.discount_codes_as_customer_note}
                    onChange={(checked) =>
                      updateForm(formName, {
                        discount_codes_as_customer_note: checked,
                      })
                    }
                  />
                </div>
              </FormListItem>
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Shopify"
          href="https://support.ordoro.com/shopify-and-ordoro-setup-workflow/"
        />
        {!hasMultiLocations && (
          <Helper
            title="Do you have Locations configured in your Shopify account?"
            type="warning-lt"
            className="margin-top-15"
          >
            <Link
              className="mid"
              href="https://support.ordoro.com/shopify-locations-and-ordoro-setup/"
              newWindow
            >
              Learn how to get them set up properly →
            </Link>
          </Helper>
        )}
      </div>
    </VendorForm>
  )
}

Shopify.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    shopURL: PropTypes.string.isRequired,
    hasChanged_shopURL: PropTypes.bool,
    use_shopify_notify_customer: PropTypes.bool,
    discount_codes_as_tags: PropTypes.bool,
    discount_codes_as_customer_note: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Shopify, setUpCartForm)
