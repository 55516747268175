export default [
  {
    name: 'Green',
    color: '#c4e1b2',
  },
  {
    name: 'Yellow',
    color: '#f5f5b0',
  },
  {
    name: 'Orange',
    color: '#fee2ca',
  },
  {
    name: 'Grey',
    color: '#b8b0b0',
  },
  {
    name: 'Rose',
    color: '#f5b0b1',
  },
  {
    name: 'Baby Blue',
    color: '#c1fcfc',
  },
  {
    name: 'Seafoam',
    color: '#cef3e5',
  },
  {
    name: 'Mint',
    color: '#b9d2c9',
  },
  {
    name: 'Sky Blue',
    color: '#d6ecf5',
  },
  {
    name: 'Pink',
    color: '#f7dbe7',
  },
  {
    name: 'Tan',
    color: '#ebe7d1',
  },
  {
    name: 'Lime',
    color: '#dfffd4',
  },
]
