import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {ReturnOrderCodeShape} from '../../../common/PropTypes.js'
import {returnOrderCodesSortedByCodeSelector} from '../../../redux/selectors/data/returnOrderCodes.js'
import {returnOrderCodesSettingsSelector} from '../../../redux/selectors/ui/settings/returnOrderCodes.js'
import CodeListItem from './CodeListItem.js'

function CodeList({returnOrderCodes, currentReturnOrderCodeID, isNew}) {
  return (
    <div className="medium-12 columns">
      <ul className="settings-list">
        {returnOrderCodes.map((code) => (
          <CodeListItem
            key={code.id}
            returnOrderCodeID={code.id}
            isExpanded={code.id === currentReturnOrderCodeID}
          />
        ))}
        <CodeListItem isExpanded={isNew} />
      </ul>
    </div>
  )
}

CodeList.propTypes = {
  returnOrderCodes: PropTypes.arrayOf(ReturnOrderCodeShape).isRequired,
  currentReturnOrderCodeID: PropTypes.number,
  isNew: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const {currentReturnOrderCodeID, isNew} =
    returnOrderCodesSettingsSelector(state)

  return {
    returnOrderCodes: returnOrderCodesSortedByCodeSelector(state),
    currentReturnOrderCodeID,
    isNew,
  }
}

export default connect(mapStateToProps)(CodeList)
