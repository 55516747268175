import {
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  PITNEY_CBDS,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../../../../../common/constants/ShipperNames.js'
import * as amazonSFP from './amazonSFP.js'
import * as amazonShipper from './amazonShipper.js'
import * as australiaPost from './australiaPost.js'
import * as canadaPost from './canadaPost.js'
import * as pitneyCBDS from '../../../../../../ordoro/settings/Shippers/Forms/PitneyCBDSForm.js'
import * as dhl from './dhl.js'
import * as dhlEcommerce from '../../../../../../ordoro/settings/Shippers/Forms/DHLEcommerceForm.js'
import * as endicia from './endicia.js'
import * as fedex from '../../../../../../ordoro/settings/Shippers/Forms/FedExForm.js'
import * as newgistics from './newgistics.js'
import * as pitney from './pitney.js'
import * as pitneyMerchant from '../../../../../../ordoro/settings/Shippers/Forms/PitneyMerchantForm.js'
import * as pitneyPresort from './pitneyPresort.js'
import * as sendle from '../../../../../../ordoro/settings/Shippers/Forms/SendleForm.js'
import * as ups from '../../../../../../ordoro/settings/Shippers/Forms/UPSForm/index.js'
import * as visibleUSPS from './visibleUSPS.js'
import * as xDelivery from './xDelivery.js'

export default {
  [AMAZON_SFP]: amazonSFP,
  [AMAZON_SHIPPER]: amazonShipper,
  [AUSTRALIA_POST]: australiaPost,
  [CANADA_POST]: canadaPost,
  [PITNEY_CBDS]: pitneyCBDS,
  [DHL]: dhl,
  [DHL_ECOMMERCE]: dhlEcommerce,
  [ENDICIA]: endicia,
  [FEDEX]: fedex,
  [NEWGISTICS]: newgistics,
  [PITNEY]: pitney,
  [PITNEY_MERCHANT]: pitneyMerchant,
  [PITNEY_PRESORT]: pitneyPresort,
  [SENDLE]: sendle,
  [UPS]: ups,
  [VISIBLE_USPS]: visibleUSPS,
  [X_DELIVERY]: xDelivery,
}
