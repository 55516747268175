import {stringifyURL} from '../../../../../common/querystring.js'

export function authorizeLinkSelector() {
  const params = {
    redirect: '/app/settings/payments',
    type: 'integration',
  }

  return stringifyURL('/verde/stripe/start', params)
}
