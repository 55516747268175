import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {updateForm, formsSelector} from '../../../store.js'
import {formConnectAuto} from '../../../common/formConnect.js'
import Radio from '../../../common/components/Form/Radio.js'
import {dropshippableOrderNumbersSelector} from '../../../data/orders.js'
import ManualDropshipForm from './ManualDropshipForm/index.js'
import ManualDropshipSummary from './ManualDropshipForm/Summary.js'
import {MANUAL_DROPSHIP_FORM} from './ManualDropshipForm/manualDropshipFormFunctions.js'
import ProcessDropshipForm from './ProcessDropshipForm/index.js'
import ProcessDropshipSummary from './ProcessDropshipForm/Summary.js'
import {PROCESS_DROPSHIP_FORM} from './ProcessDropshipForm/processDropshipFormFunctions.js'

const DROPSHIP_CONFIG_FORM = 'DROPSHIP_CONFIG_FORM'

export function setupDropshipConfigForm() {
  return {
    formName: DROPSHIP_CONFIG_FORM,
    initialForm: {
      selectedDropshipForm: PROCESS_DROPSHIP_FORM,
    },
  }
}

export function updateDropshipConfigFrom(updates) {
  updateForm(DROPSHIP_CONFIG_FORM, updates)
}

export function dropshipConfigFormSelector(state) {
  return formsSelector(state)[DROPSHIP_CONFIG_FORM]
}

export function setSelectedDropshipForm(selectedDropshipForm) {
  updateDropshipConfigFrom({selectedDropshipForm})
}

function DropshipConfigForm({orderNumbers, form, dropshippableOrdersCount}) {
  useEffect(() => {
    setSelectedDropshipForm(
      dropshippableOrdersCount === 0
        ? MANUAL_DROPSHIP_FORM
        : PROCESS_DROPSHIP_FORM,
    )
  }, [orderNumbers, dropshippableOrdersCount])

  return (
    <form className="form--dropship meta-dropshipform">
      <fieldset className="fieldset--dropship fieldset--scroll-wrap padding-top-15">
        <div className="inner-wrap__panel--actions inner-wrap__dropship">
          <dd className="list__item">
            <Radio
              name="input-dropship-process"
              label="Process Dropship-Ready Products"
              onChange={() => setSelectedDropshipForm(PROCESS_DROPSHIP_FORM)}
              checked={form.selectedDropshipForm === PROCESS_DROPSHIP_FORM}
              disabled={dropshippableOrdersCount === 0}
              alwaysShowChildren
            >
              {dropshippableOrdersCount > 0 && (
                <div>
                  <ProcessDropshipForm orderNumbers={orderNumbers} />
                </div>
              )}
            </Radio>
          </dd>
          <dd className="list__item">
            <Radio
              name="input-dropship-manual"
              label="Manually Request Dropshipments"
              onChange={() => setSelectedDropshipForm(MANUAL_DROPSHIP_FORM)}
              checked={form.selectedDropshipForm === MANUAL_DROPSHIP_FORM}
            >
              <ManualDropshipForm orderNumbers={orderNumbers} />
            </Radio>
          </dd>
        </div>
      </fieldset>
      <fieldset className="fieldset--dropship">
        {form.selectedDropshipForm === PROCESS_DROPSHIP_FORM && (
          <ProcessDropshipSummary orderNumbers={orderNumbers} />
        )}
        {form.selectedDropshipForm === MANUAL_DROPSHIP_FORM && (
          <ManualDropshipSummary orderNumbers={orderNumbers} />
        )}
      </fieldset>
    </form>
  )
}

DropshipConfigForm.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: PropTypes.shape({
    selectedDropshipForm: PropTypes.string.isRequired,
  }).isRequired,
  dropshippableOrdersCount: PropTypes.number.isRequired,
}

function mapStateToProps(state, {orderNumbers}) {
  return {
    dropshippableOrdersCount: dropshippableOrderNumbersSelector(state, {
      orderNumbers,
    }).length,
  }
}

export default formConnectAuto(
  connect(mapStateToProps)(DropshipConfigForm),
  setupDropshipConfigForm,
)
