import {all, takeEvery, call, put} from 'redux-saga/effects'

import {logError} from '../../../common/error.js'
import {
  VIEW_AND_PRINT_DOCUMENT,
  setLoading,
  setError,
} from '../../actions/ui/labelViewForm/index.js'
import {markDocumentsPrinted} from '../../../ordoro/OrderListPage/orderListActions.js'

export function* viewDocument(pdfLink) {
  try {
    yield call(window.open, pdfLink, '_blank')
  } catch (err) {
    // Edge throws an error even though it actually performs the open.
    // This seems to be a CORs related thing since this started after we switched off the proxy.
    // Also, this seems to only happen when we decouple the call from the click.
    // But Edge gives no clues as to what it wants and the Internet is no help. Maybe future
    // generations will discover the cure.
    // Swallow the error cause what else can we do.
  }
}

export function* viewAndPrintDocumentWorker(action) {
  try {
    yield put(setError(null))
    yield put(setLoading(true))

    const pdfLink = action.payload
    yield call(viewDocument, pdfLink)

    yield call(markDocumentsPrinted, pdfLink)
  } catch (err) {
    const errorMessage = `Could not mark documents as printed. ${err.message}`
    logError(err, errorMessage)
    yield put(setError(errorMessage, err))
  } finally {
    yield put(setLoading(false))
  }
}

export default function* labelViewForm() {
  yield all([takeEvery(VIEW_AND_PRINT_DOCUMENT, viewAndPrintDocumentWorker)])
}
