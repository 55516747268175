import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import get from 'lodash/get.js'

import Checkbox from '../../common/components/Checkbox.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {
  createReturnOrderSelector,
  createReturnOrderLinesSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {showAssignReturnOrderLineCodeModal} from '../ReturnOrderListPage/Modals/AssignReturnOrderLineCodeModal.js'
import {showDeleteReturnOrderLinesModal} from '../ReturnOrderListPage/Modals/DeleteReturnOrderLinesModal.js'
import {showRecieveReturnOrderModal} from '../ReturnOrderListPage/Modals/ReceiveReturnOrderModal/index.js'
import {showEditRMALineModal} from '../ReturnOrderListPage/Modals/EditRMALineModal.js'
import {
  ReturnOrderDetailFormShape,
  updateReturnOrderDetailForm,
} from './returnOrderDetailActions.js'
import {
  returnOrderDetailReferenceIDSelector,
  returnOrderDetailFormSelector,
} from './returnOrderDetailSelectors.js'
import Line from './Line.js'

export function Lines({referenceID, form, lines, ...props}) {
  return (
    <div>
      <h3 className="list__title--order-data margin-top-0 margin-bottom-10">
        RMA Contents
      </h3>
      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--sm w-1">
              {lines.length !== 0 && (
                <Checkbox
                  className="margin-bottom-0"
                  checked={lines.length === form.selectedLineIDs.length}
                  indeterminate={
                    lines.length !== form.selectedLineIDs.length &&
                    form.selectedLineIDs.length !== 0
                  }
                  onChange={() => {
                    updateReturnOrderDetailForm({
                      selectedLineIDs:
                        lines.length !== form.selectedLineIDs.length ||
                        form.selectedLineIDs.length === 0
                          ? lines.map(({line_id}) => line_id)
                          : [],
                    })
                  }}
                />
              )}
            </th>
            <th className="table__th table__th--sm th-width-20">Product/SKU</th>
            <th className="table__th table__th--sm th-width-10">Total Price</th>
            <th className="table__th table__th--sm th-width-10">
              {form.selectedLineIDs.length === 0 ? (
                'Return Code'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Return Codes"
                  onClick={() =>
                    props.showAssignReturnOrderLineCodeModal(
                      referenceID,
                      form.selectedLineIDs,
                      get(
                        lines.find(
                          (line) => line.line_id === form.selectedLineIDs[0],
                        ),
                        'code.code',
                        null,
                      ),
                    )
                  }
                >
                  Return Code
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-10 align-right">
              {form.selectedLineIDs.length === 0 ? (
                'Expected'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Expected Quantity"
                  onClick={() =>
                    props.showRecieveReturnOrderModal(
                      referenceID,
                      form.selectedLineIDs,
                      'expected',
                    )
                  }
                >
                  Expected
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-10 align-right">
              {form.selectedLineIDs.length === 0 ? (
                'Received'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Receive"
                  onClick={() =>
                    props.showRecieveReturnOrderModal(
                      referenceID,
                      form.selectedLineIDs,
                      'recieved',
                    )
                  }
                >
                  Received
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-10 align-right">
              {form.selectedLineIDs.length === 0 ? (
                'Restocked'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Restock"
                  onClick={() =>
                    props.showRecieveReturnOrderModal(
                      referenceID,
                      form.selectedLineIDs,
                      'restocked',
                    )
                  }
                >
                  Restocked
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-15">
              Additional Details
            </th>
            <th className="table__th table__th--sm w-1">
              {form.selectedLineIDs.length !== 0 && (
                <ButtonLink
                  title="Bulk remove lines from RMA"
                  className="no-underline lh-sm darker"
                  onClick={() =>
                    props.showDeleteReturnOrderLinesModal(
                      referenceID,
                      form.selectedLineIDs,
                    )
                  }
                >
                  <span className="i-trash fs-00" aria-hidden="true"></span>
                </ButtonLink>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines">
          {lines.map((line, index) => (
            <Line
              key={line.line_id}
              line={line}
              index={index}
              referenceID={referenceID}
              selectedLineIDs={form.selectedLineIDs}
              lines={lines}
            />
          ))}
          <tr>
            <td className="table__td table__td--product-search" colSpan="9">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() => showEditRMALineModal(referenceID)}
              >
                Add a Product
              </ButtonPrimary>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

Lines.propTypes = {
  referenceID: PropTypes.string.isRequired,
  form: ReturnOrderDetailFormShape.isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  showAssignReturnOrderLineCodeModal: PropTypes.func.isRequired,
  showRecieveReturnOrderModal: PropTypes.func.isRequired,
  showDeleteReturnOrderLinesModal: PropTypes.func.isRequired,
}

function mapStateToProps(_state) {
  const referenceID = returnOrderDetailReferenceIDSelector(_state)
  const returnOrderSelector = createReturnOrderSelector(referenceID)
  const linesSelector = createReturnOrderLinesSelector(returnOrderSelector)

  return (state) => ({
    referenceID,
    form: returnOrderDetailFormSelector(state),
    lines: linesSelector(state),
  })
}

const mapDispatchToProps = {
  showAssignReturnOrderLineCodeModal,
  showRecieveReturnOrderModal,
  showDeleteReturnOrderLinesModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Lines)
