import PropTypes from 'prop-types'

import {onlyIfForm, useSelector} from '../../../store.js'
import {HK_SELECT_ALL} from '../../../common/constants/HotKeys.js'
import {HotKeyConnect} from '../../../common/components/HotKeys.js'
import {STARTER_PLAN} from '../../../common/constants/Plans.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  companyIDSelector,
  hasTrialIMMSelector,
  planSelector,
} from '../../../data/company.js'
import {
  hasProductWritebackInventoryPermissionSelector,
  hasOrderImportFromCartPermissionSelector,
  hasProductImportFromCartPermissionSelector,
  isSuperuserSelector,
} from '../../../data/me.js'
import {showEditCartModal} from '../../settings/Modals/EditCartModal.js'
import {hasWebhookCartSelector} from '../../../data/carts.js'
import {
  closeModal,
  toggleSelectAll,
  saveCartSyncSettings,
  syncOnceCartSyncSettings,
  cartSyncModalFormSelector,
  cartsForSyncModalSelector,
  isAllCheckedSelector,
} from './cartSyncModalFunctions.js'
import Cart from './Cart.js'
import {showFeatureLockedModal} from '../FeatureLockedModal.js'

function CartSyncModal({form}) {
  const carts = useSelector(cartsForSyncModalSelector)
  const plan = useSelector(planSelector)
  const companyID = useSelector(companyIDSelector)
  const hasTrialIMM = useSelector(hasTrialIMMSelector)
  const hasWebhookCart = useSelector((state) =>
    hasWebhookCartSelector(state, {cartIDs: carts.map(({id}) => id)}),
  )
  const hasProductWritebackInventoryPermission = useSelector(
    hasProductWritebackInventoryPermissionSelector,
  )
  const hasOrderImportFromCartPermission = useSelector(
    hasOrderImportFromCartPermissionSelector,
  )
  const hasProductImportFromCartPermission = useSelector(
    hasProductImportFromCartPermissionSelector,
  )
  const isSuperuser = useSelector(isSuperuserSelector)
  const isAllChecked = useSelector(isAllCheckedSelector)

  return (
    <ConfirmModal
      title="Ordoro Sync Settings"
      modalSize="lg"
      confirmText="Update Autosync Settings"
      onConfirm={() => saveCartSyncSettings()}
      MiddleButtons={() => (
        <>
          {(hasProductWritebackInventoryPermission ||
            hasOrderImportFromCartPermission ||
            hasProductImportFromCartPermission) && (
            <div className="margin-right-10">
              <ButtonPrimary
                alt
                onClick={() => syncOnceCartSyncSettings()}
                isDisabled={form.isSaving || form.isSyncing}
                isLoading={form.isSyncing}
              >
                Sync Once
              </ButtonPrimary>
            </div>
          )}
        </>
      )}
      RightButtons={
        isSuperuser
          ? () => (
              <ButtonPrimary
                isOutlined
                onClick={() => syncOnceCartSyncSettings({freakFlag: true})}
                isDisabled={form.isSaving || form.isSyncing}
                isLoading={form.isSyncing}
              >
                Admin Force Sync
              </ButtonPrimary>
            )
          : null
      }
      cancelText="Close"
      onCancel={() => closeModal()}
      isDisabled={form.isSaving || form.isSyncing}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <HotKeyConnect code={HK_SELECT_ALL} func={() => toggleSelectAll()} />
      {hasTrialIMM && (
        <div className="alert alert--warning alert--lg margin-bottom-25">
          <p className="fs-01 lh-md margin-bottom-10">
            <strong>
              Ready for Ordoro to start automatically writing inventory values
              back to your sales channels?
            </strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-15">
            Sweet — we just need to turn on Inventory Writeback for your
            account.
          </p>
          <p className="fs-00 lh-md margin-bottom-10">
            There are a couple ways you can make that happen:
          </p>
          <ul className="list list--square margin-bottom-20">
            <li className="list__item fs-00 lh-md margin-bottom-10">
              Send a request via email to <strong>success@ordoro.com</strong>.
            </li>
            <li className="list__item fs-00 lh-md margin-bottom-0">
              Or,{' '}
              <ButtonLink
                className="fs-00 v-align-base darker border-underline"
                onClick={() => showFeatureLockedModal('inventory')}
              >
                <strong>book some time with a Product Expert</strong>
              </ButtonLink>{' '}
              if you’d like to learn more about this powerful feature first.
            </li>
          </ul>
          <p className="fs-00 lh-md margin-bottom-0">
            In either case, be sure to include your Support Pin (
            <strong>{companyID}</strong>) in your message.
          </p>
        </div>
      )}
      {plan !== STARTER_PLAN && (
        <p className="margin-bottom-10">
          <strong>
            <a
              href="https://support.ordoro.com/auto-sync-inventory-across-sales-channels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Autosync:
            </a>
          </strong>
          <span>
            {' '}
            Select the tasks you’d like to run automatically and click “Update
            Autosync Settings.”
          </span>
        </p>
      )}
      {hasWebhookCart && (
        <p className="margin-bottom-10">
          <strong>
            <a
              href="https://support.ordoro.com/how-insta-sync-works/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instasync:
            </a>
          </strong>
          <span>
            {' '}
            Orders will instantly appear in Ordoro as soon they arrive in your
            sales channel. It’s magic!
          </span>
        </p>
      )}
      <p className="margin-bottom-25">
        <strong>Manually sync:</strong> Select the tasks you want to run just
        this time and click “Sync Once.”
      </p>

      <div className="flex--justify">
        <ButtonPrimary
          isOutlined
          className="btn--x-sm margin-bottom-15"
          onClick={() => toggleSelectAll()}
        >
          {isAllChecked ? 'Deselect all' : 'Select all'}
        </ButtonPrimary>
        <ButtonLink
          className="fs-n0 v-align-middle"
          onClick={() => showEditCartModal()}
        >
          + Add a Sales Channel
        </ButtonLink>
      </div>
      <ul className="form-list clearfix sync-cart-list margin-bottom-10">
        {carts.map((cart, index) => (
          <Cart
            key={cart.id}
            cartID={cart.id}
            index={index}
            closeSyncModal={() => closeModal()}
          />
        ))}
      </ul>
    </ConfirmModal>
  )
}

CartSyncModal.propTypes = {
  form: PropTypes.shape({
    isSaving: PropTypes.bool.isRequired,
    isSyncing: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(CartSyncModal, cartSyncModalFormSelector)
