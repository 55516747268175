import PropTypes from 'prop-types'
import floor from 'lodash/floor.js'

export default function Floored({value = '', digits = 0}) {
  return <span>{floor(value, digits)}</span>
}

Floored.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  digits: PropTypes.number,
}
