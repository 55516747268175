import PropTypes from 'prop-types'

export default function ShippingSpeedbumps({needsSupplier}) {
  if (!needsSupplier) {
    return null
  }

  return (
    <div className="alert alert--lg alert--warning alert--tally margin-bottom-20">
      <p className="align-center fs-01 lh-lg margin-bottom-15">
        <strong>
          Before you can request a dropshipment, you must add a supplier to your
          account.
        </strong>
      </p>
      <p className="align-center speedbump-pulse margin-bottom-0">
        <a
          className="btn btn--primary btn--primary-alt btn--md"
          href="#/settings/supplier"
          title="Configure your suppliers"
        >
          Setup a Supplier
        </a>
      </p>
    </div>
  )
}

ShippingSpeedbumps.propTypes = {
  needsSupplier: PropTypes.bool.isRequired,
}
