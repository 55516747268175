import PropTypes from 'prop-types'
import classNames from 'classnames'

import Item from './Item.js'
import ItemDisplay from './ItemDisplay.js'
import ItemValue from './ItemValue.js'

export default function AdditionalInfo({children, inv, right, first, last}) {
  return (
    <div
      className={classNames(
        'wrap--product-addl-info-list',
        {'wrap--product-addl-info-inv': inv},
        {'wrap--product-addl-info-right': right},
        {first: first},
        {last: last},
      )}
    >
      {children}
    </div>
  )
}

AdditionalInfo.propTypes = {
  children: PropTypes.node.isRequired,
  right: PropTypes.bool,
  inv: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
}

AdditionalInfo.Item = Item
AdditionalInfo.ItemDisplay = ItemDisplay
AdditionalInfo.ItemValue = ItemValue
