import {
  FINANCIAL_PANEL,
  SHIPPING_PANEL,
  INVENTORY_PANEL,
  PRODUCT_PANEL,
  CUSTOMER_PANEL,
} from '../../common/constants/Analytics.js'
import {
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
  hasAnalyticsInventoryPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  hasAnalyticsCustomerPermissionSelector,
} from '../../data/me.js'
import {useSelector} from '../../store.js'
import {analyticsPanelSelector} from './analyticsSelectors.js'

import SideNavLink from './SideNavLink.js'

export default function SideNav() {
  const currentPanel = useSelector(analyticsPanelSelector)
  const hasAnalyticsFinancialPermission = useSelector(
    hasAnalyticsFinancialPermissionSelector,
  )
  const hasAnalyticsShippingPermission = useSelector(
    hasAnalyticsShippingPermissionSelector,
  )
  const hasAnalyticsInventoryPermission = useSelector(
    hasAnalyticsInventoryPermissionSelector,
  )
  const hasAnalyticsProductPermission = useSelector(
    hasAnalyticsProductPermissionSelector,
  )
  const hasAnalyticsCustomerPermission = useSelector(
    hasAnalyticsCustomerPermissionSelector,
  )

  return (
    <div className="medium-2 medium-pull-10 columns wrap--side-nav margin-top-5 padding-right-0">
      <ul className="side-nav padding-top-0">
        {hasAnalyticsFinancialPermission && (
          <SideNavLink
            title="Financial Stats"
            name={FINANCIAL_PANEL}
            icon="icon-revenue"
            isActive={currentPanel === FINANCIAL_PANEL}
          />
        )}
        {hasAnalyticsShippingPermission && (
          <SideNavLink
            title="Shipping Stats"
            name={SHIPPING_PANEL}
            icon="icon-shipments"
            isActive={currentPanel === SHIPPING_PANEL}
          />
        )}
        {hasAnalyticsInventoryPermission && (
          <SideNavLink
            title="Inventory Stats"
            name={INVENTORY_PANEL}
            icon="icon-products"
            isActive={currentPanel === INVENTORY_PANEL}
          />
        )}
        {hasAnalyticsProductPermission && (
          <SideNavLink
            title="Product Stats"
            name={PRODUCT_PANEL}
            icon="icon-top-sellers"
            isActive={currentPanel === PRODUCT_PANEL}
          />
        )}
        {hasAnalyticsCustomerPermission && (
          <SideNavLink
            title="Top Customers"
            name={CUSTOMER_PANEL}
            icon="icon-customers"
            isActive={currentPanel === CUSTOMER_PANEL}
          />
        )}
      </ul>
    </div>
  )
}
