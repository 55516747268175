import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty.js'

import Form from '../../../common/components/Form/index.js'

class BaseReconcileForm extends Component {
  render() {
    const className = classNames('form-list--reconcile', this.props.className)
    const hasColumnErrors = !isEmpty(this.props.columnErrors)

    return (
      <div className="row">
        <Form className={className}>
          {this.props.children}
          <li className="form-list-item">
            <button
              className="btn btn--primary btn--md"
              type="submit"
              disabled={hasColumnErrors || this.props.isValidatingData}
              onClick={this.props.confirmColumns}
            >
              Verify Columns
            </button>
          </li>
        </Form>
      </div>
    )
  }
}

BaseReconcileForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  confirmColumns: PropTypes.func.isRequired,
  isValidatingData: PropTypes.bool,
  columnErrors: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isValidatingData: state.ui.dataImport.isValidatingData,
    columnErrors: state.ui.dataImport.columnErrors,
  }
}

export default connect(mapStateToProps)(BaseReconcileForm)
