import {useEffect} from 'react'

import {PURCHASE_ORDER_LIST_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {setupPOListForm} from './purchaseOrderListActions.js'
import {dispatch, useSelector} from '../../store.js'
import {poListQuerySelector} from './purchaseOrderListSelectors.js'
import {LIST_MODE} from '../../common/constants/index.js'

export default function POListLocationListener() {
  const query = useSelector(poListQuerySelector)

  useEffect(() => {
    setupPOListForm()
  }, [])

  useEffect(() => {
    if (query.mode !== LIST_MODE) {
      return
    }

    dispatch(setCurrentPage(PURCHASE_ORDER_LIST_PAGE))
  }, [query.mode])

  return null
}
