import {
  OT_RETURN_ORDER,
  OT_V3_ORDER,
} from '../../../common/constants/OrderTypes.js'
import {
  updateLabelConfig,
  applyShipFrom,
  labelConfigSelector,
  labelTypeSelector,
  shipFromOptionsSelector,
  getShipFromFromValue,
  formatShipFromValue,
  orderNumberFromLabelConfigSelector,
  orderTypeSelector,
  labelShipToAddressSelector,
  referenceIDFromLabelConfigSelector,
} from '../../../data/labelInfos/index.js'
import {showRMAReturnToAddressModal} from '../../../redux/actions/data/returnOrders.js'
import {
  showAlternateShipFromAddressModal,
  orderWarehouseIDSelector,
  orderSupplierIDSelector,
  orderAlternateShipFromAddressSelector,
} from '../../../data/orders.js'
import {useSelector} from '../../../store.js'
import Select from '../../../common/components/Select.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function ShipFrom() {
  const {labelInfoID, isLabelEnableMode} = useLabelConfigContext()
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
  const labelType = useSelector((state) =>
    labelTypeSelector(state, {labelInfoID}),
  )
  const do_not_ship_from_supplier = useSelector(
    (state) =>
      labelConfigSelector(state, {labelInfoID}).do_not_ship_from_supplier ||
      false,
  )
  const shipFromOptions = useSelector((state) =>
    shipFromOptionsSelector(state, {labelInfoID}),
  )
  const orderWarehouseID = useSelector((state) =>
    orderNumber ? orderWarehouseIDSelector(state, {orderNumber}) : null,
  )
  const orderSupplierID = useSelector((state) =>
    orderNumber ? orderSupplierIDSelector(state, {orderNumber}) : null,
  )
  const alternateShipFromAddress = useSelector((state) =>
    orderNumber
      ? orderAlternateShipFromAddressSelector(state, {orderNumber})
      : null,
  )
  const orderType = useSelector((state) =>
    orderTypeSelector(state, {labelInfoID}),
  )
  const labelShipToAddress = useSelector((state) =>
    labelShipToAddressSelector(state, {labelInfoID}),
  )
  const referenceID = useSelector((state) =>
    referenceIDFromLabelConfigSelector(state, {labelInfoID}),
  )

  if (isLabelEnableMode) {
    return null
  }

  if (orderType === OT_RETURN_ORDER) {
    return (
      <div className="divider--top divider--bottom">
        <label
          className="label--bold lh-sm margin-bottom-3"
          htmlFor="return_to"
        >
          Return To
        </label>
        <div className="fs-n0 lh-sm margin-bottom-3">
          {labelShipToAddress ? (
            labelShipToAddress.company || labelShipToAddress.name
          ) : (
            <em>No address specified</em>
          )}
        </div>
        <button
          id="return_to"
          className="btn btn--link margin-bottom-0 fs-n1 block"
          type="button"
          onClick={() =>
            showRMAReturnToAddressModal(referenceID, labelShipToAddress)
          }
        >
          Edit
        </button>
      </div>
    )
  }

  if (orderType === OT_V3_ORDER && alternateShipFromAddress) {
    return (
      <div className="divider--top divider--bottom">
        <label
          className="label--bold lh-sm margin-bottom-3"
          htmlFor="alternate_ship_from"
        >
          Ship From
        </label>
        <div className="fs-n0 lh-sm margin-bottom-3">
          {alternateShipFromAddress ? (
            alternateShipFromAddress.company || alternateShipFromAddress.name
          ) : (
            <em>No address specified</em>
          )}
        </div>
        <button
          id="alternate_ship_from"
          className="btn btn--link margin-bottom-0 fs-n1 block"
          type="button"
          onClick={() =>
            showAlternateShipFromAddressModal(
              orderNumber,
              alternateShipFromAddress,
            )
          }
        >
          Edit
        </button>
      </div>
    )
  }

  if (shipFromOptions.length < 2) {
    return null
  }

  const value = formatShipFromValue(
    do_not_ship_from_supplier,
    orderSupplierID,
    orderWarehouseID,
  )

  return (
    <li className="list__item--shipping-options list__item--shipping-options-select">
      <Select
        className="w-100"
        labelClassName="label--bold"
        label={labelType === 'return' ? 'Return To' : 'Ship From'}
        id="ship_from"
        onChange={(value) => {
          const {warehouse_id, supplier_id} = getShipFromFromValue(value)

          updateLabelConfig(labelInfoID, {
            do_not_ship_from_supplier: !supplier_id,
          })

          applyShipFrom(labelInfoID, warehouse_id)
        }}
        options={shipFromOptions}
        value={value}
      />
    </li>
  )
}
