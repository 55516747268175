import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {loadCompany, apiLockedSelector} from '../data/company.js'
import {loadMe, isSuperuserSelector} from '../data/me.js'
import {getInitialData} from '../redux/actions/data/index.js'
import {
  showMainSelector,
  isStartedSelector,
  currentPageSelector,
} from '../redux/selectors/ui/index.js'

import WebSockets from '../common/components/WebSockets.js'
import CurrentDropdownListener from '../common/components/CurrentDropdownListener.js'
import PrintNodeListener from '../common/components/PrintNodeListener.js'
import XHRListener from '../common/components/XHRListener.js'
import RunningTasksListener from '../common/components/RunningTasksListener.js'
import Zuck from '../common/components/Zuck.js'
import CreateOrderLocationListener from './CreateOrderPage/CreateOrderLocationListener.js'
import OrderDetailLocationListener from './OrderDetailPage/OrderDetailLocationListener.js'
import CommonAppMessageListener from '../common/components/CommonAppMessageListener.js'
import HotKeys from '../common/components/HotKeys.js'
import ProductListLocationListener from './ProductListPage/ProductListLocationListener.js'
import PODetailLocationListener from './PurchaseOrderDetailPage/PODetailLocationListener.js'
import POListLocationListener from './PurchaseOrderListPage/POListLocationListener.js'
import ReportsPageLocationListener from './ReportsPage/ReportsPageLocationListener.js'
import ReturnOrderListLocationListener from './ReturnOrderListPage/ReturnOrderListLocationListener.js'
import ReturnOrderDetailLocationListener from './ReturnOrderDetailPage/ReturnOrderDetailLocationListener.js'
import MockAbodeLocationListener from './MockAbodePage/MockAbodeLocationListener.js'
import CurrentDateListener from './CurrentDateListener.js'
import MOListLocationListener from './MOListPage/MOListLocationListener.js'
import MODetailLocationListener from './MODetailPage/MODetailLocationListener.js'
import AnalyticsLocationListener from './AnalyticsPage/AnalyticsLocationListener.js'
import BatchListLocationListener from './BatchListPage/BatchListLocationListener.js'

import Header from './Header/index.js'
import ProgressIndicator from './Header/ProgressIndicator.js'
import AppHashListener from './AppHashListener.js'
import Modals from './Modals/index.js'
import Main from './Main.js'
import GlobalErrorMessage from './GlobalErrorMessage.js'
import QueryCall from '../common/components/QueryCall.js'
import OrderListLocationListener from './OrderListPage/OrderListLocationListener.js'
import {CALL_UP_PAGE} from '../common/constants/Pages.js'
import CallUpPage from './CallUpPage/index.js'
import CallUpLocationListener from './CallUpPage/CallUpLocationListener.js'
import Theme from '../common/components/Theme.js'
import DashboardLocationListener from './DashboardPage/DashboardLocationListener.js'
import WelcomeLocationListener from './WelcomePage/WelcomeLocationListener.js'

export class App extends Component {
  static propTypes = {
    apiLocked: PropTypes.bool.isRequired,
    showMain: PropTypes.bool.isRequired,
    isStarted: PropTypes.bool.isRequired,
    getInitialData: PropTypes.func.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    currentPage: PropTypes.string.isRequired,
  }

  componentDidMount() {
    loadCompany()
    loadMe()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isStarted && this.props.isStarted) {
      this.props.getInitialData()
    }
  }

  render() {
    const {apiLocked, showMain, currentPage} = this.props

    if (apiLocked) {
      return (
        <div className="app">
          <GlobalErrorMessage />
          <Modals />
          <WebSockets />
        </div>
      )
    }

    if (currentPage === CALL_UP_PAGE) {
      return (
        <div className="app">
          <WebSockets />
          <CallUpLocationListener />
          <CallUpPage />
        </div>
      )
    }

    return (
      <div className="app">
        <QueryCall />
        <WebSockets />
        <CommonAppMessageListener />
        <WelcomeLocationListener />
        <DashboardLocationListener />
        <CreateOrderLocationListener />
        <AppHashListener />
        <AnalyticsLocationListener />
        <OrderDetailLocationListener />
        <ProductListLocationListener />
        <POListLocationListener />
        <PODetailLocationListener />
        <ReportsPageLocationListener />
        <ReturnOrderListLocationListener />
        <ReturnOrderDetailLocationListener />
        <MOListLocationListener />
        <MODetailLocationListener />
        <BatchListLocationListener />
        <OrderListLocationListener />
        <MockAbodeLocationListener />
        <HotKeys />
        <CurrentDropdownListener />
        <CurrentDateListener />
        <PrintNodeListener />
        <XHRListener />
        <RunningTasksListener />
        <GlobalErrorMessage />
        <Modals />
        <Zuck />
        <Theme />
        <div className="wrap--header">
          <Header />
          <ProgressIndicator />
        </div>
        {showMain && <Main />}
      </div>
    )
  }
}

function mapStateToProp(state) {
  return {
    apiLocked: apiLockedSelector(state),
    showMain: showMainSelector(state),
    isStarted: isStartedSelector(state),
    isSuperuser: isSuperuserSelector(state),
    currentPage: currentPageSelector(state),
  }
}

const mapDispatchToProps = {
  getInitialData,
}

export default connect(mapStateToProp, mapDispatchToProps)(App)
