import PropTypes from 'prop-types'

export default function Filters({children}) {
  return (
    <div className="row">
      <div className="medium-12 columns">
        <ul className="list--analytics-filters flex--justify">{children}</ul>
      </div>
    </div>
  )
}

Filters.propTypes = {
  children: PropTypes.node.isRequired,
}
