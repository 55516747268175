import isEmpty from 'lodash/isEmpty.js'

import {isPresent, isNumeric} from '../../../../../common/utils.js'
import apiverson from '../../../../../common/apiverson.js'
import {refreshOrderListAndCounts} from '../../../../../ordoro/OrderListPage/orderListActions.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'
import baseStrategy from './baseStrategy.js'

const HEADER = [
  'Order ID *Req',
  'Order Date',
  'Notes from Customer',
  'Grand Total',
  'Product Amount',
  'Tax Amount',
  'Discount Amount',
  'Shipping Amount',
  'Shipping Type',
  'Ship to Name',
  'Ship to Company',
  'Ship to Email',
  'Ship to Phone',
  'Ship to Street 1',
  'Ship to Street 2',
  'Ship to City',
  'Ship to State',
  'Ship to Zipcode',
  'Ship to Country',
  'Bill to Name',
  'Bill to Company',
  'Bill to Email',
  'Bill to Phone',
  'Bill to Street 1',
  'Bill to Street 2',
  'Bill to City',
  'Bill to State',
  'Bill to Zipcode',
  'Bill to Country',
  'Line Product SKU *Req',
  'Line Product Name *Req',
  'Line Item Price',
  'Line Quantity *Req',
  'Line Notes',
  'Line Serial Numbers',
  'Line Discount Amount',
  'Line Total Price',
]

export default class orderStrategy extends baseStrategy {
  static title = 'Create Orders'
  static requiredColumns = [
    'order_id',
    'product_sku',
    'product_name',
    'quantity',
  ]

  static getExampleRows() {
    return () => {
      return apiverson
        .get('/order', {limit: 5})
        .then(({json: data}) => this.handleExampleRows(data))
    }
  }

  static addAddress(address = {}) {
    return [
      address.name,
      address.company,
      address.email,
      address.phone,
      address.street1,
      address.street2,
      address.city,
      address.state,
      address.zip,
      address.country,
    ]
  }

  static handleExampleRows(data) {
    const rows = [[...HEADER]]

    function expandBaseForEachLine(row, lines) {
      return lines.map((line) => [
        ...row,
        line.sku,
        line.product_name,
        line.item_price,
        line.quantity,
        line.details,
        line.product_serial_numbers.join(' '),
        line.discount_amount,
        line.item_price * line.quantity,
      ])
    }

    data.order.forEach((order) => {
      const base = [
        order.order_number,
        order.order_placed_date,
        order.notes_from_customer,
        order.financial.grand_total,
        order.financial.product_amount,
        order.financial.tax_amount,
        order.financial.discount_amount,
        order.financial.shipping_amount,
        order.requested_shipping_method,
        ...this.addAddress(order.shipping_address),
        ...this.addAddress(order.billing_address),
      ]

      rows.push(...expandBaseForEachLine(base, order.lines))
    })

    return rows
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      order_id: find(/order.id/i),
      order_date: find(/order.date/i),
      notes_from_customer: find(/notes.from.customer/i),
      grand_total: find(/grand.total/i),
      product_amount: find(/product.amount/i),
      tax_amount: find(/tax.amount/i),
      discount_amount: find(/discount.amount/i),
      shipping_amount: find(/ship.*amount/i),
      shipping_type: find(/ship.*type/i),
      ship_name: find(/ship.*name/i),
      ship_company: find(/ship.*company/i),
      ship_phone: find(/ship.*phone/i),
      ship_email: find(/ship.*email/i),
      ship_street1: find(/ship.*street?.1/i),
      ship_street2: find(/ship.*street?.2/i),
      ship_city: find(/ship.*city/i),
      ship_state: find(/ship.*state/i),
      ship_zip: find(/ship.*zip/i),
      ship_country: find(/ship.*country/i),
      bill_name: find(/bill.*name/i),
      bill_company: find(/bill.*company/i),
      bill_phone: find(/bill.*phone/i),
      bill_email: find(/bill.*mail/i),
      bill_street1: find(/bill.*street?.1/i),
      bill_street2: find(/bill.*street?.2/i),
      bill_city: find(/bill.*city/i),
      bill_state: find(/bill.*state/i),
      bill_zip: find(/bill.*zip/i),
      bill_country: find(/bill.*country/i),
      product_sku: find(/sku/i),
      product_name: find(/product.name/i),
      item_price: find(/item.price/i),
      quantity: find(/quantity/i),
      selected_option: find(/line.notes/i),
      product_serial_numbers: find(/(product.)?serial.number/i),
      line_discount_amount: find(/line.discount/i),
      total_price: find(/total.price/i),
      cart_orderitem_id: find(/^cart_orderitem_id$/i),
      cart_id: find(/^cart_id$/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return () => {
      const dataByID = {}

      for (let i = 0; i < rows.length; i++) {
        const data = this.transformRowToData(rows[i], columnsToDataMap)
        const orderID = data.payload.order_id

        dataByID[orderID] = dataByID[orderID] || []

        dataByID[orderID].push(data)
      }

      return Object.keys(dataByID).map((orderID, index) => {
        const dataSet = dataByID[orderID]
        const data = dataSet[0]
        data.index = index

        data.payload.lines = dataSet.map(({payload}) => payload.lines[0])

        data.payload.grand_total = orderStrategy.calculateGrandTotal(data)
        data.payload.product_amount = orderStrategy.calculateProductAmount(data)

        data.errors = this.determineDataErrors(data)

        return data
      })
    }
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()

    data.payload = {
      order_id: row[columnsByName.order_id],
      cart_id: row[columnsByName.cart_id],
      order_date: row[columnsByName.order_date],
      notes_from_customer: row[columnsByName.notes_from_customer],
      grand_total: row[columnsByName.grand_total],
      product_amount: row[columnsByName.product_amount],
      tax_amount: row[columnsByName.tax_amount],
      discount_amount: row[columnsByName.discount_amount],
      shipping_amount: row[columnsByName.shipping_amount],
      shipping_type: row[columnsByName.shipping_type],
      shipping_address: {
        name: row[columnsByName.ship_name],
        company: row[columnsByName.ship_company],
        email: row[columnsByName.ship_email],
        phone: row[columnsByName.ship_phone],
        street1: row[columnsByName.ship_street1],
        street2: row[columnsByName.ship_street2],
        city: row[columnsByName.ship_city],
        state: row[columnsByName.ship_state],
        zip: row[columnsByName.ship_zip],
        country: row[columnsByName.ship_country],
      },
      billing_address: {
        name: row[columnsByName.bill_name],
        company: row[columnsByName.bill_company],
        email: row[columnsByName.bill_email],
        phone: row[columnsByName.bill_phone],
        street1: row[columnsByName.bill_street1],
        street2: row[columnsByName.bill_street2],
        city: row[columnsByName.bill_city],
        state: row[columnsByName.bill_state],
        zip: row[columnsByName.bill_zip],
        country: row[columnsByName.bill_country],
      },
      lines: [
        {
          product_sku: row[columnsByName.product_sku],
          product_name: row[columnsByName.product_name],
          item_price: row[columnsByName.item_price],
          quantity: row[columnsByName.quantity],
          selected_option: row[columnsByName.selected_option],
          product_serial_numbers: (
            row[columnsByName.product_serial_numbers] || ''
          )
            .split(/[ ]+/)
            .filter((v) => v)
            .join('\n'),
          discount_amount: row[columnsByName.line_discount_amount],
          total_price: row[columnsByName.total_price],
          cart_orderitem_id: row[columnsByName.cart_orderitem_id],
        },
      ],
    }

    return data
  }

  static calculateGrandTotal(data) {
    const {payload} = data

    if (isNumeric(payload.grand_total)) {
      return payload.grand_total
    }

    const taxAmount = isNumeric(payload.tax_amount)
      ? Number(payload.tax_amount)
      : 0
    const discountAmount = isNumeric(payload.discount_amount)
      ? Number(payload.discount_amount)
      : 0
    const shippingAmount = isNumeric(payload.shipping_amount)
      ? Number(payload.shipping_amount)
      : 0
    const productTotal = Number(orderStrategy.calculateProductAmount(data))

    return `${taxAmount + -discountAmount + shippingAmount + productTotal}`
  }

  static calculateProductAmount(data) {
    const {payload} = data

    if (isNumeric(payload.product_amount)) {
      return payload.product_amount
    }

    return payload.lines.reduce(
      (prev, line) =>
        prev +
        (isNumeric(line.quantity) ? Number(line.quantity) : 0) *
          (isNumeric(line.item_price) ? Number(line.item_price) : 0) -
        (isNumeric(line.discount_amount) ? Number(line.discount_amount) : 0),
      0,
    )
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {}

    if (data.errors.server) {
      errors.server = data.errors.server
    }

    this.required(payload, errors, 'order_id', 'Order ID is required')

    if (
      isPresent(payload.product_amount) &&
      !isNumeric(payload.product_amount)
    ) {
      errors.product_amount = 'Amount must be a number'
    }

    if (isPresent(payload.grand_total) && !isNumeric(payload.grand_total)) {
      errors.grand_total = 'Total must be a number'
    }

    if (isPresent(payload.tax_amount) && !isNumeric(payload.tax_amount)) {
      errors.tax_amount = 'Amount must be a number'
    }

    if (
      isPresent(payload.shipping_amount) &&
      !isNumeric(payload.shipping_amount)
    ) {
      errors.shipping_amount = 'Amount must be a number'
    }

    if (
      isPresent(payload.discount_amount) &&
      !isNumeric(payload.discount_amount)
    ) {
      errors.discount_amount = 'Amount must be a number'
    }

    const lineErrors = payload.lines.reduce((_lineErrors, line, index) => {
      const errs = {}

      if (!isPresent(line.product_sku)) {
        errs.product_sku = 'Product SKU is required'
      }

      if (!isPresent(line.product_name)) {
        errs.product_name = 'Product Name is required'
      }

      if (!isNumeric(line.quantity)) {
        errs.quantity = 'Quantity must be a number'
      }

      if (isPresent(line.item_price) && !isNumeric(line.item_price)) {
        errs.item_price = 'Price must be a number'
      }

      if (isPresent(line.discount_amount) && !isNumeric(line.discount_amount)) {
        errs.discount_amount = 'Discount must be a number'
      }

      if (isPresent(line.total_price) && !isNumeric(line.total_price)) {
        errs.total_price = 'Total must be a number'
      }

      if (!isEmpty(errs)) {
        _lineErrors[index] = errs
      }

      return _lineErrors
    }, {})

    if (!isEmpty(lineErrors)) {
      errors.lines = lineErrors
    } else {
      delete errors.lines
    }

    return errors
  }

  static onBlur(index, key) {
    return (dispatch, getState) => {
      const data = getState().ui.dataImport.data[index]
      const updates = {}

      if (key === 'grand_total') {
        updates.grand_total = orderStrategy.calculateGrandTotal(data)
      }

      if (key === 'product_amount') {
        updates.product_amount = orderStrategy.calculateProductAmount(data)
      }

      dispatch(orderStrategy.updatePayload(index, updates))
    }
  }

  static save(data) {
    return async (dispatch, getState) => {
      const {
        ui: {
          dataImport: {columnsToDataMap},
        },
      } = getState()
      const order = {
        order_id: data.payload.order_id,
        order_date: data.payload.order_date,
        notes_from_customer: data.payload.notes_from_customer,
        shipping_type: data.payload.shipping_type,
        shipping_address: data.payload.shipping_address,
        billing_address: data.payload.billing_address,
      }

      if (data.payload.cart_id) {
        order.cart = data.payload.cart_id
      }

      if (data.payload.product_amount) {
        order.product_amount = data.payload.product_amount
      }

      if (data.payload.grand_total) {
        order.grand_total = data.payload.grand_total
      }

      if (data.payload.tax_amount) {
        order.tax_amount = data.payload.tax_amount
      }

      if (data.payload.shipping_amount) {
        order.shipping_amount = data.payload.shipping_amount
      }

      if (data.payload.discount_amount) {
        order.discount_amount = data.payload.discount_amount
      }

      order.lines = data.payload.lines.map((line) => {
        const newLine = {
          product: {
            sku: line.product_sku,
            name: line.product_name,
          },
          quantity: line.quantity,
          selected_option: line.selected_option,
          product_serial_numbers: (line.product_serial_numbers || '')
            .split(/[ \n\t]+/)
            .filter((v) => v),
        }

        if (line.item_price) {
          newLine.item_price = line.item_price
        }

        if (line.discount_amount) {
          newLine.discount_amount = line.discount_amount
        }

        if (line.total_price) {
          newLine.total_price = line.total_price
        }

        if (line.cart_orderitem_id) {
          newLine.cart_orderitem_id = line.cart_orderitem_id
        }

        return newLine
      })

      const has_orderitem_id = columnsToDataMap.cart_orderitem_id !== -1

      try {
        return await apiverson.post('/order', order)
      } catch (err) {
        if (err.error_message.match(/already exists$/) && has_orderitem_id) {
          // each the error
        } else {
          throw err
        }
      }
    }
  }

  static afterSave() {
    return () => {
      refreshOrderListAndCounts()

      return Promise.resolve()
    }
  }
}
