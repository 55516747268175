import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {
  CANADA_POST,
  ENDICIA,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [
  CANADA_POST,
  ENDICIA,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__nondelivery_option`]
    : []
}

export default function NondeliveryOption() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="Nondelivery Option"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      required={CANADA_POST === shipperType}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty="nondelivery"
    />
  )
}
