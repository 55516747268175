import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

export default class Row extends Component {
  render() {
    const className = classNames('form-list-item row', this.props.className)

    return <li className={className}>{this.props.children}</li>
  }
}

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
