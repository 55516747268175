import {
  activeSupplierOptionTokensSelector,
  activeSupplierOptionsSelector,
  useSuppliersSelector,
} from '../../../../data/suppliers.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  modalFormSelector,
  SUPPLIER_FILTER_PANEL,
  updateModalForm,
} from './productListFilterModalFunctions.js'

export default function SupplierFilterPanel() {
  const form = useSelector(modalFormSelector)
  const supplierOptions = useSelector(activeSupplierOptionsSelector)
  const supplierOptionTokens = useSelector(activeSupplierOptionTokensSelector)
  const useSuppliers = useSelector(useSuppliersSelector)

  if (!(useSuppliers && supplierOptions.length > 0)) {
    return null
  }

  return (
    <FilterPanel
      id={SUPPLIER_FILTER_PANEL}
      label="Suppliers"
      searchPlaceholder="Search for a supplier filter..."
      options={supplierOptions}
      optionTokens={supplierOptionTokens}
      selected={form.supplierID}
      onSelect={(option) => {
        const supplierID = form.supplierID.filter((id) => id !== option.value)

        if (supplierID.length === form.supplierID.length) {
          supplierID.push(option.value)
        }

        updateModalForm({supplierID})
      }}
    />
  )
}
