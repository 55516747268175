import PropTypes from 'prop-types'

import {formSelector, useSelector} from '../../../store.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'

export const NO_SHIPPER = 'No Shipper'

export function setupExportPOGoodsReceiptsForm(params = {}) {
  return {
    ...params,
  }
}

export function exportPOGoodsReceiptsErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportPOGoodsReceiptsPayloadSelector(state, {formName}) {
  const {reportType} = formSelector(state, {formName})

  const payload = {
    type: reportType,
    params: {
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }

  return payload
}

export default function ExportPOGoodsReceiptsForm({formName}) {
  const errors = useSelector((state) =>
    exportPOGoodsReceiptsErrorsSelector(state, {formName}),
  )

  return (
    <ul className="list list--no-style">
      <DateFilters formName={formName} errors={errors} dividerButton={false} />
    </ul>
  )
}

ExportPOGoodsReceiptsForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
