import {useEffect} from 'react'
import addBusinessDays from 'date-fns/addBusinessDays'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import cloneDeep from 'lodash/cloneDeep.js'

import {
  formsSelector,
  getState,
  removeForm,
  setForm,
  updateForm,
  useForm,
  useSelector,
} from '../../../../../store.js'
import {isAddressComplete, isPresent} from '../../../../../common/utils.js'
import {isEmailAddress} from '../../../../../common/email.js'
import {parseAnyTime} from '../../../../../common/formatTime.js'
import RowAddress from '../../../../../common/components/List/RowAddress.js'
import ButtonPrimary from '../../../../../common/components/Button/ButtonPrimary.js'
import InvoiceForm from './InvoiceForm.js'
import {shipperSelector} from '../../../../../data/shippers.js'
import {
  companyContactSelector,
  companyNameSelector,
  useBYOAShippersSelector,
  useOrdoroShippersSelector,
} from '../../../../../data/company.js'
import {
  defaultWarehouseSelector,
  warehouseAddressSelector,
} from '../../../../../data/warehouses.js'
import {Address, pickAddress} from '../../../../../common/address.js'
import {getRealDate} from '../../../../../common/date.js'
import {usePostMessage} from '../../../../../common/components/PostMessage.js'
import TextInput from '../../../../../common/components/TextInput.js'
import loadScript from '../../../../../common/loadScript.js'
import {stringifyURL} from '../../../../../common/querystring.js'
import {callParentContext} from '../../../../CallUpPage/index.js'
import {SHIP_APP_PANEL} from '../../../../Modals/UpgradeModal.js'

export const UPS_AUTH_FORM = 'UPS_AUTH_FORM'

export function setupUPSAuthForm(context = {}) {
  const {id: shipperID} = context

  const shipper = shipperSelector(getState(), {shipperID}) || {}
  const vendorConfig = shipper.vendor_config || {}
  const companyName = companyNameSelector(getState())
  const contact = companyContactSelector(getState())
  const defaultWarehouse = defaultWarehouseSelector(getState()) || {}
  const useOrdoroShippers = useOrdoroShippersSelector(getState())
  const [given_name, ...restName] = contact.split(' ')

  const address = pickAddress({
    ...new Address(),
    ...(defaultWarehouse
      ? warehouseAddressSelector(getState(), {warehouseID: defaultWarehouse.id})
      : undefined),
    name: contact,
    given_name,
    family_name: restName.join(' '),
    company: companyName,
  })

  address.title = 'Mx.'
  address.email = vendorConfig.billing_email || ''

  const pickupInfoStartDate = startOfDay(addBusinessDays(getRealDate(), 3))
  const pickupInfoDayOfWeek = {1: false, 2: false, 3: false, 4: false, 5: false}
  pickupInfoDayOfWeek[pickupInfoStartDate.getDay()] = true

  setForm(UPS_AUTH_FORM, {
    id: shipperID,
    existingAccount: !!shipperID || !useOrdoroShippers,
    username: '',
    password: '',
    address,
    invoiceNumber: '',
    invoiceDate: null,
    controlID: '',
    invoiceAmount: '',
    hasReceivedInvoice: false,
    pickupInfoCode: '08',
    pickupInfoLocation: 'Front Door',
    pickupInfoEarliestTime: '8:00 AM',
    pickupInfoPreferredTime: '12:00 PM',
    pickupInfoLatestTime: '4:00 PM',
    pickupInfoStartDate,
    pickupInfoDayOfWeek,
    pickupInfoSameAsBilling: true,
    pickupInfoAddress: cloneDeep(address),
    hasAgreedToProhibitedItems: false,
    hasAgreedToTAndC: false,
    isBusinessAccount: true,
    createUPSProfile: !shipperID,
    ...context,
    threatMetrixID: null,
    shipperNumber: vendorConfig.shipper_number || '',
    uses_discounted_rates: !!vendorConfig.uses_discounted_rates,
  })
}

export function updateUPSAuthForm(updates, meta) {
  updateForm(UPS_AUTH_FORM, updates, meta)
}

export function removeUPSAuthForm() {
  removeForm(UPS_AUTH_FORM)
}

export function upsAuthFormSelector(state) {
  return formsSelector(state)[UPS_AUTH_FORM]
}

export function errorsSelector(state) {
  const form = upsAuthFormSelector(state)

  const errors = {}

  if (!form) {
    return errors
  }

  const {
    existingAccount,
    shipperNumber,
    uses_discounted_rates,
    username,
    password,
    address,
    hasReceivedInvoice,
    invoiceNumber,
    invoiceDate,
    invoiceAmount,
    hasAgreedToProhibitedItems,
    hasAgreedToTAndC,
    pickupInfoCode,
    pickupInfoEarliestTime,
    pickupInfoPreferredTime,
    pickupInfoLatestTime,
    pickupInfoStartDate,
    pickupInfoDayOfWeek,
    pickupInfoSameAsBilling,
    pickupInfoAddress,
    createUPSProfile,
  } = form

  if (existingAccount && !uses_discounted_rates && !isPresent(shipperNumber)) {
    errors.shipperNumber = 'Account Number is required'
  }

  if (createUPSProfile && !isPresent(username)) {
    errors.username = 'Unique username is required'
  }

  if (createUPSProfile && !isPresent(password)) {
    errors.password = 'A password is required'
  }

  if (
    !isAddressComplete(address, {
      company: true,
      phone: true,
      title: true,
      name: false,
      given_name: true,
      family_name: true,
    })
  ) {
    errors.address = 'Address is incomplete'
  }

  if (!isEmailAddress(address.email)) {
    errors.address_email = 'Billing email is required'
  }

  if (hasReceivedInvoice) {
    if (!isPresent(invoiceNumber)) {
      errors.invoiceNumber = 'Invoice Number is required'
    }

    if (!invoiceDate) {
      errors.invoiceDate = 'Invoice Date is required'
    }

    if (!isPresent(invoiceAmount)) {
      errors.invoiceAmount = 'Total charges are required'
    }
  }

  if (!existingAccount && !hasAgreedToProhibitedItems) {
    errors.hasAgreedToProhibitedItems =
      'Need to acknowledge list of prohibited items'
  }

  if (!existingAccount && !hasAgreedToTAndC) {
    errors.hasAgreedToTAndC = 'Need to agree to Ground Saver T&C'
  }

  if (!existingAccount && ['01', '02', '07', '99'].includes(pickupInfoCode)) {
    const earliest = parseAnyTime(pickupInfoEarliestTime)
    const preferred = parseAnyTime(pickupInfoPreferredTime)
    const latest = parseAnyTime(pickupInfoLatestTime)

    if (earliest.totalSeconds >= latest.totalSeconds) {
      errors.pickupInfoEarliestTime = 'Must be before Latest Time'
    }

    if (preferred.totalSeconds >= latest.totalSeconds) {
      errors.pickupInfoPreferredTime = 'Must be before Latest Time'
    }

    if (earliest.totalSeconds >= preferred.totalSeconds) {
      errors.pickupInfoEarliestTime = 'Must be before Preferred Time'
    }

    if (!pickupInfoStartDate) {
      errors.pickupInfoStartDate = 'Pickup Start Date is required'
    } else if (
      isBefore(pickupInfoStartDate, addBusinessDays(startOfDay(new Date()), 3))
    ) {
      errors.pickupInfoStartDate = 'Date must be 3 business days from now'
    } else if (pickupInfoCode === '99') {
      const startDayOfWeek = pickupInfoStartDate.getDay()

      if (!pickupInfoDayOfWeek[startDayOfWeek]) {
        errors.pickupInfoStartDate = 'Date must be on a pickup day'
      }
    }

    if (
      pickupInfoCode === '99' &&
      Object.values(pickupInfoDayOfWeek).filter((v) => v).length > 4
    ) {
      errors.pickupInfoDayOfWeek = 'Pick 4 or less days'
    }

    if (
      !pickupInfoSameAsBilling &&
      !isAddressComplete(pickupInfoAddress, {
        company: true,
        email: true,
        phone: true,
        title: true,
      })
    ) {
      errors.pickupInfoAddress = 'Address is required'
    }

    if (!['US'].includes(pickupInfoAddress.country)) {
      errors.pickupInfoAddress = 'US addresses only'
    }
  }

  if (!existingAccount) {
    if (!['US'].includes(address.country)) {
      errors.address = 'US addresses only'
    }
  }

  if (Object.keys(errors).length) {
    errors.preventSave = true
  }

  return errors
}

const THREAT_METRIX_CHAR_SET =
  '1234567890abcdefghijklmnopqrstuvABCDEFGHIJKLMNOPQRSTUVWXYZ'

function generateThreatMetrixID() {
  return (
    'ordoro_' +
    Array(20)
      .fill(1)
      .map(
        () =>
          THREAT_METRIX_CHAR_SET[
            Math.floor(Math.random() * THREAT_METRIX_CHAR_SET.length)
          ],
      )
      .join('')
  )
}

async function requestLexisNexisThreatMetrix() {
  const threatMetrixID = generateThreatMetrixID()

  try {
    await loadScript(
      stringifyURL('https://linetime.ordoro.com/fp/tags.js', {
        org_id: '13slif0w',
        session_id: threatMetrixID,
        page_id: 5,
      }),
    )
  } finally {
    updateUPSAuthForm({threatMetrixID})
  }
}

function onMessage(event) {
  if (!event.data || !event.data.func) {
    return
  }

  const {func, args = []} = event.data

  if (func === 'updateUPSAuthForm') {
    updateUPSAuthForm(...args)
  } else if (func === 'setupUPSAuthForm') {
    setupUPSAuthForm(...args)
  } else if (func === 'sendContextForRegister') {
    sendContextForRegister()
  }
}

function sendContextForRegister() {
  const registerContext = upsAuthFormSelector(getState())

  callParentContext('receiveRegisterContext', [registerContext])
}

export default function UPSAuthForm() {
  const form = useForm(UPS_AUTH_FORM) // form will not exist initially
  const errors = useSelector(errorsSelector)
  const useOrdoroShippers = useSelector(useOrdoroShippersSelector)
  const useBYOAShippers = useSelector(useBYOAShippersSelector)
  const existingAccount = !!(form && form.existingAccount)
  const needsToCreateDAPShipperNumber =
    !!form && form.uses_discounted_rates && form.id && !form.shipperNumber
  const showAccountSetup =
    !(form && form.createUPSProfile) || (useOrdoroShippers && !useBYOAShippers)

  usePostMessage(onMessage, window.top)

  useEffect(() => {
    if (!form || (form.id && !needsToCreateDAPShipperNumber)) {
      return
    }

    requestLexisNexisThreatMetrix()
  }, [!!form, needsToCreateDAPShipperNumber])

  useEffect(() => {
    callParentContext('updateUPSRegistrationModal', [
      {preventSave: !!errors.preventSave},
    ])
  }, [errors.preventSave])

  useEffect(() => {
    callParentContext('updateUPSRegistrationModal', [{existingAccount}])
  }, [existingAccount])

  if (!form) {
    return null
  }

  return (
    <>
      <div className="medium-6 columns">
        <fieldset>
          <div className="form-list margin-bottom-0">
            {form.serverError && (
              <div className="error margin-bottom-20">{form.serverError}</div>
            )}
            {needsToCreateDAPShipperNumber && (
              <div className="alert alert--standard align-center margin-top-15">
                <p className="fs-00 lh-md margin-bottom-0">
                  <strong>You’re almost done!</strong>
                </p>
              </div>
            )}
            {!form.id && (
              <div className="form-list-item bigger margin-bottom-20">
                <div className="margin-bottom-20">
                  <label htmlFor="create_ups_profile">UPS Profile Status</label>
                  <p className="fs-n0 lh-md margin-bottom-0">
                    An active UPS Profile is required for Ordoro to create and
                    connect UPS shipping accounts.{' '}
                  </p>
                  <div className="flex wrap--label-unbold margin-top-7">
                    <div className="margin-right-25">
                      <label
                        className="flex align-items-center"
                        htmlFor="create_ups_profile_yes"
                      >
                        <input
                          type="radio"
                          className="margin-bottom-0 margin-right-5 v-align-middle"
                          id="create_ups_profile_yes"
                          name="create_ups_profile"
                          checked={form.createUPSProfile}
                          onChange={() =>
                            updateUPSAuthForm({createUPSProfile: true})
                          }
                        />
                        <span className="v-align-middle">
                          I need to create one
                        </span>
                      </label>
                    </div>
                    <div>
                      <label
                        className="flex align-items-center"
                        htmlFor="create_ups_profile_no"
                      >
                        <input
                          type="radio"
                          className="margin-bottom-0 margin-right-5 v-align-middle"
                          id="create_ups_profile_no"
                          name="create_ups_profile"
                          checked={!form.createUPSProfile}
                          onChange={() =>
                            updateUPSAuthForm({createUPSProfile: false})
                          }
                        />
                        <span className="v-align-middle">
                          I already have one
                        </span>
                      </label>
                    </div>
                  </div>

                  {form.createUPSProfile && (
                    <div className="alert alert--warning-lt alert--lg margin-top-25 margin-bottom-15 align-center">
                      <p className="fs-01 lh-md margin-bottom-15">
                        <strong>
                          Please visit the UPS Profile creation page to get your
                          profile set up.
                        </strong>
                      </p>
                      <p className="fs-00 lh-md margin-bottom-20">
                        {' '}
                        After doing that, come back to Ordoro to set up your UPS
                        shipping account.
                      </p>
                      <div className="margin-bottom-0">
                        <a
                          className="btn btn--primary btn--sm"
                          href="https://www.ups.com/doapp/signup?loc=en_US"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go create a UPS Profile →
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {useOrdoroShippers && useBYOAShippers ? (
                  <>
                    {!form.createUPSProfile && (
                      <>
                        <div className="margin-bottom-20">
                          <label
                            htmlFor="account_type"
                            className="margin-top-10"
                          >
                            Type of UPS Shipper Account
                          </label>
                          <div className="flex wrap--label-unbold margin-top-7">
                            <div className="margin-right-25">
                              <label
                                className="flex align-items-center"
                                htmlFor="account_type_new"
                              >
                                <input
                                  type="radio"
                                  className="margin-bottom-0 margin-right-5 v-align-middle"
                                  id="account_type_new"
                                  name="account_type"
                                  checked={!existingAccount}
                                  onChange={() =>
                                    updateUPSAuthForm({existingAccount: false})
                                  }
                                />
                                <span className="v-align-middle">
                                  UPS Discounted Rates
                                </span>
                              </label>
                            </div>
                            <div>
                              <label
                                className="flex align-items-center"
                                htmlFor="account_type_existing"
                              >
                                <input
                                  type="radio"
                                  className="margin-bottom-0 margin-right-5 v-align-middle"
                                  id="account_type_existing"
                                  name="account_type"
                                  checked={existingAccount}
                                  onChange={() =>
                                    updateUPSAuthForm({existingAccount: true})
                                  }
                                />
                                <span className="v-align-middle">
                                  UPS Merchant Account
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : !useBYOAShippers ? (
                  <div className="alert alert--warning-lt margin-top-0 margin-bottom-15 align-center">
                    <div
                      className="i-lock-closed fs-02 op-30 lh-sm margin-top-0 margin-bottom-0"
                      aria-hidden="true"
                    />
                    <p className="fs-00 lh-md margin-bottom-15">
                      To be able to{' '}
                      <a
                        className="btn btn--link darker border-underline v-align-base"
                        href="https://support.ordoro.com/setting-up-ups-in-ordoro/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>add your own UPS accounts</span>
                      </a>
                      , you need to upgrade to the{' '}
                      <strong>Premium Plan of the Shipping App</strong>.
                    </p>
                    <div className="margin-bottom-10">
                      <ButtonPrimary
                        size="sm"
                        className="meta--feature-lock-ups-byoa"
                        alt
                        onClick={() =>
                          callParentContext('showUpgradeModal', [
                            {panel: SHIP_APP_PANEL},
                          ])
                        }
                      >
                        Upgrade Your Plan
                      </ButtonPrimary>
                    </div>
                  </div>
                ) : null}
                {!existingAccount && showAccountSetup && (
                  <>
                    <div className="alert alert--standard align-center margin-top-15">
                      <p className="fs-00 lh-md margin-bottom-0">
                        <strong>Open an Account Today and Save!</strong>
                      </p>
                      <p className="fs-n0 lh-md margin-bottom-0">
                        Get discounts on domestic and international shipping,
                        including up to 77% off on UPS ® Ground shipments, up
                        to 72% for UPS 2nd Day Air ® service and UPS Next Day
                        Air ® service, and up to 88% off on international
                        services.
                      </p>
                    </div>
                    <label
                      className="margin-top-15"
                      htmlFor="purpose_of_account"
                    >
                      Purpose of Account
                    </label>
                    <div className="flex wrap--label-unbold margin-top-7">
                      <div className="margin-right-25">
                        <label
                          className="flex align-items-center"
                          htmlFor="purpose_of_account_business"
                        >
                          <input
                            type="radio"
                            className="margin-bottom-0 margin-right-5 v-align-middle"
                            id="purpose_of_account_business"
                            name="purpose_of_account"
                            checked={form.isBusinessAccount}
                            onChange={() =>
                              updateUPSAuthForm({isBusinessAccount: true})
                            }
                          />
                          <span className="v-align-middle">Business</span>
                        </label>
                      </div>
                      <div>
                        <label
                          className="flex align-items-center"
                          htmlFor="purpose_of_account_personal"
                        >
                          <input
                            type="radio"
                            className="margin-bottom-0 margin-right-5 v-align-middle"
                            id="purpose_of_account_personal"
                            name="is_business_account"
                            checked={!form.isBusinessAccount}
                            onChange={() =>
                              updateUPSAuthForm({isBusinessAccount: false})
                            }
                          />
                          <span className="v-align-middle">Personal</span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {existingAccount &&
              !form.uses_discounted_rates &&
              showAccountSetup && (
                <div className="form-list-item bigger margin-bottom-20">
                  <TextInput
                    label="Account Number"
                    id="shipper_number"
                    value={form.shipperNumber}
                    onChange={(shipperNumber) =>
                      updateUPSAuthForm({
                        shipperNumber,
                        hasChanged_shipperNumber: true,
                      })
                    }
                    errorMessage={
                      form.hasChanged_shipperNumber && errors.shipperNumber
                    }
                    required
                  />
                </div>
              )}
            {showAccountSetup && (
              <>
                <div className="divider--top divider--bottom">
                  <dl className="list--order-data list--no-style">
                    <dt className="fs-00">Billing Address for UPS Account</dt>
                    <dd className="list__item--order-data">
                      {form.address.company}
                    </dd>
                    <dd className="list__item--order-data">
                      {form.address.title} {form.address.given_name}{' '}
                      {form.address.family_name}
                    </dd>
                  </dl>
                  <RowAddress address={form.address} noEmail />
                  {errors.address && (
                    <small className="error error-message">
                      {errors.address}
                    </small>
                  )}
                  <div className="margin-bottom-10">
                    <button
                      type="button"
                      className="btn btn--link fs-n1"
                      onClick={() => callParentContext('editAddress', [form])}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="form-list-item bigger divider--top margin-bottom-5">
                    <TextInput
                      label="Billing Email"
                      id="address_email"
                      value={form.address.email}
                      onChange={(email) =>
                        updateUPSAuthForm({
                          address: {...form.address, email},
                          pickupInfoAddress: {
                            ...form.pickupInfoAddress,
                            email,
                          },
                        })
                      }
                      errorMessage={errors.address_email}
                      required
                    />
                  </div>
                </div>
                {existingAccount && (
                  <div className="form-list-item bigger margin-bottom-20">
                    <label htmlFor="name_has_received_invoice">
                      <input
                        type="checkbox"
                        className="margin-bottom-0 margin-right-5"
                        id="name_has_received_invoice"
                        checked={form.hasReceivedInvoice}
                        onChange={(event) =>
                          updateUPSAuthForm({
                            hasReceivedInvoice: event.target.checked,
                          })
                        }
                      />
                      <span>{'I have received an invoice from UPS'}</span>
                    </label>
                  </div>
                )}
                {form.hasReceivedInvoice && <InvoiceForm />}
                {!errors.address && !existingAccount && (
                  <div className="form-list-item bigger margin-bottom-20">
                    <label
                      className="flex align-items-center margin-bottom-20"
                      htmlFor="has_agreed_to_t_and_c"
                    >
                      <input
                        type="checkbox"
                        className="margin-bottom-0 margin-right-5 v-align-middle"
                        id="has_agreed_to_t_and_c"
                        checked={form.hasAgreedToTAndC}
                        onChange={() =>
                          updateUPSAuthForm({
                            hasAgreedToTAndC: !form.hasAgreedToTAndC,
                          })
                        }
                      />
                      <span className="v-align-middle">
                        {'I agree to the '}
                        <button
                          type="button"
                          className="inline-text-button"
                          onClick={() =>
                            window.open(
                              'https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf',
                              '_blank',
                            )
                          }
                        >
                          UPS Ground Saver Agreement
                        </button>
                      </span>
                    </label>
                    <label
                      className="flex align-items-center"
                      htmlFor="name_agreed_to_prohibited_items"
                    >
                      <input
                        type="checkbox"
                        className="margin-bottom-0 margin-right-5 v-align-middle"
                        id="name_agreed_to_prohibited_items"
                        checked={form.hasAgreedToProhibitedItems}
                        onChange={() =>
                          updateUPSAuthForm({
                            hasAgreedToProhibitedItems:
                              !form.hasAgreedToProhibitedItems,
                          })
                        }
                      />
                      <span className="v-align-middle">
                        {'I understand the '}
                        <button
                          type="button"
                          className="inline-text-button"
                          onClick={() =>
                            window.open(
                              'https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
                              '_blank',
                            )
                          }
                        >
                          UPS Prohibited Items List
                        </button>
                      </span>
                    </label>
                  </div>
                )}
                <p className="divider--top fs-n2 lh-md">
                  {!form.id && (
                    <em className="make-block margin-bottom-10">
                      *Discounts off UPS daily rates. Rates are limited to
                      shipping from the U.S. only. Rates and any applicable
                      discounts are subject to change at any time without
                      notice.
                    </em>
                  )}
                  <em>
                    UPS, the UPS brandmark, UPS Ready®, and the color brown are
                    trademarks of United Parcel Service of America, Inc. All
                    Rights Reserved.
                  </em>
                </p>
              </>
            )}
          </div>
        </fieldset>
      </div>

      <div className="medium-5 columns">
        {!form.id && (
          <div className="alert margin-bottom-10 alert--standard">
            <dl className="list">
              <dt className="list__title fs-00 lh-md margin-bottom-5">
                Need help authorizing your UPS account?
              </dt>
              <dd className="list__item fs-n0 margin-bottom-0">
                <a
                  className="btn--link mid meta-ups-setup-help"
                  href={
                    existingAccount
                      ? 'https://support.ordoro.com/setting-up-ups-in-ordoro/'
                      : 'https://support.ordoro.com/how-do-i-set-up-ordoros-discounted-ups-account/'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get step-by-step instructions →
                </a>
              </dd>
            </dl>
          </div>
        )}
        {form.id ? (
          <div className="alert margin-top-20 alert--neutral">
            <dl className="list">
              <dt className="list__title fs-00 lh-md margin-bottom-5">
                Book a Virtual Consultation with a UPS Solutions Expert
              </dt>
              <dd className="list__item fs-n0 margin-bottom-10">
                Get the most out of your UPS account by meeting with one of
                their logistics experts. They’ll analyze your supply chain, tell
                you about new programs, and might be able to offer you even
                better rates for your account.
              </dd>
              <dd className="list__item fs-n0 margin-bottom-0">
                <a
                  className="btn btn--primary btn--primary-ol btn--sm meta-ups-consultation"
                  href="https://solutions.ups.com/virtual-consultation-main-ussp-page.html?WT.mc_id=ONLINE_WEB_Ordoro_Ready%7CMktgVirtualConsultationMainPage_104036"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Schedule a UPS Consultation →</strong>
                </a>
              </dd>
            </dl>
          </div>
        ) : (
          <div className="alert margin-top-20 alert--neutral">
            <p className="fs-00 lh-x-md margin-bottom-15">
              <strong>What happens after I create the UPS account?</strong>
            </p>
            <p className="list__item fs-00 margin-bottom-15">
              Once the account is created, UPS will send an email with your
              account number titled, “Your New UPS Account is Ready to Use.”
              Please note that UPS will bill you weekly for any labels created.
            </p>
            <p className="fs-00 lh-md margin-bottom-10">
              If you need further assistance, reference this handy{' '}
              <a
                href="https://support.ordoro.com/who-are-ordoros-discounted-shipping-carriers/#ordoro-ups"
                rel="noopener noreferrer"
                target="_blank"
              >
                support article{' '}
              </a>
              or contact our{' '}
              <a href="mailto:support@ordoro.com">Support Team</a>.
            </p>
          </div>
        )}
      </div>
    </>
  )
}
