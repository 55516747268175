import PropTypes from 'prop-types'

import ActionSelect from './ActionSelect.js'

export default function AllocateOrder({id, index, isEditing}) {
  if (!isEditing) {
    return (
      <div>
        <strong className="margin-right-5">Allocate Order</strong>
      </div>
    )
  }

  return (
    <div className="inline-block">
      <div className="inline-block v-align-middle margin-bottom-10">
        <ActionSelect ruleID={id} index={index} />
      </div>
    </div>
  )
}

AllocateOrder.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
}
