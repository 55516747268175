import PropTypes from 'prop-types'

import {shipperNameSelector} from '../../../../data/shippers.js'
import {useSelector} from '../../../../store.js'

export default function ShipperOption({shipperID}) {
  const shipperName = useSelector((state) =>
    shipperNameSelector(state, {shipperID}),
  )

  return <option value={shipperID}>{shipperName}</option>
}

ShipperOption.propTypes = {
  shipperID: PropTypes.number.isRequired,
}
