import {useSelector} from 'react-redux'
import {AWAITING_FULFILLMENT} from '../../../../common/constants/Orders.js'
import {orderCountsSelector} from '../../../../data/orderCounts.js'
import {orderListHashBuilder} from '../../../OrderListPage/orderListSelectors.js'

export default function OrderRevisionAlert() {
  const {has_revision: orderRevisionCount} = useSelector(orderCountsSelector)

  if (!orderRevisionCount) {
    return null
  }

  return (
    <div className="wrap--external-revision-alert margin-left-7">
      <div className="alert alert--warning align-center alert--sm">
        <p className="fs-01 margin-bottom-0 v-align-middle relative margin-left-5 margin-right-5">
          <a
            href={orderListHashBuilder({
              status: [AWAITING_FULFILLMENT],
              hasRevision: true,
            })}
            className="btn btn--link revision-icon no-underline tooltip--left"
            data-hint="You have orders with external revisions"
          >
            <span
              className="i-exclamation-triangle fs-02 lh-sm v-align-middle"
              aria-hidden="true"
            />
          </a>
        </p>
      </div>
    </div>
  )
}
