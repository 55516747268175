import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import AttachHazmatInfo from '../SingleLabelConfigForm/AttachHazmatInfo.js'
import TaxInfo from '../Fields/TaxInfo.js'
import PackagingWeight from '../Fields/PackagingWeight.js'
import ReferenceNumber from '../Fields/ReferenceNumber.js'
import DeliveryConfirmation from '../Fields/DeliveryConfirmation.js'
import CertificateOrigin from '../Fields/CertificateOrigin.js'
import SaturdayDelivery from '../Fields/SaturdayDelivery.js'
import DirectDelivery from '../Fields/UPS/DirectDelivery.js'
import AdditionalHandling from '../Fields/UPS/AdditionalHandling.js'
import DeclaredValue from '../Fields/DeclaredValue.js'
import DryIceWeight from '../Fields/DryIceWeight.js'
import ShipperRelease from '../Fields/UPS/ShipperRelease.js'
import Payor from '../Fields/Payor.js'
import DutiesPayor from '../Fields/DutiesPayor.js'
import MailInnovations from '../Fields/UPS/MailInnovations.js'
import USPSEndorsement from '../Fields/UPS/USPSEndorsement.js'
import ReasonForExport from '../Fields/ReasonForExport.js'
import CostCenter from '../Fields/UPS/CostCenter.js'
import PackageBillType from '../Fields/PackageBillType.js'
import EnableNotifications from '../Fields/EnableNotifications.js'
import CarrierPickup from '../Fields/AmazonSFP/CarrierPickup.js'
import IncludeImporterOfRecord from '../SingleLabelConfigForm/IncludeImporterOfRecord.js'
import ForwardDate, {
  PICKUP_DATE_VALID_SHIPPER_TYPES,
  SHIP_DATE_VALID_SHIPPER_TYPES,
} from '../Fields/ForwardDate.js'
import PickupDateRange from '../Fields/PickupDateRange.js'
import DangerousGoods from '../Fields/DangerousGoods.js'
import ETDService from '../Fields/FedEx/ETDService.js'
import HoldAtLocation from '../Fields/HoldAtLocation.js'
import HoldForPickup from '../Fields/Endicia/HoldForPickup.js'
import ServiceEndorsement from '../Fields/ServiceEndorsement.js'
import NonstandardDay from '../Fields/DHL/NonstandardDay.js'
import NonstandardContents from '../Fields/DHL/NonstandardContents.js'
import FederalTaxID from '../Fields/DHL/FederalTaxID.js'
import StateTaxID from '../Fields/DHL/StateTaxID.js'
import SmartPostIndicia from '../Fields/FedEx/SmartPostIndicia.js'
import SmartPostHub from '../Fields/FedEx/SmartPostHub.js'
import AlcoholShipmentLicense from '../Fields/FedEx/AlcoholShipmentLicense.js'
import SignatureRequired from '../Fields/DHL/SignatureRequired.js'
import IsDutiable from '../Fields/DHL/IsDutiable.js'
import ForeignTradeRegulation from '../Fields/DHL/ForeignTradeRegulation.js'
import ContentsType from '../Fields/Endicia/ContentsType.js'
import NondeliveryOption from '../Fields/NondeliveryOption.js'
import ServiceOption from '../Fields/CanadaPost/ServiceOption.js'
import MailingZipCode from '../Fields/MailingZipCode.js'
import DeliveryInstructions from '../Fields/FedEx/DeliveryInstructions.js'
import InvoiceNumber from '../Fields/InvoiceNumber.js'
import PurchaseOrderNumber from '../Fields/PurchaseOrderNumber.js'
import DepartmentNumber from '../Fields/DepartmentNumber.js'
import InsuredValue from '../Fields/InsuredValue.js'
import ReturnReason from '../Fields/ReturnReason.js'
import Description from '../Fields/Description.js'
import DryIceRegulationSet from '../Fields/DryIceRegulationSet.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import {
  EDIT_ADDITIONAL_OPTIONS_LABEL_ID,
  labelInfoSelector,
  removeLabelInfo,
  setLabelInfos,
  updateLabelInfo,
} from '../../../data/labelInfos/index.js'
import AlternateReturnToAddress from '../Fields/AlternateReturnToAddress.js'
import PharmacyDelivery from '../Fields/PharmacyDelivery.js'
import SubPackageType from '../Fields/SubPackageType.js'
import PriorityAlert from '../Fields/PriorityAlert.js'
import className from '../../../common/className.js'
import {SHIPPER_NAMES} from '../../../common/constants/ShipperNames.js'
import LabelType from '../Fields/LabelType.js'
import LabelConfigContext from '../LabelConfigContext.js'
import AttachCustomsInfo from '../SingleLabelConfigForm/AttachCustomsInfo.js'
import ETDPreshipmentDocs from '../Fields/ETDPreshipmentDocs.js'
import AncillaryEndorsement from '../Fields/AncillaryEndorsement.js'

const MODAL_FORM = 'EDIT_ADDITIONAL_OPTIONS_MODAL'

export function showEditAdditionalOptionsModal(
  labelInfoID,
  shipperType,
  onChange,
  isLabelEnableMode,
) {
  const labelInfo = labelInfoSelector(getState(), {labelInfoID})

  setLabelInfos([
    {
      ...labelInfo,
      id: EDIT_ADDITIONAL_OPTIONS_LABEL_ID,
      config: cloneDeep(labelInfo.config),
      properties: labelInfo.properties ? [...labelInfo.properties] : undefined,
    },
  ])

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    onChange,
    isLabelEnableMode,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector() {
  return {}
}

export async function confirm() {
  try {
    const {labelInfoID, onChange} = modalFormSelector(getState())
    const {config, properties} = labelInfoSelector(getState(), {
      labelInfoID: EDIT_ADDITIONAL_OPTIONS_LABEL_ID,
    })

    updateLabelInfo(labelInfoID, {config, properties})

    removeLabelInfo(EDIT_ADDITIONAL_OPTIONS_LABEL_ID)

    closeModal()

    showMessageToast('Updated.')

    await onChange()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function onChange() {
  // noop
}

function EditAdditionalOptionsModal({form}) {
  const {shipperType, isLabelEnableMode} = form
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Edit Additional Options"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
      isDisabled={errors.preventSave}
    >
      <LabelConfigContext.Provider
        value={{
          labelInfoID: EDIT_ADDITIONAL_OPTIONS_LABEL_ID,
          shipperType,
          onChange,
          isLabelEnableMode,
        }}
      >
        <div
          className={className`wrap-shipper-addl-options fs-01 lh-md margin-bottom-25 ${shipperType}`}
        >
          <strong className="v-align-middle">
            Additional Options for {SHIPPER_NAMES[shipperType]}
          </strong>
        </div>
        <ul
          className={className`list list--shipping-options margin-bottom-15 ${
            isLabelEnableMode && 'wrap--label-enable-mode'
          }`}
        >
          <AttachCustomsInfo />
          <AlternateReturnToAddress />
          <AttachHazmatInfo />
          <TaxInfo />
          <IncludeImporterOfRecord />
          <ETDPreshipmentDocs />

          {/* Checkboxes */}
          <li className="list__item--shipping-options">
            <ul className="list list--shipping-options flex flex-wrap flex-wrap-margin-15 flex-grow">
              <EnableNotifications />
              <CarrierPickup />
              <ETDService />
              <CertificateOrigin />
              <SaturdayDelivery />
              <HoldAtLocation />
              <HoldForPickup />
              <SignatureRequired />
              <IsDutiable />
              <ShipperRelease />
              <AdditionalHandling />
              <DirectDelivery />
              <PharmacyDelivery />
            </ul>
          </li>

          {/* Date */}
          <ForwardDate
            label="Ship Date"
            labelProperty="ship_date"
            validShipperTypes={SHIP_DATE_VALID_SHIPPER_TYPES}
          />
          <ForwardDate
            label="Pickup Date"
            labelProperty="pickup_date"
            validShipperTypes={PICKUP_DATE_VALID_SHIPPER_TYPES}
          />
          <PickupDateRange />

          {/* Weight */}
          <PackagingWeight />
          <DryIceWeight />
          <DryIceRegulationSet />

          {/* Currency */}
          <DeclaredValue />
          <InsuredValue />

          {/* Selects */}
          <DangerousGoods />
          <DeliveryConfirmation />
          <ServiceEndorsement />
          <NonstandardDay />
          <NonstandardContents />
          <Payor />
          <DutiesPayor />
          <SmartPostIndicia />
          <SmartPostHub />
          <AncillaryEndorsement />
          <AlcoholShipmentLicense />
          <ForeignTradeRegulation />
          <ContentsType />
          <ReasonForExport />
          <NondeliveryOption />
          <ServiceOption />
          <PackageBillType />
          <SubPackageType />
          <LabelType />
          <PriorityAlert />

          {/* Text */}
          <FederalTaxID />
          <StateTaxID />
          <MailInnovations />
          <CostCenter />
          <USPSEndorsement />
          <MailingZipCode />
          <DeliveryInstructions />
          <InvoiceNumber />
          <PurchaseOrderNumber />
          <DepartmentNumber />
          <ReturnReason />
          <Description />
          <ReferenceNumber />
        </ul>
      </LabelConfigContext.Provider>
    </ConfirmModal>
  )
}

EditAdditionalOptionsModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape,
    shipperType: PropTypes.string.isRequired,
    isLabelEnableMode: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditAdditionalOptionsModal, modalFormSelector)
