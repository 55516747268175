import PropTypes from 'prop-types'

import PageNumberControl from './PageNumberControl.js'

export default function Footer({
  isLoading,
  limit,
  offset,
  count,
  setOffset,
  setLimit,
  preventResetOffset,
}) {
  return (
    <div>
      <hr className="hr--content-block at-bottom" />
      <div className="list--pagination-wrap flex--justify">
        <ul className="list--inline">
          <li className="list__item--inline margin-left-0">
            <select
              className="select"
              value={limit}
              onChange={(event) => {
                const newLimit = Number(event.target.value)
                setLimit(newLimit)

                // Go to the previous whole page offset that fits the limit
                if (!preventResetOffset && offset % newLimit !== 0) {
                  setOffset(Math.floor(offset / newLimit) * newLimit)
                }
              }}
            >
              <option value="10">10 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </select>
          </li>
        </ul>
        <ul className="list--inline">
          <li className="list__item--inline margin-left-0">
            <PageNumberControl
              isLoading={isLoading}
              limit={limit}
              offset={offset}
              count={count}
              setOffset={setOffset}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

Footer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  preventResetOffset: PropTypes.bool,
}
