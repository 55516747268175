import PropTypes from 'prop-types'
import classNames from 'classnames'

import {showMultiboxModal} from '../../OrderListPage/Modals/MultiboxModal/index.js'
import {
  updateWeight,
  weightSelector,
  isMultiboxSelector,
  updateParcel,
  labelUseRateShoppingSelector,
  multiboxShipperTypesSelector,
} from '../../../data/labelInfos/index.js'

import WeightInput from '../../../common/components/Form/WeightInput.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {getState, useSelector} from '../../../store.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'packages[0].weight'

async function handleWeightChange(
  labelInfoID,
  packagesIndex,
  weight,
  onChange,
) {
  const labelUseRateShopping = labelUseRateShoppingSelector(getState(), {
    labelInfoID,
  })

  if (labelUseRateShopping) {
    updateParcel(labelInfoID, packagesIndex, {weight})

    onChange()

    return
  }

  await updateWeight(labelInfoID, 0, weight)
}
export default function Weight({packagesIndex, showTitle}) {
  packagesIndex = packagesIndex || 0
  showTitle = showTitle === false ? false : true

  const {labelInfoID, onChange, isLabelEnableMode} = useLabelConfigContext()

  const weight = useSelector((state) =>
    weightSelector(state, {labelInfoID, packagesIndex}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY,
  })

  const disabled = isMultibox || (isLabelEnableMode && !isLabelPropertyEnabled)
  const invalidWeight = weight <= 0 && !disabled

  // We have to "key" the WeightInput to the labelConfigID because, if a user moves quickly between
  // unrelated label config panels, the weight of the previous panel will update the label config of
  // the new order. The WeightInput is watching prop changes and if things settle in the wrong order
  // the new order onChange handler is called with the old weight. Adding a "key" causes a new
  // WeightInput to be created instead of React reusing the old one.
  const weightInput = (
    <WeightInput
      key={labelInfoID}
      idLb="labelconfigform-weight-lb"
      weightOz={isMultibox ? null : weight}
      setWeightOz={(weight) =>
        handleWeightChange(labelInfoID, 0, weight, onChange)
      }
      metaClassName={formatMetaClassName(LABEL_PROPERTY)}
      allowZero={false}
      showScaleControl
      disabled={disabled}
    />
  )

  if (showTitle) {
    return (
      <li
        className={classNames(
          'list__item--shipping-options btm-offset-7 meta-labelconfigform-container-weight',
          {error: invalidWeight},
        )}
      >
        <div className="wrap--edit-preset-form-input">
          <div className="flex">
            <label htmlFor="labelconfigform-weight-lb" className="label--bold">
              Weight
            </label>
            {isMultibox && (
              <button
                className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
                type="button"
                onClick={() => {
                  const [shipperType] = multiboxShipperTypesSelector(
                    getState(),
                    {
                      labelInfoID,
                    },
                  )
                  showMultiboxModal(labelInfoID, shipperType)
                }}
              >
                <em>(Multiple)</em>
              </button>
            )}
          </div>
          {weightInput}
        </div>
        <EnableLabelProperty labelProperty={LABEL_PROPERTY} />
      </li>
    )
  }

  return weightInput
}

Weight.propTypes = {
  packagesIndex: PropTypes.number,
  showTitle: PropTypes.bool,
}
