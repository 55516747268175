import PropTypes from 'prop-types'
import classNames from 'classnames'

import {IntegrationShape} from '../../../common/PropTypes.js'
import {accountingAuthIntegrationIssueSelector} from '../../../redux/selectors/ui/dashboard.js'
import Forms from './Forms/index.js'
import {buildPath} from '../../../common/querystring.js'
import {ACCOUNTING, SETTINGS} from '../../../common/constants/SettingsPanels.js'
import {useSelector} from '../../../store.js'
import {settingsAccountingEditIDSelector} from './accountingFunctions.js'

export default function EditForm({integration}) {
  const integrationIssue = useSelector((state) =>
    accountingAuthIntegrationIssueSelector(state, {
      integrationID: integration.id,
    }),
  )
  const editID = useSelector(settingsAccountingEditIDSelector)

  const Form = Forms[integration.vendor]
  const isExpanded = editID === integration.id || true

  if (!Form) {
    return null
  }

  return (
    <li
      className={classNames('list__item--settings-wrap clearfix', {
        expanded: isExpanded,
      })}
    >
      <dl className="list--settings">
        <dt className="medium-4 columns list__title-logo">
          <div className={classNames('wrap--logo', integration.vendor)} />
        </dt>
        <dd className="make-medium-grey medium-5 columns">
          {integration.name}
          {integrationIssue && (
            <div>
              <strong className="label__callout label__callout--yellow v-align-middle">
                Reauthorize
              </strong>
            </div>
          )}
        </dd>
        <dd className="medium-2 columns right-aligned-text right">
          <a
            className="inline-text-button settings-list-item-button edit-url"
            href={'#' + buildPath([SETTINGS, ACCOUNTING, integration.id])}
          >
            <span
              className="icon icon-edit icon-block x-sm margin-right-2"
              aria-hidden="true"
            />
            <span className="button-text">Edit</span>
          </a>
        </dd>
      </dl>
      {isExpanded && (
        <Form
          integrationID={integration.id}
          formName={`SETTINGS_ACCOUNTING_${integration.id}`}
        />
      )}
    </li>
  )
}

EditForm.propTypes = {
  integration: IntegrationShape.isRequired,
  editID: PropTypes.number,
}
