import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {OrderLineShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {orderLineSelector, removeLines} from '../../../data/orders.js'
import {preferCartOrderLineNamesSelector} from '../../../data/company.js'

export const MODAL_FORM = 'CONFIRM_ORDER_LINE_DELETE_MODAL_FORM'

export function showConfirmOrderLineDeleteModal(orderNumber, orderLineIDs) {
  setForm(MODAL_FORM, {
    orderNumber,
    orderLineIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)
  return forms[MODAL_FORM]
}

export async function confirm() {
  try {
    const {orderNumber, orderLineIDs} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await removeLines(orderNumber, orderLineIDs)

    showMessageToast(
      `Order line${orderLineIDs.length === 1 ? '' : 's'} removed`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ConfirmOrderLineDeleteModal({form, firstLine, firstProductName}) {
  if (!firstLine) {
    return null
  }

  return (
    <ConfirmModal
      title={`Remove Order Line${form.orderLineIDs.length === 1 ? '' : 's'}`}
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-0">
        {form.orderLineIDs.length === 1 ? (
          <span>
            Do you want to remove{' '}
            <strong>
              {firstProductName} (qty: {firstLine.quantity})
            </strong>{' '}
            from this order?
          </span>
        ) : (
          <span>
            Do you want to remove{' '}
            <strong>{form.orderLineIDs.length} lines</strong> from this order?
          </span>
        )}
      </p>
    </ConfirmModal>
  )
}

ConfirmOrderLineDeleteModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    orderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  firstLine: OrderLineShape,
  firstProductName: PropTypes.string.isRequired,
}

function mapStateToProps(state, {form}) {
  const firstLine = orderLineSelector(state, {
    orderNumber: form.orderNumber,
    orderLineID: form.orderLineIDs[0],
  })

  return {
    firstLine,
    firstProductName: firstLine
      ? preferCartOrderLineNamesSelector(state)
        ? firstLine.order_line_product_name
        : firstLine.product_name
      : '',
  }
}

export default formConnect(
  connect(mapStateToProps)(ConfirmOrderLineDeleteModal),
  modalFormSelector,
)
