import {NEW_ID} from '../../../../../../common/constants/index.js'

import {isPresent} from '../../../../../../common/utils.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.paymentAccountNumber)) {
    errors.paymentAccountNumber = 'Payment account number is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    paymentAccountNumber: vendorConfig.payment_account_number || '',
    paymentCountryCode: vendorConfig.payment_country_code || '',
    isSaving: false,
  }
}
