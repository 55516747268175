import actions from '../../../../redux/actions/ui/dataImport/strategies/batchStrategy.js'
import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from './ReconcileForm.js'
import EditForm from './EditForm.js'

export default function BatchStrategy(props) {
  return (
    <BaseStrategy
      {...props}
      actions={actions}
      EditForm={EditForm}
      ReconcileForm={ReconcileForm}
    />
  )
}

BatchStrategy.title = actions.title
