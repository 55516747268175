export const BASE = 'ordoro/data/returnOrderCodes'
export const SET_RETURN_ORDER_CODES = `${BASE}/SET_RETURN_ORDER_CODES`
export const UPDATE_RETURN_ORDER_CODE = `${BASE}/UPDATE_RETURN_ORDER_CODE`
export const GET_RETURN_ORDER_CODES = `${BASE}/GET_RETURN_ORDER_CODES`

export function setReturnOrderCodes(returnOrderCodes) {
  return {
    type: SET_RETURN_ORDER_CODES,
    payload: returnOrderCodes,
  }
}

export function updateReturnOrderCode(returnOrderCode) {
  return {
    type: UPDATE_RETURN_ORDER_CODE,
    payload: returnOrderCode,
  }
}

export function getReturnOrderCodes() {
  return {
    type: GET_RETURN_ORDER_CODES,
  }
}
