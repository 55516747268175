import {combineReducers} from 'redux'
import reduce from 'lodash/reduce.js'
import get from 'lodash/get.js'

import {NEW_ID} from '../../../../../common/constants/index.js'
import {
  SET_FORMS,
  SET_FORM,
  UPDATE_FORM,
} from '../../../../actions/ui/settings/shippers/index.js'
import {NAVIGATE} from '../../../../actions/ui/index.js'
import INITIAL_STATE from '../../../INITIAL_STATE.js'
import composeReducers from '../../../../utils/composeReducers.js'

function editID(
  state = INITIAL_STATE.ui.settings.shippers.editID,
  action = {},
) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    if (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'shipper' &&
      pathComponents[2]
    ) {
      return pathComponents[2]
    }

    return INITIAL_STATE.ui.settings.shippers.editID
  }

  return state
}

function forms(state = INITIAL_STATE.ui.settings.shippers.forms) {
  return state
}

function hasChanged(state = INITIAL_STATE.ui.settings.shippers.hasChanged) {
  return state
}

const reducer = combineReducers({
  editID,
  forms,
  hasChanged,
})

function handleSetForms(state, action) {
  if (action.type === SET_FORMS) {
    // yuck, without rewriting everything, we have to preserve the NEW_ID form because it might get
    // setup first. Otherwise SET_FORMS will step on the NEW_ID form. This happens when navigating
    // to the page directly (#/settings/shipper/new) vs reloading the app directly on that page
    // When/if we remove the reducer and use instance forms for shipper settings we can ignore this
    // problem
    return {
      ...state,
      forms: {
        ...(get(state, ['forms', NEW_ID])
          ? {[NEW_ID]: state.forms[NEW_ID]}
          : null),
        ...action.payload,
      },
      hasChanged: {},
    }
  }

  return state
}

function handleSetForm(state, action) {
  if (action.type === SET_FORM) {
    const link = action.payload.link

    return {
      ...state,
      forms: {
        ...state.forms,
        [link]: action.payload.form,
      },
      hasChanged: {
        ...state.hasChanged,
        [link]: {},
      },
    }
  }

  return state
}

function handleUpdateForm(state, action) {
  if (action.type === UPDATE_FORM) {
    const link = action.payload.link

    return {
      ...state,
      forms: {
        ...state.forms,
        [link]: {
          ...state.forms[link],
          ...action.payload.form,
        },
      },
      hasChanged: {
        ...state.hasChanged,
        [link]: {
          ...state.hasChanged[link],
          ...reduce(
            action.payload.form,
            (prev, value, key) => {
              prev[key] = true

              return prev
            },
            {},
          ),
        },
      },
    }
  }

  return state
}

export default composeReducers(
  reducer,
  handleSetForms,
  handleSetForm,
  handleUpdateForm,
)
