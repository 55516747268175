import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function FormListItem({className, children}) {
  return (
    <li className={classNames('list__item--form', className)}>{children}</li>
  )
}

FormListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
