import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {bulkShipperCurrencySymbolSelector} from '../../../../data/labelInfos/index.js'
import {createLabelImages} from '../../../../data/labelInfos/labelRequest.js'
import {
  bulkCostSelector,
  bulkCreateLabelImagesDisabledSelector,
  bulkLabelsLoadingSelector,
  labelsLoadedCountSelector,
  ratesLoadedCountSelector,
  bulkRatesLoadingSelector,
  bulkInsuranceCostSelector,
  bulkIncludeInsuranceSelector,
  insufficientBalanceSelector,
  singleShipperIDForBalanceSelector,
} from '../bulkLabelConfigSelectors.js'
import Total from '../../Total.js'
import InsuranceCost from '../../Fields/Insurance/InsuranceCost.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {showPostageBalanceModal} from '../../Modals/PostageBalanceModal.js'

import BulkBalance from './BulkBalance.js'
import {HK_CREATE_LABEL} from '../../../../common/constants/HotKeys.js'

export default function BulkSummary({labelInfoIDs}) {
  const cost = useSelector((state) => bulkCostSelector(state, {labelInfoIDs}))
  const shipperCurrencySymbol = useSelector((state) =>
    bulkShipperCurrencySymbolSelector(state, {labelInfoIDs}),
  )
  const insuranceCost = useSelector((state) =>
    bulkInsuranceCostSelector(state, {labelInfoIDs}),
  )
  const includeInsurance = useSelector((state) =>
    bulkIncludeInsuranceSelector(state, {labelInfoIDs}),
  )
  const disabled = useSelector((state) =>
    bulkCreateLabelImagesDisabledSelector(state, {labelInfoIDs}),
  )
  const ratesLoading = useSelector((state) =>
    bulkRatesLoadingSelector(state, {labelInfoIDs}),
  )
  const labelsLoading = useSelector((state) =>
    bulkLabelsLoadingSelector(state, {labelInfoIDs}),
  )
  const labelsLoadedCount = useSelector((state) =>
    labelsLoadedCountSelector(state, {labelInfoIDs}),
  )
  const ratesLoadedCount = useSelector((state) =>
    ratesLoadedCountSelector(state, {labelInfoIDs}),
  )
  const insufficientBalance = useSelector((state) =>
    insufficientBalanceSelector(state, {labelInfoIDs}),
  )
  const shipperID = useSelector((state) =>
    singleShipperIDForBalanceSelector(state, {labelInfoIDs}),
  )

  return (
    <fieldset className="fieldset--shipping-options fieldset--shipping-summary">
      <div className="flex">
        <div className="flex-grow">
          <div className="flex--justify">
            <div className="flex--justify-col margin-bottom-15">
              <Total
                cost={cost}
                disabled={disabled}
                currencySymbol={shipperCurrencySymbol}
              >
                <InsuranceCost
                  insuranceCost={insuranceCost}
                  includeInsurance={includeInsurance}
                />
              </Total>
              <BulkBalance labelInfoIDs={labelInfoIDs} />
              {insufficientBalance && (
                <pre className="wrap--error-wb lh-sm">
                  <small className="error margin-top-0">
                    Additional funds required.
                  </small>
                </pre>
              )}
            </div>
            <div className="flex--justify-col margin-bottom-15">
              <ButtonPrimary
                onClick={(event) => {
                  if (event) {
                    event.preventDefault()
                  }

                  if (insufficientBalance) {
                    showPostageBalanceModal(shipperID)
                  } else {
                    createLabelImages(labelInfoIDs)
                  }
                }}
                isDeterminate
                isLoading={ratesLoading || labelsLoading}
                isDisabled={disabled || labelsLoading}
                className="meta-labelconfigform-button-createlabel"
                hotKey={HK_CREATE_LABEL}
              >
                {labelsLoading &&
                  `${labelsLoadedCount} of ${labelInfoIDs.length} Labels`}
                {ratesLoading &&
                  `${ratesLoadedCount} of ${labelInfoIDs.length} Rates`}
                {!labelsLoading &&
                  !ratesLoading &&
                  `Create ${labelInfoIDs.length} Labels`}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}

BulkSummary.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
