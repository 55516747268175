import isEmpty from 'lodash/isEmpty.js'

import api from '../../../../../common/api.js'
import {isPresent} from '../../../../../common/utils.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'

import baseStrategy from './baseStrategy.js'

export default class productImageStrategy extends baseStrategy {
  static title = 'Update Product Image'
  static requiredColumns = ['sku', 'url']

  static getExampleRows() {
    return () =>
      api.get('/product/', {limit: 10}).then(({json: data}) => {
        const rows = [
          ['SKU *Req', 'URL *Req' /*, 'Is Default', 'Cart Image ID' */],
        ]

        data.product.forEach((product) => {
          rows.push([
            product.sku,
            product.image_url || '',
            //true,
            //'',
          ])
        })

        return rows
      })
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      url: find(/^(image|url)?$/i),
      is_default: find(/is.default/i),
      cart_image_id: find(/cart.image.id/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()
    const is_default = this.toBoolean(row[columnsByName.is_default])

    data.payload = {
      sku: row[columnsByName.sku],
      url: row[columnsByName.url],
      is_default: columnsByName.is_default !== -1 ? is_default : undefined,
      cart_image_id: row[columnsByName.cart_image_id] || undefined,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    if (!isPresent(payload.sku)) {
      errors.sku = 'SKU is required'
    } else {
      delete errors.sku
    }

    if (!isPresent(payload.url)) {
      errors.url = 'URL is required'
    } else {
      delete errors.url
    }

    return errors
  }

  static save(data) {
    return (dispatch, getState) => {
      const {
        ui: {
          dataImport: {columnsToDataMap},
        },
      } = getState()
      const {payload} = data
      const params = {
        images: [
          {
            url: payload.url,
            is_default: true,
          },
        ],
      }

      if (columnsToDataMap.is_default !== -1) {
        params.images[0].is_default = payload.is_default
      }

      if (columnsToDataMap.cart_image_id !== -1) {
        params.images[0].cart_image_id = payload.cart_image_id
      }

      if (isEmpty(params)) {
        return Promise.resolve()
      }

      return api.post(
        `/product/${encodeURIComponent(payload.sku)}/image/`,
        params,
      )
    }
  }
}
