import startOfDay from 'date-fns/startOfDay'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'

import {getRealDate} from './date.js'

export default function getShipDate(date) {
  if (!date) {
    return ''
  }

  const now = getRealDate()
  const shipDate = parseISO(date)

  if (!isValid(shipDate)) {
    return ''
  }

  if (isSameDay(shipDate, now) || isBefore(shipDate, now)) {
    return now.toISOString()
  }

  return startOfDay(shipDate).toISOString()
}
