import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {JET} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.api_user)) {
    errors.api_user = 'API User is required'
    errors.preventSave = true
  }

  if (!isPresent(form.secret)) {
    errors.secret = 'Secret is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: JET}),
      api_user: '',
      secret: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    api_user: cart.vendor_config.api_user,
    secret: cart.vendor_config.secret,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param) {
    errors[serverError.param] = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    api_user: form.api_user,
    secret: form.secret,
  }
}

function Jet({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="api_user"
              label="API User"
              required
              value={form.api_user || ''}
              errorMessage={form.hasChanged_api_user && errors.api_user}
              onChange={(event) =>
                updateForm(formName, {
                  api_user: event.target.value,
                  hasChanged_api_user: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="secret"
              label="Secret"
              required
              value={form.secret || ''}
              errorMessage={form.hasChanged_secret && errors.secret}
              onChange={(event) =>
                updateForm(formName, {
                  secret: event.target.value,
                  hasChanged_secret: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
    </VendorForm>
  )
}

Jet.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    api_user: PropTypes.string.isRequired,
    hasChanged_api_user: PropTypes.bool,
    secret: PropTypes.string.isRequired,
    hasChanged_secret: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Jet, setUpCartForm)
