import PropTypes from 'prop-types'
import {useCallback} from 'react'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  updateOrders,
  orderSelector,
  ordersSelector,
} from '../../../data/orders.js'
import FormattedDate from '../../../common/components/FormattedDate.js'
import Currency from '../../../common/components/Currency.js'
import apiverson from '../../../common/apiverson.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {showOrderRevisionModal} from './OrderRevisionModal.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'

export const MODAL_FORM = 'MERGE_ORDERS_MODAL'

export async function showMergeOrdersModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    primaryOrderNumber: orderNumbers[0],
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export function secondaryOrderNumbersSelector(state) {
  const {orderNumbers, primaryOrderNumber} = modalFormSelector(state)

  return orderNumbers.filter(
    (orderNumber) => orderNumber !== primaryOrderNumber,
  )
}

export function orderNumbersWithRevisionsSelector(state) {
  const {orderNumbers} = modalFormSelector(state)

  return ordersSelector(state, {orderNumbers})
    .filter((order) => order.has_revision)
    .map(({order_number}) => order_number)
}

export async function mergeOrders() {
  const {primaryOrderNumber} = modalFormSelector(getState())
  const secondaryOrderNumbers = secondaryOrderNumbersSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    await apiverson.post('/order/merge', {
      merge_into: primaryOrderNumber,
      merge_from: secondaryOrderNumbers,
    })

    showMessageToast(
      secondaryOrderNumbers.length === 1
        ? `${secondaryOrderNumbers[0]} was merged into order ${primaryOrderNumber}`
        : `${secondaryOrderNumbers.length} orders were merged into order ${primaryOrderNumber}`,
    )

    closeModal()

    await refreshOrderListAndCounts()

    await updateOrders([primaryOrderNumber, ...secondaryOrderNumbers])
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
      isSaving: false,
    })
  }
}

function PrimaryOrderOption({orderNumber, selected}) {
  const onClick = useCallback(
    () => updateModalForm({primaryOrderNumber: orderNumber}),
    [orderNumber],
  )
  const order = useSelector((state) => orderSelector(state, {orderNumber}))

  if (!order) {
    return null
  }

  const address = order.shipping_address

  return (
    <>
      <tr
        className={`table__tr--merge-orders ${selected ? 'selected' : ''}`}
        onClick={onClick}
      >
        <td className="table__td--merge-orders td-width-1">
          <input
            className="input--radio fs-01"
            type="radio"
            checked={selected}
            onChange={onClick}
          />
        </td>
        <td className="table__td--merge-orders">
          <div className="fs-00 text--black">
            <strong>{order.order_number}</strong>
          </div>
          <div className="fs-00">
            <FormattedDate
              value={order.order_placed_date}
              format="MMM D, YYYY"
            />
          </div>
          <div className="fs-00">
            at <FormattedDate value={order.order_placed_date} format="h:mma" />
          </div>
        </td>
        <td className="table__td--merge-orders">
          {!address.company &&
          !address.name &&
          !address.city &&
          !address.street ? (
            <div className="fs-00">
              <em>No address specified</em>
            </div>
          ) : (
            <div>
              <div className="fs-00">{address.name || address.company}</div>
              <div className="fs-00">
                {address.street1}
                {address.street2 && `, ${address.street2}`}
              </div>
              <div className="fs-00">
                <div className="inline-block lh-md">
                  {address.city && `${address.city}, `} {address.state}{' '}
                  {address.zip} {address.country}
                </div>
              </div>
            </div>
          )}
        </td>
        <td className="table__td--merge-orders">
          <div className="fs-00">
            <Currency value={order.financial.grand_total} />
          </div>

          <div className="fs-00">
            <span>{order.lines.length}</span>{' '}
            {order.lines.length === 1 ? 'line' : 'lines'}
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="4" />
      </tr>
    </>
  )
}

PrimaryOrderOption.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

function MergeOrdersModal({form}) {
  const orderNumbersWithRevisions = useSelector(
    orderNumbersWithRevisionsSelector,
  )

  return (
    <ConfirmModal
      title="Merge Orders"
      modalSize=""
      confirmText="Merge Orders"
      cancelText="Cancel"
      isSaving={form.isSaving}
      onConfirm={() => mergeOrders()}
      onCancel={() => closeModal()}
      error={form.serverError}
    >
      <p className="fs-01">
        {form.orderNumbers.length === 2
          ? 'Select the primary order to merge order line info into. The other order will be marked as merged.'
          : 'Select the primary order to merge order line info into. All the other orders will be marked as merged.'}
      </p>

      {orderNumbersWithRevisions.length !== 0 && (
        <PluralBlock array={orderNumbersWithRevisions}>
          <div className="alert alert--warning align-center margin-bottom-30">
            <p className="fs-00 margin-bottom-0 v-align-middle">
              <span
                className="i-exclamation-triangle text--warning-orange fs-02 lh-0 margin-right-5 v-align-middle"
                aria-hidden="true"
              ></span>
              <ButtonLink
                className="btn btn--link darker inline-block v-align-middle"
                onClick={() =>
                  showOrderRevisionModal(orderNumbersWithRevisions)
                }
              >
                <strong>
                  <Count /> <Plural s="order has been" p="orders have been" />{' '}
                  modified outside of Ordoro
                </strong>
              </ButtonLink>{' '}
              <span>→</span>
            </p>
          </div>
        </PluralBlock>
      )}

      <table className="table table--merge-orders">
        <tbody>
          {form.orderNumbers.map((orderNumber) => (
            <PrimaryOrderOption
              key={orderNumber}
              orderNumber={orderNumber}
              selected={orderNumber === form.primaryOrderNumber}
            />
          ))}
        </tbody>
      </table>
    </ConfirmModal>
  )
}

MergeOrdersModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    primaryOrderNumber: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(MergeOrdersModal, modalFormSelector)
