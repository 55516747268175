import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import sortBy from 'lodash/sortBy.js'

import {formatCurrency, isTBD} from '../../common/components/Currency.js'
import {
  RateShape,
  BulkRateShape,
  RateErrorShape,
} from '../../common/PropTypes.js'
import {
  formsSelector,
  getState,
  updateForm,
  useAutoForm,
  useSelector,
} from '../../store.js'
import Zropdown, {
  useZropdown,
  useZropdownElement,
  POP_UP,
} from '../../common/components/Zropdown.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import className from '../../common/className.js'
import {
  boxShapeSelector,
  rateKeySelector,
  labelShipperIDSelector,
  shipperIDsSelector,
  labelShipperTypeSelector,
  shippingMethodSelector,
  sortByDeliveryEstimate,
  updateLabelInfo,
  labelShippersSelector,
} from '../../data/labelInfos/index.js'
import {getShipperName, shipperSelector} from '../../data/shippers.js'
import Select from '../../common/components/Select.js'
import Radio from '../../common/components/Radio.js'
import {useLabelConfigContext} from './LabelConfigContext.js'
import {USPS_SHIPPERS} from '../../common/constants/ShipperNames.js'

const RateSortOptions = [
  {
    value: 'cost_0-9',
    display: 'Cost - Lowest to Highest',
    sort: ['cost', 'service_type'],
  },
  {
    value: 'cost_9-0',
    display: 'Cost - Highest to Lowest',
    sort: ['cost', 'service_type'],
    reverse: true,
  },
  {
    value: 'delivery_0-9',
    display: 'Estimated Delivery - Fastest to Slowest',
    sort: [sortByDeliveryEstimate, 'cost', 'service_type'],
  },
  {
    value: 'delivery_9-0',
    display: 'Estimated Delivery - Slowest to Fastest',
    sort: [sortByDeliveryEstimate, 'cost', 'service_type'],
    reverse: true,
  },
  {
    value: 'carrier_a-z',
    display: 'Carrier - A-Z',
    sort: ['shipper_name', 'cost', 'service_type'],
  },
  {
    value: 'carrier_z-a',
    display: 'Carrier - Z-A',
    sort: ['shipper_name', 'cost', 'service_type'],
    reverse: true,
  },
]

export function formatRate(rate, currencySymbol) {
  let formattedETA = ''
  let formattedCost = ''

  if (rate.estimated_delivery_date) {
    let date = parseISO(rate.estimated_delivery_date)

    // for dumb dates from Endicia: month/day/year
    if (!isValid(date)) {
      date = parse(rate.estimated_delivery_date, 'M/d/yyyy', new Date())
    }

    if (isValid(date)) {
      formattedETA = format(date, 'MMM d')
    }
  }

  if (rate.estimated_delivery_days) {
    const days = rate.estimated_delivery_days
    formattedETA = `${days} ${days === '1' ? 'day' : 'days'}`
  }

  if (rate.disabled) {
    formattedCost = 'Not available for all orders'
  } else if (isTBD(rate.cost)) {
    formattedCost = 'TBD'
  } else {
    formattedCost = formatCurrency(rate.cost, currencySymbol)
  }

  return {formattedETA, formattedCost}
}

function carrierCountByTypeSelector(state, {labelInfoID}) {
  const shippers = labelShippersSelector(state, {labelInfoID})

  return shippers.reduce((prev, shipper) => {
    const carrier = USPS_SHIPPERS.includes(shipper.vendor)
      ? 'usps'
      : shipper.vendor

    prev[carrier] = (prev[carrier] || 0) + 1

    return prev
  }, {})
}

function RateRow({
  rate,
  isSelected,
  isRateSelected,
  applyRate,
  hasMultipleShippersOfSameCarrier,
}) {
  const ref = useRef()
  const shipper = useSelector((state) =>
    shipperSelector(state, {shipperID: rate.shipper_id}),
  )
  const shipperName = getShipperName(shipper)

  useZropdownElement({ref, isSelected})

  return (
    <>
      {rate.error_message ? (
        <tr ref={ref} className="tr--has-error" data-dropdown-prevent-close>
          <td className="table__td align-center" colSpan="6">
            <div className="error">
              <span>{shipperName} - </span>
              {rate.error_message}
            </div>
          </td>
        </tr>
      ) : (
        <tr
          ref={ref}
          className={className`tr--rs
          ${{'tr--focus': isSelected, 'tr--rate-selected': isRateSelected}}`}
          onClick={() => applyRate(rate)}
        >
          <td className="table__td">
            <Radio
              mode="fancy"
              checked={isRateSelected}
              onChange={() => applyRate(rate)}
            />
          </td>
          <td className="table__td table__td--carrier-logo">
            <div className={className`td__shipper-logo ${rate.vendor}`} />
            {hasMultipleShippersOfSameCarrier && (
              <div className="fs-n2 lh-md align-center margin-top-5 op-50">
                {shipperName}
              </div>
            )}
          </td>
          <td className="table__td">
            <span>{rate.service}</span>
          </td>
          <td className="table__td">
            <span>{rate.box_shape_name}</span>
          </td>
          <td className="table__td">
            <span>{rate.formattedETA}</span>
          </td>
          <td className="table__td align-right">
            <strong>{rate.formattedCost}</strong>
          </td>
        </tr>
      )}
    </>
  )
}

RateRow.propTypes = {
  rate: PropTypes.oneOfType([RateShape, RateErrorShape, BulkRateShape])
    .isRequired,
  isSelected: PropTypes.bool,
  isRateSelected: PropTypes.bool,
  applyRate: PropTypes.func.isRequired,
  needsShipperHeader: PropTypes.bool,
  needsShipperInRow: PropTypes.bool,
  hasMultipleShippersOfSameCarrier: PropTypes.bool,
}

function updateRatesResults(
  dropdown,
  rates,
  value,
  currencySymbol,
  labelInfoID,
) {
  try {
    const state = getState()
    const form = formsSelector(state)[dropdown]
    const shipperID = labelShipperIDSelector(state, {labelInfoID})
    const shipperType = labelShipperTypeSelector(state, {labelInfoID})
    const boxShape = boxShapeSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    })
    const shippingMethod = shippingMethodSelector(state, {labelInfoID})
    const rateKey = rateKeySelector(state, {labelInfoID})

    if (!form) {
      return
    }

    let results = rates.map((rate) => ({
      ...rate,
      ...formatRate(rate, currencySymbol),
      disabled: !!rate.error_message,
    }))

    const sortOption = RateSortOptions.find(
      (option) => option.value === form.sort,
    )

    results = sortBy(results, sortOption.sort)

    if (sortOption.reverse) {
      results.reverse()
    }

    const [selectedRateIndex, selectedRate] = results.reduce(
      (prev, rate, index) => {
        if (
          rate.service_type === shippingMethod &&
          rate.key === rateKey &&
          (!rate.box_type || rate.box_type === boxShape) &&
          rate.shipper_id === shipperID
        ) {
          prev = [index, rate]
        }

        return prev
      },
      [-1, null],
    )

    updateForm(dropdown, {
      results,
      selectedIndex: selectedRateIndex !== -1 ? selectedRateIndex : 0,
      hasFoundNothing: results.length === 0,
      selectedRateIndex,
      selectedRate,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

export default function RatePopOut({
  value,
  onChange,
  rates,
  updated,
  loading,
  disabled,
  hasErrors,
  hasWarnings,
  hasOnlyDisabledRates,
  hasNoRates,
  currencySymbol,
  dropdown,
}) {
  dropdown = dropdown || 'RATE_POP_OUT'
  const {labelInfoID} = useLabelConfigContext()

  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
    sort: RateSortOptions[0].value,
  })

  useEffect(() => {
    updateRatesResults(dropdown, rates, value, currencySymbol, labelInfoID)
  }, [rates, value, currencySymbol, labelInfoID, form.sort])

  const buttonRef = useRef()

  const {onSelectInternal, onConfirm, onUp, onDown} = useZropdown({
    dropdown,
    form,
    onSelect: (option) => onChange(option),
    closeOnConfirm: true,
  })

  const currentDropdown = useSelector(currentDropdownSelector)
  const isOpen = currentDropdown === dropdown

  const shipperIDs = useSelector((state) =>
    shipperIDsSelector(state, {labelInfoID}),
  )
  const carrierCountByType = useSelector((state) =>
    carrierCountByTypeSelector(state, {labelInfoID}),
  )
  let needsShipperHeader = false
  let lastShipperID = null
  const sortedByCarrier = !!form.sort.match(/carrier/)
  const {selectedRate} = form

  useEffect(() => {
    if (!isOpen || !updated) {
      return
    }

    updateLabelInfo(labelInfoID, {rates_updated: false})
  }, [isOpen, updated])

  return (
    <div
      className={`wrap-outer--tag-dropdown-with-filter wrap--shipping-rate-dropdown-rs ${
        isOpen ? 'open' : ''
      }`}
      data-dropdown={dropdown}
    >
      <button
        ref={buttonRef}
        className={className`btn btn--dropdown btn--filter btn--filter-w-border w-100 ${{
          'select--updated': updated,
          'select--loading': loading,
          'select--error': hasErrors || hasOnlyDisabledRates,
          'select--warning':
            hasWarnings && !(hasErrors || hasOnlyDisabledRates),
        }}`}
        type="button"
        disabled={loading || disabled || rates.length === 0}
      >
        <span className="btn__text flex--justify">
          {selectedRate ? (
            <>
              <span className="wrap--rate-shipper-pkg-info txt-overflow-ellipsis">
                <span
                  className={className`wrap--selected-rate-service block txt-overflow-ellipsis lh-md ${selectedRate.vendor}`}
                >
                  <span className="fs-n0 lh-md v-align-middle">
                    {selectedRate.service}
                  </span>
                </span>
                <span className="block lh-md txt-overflow-ellipsis">
                  {selectedRate.box_shape_name}
                </span>
              </span>
              <span className="wrap--rate-cost-eta-info">
                <strong className="block lh-md">
                  {selectedRate.formattedCost}
                </strong>
                {selectedRate.formattedETA && (
                  <span className="op-75 lh-md">
                    (ETA {selectedRate.formattedETA})
                  </span>
                )}
              </span>
            </>
          ) : hasNoRates ? (
            <em>No shipping rates are currently available.</em>
          ) : (
            'View shipping rates...'
          )}
        </span>
      </button>
      <Zropdown
        dropdown={dropdown}
        parentRef={buttonRef}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
        header={
          <div
            className="zropdown__header zropdown__header--rs"
            data-dropdown-prevent-close
          >
            <div className="zropdown__header--rs-inner flex--justify">
              <p className="fs-02 lh-md margin-bottom-0">
                <strong>Shipping Methods/Rates</strong>
              </p>
              <Select
                value={form.sort}
                onChange={(sort) => updateForm(dropdown, {sort})}
                options={RateSortOptions}
              ></Select>
            </div>
          </div>
        }
        blurOnOpen
        minimumHeight={500}
        preferredDirection={POP_UP}
      >
        <li className="list__item list--style-none wrap--table--rs">
          <table className="table table--rs">
            <thead>
              <tr>
                <th className="table__th">&nbsp;</th>
                <th className="table__th table__th--carrier-logo w-15 align-center">
                  Carrier
                </th>
                <th className="table__th w-35">Shipping Method</th>
                <th className="table__th w-25">Package Type</th>
                <th className="table__th w-10">ETA</th>
                <th className="table__th w-15 align-right">Rate</th>
              </tr>
            </thead>
            <tbody className="table__tbody--lines table__tbody--rs table__tbody--v-align-middle">
              {form.results.map((rate, index) => {
                needsShipperHeader =
                  sortedByCarrier &&
                  shipperIDs.length > 1 &&
                  rate.shipper_id !== lastShipperID
                lastShipperID = rate.shipper_id
                const carrier = USPS_SHIPPERS.includes(rate.vendor)
                  ? 'usps'
                  : rate.vendor

                return (
                  <RateRow
                    key={`${rate.key}_${index}`}
                    rate={rate}
                    isSelected={index === form.selectedIndex}
                    isRateSelected={index === form.selectedRateIndex}
                    applyRate={() => onSelectInternal(rate)}
                    needsShipperHeader={needsShipperHeader}
                    needsShipperInRow={!sortedByCarrier}
                    hasMultipleShippersOfSameCarrier={
                      carrierCountByType[carrier] > 1
                    }
                  />
                )
              })}
            </tbody>
          </table>
        </li>
      </Zropdown>
    </div>
  )
}

RatePopOut.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.oneOfType([RateShape, RateErrorShape, BulkRateShape]),
  ),
  updated: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasWarnings: PropTypes.bool,
  hasOnlyDisabledRates: PropTypes.bool,
  hasNoRates: PropTypes.bool,
  currencySymbol: PropTypes.string.isRequired,
  dropdown: PropTypes.string,
}
