import {PO_SORT_OPTIONS} from '../../common/constants/PurchaseOrders.js'
import Sort from '../../common/components/List/Sort.js'
import {setSort} from './purchaseOrderListActions.js'
import {poListQuerySelector} from './purchaseOrderListSelectors.js'
import {useSelector} from '../../store.js'

export default function POSort() {
  const {sort} = useSelector(poListQuerySelector)

  return (
    <Sort
      items={PO_SORT_OPTIONS}
      value={sort}
      onChange={(value) => setSort(value)}
    />
  )
}
