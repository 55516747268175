import {parse} from './querystring.js'

export default function hashParse(hash) {
  const [path, query] = hash.replace(/^#/, '').split('?')

  return {
    pathComponents: path
      .split('/')
      .map((component) => decodeURIComponent(component))
      .filter((component) => !!component),
    query: parse(query),
  }
}
