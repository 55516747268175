import {navigate} from '../../../ui/index.js'

export const BASE = 'ordoro/ui/settings/packingLists'
export const SET_IS_SAVING = `${BASE}/SET_IS_SAVING`
export const SET_IS_UPLOADING = `${BASE}/SET_IS_UPLOADING`
export const SET_ERROR = `${BASE}/SET_ERROR`
export const SET_CURRENT_PACKING_LIST = `${BASE}/SET_CURRENT_PACKING_LIST`
export const UPDATE_CURRENT_PACKING_LIST = `${BASE}/UPDATE_CURRENT_PACKING_LIST`
export const SETUP_FOR_EDIT = `${BASE}/SETUP_FOR_EDIT`
export const SAVE_PACKING_LIST = `${BASE}/SAVE_PACKING_LIST`
export const HANDLE_LOGO_FILE = `${BASE}/HANDLE_LOGO_FILE`

export const SETTINGS_PROFILES_URL = '#/settings/profiles'
export const PROFILES_GENERAL = 'general'
export const PROFILES_PACKING_LIST = 'packing_list'
export const PROFILES_RETURN_ORDER = 'return_order'
export const PROFILES_SHIP_FROM_ALIAS = 'ship_from_alias'

export function setError(value) {
  return {
    type: SET_ERROR,
    payload: value,
  }
}

export function setIsSaving(value) {
  return {
    type: SET_IS_SAVING,
    payload: value,
  }
}

export function setIsUploading(value) {
  return {
    type: SET_IS_UPLOADING,
    payload: value,
  }
}

export function setCurrentPackingList(value) {
  return {
    type: SET_CURRENT_PACKING_LIST,
    payload: value,
  }
}

export function updateCurrentPackingList(update) {
  return {
    type: UPDATE_CURRENT_PACKING_LIST,
    payload: update,
  }
}

export function setupForEdit() {
  return {
    type: SETUP_FOR_EDIT,
  }
}

export function setupForAdding() {
  return setCurrentPackingList({
    _link: '',
    _linklogo: '',
    name: '',
    custom_text: '',
    email: '',
    phone: '',
    website: '',
    footer: '',
    is_default: false,
    return_email_subject: '',
    return_email_content: '',
  })
}

export function goToPackingListSettings() {
  return navigate(`${SETTINGS_PROFILES_URL}/${PROFILES_GENERAL}`)
}

export function savePackingList() {
  return {
    type: SAVE_PACKING_LIST,
  }
}

export function handleLogoFile(file) {
  return {
    type: HANDLE_LOGO_FILE,
    payload: file,
  }
}
