import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {
  setWillShowOnlyErrors,
  setWillStopSavingOnErrors,
} from '../../redux/actions/ui/dataImport/index.js'

class Options extends Component {
  constructor(props) {
    super(props)

    this.toggleShowAllEntries = (event) => {
      this.props.setWillShowOnlyErrors(!event.target.checked)

      this.props.queueNextData({fromStart: true})
    }

    this.toggleWillStopSavingOnErrors = (event) => {
      this.props.setWillStopSavingOnErrors(event.target.checked)
    }
  }

  render() {
    return (
      <span>
        <label
          className="unbold-label make-inline-block margin-left-20"
          htmlFor="id_data_import_errors_at_end"
        >
          <input
            type="checkbox"
            id="id_data_import_errors_at_end"
            className="margin-bottom-0"
            checked={this.props.willStopSavingOnErrors}
            onChange={this.toggleWillStopSavingOnErrors}
          />
          <span> Pause on errors</span>
        </label>
        <label
          className="unbold-label make-inline-block margin-left-20"
          htmlFor="id_preview_data_entry"
        >
          <input
            type="checkbox"
            id="id_preview_data_entry"
            className="margin-bottom-0"
            checked={!this.props.willShowOnlyErrors}
            onChange={this.toggleShowAllEntries}
          />
          <span> Preview each entry</span>
        </label>
      </span>
    )
  }
}

Options.propTypes = {
  willShowOnlyErrors: PropTypes.bool,
  willStopSavingOnErrors: PropTypes.bool,
  setWillShowOnlyErrors: PropTypes.func.isRequired,
  setWillStopSavingOnErrors: PropTypes.func.isRequired,
  queueNextData: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const {willShowOnlyErrors, willStopSavingOnErrors} = state.ui.dataImport

  return {
    willShowOnlyErrors,
    willStopSavingOnErrors,
  }
}

const mapDispatchToProps = {
  setWillShowOnlyErrors,
  setWillStopSavingOnErrors,
}

export default connect(mapStateToProps, mapDispatchToProps)(Options)
