import {combineReducers} from 'redux'
import reduce from 'lodash/reduce.js'

import {NEW_ID} from '../../../../common/constants/index.js'
import {
  SET_IS_SAVING,
  SET_IS_CHANGE_PASSWORD,
  SET_SERVER_ERROR,
  SET_USER_FORM,
  UPDATE_USER_FORM,
  UPDATE_PERMISSIONS,
} from '../../../actions/ui/settings/users.js'
import {NAVIGATE} from '../../../actions/ui/index.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'
import composeReducers from '../../../utils/composeReducers.js'

function isSaving(
  state = INITIAL_STATE.ui.settings.users.isSaving,
  action = {},
) {
  if (action.type === SET_IS_SAVING) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return INITIAL_STATE.ui.settings.users.isSaving
  }

  return state
}

function isChangingPassword(
  state = INITIAL_STATE.ui.settings.users.isChangingPassword,
  action = {},
) {
  if (action.type === SET_IS_CHANGE_PASSWORD) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return INITIAL_STATE.ui.settings.users.isChangingPassword
  }

  return state
}

function serverError(
  state = INITIAL_STATE.ui.settings.users.serverError,
  action = {},
) {
  if (action.type === SET_SERVER_ERROR) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return INITIAL_STATE.ui.settings.users.serverError
  }

  return state
}

function editLink(
  state = INITIAL_STATE.ui.settings.users.editLink,
  action = {},
) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    if (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'user' &&
      pathComponents[2] &&
      pathComponents[2] !== NEW_ID
    ) {
      return `/user/${pathComponents[2]}/`
    }

    return INITIAL_STATE.ui.settings.users.editLink
  }

  return state
}

function isNew(state = INITIAL_STATE.ui.settings.users.isNew, action = {}) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    return (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'user' &&
      pathComponents[2] === NEW_ID
    )
  }

  return state
}

function hasChanged(
  state = INITIAL_STATE.ui.settings.users.hasChanged,
  action = {},
) {
  if (action.type === NAVIGATE) {
    return INITIAL_STATE.ui.settings.users.hasChanged
  }

  return state
}

function userForm(
  state = INITIAL_STATE.ui.settings.users.userForm,
  action = {},
) {
  if (action.type === NAVIGATE) {
    return INITIAL_STATE.ui.settings.users.userForm
  }

  return state
}

const reducer = combineReducers({
  isSaving,
  isChangingPassword,
  serverError,
  editLink,
  isNew,
  hasChanged,
  userForm,
})

function handleSetUserForm(state, action) {
  if (action.type === SET_USER_FORM) {
    return {
      ...state,
      userForm: action.payload,
      hasChanged: {},
    }
  }

  return state
}

function handleUpdateUserForm(state, action = {}) {
  if (action.type === UPDATE_USER_FORM) {
    return {
      ...state,
      userForm: {
        ...state.userForm,
        ...action.payload,
      },
      hasChanged: {
        ...state.hasChanged,
        ...reduce(
          action.payload,
          (prev, value, key) => {
            prev[key] = true

            return prev
          },
          {},
        ),
      },
    }
  }

  return state
}

function handleUpdatePermissions(state, action = {}) {
  if (action.type === UPDATE_PERMISSIONS) {
    return {
      ...state,
      userForm: {
        ...state.userForm,
        permissions: {
          ...state.userForm.permissions,
          ...action.payload,
        },
      },
      hasChanged: {
        ...state.hasChanged,
        permissions: true,
      },
    }
  }

  return state
}

export default composeReducers(
  reducer,
  handleSetUserForm,
  handleUpdateUserForm,
  handleUpdatePermissions,
)
