import defaults from 'lodash/defaults.js'
import isPlainObject from 'lodash/isPlainObject.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../store.js'
import abode from '../../common/abode.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {isSuperuserSelector} from '../../data/me.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  MOCK_ABODE_LABEL_DEFAULTS,
  MOCK_ABODE_RENDER_DEFAULTS,
} from './MockAbodeConstants.js'
import {SingleAddressLayouts} from '../../common/constants/AddressLabelLayouts.js'
import {showGlobalError} from '../GlobalErrorMessage.js'

export const MOCK_ABODE_FORM = 'MOCK_ABODE_FORM'
export const MOCK_ABODE_URI_COMPONENT = 'mock_abode'

export function setupMockAbodeForm() {
  setForm(MOCK_ABODE_FORM, {
    labelURLCache:
      '#/mock_abode/label?order=M-192837&template=10531&docs=shippinglabel&mock={}',
    rmaLabelURLCache:
      '#/mock_abode/rma_label?reference_id=RMA-192837&template=10531&mock={}',
    moURLCache: '#/mock_abode/mo?reference_id=MO-192837&mock={}',
    addressURLCache: `#/mock_abode/address?addressIDs=1234&layout=${SingleAddressLayouts[0].value}&mock={}`,
    barcodeURLCache: `#/mock_abode/barcode?value=1234&text=Test Text&copyCount=1&layout=${SingleAddressLayouts[0].value}`,
    emailURLCache:
      '#/mock_abode/email?template=email_apv_adjustment&mock={"seed":1}',
    renderURLCache: '#/mock_abode/render?layout_id=-1&mock={"seed":1}',
  })
}

export function updateMockAbodeForm(updates, meta) {
  updateForm(MOCK_ABODE_FORM, updates, meta)
}

export function removeMockAbodeForm() {
  removeForm(MOCK_ABODE_FORM)
}

export function setLabelURLCache(labelURLCache) {
  updateMockAbodeForm({labelURLCache}, {stickyProps: ['labelURLCache']})
}

export function setRMALabelURLCache(rmaLabelURLCache) {
  updateMockAbodeForm({rmaLabelURLCache}, {stickyProps: ['rmaLabelURLCache']})
}

export function setMOURLCache(moURLCache) {
  updateMockAbodeForm({moURLCache}, {stickyProps: ['moURLCache']})
}

export function setAddressURLCache(addressURLCache) {
  updateMockAbodeForm({addressURLCache}, {stickyProps: ['addressURLCache']})
}

export function setBarcodeURLCache(barcodeURLCache) {
  updateMockAbodeForm({barcodeURLCache}, {stickyProps: ['barcodeURLCache']})
}

export function setEmailURLCache(emailURLCache) {
  updateMockAbodeForm({emailURLCache}, {stickyProps: ['emailURLCache']})
}

export function setRenderURLCache(renderURLCache) {
  updateMockAbodeForm({renderURLCache}, {stickyProps: ['renderURLCache']})
}

export function canUseMockAbodeSelector(state) {
  return isSuperuserSelector(state)
}

export function mockAbodeFormSelector(state) {
  return formsSelector(state)[MOCK_ABODE_FORM]
}

export function labelURLCacheSelector(state) {
  return mockAbodeFormSelector(state).labelURLCache
}

export function rmaLabelURLCacheSelector(state) {
  return mockAbodeFormSelector(state).rmaLabelURLCache
}

export function moURLCacheSelector(state) {
  return mockAbodeFormSelector(state).moURLCache
}

export function addressURLCacheSelector(state) {
  return mockAbodeFormSelector(state).addressURLCache
}

export function barcodeURLCacheSelector(state) {
  return mockAbodeFormSelector(state).barcodeURLCache
}

export function emailURLCacheSelector(state) {
  return mockAbodeFormSelector(state).emailURLCache
}

export function renderURLCacheSelector(state) {
  return mockAbodeFormSelector(state).renderURLCache
}

export function resourceSelector(state) {
  const {
    pathComponents: [, path],
  } = locationSelector(state)

  return path || ''
}

export function querySelector(state) {
  let {query} = locationSelector(state)
  const resource = resourceSelector(state)

  query = defaults(
    {},
    query,
    resource === 'label'
      ? MOCK_ABODE_LABEL_DEFAULTS
      : resource === 'render'
        ? MOCK_ABODE_RENDER_DEFAULTS
        : undefined,
  )

  for (const key of Object.keys(query)) {
    let value = query[key]

    if (value === 'true') {
      value = true
    } else if (value === 'false') {
      value = false
    }

    query[key] = value
  }
  return query
}

export function buildMockSearchParams(query) {
  const searchParams = new URLSearchParams()

  for (const key of Object.keys(query)) {
    let value = query[key]

    if (value === false || value === null || value === '') {
      continue
    }

    value = Array.isArray(value) ? value : [value]

    for (let v of value) {
      if (isPlainObject(v)) {
        v = JSON.stringify(v)
      }

      searchParams.append(key, v)
    }
  }

  return searchParams
}

export function mockAbodeURLSelector(state) {
  const resource = resourceSelector(state)
  const query = querySelector(state)

  if (!resource) {
    return ''
  }

  const url = new URL(window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_ABODE_URL)
  url.pathname = url.pathname === '/' ? resource : `${url.pathname}/${resource}`

  url.search = buildMockSearchParams(query)

  return url.toString()
}

export function mockAbodeGoToResource(resource) {
  let url = ''

  if (resource === 'label') {
    url = labelURLCacheSelector(getState())
  } else if (resource === 'rma_label') {
    url = rmaLabelURLCacheSelector(getState())
  } else if (resource === 'address') {
    url = addressURLCacheSelector(getState())
  } else if (resource === 'barcode') {
    url = barcodeURLCacheSelector(getState())
  } else if (resource === 'email') {
    url = emailURLCacheSelector(getState())
  } else if (resource === 'render') {
    url = renderURLCacheSelector(getState())
  } else if (resource === 'mo') {
    url = moURLCacheSelector(getState())
  }

  if (!url) {
    return
  }

  window.location.href = url
}

export function mockAbodeUpdateResourceQuery(queryUpdate) {
  const resource = resourceSelector(getState())
  let query = querySelector(getState())

  query = {
    ...query,
    ...queryUpdate,
  }

  const searchParams = buildMockSearchParams(query)

  const hash = `#/${MOCK_ABODE_URI_COMPONENT}/${resource}?${searchParams.toString()}`

  if (resource === 'label') {
    setLabelURLCache(hash)
  } else if (resource === 'rma_label') {
    setRMALabelURLCache(hash)
  } else if (resource === 'address') {
    setAddressURLCache(hash)
  } else if (resource === 'barcode') {
    setBarcodeURLCache(hash)
  } else if (resource === 'email') {
    setEmailURLCache(hash)
  } else if (resource === 'render') {
    setRenderURLCache(hash)
  } else if (resource === 'mo') {
    setMOURLCache(hash)
  }

  window.location.href = hash
}

export async function mockAbodeSendAsEmail() {
  try {
    const resource = resourceSelector(getState())
    let query = querySelector(getState())

    query = {
      ...query,
      email_address: 'testemail@ordoro.com',
    }

    const searchParams = buildMockSearchParams(query)

    await abode(`/${resource}?${searchParams.toString()}`)

    showMessageToast('Test email sent. Check your spam folder.')
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error sending email.',
        details: err.message,
      },
      err,
    )
  }
}
