import PropTypes from 'prop-types'
import classNames from 'classnames'
import isNil from 'lodash/isNil.js'

import NumberInput from './NumberInput.js'

export default function DimensionInput({
  value,
  onChange,
  label,
  hasError,
  disabled,
  ...props
}) {
  value = isNil(value) ? '' : value

  return (
    <div className="flex margin-bottom-5">
      <NumberInput
        value={value}
        isInvalid={hasError}
        onChange={onChange}
        onIncrement={onChange}
        onDecrement={onChange}
        min={0}
        disabled={disabled}
        {...props}
      />
      <span
        className={classNames('input__unit inline-block', {'op-50': disabled})}
      >
        {label}
      </span>
    </div>
  )
}

DimensionInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
}
