export function stickyActionPlayback(store) {
  const stickyActions = getStickyActions()

  Object.keys(stickyActions).forEach((stickyKey) => {
    store.dispatch(stickyActions[stickyKey])
  })
}

export const stickyMiddleware = () => (next) => (action) => {
  if (action && action.meta && action.meta.sticky) {
    const stickyActions = getStickyActions()

    stickyActions[action.meta.sticky] = {
      type: action.type,
      payload: action.payload,
    }

    setStickyActions(stickyActions)
  }

  return next(action)
}

export function getStickyActionsKey() {
  return `stickyActions:company=${window.ORDORO_BOOTSTRAP.company.id}:me=${window.ORDORO_BOOTSTRAP.me.id}`
}

export function clearStickyStore() {
  localStorage.removeItem(getStickyActionsKey())
}

export function getStickyActions() {
  const stickyActionsString = localStorage.getItem(getStickyActionsKey())

  return JSON.parse(stickyActionsString || '{}')
}

export function setStickyActions(stickyActions) {
  localStorage.setItem(getStickyActionsKey(), JSON.stringify(stickyActions))
}

export function clearStickyValue(key) {
  const stickyActions = getStickyActions()

  delete stickyActions[key]

  setStickyActions(stickyActions)
}
