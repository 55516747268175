import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'
import {useSelector} from '../../store.js'
import {isInTrialSelector} from '../../data/company.js'
import loadScript from '../loadScript.js'
import {usePostMessage} from './PostMessage.js'

const CALENDAR_LINKS = {
  dustin: 'https://meetings.hubspot.com/dustin236?embed=true',
  danny:
    'https://meetings.hubspot.com/daniel-greenup/inventory-management-module-consultation?embed=true',
  matt: 'https://meetings.hubspot.com/matt-omohundro/matt-round-robin-test?embed=true',
  brian:
    'https://meetings.hubspot.com/brian-everett/inventory-management-module-consult?embed=true',
}

function onIFrameMessage(message, onMessage) {
  if (!window.MeetingsEmbedCode) {
    return
  }

  if (!window.MeetingsEmbedCode.isHubSpotOrigin(message.origin)) {
    return
  }

  if (!message.data) {
    return
  }

  onMessage(message.data)
}

export default function CalendarScheduler({onMessage}) {
  const ref = useRef()
  const isInTrial = useSelector(isInTrialSelector)

  useEffect(() => {
    loadScript(
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
    )
  }, [])

  usePostMessage((event) => onIFrameMessage(event, onMessage))

  return (
    <div
      ref={ref}
      className="meetings-iframe-container meetings-iframe-container-650-height"
      data-src={isInTrial ? CALENDAR_LINKS.brian : CALENDAR_LINKS.danny}
    ></div>
  )
}

CalendarScheduler.propTypes = {
  onMessage: PropTypes.func.isRequired,
}
