import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {
  updateParcel,
  parcelSelector,
  descriptionErrorMessageSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {X_DELIVERY} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'packages[0].description'
const VALID_SHIPPER_TYPES = [X_DELIVERY]

export default function Description({packagesIndex}) {
  packagesIndex = packagesIndex || 0
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()

  const description = useSelector(
    (state) =>
      get(parcelSelector(state, {labelInfoID, packagesIndex}), 'description') ||
      '',
  )
  const error = useSelector((state) =>
    descriptionErrorMessageSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ConfigTextInput
      label="Description"
      onChange={(value) => {
        updateParcel(labelInfoID, packagesIndex, {description: value})

        onChange()
      }}
      value={description}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      errorMessage={error}
    />
  )
}

Description.propTypes = {
  packagesIndex: PropTypes.number,
}
