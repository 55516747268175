/* eslint react/no-danger: 0 */
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {sanitize} from '../../common/sanitize.js'
import {dismissAppMessage} from '../../redux/actions/data/commonAppMessage.js'
import {
  bannerMessageSelector,
  showBannerMessageSelector,
  undismissableBannerMessageSelector,
  showUndismissableBannerMessageSelector,
} from '../../redux/selectors/data/commonAppMessage.js'
import {isSuperuserSelector} from '../../data/me.js'

export function BannerMessage({
  bannerMessage,
  showBannerMessage,
  isSuperuser,
  ...props
}) {
  if (!showBannerMessage) {
    return null
  }

  const message =
    isSuperuser && bannerMessage.label
      ? `${bannerMessage.message} (${bannerMessage.label})`
      : bannerMessage.message

  return (
    <div
      className={classNames(
        'full-width wrap--banner-msg',
        bannerMessage.meta.className,
      )}
      style={
        bannerMessage.meta.style && {
          backgroundColor: bannerMessage.meta.style['background-color'],
          color: bannerMessage.meta.style['color'],
        }
      }
    >
      <div
        className="fs-00 lh-md align-center margin-bottom-0"
        dangerouslySetInnerHTML={{
          __html: sanitize(message, {
            allowedAttributes: {'*': ['class', 'style']},
          }),
        }}
      />
      {!bannerMessage.meta.can_not_dismiss && (
        <button
          className="btn btn--link no-underline btn--close-banner-msg"
          onClick={() => props.dismissAppMessage(bannerMessage.id)}
        >
          <span className="i--close fs-01" aria-hidden="true" />
        </button>
      )}
    </div>
  )
}

BannerMessage.propTypes = {
  undismissable: PropTypes.bool,
  bannerMessage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
      className: PropTypes.string,
      style: PropTypes.object,
      can_not_dismiss: PropTypes.bool,
    }),
  }),
  isSuperuser: PropTypes.bool.isRequired,
  showBannerMessage: PropTypes.bool.isRequired,
  dismissAppMessage: PropTypes.func.isRequired,
}

function mapStateToProps(state, {undismissable}) {
  if (undismissable) {
    return {
      bannerMessage: undismissableBannerMessageSelector(state),
      showBannerMessage: showUndismissableBannerMessageSelector(state),
      isSuperuser: isSuperuserSelector(state),
    }
  }

  return {
    bannerMessage: bannerMessageSelector(state),
    showBannerMessage: showBannerMessageSelector(state),
    isSuperuser: isSuperuserSelector(state),
  }
}

const mapDispatchToProps = {
  dismissAppMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerMessage)
