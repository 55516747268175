import {useSelector} from '../../../../store.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {
  showCartSyncModal,
  allowCartSyncModalSelector,
} from '../../../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {
  needsCartSelector,
  cartsHaveLoadedSelector,
} from '../../../../data/carts.js'
import {
  activeCartTaskSelector,
  getTaskStatus,
} from '../../../../redux/selectors/data/tasks.js'
import {isRunningTasksSelector} from '../../../../redux/selectors/data/isRunningTasks.js'
import {
  batchReferenceIDSelector,
  hasActiveFiltersSelector,
  orderListQuerySelector,
} from '../../../OrderListPage/orderListSelectors.js'
import {
  AWAITING_FULFILLMENT,
  ORDER_STATUS_BY_SLUG,
} from '../../../../common/constants/Orders.js'
import {showQuickShipModal} from '../../../OrderListPage/Modals/QuickShipModal/quickShipModalFunctions.js'
import {showEditCartModal} from '../../../settings/Modals/EditCartModal.js'
import {allCountSelector} from '../../../../data/orderCounts.js'
import {shipAppSelector} from '../../../../data/company.js'
import className from '../../../../common/className.js'

export default function NoOrders() {
  const task = useSelector(activeCartTaskSelector)
  const isRunningTasks = useSelector(isRunningTasksSelector)
  const hasActiveFilters = useSelector(hasActiveFiltersSelector)
  const {status, searchText} = useSelector(orderListQuerySelector)
  const cartsHaveLoaded = useSelector(cartsHaveLoadedSelector)
  const needsCart = useSelector(needsCartSelector)
  const allowCartSyncModal = useSelector(allowCartSyncModalSelector)
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const allCount = useSelector(allCountSelector)
  const shipApp = useSelector(shipAppSelector)

  const hasSearchText = !!searchText
  const displayStatus =
    status.length === 0
      ? ''
      : ORDER_STATUS_BY_SLUG[status[0]].name.toLowerCase()

  if (hasActiveFilters || hasSearchText) {
    return (
      <dl className="list--empty-content">
        <dt className="fs-02 margin-bottom-10">
          No {displayStatus} orders match the current
          {hasSearchText && <span> search terms </span>}
          {hasSearchText && hasActiveFilters && <span> and </span>}
          {hasActiveFilters && <span> active filters </span>}
        </dt>
      </dl>
    )
  }

  if (batchReferenceID) {
    return (
      <dl className="list--empty-content">
        <dt className="fs-02 margin-bottom-10">
          There are no orders in this batch
        </dt>
      </dl>
    )
  }

  if (needsCart && cartsHaveLoaded) {
    return (
      <>
        <div className="wrap--row margin-bottom-40">
          <div className="medium-12 columns">
            <dl className="list--empty-content margin-auto">
              <dt className="margin-bottom-10">
                {allCount === 0 && (
                  <div className="fs-03 lh-md margin-bottom-20">
                    There are no orders in your account.
                  </div>
                )}
              </dt>
              <dd className="fs-02 lh-md margin-bottom-0">
                Here are a few ways you can get started:
              </dd>
            </dl>

            <div className="wrap--row flex">
              <div
                className={className`medium-4 columns flex ${{
                  'medium-offset-2': !shipApp,
                }}`}
              >
                <dl className="list list--app-resource list--app-resource-simplest w-100 margin-bottom-30">
                  <dt className="list__title fs-01 lh-md margin-bottom-10">
                    Simple Ways to Import Orders
                  </dt>
                  <dd className="list__item fs-00 lh-md margin-bottom-20">
                    If you don’t have an ecommerce store or if it isn’t
                    compatible with Ordoro, no worries!
                  </dd>
                  <dd className="list__item margin-bottom-0 flex flex-wrap">
                    <div className="flex margin-right-7 margin-bottom-15">
                      <a
                        className="btn btn--primary btn--sm btn--primary-alt ws-normal margin-right-7"
                        href="#/dataimport"
                      >
                        Use a Spreadsheet
                      </a>
                      <div className="flex--justify-col">
                        <a
                          className="btn btn--link no-underline lighter"
                          href="https://support.ordoro.com/data-import/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Learn More"
                        >
                          <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                        </a>
                      </div>
                    </div>
                    <a
                      className="btn btn--primary btn--sm btn--primary-ol ws-normal margin-bottom-15"
                      href="#/createorder"
                    >
                      Create an Order
                    </a>
                  </dd>
                </dl>
              </div>

              <div className="medium-4 columns end flex">
                <dl className="list list--app-resource w-100 list--app-resource-best margin-bottom-30">
                  <dt className="list__title fs-01 lh-md margin-bottom-10">
                    Best Way to Get Started
                  </dt>
                  <dd className="list__item fs-00 lh-md margin-bottom-20">
                    Connect a store for automatic order import.
                  </dd>
                  <dd className="list__item margin-bottom-0 flex">
                    <ButtonPrimary
                      size="sm"
                      className="margin-right-7 ws-normal"
                      onClick={() => showEditCartModal()}
                    >
                      Connect Your Store
                    </ButtonPrimary>
                    <div className="flex--justify-col">
                      <a
                        className="btn btn--link no-underline lighter"
                        href="https://support.ordoro.com/getting-started-with-ordoro/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Learn More"
                      >
                        <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                      </a>
                    </div>
                  </dd>
                </dl>
              </div>
              {!!shipApp && (
                <div className="medium-4 columns end flex">
                  <dl className="list list--app-resource list--app-resource-fastest margin-bottom-30">
                    <dt className="list__title fs-01 lh-md margin-bottom-10">
                      Fastest Way to Try Out Shipping
                    </dt>
                    <dd className="list__item fs-00 lh-md margin-bottom-20">
                      Use Quick Ship to create a shipping label without
                      importing any orders.
                    </dd>

                    <dd className="list__item margin-bottom-0 flex">
                      <ButtonPrimary
                        size="sm"
                        className="ws-normal margin-right-7"
                        onClick={() => showQuickShipModal()}
                      >
                        Try Quick Ship
                      </ButtonPrimary>
                      <div className="flex--justify-col">
                        <a
                          className="btn btn--link no-underline lighter"
                          href="https://support.ordoro.com/how-does-quick-ship-work/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Learn More"
                        >
                          <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                        </a>
                      </div>
                    </dd>
                  </dl>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  // Use isRunningTasks instead of task.complete in case the user starts multiple tasks.
  // Tasks aren't guaranteed to finish in created order.
  return (
    <dl className="list--empty-content">
      <dt className="fs-02 margin-bottom-10">
        <span>There are no {displayStatus} orders</span>
      </dt>

      {allowCartSyncModal &&
        (status.length === 0 || status.includes(AWAITING_FULFILLMENT)) && (
          <>
            {isRunningTasks && (
              <dd className="fs-01 margin-bottom-20">
                ...but hang tight, it looks like an order import is running
              </dd>
            )}

            {!isRunningTasks && (
              <dd className="fs-01 margin-bottom-20">
                If you have new pending orders in your sales channel, you can
                sync to import them into Ordoro
              </dd>
            )}
            <dd className="w-50 margin-auto">
              <button
                className={className`btn btn--primary btn--primary-alt btn--md ${{
                  'btn--processing': isRunningTasks,
                }}`}
                type="button"
                onClick={() => showCartSyncModal()}
              >
                <span
                  className="i--sync inline-block v-align-middle fs-01 op-75 margin-right-5"
                  aria-hidden="true"
                />
                <span>Sync orders</span>
              </button>
              {isRunningTasks && task && (
                <div className="margin-top-7">
                  <small>
                    <em>{getTaskStatus(task)}</em>
                  </small>
                </div>
              )}
            </dd>
          </>
        )}
    </dl>
  )
}
