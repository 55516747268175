import PropTypes from 'prop-types'
import cn from '../../../common/className.js'

export default function Tab({className, active, disabled, children, onClick}) {
  return (
    <li className={cn`list__item--tab ${className} ${{active, disabled}}`}>
      <button
        className="btn btn--tab"
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </li>
  )
}

Tab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
