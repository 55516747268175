import PropTypes from 'prop-types'

import {showMultiboxModal} from '../../../../../../OrderListPage/Modals/MultiboxModal/index.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'

export default function EditForm({
  label,
  value,
  shipperType,
  isInvalid,
  disabled,
  isMultibox,
  onEditClick,
}) {
  const {labelInfoID} = useLabelConfigContext()

  return (
    <div className={isInvalid ? 'wrap--error' : undefined}>
      <strong className="margin-right-3">{label}:</strong>
      {disabled ? (
        isMultibox ? (
          <em>Multiple</em>
        ) : (
          <span>{value || <em>Not set</em>}</span>
        )
      ) : isMultibox ? (
        <button
          className="btn btn--link v-align-base"
          type="button"
          onClick={() => showMultiboxModal(labelInfoID, shipperType)}
        >
          <em>Multiple</em>
        </button>
      ) : (
        <button
          className="btn btn--link darker v-align-base"
          type="button"
          onClick={() => onEditClick()}
        >
          <span>{value || <em>Not set</em>}</span>
        </button>
      )}
    </div>
  )
}

EditForm.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string.isRequired,
  shipperType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool.isRequired,
  isMultibox: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
}
