import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {put, select, call} from 'redux-saga/effects'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {isPresent} from '../../../common/utils.js'
import apiverson from '../../../common/apiverson.js'
import {formConnectAuto} from '../../../common/formConnect.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {updateReturnOrderCode} from '../../../redux/actions/data/returnOrderCodes.js'
import {navigate} from '../../../redux/actions/ui/index.js'
import {updateForm} from '../../../redux/actions/ui/forms.js'
import {returnOrderCodeSelector} from '../../../redux/selectors/data/returnOrderCodes.js'
import {formSelector} from '../../../redux/selectors/ui/forms.js'
import TextInput from '../Common/TextInput.js'
import Column from '../Common/Column.js'
import FormList from '../Common/FormList.js'
import FormListItem from '../Common/FormListItem.js'

export const SAVE_RETURN_ORDER_CODE = 'SAVE_RETURN_ORDER_CODE'
const RETURN_ORDER_CODE_FORM = 'RETURN_ORDER_CODE_FORM'

function updateCodeForm(updates) {
  return updateForm(RETURN_ORDER_CODE_FORM, updates)
}

function codeFormSelector(state) {
  return formSelector(state, {key: RETURN_ORDER_CODE_FORM})
}

function errorsSelector(state) {
  const form = codeFormSelector(state)
  const errors = {}

  if (!form) {
    return errors
  }

  if (form.codeHasChanged && !isPresent(form.code)) {
    errors.code = 'Code is required'
  }

  if (!isPresent(form.code)) {
    errors.preventSave = true
  }

  return errors
}

function saveReturnOrderCode() {
  return {
    type: SAVE_RETURN_ORDER_CODE,
  }
}

export function* saveReturnOrderCodeWorker() {
  try {
    const {id, code, description} = yield select(codeFormSelector)
    const {code: originalCode} = id
      ? yield select(returnOrderCodeSelector, {returnOrderCodeID: id})
      : {code: null}

    const data = {
      code,
      description,
    }

    const {json} = originalCode
      ? yield call(
          apiverson.put,
          `/return_order/code/${encodeURIComponent(originalCode)}`,
          data,
        )
      : yield call(apiverson.post, '/return_order/code', data)

    yield put(updateReturnOrderCode(json))

    yield put(navigate('#/settings/return_order_codes'))
  } catch (err) {
    yield put(
      updateCodeForm({
        serverError: err.message,
      }),
    )
  }
}

function ReturnOrderCodeForm({form, errors, ...props}) {
  return (
    <form
      className="wrap--row margin-top-30"
      onSubmit={(event) => {
        event.preventDefault()

        props.saveReturnOrderCode()
      }}
    >
      <Column className="medium-6">
        <div>{form.serverError}</div>
        <FormList>
          <FormListItem>
            <TextInput
              name="code"
              label="Code"
              required
              value={form.code}
              errorMessage={errors.code}
              onChange={(event) =>
                props.updateCodeForm({
                  code: event.target.value,
                  hasChangedCode: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="description"
              label="Description"
              value={form.description}
              errorMessage={errors.description}
              onChange={(event) =>
                props.updateCodeForm({
                  description: event.target.value,
                  hasChangedDescription: true,
                })
              }
            />
          </FormListItem>
        </FormList>
        <div className="divider--top padding-top-15">
          <ButtonPrimary
            type="submit"
            size="std"
            isDisabled={errors.preventSave || form.isSaving}
            isLoading={form.isSaving}
          >
            {form.id ? 'Save' : 'Create'}
          </ButtonPrimary>
          <a
            className="btn btn--secondary"
            href="#/settings/return_order_codes"
          >
            Cancel
          </a>
        </div>
      </Column>
    </form>
  )
}

ReturnOrderCodeForm.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  errors: ErrorsShape.isRequired,
  updateCodeForm: PropTypes.func.isRequired,
  saveReturnOrderCode: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    errors: errorsSelector(state),
  }
}

const mapDispatchToProps = {
  updateCodeForm,
  saveReturnOrderCode,
}

export default formConnectAuto(
  connect(mapStateToProps, mapDispatchToProps)(ReturnOrderCodeForm),
  (state, {returnOrderCodeID}) => {
    const code = returnOrderCodeSelector(state, {returnOrderCodeID})

    return {
      formName: RETURN_ORDER_CODE_FORM,
      initialForm: {
        id: code ? code.id : null,
        code: code ? code.code : '',
        description: code ? code.description : '',
        isSaving: false,
        serverError: null,
      },
    }
  },
)
