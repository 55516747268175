import {all, select, put, call, takeLatest, takeEvery} from 'redux-saga/effects'

import {fetchAllAPI} from '../../../common/fetchAll.js'
import {
  GET_COMMON_APP_MESSAGE,
  DISMISS_APP_MESSAGE,
  setAppMessages,
  setDismissedAppMessageIDs,
} from '../../actions/data/commonAppMessage.js'
import {commonAppMessageSelector} from '../../selectors/data/commonAppMessage.js'

export function* getCommonAppMessageWorker() {
  try {
    const appMessages = yield call(fetchAllAPI, '/app_message/', 'app_message')

    yield put(setAppMessages(appMessages))
  } catch (_err) {
    // The user shouldn't be bothered with errors from this
  }
}

export function* dismissAppMessageWorker({payload: {appMessageID}}) {
  const {dismissedAppMessageIDs} = yield select(commonAppMessageSelector)

  let newIDs = dismissedAppMessageIDs.filter((id) => id !== appMessageID)

  newIDs.push(appMessageID)

  newIDs = newIDs.slice(-20)

  yield put(setDismissedAppMessageIDs(newIDs))
}

export default function* commonAppMessageSaga() {
  yield all([
    takeLatest(GET_COMMON_APP_MESSAGE, getCommonAppMessageWorker),
    takeEvery(DISMISS_APP_MESSAGE, dismissAppMessageWorker),
  ])
}
