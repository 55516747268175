import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  addHazmatItem,
  hazmatItemsSelector,
  labelShipFromAddressSelector,
  maxHazmatItemCountSelector,
  updateHazmatItem,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import TextInput from '../../../common/components/TextInput.js'
import Select from '../../../common/components/Select.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {isNonZeroPositiveNumeric, isPresent} from '../../../common/utils.js'
import className from '../../../common/className.js'
import {companySelector} from '../../../data/company.js'
import {meSelector} from '../../../data/me.js'
import ShipperOptions from '../../../data/shipperOptions.js'

export const MODAL_FORM = 'HAZMAT_MODAL'

export function showHazmatModal(
  labelInfoID,
  shipperType,
  packageIndex,
  hazmatItemIndex,
) {
  const company = companySelector(getState())
  const me = meSelector(getState())
  const shipFrom = labelShipFromAddressSelector(getState(), {labelInfoID})
  const hazmatItems = hazmatItemsSelector(getState(), {
    labelInfoID,
    packageIndex,
  })
  const previousHazmatItem = hazmatItems[hazmatItems.length - 1] || {}

  const hazmatItem = hazmatItems[hazmatItemIndex] || {
    commodity_regulated_level_code: '',
    regulation_set: '',
    transport_category: '',
    tunnel_restriction_code: '',
    transportation_mode: '',
    technical_name: '',
    additional_description: '',
    class_division_number: '',
    hazard_label_required: '',
    id_number: '',
    packaging_instruction_code: '',
    packaging_group_type: '',
    packaging_type: '',
    packaging_type_quantity: '',
    proper_shipping_name: '',
    quantity: '',
    reportable_quantity: '',
    sub_risk_class: '',
    uom: '',
    emergency_contact: previousHazmatItem.emergency_contact || me.name,
    emergency_phone:
      previousHazmatItem.emergency_phone || shipFrom.phone || company.phone,
  }

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    packageIndex,
    hazmatItemIndex,
    isNew: hazmatItemIndex === undefined,
    isExpanded: false,
    commodity_regulated_level_code:
      hazmatItem[`${shipperType}__commodity_regulated_level_code`],
    regulation_set: hazmatItem[`${shipperType}__regulation_set`],
    transport_category: hazmatItem[`${shipperType}__transport_category`],
    tunnel_restriction_code:
      hazmatItem[`${shipperType}__tunnel_restriction_code`],
    transportation_mode: hazmatItem[`${shipperType}__transportation_mode`],
    packaging_group_type: hazmatItem[`${shipperType}__packaging_group_type`],
    ...hazmatItem,
    emergency_contact: hazmatItem.emergency_contact || '',
    emergency_phone: hazmatItem.emergency_phone || '',
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function errorsSelector(state) {
  const {
    proper_shipping_name,
    packaging_type_quantity,
    quantity,
    reportable_quantity,
    commodity_regulated_level_code,
    regulation_set,
    transport_category,
    tunnel_restriction_code,
    transportation_mode,
    uom,
    emergency_contact,
    emergency_phone,
  } = modalFormSelector(state)
  const errors = {}

  if (!isPresent(proper_shipping_name)) {
    errors.proper_shipping_name = 'Proper Shipping Name is required'
    errors.preventSave = true
  }

  if (!isNonZeroPositiveNumeric(quantity)) {
    errors.quantity = 'Quantity must be a positive number'
    errors.preventSave = true
  }

  if (!isPresent(commodity_regulated_level_code)) {
    errors.commodity_regulated_level_code =
      'Commodity Regulated Level is required'
    errors.preventSave = true
  }

  if (!isPresent(regulation_set)) {
    errors.regulation_set = 'Regulation Set is required'
    errors.preventSave = true
  }

  if (regulation_set === 'ADR') {
    if (!isPresent(transport_category)) {
      errors.transport_category = 'Transport Category is required'
      errors.preventSave = true
    }

    if (!isPresent(tunnel_restriction_code)) {
      errors.tunnel_restriction_code = 'Tunnel Restriction Code is required'
      errors.preventSave = true
    }
  }

  if (!isPresent(transportation_mode)) {
    errors.transportation_mode = 'Transportation is required'
    errors.preventSave = true
  }

  if (!isPresent(uom)) {
    errors.uom = 'UOM is required'
    errors.preventSave = true
  }

  if (!isPresent(emergency_contact)) {
    errors.emergency_contact = 'Emergency Contact is required'
    errors.preventSave = true
  }

  if (!isPresent(emergency_phone)) {
    errors.emergency_phone = 'Emergency Phone is required'
    errors.preventSave = true
  }

  if (
    isPresent(packaging_type_quantity) &&
    !isNonZeroPositiveNumeric(packaging_type_quantity)
  ) {
    errors.packaging_type_quantity = 'Quantity must be a positive number'
    errors.preventSave = true
  }

  if (
    isPresent(reportable_quantity) &&
    !isNonZeroPositiveNumeric(reportable_quantity)
  ) {
    errors.reportable_quantity = 'Quantity must be a positive number'
    errors.preventSave = true
  }

  return errors
}

export async function confirm() {
  const {
    labelInfoID,
    shipperType,
    packageIndex,
    hazmatItemIndex,
    isNew,
    commodity_regulated_level_code,
    regulation_set,
    transport_category,
    tunnel_restriction_code,
    transportation_mode,
    technical_name,
    additional_description,
    class_division_number,
    hazard_label_required,
    id_number,
    packaging_instruction_code,
    packaging_group_type,
    packaging_type,
    packaging_type_quantity,
    proper_shipping_name,
    quantity,
    reportable_quantity,
    sub_risk_class,
    uom,
    emergency_contact,
    emergency_phone,
  } = modalFormSelector(getState())

  const hazmatItem = {
    [`${shipperType}__commodity_regulated_level_code`]:
      commodity_regulated_level_code,
    [`${shipperType}__regulation_set`]: regulation_set,
    [`${shipperType}__transport_category`]:
      regulation_set === 'ADR' ? transport_category : '',
    [`${shipperType}__tunnel_restriction_code`]:
      regulation_set === 'ADR' ? tunnel_restriction_code : '',
    [`${shipperType}__transportation_mode`]: transportation_mode,
    technical_name,
    additional_description,
    class_division_number,
    hazard_label_required,
    id_number,
    packaging_instruction_code,
    [`${shipperType}__packaging_group_type`]: packaging_group_type,
    packaging_type,
    packaging_type_quantity,
    proper_shipping_name,
    quantity,
    reportable_quantity,
    sub_risk_class,
    uom,
    emergency_contact,
    emergency_phone,
  }

  if (isNew) {
    addHazmatItem(labelInfoID, packageIndex, hazmatItem)
  } else {
    updateHazmatItem(labelInfoID, packageIndex, hazmatItemIndex, hazmatItem)
  }

  closeModal()

  showMessageToast(
    `Hazmat info was successfully ${isNew ? 'added' : 'updated'}.`,
  )

  await getRates([labelInfoID])
}

function HazmatModal({form}) {
  const errors = useSelector(errorsSelector)
  const shipperOptions = ShipperOptions[form.shipperType]
  const maxHazmatItemCount = useSelector((state) =>
    maxHazmatItemCountSelector(state, {
      labelInfoID: form.labelInfoID,
      shipperType: form.shipperType,
    }),
  )

  const commodityRegulatedLevelOptions = [
    {value: '', display: ''},
    ...(shipperOptions.hazmat_commodity_regulated_level || []),
  ]
  const regulationSetOptions = [
    {value: '', display: ''},
    ...(shipperOptions.hazmat_regulation_set || []),
  ]
  const transportCategoryOptions = [
    {value: '', display: ''},
    ...(shipperOptions.hazmat_transport_category || []),
  ]
  const tunnelRestrictionCodeOptions = [
    {value: '', display: ''},
    ...(shipperOptions.hazmat_tunnel_restriction_code || []),
  ]
  const transportationModeOptions = [
    {value: '', display: ''},
    ...(shipperOptions.hazmat_transportation_mode || []),
  ]

  const packagingGroupOptions = shipperOptions.hazmat_packaging_group || []

  return (
    <ConfirmModal
      title="Edit HazMat Declaration"
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      confirmText={form.isNew ? 'Add' : 'Update'}
      cancelText="Cancel"
      isDisabled={errors.preventSave}
    >
      <p className="fs-00 lh-md margin-bottom-25">
        <strong>
          To attach a hazardous materials declaration, fill in the required
          information in the following form.
        </strong>{' '}
        Please note that a package can have up to {maxHazmatItemCount} separate
        declarations.{' '}
        <a
          className="link"
          href="https://support.ordoro.com/how-do-i-manage-hazardous-materials-with-ups/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Learn more</strong>
        </a>{' '}
        <span className="op-50">→</span>
      </p>
      <ul className="list list--no-style margin-bottom-25">
        <li className="list__item margin-bottom-15">
          <TextInput
            id="proper_shipping_name"
            label="Proper Shipping Name"
            required
            value={form.proper_shipping_name || ''}
            onChange={(proper_shipping_name) =>
              updateModalForm({
                proper_shipping_name,
                hasChanged_proper_shipping_name: true,
              })
            }
            errorMessage={
              form.hasChanged_proper_shipping_name &&
              errors.proper_shipping_name
            }
          />
        </li>
        <li className="list__item margin-bottom-15">
          <NumberInput
            id="quantity"
            label="Quantity"
            value={form.quantity || ''}
            required
            onChange={(quantity) =>
              updateModalForm({
                quantity: String(quantity),
                hasChanged_quantity: true,
              })
            }
            errorMessage={form.hasChanged_quantity && errors.quantity}
          />
        </li>
        <li className="list__item margin-bottom-15">
          <Select
            label="Commodity Regulated Level"
            value={form.commodity_regulated_level_code}
            options={commodityRegulatedLevelOptions}
            required
            onChange={(commodity_regulated_level_code) =>
              updateModalForm({
                commodity_regulated_level_code,
                hasChanged_commodity_regulated_level_code: true,
              })
            }
            errorMessage={
              form.hasChanged_commodity_regulated_level_code &&
              errors.commodity_regulated_level_code
            }
          />
        </li>
        <li className="list__item margin-bottom-15">
          <Select
            label="Regulation Set"
            required
            value={form.regulation_set}
            options={regulationSetOptions}
            onChange={(regulation_set) =>
              updateModalForm({
                regulation_set,
                hasChanged_regulation_set: true,
              })
            }
            errorMessage={
              form.hasChanged_regulation_set && errors.regulation_set
            }
          />
        </li>
        {form.regulation_set === 'ADR' && (
          <>
            <li className="list__item margin-bottom-15">
              <Select
                label="Transport Category"
                required
                value={form.transport_category}
                options={transportCategoryOptions}
                onChange={(transport_category) =>
                  updateModalForm({
                    transport_category,
                    hasChanged_transport_category: true,
                  })
                }
                errorMessage={
                  form.hasChanged_transport_category &&
                  errors.transport_category
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <Select
                label="Tunnel Restriction Code"
                required
                value={form.tunnel_restriction_code}
                options={tunnelRestrictionCodeOptions}
                onChange={(tunnel_restriction_code) =>
                  updateModalForm({
                    tunnel_restriction_code,
                    hasChanged_tunnel_restriction_code: true,
                  })
                }
                errorMessage={
                  form.hasChanged_tunnel_restriction_code &&
                  errors.tunnel_restriction_code
                }
              />
            </li>
          </>
        )}
        <li className="list__item margin-bottom-15">
          <Select
            label="Transportation Mode"
            required
            value={form.transportation_mode}
            options={transportationModeOptions}
            onChange={(transportation_mode) =>
              updateModalForm({
                transportation_mode,
                hasChanged_transportation_mode: true,
              })
            }
            errorMessage={
              form.hasChanged_transportation_mode && errors.transportation_mode
            }
          />
        </li>
        <li className="list__item margin-bottom-15">
          <TextInput
            id="uom"
            label="UOM"
            required
            value={form.uom || ''}
            onChange={(uom) => updateModalForm({uom, hasChanged_uom: true})}
            errorMessage={form.hasChanged_uom && errors.uom}
          />
        </li>
        <li className="list__item margin-bottom-15">
          <TextInput
            id="emergency_contact"
            label="Emergency Contact"
            required
            value={form.emergency_contact || ''}
            onChange={(emergency_contact) =>
              updateModalForm({
                emergency_contact,
                hasChanged_emergency_contact: true,
              })
            }
            errorMessage={
              form.hasChanged_emergency_contact && errors.emergency_contact
            }
          />
        </li>
        <li className="list__item margin-bottom-15">
          <TextInput
            id="emergency_phone"
            label="Emergency Phone"
            required
            value={form.emergency_phone || ''}
            onChange={(emergency_phone) =>
              updateModalForm({
                emergency_phone,
                hasChanged_emergency_phone: true,
              })
            }
            errorMessage={
              form.hasChanged_emergency_phone && errors.emergency_phone
            }
          />
        </li>
      </ul>
      <dl
        className={className`list list--accordion lg ${
          form.isExpanded && 'open'
        }`}
      >
        <dt className="list__title--accordion">
          <button
            className="btn btn--dropdown fs-00"
            type="button"
            onClick={() => updateModalForm({isExpanded: !form.isExpanded})}
          >
            {form.isExpanded ? 'Hide' : 'Show'} Optional Fields
          </button>
        </dt>
        <dd className="list__item--accordion">
          <ul className="list list--no-style">
            <li className="list__item margin-bottom-15">
              <TextInput
                id="technical_name"
                label="Technical Name"
                value={form.technical_name || ''}
                onChange={(technical_name) =>
                  updateModalForm({
                    technical_name,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="additional_description"
                label="Additional Description"
                value={form.additional_description || ''}
                onChange={(additional_description) =>
                  updateModalForm({
                    additional_description,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="class_division_number"
                label="Class Division Number"
                value={form.class_division_number || ''}
                onChange={(class_division_number) =>
                  updateModalForm({
                    class_division_number,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="hazard_label_required"
                label="Type of Hazard Label Required"
                value={form.hazard_label_required || ''}
                onChange={(hazard_label_required) =>
                  updateModalForm({
                    hazard_label_required,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="id_number"
                label="ID Number"
                value={form.id_number || ''}
                onChange={(id_number) =>
                  updateModalForm({
                    id_number,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="packaging_instruction_code"
                label="Packaging Instruction Code"
                value={form.packaging_instruction_code || ''}
                onChange={(packaging_instruction_code) =>
                  updateModalForm({
                    packaging_instruction_code,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <Select
                label="Packaging Group Type"
                value={form.packaging_group_type}
                options={packagingGroupOptions}
                onChange={(packaging_group_type) =>
                  updateModalForm({
                    packaging_group_type,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="packaging_type"
                label="Packaging Type"
                value={form.packaging_type || ''}
                onChange={(packaging_type) =>
                  updateModalForm({
                    packaging_type,
                  })
                }
              />
            </li>
            <li className="list__item margin-bottom-15">
              <NumberInput
                id="packaging_type_quantity"
                label="Packaging Type Quantity"
                value={form.packaging_type_quantity || ''}
                onChange={(packaging_type_quantity) =>
                  updateModalForm({
                    packaging_type_quantity: String(packaging_type_quantity),
                  })
                }
                errorMessage={errors.packaging_type_quantity}
              />
            </li>
            <li className="list__item margin-bottom-15">
              <NumberInput
                id="reportable_quantity"
                label="Reportable Quantity"
                value={form.reportable_quantity || ''}
                onChange={(reportable_quantity) =>
                  updateModalForm({
                    reportable_quantity: String(reportable_quantity),
                  })
                }
                errorMessage={errors.reportable_quantity}
              />
            </li>
            <li className="list__item margin-bottom-15">
              <TextInput
                id="sub_risk_class"
                label="Sub Risk Class"
                value={form.sub_risk_class || ''}
                onChange={(sub_risk_class) =>
                  updateModalForm({
                    sub_risk_class,
                  })
                }
              />
            </li>
          </ul>
        </dd>
      </dl>
    </ConfirmModal>
  )
}

HazmatModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    packageIndex: PropTypes.number.isRequired,
    hazmatItemIndex: PropTypes.number,
    isNew: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    commodity_regulated_level_code: PropTypes.string.isRequired,
    hasChanged_commodity_regulated_level_code: PropTypes.bool,
    regulation_set: PropTypes.string.isRequired,
    hasChanged_regulation_set: PropTypes.bool,
    transport_category: PropTypes.string.isRequired,
    hasChanged_transport_category: PropTypes.bool,
    tunnel_restriction_code: PropTypes.string.isRequired,
    hasChanged_tunnel_restriction_code: PropTypes.bool,
    transportation_mode: PropTypes.string.isRequired,
    hasChanged_transportation_mode: PropTypes.bool,
    technical_name: PropTypes.string.isRequired,
    additional_description: PropTypes.string.isRequired,
    class_division_number: PropTypes.string.isRequired,
    hazard_label_required: PropTypes.string.isRequired,
    id_number: PropTypes.string.isRequired,
    packaging_instruction_code: PropTypes.string.isRequired,
    packaging_group_type: PropTypes.string.isRequired,
    packaging_type: PropTypes.string.isRequired,
    packaging_type_quantity: PropTypes.string.isRequired,
    proper_shipping_name: PropTypes.string.isRequired,
    hasChanged_proper_shipping_name: PropTypes.bool,
    quantity: PropTypes.string.isRequired,
    hasChanged_quantity: PropTypes.bool,
    reportable_quantity: PropTypes.string.isRequired,
    sub_risk_class: PropTypes.string.isRequired,
    uom: PropTypes.string.isRequired,
    hasChanged_uom: PropTypes.bool,
    emergency_contact: PropTypes.string.isRequired,
    hasChanged_emergency_contact: PropTypes.bool,
    emergency_phone: PropTypes.string.isRequired,
    hasChanged_emergency_phone: PropTypes.bool,
  }).isRequired,
}

export default onlyIfForm(HazmatModal, modalFormSelector)
