import PropTypes from 'prop-types'

import {useSelector} from '../../store.js'
import {isFilteredSelector} from './batchListSelectors.js'

export default function EmptyList({colSpan}) {
  const isFiltered = useSelector(isFilteredSelector)

  return (
    <tr className="table__tr">
      <td className="table__td" colSpan={colSpan}>
        <div className="padding-top-25 padding-bottom-25 align-center">
          <strong className="text--md-grey fs-02 lh-md">
            {isFiltered
              ? 'No Batches match the current search terms.'
              : 'There aren’t any Batches.'}
          </strong>
        </div>
      </td>
    </tr>
  )
}

EmptyList.propTypes = {
  colSpan: PropTypes.string.isRequired,
}
