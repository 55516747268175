import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {HEADER_SUPPORT_DROPDOWN} from './headerSelectors.js'

export function SupportIcon({currentDropdown}) {
  return (
    <li
      className={classNames('utility-nav__item wrap--dropdown dropdown', {
        open: currentDropdown === HEADER_SUPPORT_DROPDOWN,
      })}
      data-dropdown={HEADER_SUPPORT_DROPDOWN}
    >
      <button className="btn btn--ko-dropdown utility-nav__link dropdown">
        <span
          className="icon icon-support icon-block x-sm"
          aria-hidden="true"
        />{' '}
      </button>
      <div className="wrap--inner-dropdown wrap--inner-dropdown-right">
        <ul className="list--dropdown">
          <li className="list__item list__item--dropdown">
            <a
              className="list__link list__link--dropdown"
              href="//support.ordoro.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support Center
            </a>
          </li>
          <li className="list__item list__item--dropdown">
            <a
              className="list__link list__link--dropdown"
              href="//ordoro.statuspage.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              System Status
            </a>
          </li>
        </ul>
      </div>
    </li>
  )
}

SupportIcon.propTypes = {
  currentDropdown: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    currentDropdown: currentDropdownSelector(state),
  }
}

export default connect(mapStateToProps)(SupportIcon)
