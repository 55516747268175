import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {TAG_TYPE__ORDER} from '../../../../../common/constants/Tags.js'
import {
  hasOrderManageTagPermissionSelector,
  hasOrderManageTagOnOrderPermissionSelector,
} from '../../../../../data/me.js'
import {
  orderTagOptionsSelector,
  orderTagOptionTokensSelector,
  addTagToOrders,
  removeTagFromOrders,
} from '../../../../../data/orderTags.js'
import ManageTagFilter from '../../../../../common/components/ManageTagFilter.js'
import {currentDropdownSelector} from '../../../../../redux/selectors/ui/index.js'
import {
  activeOrderNumbersSelector,
  orderTagQuantityMapSelector,
} from '../../../../OrderListPage/orderListSelectors.js'
import {createAndApplyTagToOrders} from '../../../../OrderListPage/orderListActions.js'
import {getState} from '../../../../../store.js'

async function applyChanges(
  orderNumbers,
  tagQuantityMapChanges,
  setIsDisabledMap,
  setTagQuantityMapChanges,
) {
  const originalTagQuantityMap = orderTagQuantityMapSelector(getState())
  const promises = []
  const isDisabledMap = {}

  for (const [tagID, quantity] of Object.entries(tagQuantityMapChanges)) {
    const originalQuantity = originalTagQuantityMap[tagID]

    // Find any quantity value that changed (quantity values: all, some, none)
    if (originalQuantity !== quantity) {
      isDisabledMap[tagID] = true

      if (quantity === 'none') {
        // if flipped to 'none' then remove
        promises.push(removeTagFromOrders(tagID, orderNumbers))
      } else {
        // else we are adding the tag
        promises.push(addTagToOrders(tagID, orderNumbers))
      }
    }
  }

  // changes have been sent to api, so clear this
  setTagQuantityMapChanges({})

  // disable tag changes for specific tags while it's promise resolves
  try {
    setIsDisabledMap((oldMap) => ({...oldMap, ...isDisabledMap}))

    await Promise.all(promises)
  } finally {
    setIsDisabledMap((oldMap) => {
      for (const tagID of Object.keys(isDisabledMap)) {
        delete oldMap[tagID]
      }

      return {...oldMap}
    })
  }
}

export default function AddOrderTagsDropdown() {
  const dropdown = 'APPLY_ORDER_TAG_FILTER'

  const currentDropdown = useSelector(currentDropdownSelector)
  const tagQuantityMap = useSelector(orderTagQuantityMapSelector)
  const [isDisabledMap, setIsDisabledMap] = useState({})
  // keep track of changes a user makes
  const [tagQuantityMapChanges, setTagQuantityMapChanges] = useState({})
  const [dropdownIsOpen, setDropdownIsOpen] = useState(
    currentDropdown === dropdown,
  )
  const orderNumbers = useSelector(activeOrderNumbersSelector)
  const orderTagOptions = useSelector(orderTagOptionsSelector)
  const orderTagOptionTokens = useSelector(orderTagOptionTokensSelector)
  const hasOrderManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const hasOrderManageTagOnOrderPermission = useSelector(
    hasOrderManageTagOnOrderPermissionSelector,
  )

  useEffect(() => {
    setDropdownIsOpen(currentDropdown === dropdown)
  }, [currentDropdown])

  useEffect(() => {
    if (!dropdownIsOpen && Object.keys(tagQuantityMapChanges).length > 0) {
      // when dropdown closes apply changes that happened while dropdown was opened
      applyChanges(
        orderNumbers,
        tagQuantityMapChanges,
        setIsDisabledMap,
        setTagQuantityMapChanges,
      )
    }
  }, [dropdownIsOpen])

  if (!hasOrderManageTagOnOrderPermission) {
    return null
  }

  return (
    <li className="inline-block margin-left-10">
      <ManageTagFilter
        dropdown={dropdown}
        onSelect={(tag) => {
          const count = tagQuantityMapChanges[tag.id] || tagQuantityMap[tag.id]

          if (count === 'all') {
            // if it was 'all' then flip to 'none'
            setTagQuantityMapChanges({
              ...tagQuantityMapChanges,
              [tag.id]: 'none',
            })
          } else {
            // otherwise mark 'all'
            setTagQuantityMapChanges({
              ...tagQuantityMapChanges,
              [tag.id]: 'all',
            })
          }
        }}
        tagOptions={orderTagOptions}
        tagOptionTokens={orderTagOptionTokens}
        tagQuantityMap={{...tagQuantityMap, ...tagQuantityMapChanges}}
        hasManageTagPermission={hasOrderManageTagPermission}
        tagType={TAG_TYPE__ORDER}
        onCreateAndApplyClick={() => createAndApplyTagToOrders(orderNumbers)}
        isDisabledMap={isDisabledMap}
      />
    </li>
  )
}
