import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {VOLUSION} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.store_url)) {
    errors.store_url = 'Store URL is required'
    errors.preventSave = true
  }

  if (!isPresent(form.username)) {
    errors.username = 'Username is required'
    errors.preventSave = true
  }

  if (!isPresent(form.encrypted_password)) {
    errors.encrypted_password = 'Password is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: VOLUSION}),
      store_url: '',
      username: '',
      encrypted_password: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    store_url: cart.vendor_config.store_url,
    username: cart.vendor_config.username,
    encrypted_password: cart.vendor_config.encrypted_password,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'store_url') {
    errors.store_url = serverError.error_message
  } else if (serverError.param === 'username') {
    errors.username = serverError.error_message
  } else if (serverError.param === 'encrypted_password') {
    errors.encrypted_password = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    store_url: form.store_url,
    username: form.username,
    encrypted_password: form.encrypted_password,
  }
}

function Volusion({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="store_url"
              label="Store URL"
              required
              value={form.store_url || ''}
              errorMessage={form.hasChanged_store_url && errors.store_url}
              onChange={(event) =>
                updateForm(formName, {
                  store_url: event.target.value,
                  hasChanged_store_url: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="username"
              label="Username"
              required
              value={form.username || ''}
              errorMessage={form.hasChanged_username && errors.username}
              onChange={(event) =>
                updateForm(formName, {
                  username: event.target.value,
                  hasChanged_username: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              type="password"
              name="encrypted_password"
              label="Encrypted Password"
              required
              value={form.encrypted_password || ''}
              errorMessage={
                form.hasChanged_encrypted_password && errors.encrypted_password
              }
              onChange={(event) =>
                updateForm(formName, {
                  encrypted_password: event.target.value,
                  hasChanged_encrypted_password: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Volusion"
          href="https://support.ordoro.com/how-do-i-find-my-volusion-api-keys/"
        />
      </div>
    </VendorForm>
  )
}

Volusion.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    store_url: PropTypes.string.isRequired,
    hasChanged_store_url: PropTypes.bool,
    username: PropTypes.string.isRequired,
    hasChanged_username: PropTypes.bool,
    encrypted_password: PropTypes.string.isRequired,
    hasChanged_encrypted_password: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Volusion, setUpCartForm)
