import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isEmpty from 'lodash/isEmpty.js'
import pick from 'lodash/pick.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
} from '../../../store.js'
import {formatAbodeURL} from '../../../common/abode.js'
import {parseNonZeroPositiveNumber} from '../../../common/parseNumbers.js'
import formConnect from '../../../common/formConnect.js'
import {SelectOptionValue} from '../../../common/PropTypes.js'
import {SingleAddressLayouts} from '../../../common/constants/AddressLabelLayouts.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ButtonSendToPrinter from '../../../common/components/Button/ButtonSendToPrinter.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import Select from '../../../common/components/Form/Select.js'
import {addressPrinterIDSelector} from '../../../data/printerSettings.js'
import {
  markOrdersAsShipped,
  allOrdersSelector,
  shipFromAddressSelector,
} from '../../../data/orders.js'
import {
  LAYOUT_ADDRESS,
  layoutsByTypeSortedByName,
} from '../../../data/layout.js'

export const MODAL_FORM = 'ADDRESS_LABEL_MODAL_FORM'

export function showAddressLabelModal(orderNumbers) {
  const needsCountryCodeCount = needsCountryCodeCountSelector(getState(), {
    orderNumbers,
  })

  setForm(MODAL_FORM, {
    orderNumbers,
    layout: SingleAddressLayouts[0].value,
    layoutID: null,
    includeCountryCode: needsCountryCodeCount === orderNumbers.length,
    includeCountryCodeIsMixed:
      needsCountryCodeCount !== orderNumbers.length &&
      needsCountryCodeCount !== 0,
    copyCount: '1',
    markAsShipped: false,
  })
}

export function updateModalForm(updates, meta) {
  updateForm(MODAL_FORM, updates, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

function getNeedsCountryCode({shipTo, shipFrom}) {
  const shipToCountry = shipTo.country || ''
  const shipFromCountry = shipFrom.country || ''

  return !(
    (['US', ''].includes(shipToCountry) &&
      ['US', ''].includes(shipFromCountry)) ||
    shipToCountry === shipFromCountry
  )
}

export function addressSelector(
  state,
  {orderNumber, includeCountryCode, determineCountryCodeUsage},
) {
  const orders = allOrdersSelector(state)
  const address = get(orders, [orderNumber, 'shipping_address'], {})
  const shipFrom = shipFromAddressSelector(state, {orderNumber})

  const needsCountryCode = determineCountryCodeUsage
    ? getNeedsCountryCode({
        shipTo: address,
        shipFrom,
      })
    : includeCountryCode

  if (!isEmpty(address)) {
    return pick(address, [
      'name',
      'company',
      'street1',
      'street2',
      'city',
      'state',
      'zip',
      ...(needsCountryCode ? ['country'] : []),
    ])
  }

  return null
}

export function addressPreviewURLSelector(state) {
  const {
    orderNumbers,
    layout,
    layoutID,
    includeCountryCode,
    includeCountryCodeIsMixed,
    copyCount,
  } = modalFormSelector(state)

  const addresses = orderNumbers.reduce((prev, orderNumber) => {
    const address = addressSelector(state, {
      orderNumber,
      includeCountryCode,
      determineCountryCodeUsage: includeCountryCodeIsMixed,
    })

    if (address) {
      prev.push(address)
    }

    return prev
  }, [])

  if (addresses.length === 0) {
    return null
  }

  const params = {
    render: JSON.stringify([
      'Address',
      {
        layout,
        copyCount: parseNonZeroPositiveNumber(copyCount) || 1,
        addressData: addresses,
      },
    ]),
  }

  if (layoutID) {
    params.layout_id = layoutID
  }

  return formatAbodeURL('/render', params)
}

export function needsCountryCodeCountSelector(state, {orderNumbers}) {
  const orders = allOrdersSelector(state)

  return orderNumbers.reduce((prev, orderNumber) => {
    const address = get(orders, [orderNumber, 'shipping_address'], {})
    const shipFrom = shipFromAddressSelector(state, {orderNumber})

    if (
      getNeedsCountryCode({
        shipTo: address,
        shipFrom,
      })
    ) {
      return prev + 1
    }

    return prev
  }, 0)
}

function updateLayoutID(layoutID) {
  updateModalForm(
    {layoutID: Number(layoutID) || null},
    {stickyProps: ['layoutID']},
  )
}

function AddressLabelModal({form, addressPreviewURL, addressPrinterID}) {
  const addressLayouts =
    useSelector(layoutsByTypeSortedByName)[LAYOUT_ADDRESS] || []

  useEffect(() => {
    if (form.layoutID && !addressLayouts.find(({id}) => id === form.layoutID)) {
      updateLayoutID(null)
    }
  }, [form.layoutID])

  return (
    <ConfirmModal
      title={
        form.orderNumbers.length > 1
          ? `Print Address Labels (${form.orderNumbers.length})`
          : 'Print Address Label'
      }
      className="modal--sm"
      confirmText="View/Print"
      onConfirm={() => {
        window.open(addressPreviewURL, '_blank')

        closeModal()

        if (form.markAsShipped) {
          markOrdersAsShipped(form.orderNumbers)
        }
      }}
      cancelText="Close"
      onCancel={() => closeModal()}
      MiddleButtons={() => (
        <div className="margin-right-10">
          <ButtonSendToPrinter
            className="margin-bottom-5 meta-addresslabelmodal-button-instaprint"
            title="Address Label"
            pdfLink={addressPreviewURL}
            printerID={addressPrinterID}
            documentCount={form.orderNumbers.length}
            onClick={({canPrint} = {}) => {
              closeModal()

              if (canPrint && form.markAsShipped) {
                markOrdersAsShipped(form.orderNumbers)
              }
            }}
          />
        </div>
      )}
    >
      <dl className="list">
        {form.orderNumbers.length == 1 && (
          <dt className="list__title--label-success lh-md margin-top-0 margin-bottom-15">
            <strong>Order:</strong>{' '}
            <span className="unbold">{form.orderNumbers[0]}</span>
          </dt>
        )}
        <dd className="list__item">
          <Select
            label="Size"
            name="layout"
            value={form.layout}
            onChange={(value) => {
              updateModalForm({layout: value}, {stickyProps: ['layout']})
            }}
          >
            {SingleAddressLayouts.map((layout) => (
              <option key={layout.value} value={layout.value}>
                {layout.display}
              </option>
            ))}
          </Select>
        </dd>
        {addressLayouts.length > 0 && (
          <dd className="list__item">
            <Select
              label="Layout"
              id="layout_id"
              value={form.layoutID || ''}
              onChange={updateLayoutID}
            >
              <option value="">Standard</option>
              {addressLayouts.map((layout) => (
                <option key={layout.id} value={layout.id}>
                  {layout.name}
                </option>
              ))}
            </Select>
          </dd>
        )}
        <dd className="list__item margin-bottom-15">
          <label htmlFor="copy_count">No. of Copies</label>
          <NumberInput
            id="copy_count"
            value={form.copyCount}
            onChange={(value) =>
              updateModalForm({
                copyCount: `${value}`,
              })
            }
            min={1}
          />
        </dd>
        <dd className="list__item margin-bottom-10">
          <Checkbox
            label="Include Country Code"
            name="include-country-code"
            checked={form.includeCountryCode}
            indeterminate={form.includeCountryCodeIsMixed}
            onChange={(checked) =>
              updateModalForm({
                includeCountryCode: checked,
                includeCountryCodeIsMixed: false,
              })
            }
          />
        </dd>
        <dd className="list__item margin-bottom-0">
          <Checkbox
            label="Mark Order as Shipped"
            name="mark-as-shipped"
            checked={form.markAsShipped}
            onChange={(checked) =>
              updateModalForm(
                {markAsShipped: checked},
                {stickyProps: ['markAsShipped']},
              )
            }
          />
        </dd>
      </dl>
    </ConfirmModal>
  )
}

AddressLabelModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    includeCountryCode: PropTypes.bool.isRequired,
    includeCountryCodeIsMixed: PropTypes.bool.isRequired,
    copyCount: PropTypes.string.isRequired,
    layout: PropTypes.string.isRequired,
    layoutID: PropTypes.number,
    markAsShipped: PropTypes.bool.isRequired,
  }).isRequired,
  addressPreviewURL: PropTypes.string,
  addressPrinterID: SelectOptionValue.isRequired,
}

function mapStateToProps(state) {
  return {
    addressPreviewURL: addressPreviewURLSelector(state),
    addressPrinterID: addressPrinterIDSelector(state),
  }
}

export default formConnect(
  connect(mapStateToProps)(AddressLabelModal),
  modalFormSelector,
)
