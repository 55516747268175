import PropTypes from 'prop-types'
import {useEffect} from 'react'
import classNames from 'classnames'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'

import {
  BYOA_SHIPPERS,
  ORDORO_SHIPPERS,
  PITNEY,
  UPS,
} from '../../../common/constants/ShipperNames.js'
import {NEW_ID} from '../../../common/constants/index.js'
import {
  ShipperFormShape,
  SelectOptionsShape,
} from '../../../common/PropTypes.js'
import Forms from './Forms/index.js'
import {useSelector} from '../../../store.js'
import {
  useBYOAShippersSelector,
  useOrdoroShippersSelector,
} from '../../../data/company.js'

export default function NewForm({
  form,
  editID,
  updateForm,
  cancel,
  setupForm,
  newShipperOptions,
  setNewShipperVendor,
}) {
  const vendor = form ? form.vendor : ''
  const Form = Forms[vendor]
  const isExpanded = editID === NEW_ID
  const useOrdoroShippers = useSelector(useOrdoroShippersSelector)
  const useBYOAShippers = useSelector(useBYOAShippersSelector)

  let isFeatureLockedOrdoroShipper =
    !useOrdoroShippers && ORDORO_SHIPPERS.includes(vendor)
  const isFeatureLockedBYOAShipper =
    !useBYOAShippers && BYOA_SHIPPERS.includes(vendor)

  // if both are off then UPS can't be used
  // so pick a feature lock to show
  if (!useOrdoroShippers && !useBYOAShippers && vendor === UPS) {
    isFeatureLockedOrdoroShipper = true
  }

  useEffect(() => {
    if (!isExpanded) {
      return
    }

    let firstShipper = newShipperOptions.find(
      (option) => option.value === PITNEY,
    )

    if (!firstShipper) {
      firstShipper = newShipperOptions[0] || {}
    }

    if (firstShipper.value) {
      setNewShipperVendor(firstShipper.value)
    }
  }, [isExpanded, newShipperOptions])

  return (
    <li
      className={classNames(
        'list__item--settings-wrap list__item--add-item-wrap clearfix',
        {expanded: isExpanded},
      )}
    >
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={`#/settings/shipper/${NEW_ID}`}
            title="Add a shipper"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Add a shipper</span>
          </a>
        </p>
      </div>
      {isExpanded && (
        <div className="settings-list-item-form-wrap padding-top-5 clear-both">
          <fieldset>
            <ul className="form-list margin-bottom-0 margin-left-15">
              {newShipperOptions.length ? (
                <li className="form-list-item margin-bottom-0">
                  <label htmlFor="shipper_type" className="margin-bottom-5">
                    Select your shipper:
                  </label>
                  <select
                    className="select bigger-select margin-bottom-0"
                    id="shipper_type"
                    value={vendor}
                    onChange={(event) =>
                      setNewShipperVendor(event.target.value)
                    }
                  >
                    {newShipperOptions.map(({value, display}) => (
                      <option key={value} value={value}>
                        {display}
                      </option>
                    ))}
                  </select>
                </li>
              ) : (
                <li className="form-list-item margin-bottom-0">
                  No carriers are available to add
                </li>
              )}
            </ul>
            {vendor && (
              <div className="list__title-logo margin-left-15 margin-top-15">
                <div className={classNames('wrap--logo', vendor)} />
              </div>
            )}
            {isFeatureLockedOrdoroShipper ? (
              <div className="alert alert--lg alert--warning-lt margin-top-25 margin-bottom-0 align-center w-65">
                <div
                  className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
                  aria-hidden="true"
                />
                <p className="align-center fs-02 lh-md  margin-bottom-15">
                  <strong>
                    Ready to connect a carrier so you can create shipping
                    labels?
                  </strong>
                </p>
                <p className="fs-01 margin-bottom-20">
                  To gain access to this feature, you need to enable our
                  Shipping App.
                </p>
                <div className="margin-bottom-10">
                  <button
                    className="btn btn--primary btn--primary-alt btn--md fs-01"
                    onClick={() => showUpgradeModal()}
                  >
                    <strong>Manage Your Apps</strong>
                  </button>
                </div>
              </div>
            ) : isFeatureLockedBYOAShipper ? (
              <div className="alert alert--lg alert--warning-lt margin-top-25 margin-bottom-0 align-center w-65">
                <div
                  className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
                  aria-hidden="true"
                />
                <p className="align-center fs-02 lh-md  margin-bottom-15">
                  <strong>
                    Ready to bring your own shipping carrier account on board?
                  </strong>
                </p>
                <p className="fs-01 margin-bottom-20">
                  In order to do this, you need to be on the Premium plan of the
                  Shipping App.
                </p>
                <div className="margin-bottom-10">
                  <button
                    className="btn btn--primary btn--primary-alt btn--md fs-01"
                    onClick={() => showUpgradeModal()}
                  >
                    <strong>Upgrade Your Plan</strong>
                  </button>
                </div>
              </div>
            ) : Form ? (
              <Form
                form={form}
                updateForm={updateForm}
                cancel={cancel}
                setupForm={setupForm}
              />
            ) : null}
          </fieldset>
        </div>
      )}
    </li>
  )
}

NewForm.propTypes = {
  form: ShipperFormShape,
  newShipperOptions: SelectOptionsShape.isRequired,
  editID: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  setNewShipperVendor: PropTypes.func.isRequired,
  setupForm: PropTypes.func.isRequired,
}
