import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {GoodsReceiptShape} from '../../common/PropTypes.js'
import {activeGoodsReceiptsSelector} from '../../data/pos.js'
import {poIDSelector} from './poDetailSelectors.js'
import GoodsReceipt from './GoodsReceipt.js'

export function GoodsReceiptsPanel({goodsReceipts}) {
  return (
    <>
      {goodsReceipts.map((goodsReceipt) => (
        <GoodsReceipt
          key={goodsReceipt.goods_receipt_id}
          goodsReceipt={goodsReceipt}
        />
      ))}
    </>
  )
}

GoodsReceiptsPanel.propTypes = {
  poID: PropTypes.string.isRequired,
  goodsReceipts: PropTypes.arrayOf(GoodsReceiptShape).isRequired,
}

function mapStateToProps(state) {
  const poID = poIDSelector(state)
  return {
    poID,
    goodsReceipts: activeGoodsReceiptsSelector(state, {poID}),
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceiptsPanel)
