import PropTypes from 'prop-types'

import {
  TAG_FILTER_OR,
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_NOT,
} from '../../../constants/Tags.js'
import Radio from '../../Form/Radio.js'

export default function FilterOperator({
  filterOperator,
  tagCount,
  onTagFilterOperatorChange,
}) {
  return (
    <li
      className="list__item list__item--dropdown list__item--dropdown-header"
      data-dropdown-prevent-close
    >
      <div className="fs-n0">
        <strong>Filtering Logic</strong>
      </div>
      {tagCount > 1 && (
        <div className="inline-block margin-top-7">
          <strong className="margin-right-7">Has:</strong>
          <Radio
            label={'Any'}
            value={TAG_FILTER_OR}
            name="tags-any"
            className="inline-block wrap--input-v-align-middle label--sm margin-right-7"
            checked={filterOperator === TAG_FILTER_OR}
            onChange={() => onTagFilterOperatorChange(TAG_FILTER_OR)}
          />
          <Radio
            label={'All'}
            value={TAG_FILTER_AND}
            name="tags-all"
            className="inline-block wrap--input-v-align-middle label--sm margin-right-7"
            checked={filterOperator === TAG_FILTER_AND}
            onChange={() => onTagFilterOperatorChange(TAG_FILTER_AND)}
          />
          <Radio
            label={'Only'}
            value={TAG_FILTER_ONLY}
            name="tags-only"
            className="inline-block wrap--input-v-align-middle label--sm"
            checked={filterOperator === TAG_FILTER_ONLY}
            onChange={() => onTagFilterOperatorChange(TAG_FILTER_ONLY)}
          />
        </div>
      )}
      <div className={tagCount > 1 ? 'divider--top' : 'margin-top-7'}>
        <strong className="margin-right-7">Does not have:</strong>
        <Radio
          label={'Any'}
          value={TAG_FILTER_NOT}
          name="tags-not"
          className="inline-block wrap--input-v-align-middle label--sm"
          checked={filterOperator === TAG_FILTER_NOT}
          onChange={() => onTagFilterOperatorChange(TAG_FILTER_NOT)}
        />
      </div>
    </li>
  )
}

FilterOperator.propTypes = {
  filterOperator: PropTypes.string,
  tagCount: PropTypes.number.isRequired,
  onTagFilterOperatorChange: PropTypes.func.isRequired,
}
