import {useSelector} from '../../store.js'
import {BATCH_SORT_OPTIONS} from '../../common/constants/Batches.js'
import Sort from '../../common/components/List/Sort.js'
import {setSort} from './batchListActions.js'
import {batchListQuerySelector} from './batchListSelectors.js'

export default function BatchSort() {
  const sort = useSelector((state) => batchListQuerySelector(state).sort)

  return (
    <Sort
      items={BATCH_SORT_OPTIONS}
      value={sort}
      onChange={(value) => setSort(value)}
    />
  )
}
