import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'

import api from '../../../common/api.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {updatePODetailForm} from '../../PurchaseOrderDetailPage/poDetailActions.js'
import {poItemSKUSelector, ensurePO, setPO} from '../../../data/pos.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const REMOVE_PO_ITEM = 'REMOVE_PO_ITEM'
export const REMOVE_PO_ITEM_MODAL = 'REMOVE_PO_ITEM_MODAL'

export function showRemovePOItemModal(poID, poItemIDs) {
  return setForm(REMOVE_PO_ITEM_MODAL, {
    poID,
    poItemIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(REMOVE_PO_ITEM_MODAL, ...args)
}

export function closeModal() {
  return removeForm(REMOVE_PO_ITEM_MODAL)
}

export function removePOItem() {
  return {
    type: REMOVE_PO_ITEM,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[REMOVE_PO_ITEM_MODAL],
)

export function* removeSinglePOItem(poID, poItemID) {
  const {json: purchaseOrder} = yield call(
    api.delete,
    `/purchase_order/${encodeURIComponent(poID)}/line/${poItemID}/`,
  )

  yield call(setPO, purchaseOrder)
}

export function* removePOItemWorker() {
  try {
    const {poID, poItemIDs} = yield select(modalFormSelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    yield all(
      poItemIDs.map((poItemID) => call(removeSinglePOItem, poID, poItemID)),
    )

    yield call(ensurePO, poID, {reload: true})

    yield put(closeModal())

    yield call(updatePODetailForm, {selectedItemIDs: []})

    yield call(
      showMessageToast,
      `Removed PO item${poItemIDs.length === 1 ? '' : 's'}`,
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function RemovePOItemModal({form, firstItemSKU, ...props}) {
  return (
    <ConfirmModal
      title={`Remove PO Item${form.poItemIDs.length === 1 ? '' : 's'}`}
      modalSize="sm"
      onConfirm={() => props.removePOItem()}
      onCancel={() => props.closeModal()}
      confirmText="Yes"
      cancelText="No"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div className="fs-01">
        <strong>{form.poID}</strong>
      </div>
      <div className="fs-01 margin-bottom-5">
        {form.poItemIDs.length === 1 ? (
          <span>
            Do you want to remove <strong>{firstItemSKU}</strong> from this PO?
          </span>
        ) : (
          <span>
            Do you want to remove <strong>{form.poItemIDs.length} items</strong>{' '}
            from this PO?
          </span>
        )}
      </div>
    </ConfirmModal>
  )
}

RemovePOItemModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    poItemIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  firstItemSKU: PropTypes.string.isRequired,
  removePOItem: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    firstItemSKU: poItemSKUSelector(state, {
      poID: form.poID,
      poItemID: form.poItemIDs[0],
    }),
  }
}

const mapDispatchToProps = {
  removePOItem,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(RemovePOItemModal),
  modalFormSelector,
)
