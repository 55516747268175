import PropTypes from 'prop-types'
import {useRef, useEffect} from 'react'
import * as RDP from 'react-datepicker'
import snakeCase from 'lodash/snakeCase.js'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import isSameDay from 'date-fns/isSameDay'

import {updateForm, useAutoForm} from '../../store.js'
import {DateShape} from '../PropTypes.js'
import TextInput from './TextInput.js'
import Zropdown, {useZropdown} from './Zropdown.js'
import FocusListener from './FocusListener.js'

const ReactDatePicker = RDP.default.default || RDP.default || RDP

export default function DatePicker({
  label,
  id,
  labelClassName,
  errorMessage,
  onChange,
  selected,
  autoFocus,
  disabled,
  required,
  ...props
}) {
  id = id || (label ? snakeCase(label) : 'NOT_DEFINED')
  const dropdown = `SELECT__${id.toUpperCase()}`

  const inputRef = useRef()
  const refFocusToInput = useRef()

  const form = useAutoForm(dropdown, () => ({
    text: selected ? format(selected, 'P') : '',
    results: selected ? [selected] : [],
    selectedIndex: 0,
    show: false,
    inputFocused: false,
  }))

  const {onSelectInternal} = useZropdown({
    dropdown,
    form,
    onSelect: (value) => {
      onChange(value)

      // need to kick this in the teeth to make it close on selection
      refFocusToInput.current && refFocusToInput.current.focus()
    },
  })

  useEffect(() => {
    if (!selected) {
      updateForm(dropdown, {text: '', results: []})

      return
    }

    const oldDate = parse(form.text, 'P', new Date())

    if (!isSameDay(selected, oldDate)) {
      const newValue = format(selected, 'P')

      updateForm(dropdown, {text: newValue, results: [selected]})
    }
  }, [selected])

  return (
    <>
      <TextInput
        ref={inputRef}
        label={label}
        prefixClassName="bg-lightest-grey"
        prefix={
          <span
            role="img"
            aria-label="date"
            className="icon-prefix fs-00 lh-lg v-align-middle"
          >
            📅
          </span>
        }
        id={id}
        labelClassName={labelClassName}
        errorMessage={errorMessage}
        onFocus={() => updateForm(dropdown, {show: true, inputFocused: true})}
        onChange={(value) => {
          updateForm(dropdown, {text: value, show: true})

          const date = parse(value, 'P', new Date())

          if (isValid(date)) {
            onChange(date)
          } else if (value === '') {
            onChange(null)
          }
        }}
        onBlur={() => {
          const date = parse(form.text, 'P', new Date())

          if (isValid(date)) {
            onChange(date)
            updateForm(dropdown, {text: format(date, 'P'), results: [date]})
          } else if (selected) {
            onChange(selected)
            updateForm(dropdown, {
              text: format(selected, 'P'),
              results: [selected],
            })
          }

          updateForm(dropdown, {inputFocused: false})
        }}
        onKeyDown={(event) => {
          if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault()
            const selectedDay = document.querySelector(
              `#date_picker__${dropdown} .react-datepicker__day[tabindex="0"]`,
            )
            selectedDay && selectedDay.focus({preventScroll: true})
          }
        }}
        value={form.text}
        autoFocus={autoFocus}
        disabled={disabled}
        required={required}
      />
      <span
        ref={refFocusToInput}
        role="button"
        tabIndex={form.show && !form.inputFocused ? '0' : '-1'}
        style={{outline: 'none'}}
      ></span>
      {form.show && (
        <>
          <FocusListener
            attrs={[{id}, {id: `date_picker__${dropdown}`}]}
            onFocusLost={() => updateForm(dropdown, {show: false})}
          />
          <Zropdown
            dropdown={dropdown}
            id={`date_picker__${dropdown}`}
            className="dropdown--datepicker"
            show
            onConfirm={() => {
              const selectedDay = document.querySelector(
                `#date_picker__${dropdown} .react-datepicker__day[tabindex="0"]`,
              )

              if (selectedDay) {
                const [, dateStr] =
                  (selectedDay.getAttribute('aria-label') || '').match(
                    /^\w+ \w+, (.*)$/,
                  ) || []

                const date = parse(dateStr, 'PPP', new Date())

                if (isValid(date)) {
                  onChange(date)
                }
              }

              refFocusToInput.current && refFocusToInput.current.focus()
            }}
            parentRef={inputRef}
          >
            <ReactDatePicker
              key={selected ? format(selected, 'P') : ''} // bug in date picker that leaves initial selected painted
              inline
              selected={selected}
              onChange={onSelectInternal}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex--justify" data-dropdown-prevent-close>
                  <button
                    className="btn no-underline react-datepicker__navigation react-datepicker__navigation--previous react--datepicker__navigation--custom"
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    aria-label="Previous Month"
                  >
                    <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                      Previous Month
                    </span>
                  </button>
                  <strong className="fs-00 lh-sm">
                    {format(date, 'MMM yyyy')}
                  </strong>
                  <button
                    className="btn no-underline react-datepicker__navigation react-datepicker__navigation--next react--datepicker__navigation--custom"
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    aria-label="Next Month"
                  >
                    <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                      Next Month
                    </span>
                  </button>
                </div>
              )}
              disabled={disabled}
              {...props}
            />
          </Zropdown>
        </>
      )}
    </>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: DateShape,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}
