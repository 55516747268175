import {createSelector} from 'reselect'
import reduce from 'lodash/reduce.js'
import isEmpty from 'lodash/isEmpty.js'

import {NEW_ID} from '../../../../common/constants/index.js'
import {isPresent} from '../../../../common/utils.js'

export function userSettingsSelector(state) {
  return state.ui.settings.users
}

export function serverErrorSelector(state) {
  return state.ui.settings.users.serverError
}

export function userFormSelector(state) {
  return state.ui.settings.users.userForm
}

export const errorsSelector = createSelector(
  userFormSelector,
  (state) => state.ui.settings.users.isChangingPassword,
  ({name, email, password, passwordConfirm}, isChangingPassword) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
    }

    if (!isPresent(email)) {
      errors.email = 'Email is required'
    }

    if (isChangingPassword) {
      if (!isPresent(password)) {
        errors.password = 'Password is required'
      }

      if (!isPresent(passwordConfirm)) {
        errors.passwordConfirm = 'Password Confirmation is required'
      }

      if (password !== passwordConfirm) {
        errors.passwordConfirm = 'Passwords do not match'
      }
    }

    return errors
  },
)

export function getServerParamErrors(serverError) {
  const errors = {}

  if (serverError.param === 'name') {
    errors.name = serverError.error_message
  }

  if (serverError.param === 'email') {
    errors.email = serverError.error_message
  }

  if (serverError.param === 'password') {
    errors.password = serverError.error_message
  }

  return errors
}

export const serverParamErrorsSelector = createSelector(
  serverErrorSelector,
  (serverError) => {
    let errors = {}

    if (serverError && serverError.param) {
      errors = getServerParamErrors(serverError)
    }

    if (serverError && isEmpty(errors)) {
      return {serverError: serverError.error_message}
    }

    return errors
  },
)

export const errorsOfChangedSelector = createSelector(
  (state) => state.ui.settings.users.hasChanged,
  errorsSelector,
  serverParamErrorsSelector,
  (hasChanged, errors, serverParamErrors) =>
    reduce(
      errors,
      (e, value, key) => {
        if (hasChanged[key]) {
          e[key] = value
        }

        return e
      },
      {...serverParamErrors},
    ),
)

export const topLevelErrorSelector = createSelector(
  serverParamErrorsSelector,
  (errors) => errors.serverError || '',
)

export const hasErrorsSelector = createSelector(
  errorsSelector,
  (errors) => !isEmpty(errors),
)

export const usersHashSelector = createSelector(
  userSettingsSelector,
  ({editLink, isNew}) => {
    if (isNew) {
      return `#/settings/user/${NEW_ID}`
    }
    if (editLink) {
      return `#/settings${editLink}`
    }
    return '#/settings/user'
  },
)
