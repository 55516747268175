import XHR from './XHR.js'
import logger from './logger.js'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

function formatError(param, message, status) {
  if (param && message) {
    return `${param}: ${message}`
  }
  if (message) {
    return message
  }
  return `${status}`
}

export function UranusError(response) {
  if (!response.json) {
    response.json = {error_message: response.raw, param: null}
  }

  this.response = response
  this.error_message = response.json.error_message || response.json.message
  this.param = response.json.param

  this.name = 'UranusError'
  this.message = formatError(
    this.param,
    this.error_message,
    this.response.status,
  )
  this.stack = new Error(this.error_message).stack
}
UranusError.prototype = Object.create(Error.prototype)

class UranusXHR extends XHR {}
UranusXHR.prototype.Error = UranusError

function send(method, url, data, headers = DEFAULT_HEADERS) {
  const fullURL = prefixURL(url)
  const xhr = new UranusXHR(method, fullURL, data, headers)

  return xhr.send().catch((err) => {
    logger.warn(err)

    throw err
  })
}

export function prefixURL(url) {
  return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_URANUS_URL}${url}`
}

export default {
  send,
  get: send.bind(send, 'get'),
}
