import PropTypes from 'prop-types'
import classNames from 'classnames'
import colors from 'color'
import {SketchPicker} from 'react-color'
import {connect} from 'react-redux'

import {ErrorsShape} from '../../PropTypes.js'
import {
  TAG_TYPE__ORDER,
  TAG_TYPE__RETURN_ORDER,
  TAG_TYPE__PRODUCT,
  TAG_TYPE__MO,
  TAG_TYPE__PO,
} from '../../constants/Tags.js'
import {isPresent} from '../../utils.js'
import ColorOptions from '../../constants/ColorOptions.js'
import formConnect from '../../formConnect.js'
import ConfirmModal from '../Modal/ConfirmModal.js'
import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {
  saveOrderTag,
  addTagToOrders,
  orderTagSelector,
} from '../../../data/orderTags.js'
import {
  saveReturnOrderTag,
  addTagToReturnOrders,
  returnOrderTagSelector,
} from '../../../data/returnOrderTags.js'
import {
  saveProductTag,
  addTagToProducts,
  productTagSelector,
} from '../../../data/productTags.js'
import {saveMOTag, addTagToMOs, moTagSelector} from '../../../data/moTags.js'
import {addTagToPOs, poTagSelector, savePOTag} from '../../../data/poTags.js'
import {showMessageToast} from '../../../ordoro/Header/Toast/index.js'
import {toggleTag} from '../../../ordoro/CreateOrderPage/createOrderActions.js'
import {setRuleApplyTag} from '../../../ordoro/settings/Rules/rulesFunctions.js'
import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'

const MODAL_FORM = 'EDIT_TAG_MODAL_FORM'
const COLOR_PICKER_DROPDOWN = 'EDIT_TAG_MODAL_COLOR_PICKER'

export function showEditTagModal(tagType, tagID, meta) {
  const tag =
    tagType === TAG_TYPE__ORDER && tagID
      ? orderTagSelector(getState(), {orderTagID: tagID})
      : tagType === TAG_TYPE__RETURN_ORDER && tagID
        ? returnOrderTagSelector(getState(), {
            returnOrderTagID: tagID,
          })
        : tagType === TAG_TYPE__PRODUCT && tagID
          ? productTagSelector(getState(), {
              productTagID: tagID,
            })
          : tagType === TAG_TYPE__MO && tagID
            ? moTagSelector(getState(), {
                moTagID: tagID,
              })
            : tagType === TAG_TYPE__PO && tagID
              ? poTagSelector(getState(), {
                  poTagID: tagID,
                })
              : {}

  setForm(MODAL_FORM, {
    tagType,
    id: tagID,
    text: (tagType === TAG_TYPE__ORDER ? tag.text : tag.name) || '',
    color: tag.color || ColorOptions[0].color,
    meta,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const form = modalFormSelector(state)
  const errors = {}

  if (!isPresent(form.text)) {
    errors.text = 'Tag text is required'
    errors.preventSave = true
  }

  if (!isPresent(form.color)) {
    errors.color = 'Tag color is required'
    errors.preventSave = true
  }

  return errors
}

export async function updateTag() {
  try {
    updateModalForm({isSaving: true})

    const {tagType, id, text, color, meta} = modalFormSelector(getState())

    const tag =
      tagType === TAG_TYPE__ORDER
        ? await saveOrderTag(text, color, id)
        : tagType === TAG_TYPE__RETURN_ORDER
          ? await saveReturnOrderTag(text, color, id)
          : tagType === TAG_TYPE__PRODUCT
            ? await saveProductTag(text, color, id)
            : tagType === TAG_TYPE__MO
              ? await saveMOTag(text, color, id)
              : tagType === TAG_TYPE__PO
                ? await savePOTag(text, color, id)
                : null

    showMessageToast(`Tag (${text}) was ${id ? 'updated' : 'created'}`)

    closeModal()

    await handleMeta(tag, meta, tagType)
  } catch (err) {
    updateModalForm({
      serverError: `Error saving tag: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

async function handleMeta(tag, meta, tagType) {
  if (!meta) {
    return
  }

  if (tagType === TAG_TYPE__ORDER && meta.addTagToOrderNumbers) {
    await addTagToOrders(tag.id, meta.addTagToOrderNumbers)
  }

  if (tagType === TAG_TYPE__ORDER && meta.addTagToRuleID) {
    setRuleApplyTag(meta.addTagToRuleID, meta.index, tag.id)
  }

  if (tagType === TAG_TYPE__ORDER && meta.addTagToNewOrder) {
    toggleTag(tag.id, true)
  }

  if (tagType === TAG_TYPE__RETURN_ORDER && meta.addTagToReferenceIDs) {
    await addTagToReturnOrders(tag.id, meta.addTagToReferenceIDs)
  }

  if (tagType === TAG_TYPE__PRODUCT && meta.addTagToSKUs) {
    await addTagToProducts(tag.id, meta.addTagToSKUs)
  }

  if (tagType === TAG_TYPE__PRODUCT && meta.addProductTagToRuleOnChange) {
    meta.addProductTagToRuleOnChange(tag.id)
  }

  if (tagType === TAG_TYPE__MO && meta.addTagToMOReferenceIDs) {
    await addTagToMOs(tag.id, meta.addTagToMOReferenceIDs)
  }

  if (tagType === TAG_TYPE__PO && meta.addTagToPOIDs) {
    await addTagToPOs(tag.id, meta.addTagToPOIDs)
  }
}

function EditTagModal({form, isColorFocused, errors}) {
  const title = form.id ? 'Edit Tag' : 'Create a New Tag'

  return (
    <ConfirmModal
      title={title}
      modalSize="sm-md"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => updateTag()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div className="row">
        <div className="columns medium-6">
          <ul className="list--form">
            <li className={classNames('list__item', {error: errors.text})}>
              <label htmlFor="id_tag_name">Tag Name</label>
              <input
                className="input--tall"
                type="text"
                name="tag_name"
                value={form.text}
                onChange={(event) =>
                  updateModalForm({text: event.target.value})
                }
              />
              {errors.text && (
                <small className="error error-message">{errors.text}</small>
              )}
            </li>
            <li className={classNames('list__item', {error: errors.color})}>
              <label htmlFor="id_tag_color">Tag Color</label>
              <div
                className={classNames('wrap--tag-color flex', {
                  focused: isColorFocused,
                })}
                data-dropdown={COLOR_PICKER_DROPDOWN}
                data-dropdown-prevent-close
              >
                <div
                  className="prefix--tag-color"
                  style={{
                    backgroundColor: form.color,
                  }}
                />
                <input
                  className="input--tall input--tag-color"
                  type="text"
                  name="tag_color"
                  value={form.color}
                  onChange={(event) =>
                    updateModalForm({color: event.target.value})
                  }
                />
              </div>
              {errors.color && (
                <small className="error error-message">{errors.color}</small>
              )}
            </li>
          </ul>
        </div>
        {isColorFocused && (
          <div
            className="wrap--color-picker"
            data-dropdown={COLOR_PICKER_DROPDOWN}
            data-dropdown-prevent-close
          >
            <SketchPicker
              onChangeComplete={(color) => {
                if (color.rgb.a !== 1) {
                  updateModalForm({
                    color: colors({
                      r: color.rgb.r,
                      g: color.rgb.g,
                      b: color.rgb.b,
                    })
                      .alpha(color.rgb.a)
                      .string(),
                  })
                } else {
                  updateModalForm({color: color.hex})
                }
              }}
              color={form.color}
              presetColors={ColorOptions.map(
                (colorOption) => colorOption.color,
              )}
            />
          </div>
        )}
      </div>
    </ConfirmModal>
  )
}

EditTagModal.propTypes = {
  form: PropTypes.shape({
    tagType: PropTypes.oneOf([
      TAG_TYPE__ORDER,
      TAG_TYPE__RETURN_ORDER,
      TAG_TYPE__PRODUCT,
      TAG_TYPE__MO,
      TAG_TYPE__PO,
    ]),
    id: PropTypes.number,
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  errors: ErrorsShape.isRequired,
  isColorFocused: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isColorFocused: currentDropdownSelector(state) === COLOR_PICKER_DROPDOWN,
    errors: errorsSelector(state),
  }
}

export default formConnect(
  connect(mapStateToProps)(EditTagModal),
  modalFormSelector,
)
