import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

export default class productArchiveStrategy extends baseStrategy {
  static title = 'Archive Products'
  static requiredColumns = ['sku']

  static getExampleRows() {
    return () =>
      api.get('/product/', {limit: 5, active: true}).then(({json: data}) => {
        const rows = [['SKU *Req']]

        data.product.forEach((product) => {
          rows.push([product.sku])
        })

        return rows
      })
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()

    data.payload = {
      sku: row[columnsByName.sku],
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/sku/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    return errors
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const url = `/product/${sku}/archive/`

      return api.post(url, {})
    }
  }
}
