import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {markOrdersAsShipped} from '../../../data/orders.js'

export const MODAL_FORM = 'CONFIRM_MARK_AS_SHIPPED_MODAL_FORM'

export function showConfirmMarkAsShippedModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    isSaving: false,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export async function confirmMarkAsShipped() {
  const {orderNumbers} = modalFormSelector(getState())

  updateModalForm({isSaving: true})

  await markOrdersAsShipped(orderNumbers)

  updateModalForm({isSaving: false})

  closeModal()
}

function ConfirmMarkAsShippedModal({form}) {
  const orderCount = form.orderNumbers.length

  return (
    <ConfirmModal
      title={`Mark ${orderCount > 1 ? 'Orders' : 'Order'} as Shipped`}
      isSaving={form.isSaving}
      onConfirm={() => confirmMarkAsShipped()}
      onCancel={() => closeModal()}
    >
      <p className="fs-01 lh-md">
        Are you sure you want to mark{' '}
        {orderCount > 1 ? 'these orders' : 'this order'} as shipped?
      </p>
      <ul className="list square text--md-grey">
        <li className="list__item fs-n0">
          The {orderCount > 1 ? 'orders' : 'order'} will be moved to the{' '}
          <strong>Shipped</strong> tab
        </li>
        <li className="list__item fs-n0">
          Sales channels <strong>WILL NOT</strong> be notified
        </li>
      </ul>
    </ConfirmModal>
  )
}

ConfirmMarkAsShippedModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
  }).isRequired,
}

export default formConnect(
  connect()(ConfirmMarkAsShippedModal),
  modalFormSelector,
)
