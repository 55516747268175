export default function Logo() {
  return (
    <a
      className="main-nav__link wrap--logo"
      href="#/dashboard"
      title="Dashboard"
    >
      <svg className="img--logo" viewBox="0 0 384.36 332.86">
        <g>
          <path
            className="logo-mark__fill"
            d="M384.36,166.43,288.27,0H96.09L0,166.43,96.09,332.86H288.27Zm-263.21,123-71-123,71-123H263.21l71,123-71,123Z"
          />
          <polygon
            className="logo-mark__fill"
            points="236.17 90.23 162.42 90.23 206.41 166.43 162.42 242.63 236.17 242.63 280.17 166.43 236.17 90.23"
          />
        </g>
      </svg>
    </a>
  )
}
