import {
  warehouseOptionsSelector,
  warehouseOptionTokensSelector,
} from '../../../../data/warehouses.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  modalFormSelector,
  updateModalForm,
  WAREHOUSE_FILTER_PANEL,
} from './productListFilterModalFunctions.js'
import {usesInventorySelector} from '../../../../data/company.js'

export default function WarehouseFilterPanel() {
  const form = useSelector(modalFormSelector)
  const warehouseOptions = useSelector(warehouseOptionsSelector)
  const warehouseOptionTokens = useSelector(warehouseOptionTokensSelector)
  const usesInventory = useSelector(usesInventorySelector)

  if (!usesInventory) {
    return null
  }

  return (
    <FilterPanel
      id={WAREHOUSE_FILTER_PANEL}
      label="Warehouses"
      searchPlaceholder="Search for a warehouse filter..."
      options={warehouseOptions}
      optionTokens={warehouseOptionTokens}
      selected={form.warehouseID}
      onSelect={(option) => {
        const warehouseID = form.warehouseID.filter((id) => id !== option.value)

        if (warehouseID.length === form.warehouseID.length) {
          warehouseID.push(option.value)
        }

        updateModalForm({warehouseID})
      }}
    />
  )
}
