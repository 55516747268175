import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {getState, dispatch} from '../../../../store.js'
import api from '../../../../common/api.js'
import {isPresent} from '../../../../common/utils.js'
import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import {NEW_ID} from '../../../../common/constants/index.js'
import {SENDLE} from '../../../../common/constants/ShipperNames.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {setShipper} from '../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  shipperFormSelector,
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.sendle_id)) {
    errors.sendle_id = 'Sendle ID is required'
  }

  if (!isPresent(shipper.api_key)) {
    errors.api_key = 'API ID is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    sendle_id: vendorConfig.sendle_id || '',
    api_key: vendorConfig.api_key || '',
    isSaving: false,
  }
}

export async function saveShipperSendle(shipperID) {
  const {name, hidden_shipping_methods, sendle_id, api_key} =
    shipperFormSelector(getState(), {shipperID})

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    sendle_id,
    api_key,
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await (shipperID !== NEW_ID
      ? api.put(`/shipper/${shipperID}/`, params)
      : api.post('/shipper/', {...params, vendor: SENDLE}))

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error while saving shipper.',
        details: err.error_message || err.message,
      },
      err,
    )

    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

function SendleForm({
  form: {id, sendle_id, api_key, isSaving},
  hasErrors,
  errors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.sendle_id},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="sendle_id">
                Sendle ID<span className="required">*</span>
              </label>
              <input
                type="text"
                id="sendle_id"
                value={sendle_id}
                onChange={(event) =>
                  props.updateForm(id, {sendle_id: event.target.value})
                }
              />
              {errors.sendle_id && (
                <small className="error error-message">
                  {errors.sendle_id}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.api_key,
              })}
            >
              <label htmlFor="api_key">
                API Key<span className="required">*</span>
              </label>
              <input
                type="text"
                id="api_key"
                value={api_key}
                onChange={(event) =>
                  props.updateForm(id, {api_key: event.target.value})
                }
              />
              {errors.api_key && (
                <small className="error error-message">{errors.api_key}</small>
              )}
            </li>
            {id !== NEW_ID && <ShippingMethods shipperID={id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                onClick={() => saveShipperSendle(id)}
                isDisabled={hasErrors}
                isLoading={isSaving}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      <div className="medium-5 columns">
        <fieldset>
          <div className="alert margin-bottom-10 alert--standard">
            {id === NEW_ID ? (
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help setting up your Sendle account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  Please contact{' '}
                  <a href="mailto:support@ordoro.com">support@ordoro.com</a> to
                  have this special shipper enabled in your account.
                  <div>
                    <a
                      className="btn--link mid meta-ups-setup-help"
                      href="https://support.ordoro.com/how-do-i-set-up-sendle-in-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info →
                    </a>
                  </div>
                </dd>
              </dl>
            ) : (
              <Archive shipperID={id} />
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

SendleForm.propTypes = {
  form: ShipperFormShape,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
  }
}

export default connect(mapStateToProps)(SendleForm)
