import PropTypes from 'prop-types'
import TagBlock from '../../../../common/components/TagBlock.js'
import {TAG_TYPE__PRODUCT} from '../../../../common/constants/Tags.js'

import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueProductTagDisplay({condition}) {
  const option = getConditionOption(condition)
  const valueParam = option.getValueParam(condition)
  const value = option.getValue(condition)

  return valueParam === 'id' ? (
    <ul className="list list--tags-lg inline-block">
      <TagBlock tagID={value} tagType={TAG_TYPE__PRODUCT} />
    </ul>
  ) : (
    <strong className="rule-criteria">
      <span>{value}</span>
    </strong>
  )
}

ConditionValueProductTagDisplay.propTypes = {
  condition: PropTypes.object.isRequired,
}
