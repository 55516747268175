import {dispatch, useSelector} from '../../store.js'
import {selectedPOIDsSelector} from './purchaseOrderListSelectors.js'
import {poActionsSelector} from '../../data/pos.js'
import {showMarkAsSentModal} from './Modals/MarkAsSentModal.js'
import {showSendToSupplierModal} from './Modals/SendToSupplierModal.js'
import {showCancelPOModal} from './Modals/CancelPOModal.js'
import AddPOTagsDropdown from './AddPOTagsDropdown.js'

export default function ActionsForSelected() {
  const selectedPOIDs = useSelector(selectedPOIDsSelector)
  const poActions = useSelector((state) =>
    poActionsSelector(state, {poIDs: selectedPOIDs}),
  )
  const plural = selectedPOIDs.length === 1 ? '' : 's'

  return (
    <>
      {poActions.send_po && (
        <li className="inline-block fs-n0 v-align-middle divider--left">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showSendToSupplierModal(selectedPOIDs)}
          >
            Send PO{plural}
          </button>
        </li>
      )}
      {poActions.mark_po_as_sent && (
        <li className="inline-block fs-n0 v-align-middle margin-left-15">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => dispatch(showMarkAsSentModal(selectedPOIDs))}
          >
            Mark as Sent
          </button>
        </li>
      )}
      {poActions.cancel_po && (
        <li className="inline-block fs-n0 v-align-middle margin-left-15">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showCancelPOModal(selectedPOIDs)}
          >
            Cancel
          </button>
        </li>
      )}
      {poActions.restore_po && (
        <li className="inline-block fs-n0 v-align-middle margin-left-15">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showCancelPOModal(selectedPOIDs, true)}
          >
            Restore
          </button>
        </li>
      )}
      <AddPOTagsDropdown poIDs={selectedPOIDs} />
    </>
  )
}
