import {csvParseRows} from 'd3-dsv'

import {setIsProcessingCSV, setColumns, setRows} from '../dataImport/index.js'

export const SET_IS_DRAG_HOVER = 'ordoro/ui/dataImport/upload/SET_IS_DRAG_HOVER'
export const SET_ERROR = 'ordoro/ui/dataImport/upload/SET_ERROR'

export function setIsDragHover(value) {
  return {
    type: SET_IS_DRAG_HOVER,
    payload: value,
  }
}

export function setError(value) {
  return {
    type: SET_ERROR,
    payload: value,
  }
}

function isValidFile(file) {
  return (
    !file.type ||
    (file.type === 'application/vnd.ms-excel' && file.name.match(/\.csv$/i)) ||
    file.type.match(/^text/)
  )
}

export function readFiles(files) {
  if (files.length > 1) {
    return Promise.reject(new Error('Only one file allowed at a time, buddy.'))
  }

  const file = files[0]

  if (!isValidFile(file)) {
    return Promise.reject(
      new Error(
        `Rut roh. That file type is invalid. Only text files are accepted. You gave us: ${file.type}.`,
      ),
    )
  }

  var reader = new FileReader()

  // We need to hold off the next step a bit to let the browser render
  // the waiting graphics, because we could be dumping a large amount
  // of data into the DOM that will prevent other graphics from rendering
  setTimeout(() => {
    reader.readAsText(file)
  }, 50)

  return new Promise((resolve) => {
    reader.onload = (event) => {
      resolve(event.target.result)
    }
  })
}

function parseCSV(text) {
  return new Promise((resolve, reject) => {
    try {
      resolve(csvParseRows(text))
    } catch (error) {
      reject(
        new Error(
          `Something went wrong processing that file. ${error.message}`,
        ),
      )
    }
  })
}

function cleanColumnNames(columns) {
  return columns.map((column) => column.trim().replace(/\s*\*req/i, ''))
}

function normalizeRowWidth(rows, columns) {
  const columnCount = columns.length

  return rows.map((row) => {
    // Add missing columns
    while (row.length < columnCount) {
      row.push('')
    }

    // Remove extra columns
    row.length = columnCount

    return row
  })
}

function extractColumnsAndRows(data) {
  if (!data || data.length < 2) {
    throw new Error('No data present in file.')
  }

  const columns = cleanColumnNames(data[0])

  const rows = normalizeRowWidth(data.slice(1), columns)

  return {
    columns,
    rows,
  }
}

export function processFiles(files, dispatch) {
  dispatch(setIsProcessingCSV(true))

  return readFiles(files)
    .then((text) => parseCSV(text))
    .then((data) => extractColumnsAndRows(data))
    .then(({columns, rows}) => {
      dispatch(setColumns(columns))
      dispatch(setRows(rows))
    })
    .catch((error) => dispatch(setError(error.message)))
    .then(() => dispatch(setIsProcessingCSV(false)))
}
