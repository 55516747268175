import className from '../../../../common/className.js'
import ButtonLink from '../../../../common/components/Button/ButtonLink.js'
import {AddressShape} from '../../../../common/PropTypes.js'
import {
  showAlternateShipFromAddressModal,
  showOrderShippingAddressModal,
} from '../../../../data/orders.js'
import {useForm, useSelector} from '../../../../store.js'
import {showEditOrderWarehouseModal} from '../EditOrderWarehouseModal.js'
import {
  errorsSelector,
  MODAL_FORM,
  QS_CREATE_LABEL_PANEL,
  QS_PRINT_LABEL_PANEL,
  QS_SHIP_FROM_PANEL,
  QS_SHIP_TO_PANEL,
  quickShipFromAddressSelector,
  quickShipToAddressSelector,
  updateQuickShipForm,
} from './quickShipModalFunctions.js'
import AddressValidationIcon from '../../../../common/components/Order/AddressValidationIcon.js'

function Address({
  address: {
    name,
    company,
    street1,
    street2,
    city,
    state,
    zip,
    country,
    validation,
  },
}) {
  const validationStatus = validation && validation.status

  return (
    <ul className={className`list list--no-style ${validationStatus}`}>
      <li>
        <AddressValidationIcon address={{validation}} />
      </li>
      {(name || company) && (
        <li className="list__item fs-n0 margin-bottom-0">
          {company && <div>{company}</div>}
          {name && <div>{name}</div>}
        </li>
      )}
      {street1 && (
        <>
          <li className="list__item fs-n0 margin-bottom-0">{street1}</li>
          {street2 && (
            <li className="list__item fs-n0 margin-bottom-0">{street2}</li>
          )}
        </>
      )}
      {(city || state || zip) && (
        <li className="list__item fs-n0 margin-bottom-0">
          {city && <span>{city}</span>}
          {city && (state || zip) ? ', ' : ''}
          {state && <span>{state}</span>}
          {state && zip ? ' ' : ''}
          {zip && <span>{zip}</span>}
        </li>
      )}
      {country && (
        <li className="list__item fs-n0 margin-bottom-0">{country}</li>
      )}
    </ul>
  )
}

Address.propTypes = {
  address: AddressShape,
}

export default function SidePanel() {
  const form = useForm(MODAL_FORM)
  const errors = useSelector(errorsSelector)
  const shipFromAddress = useSelector(quickShipFromAddressSelector)
  const shipToAddress = useSelector(quickShipToAddressSelector)

  return (
    <div className="medium-4 columns">
      <p className="fs-00 lh-sm align-center divider--top divider--bottom uppercase margin-top-0 margin-left-10 margin-right-10">
        <strong>Ship in Four Easy Steps</strong>
      </p>
      <dl
        className={className`list--order-data list--timeline-point margin-top-15 padding-bottom-20 ${{
          'list--timeline-point-active':
            form.currentPanel === QS_SHIP_FROM_PANEL,
          'list--timeline-point-completed':
            form.currentPanel !== QS_SHIP_FROM_PANEL && !errors.shipFrom,
        }}`}
      >
        <dt className="list__item--order-data list__title--timeline-point fs-00 lh-sm-md">
          Set a Ship From Address
        </dt>
        {form.currentPanel !== QS_SHIP_FROM_PANEL && shipFromAddress && (
          <dd className="list__item--order-data list__item--timeline-point margin-top-5">
            <Address address={shipFromAddress} />
            {[QS_SHIP_TO_PANEL, QS_CREATE_LABEL_PANEL].includes(
              form.currentPanel,
            ) && (
              <div className="attr--timeline-point">
                <ButtonLink
                  className="margin-right-10"
                  onClick={() => {
                    if (form.currentPanel === QS_SHIP_TO_PANEL) {
                      updateQuickShipForm({
                        currentPanel: QS_SHIP_FROM_PANEL,
                      })
                    } else if (form.currentPanel === QS_CREATE_LABEL_PANEL) {
                      if (form.shipFromType === 'warehouse_id') {
                        showEditOrderWarehouseModal([form.orderNumber])
                      } else {
                        showAlternateShipFromAddressModal(form.orderNumber)
                      }
                    }
                  }}
                >
                  Edit
                </ButtonLink>
              </div>
            )}
          </dd>
        )}
      </dl>
      <dl
        className={className`list--order-data list--timeline-point padding-bottom-20 ${{
          'list--timeline-point-active': form.currentPanel === QS_SHIP_TO_PANEL,
          'list--timeline-point-completed':
            form.currentPanel !== QS_SHIP_TO_PANEL && !errors.shipTo,
        }}`}
      >
        <dt className="list__item--order-data list__title--timeline-point fs-00 lh-sm-md">
          Set a Ship To Address
        </dt>
        {[QS_CREATE_LABEL_PANEL, QS_PRINT_LABEL_PANEL].includes(
          form.currentPanel,
        ) &&
          shipToAddress && (
            <dd className="list__item--order-data list__item--timeline-point margin-top-5">
              <Address address={shipToAddress} />
              {form.currentPanel === QS_CREATE_LABEL_PANEL && (
                <div className="attr--timeline-point">
                  <ButtonLink
                    className="margin-right-10"
                    onClick={() =>
                      showOrderShippingAddressModal(form.orderNumber)
                    }
                  >
                    Edit
                  </ButtonLink>
                </div>
              )}
            </dd>
          )}
      </dl>
      <dl
        className={className`list--order-data list--timeline-point padding-bottom-20 ${{
          'list--timeline-point-active':
            form.currentPanel === QS_CREATE_LABEL_PANEL,
          'list--timeline-point-completed':
            form.currentPanel !== QS_CREATE_LABEL_PANEL &&
            form.orderNumber &&
            !errors.createLabel,
        }}`}
      >
        <dt className="list__item--order-data list__title--timeline-point fs-00 lh-sm-md">
          Create a Shipping Label
        </dt>
      </dl>
      <dl
        className={className`list--order-data list--timeline-point ${{
          'list--timeline-point-active':
            form.currentPanel === QS_PRINT_LABEL_PANEL,
        }}`}
      >
        <dt className="list__item--order-data list__title--timeline-point fs-00 lh-sm-md">
          Print a Shipping Label
        </dt>
      </dl>
    </div>
  )
}
