import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {dispatch} from '../../store.js'
import {MO_DETAIL_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  setupMODetailForm,
  removeMODetailForm,
  getCurrentDetailMO,
} from './moDetailActions.js'
import {moDetailReferenceIDSelector} from './moDetailSelectors.js'

function MODetailLocationListener({referenceID}) {
  useEffect(() => {
    if (!referenceID) {
      return
    }

    setupMODetailForm()

    dispatch(setCurrentPage(MO_DETAIL_PAGE))

    getCurrentDetailMO()

    return () => removeMODetailForm()
  }, [referenceID])

  return null
}

MODetailLocationListener.propTypes = {
  referenceID: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    referenceID: moDetailReferenceIDSelector(state),
  }
}

export default connect(mapStateToProps)(MODetailLocationListener)
