export const FINANCIAL_PANEL = 'financial'
export const SHIPPING_PANEL = 'shipping'
export const INVENTORY_PANEL = 'inventory'
export const PRODUCT_PANEL = 'product'
export const CUSTOMER_PANEL = 'customer'

export const ANALYTICS_PANELS = [
  FINANCIAL_PANEL,
  SHIPPING_PANEL,
  INVENTORY_PANEL,
  PRODUCT_PANEL,
  CUSTOMER_PANEL,
]

export const ORDER_DATE = 'order_date'
export const SHIP_DATE = 'ship_date'
export const TRACKING_CREATED_DATE = 'tracking_created_date'

export const DATE_TYPE_OPTIONS = [
  {value: ORDER_DATE, display: 'Ordered'},
  {value: SHIP_DATE, display: 'Shipped'},
  {value: TRACKING_CREATED_DATE, display: 'Tracking Created'},
]

export const DEFAULT_PANEL = FINANCIAL_PANEL
export const DEFAULT_DATE_TYPE = ORDER_DATE

export const ANALYTICS_URI_COMPONENT = 'analytics'
