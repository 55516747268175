import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import {getRealDate} from '../../../common/date.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setBatch} from '../../../data/batches.js'
import {refreshBatchList} from '../batchListActions.js'

const MODAL_FORM = 'ARCHIVE_BATCH_MODAL'

export function showArchiveBatchModal(referenceIDs, archive = true) {
  setForm(MODAL_FORM, {
    referenceIDs,
    archive,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function archiveBatch(referenceID, params) {
  const {json} = await apiverson.put(
    `/batch/${encodeURIComponent(referenceID)}`,
    params,
  )

  setBatch(json)
}

export async function archiveBatches() {
  const {referenceIDs, archive} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true})

    const params = {
      archive_date: archive ? getRealDate().toISOString() : null,
    }

    await Promise.all(
      referenceIDs.map((referenceID) => archiveBatch(referenceID, params)),
    )

    showMessageToast(
      plural(referenceIDs.length)`${referenceIDs.length} batch${[
        'es were',
        ' was',
      ]} ${archive ? 'archived' : 'unarchived'}.`,
    )

    closeModal()

    await refreshBatchList()
  } catch (err) {
    updateModalForm({
      serverError: `Error ${archive ? 'archiving' : 'unarchiving'} batches: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function ArchiveBatchModal({form}) {
  return (
    <ConfirmModal
      title={plural(form.referenceIDs.length)`${
        form.archive ? 'Archive' : 'Unarchive'
      } Batch${['es']}`}
      onConfirm={() => archiveBatches()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md">
        <strong>
          <PluralBlock array={form.referenceIDs}>
            {form.archive ? 'Archive' : 'Unarchive'} <Count /> Batch
            <Plural p="es" />?
          </PluralBlock>
        </strong>
      </p>
    </ConfirmModal>
  )
}

ArchiveBatchModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    archive: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(ArchiveBatchModal, modalFormSelector)
