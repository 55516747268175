import PropTypes from 'prop-types'

export default function OrderNumberOrCount({orderNumbers}) {
  if (!orderNumbers) {
    return null
  }
  if (orderNumbers.length === 1) {
    return <span>{orderNumbers[0]}</span>
  }
  return <span>{orderNumbers.length} orders</span>
}

OrderNumberOrCount.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
}
