import PropTypes from 'prop-types'

import {onlyIfForm, useSelector} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  closeModal,
  errorsSelector,
  isLoadingSelector,
  modalFormSelector,
  nextQuickShipPanel,
  QS_CREATE_LABEL_PANEL,
  QS_PRINT_LABEL_PANEL,
  QS_SHIP_FROM_PANEL,
  QS_SHIP_TO_PANEL,
  quickShipNoLabelToCreateSelector,
} from './quickShipModalFunctions.js'
import ShipFromPanel from './ShipFromPanel.js'
import ShipToPanel from './ShipToPanel.js'
import CreateLabelPanel from './CreateLabelPanel.js'
import PrintLabelPanel from './PrintLabelPanel.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {navigateToOrderDetailPage} from '../../../OrderDetailPage/orderDetailActions.js'
import SidePanel from './SidePanel.js'

function QuickShipModal({form}) {
  const errors = useSelector(errorsSelector)
  const isLoading = useSelector(isLoadingSelector)
  const noLabelToCreate = useSelector(quickShipNoLabelToCreateSelector)

  return (
    <ConfirmModal
      title="Quick Ship"
      modalSize="md"
      className="modal--quick-ship"
      onConfirm={
        form.currentPanel === QS_PRINT_LABEL_PANEL
          ? null
          : () => nextQuickShipPanel()
      }
      onCancel={() => closeModal()}
      confirmText={
        form.currentPanel === QS_SHIP_FROM_PANEL
          ? 'Next'
          : form.currentPanel === QS_SHIP_TO_PANEL
            ? 'Next'
            : form.currentPanel === QS_CREATE_LABEL_PANEL
              ? noLabelToCreate
                ? 'Next'
                : 'Create Label'
              : 'Close'
      }
      cancelText={
        [QS_SHIP_FROM_PANEL, QS_SHIP_TO_PANEL].includes(form.currentPanel) &&
        !isLoading
          ? 'Cancel'
          : 'Close'
      }
      MiddleButtons={
        form.currentPanel === QS_CREATE_LABEL_PANEL &&
        form.orderNumber &&
        !isLoading
          ? () => (
              <div className="margin-right-10">
                <ButtonPrimary
                  alt
                  onClick={() => {
                    navigateToOrderDetailPage(form.orderNumber)

                    closeModal()
                  }}
                >
                  Close and View Details
                </ButtonPrimary>
              </div>
            )
          : null
      }
      preventHotKeyConfirm
      isSaving={isLoading}
      error={form.serverError || errors.createLabel}
      isDisabled={errors.preventSave}
      helpLink="https://support.ordoro.com/how-does-quick-ship-work/"
      helpMetaClass="meta-quickship-support-link"
    >
      {isLoading && (
        <div className="loading align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle margin-right-10" />
          <em className="fs-02 lh-md v-align-middle op-50">
            {form.currentPanel === QS_SHIP_TO_PANEL ? (
              <strong>Loading shipping label panel...</strong>
            ) : form.currentPanel === QS_CREATE_LABEL_PANEL ? (
              <strong>Creating your label...</strong>
            ) : null}
          </em>
        </div>
      )}
      {!isLoading && (
        <div className="row">
          <div className="medium-8 columns">
            {form.currentPanel === QS_SHIP_FROM_PANEL ? (
              <ShipFromPanel />
            ) : form.currentPanel === QS_SHIP_TO_PANEL ? (
              <ShipToPanel />
            ) : form.currentPanel === QS_CREATE_LABEL_PANEL ? (
              <CreateLabelPanel />
            ) : form.currentPanel === QS_PRINT_LABEL_PANEL ? (
              <PrintLabelPanel />
            ) : null}
          </div>
          <SidePanel />
        </div>
      )}
    </ConfirmModal>
  )
}

QuickShipModal.propTypes = {
  form: PropTypes.shape({
    currentPanel: PropTypes.oneOf([
      QS_SHIP_FROM_PANEL,
      QS_SHIP_TO_PANEL,
      QS_CREATE_LABEL_PANEL,
      QS_PRINT_LABEL_PANEL,
    ]),
    orderNumber: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(QuickShipModal, modalFormSelector)
