import PropTypes from 'prop-types'

import NumberInput from '../../../common/components/Form/NumberInput.js'
import {
  updateParcel,
  declaredValueSelector,
  declaredValueWarningSelector,
  declaredValueErrorSelector,
  isMultiboxSelector,
} from '../../../data/labelInfos/index.js'
import {showMultiboxModal} from '../../OrderListPage/Modals/MultiboxModal/index.js'
import {useSelector} from '../../../store.js'
import {
  AMAZON_SHIPPER,
  DHL,
  DHL_ECOMMERCE,
  FEDEX,
  UPS,
  X_DELIVERY,
} from '../../../common/constants/ShipperNames.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [
  AMAZON_SHIPPER,
  DHL_ECOMMERCE,
  DHL,
  FEDEX,
  UPS,
  X_DELIVERY,
]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`packages[0].${shipperType}__declared_value`]
    : []
}

export default function DeclaredValue({packagesIndex}) {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  packagesIndex = packagesIndex || 0

  const [labelProperty] = labelPropertiesFunc(shipperType)

  const declaredValue = useSelector((state) =>
    declaredValueSelector(state, {labelInfoID, shipperType, packagesIndex}),
  )
  const warning = useSelector((state) =>
    declaredValueWarningSelector(state, {labelInfoID, shipperType}),
  )
  const error = useSelector((state) =>
    declaredValueErrorSelector(state, {labelInfoID, shipperType}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes: VALID_SHIPPER_TYPES,
  })

  if (!VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  return (
    <li
      className={className`list__item--shipping-options btm-offset-7 ${formatMetaClassName(
        labelProperty,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <div className="flex">
          <label className="label--bold" htmlFor="declared_value">
            Declared Value
          </label>
          {isMultibox && (
            <button
              className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
              type="button"
              onClick={() => showMultiboxModal(labelInfoID, shipperType)}
            >
              <em>(Multiple)</em>
            </button>
          )}
        </div>
        <NumberInput
          type="text"
          id={`${labelInfoID}__${labelProperty}`}
          value={isMultibox ? '' : declaredValue}
          disabled={
            isMultibox || (isLabelEnableMode && !isLabelPropertyEnabled)
          }
          onChange={(value) => {
            updateParcel(labelInfoID, packagesIndex, {
              [`${shipperType}__declared_value`]: value,
            })

            onChange()
          }}
        />
        {warning && <small className="warning">{warning}</small>}
        {error && <small className="error">{error}</small>}
      </div>
      <EnableLabelProperty labelProperty={labelProperty} />
    </li>
  )
}

DeclaredValue.propTypes = {
  packagesIndex: PropTypes.number,
}
