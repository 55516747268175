import {useSelector} from '../../store.js'
import {plural} from '../../common/components/Plural.js'
import ListItemsCount from '../../common/components/List/ListItemsCount.js'
import {batchListQuerySelector, countSelector} from './batchListSelectors.js'

export default function BatchListItemsCount() {
  const {limit, offset} = useSelector(batchListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <li className="inline-block margin-right-3">
      <ListItemsCount
        limit={limit}
        offset={offset}
        count={count}
        thing={plural(count)`Batch${['es']}`}
      />
    </li>
  )
}
