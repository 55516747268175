import round from 'lodash/round.js'

// We have decided that APIverson will use oz as its unit of weight.
// Unfortunately, the legacy API still uses lb in some places.

export function splitLb(totalInLB = 0) {
  const lb = Math.floor(totalInLB)

  return {
    lb,
    oz: (totalInLB - lb) * 16,
  }
}

export function roundOz(oz) {
  return oz >= 15.999 && oz < 16 ? 15.999 : round(oz, 3)
}

export function splitOz(totalInOZ = 0) {
  return {
    lb: Math.floor(ozToLb(totalInOZ)),
    oz: roundOz(totalInOZ % 16),
  }
}

export function formatWeight({lb = 0, oz = 0}) {
  const formattedLB = `${lb} lb`
  const formattedOZ = `${roundOz(oz)} oz`

  if (lb && oz) {
    return `${formattedLB} ${formattedOZ}`
  } else if (lb) {
    return formattedLB
  }

  return formattedOZ
}

export function formatOz(totalInOz) {
  return formatWeight(splitOz(totalInOz))
}

export function formatLb(totalInLb) {
  return formatWeight(splitLb(totalInLb))
}

export function combineToLb(lb = 0, oz = 0) {
  return Number(lb) + ozToLb(Number(oz))
}

export function combineToOz(lb = 0, oz = 0) {
  return lbToOz(Number(lb)) + Number(oz)
}

export function lbToOz(lb = 0) {
  return lb * 16
}

export function ozToLb(oz = 0) {
  return oz / 16
}
