import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {analyticsFormSelector} from './analyticsSelectors.js'

export const topCustomersSelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'order_financials__top_customers'),
  (data) => {
    if (!data) {
      return null
    }

    return {
      buckets: data.map((bucket) => ({
        email: bucket.bill_to_email,
        name: bucket.bill_to_name || '',
        total_spent: bucket.total_product_sales,
        order_count: bucket.total_orders,
        sku_count: bucket.total_products_purchased,
      })),
    }
  },
)
