import {
  updateLabelConfig,
  insuranceCostSelector,
  insuredValueSelector,
  includeInsuranceSelector,
} from '../../../../data/labelInfos/index.js'
import Insurance from './Insurance.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function SingleInsurance() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {labelInfoID}),
  )
  const insuredValue = useSelector((state) =>
    insuredValueSelector(state, {labelInfoID}),
  )
  const insuranceCost = useSelector((state) =>
    insuranceCostSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ul className="list">
      <Insurance
        shipperType={shipperType}
        includeInsurance={includeInsurance}
        toggle={() => {
          updateLabelConfig(labelInfoID, {
            include_insurance: !includeInsurance,
          })

          onChange()
        }}
        insuredValue={insuredValue}
        setInsuredValue={(value) => {
          updateLabelConfig(labelInfoID, {insured_value: value})

          onChange()
        }}
        insuranceCost={insuranceCost}
      />
    </ul>
  )
}
