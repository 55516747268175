import {useEffect} from 'react'

import {MOCK_ABODE_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {dispatch, useSelector} from '../../store.js'
import {
  MOCK_ABODE_URI_COMPONENT,
  setupMockAbodeForm,
  removeMockAbodeForm,
  mockAbodeGoToResource,
} from './mockAbodeFunctions.js'

export default function MockAbodeLocationListener() {
  const location = useSelector(locationSelector)

  const {
    pathComponents: [resource, abodeResource],
  } = location
  const isThisPage = resource === MOCK_ABODE_URI_COMPONENT

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    setupMockAbodeForm()

    dispatch(setCurrentPage(MOCK_ABODE_PAGE))

    return () => removeMockAbodeForm()
  }, [isThisPage])

  useEffect(() => {
    if (isThisPage && !abodeResource) {
      mockAbodeGoToResource('label')
    }
  }, [isThisPage, abodeResource])

  return null
}
