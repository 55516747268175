import PropTypes from 'prop-types'
import {Component} from 'react'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'

export default class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onNameChange = (value) => updatePayload({name: value})
    this.onCompanyChange = (value) => updatePayload({company: value})
    this.onPhoneChange = (value) => updatePayload({phone: value})
    this.onFaxChange = (value) => updatePayload({fax: value})
    this.onEmailChange = (value) => updatePayload({email: value})
    this.onStreet1Change = (value) => updatePayload({street1: value})
    this.onStreet2Change = (value) => updatePayload({street2: value})
    this.onCityChange = (value) => updatePayload({city: value})
    this.onStateChange = (value) => updatePayload({state: value})
    this.onZipChange = (value) => updatePayload({zip: value})
    this.onCountryChange = (value) => updatePayload({country: value})
    this.onReferenceNumberChange = (value) =>
      updatePayload({reference_number: value})
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-8 columns" {...this.props}>
        <Row>
          <TextInput
            value={data.payload.name}
            label="Name"
            name="name"
            className="medium-7"
            fsExclude
            onChange={this.onNameChange}
          />
          <TextInput
            value={data.payload.company}
            label="Company"
            name="company"
            className="medium-5"
            fsExclude
            onChange={this.onCompanyChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.phone}
            label="Phone"
            name="phone"
            className="medium-6"
            fsExclude
            onChange={this.onPhoneChange}
          />
          <TextInput
            value={data.payload.fax}
            label="Fax"
            name="fax"
            className="medium-6"
            fsExclude
            onChange={this.onFaxChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.email}
            label="Email"
            name="email"
            className="medium-12"
            fsExclude
            onChange={this.onEmailChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.street1}
            label="Street 1"
            name="street1"
            className="medium-12"
            fsExclude
            onChange={this.onStreet1Change}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.street2}
            label="Street 2"
            name="street2"
            className="medium-12"
            fsExclude
            onChange={this.onStreet2Change}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.city}
            label="City"
            name="city"
            className="medium-7"
            fsExclude
            onChange={this.onCityChange}
          />
          <TextInput
            value={data.payload.state}
            label="State"
            name="state"
            className="medium-2"
            fsExclude
            onChange={this.onStateChange}
          />
          <TextInput
            value={data.payload.zip}
            label="Zipcode"
            name="zip"
            className="medium-3"
            fsExclude
            onChange={this.onZipChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.country}
            label="Country"
            name="country"
            className="medium-4"
            fsExclude
            onChange={this.onCountryChange}
          />
          <TextInput
            value={data.payload.reference_number}
            label="Reference Number"
            name="reference_number"
            className="medium-8"
            onChange={this.onReferenceNumberChange}
          />
        </Row>
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
