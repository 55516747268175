import PropTypes from 'prop-types'
import classNames from 'classnames'

import NumberInput from '../../../../common/components/Form/NumberInput.js'
import InsuranceCost from './InsuranceCost.js'
import {getState, useSelector} from '../../../../store.js'
import EnableLabelProperty from '../EnableLabelProperty.js'
import Boolean from '../../../../common/components/Boolean.js'
import {
  canHaveInsuranceSelector,
  insuranceErrorMessageSelector,
} from '../../../../data/labelInfos/index.js'
import useLabelProperty from '../useLabelProperty.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY_INCLUDE_INSURANCE = 'include_insurance'
const LABEL_PROPERTY_INSURED_VALUE = 'insured_value'

function includeInsuranceLabelProperties(shipperType, labelInfoID) {
  const canHaveInsurance = canHaveInsuranceSelector(getState(), {
    labelInfoID,
    shipperType,
  })

  return canHaveInsurance ? [LABEL_PROPERTY_INCLUDE_INSURANCE] : []
}

const INCLUDE_INSURANCE_VALID_PROPERTIES = [LABEL_PROPERTY_INCLUDE_INSURANCE]

function insuredValueLabelProperties(shipperType, labelInfoID) {
  const canHaveInsurance = canHaveInsuranceSelector(getState(), {
    labelInfoID,
    shipperType,
  })

  return canHaveInsurance ? [LABEL_PROPERTY_INSURED_VALUE] : []
}

function insuredValueDependantProperties() {
  return [LABEL_PROPERTY_INCLUDE_INSURANCE]
}

const INSURED_VALUE_VALID_PROPERTIES = [LABEL_PROPERTY_INSURED_VALUE]

export default function Insurance({
  includeInsurance,
  toggle,
  insuredValue,
  setInsuredValue,
  insuranceCost,
}) {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()
  const canHaveInsurance = useSelector((state) =>
    canHaveInsuranceSelector(state, {labelInfoID, shipperType}),
  )
  const insuranceErrorMessage = useSelector((state) =>
    insuranceErrorMessageSelector(state, {labelInfoID, shipperType}),
  )

  const {isLabelPropertyEnabled: isIncludeInsuranceEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_INCLUDE_INSURANCE,
    labelPropertiesFunc: includeInsuranceLabelProperties,
    validLabelProperties: INCLUDE_INSURANCE_VALID_PROPERTIES,
  })

  const {isLabelPropertyEnabled: isInsuredValueEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_INSURED_VALUE,
    labelPropertiesFunc: insuredValueLabelProperties,
    dependantPropertiesFunc: insuredValueDependantProperties,
    validLabelProperties: INSURED_VALUE_VALID_PROPERTIES,
  })

  if (!canHaveInsurance) {
    return null
  }

  return (
    <>
      <ConfigCheckbox
        label="Insure this package"
        onChange={toggle}
        labelProperty={LABEL_PROPERTY_INCLUDE_INSURANCE}
      />
      <li className="flex-item list__item--shipping-options">
        <div className="wrap--edit-preset-form-input">
          <div
            className={classNames('wrap--insurance-amt wrap--edit-mode', {
              'op-50': !includeInsurance,
            })}
          >
            <ul className="list">
              <li className="list__item--no-style flex flex-wrap flex-wrap-margin-10 margin-bottom-7">
                <div className="flex-item">
                  <label className="label--sm" htmlFor="insured_value">
                    Value
                  </label>
                  <NumberInput
                    id="insured_value"
                    value={insuredValue || ''}
                    onChange={(value) => setInsuredValue(`${value}`)}
                  />
                  {insuranceErrorMessage && (
                    <small className="error">{insuranceErrorMessage}</small>
                  )}
                </div>
              </li>
              <li className="list__item--no-style fs-n1 margin-bottom-5">
                <InsuranceCost
                  insuranceCost={insuranceCost}
                  includeInsurance={includeInsurance}
                />
              </li>
              {isLabelEnableMode && isIncludeInsuranceEnabled && (
                <li className="list__item--no-style fs-n1 margin-bottom-5">
                  <div>
                    {includeInsurance ? (
                      <div>
                        {!isInsuredValueEnabled && (
                          <em>Value of the order will be insured</em>
                        )}
                      </div>
                    ) : (
                      <Boolean value={includeInsurance} />
                    )}
                  </div>
                </li>
              )}
            </ul>
            <div className="divider--top sm">
              <small className="input--help lh-md block">
                {
                  'By using Shipsurance’s discounted insurance rates, you agree to the '
                }
                <a
                  href="http://support.ordoro.com/shipsurance-terms-conditions/"
                  title="Shipsurances terms and conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>
                {'.'}
              </small>
            </div>
          </div>
        </div>
        {isIncludeInsuranceEnabled && (
          <EnableLabelProperty labelProperty={LABEL_PROPERTY_INSURED_VALUE} />
        )}
      </li>
    </>
  )
}

Insurance.propTypes = {
  includeInsurance: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  insuredValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInsuredValue: PropTypes.func.isRequired,
  insuranceCost: PropTypes.number,
}
