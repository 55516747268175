import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Currency, {isTBD} from '../../common/components/Currency.js'
import Weight from '../../common/components/Weight.js'
import Dimensions from '../../common/components/Dimensions.js'
import {AddressShape} from '../../common/PropTypes.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {
  hasShippingInfoSelector,
  rmaShippingInfoSelector,
  rmaShippedShipperNameSelector,
  rmaShippedShippingMethodSelector,
  rmaShippedBoxShapeNameSelector,
  rmaShippedIsMultibox,
  rmaShippedCurrencySymbolSelector,
  rmaShippedTotalWeightSelector,
  rmaShippedDimensionsSelector,
  showRMAShippedDimensionsSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {showDeleteReturnOrderLabelsModal} from '../ReturnOrderListPage/Modals/DeleteReturnOrderLabelsModal.js'
import {returnOrderDetailReferenceIDSelector} from './returnOrderDetailSelectors.js'

export function RMAShippedInfo({
  referenceID,
  hasShippingInfo,
  shippingInfo,
  shipperName,
  shippingMethod,
  boxShape,
  isMultibox,
  shipperCurrencySymbol,
  totalWeight,
  dimensions,
  showDimensions,
  ...props
}) {
  if (!hasShippingInfo) {
    return null
  }

  return (
    <div className="medium-4 columns wrap__list--order-data">
      <dl className="list list--order-data">
        <dt className="list__title--order-data">Return Label Created</dt>
        <dd className="list__item--order-data">
          <ul className="list--order-data list--no-style">
            <li className="list__item--order-data">
              <strong className="margin-right-3">Date Created:</strong>
              <FormattedDate
                value={shippingInfo.created_date}
                format="MMM D, YYYY [at] h:mma"
              />
            </li>
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Returning To:</strong>
              <span>
                {shippingInfo.ship_to.company || shippingInfo.ship_to.name}
              </span>
            </li>
            {shippingInfo.tracking_number && (
              <li className="list__item--order-data">
                <strong className="margin-right-3">Tracking:</strong>
                {shippingInfo.tracking_url ? (
                  <a
                    href={shippingInfo.tracking_url}
                    className="btn btn--link v-align-base"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {shippingInfo.tracking_number}
                  </a>
                ) : (
                  shippingInfo.tracking_number
                )}
              </li>
            )}
          </ul>
        </dd>
        <dd className="list__item--order-data list__item--shipping-info">
          <ul className="list--order-data list--no-style">
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Carrier:</strong>
              {shipperName ? (
                <span>{shipperName}</span>
              ) : (
                <em>None selected</em>
              )}
            </li>
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Shipping Method:</strong>
              {shippingMethod ? (
                <span>{shippingMethod}</span>
              ) : (
                <em>None selected</em>
              )}
            </li>
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Package Type:</strong>
              {isMultibox ? (
                <em>Multiple</em>
              ) : boxShape ? (
                <span>{boxShape}</span>
              ) : (
                <em>None selected</em>
              )}
            </li>
          </ul>
        </dd>
        <dd className="list__item--order-data">
          <strong className="margin-right-3">Cost:</strong>
          <span>
            {isTBD(shippingInfo.cost) ? (
              'TBD'
            ) : (
              <Currency
                value={shippingInfo.cost}
                currencySymbol={shipperCurrencySymbol}
              />
            )}
          </span>
        </dd>
        <dd className="list__item--order-data list__item--shipping-info">
          <ul className="list--order-data list--no-style">
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Weight:</strong>
              {isMultibox ? <em>Multiple</em> : <Weight value={totalWeight} />}
            </li>
            <li className="list__item--order-data list__item--shipping-info">
              <strong className="margin-right-3">Dimensions:</strong>
              <Dimensions
                {...(showDimensions
                  ? isMultibox
                    ? {blankText: 'Multiple'}
                    : dimensions
                  : {blankText: 'N/A'})}
              />
            </li>
          </ul>
        </dd>
        <div className="list__item--order-data margin-top-3">
          <ButtonLink
            onClick={() =>
              props.showDeleteReturnOrderLabelsModal([referenceID])
            }
          >
            Delete label
          </ButtonLink>
        </div>
      </dl>
    </div>
  )
}

RMAShippedInfo.propTypes = {
  referenceID: PropTypes.string.isRequired,
  hasShippingInfo: PropTypes.bool.isRequired,
  shippingInfo: PropTypes.shape({
    created_date: PropTypes.string.isRequired,
    ship_to: AddressShape.isRequired,
    tracking_number: PropTypes.string,
    tracking_url: PropTypes.string,
    cost: PropTypes.number,
  }),
  shipperName: PropTypes.string,
  shippingMethod: PropTypes.string,
  boxShape: PropTypes.string,
  isMultibox: PropTypes.bool.isRequired,
  shipperCurrencySymbol: PropTypes.string,
  totalWeight: PropTypes.number.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
  }),
  showDimensions: PropTypes.bool.isRequired,
  showDeleteReturnOrderLabelsModal: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const referenceID = returnOrderDetailReferenceIDSelector(state)

  return {
    referenceID,
    hasShippingInfo: hasShippingInfoSelector(state, {referenceID}),
    shippingInfo: rmaShippingInfoSelector(state, {referenceID}),
    shipperName: rmaShippedShipperNameSelector(state, {referenceID}),
    shippingMethod: rmaShippedShippingMethodSelector(state, {referenceID}),
    boxShape: rmaShippedBoxShapeNameSelector(state, {referenceID}),
    isMultibox: rmaShippedIsMultibox(state, {referenceID}),
    shipperCurrencySymbol: rmaShippedCurrencySymbolSelector(state, {
      referenceID,
    }),
    totalWeight: rmaShippedTotalWeightSelector(state, {referenceID}),
    dimensions: rmaShippedDimensionsSelector(state, {referenceID}),
    showDimensions: showRMAShippedDimensionsSelector(state, {referenceID}),
  }
}

const mapDispatchToProps = {
  showDeleteReturnOrderLabelsModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(RMAShippedInfo)
