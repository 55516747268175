import {useEffect} from 'react'
import analytics from '../../../common/analytics/index.js'

import SettingsPanel from '../SettingsPanel.js'

export default function ThirdPartyLogistics() {
  useEffect(() => {
    analytics.trackEvent('third_party_logistics_marketing')
  }, [])

  return (
    <SettingsPanel
      header="Integrated 3PLs"
      subHeader={
        <div>
          Easily use Ordoro to have your orders wired, picked, packed, and
          shipped through one of our integrated third-party logistics providers
          (3PLs).
        </div>
      }
    >
      <div className="row margin-bottom-30">
        <div className="medium-7 columns">
          <div className="padding-right-15">
            <dl className="list margin-bottom-25">
              <dt className="list__title fs-02 lh-sm margin-bottom-10">
                Fulfillment by Amazon
              </dt>
              <dd className="fs-00 margin-bottom-0">
                Popular among retailers (especially Amazon sellers), Fulfillment
                By Amazon (FBA) is a third-party logistics service (3PL) that
                stores inventory and ships orders for you.
              </dd>
            </dl>
            <dl className="list">
              <dt className="list__title fs-01 lh-sm margin-bottom-10">
                How It Works
              </dt>
              <dd className="fs-00 margin-bottom-15">
                When an order arrives from any of your integrated sales
                channels, it’s automatically sent to FBA for fulfillment. Once
                shipped, FBA will route order information and updated inventory
                counts to Ordoro, which the app then relays back to your sales
                channels.
              </dd>
              <dd className="fs-00 margin-bottom-15">
                Integrate FBA with Ordoro through your Amazon account. Once
                connected, import your products, assign them to FBA, send them
                to be stored at an FBA warehouse, and prepare to relax.
              </dd>
              <dd className="fs-00 margin-bottom-15">
                <a
                  href="https://support.ordoro.com/how-do-i-set-up-ordoro-products-for-fba/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn more about configuring your products for FBA
                </a>{' '}
                <span className="op-50">→</span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="medium-9 medium-centered columns">
          <p className="fs-00 margin-bottom-0 align-center">
            <strong>
              Working with a 3PL that’s not currently supported by Ordoro?
            </strong>{' '}
            <br />
            <a
              href="https://www.ordoro.com/developer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Crack open our API
            </a>{' '}
            to build a custom integration.
          </p>
        </div>
      </div>
    </SettingsPanel>
  )
}
