import Footer from '../../../../common/components/List/Footer/index.js'
import {useSelector} from '../../../../store.js'
import {setLimit, setOffset} from '../../../OrderListPage/orderListActions.js'
import {
  countSelector,
  isLoadingSelector,
  orderListQuerySelector,
} from '../../../OrderListPage/orderListSelectors.js'

export default function OrderListFooter() {
  const isLoading = useSelector(isLoadingSelector)
  const {limit, offset} = useSelector(orderListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <Footer
      isLoading={isLoading}
      limit={limit}
      offset={offset}
      count={count}
      setOffset={setOffset}
      setLimit={setLimit}
      preventResetOffset
    />
  )
}
