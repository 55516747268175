export const Countries = [
  {
    names: ['USA', 'United States of America'],
    code2: 'US',
    code3: 'USA',
    flag: '🇺🇸',
  },
  {names: ['Afghanistan'], code2: 'AF', code3: 'AFG', flag: '🇦🇫'},
  {
    names: ['Åland Islands', 'Aland Islands'],
    code2: 'AX',
    code3: 'ALA',
    flag: '🇮🇨',
  },
  {names: ['Albania'], code2: 'AL', code3: 'ALB', flag: '🇦🇱'},
  {names: ['Algeria'], code2: 'DZ', code3: 'DZA', flag: '🇩🇿'},
  {names: ['American Samoa'], code2: 'AS', code3: 'ASM', flag: '🇦🇸'},
  {names: ['Andorra'], code2: 'AD', code3: 'AND', flag: '🇦🇩'},
  {names: ['Angola'], code2: 'AO', code3: 'AGO', flag: '🇦🇴'},
  {names: ['Anguilla'], code2: 'AI', code3: 'AIA', flag: '🇦🇮'},
  {names: ['Antarctica'], code2: 'AQ', code3: 'ATA', flag: '🇦🇶'},
  {names: ['Antigua and Barbuda'], code2: 'AG', code3: 'ATG', flag: '🇦🇬'},
  {names: ['Argentina'], code2: 'AR', code3: 'ARG', flag: '🇦🇷'},
  {names: ['Armenia'], code2: 'AM', code3: 'ARM', flag: '🇦🇲'},
  {names: ['Aruba'], code2: 'AW', code3: 'ABW', flag: '🇦🇼'},
  {names: ['Australia'], code2: 'AU', code3: 'AUS', flag: '🇦🇺'},
  {names: ['Austria'], code2: 'AT', code3: 'AUT', flag: '🇦🇹'},
  {names: ['Azerbaijan'], code2: 'AZ', code3: 'AZE', flag: '🇦🇿'},
  {names: ['Bahamas'], code2: 'BS', code3: 'BHS', flag: '🇧🇸'},
  {names: ['Bahrain'], code2: 'BH', code3: 'BHR', flag: '🇧🇭'},
  {names: ['Bangladesh'], code2: 'BD', code3: 'BGD', flag: '🇧🇩'},
  {names: ['Barbados'], code2: 'BB', code3: 'BRB', flag: '🇧🇧'},
  {names: ['Belarus'], code2: 'BY', code3: 'BLR', flag: '🇧🇾'},
  {names: ['Belgium'], code2: 'BE', code3: 'BEL', flag: '🇧🇪'},
  {names: ['Belize'], code2: 'BZ', code3: 'BLZ', flag: '🇧🇿'},
  {names: ['Benin'], code2: 'BJ', code3: 'BEN', flag: '🇧🇯'},
  {names: ['Bermuda'], code2: 'BM', code3: 'BMU', flag: '🇧🇲'},
  {names: ['Bhutan'], code2: 'BT', code3: 'BTN', flag: '🇧🇹'},
  {
    names: ['Bolivia', 'Plurinational State of Bolivia'],
    code2: 'BO',
    code3: 'BOL',
    flag: '🇧🇴',
  },
  {
    names: ['Bonaire, Sint Eustatius and Saba', 'Caribbean Netherlands'],
    code2: 'BQ',
    code3: 'BES',
    flag: '🇧🇶',
  },
  {names: ['Bosnia and Herzegovina'], code2: 'BA', code3: 'BIH', flag: '🇧🇦'},
  {names: ['Botswana'], code2: 'BW', code3: 'BWA', flag: '🇧🇼'},
  {names: ['Bouvet Island'], code2: 'BV', code3: 'BVT', flag: '🇨🇵'},
  {names: ['Brazil'], code2: 'BR', code3: 'BRA', flag: '🇧🇷'},
  {
    names: ['British Indian Ocean Territory'],
    code2: 'IO',
    code3: 'IOT',
    flag: '🇮🇴',
  },
  {names: ['Brunei Darussalam'], code2: 'BN', code3: 'BRN', flag: '🇧🇳'},
  {names: ['Bulgaria'], code2: 'BG', code3: 'BGR', flag: '🇧🇬'},
  {names: ['Burkina Faso'], code2: 'BF', code3: 'BFA', flag: '🇧🇫'},
  {names: ['Burundi'], code2: 'BI', code3: 'BDI', flag: '🇧🇮'},
  {names: ['Cabo Verde'], code2: 'CV', code3: 'CPV', flag: '🇨🇻'},
  {names: ['Cambodia'], code2: 'KH', code3: 'KHM', flag: '🇰🇭'},
  {names: ['Cameroon'], code2: 'CM', code3: 'CMR', flag: '🇨🇲'},
  {names: ['Canada'], code2: 'CA', code3: 'CAN', flag: '🇨🇦'},
  {names: ['Cayman Islands'], code2: 'KY', code3: 'CYM', flag: '🇰🇾'},
  {names: ['Central African Republic'], code2: 'CF', code3: 'CAF', flag: '🇨🇫'},
  {names: ['Chad'], code2: 'TD', code3: 'TCD', flag: '🇹🇩'},
  {names: ['Chile'], code2: 'CL', code3: 'CHL', flag: '🇨🇱'},
  {
    names: ['Taiwan', 'Republic of China', 'Taiwan, Province of China'],
    code2: 'TW',
    code3: 'TWN',
    flag: '🇹🇼',
  },
  {
    names: ['China', "People's Republic of China", 'PRC'],
    code2: 'CN',
    code3: 'CHN',
    flag: '🇨🇳',
  },
  {names: ['Christmas Island'], code2: 'CX', code3: 'CXR', flag: '🇨🇽'},
  {names: ['Cocos (Keeling) Islands'], code2: 'CC', code3: 'CCK', flag: '🇨🇨'},
  {names: ['Colombia'], code2: 'CO', code3: 'COL', flag: '🇨🇴'},
  {names: ['Comoros'], code2: 'KM', code3: 'COM', flag: '🇰🇲'},
  {
    names: ['Congo', 'Republic of the Congo', 'Congo - Brazzaville'],
    code2: 'CG',
    code3: 'COG',
    flag: '🇨🇬',
  },
  {
    names: ['Democratic Republic of the Congo', 'Congo - Kinshasa'],
    code2: 'CD',
    code3: 'COD',
    flag: '🇨🇩',
  },
  {names: ['Cook Islands'], code2: 'CK', code3: 'COK', flag: '🇨🇰'},
  {names: ['Costa Rica'], code2: 'CR', code3: 'CRI', flag: '🇨🇷'},
  {names: ["Côte d'Ivoire"], code2: 'CI', code3: 'CIV', flag: '🇨🇮'},
  {names: ['Croatia'], code2: 'HR', code3: 'HRV', flag: '🇭🇷'},
  {names: ['Cuba'], code2: 'CU', code3: 'CUB', flag: '🇨🇺'},
  {names: ['Curaçao', 'Curacao'], code2: 'CW', code3: 'CUW', flag: '🇨🇼'},
  {names: ['Cyprus'], code2: 'CY', code3: 'CYP', flag: '🇨🇾'},
  {names: ['Czechia'], code2: 'CZ', code3: 'CZE', flag: '🇨🇿'},
  {names: ['Denmark'], code2: 'DK', code3: 'DNK', flag: '🇩🇰'},
  {names: ['Djibouti'], code2: 'DJ', code3: 'DJI', flag: '🇩🇯'},
  {names: ['Dominica'], code2: 'DM', code3: 'DMA', flag: '🇩🇲'},
  {names: ['Dominican Republic'], code2: 'DO', code3: 'DOM', flag: '🇩🇴'},
  {names: ['Ecuador'], code2: 'EC', code3: 'ECU', flag: '🇪🇨'},
  {names: ['Egypt'], code2: 'EG', code3: 'EGY', flag: '🇪🇬'},
  {names: ['El Salvador'], code2: 'SV', code3: 'SLV', flag: '🇸🇻'},
  {names: ['Equatorial Guinea'], code2: 'GQ', code3: 'GNQ', flag: '🇬🇶'},
  {names: ['Eritrea'], code2: 'ER', code3: 'ERI', flag: '🇪🇷'},
  {names: ['Estonia'], code2: 'EE', code3: 'EST', flag: '🇪🇪'},
  {names: ['Eswatini'], code2: 'SZ', code3: 'SWZ', flag: '🇸🇿'},
  {names: ['Ethiopia'], code2: 'ET', code3: 'ETH', flag: '🇪🇹'},
  {
    names: ['Falkland Islands (Malvinas)'],
    code2: 'FK',
    code3: 'FLK',
    flag: '🇫🇰',
  },
  {names: ['Faroe Islands'], code2: 'FO', code3: 'FRO', flag: '🇫🇴'},
  {names: ['Fiji'], code2: 'FJ', code3: 'FJI', flag: '🇫🇯'},
  {names: ['Finland'], code2: 'FI', code3: 'FIN', flag: '🇫🇮'},
  {names: ['France'], code2: 'FR', code3: 'FRA', flag: '🇫🇷'},
  {names: ['French Guiana'], code2: 'GF', code3: 'GUF', flag: '🇬🇫'},
  {names: ['French Polynesia'], code2: 'PF', code3: 'PYF', flag: '🇵🇫'},
  {
    names: ['French Southern Territories'],
    code2: 'TF',
    code3: 'ATF',
    flag: '🇹🇫',
  },
  {names: ['Gabon'], code2: 'GA', code3: 'GAB', flag: '🇬🇦'},
  {names: ['Gambia'], code2: 'GM', code3: 'GMB', flag: '🇬🇲'},
  {names: ['Georgia'], code2: 'GE', code3: 'GEO', flag: '🇬🇪'},
  {names: ['Germany'], code2: 'DE', code3: 'DEU', flag: '🇩🇪'},
  {names: ['Ghana'], code2: 'GH', code3: 'GHA', flag: '🇬🇭'},
  {names: ['Gibraltar'], code2: 'GI', code3: 'GIB', flag: '🇬🇮'},
  {names: ['Greece'], code2: 'GR', code3: 'GRC', flag: '🇬🇷'},
  {names: ['Greenland'], code2: 'GL', code3: 'GRL', flag: '🇬🇱'},
  {names: ['Grenada'], code2: 'GD', code3: 'GRD', flag: '🇬🇩'},
  {names: ['Guadeloupe'], code2: 'GP', code3: 'GLP', flag: '🇬🇵'},
  {names: ['Guam'], code2: 'GU', code3: 'GUM', flag: '🇬🇺'},
  {names: ['Guatemala'], code2: 'GT', code3: 'GTM', flag: '🇬🇹'},
  {names: ['Guernsey'], code2: 'GG', code3: 'GGY', flag: '🇬🇬'},
  {names: ['Guinea'], code2: 'GN', code3: 'GIN', flag: '🇬🇳'},
  {names: ['Guinea-Bissau'], code2: 'GW', code3: 'GNB', flag: '🇬🇼'},
  {names: ['Guyana'], code2: 'GY', code3: 'GUY', flag: '🇬🇾'},
  {names: ['Haiti'], code2: 'HT', code3: 'HTI', flag: '🇭🇹'},
  {
    names: ['Heard Island and McDonald Islands'],
    code2: 'HM',
    code3: 'HMD',
    flag: '🇭🇲',
  },
  {
    names: ['Vatican', 'Vatican City', 'Holy See'],
    code2: 'VA',
    code3: 'VAT',
    flag: '🇻🇦',
  },
  {names: ['Honduras'], code2: 'HN', code3: 'HND', flag: '🇭🇳'},
  {names: ['Hong Kong'], code2: 'HK', code3: 'HKG', flag: '🇭🇰'},
  {names: ['Hungary'], code2: 'HU', code3: 'HUN', flag: '🇭🇺'},
  {names: ['Iceland'], code2: 'IS', code3: 'ISL', flag: '🇮🇸'},
  {names: ['India'], code2: 'IN', code3: 'IND', flag: '🇮🇳'},
  {names: ['Indonesia'], code2: 'ID', code3: 'IDN', flag: '🇮🇩'},
  {
    names: ['Iran', 'Islamic Republic of Iran'],
    code2: 'IR',
    code3: 'IRN',
    flag: '🇮🇷',
  },
  {names: ['Iraq'], code2: 'IQ', code3: 'IRQ', flag: '🇮🇶'},
  {names: ['Ireland'], code2: 'IE', code3: 'IRL', flag: '🇮🇪'},
  {names: ['Isle of Man'], code2: 'IM', code3: 'IMN', flag: '🇮🇲'},
  {names: ['Israel'], code2: 'IL', code3: 'ISR', flag: '🇮🇱'},
  {names: ['Italy'], code2: 'IT', code3: 'ITA', flag: '🇮🇹'},
  {names: ['Jamaica'], code2: 'JM', code3: 'JAM', flag: '🇯🇲'},
  {names: ['Japan'], code2: 'JP', code3: 'JPN', flag: '🇯🇵'},
  {names: ['Jersey'], code2: 'JE', code3: 'JEY', flag: '🇯🇪'},
  {names: ['Jordan'], code2: 'JO', code3: 'JOR', flag: '🇯🇴'},
  {names: ['Kazakhstan'], code2: 'KZ', code3: 'KAZ', flag: '🇰🇿'},
  {names: ['Kenya'], code2: 'KE', code3: 'KEN', flag: '🇰🇪'},
  {names: ['Kiribati'], code2: 'KI', code3: 'KIR', flag: '🇰🇮'},
  {
    names: ['Korea', 'South Korea', 'Republic of Korea'],
    code2: 'KR',
    code3: 'KOR',
    flag: '🇰🇷',
  },
  {
    names: ['North Korea', "Democratic People's Republic of Korea", 'DPRK'],
    code2: 'KP',
    code3: 'PRK',
    flag: '🇰🇵',
  },
  {names: ['Kuwait'], code2: 'KW', code3: 'KWT', flag: '🇰🇼'},
  {names: ['Kyrgyzstan'], code2: 'KG', code3: 'KGZ', flag: '🇰🇬'},
  {
    names: ['Laos', "Lao People's Democratic Republic"],
    code2: 'LA',
    code3: 'LAO',
    flag: '🇱🇦',
  },
  {names: ['Latvia'], code2: 'LV', code3: 'LVA', flag: '🇱🇻'},
  {names: ['Lebanon'], code2: 'LB', code3: 'LBN', flag: '🇱🇧'},
  {names: ['Lesotho'], code2: 'LS', code3: 'LSO', flag: '🇱🇸'},
  {names: ['Liberia'], code2: 'LR', code3: 'LBR', flag: '🇱🇷'},
  {names: ['Libya'], code2: 'LY', code3: 'LBY', flag: '🇱🇾'},
  {names: ['Liechtenstein'], code2: 'LI', code3: 'LIE', flag: '🇱🇮'},
  {names: ['Lithuania'], code2: 'LT', code3: 'LTU', flag: '🇱🇹'},
  {names: ['Luxembourg'], code2: 'LU', code3: 'LUX', flag: '🇱🇺'},
  {names: ['Macao', 'Macao Sar China'], code2: 'MO', code3: 'MAC', flag: '🇲🇴'},
  {names: ['Madagascar'], code2: 'MG', code3: 'MDG', flag: '🇲🇬'},
  {names: ['Malawi'], code2: 'MW', code3: 'MWI', flag: '🇲🇼'},
  {names: ['Malaysia'], code2: 'MY', code3: 'MYS', flag: '🇲🇾'},
  {names: ['Maldives'], code2: 'MV', code3: 'MDV', flag: '🇲🇻'},
  {names: ['Mali'], code2: 'ML', code3: 'MLI', flag: '🇲🇱'},
  {names: ['Malta'], code2: 'MT', code3: 'MLT', flag: '🇲🇹'},
  {names: ['Marshall Islands'], code2: 'MH', code3: 'MHL', flag: '🇲🇭'},
  {names: ['Martinique'], code2: 'MQ', code3: 'MTQ', flag: '🇲🇶'},
  {names: ['Mauritania'], code2: 'MR', code3: 'MRT', flag: '🇲🇷'},
  {names: ['Mauritius'], code2: 'MU', code3: 'MUS', flag: '🇲🇺'},
  {names: ['Mayotte'], code2: 'YT', code3: 'MYT', flag: '🇾🇹'},
  {names: ['Mexico'], code2: 'MX', code3: 'MEX', flag: '🇲🇽'},
  {
    names: ['Micronesia', 'Federated States of Micronesia'],
    code2: 'FM',
    code3: 'FSM',
    flag: '🇫🇲',
  },
  {
    names: ['Moldova', 'Republic of Moldova'],
    code2: 'MD',
    code3: 'MDA',
    flag: '🇲🇩',
  },
  {names: ['Monaco'], code2: 'MC', code3: 'MCO', flag: '🇲🇨'},
  {names: ['Mongolia'], code2: 'MN', code3: 'MNG', flag: '🇲🇳'},
  {names: ['Montenegro'], code2: 'ME', code3: 'MNE', flag: '🇲🇪'},
  {names: ['Montserrat'], code2: 'MS', code3: 'MSR', flag: '🇲🇸'},
  {names: ['Morocco'], code2: 'MA', code3: 'MAR', flag: '🇲🇦'},
  {names: ['Mozambique'], code2: 'MZ', code3: 'MOZ', flag: '🇲🇿'},
  {names: ['Myanmar'], code2: 'MM', code3: 'MMR', flag: '🇲🇲'},
  {names: ['Namibia'], code2: 'NA', code3: 'NAM', flag: '🇳🇦'},
  {names: ['Nauru'], code2: 'NR', code3: 'NRU', flag: '🇳🇷'},
  {names: ['Nepal'], code2: 'NP', code3: 'NPL', flag: '🇳🇵'},
  {names: ['Netherlands'], code2: 'NL', code3: 'NLD', flag: '🇳🇱'},
  {names: ['New Caledonia'], code2: 'NC', code3: 'NCL', flag: '🇳🇨'},
  {names: ['New Zealand'], code2: 'NZ', code3: 'NZL', flag: '🇳🇿'},
  {names: ['Nicaragua'], code2: 'NI', code3: 'NIC', flag: '🇳🇮'},
  {names: ['Niger'], code2: 'NE', code3: 'NER', flag: '🇳🇪'},
  {names: ['Nigeria'], code2: 'NG', code3: 'NGA', flag: '🇳🇬'},
  {names: ['Niue'], code2: 'NU', code3: 'NIU', flag: '🇳🇺'},
  {names: ['Norfolk Island'], code2: 'NF', code3: 'NFK', flag: '🇳🇫'},
  {names: ['North Macedonia'], code2: 'MK', code3: 'MKD', flag: '🇲🇰'},
  {names: ['Northern Mariana Islands'], code2: 'MP', code3: 'MNP', flag: '🇲🇵'},
  {names: ['Norway'], code2: 'NO', code3: 'NOR', flag: '🇳🇴'},
  {names: ['Oman'], code2: 'OM', code3: 'OMN', flag: '🇴🇲'},
  {names: ['Pakistan'], code2: 'PK', code3: 'PAK', flag: '🇵🇰'},
  {names: ['Palau'], code2: 'PW', code3: 'PLW', flag: '🇵🇼'},
  {
    names: ['Palestine', 'State of Palestine', 'Palestinian Territories'],
    code2: 'PS',
    code3: 'PSE',
    flag: '🇵🇸',
  },
  {names: ['Panama'], code2: 'PA', code3: 'PAN', flag: '🇵🇦'},
  {names: ['Papua New Guinea'], code2: 'PG', code3: 'PNG', flag: '🇵🇬'},
  {names: ['Paraguay'], code2: 'PY', code3: 'PRY', flag: '🇵🇾'},
  {names: ['Peru'], code2: 'PE', code3: 'PER', flag: '🇵🇪'},
  {names: ['Philippines'], code2: 'PH', code3: 'PHL', flag: '🇵🇭'},
  {
    names: ['Pitcairn', 'Pitcairn Islands'],
    code2: 'PN',
    code3: 'PCN',
    flag: '🇵🇳',
  },
  {names: ['Poland'], code2: 'PL', code3: 'POL', flag: '🇵🇱'},
  {names: ['Portugal'], code2: 'PT', code3: 'PRT', flag: '🇵🇹'},
  {names: ['Puerto Rico'], code2: 'PR', code3: 'PRI', flag: '🇵🇷'},
  {names: ['Qatar'], code2: 'QA', code3: 'QAT', flag: '🇶🇦'},
  {names: ['Réunion', 'Reunion'], code2: 'RE', code3: 'REU', flag: '🇷🇪'},
  {names: ['Romania'], code2: 'RO', code3: 'ROU', flag: '🇷🇴'},
  {
    names: ['Russia', 'Russian Federation'],
    code2: 'RU',
    code3: 'RUS',
    flag: '🇷🇺',
  },
  {names: ['Rwanda'], code2: 'RW', code3: 'RWA', flag: '🇷🇼'},
  {
    names: ['Saint Barthelemy', 'Saint Barthélemy'],
    code2: 'BL',
    code3: 'BLM',
    flag: '🇧🇱',
  },
  {
    names: ['Saint Helena, Ascension and Tristan da Cunha'],
    code2: 'SH',
    code3: 'SHN',
    flag: '🇹🇦',
  },
  {names: ['Saint Kitts and Nevis'], code2: 'KN', code3: 'KNA', flag: '🇰🇳'},
  {names: ['Saint Lucia'], code2: 'LC', code3: 'LCA', flag: '🇱🇨'},
  {
    names: ['Saint Martin (French part)'],
    code2: 'MF',
    code3: 'MAF',
    flag: '🇲🇫',
  },
  {names: ['Saint Pierre and Miquelon'], code2: 'PM', code3: 'SPM', flag: '🇵🇲'},
  {
    names: ['Saint Vincent and the Grenadines'],
    code2: 'VC',
    code3: 'VCT',
    flag: '🇻🇨',
  },
  {names: ['Samoa'], code2: 'WS', code3: 'WSM', flag: '🇼🇸'},
  {names: ['San Marino'], code2: 'SM', code3: 'SMR', flag: '🇸🇲'},
  {
    names: ['Sao Tome and Principe', 'São Tomé & Príncipe'],
    code2: 'ST',
    code3: 'STP',
    flag: '🇸🇹',
  },
  {names: ['Saudi Arabia'], code2: 'SA', code3: 'SAU', flag: '🇸🇦'},
  {names: ['Senegal'], code2: 'SN', code3: 'SEN', flag: '🇸🇳'},
  {names: ['Serbia'], code2: 'RS', code3: 'SRB', flag: '🇷🇸'},
  {names: ['Seychelles'], code2: 'SC', code3: 'SYC', flag: '🇸🇨'},
  {names: ['Sierra Leone'], code2: 'SL', code3: 'SLE', flag: '🇸🇱'},
  {names: ['Singapore'], code2: 'SG', code3: 'SGP', flag: '🇸🇬'},
  {names: ['Sint Maarten (Dutch part)'], code2: 'SX', code3: 'SXM', flag: '🇸🇽'},
  {names: ['Slovakia'], code2: 'SK', code3: 'SVK', flag: '🇸🇰'},
  {names: ['Slovenia'], code2: 'SI', code3: 'SVN', flag: '🇸🇮'},
  {names: ['Solomon Islands'], code2: 'SB', code3: 'SLB', flag: '🇸🇧'},
  {names: ['Somalia'], code2: 'SO', code3: 'SOM', flag: '🇸🇴'},
  {names: ['South Africa'], code2: 'ZA', code3: 'ZAF', flag: '🇿🇦'},
  {
    names: ['South Georgia and the South Sandwich Islands'],
    code2: 'GS',
    code3: 'SGS',
    flag: '🇬🇸',
  },
  {names: ['South Sudan'], code2: 'SS', code3: 'SSD', flag: '🇸🇸'},
  {names: ['Spain'], code2: 'ES', code3: 'ESP', flag: '🇪🇸'},
  {names: ['Sri Lanka'], code2: 'LK', code3: 'LKA', flag: '🇱🇰'},
  {names: ['Sudan'], code2: 'SD', code3: 'SDN', flag: '🇸🇩'},
  {names: ['Suriname'], code2: 'SR', code3: 'SUR', flag: '🇸🇷'},
  {names: ['Svalbard and Jan Mayen'], code2: 'SJ', code3: 'SJM', flag: '🇸🇯'},
  {names: ['Sweden'], code2: 'SE', code3: 'SWE', flag: '🇸🇪'},
  {names: ['Switzerland'], code2: 'CH', code3: 'CHE', flag: '🇨🇭'},
  {
    names: ['Syria', 'Syrian Arab Republic'],
    code2: 'SY',
    code3: 'SYR',
    flag: '🇸🇾',
  },
  {names: ['Tajikistan'], code2: 'TJ', code3: 'TJK', flag: '🇹🇯'},
  {
    names: ['Tanzania', 'United Republic of Tanzania'],
    code2: 'TZ',
    code3: 'TZA',
    flag: '🇹🇿',
  },
  {names: ['Thailand'], code2: 'TH', code3: 'THA', flag: '🇹🇭'},
  {names: ['Timor-Leste'], code2: 'TL', code3: 'TLS', flag: '🇹🇱'},
  {names: ['Togo'], code2: 'TG', code3: 'TGO', flag: '🇹🇬'},
  {names: ['Tokelau'], code2: 'TK', code3: 'TKL', flag: '🇹🇰'},
  {names: ['Tonga'], code2: 'TO', code3: 'TON', flag: '🇹🇴'},
  {names: ['Trinidad and Tobago'], code2: 'TT', code3: 'TTO', flag: '🇹🇹'},
  {names: ['Tunisia'], code2: 'TN', code3: 'TUN', flag: '🇹🇳'},
  {names: ['Turkey'], code2: 'TR', code3: 'TUR', flag: '🇹🇷'},
  {names: ['Turkmenistan'], code2: 'TM', code3: 'TKM', flag: '🇹🇲'},
  {names: ['Turks and Caicos Islands'], code2: 'TC', code3: 'TCA', flag: '🇹🇨'},
  {names: ['Tuvalu'], code2: 'TV', code3: 'TUV', flag: '🇹🇻'},
  {names: ['Uganda'], code2: 'UG', code3: 'UGA', flag: '🇺🇬'},
  {names: ['Ukraine'], code2: 'UA', code3: 'UKR', flag: '🇺🇦'},
  {
    names: ['United Arab Emirates', 'UAE'],
    code2: 'AE',
    code3: 'ARE',
    flag: '🇦🇪',
  },
  {
    names: ['UK', 'United Kingdom of Great Britain and Northern Ireland'],
    code2: 'GB',
    code3: 'GBR',
    flag: '🇬🇧',
  },
  {
    names: ['United States Minor Outlying Islands'],
    code2: 'UM',
    code3: 'UMI',
    flag: '🇺🇲',
  },
  {names: ['Uruguay'], code2: 'UY', code3: 'URY', flag: '🇺🇾'},
  {names: ['Uzbekistan'], code2: 'UZ', code3: 'UZB', flag: '🇺🇿'},
  {names: ['Vanuatu'], code2: 'VU', code3: 'VUT', flag: '🇻🇺'},
  {
    names: ['Venezuela', 'Bolivarian Republic of Venezuela'],
    code2: 'VE',
    code3: 'VEN',
    flag: '🇻🇪',
  },
  {names: ['Viet Nam', 'Vietnam'], code2: 'VN', code3: 'VNM', flag: '🇻🇳'},
  {names: ['Virgin Islands (British)'], code2: 'VG', code3: 'VGB', flag: '🇻🇬'},
  {names: ['Virgin Islands (U.S.)'], code2: 'VI', code3: 'VIR', flag: '🇻🇮'},
  {names: ['Wallis and Futuna'], code2: 'WF', code3: 'WLF', flag: '🇼🇫'},
  {names: ['Western Sahara'], code2: 'EH', code3: 'ESH', flag: '🇪🇭'},
  {names: ['Yemen'], code2: 'YE', code3: 'YEM', flag: '🇾🇪'},
  {names: ['Zambia'], code2: 'ZM', code3: 'ZMB', flag: '🇿🇲'},
  {names: ['Zimbabwe'], code2: 'ZW', code3: 'ZWE', flag: '🇿🇼'},
]

function tokenize(str) {
  // create an array of tokens: 'united', 'states', 'america'
  return (
    str
      // remove unimportant characters
      .replace(/[-().'&,]/g, '')
      .toLowerCase()
      .split(' ')
      // filter out blank strings and unimportant words
      .filter((part) => part && !['of', 'and', 'the'].includes(part))
  )
}

Countries.forEach((country) => {
  // create a token list for each country for future searches
  country.tokens = Object.keys(
    country.names.reduce((prev, name) => {
      // tokenize all the names listed
      const parts = tokenize(name)

      // remove duplicates
      parts.forEach((part) => (prev[part] = true))

      return prev
    }, {}),
  )

  // create a function to call for each country that returns a match count
  country.lookup = (str, strTokens) => {
    // if exact match then set match to highest possible to "win"
    if (str === country.names[0].toLowerCase()) {
      return Infinity
    }

    // find all tokens that match for this country
    const matches = country.tokens.filter((token) => strTokens.includes(token))

    // return count
    return matches.length
  }
})

export function getCode2FromMatch(str = '') {
  // enforce string type
  str = `${str === null ? '' : str}`

  // can't match with nothing or 1 character
  if (str.length < 2) {
    return null
  }

  // do a direct code2 lookup if 2 characters to validate country code
  if (str.length === 2) {
    str = str.toUpperCase()
    return Countries.find(({code2}) => code2 === str) ? str : null
  }

  // do a direct code3 lookup if 3 characters and return the country code
  if (str.length === 3) {
    str = str.toUpperCase()
    const country = Countries.find(({code3}) => code3 === str)

    return country ? country.code2 : null
  }

  // search through each country and look for the highest match count
  let winningMatchCount = -Infinity
  let winningIndex = -1
  // tokenize the search string
  str = str.toLowerCase()
  const strTokens = tokenize(str)

  Countries.forEach(({lookup}, index) => {
    const matchCount = lookup(str, strTokens)

    // first hightest match count wins
    if (matchCount > winningMatchCount) {
      winningMatchCount = matchCount
      winningIndex = index
    }
  })

  // get winning country (must have matched something and exist)
  const country = winningMatchCount > 0 && Countries[winningIndex]

  // return code2
  return country ? country.code2 : null
}
