import PropTypes from 'prop-types'
import flatten from 'lodash/flatten.js'
import head from 'lodash/head.js'
import uniq from 'lodash/uniq.js'

import {getState, updateForm, formsSelector} from '../../../../store.js'
import apiverson from '../../../../common/apiverson.js'
import {updateOrders} from '../../../../data/orders.js'
import {
  resetShipFrom,
  labelInfoIDsForOrdersAndLabelTypesSelector,
} from '../../../../data/labelInfos/index.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import {refreshOrderListAndCounts} from '../../orderListActions.js'

export const PROCESS_DROPSHIP_FORM = 'PROCESS_DROPSHIP_FORM'

export const ProcessDropshipFormShape = PropTypes.shape({
  isProcessing: PropTypes.bool.isRequired,
  showProcessingModal: PropTypes.bool.isRequired,
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  completedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired,
    }),
  ).isRequired,
})

export function setupProcessDropshipForm({orderNumbers}) {
  return {
    formName: PROCESS_DROPSHIP_FORM,
    initialForm: {
      isProcessing: false,
      showProcessingModal: false,
      orderNumbers,
      completedOrderNumbers: [],
      errors: [],
    },
  }
}

export function updateProcessDropshipFrom(updates) {
  updateForm(PROCESS_DROPSHIP_FORM, updates)
}

export function processDropshipFormSelector(state) {
  return formsSelector(state)[PROCESS_DROPSHIP_FORM]
}

function markOrderComplete(orderNumber) {
  const form = processDropshipFormSelector(getState())

  if (!form) {
    return
  }

  updateProcessDropshipFrom({
    completedOrderNumbers: uniq([...form.completedOrderNumbers, orderNumber]),
  })
}

function recordError(orderNumber, errorMessage) {
  const form = processDropshipFormSelector(getState())

  if (!form) {
    return
  }

  updateProcessDropshipFrom({
    errors: [...form.errors, {orderNumber, errorMessage}],
  })
}

function errorsSelector(state) {
  const form = processDropshipFormSelector(state)

  return form ? form.errors : []
}

export async function processDropshipmentForOrderNumber(orderNumber) {
  try {
    const {json} = await apiverson.post(
      `/order/${encodeURIComponent(orderNumber)}/process_dropshipments`,
    )

    return json
  } catch (err) {
    recordError(orderNumber, err.message)

    return []
  } finally {
    markOrderComplete(orderNumber)
  }
}

export async function processDropshipments(orderNumbers) {
  updateProcessDropshipFrom({
    isProcessing: true,
    errors: [],
    completedOrderNumbers: [],
    showProcessingModal: true,
  })

  try {
    const updatedOrderNumbers = flatten(
      await Promise.all(
        orderNumbers.map((orderNumber) =>
          processDropshipmentForOrderNumber(orderNumber),
        ),
      ),
    )

    // If we are on the order detail page and the order gets split,
    // then we need to redirect to one of the new orders.
    if (
      head(orderNumbers) &&
      window.location.hash ===
        `#/order/${encodeURIComponent(head(orderNumbers))}` &&
      !updatedOrderNumbers.includes(head(orderNumbers))
    ) {
      window.location.hash = `#/order/${encodeURIComponent(
        head(updatedOrderNumbers),
      )}`
    }

    const errors = errorsSelector(getState())
    const erroredOrderNumbers = errors.map(({orderNumber}) => orderNumber)

    const affectedOrderNumbers = uniq([
      ...orderNumbers,
      ...updatedOrderNumbers,
    ]).filter((orderNumber) => !erroredOrderNumbers.includes(orderNumber))

    if (affectedOrderNumbers.length > 0) {
      await updateOrders(affectedOrderNumbers)

      const labelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(
        getState(),
        {
          orderNumbers: affectedOrderNumbers,
          labelTypes: ['shipping', 'return'],
        },
      )

      await resetShipFrom(labelInfoIDs)
    }

    if (erroredOrderNumbers.length === 0) {
      doneProcessingDropshipments()
    }

    updateProcessDropshipFrom({isProcessing: false})
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while processing dropshipments.',
        details: err.error_message || err.message,
      },
      err,
    )

    updateProcessDropshipFrom({isProcessing: false})
  }
}

export async function doneProcessingDropshipments() {
  updateProcessDropshipFrom({showProcessingModal: false})

  await refreshOrderListAndCounts()
}
