import PropTypes from 'prop-types'
import {useCallback, useEffect, useRef} from 'react'
import debounce from 'lodash/debounce.js'
import classNames from 'classnames'

import api from '../../common/api.js'
import {getState, updateForm, formsSelector, useAutoForm} from '../../store.js'
import TextInput from './TextInput.js'
import Zropdown, {useZropdown} from './Zropdown.js'
import {AddressShape} from '../PropTypes.js'
import {PRIVACY_MASK} from '../analytics/index.js'

export function getInitialForm({value}) {
  return {
    text: value || '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    isLoading: false,
    serverError: null,
  }
}

export async function searchAddress(dropdown, queryParams = {}) {
  const taskQueue = searchAddress.taskQueue || {}
  searchAddress.taskQueue = taskQueue

  taskQueue[dropdown] = {}

  try {
    const form = formsSelector(getState())[dropdown]

    if (!form) {
      return
    }

    const text = form.text.trim()

    if (text.length < 3) {
      updateForm(dropdown, {
        results: [],
        selectedIndex: 0,
        hasFoundNothing: false,
        isLoading: false,
        serverError: null,
      })

      return
    }

    updateForm(dropdown, {isLoading: true, serverError: null})

    const {
      json: {address: results},
    } = await api.get('/address/search/', {
      q: form.text,
      ...queryParams,
    })

    if (searchAddress.taskQueue[dropdown] !== taskQueue[dropdown]) {
      return
    }

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
      isLoading: false,
    })
  } catch (err) {
    updateForm(dropdown, {
      results: [],
      selectedIndex: 0,
      serverError: err.message,
      isLoading: false,
    })
  } finally {
    if (searchAddress.taskQueue[dropdown] === taskQueue[dropdown]) {
      delete taskQueue[dropdown]
    }
  }
}

function Row({
  address: {name, company, street1, street2, city, state, country},
  isSelected,
  onSelect,
}) {
  const line1 = []

  if (name) {
    line1.push(name)
  }

  if (company) {
    line1.push(company)
  }

  const line2 = []

  if (street1) {
    line2.push(street1)
  }

  if (street2) {
    line2.push(street2)
  }

  if (city) {
    line2.push(city)
  }

  if (state) {
    line2.push(state)
  }

  if (country) {
    line2.push(country)
  }

  return (
    <li className={`list__item list__item--dropdown ${PRIVACY_MASK}`}>
      <button
        className={classNames('btn--link list__link--dropdown', {
          selected: isSelected,
        })}
        type="button"
        onClick={onSelect}
      >
        <div>
          <span>{line1.join(', ')}</span>
        </div>
        <div className="unbold">
          <small>{line2.join(', ')}</small>
        </div>
      </button>
    </li>
  )
}

Row.propTypes = {
  address: AddressShape.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default function AddressFilter({
  label,
  placeholder,
  dropdown,
  onSelect,
  queryParams,
  autoFocus,
  onBlur,
  value,
  onChange,
  disabled,
  required,
}) {
  const id = `${dropdown}__input`
  placeholder = placeholder || 'Search for a name'

  const debouncedSearchAddress = useCallback(
    debounce(() => searchAddress(dropdown, queryParams), 400),
    [dropdown, queryParams],
  )

  const ref = useRef()

  const form = useAutoForm(dropdown, getInitialForm({value}))

  useEffect(() => {
    if (value !== form.text) {
      updateForm(dropdown, {text: value})
    }
  }, [value, form.text])

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onChange: (value) => {
        debouncedSearchAddress()

        onChange(value)
      },
      onSelect: (address) => {
        updateForm(dropdown, {text: address.name, results: []})

        onSelect(address)
      },
    })

  return (
    <div className="outerwrap--address-search-zropdown">
      {label && (
        <label className="label--address-search" htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      )}
      <div className="wrap--address-search" ref={ref}>
        <label className="label--input-prefix label--search" htmlFor={id}>
          <TextInput
            className="input--search input--lighter inline-block v-align-middle"
            id={id}
            value={form.text}
            placeholder={placeholder}
            onChange={(value) => onChangeInternal(value)}
            onBlur={(event) => onBlur && onBlur(event)}
            autoFocus={autoFocus}
            disabled={disabled}
            autoComplete="new-password"
          />
        </label>
      </div>
      <Zropdown
        parentRef={ref}
        dropdown={dropdown}
        form={form}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
      >
        {form.isLoading && (
          <li className="list__item--dropdown list__item--dropdown-empty align-center">
            <span className="spinner--sm v-align-middle" />
            <strong className="inline-block v-align-middle fs-00 op-75 margin-left-5">
              Looking up names...
            </strong>
          </li>
        )}
        {form.results.map((address, index) => (
          <Row
            key={address.id}
            address={address}
            isSelected={index === form.selectedIndex}
            onSelect={() => onSelectInternal(address)}
          />
        ))}
        {form.hasFoundNothing && (
          <li className="list__item--dropdown list__item--dropdown-empty align-center">
            <em>
              Shucks. We didn’t find any names on file that matched, but feel
              free to enter a new one.
            </em>
          </li>
        )}
      </Zropdown>
    </div>
  )
}

AddressFilter.propTypes = {
  label: PropTypes.node,
  placeholder: PropTypes.string,
  dropdown: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  form: PropTypes.shape({
    text: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedIndex: PropTypes.number.isRequired,
    hasFoundNothing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}
