export const NEW_RULE_ID = 'NEW_RULE_ID'

export const ALLOCATE_ORDER = 'allocate_order'
export const APPLY_TAGS = 'apply_tags'
export const APPLY_PRESETS = 'apply_presets'
export const ASSIGN_TO_WAREHOUSE = 'assign_to_warehouse'
export const MARK_AS_SHIPPED = 'mark_as_shipped'
export const DROPSHIP_TO = 'dropship'

export const CONDITIONS_JOINED_WITH_ALL = 'and'
export const CONDITIONS_JOINED_WITH_ANY = 'or'

export const SHIPPING_PRESET = 'shipment_preset'
export const RETURN_PRESET = 'return_preset'
export const SHIPPING_AND_RETURN = 'SHIPPING_AND_RETURN'

export const ACTION_DEFAULTS = {
  [ALLOCATE_ORDER]: {type: ALLOCATE_ORDER, data: {}},
  [APPLY_TAGS]: {type: APPLY_TAGS, data: {tag_ids: []}},
  [APPLY_PRESETS]: {
    type: APPLY_PRESETS,
    data: {preset_ids: [], preset_type: SHIPPING_PRESET},
  },
  [ASSIGN_TO_WAREHOUSE]: {
    type: ASSIGN_TO_WAREHOUSE,
    data: {warehouse_id: null},
  },
  [MARK_AS_SHIPPED]: {type: MARK_AS_SHIPPED, data: {}},
  [DROPSHIP_TO]: {
    type: DROPSHIP_TO,
    data: {supplier_id: null},
  },
}
