import PropTypes from 'prop-types'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import NewRule from '../settings/Rules/NewRule.js'
import {
  ruleCanBeSavedSelector,
  settingsSaveRule,
} from '../settings/Rules/rulesFunctions.js'
import {NEW_RULE_ID} from '../../common/constants/Rules.js'

const MODAL_FORM = 'CREATE_RULE_MODAL'

export function showCreateRuleModal(initialRule) {
  setForm(MODAL_FORM, {
    initialRule,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function createRule() {
  try {
    updateModalForm({serverError: null, isSaving: true})

    await settingsSaveRule(NEW_RULE_ID)

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function CreateRuleModal({form}) {
  const ruleCanBeSaved = useSelector((state) =>
    ruleCanBeSavedSelector(state, {ruleID: NEW_RULE_ID}),
  )

  return (
    <ConfirmModal
      title="Create a Rule"
      modalSize="lg"
      onConfirm={() => createRule()}
      onCancel={() => closeModal()}
      confirmText="Save Rule"
      cancelText="Cancel"
      isDisabled={!ruleCanBeSaved}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <NewRule hideSaveAndCancel initialRule={form.initialRule} />
    </ConfirmModal>
  )
}

CreateRuleModal.propTypes = {
  form: PropTypes.shape({
    initialRule: PropTypes.object,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(CreateRuleModal, modalFormSelector)
