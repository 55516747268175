import PropTypes from 'prop-types'

import {activeImportProductTasksSelector} from '../../redux/selectors/data/tasks.js'
import {isFilteredSelector} from './productListFunctions.js'
import {useSelector} from '../../store.js'
import {showCreateProductModal} from './Modals/CreateProductModal.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showEditCartModal} from '../settings/Modals/EditCartModal.js'
import {hasNoProductsSelector} from '../../data/products.js'
import {productsHaveLoadedSelector} from '../../redux/selectors/data/hasLoaded.js'

export default function EmptyList({colSpan}) {
  const isProductSyncRunning =
    useSelector(activeImportProductTasksSelector).length > 0
  const isFiltered = useSelector(isFilteredSelector)
  const productsHaveLoaded = useSelector(productsHaveLoadedSelector)
  const hasNoProducts = useSelector(hasNoProductsSelector)

  if (!productsHaveLoaded) {
    return null
  }

  return (
    <tr className="table__tr">
      <td className="table__td" colSpan={colSpan}>
        {hasNoProducts ? (
          <div className="wrap--row margin-bottom-40">
            <div className="medium-12 medium-centered columns">
              <dl className="list--empty-content">
                <dt className="fs-03 margin-bottom-10 margin-auto">
                  There are no products in your account.
                </dt>
                <dd className="fs-02 lh-md margin-bottom-0">
                  Here are a few ways you can add some:
                </dd>
              </dl>
              <div className="wrap--row flex">
                <div className="medium-5 columns medium-offset-1 flex">
                  <dl className="list list--app-resource list--app-resource-simplest margin-bottom-30">
                    <dt className="list__title fs-01 lh-md margin-bottom-10">
                      Simple Ways to Add Products
                    </dt>
                    <dd className="list__item fs-00 lh-md margin-bottom-20">
                      No need to worry if you don’t have an online store or if
                      it doesn’t sync with Ordoro.
                    </dd>
                    <dd className="list__item margin-bottom-15 flex">
                      <a
                        className="btn btn--primary btn--sm btn--primary-alt margin-right-7"
                        href="#/dataimport"
                      >
                        Use a Spreadsheet
                      </a>
                      <div className="flex--justify-col">
                        <a
                          className="btn btn--link no-underline lighter"
                          href="https://support.ordoro.com/data-import/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Learn More"
                        >
                          <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                        </a>
                      </div>
                    </dd>
                    <dd className="list__item margin-bottom-0">
                      <ButtonPrimary
                        size="sm"
                        className="ws-normal"
                        isOutlined
                        onClick={() => showCreateProductModal()}
                      >
                        Create a Product Manually
                      </ButtonPrimary>
                    </dd>
                  </dl>
                </div>

                <div className="medium-5 columns end flex">
                  <dl className="list list--app-resource w-100 list--app-resource-best margin-bottom-30">
                    <dt className="list__title fs-01 lh-md margin-bottom-10">
                      Best Way to Get Started
                    </dt>
                    <dd className="list__item fs-00 lh-md margin-bottom-10">
                      Connect your sales channel for automatic product and order
                      import.
                    </dd>
                    <dd className="list__item fs-00 lh-md margin-bottom-20">
                      <em>
                        Don’t worry — we won’t change any data inside your
                        ecommerce store.
                      </em>
                    </dd>
                    <dd className="list__item margin-bottom-0 flex">
                      <ButtonPrimary
                        size="sm"
                        className="ws-normal margin-right-7"
                        onClick={() => showEditCartModal()}
                      >
                        Connect Your Store
                      </ButtonPrimary>
                      <div className="flex--justify-col">
                        <a
                          className="btn btn--link no-underline lighter"
                          href="https://support.ordoro.com/getting-started-with-ordoro/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Learn More"
                        >
                          <span className="i-help fs-01 lh-sm i--lh-sm-md" />
                        </a>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-65 margin-auto padding-top-25 padding-bottom-25 align-center">
            {isProductSyncRunning && (
              <div className="loading align-center margin-bottom-10">
                <span className="panel-processing animate-spin v-align-middle" />
              </div>
            )}
            <strong className="text--md-grey fs-02 lh-md">
              {isFiltered
                ? 'No products match the current set of filters.'
                : isProductSyncRunning
                  ? 'We’re currently importing your products. Please be patient as this might take awhile.'
                  : 'There are no products. Try importing your products again.'}
            </strong>
          </div>
        )}
      </td>
    </tr>
  )
}

EmptyList.propTypes = {
  colSpan: PropTypes.string,
}
