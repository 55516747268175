import {takeEvery, put, call} from 'redux-saga/effects'

import requests from '../../../common/requests.js'
import {logError} from '../../../common/error.js'
import {GET_SERVER_INFO, setServerInfo} from '../../actions/data/serverInfo.js'

export function* getServerInfoWorker() {
  try {
    const serverInfo = yield call(requests.get, '/info.json')

    yield put(setServerInfo(serverInfo))
  } catch (err) {
    logError(err, 'Error getting server info')
  }
}

export default function* serverInfoSaga() {
  yield takeEvery(GET_SERVER_INFO, getServerInfoWorker)
}
