// import get from 'lodash/get.js'
// import omit from 'lodash/omit.js'

// import {getSticky, setSticky} from '../common/stickyStore'

// This is where we can migrate sticky values
// Currently, we are migrating things from the old abode kit view params to the common KIT_VIEW_*
//   We can keep this around until the end of Sept 2020 at the latest
export default function migrateStickies(/* store */) {
  if (window.THIS_IS_A_TEST_ENV) {
    return
  }

  // switchShowToMode(store, PRINT_PICK_PACK_MODAL_FORM)
}

// Keep as an example
// function switchShowToMode(store, FORM) {
//   const showPackingListKitComponents = get(
//     getSticky(FORM),
//     'showPackingListKitComponents',
//   )
//
//   if (showPackingListKitComponents !== undefined) {
//     store.dispatch(
//       setPackingListKitViewMode(
//         FORM,
//         showPackingListKitComponents ? KIT_VIEW_BOTH : KIT_VIEW_PARENTS,
//       ),
//     )
//
//     const formUpdates = getSticky(FORM)
//
//     setSticky(FORM, omit(formUpdates, 'showPackingListKitComponents'))
//   }
// }
