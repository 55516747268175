import addBusinessDays from 'date-fns/addBusinessDays'
import {useEffect} from 'react'

import {DHL, SENDLE} from '../../../../../common/constants/ShipperNames.js'
import {
  labelConfigSelector,
  updateLabelConfig,
} from '../../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../../store.js'
import ForwardDate, {
  PICKUP_DATE_VALID_SHIPPER_TYPES,
} from '../../../Fields/ForwardDate.js'
import {useLabelConfigContext} from '../../../LabelConfigContext.js'

export default function BulkPickupDate() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const pickupDateStr = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID}).pickup_date,
  )
  const show = [SENDLE, DHL].includes(shipperType)
  const nextBusinessDay = shipperType === SENDLE

  useEffect(() => {
    if (!show || pickupDateStr) {
      return
    }
    const date = nextBusinessDay ? addBusinessDays(new Date(), 1) : new Date()

    updateLabelConfig(labelInfoID, {pickup_date: date.toISOString()})
  }, [show, pickupDateStr])

  if (!show) {
    return null
  }

  return (
    <ForwardDate
      label="Pickup Date"
      labelProperty="pickup_date"
      nextBusinessDay={nextBusinessDay}
      validShipperTypes={PICKUP_DATE_VALID_SHIPPER_TYPES}
    />
  )
}
