import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'
import {ReturnOrderCodeShape} from '../../../common/PropTypes.js'
import {returnOrderCodeSelector} from '../../../redux/selectors/data/returnOrderCodes.js'

import ReturnOrderCodeForm from './ReturnOrderCodeForm.js'

function CodeListItem({returnOrderCodeID, isExpanded, returnOrderCode}) {
  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
      })}
    >
      {returnOrderCode ? (
        <div className="wrap--row">
          <div className="medium-5 columns">
            <strong className="fs-01 margin-right-5 v-align-middle">
              {returnOrderCode.code}
            </strong>
          </div>
          <div className="medium-5 columns">
            <span className="fs-01 v-align-middle text--lt-grey">
              {returnOrderCode.description}
            </span>
          </div>
          <div className="medium-2 columns align-right">
            {returnOrderCode.archive_date ? (
              <em className="fs-01 v-align-middle text--lt-grey ">Archived</em>
            ) : (
              <span className="fs-01  v-align-middle">
                <a
                  className="inline-text-button settings-list-item-button edit-url"
                  href={`#/settings/return_order_codes/${returnOrderCode.id}`}
                >
                  <span
                    className="icon icon-edit icon-block x-sm margin-right-2"
                    aria-hidden="true"
                  />
                  <span className="button-text">Edit</span>
                </a>
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="medium-12 columns button-wrap">
          <p>
            <a
              href={`#/settings/return_order_codes/${NEW_ID}`}
              title="Add a return code"
              className="inline-text-button no-underline add-sales-channel-url"
            >
              <span
                className="icon icon-add icon-block x-sm margin-right-5"
                aria-hidden="true"
              />
              <span>Add a return code</span>
            </a>
          </p>
        </div>
      )}
      {isExpanded && (
        <ReturnOrderCodeForm returnOrderCodeID={returnOrderCodeID} />
      )}
    </li>
  )
}

CodeListItem.propTypes = {
  returnOrderCodeID: PropTypes.number,
  isExpanded: PropTypes.bool.isRequired,
  returnOrderCode: ReturnOrderCodeShape,
}

function mapStateToProps(state, {returnOrderCodeID}) {
  return {
    returnOrderCode: returnOrderCodeID
      ? returnOrderCodeSelector(state, {returnOrderCodeID})
      : null,
  }
}

export default connect(mapStateToProps)(CodeListItem)
