import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {
  SET_ORDERS,
  SET_IS_SAVING,
} from '../../../actions/ui/modals/confirmAttemptToAllocateModal.js'

function orders(
  state = INITIAL_STATE.ui.modals.confirmAttemptToAllocateModal.orders,
  action = {},
) {
  if (action.type === SET_ORDERS) {
    return action.payload
  }

  return state
}

function isSaving(
  state = INITIAL_STATE.ui.modals.confirmAttemptToAllocateModal.isSaving,
  action = {},
) {
  if (action.type === SET_IS_SAVING) {
    return action.payload
  }

  return state
}

export default combineReducers({
  orders,
  isSaving,
})
