import PropTypes from 'prop-types'
import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueTextDisplay({condition}) {
  const option = getConditionOption(condition)

  return (
    <strong className="rule-criteria">
      <span>{option.getValue(condition)}</span>
    </strong>
  )
}

ConditionValueTextDisplay.propTypes = {
  condition: PropTypes.object.isRequired,
}
