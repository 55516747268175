import PropTypes from 'prop-types'

import Checkbox from '../Checkbox.js'

export default function SelectAllButton(props) {
  return (
    <label
      className="wrap--checkbox-all-products wrap--checkbox-all-items inline-block"
      htmlFor="select_all"
    >
      <Checkbox id="select_all" {...props} />
    </label>
  )
}

SelectAllButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
