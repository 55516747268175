import {createSelector} from 'reselect'
import omitBy from 'lodash/omitBy.js'
import compact from 'lodash/compact.js'
import get from 'lodash/get.js'
import reduce from 'lodash/reduce.js'
import mergeWith from 'lodash/mergeWith.js'
import isArray from 'lodash/isArray.js'

import {
  STANDARD_LAYOUT,
  KIT_VIEW_PARENTS,
  ABODE_FORM_DEFAULTS,
} from '../../../common/constants/Abode.js'
import {PAGE_SIZES} from '../../../common/constants/PageSizes.js'
import {formatV3APIURL} from '../../../common/apiverson.js'
import {isEmptyValue} from '../../../common/utils.js'
import {stringify} from '../../../common/querystring.js'
import {formatAbodeURL} from '../../../common/abode.js'
import {formSelector} from '../ui/forms.js'

import {
  canUseLogosOnLabelsSelector,
  usesInventorySelector,
  useOrdoroProductNameInAbodePackingListsSelector,
  useAbodeEnableEnvelopeSelector,
  useTwoPerPageV2Selector,
  useKittingSelector,
} from '../../../data/company.js'
import {
  allOrdersSelector,
  defaultPackingListIDFromOrderNumbersSelector,
} from '../../../data/orders.js'
import {defaultPackingListIDFromRMAsSelector} from '../data/returnOrders.js'
import {printConfigSelector} from '../data/printConfigs.js'
import {
  BATCH_LABEL_VIEW_ABODE_FORM,
  BATCH_PICK_PACK_ABODE_FORM,
} from '../../actions/ui/labelViewForm/index.js'

export function labelViewFormAbodeSelector(state, {formName}) {
  return formSelector(state, {key: formName}) || {}
}

export const pageSizeSelector = createSelector(
  (state) => state.ui.labelViewForm.pageWidth,
  (state) => state.ui.labelViewForm.pageHeight,
  (pageWidth, pageHeight) => {
    if (
      pageWidth === PAGE_SIZES.thermal.width &&
      pageHeight === PAGE_SIZES.thermal.height
    ) {
      return 'thermal'
    }
    if (
      pageWidth === PAGE_SIZES.desktop.width &&
      pageHeight === PAGE_SIZES.desktop.height
    ) {
      return 'desktop'
    }
    return 'thermal'
  },
)

export function atLeastOneSplitOrderSelector(state, {orderNumbers}) {
  const orders = allOrdersSelector(state)

  return orderNumbers.reduce(
    (prev, orderNumber) =>
      prev || get(orders, [orderNumber, 'parent_order_number'], null) !== null,
    false,
  )
}

export const pickOrPackIsSelectedSelector = createSelector(
  labelViewFormAbodeSelector,
  (abode) => !!(abode.showPickList || abode.showPackingList),
)

export const abodeCustomLayoutParamsSelector = createSelector(
  labelViewFormAbodeSelector,
  (state) => state.ui.labelViewForm,
  (abode, form) => {
    if (!abode.useCustomLayoutSettings) {
      return {}
    }
    return omitBy(
      {
        labelTopMargin: form.pageTopMargin,
        labelLeftMargin: form.pageLeftMargin,
        labelWidth: form.labelWidth,
        labelHeight: form.labelHeight,
      },
      isEmptyValue,
    )
  },
)

export function printConfigLayoutSelector(state, {formName}) {
  const {printConfigID, showFullPage, size} = labelViewFormAbodeSelector(
    state,
    {
      formName,
    },
  )
  const useTwoPerPageV2 =
    useTwoPerPageV2Selector(state) ||
    [BATCH_LABEL_VIEW_ABODE_FORM, BATCH_PICK_PACK_ABODE_FORM].includes(formName)

  const layout = get(
    printConfigSelector(state, {printConfigID}),
    'settings.layout',
  )

  if (
    !layout ||
    printConfigID === STANDARD_LAYOUT ||
    layout === STANDARD_LAYOUT
  ) {
    if (size === 'desktop') {
      return showFullPage
        ? 'fullpage'
        : useTwoPerPageV2
          ? 'twoperpage_v2'
          : 'twoperpage'
    }
    return size
  }

  return layout
}

export function allowedParamsSelector(
  state,
  {formName, docTypes, includeSplitQuantitiesCheckbox},
) {
  const {printConfigID, showPackingList, showPickList} =
    labelViewFormAbodeSelector(state, {formName})
  const printConfigLayout = printConfigLayoutSelector(state, {formName})
  const useKitting = useKittingSelector(state)
  const canUseLogosOnLabels = canUseLogosOnLabelsSelector(state)
  const includeLabel = docTypes.includes('label')
  const includePackingList = docTypes.includes('pack')
  const includePickList = docTypes.includes('pick')

  const standardAllowedParams = {
    printConfigID: true,
    layout: true,
    templateID: true,
    showFullPage: true,
    breakPageOnOrder: true,
    size: true,

    showOrderTray:
      (includePackingList && showPackingList) ||
      (includePickList && showPickList),
    showLogoOnLabel: includeLabel && canUseLogosOnLabels,
    labelPosition: includeLabel && printConfigLayout === 'twoperpage_v2',
    useCustomLayoutSettings: includeLabel,

    showPackingList: includePackingList,
    packingListLayout: includePackingList,
    packingListSort: includePackingList,
    showPrice: includePackingList,
    showBarcode: includePackingList,
    showPackingListImages: includePackingList,
    packingListKitViewMode: includePackingList && useKitting,
    showSiblingOrderQuantities: !!(
      includePackingList && includeSplitQuantitiesCheckbox
    ),
    showZeroQuantityLines: includePackingList,
    highlight_pack_line_qty: includePackingList,
    showShippingInfo: includePackingList,
    showWarehouseLocation: includePackingList,
    showBillTo: includePackingList,
    showPackSerialNumbers: includePackingList,
    showCustomerNotes: includePackingList,
    show_packing_list_details: includePackingList,
    show_pack_sku_barcode: includePackingList,

    showPickList: includePickList,
    pickListPrintLayout: includePickList,
    pickListSort: includePickList,
    showPickListImages: includePickList,
    showPickListTotalItems: includePickList,
    showPickListTotalSKUs: includePickList,
    showPickListTotalOrders: includePickList,
    highlight_pick_line_qty: includePickList,
    show_pick_list_poh: includePickList,
    show_pick_sku_barcode: includePickList,
    showPickSerialNumbers: includePickList,
    pickListKitViewMode: includePickList && useKitting,
  }

  if (printConfigID === STANDARD_LAYOUT) {
    return standardAllowedParams
  }

  const printConfig = printConfigSelector(state, {printConfigID})

  if (!printConfig) {
    return standardAllowedParams
  }

  const allowedParams = printConfig.settings.allowed_params || []

  return reduce(
    standardAllowedParams,
    (prev, isAllowed, param) => {
      prev[param] = isAllowed === false ? false : allowedParams.includes(param)

      return prev
    },
    {},
  )
}

function allowedParamsForOrderNumbersSelector(
  state,
  {formName, orderNumbers, docTypes},
) {
  const includeSplitQuantitiesCheckbox = orderNumbers
    ? atLeastOneSplitOrderSelector(state, {
        orderNumbers,
      })
    : false

  return allowedParamsSelector(state, {
    formName,
    docTypes,
    includeSplitQuantitiesCheckbox,
  })
}

export function defaultsSelector(state, {formName}) {
  const {printConfigID} = labelViewFormAbodeSelector(state, {formName})

  const defaults = {...ABODE_FORM_DEFAULTS}

  if (printConfigID === STANDARD_LAYOUT) {
    return defaults
  }

  const printConfig = printConfigSelector(state, {printConfigID})

  if (!printConfig) {
    return defaults
  }

  return {
    ...defaults,
    ...printConfig.settings.defaults,
  }
}

export const abodeSharedParamsSelector = createSelector(
  labelViewFormAbodeSelector,
  (state, props) => props.utcOffset,
  (state, {templateIDs}) => templateIDs,
  printConfigLayoutSelector,
  (abode, utcOffset, templateIDs, layout) => {
    const {templateID, breakPageOnOrder} = abode

    const params = {
      layout,
      utcOffset,
      breakPageOnOrder,
    }

    // If templateID is not zero then that is the packing_list_id to use for all items (orders)
    // Otherwise if we passed in an array of packing_list_ids then use them. Their count should
    // match the item count
    if (templateID) {
      // If you pass in a single packing list id abode will expand it out to match the item count
      params.template = [templateID]
    } else if (templateIDs) {
      // If all the template IDs are the same then we can just send one template ID
      // abode will expand it out to match the item count
      const firstTemplateID = templateIDs[0]
      const allTheSame = templateIDs.reduce((prev, tID) => {
        if (prev === false) {
          return prev
        }

        if (firstTemplateID !== tID) {
          prev = false
        }

        return prev
      }, true)

      params.template =
        allTheSame && firstTemplateID ? [firstTemplateID] : templateIDs
    }

    return params
  },
)

export const abodeLabelParamsSelector = createSelector(
  (state, {docTypes}) => docTypes,
  labelViewFormAbodeSelector,
  canUseLogosOnLabelsSelector,
  abodeCustomLayoutParamsSelector,
  useAbodeEnableEnvelopeSelector,
  (
    docTypes,
    form,
    canUseLogosOnLabels,
    abodeCustomLayoutParams,
    enableEnvelope,
  ) => {
    if (!docTypes.includes('label')) {
      return {}
    }

    const {showLogoOnLabel, labelPosition} = form

    return {
      showLogoOnLabel: canUseLogosOnLabels && showLogoOnLabel,
      labelLeftAlways: labelPosition === 'left',
      labelRightAlways: labelPosition === 'right',
      enableEnvelope,
      ...abodeCustomLayoutParams,
    }
  },
)

export const abodePickPackParamsSelector = createSelector(
  (state, {docTypes}) => docTypes,
  usesInventorySelector,
  useOrdoroProductNameInAbodePackingListsSelector,
  labelViewFormAbodeSelector,
  defaultsSelector,
  allowedParamsForOrderNumbersSelector,
  (
    docTypes,
    usesInventory,
    showOrdoroProductName,
    form,
    defaults,
    allowedParams,
  ) => {
    if (!docTypes.includes('pack') && !docTypes.includes('pick')) {
      return {}
    }

    const showPackingList = allowedParams.showPackingList
      ? form.showPackingList
      : defaults.showPackingList

    const showPickList = allowedParams.showPickList
      ? form.showPickList
      : defaults.showPickList

    const optionalParams = [
      ...(showPackingList
        ? [
            'showPrice',
            'showBarcode',
            'showPackingListImages',
            'packingListSort',
            'showSiblingOrderQuantities',
            'showZeroQuantityLines',
            'highlight_pack_line_qty',
            'showShippingInfo',
            'showWarehouseLocation',
            'showBillTo',
            'showPackSerialNumbers',
            'showCustomerNotes',
            'packingListLayout',
            'showOrderTray',
            'show_packing_list_details',
            'show_pack_sku_barcode',
          ]
        : []),
      ...(showPickList
        ? [
            'showPickListImages',
            'showPickListTotalItems',
            'showPickListTotalSKUs',
            'showPickListTotalOrders',
            'highlight_pick_line_qty',
            'show_pick_list_poh',
            'showPickSerialNumbers',
            'pickListSort',
            'pickListLayout',
            'showOrderTray',
            'show_pick_sku_barcode',
          ]
        : []),
    ].reduce((prev, param) => {
      const value = allowedParams[param] ? form[param] : defaults[param]

      if (value) {
        prev[param] = value
      }

      return prev
    }, {})

    const packingListKitViewMode = allowedParams.packingListKitViewMode
      ? form.packingListKitViewMode
      : defaults.packingListKitViewMode
    const pickListKitViewMode = allowedParams.pickListKitViewMode
      ? form.pickListKitViewMode
      : defaults.pickListKitViewMode

    return {
      packingListKitViewMode:
        showPackingList && packingListKitViewMode !== KIT_VIEW_PARENTS
          ? packingListKitViewMode
          : undefined,
      pickListKitViewMode:
        showPickList && pickListKitViewMode !== KIT_VIEW_PARENTS
          ? pickListKitViewMode
          : undefined,
      showOrdoroProductName: showOrdoroProductName || undefined,
      docs: compact([
        showPackingList ? 'packing' : undefined,
        showPickList ? 'pick' : undefined,
      ]),
      ...optionalParams,
    }
  },
)

function removeFalseValues(obj) {
  return omitBy(obj, (value) => isEmptyValue(value) || value === false)
}

export const abodeParamsSelector = createSelector(
  (state, {orderNumbers}) => orderNumbers,
  (state, {referenceIDs}) => referenceIDs,
  (state, {docs}) => docs,
  abodeSharedParamsSelector,
  abodeLabelParamsSelector,
  abodePickPackParamsSelector,
  (
    orderNumbers,
    referenceIDs,
    docs,
    abodeSharedParams,
    abodeLabelParams,
    abodePickPackParams,
  ) =>
    removeFalseValues(
      mergeWith(
        {
          ...(orderNumbers ? {order: orderNumbers} : undefined),
          ...(referenceIDs ? {referenceID: referenceIDs} : undefined),
          docs,
        },
        abodeSharedParams,
        abodeLabelParams,
        abodePickPackParams,
        (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue)
          }
        },
      ),
    ),
)

export const abodeLinkSelector = function (
  state,
  {formName, orderNumbers, labelType, utcOffset, docTypes},
) {
  if (!formSelector(state, {key: formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromOrderNumbersSelector(state, {
    orderNumbers,
  })

  const docs = [`${labelType}label`]

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      orderNumbers,
      templateIDs,
      docs,
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/label?${query}`)
}

export const abodePickPackLinkSelector = function (
  state,
  {formName, orderNumbers, utcOffset, docTypes},
) {
  if (!formSelector(state, {key: formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromOrderNumbersSelector(state, {
    orderNumbers,
  })

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      orderNumbers,
      templateIDs,
      docs: [],
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/label?${query}`)
}

export function additionalDocumentsLinkSelector(
  state,
  {orderNumbers, labelType},
) {
  return orderNumbers.length
    ? formatV3APIURL(
        '/label',
        removeFalseValues({
          o: orderNumbers,
          pdf_type: 'additional_docs',
          return: labelType === 'return',
        }),
      )
    : ''
}

export function canadaPostDocumentsLinkSelector(
  state,
  {orderNumbers, labelType},
) {
  return orderNumbers.length
    ? formatV3APIURL(
        '/label',
        removeFalseValues({
          o: orderNumbers,
          pdf_type: 'canada_pdf',
          return: labelType === 'return',
        }),
      )
    : ''
}

export const abodeRMALabelLinkSelector = function (
  state,
  {formName, referenceIDs, utcOffset, docTypes},
) {
  if (!formSelector(state, {key: formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromRMAsSelector(state, {
    referenceIDs,
  })

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      referenceIDs,
      templateIDs,
      docs: [],
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/rma_label?${query}`)
}
