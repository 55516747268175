import classNames from 'classnames'

import {settingsPermissionsSelector} from '../../redux/selectors/ui/settings/index.js'
import {companyNameSelector, companyIDSelector} from '../../data/company.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {emailSelector, logout} from '../../data/me.js'
import {canUseDataImportSelector} from '../../redux/selectors/ui/dataImport/index.js'
import {canUseReportsPageSelector} from '../ReportsPage/reportsPageSelectors.js'
import {canUseMockAbodeSelector} from '../MockAbodePage/mockAbodeFunctions.js'
import {HEADER_SETTINGS_DROPDOWN} from './headerSelectors.js'
import {useSelector} from '../../store.js'

export default function SettingsDropdown() {
  const settingsPermissions = useSelector(settingsPermissionsSelector)
  const companyName = useSelector(companyNameSelector)
  const email = useSelector(emailSelector)
  const companyID = useSelector(companyIDSelector)
  const currentDropdown = useSelector(currentDropdownSelector)
  const canUseDataImport = useSelector(canUseDataImportSelector)
  const canUseReportsPage = useSelector(canUseReportsPageSelector)
  const canUseMockAbode = useSelector(canUseMockAbodeSelector)

  return (
    <li
      className={classNames('utility-nav__item wrap--dropdown dropdown', {
        open: currentDropdown === HEADER_SETTINGS_DROPDOWN,
      })}
      data-dropdown={HEADER_SETTINGS_DROPDOWN}
    >
      <button
        className="btn btn--ko-dropdown utility-nav__link utility-nav__link--settings"
        type="button"
      >
        <span
          className="icon icon-settings icon-block x-sm"
          aria-hidden="true"
        />{' '}
        <strong className="btn__text btn__text--company">{companyName}</strong>{' '}
      </button>
      <div className="wrap--inner-dropdown wrap--inner-dropdown-right">
        <ul className="list--dropdown">
          <li className="list__item list__item--dropdown">
            <ul className="wrap--support-pin">
              <li className="list__item list__item--dropdown no-border fs-n0">
                <strong className="make-block">{email}</strong>
              </li>
              <li
                className="list__item list__item--dropdown no-border fs-n0"
                data-dropdown-prevent-close
              >
                <strong>Support PIN:</strong>
                {` ${companyID}`}
              </li>
            </ul>
          </li>

          {settingsPermissions.general && (
            <li className="list__item list__item--dropdown">
              <a
                className="list__link list__link--dropdown"
                href="#/settings/general"
              >
                <span className="make-block fs-n0 margin-bottom-1">
                  Account Settings
                </span>
              </a>
            </li>
          )}

          {canUseDataImport && (
            <li className="list__item list__item--dropdown">
              <a
                className="list__link list__link--dropdown"
                href="#/dataimport"
              >
                <span className="make-block fs-n0">Data Import</span>
              </a>
            </li>
          )}

          {canUseReportsPage && (
            <li className="list__item list__item--dropdown">
              <a className="list__link list__link--dropdown" href="#/reports">
                <span className="make-block fs-n0">Reports</span>
              </a>
            </li>
          )}

          {canUseMockAbode && (
            <li className="list__item list__item--dropdown">
              <a
                className="list__link list__link--dropdown"
                href="#/mock_abode"
              >
                <span className="make-block fs-n0">Mock Abode</span>
              </a>
            </li>
          )}

          <li className="list__item list__item--dropdown">
            <button
              className="btn list__link list__link--dropdown list__link--logout fs-n0"
              onClick={() => logout()}
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </li>
  )
}
