import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {dropshippableOrderNumbersSelector} from '../../../../data/orders.js'
import {
  setupProcessDropshipForm,
  updateProcessDropshipFrom,
} from './processDropshipFormFunctions.js'
import {onlyIfAutoForm} from '../../../../store.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'

function ProcessDropshipForm({orderNumbers}) {
  const dropshippableOrderNumbers = useSelector((state) =>
    dropshippableOrderNumbersSelector(state, {
      orderNumbers,
    }),
  )
  useEffect(() => {
    updateProcessDropshipFrom({orderNumbers})
  }, [orderNumbers])

  return (
    <PluralBlock array={dropshippableOrderNumbers}>
      <div className="fs-n0 lh-md">
        We detected{' '}
        <strong>
          <Count /> <Plural word="order" />
        </strong>{' '}
        with dropship-ready products
      </div>
    </PluralBlock>
  )
}

ProcessDropshipForm.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default onlyIfAutoForm(ProcessDropshipForm, setupProcessDropshipForm)
