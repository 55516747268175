import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {cartVendorsByName} from '../../../common/constants/CartVendorOptions.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  EDIT,
} from '../../../common/constants/SettingsPanels.js'
import {CartShape} from '../../../common/PropTypes.js'
import {isCartArchivedSelector} from '../../../data/carts.js'
import {cartAuthIntegrationIssueSelector} from '../../../redux/selectors/ui/dashboard.js'
import {settingsSalesChannelCartIDSelector} from './salesChannelsSelectors.js'
import Vendors from './Vendors/index.js'
import Buttons from './Buttons.js'

export default function EditCart({cart}) {
  const isArchived = useSelector((state) =>
    isCartArchivedSelector(state, {cartID: cart.id}),
  )
  const editID = useSelector(settingsSalesChannelCartIDSelector)
  const integrationIssue = useSelector((state) =>
    cartAuthIntegrationIssueSelector(state, {cartID: cart.id}),
  )

  const isExpanded = cart.id === editID
  const Vendor = Vendors[cart.vendor]
  const formName = `SETTINGS_SALES_CHANNELS_${cart.id}`

  if (!Vendor) {
    return null
  }

  return (
    <li
      className={classNames('list__item--settings clearfix', {
        expanded: isExpanded,
        archived: isArchived,
        warning: integrationIssue,
      })}
    >
      <div className="wrap--row">
        <div className="medium-5 columns">
          <strong className="fs-01 margin-right-5 v-align-middle wrap--reauth-notice">
            {cart.name}
          </strong>
          {integrationIssue && (
            <div>
              <strong className="label__callout label__callout--yellow v-align-middle">
                Reauthorize
              </strong>
            </div>
          )}
        </div>
        <div className="medium-5 columns">
          <span className="fs-01 v-align-middle text--lt-grey">
            {cartVendorsByName[cart.vendor].display}
          </span>
        </div>
        <div className="medium-2 columns align-right">
          {isArchived && (
            <em className="fs-01 v-align-middle text--lt-grey ">Archived</em>
          )}
          {!isArchived && (
            <span className="fs-01  v-align-middle">
              <a
                className="inline-text-button settings-list-item-button edit-url"
                href={`${SALES_CHANNEL_SETTINGS_LINK}/${cart.id}/${EDIT}`}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      </div>
      {isExpanded && (
        <>
          <Vendor
            className="clear-both clearfix"
            cartID={cart.id}
            formName={formName}
          />
          <Buttons formName={formName} />
        </>
      )}
    </li>
  )
}

EditCart.propTypes = {
  cart: CartShape.isRequired,
}
