import {navigate} from '../../ui/index.js'

export const BASE = 'ordoro/ui/settings/users'
export const SET_IS_SAVING = `${BASE}/SET_IS_SAVING`
export const SET_IS_CHANGE_PASSWORD = `${BASE}/SET_IS_CHANGE_PASSWORD`
export const SET_SERVER_ERROR = `${BASE}/SET_SERVER_ERROR`
export const SET_USER_FORM = `${BASE}/SET_USER_FORM`
export const UPDATE_USER_FORM = `${BASE}/UPDATE_USER_FORM`
export const UPDATE_PERMISSIONS = `${BASE}/UPDATE_PERMISSIONS`
export const SETUP_FOR_EDIT = `${BASE}/SETUP_FOR_EDIT`
export const SAVE_USER = `${BASE}/SAVE_USER`
export const TOGGLE_CHANGE_PASSWORD = `${BASE}/TOGGLE_CHANGE_PASSWORD`

export function setServerError(value) {
  return {
    type: SET_SERVER_ERROR,
    payload: value,
  }
}

export function setIsSaving(value) {
  return {
    type: SET_IS_SAVING,
    payload: value,
  }
}

export function setIsChangingPassword(value) {
  return {
    type: SET_IS_CHANGE_PASSWORD,
    payload: value,
  }
}

export function setUserForm(value) {
  return {
    type: SET_USER_FORM,
    payload: value,
  }
}

export function updateUserForm(update) {
  return {
    type: UPDATE_USER_FORM,
    payload: update,
  }
}

export function updatePermissions(permissions) {
  return {
    type: UPDATE_PERMISSIONS,
    payload: permissions,
  }
}

export function setupForEdit() {
  return {
    type: SETUP_FOR_EDIT,
  }
}

export function setupForAdding() {
  return setUserForm({
    link: '',
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    warehouseIDs: [],
    permissions: {},
  })
}

export function goToUserSettings() {
  return navigate('#/settings/user')
}

export function saveUser() {
  return {
    type: SAVE_USER,
  }
}

export function toggleChangePassword() {
  return {
    type: TOGGLE_CHANGE_PASSWORD,
  }
}
