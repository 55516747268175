import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  LabelInfoIDShape,
  SelectOptionsShape,
} from '../../../common/PropTypes.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  labelShipToAddressSelector,
  shipperVendorConfigPropertySelector,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  holdAtLocationDisplay,
  holdAtLocationIDPropertyFunc,
  holdAtLocationOptionsPropertyFunc,
} from '../Fields/HoldAtLocation.js'
import apiverson from '../../../common/apiverson.js'
import Zelect, {
  zelectOptionComponentShape,
} from '../../../common/components/Zelect.js'
import Address from '../../../common/components/Order/Address.js'
import PrettyNumber from '../../../common/components/PrettyNumber.js'
import {pickAddress} from '../../../common/address.js'
import {useZropdownElement} from '../../../common/components/Zropdown.js'

const MODAL_FORM = 'HOLD_AT_LOCATION_MODAL'

export function showHoldAtLocationModal(labelInfoID, shipperType) {
  const labelConfig = labelConfigSelector(getState(), {
    labelInfoID,
  })
  const locations =
    labelConfig[holdAtLocationOptionsPropertyFunc(shipperType)] || []

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    hold_at_location_id:
      labelConfig[holdAtLocationIDPropertyFunc(shipperType)] || '',
    locations,
    options: locationsToOptions(locations),
    isSaving: false,
    gettingOptions: true,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function locationsToOptions(locations) {
  return [
    {value: '', display: 'Do not hold at location'},
    ...locations.map((location) => ({
      value: location.id,
      display: holdAtLocationDisplay(location),
      entity: location,
    })),
  ]
}

async function getOptions() {
  try {
    updateModalForm({gettingOptions: true, locations: [], options: []})

    const {labelInfoID, shipperType, hold_at_location_id} =
      modalFormSelector(getState())

    const [, shipper] = shipperVendorConfigPropertySelector(getState(), {
      labelInfoID,
      shipperType,
      property: 'child_key',
    })

    if (!shipper) {
      return
    }

    const address = labelShipToAddressSelector(getState(), {labelInfoID})

    const params = {
      shipper_id: shipper.id,
      ship_to: pickAddress(address),
      options: {
        location_types: [
          'FEDEX_AUTHORIZED_SHIP_CENTER',
          'FEDEX_OFFICE',
          'FEDEX_ONSITE',
          'FEDEX_EXPRESS_STATION',
        ],
      },
    }
    const {json: locations} = await apiverson.post('/fedex_locations', params)

    const options = locationsToOptions(locations)

    updateModalForm({
      locations,
      options,
      hold_at_location_id: options.find(
        ({value}) => value === hold_at_location_id,
      )
        ? hold_at_location_id
        : '',
    })
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  } finally {
    updateModalForm({gettingOptions: false})
  }
}

function updatedParamsSelector(state) {
  const form = modalFormSelector(state)
  const {shipperType} = form
  const labelConfig = labelConfigSelector(state, {
    labelInfoID: form.labelInfoID,
  })
  const labelProperty = holdAtLocationIDPropertyFunc(shipperType)

  const updates = {}

  if (labelConfig[labelProperty] !== form.hold_at_location_id) {
    updates[labelProperty] = form.hold_at_location_id
    updates[holdAtLocationOptionsPropertyFunc(shipperType)] = form.locations
  }

  return updates
}

export async function updateHoldAtLocation() {
  try {
    const form = modalFormSelector(getState())
    const params = updatedParamsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    if (Object.keys(params).length) {
      updateLabelConfig(form.labelInfoID, params)

      await getRates([form.labelInfoID], {justSaveLabelConfig: true})

      showMessageToast('Updated Hold at Location')
    }

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function AddressOption({
  option,
  index,
  form: {selectedIndex},
  values,
  onSelectInternal,
}) {
  const ref = useRef()
  const location = option.entity
  const isSelected = index === selectedIndex

  useZropdownElement({ref, isSelected})

  return (
    <li ref={ref} className="list__item list__item--dropdown">
      <button
        className={`btn--link list__link--dropdown flex--justify-nowrap ${
          values.includes(option.value) ? 'selected' : ''
        } ${isSelected ? 'list__link--dropdown-arrow-focus' : ''}`}
        type="button"
        onClick={() => onSelectInternal(option)}
      >
        {location ? (
          <>
            <div>
              <Address address={option.entity.address} />
            </div>
            <div className="unbold">
              <PrettyNumber value={option.entity.distance.value} digits={1} />{' '}
              {option.entity.distance.units}
            </div>
          </>
        ) : (
          <em>{option.display}</em>
        )}
      </button>
    </li>
  )
}

AddressOption.propTypes = zelectOptionComponentShape

function HoldAtLocationModal({form}) {
  useEffect(() => {
    getOptions()
  }, [])

  return (
    <ConfirmModal
      title="Edit Hold at Location"
      modalSize="sm"
      onConfirm={() => updateHoldAtLocation()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      showTryAgain={false}
    >
      {form.gettingOptions ? (
        <div className="loading loading--hold-at-location align-center">
          <span className="spinner--md animate-spin v-align-middle" />
          <em className="inline-block v-align-middle fs-01 op-75 margin-left-5">
            Loading locations...
          </em>
        </div>
      ) : (
        <ul className="list list--no-style">
          <li className="list__item list__item--form">
            <Zelect
              label="List of Locations"
              id="hold_at_location_id_2"
              value={form.hold_at_location_id}
              onChange={(option) => {
                updateModalForm({
                  hold_at_location_id: option.value || undefined,
                })
              }}
              disabled={form.options.length === 0 || form.gettingOptions}
              options={form.options}
              placeholder="Select one..."
              OptionComponent={AddressOption}
            />
          </li>
        </ul>
      )}
    </ConfirmModal>
  )
}

HoldAtLocationModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    hold_at_location_id: PropTypes.string.isRequired,
    options: SelectOptionsShape.isRequired,
    isSaving: PropTypes.bool.isRequired,
    gettingOptions: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(HoldAtLocationModal, modalFormSelector)
