import PropTypes from 'prop-types'
import {Component} from 'react'

import {
  UsersShape,
  ErrorsShape,
  UserFormShape,
} from '../../../common/PropTypes.js'

import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export default class List extends Component {
  static propTypes = {
    users: UsersShape.isRequired,
    generalError: PropTypes.string.isRequired,
    editLink: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    userForm: UserFormShape.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isChangingPassword: PropTypes.bool.isRequired,
    errors: ErrorsShape.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    meID: PropTypes.number.isRequired,
    canManageUser: PropTypes.bool.isRequired,
    usePermissions: PropTypes.bool.isRequired,
    updateUserForm: PropTypes.func.isRequired,
    updatePermissions: PropTypes.func.isRequired,
    setupForEdit: PropTypes.func.isRequired,
    setupForAdding: PropTypes.func.isRequired,
    saveUser: PropTypes.func.isRequired,
    toggleChangePassword: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.editLink) {
      this.props.setupForEdit()
    }

    if (this.props.isNew) {
      this.props.setupForAdding()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.editLink && this.props.editLink !== prevProps.editLink) {
      this.props.setupForEdit()
    }

    if (this.props.isNew && this.props.isNew !== prevProps.isNew) {
      this.props.setupForAdding()
    }
  }

  renderEditUsers() {
    const {
      generalError,
      userForm,
      isSaving,
      isChangingPassword,
      errors,
      hasErrors,
      meID,
      canManageUser,
      usePermissions,
      updateUserForm,
      updatePermissions,
      saveUser,
      toggleChangePassword,
    } = this.props

    return this.props.users.map((user) => (
      <EditForm
        key={user.id}
        generalError={generalError}
        user={user}
        userForm={userForm}
        isSaving={isSaving}
        errors={errors}
        hasErrors={hasErrors}
        isChangingPassword={isChangingPassword}
        meID={meID}
        canManageUser={canManageUser}
        usePermissions={usePermissions}
        updateUserForm={updateUserForm}
        updatePermissions={updatePermissions}
        saveUser={saveUser}
        toggleChangePassword={toggleChangePassword}
      />
    ))
  }

  render() {
    const {
      generalError,
      userForm,
      isNew,
      isSaving,
      errors,
      hasErrors,
      canManageUser,
      updateUserForm,
      saveUser,
    } = this.props

    return (
      <ul className="settings-list medium-12 columns">
        {this.renderEditUsers()}
        {canManageUser && (
          <NewForm
            generalError={generalError}
            userForm={userForm}
            isNew={isNew}
            isSaving={isSaving}
            errors={errors}
            hasErrors={hasErrors}
            updateUserForm={updateUserForm}
            saveUser={saveUser}
          />
        )}
      </ul>
    )
  }
}
