import {
  reportsHaveLoadedSelector,
  reportsSortedByNameSelector,
} from '../../data/reports.js'
import {useSelector} from '../../store.js'

import ReportRow from './ReportRow.js'
import {isSuperuserSelector} from '../../data/me.js'

export default function ReportListPanel() {
  const hasLoaded = useSelector(reportsHaveLoadedSelector)
  const reports = useSelector(reportsSortedByNameSelector)
  const isSuperuser = useSelector(isSuperuserSelector)

  return (
    <>
      {!hasLoaded ? (
        <div className="loading align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-7">
            Loading...
          </strong>
        </div>
      ) : reports.length ? (
        <table className="table fs-00">
          <thead>
            <tr className="fs-n0">
              <th className="table__th w-30">Report Name</th>
              <th className="table__th w-20">Report Recipients</th>
              <th className="table__th w-20">Frequency</th>
              <th className="table__th w-30">Report Downloads</th>
            </tr>
          </thead>
          <tbody className="table__tbody table__tbody--lines">
            {reports.map((report) => (
              <ReportRow
                key={report.id}
                report={report}
                showLogLink={isSuperuser}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p className="fs-02 lh-md margin-top-20 margin-bottom-10 align-center">
          <strong>No Reports have been setup.</strong>
        </p>
      )}
    </>
  )
}
