import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {ProductShape} from '../../common/PropTypes.js'
import {isPresent} from '../../common/utils.js'
import Checkbox from '../../common/components/Checkbox.js'
import Currency from '../../common/components/Currency.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {productSelector} from '../../data/products.js'

import {showEditRMALineModal} from '../ReturnOrderListPage/Modals/EditRMALineModal.js'
import {showAssignReturnOrderLineCodeModal} from '../ReturnOrderListPage/Modals/AssignReturnOrderLineCodeModal.js'
import {showDeleteReturnOrderLinesModal} from '../ReturnOrderListPage/Modals/DeleteReturnOrderLinesModal.js'
import {showRecieveReturnOrderModal} from '../ReturnOrderListPage/Modals/ReceiveReturnOrderModal/index.js'
import {showUpdateReturnOrderLineNotesModal} from '../ReturnOrderListPage/Modals/UpdateReturnOrderLineNotesModal.js'
import {updateReturnOrderDetailForm} from './returnOrderDetailActions.js'

export function Line({
  line,
  index,
  referenceID,
  selectedLineIDs,
  lines,
  product,
  ...props
}) {
  // filter out this line from selected lineIDs if removed
  useEffect(() => {
    return () => {
      updateReturnOrderDetailForm({
        selectedLineIDs: selectedLineIDs.filter(
          (lineID) => line.line_id !== lineID,
        ),
      })
    }
  }, [])

  if (!product) {
    return null
  }

  return (
    <tr className="table__tr--return-orders">
      <td className="table__td">
        <Checkbox
          className="margin-bottom-0"
          checked={selectedLineIDs.includes(line.line_id)}
          onClick={(checked, event) => {
            if (event.shiftKey) {
              const indexOfNextToLastSelected = lines.reduce(
                (prev, {line_id}, i) => {
                  if (line_id === selectedLineIDs[selectedLineIDs.length - 1]) {
                    return i
                  }

                  return prev
                },
                0,
              )

              const min = Math.min(indexOfNextToLastSelected, index)
              const max = Math.max(indexOfNextToLastSelected, index)

              const toToggle = []
              for (let i = min; i <= max; i++) {
                if (lines[i]) {
                  toToggle.push(lines[i].line_id)
                }
              }

              updateReturnOrderDetailForm({
                selectedLineIDs: [
                  ...selectedLineIDs.filter((s) => !toToggle.includes(s)),
                  ...(checked ? toToggle : []),
                ],
              })
            } else {
              const ids = selectedLineIDs.filter((id) => id !== line.line_id)

              if (checked) {
                ids.push(line.line_id)
              }

              updateReturnOrderDetailForm({
                selectedLineIDs: ids,
              })
            }
          }}
        />
      </td>
      <td className="table__td">
        <dl className="list">
          <dt className="list__title fs-n1 lh-md line-detail margin-bottom-0">
            <a
              className="btn--link darker"
              href={`#/product/${encodeURIComponent(line.sku)}`}
            >
              <strong className="block">{line.product_name}</strong>
              <span className="block unbold">SKU: {line.sku}</span>
              {product.upc && (
                <span className="block unbold">UPC: {product.upc}</span>
              )}
            </a>
          </dt>
        </dl>
      </td>
      <td className="table__td">
        <div className="fs-n1">
          {isPresent(line.total_price) ? (
            <Currency value={line.total_price} />
          ) : (
            '–'
          )}
        </div>
      </td>
      <td className="table__td">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          title={line.code ? line.code.description : 'None specified'}
          onClick={() =>
            props.showAssignReturnOrderLineCodeModal(
              referenceID,
              [line.line_id],
              line.code ? line.code.code : null,
            )
          }
        >
          {line.code ? line.code.code : <em>None specified</em>}
        </ButtonLink>
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          onClick={() =>
            props.showRecieveReturnOrderModal(
              referenceID,
              [line.line_id],
              'expected',
            )
          }
        >
          {line.expected_quantity}
        </ButtonLink>
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          onClick={() =>
            props.showRecieveReturnOrderModal(
              referenceID,
              [line.line_id],
              'recieved',
            )
          }
        >
          {line.received_quantity}
        </ButtonLink>
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="darker lh-md v-align-top fs-n1"
          onClick={() =>
            props.showRecieveReturnOrderModal(
              referenceID,
              [line.line_id],
              'restocked',
            )
          }
        >
          {line.restocked_quantity}
        </ButtonLink>
      </td>
      <td className="table__td">
        {line.line_notes ? (
          <>
            <div className="fs-n1">
              <div>
                <strong className="text--lt-grey">Notes</strong>
              </div>
              <div className="fs-n1 ws-pre-wrap">{line.line_notes}</div>
            </div>
            <ButtonLink
              className="fs-n1 meta-returnorderdetail-button-edit-customer-note"
              onClick={() =>
                props.showUpdateReturnOrderLineNotesModal(
                  referenceID,
                  line.line_id,
                )
              }
            >
              Edit
            </ButtonLink>
          </>
        ) : (
          <ButtonLink
            className="fs-n1 meta-returnorderdetail-button-add-customer-note"
            onClick={() =>
              props.showUpdateReturnOrderLineNotesModal(
                referenceID,
                line.line_id,
              )
            }
          >
            Add a note
          </ButtonLink>
        )}
        {line.product_serial_numbers.length > 0 ? (
          <>
            <div className="fs-n1 divider--top sm">
              <div>
                <strong className="text--lt-grey">
                  Serial Number{line.product_serial_numbers.length > 1 && 's'}
                </strong>
              </div>
              <div>{line.product_serial_numbers.join(', ')}</div>
            </div>
            <button
              className="btn btn--link fs-n1 v-align-base"
              type="button"
              onClick={() =>
                showEditRMALineModal(
                  referenceID,
                  [line.line_id],
                  'product_serial_numbers',
                )
              }
            >
              <span>Edit</span>
            </button>
          </>
        ) : (
          <div className="divider--top sm">
            <button
              className="btn btn--link fs-n1 v-align-base"
              type="button"
              onClick={() =>
                showEditRMALineModal(
                  referenceID,
                  [line.line_id],
                  'product_serial_numbers',
                )
              }
            >
              <span>Add serial number(s)</span>
            </button>
          </div>
        )}
      </td>
      <td className="table__td">
        <ButtonLink
          title="Remove line from RMA"
          className="no-underline"
          onClick={() =>
            props.showDeleteReturnOrderLinesModal(referenceID, [line.line_id])
          }
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

Line.propTypes = {
  line: PropTypes.shape({
    line_id: PropTypes.number.isRequired,
    product_name: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    code: PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
    expected_quantity: PropTypes.number.isRequired,
    received_quantity: PropTypes.number.isRequired,
    restocked_quantity: PropTypes.number.isRequired,
    total_price: PropTypes.number,
    status: PropTypes.string,
    line_notes: PropTypes.string,
    product_serial_numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  referenceID: PropTypes.string.isRequired,
  selectedLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  product: ProductShape,
  showAssignReturnOrderLineCodeModal: PropTypes.func.isRequired,
  showDeleteReturnOrderLinesModal: PropTypes.func.isRequired,
  showRecieveReturnOrderModal: PropTypes.func.isRequired,
  showUpdateReturnOrderLineNotesModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {line}) {
  return {
    product: productSelector(state, {sku: line.sku}),
  }
}

const mapDispatchToProps = {
  showAssignReturnOrderLineCodeModal,
  showDeleteReturnOrderLinesModal,
  showRecieveReturnOrderModal,
  showUpdateReturnOrderLineNotesModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Line)
