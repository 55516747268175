import {buildPath} from './querystring.js'

export function navigate(pathComponents, query) {
  const newHash = buildPath(pathComponents, query)

  window.location.hash = newHash

  return newHash
}

export function redirect(domain, pathComponents, query) {
  let path = buildPath(pathComponents, query)

  if (domain) {
    path = `https://${domain}${path}`
  }

  if (window.THIS_IS_A_TEST_ENV) {
    window.LAST_LOCATION_ASSIGNMENT = path
  } else {
    window.location = path
  }

  return path
}
