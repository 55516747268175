import PropTypes from 'prop-types'

import {onlyIf} from '../../store.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import TextInput from './TextInput.js'
import Zropdown from './Zropdown.js'

function FilterDropdown({
  dropdown,
  form,
  onChangeInternal,
  onConfirm,
  onUp,
  onDown,
  onBlur,
  placeholder,
  parentRef,
  children,
  additionalHeader,
  footer,
}) {
  return (
    <Zropdown
      dropdown={dropdown}
      parentRef={parentRef}
      onConfirm={onConfirm}
      onUp={onUp}
      onDown={onDown}
      header={
        <>
          <div className="wrap--text-input--dropdown-with-filter">
            <TextInput
              value={form.text}
              placeholder={placeholder}
              onChange={(value) => onChangeInternal(value)}
              onBlur={(event) => onBlur && onBlur(event)}
              autoFocus
              data-dropdown-prevent-close
            />
          </div>
          {additionalHeader}
        </>
      }
      footer={footer}
    >
      {children}
    </Zropdown>
  )
}

FilterDropdown.propTypes = {
  dropdown: PropTypes.string.isRequired,
  form: PropTypes.shape({
    text: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedIndex: PropTypes.number.isRequired,
  }),
  onChangeInternal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  parentRef: PropTypes.object.isRequired,
  children: PropTypes.node,
  additionalHeader: PropTypes.node,
  footer: PropTypes.node,
}

export default onlyIf(
  FilterDropdown,
  (state, {dropdown}) => dropdown === currentDropdownSelector(state),
)
