import {useEffect} from 'react'

import {useSelector} from '../../../store.js'
import {
  warehousesSortedByNameSelector,
  areAnyWarehousesConfiguredSelector,
} from '../../../data/warehouses.js'
import {
  settingsWarehouseIDSelector,
  settingsWarehouseAddSelector,
  setupForEdit,
  setupForAdding,
  resetWarehousesForm,
} from './warehousesFunctions.js'

import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export default function List() {
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const areAnyWarehousesConfigured = useSelector(
    areAnyWarehousesConfiguredSelector,
  )
  const settingsWarehouseID = useSelector(settingsWarehouseIDSelector)
  useEffect(() => {
    if (!settingsWarehouseID) {
      return
    }

    setupForEdit()
  }, [settingsWarehouseID])

  const settingsWarehouseAdd = useSelector(settingsWarehouseAddSelector)
  useEffect(() => {
    if (!settingsWarehouseAdd) {
      return
    }

    setupForAdding()
  }, [settingsWarehouseAdd])

  useEffect(() => {
    if (settingsWarehouseID || settingsWarehouseAdd) {
      return
    }

    resetWarehousesForm()
  }, [settingsWarehouseID, settingsWarehouseAdd])

  return (
    <ul className="settings-list medium-12 columns">
      {warehouses.map((warehouse) => (
        <EditForm key={warehouse.id} warehouse={warehouse} />
      ))}
      {areAnyWarehousesConfigured && <NewForm />}
    </ul>
  )
}
