import {
  suppliersHaveLoadedSelector,
  useSuppliersSelector,
} from '../../../data/suppliers.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {useSelector, onlyIfAutoForm} from '../../../store.js'
import SettingsPanel from '../SettingsPanel.js'
import List from './List.js'
import SuppliersFeaturePage from './SuppliersFeaturePage.js'
import {setupSuppliersForm} from './suppliersFunctions.js'

function Suppliers() {
  const hasLoaded = useSelector(suppliersHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).supplier
  const useSuppliers = useSelector(useSuppliersSelector)

  if (!useSuppliers) {
    return <SuppliersFeaturePage />
  }

  return (
    <SettingsPanel
      header="Supplier Settings"
      subHeader={
        <p>
          Setup suppliers if you dropship or want to resupply using our PO
          system. For more advanced supplier management, check out our{' '}
          <a
            href="https://support.ordoro.com/how-do-i-integrate-my-supplier-feeds-into-ordoro/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Supplier Feeds
          </a>{' '}
          and{' '}
          <a
            href="https://support.ordoro.com/how-do-i-set-up-and-use-the-vendor-portal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vendor Portal
          </a>{' '}
          functionality.
        </p>
      }
      hasPermission={hasPermission}
    >
      <div className="row hidden">
        <div className="medium-12 columns">
          <div className="alert alert--neutral align-center margin-bottom-20">
            <p className="margin-bottom-0">
              Check out our <a href="/">Supplier Feeds</a> and{' '}
              <a href="/">Vendor Portal</a> functionality for more advanced
              supplier management.
            </p>
          </div>
        </div>
      </div>
      <div className="row hidden">
        <div className="medium-6 columns border-right--light">
          <div className="padding-right-15">
            <dl className="list margin-bottom-25">
              <dt className="list__title fs-01 lh-sm margin-bottom-10">
                Supplier Feeds
              </dt>
              <dd className="fs-00 margin-bottom-15">
                By connecting directly with your suppliers, Supplier Feeds allow
                Ordoro to automatically update product quantities based on the
                amount inventory your vendors have on hand.
              </dd>
              <dd className="fs-00 margin-bottom-15">
                Supplier Feeds retrieve suppliers’ product counts in three
                different ways; CSV files via email, file transfer (FTP), or
                through the web (HTTP). Once collected, the app then targets
                every sales channel carrying those products and updates their
                quantities accordingly.
              </dd>
              <dd className="fs-00 margin-bottom-0">
                Let us know if you want Supplier Feeds and we’ll set them up for
                you. But if you have the developer chops for it, you can build
                them yourself.
              </dd>
            </dl>
          </div>
        </div>
        <div className="medium-6 columns">
          <div className="padding-left-15">
            <dl className="list margin-bottom-25">
              <dt className="list__title fs-01 lh-sm margin-bottom-10">
                Vendor Portal
              </dt>
              <dd className="fs-00 margin-bottom-15">
                A Vendor Portal puts the onus of order fulfillment entirely on
                your supplier. No routing orders their way, no phone calls, no
                faxes — just a portal for them to enter.
              </dd>
              <dd className="fs-00 margin-bottom-15">
                Vendor Portals allow your suppliers to log into Ordoro, view
                only their orders, and fulfill them themselves without any
                action on your end. The supplier is able to create and print
                shipping labels through whatever shipping account they use, and
                tracking information created or entered afterwards is sent
                directly to your sales channel for the customer to check out.
              </dd>
              <dd className="fs-00 margin-bottom-15">
                You can activate as many Vendor Portals as you have suppliers;
                each vendor will only have access to orders with products
                they’ve been assigned to. Plus, you have the power to keep
                control of your brand through customizable packing slips and
                logo-emblazoned labels.
              </dd>
              <dd className="fs-00 margin-bottom-0">
                For step-by-step guidance on setting up a Vendor Portal, take a
                look at this support article.
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="row">{hasLoaded && <List />}</div>
    </SettingsPanel>
  )
}

export default onlyIfAutoForm(Suppliers, setupSuppliersForm)
