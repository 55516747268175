import PropTypes from 'prop-types'

import {splitOz} from '../weight.js'

export default function Weight({value}) {
  value = value || 0
  const {lb, oz} = splitOz(value)
  const formattedLB = `${lb} lb`
  const formattedOZ = `${oz} oz`

  if (lb && oz) {
    return (
      <>
        <span className="inline-block margin-right-5">{formattedLB}</span>
        <span className="inline-block">{formattedOZ}</span>
      </>
    )
  } else if (lb) {
    return <span>{formattedLB}</span>
  }

  return <span>{formattedOZ}</span>
}

Weight.propTypes = {
  value: PropTypes.number,
}
