import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {
  startCheckingForRunningTasks,
  checkRunningTasks,
} from '../../redux/actions/data/isRunningTasks.js'

export class RunningTasksListener extends Component {
  static propTypes = {
    startCheckingForRunningTasks: PropTypes.func.isRequired,
    checkRunningTasks: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.startCheckingForRunningTasks()

    this.props.checkRunningTasks()
  }

  render() {
    return null
  }
}

const mapDispatchToProp = {
  startCheckingForRunningTasks,
  checkRunningTasks,
}

export default connect(null, mapDispatchToProp)(RunningTasksListener)
