import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {
  goToShipperSettings,
  showExportUSPSTransactionsModal,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  saveShipperVisibleUSPS,
  activateVisibleUSPSShipper,
} from '../../../../redux/actions/ui/settings/shippers/forms/visibleUSPS.js'
import {hasErrorsSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import PostageBalance from './PostageBalance.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function VisibleUSPSForm({form: {id, isSaving}, hasErrors, ...props}) {
  if (id !== NEW_ID) {
    return (
      <div className="settings-list-item-form-wrap settings-list-item-form-usps clear-both">
        <fieldset>
          <ul className="form-list medium-5 columns margin-bottom-0">
            <Nickname shipperID={id} />
            <ShippingMethods shipperID={id} />
            <li className="list__item list__item--no-style divider--bottom padding-bottom-20">
              <ButtonPrimary
                className="btn--primary-alt"
                isLoading={isSaving}
                isDisabled={hasErrors}
                onClick={() => props.saveShipperVisibleUSPS(id)}
              >
                Update
              </ButtonPrimary>
            </li>
          </ul>
        </fieldset>
        <fieldset className="margin-bottom-0">
          <div className="medium-5 columns">
            <PostageBalance shipperID={id} />
            <dl className="list divider--top margin-top-20">
              <dt className="list__title fs-00">Transaction History</dt>
              <dd className="list__item fs-00">
                <button
                  type="button"
                  className="btn btn--link"
                  onClick={() => props.showExportUSPSTransactionsModal()}
                >
                  Export CSV
                </button>
              </dd>
            </dl>
          </div>
        </fieldset>
      </div>
    )
  }

  return (
    <div className="medium-7 columns">
      <h4 className="margin-top-20 margin-bottom-5">
        Activate your free USPS account
      </h4>
      <p className="margin-bottom-15">
        {
          'To gain access to our USPS shipping rates and to print USPS labels, you need to activate your '
        }
        <a
          href="//support.ordoro.com/how-do-i-get-a-free-postage-account-with-ordoro/"
          title="Learn more about Ordoro's free USPS accounts"
          target="_blank"
          rel="noopener noreferrer"
        >
          free USPS account
        </a>
        {'.'}
      </p>
      <div>
        <ButtonPrimary
          onClick={() => {
            activateVisibleUSPSShipper()

            props.goToShipperSettings()
          }}
        >
          Activate now
        </ButtonPrimary>
        <ButtonSecondary onClick={() => props.cancel()}>Cancel</ButtonSecondary>
      </div>
    </div>
  )
}

VisibleUSPSForm.propTypes = {
  form: ShipperFormShape,
  hasErrors: PropTypes.bool.isRequired,
  goToShipperSettings: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  saveShipperVisibleUSPS: PropTypes.func.isRequired,
  showExportUSPSTransactionsModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    hasErrors: hasErrorsSelector(state, {form}),
  }
}

const mapDispatchToProps = {
  goToShipperSettings,
  saveShipperVisibleUSPS,
  showExportUSPSTransactionsModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibleUSPSForm)
