import PropTypes from 'prop-types'

import {updateForm, useForm, useSelector} from '../../../store.js'
import Checkbox from '../Common/Checkbox.js'
import {isSuperuserSelector} from '../../../data/me.js'
import FormListItem from '../Common/FormListItem.js'

export default function LockWriteBack({className, formName}) {
  const {prevent_write_back_settings_adjustment} = useForm(formName)
  const isSuperuser = useSelector(isSuperuserSelector)

  if (!isSuperuser) {
    return null
  }

  return (
    <FormListItem
      className={`alert alert--zuck-mode margin-bottom-20 ${className || ''}`}
    >
      <Checkbox
        label="Lock inventory writeback checkbox"
        id="preventWriteBackSettingsAdjustment"
        checked={!!prevent_write_back_settings_adjustment}
        onChange={() =>
          updateForm(formName, {
            prevent_write_back_settings_adjustment:
              !prevent_write_back_settings_adjustment,
          })
        }
      />
    </FormListItem>
  )
}

LockWriteBack.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
