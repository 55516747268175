import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {MOShape, moSelector} from '../../data/mos.js'
import {showDeleteMOLinesModal} from '../MOListPage/Modals/DeleteMOLinesModal.js'
import {showEditMOLineModal} from '../MOListPage/Modals/EditMOLineModal.js'
import {showFinishMOModal} from '../MOListPage/Modals/FinishMOModal.js'
import {MODetailFormShape, updateMODetailForm} from './moDetailActions.js'
import {
  moDetailFormSelector,
  moDetailReferenceIDSelector,
} from './moDetailSelectors.js'
import Line from './Line.js'

export function Lines({form, referenceID, mo}) {
  return (
    <div>
      <h3 className="list__title--order-data margin-top-0 margin-bottom-10">
        MFG Order Contents
      </h3>
      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--sm w-1">
              {mo.lines.length !== 0 && (
                <Checkbox
                  className="margin-bottom-0"
                  checked={mo.lines.length === form.selectedLineIDs.length}
                  indeterminate={
                    mo.lines.length !== form.selectedLineIDs.length &&
                    form.selectedLineIDs.length !== 0
                  }
                  onChange={() => {
                    updateMODetailForm({
                      selectedLineIDs:
                        mo.lines.length !== form.selectedLineIDs.length ||
                        form.selectedLineIDs.length === 0
                          ? mo.lines.map(({id}) => id)
                          : [],
                    })
                  }}
                />
              )}
            </th>
            <th className="table__th table__th--sm th-width-25">
              Finished SKU
            </th>
            <th className="table__th table__th--sm th-width-20">
              BOM Components
            </th>
            <th className="table__th table__th--sm th-width-10 align-right">
              {form.selectedLineIDs.length === 0 ? (
                'Requested Qty'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Requested Quantity"
                  onClick={() =>
                    showEditMOLineModal(
                      referenceID,
                      form.selectedLineIDs,
                      'requested_quantity',
                    )
                  }
                >
                  Requested Qty
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-10 align-right">
              {form.selectedLineIDs.length === 0 ? (
                'Finished'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Finish"
                  onClick={() =>
                    showFinishMOModal(referenceID, form.selectedLineIDs)
                  }
                >
                  Finished Qty
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--sm th-width-15">
              Notes for Manufacturer
            </th>
            <th className="table__th table__th--sm th-width-15">
              Internal Notes
            </th>
            <th className="table__th table__th--sm w-1">
              {form.selectedLineIDs.length !== 0 && (
                <ButtonLink
                  title="Bulk remove lines from MFG Order"
                  className="no-underline lh-sm darker"
                  onClick={() =>
                    showDeleteMOLinesModal(referenceID, form.selectedLineIDs)
                  }
                >
                  <span className="i-trash fs-00" aria-hidden="true"></span>
                </ButtonLink>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines">
          {mo.lines.map((line) => (
            <Line
              key={line.id}
              line={line}
              referenceID={referenceID}
              selectedLineIDs={form.selectedLineIDs}
            />
          ))}
        </tbody>
      </table>
      <div className="align-center margin-top-20">
        <ButtonPrimary
          isOutlined
          size="sm"
          onClick={() => showEditMOLineModal(referenceID)}
        >
          Add a Product
        </ButtonPrimary>
      </div>
    </div>
  )
}

Lines.propTypes = {
  form: MODetailFormShape.isRequired,
  referenceID: PropTypes.string.isRequired,
  mo: MOShape.isRequired,
}

function mapStateToProps(state) {
  const referenceID = moDetailReferenceIDSelector(state)

  return {
    form: moDetailFormSelector(state),
    referenceID,
    mo: moSelector(state, {referenceID}),
  }
}

export default connect(mapStateToProps)(Lines)
