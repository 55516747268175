import {takeEvery, put, call, select} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import {closeModal} from '../../../actions/ui/index.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import {
  ADD_PURCHASE_ORDER_COMMENT,
  setIsAdding,
  setServerError,
} from '../../../actions/ui/modals/addPurchaseOrderCommentModal.js'
import {
  poIDSelector,
  commentSelector,
} from '../../../selectors/ui/modals/addPurchaseOrderCommentModal.js'
import {setPO} from '../../../../data/pos.js'

export function* addPurchaseOrderCommentModalWorker() {
  try {
    yield put(setServerError(null))
    yield put(setIsAdding(true))

    const poID = yield select(poIDSelector)
    const comment = yield select(commentSelector)
    const params = {comment}

    const {json} = yield call(
      api.post,
      `/purchase_order/${encodeURIComponent(poID)}/comment`,
      params,
    )

    yield call(setPO, json)

    yield put(closeModal())

    yield call(showMessageToast, `Added comment to purchase order ${poID}`)
  } catch (err) {
    yield put(setServerError(`Error adding comment to PO: ${err.message}`))
  } finally {
    yield put(setIsAdding(false))
  }
}

export default function* addPurchaseOrderCommentModal() {
  yield takeEvery(
    ADD_PURCHASE_ORDER_COMMENT,
    addPurchaseOrderCommentModalWorker,
  )
}
