import {
  BATCH_LIST_PAGE,
  CREATE_ORDER_PAGE,
  DASHBOARD_PAGE,
  SETTINGS_PAGE,
  ORDER_LIST_PAGE,
  ORDER_DETAIL_PAGE,
  PRODUCT_LIST_PAGE,
  PRODUCT_DETAIL_PAGE,
  PURCHASE_ORDER_LIST_PAGE,
  PURCHASE_ORDER_DETAIL_PAGE,
  ANALYTICS_PAGE,
  DATA_IMPORT_PAGE,
  PACK_SHIP_PAGE,
  REPORTS_PAGE,
  RETURN_ORDER_LIST_PAGE,
  RETURN_ORDER_DETAIL_PAGE,
  MO_LIST_PAGE,
  MO_DETAIL_PAGE,
  MOCK_ABODE_PAGE,
  WELCOME_PAGE,
} from '../common/constants/Pages.js'
import {currentPageSelector} from '../redux/selectors/ui/index.js'

import BatchListPage from './BatchListPage/index.js'
import DashboardPage from './DashboardPage/index.js'
import Iverson from './iverson/Iverson.js'
import CreateOrderPage from './CreateOrderPage/index.js'
import OrderDetailPage from './OrderDetailPage/index.js'
import ProductDetailPage from './ProductDetailPage/index.js'
import ProductListPage from './ProductListPage/index.js'
import PurchaseOrderListPage from './PurchaseOrderListPage/index.js'
import PurchaseOrderDetailPage from './PurchaseOrderDetailPage/index.js'
import AnalyticsPage from './AnalyticsPage/index.js'
import Settings from './settings/Settings.js'
import DataImport from './dataImport/DataImport.js'
import PackShipPage from './PackShipPage/index.js'
import ReportsPage from './ReportsPage/index.js'
import ReturnOrderListPage from './ReturnOrderListPage/index.js'
import ReturnOrderDetailPage from './ReturnOrderDetailPage/index.js'
import MockAbodePage from './MockAbodePage/index.js'
import MOListPage from './MOListPage/index.js'
import MODetailPage from './MODetailPage/index.js'
import {useSelector} from '../store.js'
import WelcomePage from './WelcomePage/index.js'

export default function Main() {
  const currentPage = useSelector(currentPageSelector)

  if (currentPage === WELCOME_PAGE) {
    return <WelcomePage />
  }
  if (currentPage === DASHBOARD_PAGE) {
    return <DashboardPage />
  }
  if (currentPage === ORDER_LIST_PAGE) {
    return <Iverson />
  }
  if (currentPage === ORDER_DETAIL_PAGE) {
    return <OrderDetailPage />
  }
  if (currentPage === PRODUCT_LIST_PAGE) {
    return <ProductListPage />
  }
  if (currentPage === PRODUCT_DETAIL_PAGE) {
    return <ProductDetailPage />
  }
  if (currentPage === PURCHASE_ORDER_LIST_PAGE) {
    return <PurchaseOrderListPage />
  }
  if (currentPage === PURCHASE_ORDER_DETAIL_PAGE) {
    return <PurchaseOrderDetailPage />
  }
  if (currentPage === ANALYTICS_PAGE) {
    return <AnalyticsPage />
  }
  if (currentPage === SETTINGS_PAGE) {
    return <Settings />
  }
  if (currentPage === DATA_IMPORT_PAGE) {
    return <DataImport />
  }
  if (currentPage === PACK_SHIP_PAGE) {
    return <PackShipPage />
  }
  if (currentPage === REPORTS_PAGE) {
    return <ReportsPage />
  }
  if (currentPage === RETURN_ORDER_LIST_PAGE) {
    return <ReturnOrderListPage />
  }
  if (currentPage === RETURN_ORDER_DETAIL_PAGE) {
    return <ReturnOrderDetailPage />
  }
  if (currentPage === MOCK_ABODE_PAGE) {
    return <MockAbodePage />
  }
  if (currentPage === CREATE_ORDER_PAGE) {
    return <CreateOrderPage />
  }
  if (currentPage === MO_LIST_PAGE) {
    return <MOListPage />
  }
  if (currentPage === MO_DETAIL_PAGE) {
    return <MODetailPage />
  }
  if (currentPage === BATCH_LIST_PAGE) {
    return <BatchListPage />
  }

  return null
}
