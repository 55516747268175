import {all, takeEvery} from 'redux-saga/effects'

import {ARCHIVE_PRODUCT, archiveProductWorker} from './ArchiveProductModal.js'
import {
  SAVE_PRODUCT_WEIGHT,
  saveProductWeightWorker,
} from './ProductWeightModal.js'
import {
  SAVE_PRODUCT_DIMENSIONS,
  saveProductDimensionsWorker,
} from './ProductDimensionsModal.js'
import {SAVE_PRODUCT_COST, saveProductCostWorker} from './ProductCostModal.js'
import {
  SAVE_PRODUCT_PRICE,
  saveProductPriceWorker,
} from './ProductPriceModal.js'
import {
  UPDATE_DEFAULT_SUPPLIER,
  updateDefaultSupplierWorker,
} from './UpdateDefaultSupplierModal.js'
import {
  REMOVE_PRODUCT_SUPPLIER,
  removeProductSupplierWorker,
} from './RemoveProductSupplierModal.js'
import {
  SHOW_EDIT_PRODUCT_WAREHOUSE_MODAL,
  showEditProductWarehouseWorker,
  UPDATE_PRODUCT_WAREHOUSE,
  updateProductWarehouseWorker,
} from './EditProductWarehouseModal.js'
import createInventoryTransferModal from './CreateInventoryTransferModal/createInventoryTransferModalSagas.js'
import {
  SAVE_FULFILLMENT_LATENCY,
  saveFulfillmentLatencyWorker,
} from './FulfillmentLatencyModal.js'
import {
  UPDATE_AMAZON_FULFILLMENT,
  updateAmazonFulfillmentWorker,
} from './AmazonFulfillmentModal.js'

export default function* modals() {
  yield all([
    takeEvery(SAVE_PRODUCT_WEIGHT, saveProductWeightWorker),
    takeEvery(SAVE_PRODUCT_DIMENSIONS, saveProductDimensionsWorker),
    takeEvery(SAVE_PRODUCT_COST, saveProductCostWorker),
    takeEvery(SAVE_PRODUCT_PRICE, saveProductPriceWorker),
    takeEvery(ARCHIVE_PRODUCT, archiveProductWorker),
    takeEvery(UPDATE_DEFAULT_SUPPLIER, updateDefaultSupplierWorker),
    takeEvery(REMOVE_PRODUCT_SUPPLIER, removeProductSupplierWorker),
    takeEvery(
      SHOW_EDIT_PRODUCT_WAREHOUSE_MODAL,
      showEditProductWarehouseWorker,
    ),
    takeEvery(UPDATE_PRODUCT_WAREHOUSE, updateProductWarehouseWorker),
    takeEvery(SAVE_FULFILLMENT_LATENCY, saveFulfillmentLatencyWorker),
    takeEvery(UPDATE_AMAZON_FULFILLMENT, updateAmazonFulfillmentWorker),
    createInventoryTransferModal(),
  ])
}
