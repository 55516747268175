import {dispatch, useSelector} from '../../../../store.js'
import {LabelInfoIDShape} from '../../../../common/PropTypes.js'
import {
  orderNumberFromLabelConfigSelector,
  referenceIDFromLabelConfigSelector,
  labelErrorsSelector,
  hasLabelErrorsSelector,
  labelShipperTypeSelector,
} from '../../../../data/labelInfos/index.js'
import {returnOrderListSelectReferenceID} from '../../../ReturnOrderListPage/returnOrderListActions.js'
import {deselectOrder} from '../../../OrderListPage/orderListActions.js'

export default function ErrorMessage({labelInfoID}) {
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const referenceID = useSelector((state) =>
    referenceIDFromLabelConfigSelector(state, {labelInfoID}),
  )
  const sortedErrors = useSelector((state) =>
    labelErrorsSelector(state, {labelInfoID, shipperType}),
  )
  const hasErrors = useSelector((state) =>
    hasLabelErrorsSelector(state, {labelInfoID, shipperType}),
  )

  if (!hasErrors) {
    return null
  }

  return (
    <dd className="margin-bottom-7">
      <div>
        <strong className="fs-n1 margin-right-5">
          {orderNumber || referenceID}
        </strong>
        <button
          className="btn btn--link v-align-base fs-n1"
          type="button"
          onClick={() =>
            orderNumber
              ? deselectOrder(orderNumber)
              : dispatch(
                  returnOrderListSelectReferenceID(referenceID, false, false),
                )
          }
        >
          Deselect
        </button>
      </div>
      <ul className="list square">
        {sortedErrors.map((err, index) => (
          <li
            className="list__item--error fs-n1 lh-md margin-bottom-5"
            key={index}
          >
            {err}
          </li>
        ))}
      </ul>
    </dd>
  )
}

ErrorMessage.propTypes = {
  labelInfoID: LabelInfoIDShape.isRequired,
}
