import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {RETURN_ORDER_LIST_PAGE} from '../../common/constants/Pages.js'
import {RETURN_ORDER_PLURAL_URI_COMPONENT} from '../../common/constants/ReturnOrders.js'
import {LocationShape} from '../../common/PropTypes.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  setupReturnOrderListForm,
  setStatus,
  setPerPage,
  setSort,
  setSearchText,
  setTags,
  setUntagged,
  setTagFilterBy,
  setCurrentPageNumber,
} from './returnOrderListActions.js'

function ReturnOrderListLocationListener({location, ...props}) {
  const {
    pathComponents: [resource, status],
    query: {perPage, sort, searchText, page, tags, untagged, tagFilterBy},
  } = location
  const isThisPage = resource === RETURN_ORDER_PLURAL_URI_COMPONENT

  useEffect(() => {
    props.setupReturnOrderListForm()
  }, [])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setCurrentPage(RETURN_ORDER_LIST_PAGE)
  }, [isThisPage])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setStatus(status)
  }, [isThisPage, status])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setPerPage(perPage)
  }, [isThisPage, perPage])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setSort(sort)
  }, [isThisPage, sort])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setSearchText(searchText)
  }, [isThisPage, searchText])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setTags(tags)
  }, [isThisPage, tags])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setUntagged(untagged)
  }, [isThisPage, untagged])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setTagFilterBy(tagFilterBy)
  }, [isThisPage, tagFilterBy])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    props.setCurrentPageNumber(page)
  }, [isThisPage, page])

  return null
}

ReturnOrderListLocationListener.propTypes = {
  location: LocationShape.isRequired,
  setupReturnOrderListForm: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  setUntagged: PropTypes.func.isRequired,
  setTagFilterBy: PropTypes.func.isRequired,
  setCurrentPageNumber: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    location: locationSelector(state),
  }
}

const mapDispatchToProps = {
  setupReturnOrderListForm,
  setCurrentPage,
  setStatus,
  setPerPage,
  setSort,
  setSearchText,
  setTags,
  setUntagged,
  setTagFilterBy,
  setCurrentPageNumber,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderListLocationListener)
