import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import cookies from 'cookies-js'

import {setZuckCompanyID} from '../../redux/actions/ui/index.js'
import {isSuperuserSelector} from '../../data/me.js'
import {zuckCompanyIDSelector} from '../../redux/selectors/ui/index.js'

class Zuck extends Component {
  componentDidMount() {
    const zuckCompanyID = cookies.get('zuck')

    this.props.setZuckCompanyID(zuckCompanyID)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isSuperuser !== prevProps.isSuperuser ||
      this.props.zuckCompanyID !== prevProps.zuckCompanyID
    ) {
      this.updateZuck()
    }
  }

  updateZuck() {
    const {isSuperuser, zuckCompanyID} = this.props
    const currentZuck = Number(cookies.get('zuck')) || null

    // Somebody who was super user set a zuck cookie in the same browser then logged into a
    // none super user account
    if (!isSuperuser && currentZuck) {
      cookies.expire('zuck', {domain: window.ORDORO_BOOTSTRAP.env.BASE_DOMAIN})
      // just expire this too specific cookie (remove code after 2019-08-01)
      cookies.expire('zuck')
    }

    // Only super users past this point
    if (!isSuperuser) {
      return
    }

    // Zuck is changing for a super user so do the right thing
    if (zuckCompanyID !== currentZuck) {
      if (zuckCompanyID) {
        cookies.set('zuck', zuckCompanyID, {
          domain: window.ORDORO_BOOTSTRAP.env.BASE_DOMAIN,
        })
      } else {
        cookies.expire('zuck', {
          domain: window.ORDORO_BOOTSTRAP.env.BASE_DOMAIN,
        })
        // just expire this too specific cookie (remove code after 2019-08-01)
        cookies.expire('zuck')
      }

      window.location.reload()
    }
  }

  render() {
    return null
  }
}

Zuck.propTypes = {
  isSuperuser: PropTypes.bool.isRequired,
  zuckCompanyID: PropTypes.number,
  setZuckCompanyID: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isSuperuser: isSuperuserSelector(state),
  zuckCompanyID: zuckCompanyIDSelector(state),
})

const mapDispatchToProps = {
  setZuckCompanyID,
}

export default connect(mapStateToProps, mapDispatchToProps)(Zuck)
