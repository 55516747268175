import {stringifyURL} from '../../../../common/querystring.js'
import {navigate} from '../../ui/index.js'
import {setForm, updateForm, removeForm} from '../forms.js'

const BASE = 'ordoro/ui/packShipPage'

export const PACK_SHIP_PAGE_FORM = `${BASE}/PACK_SHIP_PAGE_FORM`
export const SETUP_PACK_SHIP_PAGE = `${BASE}/SETUP_PACK_SHIP_PAGE`
export const PACK_SHIP_ORDER_SCANNED = `${BASE}/PACK_SHIP_ORDER_SCANNED`
export const PACK_SHIP_ORDER_NOT_FOUND = `${BASE}/PACK_SHIP_ORDER_NOT_FOUND`
export const PACK_SHIP_GENERAL_ERROR = `${BASE}/PACK_SHIP_GENERAL_ERROR`

export function setupPackShipPageForm() {
  return setForm(PACK_SHIP_PAGE_FORM, {
    error: null,
    isLoading: true,
    labelType: 'shipping',
  })
}

export function updatePackShipPageForm(updates, meta) {
  return updateForm(PACK_SHIP_PAGE_FORM, updates, meta)
}

export function removePackShipPageForm() {
  return removeForm(PACK_SHIP_PAGE_FORM)
}

export function setupPackShipPage() {
  return {
    type: SETUP_PACK_SHIP_PAGE,
  }
}

export function navigateToPackShip(orderNumbers) {
  const params = {orderNumbers}

  return navigate(stringifyURL('#/pack_ship', params))
}

export function setPackShipError(type, message = null) {
  return updatePackShipPageForm({
    error: type
      ? {
          type,
          message,
        }
      : null,
  })
}

export function packShipPageDoneLoading() {
  return updatePackShipPageForm({
    isLoading: false,
  })
}

export function setPackShipLabelType(labelType) {
  return updatePackShipPageForm({
    labelType,
  })
}

export function packShipOrderScanned(scannedValue) {
  return {
    type: PACK_SHIP_ORDER_SCANNED,
    payload: {scannedValue},
  }
}
