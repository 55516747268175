import PropTypes from 'prop-types'

import {tagColors} from '../../../common/colors.js'
import {toggleTag} from '../createOrderActions.js'

export default function Tag({tag, canRemove}) {
  return (
    <li
      className="list__item--inline-block list__item--tag"
      style={tagColors(tag.color)}
    >
      <strong className="tag-name inline-block v-align-middle">
        {tag.text}
      </strong>
      {canRemove && (
        <button
          className="btn btn--remove-tag"
          type="button"
          onClick={() => toggleTag(tag.id, false)}
        >
          <span className="i--close" aria-hidden="true" />
        </button>
      )}
    </li>
  )
}

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  canRemove: PropTypes.bool.isRequired,
}
