import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {
  SET_START_DATE,
  SET_END_DATE,
  SET_IS_REQUESTING,
  SET_SERVER_ERROR,
  MODAL_NAME,
} from '../../../actions/ui/modals/exportInsuranceHistoryModal.js'

const MODAL = INITIAL_STATE.ui.modals.exportInsuranceHistoryModal

function startDate(state = MODAL.startDate, action = {}) {
  if (action.type === SET_START_DATE) {
    return action.payload
  }

  return state
}

function endDate(state = MODAL.endDate, action = {}) {
  if (action.type === SET_END_DATE) {
    return action.payload
  }

  return state
}

function isRequesting(state = MODAL.isRequesting, action = {}) {
  if (action.type === SET_IS_REQUESTING) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return false
  }

  return state
}

function serverError(state = MODAL.serverError, action = {}) {
  if (action.type === SET_SERVER_ERROR) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return null
  }

  return state
}

export default combineReducers({
  startDate,
  endDate,
  isRequesting,
  serverError,
})
