import omit from 'lodash/omit.js'

import {logError} from '../../../common/error.js'
import {
  RETURN_CUSTOMER_ADDRESS,
  RETURN_TO_ADDRESS,
} from '../../../common/constants/AddressTypes.js'
import apiverson from '../../../common/apiverson.js'
import {getState, dispatch} from '../../../store.js'
import {ensureOrdersLoaded} from '../../../data/orders.js'
import {ensureProductsLoaded} from '../../../data/products.js'
import {
  ensureLabelInfosLoaded,
  refreshReturnOrderRates,
} from '../../../data/labelInfos/index.js'
import {
  returnOrderSelector,
  getLabelInfoIDsFromReturnOrders,
  getProductSKUsFromReturnOrders,
  getOrderNumbersFromReturnOrders,
  returnToAddressSelector,
  returnOrderCustomerAddressSelector,
} from '../../selectors/data/returnOrders.js'
import {showEditAddressModal} from '../../../ordoro/Modals/EditAddressModal/index.js'

const BASE = 'data/returnOrder'
export const SET_RETURN_ORDERS = `${BASE}/SET_RETURN_ORDERS`
export const SET_RETURN_ORDER = `${BASE}/SET_RETURN_ORDER`
export const SET_RETURN_ORDER_LINE = `${BASE}/SET_RETURN_ORDER_LINE`
export const GET_RETURN_ORDER = `${BASE}/GET_RETURN_ORDER`
export const PATCH_RETURN_ORDER_TAG = `${BASE}/PATCH_RETURN_ORDER_TAG`

export function setReturnOrders(returnOrders) {
  return {
    type: SET_RETURN_ORDERS,
    payload: returnOrders,
  }
}

export function setReturnOrder(returnOrder) {
  return {
    type: SET_RETURN_ORDER,
    payload: returnOrder,
  }
}

export function setReturnOrderLine(referenceID, returnOrderLine) {
  return {
    type: SET_RETURN_ORDER_LINE,
    payload: {
      referenceID,
      returnOrderLine,
    },
  }
}

export function patchReturnOrderTag(
  referenceID,
  returnOrderTag,
  needsAdding = true,
) {
  dispatch({
    type: PATCH_RETURN_ORDER_TAG,
    payload: {referenceID, returnOrderTag, needsAdding},
  })
}

export async function ensureReturnOrder(referenceID, {reload} = {}) {
  const rma = returnOrderSelector(getState(), {referenceID})

  if (!reload && rma) {
    return rma
  }

  const res = await apiverson.get(
    `/return_order/${encodeURIComponent(referenceID)}`,
  )

  return res.json
}

export async function setReturnOrderStateAndEnsureProductsLoaded(returnOrder) {
  await setReturnOrdersStateAndEnsureProductsLoaded([returnOrder])
}

export async function setReturnOrdersStateAndEnsureProductsLoaded(
  returnOrders,
) {
  returnOrders.forEach((ro) => {
    if (!ro.label_infos) {
      ro.label_infos = []
    }
  })
  dispatch(setReturnOrders(returnOrders))

  await ensureProductsLoaded(getProductSKUsFromReturnOrders(returnOrders))

  await ensureLabelInfosLoaded(getLabelInfoIDsFromReturnOrders(returnOrders))

  // purposely not 'await'ing this because we only need the orders for selectors
  // and not for label config setup (yet). We don't need to wait for these orders
  ensureOrdersLoaded(getOrderNumbersFromReturnOrders(returnOrders))
}

export async function updateReturnOrders(referenceIDs) {
  const returnOrders = []

  async function updateReturnOrder(referenceID) {
    try {
      const res = await apiverson.get(
        `/return_order/${encodeURIComponent(referenceID)}`,
      )

      returnOrders.push(res.json)
    } catch (err) {
      logError('Error updating return order', err)
    }
  }

  await Promise.all(
    referenceIDs.map((referenceID) => updateReturnOrder(referenceID)),
  )

  await setReturnOrdersStateAndEnsureProductsLoaded(returnOrders)
}

export async function updateReturnOrderAddress(
  referenceID,
  addressType,
  address,
) {
  const data = {
    [addressType === RETURN_CUSTOMER_ADDRESS
      ? 'customer_address'
      : addressType === RETURN_TO_ADDRESS
        ? 'return_to_address'
        : 'unknown']: omit(address, ['id', 'validation']),
  }

  const {json} = await apiverson.put(
    `/return_order/${encodeURIComponent(referenceID)}`,
    data,
  )

  dispatch(setReturnOrder(json))
}

export function showRMAReturnToAddressModal(referenceID) {
  const returnToAddress = returnToAddressSelector(getState(), {referenceID})

  showEditAddressModal({
    hasAddressShortcuts: true,
    address: returnToAddress,
    toastMessage: 'Return to info was successfully updated.',
    onSave: async (address) => {
      await updateReturnOrderAddress(referenceID, RETURN_TO_ADDRESS, address)

      refreshReturnOrderRates(referenceID)
    },
  })
}

export function showRMAReturnCustomerAddressModal(referenceID) {
  const customerAddress = returnOrderCustomerAddressSelector(getState(), {
    referenceID,
  })

  showEditAddressModal({
    address: customerAddress,
    toastMessage: 'Customer info was successfully updated.',
    onSave: async (address) => {
      await updateReturnOrderAddress(
        referenceID,
        RETURN_CUSTOMER_ADDRESS,
        address,
      )

      refreshReturnOrderRates(referenceID)
    },
  })
}
