import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Weight from '../../../../../../../common/components/Weight.js'
import {
  shippedTotalWeightSelector,
  isShippedMultiboxSelector,
} from '../../../../../../../data/orders.js'

import LabelItem from './LabelItem.js'

function ShippedWeight({weight, isMultibox}) {
  return (
    <LabelItem label="Weight">
      {isMultibox ? 'Multiple' : <Weight value={weight} />}
    </LabelItem>
  )
}

ShippedWeight.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  isMultibox: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    weight: shippedTotalWeightSelector(state, {orderNumber}),
    isMultibox: isShippedMultiboxSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(ShippedWeight)
