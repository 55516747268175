import {NO_WAREHOUSE_SELECTED} from '../../../../common/constants/CreateInventoryTransferModal.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../../redux/actions/ui/forms.js'

export const CREATE_INVENTORY_TRANSFER_MODAL = 'CREATE_INVENTORY_TRANSFER_MODAL'
export const CREATE_INVENTORY_TRANSFER = 'CREATE_INVENTORY_TRANSFER'
export const GET_PRODUCT_FOR_INVENTORY_TRANSFER_CREATE =
  'GET_PRODUCT_FOR_INVENTORY_TRANSFER_CREATE'
export const REMOVE_SKU_FROM_CREATE_INVENTORY_TRANSFER =
  'REMOVE_SKU_FROM_CREATE_INVENTORY_TRANSFER'

export function showCreateInventoryTransferModal(skus) {
  return setForm(CREATE_INVENTORY_TRANSFER_MODAL, {
    skus: skus ? skus : [],
    isSaving: false,
    serverError: null,
    sendingWarehouseID: NO_WAREHOUSE_SELECTED,
    receivingWarehouseID: NO_WAREHOUSE_SELECTED,
    afterAddingProduct: false,
  })
}

export function updateCreateInventoryTransferModalForm(updates) {
  return updateForm(CREATE_INVENTORY_TRANSFER_MODAL, updates)
}

export function formatCreateInventoryTransferModalRowFormID(sku) {
  return `${CREATE_INVENTORY_TRANSFER_MODAL}_ROW_${sku}`
}

export function closeCreateInventoryTransferModal() {
  return removeForm(CREATE_INVENTORY_TRANSFER_MODAL)
}

export function createInventoryTransfer() {
  return {
    type: CREATE_INVENTORY_TRANSFER,
  }
}

export function setCreateInventoryTransferModalRowForm(sku, form) {
  const formID = formatCreateInventoryTransferModalRowFormID(sku)

  return setForm(formID, form)
}

export function updateCreateInventoryTransferModalRowForm(sku, updates) {
  const formID = formatCreateInventoryTransferModalRowFormID(sku)

  return updateForm(formID, updates)
}

export function removeCreateInventoryTransferModalRowForm(sku) {
  const formID = formatCreateInventoryTransferModalRowFormID(sku)

  return removeForm(formID)
}

export function getProductForInventoryTransferCreate(sku) {
  return {
    type: GET_PRODUCT_FOR_INVENTORY_TRANSFER_CREATE,
    payload: {sku},
  }
}

export function removeSKUFromCreateInventoryTransfer(sku) {
  return {
    type: REMOVE_SKU_FROM_CREATE_INVENTORY_TRANSFER,
    payload: {sku},
  }
}
