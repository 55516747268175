import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import isValid from 'date-fns/isValid'
import format from 'date-fns/format'

export default function DateTimeInput({
  className,
  value,
  onDateChange,
  ...props
}) {
  const [date, setDate] = useState(null)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const propDate = new Date(value)

    setDate(propDate)
    setInputValue(isValid(propDate) ? format(propDate, 'P p') : '')
  }, [value])

  return (
    <input
      type="text"
      value={inputValue}
      onChange={(event) => {
        const inputDate = new Date(event.target.value)

        setDate(inputDate)
        setInputValue(event.target.value)
      }}
      onBlur={() => onDateChange(isValid(date) ? date.toISOString() : null)}
      className={`${date !== null && !isValid(date) ? 'input--error' : ''} ${
        className || ''
      }`}
      {...props}
    />
  )
}

DateTimeInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
}
