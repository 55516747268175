import PropTypes from 'prop-types'
import classNames from 'classnames'

import {AddressShape} from '../../PropTypes.js'
import RowAddress from '../List/RowAddress.js'
import {PRIVACY_MASK} from '../../analytics/index.js'

export default function Address({
  title,
  address,
  onEdit,
  showName = true,
  showAll = true,
  canEdit = true,
  noPhone,
  noEmail,
}) {
  const {name, company, street1, city, validation} = address || {}
  const noAddress = !company && !name && !city && !street1

  const validationStatus = validation && validation.status

  return (
    <dl
      className={classNames(
        `list list--order-data ${PRIVACY_MASK}`,
        validationStatus,
      )}
    >
      {title && <dt className="list__title--order-data">{title}</dt>}

      {noAddress && (
        <dd className="list__item--order-data">
          <em>No address specified</em>
        </dd>
      )}
      {showName && company && (
        <dd className="list__item--order-data">{company}</dd>
      )}
      {showName && name && <dd className="list__item--order-data">{name}</dd>}
      {city && street1 && showAll && (
        <dd className="list__item--order-data">
          <RowAddress address={address} noPhone={noPhone} noEmail={noEmail} />
        </dd>
      )}
      {canEdit && onEdit && (
        <dd className="list__item--order-data">
          <button className="btn btn--link" type="button" onClick={onEdit}>
            Edit
          </button>
        </dd>
      )}
    </dl>
  )
}

Address.propTypes = {
  title: PropTypes.string,
  address: AddressShape,
  onEdit: PropTypes.func,
  showName: PropTypes.bool,
  showAll: PropTypes.bool,
  canEdit: PropTypes.bool,
  noPhone: PropTypes.bool,
  noEmail: PropTypes.bool,
}
