import {onlyIfForm} from '../../../../store.js'
import useToggleTimer from '../../../../common/hooks/useToggleTimer.js'
import {
  NEW_GOODS_RECEIPT_ID,
  SCANNED_CODE_NOT_IN_GOODS_RECEIPT,
  SCANNED_CODE_IN_GOODS_RECEIPT,
} from '../../../../common/constants/GoodsReceiptModal.js'
import BarcodeScannerListener from '../../../../common/components/BarcodeScannerListener.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  GoodsReceiptModalFormShape,
  closeModal,
  modalFormSelector,
  setIncrementAmount,
  goodsReceiptBarcodeScanned,
  saveGoodsReceipt,
} from './goodsReceiptModalFunctions.js'
import GoodsReceiptLines from './GoodsReceiptLines.js'
import GoodsReceiptError from './GoodsReceiptError.js'
import FocusAlert from '../../../../common/components/FocusAlert.js'

function GoodsReceiptModal({form}) {
  const [animateSuccess, startSuccessAnimation] = useToggleTimer()

  return (
    <ConfirmModal
      className="modal--scan-mode meta-goods-receipt-modal"
      modalSize="lg"
      title={
        form.goodsReceiptID === NEW_GOODS_RECEIPT_ID
          ? 'Create a Goods Receipt'
          : `Editing Goods Receipt: ${form.goodsReceiptID}`
      }
      onConfirm={() => saveGoodsReceipt()}
      confirmText="Save"
      onCancel={() => closeModal()}
      cancelText="Cancel"
      helpLink="https://support.ordoro.com/how-to-use-barcode-scanning-to-reduce-packing-errors/"
      helpMetaClass="meta-goods-receipt-support-link"
      isSaving={form.isSaving}
      error={form.serverError}
      badgeAnimate={animateSuccess}
      badgeContent={
        form.scanResult === SCANNED_CODE_IN_GOODS_RECEIPT
          ? 'Scan Successful'
          : null
      }
      preventHotKeyConfirm
    >
      <FocusAlert>
        {form.scanResult === SCANNED_CODE_NOT_IN_GOODS_RECEIPT ? (
          <GoodsReceiptError>
            <strong>
              “{form.scannedCode}” isn’t associated with this purchase order.
            </strong>
          </GoodsReceiptError>
        ) : (
          <div>
            <BarcodeScannerListener
              onScan={(scannedCode) => {
                startSuccessAnimation()

                goodsReceiptBarcodeScanned(scannedCode)
              }}
              capturePaste
            />
            <div className="flex--justify margin-top-15 margin-bottom-25">
              <p className="fs-02 lh-md margin-bottom-0">
                <span>PO: </span>
                <strong>{form.poID}</strong>
              </p>
              <ul className="list list--no-style">
                <li
                  className="inline-block v-align-middle margin-bottom-0  tooltip--left"
                  data-hint="Set the multiplier for a single barcode scan. Default is 1."
                >
                  <span
                    className="text--md-grey i-scanner fs-02 lh-sm v-align-middle margin-right-3"
                    aria-hidden="true"
                  />
                  <span className="text--lt-grey fs-01 lh-sm margin-right-5">
                    x
                  </span>
                </li>
                <li className="inline-block margin-bottom-0">
                  <NumberInput
                    value={form.incrementAmount}
                    onChange={(value) => setIncrementAmount(value)}
                    onIncrement={(value) => setIncrementAmount(value)}
                    onDecrement={(value) => setIncrementAmount(value)}
                    onBlur={(value) => {
                      if (value === '' || value < 1) {
                        setIncrementAmount(1)
                      }
                    }}
                    min={1}
                  />
                </li>
              </ul>
            </div>
            <div className="padding-bottom-25">
              <GoodsReceiptLines form={form} />
            </div>
          </div>
        )}
      </FocusAlert>
    </ConfirmModal>
  )
}

GoodsReceiptModal.propTypes = {
  form: GoodsReceiptModalFormShape,
}

export default onlyIfForm(GoodsReceiptModal, modalFormSelector)
