import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {OrderLineShape, ProductShape} from '../../common/PropTypes.js'
import {PRODUCT_DETAIL_KITTING_PANEL} from '../../common/constants/Products.js'
import PopoverImage from '../../common/components/PopoverImage.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import {getProductDetailURL} from '../ProductDetailPage/productDetailSelectors.js'
import {
  toggleExpandedOrderLine,
  getProductKitGraph,
} from './orderDetailActions.js'
import {
  isLoadingExpandedLineSelector,
  isExpandedLineSelector,
  createLeafProductsSelector,
} from './orderDetailSelectors.js'
import {showImgsOrderDetailSelector} from '../../data/company.js'

function KitGraphLeaves({
  line,
  product,
  isLoading,
  isExpanded,
  leafProducts,
  showImgsOrderDetail,
}) {
  const needsLoading = !isLoading && isExpanded && leafProducts.length === 0

  useEffect(() => {
    if (needsLoading) {
      getProductKitGraph(product.sku)
    }
  }, [needsLoading])

  return (
    <div className="margin-top-3">
      <LinkButton
        className="label__callout label__callout--grey no-underline margin-right-3"
        href={getProductDetailURL(product.sku, PRODUCT_DETAIL_KITTING_PANEL)}
      >
        <strong>KIT</strong>
      </LinkButton>
      <ButtonLink
        className="no-underline inline-block v-align-middle"
        onClick={() => toggleExpandedOrderLine(line.id)}
      >
        <span
          className="i--expand fs-00 lh-0 v-align-middle"
          aria-hidden="true"
        />
      </ButtonLink>
      {(isExpanded && isLoading) || needsLoading ? (
        <div className="loading margin-top-10">
          <span className="spinner--sm v-align-middle" />
          <span className="inline-block v-align-middle fs-n1 op-75 margin-left-5">
            Loading...
          </span>
        </div>
      ) : isExpanded ? (
        <div className="margin-top-15">
          <p className="fs-n1 margin-bottom-10">
            <strong>Kit Components:</strong>
          </p>
          <ul
            className={classNames('list', {
              'list--square': !showImgsOrderDetail,
            })}
          >
            {leafProducts.map((leafProduct) => (
              <li
                className={classNames('list__item fs-n1 margin-bottom-10', {
                  flex: showImgsOrderDetail,
                })}
                key={leafProduct.sku}
              >
                {showImgsOrderDetail && (
                  <PopoverImage
                    src={leafProduct.image_url}
                    alt={leafProduct.name}
                  />
                )}
                <LinkButton
                  className={classNames('darker fs-n1', {
                    'v-align-top': showImgsOrderDetail,
                  })}
                  href={getProductDetailURL(leafProduct.sku)}
                >
                  {leafProduct.name} ({leafProduct.quantity * line.quantity})
                </LinkButton>
              </li>
            ))}
          </ul>
          <div>
            <ButtonLink
              className="no-underline"
              onClick={() => toggleExpandedOrderLine(line.id)}
              title="Hide kit components"
            >
              <span
                className="i--expand fs-00 lh-0 v-align-middle margin-right-3"
                aria-hidden="true"
              />
              <span className="fs-n1">Hide components</span>
            </ButtonLink>
          </div>
        </div>
      ) : null}
    </div>
  )
}

KitGraphLeaves.propTypes = {
  line: OrderLineShape.isRequired,
  product: ProductShape.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  leafProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showImgsOrderDetail: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {line, product}) {
  const leafProductsSelector = createLeafProductsSelector(product.sku)

  return {
    isLoading: isLoadingExpandedLineSelector(state, {orderLineID: line.id}),
    isExpanded: isExpandedLineSelector(state, {orderLineID: line.id}),
    leafProducts: leafProductsSelector(state),
    showImgsOrderDetail: showImgsOrderDetailSelector(state),
  }
}

export default connect(mapStateToProps)(KitGraphLeaves)
