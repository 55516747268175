import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {PACKING_LISTS} from '../../../common/constants/SettingsPanels.js'
import {sortedPackingListsByIDSelector} from '../../../redux/selectors/data/packingLists.js'
import {
  updateCurrentPackingList,
  setupForEdit,
  setupForAdding,
  savePackingList,
  handleLogoFile,
} from '../../../redux/actions/ui/settings/packingLists/index.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
  currentPackingListLogoURLSelector,
} from '../../../redux/selectors/ui/settings/packingLists.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {
  PackingListsShape,
  ErrorsShape,
  CurrentPackingListShape,
} from '../../../common/PropTypes.js'
import SettingsPanel from '../SettingsPanel.js'
import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export class PackingLists extends Component {
  static propTypes = {
    packingLists: PackingListsShape.isRequired,
    error: PropTypes.string.isRequired,
    editID: PropTypes.number,
    isNew: PropTypes.bool.isRequired,
    selectedProfileTab: PropTypes.string.isRequired,
    currentPackingList: CurrentPackingListShape.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    logoURL: PropTypes.string,
    errors: ErrorsShape.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    hasPermission: PropTypes.bool.isRequired,
    updateCurrentPackingList: PropTypes.func.isRequired,
    setupForEdit: PropTypes.func.isRequired,
    setupForAdding: PropTypes.func.isRequired,
    savePackingList: PropTypes.func.isRequired,
    handleLogoFile: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (this.props.editID) {
      this.props.setupForEdit()
    }

    if (this.props.isNew) {
      this.props.setupForAdding()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.editID && this.props.editID !== prevProps.editID) {
      this.props.setupForEdit()
    }

    if (this.props.isNew && this.props.isNew !== prevProps.isNew) {
      this.props.setupForAdding()
    }
  }

  renderEditPackingLists() {
    const {
      currentPackingList,
      isNew,
      selectedProfileTab,
      isSaving,
      isUploading,
      logoURL,
      errors,
      hasErrors,
    } = this.props

    return this.props.packingLists.map((packingList) => (
      <EditForm
        key={packingList.id}
        packingList={packingList}
        currentPackingList={currentPackingList}
        isNew={isNew}
        selectedProfileTab={selectedProfileTab}
        isSaving={isSaving}
        isUploading={isUploading}
        logoURL={logoURL}
        errors={errors}
        hasErrors={hasErrors}
        updateCurrentPackingList={this.props.updateCurrentPackingList}
        savePackingList={this.props.savePackingList}
        handleLogoFile={this.props.handleLogoFile}
      />
    ))
  }

  render() {
    const {
      error,
      currentPackingList,
      isNew,
      selectedProfileTab,
      isSaving,
      isUploading,
      logoURL,
      errors,
      hasErrors,
      hasPermission,
    } = this.props

    return (
      <SettingsPanel header="Profile Settings" hasPermission={hasPermission}>
        <div className="row">
          {error && <small className="error error-message">{error}</small>}
          <ul className="settings-list medium-12 columns">
            {this.renderEditPackingLists()}
            <NewForm
              currentPackingList={currentPackingList}
              isNew={isNew}
              selectedProfileTab={selectedProfileTab}
              isSaving={isSaving}
              isUploading={isUploading}
              logoURL={logoURL}
              errors={errors}
              hasErrors={hasErrors}
              updateCurrentPackingList={this.props.updateCurrentPackingList}
              savePackingList={this.props.savePackingList}
              handleLogoFile={this.props.handleLogoFile}
            />
          </ul>
        </div>
      </SettingsPanel>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    packingLists: sortedPackingListsByIDSelector(state, props),
    error: state.ui.settings.packingLists.error,
    editID: state.ui.settings.packingLists.editID,
    isNew: state.ui.settings.packingLists.isNew,
    selectedProfileTab: state.ui.settings.packingLists.selectedProfileTab,
    currentPackingList: state.ui.settings.packingLists.currentPackingList,
    isSaving: state.ui.settings.packingLists.isSaving,
    isUploading: state.ui.settings.packingLists.isUploading,
    logoURL: currentPackingListLogoURLSelector(state),
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
    hasPermission: settingsPermissionsSelector(state)[PACKING_LISTS],
  }
}

const mapDispatchToProps = {
  updateCurrentPackingList,
  setupForEdit,
  setupForAdding,
  savePackingList,
  handleLogoFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackingLists)
