import PropTypes from 'prop-types'

import apiverson from '../../common/apiverson.js'
import api from '../../common/api.js'
import {
  setOrderStateAndEnsureProductsLoaded,
  orderSelector,
} from '../../data/orders.js'
import {setDefaultShippingMethod} from '../OrderListPage/DropshipConfigForm/ManualDropshipForm/manualDropshipFormFunctions.js'
import {getState, setForm, updateForm, removeForm} from '../../store.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {
  ORDER_DETAIL_FORM,
  orderDetailFormSelector,
  orderDetailOrderNumberSelector,
  orderLineIDsBySKUSelector,
  getOrderDetailURL,
} from './orderDetailSelectors.js'
import {navigate} from '../../common/location.js'

export const OrderDetailFormShape = PropTypes.shape({
  isLoadingLines: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  expandedOrderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoadingExpandedByOrderLineID: PropTypes.objectOf(PropTypes.bool).isRequired,
  kitGraphBySKU: PropTypes.object.isRequired,
  selectedOrderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
})

export function setupOrderDetailForm() {
  setForm(ORDER_DETAIL_FORM, {
    isLoadingLines: false,
    notFound: false,
    currentTab: '',
    expandedOrderLineIDs: [],
    isLoadingExpandedByOrderLineID: {},
    kitGraphBySKU: {},
    selectedOrderLineIDs: [],
  })
}

export function updateOrderDetailForm(updates) {
  updateForm(ORDER_DETAIL_FORM, updates)
}

export function removeOrderDetailForm() {
  removeForm(ORDER_DETAIL_FORM)
}

export function navigateToOrderDetailPage(orderNumber) {
  return navigate(...getOrderDetailURL(orderNumber))
}

export async function loadOrderDetailOrder({reload} = {}) {
  let order

  const orderNumber = orderDetailOrderNumberSelector(getState())
  if (!orderNumber) {
    return
  }

  updateOrderDetailForm({notFound: false, isLoadingLines: true})

  order = orderSelector(getState(), {orderNumber})

  if (!order || reload) {
    try {
      const {json} = await apiverson.get(
        `/order/${encodeURIComponent(orderNumber)}`,
      )
      order = json
    } catch (err) {
      updateOrderDetailForm({notFound: true, isLoadingLines: false})

      return
    }
  }

  try {
    await setOrderStateAndEnsureProductsLoaded(order)

    setDefaultShippingMethod()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while loading order.',
        details: err.message,
      },
      err,
    )

    updateOrderDetailForm({notFound: true})
  } finally {
    updateOrderDetailForm({isLoadingLines: false})
  }
}

export function toggleExpandedOrderLine(orderLineID) {
  const {expandedOrderLineIDs} = orderDetailFormSelector(getState())

  if (expandedOrderLineIDs.includes(orderLineID)) {
    updateOrderDetailForm({
      expandedOrderLineIDs: expandedOrderLineIDs.filter(
        (id) => id !== orderLineID,
      ),
    })
  } else {
    updateOrderDetailForm({
      expandedOrderLineIDs: [...expandedOrderLineIDs, orderLineID],
    })
  }
}

export async function setIsLoadingExpanded(orderLineID, isLoading) {
  const form = orderDetailFormSelector(getState())

  if (!form) {
    return
  }

  const isLoadingExpandedByOrderLineID = {
    ...form.isLoadingExpandedByOrderLineID,
  }

  if (isLoading) {
    isLoadingExpandedByOrderLineID[orderLineID] = true
  } else {
    delete isLoadingExpandedByOrderLineID[orderLineID]
  }

  updateOrderDetailForm({
    isLoadingExpandedByOrderLineID,
  })
}

function setKitGraph(sku, kitGraph) {
  const form = orderDetailFormSelector(getState())

  if (!form) {
    return
  }

  updateOrderDetailForm({
    kitGraphBySKU: {...form.kitGraphBySKU, [sku]: kitGraph},
  })
}

export async function getProductKitGraph(sku) {
  const orderLineIDs = orderLineIDsBySKUSelector(getState(), {sku})

  for (const orderLineID of orderLineIDs) {
    setIsLoadingExpanded(orderLineID, true)
  }

  try {
    const {json} = await api.get(
      `/product/${encodeURIComponent(sku)}/kit_graph/`,
      {
        components_type: 'descendants_only',
      },
    )

    setKitGraph(sku, json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while request product kit graph.',
        details: err.error_message || err.message,
      },
      err,
    )
  } finally {
    for (const orderLineID of orderLineIDs) {
      setIsLoadingExpanded(orderLineID, false)
    }
  }
}

export function resetRowSelection(orderLineID) {
  const form = orderDetailFormSelector(getState())

  if (!form) {
    return
  }

  updateOrderDetailForm({
    selectedOrderLineIDs: form.selectedOrderLineIDs.filter(
      (id) => id !== orderLineID,
    ),
  })
}
