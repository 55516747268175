import {useMemo} from 'react'
import get from 'lodash/get.js'
import {useSelector} from '../../../store.js'
import ConfigSelect from './ConfigSelect.js'
import {
  labelShippersSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import {DEFAULT_DEPENDANT_PROPERTIES_FUNC} from './useLabelProperty.js'
import {DHL} from '../../../common/constants/ShipperNames.js'
import useStable from '../../../common/useStable.js'
import ShipperOptions from '../../../data/shipperOptions.js'
import {getShipperName} from '../../../data/shippers.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

function labelPropertiesFunc(shipperType) {
  return [`${shipperType}__shipping_method`]
}

export default function ShippingMethod() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const shippers = useStable(
    useSelector((state) => labelShippersSelector(state, {labelInfoID})),
  )

  const options = useMemo(() => {
    const options = []
    const multiShipper = shippers.length > 1

    for (const shipper of shippers) {
      const shipperType = shipper.vendor
      const shippingMethods = ShipperOptions[shipperType].shipping_method
      const shipperName = getShipperName(shipper)
      const hiddenShippingMethods =
        get(shipper, 'hidden_shipping_methods') || []

      options.push(
        ...shippingMethods
          .filter((option) => !hiddenShippingMethods.includes(option.value))
          .map((option) => ({
            ...option,
            display: `${multiShipper ? `${shipperName} - ` : ''}${
              option.display
            }${shipperType === DHL ? ` - ${option.value}` : ''}`,
          })),
      )
      // DHL has duplicate shipping method names so add in value
    }

    return options
  }, [shippers])

  if (!shipperType) {
    return null
  }

  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="Shipping Method"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      dependantPropertiesFunc={DEFAULT_DEPENDANT_PROPERTIES_FUNC}
      options={options}
    />
  )
}
