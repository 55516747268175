import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'

import {
  ErrorsShape,
  DataImportColumnsShape,
  DataImportColumnsToDataMapShape,
} from '../../../../common/PropTypes.js'
import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  const {columns, columnsToDataMap, columnErrors, updateColumnMapping} = props

  if (isEmpty(columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-12" {...props}>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.sku}
          errorMessage={columnErrors.sku}
          name="sku"
          label="SKU"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.cart}
          errorMessage={columnErrors.cart}
          name="cart"
          label="Sales Channel"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.max_export_qty}
          errorMessage={columnErrors.max_export_qty}
          name="max_export_qty"
          label="Max Export Quantity"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.min_export_qty}
          errorMessage={columnErrors.min_export_qty}
          name="min_export_qty"
          label="Min Export Quantity"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.percent_export_qty}
          errorMessage={columnErrors.percent_export_qty}
          name="percent_export_qty"
          label="Percent Export Quantity"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.reserve_export_qty}
          errorMessage={columnErrors.reserve_export_qty}
          name="reserve_export_qty"
          label="Reserve Export Quantity"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.fulfillment_channel}
          errorMessage={columnErrors.fulfillment_channel}
          name="fulfillment_channel"
          label="Amazon Fulfillment Channel"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.fulfillment_latency}
          errorMessage={columnErrors.fulfillment_latency}
          name="fulfillment_latency"
          label="Fulfillment Latency"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.sync}
          errorMessage={columnErrors.sync}
          name="sync"
          label="Writeback Inventory"
          onChange={updateColumnMapping}
        />
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: DataImportColumnsShape.isRequired,
  columnsToDataMap: DataImportColumnsToDataMapShape.isRequired,
  columnErrors: ErrorsShape.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
