import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import omit from 'lodash/omit.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  PluralBlock,
  Count,
  plural,
  IfPlural,
  IfSingle,
} from '../../../common/components/Plural.js'
import apiverson from '../../../common/apiverson.js'
import {isPresent} from '../../../common/utils.js'
import {ORDER_LIST_PAGE} from '../../../common/constants/Pages.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import BatchFilter from '../../../common/components/BatchFilter.js'
import {getRelatedBatches, setBatch} from '../../../data/batches.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {refreshOrderListAndCounts} from '../../OrderListPage/orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {updateOrder} from '../../../data/orders.js'
import {stringIt} from '../../../common/querystring.js'

const MODAL_FORM = 'ADD_TO_BATCH_MODAL'

export async function showAddToBatchModal({
  orderNumbers = [],
  referenceID = null,
  orderListQueryParams = null,
  orderListCount = 0,
  movingFromBatch = false,
}) {
  setForm(MODAL_FORM, {
    referenceID,
    orderNumbersInActiveBatches: [],
    orderNumbersInArchivedBatches: [],
    orderNumbersNotInBatches: [],
    orderListQueryParams:
      orderNumbers.length === 0 && orderListQueryParams
        ? omit(orderListQueryParams, ['limit', 'offset'])
        : null,
    orderListCount,
    movingFromBatch,
    isLoading: true,
    isSaving: false,
    serverError: null,
  })

  await checkOrderNumbers(orderNumbers)

  updateModalForm({isLoading: false})
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({
    referenceID,
    orderNumbersNotInBatches,
    orderNumbersInActiveBatches,
    orderListCount,
  }) => {
    const errors = {}

    if (!isPresent(referenceID)) {
      errors.referenceID = 'Batch ID is required'
      errors.preventSave = true
    }

    if (
      orderNumbersNotInBatches.length +
        orderNumbersInActiveBatches.length +
        orderListCount ===
      0
    ) {
      errors.preventSave = true
    }

    return errors
  },
)

export async function checkOrderNumbers(orderNumbers) {
  if (orderNumbers.length === 0) {
    return
  }

  try {
    const batchByOrderNumber = await getRelatedBatches(orderNumbers)

    const orderNumbersNotInBatches = []
    const orderNumbersInArchivedBatches = []
    const orderNumbersInActiveBatches = []
    for (const orderNumber of orderNumbers) {
      const batch = batchByOrderNumber[orderNumber]

      if (!batch) {
        orderNumbersNotInBatches.push(orderNumber)
      } else if (batch.archived_date) {
        orderNumbersInArchivedBatches.push(orderNumber)
      } else {
        orderNumbersInActiveBatches.push(orderNumber)
      }
    }

    updateModalForm({
      orderNumbersInArchivedBatches,
      orderNumbersNotInBatches,
      orderNumbersInActiveBatches,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
    })
  }
}

export async function addToBatch() {
  try {
    const {
      referenceID,
      orderNumbersNotInBatches,
      orderNumbersInActiveBatches,
      orderListQueryParams,
    } = modalFormSelector(getState())

    const orderNumbers = [
      ...orderNumbersNotInBatches,
      ...orderNumbersInActiveBatches,
    ]

    updateModalForm({isSaving: true, serverError: null})

    const {json} = await apiverson.put(
      `/batch/${encodeURIComponent(referenceID)}/orders`,
      orderListQueryParams
        ? {criteria: stringIt(orderListQueryParams)}
        : {order_numbers: orderNumbers},
    )

    setBatch(json)

    orderNumbers.map((orderNumber) =>
      updateOrder(orderNumber, {batch_reference_id: referenceID}),
    )

    showMessageToast(plural(orderNumbers)`Added order${['s']} to batch.`)

    closeModal()

    if (currentPageSelector(getState()) === ORDER_LIST_PAGE) {
      await refreshOrderListAndCounts()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error adding orders to batch: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function AddToBatchModal({form}) {
  const errors = useSelector(errorsSelector)
  const totalToBeAdded =
    form.orderNumbersInActiveBatches.length +
    form.orderNumbersNotInBatches.length +
    form.orderListCount
  const canAddToBatch = totalToBeAdded > 0
  const initialOrderCount =
    totalToBeAdded + form.orderNumbersInArchivedBatches.length

  return (
    <ConfirmModal
      title={plural(totalToBeAdded)`Add Order${['s']} to an Existing Batch`}
      modalSize="sm-md"
      onConfirm={canAddToBatch ? () => addToBatch() : null}
      onCancel={() => closeModal()}
      confirmText="Add to Batch"
      cancelText={canAddToBatch ? 'Cancel' : 'Close'}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave || form.isLoading}
      error={form.serverError}
    >
      {form.isLoading ? (
        <div className="align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle" />
        </div>
      ) : (
        <>
          {initialOrderCount > 0 &&
            (form.orderListQueryParams ? (
              <PluralBlock count={totalToBeAdded}>
                <div className="divider--bottom margin-bottom-20">
                  <p className="fs-01 lh-md margin-bottom-0">
                    <strong>
                      Let’s {form.movingFromBatch ? 'move' : 'add'} all filtered
                      orders (<Count />) to an existing batch.
                    </strong>
                  </p>
                  {form.movingFromBatch && (
                    <p className="fs-n1 lh-md margin-top-5 margin-bottom-0">
                      <em>
                        Please note that orders in an archived batch cannot be
                        moved.
                      </em>
                    </p>
                  )}
                </div>
              </PluralBlock>
            ) : (
              <div
                className={
                  canAddToBatch ? 'divider--bottom margin-bottom-30' : ''
                }
              >
                {canAddToBatch && (
                  <PluralBlock count={initialOrderCount}>
                    <p className="fs-01 lh-md margin-bottom-15">
                      <strong>
                        <IfPlural>
                          {totalToBeAdded} of the <Count /> selected orders
                        </IfPlural>
                        <IfSingle>The selected order</IfSingle> can be added to
                        the batch.
                      </strong>
                    </p>
                  </PluralBlock>
                )}
                <PluralBlock array={form.orderNumbersInArchivedBatches}>
                  {!canAddToBatch ? (
                    <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                      <span className="i--error fs-01 lh-sm margin-right-3 v-align-middle" />
                      <IfPlural>
                        All selected orders are assigned to archived batches
                      </IfPlural>
                      <IfSingle>
                        This order is assigned to an archived batch
                      </IfSingle>{' '}
                      and can’t be added to a different batch.
                    </p>
                  ) : form.orderNumbersInArchivedBatches.length ? (
                    <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                      <span className="i--error fs-01 lh-sm margin-right-3 v-align-middle" />
                      <IfPlural>
                        <Count /> orders are assigned to archived batches
                      </IfPlural>
                      <IfSingle>
                        1 order is assigned to an archived batch
                      </IfSingle>{' '}
                      and can’t be added to a different batch.
                    </p>
                  ) : null}
                </PluralBlock>
                {form.orderNumbersInActiveBatches.length > 0 && (
                  <PluralBlock array={form.orderNumbersInActiveBatches}>
                    <p className="fs-00 alert alert--warning margin-bottom-10">
                      <span className="i-exclamation-triangle text--warning-orange fs-01 lh-sm v-align-middle margin-right-5" />
                      <IfPlural>
                        <Count /> orders are assigned to other batches
                      </IfPlural>
                      <IfSingle>1 order is assigned to another batch</IfSingle>{' '}
                      and will be reassigned to the batch you select.
                    </p>
                  </PluralBlock>
                )}
              </div>
            ))}
          {canAddToBatch && (
            <div className="row">
              <div className="medium-9 columns">
                <PluralBlock count={totalToBeAdded}>
                  <p className="fs-00 margin-bottom-10">
                    <strong>Which batch do you want them to go in?</strong>
                  </p>
                  {form.referenceID ? (
                    <>
                      <p className="fs-01 margin-bottom-0">
                        <strong>{form.referenceID}</strong>
                      </p>
                      <ButtonLink
                        className="fs-00"
                        id="ADD_TO_BATCH_BATCH_FILTER__input"
                        onClick={() => updateModalForm({referenceID: null})}
                      >
                        Select a different batch
                      </ButtonLink>
                    </>
                  ) : (
                    <>
                      <BatchFilter
                        dropdown="ADD_TO_BATCH_BATCH_FILTER"
                        onSelect={(batch) => {
                          updateModalForm({referenceID: batch.reference_id})
                        }}
                        autoFocus
                      />
                    </>
                  )}
                </PluralBlock>
              </div>
            </div>
          )}
        </>
      )}
    </ConfirmModal>
  )
}

AddToBatchModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string,
    orderNumbersInActiveBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    orderNumbersInArchivedBatches: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    orderNumbersNotInBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    orderListQueryParams: PropTypes.object,
    orderListCount: PropTypes.number.isRequired,
    movingFromBatch: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(AddToBatchModal, modalFormSelector)
