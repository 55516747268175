import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {usePresetsSelector} from '../../../../data/company.js'

import BulkDimensions from './BulkDimensions.js'
import BulkShippingConfiguration from './BulkShippingConfiguration/index.js'
import BulkWeight from './BulkWeight.js'
import BulkPresets from './BulkPresets/index.js'
import BulkPackingLists from './BulkPackingLists.js'
import BulkShipFrom from './BulkShipFrom.js'
import BulkShipDate from './BulkShipDate.js'
import LabelConfigContext from '../../LabelConfigContext.js'
import {
  BULK_LABEL_ID,
  labelShipperTypeSelector,
} from '../../../../data/labelInfos/index.js'

export default function BulkUpdateForm({labelInfoIDs}) {
  const labelInfoID = BULK_LABEL_ID
  const usePresets = useSelector(usePresetsSelector)
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )

  return (
    <LabelConfigContext.Provider value={{labelInfoID, shipperType}}>
      <fieldset className="fieldset--shipping-options no-border padding-top-15">
        <dl className="list">
          <dt className="list__title fs-n0 margin-bottom-10">
            Bulk Update Options
          </dt>
          {usePresets && <BulkPresets labelInfoIDs={labelInfoIDs} />}
          <BulkShippingConfiguration labelInfoIDs={labelInfoIDs} />
          <BulkShipFrom labelInfoIDs={labelInfoIDs} />
          <BulkWeight labelInfoIDs={labelInfoIDs} />
          <BulkDimensions labelInfoIDs={labelInfoIDs} />
          <BulkShipDate labelInfoIDs={labelInfoIDs} />
          <BulkPackingLists labelInfoIDs={labelInfoIDs} />
        </dl>
      </fieldset>
    </LabelConfigContext.Provider>
  )
}

BulkUpdateForm.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
