import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../store.js'
import api from '../../common/api.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  hasTrialIMMSelector,
  newPlanSelector,
  planSelector,
  sourceSelector,
  usesInventorySelector,
  enableCompanyFeature,
} from '../../data/company.js'
import {showCreditCardModal} from './CreditCardModal.js'
import {
  ADVANCED_PLAN,
  ESSENTIALS_PLAN,
  EXPRESS_PLAN,
  PLAN_NAME_LOOKUP,
  PREMIUM_PLAN,
  PRO_PLAN,
  STARTER_PLAN,
} from '../../common/constants/Plans.js'
import {needsCreditCardSelector} from '../../data/creditCard.js'
import {warehousesSortedByNameSelector} from '../../data/warehouses.js'
import {cartsSortedByNameSelector} from '../../data/carts.js'
import {activeUsersSortedByNameSelector} from '../../data/users.js'
import {showMessageToast} from '../Header/Toast/index.js'
import analytics from '../../common/analytics/index.js'
import {showCartSyncModal} from './CartSyncModal/cartSyncModalFunctions.js'
import {showFeatureLockedModal} from './FeatureLockedModal.js'
import {join} from '../../common/components/Join.js'

const MODAL_FORM = 'OLD_UPGRADE_MODAL'

export async function showOldUpgradeModal() {
  const usesInventory = usesInventorySelector(getState())

  setForm(MODAL_FORM, {
    new_plan: newPlanSelector(getState()),
    enable_imm: usesInventory,
    serverError: null,
    isSaving: false,
  })

  await analytics.trackEvent('upgrade_modal')
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function tooMuchSetupSelector(state) {
  const {new_plan} = modalFormSelector(state)
  const currentNewPlan = planSelector(state)
  let listOfThings = null

  if (currentNewPlan !== ESSENTIALS_PLAN && new_plan === ESSENTIALS_PLAN) {
    const warehouseCount = warehousesSortedByNameSelector(getState()).length
    const cartCount = cartsSortedByNameSelector(getState()).length
    const userCount = activeUsersSortedByNameSelector(getState()).length
    const list = []

    if (warehouseCount > 1) {
      list.push('warehouses')
    }

    // Manual cart + some other cart
    if (cartCount > 2) {
      list.push('carts')
    }

    if (userCount > 1) {
      list.push('users')
    }

    if (list.length) {
      listOfThings = list[0]

      if (list.length === 2) {
        listOfThings += ` and ${list[1]}`
      } else if (list.length === 3) {
        listOfThings += `, ${list[1]} and ${list[2]}`
      }
    }
  }

  return listOfThings
}

export function shouldTalkToSomebodySelector(state) {
  const {new_plan, enable_imm} = modalFormSelector(state)
  const currentNewPlan = newPlanSelector(state)
  const tooMuchSetup = tooMuchSetupSelector(state)
  const usesInventory = usesInventorySelector(getState())

  // if not changing plan then no
  if (currentNewPlan === new_plan) {
    return false
  }

  // if not changing IMM
  if (enable_imm === usesInventory) {
    return false
  }

  // if they have too much setup then yes
  if (tooMuchSetup) {
    return true
  }

  // otherwise no
  return false
}

export function directToCreditCardModalSelector(state) {
  const {new_plan} = modalFormSelector(state)
  const currentNewPlan = newPlanSelector(state)
  const needsCreditCard = needsCreditCardSelector(state)

  // if not changing a free plan then no
  if (currentNewPlan === new_plan && currentNewPlan === ESSENTIALS_PLAN) {
    return false
  }

  // if wanting to upgrade to/from free then no
  if (currentNewPlan === ESSENTIALS_PLAN || new_plan === ESSENTIALS_PLAN) {
    return false
  }

  return needsCreditCard
}

export async function confirm() {
  const currentPlan = planSelector(getState())
  const currentNewPlan = newPlanSelector(getState())
  const usesInventory = usesInventorySelector(getState())
  const {new_plan, enable_imm} = modalFormSelector(getState())
  const shouldTalkToSomebody = shouldTalkToSomebodySelector(getState())
  const directToCreditCardModal = directToCreditCardModalSelector(getState())
  const shouldEnableIMM = usesInventory !== enable_imm
  const plan =
    new_plan === ESSENTIALS_PLAN
      ? STARTER_PLAN
      : enable_imm
        ? PRO_PLAN
        : EXPRESS_PLAN
  const shouldChangePlan = currentPlan !== plan
  const shouldChangeNewPlan = currentNewPlan !== new_plan

  if (shouldTalkToSomebody) {
    window.open('https://www.ordoro.com/product-expert-consultation', '_blank')

    closeModal()
  } else if (directToCreditCardModal) {
    showCreditCardModal()
  } else if (shouldChangePlan || shouldEnableIMM || shouldChangeNewPlan) {
    try {
      updateModalForm({serverError: null, isSaving: true})
      const toastParts = []

      if (shouldChangePlan) {
        await api.post('/company/plan/', {
          plan,
        })
      }

      if (shouldChangeNewPlan) {
        const source = sourceSelector(getState())

        await api.put('/company/', {
          source: JSON.stringify({...source, new_plan}),
        })

        toastParts.push(`switched to the ${PLAN_NAME_LOOKUP[new_plan]} Plan`)
      }

      if (shouldEnableIMM) {
        if (enable_imm) {
          await enableCompanyFeature('disable_inventory_writeback')
        }

        toastParts.push(
          `${
            enable_imm ? 'enabled' : 'disabled'
          } the Inventory Management Module`,
        )
      }

      closeModal()

      showMessageToast(`You’ve ${join(toastParts)}`)
    } catch (err) {
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      })
    }
  } else {
    closeModal()
  }
}

function OldUpgradeModal({form}) {
  const newPlan = useSelector(newPlanSelector)
  const shouldTalkToSomebody = useSelector(shouldTalkToSomebodySelector)
  const directToCreditCardModal = useSelector(directToCreditCardModalSelector)
  const tooMuchSetup = useSelector(tooMuchSetupSelector)
  const usesInventory = useSelector(usesInventorySelector)
  const hasTrialIMM = useSelector(hasTrialIMMSelector)

  const shouldChangeNewPlan = newPlan !== form.new_plan
  const shouldEnableIMM = usesInventory !== form.enable_imm

  return (
    <ConfirmModal
      title="Select a Plan"
      modalSize="x-lg"
      onConfirm={
        shouldTalkToSomebody ||
        shouldChangeNewPlan ||
        directToCreditCardModal ||
        shouldEnableIMM
          ? () => confirm()
          : null
      }
      onCancel={() => closeModal()}
      confirmText={
        shouldTalkToSomebody
          ? 'Talk to a Product Expert'
          : directToCreditCardModal
            ? 'Add a Credit Card'
            : shouldChangeNewPlan
              ? 'Switch Plan'
              : shouldEnableIMM
                ? `${form.enable_imm ? 'Enable' : 'Disable'} Module`
                : 'OK'
      }
      error={form.serverError}
      LowerNotice={
        shouldEnableIMM
          ? () => (
              <div className="wrap--row">
                <div className="medium-12 columns">
                  <div className="alert alert--warning margin-top-0 margin-bottom-5">
                    <strong>
                      {form.enable_imm === false
                        ? 'The Inventory Management Module will be disabled for your account after you confirm and the window closes.'
                        : 'The Inventory Management Module will be enabled for your account after you confirm below. Your subscription fee will now start at $499 per month.'}
                    </strong>
                  </div>
                </div>
              </div>
            )
          : null
      }
      isSaving={form.isSaving}
      cancelText="Close"
    >
      <div className="row flex margin-top-25">
        <div className="medium-3 columns">
          <dl
            className={`list--pricing-summary list--pricing-summary-starter w-100 margin-bottom-40 ${
              form.new_plan === ESSENTIALS_PLAN ? 'plan-selected' : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-starter align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ESSENTIALS
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <strong className="fs-05 lh-sm">FREE</strong>
              </div>
            </dd>
            {!shouldChangeNewPlan ||
            form.new_plan !== ESSENTIALS_PLAN ? null : tooMuchSetup ? (
              <dd className="list__item--pricing-summary flex">
                <div className="alert alert--warning margin-bottom-5">
                  <p className="fs-n0 lh-md margin-bottom-0">
                    Your account cannot be downgraded to the Essentials plan
                    because you set up too many {tooMuchSetup}. Talk to a
                    Product Expert for help.
                  </p>
                </div>
              </dd>
            ) : (
              <dd className="list__item--pricing-summary flex">
                <div className="alert alert--warning margin-bottom-5">
                  <p className="fs-n0 lh-md margin-bottom-0">
                    By switching to Essentials, you will be losing features like
                    unlimited users, unlimited sales channels and unlimited
                    ship-from locations. Are you sure you want to downgrade?
                  </p>
                </div>
              </dd>
            )}
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Shipping Labels</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Lowest Shipping Rates from Top Carriers
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">1 User</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">1 Sales Channel</strong>
            </dd>
            <dd className="list__item--pricing-summary flex ">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">1 Ship From Location</strong>
            </dd>

            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Email Support</strong>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100"
                size="sm"
                alt
                disabled={form.new_plan === ESSENTIALS_PLAN}
                onClick={() =>
                  updateModalForm({
                    new_plan: ESSENTIALS_PLAN,
                    enable_imm: false,
                  })
                }
              >
                {form.new_plan === ESSENTIALS_PLAN ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Essentials Plan'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
        <div className="medium-3 columns">
          <dl
            className={`list--pricing-summary list--pricing-summary-express w-100 margin-bottom-40 ${
              form.new_plan === ADVANCED_PLAN ? 'plan-selected' : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-express align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                ADVANCED
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">59</strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>
            {form.new_plan === ADVANCED_PLAN && directToCreditCardModal && (
              <dd className="list__item--pricing-summary flex">
                <div className="alert alert--warning margin-bottom-5">
                  <p className="fs-n0 lh-md margin-bottom-0">
                    Add a credit card to lock in your Advanced plan.
                  </p>
                </div>
              </dd>
            )}
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Shipping Labels</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Lowest Shipping Rates from Top Carriers
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Users</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Sales Channels</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Unlimited Ship-From Locations
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Email Support</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Phone Support</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Automation Rules and Presets
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Advanced Analytics</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Add-On Module Access: Merchant Carrier Module*
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100"
                size="sm"
                alt
                disabled={form.new_plan === ADVANCED_PLAN}
                onClick={() =>
                  updateModalForm({new_plan: ADVANCED_PLAN, enable_imm: false})
                }
              >
                {form.new_plan === ADVANCED_PLAN ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Advanced Plan'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
        <div className="medium-3 columns">
          <dl
            className={`list--pricing-summary list--pricing-summary-enterprise w-100 margin-bottom-40 ${
              form.new_plan === PREMIUM_PLAN ? 'plan-selected' : ''
            }`}
          >
            <dt className="list__title--pricing-summary list__title--pricing-summary-enterprise align-center">
              <span className="header--pricing-summary fs-01 lh-md">
                PREMIUM
              </span>
            </dt>
            <dd className="list__item--pricing-summary align-center">
              <div className="wrap--express-price-text">
                <span className="unbold fs-02 v-align-top lh-lg">$</span>
                <strong className="fs-05 lh-sm">149</strong>
                <span className="fs-01 unbold">/mo</span>
              </div>
            </dd>

            {form.new_plan === PREMIUM_PLAN && directToCreditCardModal && (
              <dd className="list__item--pricing-summary flex">
                <div className="alert alert--warning margin-bottom-5">
                  <p className="fs-n0 lh-md margin-bottom-0">
                    Add a credit card to lock in your Premium plan.
                  </p>
                </div>
              </dd>
            )}
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Shipping Labels</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Lowest Shipping Rates from Top Carriers
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Users</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Unlimited Sales Channels</strong>
            </dd>
            <dd className="list__item--pricing-summary flex ">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Unlimited Ship-From Locations
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Email Support</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Phone Support</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Automation Rules and Presets
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">Advanced Analytics</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">
                Add-On Module Access: All Modules*
              </strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">QuickBooks Integration</strong>
            </dd>
            <dd className="list__item--pricing-summary flex">
              <span
                className="i-check fs-01 lh-lg v-align-middle margin-right-7"
                aria-hidden="true"
              ></span>
              <strong className="fs-00 lh-md">API Access</strong>
            </dd>
            <dd className="list__item--pricing-summary flex margin-top-10 margin-bottom-15">
              <ButtonPrimary
                className="fs-00 w-100"
                size="sm"
                alt
                disabled={form.new_plan === PREMIUM_PLAN}
                onClick={() =>
                  updateModalForm({
                    new_plan: PREMIUM_PLAN,
                    enable_imm: usesInventory ? true : form.enable_imm,
                  })
                }
              >
                {form.new_plan === PREMIUM_PLAN ? (
                  <span
                    className="i-check fs-01 lh-0 i--v-align"
                    aria-hidden="true"
                  />
                ) : (
                  'Select Premium Plan'
                )}
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
        <div className="medium-3 columns divider--left lg">
          <dl className="list">
            <dt className="list__title fs-01 margin-bottom-10">
              Add-On Modules
            </dt>
            <dd className="list__item fs-00 margin-bottom-15">
              Looking for even more features? Learn about our various add-on
              modules.
            </dd>
            <dd className="list__item">
              <ul className="list list--square">
                <li className="list__item fs-00">
                  <strong>Merchant Carrier Module</strong>
                </li>

                <li className="list__item fs-00">
                  <strong>Inventory Management Module</strong>

                  <div>
                    {form.enable_imm ? (
                      <>
                        <strong className="label__callout label__callout--green v-align-middle margin-right-5">
                          Enabled
                        </strong>
                        <ButtonLink
                          className="fs-n1"
                          onClick={() =>
                            updateModalForm({
                              enable_imm: false,
                            })
                          }
                        >
                          <span className="v-align-middle">Remove</span>
                        </ButtonLink>
                        {hasTrialIMM && (
                          <div className="alert alert--warning alert--sm margin-top-5 margin-bottom-10">
                            <ButtonLink
                              className="mid lh-sm v-align-middle meta-Writeback-Disabled_Upgrade-Modal"
                              onClick={() => {
                                closeModal()

                                showCartSyncModal()
                              }}
                            >
                              <strong className="fs-n2 lh-sm">
                                Ready to turn on Inventory Writeback for your
                                account?
                              </strong>
                            </ButtonLink>
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <ButtonPrimary
                          isOutlined
                          size="xx-sm"
                          onClick={() =>
                            updateModalForm({
                              new_plan: PREMIUM_PLAN,
                              enable_imm: true,
                            })
                          }
                        >
                          <span className="v-align-middle">Enable</span>
                        </ButtonPrimary>
                      </div>
                    )}
                  </div>
                </li>

                <li className="list__item fs-00">
                  <strong>Vendor Portal Module</strong>
                </li>
                <li className="list__item fs-00">
                  <strong>Customizations</strong>
                </li>
              </ul>
            </dd>

            <dd className="list__item margin-top-20 margin-bottom-0">
              <ButtonPrimary
                isOutlined
                size="sm"
                className="fs-00 w-100"
                onClick={() => {
                  closeModal()

                  showFeatureLockedModal()
                }}
              >
                Talk to an Expert
              </ButtonPrimary>
            </dd>
          </dl>
        </div>
      </div>
    </ConfirmModal>
  )
}

OldUpgradeModal.propTypes = {
  form: PropTypes.shape({
    new_plan: PropTypes.string.isRequired,
    enable_imm: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(OldUpgradeModal, modalFormSelector)
