import {FEDEX_SHIPPER_DEFAULT_HUB} from '../../../../common/constants/LabelConfig.js'
import {
  updateLabelConfig,
  labelShippersSelector,
} from '../../../../data/labelInfos/index.js'
import {defaultFedExSmartPostHubSelector} from '../../../../data/shippers.js'
import ConfigSelect from '../ConfigSelect.js'
import {useSelector} from '../../../../store.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'
import ShipperOptions from '../../../../data/shipperOptions.js'

const VALID_SHIPPER_TYPES = [FEDEX]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__smart_post_hub`]
    : []
}

export function defaultSmartPostHubsSelector(
  state,
  {labelInfoID, shipperType},
) {
  const labelShippers = labelShippersSelector(state, {labelInfoID})
  const shippersOfShipperType = labelShippers.filter(
    ({vendor}) => vendor === shipperType,
  )
  // get a list of hubs (including not set) that all the shippers use
  const defaultSmartPostHubs = shippersOfShipperType
    .map((shipper) =>
      defaultFedExSmartPostHubSelector(state, {shipperID: shipper.id}),
    )
    // not filtering out unset hubs
    .reduce((prev, v) => {
      // dedupe list
      if (!prev.includes(v)) {
        prev.push(v)
      }

      return prev
    }, [])

  return defaultSmartPostHubs
}

export default function SmartPostHub() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const defaultSmartPostHubs = useSelector((state) =>
    defaultSmartPostHubsSelector(state, {labelInfoID, shipperType}),
  )
  const hubs = ShipperOptions[shipperType].hub || []

  const defaultSmartPostHubDisplay =
    defaultSmartPostHubs.length === 1
      ? (
          hubs.find(({value}) => defaultSmartPostHubs.includes(value)) || {
            display: 'No Hub',
          }
        ).display
      : 'Multiple Hubs'
  const options = [
    {
      value: FEDEX_SHIPPER_DEFAULT_HUB,
      display: `Shipper Default (${defaultSmartPostHubDisplay})`,
    },
    ...hubs,
  ]

  return (
    <ConfigSelect
      label="FedEx Ground® Economy Hub"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      options={options}
    />
  )
}
