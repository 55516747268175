import range from 'lodash/range.js'

export function currentPageNumberSelector(state, {offset, limit}) {
  return Math.floor(offset / limit) + 1
}

export function pageNumbersSelector(state, {limit, count, offset}) {
  const currentPageNumber = currentPageNumberSelector(state, {offset, limit})
  const lastPage = Math.ceil(count / limit)

  // If you have more than 10 pages, things get a little crazy
  // 1 2 3 4 5 ... 10 (If page 1-4 is active)
  // 1 ... 4 5 6 ... 10 (If page 5 is selected)
  // 1 ... 6 7 8 9 10 (If page 6-10 is active)
  if (lastPage >= 10) {
    const firstPageCutoff = 5
    const firstPages =
      currentPageNumber < firstPageCutoff
        ? range(1, firstPageCutoff + 1).concat(['…'])
        : [1]
    const lastPageCutoff = lastPage - 4
    const lastPages =
      currentPageNumber > lastPageCutoff
        ? ['…'].concat(range(lastPageCutoff, lastPage + 1))
        : [lastPage]
    let middlePages = []

    if (
      currentPageNumber >= firstPageCutoff &&
      currentPageNumber <= lastPageCutoff
    ) {
      middlePages = [
        '…',
        currentPageNumber - 1,
        currentPageNumber,
        currentPageNumber + 1,
        '…',
      ]
    }

    return firstPages.concat(middlePages, lastPages)
  }

  return range(1, lastPage + 1)
}
