import {openModal} from '../../ui/index.js'

export const BASE = 'ordoro/ui/modal/editInstructionsModal'
export const EDIT_INSTRUCTIONS = `${BASE}/EDIT_INSTRUCTIONS`
export const SET_INSTRUCTIONS = `${BASE}/SET_INSTRUCTIONS`
export const SET_IS_SAVING = `${BASE}/SET_IS_SAVING`
export const SET_SERVER_ERROR = `${BASE}/SET_SERVER_ERROR`

export const MODAL_NAME = 'EDIT_INSTRUCTIONS_MODAL'

export function showEditInstructionsModal(poID, instructions) {
  return openModal(MODAL_NAME, {poID, instructions})
}

export function editInstructions() {
  return {
    type: EDIT_INSTRUCTIONS,
  }
}

export function setInstructions(instructions) {
  return {
    type: SET_INSTRUCTIONS,
    payload: instructions,
  }
}

export function setIsSaving(isSaving) {
  return {
    type: SET_IS_SAVING,
    payload: isSaving,
  }
}

export function setServerError(serverError) {
  return {
    type: SET_SERVER_ERROR,
    payload: serverError,
  }
}
