import loadScript from '../common/loadScript.js'
import {STRIPE_PUBLIC_KEY} from '../common/constants/index.js'

export default function Stripe(options) {
  if (!window.Stripe || !window.Stripe.StripeV3) {
    throw new Error('Need to init Stripe before using it')
  }

  return window.Stripe.StripeV3(STRIPE_PUBLIC_KEY, options)
}

export function init() {
  return loadScript('https://js.stripe.com/v3/')
}
