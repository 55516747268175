import PropTypes from 'prop-types'

import {ORDORO_VENDOR_CONNECT} from '../../constants/CartVendorOptions.js'
import Checkbox from '../Form/Checkbox.js'
import {
  orderCartTypeSelector,
  canNotifyCartAboutTrackingDeletesSelector,
} from '../../../data/orders.js'
import {useSelector} from '../../../store.js'

export default function NotifyCartAboutTrackingDeleteCheckbox({
  orderNumbers,
  labelType,
  ...props
}) {
  const allAreVendorConnect = useSelector((state) =>
    orderNumbers.reduce((prev, orderNumber) => {
      if (prev === false) {
        return false
      }

      const vendor = orderCartTypeSelector(state, {orderNumber})

      return ORDORO_VENDOR_CONNECT === vendor
    }, true),
  )
  const canNotifyCart = useSelector((state) =>
    canNotifyCartAboutTrackingDeletesSelector(state, {
      orderNumbers,
      labelType,
    }),
  )

  if (!canNotifyCart) {
    return null
  }

  return (
    <div className="margin-top-10">
      <Checkbox
        className="wrap--label-unbold margin-left-n03"
        name="notify_cart"
        label={
          allAreVendorConnect
            ? 'Notify vendor partner of this change'
            : 'Notify sales channel of this change'
        }
        {...props}
      />
    </div>
  )
}

NotifyCartAboutTrackingDeleteCheckbox.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelType: PropTypes.string,
}
