import PropTypes from 'prop-types'
import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'
import {UserFormShape, ErrorsShape} from '../../../common/PropTypes.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import {useSelector} from '../../../store.js'
import {userCountSelector} from '../../../data/users.js'
import {maxUserCountSelector} from '../../../data/company.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'

export default function NewForm({
  generalError,
  userForm: {name, email},
  isNew,
  isSaving,
  errors,
  hasErrors,
  saveUser,
  updateUserForm,
}) {
  const userCount = useSelector(userCountSelector)
  const maxUserCount = useSelector(maxUserCountSelector)

  const canHaveMoreUsers = userCount < maxUserCount

  return (
    <li className="settings-list-item add-item clearfix expanded">
      <div className="medium-12 columns button-wrap">
        {canHaveMoreUsers ? (
          <p>
            <a
              href={`#/settings/user/${NEW_ID}`}
              title="Add a user"
              className="inline-text-button no-underline"
            >
              <span
                className="icon icon-add icon-block x-sm margin-right-5"
                aria-hidden="true"
              />
              <span>Add a user</span>
            </a>
          </p>
        ) : (
          <div className="margin-top-15 align-center">
            <p className="margin-bottom-10">
              <strong>
                <PluralBlock count={maxUserCount}>
                  Want to add more than <Count options={{toEnglish: true}} />{' '}
                  <Plural word="user" />?
                </PluralBlock>
              </strong>
            </p>
            <button
              className="btn btn--primary btn--primary-alt btn--md fs-01"
              onClick={() => showUpgradeModal()}
            >
              <strong>Upgrade Your Plan</strong>
            </button>
          </div>
        )}
      </div>
      {isNew && canHaveMoreUsers && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {generalError && (
              <div className="row">
                <ul className="medium-5 columns error-list">
                  <li>{generalError}</li>
                </ul>
              </div>
            )}
            <ul className="form-list medium-5 columns margin-left-0">
              <li
                className={classNames(
                  'form-list-item bigger margin-bottom-20',
                  {error: errors.name},
                )}
              >
                <label htmlFor="user_name">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="user_name"
                  value={name}
                  onChange={(event) =>
                    updateUserForm({name: event.target.value})
                  }
                />
                {errors.name && (
                  <small className="error error-message">{errors.name}</small>
                )}
              </li>
              <li
                className={classNames(
                  'form-list-item bigger margin-bottom-20',
                  {error: errors.email},
                )}
              >
                <label htmlFor="user_email">
                  Email<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="user_email"
                  value={email}
                  onChange={(event) =>
                    updateUserForm({email: event.target.value})
                  }
                />
                {errors.email && (
                  <small className="error error-message">{errors.email}</small>
                )}
              </li>
              {!hasErrors && (
                <li className="list__item list__item--no-style margin-bottom-20">
                  <div className="alert alert--standard">
                    <span className="fs-n0 lh-sm">
                      After you create this user, an email will be sent to them
                      with instructions for setting a password for their
                      account.
                    </span>
                  </div>
                </li>
              )}

              <li className="list__item list__item--no-style">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': hasErrors,
                    'btn--processing': isSaving,
                  })}
                  disabled={hasErrors || isSaving}
                  onClick={saveUser}
                >
                  Create New User
                </button>
                <a
                  href="#/settings/user"
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </li>
            </ul>
          </fieldset>
        </form>
      )}
    </li>
  )
}

NewForm.propTypes = {
  generalError: PropTypes.string.isRequired,
  userForm: UserFormShape.isRequired,
  isNew: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateUserForm: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
}
