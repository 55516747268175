import {all, takeEvery, put, select, call} from 'redux-saga/effects'

import api from '../../../../../common/api.js'
import {NEW_ID} from '../../../../../common/constants/index.js'
import {
  shippersSortedByIDSelector,
  shipperSelector,
} from '../../../../../data/shippers.js'
import {checkRunningTasks} from '../../../../actions/data/isRunningTasks.js'
import {closeModal} from '../../../../actions/ui/index.js'
import {updateForm} from '../../../../actions/ui/forms.js'
import {showMessageToast} from '../../../../../ordoro/Header/Toast/index.js'
import {
  SETUP_FORMS,
  SETUP_FORM,
  SET_NEW_SHIPPER_VENDOR,
  EXPORT_USPS_TRANSACTIONS_MODAL,
  EXPORT_USPS_TRANSACTIONS,
  setForms,
  setForm,
} from '../../../../actions/ui/settings/shippers/index.js'
import {exportUSPSTransactionsTaskParamsSelector} from '../../../../selectors/ui/settings/shippers/exportUSPSTransactionsModal.js'
import formsSelectors from '../../../../selectors/ui/settings/shippers/forms/index.js'
import amazonSFP from './forms/amazonSFP.js'
import amazonShipper from './forms/amazonShipper.js'
import australiaPost from './forms/australiaPost.js'
import canadaPost from './forms/canadaPost.js'
import endicia from './forms/endicia.js'
import dhl from './forms/dhl.js'
import newgistics from './forms/newgistics.js'
import pitney from './forms/pitney.js'
import pitneyPresort from './forms/pitneyPresort.js'
import visibleUSPS from './forms/visibleUSPS.js'
import xDelivery from './forms/xDelivery.js'

export function* setupFormsWorker() {
  const shippers = yield select(shippersSortedByIDSelector)

  const forms = {}
  for (const shipper of shippers) {
    if (!formsSelectors[shipper.vendor]) {
      continue
    }

    const form = yield select(
      formsSelectors[shipper.vendor].setupFormSelector,
      {shipper},
    )

    forms[form.id] = form
  }

  yield put(setForms(forms))
}

export function* setupNewShipperVendorWorker({payload: vendor}) {
  const shipper = {id: NEW_ID, vendor}
  const form = yield select(formsSelectors[vendor].setupFormSelector, {shipper})

  yield put(setForm(NEW_ID, form))
}

export function* setupFormWorker({payload: {shipperID}}) {
  const shipper = yield select(shipperSelector, {shipperID})

  if (!shipper) {
    return
  }

  const form = yield select(formsSelectors[shipper.vendor].setupFormSelector, {
    shipper,
  })

  yield put(setForm(shipperID, form))
}

export function* exportUSPSTransactionsWorker() {
  try {
    const params = yield select(exportUSPSTransactionsTaskParamsSelector)

    yield put(
      updateForm(EXPORT_USPS_TRANSACTIONS_MODAL, {
        isRequesting: true,
        serverError: null,
      }),
    )

    yield call(api.post, '/new_task', params)

    yield put(checkRunningTasks())

    yield put(closeModal())

    yield call(
      showMessageToast,
      'A CSV file is being generated and can be downloaded from the activity menu.',
    )
  } catch (err) {
    yield put(
      updateForm(EXPORT_USPS_TRANSACTIONS_MODAL, {
        serverError: `Error creating export task: ${
          err.error_message || err.message
        }`,
      }),
    )
  } finally {
    yield put(updateForm(EXPORT_USPS_TRANSACTIONS_MODAL, {isRequesting: false}))
  }
}

export default function* shippersSaga() {
  yield all([
    takeEvery(SETUP_FORMS, setupFormsWorker),
    takeEvery(SETUP_FORM, setupFormWorker),
    takeEvery(SET_NEW_SHIPPER_VENDOR, setupNewShipperVendorWorker),
    takeEvery(EXPORT_USPS_TRANSACTIONS, exportUSPSTransactionsWorker),
    amazonSFP(),
    amazonShipper(),
    australiaPost(),
    canadaPost(),
    endicia(),
    dhl(),
    newgistics(),
    pitney(),
    pitneyPresort(),
    visibleUSPS(),
    xDelivery(),
  ])
}
