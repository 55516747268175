import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, select, put} from 'redux-saga/effects'

import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {returnOrderSelector} from '../../../redux/selectors/data/returnOrders.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

const MODAL_FORM = 'UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL_FORM'
export const SHOW_UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL =
  'SHOW_UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL'
export const UPDATE_RETURN_ORDER_CUSTOMER_NOTES =
  'UPDATE_RETURN_ORDER_CUSTOMER_NOTES'

export function showUpdateReturnOrderCustomerNotesModal(referenceID) {
  return {
    type: SHOW_UPDATE_RETURN_ORDER_CUSTOMER_NOTES_MODAL,
    payload: {referenceID},
  }
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function updateReturnOrderCustomerNotes() {
  return {
    type: UPDATE_RETURN_ORDER_CUSTOMER_NOTES,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* showUpdateReturnOrderCustomerNotesModalWorker({
  payload: {referenceID},
}) {
  const returnOrder = yield select(returnOrderSelector, {referenceID})

  yield put(
    setForm(MODAL_FORM, {
      referenceID,
      customerNotes: returnOrder.customer_notes || '',
      isSaving: false,
      serverError: null,
    }),
  )
}

export function* updateReturnOrderCustomerNotesWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, customerNotes} = yield select(modalFormSelector)

    const {json} = yield call(
      apiverson.put,
      `/return_order/${encodeURIComponent(referenceID)}`,
      {customer_notes: customerNotes},
    )

    yield put(setReturnOrder(json))

    yield call(showMessageToast, `RMA customer note was updated`)

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error setting RMA customer note: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function UpdateReturnOrderCustomerNotesModal({form, ...props}) {
  return (
    <ConfirmModal
      title="Update RMA Customer Notes"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => props.updateReturnOrderCustomerNotes()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>{form.referenceID}</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Customer Notes"
            rows="10"
            id="customer_notes"
            value={form.customerNotes}
            onChange={(value) => props.updateModalForm({customerNotes: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

UpdateReturnOrderCustomerNotesModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    customerNotes: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  updateReturnOrderCustomerNotes: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  updateReturnOrderCustomerNotes,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(UpdateReturnOrderCustomerNotesModal),
  modalFormSelector,
)
