import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SupplierShape} from '../PropTypes.js'
import MultiSelect from './Form/MultiSelect.js'
import {
  suppliersSortedByNameSelector,
  getSupplierName,
} from '../../data/suppliers.js'

function SupplierMultiSelect({supplierIDs, scope, onChange, suppliers}) {
  scope = scope || 'supplier_multi_select'

  return (
    <MultiSelect
      scope={scope}
      options={suppliers.map((supplier) => ({
        value: supplier.id,
        display: getSupplierName(supplier),
      }))}
      selected={supplierIDs}
      onChange={(selected) => onChange(selected)}
    />
  )
}

SupplierMultiSelect.propTypes = {
  supplierIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  scope: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(SupplierShape).isRequired,
}

function mapStateToProps(state) {
  return {
    suppliers: suppliersSortedByNameSelector(state),
  }
}

export default connect(mapStateToProps)(SupplierMultiSelect)
