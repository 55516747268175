export const BASE = 'ordoro/data/printConfigs'
export const SET_PRINT_CONFIGS = `${BASE}/SET_PRINT_CONFIGS`
export const UPDATE_PRINT_CONFIG = `${BASE}/UPDATE_PRINT_CONFIG`
export const GET_PRINT_CONFIGS = `${BASE}/GET_PRINT_CONFIGS`

export function setPrintConfigs(printConfigs) {
  return {
    type: SET_PRINT_CONFIGS,
    payload: printConfigs,
  }
}

export function updatePrintConfig(printConfig) {
  return {
    type: UPDATE_PRINT_CONFIG,
    payload: printConfig,
  }
}

export function getPrintConfigs() {
  return {
    type: GET_PRINT_CONFIGS,
  }
}
