import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {PRODUCT_LIST_PAGE} from '../../../common/constants/Pages.js'
import api from '../../../common/api.js'
import {
  PluralBlock,
  Count,
  IfPlural,
  IfSingle,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {setProduct, productsSelector} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {refreshProductList} from '../productListFunctions.js'
import Select from '../../../common/components/Select.js'
import {cartsSelector, getCartName} from '../../../data/carts.js'
import {remem} from '../../../common/mem.js'

export const MODAL_FORM = 'MARK_NEEDS_SYNC_MODAL'

export function showMarkNeedsSyncModal(skus, cartID = null) {
  setForm(MODAL_FORM, {
    skus,
    cartID,
    isSingleCart: cartID !== null,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function validCartOptionsSelector(state) {
  return remem(validCartOptionsSelector, {
    skus: modalFormSelector(state).skus,
    isSingleCart: modalFormSelector(state).isSingleCart,
    cartID: modalFormSelector(state).cartID,
    carts: cartsSelector(state),
    products: productsSelector(state),
  })(({skus, isSingleCart, cartID, products, carts}) =>
    Object.entries(
      skus.reduce((prev, sku) => {
        const product = products[sku]

        if (!product) {
          return prev
        }

        for (const cart of product.carts) {
          if (!cart.sync) {
            continue
          }

          prev[cart.id] = (prev[cart.id] || 0) + 1
        }

        return prev
      }, {}),
    )
      .filter(([id]) => !isSingleCart || cartID === Number(id))
      .filter(([_, count]) => count === skus.length)
      .map(([id]) => carts[id])
      .filter((v) => v)
      .map((cart) => ({value: cart.id, display: getCartName(cart)})),
  )
}

export async function markNeedsSync() {
  try {
    const {skus, cartID} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await Promise.all(
      skus.map(async (sku) => {
        const {json} = await api.put(`/product/${encodeURIComponent(sku)}/`, {
          inventory_changed: true,
          cart: cartID,
        })

        setProduct(json)
      }),
    )

    closeModal()

    showMessageToast(plural(skus.length)`Marked product${['s']} as Needs Sync`)

    if (currentPageSelector(getState()) === PRODUCT_LIST_PAGE) {
      await refreshProductList()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function MarkNeedsSyncModal({form}) {
  const cartOptions = useSelector(validCartOptionsSelector)

  useEffect(() => {
    const selectedCart = cartOptions.find(
      (option) => option.value === form.cartID,
    )
    if (!selectedCart) {
      updateModalForm({
        cartID: cartOptions.length ? cartOptions[0].value : null,
      })
    }
  }, [cartOptions, form.cartID])

  return (
    <ConfirmModal
      title={plural(form.skus)`Mark Product${['s']} as Needs Sync`}
      modalSize="sm"
      onConfirm={() => markNeedsSync()}
      onCancel={() => closeModal()}
      confirmText="Yes"
      cancelText="No"
      isDisabled={form.cartID === null}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      {cartOptions.length === 0 ? (
        <div>
          Selected products do not have a common sales channel to sync to.
        </div>
      ) : (
        <>
          <PluralBlock array={form.skus}>
            <div className="fs-01 lh-md margin-bottom-10">
              Are you sure you want to mark{' '}
              <strong>
                <IfSingle>{form.skus[0]}</IfSingle>
                <IfPlural>
                  <Count /> products
                </IfPlural>
              </strong>{' '}
              as Needs Sync?
            </div>
          </PluralBlock>
          {cartOptions.length === 1 ? (
            <div>Sales Channel: {cartOptions[0].display}</div>
          ) : (
            <Select
              label="Sales Channel"
              id="mark_needs_sync_cart_id"
              value={form.cartID || ''}
              onChange={(value) => updateModalForm({cartID: Number(value)})}
            >
              {cartOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.display}
                </option>
              ))}
            </Select>
          )}
        </>
      )}
    </ConfirmModal>
  )
}

MarkNeedsSyncModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    cartID: PropTypes.number,
    isSingleCart: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(MarkNeedsSyncModal, modalFormSelector)
