import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {
  exportTasksSelector,
  activityTasksSelector,
} from '../../../redux/selectors/data/tasks.js'
import {isSuperuserSelector} from '../../../data/me.js'
import {getAllTasks} from '../../../redux/actions/data/tasks.js'
import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'
import {viewTasks, clearExports} from '../../../redux/actions/ui/tasks.js'
import {hasUnreadTasksSelector} from '../../../redux/selectors/ui/tasks.js'

import {TaskShape} from '../../../common/PropTypes.js'

import ActivityMenuItem from './ActivityMenuItem.js'
import {
  REPORTS_PANEL_ACTIVITY,
  panelLinkSelector,
} from '../../ReportsPage/reportsPageSelectors.js'
import Link from '../../../common/components/Link/Link.js'

const ACTIVITY_MENU_DROPDOWN = 'ACTIVITY_MENU_DROPDOWN'

class ActivityMenu extends Component {
  static propTypes = {
    activityTasks: PropTypes.arrayOf(TaskShape).isRequired,
    exportTasks: PropTypes.arrayOf(TaskShape).isRequired,
    isVisible: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    hasUnreadTasks: PropTypes.bool.isRequired,
    reportsPanelActivityURL: PropTypes.string.isRequired,

    viewTasks: PropTypes.func.isRequired,
    clearExports: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible && !prevProps.isVisible) {
      getAllTasks()
    }
    if (this.props.isVisible !== prevProps.isVisible) {
      this.props.viewTasks()
    }
  }

  render() {
    const {
      activityTasks,
      exportTasks,
      isVisible,
      hasUnreadTasks,
      reportsPanelActivityURL,
    } = this.props

    return (
      <li
        className={classNames(
          'utility-nav__item wrap--dropdown wrap--activities dropdown',
          {
            open: isVisible,
            'unread-activity': hasUnreadTasks,
          },
        )}
        data-dropdown={ACTIVITY_MENU_DROPDOWN}
      >
        <button
          className="btn btn--ko-dropdown utility-nav__link dropdown"
          title="Activity"
        >
          <span
            className="icon icon-block icon-activity top-one x-sm"
            aria-hidden="true"
          />
        </button>
        <div className="wrap--inner-dropdown wrap--inner-dropdown-right">
          <div
            className="list--dropdown list--activity-dropdown"
            data-dropdown-prevent-close
          >
            <ul className="list--no-style">
              <li className="wrap--activity-header flex flex--justify">
                <strong>Activity</strong>
                <Link
                  className="fs-n0 v-align-base"
                  href={reportsPanelActivityURL}
                >
                  <strong>Exports</strong> →
                </Link>
              </li>
              {activityTasks
                .filter((task) => !task._linkdata)
                .map((task) => (
                  <ActivityMenuItem
                    task={task}
                    key={task.id}
                    showLogLink={this.props.isSuperuser}
                  />
                ))}
            </ul>
            {activityTasks.length === 0 && exportTasks.length === 0 && (
              <div className="list__item list__item--dropdown no-activity">
                <dl className="list--activity-item list--no-activity">
                  <dt className="list__title--activity">
                    This is your activity menu.
                  </dt>
                  <dd className="list__item--activity">
                    There are many like it, but this one is yours...
                  </dd>
                  <dd className="list__item--activity">
                    Check here for the status on order and inventory imports
                    from your shopping cart and marketplace.
                  </dd>
                </dl>
              </div>
            )}
          </div>
        </div>
      </li>
    )
  }
}

function mapStateToProps(state) {
  return {
    activityTasks: activityTasksSelector(state),
    exportTasks: exportTasksSelector(state),
    isSuperuser: isSuperuserSelector(state),
    isVisible: currentDropdownSelector(state) === ACTIVITY_MENU_DROPDOWN,
    hasUnreadTasks: hasUnreadTasksSelector(state),
    reportsPanelActivityURL: panelLinkSelector(state, {
      panel: REPORTS_PANEL_ACTIVITY,
    }),
  }
}

const mapDispatchToProps = {
  getAllTasks,
  viewTasks,
  clearExports,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMenu)
