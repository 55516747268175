import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'

import {lbToOz, combineToLb} from '../../../common/weight.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import WeightInput from '../../../common/components/Form/WeightInput.js'
import {addProducts, saveProduct, nameSelector} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {hasOrderPermissionSelector} from '../../../data/me.js'

export const SAVE_PRODUCT_WEIGHT = 'SAVE_PRODUCT_WEIGHT'
export const PRODUCT_WEIGHT_MODAL = 'PRODUCT_WEIGHT_MODAL'

export function showProductWeightModal(skus, weight = 0) {
  return setForm(PRODUCT_WEIGHT_MODAL, {
    skus,
    oz: lbToOz(weight),
    hasInputError: false,
    refresh_label_info_package: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PRODUCT_WEIGHT_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PRODUCT_WEIGHT_MODAL)
}

export function saveProductWeight() {
  return {
    type: SAVE_PRODUCT_WEIGHT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCT_WEIGHT_MODAL],
)

export function* saveProductWeightWorker() {
  try {
    const {skus, lb, oz, refresh_label_info_package} =
      yield select(modalFormSelector)
    const params = {
      weight: combineToLb(lb, oz),
      refresh_label_info_package,
      refresh_label_info_customs: refresh_label_info_package,
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const products = yield all(
      skus.map((sku) => call(saveProduct, sku, params)),
    )

    yield call(addProducts, products)

    yield put(closeModal())

    yield call(showMessageToast, 'Saved product weight')
  } catch (err) {
    yield put(updateModalForm({serverError: err.message || err.error_message}))
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function ProductWeightModal({
  form,
  firstProduct,
  hasOrderPermission,
  ...props
}) {
  return (
    <ConfirmModal
      title="Edit Weight"
      modalSize="x-sm"
      onConfirm={() => props.saveProductWeight()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={form.hasInputError}
      error={form.serverError}
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-20">
          {form.skus.length === 1 && (
            <>
              <div className="fs-01">
                <strong>{firstProduct.name}</strong>
              </div>
              <div className="fs-00">{form.skus[0]}</div>
            </>
          )}
          {form.skus.length > 1 && (
            <strong className="fs-01">
              Editing {form.skus.length} products
            </strong>
          )}
        </li>
        <li className="list__item--form list__item--no-style flex">
          <WeightInput
            weightOz={form.oz}
            setWeightOz={(weightOz) => props.updateModalForm({oz: weightOz})}
            onEnterKeyPress={() => props.saveProductWeight()}
            onErrorChange={(err) =>
              props.updateModalForm({
                hasInputError: err.lb || err.oz,
              })
            }
            showScaleControl
            autoFocus
          />
        </li>
        {hasOrderPermission && (
          <li className="list__item--form list__item--no-style divider--top">
            <p className="fs-n0 lh-md margin-bottom-10">
              Want to update the Awaiting Fulfillment and Dropshipment Requested
              orders this product is on when a change is made to its weight?
            </p>
            <Checkbox
              id="refresh_label_info_customs"
              className="fs-00 lh-md"
              label="Update open orders"
              checked={!!form.refresh_label_info_package}
              onChange={(refresh_label_info_package) =>
                props.updateModalForm({refresh_label_info_package})
              }
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

ProductWeightModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    oz: PropTypes.number.isRequired,
    hasInputError: PropTypes.bool.isRequired,
    refresh_label_info_package: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  firstProduct: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  hasOrderPermission: PropTypes.bool.isRequired,
  saveProductWeight: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  const firstSKU = form.skus[0]

  return {
    form,
    firstProduct: {
      name: nameSelector(state, {sku: firstSKU}),
    },
    hasOrderPermission: hasOrderPermissionSelector(state),
  }
}

const mapDispatchToProps = {
  saveProductWeight,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ProductWeightModal),
  modalFormSelector,
)
