import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import api from '../../../common/api.js'
import {ShipperShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {goToShipperSettings} from '../../../redux/actions/ui/settings/shippers/index.js'
import {setShipper, shipperSelector} from '../../../data/shippers.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const ARCHIVE_SHIPPER = 'ARCHIVE_SHIPPER'
export const ARCHIVE_SHIPPER_MODAL = 'ARCHIVE_SHIPPER_MODAL'

export function showArchiveShipperModal(shipperID) {
  return setForm(ARCHIVE_SHIPPER_MODAL, {
    shipperID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(ARCHIVE_SHIPPER_MODAL, ...args)
}

export function closeModal() {
  return removeForm(ARCHIVE_SHIPPER_MODAL)
}

export function archiveShipper() {
  return {
    type: ARCHIVE_SHIPPER,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[ARCHIVE_SHIPPER_MODAL],
)

export function* archiveShipperWorker() {
  try {
    const {shipperID} = yield select(modalFormSelector)
    const shipper = yield select(shipperSelector, {shipperID})

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const {json} = shipper.archive_date
      ? yield call(api.put, `/shipper/${shipperID}/`, {archive_date: null})
      : yield call(api.delete, `/shipper/${shipperID}/`)

    yield put(goToShipperSettings())

    yield call(setShipper, json)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `${shipper.archive_date ? 'Unarchived' : 'Archived'} carrier`,
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function ArchiveShipperModal({form, shipper, ...props}) {
  return (
    <ConfirmModal
      title={`${
        shipper.archive_date ? 'Unarchive' : 'Archive'
      } Carrier Account`}
      modalSize="sm"
      onConfirm={() => props.archiveShipper()}
      onCancel={() => props.closeModal()}
      confirmText="Yes"
      cancelText="No"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div className="fs-01"></div>
      <div className="fs-01 margin-bottom-5">
        <span>
          Are you sure you want to {shipper.archive_date ? 'un' : ''}archive{' '}
          <strong>{shipper.name}</strong>?
        </span>
      </div>
    </ConfirmModal>
  )
}

ArchiveShipperModal.propTypes = {
  form: PropTypes.shape({
    shipperID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  shipper: ShipperShape.isRequired,
  archiveShipper: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    shipper: shipperSelector(state, {
      shipperID: form.shipperID,
    }),
  }
}

const mapDispatchToProps = {
  archiveShipper,
  closeModal,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ArchiveShipperModal),
  modalFormSelector,
)
