import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {isLoadingAnalyticsSelector} from './analyticsSelectors.js'
import {topInventorySelector} from './inventorySelectors.js'
import Panel from './Panel.js'
import Header from './Header.js'
import SingleValuePane from './SingleValuePane.js'
import TablePane from './TablePane.js'

export function InventoryPanel({topInventory, isLoading}) {
  return (
    <Panel>
      <Header>Inventory Stats</Header>
      <div className="row margin-top-5">
        <div className="medium-5 columns">
          <SingleValuePane title="Total On Hand Value" isLoading={isLoading}>
            {!isLoading &&
              topInventory &&
              (topInventory.hits.length > 0 ? (
                <Currency value={topInventory.totalValueAll} />
              ) : (
                <dl className="list list--horiz-bar-graph centered-text hide-for-loading">
                  <dt className="list__item margin-bottom-0 make-medium-dark-grey">
                    <strong>There is no data to display</strong>
                  </dt>
                </dl>
              ))}
          </SingleValuePane>
        </div>
        <div className="medium-12 columns">
          <TablePane
            title="On-Hand SKUs with the Most Value"
            isLoading={isLoading}
          >
            {!isLoading && topInventory && topInventory.hits.length > 0 && (
              <>
                <thead>
                  <tr>
                    <th className="th-width-1 super-small-screen-hide">
                      <span className="column-name first">&nbsp;</span>
                    </th>
                    <th className="th-width-20">
                      <span className="column-name">Product Name</span>
                    </th>
                    <th className="th-width-20">
                      <span className="column-name">SKU</span>
                    </th>
                    <th className="th-width-15">
                      <span className="column-name">Total on Hand</span>
                    </th>
                    <th className="th-width-25">
                      <span className="column-name">
                        Total Value of Inventory
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topInventory.hits.map((hit, index) => (
                    <tr key={index}>
                      <td className="super-small-screen-hide">
                        <span className="table__rank">{`${index + 1}.`}</span>
                      </td>
                      <td>
                        <strong className="word-breaker">{hit.name}</strong>
                      </td>
                      <td>
                        <span className="word-breaker">{hit.sku}</span>
                      </td>
                      <td>
                        <Quantity value={hit.total_on_hand} />
                      </td>
                      <td>
                        <Currency value={hit.value} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </TablePane>
        </div>
      </div>
    </Panel>
  )
}

InventoryPanel.propTypes = {
  topInventory: PropTypes.shape({
    hits: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        sku: PropTypes.string.isRequired,
        total_on_hand: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      }),
    ),
    totalValueAll: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProp(state) {
  return {
    topInventory: topInventorySelector(state),
    isLoading: isLoadingAnalyticsSelector(state),
  }
}

export default connect(mapStateToProp)(InventoryPanel)
