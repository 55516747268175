import {STARTER_PLAN} from '../../common/constants/Plans.js'
import {planSelector} from '../../data/company.js'
import {useSelector} from '../../store.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'

export default function UpgradeMessage() {
  const plan = useSelector(planSelector)

  if (plan !== STARTER_PLAN) {
    return null
  }

  return (
    <div className="wrap--free-callout centered-text">
      <p className="fs-00 margin-bottom-0">
        <strong>
          {'Some '}
          <a
            className="inline-text-button darker with-underline"
            href="//support.ordoro.com/do-i-see-all-features-if-i-sign-up-for-the-free-plan/"
            target="_blank"
            rel="noopener noreferrer"
          >
            awesome features and phone support
          </a>
          {' are missing from the Starter plan. '}
        </strong>
        <strong>
          <button
            className="inline-text-button hyperlink"
            onClick={() => showUpgradeModal()}
          >
            Upgrade
          </button>
        </strong>{' '}
        <span className="op-50">➞</span>
      </p>
    </div>
  )
}
