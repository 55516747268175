export const BarcodeLayouts = [
  {value: '2_5x0_75', display: '2.5" x 0.75"'},
  {value: '3_5x1', display: '3.5" x 1"'},
  {value: '3_5x1_125', display: '3.5" x 1.125"'},
  {value: '30up', display: '30-up'},
  {value: '10up', display: '10-up'},
  {value: '6up', display: '6-up'},
]

export const BLANK_TEMPLATE = ''
export const SKU_TEMPLATE = '{{sku}}'
export const UPC_TEMPLATE = '{{upc}}'
export const ASIN_TEMPLATE = '{{asin}}'
export const NAME_TEMPLATE = '{{name}}'
export const PRICE_TEMPLATE = '{{currency_symbol}}{{price}}'
export const COST_TEMPLATE = '{{currency_symbol}}{{cost}}'
export const LOCATION_IN_WAREHOUSE_TEMPLATE = '{{location_in_warehouse}}'
export const LOW_STOCK_THRESHOLD_TEMPLATE = '{{low_stock_threshold}}'
export const CUSTOM_TEMPLATE = 'custom'

export const BarcodeValueOptions = [
  {value: SKU_TEMPLATE, display: 'SKU'},
  {value: UPC_TEMPLATE, display: 'UPC'},
  {value: ASIN_TEMPLATE, display: 'ASIN'},
  {value: CUSTOM_TEMPLATE, display: 'Custom'},
]

export const BarcodeTextOptions = [
  {value: BLANK_TEMPLATE, display: 'None'},
  {value: NAME_TEMPLATE, display: 'Name'},
  {value: SKU_TEMPLATE, display: 'SKU'},
  {value: UPC_TEMPLATE, display: 'UPC'},
  {value: ASIN_TEMPLATE, display: 'ASIN'},
  {value: PRICE_TEMPLATE, display: 'Price'},
  {value: COST_TEMPLATE, display: 'Cost'},
  {value: LOCATION_IN_WAREHOUSE_TEMPLATE, display: 'Location in Warehouse'},
  {value: LOW_STOCK_THRESHOLD_TEMPLATE, display: 'Low Stock Threshold'},
  {value: CUSTOM_TEMPLATE, display: 'Custom'},
]
