import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {isPresent} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import {setProduct, nameSelector} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
  getState,
} from '../../../store.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshProductList} from '../productListFunctions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import Checkbox from '../../../common/components/Checkbox.js'
import api from '../../../common/api.js'
import {buildPath} from '../../../common/querystring.js'
import {plural} from '../../../common/components/Plural.js'
import Quantity from '../../../common/components/Quantity.js'
import {hasOrderPermissionSelector} from '../../../data/me.js'

export const MODAL_FORM = 'PRODUCT_TEXT_FIELD_MODAL'

export function showProductTextFieldModal({
  sku,
  skus,
  value,
  display,
  title,
  apiProp,
  isRequired,
  needsProductListRefresh,
  inputType,
  showRefreshLabelInfoCustoms,
}) {
  setForm(MODAL_FORM, {
    skus: skus || [sku],
    apiProp,
    value,
    display,
    title: title || display,
    isRequired: !!isRequired,
    needsProductListRefresh: !!needsProductListRefresh,
    inputType: inputType || 'text',
    showRefreshLabelInfoCustoms: showRefreshLabelInfoCustoms || false,
    refresh_label_info_customs: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export const nameModalErrorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
      errors.preventSave = true
    }

    return errors
  },
)

export async function saveProductTextField() {
  try {
    const {
      skus,
      value,
      apiProp,
      needsProductListRefresh,
      showRefreshLabelInfoCustoms,
      refresh_label_info_customs,
    } = modalFormSelector(getState())

    const params = {
      [apiProp]: value,
    }

    if (showRefreshLabelInfoCustoms) {
      params.refresh_label_info_customs = refresh_label_info_customs
    }

    updateModalForm({isSaving: true, serverError: null})

    await Promise.all(
      skus.map(async (sku) => {
        const {json: product} = await api.put(
          buildPath(['product', sku]),
          params,
        )

        setProduct(product)
      }),
    )

    closeModal()

    showMessageToast(plural(skus)`Saved Product${['s']}`)

    if (needsProductListRefresh) {
      await refreshProductList()
    }
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  } finally {
    updateModalForm({isSaving: false})
  }
}

function ProductTextFieldModal({form}) {
  const errors = useSelector(nameModalErrorsSelector)
  const name = useSelector((state) => nameSelector(state, {sku: form.skus[0]}))
  const hasOrderPermission = useSelector(hasOrderPermissionSelector)

  return (
    <ConfirmModal
      title={form.title}
      modalSize="sm"
      onConfirm={() => saveProductTextField()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        {form.skus.length === 1 ? (
          <>
            <div className="fs-01">
              <strong>{name}</strong>
            </div>
            <div className="fs-00">{form.skus[0]}</div>
          </>
        ) : (
          <div className="fs-01">
            <strong>
              <Quantity value={form.skus.length} /> Products
            </strong>
          </div>
        )}
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'checkbox' ? (
        <Checkbox
          id={form.apiProp}
          label={form.display}
          className="fs-00 lh-md margin-bottom-0"
          checked={!!form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : null}
      {hasOrderPermission && form.showRefreshLabelInfoCustoms && (
        <div className="list__item list__item--form margin-top-20 divider--top">
          <p className="fs-n0 lh-md margin-bottom-10">
            Want to update the Awaiting Fulfillment and Dropshipment Requested
            orders this product is on when a change is made to its customs info?
          </p>
          <Checkbox
            id="refresh_label_info_customs"
            className="fs-00 lh-md margin-bottom-0"
            label="Update open orders"
            checked={!!form.refresh_label_info_customs}
            onChange={(refresh_label_info_customs) =>
              updateModalForm({refresh_label_info_customs})
            }
          />
        </div>
      )}
    </ConfirmModal>
  )
}

ProductTextFieldModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    display: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['text', 'textarea', 'checkbox']).isRequired,
    showRefreshLabelInfoCustoms: PropTypes.bool.isRequired,
    refresh_label_info_customs: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ProductTextFieldModal, modalFormSelector)
