import {useEffect} from 'react'
import classNames from 'classnames'

import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {isEmptyValue} from '../../../../common/utils.js'
import Currency from '../../../../common/components/Currency.js'
import {showPostageBalanceModal} from '../../Modals/PostageBalanceModal.js'
import {getAllShipperBalances} from '../../../../data/shipperBalances.js'
import {
  singleShipperIDForBalanceSelector,
  bulkBalanceSelector,
  insufficientBalanceSelector,
  bulkCanAddPostageSelector,
} from '../bulkLabelConfigSelectors.js'

export default function BulkBalance({labelInfoIDs}) {
  const shipperID = useSelector((state) =>
    singleShipperIDForBalanceSelector(state, {labelInfoIDs}),
  )
  const balance = useSelector((state) =>
    bulkBalanceSelector(state, {labelInfoIDs}),
  )
  const insufficientBalance = useSelector((state) =>
    insufficientBalanceSelector(state, {labelInfoIDs}),
  )
  const canAddPostage = useSelector((state) =>
    bulkCanAddPostageSelector(state, {labelInfoIDs}),
  )

  useEffect(() => {
    getAllShipperBalances()
  }, [shipperID, labelInfoIDs])

  return (
    <small
      className={classNames('fs-n1 wrap--usps-balance', {
        'error-message': insufficientBalance,
      })}
    >
      {insufficientBalance && (
        <span
          className="i--error fs-00 lh-sm v-align-middle margin-right-3"
          aria-hidden="true"
        />
      )}
      {isEmptyValue(balance) ? (
        <span className="text--lt-grey margin-top-3">
          <span className="spinner--x-sm v-align-middle" />{' '}
          <em className="v-align-middle">Loading balance...</em>
        </span>
      ) : canAddPostage ? (
        <button
          className="btn btn--link btn--balance mid v-align-base"
          type="button"
          onClick={() => showPostageBalanceModal(shipperID)}
        >
          <strong>Balance: </strong>
          <Currency value={balance} currencySymbol="$" />
        </button>
      ) : (
        <span className="mid v-align-base">
          <strong>Balance: </strong>
          <Currency value={balance} currencySymbol="$" />
        </span>
      )}
    </small>
  )
}

BulkBalance.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
