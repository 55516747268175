import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import BaseEditForm from '../BaseEditForm.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import {usesInventorySelector} from '../../../../data/company.js'
import {warehousesSortedByNameSelector} from '../../../../data/warehouses.js'

export class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onIDChange = (value) => updatePayload({id: value})
    this.onSKUChange = (value) => updatePayload({sku: value})
    this.onLocationInWarehouseChange = (value) =>
      updatePayload({location_in_warehouse: value})
    this.onOnHandChange = (value) => updatePayload({on_hand: value})
    this.onAdjustmentChange = (value) => updatePayload({adjustment: value})
    this.onLowStockThresholdChange = (value) =>
      updatePayload({low_stock_threshold: value})
  }

  renderWarehouseOptions() {
    const list = [
      {id: -1, address: {name: ''}},
      ...this.props.warehousesSortedByName,
    ]

    return list.map((warehouse) => (
      <option key={warehouse.id} value={warehouse.id}>
        {warehouse.address.name}
      </option>
    ))
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <Select
            value={data.payload.id}
            errorMessage={data.errors.id}
            name="id"
            label="Warehouse"
            className="columns medium-12 wrap--full-select"
            selectClassName="full-width"
            onChange={this.onIDChange}
          >
            {this.renderWarehouseOptions()}
          </Select>
        </Row>
        <Row>
          <TextInput
            value={data.payload.sku}
            errorMessage={data.errors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.location_in_warehouse}
            errorMessage={data.errors.location_in_warehouse}
            name="location_in_warehouse"
            label="Location in Warehouse"
            className="medium-6"
            onChange={this.onLocationInWarehouseChange}
          />
          {this.props.usesInventory && (
            <TextInput
              value={data.payload.on_hand}
              errorMessage={data.errors.on_hand}
              name="on_hand"
              label="Physical On Hand"
              className="medium-6"
              onChange={this.onOnHandChange}
            />
          )}
        </Row>
        {this.props.usesInventory && (
          <Row>
            <TextInput
              value={data.payload.low_stock_threshold}
              errorMessage={data.errors.low_stock_threshold}
              name="low_stock_threshold"
              label="Low Stock Threshold"
              className="medium-6"
              onChange={this.onLowStockThresholdChange}
            />
            <TextInput
              value={data.payload.adjustment}
              errorMessage={data.errors.adjustment}
              name="adjustment"
              label="On Hand Adjustment"
              className="medium-6"
              onChange={this.onAdjustmentChange}
            />
          </Row>
        )}
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
  warehousesSortedByName: PropTypes.array.isRequired,
  usesInventory: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    warehousesSortedByName: warehousesSortedByNameSelector(state),
    usesInventory: usesInventorySelector(state),
  }
}

export default connect(mapStateToProps)(EditForm)
