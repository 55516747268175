import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'

const MODAL_FORM = 'EDIT_MOCK_ORDERS_FORM'

export function showEditMockOrdersModal() {
  setForm(MODAL_FORM, {
    orderNumbersText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockOrdersModal({form}) {
  const query = useSelector(querySelector)
  const orderNumbers = ensureArray(query.order)

  useEffect(() => {
    updateModalForm({
      orderNumbersText: orderNumbers.join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Order Numbers"
      onConfirm={() => {
        const orderNumbers = form.orderNumbersText
          .replace(/[, ]/g, '\n')
          .split('\n')
          .filter((orderNumber) => !!orderNumber)

        let profileIDs = ensureArray(query.template)

        while (orderNumbers.length !== profileIDs.length) {
          if (profileIDs.length <= 1) {
            break
          }

          if (orderNumbers.length > profileIDs.length) {
            profileIDs.push(profileIDs[profileIDs.length - 1])
          } else {
            profileIDs.pop()
          }
        }

        mockAbodeUpdateResourceQuery({
          order: orderNumbers,
          template: profileIDs,
        })

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock Order Numbers</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Order Numbers"
            rows="10"
            id="order_numbers"
            value={form.orderNumbersText || ''}
            onChange={(value) => updateModalForm({orderNumbersText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockOrdersModal.propTypes = {
  form: PropTypes.shape({
    orderNumbersText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockOrdersModal, modalFormSelector)
