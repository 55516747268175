import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import omit from 'lodash/omit.js'

import {userSettingSelector} from '../../data/me.js'

function AudioFile({src, canPlayScannerSounds, ...props}) {
  if (!canPlayScannerSounds) {
    return null
  }

  return (
    <audio src={src} autoPlay {...omit(props, 'dispatch')}>
      <track kind="captions" src="garbage.vtt" srcLang="en" />
    </audio>
  )
}

AudioFile.propTypes = {
  src: PropTypes.string.isRequired,
  canPlayScannerSounds: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    canPlayScannerSounds: userSettingSelector(state, {
      setting: 'can_play_scanner_sounds',
    }),
  }
}

export default connect(mapStateToProps)(AudioFile)
