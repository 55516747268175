import {useEffect} from 'react'

import {dispatch, useSelector} from '../../store.js'
import {PRODUCT_DETAIL_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  ensureLinkedSKUsLoaded,
  linkedSKUsSelector,
} from '../../data/products.js'
import {loadCurrentProduct} from './productDetailActions.js'
import {skuSelector} from './productDetailSelectors.js'

export default function ProductDetailLocationListener() {
  const sku = useSelector(skuSelector)
  const linkedSKUs = useSelector((state) => linkedSKUsSelector(state, {sku}))

  useEffect(() => {
    if (!sku) {
      return
    }

    dispatch(setCurrentPage(PRODUCT_DETAIL_PAGE))

    loadCurrentProduct()
  }, [sku])

  useEffect(() => {
    ensureLinkedSKUsLoaded([sku])
  }, [sku, linkedSKUs])

  return null
}
