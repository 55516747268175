import {applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'

import {logError} from '../common/error.js'

import {showUnrecoverableErrorModal} from '../ordoro/Modals/UnrecoverableErrorModal.js'
import configureStore from './configureStore.js'
import {stickyMiddleware, stickyActionPlayback} from './stickyMiddleware.js'
import {stickyFormMiddleware} from './stickyFormMiddleware.js'
import rootSaga from './sagas/index.js'

export default function configureOrdoroStore({
  initialState = {},
  extraEnhancer,
} = {}) {
  const sagaMiddleware = createSagaMiddleware()

  let enhancer = compose(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(stickyMiddleware),
    applyMiddleware(stickyFormMiddleware),
  )

  enhancer = compose(
    enhancer,
    Sentry.createReduxEnhancer({
      // Don't send whole state tree, its too big
      stateTransformer: () => null,
    }),
  )

  if (extraEnhancer) {
    enhancer = compose(enhancer, extraEnhancer)
  }

  const store = configureStore({
    initialState,
    extraEnhancer: enhancer,
  })

  stickyActionPlayback(store)

  sagaMiddleware
    .run(rootSaga, store.dispatch)
    .toPromise()
    .catch((err) => {
      // https://github.com/redux-saga/redux-saga/issues/287
      logError(err)

      store.dispatch(showUnrecoverableErrorModal(err))
    })

  return store
}
