import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import ProductFilter from '../../common/components/ProductFilter.js'
import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {
  setProductFilter,
  clearProductsFilter,
  exportToCSV,
} from './analyticsActions.js'
import {
  analyticsFormSelector,
  productFiltersSelector,
  isLoadingAnalyticsSelector,
  isProductSearchLoadingSelector,
} from './analyticsSelectors.js'
import {topSellersSelector, productSearchSelector} from './productSelectors.js'
import Panel from './Panel.js'
import Header from './Header.js'
import Filters from './Filters.js'
import CartFilter from './CartFilter.js'
import SupplierFilter from './SupplierFilter.js'
import DateFilter from './DateFilter.js'
import CartActiveFilters from './CartActiveFilters.js'
import SupplierActiveFilters from './SupplierActiveFilters.js'
import SingleValuePane from './SingleValuePane.js'
import TablePane from './TablePane.js'
import ExportCSVButton from './ExportCSVButton.js'

export function ProductPanel({
  topSellers,
  productSearch,
  isLoading,
  isProductSearchLoading,
  productFilters,
}) {
  return (
    <Panel>
      <Header>Product Stats</Header>
      <Filters>
        <CartFilter />
        <SupplierFilter />
        <DateFilter />
      </Filters>
      <CartActiveFilters />
      <SupplierActiveFilters />
      <>
        <div className="row margin-top-25">
          <div className="medium-5 columns">
            <SingleValuePane title="Total Units Sold" isLoading={isLoading}>
              {!isLoading && topSellers && topSellers.hitTotal > 0 && (
                <Quantity value={topSellers.totalUnits} />
              )}
            </SingleValuePane>
          </div>
        </div>
        <div className="row">
          <div className="medium-12 columns">
            <TablePane
              title="Top-Selling Products"
              isLoading={isLoading}
              canExport={!!topSellers}
              onExport={() => exportToCSV('topSellers')}
              hasFilters
            >
              {!isLoading && topSellers && topSellers.buckets.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th className="th-width-1 super-small-screen-hide">
                        <span className="column-name first">&nbsp;</span>
                      </th>
                      <th className="th-width-20">
                        <span className="column-name">Product Name</span>
                      </th>
                      <th className="th-width-15">
                        <span className="column-name">SKU</span>
                      </th>
                      <th className="th-width-12">
                        <span className="column-name">Total Sales</span>
                      </th>
                      <th className="th-width-12">
                        <span className="column-name">Discounts</span>
                      </th>
                      <th className="th-width-12">
                        <span className="column-name">Units Sold</span>
                      </th>
                      <th className="th-width-12">
                        <span className="column-name">Orders</span>
                      </th>
                      <th className="th-width-12">
                        <span className="column-name">Total Cost</span>
                      </th>
                      <th className="th-width-15">
                        <span className="column-name">Profit Margin</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {topSellers.buckets.map((bucket, index) => (
                      <tr key={index}>
                        <td className="super-small-screen-hide">
                          <span className="table__rank">{`${index + 1}.`}</span>
                        </td>
                        <td>
                          <strong className="word-breaker">
                            {bucket.name}
                          </strong>
                        </td>
                        <td>
                          <span className="word-breaker">{bucket.sku}</span>
                        </td>
                        <td>
                          <Currency value={bucket.total_sales} />
                        </td>
                        <td>
                          <Currency value={bucket.total_product_discounts} />
                        </td>
                        <td>
                          <Quantity value={bucket.number_of_units_sold} />
                        </td>
                        <td>
                          <Quantity value={bucket.number_of_orders} />
                        </td>
                        <td>
                          <Currency value={bucket.total_cost} />
                        </td>
                        <td>
                          <Currency value={bucket.total_profit} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </TablePane>
          </div>
        </div>
        <div className="row">
          <div className="medium-12 columns">
            <div className="panel panel--analytics clearfix">
              <div className="panel__header panel__header--analytics flex--justify">
                <h3 className="subheader subheader--analytics">
                  Compare Product Performance
                </h3>
                {productSearch && (
                  <ExportCSVButton
                    onExport={() => exportToCSV('productSearch')}
                  />
                )}
              </div>
              <div className="medium-12 columns flex--justify">
                <p className="w-65 fs-00 margin-top-20 margin-bottom-10">
                  Select specific products in your inventory and compare how
                  they performed in the current date range and set of filters.
                </p>
                {isProductSearchLoading && (
                  <div className="loading align-center margin-top-30">
                    <span className="spinner--md v-align-middle" />
                  </div>
                )}
                <table className="table--analytics">
                  {productFilters.length > 0 && (
                    <thead>
                      <tr>
                        <th className="th-width-20">
                          <span className="column-name">Product Name</span>
                        </th>
                        <th className="th-width-15">
                          <span className="column-name">SKU</span>
                        </th>
                        <th className="th-width-12">
                          <span className="column-name">Total Sales</span>
                        </th>
                        <th className="th-width-12">
                          <span className="column-name">Discounts</span>
                        </th>
                        <th className="th-width-12">
                          <span className="column-name">Units Sold</span>
                        </th>
                        <th className="th-width-12">
                          <span className="column-name">Orders</span>
                        </th>
                        <th className="th-width-12">
                          <span className="column-name">Total Cost</span>
                        </th>
                        <th className="th-width-15">
                          <span className="column-name">Profit Margin</span>
                        </th>
                        <th className="th-width-1">
                          <span className="column-name">
                            <ButtonLink
                              className="no-underline"
                              onClick={() => clearProductsFilter()}
                            >
                              <span
                                className="i-trash fs-00"
                                aria-hidden="true"
                              />
                            </ButtonLink>
                          </span>
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {productSearch &&
                      productSearch.buckets.map((bucket) => (
                        <tr
                          key={bucket.sku}
                          className={classNames({
                            'op-30': isProductSearchLoading,
                          })}
                        >
                          <td>
                            <strong className="word-breaker">
                              {bucket.name}
                            </strong>
                          </td>
                          <td>
                            <span className="word-breaker">{bucket.sku}</span>
                          </td>
                          <td>
                            <Currency value={bucket.total_sales} />
                          </td>
                          <td>
                            <Currency value={bucket.total_product_discounts} />
                          </td>
                          <td>
                            <Quantity value={bucket.number_of_units_sold} />
                          </td>
                          <td>
                            <Quantity value={bucket.number_of_orders} />
                          </td>
                          <td>
                            <Currency value={bucket.total_cost} />
                          </td>
                          <td>
                            <Currency value={bucket.total_profit} />
                          </td>
                          <td>
                            <ButtonLink
                              className="no-underline"
                              onClick={() =>
                                setProductFilter(bucket.sku, false)
                              }
                            >
                              <span
                                className="i-trash fs-00"
                                aria-hidden="true"
                              />
                            </ButtonLink>
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td
                        className={classNames({
                          'padding-left-0 padding-top-0':
                            productFilters.length === 0,
                          'table__td--product-search':
                            productFilters.length > 0,
                        })}
                        colSpan="9"
                      >
                        <ProductFilter
                          label={
                            productFilters.length
                              ? 'Add another product to compare'
                              : 'Search for a product'
                          }
                          dropdown="ANALYTICS_PRODUCT_FILTER"
                          excludedSKUs={productFilters}
                          onSelect={(product) => {
                            setProductFilter(product.sku, true)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </Panel>
  )
}

ProductPanel.propTypes = {
  topSellers: PropTypes.shape({
    hitTotal: PropTypes.number.isRequired,
    totalUnits: PropTypes.number.isRequired,
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        sku: PropTypes.string,
        total_sales: PropTypes.number.isRequired,
        total_product_discounts: PropTypes.number,
        number_of_units_sold: PropTypes.number.isRequired,
        number_of_orders: PropTypes.number.isRequired,
        total_cost: PropTypes.number.isRequired,
        total_profit: PropTypes.number.isRequired,
      }),
    ),
  }),
  productSearch: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        sku: PropTypes.string,
        total_sales: PropTypes.number.isRequired,
        total_product_discounts: PropTypes.number,
        number_of_units_sold: PropTypes.number.isRequired,
        number_of_orders: PropTypes.number.isRequired,
        total_cost: PropTypes.number.isRequired,
        total_profit: PropTypes.number.isRequired,
      }),
    ),
  }),
  isLoading: PropTypes.bool.isRequired,
  isProductSearchLoading: PropTypes.bool.isRequired,
  productFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function mapStateToProp(state) {
  return {
    topSellers: topSellersSelector(state),
    productSearch: productSearchSelector(state),
    isLoading: isLoadingAnalyticsSelector(state),
    isProductSearchLoading: isProductSearchLoadingSelector(state),
    productFilters: productFiltersSelector(state),
    form: analyticsFormSelector(state),
  }
}

export default connect(mapStateToProp)(ProductPanel)
