import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import {SupplierShape} from '../../../common/PropTypes.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {setProduct, setDefaultSupplier} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {getSupplierName, supplierSelector} from '../../../data/suppliers.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const UPDATE_DEFAULT_SUPPLIER = 'UPDATE_DEFAULT_SUPPLIER'
export const UPDATE_DEFAULT_SUPPLIER_MODAL = 'UPDATE_DEFAULT_SUPPLIER_MODAL'

export function showUpdateDefaultSupplierModal(sku, supplierID) {
  return setForm(UPDATE_DEFAULT_SUPPLIER_MODAL, {
    sku,
    supplierID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(UPDATE_DEFAULT_SUPPLIER_MODAL, ...args)
}

export function closeModal() {
  return removeForm(UPDATE_DEFAULT_SUPPLIER_MODAL)
}

export function updateDefaultSupplier() {
  return {
    type: UPDATE_DEFAULT_SUPPLIER,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[UPDATE_DEFAULT_SUPPLIER_MODAL],
)

export function* updateDefaultSupplierWorker() {
  try {
    const {sku, supplierID} = yield select(modalFormSelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const product = yield call(setDefaultSupplier, supplierID, sku)

    yield call(setProduct, product)

    yield put(closeModal())

    yield call(showMessageToast, 'Set supplier as default')
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function UpdateDefaultSupplierModal({form, supplier, ...props}) {
  return (
    <ConfirmModal
      title="Update Default Supplier"
      modalSize="sm"
      onConfirm={() => props.updateDefaultSupplier()}
      onCancel={() => props.closeModal()}
      confirmText="Confirm"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 margin-bottom-0">
        Do you want to make <strong>{getSupplierName(supplier)}</strong> the
        default supplier for this SKU?
      </p>
    </ConfirmModal>
  )
}

UpdateDefaultSupplierModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    supplierID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  supplier: SupplierShape.isRequired,
  updateDefaultSupplier: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    supplier: supplierSelector(state, {supplierID: form.supplierID}),
  }
}

const mapDispatchToProps = {
  updateDefaultSupplier,
  closeModal,
  updateForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(UpdateDefaultSupplierModal),
  modalFormSelector,
)
