import INITIAL_STATE from '../INITIAL_STATE.js'
import {UPDATE_HAS_LOADED} from '../../actions/data/hasLoaded.js'

export default function reducer(
  state = INITIAL_STATE.data.hasLoaded,
  action = {},
) {
  if (action.type === UPDATE_HAS_LOADED) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}
