import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {WOOCOMMERCE} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import Checkbox from '../../Common/Checkbox.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.url)) {
    errors.url = 'URL is required'
    errors.preventSave = true
  }

  if (!isPresent(form.key)) {
    errors.key = 'Consumer Key is required'
    errors.preventSave = true
  }

  if (!isPresent(form.secret)) {
    errors.secret = 'Consumer Secret is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: WOOCOMMERCE}),
      url: '',
      key: '',
      secret: '',
      advanced_shipment_tracking: false,
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    url: cart.vendor_config.url,
    key: cart.vendor_config.consumer_key,
    secret: cart.vendor_config.consumer_secret,
    advanced_shipment_tracking:
      cart.vendor_config.advanced_shipment_tracking || false,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'url') {
    errors.url = serverError.error_message
  } else if (serverError.param === 'consumer_key') {
    errors.key = serverError.error_message
  } else if (serverError.param === 'consumer_secret') {
    errors.secret = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    url: form.url,
    consumer_key: form.key,
    consumer_secret: form.secret,
    advanced_shipment_tracking: form.advanced_shipment_tracking,
  }
}

function WooCommerce({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="url"
              label="Site URL"
              required
              value={form.url || ''}
              errorMessage={form.hasChanged_url && errors.url}
              onChange={(event) =>
                updateForm(formName, {
                  url: event.target.value,
                  hasChanged_url: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="key"
              label="Consumer Key"
              required
              value={form.key || ''}
              errorMessage={form.hasChanged_key && errors.key}
              onChange={(event) =>
                updateForm(formName, {
                  key: event.target.value,
                  hasChanged_key: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              type="password"
              name="secret"
              label="Consumer Secret"
              required
              value={form.secret || ''}
              errorMessage={form.hasChanged_secret && errors.secret}
              onChange={(event) =>
                updateForm(formName, {
                  secret: event.target.value,
                  hasChanged_secret: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <Checkbox
              id="advanced_shipment_tracking"
              name="advanced_shipment_tracking"
              label="Use Zorem Advanced Shipment Tracking plugin"
              checked={form.advanced_shipment_tracking}
              errorMessage={errors.advanced_shipment_tracking}
              onChange={(checked) =>
                updateForm(formName, {
                  advanced_shipment_tracking: checked,
                })
              }
            />
            <div className="fs-n1 margin-top-5">
              <strong>
                <a
                  href="https://support.ordoro.com/how-does-advanced-shipment-tracking-by-zorem-work-in-ordoro/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn more about the plugin
                </a>{' '}
                <span className="op-50">→</span>
              </strong>
            </div>
          </FormListItem>
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="WooCommerce"
          href="https://support.ordoro.com/how-do-i-find-my-woocommerce-api-keys/"
        />
      </div>
    </VendorForm>
  )
}

WooCommerce.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    url: PropTypes.string.isRequired,
    hasChanged_url: PropTypes.bool,
    key: PropTypes.string.isRequired,
    hasChanged_key: PropTypes.bool,
    secret: PropTypes.string.isRequired,
    hasChanged_secret: PropTypes.bool,
    advanced_shipment_tracking: PropTypes.bool.isRequired,
  }),
}

export default onlyIfAutoForm(WooCommerce, setUpCartForm)
