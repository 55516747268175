import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {STRIPE_PAYMENTS} from '../../../../common/constants/Integrations.js'
import {navigate} from '../../../../redux/actions/ui/index.js'
import {authorizeLinkSelector} from '../../../../redux/selectors/ui/settings/payments/stripe.js'

export function Stripe({isNew, authorizeLink, ...props}) {
  return (
    <div className="settings-list-item-form-wrap settings-list-item-form-cp clear-both padding-top-10">
      <fieldset>
        <ul className="form-list medium-6 columns">
          {isNew && (
            <li className="form-list-item helper-li margin-top-5 margin-bottom-10">
              You’ll be taken to Stripe to authorize Ordoro to use your account.
            </li>
          )}
          {isNew && (
            <li className="form-list-item margin-top-20">
              <button
                type="button"
                className="btn btn--primary"
                onClick={() => props.navigate(authorizeLink)}
              >
                Authorize
              </button>
              <button
                type="button"
                className="btn btn--secondary"
                onClick={() => props.navigate('#/settings/payments')}
              >
                Cancel
              </button>
            </li>
          )}
          {!isNew && (
            <li className="form-list-item margin-bottom-0">
              <button
                type="button"
                className="btn btn--primary btn--primary-ol btn--sm reauthorize"
                onClick={() => props.navigate(authorizeLink)}
              >
                Reauthorize
              </button>
            </li>
          )}
        </ul>
      </fieldset>
    </div>
  )
}

Stripe.vendor = STRIPE_PAYMENTS

Stripe.propTypes = {
  isNew: PropTypes.bool.isRequired,
  integrationID: PropTypes.number,
  authorizeLink: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    authorizeLink: authorizeLinkSelector(state, props),
  }
}

const mapDispatchToProps = {
  navigate,
}

export default connect(mapStateToProps, mapDispatchToProps)(Stripe)
