import {NEW_ID} from '../../../../../../common/constants/index.js'
import {isPresent} from '../../../../../../common/utils.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.pb_shipment_group_id)) {
    errors.pb_shipment_group_id = 'Shipment Group ID is required'
  }

  if (!isPresent(shipper.pb_integrator_carrier_id)) {
    errors.pb_integrator_carrier_id = 'Integrator Carrier ID is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    pb_shipment_group_id: vendorConfig.pb_shipment_group_id || '',
    pb_integrator_carrier_id: vendorConfig.pb_integrator_carrier_id || '',
    isSaving: false,
  }
}
