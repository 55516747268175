import {createSelector} from 'reselect'

import {getOrderIsAllocateable} from '../../../../data/orders.js'

export const confirmAttemptToAllocateOrdersSelector = (state) =>
  state.ui.modals.confirmAttemptToAllocateModal.orders

export const confirmAttemptToAllocateOrdersAllocatableOrderCountSelector =
  createSelector(
    confirmAttemptToAllocateOrdersSelector,
    (orders) => orders.filter((order) => getOrderIsAllocateable(order)).length,
  )
