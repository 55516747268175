import PropTypes from 'prop-types'
import Checkbox from '../../../common/components/Checkbox.js'
import {useSelector} from '../../../store.js'
import {
  isLabelPropertyEnabledSelector,
  onLabelPropertyChange,
} from '../../../data/labelInfos/index.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function EnableLabelProperty({labelProperty, checkedSelector}) {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()
  const checked = useSelector(
    checkedSelector ||
      ((state) =>
        isLabelPropertyEnabledSelector(state, {
          labelProperty,
          labelInfoID,
        })),
  )

  if (!labelProperty || !isLabelEnableMode) {
    return null
  }

  return (
    <div className="wrap--edit-preset-switch">
      <Checkbox
        id={`enable__${labelInfoID}__${labelProperty}`}
        mode="switch"
        checked={checked}
        onChange={(checked) =>
          onLabelPropertyChange(
            labelInfoID,
            shipperType,
            labelProperty,
            checked,
          )
        }
      />
    </div>
  )
}

EnableLabelProperty.propTypes = {
  labelProperty: PropTypes.string,
  checkedSelector: PropTypes.func,
}
