import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  MANUAL,
  FULFILLMENT_CHANNEL_FBA,
  FULFILLMENT_CHANNEL_FBM,
} from '../../../common/constants/CartVendorOptions.js'
import {PRODUCT_DETAIL_SALES_CHANNELS_PANEL} from '../../../common/constants/Products.js'
import {usesInventorySelector} from '../../../data/company.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import {
  productCartsSelector,
  getProductCartFulfillmentChannel,
  fulfillmentChannelCountsSelector,
} from '../../../data/products.js'
import {hasLinkedWarehousesSelector} from '../../../data/carts.js'
import {getProductDetailURL} from '../../ProductDetailPage/productDetailSelectors.js'
import AdditionalInfo from './AdditionalInfo/index.js'
import WritebackCellItem from './WritebackCellItem.js'

export function WritebackCell({
  sku,
  isExpanded,
  usesInventory,
  writebackItems,
  fulfillmentChannelCounts,
}) {
  return (
    <td className="table__td table__td--product-list-writeback">
      <div className="lh-md">
        {writebackItems.length === 0 && <em>None</em>}
        {usesInventory && writebackItems.length === 1 && (
          <div>
            <LinkButton
              className="darkest"
              href={getProductDetailURL(
                sku,
                PRODUCT_DETAIL_SALES_CHANNELS_PANEL,
              )}
            >
              {writebackItems[0].display}
            </LinkButton>
            {!writebackItems[0].hasLinkedWarehouses ? (
              <LinkButton
                className="unbold"
                href={`#/settings/saleschannels/${writebackItems[0].value}/edit`}
              >
                <em>(Inventory sync disabled)</em>
              </LinkButton>
            ) : writebackItems[0].isSynced ? (
              <div className="text--lt-grey unbold fs-n2">
                {writebackItems[0].quantity === null ? (
                  <em>(Multi-location)</em>
                ) : (
                  <>(Writeback qty: {writebackItems[0].quantity})</>
                )}
              </div>
            ) : (
              <div className="text--lt-grey unbold fs-n2">
                <em>(Inventory sync disabled)</em>
              </div>
            )}
            {writebackItems[0].amazonFulfillmentChannel ===
            FULFILLMENT_CHANNEL_FBA ? (
              <div className="label__callout label__callout--amazon-fba inline-block margin-right-5 margin-top-3">
                <strong>FBA</strong>
              </div>
            ) : writebackItems[0].amazonFulfillmentChannel ===
              FULFILLMENT_CHANNEL_FBM ? (
              <div className="label__callout label__callout--amazon-fbm inline-block margin-right-5 margin-top-3">
                <strong>FBM</strong>
              </div>
            ) : null}
          </div>
        )}
        {!usesInventory && writebackItems.length === 1 && (
          <span>{writebackItems[0].display}</span>
        )}
        {writebackItems.length > 1 && (
          <>
            <div>
              <LinkButton
                href={getProductDetailURL(
                  sku,
                  PRODUCT_DETAIL_SALES_CHANNELS_PANEL,
                )}
              >
                <em>Multi-channel</em>
              </LinkButton>
            </div>
            {!isExpanded &&
              fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA] > 0 && (
                <div className="label__callout label__callout--amazon-fba inline-block margin-right-5 margin-top-3">
                  <strong>
                    FBA
                    {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA] > 1
                      ? ` (${fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBA]})`
                      : ''}
                  </strong>
                </div>
              )}
            {!isExpanded &&
              fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM] > 0 && (
                <div className="label__callout label__callout--amazon-fbm inline-block margin-right-5 margin-top-3">
                  <strong>
                    FBM
                    {fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM] > 1
                      ? ` (${fulfillmentChannelCounts[FULFILLMENT_CHANNEL_FBM]})`
                      : ''}
                  </strong>
                </div>
              )}
          </>
        )}
      </div>
      {writebackItems.length > 1 && (
        <AdditionalInfo>
          {writebackItems.map((item) => (
            <WritebackCellItem
              key={item.value}
              sku={sku}
              item={item}
              usesInventory={usesInventory}
            />
          ))}
        </AdditionalInfo>
      )}
    </td>
  )
}

WritebackCell.propTypes = {
  sku: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  usesInventory: PropTypes.bool.isRequired,
  writebackItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      isSynced: PropTypes.bool.isRequired,
      hasLinkedWarehouses: PropTypes.bool.isRequired,
      inSync: PropTypes.bool.isRequired,
      amazonFulfillmentChannel: PropTypes.string,
    }),
  ).isRequired,
  fulfillmentChannelCounts: PropTypes.shape({
    [FULFILLMENT_CHANNEL_FBA]: PropTypes.number.isRequired,
    [FULFILLMENT_CHANNEL_FBM]: PropTypes.number.isRequired,
  }).isRequired,
}

function mapStateToProps(state, {sku}) {
  return {
    usesInventory: usesInventorySelector(state),
    writebackItems: productCartsSelector(state, {sku})
      .filter(({vendor}) => vendor !== MANUAL)
      .map((cart) => ({
        value: cart.id,
        display: cart.name,
        quantity:
          !cart.cart_aoh_calculated ||
          Object.keys(cart.cart_aoh_calculated).length > 1
            ? null
            : cart.cart_aoh_calculated.total_aoh,
        isSynced: cart.sync,
        hasLinkedWarehouses: hasLinkedWarehousesSelector(state, {
          cartID: cart.id,
        }),
        inSync: !cart.inventory_changed,
        amazonFulfillmentChannel: getProductCartFulfillmentChannel(cart),
      })),
    fulfillmentChannelCounts: fulfillmentChannelCountsSelector(state, {sku}),
  }
}

export default connect(mapStateToProps)(WritebackCell)
