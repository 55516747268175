import isEmpty from 'lodash/isEmpty.js'

import {useSelector} from '../../../store.js'
import Currency from '../../../common/components/Currency.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../common/components/Button/ButtonSecondary.js'
import {currencySymbolSelector} from '../../../data/company.js'
import {hasNoProductsSelector} from '../../../data/products.js'
import {showCreateProductModal} from '../../ProductListPage/Modals/CreateProductModal.js'
import {
  updateCreateOrderFormProductInfo,
  addCreateOrderFormProductInfoLine,
} from '../createOrderActions.js'
import {
  createOrderFormSelector,
  productInfoErrorsSelector,
  productTotalSelector,
  grandTotalSelector,
} from '../createOrderSelectors.js'
import ProductRow from './ProductRow.js'

export default function ProductInfo() {
  const {productInfo} = useSelector(createOrderFormSelector)
  const errors = useSelector(productInfoErrorsSelector)
  const productTotal = useSelector(productTotalSelector)
  const grandTotal = useSelector(grandTotalSelector)
  const currencySymbol = useSelector(currencySymbolSelector)
  const hasNoProducts = useSelector(hasNoProductsSelector)

  const hasErrors = !isEmpty(errors)

  return (
    <div className="medium-12 columns">
      <div className="panel panel--form-wrap margin-bottom-20">
        <div className="panel__header">
          <h3 className="fs-01">Order Contents</h3>
        </div>
        <div className="panel__body padding-top-0">
          <table className="table">
            {productInfo.lines.length > 0 && (
              <thead>
                <tr>
                  <th className="table__th table__th--sm">Product</th>
                  <th className="table__th table__th--sm th-width-20">
                    Additional Details
                  </th>
                  <th className="table__th table__th--sm th-width-20">
                    Serial Number(s)
                  </th>
                  <th className="table__th table__th--sm th-width-10">Qty</th>
                  <th className="table__th table__th--sm th-width-10">
                    Item Price
                  </th>
                  <th className="table__th table__th--sm th-width-10">
                    Discount
                  </th>
                  <th className="table__th table__th--sm th-width-10">
                    Total Price
                  </th>
                  <th className="table__th table__th--sm w-1">&nbsp;</th>
                </tr>
              </thead>
            )}
            <tbody className="table__tbody table__tbody--lines">
              {productInfo.lines.map((line, index) => (
                <ProductRow key={index} index={index} line={line} />
              ))}
              <tr>
                <td
                  className="table__td padding-left-0 align-center"
                  colSpan="7"
                >
                  {!hasNoProducts ? (
                    <>
                      <ButtonPrimary
                        isOutlined
                        size="sm"
                        onClick={() => addCreateOrderFormProductInfoLine()}
                      >
                        Add an Existing Product
                      </ButtonPrimary>

                      <ButtonSecondary
                        size="sm"
                        onClick={() =>
                          showCreateProductModal({addToCreateOrder: true})
                        }
                      >
                        Create a New Product
                      </ButtonSecondary>
                    </>
                  ) : (
                    <div className="alert alert--warning-lt alert--lg w-65 margin-auto margin-top-20 margin-bottom-20">
                      <div
                        className="i-exclamation-triangle fs-04 op-30 lh-sm margin-bottom-5"
                        aria-hidden="true"
                      />
                      <h3 className="fs-02 margin-bottom-10">
                        <strong>There are no products in your account.</strong>
                      </h3>
                      <p className="fs-01 lh-md margin-bottom-20">
                        You need to have at least product in Ordoro before you
                        can create an order.
                      </p>
                      <div className="margin-bottom-5">
                        <ButtonPrimary
                          alt
                          size="md"
                          onClick={() =>
                            showCreateProductModal({addToCreateOrder: true})
                          }
                        >
                          Create a New Product
                        </ButtonPrimary>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
            <tbody className="table__tbody table__tbody--totals">
              <tr>
                <td className="table__td fs-n1 align-right" colSpan="6">
                  <strong>Product Total</strong>
                </td>
                <td className="table__td fs-00" colSpan="2">
                  {hasErrors ? (
                    <span>--</span>
                  ) : (
                    <strong>
                      <Currency value={productTotal} />
                    </strong>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="table__td fs-n1 align-right v-align-middle"
                  colSpan="6"
                >
                  <strong>Discount</strong>
                </td>
                <td className="table__td" colSpan="2">
                  <div className="flex">
                    <div className="wrap--input-prefix flex--justify-col">
                      <strong className="fs-n1 text--md-grey">
                        {currencySymbol}
                      </strong>
                    </div>
                    <input
                      type="text"
                      className="input--text input--med-h input--sm input--w-prefix"
                      value={productInfo.discountAmount}
                      onChange={(event) =>
                        updateCreateOrderFormProductInfo({
                          discountAmount: event.target.value,
                        })
                      }
                    />
                  </div>
                  {errors.discountAmount && (
                    <small className="input__error">
                      {errors.discountAmount}
                    </small>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="table__td fs-n1 align-right v-align-middle"
                  colSpan="6"
                >
                  <strong>Shipping and Handling</strong>
                </td>
                <td className="table__td" colSpan="2">
                  <div className="flex">
                    <div className="wrap--input-prefix flex--justify-col">
                      <strong className="fs-n1 text--md-grey">
                        {currencySymbol}
                      </strong>
                    </div>
                    <input
                      type="text"
                      className="input--text input--med-h input--sm input--w-prefix"
                      value={productInfo.shippingAmount}
                      onChange={(event) =>
                        updateCreateOrderFormProductInfo({
                          shippingAmount: event.target.value,
                        })
                      }
                    />
                  </div>
                  {errors.shippingAmount && (
                    <small className="input__error">
                      {errors.shippingAmount}
                    </small>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="table__td fs-n1 align-right v-align-middle"
                  colSpan="6"
                >
                  <strong>Tax</strong>
                </td>
                <td className="table__td" colSpan="2">
                  <div className="flex">
                    <div className="wrap--input-prefix flex--justify-col">
                      <strong className="fs-n1 text--md-grey">
                        {currencySymbol}
                      </strong>
                    </div>
                    <input
                      type="text"
                      className="input--text input--med-h input--sm input--w-prefix"
                      value={productInfo.taxAmount}
                      onChange={(event) =>
                        updateCreateOrderFormProductInfo({
                          taxAmount: event.target.value,
                        })
                      }
                    />
                  </div>
                  {errors.taxAmount && (
                    <small className="input__error">{errors.taxAmount}</small>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  className="table__td table__td--grand-total fs-n1 align-right"
                  colSpan="6"
                >
                  <strong>Grand Total</strong>
                </td>
                <td
                  className="table__td table__td--grand-total fs-01"
                  colSpan="2"
                >
                  {hasErrors ? (
                    <span>--</span>
                  ) : (
                    <strong>
                      <Currency value={grandTotal} />
                    </strong>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
