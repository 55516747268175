import * as Sentry from '@sentry/browser'
import cookies from 'cookies-js'
import get from 'lodash/get.js'
import isError from 'lodash/isError.js'

import {getState} from '../store.js'
import {companyFeaturesSelector, planSelector} from '../data/company.js'
import {getFullStoryLinks} from './analytics/fullStory.js'
import datadogRum from './analytics/datadogRum.js'

function setPlanContext(scope) {
  const state = getState ? getState() : null
  const plan = state
    ? planSelector(state)
    : get(window, 'ORDORO_BOOTSTRAP.company.plan')

  if (plan) {
    scope.setTag('plan', plan)
  }
}

function setFeaturesContext(scope) {
  const state = getState ? getState() : null
  const features = state
    ? companyFeaturesSelector(state)
    : get(window, 'ORDORO_BOOTSTRAP.company.features')

  if (features) {
    scope.setExtra('features', features)
  }
}

function setZuckContext(scope) {
  const zuck = cookies.get('zuck')
  if (zuck) {
    scope.setTag('zuck', zuck)
  }
}

function setSagaContext(scope, err) {
  if (err && err.sagaStack) {
    scope.setExtra('sagaStack', err.sagaStack)
  }
}

function setFullStoryContext(scope) {
  const links = getFullStoryLinks()

  if (links.sessionUrl) {
    scope.setExtra('fullStory', links)
  }
}

export function logError(error, errorMessage = error && error.message) {
  // Don't log errors in tests
  if (!window.ORDORO_PREVENT_LOGGER) {
    if (error.sagaStack) {
      console.group(errorMessage)
      console.error(error)
      console.group('Saga stack')
      console.error(error.sagaStack)
      console.groupEnd()
      console.groupEnd()
    } else {
      if (errorMessage && error.message !== errorMessage) {
        console.error(errorMessage)
      }
      console.error(error)
    }
  }

  datadogRum.addError(error)

  Sentry.withScope((scope) => {
    setPlanContext(scope)
    setFeaturesContext(scope)
    setZuckContext(scope)
    setSagaContext(scope, error)
    setFullStoryContext(scope)

    Sentry.captureException(
      isError(error)
        ? error
        : new Error(
            error
              ? error.message || error.error_message || JSON.stringify(error)
              : 'Invalid error encountered',
          ),
    )
  })
}
