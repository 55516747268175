import {useSelector} from '../../../../store.js'
import {CREATE_RETURN_ORDER_OTHER_ADDRESS} from '../../../../common/constants/AddressTypes.js'
import {showPasteAddressModal} from '../../../../common/components/Modal/PasteAddressModal.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import TextInput from '../../../../common/components/TextInput.js'
import {
  setCreateReturnOrderModalFormValue,
  createReturnOrderModalFormSelector,
} from './createReturnOrderFunctions.js'

export default function OtherAddressForm() {
  const form = useSelector(createReturnOrderModalFormSelector)

  return (
    <div className="row">
      <div className="medium-7 columns">
        <ul className="list list--no-style margin-top-5 margin-bottom-10">
          <li className="list__item fs-n1 margin-top-15 margin-bottom-15">
            <ButtonPrimary
              isOutlined
              size="x-sm"
              onClick={() =>
                showPasteAddressModal({
                  addressType: CREATE_RETURN_ORDER_OTHER_ADDRESS,
                  address: form.otherAddress,
                })
              }
            >
              Paste Address
            </ButtonPrimary>
          </li>
          <li className="list__item list__item--form">
            <TextInput
              label="Name*"
              className="margin-bottom-0"
              id="other_address__name"
              value={form.otherAddress.name}
              onChange={(value) =>
                setCreateReturnOrderModalFormValue('otherAddress.name', value)
              }
            />
          </li>
          <li className="list__item list__item--form">
            <TextInput
              label="Company"
              className="margin-bottom-0"
              id="other_address__company"
              value={form.otherAddress.company}
              onChange={(value) =>
                setCreateReturnOrderModalFormValue(
                  'otherAddress.company',
                  value,
                )
              }
            />
          </li>
          <li className="list__item list__item--form flex--justify">
            <div className="wrap--input-half">
              <TextInput
                label="Phone"
                className="margin-bottom-0"
                id="other_address__phone"
                value={form.otherAddress.phone}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue(
                    'otherAddress.phone',
                    value,
                  )
                }
              />
            </div>
            <div className="wrap--input-half">
              <TextInput
                label="Email"
                className="margin-bottom-0"
                id="other_address__email"
                value={form.otherAddress.email}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue(
                    'otherAddress.email',
                    value,
                  )
                }
              />
            </div>
          </li>
          <li className="list__item list__item--form">
            <TextInput
              label="Street 1*"
              className="margin-bottom-0"
              id="other_address__street1"
              value={form.otherAddress.street1}
              onChange={(value) =>
                setCreateReturnOrderModalFormValue(
                  'otherAddress.street1',
                  value,
                )
              }
            />
          </li>
          <li className="list__item list__item--form">
            <TextInput
              label="Street 2"
              className="margin-bottom-0"
              id="other_address__street2"
              value={form.otherAddress.street2}
              onChange={(value) =>
                setCreateReturnOrderModalFormValue(
                  'otherAddress.street2',
                  value,
                )
              }
            />
          </li>
          <li className="list__item list__item--form flex--justify">
            <div className="wrap--input-half">
              <TextInput
                label="City"
                id="other_address__city"
                value={form.otherAddress.city}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue('otherAddress.city', value)
                }
              />
            </div>
            <div className="wrap--input-eighth">
              <TextInput
                label="State"
                id="other_address__state"
                value={form.otherAddress.state}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue(
                    'otherAddress.state',
                    value,
                  )
                }
              />
            </div>
            <div className="wrap--input-third">
              <TextInput
                label="Zip"
                id="other_address__zip"
                value={form.otherAddress.zip}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue('otherAddress.zip', value)
                }
              />
            </div>
          </li>
          <li className="list__item list__item--form flex--justify">
            <div className="wrap--input-half">
              <TextInput
                label="Country"
                id="other_address__country"
                value={form.otherAddress.country}
                onChange={(value) =>
                  setCreateReturnOrderModalFormValue(
                    'otherAddress.country',
                    value,
                  )
                }
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
