import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import {
  INITIATED_STATUS,
  ACTIVE_STATUS,
  FINISHED_STATUS,
  CANCELLED_STATUS,
  RETURN_ORDER_STATUS_DISPLAY,
} from '../../../common/constants/ReturnOrders.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'

const MODAL_FORM = 'UPDATE_RETURN_ORDERS_STATUS_MODAL_FORM'
export const UPDATE_RETURN_ORDERS_STATUS = 'UPDATE_RETURN_ORDERS_STATUS'

export function showUpdateReturnOrdersStatusModal(
  referenceIDs,
  status = INITIATED_STATUS,
) {
  return setForm(MODAL_FORM, {
    referenceIDs,
    status,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function updateReturnOrdersStatus() {
  return {
    type: UPDATE_RETURN_ORDERS_STATUS,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* updateReturnOrderStatus(referenceID, status) {
  const {json} = yield call(
    apiverson.put,
    `/return_order/${encodeURIComponent(referenceID)}`,
    {status},
  )

  yield put(setReturnOrder(json))
}

export function* updateReturnOrdersStatusWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceIDs, status} = yield select(modalFormSelector)
    const count = referenceIDs.length

    yield all(
      referenceIDs.map((referenceID) =>
        call(updateReturnOrderStatus, referenceID, status),
      ),
    )

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${count} RMA${count !== 1 ? 's were' : ' was'} updated`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error setting RMA status: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function UpdateReturnOrdersStatusModal({form, ...props}) {
  const count = form.referenceIDs.length

  return (
    <ConfirmModal
      title="Update RMA Status"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={() => props.updateReturnOrdersStatus()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {count === 1 && (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              <strong>{form.referenceIDs[0]}</strong>
            </div>
          </li>
        )}
        <li className="list__item--form list__item--no-style fs-01">
          Update the status for {count !== 1 ? count : 'this'} RMA
          {count !== 1 ? 's' : ''}:
        </li>
        {[
          INITIATED_STATUS,
          ACTIVE_STATUS,
          FINISHED_STATUS,
          CANCELLED_STATUS,
        ].map((status) => (
          <li className="list__item--no-style" key={status}>
            <label className="fs-00" htmlFor={status}>
              <input
                className="margin-right-7"
                type="radio"
                name="return_order_line_code"
                id={status}
                value={status}
                checked={status === form.status}
                onChange={() => props.updateModalForm({status})}
              />
              {RETURN_ORDER_STATUS_DISPLAY[status]}
            </label>
          </li>
        ))}
        {form.status === CANCELLED_STATUS && (
          <li className="list__item--no-style">
            <div className="alert alert--standard margin-bottom-0">
              <div>
                <strong>
                  Heads up — cancelling an RMA does not affect inventory.
                </strong>
              </div>
              If you’ve restocked any returned products associated with this
              RMA, the inventory values WILL NOT be reset.
            </div>
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

UpdateReturnOrdersStatusModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  updateReturnOrdersStatus: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  updateReturnOrdersStatus,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(UpdateReturnOrdersStatusModal),
  modalFormSelector,
)
