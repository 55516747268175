import {
  REPORT_EXPORT_ORDERS,
  REPORT_EXPORT_SHIPPED_ORDERS,
  REPORT_EXPORT_PRODUCTS,
  REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
  REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
  REPORT_EXPORT_PRODUCTS_SUPPLIERS,
  REPORT_EXPORT_RMA_SUMMARY,
  REPORT_EXPORT_RMA_DETAIL,
  REPORT_EXPORT_SKU_SALES,
  REPORT_EXPORT_DELIVERY_STATUS_DETAIL,
  REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
  REPORT_EXPORT_FULFILLMENT_SPEED,
  REPORT_EXPORT_LOW_INVENTORY,
  REPORT_LOW_INVENTORY_REPORT,
  REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
  REPORT_EXPORT_PURCHASE_ORDER_DETAIL,
  REPORT_EXPORT_GOODS_RECEIPT,
} from '../../../common/constants/Reports.js'
import ExportDeliveryStatusForm, {
  exportDeliveryStatusErrorsSelector,
  exportDeliveryStatusPayloadSelector,
  setupExportDeliveryStatusForm,
} from './ExportDeliveryStatus.js'
import ExportOrdersForm, {
  exportOrdersErrorsSelector,
  exportOrdersPayloadSelector,
  setupExportOrdersForm,
} from './ExportOrders.js'
import ExportPOGoodsReceiptsForm, {
  setupExportPOGoodsReceiptsForm,
  exportPOGoodsReceiptsErrorsSelector,
  exportPOGoodsReceiptsPayloadSelector,
} from './ExportPOGoodsReceipts.js'
import ExportPOsForm, {
  exportPOsErrorsSelector,
  exportPOsPayloadSelector,
  setupExportPOsForm,
} from './ExportPOs.js'
import ExportProductsForm, {
  setupExportProductsForm,
  exportProductsErrorsSelector,
  exportProductsPayloadSelector,
} from './ExportProducts.js'
import ExportProductsFulfillmentSpeedForm, {
  exportProductsFulfillmentSpeedErrorsSelector,
  exportProductsFulfillmentSpeedPayloadSelector,
  setupExportProductsFulfillmentSpeedForm,
} from './ExportProductsFulfillmentSpeed.js'
import ExportProductsSalesChannelsForm, {
  exportProductsSalesChannelsErrorsSelector,
  exportProductsSalesChannelsPayloadSelector,
  setupExportProductsSalesChannelsForm,
} from './ExportProductsSalesChannels.js'
import ExportRMAsForm, {
  exportRMAsErrorsSelector,
  exportRMAsPayloadSelector,
  setupExportRMAsForm,
} from './ExportRMAs.js'
import ExportSKUSalesForm, {
  exportSKUSalesErrorsSelector,
  exportSKUSalesPayloadSelector,
  setupExportSKUSalesForm,
} from './ExportSKUSales.js'
import ExportFulfillmentSpeedForm, {
  exportFulfillmentSpeedErrorsSelector,
  exportFulfillmentSpeedPayloadSelector,
  setupExportFulfillmentSpeedForm,
} from './ExportFulfillmentSpeed.js'
import ExportLowInventoryForm, {
  exportLowInventoryErrorsSelector,
  exportLowInventoryPayloadSelector,
  setupExportLowInventoryForm,
} from './ExportLowInventory.js'

export default {
  [REPORT_EXPORT_ORDERS]: {
    type: REPORT_EXPORT_ORDERS,
    Component: ExportOrdersForm,
    setupForm: setupExportOrdersForm,
    errorsSelector: exportOrdersErrorsSelector,
    payloadSelector: exportOrdersPayloadSelector,
  },
  [REPORT_EXPORT_SHIPPED_ORDERS]: {
    type: REPORT_EXPORT_SHIPPED_ORDERS,
    Component: ExportOrdersForm,
    setupForm: setupExportOrdersForm,
    errorsSelector: exportOrdersErrorsSelector,
    payloadSelector: exportOrdersPayloadSelector,
  },
  [REPORT_EXPORT_PURCHASE_ORDER_DETAIL]: {
    type: REPORT_EXPORT_PURCHASE_ORDER_DETAIL,
    Component: ExportPOsForm,
    setupForm: setupExportPOsForm,
    errorsSelector: exportPOsErrorsSelector,
    payloadSelector: exportPOsPayloadSelector,
  },
  [REPORT_EXPORT_PURCHASE_ORDER_SUMMARY]: {
    type: REPORT_EXPORT_PURCHASE_ORDER_SUMMARY,
    Component: ExportPOsForm,
    setupForm: setupExportPOsForm,
    errorsSelector: exportPOsErrorsSelector,
    payloadSelector: exportPOsPayloadSelector,
  },
  [REPORT_EXPORT_GOODS_RECEIPT]: {
    type: REPORT_EXPORT_GOODS_RECEIPT,
    Component: ExportPOGoodsReceiptsForm,
    setupForm: setupExportPOGoodsReceiptsForm,
    errorsSelector: exportPOGoodsReceiptsErrorsSelector,
    payloadSelector: exportPOGoodsReceiptsPayloadSelector,
  },
  [REPORT_EXPORT_PRODUCTS]: {
    type: REPORT_EXPORT_PRODUCTS,
    Component: ExportProductsForm,
    setupForm: setupExportProductsForm,
    errorsSelector: exportProductsErrorsSelector,
    payloadSelector: exportProductsPayloadSelector,
  },
  [REPORT_EXPORT_PRODUCTS_SALES_CHANNELS]: {
    type: REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
    Component: ExportProductsSalesChannelsForm,
    setupForm: setupExportProductsSalesChannelsForm,
    errorsSelector: exportProductsSalesChannelsErrorsSelector,
    payloadSelector: exportProductsSalesChannelsPayloadSelector,
  },
  [REPORT_EXPORT_PRODUCTS_SUPPLIERS]: {
    type: REPORT_EXPORT_PRODUCTS_SUPPLIERS,
    Component: ExportProductsForm,
    setupForm: setupExportProductsForm,
    errorsSelector: exportProductsErrorsSelector,
    payloadSelector: exportProductsPayloadSelector,
  },
  [REPORT_EXPORT_RMA_SUMMARY]: {
    type: REPORT_EXPORT_RMA_SUMMARY,
    Component: ExportRMAsForm,
    setupForm: setupExportRMAsForm,
    errorsSelector: exportRMAsErrorsSelector,
    payloadSelector: exportRMAsPayloadSelector,
  },
  [REPORT_EXPORT_RMA_DETAIL]: {
    type: REPORT_EXPORT_RMA_DETAIL,
    Component: ExportRMAsForm,
    setupForm: setupExportRMAsForm,
    errorsSelector: exportRMAsErrorsSelector,
    payloadSelector: exportRMAsPayloadSelector,
  },
  [REPORT_EXPORT_SKU_SALES]: {
    type: REPORT_EXPORT_SKU_SALES,
    Component: ExportSKUSalesForm,
    setupForm: setupExportSKUSalesForm,
    errorsSelector: exportSKUSalesErrorsSelector,
    payloadSelector: exportSKUSalesPayloadSelector,
  },
  [REPORT_EXPORT_DELIVERY_STATUS_DETAIL]: {
    type: REPORT_EXPORT_DELIVERY_STATUS_DETAIL,
    Component: ExportDeliveryStatusForm,
    setupForm: setupExportDeliveryStatusForm,
    errorsSelector: exportDeliveryStatusErrorsSelector,
    payloadSelector: exportDeliveryStatusPayloadSelector,
  },
  [REPORT_EXPORT_DELIVERY_STATUS_SUMMARY]: {
    type: REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
    Component: ExportDeliveryStatusForm,
    setupForm: setupExportDeliveryStatusForm,
    errorsSelector: exportDeliveryStatusErrorsSelector,
    payloadSelector: exportDeliveryStatusPayloadSelector,
  },
  [REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED]: {
    type: REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
    Component: ExportProductsFulfillmentSpeedForm,
    setupForm: setupExportProductsFulfillmentSpeedForm,
    errorsSelector: exportProductsFulfillmentSpeedErrorsSelector,
    payloadSelector: exportProductsFulfillmentSpeedPayloadSelector,
  },
  [REPORT_EXPORT_FULFILLMENT_SPEED]: {
    type: REPORT_EXPORT_FULFILLMENT_SPEED,
    Component: ExportFulfillmentSpeedForm,
    setupForm: setupExportFulfillmentSpeedForm,
    errorsSelector: exportFulfillmentSpeedErrorsSelector,
    payloadSelector: exportFulfillmentSpeedPayloadSelector,
  },
  [REPORT_EXPORT_LOW_INVENTORY]: {
    type: REPORT_EXPORT_LOW_INVENTORY,
    Component: ExportLowInventoryForm,
    setupForm: setupExportLowInventoryForm,
    errorsSelector: exportLowInventoryErrorsSelector,
    payloadSelector: exportLowInventoryPayloadSelector,
  },
  [REPORT_LOW_INVENTORY_REPORT]: {
    type: REPORT_LOW_INVENTORY_REPORT,
    Component: ExportLowInventoryForm,
    setupForm: setupExportLowInventoryForm,
    errorsSelector: exportLowInventoryErrorsSelector,
    payloadSelector: exportLowInventoryPayloadSelector,
  },
}
