import PropTypes from 'prop-types'

import {onlyIfForm, useSelector} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {
  closeModal,
  modalFormSelector,
  applyFilter,
  showBatchListFilterModal,
  STATUS_FILTER_PANEL,
  batchListFilterOrderSelector,
} from './batchListFilterModalFunctions.js'
import StatusFilterPanel from './StatusFilterPanel.js'

const FILTER_PANEL_LIST = {
  [STATUS_FILTER_PANEL]: StatusFilterPanel,
}

function BatchListFilterModal() {
  const filterList = useSelector(batchListFilterOrderSelector)

  return (
    <ConfirmModal
      title="Batch Filters"
      modalSize="md"
      className="modal--order-list-filters"
      onConfirm={() => applyFilter()}
      onCancel={() => closeModal()}
      confirmText="Apply"
      cancelText="Cancel"
      preventHotKeyConfirm
      RightButtons={() => (
        <div className="flex--justify">
          <ButtonPrimary
            isOutlined
            className="btn--sm margin-right-10"
            onClick={() => showBatchListFilterModal({clear: true})}
          >
            Clear Filters
          </ButtonPrimary>
        </div>
      )}
    >
      {filterList.map((item) => {
        const Panel = FILTER_PANEL_LIST[item]

        return Panel ? <Panel key={item} /> : null
      })}
    </ConfirmModal>
  )
}

BatchListFilterModal.propTypes = {
  form: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    active: PropTypes.bool,
  }).isRequired,
}

export default onlyIfForm(BatchListFilterModal, modalFormSelector)
