import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {formatAPIURL} from '../../common/api.js'
import {formConnectAuto} from '../../common/formConnect.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showEditReportModal} from './Modals/EditReportModal.js'
import {
  setupReportsPageRowForm,
  getReportRuns,
  startReportRun,
} from './reportsPageActions.js'
import {REPORT_SCHEDULE_FREQUENCY_DISPLAY} from '../../common/constants/Reports.js'

function ReportRow({report, form, showLogLink}) {
  useEffect(() => {
    getReportRuns(report.id)
  }, [report.id])

  const allComplete = form.runs.reduce(
    (prev, {complete}) => (!prev ? false : complete),
    true,
  )
  const schedule = report.schedules[0]

  return (
    <tr>
      <td className="table__td wrap--btn--edit-icon">
        <div className="margin-bottom-7">
          <strong className="fs-01 lh-md v-align-middle margin-right-5">
            {report.name}
          </strong>
          <ButtonLink
            className="btn--edit-icon no-underline"
            onClick={() =>
              showEditReportModal({reportID: report.id, autoFocusOn: 'name'})
            }
          >
            <span
              className="i-pencil fs-00 lh-0 v-align-middle"
              aria-hidden="true"
            />
          </ButtonLink>
        </div>

        <ButtonPrimary
          size="x-sm"
          alt
          isLoading={form.isLoading || !allComplete}
          onClick={() => startReportRun(report.id)}
        >
          Run Now
        </ButtonPrimary>
      </td>
      <td className="table__td">
        {report.to_emails.length > 0 && (
          <div className="fs-00 lh-md margin-bottom-3">
            {report.to_emails.map((to_emails, index) => (
              <span key={index}>
                <span>{to_emails}</span>
                {report.to_emails.length - 1 !== index ? ', ' : ''}
              </span>
            ))}
          </div>
        )}

        <div>
          <ButtonLink
            className="fs-n0"
            onClick={() =>
              showEditReportModal({
                reportID: report.id,
                autoFocusOn: 'to_emails',
              })
            }
          >
            {report.to_emails.length === 0 ? (
              <em className="lh-lg">Add an email address</em>
            ) : (
              'Edit'
            )}
          </ButtonLink>
        </div>
      </td>
      <td className="table__td">
        {schedule && (
          <div className="fs-00 lh-md margin-bottom-3">
            {REPORT_SCHEDULE_FREQUENCY_DISPLAY[schedule.frequency]}
            {!schedule.active && ' (inactive)'}
          </div>
        )}
        <div>
          <ButtonLink
            className="fs-n0"
            onClick={() =>
              showEditReportModal({
                reportID: report.id,
                autoFocusOn: 'frequency',
              })
            }
          >
            {schedule ? 'Edit' : <em className="lh-lg">Schedule report</em>}
          </ButtonLink>
        </div>
      </td>
      <td className="table__td">
        <ul className="list list--square">
          {form.isLoading ? (
            <li className="list__item list__item--no-style fs-n0">
              <div className="loading">
                <span className="spinner--sm v-align-middle margin-right-3" />
                <strong className="v-align-middle lh-sm">
                  <em className="fs-n0 text--md-grey">Fetching reports...</em>
                </strong>
              </div>
            </li>
          ) : form.runs.length > 0 ? (
            form.runs.map((run) => (
              <li key={run.id} className="list__item fs-n0">
                {run.complete ? (
                  <div className="flex--justify">
                    {run.success ? (
                      <div>
                        <LinkButton
                          title="Download this report"
                          href={formatAPIURL(run.csv_link)}
                        >
                          <strong>
                            <FormattedDate
                              value={run.created}
                              format="MMM D, YYYY [at] h:mma"
                            />
                          </strong>
                        </LinkButton>
                        <span className="fs-00 margin-left-3 v-align-middle lh-sm">
                          ⇣
                        </span>
                      </div>
                    ) : (
                      <strong className="error error-message">
                        <span>Failed: </span>
                        <FormattedDate
                          value={run.created}
                          format="MMM D, YYYY [at] h:mma"
                        />{' '}
                      </strong>
                    )}
                    {showLogLink && run.log_link && (
                      <LinkButton
                        isOutlined
                        size="x-sm"
                        title="View Report Log"
                        className="btn--primary no-underline margin-left-10"
                        href={run.log_link}
                        target="_blank"
                      >
                        <strong>Log</strong>
                      </LinkButton>
                    )}
                  </div>
                ) : (
                  <div className="loading">
                    <span className="spinner--sm v-align-middle margin-right-3" />
                    <strong className="v-align-middle lh-sm">
                      <em className="fs-n0 text--md-grey">
                        Creating report...
                      </em>
                    </strong>
                  </div>
                )}
              </li>
            ))
          ) : (
            <li className="list__item list__item--no-style fs-00">
              <em className="fs-n0 text--md-grey">No reports have been run</em>
            </li>
          )}
        </ul>
      </td>
    </tr>
  )
}

ReportRow.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    to_emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        frequency: PropTypes.string,
        to_emails: PropTypes.arrayOf(PropTypes.string).isRequired,
        active: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }),
  form: PropTypes.shape({
    runs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        created: PropTypes.string.isRequired,
        complete: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        csv_link: PropTypes.string,
      }),
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
  }),
  showLogLink: PropTypes.bool,
}

export default formConnectAuto(ReportRow, setupReportsPageRowForm)
