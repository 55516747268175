import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'

import {hasReportsPermissionSelector} from '../../data/me.js'
import {stringifyURL} from '../../common/querystring.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {currentDateSelector} from '../CurrentDateListener.js'
import subDays from 'date-fns/subDays'
import isAfter from 'date-fns/isAfter'
import {
  REPORT_EXPORT_RMA_DETAIL,
  REPORT_EXPORT_RMA_SUMMARY,
  SINGLE_RUN_REPORTS,
  SCHEDULABLE_REPORTS,
} from '../../common/constants/Reports.js'
import {hasUseReturnOrdersFeatureSelector} from '../../data/company.js'

export const REPORTS_PAGE_URI_COMPONENT = 'reports'
export const REPORTS_PAGE_FORM = 'REPORTS_PAGE'
export const REPORTS_PAGE_ROW_FORM_PREFIX = 'REPORTS_PAGE_ROW'
export const GET_REPORT_RUNS = 'GET_REPORT_RUNS'
export const START_REPORT_RUN = 'START_REPORT_RUN'
export const WATCH_REPORT_RUNS = 'WATCH_REPORT_RUNS'
export const REPORTS_PANEL_REPORTS = 'reports'
export const REPORTS_PANEL_ACTIVITY = 'activity'
export const REPORTS_PANELS = [REPORTS_PANEL_REPORTS, REPORTS_PANEL_ACTIVITY]
export const DEFAULT_REPORTS_PANEL = REPORTS_PANEL_REPORTS
export const DEFAULT_REPORTS_LIMIT = 10

export function reportPageFormSelector(state) {
  return state.ui.forms[REPORTS_PAGE_FORM] || {}
}

export function reportIDSelector(state) {
  const {reportID} = reportPageFormSelector(state)

  return reportID
}

export function formatReportRowFormName(reportID) {
  return `${REPORTS_PAGE_ROW_FORM_PREFIX}_${reportID}`
}

export const reportsPanelSelector = createSelector(
  locationSelector,
  ({pathComponents: [, panel]} = {}) =>
    REPORTS_PANELS.includes(panel) ? panel : DEFAULT_REPORTS_PANEL,
)

export function reportsPageQuerySelector(state) {
  reportsPageQuerySelector.cache = reportsPageQuerySelector.cache || {}
  const cache = reportsPageQuerySelector.cache

  const location = locationSelector(state)
  const panel = reportsPanelSelector(state) // part of location so don't check or cache

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  const {query} = location

  const result = {
    panel,
    report_id: query.report_id,
    report_type: query.report_type,
    limit: Number(query.limit) || DEFAULT_REPORTS_LIMIT,
    offset: Number(query.offset) || 0,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export function reportsActivityQuerySelector(state) {
  reportsActivityQuerySelector.cache = reportsActivityQuerySelector.cache || {}
  const cache = reportsActivityQuerySelector.cache

  const query = reportsPageQuerySelector(state)

  if (query === cache.query) {
    return cache.result
  }

  const result = {
    report_id: query.report_id,
    report_type: query.report_type,
    limit: query.limit,
    offset: query.offset,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

function hashBuilder(params = {}) {
  let hash = `#/${REPORTS_PAGE_URI_COMPONENT}`

  params = {...params}
  const {panel} = params
  delete params.panel

  if (panel && panel !== DEFAULT_REPORTS_PANEL) {
    hash += `/${panel}`
  }

  if (panel !== REPORTS_PANEL_ACTIVITY) {
    delete params.limit
  }

  return stringifyURL(hash, params)
}

export const reportPagesHashSelector = createSelector(
  reportsPageQuerySelector,
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  reportPageFormSelector,
  ({sticky__limit} = {}) => ({
    panel: DEFAULT_REPORTS_PANEL,
    limit: sticky__limit !== DEFAULT_REPORTS_LIMIT ? sticky__limit : undefined,
  }),
)

export const defaultReportsHashSelector = createSelector(
  defaultHashParamsSelector,
  hashBuilder,
)

export function panelLinkSelector(state, {panel}) {
  return hashBuilder({...defaultHashParamsSelector(state), panel})
}

export function canUseReportsPageSelector(state) {
  const hasPermission = hasReportsPermissionSelector(state)

  return hasPermission
}

export function reportRowFormSelector(state, {reportID}) {
  return state.ui.forms[formatReportRowFormName(reportID)]
}

export function hasInProgressTaskSelector(state) {
  const {tasks} = reportPageFormSelector(state)
  const oldDate = subDays(currentDateSelector(state), '2')

  return !!tasks.find(
    (task) => !task.complete && isAfter(new Date(task.created), oldDate),
  )
}

export const schedulableReportsSelector = createSelector(
  hasUseReturnOrdersFeatureSelector,
  (hasUseReturnOrdersFeature) =>
    hasUseReturnOrdersFeature
      ? SCHEDULABLE_REPORTS
      : SCHEDULABLE_REPORTS.filter(
          (report) =>
            ![REPORT_EXPORT_RMA_SUMMARY, REPORT_EXPORT_RMA_DETAIL].includes(
              report,
            ),
        ),
)

export const singleRunReportsSelector = createSelector(
  hasUseReturnOrdersFeatureSelector,
  (hasUseReturnOrdersFeature) =>
    hasUseReturnOrdersFeature
      ? SINGLE_RUN_REPORTS
      : SINGLE_RUN_REPORTS.filter(
          (report) =>
            ![REPORT_EXPORT_RMA_SUMMARY, REPORT_EXPORT_RMA_DETAIL].includes(
              report,
            ),
        ),
)
