import PropTypes from 'prop-types'
import {useEffect, useCallback} from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce.js'

import {HK_CONFIRM} from '../../../../common/constants/HotKeys.js'
import useToggleTimer from '../../../../common/hooks/useToggleTimer.js'
import BarcodeScannerListener from '../../../../common/components/BarcodeScannerListener.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {acknowledgeError} from './goodsReceiptModalFunctions.js'

const SCAN_TIMEOUT = 100

export default function GoodsReceiptError({children}) {
  const [animateError, startErrorAnimation] = useToggleTimer()
  const [blockAcknowledgeKeys, startBlockAcknowledgeKeys] =
    useToggleTimer(SCAN_TIMEOUT)

  const debouncedAcknowledgeError = useCallback(
    debounce(() => {
      acknowledgeError()
    }, SCAN_TIMEOUT),
    [],
  )

  const handleKeyPress = useCallback(
    (event) => {
      if ([' '].includes(event.key) && !blockAcknowledgeKeys) {
        debouncedAcknowledgeError()
      } else {
        debouncedAcknowledgeError.cancel()

        startBlockAcknowledgeKeys()
      }
    },
    [debouncedAcknowledgeError, startBlockAcknowledgeKeys],
  )

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <div className="wrap--scan-alert meta-verify-order-error">
      <BarcodeScannerListener
        onScan={() => startErrorAnimation()}
        capturePaste
      />
      <div className="wrap--scan-alert">
        <div
          className={classNames(
            'alert alert--error alert--lg full-border align-center margin-bottom-0',
            {shake: animateError},
          )}
        >
          <div
            className="i-exclamation-triangle fs-04 op-30 lh-sm margin-bottom-5"
            aria-hidden="true"
          />

          <p className="fs-01 error margin-bottom-5">{children}</p>

          <ButtonPrimary
            isOutlined
            className="meta-verify-order-acknowledge-error-button"
            onClick={() => {
              debouncedAcknowledgeError()
            }}
            hotKey={HK_CONFIRM}
          >
            Continue
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

GoodsReceiptError.propTypes = {
  children: PropTypes.node.isRequired,
}
