import PropTypes from 'prop-types'
import classNames from 'classnames'

import ExportCSVButton from './ExportCSVButton.js'

export default function Pane({
  isLoading,
  title,
  children,
  canExport,
  onExport,
}) {
  return (
    <div
      className={classNames('panel panel--analytics clearfix', {
        loading: isLoading,
      })}
    >
      <div className="panel__header panel__header--analytics flex--justify">
        <h3 className="subheader subheader--analytics">{title}</h3>
        {canExport && onExport && <ExportCSVButton onExport={onExport} />}
      </div>
      <div className="medium-12 columns">
        <p className="graph-loading" />
        {children}
      </div>
    </div>
  )
}

Pane.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  canExport: PropTypes.bool,
  onExport: PropTypes.func,
}
