import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ButtonPrimary({
  type = 'button',
  className,
  alt,
  saving,
  disabled,
  title,
  onClick,
}) {
  return (
    <button
      type={type}
      className={classNames(
        'btn btn--primary btn--md inline-block',
        className,
        {
          'btn--primary-alt': alt,
          'btn--processing': saving,
        },
      )}
      onClick={onClick}
      disabled={disabled || saving}
    >
      {title}
    </button>
  )
}

ButtonPrimary.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.bool,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
