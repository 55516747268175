import PropTypes from 'prop-types'
import {useState} from 'react'
import classNames from 'classnames'

export default function AdditionalOptions({children}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <dl
      className={classNames(
        'list list--accordion meta-labelconfigform-form-additionaloptions',
        {open: isOpen},
      )}
    >
      <dt className="list__title--accordion">
        <button
          className="btn btn--dropdown fs-n0 meta-labelconfigform-button-additionaloptions"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          Additional Options
        </button>
      </dt>
      <dd className="list__item--accordion">{children}</dd>
    </dl>
  )
}

AdditionalOptions.propTypes = {
  children: PropTypes.node.isRequired,
}
