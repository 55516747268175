import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {closeModal} from '../../../redux/actions/ui/index.js'
import {amazonCartOptionsSelector} from '../../../redux/selectors/ui/modals/listOnAmazonModal.js'
import {MODAL_NAME} from '../../../redux/actions/ui/modals/listOnAmazonModal.js'

import {SelectOptionsShape} from '../../PropTypes.js'
import {listOnAmazon} from '../../../data/products.js'
import BaseModal from './BaseModal/index.js'
import Content from './BaseModal/Content.js'
import Footer from './BaseModal/Footer.js'
import ButtonPrimary from './BaseModal/ButtonPrimary.js'
import ButtonSecondary from './BaseModal/ButtonSecondary.js'
import {plural} from '../Plural.js'

class ListOnAmazonModal extends Component {
  static propTypes = {
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    amazonCartOptions: SelectOptionsShape.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  state = {
    selectedCartID: 0,
  }

  componentDidMount() {
    this.setSelectedCartID(this.props.amazonCartOptions[0].value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.amazonCartOptions !== this.props.amazonCartOptions) {
      this.setSelectedCartID(this.props.amazonCartOptions[0].value)
    }
  }

  onCartChanged = (event) => {
    this.setSelectedCartID(Number(event.target.value))
  }

  setSelectedCartID(selectedCartID) {
    this.setState({selectedCartID})
  }

  createTask = () => {
    const {skus} = this.props
    const {selectedCartID} = this.state

    listOnAmazon(skus, selectedCartID)

    this.props.closeModal()
  }

  render() {
    const {skus, amazonCartOptions} = this.props
    const {selectedCartID} = this.state

    return (
      <BaseModal
        title={plural(skus)`List Product${['s']} on Amazon`}
        className="modal--sm"
        modalName={MODAL_NAME}
      >
        <Content>
          <label className="margin-bottom-5" htmlFor="selected_cart_id">
            Which Amazon sales channel do you want to list on?
          </label>
          <select
            id="selected_cart_id"
            className="select"
            value={selectedCartID}
            onChange={this.onCartChanged}
          >
            {amazonCartOptions.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </Content>
        <Footer>
          <ButtonPrimary onClick={this.createTask} title="Start Listing" />
          <ButtonSecondary
            onClick={() => this.props.closeModal()}
            title="Cancel"
          />
        </Footer>
      </BaseModal>
    )
  }
}

const mapStateToProps = (state) => ({
  skus: state.ui.modals.listOnAmazonModal.skus,
  amazonCartOptions: amazonCartOptionsSelector(state),
})

const mapDispatchToProps = {
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOnAmazonModal)
