import PropTypes from 'prop-types'

import {toggleExpanded} from '../productListFunctions.js'

export default function ExpandCell({sku}) {
  return (
    <td className="table__td align-right padding-left-3 padding-right-3 table__td--product-list-expand">
      <button
        className="btn btn--link no-underline btn--expand-product-row"
        type="button"
        onClick={() => toggleExpanded(sku)}
      >
        <span
          className="i--expand fs-01 lh-0 v-align-middle"
          aria-hidden="true"
        />
      </button>
    </td>
  )
}

ExpandCell.propTypes = {
  sku: PropTypes.string.isRequired,
}
