import get from 'lodash/get.js'

import api from '../../../../../common/api.js'
import {
  IN_HOUSE_FULFILLMENT,
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
} from '../../../../../common/constants/Products.js'
import {activeSuppliersByNameSelector} from '../../../../../data/suppliers.js'
import {autoDropshipOnOrderCreationSelector} from '../../../../../data/company.js'
import {setColumnsToDataMap} from '../index.js'
import baseStrategy from './baseStrategy.js'

export default class productFulfillmentStrategy extends baseStrategy {
  static title = 'Update Product Fulfillment'
  static requiredColumns = ['sku', 'fulfillment_type']

  static getExampleRows() {
    return () => {
      return api.get('/product/', {limit: 5}).then(({json: data}) => {
        const rows = [['SKU *Req', 'Fulfillment Type *Req', 'Default Supplier']]

        data.product.forEach((product) => {
          rows.push([
            product.sku,
            product.fulfillment_type,
            get(
              product.suppliers.find(({is_default}) => is_default),
              'address.company',
              '',
            ),
          ])
        })

        return rows
      })
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/sku/i),
      fulfillment_type: find(/^fulfillment(.type)?$/i),
      default_supplier: find(/^(default.)?supplier$/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const autoDropshipOnOrderCreation =
        autoDropshipOnOrderCreationSelector(getState())
      const suppliersByName = activeSuppliersByNameSelector(getState())
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          suppliersByName,
          autoDropshipOnOrderCreation,
        )
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData(
    row,
    columnsToDataMap,
    suppliersByName,
    autoDropshipOnOrderCreation,
  ) {
    const data = this.getNewData()
    const supplierName = row[columnsToDataMap.default_supplier]
    const supplierID = get(suppliersByName[supplierName], 'id')

    let fulfillment_type = row[columnsToDataMap.fulfillment_type] || ''
    let is_fulfillment_type_valid = true

    if (fulfillment_type.match(/house/i)) {
      fulfillment_type = IN_HOUSE_FULFILLMENT
    } else if (autoDropshipOnOrderCreation && fulfillment_type.match(/auto/i)) {
      fulfillment_type = AUTOMATICALLY_DROPSHIPPED_FULFILLMENT
    } else if (fulfillment_type.match(/drop/i)) {
      fulfillment_type = DROPSHIPPABLE_FULFILLMENT
    } else {
      is_fulfillment_type_valid = false
    }

    data.payload = {
      sku: row[columnsToDataMap.sku],
      fulfillment_type,
      is_fulfillment_type_valid,
      default_supplier_id: supplierName ? (supplierID ? supplierID : -1) : '',
      default_supplier_name: supplierName,
    }

    data.errors = this.determineDataErrors(data, columnsToDataMap)

    return data
  }

  static determineDataErrors(data, columnsToDataMap) {
    const {payload} = data
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    if (!payload.fulfillment_type) {
      errors.fulfillment_type = 'Fulfillment Type is required'
    } else if (!payload.is_fulfillment_type_valid) {
      errors.fulfillment_type = `"${payload.fulfillment_type}" is an invalid Fulfillment Type`
    } else {
      delete errors.fulfillment_type
    }

    const hasDefaultSupplierData = columnsToDataMap.default_supplier !== -1
    const isDropType = [
      DROPSHIPPABLE_FULFILLMENT,
      AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
    ].includes(payload.fulfillment_type)

    if (
      hasDefaultSupplierData &&
      isDropType &&
      payload.default_supplier_name &&
      payload.default_supplier_id === -1
    ) {
      errors.default_supplier_id = `"${payload.default_supplier_name}" is not a known supplier`
    } else if (
      hasDefaultSupplierData &&
      isDropType &&
      payload.default_supplier_id === ''
    ) {
      errors.default_supplier_id = 'Default Supplier is required'
    } else {
      delete errors.default_supplier_id
    }

    return errors
  }

  static save(data) {
    return (dispatch, getState) => {
      const columnsToDataMap = getState().ui.dataImport.columnsToDataMap
      const {sku, fulfillment_type, default_supplier_id} = data.payload

      const url = `/product/${encodeURIComponent(sku)}/fulfillment/`
      const params = {
        fulfillment_type,
      }

      if (
        [
          DROPSHIPPABLE_FULFILLMENT,
          AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
        ].includes(fulfillment_type) &&
        columnsToDataMap.default_supplier !== -1
      ) {
        params.default_supplier_id = default_supplier_id || null
      }

      return api.put(url, params)
    }
  }
}
