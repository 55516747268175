import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import round from 'lodash/round.js'
import {createSelector} from 'reselect'
import classNames from 'classnames'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import apiverson from '../../../common/apiverson.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import TextArea from '../../../common/components/TextArea.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  ensureOrder,
  setOrderStateAndEnsureProductsLoaded,
  saveFinancialInfo,
  orderLinesSelector,
  orderLineSKUSelector,
  financialInfoSelector,
} from '../../../data/orders.js'
import {
  getProduct,
  productsSelector,
  getProductName,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {hasOrderFinancialsPermissionSelector} from '../../../data/me.js'

const MODAL_FORM = 'EDIT_ORDER_LINE_MODAL'

export function showEditOrderLineModal(
  orderNumber,
  orderLineIDs = [],
  autoFocusOn = null,
) {
  const lines = orderLinesSelector(getState(), {orderNumber})

  autoFocusOn = autoFocusOn || (orderLineIDs.length === 0 ? 'sku' : 'quantity')

  const firstOrderLine = lines.find(({id}) => id === orderLineIDs[0])
  const quantity = get(firstOrderLine, 'quantity') || 1
  const totalPrice = get(firstOrderLine, 'total_price') || 0

  setForm(MODAL_FORM, {
    orderNumber,
    orderLineIDs,
    autoFocusOn,
    isSingleInputEdit: orderLineIDs.length > 1,
    sku: null,
    quantity: String(quantity),
    item_price: String(totalPrice / quantity),
    discount_amount: String(get(firstOrderLine, 'discount_amount') || 0),
    total_price: String(totalPrice),
    details: get(firstOrderLine, 'details') || '',
    product_serial_numbers: (
      get(firstOrderLine, 'product_serial_numbers') || []
    ).join('\n'),
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({orderLineIDs, sku, quantity, item_price, discount_amount, total_price}) => {
    const errors = {}

    if (orderLineIDs.length === 0 && !sku) {
      errors.sku = 'Product is required'
      errors.preventSave = true
    }

    if (!isNumeric(quantity)) {
      errors.quantity = 'Quantity must be a number'
      errors.preventSave = true
    }

    if (!isPresent(quantity)) {
      errors.quantity = 'Quantity is required'
      errors.preventSave = true
    }

    if (!isNumeric(item_price)) {
      errors.item_price = 'Item Price must be a number'
      errors.preventSave = true
    }

    if (!isPresent(item_price)) {
      errors.item_price = 'Item Price is required'
      errors.preventSave = true
    }

    if (!isNumeric(discount_amount)) {
      errors.discount_amount = 'Discount must be a number'
      errors.preventSave = true
    }

    if (!isPresent(discount_amount)) {
      errors.discount_amount = 'Discount is required'
      errors.preventSave = true
    }

    if (!isNumeric(total_price)) {
      errors.total_price = 'Total Price must be a number'
      errors.preventSave = true
    }

    if (!isPresent(total_price)) {
      errors.total_price = 'Total Price is required'
      errors.preventSave = true
    }

    return errors
  },
)

export function updatedParamsSelector(state, {orderLineID}) {
  const {
    orderNumber,
    sku,
    quantity,
    item_price,
    discount_amount,
    total_price,
    details,
    product_serial_numbers,
    autoFocusOn,
    isSingleInputEdit,
  } = modalFormSelector(state)
  const lines = orderLinesSelector(state, {orderNumber})

  const line = lines.find(({id}) => id === orderLineID) || {}

  const params = {}

  if (!orderLineID) {
    params.sku = sku
  }

  if (!isSingleInputEdit || autoFocusOn === 'quantity') {
    params.quantity = Number(quantity)
  }
  if (autoFocusOn === 'quantity') {
    params.total_price = round(params.quantity * line.item_price, 2)
  }

  if (!isSingleInputEdit || autoFocusOn === 'item_price') {
    params.item_price = round(Number(item_price), 3)
  }
  if (autoFocusOn === 'item_price') {
    params.total_price = round(line.quantity * params.item_price, 2)
  }

  if (!isSingleInputEdit || autoFocusOn === 'total_price') {
    params.total_price = round(Number(total_price), 2)
  }
  if (autoFocusOn === 'total_price') {
    params.item_price = round(params.total_price / (line.quantity || 1), 3)
  }

  if (!isSingleInputEdit || autoFocusOn === 'discount_amount') {
    params.discount_amount = round(Number(discount_amount), 2)
  }

  if (!isSingleInputEdit || autoFocusOn === 'details') {
    params.details = details || (orderLineID ? null : '')
  }

  if (!isSingleInputEdit || autoFocusOn === 'product_serial_numbers') {
    params.product_serial_numbers = product_serial_numbers
      .split(/[ \n\t]+/)
      .filter((v) => v)
  }

  return params
}

export function updateSKU(product) {
  const form = modalFormSelector(getState())
  const quantity = Number(form.quantity)

  updateModalForm({
    sku: product.sku,
    item_price: String(product.price),
    total_price: (product.price * (!isNaN(quantity) ? quantity : 1)).toFixed(2),
  })
}

export function updateQuantity(quantityString) {
  const form = modalFormSelector(getState())
  const updates = {quantity: quantityString}
  const quantity = Number(quantityString)
  const itemPrice = Number(form.item_price)

  if (!isNaN(itemPrice) && !isNaN(quantity)) {
    updates.total_price = (quantity * itemPrice).toFixed(2)
  }

  updateModalForm(updates)
}

export function updateItemPrice(itemPriceString) {
  const form = modalFormSelector(getState())
  const updates = {item_price: itemPriceString}
  const itemPrice = Number(itemPriceString)
  const quantity = Number(form.quantity)

  if (!isNaN(form.quantity) && !isNaN(itemPrice)) {
    updates.total_price = (quantity * itemPrice).toFixed(2)
  }

  updateModalForm(updates)
}

export function updateTotalPrice(totalPriceString) {
  const form = modalFormSelector(getState())
  const updates = {total_price: totalPriceString}
  const totalPrice = Number(totalPriceString)
  const quantity = Number(form.quantity)

  if (quantity > 0 && !isNaN(totalPrice)) {
    updates.item_price = String(round(totalPrice / quantity, 3))
  }

  updateModalForm(updates)
}

export async function updateSingleOrderLine(orderLineID) {
  const {orderNumber, sku} = modalFormSelector(getState())

  const params = updatedParamsSelector(getState(), {orderLineID})

  const {json: order} = await (orderLineID
    ? apiverson.put(
        `/order/${encodeURIComponent(orderNumber)}/line/${orderLineID}/`,
        params,
      )
    : apiverson.post(`/order/${encodeURIComponent(orderNumber)}/line`, params))

  const updatedOrderLine = order.lines.find((line) => line.id === orderLineID)

  getProduct(updatedOrderLine ? updatedOrderLine.sku : sku)

  return order
}

export async function updateOrderLine({addAnother} = {}) {
  try {
    const {orderNumber, orderLineIDs} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    let order

    const orderShapes = await Promise.all(
      orderLineIDs.length === 0
        ? [updateSingleOrderLine()]
        : orderLineIDs.map((orderLineID) => updateSingleOrderLine(orderLineID)),
    )

    // We need to get the final order shape and we don't know which promise that is, so if we have
    // multiple requests then just ask for the order again
    order =
      orderShapes.length === 1
        ? orderShapes[0]
        : await ensureOrder(orderNumber, {reload: true})

    await setOrderStateAndEnsureProductsLoaded(order)

    const financialInfo = financialInfoSelector(getState(), {orderNumber})
    await saveFinancialInfo(orderNumber, financialInfo)

    closeModal()

    showMessageToast(
      orderLineIDs.length === 0
        ? 'Added order line'
        : `Saved order line${orderLineIDs.length === 1 ? '' : 's'}`,
    )

    if (addAnother) {
      showEditOrderLineModal(orderNumber)
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditOrderLineModal({form}) {
  const firstOrderLineID = form.orderLineIDs[0]

  const errors = useSelector(errorsSelector)
  const firstOrderLineSKU = useSelector((state) =>
    orderLineSKUSelector(state, {
      orderNumber: form.orderNumber,
      orderLineID: firstOrderLineID,
    }),
  )
  const productsBySKU = useSelector(productsSelector)
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )

  return (
    <ConfirmModal
      title={
        form.orderLineIDs.length === 0
          ? 'Add Order Line'
          : 'Edit Order Line Values'
      }
      modalSize="sm"
      onConfirm={() => updateOrderLine()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      MiddleButtons={
        form.orderLineIDs.length === 0
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => updateOrderLine({addAnother: true})}
              >
                Create and Add Another
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {form.orderLineIDs.length === 0 ? (
          <>
            <li className="list__item--form list__item--no-style list__item--product-filter-stacked divider--bottom">
              {form.sku ? (
                <ButtonLink
                  className="darker align-left"
                  onClick={() => updateModalForm({sku: null})}
                >
                  <div className="lh-md margin-bottom-3">
                    <strong className="fs-01 margin-right-5">
                      {getProductName(productsBySKU[form.sku])}
                    </strong>
                    <span className="unbold">({form.sku})</span>
                  </div>
                  <div className="fs-n0 lh-md text--lt-grey">Edit</div>
                </ButtonLink>
              ) : (
                <ProductFilter
                  label="Select a Product to Add"
                  dropdown="EDIT_ORDER_LINE_PRODUCT_FILTER"
                  onSelect={(product) => updateSKU(product)}
                  autoFocus={form.autoFocusOn === 'sku'}
                />
              )}
            </li>
          </>
        ) : (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              {form.orderLineIDs.length === 1 ? (
                <>
                  <strong>Updating 1 Line:</strong>
                  <div>{firstOrderLineSKU}</div>
                </>
              ) : (
                <strong>Updating {form.orderLineIDs.length} Lines</strong>
              )}
            </div>
          </li>
        )}

        {(!form.isSingleInputEdit || form.autoFocusOn === 'quantity') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
              <label className="fs-00" htmlFor="quantity">
                Quantity
              </label>
            </li>
            <li
              className={classNames('list__item list__item--form', {
                'divider--bottom padding-bottom-15':
                  form.orderLineIDs.length === 1,
              })}
            >
              <NumberInput
                id="quantity"
                value={form.quantity}
                onChange={(value) => updateQuantity(String(value))}
                isInvalid={!!errors.quantity}
                autoFocus={
                  (!!form.sku && form.autoFocusOn === 'sku') ||
                  form.autoFocusOn === 'quantity'
                }
                min={0}
              />
              {errors.quantity && (
                <small className="error">{errors.quantity}</small>
              )}
            </li>
          </>
        )}
        {hasOrderFinancialsPermission &&
          (!form.isSingleInputEdit || form.autoFocusOn === 'item_price') && (
            <li className="list__item list__item--form">
              <CurrencyInput
                label="Item Price"
                className="biggie margin-bottom-0"
                width="sm"
                id="item_price"
                value={form.item_price}
                onChange={(value) => updateItemPrice(String(value))}
                errorMessage={errors.item_price}
                autoFocus={form.autoFocusOn === 'item_price'}
              />
            </li>
          )}
        {hasOrderFinancialsPermission &&
          (!form.isSingleInputEdit ||
            form.autoFocusOn === 'discount_amount') && (
            <li className="list__item list__item--form">
              <CurrencyInput
                label="Discount"
                className="biggie margin-bottom-0"
                width="sm"
                id="discount_amount"
                value={form.discount_amount}
                onChange={(value) =>
                  updateModalForm({discount_amount: `${value}`})
                }
                errorMessage={errors.discount_amount}
                autoFocus={form.autoFocusOn === 'discount_amount'}
              />
            </li>
          )}
        {hasOrderFinancialsPermission &&
          (!form.isSingleInputEdit || form.autoFocusOn === 'total_price') && (
            <li className="list__item list__item--form">
              <CurrencyInput
                label="Total Price"
                className="biggie margin-bottom-0"
                width="sm"
                id="total_price"
                value={form.total_price}
                onChange={(value) => updateTotalPrice(String(value))}
                errorMessage={errors.total_price}
                autoFocus={form.autoFocusOn === 'total_price'}
              />
            </li>
          )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'details') && (
          <li className="list__item list__item--form">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="7"
              label="Additional Details/Notes"
              id="details"
              value={form.details}
              onChange={(value) => updateModalForm({details: value})}
              errorMessage={errors.details}
              autoFocus={form.autoFocusOn === 'details'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'product_serial_numbers') && (
          <li className="list__item list__item--form">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="4"
              label="Serial Numbers (for multiple, separate with line breaks)"
              id="product_serial_numbers"
              value={form.product_serial_numbers}
              onChange={(value) =>
                updateModalForm({product_serial_numbers: value})
              }
              errorMessage={errors.product_serial_numbers}
              autoFocus={form.autoFocusOn === 'product_serial_numbers'}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditOrderLineModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    orderLineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    sku: PropTypes.string,
    details: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    item_price: PropTypes.string.isRequired,
    discount_amount: PropTypes.string.isRequired,
    total_price: PropTypes.string.isRequired,
    product_serial_numbers: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditOrderLineModal, modalFormSelector)
