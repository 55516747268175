import moment from 'moment'

import {CommentShape} from '../../../PropTypes.js'
import User from './User.js'

export default function Comment({comment: {date, text, user}}) {
  return (
    <dl className="list--order-data list--timeline-point">
      <dt className="list__item--order-data list__title--timeline-point">
        {moment(date).format('LT')}
      </dt>
      <dd className="list__item--order-data list__item--timeline-point">
        <div className="message--timeline-point">{text}</div>
        {user && <User email={user} />}
      </dd>
    </dl>
  )
}

Comment.propTypes = {
  comment: CommentShape.isRequired,
}
