import {OrderShape} from '../../../../../../common/PropTypes.js'
import {
  getRequestedShippingMethod,
  getDeliverByDate,
  getShipByDate,
  isAmazonOrderSelector,
} from '../../../../../../data/orders.js'
import DeliverByDate from '../../Fields/DeliverByDate.js'
import ShipByDate from '../../Fields/ShipByDate.js'
import {useSelector} from '../../../../../../store.js'

export default function AwaitingFulfillmentStatus({order}) {
  const isAmazonOrder = useSelector((state) =>
    isAmazonOrderSelector(state, {orderNumber: order.order_number}),
  )
  const requestedShippingMethod = getRequestedShippingMethod(order)
  const deliverByDate = getDeliverByDate(order)
  const shipByDate = getShipByDate(order)

  if (!requestedShippingMethod && !deliverByDate) {
    return null
  }

  return (
    <div>
      {requestedShippingMethod && (
        <div className="fs-n1 text--md-grey">
          <strong>Requested Ship:</strong>
          <div>{requestedShippingMethod}</div>
        </div>
      )}
      {(deliverByDate || shipByDate) && (
        <div className="alert alert--standard alert--x-sm text--md-grey alert--ship-by-date">
          {shipByDate && (
            <div className="fs-n1 lh-1-25">
              <strong>Ship By: </strong>
              <span>
                <ShipByDate date={shipByDate} isAmazonDate={isAmazonOrder} />
              </span>
            </div>
          )}
          {deliverByDate && (
            <div className="fs-n1 lh-1-25">
              <strong>Deliver By: </strong>
              <span>
                <DeliverByDate
                  date={deliverByDate}
                  isAmazonDate={isAmazonOrder}
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

AwaitingFulfillmentStatus.propTypes = {
  order: OrderShape.isRequired,
}
