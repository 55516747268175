import {all, takeEvery, put, call, select, take} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import abode from '../../../../common/abode.js'
import {setGlobalError} from '../../../actions/ui/index.js'
import {packingListsHaveLoadedSelector} from '../../../selectors/data/hasLoaded.js'
import {packingListSelector} from '../../../selectors/data/packingLists.js'
import {packingListsSettingsSelector} from '../../../selectors/ui/settings/packingLists.js'
import {
  SET_PACKING_LISTS,
  fetchPackingLists,
} from '../../../actions/data/packingLists.js'
import {
  SETUP_FOR_EDIT,
  SAVE_PACKING_LIST,
  HANDLE_LOGO_FILE,
  setIsSaving,
  setIsUploading,
  setError,
  setCurrentPackingList,
  updateCurrentPackingList,
  goToPackingListSettings,
} from '../../../actions/ui/settings/packingLists/index.js'

export function* setupForEdit() {
  yield call(getReadyForEdit)

  yield call(copyDataForEdit)
}

export function* getReadyForEdit() {
  const hasLoaded = yield select(packingListsHaveLoadedSelector)

  if (!hasLoaded) {
    yield take(SET_PACKING_LISTS)
  }
}

export function* copyDataForEdit() {
  const {editID} = yield select(packingListsSettingsSelector)
  const packingList = yield select(packingListSelector, {packingListID: editID})

  if (!packingList) {
    return
  }

  yield put(
    setCurrentPackingList({
      _link: packingList._link,
      _linklogo: packingList._linklogo,
      id: packingList.id,
      name: packingList.name,
      customer_notes_label: packingList.customer_notes_label || '',
      custom_text: packingList.custom_text || '',
      email: packingList.email || '',
      phone: packingList.phone || '',
      website: packingList.website || '',
      footer: packingList.footer || '',
      is_default: packingList.is_default || false,
      original_is_default: packingList.is_default || false,
      logo_hash: packingList.logo_hash,
      return_email_subject: packingList.return_email_subject || '',
      return_email_content: packingList.return_email_content || '',
      ship_from_alias_name: packingList.ship_from_alias_name || '',
    }),
  )
}

export function getLogoData(dataURL) {
  if (!dataURL) {
    return null
  }

  const match = dataURL.match(/^data:(image\/(?:jpg|jpeg|png|gif));base64,(.*)/)

  if (match && match.length === 3) {
    return {mime_type: match[1], data: match[2]}
  }

  return null
}

export function* savePackingList() {
  const {
    currentPackingList: {
      _link,
      _linklogo,
      name,
      customer_notes_label,
      custom_text: customText,
      email,
      phone,
      website,
      footer,
      is_default: isDefault,
      logo_hash: logoHash,
      return_email_subject,
      return_email_content,
      ship_from_alias_name,
    },
  } = yield select(packingListsSettingsSelector)

  const params = {
    name,
    customer_notes_label,
    custom_text: customText,
    email,
    phone,
    website,
    footer,
    logo_hash: logoHash,
    logo: getLogoData(_linklogo) || undefined,
    return_email_subject,
    return_email_content,
    ship_from_alias_name,
  }

  yield put(setIsSaving(true))

  try {
    if (_link) {
      yield call(api.put, _link, params)
      if (isDefault) {
        yield call(api.post, `${_link}make_default/`)
      }
    } else {
      const {json} = yield call(api.post, '/packing_list/', params)
      if (isDefault) {
        yield call(api.post, `${json._link}make_default/`)
      }
    }

    yield put(goToPackingListSettings())
    yield put(fetchPackingLists())
  } catch (err) {
    yield put(setError(err.error_message))
  }

  yield put(setIsSaving(false))
}

export function loadFileInForm(file) {
  const formData = new FormData()

  formData.append('image', file)

  return formData
}

export function* handleLogoFile({payload: file}) {
  yield put(setIsUploading(true))

  try {
    const formData = yield call(loadFileInForm, file)

    const imageInfo = yield call(abode.json, '/image/file', {
      method: 'POST',
      body: formData,
    })

    if (imageInfo.error_message) {
      throw new Error(imageInfo.error_message)
    }

    yield put(updateCurrentPackingList({logo_hash: imageInfo.hash}))
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'There was an error reading the image.',
          details: err.message,
        },
        err,
      ),
    )
  }

  yield put(setIsUploading(false))
}

export default function* packingListsSaga() {
  yield all([
    takeEvery(SETUP_FOR_EDIT, setupForEdit),
    takeEvery(SAVE_PACKING_LIST, savePackingList),
    takeEvery(HANDLE_LOGO_FILE, handleLogoFile),
  ])
}
