export const STRIPE_PAYMENT_COMMENT = 'stripe_payment'
export const ADDRESS_COMMENT = 'address'
export const BULK_UPDATE_COMMENT = 'bulk_update'
export const BATCH_CREATE_DOCUMENT_COMMENT = 'batch_create_document'
export const BATCH_SORT_COMMENT = 'batch_sort'

export const BULK_UPDATE_APPLY_PRESETS_COMMENT = 'apply_presets'
export const BULK_UPDATE_WAREHOUSE_COMMENT = 'update_warehouse'
export const BULK_UPDATE_WEIGHT_COMMENT = 'update_weight'
export const BULK_UPDATE_LABEL_INFO_CONFIG_COMMENT = 'update_label_info_config'
export const BULK_UPDATE_DIMENSIONS_COMMENT = 'update_dimensions'
export const BULK_UPDATE_SHIP_DATE_COMMENT = 'update_ship_date'
export const BULK_UPDATE_PACKING_LIST_COMMENT = 'update_packing_list'
