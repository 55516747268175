import PropTypes from 'prop-types'
import classNames from 'classnames'

import {getState, dispatch, useSelector} from '../../../../store.js'
import api from '../../../../common/api.js'
import {isPresent} from '../../../../common/utils.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import {NEW_ID} from '../../../../common/constants/index.js'
import {DHL_ECOMMERCE} from '../../../../common/constants/ShipperNames.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import Select from '../../../../common/components/Form/Select.js'
import {setShipper} from '../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  shipperFormSelector,
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'
import ShipperOptions from '../../../../data/shipperOptions.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.client_id)) {
    errors.client_id = 'Client ID is required'
  }

  if (!isPresent(shipper.client_secret)) {
    errors.client_secret = 'Client Secret is required'
  }

  if (!isPresent(shipper.pickup_account_number)) {
    errors.pickup_account_number = 'Pickup Account Number is required'
  }

  if (!isPresent(shipper.package_prefix)) {
    errors.package_prefix = 'Package Prefix is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    client_id: vendorConfig.client_id || '',
    client_secret: vendorConfig.client_secret || '',
    account_number: vendorConfig.account_number || '',
    pickup_account_number: vendorConfig.pickup_account_number || '',
    distribution_center:
      vendorConfig.distribution_center ||
      ShipperOptions[DHL_ECOMMERCE].distribution_center[0].value,
    package_prefix: vendorConfig.package_prefix || '',
    isSaving: false,
  }
}

export async function saveShipperDHLEcommerce(shipperID) {
  const {
    name,
    hidden_shipping_methods,
    client_id,
    client_secret,
    account_number,
    pickup_account_number,
    distribution_center,
    package_prefix,
  } = shipperFormSelector(getState(), {shipperID})

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    client_id,
    client_secret,
    account_number,
    pickup_account_number,
    distribution_center,
    package_prefix,
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await (shipperID !== NEW_ID
      ? api.put(`/shipper/${shipperID}/`, params)
      : api.post('/shipper/', {...params, vendor: DHL_ECOMMERCE}))

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error while saving shipper.',
        details: err.error_message || err.message,
      },
      err,
    )

    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

export default function DHLEcommerceForm({form, cancel, ...props}) {
  const errors = useSelector((state) => errorsOfChangedSelector(state, {form}))
  const hasErrors = useSelector((state) => hasErrorsSelector(state, {form}))

  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {form.id !== NEW_ID && <Nickname shipperID={form.id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.client_id},
                {'margin-top-20 divider--top': form.id !== NEW_ID},
              )}
            >
              <label htmlFor="client_id">
                Client ID<span className="required">*</span>
              </label>
              <input
                type="text"
                id="client_id"
                value={form.client_id}
                onChange={(event) =>
                  props.updateForm(form.id, {
                    client_id: event.target.value,
                  })
                }
              />
              {errors.client_id && (
                <small className="error error-message">
                  {errors.client_id}
                </small>
              )}
            </li>
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.client_secret},
                {'margin-top-20 divider--top': form.id !== NEW_ID},
              )}
            >
              <label htmlFor="client_secret">
                Client Secret<span className="required">*</span>
              </label>
              <input
                type="text"
                id="client_secret"
                value={form.client_secret}
                onChange={(event) =>
                  props.updateForm(form.id, {
                    client_secret: event.target.value,
                  })
                }
              />
              {errors.client_secret && (
                <small className="error error-message">
                  {errors.client_secret}
                </small>
              )}
            </li>
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.account_number},
                {'margin-top-20 divider--top': form.id !== NEW_ID},
              )}
            >
              <label htmlFor="account_number">Account Number</label>
              <input
                type="text"
                id="account_number"
                value={form.account_number}
                onChange={(event) =>
                  props.updateForm(form.id, {
                    account_number: event.target.value,
                  })
                }
              />
              {errors.account_number && (
                <small className="error error-message">
                  {errors.account_number}
                </small>
              )}
            </li>
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.pickup_account_number},
                {'margin-top-20 divider--top': form.id !== NEW_ID},
              )}
            >
              <label htmlFor="pickup_account_number">
                Pickup Account Number<span className="required">*</span>
              </label>
              <input
                type="text"
                id="pickup_account_number"
                value={form.pickup_account_number}
                onChange={(event) =>
                  props.updateForm(form.id, {
                    pickup_account_number: event.target.value,
                  })
                }
              />
              {errors.pickup_account_number && (
                <small className="error error-message">
                  {errors.pickup_account_number}
                </small>
              )}
            </li>
            <li className="form-list-item bigger margin-bottom-20">
              <label htmlFor="distribution_center">Distribution Center</label>
              <Select
                name="distribution_center"
                value={form.distribution_center}
                onChange={(value) =>
                  props.updateForm(form.id, {distribution_center: value})
                }
              >
                {ShipperOptions[DHL_ECOMMERCE].distribution_center.map(
                  ({value, display}) => (
                    <option key={value} value={value}>
                      {display}
                    </option>
                  ),
                )}
              </Select>
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.package_prefix,
              })}
            >
              <label htmlFor="package_prefix">
                Package Prefix<span className="required">*</span>
              </label>
              <input
                type="text"
                id="package_prefix"
                value={form.package_prefix}
                onChange={(event) =>
                  props.updateForm(form.id, {
                    package_prefix: event.target.value,
                  })
                }
              />
              {errors.package_prefix && (
                <small className="error error-message">
                  {errors.package_prefix}
                </small>
              )}
            </li>
            {form.id !== NEW_ID && <ShippingMethods shipperID={form.id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                onClick={() => saveShipperDHLEcommerce(form.id)}
                isDisabled={hasErrors}
                isLoading={form.isSaving}
              >
                {form.id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      <div className="medium-5 columns">
        <fieldset>
          <div className="alert margin-bottom-10 alert--standard">
            {form.id === NEW_ID ? (
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help authorizing your DHL eCommerce account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  <a
                    className="btn--link mid meta-ups-setup-help"
                    href="https://support.ordoro.com/how-do-i-set-up-dhl-ecommerce-in-ordoro/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get step-by-step instructions →
                  </a>
                </dd>
              </dl>
            ) : (
              <Archive shipperID={form.id} />
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

DHLEcommerceForm.propTypes = {
  form: ShipperFormShape,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
