import PropTypes from 'prop-types'

import Currency, {isTBD} from '../../../../common/components/Currency.js'

export default function InsuranceCost({insuranceCost, includeInsurance}) {
  if (!includeInsurance) {
    return null
  }

  return (
    <div>
      <strong className="fs-n1">Cost: </strong>
      <span className="fs-n1">
        {isTBD(insuranceCost) ? (
          'TBD'
        ) : (
          <Currency value={insuranceCost} currencySymbol="$" />
        )}
      </span>
    </div>
  )
}

InsuranceCost.propTypes = {
  insuranceCost: PropTypes.number,
  includeInsurance: PropTypes.bool.isRequired,
}
