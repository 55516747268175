import {takeEvery, all, put, call, select, fork} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {getProduct} from '../../../../data/products.js'
import {
  CREATE_INVENTORY_TRANSFER,
  GET_PRODUCT_FOR_INVENTORY_TRANSFER_CREATE,
  REMOVE_SKU_FROM_CREATE_INVENTORY_TRANSFER,
  updateCreateInventoryTransferModalForm,
  closeCreateInventoryTransferModal,
  updateCreateInventoryTransferModalRowForm,
} from './createInventoryTransferModalActions.js'
import {
  createInventoryTransferModalFormSelector,
  inventoryTransferParamsSelector,
} from './createInventoryTransferModalSelectors.js'

export function* createInventoryTransferWorker() {
  try {
    yield put(
      updateCreateInventoryTransferModalForm({
        isSaving: true,
        serverError: null,
      }),
    )

    const params = yield select(inventoryTransferParamsSelector)
    yield call(api.post, '/inventory_transfer/', params)

    for (const line of params.lines) {
      yield fork(getProduct, line.sku)
    }

    yield call(showMessageToast, 'Inventory has been transferred!')

    yield put(closeCreateInventoryTransferModal())
  } catch (err) {
    yield put(
      updateCreateInventoryTransferModalForm({
        serverError: `Error creating inventory transfer: ${
          err.error_message || err.message
        }`,
      }),
    )
  } finally {
    yield put(
      updateCreateInventoryTransferModalForm({
        isSaving: false,
      }),
    )
  }
}

export function* getProductForInventoryTransferCreateWorker({payload: {sku}}) {
  try {
    yield fork(getProduct, sku)
  } catch (err) {
    yield put(
      updateCreateInventoryTransferModalRowForm(sku, {
        serverError: `Unable to get product: ${
          err.error_message || err.message
        }`,
      }),
    )
  }
}

export function* removeSKUFromCreateInventoryTransferWorker({payload: {sku}}) {
  const {skus} = yield select(createInventoryTransferModalFormSelector)

  yield put(
    updateCreateInventoryTransferModalForm({
      skus: skus.filter((s) => s !== sku),
    }),
  )
}

export default function* createInventoryTransferModal() {
  yield all([
    takeEvery(CREATE_INVENTORY_TRANSFER, createInventoryTransferWorker),
    takeEvery(
      GET_PRODUCT_FOR_INVENTORY_TRANSFER_CREATE,
      getProductForInventoryTransferCreateWorker,
    ),
    takeEvery(
      REMOVE_SKU_FROM_CREATE_INVENTORY_TRANSFER,
      removeSKUFromCreateInventoryTransferWorker,
    ),
  ])
}
