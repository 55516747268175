import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {CommentGroupShape} from '../../../PropTypes.js'
import CommentGroup from '../../Details/Timeline/CommentGroup.js'

export function Popover({title, commentGroups}) {
  return (
    <dl
      key="pop-over"
      className="pop-over pop-over--w-35vw pop-over--below-right"
    >
      <dt className="pop-over__title">{title}</dt>
      <dd className="pop-over__body">
        {commentGroups.map(({day, comments}) => (
          <CommentGroup key={day} day={day} comments={comments} />
        ))}
      </dd>
    </dl>
  )
}

Popover.propTypes = {
  title: PropTypes.string.isRequired,
  commentGroupsSelector: PropTypes.func.isRequired,
  commentGroups: PropTypes.arrayOf(CommentGroupShape).isRequired,
}

function mapStateToProps(state, {commentGroupsSelector}) {
  return {
    commentGroups: commentGroupsSelector(state),
  }
}

export default connect(mapStateToProps)(Popover)
