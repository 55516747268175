import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Footer from '../../common/components/List/Footer/index.js'
import {
  setPerPage,
  updateReturnOrderListForm,
} from './returnOrderListActions.js'
import {
  isLoadingSelector,
  perPageSelector,
  currentPageSelector,
  countSelector,
} from './returnOrderListSelectors.js'

export function ReturnOrderListFooter({
  isLoading,
  perPage,
  currentPage,
  count,
  ...props
}) {
  return (
    <Footer
      isLoading={isLoading}
      limit={perPage}
      offset={(currentPage - 1) * perPage}
      count={count}
      setLimit={props.setPerPage}
      setOffset={(offset) =>
        props.updateReturnOrderListForm({currentPage: offset / perPage + 1})
      }
    />
  )
}

ReturnOrderListFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  updateReturnOrderListForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(state),
    perPage: perPageSelector(state),
    currentPage: currentPageSelector(state),
    count: countSelector(state),
  }
}

const mapDispatchToProps = {
  setPerPage,
  updateReturnOrderListForm,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderListFooter)
