import PropTypes from 'prop-types'

import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'
import {
  SHIPPING_PRESET,
  RETURN_PRESET,
  SHIPPING_AND_RETURN,
} from '../../../../common/constants/Rules.js'
import {presetSelector} from '../../../../data/presets.js'

import ActionSelect from './ActionSelect.js'
import PresetSelect from './PresetSelect.js'
import {useSelector} from '../../../../store.js'
import {
  setRuleApplyPresetPresetID,
  setRuleApplyPresetPresetType,
} from '../rulesFunctions.js'

export default function ApplyPreset({id, index, action, errors, isEditing}) {
  const {
    data: {
      preset_ids: [presetID],
      preset_type,
    },
  } = action

  const selectedPreset = useSelector(
    (state) => presetID && presetSelector(state, {presetID}),
  )

  if (!isEditing) {
    if (presetID === null) {
      return null
    }

    return (
      <div className="inline-block">
        <strong className="margin-right-5">Apply preset</strong>
        <ul className="list list--tags-lg inline-block">
          <li className="list__item--inline rule-criteria">
            {selectedPreset && (
              <strong className="fs-00">{selectedPreset.name}</strong>
            )}
          </li>
        </ul>
        {' to '}
        {preset_type === SHIPPING_PRESET && <strong>shipping labels</strong>}
        {preset_type === RETURN_PRESET && <strong>return labels</strong>}
        {preset_type === SHIPPING_AND_RETURN && (
          <strong>shipping and return labels</strong>
        )}
      </div>
    )
  }

  return (
    <div className="inline-block">
      <div className="inline-block v-align-middle margin-bottom-10">
        <ActionSelect ruleID={id} index={index} />
      </div>
      <div className="inline-block v-align-middle margin-right-10 margin-bottom-10">
        <strong className="op-50 v-align-middle margin-right-10">→</strong>
        <div className="wrap--rules-preset-dropdown inline-block margin-right-10">
          <PresetSelect
            dropdown={`PRESET_DROPDOWN_RULE_${id}-${index}`}
            label={selectedPreset ? selectedPreset.name : 'Select a preset'}
            onChange={(presetID) =>
              setRuleApplyPresetPresetID(id, index, presetID)
            }
            hasErrors={!!errors.action}
          />
        </div>
        {presetID && <strong className="op-50 v-align-middle">→</strong>}
      </div>
      {presetID && (
        <div className="inline-block v-align-middle margin-bottom-10 margin-right-5">
          <select
            className="select inline-block v-align-middle margin-bottom-0"
            value={preset_type}
            onChange={(event) =>
              setRuleApplyPresetPresetType(id, index, event.target.value)
            }
          >
            <option value={SHIPPING_PRESET}>to shipping labels</option>
            <option value={RETURN_PRESET}>to return labels</option>
            <option value={SHIPPING_AND_RETURN}>
              to shipping and return labels
            </option>
          </select>
        </div>
      )}
    </div>
  )
}

ApplyPreset.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
}
