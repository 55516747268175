import {openModal} from '../../ui/index.js'

export const BASE = 'ordoro/ui/modal/addPurchaseOrderCommentModal'
export const ADD_PURCHASE_ORDER_COMMENT = `${BASE}/ADD_PURCHASE_ORDER_COMMENT`
export const SET_COMMENT = `${BASE}/SET_COMMENT`
export const SET_IS_ADDING = `${BASE}/SET_IS_ADDING`
export const SET_SERVER_ERROR = `${BASE}/SET_SERVER_ERROR`

export const MODAL_NAME = 'ADD_PURCHASE_ORDER_COMMENT_MODAL'

export function showAddPurchaseOrderCommentModal(poID) {
  return openModal(MODAL_NAME, {poID})
}

export function addPurchaseOrderComment() {
  return {
    type: ADD_PURCHASE_ORDER_COMMENT,
  }
}

export function setComment(comment) {
  return {
    type: SET_COMMENT,
    payload: comment,
  }
}

export function setIsAdding(isAdding) {
  return {
    type: SET_IS_ADDING,
    payload: isAdding,
  }
}

export function setServerError(serverError) {
  return {
    type: SET_SERVER_ERROR,
    payload: serverError,
  }
}
