import PropTypes from 'prop-types'
import classNames from 'classnames'

import {getOrderAllocationHint} from '../../../../../data/orders.js'

export default function OrderAllocationIcon({allocationStatus, className}) {
  if (allocationStatus === 'unallocated') {
    return null
  }

  return (
    <div
      className={classNames('wrap--order-allocation-callout tooltip--top', {
        allocated: allocationStatus === 'allocated',
        partial: allocationStatus === 'partial',
        overallocated: allocationStatus === 'overallocated',
        'allocation-attempted': allocationStatus === 'could_not_allocate',
      })}
      data-hint={getOrderAllocationHint(allocationStatus)}
    >
      <strong className={classNames('inline-block', className)}>
        {allocationStatus === 'allocated' && <>Allocated</>}
        {allocationStatus === 'partial' && <>Partially Allocated</>}
        {allocationStatus === 'overallocated' && <>Overallocated</>}
        {allocationStatus === 'could_not_allocate' && <>Could Not Allocate</>}
      </strong>
    </div>
  )
}

OrderAllocationIcon.propTypes = {
  allocationStatus: PropTypes.string.isRequired,
  className: PropTypes.string,
}
