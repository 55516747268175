import PropTypes from 'prop-types'

import {
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
} from '../../../../common/constants/ShipperNames.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  removeScanFormForm,
  createScanForm,
  closeDay,
  scanFormModalFormSelector,
  scanFormShippersSelector,
  selectedShipperVendorSelector,
  isRequestingSummedSelector,
  isSelectedSummedSelector,
  activityTypeSelector,
  setupScanFormForm,
} from './scanFormModalFunctions.js'

import GenericPanel from './GenericPanel.js'
import EmptyPanel from './EmptyPanel.js'
import CanadaPostPanel from './CanadaPostPanel.js'
import {onlyIfForm, useSelector} from '../../../../store.js'

const Panels = {
  [AUSTRALIA_POST]: GenericPanel,
  [CANADA_POST]: CanadaPostPanel,
  [DHL_ECOMMERCE]: GenericPanel,
  [ENDICIA]: GenericPanel,
  [FEDEX]: GenericPanel,
  [NEWGISTICS]: EmptyPanel,
  [PITNEY]: GenericPanel,
  [PITNEY_MERCHANT]: GenericPanel,
  [VISIBLE_USPS]: GenericPanel,
}

function ScanFormModal({form}) {
  const activityType = useSelector(activityTypeSelector)
  const isRequesting = useSelector(isRequestingSummedSelector)
  const canRequest = useSelector(isSelectedSummedSelector)
  const selectedShipperVendor = useSelector(selectedShipperVendorSelector)
  const shippers = useSelector(scanFormShippersSelector)

  const Panel = Panels[selectedShipperVendor]

  return (
    <ConfirmModal
      title="SCAN Form / End of Day Actions"
      onConfirm={
        activityType === 'scan_form'
          ? () => createScanForm()
          : () => closeDay(form.shipperID)
      }
      onCancel={() => removeScanFormForm()}
      confirmText={
        activityType === 'scan_form' ? 'Create SCAN Form' : 'Close Out Day'
      }
      cancelText="Cancel"
      isSaving={isRequesting}
      isDisabled={!canRequest}
      error={form.serverError}
      modalSize="md"
    >
      <div className="margin-bottom-20">
        <label htmlFor="scan_form_carrier">Carrier</label>
        <select
          className="select"
          name="scan_form_carrier"
          onChange={(event) => setupScanFormForm(Number(event.target.value))}
          value={form.shipperID}
        >
          {shippers.map((shipper) => (
            <option key={shipper.id} value={shipper.id}>
              {shipper.name}
            </option>
          ))}
        </select>
      </div>
      <Panel />
    </ConfirmModal>
  )
}

ScanFormModal.propTypes = {
  form: PropTypes.shape({
    shipperID: PropTypes.number.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(ScanFormModal, scanFormModalFormSelector)
