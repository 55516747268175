import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {OrderShape} from '../../common/PropTypes.js'
import Currency from '../../common/components/Currency.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import OrderData from '../iverson/components/OrderListPage/OrderListRow/OrderData/index.js'
import Address from '../../common/components/Order/Address.js'
import AddressValidationIcon from '../../common/components/Order/AddressValidationIcon.js'
import OrderStatus from '../iverson/components/OrderListPage/Fields/OrderStatus.js'
import OrderCart from '../iverson/components/OrderListPage/Fields/OrderCart.js'
import OrderTags from '../iverson/components/OrderListPage/Fields/OrderTags.js'
import {
  showOrderShippingAddressModal,
  orderAddressCanBeEditedSelector,
  isPrimeOrderSelector,
} from '../../data/orders.js'
import {
  currentPackShipOrderNumberSelector,
  currentPackShipOrderSelector,
} from '../../redux/selectors/ui/packShipPage/index.js'
import {useSelector} from '../../store.js'
import {hasOrderFinancialsPermissionSelector} from '../../data/me.js'
import {orderListHashBuilder} from '../OrderListPage/orderListSelectors.js'
import {AWAITING_FULFILLMENT} from '../../common/constants/Orders.js'

function OrderInfo({orderNumber, order, isPrimeOrder, addressCanBeEdited}) {
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )

  return (
    <>
      {order && (
        <div>
          <div>
            <dl className="list list--order-data list--order-summary">
              <dt className="list__title--order-data">Order Summary</dt>
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Ordered:</strong>
                <span>
                  <FormattedDate
                    value={order.order_placed_date}
                    format="MMM D, YYYY"
                  />
                  {' at '}
                  <FormattedDate
                    value={order.order_placed_date}
                    format="h:mma"
                  />
                </span>
              </dd>
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Status:</strong>
                <span>
                  <OrderStatus order={order} />
                </span>
              </dd>
              <dd className="list__item--order-data">
                <strong className="margin-right-3">Sales Channel:</strong>
                <OrderCart orderNumber={orderNumber} />
              </dd>
              {order.requested_shipping_method && (
                <dd className="list__item--order-data">
                  <strong className="margin-right-3">Requested Ship:</strong>
                  <em>{order.requested_shipping_method}</em>
                </dd>
              )}
              {hasOrderFinancialsPermission && (
                <dd className="list__item--order-data">
                  <strong className="margin-right-3">Total:</strong>
                  <span>
                    <Currency value={order.financial.grand_total} />
                  </span>
                </dd>
              )}
              {order.tags.length > 0 && (
                <dd className="list__item--order-data">
                  <OrderTags order={order} />
                </dd>
              )}
            </dl>
          </div>
          {order.notes_from_customer && (
            <div className="divider--top">
              <dl className="list list--order-data list--order-summary">
                <dt className="list__title--order-data">Customer Notes</dt>
                <dd className="list__item--order-data">
                  {order.notes_from_customer}
                </dd>
              </dl>
            </div>
          )}
          <div className="divider--top">
            <div className="wrap--compact-addr flex">
              <AddressValidationIcon address={order.shipping_address} />
              <Address
                title="Ship-to"
                addressType="shipping"
                address={order.shipping_address}
                onEdit={() => showOrderShippingAddressModal(orderNumber)}
                canEdit={!isPrimeOrder && addressCanBeEdited}
              />
            </div>
            {order.similar_open_addresses_count > 0 && (
              <div className="divider--top">
                <a
                  className="btn btn--link fs-n1 block"
                  href={orderListHashBuilder({
                    status: [AWAITING_FULFILLMENT],
                    similarAddressTo: order.order_number,
                  })}
                >
                  Show orders with similar Ship-To (
                  {order.similar_open_addresses_count - 1})
                </a>
              </div>
            )}
          </div>
          {order.status === 'shipped' && (
            <div className="divider--top margin-bottom-10">
              <OrderData
                orderNumber={order.order_number}
                isExpanded
                parentView="PackShipPage"
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

OrderInfo.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  order: OrderShape,
  isPrimeOrder: PropTypes.bool.isRequired,
  addressCanBeEdited: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const orderNumber = currentPackShipOrderNumberSelector(state)

  return {
    orderNumber,
    order: currentPackShipOrderSelector(state),
    isPrimeOrder: isPrimeOrderSelector(state, {orderNumber}),
    addressCanBeEdited: orderAddressCanBeEditedSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(OrderInfo)
