import get from 'lodash/get.js'
import uniq from 'lodash/uniq.js'
import {createSelector} from 'reselect'

import {updateForm, formSelector, getState} from '../../../store.js'
import {labelInfosSelector} from '../../../data/labelInfos/index.js'

const ORDER_ACTION_PANEL = 'ORDER_ACTION_PANEL'
export const SHIPPING_TAB = 'shipping'
export const RETURN_TAB = 'return'
export const DROPSHIP_TAB = 'dropship'
export const BATCH_DETAILS_TAB = 'batch_details'

export function setupOrderActionPanelForm() {
  return {
    formName: ORDER_ACTION_PANEL,
    initialForm: orderActionPanelSelector.default,
  }
}

export function orderActionPanelSelector(state) {
  return (
    formSelector(state, {formName: ORDER_ACTION_PANEL}) ||
    orderActionPanelSelector.default
  )
}
orderActionPanelSelector.default = {
  currentTab: SHIPPING_TAB,
  pendingLabels: [],
}

export function orderActionPanelCurrentTabSelector(state) {
  return orderActionPanelSelector(state).currentTab
}

export function pendingLabelsSelector(state) {
  return orderActionPanelSelector(state).pendingLabels
}

export const shippingPendingLabelsOrderNumbersSelector = createSelector(
  pendingLabelsSelector,
  labelInfosSelector,
  (pendingLabels, labelInfos) =>
    pendingLabels.reduce((prev, labelInfoID) => {
      const labelInfo = get(labelInfos, labelInfoID, {})

      if (labelInfo.type === 'shipping') {
        prev.push(labelInfo.orders[0])
      }

      return prev
    }, []),
)

export const returnPendingLabelsOrderNumbersSelector = createSelector(
  pendingLabelsSelector,
  labelInfosSelector,
  (pendingLabels, labelInfos) =>
    pendingLabels.reduce((prev, labelInfoID) => {
      const labelInfo = get(labelInfos, labelInfoID, {})

      if (labelInfo.type === 'return') {
        prev.push(labelInfo.orders[0])
      }

      return prev
    }, []),
)

export function setOrderActionPanelCurrentTab(currentTab) {
  updateForm(ORDER_ACTION_PANEL, {currentTab})
}

export function addPendingLabels(labelInfoIDs) {
  let {pendingLabels} = orderActionPanelSelector(getState())

  updateForm(ORDER_ACTION_PANEL, {
    pendingLabels: uniq([...labelInfoIDs, ...pendingLabels]),
  })
}

export function removePendingLabels(labelInfoIDs) {
  let {pendingLabels} = orderActionPanelSelector(getState())

  updateForm(ORDER_ACTION_PANEL, {
    pendingLabels: pendingLabels.filter(
      (labelInfoID) => !labelInfoIDs.includes(labelInfoID),
    ),
  })
}
