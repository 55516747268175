import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {
  MAGENTO_V2,
  MAGENTO,
  AUTH_TYPE_KEY,
  AUTH_TYPE_USER,
  AUTH_TYPE_ACCESS_TOKEN,
  cartVendorsByName,
} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import Select from '../../Common/Select.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.url)) {
    errors.url = 'URL is required'
  }

  if (
    [AUTH_TYPE_KEY, AUTH_TYPE_USER].includes(form.authType) &&
    !isPresent(form.username)
  ) {
    errors.username = 'Username is required'
    errors.preventSave = true
  }

  if (form.authType === AUTH_TYPE_KEY && !isPresent(form.key)) {
    errors.key = 'API Key is required'
    errors.preventSave = true
  }

  if (form.authType === AUTH_TYPE_USER && !isPresent(form.password)) {
    errors.password = 'Password is required'
    errors.preventSave = true
  }

  if (
    form.authType === AUTH_TYPE_ACCESS_TOKEN &&
    !isPresent(form.access_token)
  ) {
    errors.access_token = 'API Key is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: MAGENTO_V2}),
      url: '',
      username: '',
      key: '',
      password: '',
      access_token: '',
      authType: AUTH_TYPE_ACCESS_TOKEN,
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    url: cart.vendor_config.url,
    username: cart.vendor_config.username || '',
    key: cart.vendor_config.key || '',
    password: cart.vendor_config.password || '',
    access_token: cart.vendor_config.access_token || '',
    authType: cart.vendor_config.access_token
      ? AUTH_TYPE_ACCESS_TOKEN
      : cart.vendor_config.password
        ? AUTH_TYPE_USER
        : AUTH_TYPE_KEY,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError, authType} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'url') {
    errors.url = serverError.error_message
  } else if (
    [AUTH_TYPE_USER, AUTH_TYPE_KEY].includes(authType) &&
    serverError.param === 'username'
  ) {
    errors.username = serverError.error_message
  } else if (authType === AUTH_TYPE_KEY && serverError.param === 'key') {
    errors.key = serverError.error_message
  } else if (authType === AUTH_TYPE_USER && serverError.param === 'password') {
    errors.password = serverError.error_message
  } else if (
    authType === AUTH_TYPE_ACCESS_TOKEN &&
    serverError.param === 'access_token'
  ) {
    errors.access_token = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  const params = {
    ...baseSaveParamsSelector(state, {formName}),
    url: form.url,
  }

  if (form.authType === AUTH_TYPE_KEY) {
    params.username = form.username
    params.key = form.key
  } else if (form.authType === AUTH_TYPE_USER) {
    params.username = form.username
    params.password = form.password
    params.access_token = null
  } else if (form.authType === AUTH_TYPE_ACCESS_TOKEN) {
    params.access_token = form.access_token
  }

  return params
}

function Magento({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {(isNew || form.vendor === MAGENTO_V2) && (
            <FormListItem>
              <Select
                name="vendor"
                label="Version"
                value={form.authType}
                onChange={(event) => {
                  const authType = event.target.value
                  const vendor =
                    authType === AUTH_TYPE_KEY ? MAGENTO : MAGENTO_V2

                  updateForm(formName, {
                    vendor,
                    authType,
                    name: `${cartVendorsByName[vendor].display} Cart`,
                  })
                }}
              >
                {isNew && <option value={AUTH_TYPE_KEY}>{'1.x'}</option>}
                <option value={AUTH_TYPE_USER}>{'2.x (user auth)'}</option>
                <option value={AUTH_TYPE_ACCESS_TOKEN}>
                  {'2.x (integration auth)'}
                </option>
              </Select>
            </FormListItem>
          )}
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="url"
              label="Store URL"
              required
              placeholder="https://mymagentostore.gostorego.com"
              value={form.url || ''}
              errorMessage={form.hasChanged_url && errors.url}
              onChange={(event) =>
                updateForm(formName, {
                  url: event.target.value,
                  hasChanged_url: true,
                })
              }
            />
          </FormListItem>
          {[AUTH_TYPE_USER, AUTH_TYPE_KEY].includes(form.authType) && (
            <FormListItem>
              <TextInput
                name="username"
                label="Magento Username"
                required
                value={form.username || ''}
                errorMessage={form.hasChanged_username && errors.username}
                onChange={(event) =>
                  updateForm(formName, {
                    username: event.target.value,
                    hasChanged_username: true,
                  })
                }
              />
            </FormListItem>
          )}
          {form.authType === AUTH_TYPE_KEY && (
            <FormListItem>
              <TextInput
                name="key"
                label="Magento API Key"
                required
                value={form.key || ''}
                errorMessage={form.hasChanged_key && errors.key}
                onChange={(event) =>
                  updateForm(formName, {
                    key: event.target.value,
                    hasChanged_key: true,
                  })
                }
              />
            </FormListItem>
          )}
          {form.authType === AUTH_TYPE_USER && (
            <FormListItem>
              <TextInput
                type="password"
                name="password"
                label="Magento Password"
                required
                value={form.password || ''}
                errorMessage={form.hasChanged_password && errors.password}
                onChange={(event) =>
                  updateForm(formName, {
                    password: event.target.value,
                    hasChanged_password: true,
                  })
                }
              />
            </FormListItem>
          )}
          {form.authType === AUTH_TYPE_ACCESS_TOKEN && (
            <FormListItem>
              <TextInput
                name="access_token"
                label="Magento Access Token"
                required
                value={form.access_token || ''}
                errorMessage={
                  form.hasChanged_access_token && errors.access_token
                }
                onChange={(event) =>
                  updateForm(formName, {
                    access_token: event.target.value,
                    hasChanged_access_token: true,
                  })
                }
              />
            </FormListItem>
          )}
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Magento"
          href={
            form.vendor === MAGENTO
              ? 'https://support.ordoro.com/magento-and-ordoro-setup/'
              : 'https://support.ordoro.com/magento-2-0-and-ordoro-setup/'
          }
        />
      </div>
    </VendorForm>
  )
}

Magento.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    vendor: PropTypes.string.isRequired,
    authType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    hasChanged_url: PropTypes.bool,
    username: PropTypes.string.isRequired,
    hasChanged_username: PropTypes.bool,
    key: PropTypes.string.isRequired,
    hasChanged_key: PropTypes.bool,
    password: PropTypes.string.isRequired,
    hasChanged_password: PropTypes.bool,
    access_token: PropTypes.string.isRequired,
    hasChanged_access_token: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Magento, setUpCartForm)
