import PropTypes from 'prop-types'
import {useMemo} from 'react'
import cloneDeep from 'lodash/cloneDeep.js'
import debounce from 'lodash/debounce.js'
import isEqual from 'lodash/isEqual.js'
import classNames from 'classnames'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import {LabelInfoIDShape} from '../../../../common/PropTypes.js'
import {
  addParcel,
  updateLabelConfig,
  makeLabelShipperNameSelector,
  labelConfigSelector,
  configureIndividualBoxShapeSelector,
  orderNumberFromLabelConfigSelector,
  referenceIDFromLabelConfigSelector,
  canAddMoreParcelsSelector,
  selectedShippingRateCostSelector,
  ratesLoadingSelector,
  labelErrorsSelector,
  makeShippingMethodNameSelector,
  makeBoxShapeNameSelector,
  createLabelImageDisabledSelector,
} from '../../../../data/labelInfos/index.js'
import {getRates} from '../../../../data/labelInfos/rateRequest.js'
import Currency, {isTBD} from '../../../../common/components/Currency.js'
import Row from './Row.js'

export const MODAL_FORM = 'MULTIBOX_MODAL_FORM'

export function showMultiboxModal(labelInfoID, shipperType) {
  const savedConfig = cloneDeep(labelConfigSelector(getState(), {labelInfoID}))

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    savedConfig,
    isSaving: false,
    hasInputError: false,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

const debouncedGetRates = debounce(getRates, 500)

export async function cancelMultiboxModal() {
  const form = modalFormSelector(getState())

  // already gone
  if (!form) {
    return
  }

  const {labelInfoID, savedConfig} = form

  try {
    closeModal()

    const labelConfig = labelConfigSelector(getState(), {labelInfoID})

    if (!isEqual(labelConfig.packages, savedConfig.packages)) {
      updateLabelConfig(labelInfoID, {packages: savedConfig.packages})

      await getRates([labelInfoID])
    }
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

function MultiboxModal({form}) {
  const {labelInfoID, shipperType} = form

  const labelShipperNameSelector = useMemo(makeLabelShipperNameSelector, [])
  const shippingMethodSelector = useMemo(makeShippingMethodNameSelector, [])
  const boxShapeNameSelector = useMemo(makeBoxShapeNameSelector, [])

  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
  const referenceID = useSelector((state) =>
    referenceIDFromLabelConfigSelector(state, {labelInfoID}),
  )
  const shipperName = useSelector((state) =>
    labelShipperNameSelector(state, {labelInfoID}),
  )
  const shippingMethod = useSelector((state) =>
    shippingMethodSelector(state, {labelInfoID, shipperType}),
  )
  const firstBoxShapeName = useSelector((state) =>
    boxShapeNameSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )
  const configureIndividualBoxShape = useSelector((state) =>
    configureIndividualBoxShapeSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const createLabelImageDisabled = useSelector((state) =>
    createLabelImageDisabledSelector(state, {
      labelInfoID,
    }),
  )
  const cost = useSelector((state) =>
    selectedShippingRateCostSelector(state, {labelInfoID}),
  )
  const ratesLoading = useSelector((state) =>
    ratesLoadingSelector(state, {labelInfoID}),
  )
  const errors = useSelector((state) =>
    labelErrorsSelector(state, {labelInfoID, shipperType}),
  )
  const canAddMoreParcels = useSelector((state) =>
    canAddMoreParcelsSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ConfirmModal
      title="Configure Multi-Package Label"
      modalSize="x-lg"
      onConfirm={() => closeModal()}
      onCancel={() => cancelMultiboxModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={form.hasInputError}
      error={form.serverError}
    >
      <div className="fs-01">
        {orderNumber ? (
          <>
            <strong>Order ID:</strong> <span>{orderNumber}</span>
          </>
        ) : referenceID ? (
          <>
            <strong>RMA ID:</strong> <span>{referenceID}</span>
          </>
        ) : null}
      </div>
      <div className="fs-00 text--md-grey margin-bottom-20">
        <div>
          <span>{shipperName}, </span>
          {!configureIndividualBoxShape && <span>{firstBoxShapeName}, </span>}
          <span>{shippingMethod} – </span>
          {ratesLoading ? (
            <div className="spinning--sm spin--animation" />
          ) : (
            (createLabelImageDisabled && 'N/A') ||
            (isTBD(cost) && 'TBD') || (
              <strong className="success-txt">
                <Currency value={cost} />
              </strong>
            )
          )}
        </div>
        {errors.length > 0 && (
          <div
            className={classNames('wrap--error-wb lh-sm', {
              'op-50': ratesLoading,
            })}
          >
            {errors.map((err, i) => (
              <small className="error margin-top-1" key={i}>
                {err}
              </small>
            ))}
          </div>
        )}
      </div>

      <table className="table margin-bottom-0">
        <thead>
          <tr>
            <th className="table__th table__th--sm divider--right w-2">
              &nbsp;
            </th>
            {configureIndividualBoxShape && (
              <th className="table__th table__th--sm">Package Type</th>
            )}
            <th className="table__th table__th--sm w-20">Weight</th>
            <th className="table__th table__th--sm w-25">Dimensions</th>
            <th className="table__th table__th--sm w-15">Dry Ice Weight</th>
            <th className="table__th table__th--sm w-15">Declared Value</th>
            {shipperType === UPS && (
              <th className="table__th table__th--sm w-15">Misc</th>
            )}
            <th className="table__th table__th--sm w-2">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines">
          {labelConfig.packages.map((parcel, index) => (
            <Row
              key={index}
              labelInfoID={form.labelInfoID}
              shipperType={form.shipperType}
              packagesIndex={index}
              onChange={() => debouncedGetRates([form.labelInfoID])}
            />
          ))}
        </tbody>
      </table>
      <div className="divider--top dark margin-top-0 align-center">
        <button
          className="btn btn--link fs-n0"
          onClick={() => addParcel(form.labelInfoID)}
          disabled={!canAddMoreParcels}
        >
          + Add another package
        </button>
      </div>
    </ConfirmModal>
  )
}

MultiboxModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    hasInputError: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(MultiboxModal, modalFormSelector)
