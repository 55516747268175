import {all, takeEvery, put, call} from 'redux-saga/effects'

import {setIsLoading, setGlobalError} from '../../actions/ui/index.js'
import {GET_INITIAL_DATA} from '../../actions/data/index.js'
import StripeV2 from '../../../libs/stripe.js'
import * as StripeV3 from '../../../libs/stripeV3.js'
import {getCarts} from '../../../data/carts.js'
import {getShippers, checkEndiciaStatus} from '../../../data/shippers.js'
import {getWarehouses} from '../../../data/warehouses.js'
import {getSuppliers} from '../../../data/suppliers.js'
import {getAllTasks} from '../../actions/data/tasks.js'
import {fetchPackingLists} from '../../actions/data/packingLists.js'
import {getPresets} from '../../../data/presets.js'
import {getUsers} from '../../../data/users.js'
import {getOrderTags} from '../../../data/orderTags.js'
import {
  updateOrderCounts,
  updateOrderRevisionCount,
} from '../../../data/orderCounts.js'
import {getAPIKeys} from '../../actions/data/apiKeys.js'
import {getPrintConfigs} from '../../actions/data/printConfigs.js'
import {getSomeProducts} from '../../../data/products.js'
import {getProductTags} from '../../../data/productTags.js'
import {getReports} from '../../../data/reports.js'
import {getReturnOrderCodes} from '../../actions/data/returnOrderCodes.js'
import {getReturnOrderTags} from '../../../data/returnOrderTags.js'
import {setupPrinterSettingsForm} from '../../../data/printerSettings.js'
import {determineCreditCardNeed} from '../../../ordoro/Modals/CreditCardModal.js'
import {getMOTags} from '../../../data/moTags.js'
import {getIntegrations} from '../../../data/integrations.js'
import {getPOTags} from '../../../data/poTags.js'

import isRunningTasks from './isRunningTasks.js'
import packingLists from './packingLists.js'
import apiKeys from './apiKeys.js'
import serverInfo from './serverInfo.js'
import printConfigs from './printConfigs.js'
import commonAppMessage from './commonAppMessage.js'
import returnOrderCodes from './returnOrderCodes.js'
import {getLayouts} from '../../../data/layout.js'
import {getPaymentAccounts} from '../../../data/paymentAccounts.js'

export function* getInitialDataWorker() {
  try {
    yield put(fetchPackingLists())
    yield put(getAPIKeys())
    yield put(getPrintConfigs())
    yield put(getReturnOrderCodes())

    yield all([
      call(getAllTasks),
      call(getIntegrations),
      call(getLayouts),
      call(getPOTags),
      call(getPresets),
      call(getProductTags),
      call(getReturnOrderTags),
      call(getReports),
      call(getOrderTags),
      call(updateOrderCounts),
      call(updateOrderRevisionCount),
      call(function* () {
        yield call(getCarts)
      }),
      call(getShippers),
      call(getWarehouses),
      call(getSuppliers),
      call(getMOTags),
      call(StripeV2.init),
      call(StripeV3.init),
      call(getSomeProducts),
      call(getUsers),
      call(setupPrinterSettingsForm),
      call(getPaymentAccounts),
    ])

    yield all([call(checkEndiciaStatus), call(determineCreditCardNeed)])
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'Error loading initial data',
        },
        err,
      ),
    )
  } finally {
    yield put(setIsLoading(false))
  }
}

export default function* dataSaga() {
  yield all([
    isRunningTasks(),
    packingLists(),
    apiKeys(),
    serverInfo(),
    printConfigs(),
    commonAppMessage(),
    returnOrderCodes(),
    takeEvery(GET_INITIAL_DATA, getInitialDataWorker),
  ])
}
