import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {useSelector} from '../../../../store.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import Select from '../../../../common/components/Select.js'
import Currency from '../../../../common/components/Currency.js'
import TextArea from '../../../../common/components/TextArea.js'
import ProductFilter from '../../../../common/components/ProductFilter.js'
import ButtonLink from '../../../../common/components/Button/ButtonLink.js'
import {productSelector} from '../../../../data/products.js'
import {returnOrderCodesSortedByCodeSelector} from '../../../../redux/selectors/data/returnOrderCodes.js'
import {
  setCreateReturnOrderModalFormValue,
  createReturnOrderModalFormSelector,
  createReturnOrderModalErrorsSelector,
  initializeCreateReturnOrderLine,
} from './createReturnOrderFunctions.js'

export default function Line({sku, index}) {
  const {lines} = useSelector(createReturnOrderModalFormSelector)
  const name = useSelector((state) =>
    get(productSelector(state, {sku}), 'name', ''),
  )
  const errors = useSelector(createReturnOrderModalErrorsSelector)
  const returnOrderCodes = useSelector(returnOrderCodesSortedByCodeSelector)

  const line = lines[index]
  const {
    expectedQuantity,
    isSelected,
    quantityInOrder,
    totalPrice,
    returnCode,
  } = line

  return (
    <tr>
      <td className="table__td">
        <Checkbox
          className="margin-bottom-0"
          checked={isSelected}
          onChange={() =>
            setCreateReturnOrderModalFormValue(
              `lines.${index}.isSelected`,
              !isSelected,
            )
          }
        />
      </td>
      <td className="table__td">
        <div className="wrap--product-filter-stacked">
          {sku ? (
            <ButtonLink
              className="darker align-left"
              onClick={() =>
                setCreateReturnOrderModalFormValue(`lines.${index}.sku`, null)
              }
            >
              <div className="fs-n0">
                <strong>{name}</strong>
              </div>
              <div className="fs-n1">{sku}</div>
              <div className="fs-n1 lh-md text--lt-grey">Edit</div>
            </ButtonLink>
          ) : (
            <ProductFilter
              dropdown={`CREATE_RMA_PRODUCT_FILTER__${index}`}
              onSelect={(product) =>
                initializeCreateReturnOrderLine(index, product)
              }
              autoFocus
            />
          )}
        </div>
      </td>
      <td className="table__td">
        {totalPrice !== null && quantityInOrder !== null && (
          <span className="fs-n1">
            <Currency value={totalPrice / quantityInOrder} />
          </span>
        )}
      </td>
      <td className="table__td">
        {quantityInOrder !== null && (
          <span className="fs-n1">{quantityInOrder}</span>
        )}
      </td>
      <td className="table__td">
        {isSelected && (
          <>
            <NumberInput
              className="margin-bottom-0"
              value={expectedQuantity}
              onChange={(value) =>
                setCreateReturnOrderModalFormValue(
                  `lines.${index}.expectedQuantity`,
                  `${value}`,
                )
              }
              autoFocus={!!sku}
            />
            {errors[`lines__${index}__expectedQuantity`] && (
              <small className="error">
                {errors[`lines__${index}__expectedQuantity`]}
              </small>
            )}
          </>
        )}
      </td>
      <td className="table__td">
        {isSelected && (
          <Select
            className="w-100 fs-n1"
            value={returnCode}
            onChange={(value) =>
              setCreateReturnOrderModalFormValue(
                `lines.${index}.returnCode`,
                value,
              )
            }
          >
            <option value="">Select a return code...</option>
            {returnOrderCodes.map((returnOrderCode) => (
              <option key={returnOrderCode.code} value={returnOrderCode.code}>
                {returnOrderCode.code}
              </option>
            ))}
          </Select>
        )}
      </td>
      <td className="table__td">
        <TextArea
          size="mh-auto"
          className="textarea w-100 fs-n1"
          placeholder="For multiple serial numbers, separate them with line breaks"
          value={line.product_serial_numbers}
          onChange={(value) =>
            setCreateReturnOrderModalFormValue(
              `lines.${index}.product_serial_numbers`,
              value,
            )
          }
        />
      </td>
    </tr>
  )
}

Line.propTypes = {
  index: PropTypes.number.isRequired,
  sku: PropTypes.string,
}
