import {useSelector} from '../../../../store.js'
import {CREATE_RETURN_ORDER_CUSTOMER_ADDRESS} from '../../../../common/constants/AddressTypes.js'
import {showPasteAddressModal} from '../../../../common/components/Modal/PasteAddressModal.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import TextInput from '../../../../common/components/TextInput.js'
import {
  setCreateReturnOrderModalFormValue,
  createReturnOrderModalFormSelector,
} from './createReturnOrderFunctions.js'

export default function CustomerAddressForm() {
  const form = useSelector(createReturnOrderModalFormSelector)

  return (
    <>
      <li className="list__item fs-n1 margin-top-15 margin-bottom-15">
        <ButtonPrimary
          isOutlined
          size="x-sm"
          onClick={() =>
            showPasteAddressModal({
              addressType: CREATE_RETURN_ORDER_CUSTOMER_ADDRESS,
              address: form.customerAddress,
            })
          }
        >
          Paste Address
        </ButtonPrimary>
      </li>
      <li className="list__item list__item--form margin-top-5 margin-bottom-10">
        <TextInput
          label="Name*"
          className="margin-bottom-0"
          id="customer_address__name"
          value={form.customerAddress.name}
          onChange={(value) =>
            setCreateReturnOrderModalFormValue('customerAddress.name', value)
          }
          fsExclude
        />
      </li>
      <li className="list__item list__item--form">
        <TextInput
          label="Company"
          className="margin-bottom-0"
          id="customer_address__company"
          value={form.customerAddress.company}
          onChange={(value) =>
            setCreateReturnOrderModalFormValue('customerAddress.company', value)
          }
          fsExclude
        />
      </li>
      <li className="list__item list__item--form">
        <TextInput
          label="Street 1*"
          className="margin-bottom-0"
          id="customer_address__street1"
          value={form.customerAddress.street1}
          onChange={(value) =>
            setCreateReturnOrderModalFormValue('customerAddress.street1', value)
          }
          fsExclude
        />
      </li>
      <li className="list__item list__item--form">
        <TextInput
          label="Street 2"
          className="margin-bottom-0"
          id="customer_address__street2"
          value={form.customerAddress.street2}
          onChange={(value) =>
            setCreateReturnOrderModalFormValue('customerAddress.street2', value)
          }
          fsExclude
        />
      </li>
      <li className="list__item list__item--form flex--justify">
        <div className="wrap--input-half">
          <TextInput
            label="City"
            id="customer_address__city"
            value={form.customerAddress.city}
            onChange={(value) =>
              setCreateReturnOrderModalFormValue('customerAddress.city', value)
            }
            fsExclude
          />
        </div>
        <div className="wrap--input-eighth">
          <TextInput
            label="State"
            id="customer_address__state"
            value={form.customerAddress.state}
            onChange={(value) =>
              setCreateReturnOrderModalFormValue('customerAddress.state', value)
            }
            fsExclude
          />
        </div>
        <div className="wrap--input-third">
          <TextInput
            label="Zip"
            id="customer_address__zip"
            value={form.customerAddress.zip}
            onChange={(value) =>
              setCreateReturnOrderModalFormValue('customerAddress.zip', value)
            }
            fsExclude
          />
        </div>
      </li>
      <li className="list__item list__item--form flex--justify">
        <div className="wrap--input-half">
          <TextInput
            label="Country"
            id="customer_address__country"
            value={form.customerAddress.country}
            onChange={(value) =>
              setCreateReturnOrderModalFormValue(
                'customerAddress.country',
                value,
              )
            }
            fsExclude
          />
        </div>
      </li>
    </>
  )
}
