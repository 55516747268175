import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {errorMessageSelector} from '../../../data/printNode.js'
import {hasPrintNodeAPIKeySelector} from '../../../data/company.js'
import APIKeyInput from './APIKeyInput.js'

export function PrintNodeHelp({hasAPIKey, errorMessage}) {
  return (
    <div className="row">
      <div className="medium-7 columns">
        <ol className="list list--ol margin-bottom-10">
          <li className="list__item margin-bottom-20">
            {'Sign up for a '}
            <a
              href="https://www.printnode.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PrintNode
            </a>
            {' account'}
          </li>
          <li className="list__item margin-bottom-20">
            {'Download and install the '}
            <a
              href="https://www.printnode.com/docs/installation/windows/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PrintNode client
            </a>
          </li>
          <li className="list__item margin-bottom-20">
            {'From your PrintNode account, '}
            <a
              href="https://support.ordoro.com/how-to-set-up-the-direct-to-scale-feature-in-ordoro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              generate an API Key
            </a>
          </li>
          <li className="list__item list__item--no-api-key margin-bottom-0">
            <label
              className="fs-01 margin-bottom-5 label--printnode-api-key"
              htmlFor="printnode_api_key"
            >
              Enter your PrintNode API Key
            </label>
            <APIKeyInput />
            {hasAPIKey && errorMessage && (
              <small className="error">{errorMessage}</small>
            )}
          </li>
        </ol>
      </div>
    </div>
  )
}

PrintNodeHelp.propTypes = {
  hasAPIKey: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    hasAPIKey: hasPrintNodeAPIKeySelector(state),
    errorMessage: errorMessageSelector(state),
  }
}

export default connect(mapStateToProps)(PrintNodeHelp)
