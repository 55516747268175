import {combineReducers} from 'redux'
import round from 'lodash/round.js'

import {isNonZeroPositiveNumeric} from '../../../../common/utils.js'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {
  SET_PAGE_TOP_MARGIN,
  SET_PAGE_LEFT_MARGIN,
  SET_LABEL_WIDTH,
  SET_LABEL_HEIGHT,
  SET_ERROR,
  SET_LOADING,
} from '../../../actions/ui/labelViewForm/index.js'

const BaseState = INITIAL_STATE.ui.labelViewForm

function pageTopMargin(state = BaseState.pageTopMargin, action = {}) {
  if (action.type === SET_PAGE_TOP_MARGIN) {
    return action.payload
  }
  return state
}

function pageLeftMargin(state = BaseState.pageLeftMargin, action = {}) {
  if (action.type === SET_PAGE_LEFT_MARGIN) {
    return action.payload
  }
  return state
}

function error(state = BaseState.error, action = {}) {
  if (action.type === SET_ERROR) {
    return action.payload
  }
  return state
}

function loading(state = BaseState.loading, action = {}) {
  if (action.type === SET_LOADING) {
    return action.payload
  }
  return state
}

function labelHeight(state = BaseState.labelHeight, action = {}) {
  if (action.type === SET_LABEL_HEIGHT) {
    if (!isNonZeroPositiveNumeric(action.payload)) {
      return null
    }

    return action.payload
  }

  if (action.type === SET_LABEL_WIDTH) {
    if (!isNonZeroPositiveNumeric(action.payload)) {
      return null
    }

    return String(round(action.payload * (6 / 4), 2))
  }

  return state
}

function labelWidth(state = BaseState.labelWidth, action = {}) {
  if (action.type === SET_LABEL_HEIGHT) {
    if (!isNonZeroPositiveNumeric(action.payload)) {
      return null
    }

    return String(round(action.payload * (4 / 6), 2))
  }

  if (action.type === SET_LABEL_WIDTH) {
    if (!isNonZeroPositiveNumeric(action.payload)) {
      return null
    }

    return action.payload
  }

  return state
}

export default combineReducers({
  pageTopMargin,
  pageLeftMargin,
  error,
  loading,
  labelWidth,
  labelHeight,
})
