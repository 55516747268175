import PropTypes from 'prop-types'
import {useMemo} from 'react'
import isEqual from 'lodash/isEqual.js'

import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import Radio from '../../../common/components/Radio.js'
import {Count, plural} from '../../../common/components/Plural.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showOrderQueryFilterModal} from '../../OrderListPage/Modals/OrderListFilterModal/orderListFilterModalFunctions.js'
import {
  DATE_CREATED,
  DATE_DROPSHIPPED,
  DATE_SHIPPED,
} from '../../../common/constants/OrderListDateRangeFilters.js'
import {
  cleanOrderQuery,
  getOrderDateRangeParams,
} from '../../OrderListPage/orderListSelectors.js'
import {ensureArray, ensureArrayOfNumbers} from '../../../common/ensure.js'
import {orderTagsSortedByNameSelector} from '../../../data/orderTags.js'
import {orderFilterList} from '../../OrderListPage/OrderListOmniBar.js'
import {cartsSortedByNameSelector} from '../../../data/carts.js'
import {shippersSortedByIDSelector} from '../../../data/shippers.js'
import {suppliersSortedByNameSelector} from '../../../data/suppliers.js'
import {warehousesSortedByNameSelector} from '../../../data/warehouses.js'
import {
  REPORTS_BY_TYPE,
  REPORT_EXPORT_ORDERS,
  REPORT_EXPORT_SHIPPED_ORDERS,
} from '../../../common/constants/Reports.js'
import {tagColors} from '../../../common/colors.js'

/**
 * Take saved report params and transform them into a useable shape for querying.
 * It also translates old params from before we switched this report to use the
 * GET /order query param shape.
 *
 * @param {object} params object of params, either the old shape or one that
 *  mirrors GET /order query params
 * @returns {object} query object used by GET /order
 */
function translateParamsOrderListQuery(params) {
  const type =
    params.date_type === 'created_date'
      ? DATE_CREATED
      : params.date_type === 'ship_date'
        ? DATE_SHIPPED
        : params.date_type === 'dropship_date'
          ? DATE_DROPSHIPPED
          : params.date_type || DATE_CREATED

  const orderQuery = {
    ...params,
    ...getOrderDateRangeParams(
      params.start_date,
      params.end_date,
      type,
      params.interval_type,
      params.interval_amount,
      params.interval_trunc,
      params.timezone,
    ),
  }

  if (params.status) {
    orderQuery.status = ensureArray(params.status)
  }

  if (params.tags) {
    orderQuery.tag = ensureArray(params.tags)
  }

  if (params.supplier) {
    orderQuery.supplier = ensureArrayOfNumbers(params.supplier)
  }

  if (params.cart) {
    orderQuery.sales_channel = ensureArrayOfNumbers(params.cart)
  }

  if (params.shippers) {
    const shippers = params.shippers.map((v) => (v === 'No Shipper' ? -1 : v))
    orderQuery.shipper = ensureArrayOfNumbers(shippers)
  }

  if (params.allocation_status) {
    orderQuery.allocation_status = ensureArray(params.allocation_status)
  }

  return cleanOrderQuery(orderQuery)
}

export function setupExportOrdersForm(params = {}) {
  let {
    reportID,
    orderQuery = {},
    queryTotalCount = null,
    selectedOrderNumbers = [],
  } = params

  let {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: orderQuery}

  orderQuery = translateParamsOrderListQuery(reportParams)

  delete orderQuery.sort
  delete orderQuery.limit
  delete orderQuery.offset

  const querySelection = selectedOrderNumbers.length ? 'selection' : 'query'

  return {
    querySelection,
    orderQuery,
    queryTotalCount,
    selectedOrderNumbers,
  }
}

export function exportOrdersErrorsSelector() {
  return {}
}

export function exportOrdersPayloadSelector(state, {formName}) {
  const {reportType, orderQuery, selectedOrderNumbers, querySelection} =
    formSelector(state, {formName})

  return {
    type: reportType,
    params:
      querySelection === 'selection'
        ? {order_number: selectedOrderNumbers}
        : orderQuery,
  }
}

async function editOrderListQueryParams({formName}) {
  const form = formSelector(getState(), {formName})

  const orderQuery = await showOrderQueryFilterModal({
    query: form.orderQuery,
    singleRun: form.singleRun,
  })

  if (!isEqual(orderQuery, form.orderQuery)) {
    updateForm(formName, {
      orderQuery,
      queryTotalCount: null,
    })
  }
}

export default function ExportOrdersForm({formName}) {
  const form = useForm(formName)
  const carts = useSelector(cartsSortedByNameSelector)
  const shippers = useSelector(shippersSortedByIDSelector)
  const suppliers = useSelector(suppliersSortedByNameSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const tags = useSelector(orderTagsSortedByNameSelector)

  const filterListByType = useMemo(
    () => [
      ...orderFilterList({
        carts,
        shippers,
        suppliers,
        warehouses,
        tags,
        intervalSummary: false,
        query: form.orderQuery,
      })
        .reduce((prev, filter) => {
          const list = prev.get(filter.type) || []

          list.push(filter)

          prev.set(filter.type, list)

          return prev
        }, new Map())
        .entries(),
    ],
    [carts, shippers, suppliers, warehouses, tags, form.orderQuery],
  )

  const filterLabel =
    form.queryTotalCount === null
      ? 'Export using custom filter set'
      : form.queryTotalCount === 0
        ? 'No orders will be exported'
        : plural(form.queryTotalCount)`Export${[' all', '']} ${Count} order${[
            's',
          ]} in the current filter set`

  return (
    <ul className="list list--no-style">
      {form.reportID ? (
        <li className="list__item--form list__item--no-style">
          <p className="fs-00 margin-top-20 margin-bottom-10">
            <strong>
              Level of Detail:{' '}
              {form.reportType === REPORT_EXPORT_ORDERS
                ? 'Order Summary'
                : 'Order Detail'}
            </strong>
          </p>
        </li>
      ) : (
        <li className="list__item--form list__item--no-style divider--bottom margin-bottom-15 padding-bottom-20">
          <label htmlFor="id_detail_level">Level of Detail</label>
          <select
            className="select w-100 margin-bottom-0"
            id="id_detail_level"
            value={form.reportType}
            onChange={(event) =>
              updateForm(formName, {reportType: event.target.value})
            }
          >
            {[
              REPORTS_BY_TYPE[REPORT_EXPORT_ORDERS],
              REPORTS_BY_TYPE[REPORT_EXPORT_SHIPPED_ORDERS],
            ].map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        </li>
      )}
      <li className="list__item--form list__item--wrap-order-report-filters divider--bottom margin-bottom-15 padding-bottom-20">
        <div className="flex margin-bottom-10">
          <strong className="fs-n0 lh-md margin-right-10 flex--justify-col">
            Current Order Filters
          </strong>
          <div>
            <ButtonPrimary
              isOutlined
              size="xx-sm"
              className="flex--justify-col"
              onClick={() => editOrderListQueryParams({formName})}
            >
              Edit
            </ButtonPrimary>
          </div>
        </div>
        {filterListByType.length === 0 ? (
          <p className="fs-n0 lh-md margin-bottom-0">
            <em>No filters have been applied to the orders.</em>
          </p>
        ) : (
          filterListByType.map(([type, list]) => (
            <div key={type} className="flex flex-wrap margin-bottom-3">
              {list.map(({type, label, color}, index) => (
                <div
                  key={index}
                  style={color ? tagColors(color) : {}}
                  className={`omni-bar_value-type_${type} omni-bar__multi-value omni-bar__multi-value-modal`}
                >
                  <div className="omni-bar__multi-value__label">{label}</div>
                </div>
              ))}
            </div>
          ))
        )}
      </li>
      {form.selectedOrderNumbers.length > 0 && (
        <li className="list__item--form margin-bottom-15">
          <Radio
            mode="fancy"
            label={plural(form.selectedOrderNumbers)`Export ${Count} ${[
              'records that are',
              'record that is',
            ]} currently selected`}
            id="query_selection__selection"
            checked={form.querySelection === 'selection'}
            onChange={() => updateForm(formName, {querySelection: 'selection'})}
          />
        </li>
      )}
      <li className="list__item--form margin-bottom-15">
        {form.selectedOrderNumbers.length > 0 ? (
          <Radio
            mode="fancy"
            label={filterLabel}
            id="query_selection__query"
            checked={form.querySelection === 'query'}
            onChange={() => updateForm(formName, {querySelection: 'query'})}
          />
        ) : (
          <strong className="fs-n0 lh-md">{filterLabel}.</strong>
        )}
      </li>
    </ul>
  )
}

ExportOrdersForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
