import {useMemo} from 'react'
import {shipperOptionsSelector} from '../../../../data/shippers.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from './FilterPanel.js'
import {
  modalFormSelector,
  SHIPPER_FILTER_PANEL,
  updateModalForm,
} from './orderListFilterModalFunctions.js'
import {tokenizeOptions} from '../../../../common/tokenizeOptions.js'

export default function ShipperFilterPanel() {
  const form = useSelector(modalFormSelector)
  const shipperOptions = useSelector(shipperOptionsSelector)

  const shipperOptionsPlus = useMemo(
    () => [
      {value: -1, display: 'No Carrier', nouns: ['no', 'carrier', 'shipper']},
      ...shipperOptions,
    ],
    [shipperOptions],
  )
  const shipperOptionTokens = useMemo(
    () => tokenizeOptions(shipperOptionsPlus),
    [shipperOptionsPlus],
  )

  return (
    <FilterPanel
      id={SHIPPER_FILTER_PANEL}
      label="Carrier"
      searchPlaceholder="Search for a carrier filter..."
      options={shipperOptionsPlus}
      optionTokens={shipperOptionTokens}
      selected={form.shipper}
      onSelect={(option) => {
        const shipper = form.shipper.filter((id) => id !== option.value)

        if (shipper.length === form.shipper.length) {
          shipper.push(option.value)
        }

        updateModalForm({shipper})
      }}
    />
  )
}
