import PropTypes from 'prop-types'
import {useCallback} from 'react'
import {connect} from 'react-redux'

import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import BaseEditForm from '../BaseEditForm.js'
import EditItemForm from './EditItemForm.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../../../data/warehouses.js'

function EditForm({
  data,
  updatePayload,
  suppliersSortedByName,
  warehousesSortedByName,
  ...props
}) {
  const onChange = useCallback(
    (updates) => updatePayload(data.index, updates),
    [data.index],
  )
  const itemsErrors = data.errors.items || {}

  return (
    <BaseEditForm className="medium-6 columns" {...props}>
      <Row>
        <div className="medium-12 columns">
          <h5>Purchase Order Info</h5>
        </div>
      </Row>
      <Row>
        <TextInput
          value={data.payload.po_id || ''}
          errorMessage={data.errors.po_id}
          name="po_id"
          label="Purchase Order ID"
          className="medium-12"
          onChange={(po_id) => onChange({po_id})}
        />
      </Row>
      <Row>
        <Select
          value={data.payload.supplier_id || ''}
          errorMessage={data.errors.supplier_id}
          name="supplier_id"
          label="Supplier"
          className="columns medium-12 wrap--full-select"
          selectClassName="full-width"
          onChange={(supplier_id) =>
            onChange({
              supplier_id: Number(supplier_id) || null,
              supplier_name_is_valid: true,
            })
          }
        >
          <option value=""></option>
          {suppliersSortedByName.map((supplier) => (
            <option key={supplier.id} value={supplier.id}>
              {getSupplierName(supplier)}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <Select
          value={data.payload.warehouse_id || ''}
          errorMessage={data.errors.warehouse_id}
          name="warehouse_id"
          label="Warehouse"
          className="columns medium-12 wrap--full-select"
          selectClassName="full-width"
          onChange={(warehouse_id) =>
            onChange({
              warehouse_id: Number(warehouse_id) || null,
              warehouse_name_is_valid: true,
            })
          }
        >
          <option value=""></option>
          {warehousesSortedByName.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {getWarehouseName(warehouse)}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <TextInput
          value={data.payload.shipping_method || ''}
          errorMessage={data.errors.shipping_method}
          name="shipping_method"
          label="Shipping Method"
          className="medium-6"
          onChange={(shipping_method) => onChange({shipping_method})}
        />
        <TextInput
          value={data.payload.payment_method || ''}
          errorMessage={data.errors.payment_method}
          name="payment_method"
          label="Payment Method"
          className="medium-6"
          onChange={(payment_method) => onChange({payment_method})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.instructions || ''}
          errorMessage={data.errors.instructions}
          name="instructions"
          label="Instructions"
          className="medium-12"
          onChange={(instructions) => onChange({instructions})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.estimated_delivery_date}
          errorMessage={data.errors.estimated_delivery_date}
          name="estimated_delivery_date"
          label="Estimated Delivery Date"
          className="medium-12"
          onChange={(estimated_delivery_date) =>
            onChange({estimated_delivery_date})
          }
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.tax_amount || ''}
          errorMessage={data.errors.tax_amount}
          name="tax_amount"
          label="Tax Amount"
          className="medium-6"
          onChange={(tax_amount) => onChange({tax_amount})}
        />
        <TextInput
          value={data.payload.discount_amount || ''}
          errorMessage={data.errors.discount_amount}
          name="discount_amount"
          label="Discount Amount"
          className="medium-6"
          onChange={(discount_amount) => onChange({discount_amount})}
        />
      </Row>
      <Row>
        <TextInput
          value={data.payload.shipping_amount || ''}
          errorMessage={data.errors.shipping_amount}
          name="shipping_amount"
          label="Shipping Amount"
          className="medium-6"
          onChange={(shipping_amount) => onChange({shipping_amount})}
        />
      </Row>
      <Row>
        <div className="medium-12 columns">
          <hr />
          <h5 className="margin-bottom-0">Items</h5>
        </div>
      </Row>
      {data.payload.items.map((item, index) => {
        const itemErrors = itemsErrors[index] || {}

        return (
          <EditItemForm
            key={index}
            dataIndex={data.index}
            items={data.payload.items}
            itemIndex={index}
            errors={itemErrors}
            updatePayload={updatePayload}
          />
        )
      })}
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
  suppliersSortedByName: PropTypes.array.isRequired,
  warehousesSortedByName: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    suppliersSortedByName: suppliersSortedByNameSelector(state),
    warehousesSortedByName: warehousesSortedByNameSelector(state),
  }
}

export default connect(mapStateToProps)(EditForm)
