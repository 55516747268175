import {initiateReturn} from './Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import {REPORT_EXPORT_RMA_SUMMARY} from '../../common/constants/Reports.js'
import {showEditReportModal} from '../ReportsPage/Modals/EditReportModal.js'

export default function Actions() {
  return (
    <>
      <li className="inline-block v-align-middle fs-n0 divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => initiateReturn()}
        >
          Initiate a Return (RMA)
        </button>
      </li>
      <li className="inline-block v-align-middle fs-n0 margin-left-15">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_RMA_SUMMARY,
              singleRun: true,
            })
          }
        >
          Export RMAs
        </button>
      </li>
    </>
  )
}
