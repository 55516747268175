import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {setSupplierFilter} from './analyticsActions.js'
import {activeSupplierFiltersSelector} from './analyticsSelectors.js'

export function SupplierActiveFilters({activeSupplierFilters}) {
  if (activeSupplierFilters.length === 0) {
    return null
  }

  return (
    <div className="row">
      <div className="medium-12 columns">
        <ul className="list list--inline margin-left-10">
          <li className="list__item--inline-block list__item--active-filter margin-right-1">
            <strong>Supplier filters:</strong>
          </li>
          {activeSupplierFilters.map(({value, display}) => (
            <li
              className="list__item--inline-block list__item--active-filter filter--channel margin-left-5"
              key={value}
            >
              <button
                type="button"
                className="inline-text-button inline-remove inline-remove-dark"
                onClick={() => setSupplierFilter(value, false)}
              >
                x
              </button>{' '}
              <span className="text--active-filter">{display}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

SupplierActiveFilters.propTypes = {
  activeSupplierFilters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

function mapStateToProp(state) {
  return {
    activeSupplierFilters: activeSupplierFiltersSelector(state),
  }
}

export default connect(mapStateToProp)(SupplierActiveFilters)
