export const BASE = 'ordoro/data/isRunningTasks'
export const SET_IS_RUNNING_TASKS = `${BASE}/SET_IS_RUNNING_TASKS`
export const CHECK_RUNNING_TASKS = `${BASE}/CHECK_RUNNING_TASKS`
export const START_CHECKING_FOR_RUNNING_TASKS = `${BASE}/START_CHECKING_FOR_RUNNING_TASKS`

export function setIsRunningTasks(isRunning) {
  return {
    type: SET_IS_RUNNING_TASKS,
    payload: isRunning,
  }
}

export function checkRunningTasks() {
  return {
    type: CHECK_RUNNING_TASKS,
  }
}

export function startCheckingForRunningTasks() {
  return {
    type: START_CHECKING_FOR_RUNNING_TASKS,
  }
}
