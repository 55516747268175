import {createSelector} from 'reselect'

import {isPositiveNumeric, isPresent} from '../../../../common/utils.js'
import {formsSelector} from '../../../../store.js'

export const CREATE_PO_MODAL = 'CREATE_PO_MODAL'

export const createPOModalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[CREATE_PO_MODAL],
)

export function errorsSelector(state) {
  const {lines} = createPOModalFormSelector(state)

  const errors = {}

  for (let index = 0; index < lines.length; index++) {
    if (!isPresent(lines[index].sku)) {
      errors[`lines_${index}_sku`] = 'Product selection is required'
      errors.preventSave = true
    }

    if (!isPositiveNumeric(lines[index].quantity)) {
      errors[`lines_${index}_quantity`] = 'Enter a positive number'
      errors.preventSave = true
    }

    if (!isPositiveNumeric(lines[index].unitPrice)) {
      errors[`lines_${index}_unitPrice`] = 'Enter a positive number'
      errors.preventSave = true
    }
  }

  if (lines.length === 0) {
    errors.lines = 'Add at least one product'
    errors.preventSave = true
  }

  return errors
}
