import {createSelector} from 'reselect'
import reduce from 'lodash/reduce.js'
import isEmpty from 'lodash/isEmpty.js'

import {NEW_ID} from '../../../../common/constants/index.js'
import {isPresent} from '../../../../common/utils.js'
import {SETTINGS_PROFILES_URL} from '../../../actions/ui/settings/packingLists/index.js'
import {companyIDSelector} from '../../../../data/company.js'
import {getPackingListLogoURL} from '../../data/packingLists.js'

export function packingListsSettingsSelector(state) {
  return state.ui.settings.packingLists
}

export const currentPackingListSelector = createSelector(
  packingListsSettingsSelector,
  ({currentPackingList}) => currentPackingList,
)

export function selectedProfileTabSelector(state) {
  return packingListsSettingsSelector(state).selectedProfileTab
}

export const errorsSelector = createSelector(
  currentPackingListSelector,
  ({name, _linklogo}) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
    }

    if (
      isPresent(_linklogo) &&
      !(
        _linklogo.match(/^data:image\/(jpg|jpeg|png|gif);base64,/) ||
        _linklogo.match(/^http/)
      )
    ) {
      errors._linklogo = 'Invalid image type'
    }

    return errors
  },
)

export const errorsOfChangedSelector = createSelector(
  (state) => state.ui.settings.packingLists.hasChanged,
  errorsSelector,
  (hasChanged, errors) =>
    reduce(
      errors,
      (e, value, key) => {
        if (hasChanged[key]) {
          e[key] = value
        }

        return e
      },
      {},
    ),
)

export const hasErrorsSelector = createSelector(
  errorsSelector,
  (errors) => !isEmpty(errors),
)

export const packingListsHashSelector = createSelector(
  packingListsSettingsSelector,
  ({editID, isNew, selectedProfileTab}) => {
    if (isNew) {
      return `${SETTINGS_PROFILES_URL}/${selectedProfileTab}/${NEW_ID}`
    }
    if (editID) {
      return `${SETTINGS_PROFILES_URL}/${selectedProfileTab}/${editID}`
    }

    return `${SETTINGS_PROFILES_URL}/${selectedProfileTab}`
  },
)

export const currentPackingListLogoURLSelector = createSelector(
  currentPackingListSelector,
  companyIDSelector,
  getPackingListLogoURL,
)
