import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_SORT = '-updated_date'
export const DEFAULT_PER_PAGE = 10

export const BATCH_SORT_OPTIONS = [
  {value: 'reference_id', display: 'Batch ID (A→Z)'},
  {value: '-reference_id', display: 'Batch ID (Z→A)'},
  {value: '-created_date', display: 'Date Created (Newest)'},
  {value: 'created_date', display: 'Date Created (Oldest)'},
  {value: '-updated_date', display: 'Date Updated (Newest)'},
  {value: 'updated_date', display: 'Date Updated (Oldest)'},
]

export const BATCH_PLURAL_URI_COMPONENT = 'batches'
export const BATCH_SINGLE_URI_COMPONENT = 'batch'
