import PropTypes from 'prop-types'
import {useState, useEffect, useCallback, useMemo} from 'react'
import addBusinessDays from 'date-fns/addBusinessDays'
import parseISO from 'date-fns/parseISO'
import isSameDay from 'date-fns/isSameDay'

import {getState, useSelector} from '../../../store.js'
import DatePicker from '../../../common/components/DatePicker.js'
import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../data/labelInfos/index.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import className from '../../../common/className.js'
import {
  AMAZON_SFP,
  AUSTRALIA_POST,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  PITNEY,
  PITNEY_MERCHANT,
  SENDLE,
  VISIBLE_USPS,
} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export const SHIP_DATE_VALID_SHIPPER_TYPES = [
  AMAZON_SFP,
  AUSTRALIA_POST,
  DHL_ECOMMERCE,
  DHL,
  ENDICIA,
  FEDEX,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
]

export const PICKUP_DATE_VALID_SHIPPER_TYPES = [DHL, SENDLE]

export default function ForwardDate({
  label,
  labelProperty,
  nextBusinessDay,
  validShipperTypes,
  labelPropertyCheckedSelector,
}) {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const id = `forward_date__${labelInfoID}__${labelProperty}`

  const dateStr = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID})[labelProperty],
  )
  const [minDate, setMinDate] = useState(
    nextBusinessDay ? addBusinessDays(new Date(), 1) : new Date(),
  )
  const date = useMemo(() => (dateStr ? parseISO(dateStr) : null), [dateStr])

  const {isLabelPropertyEnabled, ...rest} = useLabelProperty({
    labelProperty,
    validShipperTypes,
  })

  validShipperTypes = rest.validShipperTypes
  const hide = !validShipperTypes.includes(shipperType)

  const onDateChange = useCallback(
    (date) => {
      if (hide) {
        return
      }

      const origDateStr = labelConfigSelector(getState(), {labelInfoID})[
        labelProperty
      ]
      const newDateStr = date ? date.toISOString() : null

      if (
        origDateStr &&
        newDateStr &&
        isSameDay(parseISO(origDateStr), parseISO(newDateStr))
      ) {
        return
      }

      updateLabelConfig(labelInfoID, {
        [labelProperty]: newDateStr,
      })

      if (onChange) {
        onChange()
      }
    },
    [labelProperty, onChange, hide],
  )

  const setupDates = useCallback(() => {
    if (hide) {
      return
    }

    const minDate = nextBusinessDay
      ? addBusinessDays(new Date(), 1)
      : new Date()

    setMinDate(minDate)

    if (date && date < minDate) {
      onDateChange(minDate)
    }
  }, [onChange, hide])

  useEffect(() => {
    if (hide) {
      return
    }

    setupDates()

    const intervalID = setInterval(setupDates, 1000 * 60 * 10)

    return () => clearInterval(intervalID)
  }, [setupDates, hide])

  if (hide) {
    return null
  }

  return (
    <li
      className={className`list__item--shipping-options ${formatMetaClassName(
        labelProperty,
      )}`}
    >
      <div className="wrap--edit-preset-form-input wrap--edit-preset-form-input-md">
        <label className="label--bold label--forward-date" htmlFor={id}>
          {label}
        </label>
        <DatePicker
          id={id}
          minDate={minDate}
          onChange={onDateChange}
          selected={date}
          disabled={isLabelEnableMode && !isLabelPropertyEnabled}
        />
      </div>
      <EnableLabelProperty
        labelProperty={labelProperty}
        checkedSelector={labelPropertyCheckedSelector}
      />
    </li>
  )
}

ForwardDate.propTypes = {
  labelProperty: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  nextBusinessDay: PropTypes.bool,
  validShipperTypes: PropTypes.arrayOf(PropTypes.string),
  labelPropertyCheckedSelector: PropTypes.func,
}
