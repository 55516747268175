import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {getCommonAppMessage} from '../../redux/actions/data/commonAppMessage.js'

const POLL_INTERVAL = 60 * 1000

export class CommonAppMessageListener extends Component {
  static propTypes = {
    getCommonAppMessage: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.getCommonAppMessage()

    setTimeout(() => this.check(), POLL_INTERVAL)
  }

  componentWillUnmount() {
    this.stopChecking = true
  }

  stopChecking = false

  check() {
    if (this.stopChecking) {
      return
    }

    this.props.getCommonAppMessage()

    setTimeout(() => this.check(), POLL_INTERVAL)
  }

  render() {
    return null
  }
}

const mapDispatchToProp = {
  getCommonAppMessage,
}

export default connect(null, mapDispatchToProp)(CommonAppMessageListener)
