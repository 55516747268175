import {createSelector, lruMemoize} from 'reselect'
import take from 'lodash/take.js'
import sortBy from 'lodash/sortBy.js'
import head from 'lodash/head.js'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'
import {VISIBLE_ACTIVITY_COUNT} from '../../../common/constants/Tasks.js'

export const sortedTasksSelector = createSelector(
  (state) => state.data.tasks,
  (tasks) => sortBy(tasks, ['created']).reverse(),
)

export const activityTasksSelector = createSelector(
  sortedTasksSelector,
  (tasks) =>
    take(
      sortBy(
        tasks.filter((task) => !task._linkdata),
        'created',
      ).reverse(),
      VISIBLE_ACTIVITY_COUNT,
    ),
)

export const minExportDateSelector = createSelector(
  (state) => state.ui.tasks.exportsClearedTime,
  (exportsClearedTime) => {
    const clearedTime = moment(new Date(exportsClearedTime))
    const thresholdTime = moment().subtract(7, 'days')
    return moment.max(clearedTime, thresholdTime).toDate()
  },
)

export const exportTasksSelector = createSelector(
  minExportDateSelector,
  sortedTasksSelector,
  (minExportDate, tasks) =>
    take(
      sortBy(
        tasks.filter(
          (task) =>
            task._linkdata &&
            new Date(task.created).getTime() > minExportDate.getTime(),
        ),
        'created',
      ).reverse(),
      VISIBLE_ACTIVITY_COUNT,
    ),
)

export const activeCartTaskSelector = createSelector(
  sortedTasksSelector,
  (tasks) => head(tasks.filter((task) => task.table_key === 'cart_task')),
)

export const activeImportProductTasksSelector = createSelector(
  sortedTasksSelector,
  (tasks) =>
    tasks.filter(
      (task) => task.type === 'import_products_from_cart' && !task.complete,
    ),
)

export const mostRecentlyUpdatedTaskSelector = createSelector(
  (state) => state.data.tasks,
  (tasks) => head(sortBy(tasks, ['updated']).reverse()),
)

export const getTaskStatus = lruMemoize((task) => {
  if (task.status) {
    return sanitizeHtml(task.status, {allowedTags: 'a'})
  }
  return 'Waiting for activity to begin...'
})
