import PropTypes from 'prop-types'
import {useEffect, useMemo} from 'react'
import kebabCase from 'lodash/kebabCase.js'

import {
  formSelector,
  getState,
  updateForm,
  useAutoForm,
} from '../../../../store.js'
import {filterOptions} from '../../../../common/tokenizeOptions.js'
import {
  SelectOptionsShape,
  SelectOptionTokens,
} from '../../../../common/PropTypes.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'

function filter(formName, options, optionTokens) {
  const form = formSelector(getState(), {formName})

  if (!form) {
    return
  }

  if (!form.text) {
    updateForm(formName, {
      results: [...options],
      hasFoundNothing: false,
    })

    return
  }

  const results = filterOptions(form.text, optionTokens)

  updateForm(formName, {
    results: results,
    hasFoundNothing: results.length === 0,
  })
}

function getFormName(id) {
  return `FILTER_PANEL__${id.toUpperCase()}`
}

export function clearFilterPanelSearch(id) {
  updateForm(getFormName(id), {text: ''})
}

export default function FilterPanel({
  id,
  label,
  options,
  optionTokens,
  selected,
  onSelect,
  searchPlaceholder,
  Notice,
}) {
  const formName = getFormName(id)

  const form = useAutoForm(formName, {
    text: '',
    results: [],
    hasFoundNothing: false,
    showAll: false,
  })

  useEffect(() => {
    filter(formName, options, optionTokens)
  }, [form.text, options])

  const [optionsToShow, hiddenSelected] = useMemo(
    () =>
      form.results.reduce(
        (prev, option, index) => {
          if (form.showAll || index < 6) {
            prev[0].push(option)
          } else if (selected.includes(option.value)) {
            prev[1].push(option.value)
          }
          return prev
        },
        [[], []],
      ),
    [form.results, form.showAll, selected],
  )

  return (
    <div className={`wrap--modal-filters wrap--modal-filters-${kebabCase(id)}`}>
      <div className="wrap__modal-subheader margin-bottom-25 flex--justify">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0 flex--justify-col">
          <span>
            <strong>{label}</strong>
            {selected.length > 0 && (
              <PluralBlock array={selected}>
                <span className="lowercase text--blue margin-left-5">
                  (<Count /> <Plural word="filter" p="s" /> selected)
                </span>
              </PluralBlock>
            )}
          </span>
        </h4>
        {options.length > 6 && (
          <div className="wrap--filter-search w-35">
            <label
              className="label--input-prefix label--search"
              htmlFor={`filters_${id}_search`}
            >
              <input
                className="input--search input--lighter inline-block v-align-middle"
                id={`filters_${id}_search`}
                type="text"
                placeholder={searchPlaceholder || `Search for a filter...`}
                value={form.text}
                onChange={(event) =>
                  updateForm(formName, {text: event.target.value})
                }
              />
            </label>
          </div>
        )}
      </div>
      {Notice && (
        <div className="alert alert--standard margin-bottom-30 inline-block">
          <p className="fs-00 lh-md margin-bottom-0">
            <em>
              <Notice />
            </em>
          </p>
        </div>
      )}
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {optionsToShow.map((option) => (
          <li
            key={option.value}
            className="list__item list__item--modal-filters"
          >
            <Checkbox
              mode="fancy"
              id={`${id}_${option.value}`}
              checked={selected.includes(option.value)}
              onChange={() => onSelect(option)}
              label={option.display}
            />
          </li>
        ))}
        {form.text !== '' && form.hasFoundNothing && (
          <li className="list__item list__item--modal-filters list__item--modal-filters-no-results fs-01">
            <strong>No filters match the current search terms.</strong>
          </li>
        )}
      </ul>
      {form.results.length > 6 && (
        <>
          <div className="wrap--filter-expand-btn">
            <button
              className="btn btn--link no-underline"
              type="button"
              onClick={() => updateForm(formName, {showAll: !form.showAll})}
            >
              <span
                className="i--expand fs-01 lh-0 v-align-middle margin-right-3"
                aria-hidden="true"
              />
              <span className="fs-n0">
                {form.showAll ? 'Show Less' : 'Show All'}
              </span>
              {hiddenSelected.length > 0 && (
                <PluralBlock array={hiddenSelected}>
                  <span className="fs-n0 text--blue margin-left-5">
                    (<Count /> hidden <Plural p="filters are" s="filter is" />{' '}
                    selected)
                  </span>
                </PluralBlock>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

FilterPanel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: SelectOptionsShape.isRequired,
  optionTokens: SelectOptionTokens.isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  Notice: PropTypes.func,
}
