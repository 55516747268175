import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {ensureArray} from '../../../common/ensure.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextArea from '../../../common/components/TextArea.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from '../mockAbodeFunctions.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  useSelector,
} from '../../../store.js'

const MODAL_FORM = 'EDIT_MOCK_PROFILES_FORM'

export function showEditMockProfilesModal() {
  setForm(MODAL_FORM, {
    profileIDsText: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function EditMockProfilesModal({form}) {
  const query = useSelector(querySelector)
  useEffect(() => {
    updateModalForm({
      profileIDsText: ensureArray(query.template).join('\n'),
    })
  }, [])

  return (
    <ConfirmModal
      title="Mock Profiles"
      onConfirm={() => {
        const profileIDs = form.profileIDsText
          .replace(/[, ]/g, '\n')
          .split('\n')
          .filter((profileID) => !!profileID)

        const orderNumbers = ensureArray(query.order)

        while (orderNumbers.length !== profileIDs.length) {
          if (profileIDs.length <= 1) {
            break
          }

          if (orderNumbers.length > profileIDs.length) {
            profileIDs.push(profileIDs[profileIDs.length - 1])
          } else {
            profileIDs.pop()
          }
        }

        mockAbodeUpdateResourceQuery({template: profileIDs})

        removeModalForm()
      }}
      onCancel={() => removeModalForm()}
      confirmText="Okay"
      cancelText="Cancel"
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            <strong>Input Mock Profile IDs</strong>
          </div>
        </li>
        <li>
          <TextArea
            label="Profile IDs"
            rows="10"
            id="profile_id"
            value={form.profileIDsText || ''}
            onChange={(value) => updateModalForm({profileIDsText: value})}
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditMockProfilesModal.propTypes = {
  form: PropTypes.shape({
    profileIDsText: PropTypes.string,
  }),
}

export default onlyIfForm(EditMockProfilesModal, modalFormSelector)
