import PropTypes from 'prop-types'

import {
  shipperTypesSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {
  AMAZON_SFP,
  SHIPPER_VENDORS,
} from '../../../common/constants/ShipperNames.js'
import className from '../../../common/className.js'
import {EMPTY_DEPENDANT_PROPERTIES_FUNC} from './useLabelProperty.js'
import {useSelector} from '../../../store.js'
import {includesAll, includesSome} from '../../../common/utils.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const NOTIFY_BILL_TO_LABEL_PROPERTY = 'notify_bill_to'
const NOTIFY_SHIP_TO_LABEL_PROPERTY = 'notify_ship_to'
const VALID_SHIPPER_TYPES = SHIPPER_VENDORS.filter((s) => s !== AMAZON_SFP)

export default function Notify({showBillTo = true}) {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const shipperTypes = useSelector((state) =>
    shipperTypesSelector(state, {labelInfoID}),
  )

  const supportedByAllShippers = includesAll(VALID_SHIPPER_TYPES, shipperTypes)
  const hide = !includesSome(VALID_SHIPPER_TYPES, shipperTypes)

  return (
    <li className={className`flex flex-wrap flex-wrap-margin-15 ${{hide}}`}>
      <ul className="list list--shipping-options flex flex-wrap flex-grow">
        <ConfigCheckbox
          label="Notify Bill To"
          onChange={(checked) => {
            updateLabelConfig(labelInfoID, {
              [NOTIFY_BILL_TO_LABEL_PROPERTY]: checked,
            })

            onChange()
          }}
          labelProperty={NOTIFY_BILL_TO_LABEL_PROPERTY}
          validShipperTypes={VALID_SHIPPER_TYPES}
          dependantPropertiesFunc={EMPTY_DEPENDANT_PROPERTIES_FUNC}
          hide={hide || !showBillTo}
        />
        <ConfigCheckbox
          label="Notify Ship To"
          onChange={(checked) => {
            updateLabelConfig(labelInfoID, {
              [NOTIFY_SHIP_TO_LABEL_PROPERTY]: checked,
            })

            onChange()
          }}
          labelProperty={NOTIFY_SHIP_TO_LABEL_PROPERTY}
          validShipperTypes={VALID_SHIPPER_TYPES}
          dependantPropertiesFunc={EMPTY_DEPENDANT_PROPERTIES_FUNC}
          hide={hide}
        />
      </ul>
      {!supportedByAllShippers && (
        <small className="error">Not all carriers support notifications</small>
      )}
    </li>
  )
}

Notify.propTypes = {
  showBillTo: PropTypes.bool,
}
