import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  setFormValue,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  cartIntegrationBridgeSelector,
  cartSelector,
  getCartName,
  updateCartIntegrations,
} from '../../../data/carts.js'
import Checkbox from '../../../common/components/Checkbox.js'

export const MODAL_FORM = 'EDIT_CART_INTEGRATION_BRIDGE_MODAL'
export const CIBShape = PropTypes.shape({
  cart_id: PropTypes.number.isRequired,
  integration_id: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    export_tax_as_line_item: PropTypes.bool.isRequired,
  }).isRequired,
})

export async function showEditCartIntegrationBridgeModal({
  cartIDs = [],
  integrationIDs = [],
  isNew = false,
  isNotice = false,
} = {}) {
  const cib = cartIntegrationBridgeSelector(getState(), {
    cartIDs,
    integrationIDs,
  })

  setForm(MODAL_FORM, {
    cartIDs,
    integrationIDs,
    isNotice,
    isNew,
    cib,
    isCartFocused: cartIDs.length === 1,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export async function saveCartIntegrations() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {cib} = modalFormSelector(getState())

    await updateCartIntegrations(cib)

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function Row({cib, index}) {
  const cart = useSelector((state) =>
    cartSelector(state, {cartID: cib.cart_id}),
  )

  const id = `${MODAL_FORM}_cart_${cib.cart_id}_integration_${cib.integration_id}`

  return (
    <tr>
      <td className="table__td table__td--sm v-align-middle">
        <strong className="fs-n0">{getCartName(cart)}</strong>
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        <label className="margin-bottom-0" htmlFor={id}>
          <Checkbox
            className="v-align-middle margin-bottom-0 margin-right-5"
            id={id}
            checked={cib.active}
            onChange={(checked) =>
              setFormValue(MODAL_FORM, ['cib', index, 'active'], checked)
            }
          />
          <span className="fs-n0">Enabled</span>
        </label>
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        <label className="margin-bottom-0">
          <Checkbox
            className=" v-align-middle margin-bottom-0 margin-right-5"
            checked={cib.active && !!cib.config.export_tax_as_line_item}
            disabled={!cib.active}
            onChange={(checked) =>
              setFormValue(
                MODAL_FORM,
                ['cib', index, 'config', 'export_tax_as_line_item'],
                checked,
              )
            }
          />
          <span className="fs-n0">Enabled</span>
        </label>
      </td>
    </tr>
  )
}

Row.propTypes = {
  cib: CIBShape.isRequired,
  index: PropTypes.number.isRequired,
}

function EditCartIntegrationBridgeModal({form}) {
  return (
    <ConfirmModal
      title="Which Sales Channels Export to QuickBooks?"
      modalSize="md"
      confirmText="Save"
      onConfirm={() => saveCartIntegrations()}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div>
        <table className="table table--multi-location margin-top-5">
          <thead>
            <tr>
              <th className="table__th table__th--sm fs-n0 text--md-grey w-30">
                Sales Channel
              </th>
              <th className="table__th table__th--sm fs-n0 text--md-grey align-center w-40">
                Export Order Data to QuickBooks
              </th>
              <th className="table__th table__th--sm fs-n0 text--md-grey align-center w-30">
                <span className="margin-right-3">Export Tax as Line Item</span>
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody--multi-location">
            {form.cib.map((cib, index) => (
              <Row key={index} index={index} cib={cib} />
            ))}
          </tbody>
        </table>
      </div>
    </ConfirmModal>
  )
}

EditCartIntegrationBridgeModal.propTypes = {
  form: PropTypes.shape({
    cartIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    integrationIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    cib: PropTypes.arrayOf(CIBShape).isRequired,
    isNew: PropTypes.bool.isRequired,
    isNotice: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(EditCartIntegrationBridgeModal, modalFormSelector)
