export default function html(strings, ...args) {
  let out = ''
  for (const string of strings) {
    out += string

    if (args.length) {
      const arg = args.shift()

      if (Array.isArray(arg)) {
        out += arg.join('')
      } else {
        out += arg
      }
    }
  }

  return out
}
