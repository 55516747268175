import PropTypes from 'prop-types'
import {useState} from 'react'

import BarcodeScannerListener from '../../common/components/BarcodeScannerListener.js'
import packingListBarcodeFilePath from '../../../images/packing-list-barcode.svg'

import {
  PACK_SHIP_ORDER_NOT_FOUND,
  PACK_SHIP_GENERAL_ERROR,
  packShipOrderScanned,
} from '../../redux/actions/ui/packShipPage/index.js'
import {packShipErrorSelector} from '../../redux/selectors/ui/packShipPage/index.js'
import FocusAlert from '../../common/components/FocusAlert.js'
import {dispatch, useSelector} from '../../store.js'

export default function ScanToBegin({className}) {
  const [scannedValue, setScannedValue] = useState('')
  const scanError = useSelector(packShipErrorSelector)

  return (
    <div className={className}>
      <FocusAlert>
        <BarcodeScannerListener
          onScan={(value) => {
            setScannedValue(value)

            dispatch(packShipOrderScanned(value))
          }}
          capturePaste
        />
        <div className="row flex padding-top-25 padding-bottom-25">
          <div className="medium-5 columns">
            <img
              alt="Illustration of barcode scanner scanning a packing list"
              src={packingListBarcodeFilePath}
            />
          </div>
          <div className="medium-7 columns flex--justify-col">
            {scannedValue && !scanError && (
              <div className="loading">
                <span className="list-processing animate-spin v-align-middle" />
                <span className="inline-block v-align-middle fs-02 text--lt-grey margin-left-10">
                  <strong className="word-break">{scannedValue} </strong>
                  is loading...
                </span>
              </div>
            )}
            {scannedValue && scanError && (
              <div className="wrap--packing-mode-scan-alert barcode-not-found">
                <div className="alert alert--error alert--lg full-border align-center margin-bottom-0">
                  <div
                    className="i-exclamation-triangle fs-04 op-30 lh-sm margin-bottom-5"
                    aria-hidden="true"
                  />
                  <p className="fs-01 error">
                    {scanError.type === PACK_SHIP_ORDER_NOT_FOUND && (
                      <p>
                        No order was found for “
                        <strong className="word-break">{scannedValue}</strong>
                        ”. Try again or scan different barcode.
                      </p>
                    )}
                    {scanError.type === PACK_SHIP_GENERAL_ERROR && (
                      <p>
                        Something went wrong while scanning.
                        {scanError.message}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            )}
            {!scannedValue && (
              <p className="fs-02 lh-md text--lt-grey">
                <span>Scan barcode on packing list to begin...</span>
              </p>
            )}
          </div>
        </div>
      </FocusAlert>
    </div>
  )
}

ScanToBegin.propTypes = {
  className: PropTypes.string,
}
