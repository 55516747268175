import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../common/constants/index.js'
import {
  updateForm,
  cancel,
  setupForms,
  setNewShipperVendor,
  setupForm,
} from '../../../redux/actions/ui/settings/shippers/index.js'
import {
  shippersHaveLoadedSelector,
  shippersSortedByIDSelector,
} from '../../../data/shippers.js'
import {
  warehousesHaveLoadedSelector,
  warehousesSelector,
} from '../../../data/warehouses.js'
import {
  newShipperOptionsSelector,
  sortedFormsSelector,
  newFormSelector,
} from '../../../redux/selectors/ui/settings/shippers/index.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {
  ShipperShape,
  ShipperFormShape,
  SelectOptionsShape,
} from '../../../common/PropTypes.js'
import SettingsPanel from '../SettingsPanel.js'
import EditForm from './EditForm.js'
import NewForm from './NewForm.js'
import {isNewPlansSelector} from '../../../data/company.js'

export function Shippers({
  forms,
  newForm,
  newShipperOptions,
  editID,
  hasLoaded,
  hasPermission,
  shippers,
  warehouses,
  isNewPlans,
  ...props
}) {
  const hasCalledSetupFormsRef = useRef(false)
  hasCalledSetupFormsRef.current = false

  useEffect(() => {
    if (!hasLoaded || !editID || editID === NEW_ID) {
      return
    }

    props.setupForms()

    hasCalledSetupFormsRef.current = true
  }, [hasLoaded, editID])

  useEffect(() => {
    if (!hasLoaded || hasCalledSetupFormsRef.current) {
      return
    }

    props.setupForms()
  }, [hasLoaded, shippers, warehouses])

  return (
    <SettingsPanel
      header="Shipper Settings"
      subHeader={
        isNewPlans ? (
          <div>
            Automatically access unlimited shipping labels on UPS, USPS, and
            Sendle. To ship with unlimited carrier accounts across other
            carriers, additional fees may apply.
          </div>
        ) : null
      }
      hasPermission={hasPermission}
    >
      {hasLoaded && (
        <div className="row">
          <div className="medium-12 columns">
            <ul className="settings-list">
              {forms.map((form) => {
                if (form.id === NEW_ID) {
                  return null
                }

                return (
                  <EditForm
                    key={form.id}
                    form={form}
                    editID={editID}
                    updateForm={props.updateForm}
                    cancel={props.cancel}
                    setupForm={props.setupForm}
                  />
                )
              })}
              <NewForm
                form={newForm}
                newShipperOptions={newShipperOptions}
                editID={editID}
                updateForm={props.updateForm}
                cancel={props.cancel}
                setNewShipperVendor={props.setNewShipperVendor}
                setupForm={props.setupForm}
              />
            </ul>
          </div>
        </div>
      )}
    </SettingsPanel>
  )
}

Shippers.propTypes = {
  editID: PropTypes.string.isRequired,
  shippers: PropTypes.arrayOf(ShipperShape).isRequired,
  warehouses: PropTypes.object,
  forms: PropTypes.arrayOf(ShipperFormShape).isRequired,
  newForm: ShipperFormShape,
  newShipperOptions: SelectOptionsShape.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  isNewPlans: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  setupForms: PropTypes.func.isRequired,
  setNewShipperVendor: PropTypes.func.isRequired,
  setupForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    shippers: shippersSortedByIDSelector(state),
    warehouses: warehousesSelector(state),
    forms: sortedFormsSelector(state),
    newForm: newFormSelector(state),
    newShipperOptions: newShipperOptionsSelector(state),
    editID: state.ui.settings.shippers.editID,
    hasLoaded:
      warehousesHaveLoadedSelector(state) && shippersHaveLoadedSelector(state),
    hasPermission: settingsPermissionsSelector(state).shipper,
    isNewPlans: isNewPlansSelector(state),
  }
}

const mapDispatchToProps = {
  updateForm,
  cancel,
  setupForms,
  setNewShipperVendor,
  setupForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(Shippers)
