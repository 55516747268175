import PropTypes from 'prop-types'

import {getState, setForm, updateForm, removeForm} from '../../store.js'
import {
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  BATCH_SORT_OPTIONS,
  BATCH_PLURAL_URI_COMPONENT,
} from '../../common/constants/Batches.js'
import apiverson from '../../common/apiverson.js'
import handleListSelection from '../../common/handleListSelection.js'
import {BATCH_LIST_PAGE} from '../../common/constants/Pages.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {setBatches} from '../../data/batches.js'
import {navigate} from '../../common/location.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  BATCH_LIST_FORM,
  batchListFormSelector,
  queryParamsSelector,
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  expandedReferenceIDsSelector,
  getBatchDetailURL,
  defaultHashParamsSelector,
} from './batchListSelectors.js'
import {setBatchSummaries} from '../../data/batchSummaries.js'

export const BatchListFormShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReferenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedReferenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandMode: PropTypes.string.isRequired,
})

export function setupBatchListForm() {
  setForm(BATCH_LIST_FORM, {
    isLoading: false,
    count: 0,
    referenceIDList: [],
    selectedReferenceIDs: [],
    expandedReferenceIDs: [],
    expandMode: DEFAULT_EXPAND_MODE,
  })
}

export function updateBatchListForm(updates, meta) {
  updateForm(BATCH_LIST_FORM, updates, meta)
}

export function removeBatchListForm() {
  removeForm(BATCH_LIST_FORM)
}

export function navigateToBatchListPage(query) {
  const defaultQuery = defaultHashParamsSelector(getState())

  return navigate([BATCH_PLURAL_URI_COMPONENT], {...defaultQuery, ...query})
}

export function navigateBatchList(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (pathComponents[0] !== BATCH_PLURAL_URI_COMPONENT) {
    return
  } else {
    query = {...query, ...updates}
  }

  return navigate(pathComponents, query)
}

export function navigateToBatchDetailPage(referenceID, params = {}) {
  return navigate(...getBatchDetailURL(referenceID, params))
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_PER_PAGE
  }

  if (limit === DEFAULT_PER_PAGE) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updateBatchListForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigateBatchList(updates)
}

export function updateSort(sort) {
  if (!BATCH_SORT_OPTIONS.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  if (sort === DEFAULT_SORT) {
    sort = undefined
  }

  return {
    sort,
    ...resetOffset(),
  }
}

export function setSort(sort) {
  const updates = updateSort(sort)

  updateBatchListForm(
    {
      sticky__sort: updates.sort,
    },
    {stickyProps: ['sticky__sort']},
  )

  return navigateBatchList(updates)
}

export function updateActive(active) {
  return {
    active: active ? true : active === false ? false : undefined,
    ...resetOffset(),
  }
}

export function setActive(active) {
  return navigateBatchList(updateActive(active))
}

export function updateSearchText(searchText) {
  searchText = (searchText || '').trim() || undefined

  return {
    searchText,
    ...resetOffset(),
  }
}

export function setSearchText(searchText) {
  return navigateBatchList(updateSearchText(searchText))
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigateBatchList(updateOffset(offset))
}

export function setExpandedReferenceIDs(expandedReferenceIDs) {
  updateBatchListForm({
    expandedReferenceIDs,
  })
}

export function setExpandMode(expandMode) {
  updateBatchListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

export function setSelectedReferenceIDs(selectedReferenceIDs) {
  updateBatchListForm({
    selectedReferenceIDs,
  })
}

export async function refreshBatchList() {
  try {
    const currentPage = currentPageSelector(getState())
    if (currentPage !== BATCH_LIST_PAGE) {
      return
    }

    updateBatchListForm({isLoading: true})

    const params = queryParamsSelector(getState())
    const [{json}] = await Promise.all([
      apiverson.get('/batch/', params),
      refreshBatchSummaries(),
    ])

    setBatches(json.batch)

    updateQueryResults(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error querying Batches.',
        details: err.message || err.error_message,
      },
      err,
    )
  }

  updateBatchListForm({isLoading: false})
}

export async function refreshBatchSummaries() {
  const currentPage = currentPageSelector(getState())
  if (currentPage !== BATCH_LIST_PAGE) {
    return
  }

  const params = queryParamsSelector(getState())

  const {json: batchSummary} = await apiverson.get('/batch/summary', params)

  setBatchSummaries(batchSummary)
}

export function updateQueryResults({count, batch}) {
  const {selectedReferenceIDs, expandedReferenceIDs} =
    batchListFormSelector(getState())
  const referenceIDList = batch.map((b) => b.reference_id)

  updateBatchListForm({
    referenceIDList,
    count,
    selectedReferenceIDs: selectedReferenceIDs.filter((referenceID) =>
      referenceIDList.includes(referenceID),
    ),
    expandedReferenceIDs: expandedReferenceIDs.filter((referenceID) =>
      referenceIDList.includes(referenceID),
    ),
  })
}

export async function batchListSelectReferenceID(
  referenceID,
  isSelected,
  isShiftKey,
) {
  const selectedReferenceIDs = selectedReferenceIDsSelector(getState())
  const referenceIDList = referenceIDListSelector(getState())

  setSelectedReferenceIDs(
    handleListSelection({
      value: referenceID,
      isSelected,
      isShiftKey,
      selected: selectedReferenceIDs,
      list: referenceIDList,
    }),
  )
}

export async function deselectBatches(referenceIDs) {
  const selectedReferenceIDs = selectedReferenceIDsSelector(getState())

  setSelectedReferenceIDs(
    selectedReferenceIDs.filter((id) => !referenceIDs.includes(id)),
  )
}

export async function batchListToggleExpanded(referenceID) {
  const expandedReferenceIDs = expandedReferenceIDsSelector(getState())

  const referenceIDs = expandedReferenceIDs.filter((p) => p !== referenceID)

  if (!expandedReferenceIDs.includes(referenceID)) {
    referenceIDs.push(referenceID)
  }

  setExpandedReferenceIDs(referenceIDs)
}
