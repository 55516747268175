import {useRef, useState, useCallback, useEffect} from 'react'

// a toggle timer turns "value" `true` for a period of time "ms" after "startTimer()" is called
// otherwise "value" is `false`
export default function useToggleTimer(ms = 1000) {
  const timeoutRef = useRef(null)

  const [value, setValue] = useState(false)

  const startTimer = useCallback(() => {
    setTimeout(() => {
      setValue(false)

      setTimeout(() => {
        setValue(true)
      }, 0)

      clearTimeout(timeoutRef.current)

      timeoutRef.current = setTimeout(() => {
        setValue(false)
      }, ms)
    }, 0)
  }, [])

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  return [value, startTimer]
}
