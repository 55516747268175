import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  setForm,
  updateForm,
  removeForm,
  useSelector,
  onlyIfForm,
  formsSelector,
} from '../../../store.js'
import {BarcodeLayouts} from '../../../common/constants/BarcodeLabel.js'
import {formatAbodeURL} from '../../../common/abode.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Select from '../../../common/components/Form/Select.js'
import {presetOptionsSelector} from '../../../data/presets.js'
import MultiSelect from '../../../common/components/Form/MultiSelect.js'
import {HK_APPLY_PRESET_PREFIX} from '../../../common/constants/HotKeys.js'

export const MODAL_FORM = 'CREATE_PRESET_BARCODE_PDF_MODAL'

export function showCreatePresetBarcodePDFModal() {
  setForm(MODAL_FORM, {
    presetIDs: [],
    layout: BarcodeLayouts[0].value,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const barcodePreviewURLSelector = createSelector(
  modalFormSelector,
  presetOptionsSelector,
  ({layout, presetIDs}, presetOptions) => {
    if (presetIDs.length === 0) {
      return null
    }

    const params = {
      render: JSON.stringify([
        'Barcode',
        {
          layout,
          copyCount: 1,
          showValue: false,
          values: presetIDs.map((presetID) => [
            HK_APPLY_PRESET_PREFIX + btoa(JSON.stringify([presetID])),
            presetOptions.find(({value}) => value === presetID).display,
          ]),
        },
      ]),
    }

    return formatAbodeURL('/render', params)
  },
)

function CreatePresetBarcodePDFModal({form}) {
  const barcodePreviewURL = useSelector(barcodePreviewURLSelector)
  const presetOptions = useSelector(presetOptionsSelector)

  return (
    <ConfirmModal
      title="Create Preset Barcode PDF"
      modalSize="sm"
      onConfirm={() => {
        window.open(barcodePreviewURL, '_blank')

        closeModal()
      }}
      onCancel={() => closeModal()}
      confirmText="Create"
      cancelText="Cancel"
      isDisabled={!barcodePreviewURL}
    >
      <dl className="list">
        <dd className="list__item margin-bottom-15">
          <Select
            label="Barcode Sizes/Layout Options"
            id="layout"
            value={form.layout}
            onChange={(value) =>
              updateModalForm({layout: value}, {stickyProps: ['layout']})
            }
          >
            {BarcodeLayouts.map((layout) => (
              <option key={layout.value} value={layout.value}>
                {layout.display}
              </option>
            ))}
          </Select>
        </dd>
        <dd className="list__item wrap--no-inner-left-margin margin-bottom-15">
          {presetOptions.length > 0 && (
            <MultiSelect
              scope="create_preset_pdf"
              options={presetOptions}
              selected={form.presetIDs}
              onChange={(presetIDs) => updateModalForm({presetIDs})}
            />
          )}
        </dd>
      </dl>
    </ConfirmModal>
  )
}

CreatePresetBarcodePDFModal.propTypes = {
  form: PropTypes.shape({
    presetIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    layout: PropTypes.string.isRequired,
  }).isRequired,
}

export default onlyIfForm(CreatePresetBarcodePDFModal, modalFormSelector)
