import {useEffect} from 'react'

import Loading from '../../common/components/List/Loading.js'
import {refreshPurchaseOrderList} from './purchaseOrderListActions.js'
import {
  isLoadingSelector,
  poListQuerySelector,
} from './purchaseOrderListSelectors.js'
import POListFooter from './POListFooter.js'

import Table from './Table.js'
import {useSelector} from '../../store.js'

export default function List() {
  const query = useSelector(poListQuerySelector)
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    refreshPurchaseOrderList()
  }, [query])

  return (
    <div className="wrap--row margin-top-10">
      {isLoading ? (
        <div className="medium-12 columns list--empty-content margin-top-40 margin-bottom-40">
          <Loading />
        </div>
      ) : (
        <>
          <div className="medium-12 columns">
            <Table />
          </div>
          <div className="wrap--row wrap--product-footer">
            <div className="medium-12 columns">
              <POListFooter />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
