import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_API_KEYS, UPDATE_API_KEY} from '../../actions/data/apiKeys.js'

export default function reducer(
  state = INITIAL_STATE.data.apiKeys,
  action = {},
) {
  if (action.type === SET_API_KEYS) {
    return action.payload
  }

  if (action.type === UPDATE_API_KEY) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    }
  }

  return state
}
