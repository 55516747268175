import PropTypes from 'prop-types'
import {Component, useEffect} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../common/constants/index.js'
import {hasUseReturnOrdersFeatureSelector} from '../../../data/company.js'
import {
  CurrentPackingListShape,
  ErrorsShape,
} from '../../../common/PropTypes.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import Row from '../../../common/components/Form/Row.js'
import TextInput from '../../../common/components/Form/TextInput.js'
import TextArea from '../../../common/components/Form/TextArea.js'
import {
  SETTINGS_PROFILES_URL,
  PROFILES_GENERAL,
  PROFILES_PACKING_LIST,
  PROFILES_RETURN_ORDER,
  PROFILES_SHIP_FROM_ALIAS,
} from '../../../redux/actions/ui/settings/packingLists/index.js'
import LogoUpload from './LogoUpload.js'

import analytics from '../../../common/analytics/index.js'

function RMAFeaturePage() {
  useEffect(() => {
    analytics.trackEvent('rma_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-20 align-center">
      <div
        className="i-lock-closed fs-03 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-01 lh-md  margin-bottom-15">
        <strong>Returns Management is not enabled.</strong>
      </p>
      <p className="fs-00 margin-bottom-20">
        To access this feature,{' '}
        <a
          className="btn--link mid border-underline meta-feature-lock-settings-return-email"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          reach out to us
        </a>
        .
      </p>
      <div className="margin-bottom-10">
        <a
          className="btn btn--primary btn--primary-alt btn--sm meta--feature-lock-settings-return-email"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Talk to a Product Expert
        </a>
      </div>
    </div>
  )
}

export class Form extends Component {
  static propTypes = {
    currentPackingList: CurrentPackingListShape.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    logoURL: PropTypes.string,
    isNew: PropTypes.bool.isRequired,
    selectedProfileTab: PropTypes.string.isRequired,
    errors: ErrorsShape.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    updateCurrentPackingList: PropTypes.func.isRequired,
    savePackingList: PropTypes.func.isRequired,
    handleLogoFile: PropTypes.func.isRequired,
    hasUseReturnOrdersFeature: PropTypes.bool.isRequired,
  }

  setName = (name) => {
    this.props.updateCurrentPackingList({name})
  }

  setCustomText = (customText) => {
    this.props.updateCurrentPackingList({custom_text: customText})
  }

  setEmail = (email) => {
    this.props.updateCurrentPackingList({email})
  }

  setPhone = (phone) => {
    this.props.updateCurrentPackingList({phone})
  }

  setWebsite = (website) => {
    this.props.updateCurrentPackingList({website})
  }

  setFooter = (footer) => {
    this.props.updateCurrentPackingList({footer})
  }

  setIsDefault = (isDefault) => {
    this.props.updateCurrentPackingList({is_default: isDefault})
  }

  render() {
    const {
      currentPackingList: {
        id,
        name,
        custom_text: customText,
        email,
        phone,
        website,
        footer,
        is_default: isDefault,
        original_is_default: originalIsDefault,
        return_email_content: returnInstructions,
        return_email_subject: returnSubjectLine,
        ship_from_alias_name,
        customer_notes_label,
      },
      isSaving,
      isUploading,
      logoURL,
      isNew,
      selectedProfileTab,
      errors,
      hasErrors,
      hasUseReturnOrdersFeature,
      ...props
    } = this.props

    return (
      <>
        <div className="wrap--row margin-top-10 margin-bottom-25">
          <div className="medium-12 columns">
            <div className="wrap--sub-nav">
              <nav className="list--sub-nav">
                <div className="list__item--sub-nav">
                  <a
                    className={`sub-nav__link margin-left-0 ${
                      selectedProfileTab === PROFILES_GENERAL ? 'active' : ''
                    }`}
                    href={`#/settings/profiles/${PROFILES_GENERAL}/${
                      isNew ? NEW_ID : id
                    }`}
                  >
                    General Info
                  </a>
                </div>
                <div className="list__item--sub-nav">
                  <a
                    className={`sub-nav__link ${
                      selectedProfileTab === PROFILES_PACKING_LIST
                        ? 'active'
                        : ''
                    }`}
                    href={`#/settings/profiles/${PROFILES_PACKING_LIST}/${
                      isNew ? NEW_ID : id
                    }`}
                  >
                    Packing List Content
                  </a>
                </div>
                <div className="list__item--sub-nav">
                  <a
                    className={`sub-nav__link ${
                      selectedProfileTab === PROFILES_RETURN_ORDER
                        ? 'active'
                        : ''
                    }`}
                    href={`#/settings/profiles/${PROFILES_RETURN_ORDER}/${
                      isNew ? NEW_ID : id
                    }`}
                  >
                    Return Email Content
                  </a>
                </div>
                <div className="list__item--sub-nav">
                  <a
                    className={`sub-nav__link ${
                      selectedProfileTab === PROFILES_SHIP_FROM_ALIAS
                        ? 'active'
                        : ''
                    }`}
                    href={`#/settings/profiles/${PROFILES_SHIP_FROM_ALIAS}/${
                      isNew ? NEW_ID : id
                    }`}
                  >
                    Ship From Alias
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-6 columns end">
            <form>
              <fieldset>
                <ul className="form-list margin-bottom-20">
                  {selectedProfileTab === PROFILES_GENERAL && (
                    <>
                      <li className="form-list-item fs-00 margin-bottom-20">
                        This information will appear on packing lists, shipping
                        labels and emails that this profile is assigned to.
                      </li>
                      <Row className="bigger margin-bottom-10">
                        <TextInput
                          name="email"
                          label="Profile Name"
                          className="medium-12"
                          value={name}
                          onChange={this.setName}
                          errorMessage={errors.name}
                          required
                        />
                      </Row>
                      <Row className="bigger margin-bottom-10">
                        <TextInput
                          name="email"
                          label="Email"
                          className="medium-12"
                          value={email}
                          onChange={this.setEmail}
                          errorMessage={errors.email}
                        />
                      </Row>
                      <Row className="bigger margin-bottom-10">
                        <TextInput
                          name="phone"
                          label="Phone Number"
                          className="medium-12"
                          value={phone}
                          onChange={this.setPhone}
                          errorMessage={errors.phone}
                        />
                      </Row>
                      <Row className="bigger margin-bottom-10">
                        <TextInput
                          name="website"
                          label="Website URL"
                          className="medium-12"
                          value={website}
                          onChange={this.setWebsite}
                          errorMessage={errors.website}
                        />
                      </Row>
                      <LogoUpload
                        link={logoURL}
                        isUploading={isUploading}
                        error={errors._linklogo}
                        handleLogoFile={this.props.handleLogoFile}
                      />
                      <Row className="bigger margin-bottom-20">
                        <Checkbox
                          name="is_default"
                          label="Make this the default profile"
                          className="medium-12 columns"
                          checked={isDefault}
                          onChange={this.setIsDefault}
                          errorMessage={errors.isDefault}
                          disabled={originalIsDefault}
                        />
                      </Row>
                    </>
                  )}
                  {selectedProfileTab === PROFILES_PACKING_LIST && (
                    <>
                      <li className="form-list-item fs-00 margin-bottom-20">
                        Along with the general profile and related order
                        information, this content will appear on all packing
                        lists that this profile is assigned to.
                      </li>
                      <Row className="bigger margin-bottom-20">
                        <TextInput
                          name="customer_notes_label"
                          label="Label for Customer Notes Section"
                          className="medium-12"
                          placeholder="Customer Notes"
                          value={customer_notes_label}
                          onChange={(value) =>
                            props.updateCurrentPackingList({
                              customer_notes_label: value,
                            })
                          }
                          errorMessage={errors.customer_notes_label}
                        />
                      </Row>
                      <Row className="bigger margin-bottom-20">
                        <TextArea
                          name="custom_text"
                          label="Custom Text"
                          className="medium-12"
                          placeholder="101 River St. Marshalltown, IA 50158"
                          value={customText}
                          onChange={this.setCustomText}
                          errorMessage={errors.custom_text}
                        />
                      </Row>
                      <Row className="bigger margin-bottom-20">
                        <TextArea
                          name="footer"
                          label="Footer Message"
                          className="medium-12"
                          value={footer}
                          onChange={this.setFooter}
                          errorMessage={errors.footer}
                        />
                      </Row>
                    </>
                  )}
                  {selectedProfileTab === PROFILES_RETURN_ORDER &&
                    (hasUseReturnOrdersFeature ? (
                      <>
                        <li className="form-list-item fs-00 margin-bottom-20">
                          Along with the general profile and related order
                          information, this content will appear in all return
                          order emails that this profile is assigned to.
                        </li>
                        <Row className="bigger margin-bottom-10">
                          <TextInput
                            name="return_email_subject"
                            label="Subject Line"
                            className="medium-12"
                            value={returnSubjectLine}
                            onChange={(value) =>
                              props.updateCurrentPackingList({
                                return_email_subject: value,
                              })
                            }
                            errorMessage={errors.returnSubjectLine}
                          />
                        </Row>
                        <Row className="bigger margin-bottom-20">
                          <TextArea
                            name="return_email_content"
                            label="Return Email Content"
                            placeholder="This is typically used for instructions regarding a return"
                            className="medium-12"
                            value={returnInstructions}
                            onChange={(value) =>
                              props.updateCurrentPackingList({
                                return_email_content: value,
                              })
                            }
                            errorMessage={errors.returnInstructions}
                          />
                        </Row>
                      </>
                    ) : (
                      <RMAFeaturePage />
                    ))}
                  {selectedProfileTab === PROFILES_SHIP_FROM_ALIAS && (
                    <>
                      <li className="form-list-item fs-00 margin-bottom-20">
                        Text entered here will replace the “Warehouse Name“
                        above the Ship From address on shipping labels printed
                        with this profile. This is particularly useful if you
                        have multiple sales channels that use the same warehouse
                        for storing inventory.{' '}
                        <strong>
                          <a
                            href="https://support.ordoro.com/how-the-ship-from-alias-works/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Learn more
                          </a>
                        </strong>{' '}
                        <span className="op-50">→</span>
                      </li>
                      <Row className="bigger margin-bottom-10">
                        <TextInput
                          name="ship_from_alias_name"
                          label="Ship From Alias Name"
                          className="medium-12"
                          value={ship_from_alias_name}
                          onChange={(value) =>
                            props.updateCurrentPackingList({
                              ship_from_alias_name: value,
                            })
                          }
                          errorMessage={errors.ship_from_alias_name}
                        />
                      </Row>
                    </>
                  )}
                  <li className="list__item list__item--no-style">
                    <button
                      type="button"
                      className={classNames('btn btn--primary', {
                        'btn--disabled': hasErrors,
                        'btn--processing': isSaving,
                      })}
                      onClick={this.props.savePackingList}
                      disabled={hasErrors || isSaving}
                    >
                      {isNew ? 'Add' : 'Save'}
                    </button>
                    <a
                      className="btn btn--secondary"
                      href={`${SETTINGS_PROFILES_URL}/${selectedProfileTab}`}
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </fieldset>
            </form>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    hasUseReturnOrdersFeature: hasUseReturnOrdersFeatureSelector(state),
  }
}

export default connect(mapStateToProps)(Form)
