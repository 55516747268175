import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {ErrorsShape, SelectOptionsShape} from '../../../common/PropTypes.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import apiverson from '../../../common/apiverson.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import DatePicker from '../../../common/components/DatePicker.js'
import Select from '../../../common/components/Select.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshMOList} from '../moListActions.js'
import {setMO, moSelector} from '../../../data/mos.js'
import {nonFBAWarehouseOptionsSelector} from '../../../data/warehouses.js'

export const EDIT_MO_PARAM_MODAL = 'EDIT_MO_PARAM_MODAL'

export function showMOManufacturerNotesModal(referenceID) {
  const mo = moSelector(getState(), {referenceID})

  showEditMOParamModal({
    referenceID,
    value: mo.manufacturer_notes,
    display: 'Manufacturer Notes',
    apiProp: 'manufacturer_notes',
    inputType: 'textarea',
  })
}

export function showMOInternalNotesModal(referenceID) {
  const mo = moSelector(getState(), {referenceID})

  showEditMOParamModal({
    referenceID,
    value: mo.internal_notes,
    display: 'Internal Notes',
    apiProp: 'internal_notes',
    inputType: 'textarea',
  })
}

export function showMOWarehouseModal(referenceID) {
  const mo = moSelector(getState(), {referenceID})

  showEditMOParamModal({
    referenceID,
    value: mo.warehouse_id,
    display: 'Warehouse',
    apiProp: 'warehouse_id',
    inputType: 'select',
    typeCast: numberCast,
    optionsSelector: nonFBAWarehouseOptionsSelector,
  })
}

export function defaultCast(value) {
  return value
}

export function dateCast(value) {
  return value ? value.toISOString() : null
}

export function numberCast(value) {
  return Number(value)
}

export function showEditMOParamModal({
  referenceID,
  value,
  display,
  apiProp,
  isRequired,
  needsMOListRefresh,
  inputType,
  typeCast,
  SubComponent,
  optionsSelector,
}) {
  inputType = inputType || 'text'

  setForm(EDIT_MO_PARAM_MODAL, {
    referenceID,
    apiProp,
    value:
      inputType === 'date'
        ? value
          ? new Date(value)
          : null
        : isPresent(value)
          ? value
          : '',
    display,
    isRequired: !!isRequired,
    needsMOListRefresh: !!needsMOListRefresh,
    inputType,
    typeCast:
      typeCast ||
      (inputType === 'date'
        ? dateCast
        : inputType === 'currency'
          ? numberCast
          : defaultCast),
    isSaving: false,
    serverError: null,
    SubComponent,
    optionsSelector,
  })
}

export function updateModalForm(...args) {
  return updateForm(EDIT_MO_PARAM_MODAL, ...args)
}

export function closeModal() {
  return removeForm(EDIT_MO_PARAM_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[EDIT_MO_PARAM_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired, inputType}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
    }

    if (inputType === 'currency' && !isNumeric(value)) {
      errors.value = `${display} must be a number`
    }

    return errors
  },
)

export async function saveMOParam() {
  try {
    const {referenceID, value, display, apiProp, needsMOListRefresh, typeCast} =
      modalFormSelector(getState())

    const params = {
      [apiProp]: typeCast(value),
    }

    updateModalForm({isSaving: true, serverError: null})

    const {json} = await apiverson.put(
      `/manufacturing_order/${referenceID}`,
      params,
    )

    setMO(json)

    closeModal()

    showMessageToast(`Saved MFG order ${display.toLowerCase()}.`)

    if (needsMOListRefresh) {
      await refreshMOList()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditMOParamModal({form, errors, options}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title={`Edit ${form.display}`}
      modalSize="sm"
      onConfirm={() => saveMOParam()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        <div className="fs-01">
          <strong>{form.referenceID}</strong>
        </div>
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'date' ? (
        <DatePicker
          id="mo_param_modal"
          selected={form.value}
          onChange={(value) =>
            updateModalForm({
              value,
            })
          }
        />
      ) : form.inputType === 'currency' ? (
        <CurrencyInput
          className="biggie margin-bottom-0"
          width="sm"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'select' ? (
        <Select
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        >
          {options.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </Select>
      ) : null}
      {form.SubComponent && <form.SubComponent form={form} />}
    </ConfirmModal>
  )
}

EditMOParamModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    value: PropTypes.any,
    display: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf([
      'text',
      'textarea',
      'date',
      'currency',
      'select',
    ]).isRequired,
    typeCast: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    SubComponent: PropTypes.func,
    optionsSelector: PropTypes.func,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  options: SelectOptionsShape.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    errors: errorsSelector(state),
    options: form.optionsSelector ? form.optionsSelector(state) : [],
  }
}

export default formConnect(
  connect(mapStateToProps)(EditMOParamModal),
  modalFormSelector,
)
