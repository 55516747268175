import {
  labelConfigSelector,
  shipperVendorConfigPropertySelector,
} from '../../../data/labelInfos/index.js'
import {FEDEX} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {useSelector} from '../../../store.js'
import ConfigButton from './ConfigButton.js'
import {showETDPreshipmentDocsModal} from '../Modals/ETDPreshipmentDocsModal.js'
import {
  Count,
  IfPluralNotZero,
  IfSingle,
  IfZero,
  PluralBlock,
} from '../../../common/components/Plural.js'

const VALID_SHIPPER_TYPES = [FEDEX]

export function etdPreshipmentDocsPropertyFunc(shipperType) {
  return `${shipperType}__etd_preshipment_docs`
}

export default function ETDPreshipmentDocs() {
  const {labelInfoID, shipperType} = useLabelConfigContext()

  // temp_use_fedex_auth
  const [hasFedExNewCred] = useSelector((state) =>
    shipperVendorConfigPropertySelector(state, {
      labelInfoID,
      shipperType,
      property: 'child_key',
    }),
  )

  const labelProperty = etdPreshipmentDocsPropertyFunc(shipperType)
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const docs = labelConfig[labelProperty] || []

  return (
    <ConfigButton
      label="FedEx® Electronic Trade Documents Preshipment Docs"
      onClick={showETDPreshipmentDocsModal}
      labelProperty={labelProperty}
      validShipperTypes={VALID_SHIPPER_TYPES}
      hide={!hasFedExNewCred}
    >
      <PluralBlock array={docs}>
        <IfZero>Edit</IfZero>
        <IfSingle>1 Document</IfSingle>
        <IfPluralNotZero>
          <Count /> Docs
        </IfPluralNotZero>
      </PluralBlock>
    </ConfigButton>
  )
}
