import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import {saveShipperAmazonSFP} from '../../../../redux/actions/ui/settings/shippers/forms/amazonSFP.js'
import {hasErrorsSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

export function AmazonSFPForm({
  form: {id, isSaving},
  hasErrors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && (
              <>
                <Nickname shipperID={id} />
                <ShippingMethods shipperID={id} />
              </>
            )}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                isLoading={isSaving}
                isDisabled={hasErrors}
                onClick={() => props.saveShipperAmazonSFP(id)}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>

      {id !== NEW_ID && (
        <div className="medium-5 columns">
          <div className="alert margin-bottom-10 alert--standard">
            <Archive shipperID={id} />
          </div>
        </div>
      )}
    </div>
  )
}

AmazonSFPForm.propTypes = {
  form: ShipperFormShape,
  hasErrors: PropTypes.bool.isRequired,
  saveShipperAmazonSFP: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    hasErrors: hasErrorsSelector(state, props),
  }
}

const mapDispatchToProps = {
  saveShipperAmazonSFP,
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonSFPForm)
