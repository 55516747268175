import PropTypes from 'prop-types'

export default function ButtonPrimaryDropdownItem({
  title,
  description,
  onClick,
}) {
  return (
    <li className="list__item list__item--dropdown">
      <button
        className="btn--link list__link--dropdown"
        type="button"
        onClick={onClick}
      >
        <span className="btn__text">{title}</span>
        {description && <span className="block unbold">{description}</span>}
      </button>
    </li>
  )
}

ButtonPrimaryDropdownItem.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  onClick: PropTypes.func.isRequired,
}
