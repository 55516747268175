import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import {isPresent, isNumeric} from '../../../../common/utils.js'
import {ErrorsShape} from '../../../../common/PropTypes.js'
import formConnect from '../../../../common/formConnect.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../../common/apiverson.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {setReturnOrder} from '../../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  setFormValue,
  removeForm,
} from '../../../../redux/actions/ui/forms.js'
import {returnOrderSelector} from '../../../../redux/selectors/data/returnOrders.js'
import {formsSelector} from '../../../../redux/selectors/ui/forms.js'

import {refreshReturnOrderList} from '../../returnOrderListActions.js'
import Line from './Line.js'

export const RECEIVE_RETURN_ORDER_MODAL_FORM = 'RECEIVE_RETURN_ORDER_MODAL_FORM'
export const SHOW_RECIEVE_RETURN_ORDER_MODAL = 'SHOW_RECIEVE_RETURN_ORDER_MODAL'
export const RECEIVE_RETURN_ORDER = 'RECEIVE_RETURN_ORDER'

export function showRecieveReturnOrderModal(
  referenceID,
  lineIDs,
  focusType = 'recieved',
) {
  return {
    type: SHOW_RECIEVE_RETURN_ORDER_MODAL,
    payload: {referenceID, lineIDs, focusType},
  }
}

export function updateModalForm(updates) {
  return updateForm(RECEIVE_RETURN_ORDER_MODAL_FORM, updates)
}

export function setModalFormValue(path, value) {
  return setFormValue(RECEIVE_RETURN_ORDER_MODAL_FORM, path, value)
}

export function removeModalForm() {
  return removeForm(RECEIVE_RETURN_ORDER_MODAL_FORM)
}

export function receiveReturnOrder() {
  return {
    type: RECEIVE_RETURN_ORDER,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[RECEIVE_RETURN_ORDER_MODAL_FORM],
)

export const errorsSelector = createSelector(modalFormSelector, (form) => {
  const errors = {}

  if (!form) {
    return errors
  }

  form.lines.forEach(
    (
      {
        expectedQuantity,
        toBeReceivedQuantity,
        toBeRestockedQuantity,
        restockWarehouseID,
      },
      index,
    ) => {
      if (expectedQuantity && !isNumeric(expectedQuantity)) {
        errors[`lines__${index}__expectedQuantity`] =
          'Quantity must be a number'
        errors.preventSave = true
      }

      if (toBeReceivedQuantity && !isNumeric(toBeReceivedQuantity)) {
        errors[`lines__${index}__toBeReceivedQuantity`] =
          'Quantity must be a number'
        errors.preventSave = true
      }

      if (toBeRestockedQuantity && !isNumeric(toBeRestockedQuantity)) {
        errors[`lines__${index}__toBeRestockedQuantity`] =
          'Quantity must be a number'
        errors.preventSave = true
      }

      if (
        isNumeric(toBeRestockedQuantity) &&
        Number(toBeRestockedQuantity) !== 0 &&
        !isPresent(restockWarehouseID)
      ) {
        errors[`lines__${index}__restockWarehouseID`] =
          'A restock warehouse is required'
        errors.preventSave = true
      }
    },
  )

  return errors
})

export function* showRecieveReturnOrderModalWorker({
  payload: {referenceID, lineIDs, focusType},
}) {
  const returnOrder = yield select(returnOrderSelector, {referenceID})

  const lines = returnOrder.lines.reduce((prev, line) => {
    if (lineIDs.includes(line.line_id)) {
      prev.push({
        ...line,
        expectedQuantity: `${line.expected_quantity || 0}`,
        toBeReceivedQuantity: '0',
        toBeRestockedQuantity: '0',
        restockWarehouseID: line.restock_warehouse_id || null,
      })
    }

    return prev
  }, [])

  yield put(
    setForm(RECEIVE_RETURN_ORDER_MODAL_FORM, {
      referenceID,
      lines,
      focusType,
      isSaving: false,
      serverError: null,
    }),
  )
}

export function* updateReturnOrderLine(
  referenceID,
  {
    expectedQuantity,
    toBeReceivedQuantity,
    toBeRestockedQuantity,
    restockWarehouseID,
    ...line
  },
  stats,
) {
  const params = {}

  expectedQuantity = Number(expectedQuantity) || 0
  toBeReceivedQuantity = Number(toBeReceivedQuantity) || 0
  toBeRestockedQuantity = Number(toBeRestockedQuantity) || 0

  if (expectedQuantity !== line.expected_quantity) {
    params.expected_quantity = expectedQuantity
  }

  if (toBeReceivedQuantity !== 0) {
    params.received_quantity = line.received_quantity + toBeReceivedQuantity
  }

  if (toBeRestockedQuantity !== 0) {
    params.restocked_quantity = line.restocked_quantity + toBeRestockedQuantity
  }

  if (restockWarehouseID !== line.restock_warehouse_id) {
    params.restock_warehouse_id = restockWarehouseID
  }

  if (Object.keys(params).length === 0) {
    return
  }

  const {json} = yield call(
    apiverson.put,
    `/return_order/${encodeURIComponent(referenceID)}/line/${line.line_id}`,
    params,
  )

  yield put(setReturnOrder(json))

  stats.count = stats.count + 1
}

export function* receiveReturnOrderWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, lines} = yield select(modalFormSelector)
    const stats = {count: 0}

    yield all(
      lines.map((line) =>
        call(updateReturnOrderLine, referenceID, line, stats),
      ),
    )

    if (stats.count !== 0) {
      yield put(refreshReturnOrderList())
    }

    yield call(
      showMessageToast,
      `${stats.count} RMA Line${stats.count !== 1 ? 's were' : ' was'} updated`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error setting RMA status: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function ReceiveReturnOrderModal({form, errors, ...props}) {
  return (
    <ConfirmModal
      title="Receive/Restock Returned Items"
      modalSize="x-lg"
      onConfirm={() => props.receiveReturnOrder()}
      onCancel={() => props.removeModalForm()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <>
        <p className="fs-01 margin-bottom-20">
          <strong>Items in RMA</strong>
        </p>
        <table className="table">
          <thead>
            <tr>
              <th className="table__th table__th--sm w-15">Product Name/SKU</th>
              <th className="table__th table__th--sm w-10">Return Code</th>
              <th className="table__th table__th--sm w-10 border-left--light align-center">
                Qty Expected
              </th>
              <th className="table__th table__th--sm w-10 border-left--light align-center">
                Qty to Receive
              </th>
              <th className="table__th table__th--sm w-10 border-left--light align-center">
                Qty to Restock
              </th>
              <th className="table__th table__th--sm w-20 border-left--light">
                Restock Location
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody--lines">
            {form.lines.map((line, index) => (
              <Line
                key={line.line_id}
                index={index}
                line={line}
                errors={errors}
                autoFocusExpected={index === 0 && form.focusType === 'expected'}
                autoFocusReceived={index === 0 && form.focusType === 'recieved'}
                autoFocusRestocked={
                  index === 0 && form.focusType === 'restocked'
                }
                setModalFormValue={props.setModalFormValue}
              />
            ))}
          </tbody>
        </table>
      </>
    </ConfirmModal>
  )
}

ReceiveReturnOrderModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        line_id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    focusType: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  errors: ErrorsShape.isRequired,
  receiveReturnOrder: PropTypes.func.isRequired,
  setModalFormValue: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    errors: errorsSelector(state),
  }
}

const mapDispatchToProps = {
  receiveReturnOrder,
  setModalFormValue,
  removeModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ReceiveReturnOrderModal),
  modalFormSelector,
)
