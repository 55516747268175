import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Loading from '../../../common/components/List/Loading.js'
import {isLoadingSelector} from '../productListFunctions.js'
import ProductListFooter from '../ProductListFooter.js'
import Table from './Table.js'
import Marketing from './Marketing.js'

export function Basic({isLoading}) {
  return (
    <div className="wrap--row margin-top-10">
      <div className="medium-9 columns">
        {!isLoading && (
          <>
            <Table />
            <ProductListFooter />
          </>
        )}
        {isLoading && (
          <div className="columns list--empty-content margin-top-40 margin-bottom-40">
            <Loading />
          </div>
        )}
      </div>
      <div className="medium-3 columns">
        <Marketing />
      </div>
    </div>
  )
}

Basic.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isLoading: isLoadingSelector(state),
  }
}

export default connect(mapStateToProps)(Basic)
