import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

export class ProgressIndicator extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      phase: '',
    }
  }

  componentDidMount() {
    this.determineState(false, this.props.isRequesting)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRequesting !== this.props.isRequesting) {
      this.determineState(prevProps.isRequesting, this.props.isRequesting)
    }
  }

  onAnimationEnd = () => {
    if (this.state.phase === 'end--indeterminate') {
      this.clear()
    }
  }

  determineState(before, after) {
    if (!before && after) {
      this.start()
    } else if (before && !after) {
      this.end()
    } else {
      this.clear()
    }
  }

  start() {
    this.setState({
      phase: 'start--indeterminate',
    })
  }

  end() {
    this.setState({
      phase: 'end--indeterminate',
    })
  }

  clear() {
    this.setState({
      phase: '',
    })
  }

  render() {
    const {phase} = this.state

    return (
      <div
        className={classNames('progress-indicator__outer', phase)}
        onAnimationEnd={this.onAnimationEnd}
      >
        <div className="progress-indicator__inner" />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isRequesting: state.ui.isRequesting,
  }
}

export default connect(mapStateToProps)(ProgressIndicator)
