import {useRef} from 'react'
import classNames from 'classnames'

import {skuListSelector} from '../productListFunctions.js'
import ProductSelectAllButton from '../ProductSelectAllButton.js'
import Row from './Row.js'
import EmptyList from '../EmptyList.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../../data/warehouses.js'
import {useSelector} from '../../../store.js'
import useMapChild from '../../../common/components/useMapChild.js'

export default function Table() {
  const skuList = useSelector(skuListSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)

  const firstRowRef = useRef()
  const headerRef = useRef()

  const widths = useMapChild({
    ref: firstRowRef,
    onChild: (el) => el.getBoundingClientRect().width,
  })

  return (
    <div className="wrap--product-list-inner-scroll">
      {skuList.length > 0 && (
        <div
          ref={headerRef}
          className="flex table table--zebra-list table--product-list table-inner-scroll--header"
        >
          <div
            className="table__th table__th--sm v-align-middle no-border table__th--order-checkbox margin-auto-all"
            style={{width: widths[0]}}
          >
            <ProductSelectAllButton />
          </div>
          <div
            className="table__th table__th--sm table__th--product-list-name th-width-20 v-align-middle no-border margin-auto-all"
            style={{width: widths[1]}}
          >
            Product
          </div>
          <div
            className="table__th table__th--sm th-width-15 v-align-middle no-border margin-auto-all"
            style={{width: widths[2]}}
          >
            SKU
          </div>
          <div
            className="table__th table__th--sm th-width-10 v-align-middle no-border margin-auto-all"
            style={{width: widths[3]}}
          >
            UPC
          </div>
          <div
            className="table__th table__th--sm th-width-10 v-align-middle no-border margin-auto-all"
            style={{width: widths[4]}}
          >
            Weight
          </div>
          <div
            className="table__th table__th--sm th-width-10 v-align-middle no-border margin-auto-all"
            style={{width: widths[5]}}
          >
            Pkg Dims
          </div>
          <div
            className="table__th table__th--sm th-width-15 v-align-middle no-border margin-auto-all"
            style={{width: widths[6]}}
          >
            Sales Channels
          </div>
          <div
            className="table__th table__th--sm th-width-20 padding-left-0 padding-right-0 v-align-middle no-border margin-auto-all"
            style={{width: widths[7]}}
          >
            Warehouses{' '}
            <span className="unbold">
              (
              {warehouses.length > 1
                ? `${warehouses.length} warehouses`
                : getWarehouseName(warehouses[0])}
              )
            </span>
          </div>
          <div
            className="table__th table__th--sm v-align-middle no-border padding-left-3 padding-right-3 th-width-3 margin-auto-all"
            style={{width: widths[8]}}
          />
        </div>
      )}
      <table
        className={classNames(
          'table table--zebra-list table--product-list table--no-inventory',
          {
            'margin-top-43': skuList.length === 0,
          },
        )}
      >
        <tbody
          className={classNames('table__tbody--full-border', {
            'table__tbody--border-top-transparent': skuList.length > 0,
          })}
        >
          {skuList.map((sku, index) => (
            <Row key={sku} sku={sku} ref={index === 0 ? firstRowRef : null} />
          ))}
          {skuList.length === 0 && <EmptyList colSpan="9" />}
        </tbody>
      </table>
    </div>
  )
}
