import {all, takeEvery, put, call, select} from 'redux-saga/effects'

import api from '../../../../common/api.js'
import {navigate} from '../../../actions/ui/index.js'
import {usersSelector, setUser} from '../../../../data/users.js'
import {userSettingsSelector} from '../../../selectors/ui/settings/users.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import {
  SETUP_FOR_EDIT,
  SAVE_USER,
  TOGGLE_CHANGE_PASSWORD,
  setIsSaving,
  setIsChangingPassword,
  setServerError,
  setUserForm,
  goToUserSettings,
} from '../../../actions/ui/settings/users.js'

export function* setupForEditWorker() {
  const {editLink} = yield select(userSettingsSelector)
  const users = yield select(usersSelector)

  const user = Object.values(users).find((user) => user._link === editLink)

  if (!user) {
    yield put(navigate('#/settings/user'))

    return
  }

  yield put(
    setUserForm({
      link: user._link,
      name: user.name || '',
      email: user.email || '',
      password: '',
      passwordConfirm: '',
      warehouseIDs: user.warehouses.map(({id}) => id),
      permissions: user.permissions,
    }),
  )
}

export function* saveUserWorker() {
  const {
    userForm: {link, name, email, password, permissions},
  } = yield select(userSettingsSelector)

  const users = yield select(usersSelector)

  const user = Object.values(users).find((user) => user._link === link)

  const params = {
    name,
  }

  if (!user || email !== user.email) {
    params.email = email
  }

  if (password) {
    params.password = password
  }

  if (user) {
    params.permissions = permissions
  }

  yield put(setServerError(null))
  yield put(setIsSaving(true))

  try {
    const {json} = link
      ? yield call(api.put, link, params)
      : yield call(api.post, '/user/', params)

    yield put(goToUserSettings())

    yield call(setUser, json)

    yield call(showMessageToast, link ? 'User updated' : 'User created')
  } catch (err) {
    yield put(setServerError(err))
  }

  yield put(setIsSaving(false))
}

export function* toggleChangePasswordWorker() {
  const {isChangingPassword} = yield select(userSettingsSelector)

  yield put(setIsChangingPassword(!isChangingPassword))
}

export default function* packingListsSaga() {
  yield all([
    takeEvery(SETUP_FOR_EDIT, setupForEditWorker),
    takeEvery(SAVE_USER, saveUserWorker),
    takeEvery(TOGGLE_CHANGE_PASSWORD, toggleChangePasswordWorker),
  ])
}
