import analytics from './common/analytics/index.js'

import '../sass/app.scss'

import {start} from './ordoro/index.js'

if (document && document.querySelector) {
  analytics.init()

  start()
}
