import PropTypes from 'prop-types'

import ShipFrom from './Fields/ShipFrom.js'
import PackingList from './Fields/PackingList.js'
import Notify from './Fields/Notify.js'
import Weight from './Fields/Weight.js'
import SingleDimensions from './Fields/Dimensions/SingleDimensions.js'
import EditAdditionalOptions from './SingleLabelConfigForm/EditAdditionalOptions.js'
import {useSelector} from '../../store.js'
import {shipperTypesSelector} from '../../data/labelInfos/index.js'
import {useLabelConfigContext} from './LabelConfigContext.js'
import {FEDEX, UPS} from '../../common/constants/ShipperNames.js'
import ResidentialAddress from './Fields/ResidentialAddress.js'

export default function LabelConfigForm({showUnSetupPackingList, showBillTo}) {
  const {labelInfoID, isLabelEnableMode} = useLabelConfigContext()
  const shipperTypes = useSelector((state) =>
    shipperTypesSelector(state, {labelInfoID}),
  )

  return (
    <div>
      <ul className="list list--shipping-options margin-bottom-15">
        <li className="list__item--no-style">
          <ul className="list flex flex-wrap flex-wrap-margin-10">
            <Weight />
            <SingleDimensions />
          </ul>
        </li>
        <ShipFrom />
        <PackingList showUnSetupPackingList={showUnSetupPackingList} />
        <Notify showBillTo={showBillTo} />
        <ResidentialAddress />
        {shipperTypes.length > 0 && (
          <li className="list__item divider--top margin-bottom-0">
            <div>
              <strong className="fs-00 lh-md">
                Additional Carrier Options
              </strong>
            </div>
            <ul className="list list--no-style">
              {shipperTypes.map((shipperType) => (
                <EditAdditionalOptions
                  key={shipperType}
                  shipperType={shipperType}
                  isLabelEnableMode={isLabelEnableMode}
                  singleShipperType={shipperTypes.length === 1}
                />
              ))}
            </ul>
          </li>
        )}
      </ul>
      {shipperTypes.includes(UPS) && (
        <div className="label-config__carrier-fine-print">
          UPS, the UPS shield trademark, the UPS Ready mark, the UPS Developer
          Kit mark and the Color Brown are trademarks of United Parcel Service
          of America, Inc. All Rights Reserved.
        </div>
      )}
      {shipperTypes.includes(FEDEX) && (
        <div className="label-config__carrier-fine-print">
          FedEx service marks are owned by Federal Express Corporation and are
          used by permission.
        </div>
      )}
    </div>
  )
}

LabelConfigForm.propTypes = {
  showUnSetupPackingList: PropTypes.bool,
  showBillTo: PropTypes.bool,
}
