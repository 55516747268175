import PropTypes from 'prop-types'

import {NEW_ID} from '../../common/constants/index.js'
import {useSelector} from '../../store.js'
import {batchArchiveCountSelector} from '../../data/batches.js'
import {needsShipperSelector} from '../../data/shippers.js'
import {showArchiveBatchModal} from './Modals/ArchiveBatchModal.js'
import {showCreateLabelsModal} from './Modals/CreateLabelsModal.js'
import {showRefreshRatesModal} from './Modals/RefreshRatesModal.js'
import {showBulkUpdateModal} from './Modals/BulkUpdateModal.js'
import {showSortBatchModal} from './Modals/SortBatchModal.js'

export default function ActionsForSelected({referenceIDs}) {
  const archiveCount = useSelector((state) =>
    batchArchiveCountSelector(state, {referenceIDs}),
  )
  const needsShipper = useSelector(needsShipperSelector)

  return (
    <>
      {archiveCount === referenceIDs.length ? (
        <li className="inline-block fs-n0 v-align-middle divider--left">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showArchiveBatchModal(referenceIDs, false)}
          >
            Unarchive
          </button>
        </li>
      ) : archiveCount === 0 ? (
        <>
          <li className="inline-block fs-n0 v-align-middle divider--left">
            <button
              className="btn btn--action v-align-base"
              type="button"
              onClick={() => showArchiveBatchModal(referenceIDs, true)}
            >
              Archive
            </button>
          </li>
          {needsShipper ? (
            <li className="alert alert--warning alert--sm fs-n0 inline-block margin-left-10">
              <a
                className="btn btn--action btn--link darker v-align-base"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure at least one carrier account"
              >
                <strong>Add a Carrier Account</strong>
              </a>{' '}
              →
            </li>
          ) : (
            <>
              <li className="inline-block fs-n0 v-align-middle divider--left">
                <button
                  className="btn btn--action v-align-base"
                  type="button"
                  onClick={() => showBulkUpdateModal(referenceIDs)}
                >
                  Bulk Update
                </button>
              </li>
              <li className="inline-block fs-n0 v-align-middle divider--left">
                <button
                  className="btn btn--action v-align-base"
                  type="button"
                  onClick={() => showRefreshRatesModal(referenceIDs)}
                >
                  Refresh Rates
                </button>
              </li>
              <li className="inline-block fs-n0 v-align-middle divider--left">
                <button
                  className="btn btn--action v-align-base"
                  type="button"
                  onClick={() => showSortBatchModal(referenceIDs)}
                >
                  Sort Batch Orders
                </button>
              </li>
              <li className="inline-block fs-n0 v-align-middle divider--left">
                <button
                  className="btn btn--action v-align-base"
                  type="button"
                  onClick={() =>
                    showCreateLabelsModal({referenceIDs, includeLabels: false})
                  }
                >
                  Create Pick/Pack Lists
                </button>
              </li>
              <li className="inline-block fs-n0 v-align-middle divider--left">
                <button
                  className="btn btn--action v-align-base"
                  type="button"
                  onClick={() => showCreateLabelsModal({referenceIDs})}
                >
                  Create Labels
                </button>
              </li>
            </>
          )}
        </>
      ) : null}
    </>
  )
}

ActionsForSelected.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
}
