import {
  shipperTypesSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {
  AMAZON_SHIPPER,
  FEDEX,
  UPS,
} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {useSelector} from '../../../store.js'
import {includesSome} from '../../../common/utils.js'

const LABEL_PROPERTY = 'recipient_address_is_residential'
const VALID_SHIPPER_TYPES = [AMAZON_SHIPPER, FEDEX, UPS]

export default function ResidentialAddress() {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const shipperTypes = useSelector((state) =>
    shipperTypesSelector(state, {labelInfoID}),
  )

  const hide = !includesSome(VALID_SHIPPER_TYPES, shipperTypes)

  return (
    <ConfigCheckbox
      label="Residential Address"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      hide={hide}
    />
  )
}
