import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Currency from '../../../../../../../common/components/Currency.js'
import {
  shippedInsuredValueSelector,
  shippedInsuranceCostSelector,
} from '../../../../../../../data/orders.js'

export function ShippedInsuranceItem({insuranceCost, insuredValue}) {
  return (
    <li className="list__item--order-data list__item--shipping-info">
      <strong className="margin-right-3">
        Third-Party Insurance (via Shipsurance):
      </strong>
      {insuredValue ? (
        <span>
          <Currency value={insuranceCost || 0} currencySymbol="$" /> (
          <Currency value={insuredValue} currencySymbol="$" /> of coverage)
        </span>
      ) : (
        <em>None</em>
      )}
    </li>
  )
}

ShippedInsuranceItem.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  insuranceCost: PropTypes.number,
  insuredValue: PropTypes.number,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    insuranceCost: shippedInsuranceCostSelector(state, {orderNumber}),
    insuredValue: shippedInsuredValueSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(ShippedInsuranceItem)
