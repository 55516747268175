import PropTypes from 'prop-types'
import omit from 'lodash/omit.js'

import {getState, updateForm, removeForm} from '../../../store.js'
import {AddressShape} from '../../../common/PropTypes.js'
import {Address} from '../../../common/address.js'
import {isNumeric} from '../../../common/utils.js'
import {apiPost} from '../../../common/api.js'
import {logError} from '../../../common/error.js'
import {OT_RETURN_ORDER} from '../../../common/constants/OrderTypes.js'
import {RETURN_TO_ADDRESS} from '../../../common/constants/AddressTypes.js'
import {updateReturnOrderAddress} from '../../../redux/actions/data/returnOrders.js'
import {
  applyPreset,
  unavailableLabelConfigsSelector,
} from '../../../data/presets.js'
import {showGlobalError} from '../../GlobalErrorMessage.js'
import {
  BULK_LABEL_ID,
  updateLabelInfo,
  updateLabelConfig,
  updateLabelInfos,
  resetBulkRates,
  applyShipFrom,
  saveLabelInfo,
  setBulkRatesLoading,
  labelShipperTypeSelector,
  labelShipperIDSelector,
  rateKeySelector,
  labelConfigSelector,
  packagesSelector,
  parcelSelector,
  dimensionsSelector,
  shippingConfigurationSelector,
  orderTypeSelector,
  referenceIDFromLabelConfigSelector,
  localRatesSelector,
  bulkRatesSelector,
  rawBulkRatesSelector,
  getMaxParcelCount,
} from '../../../data/labelInfos/index.js'
import {
  rateRequestURLSelector,
  bulkRateRequestSelector,
  normalizeRateShape,
  getRates,
} from '../../../data/labelInfos/rateRequest.js'

import {
  BULK_LABEL_CONFIG_FORM,
  bulkLabelConfigFormSelector,
  bulkReturnToAddressSelector,
} from './bulkLabelConfigSelectors.js'

export const BulkLabelConfigFormShape = PropTypes.shape({
  showShippingConfiguration: PropTypes.bool.isRequired,
  showWeight: PropTypes.bool.isRequired,
  showDimensions: PropTypes.bool.isRequired,
  showShipDate: PropTypes.bool.isRequired,
  showPresets: PropTypes.bool.isRequired,
  showShipFrom: PropTypes.bool.isRequired,
  showPackingLists: PropTypes.bool.isRequired,
  shipFromValue: PropTypes.string.isRequired,
  presetID: PropTypes.string.isRequired,
  returnToAddress: AddressShape.isRequired,
  loading__shippingConfiguration: PropTypes.bool.isRequired,
  loading__weight: PropTypes.bool.isRequired,
  loading__dimensions: PropTypes.bool.isRequired,
  loading__shipDate: PropTypes.bool.isRequired,
  loading__shipFrom: PropTypes.bool.isRequired,
  loading__presets: PropTypes.bool.isRequired,
  loading__packingLists: PropTypes.bool.isRequired,
})

export function setupBulkLabelConfigForm() {
  return {
    showShippingConfiguration: false,
    showWeight: false,
    showDimensions: false,
    showShipDate: false,
    showPresets: false,
    showShipFrom: false,
    showPackingLists: false,
    shipFromValue: '',
    presetID: '',
    returnToAddress: new Address(),
    loading__shippingConfiguration: false,
    loading__weight: false,
    loading__dimensions: false,
    loading__shipDate: false,
    loading__shipFrom: false,
    loading__presets: false,
    loading__packingLists: false,
  }
}

export function updateBulkLabelConfigForm(updates, meta) {
  updateForm(BULK_LABEL_CONFIG_FORM, updates, meta)
}

export function removeBulkLabelConfigForm() {
  removeForm(BULK_LABEL_CONFIG_FORM)
}

export function setShowShippingConfiguration(showShippingConfiguration) {
  updateBulkLabelConfigForm({showShippingConfiguration})
}

export function setShowWeight(showWeight) {
  updateBulkLabelConfigForm({showWeight})
}

export function setShowDimensions(showDimensions) {
  updateBulkLabelConfigForm({showDimensions})
}

export function setShowShipDate(showShipDate) {
  updateBulkLabelConfigForm({showShipDate})
}

export function setShowShipFrom(showShipFrom) {
  updateBulkLabelConfigForm({showShipFrom})
}

export function setShipFromValue(shipFromValue) {
  updateBulkLabelConfigForm({shipFromValue})
}

export function toggleShowPresets() {
  const {showPresets} = bulkLabelConfigFormSelector(getState())

  updateBulkLabelConfigForm({showPresets: !showPresets})
}

export function setPresetID(presetID) {
  updateBulkLabelConfigForm({presetID})
}

export function setBulkReturnToAddress(returnToAddress) {
  updateBulkLabelConfigForm({returnToAddress})
}

export function setShowPackingLists(showPackingLists) {
  updateBulkLabelConfigForm({showPackingLists})
}

export function setShippingConfigurationLoading(isLoading) {
  updateBulkLabelConfigForm({loading__shippingConfiguration: isLoading})
}

export function setWeightLoading(isLoading) {
  updateBulkLabelConfigForm({loading__weight: isLoading})
}

export function setDimensionsLoading(isLoading) {
  updateBulkLabelConfigForm({loading__dimensions: isLoading})
}

export function setShipDateLoading(isLoading) {
  updateBulkLabelConfigForm({loading__shipDate: isLoading})
}

export function setShipFromLoading(isLoading) {
  updateBulkLabelConfigForm({loading__shipFrom: isLoading})
}

export function setBulkPresetsLoading(isLoading) {
  updateBulkLabelConfigForm({loading__presets: isLoading})
}

export function setPackingListsLoading(isLoading) {
  updateBulkLabelConfigForm({loading__packingLists: isLoading})
}

export function resetBulkPresets() {
  updateBulkLabelConfigForm({
    showPresets: false,
    presetID: '',
    returnToAddress: new Address(),
    loading__presets: false,
  })
}

export async function applyShippingConfiguration(labelInfoIDs) {
  try {
    setShippingConfigurationLoading(true)

    const shipperType = labelShipperTypeSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
    })
    const {packages, ...shippingConfiguration} = shippingConfigurationSelector(
      getState(),
      {
        labelInfoID: BULK_LABEL_ID,
        shipperType,
      },
    )
    const maxParcelCount = getMaxParcelCount(shipperType)

    for (const labelInfoID of labelInfoIDs) {
      const bulkRates = rawBulkRatesSelector(getState(), {labelInfoID})
      const config = labelConfigSelector(getState(), {labelInfoID})
      let eachPackages = packagesSelector(getState(), {labelInfoID})
      eachPackages = eachPackages.slice(0, maxParcelCount)

      updateLabelInfo(labelInfoID, {
        config: {
          ...config,
          ...shippingConfiguration,
          packages: eachPackages.map((parcel) => ({
            ...parcel,
            ...packages[0],
          })),
        },
        rates: {list: bulkRates},
      })

      await saveLabelInfo(labelInfoID)
    }

    resetBulkRates(labelInfoIDs)

    setShowShippingConfiguration(false)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error applying shipping configuration.',
        details: err.message,
      },
      err,
    )
  } finally {
    setShippingConfigurationLoading(false)
  }
}

export async function applyWeight(labelInfoIDs) {
  try {
    setWeightLoading(true)

    const {weight} = parcelSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
      packagesIndex: 0,
    })

    for (const labelInfoID of labelInfoIDs) {
      let packages = packagesSelector(getState(), {labelInfoID})

      updateLabelConfig(labelInfoID, {
        packages: packages.map((parcel) => ({
          ...parcel,
          weight,
        })),
      })

      await saveLabelInfo(labelInfoID)
    }

    setShowWeight(false)

    await getBulkRates(labelInfoIDs)

    setWeightLoading(false)

    await getRates(labelInfoIDs)
  } catch (err) {
    logError(err, `Error when applying bulk weight: ${err.message}`)

    setWeightLoading(false)
  }
}

export async function applyDimensions(labelInfoIDs) {
  try {
    setDimensionsLoading(true)

    const {length, width, height} = dimensionsSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
      packagesIndex: 0,
    })

    for (const labelInfoID of labelInfoIDs) {
      let packages = packagesSelector(getState(), {labelInfoID})

      updateLabelConfig(labelInfoID, {
        packages: packages.map((parcel) => ({
          ...parcel,
          length,
          width,
          height,
        })),
      })

      await saveLabelInfo(labelInfoID)
    }

    setShowDimensions(false)

    await getBulkRates(labelInfoIDs)

    setDimensionsLoading(false)

    await getRates(labelInfoIDs)
  } catch (err) {
    logError(err, `Error when applying bulk dimension: ${err.message}`)

    setDimensionsLoading(false)
  }
}

export async function applyShipDate(labelInfoIDs) {
  try {
    setShipDateLoading(true)

    const {ship_date} = labelConfigSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
    })

    for (const labelInfoID of labelInfoIDs) {
      updateLabelConfig(labelInfoID, {ship_date})

      await saveLabelInfo(labelInfoID)
    }

    setShowShipDate(false)

    setShipDateLoading(false)

    await getRates(labelInfoIDs)
  } catch (err) {
    logError(err, `Error when applying bulk ship date: ${err.message}`)

    setShipDateLoading(false)
  }
}

export async function applyShipFromBulk(labelInfoIDs, warehouseID) {
  try {
    setShipFromLoading(true)

    const orderType = orderTypeSelector(getState(), {
      labelInfoID: labelInfoIDs[0],
    })

    if (orderType === OT_RETURN_ORDER) {
      const address = bulkReturnToAddressSelector(getState())

      await Promise.all(
        labelInfoIDs.map(async (labelInfoID) => {
          const referenceID = referenceIDFromLabelConfigSelector(getState(), {
            labelInfoID,
          })

          await updateReturnOrderAddress(
            referenceID,
            RETURN_TO_ADDRESS,
            address,
          )
        }),
      )
    } else {
      const {do_not_ship_from_supplier} = labelConfigSelector(getState(), {
        labelInfoID: BULK_LABEL_ID,
      })

      for (const labelInfoID of labelInfoIDs) {
        updateLabelConfig(labelInfoID, {do_not_ship_from_supplier})
      }

      await Promise.all(
        labelInfoIDs.map((labelInfoID) =>
          applyShipFrom(labelInfoID, warehouseID),
        ),
      )
    }

    setShowShipFrom(false)

    await getBulkRates(labelInfoIDs)

    setShipFromLoading(false)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error applying ship from.',
        details: err.message,
      },
      err,
    )
    setShipFromLoading(false)
  }
}

export async function applyPackingListBulk(labelInfoIDs) {
  try {
    setPackingListsLoading(true)

    const {packing_list_id} = labelConfigSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
    })

    await Promise.all(
      labelInfoIDs.map(async (labelInfoID) => {
        updateLabelConfig(labelInfoID, {packing_list_id})

        await saveLabelInfo(labelInfoID)
      }),
    )

    setShowPackingLists(false)

    setPackingListsLoading(false)

    await getRates(labelInfoIDs)
  } catch (err) {
    logError(err, `Error when applying bulk profile: ${err.message}`)

    setPackingListsLoading(false)
  }
}

export async function applyPresetBulk(labelInfoIDs, presetID) {
  try {
    setBulkPresetsLoading(true)

    await Promise.all(
      labelInfoIDs.map((labelInfoID) => applyPreset(labelInfoID, presetID)),
    )

    const unavailableLabelConfigs = unavailableLabelConfigsSelector(
      getState(),
      {
        labelInfoIDs,
      },
    )

    if (unavailableLabelConfigs.length === 0) {
      resetBulkPresets()
    }
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error bulk applying preset.',
        details: err.message,
      },
      err,
    )
  } finally {
    setBulkPresetsLoading(false)
  }
}

const cache = {}

export async function fetchRates(labelInfoID, shipperType, signal) {
  try {
    const localRates = localRatesSelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
    })
    if (localRates) {
      return localRates
    }

    const url = rateRequestURLSelector(getState(), {
      labelInfoID,
      shipperType,
      fromBulk: true,
    })
    const params = bulkRateRequestSelector(getState(), {labelInfoID})

    // The params key identifies the cache as `still valid`
    // `ship_date` changes all the time, so can't track it.
    const paramsKey = JSON.stringify(omit(params, 'ship_date'))
    const previousResults = cache[labelInfoID]
    let rates

    if (!previousResults || previousResults.paramsKey !== paramsKey) {
      const json = await apiPost(`/v3${url}`, params, {signal})

      if (!Array.isArray(json)) {
        console.error('No rate list provided', json)

        throw new Error('No rate list provided')
      }

      rates = normalizeRateShape({labelInfoID: BULK_LABEL_ID, rates: json})

      // Cache the rates so we don't ask for them twice
      cache[labelInfoID] = {
        paramsKey,
        rates,
      }
    } else if (previousResults) {
      rates = previousResults.rates
    }

    return rates
  } catch (err) {
    if (err.message && err.message.match('abort')) {
      return
    }
    // FIXME: Show this error somewhere in BulkLabelConfigForm
    logError(err, `Error fetching rates: ${err.message}`)
  }
  return null
}

export async function getBulkRates(labelInfoIDs) {
  // Need to conditionally use AbortController for tests because it doesn't exist in our test env
  // also we have 8 users?!? of 2600 that don't support this class
  // we can just use AbortController directly in a better future
  const controller = window.AbortController
    ? new window.AbortController()
    : null

  const oldController = getBulkRates.controller

  if (oldController) {
    oldController.abort()
  }

  getBulkRates.controller = controller

  const shipperID = labelShipperIDSelector(getState(), {
    labelInfoID: BULK_LABEL_ID,
  })
  if (!shipperID) {
    return
  }

  setShippingConfigurationLoading(true)
  setBulkRatesLoading(labelInfoIDs, true)

  const shipperType = labelShipperTypeSelector(getState(), {
    labelInfoID: BULK_LABEL_ID,
  })

  const labelInfos = {}

  async function update(labelInfoID) {
    const rates = await fetchRates(
      labelInfoID,
      shipperType,
      controller ? controller.signal : undefined,
    )
    labelInfos[labelInfoID] = {bulk_rates: rates}
  }

  try {
    await Promise.all(labelInfoIDs.map((labelInfoID) => update(labelInfoID)))

    if (controller && controller.signal.aborted) {
      return
    }

    // Clean up cache. Rates are only cached if we are looking for them
    // So as you unselect an order, it removes it from the cache
    Object.keys(cache).map((labelInfoID) => {
      if (
        labelInfoIDs.includes(
          isNumeric(labelInfoID) ? Number(labelInfoID) : labelInfoID,
        ) !== true
      ) {
        delete cache[labelInfoID]
      }
    })

    updateLabelInfos(labelInfos)

    const rates = bulkRatesSelector(getState(), {labelInfoIDs})

    if (!rates || !rates.length) {
      return
    }
    const prevRateKey = rateKeySelector(getState(), {
      labelInfoID: BULK_LABEL_ID,
    })

    const selectedRateStillValid = rates.find(
      (rate) => rate.key === prevRateKey && !rate.disabled,
    )
    if (!selectedRateStillValid) {
      const newRate = rates.find((rate) => !rate.disabled)
      updateLabelConfig(BULK_LABEL_ID, {
        [`${shipperType}__shipping_method`]: newRate
          ? newRate.service_type
          : null,
        [`${shipperType}__rate_key`]: newRate ? newRate.key : null,
      })
    }
  } finally {
    if (controller && controller.signal.aborted) {
      return
    }

    updateLabelInfos(labelInfos)
    setBulkRatesLoading(labelInfoIDs, false)
    setShippingConfigurationLoading(false)
  }
}
