import delay from '../../../common/delay.js'
import {getState, dispatch} from '../../../store.js'

export const SET_CURRENT_DROPDOWN = 'ordoro/ui/SET_CURRENT_DROPDOWN'
import {currentDropdownSelector} from '../../selectors/ui/index.js'

export function setCurrentDropdown(dropdown) {
  dispatch({
    type: SET_CURRENT_DROPDOWN,
    payload: dropdown,
  })
}

function findDropdown(element) {
  let node = element
  const outcome = {
    dropdown: null,
    doNotClose: false,
  }

  while (node !== null) {
    const disabled = node.attributes && node.attributes.getNamedItem('disabled')

    if (disabled && disabled.value !== 'false') {
      outcome.isDisabled = true
    }

    const preventClose =
      node.attributes &&
      node.attributes.getNamedItem('data-dropdown-prevent-close')

    if (preventClose && preventClose.value === 'true') {
      outcome.doNotClose = true
    }

    if (
      node.classList &&
      node.classList.contains('data-dropdown-prevent-close')
    ) {
      outcome.doNotClose = true
    }

    const closeOnBlur =
      node.attributes &&
      node.attributes.getNamedItem('data-dropdown-close-on-blur')

    if (closeOnBlur && closeOnBlur.value === 'true') {
      outcome.closeOnBlur = true
    }

    const dataDropdown =
      node.attributes && node.attributes.getNamedItem('data-dropdown')

    if (dataDropdown) {
      outcome.dropdown = dataDropdown.value
      break
    }

    if (node.classList && node.classList.contains('data-dropdown-name')) {
      for (let i = 0; i < node.classList.length; i++) {
        const className = node.classList[i]

        if (className.startsWith('data-dropdown-name-')) {
          outcome.dropdown = className.split('data-dropdown-name-')[1]
        }
      }
      break
    }

    node = node.parentNode
  }

  return outcome
}

export function onClick({target}) {
  const {dropdown, doNotClose, isDisabled} = findDropdown(target)

  if (isDisabled) {
    return
  }

  const currentDropdown = currentDropdownSelector(getState())

  if (dropdown === currentDropdown && !doNotClose && currentDropdown !== null) {
    setCurrentDropdown(null)
  } else if (dropdown !== currentDropdown) {
    setCurrentDropdown(dropdown)
  }
}

export async function onBlur({target}, timeout = 200) {
  const {closeOnBlur} = findDropdown(target)

  if (!closeOnBlur) {
    return
  }

  // We need to wait for click actions in a dropdown to happen before we close
  // otherwise the dropdown will disappear before it can be clicked
  await delay(timeout)

  const currentDropdown = currentDropdownSelector(getState())

  if (currentDropdown) {
    setCurrentDropdown(null)
  }
}
