import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  setExpandedReferenceIDs,
  setExpandMode,
} from './returnOrderListActions.js'
import {
  expandModeSelector,
  expandedReferenceIDsSelector,
} from './returnOrderListSelectors.js'

function ReturnOrderExpandAll({expandMode, expandedROCount, ...props}) {
  const shouldCollapse =
    expandedROCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={shouldCollapse ? 'Collapse all RMAs' : 'Expand all RMAs'}
    >
      <ExpandAllButton
        className="meta-rmalistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          props.setExpandedReferenceIDs([])
          props.setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          props.setExpandedReferenceIDs([])
          props.setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}

ReturnOrderExpandAll.propTypes = {
  expandMode: PropTypes.string.isRequired,
  expandedROCount: PropTypes.number.isRequired,
  setExpandedReferenceIDs: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  expandMode: expandModeSelector(state),
  expandedROCount: expandedReferenceIDsSelector(state).length,
})

const mapDispatchToProps = {
  setExpandedReferenceIDs,
  setExpandMode,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderExpandAll)
