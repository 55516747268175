import {useEffect} from 'react'

import {
  formSelector,
  getState,
  updateForm,
  useSelector,
} from '../../../store.js'
import ButtonIcon from '../../../common/components/Button/ButtonIcon.js'

import Rule, {moveRule} from './Rule.js'
import NewRule from './NewRule.js'
import {
  SETTINGS_RULES,
  setupRuleIndicies,
  updateSettingsRulesForm,
} from './rulesFunctions.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {DragAndDropList} from '../../../common/components/DragAndDrop.js'
import {
  autoDropshipOnOrderCreationSelector,
  disableCompanyFeature,
  enableCompanyFeature,
  useDropshippingSelector,
} from '../../../data/company.js'

async function toggleAutoDropshipOnOrderCreation() {
  try {
    updateForm(SETTINGS_RULES, {isSavingDropshipFlag: true})
    if (autoDropshipOnOrderCreationSelector(getState())) {
      await disableCompanyFeature('auto_dropship_on_order_creation')
    } else {
      await enableCompanyFeature('auto_dropship_on_order_creation')
    }
  } finally {
    updateForm(SETTINGS_RULES, {isSavingDropshipFlag: false})
  }
}

export default function List() {
  const form = useSelector((state) =>
    formSelector(state, {formName: SETTINGS_RULES}),
  )
  const autoDropshipOnOrderCreation = useSelector(
    autoDropshipOnOrderCreationSelector,
  )
  const useDropshipping = useSelector(useDropshippingSelector)

  useEffect(() => {
    setupRuleIndicies()
  }, [])

  return (
    <div className="row">
      {useDropshipping && (
        <div className="medium-12 columns">
          <div className="wrap--auto-dropshipping-rule enabled">
            <div className="flex--justify">
              <div className="w-65">
                <p className="fs-01 lh-sm margin-bottom-5">
                  <strong>Automatic Dropshipping</strong>
                </p>
                <p className="fs-n0 lh-md margin-bottom-0">
                  With this enabled, you’ll have the ability to set products to
                  be dropshipped automatically when they’re on an order that
                  imports into Ordoro.
                </p>
              </div>
              <div className="switch small round switch-with-labels on-off centered-text margin-bottom-0 meta-rule-toggle-autodropshipping">
                <Checkbox
                  id="auto_dropship_on_order_creation"
                  className="margin-bottom-0"
                  checked={autoDropshipOnOrderCreation}
                  readOnly
                />
                <label
                  role="presentation"
                  htmlFor="auto_dropship_on_order_creation"
                  className="float-right margin-bottom-0"
                  onClick={() =>
                    !form.isSavingDropshipFlag &&
                    toggleAutoDropshipOnOrderCreation()
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <ul className="settings-list medium-12 columns">
        <DragAndDropList onMove={moveRule}>
          {form.indices.map(({id: ruleID}, index) => (
            <li
              className="settings-list-item padding-top-5 margin-top-10 padding-bottom-0 clearfix"
              key={ruleID}
            >
              <Rule ruleID={ruleID} uiIndex={index} />
            </li>
          ))}
        </DragAndDropList>
        <li className="settings-list-item add-item clearfix">
          {!(form.isAddingNew && form.indices.length === 0) && (
            <div className="medium-12 columns">
              <p className="margin-bottom-20">
                <ButtonIcon
                  size="x-sm"
                  iconClassName="icon-add"
                  onClick={() => updateSettingsRulesForm({isAddingNew: true})}
                  text="Add an automation rule"
                />
              </p>
            </div>
          )}
        </li>
        {form.isAddingNew && <NewRule />}
      </ul>
    </div>
  )
}
