import uniq from 'lodash/uniq.js'

import {setForm, updateForm, removeForm} from '../../redux/actions/ui/forms.js'
import {
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  RETURN_ORDER_SORT_OPTIONS,
  RETURN_ORDER_STATUS_OPTIONS,
} from '../../common/constants/ReturnOrders.js'
import {
  TAG_TYPE__RETURN_ORDER,
  TAG_FILTER_OR,
  TAG_FILTER_OPTIONS,
} from '../../common/constants/Tags.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'

export const RETURN_ORDER_LIST_FORM = 'RETURN_ORDER_LIST_FORM'
export const REFRESH_RETURN_ORDER_LIST = 'REFRESH_RETURN_ORDER_LIST'
export const RETURN_ORDER_LIST_SELECT_REFERENCE_ID =
  'RETURN_ORDER_LIST_SELECT_REFERENCE_ID'
export const DESELECT_RETURN_ORDERS = 'DESELECT_RETURN_ORDERS'
export const RETURN_ORDER_LIST_TOGGLE_EXPANDED =
  'RETURN_ORDER_LIST_TOGGLE_EXPANDED'

export function setupReturnOrderListForm() {
  return setForm(RETURN_ORDER_LIST_FORM, {
    isLoading: false,
    searchText: '',
    tags: [],
    untagged: false,
    tagFilterBy: TAG_FILTER_OR,
    status: DEFAULT_STATUS,
    sort: DEFAULT_SORT,
    perPage: DEFAULT_PER_PAGE,
    currentPage: 1,
    count: 0,
    referenceIDList: [],
    selectedReferenceIDs: [],
    expandedReferenceIDs: [],
    expandMode: DEFAULT_EXPAND_MODE,
  })
}

export function updateReturnOrderListForm(updates, meta) {
  return updateForm(RETURN_ORDER_LIST_FORM, updates, meta)
}

export function removeReturnOrderListForm() {
  return removeForm(RETURN_ORDER_LIST_FORM)
}

export function setStatus(status) {
  if (!RETURN_ORDER_STATUS_OPTIONS.find(({value}) => status === value)) {
    status = DEFAULT_STATUS
  }

  return updateReturnOrderListForm({
    status,
    currentPage: 1,
  })
}

export function setPerPage(perPage) {
  perPage = Number(perPage)

  if (![10, 50, 100].includes(perPage)) {
    perPage = DEFAULT_PER_PAGE
  }

  return updateReturnOrderListForm(
    {
      perPage,
    },
    {stickyProps: ['perPage']},
  )
}

export function setSort(sort) {
  if (!RETURN_ORDER_SORT_OPTIONS.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  return updateReturnOrderListForm(
    {
      sort,
      currentPage: 1,
    },
    {stickyProps: ['sort']},
  )
}

export function setSearchText(searchText) {
  searchText = (searchText || '').trim()

  return updateReturnOrderListForm({
    searchText,
    currentPage: 1,
  })
}

export function setTags(tags) {
  tags = tags || []

  if (!Array.isArray(tags)) {
    tags = [tags]
  }

  return updateReturnOrderListForm({
    tags: uniq(tags),
    currentPage: 1,
  })
}

export function setUntagged(untagged) {
  return updateReturnOrderListForm({
    untagged: !!untagged,
    currentPage: 1,
  })
}

export function setTagFilterBy(tagFilterBy) {
  if (!TAG_FILTER_OPTIONS.find(({value}) => tagFilterBy === value)) {
    tagFilterBy = TAG_FILTER_OR
  }

  return updateReturnOrderListForm({
    tagFilterBy,
    currentPage: 1,
  })
}

export function setCurrentPageNumber(currentPage) {
  currentPage = Number(currentPage) || 1

  return updateReturnOrderListForm({
    currentPage,
  })
}

export function setExpandedReferenceIDs(expandedReferenceIDs) {
  return updateReturnOrderListForm({
    expandedReferenceIDs,
  })
}

export function returnOrderListToggleExpanded(referenceID) {
  return {
    type: RETURN_ORDER_LIST_TOGGLE_EXPANDED,
    payload: {
      referenceID,
    },
  }
}

export function setExpandMode(expandMode) {
  return updateReturnOrderListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

export function returnOrderListSelectReferenceID(
  referenceID,
  isSelected,
  isShiftKey,
) {
  return {
    type: RETURN_ORDER_LIST_SELECT_REFERENCE_ID,
    payload: {
      referenceID,
      isSelected,
      isShiftKey,
    },
  }
}

export function setSelectedReferenceIDs(selectedReferenceIDs) {
  return updateReturnOrderListForm({
    selectedReferenceIDs,
  })
}

export function deselectReturnOrders(referenceIDs) {
  return {
    type: DESELECT_RETURN_ORDERS,
    payload: {
      referenceIDs,
    },
  }
}

export function refreshReturnOrderList() {
  return {
    type: REFRESH_RETURN_ORDER_LIST,
  }
}

export function createAndApplyTagToReturnOrders(referenceIDs) {
  showEditTagModal(TAG_TYPE__RETURN_ORDER, null, {
    addTagToReferenceIDs: referenceIDs,
  })
}
