import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SelectOptionsShape, SelectOptionTokens} from '../../common/PropTypes.js'
import {TAG_TYPE__MO} from '../../common/constants/Tags.js'
import {
  addTagToMOs,
  removeTagFromMOs,
  moTagOptionsSelector,
  moTagOptionTokensSelector,
} from '../../data/moTags.js'
import {
  hasMOManageTagPermissionSelector,
  hasMOManageTagOnMOPermissionSelector,
} from '../../data/me.js'
import ManageTagFilter from '../../common/components/ManageTagFilter.js'
import {createAndApplyTagToMOs} from './moListActions.js'
import {createMOTagQuantityMapSelector} from './moListSelectors.js'

function AddMOTagsDropdown({
  referenceIDs,
  moTagOptions,
  moTagOptionTokens,
  tagQuantityMap,
  hasMOManageTagPermission,
  hasMOManageTagOnMOPermission,
}) {
  if (!hasMOManageTagOnMOPermission) {
    return null
  }

  return (
    <li className="inline-block margin-left-10">
      <ManageTagFilter
        dropdown="APPLY_MO_TAG_FILTER"
        onSelect={(tag) => {
          if (tagQuantityMap[tag.id] === 'all') {
            removeTagFromMOs(tag.id, referenceIDs)
          } else {
            addTagToMOs(tag.id, referenceIDs)
          }
        }}
        tagOptions={moTagOptions}
        tagOptionTokens={moTagOptionTokens}
        tagQuantityMap={tagQuantityMap}
        hasManageTagPermission={hasMOManageTagPermission}
        tagType={TAG_TYPE__MO}
        onCreateAndApplyClick={() => createAndApplyTagToMOs(referenceIDs)}
      />
    </li>
  )
}

AddMOTagsDropdown.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  moTagOptions: SelectOptionsShape.isRequired,
  moTagOptionTokens: SelectOptionTokens.isRequired,
  tagQuantityMap: PropTypes.object.isRequired,
  hasMOManageTagPermission: PropTypes.bool.isRequired,
  hasMOManageTagOnMOPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {referenceIDs}) {
  const moTagQuantityMapSelector = createMOTagQuantityMapSelector()

  return {
    moTagOptions: moTagOptionsSelector(state),
    moTagOptionTokens: moTagOptionTokensSelector(state),
    tagQuantityMap: moTagQuantityMapSelector(state, {referenceIDs}),
    hasMOManageTagPermission: hasMOManageTagPermissionSelector(state),
    hasMOManageTagOnMOPermission: hasMOManageTagOnMOPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(AddMOTagsDropdown)
