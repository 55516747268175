import PropTypes from 'prop-types'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'

import {RateShape, BulkRateShape} from '../../../../common/PropTypes.js'
import {formatCurrency, isTBD} from '../../../../common/components/Currency.js'

export function formatRate(rate, currencySymbol) {
  const message = [rate.service]

  if (rate.estimated_delivery_date) {
    let date = parseISO(rate.estimated_delivery_date)

    // for dumb dates from Endicia: month/day/year
    if (!isValid(date)) {
      date = parse(rate.estimated_delivery_date, 'M/d/yyyy', new Date())
    }

    if (isValid(date)) {
      message.push(`(ETA ${format(date, 'MMM d')})`)
    }
  }

  if (rate.estimated_delivery_days) {
    const days = rate.estimated_delivery_days
    message.push(`(ETA ${days} ${days === '1' ? 'day' : 'days'})`)
  }

  if (rate.disabled) {
    message.push('- Not available for all orders')
  } else if (isTBD(rate.cost)) {
    message.push('- TBD')
  } else {
    message.push(`- ${formatCurrency(rate.cost, currencySymbol)}`)
  }

  return message.join(' ')
}

export default function RateOption({rate, currencySymbol}) {
  return (
    <option value={rate.key} disabled={rate.disabled}>
      {formatRate(rate, currencySymbol)}
    </option>
  )
}

RateOption.propTypes = {
  rate: PropTypes.oneOfType([RateShape, BulkRateShape]).isRequired,
  currencySymbol: PropTypes.string.isRequired,
}
