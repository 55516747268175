import {useSelector} from 'react-redux'

import {orderDetailOrderNumberSelector} from '../../../../OrderDetailPage/orderDetailSelectors.js'
import {activeOrderNumbersSelector} from '../../../../OrderListPage/orderListSelectors.js'
import FilterControls from './FilterControls.js'
import OrderDetailControls from './OrderDetailControls.js'
import OrderListControls from './OrderListControls.js'

export default function ListHeader() {
  const activeOrderNumbers = useSelector(activeOrderNumbersSelector)
  const isDetailView = !!useSelector(orderDetailOrderNumberSelector)

  return (
    <div>
      <div className="wrap--list-header wrap--list-header-orders flex--justify">
        {activeOrderNumbers.length !== 0 ? (
          <div>
            {isDetailView ? <OrderDetailControls /> : <OrderListControls />}
          </div>
        ) : (
          <FilterControls />
        )}
      </div>
    </div>
  )
}
