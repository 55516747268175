import PropTypes from 'prop-types'
import {useRef, forwardRef} from 'react'

import cn from '../className.js'
import {PRIVACY_MASK} from '../analytics/index.js'
import useAutoFocus from '../hooks/useAutoFocus.js'

const TextInput = forwardRef(
  (
    {
      errorMessage,
      id,
      label,
      labelClassName,
      onChange,
      type,
      fsExclude,
      className,
      autoFocus,
      required,
      prefix,
      prefixClassName,
      ...props
    },
    inputRef,
  ) => {
    const backupInputRef = useRef()
    inputRef = inputRef || backupInputRef

    useAutoFocus(inputRef, autoFocus)

    const input = (
      <input
        ref={inputRef}
        className={cn`${className} ${fsExclude && PRIVACY_MASK} ${
          errorMessage && 'input--error'
        } ${prefix && 'input--w-prefix'}`}
        id={id}
        type={type || 'text'}
        onChange={(event) => onChange(event.target.value, event)}
        {...props}
      />
    )

    return (
      <>
        {label && id && (
          <label
            className={cn`${labelClassName} ${errorMessage && 'error'}`}
            htmlFor={id}
          >
            <span>{label}</span>
            {required ? <span className="required">*</span> : null}
          </label>
        )}
        {prefix ? (
          <div className="flex">
            <div
              className={cn`wrap--input-prefix flex--justify-col ${prefixClassName}`}
            >
              <strong className="fs-n1 text--md-grey">{prefix}</strong>
            </div>
            {input}
          </div>
        ) : (
          input
        )}
        {errorMessage && (
          <small className="error error-message">{errorMessage}</small>
        )}
      </>
    )
  },
)

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  fsExclude: PropTypes.bool,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  prefix: PropTypes.node,
  prefixClassName: PropTypes.string,
}

export default TextInput
