import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'
import {
  ADDRESS_STATUS_DISPLAYS,
  ERROR_ADDRESS_STATUS,
  VALIDATED_ADDRESS_STATUS,
  WARNING_ADDRESS_STATUS,
} from './AddressTypes.js'

export const AWAITING_FULFILLMENT = 'awaiting_fulfillment'
export const SHIPPED = 'shipped'
export const DROPSHIPMENT_REQUESTED = 'dropshipment_requested'
export const CANCELLED = 'cancelled'
export const ARCHIVED = 'archived'
export const MERGED = 'merged'

export const ORDER_STATII = [
  {
    name: 'Awaiting Fulfillment',
    slug: AWAITING_FULFILLMENT,
    symbol: 'AF',
  },
  {
    name: 'Shipped',
    slug: SHIPPED,
    symbol: 'S',
  },
  {
    name: 'Dropshipment Requested',
    slug: DROPSHIPMENT_REQUESTED,
    symbol: 'DS',
  },
  {
    name: 'Cancelled',
    slug: CANCELLED,
    symbol: 'C',
  },
  {
    name: 'Archived',
    slug: ARCHIVED,
    symbol: 'AR',
  },
  {
    name: 'Merged',
    slug: MERGED,
    symbol: 'M',
  },
]

export const ORDER_STATUS_BY_SLUG = ORDER_STATII.reduce((bySlug, status) => {
  bySlug[status.slug] = status

  return bySlug
}, {})

const ORDER_PLACED_DATE_SORT = 'order_placed_date'
const SHIP_DATE_SORT = 'ship_date'
const DROPSHIP_DATE_SORT = 'dropship_date'
const GRAND_TOTAL_SORT = 'grand_total'
const ORDER_NUMBER_SORT = 'order_number'
const STATUS_SORT = 'status'
const SKU_SORT = 'sku'
const SHIPPING_METHOD_SORT = 'shipping_method'
const SHIPPING_ADDRESS_NAME_SORT = 'shipping_address_name'
const SHIPPING_ADDRESS_ZIP_SORT = 'shipping_address_zip'
const BILLING_ADDRESS_NAME_SORT = 'billing_address_name'
const BILLING_ADDRESS_ZIP_SORT = 'billing_address_zip'
export const BATCH_SORT = 'batch'

const DELIVER_BY_DATE = 'deliver_by_date'
const SHIP_BY_DATE = 'ship_by_date'

export const ORDER_SORT_OPTIONS = [
  {
    display: 'Order Date (Newest)',
    value: `-${ORDER_PLACED_DATE_SORT}`,
  },
  {
    display: 'Order Date (Oldest)',
    value: ORDER_PLACED_DATE_SORT,
  },
  {
    display: 'Ship Date (Newest)',
    value: `-${SHIP_DATE_SORT}`,
  },
  {
    display: 'Ship Date (Oldest)',
    value: SHIP_DATE_SORT,
  },
  {
    display: 'Dropship Date (Newest)',
    value: `-${DROPSHIP_DATE_SORT}`,
  },
  {
    display: 'Dropship Date (Oldest)',
    value: DROPSHIP_DATE_SORT,
  },
  {
    display: 'Order Value (Highest)',
    value: `-${GRAND_TOTAL_SORT}`,
  },
  {
    display: 'Order Value (Lowest)',
    value: GRAND_TOTAL_SORT,
  },
  {
    display: 'Order ID (A→Z)',
    value: ORDER_NUMBER_SORT,
  },
  {
    display: 'Order ID (Z→A)',
    value: `-${ORDER_NUMBER_SORT}`,
  },
  {
    display: 'Order Status (A→Z)',
    value: STATUS_SORT,
  },
  {
    display: 'Order Status (Z→A)',
    value: `-${STATUS_SORT}`,
  },
  {
    display: 'Single Item Order SKU (A→Z)',
    value: SKU_SORT,
  },
  {
    display: 'Single Item Order SKU (Z→A)',
    value: `-${SKU_SORT}`,
  },
  {
    display: 'Requested Shipping Method (A→Z)',
    value: SHIPPING_METHOD_SORT,
  },
  {
    display: 'Requested Shipping Method (Z→A)',
    value: `-${SHIPPING_METHOD_SORT}`,
  },
  {
    display: 'Shipping Address Name (A→Z)',
    value: SHIPPING_ADDRESS_NAME_SORT,
  },
  {
    display: 'Shipping Address Name (Z→A)',
    value: `-${SHIPPING_ADDRESS_NAME_SORT}`,
  },
  {
    display: 'Shipping Address Zipcode (A→Z)',
    value: SHIPPING_ADDRESS_ZIP_SORT,
  },
  {
    display: 'Shipping Address Zipcode (Z→A)',
    value: `-${SHIPPING_ADDRESS_ZIP_SORT}`,
  },
  {
    display: 'Billing Address Name (A→Z)',
    value: BILLING_ADDRESS_NAME_SORT,
  },
  {
    display: 'Billing Address Name (Z→A)',
    value: `-${BILLING_ADDRESS_NAME_SORT}`,
  },
  {
    display: 'Billing Address Zipcode (A→Z)',
    value: BILLING_ADDRESS_ZIP_SORT,
  },
  {
    display: 'Billing Address Zipcode (Z→A)',
    value: `-${BILLING_ADDRESS_ZIP_SORT}`,
  },
]

export const DELIVER_BY_DATE_SORT_OPTIONS = [
  {
    display: 'Deliver By Date (Newest)',
    value: `-${DELIVER_BY_DATE}`,
  },
  {
    display: 'Deliver By Date (Oldest)',
    value: DELIVER_BY_DATE,
  },
  {
    display: 'Ship By Date (Newest)',
    value: `-${SHIP_BY_DATE}`,
  },
  {
    display: 'Ship By Date (Oldest)',
    value: SHIP_BY_DATE,
  },
]

export const BATCH_SORT_OPTIONS = [
  {
    display: 'Batch',
    value: BATCH_SORT,
  },
  {
    display: 'Batch (Reversed)',
    value: `-${BATCH_SORT}`,
  },
]

export const ALL_ORDER_SORT_OPTIONS = [
  ...ORDER_SORT_OPTIONS,
  ...DELIVER_BY_DATE_SORT_OPTIONS,
  ...BATCH_SORT_OPTIONS,
]

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_STATUS = AWAITING_FULFILLMENT
export const DEFAULT_SORT = `-${ORDER_PLACED_DATE_SORT}`
export const DEFAULT_PER_PAGE = 10

export const UNALLOCATED = 'unallocated'
export const COULD_NOT_ALLOCATE = 'could_not_allocate'
export const ALLOCATED = 'allocated'
export const PARTIAL = 'partial'
export const OVERALLOCATED = 'overallocated'

export const ORDER_ALLOCATION_STATII = [
  {
    name: 'Unallocated',
    slug: UNALLOCATED,
  },
  {
    name: 'Could Not Allocate',
    slug: COULD_NOT_ALLOCATE,
  },
  {
    name: 'Allocated',
    slug: ALLOCATED,
  },
  {
    name: 'Partially Allocated',
    slug: PARTIAL,
  },
  {
    name: 'Overallocated',
    slug: OVERALLOCATED,
  },
]

export const ORDER_ALLOCATION_STATUS_BY_SLUG = ORDER_ALLOCATION_STATII.reduce(
  (bySlug, status) => {
    bySlug[status.slug] = status

    return bySlug
  },
  {},
)

export const SHIP_TO_ADDRESS_VALIDATION_OPTIONS = [
  {value: '', display: 'All'},
  {
    value: VALIDATED_ADDRESS_STATUS,
    display: ADDRESS_STATUS_DISPLAYS[VALIDATED_ADDRESS_STATUS],
  },
  {
    value: WARNING_ADDRESS_STATUS,
    display: ADDRESS_STATUS_DISPLAYS[WARNING_ADDRESS_STATUS],
  },
  {
    value: ERROR_ADDRESS_STATUS,
    display: ADDRESS_STATUS_DISPLAYS[ERROR_ADDRESS_STATUS],
  },
]

export const ORDER_PLURAL_URI_COMPONENT = 'orders'
export const ORDER_SINGLE_URI_COMPONENT = 'order'
