import PropTypes from 'prop-types'
import {useRef, useEffect, useMemo} from 'react'
import snakeCase from 'lodash/snakeCase.js'

import {updateForm, useAutoForm, useSelector} from '../../store.js'
import Zropdown, {useZropdown, useZropdownElement} from './Zropdown.js'
import {SelectOptionShape, SelectOptionsShape} from '../PropTypes.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import className from '../className.js'
import useStable from '../useStable.js'
import {
  Count,
  IfPluralNotZero,
  IfSingle,
  IfZero,
  PluralBlock,
} from './Plural.js'

export const zelectOptionComponentShape = {
  option: SelectOptionShape.isRequired,
  index: PropTypes.number.isRequired,
  form: PropTypes.shape({
    selectedIndex: PropTypes.number,
    results: PropTypes.array.isRequired,
  }).isRequired,
  values: PropTypes.array.isRequired,
  onSelectInternal: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export function DefaultListItem({
  option,
  index,
  form: {selectedIndex},
  values,
  onSelectInternal,
  children,
}) {
  const ref = useRef()
  const isSelected = index === selectedIndex
  useZropdownElement({ref, isSelected})

  return (
    <li ref={ref} className="list__item list__item--dropdown">
      <button
        className={`btn--link list__link--dropdown ${
          values.includes(option.value) ? 'selected' : ''
        } ${isSelected ? 'list__link--dropdown-arrow-focus' : ''}`}
        type="button"
        onClick={() => onSelectInternal(option)}
      >
        {children || option.display}
      </button>
    </li>
  )
}

DefaultListItem.propTypes = zelectOptionComponentShape

export function CheckboxListItem({
  option,
  index,
  form: {selectedIndex},
  values,
  onSelectInternal,
}) {
  const ref = useRef()
  const isSelected = index === selectedIndex
  useZropdownElement({ref, isSelected})

  return (
    <li
      ref={ref}
      className="list__item list__item--dropdown"
      key={option.value}
    >
      <label
        className={`label--selectable label--sm flex margin-bottom-0 ${
          isSelected ? 'list__link--dropdown-arrow-focus' : ''
        }`}
        data-dropdown-prevent-close
      >
        <span>
          <input
            className="fs-00"
            type="checkbox"
            checked={values.includes(option.value)}
            onChange={() => onSelectInternal(option)}
          />
        </span>
        <span className="margin-left-5">{option.display}</span>
      </label>
    </li>
  )
}

CheckboxListItem.propTypes = zelectOptionComponentShape

export default function Zelect({
  options,
  required,
  errorMessage,
  id,
  label,
  value,
  onChange,
  header,
  OptionComponent,
  IfNotSelected,
  IfSingleSelected,
  IfMultipleSelected,
  footer,
  addEmptyOption,
  placeholder = 'Select an option',
  disabled,
}) {
  id = id || (label ? snakeCase(label) : 'NOT_DEFINED')
  const dropdown = `SELECT__${id.toUpperCase()}`

  options = useStable(options)
  options = useMemo(
    () => (addEmptyOption ? [{value: '', display: ''}, ...options] : options),
    [addEmptyOption, options],
  )

  value = useStable(value)
  const isMultiSelect = Array.isArray(value)

  const values = useMemo(
    () => (isMultiSelect ? value : [value]),
    [isMultiSelect, value],
  )

  const chosenOptions = useMemo(
    () => options.filter((option) => values.includes(option.value)),
    [options, values],
  )

  const form = useAutoForm(dropdown, {
    results: [],
    selectedIndex: 0,
  })

  useEffect(() => {
    const firstSelectedOptionIndex = options.indexOf(chosenOptions[0])

    updateForm(dropdown, {
      results: options,
      selectedIndex:
        firstSelectedOptionIndex !== -1 ? firstSelectedOptionIndex : 0,
    })
  }, [options, values, chosenOptions])

  const buttonRef = useRef()

  const {onConfirm, onSelectInternal, onUp, onDown} = useZropdown({
    dropdown,
    form,
    onSelect: onChange,
    closeOnConfirm: !isMultiSelect,
  })
  const currentDropdown = useSelector(currentDropdownSelector)

  OptionComponent = OptionComponent || DefaultListItem
  IfNotSelected = IfNotSelected || (() => <em>{placeholder}</em>)
  IfSingleSelected = IfSingleSelected || (() => chosenOptions[0].display)
  IfMultipleSelected =
    IfMultipleSelected ||
    (() => (
      <>
        <Count /> Selected
      </>
    ))

  return (
    <>
      <label className="label--bold" htmlFor={dropdown}>
        <span>{label}</span>
        {required && <span className="required">*</span>}
      </label>
      <div
        className={`wrap-outer--tag-dropdown-with-filter ${
          currentDropdown === dropdown ? 'open' : ''
        }`}
        data-dropdown={dropdown}
      >
        <button
          id={dropdown}
          ref={buttonRef}
          className="btn btn--dropdown btn--filter btn--filter-w-border w-100"
          type="button"
          disabled={disabled}
        >
          <span
            className={className`btn__text ${{
              'error-message': errorMessage,
            }}`}
          >
            <PluralBlock array={chosenOptions}>
              <IfZero>
                <IfNotSelected />
              </IfZero>
              <IfSingle>
                <IfSingleSelected />
              </IfSingle>
              <IfPluralNotZero>
                <IfMultipleSelected />
              </IfPluralNotZero>
            </PluralBlock>
          </span>
        </button>
        <Zropdown
          dropdown={dropdown}
          parentRef={buttonRef}
          onConfirm={onConfirm}
          onUp={onUp}
          onDown={onDown}
          header={header}
          footer={footer}
          blurOnOpen
        >
          {form.results.map((option, index) => (
            <OptionComponent
              key={option.value}
              option={option}
              index={index}
              form={form}
              values={values}
              onSelectInternal={onSelectInternal}
            />
          ))}
        </Zropdown>
      </div>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

Zelect.propTypes = {
  options: SelectOptionsShape.isRequired,
  buttonClassName: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  buttonContents: PropTypes.node,
  header: PropTypes.node,
  OptionComponent: PropTypes.func,
  IfNotSelected: PropTypes.func,
  IfSingleSelected: PropTypes.func,
  IfMultipleSelected: PropTypes.func,
  footer: PropTypes.node,
  noneSelectedText: PropTypes.string,
  isSingleSelect: PropTypes.bool,
  addEmptyOption: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}
