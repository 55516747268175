import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase.js'

import NoPermissionsPage from '../NoPermissionsPage.js'

export default function SettingsPanel({
  header,
  subHeader,
  hasPermission = true,
  children,
}) {
  return (
    <div className={`meta-${kebabCase(header)}`}>
      <div className="row margin-bottom-25">
        <div className="medium-12 columns">
          <h2 className="lh-md margin-bottom-10">{header}</h2>
          {subHeader && <div className="fs-01">{subHeader}</div>}
        </div>
      </div>
      {hasPermission ? children : <NoPermissionsPage />}
    </div>
  )
}

SettingsPanel.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.node,
  hasPermission: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
