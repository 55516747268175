import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  formsSelector,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import {LabelInfoIDShape, LabelInfoIDsShape} from '../../../common/PropTypes.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import LabelViewForm from '../../iverson/components/LabelViewForm/index.js'
import {
  commonLabelTypeFromLabelConfigsSelector,
  orderNumberFromLabelConfigSelector,
  orderNumbersFromLabelConfigsSelector,
} from '../../../data/labelInfos/index.js'
import {
  orderNumbersWithReturnLabelsSelector,
  orderNumbersWithShippingLabelsSelector,
} from '../../../data/orders.js'
import {
  bulkErrorsWithLabelInfoIDSelector,
  bulkLabelsLoadingSelector,
  labelsLoadedCountSelector,
} from '../../LabelConfig/BulkLabelConfigForm/bulkLabelConfigSelectors.js'
import {plural} from '../../../common/components/Plural.js'
import useStable from '../../../common/useStable.js'

export const MODAL_FORM = 'PROCESS_LABEL_MODAL'

export function showProcessLabelModal({labelInfoIDs}) {
  setForm(MODAL_FORM, {
    labelInfoIDs,
    allErrors: [], // we want to cache errors
    showAbodeForm: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function ErrorMessage({labelInfoID, errors}) {
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )

  return (
    <dd className="margin-bottom-7">
      <div>
        <strong className="fs-n1 margin-right-5 ">{orderNumber}</strong>
      </div>
      <ul className="list square">
        {errors.map((err, i) => (
          <li className="list__item--error fs-n1 lh-md margin-bottom-5" key={i}>
            {err}
          </li>
        ))}
      </ul>
    </dd>
  )
}

ErrorMessage.propTypes = {
  labelInfoID: LabelInfoIDShape.isRequired,
  errors: PropTypes.array.isRequired,
}

function ProcessLabelModal({form}) {
  const {labelInfoIDs} = form
  const labelType = useSelector((state) =>
    commonLabelTypeFromLabelConfigsSelector(state, {
      labelInfoIDs,
    }),
  )
  const orderNumbers = useSelector((state) =>
    orderNumbersFromLabelConfigsSelector(state, {
      labelInfoIDs,
    }),
  )

  const orderNumbersWithLabels = useSelector((state) =>
    labelType === 'return'
      ? orderNumbersWithReturnLabelsSelector(state, {orderNumbers})
      : orderNumbersWithShippingLabelsSelector(state, {orderNumbers}),
  )
  const labelsLoadedCount = useSelector((state) =>
    labelsLoadedCountSelector(state, {labelInfoIDs}),
  )
  const labelsLoading = useSelector((state) =>
    bulkLabelsLoadingSelector(state, {labelInfoIDs}),
  )
  const allErrors = useStable(
    useSelector((state) =>
      bulkErrorsWithLabelInfoIDSelector(state, {labelInfoIDs}),
    ),
  )

  useEffect(() => {
    if (labelsLoading || form.allErrors.length) {
      return
    }

    // only set allErrors after loading has finished
    // and only set it once
    // cache errors because they might reset if order loads behind this modal
    // we would much rather show stale errors then have them disappear before
    // the user can read them
    updateModalForm({allErrors})
  }, [labelsLoading, allErrors])

  const hasErrors = form.allErrors.length > 0
  const allFailed = form.allErrors.length === labelInfoIDs.length

  const canManuallyProceed =
    hasErrors && !labelsLoading && !form.showAbodeForm && !allFailed

  return (
    <ConfirmModal
      title={
        labelsLoading
          ? plural(labelInfoIDs)`Creating Label${[]}`
          : plural(orderNumbersWithLabels)`Print Label${[]}`
      }
      onConfirm={
        canManuallyProceed ? () => updateModalForm({showAbodeForm: true}) : null
      }
      confirmText="Next"
      onCancel={labelsLoading || canManuallyProceed ? null : closeModal}
      cancelText={
        (!hasErrors || form.showAbodeForm) && !labelsLoading
          ? 'Print Later'
          : 'Close'
      }
      isSaving={labelsLoading}
      noFooter={labelsLoading}
    >
      {labelsLoading ? (
        <div className="loading align-center margin-top-40 margin-bottom-50">
          <span className="list-processing animate-spin v-align-middle" />
          {labelInfoIDs.length > 1 && (
            <strong className="inline-block v-align-middle fs-02 op-75 margin-left-10">
              {labelsLoadedCount} of {labelInfoIDs.length}
            </strong>
          )}
        </div>
      ) : !hasErrors || form.showAbodeForm ? (
        <LabelViewForm
          orderNumbers={orderNumbersWithLabels}
          labelType={labelType}
          showSingleOrderNumber
          fromModal
        />
      ) : hasErrors ? (
        <div>
          <div className="margin-bottom-10">
            The following orders had some issues:
          </div>
          {form.allErrors.map(([labelInfoID, errors]) => (
            <ErrorMessage
              labelInfoID={labelInfoID}
              key={labelInfoID}
              errors={errors}
            />
          ))}
        </div>
      ) : null}
    </ConfirmModal>
  )
}

ProcessLabelModal.propTypes = {
  form: PropTypes.shape({
    labelInfoIDs: LabelInfoIDsShape.isRequired,
    showAbodeForm: PropTypes.bool.isRequired,
    allErrors: PropTypes.array.isRequired,
  }).isRequired,
}

export default onlyIfForm(ProcessLabelModal, modalFormSelector)
