import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  getState,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {navigate} from '../../../common/location.js'
import {
  EDIT,
  SALES_CHANNELS,
  SETTINGS,
} from '../../../common/constants/SettingsPanels.js'
import {cartsWithMultiLocationsSelector} from '../../../data/carts.js'
import {SHOPIFY} from '../../../common/constants/CartVendorOptions.js'
import {showEditCartLocationWarehousesModal} from './EditCartLocationWarehousesModal.js'

export const MODAL_FORM = 'WAREHOUSE_CART_LOCATION_NOTICE_MODAL'

export async function showWarehouseCartLocationNoticeModal() {
  const carts = cartsWithMultiLocationsSelector(getState())

  const justShopifyCarts = carts.filter(({vendor}) => vendor === SHOPIFY)

  if (justShopifyCarts.length === 0) {
    return
  }

  if (justShopifyCarts.length === 1) {
    showEditCartLocationWarehousesModal({
      cartID: justShopifyCarts[0].id,
      isNotice: true,
    })
  } else {
    setForm(MODAL_FORM, {})
  }
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

function navigateToFirsShopifyCart() {
  const carts = cartsWithMultiLocationsSelector(getState())

  const justShopifyCarts = carts.filter(({vendor}) => vendor === SHOPIFY)

  navigate([SETTINGS, SALES_CHANNELS, justShopifyCarts[0].id, EDIT])
}

function WarehouseCartLocationNoticeModal() {
  return (
    <ConfirmModal
      title="Review Shopify Location Mappings"
      modalSize=""
      confirmText="Start Reviewing"
      onConfirm={() => navigateToFirsShopifyCart()}
      cancelText="Close Window"
      onCancel={() => closeModal()}
    >
      <div className="alert alert--warning alert--lg align-center margin-bottom-0">
        <p className="fs-01 lh-md margin-bottom-0">
          To ensure that your connections to Shopify continue to work correctly,
          we strongly suggest that you take a minute to double-check your Ordoro
          warehouse and Shopify Location mappings.
        </p>
      </div>
    </ConfirmModal>
  )
}

export default onlyIfForm(WarehouseCartLocationNoticeModal, modalFormSelector)
