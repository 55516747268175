import PropTypes from 'prop-types'
import {components} from 'react-select'
import CreatableSelect from 'react-select/creatable'

import {tagColors} from '../../colors.js'
import {hasClass} from '../../classNames.js'

export function parseInputValue(inputValue) {
  inputValue = inputValue || ''

  let [, filterType = '', filterOperator = '', filterValue] = inputValue.match(
    /^(?:(.*)?:(!=|[!=><-])?)?(.*)$/,
  )

  filterType = filterType.trim()
  filterValue = filterValue.trim()

  return [inputValue, filterType, filterOperator, filterValue]
}

export default function OmniBar({
  placeholder,
  inputValue,
  value,
  onRemoveValue,
  onClear,
  onInputValueChange,
  onProcessInputValue,
  onEditValue,
}) {
  return (
    <div className="flex flex-grow-2">
      <CreatableSelect
        className="omni-bar-container flex-grow-2"
        classNamePrefix="omni-bar"
        components={{
          DropdownIndicator: null,

          MultiValueContainer: function MultiValueContainer({
            innerProps,
            ...props
          }) {
            return (
              <components.MultiValueContainer
                innerProps={{
                  ...innerProps,
                  className: `omni-bar_value-type_${props.data.type} ${
                    innerProps.className || ''
                  }`,
                  onClick: (event) => {
                    if (
                      !hasClass(
                        event.target,
                        'omni-bar__multi-value__remove',
                        'omni-bar__multi-value',
                      )
                    ) {
                      onEditValue(props.data)
                    }
                  },
                }}
                {...props}
              />
            )
          },
        }}
        styles={{
          multiValue: (styles, {data}) => {
            return {
              ...styles,
              ...(data.color
                ? tagColors(data.color)
                : {backgroundColor: '#eee'}),
            }
          },
        }}
        isMulti
        isClearable
        menuIsOpen={false}
        inputValue={inputValue}
        value={value}
        placeholder={placeholder}
        onChange={(_, {action, removedValue}) => {
          if (action === 'pop-value') {
            removedValue = value[value.length - 1]
            action = 'remove-value'
          }

          if (action === 'remove-value' && removedValue) {
            onRemoveValue(removedValue)
          } else if (action === 'clear') {
            onClear()

            onInputValueChange('')
          }
        }}
        onInputChange={(value, {action}) => {
          if (action !== 'input-blur' && action !== 'menu-close') {
            onInputValueChange(value)
          }
        }}
        onKeyDown={(event) => {
          if (['Enter', 'Tab'].includes(event.key)) {
            event.preventDefault()

            onProcessInputValue(inputValue)
          }
        }}
      />

      <button
        className="btn btn--main-search btn--omni-bar-search"
        type="submit"
        onClick={() => onProcessInputValue(inputValue)}
      >
        <span className="i-search fs-01" aria-hidden="true" />
      </button>
    </div>
  )
}

OmniBar.propTypes = {
  placeholder: PropTypes.string,
  inputValue: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  onRemoveValue: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onInputValueChange: PropTypes.func.isRequired,
  onProcessInputValue: PropTypes.func.isRequired,
  onEditValue: PropTypes.func.isRequired,
}
