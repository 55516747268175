import {useMemo} from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil.js'

import FormattedRateCost from '../../../../../../../common/components/FormattedRateCost.js'
import ButtonLink from '../../../../../../../common/components/Button/ButtonLink.js'

import {shippedShippingMethodSelector} from '../../../../../../../data/orders.js'
import {
  makeShippingMethodNameSelector,
  makeCostSelector,
  orderNumberFromLabelConfigSelector,
  labelShipperTypeSelector,
} from '../../../../../../../data/labelInfos/index.js'

import {setSelectedOrderNumbers} from '../../../../../../OrderListPage/orderListActions.js'
import {
  SHIPPING_TAB,
  setOrderActionPanelCurrentTab,
} from '../../../../../../OrderListPage/ActionPanel/orderActionPanelFunctions.js'
import {useSelector} from '../../../../../../../store.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'
import {shipperCurrencySymbolSelector} from '../../../../../../../data/shippers.js'

export default function CompactRateDetails() {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )

  const shippingMethodSelector = useMemo(
    () => makeShippingMethodNameSelector(),
    [],
  )
  const costSelector = useMemo(() => makeCostSelector(), [])

  const displayShippingMethod = useSelector((state) =>
    shippedShippingMethodSelector(state, {
      orderNumber,
    }),
  )
  const shippingMethod = useSelector(
    (state) =>
      displayShippingMethod ||
      shippingMethodSelector(state, {labelInfoID, shipperType}),
  )

  const cost = useSelector((state) => costSelector(state, {labelInfoID}))
  const shipperCurrencySymbol = useSelector((state) =>
    shipperCurrencySymbolSelector(state, {shipperType}),
  )

  const showLabelTab = () => {
    setOrderActionPanelCurrentTab(SHIPPING_TAB)
    setSelectedOrderNumbers([orderNumber])
  }

  if (!shippingMethod) {
    return (
      <div>
        <ButtonLink className="v-align-base fs-n1" onClick={showLabelTab}>
          Create a label
        </ButtonLink>
      </div>
    )
  }

  return (
    <div>
      <ButtonLink className="darker v-align-base" onClick={showLabelTab}>
        <span
          className={classNames(
            'inline-block v-align-top selected-shipper',
            `selected-shipper--${shipperType}`,
          )}
        />
        <span className="fs-n1">{shippingMethod}</span>
        {!isNil(cost) && (
          <span>
            <span className="fs-n1"> - </span>
            <span className="fs-n1">
              <FormattedRateCost
                cost={cost}
                currencySymbol={shipperCurrencySymbol}
              />
            </span>
          </span>
        )}
      </ButtonLink>
    </div>
  )
}
