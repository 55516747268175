import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import {orderSelector} from '../../../data/orders.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {buildPath} from '../../../common/querystring.js'
import Currency from '../../../common/components/Currency.js'
import apiverson from '../../../common/apiverson.js'

export const MODAL_FORM = 'PARENT_ORDER_MODAL'

export async function showParentOrderModal(childOrderNumber) {
  setForm(MODAL_FORM, {
    childOrderNumber,
    parentOrder: null,
    isLoading: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export async function loadParentOrder() {
  const {childOrderNumber} = modalFormSelector(getState())

  try {
    const childOrder = orderSelector(getState(), {
      orderNumber: childOrderNumber,
    })

    if (!childOrder) {
      closeModal()

      return
    }

    updateModalForm({isLoading: true, serverError: null})

    const {json} = await apiverson.get(
      buildPath(['order', 'parent', childOrder.parent_order_number]),
    )

    updateModalForm({parentOrder: json})
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
    })
  } finally {
    updateModalForm({
      isLoading: false,
    })
  }
}

function ParentOrderModal({form}) {
  const childOrder = useSelector((state) =>
    orderSelector(state, {orderNumber: form.childOrderNumber}),
  )

  useEffect(() => {
    loadParentOrder()
  }, [form.childOrderNumber])

  if (!childOrder) {
    return null
  }

  return (
    <ConfirmModal
      title="Parent Order Contents"
      modalSize="md"
      onCancel={() => closeModal()}
      cancelText="Close"
      error={form.serverError}
    >
      <p>
        Order <strong>{form.childOrderNumber}</strong> was split from parent
        order <strong>{childOrder.parent_order_number}</strong>.
      </p>
      {form.isLoading ? (
        <div>
          <span className="list-processing animate-spin v-align-middle" />
        </div>
      ) : form.parentOrder ? (
        <table className="table">
          <thead>
            <tr>
              <th className="table__th th-width-30">Product Name/SKU</th>
              <th className="table__th th-width-20">Additional Details</th>
              <th className="table__th th-width-15 align-right">Qty</th>
              <th className="table__th th-width-15 align-right">Item Price</th>
              <th className="table__th th-width-20 align-right">Total Price</th>
            </tr>
          </thead>
          <tbody className="table__tbody--lines">
            {form.parentOrder.lines.map((line, index) => (
              <tr key={index}>
                <td className="table__td">
                  <dl className="list">
                    <dt className="list__title fs-00 lh-md">
                      {line.product_name}
                    </dt>
                    <dd className="list__item fs-n1 lh-md margin-bottom-0">
                      {line.sku}
                    </dd>
                  </dl>
                </td>
                <td className="table__td fs-n0">
                  <em className="block">{line.details}</em>
                </td>
                <td className="table__td fs-n0 align-right">{line.quantity}</td>
                <td className="table__td fs-n0 align-right">
                  <Currency value={line.item_price} />
                </td>
                <td className="table__td fs-n0 align-right">
                  <Currency value={line.total_price} />
                </td>
              </tr>
            ))}
          </tbody>
          <tbody className="table__tbody table__tbody--totals">
            <tr>
              <td className="table__td fs-n1 align-right" colSpan="4">
                <strong>Product Total</strong>
              </td>
              <td className="table__td fs-00 align-right" colSpan="1">
                <strong>
                  <Currency value={form.parentOrder.financial.product_amount} />
                </strong>
              </td>
            </tr>
            <tr>
              <td
                className="table__td fs-n1 align-right v-align-middle"
                colSpan="4"
              >
                <strong>Discount</strong>
              </td>
              <td className="table__td fs-n1 align-right" colSpan="1">
                <span>
                  <Currency
                    value={form.parentOrder.financial.discount_amount}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td
                className="table__td fs-n1 align-right v-align-middle"
                colSpan="4"
              >
                <strong>Shipping and Handling</strong>
              </td>
              <td className="table__td fs-n1 align-right" colSpan="1">
                <span>
                  <Currency
                    value={form.parentOrder.financial.shipping_amount}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td
                className="table__td fs-n1 align-right v-align-middle"
                colSpan="4"
              >
                <strong>Tax</strong>
              </td>
              <td className="table__td fs-n1 align-right" colSpan="1">
                <span>
                  <Currency value={form.parentOrder.financial.tax_amount} />
                </span>
              </td>
            </tr>
            <tr>
              <td
                className="table__td table__td--grand-total fs-n1 align-right"
                colSpan="4"
              >
                <strong>Grand Total</strong>
              </td>
              <td
                className="table__td table__td--grand-total fs-01 align-right"
                colSpan="1"
              >
                <strong>
                  <Currency value={form.parentOrder.financial.grand_total} />
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </ConfirmModal>
  )
}

ParentOrderModal.propTypes = {
  form: PropTypes.shape({
    childOrderNumber: PropTypes.string.isRequired,
    parentOrder: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ParentOrderModal, modalFormSelector)
