import PropTypes from 'prop-types'
import {useCallback, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce.js'

import {HK_CONFIRM} from '../../../common/constants/HotKeys.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import BarcodeScannerListener from '../../../common/components/BarcodeScannerListener.js'
import AudioFile from '../../../common/components/AudioFile.js'
import errorSoundFilePath from '../../../../images/error-01.mp3'
import Content from '../../../common/components/Modal/BaseModal/Content.js'
import {acknowledgeError} from './packPanelActions.js'

const SCAN_TIMEOUT = 100
const debouncedAcknowledgeError = debounce(() => {
  acknowledgeError()
}, SCAN_TIMEOUT)

export default function PackError({children}) {
  const [animateError, setAnimateError] = useState(false)
  const refs = useRef({
    animateErrorTimeout: null,
  })

  useEffect(() => {
    let blockAcknowledgeKeys = false
    let blockAcknowledgeTimeout = null
    function handleKeyPress(e) {
      if ([' ', 'Enter'].includes(e.key) && !blockAcknowledgeKeys) {
        debouncedAcknowledgeError()
      } else {
        debouncedAcknowledgeError.cancel()

        clearTimeout(blockAcknowledgeTimeout)

        blockAcknowledgeKeys = true

        blockAcknowledgeTimeout = setTimeout(() => {
          blockAcknowledgeKeys = false
        }, SCAN_TIMEOUT)
      }
    }

    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)
      clearTimeout(refs.current.animateErrorTimeout)
      clearTimeout(blockAcknowledgeTimeout)
    }
  }, [])

  const startErrorAnimation = useCallback(() => {
    setTimeout(() => {
      setAnimateError(false)

      setTimeout(() => {
        setAnimateError(true)
      }, 0)

      clearTimeout(refs.current.animateErrorTimeout)
      refs.current.animateErrorTimeout = setTimeout(() => {
        setAnimateError(false)
      }, 1000)
    }, 0)
  })

  return (
    <Content>
      {animateError && (
        <AudioFile key="animateSound" src={errorSoundFilePath} />
      )}
      <AudioFile key="initialSound" src={errorSoundFilePath} />
      <div className="wrap--scan-alert meta-verify-order-error">
        <BarcodeScannerListener
          onScan={() => startErrorAnimation()}
          capturePaste
        />
        <div className="wrap--scan-alert">
          <div
            className={classNames(
              'alert alert--error alert--lg full-border align-center margin-bottom-0',
              {shake: animateError},
            )}
          >
            <div
              className="i-exclamation-triangle fs-04 op-30 lh-sm margin-bottom-5"
              aria-hidden="true"
            />
            <div className="fs-01 error margin-bottom-5">{children}</div>
            <ButtonPrimary
              className="meta-verify-order-acknowledge-error-button"
              isOutlined
              onClick={() => debouncedAcknowledgeError()}
              hotKey={HK_CONFIRM}
            >
              Continue
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </Content>
  )
}

PackError.propTypes = {
  children: PropTypes.node.isRequired,
}
