import {useEffect} from 'react'

import {onlyIfForm, useSelector} from '../../store.js'
import {
  createOrderFormSelector,
  cloneFromOrderNumberSelector,
  submitDisabledSelector,
} from './createOrderSelectors.js'
import {CreateOrderPageFormShape, createOrder} from './createOrderActions.js'
import {orderListPageURLSelector} from '../OrderDetailPage/orderDetailSelectors.js'
import {useSetupIfNeedsProduct} from '../../data/products.js'

import OrderInfo from './OrderInfo/index.js'
import ProductInfo from './ProductInfo/index.js'
import ShippingInfo from './ShippingInfo.js'
import BillingInfo from './BillingInfo.js'
import {hasOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'

function CreateOrderPage({form}) {
  const cloneFromOrderNumber = useSelector(cloneFromOrderNumberSelector)
  const isSubmitDisabled = useSelector(submitDisabledSelector)
  const orderListPageURL = useSelector(orderListPageURLSelector)
  const hasOrderPermission = useSelector(hasOrderPermissionSelector)

  useEffect(() => {
    document.title = cloneFromOrderNumber ? 'Clone Order' : 'Create Order'
  }, [cloneFromOrderNumber])

  useSetupIfNeedsProduct({addToCreateOrder: true})

  if (!hasOrderPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page" className="wrap--container">
      <div className="wrap--row margin-top-10">
        <div className="medium-12 columns">
          <p className="margin-bottom-10">
            <a
              href={orderListPageURL}
              className="btn btn--action"
              title="Back to all orders"
            >
              &#8592; Orders
            </a>
          </p>
          <h2 className="page__title page__title--inner margin-bottom-25">
            {cloneFromOrderNumber ? 'Clone' : 'Create'} an Order
          </h2>
        </div>
      </div>
      <div className="wrap--row">
        <OrderInfo />
        <ShippingInfo />
        <BillingInfo />
      </div>
      <div className="wrap--row">
        <ProductInfo />
      </div>
      <div className="wrap--row margin-bottom-20">
        <div className="medium-12 columns">
          <ButtonPrimary
            className="margin-bottom-20"
            type="submit"
            onClick={createOrder}
            isDisabled={isSubmitDisabled}
            isLoading={form.isCreatingOrder}
          >
            {cloneFromOrderNumber ? 'Clone' : 'Create'} Order
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

CreateOrderPage.propTypes = {
  form: CreateOrderPageFormShape.isRequired,
}

export default onlyIfForm(CreateOrderPage, createOrderFormSelector)
