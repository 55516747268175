import keyBy from 'lodash/keyBy.js'
import {takeEvery, put, call} from 'redux-saga/effects'

import {fetchAllAPI} from '../../../common/fetchAll.js'
import {setGlobalError} from '../../actions/ui/index.js'
import {updateHasLoaded} from '../../actions/data/hasLoaded.js'
import {
  FETCH_PACKING_LISTS,
  setPackingLists,
} from '../../actions/data/packingLists.js'

export function* getPackingLists() {
  try {
    const packingLists = yield call(
      fetchAllAPI,
      '/packing_list/',
      'packing_list',
    )

    yield put(setPackingLists(keyBy(packingLists, 'id')))
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'Something went wrong while trying to get packing lists.',
          details: err.message,
        },
        err,
      ),
    )
  }

  yield put(updateHasLoaded({packingLists: true}))
}

export default function* packingListsSaga() {
  yield takeEvery(FETCH_PACKING_LISTS, getPackingLists)
}
