import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'

import {
  isEmptyValue,
  isPresent,
  isPositiveNumeric,
} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import DimensionsInput from '../../../common/components/Form/DimensionsInput.js'
import {addProducts, saveProduct, nameSelector} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {hasOrderPermissionSelector} from '../../../data/me.js'

export const SAVE_PRODUCT_DIMENSIONS = 'SAVE_PRODUCT_DIMENSIONS'
export const PRODUCT_DIMENSIONS_MODAL = 'PRODUCT_DIMENSIONS_MODAL'

export function showProductDimensionsModal(skus, length, height, width) {
  return setForm(PRODUCT_DIMENSIONS_MODAL, {
    skus,
    length: isPresent(length) ? `${length}` : '',
    height: isPresent(height) ? `${height}` : '',
    width: isPresent(width) ? `${width}` : '',
    refresh_label_info_package: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PRODUCT_DIMENSIONS_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PRODUCT_DIMENSIONS_MODAL)
}

export function saveProductDimensions() {
  return {
    type: SAVE_PRODUCT_DIMENSIONS,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PRODUCT_DIMENSIONS_MODAL],
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({length, height, width}) => {
    const errors = {
      errorMessage: '',
      hasLengthError: false,
      hasWidthError: false,
      hasHeightError: false,
    }

    if (!isEmptyValue(length) && !isPositiveNumeric(length)) {
      errors.errorMessage = 'Length should be a positive whole number or blank.'
      errors.hasLengthError = true
    }

    if (!isEmptyValue(width) && !isPositiveNumeric(width)) {
      errors.errorMessage = 'Width should be a positive whole number or blank.'
      errors.hasWidthError = true
    }

    if (!isEmptyValue(height) && !isPositiveNumeric(height)) {
      errors.errorMessage = 'Height should be a positive whole number or blank.'
      errors.hasHeightError = true
    }

    if (
      !errors.hasLengthError &&
      !errors.hasWidthError &&
      !errors.hasHeightError
    ) {
      const anyEmpty =
        isEmptyValue(length) || isEmptyValue(width) || isEmptyValue(height)
      const allEmpty =
        isEmptyValue(length) && isEmptyValue(width) && isEmptyValue(height)

      if (anyEmpty && !allEmpty) {
        errors.errorMessage =
          'All three dimensions need to be entered\nor they should all be blank.'
      }
    }

    return errors
  },
)

export function* saveProductDimensionsWorker() {
  try {
    const {skus, length, height, width, refresh_label_info_package} =
      yield select(modalFormSelector)
    const params = {
      length: isPositiveNumeric(length) ? Number(length) : null,
      height: isPositiveNumeric(height) ? Number(height) : null,
      width: isPositiveNumeric(width) ? Number(width) : null,
      refresh_label_info_package,
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const products = yield all(
      skus.map((sku) => call(saveProduct, sku, params)),
    )

    yield call(addProducts, products)

    yield put(closeModal())

    yield call(showMessageToast, 'Saved product dimensions')
  } catch (err) {
    yield put(updateModalForm({serverError: err.message || err.error_message}))
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function ProductDimensionsModal({
  form,
  firstProduct,
  errors,
  hasOrderPermission,
  ...props
}) {
  return (
    <ConfirmModal
      title="Edit Default Package Dimensions"
      modalSize="sm"
      onConfirm={() => props.saveProductDimensions()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={!!errors.errorMessage}
      error={
        (!errors.hasLengthError &&
          !errors.hasWidthError &&
          !errors.hasHeightError &&
          errors.errorMessage) ||
        form.serverError
      }
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-20">
          {form.skus.length === 1 && (
            <>
              <div className="fs-01">
                <strong>{firstProduct.name}</strong>
              </div>
              <div className="fs-00">{form.skus[0]}</div>
            </>
          )}
          {form.skus.length > 1 && (
            <strong className="fs-01">
              Editing {form.skus.length} products
            </strong>
          )}
        </li>
        <li className="list__item--form list__item--no-style flex">
          <DimensionsInput
            length={form.length}
            width={form.width}
            height={form.height}
            setLength={(value) => props.updateModalForm({length: value})}
            setWidth={(value) => props.updateModalForm({width: value})}
            setHeight={(value) => props.updateModalForm({height: value})}
            errorMessage={errors.errorMessage}
            hasLengthError={errors.hasLengthError}
            hasWidthError={errors.hasWidthError}
            hasHeightError={errors.hasHeightError}
            onEnterKeyPress={() =>
              !errors.errorMessage && props.saveProductDimensions()
            }
            autoFocus
          />
        </li>
        {hasOrderPermission && (
          <li className="list__item--form list__item--no-style divider--top">
            <p className="fs-n0 lh-md margin-bottom-10">
              Want to update the Awaiting Fulfillment and Dropshipment Requested
              orders this product is on when a change is made to its dimensions?
            </p>
            <Checkbox
              id="refresh_label_info_customs"
              className="fs-00 lh-md"
              label="Update open orders"
              checked={!!form.refresh_label_info_package}
              onChange={(refresh_label_info_package) =>
                props.updateModalForm({refresh_label_info_package})
              }
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

ProductDimensionsModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    length: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    refresh_label_info_package: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  firstProduct: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    hasLengthError: PropTypes.bool.isRequired,
    hasWidthError: PropTypes.bool.isRequired,
    hasHeightError: PropTypes.bool.isRequired,
  }).isRequired,
  hasOrderPermission: PropTypes.bool.isRequired,
  saveProductDimensions: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const form = modalFormSelector(state)
  const firstSKU = form.skus[0]

  return {
    form,
    firstProduct: {
      name: nameSelector(state, {sku: firstSKU}),
    },
    errors: errorsSelector(state),
    hasOrderPermission: hasOrderPermissionSelector(state),
  }
}

const mapDispatchToProps = {
  saveProductDimensions,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(ProductDimensionsModal),
  modalFormSelector,
)
