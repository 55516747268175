import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'
import {createSelector} from 'reselect'

import {isPresent} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import api from '../../../common/api.js'
import {setSupplier} from '../../../data/suppliers.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'

export const MODAL_FORM = 'CREATE_SUPPLIER_MODAL'

export function showCreateSupplierModal({isInitial} = {}) {
  setForm(MODAL_FORM, {
    isInitial: !!isInitial,
    name: '',
    nameHasChanged: false,
    email: '',
    emailHasChanged: false,
    reference: '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({name, email}) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
    }

    if (!isPresent(email)) {
      errors.email = 'Email is required'
    }

    return errors
  },
)

export async function createSupplier() {
  try {
    const {name, email, reference} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const params = {
      company: name,
      email,
      reference_number: reference,
    }

    const {json} = await api.post('/supplier/', params)

    setSupplier(json)

    closeModal()

    showMessageToast('Supplier was successfully created!')
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  } finally {
    updateModalForm({isSaving: false})
  }
}

function CreateSupplierModal({form}) {
  const errors = useSelector(errorsSelector)
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Create a Supplier"
      modalSize="sm"
      onConfirm={() => createSupplier()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      {form.isInitial && (
        <div className="alert alert--standard align-center margin-bottom-25">
          <p className="fs-00 lh-md margin-bottom-0">
            <strong>You need to add a supplier to Ordoro first</strong>
          </p>
        </div>
      )}
      <ul className="form-list margin-bottom-0">
        <li className="form-list-item margin-bottom-15 row">
          <div className="medium-8 columns">
            <label htmlFor="id_supplier_name">
              Supplier Name<span className="required">*</span>
            </label>
            <input
              className="margin-bottom-0"
              type="text"
              name="supplier_name"
              id="id_supplier_name"
              value={form.name}
              onChange={(event) =>
                updateForm(MODAL_FORM, {
                  name: event.target.value,
                  nameHasChanged: true,
                })
              }
            />
            {form.nameHasChanged && errors.name && (
              <small className="error error-message">{errors.name}</small>
            )}
          </div>
        </li>
        <li className="form-list-item margin-bottom-15 row">
          <div className="medium-8 columns">
            <label htmlFor="id_supplier_email">
              Supplier Email<span className="required">*</span>
            </label>
            <input
              className="margin-bottom-0"
              type="text"
              name="supplier_email"
              id="id_supplier_email"
              value={form.email}
              onChange={(event) =>
                updateForm(MODAL_FORM, {
                  email: event.target.value,
                  emailHasChanged: true,
                })
              }
            />
            {form.emailHasChanged && errors.email && (
              <small className="error error-message">{errors.email}</small>
            )}
          </div>
        </li>
        <li className="form-list-item row">
          <div className="medium-8 columns">
            <label htmlFor="id_account_number">Custom Text</label>
            <input
              type="text"
              name="account_number"
              id="id_account_number"
              value={form.reference}
              onChange={(event) =>
                updateForm(MODAL_FORM, {
                  reference: event.target.value,
                })
              }
            />
          </div>
        </li>
      </ul>
    </ConfirmModal>
  )
}

CreateSupplierModal.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isInitial: PropTypes.bool,
    nameHasChanged: PropTypes.bool,
    emailHasChanged: PropTypes.bool,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(CreateSupplierModal, modalFormSelector)
