import {createSelector} from 'reselect'

import {getOrderIsDeallocateable} from '../../../../data/orders.js'

export const confirmDeallocateOrdersSelector = (state) =>
  state.ui.modals.confirmDeallocateModal.orders

export const confirmDeallocateOrdersDeallocatableOrderCountSelector =
  createSelector(
    confirmDeallocateOrdersSelector,
    (orders) =>
      orders.filter((order) => getOrderIsDeallocateable(order)).length,
  )
