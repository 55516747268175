import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'
import omitBy from 'lodash/omitBy.js'
import pick from 'lodash/pick.js'
import get from 'lodash/get.js'

import {formsSelector} from '../../store.js'
import {stringifyURL} from '../../common/querystring.js'
import {isPresent, isNumeric, isPositiveNumeric} from '../../common/utils.js'
import {orderTagsSelector} from '../../data/orderTags.js'
import {canUseOrdersSelector} from '../../data/orders.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'

export const CREATE_ORDER_PAGE_FORM = 'CREATE_ORDER_PAGE_FORM'

export function createOrderFormSelector(state) {
  return formsSelector(state)[CREATE_ORDER_PAGE_FORM]
}

export function createOrderLocationSelector(state) {
  const location = locationSelector(state)

  if (location.pathComponents[0] !== 'createorder') {
    return null
  }

  return location
}

export function cloneFromOrderNumberSelector(state) {
  const createOrderLocation = createOrderLocationSelector(state)

  if (!createOrderLocation || !createOrderLocation.query.clone_from) {
    return null
  }

  return createOrderLocation.query.clone_from
}

export const createOrderHashSelector = createSelector(
  (state) => canUseOrdersSelector(state),
  cloneFromOrderNumberSelector,
  (canUseOrders, cloneFromOrderNumber) => {
    if (!canUseOrders) {
      return null
    }

    return stringifyURL(
      '#/createorder',
      cloneFromOrderNumber ? {clone_from: cloneFromOrderNumber} : undefined,
    )
  },
)

export function createOrderPayloadSelector(state) {
  const {orderInfo, productInfo} = createOrderFormSelector(state)

  return {
    order_id: orderInfo.orderNumber,
    shipping_amount: Number(productInfo.shippingAmount),
    tax_amount: Number(productInfo.taxAmount),
    discount_amount: Number(productInfo.discountAmount),
    product_amount: Number(productTotalSelector(state)),
    grand_total: Number(grandTotalSelector(state)),
    shipping_type: orderInfo.shippingMethod,
    order_date: orderInfo.orderDate.toISOString(),
    notes_from_customer: orderInfo.customerNotes,
    internal_notes: orderInfo.internalNotes,
    lines: productInfo.lines.length
      ? productInfo.lines.map((line) => ({
          product: {
            sku: line.sku,
            name: line.name,
          },
          quantity: +line.quantity,
          item_price: +line.itemPrice,
          discount_amount: +line.discountAmount,
          total_price: +line.totalPrice,
          selected_option: line.details,
          product_serial_numbers: line.product_serial_numbers
            .split(/[ \t\n]+/)
            .filter((v) => v),
        }))
      : undefined,
    shipping_address: shippingAddressPayload(state),
    billing_address: billingAddressPayload(state),
    tags: includedOrderTagsSelector(state),
  }
}

function shippingAddressPayload(state) {
  const {shippingInfo} = createOrderFormSelector(state)

  return omitBy(
    pick(shippingInfo, [
      'name',
      'company',
      'email',
      'phone',
      'street1',
      'street2',
      'city',
      'state',
      'zip',
      'country',
    ]),
    (value) => value === null || value === '',
  )
}

function billingAddressPayload(state) {
  const {billingInfo} = createOrderFormSelector(state)

  if (billingInfo.isSameAsShipping) {
    return shippingAddressPayload(state)
  }

  return omitBy(
    pick(billingInfo, [
      'name',
      'company',
      'email',
      'phone',
      'street1',
      'street2',
      'city',
      'state',
      'zip',
      'country',
    ]),
    (value) => value === null || value === '',
  )
}

export function productInfoErrorsSelector(state) {
  const {
    productInfo: {discountAmount, shippingAmount, taxAmount, lines},
  } = createOrderFormSelector(state)

  const errors = {}

  if (!isNumeric(discountAmount)) {
    errors.discountAmount = 'Must be a number'
  }

  if (!isNumeric(shippingAmount)) {
    errors.shippingAmount = 'Must be a number'
  }

  if (!isNumeric(taxAmount)) {
    errors.taxAmount = 'Must be a number'
  }

  lines.forEach((line) => {
    if (!isEmpty(getProductLineErrors(line))) {
      errors.lines = 'There is an error with at least one product'
    }
  })

  return errors
}

export function getProductLineErrors(line) {
  const errors = {}

  if (!isPresent(line.sku)) {
    errors.sku = 'Product selection required'
  }

  if (!isPositiveNumeric(line.quantity)) {
    errors.quantity = 'Must be a positive number'
  }

  if (!isPositiveNumeric(line.itemPrice)) {
    errors.itemPrice = 'Must be a positive number'
  }

  if (!isPositiveNumeric(line.discountAmount)) {
    errors.discountAmount = 'Must be a positive number'
  }

  if (!isPositiveNumeric(line.totalPrice)) {
    errors.totalPrice = 'Must be a positive number'
  }
  return errors
}

export function makeProductErrorsSelector() {
  return createSelector((state, props) => props.line, getProductLineErrors)
}

export function productTotalSelector(state) {
  const {
    productInfo: {lines},
  } = createOrderFormSelector(state)

  return lines
    .reduce(
      (sum, line) =>
        sum + Number(line.totalPrice) - Number(line.discountAmount),
      0,
    )
    .toFixed(2)
}

export function grandTotalSelector(state) {
  const productTotal = productTotalSelector(state)

  const {
    productInfo: {discountAmount, shippingAmount, taxAmount},
  } = createOrderFormSelector(state)

  return (
    productTotal -
    Number(discountAmount) +
    Number(shippingAmount) +
    Number(taxAmount)
  ).toFixed(2)
}

export function orderInfoErrorsSelector(state) {
  const {
    orderInfo: {orderNumber, orderDate, orderNumberIsDuplicated},
  } = createOrderFormSelector(state)
  const errors = {}

  if (!isPresent(orderNumber)) {
    errors.orderNumber = 'Order number is required'
  }

  if (!orderDate) {
    errors.orderDate = 'Order date is required'
  }

  if (orderNumberIsDuplicated) {
    errors.orderNumber = 'Order number already exists'
  }

  return errors
}

export function submitDisabledSelector(state) {
  const orderInfoErrors = orderInfoErrorsSelector(state)
  const productInfoErrors = productInfoErrorsSelector(state)

  return !isEmpty(orderInfoErrors) || !isEmpty(productInfoErrors)
}

export const createOrderTagIDsSelector = createSelector(
  (state) => get(createOrderFormSelector(state), 'orderInfo.tags'),
  (tagIDs) => tagIDs || [],
)

export const selectedOrderTagsSelector = createSelector(
  createOrderTagIDsSelector,
  orderTagsSelector,
  (tagIDs, orderTags) =>
    tagIDs.reduce((prev, tagID) => {
      const tag = orderTags[tagID]

      if (tag) {
        prev.push(tag)
      }

      return prev
    }, []),
)

export function includedOrderTagsSelector(state) {
  return selectedOrderTagsSelector(state).map((tag) => {
    return {color: tag.color, text: tag.text}
  })
}
