import {takeLatest, takeEvery, all, call, select, put} from 'redux-saga/effects'

import apiverson from '../../common/apiverson.js'
import {RETURN_ORDER_LIST_PAGE} from '../../common/constants/Pages.js'
import {setGlobalError} from '../../redux/actions/ui/index.js'
import {setReturnOrdersStateAndEnsureProductsLoaded} from '../../redux/actions/data/returnOrders.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {
  REFRESH_RETURN_ORDER_LIST,
  RETURN_ORDER_LIST_SELECT_REFERENCE_ID,
  DESELECT_RETURN_ORDERS,
  RETURN_ORDER_LIST_TOGGLE_EXPANDED,
  updateReturnOrderListForm,
  setSelectedReferenceIDs,
  setExpandedReferenceIDs,
} from './returnOrderListActions.js'
import {
  returnOrderListFormSelector,
  queryParamsSelector,
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  expandedReferenceIDsSelector,
} from './returnOrderListSelectors.js'
import {returnOrderListModalsSaga} from './Modals/index.js'

export function* refreshReturnOrderListWorker() {
  try {
    const currentPage = yield select(currentPageSelector)
    if (currentPage !== RETURN_ORDER_LIST_PAGE) {
      return
    }

    yield put(updateReturnOrderListForm({isLoading: true}))

    const params = yield select(queryParamsSelector)
    const {json} = yield call(apiverson.get, '/return_order/', params)

    yield call(setReturnOrdersStateAndEnsureProductsLoaded, json.return_order)

    yield call(updateQueryResults, json)
  } catch (err) {
    yield put(
      setGlobalError(
        `Error querying RMAs. ${err.message || err.error_message}`,
        err,
      ),
    )
  }

  yield put(updateReturnOrderListForm({isLoading: false}))
}

export function* updateQueryResults({count, limit, offset, return_order}) {
  const {selectedReferenceIDs, expandedReferenceIDs} = yield select(
    returnOrderListFormSelector,
  )
  const referenceIDList = return_order.map((ro) => ro.reference_id)

  yield put(
    updateReturnOrderListForm({
      referenceIDList,
      count,
      currentPage: offset / limit + 1,
      selectedReferenceIDs: selectedReferenceIDs.filter((referenceID) =>
        referenceIDList.includes(referenceID),
      ),
      expandedReferenceIDs: expandedReferenceIDs.filter((referenceID) =>
        referenceIDList.includes(referenceID),
      ),
    }),
  )
}

export function* returnOrderListSelectReferenceIDWorker({
  payload: {referenceID, isSelected, isShiftKey},
}) {
  const selectedReferenceIDs = yield select(selectedReferenceIDsSelector)

  if (isShiftKey) {
    const referenceIDList = yield select(referenceIDListSelector)
    const indexOfNextToLastRowSelected = referenceIDList.indexOf(
      selectedReferenceIDs[selectedReferenceIDs.length - 1],
    )
    const indexOfLastRowSelected = referenceIDList.indexOf(referenceID)

    const increasingNumber =
      indexOfNextToLastRowSelected < indexOfLastRowSelected

    const toToggle = []
    for (
      let i = indexOfNextToLastRowSelected;
      increasingNumber
        ? i <= indexOfLastRowSelected
        : i >= indexOfLastRowSelected;
      i = i + (increasingNumber ? 1 : -1)
    ) {
      if (referenceIDList[i]) {
        toToggle.push(referenceIDList[i])
      } else {
        break
      }
    }

    yield put(
      setSelectedReferenceIDs([
        ...selectedReferenceIDs.filter((s) => !toToggle.includes(s)),
        ...(isSelected ? toToggle : []),
      ]),
    )
  } else {
    const referenceIDs = selectedReferenceIDs.filter((p) => p !== referenceID)

    if (isSelected) {
      referenceIDs.push(referenceID)
    }

    yield put(setSelectedReferenceIDs(referenceIDs))
  }
}

export function* deselectReturnOrdersWorker({payload: {referenceIDs}}) {
  const selectedReferenceIDs = yield select(selectedReferenceIDsSelector)

  yield put(
    setSelectedReferenceIDs(
      selectedReferenceIDs.filter((id) => !referenceIDs.includes(id)),
    ),
  )
}

export function* returnOrderListToggleExpandedWorker({payload: {referenceID}}) {
  const expandedReferenceIDs = yield select(expandedReferenceIDsSelector)

  const referenceIDs = expandedReferenceIDs.filter((p) => p !== referenceID)

  if (!expandedReferenceIDs.includes(referenceID)) {
    referenceIDs.push(referenceID)
  }

  yield put(setExpandedReferenceIDs(referenceIDs))
}

export default function* returnOrderListSagas() {
  yield all([
    returnOrderListModalsSaga(),
    takeLatest(REFRESH_RETURN_ORDER_LIST, refreshReturnOrderListWorker),
    takeEvery(
      RETURN_ORDER_LIST_SELECT_REFERENCE_ID,
      returnOrderListSelectReferenceIDWorker,
    ),
    takeEvery(DESELECT_RETURN_ORDERS, deselectReturnOrdersWorker),
    takeEvery(
      RETURN_ORDER_LIST_TOGGLE_EXPANDED,
      returnOrderListToggleExpandedWorker,
    ),
  ])
}
