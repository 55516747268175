import PropTypes from 'prop-types'

import {onlyIfForm, useSelector} from '../../../../store.js'
import {DateShape} from '../../../../common/PropTypes.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import TagFilterPanel from './TagFilterPanel.js'
import {
  closeModal,
  modalFormSelector,
  applyFilter,
  STATUS_FILTER_PANEL,
  ALLOCATION_FILTER_PANEL,
  SALES_CHANNEL_FILTER_PANEL,
  SUPPLIER_FILTER_PANEL,
  WAREHOUSE_FILTER_PANEL,
  DATE_RANGE_FILTER_PANEL,
  MISC_FILTER_PANEL,
  orderListFilterOrderSelector,
  TAG_FILTER,
  clearFilters,
  SHIPPER_FILTER_PANEL,
} from './orderListFilterModalFunctions.js'
import StatusFilterPanel from './StatusFilterPanel.js'
import AllocationFilterPanel from './AllocationFilterPanel.js'
import SalesChannelFilterPanel from './SalesChannelFilterPanel.js'
import SupplierFilterPanel from './SupplierFilterPanel.js'
import WarehouseFilterPanel from './WarehouseFilterPanel.js'
import DateRangeFilterPanel from './DateRangeFilterPanel.js'
import MiscFilterPanel from './MiscFilterPanel.js'
import {showOrderListFilterSettingsModal} from '../OrderListFilterSettingsModal.js'
import ShipperFilterPanel from './ShipperFilterPanel.js'

const FILTER_PANEL_LIST = {
  [STATUS_FILTER_PANEL]: StatusFilterPanel,
  [ALLOCATION_FILTER_PANEL]: AllocationFilterPanel,
  [SHIPPER_FILTER_PANEL]: ShipperFilterPanel,
  [SALES_CHANNEL_FILTER_PANEL]: SalesChannelFilterPanel,
  [SUPPLIER_FILTER_PANEL]: SupplierFilterPanel,
  [WAREHOUSE_FILTER_PANEL]: WarehouseFilterPanel,
  [TAG_FILTER]: TagFilterPanel,
  [DATE_RANGE_FILTER_PANEL]: DateRangeFilterPanel,
  [MISC_FILTER_PANEL]: MiscFilterPanel,
}

function OrderListFilterModal() {
  const filterList = useSelector(orderListFilterOrderSelector)

  return (
    <ConfirmModal
      title="Order Filters"
      modalSize="md"
      className="modal--order-list-filters"
      onConfirm={() => applyFilter()}
      onCancel={() => closeModal()}
      confirmText="Apply"
      cancelText="Cancel"
      preventHotKeyConfirm
      RightButtons={() => (
        <div className="flex--justify">
          <ButtonPrimary
            isOutlined
            className="btn--sm margin-right-10"
            onClick={() => clearFilters()}
          >
            Clear Filters
          </ButtonPrimary>
          <ButtonPrimary
            isOutlined
            className="btn--sm"
            onClick={() => showOrderListFilterSettingsModal()}
          >
            <span
              className="icon icon-settings icon-block x-sm"
              aria-hidden="true"
            />
          </ButtonPrimary>
        </div>
      )}
    >
      {filterList.map((item) => {
        const Panel = FILTER_PANEL_LIST[item]

        return Panel ? <Panel key={item} /> : null
      })}
    </ConfirmModal>
  )
}

OrderListFilterModal.propTypes = {
  form: PropTypes.shape({
    searchText: PropTypes.string.isRequired,
    status: PropTypes.arrayOf(PropTypes.string).isRequired,
    allocationFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    cartFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
    supplierFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
    warehouseFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
    dateFilterType: PropTypes.string.isRequired,
    startDate: DateShape,
    endDate: DateShape,
    orderTagFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    untaggedSelected: PropTypes.bool.isRequired,
    tagFilterOperator: PropTypes.string.isRequired,
    showUnprintedOnly: PropTypes.bool.isRequired,
    hasSimilarOpenAddresses: PropTypes.bool.isRequired,
    shipToIsBillTo: PropTypes.bool,
    hasRevision: PropTypes.bool,
    shipToStatus: PropTypes.string.isRequired,
    shipToCountry: PropTypes.string.isRequired,
  }).isRequired,
}

export default onlyIfForm(OrderListFilterModal, modalFormSelector)
