import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import {DateShape, MomentShape} from '../PropTypes.js'
import {dateTimeFormat} from '../date.js'

export function formatDate(value, format = 'MMM D, YYYY') {
  return moment.tz(value, moment.tz.guess()).format(format)
}

export function FormattedRealDate({value, format, timeZone}) {
  if (!value) {
    return null
  }

  return <span>{dateTimeFormat(value, format, {timeZone})}</span>
}

FormattedRealDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, DateShape]),
  format: PropTypes.func,
  timeZone: PropTypes.string,
}

export default function FormattedDate({value, format}) {
  if (!value) {
    return null
  }

  return <span>{formatDate(value, format)}</span>
}

FormattedDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, MomentShape]),
  format: PropTypes.string,
}
