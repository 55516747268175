import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import get from 'lodash/get.js'
import {createSelector} from 'reselect'
import {all, call, put, select, fork} from 'redux-saga/effects'
import classNames from 'classnames'

import {ErrorsShape, WarehouseShape} from '../../../common/PropTypes.js'
import {isPresent, isNumeric, isPositiveNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import TextInput from '../../../common/components/TextInput.js'
import {
  getProduct,
  saveWarehouse,
  productWarehousesSelector,
  canEditTotalOnHandSelector,
} from '../../../data/products.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {warehouseSelector, getWarehouseName} from '../../../data/warehouses.js'
import {usesInventorySelector} from '../../../data/company.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const EDIT_PRODUCT_WAREHOUSE_MODAL = 'EDIT_PRODUCT_WAREHOUSE_MODAL'
export const SHOW_EDIT_PRODUCT_WAREHOUSE_MODAL =
  'SHOW_EDIT_PRODUCT_WAREHOUSE_MODAL'
export const UPDATE_PRODUCT_WAREHOUSE = 'UPDATE_PRODUCT_WAREHOUSE'

export function showEditProductWarehouseModal(
  sku,
  warehouseIDs = [],
  autoFocusOn = null,
) {
  return {
    type: SHOW_EDIT_PRODUCT_WAREHOUSE_MODAL,
    payload: {
      sku,
      warehouseIDs,
      autoFocusOn,
    },
  }
}

export function updateModalForm(...args) {
  return updateForm(EDIT_PRODUCT_WAREHOUSE_MODAL, ...args)
}

export function closeModal() {
  return removeForm(EDIT_PRODUCT_WAREHOUSE_MODAL)
}

export function updateProductWarehouse() {
  return {
    type: UPDATE_PRODUCT_WAREHOUSE,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[EDIT_PRODUCT_WAREHOUSE_MODAL],
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({physical_on_hand, adjustment, low_stock_threshold}) => {
    const errors = {}

    if (!Number(adjustment)) {
      if (!isPresent(physical_on_hand)) {
        errors.physical_on_hand = 'Physical on Hand is required'
      } else if (!isNumeric(physical_on_hand)) {
        errors.physical_on_hand = 'Physical on Hand must be a number'
      }
    }

    if (isPresent(adjustment) && !isNumeric(adjustment)) {
      errors.adjustment = 'Adjustment must be a number'
    }

    if (!isPresent(low_stock_threshold)) {
      errors.low_stock_threshold = 'Low Stock Threshold is required'
    } else if (!isPositiveNumeric(low_stock_threshold)) {
      errors.low_stock_threshold =
        'Low Stock Threshold must be a positive number'
    }

    return errors
  },
)

export function* showEditProductWarehouseWorker({
  payload: {sku, warehouseIDs, autoFocusOn},
}) {
  const productWarehouses = yield select(productWarehousesSelector, {sku})
  const totalThatCanEditTotalOnHand = (yield all(
    warehouseIDs.map((warehouseID) =>
      select(canEditTotalOnHandSelector, {sku, warehouseID}),
    ),
  )).filter((canEditTotalOnHand) => canEditTotalOnHand).length

  autoFocusOn = autoFocusOn || 'location_in_warehouse'

  const firstProductWarehouse = productWarehouses.find(
    ({id}) => id === warehouseIDs[0],
  )

  yield put(
    setForm(EDIT_PRODUCT_WAREHOUSE_MODAL, {
      sku,
      warehouseIDs,
      autoFocusOn,
      totalThatCanEditTotalOnHand,
      isSingleInputEdit: warehouseIDs.length > 1,
      location_in_warehouse:
        get(firstProductWarehouse, 'location_in_warehouse') || '',
      physical_on_hand: String(
        get(firstProductWarehouse, 'physical_on_hand') || 0,
      ),
      adjustment: '',
      low_stock_threshold: String(
        get(firstProductWarehouse, 'low_stock_threshold') || 0,
      ),
      isSaving: false,
      serverError: null,
    }),
  )
}

export function* updateSingleProductWarehouse(sku, warehouseID, params) {
  const adjustedParams = {...params}

  const canEditTotalOnHand = yield select(canEditTotalOnHandSelector, {
    sku,
    warehouseID,
  })

  if (!canEditTotalOnHand) {
    delete adjustedParams.on_hand
    delete adjustedParams.adjustment
  }

  const product = yield call(saveWarehouse, warehouseID, sku, adjustedParams)

  return product
}

export function* updateProductWarehouseWorker() {
  try {
    const {
      sku,
      warehouseIDs,
      location_in_warehouse,
      physical_on_hand,
      adjustment,
      low_stock_threshold,
      autoFocusOn,
      isSingleInputEdit,
    } = yield select(modalFormSelector)
    const usesInventory = yield select(usesInventorySelector)

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const params = {}

    if (!isSingleInputEdit || autoFocusOn === 'location_in_warehouse') {
      params.location_in_warehouse = location_in_warehouse
    }

    if (
      usesInventory &&
      (!isSingleInputEdit || autoFocusOn === 'physical_on_hand')
    ) {
      if (Number(adjustment)) {
        params.adjustment = Number(adjustment)
      } else {
        params.on_hand = Number(physical_on_hand)
      }
    }

    if (
      usesInventory &&
      (!isSingleInputEdit || autoFocusOn === 'low_stock_threshold')
    ) {
      params.low_stock_threshold = Number(low_stock_threshold)
    }

    yield all(
      warehouseIDs.map((warehouseID) =>
        call(updateSingleProductWarehouse, sku, warehouseID, params),
      ),
    )

    yield fork(getProduct, sku)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `Saved product warehouse${warehouseIDs.length === 1 ? '' : 's'}`,
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function EditProductWarehouseModal({
  form,
  errors,
  firstWarehouse,
  usesInventory,
  ...props
}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Edit Product Warehouse Values"
      modalSize="sm"
      onConfirm={() => props.updateProductWarehouse()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            {form.warehouseIDs.length === 1 ? (
              <>
                <strong>Updating 1 warehouse:</strong>
                <div>{getWarehouseName(firstWarehouse)}</div>
              </>
            ) : (
              <strong>Updating {form.warehouseIDs.length} Warehouses</strong>
            )}
          </div>
        </li>

        {usesInventory &&
          form.totalThatCanEditTotalOnHand > 0 &&
          (!form.isSingleInputEdit ||
            form.autoFocusOn === 'physical_on_hand') && (
            <>
              <li className="list__item--form list__item--no-style fs-00 margin-bottom-10">
                <strong>Physical On Hand</strong>
                {form.warehouseIDs.length === 1 && (
                  <span>
                    {' '}
                    (
                    {Number(form.adjustment)
                      ? String(
                          Number(form.physical_on_hand) +
                            Number(form.adjustment),
                        )
                      : form.physical_on_hand}{' '}
                    units)
                  </span>
                )}
              </li>
              <li
                className={classNames(
                  'list__item--form list__item--no-style flex',
                  {
                    'divider--bottom padding-bottom-15':
                      form.warehouseIDs.length === 1,
                  },
                )}
              >
                <div>
                  <label htmlFor="physical-on-hand">Set Total</label>
                  <NumberInput
                    id="physical-on-hand"
                    value={
                      Number(form.adjustment)
                        ? form.warehouseIDs.length === 1
                          ? String(
                              Number(form.physical_on_hand) +
                                Number(form.adjustment),
                            )
                          : ''
                        : form.physical_on_hand
                    }
                    onChange={(value) =>
                      props.updateModalForm({
                        physical_on_hand: `${value}`,
                      })
                    }
                    isInvalid={!!errors.physical_on_hand}
                    autoFocus={form.autoFocusOn === 'physical_on_hand'}
                    disabled={!!Number(form.adjustment)}
                  />
                  {errors.physical_on_hand && (
                    <small className="error">{errors.physical_on_hand}</small>
                  )}
                </div>
                <div className="flex--justify-col margin-top-20 margin-left-10 margin-right-15">
                  <strong className="text--lt-grey fs-00">OR</strong>
                </div>
                <div>
                  <label htmlFor="physical-on-hand">+/- Units</label>
                  <NumberInput
                    id="adjustment"
                    value={form.adjustment}
                    onChange={(value) =>
                      props.updateModalForm({
                        adjustment: `${value}`,
                      })
                    }
                    isInvalid={!!errors.adjustment}
                    autoFocus={form.autoFocusOn === 'adjustment'}
                  />
                  {errors.adjustment && (
                    <small className="error">{errors.adjustment}</small>
                  )}
                </div>
              </li>
            </>
          )}
        {usesInventory &&
          (!form.isSingleInputEdit ||
            form.autoFocusOn === 'low_stock_threshold') && (
            <>
              <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
                <label className="fs-00" htmlFor="low-stock-threshold">
                  Low Stock Threshold
                </label>
              </li>
              <li
                className={classNames('list__item list__item--form', {
                  'divider--bottom padding-bottom-15':
                    form.warehouseIDs.length === 1,
                })}
              >
                <NumberInput
                  id="low-stock-threshold"
                  value={form.low_stock_threshold}
                  onChange={(value) =>
                    props.updateModalForm({
                      low_stock_threshold: `${value}`,
                    })
                  }
                  isInvalid={!!errors.low_stock_threshold}
                  autoFocus={form.autoFocusOn === 'low_stock_threshold'}
                  min={0}
                />
                {errors.low_stock_threshold && (
                  <small className="error">{errors.low_stock_threshold}</small>
                )}
              </li>
            </>
          )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'location_in_warehouse') && (
          <li className="list__item--form list__item--no-style">
            <TextInput
              label="Location in Warehouse"
              id="location-in-warehouse"
              value={form.location_in_warehouse}
              onChange={(value) =>
                props.updateModalForm({location_in_warehouse: value})
              }
              errorMessage={errors.location_in_warehouse}
              autoFocus={form.autoFocusOn === 'location_in_warehouse'}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditProductWarehouseModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    warehouseIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    totalThatCanEditTotalOnHand: PropTypes.number.isRequired,
    location_in_warehouse: PropTypes.string.isRequired,
    physical_on_hand: PropTypes.string.isRequired,
    adjustment: PropTypes.string.isRequired,
    low_stock_threshold: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  firstWarehouse: WarehouseShape.isRequired,
  usesInventory: PropTypes.bool.isRequired,
  updateProductWarehouse: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  const firstWarehouseID = form.warehouseIDs[0]

  return {
    errors: errorsSelector(state),
    firstWarehouse: warehouseSelector(state, {warehouseID: firstWarehouseID}),
    usesInventory: usesInventorySelector(state),
  }
}

const mapDispatchToProps = {
  updateProductWarehouse,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(EditProductWarehouseModal),
  modalFormSelector,
)
