import PropTypes from 'prop-types'
import {useMemo} from 'react'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase.js'

import {useSelector} from '../../../store.js'
import TextArea from '../../../common/components/TextArea.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import {
  usesInventorySelector,
  currencySymbolSelector,
} from '../../../data/company.js'
import {getShippabilityHint} from '../../../data/orders.js'
import {productSelector} from '../../../data/products.js'
import {
  initializeCreateOrderFormProductInfoLine,
  updateCreateOrderFormProductInfoLine,
  updateCreateOrderLineQuantity,
  updateCreateOrderLineItemPrice,
  updateCreateOrderLineTotalPrice,
  removeCreateOrderFormProductInfoLine,
} from '../createOrderActions.js'
import {makeProductErrorsSelector} from '../createOrderSelectors.js'

export default function ProductRow({index, line}) {
  const errorSelector = useMemo(() => makeProductErrorsSelector(), [])

  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  const errors = useSelector((state) => errorSelector(state, {line}))
  const currencySymbol = useSelector(currencySymbolSelector)
  const usesInventory = useSelector(usesInventorySelector)

  const quantity = Number(line.quantity) || 0
  const shippability =
    !usesInventory || !product
      ? 'off'
      : product.total_on_hand - product.to_be_shipped <= 0
        ? 'unshippable'
        : 'shippable'
  const shippabilityHint = getShippabilityHint(shippability)

  return (
    <tr>
      <td
        className={classNames('table__td', {
          'wrap--shippability': usesInventory,
          [kebabCase(shippability)]: usesInventory,
        })}
      >
        <div className="wrap--product-filter-stacked">
          {product ? (
            <div className="flex">
              {usesInventory && (
                <div
                  className="list__item--line-detail inline-block tooltip--top"
                  data-hint={shippabilityHint}
                >
                  <span
                    className="i--shippability fs-00 lh-lg"
                    aria-hidden="true"
                  />
                </div>
              )}
              <ButtonLink
                onClick={() =>
                  updateCreateOrderFormProductInfoLine(index, {
                    sku: null,
                  })
                }
              >
                <dl className="list">
                  <dt className="list__title fs-00 lh-md line-detail margin-bottom-0">
                    {product.name}
                  </dt>
                  <dd className="list__item fs-n0 lh-md margin-bottom-0">
                    SKU: {product.sku}
                  </dd>
                  {product.upc && (
                    <dd className="list__item fs-n0 lh-md margin-bottom-0">
                      UPC: {product.upc}
                    </dd>
                  )}
                  <dd className="list__item fs-n1 lh-md margin-bottom-0 text--lt-grey">
                    Edit
                  </dd>
                </dl>
              </ButtonLink>
            </div>
          ) : (
            <ProductFilter
              dropdown={`CREATE_MO_MODAL_PRODUCT_SEARCH__${index}`}
              onSelect={(product) => {
                initializeCreateOrderFormProductInfoLine(index, product)
              }}
              autoFocus
            />
          )}
        </div>
      </td>
      <td className="table__td">
        <TextArea
          className="textarea textarea--in-cell order-detail"
          placeholder="Add a note..."
          value={line.details}
          onChange={(value) =>
            updateCreateOrderFormProductInfoLine(index, {
              details: value,
            })
          }
        />
      </td>
      <td className="table__td">
        <TextArea
          className="textarea textarea--in-cell order-detail"
          placeholder="For multiple serial numbers, separate them with line breaks"
          value={line.product_serial_numbers}
          onChange={(value) =>
            updateCreateOrderFormProductInfoLine(index, {
              product_serial_numbers: value,
            })
          }
        />
      </td>
      <td className="table__td">
        <NumberInput
          className="input--text input--med-h input--75"
          value={line.quantity}
          onChange={(value) =>
            updateCreateOrderLineQuantity(index, String(value))
          }
          autoFocus={!!line.sku}
        />
        {errors.quantity && <small className="error">{errors.quantity}</small>}
        {usesInventory && product && (
          <small className="block op-75 margin-top-5">
            <strong>{product.total_available}</strong>
            <span> Available On Hand</span>
          </small>
        )}
      </td>
      <td className="table__td">
        <div
          className={classNames('flex', {
            'op-30': quantity <= 0,
          })}
        >
          <div className="wrap--input-prefix flex--justify-col">
            <strong className="fs-n1 text--md-grey">{currencySymbol}</strong>
          </div>
          <input
            type="text"
            className="input--text input--med-h input--90 input--w-prefix"
            value={quantity <= 0 ? '--' : line.itemPrice}
            onChange={(event) =>
              updateCreateOrderLineItemPrice(index, event.target.value)
            }
            disabled={quantity <= 0}
          />
        </div>
        {errors.itemPrice && (
          <small className="error">{errors.itemPrice}</small>
        )}
      </td>
      <td className="table__td">
        <div className="flex">
          <div className="wrap--input-prefix flex--justify-col">
            <strong className="fs-n1 text--md-grey">{currencySymbol}</strong>
          </div>
          <input
            type="text"
            className="input--text input--med-h input--75 input--w-prefix"
            value={line.discountAmount}
            onChange={(event) =>
              updateCreateOrderFormProductInfoLine(index, {
                discountAmount: event.target.value,
              })
            }
          />
        </div>
        {errors.discountAmount && (
          <small className="error">{errors.discountAmount}</small>
        )}
      </td>
      <td className="table__td">
        <div className="flex">
          <div className="wrap--input-prefix flex--justify-col">
            <strong className="fs-n1 text--md-grey">{currencySymbol}</strong>
          </div>
          <input
            type="text"
            className="input--text input--med-h input--75 input--w-prefix"
            value={line.totalPrice}
            onChange={(event) =>
              updateCreateOrderLineTotalPrice(index, event.target.value)
            }
          />
        </div>
        {errors.totalPrice && (
          <small className="error">{errors.totalPrice}</small>
        )}
      </td>
      <td className="table__td">
        <button
          className="btn btn--link no-underline"
          type="button"
          title="Remove line from order"
          onClick={() => removeCreateOrderFormProductInfoLine(index)}
        >
          <span className="i-trash fs-01" aria-hidden="true" />
        </button>
      </td>
    </tr>
  )
}

ProductRow.propTypes = {
  index: PropTypes.number.isRequired,
  line: PropTypes.object.isRequired,
}
