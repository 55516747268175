import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {plural, Plural, PluralBlock} from '../../../common/components/Plural.js'
import formConnect from '../../../common/formConnect.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {createTasks} from '../../../redux/actions/data/tasks.js'
import {
  orderSelector,
  markedTrackingWritebackFailureSelector,
} from '../../../data/orders.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'

export const MODAL_FORM = 'CONFIRM_TRACKING_WRITEBACK_MODAL'

export function showConfirmTrackingWritebackModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    includeUntagged: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export async function organizeTaskShape(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})

  if (!order) {
    return null
  }

  const cartID = order.sales_channel.id

  const task = {
    type: 'export_shipment_to_cart',
    cart: cartID,
    params: {
      order_number: orderNumber,
      retry: false,
    },
  }

  return task
}

export async function retryTrackingWriteback() {
  try {
    let {orderNumbers, includeUntagged} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    if (!includeUntagged) {
      orderNumbers = markedTrackingWritebackFailureSelector(getState(), {
        orderNumbers,
      })
    }

    const tasks = (
      await Promise.all(
        orderNumbers.map((orderNumber) => organizeTaskShape(orderNumber)),
      )
    ).filter((possibleTask) => possibleTask)

    await createTasks(tasks)

    showMessageToast(
      plural(tasks.length)`Tracking writeback was started for ${
        tasks.length
      } order${['s']}.`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error starting tracking writeback: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function ConfirmTrackingWritebackModal({form, markedTrackingWritebackFailure}) {
  const orderCount = form.orderNumbers.length
  const taggedCount = markedTrackingWritebackFailure.length
  const untaggedCount = orderCount - taggedCount

  return (
    <ConfirmModal
      title="Retry Tracking Writeback"
      onConfirm={() => retryTrackingWriteback()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-0">
        <strong>
          <PluralBlock count={orderCount}>
            Are you sure you want to retry tracking writeback for {orderCount}{' '}
            <Plural word="order" />?
          </PluralBlock>
        </strong>
      </p>
      {untaggedCount > 0 && (
        <div className="alert alert--warning margin-top-20">
          <p className="fs-n0 lh-md margin-bottom-5">
            <strong>
              <PluralBlock count={untaggedCount}>
                {untaggedCount} <Plural word="order" />{' '}
                <Plural p="do" s="does" /> NOT have a writeback failure tag.
              </PluralBlock>
            </strong>
          </p>
          <p className="fs-n0 lh-md margin-bottom-10 divider--bottom">
            Retrying writeback on orders without this tag could cause some
            issues with your sales channel(s), so we will skip over them unless
            you use the following checkbox to override.
          </p>
          <div className="margin-top-10">
            <Checkbox
              className="wrap--label-unbold margin-left-n03"
              label="Retry writeback on ALL selected orders"
              name="include_untagged"
              checked={form.includeUntagged}
              onChange={() =>
                updateModalForm({
                  includeUntagged: !form.includeUntagged,
                })
              }
            />
          </div>
        </div>
      )}
    </ConfirmModal>
  )
}

ConfirmTrackingWritebackModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    includeUntagged: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  markedTrackingWritebackFailure: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    markedTrackingWritebackFailure: markedTrackingWritebackFailureSelector(
      state,
      {
        orderNumbers: form.orderNumbers,
      },
    ),
  }
}

export default formConnect(
  connect(mapStateToProps)(ConfirmTrackingWritebackModal),
  modalFormSelector,
)
