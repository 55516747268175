import PropTypes from 'prop-types'
import {useCallback, useEffect, useMemo, useRef} from 'react'
import {getState, updateForm, useAutoForm, useSelector} from '../../../store.js'
import {
  useZropdown,
  useZropdownElement,
} from '../../../common/components/Zropdown.js'
import {currentDropdownSelector} from '../../../redux/selectors/ui/index.js'
import FilterDropdown from '../../../common/components/FilterDropdown.js'
import {
  boxShapesByShipperTypeSelector,
  getBoxShapeOptions,
  labelConfigSelector,
  labelTypeSelector,
  orderTypeSelector,
  shipperTypesSelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import {
  filterOptions,
  getNounsFromValue,
  tokenizeOptions,
} from '../../../common/tokenizeOptions.js'
import className from '../../../common/className.js'
import Checkbox from '../../../common/components/Checkbox.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {
  Count,
  IfPluralNotZero,
  IfSingle,
  IfZero,
  Plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import ShipperOptions from '../../../data/shipperOptions.js'
import {
  SHIPPER_NAMES,
  SHIPPER_VENDORS,
} from '../../../common/constants/ShipperNames.js'
import useStable from '../../../common/useStable.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const PACKAGE_TYPES = 'PACKAGE_TYPES'
const PACKAGE_TYPES__SHIPPER_HEADER = 'PACKAGE_TYPES__SHIPPER_HEADER'

function propertyFunction(shipperType) {
  return `${shipperType}__box_shapes`
}

export function packageTypeLabelPropertiesFunc(shipperType, labelInfoID) {
  const shipperTypes = shipperTypesSelector(getState(), {labelInfoID})

  return shipperTypes.map(propertyFunction)
}

const VALID_LABEL_PROPERTIES = SHIPPER_VENDORS.map(propertyFunction)

function getFirstPackageTypeName(boxShapesByShipperType, boxShapeOptions) {
  for (const [shipperType, boxShapes] of Object.entries(
    boxShapesByShipperType,
  )) {
    if (boxShapes.length === 0) {
      continue
    }

    const option = boxShapeOptions.find(
      (option) =>
        option.shipperType === shipperType && option.value === boxShapes[0],
    )

    return option ? option.display : boxShapes[0]
  }
}

function filterPackageTypes(
  dropdown,
  shipperOptions,
  shipperOptionTokens,
  text,
) {
  try {
    if (!text) {
      updateForm(dropdown, {
        results: shipperOptions,
        hasFoundNothing: false,
      })

      return
    }

    const results = filterOptions(text, shipperOptionTokens)

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

function onPackageTypeSelected(labelInfoID, onChange, option) {
  const boxShapesByShipperType = boxShapesByShipperTypeSelector(getState(), {
    labelInfoID,
  })
  const box_shapes = boxShapesByShipperType[option.shipperType]

  const boxShapes = box_shapes.filter((value) => option.value !== value)

  if (boxShapes.length === box_shapes.length) {
    boxShapes.push(option.value)
  }

  updateLabelConfig(labelInfoID, {
    [`${option.shipperType}__box_shapes`]: boxShapes,
  })

  if (onChange) {
    onChange()
  }
}

function toggleAllForShipper(labelInfoID, onChange, option, boxShapeOptions) {
  const shipperType = option.value
  const boxShapesByShipperType = boxShapesByShipperTypeSelector(getState(), {
    labelInfoID,
  })
  const box_shapes = boxShapesByShipperType[shipperType]
  const options = boxShapeOptions.filter((o) => o.shipperType === shipperType)

  const boxShapes =
    box_shapes.length === options.length ? [] : options.map(({value}) => value)

  updateLabelConfig(labelInfoID, {
    [`${shipperType}__box_shapes`]: boxShapes,
  })

  if (onChange) {
    onChange()
  }
}

function Item({option, selected, disabled, isFocused, onSelect}) {
  const id = `${option.shipperType}__box_shape__${option.value}`
  const ref = useRef()

  useZropdownElement({ref, isSelected: isFocused})

  return (
    <li ref={ref} className="list__item list__item--dropdown">
      <span
        className={className`label--selectable label--selectable-pt-pb-0 flex--justify-nowrap label--selectable-left-indent margin-bottom-0 ${{
          'list__link--dropdown-arrow-focus': isFocused,
          disabled,
        }}`}
      >
        <label
          className="flex--justify-nowrap flex-grow flex--jc-left mw-unset margin-bottom-0"
          htmlFor={id}
          data-dropdown-prevent-close
        >
          <span className="margin-right-5">
            <Checkbox
              className="fs-00 v-align-middle"
              id={id}
              checked={selected}
              onClick={() => {
                if (!disabled) {
                  onSelect(option)
                }
              }}
            />
          </span>
          <span className="label--selectable__text">{option.display}</span>
        </label>
      </span>
    </li>
  )
}

Item.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    entity: PropTypes.object.isRequired,
    shipperType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
}

function ItemSeparator({option, selected, indeterminate, isFocused, onSelect}) {
  const ref = useRef()

  useZropdownElement({ref, isSelected: isFocused})

  return (
    <li
      ref={ref}
      className="list__item list__item--dropdown list__item--dropdown-header list__item--dropdown-header-pkg-type"
    >
      <span
        className={className`label--selectable label--selectable-pt-pb-0 flex--justify-nowrap margin-bottom-0 ${{
          'list__link--dropdown-arrow-focus': isFocused,
        }}`}
      >
        <label
          className="flex--justify-nowrap flex-grow flex--jc-left mw-unset align-items-center  margin-bottom-0"
          htmlFor={option.value}
          data-dropdown-prevent-close
        >
          <span>
            <Checkbox
              className="fs-00 v-align-middle"
              id={option.value}
              checked={selected}
              indeterminate={indeterminate}
              onClick={() => onSelect(option)}
            />
          </span>
          <span
            className={className`label--selectable__text label__carrier-logo lh-sm ${option.value}`}
          >
            Package Types
          </span>
        </label>
      </span>
    </li>
  )
}

ItemSeparator.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    entity: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  isFocused: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
}

export default function PackageTypesFilter({autoFocus}) {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const dropdown = `PACKAGE_TYPES_FILTER_${labelInfoID}`

  const [labelProperty] = packageTypeLabelPropertiesFunc(null, labelInfoID)
  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
    hasChanged: false,
  })
  const localOnChange = useCallback(() => {
    updateForm(dropdown, {hasChanged: true})
  }, [])

  const config = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const shipperTypes = useStable(
    useSelector((state) => shipperTypesSelector(state, {labelInfoID})),
  )
  const orderType = useSelector((state) =>
    orderTypeSelector(state, {labelInfoID}),
  )
  const labelType = useSelector((state) =>
    labelTypeSelector(state, {labelInfoID}),
  )

  const boxShapeOptions = useMemo(
    () =>
      Object.entries(ShipperOptions).reduce(
        (prev, [shipperType, shipperOptions]) => {
          if (!shipperTypes.includes(shipperType)) {
            return prev
          }

          const boxShapes = getBoxShapeOptions(
            config,
            shipperType,
            shipperOptions,
            orderType,
            labelType,
          )

          if (!boxShapes) {
            return prev
          }

          const options = []
          for (const option of boxShapes) {
            options.push({
              value: option.value,
              display: option.display,
              entity: option,
              shipperType,
              type: PACKAGE_TYPES,
              nouns: [...getNounsFromValue(option.display), shipperType],
            })
          }

          prev.push({
            value: shipperType,
            display: SHIPPER_NAMES[shipperType],
            entity: options.map(({entity}) => entity),
            type: PACKAGE_TYPES__SHIPPER_HEADER,
            nouns: new Set(
              options.reduce((prev, {nouns}) => {
                prev.push(...nouns)
                return prev
              }, []),
            ),
          })

          prev.push(...options)

          return prev
        },
        [],
      ),
    [config, shipperTypes, orderType, labelType],
  )
  const boxShapeOptionTokens = useMemo(
    () => tokenizeOptions(boxShapeOptions),
    [boxShapeOptions],
  )

  const boxShapesByShipperType = useSelector((state) =>
    boxShapesByShipperTypeSelector(state, {labelInfoID}),
  )
  const boxShapeCount = useMemo(
    () =>
      Object.values(boxShapesByShipperType).reduce(
        (prev, boxShapes) => prev + boxShapes.length,
        0,
      ),
    [boxShapesByShipperType],
  )
  const firstPackageTypeName = getFirstPackageTypeName(
    boxShapesByShipperType,
    boxShapeOptions,
  )

  useEffect(() => {
    filterPackageTypes(
      dropdown,
      boxShapeOptions,
      boxShapeOptionTokens,
      form && form.text,
    )
  }, [boxShapeOptions, form && form.text])

  const buttonRef = useRef()

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onSelect: (option) => {
        if (option.type === PACKAGE_TYPES) {
          onPackageTypeSelected(labelInfoID, localOnChange, option)
        } else {
          toggleAllForShipper(
            labelInfoID,
            localOnChange,
            option,
            boxShapeOptions,
          )
        }
      },
      autoFocus,
    })

  const currentDropdown = useSelector(currentDropdownSelector)

  useEffect(() => {
    if (!currentDropdown && form.hasChanged) {
      updateForm(dropdown, {hasChanged: false})

      if (onChange) {
        onChange()
      }
    }
  }, [currentDropdown])

  useLabelProperty({
    labelProperty,
    labelPropertiesFunc: packageTypeLabelPropertiesFunc,
    validLabelProperties: VALID_LABEL_PROPERTIES,
  })

  return (
    <li
      className={className`list__item--shipping-options list__item--shipping-options-rs flex-grow ${formatMetaClassName(
        labelProperty,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <label className="label--bold" htmlFor={dropdown}>
          <span>Package Type</span>
          <span className="required">*</span>
        </label>
        <div
          className={`wrap-outer--tag-dropdown-with-filter ${
            currentDropdown === dropdown ? 'open' : ''
          }`}
          data-dropdown={dropdown}
        >
          <button
            id={dropdown}
            ref={buttonRef}
            className="btn btn--dropdown btn--filter btn--filter-w-border w-100"
            type="button"
            disabled={form.results.length === 0}
          >
            <span className="btn__text flex">
              <span className="btn__text--no-overflow txt-overflow-ellipsis">
                <span className="txt-overflow-ellipsis">
                  <span>
                    <PluralBlock count={boxShapeCount}>
                      <IfZero>
                        <em>Select a package type...</em>
                      </IfZero>
                      <IfSingle>{firstPackageTypeName}</IfSingle>
                      <IfPluralNotZero>
                        <Count /> <Plural word="package type" />
                      </IfPluralNotZero>
                    </PluralBlock>
                  </span>
                </span>
              </span>
            </span>
          </button>
          <FilterDropdown
            dropdown={dropdown}
            form={form}
            onChangeInternal={onChangeInternal}
            onConfirm={onConfirm}
            onUp={onUp}
            onDown={onDown}
            placeholder="Search package types"
            includeSearchInput
            parentRef={buttonRef}
          >
            {form.results.map((option, index) => {
              const selectedCount = (boxShapesByShipperType[option.value] || [])
                .length

              return option.type === PACKAGE_TYPES ? (
                <Item
                  key={`${option.shipperType}__${option.value}`}
                  option={option}
                  selected={(
                    boxShapesByShipperType[option.shipperType] || []
                  ).includes(option.value)}
                  isFocused={index === form.selectedIndex}
                  onSelect={onSelectInternal}
                />
              ) : (
                <ItemSeparator
                  key={option.value}
                  option={option}
                  selected={selectedCount > 0}
                  indeterminate={
                    option.entity.length !== selectedCount &&
                    selectedCount !== 0
                  }
                  isFocused={index === form.selectedIndex}
                  onSelect={onSelectInternal}
                />
              )
            })}
            {form.hasFoundNothing && (
              <li className="list__item--dropdown list__item--dropdown-empty align-center">
                No package type match your search.
              </li>
            )}
          </FilterDropdown>
        </div>
      </div>
      <EnableLabelProperty labelProperty={labelProperty} />
    </li>
  )
}

PackageTypesFilter.propTypes = {
  dropdown: PropTypes.string,
  autoFocus: PropTypes.bool,
}
