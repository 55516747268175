import PropTypes from 'prop-types'

import Pane from './Pane.js'

export default function SingleValuePane({isLoading, title, children}) {
  return (
    <Pane title={title} isLoading={isLoading}>
      {children ? (
        <p className="fs-03 margin-top-15 margin-bottom-15">{children}</p>
      ) : (
        <dl className="list list--horiz-bar-graph centered-text hide-for-loading">
          <dt className="list__item margin-bottom-0 make-medium-dark-grey">
            <strong>There is no data to display</strong>
          </dt>
        </dl>
      )}
    </Pane>
  )
}

SingleValuePane.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}
