export default function composeReducers(...funcs) {
  return (state, action) => {
    let currentState = state

    for (var i = 0; i < funcs.length; i++) {
      currentState = funcs[i](currentState, action)
    }

    return currentState
  }
}
