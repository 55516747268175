import {
  AUSTRALIA_POST,
  CANADA_POST,
  DHL,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
} from '../../../common/constants/ShipperNames.js'
import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {useSelector} from '../../../store.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import {LABEL_PROPERTY_ATTACH_CUSTOMS_INFO} from '../SingleLabelConfigForm/AttachCustomsInfo.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [
  AUSTRALIA_POST,
  CANADA_POST,
  DHL,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
]

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
}

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__reason_for_export`]
    : []
}

const LABEL_PROPERTY_REASON_FOR_EXPORT_EXPLANATION =
  'reason_for_export_explanation'
const EXPLANATION_VALID_SHIPPER_TYPES = [
  AUSTRALIA_POST,
  CANADA_POST,
  DHL,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  VISIBLE_USPS,
]

export default function ReasonForExport() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)
  const {
    [LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]: attachCustomsInfo,
    [labelProperty]: reasonForExport,
  } = useSelector((state) => labelConfigSelector(state, {labelInfoID}))

  return (
    <div>
      <ConfigSelect
        label="Reason For Export"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [labelProperty]: value,
          })

          onChange()
        }}
        required={CANADA_POST === shipperType}
        hide={!attachCustomsInfo}
        labelProperty={labelProperty}
        labelPropertiesFunc={labelPropertiesFunc}
        validShipperTypes={VALID_SHIPPER_TYPES}
        dependantPropertiesFunc={dependantPropertiesFunc}
        shipperOptionsProperty="reason_for_export"
      />
      <ConfigTextInput
        label="Explanation"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [LABEL_PROPERTY_REASON_FOR_EXPORT_EXPLANATION]: value,
          })

          onChange()
        }}
        hide={
          !attachCustomsInfo ||
          (shipperType !== DHL && reasonForExport !== 'OTHER')
        }
        labelProperty={LABEL_PROPERTY_REASON_FOR_EXPORT_EXPLANATION}
        validShipperTypes={EXPLANATION_VALID_SHIPPER_TYPES}
        dependantPropertiesFunc={(shipperType) => [
          ...dependantPropertiesFunc(shipperType),
          ...labelPropertiesFunc(shipperType),
        ]}
      />
    </div>
  )
}
