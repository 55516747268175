import {useEffect} from 'react'

import INITIAL_STATE from '../redux/reducers/INITIAL_STATE.js'
import {dispatch} from '../store.js'
import {getRealDate} from '../common/date.js'

const SET_CURRENT_DATE = 'SET_CURRENT_DATE'

export function currentDateReducer(
  state = INITIAL_STATE.data.currentDate,
  action = {},
) {
  if (action.type === SET_CURRENT_DATE) {
    return action.payload
  }

  return state
}

function setCurrentDate() {
  dispatch({
    type: SET_CURRENT_DATE,
    payload: getRealDate(),
  })
}

export function currentDateSelector(state) {
  return state.data.currentDate
}

export default function CurrentDateListener() {
  useEffect(() => {
    setCurrentDate()

    const interval = setInterval(setCurrentDate, 15 * 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  return null
}
