import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  setOrdersStateAndEnsureProductsLoaded,
  orderSelector,
  ordersHaveTrackingCountSelector,
} from '../../../data/orders.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NotifyCartAboutTrackingDeleteCheckbox from '../../../common/components/Order/NotifyCartAboutTrackingDeleteCheckbox.js'

export const MODAL_FORM = 'CONFIRM_ORDER_CANCEL_MODAL'

export function showConfirmOrderCancelModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    willDeleteTracking: false,
    isSaving: false,
    serverError: null,
    notifyCart: false,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export async function cancelOrder(orderNumber, willDeleteTracking, notifyCart) {
  const order = orderSelector(getState(), {orderNumber})

  if (willDeleteTracking && order.shipping_info.created_date) {
    await apiverson.delete(`${order.link}/shipping_info`, {
      notify_cart: notifyCart,
    })
  }

  const {json: updatedOrder} = await apiverson.post(`${order.link}/cancel`)

  return updatedOrder
}

export async function cancelOrders() {
  try {
    const {orderNumbers, willDeleteTracking, notifyCart} =
      modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const orders = await Promise.all(
      orderNumbers.map((orderNumber) =>
        cancelOrder(orderNumber, willDeleteTracking, notifyCart),
      ),
    )

    await setOrdersStateAndEnsureProductsLoaded(orders)

    refreshOrderListAndCounts()

    const orderString = orders.length > 1 ? 'orders were' : 'order was'
    showMessageToast(`${orders.length} ${orderString} successfully cancelled.`)

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error cancelling orders: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function ConfirmOrderCancelModal({form, hasTrackingCount}) {
  const orderCount = form.orderNumbers.length

  return (
    <ConfirmModal
      title={`Cancel ${orderCount > 1 ? 'Orders' : 'Order'}`}
      onConfirm={() => cancelOrders()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-0">
        <strong>
          Are you sure you want to cancel{' '}
          {orderCount > 1 ? 'these orders' : 'this order'}?
        </strong>
      </p>
      {hasTrackingCount > 0 && (
        <>
          <div className="margin-top-10">
            <Checkbox
              className="wrap--label-unbold margin-left-n03"
              label="Delete associated shipping label and tracking info"
              name="delete_label"
              checked={form.willDeleteTracking}
              onChange={() =>
                updateModalForm({
                  willDeleteTracking: !form.willDeleteTracking,
                })
              }
            />
          </div>
          {form.willDeleteTracking && (
            <NotifyCartAboutTrackingDeleteCheckbox
              orderNumbers={form.orderNumbers}
              labelType="shipping"
              checked={form.notifyCart}
              onChange={() =>
                updateModalForm({
                  notifyCart: !form.notifyCart,
                })
              }
            />
          )}
        </>
      )}
    </ConfirmModal>
  )
}

ConfirmOrderCancelModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    willDeleteTracking: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    notifyCart: PropTypes.bool.isRequired,
  }),
  hasTrackingCount: PropTypes.number.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    hasTrackingCount: ordersHaveTrackingCountSelector(state, {
      orderNumbers: form.orderNumbers,
    }),
  }
}

export default formConnect(
  connect(mapStateToProps)(ConfirmOrderCancelModal),
  modalFormSelector,
)
