import {all, take, takeEvery} from 'redux-saga/effects'

import {CLOSE_MODAL} from '../../../actions/ui/index.js'
import confirmAttemptToAllocateModal from './confirmAttemptToAllocateModal.js'
import confirmDeallocateModal from './confirmDeallocateModal.js'
import endiciaCreditCardModal from './endiciaCreditCardModal.js'
import printPickPackModal from './printPickPackModal.js'
import exportInsuranceHistoryModal from './exportInsuranceHistoryModal.js'
import addPurchaseOrderCommentModal from './addPurchaseOrderCommentModal.js'
import editInstructionsModal from './editInstructionsModal.js'
import {
  ARCHIVE_SHIPPER,
  archiveShipperWorker,
} from '../../../../ordoro/settings/Shippers/ArchiveShipperModal.js'

export function* waitForModalConfirm() {
  const action = yield take(CLOSE_MODAL)

  return action.payload.meta.isConfirmed
}

export default function* modalSaga() {
  yield all([
    confirmAttemptToAllocateModal(),
    confirmDeallocateModal(),
    endiciaCreditCardModal(),
    printPickPackModal(),
    exportInsuranceHistoryModal(),
    addPurchaseOrderCommentModal(),
    editInstructionsModal(),
    takeEvery(ARCHIVE_SHIPPER, archiveShipperWorker),
  ])
}
