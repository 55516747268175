import PropTypes from 'prop-types'
import uniq from 'lodash/uniq.js'

import {setForm, updateForm, removeForm, getState} from '../../store.js'
import {
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  PO_SORT_OPTIONS,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
  PURCHASE_ORDER_PLURAL_URI_COMPONENT,
  PURCHASE_ORDER_STATUS_LIST,
  DEFAULT_STATUS,
} from '../../common/constants/PurchaseOrders.js'
import {
  defaultHashParamsSelector,
  expandedPOIDsSelector,
  getPODetailURL,
  poIDListSelector,
  poListQuerySelector,
  PURCHASE_ORDER_LIST,
  queryParamsSelector,
  selectedPOIDsSelector,
} from './purchaseOrderListSelectors.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {
  PURCHASE_ORDER_DETAIL_PAGE,
  PURCHASE_ORDER_LIST_PAGE,
} from '../../common/constants/Pages.js'
import api from '../../common/api.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {navigate} from '../../common/location.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import handleListSelection from '../../common/handleListSelection.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {
  TAG_FILTER_OPTIONS,
  TAG_FILTER_OR,
  TAG_TYPE__PO,
} from '../../common/constants/Tags.js'
import {setPO, setPOs} from '../../data/pos.js'
import {ensureArrayOrUndefined} from '../../common/ensure.js'

export const POListFormShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  poIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPOIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedPOIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandMode: PropTypes.string.isRequired,
})

export function setupPOListForm() {
  setForm(PURCHASE_ORDER_LIST, {
    isLoading: false,
    count: 0,
    poIDList: [],
    selectedPOIDs: [],
    expandedPOIDs: [],
    expandMode: DEFAULT_EXPAND_MODE,
  })
}

export function updatePOListForm(updates, meta) {
  updateForm(PURCHASE_ORDER_LIST, updates, meta)
}

export function removePOListForm() {
  removeForm(PURCHASE_ORDER_LIST)
}

export function navigateToPOListPage(query = {}) {
  const defaultQuery = defaultHashParamsSelector(getState())

  const pathComponents = [PURCHASE_ORDER_SINGLE_URI_COMPONENT]

  return navigate(pathComponents, {
    ...defaultQuery,
    ...query,
  })
}

export function navigateToPODetailPage(referenceID) {
  return navigate(...getPODetailURL(referenceID))
}

export function navigatePOList(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (
    ![
      PURCHASE_ORDER_PLURAL_URI_COMPONENT,
      PURCHASE_ORDER_SINGLE_URI_COMPONENT,
    ].includes(pathComponents[0])
  ) {
    return
  }

  // HACK to allow single status in url path. This code eats that status
  // and adds it to whatever status that might be present in updates
  if (
    pathComponents[0] === PURCHASE_ORDER_PLURAL_URI_COMPONENT &&
    (PURCHASE_ORDER_STATUS_LIST.includes(pathComponents[1]) ||
      !pathComponents[1])
  ) {
    updates.status = uniq([
      pathComponents[1] || DEFAULT_STATUS,
      ...(updates.status || []),
    ])
  }

  pathComponents = [PURCHASE_ORDER_SINGLE_URI_COMPONENT]

  query = {...query, ...updates}

  return navigate(pathComponents, query)
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updateStatus(status) {
  status = status.filter((s) => PURCHASE_ORDER_STATUS_LIST.includes(s))

  return {
    status,
    ...resetOffset(),
  }
}

export function setStatus(status) {
  return navigatePOList(updateStatus(status))
}

export function updatePOTagFilters(tags) {
  tags = tags || []

  if (!Array.isArray(tags)) {
    tags = [tags]
  }

  if (tags.length === 0) {
    tags = undefined
  } else {
    tags = uniq(tags)
  }

  return {
    tags,
    ...resetOffset(),
  }
}

export function setPOTagFilters(tags) {
  return navigatePOList(updatePOTagFilters(tags))
}

export function removePOTagFilter(tagName) {
  const {tags} = poListQuerySelector(getState())

  return setPOTagFilters(tags.filter((name) => name !== tagName))
}

export function updatePOUntagged(untagged) {
  return {
    untagged: untagged ? true : undefined,
    ...resetOffset(),
  }
}

export function setPOUntagged(untagged) {
  return navigatePOList(updatePOUntagged(untagged))
}

export function updatePOTagFilterBy(tag_filter_by) {
  if (!TAG_FILTER_OPTIONS.find(({value}) => tag_filter_by === value)) {
    tag_filter_by = TAG_FILTER_OR
  }

  if (tag_filter_by === TAG_FILTER_OR) {
    tag_filter_by = undefined
  }

  return {
    tag_filter_by,
    ...resetOffset(),
  }
}

export function setPOTagFilterBy(tag_filter_by) {
  return navigatePOList(updatePOTagFilterBy(tag_filter_by))
}

export function updatePOExcludeTags(exclude_tags) {
  if (!Array.isArray(exclude_tags) || exclude_tags.length === 0) {
    exclude_tags = undefined
  }

  return {
    exclude_tags,
    ...resetOffset(),
  }
}

export function setPOExcludeTags(exclude_tags) {
  return navigatePOList(updatePOExcludeTags(exclude_tags))
}

export function updateSupplierFilters(supplierIDs) {
  return {
    supplier_id: ensureArrayOrUndefined(supplierIDs),
    ...resetOffset(),
  }
}

export function setSupplierFilters(supplierIDs) {
  return navigatePOList(updateSupplierFilters(supplierIDs))
}

export function removeSupplierFilter(supplierID) {
  const {supplier_id} = poListQuerySelector(getState())

  return setSupplierFilters(supplier_id.filter((id) => id !== supplierID))
}

export function updateWarehouseFilters(warehouseIDs) {
  return {
    warehouse_id: ensureArrayOrUndefined(warehouseIDs),
    ...resetOffset(),
  }
}

export function setWarehouseFilters(warehouseIDs) {
  return navigatePOList(updateWarehouseFilters(warehouseIDs))
}

export function removeWarehouseFilter(warehouseID) {
  const {warehouse_id} = poListQuerySelector(getState())

  return setWarehouseFilters(warehouse_id.filter((id) => id !== warehouseID))
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_PER_PAGE
  }

  if (limit === DEFAULT_PER_PAGE) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updatePOListForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigatePOList(updates)
}

export function updateSort(sort) {
  if (!PO_SORT_OPTIONS.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  if (sort === DEFAULT_SORT) {
    sort = undefined
  }

  return {
    sort,
    ...resetOffset(),
  }
}

export function setSort(sort) {
  const updates = updateSort(sort)

  updatePOListForm(
    {
      sticky__sort: updates.sort,
    },
    {stickyProps: ['sticky__sort']},
  )

  return navigatePOList(updates)
}

export function updateSearchText(searchText) {
  searchText = (searchText || '').trim() || undefined

  return {
    searchText,
    ...resetOffset(),
  }
}

export function setSearchText(searchText) {
  return navigatePOList(updateSearchText(searchText))
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigatePOList(updateOffset(offset))
}

export function setCurrentPageNumber(currentPage) {
  currentPage = Number(currentPage) || 1

  updatePOListForm({
    currentPage,
  })
}

export function setQueryResults({count, purchase_order}) {
  updatePOListForm({
    poIDList: purchase_order.map((po) => po.po_id),
    count,
    selectedPOIDs: [],
    expandedPOIDs: [],
  })
}

export function setExpandedPOIDs(expandedPOIDs) {
  updatePOListForm({
    expandedPOIDs,
  })
}

export function setExpandMode(expandMode) {
  updatePOListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

export function setSelectedPOIDs(selectedPOIDs) {
  updatePOListForm({
    selectedPOIDs,
  })
}

export async function refreshPurchaseOrderList() {
  const requestToken = {}
  refreshPurchaseOrderList.requestToken = requestToken

  try {
    const currentPage = currentPageSelector(getState())
    if (currentPage !== PURCHASE_ORDER_LIST_PAGE) {
      return
    }

    updatePOListForm({isLoading: true})

    const params = queryParamsSelector(getState())

    const {json} = await api.get('/purchase_order/', params)

    if (requestToken !== refreshPurchaseOrderList.requestToken) {
      return
    }

    setPOs(json.purchase_order)

    setQueryResults(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error querying purchase orders.',
        details: err.message || err.error_message,
      },
      err,
    )
  } finally {
    updatePOListForm({isLoading: false})
  }
}

export function selectPOID(poID, isSelected, isShiftKey) {
  const selectedPOIDs = selectedPOIDsSelector(getState())
  const poIDList = poIDListSelector(getState())

  setSelectedPOIDs(
    handleListSelection({
      value: poID,
      isSelected,
      isShiftKey,
      selected: selectedPOIDs,
      list: poIDList,
    }),
  )
}

export function toggleExpanded(poID) {
  const expandedPOIDs = expandedPOIDsSelector(getState())

  const poIDs = expandedPOIDs.filter((p) => p !== poID)

  if (!expandedPOIDs.includes(poID)) {
    poIDs.push(poID)
  }

  setExpandedPOIDs(poIDs)
}

export async function updatePurchaseOrder(poID, purchaseOrder) {
  setPO(purchaseOrder)

  const currentPage = currentPageSelector(getState())

  if (
    currentPage === PURCHASE_ORDER_DETAIL_PAGE &&
    poID !== purchaseOrder.po_id
  ) {
    navigate([PURCHASE_ORDER_SINGLE_URI_COMPONENT, purchaseOrder.po_id])

    return
  }

  await refreshPurchaseOrderList()
}

export function createAndApplyTagToPOs(poIDs) {
  showEditTagModal(TAG_TYPE__PO, null, {
    addTagToPOIDs: poIDs,
  })
}
