import moment from 'moment'

import {IS_PROD_ENV} from '../../../constants/index.js'
import {StripeCommentShape} from '../../../PropTypes.js'
import {formatAmount} from '../../../constants/StripeCurrencies.js'

export default function StripeComment({
  comment: {date, chargeID, amount, currency, user},
}) {
  const isTest = !IS_PROD_ENV

  return (
    <dl className="list--order-data list--timeline-point">
      <dt className="list__item--order-data list__title--timeline-point">
        {moment(date).format('LT')}
      </dt>
      <dd className="list__item--order-data list__item--timeline-point">
        <div className="message--timeline-point">
          {`A ${formatAmount(amount, currency)} payment`}
          {' was processed via Stripe'}
          <span className="divider--left">
            <a
              className="btn--link"
              href={`https://dashboard.stripe.com${
                isTest ? '/test' : ''
              }/payments/${chargeID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View details →
            </a>
          </span>
        </div>
        {user && (
          <div className="attr--timeline-point">
            <em>– {user}</em>
          </div>
        )}
      </dd>
    </dl>
  )
}

StripeComment.propTypes = {
  comment: StripeCommentShape.isRequired,
}
