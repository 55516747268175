import {useEffect} from 'react'
import analytics from '../../common/analytics/index.js'
import {SHIP_APP_PANEL, showUpgradeModal} from '../Modals/UpgradeModal.js'

import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'

export default function BatchFeaturePage() {
  useEffect(() => {
    analytics.trackEvent('batch_feature_lock')
  }, [])

  return (
    <>
      <div className="row margin-top-30">
        <div className="medium-9 medium-centered columns align-center margin-top-30">
          <h3 className="fs-03 lh-md margin-top-5 margin-bottom-5">
            <strong>
              Take Action on Hundreds of Orders with a Single Click
            </strong>
          </h3>
          <p className="fs-02 lh-md">
            Harness the power of the ultimate shipping and order management tool
            for high-volume sellers.
          </p>
        </div>
      </div>
      <div className="row margin-top-30 flex">
        <div className="medium-6 columns">
          <img
            className="screenshot"
            src="https://storage.googleapis.com/ordoro-support-site/batch-screenshot-02.png"
            alt="Batch screenshot"
          />
        </div>
        <div className="medium-5 columns end flex--justify-col">
          <div className="margin-bottom-15">
            <p className="fs-01 lh-lg margin-bottom-25">
              To gain access to{' '}
              <a
                className="btn btn--link darker border-underline v-align-base"
                href="https://support.ordoro.com/new-batches-changes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Batches</span>
              </a>
              , you need to be on the{' '}
              <strong>Advanced or Premium Plan of our Shipping App</strong>.
            </p>
            <div>
              <ButtonPrimary
                size="x-md"
                className="meta--feature-lock-bom"
                alt
                onClick={() => showUpgradeModal({panel: SHIP_APP_PANEL})}
              >
                Upgrade Your Plan
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
