import {OrderShape} from '../../../../../common/PropTypes.js'
import {ORDER_STATUS_BY_SLUG} from '../../../../../common/constants/Orders.js'

export default function OrderStatusSymbol({order}) {
  return (
    <div
      className="status-badge inline-block tooltip--top"
      data-hint={ORDER_STATUS_BY_SLUG[order.status].name}
    >
      <strong>{ORDER_STATUS_BY_SLUG[order.status].symbol}</strong>
    </div>
  )
}

OrderStatusSymbol.propTypes = {
  order: OrderShape.isRequired,
}
