import {useSelector} from '../../../../store.js'
import {warehousesSortedByNameSelector} from '../../../../data/warehouses.js'
import {updateModalForm, modalFormSelector} from './pickupModalFunctions.js'
import Select from '../../../../common/components/Select.js'

export default function WarehouseSelect() {
  const {warehouseID} = useSelector(modalFormSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)

  if (warehouses.length > 1) {
    return (
      <Select
        className="select--modal input--lg margin-bottom-0"
        id="id_pickup_location"
        onChange={(warehouseID) =>
          updateModalForm({warehouseID}, {stickyProps: ['warehouseID']})
        }
        value={warehouseID}
      >
        {warehouses.map(({id, address: {name}}) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
    )
  }

  if (warehouses.length === 1) {
    return (
      <div className="fs-00 text--md-grey">{warehouses[0].address.name}</div>
    )
  }

  return null
}
