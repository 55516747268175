import PropTypes from 'prop-types'

import {saveCompany, companySelector} from '../../../data/company.js'
import {isPresent} from '../../../common/utils.js'
import TextInput from '../../../common/components/Form/TextInput.js'
import {
  formsSelector,
  getState,
  onlyIfAutoForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'

export const FORM = 'BILLING_EMAIL_FORM'

function setupBillingEmailForm() {
  const company = companySelector(getState())

  return {
    formName: FORM,
    initialForm: {
      billingEmail: company.billing_email,
      billingEmailHasChanged: false,
      isSaving: false,
      serverError: null,
    },
  }
}

export function updateBillingEmailForm(updates) {
  updateForm(FORM, updates)
}

export function billingEmailFormSelector(state) {
  return formsSelector(state)[FORM] || billingEmailFormSelector.default
}
billingEmailFormSelector.default = {}

export function errorsSelector(state) {
  const form = billingEmailFormSelector(state)
  const errors = {}

  if (!form) {
    return errors
  }

  if (!isPresent(form.billingEmail)) {
    errors.billingEmail = 'Billing Email is required'
    errors.preventSave = true
  }

  return errors
}

export async function saveBillingEmail() {
  try {
    const form = billingEmailFormSelector(getState())
    const params = {
      billing_email: form.billingEmail,
    }

    updateBillingEmailForm({
      isSaving: true,
      serverError: null,
    })

    const company = await saveCompany(params)

    updateBillingEmailForm({
      billingEmail: company.billing_email,
      isSaving: false,
    })
  } catch (err) {
    updateBillingEmailForm({
      isSaving: false,
      serverError: `Error saving billing email. ${
        err.error_message || err.message
      }`,
    })
  }
}

function BillingEmailForm({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        saveBillingEmail()
      }}
    >
      {form.serverError && (
        <div className="settings-list medium-12 columns margin-bottom-20">
          <div className="error error-message">{form.serverError}</div>
        </div>
      )}
      <fieldset className="margin-bottom-0">
        <ul className="list list--no-style">
          <li className="list__item row margin-bottom-15">
            <TextInput
              name="billingEmail"
              label="Billing Email"
              className="medium-12 wrap--input-tall"
              required
              errorMessage={
                form.billingEmailHasChanged ? errors.billingEmail : null
              }
              value={form.billingEmail || ''}
              onChange={(value) =>
                updateBillingEmailForm({
                  billingEmail: value,
                  billingEmailHasChanged: true,
                })
              }
            />
          </li>
          <li className="list__item row margin-bottom-10">
            <div className="medium-6 columns">
              <ButtonPrimary
                type="submit"
                isDisabled={errors.preventSave}
                isLoading={form.isSaving}
              >
                Save
              </ButtonPrimary>
            </div>
          </li>
        </ul>
      </fieldset>
    </form>
  )
}

BillingEmailForm.propTypes = {
  form: PropTypes.shape({
    billingEmail: PropTypes.string.isRequired,
    billingEmailHasChanged: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfAutoForm(BillingEmailForm, setupBillingEmailForm)
