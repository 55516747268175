import useAnimateToFollow from './useAnimateToFollow.js'

function isSameBoundingBox(boxA, boxB) {
  if (!boxA || !boxB) {
    return false
  }

  if (boxA === boxB) {
    return true
  }

  return (
    boxA.x === boxB.x &&
    boxA.y === boxB.y &&
    boxA.width === boxB.width &&
    boxA.height === boxB.height &&
    boxA.top === boxB.top &&
    boxA.right === boxB.right &&
    boxA.bottom === boxB.bottom &&
    boxA.left === boxB.left
  )
}

export default function useBoundingBox({ref}) {
  return useAnimateToFollow((prevValue) => {
    if (!ref.current) {
      return {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }
    }

    const currentValue = ref.current.getBoundingClientRect()

    return isSameBoundingBox(currentValue, prevValue) ? prevValue : currentValue
  })
}
