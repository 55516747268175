import PropTypes from 'prop-types'
import {useCallback, useMemo} from 'react'
import {createSelector} from 'reselect'

import {
  createOrderSelector,
  createOrderCommentGroupsSelector,
  isPrimeOrderSelector,
  orderAddressCanBeEditedSelector,
} from '../../../../../data/orders.js'
import {
  packageCountSelector,
  makeLabelInfoIDForOrderAndLabelTypeSelector,
} from '../../../../../data/labelInfos/index.js'
import {useInventoryAllocationSelector} from '../../../../../data/company.js'

import CompactRow from './CompactRow/index.js'
import {
  expandedOrderNumbersSelector,
  expandModeSelector,
  orderListQuerySelector,
  selectedOrderNumbersSelector,
  visibleOrderNumbersSelector,
} from '../../../../OrderListPage/orderListSelectors.js'
import {getState, useSelector} from '../../../../../store.js'
import {
  selectOrder,
  setSelectedOrderNumbers,
} from '../../../../OrderListPage/orderListActions.js'

export function selectMultipleOrders(selectedOrderNumber) {
  const selectedOrderNumbers = selectedOrderNumbersSelector(getState())
  const visibleOrderNumbers = visibleOrderNumbersSelector(getState())

  const isSelected = selectedOrderNumbers.indexOf(selectedOrderNumber) === -1
  const lastSelectedOrderNumber =
    selectedOrderNumbers[selectedOrderNumbers.length - 1]
  const indexOfFirst = visibleOrderNumbers.indexOf(lastSelectedOrderNumber)
  const indexOfSecond = visibleOrderNumbers.indexOf(selectedOrderNumber)

  const min = Math.min(indexOfFirst, indexOfSecond) || 0
  const max =
    Math.max(indexOfFirst, indexOfSecond) || visibleOrderNumbers.length - 1

  const newSelectedOrderNumbers = visibleOrderNumbers.reduce(
    (previous, orderNumber, index) => {
      if (index >= min && index <= max) {
        if (isSelected) {
          previous.push(orderNumber)
        }
      } else if (selectedOrderNumbers.indexOf(orderNumber) !== -1) {
        previous.push(orderNumber)
      }

      return previous
    },
    [],
  )

  setSelectedOrderNumbers(newSelectedOrderNumbers)
}

export const makeIsSelectedOrderSelector = () =>
  createSelector(
    selectedOrderNumbersSelector,
    (state, props) => props.orderNumber,
    (selectedOrderNumbers, orderNumber) => {
      return selectedOrderNumbers.includes(orderNumber)
    },
  )

export const makeIsExpandedOrderSelector = () =>
  createSelector(
    expandedOrderNumbersSelector,
    expandModeSelector,
    (state, props) => props.orderNumber,
    (expandedOrderNumbers, expandMode, orderNumber) => {
      if (expandMode === 'collapsed') {
        return expandedOrderNumbers.includes(orderNumber)
      }
      if (expandMode === 'expanded') {
        return !expandedOrderNumbers.includes(orderNumber)
      }
      return false
    },
  )

export default function OrderListRow({orderNumber}) {
  const orderSelector = useMemo(
    () => createOrderSelector(orderNumber),
    [orderNumber],
  )
  const isSelectedOrderSelector = useMemo(
    () => makeIsSelectedOrderSelector(),
    [],
  )
  const isExpandedOrderSelector = useMemo(
    () => makeIsExpandedOrderSelector(),
    [],
  )
  const commentGroupsSelector = useMemo(
    () => createOrderCommentGroupsSelector(orderSelector),
    [],
  )
  const labelInfoIDForOrderAndLabelType = useMemo(
    () => makeLabelInfoIDForOrderAndLabelTypeSelector(),
    [],
  )

  const order = useSelector((state) => orderSelector(state))
  const labelInfoID = useSelector((state) =>
    labelInfoIDForOrderAndLabelType(state, {
      orderNumber,
      labelType: 'shipping',
    }),
  )

  const status = useSelector((state) => orderListQuerySelector(state).status)
  const isSelected = useSelector((state) =>
    isSelectedOrderSelector(state, {orderNumber}),
  )
  const isExpanded = useSelector((state) =>
    isExpandedOrderSelector(state, {orderNumber}),
  )
  const isPrimeOrder = useSelector((state) =>
    isPrimeOrderSelector(state, {orderNumber}),
  )
  const orderAddressCanBeEdited = useSelector((state) =>
    orderAddressCanBeEditedSelector(state, {orderNumber}),
  )
  const packageCount = useSelector((state) =>
    packageCountSelector(state, {labelInfoID}),
  )
  const useInventoryAllocation = useSelector((state) =>
    useInventoryAllocationSelector(state),
  )

  const selectOrderCallback = useCallback(
    (event) => {
      if (event.nativeEvent.shiftKey) {
        selectMultipleOrders(orderNumber)
      } else {
        selectOrder(orderNumber)
      }
    },
    [orderNumber],
  )

  return (
    <CompactRow
      isExpanded={isExpanded}
      isSelected={isSelected}
      order={order}
      orderNumber={orderNumber}
      labelInfoID={labelInfoID}
      status={status}
      isPrimeOrder={isPrimeOrder}
      orderAddressCanBeEdited={orderAddressCanBeEdited}
      commentGroupsSelector={commentGroupsSelector}
      packageCount={packageCount}
      selectOrder={selectOrderCallback}
      useInventoryAllocation={useInventoryAllocation}
    />
  )
}

OrderListRow.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
