import {
  VALIDATING_USER,
  VALID_USER,
  INVALID_USER,
} from '../../../common/constants/User.js'

export function userValiditySelector(state) {
  return state.ui.userValidity
}

export function isValidatingUserSelector(state) {
  return userValiditySelector(state) === VALIDATING_USER
}

export function isUserValidSelector(state) {
  return userValiditySelector(state) === VALID_USER
}

export function isUserInvalidSelector(state) {
  return userValiditySelector(state) === INVALID_USER
}
