import PropTypes from 'prop-types'
import classNames from 'classnames'
import BaseCheckbox from '../../../common/components/Checkbox.js'

export default function Checkbox({
  errorMessage,
  label,
  id,
  unboldLabel,
  children,
  ...props
}) {
  id = id || 'unIDed_checkbox'

  return (
    <>
      <label
        htmlFor={`id_${id}`}
        className={classNames({'unbold-label': unboldLabel})}
      >
        <BaseCheckbox
          className="margin-left-3 margin-right-5 margin-bottom-0"
          id={`id_${id}`}
          {...props}
        />
        <span className={classNames({'op-50': props.disabled})}>{label}</span>
      </label>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
      {props.checked && children && (
        <div className="wrap--nested-form">{children}</div>
      )}
    </>
  )
}

Checkbox.propTypes = {
  errorMessage: PropTypes.string,
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  unboldLabel: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}
