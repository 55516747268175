import PropTypes from 'prop-types'
import {useEffect} from 'react'
import get from 'lodash/get.js'
import nunjucks from 'nunjucks'
import {createSelector} from 'reselect'

import {
  setForm,
  updateForm,
  removeForm,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {
  BarcodeLayouts,
  BarcodeValueOptions,
  BarcodeTextOptions,
  LOCATION_IN_WAREHOUSE_TEMPLATE,
  LOW_STOCK_THRESHOLD_TEMPLATE,
  CUSTOM_TEMPLATE,
} from '../../../common/constants/BarcodeLabel.js'
import {formatAbodeURL} from '../../../common/abode.js'
import {parseNonZeroPositiveNumber} from '../../../common/parseNumbers.js'
import {isPresent} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/Form/TextInput.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ButtonSendToPrinter from '../../../common/components/Button/ButtonSendToPrinter.js'
import Select from '../../../common/components/Form/Select.js'
import {warehousesSortedByNameSelector} from '../../../data/warehouses.js'
import {barcodePrinterIDSelector} from '../../../data/printerSettings.js'
import {currencySymbolSelector} from '../../../data/company.js'
import {productsSelector} from '../../../data/products.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {
  LAYOUT_BARCODE,
  layoutsByTypeSortedByName,
} from '../../../data/layout.js'

const templateEnv = new nunjucks.Environment([], {autoescape: false})

export const BARCODE_LABEL_MODAL = 'BARCODE_LABEL_MODAL'

export function showBarcodeLabelModal(skus) {
  setForm(BARCODE_LABEL_MODAL, {
    skus,
    layout: BarcodeLayouts[0].value,
    valueType: BarcodeValueOptions[0].value,
    customValue: '',
    textType: BarcodeTextOptions[0].value,
    customText: '',
    layoutID: null,
    warehouseID: null,
    copyCount: '1',
  })
}

export function updateModalForm(...args) {
  updateForm(BARCODE_LABEL_MODAL, ...args)
}

export function closeModal() {
  removeForm(BARCODE_LABEL_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[BARCODE_LABEL_MODAL]
}

export const productParamsSelector = createSelector(
  modalFormSelector,
  productsSelector,
  currencySymbolSelector,
  ({skus, warehouseID}, products, currencySymbol) =>
    skus.map((sku) => {
      const product = products[sku]

      return {
        sku: get(product, 'sku', ''),
        name: get(product, 'name', ''),
        upc: get(product, 'upc', ''),
        asin: get(product, 'asin', ''),
        cost: get(product, 'cost', ''),
        price: get(product, 'price', ''),
        currency_symbol: currencySymbol,
        ...(() => {
          if (!warehouseID) {
            return {}
          }

          const warehouse = get(product, 'warehouses', []).find(
            ({id}) => id === Number(warehouseID),
          )

          return {
            location_in_warehouse:
              get(warehouse, 'location_in_warehouse') || '',
            low_stock_threshold: get(warehouse, 'low_stock_threshold') || '',
          }
        })(),
      }
    }),
)

export const valuesSelector = createSelector(
  modalFormSelector,
  productParamsSelector,
  ({valueType, customValue}, products) => {
    try {
      const template = nunjucks.compile(
        valueType === 'custom' ? customValue : valueType,
        templateEnv,
      )

      return products.map((product) => template.render(product))
    } catch (err) {
      return products.map(() => err.message)
    }
  },
)

export const textsSelector = createSelector(
  modalFormSelector,
  productParamsSelector,
  ({textType, customText}, products) => {
    try {
      const template = nunjucks.compile(
        textType === 'custom' ? customText : textType,
        templateEnv,
      )

      return products.map((product) => template.render(product))
    } catch (err) {
      return products.map(() => err.message)
    }
  },
)

export const barcodePreviewURLSelector = createSelector(
  modalFormSelector,
  valuesSelector,
  textsSelector,
  ({layout, copyCount, layoutID}, values, texts) => {
    if (values.length === 0) {
      return null
    }

    const params = {
      render: JSON.stringify([
        'Barcode',
        {
          layout,
          copyCount: parseNonZeroPositiveNumber(copyCount) || 1,
          showValue: true,
          values: values.map((value, index) => [value, texts[index]]),
        },
      ]),
    }

    if (layoutID) {
      params.layout_id = layoutID
    }

    return formatAbodeURL('/render', params)
  },
)

export const barcodeLabelModalErrorsSelector = createSelector(
  modalFormSelector,
  valuesSelector,
  (form, values) => {
    const errors = {}

    if (values.filter((value) => !isPresent(value)).length !== 0) {
      errors.valueType = 'Not all SKUs have values for that given type'
    }

    return errors
  },
)

export const needsWarehouseSelector = createSelector(
  modalFormSelector,
  ({valueType, textType}) =>
    !![valueType, textType].find((type) =>
      [
        LOCATION_IN_WAREHOUSE_TEMPLATE,
        LOW_STOCK_THRESHOLD_TEMPLATE,
        CUSTOM_TEMPLATE,
      ].includes(type),
    ),
)

function updateLayoutID(layoutID) {
  updateModalForm(
    {layoutID: Number(layoutID) || null},
    {stickyProps: ['layoutID']},
  )
}

function BarcodeLabelModal({form}) {
  const barcodePreviewURL = useSelector(barcodePreviewURLSelector)
  const errors = useSelector(barcodeLabelModalErrorsSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const values = useSelector(valuesSelector)
  const texts = useSelector(textsSelector)
  const needsWarehouse = useSelector(needsWarehouseSelector)
  const barcodePrinterID = useSelector(barcodePrinterIDSelector)
  const barcodeLayouts =
    useSelector(layoutsByTypeSortedByName)[LAYOUT_BARCODE] || []

  useEffect(() => {
    if (!form.warehouseID) {
      updateModalForm({
        warehouseID: `${get(warehouses, '0.id', '')}`,
      })
    }
  }, [form.warehouseID])

  useEffect(() => {
    if (form.layoutID && !barcodeLayouts.find(({id}) => id === form.layoutID)) {
      updateLayoutID(null)
    }
  }, [form.layoutID])

  return (
    <ConfirmModal
      title={
        form.skus.length > 1
          ? `Print Barcode Labels (${form.skus.length})`
          : 'Print Barcode Label'
      }
      modalSize="sm"
      onConfirm={() => {
        window.open(barcodePreviewURL, '_blank')

        closeModal()
      }}
      onCancel={() => closeModal()}
      confirmText="View/Print"
      cancelText="Cancel"
      MiddleButtons={() => (
        <div className="margin-right-10">
          <ButtonSendToPrinter
            className="margin-bottom-5 meta-barcodelabelmodal-button-instaprint"
            title="Barcode Label"
            pdfLink={barcodePreviewURL}
            printerID={barcodePrinterID}
            onClick={() => closeModal({form})}
            documentCount={form.skus.length}
          />
        </div>
      )}
    >
      <dl className="list">
        {form.skus.length == 1 && (
          <dt className="list__title--label-success lh-md margin-top-0 margin-bottom-15">
            <strong>SKU:</strong> <span className="unbold">{form.skus[0]}</span>
          </dt>
        )}
        <dd className="list__item margin-bottom-15">
          <Select
            label="Size"
            id="layout"
            value={form.layout}
            onChange={(value) =>
              updateModalForm({layout: value}, {stickyProps: ['layout']})
            }
          >
            {BarcodeLayouts.map((layout) => (
              <option key={layout.value} value={layout.value}>
                {layout.display}
              </option>
            ))}
          </Select>
        </dd>
        {barcodeLayouts.length > 0 && (
          <dd className="list__item margin-bottom-15">
            <Select
              label="Layout"
              id="layout_id"
              value={form.layoutID || ''}
              onChange={updateLayoutID}
            >
              <option value="">Standard</option>
              {barcodeLayouts.map((layout) => (
                <option key={layout.id} value={layout.id}>
                  {layout.name}
                </option>
              ))}
            </Select>
          </dd>
        )}
        <dd className="list__item">
          <Select
            label="Barcode Value"
            name="valueType"
            value={form.valueType}
            onChange={(value) =>
              updateModalForm({valueType: value}, {stickyProps: ['valueType']})
            }
          >
            {BarcodeValueOptions.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </Select>
          {errors.valueType && (
            <small className="error">{errors.valueType}</small>
          )}
          {values.length && !errors.valueType && (
            <small className="fs-n1 text--md-grey">Example: {values[0]}</small>
          )}
        </dd>
        {form.valueType === 'custom' && (
          <dd className="list__item">
            <TextInput
              label="Custom Barcode"
              name="customValue"
              className="padding-left-0 w-50"
              value={form.customValue}
              onChange={(value) =>
                updateModalForm(
                  {customValue: value},
                  {stickyProps: ['customValue']},
                )
              }
            />
          </dd>
        )}
        <dd className="list__item clear-left">
          <Select
            label="Additional Text"
            name="textType"
            value={form.textType}
            onChange={(value) =>
              updateModalForm({textType: value}, {stickyProps: ['textType']})
            }
          >
            {BarcodeTextOptions.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </Select>
          {texts.length && (
            <small className="fs-n1 text--md-grey">
              Example: {texts[0] || 'n/a'}
            </small>
          )}
        </dd>
        {form.textType === 'custom' && (
          <dd className="list__item">
            <TextInput
              label="Custom Text"
              name="customValue"
              className="padding-left-0 w-50"
              value={form.customText}
              onChange={(value) =>
                updateModalForm(
                  {customText: value},
                  {stickyProps: ['customText']},
                )
              }
            />
          </dd>
        )}
        {needsWarehouse && (
          <dd className="list__item clear-left margin-bottom-15">
            <Select
              label="Warehouse"
              name="warehouseID"
              value={form.warehouseID || ''}
              onChange={(value) =>
                updateModalForm(
                  {warehouseID: value},
                  {stickyProps: ['warehouseID']},
                )
              }
            >
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.address.name}
                </option>
              ))}
            </Select>
          </dd>
        )}
        <dd className="list__item clear-left">
          <label htmlFor="copy_count">No. of Copies</label>
          <NumberInput
            id="copyCount"
            value={form.copyCount}
            onChange={(value) =>
              updateModalForm({
                copyCount: `${value}`,
              })
            }
            min={1}
          />
        </dd>
      </dl>
    </ConfirmModal>
  )
}

BarcodeLabelModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    copyCount: PropTypes.string.isRequired,
    valueType: PropTypes.string.isRequired,
    textType: PropTypes.string.isRequired,
    layout: PropTypes.string.isRequired,
    layoutID: PropTypes.number,
    customValue: PropTypes.string.isRequired,
    customText: PropTypes.string.isRequired,
    warehouseID: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(BarcodeLabelModal, modalFormSelector)
