import {UPS} from '../../../../common/constants/ShipperNames.js'
import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function BulkMailInnovations() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const isMailInnovations = useSelector(
    (state) =>
      !!labelConfigSelector(state, {
        labelInfoID,
      }).is_mail_innovations,
  )

  if (shipperType !== UPS) {
    return null
  }

  return (
    <li className="list__item--shipping-options">
      <label
        className="margin-bottom-0 meta-bulklabelconfigform-input-mailinnovations"
        htmlFor="mail_innovations"
      >
        <input
          type="checkbox"
          className="margin-right-5"
          id="mail_innovations"
          checked={isMailInnovations}
          onChange={(event) => {
            updateLabelConfig(labelInfoID, {
              is_mail_innovations: event.target.checked,
            })
          }}
        />
        <span>Mail Innovations</span>
      </label>
    </li>
  )
}
