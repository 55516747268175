import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {onlyIfAutoForm} from '../../store.js'
import Select from '../../common/components/Select.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import FinancialStats from './FinancialStats.js'
import {
  setQuickAnalyticsForm,
  refreshQuickAnalytics,
  updateQuickAnalyticsForm,
} from './quickAnalyticsFunctions.js'
import ShippingStats from './ShippingStats.js'
import ProductStats from './ProductStats.js'
import {
  hasAnalyticsFinancialPermissionSelector,
  hasAnalyticsProductPermissionSelector,
  hasAnalyticsShippingPermissionSelector,
} from '../../data/me.js'
import {useAnalyticsSelector} from '../../data/company.js'

function QuickAnalyticsBase({children}) {
  return (
    <div className="medium-7 medium-offset-1 columns border-right--light margin-bottom-30 outer-wrap--dashboard-stats">
      {children}
    </div>
  )
}
QuickAnalyticsBase.propTypes = {
  children: PropTypes.node,
}

function QuickAnalytics({form}) {
  const useAnalytics = useSelector(useAnalyticsSelector)
  const hasAnalyticsFinancialPermission = useSelector(
    hasAnalyticsFinancialPermissionSelector,
  )
  const hasAnalyticsShippingPermission = useSelector(
    hasAnalyticsShippingPermissionSelector,
  )
  const hasAnalyticsProductPermission = useSelector(
    hasAnalyticsProductPermissionSelector,
  )

  useEffect(() => {
    refreshQuickAnalytics()
  }, [form.dateRange, useAnalytics])

  return (
    <QuickAnalyticsBase>
      <div className="wrap--dashboard-stats">
        <div className="row margin-bottom-25">
          <div className="medium-12 columns">
            <div className="flex--justify">
              <div className="flex">
                <h3 className="fs-03 margin-top-0 margin-bottom-0 margin-right-15">
                  <strong>Quick Stats</strong>
                </h3>
                <div className="flex--justify-col">
                  <Select
                    value={form.dateRange}
                    onChange={(value) =>
                      updateQuickAnalyticsForm({dateRange: value})
                    }
                  >
                    <option value="7 days">Last 7 Days</option>
                    <option value="15 days">Last 15 Days</option>
                    <option value="30 days">Last 30 Days</option>
                    <option value="3 months">Last 3 Months</option>
                    <option value="12 months">Last 12 Months</option>
                  </Select>
                </div>
              </div>
              <div className="flex--justify-col wrap--meta-data">
                {form.lastSuccessfulDataSync ? (
                  <div>
                    <em className="fs-n0 lh-sm text--lt-grey make-block align-right margin-bottom-3">
                      <strong>Data Updated:</strong>{' '}
                      <FormattedDate
                        value={form.lastSuccessfulDataSync}
                        format="MMM D, YYYY [at] h:mma"
                      />
                    </em>
                    <em className="fs-n1 lh-sm text--lt-grey make-block align-right op-75">
                      (Updated approx. twice per hour)
                    </em>
                  </div>
                ) : (
                  <div className="loading align-center">
                    <span className="spinner--x-sm v-align-middle" />{' '}
                    <em className="fs-n0 lh-sm text--lt-grey">
                      Updating data...
                    </em>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {hasAnalyticsFinancialPermission && <FinancialStats />}
        {hasAnalyticsShippingPermission && <ShippingStats />}
        {hasAnalyticsProductPermission && <ProductStats />}
      </div>
    </QuickAnalyticsBase>
  )
}

QuickAnalytics.propTypes = {
  form: PropTypes.shape({
    dateRange: PropTypes.string.isRequired,
    lastSuccessfulDataSync: PropTypes.string,
  }),
}

export default onlyIfAutoForm(QuickAnalytics, setQuickAnalyticsForm, () => (
  <QuickAnalyticsBase />
))
