import {setData} from './data.js'
import {setError as setUploadError} from './upload.js'

export const SET_COLUMNS = 'ordoro/ui/dataImport/SET_COLUMNS'
export const SET_ROWS = 'ordoro/ui/dataImport/SET_ROWS'
export const SET_IS_PROCESSING_CSV =
  'ordoro/ui/dataImport/SET_IS_PROCESSING_CSV'
export const SET_IS_VALIDATING_DATA =
  'ordoro/ui/dataImport/SET_IS_VALIDATING_DATA'
export const SET_IS_SAVING = 'ordoro/ui/dataImport/SET_IS_SAVING'
export const SET_STOP_BLOCK = 'ordoro/ui/dataImport/SET_STOP_BLOCK'
export const SET_CURRENT = 'ordoro/ui/dataImport/SET_CURRENT'
export const SET_COLUMNS_TO_DATA_MAP =
  'ordoro/ui/dataImport/SET_COLUMNS_TO_DATA_MAP'
export const SET_COLUMN_ERRORS = 'ordoro/ui/dataImport/SET_COLUMN_ERRORS'
export const SET_CURRENT_DATA_INDEX =
  'ordoro/ui/dataImport/SET_CURRENT_DATA_INDEX'
export const SET_WILL_SHOW_ONLY_ERRORS =
  'ordoro/ui/dataImport/SET_WILL_SHOW_ONLY_ERRORS'
export const SET_WILL_STOP_SAVING_ON_ERRORS =
  'ordoro/ui/dataImport/SET_WILL_STOP_SAVING_ON_ERRORS'

export function setCurrent(value) {
  return {
    meta: {sticky: SET_CURRENT},
    type: SET_CURRENT,
    payload: value,
  }
}

export function setColumns(value) {
  return {
    type: SET_COLUMNS,
    payload: value,
  }
}

export function setRows(rows) {
  rows = rows.reduce((prev, row) => {
    // if row contains no value, skip
    if (!row.every((value) => !value)) {
      prev.push(row)
    }

    return prev
  }, [])
  return {
    type: SET_ROWS,
    payload: rows,
  }
}

export function setIsProcessingCSV(value) {
  return {
    type: SET_IS_PROCESSING_CSV,
    payload: value,
  }
}

export function setIsValidatingData(value) {
  return {
    type: SET_IS_VALIDATING_DATA,
    payload: value,
  }
}

export function setIsSaving(value) {
  return {
    type: SET_IS_SAVING,
    payload: value,
  }
}

export function setStopBlock(value) {
  return {
    type: SET_STOP_BLOCK,
    payload: value,
  }
}

export function setColumnsToDataMap(value) {
  return {
    type: SET_COLUMNS_TO_DATA_MAP,
    payload: value,
  }
}

export function setColumnErrors(value) {
  return {
    type: SET_COLUMN_ERRORS,
    payload: value,
  }
}

export function setCurrentDataIndex(value) {
  return {
    type: SET_CURRENT_DATA_INDEX,
    payload: value,
  }
}

export function setWillShowOnlyErrors(value) {
  return {
    type: SET_WILL_SHOW_ONLY_ERRORS,
    payload: value,
  }
}

export function setWillStopSavingOnErrors(value) {
  return {
    type: SET_WILL_STOP_SAVING_ON_ERRORS,
    payload: value,
  }
}

export function clear() {
  return (dispatch) => {
    dispatch(setIsProcessingCSV(false))
    dispatch(setIsValidatingData(false))
    dispatch(setIsSaving(false))
    dispatch(setStopBlock(false))
    dispatch(setColumns([]))
    dispatch(setRows([]))
    dispatch(setData([]))
    dispatch(setCurrentDataIndex(0))
    dispatch(setColumnsToDataMap({}))
    dispatch(setColumnErrors({}))
    dispatch(setUploadError(''))
  }
}

export function remapColumns() {
  return (dispatch) => {
    dispatch(setData([]))
    dispatch(setCurrentDataIndex(0))
  }
}
