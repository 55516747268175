import Checkbox from '../../../../common/components/Checkbox.js'
import Radio from '../../../../common/components/Radio.js'
import {
  AWAITING_FULFILLMENT,
  CANCELLED,
  DROPSHIPMENT_REQUESTED,
  SHIPPED,
  SHIP_TO_ADDRESS_VALIDATION_OPTIONS,
} from '../../../../common/constants/Orders.js'
import {useSelector} from '../../../../store.js'
import {
  modalFormSelector,
  updateModalForm,
} from './orderListFilterModalFunctions.js'
import {canUseBatchesSelector} from '../../../../data/batches.js'

const shipToCountryOptions = [
  {value: '', display: 'All'},
  {value: 'US', display: 'US Addresses'},
  {value: '-US', display: 'Non-US Addresses'},
]

export default function MiscFilterPanel() {
  const form = useSelector(modalFormSelector)
  const canUseBatches = useSelector(canUseBatchesSelector)

  if (form.status.length === 1 && form.status.includes(CANCELLED)) {
    return null
  }

  return (
    <div className="wrap--modal-filters wrap--modal-filters-misc">
      <div className="wrap__modal-subheader margin-bottom-25">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0 flex--justify-col">
          <strong>Misc</strong>
        </h4>
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {(form.status.length === 0 || form.status.includes(SHIPPED)) && (
          <li className="list__item list__item--modal-filters">
            <Checkbox
              mode="fancy"
              id="unprinted"
              checked={form.showUnprintedOnly}
              onChange={() =>
                updateModalForm({
                  showUnprintedOnly: !form.showUnprintedOnly,
                })
              }
              label="Orders with Unprinted Labels"
            />
          </li>
        )}
        {(form.status.length === 0 ||
          form.status.includes(AWAITING_FULFILLMENT)) && (
          <li className="list__item list__item--modal-filters">
            <Checkbox
              mode="fancy"
              id="similar_shipto"
              checked={form.hasSimilarOpenAddresses}
              onChange={() =>
                updateModalForm({
                  hasSimilarOpenAddresses: !form.hasSimilarOpenAddresses,
                })
              }
              label="Orders w/ Similar Ship-To"
            />
          </li>
        )}
        {(form.status.length === 0 ||
          form.status.includes(SHIPPED) ||
          form.status.includes(AWAITING_FULFILLMENT)) && (
          <li className="list__item list__item--modal-filters">
            <Checkbox
              mode="fancy"
              id="ship_to_is_bill_to"
              checked={form.shipToIsBillTo === false}
              onChange={() =>
                updateModalForm({
                  shipToIsBillTo: form.shipToIsBillTo === false ? null : false,
                })
              }
              label="Orders w/ Different Bill-To"
            />
          </li>
        )}
        {(form.status.length === 0 ||
          form.status.includes(DROPSHIPMENT_REQUESTED) ||
          form.status.includes(AWAITING_FULFILLMENT)) && (
          <li className="list__item list__item--modal-filters">
            <Checkbox
              mode="fancy"
              id="has_revision"
              checked={form.hasRevision === true}
              onChange={() =>
                updateModalForm({
                  hasRevision: !form.hasRevision,
                })
              }
              label="Orders w/ External Revisions"
            />
          </li>
        )}
      </ul>
      {(form.status.length === 0 ||
        form.status.includes(DROPSHIPMENT_REQUESTED) ||
        form.status.includes(AWAITING_FULFILLMENT)) && (
        <div className="margin-top-10 margin-bottom-30">
          <div className="fs-00">
            <strong>Orders with Shipping Rates Applied</strong>
          </div>
          <div className="flex margin-top-15">
            <div className="margin-right-25">
              <Radio
                mode="fancy"
                id="carrier_error_all"
                value="null"
                checked={form.carrier_error === null}
                onChange={() => updateModalForm({carrier_error: null})}
                label="All"
              />
            </div>
            <div className="margin-right-25">
              <Radio
                mode="fancy"
                id="carrier_error_true"
                value="true"
                checked={form.carrier_error === true}
                onChange={() => updateModalForm({carrier_error: true})}
                label="Has Errors from Carrier"
              />
            </div>
            <div>
              <Radio
                mode="fancy"
                id="carrier_error_false"
                value="false"
                checked={form.carrier_error === false}
                onChange={() => updateModalForm({carrier_error: false})}
                label="No Errors from Carrier"
              />
            </div>
          </div>
        </div>
      )}
      {canUseBatches && (
        <div className="margin-top-10 margin-bottom-30">
          <div className="fs-00">
            <strong>Orders in Batches</strong>
          </div>
          <div className="flex margin-top-15">
            <div className="margin-right-25">
              <Radio
                mode="fancy"
                id="in_batch_all"
                value="null"
                checked={form.inBatch === null}
                onChange={() => updateModalForm({inBatch: null})}
                label="All"
              />
            </div>
            <div className="margin-right-25">
              <Radio
                mode="fancy"
                id="in_batch_true"
                value="true"
                checked={form.inBatch === true}
                onChange={() => updateModalForm({inBatch: true})}
                label="In a Batch"
              />
            </div>
            <div>
              <Radio
                mode="fancy"
                id="in_batch_false"
                value="false"
                checked={form.inBatch === false}
                onChange={() => updateModalForm({inBatch: false})}
                label="Not in a Batch"
              />
            </div>
          </div>
        </div>
      )}
      {(form.status.length === 0 ||
        form.status.includes(SHIPPED) ||
        form.status.includes(AWAITING_FULFILLMENT)) && (
        <>
          <div className="margin-top-10 margin-bottom-30">
            <div className="fs-00">
              <strong>Ship-To Address Validation</strong>
            </div>
            <div className="flex margin-top-15">
              {SHIP_TO_ADDRESS_VALIDATION_OPTIONS.map((option, index) => (
                <div
                  key={option.value}
                  className={
                    index !== SHIP_TO_ADDRESS_VALIDATION_OPTIONS.length - 1
                      ? 'margin-right-25'
                      : ''
                  }
                >
                  <Radio
                    mode="fancy"
                    id={`ship_to_status_${option.value}`}
                    value={option.value}
                    checked={form.shipToStatus === option.value}
                    onChange={() =>
                      updateModalForm({shipToStatus: option.value})
                    }
                    label={option.display}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="margin-bottom-20">
            <div className="fs-00">
              <strong>Ship-To Country</strong>
            </div>
            <div className="flex margin-top-15">
              {shipToCountryOptions.map((option, index) => (
                <div
                  key={option.value}
                  className={
                    index !== shipToCountryOptions.length - 1
                      ? 'margin-right-25'
                      : ''
                  }
                >
                  <Radio
                    mode="fancy"
                    id={`ship_to_country_${option.value}`}
                    value={option.value}
                    checked={form.shipToCountry === option.value}
                    onChange={() =>
                      updateModalForm({shipToCountry: option.value})
                    }
                    label={option.display}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
