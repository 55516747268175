import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  PackingListShape,
  CurrentPackingListShape,
} from '../../../common/PropTypes.js'
import {SETTINGS_PROFILES_URL} from '../../../redux/actions/ui/settings/packingLists/index.js'
import Form from './Form.js'

export default function EditForm(props) {
  const {
    packingList,
    currentPackingList,
    isNew,
    selectedProfileTab,
    isSaving,
    isUploading,
    logoURL,
    errors,
    hasErrors,
    updateCurrentPackingList,
    savePackingList,
    handleLogoFile,
  } = props

  const editLink = `${SETTINGS_PROFILES_URL}/${selectedProfileTab}/${packingList.id}`
  const isExpanded = packingList.id === currentPackingList.id
  const isDefault = packingList.is_default

  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
      })}
    >
      <div className="clearfix">
        <strong
          className={classNames('settings-list-item-title medium-5 columns', {
            default: isDefault,
          })}
        >
          <span className="make-block warehouse-name-wrap">
            <span
              className="icon warehouse-status-icon x-sm margin-right-1"
              aria-hidden="true"
            />
            <span>{packingList.name}</span>
          </span>
          {isDefault && (
            <span className="make-block default-callout">
              <span>DEFAULT</span>
            </span>
          )}
        </strong>
        <span className="medium-2 columns end right-aligned-text right">
          <a
            className="inline-text-button settings-list-item-button edit-url"
            href={editLink}
          >
            <span
              className="icon icon-edit icon-block x-sm margin-right-2"
              aria-hidden="true"
            />
            <span className="button-text">Edit</span>
          </a>
        </span>
      </div>
      {isExpanded && (
        <Form
          currentPackingList={currentPackingList}
          isNew={isNew}
          selectedProfileTab={selectedProfileTab}
          isSaving={isSaving}
          isUploading={isUploading}
          logoURL={logoURL}
          errors={errors}
          hasErrors={hasErrors}
          updateCurrentPackingList={updateCurrentPackingList}
          savePackingList={savePackingList}
          handleLogoFile={handleLogoFile}
        />
      )}
    </li>
  )
}

EditForm.propTypes = {
  packingList: PackingListShape,
  currentPackingList: CurrentPackingListShape.isRequired,
  isNew: PropTypes.bool.isRequired,
  selectedProfileTab: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  logoURL: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateCurrentPackingList: PropTypes.func.isRequired,
  savePackingList: PropTypes.func.isRequired,
  handleLogoFile: PropTypes.func.isRequired,
}
