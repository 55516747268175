import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'

import {
  ErrorsShape,
  DataImportColumnsShape,
  DataImportColumnsToDataMapShape,
} from '../../../../common/PropTypes.js'
import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  const {columns, columnsToDataMap, columnErrors, updateColumnMapping} = props

  if (isEmpty(columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-12" {...props}>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.sku}
          errorMessage={columnErrors.sku}
          name="sku"
          label="SKU"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.url}
          errorMessage={columnErrors.url}
          name="url"
          label="URL"
          onChange={updateColumnMapping}
        />
      </Row>
      {/*
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.is_default}
          errorMessage={columnErrors.is_default}
          name="is_default"
          label="Is Default"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.cart_image_id}
          errorMessage={columnErrors.cart_image_id}
          name="cart_image_id"
          label="Cart Image ID"
          onChange={updateColumnMapping}
        />
      </Row>
      */}
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: DataImportColumnsShape.isRequired,
  columnsToDataMap: DataImportColumnsToDataMapShape.isRequired,
  columnErrors: ErrorsShape.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
