import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import api from '../../../common/api.js'
import formConnect from '../../../common/formConnect.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {ensurePO} from '../../../data/pos.js'

export const MODAL_FORM = 'DELETE_GOODS_RECEIPT_MODAL_FORM'
export const DELETE_GOODS_RECEIPT = 'DELETE_GOODS_RECEIPT'

export function showDeleteGoodsReceiptModal(goodsReceiptIDs) {
  return setForm(MODAL_FORM, {
    goodsReceiptIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function deleteGoodsReceipt() {
  return {
    type: DELETE_GOODS_RECEIPT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* deleteGoodsReceiptModalWorker() {
  try {
    yield put(updateModalForm({isSaving: true, serverError: null}))

    const {goodsReceiptIDs} = yield select(modalFormSelector)

    const responses = yield all(
      goodsReceiptIDs.map((goodsReceiptID) =>
        call(
          api.delete,
          `/goods_receipt/${encodeURIComponent(goodsReceiptID)}/`,
        ),
      ),
    )

    const poIDs = Object.keys(
      responses.reduce((prev, {json: {po_id}}) => {
        prev[po_id] = true

        return prev
      }, {}),
    )

    yield all(poIDs.map((poID) => call(ensurePO, poID, {reload: true})))

    yield put(closeModal())

    const grCount = goodsReceiptIDs.length
    yield call(
      showMessageToast,
      `${grCount} Goods receipt${grCount !== 1 ? 's' : ''} ${
        grCount !== 1 ? 'were' : 'was'
      } deleted`,
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error deleting goods receipt: ${
          err.message || err.error_message
        }`,
        isSaving: false,
      }),
    )
  }
}

function DeleteGoodsReceiptModal({form, ...props}) {
  const grCount = form.goodsReceiptIDs.length

  return (
    <ConfirmModal
      title="Delete Goods Receipt"
      onConfirm={() => props.deleteGoodsReceipt()}
      onCancel={() => props.closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      {`Are you sure you want to delete ${grCount} goods receipt${
        grCount !== 1 ? 's' : ''
      }?`}
    </ConfirmModal>
  )
}

DeleteGoodsReceiptModal.propTypes = {
  form: PropTypes.shape({
    goodsReceiptIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  deleteGoodsReceipt: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  deleteGoodsReceipt,
  updateModalForm,
  closeModal,
}

export default formConnect(
  connect(null, mapDispatchToProps)(DeleteGoodsReceiptModal),
  modalFormSelector,
)
