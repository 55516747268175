import PropTypes from 'prop-types'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../store.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import CalendarScheduler from '../../common/components/CalendarScheduler.js'

const MODAL_FORM = 'SCHEDULE_EXPERT_MODAL'

export async function showScheduleExpertModal(initialForm = {}) {
  setForm(MODAL_FORM, {
    meetingBookSucceeded: null,
    ...initialForm,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function ScheduleExportModal({form}) {
  return (
    <ConfirmModal
      title="Talk to an Expert"
      modalSize="lg"
      cancelText="Close"
      onCancel={() => closeModal()}
    >
      <div className="row">
        <div className="medium-10 columns medium-centered">
          <p className="fs-01 margin-bottom-5 align-center">
            <strong>Set Yourself Up for Success</strong>
          </p>
          <p className="fs-01 margin-bottom-20 align-center">
            Schedule time with one of our Product Experts to learn more about
            the various Ordoro apps and how they will fit with your specific
            business needs.
          </p>

          {form.meetingBookSucceeded === false && (
            <p className="fs-01 margin-bottom-20 alert alert--error">
              Something happened during scheduling the appointment. Try again
              later.
            </p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="medium-12 columns">
          <CalendarScheduler
            onMessage={({meetingBookSucceeded}) =>
              updateModalForm({meetingBookSucceeded})
            }
          />
        </div>
      </div>
    </ConfirmModal>
  )
}

ScheduleExportModal.propTypes = {
  form: PropTypes.shape({
    meetingBookSucceeded: PropTypes.bool,
  }),
}

export default onlyIfForm(ScheduleExportModal, modalFormSelector)
