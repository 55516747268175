import classNames from 'classnames'

import {
  ratesLoadingSelector,
  labelErrorsSelector,
  hasLabelErrorsSelector,
} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function LabelErrors() {
  // we want all errors from all selected shippers
  const {labelInfoID} = useLabelConfigContext()
  const sortedErrors = useSelector((state) =>
    labelErrorsSelector(state, {labelInfoID}),
  )
  const hasErrors = useSelector((state) =>
    hasLabelErrorsSelector(state, {labelInfoID}),
  )
  const ratesLoading = useSelector((state) =>
    ratesLoadingSelector(state, {labelInfoID}),
  )

  if (!hasErrors) {
    return null
  }

  return (
    <pre
      className={classNames('wrap--error-wb margin-top-3 lh-sm', {
        'op-50': ratesLoading,
      })}
    >
      {sortedErrors.map((err, i) => (
        <small className="error" key={i}>
          {err}
        </small>
      ))}
    </pre>
  )
}
