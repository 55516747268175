import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector, createStructuredSelector} from 'reselect'

import SelectAllButton from '../../common/components/List/SelectAllButton.js'
import {
  setSelectedSKUs,
  skuListSelector,
  selectedSKUsSelector,
  allSelectedSelector,
} from './productListFunctions.js'

export function ProductSelectAllButton({checked, skuList, indeterminate}) {
  return (
    <SelectAllButton
      checked={checked}
      indeterminate={indeterminate}
      onChange={(value) => setSelectedSKUs(value ? skuList : [])}
    />
  )
}

ProductSelectAllButton.propTypes = {
  skuList: PropTypes.arrayOf(PropTypes.string).isRequired,
  indeterminate: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  skuList: skuListSelector,
  indeterminate: createSelector(
    allSelectedSelector,
    selectedSKUsSelector,
    (allSelected, selectedSKUs) => !allSelected && selectedSKUs.length > 0,
  ),
  checked: allSelectedSelector,
})

export default connect(mapStateToProps)(ProductSelectAllButton)
