import {combineReducers} from 'redux'

import * as actions from '../../../actions/ui/dataImport/upload.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'

function isDragHover(
  state = INITIAL_STATE.ui.dataImport.upload.isDragHover,
  action = {},
) {
  if (action.type === actions.SET_IS_DRAG_HOVER) {
    return action.payload
  }

  return state
}

function error(state = INITIAL_STATE.ui.dataImport.upload.error, action = {}) {
  if (action.type === actions.SET_ERROR) {
    return action.payload
  }

  return state
}

const reducer = combineReducers({
  isDragHover,
  error,
})

export default reducer
