import classNames from 'classnames'
import CurrencyCodes from 'currency-codes/data.js'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  formsSelector,
  useSelector,
  useAutoForm,
} from '../../../store.js'
import {isPresent} from '../../../common/utils.js'
import {isEmailAddress} from '../../../common/email.js'
import TextInput from '../../../common/components/Form/TextInput.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import Select from '../../../common/components/Form/Select.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import Radio from '../../../common/components/Radio.js'
import {
  companySelector,
  usesInventorySelector,
  saveCompany,
  enableCompanyFeature,
  disableCompanyFeature,
  usePurchaseOrdersSelector,
} from '../../../data/company.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import STATES from '../../../common/usStates.json'

import SettingsPanel from '../SettingsPanel.js'
import {
  userSettingSelector,
  saveUserSettings,
  updateUserSettings,
} from '../../../data/me.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {hasWelcomeTabSelector} from '../../Header/headerSelectors.js'
import {showWelcomeTabModal} from '../../Modals/WelcomeTabModal.js'

export const GENERAL_SETTINGS = 'GENERAL_SETTINGS'

export function setupGeneralForm() {
  const company = companySelector(getState())

  return {
    companyName: company.name,
    email: company.email,
    mailSender: company.mail_sender,
    currencySymbol: company.currency_symbol,
    state_of_business: company.state_of_business,
    shipperCurrency: company.shipper_currency,
    trackInventory: !!company.features.track_inventory,
    calculate_cogs: !!company.features.calculate_cogs,
    calculate_cogs_include_po_costs:
      !!company.features.calculate_cogs_include_po_costs,
    show_imgs_order_detail: !!company.features.show_imgs_order_detail,
    show_imgs_product_list: !!company.features.show_imgs_product_list,
    show_imgs_product_detail: !!company.features.show_imgs_product_detail,
    isSaving: false,
    serverError: null,
  }
}

export function updateGeneralForm(...args) {
  updateForm(GENERAL_SETTINGS, ...args)
}

export function generalFormSelector(state) {
  return formsSelector(state)[GENERAL_SETTINGS] || generalFormSelector.default
}
generalFormSelector.default = {}

export const errorsSelector = createSelector(
  generalFormSelector,
  ({companyName, email, mailSender, currencySymbol}) => {
    const errors = {}

    if (!isPresent(companyName)) {
      errors.companyName = 'Company Name is required'
      errors.preventSave = true
    }

    if (!isEmailAddress(email)) {
      errors.email = 'Email is required'
      errors.preventSave = true
    }

    if (!isEmailAddress(mailSender)) {
      errors.mailSender = 'Email is required'
      errors.preventSave = true
    }

    if (!isPresent(currencySymbol)) {
      errors.currencySymbol = 'Currency is required'
      errors.preventSave = true
    }

    return errors
  },
)

export async function saveGeneral() {
  try {
    const form = generalFormSelector(getState())
    const params = {
      company_name: form.companyName,
      mail_sender: form.mailSender,
      email: form.email,
      currency_symbol: form.currencySymbol,
      shipper_currency: form.shipperCurrency,
      state_of_business: form.state_of_business || null,
    }

    updateGeneralForm({
      isSaving: true,
      serverError: null,
    })

    let company = await saveCompany(params)

    if (company.features.track_inventory !== form.trackInventory) {
      if (form.trackInventory) {
        await enableCompanyFeature('track_inventory')
      } else {
        await disableCompanyFeature('track_inventory')
      }
    }

    if (company.features.calculate_cogs !== form.calculate_cogs) {
      if (form.calculate_cogs) {
        await enableCompanyFeature('calculate_cogs')
      } else {
        await disableCompanyFeature('calculate_cogs')

        if (company.features.calculate_cogs_include_po_costs) {
          await disableCompanyFeature('calculate_cogs_include_po_costs')
        }
      }
    }

    if (
      !!company.features.calculate_cogs_include_po_costs !==
      form.calculate_cogs_include_po_costs
    ) {
      if (form.calculate_cogs_include_po_costs) {
        await enableCompanyFeature('calculate_cogs_include_po_costs')
      } else {
        await disableCompanyFeature('calculate_cogs_include_po_costs')
      }
    }

    if (
      company.features.show_imgs_order_detail !== form.show_imgs_order_detail
    ) {
      if (form.show_imgs_order_detail) {
        await enableCompanyFeature('show_imgs_order_detail')
      } else {
        await disableCompanyFeature('show_imgs_order_detail')
      }
    }

    if (
      company.features.show_imgs_product_list !== form.show_imgs_product_list
    ) {
      if (form.show_imgs_product_list) {
        await enableCompanyFeature('show_imgs_product_list')
      } else {
        await disableCompanyFeature('show_imgs_product_list')
      }
    }

    if (
      company.features.show_imgs_product_detail !==
      form.show_imgs_product_detail
    ) {
      if (form.show_imgs_product_detail) {
        await enableCompanyFeature('show_imgs_product_detail')
      } else {
        await disableCompanyFeature('show_imgs_product_detail')
      }
    }

    setForm(GENERAL_SETTINGS, setupGeneralForm())
  } catch (err) {
    updateGeneralForm({
      isSaving: false,
      serverError: `Error saving company details. ${
        err.error_message || err.message
      }`,
    })
  }
}

async function setDarkMode(dark_mode) {
  updateUserSettings({dark_mode})

  await saveUserSettings()

  showMessageToast('Saved Appearance')
}

const shipperCurrencyOptions = CurrencyCodes.map(({currency, code}) => (
  <option key={code} value={code}>{`${code}: ${currency}`}</option>
))

export default function General() {
  const form = useAutoForm(GENERAL_SETTINGS, setupGeneralForm())
  const errors = useSelector(errorsSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).general
  const usesInventory = useSelector(usesInventorySelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const dark_mode = useSelector((state) =>
    userSettingSelector(state, {
      setting: 'dark_mode',
      defaultValue: null,
    }),
  )
  const hasWelcomeTab = useSelector(hasWelcomeTabSelector)

  return (
    <SettingsPanel header="General Settings" hasPermission={hasPermission}>
      {form.serverError && (
        <div className="settings-list medium-12 columns margin-bottom-20">
          <div className="error error-message">{form.serverError}</div>
        </div>
      )}
      <form>
        <fieldset className="margin-bottom-0">
          <ul className="list list--no-style">
            <li className="list__item row margin-bottom-25">
              <TextInput
                name="companyName"
                label="Company Name"
                className="medium-6 wrap--input-tall"
                required
                errorMessage={errors.companyName}
                value={form.companyName || ''}
                onChange={(value) => updateGeneralForm({companyName: value})}
              />
            </li>
            <li className="list__item row margin-bottom-25">
              <TextInput
                name="email"
                label="Primary Account Contact"
                className="medium-6 wrap--input-tall"
                helperIcon
                helperLink="https://support.ordoro.com/how-do-i-set-the-primary-account-contact-email/"
                required
                errorMessage={errors.email}
                value={form.email || ''}
                onChange={(value) => updateGeneralForm({email: value})}
              />
            </li>
            <li className="list__item row margin-bottom-25">
              <TextInput
                name="mailSender"
                label="Email Used for Outgoing Messages"
                className="medium-6 wrap--input-tall"
                required
                errorMessage={errors.mailSender}
                value={form.mailSender || ''}
                onChange={(value) => updateGeneralForm({mailSender: value})}
              />
            </li>
            <li className="list__item row margin-bottom-25">
              <TextInput
                name="currencySymbol"
                label="Currency Shown in Ordoro"
                className="medium-6 wrap--input-tall"
                required
                errorMessage={errors.currencySymbol}
                value={form.currencySymbol || ''}
                onChange={(value) => updateGeneralForm({currencySymbol: value})}
              />
            </li>
            <li className="list__item row margin-bottom-25">
              <Select
                name="shipperCurrency"
                label="Currency Used for Shipping"
                className="columns medium-5 end"
                selectClassName="select--tall w-100"
                value={form.shipperCurrency || ''}
                onChange={(value) =>
                  updateGeneralForm({shipperCurrency: value})
                }
              >
                {shipperCurrencyOptions}
              </Select>
            </li>
            <li className="list__item row margin-bottom-25">
              <Select
                id="state_of_business"
                label="Principal State of Business (for Tax Purposes)"
                className="columns medium-5 end"
                selectClassName="select--tall w-100"
                value={form.state_of_business || ''}
                onChange={(value) =>
                  updateGeneralForm({state_of_business: value})
                }
              >
                <option value=""></option>
                {Object.entries(STATES).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
            </li>
            <li className="list__item row">
              <div className="medium-12 columns">
                <hr />
              </div>
            </li>
            <li className="list__item row">
              <div className="medium-6 columns">
                <dl className="list">
                  <dt className="list__title margin-bottom-15">Appearance</dt>
                  <dd className="list__item">
                    <Radio
                      mode="fancy"
                      id="appearance_auto"
                      label="Automatic (use system settings)"
                      checked={dark_mode === null}
                      onChange={() => setDarkMode(null)}
                    />
                  </dd>
                  <dd className="list__item">
                    <Radio
                      mode="fancy"
                      id="appearance_light"
                      label="Light Mode"
                      checked={dark_mode === false}
                      onChange={() => setDarkMode(false)}
                    />
                  </dd>
                  <dd className="list__item margin-bottom-0">
                    <Radio
                      mode="fancy"
                      id="appearance_dark"
                      label="Dark Mode"
                      checked={dark_mode === true}
                      onChange={() => setDarkMode(true)}
                    />
                  </dd>
                </dl>
              </div>
            </li>
            <li className="list__item row">
              <div className="medium-12 columns">
                <hr />
              </div>
            </li>
            <li className="list__item row">
              <div className="medium-6 columns">
                <dl className="list">
                  <dt className="list__title margin-bottom-15">
                    Product Images
                  </dt>
                  <dd className="list__item">
                    <label htmlFor="id_show_imgs_order_detail">
                      <input
                        className="margin-left-3 margin-right-5 margin-bottom-0 v-align-middle"
                        id="id_show_imgs_order_detail"
                        type="checkbox"
                        name="show_imgs_order_detail"
                        checked={form.show_imgs_order_detail || false}
                        onChange={() =>
                          updateGeneralForm({
                            show_imgs_order_detail:
                              !form.show_imgs_order_detail,
                          })
                        }
                      />
                      <span className="v-align-middle">
                        Display on Order Detail Page
                      </span>
                    </label>
                  </dd>
                  <dd className="list__item">
                    <label htmlFor="id_show_imgs_product_list">
                      <input
                        className="margin-left-3 margin-right-5 margin-bottom-0 v-align-middle"
                        id="id_show_imgs_product_list"
                        type="checkbox"
                        name="show_imgs_product_list"
                        checked={form.show_imgs_product_list || false}
                        onChange={() =>
                          updateGeneralForm({
                            show_imgs_product_list:
                              !form.show_imgs_product_list,
                          })
                        }
                      />
                      <span className="v-align-middle">
                        Display on Product List Page
                      </span>
                    </label>
                  </dd>
                  <dd className="list__item margin-bottom-0">
                    <label htmlFor="id_show_imgs_product_detail">
                      <input
                        className="margin-left-3 margin-right-5 margin-bottom-0 v-align-middle"
                        id="id_show_imgs_product_detail"
                        type="checkbox"
                        name="show_imgs_product_detail"
                        checked={form.show_imgs_product_detail || false}
                        onChange={() =>
                          updateGeneralForm({
                            show_imgs_product_detail:
                              !form.show_imgs_product_detail,
                          })
                        }
                      />
                      <span className="v-align-middle">
                        Display on Product Detail Page
                      </span>
                    </label>
                  </dd>
                </dl>
              </div>
            </li>
            <li className="list__item row">
              <div className="medium-12 columns">
                <hr />
              </div>
            </li>
            <li className="list__item row">
              <div className="medium-6 columns">
                <dl className="list">
                  <dt className="list__title margin-bottom-10">Welcome Page</dt>
                  <dd className="list__item fs-n0 margin-bottom-15">
                    <strong>Status:</strong>{' '}
                    <span>{hasWelcomeTab ? 'Visible' : 'Hidden'}</span>
                  </dd>
                  <dd className="list__item">
                    <ButtonPrimary
                      size="x-sm"
                      isOutlined
                      onClick={() => showWelcomeTabModal()}
                    >
                      {hasWelcomeTab ? 'Hide' : 'Show'} the Welcome Page
                    </ButtonPrimary>
                  </dd>
                </dl>
              </div>
            </li>

            {usesInventory && (
              <>
                <li className="list__item row">
                  <div className="medium-6 columns">
                    <hr />
                  </div>
                </li>
                <li className="list__item row margin-bottom-10">
                  <Checkbox
                    name="trackInventory"
                    label="Enable Shippability calculations for your orders"
                    className="columns medium-12"
                    checked={form.trackInventory || false}
                    onChange={(value) =>
                      updateGeneralForm({trackInventory: value})
                    }
                  />
                </li>
                <li className="list__item row margin-bottom-0">
                  <div className="medium-6 columns">
                    <p className="fs-n1 text--md-grey">
                      By enabling Shippability, we’ll look at your current
                      on-hand inventory and mark your orders with an icon and
                      color to indicate your ability to successfully fulfill
                      them or not. Green means that you have enough on-hand
                      inventory to fulfill the order, yellow means that you have
                      enough on-hand to partially fulfill the order and red
                      means you do not have enough inventory on-hand to fulfill
                      any part of the order.{' '}
                      <strong>
                        <a
                          href="https://support.ordoro.com/how-does-shippability-work/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Learn more
                        </a>
                      </strong>{' '}
                      <span className="op-50">→</span>
                    </p>
                  </div>
                </li>
                {usePurchaseOrders && (
                  <>
                    <li className="list__item row">
                      <div className="medium-6 columns">
                        <hr />
                      </div>
                    </li>
                    <li className="list__item row margin-bottom-0">
                      <Checkbox
                        name="calculate_cogs"
                        label="Recalculate Weighted Average Unit Cost upon goods receipt"
                        className="columns medium-6"
                        checked={form.calculate_cogs || false}
                        onChange={() =>
                          updateGeneralForm({
                            calculate_cogs: !form.calculate_cogs,
                          })
                        }
                      />
                    </li>
                    {form.calculate_cogs && (
                      <li className="list__item row margin-left-10 margin-bottom-0 wrap--label-unbold">
                        <Checkbox
                          name="calculate_cogs_include_po_costs"
                          label="Include Shipping and Handling, Tax, and PO Discounts"
                          className="wrap--nested-form"
                          checked={
                            form.calculate_cogs_include_po_costs || false
                          }
                          onChange={() =>
                            updateGeneralForm({
                              calculate_cogs_include_po_costs:
                                !form.calculate_cogs_include_po_costs,
                            })
                          }
                        />
                      </li>
                    )}
                    <li className="list__item row margin-top-10 margin-bottom-0">
                      <div className="medium-6 columns">
                        <p className="fs-n1 text--md-grey">
                          <strong>
                            <a
                              href="https://support.ordoro.com/can-ordoro-calculate-weighted-average-unit-cost-for-my-products/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Learn more
                            </a>
                          </strong>{' '}
                          <span className="op-50">→</span>
                        </p>
                      </div>
                    </li>
                  </>
                )}
              </>
            )}
            <li className="list__item row margin-top-25 margin-bottom-25">
              <div className="medium-6 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  onClick={() => saveGeneral()}
                  disabled={errors.preventSave || form.isSaving}
                >
                  Save
                </button>
              </div>
            </li>
          </ul>
        </fieldset>
      </form>
    </SettingsPanel>
  )
}
