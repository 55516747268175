import {updateLabelConfig} from '../../../../data/labelInfos/index.js'
import ConfigSelect from '../ConfigSelect.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [FEDEX]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__alcohol_shipment_license`]
    : []
}

export default function AlcoholShipmentLicense() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="Alcohol Shipment License"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty="alcohol_recipient_type"
    />
  )
}
