import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import range from 'lodash/range.js'

import BaseModal, {
  Footer,
  Content,
  ButtonPrimary,
  ButtonSecondary,
} from '../BaseModal/index.js'
import {closeModal} from '../../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  updateForm,
  saveEndiciaCreditCard,
} from '../../../../redux/actions/ui/modals/endiciaCreditCardModal.js'
import {
  endiciaCreditCardFormSelector,
  isSavingSelector,
  topLevelErrorSelector,
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/modals/endiciaCreditCardModal.js'
import {monthsLongNames} from '../../../Months.js'
import {ErrorsShape} from '../../../PropTypes.js'

class EndiciaCreditCardModal extends Component {
  static modalName = MODAL_NAME

  state = {
    cardNumber: '',
    cardCVV: '',
  }

  componentDidMount() {
    this.copyPropsToState(this.props)
  }

  onClose = () => {
    this.props.closeModal()
  }

  onCardNumberChange = (event) =>
    this.updateState({cardNumber: event.target.value})
  onCardNumberBlur = (event) =>
    this.props.updateForm({cardNumber: event.target.value})
  onCardCVVChange = (event) => this.updateState({cardCVV: event.target.value})
  onCardCVVBlur = (event) =>
    this.props.updateForm({cardCVV: event.target.value})
  onExpirationMonthChange = (event) =>
    this.props.updateForm({expirationMonth: event.target.value})
  onExpirationYearChange = (event) =>
    this.props.updateForm({expirationYear: event.target.value})
  onStreet1Change = (event) =>
    this.props.updateForm({street1: event.target.value})
  onCityChange = (event) => this.props.updateForm({city: event.target.value})
  onStateChange = (event) => this.props.updateForm({state: event.target.value})
  onZipChange = (event) => this.props.updateForm({zip: event.target.value})

  componentDidUpdate(prevProps) {
    if (
      prevProps.form.cardNumber !== this.props.form.cardNumber ||
      prevProps.form.cardCVV !== this.props.form.cardCVV
    ) {
      this.copyPropsToState(this.props)
    }
  }

  copyPropsToState(props) {
    const {cardNumber, cardCVV} = props.form

    this.setState({
      cardNumber,
      cardCVV,
    })
  }

  updateState(updatedState) {
    this.setState({
      ...this.state,
      ...updatedState,
    })
  }

  renderMonthOptions() {
    return monthsLongNames.map(({text, value}) => (
      <option value={value} key={value}>
        {text}
      </option>
    ))
  }

  renderYearOptions() {
    const from = new Date().getFullYear()
    const amount = 50

    return range(Number(from), Number(from) + Number(amount)).map((year) => (
      <option value={year} key={year}>
        {year}
      </option>
    ))
  }

  render() {
    const {
      form: {street1, city, state, zip, expirationMonth, expirationYear},
      isSaving,
      topLevelError,
      errors,
      hasErrors,
    } = this.props

    const {cardNumber, cardCVV} = this.state

    return (
      <BaseModal
        modalName={MODAL_NAME}
        title="Update USPS Credit Card"
        className="modal--sm"
      >
        <Content>
          <ul className="list list--form margin-bottom-10">
            <li className="list__item--form flex">
              {topLevelError && (
                <small className="error error-message">{topLevelError}</small>
              )}
            </li>
            <li className="list__item--form flex">
              <div
                className={classNames('wrap--input-half margin-right-15', {
                  error: errors.cardNumber,
                })}
              >
                <label htmlFor="id_cardNumber">
                  Credit Card Number<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0 fs-ignore"
                  type="text"
                  id="id_cardNumber"
                  value={cardNumber}
                  onChange={this.onCardNumberChange}
                  onBlur={this.onCardNumberBlur}
                />
                {errors.cardNumber && (
                  <small className="error error-message">
                    {errors.cardNumber}
                  </small>
                )}
              </div>
              <div
                className={classNames('wrap--input-eighth', {
                  error: errors.cardCVV,
                })}
              >
                <label htmlFor="id_cardCVV">
                  CVC<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0 fs-ignore"
                  type="text"
                  id="id_cardCVV"
                  value={cardCVV}
                  onChange={this.onCardCVVChange}
                  onBlur={this.onCardCVVBlur}
                />
                {errors.cardCVV && (
                  <small className="error error-message">
                    {errors.cardCVV}
                  </small>
                )}
              </div>
            </li>
            <li className="list__item--form flex">
              <div
                className={classNames('wrap--input-quarter margin-right-15', {
                  error: errors.expirationMonth,
                })}
              >
                <label htmlFor="id_expirationMonth">
                  Exp Month<span className="required">*</span>
                </label>
                <select
                  className="select w-100 margin-bottom-0 fs-ignore"
                  id="id_expirationMonth"
                  value={expirationMonth}
                  onChange={this.onExpirationMonthChange}
                >
                  {this.renderMonthOptions()}
                </select>
                {errors.expirationMonth && (
                  <small className="error error-message margin-top-5">
                    {errors.expirationMonth}
                  </small>
                )}
              </div>
              <div
                className={classNames('wrap--input-quarter', {
                  error: errors.expirationYear,
                })}
              >
                <label htmlFor="id_expirationYear">
                  Exp Year<span className="required">*</span>
                </label>
                <select
                  className="select w-100 margin-bottom-0 fs-ignore"
                  id="id_expirationYear"
                  value={expirationYear}
                  onChange={this.onExpirationYearChange}
                >
                  {this.renderYearOptions()}
                </select>
                {errors.expirationYear && (
                  <small className="error error-message margin-top-5">
                    {errors.expirationYear}
                  </small>
                )}
              </div>
            </li>
            <li className="list__item--form flex">
              <div
                className={classNames('wrap--input-half', {
                  error: errors.street1,
                })}
              >
                <label htmlFor="id_street1">
                  Street<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0"
                  type="text"
                  id="id_street1"
                  value={street1}
                  onChange={this.onStreet1Change}
                />
                {errors.street1 && (
                  <small className="error error-message">
                    {errors.street1}
                  </small>
                )}
              </div>
            </li>
            <li className="list__item--form flex">
              <div
                className={classNames('wrap--input-half margin-right-15', {
                  error: errors.city,
                })}
              >
                <label htmlFor="id_city">
                  City<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0 fs-ignore"
                  type="text"
                  id="id_city"
                  value={city}
                  onChange={this.onCityChange}
                />
                {errors.city && (
                  <small className="error error-message">{errors.city}</small>
                )}
              </div>
              <div
                className={classNames('wrap--input-eighth', {
                  error: errors.state,
                })}
              >
                <label htmlFor="id_state">
                  State<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0 fs-ignore"
                  type="text"
                  id="id_state"
                  value={state}
                  onChange={this.onStateChange}
                />
                {errors.state && (
                  <small className="error error-message">{errors.state}</small>
                )}
              </div>
            </li>
            <li className="list__item--form flex">
              <div
                className={classNames('wrap--input-third', {error: errors.zip})}
              >
                <label htmlFor="id_zip">
                  Zip<span className="required">*</span>
                </label>
                <input
                  className="margin-bottom-0 fs-ignore"
                  type="text"
                  id="id_zip"
                  value={zip}
                  onChange={this.onZipChange}
                />
                {errors.zip && (
                  <small className="error error-message margin-top-5">
                    {errors.zip}
                  </small>
                )}
              </div>
            </li>
          </ul>
        </Content>
        <Footer>
          <ButtonPrimary
            title="Save"
            saving={isSaving}
            disabled={hasErrors}
            onClick={this.props.saveEndiciaCreditCard}
          />
          <ButtonSecondary title="Cancel" onClick={this.onClose} />
        </Footer>
      </BaseModal>
    )
  }
}

const FormShape = PropTypes.shape({
  cardNumber: PropTypes.string.isRequired,
  cardCVV: PropTypes.string.isRequired,
  street1: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  expirationMonth: PropTypes.string.isRequired,
  expirationYear: PropTypes.string.isRequired,
})

EndiciaCreditCardModal.propTypes = {
  form: FormShape.isRequired,
  isSaving: PropTypes.bool.isRequired,
  topLevelError: PropTypes.string.isRequired,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveEndiciaCreditCard: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: endiciaCreditCardFormSelector(state),
    isSaving: isSavingSelector(state),
    topLevelError: topLevelErrorSelector(state),
    errors: errorsOfChangedSelector(state),
    hasErrors: hasErrorsSelector(state),
  }
}

const mapDispatchToProps = {
  updateForm,
  saveEndiciaCreditCard,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndiciaCreditCardModal)
