import PropTypes from 'prop-types'

import {
  CREATE_ORDER_SHIPPING_ADDRESS,
  CREATE_RETURN_ORDER_CUSTOMER_ADDRESS,
  CREATE_RETURN_ORDER_OTHER_ADDRESS,
  QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS,
  EDIT_WAREHOUSE_ADDRESS,
  EDIT_SUPPLIER_ADDRESS,
  QUICK_SHIP_SHIPPING_ADDRESS,
} from '../../constants/AddressTypes.js'
import {AddressShape} from '../../PropTypes.js'
import formConnect from '../../formConnect.js'
import parseAddress from '../../parseAddress.js'
import ConfirmModal from '../Modal/ConfirmModal.js'
import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import TextArea from '../TextArea.js'
import {showMessageToast} from '../../../ordoro/Header/Toast/index.js'
import {updateCreateOrderFormShippingInfo} from '../../../ordoro/CreateOrderPage/createOrderActions.js'
import {updateCreateReturnOrderModalForm} from '../../../ordoro/ReturnOrderListPage/Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import {updateWarehousesForm} from '../../../ordoro/settings/Warehouses/warehousesFunctions.js'
import {updateSuppliersForm} from '../../../ordoro/settings/Suppliers/suppliersFunctions.js'
import {updateQuickShipForm} from '../../../ordoro/OrderListPage/Modals/QuickShipModal/quickShipModalFunctions.js'
import {PRIVACY_MASK} from '../../analytics/index.js'

const MODAL_FORM = 'PASTE_ADDRESS_MODAL_FORM'

export function showPasteAddressModal({addressType, address}) {
  setForm(MODAL_FORM, {
    address: {...address},
    addressType,
    addressFreeText: '',
    hasChange: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function updateAddress() {
  const {addressType, address, addressFreeText} = modalFormSelector(getState())

  const addressUpdates = parseAddress(addressFreeText)

  if (addressType === CREATE_ORDER_SHIPPING_ADDRESS) {
    updateCreateOrderFormShippingInfo({...address, ...addressUpdates})
  } else if (addressType === CREATE_RETURN_ORDER_CUSTOMER_ADDRESS) {
    updateCreateReturnOrderModalForm({
      customerAddress: {...address, ...addressUpdates},
    })
  } else if (addressType === CREATE_RETURN_ORDER_OTHER_ADDRESS) {
    updateCreateReturnOrderModalForm({
      otherAddress: {...address, ...addressUpdates},
    })
  } else if (addressType === EDIT_WAREHOUSE_ADDRESS) {
    updateWarehousesForm({...address, ...addressUpdates})
  } else if (addressType === EDIT_SUPPLIER_ADDRESS) {
    updateSuppliersForm({...address, ...addressUpdates})
  } else if (addressType === QUICK_SHIP_ALTERNATE_SHIP_FROM_ADDRESS) {
    updateQuickShipForm({
      alternate_ship_from_address: {...address, ...addressUpdates},
    })
  } else if (addressType === QUICK_SHIP_SHIPPING_ADDRESS) {
    updateQuickShipForm({
      shipping_address: {...address, ...addressUpdates},
    })
  }

  const message =
    addressType === CREATE_ORDER_SHIPPING_ADDRESS
      ? 'Ship-to info was successfully updated.'
      : addressType === CREATE_RETURN_ORDER_CUSTOMER_ADDRESS
        ? 'Return-from info was successfully updated.'
        : addressType === EDIT_WAREHOUSE_ADDRESS
          ? 'Warehouse info was successfully updated.'
          : addressType === EDIT_SUPPLIER_ADDRESS
            ? 'Supplier info was successfully updated.'
            : 'Address was successfully updated.'

  showMessageToast(message)

  closeModal()
}

function ParseAddressModal({form}) {
  return (
    <ConfirmModal
      title="Paste Address"
      confirmText="Update Form"
      cancelText="Cancel"
      onConfirm={() => {
        updateAddress()

        closeModal()
      }}
      onCancel={() => closeModal()}
      isDisabled={!form.addressFreeText}
      helpLink="https://support.ordoro.com/pasted-address-lines/"
    >
      <>
        <p className="fs-01">
          Copy and paste a full address in the following box and we’ll put
          everything where it belongs in the main form.
        </p>
        <TextArea
          className={`textarea textarea--mh-auto margin-top-15 ${PRIVACY_MASK}`}
          rows="8"
          value={form.addressFreeText}
          onChange={(value) =>
            updateModalForm({
              addressFreeText: value,
            })
          }
        />
      </>
    </ConfirmModal>
  )
}

ParseAddressModal.propTypes = {
  form: PropTypes.shape({
    address: AddressShape.isRequired,
    addressType: PropTypes.symbol.isRequired,
    addressFreeText: PropTypes.string.isRequired,
  }),
}

export default formConnect(ParseAddressModal, modalFormSelector)
