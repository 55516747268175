import Checkbox from '../../../../common/components/Checkbox.js'

import {useSelector} from '../../../../store.js'
import {
  updateModalForm,
  modalFormSelector,
} from './batchListFilterModalFunctions.js'

export default function StatusFilterPanel() {
  const form = useSelector(modalFormSelector)

  return (
    <div className="wrap--modal-filters wrap--modal-filters-status">
      <div className="wrap__modal-subheader margin-bottom-25">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0">
          <strong>Status</strong>
        </h4>
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        <li className="list__item list__item--modal-filters">
          <Checkbox
            mode="fancy"
            id="active"
            checked={form.active !== false}
            onChange={(checked) =>
              updateModalForm({
                active: checked ? (form.active === false ? null : true) : false,
              })
            }
            label="Active"
          />
        </li>
        <li className="list__item list__item--modal-filters">
          <Checkbox
            mode="fancy"
            id="archived"
            checked={form.active !== true}
            onChange={(checked) =>
              updateModalForm({
                active: checked ? (form.active === true ? null : false) : true,
              })
            }
            label="Archived"
          />
        </li>
      </ul>
    </div>
  )
}
