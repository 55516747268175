import PropTypes from 'prop-types'

import LabelItem from './LabelItem.js'
import {useSelector} from '../../../../../../../store.js'
import {shippedShipperTypeSelector} from '../../../../../../../data/orders.js'
import {shipperOptionSelector} from '../../../../../../../data/shipperOptions.js'

export default function Payors({orderNumber, shippingInfo}) {
  const shipperType = useSelector((state) =>
    shippedShipperTypeSelector(state, {orderNumber}),
  )
  const shipperOptions = useSelector((state) =>
    shipperOptionSelector(state, {shipperType}),
  )
  const payorOptions = shipperOptions ? shipperOptions.payor : null

  if (!shippingInfo || !payorOptions) {
    return null
  }

  const payorOption = payorOptions.find(
    ({value}) => value === shippingInfo.payment_type,
  )
  const dutiesPayorOption = payorOptions.find(
    ({value}) => value === shippingInfo.duties_payment_type,
  )

  return (
    <>
      {payorOption && (
        <LabelItem label="Payor">{payorOption.display}</LabelItem>
      )}
      {dutiesPayorOption && (
        <LabelItem label="Duties Payor">{dutiesPayorOption.display}</LabelItem>
      )}
    </>
  )
}

Payors.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  shippingInfo: PropTypes.shape({
    payment_type: PropTypes.string,
    duties_payment_type: PropTypes.string,
  }),
}
