import {combineReducers} from 'redux'

import ui from './ui/index.js'
import data from './data/index.js'

const rootReducer = combineReducers({
  ui,
  data,
})

export default rootReducer
