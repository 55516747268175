import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent, isNumeric} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {SPS_COMMERCE} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.host)) {
    errors.host = 'Host is required'
    errors.preventSave = true
  }

  if (!isPresent(form.port)) {
    errors.port = 'Port is required'
    errors.preventSave = true
  } else if (!isNumeric(form.port)) {
    errors.port = 'Port must be a number'
    errors.preventSave = true
  }

  if (!isPresent(form.username)) {
    errors.username = 'Username is required'
    errors.preventSave = true
  }

  if (!isPresent(form.password)) {
    errors.password = 'Password is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: SPS_COMMERCE}),
      host: '',
      port: '22',
      username: '',
      password: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    host: cart.vendor_config.host,
    port: cart.vendor_config.port,
    username: cart.vendor_config.username,
    password: cart.vendor_config.password,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'host') {
    errors.host = serverError.error_message
  } else if (serverError.param === 'port') {
    errors.port = serverError.error_message
  } else if (serverError.param === 'username') {
    errors.username = serverError.error_message
  } else if (serverError.param === 'password') {
    errors.password = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    host: form.host,
    port: form.port,
    username: form.username,
    password: form.password,
  }
}

function SPSCommerce({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="host"
              label="Host"
              required
              value={form.host || ''}
              errorMessage={form.hasChanged_host && errors.host}
              onChange={(event) =>
                updateForm(formName, {
                  host: event.target.value,
                  hasChanged_host: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="port"
              label="Port"
              required
              value={form.port || ''}
              errorMessage={form.hasChanged_port && errors.port}
              onChange={(event) =>
                updateForm(formName, {
                  port: event.target.value,
                  hasChanged_port: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="username"
              label="Username"
              required
              value={form.username || ''}
              errorMessage={form.hasChanged_username && errors.username}
              onChange={(event) =>
                updateForm(formName, {
                  username: event.target.value,
                  hasChanged_username: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              type="password"
              name="password"
              label="Password"
              required
              value={form.password || ''}
              errorMessage={form.hasChanged_password && errors.password}
              onChange={(event) =>
                updateForm(formName, {
                  password: event.target.value,
                  hasChanged_password: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="SPS Commerce"
          href="https://support.ordoro.com/sps-commerce-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

SPSCommerce.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    host: PropTypes.string.isRequired,
    hasChanged_host: PropTypes.bool,
    port: PropTypes.string.isRequired,
    hasChanged_port: PropTypes.bool,
    username: PropTypes.string.isRequired,
    hasChanged_username: PropTypes.bool,
    password: PropTypes.string.isRequired,
    hasChanged_password: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(SPSCommerce, setUpCartForm)
