import {
  AMAZON,
  AMAZON_CA,
  AMAZON_UK,
  BIGCOMMERCE,
  CHANNELADVISOR,
  CUSTOM_INTEGRATION,
  EBAY,
  ECWID,
  ETSY,
  JET,
  MAGENTO,
  MAGENTO_V2,
  ORDORO_VENDOR_CONNECT,
  REVERB,
  SHIP_STATION,
  SHOPIFY,
  SHOPSITE,
  SPS_COMMERCE,
  SQUARE,
  SQUARESPACE,
  STRIPE,
  THREEDCART,
  VENDOR_PORTAL,
  VOLUSION,
  WALMART,
  WAYFAIR,
  WOOCOMMERCE,
} from '../../../../common/constants/CartVendorOptions.js'

import Amazon from './Amazon.js'
import AmazonCA from './AmazonCA.js'
import AmazonUK from './AmazonUK.js'
import BigCommerce from './BigCommerce.js'
import ChannelAdvisor from './ChannelAdvisor.js'
import CustomIntegration from './CustomIntegration.js'
import Ebay from './Ebay.js'
import Ecwid from './Ecwid.js'
import Etsy from './Etsy.js'
import Jet from './Jet.js'
import Magento from './Magento.js'
import OrdoroVendorConnect from './OrdoroVendorConnect.js'
import Reverb from './Reverb.js'
import ShipStation from './ShipStation.js'
import Shopify from './Shopify.js'
import Shopsite from './Shopsite.js'
import SPSCommerce from './SPSCommerce.js'
import Square from './Square.js'
import Squarespace from './Squarespace.js'
import Stripe from './Stripe.js'
import ThreeDCart from './ThreeDCart.js'
import VendorPortal from './VendorPortal.js'
import Volusion from './Volusion.js'
import Walmart from './Walmart.js'
import Wayfair from './Wayfair.js'
import WooCommerce from './WooCommerce.js'

export default {
  [AMAZON]: Amazon,
  [AMAZON_CA]: AmazonCA,
  [AMAZON_UK]: AmazonUK,
  [BIGCOMMERCE]: BigCommerce,
  [CHANNELADVISOR]: ChannelAdvisor,
  [CUSTOM_INTEGRATION]: CustomIntegration,
  [EBAY]: Ebay,
  [ECWID]: Ecwid,
  [ETSY]: Etsy,
  [JET]: Jet,
  [MAGENTO]: Magento,
  [MAGENTO_V2]: Magento,
  [ORDORO_VENDOR_CONNECT]: OrdoroVendorConnect,
  [REVERB]: Reverb,
  [SHIP_STATION]: ShipStation,
  [SHOPIFY]: Shopify,
  [SHOPSITE]: Shopsite,
  [SPS_COMMERCE]: SPSCommerce,
  [SQUARE]: Square,
  [SQUARESPACE]: Squarespace,
  [STRIPE]: Stripe,
  [THREEDCART]: ThreeDCart,
  [VENDOR_PORTAL]: VendorPortal,
  [VOLUSION]: Volusion,
  [WALMART]: Walmart,
  [WAYFAIR]: Wayfair,
  [WOOCOMMERCE]: WooCommerce,
}
