import PropTypes from 'prop-types'

import {formatAPIURL} from '../../../../common/api.js'
import FormattedDate from '../../../../common/components/FormattedDate.js'
import LinkButton from '../../../../common/components/Link/LinkButton.js'

export default function PreviousScanFormList({prevScanForms}) {
  if (prevScanForms.length === 0) {
    return null
  }

  return (
    <ul className="list list--square">
      {prevScanForms.slice(0, 3).map((task, index) => (
        <li key={index} className="list__item fs-n1">
          <LinkButton
            title="Download this end of day form"
            href={formatAPIURL(task._linkdata)}
            target="_blank"
          >
            <strong>
              <span>
                <FormattedDate value={task.created} format="MMM D, YYYY" />
                {' at '}
                <FormattedDate value={task.created} format="h:mma" />
              </span>
            </strong>
          </LinkButton>
          <span className="fs-00 margin-left-3 v-align-middle">⇣</span>
        </li>
      ))}
    </ul>
  )
}

PreviousScanFormList.propTypes = {
  prevScanForms: PropTypes.arrayOf(
    PropTypes.shape({
      _linkdata: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
