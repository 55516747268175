import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {showGlobalError} from '../../../../ordoro/GlobalErrorMessage.js'
import api from '../../../../common/api.js'
import {
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
} from '../../../../common/constants/ShipperNames.js'
import {getRealDate, getTimeZone} from '../../../../common/date.js'
import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  getState,
} from '../../../../store.js'
import {
  shippersSelector,
  activeShipperIDsSelector,
} from '../../../../data/shippers.js'
import {
  warehouseSelector,
  warehouseZipcodeSelector,
  nonFBAWarehousesSelector,
} from '../../../../data/warehouses.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import {createNewTasks} from '../../../../redux/actions/data/tasks.js'
import delay from '../../../../common/delay.js'
import {useScanFormsSelector} from '../../../../data/company.js'

export const BASE = 'ordoro/ui/modal/scanFormModal'
export const UPDATE_SHIPPER = `${BASE}/UPDATE_SHIPPER`
export const SET_SELECTED_SHIPPER_ID = `${BASE}/SET_SELECTED_SHIPPER_ID`
export const SET_SELECTED_WAREHOUSE_ID = `${BASE}/SET_SELECTED_WAREHOUSE_ID`
export const SET_SELECTED_WAREHOUSE_IDS = `${BASE}/SET_SELECTED_WAREHOUSE_IDS`
export const SET_IS_PICKUP = `${BASE}/SET_IS_PICKUP`
export const SET_PAYMENT_METHOD = `${BASE}/SET_PAYMENT_METHOD`
export const SHOW_SCAN_FORM_MODAL = `${BASE}/SHOW_SCAN_FORM_MODAL`
export const GET_PREVIOUS_SCAN_FORMS = `${BASE}/GET_PREVIOUS_SCAN_FORMS`
export const CREATE_SCAN_FORM = `${BASE}/CREATE_SCAN_FORM`
export const CLOSE_DAY = `${BASE}/CLOSE_DAY`

export const MODAL_FORM = 'SCAN_FORM_MODAL'
export const NO_WAREHOUSES_ID = 'NO_WAREHOUSES_ID'

export function updateScanFormForm(form) {
  updateForm(MODAL_FORM, form)
}

export function removeScanFormForm() {
  removeForm(MODAL_FORM)
}

export function scanFormModalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const scanFormShippersSelector = createSelector(
  shippersSelector,
  activeShipperIDsSelector,
  (shippers, activeShipperIDs) =>
    activeShipperIDs.reduce((prev, shipperID) => {
      const shipper = shippers[shipperID]

      if (
        [
          AUSTRALIA_POST,
          CANADA_POST,
          DHL_ECOMMERCE,
          ENDICIA,
          FEDEX,
          NEWGISTICS,
          PITNEY,
          PITNEY_MERCHANT,
          VISIBLE_USPS,
        ].includes(shipper.vendor)
      ) {
        prev.push(shipper)
      }

      return prev
    }, []),
)

export const canCreateScanFormSelector = createSelector(
  scanFormShippersSelector,
  useScanFormsSelector,
  (shippers, useScanForms) => useScanForms && shippers.length > 0,
)

export const selectedShipperVendorSelector = createSelector(
  shippersSelector,
  scanFormModalFormSelector,
  (shippers, {shipperID}) => get(shippers, [shipperID, 'vendor'], null),
)

export function scanFormParamsSelector(state) {
  const {shipperID} = scanFormModalFormSelector(state)
  const vendor = selectedShipperVendorSelector(state)
  const isPickup = isPickupScanFormSelector(state)
  const paymentMethod = paymentMethodScanFormSelector(state)
  const warehouseForms = scanFormWarehouseFormsSelector(state).filter(
    ({isSelected}) => isSelected,
  )

  if (vendor === CANADA_POST) {
    return warehouseForms.map(({warehouseID}) => ({
      shipper_id: shipperID,
      warehouse_id: warehouseID,
      payment_method: paymentMethod,
      pickup: isPickup,
    }))
  } else if ([AUSTRALIA_POST, VISIBLE_USPS, DHL_ECOMMERCE].includes(vendor)) {
    return warehouseForms.map(({warehouseID}) => ({
      shipper_id: shipperID,
      warehouse_id: warehouseID,
      ship_date: getRealDate().toISOString(),
      timezone: getTimeZone(),
    }))
  } else if ([PITNEY, PITNEY_MERCHANT].includes(vendor)) {
    return warehouseForms.map(({warehouseID, inductionZipcode}) => ({
      shipper_id: shipperID,
      warehouse_id: warehouseID,
      induction_postal_code: inductionZipcode,
      ship_date: getRealDate().toISOString(),
      timezone: getTimeZone(),
    }))
  }
  if (vendor === NEWGISTICS) {
    return {
      shipper_id: shipperID,
    }
  } else if ([ENDICIA, FEDEX].includes(vendor)) {
    return [
      {
        shipper_id: shipperID,
      },
    ]
  }

  return []
}

export const activityTypeSelector = createSelector(
  selectedShipperVendorSelector,
  (vendor) => {
    if ([NEWGISTICS].includes(vendor)) {
      return 'close'
    }

    return 'scan_form'
  },
)

export function activityNameSelector(state) {
  const vendor = selectedShipperVendorSelector(state)
  const activityType = activityTypeSelector(state)

  return `${vendor}_${activityType}`
}

export const groupByZipcodeSelector = createSelector(
  selectedShipperVendorSelector,
  (vendor) => [PITNEY, PITNEY_MERCHANT, VISIBLE_USPS].includes(vendor),
)

export const usesInductionZipcodeSelector = createSelector(
  selectedShipperVendorSelector,
  (vendor) => [PITNEY, PITNEY_MERCHANT].includes(vendor),
)

export const validScanFormWarehousesSelector = createSelector(
  selectedShipperVendorSelector,
  nonFBAWarehousesSelector,
  groupByZipcodeSelector,
  (vendor, warehouses, groupByZipcode) => {
    if (
      warehouses.length > 0 &&
      [
        AUSTRALIA_POST,
        CANADA_POST,
        DHL_ECOMMERCE,
        PITNEY,
        PITNEY_MERCHANT,
        VISIBLE_USPS,
      ].includes(vendor)
    ) {
      if (!groupByZipcode) {
        return warehouses
      }

      const hasZipcode = {}

      return warehouses.reduce((prev, warehouse) => {
        const zipcode = get(warehouse, 'address.zip', '')

        if (!hasZipcode[zipcode]) {
          prev.push(warehouse)

          hasZipcode[zipcode] = true
        }

        return prev
      }, [])
    }

    return [
      {
        id: NO_WAREHOUSES_ID,
        address: {name: ''},
      },
    ]
  },
)

export function warehouseIsSelectedSelector(state, {warehouseID}) {
  const form = scanFormModalFormSelector(state)

  return get(form, `${form.shipperID}_${warehouseID}__isSelected`, true)
}

export function isRequestingScanFormSelector(state, {warehouseID}) {
  const form = scanFormModalFormSelector(state)

  return get(form, `${form.shipperID}_${warehouseID}__isRequesting`, false)
}

export function inductionZipcodeSelector(state, {warehouseID}) {
  const form = scanFormModalFormSelector(state)
  const zipcode = warehouseZipcodeSelector(state, {warehouseID})

  return get(
    form,
    `${form.shipperID}_${warehouseID}__inductionZipcode`,
    zipcode,
  )
}

export function scanFormPrevScanFormsSelector(state, {warehouseID}) {
  const form = scanFormModalFormSelector(state)
  const groupByZipcode = groupByZipcodeSelector(state)
  const zipcode = warehouseZipcodeSelector(state, {warehouseID})

  const task = get(form, `${form.shipperID}_${warehouseID}__task`)

  const scanForms = (
    warehouseID === NO_WAREHOUSES_ID
      ? form.previousScanForms
      : form.previousScanForms.filter((sf) => {
          if (groupByZipcode) {
            const sfZipcode = warehouseZipcodeSelector(state, {
              warehouseID: sf.params.warehouse_id,
            })

            return sfZipcode === zipcode
          } else {
            return sf.params.warehouse_id === warehouseID
          }
        })
  ).filter((sf) => !task || sf.id !== task.id)

  return [...(task ? [task] : []), ...scanForms]
}

export function scanFormErrorSelector(state, {warehouseID}) {
  const form = scanFormModalFormSelector(state)

  return get(form, `${form.shipperID}_${warehouseID}__error`)
}

export function isPickupScanFormSelector(state) {
  const form = scanFormModalFormSelector(state)

  return get(form, `${form.shipperID}__isPickup`, false)
}

export function paymentMethodScanFormSelector(state) {
  const form = scanFormModalFormSelector(state)

  return get(form, `${form.shipperID}__paymentMethod`, 'Account')
}

export function isRequestingSummedSelector(state) {
  const warehouseForms = scanFormWarehouseFormsSelector(state)

  return warehouseForms.reduce(
    (prev, {isRequesting}) => prev || isRequesting || false,
    false,
  )
}

export function isSelectedSummedSelector(state) {
  const warehouseForms = scanFormWarehouseFormsSelector(state)

  return warehouseForms.reduce(
    (prev, {warehouseID}) =>
      prev || warehouseIsSelectedSelector(state, {warehouseID}) || false,
    false,
  )
}

export function warehouseNamesSelector(state, {warehouseID}) {
  const groupByZipcode = groupByZipcodeSelector(state)

  if (!groupByZipcode) {
    return [
      warehouseID === NO_WAREHOUSES_ID
        ? ''
        : get(warehouseSelector(state, {warehouseID}), 'address.name', ''),
    ]
  }

  const warehouses = nonFBAWarehousesSelector(state)
  const zipcode = warehouseZipcodeSelector(state, {warehouseID})

  return warehouses.reduce((prev, w) => {
    const z = get(w, 'address.zip', '')
    if (z === zipcode) {
      prev.push(w.address.name)
    }

    return prev
  }, [])
}

export function scanFormWarehouseFormsSelector(state) {
  const warehouses = validScanFormWarehousesSelector(state)

  return warehouses.map(({id: warehouseID}) => ({
    warehouseID,
    names: warehouseNamesSelector(state, {warehouseID}),
    zipcode: warehouseZipcodeSelector(state, {warehouseID}),
    isSelected: warehouseIsSelectedSelector(state, {
      warehouseID,
    }),
    isRequesting: isRequestingScanFormSelector(state, {
      warehouseID,
    }),
    prevScanForms: scanFormPrevScanFormsSelector(state, {
      warehouseID,
    }),
    error: scanFormErrorSelector(state, {
      warehouseID,
    }),
    inductionZipcode: inductionZipcodeSelector(state, {warehouseID}),
  }))
}

export function setWarehouseIsSelected(shipperID, warehouseID, isSelected) {
  updateScanFormForm({
    [`${shipperID}_${warehouseID}__isSelected`]: isSelected,
  })
}

export function setIsRequestingScanForm(shipperID, warehouseID, isRequesting) {
  updateScanFormForm({
    [`${shipperID}_${warehouseID}__isRequesting`]: isRequesting,
  })
}

export function setScanFormTask(shipperID, warehouseID, task) {
  updateScanFormForm({
    [`${shipperID}_${warehouseID}__task`]: task,
  })
}

export function setScanFormError(shipperID, warehouseID, error) {
  updateScanFormForm({
    [`${shipperID}_${warehouseID}__error`]: error,
  })
}

export function setIsPickupScanForm(shipperID, isPickup) {
  updateScanFormForm({
    [`${shipperID}__isPickup`]: isPickup,
  })
}

export function setPaymentMethodScanForm(shipperID, paymentMethod) {
  updateScanFormForm({
    [`${shipperID}__paymentMethod`]: paymentMethod,
  })
}

export function setInductionZipcode(shipperID, warehouseID, inductionZipcode) {
  updateScanFormForm({
    [`${shipperID}_${warehouseID}__inductionZipcode`]: inductionZipcode,
  })
}

export async function showScanFormModal() {
  try {
    const [shipper] = scanFormShippersSelector(getState())

    setupScanFormForm(shipper && shipper.id)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Unable to open Scan Form Modal',
        details: err.message,
      },
      err,
    )
  }
}

export async function setupScanFormForm(shipperID = null) {
  setForm(MODAL_FORM, {
    shipperID,
    previousScanForms: [],
    serverError: null,
  })

  await getPreviousScanForms(shipperID)
}

export async function getPreviousScanForms(shipperID) {
  if (!shipperID) {
    return
  }

  try {
    updateScanFormForm({
      serverError: null,
    })

    const {json} = await api.get(
      `/shipper/${shipperID}/end_of_day_forms/?limit=100`,
    )

    updateScanFormForm({
      previousScanForms: json.end_of_day_task,
    })
  } catch (err) {
    updateScanFormForm({
      serverError: err.message || err.error_message,
    })
  }
}

export async function createScanForm() {
  const allParams = scanFormParamsSelector(getState())
  const activityName = activityNameSelector(getState())

  try {
    updateScanFormForm({serverError: null})

    showMessageToast('Started request to create a scan form')

    await Promise.all(
      allParams.map((params) => createAndWaitForIt(activityName, params)),
    )

    await getPreviousScanForms()
  } catch (err) {
    updateScanFormForm({serverError: err.message || err.error_message})
  }
}

export async function createAndWaitForIt(activityName, params) {
  const shipperID = params.shipper_id
  const warehouseID = params.warehouse_id || NO_WAREHOUSES_ID

  try {
    setIsRequestingScanForm(shipperID, warehouseID, true)
    setScanFormError(shipperID, warehouseID, null)

    const [activity] = await createNewTasks([{type: activityName, params}])

    await waitForPDF(shipperID, warehouseID, activity._link)
  } catch (err) {
    setScanFormError(shipperID, warehouseID, err.message || err.error_message)
  } finally {
    setIsRequestingScanForm(shipperID, warehouseID, false)
  }
}

export async function waitForPDF(shipperID, warehouseID, activityLink) {
  let totalWait = 0

  while (totalWait < 10 * 60 * 1000) {
    const {json} = await api.get(activityLink)

    if (json.complete && json.success) {
      setScanFormTask(shipperID, warehouseID, json)

      return
    } else if (json.complete) {
      setScanFormError(shipperID, warehouseID, json.status)

      return
    }

    await delay(2000)

    totalWait += 2000
  }
}

export async function closeDay(shipperID) {
  try {
    setIsRequestingScanForm(shipperID, NO_WAREHOUSES_ID, true)
    setScanFormError(shipperID, NO_WAREHOUSES_ID, null)

    const params = scanFormParamsSelector(getState())
    const activityName = activityNameSelector(getState())

    await createNewTasks([{type: activityName, params}])

    removeScanFormForm()

    showMessageToast(
      'The process of closing out the shipper for the day has started',
    )
  } catch (err) {
    setScanFormError(
      shipperID,
      NO_WAREHOUSES_ID,
      `Error closing out shipper account: ${err.message}`,
    )
  } finally {
    setIsRequestingScanForm(shipperID, NO_WAREHOUSES_ID, false)
  }
}
