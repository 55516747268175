import {getCode2FromMatch} from './constants/Countries.js'

function findCityStateZip(lines, onLine) {
  // find the cityStateZip/postal code thing by looking backwards
  let cityStateZipIndex = -1
  let match = null
  for (let i = lines.length - 1; i >= 0; i--) {
    const line = lines[i]

    const lineMatch = onLine(line)

    if (lineMatch) {
      match = lineMatch
      cityStateZipIndex = i

      break
    }
  }

  // return early since this we didn't find it
  if (!match) {
    return [lines, null]
  }

  // lead lines are before the cityStateZip/postal code thing
  // we have explicitly thrown out the country line if present
  // since we now know it from matching on the cityStateZip/postal code thing
  const leadLines = lines.slice(0, cityStateZipIndex)

  return [leadLines, match]
}

function arrangeLeadLines(leadLines) {
  // arrange the leadLines consistently

  if (leadLines.length === 0) {
    return ['', '', '', '']
  } else if (leadLines.length === 1) {
    // one line should be treated as street1 line
    return ['', '', leadLines[0], '']
  } else if (leadLines.length === 2) {
    // two leadLines should be treated as name and street1 lines
    return [leadLines[0], '', leadLines[1], '']
  } else if (leadLines.length === 3) {
    // three leadLines should be treated as name, street1 and street2 lines
    return [leadLines[0], '', leadLines[1], leadLines[2]]
  }

  // four or more lines should be treated as name, company, street1 and street2 lines
  return leadLines.slice(0, 4)
}

function handleUSAddress(lines) {
  // try to find a US city state zip line
  // and get the lines that "lead" up to it and the regex match obj
  let [leadLines, match] = findCityStateZip(lines, (line) =>
    line.match(/^(.*\w+)[, ]+([a-z][a-z])[, ]+([0-9]{5}([- ][0-9]{4})?)/i),
  )

  // we didn't have a US address so let the next handle function run
  if (!match) {
    return null
  }

  // arrange the lead lines
  leadLines = arrangeLeadLines(leadLines)

  // layout everything
  return [
    leadLines[0], // name
    leadLines[1], // company
    leadLines[2], // street 1
    leadLines[3], // street 2
    match[1], // city
    match[2], // state
    match[3], // zip
    'US',
  ]
}

function handleCAAddress(lines) {
  let [leadLines, match] = findCityStateZip(lines, (line) =>
    line.match(
      /^(.*\w+)[, ]+([a-z][a-z])[, ]+([a-z][0-9][a-z][ -]+[0-9][a-z][0-9])$/i,
    ),
  )

  if (!match) {
    return null
  }

  leadLines = arrangeLeadLines(leadLines)

  return [
    leadLines[0],
    leadLines[1],
    leadLines[2],
    leadLines[3],
    match[1],
    match[2],
    match[3],
    'CA',
  ]
}

function handleAUAddress(lines) {
  let [leadLines, match] = findCityStateZip(lines, (line) =>
    line.match(/^(.*\w+)[, ]+([a-z]{2,3})[, ]+([0-9]{4,})$/i),
  )

  if (!match) {
    return null
  }

  leadLines = arrangeLeadLines(leadLines)

  return [
    leadLines[0],
    leadLines[1],
    leadLines[2],
    leadLines[3],
    match[1],
    match[2],
    match[3],
    'AU',
  ]
}

function handleGBAddress(lines) {
  // UK addresses set the city on a separate line from the postal code
  // and there is no "state"
  let [leadLines, match] = findCityStateZip(lines, (line) =>
    line.match(/^([a-z]{1,2}[0-9][a-z0-9]? ?[0-9][a-z]{2})$/i),
  )

  if (!match) {
    return null
  }

  // If any leadLines exist, the last must be the city
  const city = leadLines.pop()

  leadLines = arrangeLeadLines(leadLines)

  return [
    leadLines[0],
    leadLines[1],
    leadLines[2],
    leadLines[3],
    city || '',
    '',
    match[1],
    'GB',
  ]
}

function extractFullAddress(lines) {
  return (
    handleUSAddress(lines) ||
    handleCAAddress(lines) ||
    handleAUAddress(lines) ||
    handleGBAddress(lines) || [
      lines[0],
      lines[1] || '',
      lines[2] || '',
      lines[3] || '',
      lines[4] || '',
      lines[5] || '',
      '',
      '',
    ]
  )
}

function cleanSplitLines(str) {
  return (
    str
      // split on new lines
      .split('\n')
      // trim off leading/trailing spaces from each line
      .map((line) => line.trim())
      // remove any empty lines from the start
      .reduce((prev, line) => {
        // push only if we have pushed before or if line is something
        if (prev.length > 0 || line) {
          prev.push(line)
        }

        return prev
      }, [])
      // flip the array around
      .reverse()
      // remove any empty lines from the start
      .reduce((prev, line) => {
        // push only if we have pushed before or if line is something
        if (prev.length > 0 || line) {
          prev.push(line)
        }

        return prev
      }, [])
      // flip it back around
      .reverse()
  )
}

export default function parseAddress(str) {
  // remove leading and trailing spaces and new lines
  // keep empty new line in the middle
  let lines = cleanSplitLines(str)

  // nothing was in the string
  if (lines.length === 0) {
    return {}
  } else if (lines.length === 1) {
    // one line is a special expanded form with only a street1
    lines = ['', '', lines[0], '', '', '', '', '']
    // eight lines is a special expanded form
  } else if (lines.length === 7) {
    // seven lines is a special expanded form without company
    lines = [
      lines[0],
      '',
      lines[1],
      lines[2],
      lines[3],
      lines[4],
      lines[5],
      lines[6],
    ]
  }

  // eight lines is a special expanded form

  // if we don't have the special expanded form,
  // then we have a compact cityStateZip line
  if (lines.length !== 8) {
    lines = extractFullAddress(lines)
  }

  // make it an object and enforce a 2 digit code for country
  return {
    ...(lines[0] ? {name: lines[0]} : null),
    ...(lines[1] ? {company: lines[1]} : null),
    street1: lines[2],
    street2: lines[3],
    city: lines[4],
    state: lines[5],
    zip: lines[6],
    country: getCode2FromMatch(lines[7]) || '',
  }
}
