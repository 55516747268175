import {useState} from 'react'
import isEqual from 'lodash/isEqual.js'
import isNil from 'lodash/isNil.js'
import pick from 'lodash/pick.js'

import OmniBar, {parseInputValue} from '../../common/components/List/OmniBar.js'
import {parseNumber} from '../../common/parseNumbers.js'
import {
  PRODUCT_BOOLEAN_FILTER_OPTIONS,
  COMPARATORS,
  IS_ACTIVE_FILTER,
  PRODUCT_FULFILLMENT_CHANNELS,
  API_PRODUCT_BOOLEAN_FILTERS,
} from '../../common/constants/Products.js'
import {
  TAG_FILTER_OR,
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_NOT,
} from '../../common/constants/Tags.js'

import {activeCartsSortedByNameSelector} from '../../data/carts.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../data/suppliers.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../data/warehouses.js'
import {fulfillmentChannelsSelector} from '../../data/products.js'
import {productTagsSortedByNameSelector} from '../../data/productTags.js'
import {
  setFulfillmentChannelFilters,
  setCategory,
  setProductFilters,
  updateQuantity,
  updateQuantityComparator,
  setSearchText,
  updateProductTagFilters,
  updateProductUntagged,
  setProductTagFilterBy,
  productListQuerySelector,
  navigateProductList,
  setProductSupplierIDs,
  setProductWarehouseIDs,
  removeProductSupplierID,
  removeProductCartID,
  removeProductWarehouseID,
  setQuantity,
  setProductUntagged,
  removeProductTagFilter,
  navigateToProductListPage,
  updateProductFilters,
  setProductCartIDs,
  updateProductExcludeTags,
  removeProductExcludeTag,
} from './productListFunctions.js'
import {getState, useSelector} from '../../store.js'
import {isPresent} from '../../common/utils.js'

export const SEARCH_TYPE = 'search'
export const CART_TYPE = 'cart'
export const SUPPLIER_TYPE = 'supplier'
export const WAREHOUSE_TYPE = 'warehouse'
export const FULFILLMENT_CHANNEL_TYPE = 'fulfillment_channel'
export const CATEGORY_TYPE = 'category'
export const FILTERS_TYPE = 'filters'
export const QUANTITY_TYPE = 'quantity'
export const TAG_TYPE = 'tag'
export const TAG_AND_TYPE = 'tag_and'
export const TAG_ONLY_TYPE = 'tag_only'
export const TAG_NOT_TYPE = 'tag_not'
const TAG_TYPES = {
  [TAG_FILTER_OR]: TAG_TYPE,
  [TAG_FILTER_AND]: TAG_AND_TYPE,
  [TAG_FILTER_ONLY]: TAG_ONLY_TYPE,
  [TAG_FILTER_NOT]: TAG_NOT_TYPE,
}
export const UNTAGGED_TYPE = 'untagged'
export const EXCLUDE_TAGS_TYPE = 'exclude_tags'

export function productOmniBarFilterListSelector(state) {
  const cache = productOmniBarFilterListSelector.cache
  const carts = activeCartsSortedByNameSelector(state)
  const suppliers = suppliersSortedByNameSelector(state)
  const warehouses = warehousesSortedByNameSelector(state)
  const fulfillmentChannels = fulfillmentChannelsSelector(state)
  const productTags = productTagsSortedByNameSelector(state)
  const query = productListQuerySelector(state)

  if (
    cache &&
    isEqual(carts, cache.carts) &&
    isEqual(suppliers, cache.suppliers) &&
    isEqual(warehouses, cache.warehouses) &&
    isEqual(fulfillmentChannels, cache.fulfillmentChannels) &&
    isEqual(productTags, cache.productTags) &&
    query === cache.query
  ) {
    return cache.list
  }

  productOmniBarFilterListSelector.cache = {
    carts,
    suppliers,
    warehouses,
    fulfillmentChannels,
    productTags,
    query,
  }

  const list = productFilterList({
    carts,
    suppliers,
    warehouses,
    fulfillmentChannels,
    productTags,
    query,
  })

  productOmniBarFilterListSelector.cache.list = list

  return list
}

export function productFilterList({
  carts,
  suppliers,
  warehouses,
  fulfillmentChannels,
  productTags,
  query: {
    supplier = [],
    warehouse_id = [],
    sales_channel = [],
    category,
    quantity,
    quantity_comparator,
    tag = [],
    tag_filter_by,
    exclude_tags = [],
    untagged,
    search,
    ...query
  },
}) {
  const list = []

  const filters = pick(query, API_PRODUCT_BOOLEAN_FILTERS)
  const fulfillmentChannelFilters = pick(query, PRODUCT_FULFILLMENT_CHANNELS)

  PRODUCT_BOOLEAN_FILTER_OPTIONS.forEach((filter) => {
    if (isNil(filters[filter.value])) {
      return
    }

    list.push({
      type: FILTERS_TYPE,
      label: filters[filter.value] ? filter.display : filter.reverseDisplay,
      value: filter.value,
      urlValue: filter.urlValue,
    })
  })

  suppliers.forEach((s) => {
    if (!supplier.includes(s.id)) {
      return
    }

    list.push({
      type: SUPPLIER_TYPE,
      label: getSupplierName(s),
      value: `${SUPPLIER_TYPE}:${s.id}`,
      id: s.id,
    })
  })

  warehouses.forEach((warehouse) => {
    if (!warehouse_id.includes(warehouse.id)) {
      return
    }

    list.push({
      type: WAREHOUSE_TYPE,
      label: getWarehouseName(warehouse),
      value: `${WAREHOUSE_TYPE}:${warehouse.id}`,
      id: warehouse.id,
    })
  })

  fulfillmentChannels.forEach((option) => {
    if (!fulfillmentChannelFilters[option.value]) {
      return
    }

    list.push({
      type: FULFILLMENT_CHANNEL_TYPE,
      label: option.display,
      value: option.value,
    })
  })

  carts.forEach((cart) => {
    if (!sales_channel.includes(cart.id)) {
      return
    }

    list.push({
      type: CART_TYPE,
      label: cart.name,
      value: `${CART_TYPE}:${cart.id}`,
      id: cart.id,
    })
  })

  if (category) {
    list.push({
      type: CATEGORY_TYPE,
      label: category,
      value: `${CATEGORY_TYPE}:${category}`,
    })
  }

  if (isPresent(quantity)) {
    const comparator = COMPARATORS.find(
      ({value}) => value === quantity_comparator,
    )
    const quantityDisplay = `${comparator.display} ${quantity} On Hand`

    list.push({
      type: QUANTITY_TYPE,
      label: quantityDisplay,
      value: `${QUANTITY_TYPE}:${quantity}`,
    })
  }

  const tagType = TAG_TYPES[tag_filter_by || TAG_FILTER_OR]
  for (const t of productTags) {
    if (tag.includes(t.name)) {
      list.push({
        type: tagType,
        label: t.name,
        value: `${tagType}:${t.id}`,
        color: t.color,
        id: t.id,
      })
    }
  }

  for (const t of productTags) {
    if (exclude_tags.includes(t.name)) {
      list.push({
        type: EXCLUDE_TAGS_TYPE,
        label: t.name,
        value: `${EXCLUDE_TAGS_TYPE}:${t.id}`,
        color: t.color,
        text: t.name,
      })
    }
  }

  if (untagged) {
    list.push({
      type: UNTAGGED_TYPE,
      label: 'Untagged',
      value: 'untagged',
    })
  }

  if (search) {
    list.push({
      type: SEARCH_TYPE,
      label: search,
      value: search,
    })
  }

  return list
}

export const PROCESS_VALUE_FUNCTIONS = {
  processStatusFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(filterType.match(/^status$/i) && ['', '-'].includes(filterOperator))
    ) {
      return outcome
    }

    outcome.stop = true

    let reverse = filterOperator === '-'

    const filter = PRODUCT_BOOLEAN_FILTER_OPTIONS.find((option) => {
      if (
        `${option.value} ${option.urlValue} ${option.display}`.match(
          RegExp(filterValue, 'i'),
        )
      ) {
        return true
      }

      return false
    })

    if (filter) {
      outcome.hash = setProductFilters({
        [filter.urlValue]: reverse ? false : true,
      })
    }

    return outcome
  },

  processCategoryFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (!filterType.match(/^cat(egory)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setCategory(filterValue)

    return outcome
  },

  processSupplierFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^supplier[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const suppliers = suppliersSortedByNameSelector(getState())

    const matchedSuppliers = suppliers.filter((s) => {
      if (filterOperator === '=') {
        if (s.id === Number(filterValue)) {
          return true
        }
        if (s.address.company === filterValue) {
          return true
        }
      } else {
        if (
          `${s.address.company} ${s.address.city} ${s.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedSuppliers.length) {
      outcome.hash = setProductSupplierIDs([
        ...productListQuerySelector(getState()).supplier,
        ...matchedSuppliers.map(({id}) => id),
      ])
    }

    return outcome
  },

  processWarehouseFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^warehouse[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const warehouses = warehousesSortedByNameSelector(getState())

    const matchedWarehouses = warehouses.filter((w) => {
      if (filterOperator === '=') {
        if (w.id === Number(filterValue)) {
          return true
        }
        if (getWarehouseName(w) === filterValue) {
          return true
        }
      } else {
        if (
          `${w.address.name} ${w.address.company} ${w.address.city} ${w.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedWarehouses.length) {
      outcome.hash = setProductWarehouseIDs([
        ...productListQuerySelector(getState()).warehouse_id,
        ...matchedWarehouses.map(({id}) => id),
      ])
    }

    return outcome
  },

  processFulfillmentChannelFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(fulfillment|fulfillment channel|channel|fc)$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const fulfillmentChannels = fulfillmentChannelsSelector(getState())

    const matchedFulfillmentChannel = fulfillmentChannels.find((fc) => {
      if (fc.display.match(RegExp(filterValue, 'i'))) {
        return true
      }

      return false
    })

    if (matchedFulfillmentChannel) {
      outcome.hash = setFulfillmentChannelFilters({
        [matchedFulfillmentChannel.value]: true,
      })
    }

    return outcome
  },

  processCartFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(cart|sales channel)[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const carts = activeCartsSortedByNameSelector(getState())

    const matchedCarts = carts.filter((c) => {
      if (filterOperator === '=') {
        if (c.id === Number(filterValue)) {
          return true
        }
        if (c.name === filterValue) {
          return true
        }
      } else {
        if (`${c.name} ${c.vendor}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    if (matchedCarts.length) {
      outcome.hash = setProductCartIDs([
        ...productListQuerySelector(getState()).sales_channel,
        ...matchedCarts.map(({id}) => id),
      ])
    }

    return outcome
  },

  processQuantityFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(on hand )?(qty|quantity)$/i) &&
        ['', '=', '<', '>'].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    let compValue
    let number

    if (filterOperator) {
      const match = filterValue.match(/^(\d+)( on hand)?$/i)

      if (!match) {
        return outcome
      }

      compValue = filterOperator
      number = match[1]
    } else {
      const match = filterValue.match(/^((?:\w+ )+)?(\d+)( on hand)?$/i)

      if (!match) {
        return outcome
      }

      compValue = match[1] || '='
      number = match[2]
    }

    number = parseNumber(number)

    const comparator = COMPARATORS.find((option) => {
      if (
        `${option.symbol} ${option.value} is ${option.display}`.match(
          RegExp(compValue.trim(), 'i'),
        )
      ) {
        return true
      }

      return false
    })

    if (comparator && number !== null) {
      outcome.hash = navigateProductList({
        ...updateQuantity(number),
        ...updateQuantityComparator(comparator.value),
      })
    }

    return outcome
  },

  processTagFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag)[s]?$/i) &&
        ['', '=', '-', '!', '!='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const productTags = productTagsSortedByNameSelector(getState())
    const query = productListQuerySelector(getState())

    const matchedTags = productTags.filter((t) => {
      if (['=', '!='].includes(filterOperator)) {
        if (t.id === Number(filterValue)) {
          return true
        }
        if (t.name === filterValue) {
          return true
        }
      } else {
        if (`${t.name}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    const negate = ['!', '-', '!='].includes(filterOperator)

    if (matchedTags.length) {
      let updates = updateProductUntagged(false)

      if (negate) {
        updates = {
          ...updates,
          ...updateProductExcludeTags([
            ...query.exclude_tags,
            ...matchedTags.map(({name}) => name),
          ]),
        }
      } else {
        updates = {
          ...updates,
          ...updateProductTagFilters([
            ...query.tag,
            ...matchedTags.map(({name}) => name),
          ]),
        }
      }
      outcome.hash = navigateProductList(updates)
    }

    return outcome
  },

  processUntaggedValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (!filterValue.match(/^untag(ged)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = navigateProductList({
      ...updateProductUntagged(true),
      ...updateProductTagFilters([]),
    })

    return outcome
  },

  processTagFilterByValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag.filter(ing)?.logic|tfl)$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const filterBy = filterValue.match(/^(and|all)$/i)
      ? TAG_FILTER_AND
      : filterValue.match(/^only$/i)
        ? TAG_FILTER_ONLY
        : filterValue.match(/^not$/i)
          ? TAG_FILTER_NOT
          : TAG_FILTER_OR

    outcome.hash = setProductTagFilterBy(filterBy)

    return outcome
  },

  // Put new functions before this function
  processSearchTextFilterValue(inputValue) {
    return {stop: true, hash: setSearchText(inputValue)}
  },
  // Do not put functions after this function
}

export function processInputValue(inputValue, setInputValue) {
  if (!inputValue) {
    setInputValue('')

    return
  }

  const [, filterType, filterOperator, filterValue] =
    parseInputValue(inputValue)

  let hash
  for (const processFilterValue of Object.values(PROCESS_VALUE_FUNCTIONS)) {
    const outcome = processFilterValue(
      inputValue,
      filterType,
      filterOperator,
      filterValue,
    )

    if (outcome.stop) {
      hash = outcome.hash
      break
    }
  }

  if (hash) {
    setInputValue('')

    return hash
  }

  return
}

export function removeValue(value) {
  if (value.type === CART_TYPE) {
    return removeProductCartID(value.id)
  } else if (value.type === SUPPLIER_TYPE) {
    return removeProductSupplierID(value.id)
  } else if (value.type === WAREHOUSE_TYPE) {
    return removeProductWarehouseID(value.id)
  } else if (value.type === FULFILLMENT_CHANNEL_TYPE) {
    return setFulfillmentChannelFilters({[value.value]: null})
  } else if (value.type === CATEGORY_TYPE) {
    return setCategory('')
  } else if (value.type === FILTERS_TYPE) {
    return setProductFilters({[value.urlValue]: null})
  } else if (value.type === QUANTITY_TYPE) {
    return setQuantity(null)
  } else if (
    [TAG_TYPE, TAG_AND_TYPE, TAG_ONLY_TYPE, TAG_NOT_TYPE].includes(value.type)
  ) {
    return removeProductTagFilter(value.label)
  } else if (value.type === UNTAGGED_TYPE) {
    return setProductUntagged(false)
  } else if (value.type === EXCLUDE_TAGS_TYPE) {
    return removeProductExcludeTag(value.label)
  } else if (value.type === SEARCH_TYPE) {
    return setSearchText('')
  }
}

export function clearAll() {
  return navigateToProductListPage(
    updateProductFilters({
      [IS_ACTIVE_FILTER]: null,
    }),
  )
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  }
}

export default function ProductListOmniBar() {
  const [inputValue, setInputValue] = useState('')
  const list = useSelector(productOmniBarFilterListSelector)

  return (
    <OmniBar
      placeholder="Enter a product name or SKU..."
      inputValue={inputValue}
      value={list}
      onRemoveValue={(removedValue) => removeValue(removedValue)}
      onClear={() => clearAll()}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        processInputValue(inputValue, setInputValue)
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}
