import api from '../../../../../common/api.js'
import {
  suppliersSelector,
  getSupplierName,
  activeSuppliersByNameSelector,
} from '../../../../../data/suppliers.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'
import baseStrategy from './baseStrategy.js'

export default class removeSupplierSKUStrategy extends baseStrategy {
  static title = 'Remove Supplier SKUs'
  static requiredColumns = ['sku', 'supplier']

  static getExampleRows() {
    return (dispatch, getState) => {
      const suppliers = suppliersSelector(getState())

      return api
        .get('/product/', {limit: 10, sort: 'default_supplier', dropship: true})
        .then(({json: data}) => {
          const rows = [['SKU *Req', 'Supplier *Req']]

          data.product.forEach((product) => {
            product.suppliers.forEach((supplier) => {
              rows.push([product.sku, getSupplierName(suppliers[supplier.id])])
            })
          })

          return rows
        })
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      supplier: find(/^supplier$/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const suppliersByName = activeSuppliersByNameSelector(getState())
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          suppliersByName,
        )
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData(row, columnsByName, suppliersByName) {
    const data = this.getNewData()
    const supplier = suppliersByName[row[columnsByName.supplier]] || {
      id: -1,
    }

    data.payload = {
      sku: row[columnsByName.sku],
      supplierID: supplier.id,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    if (payload.supplierID === -1) {
      errors.supplierID = 'Select a valid supplier'
    } else {
      delete errors.supplierID
    }

    return errors
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const supplierID = data.payload.supplierID
      const url = `/product/${sku}/supplier/${supplierID}/`

      return api.delete(url)
    }
  }
}
