import PropTypes from 'prop-types'
import {forwardRef, useMemo} from 'react'
import classNames from 'classnames'

import {
  createProductSelector,
  createDefaultSupplierSelector,
  createDefaultSupplierWarehousesSelector,
} from '../../../data/products.js'
import {createIsProductRowExpandedSelector} from '../productListFunctions.js'
import SelectCell from '../Row/SelectCell.js'
import NameCell from '../Row/NameCell.js'
import SKUCell from '../Row/SKUCell.js'
import SupplierCell from '../Row/SupplierCell.js'
import WeightCell from '../Row/WeightCell.js'
import DimensionsCell from '../Row/DimensionsCell.js'
import WritebackCell from '../Row/WritebackCell.js'
import WarehouseTable from '../Row/WarehouseTable.js'
import ExpandCell from '../Row/ExpandCell.js'
import {useSelector} from '../../../store.js'
import {useSuppliersSelector} from '../../../data/suppliers.js'

const Row = forwardRef(({sku}, ref) => {
  const useSuppliers = useSelector(useSuppliersSelector)
  const productSelector = useMemo(() => createProductSelector(sku), [sku])
  const isExpandedSelector = useMemo(
    () => createIsProductRowExpandedSelector(sku),
    [sku],
  )
  const defaultSupplierSelector = useMemo(
    () => createDefaultSupplierSelector(productSelector),
    [productSelector],
  )
  const defaultSupplierWarehousesSelector = useMemo(
    () =>
      createDefaultSupplierWarehousesSelector(
        productSelector,
        defaultSupplierSelector,
      ),
    [productSelector, defaultSupplierSelector],
  )

  const isExpanded = useSelector(isExpandedSelector)

  return (
    <tr
      ref={ref}
      className={classNames('table__tr', {'table__tr--expanded': isExpanded})}
    >
      <SelectCell sku={sku} />
      <NameCell sku={sku} isExpanded={isExpanded} />
      <SKUCell sku={sku} />
      {useSuppliers && (
        <SupplierCell
          sku={sku}
          productSelector={productSelector}
          defaultSupplierSelector={defaultSupplierSelector}
        />
      )}
      <WeightCell sku={sku} />
      <DimensionsCell sku={sku} />
      <WritebackCell sku={sku} isExpanded={isExpanded} />
      <WarehouseTable
        productSelector={productSelector}
        defaultSupplierWarehousesSelector={defaultSupplierWarehousesSelector}
        isExpandedSelector={isExpandedSelector}
      />
      <ExpandCell sku={sku} />
    </tr>
  )
})

Row.displayName = 'Row'

Row.propTypes = {
  sku: PropTypes.string.isRequired,
}

export default Row
