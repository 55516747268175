import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {dispatch, useSelector} from '../../../../store.js'
import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {
  needsWarehouseSelector,
  defaultWarehouseSelector,
} from '../../../../data/warehouses.js'
import {showWarehouseModal} from '../../../Modals/WarehouseModal.js'
import {showExportUSPSTransactionsModal} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {
  saveShipperPitney,
  activatePitneyShipper,
} from '../../../../redux/actions/ui/settings/shippers/forms/pitney.js'
import {hasErrorsSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import PostageBalance from './PostageBalance.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

export default function PitneyForm({form, cancel}) {
  const hasErrors = useSelector((state) => hasErrorsSelector(state, {form}))
  const needsWarehouse = useSelector(needsWarehouseSelector)
  const firstWarehouseID = get(useSelector(defaultWarehouseSelector), 'id')

  if (form.id !== NEW_ID) {
    return (
      <div className="settings-list-item-form-wrap settings-list-item-form-usps clear-both">
        <fieldset>
          <ul className="form-list medium-5 columns margin-bottom-0">
            <Nickname shipperID={form.id} />
            <ShippingMethods shipperID={form.id} />
            <li className="list__item list__item--no-style divider--bottom padding-bottom-20">
              <ButtonPrimary
                className="btn--primary-alt"
                isLoading={form.isSaving}
                isDisabled={hasErrors}
                onClick={() => dispatch(saveShipperPitney(form.id))}
              >
                Update
              </ButtonPrimary>
            </li>
          </ul>
        </fieldset>
        <fieldset className="margin-bottom-0">
          <div className="medium-5 columns">
            <PostageBalance shipperID={form.id} />
            <dl className="list divider--top margin-top-20">
              <dt className="list__title fs-00">Transaction History</dt>
              <dd className="list__item fs-00">
                <button
                  type="button"
                  className="btn btn--link"
                  onClick={() => dispatch(showExportUSPSTransactionsModal())}
                >
                  Export CSV
                </button>
              </dd>
            </dl>
          </div>
        </fieldset>
      </div>
    )
  }

  return (
    <div className="medium-7 columns">
      <h4 className="margin-top-20 margin-bottom-5">
        Activate your free USPS account
      </h4>
      <p className="margin-bottom-15">
        {
          'To gain access to our USPS shipping rates and to print USPS labels, you need to activate your '
        }
        <a
          href="//support.ordoro.com/how-do-i-get-a-free-postage-account-with-ordoro/"
          title="Learn more about Ordoro's free USPS accounts"
          target="_blank"
          rel="noopener noreferrer"
        >
          free USPS account
        </a>
        {'.'}
      </p>
      {needsWarehouse ? (
        <div className="alert alert--warning-lt alert--lg align-center margin-bottom-20">
          <div
            className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-5"
            aria-hidden="true"
          />
          <p className="fs-01 margin-bottom-10">
            <strong>
              You need to set a ship-from/warehouse address before you can
              activate your free USPS account.
            </strong>
          </p>
          <ButtonPrimary
            alt
            size="md"
            onClick={() =>
              showWarehouseModal({
                warehouseID: firstWarehouseID,
                openedFrom: 'setup',
              })
            }
          >
            Set a Ship-From Address
          </ButtonPrimary>
        </div>
      ) : (
        <div>
          <ButtonPrimary
            onClick={() => {
              activatePitneyShipper()

              cancel()
            }}
          >
            Activate now
          </ButtonPrimary>
          <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
        </div>
      )}
    </div>
  )
}

PitneyForm.propTypes = {
  form: ShipperFormShape,
  cancel: PropTypes.func.isRequired,
}
