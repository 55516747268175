import {createSelector} from 'reselect'

import {NEW_ID} from '../../../../common/constants/index.js'
import {isPresent, isNumeric} from '../../../../common/utils.js'
import {API_KEYS_FORM} from '../../../actions/ui/settings/apiKeys.js'
import {routeSelector} from '../route.js'
import {formSelector} from '../forms.js'

export function apiKeyFormSelector(state) {
  return formSelector(state, {key: API_KEYS_FORM}) || {}
}

export function apiKeyEditFormSelector(state, {apiKeyID}) {
  return (
    formSelector(state, {key: `${API_KEYS_FORM}/${apiKeyID}`}) || {
      name: '',
      isSaving: false,
      serverError: null,
      showArchiveConfirm: false,
      isArchiving: false,
      archiveError: null,
    }
  )
}

export function apiKeyEditFormErrors(state, {apiKeyID}) {
  const form = apiKeyEditFormSelector(state, {apiKeyID})
  const errors = {}

  if (!isPresent(form.name)) {
    errors.name = 'Name is required'
  }

  return errors
}

export const apiKeyEditIDSelector = createSelector(
  routeSelector,
  ({pathComponents}) =>
    pathComponents[0] === 'settings' &&
    pathComponents[1] === 'api_key' &&
    pathComponents[2]
      ? isNumeric(pathComponents[2])
        ? Number(pathComponents[2])
        : pathComponents[2]
      : null,
)

export const apiKeyIsNewSelector = createSelector(
  apiKeyEditIDSelector,
  (editID) => editID === NEW_ID,
)

export const apiKeysHashSelector = createSelector(
  apiKeyEditIDSelector,
  (editID) => {
    if (editID) {
      return `#/settings/api_key/${editID}/`
    }
    return '#/settings/api_key'
  },
)
