import {useEffect} from 'react'

import {PRODUCT_LIST_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  setupProductListForm,
  productListQuerySelector,
} from './productListFunctions.js'
import {dispatch, useSelector} from '../../store.js'
import {LIST_MODE} from '../../common/constants/index.js'

export default function ProductListLocationListener() {
  const query = useSelector(productListQuerySelector)

  useEffect(() => {
    setupProductListForm()
  }, [])

  useEffect(() => {
    if (query.mode !== LIST_MODE) {
      return
    }

    dispatch(setCurrentPage(PRODUCT_LIST_PAGE))
  }, [query.mode])

  return null
}
