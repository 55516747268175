import PropTypes from 'prop-types'
import Checkbox from '../../../common/components/Checkbox.js'
import {setFormValue, useForm, useSelector} from '../../../store.js'
import FormListItem from '../Common/FormListItem.js'
import {
  canReviseLineTotalPriceSelector,
  canReviseOrdersSelector,
} from '../../../data/carts.js'

export default function RevisionConfig({className, formName}) {
  const {id} = useForm(formName)
  const canReviseOrders = useSelector((state) =>
    canReviseOrdersSelector(state, {cartID: id}),
  )
  const canReviseLineTotalPrice = useSelector((state) =>
    canReviseLineTotalPriceSelector(state, {cartID: id}),
  )
  const {revision_config} = useForm(formName)

  if (!canReviseOrders) {
    return null
  }

  return (
    <FormListItem className={className}>
      <p className="fs-00 lh-md margin-bottom-5">
        <strong>External Order Revisions</strong>{' '}
        <span className="margin-right-3">(if supported)</span>
        <a
          className="btn btn--link no-underline light"
          href="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/"
          target="_blank"
          rel="noopener noreferrer"
          title="Learn about external order line revisions"
        >
          <span className="i-help fs-01 lh-sm margin-right-3"></span>
        </a>
      </p>
      <p className="fs-00 lh-md margin-bottom-5">
        Select from the following list which elements of an order you would like
        to receive notifications for if they are updated outside of Ordoro. Once
        notified, you can choose to accept the revision in Ordoro.
      </p>
      <p className="fs-00 lh-md">
        <a
          className="btn btn--link light"
          href="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/"
          target="_blank"
          rel="noopener noreferrer"
          title="Learn about external order line revisions"
        >
          View list of supported sales channels
        </a>{' '}
        →
      </p>
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="revision_config__line_changes__quantity"
        label="Changes to Line Quantity"
        checked={revision_config.lines_changed.includes('quantity')}
        onChange={(checked) => {
          let line = revision_config.lines_changed.filter(
            (v) => v !== 'quantity',
          )

          if (checked) {
            line.push('quantity')
          }

          setFormValue(formName, ['revision_config', 'lines_changed'], line)
        }}
      />
      {canReviseLineTotalPrice && (
        <Checkbox
          labelClassName="flex margin-bottom-10"
          id="revision_config__line_changes__total_price"
          label="Changes to the Line Total Price"
          checked={revision_config.lines_changed.includes('total_price')}
          onChange={(checked) => {
            let line = revision_config.lines_changed.filter(
              (v) => v !== 'total_price',
            )

            if (checked) {
              line.push('total_price')
            }

            setFormValue(formName, ['revision_config', 'lines_changed'], line)
          }}
        />
      )}
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="revision_config__lines_added"
        label="Additional Lines"
        checked={revision_config.lines_added}
        onChange={(checked) =>
          setFormValue(formName, ['revision_config', 'lines_added'], checked)
        }
      />
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="revision_config__lines_removed"
        label="Removed Lines"
        checked={revision_config.lines_removed}
        onChange={(checked) =>
          setFormValue(formName, ['revision_config', 'lines_removed'], checked)
        }
      />
      <p className="fs-00 lh-md margin-top-15 margin-bottom-5">
        <strong>Want to accept external order revisions automatically?</strong>
      </p>
      <p className="fs-00 lh-md margin-bottom-10">
        Checking the following option will bypass notifications of your selected
        order revision types and instead automatically apply them to the
        respective order in Ordoro.
      </p>
      <Checkbox
        labelClassName="flex margin-bottom-10"
        id="revision_config__auto_apply"
        label="Automatically accept my selected order line revisions"
        checked={!!revision_config.auto_apply}
        onChange={(checked) =>
          setFormValue(formName, ['revision_config', 'auto_apply'], checked)
        }
      />
    </FormListItem>
  )
}

RevisionConfig.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
