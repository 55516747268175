import {createSelector} from 'reselect'

import {setForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'

export const BATCH_SUMMARIES = 'BATCH_SUMMARIES'

export function setBatchSummaries(batchSummaries) {
  setForm(BATCH_SUMMARIES, batchSummaries)
}

export const batchSummariesSelector = createSelector(
  formsSelector,
  (forms) => forms[BATCH_SUMMARIES] || batchSummariesSelector.default,
)
batchSummariesSelector.default = {}

export function batchSummarySelector(state, {referenceID}) {
  return batchSummariesSelector(state)[referenceID]
}

export async function ensureBatchSummary(referenceID) {
  const {json: batchSummary} = await apiverson.get('/batch/summary', {
    reference_id: referenceID,
  })

  setBatchSummaries(batchSummary)
}
