import toPath from 'lodash/toPath.js'
import get from 'lodash/get.js'
import set from 'lodash/set.js'
import cloneDeep from 'lodash/cloneDeep.js'
import find from 'lodash/find.js'
import sortBy from 'lodash/sortBy.js'
import {
  allLinesVerifiedSelector,
  getFlattenedLines,
  initialLinesWithCountsSelector,
  packingModeSelector,
  packingOrderNumbersSelector,
  packPanelLinesWithCountsSelector,
  PACK_PANEL_BARCODE_SCANNED,
  PACK_PANEL_FORM,
  PACK_PANEL_GENERAL_ERROR,
  PACK_PANEL_NEEDS_SERIAL_NUMBER,
  scannedCodeMatchesLine,
  packingOrderScanResultSelector,
  packPanelFormSelector,
  PACK_PANEL_DUPLICATE_SERIAL_NUMBER,
  serialNumbersUpdatesSelector,
  SCANNED_CODE_SERIAL_NUMBER_IS_SKU,
} from './packPanelSelectors.js'
import {getState, updateForm} from '../../../store.js'
import {
  orderProductsSelector,
  orderSelector,
  setOrder,
  addOrderComment,
  ensureOrdersTagged,
  updateOrders,
} from '../../../data/orders.js'
import {ensureProductKitsLoaded} from '../../../redux/actions/data/productKits.js'
import {
  PACK_MODE,
  PICK_MODE,
  PRODUCT_OVERSCANNED,
  SCANNED_CODE_NOT_IN_ORDER,
} from '../../../common/constants/PackingOrderModal.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {PACK_SHIP_PAGE} from '../../../common/constants/Pages.js'
import {closeModal as closePackingOrderModal} from '../Modals/PackingOrderModal.js'
import {closeModal as closePickOrdersModal} from '../Modals/PickOrdersModal.js'
import apiverson from '../../../common/apiverson.js'

export function setupPackPanelForm({orderNumbers, mode}) {
  return {
    formName: PACK_PANEL_FORM,
    initialForm: {
      orderNumbers,
      mode,
      scannedCode: null,
      scanResult: null,
      overscannedComponents: null,
      linesWithCounts: [],
      isLoading: true,
      sortListBy: 'location',
      includeKitParents: true,
      scannedSerialNumber: null,
      serialNumberNeed: [],
      serialNumbersForKits: false,
      serialNumberFocusIndex: 0,
    },
  }
}

export async function setupPackPanel(orderNumbers) {
  updatePackPanelForm({orderNumbers})

  try {
    const lookup = {}
    for (const orderNumber of orderNumbers) {
      const order = orderSelector(getState(), {orderNumber})
      if (!order) {
        await updateOrders([orderNumber])
      }

      const products = orderProductsSelector(getState(), {orderNumber})
      Object.values(products).forEach((product) => (lookup[product.sku] = true))
    }

    const skus = Object.keys(lookup)

    await ensureProductKitsLoaded(skus)

    updatePackPanelForm({
      linesWithCounts: initialLinesWithCountsSelector(getState()),
    })
  } catch (error) {
    updatePackPanelForm({
      error: {type: PACK_PANEL_GENERAL_ERROR, message: error.message},
    })
  } finally {
    updatePackPanelForm({
      isLoading: false,
    })
  }
}

export function updatePackPanelForm(updates, meta) {
  updateForm(PACK_PANEL_FORM, updates, meta)
}

export function acknowledgeError() {
  const {scanResult} = packPanelFormSelector(getState()) || {}

  if (
    [
      PACK_PANEL_DUPLICATE_SERIAL_NUMBER,
      SCANNED_CODE_SERIAL_NUMBER_IS_SKU,
    ].includes(scanResult)
  ) {
    updatePackPanelForm({
      scanResult: PACK_PANEL_NEEDS_SERIAL_NUMBER,
      overscannedComponents: null,
    })
  } else {
    updatePackPanelForm({
      scanResult: null,
      overscannedComponents: null,
    })
  }
}

export function propagateToChildren(line, incrementQuantity) {
  return {
    ...line,
    verifiedQuantity:
      incrementQuantity === Infinity
        ? line.orderedQuantity
        : line.verifiedQuantity + incrementQuantity,
    ...(line.children
      ? {
          children: line.children.map((child) =>
            propagateToChildren(
              child,
              incrementQuantity === Infinity
                ? Infinity
                : incrementQuantity * child.kitQuantity,
            ),
          ),
        }
      : undefined),
  }
}

export function recalculateChildren(line) {
  if (line.children) {
    const children = line.children.map(recalculateChildren)
    const satisfiedChildKitQuantities = children.map((child) => {
      return Math.floor(child.verifiedQuantity / child.kitQuantity)
    })
    return {
      ...line,
      verifiedQuantity: Math.max(
        line.verifiedQuantity,
        Math.min(...satisfiedChildKitQuantities),
      ),
      children,
    }
  } else {
    return line
  }
}

export function recalculateLine(linesWithCounts, path) {
  const topParentPath = `[${toPath(path)[0] || '0'}]`
  const topParent = get(linesWithCounts, topParentPath)

  return set(linesWithCounts, topParentPath, recalculateChildren(topParent))
}

// collect only leaf nodes with each quantities multiplied by increment quantity
export function getLeafUpdates(line, incrementQuantity) {
  if (!line.children) {
    return [
      {
        sku: line.sku,
        incrementQuantity,
      },
    ]
  }
  return line.children.reduce(
    (prev, child) => [
      ...prev,
      ...getLeafUpdates(child, incrementQuantity * child.kitQuantity),
    ],
    [],
  )
}

export function forceVerifyLine(path) {
  const linesWithCounts = cloneDeep(
    packPanelLinesWithCountsSelector(getState()),
  )

  const line = get(linesWithCounts, path)
  const updatedLine = propagateToChildren(line, Infinity)
  set(linesWithCounts, path, updatedLine)
  recalculateLine(linesWithCounts, updatedLine.path)

  const [serialNumberNeed, serialNumbersForKits] = determineSerialNumberNeed(
    linesWithCounts,
    line.path,
  )

  updatePackPanelForm({
    linesWithCounts,
    serialNumberNeed,
    serialNumbersForKits,
    serialNumberFocusIndex: 0,
    scanResult: serialNumberNeed.length ? PACK_PANEL_NEEDS_SERIAL_NUMBER : null,
  })
}

function setZeroQuantity(line) {
  if (line.children) {
    line.children = line.children.map(setZeroQuantity)
  }

  return {
    ...line,
    verifiedQuantity: 0,
    orderLineIDSets: line.orderLineIDSets.map((orderLineIDSet) => ({
      ...orderLineIDSet,
      serialNumbers: [],
    })),
  }
}

export function resetPackPanel() {
  const linesWithCounts = packPanelLinesWithCountsSelector(getState())

  updatePackPanelForm({
    scanResult: null,
    linesWithCounts: linesWithCounts.map(setZeroQuantity),
  })
}

async function updateOrderLineSerialNumbers(
  orderNumber,
  orderLineID,
  serialNumbers,
) {
  const {json: order} = await apiverson.put(
    `/order/${encodeURIComponent(orderNumber)}/line/${orderLineID}/`,
    {product_serial_numbers: serialNumbers},
  )

  setOrder(order)
}

export async function verifyContents() {
  try {
    const allLinesVerified = allLinesVerifiedSelector(getState())

    const serialNumberUpdates = serialNumbersUpdatesSelector(getState())

    if (!allLinesVerified) {
      return
    }

    const orderNumbers = packingOrderNumbersSelector(getState())
    const mode = packingModeSelector(getState())

    await ensureOrdersTagged(
      mode === PACK_MODE
        ? {
            label: 'Contents Verified',
            color: '#3E992C',
            orderNumbers,
          }
        : {
            label: 'Contents Picked',
            color: '#F58026',
            orderNumbers,
          },
    )

    await Promise.all(
      orderNumbers.map((orderNumber) =>
        addOrderComment(
          orderNumber,
          `Package contents ${mode === PACK_MODE ? 'verified' : 'picked'}`,
        ),
      ),
    )

    await Promise.all(
      serialNumberUpdates.reduce(
        (prev, {orderNumber, lines}) => [
          ...prev,
          ...lines.map(({orderLineID, serialNumbers}) =>
            updateOrderLineSerialNumbers(
              orderNumber,
              orderLineID,
              serialNumbers,
            ),
          ),
        ],
        [],
      ),
    )

    const currentPage = currentPageSelector(getState())
    if (currentPage !== PACK_SHIP_PAGE) {
      closePackingOrderModal()
      closePickOrdersModal()
    }
  } catch (error) {
    updatePackPanelForm({
      error: {type: PACK_PANEL_GENERAL_ERROR, message: error.message},
    })
  }
}

export function handleSerialNumberScan(serialNumber) {
  let {linesWithCounts, serialNumberNeed, serialNumberFocusIndex} =
    packPanelFormSelector(getState())

  if (!serialNumberNeed || !serialNumberNeed[serialNumberFocusIndex]) {
    return
  }

  // get first path to line that needs a serial number
  const need = serialNumberNeed[serialNumberFocusIndex]
  const line = get(linesWithCounts, need.path)

  if (scannedCodeMatchesLine(serialNumber, line)) {
    updatePackPanelForm({
      scannedSerialNumber: serialNumber,
      scanResult: SCANNED_CODE_SERIAL_NUMBER_IS_SKU,
    })

    return
  }

  // get the order line ID set that doesn't have enough serial numbers
  const orderLineIDSet = line.orderLineIDSets.find(
    (lineSet) => lineSet.quantity > lineSet.serialNumbers.length,
  )

  // if we actually got a order line set
  if (orderLineIDSet) {
    // but make sure we haven't already used this serial number
    if (orderLineIDSet.serialNumbers.includes(serialNumber)) {
      updatePackPanelForm({
        scannedSerialNumber: serialNumber,
        serialNumberNeed,
        scanResult: PACK_PANEL_DUPLICATE_SERIAL_NUMBER,
      })

      return
    }

    // add it to the serial numbers of the order line
    orderLineIDSet.serialNumbers.push(serialNumber)
  }

  // remove first path since we have found a place for the serial number
  if (need.quantity > 1) {
    need.quantity -= 1
  } else {
    // filter out used up index position
    serialNumberNeed = serialNumberNeed.filter(
      (_, index) => index !== serialNumberFocusIndex,
    )
  }

  // if there are still serial numbers to collect
  if (serialNumberNeed.length) {
    // ask the user for another serial number
    updatePackPanelForm({
      serialNumberNeed: [...serialNumberNeed],
      // move index if no longer available
      serialNumberFocusIndex: serialNumberNeed[serialNumberFocusIndex]
        ? serialNumberFocusIndex
        : serialNumberFocusIndex - 1,
      scanResult: PACK_PANEL_NEEDS_SERIAL_NUMBER,
    })
  } else {
    // otherwise ask for another SKU
    finishSerialNumberScan()
  }
}

export function packPanelBarcodeScanned(scannedCode) {
  // if we are expecting a serial number
  if (
    packingOrderScanResultSelector(getState()) ===
    PACK_PANEL_NEEDS_SERIAL_NUMBER
  ) {
    handleSerialNumberScan(scannedCode)

    return
  }

  const linesWithCounts = packPanelLinesWithCountsSelector(getState())

  // flatten tree of lines into 1D array
  const flattenedLinesWithCounts = getFlattenedLines(linesWithCounts)

  // find line in flat array
  const matchingFlattenedLine = find(flattenedLinesWithCounts, (line) =>
    scannedCodeMatchesLine(scannedCode, line),
  )

  // exit early if line not found
  if (!matchingFlattenedLine) {
    updatePackPanelForm({
      scannedCode,
      scanResult: SCANNED_CODE_NOT_IN_ORDER,
    })

    return
  }

  // only lines that have quantity left to verify
  const unverifiedLines = flattenedLinesWithCounts.filter(
    (line) => line.verifiedQuantity < line.orderedQuantity,
  )

  // order by level in tree (lower / more parent first)
  const sortedUnverifiedLines = sortBy(unverifiedLines, 'level')

  // look for line again
  const matchingFlattenedUnverifiedLine = find(sortedUnverifiedLines, (line) =>
    scannedCodeMatchesLine(scannedCode, line),
  )

  // if not found then we overscanned line
  if (!matchingFlattenedUnverifiedLine) {
    updatePackPanelForm({
      scannedCode,
      scanResult: PRODUCT_OVERSCANNED,
      overscannedComponents: {
        [matchingFlattenedLine.sku]: {
          remaining: 0,
          scanned: 1,
        },
      },
    })

    return
  }

  // clone because we will be updating tree
  let updatedLinesWithCounts = cloneDeep(linesWithCounts)

  // get matching line again, why?
  const matchingLine = get(
    linesWithCounts,
    matchingFlattenedUnverifiedLine.path,
  )

  // consume 1 from self and descendence
  let updatedLine = propagateToChildren(matchingLine, 1)

  // update cloned tree
  set(updatedLinesWithCounts, updatedLine.path, updatedLine)

  // recalculate quantities starting from top parent
  recalculateLine(updatedLinesWithCounts, updatedLine.path)

  // look for over verified quantities after recalculation
  const overscannedParts = getFlattenedLines([updatedLine]).filter(
    (line) =>
      line.verifiedQuantity > line.orderedQuantity &&
      line.verifiedQuantity !== Infinity,
  )

  // if lines are over verified
  // and we can't put the overscan in other lines
  // return early and don't really update things
  if (
    overscannedParts.length > 0 &&
    handleOverScannedParts(
      scannedCode,
      overscannedParts,
      updatedLinesWithCounts,
      matchingLine,
    )
  ) {
    return
  }

  // make a list of serial numbers that need to collected
  const [serialNumberNeed, serialNumbersForKits] = determineSerialNumberNeed(
    updatedLinesWithCounts,
    matchingLine.path,
  )

  updatePackPanelForm({
    linesWithCounts: updatedLinesWithCounts,
    scannedCode,
    serialNumberNeed,
    serialNumbersForKits,
    serialNumberFocusIndex: 0,
    scanResult: serialNumberNeed.length
      ? PACK_PANEL_NEEDS_SERIAL_NUMBER
      : PACK_PANEL_BARCODE_SCANNED,
  })
}

function determineSerialNumberNeed(linesWithCounts, path) {
  const {mode} = packPanelFormSelector(getState())

  if (mode === PICK_MODE) {
    return [[], false]
  }

  // we can't just look at this one path for verified changes
  // we need to know EVERYTHING that changed because maybe a kit parent was fully verified
  const onlyUpdatedLines = getOnlyUpdatedVerified(linesWithCounts)
  let serialNumbersForKits = false

  return [
    onlyUpdatedLines
      .filter((line) => line.has_serial_numbers)
      .map((line) => {
        if (line.path !== path) {
          serialNumbersForKits = true
        }

        return {
          path: line.path,
          quantity: line.diff, // we got this value from getOnlyUpdatedVerified
          sku: line.sku,
          name: line.name,
        }
      }),
    serialNumbersForKits,
  ]
}

// get what we just verified because kits make this hard to know
function getOnlyUpdatedVerified(updatedLinesWithCounts) {
  // get the last state
  const {linesWithCounts} = packPanelFormSelector(getState())
  const onlyUpdatedLines = []

  function eachLine(ogLine, newLine) {
    // changes to verified
    const diff = newLine.verifiedQuantity - ogLine.verifiedQuantity

    // non-zero numbers are "differences"
    if (diff) {
      // remember the diff and the line it happened on
      onlyUpdatedLines.push({...newLine, diff})
    }

    // look down into the kits
    for (const [index, ogChild] of (ogLine.children || []).entries()) {
      const newChild = newLine.children[index]

      eachLine(ogChild, newChild)
    }
  }

  // look at each line
  for (const [index, ogLine] of linesWithCounts.entries()) {
    const newLine = updatedLinesWithCounts[index]

    eachLine(ogLine, newLine)
  }

  // only the updated lines (could be component lines)
  return onlyUpdatedLines
}

export function finishSerialNumberScan() {
  updatePackPanelForm({
    scanResult: PACK_PANEL_BARCODE_SCANNED,
    serialNumberNeed: [],
    serialNumbersForKits: false,
    serialNumberFocusIndex: 0,
  })
}

function handleOverScannedParts(
  scannedCode,
  overscannedParts,
  linesWithCounts,
  matchingLine,
) {
  // collect total quantity of overscanned skus
  // plus set verified quantity to ordered quantity
  const overscannedBySKU = {}
  overscannedParts.forEach((part) => {
    if (!part.hasChildren) {
      const overscanAmount = part.verifiedQuantity - part.orderedQuantity
      overscannedBySKU[part.sku] = overscannedBySKU[part.sku]
        ? overscannedBySKU[part.sku] + overscanAmount
        : overscanAmount
    }
    set(linesWithCounts, `${part.path}.verifiedQuantity`, part.orderedQuantity)
  })

  // collect only leaf nodes with each quantities multiplied by increment quantity
  // then collect increment quantities by sku
  const leafUpdatesBySKU = getLeafUpdates(matchingLine, 1).reduce(
    (acc, {sku, incrementQuantity}) => {
      acc[sku] = acc[sku] ? acc[sku] + incrementQuantity : incrementQuantity
      return acc
    },
    {},
  )

  // collect flatten tree of lines with only unverified lines
  const stillUnverifiedLines = getFlattenedLines(linesWithCounts).filter(
    (line) => line.verifiedQuantity < line.orderedQuantity,
  )

  // run through the overscanned lines by sku and look for places to put unaccounted quantities
  // so if we overscan one line of "sku A" but that overscan can be used in another line of
  // the same sku then put the overflow there
  // then collect overscanned components that still have no place to put overflow
  const overscannedComponents = {}
  for (const sku in overscannedBySKU) {
    let unaccounted = overscannedBySKU[sku]
    for (const unverifiedLine of stillUnverifiedLines) {
      // skip if not this sku or there are no more unaccounted quantities
      if (unverifiedLine.sku !== sku || unaccounted <= 0) {
        continue
      }

      const available =
        unverifiedLine.orderedQuantity - unverifiedLine.verifiedQuantity
      // increment won't be more than available
      const increment = Math.min(unaccounted, available)
      const line = get(linesWithCounts, unverifiedLine.path)

      // verify as much as we can
      line.verifiedQuantity += increment

      // recalculate quantities starting from top parent
      recalculateLine(linesWithCounts, line.path)

      unaccounted -= increment
    }

    // there are still unaccounted quantities
    if (unaccounted > 0) {
      overscannedComponents[sku] = {
        // how many the user tried to scan
        scanned: leafUpdatesBySKU[sku],
        // how many we actually have to scan
        remaining: leafUpdatesBySKU[sku] - unaccounted,
      }
    }
  }

  // There are real overscanned components so we need to inform user
  if (Object.keys(overscannedComponents).length > 0) {
    updatePackPanelForm({
      scannedCode,
      scanResult: PRODUCT_OVERSCANNED,
      overscannedComponents,
    })

    // the updates to linesWithCounts will not be saved
    return true
  }

  // the updates to linesWithCounts will be saved
  return false
}
