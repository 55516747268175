import PropTypes from 'prop-types'
import {useEffect, useCallback} from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce.js'

import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import {
  getMissingRates,
  hasLabelConfigLoadedSelector,
  labelShipperTypeSelector,
  isInternationalLabelSelector,
  labelUseRateShoppingSelector,
} from '../../../data/labelInfos/index.js'
import {
  getRates,
  saveConfigAndGetRatesAll,
} from '../../../data/labelInfos/rateRequest.js'
import {
  usePresetsSelector,
  showFeatureLocksSelector,
} from '../../../data/company.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import {showEditPresetModal} from '../Modals/EditPresetModal/index.js'

import Summary from './Summary.js'
import ShipperSelect from '../Fields/ShipperSelect/index.js'
import PackageType from '../Fields/PackageType.js'

import PresetsDropdown from './PresetsDropdown.js'
import {useSelector} from '../../../store.js'
import LabelConfigForm from '../LabelConfigForm.js'
import OldLabelConfigForm from '../OldLabelConfigForm.js'
import ShippersFilter from '../Fields/ShippersFilter.js'
import PackageTypesFilter from '../Fields/PackageTypesFilter.js'
import {presetCountSelector} from '../../../data/presets.js'
import LabelConfigContext from '../LabelConfigContext.js'

export default function SingleLabelConfigForm({
  labelInfoID,
  showCreateButton,
  showErrors,
  showEmptyPresets = true,
  showUnSetupPackingList,
  showBillTo,
  showInsuranceFull,
}) {
  const hasLabelConfigLoaded = useSelector((state) =>
    hasLabelConfigLoadedSelector(state, {
      labelInfoID,
    }),
  )
  const shipperType = useSelector((state) =>
    hasLabelConfigLoaded ? labelShipperTypeSelector(state, {labelInfoID}) : '',
  )
  const usePresets = useSelector(usePresetsSelector)
  const presetCount = useSelector(presetCountSelector)
  const isInternationalLabel = useSelector((state) =>
    hasLabelConfigLoaded
      ? isInternationalLabelSelector(state, {labelInfoID, shipperType})
      : false,
  )
  const showFeatureLocks = useSelector(showFeatureLocksSelector)
  const labelUseRateShopping = useSelector((state) =>
    labelUseRateShoppingSelector(state, {labelInfoID}),
  )

  const onChangeOld = useCallback(
    debounce((options) => getRates([labelInfoID], options), 1000, {
      leading: true,
    }),
    [labelInfoID],
  )

  const onChange = useCallback(
    debounce(
      (options) => saveConfigAndGetRatesAll([labelInfoID], options),
      1000,
      {
        leading: true,
      },
    ),
    [labelInfoID],
  )

  useEffect(() => {
    getMissingRates([labelInfoID])
  }, [labelInfoID])

  if (!hasLabelConfigLoaded) {
    return null
  }

  return (
    <LabelConfigContext.Provider
      value={{
        labelInfoID,
        onChange: labelUseRateShopping ? onChange : onChangeOld,
      }}
    >
      <form
        className={classNames(
          'form',
          'form--shipping-options',
          'meta-labelconfigform-form-single',
          `meta-labelconfigform-shipper-${shipperType}`,
          `meta-labelconfigform-international-${isInternationalLabel}`,
        )}
      >
        <fieldset className="fieldset--shipping-options fieldset--scroll-wrap">
          {!showEmptyPresets && presetCount === 0 ? null : usePresets ? (
            <div className="outer-wrap--dropdown-presets">
              <PresetsDropdown labelInfoID={labelInfoID} />
              <div>
                <ButtonPrimary
                  isOutlined
                  size="x-sm"
                  className="lh-sm"
                  title="Create a Shipping Preset"
                  onClick={() => showEditPresetModal({labelInfoID})}
                >
                  +
                </ButtonPrimary>
              </div>
            </div>
          ) : showFeatureLocks ? (
            <div className="align-right margin-right-15 margin-bottom-10">
              <ButtonLink
                className="lh-inherit align-right op-50 no-underline"
                title="Upgrade your account to access Presets"
                onClick={() => showUpgradeModal()}
              >
                <span
                  className="i-lock-closed fs-01 lh-sm no-underline align-center op-75 margin-right-1 v-align-middle"
                  aria-hidden="true"
                />
                <span className="lh-sm fs-00">Presets</span>
              </ButtonLink>
            </div>
          ) : null}
          <div
            className={classNames('inner-wrap__panel--actions', {
              'inner-wrap__panel--actions-rs': labelUseRateShopping,
            })}
          >
            {labelUseRateShopping ? (
              <>
                <ul className="list list--shipping-options flex--justify flex-wrap-margin-10">
                  <ShippersFilter />
                  <PackageTypesFilter />
                </ul>
                <LabelConfigForm
                  showUnSetupPackingList={showUnSetupPackingList}
                  showBillTo={showBillTo}
                />
              </>
            ) : (
              <LabelConfigContext.Provider
                value={{
                  labelInfoID,
                  onChange: onChangeOld,
                  shipperType,
                }}
              >
                <ul className="list list--shipping-options flex--justify flex-wrap-margin-10">
                  <ShipperSelect />
                  <PackageType />
                </ul>
                <OldLabelConfigForm
                  showUnSetupPackingList={showUnSetupPackingList}
                  showBillTo={showBillTo}
                />
              </LabelConfigContext.Provider>
            )}
          </div>
        </fieldset>
        <Summary
          showCreateButton={showCreateButton}
          showErrors={showErrors}
          showInsuranceFull={showInsuranceFull}
        />
      </form>
    </LabelConfigContext.Provider>
  )
}

SingleLabelConfigForm.propTypes = {
  labelInfoID: LabelInfoIDShape.isRequired,
  showCreateButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  showEmptyPresets: PropTypes.bool,
  showUnSetupPackingList: PropTypes.bool,
  showBillTo: PropTypes.bool,
  showInsuranceFull: PropTypes.bool,
}
