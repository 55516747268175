import PropTypes from 'prop-types'
import classNames from 'classnames'

import {AddressShape} from '../../../../../common/PropTypes.js'
import Address from '../../../../../common/components/Order/Address.js'
import AddressValidationIcon from '../../../../../common/components/Order/AddressValidationIcon.js'

export default function OrderListAddress({addressType, ...props}) {
  return (
    <div className={classNames('flex', `meta-address-type-${addressType}`)}>
      <AddressValidationIcon address={props.address} />
      <div className="flex-grow">
        <Address {...props} />
      </div>
    </div>
  )
}

OrderListAddress.propTypes = {
  addressType: PropTypes.string.isRequired,
  address: AddressShape.isRequired,
}
