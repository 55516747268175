import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import isEqual from 'lodash/isEqual.js'

import {ReturnOrderListFormShape} from '../../common/PropTypes.js'
import Loading from '../../common/components/List/Loading.js'
import {refreshReturnOrderList} from './returnOrderListActions.js'
import {
  returnOrderListFormSelector,
  isLoadingSelector,
} from './returnOrderListSelectors.js'
import ReturnOrderListFooter from './ReturnOrderListFooter.js'
import ActionPanel from './ActionPanel/index.js'
import Table from './Table.js'

class List extends Component {
  static propTypes = {
    form: ReturnOrderListFormShape.isRequired,
    isLoading: PropTypes.bool.isRequired,
    refreshReturnOrderList: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.refreshReturnOrderList()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.form.searchText !== this.props.form.searchText ||
      prevProps.form.perPage !== this.props.form.perPage ||
      prevProps.form.currentPage !== this.props.form.currentPage ||
      prevProps.form.status !== this.props.form.status ||
      prevProps.form.sort !== this.props.form.sort ||
      isEqual(prevProps.form.tags, this.props.form.tags) === false ||
      prevProps.form.untagged !== this.props.form.untagged ||
      prevProps.form.tagFilterBy !== this.props.form.tagFilterBy
    ) {
      this.props.refreshReturnOrderList()
    }
  }

  render() {
    const {isLoading} = this.props

    return (
      <>
        {isLoading ? (
          <div className="medium-12 columns list--empty-content margin-top-40 margin-bottom-40">
            <Loading />
          </div>
        ) : (
          <>
            <div className="medium-8 columns">
              <Table />
              <ReturnOrderListFooter />
            </div>
            <ActionPanel />
          </>
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    form: returnOrderListFormSelector(state),
    isLoading: isLoadingSelector(state),
  }
}

const mapDispatchToProps = {
  refreshReturnOrderList,
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
