import {createSelector} from 'reselect'

export const editInstructionsModalSelector = createSelector(
  (state) => state.ui.modals.editInstructionsModal,
  (editInstructionsModal) => editInstructionsModal,
)

export const poIDSelector = createSelector(
  editInstructionsModalSelector,
  ({poID}) => poID,
)

export const instructionsSelector = createSelector(
  editInstructionsModalSelector,
  ({instructions}) => instructions,
)

export const isSavingSelector = createSelector(
  editInstructionsModalSelector,
  ({isSaving}) => isSaving,
)

export const serverErrorSelector = createSelector(
  editInstructionsModalSelector,
  ({serverError}) => serverError,
)
