import {useEffect} from 'react'
import {updateForm, useAutoForm, useSelector} from '../../store.js'
import {userSettingSelector} from '../../data/me.js'
import {whiteListThemeSelector} from '../../data/company.js'

const THEME = 'THEME'
const prefersColorSchemeDarkMatchMedia = window.matchMedia(
  '(prefers-color-scheme: dark)',
)

function updateThemeForm(updates) {
  updateForm(THEME, updates)
}

export default function Theme() {
  const form = useAutoForm(THEME, {
    prefersColorScheme: prefersColorSchemeDarkMatchMedia.matches
      ? 'dark'
      : 'light',
  })
  const dark_mode = useSelector((state) =>
    userSettingSelector(state, {
      setting: 'dark_mode',
      defaultValue: null,
    }),
  )
  const whiteListTheme = useSelector(whiteListThemeSelector)

  useEffect(() => {
    if (
      dark_mode === false ||
      (dark_mode === null && form.prefersColorScheme === 'light')
    ) {
      // remove
      document.querySelector('html').classList.remove('dark-mode')
      localStorage.setItem('theme', 'light')
    } else {
      // add
      document.querySelector('html').classList.add('dark-mode')
      localStorage.setItem('theme', 'dark')
    }
  }, [form.prefersColorScheme, dark_mode])

  useEffect(() => {
    function onChange(event) {
      updateThemeForm({prefersColorScheme: event.matches ? 'dark' : 'light'})
    }

    prefersColorSchemeDarkMatchMedia.addEventListener('change', onChange)

    return () =>
      prefersColorSchemeDarkMatchMedia.removeEventListener('change', onChange)
  }, [])

  useEffect(() => {
    for (const cn of document.body.classList.values()) {
      if (cn.match(/^white-label--/)) {
        document.body.classList.remove(cn)
      }
    }

    if (whiteListTheme) {
      document.body.classList.add(`white-label--${whiteListTheme}`)
    }
  }, [whiteListTheme])

  return null
}
