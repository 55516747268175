import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_SERVER_INFO} from '../../actions/data/serverInfo.js'

const SERVER_INFO = INITIAL_STATE.data.serverInfo

export default function reducer(state = SERVER_INFO, action = {}) {
  if (action.type === SET_SERVER_INFO) {
    const serverInfo = action.payload

    if (state.version && state.version !== serverInfo.version) {
      return {
        ...state,
        version: serverInfo.version,
        needsAppReload: true,
      }
    }

    return {
      ...state,
      version: serverInfo.version,
    }
  }

  return state
}
