import PropTypes from 'prop-types'
import classnames from 'classnames'
import {showEditAdditionalOptionsModal} from '../Modals/EditAdditionalOptionsModal.js'
import {SHIPPER_NAMES} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function EditAdditionalOptions({
  shipperType,
  singleShipperType,
  isLabelEnableMode,
}) {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <li
      className={classnames(
        'list__item fs-n0 lh-md margin-top-5 margin-bottom-0',
        {
          'list__item--square': !singleShipperType,
        },
      )}
    >
      {!singleShipperType && (
        <strong className="v-align-base margin-right-5">
          {SHIPPER_NAMES[shipperType]}
        </strong>
      )}
      <button
        className={classnames('btn', {
          'btn--link inline-block fs-n0 lh-md v-align-base': !singleShipperType,
          'btn--primary btn--primary-ol btn--x-sm': singleShipperType,
        })}
        type="button"
        id="edit_additional_options"
        onClick={() =>
          showEditAdditionalOptionsModal(
            labelInfoID,
            shipperType,
            onChange,
            isLabelEnableMode,
          )
        }
      >
        View/Edit
      </button>
    </li>
  )
}

EditAdditionalOptions.propTypes = {
  shipperType: PropTypes.string.isRequired,
  singleShipperType: PropTypes.bool.isRequired,
  isLabelEnableMode: PropTypes.bool,
}
