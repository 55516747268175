import {useSelector, onlyIfForm} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import TextInput from '../../../../common/components/TextInput.js'
import TextArea from '../../../../common/components/TextArea.js'
import Select from '../../../../common/components/Select.js'
import ButtonLink from '../../../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {navigate} from '../../../../common/location.js'
import {
  warehouseAddressSelector,
  nonFBAWarehouseOptionsSelector,
} from '../../../../data/warehouses.js'
import {
  supplierAddressSelector,
  activeNonFBASupplierOptionsSelector,
} from '../../../../data/suppliers.js'
import {
  CreateReturnOrderModalFormShape,
  OTHER_ADDRESS,
  updateCreateReturnOrderModalForm,
  setCreateReturnOrderModalFormValue,
  removeCreateReturnOrderModalForm,
  checkReferenceID,
  createReturnOrder,
  addProductToCreateReturnOrder,
  createReturnOrderModalFormSelector,
  createReturnOrderModalErrorsSelector,
} from './createReturnOrderFunctions.js'
import CustomerAddressForm from './CustomerAddressForm.js'
import OtherAddressForm from './OtherAddressForm.js'
import Line from './Line.js'
import {COMMUNICATION_DROPSHIPMENT} from '../../../../common/constants/Suppliers.js'
import {PRIVACY_MASK} from '../../../../common/analytics/index.js'

function CreateReturnOrderModal({form}) {
  const warehouseOptions = useSelector(nonFBAWarehouseOptionsSelector)
  const supplierOptions = useSelector(activeNonFBASupplierOptionsSelector)
  const errors = useSelector(createReturnOrderModalErrorsSelector)
  const returnToAddress = useSelector((state) =>
    form.returnToType === OTHER_ADDRESS
      ? null
      : form.returnToType === 'supplier_id'
        ? supplierAddressSelector(state, {
            supplierID: form.supplierID,
            type: COMMUNICATION_DROPSHIPMENT,
          })
        : warehouseAddressSelector(state, {warehouseID: form.warehouseID}),
  )
  const returnToOptions = [
    {value: OTHER_ADDRESS, display: 'Other address'},
    ...(warehouseOptions.length > 0
      ? [{disabled: true, display: '--- Warehouses ---'}]
      : []),
    ...warehouseOptions.map(({value, display}) => ({
      value: `warehouse_id__${value}`,
      display,
    })),
    ...(supplierOptions.length > 0
      ? [{disabled: true, display: '--- Suppliers ---'}]
      : []),
    ...supplierOptions.map(({value, display}) => ({
      value: `supplier_id__${value}`,
      display,
    })),
  ]
  const selectedCount = form.lines.reduce(
    (prev, {isSelected}) => prev + (isSelected ? 1 : 0),
    0,
  )
  const allSelected = form.lines.length === selectedCount && selectedCount !== 0
  const someSelected =
    form.lines.length !== selectedCount && selectedCount !== 0

  return (
    <ConfirmModal
      title="Initiate a Return (RMA)"
      modalSize="md"
      confirmText="Create RMA"
      onConfirm={() => createReturnOrder()}
      MiddleButtons={() => (
        <ButtonPrimary
          alt
          className="margin-right-10"
          isDisabled={errors.preventSave || form.isSaving}
          isLoading={form.isSaving}
          onClick={() => createReturnOrder({goToRMA: true})}
        >
          Create and View RMA
        </ButtonPrimary>
      )}
      cancelText="Cancel"
      onCancel={() => removeCreateReturnOrderModalForm()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div>
        <div className="row">
          <div className="medium-7 columns">
            <ul className="list list--no-style margin-bottom-30">
              <li className="list__item">
                <label className="fs-01" htmlFor="referenceID">
                  Return Merchandise Authorization (RMA) ID*
                </label>
                <div className="flex">
                  <div className="wrap--input-prefix flex--justify-col">
                    <strong className="fs-n1 text--md-grey">RMA-</strong>
                  </div>
                  <TextInput
                    id="referenceID"
                    className="margin-bottom-0 input--med-h input--w-prefix"
                    value={form.referenceID}
                    onChange={(value) =>
                      updateCreateReturnOrderModalForm({
                        referenceID: value,
                      })
                    }
                    onBlur={() => checkReferenceID()}
                  />
                </div>
                {errors.referenceID && (
                  <small className="error">{errors.referenceID}</small>
                )}
              </li>
            </ul>
          </div>
        </div>

        <ul className="list list--no-style margin-bottom-30">
          <li className="list__item fs-01 margin-bottom-0">
            <strong>Return From</strong>
          </li>
          <li className="list__item fs-01 row">
            <ul className="list list--no-style medium-7 columns">
              {form.editCustomerAddress ? (
                <CustomerAddressForm />
              ) : (
                <>
                  <li
                    className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                  >
                    {form.customerAddress.name}
                  </li>
                  {form.customerAddress.company && (
                    <li
                      className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                    >
                      {form.customerAddress.company}
                    </li>
                  )}
                  <li
                    className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                  >
                    {form.customerAddress.street1}
                  </li>
                  {form.customerAddress.street2 && (
                    <li
                      className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                    >
                      {form.customerAddress.street2}
                    </li>
                  )}
                  <li
                    className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                  >
                    {form.customerAddress.city}, {form.customerAddress.state}{' '}
                    {form.customerAddress.zip}
                  </li>
                  <li
                    className={`list__item fs-n0 margin-bottom-0 ${PRIVACY_MASK}`}
                  >
                    {form.customerAddress.country}
                  </li>
                </>
              )}
              <li className="list__item fs-n1 margin-bottom-0">
                {form.editCustomerAddress ? (
                  <ButtonPrimary
                    size="sm"
                    className="margin-top-3"
                    alt
                    onClick={() =>
                      updateCreateReturnOrderModalForm({
                        editCustomerAddress: !form.editCustomerAddress,
                      })
                    }
                  >
                    Save
                  </ButtonPrimary>
                ) : (
                  <ButtonLink
                    onClick={() =>
                      updateCreateReturnOrderModalForm({
                        editCustomerAddress: !form.editCustomerAddress,
                      })
                    }
                  >
                    Edit
                  </ButtonLink>
                )}
              </li>
              <li className="list__item list__item--form margin-top-15 divider--top">
                <TextArea
                  id="customerNotes"
                  label="Notes From Customer"
                  value={form.customerNotes}
                  onChange={(value) =>
                    updateCreateReturnOrderModalForm({
                      customerNotes: value,
                    })
                  }
                />
              </li>
            </ul>
          </li>
        </ul>
        <ul className="list list--no-style margin-top-10">
          <li className="list__item fs-01 margin-bottom-5">
            <strong>Return To</strong>
          </li>
          {returnToOptions.length > 0 && (
            <li className="list__item list__item--form margin-bottom-5">
              <Select
                value={
                  form.returnToType === OTHER_ADDRESS
                    ? OTHER_ADDRESS
                    : `${form.returnToType}__${
                        form.returnToType === 'supplier_id'
                          ? form.supplierID
                          : form.warehouseID
                      }`
                }
                onChange={(value) => {
                  if (value === OTHER_ADDRESS) {
                    updateCreateReturnOrderModalForm({
                      returnToType: OTHER_ADDRESS,
                    })

                    return
                  }

                  const [, type, id] =
                    value.match(/^(supplier_id|warehouse_id)__(\d+)$/) || []

                  if (type === 'supplier_id' && id) {
                    updateCreateReturnOrderModalForm({
                      returnToType: type,
                      supplierID: Number(id),
                    })
                  } else if (type === 'warehouse_id' && id) {
                    updateCreateReturnOrderModalForm({
                      returnToType: type,
                      warehouseID: Number(id),
                    })
                  }
                }}
              >
                {returnToOptions.map(({value, display, disabled}, index) => (
                  <option
                    key={disabled ? `disabled_${index}` : value}
                    value={value}
                    disabled={disabled}
                  >
                    {display}
                  </option>
                ))}
              </Select>
            </li>
          )}
          {returnToAddress &&
          returnToAddress.city &&
          returnToAddress.street1 ? (
            <li className="list__item list__item--form">
              <ul className="list list--no-style">
                <li className="list__item fs-n0 margin-bottom-0">
                  {returnToAddress.street1}
                </li>
                {returnToAddress.street2 && (
                  <li className="list__item fs-n0 margin-bottom-0">
                    {returnToAddress.street2}
                  </li>
                )}
                <li className="list__item fs-n0 margin-bottom-0">
                  {`${returnToAddress.city}${
                    returnToAddress.state || returnToAddress.zip ? ',' : ''
                  } ${returnToAddress.state} ${returnToAddress.zip}`.trim()}
                </li>
                {returnToAddress.country && (
                  <li className="list__item fs-n0 margin-bottom-0">
                    {returnToAddress.country}
                  </li>
                )}
              </ul>
            </li>
          ) : form.returnToType === 'supplier_id' ? (
            <li className="list__item list__item--form margin-top-10 fs-00">
              <em>No street address has been entered for this supplier.</em>
              <div className="margin-bottom-5">
                <ButtonLink
                  className="hyperlink"
                  onClick={() => {
                    removeCreateReturnOrderModalForm()

                    navigate(['settings', 'supplier', form.supplierID])
                  }}
                >
                  Add an address
                </ButtonLink>{' '}
                <span className="op-50">→</span>
              </div>
            </li>
          ) : null}
        </ul>
        {form.returnToType === OTHER_ADDRESS && <OtherAddressForm />}
        <div className="row margin-bottom-30">
          <div className="medium-7 columns">
            <div className="list__item list__item--form margin-top-10 divider--top">
              <TextArea
                id="internalNotes"
                label="Internal Notes for Processing Return"
                value={form.internalNotes}
                onChange={(value) =>
                  updateCreateReturnOrderModalForm({
                    internalNotes: value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <p className="fs-01 margin-bottom-5">
            <strong>Items to be Returned</strong>
          </p>
          <table className="table">
            <thead>
              <tr>
                <th className="table__th table__th--sm w-2">
                  <Checkbox
                    className="margin-bottom-0"
                    checked={allSelected}
                    indeterminate={someSelected}
                    onChange={() => {
                      form.lines.forEach((line, index) => {
                        if (
                          allSelected ||
                          ((someSelected || (!allSelected && !someSelected)) &&
                            !line.isSelected)
                        ) {
                          setCreateReturnOrderModalFormValue(
                            `lines.${index}.isSelected`,
                            allSelected ? false : true,
                          )
                        }
                      })
                    }}
                  />
                </th>
                <th className="table__th table__th--sm w-20">
                  Product Name/SKU
                </th>
                <th className="table__th table__th--sm w-10">Price</th>
                <th className="table__th table__th--sm w-15">Qty in Order</th>
                <th className="table__th table__th--sm w-15">Return Qty</th>
                <th className="table__th table__th--sm w-15">Return Code</th>
                <th className="table__th table__th--sm w-20">
                  Serial Number(s)
                </th>
              </tr>
            </thead>
            <tbody className="table__tbody--lines">
              {form.lines.map((line, index) => (
                <Line key={index} index={index} sku={line.sku} />
              ))}
              <tr>
                <td
                  className="table__td table__td--product-search padding-left-0 align-center"
                  colSpan="7"
                >
                  <ButtonPrimary
                    isOutlined
                    size="sm"
                    onClick={() => addProductToCreateReturnOrder()}
                  >
                    Add a Product
                  </ButtonPrimary>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ConfirmModal>
  )
}

CreateReturnOrderModal.propTypes = {
  form: CreateReturnOrderModalFormShape,
}

export default onlyIfForm(
  CreateReturnOrderModal,
  createReturnOrderModalFormSelector,
)
