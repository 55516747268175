import PropTypes from 'prop-types'
import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import get from 'lodash/get.js'
import {createSelector} from 'reselect'

import {
  IN_HOUSE_FULFILLMENT,
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
} from '../../../common/constants/Products.js'
import {
  isPresent,
  isNumeric,
  isNonZeroPositiveNumeric,
} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {
  addProducts,
  saveProductSupplier,
  setDefaultSupplier,
  removeDefaultSupplier,
  saveProductFulfillment,
  nameSelector,
  productSuppliersSelector,
  productDefaultSupplierSelector,
  fulfillmentTypeSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshProductList} from '../productListFunctions.js'
import {showCreateSupplierModal} from './CreateSupplierModal.js'
import {supplierOptionsSelector} from '../../../data/suppliers.js'
import {
  autoDropshipOnOrderCreationSelector,
  useDropshippingSelector,
  usePurchaseOrdersSelector,
} from '../../../data/company.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  formsSelector,
  getState,
} from '../../../store.js'
import Radio from '../../../common/components/Radio.js'
import Checkbox from '../../../common/components/Checkbox.js'

export const MODAL_FORM = 'PRODUCT_SUPPLIER_MODAL'

export function showProductSupplierModal(skus) {
  setForm(MODAL_FORM, {
    skus,
    fulfillmentType: IN_HOUSE_FULFILLMENT,
    defaultSupplier: '',
    cost: '',
    minOrderQuantity: '',
    isDropshippedAutomatic: false,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({skus, fulfillmentType, defaultSupplier, minOrderQuantity, cost}) => {
    const errors = {}
    const isDropshipped = [
      DROPSHIPPABLE_FULFILLMENT,
      AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
    ].includes(fulfillmentType)

    if (skus.length === 1 && defaultSupplier) {
      if (!isNumeric(cost)) {
        errors.cost = 'Supplier Unit Cost must be a number'
      }

      if (!isPresent(cost)) {
        errors.cost = 'Supplier Unit Cost is required'
      }

      if (!isDropshipped) {
        if (!isNonZeroPositiveNumeric(minOrderQuantity)) {
          errors.minOrderQuantity = 'Quantity must be a positive number'
        }

        if (!isPresent(minOrderQuantity)) {
          errors.minOrderQuantity = 'Quantity is required'
        }
      }
    }

    if (isDropshipped && !isPresent(defaultSupplier)) {
      errors.defaultSupplier = 'Supplier is required for dropshipping'
    }

    return errors
  },
)

export async function doSaveProductSupplier() {
  try {
    const {skus, fulfillmentType, defaultSupplier, cost, minOrderQuantity} =
      modalFormSelector(getState())
    const supplierID = Number(defaultSupplier) || null
    const isDropshipped = [
      DROPSHIPPABLE_FULFILLMENT,
      AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
    ].includes(fulfillmentType)

    updateModalForm({isSaving: true, serverError: null})

    const params = {
      fulfillment_type: fulfillmentType,
    }

    if (supplierID && isDropshipped) {
      params.default_supplier_id = supplierID
    }

    let products = await Promise.all(
      skus.map((sku) => saveProductFulfillment(sku, params)),
    )

    if (supplierID && !isDropshipped) {
      products = await Promise.all(
        skus.map((sku) => setDefaultSupplier(supplierID, sku)),
      )
    }

    if (!supplierID) {
      products = await Promise.all(
        skus.map((sku) => removeDefaultSupplier(sku)),
      )
    }

    if (supplierID && skus.length === 1) {
      const supplierParams = {
        supplier_price: cost,
        min_order_qty: minOrderQuantity,
      }

      products = await Promise.all(
        skus.map((sku) => saveProductSupplier(supplierID, sku, supplierParams)),
      )
    }

    addProducts(products)

    closeModal()

    showMessageToast('Saved supply method')

    await refreshProductList()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
    })
  } finally {
    updateModalForm({isSaving: false})
  }
}

function ProductSupplierModal({form}) {
  const firstSKU = form.skus[0]

  const useDropshipping = useSelector(useDropshippingSelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const defaultSupplier = useSelector((state) =>
    productDefaultSupplierSelector(state, {
      sku: firstSKU,
    }),
  )
  const errors = useSelector(errorsSelector)
  const suppliers = useSelector(supplierOptionsSelector)
  const firstProductName = useSelector((state) =>
    nameSelector(state, {sku: firstSKU}),
  )
  const firstProductSuppliers = useSelector((state) =>
    productSuppliersSelector(state, {sku: firstSKU}),
  )
  const autoDropshipOnOrderCreation = useSelector(
    autoDropshipOnOrderCreationSelector,
  )

  const firstProductDefaultSupplierID = defaultSupplier
    ? defaultSupplier.id
    : null

  const isDropshipped = [
    DROPSHIPPABLE_FULFILLMENT,
    AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
  ].includes(form.fulfillmentType)

  const onDefaultSupplierChanged = useCallback(() => {
    const defaultSupplier = Number(form.defaultSupplier) || null

    if (defaultSupplier) {
      const supplier = firstProductSuppliers.find(
        ({id}) => defaultSupplier === id,
      )

      updateModalForm({
        cost: supplier ? supplier.supplier_price : 0,
        minOrderQuantity: supplier ? supplier.min_order_qty : 1,
      })
    }
  }, [form.defaultSupplier, firstProductSuppliers])

  const onIsDropshippedChanged = useCallback(() => {
    if (form.defaultSupplier) {
      // existing value will work
    } else if (firstProductDefaultSupplierID) {
      // copy default supplier from first product
      updateModalForm({
        defaultSupplier: `${firstProductDefaultSupplierID}`,
      })
    } else if (isDropshipped) {
      // dropshipped products have to have a supplier
      updateModalForm({
        defaultSupplier: `${get(suppliers, '0.value', '')}`,
      })
    } else {
      // inhouse products can default to unset supplier
      updateModalForm({
        defaultSupplier: '',
      })
    }
  }, [form.defaultSupplier, firstProductDefaultSupplierID, isDropshipped])

  useEffect(() => {
    if (suppliers.length === 0) {
      showCreateSupplierModal({isInitial: true})
    }
  }, [suppliers])

  useEffect(() => {
    const firstProductFulfillmentType = fulfillmentTypeSelector(getState(), {
      sku: firstSKU,
    })

    updateModalForm({
      fulfillmentType: firstProductFulfillmentType,
    })

    onIsDropshippedChanged()
  }, [])

  useEffect(() => {
    onDefaultSupplierChanged()
  }, [form.defaultSupplier])

  useEffect(() => {
    onIsDropshippedChanged()
  }, [isDropshipped])

  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Set Fulfillment and Supplier/Dropshipper"
      modalSize=""
      onConfirm={() => doSaveProductSupplier()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <ul className="list list--no-style margin-bottom-20">
        {form.skus.length === 1 && (
          <li className="list__item--form list__item--no-style margin-bottom-0">
            <div className="fs-01">
              <strong>{firstProductName}</strong>
            </div>
            <div className="fs-00">{form.skus[0]}</div>
          </li>
        )}
        <li className="list__item fs-01">
          {form.skus.length === 1 ? (
            <span className="inline-block divider--top">
              How is the product fulfilled?
            </span>
          ) : (
            <span>
              How are these <strong>{form.skus.length} products</strong>{' '}
              fulfilled?
            </span>
          )}
        </li>
        {useDropshipping && (
          <li className="list__item flex margin-bottom-15">
            <div className="margin-right-15">
              <label className="margin-bottom-0">
                <Radio
                  className="margin-right-5 margin-bottom-0"
                  checked={form.fulfillmentType === IN_HOUSE_FULFILLMENT}
                  onChange={() =>
                    updateModalForm({
                      fulfillmentType: IN_HOUSE_FULFILLMENT,
                    })
                  }
                  autoFocus
                />
                <span>In-House</span>
              </label>
            </div>
            <div className="flex">
              <label className="margin-bottom-0">
                <Radio
                  className="margin-right-5 margin-bottom-0"
                  checked={[
                    DROPSHIPPABLE_FULFILLMENT,
                    AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
                  ].includes(form.fulfillmentType)}
                  onChange={() =>
                    updateModalForm({
                      fulfillmentType: DROPSHIPPABLE_FULFILLMENT,
                    })
                  }
                />
                <span>Dropshipped</span>
              </label>
              {autoDropshipOnOrderCreation && isDropshipped && (
                <span className="flex divider--left">
                  <Checkbox
                    label="Automatic"
                    id="automatic_dropship"
                    labelClassName="unbold-label inline-block margin-right-5 margin-bottom-0"
                    checked={
                      form.fulfillmentType ===
                      AUTOMATICALLY_DROPSHIPPED_FULFILLMENT
                    }
                    onChange={() =>
                      updateModalForm({
                        fulfillmentType:
                          form.fulfillmentType === DROPSHIPPABLE_FULFILLMENT
                            ? AUTOMATICALLY_DROPSHIPPED_FULFILLMENT
                            : DROPSHIPPABLE_FULFILLMENT,
                      })
                    }
                  />
                </span>
              )}
            </div>
          </li>
        )}
        {autoDropshipOnOrderCreation &&
          form.fulfillmentType === AUTOMATICALLY_DROPSHIPPED_FULFILLMENT && (
            <li className="list__item alert alert--warning margin-bottom-15">
              <div className="flex margin-bottom-5">
                <div
                  className="i-exclamation-triangle fs-01 lh-sm op-30 margin-right-5"
                  aria-hidden="true"
                />
                <p className="fs-00 lh-sm margin-bottom-0">
                  <strong>
                    This product has been set to dropship automatically.
                  </strong>
                </p>
              </div>
              <p className="fs-n1 lh-md margin-bottom-0">
                The assigned dropshipper will be emailed{' '}
                <strong>INSTANTLY</strong> if the product is detected on a new
                order during import.
              </p>
            </li>
          )}
        <li className="list__item list__item--form">
          {suppliers.length === 1 && isDropshipped && (
            <p className="fs-00 margin-bottom-0">
              <strong className="margin-right-3">
                {isDropshipped ? 'Dropshipper:' : 'Supplier:'}
              </strong>
              <span>{suppliers[0].display}</span>
            </p>
          )}

          {(suppliers.length > 1 ||
            (suppliers.length === 1 && !isDropshipped)) && (
            <label className="margin-bottom-1" htmlFor="default-supplier">
              {isDropshipped ? 'Default Dropshipper' : 'Default Supplier'}
            </label>
          )}
          <div>
            {(suppliers.length > 1 ||
              (suppliers.length === 1 && !isDropshipped)) && (
              <select
                id="default-supplier"
                className="select inline-block v-align-middle margin-right-15"
                value={form.defaultSupplier}
                onChange={(event) =>
                  updateModalForm({
                    defaultSupplier: event.target.value,
                  })
                }
              >
                {!isDropshipped && <option value="">No Default</option>}
                {suppliers.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </select>
            )}

            {errors.defaultSupplier && (
              <div>
                <small className="error">{errors.defaultSupplier}</small>
              </div>
            )}
            <button
              className="btn btn--link fs-n0"
              type="button"
              onClick={() => showCreateSupplierModal()}
            >
              {isDropshipped ? 'Add a new dropshipper' : 'Add a new supplier'}
            </button>
          </div>
        </li>
      </ul>
      {usePurchaseOrders &&
        !!form.defaultSupplier &&
        form.skus.length === 1 && (
          <ul className="list list--no-style">
            <li className="list__item list__item--form margin-bottom-0">
              <label htmlFor="unit-cost">Supplier Unit Cost</label>
              <NumberInput
                id="unit-cost"
                value={form.cost}
                onChange={(value) => updateModalForm({cost: value})}
                onIncrement={(value) => updateModalForm({cost: value})}
                onDecrement={(value) => updateModalForm({cost: value})}
                isInvalid={!!errors.cost}
              />
              {errors.cost && <small className="error">{errors.cost}</small>}
            </li>
            {!isDropshipped && (
              <li className="list__item list__item--form margin-top-15">
                <label htmlFor="min-order-qty">Minimum order quantity</label>
                <NumberInput
                  id="min-order-qty"
                  value={form.minOrderQuantity}
                  onChange={(value) =>
                    updateModalForm({
                      minOrderQuantity: value,
                    })
                  }
                  onIncrement={(value) =>
                    updateModalForm({
                      minOrderQuantity: value,
                    })
                  }
                  onDecrement={(value) =>
                    updateModalForm({
                      minOrderQuantity: value,
                    })
                  }
                  isInvalid={!!errors.minOrderQuantity}
                />
                {errors.minOrderQuantity && (
                  <small className="error">{errors.minOrderQuantity}</small>
                )}
              </li>
            )}
          </ul>
        )}
    </ConfirmModal>
  )
}

ProductSupplierModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    fulfillmentType: PropTypes.string.isRequired,
    defaultSupplier: PropTypes.string,
    cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    minOrderQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ProductSupplierModal, modalFormSelector)
