import {getDate} from '../../common/date.js'

let lastNowToTheMinute = getDate()
export function nowToTheMinuteSelector() {
  const nowToTheMinute = getDate().startOf('minute')

  if (!nowToTheMinute.isSame(lastNowToTheMinute)) {
    lastNowToTheMinute = nowToTheMinute
  }

  return lastNowToTheMinute
}
