import {isPositiveNumeric} from '../../common/utils.js'
import Select from '../../common/components/Form/Select.js'
import NumberInput from '../../common/components/Form/NumberInput.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  mockAbodeUpdateResourceQuery,
  mockAbodeSendAsEmail,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'
import {MOCK_ABODE_RENDER_OPTIONS} from './MockAbodeConstants.js'

export default function MockAbodeRenderOptions() {
  const query = useSelector(querySelector)
  const mock = JSON.parse(query.mock || '{}')
  return (
    <>
      <Select
        label="Layout"
        name="mock_abode__layout_id"
        value={query.render}
        onChange={(value) => {
          const {mock_type, render} = MOCK_ABODE_RENDER_OPTIONS.find(
            (option) => option.value === value,
          )

          mockAbodeUpdateResourceQuery({
            mock: {...mock, type: mock_type},
            render,
          })
        }}
        className="margin-bottom-20"
      >
        {MOCK_ABODE_RENDER_OPTIONS.map(({value, display}) => (
          <option key={value} value={value}>
            {display}
          </option>
        ))}
      </Select>
      <div>
        <label htmlFor="mock_abode__seed">
          <span>Seed</span>
        </label>
        <NumberInput
          id="mock_abode__seed"
          value={mock.seed}
          min={0}
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({
              mock: JSON.stringify({
                ...mock,
                seed: isPositiveNumeric ? Number(value) : undefined,
              }),
            })
          }
        />
      </div>
      <ButtonPrimary
        className="margin-top-20 margin-bottom-20"
        onClick={() => mockAbodeSendAsEmail()}
      >
        Send as Email
      </ButtonPrimary>
    </>
  )
}
