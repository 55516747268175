import {useEffect} from 'react'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {getState, useSelector} from '../../store.js'
import {buildPath} from '../querystring.js'

import {showEditCartLocationWarehousesModal} from '../../ordoro/settings/Modals/EditCartLocationWarehousesModal.js'
import {showUPSRegistrationModal} from '../../ordoro/settings/Shippers/Forms/UPSForm/UPSRegistrationModal.js'
import {isLoadingSelector} from '../../redux/selectors/ui/index.js'

// This component allows functions to be called if they are present in the `_call` query string arg
// _call is a JSON encoded string in the shape of ['funcName', 'arg1', 'arg2', ...]
// EG: https://app.ordoro.com/#/dashboard?_call=%5B%22showEditCartLocationWarehousesModal%22%2C%7B%22cartID%22%3A27302%7D%5D
// which has a payload of: ['showEditCartLocationWarehousesModal', {cartID: 27302}]

// query call can happen long after the page loads
// because _call can be inserted into the query string at any time
// but realistically _call is only run on page load as directed by verde

// Put functions here if you want them callable from the query string
const REGISTERED_CALLABLE_MAP = {
  showEditCartLocationWarehousesModal,
  showUPSRegistrationModal,
}

function processQueryCall() {
  const {pathComponents, query} = locationSelector(getState())

  // this is normal, there is no _call param
  if (!query._call) {
    return
  }

  let call = query._call

  // we only want this to be called once so delete
  delete query._call

  // replace the url in the browser so that the _call param is gone,
  // so that when the user uses the back button the _call doesn't happen again
  history.replaceState(
    null,
    '',
    `${window.location.pathname}#${buildPath(pathComponents, query)}`,
  )

  // The call payload is a JSON string
  try {
    call = JSON.parse(call)
  } catch (err) {
    console.error('QueryCall: Unable to parse _call param', err)

    return
  }

  // The call payload is an array
  if (!Array.isArray(call)) {
    return
  }

  // The call payload is [funcName, 'arg1', 'arg2', ...]
  const [funcName, ...args] = call
  const func = REGISTERED_CALLABLE_MAP[funcName]

  // Only call functions that are registered
  if (!func) {
    return
  }

  // Call it with a timeout since react might not be ready (1 sec might be too long, we'll see)
  setTimeout(func, 1000, ...args)
}

export default function QueryCall() {
  const {query} = useSelector(locationSelector)
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    // wait for initial state to load
    if (!isLoading) {
      processQueryCall()
    }
  }, [query, isLoading])

  return null
}
