import {useEffect} from 'react'

import {onlyIfForm, useSelector} from '../../store.js'
import {BatchListFormShape} from './batchListActions.js'
import {batchListFormSelector} from './batchListSelectors.js'
import Modals from './Modals/index.js'
import BatchListItemsCount from './BatchListItemsCount.js'
import RefreshBatches from './RefreshBatches.js'
import BatchExpandAll from './BatchExpandAll.js'
import BatchSort from './BatchSort.js'
import Actions from './Actions.js'
import ActionsForSelected from './ActionsForSelected.js'
import List from './List.js'
import BatchListOmniBar from './BatchListOmniBar.js'
import {hasBatchPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {showBatchListFilterModal} from './Modals/BatchListFilterModal/batchListFilterModalFunctions.js'
import BatchFeaturePage from './BatchFeaturePage.js'
import {hasUseBatchesFeatureSelector} from '../../data/company.js'

function BatchListPage({form}) {
  useEffect(() => {
    document.title = 'Batch Orders'
  }, [])

  const useBatches = useSelector(hasUseBatchesFeatureSelector)
  const hasBatchPermission = useSelector(hasBatchPermissionSelector)

  if (!useBatches) {
    return <BatchFeaturePage />
  }

  if (!hasBatchPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div id="iverson-page">
      <Modals />
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <div className="wrap--list-header flex--justify">
              <ul className="list list--no-style">
                <BatchListItemsCount />
                <RefreshBatches />
                <BatchExpandAll />
                <li className="inline-block divider--left">
                  <button
                    className="btn btn--primary btn--xx-sm btn--primary-ol"
                    type="button"
                    onClick={() => showBatchListFilterModal()}
                  >
                    <span
                      className="i-filters fs-00 lh-0 v-align-middle margin-right-5"
                      aria-hidden="true"
                    />
                    <span className="fs-n1 v-align-middle">Filters</span>
                  </button>
                </li>
                <BatchSort />
                {form.selectedReferenceIDs.length ? (
                  <ActionsForSelected
                    referenceIDs={form.selectedReferenceIDs}
                  />
                ) : (
                  <Actions />
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <BatchListOmniBar />
          </div>
        </div>
        <div className="wrap--row margin-top-10">
          <List />
        </div>
      </div>
    </div>
  )
}

BatchListPage.propTypes = {
  form: BatchListFormShape.isRequired,
}

export default onlyIfForm(BatchListPage, batchListFormSelector)
