import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showEditPresetModal} from '../../LabelConfig/Modals/EditPresetModal/index.js'

export default function IdlePanel() {
  return (
    <div className="panel--actions panel--order-actions panel--unselected align-center">
      <p className="margin-bottom-3 align-center fs-00 op-75">
        <strong>Select an order in the list to take action on it</strong>
      </p>
      <div>
        <div className="margin-bottom-7 fs-00 op-75">or</div>
        <ButtonPrimary
          isOutlined
          size="sm"
          onClick={() => showEditPresetModal()}
        >
          Create a Shipping Preset
        </ButtonPrimary>
      </div>
    </div>
  )
}
