import {useEffect} from 'react'

import Loading from '../../../../common/components/List/Loading.js'
import {refreshOrderListAndCounts} from '../../../OrderListPage/orderListActions.js'

import OrderListRow from './OrderListRow/index.js'
import OrderListFooter from './OrderListFooter.js'
import NoOrders from './NoOrders.js'
import {useSelector} from '../../../../store.js'
import {
  batchReferenceIDSelector,
  isLoadingSelector,
  orderListQuerySelector,
  visibleOrderNumbersSelector,
} from '../../../OrderListPage/orderListSelectors.js'
import className from '../../../../common/className.js'

export default function OrderList() {
  useEffect(() => {
    document.title = 'Orders'
  }, [])

  const params = useSelector(orderListQuerySelector)
  const isLoading = useSelector(isLoadingSelector)
  const visibleOrderNumbers = useSelector(visibleOrderNumbersSelector)
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const showNoOrders = visibleOrderNumbers.length === 0 && !isLoading

  useEffect(() => {
    refreshOrderListAndCounts()
  }, [params])

  return (
    <div
      className={className`columns ${
        visibleOrderNumbers.length === 0 && !(batchReferenceID && !isLoading)
          ? 'medium-12'
          : 'medium-8'
      }`}
    >
      <div className="relative">
        {visibleOrderNumbers.length > 0 && <hr className="hr--content-block" />}
        <ul
          className={className`list--content-blocks list--order-blocks ${{
            'list--loading': isLoading,
          }}`}
        >
          {visibleOrderNumbers.map((orderNumber) => (
            <OrderListRow key={orderNumber} orderNumber={orderNumber} />
          ))}
        </ul>
        {showNoOrders && <NoOrders />}
        {isLoading && <Loading />}
      </div>
      {visibleOrderNumbers.length > 0 && <OrderListFooter />}
    </div>
  )
}
