import {useEffect, useMemo} from 'react'
import {createSelector} from 'reselect'

import {
  ANALYTICS_PAGE,
  BATCH_LIST_PAGE,
  CREATE_ORDER_PAGE,
  DASHBOARD_PAGE,
  DATA_IMPORT_PAGE,
  ORDER_LIST_PAGE,
  ORDER_DETAIL_PAGE,
  PACK_SHIP_PAGE,
  PRODUCT_LIST_PAGE,
  PRODUCT_DETAIL_PAGE,
  PURCHASE_ORDER_LIST_PAGE,
  PURCHASE_ORDER_DETAIL_PAGE,
  RETURN_ORDER_LIST_PAGE,
  RETURN_ORDER_DETAIL_PAGE,
  MO_LIST_PAGE,
  MO_DETAIL_PAGE,
  SETTINGS_PAGE,
  REPORTS_PAGE,
  WELCOME_PAGE,
} from '../common/constants/Pages.js'
import HashListener from '../common/components/HashListener.js'

import {currentPageSelector} from '../redux/selectors/ui/index.js'
import {analyticsHashSelector} from './AnalyticsPage/analyticsSelectors.js'
import {createOrderHashSelector} from './CreateOrderPage/createOrderSelectors.js'
import {orderListHashSelector} from './OrderListPage/orderListSelectors.js'
import {orderDetailHashSelector} from './OrderDetailPage/orderDetailSelectors.js'
import {productListHashSelector} from './ProductListPage/productListFunctions.js'
import {productDetailHashSelector} from './ProductDetailPage/productDetailSelectors.js'
import {purchaseOrderListHashSelector} from './PurchaseOrderListPage/purchaseOrderListSelectors.js'
import {purchaseOrderDetailHashSelector} from './PurchaseOrderDetailPage/poDetailSelectors.js'
import {settingsHashSelector} from './settings/Settings.js'
import {packShipPageHashSelector} from '../redux/selectors/ui/packShipPage/index.js'
import {returnOrderListHashSelector} from './ReturnOrderListPage/returnOrderListSelectors.js'
import {returnOrderDetailHashSelector} from './ReturnOrderDetailPage/returnOrderDetailSelectors.js'
import {moListHashSelector} from './MOListPage/moListSelectors.js'
import {moDetailHashSelector} from './MODetailPage/moDetailSelectors.js'
import {batchListHashSelector} from './BatchListPage/batchListSelectors.js'
import {reportPagesHashSelector} from './ReportsPage/reportsPageSelectors.js'
import {useSelector} from '../store.js'
import {locationSelector} from '../redux/selectors/ui/location.js'
import datadogRum from '../common/analytics/datadogRum.js'
import {DASHBOARD_URI_COMPONENT} from './DashboardPage/dashboardFunctions.js'
import {WELCOME_URI_COMPONENT} from '../common/constants/index.js'

export const hashSelector = createSelector(
  currentPageSelector,
  analyticsHashSelector,
  createOrderHashSelector,
  orderListHashSelector,
  orderDetailHashSelector,
  productListHashSelector,
  productDetailHashSelector,
  purchaseOrderListHashSelector,
  purchaseOrderDetailHashSelector,
  settingsHashSelector,
  packShipPageHashSelector,
  reportPagesHashSelector,
  returnOrderListHashSelector,
  returnOrderDetailHashSelector,
  moListHashSelector,
  moDetailHashSelector,
  batchListHashSelector,
  (
    currentPage,
    analyticsHash,
    createOrderHash,
    orderListHash,
    orderDetailHash,
    productListHash,
    productDetailHash,
    purchaseOrderListHash,
    purchaseOrderDetailHash,
    settingsHash,
    packShipPageHash,
    reportPagesHash,
    returnOrderListHash,
    returnOrderDetailHash,
    moListHash,
    moDetailHash,
    batchListHash,
  ) => {
    if (currentPage === ANALYTICS_PAGE) {
      return analyticsHash
    }
    if (currentPage === CREATE_ORDER_PAGE) {
      return createOrderHash
    }
    if (currentPage === ORDER_LIST_PAGE) {
      return orderListHash
    }
    if (currentPage === ORDER_DETAIL_PAGE) {
      return orderDetailHash
    }
    if (currentPage === PRODUCT_LIST_PAGE) {
      return productListHash
    }
    if (currentPage === PRODUCT_DETAIL_PAGE) {
      return productDetailHash
    }
    if (currentPage === PURCHASE_ORDER_LIST_PAGE) {
      return purchaseOrderListHash
    }
    if (currentPage === PURCHASE_ORDER_DETAIL_PAGE) {
      return purchaseOrderDetailHash
    }
    if (currentPage === SETTINGS_PAGE) {
      return settingsHash
    }
    if (currentPage === DATA_IMPORT_PAGE) {
      return '#/dataimport'
    }
    if (currentPage === DASHBOARD_PAGE) {
      return `#/${DASHBOARD_URI_COMPONENT}`
    }
    if (currentPage === WELCOME_PAGE) {
      return `#/${WELCOME_URI_COMPONENT}`
    }
    if (currentPage === PACK_SHIP_PAGE) {
      return packShipPageHash
    }
    if (currentPage === REPORTS_PAGE) {
      return reportPagesHash
    }
    if (currentPage === RETURN_ORDER_LIST_PAGE) {
      return returnOrderListHash
    }
    if (currentPage === RETURN_ORDER_DETAIL_PAGE) {
      return returnOrderDetailHash
    }
    if (currentPage === MO_LIST_PAGE) {
      return moListHash
    }
    if (currentPage === MO_DETAIL_PAGE) {
      return moDetailHash
    }
    if (currentPage === BATCH_LIST_PAGE) {
      return batchListHash
    }

    return null
  },
)

export default function AppHashListener() {
  const {pathComponents} = useSelector(locationSelector)

  const viewName = useMemo(
    () => '#/' + pathComponents.join('/'),
    [pathComponents],
  )

  useEffect(() => {
    if (viewName === '#/') {
      return
    }

    datadogRum.startView({name: viewName})
  }, [viewName])

  return <HashListener hashSelector={hashSelector} />
}
