import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {plural} from '../../../../common/components/Plural.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonPrimaryDropdown from '../../../../common/components/Button/ButtonPrimaryDropdown.js'
import ButtonPrimaryDropdownItem from '../../../../common/components/Button/ButtonPrimaryDropdownItem.js'
import {
  ManualDropShipFormShape,
  manualDropshipFormSelector,
  manualDropshipFormSupplierIsFBASelector,
  dropshipOrders,
  previewRequest,
  markAsDropshipped,
  manualDropshipFormSupplierRoutingChannelSelector,
} from './manualDropshipFormFunctions.js'
import {onlyIfForm} from '../../../../store.js'
import {COMMUNICATION_NONE} from '../../../../common/constants/Suppliers.js'

function ManualDropshipSummary({orderNumbers, form}) {
  const selectedSupplierIsFBA = useSelector(
    manualDropshipFormSupplierIsFBASelector,
  )
  const selectedSupplierRoutingChannel = useSelector(
    manualDropshipFormSupplierRoutingChannelSelector,
  )

  if (selectedSupplierIsFBA) {
    return (
      <div>
        <div>
          <ButtonPrimary
            isLoading={form.isProcessing}
            onClick={() => dropshipOrders(orderNumbers)}
          >
            Send to FBA
          </ButtonPrimary>
        </div>
      </div>
    )
  }

  if (selectedSupplierRoutingChannel === COMMUNICATION_NONE) {
    return (
      <div className="flex">
        <ButtonPrimaryDropdown
          dropdownID="DROPSHIP_REQUEST_DROPDOWN"
          title="Mark as Dropshipped"
          isLoading={form.isProcessing}
          onClick={() => markAsDropshipped(orderNumbers)}
        >
          <ButtonPrimaryDropdownItem
            title={plural(orderNumbers)`Print Dropshipment Request${['s']}`}
            description="Will not change the status, but allows you to print your dropshipment requests"
            onClick={() => previewRequest(orderNumbers)}
          />
        </ButtonPrimaryDropdown>
      </div>
    )
  }

  return (
    <div className="flex">
      <ButtonPrimaryDropdown
        dropdownID="DROPSHIP_REQUEST_DROPDOWN"
        title={plural(orderNumbers)`Email Request${['s']}`}
        isLoading={form.isProcessing}
        onClick={() => dropshipOrders(orderNumbers)}
      >
        <ButtonPrimaryDropdownItem
          title={plural(orderNumbers)`Preview Dropshipment Request${['s']}`}
          description="Will not change the status, but allows you to print your dropshipment requests"
          onClick={() => previewRequest(orderNumbers)}
        />
        <ButtonPrimaryDropdownItem
          title="Mark as Dropshipped"
          description="Changes status to Dropshipment Requested, but does not email dropshipper"
          onClick={() => markAsDropshipped(orderNumbers)}
        />
      </ButtonPrimaryDropdown>
    </div>
  )
}

ManualDropshipSummary.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: ManualDropShipFormShape.isRequired,
}

export default onlyIfForm(ManualDropshipSummary, manualDropshipFormSelector)
