import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty.js'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {isPresent} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import DatePicker from '../../../common/components/DatePicker.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {savePO, setPO} from '../../../data/pos.js'

export const SAVE_PO_TEXT_FIELD = 'SAVE_PO_TEXT_FIELD'
export const PO_TEXT_FIELD_MODAL = 'PO_TEXT_FIELD_MODAL'

export function showPOTextFieldModal({
  poID,
  value,
  display,
  apiProp,
  isRequired,
  needsPOListRefresh,
  inputType,
}) {
  return setForm(PO_TEXT_FIELD_MODAL, {
    poID,
    apiProp,
    value:
      inputType === 'date'
        ? value
          ? new Date(value)
          : null
        : isPresent(value)
          ? value
          : '',
    display,
    isRequired: !!isRequired,
    needsPOListRefresh: !!needsPOListRefresh,
    inputType: inputType || 'text',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(PO_TEXT_FIELD_MODAL, ...args)
}

export function closeModal() {
  return removeForm(PO_TEXT_FIELD_MODAL)
}

export function savePOTextField() {
  return {
    type: SAVE_PO_TEXT_FIELD,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PO_TEXT_FIELD_MODAL],
)

export const errorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
    }

    return errors
  },
)

export function* savePOTextFieldWorker() {
  try {
    const {poID, value, display, apiProp, needsPOListRefresh, inputType} =
      yield select(modalFormSelector)

    const params = {
      [apiProp]:
        inputType === 'date' ? (value ? value.toISOString() : null) : value,
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    const po = yield call(savePO, poID, params)

    yield call(setPO, po)

    yield put(closeModal())

    yield call(
      showMessageToast,
      `Saved purchase order ${display.toLowerCase()}`,
    )

    if (needsPOListRefresh) {
      yield call(refreshPurchaseOrderList)
    }
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function POTextFieldModal({form, errors, ...props}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title={`Edit PO ${form.display}`}
      modalSize="sm"
      onConfirm={() => props.savePOTextField()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        <div className="fs-01">
          <strong>{form.poID}</strong>
        </div>
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => props.updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => props.updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'date' ? (
        <DatePicker
          id="po_param_modal"
          selected={form.value}
          onChange={(value) =>
            props.updateModalForm({
              value,
            })
          }
          autoFocus
        />
      ) : form.inputType === 'currency' ? (
        <CurrencyInput
          className="biggie margin-bottom-0"
          width="sm"
          value={form.value}
          onChange={(value) => props.updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : null}
    </ConfirmModal>
  )
}

POTextFieldModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    value: PropTypes.any,
    display: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['text', 'textarea', 'date', 'currency'])
      .isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  savePOTextField: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: modalFormSelector(state),
    errors: errorsSelector(state),
  }
}

const mapDispatchToProps = {
  savePOTextField,
  closeModal,
  updateModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(POTextFieldModal),
  modalFormSelector,
)
