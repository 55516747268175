import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import DatePicker from '../../common/components/DatePicker.js'
import {DateShape, SelectOptionsShape} from '../../common/PropTypes.js'
import {setDateRangeFilters, setDateType} from './analyticsActions.js'
import {startDateSelector, endDateSelector} from './analyticsSelectors.js'

export function DateFilter({
  dateTypes,
  selectedDateType,
  startDate,
  endDate,
  maxDate,
}) {
  return (
    <li className="list__item--inline-block list__item--date margin-right-0 flex align-items-center">
      {dateTypes && (
        <select
          className="select select--ff h-auto margin-right-10"
          id="id_shipping_date_type"
          value={selectedDateType}
          onChange={(event) => setDateType(event.target.value)}
        >
          {dateTypes.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      )}{' '}
      <span className="make-inline-block">
        <DatePicker
          id="analytics_start_date"
          className="margin-bottom-0"
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            setDateRangeFilters(date || startDate, endDate)
          }}
          maxDate={maxDate}
        />
      </span>{' '}
      <span className="make-inline-block margin-left-3">to</span>{' '}
      <span className="make-inline-block margin-left-3">
        <DatePicker
          id="analytics_end_date"
          className="margin-bottom-0"
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            setDateRangeFilters(startDate, date || endDate)
          }}
          maxDate={maxDate}
        />
      </span>
    </li>
  )
}

DateFilter.propTypes = {
  dateTypes: SelectOptionsShape,
  selectedDateType: PropTypes.string,
  startDate: DateShape.isRequired,
  endDate: DateShape.isRequired,
  maxDate: DateShape.isRequired,
}

function mapStateToProp(state) {
  return {
    startDate: startDateSelector(state),
    endDate: endDateSelector(state),
    maxDate: new Date(),
  }
}

export default connect(mapStateToProp)(DateFilter)
