import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import {TAG_FILTER_OR} from '../../../../common/constants/Tags.js'
import {clearTagFilterPanelSearch} from './TagFilterPanel.js'
import {userSettingsSelector} from '../../../../data/me.js'
import {poListQuerySelector} from '../../purchaseOrderListSelectors.js'
import {
  navigatePOList,
  updatePOExcludeTags,
  updatePOTagFilters,
  updateSearchText,
  updateStatus,
  updatePOTagFilterBy,
  updatePOUntagged,
  updateSupplierFilters,
  updateWarehouseFilters,
} from '../../purchaseOrderListActions.js'
import {clearFilterPanelSearch} from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'

const MODAL_FORM = 'PO_LIST_FILTER_MODAL'

export const STATUS_FILTER_PANEL = 'status'
export const TAG_FILTER = 'tag'
export const SUPPLIER_FILTER_PANEL = 'supplier'
export const WAREHOUSE_FILTER_PANEL = 'warehouse'
const DEFAULT_PO_LIST_FILTER_ORDER = [
  STATUS_FILTER_PANEL,
  TAG_FILTER,
  SUPPLIER_FILTER_PANEL,
  WAREHOUSE_FILTER_PANEL,
]

export function showPOListFilterModal({clear} = {}) {
  const query = poListQuerySelector(getState())

  setForm(MODAL_FORM, {
    searchText: clear ? '' : query.searchText,
    status: clear ? [] : query.status,
    tags: clear ? [] : query.tags,
    untagged: clear ? false : query.untagged,
    tag_filter_by: clear ? TAG_FILTER_OR : query.tag_filter_by,
    exclude_tags: clear ? [] : query.exclude_tags,
    supplier_id: clear ? [] : query.supplier_id,
    warehouse_id: clear ? [] : query.warehouse_id,
  })

  clearFilterPanelSearch(SUPPLIER_FILTER_PANEL)
  clearFilterPanelSearch(WAREHOUSE_FILTER_PANEL)
  clearTagFilterPanelSearch()
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(modalFormSelector, () => {
  const errors = {}

  return errors
})

export async function applyFilter() {
  const {
    searchText,
    status,
    tags,
    untagged,
    tag_filter_by,
    exclude_tags,
    supplier_id,
    warehouse_id,
  } = modalFormSelector(getState())
  const query = poListQuerySelector(getState())

  const updates = {
    ...(searchText !== query.searchText
      ? updateSearchText(searchText)
      : undefined),
    ...(!isEqual(status, query.status) ? updateStatus(status) : undefined),
    ...(!isEqual(tags, query.tags) ? updatePOTagFilters(tags) : undefined),
    ...(untagged !== query.untagged ? updatePOUntagged(untagged) : undefined),
    ...(tag_filter_by !== query.tag_filter_by
      ? updatePOTagFilterBy(tag_filter_by)
      : undefined),
    ...(!isEqual(exclude_tags, query.exclude_tags)
      ? updatePOExcludeTags(exclude_tags)
      : undefined),
    ...(!isEqual(supplier_id, query.supplier_id)
      ? updateSupplierFilters(supplier_id)
      : undefined),
    ...(!isEqual(warehouse_id, query.warehouse_id)
      ? updateWarehouseFilters(warehouse_id)
      : undefined),
  }

  navigatePOList(updates)

  closeModal()
}

export const poListFilterOrderSelector = createSelector(
  (state) => userSettingsSelector(state).po_list_filter_order,
  (list) => {
    if (!list) {
      return DEFAULT_PO_LIST_FILTER_ORDER
    }

    // remove any items that have been removed from the default list
    list = list.filter((item) => DEFAULT_PO_LIST_FILTER_ORDER.includes(item))

    // if the list aren't the same size then there are new items in the default list
    // that need to be added to the list
    if (list.length !== DEFAULT_PO_LIST_FILTER_ORDER.length) {
      const newItems = DEFAULT_PO_LIST_FILTER_ORDER.filter(
        (item) => !list.includes(item),
      )

      list.push(...newItems)
    }

    return list
  },
)
