import {useCallback, useEffect} from 'react'

import {useSelector} from '../../../../../store.js'
import {LabelInfoIDsShape} from '../../../../../common/PropTypes.js'

import {
  updateLabelConfig,
  rateKeySelector,
  labelShipperIDSelector,
  boxShapeSelector,
  resetBulkRates,
  labelConfigSelector,
  bulkRatesSelector,
  allBulkRatesLoadingSelector,
  bulkRatesNumberLoadedSelector,
  includeInsuranceSelector,
  insuredValueSelector,
  labelShipperTypeSelector,
} from '../../../../../data/labelInfos/index.js'
import {getBulkRates} from '../../bulkLabelConfigActions.js'

import RateSelect from '../../../Fields/RateSelect/index.js'
import {useLabelConfigContext} from '../../../LabelConfigContext.js'
import {shipperCurrencySymbolSelector} from '../../../../../data/shippers.js'

export default function BulkRates({labelInfoIDs}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperID = useSelector(
    (state) => labelShipperIDSelector(state, {labelInfoID}) || '',
  )
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const boxShape = useSelector(
    (state) =>
      boxShapeSelector(state, {
        labelInfoID,
        shipperType,
        packagesIndex: 0,
      }) || '',
  )
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {
      labelInfoID,
    }),
  )
  const insuredValue = useSelector(
    (state) => insuredValueSelector(state, {labelInfoID}) || '',
  )
  const disabled = !shipperID || !boxShape
  const rateKey = useSelector(
    (state) => rateKeySelector(state, {labelInfoID}) || '',
  )
  const isMailInnovations = useSelector(
    (state) =>
      !!labelConfigSelector(state, {
        labelInfoID,
      }).is_mail_innovations,
  )
  const rates = useSelector((state) => bulkRatesSelector(state, {labelInfoIDs}))
  const shipperCurrencySymbol = useSelector((state) =>
    shipperCurrencySymbolSelector(state, {
      shipperType,
    }),
  )
  const loading = useSelector((state) =>
    allBulkRatesLoadingSelector(state, {labelInfoIDs}),
  )
  const numberLoaded = useSelector((state) =>
    bulkRatesNumberLoadedSelector(state, {labelInfoIDs}),
  )

  const loadRates = useCallback(() => {
    if (!disabled) {
      getBulkRates(labelInfoIDs)
    }
  }, [disabled, labelInfoIDs])

  useEffect(() => {
    resetBulkRates(labelInfoIDs)

    loadRates()
  }, [
    labelInfoIDs,
    shipperID,
    boxShape,
    includeInsurance,
    insuredValue,
    isMailInnovations,
  ])

  return (
    <div className="margin-bottom-7">
      <RateSelect
        rates={rates}
        value={rateKey}
        onChange={(rate) =>
          updateLabelConfig(labelInfoID, {
            [`${shipperType}__shipping_method`]: rate
              ? rate.service_type
              : null,
            [`${shipperType}__rate_key`]: rate ? rate.key : null,
          })
        }
        hasErrors={false}
        updated={false}
        loading={loading}
        disabled={disabled}
        refresh={() => loadRates()}
        currencySymbol={shipperCurrencySymbol}
      />
      {loading && (
        <small className="fs-n2">
          Loading rates for <strong>{numberLoaded}</strong> of{' '}
          <strong>{labelInfoIDs.length}</strong> orders
        </small>
      )}
    </div>
  )
}

BulkRates.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
