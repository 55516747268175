import PropTypes from 'prop-types'

import {
  UNSENT_STATUS,
  SENT_STATUS,
  PARTIAL_STATUS,
  RECEIVED_STATUS,
  CANCELLED_STATUS,
} from '../../common/constants/PurchaseOrders.js'
import {useSelector} from '../../store.js'
import {
  isFilteredSelector,
  poListQuerySelector,
} from './purchaseOrderListSelectors.js'

export default function EmptyList({colSpan}) {
  const {status} = useSelector(poListQuerySelector)
  const isFiltered = useSelector(isFilteredSelector)

  return (
    <tr className="table__tr">
      <td className="table__td" colSpan={colSpan}>
        <div className="padding-top-25 padding-bottom-25 align-center">
          <strong className="text--md-grey fs-02 lh-md">
            {isFiltered || status.length !== 1
              ? 'No POs match the current search terms'
              : status[0] === UNSENT_STATUS
                ? 'There are no unsent POs. You can create one using the “Order More Products” button above.'
                : status[0] === SENT_STATUS
                  ? 'None of your POs have been sent.'
                  : status[0] === PARTIAL_STATUS
                    ? 'None of your POs are partially sent.'
                    : status[0] === RECEIVED_STATUS
                      ? 'None of your POs have been received.'
                      : status[0] === CANCELLED_STATUS
                        ? 'None of your POs are cancelled.'
                        : 'There aren’t any POs.'}
          </strong>
        </div>
      </td>
    </tr>
  )
}

EmptyList.propTypes = {
  colSpan: PropTypes.string.isRequired,
}
