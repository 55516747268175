import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'

export default class Select extends Component {
  static propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    selectClassName: PropTypes.string,
    errorMessage: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (event) => this.props.onChange(event.target.value, this.props.name)

  render() {
    const name = this.props.name || this.props.id
    const id = this.props.id || `id_${name}`
    const errorMessage = this.props.errorMessage
    const allClassName = classNames(`container_${name}`, this.props.className, {
      error: !!errorMessage,
      disabled: this.props.disabled,
    })
    const selectClassName = classNames(
      'select margin-bottom-0',
      this.props.selectClassName,
    )

    return (
      <div className={allClassName}>
        {this.props.label && (
          <label htmlFor={id}>
            <span>{this.props.label}</span>
            {this.props.required && <span className="required">*</span>}
          </label>
        )}
        <select
          className={selectClassName}
          name={name}
          id={id}
          value={this.props.value}
          onChange={this.onChange}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </select>
        {errorMessage && (
          <small className="error error-message">{errorMessage}</small>
        )}
      </div>
    )
  }
}
