import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import DimensionsInput from '../../../../common/components/Form/DimensionsInput.js'
import {
  updateParcel,
  parcelSelector,
  dimensionHasError,
  dimensionsWarningMessageSelector,
  dimensionsErrorMessageSelector,
} from '../../../../data/labelInfos/index.js'
import {setShowDimensions, applyDimensions} from '../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../bulkLabelConfigSelectors.js'

import UpdateOrdersButton from './UpdateOrdersButton.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function BulkDimensions({labelInfoIDs}) {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const form = useSelector(bulkLabelConfigFormSelector)
  const {length, width, height} = useSelector((state) =>
    parcelSelector(state, {
      labelInfoID,
      packagesIndex: 0,
    }),
  )
  const errorMessage = useSelector((state) =>
    dimensionsErrorMessageSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )
  const warningMessage = useSelector((state) =>
    dimensionsWarningMessageSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )

  const errors = {
    hasLengthError: dimensionHasError(length),
    hasWidthError: dimensionHasError(width),
    hasHeightError: dimensionHasError(height),
  }
  const {hasLengthError, hasWidthError, hasHeightError} = {
    ...(errors.hasLengthError && errors.hasWidthError && errors.hasHeightError
      ? {
          hasLengthError: false,
          hasWidthError: false,
          hasHeightError: false,
        }
      : errors),
  }

  return (
    <dd className="list__item">
      <label htmlFor="bulk_dimensions">
        <input
          type="checkbox"
          id="bulk_dimensions"
          onChange={() => setShowDimensions(!form.showDimensions)}
          checked={form.showDimensions}
        />
        <span className="margin-left-5">Dimensions</span>
      </label>
      {form.showDimensions && (
        <div className="wrap--nested-form">
          <ul className="list--shipping-options">
            <DimensionsInput
              length={length || ''}
              width={width || ''}
              height={height || ''}
              setLength={(value) =>
                updateParcel(labelInfoID, 0, {length: value})
              }
              setWidth={(value) => updateParcel(labelInfoID, 0, {width: value})}
              setHeight={(value) =>
                updateParcel(labelInfoID, 0, {height: value})
              }
              errorMessage={errorMessage}
              hasLengthError={hasLengthError}
              hasWidthError={hasWidthError}
              hasHeightError={hasHeightError}
              warningMessage={warningMessage}
            />
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              disabled={!!errorMessage}
              onClick={() => applyDimensions(labelInfoIDs)}
              loading={form.loading__dimensions}
            />
          </ul>
        </div>
      )}
    </dd>
  )
}

BulkDimensions.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
