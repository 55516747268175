import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce.js'

import {isPositiveNumeric} from '../../utils.js'
import {splitOz, combineToOz, lbToOz} from '../../weight.js'
import NumberInput from './NumberInput.js'
import ScaleControl from './ScaleControl.js'

export default class WeightInput extends Component {
  static propTypes = {
    weightOz: PropTypes.number,
    setWeightOz: PropTypes.func.isRequired,
    idLb: PropTypes.string,
    metaClassName: PropTypes.string,
    allowZero: PropTypes.bool,
    showScaleControl: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    delay: PropTypes.number,
    onEnterKeyPress: PropTypes.func,
    onErrorChange: PropTypes.func,
  }

  state = {
    lb: '',
    oz: '',
    lbError: false,
    ozError: false,
    specialWeight: null,
  }

  componentDidMount() {
    this.setupInputChange()

    this.onWeightOzUpdate()
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.weightOz !== this.props.weightOz) {
      this.onWeightOzUpdate()
    }

    if (
      this.props.onErrorChange &&
      (oldState.lbError !== this.state.lbError ||
        oldState.ozError !== this.state.ozError)
    ) {
      this.props.onErrorChange({
        lb: this.state.lbError,
        oz: this.state.ozError,
      })
    }

    if (oldState.lb !== this.state.lb || oldState.oz !== this.state.oz) {
      this.onInputChange()
    }

    if (oldState.specialWeight === null && this.state.specialWeight !== null) {
      this.handleSpecialWeight()
    }
  }

  handleSpecialWeight() {
    this.setState({
      specialWeight: null,
      ...this.getStateFromTotalOz(this.state.specialWeight),
    })
  }

  onWeightOzUpdate() {
    this.setState(this.getStateFromTotalOz(this.props.weightOz))
  }

  onIncrementOz(oz) {
    const newTotalOz = combineToOz(this.state.lb, oz)

    if (newTotalOz < 0) {
      return
    }

    this.setState(this.getStateFromTotalOz(newTotalOz))
  }

  onScaleChange(scaleWeight) {
    this.setState(this.getStateFromTotalOz(scaleWeight))
  }

  onEnterKeyPress() {
    this.onInputChange()

    // make it happen right now
    this.onInputChange.flush()

    if (this.props.onEnterKeyPress) {
      this.props.onEnterKeyPress()
    }
  }

  calculateTotalWeightOz() {
    const lb = Number(this.state.lb)
    const oz = Number(this.state.oz)

    if (oz >= 16) {
      this.setState({specialWeight: oz})

      return oz
    }

    if (this.state.lb.match(/\./)) {
      this.setState({specialWeight: lbToOz(lb)})

      return lbToOz(lb)
    }

    return combineToOz(lb, oz)
  }

  getStateFromTotalOz(totalOz) {
    if (totalOz === null) {
      return {
        lb: '',
        oz: '',
        lbError: false,
        ozError: false,
      }
    }

    const {lb, oz} = splitOz(totalOz)
    return {
      lb: `${lb}`,
      oz: `${oz}`,
      ...this.getErrors(lb, oz),
    }
  }

  getErrors(lb, oz) {
    return {
      lbError: !isPositiveNumeric(lb),
      ozError: !isPositiveNumeric(oz),
    }
  }

  setLb(lb) {
    this.setState({lb, ...this.getErrors(lb, this.state.oz)})
  }

  setOz(oz) {
    this.setState({oz, ...this.getErrors(this.state.lb, oz)})
  }

  setupInputChange() {
    this.onInputChange = debounce(
      () => {
        if (
          this.props.disabled ||
          this.props.weightOz === null ||
          this.state.lbError ||
          this.state.ozError
        ) {
          return
        }

        const weightOz = this.calculateTotalWeightOz()

        if (this.props.weightOz !== weightOz) {
          this.props.setWeightOz(weightOz)
        }
      },
      this.props.delay === undefined ? 500 : this.props.delay,
    )
  }

  render() {
    const {
      idLb,
      weightOz,
      metaClassName,
      allowZero = true,
      showScaleControl,
      autoFocus,
      disabled,
    } = this.props

    const invalidWeight =
      weightOz !== null ? (allowZero ? weightOz < 0 : weightOz <= 0) : false

    return (
      <div
        className={classNames('meta-input-weightOz', metaClassName, {
          error: invalidWeight,
        })}
      >
        <div className="flex flex-wrap">
          <div className="flex wrap--weight-input margin-bottom-5 margin-right-3">
            <NumberInput
              id={idLb}
              value={this.state.lb}
              isInvalid={invalidWeight || this.state.lbError}
              onChange={(lb) => this.setLb(lb)}
              onIncrement={(lb) => this.setLb(`${lb}`)}
              onDecrement={(lb) => this.setLb(`${lb}`)}
              min={0}
              onEnterKeyPress={() => this.onEnterKeyPress()}
              autoFocus={autoFocus}
              disabled={disabled}
            />
            <span
              className={classNames('input__unit inline-block', {
                'op-50': disabled,
              })}
            >
              lb
            </span>
          </div>
          <div className="flex wrap--weight-input margin-bottom-5 margin-right-3">
            <NumberInput
              value={this.state.oz}
              isInvalid={invalidWeight || this.state.ozError}
              disabled={disabled}
              onChange={(oz) => this.setOz(oz)}
              onIncrement={(oz) => this.onIncrementOz(oz)}
              onDecrement={(oz) => this.onIncrementOz(oz)}
              onEnterKeyPress={() => this.onEnterKeyPress()}
            />
            <span
              className={classNames('input__unit inline-block', {
                'op-50': disabled,
              })}
            >
              oz
            </span>
          </div>
          {!disabled && showScaleControl && (
            <ScaleControl onChange={(weight) => this.onScaleChange(weight)} />
          )}
        </div>
      </div>
    )
  }
}
