import {csvFormatRows} from 'd3-dsv'
import merge from 'lodash/merge.js'
import omit from 'lodash/omit.js'
import isEmpty from 'lodash/isEmpty.js'
import isString from 'lodash/isString.js'
import isFunction from 'lodash/isFunction.js'

import logger from '../../../../../common/logger.js'
import {isPresent, isNumeric} from '../../../../../common/utils.js'
import promiseReduce from '../../../../../common/promiseReduce.js'
import {saveCSVFile} from '../../../../../common/createCSVFile.js'
import {setGlobalError} from '../../../ui/index.js'
import {
  setIsValidatingData,
  setIsSaving,
  setCurrentDataIndex,
  setStopBlock,
  setColumnErrors,
  setColumnsToDataMap,
} from '../../dataImport/index.js'
import {saveableIndexesSelector} from '../../../../selectors/ui/dataImport/index.js'
import {processFiles as uploadProcessFiles} from '../upload.js'
import {
  setData,
  setIsSaved,
  setErrors,
  setPayload,
  setIsDeleted,
} from '../data.js'

export default class baseStrategy {
  static title = 'Base'
  static requiredColumns = []

  static formatCSVRows(rows) {
    return csvFormatRows(rows)
  }

  static saveFile(text) {
    const fileName = `${this.title}-example.csv`

    saveCSVFile(text, fileName)
  }

  static createExampleFile() {
    return (dispatch, getState) =>
      dispatch(this.getExampleRows(getState))
        .then((rows) => this.formatCSVRows(rows))
        .then((text) => this.saveFile(text))
        .catch((err) => dispatch(setGlobalError({summary: err.message}, err)))
  }

  static getExampleRows() {}

  static processFiles(files) {
    return (dispatch, getState) =>
      uploadProcessFiles(files, dispatch).then(() => {
        const columns = getState().ui.dataImport.columns

        dispatch(this.guessAtColumnDataMap(columns))
        dispatch(this.validateColumns())
      })
  }

  static guessAtColumnDataMap() {}

  static validateColumns() {
    return (dispatch, getState) => {
      const columnsToDataMap = getState().ui.dataImport.columnsToDataMap

      const requiredColumns = isFunction(this.requiredColumns)
        ? this.requiredColumns(columnsToDataMap)
        : this.requiredColumns

      const columnErrors = requiredColumns.reduce((errors, column) => {
        if (columnsToDataMap[column] === -1) {
          errors[column] = 'Field is required'
        }

        return errors
      }, {})

      dispatch(setColumnErrors(columnErrors))
    }
  }

  static updateColumnMapping(value, name) {
    return (dispatch, getState) => {
      const columnsToDataMap = getState().ui.dataImport.columnsToDataMap
      const map = {...columnsToDataMap}

      map[name] = +value

      dispatch(setColumnsToDataMap(map))
      dispatch(this.validateColumns())
    }
  }

  static confirmColumns() {
    return (dispatch, getState) => {
      const {columnsToDataMap, rows} = getState().ui.dataImport

      dispatch(setIsValidatingData(true))

      // Give UI time to paint new status
      return new Promise((resolve) => {
        setTimeout(() => {
          const data = dispatch(
            this.transformRowsToData(rows, columnsToDataMap),
          )

          dispatch(setData(data))
          dispatch(setIsValidatingData(false))
          dispatch(this.queueNextData({fromStart: true}))

          resolve()
        }, 100)
      })
    }
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return () => {
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(rows[i], columnsToDataMap)
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData() {}

  static getNewData() {
    return {
      isSaved: false,
      isDeleted: false,
      errors: {},
      payload: {},
    }
  }

  static updatePayload(index, updatesToPayload) {
    return (dispatch, getState) => {
      const data = getState().ui.dataImport.data[index]
      const {columnsToDataMap} = getState().ui.dataImport
      const payload = merge(data.payload, updatesToPayload)
      const errors = this.determineDataErrors(
        {
          ...data,
          payload,
        },
        columnsToDataMap,
      )

      dispatch(setPayload(index, payload))
      dispatch(setErrors(index, errors))
    }
  }

  static onBlur(/* index, key */) {}

  static determineDataErrors() {
    return {}
  }

  static required(...args) {
    return this.requiredBase(...args, isPresent)
  }

  static requiredNumber(...args) {
    return this.requiredBase(...args, isNumeric)
  }

  static requiredBase(
    payload,
    errors,
    key,
    message = `${key} is required`,
    validator,
  ) {
    const value = payload[key]

    // undefined value means that column was not provided in the CSV
    if (validator(value) || value === undefined) {
      delete errors[key]
    } else {
      errors[key] = message
    }
  }

  static deleteData() {
    return (dispatch, getState) => {
      const dataImport = getState().ui.dataImport
      const currentDataIndex = dataImport.currentDataIndex

      dispatch(setIsDeleted(currentDataIndex, true))
      dispatch(this.queueNextData())
    }
  }

  static saveOne(index) {
    return (dispatch, getState) => {
      const dataImport = getState().ui.dataImport
      const data = dataImport.data[index]

      return dispatch(this.save(data))
        .then(() => {
          dispatch(setIsSaved(data.index, true))

          if (data.errors.server) {
            const errors = omit(data.errors, 'server')
            dispatch(setErrors(data.index, errors))
          }
        })
        .catch((error) => {
          const message = `Error saving: ${error.error_message}`
          const errors = {...data.errors, server: message}

          dispatch(setErrors(data.index, errors))

          throw error
        })
    }
  }

  static saveCurrent() {
    return (dispatch, getState) => {
      const dataImport = getState().ui.dataImport
      const currentDataIndex = dataImport.currentDataIndex

      dispatch(setIsSaving(true))

      return dispatch(this.saveOne(currentDataIndex))
        .then(() => dispatch(this.queueNextData()))
        .catch((err) => logger.info(err))
        .then(() => dispatch(this.afterSave()))
        .then(() => dispatch(setIsSaving(false)))
    }
  }

  static saveAll() {
    return (dispatch, getState) => {
      const state = getState()
      const dataImport = state.ui.dataImport
      const willStopSavingOnErrors = dataImport.willStopSavingOnErrors

      dispatch(setStopBlock(false))
      dispatch(setIsSaving(true))

      const saveableIndexes = saveableIndexesSelector(state)

      const promise = promiseReduce(
        saveableIndexes,
        (previousResolve, index) => {
          if (getState().ui.dataImport.stopBlock) {
            return Promise.reject()
          }

          return dispatch(this.saveOne(index)).catch((error) => {
            if (willStopSavingOnErrors) {
              dispatch(setCurrentDataIndex(index))

              throw error
            }
          })
        },
      )

      return promise
        .then(() => dispatch(this.queueNextData({fromStart: true})))
        .catch((err) => logger.info(err))
        .then(() => dispatch(this.afterSave()))
        .then(() => {
          dispatch(setIsSaving(false))
        })
    }
  }

  static save() {}

  static afterSave() {
    return () => {}
  }

  static getNextIndex({data, initialIndex, willShowOnlyErrors, direction = 1}) {
    function next(increment, lastIndex) {
      let nextIndex = lastIndex + increment

      if (nextIndex < 0) {
        nextIndex = data.length + increment
      } else if (nextIndex >= data.length) {
        nextIndex = (lastIndex + 1) % data.length
      }
      return nextIndex
    }

    // loop around the array if at the end
    let nextIndex = next(direction, initialIndex)

    // stop if we are where we started
    while (nextIndex !== initialIndex) {
      // Stop when we have something to edit
      if (this.isEditable({data: data[nextIndex], willShowOnlyErrors})) {
        break
      }

      nextIndex = next(direction, nextIndex)
    }

    if (!data[nextIndex]) {
      return 0
    }

    return nextIndex
  }

  static isEditable({data, willShowOnlyErrors}) {
    if (data.isSaved || data.isDeleted) {
      return false
    }

    if (willShowOnlyErrors && isEmpty(data.errors)) {
      return false
    }

    return true
  }

  static queueNextData({fromStart, direction} = {}) {
    return (dispatch, getState) => {
      const {data, currentDataIndex, willShowOnlyErrors} =
        getState().ui.dataImport

      const initialIndex = fromStart ? data.length - 1 : currentDataIndex

      const nextIndex = this.getNextIndex({
        data,
        initialIndex,
        willShowOnlyErrors,
        direction,
      })

      if (nextIndex !== currentDataIndex) {
        dispatch(setCurrentDataIndex(nextIndex))
      }
    }
  }

  static queuePreviousData(args = {}) {
    args.direction = -1

    return this.queueNextData(args)
  }

  static stop() {
    return setStopBlock(true)
  }

  static fromBoolean(value) {
    return value ? 'true' : 'false'
  }

  static toBoolean(value) {
    if (isString(value)) {
      value = !value.match(/^(f|false|0|)$/i)
    }

    return !!value
  }
}
