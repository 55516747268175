import {
  updateLabelConfig,
  labelConfigSelector,
  labelPropertiesSelector,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {getState, useSelector} from '../../../store.js'
import {DHL, FEDEX, UPS} from '../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import ConfigTextInput from './ConfigTextInput.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import useLabelProperty from './useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

// Type
const VALID_SHIPPER_TYPES = [DHL, FEDEX, UPS]

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID]
}

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [
        `${shipperType}__${
          shipperType === DHL ? 'duty_payment_type' : 'duties_payment_type'
        }`,
      ]
    : []
}

// Account
const LABEL_PROPERTY_DUTIES_PAYMENT_ACCOUNT = 'duties_payment_account'
const DUTIES_PAYMENT_ACCOUNT_VALID_LABEL_PROPERTIES = [
  LABEL_PROPERTY_DUTIES_PAYMENT_ACCOUNT,
]

function showDutiesPaymentAccountSelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const paymentType = labelConfig[labelProperty] || ''

  return (
    (paymentType !== 'S' && shipperType === DHL) ||
    (paymentType !== 'SENDER' && shipperType === FEDEX) ||
    (paymentType !== 'BillShipper' && shipperType === UPS)
  )
}

function dutiesPaymentAccountDependantFunc(shipperType) {
  return [
    ...dependantPropertiesFunc(shipperType),
    ...labelPropertiesFunc(shipperType),
  ]
}

function dutiesPaymentAccountPropertiesFunc(shipperType, labelInfoID) {
  const showDutiesPaymentAccount = showDutiesPaymentAccountSelector(
    getState(),
    {
      labelInfoID,
      shipperType,
    },
  )

  return showDutiesPaymentAccount ? [LABEL_PROPERTY_DUTIES_PAYMENT_ACCOUNT] : []
}

// Zip and Country
const DUTIES_ZIP_COUNTRY_VALID_SHIPPER_TYPES = [UPS]
const LABEL_PROPERTY_DUTIES_PAYMENT_ZIP = 'duties_payment_zip'
const LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY = 'duties_payment_country'
const DUTIES_ZIP_COUNTRY_VALID_LABEL_PROPERTIES = [
  LABEL_PROPERTY_DUTIES_PAYMENT_ZIP,
  LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY,
]

function showDutiesPaymentZipCountrySelector(
  state,
  {labelInfoID, shipperType},
) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const paymentType = labelConfig[labelProperty] || ''

  return paymentType === 'BillThirdParty' && shipperType === UPS
}

function isDutiesZipCountryPropertiesEnabledSelector(state, {labelInfoID}) {
  const properties = labelPropertiesSelector(state, {labelInfoID})

  return (
    properties.includes(LABEL_PROPERTY_DUTIES_PAYMENT_ZIP) &&
    properties.includes(LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY)
  )
}

function dutiesZipCountryDependantPropertiesFunc(shipperType) {
  return [
    ...dependantPropertiesFunc(shipperType),
    ...labelPropertiesFunc(shipperType),
  ]
}

function dutiesZipCountryLabelPropertiesFunc(shipperType, labelInfoID) {
  const showDutiesPaymentZipCountry = showDutiesPaymentZipCountrySelector(
    getState(),
    {
      labelInfoID,
      shipperType,
    },
  )

  return showDutiesPaymentZipCountry
    ? [LABEL_PROPERTY_DUTIES_PAYMENT_ZIP, LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY]
    : []
}

export default function DutiesPayor() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const showDutiesPaymentAccount = useSelector((state) =>
    showDutiesPaymentAccountSelector(state, {labelInfoID, shipperType}),
  )
  const showDutiesPaymentZipCountry = useSelector((state) =>
    showDutiesPaymentZipCountrySelector(state, {labelInfoID, shipperType}),
  )

  // Zip and Country
  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_DUTIES_PAYMENT_ZIP,
    validShipperTypes: DUTIES_ZIP_COUNTRY_VALID_SHIPPER_TYPES,
    dependantPropertiesFunc: dutiesZipCountryDependantPropertiesFunc,
    labelPropertiesFunc: dutiesZipCountryLabelPropertiesFunc,
    validLabelProperties: DUTIES_ZIP_COUNTRY_VALID_LABEL_PROPERTIES,
  })

  const dutiesPaymentZip = labelConfig[LABEL_PROPERTY_DUTIES_PAYMENT_ZIP] || ''
  const dutiesPaymentCountry =
    labelConfig[LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY] || ''

  return (
    <>
      <ConfigSelect
        label="Duties Payor"
        noEmptyOption
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [labelProperty]: value,
          })

          onChange()
        }}
        labelProperty={labelProperty}
        labelPropertiesFunc={labelPropertiesFunc}
        validShipperTypes={VALID_SHIPPER_TYPES}
        dependantPropertiesFunc={dependantPropertiesFunc}
        shipperOptionsProperty="payor"
      />
      <ConfigTextInput
        label="Duties Payor Account Number"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [LABEL_PROPERTY_DUTIES_PAYMENT_ACCOUNT]: value,
          })

          onChange()
        }}
        labelProperty={LABEL_PROPERTY_DUTIES_PAYMENT_ACCOUNT}
        validShipperTypes={VALID_SHIPPER_TYPES}
        labelPropertiesFunc={dutiesPaymentAccountPropertiesFunc}
        dependantPropertiesFunc={dutiesPaymentAccountDependantFunc}
        validLabelProperties={DUTIES_PAYMENT_ACCOUNT_VALID_LABEL_PROPERTIES}
        hide={!showDutiesPaymentAccount}
      />
      {showDutiesPaymentZipCountry && (
        <li className="list__item--shipping-options fs-n1 margin-bottom-5">
          <div className="flex flex-wrap wrap--edit-preset-form-input">
            <div className="margin-bottom-5 margin-right-15">
              <label className="label--bold" htmlFor="duties-payment-zip">
                Duties Payor Zipcode
              </label>
              <input
                className="input--md inline-block"
                type="text"
                id="duties-payment-zip"
                value={dutiesPaymentZip}
                onChange={(event) => {
                  updateLabelConfig(labelInfoID, {
                    [LABEL_PROPERTY_DUTIES_PAYMENT_ZIP]: event.target.value,
                  })

                  onChange()
                }}
                disabled={isLabelEnableMode && !isLabelPropertyEnabled}
              />
            </div>
            <div className="margin-bottom-5">
              <label className="label--bold" htmlFor="duties-payment-country">
                Duties Payor Country Code
              </label>
              <input
                className="input--sm inline-block"
                type="text"
                id="duties-payment-country"
                value={dutiesPaymentCountry}
                onChange={(event) => {
                  updateLabelConfig(labelInfoID, {
                    [LABEL_PROPERTY_DUTIES_PAYMENT_COUNTRY]: event.target.value,
                  })

                  onChange()
                }}
                disabled={isLabelEnableMode && !isLabelPropertyEnabled}
              />
            </div>
          </div>
          <EnableLabelProperty
            labelProperty={LABEL_PROPERTY_DUTIES_PAYMENT_ZIP}
            checkedSelector={(state) =>
              isDutiesZipCountryPropertiesEnabledSelector(state, {
                labelInfoID,
              })
            }
          />
        </li>
      )}
    </>
  )
}
