import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {MO_STATII} from '../../common/constants/MOs.js'
import HeaderDropdown from '../../common/components/List/HeaderDropdown.js'
import DropdownItemButton from '../../common/components/Dropdown/DropdownItemButton.js'
import {setStatus} from './moListActions.js'
import {moListQuerySelector} from './moListSelectors.js'

function MOStatusDropdown({status}) {
  return (
    <HeaderDropdown label="Status" dropdown="DROPDOWN_FILTER_STATUS">
      {MO_STATII.map(({slug, name}) => (
        <DropdownItemButton
          key={slug}
          value={slug}
          selectedValue={status}
          onClick={() => {
            if (status !== slug) {
              setStatus(slug)
            }
          }}
        >
          {name}
        </DropdownItemButton>
      ))}
    </HeaderDropdown>
  )
}

MOStatusDropdown.propTypes = {
  status: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    status: moListQuerySelector(state).status,
  }
}

export default connect(mapStateToProps)(MOStatusDropdown)
