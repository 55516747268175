import PropTypes from 'prop-types'
import classNames from 'classnames'
import {getConditionOption} from './conditionalOptions.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import TextInput from '../../../../common/components/TextInput.js'

export default function ConditionValueTextInput({condition, error, onChange}) {
  const option = getConditionOption(condition)
  const value = option.getValue(condition)

  return (
    <div
      className={classNames('flex-item', {
        error,
        'flex-grow': option.type === 'string',
      })}
    >
      {option.type === 'number' ? (
        <NumberInput
          value={value}
          onChange={(value) => onChange(String(value))}
        />
      ) : (
        <TextInput value={value} onChange={(value) => onChange(value)} />
      )}
      {error && <small className="error error-message">{error}</small>}
    </div>
  )
}

ConditionValueTextInput.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
}
