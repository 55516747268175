import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {isRunningTasksSelector} from '../../redux/selectors/data/isRunningTasks.js'
import {showCartSyncModal} from '../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {checkRunningTasks} from '../../redux/actions/data/isRunningTasks.js'

class SyncButton extends Component {
  static propTypes = {
    isRunningTasks: PropTypes.bool.isRequired,
    checkRunningTasks: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.checkRunningTasks()
  }

  render() {
    const {isRunningTasks} = this.props

    return (
      <button
        className={classNames(
          'btn btn--primary btn--primary-alt btn--nav-sync',
          {
            'btn--processing': isRunningTasks,
          },
        )}
        type="button"
        onClick={() => showCartSyncModal()}
      >
        SYNC
      </button>
    )
  }
}

function mapStateToProps(state) {
  return {
    isRunningTasks: isRunningTasksSelector(state),
  }
}

const mapDispatchToProps = {
  checkRunningTasks,
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncButton)
