import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_PACKING_LISTS} from '../../actions/data/packingLists.js'

export default function reducer(
  state = INITIAL_STATE.data.packingLists,
  action = {},
) {
  if (action.type === SET_PACKING_LISTS) {
    return action.payload
  }

  return state
}
