import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep.js'
import isEqual from 'lodash/isEqual.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  LabelConfigShape,
  LabelInfoIDShape,
} from '../../../../common/PropTypes.js'
import Currency from '../../../../common/components/Currency.js'
import {formatWeight, splitOz} from '../../../../common/weight.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {
  updateLabelConfig,
  addCustomsLine,
  labelConfigSelector,
  customsInfoSelector,
  totalQuantitySelector,
  totalValueSelector,
  totalWeightSelector,
  generalCustomsErrorsSelector,
  canAddLineSelector,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  customsErrorsSelector,
  getCanHaveHarmonizationCode,
  getCanHaveHarmonizationCodeCountry,
  getCanHaveSKU,
} from '../../../../data/labelInfos/index.js'
import {getRates} from '../../../../data/labelInfos/rateRequest.js'
import Line from './Line.js'

export const MODAL_FORM = 'CUSTOMS_MODAL_FORM'

export function showCustomsModal(labelInfoID, shipperType) {
  const savedConfig = cloneDeep(labelConfigSelector(getState(), {labelInfoID}))

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    savedConfig,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export async function confirm() {
  const {labelInfoID, savedConfig} = modalFormSelector(getState())
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  closeModal()

  if (!isEqual(savedConfig, labelConfig)) {
    showMessageToast('Customs declarations were successfully updated.')

    await getRates([labelInfoID])
  }
}

export async function cancel() {
  const form = modalFormSelector(getState())

  if (!form) {
    return
  }

  const {labelInfoID, savedConfig} = form

  closeModal()

  updateLabelConfig(labelInfoID, savedConfig)

  await getRates([labelInfoID])
}

function CustomsModal({form}) {
  const {labelInfoID, shipperType} = form

  const lines = useSelector((state) =>
    customsInfoSelector(state, {labelInfoID}),
  )
  const totalQuantity = useSelector((state) =>
    totalQuantitySelector(state, {labelInfoID}),
  )
  const totalValue = useSelector((state) =>
    totalValueSelector(state, {labelInfoID}),
  )
  const totalWeight = useSelector((state) =>
    totalWeightSelector(state, {labelInfoID}),
  )
  const hasErrors = !!useSelector((state) =>
    customsErrorsSelector(state, {labelInfoID, shipperType}),
  )
  const generalCustomsErrors = useSelector((state) =>
    generalCustomsErrorsSelector(state, {labelInfoID, shipperType}),
  )
  const canAddLine = useSelector((state) =>
    canAddLineSelector(state, {labelInfoID, shipperType}),
  )
  const canHaveHarmonizationCode = getCanHaveHarmonizationCode(shipperType)
  const canHaveHarmonizationCodeCountry =
    getCanHaveHarmonizationCodeCountry(shipperType)
  const canHaveSKU = getCanHaveSKU(shipperType)
  const shipFromAddress = useSelector((state) =>
    labelShipFromAddressSelector(state, {labelInfoID}),
  )
  const shipToAddress = useSelector((state) =>
    labelShipToAddressSelector(state, {labelInfoID}),
  )
  const lastCustomLine = lines[lines.length - 1] || {
    description: '',
    quantity: 1,
    value: 0,
    weightLB: 0,
    weightOZ: 0,
    sku: '',
    country: shipFromAddress.country || 'US',
    harmonizationCode: '',
    harmonizationCodeCountry: shipToAddress.country || 'US',
  }

  return (
    <ConfirmModal
      title="Edit Customs Declarations"
      modalSize="x-lg"
      onConfirm={() => confirm()}
      onCancel={() => cancel()}
      confirmText="Save and Return"
      cancelText="Cancel"
      isDisabled={hasErrors}
    >
      <div className="row">
        <div className="medium-12 columns">
          {generalCustomsErrors.map((errorMessage) => (
            <dl
              key={errorMessage}
              className="list list--error margin-bottom-10"
            >
              <dd className="fs-n0 margin-bottom-0">{errorMessage}</dd>
            </dl>
          ))}
          <table className="table">
            <thead>
              <tr>
                <th className="table__th table__th--sm th-width-20">
                  Desc of Contents
                </th>
                <th className="table__th table__th--sm th-width-10">Qty</th>
                <th className="table__th table__th--sm th-width-20">
                  Total Weight
                </th>
                <th className="table__th table__th--sm th-width-10">
                  Unit Value
                </th>
                {canHaveSKU && (
                  <th className="table__th table__th--sm th-width-10">SKU</th>
                )}
                {canHaveHarmonizationCode && (
                  <th className="table__th table__th--sm th-width-10">
                    Harmonized Code
                  </th>
                )}
                {canHaveHarmonizationCodeCountry && (
                  <th className="table__th table__th--sm th-width-10">
                    Dest. Country
                  </th>
                )}
                <th className="table__th table__th--sm th-width-10">
                  Country of Origin
                </th>
                <th className="table__th table__th--sm">&nbsp;</th>
              </tr>
            </thead>
            <tbody className="table__tbody table__tbody--lines">
              {lines.map((line, index) => (
                <Line
                  key={index}
                  labelInfoID={labelInfoID}
                  shipperType={shipperType}
                  customsInfoIndex={index}
                  canHaveHarmonizationCode={canHaveHarmonizationCode}
                  canHaveHarmonizationCodeCountry={
                    canHaveHarmonizationCodeCountry
                  }
                  canHaveSKU={canHaveSKU}
                />
              ))}
            </tbody>
            <tbody className="table__tbody table__tbody--single-total">
              <tr>
                <td className="table__td">
                  <strong className="fs-n1">Totals</strong>
                </td>
                <td className="table__td border-left--light">
                  <strong className="fs-n1">{totalQuantity}</strong>
                </td>
                <td className="table__td border-left--light">
                  <strong className="fs-n1">
                    {formatWeight(splitOz(totalWeight))}
                  </strong>
                </td>
                <td className="table__td border-left--light">
                  <strong className="fs-n1">
                    <Currency value={totalValue} />
                  </strong>
                </td>
                <td className="table__td border-left--light" />
                {canHaveSKU && <td className="table__td" />}
                {canHaveHarmonizationCode && <td className="table__td" />}
                {canHaveHarmonizationCodeCountry && (
                  <td className="table__td" />
                )}
                <td className="table__td" />
              </tr>
            </tbody>
            {canAddLine && (
              <tbody>
                <tr>
                  <td
                    className="table__td table__td--product-search align-center"
                    colSpan={
                      (canHaveSKU ? 1 : 0) +
                      (canHaveHarmonizationCode ? 1 : 0) +
                      (canHaveHarmonizationCodeCountry ? 1 : 0) +
                      6
                    }
                  >
                    <button
                      className="btn btn--link fs-n0"
                      onClick={() =>
                        addCustomsLine(labelInfoID, {...lastCustomLine})
                      }
                    >
                      Add Another Line
                    </button>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </ConfirmModal>
  )
}

CustomsModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    savedConfig: LabelConfigShape.isRequired,
  }).isRequired,
}

export default onlyIfForm(CustomsModal, modalFormSelector)
