import {createSelector} from 'reselect'
import get from 'lodash/get.js'

export const commonAppMessageSelector = createSelector(
  (state) => state.data,
  ({commonAppMessage}) => commonAppMessage,
)

export const bannerMessagesSelector = createSelector(
  commonAppMessageSelector,
  ({appMessages}) =>
    appMessages.filter(({meta}) => get(meta, 'type', 'banner') === 'banner'),
)

export const undismissableBannerMessagesSelector = createSelector(
  bannerMessagesSelector,
  (bannerMessages) =>
    bannerMessages.filter((message) =>
      get(message, 'meta.can_not_dismiss', false),
    ),
)

export const dismissableBannerMessagesSelector = createSelector(
  bannerMessagesSelector,
  (bannerMessages) =>
    bannerMessages.filter(
      (message) => !get(message, 'meta.can_not_dismiss', false),
    ),
)

export const notDismissedBannerMessagesSelector = createSelector(
  commonAppMessageSelector,
  dismissableBannerMessagesSelector,
  ({dismissedAppMessageIDs}, bannerMessages) =>
    bannerMessages.filter(
      (bannerMessage) => !dismissedAppMessageIDs.includes(bannerMessage.id),
    ),
)

export const bannerMessageSelector = createSelector(
  notDismissedBannerMessagesSelector,
  (bannerMessages) => bannerMessages[0],
)

export const showBannerMessageSelector = createSelector(
  bannerMessageSelector,
  (bannerMessage) => !!bannerMessage,
)

export const undismissableBannerMessageSelector = createSelector(
  undismissableBannerMessagesSelector,
  (bannerMessages) => bannerMessages[0],
)

export const showUndismissableBannerMessageSelector = createSelector(
  undismissableBannerMessageSelector,
  (bannerMessage) => !!bannerMessage,
)
