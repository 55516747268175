import PropTypes from 'prop-types'

export default function ItemValue({children}) {
  return (
    <dd className="list__item--product-warehouse list__item--line-qty">
      <span>{children}</span>
    </dd>
  )
}

ItemValue.propTypes = {
  children: PropTypes.node,
}
