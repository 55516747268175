import groupBy from 'lodash/groupBy.js'

import api from '../../../common/api.js'
import {
  TASK_ORDER,
  VISIBLE_ACTIVITY_COUNT,
} from '../../../common/constants/Tasks.js'
import {showGlobalError} from '../../../ordoro/GlobalErrorMessage.js'
import {dispatch} from '../../../store.js'
import {checkRunningTasks} from './isRunningTasks.js'

export const BASE = 'ordoro/data/tasks'
export const SET_TASK = `${BASE}/SET_TASK`
export const UPDATE_TASKS = `${BASE}/UPDATE_TASKS`

export function setTask(task) {
  dispatch({
    type: SET_TASK,
    payload: task,
  })
}

export function updateTasks(tasks) {
  dispatch({
    type: UPDATE_TASKS,
    payload: tasks,
  })
}

export async function getTasks() {
  const {json} = await api.get('/activity/', {limit: VISIBLE_ACTIVITY_COUNT})

  updateTasks(json.activity)
}

export async function getExports() {
  const {json} = await api.get('/new_task/', {
    limit: VISIBLE_ACTIVITY_COUNT,
    table_key: 'export_task',
  })

  updateTasks(json.task)
}

export async function getAllTasks() {
  try {
    await Promise.all([getTasks(), getExports()])
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error loading tasks.',
        details: err.message,
      },
      err,
    )
  }
}

export async function createTask(type, cartID, params) {
  const {json} = await api.post('/task/', {
    type,
    cart: cartID,
    params,
  })

  setTask(json)

  return json
}

export async function createNewTask(type, params) {
  const {json} = await api.post('/new_task/', {
    type,
    params: params || {},
  })

  setTask(json)

  return json
}

export async function createTasks(tasks) {
  const sortedTasks = sortTasks(tasks)

  await Promise.all(
    sortedTasks.map((task) => createTask(task.type, task.cart, task.params)),
  )

  dispatch(checkRunningTasks())
}

export async function createNewTasks(tasks) {
  const jsons = await Promise.all(
    tasks.map((task) => createNewTask(task.type, task.params)),
  )

  dispatch(checkRunningTasks())

  return jsons
}

function sortTasks(unsortedTasks) {
  if (unsortedTasks.length < 2) {
    return unsortedTasks
  }

  let sortedTasks = []

  const groupedTasks = groupBy(unsortedTasks, 'type')

  TASK_ORDER.forEach((type) => {
    const tasks = groupedTasks[type]
    if (tasks) {
      sortedTasks = sortedTasks.concat(tasks)
    }
    delete groupedTasks[type]
  })

  Object.keys(groupedTasks).forEach((type) => {
    const tasks = groupedTasks[type]
    if (tasks) {
      sortedTasks = sortedTasks.concat(tasks)
    }
  })

  return sortedTasks
}
