import PropTypes from 'prop-types'

export default function ButtonRefresh({onClick}) {
  return (
    <button
      className="btn btn--link no-underline meta-orderlistheader-button-refreshorderlist lh-0"
      type="button"
      onClick={onClick}
    >
      <span
        className="i-refresh fs-01 lh-0 v-align-middle margin-left-3"
        aria-hidden="true"
      />
    </button>
  )
}

ButtonRefresh.propTypes = {
  onClick: PropTypes.func.isRequired,
}
