import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  shipperVendorConfigPropertySelector,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {etdPreshipmentDocsPropertyFunc} from '../Fields/ETDPreshipmentDocs.js'
import {isEqual} from 'lodash'
import Zelect from '../../../common/components/Zelect.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import apiverson from '../../../common/apiverson.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'

const MODAL_FORM = 'ETD_PRESHIPMENT_DOCS_MODAL'

const DOCUMENT_TYPE_OPTIONS = [
  {value: 'CERTIFICATE_OF_ORIGIN', display: 'Certificate of Origin'},
  {value: 'COMMERCIAL_INVOICE', display: 'Commercial Invoice'},
  {value: 'OTHER', display: 'Other'},
  {value: 'PRO_FORMA_INVOICE', display: 'Pro Forma Invoice'},
]
const DOCUMENT_TYPE_BY_VALUE = DOCUMENT_TYPE_OPTIONS.reduce(
  (prev, {value, display}) => {
    prev[value] = display
    return prev
  },
  {},
)

export function showETDPreshipmentDocsModal(labelInfoID, shipperType) {
  const labelConfig = labelConfigSelector(getState(), {
    labelInfoID,
  })
  // temp_use_fedex_auth
  const [, shipper] = shipperVendorConfigPropertySelector(getState(), {
    labelInfoID,
    shipperType,
    property: 'child_key',
  })

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    shipper_id: shipper.id,
    attachment: '',
    document_content_type: '',
    filename: '',
    document_type: DOCUMENT_TYPE_OPTIONS[0].value,
    docs: labelConfig[etdPreshipmentDocsPropertyFunc(shipperType)] || [],
    isSaving: false,
    isUploading: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function isValidFile(file) {
  return [
    'application/pdf',
    'application/x-soffice',
    'application/doc',
    'text/richtext',
    'text/rtf',
    'application/x-rtf',
    'application/rtf',
    'application/msword',
    'text/plain',
    'image/bmp',
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/tiff',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ].includes(file.type)
}

export async function handleFiles(event) {
  const files = event.target.files

  if (files.length === 0) {
    return
  }

  try {
    updateModalForm({
      serverError: null,
      isUploading: true,
    })

    if (files.length > 1) {
      throw new Error('Only one file allowed at a time.')
    }

    const file = files[0]

    if (!isValidFile(file)) {
      throw new Error(
        `The file type is invalid. We accept PDF, Word, Excel, Text and Image files. You gave us: ${file.type}.`,
      )
    }

    var reader = new FileReader()

    // We need to hold off the next step a bit to let the browser render
    // the waiting graphics, because we could be dumping a large amount
    // of data into the DOM that will prevent other graphics from rendering
    setTimeout(() => {
      reader.readAsDataURL(file)
    }, 50)

    const attachment = await new Promise((resolve) => {
      reader.onload = (event) => {
        resolve(event.target.result.split(',')[1] || '')
      }
    })

    updateModalForm({
      attachment,
      document_content_type: file.type,
      filename: file.name,
      isUploading: false,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isUploading: false,
    })
  }
}

async function upload() {
  try {
    updateModalForm({
      serverError: null,
      isUploading: true,
    })

    const {
      labelInfoID,
      shipper_id,
      attachment,
      document_content_type,
      filename,
      document_type,
    } = modalFormSelector(getState())

    const shipFrom = labelShipFromAddressSelector(getState(), {labelInfoID})
    const shipTo = labelShipToAddressSelector(getState(), {labelInfoID})
    const labelConfig = labelConfigSelector(getState(), {labelInfoID})

    const params = {
      attachment,
      document_content_type,
      filename,
      document_type,
      ship_date: labelConfig.ship_date,
      shipper_id,
      ship_from_country: shipFrom.country,
      ship_to_country: shipTo.country,
    }

    const {json} = await apiverson.post('/fedex_upload_document', params)

    const {docs} = modalFormSelector(getState())

    updateModalForm({
      attachment: '',
      document_content_type: '',
      filename: '',
      docs: [...docs, {filename, ...json}],
      isUploading: false,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isUploading: false,
    })
  }
}

function removeDocument(id) {
  const {docs} = modalFormSelector(getState())

  updateModalForm({
    docs: docs.filter((document) => document.id !== id),
  })
}

function updatedParamsSelector(state) {
  const form = modalFormSelector(state)
  const {shipperType} = form
  const labelConfig = labelConfigSelector(state, {
    labelInfoID: form.labelInfoID,
  })
  const labelProperty = etdPreshipmentDocsPropertyFunc(shipperType)

  const updates = {}

  if (!isEqual(labelConfig[labelProperty], form.docs)) {
    updates[labelProperty] = form.docs
  }

  return updates
}

export async function updateETDPreshipmentDocs() {
  try {
    const form = modalFormSelector(getState())
    const params = updatedParamsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    if (Object.keys(params).length) {
      updateLabelConfig(form.labelInfoID, params)

      await getRates([form.labelInfoID], {justSaveLabelConfig: true})

      showMessageToast('Updated Preshipment Documents')
    }

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ETDPreshipmentDocsModal({form}) {
  return (
    <ConfirmModal
      title="Edit Preshipment Documents"
      onConfirm={() => updateETDPreshipmentDocs()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      showTryAgain={false}
    >
      {form.isUploading ? (
        <div className="loading loading--hold-at-location align-center">
          <span className="spinner--md animate-spin v-align-middle" />
          <em className="inline-block v-align-middle fs-01 op-75 margin-left-5">
            Uploading document...
          </em>
        </div>
      ) : (
        <ul className="list list--no-style">
          <li className="list__item list__item--form">
            <label
              className="file-upload-wrapper btn btn--primary btn--primary-ol btn--sm make-inline-block"
              htmlFor="file_upload_input"
            >
              <span
                className="icon icon-upload fs-01 v-align-middle margin-right-3"
                aria-hidden="true"
              />
              <span>Select a file from your computer</span>
              <input
                type="file"
                name="files[]"
                id="file_upload_input"
                onChange={handleFiles}
              />
            </label>
          </li>
          {form.attachment && (
            <>
              <li className="list__item list__item--form divider--top lg">
                <strong>File Name:</strong>
                <div className="fs-01 lh-md">{form.filename}</div>
              </li>
              <li className="list__item list__item--form w-50">
                <Zelect
                  label="Type of Document"
                  value={form.document_type}
                  onChange={(option) =>
                    updateModalForm({document_type: option.value})
                  }
                  options={DOCUMENT_TYPE_OPTIONS}
                />
              </li>
              <li className="list__item list__item--form">
                <ButtonPrimary alt size="x-sm" onClick={() => upload()}>
                  Finish Uploading File
                </ButtonPrimary>
              </li>
            </>
          )}
        </ul>
      )}
      {form.docs.length > 0 && (
        <table className="table fs-00 margin-top-30">
          <thead>
            <tr>
              <th className="table__th table__th--md align-left">File Name</th>
              <th className="table__th table__th--md">Document Type</th>
              <th className="table__th table__th--md padding-right-0">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody table__tbody--lines">
            {form.docs.map(({id, filename, document_type}) => (
              <tr key={id}>
                <td className="table__td table__td--md">
                  {filename || <em>not provided</em>}
                </td>
                <td className="table__td table__td--md">
                  {DOCUMENT_TYPE_BY_VALUE[document_type] || (
                    <em>not provided</em>
                  )}
                </td>
                <td className="table__td table__td--md align-right padding-right-0">
                  <ButtonLink
                    className="no-underline"
                    title="Remove Document"
                    onClick={() => removeDocument(id)}
                  >
                    <span className="i-trash fs-00" aria-hidden="true"></span>
                  </ButtonLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </ConfirmModal>
  )
}

ETDPreshipmentDocsModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    document_type: PropTypes.string.isRequired,
    attachment: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        filename: PropTypes.string,
        document_type: PropTypes.string.isRequired,
      }),
    ),
    isUploading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ETDPreshipmentDocsModal, modalFormSelector)
