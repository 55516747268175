import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {commonDefaultSupplierForOrderNumbersSelector} from '../../../../data/orders.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'
import {
  getPackingListName,
  sortedPackingListsByIDSelector,
  defaultPackingListSelector,
} from '../../../../redux/selectors/data/packingLists.js'
import Select from '../../../../common/components/Select.js'
import {
  ManualDropShipFormShape,
  setupManualDropshipForm,
  updateManualDropshipFrom,
  setManualDropshipSupplierID,
  manualDropshipFormSupplierIsFBASelector,
  shippingMappingSelector,
  manualDropshipFormSupplierRoutingChannelSelector,
} from './manualDropshipFormFunctions.js'
import {onlyIfAutoForm, useSelector} from '../../../../store.js'
import {
  COMMUNICATION_EMAIL,
  FBA_SHIPPING_METHODS,
} from '../../../../common/constants/Suppliers.js'

function ManualDropshipForm({orderNumbers, form}) {
  const defaultPackingList = useSelector(defaultPackingListSelector)
  const manualDropshipFormDefaultSupplier = useSelector((state) =>
    commonDefaultSupplierForOrderNumbersSelector(state, {orderNumbers}),
  )
  const packingLists = useSelector(sortedPackingListsByIDSelector)
  const selectedSupplierIsFBA = useSelector(
    manualDropshipFormSupplierIsFBASelector,
  )
  const selectedSupplierRoutingChannel = useSelector(
    manualDropshipFormSupplierRoutingChannelSelector,
  )
  const suppliers = useSelector(suppliersSortedByNameSelector)
  const outcome = useSelector((state) =>
    shippingMappingSelector(state, {
      shippingMethod: form.originalShippingMethod,
    }),
  )

  useEffect(() => {
    if (!form.hasChanged__supplierID) {
      setManualDropshipSupplierID(manualDropshipFormDefaultSupplier.id, {
        initial: true,
      })
    }
  }, [orderNumbers])

  useEffect(() => {
    if (form.packingListID === null && defaultPackingList) {
      updateManualDropshipFrom({packingListID: defaultPackingList.id})
    }
  }, [])

  const shippingMethodSubText =
    outcome.defaultedMethod || outcome.mappedMethod ? (
      form.shippingMethod !== outcome.shippingMethod ? (
        <div className="alert alert--x-sm alert--warning text--warning fs-n1 margin-top-5">
          <em className="fs-n1 lh-sm make-block">
            {outcome.defaultedMethod
              ? 'This will override the default method'
              : `This will override the mapped method for “${form.originalShippingMethod}”`}
          </em>
        </div>
      ) : (
        <div className="fs-n1">
          <em>
            {outcome.defaultedMethod
              ? form.originalShippingMethod
                ? 'This is the default shipping method'
                : 'No method was specified, so the default is being mapped'
              : `This is the mapped method for “${form.originalShippingMethod}”`}
          </em>
        </div>
      )
    ) : null

  const supplierSelect = (
    <li className="list__item--form">
      <Select
        className="w-100"
        labelClassName="label--bold"
        label="Supplier"
        value={form.supplierID || ''}
        onChange={(value) => setManualDropshipSupplierID(Number(value))}
      >
        {suppliers.map((supplier) => (
          <option key={supplier.id} value={supplier.id}>
            {getSupplierName(supplier)}
          </option>
        ))}
      </Select>
    </li>
  )

  if (selectedSupplierIsFBA) {
    return (
      <ul className="list meta-manualdropshipform list--dropship">
        {supplierSelect}
        <li className="list__item--form">
          <label className="label--bold" htmlFor="shippingMethod">
            Shipping Method to be Used
          </label>
          <div className="margin-left-3">
            {FBA_SHIPPING_METHODS.map((value, index) => (
              <label
                key={value}
                className={`inline-block unbold ${
                  index < FBA_SHIPPING_METHODS.length - 1
                    ? 'margin-right-15'
                    : ''
                } margin-bottom-0`}
              >
                <input
                  id="shippingMethod"
                  name="shippingMethod"
                  type="radio"
                  onChange={() =>
                    updateManualDropshipFrom({
                      shippingMethod: value,
                      hasChanged_shipping: true,
                    })
                  }
                  value={value}
                  checked={form.shippingMethod === value}
                />
                <span className="margin-left-5">{value}</span>
              </label>
            ))}
          </div>
          {shippingMethodSubText}
        </li>
      </ul>
    )
  }

  return (
    <ul className="list meta-manualdropshipform list--dropship">
      {supplierSelect}
      {packingLists.length > 1 ? (
        <li className="list__item--form">
          <Select
            className="w-100"
            labelClassName="label--bold"
            label={
              selectedSupplierRoutingChannel === COMMUNICATION_EMAIL
                ? 'Profile for Email and Packing List'
                : 'Profile for Packing List'
            }
            value={form.packingListID || ''}
            onChange={(value) =>
              updateManualDropshipFrom({packingListID: Number(value)})
            }
          >
            {packingLists.map((packingList) => (
              <option key={packingList.id} value={packingList.id}>
                {getPackingListName(packingList)}
              </option>
            ))}
          </Select>
        </li>
      ) : null}
      <li className="list__item--form">
        <label className="label--bold" htmlFor="shippingCarrier">
          Carrier To Be Used
        </label>
        <input
          id="shippingCarrier"
          type="text"
          className="w-100"
          onChange={(event) =>
            updateManualDropshipFrom({
              shippingCarrier: event.target.value,
              hasChanged_shipping: true,
            })
          }
          value={form.shippingCarrier}
        />
        {outcome.defaultedCarrier || outcome.mappedCarrier ? (
          form.shippingCarrier !== outcome.shippingCarrier ? (
            <div className="alert alert--x-sm alert--warning text--warning fs-n1 margin-top-5">
              <em className="fs-n1 lh-sm make-block">
                {outcome.defaultedCarrier
                  ? 'This will override the default carrier'
                  : `This will override the mapped carrier for “${form.originalShippingMethod}”`}
              </em>
            </div>
          ) : (
            <div className="fs-n1">
              <em>
                {outcome.defaultedCarrier
                  ? 'This is the default carrier'
                  : `This is the mapped carrier for “${form.originalShippingMethod}”`}
              </em>
            </div>
          )
        ) : null}
      </li>
      <li className="list__item--form">
        <label className="label--bold" htmlFor="shippingAccount">
          Carrier Account To Be Used
        </label>
        <input
          id="shippingAccount"
          type="text"
          className="w-100"
          onChange={(event) =>
            updateManualDropshipFrom({
              shippingAccount: event.target.value,
              hasChanged_shipping: true,
            })
          }
          value={form.shippingAccount}
        />
        {outcome.defaultedAccount || outcome.mappedAccount ? (
          form.shippingAccount !== outcome.shippingAccount ? (
            <div className="alert alert--x-sm alert--warning text--warning fs-n1 margin-top-5">
              <em className="fs-n1 lh-sm make-block">
                {outcome.defaultedAccount
                  ? 'This will override the default account'
                  : `This will override the mapped account for “${form.originalShippingMethod}”`}
              </em>
            </div>
          ) : (
            <div className="fs-n1">
              <em>
                {outcome.defaultedAccount
                  ? 'This is the default account'
                  : `This is the mapped account for “${form.originalShippingMethod}”`}
              </em>
            </div>
          )
        ) : null}
      </li>
      <li className="list__item--form">
        <label className="label--bold" htmlFor="shippingMethod">
          Shipping Method To Be Used
        </label>
        <input
          id="shippingMethod"
          type="text"
          className="w-100"
          onChange={(event) =>
            updateManualDropshipFrom({
              shippingMethod: event.target.value,
              hasChanged_shipping: true,
            })
          }
          value={form.shippingMethod}
        />
        {shippingMethodSubText}
      </li>
      <li className="list__item--form">
        <label className="label--bold" htmlFor="additionalInstructions">
          Additional Instructions
        </label>
        <textarea
          id="additionalInstructions"
          className="textarea w-100"
          value={form.additionalInstructions}
          onChange={(event) =>
            updateManualDropshipFrom({
              additionalInstructions: event.target.value,
            })
          }
        />
      </li>
      <li className="list--dropship margin-bottom-5">
        <label className="margin-bottom-0" htmlFor="show_ship_to_email">
          <input
            id="show_ship_to_email"
            type="checkbox"
            checked={form.show_ship_to_email}
            onChange={(event) =>
              updateManualDropshipFrom({
                show_ship_to_email: event.target.checked,
              })
            }
          />
          <span className="margin-left-5">
            Show Ship To email in{' '}
            {selectedSupplierRoutingChannel === COMMUNICATION_EMAIL
              ? 'email'
              : 'request'}
          </span>
        </label>
      </li>
      <li className="list--dropship margin-bottom-5">
        <label className="margin-bottom-0" htmlFor="showPrice">
          <input
            id="showPrice"
            type="checkbox"
            checked={form.showPrice}
            onChange={(event) =>
              updateManualDropshipFrom({showPrice: event.target.checked})
            }
          />
          <span className="margin-left-5">
            Show supplier unit cost in{' '}
            {selectedSupplierRoutingChannel === COMMUNICATION_EMAIL
              ? 'email'
              : 'request'}
          </span>
        </label>
      </li>
    </ul>
  )
}

ManualDropshipForm.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: ManualDropShipFormShape.isRequired,
}

export default onlyIfAutoForm(ManualDropshipForm, setupManualDropshipForm)
