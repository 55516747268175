import {createSelector} from 'reselect'
import mapValues from 'lodash/mapValues.js'

import {stringifyURL} from '../../common/querystring.js'
import {isPresent} from '../../common/utils.js'
import {TAG_FILTER_OR} from '../../common/constants/Tags.js'
import {
  RETURN_ORDER_PLURAL_URI_COMPONENT,
  RETURN_ORDER_SINGLE_URI_COMPONENT,
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  ALL_STATUS,
} from '../../common/constants/ReturnOrders.js'
import {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  returnOrdersSelector,
  canUseReturnOrdersSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {returnOrderTagsSortedByNameSelector} from '../../data/returnOrderTags.js'
import {RETURN_ORDER_LIST_FORM} from './returnOrderListActions.js'
import {labelInfosSelector} from '../../data/labelInfos/index.js'

export function returnOrderListFormSelector(state) {
  return state.ui.forms[RETURN_ORDER_LIST_FORM]
}

export const referenceIDListSelector = createSelector(
  returnOrderListFormSelector,
  ({referenceIDList} = {}) => referenceIDList || [],
)

export const searchTextSelector = createSelector(
  returnOrderListFormSelector,
  ({searchText} = {}) => searchText || '',
)

export const isLoadingSelector = createSelector(
  returnOrderListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const statusSelector = createSelector(
  returnOrderListFormSelector,
  ({status} = {}) => status || DEFAULT_STATUS,
)

export const perPageSelector = createSelector(
  returnOrderListFormSelector,
  ({perPage} = {}) => perPage || DEFAULT_PER_PAGE,
)

export const currentPageSelector = createSelector(
  returnOrderListFormSelector,
  ({currentPage} = {}) => currentPage || 0,
)

export const countSelector = createSelector(
  returnOrderListFormSelector,
  ({count} = {}) => count || 0,
)

export const sortSelector = createSelector(
  returnOrderListFormSelector,
  ({sort} = {}) => sort || DEFAULT_SORT,
)

export const returnOrderTagFiltersSelector = createSelector(
  returnOrderListFormSelector,
  ({tags} = {}) => tags || [],
)

export const returnOrderUntaggedFilterSelector = createSelector(
  returnOrderListFormSelector,
  ({untagged} = {}) => untagged || false,
)

export const returnOrderTagFilterBySelector = createSelector(
  returnOrderListFormSelector,
  ({tagFilterBy} = {}) => tagFilterBy || TAG_FILTER_OR,
)

export const expandModeSelector = createSelector(
  returnOrderListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedReferenceIDsSelector = createSelector(
  returnOrderListFormSelector,
  ({expandedReferenceIDs} = {}) => expandedReferenceIDs || [],
)

export const selectedReferenceIDsSelector = createSelector(
  returnOrderListFormSelector,
  ({selectedReferenceIDs} = {}) => selectedReferenceIDs || [],
)

export const allSelectedSelector = createSelector(
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  (referenceIDList, selected) =>
    referenceIDList.length > 0 && referenceIDList.length === selected.length,
)

export const firstSelectedReferenceIDSelector = createSelector(
  selectedReferenceIDsSelector,
  (selectedReferenceIDs) => selectedReferenceIDs[0],
)

function hashBuilder(status, params, canUseReturnOrders) {
  if (!canUseReturnOrders) {
    return null
  }

  let hash = `#/${RETURN_ORDER_PLURAL_URI_COMPONENT}`

  if (status && status !== DEFAULT_STATUS) {
    hash = `${hash}/${status}`
  }

  return stringifyURL(hash, params)
}

export const hashParamsSelector = createSelector(
  returnOrderListFormSelector,
  ({
    searchText,
    sort,
    tags,
    untagged,
    tagFilterBy,
    currentPage,
    perPage,
  } = {}) => ({
    page: currentPage > 1 ? currentPage : undefined,
    perPage: perPage !== DEFAULT_PER_PAGE ? perPage : undefined,
    searchText: searchText || undefined,
    sort: DEFAULT_SORT !== sort ? sort : undefined,
    tags: tags || undefined,
    untagged: untagged || undefined,
    tagFilterBy: tagFilterBy !== TAG_FILTER_OR ? tagFilterBy : undefined,
  }),
)

export const returnOrderListHashSelector = createSelector(
  statusSelector,
  hashParamsSelector,
  canUseReturnOrdersSelector,
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  returnOrderListFormSelector,
  ({perPage, sort} = {}) => ({
    perPage: perPage !== DEFAULT_PER_PAGE ? perPage : undefined,
    sort: sort !== DEFAULT_SORT ? sort : undefined,
  }),
)

export const defaultReturnOrderListHashSelector = createSelector(
  () => DEFAULT_STATUS,
  defaultHashParamsSelector,
  canUseReturnOrdersSelector,
  hashBuilder,
)

export function queryParamsSelector(state) {
  const status = statusSelector(state)
  const {searchText, sort, tags, untagged, tagFilterBy, perPage, currentPage} =
    returnOrderListFormSelector(state) || {}

  return {
    search: searchText,
    status: status !== ALL_STATUS ? status : undefined,
    sort,
    tag: tags,
    untagged,
    tag_filter_by: tagFilterBy !== TAG_FILTER_OR ? tagFilterBy : undefined,
    limit: perPage,
    offset: perPage * (currentPage - 1),
  }
}

export const createIsSelectedSelector = (referenceID) =>
  createSelector(selectedReferenceIDsSelector, (selectedReferenceIDs) => {
    return selectedReferenceIDs.includes(referenceID)
  })

export const createIsExpandedSelector = (referenceID) =>
  createSelector(
    expandedReferenceIDsSelector,
    expandModeSelector,
    (expandedReferenceIDs, expandMode) => {
      if (expandMode === EXPAND_MODE_COLLAPSED) {
        return expandedReferenceIDs.includes(referenceID)
      }
      if (expandMode === EXPAND_MODE_EXPANDED) {
        return !expandedReferenceIDs.includes(referenceID)
      }
      return false
    },
  )

export const createDetailURLSelector = (referenceID) => () =>
  `#/${RETURN_ORDER_SINGLE_URI_COMPONENT}/${encodeURIComponent(referenceID)}`

export const isFilteredSelector = createSelector(
  searchTextSelector,
  returnOrderTagFiltersSelector,
  (searchText, tags) => isPresent(searchText) || tags.length > 0,
)

export const labelInfoIDsThatCanHaveLabelsSelector = createSelector(
  selectedReferenceIDsSelector,
  returnOrdersSelector,
  labelInfosSelector,
  (referenceIDs, returnOrders, labelInfos) =>
    referenceIDs.reduce((prev, referenceID) => {
      const returnOrder = returnOrders[referenceID]
      const labelInfoID = returnOrder.label_infos[0]

      if (
        returnOrder &&
        labelInfoID &&
        labelInfos[labelInfoID] &&
        returnOrder.shipping_infos.length === 0
      ) {
        prev.push(returnOrder.label_infos[0])
      }

      return prev
    }, []),
)

export const referenceIDsThatHaveLabelsSelector = createSelector(
  selectedReferenceIDsSelector,
  returnOrdersSelector,
  (referenceIDs, returnOrders) =>
    referenceIDs.reduce((prev, referenceID) => {
      const returnOrder = returnOrders[referenceID]

      if (returnOrder && returnOrder.shipping_infos.length) {
        prev.push(referenceID)
      }

      return prev
    }, []),
)

export const returnOrderTagQuantityMapSelector = createSelector(
  (state, {referenceIDs}) => referenceIDs,
  returnOrdersSelector,
  returnOrderTagsSortedByNameSelector,
  (referenceIDs, returnOrders, returnOrderTags) => {
    const returnOrderTagQuantityMap = returnOrderTags.reduce(
      (prev, tag) => ({
        ...prev,
        [tag.id]: 0,
      }),
      {},
    )

    referenceIDs.forEach((referenceID) => {
      const returnOrder = returnOrders[referenceID]

      if (!returnOrder) {
        return
      }

      returnOrder.tags.forEach((tag) => {
        returnOrderTagQuantityMap[tag.id] += 1
      })
    })

    return mapValues(returnOrderTagQuantityMap, (selectedQuantity) => {
      const diff = referenceIDs.length - selectedQuantity
      if (diff === 0) {
        return 'all'
      } else if (diff === referenceIDs.length) {
        return 'none'
      }

      return 'some'
    })
  },
)

export const returnOrderTagsWithSelectedStateSelector = createSelector(
  returnOrderTagsSortedByNameSelector,
  returnOrderTagFiltersSelector,
  (returnOrderTags, tagFilters) =>
    returnOrderTags.map((tag) => ({
      ...tag,
      selected: tagFilters.indexOf(tag.name) !== -1,
    })),
)
