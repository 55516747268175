import {useSelector} from '../../store.js'
import {
  PRODUCT_DETAIL_WAREHOUSES_PANEL,
  PRODUCT_DETAIL_SUPPLIERS_PANEL,
  PRODUCT_DETAIL_SALES_CHANNELS_PANEL,
  PRODUCT_DETAIL_KITTING_PANEL,
  PRODUCT_DETAIL_BOM_PANEL,
  PRODUCT_DETAIL_ANALYTICS_PANEL,
} from '../../common/constants/Products.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showEditProductSupplierModal} from '../ProductListPage/Modals/EditProductSupplierModal.js'
import {isKitComponentSelector} from '../../data/directKitParents.js'
import {
  productUnassignedSupplierOptionsSelector,
  isKitSelector,
  isBOMSelector,
} from '../../data/products.js'
import {
  usesInventorySelector,
  hasUseMOsFeatureSelector,
  showFeatureLocksSelector,
  useKittingSelector,
  useAnalyticsSelector,
} from '../../data/company.js'
import {
  productDetailFormSelector,
  skuSelector,
  currentPanelSelector,
} from './productDetailSelectors.js'

import Warehouses from './Warehouses.js'
import Suppliers from './Suppliers.js'
import SuppliersFeaturePage from './SuppliersFeaturePage.js'
import SalesChannels from './SalesChannels.js'
import SalesChannelsFeaturePage from './SalesChannelsFeaturePage.js'
import BOM from './BOM.js'
import BOMFeaturePage from './BOMFeaturePage.js'
import Kitting from './Kitting.js'
import KittingFeaturePage from './KittingFeaturePage.js'
import ProductAnalytics, {
  productAnalyticsDefaultLinkSelector,
} from './ProductAnalytics.js'
import ProductAnalyticsFeaturePage from './ProductAnalyticsFeaturePage.js'
import {showAnalyticsNavSelector} from '../AnalyticsPage/analyticsSelectors.js'
import {hasAnalyticsProductPermissionSelector} from '../../data/me.js'
import {useSuppliersSelector} from '../../data/suppliers.js'

export default function TabbedPanels() {
  const sku = useSelector(skuSelector)
  const currentPanel = useSelector(currentPanelSelector)
  const form = useSelector(productDetailFormSelector)
  const hasUnassignedSuppliers =
    useSelector((state) =>
      productUnassignedSupplierOptionsSelector(state, {
        sku,
      }),
    ).length !== 0
  const usesInventory = useSelector(usesInventorySelector)
  const useKitting = useSelector(useKittingSelector)
  const useSuppliers = useSelector(useSuppliersSelector)
  const hasUseMOsFeature = useSelector(hasUseMOsFeatureSelector)
  const showFeatureLocks = useSelector(showFeatureLocksSelector)
  const useAnalytics = useSelector(useAnalyticsSelector)
  const showAnalyticsNav = useSelector(showAnalyticsNavSelector)
  const hasAnalyticsProductPermission = useSelector(
    hasAnalyticsProductPermissionSelector,
  )
  const isKit = useSelector((state) => isKitSelector(state, {sku}))
  const isKitComponent = useSelector((state) =>
    isKitComponentSelector(state, {sku}),
  )
  const isBOM = useSelector((state) => isBOMSelector(state, {sku}))
  const encodedSKU = encodeURIComponent(sku)
  const productAnalyticsDefaultLink = useSelector(
    productAnalyticsDefaultLinkSelector,
  )

  const showProductAnalyticsNav =
    showAnalyticsNav && hasAnalyticsProductPermission
  const hasBOMFeature = usesInventory && hasUseMOsFeature
  const canAccessBOMTab =
    hasBOMFeature &&
    hasUseMOsFeature &&
    (isBOM || currentPanel === PRODUCT_DETAIL_BOM_PANEL)
  const canAccessKitTab =
    useKitting &&
    (!hasUseMOsFeature ||
      isKit ||
      isKitComponent ||
      currentPanel === PRODUCT_DETAIL_KITTING_PANEL)

  return (
    <>
      <div className="wrap--sub-nav wrap--sub-nav-panel">
        <nav className="list--sub-nav">
          <div className="list__item--sub-nav wider">
            <a
              className={`sub-nav__link fs-01 margin-left-0${
                currentPanel === PRODUCT_DETAIL_WAREHOUSES_PANEL
                  ? ' active'
                  : ''
              }`}
              href={`#/product/${encodedSKU}`}
            >
              Warehouses
            </a>
          </div>
          {(showFeatureLocks || useSuppliers) && (
            <div className="list__item--sub-nav wider">
              {!useSuppliers && (
                <span
                  className="i-lock-closed fs-02 v-align-middle lh-lg no-underline op-30 margin-right-1"
                  aria-hidden="true"
                />
              )}
              <a
                className={`sub-nav__link fs-01${
                  currentPanel === PRODUCT_DETAIL_SUPPLIERS_PANEL
                    ? ' active'
                    : ''
                }
                ${useSuppliers ? '' : 'op-50'}
                `}
                href={`#/product/${encodedSKU}/${PRODUCT_DETAIL_SUPPLIERS_PANEL}`}
              >
                Suppliers
              </a>
            </div>
          )}
          {(showFeatureLocks || usesInventory) && (
            <div className="list__item--sub-nav wider">
              {!usesInventory && (
                <span
                  className="i-lock-closed fs-02 v-align-middle lh-lg no-underline op-30 margin-right-1"
                  aria-hidden="true"
                />
              )}
              <a
                className={`sub-nav__link fs-01${
                  currentPanel === PRODUCT_DETAIL_SALES_CHANNELS_PANEL
                    ? ' active'
                    : ''
                }
                ${usesInventory ? '' : 'op-50'}
                `}
                href={`#/product/${encodedSKU}/${PRODUCT_DETAIL_SALES_CHANNELS_PANEL}`}
              >
                Sales Channels
              </a>
            </div>
          )}
          {((showFeatureLocks && !hasBOMFeature) || canAccessBOMTab) && (
            <div className="list__item--sub-nav wider">
              {!canAccessBOMTab && (
                <span
                  className="i-lock-closed fs-02 v-align-middle lh-lg no-underline op-30 margin-right-1"
                  aria-hidden="true"
                />
              )}
              <a
                className={`sub-nav__link fs-01${
                  currentPanel === PRODUCT_DETAIL_BOM_PANEL ? ' active' : ''
                }
                ${canAccessBOMTab ? '' : 'op-50'}
                `}
                href={`#/product/${encodedSKU}/${PRODUCT_DETAIL_BOM_PANEL}`}
              >
                BOM
              </a>
            </div>
          )}
          {((showFeatureLocks && !useKitting) || canAccessKitTab) && (
            <div className="list__item--sub-nav wider">
              {!canAccessKitTab && (
                <span
                  className="i-lock-closed fs-02 v-align-middle lh-lg no-underline op-30 margin-right-1"
                  aria-hidden="true"
                />
              )}
              <a
                className={`sub-nav__link fs-01${
                  currentPanel === PRODUCT_DETAIL_KITTING_PANEL ? ' active' : ''
                }
                ${canAccessKitTab ? '' : 'op-50'}
                `}
                href={`#/product/${encodedSKU}/${PRODUCT_DETAIL_KITTING_PANEL}`}
              >
                Kitting
              </a>
            </div>
          )}
          {showProductAnalyticsNav && (
            <div className="list__item--sub-nav wider">
              {!useAnalytics && (
                <span
                  className="i-lock-closed fs-02 v-align-middle lh-lg no-underline op-30 margin-right-1"
                  aria-hidden="true"
                />
              )}
              <a
                className={`sub-nav__link fs-01${
                  currentPanel === PRODUCT_DETAIL_ANALYTICS_PANEL
                    ? ' active'
                    : ''
                }
                ${useAnalytics ? '' : 'op-50'}
                `}
                href={
                  currentPanel === PRODUCT_DETAIL_ANALYTICS_PANEL ||
                  !form.productAnalyticsReturnLink
                    ? productAnalyticsDefaultLink
                    : form.productAnalyticsReturnLink
                }
              >
                Analytics
              </a>
            </div>
          )}
        </nav>
        {(hasUnassignedSuppliers || form.selectedSupplierIDs.length === 1) &&
          currentPanel === PRODUCT_DETAIL_SUPPLIERS_PANEL && (
            <ButtonPrimary
              className="margin-bottom-5"
              size="sm"
              isOutlined
              onClick={() =>
                showEditProductSupplierModal(sku, form.selectedSupplierIDs)
              }
            >
              {form.selectedSupplierIDs.length === 0 ? 'Add' : 'Edit'}
              {' Supplier Assignment'}
            </ButtonPrimary>
          )}
      </div>

      <div className="wrap--table-panel">
        {currentPanel === PRODUCT_DETAIL_WAREHOUSES_PANEL ? (
          <Warehouses />
        ) : currentPanel === PRODUCT_DETAIL_SUPPLIERS_PANEL ? (
          useSuppliers ? (
            <Suppliers />
          ) : (
            <SuppliersFeaturePage />
          )
        ) : currentPanel === PRODUCT_DETAIL_SALES_CHANNELS_PANEL ? (
          usesInventory ? (
            <SalesChannels />
          ) : (
            <SalesChannelsFeaturePage />
          )
        ) : currentPanel === PRODUCT_DETAIL_BOM_PANEL ? (
          canAccessBOMTab ? (
            <BOM />
          ) : (
            <BOMFeaturePage />
          )
        ) : currentPanel === PRODUCT_DETAIL_KITTING_PANEL ? (
          canAccessKitTab ? (
            <Kitting />
          ) : (
            <KittingFeaturePage />
          )
        ) : currentPanel === PRODUCT_DETAIL_ANALYTICS_PANEL ? (
          !showProductAnalyticsNav ? null : useAnalytics ? (
            <ProductAnalytics />
          ) : (
            <ProductAnalyticsFeaturePage />
          )
        ) : null}
      </div>
    </>
  )
}
