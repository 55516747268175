import PropTypes from 'prop-types'
import startCase from 'lodash/startCase.js'

import {ScaleShape} from '../../../common/PropTypes.js'

export default function Scale({scale, checked, onChange}) {
  return (
    <tr>
      <td className="table__td">
        <input
          className="margin-bottom-0 margin-right-5"
          type="radio"
          id={`scale-${scale.id}`}
          key={scale.id}
          checked={checked}
          onChange={() => onChange(scale.id)}
        />
      </td>
      <td className="table__td">
        <label htmlFor={`scale-${scale.id}`} className="margin-bottom-0">
          {startCase(scale.product)}
        </label>
      </td>
      <td className="table__td">
        <label
          htmlFor={`scale-${scale.id}`}
          className="unbold-label margin-bottom-0"
        >
          {scale.vendor}
        </label>
      </td>
      <td className="table__td">
        <label
          htmlFor={`scale-${scale.id}`}
          className="unbold-label margin-bottom-0"
        >
          {scale.computerName}
        </label>
      </td>
    </tr>
  )
}

Scale.propTypes = {
  scale: ScaleShape.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
