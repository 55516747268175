import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {
  isArchivedSelector,
  canArchiveSelector,
} from '../../../../data/shippers.js'
import {showArchiveShipperModal} from '../ArchiveShipperModal.js'

function Archive({shipperID, isArchived, canArchive, ...props}) {
  if (!canArchive) {
    return null
  }

  return (
    <dl className="list">
      <dt className="list__title fs-00 lh-md margin-bottom-5">
        {isArchived ? 'Unarchive' : 'Archive'} Account
      </dt>
      {isArchived ? (
        <dd className="list__item fs-n0 margin-bottom-10">
          Unarchiving this carrier account will give you access again to its
          shipping rates on the Orders page.
        </dd>
      ) : (
        <dd className="list__item fs-n0 margin-bottom-10">
          If you’d like to hide this carrier account from appearing on the
          Orders page, we recommend archiving it. You can always bring it back
          by unarchiving it.
        </dd>
      )}
      <dd className="list__item fs-n0 margin-bottom-0">
        <ButtonPrimary
          size="sm"
          isOutlined
          onClick={() => props.showArchiveShipperModal(shipperID)}
        >
          {isArchived ? 'Unarchive' : 'Archive'}
        </ButtonPrimary>
      </dd>
    </dl>
  )
}

Archive.propTypes = {
  shipperID: PropTypes.number.isRequired,
  isArchived: PropTypes.bool.isRequired,
  canArchive: PropTypes.bool.isRequired,
  showArchiveShipperModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {shipperID}) {
  return {
    isArchived: isArchivedSelector(state, {shipperID}),
    canArchive: canArchiveSelector(state, {shipperID}),
  }
}

const mapDispatchToProps = {
  showArchiveShipperModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Archive)
