import {OrderShape} from '../../../../../../common/PropTypes.js'
import ShipDate from '../../Fields/ShipDate.js'

export default function DropshipmentRequestedStatus({order}) {
  return (
    <div>
      <div className="fs-n1 text--md-grey">
        <strong>DS Requested:</strong>
      </div>
      <div className="fs-n1">
        <ShipDate date={order.dropshipping_info.requested_date} />
      </div>
    </div>
  )
}

DropshipmentRequestedStatus.propTypes = {
  order: OrderShape.isRequired,
}
