import {useEffect} from 'react'

import {useSelector, dispatch} from '../../store.js'
import {WELCOME_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {WELCOME_URI_COMPONENT} from '../../common/constants/index.js'
import {hasWelcomeTabSelector} from '../Header/headerSelectors.js'

export default function WelcomeLocationListener() {
  const {
    pathComponents: [resource],
  } = useSelector(locationSelector)
  const hasWelcomeTab = useSelector(hasWelcomeTabSelector)

  // if no route is supplied then they land on welcome page (if they want the welcome tab)
  const isThisPage =
    resource === WELCOME_URI_COMPONENT || (hasWelcomeTab && !resource)

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    dispatch(setCurrentPage(WELCOME_PAGE))
  }, [isThisPage])

  return null
}
