import {useEffect} from 'react'
import classNames from 'classnames'
import debounce from 'lodash/debounce.js'

import {showImporterOfRecordModal} from '../../OrderListPage/Modals/ImporterOfRecordModal.js'
import {
  updateLabelConfig,
  saveLabelInfo,
  attachCustomsInfoSelector,
  labelConfigSelector,
  importerOfRecordErrorsSelector,
  onLabelPropertyValueChange,
} from '../../../data/labelInfos/index.js'
import {getState, useSelector} from '../../../store.js'
import EnableLabelProperty from '../Fields/EnableLabelProperty.js'
import {FEDEX} from '../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_ATTACH_CUSTOMS_INFO} from './AttachCustomsInfo.js'
import useLabelProperty from '../Fields/useLabelProperty.js'
import {LABEL_PROPERTY_SHIPPER_ID} from '../Fields/ShipperSelect/index.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const debouncedSaveLabelInfo = debounce(saveLabelInfo, 500)

export const LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD =
  'include_importer_of_record'
export const LABEL_PROPERTY_IMPORTER_OF_RECORD = 'importer_of_record'
const VALID_INCLUDE_IMPORTER_OF_RECORD_LABEL_PROPERTIES = [
  LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD,
]
const VALID_IMPORTER_OF_RECORD_LABEL_PROPERTIES = [
  LABEL_PROPERTY_IMPORTER_OF_RECORD,
]
const VALID_SHIPPER_TYPES = [FEDEX]

function includeImporterOfRecordLabelPropertiesFunc(shipperType, labelInfoID) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  return VALID_SHIPPER_TYPES.includes(shipperType) &&
    labelConfig[LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
    ? [LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD]
    : []
}

function includeImporterOfRecordDependentPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID, LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
}

function importerOfRecordLabelPropertiesFunc(shipperType, labelInfoID) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  return VALID_SHIPPER_TYPES.includes(shipperType) &&
    labelConfig[LABEL_PROPERTY_ATTACH_CUSTOMS_INFO] &&
    labelConfig[LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD]
    ? [LABEL_PROPERTY_IMPORTER_OF_RECORD]
    : []
}

function importerOfRecordDependantPropertiesFunc() {
  return [
    LABEL_PROPERTY_SHIPPER_ID,
    LABEL_PROPERTY_ATTACH_CUSTOMS_INFO,
    LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD,
  ]
}

export default function IncludeImporterOfRecord() {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()
  const attachCustomsInfo = useSelector(
    (state) => attachCustomsInfoSelector(state, {labelInfoID}) || false,
  )
  const {include_importer_of_record = false, importer_of_record} = useSelector(
    (state) =>
      labelConfigSelector(state, {labelInfoID}) || {
        include_importer_of_record: false,
      },
  )
  const errors = useSelector((state) =>
    importerOfRecordErrorsSelector(state, {labelInfoID, shipperType}),
  )

  // include importer of record
  const {isLabelPropertyEnabled} = useLabelProperty({
    labelInfoID,
    labelProperty: LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD,
    validLabelProperties: VALID_INCLUDE_IMPORTER_OF_RECORD_LABEL_PROPERTIES,
    validShipperTypes: VALID_SHIPPER_TYPES,
    labelPropertiesFunc: includeImporterOfRecordLabelPropertiesFunc,
    dependantPropertiesFunc: includeImporterOfRecordDependentPropertiesFunc,
  })

  // importer of record
  useLabelProperty({
    labelProperty: LABEL_PROPERTY_IMPORTER_OF_RECORD,
    validLabelProperties: VALID_IMPORTER_OF_RECORD_LABEL_PROPERTIES,
    validShipperTypes: VALID_SHIPPER_TYPES,
    labelPropertiesFunc: importerOfRecordLabelPropertiesFunc,
    dependantPropertiesFunc: importerOfRecordDependantPropertiesFunc,
  })

  useEffect(() => {
    onLabelPropertyValueChange(
      labelInfoID,
      shipperType,
      LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD,
    )
  }, [include_importer_of_record])

  if (!attachCustomsInfo || !VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  const hasErrors = Object.keys(errors).length > 0

  return (
    <>
      <li className="list__item--shipping-options margin-bottom-15">
        <label className="inline-block margin-right-5 margin-bottom-0">
          <input
            type="checkbox"
            className="margin-right-5"
            checked={!!include_importer_of_record}
            onChange={(event) => {
              updateLabelConfig(labelInfoID, {
                [LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD]:
                  event.target.checked,
              })

              debouncedSaveLabelInfo(labelInfoID)
            }}
            disabled={isLabelEnableMode && !isLabelPropertyEnabled}
          />
          <span>Importer of Record is NOT Recipient</span>
        </label>
        {include_importer_of_record && (
          <button
            className={classNames(
              'btn btn--link inline-block fs-n1 v-align-base',
              {error: hasErrors},
            )}
            type="button"
            onClick={() => showImporterOfRecordModal(labelInfoID, shipperType)}
            disabled={isLabelEnableMode && !isLabelPropertyEnabled}
          >
            Edit
          </button>
        )}
        <EnableLabelProperty
          labelProperty={LABEL_PROPERTY_INCLUDE_IMPORTER_OF_RECORD}
        />
      </li>
      {isLabelEnableMode &&
        isLabelPropertyEnabled &&
        include_importer_of_record && (
          <li className="list__item--shipping-options margin-bottom-15">
            <div>
              {(importer_of_record &&
                `${
                  importer_of_record.address.company ||
                  importer_of_record.address.name ||
                  ''
                }${
                  importer_of_record.tin.number
                    ? ` (Tax ID#: ${importer_of_record.tin.number})`
                    : ''
                }`) ||
                'Not Setup'}
            </div>
            <EnableLabelProperty
              labelProperty={LABEL_PROPERTY_IMPORTER_OF_RECORD}
            />
          </li>
        )}
    </>
  )
}
