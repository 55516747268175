import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import {canAddCartsSelector} from '../../../data/carts.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import NewVendorSelect from './NewVendorSelect.js'
import Buttons from './Buttons.js'
import Vendors from './Vendors/index.js'
import {updateSalesChannelsForm} from './salesChannelsActions.js'
import {
  salesChannelsFormSelector,
  settingsSalesChannelAddSelector,
} from './salesChannelsSelectors.js'
import {maxCartCountSelector} from '../../../data/company.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'

export default function NewCart() {
  const {newVendor} = useSelector(salesChannelsFormSelector)
  const isAdding = useSelector(settingsSalesChannelAddSelector)
  const maxCartCount = useSelector(maxCartCountSelector)
  const canAddCarts = useSelector(canAddCartsSelector)

  const isExpanded = isAdding && canAddCarts

  const formName = `SETTINGS_SALES_CHANNELS_ADD_${newVendor}`

  return (
    <li className={classNames('list__item--settings', {expanded: isExpanded})}>
      <div className="wrap--row">
        <div className="medium-12 columns">
          {canAddCarts ? (
            <p>
              <a
                href={`${SALES_CHANNEL_SETTINGS_LINK}/add`}
                title="Add a sales channel"
                className="btn btn--link fs-01 no-underline"
              >
                <span
                  className="icon icon-add icon-block x-sm margin-right-5"
                  aria-hidden="true"
                />
                <span>Add a sales channel</span>
              </a>
            </p>
          ) : (
            <div className="margin-top-15 align-center">
              <p className="margin-bottom-10">
                <strong>
                  <PluralBlock count={maxCartCount}>
                    Want to add more than <Count options={{toEnglish: true}} />{' '}
                    <Plural word="sales channel" />?
                  </PluralBlock>
                </strong>
              </p>
              <button
                className="btn btn--primary btn--primary-alt btn--md fs-01"
                onClick={() => showUpgradeModal()}
              >
                <strong>Upgrade Your Plan</strong>
              </button>
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="wrap--row">
          <div className="medium-4 columns">
            <NewVendorSelect
              value={newVendor}
              className="w-100"
              onChange={(newVendor) => updateSalesChannelsForm({newVendor})}
            />
          </div>
        </div>
      )}
      {isExpanded &&
        (() => {
          const Vendor = Vendors[newVendor]

          if (!Vendor) {
            return null
          }

          return (
            <>
              <Vendor formName={formName} cartID={NEW_ID} />
              <Buttons formName={formName} />
            </>
          )
        })()}
    </li>
  )
}
