import PropTypes from 'prop-types'
import format from 'date-fns/format'
import get from 'lodash/get.js'
import round from 'lodash/round.js'

import {DateShape, AddressShape, addressProps} from '../../common/PropTypes.js'
import {TAG_TYPE__ORDER} from '../../common/constants/Tags.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {getRealDate} from '../../common/date.js'
import delay from '../../common/delay.js'
import apiverson from '../../common/apiverson.js'
import {getState, setForm, updateForm, removeForm} from '../../store.js'
import {
  setOrderStateAndEnsureProductsLoaded,
  ensureOrdersLoaded,
  addOrderComment,
  getIsAnUnsplitOrder,
} from '../../data/orders.js'
import {navigate} from '../../common/location.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {refreshOrderListAndCounts} from '../OrderListPage/orderListActions.js'

import {
  CREATE_ORDER_PAGE_FORM,
  createOrderFormSelector,
  cloneFromOrderNumberSelector,
  createOrderPayloadSelector,
} from './createOrderSelectors.js'
import {orderListHashBuilder} from '../OrderListPage/orderListSelectors.js'

export const CreateOrderPageFormShape = PropTypes.shape({
  isCreatingOrder: PropTypes.bool.isRequired,
  orderInfo: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    shippingMethod: PropTypes.string.isRequired,
    customerNotes: PropTypes.string.isRequired,
    internalNotes: PropTypes.string.isRequired,
    orderDate: DateShape.isRequired,
  }).isRequired,
  productInfo: PropTypes.shape({
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        quantity: PropTypes.string.isRequired,
        itemPrice: PropTypes.string.isRequired,
        discountAmount: PropTypes.string.isRequired,
        totalPrice: PropTypes.string.isRequired,
        details: PropTypes.string.isRequired,
        product_serial_numbers: PropTypes.string.isRequired,
      }),
    ).isRequired,
    discountAmount: PropTypes.string.isRequired,
    shippingAmount: PropTypes.string.isRequired,
    taxAmount: PropTypes.string.isRequired,
  }),
  shippingInfo: AddressShape.isRequired,
  billingInfo: PropTypes.shape({
    isSameAsShipping: PropTypes.bool.isRequired,
    ...addressProps,
  }).isRequired,
})

export async function setupCreateOrderForm() {
  const cloneFromOrderNumber = cloneFromOrderNumberSelector(getState())

  const form =
    (cloneFromOrderNumber &&
      (await setupAsCloneOfOrder(cloneFromOrderNumber))) ||
    setupAsNewOrder()

  setForm(CREATE_ORDER_PAGE_FORM, {
    isCreatingOrder: false,
    orderInfo: {
      orderNumber: '',
      shippingMethod: '',
      customerNotes: '',
      internalNotes: '',
      orderDate: '',
      tags: [],
      ...form.orderInfo,
    },
    productInfo: {
      lines: [],
      discountAmount: '0',
      shippingAmount: '0',
      taxAmount: '0',
      ...form.productInfo,
    },
    shippingInfo: {
      name: '',
      company: '',
      email: '',
      phone: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      ...form.shippingInfo,
    },
    billingInfo: {
      isSameAsShipping: false,
      name: '',
      company: '',
      email: '',
      phone: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      ...form.billingInfo,
    },
  })

  await checkOrderNumber()
}

export function updateCreateOrderForm(updates) {
  updateForm(CREATE_ORDER_PAGE_FORM, updates)
}

export function updateCreateOrderFormOrderInfo(updates) {
  const form = createOrderFormSelector(getState()) || {}

  updateCreateOrderForm({
    orderInfo: {...form.orderInfo, ...updates},
  })
}

export function updateCreateOrderFormProductInfo(updates) {
  const form = createOrderFormSelector(getState()) || {}

  updateCreateOrderForm({
    productInfo: {...form.productInfo, ...updates},
  })
}

export function updateCreateOrderFormProductInfoLine(index, updates) {
  const form = createOrderFormSelector(getState()) || {}
  const lines = [...form.productInfo.lines]

  lines[index] = {...lines[index], ...updates}

  updateCreateOrderFormProductInfo({lines})
}

export function addCreateOrderFormProductInfoLine(sku) {
  sku = sku || null

  const {
    productInfo: {lines},
  } = createOrderFormSelector(getState())

  updateCreateOrderFormProductInfo({
    lines: [
      ...lines,
      {
        sku,
        details: '',
        product_serial_numbers: '',
        quantity: '1',
        itemPrice: '0',
        discountAmount: '0',
        totalPrice: '0',
      },
    ],
  })
}

export function initializeCreateOrderFormProductInfoLine(index, product) {
  updateCreateOrderFormProductInfoLine(index, {
    sku: product.sku,
    details: '',
    product_serial_numbers: '',
    quantity: '1',
    itemPrice: String(round(product.price, 3)),
    discountAmount: '0',
    totalPrice: product.price.toFixed(2),
  })
}

export function updateCreateOrderLineQuantity(index, quantityString) {
  const {
    productInfo: {lines},
  } = createOrderFormSelector(getState())
  const line = lines[index]
  const updates = {quantity: quantityString}
  const quantity = Number(quantityString)
  const itemPrice = Number(line.itemPrice)

  if (!isNaN(itemPrice) && !isNaN(quantity)) {
    updates.totalPrice = (quantity * itemPrice).toFixed(2)
  }

  updateCreateOrderFormProductInfoLine(index, updates)
}

export function updateCreateOrderLineItemPrice(index, itemPriceString) {
  const {
    productInfo: {lines},
  } = createOrderFormSelector(getState())
  const line = lines[index]
  const updates = {itemPrice: itemPriceString}
  const itemPrice = Number(itemPriceString)
  const quantity = Number(line.quantity)

  if (!isNaN(line.quantity) && !isNaN(itemPrice)) {
    updates.totalPrice = (quantity * itemPrice).toFixed(2)
  }

  updateCreateOrderFormProductInfoLine(index, updates)
}

export function updateCreateOrderLineTotalPrice(index, totalPriceString) {
  const {
    productInfo: {lines},
  } = createOrderFormSelector(getState())
  const line = lines[index]
  const updates = {totalPrice: totalPriceString}
  const totalPrice = Number(totalPriceString)
  const quantity = Number(line.quantity)

  if (quantity > 0 && !isNaN(totalPrice)) {
    updates.itemPrice = String(round(totalPrice / quantity, 3))
  }

  updateCreateOrderFormProductInfoLine(index, updates)
}

export function removeCreateOrderFormProductInfoLine(index) {
  let {
    productInfo: {lines},
  } = createOrderFormSelector(getState())

  lines = lines.filter((_, i) => i !== index)

  updateCreateOrderFormProductInfo({lines})
}

export function updateCreateOrderFormShippingInfo(updates) {
  const form = createOrderFormSelector(getState())

  updateCreateOrderForm({
    shippingInfo: {...form.shippingInfo, ...updates},
  })
}

export function updateCreateOrderFormBillingInfo(updates) {
  const form = createOrderFormSelector(getState())

  updateCreateOrderForm({
    billingInfo: {...form.billingInfo, ...updates},
  })
}

export function removeCreateOrderForm() {
  removeForm(CREATE_ORDER_PAGE_FORM)
}

export function toggleTag(tagID, shouldAdd) {
  const {
    orderInfo: {tags},
  } = createOrderFormSelector(getState())

  const newTags = tags.filter((id) => id !== tagID)

  if (shouldAdd) {
    newTags.push(tagID)
  }

  updateCreateOrderFormOrderInfo({tags: newTags})
}

export function createAndApplyTagToNewOrder() {
  showEditTagModal(TAG_TYPE__ORDER, null, {
    addTagToNewOrder: true,
  })
}

export function setupAsNewOrder() {
  const date = getRealDate()

  return {
    orderInfo: {
      orderNumber: format(date, 'yyyyMMdd-HHmmss'),
      orderDate: date,
    },
  }
}

export async function setupAsCloneOfOrder(cloneFromOrderNumber) {
  const [order] = await ensureOrdersLoaded([cloneFromOrderNumber])

  if (!order) {
    return null
  }

  const date = getRealDate()

  return {
    orderInfo: {
      orderNumber: order.order_number.replace(/^M-/, '').concat('-1'),
      orderDate: date,
      shippingMethod: order.requested_shipping_method || '',
      customerNotes: order.notes_from_customer || '',
      internalNotes: order.internal_notes || '',
      tags: order.tags.map((tag) => tag.id),
    },
    productInfo: {
      lines: order.lines.map((line) => ({
        sku: line.sku,
        name: line.product_name,
        quantity: String(line.quantity),
        itemPrice: String(line.item_price),
        discountAmount: String(line.discount_amount || 0),
        totalPrice: String(line.item_price * line.quantity),
        details: line.details || '',
        product_serial_numbers: '', // assuming cloning serial numbers isn't the right thing
        shippability: get(line, 'shippability.shippability', 'off'),
      })),
      discountAmount: String(order.financial.discount_amount || 0),
      shippingAmount: String(order.financial.shipping_amount || 0),
      taxAmount: String(order.financial.tax_amount || 0),
    },
    shippingInfo: {
      name: order.shipping_address.name || '',
      company: order.shipping_address.company || '',
      email: order.shipping_address.email || '',
      phone: order.shipping_address.phone || '',
      street1: order.shipping_address.street1 || '',
      street2: order.shipping_address.street2 || '',
      city: order.shipping_address.city || '',
      state: order.shipping_address.state || '',
      zip: order.shipping_address.zip || '',
      country: order.shipping_address.country || '',
    },
    billingInfo: {
      name: order.billing_address.name || '',
      company: order.billing_address.company || '',
      email: order.billing_address.email || '',
      phone: order.billing_address.phone || '',
      street1: order.billing_address.street1 || '',
      street2: order.billing_address.street2 || '',
      city: order.billing_address.city || '',
      state: order.billing_address.state || '',
      zip: order.billing_address.zip || '',
      country: order.billing_address.country || '',
    },
  }
}

export function goToCreateOrderPage(query) {
  return navigate(['createorder'], query)
}

let checkOrderNumberRequestToken
export async function checkOrderNumber() {
  const requestToken = (checkOrderNumberRequestToken = {})

  const {
    orderInfo: {orderNumber},
  } = createOrderFormSelector(getState())

  if (!orderNumber) {
    updateCreateOrderFormOrderInfo({orderNumberIsDuplicated: false})

    return
  }

  const orderURL = `/order/M-${encodeURIComponent(orderNumber)}`

  try {
    await apiverson.get(orderURL)

    if (requestToken !== checkOrderNumberRequestToken) {
      return
    }

    updateCreateOrderFormOrderInfo({orderNumberIsDuplicated: true})
  } catch (err) {
    if (err.response && err.response.status === 404) {
      updateCreateOrderFormOrderInfo({orderNumberIsDuplicated: false})
    } else {
      showGlobalError(
        {
          summary: 'Error checking order number.',
          details: err.message,
        },
        err,
      )
    }
  }
}

export async function waitForOrderToBeCreated(orderNumber, INTERVAL = 1000) {
  // We don't have a guarantee that an order is created once POST /order returns a 201.
  // The only way to be sure its created is to wait until GET /order/:id returns the order.
  // This is because tasks like add to elasticsearch, validate address, etc. need to process first.
  let orderIsReady = false

  while (!orderIsReady) {
    try {
      const response = await apiverson.get(
        `/order/parent/${encodeURIComponent(orderNumber)}`,
      )

      let parentOrder = response.json
      orderIsReady = get(parentOrder, 'ready', false)

      if (orderIsReady) {
        const childOrderNumber = parentOrder.child_order_numbers[0]
        const {json: childOrder} = await apiverson.get(
          `/order/${encodeURIComponent(childOrderNumber)}`,
        )
        return childOrder
      }
    } catch (err) {
      if (err.response && err.response.status !== 404) {
        throw err
      }
    }

    if (!orderIsReady) {
      await delay(INTERVAL)
    }
  }

  const {json: order} = await apiverson.get(
    `/order/${encodeURIComponent(orderNumber)}`,
  )

  return order
}

export async function createOrder() {
  const payload = createOrderPayloadSelector(getState())
  const cloneFromOrderNumber = cloneFromOrderNumberSelector(getState())

  updateCreateOrderForm({isCreatingOrder: true})

  try {
    const {
      json: {
        order_number: initialOrderNumber,
        parent_order_number: initialParentOrderNumber,
      },
    } = await apiverson.post('/order/', payload)

    const order = await waitForOrderToBeCreated(initialParentOrderNumber)
    const createdOrderNumber = order.order_number

    await setOrderStateAndEnsureProductsLoaded(order)

    if (cloneFromOrderNumber) {
      await addOrderComment(
        createdOrderNumber,
        `Cloned order from ${cloneFromOrderNumber}`,
      )
    }

    if (getIsAnUnsplitOrder(order)) {
      window.location.hash = `#${order.link}`
    } else {
      window.location.hash = orderListHashBuilder({
        searchText: initialOrderNumber,
      })
    }

    refreshOrderListAndCounts()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error saving order.',
        details: err.message || err.error_message,
      },
      err,
    )
  }

  updateCreateOrderForm({isCreatingOrder: false})
}
