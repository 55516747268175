export const BASE = 'ordoro/ui/modals/endiciaCreditCardModal'
export const SET_IS_SAVING = `${BASE}/SET_IS_SAVING`
export const SET_SHIPPER_ID = `${BASE}/SET_SHIPPER_ID`
export const SET_SERVER_ERROR = `${BASE}/SET_SERVER_ERROR`
export const SET_FORM = `${BASE}/SET_FORM`
export const UPDATE_FORM = `${BASE}/UPDATE_FORM`
export const SHOW_MODAL = `${BASE}/SHOW_MODAL`
export const SAVE_ENDICIA_CREDIT_CARD = `${BASE}/SAVE_ENDICIA_CREDIT_CARD`

export const MODAL_NAME = 'ENDICIA_CREDIT_CARD_MODAL'

export function setIsSaving(isSaving) {
  return {
    type: SET_IS_SAVING,
    payload: isSaving,
  }
}

export function setShipperID(shipperID) {
  return {
    type: SET_SHIPPER_ID,
    payload: shipperID,
  }
}

export function setServerError(serverError) {
  return {
    type: SET_SERVER_ERROR,
    payload: serverError,
  }
}

export function setForm(form) {
  return {
    type: SET_FORM,
    payload: form,
  }
}

export function updateForm(form) {
  return {
    type: UPDATE_FORM,
    payload: form,
  }
}

export function showEndiciaCreditCardModal(shipperID) {
  return {
    type: SHOW_MODAL,
    payload: shipperID,
  }
}

export function saveEndiciaCreditCard() {
  return {
    type: SAVE_ENDICIA_CREDIT_CARD,
  }
}
