import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'

import FormattedDate from '../../common/components/FormattedDate.js'
import {TaskShape} from '../../common/PropTypes.js'

import {showCartSyncModal} from '../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {checkRunningTasks} from '../../redux/actions/data/isRunningTasks.js'
import {allCartsAreWebhookedSelector} from '../../data/carts.js'
import {mostRecentlyUpdatedTaskSelector} from '../../redux/selectors/data/tasks.js'
import {isRunningTasksSelector} from '../../redux/selectors/data/isRunningTasks.js'

class SyncStatus extends Component {
  static propTypes = {
    task: TaskShape,
    isRunningTasks: PropTypes.bool.isRequired,
    allCartsAreWebhooked: PropTypes.bool.isRequired,
    checkRunningTasks: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (!this.props.task) {
      this.props.checkRunningTasks()
    }
  }

  render() {
    const {allCartsAreWebhooked, task, isRunningTasks} = this.props

    if (allCartsAreWebhooked) {
      return null
    }

    return (
      <div className="panel panel--dashboard padding-left-20 padding-right-20">
        <div className="clearfix margin-top-20 margin-bottom-15 align-center">
          {!task && (
            <p className="fs-01 lh-md margin-bottom-10">
              <em>Loading sync status...</em>
            </p>
          )}
          {task && (
            <div className="flex--justify-col">
              {!isRunningTasks && (
                <>
                  <p className="fs-01 lh-md margin-bottom-0">
                    <strong>LATEST SYNC:</strong>
                  </p>
                  <p className="fs-01 lh-md margin-bottom-10">
                    <FormattedDate value={task.updated} format="lll" />
                  </p>
                </>
              )}
              {isRunningTasks && (
                <p className="fs-01 lh-md margin-bottom-10">
                  <em>Syncing with your sales channels...</em>
                </p>
              )}
            </div>
          )}
          <div className="margin-bottom-10">
            <button
              className={classNames(
                'btn btn--primary btn--primary-alt btn--x-md lh-sm ws-normal w-100 margin-bottom-0',
                {
                  'btn--processing': isRunningTasks,
                },
              )}
              type="button"
              onClick={() => showCartSyncModal()}
            >
              <span
                className="i--sync inline-block v-align-middle fs-01 op-75 margin-right-5"
                aria-hidden="true"
              />
              <span className="inline-block v-align-middle">
                Sync Sales Channels
              </span>
            </button>
          </div>

          <div className="medium-7 columns flex--justify-col"></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    task: mostRecentlyUpdatedTaskSelector(state),
    isRunningTasks: isRunningTasksSelector(state),
    allCartsAreWebhooked: allCartsAreWebhookedSelector(state),
  }
}

const mapDispatchToProps = {
  checkRunningTasks,
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncStatus)
