export function blobToBase64URL(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export default async function blobToBase64(blob) {
  const base64URL = await blobToBase64URL(blob)

  // split on data:*/*;base64,
  // since `,` is not a base64 character this is fine
  const [, base64] = base64URL.split(',')

  return base64
}
