import PropTypes from 'prop-types'
import {Component} from 'react'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default class ReconcileForm extends Component {
  constructor(props) {
    super(props)

    this.onChange = (value, name) => this.props.updateColumnMapping(value, name)
  }

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-10" {...this.props}>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.company}
            errorMessage={this.props.columnErrors.company}
            name="company"
            label="Company"
            required
            className="medium-12 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.phone}
            errorMessage={this.props.columnErrors.phone}
            name="phone"
            label="Phone"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.fax}
            errorMessage={this.props.columnErrors.fax}
            name="fax"
            label="Fax"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.email}
            errorMessage={this.props.columnErrors.email}
            name="email"
            label="Email"
            required
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.street1}
            errorMessage={this.props.columnErrors.street1}
            name="street1"
            label="Street1"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.street2}
            errorMessage={this.props.columnErrors.street2}
            name="street2"
            label="Street2"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.city}
            errorMessage={this.props.columnErrors.city}
            name="city"
            label="City"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.state}
            errorMessage={this.props.columnErrors.state}
            name="state"
            label="State"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.zip}
            errorMessage={this.props.columnErrors.zip}
            name="zip"
            label="Zip"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.country}
            errorMessage={this.props.columnErrors.country}
            name="country"
            label="Country"
            className="medium-5"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.reference_number}
            errorMessage={this.props.columnErrors.reference_number}
            name="reference_number"
            label="Reference Number"
            className="medium-7 end"
            onChange={this.onChange}
          />
        </Row>
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
