import PropTypes from 'prop-types'
import {Component} from 'react'

import datadogRum from '../analytics/datadogRum.js'

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
  }

  componentDidCatch(error, info) {
    this.setState({error, info})

    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = info.componentStack
    renderingError.cause = error

    datadogRum.addError(renderingError)
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error">
          <h1 className="error" style={{marginLeft: '2rem'}}>
            Ordoro encountered an error. Please reload the page.
          </h1>
          <div style={{fontSize: '1rem'}}>
            <pre style={{marginLeft: '2rem', fontSize: '1.5rem'}}>
              {this.state.error.message}
            </pre>
            <pre
              style={{
                marginLeft: '2rem',
                marginTop: '1.5rem',
                fontSize: '0.75rem',
              }}
            >
              {this.state.error.stack}
            </pre>
            <pre
              style={{
                fontSize: '0.75rem',
                marginLeft: '0.2rem',
              }}
            >
              {this.state.info.componentStack}
            </pre>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}
