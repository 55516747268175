import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  dispatch,
} from '../../../store.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import apiverson from '../../../common/apiverson.js'
import {setBatch} from '../../../data/batches.js'

const MODAL_FORM = 'REFRESH_RATES_MODAL'

export function showRefreshRatesModal(referenceIDs) {
  setForm(MODAL_FORM, {
    referenceIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function refreshRates(referenceID) {
  await apiverson.post(`/batch/${encodeURIComponent(referenceID)}/action`, {
    params: {
      action: {
        data: {},
        type: 'fetch_rates',
      },
    },
    comment: 'Task started to refresh rates.',
  })

  const {json} = await apiverson.get(
    `/batch/${encodeURIComponent(referenceID)}`,
  )

  setBatch(json)
}

export async function refreshRatesAll() {
  const {referenceIDs} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true})

    await Promise.all(
      referenceIDs.map((referenceID) => refreshRates(referenceID)),
    )

    dispatch(checkRunningTasks())

    showMessageToast(
      plural(referenceIDs.length)`A task was started to refresh rates for ${
        referenceIDs.length
      } batch${['es']}.`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error refreshing rates for batches: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function RefreshRatesModal({form}) {
  return (
    <ConfirmModal
      title="Refresh Rates"
      onConfirm={() => refreshRatesAll()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md">
        <strong>
          <PluralBlock array={form.referenceIDs}>
            Refresh rates for <Count /> batch
            <Plural p="es" />?
          </PluralBlock>
        </strong>
      </p>
    </ConfirmModal>
  )
}

RefreshRatesModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(RefreshRatesModal, modalFormSelector)
