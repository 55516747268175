import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {isPresent} from '../../../common/utils.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {productUPCSelector} from '../../../data/products.js'
import {showProductTextFieldModal} from '../Modals/ProductTextFieldModal.js'

export function UPCCell({sku, upc}) {
  return (
    <td className="table__td table__td--product-list-upc">
      <ButtonLink
        className={classNames({darkest: isPresent(upc)})}
        onClick={() =>
          showProductTextFieldModal({
            sku,
            value: upc,
            display: 'UPC',
            apiProp: 'upc',
          })
        }
      >
        {isPresent(upc) ? upc : <em>Not set</em>}
      </ButtonLink>
    </td>
  )
}

UPCCell.propTypes = {
  sku: PropTypes.string.isRequired,
  upc: PropTypes.string,
}

function mapStateToProps(state, {sku}) {
  return {
    upc: productUPCSelector(state, {sku}),
  }
}

export default connect(mapStateToProps)(UPCCell)
