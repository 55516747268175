import PropTypes from 'prop-types'

import {getState, onlyIfAutoForm} from '../../../../store.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {VENDOR_PORTAL} from '../../../../common/constants/CartVendorOptions.js'
import FormList from '../../Common/FormList.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  return baseCartErrorsSelector(state, {formName})
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return baseNewCartParamsSelector(state, {vendor: VENDOR_PORTAL})
  }

  return baseEditCartParamsSelector(getState(), {cartID})
}

export function saveParamsSelector(state, {formName}) {
  return baseSaveParamsSelector(state, {formName})
}

function VendorPortal({form, formName}) {
  const isNew = form.id === NEW_ID

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
    </VendorForm>
  )
}

VendorPortal.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(VendorPortal, setUpCartForm)
