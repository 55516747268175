import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Column({className, as, children, ...props}) {
  const TagName = as || 'fieldset'

  return (
    <TagName className={classNames('columns', className)} {...props}>
      {children}
    </TagName>
  )
}

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  as: PropTypes.string,
}
