import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import api from '../../../common/api.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {getUsers, userSelector} from '../../../data/users.js'
import {navigate} from '../../../common/location.js'
import {SETTINGS, USERS} from '../../../common/constants/SettingsPanels.js'

export const MODAL_FORM = 'ARCHIVE_USER_MODAL'

export function showArchiveUserModal(userID) {
  setForm(MODAL_FORM, {
    userID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function archiveUser() {
  try {
    const {userID} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await api.delete(`/user/${userID}`)

    navigate([SETTINGS, USERS])

    closeModal()

    showMessageToast('User has been archived.')

    await getUsers()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ArchiveUserModal({form}) {
  const user = useSelector((state) =>
    userSelector(state, {userID: form.userID}),
  )

  return (
    <ConfirmModal
      title="Archive User"
      modalSize="sm"
      onConfirm={() => archiveUser()}
      onCancel={() => closeModal()}
      confirmText="Archive"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      preventInnerScroll
    >
      Are you sure you want to <strong>permanently</strong> archive user{' '}
      <strong>{user.name}</strong>? This <strong>cannot</strong> be undone.
    </ConfirmModal>
  )
}

ArchiveUserModal.propTypes = {
  form: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(ArchiveUserModal, modalFormSelector)
