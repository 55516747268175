import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty.js'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import api from '../../../common/api.js'
import {isPresent} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import TextArea from '../../../common/components/TextArea.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {setPO} from '../../../data/pos.js'

const MODAL_FORM = 'MODAL_FORM'
export const ADD_PO_COMMENT = 'ADD_PO_COMMENT'

export function showAddPOCommentModal(poID) {
  return setForm(MODAL_FORM, {
    poID,
    comment: '',
    commentHasChanged: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function addPOComment() {
  return {
    type: ADD_PO_COMMENT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

const errorsSelector = createSelector(modalFormSelector, (form) => {
  const errors = {}

  if (!form) {
    return errors
  }

  if (!isPresent(form.comment)) {
    errors.comment = 'Comment is required'
  }

  return errors
})

export function* addPOCommentWorker() {
  try {
    yield put(updateModalForm({serverError: null, isSaving: true}))

    const {poID, comment} = yield select(modalFormSelector)

    const params = {comment: comment.trim()}

    const {json} = yield call(
      api.post,
      `/purchase_order/${encodeURIComponent(poID)}/comment`,
      params,
    )

    yield call(setPO, json)

    yield call(showMessageToast, `Added comment to purchase order ${poID}`)

    yield put(closeModal())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error adding PO comment: ${
          err.message || err.error_message
        }`,
        isSaving: false,
      }),
    )
  }
}

function AddPOCommentModal({form, errors, ...props}) {
  const hasErrors = !isEmpty(errors)

  return (
    <ConfirmModal
      title="Add Purchase Order Comment"
      onConfirm={() => props.addPOComment()}
      onCancel={() => props.closeModal()}
      confirmText="Add Comment"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      error={form.serverError}
    >
      <TextArea
        className="fs-00 lh-md margin-bottom-0"
        value={form.comment}
        onChange={(value) =>
          props.updateModalForm({
            comment: value,
            commentHasChanged: true,
          })
        }
        errorMessage={form.commentHasChanged ? errors.comment : null}
        autoFocus
      />
    </ConfirmModal>
  )
}

AddPOCommentModal.propTypes = {
  form: PropTypes.shape({
    poID: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    commentHasChanged: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  errors: ErrorsShape.isRequired,
  addPOComment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: modalFormSelector(state),
    errors: errorsSelector(state),
  }
}

const mapDispatchToProps = {
  addPOComment,
  updateModalForm,
  closeModal,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(AddPOCommentModal),
  modalFormSelector,
)
