import {useSelector} from '../../store.js'
import {
  PRODUCT_DETAIL_KITTING_PANEL,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../common/constants/Products.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import {
  directKitParentsHaveLoadedSelector,
  directKitParentsSelector,
} from '../../data/directKitParents.js'
import {skuSelector} from './productDetailSelectors.js'

export default function KitParentsPanel() {
  const sku = useSelector(skuSelector)
  const directKitParentsHaveLoaded = useSelector((state) =>
    directKitParentsHaveLoadedSelector(state, {sku}),
  )
  const directKitParents = useSelector((state) =>
    directKitParentsSelector(state, {sku}),
  )

  return (
    <dl className="list">
      <dt className="list__title fs-01 lh-md">Kit Component Info</dt>

      {!directKitParentsHaveLoaded ? (
        <dd className="list__item fs-00 margin-top-10">
          <div className="loading">
            <span className="spinner--sm v-align-middle" />
            <span className="inline-block v-align-middle fs-00 op-75 margin-left-5">
              Loading...
            </span>
          </div>
        </dd>
      ) : directKitParents.length === 0 ? (
        <dd className="list__item fs-00 margin-top-10">
          This product is not a component of any parent kits.
        </dd>
      ) : (
        <>
          <dd className="list__item fs-00 margin-top-10">
            This product is a component of{' '}
            <span>{directKitParents.length}</span> kit parent
            {directKitParents.length === 1 ? '' : 's'}:
          </dd>

          <dd className="list__item margin-top-15">
            <ul className="list list--square">
              {directKitParents.map(({id, name, quantityInParent}) => (
                <li className="list__item fs-00" key={id}>
                  <LinkButton
                    href={`#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(
                      id,
                    )}/${PRODUCT_DETAIL_KITTING_PANEL}`}
                    className="darker v-align-top"
                  >
                    {name} ({quantityInParent})
                  </LinkButton>
                </li>
              ))}
            </ul>
          </dd>
        </>
      )}
    </dl>
  )
}
