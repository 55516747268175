import PropTypes from 'prop-types'
import className from '../../common/className.js'

import {useSelector} from '../../store.js'
import {
  panelLinkSelector,
  reportsPanelSelector,
  REPORTS_PANEL_ACTIVITY,
  REPORTS_PANEL_REPORTS,
} from './reportsPageSelectors.js'

function SubNavLink({title, panel, isActive}) {
  const href = useSelector((state) => panelLinkSelector(state, {panel}))

  return (
    <div className="list__item--sub-nav wider">
      <a
        className={className`sub-nav__link fs-01 ${{
          active: isActive,
        }}`}
        title={title}
        href={href}
      >
        <span>{title}</span>
      </a>
    </div>
  )
}

SubNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  panel: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default function SubNav() {
  const panel = useSelector(reportsPanelSelector)

  return (
    <div className="wrap--sub-nav wrap--sub-nav-panel">
      <nav className="list--sub-nav">
        <SubNavLink
          title="Existing Reports"
          panel={REPORTS_PANEL_REPORTS}
          isActive={panel === REPORTS_PANEL_REPORTS}
        />
        <SubNavLink
          title="Activity Log"
          panel={REPORTS_PANEL_ACTIVITY}
          isActive={panel === REPORTS_PANEL_ACTIVITY}
        />
      </nav>
    </div>
  )
}
