export const PROD_ENV = 'PROD_ENV'
export const DEV_ENV = 'DEV_ENV'
export const LOCAL_ENV = 'LOCAL_ENV'
export const ENV =
  window.location.hostname === 'app.ordoro-dev.com'
    ? DEV_ENV
    : window.location.hostname === 'app.ordoro.com'
      ? PROD_ENV
      : LOCAL_ENV
export const IS_PROD_ENV = ENV === PROD_ENV
export const IS_DEV_ENV = ENV === DEV_ENV
export const IS_LOCAL_ENV = ENV === LOCAL_ENV

export const NEW_ID = 'new'

export const THREEDCART_PUBLIC_KEY = IS_PROD_ENV
  ? '3818079655deb9edb3c6c381421937e7'
  : 'f988ed51de767a512696706e00aa3609'
export const GOOGLE_TAG_MANAGER_CONTAINER_ID = 'GTM-NFSH8BQ'
export const STRIPE_PUBLIC_KEY = IS_PROD_ENV
  ? 'pk_live_VhGaIiGqL1wYoiCUufytlIBF'
  : 'pk_test_FaVdXGDzMjkmG7wqxS1msmB5'
export const STRIPE_CONNECT_PUBLIC_KEY = IS_PROD_ENV
  ? 'pk_live_eThfMjmAMPdvUtTe93DX9WJX'
  : 'pk_test_3FCzMRnx8VjFz0P5nZxf2xnV'

export const NET_ZERO_TOKENIZATION = IS_PROD_ENV
  ? {
      SCRIPT_URL: 'https://tokenization.paynetzero.com/tokenization/v0.2',
      SOURCE_KEY: 'pk_JfSoefU7YLBuZTyaGQX3DiAWCHmjL',
    }
  : {
      SCRIPT_URL:
        'https://tokenization.sandbox.paynetzero.com/tokenization/v0.2',
      SOURCE_KEY: 'pk_DOLHfRfdohrewtvYw0Ew6ShWObUDG',
    }

export const LIST_MODE = 'LIST_MODE'
export const DETAIL_MODE = 'DETAIL_MODE'

export const WELCOME_URI_COMPONENT = 'welcome'
