import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep.js'
import omit from 'lodash/omit.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {presetSelector, savePreset} from '../../../../data/presets.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {
  EDIT_PRESET_LABEL_ID,
  labelConfigSelector,
  labelInfoSelector,
  setLabelInfos,
  labelShipperTypeSelector,
  validShipperIDsSelector,
  updateLabelConfig,
} from '../../../../data/labelInfos/index.js'
import ShipperSelect from '../../Fields/ShipperSelect/index.js'
import PackageType from '../../Fields/PackageType.js'
import TextInput from '../../../../common/components/TextInput.js'
import {isPresent} from '../../../../common/utils.js'
import {showCreateRuleModal} from '../../../Modals/CreateRuleModal.js'
import {
  APPLY_PRESETS,
  RETURN_PRESET,
  SHIPPING_PRESET,
} from '../../../../common/constants/Rules.js'
import {
  RETURN_TAB,
  orderActionPanelCurrentTabSelector,
} from '../../../OrderListPage/ActionPanel/orderActionPanelFunctions.js'
import SingleInsurance from '../../Fields/Insurance/SingleInsurance.js'
import ShippingMethod from '../../Fields/ShippingMethod.js'
import MultiboxButton from '../../Fields/MultiboxButton.js'
import {
  DEFAULT_LABEL_CONFIG,
  DEFAULT_PACKAGE_SHAPE,
} from '../../../../common/constants/LabelConfig.js'
import OldLabelConfigForm from '../../OldLabelConfigForm.js'
import LabelConfigContext from '../../LabelConfigContext.js'

const MODAL_FORM = 'EDIT_PRESET_MODAL'

export function showEditPresetModal({presetID, labelInfoID} = {}) {
  const preset = presetID
    ? presetSelector(getState(), {presetID})
    : {
        name: '',
        body: {
          properties: [],
          labelConfig: labelInfoID
            ? cloneDeep(labelConfigSelector(getState(), {labelInfoID}))
            : {
                ...cloneDeep(DEFAULT_LABEL_CONFIG),
                packages: [cloneDeep(DEFAULT_PACKAGE_SHAPE)],
              },
        },
      }

  setLabelInfos([
    {
      id: EDIT_PRESET_LABEL_ID,
      config: cloneDeep(preset.body.labelConfig),
      type: 'shipping',
      properties: preset.body.properties,
    },
  ])

  // set initial shipper if new from scratch
  if (!labelInfoID && !presetID) {
    const validShipperIDs = validShipperIDsSelector(getState(), {
      labelInfoID: EDIT_PRESET_LABEL_ID,
    })

    updateLabelConfig(EDIT_PRESET_LABEL_ID, {shipper_id: validShipperIDs[0]})
  }

  setForm(MODAL_FORM, {
    presetID,
    createRuleAfterSave: false,
    name: preset.name,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {name} = modalFormSelector(state)
  const errors = {}

  if (!isPresent(name)) {
    errors.name = 'Name is required'
    errors.preventSave = true
  }

  return errors
}

export function presetParamsSelector(state) {
  const {presetID, name} = modalFormSelector(state)
  const {properties, config} = labelInfoSelector(state, {
    labelInfoID: EDIT_PRESET_LABEL_ID,
  })

  return {
    id: presetID || undefined,
    name,
    body: {
      version: '2',
      properties,
      labelConfig: omit(config, [
        'rates',
        'order_id',
        'label_type',
        'error_message',
        'rates_loading',
        'rates_updated',
        'departed_shipping_method',
        'additional_options_open',
        'shipping_address',
      ]),
    },
  }
}

export async function confirm() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {presetID, createRuleAfterSave} = modalFormSelector(getState())

    const presetParams = presetParamsSelector(getState())

    const preset = await savePreset(presetParams)

    showMessageToast(presetID ? 'Updated Preset' : 'Created Preset')

    closeModal()

    if (createRuleAfterSave) {
      const currentActionPanelTab =
        orderActionPanelCurrentTabSelector(getState())

      showCreateRuleModal({
        action: [
          {
            type: APPLY_PRESETS,
            data: {
              preset_ids: [preset.id],
              preset_type:
                currentActionPanelTab === RETURN_TAB
                  ? RETURN_PRESET
                  : SHIPPING_PRESET,
            },
          },
        ],
      })
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function onChange() {
  // noop
}

function EditPresetModal({form}) {
  const errors = useSelector(errorsSelector)
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID: EDIT_PRESET_LABEL_ID}),
  )

  return (
    <ConfirmModal
      title={`${form.presetID ? 'Edit' : 'Create'} Preset`}
      confirmText={form.presetID ? 'Save' : 'Create'}
      cancelText="Cancel"
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <LabelConfigContext.Provider
        value={{
          labelInfoID: EDIT_PRESET_LABEL_ID,
          onChange,
          shipperType,
          isLabelEnableMode: true,
        }}
      >
        <div className="row">
          <div className="medium-7 columns">
            <TextInput
              className="margin-bottom-5"
              id="preset_name"
              label="Preset Name"
              required
              value={form.name}
              onChange={(name) => updateModalForm({name})}
              errorMessage={errors.name}
            />
          </div>
        </div>
        <div className="inner-wrap__panel--actions wrap--edit-preset-list">
          <ul className="list list--shipping-options flex--justify flex-wrap-margin-10">
            <ShipperSelect />
            <PackageType />
          </ul>
          <OldLabelConfigForm />
          <SingleInsurance />
          <ul className="list list--shipping-options">
            <ShippingMethod />
            <MultiboxButton />
          </ul>
        </div>
        <div className="row">
          <div className="medium-12 columns">
            <div className="alert alert--standard margin-top-10">
              <label
                className="margin-bottom-0"
                htmlFor="create_rule_after_save"
              >
                <input
                  type="checkbox"
                  id="create_rule_after_save"
                  className="margin-right-5 margin-bottom-0"
                  value={form.createRuleAfterSave}
                  onChange={(e) =>
                    updateModalForm({createRuleAfterSave: e.target.checked})
                  }
                />
                <span>
                  Create a rule that automatically applies this preset
                </span>
              </label>
            </div>
          </div>
        </div>
      </LabelConfigContext.Provider>
    </ConfirmModal>
  )
}

EditPresetModal.propTypes = {
  form: PropTypes.shape({
    presetID: PropTypes.number,
    name: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    createRuleAfterSave: PropTypes.bool.isRequired,
  }).isRequired,
}

export default onlyIfForm(EditPresetModal, modalFormSelector)
