import INITIAL_STATE from '../INITIAL_STATE.js'
import {
  SET_RETURN_ORDER_CODES,
  UPDATE_RETURN_ORDER_CODE,
} from '../../actions/data/returnOrderCodes.js'

export default function reducer(
  state = INITIAL_STATE.data.returnOrderCodes,
  action = {},
) {
  if (action.type === SET_RETURN_ORDER_CODES) {
    return action.payload
  }

  if (action.type === UPDATE_RETURN_ORDER_CODE) {
    return {
      ...state,
      [action.payload.id]: action.payload,
    }
  }

  return state
}
