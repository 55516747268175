import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import uniq from 'lodash/uniq.js'

import {getState, updateForm, formsSelector} from '../../../../store.js'
import apiverson, {formatV3APIURL} from '../../../../common/apiverson.js'
import {setOrdersStateAndEnsureProductsLoaded} from '../../../../data/orders.js'
import {updateOrderCounts} from '../../../../data/orderCounts.js'
import {
  resetShipFrom,
  labelInfoIDsForOrdersAndLabelTypesSelector,
} from '../../../../data/labelInfos/index.js'
import {
  getSupplierName,
  getIsFBA,
  activeSuppliersSelector,
  supplierSelector,
  getDropshipmentRoutingChannel,
} from '../../../../data/suppliers.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {refreshOrderList} from '../../orderListActions.js'
import {FBA_SHIPPING_METHODS} from '../../../../common/constants/Suppliers.js'
import {Count, plural} from '../../../../common/components/Plural.js'
import {activeOrdersSelector} from '../../orderListSelectors.js'

export const MANUAL_DROPSHIP_FORM = 'MANUAL_DROPSHIP_FORM'

export const ManualDropShipFormShape = PropTypes.shape({
  additionalInstructions: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  packingListID: PropTypes.number,
  shippingMethod: PropTypes.string.isRequired,
  originalShippingMethod: PropTypes.string,
  hasChanged_shipping: PropTypes.bool,
  shippingAccount: PropTypes.string.isRequired,
  shippingCarrier: PropTypes.string.isRequired,
  show_ship_to_email: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
  supplierID: PropTypes.number,
})

export function setupManualDropshipForm() {
  return {
    formName: MANUAL_DROPSHIP_FORM,
    initialForm: {
      additionalInstructions: '',
      isProcessing: false,
      packingListID: null,
      originalShippingMethod: null,
      shippingMethod: '',
      shippingAccount: '',
      shippingCarrier: '',
      show_ship_to_email: false,
      showPrice: false,
      supplierID: null,
    },
  }
}

export function updateManualDropshipFrom(updates) {
  updateForm(MANUAL_DROPSHIP_FORM, updates)
}

export function manualDropshipFormSelector(state) {
  return formsSelector(state)[MANUAL_DROPSHIP_FORM]
}

export const manualDropshipFormSupplierSelector = createSelector(
  activeSuppliersSelector,
  manualDropshipFormSelector,
  (suppliers, form) => (form ? suppliers[form.supplierID] : null),
)

export const manualDropshipFormSupplierIsFBASelector = createSelector(
  manualDropshipFormSupplierSelector,
  getIsFBA,
)

export const manualDropshipFormSupplierRoutingChannelSelector = createSelector(
  manualDropshipFormSupplierSelector,
  getDropshipmentRoutingChannel,
)

export function shippingMappingSelector(state, {shippingMethod}) {
  const {supplierID} = manualDropshipFormSelector(state)
  const supplier = supplierSelector(state, {supplierID})
  const outcome = {
    mapped: undefined,
    shippingMethod: '',
    mappedMethod: false,
    defaultedMethod: false,
    shippingCarrier: '',
    mappedCarrier: false,
    defaultedCarrier: false,
    shippingAccount: '',
    mappedAccount: false,
    defaultedAccount: false,
  }

  // no supplier, early return
  if (!supplier) {
    outcome.shippingMethod = shippingMethod

    return outcome
  }

  // find mapped shipping if it exists
  outcome.mapped = supplier.shipping_method_maps.find(
    (mapping) =>
      mapping.requested_shipping_method === shippingMethod && mapping.active,
  )

  if (outcome.mapped && outcome.mapped.mapped_shipping_method) {
    // if mapped shipping method
    // take mapped shipping method value
    outcome.shippingMethod = outcome.mapped.mapped_shipping_method
    outcome.mappedMethod = true
  } else if (getIsFBA(supplier)) {
    // else if supplier is FBA
    if (FBA_SHIPPING_METHODS.includes(shippingMethod)) {
      // take shipping method only if valid value
      outcome.shippingMethod = shippingMethod
    } else {
      // otherwise take the default (or first valid value)
      outcome.defaultedMethod = true
      outcome.shippingMethod =
        supplier.default_shipping_method || FBA_SHIPPING_METHODS[0]
    }
  } else {
    if (supplier.default_shipping_method) {
      // else take default shipping method
      outcome.defaultedMethod = true
      outcome.shippingMethod = supplier.default_shipping_method
    } else {
      // or the original shipping method
      outcome.shippingMethod = shippingMethod
    }
  }

  // get shipping carrier value
  if (outcome.mapped && outcome.mapped.mapped_shipping_carrier) {
    // if we have a mapped shipping carrier use it
    outcome.shippingCarrier = outcome.mapped.mapped_shipping_carrier
    outcome.mappedCarrier = true
  } else if (supplier.default_shipping_carrier) {
    // else if we have a default carrier use it
    outcome.shippingCarrier = supplier.default_shipping_carrier
    outcome.defaultedCarrier = true
  } else {
    // otherwise blank string
    outcome.shippingCarrier = ''
  }

  // get shipping account value
  if (outcome.mapped && outcome.mapped.mapped_shipping_account) {
    // if we have a mapped shipping account use it
    outcome.shippingAccount = outcome.mapped.mapped_shipping_account
    outcome.mappedAccount = true
  } else if (supplier.default_shipping_account) {
    // else if we have a default account use it
    outcome.shippingAccount = supplier.default_shipping_account
    outcome.defaultedAccount = true
  } else {
    // otherwise blank string
    outcome.shippingAccount = ''
  }

  return outcome
}

export function setManualDropshipSupplierID(supplierID, {initial} = {}) {
  updateManualDropshipFrom({
    supplierID,
    hasChanged__supplierID: !initial,
  })

  setDefaultShowPriceOption()

  setDefaultShippingMethod()
}

export function setDefaultShowShipToEmailOption() {
  const form = manualDropshipFormSelector(getState())

  if (!form) {
    return
  }

  const supplier = manualDropshipFormSupplierSelector(getState())

  if (supplier) {
    updateManualDropshipFrom({
      show_ship_to_email: supplier.automatically_show_ship_to_email,
    })
  }
}

export function setDefaultShowPriceOption() {
  const form = manualDropshipFormSelector(getState())

  if (!form) {
    return
  }

  const supplier = manualDropshipFormSupplierSelector(getState())

  if (supplier) {
    updateManualDropshipFrom({
      showPrice: supplier.automatically_show_supplier_price,
    })
  }
}

export function setDefaultShippingMethod() {
  const form = manualDropshipFormSelector(getState())

  if (!form) {
    return
  }

  const supplierIsFBA = manualDropshipFormSupplierIsFBASelector(getState())

  const updates = {}

  if (!form.hasChanged_shipping) {
    // set default shipping method to the order(s) but only when
    // - shipping method has not already been edited by the user
    // - AND if there are multiple orders, they all have the same requested shipping method

    const activeOrders = activeOrdersSelector(getState())
    const uniqueShippingMethods = uniq(
      activeOrders.map((order) => order.requested_shipping_method),
    )

    updates.originalShippingMethod =
      uniqueShippingMethods.length === 1 ? uniqueShippingMethods[0] : ''

    const outcome = shippingMappingSelector(getState(), {
      shippingMethod: updates.originalShippingMethod,
    })

    updates.shippingMethod = outcome.shippingMethod
    updates.shippingCarrier = outcome.shippingCarrier
    updates.shippingAccount = outcome.shippingAccount
  } else if (supplierIsFBA) {
    // if shipping method has changed and is FBA
    // then make sure shipping method is a valid FBA shipping method
    if (!FBA_SHIPPING_METHODS.includes(form.shippingMethod)) {
      const outcome = shippingMappingSelector(getState(), {
        shippingMethod: form.shippingMethod,
      })

      updates.shippingMethod = outcome.shippingMethod
    }
  }

  if (Object.keys(updates).length) {
    updateManualDropshipFrom(updates)
  }
}

export function dropshipOrderPayloadSelector(state) {
  const {
    additionalInstructions,
    packingListID,
    shippingMethod,
    shippingAccount,
    shippingCarrier,
    show_ship_to_email,
    showPrice,
    supplierID,
  } = manualDropshipFormSelector(state)
  const selectedSupplierIsFBA = manualDropshipFormSupplierIsFBASelector(state)

  let payload = {
    supplier_id: supplierID,
    shipping_method: shippingMethod,
    shipping_account: shippingAccount,
    shipping_carrier: shippingCarrier,
    override_shipping_mappings: true,
  }

  if (!selectedSupplierIsFBA) {
    payload = {
      instructions: additionalInstructions,
      show_ship_to_email,
      show_price: showPrice,
      ...payload,
    }

    if (packingListID) {
      payload.packing_list_id = packingListID
    }
  }

  return payload
}

export async function dropshipOrders(orderNumbers, markAsDropshipped) {
  const supplier = manualDropshipFormSupplierSelector(getState())
  const supplierName = getSupplierName(supplier)

  try {
    updateManualDropshipFrom({isProcessing: true})

    const params = dropshipOrderPayloadSelector(getState())

    const orders = (
      await Promise.all(
        orderNumbers.map((orderNumber) =>
          apiverson.post(
            `/order/${encodeURIComponent(orderNumber)}/${
              markAsDropshipped ? 'mark_as_dropshipped' : 'dropship'
            }`,
            params,
          ),
        ),
      )
    ).map(({json}) => json)

    await refreshOrderList()

    await setOrdersStateAndEnsureProductsLoaded(orders)

    showMessageToast(
      markAsDropshipped
        ? plural(orderNumbers)`${Count} ${[
            'orders were',
            'order was',
          ]} marked as dropshipped`
        : plural(orderNumbers)`${Count} dropshipment ${[
            'requests were',
            'request was',
          ]} sent to ${supplierName}`,
    )

    updateManualDropshipFrom({isProcessing: false})

    const labelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(
      getState(),
      {
        orderNumbers,
        labelTypes: ['shipping', 'return'],
      },
    )

    await Promise.all([updateOrderCounts(), resetShipFrom(labelInfoIDs)])
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error dropshipping orders.',
        details: err.message,
      },
      err,
    )

    updateManualDropshipFrom({isProcessing: false})
  }
}

export async function markAsDropshipped(orderNumbers) {
  await dropshipOrders(orderNumbers, true)
}

export function previewRequest(orderNumbers) {
  orderNumbers.map((orderNumber) => previewDropshipmentRequest(orderNumber))
}

export function previewDropshipmentRequest(orderNumber) {
  const params = dropshipOrderPayloadSelector(getState())
  const url = formatV3APIURL(
    `/order/${encodeURIComponent(orderNumber)}/dropship`,
    params,
  )

  window.open(url, '_blank')
}
