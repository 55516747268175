import {useEffect} from 'react'

import {PURCHASE_ORDER_DETAIL_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {dispatch, useSelector} from '../../store.js'
import {getCurrentDetailPO} from './poDetailActions.js'
import {poListQuerySelector} from '../PurchaseOrderListPage/purchaseOrderListSelectors.js'
import {DETAIL_MODE} from '../../common/constants/index.js'

export default function PODetailLocationListener() {
  const query = useSelector(poListQuerySelector)

  useEffect(() => {
    if (query.mode !== DETAIL_MODE) {
      return
    }

    dispatch(setCurrentPage(PURCHASE_ORDER_DETAIL_PAGE))
  }, [query.mode])

  useEffect(() => {
    if (query.mode !== DETAIL_MODE || !query.po_id) {
      return
    }

    getCurrentDetailPO()
  }, [query.mode, query.po_id])

  return null
}
