import PropTypes from 'prop-types'
import {useEffect, useMemo, useRef} from 'react'

import {filterOptions} from '../tokenizeOptions.js'
import {SelectOptionsShape, SelectOptionTokens} from '../PropTypes.js'
import {
  getState,
  updateForm,
  formsSelector,
  useAutoForm,
  useSelector,
} from '../../store.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {showEditTagModal} from './Modal/EditTagModal.js'
import {showDeleteTagsModal} from './Modal/DeleteTagsModal.js'
import Checkbox from './Checkbox.js'
import Radio from './Radio.js'
import FilterDropdown from './FilterDropdown.js'
import {useZropdown} from './Zropdown.js'

function filterTags(dropdown, tagOptions, tagOptionTokens) {
  try {
    const form = formsSelector(getState())[dropdown]

    if (!form) {
      return
    }

    if (!form.text) {
      updateForm(dropdown, {
        results: [...tagOptions],
        hasFoundNothing: false,
      })

      return
    }

    const results = filterOptions(form.text, tagOptionTokens)

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
    })
  } catch (err) {
    updateForm(dropdown, {
      serverError: err.message,
    })
  }
}

export default function ManageTagFilter({
  label,
  dropdown,
  className,
  buttonClassName,
  onSelect,
  tagOptions,
  tagOptionTokens,
  onCreateAndApplyClick,
  tagQuantityMap,
  hasManageTagPermission,
  tagType,
  autoFocus,
  isSingleSelect,
  hasErrors,
  isDisabledMap,
}) {
  label = label || 'Apply Tags'
  dropdown = dropdown || 'FILTER_TAG_FILTER'
  isDisabledMap = isDisabledMap || {}

  const buttonRef = useRef()

  const form = useAutoForm(dropdown, {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    serverError: null,
  })

  useEffect(() => {
    filterTags(dropdown, tagOptions, tagOptionTokens)
  }, [tagOptions])

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onChange: () => filterTags(dropdown, tagOptions, tagOptionTokens),
      onSelect: (option) => onSelect(option.entity),
      autoFocus,
    })

  const selectedTags = useMemo(
    () =>
      tagOptions.reduce((prev, option) => {
        const tag = option.entity

        if (tagQuantityMap[tag.id] !== 'none') {
          prev.push(tag)
        }

        return prev
      }, []),
    [tagOptions, tagQuantityMap],
  )

  const currentDropdown = useSelector(currentDropdownSelector)

  return (
    <div
      className={`wrap-outer--tag-dropdown-with-filter ${className || ''} ${
        currentDropdown === dropdown ? 'open' : ''
      }`}
      data-dropdown={dropdown}
    >
      <button
        ref={buttonRef}
        className={`btn btn--dropdown btn--filter ${buttonClassName || ''}`}
        type="button"
      >
        {isSingleSelect ? (
          selectedTags.length > 0 ? (
            <span>
              <span
                className="tag-color-block"
                style={{
                  backgroundColor: selectedTags[0].color,
                }}
              />
              <strong className="inline-block v-align-middle">
                {selectedTags[0].text || selectedTags[0].name}
              </strong>
            </span>
          ) : (
            <strong className={`${hasErrors ? 'error-message' : ''}`}>
              {label}
            </strong>
          )
        ) : (
          <span className={`btn__text ${hasErrors ? 'error-message' : ''}`}>
            {label}
          </span>
        )}
      </button>
      <FilterDropdown
        dropdown={dropdown}
        form={form}
        onChangeInternal={onChangeInternal}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
        placeholder="Search Tags"
        parentRef={buttonRef}
        footer={
          onCreateAndApplyClick && hasManageTagPermission ? (
            <div className="list__item--dropdown  list__item--dropdown-last">
              <button
                className="btn--link list__link list__link--dropdown"
                type="button"
                onClick={() => onCreateAndApplyClick()}
              >
                Create and apply a new tag
              </button>
            </div>
          ) : null
        }
      >
        <>
          {form.results.map((option, index) => {
            const tag = option.entity
            const tagQuantity = tagQuantityMap[tag.id]
            return (
              <li
                className="list__item list__item--dropdown disabled"
                key={tag.id}
              >
                <span
                  className={`label--selectable label--tag label--selectable-pt-pb-0 flex--justify-nowrap margin-bottom-0 ${
                    index === form.selectedIndex
                      ? 'list__link--dropdown-arrow-focus'
                      : ''
                  }`}
                  style={{borderColor: tag.color}}
                >
                  <label
                    className="flex--justify-nowrap flex-grow flex--jc-left margin-bottom-0 margin-right-5"
                    htmlFor={`tag__${tag.id}`}
                    data-dropdown-prevent-close
                  >
                    <span>
                      {isSingleSelect ? (
                        <Radio
                          className="fs-00 v-align-middle margin-bottom-0"
                          id={`tag__${tag.id}`}
                          checked={tagQuantity !== 'none'}
                          onClick={() => onSelectInternal(option)}
                          disabled={isDisabledMap[tag.id]}
                        />
                      ) : (
                        <Checkbox
                          className="fs-00 v-align-middle"
                          id={`tag__${tag.id}`}
                          checked={tagQuantity !== 'none'}
                          indeterminate={tagQuantity === 'some'}
                          onClick={() => onSelectInternal(option)}
                          disabled={isDisabledMap[tag.id]}
                        />
                      )}
                    </span>
                    <span className="margin-left-5">{option.display}</span>
                  </label>

                  {hasManageTagPermission && (
                    <span className="wrap--tag-buttons">
                      <span className="wrap--tag-edit">
                        <button
                          className="btn btn--link no-underline"
                          type="button"
                          title="Edit tag"
                          onClick={() => {
                            showEditTagModal(tagType, tag.id)
                          }}
                        >
                          <span className="i-pencil fs-00" aria-hidden="true" />
                        </button>
                      </span>
                      <button
                        className="btn btn--link no-underline"
                        type="button"
                        title="Delete tag"
                        onClick={() => {
                          showDeleteTagsModal(tagType, [tag.id])
                        }}
                      >
                        <span className="i-trash fs-00" aria-hidden="true" />
                      </button>
                    </span>
                  )}
                </span>
              </li>
            )
          })}
          {form.hasFoundNothing && (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              No tags match your search.
            </li>
          )}
          {tagOptions.length === 0 && (
            <li className="list__item--dropdown list__item--dropdown-empty align-center">
              You haven’t created any tags, yet!
            </li>
          )}
        </>
      </FilterDropdown>
    </div>
  )
}

ManageTagFilter.propTypes = {
  label: PropTypes.node,
  dropdown: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  tagOptions: SelectOptionsShape.isRequired,
  tagOptionTokens: SelectOptionTokens.isRequired,
  tagType: PropTypes.string.isRequired,
  onCreateAndApplyClick: PropTypes.func,
  tagQuantityMap: PropTypes.object.isRequired,
  hasManageTagPermission: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  isSingleSelect: PropTypes.bool,
  hasErrors: PropTypes.bool,
  isDisabledMap: PropTypes.object,
}
