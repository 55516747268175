import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from './ReconcileForm.js'
import EditForm from './EditForm.js'
import actions from '../../../../redux/actions/ui/dataImport/strategies/productTagStrategy.js'

export default function ProductTagStrategy(props) {
  return (
    <BaseStrategy
      {...props}
      actions={actions}
      EditForm={EditForm}
      ReconcileForm={ReconcileForm}
    />
  )
}

ProductTagStrategy.title = actions.title
