import {
  isEditingAPIKeySelector,
  errorMessageSelector,
  updatePrintNode,
} from '../../../data/printNode.js'
import {hasPrintNodeAPIKeySelector} from '../../../data/company.js'
import APIKeyInput from './APIKeyInput.js'
import {useSelector} from '../../../store.js'

export default function EditPrintNodeAPIKey() {
  const errorMessage = useSelector(errorMessageSelector)
  const hasAPIKey = useSelector(hasPrintNodeAPIKeySelector)
  const isEditingAPIKey = useSelector(isEditingAPIKeySelector)

  return (
    <div className="row">
      <div className="medium-7 columns">
        <div>
          <button
            className="btn btn--link fs-00 margin-bottom-10"
            type="button"
            onClick={() => updatePrintNode({isEditingAPIKey: true})}
          >
            Edit PrintNode API Key
          </button>
          {isEditingAPIKey && <APIKeyInput />}
          {hasAPIKey && errorMessage && (
            <small className="error">{errorMessage}</small>
          )}
        </div>
      </div>
    </div>
  )
}
