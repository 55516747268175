import {openModal, closeModal} from '../../ui/index.js'

export const MODAL_NAME = 'CONFIRM_EXCESSIVE_FULFILLMENT_LATENCY_MODAL'

export function showConfirmExcessiveFulfillmentLatencyModal() {
  return openModal(MODAL_NAME)
}

export function confirm(isConfirmed) {
  return closeModal({isConfirmed})
}
