import {useSelector} from 'react-redux'

import ExpandAllButton, {
  EXPAND_MODE_COLLAPSED,
  EXPAND_MODE_EXPANDED,
} from '../../../../../common/components/List/ExpandAllButton.js'
import {
  setExpandedOrderNumbers,
  setExpandMode,
} from '../../../../OrderListPage/orderListActions.js'
import {
  expandedOrderNumbersSelector,
  expandModeSelector,
} from '../../../../OrderListPage/orderListSelectors.js'

export default function ExpandAll() {
  const expandMode = useSelector(expandModeSelector)
  const expandedOrderCount = useSelector(expandedOrderNumbersSelector).length
  const shouldCollapse =
    expandedOrderCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={
        shouldCollapse
          ? 'Collapse all of the orders'
          : 'Expand all of the orders'
      }
    >
      <ExpandAllButton
        className="meta-orderlistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          setExpandedOrderNumbers([])
          setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          setExpandedOrderNumbers([])
          setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}
