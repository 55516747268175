import PropTypes from 'prop-types'

export const EXPAND_MODE_EXPANDED = 'expanded'
export const EXPAND_MODE_COLLAPSED = 'collapsed'

export default function ExpandAllButton({
  className,
  lineHeight,
  shouldCollapse,
  onExpandAll,
  onCollapseAll,
}) {
  lineHeight = lineHeight || '0'

  return (
    <button
      onClick={() => (shouldCollapse ? onCollapseAll() : onExpandAll())}
      className={`btn btn--link no-underline ${className || ''}`}
      type="button"
    >
      <span
        className={`i--expand fs-01 lh-${lineHeight} v-align-bottom`}
        aria-hidden="true"
      />
    </button>
  )
}

ExpandAllButton.propTypes = {
  className: PropTypes.string,
  lineHeight: PropTypes.string,
  shouldCollapse: PropTypes.bool.isRequired,
  onExpandAll: PropTypes.func.isRequired,
  onCollapseAll: PropTypes.func.isRequired,
}
