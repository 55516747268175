import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty.js'

import DatePicker from '../../../common/components/DatePicker.js'
import {ErrorsShape, DateShape} from '../../../common/PropTypes.js'
import {closeModal} from '../../../redux/actions/ui/index.js'
import {updateForm} from '../../../redux/actions/ui/forms.js'
import {
  EXPORT_USPS_TRANSACTIONS_MODAL,
  exportUSPSTransactions,
} from '../../../redux/actions/ui/settings/shippers/index.js'
import {
  exportUSPSTransactionsModalFormSelector,
  exportUSPSTransactionsModalErrorsSelector,
} from '../../../redux/selectors/ui/settings/shippers/exportUSPSTransactionsModal.js'

import BaseConfirmModal from '../../../common/components/Modal/BaseConfirmModal.js'

function ExportUSPSTransactionsModal({form, errors, ...props}) {
  return (
    <BaseConfirmModal
      title="USPS Transaction History"
      modalName={EXPORT_USPS_TRANSACTIONS_MODAL}
      isSaving={form.isRequesting}
      isDisabled={!isEmpty(errors)}
      confirmText="Export CSV"
      onConfirm={props.exportUSPSTransactions}
      cancelText="Cancel"
      onCancel={() => props.closeModal({form})}
      error={form.serverError}
      preventInnerScroll
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-25">
          Enter a specific date range or leave the fields blank to export the
          last 30 days of transactions.
        </li>
        <li className="list__item--form list__item--no-style flex">
          <div className={classNames({error: errors.startDate})}>
            <label className="clearfix" htmlFor="id_start_date">
              <span className="left">Start Date</span>
            </label>
            <DatePicker
              id="id_start_date"
              onChange={(date) =>
                props.updateForm(EXPORT_USPS_TRANSACTIONS_MODAL, {
                  startDate: date,
                })
              }
              selected={form.startDate}
              startDate={form.startDate}
              endDate={form.endDate}
            />
            {errors.startDate && (
              <small className="error error-message">{errors.startDate}</small>
            )}
          </div>
          <div
            className={classNames('margin-left-15', {error: errors.endDate})}
          >
            <label className="clearfix" htmlFor="id_end_date">
              <span className="left">End Date</span>
            </label>
            <DatePicker
              id="id_end_date"
              onChange={(date) =>
                props.updateForm(EXPORT_USPS_TRANSACTIONS_MODAL, {
                  endDate: date,
                })
              }
              selected={form.endDate}
              startDate={form.startDate}
              endDate={form.endDate}
            />
            {errors.endDate && (
              <small className="error error-message">{errors.endDate}</small>
            )}
          </div>
        </li>
      </ul>
    </BaseConfirmModal>
  )
}

ExportUSPSTransactionsModal.propTypes = {
  form: PropTypes.shape({
    startDate: DateShape,
    endDate: DateShape,
    isRequesting: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  exportUSPSTransactions: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

ExportUSPSTransactionsModal.modalName = EXPORT_USPS_TRANSACTIONS_MODAL

function mapStateToProps(state) {
  return {
    form: exportUSPSTransactionsModalFormSelector(state),
    errors: exportUSPSTransactionsModalErrorsSelector(state),
  }
}

const mapDispatchToProps = {
  exportUSPSTransactions,
  updateForm,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportUSPSTransactionsModal)
