import PropTypes from 'prop-types'
import {useRef} from 'react'
import useAutoFocus from '../hooks/useAutoFocus.js'

export default function Radio({
  checked,
  autoFocus,
  onChange,
  onClick,
  className,
  labelClassName,
  id,
  label,
  mode,
  ...props
}) {
  className = className || ''
  labelClassName = labelClassName || ''
  const radio = useRef(null)

  useAutoFocus(radio, autoFocus)

  if (mode === 'fancy') {
    const input = (
      <input
        className={`radio--fancy ${className}`}
        type="radio"
        id={id}
        ref={radio}
        onChange={onChange ? (event) => onChange(!checked, event) : () => {}}
        onClick={onClick ? (event) => onClick(!checked, event) : undefined}
        checked={checked}
        {...props}
      />
    )

    return label ? (
      <label className={`label--fancy flex ${labelClassName}`} htmlFor={id}>
        {input}
        <div className="label__text--fancy flex--justify-col">
          <div>
            <span className="label__pre-text">{label}</span>
          </div>
        </div>
      </label>
    ) : (
      input
    )
  }

  return (
    <input
      ref={radio}
      type="radio"
      id={id}
      className={className}
      onChange={onChange ? (event) => onChange(!checked, event) : () => {}}
      onClick={onClick ? (event) => onClick(!checked, event) : undefined}
      checked={checked}
      {...props}
    />
  )
}

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  mode: PropTypes.string,
}
