import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import subMonths from 'date-fns/subMonths'

import DatePicker from '../DatePicker.js'
import {ErrorsShape, DateShape} from '../../PropTypes.js'
import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  setStartDate,
  setEndDate,
  requestExport,
} from '../../../redux/actions/ui/modals/exportInsuranceHistoryModal.js'
import {
  startDateSelector,
  endDateSelector,
  errorsSelector,
  hasErrorsSelector,
} from '../../../redux/selectors/ui/modals/exportInsuranceHistoryModal.js'
import BaseConfirmModal from './BaseConfirmModal.js'

class ExportInsuranceHistoryModal extends Component {
  static propTypes = {
    now: DateShape.isRequired,
    startDate: DateShape,
    endDate: DateShape,
    isRequesting: PropTypes.bool.isRequired,
    errors: ErrorsShape.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    requestExport: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  componentDidMount() {
    const {now} = this.props

    this.props.setStartDate(subMonths(now, 1))
    this.props.setEndDate(now)
  }

  render() {
    const {
      startDate,
      endDate,
      errors,
      isRequesting,
      hasErrors,
      serverError,
      ...props
    } = this.props

    return (
      <BaseConfirmModal
        title="Export Insurance History to CSV"
        modalSize="sm"
        modalName={MODAL_NAME}
        onConfirm={() => props.requestExport()}
        onCancel={() => props.closeModal()}
        confirmText="Export"
        cancelText="Cancel"
        isSaving={isRequesting}
        isDisabled={hasErrors}
        error={serverError}
        preventInnerScroll
      >
        <ul className="list">
          <li className="list__item--form list__item--no-style flex">
            <div className={classNames({error: errors.startDate})}>
              <label className="clearfix" htmlFor="id_start_date">
                <span className="left">Start Date</span>
              </label>
              <DatePicker
                id="id_start_date"
                onChange={(date) => props.setStartDate(date)}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
              />
              {errors.startDate && (
                <small className="error error-message">
                  {errors.startDate}
                </small>
              )}
            </div>
            <div
              className={classNames('margin-left-15', {error: errors.endDate})}
            >
              <label className="clearfix" htmlFor="id_end_date">
                <span className="left">End Date</span>
              </label>
              <DatePicker
                id="id_end_date"
                onChange={(date) => props.setEndDate(date)}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
              />
              {errors.endDate && (
                <small className="error error-message">{errors.endDate}</small>
              )}
            </div>
          </li>
        </ul>
      </BaseConfirmModal>
    )
  }
}

function mapStateToProps(state) {
  const modal = state.ui.modals.exportInsuranceHistoryModal

  return {
    now: new Date(),
    startDate: startDateSelector(state),
    endDate: endDateSelector(state),
    isRequesting: modal.isRequesting,
    errors: errorsSelector(state),
    hasErrors: hasErrorsSelector(state),
  }
}

const mapDispatchToProps = {
  closeModal,
  setStartDate,
  setEndDate,
  requestExport,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportInsuranceHistoryModal)
