import {useEffect} from 'react'

import {hasReportsPermissionSelector} from '../../data/me.js'
import {useSelector} from '../../store.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import Modals from './Modals/index.js'
import {showEditReportModal} from './Modals/EditReportModal.js'
import ReportActivityPanel from './ReportActivityPanel.js'
import ReportListPanel from './ReportListPanel.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  reportsPanelSelector,
  REPORTS_PANEL_ACTIVITY,
  REPORTS_PANEL_REPORTS,
} from './reportsPageSelectors.js'
import SubNav from './SubNav.js'

export default function Reports() {
  const hasReportsPermission = useSelector(hasReportsPermissionSelector)
  const panel = useSelector(reportsPanelSelector)

  useEffect(() => {
    document.title = `Reports - ${
      panel === REPORTS_PANEL_REPORTS ? 'Schedules' : 'Activity'
    }`
  }, [panel])

  if (!hasReportsPermission) {
    return <NoPermissionsPage />
  }

  return (
    <div className="wrap--reports" id="iverson-page">
      <Modals />

      <div className="wrap--row flex margin-top-30">
        <div className="medium-10 medium-centered columns margin-bottom-30">
          <div className="flex--justify">
            <h2 className="page__title margin-bottom-25">
              <strong>Reports</strong>
            </h2>
            {panel === REPORTS_PANEL_REPORTS && (
              <div>
                <ButtonPrimary onClick={() => showEditReportModal()} size="md">
                  Create a New Report
                </ButtonPrimary>
              </div>
            )}
            {panel === REPORTS_PANEL_ACTIVITY && (
              <div>
                <ButtonPrimary
                  onClick={() => showEditReportModal({singleRun: true})}
                  alt
                  size="md"
                >
                  Export a Report
                </ButtonPrimary>
              </div>
            )}
          </div>
          <SubNav />
          <div className="wrap--table-panel">
            {panel === REPORTS_PANEL_REPORTS && <ReportListPanel />}
            {panel === REPORTS_PANEL_ACTIVITY && <ReportActivityPanel />}
          </div>
        </div>
      </div>
    </div>
  )
}
