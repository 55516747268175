import {useRef} from 'react'
import {useSelector} from 'react-redux'
import Currency from '../../common/components/Currency.js'
import {useElementSize} from '../../common/components/useWindowSize.js'
import {SHIPPING_PANEL} from '../../common/constants/Analytics.js'
import {analyticsHashBuilder} from '../AnalyticsPage/analyticsSelectors.js'
import SparkLineGraph from './SparkLIneGraph.js'
import {
  analyticsLinkParamsSelector,
  quickAnalyticsFormSelector,
  quickAnalyticsParamsSelector,
  shippingCountsByCarrierSelector,
  shippingDataSelector,
} from './quickAnalyticsFunctions.js'
import HorizontalBarGraph from '../AnalyticsPage/HorizontalBarGraph.js'
import Quantity from '../../common/components/Quantity.js'

const lines = ['Shipped Orders']
const margin = {top: 10, right: 0, left: 0, bottom: 15}
const MAX_SHIPPERS = 5

export default function ShippingStats() {
  const form = useSelector(quickAnalyticsFormSelector)
  const ref = useRef(null)
  const {width, height} = useElementSize({ref, margin, deps: [form.isLoading]})
  const analyticsLinkParams = useSelector(analyticsLinkParamsSelector)
  const shippingData = useSelector(shippingDataSelector)
  const shippingCountsByCarrier = useSelector(shippingCountsByCarrierSelector)
  const quickAnalyticsParams = useSelector(quickAnalyticsParamsSelector)

  return (
    <div className="row flex">
      <div className="medium-12 columns flex">
        <div className="panel panel--analytics panel--full-width-graph clearfix w-100 ">
          <div className="panel__header panel__header--analytics flex--justify">
            <h3 className="subheader subheader--analytics fs-01">
              Shipping Stats
            </h3>
            <a
              href={analyticsHashBuilder(SHIPPING_PANEL, analyticsLinkParams)}
              className="btn btn--link fs-00 lh-x-md"
            >
              View All →
            </a>
          </div>
          {form.isLoading ? (
            <div className="loading">
              <p className="graph-loading" />
            </div>
          ) : shippingData ? (
            <div className="padding-top-20 padding-bottom-20">
              <div className="wrap--row">
                <div className="medium-12 columns">
                  <div className="flex flex--reset-sm divider--bottom padding-bottom-20 margin-bottom-25">
                    <dl className="list min-w-20 margin-right-20">
                      <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                        Shipped Orders
                      </dt>
                      <dd className="list__item fs-05 lh-sm margin-bottom-0">
                        <strong>
                          <Quantity
                            value={shippingData.totals['Shipped Orders']}
                          />
                        </strong>
                      </dd>
                    </dl>
                    <div
                      className="wrap wrap--graph wrap--line-graph flex-grow-1 align-center"
                      ref={ref}
                      style={{minHeight: '5rem', position: 'relative'}}
                    >
                      {form.isLoading || !shippingData ? null : shippingData
                          .buckets.length === 0 ? (
                        <dl className="list--horiz-bar-graph list--horiz-bar-graph-none list--sparkline-none align-center hide-for-loading">
                          <dt className="list__title fs-01 margin-bottom-0">
                            <strong>
                              No data to display for this date range
                            </strong>
                          </dt>
                          <dd className="list__item fs-00">
                            Adjust your dates and try again
                          </dd>
                        </dl>
                      ) : (
                        <div style={{position: 'absolute'}}>
                          <SparkLineGraph
                            className="shipping-stats"
                            data={shippingData}
                            lines={lines}
                            startDate={quickAnalyticsParams.start_date}
                            endDate={quickAnalyticsParams.end_date}
                            width={width}
                            height={height}
                            margin={margin}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap--row flex flex--reset-sm">
                <div className="medium-7 columns divider--right">
                  <dl className="list">
                    <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 lh-md margin-bottom-5">
                      Shipped Orders by Carrier
                    </dt>
                    <dd className="list__item fs-04 lh-sm margin-bottom-0">
                      <HorizontalBarGraph
                        data={shippingCountsByCarrier}
                        max={MAX_SHIPPERS}
                      />
                    </dd>
                    {shippingCountsByCarrier.buckets.length > MAX_SHIPPERS && (
                      <dd className="list__item fs-01 lh-sm margin-bottom-0">
                        <a
                          href={analyticsHashBuilder(
                            SHIPPING_PANEL,
                            analyticsLinkParams,
                          )}
                          className="btn btn--link fs-00 lh-x-md"
                        >
                          View Additional Carrier Stats →
                        </a>
                      </dd>
                    )}
                  </dl>
                </div>
                <div className="medium-5 columns">
                  <dl className="list">
                    <dt className="list__title subheader subheader--analytics text--lt-grey fs-00 margin-bottom-5">
                      Total Shipping Costs
                    </dt>
                    <dd className="list__item fs-04 lh-sm margin-bottom-0">
                      <strong>
                        <Currency
                          value={shippingData.totals['Total Shipping Cost']}
                        />
                      </strong>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
