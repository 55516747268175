import PropTypes from 'prop-types'
import {Children, cloneElement, Component} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'

import {currentModalSelector} from '../../redux/selectors/ui/index.js'

export class ModalPortal extends Component {
  constructor(...args) {
    super(...args)

    this.el = document.createElement('div')

    this.root = document.getElementById('modal-root')
  }

  componentDidMount() {
    this.root.appendChild(this.el)
  }

  componentWillUnmount() {
    this.root.removeChild(this.el)
  }

  render() {
    const {children, currentModal} = this.props

    if (!currentModal) {
      return null
    }

    let modal

    Children.forEach(children, (child) => {
      if (child.type.modalName === currentModal) {
        modal = child
      }
    })

    if (!modal) {
      return null
    }

    return ReactDOM.createPortal(cloneElement(modal), this.el)
  }
}

ModalPortal.propTypes = {
  children: PropTypes.node.isRequired,
  currentModal: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    currentModal: currentModalSelector(state),
  }
}

export default connect(mapStateToProps)(ModalPortal)
