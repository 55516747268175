import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import api from '../../../common/api.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {unsetPreset, presetSelector} from '../../../data/presets.js'
import {showMessageToast} from '../../Header/Toast/index.js'

const MODAL_FORM = 'DELETE_PRESET_MODAL'

export function showDeletePresetModal(presetID) {
  setForm(MODAL_FORM, {
    presetID,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function confirm() {
  try {
    const {presetID} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await api.delete(`/preset/${presetID}`)

    closeModal()

    unsetPreset(presetID)

    showMessageToast('Preset deleted.')
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function DeletePresetModal({form}) {
  const preset = useSelector((state) =>
    presetSelector(state, {presetID: form.presetID}),
  )

  return (
    <ConfirmModal
      title="Delete Preset"
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      Are you sure you want to delete preset <strong>{preset.name}</strong>?
    </ConfirmModal>
  )
}

DeletePresetModal.propTypes = {
  form: PropTypes.shape({
    presetID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(DeletePresetModal, modalFormSelector)
