import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import SelectAllButton from '../../common/components/List/SelectAllButton.js'
import {setSelectedReferenceIDs} from './moListActions.js'
import {
  referenceIDListSelector,
  indeterminateSelectedSelector,
  allSelectedSelector,
} from './moListSelectors.js'

export function MOSelectAllButton({checked, referenceIDList, indeterminate}) {
  return (
    <SelectAllButton
      checked={checked}
      indeterminate={indeterminate}
      onChange={(value) =>
        setSelectedReferenceIDs(value ? referenceIDList : [])
      }
    />
  )
}

MOSelectAllButton.propTypes = {
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  indeterminate: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    referenceIDList: referenceIDListSelector(state),
    indeterminate: indeterminateSelectedSelector(state),
    checked: allSelectedSelector(state),
  }
}

export default connect(mapStateToProps)(MOSelectAllButton)
