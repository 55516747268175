import PropTypes from 'prop-types'
import {Component} from 'react'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default class ReconcileForm extends Component {
  onChange = (value, name) => this.props.updateColumnMapping(value, name)

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-10" {...this.props}>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.order_number}
            errorMessage={this.props.columnErrors.order_number}
            name="order_number"
            label="Order Number"
            className="medium-7"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.ship_date}
            errorMessage={this.props.columnErrors.ship_date}
            name="ship_date"
            label="Shipment Date"
            className="medium-5 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.tracking_number}
            errorMessage={this.props.columnErrors.tracking_number}
            name="tracking_number"
            label="Tracking Number"
            className="medium-7"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.carrier_name}
            errorMessage={this.props.columnErrors.carrier_name}
            name="carrier_name"
            label="Carrier Name"
            className="medium-5 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.shipping_method}
            errorMessage={this.props.columnErrors.shipping_method}
            name="shipping_method"
            label="Shipment Method"
            className="medium-7"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.cost}
            errorMessage={this.props.columnErrors.cost}
            name="cost"
            label="Cost"
            className="medium-5 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.notify_ship_to}
            errorMessage={this.props.columnErrors.notify_ship_to}
            name="notify_ship_to"
            label="Notify Ship To"
            className="medium-7"
            onChange={this.onChange}
          />
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.notify_bill_to}
            errorMessage={this.props.columnErrors.notify_bill_to}
            name="notify_bill_to"
            label="Notify Bill To"
            className="medium-5 end"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.notify_cart}
            errorMessage={this.props.columnErrors.notify_cart}
            name="notify_cart"
            label="Notify Cart"
            className="medium-7"
            onChange={this.onChange}
          />
        </Row>
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
