import {useEffect, useState} from 'react'
import classNames from 'classnames'

import {NEW_ID} from '../../../common/constants/index.js'
import Forms from './Forms/index.js'
import {useSelector} from '../../../store.js'
import {
  accountingIntegrationOptionsSelector,
  settingsAccountingAddSelector,
} from './accountingFunctions.js'
import {ACCOUNTING, SETTINGS} from '../../../common/constants/SettingsPanels.js'
import {buildPath} from '../../../common/querystring.js'

export default function NewForm() {
  const [vendor, setVendor] = useState(null)
  const accountingIntegrationOptions = useSelector(
    accountingIntegrationOptionsSelector,
  )
  const isNew = useSelector(settingsAccountingAddSelector)

  useEffect(() => {
    if (!accountingIntegrationOptions.find(({value}) => value === vendor)) {
      setVendor(accountingIntegrationOptions[0].value)
    }
  }, [accountingIntegrationOptions, vendor])

  const Form = vendor && Forms[vendor]

  if (accountingIntegrationOptions.length === 0 || !Form) {
    return null
  }

  return (
    <li
      className={classNames(
        'list__item--settings-wrap list__item--add-item-wrap clearfix',
        {expanded: isNew},
      )}
    >
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={'#' + buildPath([SETTINGS, ACCOUNTING, NEW_ID])}
            title="Add an integration"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Add an integration</span>
          </a>
        </p>
      </div>
      {isNew && (
        <div className="settings-list-item-form-wrap padding-top-5 clear-both">
          <fieldset>
            <ul className="form-list margin-bottom-0 margin-left-15">
              <li className="form-list-item margin-bottom-0">
                <label htmlFor="integration_type" className="margin-bottom-5">
                  Select your integration:
                </label>
                <select
                  className="select bigger-select margin-bottom-0"
                  id="integration_type"
                  value={vendor}
                  onChange={(event) => setVendor(event.target.value)}
                >
                  {accountingIntegrationOptions.map(({value, display}) => (
                    <option key={value} value={value}>
                      {display}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
            <Form isNew formName={`SETTINGS_ACCOUNTING_NEW_${vendor}`} />
          </fieldset>
        </div>
      )}
    </li>
  )
}
