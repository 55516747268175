import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {isPresent, isPositiveNumeric, isNumeric} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import Checkbox from '../../../common/components/Checkbox.js'
import {
  getProduct,
  productCartsSelector,
  productSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  getCartName,
  cartSelector,
  getHasMultiLocations,
  cartsSelector,
} from '../../../data/carts.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import api from '../../../common/api.js'
import {buildPath} from '../../../common/querystring.js'
import {
  ETSY,
  cartVendorsByName,
} from '../../../common/constants/CartVendorOptions.js'

export const MODAL_FORM = 'EDIT_PRODUCT_CART_MODAL'
export const SHOW_EDIT_PRODUCT_CART_MODAL = 'SHOW_EDIT_PRODUCT_CART_MODAL'
export const UPDATE_PRODUCT_CART = 'UPDATE_PRODUCT_CART'

export function showEditProductCartModal(
  sku,
  cartIDs = [],
  autoFocusOn = null,
) {
  const productCarts = productCartsSelector(getState(), {sku})

  autoFocusOn = autoFocusOn || 'sync'

  const firstProductCart = productCarts.find(({id}) => id === cartIDs[0]) || {
    sync: false,
    max_export_qty: null,
    min_export_qty: null,
    percent_export_qty: 100,
    reserve_export_qty: 0,
  }

  setForm(MODAL_FORM, {
    sku,
    cartIDs,
    autoFocusOn,
    isSingleInputEdit: cartIDs.length > 1,
    sync: firstProductCart.sync,
    max_export_qty: String(
      typeof firstProductCart.max_export_qty === 'number'
        ? firstProductCart.max_export_qty
        : '',
    ),
    min_export_qty: String(
      typeof firstProductCart.min_export_qty === 'number'
        ? firstProductCart.min_export_qty
        : '',
    ),
    percent_export_qty: String(firstProductCart.percent_export_qty),
    reserve_export_qty: String(firstProductCart.reserve_export_qty),
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function calculatedExportQuantitySelector(state) {
  let {
    sku,
    cartIDs,
    max_export_qty,
    min_export_qty,
    percent_export_qty,
    reserve_export_qty,
  } = modalFormSelector(getState())
  const product = productSelector(state, {sku})

  if (cartIDs.length > 1) {
    return null
  }

  const productCart = product.carts.find(({id}) => id === cartIDs[0])

  if (Object.keys(productCart.cart_aoh).length > 1) {
    return null
  }

  const aoh = productCart.cart_aoh.total_aoh
  max_export_qty = isNumeric(max_export_qty) ? Number(max_export_qty) : null
  min_export_qty = isNumeric(min_export_qty) ? Number(min_export_qty) : null
  percent_export_qty = Number(percent_export_qty) || 100
  reserve_export_qty = Number(reserve_export_qty) || 0

  const reserved_applied_aoh =
    aoh - Math.min(Math.max(0, aoh), reserve_export_qty)

  const calculated =
    reserved_applied_aoh > 0
      ? reserved_applied_aoh * (percent_export_qty * 0.01)
      : reserved_applied_aoh

  const max_export = max_export_qty
    ? Math.min(max_export_qty, calculated)
    : calculated

  return min_export_qty !== null
    ? Number(Math.max(max_export, min_export_qty))
    : Number(max_export)
}

function hasEtsySelector(state) {
  const {cartIDs} = modalFormSelector(state)
  const carts = cartsSelector(state)

  for (const cart of Object.values(carts)) {
    if (!cartIDs.includes(cart.id)) {
      continue
    }

    if (cart.vendor === ETSY) {
      return true
    }
  }

  return false
}

function maxMaxExportQtySelector(state) {
  const hasEtsy = hasEtsySelector(state)

  return hasEtsy ? 999 : Infinity
}

export const errorsSelector = createSelector(
  modalFormSelector,
  maxMaxExportQtySelector,
  (
    {max_export_qty, min_export_qty, percent_export_qty, reserve_export_qty},
    maxMaxExportQty,
  ) => {
    const errors = {}

    if (isPresent(max_export_qty)) {
      if (!isPositiveNumeric(max_export_qty)) {
        errors.max_export_qty =
          'Max export quantity must be a positive number or blank'
        errors.preventSave = true
      } else if (max_export_qty > maxMaxExportQty) {
        errors.max_export_qty = `Max export quantity must be less than ${maxMaxExportQty}`
        errors.preventSave = true
      }
    }

    if (isPresent(min_export_qty) && !isNumeric(min_export_qty)) {
      errors.min_export_qty = 'Min export quantity must be a number or blank'
      errors.preventSave = true
    }

    if (!isPositiveNumeric(percent_export_qty)) {
      errors.percent_export_qty =
        'Percent export quantity must be a positive number'
      errors.preventSave = true
    } else if (Number(percent_export_qty) > 100) {
      errors.percent_export_qty =
        'Percent export quantity must be less than or equal to 100%'
      errors.preventSave = true
    }

    if (!isPositiveNumeric(reserve_export_qty)) {
      errors.reserve_export_qty =
        'Reserve export quantity must be a positive number'
      errors.preventSave = true
    }

    return errors
  },
)

export async function updateProductCart() {
  try {
    const {
      sku,
      cartIDs,
      sync,
      max_export_qty,
      min_export_qty,
      percent_export_qty,
      reserve_export_qty,
      autoFocusOn,
      isSingleInputEdit,
    } = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const params = {}

    if (!isSingleInputEdit || autoFocusOn === 'sync') {
      params.sync = sync
    }

    if (!isSingleInputEdit || autoFocusOn === 'export_qty') {
      params.max_export_qty = isPositiveNumeric(max_export_qty)
        ? Number(max_export_qty)
        : null

      params.min_export_qty = isNumeric(min_export_qty)
        ? Number(min_export_qty)
        : null

      params.percent_export_qty = isPositiveNumeric(percent_export_qty)
        ? Number(percent_export_qty)
        : 100

      params.reserve_export_qty = isPositiveNumeric(reserve_export_qty)
        ? Number(reserve_export_qty)
        : 0
    }

    await Promise.all(
      cartIDs.map((cartID) =>
        api.put(buildPath(['product', sku, 'cart', cartID]), params),
      ),
    )

    await getProduct(sku)

    closeModal()

    showMessageToast(
      `Saved product sales channel${cartIDs.length === 1 ? '' : 's'}`,
    )
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditProductCartModal({form}) {
  const calculatedExportQuantity = useSelector(calculatedExportQuantitySelector)
  const firstCartID = form.cartIDs[0]
  const firstCart = useSelector((state) =>
    cartSelector(state, {cartID: firstCartID}),
  )
  const errors = useSelector(errorsSelector)
  const hasEtsy = useSelector(hasEtsySelector)
  const hasMultiLocations = getHasMultiLocations(firstCart)
  const vendorName = cartVendorsByName[firstCart.vendor]

  return (
    <ConfirmModal
      title="Edit Sales Channel Values"
      modalSize="sm"
      onConfirm={() => updateProductCart()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style margin-bottom-10">
        <li className="list__item--form list__item--no-style divider--bottom">
          <div className="fs-01">
            {form.cartIDs.length === 1 ? (
              <>
                <strong>Updating 1 sales channel:</strong>
                <div>{getCartName(firstCart)}</div>
              </>
            ) : (
              <strong>Updating {form.cartIDs.length} sales channels</strong>
            )}
          </div>
        </li>
        {(!form.isSingleInputEdit || form.autoFocusOn === 'sync') && (
          <li className="list__item margin-top-20 padding-bottom-20 margin-bottom-0 divider--bottom">
            <label
              className="fs-01 inline-block margin-right-7 margin-bottom-0"
              htmlFor="enable_inventory_writeback"
            >
              <Checkbox
                id="enable_inventory_writeback"
                className="v-align-middle margin-bottom-0 margin-right-7"
                checked={form.sync}
                onChange={() =>
                  updateModalForm({
                    sync: !form.sync,
                  })
                }
                autoFocus={form.autoFocusOn === 'sync'}
              />
              <span className="v-align-middle">Enable Inventory Writeback</span>
            </label>
          </li>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'export_qty') && (
          <li className="list__item list__item--form">
            <div className="w-80 margin-top-20 margin-bottom-20">
              <p className="fs-01 margin-bottom-5">
                <strong>Set Writeback Quantity</strong>
              </p>
              <p className="fs-n0 lh-md margin-bottom-0">
                For more info on how writeback quantities are calculated, check
                out this handy{' '}
                <strong>
                  <a
                    href="https://support.ordoro.com/setting-max-export-quantity-by-cart/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help article
                  </a>
                </strong>{' '}
                →
              </p>
            </div>
            {hasEtsy && (
              <div className="alert alert--warning alert--sm align-center">
                <em className="fs-n0 lh-md">
                  <strong>
                    The maximum quantity for Max Export that Etsy accepts is
                    999.
                  </strong>
                </em>
              </div>
            )}
            <div className="flex margin-top-25">
              <label
                htmlFor="max_export_qty"
                className="flex--justify-col margin-right-7"
              >
                <span className="fs-00">Max Export</span>
              </label>
              <div className="margin-right-10">
                <NumberInput
                  id="max_export_qty"
                  value={form.max_export_qty}
                  onChange={(value) =>
                    updateModalForm({
                      max_export_qty: `${value}`,
                    })
                  }
                  isInvalid={!!errors.max_export_qty}
                  autoFocus={form.autoFocusOn === 'export_qty'}
                />
              </div>
              <div className="flex--justify-col fs-n0 text--lt-grey">
                <em>Enter a value or leave blank for “All”</em>
              </div>
            </div>
            {errors.max_export_qty && (
              <small className="error">{errors.max_export_qty}</small>
            )}
            <div className="flex margin-top-15">
              <label
                htmlFor="min_export_qty"
                className="flex--justify-col margin-right-7"
              >
                <span className="fs-00">Min Export</span>
              </label>
              <div className="margin-right-10">
                <NumberInput
                  id="min_export_qty"
                  value={form.min_export_qty}
                  onChange={(value) =>
                    updateModalForm({
                      min_export_qty: `${value}`,
                    })
                  }
                  isInvalid={!!errors.min_export_qty}
                />
              </div>
              <div className="flex--justify-col fs-n0 text--lt-grey">
                <em>Enter a value or leave blank to disable</em>
              </div>
            </div>
            {errors.min_export_qty && (
              <small className="error">{errors.min_export_qty}</small>
            )}
            <div className="flex margin-top-15">
              <label
                htmlFor="percent_export_qty"
                className="flex--justify-col margin-right-7"
              >
                <span className="fs-00">Percentage</span>
              </label>
              <div className="margin-right-10">
                <NumberInput
                  id="percent_export_qty"
                  value={form.percent_export_qty}
                  onChange={(value) =>
                    updateModalForm({
                      percent_export_qty: `${value}`,
                    })
                  }
                  isInvalid={!!errors.percent_export_qty}
                  placeHolder="100%"
                />
              </div>
            </div>
            {errors.percent_export_qty && (
              <small className="error">{errors.percent_export_qty}</small>
            )}
            <div className="flex margin-top-15">
              <label
                htmlFor="reserve_export_qty"
                className="flex--justify-col margin-right-7"
              >
                <span className="fs-00">Reserve Qty</span>
              </label>
              <div className="margin-right-10">
                <NumberInput
                  id="reserve_export_qty"
                  value={form.reserve_export_qty}
                  onChange={(value) =>
                    updateModalForm({
                      reserve_export_qty: `${value}`,
                    })
                  }
                  isInvalid={!!errors.reserve_export_qty}
                  placeHolder="0"
                />
              </div>
            </div>
            {errors.reserve_export_qty && (
              <small className="error">{errors.reserve_export_qty}</small>
            )}
            {hasMultiLocations && (
              <div className="alert alert--standard inline-block margin-top-20">
                These values will be applied to <strong>each</strong>{' '}
                {vendorName.display} location.
              </div>
            )}
            {calculatedExportQuantity !== null && (
              <div className="alert alert--standard inline-block margin-top-20">
                <strong className="fs-00">Calculated Qty:</strong>{' '}
                <span className="fs-00">{calculatedExportQuantity}</span>
              </div>
            )}
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditProductCartModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    cartIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    sync: PropTypes.bool.isRequired,
    max_export_qty: PropTypes.string.isRequired,
    min_export_qty: PropTypes.string.isRequired,
    percent_export_qty: PropTypes.string.isRequired,
    reserve_export_qty: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditProductCartModal, modalFormSelector)
