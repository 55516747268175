import {NAVIGATE} from '../../../actions/ui/index.js'
import INITIAL_STATE from '../../INITIAL_STATE.js'

export default function reducer(
  state = INITIAL_STATE.ui.settings.payments,
  action = {},
) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    if (
      pathComponents[0] === 'settings' &&
      pathComponents[1] === 'payments' &&
      pathComponents[2]
    ) {
      return {editID: pathComponents[2]}
    }

    return {editID: INITIAL_STATE.ui.settings.shippers.editLink}
  }

  return state
}
