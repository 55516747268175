import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_IS_RUNNING_TASKS} from '../../actions/data/isRunningTasks.js'

export default function reducer(
  state = INITIAL_STATE.data.isRunningTasks,
  action = {},
) {
  if (action.type === SET_IS_RUNNING_TASKS) {
    return action.payload
  }
  return state
}
