import {createSelector} from 'reselect'
import _ from 'lodash'

import {dataImportSelector} from '../dataImport/index.js'

export const totalSavedSelector = createSelector(dataImportSelector, ({data}) =>
  data.reduce((sum, obj) => {
    if (obj.isSaved) {
      sum += 1
    }

    return sum
  }, 0),
)

export const totalDeletedSelector = createSelector(
  dataImportSelector,
  ({data}) =>
    data.reduce((sum, obj) => {
      if (obj.isDeleted) {
        sum += 1
      }

      return sum
    }, 0),
)

export const totalErroredSelector = createSelector(
  dataImportSelector,
  ({data}) =>
    data.reduce((sum, obj) => {
      if (!obj.isSaved && !obj.isDeleted && !_.isEmpty(obj.errors)) {
        sum += 1
      }

      return sum
    }, 0),
)

const totalDoneSelector = createSelector(
  totalSavedSelector,
  totalDeletedSelector,
  (totalSaved, totalDeleted) => totalSaved + totalDeleted,
)

const rowCountSelector = createSelector(
  dataImportSelector,
  ({rows}) => rows.length,
)

const dataCountSelector = createSelector(
  dataImportSelector,
  ({data}) => data.length,
)

export const progressOfTotalSelector = createSelector(
  dataImportSelector,
  totalDoneSelector,
  rowCountSelector,
  dataCountSelector,
  ({isValidatingData}, totalDone, rowCount, dataCount) => {
    if (dataCount === 0) {
      return rowCount / 2
    } else if (isValidatingData) {
      return rowCount + dataCount
    }

    return dataCount * 2 + totalDone
  },
)

export const widthSelector = createSelector(
  rowCountSelector,
  dataCountSelector,
  progressOfTotalSelector,
  (rowCount, dataCount, progressOfTotal) => {
    const total = (dataCount || rowCount) * 3
    let width = 0

    if (total !== 0) {
      width = (progressOfTotal / total) * 100
    }

    return `${width}%`
  },
)

export const reviewListSelector = createSelector(
  dataImportSelector,
  ({data, currentDataIndex, willShowOnlyErrors}) =>
    data.reduce(
      (reviewList, {index, isSaved, isDeleted, errors}) => {
        if (
          !(isSaved || isDeleted || (willShowOnlyErrors && _.isEmpty(errors)))
        ) {
          if (index < currentDataIndex) {
            reviewList.currentIndex += 1
          }

          reviewList.data.push(index)
        }

        return reviewList
      },
      {data: [], currentIndex: 0},
    ),
)

export const progressSelector = createSelector(
  totalSavedSelector,
  totalErroredSelector,
  totalDeletedSelector,
  dataCountSelector,
  totalDoneSelector,
  widthSelector,
  reviewListSelector,
  (
    totalSaved,
    totalErrored,
    totalDeleted,
    dataCount,
    totalDone,
    width,
    reviewList,
  ) => ({
    totalSaved,
    totalErrored,
    totalDeleted,
    totalUnsaved: dataCount - totalDone,
    totalDone,
    isDone: dataCount > 0 && totalDone === dataCount,
    width,
    reviewList,
  }),
)
