import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {call, put, select} from 'redux-saga/effects'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {WALMART} from '../../../common/constants/CartVendorOptions.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {saveProductCartExtraInfo, nameSelector} from '../../../data/products.js'
import {cartVendorSelector} from '../../../data/carts.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'

export const SAVE_FULFILLMENT_LATENCY = 'SAVE_FULFILLMENT_LATENCY'
export const FULFILLMENT_LATENCY_MODAL = 'FULFILLMENT_LATENCY_MODAL'

export function showFulfillmentLatencyModal(sku, cartID, fulfillment_latency) {
  return setForm(FULFILLMENT_LATENCY_MODAL, {
    sku,
    cartID,
    fulfillment_latency: isPresent(fulfillment_latency)
      ? String(fulfillment_latency)
      : '',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  return updateForm(FULFILLMENT_LATENCY_MODAL, ...args)
}

export function closeModal() {
  return removeForm(FULFILLMENT_LATENCY_MODAL)
}

export function saveFulfillmentLatency() {
  return {
    type: SAVE_FULFILLMENT_LATENCY,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[FULFILLMENT_LATENCY_MODAL],
)

export function errorsSelector(state) {
  const {fulfillment_latency, cartID} = modalFormSelector(state)
  const cartVendor = cartVendorSelector(state, {cartID})
  const errors = {}

  if (
    isPresent(fulfillment_latency) &&
    !isPositiveNumeric(fulfillment_latency)
  ) {
    errors.fulfillment_latency = 'Fulfillment Latency must be a positive number'
    errors.preventSave = true
  }

  if (cartVendor === WALMART && Number(fulfillment_latency) >= 2) {
    errors.excessiveFulfillmentLatency = true
  }

  return errors
}

export function* saveFulfillmentLatencyWorker() {
  try {
    const {sku, cartID, fulfillment_latency} = yield select(modalFormSelector)
    const params = {
      fulfillment_latency: isPositiveNumeric(fulfillment_latency)
        ? Number(fulfillment_latency)
        : null,
    }

    yield put(updateModalForm({isSaving: true, serverError: null}))

    yield call(saveProductCartExtraInfo, sku, cartID, params)

    yield put(closeModal())

    yield call(
      showMessageToast,
      'Saved product sales channel fulfillment latency',
    )
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: err.message || err.error_message,
        isSaving: false,
      }),
    )
  }
}

function FulfillmentLatencyModal({form, errors, productName, ...props}) {
  return (
    <ConfirmModal
      title="Edit Fulfillment Latency"
      modalSize="sm"
      onConfirm={() => props.saveFulfillmentLatency()}
      onCancel={() => props.closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div className="fs-01">
        <strong>{productName}</strong>
      </div>
      <div className="fs-00 margin-bottom-15">
        <span>SKU:</span> {form.sku}
      </div>
      <label htmlFor="max-export-qty">Fulfillment Latency</label>
      <NumberInput
        id="fulfillment-latency"
        value={form.fulfillment_latency}
        onChange={(value) =>
          props.updateForm(FULFILLMENT_LATENCY_MODAL, {
            fulfillment_latency: `${value}`,
          })
        }
        isInvalid={!!errors.fulfillment_latency}
        min={0}
        autoFocus
      />
      {errors.fulfillment_latency && (
        <small className="error">{errors.fulfillment_latency}</small>
      )}
      {errors.excessiveFulfillmentLatency && (
        <div className="alert alert--warning margin-top-20 ">
          <p className="fs-00 margin-bottom-15">
            <strong>Don’t Get Penalized by Walmart!</strong>
          </p>
          <p className="fs-00 margin-bottom-15">
            You need to{' '}
            <a
              href="https://marketplace.walmart.com/knowledgebase/articles/File_Download/Request-Lag-Time-Exceptions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>request a lag time exception</strong>
            </a>{' '}
            with Walmart for any product with an extended fulfillment latency (2
            days or more).
          </p>
          <p className="fs-00 margin-bottom-15">
            Ordoro will still set your fulfillment latency to whatever you
            desire, just know that you might get penalized if you do not request
            the exception!
          </p>
          <p className="fs-00 margin-bottom-0">
            <a
              href="https://marketplace.walmart.com/knowledgebase/articles/File_Download/Request-Lag-Time-Exceptions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Learn more</strong>
            </a>{' '}
            <span className="op-50">→</span>
          </p>
        </div>
      )}
    </ConfirmModal>
  )
}

FulfillmentLatencyModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    cartID: PropTypes.number.isRequired,
    fulfillment_latency: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
  errors: ErrorsShape.isRequired,
  productName: PropTypes.string.isRequired,
  saveFulfillmentLatency: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    errors: errorsSelector(state),
    productName: nameSelector(state, {sku: form.sku}),
  }
}

const mapDispatchToProps = {
  saveFulfillmentLatency,
  closeModal,
  updateForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(FulfillmentLatencyModal),
  modalFormSelector,
)
