import {
  shipperVendorConfigPropertySelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {FEDEX} from '../../../common/constants/ShipperNames.js'
import {useSelector} from '../../../store.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'pharmacy_delivery'
const VALID_SHIPPER_TYPES = [FEDEX]

export default function PharmacyDelivery() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  // temp_use_fedex_auth
  const [hasFedExNewCred] = useSelector((state) =>
    shipperVendorConfigPropertySelector(state, {
      labelInfoID,
      shipperType,
      property: 'child_key',
    }),
  )

  return (
    <ConfigCheckbox
      label="Pharmacy Delivery"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      hide={!hasFedExNewCred}
    />
  )
}
