import PropTypes from 'prop-types'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm(props) {
  if (_.isEmpty(props.columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-10" {...props}>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.reference_id}
          errorMessage={props.columnErrors.reference_id}
          name="reference_id"
          label="Batch ID"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={props.columns}
          value={props.columnsToDataMap.order_number}
          errorMessage={props.columnErrors.order_number}
          name="order_number"
          label="Order Number"
          className="medium-10"
          onChange={props.updateColumnMapping}
        />
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
