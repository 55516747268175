import PropTypes from 'prop-types'

import {isNumeric} from '../utils.js'

export function formatNumber(value = '', digits = 0) {
  if (!isNumeric(value)) {
    return null
  }

  return Number(value).toLocaleString('en-us', {
    maximumFractionDigits: digits,
  })
}

export default function PrettyNumber({value = '', digits = 0}) {
  const formattedValue = formatNumber(value, digits)
  if (!formattedValue) {
    return null
  }
  return <span>{formattedValue}</span>
}

PrettyNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  digits: PropTypes.number,
}
