import PropTypes from 'prop-types'
import moment from 'moment'

import Pages from './constants/Pages.js'
import {
  APPLY_TAGS,
  APPLY_PRESETS,
  ALLOCATE_ORDER,
  ASSIGN_TO_WAREHOUSE,
  MARK_AS_SHIPPED,
  DROPSHIP_TO,
} from './constants/Rules.js'

export const PaymentDetailsShape = PropTypes.shape({
  last4: PropTypes.string,
  brand: PropTypes.string,
  exp_month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stripe_customer_id: PropTypes.string,
  exp_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const FeaturesShape = PropTypes.object

export const CompanyShape = PropTypes.shape({
  activated: PropTypes.any,
  address: PropTypes.string,
  contact: PropTypes.string,
  created: PropTypes.string,
  currency_symbol: PropTypes.string,
  deactivated: PropTypes.any,
  email: PropTypes.string,
  fax: PropTypes.string,
  features: FeaturesShape,
  footer: PropTypes.string,
  id: PropTypes.number,
  mail_sender: PropTypes.string,
  name: PropTypes.string,
  payment_details: PropTypes.shape({
    stripe: PaymentDetailsShape,
    net_zero: PaymentDetailsShape,
  }),
  phone: PropTypes.string,
  plan: PropTypes.string,
  shipper_currency: PropTypes.string,
  stripe_customer_id: PropTypes.string,
  website: PropTypes.string,
})

export const LinkShape = PropTypes.shape({
  id: PropTypes.number,
  link: PropTypes.string,
})

export const addressProps = {
  city: PropTypes.string,
  name: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
  company: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  email: PropTypes.string,
  fax: PropTypes.string,
}

export const AddressShape = PropTypes.shape({
  link: PropTypes.string,
  ...addressProps,
})

export const WarehouseShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  address: AddressShape.isRequired,
})

export const ShipperShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  vendor_config: PropTypes.object.isRequired,
  archive_date: PropTypes.string,
})

export const CartShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  vendor_config: PropTypes.object.isRequired,
})

export const ShippingInfoShape = PropTypes.shape({
  tracking_number: PropTypes.string,
  tracking_url: PropTypes.string,
  cost: PropTypes.number,
  shipping_method: PropTypes.string,
  ship_date: PropTypes.string,
  label_link: PropTypes.string,
  carrier: PropTypes.shape({
    link: PropTypes.string,
  }),
})

export const OrderLineShape = PropTypes.shape({
  details: PropTypes.string,
  item_price: PropTypes.number,
  link: PropTypes.string,
  product_link: PropTypes.string,
  product_name: PropTypes.string,
  order_line_product_name: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shippability: PropTypes.shape({
    is_dropship: PropTypes.bool,
    shippability: PropTypes.string,
    supplier_id: PropTypes.number,
  }),
  sku: PropTypes.string,
})

export const CommentShape = PropTypes.shape({
  date: PropTypes.string,
  text: PropTypes.string,
  user: PropTypes.string,
})

export const StripeCommentShape = PropTypes.shape({
  date: PropTypes.string,
  text: PropTypes.string,
  user: PropTypes.string,
  chargeID: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
})

export const CommentGroupShape = PropTypes.shape({
  day: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(CommentShape),
})

export const OrderFinancialShape = PropTypes.shape({
  credit_card_issuer: PropTypes.string,
  discount_amount: PropTypes.number,
  product_amount: PropTypes.number,
  shipping_amount: PropTypes.number,
  tax_amount: PropTypes.number,
  grand_total: PropTypes.number,
})

export const DropshippingInfoShape = PropTypes.shape({
  requested_date: PropTypes.string,
  supplier: LinkShape,
  show_price: PropTypes.bool,
  instructions: PropTypes.string,
  requested_shipping_method: PropTypes.string,
})

export const OrderShape = PropTypes.shape({
  id: PropTypes.number,
  company_id: PropTypes.number,
  order_number: PropTypes.string,
  order_placed_date: PropTypes.string,
  updated_date: PropTypes.string,
  status: PropTypes.string,
  shipping_address: AddressShape,
  billing_address: AddressShape,
  lines: PropTypes.arrayOf(OrderLineShape),
  weight: PropTypes.number,
  notes_from_customer: PropTypes.string,
  requested_shipping_method: PropTypes.string,
  cart: LinkShape,
  warehouse: LinkShape,
  dropshipping_info: DropshippingInfoShape,
  shipping_info: ShippingInfoShape,
  return_shipping_info: ShippingInfoShape,
  comments: PropTypes.arrayOf(CommentShape),
  tags: PropTypes.arrayOf(LinkShape),
  financial: OrderFinancialShape,
})

export const RateShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  service: PropTypes.string,
  service_type: PropTypes.string,
  package: PropTypes.string,
  delivery_day: PropTypes.string,
  cost: PropTypes.number,
})

export const BulkRateShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  service: PropTypes.string,
  service_type: PropTypes.string,
  package: PropTypes.string,
  delivery_day: PropTypes.string,
  cost: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
})

export const RateErrorShape = PropTypes.shape({
  error_message: PropTypes.string,
  vendor: PropTypes.string,
  shipper_id: PropTypes.number,
})

export const SelectOptionValue = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

export const SelectOptionShape = PropTypes.shape({
  value: SelectOptionValue,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  nouns: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
})

export const SelectOptionsShape = PropTypes.arrayOf(SelectOptionShape)

export const SelectOptionTokens = PropTypes.object

export const TagShape = PropTypes.shape({
  id: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
})

export const TagsShape = PropTypes.arrayOf(TagShape)

export const PageShape = PropTypes.oneOf(
  Object.keys(Pages).map((key) => Pages[key]),
)

export const CustomsLineShape = PropTypes.shape({
  description: PropTypes.string.isRequired,
  quantity: PropTypes.any.isRequired,
  weightLB: PropTypes.any.isRequired,
  weightOZ: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  sku: PropTypes.string.isRequired,
  harmonizationCode: PropTypes.string.isRequired,
  harmonizationCodeCountry: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
})

export const ErrorsShape = PropTypes.shape({})

export const EmptyObjectShape = PropTypes.shape({})

export const PackingListShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  _link: PropTypes.string.isRequired,
  _linklogo: PropTypes.string,
  name: PropTypes.string.isRequired,
  custom_text: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  footer: PropTypes.string,
  is_default: PropTypes.bool,
})

export const ShipperFormShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const PostageBalanceShape = PropTypes.shape({
  addAmount: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isLoadingBalance: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
})

export const PackingListsShape = PropTypes.arrayOf(PackingListShape)

export const CurrentPackingListShape = PropTypes.oneOfType([
  EmptyObjectShape,
  PackingListShape,
])

export const TaskShape = PropTypes.shape({
  _link: PropTypes.string,
  _linkstart: PropTypes.string,
  _linkdata: PropTypes.string,
  _logurl: PropTypes.string,
  cart_id: PropTypes.number,
  company_id: PropTypes.number,
  complete: PropTypes.bool,
  created: PropTypes.string,
  created_display: PropTypes.string,
  creator: PropTypes.string,
  error_event_id: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  message_id: PropTypes.string,
  params: PropTypes.shape({
    shipment_ids: PropTypes.arrayOf(PropTypes.string),
  }),
  status: PropTypes.string,
  success: PropTypes.bool,
  table_key: PropTypes.string,
  type: PropTypes.string,
  updated: PropTypes.string,
  updated_display: PropTypes.string,
})

export const CartSettingsShape = PropTypes.object

export const DataImportColumnsShape = PropTypes.arrayOf(PropTypes.string)

export const DataImportColumnsToDataMapShape = PropTypes.objectOf(
  PropTypes.number,
)

export const DataImportDataShape = PropTypes.shape({
  isSaved: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  errors: ErrorsShape,
  index: PropTypes.number.isRequired,
})

export const LabelConfigShape = PropTypes.shape({})

export const PresetShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  created: PropTypes.string,
  updated: PropTypes.string,
  body: PropTypes.shape({
    version: PropTypes.string.isRequired,
    labelConfig: LabelConfigShape.isRequired,
    properties: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
})

export const UserShape = PropTypes.shape({
  _link: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  warehouses: PropTypes.arrayOf(PropTypes.object).isRequired,
})

export const UsersShape = PropTypes.arrayOf(UserShape)

export const UserFormShape = PropTypes.shape({
  link: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
  warehouseIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  permissions: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  ).isRequired,
})

export const PrinterShape = PropTypes.shape({
  capabilities: PropTypes.shape({
    bins: PropTypes.arrayOf(PropTypes.string),
    collate: PropTypes.bool,
    color: PropTypes.bool,
    copies: PropTypes.number,
    dpis: PropTypes.arrayOf(PropTypes.string),
    duplex: PropTypes.bool,
    extent: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    medias: PropTypes.arrayOf(PropTypes.string),
    nup: PropTypes.arrayOf(PropTypes.number),
    papers: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
    ]),
    printrate: PropTypes.objectOf(PropTypes.number),
    supports_custom_paper_size: PropTypes.bool,
  }),
  computer: PropTypes.shape({
    createTimestamp: PropTypes.string,
    hostname: PropTypes.string,
    id: PropTypes.number,
    inet: PropTypes.string,
    inet6: PropTypes.any,
    jre: PropTypes.any,
    name: PropTypes.string,
    state: PropTypes.string,
    version: PropTypes.string,
  }),
  createTimestamp: PropTypes.string,
  default: PropTypes.bool,
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  state: PropTypes.string,
})

export const PrintJobShape = PropTypes.shape({
  contentType: PropTypes.string,
  createTimestamp: PropTypes.string,
  expireAt: PropTypes.any,
  id: PropTypes.number,
  printer: PrinterShape,
  source: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.string,
})

export const RuleActionTypeShape = PropTypes.oneOf([
  ALLOCATE_ORDER,
  APPLY_TAGS,
  APPLY_PRESETS,
  ASSIGN_TO_WAREHOUSE,
  MARK_AS_SHIPPED,
  DROPSHIP_TO,
])

export const RuleActionShape = PropTypes.shape({
  type: RuleActionTypeShape.isRequired,
  data: PropTypes.object.isRequired,
})

export const RuleConditionShape = PropTypes.shape({
  variable: PropTypes.string.isRequired,
  comparator: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export const RuleShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  active: PropTypes.bool.isRequired,
  latch: PropTypes.string.isRequired,
  condition: PropTypes.object.isRequired,
  action: PropTypes.arrayOf(RuleActionShape).isRequired,
  internal_notes: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
})

export const WarehouseFormShape = PropTypes.shape({
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  street1: PropTypes.string.isRequired,
  street2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
})

const Supplier = {
  _link: PropTypes.string.isRequired,
  address: AddressShape.isRequired,
  archive_date: PropTypes.string,
  company_id: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  updated: PropTypes.string,
  vendor_config: PropTypes.object,
}

export const SupplierShape = PropTypes.shape(Supplier)

export const SupplierFilterShape = PropTypes.shape({
  ...Supplier,
  selected: PropTypes.bool.isRequired,
})

export const AppMessageShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  canDismiss: PropTypes.bool,
  redirectTo: PropTypes.string,
  needsRefresh: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  buttonText: PropTypes.string,
  modalSize: PropTypes.string,
})

function momentValidator(isRequired, props, propName, componentName) {
  const value = props[propName]
  const isValid = moment.isMoment(value)

  if (isValid) {
    return
  }

  if (isRequired) {
    // complain when nothing is passed
    if (value === null) {
      return new Error(
        `Prop \`${propName}\` supplied to \`${componentName}\` is marked as required. Should be a moment object, but its value is null.`,
      )
    }
    if (value === undefined) {
      return new Error(
        `Prop \`${propName}\` supplied to \`${componentName}\` is marked as required. Should be a moment object, but its value is undefined.`,
      )
    }
  }
  // complain about passing things that aren't nothing
  if (![null, undefined].includes(value)) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Should be a moment object.`,
    )
  }
}
export const MomentShape = momentValidator.bind(null, false)
MomentShape.isRequired = momentValidator.bind(null, true)

export const DateShape = PropTypes.instanceOf(Date)

export const ProductShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  upc: PropTypes.string,
})

export const ProductSupplierShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  address: AddressShape.isRequired,
  is_default: PropTypes.bool.isRequired,
  min_order_qty: PropTypes.number,
  supplier_price: PropTypes.number,
  supplier_sku: PropTypes.sku,
  vendor_config: PropTypes.object,
  _link: PropTypes.string.isRequired,
})

export const ProductWarehouseShape = PropTypes.shape({
  indirectly_committed: PropTypes.number,
  out_of_stock_threshold: PropTypes.number,
  is_configured_for_shipping: PropTypes.bool.isRequired,
  committed: PropTypes.number,
  on_hand: PropTypes.number,
  id: PropTypes.number.isRequired,
  directly_committed: PropTypes.number,
  low_stock_threshold: PropTypes.number,
  po_committed: PropTypes.number,
  available: PropTypes.number,
  updated: PropTypes.string,
  po_indirectly_committed: PropTypes.number,
  cart: PropTypes.object,
  is_default_location: PropTypes.bool.isRequired,
  physical_on_hand: PropTypes.number,
  address: AddressShape.isRequired,
  po_directly_committed: PropTypes.number,
  created: PropTypes.string,
  location_in_warehouse: PropTypes.string,
})

export const KitComponentShape = PropTypes.shape({
  productLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  childSKU: PropTypes.string.isRequired,
  physicalOnHand: PropTypes.number.isRequired,
  availableOnHand: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
})

export const ProductWarehouseItemsType = PropTypes.arrayOf(
  PropTypes.shape({
    warehouseID: PropTypes.number.isRequired,
    warehouseName: PropTypes.string.isRequired,
    onHand: PropTypes.number.isRequired,
    available: PropTypes.number.isRequired,
    committed: PropTypes.number.isRequired,
    moOrdered: PropTypes.number,
    poCommitted: PropTypes.number.isRequired,
  }),
)

export const SignInFormShape = PropTypes.shape({
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
})

export const SettingsPermissionsShape = PropTypes.shape({})

export const IntegrationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  _link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  vendor_display: PropTypes.string.isRequired,
  vendor_config: PropTypes.object.isRequired,
})

export const TableHeaderShape = PropTypes.shape({
  display: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  sort: PropTypes.string,
})

export const HeaderTabShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  attention: PropTypes.number,
})

export const PackLineShape = PropTypes.shape({
  path: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  upc: PropTypes.string,
  location: PropTypes.string,
  kit_components: PropTypes.array,
  orderedQuantity: PropTypes.number.isRequired,
  verifiedQuantity: PropTypes.number.isRequired,
  hasChildren: PropTypes.bool.isRequired,
})

export const GoodsReceiptLineShape = PropTypes.shape({
  sku: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  upc: PropTypes.string,
  location: PropTypes.string,
  received: PropTypes.number.isRequired,
  ordered: PropTypes.number.isRequired,
  isKit: PropTypes.bool.isRequired,
})

export const CountsShape = PropTypes.objectOf(PropTypes.number)

export const ScaleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
  mass: PropTypes.arrayOf(PropTypes.number).isRequired,
  computerName: PropTypes.string.isRequired,
})

export const GoodsReceiptShape = PropTypes.shape({
  goods_receipt_id: PropTypes.string.isRequired,
})

export const GoodsReceiptItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  po_item_id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
})

export const CartWarehouseLinkShape = PropTypes.shape({
  include: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
})

export const APIKeyShape = PropTypes.shape({
  _link: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  client_id: PropTypes.string.isRequired,
  client_secret: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  user_id: PropTypes.number.isRequired,
  company_id: PropTypes.number.isRequired,
})

export const LocationShape = PropTypes.shape({
  pathComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.object.isRequired,
})

export const LabelInfoIDShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
])

export const LabelInfoIDsShape = PropTypes.arrayOf(LabelInfoIDShape)

export const PrintConfigShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    layout: PropTypes.string,
    type: PropTypes.string,
    allowed_params: PropTypes.arrayOf(PropTypes.string),
    defaults: PropTypes.object,
  }).isRequired,
  created_date: PropTypes.string.isRequired,
  updated_date: PropTypes.string.isRequired,
  archive_date: PropTypes.string,
})

export const IntegrationIssueShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
})

export const ReturnOrderLineShape = PropTypes.shape({
  line_id: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  product_name: PropTypes.string.isRequired,
})

export const ReturnOrderShape = PropTypes.shape({
  reference_id: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(ReturnOrderLineShape).isRequired,
})

export const ReturnOrderListFormShape = PropTypes.shape({
  searchText: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  untagged: PropTypes.bool.isRequired,
  tagFilterBy: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReferenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const ReturnOrderCodeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
})
