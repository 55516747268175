import {all, takeEvery, put, call, select} from 'redux-saga/effects'

import {NEWGISTICS} from '../../../../../../common/constants/ShipperNames.js'
import {NEW_ID} from '../../../../../../common/constants/index.js'
import api from '../../../../../../common/api.js'
import {setGlobalError} from '../../../../../actions/ui/index.js'
import {setShipper} from '../../../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../../actions/ui/settings/shippers/index.js'
import {shipperFormSelector} from '../../../../../selectors/ui/settings/shippers/index.js'
import {SAVE_SHIPPER_NEWGISTICS} from '../../../../../actions/ui/settings/shippers/forms/newgistics.js'

export function* saveShipperNewgisticsWorker({payload: shipperID}) {
  const {
    name,
    hidden_shipping_methods,
    merchantID,
    clientFacilityID,
    ngsFacilityID,
  } = yield select(shipperFormSelector, {shipperID})

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    merchant_id: merchantID,
    client_facility_id: clientFacilityID,
    ngs_facility_id: ngsFacilityID,
  }

  yield put(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} =
      shipperID !== NEW_ID
        ? yield call(api.put, `/shipper/${shipperID}/`, params)
        : yield call(api.post, '/shipper/', {...params, vendor: NEWGISTICS})

    yield put(goToShipperSettings())

    yield call(setShipper, json)
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'There was an error while saving shipper.',
        },
        err,
      ),
    )

    yield put(updateForm(shipperID, {isSaving: false}))
  }
}

export default function* newgisticsSaga() {
  yield all([takeEvery(SAVE_SHIPPER_NEWGISTICS, saveShipperNewgisticsWorker)])
}
