import {useSelector} from '../../store.js'
import {updateCreateOrderFormBillingInfo} from './createOrderActions.js'
import {createOrderFormSelector} from './createOrderSelectors.js'
import AddressFilter from '../../common/components/AddressFilter.js'
import {PRIVACY_MASK} from '../../common/analytics/index.js'

export default function BillingInfo() {
  const {shippingInfo, billingInfo} = useSelector(createOrderFormSelector)

  const disabled = billingInfo.isSameAsShipping
  const {
    name,
    company,
    email,
    phone,
    street1,
    street2,
    city,
    state,
    zip,
    country,
  } = disabled ? shippingInfo : billingInfo

  return (
    <div className="medium-4 columns end wrap--billto">
      <div className="panel panel--form-wrap">
        <div className="panel__header flex--justify">
          <h3 className="fs-01">Billing Address</h3>
          <label className="label--checkbox lh-xl margin-bottom-0">
            <input
              className="checkbox"
              type="checkbox"
              checked={disabled}
              onChange={(event) =>
                updateCreateOrderFormBillingInfo({
                  isSameAsShipping: event.target.checked,
                })
              }
            />
            <span className="fs-n1"> Same as shipping</span>
          </label>
        </div>
        <div className="panel__body">
          <ul className="list list--form margin-bottom-5">
            <li className="list__item--form">
              <AddressFilter
                label="Name"
                value={name || ''}
                onSelect={(address) =>
                  updateCreateOrderFormBillingInfo({...address})
                }
                onChange={(name) => updateCreateOrderFormBillingInfo({name})}
                dropdown="CREATE_ORDER_BILLING_ADDRESS"
                disabled={disabled}
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_billto_company">
                Company
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_billto_company"
                value={company || ''}
                onChange={(event) =>
                  updateCreateOrderFormBillingInfo({
                    company: event.target.value,
                  })
                }
                disabled={disabled}
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_billto_email">
                Email
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_billto_email"
                value={email || ''}
                onChange={(event) =>
                  updateCreateOrderFormBillingInfo({email: event.target.value})
                }
                disabled={disabled}
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_billto_phone">
                Phone
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_billto_phone"
                value={phone || ''}
                onChange={(event) =>
                  updateCreateOrderFormBillingInfo({phone: event.target.value})
                }
                disabled={disabled}
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_billto_street1">
                Street 1
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_billto_street1"
                value={street1 || ''}
                onChange={(event) =>
                  updateCreateOrderFormBillingInfo({
                    street1: event.target.value,
                  })
                }
                disabled={disabled}
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_billto_street2">
                Street 2
              </label>
              <input
                type="text"
                className={`input--text input--med-h ${PRIVACY_MASK}`}
                id="id_billto_street2"
                value={street2 || ''}
                onChange={(event) =>
                  updateCreateOrderFormBillingInfo({
                    street2: event.target.value,
                  })
                }
                disabled={disabled}
              />
            </li>
            <li className="list__item--form flex--justify">
              <div className="wrap--input-two-thirds">
                <label className="label--bold" htmlFor="id_billto_city">
                  City
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_billto_city"
                  value={city || ''}
                  onChange={(event) =>
                    updateCreateOrderFormBillingInfo({city: event.target.value})
                  }
                  disabled={disabled}
                />
              </div>
              <div className="wrap--input-third">
                <label className="label--bold" htmlFor="id_billto_state">
                  State
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_billto_state"
                  value={state || ''}
                  onChange={(event) =>
                    updateCreateOrderFormBillingInfo({
                      state: event.target.value,
                    })
                  }
                  disabled={disabled}
                />
              </div>
            </li>
            <li className="list__item--form flex--justify">
              <div className="wrap--input-half">
                <label className="label--bold" htmlFor="id_billto_zip">
                  Zipcode
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_billto_zip"
                  value={zip || ''}
                  onChange={(event) =>
                    updateCreateOrderFormBillingInfo({zip: event.target.value})
                  }
                  disabled={disabled}
                />
              </div>
              <div className="wrap--input-half">
                <label className="label--bold" htmlFor="id_billto_country">
                  Country
                </label>
                <input
                  type="text"
                  className={`input--text input--med-h ${PRIVACY_MASK}`}
                  id="id_billto_country"
                  value={country || ''}
                  onChange={(event) =>
                    updateCreateOrderFormBillingInfo({
                      country: event.target.value,
                    })
                  }
                  disabled={disabled}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
