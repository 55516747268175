import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {usersSelector} from '../../../../data/users.js'
import {apiKeysSelector} from '../../../../redux/selectors/data/apiKeys.js'

const isEmailLikeRegex = /^.+@.+$/
const isEmailLike = (email) => email.match(isEmailLikeRegex) !== null

const isAPIKeyRegex = /^api_user_[^@]+$/
const isAPIKey = (email) => email.match(isAPIKeyRegex) !== null

const isOrdoroRegex = /^(user|dog_ipa|system)@ordoro.com$/
const isOrdoro = (email) => email.match(isOrdoroRegex) !== null

function makeUserNameFromEmailSelector({email}) {
  const type =
    (isOrdoro(email) && 'system_user') ||
    (isEmailLike(email) && 'user') ||
    (isAPIKey(email) && 'api_user') ||
    null

  return createSelector(usersSelector, apiKeysSelector, (users, apiKeys) => {
    if (type === 'system_user') {
      return 'System User'
    }

    if (type === 'user') {
      const user = Object.values(users).find((user) => user.email === email)

      if (user) {
        return `${user.name} (${user.email})`
      }
    }

    if (type === 'api_user') {
      const user = Object.values(users).find((user) => user.email === email)

      if (user && user.is_api_user) {
        const apiKey = Object.values(apiKeys).find(
          (apiKey) => apiKey.user_id === user.id,
        )

        if (apiKey) {
          return apiKey.name
        }
      }
    }

    return email
  })
}

function User({name}) {
  if (!name) {
    return null
  }

  return (
    <div className="attr--timeline-point">
      <em>
        {'– '}
        {name}
      </em>
    </div>
  )
}

User.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
}

const selectorCache = {}

function mapStateToProps(state, {email}) {
  const userNameFromEmailSelector =
    selectorCache[email] || makeUserNameFromEmailSelector({email})

  selectorCache[email] = userNameFromEmailSelector

  return (state) => ({
    name: userNameFromEmailSelector(state),
  })
}

export default connect(mapStateToProps)(User)
