import {PropTypes} from 'prop-types'
import {connect} from 'react-redux'

import {orderCartNameSelector} from '../../../../../data/orders.js'

function OrderCart({cartName}) {
  return <span>{cartName}</span>
}

OrderCart.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  cartName: PropTypes.string,
}

const mapStateToProps = (state, {orderNumber}) => ({
  cartName: orderCartNameSelector(state, {orderNumber}) || '',
})

export default connect(mapStateToProps)(OrderCart)
