import PropTypes from 'prop-types'

import LabelItem from './LabelItem.js'

export default function RequestedShippingMethod({method}) {
  return (
    <LabelItem label="Requested Shipping Method">
      {(method && method.trim()) || <em>None specified</em>}
    </LabelItem>
  )
}

RequestedShippingMethod.propTypes = {
  method: PropTypes.string,
}
