import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {ALL_STATUS, MO_STATII} from '../../common/constants/MOs.js'
import {TagsShape} from '../../common/PropTypes.js'
import {Plural, PluralBlock} from '../../common/components/Plural.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import RowAddress from '../../common/components/List/RowAddress.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import TimelinePopover from '../../common/components/List/TimelinePopover/index.js'
import {productsSelector, getProductName} from '../../data/products.js'
import {
  warehousesSelector,
  getWarehouseName,
  getWarehouseAddress,
} from '../../data/warehouses.js'
import {
  createMOSelector,
  moStatusSelector,
  createMOCommentGroupsSelector,
  createMOLinesSelector,
  createMOTotalSKUCountSelector,
  createMOTotalLineQuantitySelector,
  createMOCreatedDateSelector,
  createMOUpdatedDateSelector,
  createMOTagsSelector,
  moWarehouseIDSelector,
  manufacturerNotesSelector,
  internalNotesSelector,
} from '../../data/mos.js'
import {
  showMOManufacturerNotesModal,
  showMOInternalNotesModal,
  showMOWarehouseModal,
} from './Modals/EditMOParamModal.js'
import {moListSelectReferenceID, moListToggleExpanded} from './moListActions.js'
import {
  moListQuerySelector,
  createIsSelectedSelector,
  createIsExpandedSelector,
  createDetailURLSelector,
} from './moListSelectors.js'
import MOTags from './MOTags.js'

export function Row({
  referenceID,
  commentGroupsSelector,
  status,
  moStatus,
  warehouseID,
  isSelected,
  isExpanded,
  detailURL,
  lines,
  totalSKUCount,
  totalLineQuantity,
  createdDate,
  updatedDate,
  internalNotes,
  manufacturerNotes,
  tags,
  warehousesByID,
  productsBySKU,
}) {
  const warehouse = warehousesByID[warehouseID]

  return (
    <tr
      className={classNames('table__tr table__tr--mo-row', {
        'table__tr--expanded': isExpanded,
      })}
    >
      <td className="table__td table__td--order-checkbox relative">
        <TimelinePopover
          title="MO Timeline"
          type="mo"
          commentGroupsSelector={commentGroupsSelector}
        />
        <Checkbox
          checked={isSelected}
          onClick={(checked, event) =>
            moListSelectReferenceID(referenceID, checked, event.shiftKey)
          }
        />
      </td>
      <td className="table__td">
        {status === ALL_STATUS &&
          (() => {
            const {badge, name} =
              MO_STATII.find(({slug}) => slug === moStatus) || {}

            return (
              <div
                className="status-badge inline-block lh-md tooltip--top margin-right-5 v-align-top"
                data-hint={name}
              >
                <strong>{badge}</strong>
              </div>
            )
          })()}
        <div className="wrap--product-name inline-block">
          <a
            className="btn btn--link darkest fs-n1 lh-md margin-right-5"
            href={detailURL}
          >
            <strong>{referenceID}</strong>
          </a>
        </div>
        {tags.length > 0 && (
          <div
            className={
              isExpanded
                ? 'wrap--po-addl-info list list--order-data'
                : 'wrap--summary-tags-table wrap--summary-tags-inline'
            }
          >
            <MOTags referenceID={referenceID} tags={tags} />
          </div>
        )}
        <dl className="wrap--po-addl-info list list--order-data with-top-divider">
          <dt className="list__title--order-data">Notes for Manufacturer</dt>
          {manufacturerNotes ? (
            <dd className="list__item--order-data">
              <div className="ws-pre-wrap">{manufacturerNotes}</div>
              <ButtonLink
                className="meta-molist-button-edit-internal-note"
                onClick={() => showMOManufacturerNotesModal(referenceID)}
              >
                Edit
              </ButtonLink>
            </dd>
          ) : (
            <dd className="list__item--order-data">
              <ButtonLink
                className="meta-molist-button-add-internal-note"
                onClick={() => showMOManufacturerNotesModal(referenceID)}
              >
                Add note
              </ButtonLink>
            </dd>
          )}
        </dl>

        <dl className="wrap--po-addl-info list list--order-data with-top-divider">
          <dt className="list__title--order-data">Internal Notes</dt>
          {internalNotes ? (
            <dd className="list__item--order-data">
              <div className="ws-pre-wrap">{internalNotes}</div>
              <ButtonLink
                className="meta-molist-button-edit-internal-note"
                onClick={() => showMOInternalNotesModal(referenceID)}
              >
                Edit
              </ButtonLink>
            </dd>
          ) : (
            <dd className="list__item--order-data">
              <ButtonLink
                className="meta-molist-button-add-internal-note"
                onClick={() => showMOInternalNotesModal(referenceID)}
              >
                Add note
              </ButtonLink>
            </dd>
          )}
        </dl>
      </td>
      <td className="table__td">
        <div className="fs-n0">
          <PluralBlock count={totalSKUCount}>
            <strong>
              {totalSKUCount} <Plural word="SKU" />
            </strong>{' '}
          </PluralBlock>
          <PluralBlock count={totalLineQuantity}>
            ({totalLineQuantity} <Plural p="total " />
            <Plural word="item" />)
          </PluralBlock>
        </div>

        {isExpanded &&
          lines.map((line) => (
            <div className="flex flex--justify divider--top" key={line.id}>
              <div className="margin-right-10">
                <div className="fs-n0 lh-md">
                  <div>{getProductName(productsBySKU[line.sku])}</div>
                  <div className="margin-bottom-5">{line.sku}</div>
                </div>
              </div>
              <div className="fs-n0">{line.requested_quantity}</div>
            </div>
          ))}
      </td>

      <td className="table__td">
        <div>
          <strong className="fs-n1">{getWarehouseName(warehouse)}</strong>
        </div>
        <div className="wrap--po-addl-info divider--top">
          <RowAddress
            address={getWarehouseAddress(warehouse)}
            noPhone
            noEmail
          />
        </div>
        <ButtonLink
          className="meta-returnorderlist-button-edit-warehouse"
          onClick={() => showMOWarehouseModal(referenceID)}
        >
          Edit
        </ButtonLink>
      </td>
      <td className="table__td">
        <div className="fs-n1 margin-bottom-1">
          <FormattedDate value={createdDate} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={createdDate} format="h:mma" />
        </div>
        <em className="fs-n2 text--lt-grey">
          Updated: <FormattedDate value={updatedDate} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={updatedDate} format="h:mma" />
        </em>
      </td>

      <td className="table__td align-right">
        <ButtonLink
          className="no-underline btn--expand-product-row"
          onClick={() => moListToggleExpanded(referenceID)}
        >
          <span
            className="i--expand fs-01 lh-0 v-align-middle"
            aria-hidden="true"
          />
        </ButtonLink>
      </td>
    </tr>
  )
}

Row.propTypes = {
  referenceID: PropTypes.string.isRequired,
  originalOrderNumber: PropTypes.string,
  commentGroupsSelector: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  moStatus: PropTypes.string.isRequired,
  warehouseID: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  detailURL: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalSKUCount: PropTypes.number.isRequired,
  totalLineQuantity: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
  updatedDate: PropTypes.string.isRequired,
  internalNotes: PropTypes.string,
  manufacturerNotes: PropTypes.string,
  tags: TagsShape.isRequired,
  warehousesByID: PropTypes.object.isRequired,
  productsBySKU: PropTypes.object.isRequired,
}

function mapStateToProps(_state, {referenceID}) {
  const moSelector = createMOSelector(referenceID)
  const commentGroupsSelector = createMOCommentGroupsSelector(moSelector)
  const isSelectedSelector = createIsSelectedSelector(referenceID)
  const isExpandedSelector = createIsExpandedSelector(referenceID)
  const detailURLSelector = createDetailURLSelector(referenceID)
  const linesSelector = createMOLinesSelector(moSelector)
  const totalSKUCountSelector = createMOTotalSKUCountSelector(moSelector)
  const totalLineQuantitySelector =
    createMOTotalLineQuantitySelector(moSelector)
  const createdDateSelector = createMOCreatedDateSelector(moSelector)
  const updatedDateSelector = createMOUpdatedDateSelector(moSelector)
  const tagsSelector = createMOTagsSelector(moSelector)

  return (state) => ({
    commentGroupsSelector,
    status: moListQuerySelector(state).status,
    moStatus: moStatusSelector(state, {referenceID}),
    warehouseID: moWarehouseIDSelector(state, {referenceID}),
    isSelected: isSelectedSelector(state),
    isExpanded: isExpandedSelector(state),
    detailURL: detailURLSelector(state),
    lines: linesSelector(state),
    totalSKUCount: totalSKUCountSelector(state),
    totalLineQuantity: totalLineQuantitySelector(state),
    createdDate: createdDateSelector(state),
    updatedDate: updatedDateSelector(state),
    internalNotes: internalNotesSelector(state, {referenceID}),
    manufacturerNotes: manufacturerNotesSelector(state, {referenceID}),
    tags: tagsSelector(state),
    warehousesByID: warehousesSelector(state),
    productsBySKU: productsSelector(state),
  })
}

export default connect(mapStateToProps)(Row)
