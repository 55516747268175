import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {removeHazmatItem} from '../../../data/labelInfos/index.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'

export const MODAL_FORM = 'REMOVE_HAZMAT_ITEM_MODAL'

export function showRemoveHazmatItemModal(
  labelInfoID,
  packageIndex,
  hazmatItemIndex,
) {
  setForm(MODAL_FORM, {
    labelInfoID,
    packageIndex,
    hazmatItemIndex,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function remove() {
  const {labelInfoID, packageIndex, hazmatItemIndex} =
    modalFormSelector(getState())

  removeHazmatItem(labelInfoID, packageIndex, hazmatItemIndex)

  closeModal()

  showMessageToast('Hazmat info was successfully removed.')

  await getRates([labelInfoID])
}

function RemoveHazmatItemModal() {
  return (
    <ConfirmModal
      title="Remove HazMat Declaration"
      onConfirm={() => remove()}
      onCancel={() => closeModal()}
    >
      <p className="fs-01 lh-md">
        Are you sure you want to remove this declaration?
      </p>
    </ConfirmModal>
  )
}

RemoveHazmatItemModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    packageIndex: PropTypes.number.isRequired,
    hazmatItemIndex: PropTypes.number,
  }).isRequired,
}

export default onlyIfForm(RemoveHazmatItemModal, modalFormSelector)
