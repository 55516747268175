import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import Progress from './Progress.js'
import Options from './Options.js'
import Summary from './Summary.js'

import {clear, remapColumns} from '../../redux/actions/ui/dataImport/index.js'

import {progressSelector} from '../../redux/selectors/ui/dataImport/progress.js'

class Overview extends Component {
  static propTypes = {
    saveAll: PropTypes.func.isRequired,
    queueNextData: PropTypes.func.isRequired,
    stop: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    remapColumns: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isValidatingData: PropTypes.bool,
    isSaving: PropTypes.bool,
    progress: PropTypes.object.isRequired,
  }

  fixColumnsParams() {
    return {
      step: 2,
      message: 'Verify Column Titles',
      subMessage: this.props.isValidatingData
        ? 'Mapping column titles...'
        : 'Make sure the column titles in your CSV (dropdowns) map  correctly to the standard column titles in Ordoro (bold text). To remove a column, leave the dropdown blank.',
    }
  }

  savingParams() {
    return {
      step: 3,
      message: 'Save Data Entries',
      subMessage:
        'We’ve started saving your data. You might want to grab a coffee in the meantime...',
      button: {
        className: 'btn btn--primary btn--primary-ol btn--md margin-bottom-0',
        onClick: this.props.stop,
        text: 'Stop',
      },
      showSummary: true,
      showRemap: true,
    }
  }

  fixDataParams() {
    return {
      step: 3,
      message: 'Save Data Entries',
      subMessage:
        'Use the “Save All” button to start importing your data in Ordoro. You can also edit or save each entry individually by selecting “Preview each entry.”',
      button: {
        className: 'btn btn--primary btn--md margin-bottom-0',
        onClick: this.props.saveAll,
        text: 'Save All',
      },
      showOptions: true,
      showSummary: true,
      showRemap: true,
    }
  }

  doneParams() {
    return {
      message: 'Your data has been saved!',
      button: {
        className: 'btn btn--link fs-00',
        onClick: this.props.clear,
        text: 'Import another CSV file',
      },
    }
  }

  render() {
    let params

    if (this.props.progress.isDone) {
      params = this.doneParams()
    } else if (this.props.isSaving) {
      params = this.savingParams()
    } else if (this.props.data.length !== 0) {
      params = this.fixDataParams()
    } else if (this.props.rows.length !== 0) {
      params = this.fixColumnsParams()
    } else {
      params = {}
    }

    if (params.step) {
      params.stepHTML = (
        <strong className="make-medium-grey">Step {params.step} of 3</strong>
      )
    }

    if (params.button) {
      params.buttonHTML = (
        <button
          className={params.button.className}
          disabled={params.button.disabled}
          onClick={params.button.onClick}
        >
          {params.button.text}
        </button>
      )
    }

    if (params.showRemap) {
      params.remapHTML = (
        <button
          className="inline-text-button make-smaller margin-bottom-0 margin-left-20"
          onClick={this.props.remapColumns}
        >
          <span>&#171;</span> <span>Redo column mappings</span>
        </button>
      )
    }

    return (
      <div className="list-actions row margin-top-0">
        <div className="medium-12 small-12 columns main-action-button-group margin-bottom-0">
          <h4 className="margin-top-0 margin-bottom-20 make-medium-dark-grey">
            <strong>{this.props.title}</strong>
          </h4>
          <Progress />
          <dl className="medium-10 margin-bottom-20">
            <dt>
              <div className="make-smaller margin-bottom-3">
                {params.stepHTML}
              </div>
              <div>{params.message}</div>
            </dt>
            <dd>{params.subMessage}</dd>
            <dd>
              {params.showSummary ? <Summary /> : null}
              {params.remapHTML}
            </dd>
            <dd className="margin-bottom-0">
              {params.buttonHTML}
              {params.showOptions ? (
                <Options queueNextData={this.props.queueNextData} />
              ) : null}
            </dd>
          </dl>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {current, columns, rows, data, isValidatingData, isSaving} =
    state.ui.dataImport

  return {
    title: current,
    columns,
    rows,
    data,
    isValidatingData,
    isSaving,
    progress: progressSelector(state),
  }
}

const mapDispatchToProps = {
  clear,
  remapColumns,
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
