import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SelectOptionsShape, SelectOptionTokens} from '../../common/PropTypes.js'
import {TAG_TYPE__RETURN_ORDER} from '../../common/constants/Tags.js'
import {
  addTagToReturnOrders,
  removeTagFromReturnOrders,
  returnOrderTagOptionsSelector,
  returnOrderTagOptionTokensSelector,
} from '../../data/returnOrderTags.js'
import {
  hasReturnOrderManageTagPermissionSelector,
  hasReturnOrderManageTagOnReturnOrderPermissionSelector,
} from '../../data/me.js'
import ManageTagFilter from '../../common/components/ManageTagFilter.js'
import {createAndApplyTagToReturnOrders} from './returnOrderListActions.js'
import {returnOrderTagQuantityMapSelector} from './returnOrderListSelectors.js'

function AddReturnOrderTagsDropdown({
  referenceIDs,
  returnOrderTagOptions,
  returnOrderTagOptionTokens,
  tagQuantityMap,
  hasReturnOrderManageTagPermission,
  hasReturnOrderManageTagOnReturnOrderPermission,
}) {
  if (!hasReturnOrderManageTagOnReturnOrderPermission) {
    return null
  }

  return (
    <li className="inline-block margin-left-10">
      <ManageTagFilter
        dropdown="APPLY_RETURN_ORDER_TAG_FILTER"
        onSelect={(tag) => {
          if (tagQuantityMap[tag.id] === 'all') {
            removeTagFromReturnOrders(tag.id, referenceIDs)
          } else {
            addTagToReturnOrders(tag.id, referenceIDs)
          }
        }}
        tagOptions={returnOrderTagOptions}
        tagOptionTokens={returnOrderTagOptionTokens}
        tagQuantityMap={tagQuantityMap}
        hasManageTagPermission={hasReturnOrderManageTagPermission}
        tagType={TAG_TYPE__RETURN_ORDER}
        onCreateAndApplyClick={() =>
          createAndApplyTagToReturnOrders(referenceIDs)
        }
      />
    </li>
  )
}

AddReturnOrderTagsDropdown.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  returnOrderTagOptions: SelectOptionsShape.isRequired,
  returnOrderTagOptionTokens: SelectOptionTokens.isRequired,
  tagQuantityMap: PropTypes.object.isRequired,
  hasReturnOrderManageTagPermission: PropTypes.bool.isRequired,
  hasReturnOrderManageTagOnReturnOrderPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {referenceIDs}) {
  return {
    returnOrderTagOptions: returnOrderTagOptionsSelector(state),
    returnOrderTagOptionTokens: returnOrderTagOptionTokensSelector(state),
    tagQuantityMap: returnOrderTagQuantityMapSelector(state, {referenceIDs}),
    hasReturnOrderManageTagPermission:
      hasReturnOrderManageTagPermissionSelector(state),
    hasReturnOrderManageTagOnReturnOrderPermission:
      hasReturnOrderManageTagOnReturnOrderPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(AddReturnOrderTagsDropdown)
