import {createSelector} from 'reselect'

export const addPurchaseOrderCommentModalSelector = createSelector(
  (state) => state.ui.modals.addPurchaseOrderCommentModal,
  (addPurchaseOrderCommentModal) => addPurchaseOrderCommentModal,
)

export const poIDSelector = createSelector(
  addPurchaseOrderCommentModalSelector,
  ({poID}) => poID,
)

export const commentSelector = createSelector(
  addPurchaseOrderCommentModalSelector,
  ({comment}) => comment,
)

export const isAddingSelector = createSelector(
  addPurchaseOrderCommentModalSelector,
  ({isAdding}) => isAdding,
)

export const serverErrorSelector = createSelector(
  addPurchaseOrderCommentModalSelector,
  ({serverError}) => serverError,
)
