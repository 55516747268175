import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {TagsShape} from '../../common/PropTypes.js'
import {removeTagFromReturnOrders} from '../../data/returnOrderTags.js'
import {hasReturnOrderManageTagOnReturnOrderPermissionSelector} from '../../data/me.js'
import AssignedTags from '../../common/components/List/AssignedTags/index.js'

export function ReturnOrderTags({
  referenceID,
  tags,
  hasReturnOrderManageTagOnReturnOrderPermission,
}) {
  return (
    <AssignedTags
      tags={tags}
      hasManageTagOnOrderPermission={
        hasReturnOrderManageTagOnReturnOrderPermission
      }
      onRemove={(tag) => removeTagFromReturnOrders(tag.id, [referenceID])}
    />
  )
}

ReturnOrderTags.propTypes = {
  referenceID: PropTypes.string.isRequired,
  tags: TagsShape.isRequired,
  hasReturnOrderManageTagOnReturnOrderPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    hasReturnOrderManageTagOnReturnOrderPermission:
      hasReturnOrderManageTagOnReturnOrderPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(ReturnOrderTags)
