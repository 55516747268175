import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'

import {getState, setForm, updateForm, formsSelector} from '../store.js'
import api from '../common/api.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {TAG_TYPE__PRODUCT} from '../common/constants/Tags.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {patchProductTag, productsSelector} from '../data/products.js'
import {refreshProductList} from '../ordoro/ProductListPage/productListFunctions.js'
import {tokenizeOptions, getNounsFromValue} from '../common/tokenizeOptions.js'
import userflow from '../common/analytics/userflow.js'

export const PRODUCT_TAGS = 'PRODUCT_TAGS'

export function setProductTags(productTags) {
  setForm(PRODUCT_TAGS, keyBy(productTags, 'id'))
}

export function updateProductTag(productTag) {
  updateForm(PRODUCT_TAGS, {[productTag.id]: productTag})
}

export function removeProductTag(productTag) {
  const productTags = {...productTagsSelector(getState())}

  delete productTags[productTag.id]

  setForm(PRODUCT_TAGS, productTags)
}

export function productTagsSelector(state) {
  return formsSelector(state)[PRODUCT_TAGS] || productTagsSelector.default
}
productTagsSelector.default = {}

export function productTagSelector(state, {productTagID}) {
  return productTagsSelector(state)[productTagID]
}

export const productTagsSortedByNameSelector = createSelector(
  productTagsSelector,
  (productTags) => sortBy(productTags, ({name}) => name.toLowerCase()),
)

export const productTagOptionsSelector = createSelector(
  productTagsSortedByNameSelector,
  (tags) =>
    tags.map((tag) => ({
      value: tag.id,
      display: tag.name,
      entity: tag,
      type: TAG_TYPE__PRODUCT,
      nouns: getNounsFromValue(tag.name),
    })),
)

export const productTagOptionTokensSelector = createSelector(
  productTagOptionsSelector,
  (tagOptions) => tokenizeOptions(tagOptions),
)

export function productTagsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PRODUCT_TAGS]
}

export async function getProductTags() {
  try {
    const productTags = await fetchAllAPI('/product/tag/', 'tag')

    setProductTags(productTags)

    userflow.updateGroup({
      product_tag_count: productTags.length,
    })
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting product tags.',
        details: err.error_message || err.message,
      },
      err,
    )

    setProductTags([])
  }
}

export async function saveProductTag(name, color, id) {
  const params = {name: name.trim(), color}
  const originalTag = id
    ? productTagSelector(getState(), {productTagID: id})
    : null

  const {json} = id
    ? await api.put(
        `/product/tag/${encodeURIComponent(originalTag.name)}`,
        params,
      )
    : await api.post('/product/tag', params)

  updateProductTag(json)

  if (id) {
    await refreshProductList()
  }

  return json
}

export async function deleteProductTag(tagID) {
  const tag = productTagSelector(getState(), {productTagID: tagID})

  await api.delete(`/product/tag/${encodeURIComponent(tag.name)}`)

  removeProductTag(tag)
}

export async function addTagToProducts(productTagID, skus) {
  const products = productsSelector(getState())
  const tag = productTagSelector(getState(), {productTagID})

  const skusWithoutTag = skus.filter(
    (sku) =>
      !get(products, [sku, 'tags'], []).find((t) => t.id === productTagID),
  )

  skusWithoutTag.map((sku) => patchProductTag(sku, tag, true))

  try {
    await Promise.all(
      skusWithoutTag.map((sku) =>
        api.post(
          `/product/${encodeURIComponent(sku)}/tag/${encodeURIComponent(
            tag.name,
          )}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error adding product tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    skusWithoutTag.map((sku) => patchProductTag(sku, tag, false))
  }
}

export async function removeTagFromProducts(productTagID, skus) {
  const products = productsSelector(getState())
  const tag = productTagSelector(getState(), {productTagID})

  const skusWithTag = skus.filter((sku) =>
    get(products, [sku, 'tags'], []).find((t) => t.id === productTagID),
  )

  skusWithTag.map((sku) => patchProductTag(sku, tag, false))

  try {
    await Promise.all(
      skusWithTag.map((sku) =>
        api.delete(
          `/product/${encodeURIComponent(sku)}/tag/${encodeURIComponent(
            tag.name,
          )}`,
        ),
      ),
    )
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error removing product tag.',
        details: error.error_message || error.message,
      },
      error,
    )

    skusWithTag.map((sku) => patchProductTag(sku, tag, true))
  }
}
