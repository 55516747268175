export const BASE = 'ordoro/ui/tasks'
export const VIEW_TASKS = `${BASE}/VIEW_TASKS`
export const CLEAR_EXPORTS = `${BASE}/CLEAR_EXPORTS`

export function viewTasks() {
  return {
    type: VIEW_TASKS,
    meta: {sticky: VIEW_TASKS},
  }
}

export function clearExports() {
  return {
    type: CLEAR_EXPORTS,
    meta: {sticky: CLEAR_EXPORTS},
  }
}
