import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import api from '../../../../common/api.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import DatePicker from '../../../../common/components/DatePicker.js'
import {
  ACCOUNTING,
  SETTINGS,
} from '../../../../common/constants/SettingsPanels.js'
import {navigate, redirect} from '../../../../common/location.js'
import {buildPath} from '../../../../common/querystring.js'
import {
  getIntegrations,
  integrationSelector,
  setIntegration,
} from '../../../../data/integrations.js'
import {checkRunningTasks} from '../../../../redux/actions/data/isRunningTasks.js'
import {
  dispatch,
  formSelector,
  getState,
  onlyIfAutoForm,
  updateForm,
  useSelector,
} from '../../../../store.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {DateShape} from '../../../../common/PropTypes.js'
import CartIntegrationBridgeConfig from '../CartIntergrationBridgeConfig.js'
import verde from '../../../../common/verde.js'
import {accountingAuthIntegrationIssueSelector} from '../../../../redux/selectors/ui/dashboard.js'

export function setupForm({integrationID, formName}) {
  const integration = integrationID
    ? integrationSelector(getState(), {integrationID})
    : {
        vendor_config: {
          create_products: false,
        },
      }

  return {
    formName,
    initialForm: {
      id: integrationID,
      create_products: integration.vendor_config.create_products || false,
      backoff_date: null,
      integrationIssue: false,
      isSaving: false,
      isExportingOrders: false,
      isExportingProducts: false,
      isExportingPOs: false,
      serverError: null,
    },
  }
}

export function authorize() {
  const params = {
    redirect: '/app/settings/accounting',
  }

  return redirect(null, ['verde', 'quickbooks', 'start'], params)
}

export async function uninstall(formName) {
  try {
    const {id} = formSelector(getState(), {formName})

    await verde.get('/quickbooks/uninstall', {
      integration_id: id,
    })

    showMessageToast('Uninstalled integration')

    await getIntegrations()
  } catch (err) {
    updateForm(formName, {
      serverError: `Error uninstalling QuickBooks: ${
        err.error_message || err.message
      }`,
    })
  }
}

export async function archive(formName) {
  try {
    const {id} = formSelector(getState(), {formName})

    await api.delete(`/integration/${id}/`)

    showMessageToast('Archived integration')

    await getIntegrations()
  } catch (err) {
    updateForm(formName, {
      serverError: `Error archiving QuickBooks: ${
        err.error_message || err.message
      }`,
    })
  }
}

export async function exportOrdersToQuickBooks(formName, integrationID) {
  try {
    const {backoff_date} = formSelector(getState(), {formName})

    updateForm(formName, {isExportingOrders: true, serverError: null})

    const params = {integrationID}

    if (backoff_date) {
      params.backoff_date = backoff_date
    }

    await api.post('/new_task', {
      type: 'export_orders_to_integration',
      params,
    })

    dispatch(checkRunningTasks())

    showMessageToast(
      'Your latest order data has started exporting to QuickBooks...',
    )
  } catch (err) {
    updateForm(formName, {
      serverError: `Error starting export task to QuickBooks: ${
        err.error_message || err.message
      }`,
    })
  } finally {
    updateForm(formName, {isExportingOrders: false})
  }
}

export async function exportProductsToQuickBooks(formName, integrationID) {
  try {
    const {backoff_date} = formSelector(getState(), {formName})

    updateForm(formName, {isExportingProducts: true, serverError: null})

    const params = {integrationID}

    if (backoff_date) {
      params.backoff_date = backoff_date
    }

    await api.post('/new_task', {
      type: 'export_products_to_integration',
      params,
    })

    dispatch(checkRunningTasks())

    showMessageToast(
      'Your latest inventory data has started exporting to QuickBooks...',
    )
  } catch (err) {
    updateForm(formName, {
      serverError: `Error starting export task to QuickBooks: ${
        err.error_message || err.message
      }`,
    })
  } finally {
    updateForm(formName, {isExportingProducts: false})
  }
}

export async function exportPOsToQuickBooks(formName, integrationID) {
  try {
    const {backoff_date} = formSelector(getState(), {formName})

    updateForm(formName, {isExportingPOs: true, serverError: null})

    const params = {integrationID}

    if (backoff_date) {
      params.backoff_date = backoff_date
    }

    await api.post('/new_task', {
      type: 'export_purchase_orders_to_integration',
      params,
    })

    dispatch(checkRunningTasks())

    showMessageToast(
      'Your latest purchase order data has started exporting to QuickBooks...',
    )
  } catch (err) {
    updateForm(formName, {
      serverError: `Error starting export task to QuickBooks: ${
        err.error_message || err.message
      }`,
    })
  } finally {
    updateForm(formName, {isExportingPOs: false})
  }
}

export async function saveQuickBooks(formName, integrationID) {
  try {
    const {create_products} = formSelector(getState(), {formName})

    updateForm(formName, {
      serverError: null,
      isSaving: true,
    })

    const {json: integration} = await api.put(
      buildPath(['integration', integrationID]),
      {
        create_products,
      },
    )

    setIntegration(integration)

    showMessageToast('Updated integration')
  } catch (err) {
    updateForm(formName, {
      serverError: `Error saving integration: ${
        err.error_message || err.message
      }`,
    })
  } finally {
    updateForm(formName, {isSaving: false})
  }
}

export async function checkQuickbooksAccess(integrationID, formName) {
  const integration = integrationSelector(getState(), {integrationID})

  if (!integration) {
    return
  }

  try {
    await verde.post(`/quickbooks/access_token/${integrationID}`)

    updateForm(formName, {
      integrationIssue: false,
    })
  } catch (err) {
    updateForm(formName, {
      integrationIssue: true,
    })
  }
}

function QuickBooks({isNew, integrationID, formName, form}) {
  const integration = useSelector((state) =>
    integrationSelector(state, {integrationID}),
  )
  const integrationIssue =
    useSelector((state) =>
      isNew
        ? false
        : accountingAuthIntegrationIssueSelector(state, {
            integrationID: integration.id,
          }),
    ) || form.integrationIssue
  const [now] = useState(new Date())

  useEffect(() => {
    checkQuickbooksAccess(integrationID, formName)
  }, [integration ? integration.vendor_config.access_token : null])

  return (
    <div className="settings-list-item-form-wrap settings-list-item-form-cp clear-both padding-top-10">
      <div className="wrap--row">
        <div className="medium-6 columns">
          <ul className="list list--no-style">
            {form.serverError && (
              <li className="list__item margin-bottom-10">
                <small className="error error-message">
                  {form.serverError}
                </small>
              </li>
            )}
            {isNew ? (
              <>
                <li className="list__item margin-top-5 margin-bottom-10 margin-right-25">
                  <em>
                    You’ll be taken to QuickBooks to authorize Ordoro to use
                    your account.
                  </em>
                </li>
                <li className="list__item margin-top-20">
                  <button
                    type="button"
                    className="btn btn--primary btn--qb"
                    onClick={() => authorize()}
                  >
                    Connect to QuickBooks
                  </button>
                  <button
                    type="button"
                    className="btn btn--secondary"
                    onClick={() => navigate([SETTINGS, ACCOUNTING])}
                  >
                    Cancel
                  </button>
                </li>
              </>
            ) : integrationIssue ? (
              <>
                <li className="list__item margin-bottom-0">
                  <ButtonPrimary
                    className="margin-right-10 btn--qb"
                    onClick={() => authorize()}
                  >
                    Connect to QuickBooks
                  </ButtonPrimary>
                </li>
              </>
            ) : null}
          </ul>
        </div>
        {isNew && (
          <div className="medium-5 columns">
            <div className="alert margin-bottom-10 alert--standard">
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help authorizing your QuickBooks account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/ordoros-quickbooks-online-integration/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get step-by-step instructions →
                  </a>
                </dd>
              </dl>
            </div>
          </div>
        )}
      </div>
      {!isNew && (
        <div className="wrap--row">
          <div className="medium-9 columns">
            <ul className="list list--no-style">
              <li className="list__item divider--top">
                <h4>
                  <strong>Data Export Options</strong>
                </h4>
                <p className="fs-00 lh-md">
                  Export order, inventory and PO data from Ordoro to your
                  QuickBooks account.
                </p>

                <div className="fs-00 margin-top-20 margin-bottom-20">
                  <label
                    className="label--bold margin-bottom-0"
                    htmlFor={`${integrationID}_backoff_date`}
                  >
                    Export Start Date{' '}
                    <span className="unbold">
                      (based on date orders were shipped)
                    </span>
                  </label>
                  <div className="margin-bottom-10">
                    <em className="fs-n1 lh-sm">
                      Leave field blank for max date range.
                    </em>
                  </div>
                  <div className="w-50">
                    <DatePicker
                      id={`${integrationID}_backoff_date`}
                      onChange={(date) =>
                        updateForm(formName, {backoff_date: date})
                      }
                      selected={form.backoff_date}
                      maxDate={now}
                    />
                  </div>
                </div>

                <dl className="list">
                  <dt className="list__title fs-01">Order Export</dt>
                  <dd className="list__item fs-00 ">
                    <ButtonPrimary
                      alt
                      size="sm"
                      onClick={() =>
                        exportOrdersToQuickBooks(formName, integrationID)
                      }
                      isLoading={form.isExportingOrders}
                    >
                      Export Latest Order Data
                    </ButtonPrimary>
                  </dd>
                  <dd className="list__item margin-bottom-0">
                    <CartIntegrationBridgeConfig formName={formName} />
                  </dd>
                </dl>
                <dl className="list divider--top dark">
                  <dt className="list__title fs-01">Inventory Export</dt>
                  <dd className="list__item fs-00">
                    <ButtonPrimary
                      alt
                      size="sm"
                      onClick={() =>
                        exportProductsToQuickBooks(formName, integrationID)
                      }
                      isLoading={form.isExportingProducts}
                      isDisabled={!integration.vendor_config.create_products}
                    >
                      Export Latest Inventory Data
                    </ButtonPrimary>
                  </dd>
                  <dd className="list__item fs-00 margin-top-20">
                    <div className="margin-bottom-10">
                      <Checkbox
                        id="create_products"
                        label="Create missing products in QuickBooks"
                        checked={form.create_products}
                        onChange={() =>
                          updateForm(formName, {
                            create_products: !form.create_products,
                          })
                        }
                      />
                    </div>
                    <p className="fs-n0 lh-md">
                      <em>
                        During a data export, Ordoro will detect and create
                        products that are missing in your QuickBooks account.
                        You must enable this in order to do an inventory export.
                      </em>
                    </p>
                  </dd>
                </dl>
                <dl className="list divider--top dark">
                  <dt className="list__title fs-01">PO Export</dt>

                  <dd className="list__item fs-00">
                    <ButtonPrimary
                      alt
                      size="sm"
                      onClick={() =>
                        exportPOsToQuickBooks(formName, integrationID)
                      }
                      isLoading={form.isExportingPOs}
                    >
                      Export Latest PO Data
                    </ButtonPrimary>
                  </dd>
                </dl>
              </li>
              <li className="form-list-item margin-bottom-0 divider--top  margin-top-15 padding-top-15">
                <div className="flex--justify">
                  <ButtonPrimary
                    type="button"
                    size="md"
                    onClick={() => saveQuickBooks(formName, integrationID)}
                    isLoading={form.isSaving}
                  >
                    Save
                  </ButtonPrimary>
                  {!isNew && (
                    <ButtonPrimary
                      className="meta_QB-uninstall"
                      isOutlined
                      size="sm"
                      onClick={() =>
                        integrationIssue
                          ? archive(formName)
                          : uninstall(formName)
                      }
                    >
                      {integrationIssue ? 'Archive' : 'Uninstall QuickBooks'}
                    </ButtonPrimary>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

QuickBooks.propTypes = {
  isNew: PropTypes.bool,
  integrationID: PropTypes.number,
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    create_products: PropTypes.bool.isRequired,
    backoff_date: DateShape,
    integrationIssue: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isExportingOrders: PropTypes.bool.isRequired,
    isExportingProducts: PropTypes.bool.isRequired,
    isExportingPOs: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfAutoForm(QuickBooks, setupForm)
