import PropTypes from 'prop-types'

import {cartSelector, getCartName} from '../../../../data/carts.js'
import {useSelector} from '../../../../store.js'
import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueCartDisplay({condition}) {
  const option = getConditionOption(condition)

  const cart = useSelector((state) =>
    cartSelector(state, {cartID: option.getValue(condition)}),
  )

  if (!cart) {
    return null
  }

  return (
    <strong className="rule-criteria">
      <span>{getCartName(cart)}</span>
    </strong>
  )
}

ConditionValueCartDisplay.propTypes = {
  condition: PropTypes.object.isRequired,
}
