export const BASE = 'ordoro/ui/labelViewForm'
export const SET_PAGE_TOP_MARGIN = `${BASE}/SET_PAGE_TOP_MARGIN`
export const SET_PAGE_LEFT_MARGIN = `${BASE}/SET_PAGE_LEFT_MARGIN`
export const SET_LABEL_WIDTH = `${BASE}/SET_LABEL_WIDTH`
export const SET_LABEL_HEIGHT = `${BASE}/SET_LABEL_HEIGHT`
export const SET_ERROR = `${BASE}/SET_ERROR`
export const SET_LOADING = `${BASE}/SET_LOADING`
export const VIEW_AND_PRINT_DOCUMENT = `${BASE}/VIEW_AND_PRINT_DOCUMENT`
export const LABEL_VIEW_ABODE_FORM = 'LABEL_VIEW_ABODE_FORM'
export const RMA_LABEL_VIEW_ABODE_FORM = 'RMA_LABEL_VIEW_ABODE_FORM'
export const BATCH_LABEL_VIEW_ABODE_FORM = 'BATCH_LABEL_VIEW_ABODE_FORM'
export const BATCH_PICK_PACK_ABODE_FORM = 'BATCH_PICK_PACK_ABODE_FORM'

export function setPageTopMargin(payload) {
  return {
    meta: {sticky: SET_PAGE_TOP_MARGIN},
    type: SET_PAGE_TOP_MARGIN,
    payload,
  }
}

export function setPageLeftMargin(payload) {
  return {
    meta: {sticky: SET_PAGE_LEFT_MARGIN},
    type: SET_PAGE_LEFT_MARGIN,
    payload,
  }
}

export function setLabelWidth(payload) {
  return {
    meta: {sticky: SET_LABEL_WIDTH},
    type: SET_LABEL_WIDTH,
    payload,
  }
}

export function setLabelHeight(payload) {
  return {
    meta: {sticky: SET_LABEL_HEIGHT},
    type: SET_LABEL_HEIGHT,
    payload,
  }
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  }
}

export function setError(errorMessage) {
  return {
    type: SET_ERROR,
    payload: errorMessage,
  }
}

export function viewAndPrintDocument(pdfLink) {
  return {
    type: VIEW_AND_PRINT_DOCUMENT,
    payload: pdfLink,
  }
}
