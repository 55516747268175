import {useState} from 'react'
import isValid from 'date-fns/isValid'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import isEqual from 'lodash/isEqual.js'

import {
  DATE_CREATED,
  DATE_DELIVER_BY,
  DATE_DROPSHIPPED,
  DATE_ORDERED,
  DATE_SHIPPED,
  DATE_SHIP_BY,
} from '../../common/constants/OrderListDateRangeFilters.js'
import {
  ORDER_STATUS_BY_SLUG,
  ORDER_ALLOCATION_STATUS_BY_SLUG,
} from '../../common/constants/Orders.js'
import {
  TAG_FILTER_OR,
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
} from '../../common/constants/Tags.js'
import {ADDRESS_STATUS_DISPLAYS} from '../../common/constants/AddressTypes.js'
import OmniBar, {parseInputValue} from '../../common/components/List/OmniBar.js'
import {
  dateTimeFormat,
  formatDateTime,
  getShortTimeZone,
} from '../../common/date.js'
import {cartsSortedByNameSelector} from '../../data/carts.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../data/suppliers.js'
import {
  getWarehouseName,
  warehousesSortedByNameSelector,
} from '../../data/warehouses.js'
import {orderTagsSortedByNameSelector} from '../../data/orderTags.js'
import {getState, useSelector} from '../../store.js'
import {
  allocationStatiiSelector,
  orderListQuerySelector,
  orderStatiiSelector,
  summarizeOrderDateRangeParams,
} from './orderListSelectors.js'
import {
  navigateOrderList,
  navigateToOrderListPage,
  removeAllocationFilter,
  removeCartFilter,
  removeShipperFilter,
  removeOrderExcludeTag,
  removeOrderTagFilter,
  removeSupplierFilter,
  removeWarehouseFilter,
  setAllocationFilters,
  setBatchReferenceID,
  setCarrierError,
  setCartFilters,
  setShipperFilters,
  setDateRangeFilters,
  setHasRevision,
  setHasSimilarOpenAddresses,
  setInBatch,
  setOrderListStatus,
  setOrderTagFilterBy,
  setOrderUntaggedFilter,
  setSearchText,
  setShipToCountry,
  setShipToIsBillTo,
  setShipToStatus,
  setShowUnprintedOnly,
  setSimilarAddressTo,
  setSupplierFilters,
  setWarehouseFilters,
  updateOrderExcludeTags,
  updateOrderListStatus,
  updateOrderTagFilters,
  updateOrderUntaggedFilter,
  setTimezone,
} from './orderListActions.js'
import {isEmptyValue} from '../../common/utils.js'
import {shippersSortedByIDSelector} from '../../data/shippers.js'
import {visualizeInterval} from '../ReportsPage/ReportForms/IntervalSummary.js'
import {plural, Count} from '../../common/components/Plural.js'
import {REPORT_INTERVAL_ABBV_LOOKUP} from '../../common/constants/Reports.js'

export const SEARCH_TYPE = 'search'
export const STATUS_TYPE = 'status'
export const CART_TYPE = 'cart'
export const SHIPPER_TYPE = 'shipper'
export const SUPPLIER_TYPE = 'supplier'
export const WAREHOUSE_TYPE = 'warehouse'
export const ALLOCATION_TYPE = 'allocation'
export const TAG_TYPE = 'tag'
export const TAG_AND_TYPE = 'tag_and'
export const TAG_ONLY_TYPE = 'tag_only'
const TAG_TYPES = {
  [TAG_FILTER_OR]: TAG_TYPE,
  [TAG_FILTER_AND]: TAG_AND_TYPE,
  [TAG_FILTER_ONLY]: TAG_ONLY_TYPE,
}
export const UNTAGGED_TYPE = 'untagged'
export const EXCLUDE_TAGS_TYPE = 'exclude_tags'
export const DATE_DELIVER_BY_TYPE = 'deliver_by'
export const DATE_DROPSHIPPED_TYPE = 'dropshipped'
export const DATE_ORDERED_TYPE = 'ordered'
export const DATE_SHIPPED_TYPE = 'shipped'
export const DATE_SHIP_BY_TYPE = 'ship_by'
export const DATE_CREATED_TYPE = 'created'
const DATE_TYPES = {
  [DATE_DELIVER_BY]: DATE_DELIVER_BY_TYPE,
  [DATE_DROPSHIPPED]: DATE_DROPSHIPPED_TYPE,
  [DATE_ORDERED]: DATE_ORDERED_TYPE,
  [DATE_SHIPPED]: DATE_SHIPPED_TYPE,
  [DATE_SHIP_BY]: DATE_SHIP_BY_TYPE,
  [DATE_CREATED]: DATE_CREATED_TYPE,
}
export const TIMEZONE_TYPE = 'timezone'
export const UNPRINTED_TYPE = 'unprinted'
export const HAS_SIMILAR_OPEN_ADDRESSES_TYPE = 'has_similar_open_addresses'
export const SHIP_TO_IS_BILL_TO_TYPE = 'ship_to_is_bill_to'
export const HAS_REVISION_TYPE = 'has_revision'
export const SIMILAR_ADDRESS_TO_TYPE = 'similar_address_to'
export const SHIP_TO_STATUS_TYPE = 'ship_to_status'
export const SHIP_TO_COUNTRY_TYPE = 'ship_to_country'
export const BATCH_REFERENCE_ID_TYPE = 'batch_reference_id'
export const CARRIER_ERROR_TYPE = 'carrier_error'
export const IN_BATCH_TYPE = 'in_batch'

export function orderOmniBarFilterListSelector(state) {
  const cache = orderOmniBarFilterListSelector.cache
  const carts = cartsSortedByNameSelector(state)
  const shippers = shippersSortedByIDSelector(state)
  const suppliers = suppliersSortedByNameSelector(state)
  const warehouses = warehousesSortedByNameSelector(state)
  const tags = orderTagsSortedByNameSelector(state)
  const query = orderListQuerySelector(state)

  if (
    cache &&
    isEqual(carts, cache.carts) &&
    isEqual(shippers, cache.shippers) &&
    isEqual(suppliers, cache.suppliers) &&
    isEqual(warehouses, cache.warehouses) &&
    isEqual(tags, cache.tags) &&
    query === cache.query
  ) {
    return cache.list
  }

  orderOmniBarFilterListSelector.cache = {
    carts,
    shippers,
    suppliers,
    warehouses,
    tags,
    query,
  }

  const list = orderFilterList({
    carts,
    shippers,
    suppliers,
    warehouses,
    tags,
    query,
  })

  orderOmniBarFilterListSelector.cache.list = list

  return list
}

export function orderFilterList({
  carts,
  shippers,
  suppliers,
  warehouses,
  tags,
  intervalSummary = true,
  query: {
    batch_reference_id = [],
    status = [],
    supplier = [],
    warehouse_id = [],
    allocation_status = [],
    sales_channel = [],
    shipper = [],
    tag = [],
    tag_filter_by,
    exclude_tags = [],
    untagged,
    printed,
    has_similar_open_addresses,
    ship_to_is_bill_to,
    has_revision,
    similar_address_to,
    ship_to_status,
    ship_to_country = [],
    carrier_error,
    in_batch,
    search,
    ...query
  },
}) {
  const list = []

  if (batch_reference_id[0]) {
    list.push({
      type: BATCH_REFERENCE_ID_TYPE,
      label: batch_reference_id[0],
      value: batch_reference_id[0],
    })
  }

  for (const s of status) {
    list.push({
      type: STATUS_TYPE,
      label: ORDER_STATUS_BY_SLUG[s].name,
      value: s,
    })
  }

  suppliers.forEach((s) => {
    if (!supplier.includes(s.id)) {
      return
    }

    list.push({
      type: SUPPLIER_TYPE,
      label: getSupplierName(s),
      value: `${SUPPLIER_TYPE}:${s.id}`,
      id: s.id,
    })
  })

  warehouses.forEach((w) => {
    if (!warehouse_id.includes(w.id)) {
      return
    }

    list.push({
      type: WAREHOUSE_TYPE,
      label: getWarehouseName(w),
      value: `${WAREHOUSE_TYPE}:${w.id}`,
      id: w.id,
    })
  })

  allocation_status.forEach((allocationStatus) => {
    const allocationObj = ORDER_ALLOCATION_STATUS_BY_SLUG[allocationStatus]
    if (!allocationObj) {
      return
    }

    list.push({
      type: ALLOCATION_TYPE,
      label: allocationObj.name,
      value: `${ALLOCATION_TYPE}:${allocationStatus}`,
      slug: allocationStatus,
    })
  })

  carts.forEach((c) => {
    if (!sales_channel.includes(c.id)) {
      return
    }

    list.push({
      type: CART_TYPE,
      label: c.name,
      value: `${CART_TYPE}:${c.id}`,
      id: c.id,
    })
  })

  shippers.forEach((s) => {
    if (!shipper.includes(s.id)) {
      return
    }

    list.push({
      type: SHIPPER_TYPE,
      label: s.name,
      value: `${SHIPPER_TYPE}:${s.id}`,
      id: s.id,
    })
  })

  if (shipper.includes(-1)) {
    list.push({
      type: SHIPPER_TYPE,
      label: 'No Carrier',
      value: `${SHIPPER_TYPE}:-1`,
      id: -1,
    })
  }

  const tagType = TAG_TYPES[tag_filter_by || TAG_FILTER_OR]
  for (const t of tags) {
    if (tag.includes(t.text)) {
      list.push({
        type: tagType,
        label: t.text,
        value: `${tagType}:${t.id}`,
        color: t.color,
        text: t.text,
      })
    }
  }

  for (const t of tags) {
    if (exclude_tags.includes(t.text)) {
      list.push({
        type: EXCLUDE_TAGS_TYPE,
        label: t.text,
        value: `${EXCLUDE_TAGS_TYPE}:${t.id}`,
        color: t.color,
        text: t.text,
      })
    }
  }

  if (untagged) {
    list.push({
      type: UNTAGGED_TYPE,
      label: 'Untagged',
      value: 'untagged',
    })
  }

  const {
    dateFilterType,
    startDate,
    endDate,
    intervalType,
    intervalAmount,
    intervalTrunc,
    timezone,
  } = summarizeOrderDateRangeParams(query)
  const dateType = DATE_TYPES[dateFilterType]

  if (intervalType) {
    const [visualStartDateString, visualEndDateString] = visualizeInterval(
      intervalType,
      intervalAmount,
      intervalTrunc,
    )
    const startIntervalDate = new Date(visualStartDateString)
    const startIntervalStr = dateTimeFormat(startIntervalDate, formatDateTime)
    let startTimezoneStr = timezone
      ? ` ${getShortTimeZone(startIntervalDate, timezone)}`
      : ''

    const endIntervalDate = new Date(visualEndDateString)
    const endIntervalStr = dateTimeFormat(endIntervalDate, formatDateTime)
    const endTimezoneStr = timezone
      ? ` ${getShortTimeZone(endIntervalDate, timezone)}`
      : ''

    if (startTimezoneStr === endTimezoneStr) {
      startTimezoneStr = ''
    }

    const value = plural(intervalAmount)`interval of ${Count} ${intervalType}${[
      's',
    ]}${intervalTrunc ? ' truncated' : ''}`

    const label = intervalSummary
      ? `${value} (${startIntervalStr}${startTimezoneStr} to ${endIntervalStr}${endTimezoneStr})`
      : value

    list.push({
      type: dateType,
      label,
      value,
      intervalType,
    })
  } else if (startDate || endDate) {
    let [startStr, startTimezoneStr] = startDate
      ? [
          dateTimeFormat(startDate),
          timezone ? ` ${getShortTimeZone(startDate, timezone)}` : '',
        ]
      : ['', '']
    const [endStr, endTimezoneStr] = endDate
      ? [
          dateTimeFormat(endDate),
          timezone ? ` ${getShortTimeZone(endDate, timezone)}` : '',
        ]
      : ['', '']

    if (startStr && endStr && startTimezoneStr === endTimezoneStr) {
      startTimezoneStr = ''
    }

    const label =
      startStr && endStr
        ? `${dateTimeFormat(startDate)}${startTimezoneStr} to ${dateTimeFormat(
            endDate,
          )}${endTimezoneStr}`
        : startStr
          ? `After ${startStr}${startTimezoneStr}`
          : `Before ${endStr}${endTimezoneStr}`

    const value =
      startStr && endStr
        ? `${dateTimeFormat(startDate)} to ${dateTimeFormat(endDate)}`
        : startStr
          ? `After ${startStr}`
          : `Before ${endStr}`

    list.push({
      type: dateType,
      label,
      value,
    })
  }

  if (timezone) {
    list.push({
      type: TIMEZONE_TYPE,
      label: timezone,
      value: timezone,
    })
  }

  if (printed === false) {
    list.push({
      type: UNPRINTED_TYPE,
      label: 'With Unprinted Labels',
      value: 'unprinted',
    })
  }

  if (has_similar_open_addresses) {
    list.push({
      type: HAS_SIMILAR_OPEN_ADDRESSES_TYPE,
      label: 'With Similar Ship-To',
      value: true,
    })
  }

  if (!isEmptyValue(ship_to_is_bill_to)) {
    list.push({
      type: SHIP_TO_IS_BILL_TO_TYPE,
      label: ship_to_is_bill_to
        ? 'With Similar Bill-To'
        : 'With Different Bill-To',
      value: ship_to_is_bill_to,
    })
  }

  if (has_revision) {
    list.push({
      type: HAS_REVISION_TYPE,
      label: 'Has External Revisions',
      value: true,
    })
  }

  if (similar_address_to) {
    list.push({
      type: SIMILAR_ADDRESS_TO_TYPE,
      label: similar_address_to,
      value: similar_address_to,
    })
  }

  if (ship_to_status) {
    list.push({
      type: SHIP_TO_STATUS_TYPE,
      label: ADDRESS_STATUS_DISPLAYS[ship_to_status],
      value: ship_to_status,
    })
  }

  if (ship_to_country[0]) {
    const [, negSymbol, country] = ship_to_country[0].match(/(-)?(.*)/)
    list.push({
      type: SHIP_TO_COUNTRY_TYPE,
      label: `${negSymbol ? 'Non-' : ''}${country}`,
      value: ship_to_country[0],
    })
  }

  if (carrier_error === true || carrier_error === false) {
    list.push({
      type: CARRIER_ERROR_TYPE,
      label: carrier_error ? 'Has A Carrier Error' : 'Has No Carrier Error',
      value: carrier_error,
    })
  }

  if (in_batch === true || in_batch === false) {
    list.push({
      type: IN_BATCH_TYPE,
      label: in_batch ? 'In A Batch' : 'Not In A Batch',
      value: in_batch,
    })
  }

  if (search) {
    list.push({
      type: SEARCH_TYPE,
      label: search,
      value: search,
    })
  }

  return list
}

const parseFuncs = [
  (date) => parseISO(date),
  (date) => parse(date, 'MMMM', new Date()),
  (date) => parse(date, 'MMM', new Date()),
  (date) => parse(date, 'MMMM yyyy', new Date()),
  (date) => parse(date, 'MMM yyyy', new Date()),
  (date) => parse(date, 'MMM d, yyyy', new Date()),
]

function parseDate(date) {
  if (!date) {
    return null
  }

  for (const parseFunc of parseFuncs) {
    const possibleDate = parseFunc(date)

    if (isValid(possibleDate)) {
      return possibleDate
    }
  }

  return null
}

function getDateTypeAndRest(filterValue) {
  for (const {regex, type} of [
    {regex: /^created? (.*)$/i, type: DATE_CREATED},
    {regex: /^order(?:ed)? (.*)$/i, type: DATE_ORDERED},
    {regex: /^shipped (.*)$/i, type: DATE_SHIPPED},
    {regex: /^dropship(?:ped)? (.*)$/i, type: DATE_DROPSHIPPED},
    {regex: /^deliver(?:y)?(?:.by)? (.*)$/i, type: DATE_DELIVER_BY},
    {regex: /^ship by (.*)$/i, type: DATE_SHIP_BY},
  ]) {
    const match = filterValue.match(regex)

    if (match) {
      return {dateType: type, dateRest: match[1]}
    }
  }

  return {dateType: DATE_ORDERED, dateRest: filterValue}
}

export const PROCESS_VALUE_FUNCTIONS = {
  processStatusFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^status$/i) && ['', '-', '!'].includes(filterOperator)
      )
    ) {
      return outcome
    }

    const orderStatii = orderStatiiSelector(getState())

    outcome.stop = true

    const statusMatch = orderStatii.find(({slug, name}) =>
      `${slug} ${name}`.match(RegExp(filterValue, 'i')),
    )

    if (statusMatch) {
      const status = orderListQuerySelector(getState()).status.filter(
        (s) => s !== statusMatch.slug,
      )

      if (!['-', '!'].includes(filterOperator)) {
        status.push(statusMatch.slug)
      }

      outcome.hash = setOrderListStatus(status)
    }

    return outcome
  },

  processSupplierFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^supplier[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const suppliers = suppliersSortedByNameSelector(getState())
    const query = orderListQuerySelector(getState())

    const matchedSuppliers = suppliers.filter((s) => {
      if (filterOperator === '=') {
        if (s.id === Number(filterValue)) {
          return true
        }
        if (s.address.company === filterValue) {
          return true
        }
      } else {
        if (
          `${s.address.company} ${s.address.city} ${s.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedSuppliers.length) {
      outcome.hash = setSupplierFilters([
        ...query.supplier,
        ...matchedSuppliers.map(({id}) => id),
      ])
    }

    return outcome
  },

  processWarehouseFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^warehouse[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const warehouses = warehousesSortedByNameSelector(getState())
    const query = orderListQuerySelector(getState())

    const matchedWarehouses = warehouses.filter((w) => {
      if (filterOperator === '=') {
        if (w.id === Number(filterValue)) {
          return true
        }
        if (getWarehouseName(w) === filterValue) {
          return true
        }
      } else {
        if (
          `${w.address.name} ${w.address.company} ${w.address.city} ${w.address.state}`.match(
            RegExp(filterValue, 'i'),
          )
        ) {
          return true
        }
      }

      return false
    })

    if (matchedWarehouses.length) {
      outcome.hash = setWarehouseFilters([
        ...query.warehouse_id,
        ...matchedWarehouses.map(({id}) => id),
      ])
    }

    return outcome
  },

  processAllocationFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^alloc(ation|ated)?\b( status)?/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const allocationStatii = allocationStatiiSelector(getState())
    const query = orderListQuerySelector(getState())

    const statusMatch = allocationStatii.find(({slug, name}) => {
      if (filterValue.match(/^al/i) && slug !== 'allocated') {
        return false
      }

      if (filterValue.match(/^un/i) && slug !== 'unallocated') {
        return false
      }

      return `${slug} ${name}`.match(RegExp(filterValue, 'i'))
    })

    if (statusMatch) {
      outcome.hash = setAllocationFilters([
        ...query.allocation_status,
        statusMatch.slug,
      ])
    }
    return outcome
  },

  processCartFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(cart|sales channel)[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const carts = cartsSortedByNameSelector(getState())
    const query = orderListQuerySelector(getState())

    const matchedCarts = carts.filter((c) => {
      if (filterOperator === '=') {
        if (c.id === Number(filterValue)) {
          return true
        }
        if (c.name === filterValue) {
          return true
        }
      } else {
        if (`${c.name} ${c.vendor}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    if (matchedCarts.length) {
      outcome.hash = setCartFilters([
        ...query.sales_channel,
        ...matchedCarts.map(({id}) => id),
      ])
    }

    return outcome
  },

  processShipperFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(shipper|carrier)[s]?$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const shippers = shippersSortedByIDSelector(getState())
    const query = orderListQuerySelector(getState())

    const matchedShippers = shippers.filter((c) => {
      if (filterOperator === '=') {
        if (c.id === Number(filterValue)) {
          return true
        }
        if (c.name === filterValue) {
          return true
        }
      } else {
        if (`${c.name} ${c.vendor}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    if (matchedShippers.length) {
      outcome.hash = setShipperFilters([
        ...query.shipper,
        ...matchedShippers.map(({id}) => id),
      ])
    }

    return outcome
  },

  processTagFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag)[s]?$/i) &&
        ['', '=', '-', '!', '!='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const tags = orderTagsSortedByNameSelector(getState())
    const query = orderListQuerySelector(getState())

    const matchedTags = tags.filter((t) => {
      if (['=', '!='].includes(filterOperator)) {
        if (t.id === Number(filterValue)) {
          return true
        }
        if (t.text === filterValue) {
          return true
        }
      } else {
        if (`${t.text}`.match(RegExp(filterValue, 'i'))) {
          return true
        }
      }

      return false
    })

    const negate = ['!', '-', '!='].includes(filterOperator)

    if (matchedTags.length) {
      let updates = updateOrderUntaggedFilter(false)

      if (negate) {
        updates = {
          ...updates,
          ...updateOrderExcludeTags([
            ...query.exclude_tags,
            ...matchedTags.map(({text}) => text),
          ]),
        }
      } else {
        updates = {
          ...updates,
          ...updateOrderTagFilters([
            ...query.tag,
            ...matchedTags.map(({text}) => text),
          ]),
        }
      }

      outcome.hash = navigateOrderList(updates)
    }

    return outcome
  },

  processUntaggedValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (!filterValue.match(/^untag(ged)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = navigateOrderList({
      ...updateOrderUntaggedFilter(true),
      ...updateOrderTagFilters([]),
    })

    return outcome
  },

  processTagFilterByValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^(tag.filter(ing)?.logic|tfl)$/i) &&
        ['', '='].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    const filterBy = filterValue.match(/^(and|all)$/i)
      ? TAG_FILTER_AND
      : filterValue.match(/^only$/i)
        ? TAG_FILTER_ONLY
        : TAG_FILTER_OR

    outcome.hash = setOrderTagFilterBy(filterBy)

    return outcome
  },

  processDateFilterValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (!(filterType.match(/^date$/i) && ['', '='].includes(filterOperator))) {
      return outcome
    }

    outcome.stop = true

    let {dateType, dateRest} = getDateTypeAndRest(filterValue)

    if (!dateRest) {
      return outcome
    }

    // get date preposition
    const match = dateRest.match(/^(after|before|between)(.*)$/i)

    const datePreposition = match ? match[1] : null

    // get the rest of the date
    dateRest = match ? match[2].trim() : dateRest

    // break up the rest in to two possible pieces
    let [date1, date2] = dateRest.split(/ (?:and|to) /i)

    date1 = parseDate(date1)
    date2 = parseDate(date2)

    // exclude date2 if not used
    if (datePreposition === 'after') {
      date2 = null

      date1 = startOfDay(date1)
    }

    // copy date1 to date2 if before (cause it is the end)
    if (datePreposition === 'before') {
      date2 = date1
      date1 = null

      date2 = endOfDay(subDays(date2, 1))
    }

    // got nothing
    if (!date1 && !date2) {
      return outcome
    }

    // flip dates to put oldest at the start
    if (date1 && date2 && date1 > date2) {
      ;[date2, date1] = [date1, date2]
    }

    const query = orderListQuerySelector(getState())

    // update no matter what, maybe nothing changed <shrug>
    outcome.hash = setDateRangeFilters({
      startDate: date1,
      endDate: date2,
      dateFilterType: dateType,
      timezone: query.timezone,
    })

    return outcome
  },

  processIntervalFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(filterType.match(/^interval$/i) && ['', '='].includes(filterOperator))
    ) {
      return outcome
    }

    outcome.stop = true

    // "created 1 hour ago truncated"
    let {dateType, dateRest} = getDateTypeAndRest(filterValue)

    if (!dateRest) {
      return outcome
    }

    // throw out fluff
    dateRest = dateRest.replace(/^interval( of)? /, '')

    // "1 hour ago" or "0.5m ago trunc" or "50 months truncated"
    let [, intervalAmount, intervalType, intervalTrunc] =
      dateRest.match(
        /^(\d+(?:\.\d+)?)[ ]*([a-z]+)[ ]*(?:ago)?[ ]*(trunc(?:ate(?:d)?)?)?$/i,
      ) || []

    intervalAmount = Number(intervalAmount)

    if (!intervalAmount || intervalAmount <= 0) {
      return outcome
    }

    intervalType = REPORT_INTERVAL_ABBV_LOOKUP[intervalType]

    if (!intervalType) {
      return outcome
    }

    intervalTrunc = !!intervalTrunc

    const query = orderListQuerySelector(getState())

    // update no matter what, maybe nothing changed <shrug>
    outcome.hash = setDateRangeFilters({
      dateFilterType: dateType,
      intervalType,
      intervalAmount,
      intervalTrunc,
      timezone: query.timezone,
    })

    return outcome
  },

  processUnprintedValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    if (!filterValue.match(/^unprint(ed)?( only)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setShowUnprintedOnly(true)

    return outcome
  },

  processHasSimilarOpenAddressValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      filterType ||
      !filterValue.match(/^(similar|same)( open)? address(es)?$/i)
    ) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setHasSimilarOpenAddresses(true)

    return outcome
  },

  processShipToIsBillToValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    const match = filterValue.match(
      /^((?<same>similar|same)|(?<different>diff(erent)?))( \w+)? bill(([- ])?to)?$/i,
    )

    if (filterType || !match) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setShipToIsBillTo(
      match.groups.same ? true : match.groups.different ? false : null,
    )

    return outcome
  },

  processHasRevisionValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    const match = filterValue.match(/^has_rev(ision)?$/i)

    if (filterType || !match) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setHasRevision(true)

    return outcome
  },

  processSimilarAddressToFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (!filterType.match(/^(similar|same) address( to)?$/i)) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setSimilarAddressTo(filterValue)

    return outcome
  },

  processShipToStatusFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !filterType.match(
        /^((address|ship to|ship address|ship to address) status)|(address validation)$/i,
      )
    ) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setShipToStatus(filterValue)

    return outcome
  },

  processShipToCountryFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(
          /^((address|ship to|ship address|ship to address)\s+)?country$/i,
        ) && ['', '-'].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setShipToCountry(
      `${filterOperator === '-' ? '-' : ''}${filterValue}`,
    )

    return outcome
  },

  processBatchReferenceIDFilterValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    if (
      !(
        filterType.match(/^batch( id)?$/i) && ['', '-'].includes(filterOperator)
      )
    ) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setBatchReferenceID(filterValue)

    return outcome
  },

  processCarrierErrorValue(
    inputValue,
    filterType,
    filterOperator,
    filterValue,
  ) {
    const outcome = {stop: false}

    const match = filterValue.match(
      /^(has.)?(?<no>no.)?(a.)?carrier.err((i)?(o|e)r)?$/i,
    )

    if (filterType || !match) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setCarrierError(match.groups.no ? false : true)

    return outcome
  },

  processInBatchValue(inputValue, filterType, filterOperator, filterValue) {
    const outcome = {stop: false}

    const match = filterValue.match(/^(?<no>not.)?(in.a.)?batch(ed)?$/i)

    if (filterType || !match) {
      return outcome
    }

    outcome.stop = true

    outcome.hash = setInBatch(match.groups.no ? false : true)

    return outcome
  },

  // Put new functions before this function
  processSearchTextFilterValue(inputValue) {
    return {stop: true, hash: setSearchText(inputValue)}
  },
  // Do not put functions after this function
}

export function processInputValue(inputValue, setInputValue) {
  if (!inputValue) {
    setInputValue('')

    return
  }

  const [, filterType, filterOperator, filterValue] =
    parseInputValue(inputValue)

  let hash
  for (const processFilterValue of Object.values(PROCESS_VALUE_FUNCTIONS)) {
    const outcome = processFilterValue(
      inputValue,
      filterType,
      filterOperator,
      filterValue,
    )

    if (outcome.stop) {
      hash = outcome.hash
      break
    }
  }

  if (hash) {
    setInputValue('')

    return hash
  }

  return
}

export function removeValue(value) {
  if (value.type === STATUS_TYPE) {
    const {status} = orderListQuerySelector(getState())
    return setOrderListStatus(status.filter((s) => s !== value.value))
  } else if (value.type === CART_TYPE) {
    return removeCartFilter(value.id)
  } else if (value.type === SHIPPER_TYPE) {
    return removeShipperFilter(value.id)
  } else if (value.type === SUPPLIER_TYPE) {
    return removeSupplierFilter(value.id)
  } else if (value.type === WAREHOUSE_TYPE) {
    return removeWarehouseFilter(value.id)
  } else if (value.type === ALLOCATION_TYPE) {
    return removeAllocationFilter(value.slug)
  } else if ([TAG_TYPE, TAG_AND_TYPE, TAG_ONLY_TYPE].includes(value.type)) {
    return removeOrderTagFilter(value.text)
  } else if (value.type === UNTAGGED_TYPE) {
    return setOrderUntaggedFilter(false)
  } else if (value.type === EXCLUDE_TAGS_TYPE) {
    return removeOrderExcludeTag(value.text)
  } else if (
    [
      DATE_DELIVER_BY_TYPE,
      DATE_DROPSHIPPED_TYPE,
      DATE_ORDERED_TYPE,
      DATE_SHIPPED_TYPE,
      DATE_SHIP_BY_TYPE,
      DATE_CREATED_TYPE,
    ].includes(value.type)
  ) {
    return setDateRangeFilters({
      dateFilterType: null,
      startDate: null,
      endDate: null,
      intervalType: null,
      intervalAmount: null,
      intervalTrunc: null,
      timezone: null,
    })
  } else if (value.type === TIMEZONE_TYPE) {
    return setTimezone(null)
  } else if (value.type === UNPRINTED_TYPE) {
    return setShowUnprintedOnly(false)
  } else if (value.type === HAS_SIMILAR_OPEN_ADDRESSES_TYPE) {
    return setHasSimilarOpenAddresses(false)
  } else if (value.type === SHIP_TO_IS_BILL_TO_TYPE) {
    return setShipToIsBillTo(null)
  } else if (value.type === HAS_REVISION_TYPE) {
    return setHasRevision(false)
  } else if (value.type === SIMILAR_ADDRESS_TO_TYPE) {
    return setSimilarAddressTo('')
  } else if (value.type === SHIP_TO_STATUS_TYPE) {
    return setShipToStatus()
  } else if (value.type === SHIP_TO_COUNTRY_TYPE) {
    return setShipToCountry()
  } else if (value.type === BATCH_REFERENCE_ID_TYPE) {
    return setBatchReferenceID()
  } else if (value.type === CARRIER_ERROR_TYPE) {
    return setCarrierError(null)
  } else if (value.type === IN_BATCH_TYPE) {
    return setInBatch(null)
  } else if (value.type === SEARCH_TYPE) {
    return setSearchText('')
  }
}

export function clearAll() {
  return navigateToOrderListPage(updateOrderListStatus([]))
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  } else if (
    [
      DATE_DELIVER_BY_TYPE,
      DATE_DROPSHIPPED_TYPE,
      DATE_ORDERED_TYPE,
      DATE_SHIPPED_TYPE,
      DATE_SHIP_BY_TYPE,
      DATE_CREATED_TYPE,
    ].includes(data.type)
  ) {
    setInputValue(
      `${data.intervalType ? 'interval' : 'date'}: ${data.type} ${data.value}`,
    )
  } else if (data.type === SIMILAR_ADDRESS_TO_TYPE) {
    setInputValue(`similar address to:${data.value}`)
  } else if (data.type === SHIP_TO_COUNTRY_TYPE) {
    setInputValue(`country:${data.value}`)
  } else if (data.type === BATCH_REFERENCE_ID_TYPE) {
    setInputValue(`batch id:${data.value}`)
  }
}

export default function OrderListOmniBar() {
  const [inputValue, setInputValue] = useState('')
  const list = useSelector(orderOmniBarFilterListSelector)

  return (
    <OmniBar
      placeholder="Enter an Order ID or Product SKU..."
      inputValue={inputValue}
      value={list}
      onRemoveValue={(removedValue) => removeValue(removedValue)}
      onClear={() => clearAll()}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        processInputValue(inputValue, setInputValue)
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}
