import {
  shipperVendorConfigPropertySelector,
  updateLabelConfig,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {FEDEX} from '../../../common/constants/ShipperNames.js'
import {useSelector} from '../../../store.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [FEDEX]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__sub_package_type`]
    : []
}

export default function SubPackageType() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  // temp_use_fedex_auth
  const [hasFedExNewCred] = useSelector((state) =>
    shipperVendorConfigPropertySelector(state, {
      labelInfoID,
      shipperType,
      property: 'child_key',
    }),
  )

  return (
    <ConfigSelect
      label="Sub-Package Type"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty="sub_package_type"
      hide={!hasFedExNewCred}
    />
  )
}
