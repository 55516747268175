import {useEffect} from 'react'
import {createSelector} from 'reselect'

import {useSelector} from '../../store.js'
import {
  ACCOUNTING,
  API_KEYS,
  BARCODE_SCANNERS,
  BILLING,
  GENERAL,
  PACKING_LISTS,
  PAYMENTS,
  PRESETS,
  PRINTING,
  RETURN_ORDER_CODES,
  RULES,
  SALES_CHANNELS,
  SCALES,
  SHIPPERS,
  SHIPPING_INSURANCE,
  SUPPLIERS,
  THREE_P_L,
  USERS,
  WAREHOUSES,
} from '../../common/constants/SettingsPanels.js'
import {
  showSuppliersNavSelector,
  showRulesNavSelector,
  companyIDSelector,
} from '../../data/company.js'
import {showAPIKeysNavSelector} from '../../data/me.js'
import {
  currentSettingsPanelSelector,
  settingsPermissionsSelector,
} from '../../redux/selectors/ui/settings/index.js'
import {
  selectedProfileTabSelector,
  packingListsHashSelector,
} from '../../redux/selectors/ui/settings/packingLists.js'
import {
  SETTINGS_PROFILES_URL,
  PROFILES_GENERAL,
  PROFILES_PACKING_LIST,
  PROFILES_RETURN_ORDER,
  PROFILES_SHIP_FROM_ALIAS,
} from '../../redux/actions/ui/settings/packingLists/index.js'
import Accounting from './Accounting/index.js'
import APIKeys from './APIKeys/index.js'
import BarcodeScanners from './BarcodeScanners/index.js'
import Billing from './Billing/index.js'
import General from './General/index.js'
import PackingLists from './PackingLists/index.js'
import Payments from './Payments/index.js'
import Presets from './Presets/index.js'
import Printing from './Printing/index.js'
import ReturnOrderCodes from './ReturnOrderCodes/index.js'
import Rules from './Rules/index.js'
import SalesChannels from './SalesChannels/index.js'
import Scales from './Scales/index.js'
import Shippers from './Shippers/index.js'
import ShippingInsurance from './ShippingInsurance/index.js'
import Suppliers from './Suppliers/index.js'
import ThirdPartyLogistics from './ThirdPartyLogistics/index.js'
import Users from './Users/index.js'
import Warehouses from './Warehouses/index.js'
import SettingsNavItem from './SettingsNavItem.js'
import Modals from './Modals/index.js'
import {apiKeysHashSelector} from '../../redux/selectors/ui/settings/apiKeys.js'
import {paymentsHashSelector} from '../../redux/selectors/ui/settings/payments/index.js'
import {returnOrderCodesHashSelector} from '../../redux/selectors/ui/settings/returnOrderCodes.js'
import {salesChannelsHashSelector} from './SalesChannels/salesChannelsSelectors.js'
import {shippersHashSelector} from '../../redux/selectors/ui/settings/shippers/index.js'
import {suppliersHashSelector} from './Suppliers/suppliersFunctions.js'
import {usersHashSelector} from '../../redux/selectors/ui/settings/users.js'
import {warehousesHashSelector} from './Warehouses/warehousesFunctions.js'
import {showReturnOrdersNavSelector} from '../../redux/selectors/data/returnOrders.js'
import {accountingHashSelector} from './Accounting/accountingFunctions.js'

const panels = {
  [ACCOUNTING]: Accounting,
  [API_KEYS]: APIKeys,
  [BARCODE_SCANNERS]: BarcodeScanners,
  [BILLING]: Billing,
  [GENERAL]: General,
  [PACKING_LISTS]: PackingLists,
  [PAYMENTS]: Payments,
  [PRESETS]: Presets,
  [PRINTING]: Printing,
  [RETURN_ORDER_CODES]: ReturnOrderCodes,
  [RULES]: Rules,
  [SALES_CHANNELS]: SalesChannels,
  [SCALES]: Scales,
  [SHIPPERS]: Shippers,
  [SHIPPING_INSURANCE]: ShippingInsurance,
  [SUPPLIERS]: Suppliers,
  [THREE_P_L]: ThirdPartyLogistics,
  [USERS]: Users,
  [WAREHOUSES]: Warehouses,
}

export const settingsHashSelector = createSelector(
  currentSettingsPanelSelector,
  accountingHashSelector,
  apiKeysHashSelector,
  packingListsHashSelector,
  paymentsHashSelector,
  returnOrderCodesHashSelector,
  salesChannelsHashSelector,
  shippersHashSelector,
  suppliersHashSelector,
  usersHashSelector,
  warehousesHashSelector,
  (
    currentSettingsPanel,
    accountingHash,
    apiKeysHash,
    packingListsHash,
    paymentsHash,
    returnOrderCodesHash,
    salesChannelsHash,
    shippersHash,
    suppliersHash,
    usersHash,
    warehousesHash,
  ) => {
    if (currentSettingsPanel === ACCOUNTING) {
      return accountingHash
    }
    if (currentSettingsPanel === API_KEYS) {
      return apiKeysHash
    }
    if (currentSettingsPanel === BARCODE_SCANNERS) {
      return '#/settings/barcode_scanners'
    }
    if (currentSettingsPanel === BILLING) {
      return '#/settings/billing'
    }
    if (currentSettingsPanel === PACKING_LISTS) {
      return packingListsHash
    }
    if (currentSettingsPanel === PAYMENTS) {
      return paymentsHash
    }
    if (currentSettingsPanel === PRESETS) {
      return '#/settings/presets'
    }
    if (currentSettingsPanel === PRINTING) {
      return '#/settings/printing'
    }
    if (currentSettingsPanel === RETURN_ORDER_CODES) {
      return returnOrderCodesHash
    }
    if (currentSettingsPanel === RULES) {
      return '#/settings/rules'
    }
    if (currentSettingsPanel === SALES_CHANNELS) {
      return salesChannelsHash
    }
    if (currentSettingsPanel === SCALES) {
      return '#/settings/scales'
    }
    if (currentSettingsPanel === SHIPPERS) {
      return shippersHash
    }
    if (currentSettingsPanel === SHIPPING_INSURANCE) {
      return `#/settings/${SHIPPING_INSURANCE}`
    }
    if (currentSettingsPanel === SUPPLIERS) {
      return suppliersHash
    }
    if (currentSettingsPanel === THREE_P_L) {
      return '#/settings/3PL'
    }
    if (currentSettingsPanel === USERS) {
      return usersHash
    }
    if (currentSettingsPanel === WAREHOUSES) {
      return warehousesHash
    }

    return '#/settings/general'
  },
)

export default function Settings() {
  useEffect(() => {
    document.title = 'Settings'
  }, [])
  const currentSettingsPanel = useSelector(currentSettingsPanelSelector)
  const selectedProfileTab = useSelector(selectedProfileTabSelector)
  const showSuppliersNav = useSelector(showSuppliersNavSelector)
  const showRulesNav = useSelector(showRulesNavSelector)
  const showAPIKeysNav = useSelector(showAPIKeysNavSelector)
  const settingsPermissions = useSelector(settingsPermissionsSelector)
  const companyID = useSelector(companyIDSelector)
  const showReturnOrdersNav = useSelector(showReturnOrdersNavSelector)

  const Panel = panels[currentSettingsPanel]

  return (
    <section className="wrap--container" role="main">
      <Modals />
      <div className="wrap--row">
        <div className="medium-9 medium-push-2 columns padding-top-10">
          {Panel && <Panel />}
        </div>
        <div className="medium-2 medium-pull-10 columns">
          <div className="inner-wrap--side-nav">
            <div className="alert alert--standard alert--sm alert--support-pin align-center">
              <strong className="fs-n0">Support Pin: </strong>
              <span className="fs-n0">{companyID}</span>
            </div>
            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Account Settings</dt>
              <SettingsNavItem
                title="General"
                resource={GENERAL}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[GENERAL]}
              />
              <SettingsNavItem
                title="Users"
                resource={USERS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[USERS]}
              />
              <SettingsNavItem
                title="Billing"
                resource={BILLING}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[BILLING]}
              />
              {showAPIKeysNav && (
                <SettingsNavItem
                  title="API Keys"
                  resource={API_KEYS}
                  currentSettingsPanel={currentSettingsPanel}
                  disabled={!settingsPermissions[API_KEYS]}
                />
              )}
            </dl>

            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Fulfillment</dt>
              <SettingsNavItem
                title="Warehouses"
                resource={WAREHOUSES}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[WAREHOUSES]}
              />
              {showSuppliersNav && (
                <SettingsNavItem
                  title="Suppliers"
                  resource={SUPPLIERS}
                  currentSettingsPanel={currentSettingsPanel}
                  disabled={!settingsPermissions[SUPPLIERS]}
                />
              )}
              <SettingsNavItem
                title="3PLs"
                resource={THREE_P_L}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[THREE_P_L]}
              />
            </dl>
            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Integrations</dt>
              <SettingsNavItem
                title="Sales Channels"
                resource={SALES_CHANNELS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[SALES_CHANNELS]}
              />
              <SettingsNavItem
                title="Shippers/Carriers"
                resource={SHIPPERS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[SHIPPERS]}
              />
              <SettingsNavItem
                title="Accounting"
                resource={ACCOUNTING}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[ACCOUNTING]}
              />
              <SettingsNavItem
                title="Payments"
                resource={PAYMENTS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[PAYMENTS]}
              />
              <SettingsNavItem
                title="Shipping Insurance"
                resource={SHIPPING_INSURANCE}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[SHIPPING_INSURANCE]}
              />
            </dl>
            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Profiles</dt>
              <SettingsNavItem
                title="General"
                href={`${SETTINGS_PROFILES_URL}/${PROFILES_GENERAL}`}
                isActive={
                  currentSettingsPanel === PACKING_LISTS &&
                  selectedProfileTab === PROFILES_GENERAL
                }
                disabled={!settingsPermissions[PACKING_LISTS]}
              />
              <SettingsNavItem
                title="Packing Lists"
                href={`${SETTINGS_PROFILES_URL}/${PROFILES_PACKING_LIST}`}
                isActive={
                  currentSettingsPanel === PACKING_LISTS &&
                  selectedProfileTab === PROFILES_PACKING_LIST
                }
                disabled={!settingsPermissions[PACKING_LISTS]}
              />
              {showReturnOrdersNav && (
                <SettingsNavItem
                  title="Return Emails"
                  href={`${SETTINGS_PROFILES_URL}/${PROFILES_RETURN_ORDER}`}
                  isActive={
                    currentSettingsPanel === PACKING_LISTS &&
                    selectedProfileTab === PROFILES_RETURN_ORDER
                  }
                  disabled={!settingsPermissions[PACKING_LISTS]}
                />
              )}
              <SettingsNavItem
                title="Ship From Alias"
                href={`${SETTINGS_PROFILES_URL}/${PROFILES_SHIP_FROM_ALIAS}`}
                isActive={
                  currentSettingsPanel === PACKING_LISTS &&
                  selectedProfileTab === PROFILES_SHIP_FROM_ALIAS
                }
                disabled={!settingsPermissions[PACKING_LISTS]}
              />
            </dl>
            {showReturnOrdersNav && (
              <dl className="list--side-nav">
                <dt className="list__title--side-nav">Returns</dt>
                <SettingsNavItem
                  title="Return Codes"
                  resource={RETURN_ORDER_CODES}
                  currentSettingsPanel={currentSettingsPanel}
                  disabled={!settingsPermissions[RETURN_ORDER_CODES]}
                />
              </dl>
            )}
            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Devices</dt>
              <SettingsNavItem
                title="Printers"
                resource={PRINTING}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[PRINTING]}
              />
              <SettingsNavItem
                title="Scales"
                resource={SCALES}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[SCALES]}
              />
              <SettingsNavItem
                title="Barcode Scanners"
                resource={BARCODE_SCANNERS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[BARCODE_SCANNERS]}
              />
            </dl>
            <dl className="list--side-nav">
              <dt className="list__title--side-nav">Automation</dt>
              <SettingsNavItem
                title="Presets"
                resource={PRESETS}
                currentSettingsPanel={currentSettingsPanel}
                disabled={!settingsPermissions[PRESETS]}
              />
              {showRulesNav && (
                <SettingsNavItem
                  title="Rules"
                  resource={RULES}
                  currentSettingsPanel={currentSettingsPanel}
                  disabled={!settingsPermissions[RULES]}
                />
              )}
            </dl>
          </div>
        </div>
      </div>
    </section>
  )
}
