import Footer from '../../common/components/List/Footer/index.js'
import {
  isLoadingSelector,
  countSelector,
  productListQuerySelector,
  setLimit,
  setOffset,
} from './productListFunctions.js'
import {useSelector} from '../../store.js'

export default function ProductListFooter() {
  const isLoading = useSelector(isLoadingSelector)
  const {limit, offset} = useSelector(productListQuerySelector)
  const count = useSelector(countSelector)

  return (
    <Footer
      isLoading={isLoading}
      limit={limit}
      offset={offset}
      count={count}
      setLimit={setLimit}
      setOffset={setOffset}
      preventResetOffset
    />
  )
}
