import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../store.js'
import {isPresent} from '../../common/utils.js'
import api from '../../common/api.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  hasAccountCreditCardSelector,
  usePaymentAccountsForCCSelector,
} from '../../data/company.js'
import {showMessageToast} from '../Header/Toast/index.js'
import deferPromise from '../../common/deferPromise.js'
import NetZeroCreditCardForm, {
  NetZeroCreditCardFormShape,
} from '../settings/Billing/NetZeroCreditCardForm.js'
import {getRealDate} from '../../common/date.js'
import {
  netZeroPaymentAccountSelector,
  postagePaymentAccountsSelector,
} from '../../data/paymentAccounts.js'

const MODAL_FORM = 'ACCOUNT_CREDIT_CARD_MODAL'

export function showAccountCreditCardModal() {
  const deferredPromise = deferPromise()

  setForm(MODAL_FORM, {
    name: '',
    cardForm: null,
    isCardComplete: false,
    isSaving: false,
    cardResultError: null,
    serverError: null,
    deferredPromise,
  })

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(hasCreditCard = false) {
  const form = modalFormSelector(getState())

  removeForm(MODAL_FORM)

  form.deferredPromise.resolve(hasCreditCard)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {name, isCardComplete} = modalFormSelector(state)
  const errors = {}

  if (!isPresent(name)) {
    errors.name = 'Name is required'
    errors.preventSave = true
  }

  if (!isCardComplete) {
    errors.preventSave = true
  }

  return errors
}

export function onCardFormEvent({error, result}) {
  const updates = {
    cardResultError: null,
    isCardComplete: true,
  }
  const now = getRealDate()

  if (error) {
    updates.cardResultError = error
    updates.isCardComplete = false
  }

  if (!result.maskedCard || !result.maskedCvv2 || !result.avsZip) {
    updates.isCardComplete = false
  }

  if (result.expiryYear < 2020 || result.expiryMonth === 0) {
    updates.isCardComplete = false
  } else if (new Date(result.expiryYear, result.expiryMonth - 1) < now) {
    updates.cardResultError = 'Card has expired'
    updates.isCardComplete = false
  }

  updateModalForm(updates)
}

export async function saveCreditCard() {
  const {name, cardForm} = modalFormSelector(getState())
  const usePaymentAccountsForCC = usePaymentAccountsForCCSelector(getState())

  updateModalForm({isSaving: true, serverError: null})

  try {
    const result = await cardForm.getNonceToken()

    const params = {
      token: result.nonce,
      zip: result.avsZip,
      last4: result.last4,
      expiry_year: result.expiryYear,
      expiry_month: result.expiryMonth,
      card_type: result.cardType,
      name,
      payment_backend: 'net_zero',
    }

    if (usePaymentAccountsForCC) {
      const paymentAccount = netZeroPaymentAccountSelector(getState())

      if (paymentAccount) {
        await api.put(`/company/payment/${paymentAccount.id}/`, params)
      } else {
        const paymentAccounts = postagePaymentAccountsSelector(getState())

        if (!paymentAccounts.find(({is_default}) => is_default)) {
          // if nobody is default then make this default
          params.is_default = true
        }

        await api.post('/company/payment/', params)
      }
    } else {
      await api.put('/company/credit_card/', params)
    }

    showMessageToast('Credit card updated')

    closeModal(true)
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function AccountCreditCardModal({form}) {
  const errors = useSelector(errorsSelector)
  const hasAccountCreditCard = useSelector(hasAccountCreditCardSelector)

  return (
    <ConfirmModal
      title={`${hasAccountCreditCard ? 'Update' : 'Add'} Credit Card`}
      modalSize="sm"
      onConfirm={() => saveCreditCard()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError || form.cardResultError}
    >
      <div className="alert alert--standard margin-bottom-20">
        <p className="fs-n0 margin-bottom-0">
          This credit card will be used for postage purchases.
        </p>
      </div>
      <NetZeroCreditCardForm
        form={form}
        errors={errors}
        updateForm={updateModalForm}
        onCardFormEvent={onCardFormEvent}
      />
    </ConfirmModal>
  )
}

AccountCreditCardModal.propTypes = {
  form: NetZeroCreditCardFormShape.isRequired,
}

export default onlyIfForm(AccountCreditCardModal, modalFormSelector)
