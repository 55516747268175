import get from 'lodash/get.js'

import {OrderShape} from '../../../../../../../common/PropTypes.js'
import ShipperOptions from '../../../../../../../data/shipperOptions.js'
import LabelItem from './LabelItem.js'

export default function ShippedDeliveryConfirmation({order}) {
  const deliveryConfirmation = get(order, 'shipping_info.delivery_confirmation')

  if (!deliveryConfirmation) {
    return null
  }

  const shipperType = get(order, 'shipping_info.carrier_name')
  const deliveryConfirmationOptions = get(ShipperOptions, [
    shipperType,
    'delivery_confirmation',
  ])

  const deliveryConfirmationOption =
    deliveryConfirmationOptions &&
    deliveryConfirmationOptions.find(
      ({value}) => value === deliveryConfirmation,
    )

  return (
    <LabelItem label="Delivery Confirmation">
      {deliveryConfirmationOption
        ? deliveryConfirmationOption.display
        : deliveryConfirmation}
    </LabelItem>
  )
}

ShippedDeliveryConfirmation.propTypes = {
  order: OrderShape.isRequired,
}
