export const months = [
  {text: '1 - Jan', value: '01'},
  {text: '2 - Feb', value: '02'},
  {text: '3 - Mar', value: '03'},
  {text: '4 - Apr', value: '04'},
  {text: '5 - May', value: '05'},
  {text: '6 - Jun', value: '06'},
  {text: '7 - Jul', value: '07'},
  {text: '8 - Aug', value: '08'},
  {text: '9 - Sep', value: '09'},
  {text: '10 - Oct', value: '10'},
  {text: '11 - Nov', value: '11'},
  {text: '12 - Dec', value: '12'},
]

export const monthsLongNames = [
  {text: '1 - Jan', value: 'January'},
  {text: '2 - Feb', value: 'February'},
  {text: '3 - Mar', value: 'March'},
  {text: '4 - Apr', value: 'April'},
  {text: '5 - May', value: 'May'},
  {text: '6 - Jun', value: 'June'},
  {text: '7 - Jul', value: 'July'},
  {text: '8 - Aug', value: 'August'},
  {text: '9 - Sep', value: 'September'},
  {text: '10 - Oct', value: 'October'},
  {text: '11 - Nov', value: 'November'},
  {text: '12 - Dec', value: 'December'},
]
