import PropTypes from 'prop-types'
import {useRef, useEffect} from 'react'
import {select} from 'd3-selection'
import {scaleTime, scaleLinear} from 'd3-scale'
import {line, area, curveBumpX} from 'd3-shape'
import startOfDay from 'date-fns/startOfDay'
import parse from 'date-fns/parse'
import addDays from 'date-fns/addDays'
import dateFormat from 'date-fns/format'

import {DateShape} from '../../common/PropTypes.js'

function cloneBucketForDate(date, originalBucket) {
  return {
    ...originalBucket,
    key: date.toISOString(),
    key_as_string: dateFormat(date, 'yyyy-MM-dd'),
    date,
  }
}

function renderGraph(
  data,
  lines,
  width,
  height,
  startDate,
  endDate,
  groupElement,
  margin,
) {
  if (!groupElement) {
    return
  }

  const group = select(groupElement)
  group.html('')

  if (!data) {
    return
  }

  if (data.buckets.length === 0) {
    return
  }

  if (height <= 0 || width <= 0) {
    return
  }

  const x = scaleTime().range([0, width])

  const y = scaleLinear().range([height, 0])

  group.attr('transform', `translate(${margin.left},${margin.top})`)

  const yMax = data.buckets.reduce((prev, d) => {
    d.date = parse(d.key_as_string, 'yyyy-MM-dd', new Date())

    lines.forEach((title) => {
      prev = Math.max(prev, d[title])
    })

    return prev
  }, 10)

  startDate = startOfDay(startDate)
  endDate = startOfDay(endDate)

  let current = new Date(startDate)
  const buckets = []
  const emptyBucket = lines.reduce((prev, title) => {
    prev[title] = 0

    return prev
  }, {})

  let dataIndex = 0
  while (current <= endDate) {
    const currentBucket = data.buckets[dataIndex]

    if (currentBucket && currentBucket.date <= current) {
      buckets.push(currentBucket)

      dataIndex += 1
    } else {
      buckets.push(cloneBucketForDate(current, emptyBucket))
    }

    current = addDays(current, 1)
  }

  buckets.push(cloneBucketForDate(current, buckets[buckets.length - 1]))

  x.domain([startDate, current])
  y.domain([0, yMax])

  lines.forEach((title, index) => {
    const graphLine = line()
      .x((d) => x(d.date))
      .y((d) => y(d[title]))
      .curve(curveBumpX)

    const graphArea = area()
      .x((d) => x(d.date))
      .y0(height)
      .y1((d) => y(d[title]))
      .curve(curveBumpX)

    group
      .append('path')
      .datum(buckets)
      .attr('class', `line lc-0${index + 1}`)
      .attr('d', graphLine)

    group
      .append('path')
      .datum(buckets)
      .attr('class', `area ac-0${index + 1}`)
      .attr('d', graphArea)
  })
}

export default function SparkLineGraph({
  className,
  data,
  lines,
  startDate,
  endDate,
  width,
  height,
  margin,
}) {
  const groupRef = useRef(null)

  useEffect(() => {
    renderGraph(
      data,
      lines,
      width,
      height,
      startDate,
      endDate,
      groupRef.current,
      margin,
    )
  }, [data, lines, width, height, groupRef.current])

  return (
    <svg
      className={className}
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g ref={groupRef}></g>
    </svg>
  )
}

SparkLineGraph.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        date: DateShape,
      }),
    ).isRequired,
  }),
  startDate: DateShape,
  endDate: DateShape,
  lines: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
}
