import {useEffect} from 'react'

import {showMultiboxModal} from '../../OrderListPage/Modals/MultiboxModal/index.js'
import {
  boxShapeSelector,
  isMultiboxSelector,
  configureIndividualBoxShapeSelector,
  boxShapeOptionsSelector,
  onLabelPropertyValueChange,
  packagesSelector,
  updateParcel,
  updateLabelConfig,
  labelShipperTypeSelector,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {getState, useSelector} from '../../../store.js'
import {DEFAULT_DEPENDANT_PROPERTIES_FUNC} from './useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'

export function packageTypeLabelPropertiesFunc(shipperType) {
  return [`packages[0].${shipperType}__box_shape`]
}

export async function updateBoxShapeAll(labelInfoID, shipperType, boxShape) {
  try {
    const packages = packagesSelector(getState(), {labelInfoID})

    packages.forEach((_, index) =>
      updateParcel(labelInfoID, index, {
        [`${shipperType}__box_shape`]: boxShape,
      }),
    )

    await getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

export default function PackageType() {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const boxShape = useSelector((state) =>
    boxShapeSelector(state, {labelInfoID, shipperType, packagesIndex: 0}),
  )
  const options = useSelector((state) =>
    boxShapeOptionsSelector(state, {labelInfoID, shipperType}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )
  const configureIndividualBoxShape = useSelector((state) =>
    configureIndividualBoxShapeSelector(state, {labelInfoID, shipperType}),
  )
  const disabled = isMultibox && configureIndividualBoxShape

  useEffect(() => {
    if (!boxShape && options[0]) {
      updateBoxShapeAll(labelInfoID, shipperType, options[0].value)
    }
  }, [shipperType, boxShape, options])

  const [labelProperty] = packageTypeLabelPropertiesFunc(shipperType)

  useEffect(() => {
    if (!shipperType) {
      return
    }

    onLabelPropertyValueChange(labelInfoID, shipperType, labelProperty)
  }, [shipperType, boxShape])

  const id = `${labelInfoID}__${labelProperty}`

  return (
    <ConfigSelect
      className="list__item--shipping-options flex-grow"
      id={id}
      value={disabled ? '' : boxShape}
      options={options}
      noEmptyOption={!disabled}
      disabled={disabled}
      onChange={(payload) =>
        updateBoxShapeAll(labelInfoID, shipperType, payload)
      }
      Label={() => (
        <>
          <label className="label--bold" htmlFor={id}>
            Package Type<span className="required">*</span>
          </label>
          {disabled && (
            <button
              className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
              type="button"
              onClick={() => showMultiboxModal(labelInfoID, shipperType)}
            >
              <em>(Multiple)</em>
            </button>
          )}
        </>
      )}
      labelProperty={labelProperty}
      labelPropertiesFunc={packageTypeLabelPropertiesFunc}
      dependantPropertiesFunc={DEFAULT_DEPENDANT_PROPERTIES_FUNC}
    />
  )
}
