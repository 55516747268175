import {useSelector} from '../../../../../store.js'
import {LabelInfoIDsShape} from '../../../../../common/PropTypes.js'
import {
  unavailableLabelConfigsSelector,
  unavailableShippingMethodSelector,
} from '../../../../../data/presets.js'
import {orderNumbersFromLabelConfigsSelector} from '../../../../../data/labelInfos/index.js'
import {deselectOrder} from '../../../../OrderListPage/orderListActions.js'

export default function Warning({labelInfoIDs}) {
  const unavailableOrderNumbers = useSelector((state) =>
    orderNumbersFromLabelConfigsSelector(state, {
      labelInfoIDs: unavailableLabelConfigsSelector(state, {
        labelInfoIDs,
      }),
    }),
  )
  const unavailableShippingMethod = useSelector((state) =>
    unavailableShippingMethodSelector(state, {
      labelInfoIDs,
    }),
  )

  if (!unavailableShippingMethod) {
    return null
  }

  return (
    <div className="alert alert--warning alert--warning-lt">
      <p className="fs-n0 margin-bottom-15">
        <strong>
          {unavailableShippingMethod} is not available for these orders:
        </strong>
      </p>
      <ul className="list list--square">
        {unavailableOrderNumbers.map((orderNumber) => (
          <li
            key={orderNumber}
            className="list__item list__item--mb0-last fs-n1"
          >
            <span className="margin-right-5">{orderNumber}</span>
            <button
              className="btn btn--link v-align-base"
              type="button"
              onClick={() => deselectOrder(orderNumber)}
            >
              Deselect
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

Warning.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
