import PropTypes from 'prop-types'

import {updateForm, useForm, useSelector} from '../../../store.js'
import Select from '../Common/Select.js'
import {packingListOptionsSelector} from '../../../redux/selectors/data/packingLists.js'
import FormListItem from '../Common/FormListItem.js'

export default function PackingListSelect({className, formName}) {
  const {defaultPackingListID} = useForm(formName)
  const cartPackingListOptions = useSelector(packingListOptionsSelector)

  return (
    <FormListItem className={className}>
      <Select
        label="Default Profile"
        name="default_packing_list"
        value={defaultPackingListID || ''}
        onChange={(event) =>
          updateForm(formName, {defaultPackingListID: event.target.value})
        }
      >
        <option value="">No Default</option>
        {cartPackingListOptions.map((vendor) => (
          <option key={vendor.value} value={vendor.value}>
            {vendor.display}
          </option>
        ))}
      </Select>
    </FormListItem>
  )
}

PackingListSelect.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
