import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import verde from '../../../common/verde.js'

async function getEULA(setEULAText) {
  if (!EULA) {
    const {raw} = await verde.get('/fedex/eula')

    EULA = raw
  }

  setEULAText(EULA)
}
let EULA = ''

export function FedExEULA({onRockBottom}) {
  const [eulaText, setEULAText] = useState(EULA)

  useEffect(() => {
    getEULA(setEULAText)
  }, [])

  return (
    <div
      className="wrap--eula-scroll wrap--eula-scroll-fedex"
      onScroll={(event) => {
        const element = event.target

        if (element.scrollTop >= element.scrollHeight - element.offsetHeight) {
          onRockBottom(true)
        }
      }}
      dangerouslySetInnerHTML={{
        __html: eulaText,
      }}
    />
  )
}

FedExEULA.propTypes = {
  onRockBottom: PropTypes.func,
}
