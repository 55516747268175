import classNames from 'classnames'

import {
  ratesLoadingSelector,
  labelWarningsSelector,
} from '../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../store.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export default function LabelWarnings() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const warnings = useSelector((state) =>
    labelWarningsSelector(state, {labelInfoID, shipperType}),
  )
  const ratesLoading = useSelector((state) =>
    ratesLoadingSelector(state, {labelInfoID}),
  )

  if (warnings.length === 0) {
    return null
  }

  return (
    <pre
      className={classNames('wrap--error-wb margin-top-3 lh-sm', {
        'op-50': ratesLoading,
      })}
    >
      {warnings.map((warning, i) => (
        <small className="warning" key={i}>
          {warning}
        </small>
      ))}
    </pre>
  )
}
