import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import DatePicker from '../../../common/components/DatePicker.js'
import {hasOrderManageTagOnOrderPermissionSelector} from '../../../data/me.js'
import {
  updateCreateOrderFormOrderInfo,
  checkOrderNumber,
} from '../createOrderActions.js'
import {
  createOrderFormSelector,
  orderInfoErrorsSelector,
  selectedOrderTagsSelector,
} from '../createOrderSelectors.js'

import TagsDropdown from './TagsDropdown.js'
import Tag from './Tag.js'

export default function OrderInfo() {
  const {orderInfo} = useSelector(createOrderFormSelector)
  const errors = useSelector(orderInfoErrorsSelector)
  const tags = useSelector(selectedOrderTagsSelector)
  const hasOrderManageTagOnOrderPermission = useSelector(
    hasOrderManageTagOnOrderPermissionSelector,
  )

  return (
    <div className="medium-4 columns">
      <div className="panel panel--form-wrap">
        <div className="panel__header">
          <h3 className="fs-01">Order Info</h3>
        </div>
        <div className="panel__body">
          <ul className="list list--form">
            <li
              className={classNames('list__item--form', {
                error: errors.orderNumber,
              })}
            >
              <label className="label--bold" htmlFor="id_order_id">
                Order ID*
              </label>
              <div className="flex">
                <div className="wrap--input-prefix flex--justify-col">
                  <strong className="fs-n1 text--md-grey">M-</strong>
                </div>
                <input
                  type="text"
                  className="input--text input--med-h input--w-prefix"
                  id="id_order_id"
                  value={orderInfo.orderNumber}
                  onChange={(event) =>
                    updateCreateOrderFormOrderInfo({
                      orderNumber: event.target.value,
                    })
                  }
                  onBlur={() => checkOrderNumber()}
                />
              </div>
              {errors.orderNumber && (
                <small className="error">{errors.orderNumber}</small>
              )}
            </li>
            <li
              className={classNames('list__item--form wrap--datepicker', {
                error: errors.orderDate,
              })}
            >
              <label className="label--bold" htmlFor="id_order_date">
                Order Date*
              </label>
              <DatePicker
                id="id_order_date"
                onChange={(date) =>
                  updateCreateOrderFormOrderInfo({
                    orderDate: date,
                  })
                }
                selected={orderInfo.orderDate}
              />
              {errors.orderDate && (
                <small className="error">{errors.orderDate}</small>
              )}
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_order_ship_method">
                Requested Shipping Method
              </label>
              <input
                type="text"
                className="input--text input--med-h"
                id="id_order_ship_method"
                value={orderInfo.shippingMethod}
                onChange={(event) =>
                  updateCreateOrderFormOrderInfo({
                    shippingMethod: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_customer_notes">
                Customer Notes{' '}
                <span className="unbold">(will appear on packing lists)</span>
              </label>
              <textarea
                className="textarea"
                id="id_customer_notes"
                value={orderInfo.customerNotes}
                onChange={(event) =>
                  updateCreateOrderFormOrderInfo({
                    customerNotes: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <label className="label--bold" htmlFor="id_internal_notes">
                Internal Notes
              </label>
              <textarea
                className="textarea"
                id="id_internal_notes"
                value={orderInfo.internalNotes}
                onChange={(event) =>
                  updateCreateOrderFormOrderInfo({
                    internalNotes: event.target.value,
                  })
                }
              />
            </li>
            <li className="list__item--form">
              <ul className="list">
                {tags.map((tag) => (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    canRemove={hasOrderManageTagOnOrderPermission}
                  />
                ))}
              </ul>
              <TagsDropdown />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
