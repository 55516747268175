import {useMemo} from 'react'

import {orderCountsSelector} from '../../../../data/orderCounts.js'
import {useSelector} from '../../../../store.js'
import {orderStatiiSelector} from '../../orderListSelectors.js'
import {
  updateModalForm,
  modalFormSelector,
  STATUS_FILTER_PANEL,
} from './orderListFilterModalFunctions.js'
import {createSelector} from 'reselect'
import FilterPanel from './FilterPanel.js'
import {
  getNounsFromValue,
  tokenizeOptions,
} from '../../../../common/tokenizeOptions.js'

const orderStatusOptionsSelector = createSelector(
  orderStatiiSelector,
  orderCountsSelector,
  (orderStatii, orderCounts) =>
    orderStatii.map(({name, slug}) => ({
      value: slug,
      display: (
        <>
          {name}
          {slug in orderCounts && (
            <span className="unbold"> ({orderCounts[slug]})</span>
          )}
        </>
      ),
      nouns: getNounsFromValue(`${slug} ${name}`),
    })),
)

export default function StatusFilterPanel() {
  const form = useSelector(modalFormSelector)
  const statusOptions = useSelector(orderStatusOptionsSelector)
  const statusOptionTokens = useMemo(
    () => tokenizeOptions(statusOptions),
    [statusOptions],
  )

  return (
    <FilterPanel
      id={STATUS_FILTER_PANEL}
      label="Status"
      searchPlaceholder="Search for a status filter..."
      options={statusOptions}
      optionTokens={statusOptionTokens}
      selected={form.status}
      onSelect={(option) => {
        const status = form.status.filter((id) => id !== option.value)

        if (status.length === form.status.length) {
          status.push(option.value)
        }

        updateModalForm({status})
      }}
    />
  )
}
