import {createSelector} from 'reselect'
import mapValues from 'lodash/mapValues.js'
import isEqual from 'lodash/isEqual.js'

import {ensureArray, ensureTroolean} from '../../common/ensure.js'
import {stringifyURL} from '../../common/querystring.js'
import {isPresent} from '../../common/utils.js'
import {TAG_FILTER_OR} from '../../common/constants/Tags.js'
import {
  MO_PLURAL_URI_COMPONENT,
  MO_SINGLE_URI_COMPONENT,
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  ALL_STATUS,
} from '../../common/constants/MOs.js'
import {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {formsSelector} from '../../store.js'
import {mosSelector} from '../../data/mos.js'
import {moTagsSortedByNameSelector} from '../../data/moTags.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'

export const MO_LIST_FORM = 'MO_LIST_FORM'

export function moListFormSelector(state) {
  return formsSelector(state)[MO_LIST_FORM]
}

export const referenceIDListSelector = createSelector(
  moListFormSelector,
  ({referenceIDList} = {}) => referenceIDList || [],
)

export function moListQuerySelector(state) {
  moListQuerySelector.cache = moListQuerySelector.cache || {}
  const cache = moListQuerySelector.cache

  const location = locationSelector(state)

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  const result = {
    searchText: location.query.searchText || '',
    status: location.query.status || DEFAULT_STATUS,
    sort: location.query.sort || DEFAULT_SORT,
    limit: Number(location.query.limit) || DEFAULT_PER_PAGE,
    offset: Number(location.query.offset) || 0,
    tags: ensureArray(location.query.tags),
    untagged: ensureTroolean(location.query.untagged) || false,
    tagFilterBy: location.query.tagFilterBy || TAG_FILTER_OR,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}

export const isLoadingSelector = createSelector(
  moListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const countSelector = createSelector(
  moListFormSelector,
  ({count} = {}) => count || 0,
)

export const expandModeSelector = createSelector(
  moListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedReferenceIDsSelector = createSelector(
  moListFormSelector,
  ({expandedReferenceIDs} = {}) => expandedReferenceIDs || [],
)

export const selectedReferenceIDsSelector = createSelector(
  moListFormSelector,
  ({selectedReferenceIDs} = {}) => selectedReferenceIDs || [],
)

export const allSelectedSelector = createSelector(
  referenceIDListSelector,
  selectedReferenceIDsSelector,
  (referenceIDList, selected) =>
    referenceIDList.length > 0 && referenceIDList.length === selected.length,
)

export const indeterminateSelectedSelector = createSelector(
  allSelectedSelector,
  selectedReferenceIDsSelector,
  (allSelected, selectedReferenceIDs) =>
    !allSelected && selectedReferenceIDs.length > 0,
)

export const firstSelectedReferenceIDSelector = createSelector(
  selectedReferenceIDsSelector,
  (selectedReferenceIDs) => selectedReferenceIDs[0],
)

function hashBuilder(params) {
  let hash = `#/${MO_PLURAL_URI_COMPONENT}`

  return stringifyURL(hash, params)
}

export const moListHashSelector = createSelector(
  moListQuerySelector,
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  moListFormSelector,
  ({sticky__limit, sticky__sort} = {}) => ({
    limit: sticky__limit !== DEFAULT_PER_PAGE ? sticky__limit : undefined,
    sort: sticky__sort !== DEFAULT_SORT ? sticky__sort : undefined,
  }),
)

export const defaultMOListHashSelector = createSelector(
  defaultHashParamsSelector,
  hashBuilder,
)

export function queryParamsSelector(state) {
  const {status, searchText, sort, tags, untagged, tagFilterBy, limit, offset} =
    moListQuerySelector(state)

  return {
    search: searchText.trim() || undefined,
    status: status !== ALL_STATUS ? status : undefined,
    sort,
    tag: tags.length ? tags : undefined,
    untagged: untagged || undefined,
    tag_filter_by: tagFilterBy !== TAG_FILTER_OR ? tagFilterBy : undefined,
    limit,
    offset,
  }
}

export const createIsSelectedSelector = (referenceID) =>
  createSelector(selectedReferenceIDsSelector, (selectedReferenceIDs) => {
    return selectedReferenceIDs.includes(referenceID)
  })

export const createIsExpandedSelector = (referenceID) =>
  createSelector(
    expandedReferenceIDsSelector,
    expandModeSelector,
    (expandedReferenceIDs, expandMode) => {
      if (expandMode === EXPAND_MODE_COLLAPSED) {
        return expandedReferenceIDs.includes(referenceID)
      }
      if (expandMode === EXPAND_MODE_EXPANDED) {
        return !expandedReferenceIDs.includes(referenceID)
      }
      return false
    },
  )

export const createDetailURLSelector = (referenceID) => () =>
  `#/${MO_SINGLE_URI_COMPONENT}/${encodeURIComponent(referenceID)}`

export const isFilteredSelector = createSelector(
  moListQuerySelector,
  ({searchText, tags}) => isPresent(searchText) || tags.length > 0,
)

export function createMOTagQuantityMapSelector() {
  return createSelector(
    (state, {referenceIDs}) => referenceIDs,
    mosSelector,
    moTagsSortedByNameSelector,
    (referenceIDs, mos, moTags) => {
      const moTagQuantityMap = moTags.reduce(
        (prev, tag) => ({
          ...prev,
          [tag.id]: 0,
        }),
        {},
      )

      referenceIDs.forEach((referenceID) => {
        const mo = mos[referenceID]

        if (!mo) {
          return
        }

        mo.tags.forEach((tag) => {
          moTagQuantityMap[tag.id] += 1
        })
      })

      return mapValues(moTagQuantityMap, (selectedQuantity) => {
        const diff = referenceIDs.length - selectedQuantity
        if (diff === 0) {
          return 'all'
        } else if (diff === referenceIDs.length) {
          return 'none'
        }

        return 'some'
      })
    },
  )
}
