import {useEffect} from 'react'
import analytics from '../../../common/analytics/index.js'

import SettingsPanel from '../SettingsPanel.js'

export default function ShippingInsurance() {
  useEffect(() => {
    analytics.trackEvent('insurance_marketing')
  }, [])

  return (
    <SettingsPanel
      header="Shipping Insurance"
      subHeader={
        <div>
          <p className="fs-01 margin-bottom-20">
            Protect shipments the easy way with our automatically built-in
            shipping insurance.
          </p>
          <hr className="margin-bottom-0" />
        </div>
      }
    >
      <div className="row margin-bottom-30">
        <div className="medium-8 columns">
          <dl className="list margin-bottom-30">
            <dt className="list__title fs-01 lh-sm margin-bottom-5">
              Integrated Provider:
            </dt>
            <dd className="fs-02 margin-bottom-0">Shipsurance</dd>
          </dl>
          <dl className="list margin-bottom-30">
            <dt className="list__title fs-00 lh-sm margin-bottom-15">
              Shipsurance Resources:
            </dt>
            <dd className="fs-00 margin-bottom-15">
              <ul className="list square">
                <li className="list__item fs-00">
                  <a
                    className="btn--link mid"
                    href="https://www.ordoro.com/features/shipping-insurance"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    General Info
                  </a>
                </li>
                <li className="list__item fs-00">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/category/shipping/insurance/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Help Articles
                  </a>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </SettingsPanel>
  )
}
