import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {isNonZeroPositiveNumeric} from '../../../common/utils.js'
import {NEW_RULE_ID} from '../../../common/constants/Rules.js'
import {LabelInfoIDShape} from '../../../common/PropTypes.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import Insurance from '../../LabelConfig/Fields/Insurance/Insurance.js'
import {
  updateLabelConfig,
  labelConfigSelector,
  insuranceCostSelector,
  insuredValueSelector,
  includeInsuranceSelector,
} from '../../../data/labelInfos/index.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'
import {savePreset} from '../../../data/presets.js'
import {saveRule} from '../../../data/rules.js'
import analytics from '../../../common/analytics/index.js'
import LabelConfigContext from '../../LabelConfig/LabelConfigContext.js'

export const MODAL_FORM = 'INSURANCE_MODAL_FORM'

export async function showInsuranceModal(labelInfoID, shipperType) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  setForm(MODAL_FORM, {
    labelInfoID,
    shipperType,
    savedIncludeInsurance: labelConfig.include_insurance,
    savedInsuredValue: labelConfig.insured_value,
    setupAutomaticInsurance: false,
    automaticInsuranceValue: '100',
    isSaving: false,
    serverError: null,
  })

  await analytics.trackEvent('insurance_modal')
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export const modalErrorsSelector = createSelector(modalFormSelector, (form) => {
  const errors = {}

  if (
    form.setupAutomaticInsurance &&
    !isNonZeroPositiveNumeric(form.automaticInsuranceValue)
  ) {
    errors.automaticInsuranceValue = 'Value needs to be a positive number'
  }

  return errors
})

export async function cancelEdit() {
  const {labelInfoID, savedIncludeInsurance, savedInsuredValue} =
    modalFormSelector(getState())
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  if (
    labelConfig.include_insurance !== savedIncludeInsurance ||
    labelConfig.insured_value !== savedInsuredValue
  ) {
    updateLabelConfig(labelInfoID, {
      include_insurance: savedIncludeInsurance,
      insured_value: savedInsuredValue,
    })

    await getRates([labelInfoID])
  }
}

export async function createAutomaticInsurance() {
  const {setupAutomaticInsurance, automaticInsuranceValue} =
    modalFormSelector(getState())

  if (!setupAutomaticInsurance) {
    return
  }

  const value = Number(automaticInsuranceValue)

  try {
    updateModalForm({
      serverError: null,
      isSaving: true,
    })

    const preset = await savePreset({
      name: 'Add Insurance for order value',
      body: {
        properties: ['include_insurance'],
        labelConfig: {
          include_insurance: true,
        },
      },
    })

    await saveRule({
      id: NEW_RULE_ID,
      active: true,
      latch: 'order_created',
      condition: {
        '>=': [{var: 'financial.product_amount'}, value],
      },
      action: [
        {
          data: {
            preset_ids: [preset.id],
            preset_type: 'shipment_preset',
          },
          type: 'apply_presets',
        },
      ],
    })

    showMessageToast(
      `Insurance will now be added to all future orders valued over $${value.toFixed(
        2,
      )}.`,
    )
  } catch (err) {
    updateModalForm({
      serverError: `Something went wrong while setting up automatic insurance.: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function InsuranceModal({form}) {
  const {labelInfoID, shipperType} = form

  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {labelInfoID}),
  )
  const insuredValue = useSelector((state) =>
    insuredValueSelector(state, {labelInfoID}),
  )
  const insuranceCost = useSelector((state) =>
    insuranceCostSelector(state, {labelInfoID, shipperType}),
  )
  const errors = useSelector((state) => modalErrorsSelector(state))

  return (
    <ConfirmModal
      title="Add Insurance"
      modalSize="x-sm"
      onConfirm={() => {
        getRates([form.labelInfoID])

        createAutomaticInsurance()

        closeModal()
      }}
      onCancel={() => {
        cancelEdit()

        closeModal()
      }}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={!isEmpty(errors)}
      error={form.serverError}
    >
      <LabelConfigContext.Provider value={{labelInfoID, shipperType}}>
        <ul className="list">
          <Insurance
            includeInsurance={includeInsurance}
            toggle={() => {
              const isChecked = !includeInsurance
              if (!isChecked) {
                updateModalForm({
                  setupAutomaticInsurance: false,
                })
              }

              updateLabelConfig(form.labelInfoID, {
                include_insurance: isChecked,
              })
            }}
            insuredValue={insuredValue}
            setInsuredValue={(value) => {
              updateLabelConfig(form.labelInfoID, {insured_value: value})
            }}
            insuranceCost={insuranceCost}
          />
        </ul>
        {includeInsurance && (
          <div className="alert alert--standard margin-top-20">
            <label
              className="margin-bottom-0 meta-labelconfigform-automatic-insurance"
              htmlFor="apply_automatic_insurance"
            >
              <input
                type="checkbox"
                id="apply_automatic_insurance"
                className="margin-right-5 margin-bottom-0 meta-labelconfigform-automatic-insurance"
                value={form.setupAutomaticInsurance}
                onChange={() => {
                  updateModalForm({
                    setupAutomaticInsurance: !form.setupAutomaticInsurance,
                  })
                }}
              />
              <span>Automatically apply insurance</span>
            </label>
            {form.setupAutomaticInsurance && (
              <div className="wrap--edit-mode">
                <label
                  className="label--sm unbold"
                  htmlFor="automatic_insurance_value"
                >
                  For all orders valued over:
                </label>
                <NumberInput
                  id="automatic_insurance_value"
                  value={form.automaticInsuranceValue}
                  onChange={(value) => {
                    updateModalForm({
                      automaticInsuranceValue: `${value}`,
                    })
                  }}
                />
                {errors.automaticInsuranceValue && (
                  <small className="error">
                    {errors.automaticInsuranceValue}
                  </small>
                )}
              </div>
            )}
          </div>
        )}
      </LabelConfigContext.Provider>
    </ConfirmModal>
  )
}

InsuranceModal.propTypes = {
  form: PropTypes.shape({
    labelInfoID: LabelInfoIDShape.isRequired,
    shipperType: PropTypes.string.isRequired,
    setupAutomaticInsurance: PropTypes.bool.isRequired,
    automaticInsuranceValue: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(InsuranceModal, modalFormSelector)
