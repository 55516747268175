import PropTypes from 'prop-types'
import {useEffect} from 'react'
import classNames from 'classnames'

import {cartsSortedByNameSelector, getCartName} from '../../../../data/carts.js'
import {useSelector} from '../../../../store.js'
import {getConditionOption} from './conditionalOptions.js'

export default function ConditionValueCartSelect({condition, error, onChange}) {
  const option = getConditionOption(condition)
  const value = option.getValue(condition)

  const carts = useSelector(cartsSortedByNameSelector)

  useEffect(() => {
    if (value === null && carts.length) {
      onChange(carts[0].id)
    }
  }, [value, carts])

  if (!value) {
    return null
  }

  return (
    <div className={classNames('flex-item', {error})}>
      <select
        className="select w-100"
        onChange={(event) => onChange(Number(event.target.value))}
        value={value}
      >
        {carts.map((cart) => (
          <option key={cart.id} value={cart.id}>
            {getCartName(cart)}
          </option>
        ))}
      </select>
      {error && <small className="error error-message">{error}</small>}
    </div>
  )
}

ConditionValueCartSelect.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
}
