import PropTypes from 'prop-types'
import {Component} from 'react'

import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import BaseEditForm from '../BaseEditForm.js'
import EditOrderLine from './EditOrderLine.js'

export default class EditForm extends Component {
  constructor(props) {
    super(props)

    const updatePayload = (payloadDelta) => {
      this.props.updatePayload(this.props.data.index, payloadDelta)
    }

    this.onOrderIDChange = (value) => updatePayload({order_id: value})
    this.onOrderDateChange = (value) => updatePayload({order_date: value})
    this.onNotesFromCustomerChange = (value) =>
      updatePayload({notes_from_customer: value})
    this.onGrandTotalChange = (value) => updatePayload({grand_total: value})
    this.onProductAmountChange = (value) =>
      updatePayload({product_amount: value})
    this.onTaxAmountChange = (value) => updatePayload({tax_amount: value})
    this.onDiscountAmountChange = (value) =>
      updatePayload({discount_amount: value})
    this.onShippingAmountChange = (value) =>
      updatePayload({shipping_amount: value})
    this.onShippingTypeChange = (value) => updatePayload({shipping_type: value})

    this.onShipNameChange = (value) =>
      this.onAddressChange('shipping_address', 'name', value)
    this.onShipCompanyChange = (value) =>
      this.onAddressChange('shipping_address', 'company', value)
    this.onShipPhoneChange = (value) =>
      this.onAddressChange('shipping_address', 'phone', value)
    this.onShipEmailChange = (value) =>
      this.onAddressChange('shipping_address', 'email', value)
    this.onShipStreet1Change = (value) =>
      this.onAddressChange('shipping_address', 'street1', value)
    this.onShipStreet2Change = (value) =>
      this.onAddressChange('shipping_address', 'street2', value)
    this.onShipCityChange = (value) =>
      this.onAddressChange('shipping_address', 'city', value)
    this.onShipStateChange = (value) =>
      this.onAddressChange('shipping_address', 'state', value)
    this.onShipZipChange = (value) =>
      this.onAddressChange('shipping_address', 'zip', value)
    this.onShipCountryChange = (value) =>
      this.onAddressChange('shipping_address', 'country', value)

    this.onBillNameChange = (value) =>
      this.onAddressChange('billing_address', 'name', value)
    this.onBillCompanyChange = (value) =>
      this.onAddressChange('billing_address', 'company', value)
    this.onBillPhoneChange = (value) =>
      this.onAddressChange('billing_address', 'phone', value)
    this.onBillEmailChange = (value) =>
      this.onAddressChange('billing_address', 'email', value)
    this.onBillStreet1Change = (value) =>
      this.onAddressChange('billing_address', 'street1', value)
    this.onBillStreet2Change = (value) =>
      this.onAddressChange('billing_address', 'street2', value)
    this.onBillCityChange = (value) =>
      this.onAddressChange('billing_address', 'city', value)
    this.onBillStateChange = (value) =>
      this.onAddressChange('billing_address', 'state', value)
    this.onBillZipChange = (value) =>
      this.onAddressChange('billing_address', 'zip', value)
    this.onBillCountryChange = (value) =>
      this.onAddressChange('billing_address', 'country', value)
  }

  onAddressChange(addressType, name, value) {
    const index = this.props.data.index

    const address = {
      ...this.props.data.payload[addressType],
      [name]: value,
    }

    this.props.updatePayload(index, {[addressType]: address})
  }

  renderLines() {
    const lines = this.props.data.payload.lines
    const errors = this.props.data.errors.lines || {}

    return lines.map((line, index) => {
      const lineErrors = errors[index] || {}

      return (
        <EditOrderLine
          key={index}
          dataIndex={this.props.data.index}
          lines={lines}
          lineIndex={index}
          errors={lineErrors}
          updatePayload={this.props.updatePayload}
          className="wrap--edit-order-line"
        />
      )
    })
  }

  render() {
    const data = this.props.data

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <div className="medium-12 columns">
            <h5>Order Info</h5>
          </div>
        </Row>
        <Row>
          <TextInput
            value={data.payload.order_id}
            errorMessage={data.errors.order_id}
            name="order_id"
            label="Order ID"
            className="medium-12"
            onChange={this.onOrderIDChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.order_date}
            errorMessage={data.errors.order_date}
            name="order_date"
            label="Order Date"
            className="medium-12"
            onChange={this.onOrderDateChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.notes_from_customer}
            errorMessage={data.errors.notes_from_customer}
            name="notes_from_customer"
            label="Notes From Customer"
            className="medium-12"
            onChange={this.onNotesFromCustomerChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.grand_total}
            errorMessage={data.errors.grand_total}
            name="grand_total"
            label="Grand Total"
            className="medium-6"
            onChange={this.onGrandTotalChange}
            onBlur={() =>
              this.props.onBlur(this.props.data.index, 'grand_total')
            }
          />
          <TextInput
            value={data.payload.product_amount}
            errorMessage={data.errors.product_amount}
            name="product_amount"
            label="Product Amount"
            className="medium-6"
            onChange={this.onProductAmountChange}
            onBlur={() =>
              this.props.onBlur(this.props.data.index, 'product_amount')
            }
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.tax_amount}
            errorMessage={data.errors.tax_amount}
            name="tax_amount"
            label="Tax Amount"
            className="medium-6"
            onChange={this.onTaxAmountChange}
          />
          <TextInput
            value={data.payload.discount_amount}
            errorMessage={data.errors.discount_amount}
            name="discount_amount"
            label="Discount Amount"
            className="medium-6"
            onChange={this.onDiscountAmountChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_amount}
            errorMessage={data.errors.shipping_amount}
            name="shipping_amount"
            label="Shipping Amount"
            className="medium-6"
            onChange={this.onShippingAmountChange}
          />
          <TextInput
            value={data.payload.shipping_type}
            errorMessage={data.errors.shipping_type}
            name="shipping_type"
            label="Shipping Type"
            className="medium-6"
            onChange={this.onShippingTypeChange}
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5>Shipping Address</h5>
          </div>
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.name}
            errorMessage={data.errors.ship_name}
            name="ship_name"
            label="Name"
            className="medium-6"
            onChange={this.onShipNameChange}
            fsExclude
          />
          <TextInput
            value={data.payload.shipping_address.company}
            errorMessage={data.errors.ship_company}
            name="ship_company"
            label="Company"
            className="medium-6"
            onChange={this.onShipCompanyChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.phone}
            errorMessage={data.errors.ship_phone}
            name="ship_phone"
            label="Phone"
            className="medium-6"
            onChange={this.onShipPhoneChange}
            fsExclude
          />
          <TextInput
            value={data.payload.shipping_address.email}
            errorMessage={data.errors.ship_email}
            name="ship_email"
            label="Email"
            className="medium-6"
            onChange={this.onShipEmailChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.street1}
            errorMessage={data.errors.ship_street1}
            name="ship_street1"
            label="Street1"
            className="medium-12"
            onChange={this.onShipStreet1Change}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.street2}
            errorMessage={data.errors.ship_street2}
            name="ship_street2"
            label="Street2"
            className="medium-12"
            onChange={this.onShipStreet2Change}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.city}
            errorMessage={data.errors.ship_city}
            name="ship_city"
            label="City"
            className="medium-9"
            onChange={this.onShipCityChange}
            fsExclude
          />
          <TextInput
            value={data.payload.shipping_address.state}
            errorMessage={data.errors.ship_state}
            name="ship_state"
            label="State"
            className="medium-3"
            onChange={this.onShipStateChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.zip}
            errorMessage={data.errors.ship_zip}
            name="ship_zip"
            label="Zip"
            className="medium-6"
            onChange={this.onShipZipChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.shipping_address.country}
            errorMessage={data.errors.ship_country}
            name="ship_country"
            label="Country"
            className="medium-6"
            onChange={this.onShipCountryChange}
            fsExclude
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5>Billing Address</h5>
          </div>
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.name}
            errorMessage={data.errors.bill_name}
            name="bill_name"
            label="Name"
            className="medium-6"
            onChange={this.onBillNameChange}
            fsExclude
          />
          <TextInput
            value={data.payload.billing_address.company}
            errorMessage={data.errors.bill_company}
            name="bill_company"
            label="Company"
            className="medium-6"
            onChange={this.onBillCompanyChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.phone}
            errorMessage={data.errors.bill_phone}
            name="bill_phone"
            label="Phone"
            className="medium-6"
            onChange={this.onBillPhoneChange}
            fsExclude
          />
          <TextInput
            value={data.payload.billing_address.email}
            errorMessage={data.errors.bill_email}
            name="bill_email"
            label="Email"
            className="medium-6"
            onChange={this.onBillEmailChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.street1}
            errorMessage={data.errors.bill_street1}
            name="bill_street1"
            label="Street1"
            className="medium-12"
            onChange={this.onBillStreet1Change}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.street2}
            errorMessage={data.errors.bill_street2}
            name="bill_street2"
            label="Street2"
            className="medium-12"
            onChange={this.onBillStreet2Change}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.city}
            errorMessage={data.errors.bill_city}
            name="bill_city"
            label="City"
            className="medium-9"
            onChange={this.onBillCityChange}
            fsExclude
          />
          <TextInput
            value={data.payload.billing_address.state}
            errorMessage={data.errors.bill_state}
            name="bill_state"
            label="State"
            className="medium-3"
            onChange={this.onBillStateChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.zip}
            errorMessage={data.errors.bill_zip}
            name="bill_zip"
            label="Zip"
            className="medium-6"
            onChange={this.onBillZipChange}
            fsExclude
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.billing_address.country}
            errorMessage={data.errors.bill_country}
            name="bill_country"
            label="Country"
            className="medium-6"
            onChange={this.onBillCountryChange}
            fsExclude
          />
        </Row>
        <Row>
          <div className="medium-12 columns">
            <hr />
            <h5 className="margin-bottom-0">Order Lines</h5>
          </div>
        </Row>
        {this.renderLines()}
      </BaseEditForm>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}
