import PropTypes from 'prop-types'
import flatMap from 'lodash/flatMap.js'

import Unusable from './Unusable.js'

export default function UnusableWarnings({
  warningMessages,
  singleTitle,
  pluralTitle,
  showDeselect,
  deselect,
}) {
  const unusableIDs = flatMap(
    warningMessages,
    (warningMessage) => warningMessage.ids,
  )

  if (unusableIDs.length === 0) {
    return null
  }

  const title = unusableIDs.length > 1 ? pluralTitle : singleTitle
  return (
    <div className="alert alert--tally">
      <p className="fs-n0 margin-bottom-5">
        <strong>{title}</strong>
      </p>
      <ul className="list list--square">
        {warningMessages.map(({ids, singleMessage, pluralMessage}, i) => {
          const message = ids.length > 1 ? pluralMessage : singleMessage

          return (
            <Unusable
              key={i}
              ids={ids}
              showDeselect={showDeselect}
              deselect={deselect}
            >
              {message}
            </Unusable>
          )
        })}
      </ul>
    </div>
  )
}

UnusableWarnings.propTypes = {
  warningMessages: PropTypes.arrayOf(
    PropTypes.shape({
      ids: PropTypes.arrayOf(PropTypes.string).isRequired,
      pluralMessage: PropTypes.string.isRequired,
      singleMessage: PropTypes.string.isRequired,
    }),
  ).isRequired,
  singleTitle: PropTypes.string.isRequired,
  pluralTitle: PropTypes.string.isRequired,
  showDeselect: PropTypes.bool.isRequired,
  deselect: PropTypes.func.isRequired,
}
