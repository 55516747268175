import {createSelector} from 'reselect'
import {NEW_ID} from '../../../common/constants/index.js'
import {QUICK_BOOKS} from '../../../common/constants/Integrations.js'
import {
  ACCOUNTING,
  ACCOUNTING_SETTINGS_LINK,
  SETTINGS,
} from '../../../common/constants/SettingsPanels.js'
import {isPositiveNumeric} from '../../../common/utils.js'
import {locationSelector} from '../../../redux/selectors/ui/location.js'

export const settingsAccountingEditIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === ACCOUNTING &&
      isPositiveNumeric(pathComponents[2])
    ) {
      return Number(pathComponents[2])
    }

    return null
  },
)

export const settingsAccountingAddSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    return (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === ACCOUNTING &&
      pathComponents[2] === NEW_ID
    )
  },
)

export const accountingHashSelector = createSelector(
  settingsAccountingEditIDSelector,
  settingsAccountingAddSelector,
  (integrationID, isNew) => {
    if (isNew) {
      return `${ACCOUNTING_SETTINGS_LINK}/${NEW_ID}`
    }

    if (integrationID) {
      return `${ACCOUNTING_SETTINGS_LINK}/${integrationID}`
    }

    return ACCOUNTING_SETTINGS_LINK
  },
)

export function accountingIntegrationOptionsSelector() {
  return [{value: QUICK_BOOKS, display: 'QuickBooks'}]
}
