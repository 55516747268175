import PropTypes from 'prop-types'

import cn from '../../../common/className.js'
import {SelectOptionsShape} from '../../../common/PropTypes.js'
import Select from '../../../common/components/Select.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {useSelector} from '../../../store.js'
import {labelConfigSelector} from '../../../data/labelInfos/index.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import ShipperOptions from '../../../data/shipperOptions.js'

export default function ConfigSelect({
  className,
  id,
  label,
  Label,
  value,
  options,
  hide,
  disabled,
  errorMessage,
  required,
  noEmptyOption,
  onChange,
  children,
  validShipperTypes,
  labelProperty,
  labelPropertiesFunc,
  labelPropertyCheckedSelector,
  shipperOptionsProperty,
  dependantPropertiesFunc,
  validLabelProperties,
  preventPropertyDisable,
}) {
  const {labelInfoID, shipperType} = useLabelConfigContext()

  labelProperty = labelProperty || ''
  id = id || `${labelInfoID}__${labelProperty}`

  const obj = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes,
    dependantPropertiesFunc,
    validLabelProperties,
  })

  validShipperTypes = obj.validShipperTypes
  labelPropertiesFunc = obj.labelPropertiesFunc
  dependantPropertiesFunc = obj.dependantPropertiesFunc

  value = useSelector((state) =>
    value === undefined
      ? labelConfigSelector(state, {labelInfoID})[labelProperty]
      : value,
  )

  options =
    options ||
    (shipperOptionsProperty &&
      ShipperOptions[shipperType][shipperOptionsProperty]) ||
    undefined

  // if the value is undefined for some reason then show empty option
  // only for selects with a force "noEmptyOption"
  noEmptyOption =
    noEmptyOption === true && value === undefined ? false : noEmptyOption

  // enforce a value
  value = value || ''

  hide = hide || (!!shipperType && !validShipperTypes.includes(shipperType))

  if (hide) {
    return null
  }

  return (
    <li
      className={cn`list__item--shipping-options list__item--shipping-options-select ${formatMetaClassName(
        labelProperty,
      )} ${className}`}
    >
      <div
        className={cn`wrap--edit-preset-form-input ${
          preventPropertyDisable && 'wrap--edit-preset-prevent-disable'
        }`}
      >
        {Label ? <Label /> : null}
        <Select
          className="w-100"
          labelClassName="label--bold"
          label={label}
          id={id}
          onChange={(newValue) =>
            onChange(newValue === 'None' ? null : newValue)
          }
          value={value}
          disabled={disabled}
          options={options}
          errorMessage={errorMessage}
          required={required}
        >
          {!noEmptyOption && <option value=""></option>}
          {children}
        </Select>
      </div>
      <EnableLabelProperty
        labelProperty={labelProperty}
        checkedSelector={labelPropertyCheckedSelector}
      />
    </li>
  )
}

ConfigSelect.propTypes = {
  label: PropTypes.string,
  Label: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  noEmptyOption: PropTypes.bool,
  value: PropTypes.any,
  options: SelectOptionsShape,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  validShipperTypes: PropTypes.arrayOf(PropTypes.string),
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelPropertiesFunc: PropTypes.func,
  labelPropertyCheckedSelector: PropTypes.func,
  shipperOptionsProperty: PropTypes.string,
  dependantPropertiesFunc: PropTypes.func,
  validLabelProperties: PropTypes.arrayOf(PropTypes.string),
  preventPropertyDisable: PropTypes.bool,
}
