import {createSelector} from 'reselect'
import moment from 'moment'
import get from 'lodash/get.js'

import {QUICK_BOOKS} from '../../../common/constants/Integrations.js'
import {BIGCOMMERCE, ETSY} from '../../../common/constants/CartVendorOptions.js'
import {activeCartsSortedByNameSelector} from '../../../data/carts.js'
import {integrationsSortedByNameSelector} from '../../../data/integrations.js'
import {nowToTheMinuteSelector} from '../dateTime.js'
import {shipperIntegrationIssuesSelector} from '../../../data/shippers.js'
import {paymentAccountIntegrationIssuesSelector} from '../../../data/paymentAccounts.js'

export const showSetupGuideSelector = (state) =>
  state.ui.dashboard.showSetupGuide

export const integrationIssuesSelector = createSelector(
  activeCartsSortedByNameSelector,
  shipperIntegrationIssuesSelector,
  integrationsSortedByNameSelector,
  paymentAccountIntegrationIssuesSelector,
  nowToTheMinuteSelector,
  (
    carts,
    shipperIntegrationIssues,
    integrations,
    paymentAccountIntegrationIssues,
    now,
  ) => {
    return [
      ...carts.reduce((prev, cart) => {
        if (cart.vendor_config.needs_reauth) {
          prev.push({
            id: cart.id,
            type: 'cart',
            name: cart.name,
            message: 'needs to be reauthorized',
            errorType: 'needs_reauth',
            link: `#/settings/saleschannels/${cart.id}/edit`,
          })
        } else if (cart.vendor_config.refresh_token_expiration) {
          const expirationDate = moment(
            cart.vendor_config.refresh_token_expiration,
          )

          if (expirationDate.isBefore(now)) {
            prev.push({
              id: cart.id,
              type: 'cart',
              name: cart.name,
              message: 'needs to be reauthorized',
              errorType: 'needs_reauth',
              link: `#/settings/saleschannels/${cart.id}/edit`,
            })
          } else if (
            expirationDate.clone().subtract(1, 'weeks').isBefore(now)
          ) {
            prev.push({
              id: cart.id,
              type: 'cart',
              name: cart.name,
              message: `will need to be reauthorized by ${expirationDate.format(
                'MMM D, YYYY',
              )}`,
              errorType: 'needs_reauth',
              link: `#/settings/saleschannels/${cart.id}/edit`,
            })
          }
        } else if (cart.vendor === BIGCOMMERCE) {
          const hasOldAPIKeys = !get(cart, 'vendor_config.context')

          if (hasOldAPIKeys) {
            prev.push({
              id: cart.id,
              type: 'cart',
              name: cart.name,
              message: 'needs to be reauthorized',
              errorType: 'needs_reauth',
              link: `#/settings/saleschannels/${cart.id}/edit`,
            })
          }
        } else if (cart.vendor === ETSY) {
          const usesOldOAuth = !get(cart, 'vendor_config.access_token')

          if (usesOldOAuth) {
            prev.push({
              id: cart.id,
              type: 'cart',
              name: cart.name,
              message: 'needs to be reauthorized',
              errorType: 'needs_reauth',
              link: `#/settings/saleschannels/${cart.id}/edit`,
            })
          }
        }

        return prev
      }, []),
      ...shipperIntegrationIssues,
      ...integrations.reduce((prev, integration) => {
        const refreshTokenExpiration =
          integration.vendor_config.refresh_token_expiration
        const expirationDate = refreshTokenExpiration
          ? moment(refreshTokenExpiration)
          : null

        if (
          integration.vendor === QUICK_BOOKS &&
          (!expirationDate || expirationDate.isBefore(now))
        ) {
          prev.push({
            id: integration.id,
            type: 'accounting',
            name: integration.name,
            message: 'needs to be reauthorized',
            errorType: 'needs_reauth',
            link: '#/settings/accounting/',
          })
        }

        return prev
      }, []),
      ...paymentAccountIntegrationIssues,
    ]
  },
)

export function cartAuthIntegrationIssueSelector(state, {cartID}) {
  const integrationIssues = integrationIssuesSelector(state)

  return integrationIssues.find(
    ({id, type, errorType}) =>
      type === 'cart' && errorType === 'needs_reauth' && id === cartID,
  )
}

export function accountingAuthIntegrationIssueSelector(state, {integrationID}) {
  const integrationIssues = integrationIssuesSelector(state)

  return integrationIssues.find(
    ({id, type, errorType}) =>
      type === 'accounting' &&
      errorType === 'needs_reauth' &&
      id === integrationID,
  )
}
