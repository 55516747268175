import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {setForm, updateForm, removeForm, formsSelector} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {enableCompanyFeature} from '../../../data/company.js'

export const MODAL_FORM = 'CONFIRM_CLOSE_SETUP_MODAL_FORM'

export function showConfirmCloseSetupModal() {
  setForm(MODAL_FORM, {
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)
  return forms[MODAL_FORM]
}

export async function confirm() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    await enableCompanyFeature('hide_unfinished_setup')

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error closing setup: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

function ConfirmCloseSetupModal({form}) {
  return (
    <ConfirmModal
      title="Close Setup Guide"
      onConfirm={() => confirm()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-0">
        <strong>Are you sure you want to close the quick start guide?</strong>
      </p>
    </ConfirmModal>
  )
}

ConfirmCloseSetupModal.propTypes = {
  form: PropTypes.shape({
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default formConnect(connect()(ConfirmCloseSetupModal), modalFormSelector)
