import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import LinkButton from '../../../../../../common/components/Link/LinkButton.js'
import {returnOrderReferenceIDsSelector} from '../../../../../../data/orders.js'

function LinkedRMAList({referenceIDs}) {
  if (referenceIDs.length === 0) {
    return null
  }

  return (
    <>
      <h4 className="list__title--order-data margin-bottom-3">
        Associated RMA<span className="lowercase">s</span>
      </h4>
      <ul className="list square margin-bottom-5">
        {referenceIDs.map((referenceID) => (
          <li className="list__item fs-n1 margin-bottom-1" key={referenceID}>
            <LinkButton
              className="darker"
              title={`Navigate to ${referenceID} detail page`}
              href={`#/rma/${encodeURIComponent(referenceID)}`}
            >
              {referenceID}
            </LinkButton>
          </li>
        ))}
      </ul>
    </>
  )
}

LinkedRMAList.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function mapStateToProps(state, {orderNumber}) {
  return {
    referenceIDs: returnOrderReferenceIDsSelector(state, {orderNumber}),
  }
}

export default connect(mapStateToProps)(LinkedRMAList)
