import {useEffect} from 'react'

import {useSelector} from '../../../../../store.js'
import {LabelInfoIDsShape} from '../../../../../common/PropTypes.js'
import {
  updateLabelConfig,
  resetBulkRates,
  labelShipperIDSelector,
  validBulkShipperIDsSelector,
} from '../../../../../data/labelInfos/index.js'
import Select from '../../../../../common/components/Select.js'
import ShipperOption from '../../../Fields/ShipperSelect/ShipperOption.js'
import {useLabelConfigContext} from '../../../LabelConfigContext.js'

export default function BulkShipperSelect({labelInfoIDs}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperID = useSelector(
    (state) => labelShipperIDSelector(state, {labelInfoID}) || '',
  )
  const shipperIDs = useSelector((state) =>
    validBulkShipperIDsSelector(state, {labelInfoIDs}),
  )

  useEffect(() => {
    if (!shipperID && shipperIDs.length > 0) {
      updateLabelConfig(labelInfoID, {
        shipper_id: shipperIDs[0],
      })
    }
  }, [shipperID, shipperIDs])

  return (
    <Select
      value={shipperID}
      className="list__item--shipping-options w-100 flex-grow"
      label="Carrier*"
      onChange={(payload) => {
        updateLabelConfig(labelInfoID, {shipper_id: Number(payload)})

        resetBulkRates(labelInfoIDs)
      }}
    >
      {shipperIDs.map((id) => (
        <ShipperOption shipperID={id} key={id} />
      ))}
    </Select>
  )
}

BulkShipperSelect.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
