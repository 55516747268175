import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  PRODUCT_DETAIL_KITTING_PANEL,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../common/constants/Products.js'
import handleListSelection from '../../common/handleListSelection.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showProductComponentModal} from '../ProductListPage/Modals/ProductComponentModal.js'
import {showRemoveComponentModal} from '../ProductListPage/Modals/RemoveComponentModal.js'
import {hasUseMOsFeatureSelector} from '../../data/company.js'
import {isBOMSelector} from '../../data/products.js'
import {
  ProductDetailFormShape,
  updateProductDetailForm,
} from './productDetailActions.js'
import {
  productDetailFormSelector,
  skuSelector,
  kitComponentsSelector,
  kitComponentSKUsSelector,
  areAllKitComponentsSelectedSelector,
  areIndeterminateKitComponentsSelectedSelector,
} from './productDetailSelectors.js'
import KitParentsPanel from './KitParentsPanel.js'

export function Kitting({
  form,
  sku,
  kitComponents,
  kitComponentSKUs,
  firstSelectedComponentQuantity,
  areAllKitComponentsSelected,
  areIndeterminateKitComponentsSelected,
  isBOM,
  hasUseMOsFeature,
}) {
  return (
    <div className="row flex">
      <div className="medium-9 columns padding-left-0">
        <dl className="list">
          <dt className="list__title fs-01 lh-md">Kit Parent Info</dt>
          <dd className="list__item fs-00 margin-top-10 margin-bottom-20">
            {isBOM
              ? 'This product is a BOM and cannot contain kit components.'
              : kitComponents.length === 0
                ? 'This product is not currently a kit parent.'
                : 'This product is a kit parent.'}
          </dd>
        </dl>
        {kitComponents.length > 0 && (
          <table className="table fs-00">
            <thead>
              <tr>
                <th className="table__th table__th--sm w-1">
                  {kitComponentSKUs.length === 0 ? (
                    ''
                  ) : (
                    <Checkbox
                      className="margin-bottom-0"
                      checked={areAllKitComponentsSelected}
                      indeterminate={areIndeterminateKitComponentsSelected}
                      onClick={(checked) =>
                        updateProductDetailForm({
                          selectedKitComponentSKUs: checked
                            ? kitComponentSKUs
                            : [],
                        })
                      }
                    />
                  )}
                </th>
                <th className="table__th table__th--md">Component Name</th>
                <th className="table__th table__th--md">SKU</th>
                <th className="table__th table__th--md align-right">
                  Phys On Hand
                </th>
                <th className="table__th table__th--md align-right">
                  Available
                </th>
                <th className="table__th table__th--md align-right">
                  {form.selectedKitComponentSKUs.length === 0 ? (
                    'Qty Needed for Kit'
                  ) : (
                    <ButtonLink
                      className="darker lh-md"
                      title="Bulk Update Quantity Needed for Kit"
                      onClick={() =>
                        showProductComponentModal(
                          sku,
                          form.selectedKitComponentSKUs,
                          firstSelectedComponentQuantity,
                        )
                      }
                    >
                      Qty Needed for Kit
                    </ButtonLink>
                  )}
                </th>
                <th className="table__th table__th--md align-right">
                  {form.selectedKitComponentSKUs.length === 0 ? (
                    ''
                  ) : (
                    <ButtonLink
                      className="no-underline"
                      title="Remove component"
                      onClick={() =>
                        showRemoveComponentModal(
                          sku,
                          form.selectedKitComponentSKUs,
                        )
                      }
                    >
                      <span className="i-trash fs-00" aria-hidden="true"></span>
                    </ButtonLink>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="table__tbody table__tbody--lines">
              {kitComponents.map((kitComponent) => (
                <tr key={kitComponent.sku}>
                  <td className="table__td">
                    <Checkbox
                      key={kitComponent.sku}
                      className="margin-bottom-0"
                      checked={form.selectedKitComponentSKUs.includes(
                        kitComponent.sku,
                      )}
                      onClick={(checked, event) =>
                        updateProductDetailForm({
                          selectedKitComponentSKUs: handleListSelection({
                            value: kitComponent.sku,
                            isSelected: checked,
                            isShiftKey: event.shiftKey,
                            selected: form.selectedKitComponentSKUs,
                            list: kitComponentSKUs,
                          }),
                        })
                      }
                    />
                  </td>
                  <td className="table__td table__td--md">
                    <LinkButton
                      href={`#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(
                        kitComponent.sku,
                      )}/${PRODUCT_DETAIL_KITTING_PANEL}`}
                      className="darker v-align-base"
                    >
                      <strong>{kitComponent.name}</strong>
                    </LinkButton>
                  </td>
                  <td className="table__td table__td--md">
                    <LinkButton
                      href={`#/product/${encodeURIComponent(
                        kitComponent.sku,
                      )}/${PRODUCT_DETAIL_KITTING_PANEL}`}
                      className="darker v-align-base"
                    >
                      {kitComponent.sku}
                    </LinkButton>
                  </td>
                  <td className="table__td table__td--md align-right">
                    {kitComponent.total_on_hand}
                  </td>
                  <td className="table__td table__td--md align-right">
                    {kitComponent.total_available}
                  </td>
                  <td className="table__td table__td--md align-right">
                    <ButtonLink
                      className="darker v-align-base"
                      onClick={() =>
                        showProductComponentModal(
                          sku,
                          [kitComponent.sku],
                          kitComponent.quantity,
                        )
                      }
                    >
                      {kitComponent.quantity}
                    </ButtonLink>
                  </td>
                  <td className="table__td table__td--md align-right">
                    <ButtonLink
                      className="no-underline"
                      title="Remove kit component"
                      onClick={() =>
                        showRemoveComponentModal(sku, [kitComponent.sku])
                      }
                    >
                      <span className="i-trash fs-00" aria-hidden="true"></span>
                    </ButtonLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {(kitComponents.length > 0 || !hasUseMOsFeature) && (
          <div className="align-center padding-top-15">
            <ButtonPrimary
              isOutlined
              size="sm"
              onClick={() => showProductComponentModal(sku)}
            >
              Add a kit component
            </ButtonPrimary>
          </div>
        )}
      </div>
      <div className="medium-3 columns border-left--light padding-right-0">
        <KitParentsPanel />
      </div>
    </div>
  )
}

Kitting.propTypes = {
  sku: PropTypes.string.isRequired,
  kitComponents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      total_on_hand: PropTypes.number.isRequired,
      total_available: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ),
  kitComponentSKUs: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: ProductDetailFormShape.isRequired,
  firstSelectedComponentQuantity: PropTypes.number,
  areAllKitComponentsSelected: PropTypes.bool.isRequired,
  areIndeterminateKitComponentsSelected: PropTypes.bool.isRequired,
  isBOM: PropTypes.bool.isRequired,
  hasUseMOsFeature: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const form = productDetailFormSelector(state)
  const sku = skuSelector(state)
  const kitComponents = kitComponentsSelector(state)
  const kitComponentSKUs = kitComponentSKUsSelector(state)
  const firstKitComponent = form.selectedKitComponentSKUs.length
    ? kitComponents.find(({sku}) => form.selectedKitComponentSKUs.includes(sku))
    : null

  return {
    sku,
    kitComponents,
    kitComponentSKUs,
    form,
    firstSelectedComponentQuantity: firstKitComponent
      ? firstKitComponent.quantity
      : null,
    areAllKitComponentsSelected: areAllKitComponentsSelectedSelector(state),
    areIndeterminateKitComponentsSelected:
      areIndeterminateKitComponentsSelectedSelector(state),
    isBOM: isBOMSelector(state, {sku}),
    hasUseMOsFeature: hasUseMOsFeatureSelector(state),
  }
}

export default connect(mapStateToProps)(Kitting)
