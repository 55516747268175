import {createSelector} from 'reselect'
import reduce from 'lodash/reduce.js'
import isEmpty from 'lodash/isEmpty.js'

import Stripe from '../../../../libs/stripe.js'

import {isPresent} from '../../../../common/utils.js'
import {shippersSelector} from '../../../../data/shippers.js'

export function endiciaCreditCardModalSelector(state) {
  return state.ui.modals.endiciaCreditCardModal
}

export const endiciaCreditCardFormSelector = createSelector(
  endiciaCreditCardModalSelector,
  (modal) => modal.form,
)

export const shipperSelector = createSelector(
  shippersSelector,
  endiciaCreditCardModalSelector,
  (shippers, {shipperID}) => shipperID && shippers[shipperID],
)

export const isSavingSelector = createSelector(
  endiciaCreditCardModalSelector,
  ({isSaving}) => isSaving,
)

export const errorsSelector = createSelector(
  endiciaCreditCardFormSelector,
  (form) => {
    const errors = {}

    if (!isPresent(form.cardNumber)) {
      errors.cardNumber = 'Card number is required'
    }

    if (form.cardNumber && !Stripe.card.validateCardNumber(form.cardNumber)) {
      errors.cardNumber = 'Invalid card number'
    }

    if (!isPresent(form.cardCVV)) {
      errors.cardCVV = 'CVC is required'
    }

    if (form.cardCVV && !Stripe.card.validateCVC(form.cardCVV)) {
      errors.cardCVV = 'Invalid CVC'
    }

    if (!isPresent(form.street1)) {
      errors.street1 = 'Street is required'
    }

    if (!isPresent(form.city)) {
      errors.city = 'City is required'
    }

    if (!isPresent(form.state)) {
      errors.state = 'State is required'
    }

    if (!isPresent(form.zip)) {
      errors.zip = 'Zipcode is required'
    }

    return errors
  },
)

export const serverErrorSelector = createSelector(
  endiciaCreditCardModalSelector,
  ({serverError}) => serverError,
)

export function getServerParamErrors(serverError) {
  const errors = {}

  if (['cardNumber', 'cardCVV'].includes(serverError.param)) {
    errors[serverError.param] = serverError.error_message
  }

  return errors
}

export const serverParamErrorsSelector = createSelector(
  serverErrorSelector,
  (serverError) => {
    const errors = {}

    if (serverError && serverError.param) {
      Object.assign(errors, getServerParamErrors(serverError))
    }

    if (serverError && isEmpty(errors)) {
      errors.serverError = serverError.message || serverError.error_message
    }

    return errors
  },
)

export const errorsOfChangedSelector = createSelector(
  (state) => endiciaCreditCardModalSelector(state).hasChanged,
  errorsSelector,
  serverParamErrorsSelector,
  (hasChanged, errors, serverParamErrors) =>
    reduce(
      errors,
      (prev, value, key) => {
        if (hasChanged[key]) {
          prev[key] = value
        }

        return prev
      },
      {...serverParamErrors},
    ),
)

export const topLevelErrorSelector = createSelector(
  serverParamErrorsSelector,
  (errors) => errors.serverError || '',
)

export const hasErrorsSelector = createSelector(
  errorsSelector,
  (errors) => !isEmpty(errors),
)

export function endiciaCardType(cardNumber) {
  const stripeCardType = Stripe.card.cardType(cardNumber) || ''

  const cardType = stripeCardType.replace(/[ ']/g, '')

  if (cardType === 'MasterCard') {
    return 'Mastercard'
  }

  return cardType
}

export function createParamsSelector(state) {
  const {
    cardNumber,
    cardCVV,
    street1,
    city,
    state: st,
    zip,
    expirationMonth,
    expirationYear,
  } = endiciaCreditCardFormSelector(state)

  const {
    vendor_config: {account_id: accountID, passphrase},
  } = shipperSelector(state)

  return {
    cardNumber,
    cardCVV,
    cardType: endiciaCardType(cardNumber),
    street1,
    city,
    state: st,
    zip,
    expirationMonth,
    expirationYear,
    accountID,
    passphrase,
  }
}
