import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {DATE_TYPE_OPTIONS} from '../../common/constants/Analytics.js'
import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {exportToCSV} from './analyticsActions.js'
import {
  dateTypeSelector,
  isLoadingAnalyticsSelector,
} from './analyticsSelectors.js'
import {
  summaryStatsSelector,
  shippingCostsByCarrierSelector,
  shippingCostsByMethodSelector,
  shippingCountsByCarrierSelector,
  shippingFulfillmentTimeSelector,
} from './shippingSelectors.js'
import Panel from './Panel.js'
import Header from './Header.js'
import Filters from './Filters.js'
import CartFilter from './CartFilter.js'
import DateFilter from './DateFilter.js'
import CartActiveFilters from './CartActiveFilters.js'
import SingleValuePane from './SingleValuePane.js'
import HorizontalBarPane from './HorizontalBarPane.js'

export function ShippingPanel({
  isLoading,
  shippingDateType,
  summaryStats,
  shippingCostsByCarrier,
  shippingFulfillmentTime,
  shippingCountsByCarrier,
  shippingCostsByMethod,
}) {
  return (
    <Panel>
      <Header>Shipping Stats</Header>
      <Filters>
        <CartFilter />
        <DateFilter
          dateTypes={DATE_TYPE_OPTIONS}
          selectedDateType={shippingDateType}
        />
      </Filters>
      <CartActiveFilters />
      <div className="row margin-top-25">
        <div className="medium-4 columns">
          <SingleValuePane title="Total Shipping Costs" isLoading={isLoading}>
            {!isLoading && summaryStats && (
              <Currency value={summaryStats.totalCosts} />
            )}
          </SingleValuePane>
        </div>
        <div className="medium-4 columns">
          <SingleValuePane title="Average Shipping Cost" isLoading={isLoading}>
            {!isLoading && summaryStats && (
              <Currency value={summaryStats.averageCost} />
            )}
          </SingleValuePane>
        </div>
        <div className="medium-4 columns end">
          <SingleValuePane title="Shipped Orders" isLoading={isLoading}>
            {!isLoading && summaryStats && (
              <Quantity value={summaryStats.totalCount} />
            )}
          </SingleValuePane>
        </div>
      </div>
      <div className="row">
        <div className="medium-6 columns">
          <HorizontalBarPane
            isLoading={isLoading}
            title="Shipping Costs by Carrier"
            data={shippingCostsByCarrier}
            isCurrency
            onExport={() => exportToCSV('shippingCostsByCarrier')}
          />
          <HorizontalBarPane
            isLoading={isLoading}
            title="Order Fulfillment Speed"
            data={shippingFulfillmentTime}
          />
        </div>
        <div className="medium-6 columns">
          <HorizontalBarPane
            isLoading={isLoading}
            title="Shipped Orders by Carrier"
            data={shippingCountsByCarrier}
            onExport={() => exportToCSV('shippingCountsByCarrier')}
          />
          <HorizontalBarPane
            isLoading={isLoading}
            title="Shipping Costs by Method"
            data={shippingCostsByMethod}
            isCurrency
            onExport={() => exportToCSV('shippingCostsByMethod')}
          />
        </div>
      </div>
    </Panel>
  )
}

ShippingPanel.propTypes = {
  shippingDateType: PropTypes.string.isRequired,
  summaryStats: PropTypes.shape({
    totalCosts: PropTypes.number.isRequired,
    averageCost: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
  }),
  shippingCostsByCarrier: PropTypes.shape({}),
  shippingFulfillmentTime: PropTypes.shape({}),
  shippingCountsByCarrier: PropTypes.shape({}),
  shippingCostsByMethod: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProp(state) {
  return {
    shippingDateType: dateTypeSelector(state),
    summaryStats: summaryStatsSelector(state),
    shippingCostsByCarrier: shippingCostsByCarrierSelector(state),
    shippingFulfillmentTime: shippingFulfillmentTimeSelector(state),
    shippingCountsByCarrier: shippingCountsByCarrierSelector(state),
    shippingCostsByMethod: shippingCostsByMethodSelector(state),
    isLoading: isLoadingAnalyticsSelector(state),
  }
}

export default connect(mapStateToProp)(ShippingPanel)
