import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'
import {isPresent, isPositiveNumeric} from '../../../../../common/utils.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

import {
  suppliersSelector,
  getSupplierName,
  activeSuppliersByNameSelector,
} from '../../../../../data/suppliers.js'

export default class supplierSKUStrategy extends baseStrategy {
  static title = 'Create/Update Supplier SKUs'
  static requiredColumns = ['sku', 'supplier']

  static getExampleRows() {
    return (dispatch, getState) => {
      const suppliers = suppliersSelector(getState())

      return api
        .get('/product/', {limit: 10, sort: 'default_supplier'})
        .then(({json: data}) => {
          const rows = [
            [
              'SKU *Req',
              'Supplier *Req',
              'Supplier SKU',
              'Supplier Unit Cost',
              'Default Supplier',
              'Min Order Quantity',
              'Lead Time',
            ],
          ]

          data.product.forEach((product) => {
            product.suppliers.forEach((supplier) => {
              rows.push([
                product.sku,
                getSupplierName(suppliers[supplier.id]),
                supplier.supplier_sku,
                supplier.supplier_price,
                this.fromBoolean(supplier.is_default),
                supplier.min_order_qty,
                supplier.supplier_lead_time || 0,
              ])
            })
          })

          return rows
        })
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      supplier: find(/^supplier$/i),
      supplier_sku: find(/^supplier.sku$/i),
      supplier_price: find(/(price|cost)/i),
      is_default: find(/default/i),
      min_order_qty: find(/min.*quantity|qty/i),
      supplier_lead_time: find(/(supplier.)?lead.time/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const suppliersByName = activeSuppliersByNameSelector(getState())
      const data = []

      for (let i = 0; i < rows.length; i++) {
        const object = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          suppliersByName,
        )
        object.index = i
        data.push(object)
      }

      return data
    }
  }

  static transformRowToData(row, columnsByName, suppliersByName) {
    const data = this.getNewData()
    const supplier = suppliersByName[row[columnsByName.supplier]] || {
      _link: '-1',
    }
    const isDefault = this.toBoolean(row[columnsByName.is_default])
    const minOrderQty = isPresent(row[columnsByName.min_order_qty])
      ? row[columnsByName.min_order_qty]
      : '1'
    const leadTime = isPresent(row[columnsByName.supplier_lead_time])
      ? row[columnsByName.supplier_lead_time]
      : '0'

    data.payload = {
      sku: row[columnsByName.sku],
      link: supplier._link,
      supplier_sku: row[columnsByName.supplier_sku],
      supplier_price: row[columnsByName.supplier_price],
      is_default: columnsByName.is_default !== -1 ? isDefault : undefined,
      min_order_qty:
        columnsByName.min_order_qty !== -1 ? minOrderQty : undefined,
      supplier_lead_time:
        columnsByName.supplier_lead_time !== -1 ? leadTime : undefined,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')
    this.requiredNumber(
      payload,
      errors,
      'supplier_price',
      'Supplier Unit Cost must be a number',
    )
    this.requiredNumber(
      payload,
      errors,
      'min_order_qty',
      'Quantity must be a number',
    )

    if (
      isPresent(payload.supplier_lead_time) &&
      !isPositiveNumeric(payload.supplier_lead_time)
    ) {
      errors.supplier_lead_time = 'Lead Time must be a positive number'
    } else {
      delete errors.supplier_lead_time
    }

    if (payload.link === '-1') {
      errors.link = 'Select a valid supplier'
    } else {
      delete errors.link
    }

    return errors
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const supplierLink = data.payload.link
      const url = `/product/${sku}${supplierLink}`

      const payload = {
        supplier_sku: data.payload.supplier_sku,
        supplier_price:
          data.payload.supplier_price !== undefined
            ? Number(data.payload.supplier_price)
            : undefined,
        min_order_qty:
          data.payload.min_order_qty !== undefined
            ? Number(data.payload.min_order_qty)
            : undefined,
        supplier_lead_time:
          data.payload.supplier_lead_time !== undefined
            ? Number(data.payload.supplier_lead_time)
            : undefined,
        is_default:
          data.payload.is_default !== undefined
            ? !!data.payload.is_default
            : undefined,
      }

      return api.put(url, payload)
    }
  }
}
