import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
  FULFILLMENT_OPTIONS,
  FULFILLMENT_NO_AUTO_OPTIONS,
} from '../../../../common/constants/Products.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'
import BaseEditForm from '../BaseEditForm.js'
import {autoDropshipOnOrderCreationSelector} from '../../../../data/company.js'

export default function EditForm({data, columnsToDataMap, ...props}) {
  const suppliersSortedByName = useSelector(suppliersSortedByNameSelector)
  const autoDropshipOnOrderCreation = useSelector(
    autoDropshipOnOrderCreationSelector,
  )

  return (
    <BaseEditForm className="medium-5 columns" {...props}>
      <Row>
        <Select
          value={
            data.payload.is_fulfillment_type_valid
              ? data.payload.fulfillment_type
              : ''
          }
          label="Fulfillment Type"
          name="fulfillment_type"
          className="columns medium-12 wrap--full-select"
          selectClassName="full-width"
          errorMessage={data.errors.fulfillment_type}
          onChange={(value) =>
            props.updatePayload(data.index, {
              fulfillment_type: value,
              is_fulfillment_type_valid: true,
            })
          }
        >
          {!data.payload.is_fulfillment_type_valid && (
            <option disabled value="">
              Invalid Fulfillment Type
            </option>
          )}
          {(autoDropshipOnOrderCreation
            ? FULFILLMENT_OPTIONS
            : FULFILLMENT_NO_AUTO_OPTIONS
          ).map(({display, value}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <TextInput
          value={data.payload.sku}
          label="SKU"
          name="sku"
          className="medium-12"
          errorMessage={data.errors.sku}
          onChange={(value) =>
            props.updatePayload(data.index, {
              sku: value,
            })
          }
        />
      </Row>
      {columnsToDataMap.default_supplier !== -1 &&
        [
          DROPSHIPPABLE_FULFILLMENT,
          AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
        ].includes(data.payload.fulfillment_type) && (
          <Row>
            <Select
              value={data.payload.default_supplier_id}
              errorMessage={data.errors.default_supplier_id}
              name="default_supplier_id"
              label="Default Supplier"
              className="columns medium-12 wrap--full-select"
              selectClassName="full-width"
              onChange={(value) =>
                props.updatePayload(data.index, {
                  default_supplier_id: value ? Number(value) : '',
                })
              }
            >
              {data.payload.default_supplier_id === -1 && (
                <option value={-1} disabled>
                  Invalid Supplier
                </option>
              )}
              {data.payload.default_supplier_id === '' && (
                <option value="" disabled></option>
              )}
              {suppliersSortedByName.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {getSupplierName(supplier)}
                </option>
              ))}
            </Select>
          </Row>
        )}
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
}
