import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default function ReconcileForm({
  columns,
  columnsToDataMap,
  columnErrors,
  updateColumnMapping,
  ...props
}) {
  if (isEmpty(columnsToDataMap)) {
    return null
  }

  return (
    <BaseReconcileForm className="medium-12" {...props}>
      <Row>
        <div className="medium-12 columns">
          <h5>Purchase Order Info</h5>
        </div>
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.po_id}
          errorMessage={columnErrors.po_id}
          name="po_id"
          label="Purchase Order ID"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.supplier_name}
          errorMessage={columnErrors.supplier_name}
          name="supplier_name"
          label="Supplier"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.warehouse_name}
          errorMessage={columnErrors.warehouse_name}
          name="warehouse_name"
          label="Warehouse"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.shipping_method}
          errorMessage={columnErrors.shipping_method}
          name="shipping_method"
          label="Shipping Method"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.payment_method}
          errorMessage={columnErrors.payment_method}
          name="payment_method"
          label="Payment Method"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.instructions}
          errorMessage={columnErrors.instructions}
          name="instructions"
          label="Instructions"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.estimated_delivery_date}
          errorMessage={columnErrors.estimated_delivery_date}
          name="estimated_delivery_date"
          label="Estimated Delivery Date"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.discount_amount}
          errorMessage={columnErrors.discount_amount}
          name="discount_amount"
          label="Discount Amount"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.shipping_amount}
          errorMessage={columnErrors.shipping_amount}
          name="shipping_amount"
          label="Shipping Amount"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.tax_amount}
          errorMessage={columnErrors.tax_amount}
          name="tax_amount"
          label="Tax Amount"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <div className="medium-12 columns">
          <hr />
          <h5>Items</h5>
        </div>
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.sku}
          errorMessage={columnErrors.sku}
          name="sku"
          label="SKU"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.quantity}
          errorMessage={columnErrors.quantity}
          name="quantity"
          label="Quantity"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.unit_price}
          errorMessage={columnErrors.unit_price}
          name="unit_price"
          label="Supplier Unit Cost"
          className="medium-5"
          onChange={updateColumnMapping}
        />
        <ReconcileSelect
          columns={columns}
          value={columnsToDataMap.item_discount_amount}
          errorMessage={columnErrors.item_discount_amount}
          name="item_discount_amount"
          label="Item Discount"
          className="medium-7 end"
          onChange={updateColumnMapping}
        />
      </Row>
      <Row>
        <div className="medium-12 columns">
          <hr />
        </div>
      </Row>
    </BaseReconcileForm>
  )
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
