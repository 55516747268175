import PropTypes from 'prop-types'

import Currency from '../../../common/components/Currency.js'
import {showCreditCardModal} from '../../Modals/CreditCardModal.js'
import {showInsuranceModal} from '../../OrderListPage/Modals/InsuranceModal.js'
import {
  insuranceCostSelector,
  includeInsuranceSelector,
  insuredValueSelector,
  canHaveInsuranceSelector,
  labelShipperTypeSelector,
} from '../../../data/labelInfos/index.js'
import {getState, useSelector} from '../../../store.js'
import {hasCreditCardSelector} from '../../../data/company.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

async function onAddInsurance(labelInfoID, shipperType) {
  let hasCreditCard = hasCreditCardSelector(getState())

  if (!hasCreditCard) {
    hasCreditCard = await showCreditCardModal()
  }

  if (hasCreditCard) {
    showInsuranceModal(labelInfoID, shipperType)
  }
}

export default function InsuranceNotice({showInsuranceFull = true}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const insuranceCost = useSelector((state) =>
    insuranceCostSelector(state, {labelInfoID, shipperType}),
  )
  const insuredValue = useSelector((state) =>
    insuredValueSelector(state, {labelInfoID}),
  )
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {labelInfoID}),
  )
  const canHaveInsurance = useSelector((state) =>
    canHaveInsuranceSelector(state, {labelInfoID, shipperType}),
  )
  const hasCreditCard = useSelector(hasCreditCardSelector)

  if (!canHaveInsurance) {
    return null
  }

  if (!hasCreditCard) {
    return null
  }

  if (!includeInsurance) {
    return (
      <div className="alert alert--sm alert--warning align-center margin-bottom-10">
        <span
          className="i-shield text--warning fs-00 lh-sm inline-block v-align-middle margin-right-3"
          aria-hidden="true"
        />
        <button
          type="button"
          className="btn btn--link darker fs-n1 meta-labelconfigform-button-insurance-not-set"
          onClick={() => onAddInsurance(labelInfoID, shipperType)}
        >
          <strong>Add third-party insurance?</strong>
          {showInsuranceFull ||
            (insuredValue > 0 && (
              <>
                {' '}
                <span>
                  (<Currency value={insuredValue} currencySymbol="$" /> value)
                </span>
              </>
            ))}
        </button>
      </div>
    )
  }

  return (
    <div className="alert alert--sm alert--success align-center margin-bottom-10">
      <span
        className="i-shield-check text--green fs-00 lh-sm inline-block v-align-middle margin-right-3"
        aria-hidden="true"
      />
      <button
        type="button"
        className="btn btn--link darker fs-n1 meta-labelconfigform-button-insurance-set"
        onClick={() => onAddInsurance(labelInfoID, shipperType)}
      >
        <strong>
          Insured for <Currency value={insuranceCost} currencySymbol="$" />
        </strong>{' '}
        <span>
          (<Currency value={insuredValue} currencySymbol="$" /> of coverage)
        </span>
      </button>
    </div>
  )
}

InsuranceNotice.propTypes = {
  showInsuranceFull: PropTypes.bool,
}
