import PropTypes from 'prop-types'

import {LabelInfoIDShape} from '../../../../../../common/PropTypes.js'
import {showMultiboxModal} from '../../../../../OrderListPage/Modals/MultiboxModal/index.js'
import CompactDimensions from './Fields/CompactDimensions.js'
import CompactWeight from './Fields/CompactWeight.js'
import CompactRateDetails from './Fields/CompactRateDetails.js'
import LabelConfigContext from '../../../../../LabelConfig/LabelConfigContext.js'
import {getState} from '../../../../../../store.js'
import {labelShipperTypeSelector} from '../../../../../../data/labelInfos/index.js'

export default function AwaitingFulfillmentSummary({
  labelInfoID,
  packageCount,
}) {
  if (!labelInfoID) {
    return null
  }

  return (
    <LabelConfigContext.Provider value={{labelInfoID}}>
      <div>
        {packageCount == 1 && (
          <span>
            <span className="fs-n1 margin-right-5">
              <CompactDimensions />
            </span>
            <span className="fs-n1 op-50 margin-right-5">•</span>
            <span className="fs-n1">
              <CompactWeight />
            </span>
          </span>
        )}
        {packageCount > 1 && (
          <button
            className="btn btn--link fs-n1 v-align-base margin-bottom-1"
            type="button"
            onClick={() => {
              const shipperType = labelShipperTypeSelector(getState(), {
                labelInfoID,
              })
              showMultiboxModal(labelInfoID, shipperType)
            }}
          >
            <em>Multiple pkgs ({packageCount})</em>
          </button>
        )}
        <CompactRateDetails />
      </div>
    </LabelConfigContext.Provider>
  )
}

AwaitingFulfillmentSummary.propTypes = {
  labelInfoID: LabelInfoIDShape,
  packageCount: PropTypes.number.isRequired,
}
