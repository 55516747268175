import {NEW_ID} from '../../../../../../common/constants/index.js'

import {isPresent} from '../../../../../../common/utils.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.accountNumber)) {
    errors.accountNumber = 'Account number is required'
  }

  if (!isPresent(shipper.apiKey)) {
    errors.apiKey = 'API Key is required'
  }

  if (!isPresent(shipper.apiSecret)) {
    errors.apiSecret = 'API Secret is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name,
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    accountNumber: vendorConfig.account_number || '',
    apiKey: vendorConfig.api_key || '',
    apiSecret: vendorConfig.api_secret || '',
    printAsYouGo: vendorConfig.print_as_you_go || false,
    isSaving: false,
  }
}
