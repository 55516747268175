export const TAG_TYPE__ORDER = 'TAG_TYPE__ORDER'
export const TAG_TYPE__RETURN_ORDER = 'TAG_TYPE__RETURN_ORDER'
export const TAG_TYPE__PRODUCT = 'TAG_TYPE__PRODUCT'
export const TAG_TYPE__MO = 'TAG_TYPE__MO'
export const TAG_TYPE__PO = 'TAG_TYPE__PO'

export const TAG_FILTER_OR = 'or'
export const TAG_FILTER_AND = 'and'
export const TAG_FILTER_ONLY = 'only'
export const TAG_FILTER_NOT = 'not'

export const TAG_FILTER_OPTIONS = [
  {value: TAG_FILTER_OR, display: 'Or'},
  {value: TAG_FILTER_AND, display: 'And'},
  {value: TAG_FILTER_ONLY, display: 'Only'},
  {value: TAG_FILTER_NOT, display: 'Not'},
]
