export const KIT_VIEW_PARENTS = 'KIT_VIEW_PARENTS'
export const KIT_VIEW_BOTH = 'KIT_VIEW_BOTH'
export const KIT_VIEW_COMPONENTS = 'KIT_VIEW_COMPONENTS'

export const STANDARD_LAYOUT = 'standard'

export const ABODE_FORM_DEFAULTS = {
  showLogoOnLabel: true,
  showOrderTray: false,
  labelPosition: '',
  showPackingList: false,
  packingListSort: '',
  showPrice: true,
  showBarcode: true,
  showPackingListImages: false,
  packingListKitViewMode: KIT_VIEW_PARENTS,
  showSiblingOrderQuantities: false,
  showZeroQuantityLines: false,
  highlight_pack_line_qty: false,
  showShippingInfo: false,
  showWarehouseLocation: false,
  showPickList: false,
  showBillTo: false,
  showPackSerialNumbers: false,
  show_packing_list_details: true,
  show_pack_sku_barcode: false,
  showCustomerNotes: true,
  pickListSort: 'sku',
  showPickListImages: false,
  showPickListTotalItems: false,
  showPickListTotalSKUs: false,
  showPickListTotalOrders: false,
  highlight_pick_line_qty: false,
  show_pick_list_poh: false,
  show_pick_sku_barcode: false,
  showPickSerialNumbers: false,
  pickListKitViewMode: KIT_VIEW_PARENTS,
  templateID: 0,
  showFullPage: false,
  breakPageOnOrder: false,
  useCustomLayoutSettings: false,
  labelPrinterID: '',
  additionalDocsPrinterID: '',
  canadaPostDocsPrinterID: '',
  packingListPrinterID: '',
  addressPrinterID: '',
  barcodePrinterID: '',
  printConfigID: STANDARD_LAYOUT,
  size: 'desktop',
}
