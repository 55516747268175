import {useEffect} from 'react'
import classNames from 'classnames'

import {showCustomsModal} from '../../OrderListPage/Modals/CustomsModal/index.js'
import {
  updateLabelConfig,
  attachCustomsInfoSelector,
  hasCustomsErrorsSelector,
  hasCustomsWarningSelector,
  customsInfoSelector,
  labelConfigSelector,
  onLabelPropertyValueChange,
} from '../../../data/labelInfos/index.js'
import {getState, useSelector} from '../../../store.js'
import EnableLabelProperty from '../Fields/EnableLabelProperty.js'
import {formatWeight} from '../../../common/weight.js'
import Currency from '../../../common/components/Currency.js'
import {
  AUSTRALIA_POST,
  CANADA_POST,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_CBDS,
  PITNEY_MERCHANT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
} from '../../../common/constants/ShipperNames.js'
import useLabelProperty, {
  EMPTY_DEPENDANT_PROPERTIES_FUNC,
} from '../Fields/useLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export const LABEL_PROPERTY_ATTACH_CUSTOMS_INFO = 'attach_customs_info'
export const LABEL_PROPERTY_CUSTOMS_INFO = 'customs_info'
const VALID_CUSTOMS_INFO_LABEL_PROPERTIES = [LABEL_PROPERTY_CUSTOMS_INFO]
const VALID_SHIPPER_TYPES = [
  AUSTRALIA_POST,
  CANADA_POST,
  DHL_ECOMMERCE,
  DHL,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY_CBDS,
  PITNEY,
  PITNEY_MERCHANT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
]

function customsInfoLabelPropertiesFunc(shipperType, labelInfoID) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  return VALID_SHIPPER_TYPES.includes(shipperType) &&
    labelConfig.attach_customs_info
    ? [LABEL_PROPERTY_CUSTOMS_INFO]
    : []
}

function customsInfoDependantPropertiesFunc() {
  return [LABEL_PROPERTY_ATTACH_CUSTOMS_INFO]
}

export default function AttachCustomsInfo() {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()
  const attachCustomsInfo = useSelector(
    (state) => attachCustomsInfoSelector(state, {labelInfoID}) || false,
  )
  const customsInfo = useSelector((state) =>
    customsInfoSelector(state, {labelInfoID}),
  )
  const hasCustomsErrors = useSelector((state) =>
    hasCustomsErrorsSelector(state, {labelInfoID, shipperType}),
  )
  const hasCustomsWarnings = useSelector((state) =>
    hasCustomsWarningSelector(state, {labelInfoID, shipperType}),
  )

  // attach customs info
  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_ATTACH_CUSTOMS_INFO,
    validShipperTypes: VALID_SHIPPER_TYPES,
    dependantPropertiesFunc: EMPTY_DEPENDANT_PROPERTIES_FUNC,
  })

  // customs info
  useLabelProperty({
    labelProperty: LABEL_PROPERTY_CUSTOMS_INFO,
    validLabelProperties: VALID_CUSTOMS_INFO_LABEL_PROPERTIES,
    validShipperTypes: VALID_SHIPPER_TYPES,
    labelPropertiesFunc: customsInfoLabelPropertiesFunc,
    dependantPropertiesFunc: customsInfoDependantPropertiesFunc,
  })

  useEffect(() => {
    onLabelPropertyValueChange(
      labelInfoID,
      shipperType,
      LABEL_PROPERTY_ATTACH_CUSTOMS_INFO,
    )
  }, [attachCustomsInfo])

  const hide = !VALID_SHIPPER_TYPES.includes(shipperType)

  if (hide) {
    return null
  }

  return (
    <>
      <li
        className={classNames('list__item--shipping-options margin-bottom-15', {
          error: hasCustomsErrors,
          warning: hasCustomsWarnings && !hasCustomsErrors,
        })}
      >
        <div className="wrap--edit-preset-form-input">
          <label className="inline-block margin-right-5 margin-bottom-0">
            <input
              type="checkbox"
              className="margin-right-5 margin-bottom-0 v-align-middle"
              checked={attachCustomsInfo}
              onChange={(event) =>
                updateLabelConfig(labelInfoID, {
                  attach_customs_info: event.target.checked,
                })
              }
            />
            <span className="v-align-middle">Attach Customs Info</span>
          </label>
          {attachCustomsInfo && (
            <button
              className={classNames(
                'btn btn--link inline-block fs-n1 v-align-base',
                {error: hasCustomsErrors},
              )}
              type="button"
              onClick={() => showCustomsModal(labelInfoID, shipperType)}
            >
              Edit
            </button>
          )}
        </div>
        <EnableLabelProperty
          labelProperty={LABEL_PROPERTY_ATTACH_CUSTOMS_INFO}
        />
      </li>
      {isLabelEnableMode && attachCustomsInfo && isLabelPropertyEnabled && (
        <li className="list__item--shipping-options wrap--edit-preset-form-flex-item">
          <div className="wrap--edit-preset-form-input">
            <label className="margin-bottom-0">
              <span className="fs-n0">Customs Info</span>
            </label>
            <ul className="list list--square">
              {customsInfo.map(
                (
                  {description, quantity, weightOZ, weightLB, value, country},
                  index,
                ) => (
                  <li
                    className="list__item fs-n1 lh-sm margin-bottom-5"
                    key={index}
                  >
                    {description} ({quantity} qty,{' '}
                    {formatWeight({lb: weightLB, oz: weightOZ})},{' '}
                    <Currency value={value} />, {country})
                  </li>
                ),
              )}
            </ul>
          </div>
          <EnableLabelProperty labelProperty={LABEL_PROPERTY_CUSTOMS_INFO} />
        </li>
      )}
    </>
  )
}
