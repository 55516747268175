import PropTypes from 'prop-types'
import classNames from 'classnames'

import Helper from '../Common/Helper.js'
import Link from '../../../common/components/Link/Link.js'

export default function AuthorizeHelper({className, vendorName, href}) {
  return (
    <Helper
      title={`Need help authorizing your ${vendorName} account?`}
      className={classNames(className, 'margin-bottom-10')}
    >
      <Link className="mid" href={href} newWindow>
        Get step-by-step instructions →
      </Link>
    </Helper>
  )
}

AuthorizeHelper.propTypes = {
  className: PropTypes.string,
  vendorName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}
