import classNames from 'classnames'

import {useSelector} from '../../../store.js'
import {
  WAREHOUSE_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import {maxWarehouseCountSelector} from '../../../data/company.js'
import Form from './Form.js'
import {
  warehousesFormSelector,
  errorsSelector,
  saveWarehouse,
} from './warehousesFunctions.js'
import {canHaveMoreWarehousesSelector} from '../../../data/warehouses.js'
import {Count, Plural, PluralBlock} from '../../../common/components/Plural.js'

export default function NewForm() {
  const form = useSelector(warehousesFormSelector)
  const errors = useSelector(errorsSelector)
  const maxWarehouseCount = useSelector(maxWarehouseCountSelector)
  const canHaveMoreWarehouses = useSelector(canHaveMoreWarehousesSelector)

  const isNew = NEW_ID === form.id

  return (
    <li className="settings-list-item add-item clearfix expanded">
      <div className="medium-12 columns button-wrap">
        {canHaveMoreWarehouses ? (
          <p>
            <a
              href={`${WAREHOUSE_SETTINGS_LINK}/${NEW_ID}`}
              title="Add a warehouse"
              className="inline-text-button no-underline"
            >
              <span
                className="icon icon-add icon-block x-sm margin-right-5"
                aria-hidden="true"
              />
              <span>Add a warehouse</span>
            </a>
          </p>
        ) : (
          <div className="margin-top-15 align-center">
            <p className="margin-bottom-10">
              <strong>
                <PluralBlock count={maxWarehouseCount}>
                  Want to add more than <Count options={{toEnglish: true}} />{' '}
                  <Plural word="warehouse" />?
                </PluralBlock>
              </strong>
            </p>
            <button
              className="btn btn--primary btn--primary-alt btn--md fs-01"
              onClick={() => showUpgradeModal()}
            >
              <strong>Upgrade Your Plan</strong>
            </button>
          </div>
        )}
      </div>
      {isNew && canHaveMoreWarehouses && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {errors.serverError && (
              <div className="wrap--row margin-bottom-25">
                <div className="medium-7 columns">
                  <div className="alert alert--error full-border error-message">
                    <strong className="fs-00">{errors.serverError}</strong>
                  </div>
                </div>
              </div>
            )}
            <Form />
            <div className="clear-left">
              <div className="medium-5 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  disabled={errors.preventSave || form.isSaving}
                  onClick={() => saveWarehouse()}
                >
                  Save
                </button>
                <a
                  href={WAREHOUSE_SETTINGS_LINK}
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </li>
  )
}
