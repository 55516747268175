import {isEqual} from 'lodash'
import PropTypes from 'prop-types'
import {
  DragAndDropList,
  Draggable,
  DragHandle,
  MOVE_AFTER,
  MOVE_BEFORE,
} from '../../../common/components/DragAndDrop.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {saveUserSettings, updateUserSettings} from '../../../data/me.js'

import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
} from '../../../store.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  poListFilterOrderSelector,
  STATUS_FILTER_PANEL,
  SUPPLIER_FILTER_PANEL,
  TAG_FILTER,
  WAREHOUSE_FILTER_PANEL,
} from './POListFilterModal/poListFilterModalFunctions.js'

const MODAL_FORM = 'PO_LIST_FILTER_SETTINGS_MODAL'

export function showPOListFilterSettingsModal() {
  const list = poListFilterOrderSelector(getState())

  setForm(MODAL_FORM, {
    list: [...list],
    serverError: null,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

async function onFilterMove(filter, nearFilter, where) {
  const {list: originalList} = modalFormSelector(getState())

  // get sorted items and exclude moving item
  const list = originalList.filter((item) => item !== filter)

  // get index of near item
  const nearIndex = list.indexOf(nearFilter)

  // put moving item in it's place
  if (where === MOVE_BEFORE) {
    list.splice(nearIndex, 0, filter)
  } else if (where === MOVE_AFTER) {
    list.splice(nearIndex + 1, 0, filter)
  }

  if (!isEqual(list, originalList)) {
    updateModalForm({list})
  }
}

async function applyList() {
  try {
    const originalList = poListFilterOrderSelector(getState())
    const {list} = modalFormSelector(getState())

    if (!isEqual(list, originalList)) {
      updateModalForm({serverError: null, isSaving: true})

      updateUserSettings({po_list_filter_order: list})

      await saveUserSettings()
    }

    showMessageToast('Filter settings were updated.')

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

const FILTER_LIST = {
  [STATUS_FILTER_PANEL]: 'Status',
  [TAG_FILTER]: 'Tags',
  [WAREHOUSE_FILTER_PANEL]: 'Warehouses',
  [SUPPLIER_FILTER_PANEL]: 'Suppliers',
}

function POFilterSettingsModal({form}) {
  return (
    <ConfirmModal
      title="Purchase Order Filters Settings"
      modalSize="sm"
      onConfirm={() => applyList()}
      onCancel={() => closeModal()}
      confirmText="Apply"
      cancelText="Cancel"
      error={form.serverError}
      isSaving={form.isSaving}
    >
      <DragAndDropList onMove={onFilterMove}>
        <>
          <p className="fs-01 lh-md margin-bottom-25">
            <strong className="inline-block v-align-middle">
              Reorder your filters by dragging the{' '}
              <span className="inline-block margin-left-3 margin-right-3 v-align-middle op-30">
                <span className="i-drag-handle block fs-00 lh-sm" />
                <span className="i-drag-handle block fs-00 lh-sm" />
              </span>{' '}
              icon.
            </strong>
          </p>
          <div className="wrap--modal-draggable-items">
            {form.list.map((item, index) => (
              <Draggable
                className="flex wrap--draggable-item"
                key={item}
                id={item}
                uiIndex={index}
              >
                <DragHandle className="padding-top-10 padding-bottom-10 border-top--light" />
                <div className="fs-01 flex--justify-col border-top--light padding-top-10 padding-bottom-10 flex-grow">
                  {FILTER_LIST[item]}
                </div>
              </Draggable>
            ))}
          </div>
        </>
      </DragAndDropList>
    </ConfirmModal>
  )
}

POFilterSettingsModal.propTypes = {
  form: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(POFilterSettingsModal, modalFormSelector)
