import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {FEDEX, UPS} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'certificate_origin'
const VALID_SHIPPER_TYPES = [FEDEX, UPS]

export default function CertificateOrigin() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigCheckbox
      label="Certificate Origin"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
