import PropTypes from 'prop-types'
import {useEffect} from 'react'

import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'
import {useSelector} from '../../../../store.js'

export default function SupplierSelect({onChange, selectedSupplierID}) {
  const suppliers = useSelector(suppliersSortedByNameSelector)

  useEffect(() => {
    if (!selectedSupplierID && suppliers.length) {
      onChange(suppliers[0].id)
    }
  }, [selectedSupplierID, suppliers])

  return (
    <select
      className="select"
      id="supplier_select"
      value={selectedSupplierID || ''}
      onChange={(event) => onChange(Number(event.target.value))}
    >
      {suppliers.map((supplier) => (
        <option value={supplier.id} key={supplier.id}>
          {getSupplierName(supplier)}
        </option>
      ))}
    </select>
  )
}

SupplierSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedSupplierID: PropTypes.number,
}
