import PropTypes from 'prop-types'

export default function Select({
  children,
  errorMessage,
  label,
  name = 'unnamed_select',
  required,
  className,
  tall,
  width,
  ...props
}) {
  className = className || ''
  tall = tall === false ? false : true
  width = width || 50

  return (
    <>
      {label && (
        <label className="fs-00 margin-bottom-3" htmlFor={`id_${name}`}>
          <span>{label}</span>
          {required && <span className="required">*</span>}
        </label>
      )}
      <select
        className={`select ${
          tall ? 'select--tall' : ''
        } w-${width} ${className}`}
        id={`id_${name}`}
        {...props}
      >
        {children}
      </select>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  tall: PropTypes.bool,
  width: PropTypes.number,
}
