import PropTypes from 'prop-types'
import {useState, useCallback} from 'react'
import sortBy from 'lodash/sortBy.js'
import findIndex from 'lodash/findIndex.js'

import {RuleShape} from '../../../common/PropTypes.js'
import {ruleSelector, toggleActive} from '../../../data/rules.js'

import DisplayRule from './DisplayRule.js'
import EditingRule from './EditingRule.js'
import {
  formsSelector,
  getState,
  onlyIfAutoForm,
  updateForm,
} from '../../../store.js'
import {
  debouncedRuleCommit,
  getRuleFormName,
  ruleFormSelector,
  updateRuleForm,
  updateSettingsRulesForm,
  getUIRuleFromDataRule,
} from './rulesFunctions.js'
import {showDeleteRulesModal} from '../Modals/DeleteRulesModal.js'
import {
  Draggable,
  DragHandle,
  MOVE_AFTER,
  MOVE_BEFORE,
} from '../../../common/components/DragAndDrop.js'

export function setupRule({ruleID}) {
  return {
    formName: getRuleFormName(ruleID),
    initialForm: getUIRuleFromDataRule(ruleSelector(getState(), {ruleID})),
  }
}

function allRuleFormsSelector(state) {
  return Object.entries(formsSelector(state)).reduce((prev, [key, form]) => {
    if (key.match(/^SETTINGS_RULES_\d+$/)) {
      prev.push(form)
    }
    return prev
  }, [])
}

export async function moveRule(ruleID, nearRuleID, where) {
  const movingRule = ruleFormSelector(getState(), {ruleID})
  const allRuleForms = allRuleFormsSelector(getState())

  // sort rules by index and exclude moving rule
  const sortedRules = sortBy(allRuleForms, 'index').filter(
    (rule) => rule.id !== ruleID,
  )

  // get real index of near rule (not rule.index, which can be anything)
  const nearRuleSortIndex = findIndex(
    sortedRules,
    (rule) => rule.id === nearRuleID,
  )

  // put moving rule in it's place
  if (where === MOVE_BEFORE) {
    sortedRules.splice(nearRuleSortIndex, 0, movingRule)
  } else if (where === MOVE_AFTER) {
    sortedRules.splice(nearRuleSortIndex + 1, 0, movingRule)
  }

  // get a list of all indexes sorted
  // we want to reuse existing index values
  // they might be sparse due to "deleted" rules
  const indices = allRuleForms.map((rule) => rule.index).sort((a, b) => a - b)

  // update each rule form with the existing index value that matches order
  sortedRules.forEach((rule, i) => {
    const newIndex = indices[i]

    updateRuleForm(rule.id, {index: newIndex})
  })

  // get all the forms again and recreate the indices array
  updateSettingsRulesForm({
    indices: sortBy(allRuleFormsSelector(getState()), 'index').map(
      ({id, index}) => ({id, index}),
    ),
  })

  // inform function to eventually commit new indices
  await debouncedRuleCommit()
}

export function Rule({formName, form, uiIndex}) {
  const [beforeEditing, setBeforeEditing] = useState(false)

  const editRule = useCallback(() => {
    setBeforeEditing({...form})
    updateForm(formName, {isEditing: true})
  }, [form])

  return (
    <Draggable className="wrap--rule" id={form.id} uiIndex={uiIndex}>
      <div className="row flex">
        <DragHandle className="columns small-screen-hide" />
        {!form.isEditing && <DisplayRule rule={form} editRule={editRule} />}

        {form.isEditing && (
          <EditingRule
            rule={form}
            onCancel={() => {
              updateForm(formName, {
                ...beforeEditing,
                index: form.index,
                isEditing: false,
              })
            }}
          />
        )}

        <div className="medium-2 small-2 columns right-aligned-text right">
          <ul className="no-list-style margin-top-5 margin-left-0">
            <li>
              <div className="switch small round switch-with-labels on-off centered-text clearfix margin-bottom-0 meta-rule-toggle-active">
                <input
                  id={`toggle-${form.id}`}
                  className="margin-bottom-0"
                  type="checkbox"
                  checked={form.active}
                  readOnly
                />
                <label
                  role="presentation"
                  htmlFor={`toggle-${form.id}`}
                  className="float-right margin-bottom-0"
                  onClick={async () =>
                    updateForm(formName, {active: await toggleActive(form.id)})
                  }
                />
              </div>
            </li>
            {!form.isEditing && (
              <li className="margin-right-5">
                <button
                  className="inline-text-button settings-list-item-button margin-right-5 meta-rule-edit"
                  title="Edit"
                  onClick={() => editRule()}
                >
                  <span className="i-pencil fs-01" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="inline-text-button settings-list-item-button meta-rule-delete"
                  title="Delete"
                  onClick={() => showDeleteRulesModal([form.id])}
                >
                  <span className="i-trash fs-01" aria-hidden="true" />
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Draggable>
  )
}

Rule.propTypes = {
  formName: PropTypes.string.isRequired,
  form: RuleShape.isRequired,
  uiIndex: PropTypes.number.isRequired,
}

export default onlyIfAutoForm(Rule, setupRule)
