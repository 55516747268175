import PropTypes from 'prop-types'
import {useEffect} from 'react'
import get from 'lodash/get.js'
import omit from 'lodash/omit.js'
import pick from 'lodash/pick.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {parseNonZeroPositiveNumber} from '../../../common/parseNumbers.js'
import {SelectOptionValue} from '../../../common/PropTypes.js'
import {formatAbodeURL} from '../../../common/abode.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {MultipleAddressLayouts} from '../../../common/constants/AddressLabelLayouts.js'
import ButtonSendToPrinter from '../../../common/components/Button/ButtonSendToPrinter.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import Checkbox from '../../../common/components/Form/Checkbox.js'
import Select from '../../../common/components/Form/Select.js'
import {
  warehouseNameSelector,
  warehouseAddressSelector,
} from '../../../data/warehouses.js'
import {
  sortedPackingListsByIDSelector,
  defaultPackingListSelector,
  packingListLogoURLSelector,
} from '../../../redux/selectors/data/packingLists.js'
import {addressPrinterIDSelector} from '../../../data/printerSettings.js'
import {
  LAYOUT_ADDRESS,
  layoutsByTypeSortedByName,
} from '../../../data/layout.js'

const MODAL_FORM = 'WAREHOUSE_ADDRESS_LABEL_MODAL'

export function showWarehouseAddressLabelModal(warehouseID) {
  const packingLists = sortedPackingListsByIDSelector(getState())
  const defaultPackingList = defaultPackingListSelector(getState())
  const packingListID =
    get(defaultPackingList, 'id') || get(packingLists, [0, 'id']) || null

  setForm(MODAL_FORM, {
    warehouseID,
    layoutID: null,
    layout: MultipleAddressLayouts[0].value,
    includeCountryCode: false,
    includeCompanyLogo: !!packingListID,
    packingListID,
    pageCount: '1',
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function addressPreviewURLSelector(state) {
  const {
    warehouseID,
    layoutID,
    layout,
    includeCountryCode,
    includeCompanyLogo,
    packingListID,
    ...form
  } = modalFormSelector(state)

  const pageCount = parseNonZeroPositiveNumber(form.pageCount) || 1
  const {labelsPerPage} = MultipleAddressLayouts.find(
    ({value}) => layout === value,
  )

  let address = warehouseAddressSelector(state, {warehouseID})

  if (address.country && !includeCountryCode) {
    address = omit(address, 'country')
  }

  const params = {
    render: JSON.stringify([
      'Address',
      {
        layout,
        copyCount: pageCount * labelsPerPage,
        addressData: [
          pick(address, [
            'name',
            'phone',
            'email',
            'street1',
            'street2',
            'city',
            'state',
            'zip',
            'country',
          ]),
        ],
        ...(includeCompanyLogo && packingListID
          ? {logoLink: packingListLogoURLSelector(state, {packingListID})}
          : null),
      },
    ]),
  }

  if (layoutID) {
    params.layout_id = layoutID
  }

  return formatAbodeURL('/render', params)
}

function updateLayoutID(layoutID) {
  updateModalForm(
    {layoutID: Number(layoutID) || null},
    {stickyProps: ['layoutID']},
  )
}

function WarehouseAddressLabelModal({form}) {
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID: form.warehouseID}),
  )
  const packingLists = useSelector(sortedPackingListsByIDSelector)
  const addressPreviewURL = useSelector(addressPreviewURLSelector)
  const addressPrinterID = useSelector(addressPrinterIDSelector)
  const addressLayouts =
    useSelector(layoutsByTypeSortedByName)[LAYOUT_ADDRESS] || []

  useEffect(() => {
    if (form.layoutID && !addressLayouts.find(({id}) => id === form.layoutID)) {
      updateLayoutID(null)
    }
  }, [form.layoutID])

  return (
    <ConfirmModal
      title="Print Address Labels"
      modalSize="sm"
      onConfirm={() => {
        window.open(addressPreviewURL, '_blank')

        closeModal()
      }}
      onCancel={() => closeModal()}
      confirmText="View/Print"
      cancelText="Close"
      MiddleButtons={() => (
        <div className="margin-right-10">
          <ButtonSendToPrinter
            className="margin-bottom-5"
            title="Address Label"
            pdfLink={addressPreviewURL}
            printerID={addressPrinterID}
            onClick={() => closeModal()}
          />
        </div>
      )}
    >
      <dl className="list">
        <dt className="list__title--label-success lh-md margin-top-0 margin-bottom-15">
          For {warehouseName}
        </dt>
        <dd className="list__item">
          <Select
            label="Size"
            name="layout"
            value={form.layout}
            onChange={(value) => {
              updateModalForm({layout: value}, {stickyProps: ['layout']})
            }}
            className="margin-bottom-10"
            selectClassName="w-75"
          >
            {MultipleAddressLayouts.map((layout) => (
              <option key={layout.value} value={layout.value}>
                {layout.display}
              </option>
            ))}
          </Select>
        </dd>
        {addressLayouts.length > 0 && (
          <dd className="list__item">
            <Select
              label="Layout"
              id="layout_id"
              value={form.layoutID || ''}
              onChange={updateLayoutID}
            >
              <option value="">Standard</option>
              {addressLayouts.map((layout) => (
                <option key={layout.id} value={layout.id}>
                  {layout.name}
                </option>
              ))}
            </Select>
          </dd>
        )}
        <dd className="list__item margin-bottom-15">
          <label htmlFor="copy_count">No. of Copies</label>
          <NumberInput
            id="copy_count"
            value={form.pageCount}
            onChange={(value) =>
              updateModalForm({
                pageCount: `${value}`,
              })
            }
            min={1}
          />
        </dd>
        {packingLists.length > 0 && (
          <dd className="list__item margin-bottom-15">
            <>
              <Checkbox
                label="Include Company Logo"
                name="include-company-logo"
                checked={form.includeCompanyLogo}
                onChange={(checked) =>
                  updateModalForm({
                    includeCompanyLogo: checked,
                  })
                }
              />
              {form.packingListID &&
                form.includeCompanyLogo &&
                packingLists.length > 1 && (
                  <Select
                    label="Template"
                    name="layout"
                    value={form.packingListID}
                    onChange={(value) =>
                      updateModalForm({
                        packingListID: value,
                      })
                    }
                    className="margin-bottom-10"
                    selectClassName="w-75"
                  >
                    {packingLists.map((packingList) => (
                      <option key={packingList.id} value={packingList.id}>
                        {packingList.name}
                      </option>
                    ))}
                  </Select>
                )}
            </>
          </dd>
        )}
        <dd className="list__item">
          <Checkbox
            label="Include Country Code"
            name="include-country-code"
            checked={form.includeCountryCode}
            onChange={(checked) =>
              updateModalForm({
                includeCountryCode: checked,
              })
            }
          />
        </dd>
      </dl>
    </ConfirmModal>
  )
}

WarehouseAddressLabelModal.propTypes = {
  form: PropTypes.shape({
    warehouseID: PropTypes.number.isRequired,
    layoutID: PropTypes.number,
    includeCountryCode: PropTypes.bool.isRequired,
    pageCount: PropTypes.string.isRequired,
    layout: PropTypes.string.isRequired,
    includeCompanyLogo: PropTypes.bool.isRequired,
    packingListID: SelectOptionValue,
  }).isRequired,
}

export default onlyIfForm(WarehouseAddressLabelModal, modalFormSelector)
