import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, put, select} from 'redux-saga/effects'

import api from '../../../common/api.js'
import formConnect from '../../../common/formConnect.js'
import {getDate} from '../../../common/date.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {setPO} from '../../../data/pos.js'

export const MODAL_FORM = 'MARK_AS_SENT_MODAL_FORM'
export const MARK_AS_SENT = 'MARK_AS_SENT'

export function showMarkAsSentModal(poIDs) {
  return setForm(MODAL_FORM, {
    poIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function markAsSent() {
  return {
    type: MARK_AS_SENT,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* markPO(id) {
  const date = yield call(getDate)
  const params = {
    sent: date,
  }

  const {json} = yield call(
    api.put,
    `/purchase_order/${encodeURIComponent(id)}`,
    params,
  )

  yield call(setPO, json)
}

export function* markAsSentWorker() {
  try {
    yield put(updateModalForm({isSaving: true, serverError: null}))

    const {poIDs} = yield select(modalFormSelector)
    const poCount = poIDs.length

    yield all(poIDs.map((id) => call(markPO, id)))

    yield call(
      showMessageToast,
      `${poCount} PO${poCount !== 1 ? 's were' : ' was'} marked as sent`,
    )

    yield put(closeModal())

    yield call(refreshPurchaseOrderList)
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error marking PO as sent: ${
          err.message || err.error_message
        }`,
        isSaving: false,
      }),
    )
  }
}

function MarkAsSentModal({form, ...props}) {
  if (!form) {
    return null
  }

  const poCount = form.poIDs.length

  return (
    <ConfirmModal
      title="Mark Purchase Order as Sent"
      onConfirm={() => props.markAsSent()}
      onCancel={() => props.closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      Mark {poCount} PO{poCount !== 1 ? 's' : ''} as sent?
    </ConfirmModal>
  )
}

MarkAsSentModal.propTypes = {
  form: PropTypes.shape({
    poIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  markAsSent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    form: modalFormSelector(state),
  }
}

const mapDispatchToProps = {
  markAsSent,
  updateModalForm,
  closeModal,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(MarkAsSentModal),
  modalFormSelector,
)
