import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import {
  authorize,
  saveShipperAmazonShipper,
} from '../../../../redux/actions/ui/settings/shippers/forms/amazonShipper.js'
import {hasErrorsSelector} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function AmazonShipperForm({
  form: {id, isSaving},
  hasErrors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap settings-list-item-form-cp clear-both">
      <div className="medium-5 columns">
        {id !== NEW_ID && (
          <fieldset>
            <ul className="form-list">
              <Nickname shipperID={id} />
              <ShippingMethods shipperID={id} />
              <li className="list__item list__item--no-style padding-bottom-20 divider--bottom">
                <ButtonPrimary
                  className="btn--primary-alt"
                  isLoading={isSaving}
                  isDisabled={hasErrors}
                  onClick={() => props.saveShipperAmazonShipper(id)}
                >
                  Update
                </ButtonPrimary>
              </li>
            </ul>
          </fieldset>
        )}
        <fieldset>
          <ul className="form-list">
            <li className="form-list-item helper-li">
              You will be directed to Amazon Login to authorize Ordoro to use
              your Amazon account to print shipping labels.
            </li>
            <li className="form-list-item margin-top-20">
              <ButtonPrimary onClick={() => props.authorize(id)}>
                {id !== NEW_ID ? 'Reauthorize' : 'Authorize'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>

      {id !== NEW_ID && (
        <div className="medium-5 columns">
          <div className="alert margin-bottom-10 alert--standard">
            <Archive shipperID={id} />
          </div>
        </div>
      )}
    </div>
  )
}

AmazonShipperForm.propTypes = {
  form: ShipperFormShape,
  hasErrors: PropTypes.bool.isRequired,
  authorize: PropTypes.func.isRequired,
  saveShipperAmazonShipper: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    hasErrors: hasErrorsSelector(state, {form}),
  }
}

const mapDispatchToProps = {
  authorize,
  saveShipperAmazonShipper,
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonShipperForm)
