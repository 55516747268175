import {
  warehouseOptionsSelector,
  warehouseOptionTokensSelector,
} from '../../../../data/warehouses.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from './FilterPanel.js'
import {
  modalFormSelector,
  updateModalForm,
  WAREHOUSE_FILTER_PANEL,
} from './orderListFilterModalFunctions.js'

export default function WarehouseFilterPanel() {
  const form = useSelector(modalFormSelector)
  const warehouseOptions = useSelector(warehouseOptionsSelector)
  const warehouseOptionTokens = useSelector(warehouseOptionTokensSelector)

  return (
    <FilterPanel
      id={WAREHOUSE_FILTER_PANEL}
      label="Warehouses"
      searchPlaceholder="Search for a warehouse filter..."
      options={warehouseOptions}
      optionTokens={warehouseOptionTokens}
      selected={form.warehouseFilters}
      onSelect={(option) => {
        const warehouseFilters = form.warehouseFilters.filter(
          (id) => id !== option.value,
        )

        if (warehouseFilters.length === form.warehouseFilters.length) {
          warehouseFilters.push(option.value)
        }

        updateModalForm({warehouseFilters})
      }}
    />
  )
}
