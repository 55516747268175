import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function ButtonIcon({
  className,
  iconClassName,
  onClick,
  text = '',
  size = 'x-sm',
}) {
  return (
    <button
      type="button"
      className={classNames(
        'inline-text-button no-underline v-align-middle margin-top-5 margin-left-5',
        className,
      )}
      onClick={onClick}
    >
      <span
        className={classNames('icon icon-block', iconClassName, size, {
          'margin-right-3': !text,
          'margin-right-5': !!text,
        })}
      />
      {text && (
        <span
          className={classNames('v-align-middle', {'fs-00': size === 'xx-sm'})}
        >
          {text}
        </span>
      )}
    </button>
  )
}

ButtonIcon.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.node,
  size: PropTypes.oneOf(['x-sm', 'xx-sm']),
}
