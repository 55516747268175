import PropTypes from 'prop-types'
import {useMemo} from 'react'
import isEqual from 'lodash/isEqual.js'

import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import {showProductQueryFilterModal} from '../../ProductListPage/Modals/ProductListFilterModal/productListFilterModalFunctions.js'
import {productFilterList} from '../../ProductListPage/ProductListOmniBar.js'
import {cartsSortedByNameSelector} from '../../../data/carts.js'
import {shippersSortedByIDSelector} from '../../../data/shippers.js'
import {suppliersSortedByNameSelector} from '../../../data/suppliers.js'
import {warehousesSortedByNameSelector} from '../../../data/warehouses.js'
import {fulfillmentChannelsSelector} from '../../../data/products.js'
import {productTagsSortedByNameSelector} from '../../../data/productTags.js'
import {Count, plural} from '../../../common/components/Plural.js'
import {tagColors} from '../../../common/colors.js'
import Radio from '../../../common/components/Radio.js'

async function editProductListQueryParams({formName}) {
  const form = formSelector(getState(), {formName})

  const productQuery = await showProductQueryFilterModal({
    query: form.productQuery,
    singleRun: form.singleRun,
  })

  if (!isEqual(productQuery, form.productQuery)) {
    updateForm(formName, {
      productQuery,
      queryTotalCount: null,
    })
  }
}

export default function ProductFilters({formName}) {
  const form = useForm(formName)
  const carts = useSelector(cartsSortedByNameSelector)
  const shippers = useSelector(shippersSortedByIDSelector)
  const suppliers = useSelector(suppliersSortedByNameSelector)
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const fulfillmentChannels = useSelector(fulfillmentChannelsSelector)
  const productTags = useSelector(productTagsSortedByNameSelector)

  const filterListByType = useMemo(
    () => [
      ...productFilterList({
        carts,
        suppliers,
        warehouses,
        fulfillmentChannels,
        productTags,
        query: form.productQuery,
      })
        .reduce((prev, filter) => {
          const list = prev.get(filter.type) || []

          list.push(filter)

          prev.set(filter.type, list)

          return prev
        }, new Map())
        .entries(),
    ],
    [carts, shippers, suppliers, warehouses, productTags, form.productQuery],
  )

  const filterLabel =
    form.queryTotalCount === null
      ? 'Export using custom filter set'
      : form.queryTotalCount === 0
        ? 'No products will be exported'
        : plural(form.queryTotalCount)`Export${[' all', '']} ${Count} product${[
            's',
          ]} in the current filter set`

  return (
    <>
      <li className="list__item--form list__item--wrap-order-report-filters divider--bottom margin-bottom-15 padding-bottom-20">
        <div className="flex margin-bottom-10">
          <strong className="fs-n0 lh-md margin-right-10 flex--justify-col">
            Current Product Filters
          </strong>
          <div>
            <ButtonPrimary
              isOutlined
              size="xx-sm"
              className="flex--justify-col"
              onClick={() => editProductListQueryParams({formName})}
            >
              Edit
            </ButtonPrimary>
          </div>
        </div>
        {filterListByType.length === 0 ? (
          <p className="fs-n0 lh-md margin-bottom-0">
            <em>No filters have been applied to the orders.</em>
          </p>
        ) : (
          filterListByType.map(([type, list]) => (
            <div key={type} className="flex flex-wrap margin-bottom-3">
              {list.map(({type, label, color}, index) => (
                <div
                  key={index}
                  style={color ? tagColors(color) : {}}
                  className={`omni-bar_value-type_${type} omni-bar__multi-value omni-bar__multi-value-modal`}
                >
                  <div className="omni-bar__multi-value__label">{label}</div>
                </div>
              ))}
            </div>
          ))
        )}
      </li>
      {form.selectedSKUs.length > 0 && (
        <li className="list__item--form margin-bottom-15">
          <Radio
            mode="fancy"
            label={plural(form.selectedSKUs)`Export ${Count} ${[
              'records that are',
              'record that is',
            ]} currently selected`}
            id="query_selection__selection"
            checked={form.querySelection === 'selection'}
            onChange={() => updateForm(formName, {querySelection: 'selection'})}
          />
        </li>
      )}
      <li className="list__item--form margin-bottom-15">
        {form.selectedSKUs.length > 0 ? (
          <Radio
            mode="fancy"
            label={filterLabel}
            id="query_selection__query"
            checked={form.querySelection === 'query'}
            onChange={() => updateForm(formName, {querySelection: 'query'})}
          />
        ) : (
          <strong className="fs-n0 lh-md">{filterLabel}.</strong>
        )}
      </li>
    </>
  )
}

ProductFilters.propTypes = {
  formName: PropTypes.string.isRequired,
}
