import pick from 'lodash/pick.js'

export function pickAddress(obj) {
  return pick(obj, [
    'name',
    'given_name',
    'family_name',
    'company',
    'street1',
    'street2',
    'city',
    'state',
    'zip',
    'country',
    'phone',
    'email',
    'fax',
    'title',
  ])
}

export class Address {
  name = ''
  company = ''
  street1 = ''
  street2 = ''
  city = ''
  state = ''
  zip = ''
  country = ''
  phone = ''
  email = ''
  fax = ''

  constructor(obj = {}) {
    this.name = obj.name || ''
    this.company = obj.company || ''
    this.street1 = obj.street1 || ''
    this.street2 = obj.street2 || ''
    this.city = obj.city || ''
    this.state = obj.state || ''
    this.zip = obj.zip || ''
    this.country = obj.country || ''
    this.phone = obj.phone || ''
    this.email = obj.email || ''
    this.fax = obj.fax || ''
  }
}
