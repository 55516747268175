import {all, takeEvery} from 'redux-saga/effects'

import CreatePOModal from '../../ProductListPage/Modals/CreatePOModal/index.js'
import GoodsReceiptModal from './GoodsReceiptModal/index.js'
import AddPOCommentModal, {
  ADD_PO_COMMENT,
  addPOCommentWorker,
} from './AddPOCommentModal.js'
import CancelPOModal from './CancelPOModal.js'
import DeleteGoodsReceiptModal, {
  DELETE_GOODS_RECEIPT,
  deleteGoodsReceiptModalWorker,
} from './DeleteGoodsReceiptModal.js'
import EditPOItemModal, {
  SHOW_EDIT_PO_ITEM_MODAL,
  UPDATE_PO_ITEM,
  showEditPOItemWorker,
  updatePOItemWorker,
} from './EditPOItemModal.js'
import GoodsReceiptTextFieldModal from './GoodsReceiptTextFieldModal.js'
import MarkAsSentModal, {
  MARK_AS_SENT,
  markAsSentWorker,
} from './MarkAsSentModal.js'
import POIDModal, {SAVE_PO_ID, savePOIDWorker} from './POIDModal.js'
import PORestockLocationModal, {
  SAVE_PO_RESTOCK_LOCATION,
  savePORestockLocationWorker,
} from './PORestockLocationModal.js'
import POTextFieldModal, {
  SAVE_PO_TEXT_FIELD,
  savePOTextFieldWorker,
} from './POTextFieldModal.js'
import RemovePOItemModal, {
  removePOItemWorker,
  REMOVE_PO_ITEM,
} from './RemovePOItemModal.js'
import SendToSupplierModal from './SendToSupplierModal.js'
import POListFilterModal from './POListFilterModal/index.js'
import POListFilterSettingsModal from './POListFilterSettingsModal.js'

export default function Modals() {
  return (
    <>
      <CreatePOModal />
      <GoodsReceiptModal />
      <AddPOCommentModal />
      <CancelPOModal />
      <DeleteGoodsReceiptModal />
      <EditPOItemModal />
      <GoodsReceiptTextFieldModal />
      <MarkAsSentModal />
      <POIDModal />
      <POListFilterModal />
      <POListFilterSettingsModal />
      <PORestockLocationModal />
      <POTextFieldModal />
      <RemovePOItemModal />
      <SendToSupplierModal />
    </>
  )
}

export function* purchaseOrderListModalSagas() {
  yield all([
    takeEvery(ADD_PO_COMMENT, addPOCommentWorker),
    takeEvery(DELETE_GOODS_RECEIPT, deleteGoodsReceiptModalWorker),
    takeEvery(SHOW_EDIT_PO_ITEM_MODAL, showEditPOItemWorker),
    takeEvery(UPDATE_PO_ITEM, updatePOItemWorker),
    takeEvery(MARK_AS_SENT, markAsSentWorker),
    takeEvery(SAVE_PO_ID, savePOIDWorker),
    takeEvery(SAVE_PO_TEXT_FIELD, savePOTextFieldWorker),
    takeEvery(SAVE_PO_RESTOCK_LOCATION, savePORestockLocationWorker),
    takeEvery(REMOVE_PO_ITEM, removePOItemWorker),
  ])
}
