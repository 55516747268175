import {useEffect} from 'react'

import Select from '../../common/components/Form/Select.js'
import Checkbox from '../../common/components/Form/Checkbox.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  mockAbodeGoToResource,
  mockAbodeUpdateResourceQuery,
  mockAbodeFormSelector,
  querySelector,
  resourceSelector,
  mockAbodeURLSelector,
} from './mockAbodeFunctions.js'
import Modals from './Modals/index.js'
import MockAbodeAddressOptions from './MockAbodeAddressOptions.js'
import MockAbodeBarcodeOptions from './MockAbodeBarcodeOptions.js'
import MockAbodeEmailOptions from './MockAbodeEmailOptions.js'
import MockAbodeLabelOptions from './MockAbodeLabelOptions.js'
import MockAbodeRMALabelOptions from './MockAbodeRMALabelOptions.js'
import MockAbodeMOOptions from './MockAbodeMOOptions.js'
import MockAbodeRenderOptions from './MockAbodeRenderOptions.js'
import {onlyIfForm, useSelector} from '../../store.js'

function MockAbodePage() {
  const query = useSelector(querySelector)
  const resource = useSelector(resourceSelector)
  const mockAbodeURL = useSelector(mockAbodeURLSelector)

  useEffect(() => {
    document.title = 'Mock Abode'
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100vh - 54.2px - 7px)',
      }}
    >
      <Modals />
      <div
        className="margin-left-15 margin-top-15"
        style={{
          width: '300px',
        }}
      >
        <Select
          label="Abode Resource"
          name="mock_abode__resource"
          value={resource}
          onChange={(value) => mockAbodeGoToResource(value)}
          className="margin-bottom-10"
        >
          <option value="label">Label</option>
          <option value="rma_label">RMA Label</option>
          <option value="mo">MFG Orders</option>
          <option value="address">Address</option>
          <option value="barcode">Barcode</option>
          <option value="email">Email</option>
          <option value="render">Render</option>
        </Select>
        {resource === 'label' ? (
          <MockAbodeLabelOptions />
        ) : resource === 'rma_label' ? (
          <MockAbodeRMALabelOptions />
        ) : resource === 'address' ? (
          <MockAbodeAddressOptions />
        ) : resource === 'barcode' ? (
          <MockAbodeBarcodeOptions />
        ) : resource === 'email' ? (
          <MockAbodeEmailOptions />
        ) : resource === 'mo' ? (
          <MockAbodeMOOptions />
        ) : resource === 'render' ? (
          <MockAbodeRenderOptions />
        ) : null}
        <Checkbox
          label="View as HTML"
          name="mock_abode__format"
          checked={
            resource === 'email'
              ? query.format !== 'pdf'
              : query.format === 'html'
          }
          onChange={(checked) =>
            mockAbodeUpdateResourceQuery({
              format: checked ? 'html' : 'pdf',
            })
          }
        />
        <ButtonPrimary
          className="margin-top-20"
          onClick={() => window.open(mockAbodeURL, '_blank')}
        >
          Open In Tab
        </ButtonPrimary>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <iframe
          title="PDF Output"
          style={{
            width: '100%',
            height: '100%',
          }}
          src={mockAbodeURL}
        />
      </div>
    </div>
  )
}

export default onlyIfForm(MockAbodePage, mockAbodeFormSelector)
