import {RETURN_ORDER_PLURAL_URI_COMPONENT} from '../../../common/constants/ReturnOrders.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import LinkedRMAListSidebar from './LinkedRMAListSidebar.js'
import {initiateReturn} from '../../ReturnOrderListPage/Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import {activeOrderNumbersSelector} from '../orderListSelectors.js'
import {useSelector} from '../../../store.js'

export default function ReturnOrderPanel() {
  const orderNumbers = useSelector(activeOrderNumbersSelector)
  return (
    <div className="wrap--returns-btns align-center">
      {orderNumbers.length === 1 ? (
        <>
          <p>To create a return label, initiate a return first.</p>
          <ButtonPrimary
            className="meta-labelconfigform-button-createreturnlabel margin-right-10"
            onClick={() => initiateReturn(orderNumbers[0])}
          >
            Initiate a Return (RMA)
          </ButtonPrimary>
        </>
      ) : (
        <p>
          Return Merchandise Authorizations and return labels can only be
          created one at a time.
        </p>
      )}
      <LinkButton
        mode="primary"
        className="btn--primary-alt btn--md"
        href={`#/${RETURN_ORDER_PLURAL_URI_COMPONENT}`}
      >
        View All RMAs
      </LinkButton>
      <LinkedRMAListSidebar orderNumber={orderNumbers[0]} />
    </div>
  )
}
