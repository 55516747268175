import {OrderShape} from '../../../../../../common/PropTypes.js'

import OrderSupplier from '../../Fields/OrderSupplier.js'

export default function DropshipmentRequestedSummary({order}) {
  return (
    <div>
      <div>
        <strong className="fs-n1 text--md-grey">Order Supplier:</strong>
      </div>
      <div className="fs-n1">
        <span>
          <OrderSupplier orderNumber={order.order_number} />
        </span>
        {order.dropshipping_info.requested_shipping_method && (
          <span className="fs-n1"> - </span>
        )}
        <em className="fs-n1">
          {order.dropshipping_info.requested_shipping_method}
        </em>
      </div>
    </div>
  )
}

DropshipmentRequestedSummary.propTypes = {
  order: OrderShape.isRequired,
}
