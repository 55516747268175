import isEmpty from 'lodash/isEmpty.js'

import {isPresent, isNumeric} from '../../../../../common/utils.js'
import api from '../../../../../common/api.js'
import {setColumnsToDataMap} from '../../dataImport/index.js'
import {
  getSupplierName,
  activeSuppliersByNameSelector,
} from '../../../../../data/suppliers.js'
import {
  getWarehouseName,
  warehousesByNameSelector,
} from '../../../../../data/warehouses.js'
import baseStrategy from './baseStrategy.js'

const HEADER = [
  'PO Number',
  'Supplier Name *Req',
  'Receiving Warehouse',
  'Shipping Method',
  'Payment Method',
  'Instructions',
  'Estimated Delivery',
  'Discount',
  'Shipping + Handling',
  'Tax',
  'SKU *Req',
  'Line Ordered Qty',
  'Supplier Unit Cost',
  'Line Discount',
]

export default class purchaseOrderStrategy extends baseStrategy {
  static title = 'Create Purchase Orders'
  static requiredColumns = ['supplier_id', 'sku']

  static getExampleRows() {
    return () =>
      api
        .get('/purchase_order/', {limit: 5})
        .then(({json: data}) => this.handleExampleRows(data))
  }

  static handleExampleRows(data) {
    const rows = [[...HEADER]]

    function expandBaseForEachItem(row, items) {
      return items.map((item) => [
        ...row,
        item.product.sku,
        item.quantity,
        item.unit_price,
        item.discount_amount,
      ])
    }

    data.purchase_order.forEach((po) => {
      const base = [
        po.po_id,
        getSupplierName(po.supplier),
        getWarehouseName(po.warehouse),
        po.shipping_method,
        po.payment_method,
        po.instructions,
        po.estimated_delivery_date,
        po.discount_amount,
        po.shipping_amount,
        po.tax_amount,
      ]

      rows.push(...expandBaseForEachItem(base, po.items))
    })

    return rows
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      po_id: find(/(purchase.order|po).(id|number)/i),
      supplier_name: find(/supplier/i),
      warehouse_name: find(/warehouse/i),
      shipping_method: find(/shipping.method/i),
      payment_method: find(/payment.method/i),
      instructions: find(/instructions/i),
      estimated_delivery_date: find(/est(imated)?.delivery(.date)?/i),
      shipping_amount: find(/^shipping(.*(handling|amount))/i),
      discount_amount: find(/^discount/i),
      tax_amount: find(/^tax/i),
      sku: find(/sku/i),
      quantity: find(/(quantity|qty)/i),
      unit_price: find(/(line|unit|item).(price|cost)/i),
      item_discount_amount: find(/(line|unit|item).discount/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowsToData(rows, columnsToDataMap) {
    return (dispatch, getState) => {
      const state = getState()
      const suppliersByName = activeSuppliersByNameSelector(state)
      const warehousesByName = warehousesByNameSelector(state)
      const dataByID = {}

      for (let i = 0; i < rows.length; i++) {
        const data = this.transformRowToData(
          rows[i],
          columnsToDataMap,
          suppliersByName,
          warehousesByName,
        )
        const id = data.payload.po_id || data.payload.supplier_id

        dataByID[id] = dataByID[id] || []

        dataByID[id].push(data)
      }

      return Object.keys(dataByID).map((id, index) => {
        const dataSet = dataByID[id]
        const data = dataSet[0]
        data.index = index

        data.payload.items = dataSet.map(({payload}) => payload.items[0])

        data.errors = this.determineDataErrors(data)

        return data
      })
    }
  }

  static transformRowToData(
    row,
    columnsByName,
    suppliersByName,
    warehousesByName,
  ) {
    const data = this.getNewData()
    const supplierName = row[columnsByName.supplier_name]
    const supplier = suppliersByName[row[columnsByName.supplier_name]] || {
      id: null,
    }
    const warehouseName = row[columnsByName.warehouse_name]
    const warehouse = warehousesByName[warehouseName] || {
      id: null,
    }

    data.payload = {
      po_id: row[columnsByName.po_id],
      supplier_id: supplier.id,
      supplier_name: supplierName,
      supplier_name_is_valid: supplierName ? supplier.id !== null : true,
      discount_amount: row[columnsByName.discount_amount],
      shipping_amount: row[columnsByName.shipping_amount],
      tax_amount: row[columnsByName.tax_amount],
      shipping_method: row[columnsByName.shipping_method],
      payment_method: row[columnsByName.payment_method],
      instructions: row[columnsByName.instructions],
      estimated_delivery_date: row[columnsByName.estimated_delivery_date],
      warehouse_id: warehouse.id,
      warehouse_name: warehouseName,
      warehouse_name_is_valid: warehouseName ? warehouse.id !== null : true,
      items: [
        {
          sku: row[columnsByName.sku],
          quantity: row[columnsByName.quantity] || 1,
          unit_price: row[columnsByName.unit_price],
          discount_amount: row[columnsByName.item_discount_amount],
        },
      ],
    }

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {}

    if (data.errors.server) {
      errors.server = data.errors.server
    }

    if (!payload.supplier_name_is_valid) {
      errors.supplier_id = `Supplier (${payload.supplier_name}) is not valid`
    } else if (payload.supplier_id === null) {
      errors.supplier_id = 'Supplier is required'
    }

    if (!payload.warehouse_name_is_valid) {
      errors.warehouse_id = `Warehouse (${payload.warehouse_name}) is not valid`
    }

    if (
      isPresent(payload.discount_amount) &&
      !isNumeric(payload.discount_amount)
    ) {
      errors.discount_amount = 'Amount must be a number'
    }

    if (
      isPresent(payload.shipping_amount) &&
      !isNumeric(payload.shipping_amount)
    ) {
      errors.shipping_amount = 'Amount must be a number'
    }

    if (isPresent(payload.tax_amount) && !isNumeric(payload.tax_amount)) {
      errors.tax_amount = 'Amount must be a number'
    }

    const itemErrors = payload.items.reduce((_itemErrors, item, index) => {
      const errs = {}

      if (!isPresent(item.sku)) {
        errs.sku = 'SKU is required'
      }

      if (!isNumeric(item.quantity)) {
        errs.quantity = 'Quantity must be a number'
      }

      if (!isPresent(item.quantity)) {
        errs.quantity = 'Quantity is required'
      }

      if (isPresent(item.unit_price) && !isNumeric(item.unit_price)) {
        errs.unit_price = 'Supplier Unit Cost must be a number'
      }

      if (isPresent(item.discount_amount) && !isNumeric(item.discount_amount)) {
        errs.discount_amount = 'Amount must be a number'
      }

      if (!isEmpty(errs)) {
        _itemErrors[index] = errs
      }

      return _itemErrors
    }, {})

    if (!isEmpty(itemErrors)) {
      errors.items = itemErrors
    }

    return errors
  }

  static save(data) {
    return () => {
      const po = {
        supplier_id: data.payload.supplier_id,
      }

      if (data.payload.po_id) {
        po.po_id = data.payload.po_id
      }

      if (data.payload.warehouse_id) {
        po.warehouse_id = data.payload.warehouse_id
      }

      if (data.payload.shipping_method) {
        po.shipping_method = data.payload.shipping_method
      }

      if (data.payload.payment_method) {
        po.payment_method = data.payload.payment_method
      }

      if (data.payload.instructions) {
        po.instructions = data.payload.instructions
      }

      if (data.payload.estimated_delivery_date) {
        po.estimated_delivery_date = data.payload.estimated_delivery_date
      }

      if (data.payload.shipping_amount) {
        po.shipping_amount = Number(data.payload.shipping_amount)
      }

      if (data.payload.tax_amount) {
        po.tax_amount = Number(data.payload.tax_amount)
      }

      if (data.payload.discount_amount) {
        po.discount_amount = Number(data.payload.discount_amount)
      }

      po.items = data.payload.items.map((item) => {
        const newItem = {
          sku: item.sku,
          quantity: Number(item.quantity),
        }

        if (item.unit_price) {
          newItem.unit_price = Number(item.unit_price)
        }

        if (item.discount_amount) {
          newItem.discount_amount = Number(item.discount_amount)
        }

        return newItem
      })

      return api.post('/purchase_order', po)
    }
  }
}
