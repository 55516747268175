import {NEW_ID} from '../../../../../../common/constants/index.js'
import {stringifyURL} from '../../../../../../common/querystring.js'
import {navigate} from '../../../../ui/index.js'

export const BASE = 'ordoro/ui/settings/shippers/forms/canadaPost'
export const SAVE_SHIPPER_CANADA_POST = `${BASE}/SAVE_SHIPPER_CANADA_POST`

export function authorize(id) {
  const params = {
    shipperID: id && id !== NEW_ID ? id : undefined,
  }

  return navigate(stringifyURL('/verde/canada_post/start', params))
}

export function saveShipperCanadaPost(shipperID) {
  return {
    type: SAVE_SHIPPER_CANADA_POST,
    payload: shipperID,
  }
}
