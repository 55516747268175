import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import classNames from 'classnames'

import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
} from '../../../common/constants/Products.js'
import {getSupplierName} from '../../../data/suppliers.js'
import {getFulfillmentType} from '../../../data/products.js'
import {showProductSupplierModal} from '../Modals/ProductSupplierModal.js'
import {autoDropshipOnOrderCreationSelector} from '../../../data/company.js'

export default function SupplierCell({
  sku,
  productSelector,
  defaultSupplierSelector,
}) {
  const product = useSelector(productSelector)
  const defaultSupplier = useSelector(defaultSupplierSelector)
  const autoDropshipOnOrderCreation = useSelector(
    autoDropshipOnOrderCreationSelector,
  )
  const fulfillmentType = getFulfillmentType(product)

  return (
    <td className="table__td table__td--product-list-supplier">
      <ButtonLink
        className={classNames({darkest: defaultSupplier})}
        onClick={() => showProductSupplierModal([sku])}
      >
        {defaultSupplier ? getSupplierName(defaultSupplier) : <em>Not set</em>}
      </ButtonLink>
      {[
        DROPSHIPPABLE_FULFILLMENT,
        AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
      ].includes(fulfillmentType) && (
        <div className="margin-top-1">
          <strong className="label__callout label__callout--grey margin-top-1">
            DROPSHIPPED
          </strong>
        </div>
      )}
      {autoDropshipOnOrderCreation &&
        fulfillmentType === AUTOMATICALLY_DROPSHIPPED_FULFILLMENT && (
          <div className="margin-top-1">
            <strong className="label__callout label__callout--green margin-top-1">
              AUTO
            </strong>
          </div>
        )}
    </td>
  )
}

SupplierCell.propTypes = {
  sku: PropTypes.string.isRequired,
  productSelector: PropTypes.func.isRequired,
  defaultSupplierSelector: PropTypes.func.isRequired,
}
