import PropTypes from 'prop-types'
import classNames from 'classnames'

import {SupplierShape} from '../../common/PropTypes.js'
import handleListSelection from '../../common/handleListSelection.js'
import Currency from '../../common/components/Currency.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {plural} from '../../common/components/Plural.js'
import {productSuppliersSelector} from '../../data/products.js'
import {suppliersSelector, getSupplierName} from '../../data/suppliers.js'
import {showEditProductSupplierModal} from '../ProductListPage/Modals/EditProductSupplierModal.js'
import {showUpdateDefaultSupplierModal} from '../ProductListPage/Modals/UpdateDefaultSupplierModal.js'
import {showRemoveProductSupplierModal} from '../ProductListPage/Modals/RemoveProductSupplierModal.js'
import {
  ProductDetailFormShape,
  updateProductDetailForm,
} from './productDetailActions.js'
import {
  productDetailFormSelector,
  skuSelector,
  productSupplierIDsSelector,
  areAllSuppliersSelectedSelector,
  areIndeterminateSuppliersSelectedSelector,
} from './productDetailSelectors.js'
import {usePurchaseOrdersSelector} from '../../data/company.js'
import {dispatch, useSelector} from '../../store.js'

function Supplier({sku, productSupplier, supplier, form, productSupplierIDs}) {
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)

  if (!supplier) {
    return null
  }

  return (
    <tr>
      <td className="table__td">
        <Checkbox
          key={productSupplier.id}
          className="margin-bottom-0"
          checked={form.selectedSupplierIDs.includes(productSupplier.id)}
          onClick={(checked, event) =>
            updateProductDetailForm({
              selectedSupplierIDs: handleListSelection({
                value: productSupplier.id,
                isSelected: checked,
                isShiftKey: event.shiftKey,
                selected: form.selectedSupplierIDs,
                list: productSupplierIDs,
              }),
            })
          }
        />
      </td>
      <td className="table__td table__td--md">
        <strong>{getSupplierName(supplier)}</strong>
      </td>
      <td className="table__td table__td--md">
        <ButtonLink
          className="darker v-align-base"
          onClick={() =>
            showEditProductSupplierModal(
              sku,
              [productSupplier.id],
              'supplier_sku',
            )
          }
        >
          {productSupplier.supplier_sku}
        </ButtonLink>
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="darker v-align-base"
          onClick={() =>
            showEditProductSupplierModal(
              sku,
              [productSupplier.id],
              'supplier_price',
            )
          }
        >
          <Currency value={productSupplier.supplier_price} />
        </ButtonLink>
      </td>
      {usePurchaseOrders && (
        <td className="table__td table__td--md align-right">
          <ButtonLink
            className="darker v-align-base"
            onClick={() =>
              showEditProductSupplierModal(
                sku,
                [productSupplier.id],
                'min_order_qty',
              )
            }
          >
            {productSupplier.min_order_qty}
          </ButtonLink>
        </td>
      )}
      <td className="table__td table__td--md">
        <ButtonLink
          className={classNames('v-align-base', {
            darker: productSupplier.supplier_lead_time,
          })}
          onClick={() =>
            showEditProductSupplierModal(
              sku,
              [productSupplier.id],
              'supplier_lead_time',
            )
          }
        >
          {productSupplier.supplier_lead_time ? (
            plural(productSupplier.supplier_lead_time)`${
              productSupplier.supplier_lead_time
            } day${['s']}`
          ) : (
            <em>Not configured</em>
          )}
        </ButtonLink>
      </td>
      <td className="table__td table__td--md align-right">
        {productSupplier.is_default ? (
          <strong className="label__callout label__callout--blue fs-n0">
            DEFAULT
          </strong>
        ) : (
          <ButtonPrimary
            className="btn--make-default-td"
            isOutlined
            size="xx-sm"
            onClick={() =>
              dispatch(showUpdateDefaultSupplierModal(sku, productSupplier.id))
            }
          >
            Make Default
          </ButtonPrimary>
        )}
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="no-underline"
          title="Remove supplier"
          onClick={() =>
            dispatch(showRemoveProductSupplierModal(sku, [productSupplier.id]))
          }
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

Supplier.propTypes = {
  sku: PropTypes.string.isRequired,
  productSupplier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    supplier_sku: PropTypes.string.isRequired,
    supplier_price: PropTypes.number.isRequired,
    min_order_qty: PropTypes.number.isRequired,
    supplier_lead_time: PropTypes.number,
    is_default: PropTypes.bool.isRequired,
  }),
  supplier: SupplierShape.isRequired,
  form: ProductDetailFormShape.isRequired,
  productSupplierIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default function Suppliers() {
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const sku = useSelector(skuSelector)
  const productSuppliers = useSelector((state) =>
    productSuppliersSelector(state, {sku}),
  )
  const suppliers = useSelector(suppliersSelector)
  const form = useSelector(productDetailFormSelector)
  const productSupplierIDs = useSelector(productSupplierIDsSelector)
  const areAllSuppliersSelected = useSelector(areAllSuppliersSelectedSelector)
  const areIndeterminateSuppliersSelected = useSelector(
    areIndeterminateSuppliersSelectedSelector,
  )

  return (
    <div>
      <table className="table fs-00">
        <thead>
          <tr>
            <th className="table__th table__th--sm w-1">
              <Checkbox
                className="margin-bottom-0"
                checked={areAllSuppliersSelected}
                indeterminate={areIndeterminateSuppliersSelected}
                onClick={(checked) =>
                  updateProductDetailForm({
                    selectedSupplierIDs: checked ? productSupplierIDs : [],
                  })
                }
              />
            </th>
            <th className="table__th table__th--md w-20">Supplier Name</th>
            <th className="table__th table__th--md w-20">
              {form.selectedSupplierIDs.length === 0 ? (
                'Supplier SKU'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Supplier SKUs"
                  onClick={() =>
                    showEditProductSupplierModal(
                      sku,
                      form.selectedSupplierIDs,
                      'supplier_sku',
                    )
                  }
                >
                  Supplier SKU
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--md align-right w-10">
              {form.selectedSupplierIDs.length === 0 ? (
                'Unit Cost'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Supplier Unit Cost"
                  onClick={() =>
                    showEditProductSupplierModal(
                      sku,
                      form.selectedSupplierIDs,
                      'supplier_price',
                    )
                  }
                >
                  Supplier Unit Cost
                </ButtonLink>
              )}
            </th>
            {usePurchaseOrders && (
              <th className="table__th table__th--md align-right w-15">
                {form.selectedSupplierIDs.length === 0 ? (
                  'Min Order Qty'
                ) : (
                  <ButtonLink
                    className="darker lh-md"
                    title="Bulk Update Minimum Order Quantities"
                    onClick={() =>
                      showEditProductSupplierModal(
                        sku,
                        form.selectedSupplierIDs,
                        'min_order_qty',
                      )
                    }
                  >
                    Min Order Qty
                  </ButtonLink>
                )}
              </th>
            )}
            <th className="table__th table__th--md w-15">
              {form.selectedSupplierIDs.length === 0 ? (
                'Lead Time'
              ) : (
                <ButtonLink
                  className="darker lh-md"
                  title="Bulk Update Lead Time"
                  onClick={() =>
                    showEditProductSupplierModal(
                      sku,
                      form.selectedSupplierIDs,
                      'supplier_lead_time',
                    )
                  }
                >
                  Lead Time
                </ButtonLink>
              )}
            </th>
            <th className="table__th table__th--md">&nbsp;</th>
            <th className="table__th table__th--md align-right w-5">
              {form.selectedSupplierIDs.length === 0 ? (
                ''
              ) : (
                <ButtonLink
                  className="no-underline"
                  title="Remove supplier"
                  onClick={() =>
                    dispatch(
                      showRemoveProductSupplierModal(
                        sku,
                        form.selectedSupplierIDs,
                      ),
                    )
                  }
                >
                  <span className="i-trash fs-00" aria-hidden="true"></span>
                </ButtonLink>
              )}
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines">
          {productSuppliers.length === 0 && (
            <tr>
              <td className="table__td align-center" colSpan="8">
                <p className="fs-01 margin-bottom-10">
                  <strong>
                    No suppliers have been assigned to this product.
                  </strong>
                </p>
                <ButtonPrimary
                  className="margin-bottom-0"
                  size="sm"
                  isOutlined
                  onClick={() => showEditProductSupplierModal(sku)}
                >
                  Add Supplier Assignment
                </ButtonPrimary>
              </td>
            </tr>
          )}
          {productSuppliers.map((productSupplier) => (
            <Supplier
              key={productSupplier.id}
              sku={sku}
              productSupplier={productSupplier}
              supplier={suppliers[productSupplier.id]}
              form={form}
              productSupplierIDs={productSupplierIDs}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
