import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import ConfigCheckbox from './ConfigCheckbox.js'
import {NEWGISTICS} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'enable_notifications'
const VALID_SHIPPER_TYPES = [NEWGISTICS]

export default function EnableNotifications() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigCheckbox
      label="Enable Notifications"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
