import keyBy from 'lodash/keyBy.js'
import {put, call, takeEvery} from 'redux-saga/effects'

import {fetchAllAPI} from '../../../common/fetchAll.js'
import {setGlobalError} from '../../actions/ui/index.js'
import {updateHasLoaded} from '../../actions/data/hasLoaded.js'
import {GET_API_KEYS, setAPIKeys} from '../../actions/data/apiKeys.js'

export function* getAPIKeysWorker() {
  try {
    const apiKeys = yield call(fetchAllAPI, '/api_key/', 'api_key')

    yield put(setAPIKeys(keyBy(apiKeys, 'id')))
  } catch (err) {
    yield put(
      setGlobalError(
        `Error getting apiKeys. ${err.error_message || err.message}`,
        err,
      ),
    )
  }

  yield put(updateHasLoaded({apiKeys: true}))
}

export default function* apiKeysSaga() {
  yield takeEvery(GET_API_KEYS, getAPIKeysWorker)
}
