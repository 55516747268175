import {useSelector} from '../../store.js'
import BatchSelectAllButton from './BatchSelectAllButton.js'
import {referenceIDListSelector} from './batchListSelectors.js'
import Row from './Row.js'
import EmptyList from './EmptyList.js'

export default function Table() {
  const referenceIDList = useSelector(referenceIDListSelector)

  return (
    <table className="table table--zebra-list table--product-list">
      <thead>
        <tr className="table__tr">
          <th className="table__th table__th--sm v-align-middle no-border table__th--order-checkbox">
            <BatchSelectAllButton />
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            <span className="fs-n0">Batch ID</span>
          </th>
          <th className="table__th table__th--sm th-width-20 v-align-middle no-border">
            <span className="fs-n0">Contents</span>
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            <span className="fs-n0">Date Created</span>
          </th>
          <th className="table__th table__th--sm v-align-middle th-width-15 no-border">
            <span className="fs-n0">Last Updated/Status</span>
          </th>
          <th className="table__th table__th--sm v-align-middle th-width-30 no-border">
            <span className="fs-n0">Documents</span>
          </th>
          <th className="table__th table__th--sm v-align-middle no-border w-3" />
        </tr>
      </thead>
      <tbody className="table__tbody--full-border">
        {referenceIDList.map((referenceID) => (
          <Row key={referenceID} referenceID={referenceID} />
        ))}
        {referenceIDList.length === 0 && <EmptyList colSpan="7" />}
      </tbody>
    </table>
  )
}
