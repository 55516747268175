import Checkbox from '../../../../common/components/Checkbox.js'
import {useSelector} from '../../../../store.js'
import {
  modalFormSelector,
  updateModalForm,
} from './productListFilterModalFunctions.js'
import {fulfillmentChannelsSelector} from '../../../../data/products.js'
import TextInput from '../../../../common/components/TextInput.js'
import {usesInventorySelector} from '../../../../data/company.js'

export default function MiscFilterPanel() {
  const form = useSelector(modalFormSelector)
  const fulfillmentChannels = useSelector(fulfillmentChannelsSelector)
  const usesInventory = useSelector(usesInventorySelector)

  return (
    <div className="wrap--modal-filters wrap--modal-filters-misc">
      <div className="wrap__modal-subheader margin-bottom-25">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0 flex--justify-col">
          <strong>Misc</strong>
        </h4>
      </div>
      {usesInventory && (
        <div className="margin-top-10 margin-bottom-30">
          <div className="fs-00">
            <strong>Category</strong>
          </div>
          <div className="flex margin-top-3">
            <div className="flex w-50 margin-right-15">
              <TextInput
                id="category"
                value={form.category}
                onChange={(category) => updateModalForm({category})}
              />
            </div>
            <em className="fs-n0 lh-md op-50 flex--justify-col">
              Enter a product category to filter by.
            </em>
          </div>
        </div>
      )}
      <div className="margin-top-10 margin-bottom-30">
        <div className="fs-00">
          <strong>Free Text Search</strong>
        </div>
        <div className="flex margin-top-3">
          <div className="flex w-50 margin-right-15">
            <TextInput
              id="searchText"
              value={form.searchText}
              onChange={(searchText) => updateModalForm({searchText})}
            />
          </div>
          <em className="fs-n0 lh-md op-50 flex--justify-col">
            Filter by any product-related text string.
          </em>
        </div>
      </div>
      {fulfillmentChannels.length > 0 && (
        <div className="margin-top-10 margin-bottom-30">
          <div className="fs-00">
            <strong>Fulfillment Type</strong>
          </div>
          <div className="flex margin-top-7">
            {fulfillmentChannels.map((option, index) => (
              <div
                key={option.value}
                className={
                  index !== fulfillmentChannels.length - 1
                    ? 'margin-right-25'
                    : ''
                }
              >
                <Checkbox
                  mode="fancy"
                  id={option.value}
                  value={option.value}
                  checked={!!form[option.value]}
                  onChange={(checked) => {
                    updateModalForm({[option.value]: checked})
                  }}
                  label={option.display}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
