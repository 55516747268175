import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import BaseConfirmModal from './BaseConfirmModal.js'
import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  confirm,
} from '../../../redux/actions/ui/modals/confirmAttemptToAllocateModal.js'
import {confirmAttemptToAllocateOrdersAllocatableOrderCountSelector} from '../../../redux/selectors/ui/modals/confirmAttemptToAllocateModal.js'

class ConfirmAttemptToAllocateModal extends Component {
  static propTypes = {
    allocatableOrderCount: PropTypes.number.isRequired,
    orderCount: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  render() {
    const {allocatableOrderCount, orderCount, isSaving} = this.props

    return (
      <BaseConfirmModal
        title={`Attempt to Allocate ${orderCount > 1 ? 'Orders' : 'Order'}`}
        modalName={MODAL_NAME}
        isSaving={isSaving}
        onConfirm={this.props.confirm}
        onCancel={() => this.props.closeModal()}
      >
        <p className="fs-01 lh-md margin-bottom-0">
          We can attempt to allocate stock for{' '}
          <strong>
            {allocatableOrderCount}{' '}
            {allocatableOrderCount > 1
              ? `of the selected ${orderCount} orders`
              : 'order'}
          </strong>
          . Do you want this to happen?
        </p>
      </BaseConfirmModal>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  orderCount: (state) =>
    state.ui.modals.confirmAttemptToAllocateModal.orders.length,
  isSaving: (state) => state.ui.modals.confirmAttemptToAllocateModal.isSaving,
  allocatableOrderCount:
    confirmAttemptToAllocateOrdersAllocatableOrderCountSelector,
})

const mapDispatchToProps = {
  confirm,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmAttemptToAllocateModal)
