import {useEffect} from 'react'
import {connect} from 'react-redux'

import {dispatch} from '../../store.js'
import {MO_LIST_PAGE} from '../../common/constants/Pages.js'
import {MO_PLURAL_URI_COMPONENT} from '../../common/constants/MOs.js'
import {LocationShape} from '../../common/PropTypes.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {setupMOListForm} from './moListActions.js'

function MOListLocationListener({location}) {
  const {
    pathComponents: [resource],
  } = location
  const isThisPage = resource === MO_PLURAL_URI_COMPONENT

  useEffect(() => {
    setupMOListForm()
  }, [])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    dispatch(setCurrentPage(MO_LIST_PAGE))
  }, [isThisPage])

  return null
}

MOListLocationListener.propTypes = {
  location: LocationShape.isRequired,
}

function mapStateToProps(state) {
  return {
    location: locationSelector(state),
  }
}

export default connect(mapStateToProps)(MOListLocationListener)
