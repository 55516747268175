import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_CURRENT_DROPDOWN} from '../../actions/ui/currentDropdown.js'

export default function currentDropdown(
  state = INITIAL_STATE.ui.currentDropdown,
  action = {},
) {
  if (action.type === SET_CURRENT_DROPDOWN) {
    return action.payload
  }

  return state
}
