import PropTypes from 'prop-types'

import {SelectOptionsShape} from '../../PropTypes.js'
import HeaderDropdown from './HeaderDropdown.js'
import DropdownItemButton from '../Dropdown/DropdownItemButton.js'

const DROPDOWN = 'DROPDOWN_FILTER_SORT_BY'

export default function Sort({items, value, onChange}) {
  const selectedItem = items.find((option) => option.value === value)
  const selectedItemName = selectedItem ? selectedItem.display : ''

  return (
    <>
      <li className="wrap--sort-label fs-n0 divider--left">
        <strong>Sort:</strong>
      </li>
      <HeaderDropdown label={selectedItemName} dropdown={DROPDOWN}>
        {items.map((item) => (
          <DropdownItemButton
            key={item.value}
            value={item.value}
            selectedValue={value}
            onClick={() => {
              if (onChange && value !== item.value) {
                onChange(item.value)
              }
            }}
          >
            {item.display}
          </DropdownItemButton>
        ))}
      </HeaderDropdown>
    </>
  )
}

Sort.propTypes = {
  items: SelectOptionsShape.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}
