import PropTypes from 'prop-types'
import classNames from 'classnames'

import {NEW_ID} from '../../../../common/constants/index.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {ShipperFormShape} from '../../../../common/PropTypes.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
  shipperFormSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'
import {dispatch, getState, useSelector} from '../../../../store.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'
import {
  goToShipperSettings,
  updateForm,
} from '../../../../redux/actions/ui/settings/shippers/index.js'
import {PITNEY_CBDS} from '../../../../common/constants/ShipperNames.js'
import api from '../../../../common/api.js'
import {setShipper} from '../../../../data/shippers.js'
import {isPresent} from '../../../../common/utils.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.postal_reporting_number)) {
    errors.postal_reporting_number = 'Shipper ID is required'
  }

  if (!isPresent(shipper.client_id)) {
    errors.client_id = 'Client ID is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    postal_reporting_number: vendorConfig.postal_reporting_number || '',
    client_id: vendorConfig.client_id || '',
    client_facility_id: vendorConfig.client_facility_id || '',
    facility_id: vendorConfig.facility_id || '',
    isSaving: false,
  }
}

export async function saveShipperPitneyCBDS(shipperID) {
  const {
    name,
    hidden_shipping_methods,
    postal_reporting_number,
    client_id,
    client_facility_id,
    facility_id,
  } = shipperFormSelector(getState(), {
    shipperID,
  })

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    postal_reporting_number,
    client_id,
    client_facility_id,
    facility_id,
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await (shipperID !== NEW_ID
      ? api.put(`/shipper/${shipperID}/`, params)
      : api.post('/shipper/', {...params, vendor: PITNEY_CBDS}))

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error while saving shipper.',
      },
      err,
    )

    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

export default function PitneyCBDSForm({form, cancel, updateForm}) {
  const errors = useSelector((state) => errorsOfChangedSelector(state, {form}))
  const hasErrors = useSelector((state) => hasErrorsSelector(state, {form}))
  const {
    id,
    postal_reporting_number,
    client_id,
    client_facility_id,
    facility_id,
    isSaving,
  } = form

  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.postal_reporting_number},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="id_postal_reporting_number">
                Shipper ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="postal_reporting_number"
                id="id_postal_reporting_number"
                value={postal_reporting_number}
                onChange={(event) =>
                  updateForm(id, {
                    postal_reporting_number: event.target.value,
                  })
                }
              />
              {errors.postal_reporting_number && (
                <small className="error error-message">
                  {errors.postal_reporting_number}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.client_id,
              })}
            >
              <label htmlFor="id_client_id">
                Client ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="client_id"
                id="id_client_id"
                value={client_id}
                onChange={(event) =>
                  updateForm(id, {
                    client_id: event.target.value,
                  })
                }
              />
              {errors.client_id && (
                <small className="error error-message">
                  {errors.client_id}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.client_facility_id,
              })}
            >
              <label htmlFor="id_client_facility_id">Client Facility ID</label>
              <input
                type="text"
                name="client_facility_id"
                id="id_client_facility_id"
                value={client_facility_id}
                onChange={(event) =>
                  updateForm(id, {
                    client_facility_id: event.target.value,
                  })
                }
              />
              {errors.client_facility_id && (
                <small className="error error-message">
                  {errors.client_facility_id}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.facility_id,
              })}
            >
              <label htmlFor="id_facility_id">Carrier Facility ID</label>
              <input
                type="text"
                name="facility_id"
                id="id_facility_id"
                value={facility_id}
                onChange={(event) =>
                  updateForm(id, {
                    facility_id: event.target.value,
                  })
                }
              />
              {errors.facility_id && (
                <small className="error error-message">
                  {errors.facility_id}
                </small>
              )}
            </li>
            {id !== NEW_ID && <ShippingMethods shipperID={id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                onClick={() => saveShipperPitneyCBDS(id)}
                isDisabled={hasErrors}
                isLoading={isSaving}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      {id === NEW_ID && (
        <div className="medium-5 columns">
          <fieldset>
            <div className="alert margin-bottom-10 alert--standard">
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help setting up your Pitney Cross-Border Delivery Service
                  account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-3">
                  <div className="margin-bottom-3">
                    To get the process started, please contact Pitney Bowes
                    directly and let them know you’re an Ordoro customer.
                  </div>
                  <div>
                    <strong>
                      <a
                        className="btn--link mid meta-ups-setup-help"
                        href="https://support.ordoro.com/how-do-i-set-up-cbds-in-ordoro/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More info →
                      </a>
                    </strong>
                  </div>
                </dd>
              </dl>
            </div>
          </fieldset>
        </div>
      )}
    </div>
  )
}

PitneyCBDSForm.propTypes = {
  form: ShipperFormShape.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
