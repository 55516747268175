import WarehouseSelect from './WarehouseSelect.js'
import {useSelector} from '../../../../store.js'
import {
  isPickupScanFormSelector,
  paymentMethodScanFormSelector,
  scanFormModalFormSelector,
  setIsPickupScanForm,
  setPaymentMethodScanForm,
} from './scanFormModalFunctions.js'

export default function CanadaPostPanel() {
  const {shipperID} = useSelector(scanFormModalFormSelector)
  const isPickup = useSelector(isPickupScanFormSelector)
  const paymentMethod = useSelector(paymentMethodScanFormSelector)

  return (
    <div>
      <div className="margin-bottom-20">
        <label className="fs-00" htmlFor="id_cp_dropoff">
          Are the orders being picked up or dropped off?
        </label>
        <div className="inline-block margin-right-15">
          <label className="unbold-label" htmlFor="id_cp_pickup">
            <input
              className="margin-bottom-0 margin-right-5"
              type="radio"
              id="id_cp_pickup"
              name="cp_pickup_option"
              value="pickup"
              checked={isPickup}
              onChange={() => setIsPickupScanForm(shipperID, true)}
            />
            Picked up
          </label>
        </div>
        <div className="inline-block">
          <label className="unbold-label" htmlFor="id_cp_dropoff">
            <input
              className="margin-bottom-0 margin-right-5"
              type="radio"
              id="id_cp_dropoff"
              name="cp_pickup_option"
              value="droppped_off"
              checked={!isPickup}
              onChange={() => setIsPickupScanForm(shipperID, false)}
            />
            Dropped off
          </label>
        </div>
      </div>
      <div className="margin-bottom-20">
        <label className="fs-00" htmlFor="id_cp_acct">
          How would you like to be charged?
        </label>
        <div className="inline-block margin-right-15">
          <label className="unbold-label" htmlFor="id_cp_acct">
            <input
              className="margin-bottom-0 margin-right-5"
              type="radio"
              id="id_cp_acct"
              name="cp_payment_option"
              value="Account"
              checked={paymentMethod === 'Account'}
              onChange={() => setPaymentMethodScanForm(shipperID, 'Account')}
            />
            On my account
          </label>
        </div>
        <div className="inline-block">
          <label className="unbold-label" htmlFor="id_cp_cc">
            <input
              className="margin-bottom-0 margin-right-5"
              type="radio"
              id="id_cp_cc"
              name="cp_payment_option"
              value="CreditCard"
              checked={paymentMethod === 'CreditCard'}
              onChange={() => setPaymentMethodScanForm(shipperID, 'CreditCard')}
            />
            On a credit card
          </label>
        </div>
      </div>
      <WarehouseSelect />
    </div>
  )
}
