import {all, takeEvery, call, put, select, fork} from 'redux-saga/effects'
import get from 'lodash/get.js'
import countBy from 'lodash/countBy.js'

import {
  closeModal,
  openModal,
  setGlobalError,
} from '../../../actions/ui/index.js'
import {
  CONFIRM,
  SHOW_MODAL,
  MODAL_NAME,
  setOrders,
  setIsSaving,
} from '../../../actions/ui/modals/confirmAttemptToAllocateModal.js'
import {confirmAttemptToAllocateOrdersSelector} from '../../../selectors/ui/modals/confirmAttemptToAllocateModal.js'
import {
  ordersSelector,
  postAndSetOrderResponse,
} from '../../../../data/orders.js'
import {refreshOrderListAndCounts} from '../../../../ordoro/OrderListPage/orderListActions.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import {Count, plural} from '../../../../common/components/Plural.js'

export const ATTEMPT_TO_ALLOCATE_ORDER = `ordoro/data/orders/ATTEMPT_TO_ALLOCATE_ORDER`

export function attemptToAllocateOrders(orderNumbers) {
  return {
    type: ATTEMPT_TO_ALLOCATE_ORDER,
    payload: {orderNumbers},
  }
}

export function* attemptToAllocateOrdersWorker({payload: {orderNumbers}}) {
  try {
    const orders = yield select(ordersSelector, {orderNumbers})

    const attemptedOrders = []

    // NOTE: we don't want to run these in parallel - they should run serially
    // in sort order from the list page
    for (const order of orders) {
      if (
        ['unallocated', 'could_not_allocate', 'partial'].includes(
          order.allocation_status,
        ) &&
        order.status === 'awaiting_fulfillment'
      ) {
        attemptedOrders.push(order.order_number)
        yield call(
          postAndSetOrderResponse,
          `${order.link}/attempt_to_allocate`,
          {force_comment: true},
        )
      }
    }

    yield fork(refreshOrderListAndCounts)

    const updatedOrders = yield select(ordersSelector, {orderNumbers})
    const statusCounts = countBy(updatedOrders, (order) =>
      get(order, 'allocation_status'),
    )

    let messages = []
    if (statusCounts.allocated) {
      messages.push(
        plural(statusCounts.allocated)`${Count} order${['s']} allocated`,
      )
    }
    if (statusCounts.partial) {
      messages.push(
        plural(
          statusCounts.partial,
        )`${Count} order${['s']} partially allocated`,
      )
    }
    if (statusCounts.could_not_allocate) {
      messages.push(
        plural(
          statusCounts.could_not_allocate,
        )`${Count} order${['s']} could not be allocated`,
      )
    }

    let combinedMessage = messages.join(', ')
    yield call(showMessageToast, combinedMessage)
  } catch (error) {
    if (orderNumbers.length === 1) {
      yield put(
        setGlobalError(
          {
            summary: 'Error allocating order.',
            details: error.message,
          },
          error,
        ),
      )
    } else {
      yield put(
        setGlobalError(
          {
            summary: 'Error allocating orders.',
            details: error.message,
          },
          error,
        ),
      )
    }
  }
}

export function* confirmWorker() {
  const orders = yield select(confirmAttemptToAllocateOrdersSelector)

  yield put(setIsSaving(true))
  yield call(
    attemptToAllocateOrdersWorker,
    attemptToAllocateOrders(orders.map(({order_number}) => order_number)),
  )
  yield put(setIsSaving(false))
  yield put(closeModal())
}

export function* showModalWorker(action) {
  const {payload: orders} = action

  yield put(setOrders(orders))
  yield put(setIsSaving(false))
  yield put(openModal(MODAL_NAME))
}

export default function* confirmAttemptToAllocateModal() {
  yield all([
    takeEvery(CONFIRM, confirmWorker),
    takeEvery(SHOW_MODAL, showModalWorker),
  ])
}
