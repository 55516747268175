import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import api from '../../../common/api.js'
import {isPresent} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import DatePicker from '../../../common/components/DatePicker.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {setPO} from '../../../data/pos.js'

export const MODAL_FORM = 'GOODS_RECEIPT_TEXT_FIELD'

export function showGoodsReceiptTextFieldModal({
  goodsReceiptID,
  value,
  display,
  apiProp,
  isRequired,
  needsPOListRefresh,
  inputType,
}) {
  setForm(MODAL_FORM, {
    goodsReceiptID,
    apiProp,
    value:
      inputType === 'date'
        ? value
          ? new Date(value)
          : null
        : isPresent(value)
          ? value
          : '',
    display,
    isRequired: !!isRequired,
    needsPOListRefresh: !!needsPOListRefresh,
    inputType: inputType || 'text',
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
      errors.preventSave = true
    }

    return errors
  },
)

export async function saveGoodsReceiptTextField() {
  try {
    const {
      goodsReceiptID,
      value,
      display,
      apiProp,
      needsPOListRefresh,
      inputType,
    } = modalFormSelector(getState())

    const params = {
      [apiProp]:
        inputType === 'date' ? (value ? value.toISOString() : null) : value,
    }

    updateModalForm({isSaving: true, serverError: null})

    const {json: goodsReceipt} = await api.put(
      `/goods_receipt/${encodeURIComponent(goodsReceiptID)}/`,
      params,
    )

    const {json: purchaseOrder} = await api.get(
      `/purchase_order/${encodeURIComponent(goodsReceipt.po_id)}/`,
    )
    setPO(purchaseOrder)

    closeModal()

    showMessageToast(`Saved Goods Receipt ${display.toLowerCase()}`)

    if (needsPOListRefresh) {
      await refreshPurchaseOrderList()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function GoodsReceiptTextFieldModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title={`Edit Goods Receipt ${form.display}`}
      modalSize="sm"
      onConfirm={() => saveGoodsReceiptTextField()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style divider--bottom margin-bottom-20">
        <div className="fs-01">
          <strong>{form.goodsReceiptID}</strong>
        </div>
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'date' ? (
        <DatePicker
          id="good_receipt_param_modal"
          selected={form.value}
          onChange={(value) =>
            updateModalForm({
              value,
            })
          }
        />
      ) : form.inputType === 'currency' ? (
        <CurrencyInput
          className="biggie margin-bottom-0"
          width="sm"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : null}
    </ConfirmModal>
  )
}

GoodsReceiptTextFieldModal.propTypes = {
  form: PropTypes.shape({
    goodsReceiptID: PropTypes.string.isRequired,
    value: PropTypes.any,
    display: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['text', 'textarea', 'date', 'currency'])
      .isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(GoodsReceiptTextFieldModal, modalFormSelector)
