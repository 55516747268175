import get from 'lodash/get.js'

import ShipperOptions from '../../data/shipperOptions.js'
import {
  AMAZON_SFP,
  CANADA_POST,
  DHL,
  ENDICIA,
  FEDEX,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  UPS,
  VISIBLE_USPS,
} from './ShipperNames.js'
const SHIPPERS = Object.keys(ShipperOptions)

function getDefaultsForOption(propertyName, optionName = propertyName) {
  return SHIPPERS.reduce((prev, shipperType) => {
    const value = get(
      ShipperOptions,
      [shipperType, optionName, 0, 'value'],
      null,
    )

    if (value !== null) {
      prev[`${shipperType}__${propertyName}`] = value
    }

    return prev
  }, {})
}

export const PRESET_TEMPLATE__ORDER_NUMBER = 'PRESET_TEMPLATE__ORDER_NUMBER'
export const PRESET_TEMPLATE__REFERENCE_ID = 'PRESET_TEMPLATE__REFERENCE_ID'
export const PRESET_TEMPLATE__PRODUCT_NAMES = 'PRESET_TEMPLATE__PRODUCT_NAMES'
export const PRESET_TEMPLATE__PRODUCT_SKUS = 'PRESET_TEMPLATE__PRODUCT_SKUS'

export const PRESET_TEMPLATES = {
  [PRESET_TEMPLATE__ORDER_NUMBER]: '{{order.order_number}}',
  [PRESET_TEMPLATE__REFERENCE_ID]: '{{order.reference_id}}',
  [PRESET_TEMPLATE__PRODUCT_NAMES]:
    '{% for line in order.lines %}{{line.product_name}}[{{line.quantity}}]{% if loop.last == false %}, {% endif %}{% endfor %}',
  [PRESET_TEMPLATE__PRODUCT_SKUS]:
    '{% for line in order.lines %}{{line.sku}}[{{line.quantity}}]{% if loop.last == false %}, {% endif %}{% endfor %}',
}

export const CONTENTS_TYPE_MERCHANDISE =
  ShipperOptions.endicia.contents_type.find(
    (option) => option.display === 'Merchandise',
  ).value
export const REASON_FOR_EXPORT_MERCHANDISE =
  ShipperOptions.pitney.reason_for_export.find(
    (option) => option.display === 'Merchandise',
  ).value

export const DEFAULT_LABEL_CONFIG = {
  amazon__delivery_confirmation: get(ShipperOptions, [
    AMAZON_SFP,
    'delivery_confirmation',
    1,
    'value',
  ]),
  canada_post__reason_for_export: get(ShipperOptions, [
    CANADA_POST,
    'reason_for_export',
    0,
    'value',
  ]),
  canada_post__nondelivery_option: get(ShipperOptions, [
    CANADA_POST,
    'nondelivery',
    0,
    'value',
  ]),
  fedex__duties_payment_type: get(ShipperOptions, [FEDEX, 'payor', 0, 'value']),
  dhl__duty_payment_type: get(ShipperOptions, [DHL, 'payor', 1, 'value']),
  dhl__reason_for_export: get(ShipperOptions, [
    DHL,
    'reason_for_export',
    0,
    'value',
  ]),
  ups__usps_endorsement: get(ShipperOptions, [
    UPS,
    'usps_endorsement',
    0,
    'value',
  ]),
  ups__duties_payment_type: get(ShipperOptions, [UPS, 'payor', 0, 'value']),
  ups__package_bill_type: get(ShipperOptions, [
    UPS,
    'package_bill_type',
    1,
    'value',
  ]),
  include_insurance: false,
  attach_customs_info: false,
  etd_service: true,
  packaging_weight: 0,
  ...getDefaultsForOption('payment_type', 'payor'),
  customs_info: [],
  packages: [],
}

export const DEFAULT_BOX_SHAPES = SHIPPERS.reduce((prev, shipperType) => {
  let value

  if (shipperType === AMAZON_SFP) {
    value = 'CUSTOM_PACKAGE'
  } else if (shipperType === DHL) {
    value = 'BOX'
  } else if (shipperType === ENDICIA) {
    value = 'Parcel'
  } else if (shipperType === PITNEY) {
    value = 'PKG'
  } else if (shipperType === PITNEY_MERCHANT) {
    value = 'PKG'
  } else if (shipperType === PITNEY_PRESORT) {
    value = 'PKG'
  } else if (shipperType === VISIBLE_USPS) {
    value = 'Package'
  } else {
    value = get(ShipperOptions, [shipperType, 'box_shape', 0, 'value'], null)
  }

  if (value !== null) {
    prev[`${shipperType}__box_shape`] = value
  }

  return prev
}, {})

export const DEFAULT_PACKAGE_SHAPE = {
  ...DEFAULT_BOX_SHAPES,
  weight: 0,
  length: 7,
  width: 7,
  height: 7,
  dry_ice_weight: 0,
  dry_ice_regulation_set: get(
    ShipperOptions,
    [UPS, 'dry_ice_regulation_set', 0, 'value'],
    null,
  ),
}

export const DEFAULT_RATES_SHAPE = {
  rates_loading: false,
  ratesUpdated: false,
  errorMessage: null,
  departedShippingMethod: null,
  shipperType: null,
  rates: null,
}

export const SHIPPER_REFERENCE_NUMBER_MAX_LENGTH = {
  [CANADA_POST]: 35,
}

export const SHIPPER_INVOICE_NUMBER_MAX_LENGTH = {
  [CANADA_POST]: 10,
}

export const FEDEX_SHIPPER_DEFAULT_HUB = 'FEDEX_SHIPPER_DEFAULT_HUB'
