import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {closeModal} from '../../../redux/actions/ui/index.js'
import {
  MODAL_NAME,
  setComment,
  addPurchaseOrderComment,
} from '../../../redux/actions/ui/modals/addPurchaseOrderCommentModal.js'
import {
  commentSelector,
  isAddingSelector,
  serverErrorSelector,
} from '../../../redux/selectors/ui/modals/addPurchaseOrderCommentModal.js'

import BaseConfirmModal from './BaseConfirmModal.js'

function AddPurchaseOrderCommentModal({
  comment,
  isAdding,
  serverError,
  ...props
}) {
  return (
    <BaseConfirmModal
      title="Add Purchase Order Comment"
      modalName={MODAL_NAME}
      isSaving={isAdding}
      confirmText="Add Comment"
      onConfirm={props.addPurchaseOrderComment}
      cancelText="Cancel"
      onCancel={() => props.closeModal()}
    >
      {serverError && (
        <dl className="list list--error error-message margin-bottom-15">
          <dd className="fs-n0 margin-bottom-0">{serverError}</dd>
        </dl>
      )}
      <div className="row">
        <div className="medium-12 columns">
          <textarea
            className="textarea-md"
            rows="10"
            name="create-comment"
            id="create-comment-id"
            value={comment}
            onChange={(event) => props.setComment(event.target.value)}
          />
        </div>
      </div>
    </BaseConfirmModal>
  )
}

AddPurchaseOrderCommentModal.propTypes = {
  comment: PropTypes.string.isRequired,
  isAdding: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
  setComment: PropTypes.func.isRequired,
  addPurchaseOrderComment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

AddPurchaseOrderCommentModal.modalName = MODAL_NAME

function mapStateToProps(state) {
  return {
    comment: commentSelector(state),
    isAdding: isAddingSelector(state),
    serverError: serverErrorSelector(state),
  }
}

const mapDispatchToProps = {
  setComment,
  addPurchaseOrderComment,
  closeModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPurchaseOrderCommentModal)
