import PropTypes from 'prop-types'

import {
  TAG_TYPE__ORDER,
  TAG_TYPE__RETURN_ORDER,
  TAG_TYPE__PRODUCT,
  TAG_TYPE__MO,
  TAG_TYPE__PO,
} from '../../constants/Tags.js'
import formConnect from '../../formConnect.js'
import ConfirmModal from '../Modal/ConfirmModal.js'
import {
  getState,
  dispatch,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import {showMessageToast} from '../../../ordoro/Header/Toast/index.js'
import {deleteOrderTag, orderTagSelector} from '../../../data/orderTags.js'
import {deleteReturnOrderTag} from '../../../data/returnOrderTags.js'
import {deleteProductTag} from '../../../data/productTags.js'
import {
  refreshOrderList,
  removeOrderTagFilter,
} from '../../../ordoro/OrderListPage/orderListActions.js'
import {refreshProductList} from '../../../ordoro/ProductListPage/productListFunctions.js'
import {loadCurrentProduct} from '../../../ordoro/ProductDetailPage/productDetailActions.js'
import {refreshReturnOrderList} from '../../../ordoro/ReturnOrderListPage/returnOrderListActions.js'
import {loadCurrentReturnOrder} from '../../../ordoro/ReturnOrderDetailPage/returnOrderDetailActions.js'
import {loadOrderDetailOrder} from '../../../ordoro/OrderDetailPage/orderDetailActions.js'
import {deleteMOTag, moTagSelector} from '../../../data/moTags.js'
import {
  refreshMOList,
  removeMOTagFilter,
} from '../../../ordoro/MOListPage/moListActions.js'
import {getCurrentDetailMO} from '../../../ordoro/MODetailPage/moDetailActions.js'
import {deletePOTag, poTagSelector} from '../../../data/poTags.js'
import {
  refreshPurchaseOrderList,
  removePOTagFilter,
} from '../../../ordoro/PurchaseOrderListPage/purchaseOrderListActions.js'
import {getCurrentDetailPO} from '../../../ordoro/PurchaseOrderDetailPage/poDetailActions.js'

const MODAL_FORM = 'DELETE_TAGS_MODAL_FORM'

export function showDeleteTagsModal(tagType, tagIDs) {
  setForm(MODAL_FORM, {
    tagType,
    tagIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deleteTag(tagType, tagID) {
  if (tagType === TAG_TYPE__ORDER) {
    const tag = orderTagSelector(getState(), {orderTagID: tagID})

    removeOrderTagFilter(tag.text)

    await deleteOrderTag(tagID)
  }
  if (tagType === TAG_TYPE__RETURN_ORDER) {
    await deleteReturnOrderTag(tagID)
  }
  if (tagType === TAG_TYPE__PRODUCT) {
    await deleteProductTag(tagID)
  }
  if (tagType === TAG_TYPE__MO) {
    const tag = moTagSelector(getState(), {moTagID: tagID})

    removeMOTagFilter(tag.name)

    await deleteMOTag(tagID)
  }
  if (tagType === TAG_TYPE__PO) {
    const tag = poTagSelector(getState(), {poTagID: tagID})

    removePOTagFilter(tag.name)

    await deletePOTag(tagID)
  }
}

export async function deleteTags() {
  try {
    updateModalForm({isSaving: true})

    const {tagType, tagIDs} = modalFormSelector(getState())
    const count = tagIDs.length

    await Promise.all(tagIDs.map((tagID) => deleteTag(tagType, tagID)))

    showMessageToast(`${count} Tag${count !== 1 ? 's were' : ' was'} deleted`)

    closeModal()

    if (tagType === TAG_TYPE__ORDER) {
      loadOrderDetailOrder({reload: true})

      refreshOrderList()
    }
    if (tagType === TAG_TYPE__RETURN_ORDER) {
      await loadCurrentReturnOrder()

      dispatch(refreshReturnOrderList())
    }
    if (tagType === TAG_TYPE__PRODUCT) {
      await loadCurrentProduct()

      await refreshProductList()
    }
    if (tagType === TAG_TYPE__MO) {
      await refreshMOList()

      await getCurrentDetailMO()
    }
    if (tagType === TAG_TYPE__PO) {
      await refreshPurchaseOrderList()

      await getCurrentDetailPO()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error deleting tag: ${err.message || err.error_message}`,
      isSaving: false,
    })
  }
}

function DeleteTagsModal({form}) {
  const count = form.tagIDs.length

  return (
    <ConfirmModal
      title={`Delete Tag${count !== 1 ? 's' : ''}`}
      onConfirm={() => deleteTags()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      {count !== 1 ? `Delete ${count} tags?` : 'Delete this tag?'}
    </ConfirmModal>
  )
}

DeleteTagsModal.propTypes = {
  form: PropTypes.shape({
    tagType: PropTypes.string.isRequired,
    tagIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default formConnect(DeleteTagsModal, modalFormSelector)
