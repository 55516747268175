import PropTypes from 'prop-types'
import classNames from 'classnames'

import Currency from '../../common/components/Currency.js'
import ExportCSVButton from './ExportCSVButton.js'

export default function RevenueAndCostsStatement({
  isLoading,
  revenueAndCosts,
  onExport,
}) {
  return (
    <div className="row">
      <div className="medium-12 columns">
        <div
          className={classNames('panel panel--analytics clearfix', {
            loading: isLoading,
          })}
        >
          <div className="panel__header panel__header--analytics flex--justify">
            <h3 className="subheader subheader--analytics">
              Revenue and Costs Statement
            </h3>
            {revenueAndCosts && <ExportCSVButton onExport={onExport} />}
          </div>
          <div className="medium-12 columns">
            <p className="graph-loading" />
            {!isLoading && revenueAndCosts && (
              <table className="table--analytics table--revenue-statement">
                <thead>
                  <tr>
                    <th>
                      <span className="column-name first">&nbsp;</span>
                    </th>
                    <th>
                      <span className="column-name">Shipped Orders</span>
                    </th>
                    <th>
                      <span className="column-name">Unshipped Orders</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Total Revenue</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Total Revenue']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Total Revenue']}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Product Total</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Product Total']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Product Total']}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>S & H</strong>
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Shipped['S & H']} />
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Unshipped['S & H']} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tax</strong>
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Shipped.Tax} />
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Unshipped.Tax} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Discounts</strong>
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Shipped.Discounts} />
                    </td>
                    <td>
                      <Currency value={revenueAndCosts.Unshipped.Discounts} />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>
                      <strong>Total Costs</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Total Costs']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Total Costs']}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Product Costs</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Product Costs']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Product Costs']}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Shipping Costs</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Shipping Costs']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Shipping Costs']}
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>
                      <strong>Total Margins</strong>
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Shipped['Total Margins']}
                      />
                    </td>
                    <td>
                      <Currency
                        value={revenueAndCosts.Unshipped['Total Margins']}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const RevenueAndCostShape = PropTypes.shape({
  'Total Revenue': PropTypes.number.isRequired,
  'Product Total': PropTypes.number.isRequired,
  'S & H': PropTypes.number.isRequired,
  Tax: PropTypes.number.isRequired,
  Discounts: PropTypes.number.isRequired,
  'Total Costs': PropTypes.number.isRequired,
  'Product Costs': PropTypes.number.isRequired,
  'Shipping Costs': PropTypes.number.isRequired,
  'Total Margins': PropTypes.number.isRequired,
})

RevenueAndCostsStatement.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  revenueAndCosts: PropTypes.shape({
    Shipped: RevenueAndCostShape.isRequired,
    Unshipped: RevenueAndCostShape.isRequired,
  }),
  onExport: PropTypes.func.isRequired,
}
