import SelectAllButton from '../../common/components/List/SelectAllButton.js'
import {useSelector} from '../../store.js'
import {setSelectedPOIDs} from './purchaseOrderListActions.js'
import {
  poIDListSelector,
  selectedPOIDsSelector,
  allSelectedSelector,
} from './purchaseOrderListSelectors.js'

export default function POSelectAllButton() {
  const poIDList = useSelector(poIDListSelector)
  const selectedPOIDs = useSelector(selectedPOIDsSelector)
  const allSelected = useSelector(allSelectedSelector)

  const indeterminate = !allSelected && selectedPOIDs.length > 0

  return (
    <SelectAllButton
      checked={allSelected}
      indeterminate={indeterminate}
      onChange={(value) => setSelectedPOIDs(value ? poIDList : [])}
    />
  )
}
