import PropTypes from 'prop-types'

import api from '../../../common/api.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setForm,
  updateForm,
  removeForm,
  onlyIfForm,
  getState,
  useSelector,
} from '../../../store.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshPurchaseOrderList} from '../purchaseOrderListActions.js'
import {
  Count,
  IfPlural,
  IfSingle,
  plural,
  Plural,
  PluralBlock,
} from '../../../common/components/Plural.js'
import {suppliersWithoutPOEmailSelector, setPO} from '../../../data/pos.js'
import {getSupplierName} from '../../../data/suppliers.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {navigate} from '../../../common/location.js'

export const MODAL_FORM = 'SEND_TO_SUPPLIER_MODAL_FORM'
export const SEND_TO_SUPPLIER = 'SEND_TO_SUPPLIER'

export function showSendToSupplierModal(poIDs) {
  setForm(MODAL_FORM, {
    poIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function sendPO(id) {
  const {json} = await api.post(
    `/purchase_order/${encodeURIComponent(id)}/send`,
  )

  setPO(json)
}

export async function sendToSupplier() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {poIDs} = modalFormSelector(getState())

    await Promise.all(poIDs.map((id) => sendPO(id)))

    showMessageToast(
      plural(poIDs)`${Count} PO${['s were', ' was']} successfully emailed!`,
    )

    closeModal()

    await refreshPurchaseOrderList()
  } catch (err) {
    updateModalForm({
      serverError: `Error sending PO to supplier: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

export function navigateToSupplierSettings(supplierID) {
  closeModal()

  navigate(['settings', 'supplier', supplierID])
}

function SendToSupplierModal({form}) {
  const supplierWithoutPOEmail = useSelector((state) =>
    suppliersWithoutPOEmailSelector(state, {poIDs: form.poIDs}),
  )

  const cantSend = supplierWithoutPOEmail.length > 0

  return (
    <ConfirmModal
      title={plural(form.poIDs)`Send PO${['s']} to Supplier${['s']}`}
      onConfirm={cantSend ? null : () => sendToSupplier()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
      cancelText={cantSend ? 'Cancel' : 'No'}
    >
      {cantSend ? (
        <PluralBlock array={supplierWithoutPOEmail}>
          <IfPlural>
            <>
              <div className="margin-bottom-15">
                <strong>
                  <Count /> suppliers do not have PO email addresses.
                </strong>{' '}
              </div>
              <div>
                PO requests cannot be sent until email addresses have been
                configured.
              </div>
            </>
          </IfPlural>
          <IfSingle>
            <>
              <div className="margin-bottom-15">
                <strong>{getSupplierName(supplierWithoutPOEmail[0])}</strong>{' '}
                does not have a PO email address assigned to it.
              </div>

              <div>
                <ButtonLink
                  className="mid border-underline v-align-base"
                  onClick={() =>
                    navigateToSupplierSettings(supplierWithoutPOEmail[0].id)
                  }
                >
                  <strong>Add a PO email address for this supplier</strong>
                </ButtonLink>{' '}
                →
              </div>
            </>
          </IfSingle>
        </PluralBlock>
      ) : (
        <PluralBlock array={form.poIDs}>
          Send <Count /> <Plural word="PO" /> to your <Plural word="supplier" />
          ?
        </PluralBlock>
      )}
    </ConfirmModal>
  )
}

SendToSupplierModal.propTypes = {
  form: PropTypes.shape({
    poIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(SendToSupplierModal, modalFormSelector)
