import {useEffect} from 'react'
import classNames from 'classnames'

import {useAutoForm, useSelector} from '../../../store.js'
import {LabelInfoIDsShape} from '../../../common/PropTypes.js'
import {
  getMissingRates,
  setBulkLabelConfig,
} from '../../../data/labelInfos/index.js'
import {setupBulkLabelConfigForm} from './bulkLabelConfigActions.js'
import {
  BULK_LABEL_CONFIG_FORM,
  bulkLabelConfigFormSelector,
} from './bulkLabelConfigSelectors.js'

import BulkSummary from './BulkSummary/index.js'
import BulkErrors from './BulkErrors/index.js'
import BulkUpdateForm from './BulkUpdateForm/index.js'

export default function BulkLabelConfigForm({labelInfoIDs}) {
  useAutoForm(BULK_LABEL_CONFIG_FORM, setupBulkLabelConfigForm())

  useEffect(() => {
    setBulkLabelConfig(labelInfoIDs)
  }, [])

  useEffect(() => {
    getMissingRates(labelInfoIDs)
  }, [labelInfoIDs])

  const form = useSelector(bulkLabelConfigFormSelector)

  if (!form) {
    return null
  }

  return (
    <form
      className={classNames(
        'form',
        'form--shipping-options',
        'meta-labelconfigform-form-bulk',
      )}
    >
      <BulkErrors labelInfoIDs={labelInfoIDs} />
      <BulkUpdateForm labelInfoIDs={labelInfoIDs} />
      <BulkSummary labelInfoIDs={labelInfoIDs} />
    </form>
  )
}

BulkLabelConfigForm.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
