import PropTypes from 'prop-types'

import {
  updateParcel,
  parcelSelector,
  isMultiboxSelector,
} from '../../../../data/labelInfos/index.js'
import {showMultiboxModal} from '../../../OrderListPage/Modals/MultiboxModal/index.js'
import {useSelector} from '../../../../store.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'packages[0].additional_handling'
const VALID_SHIPPER_TYPES = [UPS]

export default function AdditionalHandling({packagesIndex}) {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  packagesIndex = packagesIndex || 0

  const additionalHandling = useSelector(
    (state) =>
      parcelSelector(state, {labelInfoID, packagesIndex}).additional_handling ||
      false,
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <ConfigCheckbox
      Label={() => (
        <>
          <span>Additional Handling</span>
          {isMultibox && (
            <button
              className="btn btn--link margin-bottom-3 margin-left-3 fs-n0"
              type="button"
              onClick={() => showMultiboxModal(labelInfoID, shipperType)}
            >
              <em>(Multiple)</em>
            </button>
          )}
        </>
      )}
      onChange={(checked) => {
        updateParcel(labelInfoID, packagesIndex, {
          additional_handling: checked,
        })

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      checked={additionalHandling}
      disabled={isMultibox}
    />
  )
}

AdditionalHandling.propTypes = {
  packagesIndex: PropTypes.number,
}
