import {
  cartOptionsSelector,
  cartOptionTokensSelector,
} from '../../../../data/carts.js'
import {useSelector} from '../../../../store.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  modalFormSelector,
  SALES_CHANNEL_FILTER_PANEL,
  updateModalForm,
} from './productListFilterModalFunctions.js'
import {usesInventorySelector} from '../../../../data/company.js'

export default function SalesChannelFilterPanel() {
  const form = useSelector(modalFormSelector)
  const cartOptions = useSelector(cartOptionsSelector)
  const cartOptionTokens = useSelector(cartOptionTokensSelector)
  const usesInventory = useSelector(usesInventorySelector)

  if (!usesInventory) {
    return null
  }

  return (
    <FilterPanel
      id={SALES_CHANNEL_FILTER_PANEL}
      label="Sales Channels"
      searchPlaceholder="Search for a sales channel filter..."
      options={cartOptions}
      optionTokens={cartOptionTokens}
      selected={form.cartID}
      onSelect={(option) => {
        const cartID = form.cartID.filter((id) => id !== option.value)

        if (cartID.length === form.cartID.length) {
          cartID.push(option.value)
        }

        updateModalForm({cartID})
      }}
    />
  )
}
