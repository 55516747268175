import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {PACK_MODE} from '../../../common/constants/PackingOrderModal.js'
import {
  setForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import PackPanel from '../PackPanel/index.js'
import {
  packPanelFormSelector,
  PACK_PANEL_BARCODE_SCANNED,
  PACK_PANEL_NEEDS_SERIAL_NUMBER,
} from '../PackPanel/packPanelSelectors.js'
import {finishSerialNumberScan} from '../PackPanel/packPanelActions.js'
import useToggleTimer from '../../../common/hooks/useToggleTimer.js'

export const MODAL_FORM = 'PACKING_ORDER_MODAL_FORM'

export function showPackingOrderModal(orderNumber) {
  setForm(MODAL_FORM, {
    orderNumber,
  })
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

function PackingOrderModal({form}) {
  const [animateSuccess, startSuccessAnimation] = useToggleTimer()
  const packPanelForm = useSelector(packPanelFormSelector) || {}
  const isSerialNumberScanning =
    packPanelForm.scanResult === PACK_PANEL_NEEDS_SERIAL_NUMBER

  return (
    <ConfirmModal
      title="Scan/Verify Package Contents"
      className="modal--lg modal--scan-mode meta-verify-order-modal"
      onCancel={
        isSerialNumberScanning
          ? () => finishSerialNumberScan()
          : () => closeModal()
      }
      cancelText={isSerialNumberScanning ? 'Skip' : 'Cancel'}
      badgeAnimate={animateSuccess}
      badgeContent={
        [PACK_PANEL_BARCODE_SCANNED, PACK_PANEL_NEEDS_SERIAL_NUMBER].includes(
          packPanelForm.scanResult,
        )
          ? 'Scan Successful'
          : null
      }
      helpLink="https://support.ordoro.com/how-to-use-barcode-scanning-to-reduce-packing-errors/"
      helpMetaClass="meta-verify-order-support-link"
    >
      <PackPanel
        orderNumbers={[form.orderNumber]}
        mode={PACK_MODE}
        onScan={() => startSuccessAnimation()}
        inModal
      />
    </ConfirmModal>
  )
}

PackingOrderModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
  }).isRequired,
}

export default onlyIfForm(PackingOrderModal, modalFormSelector)
