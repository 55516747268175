import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import noop from 'lodash/noop.js'

import {ErrorsShape} from '../../../../common/PropTypes.js'
import ButtonIcon from '../../../../common/components/Button/ButtonIcon.js'
import {
  conditionalOptionsSelector,
  getConditionOption,
} from './conditionalOptions.js'
import {useSelector} from '../../../../store.js'

export default function RuleCondition({
  condition,
  ruleID,
  index,
  errors,
  isEditing,
  isRemovable,
  onRemove,
  onChange,
}) {
  onRemove = onRemove || noop
  onChange = onChange || noop

  const conditionalOptions = useSelector(conditionalOptionsSelector)
  const selectedOption = getConditionOption(condition)
  const selectedComparator =
    selectedOption && selectedOption.getComparator(condition)

  if (!selectedOption || !selectedComparator) {
    return (
      <span className="error-message">
        There is a problem interpretting the condition for this rule. Try
        deleting and recreating it.
      </span>
    )
  }

  const hideValue = ['blank', 'not blank'].includes(selectedComparator.value)

  if (!isEditing) {
    return (
      <div>
        {selectedOption.aggregates.length > 0 && (
          <strong>
            {
              (
                selectedOption.aggregates.find(
                  ({value}) => value === selectedOption.getAggregate(condition),
                ) || {display: ''}
              ).display
            }{' '}
          </strong>
        )}
        <strong>{selectedOption.display} </strong>
        <span>{selectedComparator.display} </span>
        {!hideValue && (
          <selectedOption.displayValueComponent condition={condition} />
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-wrap flex-wrap-margin-10">
      {selectedOption.aggregates.length > 0 && (
        <select
          className="select flex-item"
          name="variable"
          value={selectedOption.getAggregate(condition)}
          onChange={(event) =>
            onChange(selectedOption.setAggregate(condition, event.target.value))
          }
        >
          {selectedOption.aggregates.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      )}
      <select
        className="select flex-item w-35"
        name="variable"
        value={selectedOption.value}
        onChange={(event) => {
          const newVariable = event.target.value

          const matchingOptions = conditionalOptions.find(
            ({value}) => value === newVariable,
          )
          onChange(matchingOptions.create())
        }}
      >
        {conditionalOptions.map(({value: optionVariable, display}) => (
          <option key={optionVariable} value={optionVariable}>
            {display}
          </option>
        ))}
      </select>
      {selectedOption.comparators.length === 1 ? (
        <span className="flex-item">
          {selectedOption.comparators[0].display}
        </span>
      ) : selectedOption.comparators.length > 1 ? (
        <select
          className="select flex-item"
          name="comparator"
          value={selectedComparator.value}
          onChange={(event) =>
            onChange(
              selectedOption.setComparator(condition, event.target.value),
            )
          }
        >
          {selectedOption.comparators.map(
            ({value: comparatorValue, display}) => (
              <option key={comparatorValue} value={comparatorValue}>
                {display}
              </option>
            ),
          )}
        </select>
      ) : null}
      {!hideValue && (
        <selectedOption.editValueComponent
          ruleID={ruleID}
          index={index}
          condition={condition}
          onChange={(newValue) =>
            onChange(selectedOption.setValue(condition, newValue))
          }
          error={get(errors, 'conditionValue')}
        />
      )}
      {isRemovable && (
        <ButtonIcon
          className="btn--link flex-item"
          size="xx-sm"
          iconClassName="icon-subtract margin-left-5"
          onClick={() => onRemove()}
        />
      )}
    </div>
  )
}

RuleCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  ruleID: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
  isRemovable: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
}
