import PropTypes from 'prop-types'
import classNames from 'classnames'

import {HK_CONFIRM} from '../../constants/HotKeys.js'
import ButtonPrimary from '../Button/ButtonPrimary.js'
import ButtonSecondary from '../Button/ButtonSecondary.js'
import ErrorMessage from '../ErrorMessage.js'
import BaseModal from './BaseModal/index.js'
import {ensureArray} from '../../ensure.js'

export default function BaseConfirmModal({
  title = 'Confirm',
  className,
  children,
  isSaving,
  isDisabled,
  modalName,
  modalSize,
  confirmText = 'Yes',
  cancelText = 'No',
  onConfirm,
  onCancel,
  error,
  preventClose,
  preventInnerScroll,
  helpLink,
  helpTitle,
  helpMetaClass,
  isGlobalError,
  MiddleButtons,
  middleButtons,
  RightButtons,
  badgeAnimate,
  badgeContent,
  preventHotKeyConfirm,
  noFooter,
  LowerNotice,
  showTryAgain = true,
}) {
  const errors = ensureArray(error)
  return (
    <BaseModal
      modalName={modalName}
      title={title}
      className={`${modalSize ? `modal--${modalSize}` : ''} ${
        isGlobalError ? `modal--global-error` : ''
      } ${errors.length ? 'modal__has-errors' : ''} ${className || ''}`}
      onClose={onCancel}
      preventClose={preventClose}
    >
      {badgeContent && (
        <div
          className={classNames('wrap--scan-success lh-sm', {
            in: badgeAnimate,
          })}
        >
          <span className="i-check-circle inline-block v-align-middle fs-01 margin-right-3" />
          <strong className="fs-00">{badgeContent}</strong>
        </div>
      )}
      <div
        className={classNames('modal__content', {
          'modal__prevent-inner-scroll': preventInnerScroll,
        })}
      >
        <div className="row">
          <div className="medium-12 columns">
            <div className="margin-top-10 margin-bottom-10">{children}</div>
          </div>
        </div>
      </div>
      {LowerNotice && <LowerNotice />}
      {errors.length > 0 && (
        <div className="modal__errors">
          <div className="row">
            <div className="medium-12 columns">
              <div className="alert alert--error alert--sm full-border">
                {errors.map((err, i) => (
                  <ErrorMessage key={i}>{err}</ErrorMessage>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {!noFooter && (
        <div className="modal__footer">
          <div className="wrap--row">
            <div className="medium-12 columns">
              <div className="flex--justify">
                <div className="flex--justify">
                  {onConfirm && (
                    <div className="margin-right-10">
                      <ButtonPrimary
                        type="submit"
                        autoFocus
                        isDisabled={isDisabled}
                        isLoading={isSaving}
                        onClick={() => onConfirm()}
                        hotKey={preventHotKeyConfirm ? null : HK_CONFIRM}
                      >
                        {error && showTryAgain ? 'Try Again' : confirmText}
                      </ButtonPrimary>
                    </div>
                  )}
                  {MiddleButtons && <MiddleButtons />}
                  {middleButtons}
                  {!preventClose && onCancel && (
                    <div className="margin-right-10">
                      <ButtonSecondary onClick={() => onCancel()}>
                        {error ? 'Cancel' : cancelText}
                      </ButtonSecondary>
                    </div>
                  )}
                </div>
                {RightButtons && (
                  <div className="wrap--right-modal-buttons">
                    <RightButtons />
                  </div>
                )}
                {helpLink && (
                  <div>
                    <a
                      className={`btn btn--link no-underline lighter ${helpMetaClass}`}
                      href={helpLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={helpTitle || 'Learn more about using this feature'}
                    >
                      <span className="i-help fs-02 lh-xl" />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </BaseModal>
  )
}

BaseConfirmModal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isSaving: PropTypes.bool,
  isDisabled: PropTypes.bool,
  modalSize: PropTypes.oneOf([
    'x-sm',
    'sm',
    'sm-md',
    '',
    'md',
    'lg',
    'x-lg',
    'xx-lg',
  ]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  preventClose: PropTypes.bool,
  preventInnerScroll: PropTypes.bool,
  helpLink: PropTypes.string,
  helpTitle: PropTypes.string,
  helpMetaClass: PropTypes.string,
  isGlobalError: PropTypes.bool,
  MiddleButtons: PropTypes.func,
  middleButtons: PropTypes.node,
  RightButtons: PropTypes.func,
  badgeAnimate: PropTypes.bool,
  badgeContent: PropTypes.node,
  preventHotKeyConfirm: PropTypes.bool,
  noFooter: PropTypes.bool,
  LowerNotice: PropTypes.func,
  showTryAgain: PropTypes.bool,
}
