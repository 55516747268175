import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'

export const UNSENT_STATUS = 'unsent'
export const SENT_STATUS = 'sent'
export const PARTIAL_STATUS = 'partial'
export const RECEIVED_STATUS = 'received'
export const CANCELLED_STATUS = 'cancelled'

export const PURCHASE_ORDER_STATUS_LIST = [
  UNSENT_STATUS,
  SENT_STATUS,
  PARTIAL_STATUS,
  RECEIVED_STATUS,
  CANCELLED_STATUS,
]

export const PURCHASE_ORDER_STATUS_NAMES = {
  [UNSENT_STATUS]: 'Unsent',
  [SENT_STATUS]: 'Sent',
  [PARTIAL_STATUS]: 'Partial',
  [RECEIVED_STATUS]: 'Received',
  [CANCELLED_STATUS]: 'Cancelled',
}

export const PURCHASE_ORDER_STATII = [
  {
    name: PURCHASE_ORDER_STATUS_NAMES[UNSENT_STATUS],
    slug: UNSENT_STATUS,
    badge: 'U',
  },
  {
    name: PURCHASE_ORDER_STATUS_NAMES[SENT_STATUS],
    slug: SENT_STATUS,
    badge: 'S',
  },
  {
    name: PURCHASE_ORDER_STATUS_NAMES[PARTIAL_STATUS],
    slug: PARTIAL_STATUS,
    badge: 'P',
  },
  {
    name: PURCHASE_ORDER_STATUS_NAMES[RECEIVED_STATUS],
    slug: RECEIVED_STATUS,
    badge: 'R',
  },
  {
    name: PURCHASE_ORDER_STATUS_NAMES[CANCELLED_STATUS],
    slug: CANCELLED_STATUS,
    badge: 'C',
  },
]

export const STATUS_FILTERS = [
  {value: UNSENT_STATUS, display: PURCHASE_ORDER_STATUS_NAMES[UNSENT_STATUS]},
  {value: SENT_STATUS, display: PURCHASE_ORDER_STATUS_NAMES[SENT_STATUS]},
  {value: PARTIAL_STATUS, display: PURCHASE_ORDER_STATUS_NAMES[PARTIAL_STATUS]},
  {
    value: RECEIVED_STATUS,
    display: PURCHASE_ORDER_STATUS_NAMES[RECEIVED_STATUS],
  },
  {
    value: CANCELLED_STATUS,
    display: PURCHASE_ORDER_STATUS_NAMES[CANCELLED_STATUS],
  },
]

export const STATUS_SORT = 'status'
export const PO_ID_SORT = 'po_id'
export const SHIP_TO_SORT = 'ship_to'
export const SUPPLIER_SORT = 'supplier'
export const UPDATED_SORT = 'updated'
export const ESTIMATED_DELIVERY_DATE_SORT = 'estimated_delivery_date'

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_STATUS = UNSENT_STATUS
export const DEFAULT_SORT = `-${UPDATED_SORT}`
export const DEFAULT_PER_PAGE = 10

export const PO_SORT_OPTIONS = [
  {value: STATUS_SORT, display: 'Status'},
  {value: `-${STATUS_SORT}`, display: 'Status (Reversed)'},
  {value: PO_ID_SORT, display: 'Purchase Order ID (A→Z)'},
  {value: `-${PO_ID_SORT}`, display: 'Purchase Order ID (Z→A)'},
  {value: SHIP_TO_SORT, display: 'Receiving Warehouse (A→Z)'},
  {value: `-${SHIP_TO_SORT}`, display: 'Receiving Warehouse (Z→A)'},
  {value: SUPPLIER_SORT, display: 'Supplier (A→Z)'},
  {value: `-${SUPPLIER_SORT}`, display: 'Supplier (Z→A)'},
  {value: `-${UPDATED_SORT}`, display: 'Date Updated (Newest)'},
  {value: UPDATED_SORT, display: 'Date Updated (Oldest)'},
  {
    value: ESTIMATED_DELIVERY_DATE_SORT,
    display: 'Est. Delivery (Chronologic)',
  },
  {
    value: `-${ESTIMATED_DELIVERY_DATE_SORT}`,
    display: 'Est. Delivery (Reverse Chronologic)',
  },
]

export const PO_DETAIL_ITEMS_PANEL = ''
export const PO_DETAIL_GOODS_RECEIPTS_PANEL = 'receipt'
export const PO_DETAIL_TIMELINE_PANEL = 'timeline'
export const PO_DETAIL_PANELS = [
  PO_DETAIL_ITEMS_PANEL,
  PO_DETAIL_GOODS_RECEIPTS_PANEL,
  PO_DETAIL_TIMELINE_PANEL,
]

export const PURCHASE_ORDER_PLURAL_URI_COMPONENT = 'pos'
export const PURCHASE_ORDER_SINGLE_URI_COMPONENT = 'po'
