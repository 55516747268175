import {createSelector} from 'reselect'

import {SETTINGS_PAGE} from '../../../../common/constants/Pages.js'
import {
  ACCOUNTING,
  API_KEYS,
  BARCODE_SCANNERS,
  BILLING,
  GENERAL,
  PACKING_LISTS,
  PAYMENTS,
  PRESETS,
  PRINTING,
  RETURN_ORDER_CODES,
  RULES,
  SALES_CHANNELS,
  SCALES,
  SHIPPERS,
  SHIPPING_INSURANCE,
  SUPPLIERS,
  THREE_P_L,
  USERS,
  WAREHOUSES,
} from '../../../../common/constants/SettingsPanels.js'
import {currentPageSelector} from '../../ui/index.js'
import {
  canManageUserPermissionSelector,
  hasSettingsPermissionSelector,
} from '../../../../data/me.js'

export const settingsSelector = createSelector(
  (state) => state.ui,
  ({settings}) => settings,
)

export const currentSettingsPanelSelector = createSelector(
  settingsSelector,
  (settings) => settings.currentSettingsPanel,
)

export function getSettingsPermissions(hasSettingsPermission, canManageUser) {
  return {
    [ACCOUNTING]: hasSettingsPermission,
    [API_KEYS]: canManageUser,
    [BARCODE_SCANNERS]: true,
    [BILLING]: hasSettingsPermission,
    [GENERAL]: hasSettingsPermission,
    [PACKING_LISTS]: hasSettingsPermission,
    [PAYMENTS]: hasSettingsPermission,
    [PRESETS]: true,
    [PRINTING]: true,
    [RETURN_ORDER_CODES]: hasSettingsPermission,
    [RULES]: hasSettingsPermission,
    [SALES_CHANNELS]: hasSettingsPermission,
    [SCALES]: true,
    [SHIPPERS]: hasSettingsPermission,
    [SHIPPING_INSURANCE]: true,
    [SUPPLIERS]: hasSettingsPermission,
    [THREE_P_L]: true,
    [USERS]: true,
    [WAREHOUSES]: hasSettingsPermission,
  }
}

export const settingsPermissionsSelector = createSelector(
  hasSettingsPermissionSelector,
  canManageUserPermissionSelector,
  getSettingsPermissions,
)

export function inSettingsSelector(state) {
  return currentPageSelector(state) === SETTINGS_PAGE
}
