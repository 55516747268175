import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  PO_DETAIL_ITEMS_PANEL,
  PO_DETAIL_GOODS_RECEIPTS_PANEL,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
} from '../../common/constants/PurchaseOrders.js'
import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  activeGoodsReceiptsSelector,
  isPOLockedSelector,
} from '../../data/pos.js'
import {createNewGoodsReceipt} from '../PurchaseOrderListPage/Modals/GoodsReceiptModal/goodsReceiptModalFunctions.js'
import ItemsPanel from './ItemsPanel.js'
import GoodsReceiptsPanel from './GoodsReceiptsPanel.js'
import {POFormShape, setGoodsReceiptExpandMode} from './poDetailActions.js'
import {
  poIDSelector,
  currentPanelSelector,
  poDetailFormSelector,
} from './poDetailSelectors.js'

export function TabbedPanels({
  poID,
  currentPanel,
  goodsReceiptsCount,
  form,
  isPOLocked,
}) {
  const encodedPOID = encodeURIComponent(poID)
  const shouldCollapseGoodsReceipts =
    form.expandedGoodsReceiptIDs.length > 1 ||
    form.goodsReceiptsExpandMode === EXPAND_MODE_EXPANDED

  return (
    <>
      <div className="wrap--sub-nav wrap--sub-nav-panel">
        <nav className="list--sub-nav">
          <div className="list__item--sub-nav wider">
            <a
              className={`sub-nav__link fs-01 margin-left-0${
                currentPanel === PO_DETAIL_ITEMS_PANEL ? ' active' : ''
              }`}
              href={`#/${PURCHASE_ORDER_SINGLE_URI_COMPONENT}/${encodedPOID}`}
            >
              Items in PO
            </a>
          </div>
          <div className="list__item--sub-nav wider">
            <a
              className={`sub-nav__link fs-01${
                currentPanel === PO_DETAIL_GOODS_RECEIPTS_PANEL ? ' active' : ''
              }`}
              href={`#/${PURCHASE_ORDER_SINGLE_URI_COMPONENT}/${encodedPOID}/${PO_DETAIL_GOODS_RECEIPTS_PANEL}`}
            >
              Goods Receipts ({goodsReceiptsCount})
            </a>
          </div>
        </nav>
        {currentPanel === PO_DETAIL_GOODS_RECEIPTS_PANEL && (
          <div className="flex margin-bottom-5">
            <ExpandAllButton
              className="margin-right-7 btn--expand-goods-receipt-all flex--justify-col"
              shouldCollapse={shouldCollapseGoodsReceipts}
              onExpandAll={() =>
                setGoodsReceiptExpandMode(EXPAND_MODE_EXPANDED)
              }
              onCollapseAll={() =>
                setGoodsReceiptExpandMode(EXPAND_MODE_COLLAPSED)
              }
            />
            <ButtonPrimary
              className="margin-bottom-0"
              size="sm"
              isOutlined
              onClick={() => createNewGoodsReceipt(poID)}
              disabled={isPOLocked}
            >
              Add a Goods Receipt
            </ButtonPrimary>
          </div>
        )}
      </div>

      <div className="wrap--table-panel">
        {currentPanel === PO_DETAIL_ITEMS_PANEL ? (
          <ItemsPanel />
        ) : currentPanel === PO_DETAIL_GOODS_RECEIPTS_PANEL ? (
          <GoodsReceiptsPanel />
        ) : null}
      </div>
    </>
  )
}

TabbedPanels.propTypes = {
  poID: PropTypes.string.isRequired,
  currentPanel: PropTypes.string.isRequired,
  goodsReceiptsCount: PropTypes.number.isRequired,
  form: POFormShape.isRequired,
  isPOLocked: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const poID = poIDSelector(state)
  return {
    poID,
    currentPanel: currentPanelSelector(state),
    goodsReceiptsCount: activeGoodsReceiptsSelector(state, {poID}).length,
    form: poDetailFormSelector(state),
    isPOLocked: isPOLockedSelector(state, {poID}),
  }
}

export default connect(mapStateToProps)(TabbedPanels)
