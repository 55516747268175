import PropTypes from 'prop-types'

import {SelectOptionsShape} from '../../PropTypes.js'
import handleListSelection from '../../handleListSelection.js'
import ButtonPrimary from '../Button/ButtonPrimary.js'
import Checkbox from './Checkbox.js'

export default function MultiSelect({
  options,
  selected,
  scope,
  canSelectAll,
  canDeselectAll,
  ...props
}) {
  scope = scope || 'multi_select'
  canDeselectAll = canDeselectAll !== false
  canSelectAll = canSelectAll !== false

  const action =
    options.length > 1
      ? canDeselectAll && options.length === selected.length
        ? 'deselect'
        : canSelectAll && options.length !== selected.length
          ? 'select'
          : null
      : null

  return (
    <div>
      {action && (
        <ButtonPrimary
          isOutlined
          className="btn--x-sm margin-bottom-15"
          onClick={() => {
            if (action === 'deselect') {
              props.onChange([])
            } else {
              props.onChange(options.map(({value}) => value))
            }
          }}
        >
          {action === 'deselect' ? 'Deselect all' : 'Select all'}
        </ButtonPrimary>
      )}

      {options.map(({value, display}) => (
        <Checkbox
          key={value}
          className="wrap--label-unbold wrap--v-align-inner margin-left-n03 margin-bottom-7"
          name={`${scope}__${value}`}
          label={display}
          checked={selected.includes(value)}
          onClick={(checked, event) =>
            props.onChange(
              handleListSelection({
                value,
                isSelected: checked,
                isShiftKey: event.shiftKey,
                selected,
                list: options.map(({value}) => value),
              }),
            )
          }
        />
      ))}
    </div>
  )
}

MultiSelect.propTypes = {
  options: SelectOptionsShape.isRequired,
  selected: PropTypes.arrayOf(PropTypes.any).isRequired,
  scope: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  canSelectAll: PropTypes.bool,
  canDeselectAll: PropTypes.bool,
}
