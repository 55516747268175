import PropTypes from 'prop-types'
import classnames from 'classnames'

import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'

export default function UpdateOrdersButton({
  labelInfoIDs,
  disabled,
  loading,
  onClick,
}) {
  return (
    <li className="list__item--shipping-options">
      <button
        type="button"
        className={classnames('btn btn--primary btn--sm margin-top-5', {
          'btn--processing': loading,
        })}
        onClick={() => onClick(labelInfoIDs)}
        disabled={disabled}
      >
        Update Orders
      </button>
    </li>
  )
}

UpdateOrdersButton.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
