import PropTypes from 'prop-types'

import {
  ACTION_DEFAULTS,
  APPLY_TAGS,
  NEW_RULE_ID,
} from '../../../common/constants/Rules.js'
import {RuleShape} from '../../../common/PropTypes.js'

import EditingRule from './EditingRule.js'
import {onlyIfAutoForm} from '../../../store.js'
import {getRuleFormName, updateSettingsRulesForm} from './rulesFunctions.js'
import {CONDITIONAL_OPTIONS} from './RuleCondition/conditionalOptions.js'

function setupNewRule({initialRule}) {
  return {
    formName: getRuleFormName(NEW_RULE_ID),
    initialForm: {
      isEditing: true,
      id: NEW_RULE_ID,
      active: true,
      latch: 'order_created',
      condition: CONDITIONAL_OPTIONS[0].create(),
      action: [ACTION_DEFAULTS[APPLY_TAGS]],
      errors: {},
      ...initialRule,
    },
  }
}

function NewRule({form, hideSaveAndCancel}) {
  return (
    <EditingRule
      rule={form}
      onCancel={() => updateSettingsRulesForm({isAddingNew: false})}
      hideSaveAndCancel={hideSaveAndCancel}
    />
  )
}

NewRule.propTypes = {
  form: RuleShape.isRequired,
  hideSaveAndCancel: PropTypes.bool,
  initialRule: PropTypes.object,
}

export default onlyIfAutoForm(NewRule, setupNewRule)
