import {useEffect} from 'react'

import {useSelector, dispatch} from '../../store.js'
import {BATCH_LIST_PAGE} from '../../common/constants/Pages.js'
import {BATCH_PLURAL_URI_COMPONENT} from '../../common/constants/Batches.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {setupBatchListForm} from './batchListActions.js'

export default function BatchListLocationListener() {
  const {
    pathComponents: [resource],
  } = useSelector(locationSelector)

  const isThisPage = resource === BATCH_PLURAL_URI_COMPONENT

  useEffect(() => {
    setupBatchListForm()
  }, [])

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    dispatch(setCurrentPage(BATCH_LIST_PAGE))
  }, [isThisPage])

  return null
}
