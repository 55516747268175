import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
  IfPlural,
  IfSingle,
} from '../../../common/components/Plural.js'
import apiverson from '../../../common/apiverson.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {getRelatedBatches, setBatch} from '../../../data/batches.js'
import {ordersSelector, updateOrder} from '../../../data/orders.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {ORDER_LIST_PAGE} from '../../../common/constants/Pages.js'
import {refreshOrderListAndCounts} from '../../OrderListPage/orderListActions.js'

const MODAL_FORM = 'REMOVE_FROM_BATCH_MODAL'

export async function showRemoveFromBatchModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbersInActiveBatches: [],
    orderNumbersInArchivedBatches: [],
    orderNumbersNotInBatches: [],
    isLoading: true,
    isSaving: false,
    serverError: null,
  })

  try {
    const batchByOrderNumber = await getRelatedBatches(orderNumbers)

    const orderNumbersNotInBatches = []
    const orderNumbersInArchivedBatches = []
    const orderNumbersInActiveBatches = []
    for (const orderNumber of orderNumbers) {
      const batch = batchByOrderNumber[orderNumber]

      if (!batch) {
        orderNumbersNotInBatches.push(orderNumber)
      } else if (batch.archived_date) {
        orderNumbersInArchivedBatches.push(orderNumber)
      } else {
        orderNumbersInActiveBatches.push(orderNumber)
      }
    }

    updateModalForm({
      orderNumbersInArchivedBatches,
      orderNumbersNotInBatches,
      orderNumbersInActiveBatches,
    })
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
    })
  } finally {
    updateModalForm({
      isLoading: false,
    })
  }
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({orderNumbersInActiveBatches}) => {
    const errors = {}

    if (orderNumbersInActiveBatches.length === 0) {
      errors.preventSave = true
    }

    return errors
  },
)

export async function removeFromBatch(referenceID, orderNumbers) {
  const {json: batch} = await apiverson.delete(
    `/batch/${encodeURIComponent(referenceID)}/orders`,
    {
      order_numbers: orderNumbers,
    },
  )

  orderNumbers.map((orderNumber) =>
    updateOrder(orderNumber, {batch_reference_id: null}),
  )

  setBatch(batch)
}

export async function removeFromBatches() {
  try {
    const {orderNumbersInActiveBatches} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const orders = ordersSelector(getState(), {
      orderNumbers: orderNumbersInActiveBatches,
    })

    const orderNumbersByReferenceID = orders.reduce((prev, order) => {
      const referenceID = order.batch_reference_id

      if (!referenceID) {
        return prev
      }

      const list = prev.get(referenceID) || []

      list.push(order.order_number)

      prev.set(referenceID, list)

      return prev
    }, new Map())

    await Promise.all(
      [...orderNumbersByReferenceID.entries()].map(
        ([referenceID, orderNumbers]) =>
          removeFromBatch(referenceID, orderNumbers),
      ),
    )

    closeModal()

    showMessageToast(
      plural(orderNumbersInActiveBatches.length)`Removed order${[
        's',
      ]} from batch.`,
    )

    if (currentPageSelector(getState()) === ORDER_LIST_PAGE) {
      await refreshOrderListAndCounts()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error removing orders from batch: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function RemoveFromBatchModal({form}) {
  const errors = useSelector(errorsSelector)
  const totalToBeRemoved = form.orderNumbersInActiveBatches.length
  const canRemoveFromBatch = totalToBeRemoved > 0
  const initialOrderCount =
    totalToBeRemoved +
    form.orderNumbersInArchivedBatches.length +
    form.orderNumbersNotInBatches.length

  return (
    <ConfirmModal
      title={plural(totalToBeRemoved)`Remove Order${['s']} from Batch`}
      size="sm-md"
      onConfirm={canRemoveFromBatch ? () => removeFromBatches() : null}
      onCancel={() => closeModal()}
      confirmText="Remove"
      cancelText={canRemoveFromBatch ? 'Cancel' : 'Close'}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave || form.isLoading}
      error={form.serverError}
    >
      {form.isLoading && (
        <div className="align-center margin-top-30 margin-bottom-30">
          <span className="list-processing animate-spin v-align-middle" />
        </div>
      )}
      {!form.isLoading && (
        <>
          {!canRemoveFromBatch ? (
            <PluralBlock count={initialOrderCount}>
              <p className="fs-01 margin-bottom-10">
                <IfPlural>None of the selected orders can</IfPlural>
                <IfSingle>The selected order cannot</IfSingle> be removed from
                this batch.
              </p>
            </PluralBlock>
          ) : (
            <p className="fs-01 margin-bottom-10">
              <PluralBlock count={totalToBeRemoved}>
                <strong>
                  Are you sure you want to remove <Count />{' '}
                  <Plural word="order" /> from their batch?
                </strong>
              </PluralBlock>
            </p>
          )}
          {form.orderNumbersInArchivedBatches.length > 0 && (
            <PluralBlock array={form.orderNumbersInArchivedBatches}>
              <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                <span className="i--error fs-01 lh-sm margin-right-3 v-align-middle" />
                <IfPlural>
                  <Count /> orders are assigned to archived batches
                </IfPlural>
                <IfSingle>1 order is assigned to an archived batch</IfSingle>{' '}
                and can’t be removed.
              </p>
            </PluralBlock>
          )}
          {form.orderNumbersNotInBatches.length > 0 && (
            <PluralBlock array={form.orderNumbersNotInBatches}>
              <p className="fs-00 alert alert--error full-border error margin-bottom-10">
                <IfPlural>
                  <Count /> orders are not assigned to batches.
                </IfPlural>
                <IfSingle>1 order is not assigned to a batch.</IfSingle>
              </p>
            </PluralBlock>
          )}
        </>
      )}
    </ConfirmModal>
  )
}

RemoveFromBatchModal.propTypes = {
  form: PropTypes.shape({
    orderNumbersInActiveBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    orderNumbersInArchivedBatches: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    orderNumbersNotInBatches: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(RemoveFromBatchModal, modalFormSelector)
