import PropTypes from 'prop-types'
import {Component} from 'react'
import _ from 'lodash'

import Row from '../../../../common/components/Form/Row.js'
import BaseReconcileForm from '../BaseReconcileForm.js'
import ReconcileSelect from '../ReconcileSelect.js'

export default class ReconcileForm extends Component {
  constructor(props) {
    super(props)

    this.onChange = (value, name) => this.props.updateColumnMapping(value, name)
  }

  render() {
    if (_.isEmpty(this.props.columnsToDataMap)) {
      return null
    }

    return (
      <BaseReconcileForm className="medium-10" {...this.props}>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.fulfillment_type}
            errorMessage={this.props.columnErrors.fulfillment_type}
            name="fulfillment_type"
            label="Fulfillment Type"
            className="medium-12"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.sku}
            errorMessage={this.props.columnErrors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <ReconcileSelect
            columns={this.props.columns}
            value={this.props.columnsToDataMap.default_supplier}
            errorMessage={this.props.columnErrors.default_supplier}
            name="default_supplier"
            label="Default Supplier"
            className="medium-12"
            onChange={this.onChange}
          />
        </Row>
      </BaseReconcileForm>
    )
  }
}

ReconcileForm.propTypes = {
  columns: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
}
