import PropTypes from 'prop-types'
import {useEffect, useRef} from 'react'
import isRegExp from 'lodash/isRegExp.js'

import combokeys from '../combokeys.js'
import {userComboKeysSelector} from '../../data/me.js'
import {HOT_KEY_CODES} from '../constants/HotKeys.js'
import BarcodeScannerListener from './BarcodeScannerListener.js'
import {useSelector} from '../../store.js'

const registry = new Map()

window._registry = registry
export function triggerHotKey(value = '', ...args) {
  for (const [code, funcList] of registry.entries()) {
    if (isRegExp(code)) {
      const match = value.match(code)

      if (!match || !match.groups.args) {
        continue
      }

      try {
        args = [...args, ...JSON.parse(atob(match.groups.args))]
      } catch (err) {
        // noop
      }
    } else if (code !== value) {
      continue
    }

    const funcRef = funcList[funcList.length - 1]

    funcRef.current(...args)

    return false // to prevent bubble
  }
}

function bindComboKeys(userComboKeys) {
  for (let code in userComboKeys) {
    const combos = userComboKeys[code]

    if (combos) {
      combokeys.bind(combos, () => triggerHotKey(code))
    }
  }
}

function unbindComboKeys(userComboKeys) {
  for (let code in userComboKeys) {
    const combos = userComboKeys[code]

    if (combos) {
      combokeys.unbind(combos)
    }
  }
}

export default function HotKeys() {
  const userComboKeys = useSelector(userComboKeysSelector)

  useEffect(() => {
    bindComboKeys(userComboKeys)

    return () => unbindComboKeys(userComboKeys)
  }, [userComboKeys])

  return (
    <BarcodeScannerListener
      onScan={(value) => triggerHotKey(value)}
      capturePaste
      allowHotKeys
    />
  )
}

function register(code, funcRef) {
  if (!HOT_KEY_CODES.includes(code)) {
    throw new Error(`HotKey code: ${code} not found in constants/HotKeys.js`)
  }

  const codeFuncList = registry.get(code)

  if (codeFuncList && !codeFuncList.includes(funcRef)) {
    codeFuncList.push(funcRef)
  } else if (!codeFuncList) {
    registry.set(code, [funcRef])
  }
}

function unregister(code, funcRef) {
  let codeFuncList = registry.get(code)

  if (!codeFuncList) {
    return
  }

  codeFuncList = codeFuncList.filter((fr) => fr !== funcRef)

  if (codeFuncList.length === 0) {
    registry.delete(code)
  } else {
    registry.set(code, codeFuncList)
  }
}

export function HotKeyConnect({code, isDisabled, func, children}) {
  const funcRef = useRef(() => {})

  useEffect(() => {
    funcRef.current = (...args) => {
      if (!isDisabled && func) {
        return func(...args)
      }
    }
  }, [isDisabled, func])

  useEffect(() => {
    register(code, funcRef)

    return () => unregister(code, funcRef)
  }, [code])

  return <>{children}</>
}

HotKeyConnect.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
    .isRequired,
  func: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
}
