import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {analyticsFormSelector} from './analyticsSelectors.js'

export const topInventorySelector = createSelector(
  (state) =>
    get(analyticsFormSelector(state), 'product_statistics__top_inventory'),
  (state) => get(analyticsFormSelector(state), 'product_statistics__all'),
  (topInventoryData, allData) => {
    if (!topInventoryData || !allData) {
      return null
    }

    return {
      hits: topInventoryData.map((bucket) => ({
        name: bucket.product_name,
        sku: bucket.sku,
        total_on_hand: bucket.physical_on_hand,
        value: bucket.value,
      })),
      totalOnHandAll: get(allData, [0, 'physical_on_hand'], 0),
      totalValueAll: get(allData, [0, 'value'], 0),
    }
  },
)
