import {useSelector} from '../../../store.js'
import {
  updateLabelConfig,
  labelTypeSelector,
} from '../../../data/labelInfos/index.js'
import {DHL_ECOMMERCE} from '../../../common/constants/ShipperNames.js'
import ConfigTextInput from './ConfigTextInput.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY = 'return_reason'
const VALID_SHIPPER_TYPES = [DHL_ECOMMERCE]

export default function ReturnReason() {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const labelType = useSelector((state) =>
    labelTypeSelector(state, {labelInfoID}),
  )

  return (
    <ConfigTextInput
      label="Reason for Return"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
      hide={labelType !== 'return'}
    />
  )
}
