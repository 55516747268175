import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {
  isPresent,
  isNumeric,
  isPositiveNumeric,
  isNonZeroPositiveNumeric,
} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import TextInput from '../../../common/components/TextInput.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import Select from '../../../common/components/Form/Select.js'
import {
  getProduct,
  saveProductSupplier,
  setDefaultSupplier,
  productSuppliersSelector,
  productIsDropshippedSelector,
  productUnassignedSupplierOptionsSelector,
  costSelector,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {supplierSelector, getSupplierName} from '../../../data/suppliers.js'
import {usePurchaseOrdersSelector} from '../../../data/company.js'

export const MODAL_FORM = 'EDIT_PRODUCT_SUPPLIER_MODAL'

export function showEditProductSupplierModal(
  sku,
  supplierIDs = [],
  autoFocusOn = null,
) {
  const isDropshipped = productIsDropshippedSelector(getState(), {sku})
  const productCost = costSelector(getState(), {sku})
  const productSuppliers = productSuppliersSelector(getState(), {sku})
  const unassignedSupplierOptions = productUnassignedSupplierOptionsSelector(
    getState(),
    {sku},
  )
  const isNew = supplierIDs.length === 0
  autoFocusOn = autoFocusOn || (isNew ? 'supplierID' : 'supplier_sku')

  const firstProductSupplier = !isNew
    ? productSuppliers.find(({id}) => id === supplierIDs[0])
    : null

  setForm(MODAL_FORM, {
    sku,
    supplierIDs:
      isNew && unassignedSupplierOptions.length > 0
        ? [unassignedSupplierOptions[0].value]
        : supplierIDs,
    autoFocusOn,
    isSingleInputEdit: autoFocusOn && supplierIDs.length > 1,
    supplier_sku: get(firstProductSupplier, 'supplier_sku') || sku,
    supplier_price: String(
      get(firstProductSupplier, 'supplier_price') || productCost || 0,
    ),
    min_order_qty: String(
      get(firstProductSupplier, 'min_order_qty') || (isDropshipped ? 0 : 1),
    ),
    supplier_lead_time: String(
      get(firstProductSupplier, 'supplier_lead_time') || 0,
    ),
    isDropshipped,
    isNew,
    existingProductSuppliersCount: productSuppliers.length,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({
    supplier_price,
    min_order_qty,
    supplier_lead_time,
    isDropshipped,
    isNew,
    supplierIDs,
  }) => {
    const errors = {}

    if (isNew && !supplierIDs[0]) {
      errors.supplierIDs = 'There are no more suppliers to assign'
      errors.preventSave = true
    }

    if (!isNumeric(supplier_price)) {
      errors.supplier_price = 'Supplier Unit Cost must be a number'
      errors.preventSave = true
    }

    if (!isPresent(supplier_price)) {
      errors.supplier_price = 'Supplier Unit Cost is required'
      errors.preventSave = true
    }

    if (
      isPresent(supplier_lead_time) &&
      !isPositiveNumeric(supplier_lead_time)
    ) {
      errors.supplier_lead_time = 'Supplier Lead Time must be a positive number'
      errors.preventSave = true
    }

    if (isDropshipped) {
      if (isPresent(min_order_qty) && !isPositiveNumeric(min_order_qty)) {
        errors.min_order_qty = 'Quantity must be a positive number'
        errors.preventSave = true
      }
    } else {
      if (!isNonZeroPositiveNumeric(min_order_qty)) {
        errors.min_order_qty = 'Quantity must be 1 or more'
        errors.preventSave = true
      }

      if (!isPresent(min_order_qty)) {
        errors.min_order_qty = 'Quantity is required'
        errors.preventSave = true
      }
    }

    return errors
  },
)

export async function updateProductSupplier() {
  try {
    const {
      sku,
      supplierIDs,
      supplier_sku,
      supplier_price,
      min_order_qty,
      supplier_lead_time,
      autoFocusOn,
      isSingleInputEdit,
      isNew,
      existingProductSuppliersCount,
    } = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const params = {}

    if (!isSingleInputEdit || autoFocusOn === 'supplier_sku') {
      params.supplier_sku = supplier_sku
    }

    if (!isSingleInputEdit || autoFocusOn === 'supplier_price') {
      params.supplier_price = Number(supplier_price)
    }

    if (!isSingleInputEdit || autoFocusOn === 'min_order_qty') {
      params.min_order_qty = Number(min_order_qty)
    }

    if (!isSingleInputEdit || autoFocusOn === 'supplier_lead_time') {
      params.supplier_lead_time = Number(supplier_lead_time)
    }

    await Promise.all(
      supplierIDs.map((supplierID) =>
        saveProductSupplier(supplierID, sku, params),
      ),
    )

    if (isNew && existingProductSuppliersCount === 0) {
      await setDefaultSupplier(supplierIDs[0], sku)
    }

    closeModal()

    showMessageToast('Saved product supplier')

    await getProduct(sku)
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditProductSupplierModal({form}) {
  const firstSupplierID = form.supplierIDs[0]

  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)

  const firstSupplier = useSelector((state) =>
    firstSupplierID
      ? supplierSelector(state, {supplierID: firstSupplierID})
      : null,
  )

  const errors = useSelector(errorsSelector)

  const unassignedSupplierOptions = useSelector((state) =>
    productUnassignedSupplierOptionsSelector(state, {
      sku: form.sku,
    }),
  )

  return (
    <ConfirmModal
      title="Update Supplier/Dropshipper Assignment"
      modalSize=""
      onConfirm={() => updateProductSupplier()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {form.isNew ? (
          <li className="list__item list__item--form">
            <Select
              label="Supplier"
              id="new_supplier_id"
              value={form.supplierIDs[0]}
              onChange={(value) =>
                updateModalForm({supplierIDs: [Number(value)]})
              }
              errorMessage={errors.supplierIDs}
            >
              {unassignedSupplierOptions.map(({value, display}) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
              {unassignedSupplierOptions.length === 0 && (
                <option selected disabled>
                  No Suppliers to Assign
                </option>
              )}
            </Select>
          </li>
        ) : (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              {form.supplierIDs.length === 1 ? (
                <>
                  <strong>Updating 1 supplier:</strong>
                  <div>{getSupplierName(firstSupplier)}</div>
                </>
              ) : (
                <strong>Updating {form.supplierIDs.length} suppliers</strong>
              )}
            </div>
          </li>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'supplier_sku') && (
          <li className="list__item list__item--form">
            <TextInput
              label="Supplier SKU"
              id="supplier-sku"
              value={form.supplier_sku}
              onChange={(value) => updateModalForm({supplier_sku: value})}
              errorMessage={errors.supplier_sku}
              autoFocus={form.autoFocusOn === 'supplier_sku'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'supplier_price') && (
          <li className="list__item list__item--form">
            <CurrencyInput
              label="Supplier Unit Cost"
              className="biggie margin-bottom-0"
              width="sm"
              id="supplier-price"
              value={form.supplier_price}
              onChange={(value) =>
                updateModalForm({supplier_price: `${value}`})
              }
              errorMessage={errors.supplier_price}
              autoFocus={form.autoFocusOn === 'supplier_price'}
            />
          </li>
        )}
        {usePurchaseOrders &&
          (!form.isSingleInputEdit || form.autoFocusOn === 'min_order_qty') && (
            <li className="list__item list__item--form">
              <label htmlFor="min-order-qty">Min Order Qty</label>
              <NumberInput
                id="min-order-qty"
                value={form.min_order_qty}
                onChange={(value) =>
                  updateModalForm({
                    min_order_qty: `${value}`,
                  })
                }
                isInvalid={!!errors.min_order_qty}
                autoFocus={form.autoFocusOn === 'min_order_qty'}
              />
              {errors.min_order_qty && (
                <small className="error">{errors.min_order_qty}</small>
              )}
            </li>
          )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'supplier_lead_time') && (
          <li className="list__item list__item--form">
            <label htmlFor="supplier_lead_time">Lead Time (Days)</label>
            <NumberInput
              id="supplier_lead_time"
              value={form.supplier_lead_time}
              onChange={(value) =>
                updateModalForm({
                  supplier_lead_time: `${value}`,
                })
              }
              isInvalid={!!errors.supplier_lead_time}
              autoFocus={form.autoFocusOn === 'supplier_lead_time'}
            />
            {errors.supplier_lead_time && (
              <small className="error">{errors.supplier_lead_time}</small>
            )}
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditProductSupplierModal.propTypes = {
  form: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    supplierIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    supplier_sku: PropTypes.string.isRequired,
    supplier_price: PropTypes.string.isRequired,
    min_order_qty: PropTypes.string.isRequired,
    supplier_lead_time: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isDropshipped: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    existingProductSuppliersCount: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditProductSupplierModal, modalFormSelector)
