import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import FormCheckbox from '../../../common/components/Form/Checkbox.js'
import {
  debouncedSaveUserSettings,
  updateUserSettings,
  userSettingSelector,
} from '../../../data/me.js'
import SettingsPanel from '../SettingsPanel.js'
import BarcodeScannersFeaturePage from './BarcodeScannersFeaturePage.js'
import {useSelector} from '../../../store.js'
import {planSelector} from '../../../data/company.js'
import {STARTER_PLAN} from '../../../common/constants/Plans.js'
import {showCreatePresetBarcodePDFModal} from '../../LabelConfig/Modals/CreatePresetBarcodePDFModal.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'

function BarcodeScanners({canPlayScannerSounds}) {
  const plan = useSelector(planSelector)
  const showFocusAlert = useSelector((state) =>
    userSettingSelector(state, {
      setting: 'show_focus_alert',
      defaultValue: true,
    }),
  )

  if (plan === STARTER_PLAN) {
    return <BarcodeScannersFeaturePage />
  }

  return (
    <SettingsPanel
      header="Barcode Scanners"
      subHeader={
        <div>
          <p className="fs-01 margin-bottom-20">
            Keep fulfillment and goods receipt errors to a minimum with our
            barcode scanning functionality.
          </p>
          <hr className="margin-bottom-0" />
        </div>
      }
    >
      <div className="row margin-bottom-30">
        <div className="medium-8 columns">
          <dl className="list margin-bottom-30">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Recommended barcode scanner type:
            </dt>
            <dd className="fs-01 margin-bottom-0">1D CCD USB Scanner</dd>
          </dl>
          <dl className="list margin-bottom-30">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Tutorials
            </dt>
            <dd className="fs-00 margin-bottom-15">
              <ul className="list square">
                <li className="list__item">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/how-to-use-your-barcode-scanner-in-ordoro/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    General barcode scanner usage in Ordoro
                  </a>
                </li>
                <li className="list__item">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/how-to-use-barcode-scanning-to-reduce-packing-errors/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    How to verify contents for a single order
                  </a>
                </li>
                <li className="list__item">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/how-to-verify-multiple-orders-via-barcode-scanning/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    How to verify contents for a batch of orders
                  </a>
                </li>
                <li className="list__item">
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/can-i-scan-in-bar-codes-for-po-goods-receipts/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    How to verify items for a PO goods receipt
                  </a>
                </li>
              </ul>
            </dd>
          </dl>
          <dl className="list divider--top padding-top-25">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Notification Sounds
            </dt>
            <dd className="fs-00 margin-bottom-20">
              If you enable this option, Ordoro will play a sound after a
              successful barcode verification scan, when there is an error with
              a scan, and when an order has been fully verified. Make sure you
              have your speakers on and your volume up.
            </dd>

            <dd className="fs-00 margin-bottom-20">
              <FormCheckbox
                label="Enable notification sounds"
                name="can_play_scanner_sounds"
                checked={canPlayScannerSounds}
                onChange={() => {
                  updateUserSettings({
                    can_play_scanner_sounds: !canPlayScannerSounds,
                  })

                  debouncedSaveUserSettings()
                }}
              />
            </dd>
          </dl>
          <dl className="list divider--top padding-top-25">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Active Tab Lost Focus Alert
            </dt>
            <dd className="fs-00 margin-bottom-20">
              If you enable this option, a notice will display if Ordoro is not
              the active tab during barcode scanning operations.
            </dd>

            <dd className="fs-00 margin-bottom-20">
              <FormCheckbox
                label="Enable active tab alert"
                name="show_focus_alert"
                checked={showFocusAlert}
                onChange={() => {
                  updateUserSettings({
                    show_focus_alert: !showFocusAlert,
                  })

                  debouncedSaveUserSettings()
                }}
              />
            </dd>
          </dl>
          <dl className="list divider--top padding-top-25">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Barcode Action Shortcut Printout
            </dt>
            <dd className="fs-00 margin-bottom-10">
              Print out this sheet and scan barcodes on it to complete some
              on-screen tasks without having to move back to your mouse or
              keyboard.
            </dd>
            <dd className="fs-00 margin-bottom-15">
              <strong>
                <a
                  className="btn btn--primary btn--sm btn--primary-ol"
                  href="https://storage.googleapis.com/ordoro-pappy-assets/public/ordoro-barcode-action-shortcuts.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Download PDF ⇣
                </a>
              </strong>
            </dd>
          </dl>
          <dl className="list divider--top padding-top-25">
            <dt className="list__title fs-01 lh-sm margin-bottom-10">
              Barcode Preset Shortcut Printout
            </dt>
            <dd className="fs-00 margin-bottom-10">
              Prepare this sheet and scan the barcodes on it to apply presets to
              orders without having to move back to your mouse or keyboard.
            </dd>
            <dd className="fs-00 margin-bottom-15">
              <strong>
                <ButtonPrimary
                  size="sm"
                  isOutlined
                  onClick={() => showCreatePresetBarcodePDFModal()}
                >
                  Create Preset PDF
                </ButtonPrimary>
              </strong>
            </dd>
          </dl>
        </div>
      </div>
    </SettingsPanel>
  )
}

BarcodeScanners.propTypes = {
  canPlayScannerSounds: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    canPlayScannerSounds:
      userSettingSelector(state, {
        setting: 'can_play_scanner_sounds',
      }) || false,
  }
}

export default connect(mapStateToProps)(BarcodeScanners)
