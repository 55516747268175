import flattenDeep from 'lodash/flattenDeep.js'

function growTree(sku, quantity, edges, nodes) {
  const node = nodes.find(({id}) => id === sku)

  if (!node) {
    return {
      sku,
      quantity,
      children: [],
    }
  }

  const childEdges = edges.filter(({source}) => source === sku)

  return {
    ...node,
    sku: node.id,
    quantity,
    children: childEdges.map(({dest, weight}) =>
      growTree(dest, weight, edges, nodes),
    ),
  }
}

// returns an a flattened view of the kit graph with only leaf components and
// their quantities
function leavesWithBranchQuantities(productGraph, quantity) {
  const {id: _id, children, ...productInfo} = productGraph

  if (children.length > 0) {
    return children.map((child) =>
      leavesWithBranchQuantities(child, quantity * child.quantity),
    )
  }

  return [
    {
      ...productInfo,
      quantity,
    },
  ]
}

export function getLeafComponents(rootProductSku, kitGraph) {
  const components = {}
  const {edges, nodes} = kitGraph

  const productGraph = growTree(rootProductSku, 1, edges, nodes)

  const leafSums = flattenDeep(
    leavesWithBranchQuantities(productGraph, productGraph.quantity),
  )

  for (const {sku, quantity, ...productInfo} of leafSums) {
    if (sku in components) {
      components[sku].quantity += quantity
    } else {
      components[sku] = {
        ...productInfo,
        sku,
        quantity,
      }
    }
  }

  delete components[rootProductSku]

  return Object.values(components)
}
