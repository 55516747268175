import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function UntaggedItem({
  disabled,
  selected,
  onToggleUntaggedFilter,
  isFocused,
}) {
  return (
    <li className="list__item list__item--dropdown">
      <span
        className={classNames(
          'label--selectable label--tag label--untagged flex--justify-nowrap margin-bottom-0',
          {
            'list__link--dropdown-arrow-focus': isFocused,
            disabled,
          },
        )}
        role="checkbox"
        aria-checked={selected}
        tabIndex={0}
        onClick={() => {
          if (!disabled && onToggleUntaggedFilter) {
            onToggleUntaggedFilter(!selected)
          }
        }}
        onKeyPress={() => {
          /** noop **/
        }}
        data-dropdown-prevent-close
      >
        <span className="flex--justify-nowrap">
          <span>
            <input
              className="fs-00"
              type="checkbox"
              checked={selected}
              disabled={disabled}
              readOnly
            />
          </span>
          <span className="margin-left-5">Untagged</span>
        </span>
      </span>
    </li>
  )
}

UntaggedItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  onToggleUntaggedFilter: PropTypes.func.isRequired,
}
