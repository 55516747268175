import PropTypes from 'prop-types'

import {OrderShape} from '../../../../../../common/PropTypes.js'
import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'
import ShipDate from '../../Fields/ShipDate.js'
import OrderSupplier from '../../Fields/OrderSupplier.js'
import OrderWarehouse from '../../Fields/OrderWarehouse.js'
import ListItem from './Fields/ListItem.js'
import {showMarkAsAwaitingFulfillmentModal} from '../../../../../OrderListPage/Modals/MarkAsAwaitingFulfillmentModal.js'

export default function Dropshipment({order, isCancelled}) {
  return (
    <div className="margin-bottom-15">
      <h4 className="list__title--order-data">Dropshipment Requested</h4>
      <ul className="list--order-data list--no-style">
        <li className="list__item--order-data">
          <strong className="margin-right-3">Order Supplier:</strong>
          <OrderSupplier orderNumber={order.order_number} />
        </li>
        <li className="list__item--order-data">
          <strong className="margin-right-3">Date:</strong>
          <ShipDate date={order.dropshipping_info.requested_date} />
        </li>

        {order.dropshipping_info.requested_shipping_carrier && (
          <li className="list__item--order-data">
            <strong className="margin-right-3">Carrier To Be Used:</strong>
            {order.dropshipping_info.requested_shipping_carrier}
          </li>
        )}
        {order.dropshipping_info.requested_shipping_account && (
          <li className="list__item--order-data">
            <strong className="margin-right-3">
              Carrier Account To Be Used:
            </strong>
            {order.dropshipping_info.requested_shipping_account}
          </li>
        )}
        <li className="list__item--order-data">
          <strong className="margin-right-3">
            Shipping Method To Be Used:
          </strong>
          {(order.dropshipping_info.requested_shipping_method &&
            order.dropshipping_info.requested_shipping_method.trim()) || (
            <em>None specified</em>
          )}
        </li>
        {order.dropshipping_info.instructions && (
          <li className="list__item--order-data">
            <strong className="margin-right-3">Instructions:</strong>
            {order.dropshipping_info.instructions}
          </li>
        )}
        <li className="list__item--order-data">
          <strong className="margin-right-3">Show Price:</strong>
          {order.dropshipping_info.show_price ? 'Yes' : 'No'}
        </li>
        <li className="list__item--order-data">
          <strong className="margin-right-3">Order Warehouse:</strong>
          <OrderWarehouse orderNumber={order.order_number} />
        </li>
        {!isCancelled && (
          <ListItem>
            <ButtonLink
              onClick={() =>
                showMarkAsAwaitingFulfillmentModal([order.order_number])
              }
            >
              Mark as Awaiting Fulfillment
            </ButtonLink>
          </ListItem>
        )}
      </ul>
    </div>
  )
}

Dropshipment.propTypes = {
  order: OrderShape.isRequired,
  isCancelled: PropTypes.bool.isRequired,
}
