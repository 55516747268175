import uniq from 'lodash/uniq.js'

import api from '../../../common/api.js'
import {skuMappingsSelector} from '../../selectors/data/productKits.js'
import {showGlobalError} from '../../../ordoro/GlobalErrorMessage.js'
import {dispatch, getState} from '../../../store.js'

export const BASE = 'ordoro/data/products'
export const SET_INVENTORY_GRAPH = `${BASE}/SET_INVENTORY_GRAPH`

export function setKitGraph(kitGraph) {
  return {
    type: SET_INVENTORY_GRAPH,
    payload: kitGraph,
  }
}

export async function getProductKit(sku) {
  try {
    const response = await api.get(
      `/product/${encodeURIComponent(sku)}/kit_graph/`,
      {components_type: 'descendants_only'},
    )
    dispatch(setKitGraph(response.json))
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting product kit.',
        details: err.message,
      },
      err,
    )
  }
}

export async function ensureProductKitsLoaded(productSKUs) {
  try {
    const skuMappings = skuMappingsSelector(getState())
    const loadedSKUs = new Set(Object.keys(skuMappings))
    const stillNeeded = uniq(
      productSKUs.filter((productSKU) => !loadedSKUs.has(productSKU)),
    )
    if (!stillNeeded.length) {
      return
    }
    await Promise.all(stillNeeded.map((sku) => getProductKit(sku)))
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting product kit.',
        details: err.message,
      },
      err,
    )
  }
}
