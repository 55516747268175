import PropTypes from 'prop-types'

import Currency, {isTBD} from '../../common/components/Currency.js'

export default function Total({cost = 0, disabled, currencySymbol, children}) {
  let value

  if (disabled) {
    value = '--'
  } else if (isTBD(cost)) {
    value = 'TBD'
  } else {
    value = <Currency value={cost} currencySymbol={currencySymbol} />
  }

  return (
    <dl className="list list--total flex--justify-col">
      {!disabled && children}
      <dt className="list__title fs-01 lh-md margin-bottom-0">
        <span>Total:</span> <span className="success-txt">{value}</span>
      </dt>
    </dl>
  )
}

Total.propTypes = {
  cost: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  children: PropTypes.node,
}
