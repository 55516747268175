import {useEffect} from 'react'
import analytics from '../../common/analytics/index.js'
import {useSelector} from '../../store.js'
import {usesAppsSelector} from '../../data/company.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'

export default function ProductAnalyticsFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)

  useEffect(() => {
    analytics.trackEvent('analytics_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
      <div
        className="i-lock-closed fs-04 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-02 lh-md  margin-bottom-15">
        <strong>Analytics is not enabled in your account.</strong>
      </p>
      {usesApps ? (
        <>
          <p className="fs-01 margin-bottom-20">
            To gain access to this feature, you need to upgrade to the Advanced
            or Premium plan for the Shipping App.
          </p>
          <div className="margin-bottom-10">
            <ButtonPrimary
              className="meta--feature-lock-supplier-mgmt"
              alt
              onClick={() => showUpgradeModal()}
            >
              Upgrade Your Plan
            </ButtonPrimary>
          </div>
        </>
      ) : (
        <>
          <p className="fs-01 margin-bottom-20">
            To access this feature,{' '}
            <a
              className="btn--link mid border-underline meta-feature-lock-supplier-mgmt"
              href="https://www.ordoro.com/product-expert-consultation"
              target="_blank"
              rel="noopener noreferrer"
            >
              reach out to us
            </a>
            .
          </p>
          <div className="margin-bottom-10">
            <a
              className="btn btn--primary btn--primary-alt btn--md meta--feature-lock-supplier-mgmt"
              href="https://www.ordoro.com/product-expert-consultation"
              target="_blank"
              rel="noopener noreferrer"
            >
              Talk to a Product Expert
            </a>
          </div>
        </>
      )}
    </div>
  )
}
