import NumberInput from '../../../common/components/Form/NumberInput.js'
import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {
  CANADA_POST,
  DHL_ECOMMERCE,
  DHL,
  ENDICIA,
} from '../../../common/constants/ShipperNames.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import className from '../../../common/className.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [CANADA_POST, DHL_ECOMMERCE, DHL, ENDICIA]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__insured_value`]
    : []
}

function getDHLInsuredValueError(config) {
  const insuredValue = Number(config.dhl__insured_value)

  if (!insuredValue) {
    return null
  }

  const totalDeclaredValue = config.packages.reduce(
    (prev, {dhl__declared_value}) => prev + (Number(dhl__declared_value) || 0),
    0,
  )

  if (totalDeclaredValue && totalDeclaredValue < insuredValue) {
    return 'Declared value can not be less than insured value'
  }

  return null
}

export function insuredValueErrorSelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  if (shipperType === DHL) {
    return getDHLInsuredValueError(labelConfig)
  }

  return null
}

export default function InsuredValue() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const insuredValue = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID})[labelProperty] || '',
  )
  const error = useSelector((state) =>
    insuredValueErrorSelector(state, {labelInfoID, shipperType}),
  )

  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes: VALID_SHIPPER_TYPES,
  })

  if (!VALID_SHIPPER_TYPES.includes(shipperType)) {
    return null
  }

  return (
    <li
      className={className`list__item--shipping-options btm-offset-7 ${formatMetaClassName(
        labelProperty,
      )}`}
    >
      <div className="wrap--edit-preset-form-input">
        <div className="flex">
          <label className="label--bold" htmlFor="insured_value">
            Insured Value
          </label>
        </div>
        <NumberInput
          type="text"
          id={`${labelInfoID}__${labelProperty}`}
          value={insuredValue}
          disabled={isLabelEnableMode && !isLabelPropertyEnabled}
          onChange={(value) => {
            updateLabelConfig(labelInfoID, {
              [labelProperty]: value,
            })

            onChange()
          }}
        />
      </div>
      {error && <small className="error">{error}</small>}
      <EnableLabelProperty labelProperty={labelProperty} />
    </li>
  )
}
