import {useSelector} from '../../store.js'
import ExpandAllButton, {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {setExpandedReferenceIDs, setExpandMode} from './batchListActions.js'
import {
  expandModeSelector,
  expandedReferenceIDsSelector,
} from './batchListSelectors.js'

export default function MOExpandAll() {
  const expandMode = useSelector(expandModeSelector)
  const expandedBatchCount = useSelector(
    (state) => expandedReferenceIDsSelector(state).length,
  )

  const shouldCollapse =
    expandedBatchCount > 1 || expandMode === EXPAND_MODE_EXPANDED

  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint={shouldCollapse ? 'Collapse all Batches' : 'Expand all Batches'}
    >
      <ExpandAllButton
        className="meta-rmalistheader-button-expandall"
        shouldCollapse={shouldCollapse}
        onExpandAll={() => {
          setExpandedReferenceIDs([])
          setExpandMode(EXPAND_MODE_EXPANDED)
        }}
        onCollapseAll={() => {
          setExpandedReferenceIDs([])
          setExpandMode(EXPAND_MODE_COLLAPSED)
        }}
      />
    </li>
  )
}
