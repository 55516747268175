import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../common/constants/index.js'
import {apiKeyIsNewSelector} from '../../../redux/selectors/ui/settings/apiKeys.js'

import Form from './Form.js'

export function NewForm({isNew}) {
  const editLink = `/settings/api_key/${NEW_ID}/`

  return (
    <li id={editLink} className="settings-list-item add-item clearfix expanded">
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={`#${editLink}`}
            title="Add an API Key"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Add an API Key</span>
          </a>
        </p>
      </div>
      {isNew && <Form apiKeyID={NEW_ID} />}
    </li>
  )
}

NewForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isNew: apiKeyIsNewSelector(state),
  }
}

export default connect(mapStateToProps)(NewForm)
