// import endOfDay from 'date-fns/endOfDay'
import isEmpty from 'lodash/isEmpty.js'
import isEqual from 'lodash/isEqual.js'
import {createSelector} from 'reselect'

import {isEmptyValue} from '../../common/utils.js'
import {formsSelector} from '../../store.js'
import {
  DATE_CREATED,
  DATE_DELIVER_BY,
  DATE_DROPSHIPPED,
  DATE_ORDERED,
  DATE_RANGE_FILTER_OPTIONS,
  DATE_SHIPPED,
  DATE_SHIP_BY,
} from '../../common/constants/OrderListDateRangeFilters.js'
import {
  AWAITING_FULFILLMENT,
  DEFAULT_EXPAND_MODE,
  DEFAULT_PER_PAGE,
  DEFAULT_SORT,
  DEFAULT_STATUS,
  DROPSHIPMENT_REQUESTED,
  ORDER_ALLOCATION_STATII,
  ORDER_PLURAL_URI_COMPONENT,
  ORDER_SINGLE_URI_COMPONENT,
  ORDER_STATII,
  ORDER_STATUS_BY_SLUG,
  SHIPPED,
} from '../../common/constants/Orders.js'
import {TAG_FILTER_OR} from '../../common/constants/Tags.js'
import {
  ensureArray,
  ensureArrayOfNumbers,
  ensureBoolean,
  ensureTroolean,
} from '../../common/ensure.js'
import {stringifyURL} from '../../common/querystring.js'
import {
  orderTagsSelector,
  orderTagsSortedByNameSelector,
} from '../../data/orderTags.js'
import {
  useDropshippingSelector,
  useInventoryAllocationSelector,
  usePartialInventoryAllocationSelector,
} from '../../data/company.js'
import {
  allOrdersSelector,
  canUseDeliverByDateSelector,
  canUseOrdersSelector,
  getOrdersActions,
} from '../../data/orders.js'
import {canUseReturnOrdersSelector} from '../../redux/selectors/data/returnOrders.js'
import {cartsSelector} from '../../data/carts.js'
import {hasOrderManageRevisionPermissionSelector} from '../../data/me.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {orderDetailOrderNumberSelector} from '../OrderDetailPage/orderDetailSelectors.js'
import {DETAIL_MODE, LIST_MODE} from '../../common/constants/index.js'
import {toZonedTime} from '../../common/zonedTime.js'

export const ORDER_LIST = 'ORDER_LIST'

export function orderListFormSelector(state) {
  return formsSelector(state)[ORDER_LIST]
}

export const visibleOrderNumbersSelector = createSelector(
  orderListFormSelector,
  ({visibleOrderNumbers} = {}) =>
    visibleOrderNumbers || visibleOrderNumbersSelector.default,
)
visibleOrderNumbersSelector.default = []

export const isLoadingSelector = createSelector(
  orderListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const countSelector = createSelector(
  orderListFormSelector,
  ({count} = {}) => count || 0,
)

export const expandModeSelector = createSelector(
  orderListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedOrderNumbersSelector = createSelector(
  orderListFormSelector,
  ({expandedOrderNumbers} = {}) =>
    expandedOrderNumbers || expandedOrderNumbersSelector.default,
)
expandedOrderNumbersSelector.default = []

export const isAllExpandedSelector = createSelector(
  visibleOrderNumbersSelector,
  expandedOrderNumbersSelector,
  (visibleOrderNumbers, expandedOrderNumbers) =>
    visibleOrderNumbers.length !== 0 &&
    visibleOrderNumbers.length === expandedOrderNumbers.length,
)

export const selectedOrderNumbersSelector = createSelector(
  orderListFormSelector,
  ({selectedOrderNumbers} = {}) =>
    selectedOrderNumbers || selectedOrderNumbersSelector.default,
)
selectedOrderNumbersSelector.default = []

export const allSelectedSelector = createSelector(
  visibleOrderNumbersSelector,
  selectedOrderNumbersSelector,
  (visibleOrderNumbers, selected) =>
    visibleOrderNumbers.length > 0 &&
    visibleOrderNumbers.length === selected.length,
)

export const indeterminateSelectedSelector = createSelector(
  allSelectedSelector,
  selectedOrderNumbersSelector,
  (allSelected, selectedOrderNumbers) =>
    !allSelected && selectedOrderNumbers.length > 0,
)

export const activeOrderNumbersSelector = createSelector(
  selectedOrderNumbersSelector,
  orderDetailOrderNumberSelector,
  (selectedOrderNumbers, orderDetailOrderNumber) =>
    orderDetailOrderNumber ? [orderDetailOrderNumber] : selectedOrderNumbers,
)

export const activeOrdersSelector = createSelector(
  activeOrderNumbersSelector,
  (state) => allOrdersSelector(state),
  (activeOrderNumbers, orders) =>
    activeOrderNumbers.reduce((prev, orderNumber) => {
      if (orders[orderNumber]) {
        prev.push(orders[orderNumber])
      }

      return prev
    }, []),
)

export const selectedOrdersSelector = createSelector(
  (state) => allOrdersSelector(state),
  selectedOrderNumbersSelector,
  (orders, orderNumbers) =>
    orderNumbers.map((orderNumber) => orders[orderNumber]),
)

export const selectedOrderActionsSelector = createSelector(
  selectedOrdersSelector,
  canUseReturnOrdersSelector,
  cartsSelector,
  useInventoryAllocationSelector,
  hasOrderManageRevisionPermissionSelector,
  (
    orders,
    canUseReturnOrders,
    carts,
    useInventoryAllocation,
    hasOrderManageRevisionPermission,
  ) =>
    getOrdersActions(
      orders,
      canUseReturnOrders,
      carts,
      useInventoryAllocation,
      hasOrderManageRevisionPermission,
    ),
)

export const hasSelectedOrderSelector = (state) =>
  selectedOrderNumbersSelector(state).length > 0

export const orderTagQuantityMapSelector = createSelector(
  activeOrdersSelector,
  (state) => orderTagsSortedByNameSelector(state),
  (selectedOrders, orderTags) => {
    const orderTagQuantityMap = orderTags.reduce(
      (prev, tag) => ({...prev, [tag.id]: 0}),
      {},
    )

    selectedOrders.forEach((order) => {
      order.tags.forEach((tag) => {
        orderTagQuantityMap[tag.id] += tag.pending !== -1 ? 1 : 0
      })
    })

    return Object.entries(orderTagQuantityMap).reduce(
      (prev, [tagID, selectedQuantity]) => {
        const diff = selectedOrders.length - selectedQuantity
        if (diff === 0) {
          prev[tagID] = 'all'
        } else if (diff === selectedOrders.length) {
          prev[tagID] = 'none'
        } else {
          prev[tagID] = 'some'
        }

        return prev
      },
      {},
    )
  },
)

export function getOrderDateRangeParams(
  startDate,
  endDate,
  dateFilterType,
  intervalType,
  intervalAmount,
  intervalTrunc,
  timezone,
) {
  const params = {timezone}

  if (startDate) {
    startDate = new Date(startDate)

    const startDateString = startDate.toISOString()

    if (dateFilterType === DATE_CREATED) {
      params.created_after = startDateString
    }

    if (dateFilterType === DATE_ORDERED) {
      params.order_placed_after = startDateString
    }

    if (dateFilterType === DATE_SHIPPED) {
      params.shipped_after = startDateString
    }

    if (dateFilterType === DATE_DROPSHIPPED) {
      params.dropshipped_after = startDateString
    }

    if (dateFilterType === DATE_DELIVER_BY) {
      params.deliver_by_after = startDateString
    }

    if (dateFilterType === DATE_SHIP_BY) {
      params.ship_by_after = startDateString
    }
  }

  if (endDate) {
    endDate = new Date(endDate)

    // if (timezone) {
    //   endDate = fromZonedTime(endDate, timezone)
    // }

    const endDateString = endDate.toISOString()

    if (dateFilterType === DATE_CREATED) {
      params.created_before = endDateString
    }

    if (dateFilterType === DATE_ORDERED) {
      params.order_placed_before = endDateString
    }

    if (dateFilterType === DATE_SHIPPED) {
      params.shipped_before = endDateString
    }

    if (dateFilterType === DATE_DROPSHIPPED) {
      params.dropshipped_before = endDateString
    }

    if (dateFilterType === DATE_DELIVER_BY) {
      params.deliver_by_before = endDateString
    }

    if (dateFilterType === DATE_SHIP_BY) {
      params.ship_by_before = endDateString
    }
  }

  if (intervalType) {
    intervalTrunc = ensureBoolean(intervalTrunc)

    if (dateFilterType === DATE_CREATED) {
      params.created_interval_type = intervalType
      params.created_interval_amount = intervalAmount
      params.created_interval_trunc = intervalTrunc
    }

    if (dateFilterType === DATE_ORDERED) {
      params.order_placed_interval_type = intervalType
      params.order_placed_interval_amount = intervalAmount
      params.order_placed_interval_trunc = intervalTrunc
    }

    if (dateFilterType === DATE_SHIPPED) {
      params.shipped_interval_type = intervalType
      params.shipped_interval_amount = intervalAmount
      params.shipped_interval_trunc = intervalTrunc
    }

    if (dateFilterType === DATE_DROPSHIPPED) {
      params.dropshipped_interval_type = intervalType
      params.dropshipped_interval_amount = intervalAmount
      params.dropshipped_interval_trunc = intervalTrunc
    }

    if (dateFilterType === DATE_DELIVER_BY) {
      params.deliver_by_interval_type = intervalType
      params.deliver_by_interval_amount = intervalAmount
      params.deliver_by_interval_trunc = intervalTrunc
    }

    if (dateFilterType === DATE_SHIP_BY) {
      params.ship_by_interval_type = intervalType
      params.ship_by_interval_amount = intervalAmount
      params.ship_by_interval_trunc = intervalTrunc
    }
  }

  return params
}

export function summarizeOrderDateRangeParams(query) {
  let startDate
  let endDate
  let dateFilterType
  let intervalType
  let intervalAmount
  let intervalTrunc
  let timezone

  if (query.timezone) {
    timezone = query.timezone
  }

  if (query.created_after) {
    startDate = new Date(query.created_after)
    dateFilterType = DATE_CREATED
  }

  if (query.order_placed_after) {
    startDate = new Date(query.order_placed_after)
    dateFilterType = DATE_ORDERED
  }

  if (query.shipped_after) {
    startDate = new Date(query.shipped_after)
    dateFilterType = DATE_SHIPPED
  }

  if (query.dropshipped_after) {
    startDate = new Date(query.dropshipped_after)
    dateFilterType = DATE_DROPSHIPPED
  }

  if (query.deliver_by_after) {
    startDate = new Date(query.deliver_by_after)
    dateFilterType = DATE_DELIVER_BY
  }

  if (query.ship_by_after) {
    startDate = new Date(query.ship_by_after)
    dateFilterType = DATE_SHIP_BY
  }

  if (startDate && timezone) {
    startDate = toZonedTime(startDate, timezone)
  }

  if (query.created_before) {
    endDate = new Date(query.created_before)
    dateFilterType = DATE_CREATED
  }

  if (query.order_placed_before) {
    endDate = new Date(query.order_placed_before)
    dateFilterType = DATE_ORDERED
  }

  if (query.shipped_before) {
    endDate = new Date(query.shipped_before)
    dateFilterType = DATE_SHIPPED
  }

  if (query.dropshipped_before) {
    endDate = new Date(query.dropshipped_before)
    dateFilterType = DATE_DROPSHIPPED
  }

  if (query.deliver_by_before) {
    endDate = new Date(query.deliver_by_before)
    dateFilterType = DATE_DELIVER_BY
  }

  if (query.ship_by_before) {
    endDate = new Date(query.ship_by_before)
    dateFilterType = DATE_SHIP_BY
  }

  if (endDate && timezone) {
    endDate = toZonedTime(endDate, timezone)
  }

  if (query.created_interval_type) {
    intervalType = query.created_interval_type
    intervalAmount = query.created_interval_amount
    intervalTrunc = query.created_interval_trunc
    dateFilterType = DATE_CREATED
  }

  if (query.order_placed_interval_type) {
    intervalType = query.order_placed_interval_type
    intervalAmount = query.order_placed_interval_amount
    intervalTrunc = query.order_placed_interval_trunc
    dateFilterType = DATE_ORDERED
  }

  if (query.shipped_interval_type) {
    intervalType = query.shipped_interval_type
    intervalAmount = query.shipped_interval_amount
    intervalTrunc = query.shipped_interval_trunc
    dateFilterType = DATE_SHIPPED
  }

  if (query.dropshipped_interval_type) {
    intervalType = query.dropshipped_interval_type
    intervalAmount = query.dropshipped_interval_amount
    intervalTrunc = query.dropshipped_interval_trunc
    dateFilterType = DATE_DROPSHIPPED
  }

  if (query.deliver_by_interval_type) {
    intervalType = query.deliver_by_interval_type
    intervalAmount = query.deliver_by_interval_amount
    intervalTrunc = query.deliver_by_interval_trunc
    dateFilterType = DATE_DELIVER_BY
  }

  if (query.ship_by_interval_type) {
    intervalType = query.ship_by_interval_type
    intervalAmount = query.ship_by_interval_amount
    intervalTrunc = query.ship_by_interval_trunc
    dateFilterType = DATE_SHIP_BY
  }

  return {
    startDate,
    endDate,
    dateFilterType,
    intervalType,
    intervalAmount,
    intervalTrunc,
    timezone,
  }
}

export function orderListQuerySelector(state) {
  const cache = orderListQuerySelector.cache

  const location = locationSelector(state)
  const orderTags = orderTagsSelector(state)

  if (location === cache.location && isEqual(orderTags, cache.orderTags)) {
    return cache.result
  }

  cache.location = location
  cache.orderTags = orderTags

  const {
    query,
    pathComponents: [resource, singleStatusOrOrderNumber],
  } = location

  const orderNumber =
    !!singleStatusOrOrderNumber && resource === ORDER_SINGLE_URI_COMPONENT
      ? singleStatusOrOrderNumber
      : null

  const result = {
    mode:
      resource === ORDER_PLURAL_URI_COMPONENT ||
      (resource === ORDER_SINGLE_URI_COMPONENT && !orderNumber)
        ? LIST_MODE
        : resource === ORDER_SINGLE_URI_COMPONENT
          ? DETAIL_MODE
          : null,
    orderNumber,
    ...orderListQueryToOrderQuery(
      query,
      orderTags,
      resource,
      singleStatusOrOrderNumber,
    ),
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}
orderListQuerySelector.cache = {}

export function orderListQueryToOrderQuery(
  query,
  orderTags,
  resource,
  singleStatusOrOrderNumber,
) {
  const status = [
    ...(resource === ORDER_PLURAL_URI_COMPONENT
      ? singleStatusOrOrderNumber
        ? singleStatusOrOrderNumber in ORDER_STATUS_BY_SLUG
          ? [singleStatusOrOrderNumber]
          : []
        : [DEFAULT_STATUS]
      : ensureArray(query.status).filter((s) => s in ORDER_STATUS_BY_SLUG)),
  ]

  return {
    limit: Number(query.limit) || DEFAULT_PER_PAGE,
    offset: Number(query.offset) || 0,
    status,
    tag: ensureArray(query.orderTagFilters).reduce((prev, link) => {
      const orderTag = Object.values(orderTags).find((t) => t.link === link)

      if (orderTag) {
        prev.push(orderTag.text)
      }

      return prev
    }, []),
    sales_channel: ensureArrayOfNumbers(query.cartFilters),
    supplier: ensureArrayOfNumbers(query.supplierFilters),
    shipper: ensureArrayOfNumbers(query.shipper),
    warehouse_id: ensureArrayOfNumbers(query.warehouseFilters),
    allocation_status: ensureArray(query.allocationFilters),
    printed:
      query.showUnprintedOnly === 'true' &&
      (status.length === 0 || status.includes(SHIPPED))
        ? false
        : undefined,
    untagged: query.orderUntaggedFilter === 'true' ? true : undefined,
    search: !isEmpty(query.searchText) ? query.searchText.trim() : undefined,
    sort: query.sort || DEFAULT_SORT,
    tag_filter_by:
      query.orderTagFilterOperator !== TAG_FILTER_OR
        ? query.orderTagFilterOperator
        : undefined,
    exclude_tags: ensureArray(query.exclude_tags),
    has_similar_open_addresses:
      query.hasSimilarOpenAddresses === 'true' &&
      (status.length === 0 || status.includes(AWAITING_FULFILLMENT))
        ? true
        : undefined,
    ship_to_is_bill_to: ensureTroolean(query.shipToIsBillTo),
    has_revision: ensureTroolean(query.hasRevision),
    similar_address_to:
      ((status.length === 0 || status.includes(AWAITING_FULFILLMENT)) &&
        query.similarAddressTo) ||
      undefined,
    ship_to_status: query.shipToStatus || undefined,
    ship_to_country: ensureArray(query.shipToCountry),
    batch_reference_id: ensureArray(query.batch_reference_id),
    carrier_error: ensureTroolean(query.carrier_error),
    in_batch: ensureTroolean(query.inBatch),
    ...getOrderDateRangeParams(
      query.startDate,
      query.endDate,
      query.dateFilterType,
      query.intervalType,
      query.intervalAmount,
      query.intervalTrunc,
      query.timezone,
    ),
  }
}

export function orderListHashBuilder(params, canUseOrders = true) {
  if (canUseOrders === false) {
    return null
  }

  let hash = `#/${ORDER_SINGLE_URI_COMPONENT}`

  return stringifyURL(hash, params)
}

export const orderListHashSelector = createSelector(
  orderListQuerySelector,
  (state) => canUseOrdersSelector(state),
  orderListHashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  orderListFormSelector,
  ({sticky__limit, sticky__sort} = {}) => ({
    status: [DEFAULT_STATUS],
    limit: sticky__limit !== DEFAULT_PER_PAGE ? sticky__limit : undefined,
    sort: sticky__sort !== DEFAULT_SORT ? sticky__sort : undefined,
  }),
)

export const defaultOrderListHashSelector = createSelector(
  defaultHashParamsSelector,
  (state) => canUseOrdersSelector(state),
  orderListHashBuilder,
)

export function cleanOrderQuery(query) {
  return Object.entries(query)
    .filter(([key, value]) => {
      if (
        ![
          'limit',
          'offset',
          'status',
          'tag',
          'sales_channel',
          'shipper',
          'supplier',
          'warehouse_id',
          'allocation_status',
          'printed',
          'untagged',
          'search',
          'sort',
          'tag_filter_by',
          'exclude_tags',
          'has_similar_open_addresses',
          'ship_to_is_bill_to',
          'has_revision',
          'similar_address_to',
          'ship_to_status',
          'ship_to_country',
          'batch_reference_id',
          'carrier_error',
          'in_batch',
          'created_after',
          'order_placed_after',
          'shipped_after',
          'dropshipped_after',
          'deliver_by_after',
          'ship_by_after',
          'created_before',
          'order_placed_before',
          'shipped_before',
          'dropshipped_before',
          'deliver_by_before',
          'ship_by_before',
          'created_interval_type',
          'created_interval_amount',
          'created_interval_trunc',
          'order_placed_interval_type',
          'order_placed_interval_amount',
          'order_placed_interval_trunc',
          'shipped_interval_type',
          'shipped_interval_amount',
          'shipped_interval_trunc',
          'dropshipped_interval_type',
          'dropshipped_interval_amount',
          'dropshipped_interval_trunc',
          'deliver_by_interval_type',
          'deliver_by_interval_amount',
          'deliver_by_interval_trunc',
          'ship_by_interval_type',
          'ship_by_interval_amount',
          'ship_by_interval_trunc',
          'timezone',
        ].includes(key)
      ) {
        return false
      }

      if (Array.isArray(value) ? value.length === 0 : isEmptyValue(value)) {
        return false
      }

      return true
    })
    .reduce((prev, [key, value]) => {
      prev[key] = value
      return prev
    }, {})
}

export function orderListQueryParamsSelector(state) {
  const query = orderListQuerySelector(state)

  return cleanOrderQuery(query)
}

export const hasActiveFiltersSelector = createSelector(
  orderListQueryParamsSelector,
  (params) =>
    Object.keys(params).filter(
      (key) =>
        !['status', 'sort', 'limit', 'offset', 'batch_reference_id'].includes(
          key,
        ),
    ).length !== 0,
)

export const batchReferenceIDSelector = createSelector(
  orderListQuerySelector,
  (params) => params.batch_reference_id[0],
)

export function getOrderDetailURL(orderNumber, params = {}) {
  return [[ORDER_SINGLE_URI_COMPONENT, orderNumber], params]
}

export const orderStatiiSelector = createSelector(
  useDropshippingSelector,
  (useDropshipping) =>
    ORDER_STATII.filter((orderStatus) => {
      if (orderStatus.slug === 'dropshipment_requested' && !useDropshipping) {
        return false
      }

      return true
    }),
)

export function getValidDateRangeFilterTypes(status, canUseDeliverByDate) {
  const deliverByDate = canUseDeliverByDate
    ? [DATE_DELIVER_BY, DATE_SHIP_BY]
    : []

  if (status.length === 1 && status[0] === AWAITING_FULFILLMENT) {
    return [DATE_ORDERED, ...deliverByDate, DATE_CREATED]
  }
  if (status.length === 1 && status[0] === DROPSHIPMENT_REQUESTED) {
    return [DATE_ORDERED, ...deliverByDate, DATE_DROPSHIPPED, DATE_CREATED]
  }

  return [
    DATE_ORDERED,
    ...deliverByDate,
    DATE_SHIPPED,
    DATE_DROPSHIPPED,
    DATE_CREATED,
  ]
}

export const validDateRangeFilterTypesSelector = createSelector(
  (state, {status} = {}) => status || orderListQuerySelector(state).status,
  (state) => canUseDeliverByDateSelector(state),
  (status, canUseDeliverByDate) =>
    getValidDateRangeFilterTypes(status, canUseDeliverByDate),
)

export const validDateRangeFilterTypesOptionsSelector = createSelector(
  (state, {status} = {}) => validDateRangeFilterTypesSelector(state, {status}),
  (validFilterTypes) =>
    validFilterTypes.map((filterType) =>
      DATE_RANGE_FILTER_OPTIONS.find(({value}) => value === filterType),
    ),
)

export const allocationStatiiSelector = createSelector(
  usePartialInventoryAllocationSelector,
  (partialAllocationAllowed) =>
    ORDER_ALLOCATION_STATII.filter((allocationStatus) => {
      if (allocationStatus.slug === 'partial' && !partialAllocationAllowed) {
        return false
      }

      return true
    }),
)
