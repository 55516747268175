import PropTypes from 'prop-types'
import {createContext, useContext} from 'react'

import {isNumeric} from '../utils.js'
import Quantity from './Quantity.js'

/**
 * plural is a formatting function to add 's'es to words
 *
 * Examples
 *
 * As a simple format function
 * plural(5, 'es', 'oos', 'farts')
 * > fartses
 * plural(1, 'es', 'oos', 'farts')
 * > fartsoos
 * plural(5, 'es')
 * > es
 * plural(0, 'es', 'oos', 'farts')
 * > fartses
 * plural(1, 'es')
 * > <blank string>
 * plural(1, 'es', 'oos')
 * > oos
 *
 * As a template function
 * plural(5)`this is fun${['aans', 'ooos']} or what${['s']}`
 * > this is funaans or whats
 * plural(1)`this is fun${['aans', 'ooos']} or what${['s']}`
 * > this is funooos or what
 * plural(0)`this is fun${['aans', 'ooos']} or what${['s']}`
 * > this is funaans or whats
 */
export function plural(thing, plural, single, word) {
  const count = Array.isArray(thing)
    ? thing.length
    : isNumeric(thing)
      ? Number(thing)
      : 1

  if (plural !== undefined) {
    word = word || ''
    single = single || ''

    return `${word}${count === 1 ? single : plural}`
  }

  return (stringParts, ...params) => {
    return stringParts
      .reduce((prev, part, index) => {
        prev.push(part)

        if (index > params.length - 1) {
          return prev
        }

        const value = params[index]

        if (value === Count) {
          prev.push(count)
        } else if (Array.isArray(value)) {
          prev.push((count === 1 ? value[1] : value[0]) || '')
        } else {
          prev.push(value)
        }

        return prev
      }, [])
      .join('')
  }
}

/**
Example usage of components

<PluralBlock count={some_number_of_things}>
  You are about to create {some_number_of_things} <Plural word="order" />. Are
  you sure you want to create <Plural p="these orders" s="this order" />?
</PluralBlock>
 */

// A context for sharing a common count in a PluralBlock
const PluralContext = createContext({})

// A way to form a statement that may contain multiple pluralization based on the same count
export function PluralBlock({count, array, children}) {
  count = count !== undefined ? count : array !== undefined ? array.length : 1

  return (
    <PluralContext.Provider value={{count}}>{children}</PluralContext.Provider>
  )
}

PluralBlock.propTypes = {
  count: PropTypes.number,
  array: PropTypes.array,
  children: PropTypes.node,
}

// Used to denote a point of pluralization. It can be used independent of PluralBlock or inside it
export function Plural({word = '', s = '', p = 's', count, array, children}) {
  const context = useContext(PluralContext)
  count =
    count !== undefined
      ? count
      : array !== undefined
        ? array.length
        : context.count !== undefined
          ? context.count
          : 1
  const single = s
  const plural = p

  const str = `${word}${count === 1 ? single : plural}`

  if (children) {
    return (
      <>
        {children}
        {str}
      </>
    )
  }

  return str
}

Plural.propTypes = {
  word: PropTypes.string,
  s: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.number,
  array: PropTypes.array,
  children: PropTypes.node,
}

export function Count({options}) {
  const context = useContext(PluralContext)
  const count = context.count !== undefined ? context.count : 1

  return <Quantity value={count} options={options} />
}

Count.propTypes = {
  options: PropTypes.object,
}

export function IfSingle({children}) {
  const context = useContext(PluralContext)
  const count = context.count !== undefined ? context.count : 1

  return count === 1 ? children : null
}

IfSingle.propTypes = {
  children: PropTypes.node,
}

export function IfPlural({children}) {
  const context = useContext(PluralContext)
  const count = context.count !== undefined ? context.count : 1

  return count !== 1 ? children : null
}

IfPlural.propTypes = {
  children: PropTypes.node,
}

export function IfZero({children}) {
  const context = useContext(PluralContext)
  const count = context.count !== undefined ? context.count : 1

  return count === 0 ? children : null
}

IfZero.propTypes = {
  children: PropTypes.node,
}

export function IfPluralNotZero({children}) {
  const context = useContext(PluralContext)
  const count = context.count !== undefined ? context.count : 1

  return count > 1 ? children : null
}

IfPluralNotZero.propTypes = {
  children: PropTypes.node,
}
