import RefreshButton from '../../common/components/List/RefreshButton.js'
import {refreshBatchList} from './batchListActions.js'

export default function RefreshBatches() {
  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint="Refresh the Batch list"
    >
      <RefreshButton onClick={() => refreshBatchList()} />
    </li>
  )
}
