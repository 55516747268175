import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'
import {OPEN_MODAL} from '../../../actions/ui/index.js'
import {
  MODAL_NAME,
  SET_ERROR_MESSAGE,
} from '../../../actions/ui/modals/printPickPackModal.js'

const BASE_STATE = INITIAL_STATE.ui.modals.printPickPackModal

function orderNumbers(state = BASE_STATE.orderNumbers, action = {}) {
  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return action.payload.meta.orderNumbers
  }

  return state
}

function errorMessage(state = BASE_STATE.errorMessage, action = {}) {
  if (action.type === SET_ERROR_MESSAGE) {
    return action.payload
  }

  if (action.type === OPEN_MODAL && action.payload.modalName === MODAL_NAME) {
    return BASE_STATE.errorMessage
  }

  return state
}

export default combineReducers({
  orderNumbers,
  errorMessage,
})
