const BASE = 'ordoro/data/serverInfo'
export const SET_SERVER_INFO = `${BASE}/SET_SERVER_INFO`
export const GET_SERVER_INFO = `${BASE}/GET_SERVER_INFO`

export function setServerInfo(serverInfo) {
  return {
    type: SET_SERVER_INFO,
    payload: serverInfo,
  }
}

export function getServerInfo() {
  return {
    type: GET_SERVER_INFO,
  }
}
