import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {HK_PACK_SHIP_START} from '../../common/constants/HotKeys.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import {navigate} from '../../redux/actions/ui/index.js'
import {
  packShipOrderNumbersSelector,
  indexSelector,
  nextURLSelector,
  cleanStartURLSelector,
} from '../../redux/selectors/ui/packShipPage/index.js'
import {AWAITING_FULFILLMENT} from '../../common/constants/Orders.js'
import {orderListHashBuilder} from '../OrderListPage/orderListSelectors.js'

export function DonePanel({orderNumbers, index, cleanStartURL, ...props}) {
  if (orderNumbers.length === 1 || index === orderNumbers.length - 1) {
    return (
      <div className="flex--justify-col">
        <ButtonPrimary
          className="btn--sm"
          isOutlined
          onClick={() => props.navigate(cleanStartURL)}
          hotKey={HK_PACK_SHIP_START}
        >
          Verify Another Order
        </ButtonPrimary>
      </div>
    )
  }

  if (index < orderNumbers.length - 1) {
    return (
      <div className="flex--justify-col">
        <LinkButton
          href={orderListHashBuilder({status: [AWAITING_FULFILLMENT]})}
          mode="primary"
          className="btn--primary-ol btn--sm"
        >
          Start Over
        </LinkButton>
      </div>
    )
  }

  return null
}

DonePanel.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  nextURL: PropTypes.string,
  cleanStartURL: PropTypes.string,
  navigate: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    orderNumbers: packShipOrderNumbersSelector(state),
    index: indexSelector(state),
    nextURL: nextURLSelector(state),
    cleanStartURL: cleanStartURLSelector(state),
  }
}

const mapDispatchToProps = {
  navigate,
}

export default connect(mapStateToProps, mapDispatchToProps)(DonePanel)
