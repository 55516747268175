import PropTypes from 'prop-types'
import {useMemo} from 'react'
import pick from 'lodash/pick.js'

import {
  REPORT_EXPORT_RMA_SUMMARY,
  REPORT_EXPORT_RMA_DETAIL,
} from '../../../common/constants/Reports.js'
import {reportSelector} from '../../../data/reports.js'
import {
  formSelector,
  getState,
  updateForm,
  useForm,
  useSelector,
} from '../../../store.js'
import {hasOrderManageTagPermissionSelector} from '../../../data/me.js'
import {tagColors} from '../../../common/colors.js'
import {TagShape} from '../../../common/PropTypes.js'
import ManageTagFilter from '../../../common/components/ManageTagFilter.js'
import {TAG_TYPE__RETURN_ORDER} from '../../../common/constants/Tags.js'
import Select from '../../../common/components/Select.js'
import {
  returnOrderTagOptionTokensSelector,
  returnOrderTagOptionsSelector,
  returnOrderTagsSortedByNameSelector,
} from '../../../data/returnOrderTags.js'
import {
  RETURN_ORDER_STATII,
  RETURN_ORDER_STATUS_OPTIONS,
} from '../../../common/constants/ReturnOrders.js'
import DateFilters, {
  dateFilterErrorsSelector,
  dateFilterPayloadParamsSelector,
} from './DateFilters.js'

export function setupExportRMAsForm(params = {}) {
  let {reportID} = params
  params = pick(params, ['rmaStatus', 'returnOrderTagNames'])

  const {params: reportParams} = reportID
    ? reportSelector(getState(), {reportID})
    : {params: {}}

  return {
    rmaStatus: RETURN_ORDER_STATII.includes(reportParams.status)
      ? reportParams.status
      : '',
    returnOrderTagNames: reportParams.tags || [],
    ...params,
  }
}

export function exportRMAsErrorsSelector(state, {formName}) {
  const errors = {...dateFilterErrorsSelector(state, {formName})}

  return errors
}

export function exportRMAsPayloadSelector(state, {formName}) {
  const {reportType, rmaStatus, returnOrderTagNames} = formSelector(state, {
    formName,
  })

  const payload = {
    type: reportType,
    params: {
      ...dateFilterPayloadParamsSelector(state, {formName}),
    },
  }

  if (rmaStatus) {
    payload.params.status = rmaStatus
  }

  if (returnOrderTagNames.length > 0) {
    payload.params.tags = returnOrderTagNames
  }

  return payload
}

export function toggleTag(tagName, formName) {
  const {returnOrderTagNames} = formSelector(getState(), {
    formName,
  })

  if (returnOrderTagNames.find((name) => name === tagName)) {
    updateForm(formName, {
      returnOrderTagNames: returnOrderTagNames.filter(
        (name) => name !== tagName,
      ),
    })
  } else {
    updateForm(formName, {
      returnOrderTagNames: [...returnOrderTagNames, tagName],
    })
  }
}

function Tag({tag, onClick}) {
  return (
    <li
      className="list__item--inline-block list__item--tag"
      style={tagColors(tag.color)}
    >
      <strong className="tag-name inline-block v-align-middle">
        {tag.name}
      </strong>
      <button className="btn btn--remove-tag" type="button" onClick={onClick}>
        <span className="i--close" aria-hidden="true" />
      </button>
    </li>
  )
}

Tag.propTypes = {
  tag: TagShape.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default function ExportRMAsForm({formName}) {
  const form = useForm(formName)
  const errors = useSelector((state) =>
    exportRMAsErrorsSelector(state, {formName}),
  )
  const returnOrderTagOptions = useSelector(returnOrderTagOptionsSelector)
  const returnOrderTagOptionTokens = useSelector(
    returnOrderTagOptionTokensSelector,
  )
  const returnOrderTags = useSelector(returnOrderTagsSortedByNameSelector)
  const hasOrderManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const tagQuantityMap = useMemo(
    () =>
      returnOrderTags.reduce((prev, tag) => {
        prev[tag.id] = form.returnOrderTagNames.includes(tag.name)
          ? 'all'
          : 'none'

        return prev
      }, {}),
    [returnOrderTags, form.returnOrderTagNames],
  )
  const tags = useMemo(
    () =>
      form.returnOrderTagNames.reduce((prev, tagName) => {
        const tag = returnOrderTags.find(({name}) => name === tagName)

        if (tag) {
          prev.push(tag)
        }

        return prev
      }, []),
    [returnOrderTags, form.returnOrderTagNames],
  )

  return (
    <ul className="list">
      <DateFilters formName={formName} errors={errors} />
      <li className="list__item--form list__item--no-style">
        <Select
          label="Status"
          className="w-75 margin-bottom-0"
          id="id_status"
          value={form.rmaStatus}
          onChange={(rmaStatus) => updateForm(formName, {rmaStatus})}
        >
          {RETURN_ORDER_STATUS_OPTIONS.map((status) => (
            <option key={status.value} value={status.value}>
              {status.display}
            </option>
          ))}
        </Select>
      </li>
      {form.reportID ? (
        <li className="list__item--form list__item--no-style">
          <p className="fs-00 margin-top-20 margin-bottom-10">
            <strong>
              Level of Detail:{' '}
              {form.reportType === REPORT_EXPORT_RMA_SUMMARY
                ? 'Summary'
                : 'Detail'}
            </strong>
          </p>
        </li>
      ) : (
        <li className="list__item--form list__item--no-style divider--bottom margin-bottom-15 padding-bottom-20">
          <Select
            label="Level of Detail"
            className="w-75 margin-bottom-0"
            id="id_report_type"
            value={form.reportType}
            onChange={(reportType) => updateForm(formName, {reportType})}
          >
            <option value={REPORT_EXPORT_RMA_SUMMARY}>Summary</option>
            <option value={REPORT_EXPORT_RMA_DETAIL}>Detail</option>
          </Select>
        </li>
      )}
      <li className="list__item--form list__item--no-style">
        <ManageTagFilter
          dropdown="EXPORT_RMAS_MODAL_TAG_FILTER"
          buttonClassName="btn--dropdown-darker"
          label={<strong>Tags</strong>}
          onSelect={(tag) => toggleTag(tag.name, formName)}
          tagOptions={returnOrderTagOptions}
          tagOptionTokens={returnOrderTagOptionTokens}
          tagQuantityMap={tagQuantityMap}
          hasManageTagPermission={hasOrderManageTagPermission}
          tagType={TAG_TYPE__RETURN_ORDER}
        />
        {tags.length > 0 && (
          <ul className="margin-left-0">
            {tags.map((tag) => (
              <Tag
                key={tag.id}
                tag={tag}
                onClick={() => toggleTag(tag.name, formName)}
              />
            ))}
          </ul>
        )}
      </li>
    </ul>
  )
}

ExportRMAsForm.propTypes = {
  formName: PropTypes.string.isRequired,
}
