import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import subMonths from 'date-fns/subMonths'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

import {dispatch} from '../../store.js'
import {DateShape} from '../../common/PropTypes.js'
import {ANALYTICS_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  analyticsPanelSelector,
  startDateSelector,
  endDateSelector,
} from './analyticsSelectors.js'
import {setDateRangeFilters} from './analyticsActions.js'

function AnalyticsLocationListener({panel, startDate, endDate}) {
  useEffect(() => {
    // panel will exist as something if the URL is on the analytics page
    if (!panel) {
      return
    }

    dispatch(setCurrentPage(ANALYTICS_PAGE))
  }, [panel])

  useEffect(() => {
    // if we came to analytics without date filters
    if (!panel || (startDate && endDate)) {
      return
    }

    const now = new Date()

    // this will cause an extra navigation, so try not to get here
    setDateRangeFilters(startOfDay(subMonths(now, 1)), endOfDay(now))
  }, [panel, startDate, endDate])

  return null
}

AnalyticsLocationListener.propTypes = {
  panel: PropTypes.string,
  startDate: DateShape,
  endDate: DateShape,
}

function mapStateToProps(state) {
  return {
    panel: analyticsPanelSelector(state),
    startDate: startDateSelector(state),
    endDate: endDateSelector(state),
  }
}

export default connect(mapStateToProps)(AnalyticsLocationListener)
