import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  OrderShape,
  LabelInfoIDShape,
} from '../../../../../../common/PropTypes.js'
import Currency from '../../../../../../common/components/Currency.js'
import FormattedDate from '../../../../../../common/components/FormattedDate.js'
import TimelinePopover from '../../../../../../common/components/List/TimelinePopover/index.js'

import OrderTags from '../../Fields/OrderTags.js'
import OrderStatusSymbol from '../../Fields/OrderStatusSymbol.js'
import OrderAllocationIcon from '../../Fields/OrderAllocationIcon.js'
import ShippabilityIcon from '../../Fields/ShippabilityIcon.js'

import ExpandedRow from '../ExpandedRow.js'
import AwaitingFulfillmentSummary from './AwaitingFulfillmentSummary.js'
import AwaitingFulfillmentStatus from './AwaitingFulfillmentStatus.js'
import ShippedStatus from './ShippedStatus.js'
import ShippedSummary from './ShippedSummary.js'
import DropshipmentRequestedStatus from './DropshipmentRequestedStatus.js'
import DropshipmentRequestedSummary from './DropshipmentRequestedSummary.js'
import CancelledStatus from './CancelledStatus.js'
import CancelledSummary from './CancelledSummary.js'
import CompactAddress from './Fields/CompactAddress.js'
import InternalNotesPopOver from '../../Fields/InternalNotesPopOver.js'
import {showOrderRevisionModal} from '../../../../../OrderListPage/Modals/OrderRevisionModal.js'
import {useSelector} from '../../../../../../store.js'
import {
  hasOrderFinancialsPermissionSelector,
  hasOrderManageRevisionPermissionSelector,
} from '../../../../../../data/me.js'
import {toggleExpanded} from '../../../../../OrderListPage/orderListActions.js'
import {ORDER_SHIPPING_ADDRESS} from '../../../../../../common/constants/AddressTypes.js'
import {
  batchReferenceIDSelector,
  orderListHashBuilder,
} from '../../../../../OrderListPage/orderListSelectors.js'
import {AWAITING_FULFILLMENT} from '../../../../../../common/constants/Orders.js'
import {buildBatchDetailURL} from '../../../../../BatchListPage/batchListSelectors.js'

export default function CompactRow({
  isExpanded,
  isSelected,
  order,
  orderNumber,
  labelInfoID,
  status,
  isPrimeOrder,
  orderAddressCanBeEdited,
  packageCount,
  commentGroupsSelector,
  selectOrder,
  useInventoryAllocation,
}) {
  const hasOrderManageRevisionPermission = useSelector(
    hasOrderManageRevisionPermissionSelector,
  )
  const hasOrderFinancialsPermission = useSelector(
    hasOrderFinancialsPermissionSelector,
  )
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const batchDetailURL = order.batch_reference_id
    ? buildBatchDetailURL(order.batch_reference_id)
    : null

  return (
    <li
      className={classNames(
        'list__item--content-block list__item--order-block padding-0',
        'meta-orderlistrow',
        'orderlistrow-compact',
        {selected: isSelected},
        {'list__item--order-revision': order.has_revision},
      )}
    >
      <div className="wrap--row flex">
        <div className="medium-12 columns wrap--order-header">
          <TimelinePopover
            title="Order Timeline"
            type="order"
            commentGroupsSelector={commentGroupsSelector}
          />
          {order.has_revision && (
            <div className="wrap--order-revision-callout relative">
              {hasOrderManageRevisionPermission ? (
                <>
                  <button
                    className="btn btn--link darker inline-block v-align-middle fs-n1"
                    type="button"
                    onClick={() => showOrderRevisionModal([orderNumber])}
                  >
                    <strong>
                      This order has been modified outside of Ordoro
                    </strong>
                  </button>{' '}
                  <span className="fs-n1">→</span>
                </>
              ) : (
                <strong className="fs-n1">
                  This order has been modified outside of Ordoro
                </strong>
              )}
              <a
                className="btn btn--link no-underline light absolute abs-right"
                href="https://support.ordoro.com/how-do-i-sync-order-line-revisions-in-ordoro/"
                target="_blank"
                rel="noopener noreferrer"
                title="Learn about external order line revisions"
              >
                <span className="i-help fs-01 lh-xl" />
              </a>
            </div>
          )}
          <table className="table table--order-summary margin-left-0">
            <tbody>
              <tr>
                <td className="table__td--order-checkbox">
                  <input
                    className="inline-block checkbox--order"
                    type="checkbox"
                    checked={isSelected}
                    onChange={selectOrder}
                  />
                  {batchDetailURL && !batchReferenceID && (
                    <div
                      className="tooltip--right margin-top-5"
                      data-hint="Go to batch"
                    >
                      <a
                        className="btn btn--link mid no-underline"
                        href={batchDetailURL}
                      >
                        <span className="i-batch-03 fs-00" aria-hidden="true" />
                      </a>
                    </div>
                  )}
                </td>
                {status.length !== 1 && (
                  <td className="table__td--order-summary td-width-3">
                    <OrderStatusSymbol order={order} />
                  </td>
                )}
                <td className="table__td--order-summary td-width-20">
                  <div className="flex">
                    <div>
                      <div className="list__title--order-header v-align-top fs-n1 margin-right-5 margin-bottom-0 block-when-selected">
                        <a
                          className="link--order-header"
                          href={`#/order/${encodeURIComponent(orderNumber)}`}
                        >
                          {orderNumber}
                        </a>
                      </div>

                      {useInventoryAllocation &&
                        order.allocation_status !== 'unallocated' && (
                          <div className="margin-top-3 margin-bottom-1">
                            <OrderAllocationIcon
                              allocationStatus={order.allocation_status}
                              className="v-align-middle fs-n2"
                            />
                          </div>
                        )}
                      {order.tags.length > 0 && (
                        <div className="wrap--summary-tags-table wrap--summary-tags-inline">
                          <OrderTags order={order} />
                        </div>
                      )}
                    </div>
                    <InternalNotesPopOver
                      internalNotes={order.internal_notes}
                    />
                  </div>
                </td>
                <td className="table__td--order-summary td-width-15">
                  <div className="fs-n1">
                    <FormattedDate
                      value={order.order_placed_date}
                      format="MMM D, YYYY"
                    />
                  </div>
                  <div>
                    {useInventoryAllocation || (
                      <ShippabilityIcon
                        shippability={order.shippability}
                        className="inline-block compact"
                      />
                    )}
                    {hasOrderFinancialsPermission && (
                      <div className="inline-block">
                        <span className="fs-n1">
                          <Currency value={order.financial.grand_total} />
                        </span>
                        <span className="fs-n1"> ({order.lines.length})</span>
                      </div>
                    )}
                  </div>
                </td>
                <td className="table__td--order-summary td-width-15">
                  <div className="flex">
                    {order.similar_open_addresses_count > 0 && (
                      <div className="margin-right-3">
                        <div
                          className="tooltip--right"
                          data-hint="Show orders with similar ship-to addresses"
                        >
                          <a
                            className="btn btn--link lighter no-underline"
                            href={orderListHashBuilder({
                              status: [AWAITING_FULFILLMENT],
                              similarAddressTo: order.order_number,
                            })}
                          >
                            <span className="i--new fs-n1" aria-hidden="true" />
                          </a>
                        </div>
                      </div>
                    )}
                    <CompactAddress
                      orderNumber={order.order_number}
                      address={order.shipping_address}
                      addressType={ORDER_SHIPPING_ADDRESS}
                      notes={order.notes_from_customer}
                      canEdit={orderAddressCanBeEdited}
                    />
                  </div>
                </td>
                <td className="table__td--order-summary td-width-20">
                  {order.status === 'awaiting_fulfillment' && (
                    <AwaitingFulfillmentStatus order={order} />
                  )}
                  {order.status === 'shipped' && (
                    <ShippedStatus order={order} />
                  )}
                  {order.status === 'dropshipment_requested' && (
                    <DropshipmentRequestedStatus order={order} />
                  )}
                  {order.status === 'cancelled' && (
                    <CancelledStatus order={order} />
                  )}
                </td>
                <td className="table__td--order-summary td-width-25 make-relative padding-right-25">
                  {order.status === 'awaiting_fulfillment' && (
                    <AwaitingFulfillmentSummary
                      labelInfoID={labelInfoID}
                      packageCount={packageCount}
                    />
                  )}
                  {order.status === 'shipped' && (
                    <ShippedSummary order={order} />
                  )}
                  {order.status === 'dropshipment_requested' && (
                    <DropshipmentRequestedSummary order={order} />
                  )}
                  {order.status === 'cancelled' && (
                    <CancelledSummary orderNumber={orderNumber} />
                  )}
                  <button
                    className="btn btn--link no-underline btn--expand-row"
                    type="button"
                    onClick={() => toggleExpanded(orderNumber)}
                    title="Expand this order"
                  >
                    <span className="i--expand fs-01 lh-0" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {isExpanded && (
        <ExpandedRow
          showHeader={false}
          isExpanded={isExpanded}
          isSelected={isSelected}
          order={order}
          orderNumber={orderNumber}
          status={status}
          isPrimeOrder={isPrimeOrder}
          orderAddressCanBeEdited={orderAddressCanBeEdited}
          commentGroupsSelector={commentGroupsSelector}
          selectOrder={selectOrder}
          useInventoryAllocation={useInventoryAllocation}
        />
      )}
    </li>
  )
}

CompactRow.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  order: OrderShape.isRequired,
  orderNumber: PropTypes.string.isRequired,
  labelInfoID: LabelInfoIDShape,
  status: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrimeOrder: PropTypes.bool.isRequired,
  orderAddressCanBeEdited: PropTypes.bool.isRequired,
  commentGroupsSelector: PropTypes.func.isRequired,
  packageCount: PropTypes.number.isRequired,
  selectOrder: PropTypes.func.isRequired,
  useInventoryAllocation: PropTypes.bool.isRequired,
}
