import {useSelector} from '../../store.js'
import {allowCartSyncModalSelector} from '../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {HEADER_NAV_DROPDOWN} from './headerSelectors.js'
import Logo from './Logo.js'
import NavTabs from './NavTabs.js'
import SettingsDropdown from './SettingsDropdown.js'
import SupportIcon from './SupportIcon.js'
import ActivityMenu from './ActivityMenu/index.js'
import SyncButton from './SyncButton.js'
import NavDropDown from './NavDropDown.js'
import TrialNotice from './TrialNotice.js'

export default function Nav() {
  const allowCartSyncModal = useSelector(allowCartSyncModalSelector)

  return (
    <div className="wrap--container">
      <div className="wrap--nav-lg dropdown">
        <button
          className="btn btn--nav-misc btn--nav-toggle"
          type="button"
          data-dropdown={HEADER_NAV_DROPDOWN}
        >
          Menu
        </button>
        <nav className="main-nav">
          <Logo />
          <NavTabs />
        </nav>
        <TrialNotice />
        <div className="wrap--utility-nav">
          <ul className="utility-nav">
            <SettingsDropdown />
            <SupportIcon />
            <ActivityMenu />
          </ul>
          {allowCartSyncModal && <SyncButton />}
        </div>
      </div>
      <NavDropDown />
    </div>
  )
}
