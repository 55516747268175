import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {HEADER_NAV_DROPDOWN} from './headerSelectors.js'
import ListenToServerInfo from './ListenToServerInfo.js'
import ZuckBanner from './ZuckBanner.js'
import BannerMessage from './BannerMessage.js'
import ReloadMessage from './ReloadMessage.js'
import EndiciaAccountError from './EndiciaAccountError.js'
import Nav from './Nav.js'
import UpgradeMessage from './UpgradeMessage.js'
import Toast from './Toast/index.js'

export function Header({currentDropdown}) {
  return (
    <header
      className={classNames('header header--main', {
        'open-nav': currentDropdown === HEADER_NAV_DROPDOWN,
      })}
    >
      <ListenToServerInfo />
      <ZuckBanner />
      <ReloadMessage />
      <BannerMessage undismissable />
      <BannerMessage />
      <EndiciaAccountError />
      <Nav />
      <UpgradeMessage />
      <Toast />
    </header>
  )
}

Header.propTypes = {
  currentDropdown: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    currentDropdown: currentDropdownSelector(state),
  }
}

export default connect(mapStateToProps)(Header)
