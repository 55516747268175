import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  formSelector,
  onlyIfForm,
  useForm,
  useSelector,
} from '../../../store.js'
import {NEW_ID} from '../../../common/constants/SettingsPanels.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {cartSelector} from '../../../data/carts.js'
import NewVendorSelect from '../SalesChannels/NewVendorSelect.js'
import Vendors from '../SalesChannels/Vendors/index.js'
import {saveSalesChannel} from '../SalesChannels/salesChannelsActions.js'
import {cartFormErrorsSelector} from '../SalesChannels/salesChannelsSelectors.js'

export const MODAL_FORM = 'EDIT_CART_MODAL'

export async function showEditCartModal({cartID, newVendor} = {}) {
  const cart = cartID
    ? cartSelector(getState(), {cartID})
    : {id: NEW_ID, vendor: newVendor || ''}

  setForm(MODAL_FORM, {
    cartID: cart.id,
    vendor: cart.vendor,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

function getFormName(vendor) {
  return `${MODAL_FORM}_CART_${vendor}`
}

export async function confirmCart() {
  const {vendor} = modalFormSelector(getState())
  const formName = getFormName(vendor)

  await saveSalesChannel(formName)

  const cartForm = formSelector(getState(), {formName})

  if (cartForm && !cartForm.serverError) {
    closeModal()
  }
}

function EditCartModal({form}) {
  const formName = getFormName(form.vendor)
  const cartForm = useForm(formName) || {}
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )
  const isNew = form.cartID === NEW_ID
  const Vendor = Vendors[form.vendor]

  return (
    <ConfirmModal
      title={isNew ? 'Connect a Sales Channel' : 'Edit a Sales Channel'}
      modalSize="md"
      confirmText={isNew ? 'Connect' : 'Save'}
      onConfirm={() => confirmCart()}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={cartForm.isSaving}
      error={errors.serverError}
    >
      <div className="wrap--settings-form-modal">
        {isNew && (
          <>
            <div className="row">
              <div className="medium-6 columns">
                <NewVendorSelect
                  className="w-100"
                  value={form.vendor}
                  onChange={(vendor) => updateModalForm({vendor})}
                />
              </div>
            </div>
            <hr />
          </>
        )}
        {form.vendor && (
          <>{Vendor && <Vendor formName={formName} cartID={form.cartID} />}</>
        )}
      </div>
    </ConfirmModal>
  )
}

EditCartModal.propTypes = {
  form: PropTypes.shape({
    cartID: PropTypes.any.isRequired,
    vendor: PropTypes.string.isRequired,
  }),
}

export default onlyIfForm(EditCartModal, modalFormSelector)
