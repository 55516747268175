import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'

import {
  modalFormSelector,
  statusSelector,
  titleSelector,
  messageSelector,
} from './EditAddressFunctions.js'
import Suggestion from './Suggestion.js'

function ValidationPanel({validation, status, title, message}) {
  const {suggested} = validation || {}

  return (
    <dl className="list--modal-sb">
      <dt className="list__header--modal-sb">Address Validator</dt>
      <dd className="list__item--modal-sb">
        {status !== 'validated' &&
          (title || message || ['validating', 'error'].includes(status)) && (
            <dl className={classNames('list--addr-msg', _.kebabCase(status))}>
              {status === 'validating' && (
                <dd className="list__item--addr-msg align-center margin-bottom-0">
                  <span className="spinning--sm-ko" />
                  <span className="inline-block v-align-middle margin-left-5">
                    Validating...
                  </span>
                </dd>
              )}

              {title && (
                <dt className="list__title--addr-msg flex">
                  <span
                    className="i--validation fs-01 margin-right-5"
                    aria-hidden="true"
                  />
                  <span>{title}</span>
                </dt>
              )}

              {message && <dd className="list__item--addr-msg">{message}</dd>}

              {status === 'error' && (
                <dd className="list__item--usps-callout">
                  You can also try verifying the address using the{' '}
                  <a
                    href="https://tools.usps.com/go/ZipLookupAction!input.action"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    USPS validation tool
                  </a>
                  .
                </dd>
              )}
            </dl>
          )}

        {suggested && suggested.length > 0 ? (
          <dl className="list--addr-callout">
            <dt className="list__header--addr-callout">Suggested address:</dt>
            {suggested.map((suggestion, index) => (
              <Suggestion key={index} suggestion={suggestion} />
            ))}
          </dl>
        ) : (
          <dl className="list--addr-msg">
            <dd className="list__item--addr-msg align-center margin-bottom-0">
              No Suggested Address
            </dd>
          </dl>
        )}
      </dd>
    </dl>
  )
}

ValidationPanel.propTypes = {
  validation: PropTypes.object.isRequired,
  status: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    validation: modalFormSelector(state).validation,
    status: statusSelector(state),
    title: titleSelector(state),
    message: messageSelector(state),
  }
}

export default connect(mapStateToProps)(ValidationPanel)
