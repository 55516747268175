export const PAGE_SIZES = {
  thermal: {
    width: 4,
    height: 6,
  },
  desktop: {
    width: 8.5,
    height: 11,
  },
}
