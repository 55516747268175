import {useSelector} from '../../../store.js'
import {returnOrderCodesHaveLoadedSelector} from '../../../redux/selectors/data/hasLoaded.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import {hasUseReturnOrdersFeatureSelector} from '../../../data/company.js'

import SettingsPanel from '../SettingsPanel.js'
import CodeList from './CodeList.js'
import ReturnOrderCodesFeaturePage from './ReturnOrderCodesFeaturePage.js'

export default function ReturnOrderCodes() {
  const hasLoaded = useSelector(returnOrderCodesHaveLoadedSelector)
  const hasPermission = useSelector(
    settingsPermissionsSelector,
  ).return_order_codes
  const hasUseReturnOrdersFeature = useSelector(
    hasUseReturnOrdersFeatureSelector,
  )

  if (!hasUseReturnOrdersFeature) {
    return <ReturnOrderCodesFeaturePage />
  }

  return (
    <SettingsPanel header="Return Codes" hasPermission={hasPermission}>
      <div className="row">{hasLoaded && <CodeList />}</div>
    </SettingsPanel>
  )
}
