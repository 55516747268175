import {useEffect, useMemo} from 'react'
import ReactDOM from 'react-dom'

import BaseConfirmModal from './BaseConfirmModal.js'

export default function ConfirmModal(props) {
  const el = useMemo(() => document.createElement('div'), [])

  useEffect(() => {
    const root = document.getElementById('modal-root')

    root.appendChild(el)

    return () => root.removeChild(el)
  }, [])

  return ReactDOM.createPortal(
    <BaseConfirmModal modalName="CONFIRM_MODAL" {...props} />,
    el,
  )
}
