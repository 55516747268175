import PropTypes from 'prop-types'

import {onlyIfForm, useSelector} from '../../../../store.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  createPO,
  closeCreatePOModal,
  addCreatePOLine,
} from './createPOModalActions.js'
import {activeNonFBASupplierOptionsSelector} from '../../../../data/suppliers.js'
import {nonFBAWarehouseOptionsSelector} from '../../../../data/warehouses.js'
import {
  createPOModalFormSelector,
  errorsSelector,
} from './createPOModalSelectors.js'

import ProductRow from './ProductRow.js'
import SetupModal from './SetupModal.js'

function CreatePOModal({form}) {
  const errors = useSelector(errorsSelector)
  const needsSupplier = useSelector(
    (state) => activeNonFBASupplierOptionsSelector(state).length === 0,
  )
  const needsWarehouse = useSelector(
    (state) => nonFBAWarehouseOptionsSelector(state).length === 0,
  )

  if (needsWarehouse || needsSupplier) {
    return (
      <SetupModal
        needsWarehouse={needsWarehouse}
        needsSupplier={needsSupplier}
      />
    )
  }

  return (
    <ConfirmModal
      title="Order More Products"
      modalSize="xx-lg"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      confirmText="Order More"
      cancelText="Cancel"
      onConfirm={() => createPO()}
      onCancel={() => closeCreatePOModal()}
      error={form.serverError}
    >
      <div style={{minHeight: '15em'}}>
        <table className="table margin-bottom-25">
          <thead>
            <tr>
              <th className="table__th table__th--sm th-width-15">
                Product/SKU
              </th>

              <th className="table__th table__th--sm th-width-15">Supplier</th>
              <th className="table__th table__th--sm th-width-15">Warehouse</th>
              <th className="table__th table__th--sm th-width-5">Qty</th>
              <th className="table__th table__th--sm th-width-10">
                Supp. Unit Cost
              </th>
              <th className="table__th table__th--sm th-width-10 align-right">
                <div className="tooltip--left" data-hint="Physical On Hand">
                  POH
                </div>
              </th>
              <th className="table__th table__th--sm th-width-5 align-right">
                <div className="tooltip--left" data-hint="Committed">
                  CMT
                </div>
              </th>
              <th className="table__th table__th--sm th-width-5 align-right">
                <div className="tooltip--left" data-hint="Already Reordered">
                  Reord.
                </div>
              </th>
              <th className="table__th table__th--sm th-width-5 align-right">
                <div className="tooltip--left" data-hint="Low Stock Threshold">
                  LST
                </div>
              </th>
              <th className="table__th table__th--sm th-width-5">
                <div className="tooltip--left" data-hint="Lead Time">
                  LT
                </div>
              </th>
              <th className="table__th table__th--sm align-right padding-right-0 th-width-1" />
            </tr>
          </thead>
          <tbody className="table__tbody--lines">
            {form.lines.map((line, index) => (
              <ProductRow
                key={index}
                index={index}
                line={line}
                errors={errors}
              />
            ))}
            <tr>
              <td
                className="table__td table__td--product-search padding-left-0 align-center"
                colSpan="12"
              >
                <ButtonPrimary
                  isOutlined
                  size="sm"
                  onClick={() => addCreatePOLine()}
                >
                  Add a Product
                </ButtonPrimary>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ConfirmModal>
  )
}

CreatePOModal.propTypes = {
  form: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.object).isRequired,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(CreatePOModal, createPOModalFormSelector)
