import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {dispatch} from '../../store.js'
import {RETURN_ORDER_DETAIL_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {
  setupReturnOrderDetailForm,
  removeReturnOrderDetailForm,
  loadCurrentReturnOrder,
} from './returnOrderDetailActions.js'
import {returnOrderDetailReferenceIDSelector} from './returnOrderDetailSelectors.js'

function ReturnOrderDetailLocationListener({referenceID}) {
  useEffect(() => {
    if (!referenceID) {
      return
    }

    setupReturnOrderDetailForm()

    dispatch(setCurrentPage(RETURN_ORDER_DETAIL_PAGE))

    loadCurrentReturnOrder()

    return () => removeReturnOrderDetailForm()
  }, [referenceID])

  return null
}

ReturnOrderDetailLocationListener.propTypes = {
  referenceID: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    referenceID: returnOrderDetailReferenceIDSelector(state),
  }
}

export default connect(mapStateToProps)(ReturnOrderDetailLocationListener)
