import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {
  setShowPackingLists,
  applyPackingListBulk,
} from '../bulkLabelConfigActions.js'
import {bulkLabelConfigFormSelector} from '../bulkLabelConfigSelectors.js'
import {sortedPackingListsByIDSelector} from '../../../../redux/selectors/data/packingLists.js'
import PackingList from '../../Fields/PackingList.js'
import UpdateOrdersButton from './UpdateOrdersButton.js'

export default function BulkPackingLists({labelInfoIDs}) {
  const form = useSelector(bulkLabelConfigFormSelector)

  const hasMoreThanOnePackingList =
    useSelector(sortedPackingListsByIDSelector).length > 1

  if (!hasMoreThanOnePackingList) {
    return null
  }

  return (
    <dd className="list__item meta-labelconfigform-bulk-packinglist">
      <label htmlFor="bulk_packinglist">
        <input
          type="checkbox"
          id="bulk_packinglist"
          onChange={(event) => setShowPackingLists(event.target.checked)}
          checked={form.showPackingLists}
        />
        <span className="margin-left-5">Profile</span>
      </label>
      {form.showPackingLists && (
        <div className="wrap--nested-form">
          <div className="list--shipping-options list--hide-label">
            <PackingList />
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              onClick={() => applyPackingListBulk(labelInfoIDs)}
              loading={form.loading__packingLists}
            />
          </div>
        </div>
      )}
    </dd>
  )
}

BulkPackingLists.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
