import PropTypes from 'prop-types'

import {useSelector} from '../../../store.js'
import DropshipConfigForm from '../DropshipConfigForm/index.js'
import DropshipFeaturePage from '../DropshipConfigForm/DropshipFeaturePage.js'
import UnusableWarnings from '../../../common/components/List/ActionPanel/UnusableWarnings.js'
import DropshipSpeedbumps from './DropshipSpeedbumps.js'
import {deselectOrders} from '../orderListActions.js'
import {useDropshippingSelector} from '../../../data/company.js'

export default function DropshipTab({
  needsSupplier,
  isOrderDetailView,
  unfulfilledOrderNumbers,
  archivedOrderNumbers,
  cancelledOrderNumbers,
  mergedOrderNumbers,
  orderNumbersWithShippingLabels,
  dropshippedOrderNumbers,
  markAsShippedOrderNumbers,
  manuallyTrackedOrderNumbers,
}) {
  const useDropshipping = useSelector(useDropshippingSelector)

  if (!useDropshipping) {
    return <DropshipFeaturePage />
  }

  if (needsSupplier) {
    return <DropshipSpeedbumps needsSupplier={needsSupplier} />
  }

  const singleOrderReference = isOrderDetailView ? 'This order' : '1 order that'
  const allWarningMessages = {
    shippingLabelled: {
      ids: orderNumbersWithShippingLabels,
      singleMessage: `${singleOrderReference} already has a shipping label`,
      pluralMessage: `${orderNumbersWithShippingLabels.length} orders that already have shipping labels`,
    },
    dropshipped: {
      ids: dropshippedOrderNumbers,
      singleMessage: `${singleOrderReference} already has a dropshipment request`,
      pluralMessage: `${dropshippedOrderNumbers.length} orders that already have dropshipment requests`,
    },
    markedAsShipped: {
      ids: markAsShippedOrderNumbers,
      singleMessage: `${singleOrderReference} was marked as shipped`,
      pluralMessage: `${markAsShippedOrderNumbers.length} orders that are marked as shipped`,
    },
    manuallyTracked: {
      ids: manuallyTrackedOrderNumbers,
      singleMessage: `${singleOrderReference} had a tracking number entered for it`,
      pluralMessage: `${manuallyTrackedOrderNumbers.length} orders that had tracking numbers entered for them`,
    },
    archived: {
      ids: archivedOrderNumbers,
      singleMessage: `${singleOrderReference} was archived`,
      pluralMessage: `${archivedOrderNumbers.length} orders that have been archived`,
    },
    cancelled: {
      ids: cancelledOrderNumbers,
      singleMessage: `${singleOrderReference} was cancelled`,
      pluralMessage: `${cancelledOrderNumbers.length} orders that have been cancelled`,
    },
    merged: {
      ids: mergedOrderNumbers,
      singleMessage: `${singleOrderReference} was merged`,
      pluralMessage: `${mergedOrderNumbers.length} orders that have been merged`,
    },
  }

  const warningMessages = [
    allWarningMessages.dropshipped,
    allWarningMessages.shippingLabelled,
    allWarningMessages.markedAsShipped,
    allWarningMessages.manuallyTracked,
    allWarningMessages.archived,
    allWarningMessages.cancelled,
    allWarningMessages.merged,
  ]

  if (unfulfilledOrderNumbers.length > 0) {
    const singleWarningTitle = 'A dropshipment request will not be sent for:'
    const pluralWarningTitle = 'Dropshipment requests will not be sent for:'
    return (
      <div>
        <UnusableWarnings
          warningMessages={warningMessages}
          singleTitle={singleWarningTitle}
          pluralTitle={pluralWarningTitle}
          showDeselect={!isOrderDetailView}
          deselect={(...args) => deselectOrders(...args)}
        />
        <DropshipConfigForm orderNumbers={unfulfilledOrderNumbers} />
      </div>
    )
  }

  const singleWarningTitle = isOrderDetailView
    ? 'A dropshipment request cannot be sent:'
    : 'A dropshipment request will not be sent for:'
  const pluralWarningTitle = 'Dropshipment requests will not be sent for:'
  return (
    <div className="margin-bottom-15">
      <UnusableWarnings
        warningMessages={warningMessages}
        singleTitle={singleWarningTitle}
        pluralTitle={pluralWarningTitle}
        showDeselect={!isOrderDetailView}
        deselect={(...args) => deselectOrders(...args)}
      />
    </div>
  )
}

DropshipTab.propTypes = {
  needsSupplier: PropTypes.bool.isRequired,
  isOrderDetailView: PropTypes.bool.isRequired,
  unfulfilledOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderNumbersWithShippingLabels: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  dropshippedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  archivedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelledOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  mergedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  markAsShippedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  manuallyTrackedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
}
