import PropTypes from 'prop-types'

import {getState, updateForm, onlyIfAutoForm} from '../../../../store.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {THREEDCART_PUBLIC_KEY} from '../../../../common/constants/index.js'
import {THREEDCART} from '../../../../common/constants/CartVendorOptions.js'
import {getCarts, cartsSelector} from '../../../../data/carts.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import TextInput from '../../Common/TextInput.js'
import VendorForm from './VendorForm.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      authorize,
    },
  }
}

export function errorsSelector(state, {formName}) {
  return baseCartErrorsSelector(state, {formName})
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return baseNewCartParamsSelector(state, {vendor: THREEDCART})
  }

  return baseEditCartParamsSelector(getState(), {cartID})
}

export function saveParamsSelector(state, {formName}) {
  return baseSaveParamsSelector(state, {formName})
}

export async function authorize(formName) {
  updateForm(formName, {
    serverError: null,
  })

  await getCarts()

  const carts = cartsSelector(getState())

  const cart = Object.values(carts).find((c) => c.vendor === THREEDCART)

  if (!cart) {
    updateForm(formName, {
      serverError: {
        error_message: 'Please add our public key to your 3D Cart first.',
      },
    })

    return
  }
}

function ThreeDCart({form, formName}) {
  const isNew = form.id === NEW_ID

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              label="Public Key (copy this)"
              value={THREEDCART_PUBLIC_KEY}
              spellCheck="false"
              name="threedcart_publickey"
              type="text"
              onClick={(event) => {
                event.target.setSelectionRange(0, event.target.value.length)
              }}
              readOnly
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="3dcart"
          href="https://support.ordoro.com/3dcart-setup-with-ordoro/"
        />
      </div>
    </VendorForm>
  )
}

ThreeDCart.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
}

export default onlyIfAutoForm(ThreeDCart, setUpCartForm)
