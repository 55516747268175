import {useEffect} from 'react'
import analytics from '../../common/analytics/index.js'
import {useSelector} from '../../store.js'
import {usesAppsSelector} from '../../data/company.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'

export default function AnalyticsFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)

  useEffect(() => {
    analytics.trackEvent('analytics_feature_lock')
  }, [])

  return (
    <>
      <div className="row margin-top-30">
        <div className="medium-8 medium-centered columns align-center">
          <div
            className="i-lock-closed fs-05 op-30 lh-sm margin-bottom-0"
            aria-hidden="true"
          />
          <h3 className="fs-03 lh-md margin-top-5 margin-bottom-5">
            <strong>Analytics is not enabled in your account.</strong>
          </h3>
          <p className="fs-02">
            To access this feature,{' '}
            {usesApps ? (
              <ButtonLink
                className="meta-feature-lock-analytics-header btn--link mid border-underline"
                onClick={() => showUpgradeModal()}
              >
                you need to upgrade your Shipping plan
              </ButtonLink>
            ) : (
              <a
                className="btn--link mid border-underline meta-feature-lock-analytics-header"
                href="https://www.ordoro.com/product-expert-consultation"
                target="_blank"
                rel="noopener noreferrer"
              >
                reach out to us
              </a>
            )}
            .
          </p>
        </div>
      </div>
      <div className="row margin-top-30 flex">
        <div className="medium-6 columns">
          <img
            className="screenshot"
            src="https://storage.googleapis.com/ordoro-pappy-assets/public/analytics-feature-lock.png"
            alt="Analytics screenshot"
          />
        </div>
        <div className="medium-6 columns flex--justify-col">
          <div className="margin-bottom-15">
            <p className="fs-01 lh-lg margin-bottom-15">
              <strong>Easily Monitor Your Overall Performance:</strong>
            </p>
            <ul className="list list--square margin-bottom-20">
              <li className="list__item fs-01">
                Total revenue and total costs across your entire business over
                any period of time
              </li>
              <li className="list__item fs-01">
                Total shipping costs (broken down by carrier)
              </li>
              <li className="list__item fs-01">
                Total value of your inventory (and how much cash is tied up in
                it)
              </li>
              <li className="list__item fs-01">
                Profit margins and revenue and cost statements
              </li>
              <li className="list__item fs-01">A whole lot more!</li>
            </ul>
            {usesApps ? (
              <>
                <p className="fs-01 lh-lg margin-bottom-20">
                  Upgrade to the Advanced or Premium plan in the Shipping app to
                  access Advanced Analytics.
                </p>
                <div>
                  <ButtonPrimary
                    className="meta--feature-lock-analytics"
                    alt
                    onClick={() => showUpgradeModal()}
                  >
                    Upgrade Your Plan
                  </ButtonPrimary>
                </div>
              </>
            ) : (
              <>
                <p className="fs-01 lh-lg margin-bottom-20">
                  Chat with one of our Product Experts to get Analytics added to
                  your account.
                </p>
                <div>
                  <a
                    className="btn btn--primary btn--primary-alt btn--md meta--feature-lock-analytics"
                    href="https://www.ordoro.com/product-expert-consultation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Talk to a Product Expert
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
