import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {setCurrent} from '../../redux/actions/ui/dataImport/index.js'

export function StrategiesList({current, strategyClasses, setCurrent}) {
  return (
    <p className="centered-text margin-bottom-20">
      <strong className="margin-right-5">
        Type of data you want to import:
      </strong>
      <select
        id="import-type"
        name="import-type"
        className="select bigger-select margin-bottom-0"
        value={current}
        onChange={(event) => setCurrent(event.target.value)}
      >
        {strategyClasses.map((strategyClass) => (
          <option key={strategyClass.title} value={strategyClass.title}>
            {strategyClass.title}
          </option>
        ))}
      </select>
    </p>
  )
}

StrategiesList.propTypes = {
  strategyClasses: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired,
  setCurrent: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    current: state.ui.dataImport.current,
  }
}

const mapDispatchToProps = {
  setCurrent,
}

export default connect(mapStateToProps, mapDispatchToProps)(StrategiesList)
