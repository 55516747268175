import {useEffect} from 'react'

import {dispatch, useSelector} from '../../../store.js'
import {getAPIKeys} from '../../../redux/actions/data/apiKeys.js'
import {
  canManageUserPermissionSelector,
  usesAPIKeysSelector,
} from '../../../data/me.js'
import {apiKeysHaveLoadedSelector} from '../../../redux/selectors/data/hasLoaded.js'
import {apiKeysSortedByNameSelector} from '../../../redux/selectors/data/apiKeys.js'
import SettingsPanel from '../SettingsPanel.js'
import EditForm from './EditForm.js'
import NewForm from './NewForm.js'
import APIKeysFeaturePage from './APIKeysFeaturePage.js'

export default function APIKeys() {
  const hasLoaded = useSelector(apiKeysHaveLoadedSelector)
  const apiKeys = useSelector(apiKeysSortedByNameSelector)
  const canManageUser = useSelector(canManageUserPermissionSelector)
  const usesAPIKeys = useSelector(usesAPIKeysSelector)

  useEffect(() => {
    if (usesAPIKeys) {
      dispatch(getAPIKeys())
    }
  }, [usesAPIKeys])

  if (!usesAPIKeys) {
    return <APIKeysFeaturePage />
  }

  return (
    <SettingsPanel header="API Keys">
      <div className="row">
        {hasLoaded && (
          <ul className="settings-list medium-12 columns">
            {apiKeys.map((apiKey) => (
              <EditForm key={apiKey.id} apiKey={apiKey} />
            ))}
            {canManageUser && <NewForm />}
          </ul>
        )}
      </div>
    </SettingsPanel>
  )
}
