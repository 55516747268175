import {openModal, closeModal} from '../../ui/index.js'

export const BASE = 'ordoro/ui/modal/confirmDropshipProductModal'
export const SET_PRODUCT_COUNT = `${BASE}/SET_PRODUCT_COUNT`
export const SET_IS_CONFIRMED = `${BASE}/SET_IS_CONFIRMED`

export const MODAL_NAME = 'CONFIRM_DROPSHIP_PRODUCT_MODAL'

export function setProductCount(productCount) {
  return {
    type: SET_PRODUCT_COUNT,
    payload: productCount,
  }
}

export function showConfirmDropshipProductModal(productCount = 1) {
  return openModal(MODAL_NAME, {productCount})
}

export function confirm(isConfirmed) {
  return closeModal({isConfirmed})
}
