import PropTypes from 'prop-types'

import {TagsShape} from '../../common/PropTypes.js'
import {hasPOManageTagOnPOPermissionSelector} from '../../data/me.js'
import AssignedTags from '../../common/components/List/AssignedTags/index.js'
import {useSelector} from '../../store.js'
import {removeTagFromPOs} from '../../data/poTags.js'

export function POTags({poID, tags, className}) {
  const hasPOManageTagOnPOPermission = useSelector(
    hasPOManageTagOnPOPermissionSelector,
  )

  return (
    <AssignedTags
      tags={tags}
      className={className}
      hasManageTagOnOrderPermission={hasPOManageTagOnPOPermission}
      onRemove={(tag) => removeTagFromPOs(tag.id, [poID])}
    />
  )
}

POTags.propTypes = {
  poID: PropTypes.string.isRequired,
  tags: TagsShape.isRequired,
  className: PropTypes.string,
}
