import PropTypes from 'prop-types'
import {Fragment} from 'react'
import {createSelector} from 'reselect'

import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import api from '../../common/api.js'
import {
  hasCancellationReasonSelector,
  usesInventorySelector,
  setCompany,
} from '../../data/company.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  setFormValue,
  updateForm,
  useSelector,
} from '../../store.js'
import Checkbox from '../../common/components/Checkbox.js'
import TextInput from '../../common/components/TextInput.js'
import TextArea from '../../common/components/TextArea.js'
import {isPresent} from '../../common/utils.js'
import {meSelector} from '../../data/me.js'

const MODAL_FORM = 'CANCELLATION_MODAL'
export const REASON_OPTIONS = [
  {value: 'business_closing', display: 'Our business is closing'},
  {value: 'too_expensive', display: 'Ordoro is too expensive'},
  {value: 'low_order_volume', display: 'Our order volume is low'},
  {value: 'not_ready', display: 'We’re not ready to begin using Ordoro'},
  {
    value: 'switched_to_3PL',
    display: 'We’ve started using a 3PL to fulfill our orders',
  },
  {
    value: 'switched_to_other_software',
    display: 'We’re switching to different software',
    explainedKey: 'switched_to_other_software_explained',
    explainedDisplay: 'Which software are you switching to?',
  },
  {
    value: 'missing_feature',
    display: 'Ordoro is missing features that we need',
    explainedKey: 'missing_feature_explained',
    explainedDisplay: 'What features are missing?',
  },
  {
    value: 'not_working_for_our_needs',
    display: 'Ordoro just isn’t meeting our needs',
  },
]

export async function showCancellationModal() {
  const me = meSelector(getState())

  setForm(MODAL_FORM, {
    reason: {user_id: me.id, user_name: me.name, user_email: me.email},
    serverError: null,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(modalFormSelector, ({reason}) => {
  const hasCancellationReason = hasCancellationReasonSelector(getState())
  const errors = {}

  if (hasCancellationReason) {
    return errors
  }

  let hasReasonSelected = false
  for (const {value, explainedKey} of REASON_OPTIONS) {
    if (explainedKey && reason[value] && !isPresent(reason[explainedKey])) {
      errors[explainedKey] = 'More info is required'
      errors.preventSave = true
    }

    if (reason[value]) {
      hasReasonSelected = true
    }
  }

  if (!hasReasonSelected) {
    errors.preventSave = true
  }

  return errors
})

export async function deactivateCompany() {
  const {reason} = modalFormSelector(getState())

  updateModalForm({isSaving: true, serverError: null})

  try {
    const {json} = await api.post('/company/deactivate/', {
      reason,
      deactivated: null,
    })

    setCompany(json)
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  } finally {
    updateModalForm({isSaving: false})
  }
}

function ReasonPanel() {
  const form = useSelector(modalFormSelector)
  const usesInventory = useSelector(usesInventorySelector)

  return (
    <div className="row">
      <div className="medium-12 columns">
        <div className="alert alert--warning margin-bottom-20">
          <dl className="list">
            <dt className="list__title fs-00 lh-md margin-bottom-5">
              IMPORTANT — Export Your Historical Data First!
            </dt>
            <dd className="list__item fs-n0 margin-bottom-10">
              When your account is closed, all of your data will be permanently
              deleted and cannot be recovered. We recommend exporting all of it
              BEFORE cancelling your account. Here are some handy support
              articles to help you export:
            </dd>
            <dd className="list__item fs-n0">
              <a
                className="btn--link mid"
                href="https://support.ordoro.com/export-order-data-into-a-spreadsheet/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Exporting order data</strong>
              </a>

              {usesInventory && (
                <>
                  <span className="margin-left-5 margin-right-5">·</span>
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/exporting-inventory-data-into-a-spreadsheet/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Exporting inventory data</strong>
                  </a>
                  <span className="margin-left-5 margin-right-5">·</span>
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/how-do-i-export-purchase-orders-into-a-spreadsheet/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Exporting purchase order data</strong>
                  </a>
                  <span className="margin-left-5 margin-right-5">·</span>
                  <a
                    className="btn--link mid"
                    href="https://support.ordoro.com/how-do-i-export-goods-receipts-into-a-spreadsheet/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Exporting goods receipt data</strong>
                  </a>
                </>
              )}
              <span className="margin-left-5 margin-right-5">·</span>
              <a
                className="btn--link mid"
                href="https://support.ordoro.com/how-do-i-export-my-usps-transactions-in-my-account/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Exporting USPS transaction history</strong>
              </a>
            </dd>
          </dl>
        </div>
        <div className="margin-bottom-20">
          <label className="fs-01 lh-sm-md" htmlFor="reason">
            Please tell us why you’re leaving<span className="required">*</span>{' '}
            <div className="unbold">
              <em className="fs-n0">(check all that apply)</em>
            </div>
          </label>
          <ul className="list list--no-style margin-top-15">
            {REASON_OPTIONS.map(
              ({value, display, explainedKey, explainedDisplay}) => (
                <Fragment key={value}>
                  <li className="list__item margin-bottom-10 wrap--label-unbold">
                    <Checkbox
                      id={value}
                      label={display}
                      checked={!!form.reason[value]}
                      onChange={(checked) =>
                        setFormValue(MODAL_FORM, ['reason', value], checked)
                      }
                    />
                  </li>
                  {explainedKey && form.reason[value] && (
                    <li className="list__item wrap--label-unbold margin-left-20 margin-bottom-15">
                      <TextInput
                        required
                        label={explainedDisplay || 'Explain'}
                        id={explainedKey}
                        value={form.reason[explainedKey] || ''}
                        onChange={(value) =>
                          setFormValue(
                            MODAL_FORM,
                            ['reason', explainedKey],
                            value,
                          )
                        }
                        autoFocus={!!form.reason[value]}
                      />
                    </li>
                  )}
                </Fragment>
              ),
            )}
          </ul>
        </div>
        <div className="margin-top-30">
          <TextArea
            className="fs-n1 lh-md"
            label="Is there anything we can do to keep you as a customer?"
            size="sm"
            id="what_would_it_take"
            value={form.reason.what_would_it_take || ''}
            onChange={(value) =>
              setFormValue(MODAL_FORM, ['reason', 'what_would_it_take'], value)
            }
          />
        </div>
      </div>
    </div>
  )
}

function DonePanel() {
  return (
    <div className="row">
      <div className="medium-12 columns">
        <p className="fs-01 lh-md margin-bottom-10">
          <strong>Your cancellation has been initiated.</strong>
        </p>
        <p className="fs-00 margin-bottom-20">
          We’re sorry to see you go! Your cancellation will be completed before
          the next billing period.
        </p>
      </div>
    </div>
  )
}

function CancellationModal({form}) {
  const errors = useSelector(errorsSelector)
  const hasCancellationReason = useSelector(hasCancellationReasonSelector)

  return (
    <ConfirmModal
      title="Cancel Your Account"
      modalSize="sm"
      onConfirm={!hasCancellationReason ? deactivateCompany : null}
      onCancel={closeModal}
      confirmText="Cancel my account"
      cancelText={!hasCancellationReason ? 'I changed my mind' : 'Got it'}
      error={form.serverError}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
    >
      {hasCancellationReason ? <DonePanel /> : <ReasonPanel />}
    </ConfirmModal>
  )
}

CancellationModal.propTypes = {
  form: PropTypes.shape({
    reason: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(CancellationModal, modalFormSelector)
