import PropTypes from 'prop-types'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'

import {showOrderListFilterModal} from '../../../../OrderListPage/Modals/OrderListFilterModal/orderListFilterModalFunctions.js'
import {
  canSchedulePickupSelector,
  showPickupModal,
} from '../../../../OrderListPage/Modals/PickupModal/pickupModalFunctions.js'
import HeaderDropdown from '../../../../../common/components/List/HeaderDropdown.js'

import OrderListItemsCount from '../OrderListItemsCount.js'
import OrderSelectAllButton from './OrderSelectAllButton.js'
import ExpandAll from './ExpandAll.js'
import OrderSort from './OrderSort.js'
import RefreshOrders from './RefreshOrders.js'
import BarcodeDropdown from './BarcodeDropdown.js'
import {navigateToBatchListPage} from '../../../../BatchListPage/batchListActions.js'
import {showEditTagModal} from '../../../../../common/components/Modal/EditTagModal.js'
import {TAG_TYPE__ORDER} from '../../../../../common/constants/Tags.js'
import {hasOrderManageTagPermissionSelector} from '../../../../../data/me.js'
import {
  orderListQueryParamsSelector,
  countSelector,
  summarizeOrderDateRangeParams,
  batchReferenceIDSelector,
} from '../../../../OrderListPage/orderListSelectors.js'
import {showQuickShipModal} from '../../../../OrderListPage/Modals/QuickShipModal/quickShipModalFunctions.js'
import {needsShipperSelector} from '../../../../../data/shippers.js'
import {showEditReportModal} from '../../../../ReportsPage/Modals/EditReportModal.js'
import {
  REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
  REPORT_EXPORT_ORDERS,
  REPORT_EXPORT_SKU_SALES,
} from '../../../../../common/constants/Reports.js'
import {
  canCreateScanFormSelector,
  showScanFormModal,
} from '../../../../OrderListPage/Modals/ScanFormModal/scanFormModalFunctions.js'
import {
  AWAITING_FULFILLMENT,
  SHIPPED,
} from '../../../../../common/constants/Orders.js'
import {useQuickShipSelector} from '../../../../../data/company.js'
import {canUseBatchesSelector} from '../../../../../data/batches.js'
import {orderNumbersInBatchSelector} from '../../../../../data/orders.js'
import {showCreateBatchModal} from '../../../../BatchListPage/Modals/CreateBatchModal.js'
import {showAddToBatchModal} from '../../../../BatchListPage/Modals/AddToBatchModal.js'
import {showRemoveFromBatchModal} from '../../../../BatchListPage/Modals/RemoveFromBatchModal.js'
import {showBulkUpdateModal} from '../../../../BatchListPage/Modals/BulkUpdateModal.js'
import {showRefreshRatesModal} from '../../../../BatchListPage/Modals/RefreshRatesModal.js'
import {showCreateLabelsModal} from '../../../../BatchListPage/Modals/CreateLabelsModal.js'
import {showSortBatchModal} from '../../../../BatchListPage/Modals/SortBatchModal.js'

export function BatchActions({orderNumbers = []}) {
  const canUseBatches = useSelector(canUseBatchesSelector)
  const needsShipper = useSelector(needsShipperSelector)

  const orderNumbersInBatch = useSelector((state) =>
    orderNumbersInBatchSelector(state, {orderNumbers}),
  )
  const orderListQueryParams = useSelector(orderListQueryParamsSelector)
  const orderListCount = useSelector(countSelector)
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const movingFromBatch = !!batchReferenceID
  const action = movingFromBatch ? 'Move' : 'Add'

  if (!canUseBatches) {
    return null
  }

  return (
    <>
      <li className="list__item list__item--dropdown list__item--dropdown-header">
        <strong>BATCH ACTIONS</strong>
      </li>
      <li className="list__item list__item--dropdown">
        <button
          className="btn--link list__link--dropdown"
          type="button"
          onClick={() => showCreateBatchModal({orderNumbers, movingFromBatch})}
        >
          {orderNumbers.length === 0
            ? 'Create a New Batch'
            : `${action} All Selected Orders to a New Batch`}
        </button>
      </li>
      {orderNumbers.length === 0 && (
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showCreateBatchModal({
                orderListQueryParams,
                movingFromBatch,
                orderListCount,
              })
            }
          >
            {action} All Filtered Orders ({orderListCount}) to a New Batch
          </button>
        </li>
      )}
      {orderNumbers.length === 0 ? (
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showAddToBatchModal({
                orderListQueryParams,
                movingFromBatch,
                orderListCount,
              })
            }
          >
            {action} All Filtered Orders ({orderListCount}) to an Existing Batch
          </button>
        </li>
      ) : (
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() => showAddToBatchModal({orderNumbers, movingFromBatch})}
          >
            {action} All Selected Orders to an Existing Batch
          </button>
        </li>
      )}
      {orderNumbersInBatch.length > 0 && (
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() => showRemoveFromBatchModal(orderNumbers)}
          >
            Remove from Batch
          </button>
        </li>
      )}

      {!needsShipper && batchReferenceID && (
        <>
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showBulkUpdateModal([batchReferenceID])}
            >
              Bulk Update
            </button>
          </li>
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showRefreshRatesModal([batchReferenceID])}
            >
              Refresh Rates
            </button>
          </li>
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() =>
                showCreateLabelsModal({
                  referenceIDs: [batchReferenceID],
                  includeLabels: false,
                })
              }
            >
              Create Pick/Pack Lists
            </button>
          </li>
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() =>
                showCreateLabelsModal({referenceIDs: [batchReferenceID]})
              }
            >
              Create Labels
            </button>
          </li>
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showSortBatchModal([batchReferenceID])}
            >
              Sort Orders
            </button>
          </li>
        </>
      )}
    </>
  )
}

BatchActions.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string),
}

export default function FilterControls() {
  const useQuickShip = useSelector(useQuickShipSelector)
  const canCreateScanForm = useSelector(canCreateScanFormSelector)
  const canSchedulePickup = useSelector(canSchedulePickupSelector)
  const hasOrderManageTagPermission = useSelector(
    hasOrderManageTagPermissionSelector,
  )
  const needsShipper = useSelector(needsShipperSelector)
  const orderQuery = useSelector(orderListQueryParamsSelector)
  const queryTotalCount = useSelector(countSelector)
  const {start_date, end_date} = useMemo(
    () => summarizeOrderDateRangeParams(orderQuery),
    [orderQuery],
  )
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const status = orderQuery.status || []

  return (
    <ul className="list list--no-style list--orders-top-action-bar">
      <li className="inline-block v-align-middle margin-right-7">
        <OrderSelectAllButton />
      </li>
      <li className="inline-block margin-right-3">
        <OrderListItemsCount />
      </li>
      <RefreshOrders />
      <ExpandAll />
      {(status.length === 0 ||
        status.includes(AWAITING_FULFILLMENT) ||
        status.includes(SHIPPED)) && <BarcodeDropdown />}
      <li className="inline-block divider--left">
        <button
          className="btn btn--primary btn--xx-sm btn--primary-ol"
          type="button"
          onClick={() => showOrderListFilterModal({query: orderQuery})}
        >
          <span
            className="i-filters fs-00 lh-0 v-align-middle margin-right-5"
            aria-hidden="true"
          />
          <span className="fs-n1 v-align-middle">Filters</span>
        </button>
      </li>
      <OrderSort />
      <HeaderDropdown
        label="Actions"
        className="divider--left"
        dropdown="DROPDOWN_ORDER_LIST_OTHER_ACTIONS"
      >
        {batchReferenceID && <BatchActions />}
        <li className="list__item list__item--dropdown list__item--dropdown-header">
          <strong>MISC ACTIONS</strong>
        </li>
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showEditReportModal({
                reportType: REPORT_EXPORT_ORDERS,
                singleRun: true,
                start_date,
                end_date,
                orderQuery,
                queryTotalCount,
              })
            }
          >
            Export Order Info
          </button>
        </li>
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showEditReportModal({
                reportType: REPORT_EXPORT_SKU_SALES,
                singleRun: true,
                start_date,
                end_date,
              })
            }
          >
            Export Sales by SKU Info
          </button>
        </li>
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown"
            type="button"
            onClick={() =>
              showEditReportModal({
                reportType: REPORT_EXPORT_DELIVERY_STATUS_SUMMARY,
                singleRun: true,
                start_date,
                end_date,
              })
            }
          >
            Export Delivery Status
          </button>
        </li>
        {canCreateScanForm && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showScanFormModal()}
            >
              SCAN Form / End of Day Actions
            </button>
          </li>
        )}
        {canSchedulePickup && (
          <li className="list__item list__item--dropdown">
            <button
              className="btn--link list__link--dropdown"
              type="button"
              onClick={() => showPickupModal()}
            >
              Schedule Pickup
            </button>
          </li>
        )}
        {!batchReferenceID && <BatchActions />}
      </HeaderDropdown>
      {hasOrderManageTagPermission && (
        <li className="inline-block fs-n0 margin-left-10">
          <button
            className="btn btn--action v-align-base"
            onClick={() => showEditTagModal(TAG_TYPE__ORDER)}
          >
            Create Tag
          </button>
        </li>
      )}
      {useQuickShip && !needsShipper && (
        <li className="inline-block fs-n0 margin-left-10">
          <button
            className="btn btn--action v-align-base meta-quickshipOrderListhasShipper"
            onClick={() => showQuickShipModal()}
          >
            Quick Ship
          </button>
        </li>
      )}
      <li className="inline-block fs-n0 margin-left-10">
        <a
          className="btn btn--action v-align-base"
          href="#/createorder"
          title="Create Order"
        >
          Create Order
        </a>
      </li>
      <li className="inline-block divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => navigateToBatchListPage()}
        >
          Batches
        </button>
      </li>
      {useQuickShip && needsShipper && (
        <li className="alert alert--warning alert--sm fs-n0 inline-block margin-left-10">
          <button
            className="btn btn--action v-align-base meta-quickshipOrderListnoShipper"
            onClick={() => showQuickShipModal()}
          >
            <strong>Create a Label with Quick Ship</strong>
          </button>
        </li>
      )}
    </ul>
  )
}
