import PropTypes from 'prop-types'
import {useRef} from 'react'
import useAutoFocus from '../hooks/useAutoFocus.js'

export default function TextArea({
  className,
  errorMessage,
  id,
  label,
  labelClassName,
  onChange,
  size,
  autoFocus,
  required,
  ...props
}) {
  const inputRef = useRef(null)

  useAutoFocus(inputRef, autoFocus)

  return (
    <>
      {label && id && (
        <label className={labelClassName} htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      )}
      <textarea
        ref={inputRef}
        id={id}
        className={`${className || ''} textarea-${size || 'md'}`}
        onChange={(event) => onChange(event.target.value, event)}
        {...props}
      />
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
}
