import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {setForm, removeForm, onlyIfForm, useSelector} from '../../store.js'
import {companyIDSelector} from '../../data/company.js'
import {formsSelector} from '../../redux/selectors/ui/forms.js'

export const REACTIVATION_LOCKED_MODAL = 'REACTIVATION_LOCKED_MODAL'

export function showReactivationLockedModal() {
  setForm(REACTIVATION_LOCKED_MODAL, {})
}

export function modalFormSelector(state) {
  return formsSelector(state)[REACTIVATION_LOCKED_MODAL]
}

function closeModal() {
  removeForm(REACTIVATION_LOCKED_MODAL)
}

function ReactivationLockedModal() {
  const companyID = useSelector(companyIDSelector)
  return (
    <ConfirmModal
      title="This account has been deactivated"
      modalSize="sm-md"
      onCancel={() => closeModal()}
      preventClose
    >
      <p className="fs-01 margin-bottom-15">
        <strong>CONTACT OUR SALES TEAM TO REACTIVATE</strong>
      </p>
      <p className="fs-01 margin-bottom-15">
        To gain access to reports and to ensure that all shipping accounts and
        sales channel syncs can be properly restarted, please send an email to{' '}
        <strong>info@ordoro.com</strong>.
      </p>
      <p className="fs-01 margin-bottom-0">
        Provide your support pin <strong>({companyID})</strong> and let them
        know that you want to reactivate this account. They’ll get the process
        started shortly thereafter.
      </p>
    </ConfirmModal>
  )
}

export default onlyIfForm(ReactivationLockedModal, modalFormSelector)
