import {PACK_MODE} from '../../../common/constants/PackingOrderModal.js'
import {PluralBlock, Plural} from '../../../common/components/Plural.js'
import {
  overscannedProductsSelector,
  packPanelFormSelector,
  scannedProductSelector,
} from './packPanelSelectors.js'

import PackError from './PackError.js'
import {useSelector} from '../../../store.js'

export default function ProductOverscanError() {
  const {orderNumbers, mode} = useSelector(packPanelFormSelector)
  const scannedProduct = useSelector(scannedProductSelector)
  const overscannedProducts = useSelector(overscannedProductsSelector)

  const verb = mode === PACK_MODE ? 'verified' : 'picked'

  if (
    overscannedProducts.length === 1 &&
    overscannedProducts[0].sku === scannedProduct.sku
  ) {
    return (
      <PackError>
        <PluralBlock array={orderNumbers}>
          <strong>
            “{scannedProduct.name}” already scanned and {verb} for{' '}
            <Plural p="these orders" s="this order" />.
          </strong>
        </PluralBlock>
      </PackError>
    )
  }

  return (
    <PackError>
      <PluralBlock array={orderNumbers}>
        <div className="lh-x-md divider--bottom">
          <strong>
            “{scannedProduct.name}” can’t be {verb} because there aren’t enough
            un
            {verb} components remaining for it on{' '}
            <Plural p="these orders" s="the order" />.
          </strong>
        </div>
        <ul className="list square align-left margin-bottom-15">
          {overscannedProducts.map((product) => (
            <li className="list__item" key={product.sku}>
              {product.name} ({product.scanned} in this kit, but{' '}
              {product.remaining} left on{' '}
              <Plural p="these orders" s="the order" />)
            </li>
          ))}
        </ul>
      </PluralBlock>
    </PackError>
  )
}
