import {useEffect, useCallback} from 'react'
import get from 'lodash/get.js'

import {useSelector} from '../../../../../store.js'
import {
  updateParcel,
  labelShipperSelector,
  boxShapeSelector,
  boxShapeOptionsSelector,
} from '../../../../../data/labelInfos/index.js'
import Select from '../../../../../common/components/Select.js'
import {useLabelConfigContext} from '../../../LabelConfigContext.js'

export default function BulkPackageType() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const boxShape = useSelector((state) =>
    boxShapeSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )
  const disabled = useSelector(
    (state) => !labelShipperSelector(state, {labelInfoID}),
  )
  const options = useSelector((state) =>
    boxShapeOptionsSelector(state, {labelInfoID, shipperType}),
  )

  const onChange = useCallback(
    (value) =>
      updateParcel(labelInfoID, 0, {
        [`${shipperType}__box_shape`]: value,
      }),
    [shipperType],
  )

  useEffect(() => {
    if (!boxShape && options.length > 0) {
      onChange(get(options, '[0].value'))
    }
  }, [boxShape, options])

  return (
    <Select
      className="list__item--shipping-options w-100 flex-grow"
      label="Package Type*"
      value={boxShape}
      onChange={onChange}
      disabled={disabled}
      options={options}
    />
  )
}
