import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'
import get from 'lodash/get.js'
import fpSet from 'lodash/fp/set.js'
import fpFlow from 'lodash/fp/flow.js'

import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {returnOrderSelector} from '../../../redux/selectors/data/returnOrders.js'

const MODAL_FORM = 'DELETE_RETURN_ORDER_LABELS_MODAL_FORM'
export const DELETE_RETURN_ORDER_LABELS = 'DELETE_RETURN_ORDER_LABELS'

export function showDeleteReturnOrderLabelsModal(referenceIDs) {
  return setForm(MODAL_FORM, {
    referenceIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function deleteReturnOrderLabels() {
  return {
    type: DELETE_RETURN_ORDER_LABELS,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* deleteReturnOrderLabel(referenceID) {
  const returnOrder = yield select(returnOrderSelector, {referenceID})
  const firstShippingInfoID = get(returnOrder, 'shipping_infos.0.id')

  if (!firstShippingInfoID) {
    return
  }

  yield call(
    apiverson.delete,
    `/return_order/${encodeURIComponent(
      referenceID,
    )}/shipping_info/${firstShippingInfoID}`,
  )

  const updatedReturnOrder = fpFlow(
    fpSet('has_shipping_info', false),
    fpSet('shipping_infos', []),
  )(returnOrder)

  yield put(setReturnOrder(updatedReturnOrder))
}

export function* deleteReturnOrderLabelsWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceIDs} = yield select(modalFormSelector)
    const roCount = referenceIDs.length

    yield all(referenceIDs.map((id) => call(deleteReturnOrderLabel, id)))

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${roCount} return label${roCount !== 1 ? 's were' : ' was'} deleted`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error deleting return label: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function DeleteReturnOrderLabelsModal({form, ...props}) {
  const roCount = form.referenceIDs.length

  return (
    <ConfirmModal
      title="Delete Return Label"
      onConfirm={() => props.deleteReturnOrderLabels()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      Are you sure you want to delete {roCount} return label
      {roCount !== 1 ? 's' : ''}?
    </ConfirmModal>
  )
}

DeleteReturnOrderLabelsModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  deleteReturnOrderLabels: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  deleteReturnOrderLabels,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(DeleteReturnOrderLabelsModal),
  modalFormSelector,
)
