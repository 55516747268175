import PropTypes from 'prop-types'
import {Component} from 'react'
import {select} from 'd3-selection'
import {scaleQuantize} from 'd3-scale'
import {geoAlbersUsa, geoPath} from 'd3-geo'
import {range} from 'd3-array'
import {feature} from 'topojson-client'
import d3tip from 'd3-tip'
import debounce from 'lodash/debounce.js'

import usStateShapes from '../../common/data/usStateShapes.json'
import Currency, {formatCurrency} from '../../common/components/Currency.js'
import html from '../../common/html.js'

function toolTipTemplate(state, value) {
  return html`
    <div class="popover-wrapper wrapper--popover-states">
      <div class="popover popover--states">
        <div class="arrow"></div>
        <div class="inner">
          <div class="content">
            <dl class="list--graph-text">
              <dt class="list__item--graph-title">${state}</dt>
              <dd class="list__item--graph-value">
                ${formatCurrency(value, '$')}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  `
}

const margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export default class ValueByStateGraph extends Component {
  state = {
    width: 0,
    height: 0,
  }

  componentDidMount() {
    this.svg = select(this.mapHolder).append('svg')

    this.group = this.svg.append('g').attr('class', 'states')

    this.tip = d3tip()

    this.group.call(this.tip)

    this.onResize()

    this.onResizeDebounced = debounce(() => this.onResize(), 500)

    window.addEventListener('resize', this.onResizeDebounced)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.width !== this.state.width ||
      prevState.height !== this.state.height
    ) {
      this.renderGraph()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeDebounced)
  }

  onElement = (element) => {
    if (!element || this.mapHolder === element) {
      this.mapHolder = element

      return
    }

    this.mapHolder = element
  }

  onResize() {
    if (!this.mapHolder) {
      return
    }

    this.setState({
      width: this.mapHolder.offsetWidth - margin.left - margin.right,
      height: this.mapHolder.offsetHeight - margin.top - margin.bottom,
    })
  }

  addTooltips(valueByKey) {
    this.tip
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html((event, d) => {
        var value = valueByKey[d.properties.abbv] || 0

        return toolTipTemplate(d.properties.name, value)
      })

    this.group
      .selectAll('path')
      .on('mouseover', this.tip.show)
      .on('mouseout', this.tip.hide)
  }

  renderGraph() {
    const {data} = this.props
    const {width, height} = this.state
    this.group.html('')

    if (!data) {
      this.tip.hide()

      return
    }

    if (data.buckets.length === 0) {
      return
    }

    if (height <= 0 || width <= 0) {
      return
    }

    var valueByKey = {}

    var projection = geoAlbersUsa()
    var path = geoPath().projection(projection)

    this.svg.attr('width', width).attr('height', height)

    var scaleFactor = Math.min(width / 869, height / 501)
    this.group.attr('transform', `scale(${scaleFactor})`)

    var maxValue = 0
    var minValue = Infinity
    data.buckets.forEach((d) => {
      valueByKey[d.key] = d.value
      maxValue = Math.max(maxValue, d.value)
      minValue = Math.min(minValue, d.value)
    }, this)

    if (minValue === maxValue) {
      minValue = 0
    }

    var quantize = scaleQuantize()
      .domain([minValue, maxValue])
      .range(range(9).map((i) => `q${i + 1}-10`))

    this.group
      .selectAll('path')
      .data(feature(usStateShapes, usStateShapes.objects['us-states']).features)
      .enter()
      .append('path')
      .attr('class', (d) => {
        var value = valueByKey[d.properties.abbv]
        var q

        if (value) {
          q = quantize(value)
        } else {
          q = 'q0-10'
        }

        return q
      })
      .attr('d', path)

    this.addTooltips(valueByKey)
  }

  render() {
    const {data} = this.props

    return (
      <div>
        <div className="medium-9 columns padding-right-0">
          <div className="wrap wrap--graph wrap--map" ref={this.onElement} />
        </div>
        {data && data.buckets.length > 0 && (
          <div className="medium-3 columns subdue-for-loading">
            <ol className="list--totals list--top-states">
              {data.topStates.map(({fullName, value}) => (
                <li
                  className="list__item list__item--graph-total list__item--top-state"
                  key={fullName}
                >
                  {' '}
                  <dl className="list--graph-text">
                    <dt className="list__item--graph-title">{fullName}</dt>
                    <dd className="list__item--graph-value">
                      <Currency value={value} />
                    </dd>
                  </dl>
                </li>
              ))}
            </ol>
          </div>
        )}
        {data && data.buckets.length === 0 && (
          <div className="overlay overlay--no-data">
            <dl className="list list--no-data">
              <dt className="list__title margin-bottom-0">
                <strong>No data to display for this graph</strong>
              </dt>
              <dd className="list__item list__item--sm">
                Adjust the filters or date range and try again
              </dd>
            </dl>
          </div>
        )}
      </div>
    )
  }
}

const RevenueByStateShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
})

ValueByStateGraph.propTypes = {
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(RevenueByStateShape).isRequired,
    topStates: PropTypes.arrayOf(RevenueByStateShape).isRequired,
  }),
}
