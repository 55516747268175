import {useSelector} from '../../store.js'
import {ensureArray} from '../../common/ensure.js'
import Select from '../../common/components/Form/Select.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showEditMockMOsModal} from './Modals/EditMockMOsModal.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from './mockAbodeFunctions.js'

export default function MockAbodeMOOptions() {
  const query = useSelector(querySelector)
  const referenceIDs = ensureArray(query.reference_id)

  return (
    <>
      <div>
        <ButtonLink onClick={() => showEditMockMOsModal()}>
          {referenceIDs.length === 0
            ? 'No MFG Order'
            : referenceIDs.length === 1
              ? referenceIDs[0]
              : `${referenceIDs.length} MFG Orders`}
        </ButtonLink>
      </div>
      <Select
        label="Layout"
        name="mock_abode__layout"
        value={query.layout}
        onChange={(value) => mockAbodeUpdateResourceQuery({layout: value})}
        className="margin-bottom-20"
      >
        <option value="fullpage">fullpage</option>
      </Select>
    </>
  )
}
