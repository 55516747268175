import PropTypes from 'prop-types'

import cn from '../../../common/className.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {labelConfigSelector} from '../../../data/labelInfos/index.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import {useSelector} from '../../../store.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function ConfigCheckbox({
  className,
  id,
  checked,
  disabled,
  label,
  hide,
  Label,
  onChange,
  validShipperTypes,
  labelProperty,
  labelPropertiesFunc,
  labelPropertyCheckedSelector,
  dependantPropertiesFunc,
  validLabelProperties,
}) {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()

  labelProperty = labelProperty || ''
  id = id || `${labelInfoID}__${labelProperty}`

  const {isLabelPropertyEnabled, ...rest} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes,
    dependantPropertiesFunc,
    validLabelProperties,
  })

  validShipperTypes = rest.validShipperTypes
  labelPropertiesFunc = rest.labelPropertiesFunc
  dependantPropertiesFunc = rest.dependantPropertiesFunc

  checked = useSelector((state) =>
    checked === undefined
      ? labelConfigSelector(state, {labelInfoID})[labelProperty] || false
      : checked,
  )

  hide = hide || (!!shipperType && !validShipperTypes.includes(shipperType))

  if (hide) {
    return null
  }

  return (
    <li className="flex-item list__item--shipping-options">
      <div className="wrap--edit-preset-form-input">
        <label
          className={cn`margin-bottom-0 ${formatMetaClassName(
            labelProperty,
          )} ${className}`}
          htmlFor={id}
        >
          <input
            type="checkbox"
            className="v-align-middle margin-bottom-0 margin-right-5"
            id={id}
            checked={checked}
            disabled={
              disabled || (isLabelEnableMode && !isLabelPropertyEnabled)
            }
            onChange={(event) => onChange(event.target.checked)}
          />
          {Label ? <Label /> : <span className="v-align-middle">{label}</span>}
        </label>
      </div>
      <EnableLabelProperty
        labelProperty={labelProperty}
        checkedSelector={labelPropertyCheckedSelector}
      />
    </li>
  )
}

ConfigCheckbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  Label: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validShipperTypes: PropTypes.arrayOf(PropTypes.string),
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelPropertiesFunc: PropTypes.func,
  labelPropertyCheckedSelector: PropTypes.func,
  dependantPropertiesFunc: PropTypes.func,
  validLabelProperties: PropTypes.arrayOf(PropTypes.string),
}
