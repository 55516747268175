import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  orderLinkSelector,
  billingEmailSelector,
  setOrderComments,
} from '../../../data/orders.js'

export const MODAL_FORM = 'SEND_INVOICE_MODAL_FORM'

export function showSendInvoiceModal(orderNumber) {
  setForm(MODAL_FORM, {
    orderNumber,
    isSending: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export async function sendInvoice() {
  try {
    const {orderNumber} = modalFormSelector(getState())
    const link = orderLinkSelector(getState(), {orderNumber})

    updateModalForm({isSending: true, serverError: null})

    const {json} = await apiverson.post(`${link}/send_invoice`)

    setOrderComments(orderNumber, json.comments)

    showMessageToast('The invoice was successfully sent!')

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error sending invoice: ${err.message || err.error_message}`,
      isSending: false,
    })
  }
}

function SendInvoiceModal({form, email}) {
  return (
    <ConfirmModal
      title="Send Invoice"
      confirmText="Send"
      cancelText="Cancel"
      onConfirm={() => sendInvoice()}
      onCancel={() => closeModal()}
      isSaving={form.isSending}
      isDisabled={!email}
      error={form.serverError}
    >
      {email ? (
        <p className="fs-01 margin-bottom-0">
          An invoice for this order will be emailed to <strong>{email}</strong>.
        </p>
      ) : (
        <p className="fs-01 margin-bottom-0">
          This order does not contain a valid billing email address.
        </p>
      )}
    </ConfirmModal>
  )
}

SendInvoiceModal.propTypes = {
  form: PropTypes.shape({
    isSending: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  email: PropTypes.string.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    email: billingEmailSelector(state, {orderNumber: form.orderNumber}),
  }
}

export default formConnect(
  connect(mapStateToProps)(SendInvoiceModal),
  modalFormSelector,
)
