import {useEffect} from 'react'

import {REPORTS_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {REPORTS_PAGE_URI_COMPONENT} from './reportsPageSelectors.js'
import {
  setupReportsPageForm,
  removeReportsPageForm,
} from './reportsPageActions.js'
import {dispatch, useSelector} from '../../store.js'

export default function ReportsPageLocationListener() {
  const {
    pathComponents: [resource],
  } = useSelector(locationSelector)

  const isThisPage = resource === REPORTS_PAGE_URI_COMPONENT

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    setupReportsPageForm()

    dispatch(setCurrentPage(REPORTS_PAGE))

    return () => removeReportsPageForm()
  }, [isThisPage])

  return null
}
