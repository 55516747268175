import {csvFormatRows} from 'd3-dsv'
import {isPresent} from './utils.js'

export function saveCSVFile(text, fileName) {
  if (window.THIS_IS_A_TEST_ENV) {
    saveCSVFile.last_call = [text, fileName]

    return
  }

  const file = new File([`${text}\n`], fileName, {
    type: 'text/plain',
  })

  const element = document.createElement('a')
  element.setAttribute('href', URL.createObjectURL(file))
  element.setAttribute('download', fileName)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export default function createCSVFile(headers, rows, fileName) {
  const orderedRows = rows.map((row) =>
    headers.map((header) => (isPresent(row[header]) ? row[header] : '')),
  )

  const csvText = csvFormatRows([headers, ...orderedRows])

  saveCSVFile(csvText, fileName || 'export.csv')
}
