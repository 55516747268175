import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Timeline from '../../common/components/Details/Timeline/index.js'
import {
  createPOCommentGroupsSelector,
  isPOLockedSelector,
} from '../../data/pos.js'
import {showAddPOCommentModal} from '../PurchaseOrderListPage/Modals/AddPOCommentModal.js'
import {poIDSelector} from './poDetailSelectors.js'

function POTimeline({poID, commentGroupsSelector, isPOLocked, ...props}) {
  return (
    <div className="wrap--row flex">
      <div className="medium-7 columns">
        <Timeline
          commentGroupsSelector={commentGroupsSelector}
          readOnly={isPOLocked}
          addComment={() => props.showAddPOCommentModal(poID)}
        />
      </div>
    </div>
  )
}

POTimeline.propTypes = {
  poID: PropTypes.string.isRequired,
  commentGroupsSelector: PropTypes.func.isRequired,
  isPOLocked: PropTypes.bool.isRequired,
  showAddPOCommentModal: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const poID = poIDSelector(state)
  return {
    poID,
    commentGroupsSelector: createPOCommentGroupsSelector(poID),
    isPOLocked: isPOLockedSelector(state, {poID}),
  }
}

const mapDispatchToProps = {
  showAddPOCommentModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(POTimeline)
