import PropTypes from 'prop-types'
import {forwardRef, useRef} from 'react'

import {HotKeyConnect} from '../HotKeys.js'
import cn from '../../className.js'

const ButtonPrimary = forwardRef(
  (
    {
      type,
      children,
      isLoading,
      isDeterminate,
      isDisabled,
      isDisabledWarning,
      isDisabledError,
      isOutlined,
      className,
      size,
      hotKey,
      alt,
      ...props
    },
    buttonRef,
  ) => {
    const backupInputRef = useRef()
    buttonRef = buttonRef || backupInputRef

    size = size || 'md'
    type = type || 'button'

    const button = (
      <button
        ref={buttonRef}
        className={cn`btn btn--primary ${className} ${`btn--${size}`} ${{
          'btn--primary-alt': alt,
          'btn--disabled': isDisabled,
          'btn--processing': isLoading && !isDeterminate,
          'btn--progress': isLoading && isDeterminate,
          'btn--primary-ol': isOutlined,
        }} ${
          isDisabledError
            ? 'btn--disabled-error'
            : isDisabledWarning
              ? 'btn--disabled-warning'
              : ''
        }`}
        disabled={isDisabled || isLoading}
        type={type}
        {...props}
      >
        {children}
      </button>
    )

    if (hotKey) {
      return (
        <HotKeyConnect
          code={hotKey}
          func={props.onClick}
          isDisabled={isDisabled || isLoading}
        >
          {button}
        </HotKeyConnect>
      )
    }

    return button
  },
)

ButtonPrimary.displayName = 'ButtonPrimary'

ButtonPrimary.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isDeterminate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDisabledWarning: PropTypes.bool,
  isDisabledError: PropTypes.bool,
  isOutlined: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  hotKey: PropTypes.string,
  alt: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ButtonPrimary
