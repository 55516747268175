export function join(array, separator1 = ', ', separator2 = ' and ') {
  array = [...array]
  const last = array.pop()

  if (array.length == 0) {
    return last !== undefined ? last : ''
  }

  return `${array.join(separator1)}${separator2}${last}`
}

export default function Join({array, separator1 = ', ', separator2 = ' and '}) {
  return join(array, separator1, separator2)
}
