import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  setFormValue,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  navigateToOrderListPage,
  refreshOrderListAndCounts,
} from '../orderListActions.js'
import {getProductSKUsFromOrders, orderSelector} from '../../../data/orders.js'
import apiverson from '../../../common/apiverson.js'
import {currentPageSelector} from '../../../redux/selectors/ui/index.js'
import {ORDER_DETAIL_PAGE} from '../../../common/constants/Pages.js'
import {orderDetailStatusSelector} from '../../OrderDetailPage/orderDetailSelectors.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import {ErrorsShape, OrderLineShape} from '../../../common/PropTypes.js'
import {isPositiveNumeric, isPresent} from '../../../common/utils.js'
import {productSelector, ensureProductsLoaded} from '../../../data/products.js'
import {getWarehouseName} from '../../../data/warehouses.js'

export const MODAL_FORM = 'SPLIT_ORDER_MODAL_FORM'

export async function showSplitOrderModal(orderNumber) {
  const order = orderSelector(getState(), {orderNumber})
  const skus = getProductSKUsFromOrders([order])

  setForm(MODAL_FORM, {
    orderNumber,
    targetQuantities: {},
    isSaving: false,
    serverError: null,
  })

  await ensureProductsLoaded(skus, {refresh: true})
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function setTargetQuantity(lineID, value) {
  setFormValue(MODAL_FORM, ['targetQuantities', lineID], String(value))
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const form = modalFormSelector(state)
  const order = orderSelector(state, {orderNumber: form.orderNumber})
  const errors = {}

  for (const line of order.lines) {
    if (form.targetQuantities[line.id] === undefined) {
      continue
    }

    const targetQuantity = form.targetQuantities[line.id]

    if (isPresent(targetQuantity) && !isPositiveNumeric(targetQuantity)) {
      errors[`targetQuantities__${line.id}`] =
        'Quantity must be a positive number'

      errors.preventSave = true
    }

    if (targetQuantity > line.quantity) {
      errors[`targetQuantities__${line.id}`] =
        `Quantity cannot exceed ${line.quantity}`

      errors.preventSave = true
    }
  }

  return errors
}

function originQuantityCalculation(targetQuantity, initialQuantity) {
  const originQuantity = initialQuantity - targetQuantity

  return originQuantity >= 0 ? originQuantity : 0
}

export function paramsSelector(state) {
  const {orderNumber, targetQuantities} = modalFormSelector(state)
  const order = orderSelector(state, {orderNumber})

  const params = {
    split_orders: [{lines: []}, {lines: []}],
  }

  order.lines.forEach((line) => {
    const targetLineQuantity = Number(targetQuantities[line.id]) || 0
    const originQuantity = originQuantityCalculation(
      targetLineQuantity,
      line.quantity,
    )

    if (
      originQuantity !== 0 ||
      (originQuantity === 0 && targetLineQuantity === 0)
    ) {
      params.split_orders[0].lines.push({
        line_id: line.id,
        quantity: originQuantity,
      })
    }

    if (targetLineQuantity !== 0) {
      params.split_orders[1].lines.push({
        line_id: line.id,
        quantity: targetLineQuantity,
      })
    }
  })

  return params
}

export async function splitOrder() {
  try {
    const {orderNumber} = modalFormSelector(getState())

    const params = paramsSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    await apiverson.post(
      `/order/${encodeURIComponent(orderNumber)}/split`,
      params,
    )

    showMessageToast(
      `Order ${orderNumber} was successfully split into two new orders.`,
    )

    closeModal()

    if (currentPageSelector(getState()) == ORDER_DETAIL_PAGE) {
      const status = orderDetailStatusSelector(getState())

      navigateToOrderListPage({status})
    } else {
      await refreshOrderListAndCounts()
    }
  } catch (err) {
    updateModalForm({
      serverError: `Error encountered while splitting order: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function Line({line, targetQuantity, errors, assignedWarehouseID}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  targetQuantity = targetQuantity !== undefined ? targetQuantity : '0'
  const originQuantity = originQuantityCalculation(
    Number(targetQuantity),
    line.quantity,
  )
  const error = errors[`targetQuantities__${line.id}`]
  const assignedWarehouse = product.warehouses.find(
    ({id}) => id === assignedWarehouseID,
  )

  return (
    <tr>
      <td className="table__td">
        <dl className="list">
          <dt className="list__title fs-01 lh-md margin-bottom-0">
            {line.product_name}
          </dt>
          <dd className="list__item fs-n0 lh-md margin-bottom-0">
            SKU: {line.sku}
          </dd>
          <dd className="list__item fs-n2 lh-md divider--top-stubby text--md-grey margin-bottom-0">
            <span>{getWarehouseName(assignedWarehouse)}</span> POH:{' '}
            {assignedWarehouse.physical_on_hand}
          </dd>
          <dd className="list__item fs-n2 lh-md text--md-grey margin-bottom-0">
            Total POH: {product.total_on_hand}
          </dd>
        </dl>
      </td>
      <td className="table__td align-center">
        <strong
          className={`fs-02 lh-md v-align-middle ${
            originQuantity === 0 ? 'op-50' : ''
          }`}
        >
          {originQuantity}
        </strong>
      </td>
      <td className="table__td border-left--light align-center">
        <div className="flex flex--jc-center">
          <NumberInput
            min={0}
            max={line.quantity}
            placeholder={0}
            value={targetQuantity}
            onChange={(value) => setTargetQuantity(line.id, value)}
          />
        </div>
        {error && <small className="error">{error}</small>}
      </td>
    </tr>
  )
}

Line.propTypes = {
  line: OrderLineShape.isRequired,
  targetQuantity: PropTypes.string,
  errors: ErrorsShape.isRequired,
  assignedWarehouseID: PropTypes.number.isRequired,
}

function SplitOrderModal({form}) {
  const order = useSelector((state) =>
    orderSelector(state, {orderNumber: form.orderNumber}),
  )
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Split Order"
      modalSize="md"
      confirmText="Split Order"
      onConfirm={() => splitOrder()}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <p>
        Split items from an <strong>existing order</strong> (
        {order.order_number}) into <strong>two new orders</strong>.
      </p>

      {order.status == 'dropshipment_requested' && (
        <div className="alert alert--standard align-center margin-bottom-25">
          <p className="fs-00 lh-md margin-bottom-0">
            <strong>
              Dropshippers will not be notified after splitting an already
              dropshipped order.
            </strong>
          </p>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--top-border th-width-45">
              Product Name/SKU
            </th>
            <th className="table__th table__th--top-border th-width-30 align-center">
              Qty in New Order 1
            </th>
            <th className="table__th table__th--top-border border-left--light th-width-25 align-center">
              Qty in New Order 2
            </th>
          </tr>
        </thead>
        <tbody className="table__tbody--lines">
          {order.lines.map((line, index) => (
            <Line
              key={index}
              line={line}
              targetQuantity={form.targetQuantities[line.id]}
              errors={errors}
              assignedWarehouseID={order.warehouse.id}
            />
          ))}
        </tbody>
      </table>
    </ConfirmModal>
  )
}

SplitOrderModal.propTypes = {
  form: PropTypes.shape({
    orderNumber: PropTypes.string.isRequired,
    targetQuantities: PropTypes.objectOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(SplitOrderModal, modalFormSelector)
