import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {TagsShape} from '../../common/PropTypes.js'
import AssignedTags from '../../common/components/List/AssignedTags/index.js'
import {removeTagFromMOs} from '../../data/moTags.js'
import {hasMOManageTagOnMOPermissionSelector} from '../../data/me.js'

export function MOTags({referenceID, tags, hasMOManageTagOnMOPermission}) {
  return (
    <AssignedTags
      tags={tags}
      hasManageTagOnOrderPermission={hasMOManageTagOnMOPermission}
      onRemove={(tag) => removeTagFromMOs(tag.id, [referenceID])}
    />
  )
}

MOTags.propTypes = {
  referenceID: PropTypes.string.isRequired,
  tags: TagsShape.isRequired,
  hasMOManageTagOnMOPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    hasMOManageTagOnMOPermission: hasMOManageTagOnMOPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(MOTags)
