import PropTypes from 'prop-types'
import cn from '../../../common/className.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'
import useLabelProperty from './useLabelProperty.js'

export default function ConfigButton({
  className,
  id,
  disabled,
  label,
  hide,
  Label,
  onClick,
  validShipperTypes,
  labelProperty,
  labelPropertiesFunc,
  labelPropertyCheckedSelector,
  dependantPropertiesFunc,
  validLabelProperties,
  errorMessage,
  children,
}) {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()

  labelProperty = labelProperty || ''
  id = id || `${labelInfoID}__${labelProperty}`

  const {isLabelPropertyEnabled, ...rest} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes,
    dependantPropertiesFunc,
    validLabelProperties,
  })

  validShipperTypes = rest.validShipperTypes
  labelPropertiesFunc = rest.labelPropertiesFunc
  dependantPropertiesFunc = rest.dependantPropertiesFunc

  hide = hide || (!!shipperType && !validShipperTypes.includes(shipperType))

  if (hide) {
    return null
  }

  return (
    <li
      className={cn`${className} list__item--shipping-options margin-bottom-15 ${
        errorMessage ? 'error' : ''
      }`}
    >
      <div className="wrap--edit-preset-form-input">
        <label
          className="inline-block margin-right-5 margin-bottom-0"
          htmlFor={id}
        >
          {Label ? <Label /> : label}
        </label>
        <button
          className="btn btn--link inline-block fs-n1 v-align-base"
          type="button"
          id={id}
          disabled={disabled || (isLabelEnableMode && !isLabelPropertyEnabled)}
          onClick={() => onClick(labelInfoID, shipperType)}
        >
          {children}
        </button>
      </div>
      <EnableLabelProperty
        labelProperty={labelProperty}
        checkedSelector={labelPropertyCheckedSelector}
      />
    </li>
  )
}

ConfigButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  Label: PropTypes.func,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  validShipperTypes: PropTypes.arrayOf(PropTypes.string),
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelPropertiesFunc: PropTypes.func,
  labelPropertyCheckedSelector: PropTypes.func,
  dependantPropertiesFunc: PropTypes.func,
  validLabelProperties: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  children: PropTypes.node,
}
