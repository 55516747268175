import {OrderShape} from '../../../../../common/PropTypes.js'
import {ORDER_STATUS_BY_SLUG} from '../../../../../common/constants/Orders.js'

export default function OrderStatus({order}) {
  return <span>{ORDER_STATUS_BY_SLUG[order.status].name}</span>
}

OrderStatus.propTypes = {
  order: OrderShape.isRequired,
}
