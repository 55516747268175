import {useCallback, useEffect, useMemo} from 'react'
import uniq from 'lodash/uniq.js'

import {
  isLabelPropertyEnabledSelector,
  updateLabelPropertyChart,
} from '../../../data/labelInfos/index.js'
import {useSelector} from '../../../store.js'
import {SHIPPER_VENDORS} from '../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import {LABEL_PROPERTY_SHIPPER_IDS} from './ShippersFilter.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export function formatMetaClassName(labelProperty) {
  return `meta-labelconfigform-input-${(labelProperty || '')
    .toLowerCase()
    .replace(/[-_]/, '')}`
}

export const EMPTY_DEPENDANT_PROPERTIES_FUNC = () => []
export const DEFAULT_DEPENDANT_PROPERTIES_FUNC = () => [
  LABEL_PROPERTY_SHIPPER_ID,
  LABEL_PROPERTY_SHIPPER_IDS,
]

/**
 * Hook to package up all the label property (preset) hooks and param defaulting
 *
 * @param {{
 *  shipperType: string,
 *  labelInfoID: string,
 *  labelProperty: string,
 *  labelPropertiesFunc: func,
 *  validShipperTypes: Array,
 *  dependantPropertiesFunc: func,
 *  validLabelProperties: Array,
 * }} param0
 * @returns {{
 *  isLabelEnableMode: boolean,
 *  isLabelPropertyEnabled: boolean,
 *  shipperType: string,
 *  labelPropertiesFunc: func,
 *  dependantPropertiesFunc: func,
 *  validShipperTypes: Array,
 * }}
 */
export default function useLabelProperty({
  labelProperty,
  labelPropertiesFunc,
  validShipperTypes,
  dependantPropertiesFunc,
  validLabelProperties,
}) {
  const {labelInfoID, isLabelEnableMode} = useLabelConfigContext()
  validShipperTypes = validShipperTypes || SHIPPER_VENDORS

  // Assume dependant (parent) property is just `shipper_id`
  // But if all shippers are valid then assume no dependant properties
  // But let it be overridable
  dependantPropertiesFunc =
    dependantPropertiesFunc ||
    (validShipperTypes === SHIPPER_VENDORS
      ? EMPTY_DEPENDANT_PROPERTIES_FUNC
      : DEFAULT_DEPENDANT_PROPERTIES_FUNC)

  // Assume label properties are just `labelProperty` if given shipper type is a valid shipper
  // But let it be overridable
  labelPropertiesFunc = useCallback(
    labelPropertiesFunc ||
      ((shipperType) =>
        validShipperTypes.includes(shipperType) ? [labelProperty] : []),
    [labelProperty],
  )

  // Assume valid label properties are just all the properties from labelPropertiesFunc from all valid shippers
  // But let it be overridable
  validLabelProperties = useMemo(
    () =>
      validLabelProperties ||
      uniq(
        validShipperTypes.reduce(
          (prev, shipperType) => [
            ...prev,
            ...labelPropertiesFunc(shipperType, labelInfoID),
          ],
          [],
        ),
      ),
    [],
  )

  const isLabelPropertyEnabled = useSelector((state) =>
    isLabelPropertyEnabledSelector(state, {labelInfoID, labelProperty}),
  )

  useEffect(() => {
    if (!isLabelEnableMode) {
      return
    }

    updateLabelPropertyChart(
      labelInfoID,
      labelProperty,
      validShipperTypes,
      labelPropertiesFunc,
      dependantPropertiesFunc,
      validLabelProperties,
    )
  }, [labelProperty])

  return {
    isLabelPropertyEnabled,
    labelPropertiesFunc,
    dependantPropertiesFunc,
    validShipperTypes,
  }
}
