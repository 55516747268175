import {createSelector} from 'reselect'

import {awaitingFulfillmentCountSelector} from '../../data/orderCounts.js'
import {canUseOrdersSelector} from '../../data/orders.js'
import {canUseProductsSelector} from '../../data/products.js'
import {defaultProductListHashSelector} from '../ProductListPage/productListFunctions.js'
import {defaultPurchaseOrderListHashSelector} from '../PurchaseOrderListPage/purchaseOrderListSelectors.js'
import {showPurchaseOrderNavSelector} from '../../data/pos.js'
import {
  showAnalyticsNavSelector,
  defaultAnalyticsHashSelector,
} from '../AnalyticsPage/analyticsSelectors.js'
import {defaultReturnOrderListHashSelector} from '../ReturnOrderListPage/returnOrderListSelectors.js'
import {canUseReturnOrdersSelector} from '../../redux/selectors/data/returnOrders.js'
import {showMONavSelector} from '../../data/mos.js'
import {defaultMOListHashSelector} from '../MOListPage/moListSelectors.js'
import {defaultOrderListHashSelector} from '../OrderListPage/orderListSelectors.js'
import {DASHBOARD_URI_COMPONENT} from '../DashboardPage/dashboardFunctions.js'
import {
  ORDER_PLURAL_URI_COMPONENT,
  ORDER_SINGLE_URI_COMPONENT,
} from '../../common/constants/Orders.js'
import {
  RETURN_ORDER_PLURAL_URI_COMPONENT,
  RETURN_ORDER_SINGLE_URI_COMPONENT,
} from '../../common/constants/ReturnOrders.js'
import {
  PRODUCT_PLURAL_URI_COMPONENT,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../common/constants/Products.js'
import {
  PURCHASE_ORDER_PLURAL_URI_COMPONENT,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
} from '../../common/constants/PurchaseOrders.js'
import {
  MO_PLURAL_URI_COMPONENT,
  MO_SINGLE_URI_COMPONENT,
} from '../../common/constants/MOs.js'
import {ANALYTICS_URI_COMPONENT} from '../../common/constants/Analytics.js'
import {BATCH_PLURAL_URI_COMPONENT} from '../../common/constants/Batches.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {userTabsSelector} from '../../data/me.js'
import {showWelcomeNavSelector} from '../WelcomePage/index.js'
import {WELCOME_URI_COMPONENT} from '../../common/constants/index.js'
import {usesAppsSelector} from '../../data/company.js'

export const HEADER_NAV_DROPDOWN = 'HEADER_NAV_DROPDOWN'
export const HEADER_SETTINGS_DROPDOWN = 'HEADER_SETTINGS_DROPDOWN'
export const HEADER_SUPPORT_DROPDOWN = 'HEADER_SUPPORT_DROPDOWN'

export const WELCOME_TAB_ID = 'welcome'
const DASHBOARD_TAB_ID = 'dashboard'
const ORDERS_TAB_ID = 'orders'
const RETURNS_TAB_ID = 'returns'
const PRODUCTS_TAB_ID = 'products'
const POS_TAB_ID = 'pos'
const MANUFACTURING_TAB_ID = 'manufacturing'
const ANALYTICS_TAB_ID = 'analytics'

const DEFAULT_TABS = [
  {
    id: WELCOME_TAB_ID,
    display: 'Welcome',
    uris: [WELCOME_URI_COMPONENT, ''],
    defaultHashSelector: () => `#/${WELCOME_URI_COMPONENT}`,
  },
  {
    id: DASHBOARD_TAB_ID,
    display: 'Dashboard',
    uris: [DASHBOARD_URI_COMPONENT, ''],
    defaultHashSelector: () => `#/${DASHBOARD_URI_COMPONENT}`,
  },
  {
    id: ORDERS_TAB_ID,
    display: 'Orders',
    uris: [
      ORDER_PLURAL_URI_COMPONENT,
      ORDER_SINGLE_URI_COMPONENT,
      BATCH_PLURAL_URI_COMPONENT,
    ],
    defaultHashSelector: defaultOrderListHashSelector,
    countSelector: awaitingFulfillmentCountSelector,
  },
  {
    id: RETURNS_TAB_ID,
    display: 'Returns',
    uris: [
      RETURN_ORDER_PLURAL_URI_COMPONENT,
      RETURN_ORDER_SINGLE_URI_COMPONENT,
    ],
    defaultHashSelector: defaultReturnOrderListHashSelector,
  },
  {
    id: PRODUCTS_TAB_ID,
    display: 'Products',
    uris: [PRODUCT_PLURAL_URI_COMPONENT, PRODUCT_SINGLE_URI_COMPONENT],
    defaultHashSelector: defaultProductListHashSelector,
  },
  {
    id: POS_TAB_ID,
    display: 'POs',
    uris: [
      PURCHASE_ORDER_PLURAL_URI_COMPONENT,
      PURCHASE_ORDER_SINGLE_URI_COMPONENT,
    ],
    defaultHashSelector: defaultPurchaseOrderListHashSelector,
  },
  {
    id: MANUFACTURING_TAB_ID,
    display: 'Manufacturing',
    uris: [MO_PLURAL_URI_COMPONENT, MO_SINGLE_URI_COMPONENT],
    defaultHashSelector: defaultMOListHashSelector,
  },
  {
    id: ANALYTICS_TAB_ID,
    display: 'Analytics',
    uris: [ANALYTICS_URI_COMPONENT],
    defaultHashSelector: defaultAnalyticsHashSelector,
  },
]
const DEFAULT_TABS_BY_ID = DEFAULT_TABS.reduce((prev, tab) => {
  prev[tab.id] = tab
  return prev
}, {})

const URI_ALLOWED_SELECTORS = {
  [WELCOME_URI_COMPONENT]: showWelcomeNavSelector,
  [ORDER_PLURAL_URI_COMPONENT]: canUseOrdersSelector,
  [ORDER_SINGLE_URI_COMPONENT]: canUseOrdersSelector,
  [BATCH_PLURAL_URI_COMPONENT]: canUseOrdersSelector,
  [RETURN_ORDER_PLURAL_URI_COMPONENT]: canUseReturnOrdersSelector,
  [RETURN_ORDER_SINGLE_URI_COMPONENT]: canUseReturnOrdersSelector,
  [PRODUCT_PLURAL_URI_COMPONENT]: canUseProductsSelector,
  [PRODUCT_SINGLE_URI_COMPONENT]: canUseProductsSelector,
  [PURCHASE_ORDER_PLURAL_URI_COMPONENT]: showPurchaseOrderNavSelector,
  [PURCHASE_ORDER_SINGLE_URI_COMPONENT]: showPurchaseOrderNavSelector,
  [MO_PLURAL_URI_COMPONENT]: showMONavSelector,
  [MO_SINGLE_URI_COMPONENT]: showMONavSelector,
  [ANALYTICS_URI_COMPONENT]: showAnalyticsNavSelector,
}

function tabSelector(state, {tab}) {
  return {
    id: tab.id,
    display: tab.display,
    url: tab.url || (tab.defaultHashSelector && tab.defaultHashSelector(state)),
    attention: tab.countSelector ? tab.countSelector(state) : 0,
    uris: tab.uris || [],
    hide:
      tab.hide === true ||
      !(tab.uris && URI_ALLOWED_SELECTORS[tab.uris[0]]
        ? URI_ALLOWED_SELECTORS[tab.uris[0]](state)
        : true),
  }
}

function mergeTab(tab) {
  if (!tab.id) {
    return tab
  }

  return {...DEFAULT_TABS_BY_ID[tab.id], ...tab}
}

export const tabsSelector = createSelector(
  (state) => state,
  locationSelector,
  userTabsSelector,
  usesAppsSelector,
  (state, location, userTabs, usesApps) => {
    const root = location.pathComponents[0] || ''

    let tabs = []

    // split into two buckets. with index and without
    const [userTabsWithIndex, userTabsWithoutIndex] = userTabs.reduce(
      (prev, tab) => {
        if (tab.index !== undefined) {
          prev[0].push(tab)
        } else {
          prev[1].push(tab)
        }
        return prev
      },
      [[], []],
    )

    // add all the default tabs
    tabs = DEFAULT_TABS.reduce((prev, tab) => {
      // but add this one first if it's index wants to be here
      const thisFirst = userTabsWithIndex.find(
        ({index}) => prev.length === index,
      )

      if (thisFirst) {
        prev.push(mergeTab(thisFirst))
      }

      // now add the default tab as long as we haven't already added it
      // we might have already added because it set an index
      if (!prev.find(({id}) => id === tab.id)) {
        const modifiedTab = userTabsWithoutIndex.find(({id}) => id === tab.id)
        prev.push(modifiedTab ? mergeTab(modifiedTab) : tab)
      }

      return prev
    }, [])

    // now add all the ones without index set
    tabs = userTabsWithoutIndex.reduce((prev, tab) => {
      // but only custom ones
      if (!tab.id) {
        prev.push(tab)
      }

      return prev
    }, tabs)

    // hide welcome tab if not on apps
    if (!usesApps) {
      tabs = tabs.filter(({id}) => id !== WELCOME_TAB_ID)
    }

    // apply all the state based info
    // filter out hidden tabs
    tabs = tabs
      .map((tab) => tabSelector(state, {tab}))
      .filter(({hide}) => !hide)

    const hash = window.location.hash

    const selected = {
      index: null,
      exact: false,
    }

    // find the most selected tab
    for (const [index, tab] of tabs.entries()) {
      // if it's an exact match then god damn it it's selected
      if (tab.url === hash) {
        selected.exact = true
        selected.index = index

        break
      }

      // otherwise check to see if the uris list matches current root
      if (tab.uris && tab.uris.includes(root)) {
        selected.index = index

        // welcome and dashboard can both be default location so just take first one
        if (root === '') {
          break
        }
      }
    }

    // apply selected value
    return tabs.map((tab, index) => ({
      ...tab,
      selected: selected.index === index,
    }))
  },
)

export function hasWelcomeTabSelector(state) {
  return !!tabsSelector(state).find(({id}) => id === WELCOME_TAB_ID)
}
