import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {dropshippableOrderNumbersSelector} from '../../../../data/orders.js'
import {
  ProcessDropshipFormShape,
  processDropshipFormSelector,
  processDropshipments,
} from './processDropshipFormFunctions.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'
import {onlyIfForm} from '../../../../store.js'

function ProcessDropshipSummary({form}) {
  const dropshippableOrderNumbers = useSelector((state) =>
    dropshippableOrderNumbersSelector(state, {
      orderNumbers: form.orderNumbers,
    }),
  )
  const dropshippableOrdersCount = dropshippableOrderNumbers.length

  return (
    <PluralBlock count={dropshippableOrdersCount}>
      <div>
        <ButtonPrimary
          isLoading={form.isProcessing}
          isDisabled={dropshippableOrdersCount === 0}
          onClick={() => processDropshipments(dropshippableOrderNumbers)}
        >
          <span>
            Process <Count /> <Plural word="Order" />
          </span>
        </ButtonPrimary>
      </div>
    </PluralBlock>
  )
}

ProcessDropshipSummary.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: ProcessDropshipFormShape.isRequired,
}

export default onlyIfForm(ProcessDropshipSummary, processDropshipFormSelector)
