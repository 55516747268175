import {createSelector} from 'reselect'
import startCase from 'lodash/startCase.js'
import truncate from 'lodash/truncate.js'
import {
  hasPrintNodeAPIKeySelector,
  printNodeAPIKeySelector,
  printNodeConfigSelector,
  savePrintNodeConfig,
} from '../data/company.js'
import {formsSelector, getState, setForm, updateForm} from '../store.js'
import delay from '../common/delay.js'
import {isNumeric} from '../common/utils.js'
import blobToBase64 from '../common/blobToBase64.js'

const PRINT_NODE = 'PRINT_NODE'
const DEFAULT = {
  account: null,
  isLoadingAccount: false,
  printers: [],
  isLoadingPrinters: false,
  printJobs: [],
  scales: [],
  isLoadingScales: false,
  isReadingScale: false,
  selectedScaleID: null,
  errorMessage: null,
  isEditingAPIKey: false,
}

export function setupPrintNode() {
  setForm(PRINT_NODE, DEFAULT)
}

export function printNodeSelector(state) {
  return formsSelector(state)[PRINT_NODE] || DEFAULT
}

export const apiKeySelector = createSelector(
  printNodeSelector,
  ({apiKey}) => apiKey,
)

export const isEditingAPIKeySelector = createSelector(
  printNodeSelector,
  ({isEditingAPIKey}) => isEditingAPIKey,
)

export const printEnabledSelector = createSelector(
  printNodeConfigSelector,
  (config) => !!config.print_enabled,
)

export const canUsePrintNodePrintingSelector = createSelector(
  printEnabledSelector,
  hasPrintNodeAPIKeySelector,
  (printEnabled, hasPrintNOdeAPIKey) => printEnabled && hasPrintNOdeAPIKey,
)

export const scaleEnabledSelector = createSelector(
  printNodeConfigSelector,
  (config) => !!config.scale_enabled,
)

export const printersSelector = createSelector(
  printNodeSelector,
  ({printers}) => printers,
)

export function printerSelector(state, {printerID}) {
  const printers = printersSelector(state)

  return printers && printers.find((printer) => printer.id === printerID)
}

function formatPrinterName(printer) {
  return `${startCase(printer.name)} - ${printer.computer.name}`
}

export function printerNameSelector(state, {printerID}) {
  const printer = printerSelector(state, {printerID})

  return formatPrinterName(printer)
}

export const scalesSelector = createSelector(
  printNodeSelector,
  ({scales}) => scales,
)

export const defaultPrinterSelector = createSelector(
  printersSelector,
  (printers) => printers && printers.find((printer) => printer.default),
)

export const printJobsSelector = createSelector(
  printNodeSelector,
  ({printJobs}) => printJobs,
)

export const printJobSelector = createSelector(
  printJobsSelector,
  (state, props) => props.printJobID,
  (printJobs, printJobID) =>
    printJobs && printJobs.find((printJob) => printJob.id === printJobID),
)

export const accountSelector = createSelector(
  printNodeSelector,
  ({account}) => account,
)

export const errorMessageSelector = createSelector(
  printNodeSelector,
  ({errorMessage}) => errorMessage,
)

export const isLoadingPrintersSelector = createSelector(
  printNodeSelector,
  ({isLoadingPrinters}) => isLoadingPrinters,
)

export const isLoadingScalesSelector = createSelector(
  printNodeSelector,
  ({isLoadingScales}) => isLoadingScales,
)

export const isReadingScaleSelector = createSelector(
  printNodeSelector,
  ({isReadingScale}) => isReadingScale,
)

export const isLoadingAccountSelector = createSelector(
  printNodeSelector,
  ({isLoadingAccount}) => isLoadingAccount,
)

export const selectedScaleIDSelector = createSelector(
  scalesSelector,
  printNodeSelector,
  (scales, {selectedScaleID}) => {
    if (scales.length === 0) {
      return null
    }

    if (selectedScaleID === null) {
      return null
    }

    const scale = scales.find(({id}) => id === selectedScaleID)

    if (scale) {
      return scale.id
    }

    return scales[0].id
  },
)

function isPrinterOnline(printer) {
  return printer.state === 'online' && printer.computer.state === 'connected'
}

export const printerOptionsSelector = createSelector(
  printersSelector,
  (printers) =>
    (printers || []).map((printer) => ({
      value: printer.id,
      display: truncate(
        `${isPrinterOnline(printer) ? '' : 'OFFLINE - '}${formatPrinterName(
          printer,
        )}
        `,
        {length: 60},
      ),
    })),
)

export function isPrinterOnlineSelector(state, {printerID}) {
  const canUsePrintNodePrinting = canUsePrintNodePrintingSelector(state)

  if (!canUsePrintNodePrinting) {
    return false
  }

  const printer = printerSelector(state, {printerID})

  if (!printer) {
    return false
  }

  return isPrinterOnline(printer)
}

export const OUNCES_PER_MICRO_GRAM = 0.00000003527396

export const hasPrintNodeScaleSelector = createSelector(
  scaleEnabledSelector,
  (scaleEnabled) => scaleEnabled,
)

export const selectedScaleWeightSelector = createSelector(
  scalesSelector,
  selectedScaleIDSelector,
  (scales, selectedScaleID) => {
    if (selectedScaleID === null) {
      return null
    }

    const scale = scales.find(({id}) => id === selectedScaleID)

    if (!scale || !isNumeric(scale.mass[0])) {
      return null
    }

    return scale.mass[0] * OUNCES_PER_MICRO_GRAM
  },
)

export function updatePrintNode(updates, meta) {
  updateForm(PRINT_NODE, updates, meta)
}

export function setSelectedScaleID(selectedScaleID) {
  updatePrintNode({selectedScaleID}, {stickyProps: ['selectedScaleID']})
}

async function printNodeAPI(url, {data, ...options} = {}) {
  const apiKey = printNodeAPIKeySelector(getState())

  const headers = new Headers()

  headers.set('Authorization', 'Basic ' + btoa(apiKey + ':'))

  if (data) {
    headers.set('Content-Type', 'application/json;charset=utf-8')
    options.body = JSON.stringify(data)
  }

  options.headers = headers

  const res = await fetch(`https://api.printnode.com${url}`, options)

  const json = await res.json()

  if (res.status >= 400) {
    throw new Error(`PrintNode: ${json.message || 'Invalid Response'}`)
  }

  return json
}

export async function createPrintJob({printerID, pdfName, pdfLink}) {
  const pdfRes = await fetch(pdfLink, {
    credentials: 'include',
  })
  const blob = await pdfRes.blob()
  const pdfBase64 = await blobToBase64(blob)
  const paper = pdfRes.headers['x-ordoro-printnode-paper']

  const printJobID = await printNodeAPI('/printjobs', {
    method: 'POST',
    data: {
      printer: printerID,
      title: pdfName,
      contentType: 'pdf_base64',
      content: pdfBase64,
      source: 'Ordoro',
      options: {
        paper,
      },
    },
  })

  return printJobID
}

export async function pollPrintJob(printJobID) {
  const forever = true

  while (forever) {
    const printJobs = await printNodeAPI('/printjobs')

    updatePrintNode({printJobs})

    const printJob = printJobSelector(getState(), {printJobID})

    if (!printJob || printJob.state === 'done' || printJob.state === 'error') {
      return // nothing lasts forever
    }

    await delay(500)
  }
}

export async function getData() {
  await getAccount()

  await getPrinters()

  await getScales()
}

export async function getPrintNodeData() {
  try {
    await getData()
  } catch (err) {
    updatePrintNode({
      errorMessage: `Error connecting to PrintNode: ${err.message}`,
    })
  }
}

export async function getAccount() {
  try {
    const printEnabled = printEnabledSelector(getState())
    const scaleEnabled = scaleEnabledSelector(getState())

    if (!printEnabled && !scaleEnabled) {
      return
    }

    updatePrintNode({isLoadingAccount: true})

    const account = await printNodeAPI('/whoami')

    updatePrintNode({account})
  } finally {
    updatePrintNode({isLoadingAccount: false})
  }
}

export async function getPrinters() {
  try {
    const printEnabled = printEnabledSelector(getState())

    if (!printEnabled) {
      updatePrintNode({printers: []})

      return
    }

    updatePrintNode({isLoadingPrinters: true})

    const printers = await printNodeAPI('/printers')

    updatePrintNode({printers})
  } finally {
    updatePrintNode({isLoadingPrinters: false})
  }
}

export async function getScales() {
  try {
    const scaleEnabled = scaleEnabledSelector(getState())

    if (!scaleEnabled) {
      updatePrintNode({scales: [], selectedScaleID: null})

      return
    }

    updatePrintNode({isLoadingScales: true})

    const computers = await printNodeAPI('/computers')

    const scales = (await printNodeAPI('/scales')).map((scale) => {
      const computer = computers.find(({id}) => scale.computerId === id) || {}

      return {
        ...scale,
        id: `${scale.computerId}:${scale.port}:${scale.productId}`,
        computerName: computer.name || 'UNKNOWN',
      }
    })

    const {selectedScaleID} = printNodeSelector(getState())

    updatePrintNode({
      scales,
      ...(!selectedScaleID && scales.length
        ? {selectedScaleID: scales[0].id}
        : undefined),
    })
  } finally {
    updatePrintNode({isLoadingScales: false})
  }
}

export async function updateAPIKey(apiKey) {
  try {
    updatePrintNode({errorMessage: null})

    await savePrintNodeConfig({api_key: apiKey})

    await getData()

    updatePrintNode({isEditingAPIKey: false})
  } catch (err) {
    updatePrintNode({
      errorMessage: `Error saving apiKey to company: ${err.message}`,
    })
  }
}

export async function readScale() {
  try {
    updatePrintNode({isReadingScale: true, errorMessage: null})

    await getScales()

    return selectedScaleWeightSelector(getState())
  } catch (err) {
    updatePrintNode({
      errorMessage: `Error reading scale weight: ${err.message}`,
    })
  } finally {
    updatePrintNode({isReadingScale: false})
  }
}

export async function updatePrintNodeConfig(config) {
  try {
    updatePrintNode({errorMessage: null})

    await savePrintNodeConfig(config)

    await getData()
  } catch (err) {
    updatePrintNode({
      errorMessage: `Error updating PrintNode config to company: ${err.message}`,
    })
  }
}
