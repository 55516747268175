import get from 'lodash/get.js'

import {isNumeric} from '../../../../common/utils.js'

import ConditionValueCartSelect from './ConditionValueCartSelect.js'
import ConditionValueCartDisplay from './ConditionValueCartDisplay.js'
import ConditionValueTextInput from './ConditionValueTextInput.js'
import ConditionValueTextDisplay from './ConditionValueTextDisplay.js'
import ConditionValueWeightInput from './ConditionValueWeightInput.js'
import ConditionValueWeightDisplay from './ConditionValueWeightDisplay.js'
import ConditionValueProductTagDisplay from './ConditionValueProductTagDisplay.js'
import ConditionValueProductTagSelect from './ConditionValueProductTagSelect.js'
import {
  CONDITIONS_JOINED_WITH_ALL,
  CONDITIONS_JOINED_WITH_ANY,
} from '../../../../common/constants/Rules.js'

export function getConditionOption(condition) {
  return CONDITIONAL_OPTIONS.find((option) => option.match(condition))
}

export function getConditionsJoiner(rule) {
  return Object.keys(rule.condition)[0] || null
}

export function getConditions(rule) {
  const [key, value] = Object.entries(rule.condition)[0]

  return (
    [CONDITIONS_JOINED_WITH_ALL, CONDITIONS_JOINED_WITH_ANY].includes(key)
      ? value
      : [rule.condition]
  ).map((condition) => {
    // we need to remember things that don't save on the condition
    if (!condition._meta) {
      const conditionOption = getConditionOption(condition)

      if (conditionOption) {
        condition = conditionOption.buildMeta(condition)
      }
    }

    return condition
  })
}

export function setConditions(rule, conditions) {
  const conditionsJoiner = getConditionsJoiner(rule)

  return conditions.length === 1
    ? conditions[0]
    : {[conditionsJoiner]: conditions}
}

const basic = {
  buildMeta(condition) {
    if (condition._meta) {
      return condition
    }

    const meta = {}
    const comparatorOperator = this.getComparatorOperator(condition)
    const value = this.getValue(condition)

    // is blank / is not blank?
    if (
      ['==', '!='].includes(comparatorOperator) &&
      value === '' &&
      this.hasBlank
    ) {
      meta.isBlank = true
    }

    condition._meta = meta

    return condition
  },
  match(condition) {
    return this.getValueParam(condition) === this.value
  },
  reverseArgOrder(condition) {
    const comparatorOperator = this.getComparatorOperator(condition)

    return ['in'].includes(comparatorOperator)
  },
  create(
    comparatorOperator = this.comparators[0].value,
    value = undefined,
    valueParam = this.value,
    negate = false,
    isBlank = false,
  ) {
    const operation = this.createOperation(
      comparatorOperator,
      value,
      valueParam,
      valueParam,
    )

    const condition = negate ? {'!': operation} : operation

    condition._meta = {}
    if (this.hasBlank && isBlank && value === '') {
      condition._meta.isBlank = true
    }

    return condition
  },
  createOperation(comparatorOperator, value, valueParam, varParam) {
    return {
      [comparatorOperator]: ['in'].includes(comparatorOperator)
        ? [
            value === undefined ? this.default(comparatorOperator) : value,
            {var: varParam},
          ]
        : [
            {var: varParam},
            value === undefined ? this.default(comparatorOperator) : value,
          ],
    }
  },
  aggregates: [],
  getValueParam(condition) {
    const value = Object.values(
      this.isNegated(condition) ? condition['!'] : condition,
    )[0]

    return get(value, [this.reverseArgOrder(condition) ? 1 : 0, 'var'])
  },
  setAggregate(condition) {
    return condition
  },
  isNegated(condition) {
    return !!condition['!']
  },
  getComparatorOperator(condition) {
    return Object.keys(
      this.isNegated(condition) ? condition['!'] : condition,
    )[0]
  },
  getComparator(condition) {
    const isNegated = this.isNegated(condition)
    const comparatorOperator = condition._meta.isBlank
      ? 'blank'
      : this.getComparatorOperator(condition)

    const value = (isNegated ? 'not ' : '') + comparatorOperator

    return this.comparators.find((comparator) => comparator.value === value)
  },
  setComparator(condition, comparatorValue) {
    let [, isNegated, cValue] = comparatorValue.match(/^(not )?(.*)$/)
    const isBlank = cValue === 'blank'
    cValue = isBlank ? '==' : cValue

    return this.create(
      cValue,
      isBlank ? '' : this.getValue(condition),
      undefined,
      !!isNegated,
      isBlank,
    )
  },
  getValue(condition) {
    return Object.values(
      this.isNegated(condition) ? condition['!'] : condition,
    )[0][this.reverseArgOrder(condition) ? 0 : 1]
  },
  setValue(condition, newValue) {
    return this.create(
      this.getComparatorOperator(condition),
      newValue,
      undefined,
      this.isNegated(condition),
      condition._meta.isBlank,
    )
  },
  validate() {
    return null
  },
  getCondition(condition) {
    if (this.type === 'number') {
      const value = this.getValue(condition)

      condition = this.setValue(condition, Number(value))
    }

    delete condition._meta

    return condition
  },
}

const stringOptions = {
  ...basic,
  type: 'string',
  hasBlank: true,
  displayValueComponent: ConditionValueTextDisplay,
  editValueComponent: ConditionValueTextInput,
  comparators: [
    {display: 'is', value: '=='},
    {display: 'is not', value: '!='},
    {display: 'contains', value: 'in'},
    {display: 'does not contain', value: 'not in'},
    {display: 'is blank', value: 'blank'},
    {display: 'is not blank', value: 'not blank'},
    {display: 'starts with', value: 'starts'},
    {display: 'does not start with', value: 'not starts'},
    {display: 'ends with', value: 'ends'},
    {display: 'does not end with', value: 'not ends'},
  ],
  default() {
    return ''
  },
}

const numberOptions = {
  ...basic,
  type: 'number',
  displayValueComponent: ConditionValueTextDisplay,
  editValueComponent: ConditionValueTextInput,
  comparators: [
    {display: 'equals', value: '=='},
    {display: 'does not equal', value: '!='},
    {display: 'is greater than', value: '>'},
    {display: 'is greater than or equal to', value: '>='},
    {display: 'is less than', value: '<'},
    {display: 'is less than or equal to', value: '<='},
  ],
  default() {
    return 0
  },
  validate(condition) {
    const value = this.getValue(condition)

    if (!isNumeric(value)) {
      return 'This should be a number.'
    }

    return null
  },
}

const weightOptions = {
  ...numberOptions,
  displayValueComponent: ConditionValueWeightDisplay,
  editValueComponent: ConditionValueWeightInput,
}

const cartOptions = {
  ...basic,
  type: 'cart',
  displayValueComponent: ConditionValueCartDisplay,
  editValueComponent: ConditionValueCartSelect,
  comparators: [
    {display: 'is', value: '=='},
    {display: 'is not', value: '!='},
  ],
  default() {
    return null
  },
}

const orderLineAggregateOptions = {
  ...basic,
  aggregates: [
    {value: 'any', display: 'Any'},
    {value: 'all', display: 'Every'},
  ],
  create(
    aggregate = this.aggregates[0].value,
    comparatorOperator = this.comparators[0].value,
    value = undefined,
    valueParam = this.value,
    negate = false,
    isBlank = false,
  ) {
    const operation = this.createOperation(
      comparatorOperator,
      value,
      valueParam,
      '.',
    )

    const condition = {
      [aggregate]: {
        map: [
          {
            var: valueParam,
          },
          negate ? {'!': operation} : operation,
        ],
      },
    }

    condition._meta = {}
    if (this.hasBlank && isBlank && value === '') {
      condition._meta.isBlank = true
    }

    return condition
  },
  getBaseOperator(condition) {
    return get(condition, [this.getAggregate(condition), 'map', 1], {})
  },
  getValueParam(condition) {
    return get(condition, [this.getAggregate(condition), 'map', 0, 'var'])
  },
  getAggregate(condition) {
    return Object.keys(condition)[0]
  },
  setAggregate(condition, value) {
    return {
      [value]: Object.values(condition)[0],
    }
  },
  isNegated(condition) {
    return Object.keys(this.getBaseOperator(condition))[0] === '!'
  },
  getComparatorOperator(condition) {
    const baseOperator = this.getBaseOperator(condition)

    return Object.keys(
      this.isNegated(condition) ? get(baseOperator, ['!']) : baseOperator,
    )[0]
  },
  setComparator(condition, comparatorValue) {
    let [, isNegated, cValue] = comparatorValue.match(/^(not )?(.*)$/)
    const isBlank = cValue === 'blank'
    cValue = isBlank ? '==' : cValue

    return this.create(
      this.getAggregate(condition),
      cValue,
      isBlank ? '' : this.getValue(condition),
      this.getValueParam(condition),
      !!isNegated,
      isBlank,
    )
  },
  getValue(condition) {
    const baseOperator = this.getBaseOperator(condition)

    const rest = [
      this.getComparatorOperator(condition),
      this.reverseArgOrder(condition) ? 0 : 1,
    ]

    return get(baseOperator, this.isNegated(condition) ? ['!', ...rest] : rest)
  },
  setValue(condition, newValue) {
    return this.create(
      this.getAggregate(condition),
      this.getComparatorOperator(condition),
      newValue,
      this.getValueParam(condition),
      this.isNegated(condition),
      condition._meta.isBlank,
    )
  },
}

const reduceMapOptions = {
  reduceMapValues: [],
  match(condition) {
    const map = get(Object.values(condition)[0], [0, 'reduce', 0, 'map'], [])

    return (
      map.reduce((prev, element, index) => {
        const value = this.reduceMapValues[index]

        return (
          prev +
          (!!value && (element === value || element.var === value.var) ? 1 : 0)
        )
      }, 0) === this.reduceMapValues.length
    )
  },
  create(
    comparatorOperator = this.comparators[0].value,
    value = undefined,
    _valueParam = this.value,
    negate = false,
    isBlank = false,
  ) {
    let condition = {
      [comparatorOperator]: [
        {
          reduce: [
            {map: this.reduceMapValues},
            {'+': [{var: 'current'}, {var: 'accumulator'}]},
            0,
          ],
        },
        value === undefined ? this.default(comparatorOperator) : value,
      ],
    }

    condition = negate ? {'!': condition} : condition

    condition._meta = {}
    if (this.hasBlank && isBlank && value === '') {
      condition._meta.isBlank = true
    }

    return condition
  },
}

const productTagOptions = {
  ...stringOptions,
  ...orderLineAggregateOptions,
  type: 'product_tag',
  displayValueComponent: ConditionValueProductTagDisplay,
  editValueComponent: ConditionValueProductTagSelect,
  hasBlank: false,
  comparators: stringOptions.comparators.filter(
    ({value}) => !['blank', 'not blank'].includes(value),
  ),
  default(comparatorValue) {
    return this._getValueParam(comparatorValue) === 'id' ? null : ''
  },
  match(condition) {
    return (
      get(condition, [this.getAggregate(condition), 'map', 0, 'var']) ===
      this.value
    )
  },
  create(
    aggregate = this.aggregates[0].value,
    comparatorOperator = this.comparators[0].value,
    value = undefined,
    valueParam = 'id',
    negate = false,
    isBlank = false,
  ) {
    const operation = this.createOperation(
      comparatorOperator,
      value,
      valueParam,
      valueParam,
    )

    const condition = {
      [aggregate]: {
        map: [
          {
            var: this.value,
          },
          {
            any: {
              map: [
                {
                  var: '.',
                },
                negate ? {'!': operation} : operation,
              ],
            },
          },
        ],
      },
    }

    condition._meta = {}
    if (this.hasBlank && isBlank && value === '') {
      condition._meta.isBlank = true
    }

    return condition
  },
  _getValueParam(comparatorValue) {
    if (['==', '!='].includes(comparatorValue)) {
      return 'id'
    } else {
      return 'name'
    }
  },
  getValueParam(condition) {
    return this._getValueParam(this.getComparatorOperator(condition))
  },
  getBaseOperator(condition) {
    return get(
      condition,
      [this.getAggregate(condition), 'map', 1, 'any', 'map', 1],
      {},
    )
  },
  setComparator(condition, comparatorValue) {
    let [, isNegated, cValue] = comparatorValue.match(/^(not )?(.*)$/)
    const isBlank = cValue === 'blank'

    cValue = isBlank ? '==' : cValue

    const valueParam = this._getValueParam(comparatorValue)
    const value = isBlank
      ? ''
      : this.getValueParam(condition) !== valueParam
        ? this.default(comparatorValue)
        : this.getValue(condition)

    return this.create(
      this.getAggregate(condition),
      cValue,
      value,
      valueParam,
      !!isNegated,
      isBlank,
    )
  },
}

export function conditionalOptionsSelector() {
  return CONDITIONAL_OPTIONS
}

export const CONDITIONAL_OPTIONS = [
  {
    display: 'Requested Shipping Method',
    value: 'requested_shipping_method',
    ...stringOptions,
  },
  {
    display: 'Order ID',
    value: 'order_number',
    ...stringOptions,
  },
  {
    display: 'Sales Channel',
    value: 'sales_channel.id',
    ...cartOptions,
  },
  {
    display: 'Notes From Customer',
    value: 'notes_from_customer',
    ...stringOptions,
  },
  {
    display: 'Ship To: Name',
    value: 'shipping_address.name',
    ...stringOptions,
  },
  {
    display: 'Ship To: Email',
    value: 'shipping_address.email',
    ...stringOptions,
  },
  {
    display: 'Ship To: Company',
    value: 'shipping_address.company',
    ...stringOptions,
  },
  {
    display: 'Ship To: Phone',
    value: 'shipping_address.phone',
    ...stringOptions,
  },
  {
    display: 'Ship To: Street 1',
    value: 'shipping_address.street1',
    ...stringOptions,
  },
  {
    display: 'Ship To: Street 2',
    value: 'shipping_address.street2',
    ...stringOptions,
  },
  {
    display: 'Ship To: City',
    value: 'shipping_address.city',
    ...stringOptions,
  },
  {
    display: 'Ship To: State',
    value: 'shipping_address.state',
    ...stringOptions,
  },
  {
    display: 'Ship To: Country',
    value: 'shipping_address.country',
    ...stringOptions,
  },
  {
    display: 'Ship To: Zip',
    value: 'shipping_address.zip',
    ...stringOptions,
  },
  {
    display: 'Bill To: Name',
    value: 'billing_address.name',
    ...stringOptions,
  },
  {
    display: 'Bill To: Email',
    value: 'billing_address.email',
    ...stringOptions,
  },
  {
    display: 'Bill To: Company',
    value: 'billing_address.company',
    ...stringOptions,
  },
  {
    display: 'Bill To: Phone',
    value: 'billing_address.phone',
    ...stringOptions,
  },
  {
    display: 'Bill To: Street 1',
    value: 'billing_address.street1',
    ...stringOptions,
  },
  {
    display: 'Bill To: Street 2',
    value: 'billing_address.street2',
    ...stringOptions,
  },
  {
    display: 'Bill To: City',
    value: 'billing_address.city',
    ...stringOptions,
  },
  {
    display: 'Bill To: State',
    value: 'billing_address.state',
    ...stringOptions,
  },
  {
    display: 'Bill To: Country',
    value: 'billing_address.country',
    ...stringOptions,
  },
  {
    display: 'Bill To: Zip',
    value: 'billing_address.zip',
    ...stringOptions,
  },
  {
    display: 'Order Grand Total',
    value: 'financial.grand_total',
    ...numberOptions,
  },
  {
    display: 'Product Total',
    value: 'financial.product_amount',
    ...numberOptions,
  },
  {
    display: 'Shipping and Handling',
    value: 'financial.shipping_amount',
    ...numberOptions,
  },
  {
    display: 'Discount Amount',
    value: 'financial.discount_amount',
    ...numberOptions,
  },
  {
    display: 'Tax Amount',
    value: 'financial.tax_amount',
    ...numberOptions,
  },
  {
    display: 'Order Weight',
    value: 'weight',
    ...weightOptions,
  },
  {
    display: 'Product SKU',
    value: 'lines.*.sku',
    ...stringOptions,
    ...orderLineAggregateOptions,
  },
  {
    display: 'Product Name',
    value: 'lines.*.product_name',
    ...stringOptions,
    ...orderLineAggregateOptions,
  },
  {
    display: 'Product Category',
    value: 'lines.*.product_category',
    ...stringOptions,
    ...orderLineAggregateOptions,
  },
  {
    display: 'Product Tag',
    value: 'lines.*.product_tags',
    ...productTagOptions,
  },
  {
    display: 'Order Line Additional Details',
    value: 'lines.*.details',
    ...stringOptions,
    ...orderLineAggregateOptions,
  },
  {
    display: 'Order Line Quantity',
    value: 'lines.*.quantity',
    ...numberOptions,
    ...orderLineAggregateOptions,
  },
  {
    display: 'Total Item Count in Order',
    value: 'total lines quantity',
    ...numberOptions,
    ...reduceMapOptions,
    reduceMapValues: [{var: 'lines'}, {var: 'quantity'}],
  },
  {
    display: 'Total Line Count in Order',
    value: 'total line count',
    ...numberOptions,
    ...reduceMapOptions,
    reduceMapValues: [{var: 'lines'}, 1],
  },
  {
    display: 'Order Line Total Price',
    value: 'lines.*.total_price',
    ...numberOptions,
    ...orderLineAggregateOptions,
  },
]
