import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {ShipperFormShape} from '../../../common/PropTypes.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {
  shipperNameSelector,
  isArchivedSelector,
  tokenExpirationSelector,
} from '../../../data/shippers.js'
import {showArchiveShipperModal} from './ArchiveShipperModal.js'
import Forms from './Forms/index.js'

function EditForm(props) {
  const {
    form,
    editID,
    updateForm,
    cancel,
    setupForm,
    shipperName,
    isArchived,
    tokenExpiration,
  } = props

  const Form = Forms[form.vendor]
  const href = `#/settings/shipper/${form.id}`
  const isExpanded = Number(editID) === form.id
  const needsReauth = !isArchived && tokenExpiration.isExpired

  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
        'archived wrap--reveal': isArchived,
        warning: needsReauth,
      })}
    >
      <div className="wrap--row ">
        <div className="medium-4 columns list__title-logo">
          <div className={classNames('wrap--logo', form.vendor)} />
        </div>
        <div className="make-medium-grey medium-5 columns wrap--reauth-notice">
          <strong className="fs-01 margin-right-5 v-align-middle">
            {shipperName}
          </strong>
          {needsReauth && (
            <div>
              <strong className="label__callout label__callout--yellow v-align-middle">
                Reauthorize
              </strong>
            </div>
          )}
        </div>
        <div className="medium-2 columns align-right">
          {isArchived ? (
            <>
              <div>
                <em className="fs-01 v-align-middle text--lt-grey">Archived</em>
              </div>
              <ButtonPrimary
                isOutlined
                size="xx-sm"
                className="margin-top-5 btn--reveal"
                onClick={() => props.showArchiveShipperModal(form.id)}
              >
                Unarchive
              </ButtonPrimary>
            </>
          ) : (
            <span className="fs-01 v-align-middle">
              <a
                className="inline-text-button settings-list-item-button edit-url"
                href={href}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      </div>
      {isExpanded && (
        <Form
          form={form}
          updateForm={updateForm}
          cancel={cancel}
          setupForm={setupForm}
        />
      )}
    </li>
  )
}

EditForm.propTypes = {
  form: ShipperFormShape.isRequired,
  editID: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  setupForm: PropTypes.func.isRequired,
  shipperName: PropTypes.string.isRequired,
  isArchived: PropTypes.bool.isRequired,
  tokenExpiration: PropTypes.object.isRequired,
  showArchiveShipperModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {form}) {
  return {
    shipperName: shipperNameSelector(state, {shipperID: form.id}),
    isArchived: isArchivedSelector(state, {shipperID: form.id}),
    tokenExpiration: tokenExpirationSelector(state, {shipperID: form.id}),
  }
}

const mapDispatchToProps = {
  showArchiveShipperModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)
