import {useEffect, useState} from 'react'

import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import api from '../../common/api.js'
import {setCompany, appMessageSelector} from '../../data/company.js'
import {dispatch, getState, useSelector} from '../../store.js'
import {sanitize} from '../../common/sanitize.js'
import openNewTab from '../../common/openNewTab.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {
  navigate,
  setIsStarted,
  setZuckSuppressAppMessage,
} from '../../redux/actions/ui/index.js'
import {
  isStartedSelector,
  zuckCompanyIDSelector,
} from '../../redux/selectors/ui/index.js'

export function closeModal() {
  const zuckCompanyID = zuckCompanyIDSelector(getState())

  if (zuckCompanyID) {
    dispatch(setZuckSuppressAppMessage(true))
  } else {
    dismissAppMessage({isCancelled: true})
  }
}

export function handleModalClose(needsRefresh) {
  const zuckCompanyID = zuckCompanyIDSelector(getState())

  if (needsRefresh && !zuckCompanyID) {
    window.location.reload(window.location)
  } else {
    const isStarted = isStartedSelector(getState())

    if (!isStarted) {
      dispatch(setIsStarted(true))
    }
  }
}

export async function dismissAppMessage({isCancelled} = {}) {
  const {canDismiss, redirectTo, openInNewTab} = appMessageSelector(getState())

  if (!canDismiss) {
    return
  }

  try {
    const {json} = await api.put('/company/', {app_message: null})

    setCompany(json)

    if (!isCancelled && redirectTo && !openInNewTab) {
      dispatch(navigate(redirectTo))
    }
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while clearing app message.',
        details: `${err.message || err.error_message}`,
      },
      err,
    )
  }
}

export default function AppMessageModal() {
  const appMessage = useSelector(appMessageSelector)
  const [needsRefresh, setNeedsRefresh] = useState(
    appMessage ? appMessage.needsRefresh : false,
  )
  const showAppMessage = !!appMessage

  useEffect(() => {
    setNeedsRefresh(appMessage ? appMessage.needsRefresh : false)
  }, [appMessage])

  useEffect(() => {
    if (!showAppMessage) {
      handleModalClose(needsRefresh)
    }
  }, [showAppMessage])

  if (!showAppMessage) {
    return null
  }

  let {
    title,
    buttonText,
    content,
    canDismiss,
    modalSize,
    openInNewTab,
    redirectTo,
  } = appMessage

  buttonText =
    buttonText ||
    (canDismiss && !redirectTo
      ? 'Okay'
      : canDismiss && redirectTo
        ? 'Take me there'
        : 'Please wait')

  // allow user to close modal without confirm only if there is an action
  // associated with the confirm button (like redirecting to location)
  // otherwise hide the close/cancel buttons because the confirm button
  // is the only valid choice
  const canCancel = canDismiss && redirectTo

  return (
    <ConfirmModal
      title={title}
      modalSize={modalSize}
      onConfirm={() => {
        if (redirectTo && openInNewTab) {
          openNewTab(redirectTo)
        }

        dismissAppMessage()
      }}
      onCancel={closeModal}
      confirmText={buttonText}
      cancelText="Close"
      preventClose={!canCancel}
      isDisabled={!canDismiss}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(content, {
            allowedAttributes: {'*': ['class', 'style']},
            allowedTags: ['iframe'],
          }),
        }}
      />
    </ConfirmModal>
  )
}
