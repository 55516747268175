import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'
import mapValues from 'lodash/mapValues.js'

import {
  PURCHASE_ORDER_PLURAL_URI_COMPONENT,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
  PURCHASE_ORDER_STATII,
  DEFAULT_STATUS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  DEFAULT_EXPAND_MODE,
  PURCHASE_ORDER_STATUS_LIST,
} from '../../common/constants/PurchaseOrders.js'
import {buildPath, stringifyURL} from '../../common/querystring.js'
import {isPresent} from '../../common/utils.js'
import {
  EXPAND_MODE_EXPANDED,
  EXPAND_MODE_COLLAPSED,
} from '../../common/components/List/ExpandAllButton.js'
import {formsSelector} from '../../store.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {poTagsSortedByNameSelector} from '../../data/poTags.js'
import {posSelector} from '../../data/pos.js'
import {
  ensureArray,
  ensureArrayOfNumbers,
  ensureTroolean,
} from '../../common/ensure.js'
import {TAG_FILTER_OR} from '../../common/constants/Tags.js'
import {DETAIL_MODE, LIST_MODE} from '../../common/constants/index.js'

export const PURCHASE_ORDER_LIST = 'PURCHASE_ORDER_LIST'

export function poListFormSelector(state) {
  return formsSelector(state)[PURCHASE_ORDER_LIST]
}

export const poIDListSelector = createSelector(
  poListFormSelector,
  ({poIDList} = {}) => poIDList || [],
)

export function poListQuerySelector(state) {
  const cache = poListQuerySelector.cache

  const location = locationSelector(state)

  if (location === cache.location) {
    return cache.result
  }

  cache.location = location

  const {
    query,
    pathComponents: [resource, singleStatusOrPOID, panel],
  } = location

  const po_id =
    !!singleStatusOrPOID && resource === PURCHASE_ORDER_SINGLE_URI_COMPONENT
      ? singleStatusOrPOID
      : null

  const status = [
    ...(resource === PURCHASE_ORDER_PLURAL_URI_COMPONENT
      ? singleStatusOrPOID
        ? PURCHASE_ORDER_STATUS_LIST.includes(singleStatusOrPOID)
          ? [singleStatusOrPOID]
          : []
        : [DEFAULT_STATUS]
      : ensureArray(query.status).filter((s) =>
          PURCHASE_ORDER_STATUS_LIST.includes(s),
        )),
  ]

  const result = {
    mode:
      resource === PURCHASE_ORDER_PLURAL_URI_COMPONENT ||
      (resource === PURCHASE_ORDER_SINGLE_URI_COMPONENT && !po_id)
        ? LIST_MODE
        : resource === PURCHASE_ORDER_SINGLE_URI_COMPONENT
          ? DETAIL_MODE
          : null,
    searchText: query.searchText || '',
    status,
    po_id,
    panel,
    tags: ensureArray(query.tags),
    untagged: ensureTroolean(query.untagged) || false,
    tag_filter_by: query.tag_filter_by || TAG_FILTER_OR,
    exclude_tags: ensureArray(query.exclude_tags),
    supplier_id: ensureArrayOfNumbers(query.supplier_id),
    warehouse_id: ensureArrayOfNumbers(query.warehouse_id),
    sort: query.sort || DEFAULT_SORT,
    limit: Number(query.limit) || DEFAULT_PER_PAGE,
    offset: Number(query.offset) || 0,
  }

  if (isEqual(cache.result, result)) {
    return cache.result
  }

  cache.result = result

  return result
}
poListQuerySelector.cache = {}

export const isLoadingSelector = createSelector(
  poListFormSelector,
  ({isLoading} = {}) => isLoading || false,
)

export const countSelector = createSelector(
  poListFormSelector,
  ({count} = {}) => count || 0,
)

export const expandModeSelector = createSelector(
  poListFormSelector,
  ({expandMode} = {}) => expandMode || DEFAULT_EXPAND_MODE,
)

export const expandedPOIDsSelector = createSelector(
  poListFormSelector,
  ({expandedPOIDs} = {}) => expandedPOIDs || [],
)

export const selectedPOIDsSelector = createSelector(
  poListFormSelector,
  ({selectedPOIDs} = {}) => selectedPOIDs || [],
)

export const allSelectedSelector = createSelector(
  poIDListSelector,
  selectedPOIDsSelector,
  (poIDList, selected) =>
    poIDList.length > 0 && poIDList.length === selected.length,
)

export const firstSelectedPOIDSelector = createSelector(
  selectedPOIDsSelector,
  (selectedPOIDs) => selectedPOIDs[0],
)

export function countsSelector() {
  return PURCHASE_ORDER_STATII.reduce((prev, {slug}) => {
    prev[slug] = 0

    return prev
  }, {})
}

function hashBuilder(params) {
  let hash = `#/${PURCHASE_ORDER_SINGLE_URI_COMPONENT}`

  return stringifyURL(hash, params)
}

export const purchaseOrderListHashSelector = createSelector(
  poListQuerySelector,
  hashBuilder,
)

export const defaultHashParamsSelector = createSelector(
  poListFormSelector,
  ({sticky__limit, sticky__sort} = {}) => ({
    status: [DEFAULT_STATUS],
    limit: sticky__limit !== DEFAULT_PER_PAGE ? sticky__limit : undefined,
    sort: sticky__sort !== DEFAULT_SORT ? sticky__sort : undefined,
  }),
)

export const defaultPurchaseOrderListHashSelector = createSelector(
  defaultHashParamsSelector,
  hashBuilder,
)

export function queryParamsSelector(state) {
  const {
    status,
    searchText,
    tags,
    untagged,
    tag_filter_by,
    exclude_tags,
    supplier_id,
    warehouse_id,
    sort,
    limit,
    offset,
  } = poListQuerySelector(state)

  return {
    status,
    search: searchText.trim() || undefined,
    tag: tags.length ? tags : undefined,
    untagged: untagged || undefined,
    tag_filter_by: tag_filter_by !== TAG_FILTER_OR ? tag_filter_by : undefined,
    exclude_tags: exclude_tags.length ? exclude_tags : undefined,
    supplier_id: supplier_id.length ? supplier_id : undefined,
    warehouse_id: warehouse_id.length ? warehouse_id : undefined,
    sort,
    limit,
    offset,
  }
}

export const createIsSelectedSelector = (poID) =>
  createSelector(selectedPOIDsSelector, (selectedPOIDs) => {
    return selectedPOIDs.includes(poID)
  })

export const createIsExpandedSelector = (poID) =>
  createSelector(
    expandedPOIDsSelector,
    expandModeSelector,
    (expandedPOIDs, expandMode) => {
      if (expandMode === EXPAND_MODE_COLLAPSED) {
        return expandedPOIDs.includes(poID)
      }
      if (expandMode === EXPAND_MODE_EXPANDED) {
        return !expandedPOIDs.includes(poID)
      }
      return false
    },
  )

export function getPODetailURL(poID) {
  return [[PURCHASE_ORDER_SINGLE_URI_COMPONENT, poID]]
}

export const createDetailURLSelector = (poID) => () =>
  '#' + buildPath(...getPODetailURL(poID))

export const isFilteredSelector = createSelector(
  poListQuerySelector,
  ({searchText, tags, exclude_tags, untagged, supplier_id, warehouse_id}) =>
    isPresent(searchText) ||
    tags.length !== 0 ||
    untagged === true ||
    exclude_tags.length !== 0 ||
    supplier_id.length !== 0 ||
    warehouse_id.length !== 0,
)

export function createPOTagQuantityMapSelector() {
  return createSelector(
    (state, {poIDs}) => poIDs,
    posSelector,
    poTagsSortedByNameSelector,
    (poIDs, pos, poTags) => {
      const poTagQuantityMap = poTags.reduce(
        (prev, tag) => ({
          ...prev,
          [tag.id]: 0,
        }),
        {},
      )

      poIDs.forEach((poID) => {
        const po = pos[poID]

        if (!po) {
          return
        }

        po.tags.forEach((tag) => {
          poTagQuantityMap[tag.id] += 1
        })
      })

      return mapValues(poTagQuantityMap, (selectedQuantity) => {
        const diff = poIDs.length - selectedQuantity
        if (diff === 0) {
          return 'all'
        } else if (diff === poIDs.length) {
          return 'none'
        }

        return 'some'
      })
    },
  )
}
