import PropTypes from 'prop-types'
import {useEffect} from 'react'
import classnames from 'classnames'

import {
  RateShape,
  BulkRateShape,
  RateErrorShape,
} from '../../common/PropTypes.js'
import {showMultiboxModal} from '../OrderListPage/Modals/MultiboxModal/index.js'
import ButtonRefresh from '../../common/components/Button/ButtonRefresh.js'
import {formatMetaClassName} from './Fields/useLabelProperty.js'
import RatePopOut from './RatePopOut.js'
import useStable from '../../common/useStable.js'
import {useLabelConfigContext} from './LabelConfigContext.js'
import {multiboxShipperTypesSelector} from '../../data/labelInfos/index.js'
import {getState} from '../../store.js'

export default function RateSelection({
  value,
  hasErrors,
  hasWarnings,
  updated,
  loading,
  disabled,
  packageCount,
  canHaveMultibox,
  rates,
  refresh,
  onChange,
  currencySymbol,
}) {
  const {labelInfoID} = useLabelConfigContext()
  const disabledRates = rates.filter((rate) => rate.disabled)
  const notDisabledRates = useStable(rates.filter((rate) => !rate.disabled))

  const hasNoRates = !loading && !disabled && rates.length === 0

  const hasOnlyDisabledRates =
    rates.length === disabledRates.length && rates.length > 0

  useEffect(() => {
    if (!value && notDisabledRates.length) {
      onChange(notDisabledRates[0])
    } else if (value && hasOnlyDisabledRates) {
      onChange(null)
    }
  }, [hasOnlyDisabledRates, value, notDisabledRates])

  return (
    <div className={formatMetaClassName('rate_select')}>
      <label className="label--bold" htmlFor="rate-select">
        <span>
          Shipping Methods/Rates<span className="required">*</span>
        </span>
        {updated && (
          <span className="label__callout label__callout--blue margin-left-5">
            Updated
          </span>
        )}
      </label>
      <div className="flex">
        <RatePopOut
          value={value}
          onChange={(rate) => onChange(rate)}
          rates={rates}
          updated={updated}
          loading={loading}
          disabled={disabled}
          hasErrors={hasErrors}
          hasWarnings={hasWarnings}
          hasOnlyDisabledRates={hasOnlyDisabledRates}
          hasNoRates={hasNoRates}
          currencySymbol={currencySymbol}
        />
        <div className="flex align-items-center">
          <ButtonRefresh
            title="Refresh rates"
            loading={loading}
            onClick={refresh}
            className="meta-labelconfigform-button-refreshrates"
          />
          {canHaveMultibox && (
            <button
              className={classnames(
                'btn btn--primary btn--primary-ol btn--multibox btn--multibox-rs meta-labelconfigform-button-multibox',
                {
                  'btn--disabled': loading,
                },
              )}
              type="button"
              title="Add multiple packages to this label"
              onClick={() => {
                const [shipperType] = multiboxShipperTypesSelector(getState(), {
                  labelInfoID,
                })

                showMultiboxModal(labelInfoID, shipperType)
              }}
            >
              {packageCount === 1 && (
                <span className="text--multibox fs-02">+</span>
              )}
              {packageCount > 1 && (
                <strong className="text--multibox fs-00 lh-lg">
                  {packageCount}
                </strong>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

RateSelection.propTypes = {
  rates: PropTypes.arrayOf(
    PropTypes.oneOfType([RateShape, RateErrorShape, BulkRateShape]),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,

  hasErrors: PropTypes.bool,
  hasWarnings: PropTypes.bool,
  updated: PropTypes.bool,
  loading: PropTypes.bool,
  packageCount: PropTypes.number,
  canHaveMultibox: PropTypes.bool,

  disabled: PropTypes.bool,

  currencySymbol: PropTypes.string.isRequired,
}
