import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {setForm, updateForm, formsSelector, removeFormValue} from '../store.js'
import {fetchAllApiverson} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const LAYOUTS = 'LAYOUTS'
export const LAYOUT_ADDRESS = 'address'
export const LAYOUT_BARCODE = 'barcode'

export function layoutsSelector(state) {
  return formsSelector(state)[LAYOUTS] || layoutsSelector.default
}
layoutsSelector.default = {}

export function layoutsHaveLoadedSelector(state) {
  return !!formsSelector(state)[LAYOUTS]
}

export function layoutSelector(state, {layoutID}) {
  return layoutsSelector(state)[layoutID]
}

export const layoutsSortedByIndexSelector = createSelector(
  layoutsSelector,
  (layouts) => sortBy(layouts, 'index'),
)

export const layoutsByTypeSortedByName = createSelector(
  layoutsSelector,
  (layoutsByID) => {
    const layoutsByType = Object.values(layoutsByID).reduce((prev, layout) => {
      prev[layout.type] = prev[layout.type] || []

      prev[layout.type].push(layout)

      return prev
    }, {})

    return Object.entries(layoutsByType).reduce((prev, [type, layouts]) => {
      prev[type] = sortBy(layouts, 'name')

      return prev
    }, {})
  },
)

export function setLayouts(layouts) {
  setForm(LAYOUTS, keyBy(layouts, 'id'))
}

export function setLayout(layout) {
  updateForm(LAYOUTS, {[layout.id]: layout})
}

export function removeLayout(layoutID) {
  removeFormValue(LAYOUTS, [layoutID])
}

export async function getLayouts() {
  try {
    const layouts = await fetchAllApiverson('/layout', 'layout')

    setLayouts(layouts)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting layouts.',
        details: err.message || err.error_message,
      },
      `Error getting layouts. ${err.error_message || err.message}`,
      err,
    )

    setLayouts([])
  }
}
