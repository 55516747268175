export function numberToEnglish(number) {
  number = Number(number) || 0

  if (number >= 1_000_000_000_000) {
    return 'too many'
  }

  if (number >= 1_000_000_000) {
    const bil = Math.floor(number / 1_000_000_000)
    const remainder = number - bil * 1_000_000_000
    return (
      numberToEnglish(bil) +
      ' billion' +
      (remainder ? ' ' + numberToEnglish(remainder) : '')
    )
  }

  if (number >= 1_000_000) {
    const mil = Math.floor(number / 1_000_000)
    const remainder = number - mil * 1_000_000
    return (
      numberToEnglish(mil) +
      ' million' +
      (remainder ? ' ' + numberToEnglish(remainder) : '')
    )
  }

  if (number >= 1_000) {
    const tho = Math.floor(number / 1_000)
    const remainder = number - tho * 1_000
    return (
      numberToEnglish(tho) +
      ' thousand' +
      (remainder ? ' ' + numberToEnglish(remainder) : '')
    )
  }

  if (number >= 100) {
    const hun = Math.floor(number / 100)
    const remainder = number - hun * 100
    return (
      numberToEnglish(hun) +
      ' hundred' +
      (remainder ? ' ' + numberToEnglish(remainder) : '')
    )
  }

  if (number >= 20) {
    const dec = Math.floor(number / 10)
    const remainder = number - dec * 10
    return (
      (dec === 2
        ? 'twenty'
        : dec === 3
          ? 'thirty'
          : dec === 4
            ? 'forty'
            : dec === 5
              ? 'fifty'
              : dec === 6
                ? 'sixty'
                : dec === 7
                  ? 'seventy'
                  : dec === 8
                    ? 'eighty'
                    : 'ninety') +
      (remainder ? ' ' + numberToEnglish(remainder) : '')
    )
  }

  number = Math.floor(number)

  return number === 19
    ? 'nineteen'
    : number === 18
      ? 'eighteen'
      : number === 17
        ? 'seventeen'
        : number === 16
          ? 'sixteen'
          : number === 15
            ? 'fifteen'
            : number === 14
              ? 'fourteen'
              : number === 13
                ? 'thirteen'
                : number === 12
                  ? 'twelve'
                  : number === 11
                    ? 'eleven'
                    : number === 10
                      ? 'ten'
                      : number === 9
                        ? 'nine'
                        : number === 8
                          ? 'eight'
                          : number === 7
                            ? 'seven'
                            : number === 6
                              ? 'six'
                              : number === 5
                                ? 'five'
                                : number === 4
                                  ? 'four'
                                  : number === 3
                                    ? 'three'
                                    : number === 2
                                      ? 'two'
                                      : number === 1
                                        ? 'one'
                                        : 'zero'
}
