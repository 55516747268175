import PropTypes from 'prop-types'

import LinkButton from '../../../common/components/Link/LinkButton.js'
import {returnOrderReferenceIDsSelector} from '../../../data/orders.js'
import {useSelector} from '../../../store.js'

export default function LinkedRMAListSidebar({orderNumber}) {
  const referenceIDs = useSelector((state) =>
    returnOrderReferenceIDsSelector(state, {orderNumber}),
  )

  if (referenceIDs.length === 0) {
    return null
  }

  return (
    <div className="align-left alert alert--standard margin-top-20">
      <h4 className="list__title--order-data margin-bottom-0">
        Associated RMA<span className="lowercase">s</span>
      </h4>
      <ul className="list square margin-bottom-0">
        {referenceIDs.map((referenceID) => (
          <li
            className="list__item fs-n0 margin-bottom-0 margin-top-5"
            key={referenceID}
          >
            <LinkButton
              className="darker"
              title={`Navigate to ${referenceID} detail page`}
              href={`#/rma/${encodeURIComponent(referenceID)}`}
            >
              {referenceID}
            </LinkButton>
          </li>
        ))}
      </ul>
    </div>
  )
}

LinkedRMAListSidebar.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
