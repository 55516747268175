import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  packageCountSelector,
  canHaveMultiboxSelector,
  rateKeySelector,
  setLabelRate,
  ratesSelector,
  ratesLoadingSelector,
  ratesUpdatedSelector,
  hasLabelErrorsSelector,
  hasLabelWarningsSelector,
  rateSelectDisabledSelector,
  labelUseRateShoppingSelector,
  labelShipperTypeSelector,
} from '../../../../data/labelInfos/index.js'
import {getRates} from '../../../../data/labelInfos/rateRequest.js'

import RateSelect from '../../Fields/RateSelect/index.js'
import LabelErrors from './LabelErrors.js'
import LabelWarnings from './LabelWarnings.js'
import Departed from './Departed.js'
import {useSelector} from '../../../../store.js'
import RateSelection from '../../RateSelection.js'
import useStable from '../../../../common/useStable.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'
import {shipperCurrencySymbolSelector} from '../../../../data/shippers.js'

export default function Rates({showErrors = true}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const value = useSelector(
    (state) => rateKeySelector(state, {labelInfoID}) || '',
  )
  const rates = useStable(
    useSelector((state) => ratesSelector(state, {labelInfoID})),
  )
  const disabled = useSelector((state) =>
    rateSelectDisabledSelector(state, {labelInfoID}),
  )
  const hasErrors = useSelector((state) =>
    hasLabelErrorsSelector(state, {labelInfoID}),
  )
  const hasWarnings = useSelector((state) =>
    hasLabelWarningsSelector(state, {labelInfoID}),
  )
  const ratesLoading = useSelector((state) =>
    ratesLoadingSelector(state, {labelInfoID}),
  )
  const ratesUpdated = useSelector((state) =>
    ratesUpdatedSelector(state, {labelInfoID}),
  )
  const packageCount = useSelector((state) =>
    packageCountSelector(state, {labelInfoID}),
  )
  const canHaveMultibox = useSelector((state) =>
    canHaveMultiboxSelector(state, {labelInfoID}),
  )
  const shipperCurrencySymbol = useSelector((state) =>
    shipperCurrencySymbolSelector(state, {shipperType}),
  )
  const labelUseRateShopping = useSelector((state) =>
    labelUseRateShoppingSelector(state, {labelInfoID}),
  )

  return (
    <div
      className={classnames('list__item--shipping-options', {error: hasErrors})}
    >
      {labelUseRateShopping ? (
        <RateSelection
          rates={rates}
          value={value}
          onChange={(rate) => {
            setLabelRate(labelInfoID, rate)
          }}
          hasErrors={hasErrors}
          hasWarnings={hasWarnings}
          updated={ratesUpdated}
          loading={ratesLoading}
          disabled={disabled}
          refresh={() => getRates([labelInfoID])}
          currencySymbol={shipperCurrencySymbol}
          canHaveMultibox={canHaveMultibox}
          packageCount={packageCount}
        />
      ) : (
        <RateSelect
          rates={rates}
          value={value}
          onChange={(rate) => {
            setLabelRate(labelInfoID, rate)
          }}
          hasErrors={hasErrors}
          hasWarnings={hasWarnings}
          updated={ratesUpdated}
          loading={ratesLoading}
          disabled={disabled}
          refresh={() => getRates([labelInfoID])}
          currencySymbol={shipperCurrencySymbol}
          canHaveMultibox={canHaveMultibox}
          packageCount={packageCount}
        />
      )}
      <Departed />
      {showErrors && <LabelErrors />}
      <LabelWarnings />
    </div>
  )
}

Rates.propTypes = {
  showErrors: PropTypes.bool,
}
