import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'

import {formConnectAuto} from '../../common/formConnect.js'
import {setPODetailForm} from './poDetailActions.js'
import {poIDSelector} from './poDetailSelectors.js'

import Modals from '../PurchaseOrderListPage/Modals/index.js'
import Controls from './Controls.js'
import Header from './Header.js'
import TabbedPanels from './TabbedPanels.js'
import POTimeline from './POTimeline.js'
import {hasPurchaseOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {poSelector} from '../../data/pos.js'

export function PurchaseOrderDetailPage({
  poID,
  purchaseOrder,
  hasPurchaseOrderPermission,
}) {
  useEffect(() => {
    document.title = `Purchase Orders - ${poID}`
  }, [poID])

  if (!hasPurchaseOrderPermission) {
    return <NoPermissionsPage />
  }

  return (
    <>
      <Modals />
      {purchaseOrder ? (
        <div className="wrap--container">
          <Controls />
          <div className="wrap--row flex">
            <div className="medium-9 columns">
              <Header />
            </div>
          </div>
          <div className="wrap--row flex margin-bottom-25">
            <div className="medium-12 columns">
              <TabbedPanels />
            </div>
          </div>
          <POTimeline />
        </div>
      ) : (
        <div className="margin-top-40">
          <div className="loading align-center">
            <span className="list-processing animate-spin v-align-middle" />
            <strong className="inline-block v-align-middle fs-02 op-75 margin-left-5">
              Loading...
            </strong>
          </div>
        </div>
      )}
    </>
  )
}

PurchaseOrderDetailPage.propTypes = {
  poID: PropTypes.string,
  purchaseOrder: PropTypes.object,
  hasPurchaseOrderPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  const poID = poIDSelector(state)

  return {
    poID,
    purchaseOrder: poSelector(state, {poID}),
    hasPurchaseOrderPermission: hasPurchaseOrderPermissionSelector(state),
  }
}

export default formConnectAuto(
  connect(mapStateToProps)(PurchaseOrderDetailPage),
  setPODetailForm,
)
