import PropTypes from 'prop-types'
import {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {progressSelector} from '../../../redux/selectors/ui/dataImport/progress.js'

import Header from '../Header.js'

class BaseStrategy extends Component {
  render() {
    const ReconcileForm = this.props.ReconcileForm
    const EditForm = this.props.EditForm
    const progress = this.props.progress
    const needsReconcile =
      this.props.columns.length !== 0 && this.props.data.length === 0
    const needsEdit =
      progress.reviewList.data.length !== 0 &&
      !this.props.isSaving &&
      !progress.isDone

    const reconcileForm = (
      <ReconcileForm
        columns={this.props.columns}
        columnsToDataMap={this.props.columnsToDataMap}
        columnErrors={this.props.columnErrors}
        confirmColumns={this.props.confirmColumns}
        updateColumnMapping={this.props.updateColumnMapping}
      />
    )
    const editForm = this.props.currentData && (
      <EditForm
        data={this.props.currentData}
        columnsToDataMap={this.props.columnsToDataMap}
        updatePayload={this.props.updatePayload}
        saveCurrent={this.props.saveCurrent}
        deleteData={this.props.deleteData}
        queueNextData={this.props.queueNextData}
        queuePreviousData={this.props.queuePreviousData}
        onBlur={this.props.onBlur}
      />
    )

    return (
      <div>
        <Header
          strategyClasses={this.props.strategyClasses}
          processFiles={this.props.processFiles}
          createExampleFile={this.props.createExampleFile}
          confirmColumns={this.props.confirmColumns}
          saveAll={this.props.saveAll}
          queueNextData={this.props.queueNextData}
          stop={this.props.stop}
        />
        {needsReconcile && reconcileForm}
        {needsEdit && editForm}
      </div>
    )
  }
}

BaseStrategy.propTypes = {
  // external props
  strategyClasses: PropTypes.array.isRequired,
  actions: PropTypes.func,
  EditForm: PropTypes.any,
  ReconcileForm: PropTypes.any,
  // from mapStateToProps
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  columnsToDataMap: PropTypes.object.isRequired,
  columnErrors: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
  currentData: PropTypes.object,
  progress: PropTypes.object,
  // from mapDispatchToProps
  processFiles: PropTypes.func.isRequired,
  createExampleFile: PropTypes.func.isRequired,
  confirmColumns: PropTypes.func.isRequired,
  saveCurrent: PropTypes.func.isRequired,
  saveAll: PropTypes.func.isRequired,
  queueNextData: PropTypes.func.isRequired,
  queuePreviousData: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  updatePayload: PropTypes.func.isRequired,
  updateColumnMapping: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const dataImport = state.ui.dataImport

  return {
    columns: dataImport.columns,
    data: dataImport.data,
    columnsToDataMap: dataImport.columnsToDataMap,
    columnErrors: dataImport.columnErrors,
    isSaving: dataImport.isSaving,
    currentData: dataImport.data[dataImport.currentDataIndex],
    progress: progressSelector(state),
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      processFiles: (...args) => ownProps.actions.processFiles(...args),
      createExampleFile: (...args) =>
        ownProps.actions.createExampleFile(...args),
      confirmColumns: (...args) => ownProps.actions.confirmColumns(...args),
      saveCurrent: (...args) => ownProps.actions.saveCurrent(...args),
      saveAll: (...args) => ownProps.actions.saveAll(...args),
      queueNextData: (...args) => ownProps.actions.queueNextData(...args),
      queuePreviousData: (...args) =>
        ownProps.actions.queuePreviousData(...args),
      stop: (...args) => ownProps.actions.stop(...args),
      deleteData: (...args) => ownProps.actions.deleteData(...args),
      updatePayload: (...args) => ownProps.actions.updatePayload(...args),
      updateColumnMapping: (...args) =>
        ownProps.actions.updateColumnMapping(...args),
      onBlur: (...args) => ownProps.actions.onBlur(...args),
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseStrategy)
