import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import fpSet from 'lodash/fp/set.js'
import findIndex from 'lodash/findIndex.js'
import fpGet from 'lodash/fp/get.js'
import {createSelector} from 'reselect'
import find from 'lodash/find.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import api from '../../../../common/api.js'
import {isPositiveNumeric} from '../../../../common/utils.js'
import {
  NEW_GOODS_RECEIPT_ID,
  SCANNED_CODE_NOT_IN_GOODS_RECEIPT,
  SCANNED_CODE_IN_GOODS_RECEIPT,
} from '../../../../common/constants/GoodsReceiptModal.js'
import {getProduct} from '../../../../data/products.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {
  poItemReceivedSelector,
  poSelector,
  setPO,
} from '../../../../data/pos.js'
import {refreshPurchaseOrderList} from '../../purchaseOrderListActions.js'
import {GoodsReceiptLineShape} from '../../../../common/PropTypes.js'
import {showGlobalError} from '../../../GlobalErrorMessage.js'

export const GoodsReceiptModalFormShape = PropTypes.shape({
  incrementAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  poID: PropTypes.string.isRequired,
  goodsReceiptID: PropTypes.string.isRequired,
  goodsReceiptItemID: PropTypes.number,
  scannedCode: PropTypes.string,
  scanResult: PropTypes.string,
  lines: PropTypes.arrayOf(GoodsReceiptLineShape).isRequired,
  isSaving: PropTypes.bool.isRequired,
  serverError: PropTypes.string,
})

export const MODAL_FORM = 'GOODS_RECEIPT_MODAL'

export function showModal(form) {
  setForm(MODAL_FORM, {
    incrementAmount: 1,
    poID: null,
    goodsReceiptID: null,
    goodsReceiptItemID: null,
    scannedCode: null,
    scanResult: null,
    lines: [],
    isSaving: false,
    errorMessage: null,
    ...form,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const scannedCodeMatchesLine = (scannedCode, line) =>
  line.sku === scannedCode || line.upc === scannedCode

export const scannedProductSelector = createSelector(
  modalFormSelector,
  ({scannedCode, lines}) => {
    const productLine = find(lines, (line) =>
      scannedCodeMatchesLine(scannedCode, line),
    )

    return {
      name: productLine.name,
      sku: productLine.sku,
    }
  },
)

export function acknowledgeError() {
  updateModalForm({
    scanResult: null,
    overscannedComponents: null,
  })
}

export function setIncrementAmount(incrementAmount) {
  updateModalForm(
    !isPositiveNumeric(incrementAmount) && incrementAmount !== ''
      ? {}
      : {
          incrementAmount,
        },
  )
}

export function createNewGoodsReceipt(poID) {
  const purchaseOrder = poSelector(getState(), {poID})

  const lines = purchaseOrder.items.map((item) => {
    const fromOtherGoodsReceipts = poItemReceivedSelector(getState(), {
      poID,
      poItemID: item.id,
    })

    return {
      goodsReceiptItemID: null,
      poItemID: item.id,
      name: item.product.name,
      sku: item.product.sku,
      upc: item.product.upc,
      location: item.product.warehouse.location_in_warehouse,
      ordered: item.quantity,
      received: 0,
      fromOtherGoodsReceipts,
      isKit: item.product.is_kit_parent,
    }
  })

  showModal({
    poID,
    goodsReceiptID: NEW_GOODS_RECEIPT_ID,
    lines,
  })
}

export function openGoodsReceiptModal(
  poID,
  goodsReceiptID,
  goodsReceiptItemID,
) {
  try {
    const purchaseOrder = poSelector(getState(), {poID})

    const goodsReceipt = purchaseOrder.goods_receipts.find(
      (goodsReceipt) => goodsReceipt.goods_receipt_id === goodsReceiptID,
    )

    const lines = goodsReceipt.items.map((item) => {
      const poItem = purchaseOrder.items.find(
        (poItem) => poItem.id === item.po_item_id,
      )
      const inAllGoodsReceipts = poItemReceivedSelector(getState(), {
        poID,
        poItemID: poItem.id,
      })

      return {
        goodsReceiptItemID: item.id,
        poItemID: item.po_item_id,
        name: item.product.name,
        sku: item.product.sku,
        upc: item.product.upc,
        location: get(poItem, 'product.warehouse.location_in_warehouse', ''),
        ordered: poItem.quantity,
        received: item.quantity,
        fromOtherGoodsReceipts: inAllGoodsReceipts - item.quantity,
        isKit: item.product.is_kit_parent,
      }
    })

    showModal({
      poID,
      goodsReceiptID,
      goodsReceiptItemID,
      lines,
    })
  } catch (error) {
    showGlobalError(
      {
        summary: 'Error opening goods receipt for editing.',
        details: error.message,
      },
      error,
    )
  }
}

export function forceReceiveAll() {
  const {lines} = modalFormSelector(getState())

  updateModalForm({
    scanResult: null,
    scannedCode: null,
    lines: lines.map((line) =>
      fpSet(
        'received',
        line.received +
          Math.max(
            0,
            line.ordered - line.received - line.fromOtherGoodsReceipts,
          ),
        line,
      ),
    ),
  })
}

export function forceReceiveLine(index) {
  const {lines} = modalFormSelector(getState())
  const line = lines[index]

  updateModalForm({
    scanResult: null,
    scannedCode: null,
    lines: fpSet(
      [index, 'received'],
      line.received +
        Math.max(0, line.ordered - line.received - line.fromOtherGoodsReceipts),
      lines,
    ),
  })
}

export function goodsReceiptBarcodeScanned(scannedCode) {
  const form = modalFormSelector(getState())

  if (!form) {
    return
  }

  const {lines, incrementAmount} = form

  const matchingLineIndex = findIndex(lines, (line) =>
    scannedCodeMatchesLine(scannedCode, line),
  )
  if (matchingLineIndex == -1) {
    updateModalForm({
      scannedCode,
      scanResult: SCANNED_CODE_NOT_IN_GOODS_RECEIPT,
    })

    return
  }

  const prevReceived = fpGet([matchingLineIndex, 'received'], lines)
  updateModalForm({
    lines: fpSet(
      [matchingLineIndex, 'received'],
      Number(prevReceived) + Number(incrementAmount),
      lines,
    ),
    scannedCode,
    scanResult: SCANNED_CODE_IN_GOODS_RECEIPT,
  })
}

export function setLineReceivedValue(index, value) {
  // Just ignore invalid received value inputs. We could handle invalid
  // input more elaborately (for example, by showing it as an error state
  // and disabling save), but this is simpler and seems like it should be
  // good enough to start with.
  if (!isPositiveNumeric(value) && value !== '') {
    return
  }

  const numberValue = value === '' ? 0 : Number(value)

  const {lines} = modalFormSelector(getState())

  updateModalForm({
    scanResult: null,
    scannedCode: null,
    lines: fpSet([index, 'received'], numberValue, lines),
  })
}

export async function saveGoodsReceipt() {
  try {
    const {goodsReceiptID, poID, lines} = modalFormSelector(getState())
    const params = {
      received_lines: lines.map((line) => ({
        po_item_id: line.poItemID,
        quantity: line.received,
      })),
    }

    updateModalForm({
      isSaving: true,
      serverError: null,
    })

    if (goodsReceiptID === NEW_GOODS_RECEIPT_ID) {
      await api.post('/goods_receipt/', {
        po_id: poID,
        ...params,
      })
    } else {
      await api.put(
        `/goods_receipt/${encodeURIComponent(goodsReceiptID)}/`,
        params,
      )
    }

    const {json: purchaseOrder} = await api.get(
      `/purchase_order/${encodeURIComponent(poID)}/`,
    )
    setPO(purchaseOrder)

    showMessageToast(
      `Goods Receipt for ${poID} was ${
        goodsReceiptID === NEW_GOODS_RECEIPT_ID ? 'created' : 'updated'
      }`,
    )

    closeModal()

    await Promise.all(lines.map(({sku}) => getProduct(sku)))

    await refreshPurchaseOrderList()
  } catch (err) {
    updateModalForm({
      serverError: `There was an error saving the goods receipt: ${err.message}`,
      isSaving: false,
    })
  }
}
