import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {MO_STATII, ALL_STATUS} from '../../common/constants/MOs.js'
import {
  TAG_FILTER_OR,
  TAG_FILTER_AND,
  TAG_FILTER_ONLY,
  TAG_FILTER_NOT,
} from '../../common/constants/Tags.js'
import OmniBar from '../../common/components/List/OmniBar.js'
import {TagsShape} from '../../common/PropTypes.js'

import {
  navigateMOList,
  setSearchText,
  updateSearchText,
  setStatus,
  updateStatus,
  setTags,
  updateTags,
  setUntagged,
  updateUntagged,
  updateTagFilterBy,
} from './moListActions.js'
import {moTagsSortedByNameSelector} from '../../data/moTags.js'
import {moListQuerySelector} from './moListSelectors.js'

export const SEARCH_TYPE = 'search'
export const STATUS_TYPE = 'status'
export const TAG_TYPE = 'tag'
export const TAG_AND_TYPE = 'tag_and'
export const TAG_ONLY_TYPE = 'tag_only'
export const TAG_NOT_TYPE = 'tag_not'
const TAG_TYPES = {
  [TAG_FILTER_OR]: TAG_TYPE,
  [TAG_FILTER_AND]: TAG_AND_TYPE,
  [TAG_FILTER_ONLY]: TAG_ONLY_TYPE,
  [TAG_FILTER_NOT]: TAG_NOT_TYPE,
}
export const UNTAGGED_TYPE = 'untagged'

export function buildValue({
  tags,
  tagFilters,
  tagFilterBy,
  untaggedFilter,
  searchText,
  status,
}) {
  const value = []

  MO_STATII.forEach(({slug, name}) => {
    if (slug !== status) {
      return
    }

    value.push({
      type: STATUS_TYPE,
      label: name,
      value: status,
    })
  })

  const tagType = TAG_TYPES[tagFilterBy]
  tags.forEach((tag) => {
    if (!tagFilters.includes(tag.name)) {
      return
    }

    value.push({
      type: tagType,
      label: tag.name,
      value: `${tagType}:${tag.id}`,
      color: tag.color,
      id: tag.id,
    })
  })

  if (untaggedFilter) {
    value.push({
      type: UNTAGGED_TYPE,
      label: 'Untagged',
      value: 'untagged',
    })
  }

  if (searchText) {
    value.push({
      type: SEARCH_TYPE,
      label: searchText,
      value: searchText,
    })
  }

  return value
}

export function processStatusFilterValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
) {
  if (!filterType.match(/^status$/i)) {
    return false
  }

  const statusMatch = MO_STATII.find(({slug, name}) =>
    `${slug} ${name}`.match(RegExp(filterValue, 'i')),
  )

  if (statusMatch) {
    outcome.updates = updateStatus(statusMatch.slug)
  }

  return true
}

export function processTagFilterValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
  tags,
  tagFilters,
) {
  if (
    !(filterType.match(/^(tag)[s]?$/i) && ['', '='].includes(filterOperator))
  ) {
    return false
  }

  const matchedTags = tags.filter((t) => {
    if (filterOperator === '=') {
      if (t.id === Number(filterValue)) {
        return true
      }
      if (t.name === filterValue) {
        return true
      }
    } else {
      if (`${t.name}`.match(RegExp(filterValue, 'i'))) {
        return true
      }
    }

    return false
  })

  if (matchedTags.length) {
    outcome.updates = {
      ...updateTags([...tagFilters, ...matchedTags.map(({name}) => name)]),
      ...updateUntagged(false),
    }
  }

  return true
}

export function processUntaggedValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
) {
  if (!filterValue.match(/^untag(ged)?$/i)) {
    return false
  }

  outcome.updates = {
    ...updateTags([]),
    ...updateUntagged(true),
  }

  return true
}

export function processTagFilterByValue(
  filterType,
  filterOperator,
  filterValue,
  outcome,
) {
  if (
    !(
      filterType.match(/^(tag.filter(ing)?.logic|tfl)$/i) &&
      ['', '='].includes(filterOperator)
    )
  ) {
    return false
  }

  const filterBy = filterValue.match(/^(and|all)$/i)
    ? TAG_FILTER_AND
    : filterValue.match(/^only$/i)
      ? TAG_FILTER_ONLY
      : filterValue.match(/^not$/i)
        ? TAG_FILTER_NOT
        : TAG_FILTER_OR

  outcome.updates = updateTagFilterBy(filterBy)

  return true
}

export function processSearchTextFilterValue(inputValue, outcome) {
  outcome.updates = updateSearchText(inputValue)

  return true
}

export function processInputValue(inputValue, tags, tagFilters) {
  if (!inputValue) {
    return ''
  }

  let [, filterType = '', filterOperator = '', filterValue] = inputValue.match(
    /^(?:(.*)?:([=><-])?)?(.*)$/,
  )
  const outcome = {updates: null}

  filterType = filterType.trim()
  filterValue = filterValue.trim()

  processStatusFilterValue(filterType, filterOperator, filterValue, outcome) ||
    processTagFilterValue(
      filterType,
      filterOperator,
      filterValue,
      outcome,
      tags,
      tagFilters,
    ) ||
    processUntaggedValue(filterType, filterOperator, filterValue, outcome) ||
    processTagFilterByValue(filterType, filterOperator, filterValue, outcome) ||
    processSearchTextFilterValue(inputValue, outcome)

  if (outcome.updates) {
    navigateMOList(outcome.updates)

    return ''
  }

  return inputValue
}

export function removeValue(value, tagFilters) {
  if (value.type === STATUS_TYPE) {
    return setStatus(ALL_STATUS)
  } else if (
    [TAG_TYPE, TAG_AND_TYPE, TAG_ONLY_TYPE, TAG_NOT_TYPE].includes(value.type)
  ) {
    return setTags(tagFilters.filter((name) => name !== value.label))
  } else if (value.type === UNTAGGED_TYPE) {
    return setUntagged(false)
  } else if (value.type === SEARCH_TYPE) {
    return setSearchText('')
  }
}

export function clearAll() {
  return navigateMOList({
    ...updateStatus(ALL_STATUS),
    ...updateTags([]),
    ...updateUntagged(false),
    ...updateTagFilterBy(TAG_FILTER_OR),
    ...updateSearchText(''),
  })
}

export function editValue(data, setInputValue) {
  if (data.type === SEARCH_TYPE) {
    setInputValue(data.value)
  }
}

function MOListOmniBar({
  tags,
  tagFilters,
  searchText,
  status,
  untaggedFilter,
  tagFilterBy,
}) {
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setValue(
      buildValue({
        tags,
        tagFilters,
        searchText,
        status,
        untaggedFilter,
        tagFilterBy,
      }),
    )
  }, [tags, tagFilters, searchText, status, untaggedFilter, tagFilterBy])

  return (
    <OmniBar
      placeholder="Enter an MFG Order ID or Product SKU..."
      inputValue={inputValue}
      value={value}
      onRemoveValue={(removedValue) => removeValue(removedValue, tagFilters)}
      onClear={() => clearAll()}
      onInputValueChange={(inputValue) => setInputValue(inputValue)}
      onProcessInputValue={(inputValue) =>
        setInputValue(processInputValue(inputValue, tags, tagFilters))
      }
      onEditValue={(data) => editValue(data, setInputValue)}
    />
  )
}

MOListOmniBar.propTypes = {
  tags: TagsShape.isRequired,
  tagFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  untaggedFilter: PropTypes.bool.isRequired,
  tagFilterBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  const params = moListQuerySelector(state)

  return {
    tags: moTagsSortedByNameSelector(state),
    tagFilters: params.tags,
    untaggedFilter: params.untagged,
    tagFilterBy: params.tagFilterBy,
    searchText: params.searchText,
    status: params.status,
  }
}

export default connect(mapStateToProps)(MOListOmniBar)
