import {onlyIfForm, useSelector} from '../../../../store.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import TagFilterPanel from './TagFilterPanel.js'
import {
  closeModal,
  modalFormSelector,
  applyFilter,
  FILTERS_PANEL,
  SALES_CHANNEL_FILTER_PANEL,
  SUPPLIER_FILTER_PANEL,
  WAREHOUSE_FILTER_PANEL,
  TAG_FILTER,
  MISC_FILTER_PANEL,
  productListFilterOrderSelector,
  clearFilters,
} from './productListFilterModalFunctions.js'
import FiltersPanel from './FiltersPanel.js'
import SalesChannelFilterPanel from './SalesChannelFilterPanel.js'
import SupplierFilterPanel from './SupplierFilterPanel.js'
import WarehouseFilterPanel from './WarehouseFilterPanel.js'
import MiscFilterPanel from './MiscFilterPanel.js'
import {showProductListFilterSettingsModal} from '../ProductListFilterSettingsModal.js'

const FILTER_PANEL_LIST = {
  [FILTERS_PANEL]: FiltersPanel,
  [SALES_CHANNEL_FILTER_PANEL]: SalesChannelFilterPanel,
  [SUPPLIER_FILTER_PANEL]: SupplierFilterPanel,
  [WAREHOUSE_FILTER_PANEL]: WarehouseFilterPanel,
  [TAG_FILTER]: TagFilterPanel,
  [MISC_FILTER_PANEL]: MiscFilterPanel,
}

function ProductListFilterModal() {
  const filterList = useSelector(productListFilterOrderSelector)

  return (
    <ConfirmModal
      title="Product Filters"
      modalSize="md"
      className="modal--order-list-filters"
      onConfirm={() => applyFilter()}
      onCancel={() => closeModal()}
      confirmText="Apply"
      cancelText="Cancel"
      preventHotKeyConfirm
      RightButtons={() => (
        <div className="flex--justify">
          <ButtonPrimary
            isOutlined
            className="btn--sm margin-right-10"
            onClick={() => clearFilters()}
          >
            Clear Filters
          </ButtonPrimary>
          <ButtonPrimary
            isOutlined
            className="btn--sm"
            onClick={() => showProductListFilterSettingsModal()}
          >
            <span
              className="icon icon-settings icon-block x-sm"
              aria-hidden="true"
            />
          </ButtonPrimary>
        </div>
      )}
    >
      {filterList.map((item) => {
        const Panel = FILTER_PANEL_LIST[item]

        return Panel ? <Panel key={item} /> : null
      })}
    </ConfirmModal>
  )
}

export default onlyIfForm(ProductListFilterModal, modalFormSelector)
