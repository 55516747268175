import Currency from '../../../../../../../common/components/Currency.js'
import {showInsuranceModal} from '../../../../../../OrderListPage/Modals/InsuranceModal.js'
import {
  insuranceCostSelector,
  includeInsuranceSelector,
  insuredValueSelector,
  canHaveInsuranceSelector,
  labelShipperTypeSelector,
} from '../../../../../../../data/labelInfos/index.js'
import {useSelector} from '../../../../../../../store.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'

export default function InsuranceItem() {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const insuranceCost = useSelector((state) =>
    insuranceCostSelector(state, {labelInfoID, shipperType}),
  )
  const insuredValue = useSelector(
    (state) => insuredValueSelector(state, {labelInfoID}) || '',
  )
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {labelInfoID}),
  )
  const canHaveInsurance = useSelector((state) =>
    canHaveInsuranceSelector(state, {labelInfoID, shipperType}),
  )

  if (!canHaveInsurance) {
    return null
  }

  return (
    <li className="list__item--order-data list__item--shipping-info">
      <strong className="margin-right-3">
        Third-Party Insurance (via Shipsurance):
      </strong>
      <button
        className="btn btn--link darker v-align-base meta-orderlistrow-expanded-button-insurance"
        type="button"
        onClick={() => showInsuranceModal(labelInfoID, shipperType)}
      >
        {includeInsurance ? (
          <span>
            <Currency value={insuranceCost} currencySymbol="$" /> (
            <Currency value={insuredValue} currencySymbol="$" /> of coverage)
          </span>
        ) : (
          <em>None</em>
        )}
      </button>
    </li>
  )
}
