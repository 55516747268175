import {NEW_ID} from '../../../../../../common/constants/index.js'
import {isPresent} from '../../../../../../common/utils.js'

export function errorsSelector(shipper) {
  const errors = {}

  if (!isPresent(shipper.merchantID)) {
    errors.merchantID = 'Merchant ID is required'
  }

  if (!isPresent(shipper.clientFacilityID)) {
    errors.clientFacilityID = 'Client Facility ID is required'
  }

  if (!isPresent(shipper.ngsFacilityID)) {
    errors.ngsFacilityID = 'NGS Facility ID is required'
  }

  return errors
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id || NEW_ID,
    link: shipper._link,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    merchantID: vendorConfig.merchant_id || '',
    clientFacilityID: vendorConfig.client_facility_id || '',
    ngsFacilityID: vendorConfig.ngs_facility_id || '',
    isSaving: false,
  }
}
