import {
  labelConfigSelector,
  updateLabelConfig,
  labelPropertiesSelector,
} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {getState, useSelector} from '../../../store.js'
import {DHL, FEDEX, UPS} from '../../../common/constants/ShipperNames.js'
import {LABEL_PROPERTY_SHIPPER_ID} from './ShipperSelect/index.js'
import ConfigTextInput from './ConfigTextInput.js'
import useLabelProperty from './useLabelProperty.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

// Type
const VALID_SHIPPER_TYPES = [DHL, FEDEX, UPS]

function dependantPropertiesFunc() {
  return [LABEL_PROPERTY_SHIPPER_ID]
}

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__payment_type`]
    : []
}

// Account
const LABEL_PROPERTY_PAYMENT_ACCOUNT = 'payment_account'
const PAYMENT_ACCOUNT_VALID_LABEL_PROPERTIES = [LABEL_PROPERTY_PAYMENT_ACCOUNT]

function showPaymentAccountSelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const paymentType = labelConfig[labelProperty] || ''

  return (
    (paymentType !== 'S' && shipperType === DHL) ||
    (paymentType !== 'SENDER' && shipperType === FEDEX) ||
    (paymentType !== 'BillShipper' && shipperType === UPS)
  )
}

function paymentAccountDependantFunc(shipperType) {
  return [
    ...dependantPropertiesFunc(shipperType),
    ...labelPropertiesFunc(shipperType),
  ]
}

function paymentAccountPropertiesFunc(shipperType, labelInfoID) {
  const showPaymentAccount = showPaymentAccountSelector(getState(), {
    labelInfoID,
    shipperType,
  })

  return showPaymentAccount ? [LABEL_PROPERTY_PAYMENT_ACCOUNT] : []
}

// Zip and Country
const ZIP_COUNTRY_VALID_SHIPPER_TYPES = [UPS]
const LABEL_PROPERTY_PAYMENT_ZIP = 'payment_zip'
const LABEL_PROPERTY_PAYMENT_COUNTRY = 'payment_country'
const ZIP_COUNTRY_VALID_LABEL_PROPERTIES = [
  LABEL_PROPERTY_PAYMENT_ZIP,
  LABEL_PROPERTY_PAYMENT_COUNTRY,
]

function showPaymentZipCountrySelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const [labelProperty] = labelPropertiesFunc(shipperType)

  const paymentType = labelConfig[labelProperty] || ''

  return paymentType === 'BillThirdParty' && shipperType === UPS
}

function isZipCountryPropertiesEnabledSelector(state, {labelInfoID}) {
  const properties = labelPropertiesSelector(state, {labelInfoID})

  return (
    properties.includes(LABEL_PROPERTY_PAYMENT_ZIP) &&
    properties.includes(LABEL_PROPERTY_PAYMENT_COUNTRY)
  )
}

function zipCountryDependantPropertiesFunc(shipperType) {
  return [
    ...dependantPropertiesFunc(shipperType),
    ...labelPropertiesFunc(shipperType),
  ]
}

function zipCountryLabelPropertiesFunc(shipperType, labelInfoID) {
  const showPaymentZipCountry = showPaymentZipCountrySelector(getState(), {
    labelInfoID,
    shipperType,
  })

  return showPaymentZipCountry
    ? [LABEL_PROPERTY_PAYMENT_ZIP, LABEL_PROPERTY_PAYMENT_COUNTRY]
    : []
}

export default function Payor() {
  const {labelInfoID, shipperType, onChange, isLabelEnableMode} =
    useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const showPaymentAccount = useSelector((state) =>
    showPaymentAccountSelector(state, {labelInfoID, shipperType}),
  )
  const showPaymentZipCountry = useSelector((state) =>
    showPaymentZipCountrySelector(state, {labelInfoID, shipperType}),
  )

  // Zip and Country
  const {isLabelPropertyEnabled} = useLabelProperty({
    labelProperty: LABEL_PROPERTY_PAYMENT_ZIP,
    validShipperTypes: ZIP_COUNTRY_VALID_SHIPPER_TYPES,
    dependantPropertiesFunc: zipCountryDependantPropertiesFunc,
    labelPropertiesFunc: zipCountryLabelPropertiesFunc,
    validLabelProperties: ZIP_COUNTRY_VALID_LABEL_PROPERTIES,
  })

  const paymentZip = labelConfig[LABEL_PROPERTY_PAYMENT_ZIP] || ''
  const paymentCountry = labelConfig[LABEL_PROPERTY_PAYMENT_COUNTRY] || ''

  return (
    <>
      <ConfigSelect
        label="Payor"
        noEmptyOption
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [labelProperty]: value,
          })

          onChange()
        }}
        labelProperty={labelProperty}
        labelPropertiesFunc={labelPropertiesFunc}
        validShipperTypes={VALID_SHIPPER_TYPES}
        dependantPropertiesFunc={dependantPropertiesFunc}
        shipperOptionsProperty="payor"
      />
      <ConfigTextInput
        label="Payor Account Number"
        onChange={(value) => {
          updateLabelConfig(labelInfoID, {
            [LABEL_PROPERTY_PAYMENT_ACCOUNT]: value,
          })

          onChange()
        }}
        labelProperty={LABEL_PROPERTY_PAYMENT_ACCOUNT}
        validShipperTypes={VALID_SHIPPER_TYPES}
        labelPropertiesFunc={paymentAccountPropertiesFunc}
        dependantPropertiesFunc={paymentAccountDependantFunc}
        validLabelProperties={PAYMENT_ACCOUNT_VALID_LABEL_PROPERTIES}
        hide={!showPaymentAccount}
      />
      {showPaymentZipCountry && (
        <li className="list__item--shipping-options fs-n1 margin-bottom-5">
          <div className="flex flex-wrap wrap--edit-preset-form-input">
            <div className="margin-bottom-5 margin-right-15">
              <label className="label--bold" htmlFor="payment-zip">
                Payor Zipcode
              </label>
              <input
                className="input--md inline-block"
                type="text"
                id="payment-zip"
                value={paymentZip}
                onChange={(event) => {
                  updateLabelConfig(labelInfoID, {
                    [LABEL_PROPERTY_PAYMENT_ZIP]: event.target.value,
                  })

                  onChange()
                }}
                disabled={isLabelEnableMode && !isLabelPropertyEnabled}
              />
            </div>
            <div className="margin-bottom-5">
              <label className="label--bold" htmlFor="payment-country">
                Payor Country Code
              </label>
              <input
                className="input--sm inline-block"
                type="text"
                id="payment-country"
                value={paymentCountry}
                onChange={(event) => {
                  updateLabelConfig(labelInfoID, {
                    [LABEL_PROPERTY_PAYMENT_COUNTRY]: event.target.value,
                  })

                  onChange()
                }}
                disabled={isLabelEnableMode && !isLabelPropertyEnabled}
              />
            </div>
          </div>
          <EnableLabelProperty
            labelProperty={LABEL_PROPERTY_PAYMENT_ZIP}
            checkedSelector={(state) =>
              isZipCountryPropertiesEnabledSelector(state, {
                labelInfoID,
              })
            }
          />
        </li>
      )}
    </>
  )
}
