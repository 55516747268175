import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isNil from 'lodash/isNil.js'

export const skuMappingsSelector = (state) => state.data.productKits.skuMappings
const kitGraphsSelector = (state) => state.data.productKits.kitGraphs

const kitGraphSelector = (state, {sku}) => {
  const skuMappings = skuMappingsSelector(state)
  const kitGraphs = kitGraphsSelector(state)

  const kitGraphLabel = get(skuMappings, sku)
  return get(kitGraphs, kitGraphLabel, null)
}

const productForNode = (sku, kitGraph) => {
  if (isNil(kitGraph)) {
    return null
  }

  const {nodes, edges} = kitGraph
  const {id, ...rest} = nodes.find((node) => node.id === sku)

  const childEdges = edges.filter((edge) => edge.source === sku)
  const product = {
    sku: id,
    quantity: 1,
    ...rest,
  }

  if (childEdges.length > 0) {
    product.children = childEdges.map(({dest, weight}) => {
      const component = productForNode(dest, kitGraph)
      component.quantity = weight
      return component
    })
  }

  return product
}

export const makeProductKitSelector = () =>
  createSelector(
    kitGraphSelector,
    (_, {sku}) => sku,
    (kitGraph, sku) => {
      if (isNil(kitGraph)) {
        return null
      }
      return productForNode(sku, kitGraph)
    },
  )
