import RefreshButton from '../../common/components/List/RefreshButton.js'
import {refreshMOList} from './moListActions.js'

export default function RefreshMOs() {
  return (
    <li
      className="inline-block margin-right-3 tooltip--top"
      data-hint="Refresh the Manufacturing Order list"
    >
      <RefreshButton onClick={() => refreshMOList()} />
    </li>
  )
}
