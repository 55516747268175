import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import get from 'lodash/get.js'

import {
  ReturnOrderCodeShape,
  SelectOptionsShape,
} from '../../../../common/PropTypes.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import Select from '../../../../common/components/Select.js'
import {productSelector} from '../../../../data/products.js'
import {nonFBAWarehouseOptionsSelector} from '../../../../data/warehouses.js'

function Line({
  index,
  line: {
    expectedQuantity,
    toBeReceivedQuantity,
    toBeRestockedQuantity,
    restockWarehouseID,
    ...line
  },
  errors,
  autoFocusExpected,
  autoFocusReceived,
  autoFocusRestocked,
  name,
  warehouseOptions,
  ...props
}) {
  return (
    <tr>
      <td className="table__td">
        <div className="fs-n1">
          <strong>{name}</strong>
        </div>
        <div className="fs-n1">{line.sku}</div>
      </td>
      <td className="table__td">
        <span
          title={line.code ? line.code.description : 'None specified'}
          className="fs-n1"
        >
          {line.code ? line.code.code : <em>None specified</em>}
        </span>
      </td>
      <td className="table__td border-left--light align-center">
        <div className="inline-block">
          <NumberInput
            className="margin-bottom-0"
            value={expectedQuantity}
            onChange={(value) =>
              props.setModalFormValue(
                `lines.${index}.expectedQuantity`,
                `${value}`,
              )
            }
            autoFocus={autoFocusExpected}
          />
          {errors[`lines__${index}__expectedQuantity`] && (
            <small className="error">
              {errors[`lines__${index}__expectedQuantity`]}
            </small>
          )}
        </div>
      </td>
      <td className="table__td border-left--light align-center">
        <div className="inline-block">
          <NumberInput
            className="margin-bottom-0"
            value={toBeReceivedQuantity}
            onChange={(value) =>
              props.setModalFormValue(
                `lines.${index}.toBeReceivedQuantity`,
                `${value}`,
              )
            }
            autoFocus={autoFocusReceived}
          />
          {errors[`lines__${index}__toBeReceivedQuantity`] && (
            <small className="error">
              {errors[`lines__${index}__toBeReceivedQuantity`]}
            </small>
          )}
        </div>
        <div className="fs-n2 text--lt-grey margin-top-5">
          (Previous: <span>{line.received_quantity}</span>)
        </div>
      </td>
      <td className="table__td border-left--light align-center">
        <div className="inline-block">
          <NumberInput
            className="margin-bottom-0"
            value={toBeRestockedQuantity}
            onChange={(value) =>
              props.setModalFormValue(
                `lines.${index}.toBeRestockedQuantity`,
                `${value}`,
              )
            }
            autoFocus={autoFocusRestocked}
            disabled={!restockWarehouseID}
          />
          {errors[`lines__${index}__toBeRestockedQuantity`] && (
            <small className="error">
              {errors[`lines__${index}__toBeRestockedQuantity`]}
            </small>
          )}
        </div>
        <div className="fs-n2 text--lt-grey margin-top-5">
          (Previous: <span>{line.restocked_quantity}</span>)
        </div>
      </td>
      <td className="table__td border-left--light">
        <Select
          className="w-100"
          value={restockWarehouseID || ''}
          onChange={(value) => {
            value = value ? Number(value) : null

            props.setModalFormValue(`lines.${index}.restockWarehouseID`, value)

            if (!value) {
              props.setModalFormValue(
                `lines.${index}.toBeRestockedQuantity`,
                '0',
              )
            }
          }}
          disabled={line.restocked_quantity !== 0}
          errorMessage={errors[`lines__${index}__restockWarehouseID`]}
        >
          <option value="">None specified</option>
          {warehouseOptions.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </Select>
      </td>
    </tr>
  )
}

Line.propTypes = {
  index: PropTypes.number.isRequired,
  line: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    received_quantity: PropTypes.number.isRequired,
    restocked_quantity: PropTypes.number.isRequired,
    expected_quantity: PropTypes.number,
    code: ReturnOrderCodeShape,
    expectedQuantity: PropTypes.string.isRequired,
    toBeRestockedQuantity: PropTypes.string.isRequired,
    toBeReceivedQuantity: PropTypes.string.isRequired,
    restockWarehouseID: PropTypes.number,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  autoFocusExpected: PropTypes.bool.isRequired,
  autoFocusReceived: PropTypes.bool.isRequired,
  autoFocusRestocked: PropTypes.bool.isRequired,
  setModalFormValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  warehouseOptions: SelectOptionsShape.isRequired,
}

function mapStateToProps(state, {line: {sku}}) {
  return {
    name: get(productSelector(state, {sku}), 'name', ''),
    warehouseOptions: nonFBAWarehouseOptionsSelector(state),
  }
}

export default connect(mapStateToProps)(Line)
