import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {needsAppReloadSelector} from '../../redux/selectors/data/serverInfo.js'

export function ReloadMessage({needsAppReload}) {
  if (!needsAppReload) {
    return null
  }

  return (
    <div className="wrap--reload-app-msg full-width">
      <div className="row">
        <div className="medium-12 columns">
          <p className="fs-00 lh-sm margin-bottom-0 align-center">
            <button
              className="btn btn--link lightest"
              onClick={() => window.location.reload()}
            >
              <strong>
                Please reload the page for the latest version of Ordoro
              </strong>
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}

ReloadMessage.propTypes = {
  needsAppReload: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    needsAppReload: needsAppReloadSelector(state),
  }
}

export default connect(mapStateToProps)(ReloadMessage)
