import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import FormattedDate from '../../common/components/FormattedDate.js'
import {analyticsFormSelector} from './analyticsSelectors.js'

export default function Header({children}) {
  const form = useSelector(analyticsFormSelector)

  return (
    <div className="row">
      <div className="medium-12 columns">
        <div className="flex--justify">
          <h2 className="list-title margin-top-0 margin-bottom-15">
            {children}
          </h2>
          <div className="flex--justify-col">
            {form.lastSuccessfulDataSync ? (
              <div>
                <em className="fs-n0 lh-sm text--lt-grey make-block align-right margin-bottom-3">
                  <strong>Data Updated:</strong>{' '}
                  <FormattedDate
                    value={form.lastSuccessfulDataSync}
                    format="MMM D, YYYY [at] h:mma"
                  />
                </em>
                <em className="fs-n1 lh-sm text--lt-grey make-block align-right op-75">
                  (Updated approx. twice per hour)
                </em>
              </div>
            ) : (
              <div className="loading align-center">
                <span className="spinner--x-sm v-align-middle" />{' '}
                <em className="fs-n0 lh-sm text--lt-grey">Updating data...</em>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
}
