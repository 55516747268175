import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {useSelector, useForm, formSelector} from '../../../store.js'
import {SHOPIFY} from '../../../common/constants/CartVendorOptions.js'
import {
  usesInventorySelector,
  useShopifyLocationAsWarehouseMappedSelector,
} from '../../../data/company.js'
import {
  warehouseNameSelector,
  warehousesSortedByNameSelector,
} from '../../../data/warehouses.js'
import {
  cartSelector,
  cartVendorNameSelector,
  cartVendorSelector,
  getHasLinkedWarehouses,
  hasMultiLocationsSelector,
  multiLocationsSelector,
} from '../../../data/carts.js'
import Helper from '../Common/Helper.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showEditCartLocationWarehousesModal} from '../Modals/EditCartLocationWarehousesModal.js'

export function currentCartHasLinkedWarehousesSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const warehouses = warehousesSortedByNameSelector(state)
  const hasMultiLocations = hasMultiLocationsSelector(state, {
    cartID: form.id,
  })

  return getHasLinkedWarehouses(form.cweb, hasMultiLocations, warehouses)
}

function Warehouse({cartID, warehouseID}) {
  const cart = useSelector((state) => cartSelector(state, {cartID}))
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID}),
  )

  const isLinked = get(cart, ['cweb', warehouseID, 'include']) !== false

  return (
    <div className="list__item--form margin-bottom-15 fs-n0">
      <strong>{warehouseName}</strong>
      {!isLinked && (
        <div className="margin-top-0">
          <em className="fs-n0 op-50">(Not synced)</em>
        </div>
      )}
    </div>
  )
}

Warehouse.propTypes = {
  cartID: PropTypes.number.isRequired,
  warehouseID: PropTypes.number.isRequired,
}

function MultiWarehouse({cartID, warehouseID}) {
  const cart = useSelector((state) => cartSelector(state, {cartID}))
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID}),
  )
  const multiLocations =
    useSelector((state) => multiLocationsSelector(state, {cartID})) || []

  const warehouseLink = get(cart, ['cweb', warehouseID]) || {
    include: true,
    locations: [],
  }
  const locationID = get(warehouseLink, 'locations[0]', '')

  const location = multiLocations.find(({id}) => id === locationID) || {
    name: locationID || <em className="op-50">Not set</em>,
  }

  return (
    <tr>
      <td className="table__td table__td--sm v-align-middle">
        <strong className="fs-n0">{warehouseName}</strong>
        {!warehouseLink.include && (
          <div className="margin-top-1">
            <em className="fs-n0 op-50">(Not synced)</em>
          </div>
        )}
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        <strong className="fs-01 v-align-middle text--lt-grey">→</strong>
      </td>
      <td className="table__td table__td--sm v-align-middle">
        {location.name}
      </td>
    </tr>
  )
}

MultiWarehouse.propTypes = {
  cartID: PropTypes.number.isRequired,
  warehouseID: PropTypes.number.isRequired,
}

export default function Warehouses({formName}) {
  const form = useForm(formName)

  const warehouses = useSelector(warehousesSortedByNameSelector)
  const hasMultiLocations = useSelector((state) =>
    hasMultiLocationsSelector(state, {cartID: form.id}),
  )
  const cartVendorName = useSelector((state) =>
    cartVendorNameSelector(state, {cartID: form.id}),
  )
  const hasLinkedWarehouses = useSelector((state) =>
    currentCartHasLinkedWarehousesSelector(state, {formName}),
  )
  const usesInventory = useSelector(usesInventorySelector)
  const useShopifyLocationAsWarehouseMapped = useSelector(
    useShopifyLocationAsWarehouseMappedSelector,
  )
  const vendor = useSelector((state) =>
    cartVendorSelector(state, {cartID: form.id}),
  )

  const canSyncTracking =
    useShopifyLocationAsWarehouseMapped && vendor === SHOPIFY

  if (!canSyncTracking && !usesInventory) {
    return null
  }

  return (
    <div className="divider--top margin-top-15">
      <p className="fs-00 margin-bottom-10">
        {canSyncTracking ? (
          <>
            <strong className="margin-right-3">
              {usesInventory ? 'Inventory and Tracking Sync' : 'Tracking Sync'}
            </strong>
            <a
              href="https://support.ordoro.com/shopify-locations-does-it-matter-which-ordoro-warehouse-is-mapped/"
              title="What does this mean?"
              className="btn btn--link no-underline v-align-middle"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span
                className="icon icon-support icon-block fs-n0"
                aria-hidden="true"
              />
            </a>
          </>
        ) : (
          <strong>Warehouse Inventory Sync Config</strong>
        )}
      </p>
      <div className="margin-bottom-15">
        <ButtonPrimary
          isOutlined
          size="x-sm"
          onClick={() => showEditCartLocationWarehousesModal({cartID: form.id})}
        >
          Edit Syncing
        </ButtonPrimary>
      </div>
      {!hasLinkedWarehouses && (
        <Helper
          title="Syncing is completely disabled"
          type="warning-lt"
          className="margin-bottom-10"
        >
          Without any warehouses selected, this sales channel will not have any
          inventory levels written back to it.
        </Helper>
      )}
      {!hasMultiLocations && (
        <div className="list--form">
          {warehouses.map(({id}) => (
            <Warehouse key={id} cartID={form.id} warehouseID={id} />
          ))}
        </div>
      )}
      {hasMultiLocations && (
        <table className="table table--multi-location margin-top-5">
          <thead>
            <tr>
              <th className="table__th table__th--sm fs-n1 text--md-grey w-40">
                Ordoro Warehouse
              </th>
              <th className="table__th table__th--sm fs-n1 text--md-grey">
                &nbsp;
              </th>
              <th className="table__th table__th--sm fs-n1 text--md-grey w-40">
                <span className="margin-right-3">
                  {cartVendorName} Location
                </span>
                <a
                  href="https://support.ordoro.com/shopify-locations-and-ordoro-setup/"
                  title="What does this mean?"
                  className="btn btn--link no-underline v-align-middle"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    className="icon icon-support icon-block fs-n0"
                    aria-hidden="true"
                  />
                </a>
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody--multi-location">
            {warehouses.map(({id}) => (
              <MultiWarehouse key={id} cartID={form.id} warehouseID={id} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

Warehouses.propTypes = {
  formName: PropTypes.string.isRequired,
}
