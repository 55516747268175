import classNames from 'classnames'

import {
  getSupplierName,
  getIsFBA,
  supplierWarehouseSelector,
} from '../../../data/suppliers.js'
import Form from './Form.js'
import {
  suppliersFormSelector,
  errorsSelector,
  syncFBAInventory,
  saveSupplier,
} from './suppliersFunctions.js'
import {useSelector} from '../../../store.js'
import {SupplierShape} from '../../../common/PropTypes.js'
import {SUPPLIER_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import {COMMUNICATION_EMAIL} from '../../../common/constants/Suppliers.js'

export default function EditForm({supplier}) {
  const form = useSelector(suppliersFormSelector)
  const errors = useSelector(errorsSelector)
  const warehouse = useSelector((state) =>
    supplierWarehouseSelector(state, {supplierID: supplier.id}),
  )

  const isExpanded = supplier.id === form.id
  const editLink = `${SUPPLIER_SETTINGS_LINK}/${supplier.id}`
  const isFBASupplier = getIsFBA(supplier)
  const isArchived = !!supplier.archive_date
  const emails = [
    supplier.dropshipment_routing_channel === COMMUNICATION_EMAIL &&
      supplier.dropshipment_routing_address,
    supplier.po_routing_channel === COMMUNICATION_EMAIL &&
      supplier.po_routing_address,
  ]
    .filter((v) => v)
    .reduce((prev, email) => {
      if (!prev.includes(email)) {
        prev.push(email)
      }

      return prev
    }, [])
    .join(', ')

  return (
    <li
      className={classNames('settings-list-item clearfix', {
        expanded: isExpanded,
        archived: isArchived,
      })}
    >
      {isFBASupplier && (
        <div>
          <span className="medium-10 small-8 columns">
            <strong className="make-block">{getSupplierName(supplier)}</strong>
            {warehouse && (
              <p className="make-smaller make-medium-grey margin-bottom-0">
                {'FBA inventory is syncing with '}
                <a
                  className="inline-text-button darker with-underline"
                  href="#/settings/warehouse"
                >
                  {warehouse.address.name}
                </a>
              </p>
            )}
            {!warehouse && !isArchived && (
              <button
                className="inline-text-button hyperlink make-smaller"
                onClick={() => syncFBAInventory(supplier.id)}
              >
                Start syncing FBA inventory
              </button>
            )}
          </span>
          {isArchived ? (
            <em className="medium-2 columns right-aligned-text right">
              Archived
            </em>
          ) : (
            <span className="medium-2 small-4 columns right right-aligned-text">
              <a
                type="button"
                className="inline-text-button settings-list-item-button"
                href={editLink}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      )}
      {!isFBASupplier && (
        <div>
          <strong className="settings-list-item-title medium-5 small-8 columns">
            {getSupplierName(supplier)}
          </strong>
          {emails && (
            <span className="settings-list-item-desc supplier-email medium-5 hide-for-small columns">
              {emails}
            </span>
          )}
          {isArchived ? (
            <em className="medium-2 columns right-aligned-text right">
              Archived
            </em>
          ) : (
            <span className="medium-2 small-4 columns right right-aligned-text">
              <a
                type="button"
                className="inline-text-button settings-list-item-button"
                href={editLink}
              >
                <span
                  className="icon icon-edit icon-block x-sm margin-right-2"
                  aria-hidden="true"
                />
                <span className="button-text">Edit</span>
              </a>
            </span>
          )}
        </div>
      )}
      {isExpanded && (
        <form className="settings-list-item-form-wrap padding-bottom-10 clear-both">
          <fieldset>
            {errors.serverError && (
              <small className="error error-message">
                {errors.serverError}
              </small>
            )}
            <Form />
            <div className="clear-left">
              <div className="medium-5 columns">
                <button
                  type="button"
                  className={classNames('btn btn--primary', {
                    'btn--disabled': errors.preventSave,
                    'btn--processing': form.isSaving,
                  })}
                  disabled={errors.preventSave || form.isSaving}
                  onClick={saveSupplier}
                >
                  Save
                </button>
                <a
                  href="#/settings/supplier"
                  className="btn btn--secondary"
                  type="button"
                >
                  Cancel
                </a>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </li>
  )
}

EditForm.propTypes = {
  supplier: SupplierShape,
}
