import {createSelector} from 'reselect'
import isEmpty from 'lodash/isEmpty.js'

import {getState, setForm, updateForm, formsSelector} from '../../../store.js'
import api from '../../../common/api.js'
import {isPresent, isPositiveNumeric} from '../../../common/utils.js'
import {navigate} from '../../../common/location.js'
import {
  SETTINGS,
  WAREHOUSES,
  WAREHOUSE_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import {locationSelector} from '../../../redux/selectors/ui/location.js'
import {
  getWarehouses,
  setWarehouse,
  warehouseSelector,
  isFBAWarehouseSelector,
} from '../../../data/warehouses.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {autoCreatePitneyShipper} from '../../../data/shippers.js'
import {showWarehouseCartLocationNoticeModal} from '../Modals/WarehouseCartLocationNoticeModal.js'
import {setInitialStateOfBusiness} from '../../../data/company.js'

export const SETTINGS_WAREHOUSES = 'SETTINGS_WAREHOUSES'
const initialForm = {
  id: null,
  name: '',
  phone: '',
  email: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  is_default_location: false,
  isSaving: false,
  serverError: null,
}

export function setupWarehousesForm() {
  return {
    formName: SETTINGS_WAREHOUSES,
    initialForm,
  }
}

export function setupForEdit() {
  const warehouseID = settingsWarehouseIDSelector(getState())
  const warehouse = warehouseSelector(getState(), {warehouseID})

  if (!warehouse) {
    return
  }

  setForm(SETTINGS_WAREHOUSES, {
    ...initialForm,
    id: warehouse.id,
    name: warehouse.address.name || '',
    phone: warehouse.address.phone || '',
    email: warehouse.address.email || '',
    street1: warehouse.address.street1 || '',
    street2: warehouse.address.street2 || '',
    city: warehouse.address.city || '',
    state: warehouse.address.state || '',
    zip: warehouse.address.zip || '',
    country: warehouse.address.country || '',
    is_default_location: warehouse.is_default_location || false,
  })
}

export function setupForAdding() {
  setForm(SETTINGS_WAREHOUSES, {...initialForm, id: NEW_ID})
}

export function resetWarehousesForm() {
  setForm(SETTINGS_WAREHOUSES, initialForm)
}

export function updateWarehousesForm(...args) {
  updateForm(SETTINGS_WAREHOUSES, ...args)
}

export function warehousesFormSelector(state) {
  return (
    formsSelector(state)[SETTINGS_WAREHOUSES] || warehousesFormSelector.default
  )
}
warehousesFormSelector.default = {}

export function isFBAWarehouseFormSelector(state) {
  const {id} = warehousesFormSelector(state)

  return isFBAWarehouseSelector(state, {warehouseID: id})
}

export const errorsSelector = createSelector(
  warehousesFormSelector,
  isFBAWarehouseFormSelector,
  (
    {name, phone, email, street1, city, state, zip, country, serverError},
    isFBAWarehouse,
  ) => {
    const errors = {}

    if (
      serverError &&
      [
        'name',
        'phone',
        'email',
        'street1',
        'city',
        'state',
        'zip',
        'country',
      ].includes(serverError.param)
    ) {
      errors[serverError.param] =
        serverError.error_message || serverError.message
    }

    if (serverError && isEmpty(errors)) {
      errors.serverError = `${
        serverError.error_message || serverError.message
      }${serverError.param ? `: ${serverError.param}` : ''}`
    }

    if (!isPresent(name)) {
      errors.name = 'Name is required'
      errors.preventSave = true
    } else if (name.split(' ').filter((v) => v).length < 2) {
      errors.name = 'Name needs to be two words'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(phone)) {
      errors.phone = 'Phone is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(email)) {
      errors.email = 'Email is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(street1)) {
      errors.street1 = 'Street 1 is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(city)) {
      errors.city = 'City is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(state)) {
      errors.state = 'State is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(zip)) {
      errors.zip = 'Zipcode is required'
      errors.preventSave = true
    }

    if (!isFBAWarehouse && !isPresent(country)) {
      errors.country = 'Country is required'
      errors.preventSave = true
    }

    return errors
  },
)

export const settingsWarehouseIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === WAREHOUSES &&
      isPositiveNumeric(pathComponents[2])
    ) {
      return Number(pathComponents[2])
    }

    return null
  },
)

export const settingsWarehouseAddSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    return (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === WAREHOUSES &&
      pathComponents[2] === NEW_ID
    )
  },
)

export const warehousesHashSelector = createSelector(
  settingsWarehouseIDSelector,
  settingsWarehouseAddSelector,
  (warehouseID, isNew) => {
    if (isNew) {
      return `${WAREHOUSE_SETTINGS_LINK}/${NEW_ID}`
    }

    if (warehouseID) {
      return `${WAREHOUSE_SETTINGS_LINK}/${warehouseID}/edit`
    }

    return WAREHOUSE_SETTINGS_LINK
  },
)

export async function saveWarehouse() {
  const {
    id,
    name,
    phone,
    email,
    street1,
    street2,
    city,
    state,
    zip,
    country,
    is_default_location,
  } = warehousesFormSelector(getState())

  const params = {
    name,
    phone,
    email,
    street1,
    street2,
    city,
    state,
    zip,
    country,
  }
  const isNew = id === NEW_ID

  updateWarehousesForm({
    serverError: null,
    isSaving: true,
  })

  try {
    const {json: warehouse} = isNew
      ? await api.post('/warehouse/', params)
      : await api.put(`/warehouse/${id}/`, params)

    if (is_default_location && !warehouse.is_default_location) {
      await api.post(`/warehouse/${warehouse.id}/make_default/`)
      await getWarehouses()
    } else {
      setWarehouse(warehouse)
    }

    await autoCreatePitneyShipper()

    showMessageToast(`${isNew ? 'Added' : 'Updated'} warehouse successfully.`)

    if (isNew) {
      // have to timeout call because `navigate()` will close open modals
      setTimeout(() => showWarehouseCartLocationNoticeModal(), 500)
    }

    if (is_default_location) {
      await setInitialStateOfBusiness()
    }

    return navigate([SETTINGS, WAREHOUSES])
  } catch (err) {
    updateWarehousesForm({
      serverError: err,
    })
  }

  updateWarehousesForm({
    isSaving: false,
  })
}
