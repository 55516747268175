import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {formConnectAuto} from '../../../../common/formConnect.js'
import {
  PrintConfigShape,
  PackingListShape,
} from '../../../../common/PropTypes.js'
import {isNumeric} from '../../../../common/utils.js'
import {
  setShowOrderTray,
  setShowPackingList,
  setPackingListSort,
  setShowPickList,
  setPickListSort,
  setTemplateID,
  setShowPrice,
  setShowBarcode,
  setShowLogoOnLabel,
  setLabelPosition,
  setShowFullPage,
  setBreakPageOnOrder,
  setShowPackingListImages,
  setShowPickListImages,
  setShowPickListTotalItems,
  setShowPickListTotalSKUs,
  setShowPickListTotalOrders,
  setHighlightPickLineQty,
  setPackingListKitViewMode,
  setShowSiblingOrderQuantities,
  setShowZeroQuantityLines,
  setHighlightPackLineQty,
  setShowPickListPOH,
  setShowShippingInfo,
  setShowWarehouseLocation,
  setShowBillTo,
  setShowPackSerialNumbers,
  setShowPickSerialNumbers,
  setShowCustomerNotes,
  setPickListKitViewMode,
  setUseCustomLayoutSettings,
  setPrintConfigID,
  setSize,
  setShowPackingListDetails,
  setShowPackSKUBarcode,
  setShowPickSKUBarcode,
} from '../../../../redux/actions/ui/labelViewForm/abode.js'
import {canUseLogosOnLabelsSelector} from '../../../../data/company.js'

import {sortedPackingListsByIDSelector} from '../../../../redux/selectors/data/packingLists.js'
import {printConfigsSortedByNameSelector} from '../../../../redux/selectors/data/printConfigs.js'
import {allowedParamsSelector} from '../../../../redux/selectors/ui/labelViewForm.js'

import {
  KIT_VIEW_PARENTS,
  KIT_VIEW_BOTH,
  KIT_VIEW_COMPONENTS,
  STANDARD_LAYOUT,
  ABODE_FORM_DEFAULTS,
} from '../../../../common/constants/Abode.js'
import Checkbox from '../../../../common/components/Form/Checkbox.js'
import Radio from '../../../../common/components/Form/Radio.js'
import Select from '../../../../common/components/Form/Select.js'

import PageMargins from './Fields/PageMargins.js'
import LabelDimensions from './Fields/LabelDimensions.js'

function AbodeForm({
  formName,
  fromModal,
  allowedParams,
  form,
  canUseLogosOnLabels,
  packingLists,
  printConfigs,
  ...props
}) {
  return (
    <dl className="list margin-bottom-15">
      <dt className="list__title--print-options">Document Options</dt>
      {printConfigs.length > 0 && (
        <Select
          label="Layout"
          name={`${formName}__layout`}
          value={form.printConfigID}
          onChange={(printConfigID) =>
            props.setPrintConfigID(
              formName,
              isNumeric(printConfigID) ? Number(printConfigID) : printConfigID,
            )
          }
          className="margin-bottom-20"
        >
          <option value={STANDARD_LAYOUT}>Standard</option>
          {printConfigs.map((printConfig) => (
            <option key={printConfig.id} value={printConfig.id}>
              {printConfig.name}
            </option>
          ))}
        </Select>
      )}
      {allowedParams.showLogoOnLabel && (
        <div className="margin-bottom-15">
          <Checkbox
            label="Show Logo on Label"
            name={`${formName}__show_logo_on_label`}
            checked={form.showLogoOnLabel}
            onChange={(checked) => props.setShowLogoOnLabel(formName, checked)}
          />
        </div>
      )}
      {!canUseLogosOnLabels && !allowedParams.showLogoOnLabel && (
        <div className="flex margin-bottom-15">
          <Checkbox
            label="Show Logo on Label"
            name={`${formName}__show_logo_on_label`}
            checked={false}
            onChange={() => null}
            disabled
          />
          <a
            className="btn btn--link no-underline tooltip--top"
            data-hint="Available in Express and above"
            href="https://support.ordoro.com/how-do-i-upload-my-logo-used-on-the-shipping-label-feature/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="icon icon-support icon-block fs-n1 margin-left-5"
              aria-hidden="true"
            />
          </a>
        </div>
      )}

      {allowedParams.showOrderTray && (
        <div className="margin-bottom-15">
          <Checkbox
            label="Display Order Tray Numbers"
            name={`${formName}__show_order_tray`}
            checked={form.showOrderTray}
            onChange={(checked) => props.setShowOrderTray(formName, checked)}
          />
        </div>
      )}

      {allowedParams.showPackingList && (
        <Checkbox
          label="Attach Packing List"
          name={`${formName}__doc_packing`}
          checked={form.showPackingList}
          onChange={(checked) => props.setShowPackingList(formName, checked)}
        >
          {allowedParams.packingListSort && (
            <Select
              label="Sort By"
              name={`${formName}__packing_list_sort_by`}
              value={form.packingListSort}
              onChange={(value) => props.setPackingListSort(formName, value)}
              className="margin-bottom-10"
            >
              <option value="">Original order lines</option>
              <option value="sku">SKU</option>
              <option value="description">Description</option>
              <option value="location">Location (A→Z)</option>
              <option value="location-reverse">Location (Z→A)</option>
              <option value="quantity-reverse">Quantity (High to Low)</option>
              <option value="quantity">Quantity (Low to High)</option>
            </Select>
          )}
          {allowedParams.showPrice && (
            <Checkbox
              label="Show Price"
              name={`${formName}__show_price`}
              checked={form.showPrice}
              onChange={(checked) => props.setShowPrice(formName, checked)}
            />
          )}
          {allowedParams.showBarcode && (
            <Checkbox
              label="Show Barcode"
              name={`${formName}__show_barcode`}
              checked={form.showBarcode}
              onChange={(checked) => props.setShowBarcode(formName, checked)}
            />
          )}
          {allowedParams.showPackingListImages && (
            <Checkbox
              label="Show Images"
              name={`${formName}__show_packing_images`}
              checked={form.showPackingListImages}
              onChange={(checked) =>
                props.setShowPackingListImages(formName, checked)
              }
            />
          )}
          {allowedParams.packingListKitViewMode && (
            <Checkbox
              label="Show Kit Components"
              name={`${formName}__show_packing_kit_components`}
              checked={form.packingListKitViewMode !== KIT_VIEW_PARENTS}
              onChange={(checked) => {
                props.setPackingListKitViewMode(
                  formName,
                  checked ? KIT_VIEW_BOTH : KIT_VIEW_PARENTS,
                )
              }}
            >
              <Select
                name={`${formName}__packing_list_kit_view_mode`}
                value={form.packingListKitViewMode}
                onChange={(value) =>
                  props.setPackingListKitViewMode(formName, value)
                }
                className="margin-bottom-10"
              >
                <option value={KIT_VIEW_BOTH}>Parents & Components</option>
                <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
              </Select>
            </Checkbox>
          )}
          {allowedParams.showSiblingOrderQuantities && (
            <Checkbox
              label="Show Quantities from Split Orders"
              name={`${formName}__show_sibling_order_quantities`}
              checked={form.showSiblingOrderQuantities}
              onChange={(checked) =>
                props.setShowSiblingOrderQuantities(formName, checked)
              }
            />
          )}
          {allowedParams.showZeroQuantityLines && (
            <Checkbox
              label="Show Lines with Zero Quantity"
              name={`${formName}__show_zero_quantity_lines`}
              checked={form.showZeroQuantityLines}
              onChange={(checked) =>
                props.setShowZeroQuantityLines(formName, checked)
              }
            />
          )}
          {allowedParams.highlight_pack_line_qty && (
            <Checkbox
              label="Highlight Line Item Quantities Greater Than One"
              name={`${formName}__highlight_pack_line_qty`}
              checked={form.highlight_pack_line_qty}
              onChange={(checked) =>
                props.setHighlightPackLineQty(formName, checked)
              }
            />
          )}
          {allowedParams.showShippingInfo && (
            <Checkbox
              label="Show Shipping Info"
              name={`${formName}__show_shipping_info`}
              checked={form.showShippingInfo}
              onChange={(checked) =>
                props.setShowShippingInfo(formName, checked)
              }
            />
          )}
          {allowedParams.showWarehouseLocation && (
            <Checkbox
              label="Show Warehouse Location"
              name={`${formName}__show_warehouse_location`}
              checked={form.showWarehouseLocation}
              onChange={(checked) =>
                props.setShowWarehouseLocation(formName, checked)
              }
            />
          )}
          {allowedParams.showBillTo && (
            <Checkbox
              label="Show Bill-To"
              name={`${formName}__show_bill_to`}
              checked={form.showBillTo}
              onChange={(checked) => props.setShowBillTo(formName, checked)}
            />
          )}
          {allowedParams.showPackSerialNumbers && (
            <Checkbox
              label="Show Serial Numbers"
              name={`${formName}__show_pack_serial_numbers`}
              checked={form.showPackSerialNumbers}
              onChange={(checked) =>
                props.setShowPackSerialNumbers(formName, checked)
              }
            />
          )}
          {allowedParams.showCustomerNotes && (
            <Checkbox
              label="Show Customer Notes"
              name={`${formName}__show_customer_notes`}
              checked={form.showCustomerNotes}
              onChange={(checked) =>
                props.setShowCustomerNotes(formName, checked)
              }
            />
          )}
          {allowedParams.show_packing_list_details && (
            <Checkbox
              label="Show Order Line Additional Details"
              name={`${formName}__show_packing_list_details`}
              checked={form.show_packing_list_details}
              onChange={(checked) =>
                props.setShowPackingListDetails(formName, checked)
              }
            />
          )}
          {allowedParams.show_pack_sku_barcode && (
            <Checkbox
              label="Show Barcode of SKU"
              name={`${formName}__show_pack_sku_barcode`}
              checked={form.show_pack_sku_barcode}
              onChange={(checked) =>
                props.setShowPackSKUBarcode(formName, checked)
              }
            />
          )}
        </Checkbox>
      )}
      {allowedParams.showPickList && (
        <Checkbox
          label="Attach Pick List"
          name={`${formName}__doc_pick`}
          checked={form.showPickList}
          onChange={() => props.setShowPickList(formName, !form.showPickList)}
        >
          {allowedParams.pickListSort && (
            <Select
              label="Sort By"
              name={`${formName}__pick_list_sort_by`}
              value={form.pickListSort}
              onChange={(value) => props.setPickListSort(formName, value)}
              className="margin-bottom-10"
            >
              <option value="sku">SKU</option>
              <option value="description">Description</option>
              <option value="location">Location</option>
              <option value="quantity-reverse">Quantity (High to Low)</option>
              <option value="quantity">Quantity (Low to High)</option>
            </Select>
          )}
          {allowedParams.showPickListImages && (
            <Checkbox
              label="Show Images"
              name={`${formName}__show_pick_images`}
              checked={form.showPickListImages}
              onChange={(checked) =>
                props.setShowPickListImages(formName, checked)
              }
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total Items Count"
              name={`${formName}__show_pick_list_total_items`}
              checked={form.showPickListTotalItems}
              onChange={(checked) =>
                props.setShowPickListTotalItems(formName, checked)
              }
            />
          )}
          {allowedParams.highlight_pick_line_qty && (
            <Checkbox
              label="Highlight Line Item Quantities Greater Than One"
              name={`${formName}__highlight_pick_line_qty`}
              checked={form.highlight_pick_line_qty}
              onChange={(checked) =>
                props.setHighlightPickLineQty(formName, checked)
              }
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total SKU Count"
              name={`${formName}__show_pick_list_total_skus`}
              checked={form.showPickListTotalSKUs}
              onChange={(checked) =>
                props.setShowPickListTotalSKUs(formName, checked)
              }
            />
          )}
          {allowedParams.showPickListTotalItems && (
            <Checkbox
              label="Show Total Order Count"
              name={`${formName}__show_pick_list_total_orders`}
              checked={form.showPickListTotalOrders}
              onChange={(checked) =>
                props.setShowPickListTotalOrders(formName, checked)
              }
            />
          )}
          {allowedParams.showPickSerialNumbers && (
            <Checkbox
              label="Show Serial Numbers"
              name={`${formName}__show_pick_serial_numbers`}
              checked={form.showPickSerialNumbers}
              onChange={(checked) =>
                props.setShowPickSerialNumbers(formName, checked)
              }
            />
          )}
          {allowedParams.show_pick_list_poh && (
            <Checkbox
              label="Show Physical Units On Hand (POH)"
              name={`${formName}__show_pick_list_poh`}
              checked={form.show_pick_list_poh}
              onChange={(checked) =>
                props.setShowPickListPOH(formName, checked)
              }
            />
          )}
          {allowedParams.show_pick_sku_barcode && (
            <Checkbox
              label="Show Barcode of SKU"
              name={`${formName}__show_pick_sku_barcode`}
              checked={form.show_pick_sku_barcode}
              onChange={(checked) =>
                props.setShowPickSKUBarcode(formName, checked)
              }
            />
          )}
          {allowedParams.pickListKitViewMode && (
            <Checkbox
              label="Show Kit Components"
              name={`${formName}__show_pick_kit_components`}
              checked={form.pickListKitViewMode !== KIT_VIEW_PARENTS}
              onChange={(checked) => {
                props.setPickListKitViewMode(
                  formName,
                  checked ? KIT_VIEW_BOTH : KIT_VIEW_PARENTS,
                )
              }}
            >
              <Select
                name={`${formName}__pick_list_kit_view_mode`}
                value={form.pickListKitViewMode}
                onChange={(value) =>
                  props.setPickListKitViewMode(formName, value)
                }
                className="margin-bottom-10"
              >
                <option value={KIT_VIEW_BOTH}>Parents & Components</option>
                <option value={KIT_VIEW_COMPONENTS}>Components Only</option>
              </Select>
            </Checkbox>
          )}
        </Checkbox>
      )}
      {allowedParams.templateID &&
        packingLists.length > 1 &&
        (form.showPackingList || form.showLogoOnLabel) && (
          <Select
            label="Profile"
            name={`${formName}__template`}
            value={form.templateID}
            onChange={(newTemplateID) =>
              props.setTemplateID(formName, Number(newTemplateID))
            }
            className="margin-bottom-10"
          >
            <option value="0">Use preconfigured profile</option>
            <option disabled>---------------------------</option>
            {packingLists.map((packingList) => (
              <option value={packingList.id} key={packingList.id}>
                {!packingList.website
                  ? packingList.name
                  : `${packingList.name} - ${packingList.website}`}
              </option>
            ))}
          </Select>
        )}
      {allowedParams.size && (
        <>
          <dt className="list__title--print-options">Printer Options</dt>
          <Radio
            label={'Thermal Printer'}
            value="thermal"
            name={`${formName}_${fromModal ? '_modal_' : ''}_thermal_size`}
            checked={['thermal', 'thermal_8_5', 'thermal_10_5'].includes(
              form.size,
            )}
            onChange={() => props.setSize(formName, 'thermal')}
          >
            <Select
              label="Size"
              name={`${formName}_${
                fromModal ? '_modal_' : ''
              }_thermal_size_select`}
              value={form.size}
              onChange={(value) => props.setSize(formName, value)}
              className="margin-bottom-10"
            >
              <option value="thermal">4&quot; x 6&quot;</option>
              <option value="thermal_8_5">4&quot; x 8.5&quot;</option>
              <option value="thermal_10_5">4&quot; x 10.5&quot;</option>
            </Select>
          </Radio>
          <Radio
            label={'Desktop Printer (8.5" x 11")'}
            value="desktop"
            name={`${formName}_${fromModal ? '_modal_' : ''}_desktop_size`}
            checked={form.size === 'desktop'}
            onChange={(value) => props.setSize(formName, value)}
          >
            {allowedParams.showFullPage && (
              <>
                <Radio
                  name={`${formName}_${
                    fromModal ? '_modal_' : ''
                  }_show_one_per_page`}
                  label="Full page"
                  onChange={() => props.setShowFullPage(formName, true)}
                  checked={form.showFullPage}
                />
                <Radio
                  name={`${formName}_${
                    fromModal ? '_modal_' : ''
                  }_show_two_per_page`}
                  label="Side-by-side"
                  onChange={() => props.setShowFullPage(formName, false)}
                  checked={!form.showFullPage}
                >
                  {allowedParams.labelPosition && (
                    <Select
                      label="Placement of Label on Page"
                      name={`${formName}__label_position`}
                      value={form.labelPosition}
                      onChange={(value) =>
                        props.setLabelPosition(formName, value)
                      }
                      className="margin-bottom-10"
                    >
                      <option value="">Either side (flows with content)</option>
                      <option value="left">Left side always</option>
                      <option value="right">Right side always</option>
                    </Select>
                  )}
                  {allowedParams.breakPageOnOrder && (
                    <Checkbox
                      label="Break Page on Order"
                      name={`${formName}__break_page_on_order`}
                      checked={form.breakPageOnOrder}
                      onChange={(checked) =>
                        props.setBreakPageOnOrder(formName, checked)
                      }
                    />
                  )}
                </Radio>
              </>
            )}
          </Radio>
        </>
      )}
      {allowedParams.useCustomLayoutSettings && (
        <Checkbox
          label="Custom Settings"
          name={`${formName}__custom_settings`}
          checked={form.useCustomLayoutSettings}
          onChange={(checked) =>
            props.setUseCustomLayoutSettings(formName, checked)
          }
        >
          <ul className="list">
            <li className="list__item--shipping-options flex flex-wrap flex-wrap-margin-10">
              <PageMargins label="Label Margins" />
              <LabelDimensions />
            </li>
          </ul>
        </Checkbox>
      )}
    </dl>
  )
}

AbodeForm.propTypes = {
  formName: PropTypes.string.isRequired,
  docTypes: PropTypes.arrayOf(PropTypes.string),
  includeSplitQuantitiesCheckbox: PropTypes.bool,
  fromModal: PropTypes.bool,
  allowPrintConfigs: PropTypes.bool,

  allowedParams: PropTypes.object.isRequired,
  form: PropTypes.shape({
    showOrderTray: PropTypes.bool.isRequired,
    printConfigID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showLogoOnLabel: PropTypes.bool.isRequired,
    labelPosition: PropTypes.string.isRequired,
    showPackingList: PropTypes.bool.isRequired,
    packingListSort: PropTypes.string.isRequired,
    showPrice: PropTypes.bool.isRequired,
    showBarcode: PropTypes.bool.isRequired,
    showPackingListImages: PropTypes.bool.isRequired,
    packingListKitViewMode: PropTypes.string.isRequired,
    showPickList: PropTypes.bool.isRequired,
    pickListSort: PropTypes.string.isRequired,
    showPickListImages: PropTypes.bool.isRequired,
    showPickListTotalItems: PropTypes.bool.isRequired,
    showPickListTotalSKUs: PropTypes.bool.isRequired,
    showPickListTotalOrders: PropTypes.bool.isRequired,
    highlight_pick_line_qty: PropTypes.bool.isRequired,
    pickListKitViewMode: PropTypes.string.isRequired,
    showSiblingOrderQuantities: PropTypes.bool.isRequired,
    showZeroQuantityLines: PropTypes.bool.isRequired,
    highlight_pack_line_qty: PropTypes.bool.isRequired,
    show_pick_list_poh: PropTypes.bool.isRequired,
    showShippingInfo: PropTypes.bool.isRequired,
    showWarehouseLocation: PropTypes.bool.isRequired,
    showBillTo: PropTypes.bool.isRequired,
    showPackSerialNumbers: PropTypes.bool.isRequired,
    showPickSerialNumbers: PropTypes.bool.isRequired,
    showCustomerNotes: PropTypes.bool.isRequired,
    templateID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showFullPage: PropTypes.bool.isRequired,
    breakPageOnOrder: PropTypes.bool.isRequired,
    useCustomLayoutSettings: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    show_packing_list_details: PropTypes.bool.isRequired,
    show_pack_sku_barcode: PropTypes.bool.isRequired,
    show_pick_sku_barcode: PropTypes.bool.isRequired,
  }),
  canUseLogosOnLabels: PropTypes.bool.isRequired,
  printConfigs: PropTypes.arrayOf(PrintConfigShape).isRequired,
  packingLists: PropTypes.arrayOf(PackingListShape),

  setShowOrderTray: PropTypes.func.isRequired,
  setPrintConfigID: PropTypes.func.isRequired,
  setShowLogoOnLabel: PropTypes.func.isRequired,
  setLabelPosition: PropTypes.func.isRequired,
  setShowPackingList: PropTypes.func.isRequired,
  setPackingListSort: PropTypes.func.isRequired,
  setShowPrice: PropTypes.func.isRequired,
  setShowBarcode: PropTypes.func.isRequired,
  setShowPackingListImages: PropTypes.func.isRequired,
  setPackingListKitViewMode: PropTypes.func.isRequired,
  setShowPickList: PropTypes.func.isRequired,
  setPickListSort: PropTypes.func.isRequired,
  setShowPickListImages: PropTypes.func.isRequired,
  setShowPickListTotalItems: PropTypes.func.isRequired,
  setShowPickListTotalSKUs: PropTypes.func.isRequired,
  setShowPickListTotalOrders: PropTypes.func.isRequired,
  setHighlightPickLineQty: PropTypes.func.isRequired,
  setPickListKitViewMode: PropTypes.func.isRequired,
  setShowSiblingOrderQuantities: PropTypes.func.isRequired,
  setShowZeroQuantityLines: PropTypes.func.isRequired,
  setHighlightPackLineQty: PropTypes.func.isRequired,
  setShowPickListPOH: PropTypes.func.isRequired,
  setShowShippingInfo: PropTypes.func.isRequired,
  setShowWarehouseLocation: PropTypes.func.isRequired,
  setShowBillTo: PropTypes.func.isRequired,
  setShowPackSerialNumbers: PropTypes.func.isRequired,
  setShowPickSerialNumbers: PropTypes.func.isRequired,
  setShowCustomerNotes: PropTypes.func.isRequired,
  setTemplateID: PropTypes.func.isRequired,
  setSize: PropTypes.func.isRequired,
  setShowFullPage: PropTypes.func.isRequired,
  setBreakPageOnOrder: PropTypes.func.isRequired,
  setUseCustomLayoutSettings: PropTypes.func.isRequired,
  setShowPackingListDetails: PropTypes.func.isRequired,
  setShowPackSKUBarcode: PropTypes.func.isRequired,
  setShowPickSKUBarcode: PropTypes.func.isRequired,
}

function mapStateToProps(
  state,
  {formName, docTypes, includeSplitQuantitiesCheckbox, allowPrintConfigs},
) {
  docTypes = docTypes || ['label', 'pick', 'pack']

  return {
    allowedParams: allowedParamsSelector(state, {
      formName,
      docTypes,
      includeSplitQuantitiesCheckbox,
    }),
    canUseLogosOnLabels: canUseLogosOnLabelsSelector(state),
    packingLists: sortedPackingListsByIDSelector(state),
    printConfigs: allowPrintConfigs
      ? printConfigsSortedByNameSelector(state)
      : [],
  }
}

const mapDispatchToProps = {
  setShowOrderTray,
  setShowLogoOnLabel,
  setLabelPosition,
  setShowPackingList,
  setPackingListSort,
  setShowPrice,
  setShowBarcode,
  setShowPackingListImages,
  setPackingListKitViewMode,
  setShowSiblingOrderQuantities,
  setShowZeroQuantityLines,
  setHighlightPackLineQty,
  setShowPickListPOH,
  setShowShippingInfo,
  setShowWarehouseLocation,
  setShowBillTo,
  setShowPackSerialNumbers,
  setShowCustomerNotes,
  setShowPickList,
  setPickListSort,
  setShowPickListImages,
  setShowPickListTotalItems,
  setShowPickListTotalSKUs,
  setShowPickListTotalOrders,
  setHighlightPickLineQty,
  setShowPickSerialNumbers,
  setPickListKitViewMode,
  setShowFullPage,
  setBreakPageOnOrder,
  setTemplateID,
  setSize,
  setUseCustomLayoutSettings,
  setPrintConfigID,
  setShowPackingListDetails,
  setShowPackSKUBarcode,
  setShowPickSKUBarcode,
}

export default formConnectAuto(
  connect(mapStateToProps, mapDispatchToProps)(AbodeForm),
  (state, {formName}) => {
    formName = formName || ''

    return {
      formName,
      initialForm: {...ABODE_FORM_DEFAULTS},
    }
  },
)
