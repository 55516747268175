import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import Weight from '../../../common/components/Weight.js'
import {weightSelector} from '../../../data/products.js'
import {lbToOz} from '../../../common/weight.js'
import {showProductWeightModal} from '../Modals/ProductWeightModal.js'

export function WeightCell({sku, weight, ...props}) {
  return (
    <td className="table__td table__td--product-list-weight">
      <span className="margin-right-5">
        <ButtonLink
          className="darkest"
          onClick={() => props.showProductWeightModal([sku], weight)}
        >
          <Weight value={lbToOz(weight)} />
        </ButtonLink>
      </span>
    </td>
  )
}

WeightCell.propTypes = {
  sku: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  showProductWeightModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {sku}) {
  return {
    weight: weightSelector(state, {sku}),
  }
}

const mapDispatchToProps = {
  showProductWeightModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(WeightCell)
