import {all, takeEvery, put, select, call} from 'redux-saga/effects'

import {getDate} from '../../../../common/date.js'
import {openModal, closeModal} from '../../../actions/ui/index.js'
import {showMessageToast} from '../../../../ordoro/Header/Toast/index.js'
import requests from '../../../../common/requests.js'
import {
  MODAL_NAME,
  SHOW_MODAL,
  SAVE_ENDICIA_CREDIT_CARD,
  setIsSaving,
  setServerError,
  setForm,
  setShipperID,
} from '../../../actions/ui/modals/endiciaCreditCardModal.js'
import {createParamsSelector} from '../../../selectors/ui/modals/endiciaCreditCardModal.js'

export function* showModalWorker({payload: shipperID}) {
  yield put(setIsSaving(false))

  yield put(setServerError(null))

  const date = yield call(getDate)

  yield put(
    setForm({
      cardNumber: '',
      cardCVV: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
      expirationMonth: date.format('MMMM'),
      expirationYear: date.format('YYYY'),
    }),
  )

  yield put(setShipperID(shipperID))

  yield put(openModal(MODAL_NAME))
}

export function* saveEndiciaCreditCardWorker() {
  const params = yield select(createParamsSelector)

  yield put(setIsSaving(true))

  yield put(setServerError(null))

  try {
    yield call(requests.post, '/verde/endicia/credit_card', params)

    yield put(closeModal())

    yield call(showMessageToast, 'USPS credit card updated')
  } catch (err) {
    yield put(setServerError(err))
  }

  yield put(setIsSaving(false))
}

export default function* endiciaCreditCardModal() {
  yield all([
    takeEvery(SHOW_MODAL, showModalWorker),
    takeEvery(SAVE_ENDICIA_CREDIT_CARD, saveEndiciaCreditCardWorker),
  ])
}
