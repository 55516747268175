import {useEffect} from 'react'
import {CardElement, Elements, useElements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import {STRIPE_CONNECT_PUBLIC_KEY} from '../../constants/index.js'

import {
  isPaymentsSetupSelector,
  paymentIntegrationIDSelector,
  stripeUserIDSelector,
} from '../../../data/integrations.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from './ConfirmModal.js'
import {
  ensureOrdersTagged,
  ordersSelector,
  updateOrders,
} from '../../../data/orders.js'
import {formatCurrency} from '../Currency.js'
import {showMessageToast} from '../../../ordoro/Header/Toast/index.js'
import api from '../../api.js'
import {
  parseAmount,
  stripeCurrenciesOptions,
} from '../../constants/StripeCurrencies.js'
import {isNonZeroPositiveNumeric, isPresent} from '../../utils.js'
import {navigate} from '../../location.js'
import {PAYMENTS, SETTINGS} from '../../constants/SettingsPanels.js'

const MODAL_FORM = 'CREATE_CHARGE_MODAL'

export function showCreateChargeModal(orderNumbers) {
  const orders = ordersSelector(getState(), {orderNumbers})

  const values = orders.reduce(
    (prev, order) => {
      if (!prev.name && order.billing_address.name) {
        prev.name = order.billing_address.name
      }

      if (!prev.zipcode && order.billing_address.zip) {
        prev.zipcode = order.billing_address.zip
      }

      prev.amount += order.financial.grand_total || 0

      return prev
    },
    {
      name: '',
      amount: 0,
      zipcode: '',
    },
  )

  setForm(MODAL_FORM, {
    name: values.name,
    amount: formatCurrency(values.amount, ''),
    zipcode: values.zipcode,
    description: `Charge applied for order number${
      orderNumbers.length > 1 ? 's' : ''
    }: ${orderNumbers.join(', ')}`,
    orderNumbers,
    isCardComplete: false,
    currency: 'usd',
    stripePromise: null,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {name, isCardComplete, amount} = modalFormSelector(state)
  const errors = {}

  if (!isPresent(name)) {
    errors.name = 'Name is required'
  }

  if (!isCardComplete) {
    errors.card = 'Card is required'
  }

  if (!isNonZeroPositiveNumeric(amount)) {
    errors.amount = 'Amount must be a positive number'
  }

  if (!isPresent(amount)) {
    errors.amount = 'Amount is required'
  }

  if (Object.keys(errors).length) {
    errors.preventSave = true
  }

  return errors
}

export async function createCharge() {
  try {
    const {
      name,
      orderNumbers,
      amount,
      currency,
      description,
      stripePromise,
      elements,
    } = modalFormSelector(getState())
    const integrationID = paymentIntegrationIDSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const stripe = await stripePromise

    const cardElement = elements.getElement(CardElement)
    const response = await stripe.createToken(cardElement, {name, currency})

    if (response.error) {
      throw response.error
    }

    if (!response.token) {
      throw new Error('Unable to process payment')
    }

    const params = {
      integration_id: integrationID,
      order_numbers: orderNumbers,
      amount: parseAmount(amount, currency),
      currency,
      source: response.token.id,
      description,
    }

    await api.post('/v3/order/charge', params)

    await ensureOrdersTagged({
      label: 'Payment Processed',
      color: '#ffffff',
      orderNumbers,
    })

    await updateOrders(orderNumbers)

    showMessageToast('Payment successfully submitted!')

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function ChargeForm({form}) {
  const elements = useElements()
  const errors = useSelector(errorsSelector)

  useEffect(() => {
    updateModalForm({elements})
  }, [elements])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()

        createCharge()
      }}
    >
      <ul className="list list--no-style margin-bottom-0">
        <li className="list__item margin-bottom-15 row">
          <div className="medium-9 columns">
            <label htmlFor="id_name">
              Name<span className="required">*</span>
            </label>
            <input
              className="margin-bottom-0"
              type="text"
              name="name"
              id="id_name"
              value={form.name}
              onChange={(event) =>
                updateModalForm({
                  name: event.target.value,
                  hasChanged_name: true,
                })
              }
            />
            {form.hasChanged_name && errors.name && (
              <small className="error error-message">{errors.name}</small>
            )}
          </div>
        </li>
        <li className="list__item margin-bottom-15 row">
          <div className="medium-5 columns">
            <label htmlFor="id_amount">
              Payment Amount<span className="required">*</span>
            </label>
            <input
              className="margin-bottom-0"
              type="text"
              name="amount"
              id="id_amount"
              value={form.amount}
              onChange={(event) =>
                updateModalForm({
                  amount: event.target.value,
                  hasChanged_amount: true,
                })
              }
            />
            {form.hasChanged_amount && errors.amount && (
              <small className="error error-message">{errors.amount}</small>
            )}
          </div>
          <div className="medium-4 columns end">
            <label htmlFor="id_currency">Currency</label>
            <select
              className="select margin-bottom-0"
              name="currency"
              id="id_currency"
              value={form.currency}
              onChange={(event) =>
                updateModalForm(
                  {currency: event.target.value},
                  {stickyProps: ['currency']},
                )
              }
            >
              {stripeCurrenciesOptions.map(({value, display}) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
            </select>
          </div>
        </li>
        <li className="list__item margin-bottom-15 row">
          <div className="medium-9 columns wrap--stripe-element">
            <label htmlFor="cart_element">
              Credit Card Info<span className="required">*</span>
            </label>
            <CardElement
              onChange={(event) =>
                updateModalForm({
                  zipcode: event.value.postalCode,
                  isCardComplete: event.complete,
                })
              }
              value={{postalCode: form.zipcode}}
            />
          </div>
        </li>

        <li className="list__item margin-bottom-0 row">
          <div className="medium-9 columns">
            <label htmlFor="id_description">Description of Payment</label>
            <textarea
              className="textarea lh-md margin-bottom-0"
              name="description"
              id="id_description"
              value={form.description}
              onChange={(event) =>
                updateModalForm({description: event.target.value})
              }
            />
          </div>
        </li>
      </ul>
    </form>
  )
}

function CreateChargeModal({form}) {
  const stripeUserID = useSelector(stripeUserIDSelector)
  const isPaymentsSetup = useSelector(isPaymentsSetupSelector)
  const errors = useSelector(errorsSelector)

  useEffect(() => {
    updateModalForm({
      stripePromise: loadStripe(STRIPE_CONNECT_PUBLIC_KEY, {
        stripeAccount: stripeUserID,
      }),
    })
  }, [stripeUserID])

  if (!isPaymentsSetup) {
    return (
      <ConfirmModal
        title="Setup Required"
        modalSize="sm"
        onConfirm={() => {
          closeModal()

          navigate([SETTINGS, PAYMENTS])
        }}
        onCancel={() => closeModal()}
        confirmText="Take me there"
        cancelText="Close"
        isSaving={form.isSaving}
        isDisabled={errors.preventSave}
        error={form.serverError}
      >
        You need to connect a Stripe account before you can process payments.
      </ConfirmModal>
    )
  }

  return (
    <ConfirmModal
      title="Process Payment"
      modalSize="sm"
      onConfirm={() => createCharge()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <Elements stripe={form.stripePromise}>
        <ChargeForm form={form} />
      </Elements>
    </ConfirmModal>
  )
}

export default onlyIfForm(CreateChargeModal, modalFormSelector)
