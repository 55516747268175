import PropTypes from 'prop-types'

import {
  getState,
  updateForm,
  onlyIfAutoForm,
  formSelector,
  useSelector,
} from '../../../../store.js'
import {isPresent} from '../../../../common/utils.js'
import {NEW_ID} from '../../../../common/constants/SettingsPanels.js'
import {SHOPSITE} from '../../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../../data/carts.js'
import TextInput from '../../Common/TextInput.js'
import FormList from '../../Common/FormList.js'
import FormListItem from '../../Common/FormListItem.js'
import VendorForm from './VendorForm.js'
import NameInput from '../NameInput.js'
import PackingListSelect from '../PackingListSelect.js'
import AuthorizeHelper from '../AuthorizeHelper.js'
import LockWriteBack from '../LockWriteBack.js'
import {
  cartFormErrorsSelector,
  baseNewCartParamsSelector,
  baseEditCartParamsSelector,
  baseCartErrorsSelector,
  baseSaveParamsSelector,
} from '../salesChannelsSelectors.js'
import RevisionConfig from '../RevisionConfig.js'
import SyncConfig from '../SyncConfig.js'

export function setUpCartForm({cartID, formName}) {
  return {
    formName,
    initialForm: {
      ...initialFormSelector(getState(), {cartID}),
      errorsSelector,
      saveParamsSelector,
      serverParamErrorsSelector,
    },
  }
}

export function errorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = baseCartErrorsSelector(state, {formName})

  if (!form) {
    return errors
  }

  if (!isPresent(form.url)) {
    errors.url = 'URL is required'
    errors.preventSave = true
  }

  if (!isPresent(form.username)) {
    errors.username = 'Username is required'
    errors.preventSave = true
  }

  if (!isPresent(form.password)) {
    errors.password = 'Password is required'
    errors.preventSave = true
  }

  return errors
}

export function initialFormSelector(state, {cartID}) {
  if (cartID === NEW_ID) {
    return {
      ...baseNewCartParamsSelector(state, {vendor: SHOPSITE}),
      url: '',
      username: '',
      password: '',
    }
  }

  const cart = cartSelector(getState(), {cartID})

  return {
    ...baseEditCartParamsSelector(getState(), {cartID}),
    url: cart.vendor_config.url,
    username: cart.vendor_config.username,
    password: cart.vendor_config.password,
  }
}

export function serverParamErrorsSelector(state, {formName}) {
  const {serverError} = formSelector(state, {formName})
  const errors = {}

  if (serverError.param === 'url') {
    errors.url = serverError.error_message
  } else if (serverError.param === 'username') {
    errors.username = serverError.error_message
  } else if (serverError.param === 'password') {
    errors.password = serverError.error_message
  }

  return errors
}

export function saveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  return {
    ...baseSaveParamsSelector(state, {formName}),
    url: form.url,
    username: form.username,
    password: form.password,
  }
}

function Shopsite({form, formName}) {
  const isNew = form.id === NEW_ID
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <VendorForm>
      <div className="medium-6 columns">
        <FormList>
          {!isNew && (
            <>
              <LockWriteBack formName={formName} />
              <NameInput formName={formName} />
            </>
          )}
          <FormListItem className="divider--top">
            <TextInput
              name="url"
              label="Admin URL"
              required
              value={form.url || ''}
              errorMessage={form.hasChanged_url && errors.url}
              onChange={(event) =>
                updateForm(formName, {
                  url: event.target.value,
                  hasChanged_url: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              name="username"
              label="Cart Username"
              required
              value={form.username || ''}
              errorMessage={form.hasChanged_username && errors.username}
              onChange={(event) =>
                updateForm(formName, {
                  username: event.target.value,
                  hasChanged_username: true,
                })
              }
            />
          </FormListItem>
          <FormListItem>
            <TextInput
              type="password"
              name="password"
              label="Cart Password"
              required
              value={form.password || ''}
              errorMessage={form.hasChanged_password && errors.password}
              onChange={(event) =>
                updateForm(formName, {
                  password: event.target.value,
                  hasChanged_password: true,
                })
              }
            />
          </FormListItem>
          {!isNew && (
            <>
              <PackingListSelect className="divider--top" formName={formName} />
              <RevisionConfig className="divider--top" formName={formName} />
              <SyncConfig className="divider--top" formName={formName} />
            </>
          )}
        </FormList>
      </div>
      <div className="medium-5 columns">
        <AuthorizeHelper
          vendorName="Shopsite"
          href="https://support.ordoro.com/shopsite-and-ordoro-setup/"
        />
      </div>
    </VendorForm>
  )
}

Shopsite.propTypes = {
  formName: PropTypes.string.isRequired,
  form: PropTypes.shape({
    id: PropTypes.any.isRequired,
    url: PropTypes.string.isRequired,
    hasChanged_url: PropTypes.bool,
    username: PropTypes.string.isRequired,
    hasChanged_username: PropTypes.bool,
    password: PropTypes.string.isRequired,
    hasChanged_password: PropTypes.bool,
  }),
}

export default onlyIfAutoForm(Shopsite, setUpCartForm)
