import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {
  CartShape,
  DataImportDataShape,
  DataImportColumnsToDataMapShape,
} from '../../../../common/PropTypes.js'
import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import {cartsSortedByNameSelector} from '../../../../data/carts.js'
import BaseEditForm from '../BaseEditForm.js'

export class EditForm extends Component {
  static propTypes = {
    data: DataImportDataShape.isRequired,
    updatePayload: PropTypes.func.isRequired,
    cartsSortedByName: PropTypes.arrayOf(CartShape).isRequired,
    columnsToDataMap: DataImportColumnsToDataMapShape.isRequired,
  }

  onSKUChange = (value) => {
    this.updatePayload({sku: value})
  }

  onURLChange = (value) => {
    this.updatePayload({url: value})
  }

  onIsDefaultChange = (value) => {
    this.updatePayload({is_default: value})
  }

  onCartImageIDChange = (value) => {
    this.updatePayload({cart_image_id: value})
  }

  updatePayload = (payloadDelta) => {
    this.props.updatePayload(this.props.data.index, payloadDelta)
  }

  render() {
    const {data, columnsToDataMap} = this.props

    return (
      <BaseEditForm className="medium-6 columns" {...this.props}>
        <Row>
          <TextInput
            value={data.payload.sku}
            errorMessage={data.errors.sku}
            name="sku"
            label="SKU"
            className="medium-12"
            onChange={this.onSKUChange}
          />
        </Row>
        <Row>
          <TextInput
            value={data.payload.url}
            errorMessage={data.errors.url}
            name="url"
            label="URL"
            className="medium-12"
            onChange={this.onURLChange}
            disabled={columnsToDataMap.url === -1}
          />
        </Row>
        {/*
        <Row>
          <div className="medium-12 columns margin-top-5 flex--justify">
            <Checkbox
              checked={data.payload.is_default}
              errorMessage={data.errors.is_default}
              className="columns column--reset margin-right-3"
              name="is_default"
              label="Is Default"
              onChange={this.onIsDefaultChange}
              disabled={columnsToDataMap.is_default === -1}
            />
          </div>
        </Row>
        <Row>
          <TextInput
            value={data.payload.cart_image_id}
            errorMessage={data.errors.cart_image_id}
            name="cart_image_id"
            label="Cart Image ID"
            className="medium-12"
            onChange={this.onCartImageIDChange}
            disabled={columnsToDataMap.cart_image_id === -1}
          />
        </Row>
        */}
      </BaseEditForm>
    )
  }
}

function mapStateToProps(state) {
  return {
    cartsSortedByName: cartsSortedByNameSelector(state),
    columnsToDataMap: state.ui.dataImport.columnsToDataMap,
  }
}

export default connect(mapStateToProps)(EditForm)
