import {createSelector} from 'reselect'
import isEqual from 'lodash/isEqual.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../../store.js'
import {DEFAULT_DATE_RANGE_TYPE} from '../../../../common/constants/OrderListDateRangeFilters.js'
import {TAG_FILTER_OR} from '../../../../common/constants/Tags.js'
import {clearFilterPanelSearch} from './FilterPanel.js'
import {clearTagFilterPanelSearch} from './TagFilterPanel.js'
import {userSettingsSelector} from '../../../../data/me.js'
import {
  cleanOrderQuery,
  orderListQueryToOrderQuery,
  summarizeOrderDateRangeParams,
} from '../../orderListSelectors.js'
import {
  navigateOrderList,
  updateAllocationFilters,
  updateCarrierError,
  updateCartFilters,
  updateShipperFilters,
  updateDateRangeFilters,
  updateHasRevision,
  updateHasSimilarOpenAddresses,
  updateInBatch,
  updateOrderExcludeTags,
  updateOrderListStatus,
  updateOrderTagFilterBy,
  updateOrderTagFilters,
  updateOrderUntaggedFilter,
  updateSearchText,
  updateShipToCountry,
  updateShipToIsBillTo,
  updateShipToStatus,
  updateShowUnprintedOnly,
  updateSupplierFilters,
  updateWarehouseFilters,
} from '../../orderListActions.js'
import deferPromise from '../../../../common/deferPromise.js'
import {
  REPORT_INTERVALS,
  REPORT_INTERVAL_MONTH,
} from '../../../../common/constants/Reports.js'
import {
  isNonZeroPositiveInteger,
  isNonZeroPositiveNumeric,
} from '../../../../common/utils.js'
import {ensureTroolean} from '../../../../common/ensure.js'
import {orderTagsSelector} from '../../../../data/orderTags.js'
import {getTimeZone} from '../../../../common/date.js'

const MODAL_FORM = 'ORDER_LIST_FILTER_MODAL'

export const STATUS_FILTER_PANEL = 'status'
export const ALLOCATION_FILTER_PANEL = 'allocation'
export const SALES_CHANNEL_FILTER_PANEL = 'sales_channel'
export const SHIPPER_FILTER_PANEL = 'shipper'
export const SUPPLIER_FILTER_PANEL = 'supplier'
export const WAREHOUSE_FILTER_PANEL = 'warehouse'
export const TAG_FILTER = 'tag'
export const DATE_RANGE_FILTER_PANEL = 'date_range'
export const MISC_FILTER_PANEL = 'misc'
const DEFAULT_ORDER_LIST_FILTER_ORDER = [
  STATUS_FILTER_PANEL,
  ALLOCATION_FILTER_PANEL,
  SALES_CHANNEL_FILTER_PANEL,
  SHIPPER_FILTER_PANEL,
  SUPPLIER_FILTER_PANEL,
  WAREHOUSE_FILTER_PANEL,
  TAG_FILTER,
  DATE_RANGE_FILTER_PANEL,
  MISC_FILTER_PANEL,
]

export async function showOrderListFilterModal({query = {}} = {}) {
  const {updates} = await showOrderListFilterModalPrime({
    query,
    singleRun: true,
  })

  return await navigateOrderList(updates)
}

export async function showOrderQueryFilterModal({
  query = {},
  singleRun = true,
} = {}) {
  const {originalForm, updates} = await showOrderListFilterModalPrime({
    query,
    singleRun,
  })

  if (!updates) {
    return query
  }

  const orderTags = orderTagsSelector(getState())
  const orderQuery = cleanOrderQuery(
    orderListQueryToOrderQuery({...originalForm, ...updates}, orderTags),
  )

  delete orderQuery.sort
  delete orderQuery.limit
  delete orderQuery.offset

  return orderQuery
}

export async function showOrderListFilterModalPrime({
  query = {},
  singleRun = true,
  deferredPromise = deferPromise(),
  originalForm,
} = {}) {
  const {
    startDate,
    endDate,
    dateFilterType,
    intervalType,
    intervalAmount,
    intervalTrunc,
  } = summarizeOrderDateRangeParams(query)

  const form = {
    searchText: query.search || '',
    status: query.status || [],
    allocationFilters: query.allocation_status || [],
    cartFilters: query.sales_channel || [],
    shipper: query.shipper || [],
    supplierFilters: query.supplier || [],
    warehouseFilters: query.warehouse_id || [],
    dateFilterType: dateFilterType || DEFAULT_DATE_RANGE_TYPE,
    startDate: startDate || null,
    endDate: endDate || null,
    intervalType: intervalType || (!singleRun ? REPORT_INTERVALS[0] : ''),
    intervalAmount: intervalAmount || '1',
    intervalTrunc: intervalTrunc || false,
    orderTagFilters: query.tag || [],
    untaggedSelected: query.untagged || false,
    tagFilterOperator: query.tag_filter_by || TAG_FILTER_OR,
    exclude_tags: query.exclude_tags || [],
    showUnprintedOnly: query.printed === false,
    hasSimilarOpenAddresses: query.has_similar_open_addresses || false,
    shipToIsBillTo: ensureTroolean(query.ship_to_is_bill_to),
    hasRevision: ensureTroolean(query.has_revision),
    shipToStatus: query.ship_to_status || '',
    shipToCountry: (query.ship_to_country && query.ship_to_country[0]) || '',
    carrier_error: ensureTroolean(query.carrier_error),
    inBatch: ensureTroolean(query.in_batch),
    timezone: getTimeZone(),
    includeTimezone: false,
  }

  setForm(MODAL_FORM, {
    singleRun,
    deferredPromise,
    originalForm: originalForm || {...form},
    ...form,
  })

  // timezone is sticky so override its sticky value applied in setForm()
  if (query.timezone) {
    updateModalForm({timezone: query.timezone, includeTimezone: true})
  }

  clearFilterPanelSearch(SALES_CHANNEL_FILTER_PANEL)
  clearFilterPanelSearch(SUPPLIER_FILTER_PANEL)
  clearFilterPanelSearch(WAREHOUSE_FILTER_PANEL)
  clearFilterPanelSearch(SHIPPER_FILTER_PANEL)
  clearTagFilterPanelSearch()

  return deferredPromise.promise
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal(updates) {
  const {originalForm, deferredPromise} = modalFormSelector(getState())

  deferredPromise.resolve({originalForm, updates})

  removeForm(MODAL_FORM)
}

export function clearFilters() {
  const {singleRun, deferredPromise, originalForm} =
    modalFormSelector(getState())

  // re-setup modal form with most of the pieces reused
  // reuse deferredPromise so that any awaits from the original call to show... will resolve
  // reuse originalForm so applyFilter() has the correct thing to compare against
  // reuse singleRun because that is part of the context
  showOrderListFilterModalPrime({
    singleRun,
    deferredPromise,
    originalForm,
  })
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({intervalType, intervalAmount}) => {
    const errors = {}

    if (intervalType) {
      if (
        intervalType === REPORT_INTERVAL_MONTH &&
        !isNonZeroPositiveInteger(intervalAmount)
      ) {
        errors.intervalAmount = 'Amount must be a positive whole number'
        errors.preventSave = true
      } else if (
        intervalType !== REPORT_INTERVAL_MONTH &&
        !isNonZeroPositiveNumeric(intervalAmount)
      ) {
        errors.intervalAmount = 'Amount must be a positive number'
        errors.preventSave = true
      }
    }

    return errors
  },
)

export async function applyFilter() {
  const {
    originalForm,
    searchText,
    status,
    allocationFilters,
    cartFilters,
    shipper,
    supplierFilters,
    warehouseFilters,
    dateFilterType,
    startDate,
    endDate,
    intervalType,
    intervalAmount,
    intervalTrunc,
    orderTagFilters,
    untaggedSelected,
    tagFilterOperator,
    exclude_tags,
    showUnprintedOnly,
    hasSimilarOpenAddresses,
    shipToIsBillTo,
    hasRevision,
    shipToStatus,
    shipToCountry,
    carrier_error,
    inBatch,
    timezone,
    includeTimezone,
  } = modalFormSelector(getState())

  let updates = {}

  if (searchText !== originalForm.search) {
    updates = {...updates, ...updateSearchText(searchText)}
  }

  if (!isEqual(status, originalForm.status)) {
    updates = {...updates, ...updateOrderListStatus(status)}
  }

  if (!isEqual(allocationFilters, originalForm.allocationFilters)) {
    updates = {...updates, ...updateAllocationFilters(allocationFilters)}
  }

  if (!isEqual(cartFilters, originalForm.cartFilters)) {
    updates = {...updates, ...updateCartFilters(cartFilters)}
  }

  if (!isEqual(shipper, originalForm.shipper)) {
    updates = {...updates, ...updateShipperFilters(shipper)}
  }

  if (!isEqual(supplierFilters, originalForm.supplierFilters)) {
    updates = {...updates, ...updateSupplierFilters(supplierFilters)}
  }

  if (!isEqual(warehouseFilters, originalForm.warehouseFilters)) {
    updates = {...updates, ...updateWarehouseFilters(warehouseFilters)}
  }

  if (
    dateFilterType !== originalForm.dateFilterType ||
    startDate !== originalForm.startDate ||
    endDate !== originalForm.endDate ||
    intervalType !== originalForm.intervalType ||
    intervalAmount !== originalForm.intervalAmount ||
    intervalTrunc !== originalForm.intervalTrunc ||
    timezone !== originalForm.timezone ||
    includeTimezone !== originalForm.includeTimezone
  ) {
    updates = {
      ...updates,
      ...updateDateRangeFilters(
        {
          dateFilterType,
          startDate,
          endDate,
          intervalType,
          intervalAmount,
          intervalTrunc,
          timezone: includeTimezone ? timezone : undefined,
        },
        updates.status || originalForm.status,
      ),
    }
  }

  if (!isEqual(orderTagFilters, originalForm.orderTagFilters)) {
    updates = {...updates, ...updateOrderTagFilters(orderTagFilters)}
  }

  if (untaggedSelected !== originalForm.untaggedSelected) {
    updates = {...updates, ...updateOrderUntaggedFilter(untaggedSelected)}
  }

  if (tagFilterOperator !== originalForm.tagFilterOperator) {
    updates = {...updates, ...updateOrderTagFilterBy(tagFilterOperator)}
  }

  if (!isEqual(exclude_tags, originalForm.exclude_tags)) {
    updates = {...updates, ...updateOrderExcludeTags(exclude_tags)}
  }

  if (showUnprintedOnly !== originalForm.showUnprintedOnly) {
    updates = {...updates, ...updateShowUnprintedOnly(showUnprintedOnly)}
  }

  if (hasSimilarOpenAddresses !== originalForm.hasSimilarOpenAddresses) {
    updates = {
      ...updates,
      ...updateHasSimilarOpenAddresses(hasSimilarOpenAddresses),
    }
  }

  if (shipToIsBillTo !== originalForm.shipToIsBillTo) {
    updates = {...updates, ...updateShipToIsBillTo(shipToIsBillTo)}
  }

  if (hasRevision !== originalForm.hasRevision) {
    updates = {...updates, ...updateHasRevision(hasRevision)}
  }

  if (shipToStatus !== originalForm.shipToStatus) {
    updates = {...updates, ...updateShipToStatus(shipToStatus)}
  }

  if (shipToCountry !== originalForm.shipToCountry) {
    updates = {...updates, ...updateShipToCountry(shipToCountry)}
  }

  if (carrier_error !== originalForm.carrier_error) {
    updates = {...updates, ...updateCarrierError(carrier_error)}
  }

  if (inBatch !== originalForm.inBatch) {
    updates = {...updates, ...updateInBatch(inBatch)}
  }

  closeModal(updates)
}

export const orderListFilterOrderSelector = createSelector(
  (state) => userSettingsSelector(state).order_list_filter_order,
  (list) => {
    if (!list) {
      return DEFAULT_ORDER_LIST_FILTER_ORDER
    }

    // remove any items that have been removed from the default list
    list = list.filter((item) => DEFAULT_ORDER_LIST_FILTER_ORDER.includes(item))

    // if the list aren't the same size then there are new items in the default list
    // that need to be added to the list
    if (list.length !== DEFAULT_ORDER_LIST_FILTER_ORDER.length) {
      const newItems = DEFAULT_ORDER_LIST_FILTER_ORDER.filter(
        (item) => !list.includes(item),
      )

      list.push(...newItems)
    }

    return list
  },
)
