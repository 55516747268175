import PropTypes from 'prop-types'

import Weight from '../../../../../../../common/components/Weight.js'
import {
  orderNumberFromLabelConfigSelector,
  weightSelector,
  isMultiboxSelector,
  isInvalidWeightSelector,
  labelShipperTypeSelector,
} from '../../../../../../../data/labelInfos/index.js'

import EditForm from './EditForm.js'
import {showEditOrderWeightModal} from '../../../../../../OrderListPage/Modals/EditOrderWeightModal.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'
import {useSelector} from '../../../../../../../store.js'

export default function EditWeight({disabled}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
  const weight = useSelector((state) => weightSelector(state, {labelInfoID}))
  const isInvalid = useSelector((state) =>
    isInvalidWeightSelector(state, {labelInfoID}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  return (
    <li className="list__item--order-data list__item--shipping-info meta-orderlistrow-expanded-wrap-edit-weight">
      <EditForm
        label="Weight"
        value={<Weight value={weight} />}
        labelInfoID={labelInfoID}
        disabled={disabled}
        isInvalid={isInvalid}
        isMultibox={isMultibox}
        shipperType={shipperType}
        onEditClick={() => showEditOrderWeightModal([orderNumber])}
      />
    </li>
  )
}

EditWeight.propTypes = {
  disabled: PropTypes.bool,
}
