import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {TagsShape} from '../../common/PropTypes.js'
import {removeTagFromProducts} from '../../data/productTags.js'
import {hasProductManageTagOnProductPermissionSelector} from '../../data/me.js'
import AssignedTags from '../../common/components/List/AssignedTags/index.js'

export function ProductTags({
  sku,
  tags,
  className,
  hasProductManageTagOnProductPermission,
}) {
  return (
    <AssignedTags
      tags={tags}
      className={className}
      hasManageTagOnOrderPermission={hasProductManageTagOnProductPermission}
      onRemove={(tag) => removeTagFromProducts(tag.id, [sku])}
    />
  )
}

ProductTags.propTypes = {
  sku: PropTypes.string.isRequired,
  tags: TagsShape.isRequired,
  className: PropTypes.string,
  hasProductManageTagOnProductPermission: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    hasProductManageTagOnProductPermission:
      hasProductManageTagOnProductPermissionSelector(state),
  }
}

export default connect(mapStateToProps)(ProductTags)
