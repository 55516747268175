import PropTypes from 'prop-types'
import classNames from 'classnames'

import {isNumeric} from '../../../utils.js'

export default function PageNumber({pageNumber, active, onClick}) {
  return (
    <li className="list__item--inline margin-left-3">
      {isNumeric(pageNumber) ? (
        <button
          className={classNames('btn btn--pagination', {active})}
          type="button"
          onClick={() => onClick()}
        >
          {pageNumber}
        </button>
      ) : (
        <span>{pageNumber}</span>
      )}
    </li>
  )
}

PageNumber.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  pageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}
