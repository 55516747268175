import PropTypes from 'prop-types'
import {useMemo} from 'react'

import {TAG_TYPE__PO} from '../../common/constants/Tags.js'
import {
  addTagToPOs,
  removeTagFromPOs,
  poTagOptionsSelector,
  poTagOptionTokensSelector,
} from '../../data/poTags.js'
import {
  hasPOManageTagPermissionSelector,
  hasPOManageTagOnPOPermissionSelector,
} from '../../data/me.js'
import ManageTagFilter from '../../common/components/ManageTagFilter.js'
import {useSelector} from '../../store.js'
import {createPOTagQuantityMapSelector} from './purchaseOrderListSelectors.js'
import {createAndApplyTagToPOs} from './purchaseOrderListActions.js'

export default function AddPOTagsDropdown({poIDs}) {
  const poTagQuantityMapSelector = useMemo(
    () => createPOTagQuantityMapSelector(),
    [],
  )
  const poTagOptions = useSelector(poTagOptionsSelector)
  const poTagOptionTokens = useSelector(poTagOptionTokensSelector)
  const tagQuantityMap = useSelector((state) =>
    poTagQuantityMapSelector(state, {poIDs}),
  )
  const hasPOManageTagPermission = useSelector(hasPOManageTagPermissionSelector)
  const hasPOManageTagOnPOPermission = useSelector(
    hasPOManageTagOnPOPermissionSelector,
  )

  if (!hasPOManageTagOnPOPermission) {
    return null
  }

  return (
    <li className="inline-block margin-left-10">
      <ManageTagFilter
        dropdown="APPLY_PO_TAG_FILTER"
        onSelect={(tag) => {
          if (tagQuantityMap[tag.id] === 'all') {
            removeTagFromPOs(tag.id, poIDs)
          } else {
            addTagToPOs(tag.id, poIDs)
          }
        }}
        tagOptions={poTagOptions}
        tagOptionTokens={poTagOptionTokens}
        tagQuantityMap={tagQuantityMap}
        hasManageTagPermission={hasPOManageTagPermission}
        tagType={TAG_TYPE__PO}
        onCreateAndApplyClick={() => createAndApplyTagToPOs(poIDs)}
      />
    </li>
  )
}

AddPOTagsDropdown.propTypes = {
  poIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
}
