import PropTypes from 'prop-types'

import {RuleShape} from '../../../common/PropTypes.js'
import {
  CONDITIONS_JOINED_WITH_ALL,
  CONDITIONS_JOINED_WITH_ANY,
} from '../../../common/constants/Rules.js'
import ButtonIcon from '../../../common/components/Button/ButtonIcon.js'
import {
  settingsSaveRule,
  addRuleAction,
  handleRemoveRuleAction,
  addRuleCondition,
  removeRuleCondition,
  setRuleCondition,
  setRuleConditionsJoinedWith,
  ruleErrorsSelector,
  ruleCanBeSavedSelector,
  ruleActionsSelector,
  updateRuleForm,
} from './rulesFunctions.js'

import RuleCondition from './RuleCondition/index.js'
import RuleAction from './RuleAction/index.js'
import {useSelector} from '../../../store.js'
import {
  getConditions,
  getConditionsJoiner,
} from './RuleCondition/conditionalOptions.js'
import TextArea from '../../../common/components/TextArea.js'

export default function EditingRule({rule, hideSaveAndCancel, onCancel}) {
  const actions = useSelector((state) =>
    ruleActionsSelector(state, {ruleID: rule.id}),
  )
  const actionCount = actions.filter((action) => !action.hide).length
  const conditionJoiner = getConditionsJoiner(rule)
  const conditions = getConditions(rule)

  const errors = useSelector((state) =>
    ruleErrorsSelector(state, {ruleID: rule.id}),
  )
  const disableSave = useSelector(
    (state) => !ruleCanBeSavedSelector(state, {ruleID: rule.id}),
  )

  return (
    <div className="form-list medium-10 small-10 columns padding-left-0 meta-editing-rule">
      <div className="wrap--rule-notes editing w-75">
        <TextArea
          label="Rule Description/Notes"
          className="fs-n0"
          size="sm"
          id="internal_notes"
          value={rule.internal_notes || ''}
          onChange={(internal_notes) =>
            updateRuleForm(rule.id, {internal_notes})
          }
        />
      </div>
      <dl className="list--rule-group">
        <dt className="list__title--rule-condition">WHEN</dt>
        <dd className="list__item--rule-criteria">
          <strong>An order is created</strong>
        </dd>
      </dl>
      <dl className="list--rule-group">
        <dt className="list__title--rule-condition margin-top-1">IF</dt>
        <dd className="list__item--rule-criteria criteria--if">
          {conditions.length > 1 && (
            <div className="margin-bottom-10">
              <select
                className="select margin-right-5 v-align-middle"
                name="joinedWith"
                value={conditionJoiner}
                onChange={(event) =>
                  setRuleConditionsJoinedWith(rule.id, event.target.value)
                }
              >
                <option value={CONDITIONS_JOINED_WITH_ALL}>All</option>
                <option value={CONDITIONS_JOINED_WITH_ANY}>Any</option>
              </select>
              of the following conditions are met:
            </div>
          )}
          {conditions.map((condition, index) => (
            <div key={index}>
              <RuleCondition
                ruleID={rule.id}
                index={index}
                condition={condition}
                onChange={(condition) =>
                  setRuleCondition(rule.id, index, condition)
                }
                onRemove={() => removeRuleCondition(rule.id, index)}
                errors={errors.conditions[index]}
                isRemovable={conditions.length > 1}
                isEditing
              />
            </div>
          ))}
          <ButtonIcon
            iconClassName="icon-add"
            size="xx-sm"
            onClick={() => addRuleCondition(rule.id)}
            text="Add another condition"
          />
        </dd>
      </dl>
      <dl className="list--rule-group">
        <dt className="list__title--rule-condition">THEN</dt>
        <dd className="list__item--rule-criteria">
          {actionCount > 1 && (
            <div className="margin-bottom-15">
              <strong>Take the following actions:</strong>
            </div>
          )}
          {actions.map((action, index) =>
            action.hide ? null : (
              <div className="margin-top-1 margin-bottom-10" key={index}>
                <RuleAction
                  id={rule.id}
                  index={index}
                  action={action}
                  errors={errors.actions[index]}
                  isEditing
                />
                {actionCount > 1 && (
                  <ButtonIcon
                    className="margin-bottom-10"
                    iconClassName="icon-subtract top-zero"
                    size="xx-sm"
                    onClick={() => handleRemoveRuleAction(rule.id, index)}
                  />
                )}
              </div>
            ),
          )}
          <ButtonIcon
            size="xx-sm"
            iconClassName="icon-add"
            onClick={() => addRuleAction(rule.id)}
            text="Add another action"
          />
        </dd>
      </dl>

      {!hideSaveAndCancel && (
        <div className="wrap--rule-buttons">
          <button
            type="button"
            className="btn btn--primary btn--md meta-rule-save"
            onClick={() => settingsSaveRule(rule.id)}
            disabled={disableSave}
          >
            Save
          </button>
          <a
            href="#/settings/rules"
            className="btn btn--secondary btn--md meta-rule-cancel"
            onClick={() => onCancel()}
          >
            Cancel
          </a>
        </div>
      )}
    </div>
  )
}

EditingRule.propTypes = {
  hideSaveAndCancel: PropTypes.bool,
  rule: RuleShape.isRequired,
  onCancel: PropTypes.func.isRequired,
}
