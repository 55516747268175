import PropTypes from 'prop-types'
import {Component} from 'react'

import Popover from './Popover.js'

export default class TimelinePopover extends Component {
  state = {
    showPopover: false,
  }

  render() {
    const {type, title, commentGroupsSelector} = this.props
    const {showPopover} = this.state

    return (
      <div
        className={`wrap--${type}-timeline-pop-over`}
        onMouseEnter={() => this.setState({showPopover: true})}
        onMouseLeave={() => this.setState({showPopover: false})}
      >
        <div className="wrap--pop-over padding-right-5 inline-block">
          <div
            key="icon"
            className="icon--pop-over i-clock-o op-30 fs-00"
            aria-hidden="true"
          />
          {showPopover && (
            <Popover
              title={title}
              commentGroupsSelector={commentGroupsSelector}
            />
          )}
        </div>
      </div>
    )
  }
}

TimelinePopover.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  commentGroupsSelector: PropTypes.func.isRequired,
}
