import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import capitalize from 'lodash/capitalize.js'

import {GoodsReceiptShape} from '../../common/PropTypes.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {isPOLockedSelector} from '../../data/pos.js'
import {showDeleteGoodsReceiptModal} from '../PurchaseOrderListPage/Modals/DeleteGoodsReceiptModal.js'
import {showGoodsReceiptTextFieldModal} from '../PurchaseOrderListPage/Modals/GoodsReceiptTextFieldModal.js'
import {openGoodsReceiptModal} from '../PurchaseOrderListPage/Modals/GoodsReceiptModal/goodsReceiptModalFunctions.js'
import {goodsReceiptToggleExpanded} from './poDetailActions.js'
import {createIsGoodsReceiptExpandedSelector} from './poDetailSelectors.js'
import GoodsReceiptItem from './GoodsReceiptItem.js'

export function GoodsReceipt({goodsReceipt, isExpanded, isPOLocked, ...props}) {
  return (
    <div className="wrap--goods-receipt">
      <div className="flex--justify">
        <div className="mw-40">
          <p className="fs-02 lh-md margin-right-15 margin-bottom-3">
            <strong>{goodsReceipt.goods_receipt_id}</strong>
          </p>
          <p className="fs-00 margin-bottom-3">
            <strong>Created: </strong>
            <FormattedDate
              value={goodsReceipt.created}
              format="MMM D, YYYY [at] h:mma"
            />
          </p>
          <p className="fs-00 margin-bottom-3">
            <strong>Status: </strong>
            <span>{capitalize(goodsReceipt.status)}</span>
          </p>
          <div className="fs-00">
            <strong>Internal Notes:</strong>
          </div>
          <div className="fs-n0">
            {goodsReceipt.internal_notes && (
              <div className="ws-pre-wrap">{goodsReceipt.internal_notes}</div>
            )}
            <ButtonLink
              className="v-align-base"
              onClick={() =>
                showGoodsReceiptTextFieldModal({
                  goodsReceiptID: goodsReceipt.goods_receipt_id,
                  value: goodsReceipt.internal_notes,
                  display: 'Internal Notes',
                  apiProp: 'internal_notes',
                  inputType: 'textarea',
                })
              }
              disabled={isPOLocked}
            >
              {goodsReceipt.internal_notes ? 'Edit' : 'Add note'}
            </ButtonLink>
          </div>
        </div>
        <div>
          <div className="flex">
            <ButtonLink
              className="no-underline border-right--light padding-right-7 btn--expand-goods-receipt-single flex--justify-col"
              onClick={() =>
                goodsReceiptToggleExpanded(goodsReceipt.goods_receipt_id)
              }
            >
              <span
                className="i--expand fs-01 lh-0 v-align-middle"
                aria-hidden="true"
              />
            </ButtonLink>
            <ButtonLink
              size="sm"
              onClick={() =>
                props.showDeleteGoodsReceiptModal([
                  goodsReceipt.goods_receipt_id,
                ])
              }
              disabled={isPOLocked}
            >
              Delete
            </ButtonLink>
            <ButtonPrimary
              size="x-sm"
              isOutlined
              onClick={() =>
                openGoodsReceiptModal(
                  goodsReceipt.po_id,
                  goodsReceipt.goods_receipt_id,
                )
              }
              disabled={isPOLocked}
            >
              Edit
            </ButtonPrimary>
          </div>
        </div>
      </div>
      {isExpanded ? (
        <table className="table fs-00 margin-top-30">
          <thead>
            <tr className="fs-n0">
              <th className="table__th table__th--md">Product Name/SKU</th>
              <th className="table__th table__th--md">Location in Warehouse</th>
              <th className="table__th table__th--md align-right">
                Ordered Qty
              </th>
              <th className="table__th table__th--md align-right">
                Remaining Qty
              </th>
              <th className="table__th table__th--md align-right">
                Received Qty
              </th>
            </tr>
          </thead>
          <tbody className="table__tbody table__tbody--lines">
            {goodsReceipt.items.map((item) => (
              <GoodsReceiptItem
                key={item.id}
                poID={goodsReceipt.po_id}
                goodsReceiptID={goodsReceipt.goods_receipt_id}
                item={item}
                isPOLocked={isPOLocked}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="align-center margin-top-0 margin-bottom-10">
          <ButtonPrimary
            size="x-sm"
            isOutlined
            className="op-75 btn--expand-goods-receipt-single"
            onClick={() =>
              goodsReceiptToggleExpanded(goodsReceipt.goods_receipt_id)
            }
          >
            <span
              className="i--expand fs-00 lh-0 v-align-middle padding-right-5"
              aria-hidden="true"
            />
            <span className="fs-n1">Show goods receipt lines</span>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

GoodsReceipt.propTypes = {
  goodsReceipt: GoodsReceiptShape.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isPOLocked: PropTypes.bool.isRequired,
  showDeleteGoodsReceiptModal: PropTypes.func.isRequired,
}

function mapStateToProps(_state, {goodsReceipt}) {
  const isExpandedSelector = createIsGoodsReceiptExpandedSelector(
    goodsReceipt.goods_receipt_id,
  )

  return (state) => ({
    isExpanded: isExpandedSelector(state),
    isPOLocked: isPOLockedSelector(state, {poID: goodsReceipt.po_id}),
  })
}

const mapDispatchToProps = {
  showDeleteGoodsReceiptModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodsReceipt)
