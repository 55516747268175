import PropTypes from 'prop-types'

export default function Panel({children}) {
  return (
    <div className="wrap--analytics medium-10 medium-push-2 columns">
      {children}
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
}
