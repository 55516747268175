export const BASE = 'ordoro/ui/modal/confirmDeallocateModal'
export const CONFIRM = `${BASE}/CONFIRM`
export const SET_ORDERS = `${BASE}/SET_ORDERS`
export const SET_IS_SAVING = `${BASE}/SET_IS_SAVING`
export const SHOW_MODAL = `${BASE}/SHOW_MODAL`

export const MODAL_NAME = 'CONFIRM_DEALLOCATE_MODAL'

export function confirm() {
  return {
    type: CONFIRM,
  }
}

export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    payload: orders,
  }
}

export function setIsSaving(isSaving) {
  return {
    type: SET_IS_SAVING,
    payload: isSaving,
  }
}

export function showConfirmDeallocateModal(orders) {
  return {
    type: SHOW_MODAL,
    payload: orders,
  }
}
