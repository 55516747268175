import {updateLabelConfig} from '../../../../data/labelInfos/index.js'
import ConfigSelect from '../ConfigSelect.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [FEDEX]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__smart_post_indicia`]
    : []
}

export default function SmartPostIndicia() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="FedEx Ground® Economy Indicia"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty="indicia"
    />
  )
}
