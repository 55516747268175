import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import Checkbox from '../../Common/Checkbox.js'
import {saveShipperAustraliaPost} from '../../../../redux/actions/ui/settings/shippers/forms/australiaPost.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'

import Archive from './Archive.js'
import Nickname from './Nickname.js'
import ShippingMethods from './ShippingMethods.js'

function AustraliaPostForm({
  form: {id, accountNumber, apiKey, apiSecret, printAsYouGo, isSaving},
  hasErrors,
  errors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-5 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.accountNumber},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="id_australia_post_acct_no">
                Account Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="australia_post_acct_no"
                id="id_australia_post_acct_no"
                value={accountNumber}
                onChange={(event) =>
                  props.updateForm(id, {accountNumber: event.target.value})
                }
              />
              {errors.accountNumber && (
                <small className="error error-message">
                  {errors.accountNumber}
                </small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.apiKey,
              })}
            >
              <label htmlFor="id_australia_post_api_key">
                API Key<span className="required">*</span>
              </label>
              <input
                type="text"
                name="australia_post_api_key"
                id="id_australia_post_api_key"
                value={apiKey}
                onChange={(event) =>
                  props.updateForm(id, {apiKey: event.target.value})
                }
              />
              {errors.apiKey && (
                <small className="error error-message">{errors.apiKey}</small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.apiSecret,
              })}
            >
              <label htmlFor="id_australia_post_api_secret">
                API Secret<span className="required">*</span>
              </label>
              <input
                type="password"
                name="australia_post_api_secret"
                id="id_australia_post_api_secret"
                value={apiSecret}
                onChange={(event) =>
                  props.updateForm(id, {apiSecret: event.target.value})
                }
              />
              {errors.apiSecret && (
                <small className="error error-message">
                  {errors.apiSecret}
                </small>
              )}
            </li>
            <li className="form-list-item bigger margin-bottom-20">
              <Checkbox
                label="Print As You Go"
                id="print_as_you_go"
                checked={printAsYouGo}
                onChange={() =>
                  props.updateForm(id, {printAsYouGo: !printAsYouGo})
                }
              />
            </li>
            {id !== NEW_ID && <ShippingMethods shipperID={id} />}
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                isLoading={isSaving}
                isDisabled={hasErrors}
                onClick={() => props.saveShipperAustraliaPost(id)}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>

      {id !== NEW_ID && (
        <div className="medium-5 columns">
          <div className="alert margin-bottom-10 alert--standard">
            <Archive shipperID={id} />
          </div>
        </div>
      )}
    </div>
  )
}

AustraliaPostForm.propTypes = {
  form: ShipperFormShape,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveShipperAustraliaPost: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
  }
}

const mapDispatchToProps = {
  saveShipperAustraliaPost,
}

export default connect(mapStateToProps, mapDispatchToProps)(AustraliaPostForm)
