import {OrderShape} from '../../../../../../common/PropTypes.js'
import ShipDate from '../../Fields/ShipDate.js'

import {
  getIsManuallyTracked,
  getIsMarkedAsShipped,
} from '../../../../../../data/orders.js'

function getTitle(order) {
  if (getIsManuallyTracked(order)) {
    return 'Tracking Info Added:'
  }
  if (getIsMarkedAsShipped(order)) {
    return 'Marked as Shipped:'
  }
  return 'Label Created:'
}

export default function ShippedStatus({order}) {
  return (
    <div>
      <div className="fs-n1 text--md-grey">
        <strong>{getTitle(order)}</strong>
      </div>
      {order.shipping_info.ship_date && (
        <div className="fs-n1">
          <ShipDate date={order.shipping_info.ship_date} />
        </div>
      )}
    </div>
  )
}

ShippedStatus.propTypes = {
  order: OrderShape.isRequired,
}
