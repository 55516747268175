export function toEmailList(stringOfEmails) {
  return String(stringOfEmails || '')
    .split(/[\s,]/)
    .filter((email) => email)
    .map((email) => email.toLowerCase())
}

export function validateEmailList(emailList) {
  return emailList.reduce(
    (prev, email) => {
      if (isEmailAddress(email)) {
        prev.validEmails.push(email)
      } else {
        prev.invalidEmails.push(email)
      }
      return prev
    },
    {
      validEmails: [],
      invalidEmails: [],
    },
  )
}

export function isEmailAddress(value) {
  return /^[^@\s]+@[^@\s]+\.[^@.\s]+$/.test(value)
}
