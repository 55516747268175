import {updateLabelConfig} from '../../../../data/labelInfos/index.js'
import ConfigCheckbox from '../ConfigCheckbox.js'
import {UPS} from '../../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'shipper_release'
const VALID_SHIPPER_TYPES = [UPS]

export default function ShipperRelease() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigCheckbox
      label="Shipper Release"
      onChange={(checked) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: checked})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
