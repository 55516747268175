import {combineReducers} from 'redux'
import reduce from 'lodash/reduce.js'

import {NEW_ID} from '../../../../../common/constants/index.js'
import {
  SET_IS_SAVING,
  SET_IS_UPLOADING,
  SET_ERROR,
  SET_CURRENT_PACKING_LIST,
  UPDATE_CURRENT_PACKING_LIST,
  PROFILES_GENERAL,
  PROFILES_PACKING_LIST,
  PROFILES_RETURN_ORDER,
  PROFILES_SHIP_FROM_ALIAS,
} from '../../../../actions/ui/settings/packingLists/index.js'
import {NAVIGATE} from '../../../../actions/ui/index.js'
import INITIAL_STATE from '../../../INITIAL_STATE.js'
import composeReducers from '../../../../utils/composeReducers.js'

function isSaving(
  state = INITIAL_STATE.ui.settings.packingLists.isSaving,
  action = {},
) {
  if (action.type === SET_IS_SAVING) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return false
  }

  return state
}

function isUploading(
  state = INITIAL_STATE.ui.settings.packingLists.isUploading,
  action = {},
) {
  if (action.type === SET_IS_UPLOADING) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return false
  }

  return state
}

function error(
  state = INITIAL_STATE.ui.settings.packingLists.error,
  action = {},
) {
  if (action.type === SET_ERROR) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    return ''
  }

  return state
}

function editID(state = INITIAL_STATE.ui.settings.packingLists.editID) {
  return state
}

function isNew(state = INITIAL_STATE.ui.settings.packingLists.isNew) {
  return state
}

function selectedProfileTab(state = PROFILES_GENERAL) {
  return state
}

function hasChanged(
  state = INITIAL_STATE.ui.settings.packingLists.hasChanged,
  action = {},
) {
  if (action.type === NAVIGATE) {
    return {}
  }

  return state
}

function currentPackingList(
  state = INITIAL_STATE.ui.settings.packingLists.currentPackingList,
) {
  return state
}

const reducer = combineReducers({
  isSaving,
  isUploading,
  error,
  editID,
  isNew,
  selectedProfileTab,
  hasChanged,
  currentPackingList,
})

function handleNavigate(state, action) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload
    let editID = INITIAL_STATE.ui.settings.packingLists.editID
    let isNew = INITIAL_STATE.ui.settings.packingLists.isNew
    let selectedProfileTab = PROFILES_GENERAL
    let currentPackingList = state.currentPackingList

    if (pathComponents[0] !== 'settings' || pathComponents[1] !== 'profiles') {
      return state
    }

    if (
      [
        PROFILES_GENERAL,
        PROFILES_PACKING_LIST,
        PROFILES_RETURN_ORDER,
        PROFILES_SHIP_FROM_ALIAS,
      ].includes(pathComponents[2])
    ) {
      selectedProfileTab = pathComponents[2]
    }

    if (pathComponents[3] && pathComponents[3] !== NEW_ID) {
      editID = Number(pathComponents[3]) || null
    }

    isNew = pathComponents[3] === NEW_ID

    if (state.isNew !== isNew || state.editID !== editID) {
      currentPackingList =
        INITIAL_STATE.ui.settings.packingLists.currentPackingList
    }

    return {
      ...state,
      editID,
      isNew,
      selectedProfileTab,
      currentPackingList,
    }
  }

  return state
}

function handleSetCurrentCart(state, action) {
  if (action.type === SET_CURRENT_PACKING_LIST) {
    return {
      ...state,
      currentPackingList: action.payload,
      hasChanged: {},
    }
  }

  return state
}

function handleUpdateCurrentCart(state, action) {
  if (action.type === UPDATE_CURRENT_PACKING_LIST) {
    return {
      ...state,
      currentPackingList: {
        ...state.currentPackingList,
        ...action.payload,
      },
      hasChanged: {
        ...state.hasChanged,
        ...reduce(
          action.payload,
          (prev, value, key) => {
            prev[key] = true

            return prev
          },
          {},
        ),
      },
    }
  }

  return state
}

export default composeReducers(
  reducer,
  handleNavigate,
  handleSetCurrentCart,
  handleUpdateCurrentCart,
)
