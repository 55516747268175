import ListHeader from './ListHeader/index.js'
import OrderList from './OrderList.js'
import ActionPanel from '../../../OrderListPage/ActionPanel/index.js'
import NewOrdersAlert from './NewOrdersAlert.js'
import OrderListOmniBar from '../../../OrderListPage/OrderListOmniBar.js'
import {onlyIfForm, useSelector} from '../../../../store.js'
import {hasOrderPermissionSelector} from '../../../../data/me.js'
import NoPermissionsPage from '../../../NoPermissionsPage.js'
import OrderRevisionAlert from './OrderRevisionAlert.js'
import {orderListFormSelector} from '../../../OrderListPage/orderListSelectors.js'

function OrderListPage() {
  const hasOrderPermission = useSelector(hasOrderPermissionSelector)

  if (!hasOrderPermission) {
    return <NoPermissionsPage />
  }

  return (
    <>
      <div className="wrap--container">
        <div className="wrap--row">
          <div className="medium-12 columns">
            <NewOrdersAlert />
          </div>
        </div>
        <div className="wrap--row">
          <div className="medium-12 columns">
            <ListHeader />
          </div>
        </div>

        <div className="wrap--row margin-bottom-15">
          <div className="medium-12 columns flex">
            <OrderListOmniBar />
            <OrderRevisionAlert />
          </div>
        </div>
        <div className="wrap--row">
          <OrderList />
          <div className="medium-4 columns">
            <ActionPanel />
          </div>
        </div>
      </div>
    </>
  )
}

export default onlyIfForm(OrderListPage, orderListFormSelector)
