import classNames from 'classnames'

import {AddressShape} from '../../PropTypes.js'

export default function AddressValidationIcon({address}) {
  const validationStatus = address.validation && address.validation.status

  return (
    <div className={classNames('wrap--address-validation', validationStatus)}>
      {['warning', 'error'].includes(validationStatus) && (
        <div
          className="tooltip--top"
          data-hint={address.validation.additional_text}
        >
          <div
            className="i--validation fs-00 margin-right-3"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  )
}

AddressValidationIcon.propTypes = {
  address: AddressShape.isRequired,
}
