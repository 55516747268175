import PropTypes from 'prop-types'
import {useCallback, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'

import {updateForm, onlyIf} from '../../store.js'
import {areChildrenNotEmpty} from '../utils.js'
import {HK_CONFIRM, HK_UP, HK_DOWN} from '../constants/HotKeys.js'
import {HotKeyConnect} from './HotKeys.js'
import {setCurrentDropdown} from '../../redux/actions/ui/currentDropdown.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import useBoundingBox from './useBoundingBox.js'
import useScrollHeight from './useScrollHeight.js'
import useWindowSize from './useWindowSize.js'

export function useZropdownElement({ref, isSelected}) {
  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({block: 'nearest'})
    }
  }, [ref.current, isSelected])
}

export function useZropdown({
  dropdown,
  form,
  onChange,
  onSelect,
  autoFocus,
  closeOnConfirm,
}) {
  const onChangeInternal = useCallback(
    (value) => {
      updateForm(dropdown, {text: value})

      setCurrentDropdown(dropdown)

      if (onChange) {
        onChange(value)
      }
    },
    [onChange],
  )

  const onSelectInternal = useCallback(
    (result) => {
      if (onSelect) {
        onSelect(result)
      }
    },
    [onSelect],
  )

  const onConfirm = useCallback(() => {
    const option = form.results[form.selectedIndex]

    if (option && !option.disabled) {
      onSelectInternal(option)
    }

    if (closeOnConfirm) {
      setCurrentDropdown(null)
    }
  }, [form])

  const onUp = useCallback(() => {
    let validOptionIndex = form.selectedIndex

    while (validOptionIndex - 1 >= 0) {
      validOptionIndex -= 1

      const option = form.results[validOptionIndex]

      if (option.disabled) {
        continue
      }

      updateForm(dropdown, {selectedIndex: validOptionIndex})

      break
    }
  }, [form])

  const onDown = useCallback(() => {
    let validOptionIndex = form.selectedIndex

    while (validOptionIndex + 1 < form.results.length) {
      validOptionIndex += 1

      const option = form.results[validOptionIndex]

      if (option.disabled) {
        continue
      }

      updateForm(dropdown, {selectedIndex: validOptionIndex})

      break
    }
  }, [form])

  useEffect(() => {
    if (autoFocus) {
      setCurrentDropdown(dropdown)
    }
  }, [autoFocus])

  return {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown}
}

const MIN_HEIGHT = 57 * 3 // if we can see at least three items in dropdown
export const POP_DOWN = 'down'
export const POP_UP = 'up'

function Zropdown({
  dropdown,
  id,
  className,
  parentRef,
  onConfirm,
  onUp,
  onDown,
  preventClose,
  header,
  footer,
  blurOnOpen,
  minimumHeight = MIN_HEIGHT,
  preferredDirection = POP_DOWN,
  children,
}) {
  className = className || ''

  useEffect(() => {
    if (blurOnOpen) {
      document.activeElement.blur()
    }
  }, [blurOnOpen])

  const ref = useRef()
  const headerRef = useRef()
  const footerRef = useRef()
  const boundingBox = useBoundingBox({ref: parentRef})
  const {height: headerHeight} = useBoundingBox({ref: headerRef})
  const {height: footerHeight} = useBoundingBox({ref: footerRef})
  const contentHeight = useScrollHeight({ref})
  const {width: contentWidth} = useBoundingBox({ref})
  const windowSize = useWindowSize()

  if (!areChildrenNotEmpty(children)) {
    return null
  }

  const border = 4 // size of dropdown black border
  const vertOffset = 13 // vertical offset from "parent" element
  const horzOffset = 0 // horizontal offset from "parent" element
  const edgeGap = 5 // size of buffer between edge of window and dropdown

  // the real size of the full dropdown if there is no space limit
  const fullDropdownHeight =
    headerHeight + contentHeight + footerHeight + border

  // the amount of raw space below the parent element
  const bottomSpace = windowSize.height - boundingBox.bottom
  // the amount of raw space above the parent element
  const topSpace = boundingBox.top

  let isAbove = false
  if (preferredDirection === POP_DOWN) {
    // put the dropdown above parent if no space below
    isAbove = bottomSpace < headerHeight + minimumHeight + footerHeight
  } else {
    // put the dropdown below parent if no space above
    isAbove = topSpace > headerHeight + minimumHeight + footerHeight
  }

  // space available after accounting for gaps
  const spaceAvailable =
    (isAbove ? topSpace : bottomSpace) - vertOffset - edgeGap

  // if the full dropdown height can be shown then show it
  const isFullSize = spaceAvailable >= fullDropdownHeight

  const forcedContentHeight = isFullSize
    ? // take full height of content
      contentHeight
    : // take space available and minus the fixed bits
      spaceAvailable - headerHeight - footerHeight - border

  // spaceAvailable on the right
  const rightSpaceAvailable = windowSize.width - boundingBox.left

  // if there isn't enough space on the right for it move it to the left
  const tooFarRight = rightSpaceAvailable < contentWidth + border + horzOffset

  // shift dropdown to align with parent
  const dropdownLeft = tooFarRight
    ? boundingBox.right - contentWidth
    : boundingBox.left

  // setup styles
  const style = {
    left: dropdownLeft,
    width: 'fit-content',
    height: 'fit-content',
  }
  const dropStyle = {
    height: forcedContentHeight,
  }
  if (isAbove) {
    style.top =
      boundingBox.top -
      (forcedContentHeight + headerHeight + footerHeight + border + vertOffset)
  } else {
    style.top = boundingBox.bottom + vertOffset
  }

  return ReactDOM.createPortal(
    <div
      id={id}
      className={`wrap--base-dropdown open ${className}`}
      style={style}
      data-dropdown={dropdown}
    >
      <div
        className={`wrap--dropdown-list ${
          isAbove ? 'wrap--dropdown-list--up' : 'wrap--dropdown-list--down'
        } ${
          tooFarRight
            ? 'wrap--dropdown-list--right'
            : 'wrap--dropdown-list--left'
        }`}
        data-dropdown-prevent-close={preventClose}
      >
        {header && (
          <div className="dropdown-list--header" ref={headerRef}>
            {header}
          </div>
        )}
        <div className="list--dropdown list--dropdown-open" style={dropStyle}>
          {onConfirm && <HotKeyConnect code={HK_CONFIRM} func={onConfirm} />}
          {onUp && <HotKeyConnect code={HK_UP} func={onUp} />}
          {onDown && <HotKeyConnect code={HK_DOWN} func={onDown} />}
          <ul ref={ref}>{children}</ul>
        </div>
        {footer && (
          <div className="dropdown-list--footer" ref={footerRef}>
            {footer}
          </div>
        )}
      </div>
    </div>,
    document.querySelector('#dropdown-root'),
  )
}

Zropdown.propTypes = {
  dropdown: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  parentRef: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  preventClose: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  blurOnOpen: PropTypes.bool,
  minimumHeight: PropTypes.number,
  preferredDirection: PropTypes.oneOf([POP_DOWN, POP_UP]),
}

export default onlyIf(
  Zropdown,
  (state, {dropdown, show}) =>
    show || dropdown === currentDropdownSelector(state),
)
