import {useMemo} from 'react'
import get from 'lodash/get.js'
import round from 'lodash/round.js'

import {
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  getState,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  autoFillPostageConfigSelector,
  shipperTypeSelector,
} from '../../../data/shippers.js'
import className from '../../../common/className.js'
import {showAutoFillPostageModal} from '../../Modals/AutoFillPostageModal.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import Quantity from '../../../common/components/Quantity.js'
import Currency, {formatCurrency} from '../../../common/components/Currency.js'
import {
  companySelector,
  currencySymbolSelector,
  hasAccountCreditCardSelector,
} from '../../../data/company.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {addToShipperBalance} from '../../../data/shipperBalances.js'
import {
  anyPaymentAccountIsLockedSelector,
  defaultPaymentAccountSelector,
  hasInprogressAccountTransferSelector,
  NET_ZERO_PA,
  paymentAccountSelector,
  PLAID,
  postagePaymentAccountOptionsSelector,
} from '../../../data/paymentAccounts.js'
import {isNonZeroPositiveNumeric} from '../../../common/utils.js'
import {PITNEY, VISIBLE_USPS} from '../../../common/constants/ShipperNames.js'
import {amountExceedsLimitSelector} from '../../../data/account.js'
import {PostageBalanceWrapper} from '../../settings/Shippers/Forms/PostageBalance.js'
import Zelect, {DefaultListItem} from '../../../common/components/Zelect.js'

const MODAL_FORM = 'POSTAGE_BALANCE_MODAL'

export function showPostageBalanceModal(shipperID) {
  const defaultPaymentAccount = defaultPaymentAccountSelector(getState()) || {}

  setForm(MODAL_FORM, {
    shipperID,
    paymentAccountID: defaultPaymentAccount.id,
    addAmount: '',
    serverError: null,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function errorsSelector(state) {
  const {shipperID, addAmount, paymentAccountID} = modalFormSelector(state)
  const shipperType = shipperTypeSelector(state, {shipperID})
  const hasAccountCreditCard = hasAccountCreditCardSelector(state)
  const hasInprogressAccountTransfer = paymentAccountID
    ? hasInprogressAccountTransferSelector(state, {paymentAccountID})
    : false
  const anyPaymentAccountIsLocked = anyPaymentAccountIsLockedSelector(state)
  const errors = {}

  if (!hasAccountCreditCard) {
    errors.creditCard = 'A payment method must be provided'
    errors.preventSave = true
  }

  if (!isNonZeroPositiveNumeric(addAmount)) {
    errors.addAmount = 'Amount must be a positive number'
    errors.preventSave = true
  }

  if (
    isNonZeroPositiveNumeric(addAmount) &&
    [PITNEY, VISIBLE_USPS].includes(shipperType) &&
    amountExceedsLimitSelector(state, {amount: Number(addAmount)})
  ) {
    errors.addAmount = 'Amount exceeds purchase limit'
    errors.preventSave = true
  }

  if (hasInprogressAccountTransfer) {
    errors.addAmount = 'Transfer has already started'
    errors.preventSave = true
  }

  if (anyPaymentAccountIsLocked) {
    errors.addAmount = 'Payment account is locked'
    errors.preventSave = true
  }

  return errors
}

export function showIncreasePostageLimitMessageSelector(state) {
  const {addAmount, serverError} = modalFormSelector(state)
  const amountExceedsLimit = amountExceedsLimitSelector(state, {
    amount: Number(addAmount) || 0,
  })

  return (
    amountExceedsLimit ||
    !!(
      serverError &&
      serverError.match(/You have exceeded your daily postage purchase limit/)
    )
  )
}

export async function addPostageToShipper() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const {shipperID, addAmount, paymentAccountID} =
      modalFormSelector(getState())

    const amount = Number(addAmount)

    await addToShipperBalance(shipperID, amount, paymentAccountID)

    showMessageToast(
      `Added ${formatCurrency(
        amount,
        currencySymbolSelector(getState()),
      )} postage to USPS account`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isSaving: false,
    })
  }
}

function PaymentAccountOption({option, ...props}) {
  const paymentAccount = option.entity
  const account = get(paymentAccount, ['activation_response', 'account'])
  const {payment_backend, payment_method_response} = paymentAccount

  return (
    <DefaultListItem option={option} {...props}>
      {payment_backend === PLAID ? (
        <>
          <div>
            <strong className="fs-00 lh-md margin-right-3">
              {account.name}
            </strong>
            <span className="fs-00 lh-md">(ending in {account.mask})</span>
          </div>
          {account.official_name && (
            <div className="op-75 margin-top-3">{account.official_name}</div>
          )}
        </>
      ) : payment_backend === NET_ZERO_PA ? (
        <>
          <div>
            <strong className="fs-00 lh-md margin-right-3">
              {payment_method_response.card_type}
            </strong>
            <span className="fs-00 lh-md">
              (ending in {payment_method_response.last4})
            </span>
          </div>
          <div className="op-75 margin-top-3">
            expires {payment_method_response.expiry_month}/
            {payment_method_response.expiry_year}
          </div>
        </>
      ) : (
        option.display
      )}
    </DefaultListItem>
  )
}

function PostageBalanceModal({form}) {
  const errors = useSelector(errorsSelector)
  const {isEnabled} = useSelector((state) =>
    autoFillPostageConfigSelector(state, {shipperID: form.shipperID}),
  )
  const showIncreasePostageLimitMessage = useSelector((state) =>
    showIncreasePostageLimitMessageSelector(state, {shipperID: form.shipperID}),
  )
  const {credit_card_processing_fee} = useSelector(companySelector)
  const postagePaymentAccountOptions = useSelector(
    postagePaymentAccountOptionsSelector,
  )
  const paymentAccount = useSelector((state) =>
    form.paymentAccountID
      ? paymentAccountSelector(state, {paymentAccountID: form.paymentAccountID})
      : null,
  )

  const [totalCharge, amount, fee, percentage, cap, charge] = useMemo(() => {
    const amount = Number(form.addAmount) || 0
    let percentage = credit_card_processing_fee || 0
    let cap = Infinity
    let charge = 0

    if (paymentAccount) {
      const processing_fee =
        paymentAccount.processing_fee[paymentAccount.payment_type]

      if (processing_fee) {
        percentage = processing_fee.percentage || 0
        cap = processing_fee.cap || Infinity
        charge = processing_fee.charge || 0
      }
    }

    let fee = charge + amount * round(percentage, 4)
    fee = fee > cap ? cap : fee
    const totalCharge = amount + fee

    return [totalCharge, amount, fee, percentage, cap, charge]
  }, [form.addAmount, credit_card_processing_fee, paymentAccount])

  const hasBaseFee = charge > 0
  const hasMaxFee = cap > 0 && cap !== Infinity
  const reachedMaxFee = hasMaxFee && fee === cap

  return (
    <ConfirmModal
      title="Add Funds to Postage Balance"
      confirmText="Add Postage"
      cancelText="Cancel"
      onConfirm={() => addPostageToShipper()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <PostageBalanceWrapper shipperID={form.shipperID}>
        <dd className="bigger postage-add">
          {postagePaymentAccountOptions.length > 1 && (
            <Zelect
              label="Payment Account"
              id="payment_account"
              value={form.paymentAccountID}
              onChange={(option) => {
                updateModalForm({
                  paymentAccountID: option.value || undefined,
                })
              }}
              options={postagePaymentAccountOptions}
              OptionComponent={PaymentAccountOption}
            />
          )}
          <CurrencyInput
            label="Increase your balance:"
            id="add_amount"
            placeholder="0.00"
            value={form.addAmount}
            onChange={(value) =>
              updateModalForm({
                addAmount: value,
                hasChanged_addAmount: true,
              })
            }
            errorMessage={form.hasChanged_addAmount && errors.addAmount}
          />
          {fee > 0 && (
            <div className="margin-top-10 margin-bottom-10">
              <div className="fs-n0 text--md-grey">
                <strong>
                  <Quantity
                    value={percentage * 100}
                    options={{
                      style: 'decimal',
                      maximumFractionDigits: 2,
                    }}
                  />
                  % Processing Fee:
                </strong>{' '}
                <Currency value={amount * percentage} />
              </div>
              {hasBaseFee && (
                <div className="fs-00 text--dk-grey">
                  <strong>Base Fee:</strong> <Currency value={charge} />
                </div>
              )}
              {reachedMaxFee && (
                <div className="fs-00 text--dk-grey">
                  <strong>Max Fee:</strong> <Currency value={cap} />
                </div>
              )}
              {hasBaseFee && hasMaxFee && (
                <div className="fs-00 text--dk-grey">
                  <strong>Total Fee:</strong> <Currency value={fee} />
                </div>
              )}
              <div className="fs-00 text--dk-grey">
                <strong>Total Charge:</strong> <Currency value={totalCharge} />
              </div>
            </div>
          )}
          {showIncreasePostageLimitMessage && (
            <small className="error error-message">
              {'Please contact Ordoro support to increase this limit. '}
              <a
                href="https://support.ordoro.com/how-do-i-increase-my-daily-postage-purchase-limit/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more.
              </a>
            </small>
          )}
        </dd>
        <dd
          className={className`alert ${
            isEnabled ? 'alert--success' : 'alert--neutral'
          } inline-block`}
        >
          {isEnabled ? (
            <strong className="fs-n0 lh-md v-align-middle margin-right-10">
              Postage auto-fill is enabled
            </strong>
          ) : (
            <span className="fs-n0 lh-md v-align-middle margin-right-10">
              Postage auto-fill is disabled
            </span>
          )}
          <ButtonPrimary
            isOutlined
            size="xx-sm"
            onClick={() => showAutoFillPostageModal(form.shipperID)}
          >
            Manage
          </ButtonPrimary>
        </dd>
      </PostageBalanceWrapper>
    </ConfirmModal>
  )
}

export default onlyIfForm(PostageBalanceModal, modalFormSelector)
