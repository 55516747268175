import {useForm, useSelector} from '../../../../store.js'
import LabelViewForm from '../../../iverson/components/LabelViewForm/index.js'
import {
  MODAL_FORM,
  quickShipHasLabelSelector,
} from './quickShipModalFunctions.js'

export default function PrintLabelPanel() {
  const {orderNumber} = useForm(MODAL_FORM)
  const hasLabel = useSelector(quickShipHasLabelSelector)

  if (!orderNumber) {
    return null
  }

  return hasLabel ? (
    <>
      <p className="fs-02 lh-md margin-bottom-15">
        <strong>Last step — print out your label!</strong>
      </p>
      <div className="row wrap--label-panel-in-modal">
        <div className="medium-12 columns">
          <LabelViewForm
            orderNumbers={[orderNumber]}
            labelType="shipping"
            showSingleOrderNumber
            fromModal
            docTypes={['label']}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="alert alert--warning align-center">
      <p className="fs-01 lh-md margin-bottom-0">
        <strong>There is no label to print.</strong>
      </p>
    </div>
  )
}
