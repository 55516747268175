import {STARTER_PLAN} from '../common/constants/Plans.js'
import {
  deactivatedDateSelector,
  hasCreditCardSelector,
  isVendorConnectSelector,
  planSelector,
} from '../data/company.js'
import {hasChargeableShipperAccountSelector} from './shippers.js'

export function needsCreditCardSelector(state) {
  const hasCreditCard = hasCreditCardSelector(state)
  const plan = planSelector(state)
  const deactivated = deactivatedDateSelector(state)
  const hasChargeableShipperAccount = hasChargeableShipperAccountSelector(state)
  const isVendorConnect = isVendorConnectSelector(state)

  return (
    (!hasCreditCard || !!deactivated) &&
    ((plan !== STARTER_PLAN && !isVendorConnect) || hasChargeableShipperAccount)
  )
}
