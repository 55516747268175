import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import chunk from 'lodash/chunk.js'
import uniq from 'lodash/uniq.js'

import {locationSelector} from '../../redux/selectors/ui/location.js'
import handleListSelection from '../../common/handleListSelection.js'
import uranus from '../../common/uranus.js'
import createCSVFile from '../../common/createCSVFile.js'
import {getState, updateForm, removeForm} from '../../store.js'
import {
  FINANCIAL_PANEL,
  CUSTOMER_PANEL,
  INVENTORY_PANEL,
  PRODUCT_PANEL,
  SHIPPING_PANEL,
  DATE_TYPE_OPTIONS,
  DEFAULT_DATE_TYPE,
  ANALYTICS_URI_COMPONENT,
} from '../../common/constants/Analytics.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {navigate} from '../../common/location.js'
import {ensureProductsLoaded} from '../../data/products.js'
import {cartsSortedByNameSelector} from '../../data/carts.js'
import {suppliersSortedByNameSelector} from '../../data/suppliers.js'
import {
  ANALYTICS,
  analyticsFormSelector,
  analyticsPanelSelector,
  analyticsQuerySelector,
  exportToCSVParamsSelector,
  missingSKUsSelector,
  queryParamsSelector,
  cartFiltersSelector,
  supplierFiltersSelector,
} from './analyticsSelectors.js'
import {companyMightBeTooNewSelector} from '../DashboardPage/quickAnalyticsFunctions.js'

export function setAnalyticsForm() {
  return {
    formName: ANALYTICS,
    initialForm: {
      isLoading: false,
      isProductSearchLoading: false,
      lastSupplierIDSelected: null,
      lastCartIDSelected: null,
      lastSuccessfulDataSync: null,
      analyticsReady: true,
    },
  }
}

export function updateAnalyticsForm(...args) {
  updateForm(ANALYTICS, ...args)
}

export function removeAnalyticsForm() {
  removeForm(ANALYTICS)
}

export function navigateToAnalytics(panel = '', query = {}) {
  return navigate([ANALYTICS_URI_COMPONENT, panel], query)
}

export function navigateAnalytics(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (pathComponents[0] !== ANALYTICS_URI_COMPONENT) {
    return
  } else {
    query = {...query, ...updates}
  }

  return navigate(pathComponents, query)
}

export function updateArray(array, key) {
  array = array || []

  if (!Array.isArray(array)) {
    array = [array]
  }

  if (array.length === 0) {
    array = undefined
  } else {
    array = uniq(array)
  }

  return {
    [key]: array,
  }
}

export function setCartFilters(cartIDs) {
  return navigateAnalytics(updateArray(cartIDs, 'cartFilters'))
}

export function setCartFilter(id, isSelected) {
  const {cartFilters} = analyticsQuerySelector(getState())

  const cartIDs = cartFilters.filter((cartID) => cartID !== id)

  if (isSelected) {
    cartIDs.push(id)
  }

  return setCartFilters(cartIDs)
}

export async function handleAnalyticsCartListSelection(
  cartID,
  isSelected,
  isShiftKey,
) {
  const selectedCartIDs = cartFiltersSelector(getState())
  const allCartIDs = cartsSortedByNameSelector(getState()).map(({id}) => id)
  const {lastCartIDSelected} = analyticsFormSelector(getState())

  setCartFilters(
    handleListSelection({
      value: cartID,
      isSelected,
      isShiftKey,
      selected: selectedCartIDs,
      list: allCartIDs,
      previousValue: lastCartIDSelected,
    }),
  )

  updateAnalyticsForm({lastCartIDSelected: cartID})
}

export function setSupplierFilters(supplierIDs) {
  return navigateAnalytics(updateArray(supplierIDs, 'supplierFilters'))
}

export function setSupplierFilter(id, isSelected) {
  const {supplierFilters} = analyticsQuerySelector(getState())

  const supplierIDs = supplierFilters.filter((supplierID) => supplierID !== id)

  if (isSelected) {
    supplierIDs.push(id)
  }

  return setSupplierFilters(supplierIDs)
}

export async function handleAnalyticsSupplierListSelection(
  supplierID,
  isSelected,
  isShiftKey,
) {
  const selectedSupplierIDs = supplierFiltersSelector(getState())
  const allSupplierIDs = suppliersSortedByNameSelector(getState()).map(
    ({id}) => id,
  )
  const {lastSupplierIDSelected} = analyticsFormSelector(getState())

  setSupplierFilters(
    handleListSelection({
      value: supplierID,
      isSelected,
      isShiftKey,
      selected: selectedSupplierIDs,
      list: allSupplierIDs,
      previousValue: lastSupplierIDSelected,
    }),
  )

  updateAnalyticsForm({lastSupplierIDSelected: supplierID})
}

export function setProductFilters(skus) {
  return navigateAnalytics(updateArray(skus, 'productFilters'))
}

export function setProductFilter(sku, isSelected) {
  const {productFilters} = analyticsQuerySelector(getState())

  const skus = productFilters.filter((s) => s !== sku)

  if (isSelected) {
    skus.push(sku)
  }

  return setProductFilters(skus)
}

export function clearProductsFilter() {
  setProductFilters([])
}

export function updateDate(date, key) {
  date = new Date(date)

  return {[key]: isValid(date) ? date.toISOString() : undefined}
}

export function setDateRangeFilters(startDate, endDate) {
  return navigateAnalytics({
    ...updateDate(startDate, 'startDate'),
    ...updateDate(endDate, 'endDate'),
  })
}

export function updateDateType(dateType) {
  if (!DATE_TYPE_OPTIONS.find(({value}) => dateType === value)) {
    dateType = DEFAULT_DATE_TYPE
  }

  if (dateType === DEFAULT_DATE_TYPE) {
    dateType = undefined
  }

  return {
    dateType,
  }
}

export function setDateType(dateType) {
  return navigateAnalytics(updateDateType(dateType))
}

export async function getResource(resource, params) {
  // copy params because we are changing them
  params = {...params}

  // where to put all the rows
  let data = []

  // if user wants a specific amount then set a hard limit at that point
  // otherwise we set infinity until we get a real count
  let hardLimit = params.limit || Infinity

  // only request if we haven't gotten all we want
  while (data.length < hardLimit) {
    // actually get the things
    const {json} = await uranus.get(resource, params)

    // hardLimit is the max the server will give
    // or what the user wants which ever is less
    hardLimit = Math.min(hardLimit, json.count)

    if (json.offset + json.data.length > hardLimit) {
      // server gave more than we want
      data = [...data, ...json.data.slice(0, hardLimit - json.offset)]
    } else {
      // we want all the server gave
      data = [...data, ...json.data]
    }

    // move the offset from the server limit
    params.offset = json.offset + json.limit

    // set the limit so that we only get what we want
    params.limit = Math.min(
      json.limit,
      hardLimit - (json.offset + json.data.length),
    )
  }

  return data
}

export async function getResourceIntoObject(obj, key, ...args) {
  obj[key] = await getResource(...args)
}

let refreshAnalyticsToken
export async function refreshAnalytics() {
  const token = (refreshAnalyticsToken = {})
  const state = getState()
  const companyMightBeTooNew = companyMightBeTooNewSelector(getState())

  updateAnalyticsForm({isLoading: true, analyticsReady: !companyMightBeTooNew})

  try {
    const {json} = await uranus.get('/meta')

    updateAnalyticsForm({
      lastSuccessfulDataSync: json.last_successful_data_sync,
    })

    const panel = analyticsPanelSelector(state)
    const params = queryParamsSelector(state)

    // FIXME useNewAnalyticsPart2Selector move or remove this code to a better place after chop
    if (params.start_date) {
      params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
    }
    if (params.end_date) {
      params.end_date = format(new Date(params.end_date), 'yyyy-MM-dd')
    }
    if (params.date_type) {
      params.date_type = {
        order_date: 'ordered_date',
        ship_date: 'shipped_date',
        tracking_created_date: 'tracking_created_date',
      }[params.date_type]
    }

    delete params.sku

    const data = {}
    if (panel === FINANCIAL_PANEL) {
      await Promise.all([
        getResourceIntoObject(
          data,
          'order_financials__order_placed_date',
          '/v2/order_financials',
          {...params, group_by: ['order_placed_date']},
        ),
        getResourceIntoObject(
          data,
          'order_financials__order_status',
          '/v2/order_financials',
          {...params, group_by: ['order_status']},
        ),
        getResourceIntoObject(
          data,
          'order_financials__ship_to_state',
          '/v2/order_financials',
          {...params, group_by: ['ship_to_state']},
        ),
        getResourceIntoObject(
          data,
          'order_financials__cart_id',
          '/v2/order_financials',
          {...params, group_by: ['cart_id']},
        ),
        getResourceIntoObject(
          data,
          'order_item_financials__product_supplier_id',
          '/v2/order_item_financials',
          {...params, group_by: ['product_supplier_id']},
        ),
      ])
    } else if (panel === CUSTOMER_PANEL) {
      await getResourceIntoObject(
        data,
        'order_financials__top_customers',
        '/v2/order_financials',
        {
          ...params,
          group_by: ['bill_to_email', 'bill_to_name'],
          order_by: ['-total_product_sales'],
          limit: 20,
        },
      )
    } else if (panel === INVENTORY_PANEL) {
      await Promise.all([
        getResourceIntoObject(
          data,
          'product_statistics__top_inventory',
          '/v2/product_statistics',
          {
            ...params,
            group_by: ['sku', 'product_name'],
            order_by: ['-value'],
            limit: 20,
          },
        ),
        getResourceIntoObject(
          data,
          'product_statistics__all',
          '/v2/product_statistics',
          params,
        ),
      ])
    } else if (panel === PRODUCT_PANEL) {
      await Promise.all([
        getResourceIntoObject(
          data,
          'order_item_financials__top_sellers',
          '/v2/order_item_financials',
          {
            ...params,
            group_by: ['sku', 'product_name'],
            order_by: ['-total_product_sales'],
            limit: 20,
          },
        ),
        getResourceIntoObject(
          data,
          'order_item_financials__all',
          '/v2/order_item_financials',
          params,
        ),
      ])
    } else if (panel === SHIPPING_PANEL) {
      await Promise.all([
        getResourceIntoObject(
          data,
          'order_financials__shipping',
          '/v2/order_financials',
          {
            ...params,
            group_by: ['shipping_vendor', 'shipping_method'],
            order_by: ['-total_label_costs'],
          },
        ),
        getResourceIntoObject(
          data,
          'order_financials__fulfillment_latency',
          '/v2/order_financials',
          {
            ...params,
            group_by: ['fulfillment_latency'],
            order_by: ['-total_orders'],
          },
        ),
      ])
    }

    if (token !== refreshAnalyticsToken) {
      return
    }

    const analyticsReady = !(
      !Object.values(data).reduce((prev, d) => prev || d.length > 0, false) &&
      companyMightBeTooNew
    )

    if (!analyticsReady) {
      setTimeout(() => refreshAnalytics(), 60 * 1000)
    }

    updateAnalyticsForm({...data, isLoading: false, analyticsReady})
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error requesting analytics data.',
        details: err.message,
      },
      err,
    )
    updateAnalyticsForm({isLoading: false})
  }
}

let refreshProductSearchToken
export async function refreshProductSearch() {
  const token = (refreshProductSearchToken = {})
  const state = getState()

  const panel = analyticsPanelSelector(state)
  if (panel !== PRODUCT_PANEL) {
    return
  }

  updateAnalyticsForm({isProductSearchLoading: true})

  try {
    const params = queryParamsSelector(state)

    if (params.start_date) {
      params.start_date = format(new Date(params.start_date), 'yyyy-MM-dd')
    }
    if (params.end_date) {
      params.end_date = format(new Date(params.end_date), 'yyyy-MM-dd')
    }

    const skus = params.sku
    delete params.sku

    const allData = await Promise.all(
      chunk(skus, 100).map((sku) =>
        getResource('/v2/order_item_financials', {
          ...params,
          sku,
          group_by: ['sku', 'product_name'],
          order_by: ['-total_product_sales'],
        }),
      ),
    )

    const data = {
      order_item_financials__product_search: allData.reduce(
        (prev, d) => [...prev, ...d],
        [],
      ),
    }

    if (token !== refreshProductSearchToken) {
      return
    }

    updateAnalyticsForm({...data, isProductSearchLoading: false})

    const missingSKUs = missingSKUsSelector(getState())

    await ensureProductsLoaded(missingSKUs)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error requesting product search analytics data.',
        details: err.message,
      },
      err,
    )
    updateAnalyticsForm({isProductSearchLoading: false})
  }
}

export function exportToCSV(type) {
  const state = getState()

  try {
    const params = exportToCSVParamsSelector(state, {type})

    createCSVFile(params.headers, params.data, params.fileName)

    showMessageToast(params.toastMessage)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error creating CSV file.',
        details: err.message,
      },
      err,
    )
  }
}
