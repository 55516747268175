import PropTypes from 'prop-types'
import classNames from 'classnames'

import {DateShape} from '../../common/PropTypes.js'
import ExportCSVButton from './ExportCSVButton.js'
import MoneyOverTimeGraph from './MoneyOverTimeGraph.js'

export default function MoneyOverTimePane({
  isLoading,
  title,
  data,
  startDate,
  endDate,
  onExport,
}) {
  return (
    <div className="row">
      <div className="medium-12 columns">
        <div
          className={classNames('panel panel--analytics clearfix', {
            loading: isLoading,
          })}
        >
          <div className="panel__header panel__header--analytics flex--justify">
            <h3 className="subheader subheader--analytics">{title}</h3>
            {data && <ExportCSVButton onExport={onExport} />}
          </div>
          <MoneyOverTimeGraph
            data={data}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </div>
  )
}

MoneyOverTimePane.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  startDate: DateShape,
  endDate: DateShape,
  onExport: PropTypes.func.isRequired,
}
