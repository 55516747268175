import {useMemo} from 'react'

import {
  dimensionsSelector,
  labelShipperTypeSelector,
  makeBoxShapeNameSelector,
  showDimensionsSelector,
} from '../../../../../../../data/labelInfos/index.js'
import {showEditOrderDimensionsModal} from '../../../../../../OrderListPage/Modals/EditOrderDimensionsModal.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'
import {useSelector} from '../../../../../../../store.js'

export default function CompactDimensions() {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const {length, width, height} = useSelector((state) =>
    dimensionsSelector(state, {
      labelInfoID,
    }),
  )

  const boxShapeNameSelector = useMemo(() => makeBoxShapeNameSelector(), [])

  const showDimensions = useSelector((state) =>
    showDimensionsSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const boxShapeName = useSelector((state) =>
    boxShapeNameSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )

  if (!showDimensions) {
    return <span>{boxShapeName}</span>
  }

  return (
    <button
      className="btn btn--link darker meta-orderlistrow-compact-button-edit-dimensions"
      type="button"
      onClick={() => showEditOrderDimensionsModal([labelInfoID])}
    >
      <span>
        {length} x {width} x {height} in
      </span>
    </button>
  )
}
