import Weight from '../../../../../../../common/components/Weight.js'
import {
  weightSelector,
  isInvalidWeightSelector,
  showDimensionsSelector,
  orderNumberFromLabelConfigSelector,
  labelShipperTypeSelector,
} from '../../../../../../../data/labelInfos/index.js'
import {showEditOrderWeightModal} from '../../../../../../OrderListPage/Modals/EditOrderWeightModal.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'
import {useSelector} from '../../../../../../../store.js'

export default function CompactWeight() {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const orderNumber = useSelector((state) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
  const showDimensions = useSelector((state) =>
    showDimensionsSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const weight = useSelector((state) => weightSelector(state, {labelInfoID}))
  const isInvalidWeight = useSelector((state) =>
    isInvalidWeightSelector(state, {labelInfoID}),
  )

  if (!showDimensions) {
    return null
  }
  return (
    <button
      className="btn btn--link darker meta-orderlistrow-compact-button-edit-weight"
      onClick={() => showEditOrderWeightModal([orderNumber])}
    >
      <span className={isInvalidWeight ? 'wrap--error' : undefined}>
        <Weight value={weight} />
      </span>
    </button>
  )
}
