import PropTypes from 'prop-types'

export default function OrderNotFound({orderNumber}) {
  return (
    <dl className="list--empty-content">
      <dt className="fs-02 margin-bottom-10">
        <span>Order ‘{orderNumber}’ could not be found</span>
      </dt>
    </dl>
  )
}

OrderNotFound.propTypes = {
  orderNumber: PropTypes.string.isRequired,
}
