import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'

export function apiKeysSelector(state) {
  return state.data.apiKeys
}

export function apiKeySelector(state, {apiKeyID}) {
  return get(apiKeysSelector(state), apiKeyID)
}

export const apiKeysByNameSelector = createSelector(
  apiKeysSelector,
  (apiKeys) => keyBy(apiKeys, 'name'),
)

export const apiKeysSortedByNameSelector = createSelector(
  apiKeysByNameSelector,
  (apiKeysByName) =>
    Object.keys(apiKeysByName)
      .sort()
      .map((name) => apiKeysByName[name])
      .filter((apiKey) => !apiKey.archive_date),
)
