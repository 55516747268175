import omitBy from 'lodash/omitBy.js'
import mergeWith from 'lodash/mergeWith.js'
import get from 'lodash/get.js'
import omit from 'lodash/omit.js'
import isEqual from 'lodash/isEqual.js'
import {createSelector} from 'reselect'
import addHours from 'date-fns/addHours'
import fpAssign from 'lodash/fp/assign.js'

import {logError} from '../../common/error.js'
import {
  OT_V3_ORDER,
  OT_RETURN_ORDER,
} from '../../common/constants/OrderTypes.js'
import {
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  PITNEY_CBDS,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
} from '../../common/constants/ShipperNames.js'
import {FEDEX_SHIPPER_DEFAULT_HUB} from '../../common/constants/LabelConfig.js'
import {isEmptyValue} from '../../common/utils.js'
import {parseNonZeroPositiveNumber} from '../../common/parseNumbers.js'
import getShipDate from '../../common/getShipDate.js'
import {orderLinkSelector} from '../orders.js'
import {
  BULK_LABEL_ID,
  labelConfigSelector,
  labelShipperSelector,
  labelShipperTypeSelector,
  labelTypeSelector,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  orderTypeSelector,
  orderNumberFromLabelConfigSelector,
  sortRates,
  shippingMethodToRate,
  updateLabelInfo,
  saveLabelInfo,
  validLabelInfoIDsSelector,
  startUpdatingRates,
  localRatesSelector,
  ratesSelector,
  shippingMethodSelector,
  rateKeySelector,
  shippingMethodDisplayNameSelector,
  labelInfoSelector,
  getInsuranceParams,
  labelShippersSelector,
  getBoxShapeOptions,
  boxShapeSelector,
  labelShipperIDSelector,
  updateLabelRate,
  labelUseRateShoppingSelector,
} from '../labelInfos/index.js'
import {
  getFedExPaymentParams,
  getFedExDutiesParams,
  getUpsPaymentParams,
  getPackagesParam,
  getCustomsParams,
} from './labelRequest.js'
import {showGlobalError} from '../../ordoro/GlobalErrorMessage.js'
import {getState} from '../../store.js'
import apiverson from '../../common/apiverson.js'
import {getShipperName, usesDiscountedRatesSelector} from '../shippers.js'
import {useFedExAuthSelector} from '../company.js'
import ShipperOptions from '../shipperOptions.js'

export function getReturnLabelParams(labelType) {
  return labelType === 'return' ? {return: true} : {}
}

function sharedParams(
  config,
  shipper,
  labelType,
  shipFromAddress,
  shipToAddress,
) {
  const shipperType = get(shipper, 'vendor')
  const insuranceParams = [
    PITNEY,
    PITNEY_MERCHANT,
    PITNEY_PRESORT,
    VISIBLE_USPS,
  ].includes(shipperType)
    ? {}
    : getInsuranceParams(config, shipperType)

  return {
    shipper_id: get(shipper, 'id'),
    delivery_confirmation: config[`${shipperType}__delivery_confirmation`],
    ship_from: omit(shipFromAddress, ['validation']),
    ship_to: omit(shipToAddress, ['validation']),
    ...insuranceParams,
    ...getReturnLabelParams(labelType),
  }
}

function upsParams(config) {
  return {
    saturday_delivery: config.saturday_delivery,
    direct_delivery: config.direct_delivery,
    recipient_address_is_residential: config.recipient_address_is_residential,
    package_bill_type: config.ups__package_bill_type,
    delivery_confirmation: config.can_have_delivery_confirmation
      ? config.ups__delivery_confirmation
      : null,
    ...getUpsPaymentParams(config),
    ...getPackagesParam(
      config,
      UPS,
      get(config, 'packages', []).map((parcel) => {
        const dryIceWeight = parseNonZeroPositiveNumber(parcel.dry_ice_weight)

        return omitBy(
          {
            additional_handling: parcel.additional_handling || null,
            declared_value: parseNonZeroPositiveNumber(
              parcel.ups__declared_value,
            ),
            dry_ice_weight: dryIceWeight,
            dry_ice_regulation_set: dryIceWeight
              ? parcel.dry_ice_regulation_set
              : null,
          },
          isEmptyValue,
        )
      }),
    ),
  }
}

function fedexParams(config, shipper, fedExCanHaveCustomsOnRateRequest) {
  return {
    ship_date: getShipDate(config.ship_date),
    recipient_address_is_residential: config.recipient_address_is_residential,
    delivery_instructions: config.delivery_instructions,
    saturday_delivery: config.saturday_delivery,
    // temp_use_fedex_auth
    ...(get(shipper, 'vendor_config.child_key')
      ? {hold_at_location_id: config[`${FEDEX}__hold_at_location_id`]}
      : {hold_at_location: config.hold_at_location}),
    smart_post_hub:
      config.fedex__smart_post_hub === FEDEX_SHIPPER_DEFAULT_HUB
        ? get(shipper, 'vendor_config.smartpost_hub') || ''
        : config.fedex__smart_post_hub,
    smart_post_indicia: config.fedex__smart_post_indicia,
    smart_post_ancillary_endorsement: config.fedex__ancillary_endorsement,
    alcohol_shipment_license: config.fedex__alcohol_shipment_license,
    dangerous_goods_option: config.fedex__dangerous_goods_option,
    delivery_confirmation: config.fedex__delivery_confirmation,
    pharmacy_delivery: config.pharmacy_delivery,
    priority_alert: get(shipper, 'vendor_config.has_priority_alert')
      ? config.fedex__priority_alert
      : null,
    ...(fedExCanHaveCustomsOnRateRequest
      ? getCustomsParams(config, FEDEX)
      : null),
    ...getFedExPaymentParams(config),
    ...getFedExDutiesParams(config),
    ...getPackagesParam(
      config,
      FEDEX,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value: parseNonZeroPositiveNumber(
              parcel.fedex__declared_value,
            ),
            dry_ice_weight: parseNonZeroPositiveNumber(parcel.dry_ice_weight),
            sub_package_type: config.fedex__sub_package_type,
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

function endiciaParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    hold_for_pickup: config.hold_for_pickup,
    contents_type: config.endicia__contents_type,
    nondelivery_option: config.endicia__nondelivery_option,
    mailing_zip_code: config.mailing_zip_code,
    ...getPackagesParam(config, ENDICIA),
  }
}

function pitneyParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    non_delivery: config.pitney__nondelivery_option,
    shipping_method: 'ALL',
    mailing_zip_code: config.mailing_zip_code,
    ...getPackagesParam(config, PITNEY),
  }
}

function pitneyMerchantParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    non_delivery: config.pitney_merchant__nondelivery_option,
    shipping_method: 'ALL',
    mailing_zip_code: config.mailing_zip_code,
    ...getPackagesParam(config, PITNEY_MERCHANT),
  }
}

function pitneyPresortParams() {
  // Pitney Presort doesn't get rates (see localRatesSelector())
  return {}
}

function canadaPostParams(config) {
  return {
    reason_for_export: config.canada_post__reason_for_export,
    nondelivery_option: config.canada_post__nondelivery_option,
    service_option: config.service_option,
    ...getPackagesParam(config, CANADA_POST),
  }
}

function pitneyCBDSParams(config) {
  return {
    shipping_method: 'ALL',
    ship_date: getShipDate(config.ship_date),
    ship_to: undefined,
    ship_from: undefined,
    ...getPackagesParam(config, PITNEY_CBDS),
    ...getCustomsParams(config, PITNEY_CBDS),
  }
}

function dhlParams(config) {
  return {
    nonstandard_day: config.dhl__nonstandard_day,
    nonstandard_contents: config.dhl__nonstandard_contents,
    signature_service: config.dhl__signature_service,
    is_dutiable: !!config.is_dutiable,
    ...getPackagesParam(
      config,
      DHL,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value: parseNonZeroPositiveNumber(
              parcel.dhl__declared_value,
            ),
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

function dhlEcommerceParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    dangerous_goods: config[`${DHL_ECOMMERCE}__dangerous_goods`],
    delivery_confirmation: config[`${DHL_ECOMMERCE}__delivery_confirmation`],
    reference_number: config.reference_number,
    ...getPackagesParam(
      config,
      DHL_ECOMMERCE,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            description: parcel.description || '',
            declared_value: parseNonZeroPositiveNumber(
              parcel[`${DHL_ECOMMERCE}__declared_value`],
            ),
          },
          isEmptyValue,
        ),
      ),
    ),
    ...getCustomsParams(config, DHL_ECOMMERCE),
  }
}

function amazonParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    carrier_pickup: config.carrier_pickup,
    ship_to: undefined,
    ...getPackagesParam(config, AMAZON_SFP),
  }
}

function amazonShipperParams(config) {
  return {
    recipient_address_is_residential: config.recipient_address_is_residential,
    ...(config.pickup_start_date && config.pickup_end_date
      ? {
          expected_service_offering: {
            start_date: config.pickup_start_date,
            end_date: config.pickup_end_date,
          },
        }
      : undefined),
    ship_to: undefined,
    ...getPackagesParam(
      config,
      AMAZON_SHIPPER,
      get(config, 'packages', []).map((parcel) =>
        omitBy(
          {
            declared_value: parseNonZeroPositiveNumber(
              parcel.amazon_shipper__declared_value,
            ),
          },
          isEmptyValue,
        ),
      ),
    ),
  }
}

function australiaPostParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    reference_number: config.reference_number,
    reason_for_export: config.australia_post__reason_for_export,
    reason_for_export_explanation: config.reason_for_export_explanation,
    ...getPackagesParam(config, AUSTRALIA_POST),
    ...getCustomsParams(config, AUSTRALIA_POST),
  }
}

function newgisticsParams(config) {
  return {
    non_delivery: config.newgistics__nondelivery_option,
    enable_notifications: config.enable_notifications,
    dangerous_goods_option: config.newgistics__dangerous_goods,
    ...getPackagesParam(config, NEWGISTICS),
  }
}

function sendleParams(config) {
  return {
    ...getPackagesParam(
      config,
      SENDLE,
      get(config, 'packages', []).map((parcel) => ({
        description: parcel.description || '',
      })),
    ),
  }
}

function visibleUSPSParams(config) {
  return {
    ship_date: getShipDate(config.ship_date),
    ...getPackagesParam(config, VISIBLE_USPS),
  }
}

function xDeliveryParams() {
  // X Delivery doesn't get rates (see localRatesSelector())
  return {}
}

function shipperParams(config, shipper, fedExCanHaveCustomsOnRateRequest) {
  const shipperType = get(shipper, 'vendor')

  if (shipperType === UPS) {
    return upsParams(config)
  }
  if (shipperType === FEDEX) {
    return fedexParams(config, shipper, fedExCanHaveCustomsOnRateRequest)
  }
  if (shipperType === ENDICIA) {
    return endiciaParams(config)
  }
  if (shipperType === PITNEY) {
    return pitneyParams(config)
  }
  if (shipperType === PITNEY_MERCHANT) {
    return pitneyMerchantParams(config)
  }
  if (shipperType === PITNEY_PRESORT) {
    return pitneyPresortParams(config)
  }
  if (shipperType === CANADA_POST) {
    return canadaPostParams(config)
  }
  if (shipperType === PITNEY_CBDS) {
    return pitneyCBDSParams(config)
  }
  if (shipperType === DHL) {
    return dhlParams(config)
  }
  if (shipperType === DHL_ECOMMERCE) {
    return dhlEcommerceParams(config)
  }
  if (shipperType === AMAZON_SFP) {
    return amazonParams(config)
  }
  if (shipperType === AMAZON_SHIPPER) {
    return amazonShipperParams(config)
  }
  if (shipperType === AUSTRALIA_POST) {
    return australiaPostParams(config)
  }
  if (shipperType === NEWGISTICS) {
    return newgisticsParams(config)
  }
  if (shipperType === SENDLE) {
    return sendleParams(config)
  }
  if (shipperType === VISIBLE_USPS) {
    return visibleUSPSParams(config)
  }
  if (shipperType === X_DELIVERY) {
    return xDeliveryParams(config)
  }
  return {}
}

export function rateRequestParams(
  config,
  shipper,
  labelType,
  shipFromAddress,
  shipToAddress,
  fedExCanHaveCustomsOnRateRequest,
) {
  const params = {
    ...sharedParams(config, shipper, labelType, shipFromAddress, shipToAddress),
    ...shipperParams(config, shipper, fedExCanHaveCustomsOnRateRequest),
  }
  return omitBy(params, isEmptyValue)
}

function fedExCanHaveCustomsOnRateRequestSelector(state, {labelInfoID}) {
  const shipper = labelShipperSelector(state, {labelInfoID})
  const useFedExAuth = useFedExAuthSelector(state)

  return (
    useFedExAuth &&
    shipper &&
    shipper.vendor === FEDEX &&
    !!shipper.vendor_config.child_key
  )
}

export const rateRequestSelector = createSelector(
  labelConfigSelector,
  labelShipperSelector,
  labelTypeSelector,
  labelShipFromAddressSelector,
  labelShipToAddressSelector,
  fedExCanHaveCustomsOnRateRequestSelector,
  (
    config,
    shipper,
    labelType,
    shipFromAddress,
    shipToAddress,
    fedExCanHaveCustomsOnRateRequest,
  ) =>
    rateRequestParams(
      config,
      shipper,
      labelType,
      shipFromAddress,
      shipToAddress,
      fedExCanHaveCustomsOnRateRequest,
    ),
)

export function mergeLabelConfigWithBulkConfig(labelConfig, bulkConfig) {
  return mergeWith({}, labelConfig, bulkConfig, (objValue, srcValue) => {
    if (srcValue === null) {
      return objValue
    }
  })
}

export function bulkRateRequestSelector(state, {labelInfoID}) {
  const config = labelConfigSelector(state, {labelInfoID})
  const labelType = labelTypeSelector(state, {labelInfoID})
  const shipFromAddress = labelShipFromAddressSelector(state, {labelInfoID})
  const shipToAddress = labelShipToAddressSelector(state, {labelInfoID})
  const bulkConfig = labelConfigSelector(state, {labelInfoID: BULK_LABEL_ID})
  const bulkShipper = labelShipperSelector(state, {
    labelInfoID: BULK_LABEL_ID,
  })
  const fedExCanHaveCustomsOnRateRequest =
    fedExCanHaveCustomsOnRateRequestSelector(state, {labelInfoID})

  const mergedConfig = mergeLabelConfigWithBulkConfig(config, bulkConfig)

  return rateRequestParams(
    mergedConfig,
    bulkShipper,
    labelType,
    shipFromAddress,
    shipToAddress,
    fedExCanHaveCustomsOnRateRequest,
  )
}

export function rateRequestURLSelector(
  state,
  {labelInfoID, shipperType, fromBulk = false},
) {
  shipperType = shipperType || labelShipperTypeSelector(state, {labelInfoID})
  const orderType = orderTypeSelector(state, {labelInfoID})
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
    fromBulk,
  })

  let baseURL
  if (orderType === OT_V3_ORDER) {
    const orderNumber = orderNumberFromLabelConfigSelector(state, {
      labelInfoID,
    })
    baseURL = orderLinkSelector(state, {orderNumber})
  }
  if (orderType === OT_RETURN_ORDER) {
    baseURL = '/return_order'
  }

  if (!baseURL) {
    throw new Error('Label config has no rate capabilities')
  }

  if (labelUseRateShopping) {
    return `${baseURL}/rate`
  }

  return `${baseURL}/rate/${encodeURIComponent(
    shipperType === PITNEY_MERCHANT ? PITNEY : shipperType,
  )}`
}

export function normalizeRateShape({labelInfoID, rates}) {
  const shippers = labelShippersSelector(getState(), {labelInfoID})
  const labelType = labelTypeSelector(getState(), {labelInfoID})
  const orderType = orderTypeSelector(getState(), {labelInfoID})
  const config = labelConfigSelector(getState(), {labelInfoID})
  const labelUseRateShopping =
    labelUseRateShoppingSelector(getState(), {labelInfoID}) &&
    labelInfoID !== BULK_LABEL_ID

  const normalizedRates = []
  for (const shipper of shippers) {
    const shipperType = shipper.vendor
    const shipperOptions = ShipperOptions[shipperType]
    const hiddenShippingMethods = get(shipper, 'hidden_shipping_methods') || []
    const useServiceToken = [AMAZON_SHIPPER].includes(shipperType)
    const usesDiscountedRates = usesDiscountedRatesSelector(getState(), {
      shipperID: shipper.id,
    })
    const boxShapeOptions = getBoxShapeOptions(
      config,
      shipperType,
      shipperOptions,
      orderType,
      labelType,
    )

    for (const rate of rates) {
      if (labelUseRateShopping && rate.shipper_id !== shipper.id) {
        continue
      }

      if (rate.error_message) {
        normalizedRates.push({...rate, shipper_name: getShipperName(shipper)})

        continue
      }

      const shippingMethodOption = shipperOptions.shipping_method.find(
        (option) => [option.value, option.display].includes(rate.service_type),
      )
      const boxShapeOption = boxShapeOptions.find(
        (option) => rate.box_type === option.value,
      )

      const shape = {
        service: shippingMethodOption
          ? (usesDiscountedRates && shippingMethodOption.discounted_display) ||
            shippingMethodOption.display
          : rate.service_type,
        key: useServiceToken
          ? rate.service_token
          : shippingMethodOption
            ? shippingMethodOption.value
            : rate.service_type,
        expires: rate.service_token
          ? addHours(new Date(), 1).toISOString()
          : null,
        service_type: shippingMethodOption
          ? shippingMethodOption.value
          : rate.service_type,
        box_type: rate.box_type,
        box_shape_name: boxShapeOption ? boxShapeOption.display : rate.box_type,
        estimated_delivery_date: rate.estimated_delivery_date,
        estimated_delivery_days: rate.estimated_delivery_days,
        cost: rate.rate || rate.cost,
        service_token: rate.service_token,
        shipment_id: rate.shipment_id,
        carrier: rate.carrier,
        vendor: rate.vendor || shipperType,
        shipper_id: rate.shipper_id || shipper.id,
        shipper_name: getShipperName(shipper),
      }

      if (hiddenShippingMethods.includes(shape.service_type)) {
        continue
      }

      normalizedRates.push(shape)
    }

    // add non-rated shipping methods to the list
    if (
      NEWGISTICS === shipperType ||
      (DHL_ECOMMERCE === shipperType && labelType !== 'return')
    ) {
      shipperOptions.shipping_method
        // include only `no_rates` shipping methods
        .filter(({no_rates}) => no_rates)
        // remove hidden shipping methods
        .filter(({value}) => !hiddenShippingMethods.includes(value))
        // remove shipping methods that have rates (just in case)
        .filter(
          ({value}) =>
            !normalizedRates.find(({service_type}) => value === service_type),
        )
        // remove DHL return type methods
        .filter(
          ({type}) => !(DHL_ECOMMERCE === shipperType && type === 'return'),
        )
        .forEach((option) =>
          normalizedRates.push(shippingMethodToRate(option, shipperType)),
        )
    }
  }

  return sortRates(normalizedRates)
}

const rateRequestTokens = {}
async function getRatesTask(labelInfoID) {
  const token = {}
  rateRequestTokens[labelInfoID] = token

  try {
    const updatedLabel = await fetchRates(labelInfoID)

    if (rateRequestTokens[labelInfoID] !== token) {
      return
    }
    delete rateRequestTokens[labelInfoID]

    updateLabelInfo(labelInfoID, updatedLabel)

    await saveLabelInfo(labelInfoID)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting rates.',
        details: err.message,
      },
      err,
    )
    delete rateRequestTokens[labelInfoID]
  }
}

export async function getRates(labelInfoIDs, {justSaveLabelConfig} = {}) {
  try {
    const validLabelInfoIDs = validLabelInfoIDsSelector(getState(), {
      labelInfoIDs,
    })

    startUpdatingRates(validLabelInfoIDs)

    const promises = []
    for (const labelInfoID of labelInfoIDs) {
      if (validLabelInfoIDs.includes(labelInfoID) && !justSaveLabelConfig) {
        promises.push(getRatesTask(labelInfoID))
      } else {
        updateLabelInfo(labelInfoID, {rates_loading: false})

        promises.push(saveLabelInfo(labelInfoID))
      }
    }

    await Promise.all(promises)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting rates.',
        details: err.message,
      },
      err,
    )
  }
}

async function saveConfigAndGetRates(labelInfoID, {justSaveLabelConfig} = {}) {
  if (justSaveLabelConfig) {
    updateLabelInfo(labelInfoID, {rates_loading: false})
  }

  await saveLabelInfo(labelInfoID, {clearRates: !justSaveLabelConfig})

  if (!justSaveLabelConfig) {
    await getRatesTask(labelInfoID)
  }
}

export async function saveConfigAndGetRatesAll(
  labelInfoIDs,
  {justSaveLabelConfig} = {},
) {
  try {
    const validLabelInfoIDs = validLabelInfoIDsSelector(getState(), {
      labelInfoIDs,
    })

    startUpdatingRates(validLabelInfoIDs)

    const promises = []
    for (const labelInfoID of labelInfoIDs) {
      promises.push(
        saveConfigAndGetRates(labelInfoID, {
          justSaveLabelConfig:
            justSaveLabelConfig || !validLabelInfoIDs.includes(labelInfoID),
        }),
      )
    }

    await Promise.all(promises)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting rates.',
        details: err.message,
      },
      err,
    )
  }
}

export async function loadLocalOrRemoteRates(labelInfoID) {
  const localRates = localRatesSelector(getState(), {labelInfoID})
  if (localRates) {
    return localRates
  }

  let params = {}

  const labelUseRateShopping = labelUseRateShoppingSelector(getState(), {
    labelInfoID,
  })

  try {
    params = labelUseRateShopping
      ? undefined
      : rateRequestSelector(getState(), {labelInfoID})
  } catch (err) {
    // Something bad happened while collecting params
    // * like the shipper_id set in the label config is no longer active

    // We want to log this situation, but not bubble the error to the label config. Rate request
    // errors are not sent to sentry so we won't know when rateRequestSelector has a bug
    const config = labelConfigSelector(getState(), {labelInfoID})
    logError(
      err,
      `Error preparing params for a rate request for label_info.id (${labelInfoID}). ${err.message}`,
      {config},
    )

    // We can't exit early, even though we know the request will be garbage
    // If we exit early, nothing is settled and we will be back here again for infinity,
    // so let a rate request of (empty object) be made
  }

  const url = rateRequestURLSelector(getState(), {labelInfoID})

  const {json} = await apiverson.post(url, params)

  const rates = normalizeRateShape({labelInfoID, rates: json})

  return rates
}

function getCheapestRate(rates) {
  rates = sortRates(
    // exclude error message rates
    rates.filter(({error_message}) => !error_message),
    {includeShipperID: false},
  )

  return rates[0]
}

export async function fetchRates(labelInfoID) {
  const labelInfoUpdates = {
    config: {},
    rates_loading: false,
    rates_updated: false,
    error_message: null,
    departed_shipping_method: null,
    rates: null,
  }

  try {
    let shipperType = labelShipperTypeSelector(getState(), {labelInfoID})
    const prevRates = ratesSelector(getState(), {labelInfoID})
    const rates = await loadLocalOrRemoteRates(labelInfoID)
    const labelUseRateShopping = labelUseRateShoppingSelector(getState(), {
      labelInfoID,
    })

    labelInfoUpdates.rates = {list: rates}

    if (
      rates.length &&
      rates.filter(({error_message}) => error_message).length === rates.length
    ) {
      // if all the rates are error messages then throw the first error
      // so that we store it on the error_message nugget
      throw new Error(rates[0].error_message)
    }

    const ratesChanged = !isEqual(prevRates, rates)

    if (!ratesChanged) {
      return // move to finally block
    }

    const prevShipperID = labelShipperIDSelector(getState(), {
      labelInfoID,
    })
    const prevShippingMethod = shippingMethodSelector(getState(), {
      labelInfoID,
    })
    const prevBoxShape = boxShapeSelector(getState(), {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    })
    const prevRateKey = rateKeySelector(getState(), {
      labelInfoID,
    })
    const prevRateInRates = rates.find(
      (rate) =>
        (!rate.shipper_id || rate.shipper_id === prevShipperID) &&
        rate.service_type === prevShippingMethod &&
        (!labelUseRateShopping ||
          !rate.box_type ||
          rate.box_type === prevBoxShape),
    )
    const prevRateKeyInRates = rates.find((rate) => rate.key === prevRateKey)

    const prevShippingMethodWasInitialShippingMethod =
      !prevRates.length && prevRateKey

    if (!prevShippingMethodWasInitialShippingMethod) {
      labelInfoUpdates.rates_updated = true
    }

    if (prevShippingMethod && !prevRateInRates) {
      const name = shippingMethodDisplayNameSelector(getState(), {
        labelInfoID,
        shippingMethod: prevShippingMethod,
      })

      labelInfoUpdates.departed_shipping_method = name
    } else {
      labelInfoUpdates.departed_shipping_method = null
    }

    const cheapestRate = getCheapestRate(rates)
    if (cheapestRate && !prevRateInRates) {
      labelInfoUpdates.config = {
        ...labelInfoUpdates.config,
        ...updateLabelRate(labelInfoID, shipperType, cheapestRate),
      }
    } else if (prevRateInRates && !prevRateKeyInRates) {
      // updating rate key because it would change for some shippers between rate requests
      labelInfoUpdates.config[`${shipperType}__rate_key`] = prevRateInRates.key
    }
  } catch (err) {
    labelInfoUpdates.error_message = err.message || err.error_message
  } finally {
    const labelInfo = labelInfoSelector(getState(), {labelInfoID})

    const updatedLabelInfo = fpAssign(labelInfo, labelInfoUpdates)
    updatedLabelInfo.config = fpAssign(
      labelInfo.config,
      labelInfoUpdates.config,
    )

    return updatedLabelInfo
  }
}
