import PropTypes from 'prop-types'

import {isNumeric, isPresent} from '../utils.js'
import {currencySymbolSelector} from '../../data/company.js'
import {useSelector} from '../../store.js'

export function isTBD(value) {
  return [0, Infinity, null, undefined].includes(value)
}

export function formatCurrency(value, currencySymbol = '', options = {}) {
  if (!isNumeric(value)) {
    return `${currencySymbol}${value}`
  }

  const num = Number(value)
  const isNegative = num < 0

  const formattedValue = Math.abs(num).toLocaleString('en-us', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  })

  return `${isNegative ? '-' : ''}${currencySymbol}${formattedValue}`
}

export default function Currency({
  value = '',
  currencySymbol = '',
  options = {},
}) {
  currencySymbol = useSelector((state) =>
    isPresent(currencySymbol) ? currencySymbol : currencySymbolSelector(state),
  )

  const formattedValue = formatCurrency(value, currencySymbol, options)

  if (!formattedValue) {
    return null
  }

  return <span>{formattedValue}</span>
}

Currency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currencySymbol: PropTypes.string,
  options: PropTypes.object,
}
