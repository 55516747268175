import {useEffect, useState} from 'react'
import isEqual from 'lodash/isEqual.js'

import {LabelInfoIDsShape} from '../../common/PropTypes.js'
import SingleLabelConfigForm from './SingleLabelConfigForm/index.js'
import BulkLabelConfigForm from './BulkLabelConfigForm/index.js'

export default function LabelConfig({labelInfoIDs}) {
  const [stableLabelInfoIDs, setStableLabelInfoIDs] = useState([])
  useEffect(() => {
    const ids = labelInfoIDs.filter((labelInfoID) => labelInfoID)
    if (!isEqual(ids, stableLabelInfoIDs)) {
      setStableLabelInfoIDs(ids)
    }
  }, [labelInfoIDs])

  if (stableLabelInfoIDs.length === 0) {
    return null
  }

  return (
    <>
      {stableLabelInfoIDs.length === 1 ? (
        <SingleLabelConfigForm labelInfoID={stableLabelInfoIDs[0]} />
      ) : (
        <BulkLabelConfigForm labelInfoIDs={stableLabelInfoIDs} />
      )}
    </>
  )
}

LabelConfig.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
