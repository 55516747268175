import PropTypes from 'prop-types'
import {useEffect} from 'react'

import UPSPickup from './UPSPickup.js'
import FedExPickup from './FedExPickup.js'
import {onlyIfForm, useSelector} from '../../../../store.js'
import {
  closeModal,
  modalFormSelector,
  errorsSelector,
  schedulePickup,
  selectedShipperSelector,
  setPickupShipperID,
  pickupShippersSelector,
} from './pickupModalFunctions.js'
import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {
  AMAZON_SFP,
  FEDEX,
  UPS,
} from '../../../../common/constants/ShipperNames.js'
import AmazonSFPPickup from './AmazonSFPPickup.js'

const vendorForms = {
  [UPS]: UPSPickup,
  [FEDEX]: FedExPickup,
  [AMAZON_SFP]: AmazonSFPPickup,
}

function PickupModal({form}) {
  const errors = useSelector(errorsSelector)
  const selectedShipper = useSelector(selectedShipperSelector)
  const VendorForm = vendorForms[selectedShipper.vendor]
  const shippers = useSelector(pickupShippersSelector)

  useEffect(() => {
    if (!VendorForm) {
      if (shippers.length) {
        setPickupShipperID(shippers[0].id)
      } else {
        closeModal()
      }
    }
  }, [VendorForm])

  if (!VendorForm) {
    return null
  }

  return (
    <ConfirmModal
      title="Schedule Pickup"
      onConfirm={() => schedulePickup()}
      onCancel={() => closeModal()}
      confirmText="Schedule"
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
      isDisabled={errors.preventSave}
    >
      <VendorForm />
    </ConfirmModal>
  )
}

PickupModal.propTypes = {
  form: PropTypes.shape({
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(PickupModal, modalFormSelector)
