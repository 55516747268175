import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

import api from '../../../common/api.js'
import {DateShape} from '../../../common/PropTypes.js'
import DatePicker from '../../../common/components/DatePicker.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import {
  getState,
  dispatch,
  setForm,
  updateForm,
  removeForm,
  useSelector,
  onlyIfForm,
  formSelector,
} from '../../../store.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {getRealDate, getTimeZone} from '../../../common/date.js'
import Radio from '../../../common/components/Radio.js'
import Select from '../../../common/components/Select.js'
import {TIME_ZONE_OPTIONS} from '../../../common/constants/TimeZones.js'

export const MODAL_FORM = 'EXPORT_MO_MODAL'
export const EXPORT_MO_DETAIL = 'export_mo_detail'
export const EXPORT_MO_SUMMARY = 'export_mo_summary'

export function showExportMOModal(type = EXPORT_MO_DETAIL, startDate, endDate) {
  const now = getRealDate()

  if (!startDate && !endDate) {
    startDate = subMonths(now, 1)
    endDate = now
  } else if (startDate && !endDate) {
    endDate = addMonths(startDate, 1)

    if (endDate > now) {
      endDate = now
    }
  } else if (!startDate && endDate) {
    startDate = subMonths(endDate, 1)
  }

  setForm(MODAL_FORM, {
    type,
    start_date: startOfDay(startDate),
    end_date: endOfDay(endDate),
    timezone: getTimeZone(),
    isRequesting: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({start_date, end_date}) => {
    const errors = {}

    if (!start_date) {
      errors.start_date = 'Date is required'
      errors.preventSave = true
    }

    if (!end_date) {
      errors.end_date = 'Date is required'
      errors.preventSave = true
    }

    if (start_date > end_date) {
      errors.end_date = 'End date must be after start date'
      errors.preventSave = true
    }

    return errors
  },
)

export async function exportMOs() {
  try {
    const {type, start_date, end_date, timezone} = modalFormSelector(getState())

    const task = {
      type,
      params: {
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),
        timezone,
      },
    }

    updateModalForm({
      serverError: null,
      isRequesting: true,
    })

    await api.post('/new_task', task)

    dispatch(checkRunningTasks())

    showMessageToast(
      'We’re generating your csv file. Check the Activity menu in the top right of the screen to download it.',
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isRequesting: false,
    })
  }
}

function ExportMOModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title={
        form.type === EXPORT_MO_DETAIL
          ? 'Export MFG Order Details'
          : 'Export MFG Order Summary'
      }
      isSaving={form.isRequesting}
      confirmText="Export"
      cancelText="Cancel"
      onConfirm={() => exportMOs()}
      onCancel={() => closeModal()}
      error={form.serverError}
      isDisabled={errors.preventSave}
      preventInnerScroll
    >
      <ul className="list margin-bottom-0">
        <li className="list__item--no-style fs-01 margin-bottom-7">
          <strong>Report Type:</strong>
        </li>
        <li className="list__item--no-style flex margin-bottom-20">
          <div className="margin-right-15">
            <Radio
              mode="fancy"
              id="type_detail"
              label="Detail"
              value={EXPORT_MO_DETAIL}
              checked={form.type === EXPORT_MO_DETAIL}
              onChange={() => updateModalForm({type: EXPORT_MO_DETAIL})}
            />
          </div>
          <div>
            <Radio
              mode="fancy"
              id="type_summary"
              label="Summary"
              value={EXPORT_MO_SUMMARY}
              checked={form.type === EXPORT_MO_SUMMARY}
              onChange={() => updateModalForm({type: EXPORT_MO_SUMMARY})}
            />
          </div>
        </li>
        <li className="list__item--no-style fs-01 margin-bottom-5">
          <strong>Date Range:</strong>
        </li>
        <li className="list__item--no-style flex">
          <div className="margin-right-15">
            <label className="clearfix" htmlFor="id_start_date">
              <span className="left">Start</span>
            </label>
            <DatePicker
              id="id_start_date"
              onChange={(date) =>
                updateModalForm({
                  start_date: date,
                })
              }
              selected={form.start_date}
              startDate={form.start_date}
              endDate={form.end_date}
            />
            {errors.start_date && (
              <small className="error error-message">{errors.start_date}</small>
            )}
          </div>
          <div>
            <label className="clearfix" htmlFor="id_end_date">
              <span className="left">End</span>
            </label>
            <DatePicker
              id="id_end_date"
              onChange={(date) =>
                updateModalForm({
                  end_date: date,
                })
              }
              selected={form.end_date}
              startDate={form.start_date}
              endDate={form.end_date}
            />
            {errors.end_date && (
              <small className="error error-message">{errors.end_date}</small>
            )}
          </div>
        </li>
        <li className="list__item--form list__item--no-style margin-top-15">
          <div className="w-50">
            <label htmlFor="id_time_zone">Time Zone</label>
            <Select
              className="margin-bottom-0 w-100"
              id="id_time_zone"
              value={form.timezone}
              onChange={(timezone) =>
                updateModalForm({timezone}, {stickyProps: ['timezone']})
              }
            >
              {TIME_ZONE_OPTIONS.map(({value, display}) => (
                <option value={value} key={value}>
                  {display}
                </option>
              ))}
            </Select>
          </div>
        </li>
      </ul>
    </ConfirmModal>
  )
}

ExportMOModal.propTypes = {
  form: PropTypes.shape({
    type: PropTypes.oneOf([EXPORT_MO_DETAIL, EXPORT_MO_SUMMARY]).isRequired,
    start_date: DateShape,
    end_date: DateShape,
    timezone: PropTypes.string.isRequired,
    serverError: PropTypes.string,
    isRequesting: PropTypes.bool.isRequired,
  }),
}

export default onlyIfForm(ExportMOModal, modalFormSelector)
