import PropTypes from 'prop-types'

import {RuleActionShape, ErrorsShape} from '../../../../common/PropTypes.js'
import TagBlock from '../../../../common/components/TagBlock.js'
import EditTag from './EditTag.js'
import {TAG_TYPE__ORDER} from '../../../../common/constants/Tags.js'

export default function ApplyTag({id, index, action, errors, isEditing}) {
  const {
    data: {
      tag_ids: [tagID],
    },
  } = action

  if (!isEditing) {
    if (tagID === null) {
      return null
    }

    return (
      <div>
        <strong className="margin-right-5">Apply tag</strong>
        <ul className="list list--tags-lg inline-block">
          <TagBlock tagID={tagID} tagType={TAG_TYPE__ORDER} />
        </ul>
      </div>
    )
  }

  return <EditTag id={id} index={index} action={action} errors={errors} />
}

ApplyTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  action: RuleActionShape.isRequired,
  errors: ErrorsShape,
  isEditing: PropTypes.bool.isRequired,
}
