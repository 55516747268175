import PropTypes from 'prop-types'
import classNames from 'classnames'

import {formatAPIURL} from '../../../common/api.js'
import copyTextToClipboard from '../../../common/copyTextToClipboard.js'
import {TaskShape} from '../../../common/PropTypes.js'
import {showMessageToast} from '../Toast/index.js'
import TaskStatus from './TaskStatus.js'

export default function ActivityMenuItem({task, showLogLink}) {
  const showResultLink =
    task.table_key === 'export_task' && task.complete && task.success

  return (
    <li
      className={classNames('list__item list__item--dropdown', {
        'activity-error': !task.success && task.complete,
        'activity-ok': task.success,
      })}
    >
      <dl className="list--activity-item">
        {!task.success && task.complete ? (
          <>
            <dt className="list__title--activity">
              <span
                className="i-exclamation-triangle fs-00 v-align-middle margin-right-5"
                aria-hidden="true"
              />
              <strong>An error occurred — contact support</strong>
            </dt>
            <dd className="list__item--activity list__item--activity-error">
              <TaskStatus task={task} />
              <button
                className="btn btn--link no-underline"
                type="button"
                onClick={() => {
                  copyTextToClipboard(task.status)

                  showMessageToast('Error message copied to clipboard')
                }}
              >
                <strong>Copy to clipboard</strong>
              </button>
            </dd>
          </>
        ) : (
          <dt className="list__title--activity flex">
            <TaskStatus task={task} />
            {showResultLink && (
              <div>
                <span className="unbold op-75 margin-right-3">–</span>
                <strong className="op-75">⇣</strong>
                <a href={formatAPIURL(task._linkdata)}>
                  <strong>CSV</strong>
                </a>
              </div>
            )}
          </dt>
        )}
        <dd className="list__item--activity">
          <span className="activity-date">{task.updated_display}</span>
        </dd>

        {showLogLink && (
          <dd className="list__item--activity align-center">
            <a
              className="result-link log-link"
              href={task._logurl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>Activity Log</strong>
            </a>
          </dd>
        )}
      </dl>
    </li>
  )
}

ActivityMenuItem.propTypes = {
  task: TaskShape.isRequired,
  showLogLink: PropTypes.bool.isRequired,
}
