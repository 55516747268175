import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ReturnOrderSelectAllButton from './ReturnOrderSelectAllButton.js'
import {referenceIDListSelector} from './returnOrderListSelectors.js'
import Row from './Row.js'
import EmptyList from './EmptyList.js'

function Table({referenceIDList}) {
  return (
    <table className="table table--zebra-list table--product-list">
      <thead>
        <tr className="table__tr">
          <th className="table__th table__th--sm v-align-middle no-border table__th--order-checkbox">
            <ReturnOrderSelectAllButton />
          </th>
          <th className="table__th table__th--sm th-width-25 v-align-middle no-border">
            RMA ID
          </th>
          <th className="table__th table__th--sm th-width-25 v-align-middle no-border">
            Date Created
          </th>

          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            Customer
          </th>
          <th className="table__th table__th--sm th-width-20 v-align-middle no-border">
            Return Address
          </th>
          <th className="table__th table__th--sm th-width-15 v-align-middle no-border">
            Expected Items
          </th>
          <th className="table__th table__th--sm v-align-middle no-border" />
        </tr>
      </thead>
      <tbody className="table__tbody--full-border">
        {referenceIDList.map((referenceID) => (
          <Row key={referenceID} referenceID={referenceID} />
        ))}
        {referenceIDList.length === 0 && <EmptyList colSpan="7" />}
      </tbody>
    </table>
  )
}

Table.propTypes = {
  referenceIDList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function mapStateToProps(state) {
  return {
    referenceIDList: referenceIDListSelector(state),
  }
}

export default connect(mapStateToProps)(Table)
