import {
  accountingIntegrationsSelector,
  integrationsHaveLoadedSelector,
} from '../../../data/integrations.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import SettingsPanel from '../SettingsPanel.js'
import EditForm from './EditForm.js'
import NewForm from './NewForm.js'
import {useSelector} from '../../../store.js'
import {cartsHaveLoadedSelector} from '../../../data/carts.js'
import {useAccountingSelector} from '../../../data/company.js'
import AccountingFeaturePage from './AccountingFeaturePage.js'

export default function Accounting() {
  const integrations = useSelector(accountingIntegrationsSelector)
  const integrationsHaveLoaded = useSelector(integrationsHaveLoadedSelector)
  const cartsHaveLoaded = useSelector(cartsHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).accounting
  const useAccounting = useSelector(useAccountingSelector)

  const hasLoaded = integrationsHaveLoaded && cartsHaveLoaded

  if (!useAccounting) {
    return <AccountingFeaturePage />
  }

  return (
    <SettingsPanel header="Accounting Settings" hasPermission={hasPermission}>
      <div className="row">
        {hasLoaded && (
          <div className="medium-12 columns">
            <ul className="list--settings-wrap medium-12 columns">
              {integrations.map((integration) => (
                <EditForm key={integration.id} integration={integration} />
              ))}
              <NewForm />
            </ul>
          </div>
        )}
      </div>
    </SettingsPanel>
  )
}
