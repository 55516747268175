import _ from 'lodash'

export default function debounce(func, wait, options) {
  if (window.WAIT_OVERRIDE === 0) {
    const debouncedFunc = (...args) => func(...args)
    debouncedFunc.cancel = function () {}

    return debouncedFunc
  }

  return _.debounce(func, window.WAIT_OVERRIDE || wait, options)
}

export function debouncePromise(func, timeout = 0) {
  if (window.WAIT_OVERRIDE === 0) {
    const debouncedFunc = (...args) => func(...args)
    debouncedFunc.cancel = function () {}

    return debouncedFunc
  }

  timeout = window.WAIT_OVERRIDE || timeout

  let promise = null
  let resolve = null
  let timeoutRef = null
  let cancelled = false

  const debouncedFunc = (...args) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef)
    }

    timeoutRef = setTimeout(() => {
      timeoutRef = null

      if (!cancelled) {
        resolve()
      }

      resolve = null
    }, timeout)

    if (promise) {
      return promise
    }

    promise = new Promise((r) => {
      resolve = r
    })
      .then(() => {
        promise = null
      })
      .then(() => func(...args))

    return promise
  }

  debouncedFunc.cancel = () => {
    cancelled = true
  }

  return debouncedFunc
}

export function limitPromise(func, {limitBy = () => '_'} = {}) {
  const inflight = {}

  return (...args) => {
    const id = limitBy(...args)

    if (inflight[id]) {
      return inflight[id]
    }

    inflight[id] = func(...args).then(() => delete inflight[id])

    return inflight[id]
  }
}
