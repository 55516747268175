import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'

const MODAL_FORM = 'DELETE_RETURN_ORDER_LINES_MODAL_FORM'
export const DELETE_RETURN_ORDER_LINES = 'DELETE_RETURN_ORDER_LINES'

export function showDeleteReturnOrderLinesModal(referenceID, lineIDs) {
  return setForm(MODAL_FORM, {
    referenceID,
    lineIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function deleteReturnOrderLines() {
  return {
    type: DELETE_RETURN_ORDER_LINES,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* deleteReturnOrderLine(referenceID, lineID) {
  const {json} = yield call(
    apiverson.delete,
    `/return_order/${encodeURIComponent(referenceID)}/line/${lineID}`,
  )

  yield put(setReturnOrder(json))
}

export function* deleteReturnOrderLinesWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceID, lineIDs} = yield select(modalFormSelector)
    const lineCount = lineIDs.length

    yield all(
      lineIDs.map((lineID) => call(deleteReturnOrderLine, referenceID, lineID)),
    )

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${lineCount} RMA Line${lineCount !== 1 ? 's were' : ' was'} deleted`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error deleting RMA Line: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function DeleteReturnOrderLinesModal({form, ...props}) {
  const lineCount = form.lineIDs.length

  return (
    <ConfirmModal
      title="Delete RMA Line"
      onConfirm={() => props.deleteReturnOrderLines()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      Delete {lineCount} RMA line{lineCount !== 1 ? 's' : ''}?
    </ConfirmModal>
  )
}

DeleteReturnOrderLinesModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  deleteReturnOrderLines: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  deleteReturnOrderLines,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(DeleteReturnOrderLinesModal),
  modalFormSelector,
)
