import PropTypes from 'prop-types'

export default function ExportCSVButton({onExport}) {
  return (
    <button className="btn btn--link fs-00 lh-x-md" onClick={() => onExport()}>
      ⇣CSV
    </button>
  )
}

ExportCSVButton.propTypes = {
  onExport: PropTypes.func.isRequired,
}
