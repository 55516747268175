import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {RETURN_ORDER_SORT_OPTIONS} from '../../common/constants/ReturnOrders.js'
import Sort from '../../common/components/List/Sort.js'
import {setSort} from './returnOrderListActions.js'
import {sortSelector} from './returnOrderListSelectors.js'

export function ReturnOrderSort({sort, ...props}) {
  return (
    <Sort
      items={RETURN_ORDER_SORT_OPTIONS}
      value={sort}
      onChange={(value) => props.setSort(value)}
    />
  )
}

ReturnOrderSort.propTypes = {
  sort: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    sort: sortSelector(state),
  }
}

const mapDispatchToProps = {
  setSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrderSort)
