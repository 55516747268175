import {useEffect} from 'react'
import DatePicker from '../../../../common/components/DatePicker.js'
import Radio from '../../../../common/components/Radio.js'
import {useSelector} from '../../../../store.js'
import {validDateRangeFilterTypesOptionsSelector} from '../../orderListSelectors.js'
import {
  modalFormSelector,
  errorsSelector,
  updateModalForm,
} from './orderListFilterModalFunctions.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import {REPORT_INTERVAL_OPTIONS} from '../../../../common/constants/Reports.js'
import {plural} from '../../../../common/components/Plural.js'
import Select from '../../../../common/components/Select.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import FormattedDate from '../../../../common/components/FormattedDate.js'
import {visualizeInterval} from '../../../ReportsPage/ReportForms/IntervalSummary.js'
import {TIME_ZONE_OPTIONS} from '../../../../common/constants/TimeZones.js'

function modalValidDateRangeFilterTypesOptionsSelector(state) {
  const cache = modalValidDateRangeFilterTypesOptionsSelector.cache
  const {status} = modalFormSelector(state)

  const options = validDateRangeFilterTypesOptionsSelector(state, {status})

  if (
    options.length === cache.length &&
    !options.find(({value}, index) => cache[index].value !== value)
  ) {
    return cache
  }

  modalValidDateRangeFilterTypesOptionsSelector.cache = options

  return options
}
modalValidDateRangeFilterTypesOptionsSelector.cache = []

function IntervalSummary() {
  const form = useSelector(modalFormSelector)
  const errors = useSelector(errorsSelector)

  if (!form.intervalType) {
    return null
  }

  const [visualStartDateString, visualEndDateString] = visualizeInterval(
    form.intervalType,
    form.intervalAmount,
    form.intervalTrunc,
  )

  return (
    <>
      {errors.intervalAmount && (
        <li className="list__item margin-bottom-15">
          <small className="error error-message">{errors.intervalAmount}</small>
        </li>
      )}
      <li className="list__item wrap--v-align-inner margin-bottom-10">
        <Checkbox
          label={`Truncate to start of ${form.intervalType}`}
          id="interval_trunc"
          checked={form.intervalTrunc}
          onChange={(intervalTrunc) => updateModalForm({intervalTrunc})}
        />
      </li>
      <li className="list__item w-65 alert alert--standard margin-bottom-25">
        <div className="fs-n0 lh-md">
          The filtered list will include dates from{' '}
          <strong>
            <FormattedDate
              value={visualStartDateString}
              format="MMM D, YYYY [at] h:mma"
            />
          </strong>{' '}
          up to{' '}
          <strong>
            <FormattedDate
              value={visualEndDateString}
              format="MMM D, YYYY [at] h:mma"
            />
          </strong>
          .
        </div>
      </li>
    </>
  )
}

export default function DateRangeFilterPanel() {
  const form = useSelector(modalFormSelector)
  const errors = useSelector(errorsSelector)
  const validDateRangeFilterTypesOptions = useSelector(
    modalValidDateRangeFilterTypesOptionsSelector,
  )

  useEffect(() => {
    if (
      !validDateRangeFilterTypesOptions.find(
        ({value}) => value === form.dateFilterType,
      )
    ) {
      updateModalForm({
        dateFilterType: validDateRangeFilterTypesOptions[0].value,
      })
    }
  }, [validDateRangeFilterTypesOptions, form.dateFilterType])

  return (
    <div className="wrap--modal-filters wrap--modal-filters-date-range">
      <div className="wrap__modal-subheader margin-bottom-25 flex--justify">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0 flex--justify-col">
          <strong>Date Range</strong>
        </h4>
      </div>

      <div className="fs-00 margin-bottom-15">
        <strong>Date Type</strong>
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {validDateRangeFilterTypesOptions.map((option) => (
          <li
            key={option.value}
            className="list__item list__item--modal-filters"
          >
            <Radio
              mode="fancy"
              id={`date_type__${option.value}`}
              value={option.value}
              checked={form.dateFilterType === option.value}
              onChange={() => updateModalForm({dateFilterType: option.value})}
              label={option.display}
            />
          </li>
        ))}
      </ul>
      <div className="margin-top-10 margin-bottom-20">
        <div className="fs-00 margin-bottom-15">
          <strong>Interval</strong>
        </div>
        <div className="flex">
          {form.intervalType && (
            <NumberInput
              containerClassName="margin-right-10 wrap--weight-input"
              id="interval_amount"
              value={form.intervalAmount}
              onChange={(value) =>
                updateModalForm({intervalAmount: String(value)})
              }
              isInvalid={!!errors.intervalAmount}
            />
          )}
          <div>
            <Select
              id="interval_type"
              value={form.intervalType}
              onChange={(intervalType) => updateModalForm({intervalType})}
            >
              {form.singleRun && <option value="">Specific Date Range</option>}
              {REPORT_INTERVAL_OPTIONS.map(({value, display}) => (
                <option key={value} value={value}>
                  {plural(Number(form.intervalAmount) || 0, 's', '', display)}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {!form.intervalType && form.singleRun && (
        <ul className="list list--no-style list--modal-filters flex flex-wrap">
          <li className="list__item list__item--modal-filters flex">
            <div className={`w-20rem ${errors.startDate ? 'error' : ''}`}>
              <DatePicker
                label="Start Date"
                id="id_start_date"
                onChange={(date) => updateModalForm({startDate: date})}
                selected={form.startDate}
                startDate={form.startDate}
                endDate={form.endDate}
                errorMessage={errors.startDate}
              />
            </div>
            <div
              className={`w-20rem margin-left-25 ${
                errors.endDate ? 'error' : ''
              }`}
            >
              <DatePicker
                label="End Date"
                id="id_end_date"
                onChange={(date) => updateModalForm({endDate: date})}
                selected={form.endDate}
                startDate={form.startDate}
                endDate={form.endDate}
                errorMessage={errors.endDate}
              />
            </div>
          </li>
        </ul>
      )}
      <ul className="list list--no-style">
        <IntervalSummary />
      </ul>
      <div className="w-50 margin-bottom-20">
        <Checkbox
          label="Include Time Zone"
          id="include_timezone"
          checked={form.includeTimezone}
          onChange={() =>
            updateModalForm({includeTimezone: !form.includeTimezone})
          }
        />
        {form.includeTimezone && (
          <Select
            label="Time Zone"
            labelClassName="margin-top-10"
            className="margin-bottom-0 w-100"
            id="id_time_zone"
            value={form.timezone}
            onChange={(timezone) =>
              updateModalForm({timezone}, {stickyProps: ['timezone']})
            }
          >
            {TIME_ZONE_OPTIONS.map(({value, display}) => (
              <option value={value} key={value}>
                {display}
              </option>
            ))}
          </Select>
        )}
      </div>
    </div>
  )
}
