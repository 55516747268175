import {useEffect} from 'react'

import {onlyIfAutoForm, useSelector} from '../../store.js'
import {
  FINANCIAL_PANEL,
  SHIPPING_PANEL,
  INVENTORY_PANEL,
  PRODUCT_PANEL,
  CUSTOMER_PANEL,
} from '../../common/constants/Analytics.js'
import {
  analyticsPanelSelector,
  cartFiltersSelector,
  supplierFiltersSelector,
  productFiltersSelector,
  startDateSelector,
  endDateSelector,
  dateTypeSelector,
  canUseAnalyticsSelector,
} from './analyticsSelectors.js'
import {
  setAnalyticsForm,
  refreshAnalytics,
  refreshProductSearch,
} from './analyticsActions.js'
import SideNav from './SideNav.js'
import CustomerPanel from './CustomerPanel.js'
import FinancialPanel from './FinancialPanel.js'
import InventoryPanel from './InventoryPanel.js'
import ProductPanel from './ProductPanel.js'
import ShippingPanel from './ShippingPanel.js'
import AnalyticsFeaturePage from './AnalyticsFeaturePage.js'
import {hasAnalyticsPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'
import {useAnalyticsSelector} from '../../data/company.js'

function AnalyticsPage() {
  useEffect(() => {
    document.title = 'Analytics'
  }, [])
  const canUseAnalytics = useSelector(canUseAnalyticsSelector)
  const useAnalytics = useSelector(useAnalyticsSelector)
  const hasAnalyticsPermission = useSelector(hasAnalyticsPermissionSelector)
  const panel = useSelector(analyticsPanelSelector)
  const cartFilters = useSelector(cartFiltersSelector)
  const supplierFilters = useSelector(supplierFiltersSelector)
  const productFilters = useSelector(productFiltersSelector)
  const startDate = useSelector(startDateSelector)
  const endDate = useSelector(endDateSelector)
  const dateType = useSelector(dateTypeSelector)

  useEffect(() => {
    if (canUseAnalytics) {
      refreshAnalytics()
    }
  }, [
    canUseAnalytics,
    panel,
    cartFilters,
    supplierFilters,
    startDate,
    endDate,
    dateType,
  ])

  useEffect(() => {
    if (canUseAnalytics) {
      refreshProductSearch()
    }
  }, [
    canUseAnalytics,
    panel,
    cartFilters,
    supplierFilters,
    startDate,
    endDate,
    productFilters,
  ])

  if (!useAnalytics) {
    return <AnalyticsFeaturePage />
  }

  if (!hasAnalyticsPermission) {
    return <NoPermissionsPage />
  }

  if (!startDate || !endDate) {
    return null
  }

  return (
    <section id="analytics-main" className="row">
      <div className="row margin-top-30 margin-bottom-25">
        {panel === CUSTOMER_PANEL && <CustomerPanel />}
        {panel === FINANCIAL_PANEL && <FinancialPanel />}
        {panel === INVENTORY_PANEL && <InventoryPanel />}
        {panel === PRODUCT_PANEL && <ProductPanel />}
        {panel === SHIPPING_PANEL && <ShippingPanel />}
        <SideNav />
      </div>
    </section>
  )
}

export default onlyIfAutoForm(AnalyticsPage, setAnalyticsForm)
