import logger from './logger.js'
import XHR from './XHR.js'

const isAPI = new RegExp(
  `^${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_API_URL.replace('.', '\\.')}`,
)

var requests = {
  DEFAULT_TIMEOUT: 120000,
  send: function (method, url, data) {
    var xhr = new XHR(method, url, data)

    return xhr
      .send(this.DEFAULT_TIMEOUT)
      .then(function (response) {
        return response.json
      })
      .catch((err) => {
        logger.error(err)

        var response = err.response || {}

        if (
          (response.status === 401 || response.status === 503) &&
          isAPI.test(url)
        ) {
          this.redirectToLogin()
        }

        var errorResponse = response.json || this.makeAPIError(response.raw)
        return Promise.reject(errorResponse)
      })
  },
  makeAPIError: function (message) {
    return {error_message: message, param: null}
  },
  redirectToLogin: function () {
    window.location.href = '/login'
  },
  getErrorText: function (errorResponse) {
    return errorResponse.error_message
  },
}

requests.get = requests.send.bind(requests, 'get')
requests.put = requests.send.bind(requests, 'put')
requests.post = requests.send.bind(requests, 'post')
requests.delete = requests.send.bind(requests, 'delete')

export default requests
