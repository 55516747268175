import {useSelector} from '../../../../store.js'
import SingleLabelConfigForm from '../../../LabelConfig/SingleLabelConfigForm/index.js'
import {
  quickShipHasLabelSelector,
  quickShipIsShippedSelector,
  quickShipLabelInfoIDSelector,
} from './quickShipModalFunctions.js'

export default function CreateLabelPanel() {
  const labelInfoID = useSelector(quickShipLabelInfoIDSelector)
  const hasLabel = useSelector(quickShipHasLabelSelector)
  const isShipped = useSelector(quickShipIsShippedSelector)

  if (!labelInfoID) {
    return null
  }

  return (
    <>
      {hasLabel ? (
        <div className="alert alert--warning align-center">
          <p className="fs-01 lh-md margin-bottom-0">
            <strong>A label has already been created.</strong>
          </p>
        </div>
      ) : isShipped ? (
        <div className="alert alert--warning align-center">
          <p className="fs-01 lh-md margin-bottom-0">
            <strong>This order has already shipped.</strong>
          </p>
        </div>
      ) : (
        <>
          <p className="fs-02 lh-md margin-bottom-10">
            <strong>Now, create a shipping label.</strong>
          </p>
          <p className="fs-01 lh-md margin-bottom-25">
            Select from any of your connected carrier accounts.
          </p>
          <div className="row wrap--label-panel-in-modal">
            <div className="medium-9 columns">
              <SingleLabelConfigForm
                labelInfoID={labelInfoID}
                showEmptyPresets={false}
                showCreateButton={false}
                showErrors={false}
                showUnSetupPackingList={false}
                showBillTo={false}
                showInsuranceFull={false}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
