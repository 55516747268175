import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {CommentGroupShape} from '../../../PropTypes.js'
import CommentGroup from './CommentGroup.js'

export function History(props) {
  const {
    readOnly,
    addComment,

    commentGroups,
  } = props

  return (
    <div>
      <h3 className="list__title--order-data margin-top-0 margin-bottom-5">
        Timeline
      </h3>
      <button
        className="btn btn--link fs-n1 margin-bottom-15"
        type="button"
        onClick={() => addComment()}
        disabled={readOnly}
      >
        Add a comment
      </button>
      {commentGroups.map(({day, comments}) => (
        <CommentGroup key={day} day={day} comments={comments} />
      ))}
    </div>
  )
}

History.propTypes = {
  commentGroupsSelector: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  addComment: PropTypes.func.isRequired,

  commentGroups: PropTypes.arrayOf(CommentGroupShape).isRequired,
}

function mapStateToProps(state, {commentGroupsSelector}) {
  return (state) => ({
    commentGroups: commentGroupsSelector(state),
  })
}

export default connect(mapStateToProps)(History)
