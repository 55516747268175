import {useSelector} from 'react-redux'

import {
  ORDER_SORT_OPTIONS,
  BATCH_SORT_OPTIONS,
  DELIVER_BY_DATE_SORT_OPTIONS,
} from '../../../../../common/constants/Orders.js'
import Sort from '../../../../../common/components/List/Sort.js'
import {canUseDeliverByDateSelector} from '../../../../../data/orders.js'
import {orderListQuerySelector} from '../../../../OrderListPage/orderListSelectors.js'
import {setSort} from '../../../../OrderListPage/orderListActions.js'

export default function OrderSort() {
  const {sort, batch_reference_id} = useSelector(orderListQuerySelector)
  const canUseDeliverByDate = useSelector(canUseDeliverByDateSelector)

  let options = canUseDeliverByDate
    ? [...ORDER_SORT_OPTIONS, ...DELIVER_BY_DATE_SORT_OPTIONS]
    : ORDER_SORT_OPTIONS

  if (batch_reference_id[0]) {
    options = [...options, ...BATCH_SORT_OPTIONS]
  }

  return (
    <Sort items={options} value={sort} onChange={(value) => setSort(value)} />
  )
}
