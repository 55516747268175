import {all, takeLatest, call, put, select} from 'redux-saga/effects'

import apiverson from '../../../../common/apiverson.js'
import {PACK_SHIP_PAGE} from '../../../../common/constants/Pages.js'
import {ensureOrdersLoaded} from '../../../../data/orders.js'
import {
  SETUP_PACK_SHIP_PAGE,
  PACK_SHIP_ORDER_SCANNED,
  PACK_SHIP_ORDER_NOT_FOUND,
  PACK_SHIP_GENERAL_ERROR,
  setupPackShipPageForm,
  navigateToPackShip,
  setPackShipError,
  packShipPageDoneLoading,
} from '../../../actions/ui/packShipPage/index.js'
import {showPackingOrderModal} from '../../../../ordoro/PackShipPage/Modals/PackingOrderModal.js'
import {currentPageSelector} from '../../../selectors/ui/index.js'
import {packShipOrderNumbersSelector} from '../../../selectors/ui/packShipPage/index.js'

export function* setupPackShipPageWorker() {
  const orderNumbers = yield select(packShipOrderNumbersSelector)

  yield put(setupPackShipPageForm())

  yield call(ensureOrdersLoaded, orderNumbers)

  yield put(packShipPageDoneLoading())
}

export function* packShipOrderScannedWorker({payload: {scannedValue}}) {
  yield put(setPackShipError(null))

  try {
    const {json} = yield call(apiverson.get, '/order/', {
      barcode: scannedValue,
      limit: 1,
      offset: 0,
      status: 'all',
    })
    if (json.count !== 1) {
      yield put(setPackShipError(PACK_SHIP_ORDER_NOT_FOUND))
    } else {
      const order = json.order[0]
      const currentPage = yield select(currentPageSelector)

      if (currentPage === PACK_SHIP_PAGE) {
        yield put(navigateToPackShip([order.order_number]))
      } else {
        yield call(showPackingOrderModal, order.order_number)
      }
    }
  } catch (error) {
    yield put(setPackShipError(PACK_SHIP_GENERAL_ERROR, error.message))
  }
}

export default function* packShipPage() {
  yield all([
    takeLatest(PACK_SHIP_ORDER_SCANNED, packShipOrderScannedWorker),
    takeLatest(SETUP_PACK_SHIP_PAGE, setupPackShipPageWorker),
  ])
}
