import {combineReducers} from 'redux'
import INITIAL_STATE from '../INITIAL_STATE.js'
import {SET_SHOW_SETUP_GUIDE} from '../../actions/ui/dashboard.js'

function showSetupGuide(
  state = INITIAL_STATE.ui.dashboard.showSetupGuide,
  action = {},
) {
  if (action.type === SET_SHOW_SETUP_GUIDE) {
    return action.payload
  }
  return state
}

export default combineReducers({
  showSetupGuide,
})
