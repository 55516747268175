import ShipperSelect from './ShipperSelect.js'
import WarehouseSelect from './WarehouseSelect.js'
import PickupDateTime from './PickupDateTime.js'
import Piece from './Piece.js'
import {addFormArrayElement, getState, useSelector} from '../../../../store.js'
import {
  updateModalForm,
  modalFormSelector,
  setPickupAddressSameAsAccount,
  MODAL_FORM,
  defaultPieceFormSelector,
} from './pickupModalFunctions.js'

export default function UPSPickup() {
  const form = useSelector(modalFormSelector)

  return (
    <div>
      <h3 className="fs-01">
        <strong>Pickup Info</strong>
      </h3>
      <ShipperSelect />
      <ul className="list">
        <li className="list__item--form list__item--no-style margin-bottom-15">
          <label className="margin-bottom-5" htmlFor="id_use_warehouse">
            Pickup Location
          </label>
          <div className="margin-right-20">
            <WarehouseSelect />
          </div>
          <div className="margin-top-5">
            <label
              className="fs-n0 unbold"
              htmlFor="id_pickup_address_same_as_account"
            >
              <input
                type="checkbox"
                className="margin-right-5"
                name="id_pickup_address_same_as_account"
                id="id_pickup_address_same_as_account"
                checked={form.pickupAddressSameAsAccount}
                onChange={() =>
                  setPickupAddressSameAsAccount(
                    !form.pickupAddressSameAsAccount,
                  )
                }
              />
              <span>
                Pickup address is the same as address on carrier account
              </span>
            </label>
          </div>
        </li>
        <PickupDateTime />
      </ul>
      <h3 className="fs-01 margin-top-30">
        <strong>Package Info</strong>
      </h3>
      <table className="table">
        <thead>
          <tr>
            <th className="table__th table__th--sm th-width-20">
              Container Type
            </th>

            <th className="table__th table__th--sm th-width-30">Ship Method</th>
            <th className="table__th table__th--sm th-width-30">
              Destination Country
            </th>
            <th className="table__th table__th--sm th-width-10">Qty</th>
            <th className="table__th table__th--sm">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="table__tbody table__tbody--lines table__tbody--v-align-middle">
          {form.pieces.map((piece, index) => (
            <Piece
              key={index}
              index={index}
              piece={piece}
              canDelete={form.pieces.length > 1}
            />
          ))}
        </tbody>
        <tbody>
          <tr>
            <td
              className="table__td table__td--product-search align-center"
              colSpan="5"
            >
              <button
                className="btn btn--link fs-n0"
                onClick={() =>
                  addFormArrayElement(
                    MODAL_FORM,
                    ['pieces'],
                    defaultPieceFormSelector(getState()),
                  )
                }
              >
                + Add another pickup group
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="divider--top divider--darken margin-top-10">
        <label
          className="unbold fs-n0 margin-bottom-0"
          htmlFor="id_has_overweight_packages"
        >
          <input
            type="checkbox"
            className="margin-right-5"
            name="id_has_overweight_packages"
            id="id_has_overweight_packages"
            checked={form.hasOverweightPackages}
            onChange={() =>
              updateModalForm({
                hasOverweightPackages: !form.hasOverweightPackages,
              })
            }
          />
          <span>This pickup will have overweight packages</span>
        </label>
      </div>
    </div>
  )
}
