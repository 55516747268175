import {useEffect} from 'react'

import {useSelector} from '../../store.js'
import {
  onClick,
  onBlur,
  setCurrentDropdown,
} from '../../redux/actions/ui/currentDropdown.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import {HK_CANCEL} from '../constants/HotKeys.js'
import {HotKeyConnect} from './HotKeys.js'

export default function CurrentDropdownListener() {
  const currentDropdown = useSelector(currentDropdownSelector)

  useEffect(() => {
    const onClickHandle = (event) => onClick(event)

    document.addEventListener('click', onClickHandle, false)

    return () => document.removeEventListener('click', onClickHandle)
  }, [])

  useEffect(() => {
    const onBlurHandle = (event) => onBlur(event)

    document.addEventListener('blur', onBlurHandle, true)

    return () => document.removeEventListener('blur', onBlurHandle)
  }, [])

  if (!currentDropdown) {
    return null
  }

  return (
    <HotKeyConnect code={HK_CANCEL} func={() => setCurrentDropdown(null)} />
  )
}
