import {useEffect} from 'react'

import {useSelector} from '../../../store.js'
import {allSuppliersSortedByNameSelector} from '../../../data/suppliers.js'
import {
  settingsSupplierIDSelector,
  settingsSupplierAddSelector,
  setupForEdit,
  setupForAdding,
  resetSuppliersForm,
} from './suppliersFunctions.js'

import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export default function List() {
  const suppliers = useSelector(allSuppliersSortedByNameSelector)

  const settingsSupplierID = useSelector(settingsSupplierIDSelector)
  useEffect(() => {
    if (!settingsSupplierID) {
      return
    }

    setupForEdit()
  }, [settingsSupplierID])

  const settingsSupplierAdd = useSelector(settingsSupplierAddSelector)
  useEffect(() => {
    if (!settingsSupplierAdd) {
      return
    }

    setupForAdding()
  }, [settingsSupplierAdd])

  useEffect(() => {
    if (settingsSupplierID || settingsSupplierAdd) {
      return
    }

    resetSuppliersForm()
  }, [settingsSupplierID, settingsSupplierAdd])

  return (
    <ul className="settings-list medium-12 columns">
      {suppliers.map((supplier) => (
        <EditForm key={supplier.id} supplier={supplier} />
      ))}
      <NewForm />
    </ul>
  )
}
