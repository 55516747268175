import PropTypes from 'prop-types'

import {getState, updateForm} from '../../store.js'
import {ProductShape} from '../../common/PropTypes.js'
import {EXPAND_MODE_EXPANDED} from '../../common/components/List/ExpandAllButton.js'
import api from '../../common/api.js'
import {
  PO_DETAIL_FORM,
  expandedGoodsReceiptIDsSelector,
  poIDSelector,
} from './poDetailSelectors.js'
import {ensurePO, setPO} from '../../data/pos.js'
import {showGlobalError} from '../GlobalErrorMessage.js'

export const POFormShape = PropTypes.shape({
  selectedItemIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  addLineError: PropTypes.string,
  expandedGoodsReceiptIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  goodsReceiptsExpandMode: PropTypes.string.isRequired,
  isAddingProduct: PropTypes.bool.isRequired,
  afterAddingProduct: PropTypes.bool.isRequired,
  tempProduct: ProductShape,
})

export function setPODetailForm() {
  return {
    formName: PO_DETAIL_FORM,
    initialForm: {
      selectedItemIDs: [],
      addLineError: null,
      expandedGoodsReceiptIDs: [],
      goodsReceiptsExpandMode: EXPAND_MODE_EXPANDED,
      isAddingProduct: false,
      afterAddingProduct: false,
    },
  }
}

export function updatePODetailForm(...args) {
  updateForm(PO_DETAIL_FORM, ...args)
}

export function setGoodsReceiptExpandMode(goodsReceiptsExpandMode) {
  updatePODetailForm(
    {
      expandedGoodsReceiptIDs: [],
      goodsReceiptsExpandMode,
    },
    {stickyProps: ['goodsReceiptsExpandMode']},
  )
}

export async function addPurchaseOrderLine(poID, product) {
  try {
    updatePODetailForm({addLineError: null, tempProduct: product})

    const params = {
      quantity: 1,
      sku: product.sku,
    }

    const {json: purchaseOrder} = await api.post(
      `/purchase_order/${encodeURIComponent(poID)}/line/`,
      params,
    )

    setPO(purchaseOrder)
  } catch (err) {
    updatePODetailForm({
      addLineError: err.message || err.error_message,
    })
  } finally {
    updatePODetailForm({
      tempProduct: null,
      isAddingProduct: false,
      afterAddingProduct: true,
    })
  }
}

export function goodsReceiptToggleExpanded(goodsReceiptID) {
  const expandedGoodsReceiptIDs = expandedGoodsReceiptIDsSelector(getState())

  const goodsReceiptIDs = expandedGoodsReceiptIDs.filter(
    (id) => id !== goodsReceiptID,
  )

  if (!expandedGoodsReceiptIDs.includes(goodsReceiptID)) {
    goodsReceiptIDs.push(goodsReceiptID)
  }

  updatePODetailForm({expandedGoodsReceiptIDs: goodsReceiptIDs})
}

export async function getCurrentDetailPO() {
  const poID = poIDSelector(getState())

  try {
    if (!poID) {
      return
    }

    await ensurePO(poID, {reload: true})
  } catch (err) {
    if (!err.response || err.response.status !== 404) {
      showGlobalError(
        {
          summary: `There was an error loading Purchase Order Details for ${poID}.`,
          details: err.message,
        },
        err,
      )
    }
  }
}
