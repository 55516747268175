import PropTypes from 'prop-types'
import snakeCase from 'lodash/snakeCase.js'

import {SelectOptionsShape} from '../PropTypes.js'
import {PRIVACY_MASK} from '../analytics/index.js'

export default function Select({
  options,
  children,
  className,
  errorMessage,
  id,
  label,
  labelClassName,
  required,
  fsExclude,
  onChange,
  ...props
}) {
  id = id || (label ? snakeCase(label) : undefined)

  return (
    <>
      {label && id && (
        <label className={labelClassName} htmlFor={id}>
          <span>{label}</span>
          {required ? <span className="required">*</span> : null}
        </label>
      )}
      <select
        className={`select ${className || ''} ${
          fsExclude ? PRIVACY_MASK : ''
        } `}
        id={id}
        onChange={(event) => onChange(event.target.value, event)}
        {...props}
      >
        {children}
        {options &&
          options.map(({value, display, disabled}, index) => (
            <option
              key={disabled ? `disabled_${index}` : value}
              value={
                value !== undefined ? value : `value_is_undefined_${index}`
              }
              disabled={disabled}
            >
              {display}
            </option>
          ))}
      </select>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

Select.propTypes = {
  options: SelectOptionsShape,
  children: PropTypes.node,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
  fsExclude: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}
