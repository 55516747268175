import {EXPAND_MODE_EXPANDED} from '../components/List/ExpandAllButton.js'

export const INITIATED_STATUS = 'initiated'
export const ACTIVE_STATUS = 'active'
export const FINISHED_STATUS = 'finished'
export const CANCELLED_STATUS = 'cancelled'
export const ALL_STATUS = 'all'

export const RETURN_ORDER_STATUS_DISPLAY = {
  [INITIATED_STATUS]: 'Initiated',
  [ACTIVE_STATUS]: 'Active',
  [FINISHED_STATUS]: 'Finished',
  [CANCELLED_STATUS]: 'Cancelled',
  [ALL_STATUS]: 'All',
}

export const RETURN_ORDER_STATUS_OPTIONS = [
  {
    display: RETURN_ORDER_STATUS_DISPLAY[INITIATED_STATUS],
    value: INITIATED_STATUS,
    badge: 'I',
  },
  {
    display: RETURN_ORDER_STATUS_DISPLAY[ACTIVE_STATUS],
    value: ACTIVE_STATUS,
    badge: 'A',
  },
  {
    display: RETURN_ORDER_STATUS_DISPLAY[FINISHED_STATUS],
    value: FINISHED_STATUS,
    badge: 'F',
  },
  {
    display: RETURN_ORDER_STATUS_DISPLAY[CANCELLED_STATUS],
    value: CANCELLED_STATUS,
    badge: 'C',
  },
  {
    display: RETURN_ORDER_STATUS_DISPLAY[ALL_STATUS],
    value: ALL_STATUS,
    badge: 'A',
  },
]

export const RETURN_ORDER_STATII = RETURN_ORDER_STATUS_OPTIONS.map(
  ({value}) => value,
)

export const DEFAULT_EXPAND_MODE = EXPAND_MODE_EXPANDED
export const DEFAULT_STATUS = INITIATED_STATUS
export const DEFAULT_SORT = '-updated_date'
export const DEFAULT_PER_PAGE = 10

export const RETURN_ORDER_SORT_OPTIONS = [
  {value: 'reference_id', display: 'RMA ID (A→Z)'},
  {value: '-reference_id', display: 'RMA ID (Z→A)'},
  {value: 'status', display: 'Status'},
  {value: '-status', display: 'Status (Reversed)'},
  {value: 'warehouse_name', display: 'Warehouse Name (A→Z)'},
  {value: '-warehouse_name', display: 'Warehouse Name (Z→A)'},
  {value: '-created_date', display: 'Date Created (Newest)'},
  {value: 'created_date', display: 'Date Created (Oldest)'},
  {value: '-updated_date', display: 'Date Updated (Newest)'},
  {value: 'updated_date', display: 'Date Updated (Oldest)'},
]

export const RETURN_ORDER_PLURAL_URI_COMPONENT = 'returns'
export const RETURN_ORDER_SINGLE_URI_COMPONENT = 'rma'
