import {isPositiveNumeric} from '../../common/utils.js'
import Select from '../../common/components/Form/Select.js'
import NumberInput from '../../common/components/Form/NumberInput.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {
  mockAbodeUpdateResourceQuery,
  mockAbodeSendAsEmail,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'

export default function MockAbodeEmailOptions() {
  const query = useSelector(querySelector)
  const mock = JSON.parse(query.mock || '{}')
  return (
    <>
      <Select
        label="Layout"
        name="mock_abode__template"
        value={query.template}
        onChange={(value) => mockAbodeUpdateResourceQuery({template: value})}
        className="margin-bottom-20"
      >
        {[
          {value: 'email_apv_adjustment', display: 'Apv Adjustment'},
          {value: 'email_deactivate', display: 'Deactivate'},
          {value: 'email_dropship', display: 'Dropship'},
          {
            value: 'email_dropship_packing_list',
            display: 'Dropship Packing List',
          },
          {value: 'email_ebay_signup', display: 'Ebay Signup'},
          {value: 'email_feedback', display: 'Feedback'},
          {value: 'email_gdpr', display: 'GDPR'},
          {value: 'email_gdpr_data_request', display: 'GDPR Data Request'},
          {value: 'email_invoice', display: 'Invoice'},
          {value: 'email_low_quantity', display: 'Low Quantity'},
          {value: 'email_packing_list', display: 'Packing List'},
          {value: 'email_password_reset', display: 'Password Reset'},
          {
            value: 'email_return_order_contents',
            display: 'Return Order Contents',
          },
          {value: 'email_return_order_label', display: 'Return Order Label'},
          {value: 'email_supplier', display: 'Supplier'},
          {value: 'email_tracking', display: 'Tracking'},
          {value: 'email_welcome', display: 'Welcome'},
        ].map(({value, display}) => (
          <option key={value} value={value}>
            {display}
          </option>
        ))}
      </Select>
      <div>
        <label htmlFor="mock_abode__seed">
          <span>Seed</span>
        </label>
        <NumberInput
          id="mock_abode__seed"
          value={mock.seed}
          min={0}
          onChange={(value) =>
            mockAbodeUpdateResourceQuery({
              mock: JSON.stringify({
                ...mock,
                seed: isPositiveNumeric ? Number(value) : undefined,
              }),
            })
          }
        />
      </div>
      <ButtonPrimary
        className="margin-top-20 margin-bottom-20"
        onClick={() => mockAbodeSendAsEmail()}
      >
        Send as Email
      </ButtonPrimary>
    </>
  )
}
