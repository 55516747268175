import PropTypes from 'prop-types'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {NEW_ID} from '../../../../common/constants/index.js'
import {ShipperFormShape, ErrorsShape} from '../../../../common/PropTypes.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../common/components/Button/ButtonSecondary.js'
import {saveShipperXDelivery} from '../../../../redux/actions/ui/settings/shippers/forms/xDelivery.js'
import {
  errorsOfChangedSelector,
  hasErrorsSelector,
} from '../../../../redux/selectors/ui/settings/shippers/index.js'
import Archive from './Archive.js'
import Nickname from './Nickname.js'

function XDeliveryForm({
  form: {id, username, password, isSaving},
  hasErrors,
  errors,
  cancel,
  ...props
}) {
  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        <fieldset>
          <ul className="form-list margin-bottom-0">
            {id !== NEW_ID && <Nickname shipperID={id} />}
            <li
              className={classNames(
                'form-list-item bigger margin-bottom-20',
                {error: errors.username},
                {'margin-top-20 divider--top': id !== NEW_ID},
              )}
            >
              <label htmlFor="id_x_delivery_username">
                API ID<span className="required">*</span>
              </label>
              <input
                type="text"
                name="x_delivery_username"
                id="id_x_delivery_username"
                value={username}
                onChange={(event) =>
                  props.updateForm(id, {username: event.target.value})
                }
              />
              {errors.username && (
                <small className="error error-message">{errors.username}</small>
              )}
            </li>
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.password,
              })}
            >
              <label htmlFor="id_x_delivery_password">
                API Key<span className="required">*</span>
              </label>
              <input
                type="password"
                name="x_delivery_password"
                id="id_x_delivery_password"
                value={password}
                onChange={(event) =>
                  props.updateForm(id, {password: event.target.value})
                }
              />
              {errors.password && (
                <small className="error error-message">{errors.password}</small>
              )}
            </li>
            <li className="list__item list__item--no-style">
              <ButtonPrimary
                onClick={() => props.saveShipperXDelivery(id)}
                isDisabled={hasErrors}
                isLoading={isSaving}
              >
                {id !== NEW_ID ? 'Save' : 'Create'}
              </ButtonPrimary>
              <ButtonSecondary onClick={() => cancel()}>Cancel</ButtonSecondary>
            </li>
          </ul>
        </fieldset>
      </div>
      <div className="medium-5 columns">
        <fieldset>
          <div className="alert margin-bottom-10 alert--standard">
            {id === NEW_ID ? (
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Need help setting up your X Delivery account?
                </dt>
                <dd className="list__item fs-n0 margin-bottom-0">
                  Please contact{' '}
                  <a href="mailto:support@ordoro.com">support@ordoro.com</a> to
                  have this special shipper enabled in your account.
                  <div>
                    <a
                      className="btn--link mid meta-ups-setup-help"
                      href="https://support.ordoro.com/how-do-i-set-up-x-delivery-in-ordoro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More info →
                    </a>
                  </div>
                </dd>
              </dl>
            ) : (
              <Archive shipperID={id} />
            )}
          </div>
        </fieldset>
      </div>
    </div>
  )
}

XDeliveryForm.propTypes = {
  form: ShipperFormShape,
  errors: ErrorsShape.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  updateForm: PropTypes.func.isRequired,
  saveShipperXDelivery: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

function mapStateToProps(state, props) {
  return {
    errors: errorsOfChangedSelector(state, props),
    hasErrors: hasErrorsSelector(state, props),
  }
}

const mapDispatchToProps = {
  saveShipperXDelivery,
}

export default connect(mapStateToProps, mapDispatchToProps)(XDeliveryForm)
