import INITIAL_STATE from '../INITIAL_STATE.js'
import {
  SET_RETURN_ORDERS,
  SET_RETURN_ORDER,
  SET_RETURN_ORDER_LINE,
  PATCH_RETURN_ORDER_TAG,
} from '../../actions/data/returnOrders.js'

export default function reducer(
  state = INITIAL_STATE.data.returnOrders,
  action = {},
) {
  if (action.type === SET_RETURN_ORDER) {
    return {
      ...state,
      [action.payload.reference_id]: action.payload,
    }
  }

  if (action.type === SET_RETURN_ORDERS) {
    const returnOrders = action.payload

    return {
      ...state,
      ...returnOrders.reduce((prev, returnOrder) => {
        prev[returnOrder.reference_id] = returnOrder

        return prev
      }, {}),
    }
  }

  if (action.type === SET_RETURN_ORDER_LINE) {
    const {referenceID, returnOrderLine} = action.payload

    const updatedReturnOrder = {
      ...state[referenceID],
      items: state[referenceID].items.map((item) => {
        if (item.line_id === returnOrderLine.line_id) {
          return returnOrderLine
        }

        return item
      }),
    }

    return {
      ...state,
      [referenceID]: updatedReturnOrder,
    }
  }

  if (action.type === PATCH_RETURN_ORDER_TAG) {
    const {referenceID, returnOrderTag, needsAdding} = action.payload
    const returnOrder = state[referenceID]

    if (!returnOrder || !returnOrderTag) {
      return state
    }

    const tags = returnOrder.tags.filter(({id}) => id !== returnOrderTag.id)

    if (needsAdding) {
      tags.push(returnOrderTag)
    }

    return {
      ...state,
      [referenceID]: {
        ...returnOrder,
        tags,
      },
    }
  }

  return state
}
