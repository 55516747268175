import {useEffect} from 'react'

import {useSelector, dispatch} from '../../store.js'
import {ORDER_LIST_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {setupOrderListForm, updateOrderListForm} from './orderListActions.js'
import {orderListQuerySelector} from './orderListSelectors.js'
import {LIST_MODE} from '../../common/constants/index.js'

export default function OrderListLocationListener() {
  const query = useSelector(orderListQuerySelector)

  useEffect(() => {
    setupOrderListForm()
  }, [])

  useEffect(() => {
    if (query.mode !== LIST_MODE) {
      return
    }

    dispatch(setCurrentPage(ORDER_LIST_PAGE))
  }, [query.mode])

  // When the status changes cache the value, so we can use this info to
  // get back to this status from the order detail page. THIS IS DUMB,
  // PLEASE STOP. The browser already caches this info.
  useEffect(() => {
    if (query.mode !== LIST_MODE) {
      return
    }

    updateOrderListForm({cachedOrderListStatus_DO_NOT_USE: query.status})
  }, [query.status])

  return null
}
