import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import cloneDeep from 'lodash/cloneDeep.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  useForm,
  setFormValue,
  dispatch,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  canSyncTrackingSelector,
  cartSelector,
  cartVendorNameSelector,
  getHasLinkedWarehouses,
  hasMultiLocationsSelector,
  multiLocationsSelector,
  updateCartWarehouseLinks,
} from '../../../data/carts.js'
import {
  warehouseNameSelector,
  warehousesSortedByNameSelector,
} from '../../../data/warehouses.js'
import {usesInventorySelector} from '../../../data/company.js'
import Helper from '../Common/Helper.js'
import {isNumeric} from '../../../common/utils.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {showWarehouseModal} from '../../Modals/WarehouseModal.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import {createTask} from '../../../redux/actions/data/tasks.js'
import className from '../../../common/className.js'

export const MODAL_FORM = 'EDIT_CART_LOCATION_WAREHOUSE_MODAL'

export async function showEditCartLocationWarehousesModal({
  cartID,
  isNew = false,
  isNotice = true,
} = {}) {
  const cart = cartID ? cartSelector(getState(), {cartID}) : null

  setForm(MODAL_FORM, {
    cartID,
    isNotice,
    isNew,
    cweb: cart ? cloneDeep(cart.cweb) : {},
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function currentCartHasLinkedWarehousesSelector(state) {
  const form = modalFormSelector(state)
  const warehouses = warehousesSortedByNameSelector(state)
  const hasMultiLocations = hasMultiLocationsSelector(state, {
    cartID: form.id,
  })

  return getHasLinkedWarehouses(form.cweb, hasMultiLocations, warehouses)
}

export async function saveWarehouseLinks({startTask = false} = {}) {
  try {
    const {cartID, cweb} = modalFormSelector(getState())

    await updateCartWarehouseLinks(cartID, cweb)

    if (startTask) {
      await createTask('cart_configured', cartID)

      dispatch(checkRunningTasks())
    }

    closeModal()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

function WarehouseCheckBox({cartID, warehouseID}) {
  const form = useForm(MODAL_FORM)
  const id = `${MODAL_FORM}_${cartID}_warehouse_${warehouseID}`
  const isLinked = get(form, ['cweb', warehouseID, 'include']) !== false
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID}),
  )

  return (
    <div className="list__item--form margin-bottom-10">
      <input
        type="checkbox"
        className="margin-bottom-0"
        id={id}
        checked={isLinked}
        onChange={() =>
          setFormValue(MODAL_FORM, ['cweb', warehouseID, 'include'], !isLinked)
        }
      />
      <label htmlFor={id} className="fs-n0">
        {warehouseName}
      </label>
    </div>
  )
}

WarehouseCheckBox.propTypes = {
  cartID: PropTypes.number.isRequired,
  warehouseID: PropTypes.number.isRequired,
}

function MultiWarehouse({cartID, warehouseID}) {
  const form = useForm(MODAL_FORM)

  const warehouseForm = form.cweb[warehouseID] || {include: true, locations: []}
  const warehouseName = useSelector((state) =>
    warehouseNameSelector(state, {warehouseID}),
  )
  const multiLocations = useSelector((state) =>
    multiLocationsSelector(state, {cartID}),
  )

  const id = `${MODAL_FORM}_${cartID}_warehouse_${warehouseID}`

  return (
    <tr>
      <td className="table__td table__td--sm v-align-middle">
        <label className="margin-bottom-0" htmlFor={id}>
          <input
            className="checkbox v-align-middle checkbox--multi-location margin-bottom-0 margin-right-5 "
            type="checkbox"
            id={id}
            checked={warehouseForm.include}
            onChange={() => {
              const include = !warehouseForm.include
              let locations = warehouseForm.locations

              if (!include) {
                locations = []
              }

              if (include && locations.length === 0) {
                const firstLocationID = get(multiLocations, [0, 'id'])

                locations = firstLocationID ? [firstLocationID] : []
              }

              setFormValue(MODAL_FORM, ['cweb', warehouseID], {
                include,
                locations,
              })
            }}
          />
          <span className="fs-n1 lh-sm v-align-middle">{warehouseName}</span>
        </label>
      </td>
      <td className="table__td table__td--sm align-center v-align-middle">
        <strong className="fs-01 v-align-middle text--lt-grey">→</strong>
      </td>
      <td className="table__td table__td--sm v-align-middle">
        <select
          className="select w-100"
          value={get(warehouseForm, 'locations[0]', '')}
          disabled={!warehouseForm.include}
          onChange={(event) => {
            let value = event.target.value
            value = isNumeric(value) ? Number(value) : value

            setFormValue(MODAL_FORM, ['cweb', warehouseID], {
              include: warehouseForm.include,
              locations: value ? [value] : [],
            })
          }}
        >
          <option key="" />
          {multiLocations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </select>
      </td>
    </tr>
  )
}

MultiWarehouse.propTypes = {
  cartID: PropTypes.number.isRequired,
  warehouseID: PropTypes.number.isRequired,
}

function EditCartLocationWarehousesModal({form}) {
  const warehouses = useSelector(warehousesSortedByNameSelector)
  const hasMultiLocations = useSelector((state) =>
    hasMultiLocationsSelector(state, {cartID: form.cartID}),
  )
  const cartVendorName = useSelector((state) =>
    cartVendorNameSelector(state, {cartID: form.cartID}),
  )
  const hasLinkedWarehouses = useSelector(
    currentCartHasLinkedWarehousesSelector,
  )
  const usesInventory = useSelector(usesInventorySelector)
  const canSyncTracking = useSelector((state) =>
    canSyncTrackingSelector(state, {cartID: form.cartID}),
  )

  const needsMappingNotice = hasMultiLocations && form.isNotice
  const needsMappingInstructions = hasMultiLocations

  return (
    <ConfirmModal
      title="Update Warehouse Mappings"
      modalSize=""
      confirmText="Save"
      onConfirm={() => saveWarehouseLinks()}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      MiddleButtons={
        needsMappingNotice && form.isNew
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isLoading={form.isSaving}
                onClick={() => saveWarehouseLinks({startTask: true})}
              >
                Save and Import
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      error={form.serverError}
    >
      {form.cartID && (
        <>
          {(needsMappingNotice || needsMappingInstructions) && (
            <div className="alert alert--warning margin-bottom-25">
              {!needsMappingNotice ? null : form.isNew ? (
                <>
                  <p className="fs-01 align-center lh-md margin-bottom-3">
                    <strong>
                      Map Warehouses to {cartVendorName} Locations
                    </strong>
                  </p>
                  <p className="fs-00 align-center lh-md margin-bottom-0">
                    To make sure your connection works correctly, please map
                    your Ordoro warehouses to their corresponding locations in{' '}
                    {cartVendorName}.
                  </p>
                </>
              ) : (
                <>
                  <p className="fs-01 align-center lh-md margin-bottom-3">
                    <strong>
                      Review Your Warehouse/{cartVendorName} Location Mappings
                    </strong>
                  </p>
                  <p className="fs-00 align-center lh-md margin-bottom-0">
                    To ensure your connection continues to work correctly,
                    please double-check your Ordoro warehouse and{' '}
                    {cartVendorName} Location mappings.
                  </p>
                </>
              )}
              {needsMappingInstructions && (
                <>
                  <p
                    className={className`fs-00 lh-md ${
                      needsMappingNotice
                        ? 'divider--top margin-top-15 padding-top-15'
                        : 'margin-top-5'
                    } margin-bottom-15`}
                  >
                    <strong>
                      What happens with my orders and inventory when mapping{' '}
                      {cartVendorName} locations to Ordoro warehouses?
                    </strong>
                  </p>
                  <ul className="list list--square margin-bottom-15">
                    <li className="fs-n0 lh-md margin-bottom-15">
                      <dl className="list">
                        <dt className="list__title fs-n0 lh-md margin-bottom-1">
                          One {cartVendorName} Location to One Ordoro Warehouse
                        </dt>
                        <dd className="list__item fs-n0 lh-md margin-bottom-0">
                          Orders and inventory will be assigned to the{' '}
                          <em>mapped</em> warehouses.
                        </dd>
                      </dl>
                    </li>
                    <li className="fs-n0 lh-md margin-bottom-15">
                      <dl className="list">
                        <dt className="list__title fs-n0 lh-md margin-bottom-1">
                          One {cartVendorName} Location to Multiple Ordoro
                          Warehouses
                        </dt>
                        <dd className="list__item fs-n0 lh-md margin-bottom-0">
                          Orders and inventory will be assigned to the{' '}
                          <em>default</em> warehouse.
                        </dd>
                      </dl>
                    </li>
                  </ul>
                  <p className="fs-n0 lh-md margin-bottom-5">
                    <em>
                      For more info, see this{' '}
                      <a
                        href="https://support.ordoro.com/how-to-map-your-ordoro-warehouses-and-shopify-locations/"
                        title="What does this mean?"
                        className="btn btn--link v-align-base"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        comprehensive support article
                      </a>{' '}
                      →
                    </em>
                  </p>
                </>
              )}
            </div>
          )}
          <p className="fs-00 margin-bottom-10">
            {canSyncTracking ? (
              <>
                <strong className="margin-right-3">
                  {usesInventory
                    ? 'Inventory and Tracking Sync'
                    : 'Tracking Sync'}
                </strong>
                <a
                  href="https://support.ordoro.com/shopify-locations-does-it-matter-which-ordoro-warehouse-is-mapped/"
                  title="What does this mean?"
                  className="btn btn--link no-underline v-align-middle"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    className="icon icon-support icon-block fs-n0"
                    aria-hidden="true"
                  />
                </a>
              </>
            ) : (
              <strong>Inventory Sync</strong>
            )}
          </p>
          {!hasLinkedWarehouses && (
            <Helper
              title="Syncing is completely disabled"
              type="warning-lt"
              className="margin-bottom-10"
            >
              Without any warehouses selected, this sales channel will not have
              any inventory levels written back to it.
            </Helper>
          )}
          {hasMultiLocations ? (
            <table className="table table--multi-location margin-top-5">
              <thead>
                <tr>
                  <th className="table__th table__th--sm fs-n1 text--md-grey w-40">
                    Ordoro Warehouse
                  </th>
                  <th className="table__th table__th--sm fs-n1 text--md-grey">
                    &nbsp;
                  </th>
                  <th className="table__th table__th--sm fs-n1 text--md-grey w-40">
                    <span className="margin-right-3">
                      {cartVendorName} Location
                    </span>
                    <a
                      href="https://support.ordoro.com/shopify-locations-and-ordoro-setup/"
                      title="What does this mean?"
                      className="btn btn--link no-underline v-align-middle"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span
                        className="icon icon-support icon-block fs-n0"
                        aria-hidden="true"
                      />
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody className="table__tbody--multi-location">
                {warehouses.map(({id}) => (
                  <MultiWarehouse
                    key={id}
                    cartID={form.cartID}
                    warehouseID={id}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="list--form">
              {warehouses.map(({id}) => (
                <WarehouseCheckBox
                  key={id}
                  cartID={form.cartID}
                  warehouseID={id}
                />
              ))}
            </div>
          )}
          <ButtonPrimary
            size="sm"
            isOutlined
            onClick={() => showWarehouseModal({openedFrom: MODAL_FORM})}
          >
            Add a Warehouse
          </ButtonPrimary>
        </>
      )}
    </ConfirmModal>
  )
}

EditCartLocationWarehousesModal.propTypes = {
  form: PropTypes.shape({
    cartID: PropTypes.any,
    isNew: PropTypes.bool.isRequired,
    isNotice: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(EditCartLocationWarehousesModal, modalFormSelector)
