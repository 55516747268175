import {useEffect, useMemo} from 'react'
import isNil from 'lodash/isNil.js'

import {
  productFilterOptionsSelector,
  productFilterOptionTokensSelector,
} from '../../../../data/products.js'
import {
  modalFormSelector,
  updateModalForm,
} from './productListFilterModalFunctions.js'
import {PRODUCT_BOOLEAN_FILTERS} from '../../../../common/constants/Products.js'

import {
  formSelector,
  getState,
  updateForm,
  useAutoForm,
  useSelector,
} from '../../../../store.js'
import {filterOptions} from '../../../../common/tokenizeOptions.js'
import Checkbox from '../../../../common/components/Checkbox.js'
import {
  Count,
  Plural,
  PluralBlock,
} from '../../../../common/components/Plural.js'

const formName = 'FILTER_PANEL__FILTERS'

function filter(formName, options, optionTokens) {
  const form = formSelector(getState(), {formName})

  if (!form) {
    return
  }

  if (!form.text) {
    updateForm(formName, {
      results: [...options],
      hasFoundNothing: false,
    })

    return
  }

  const results = filterOptions(form.text, optionTokens)

  updateForm(formName, {
    results: results,
    hasFoundNothing: results.length === 0,
  })
}

export function clearStatusFilterPanelSearch() {
  updateForm(formName, {text: ''})
}

export default function FiltersPanel() {
  const modalForm = useSelector(modalFormSelector)
  const productFiltersOptions = useSelector(productFilterOptionsSelector)
  const productFiltersOptionTokens = useSelector(
    productFilterOptionTokensSelector,
  )

  const label = 'Status'
  const searchPlaceholder = 'Search for a status filter...'
  const selectedCount = useMemo(
    () =>
      Object.entries(modalForm).reduce(
        (prev, [key, value]) =>
          PRODUCT_BOOLEAN_FILTERS.includes(key) && !isNil(value)
            ? prev + 1
            : prev,
        0,
      ),
    [modalForm],
  )

  const form = useAutoForm(formName, {
    text: '',
    results: [],
    hasFoundNothing: false,
    showAll: false,
  })

  useEffect(() => {
    filter(formName, productFiltersOptions, productFiltersOptionTokens)
  }, [form.text, productFiltersOptions])

  const [optionsToShow, hiddenSelected] = useMemo(
    () =>
      form.results.reduce(
        (prev, option, index) => {
          if (form.showAll || index < 6) {
            prev[0].push(option)
          } else if (!isNil(modalForm[option.urlValue])) {
            prev[1].push(option.urlValue)
          }
          return prev
        },
        [[], []],
      ),
    [form.results, form.showAll, modalForm],
  )

  return (
    <div className={`wrap--modal-filters wrap--modal-filters-status`}>
      <div className="wrap__modal-subheader margin-bottom-25 flex--justify">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0 flex--justify-col">
          <span>
            <strong>{label}</strong>
            {selectedCount > 0 && (
              <PluralBlock count={selectedCount}>
                <span className="lowercase text--blue margin-left-5">
                  (<Count /> <Plural word="filter" p="s" /> selected)
                </span>
              </PluralBlock>
            )}
          </span>
        </h4>
        {productFiltersOptions.length > 6 && (
          <div className="wrap--filter-search w-35">
            <label
              className="label--input-prefix label--search"
              htmlFor="filters_status_search"
            >
              <input
                className="input--search input--lighter inline-block v-align-middle"
                id="filters_status_search"
                type="text"
                placeholder={searchPlaceholder || `Search for a filter...`}
                value={form.text}
                onChange={(event) =>
                  updateForm(formName, {text: event.target.value})
                }
              />
            </label>
          </div>
        )}
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {optionsToShow.map((option) => (
          <li
            key={option.urlValue}
            className="list__item list__item--modal-filters flex"
          >
            <Checkbox
              mode="fancy"
              id={`${formName}_${option.urlValue}`}
              checked={!isNil(modalForm[option.urlValue])}
              label={
                modalForm[option.urlValue] === false
                  ? option.reverseDisplay
                  : option.display
              }
              onChange={() =>
                updateModalForm({
                  [option.urlValue]: !isNil(modalForm[option.urlValue])
                    ? null
                    : true,
                })
              }
            />
            <button
              type="button"
              className="btn btn--link btn--filter-reverse no-underline fs-01"
              title="Reverse filter"
              onClick={() =>
                updateModalForm({
                  [option.urlValue]:
                    modalForm[option.urlValue] === false ? true : false,
                })
              }
            >
              <strong>⇋</strong>
            </button>
          </li>
        ))}
        {form.text !== '' && form.hasFoundNothing && (
          <li className="list__item list__item--modal-filters list__item--modal-filters-no-results fs-01">
            <strong>No filters match the current search terms.</strong>
          </li>
        )}
      </ul>
      {form.results.length > 6 && (
        <>
          <div className="wrap--filter-expand-btn">
            <button
              className="btn btn--link no-underline"
              type="button"
              onClick={() => updateForm(formName, {showAll: !form.showAll})}
            >
              <span
                className="i--expand fs-01 lh-0 v-align-middle margin-right-3"
                aria-hidden="true"
              />
              <span className="fs-n0">
                {form.showAll ? 'Show Less' : 'Show All'}
              </span>
              {hiddenSelected.length > 0 && (
                <PluralBlock array={hiddenSelected}>
                  <span className="fs-n0 text--blue margin-left-5">
                    (<Count /> hidden <Plural p="filters are" s="filter is" />{' '}
                    selected)
                  </span>
                </PluralBlock>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
