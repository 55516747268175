import PropTypes from 'prop-types'
import {useCallback, useMemo} from 'react'

import {showConfirmLabelDeleteModal} from '../../../../../OrderListPage/Modals/ConfirmLabelDeleteModal.js'
import {showEditTrackingNumberModal} from '../../../../../OrderListPage/Modals/EditTrackingNumberModal.js'
import {showPackingOrderModal} from '../../../../../PackShipPage/Modals/PackingOrderModal.js'
import {showPickOrdersModal} from '../../../../../PackShipPage/Modals/PickOrdersModal.js'
import {initiateReturn} from '../../../../../ReturnOrderListPage/Modals/CreateReturnOrderModal/createReturnOrderFunctions.js'
import {
  currencySymbolSelector,
  useManualTrackingSelector,
} from '../../../../../../data/company.js'
import {
  getIsManuallyTracked,
  getIsMarkedAsShipped,
  getIsDropshipped,
  getIsCancelled,
  getIsArchived,
  getIsMerged,
  createOrderSelector,
  isPackableOrderSelector,
} from '../../../../../../data/orders.js'
import {canUseReturnOrdersSelector} from '../../../../../../redux/selectors/data/returnOrders.js'
import {makeLabelInfoIDForOrderAndLabelTypeSelector} from '../../../../../../data/labelInfos/index.js'

import ButtonLink from '../../../../../../common/components/Button/ButtonLink.js'

import ListItem from './Fields/ListItem.js'
import Cancelled from './Cancelled.js'
import Archived from './Archived.js'
import Merged from './Merged.js'
import Dropshipment from './Dropshipment.js'
import NoShippingLabel from './NoShippingLabel.js'
import ShippingLabel from './ShippingLabel.js'
import ReturnLabel from './ReturnLabel.js'
import ManualTracking from './ManualTracking.js'
import MarkedAsShipped from './MarkedAsShipped.js'
import LinkedRMAList from './LinkedRMAList.js'
import {setSelectedOrderNumbers} from '../../../../../OrderListPage/orderListActions.js'
import {
  DROPSHIP_TAB,
  RETURN_TAB,
  SHIPPING_TAB,
  setOrderActionPanelCurrentTab,
} from '../../../../../OrderListPage/ActionPanel/orderActionPanelFunctions.js'
import {useSelector} from '../../../../../../store.js'

export default function OrderData({orderNumber, isExpanded, parentView}) {
  const shippingLabelInfoIDForOrderAndLabelTypeSelector = useMemo(() =>
    makeLabelInfoIDForOrderAndLabelTypeSelector(),
  )
  const orderSelector = useMemo(
    () => createOrderSelector(orderNumber),
    [orderNumber],
  )
  const showShippingLabelTab = useCallback(() => {
    setOrderActionPanelCurrentTab(SHIPPING_TAB)
    setSelectedOrderNumbers([orderNumber])
  })
  const showReturnLabelTab = useCallback(() => {
    setOrderActionPanelCurrentTab(RETURN_TAB)
    setSelectedOrderNumbers([orderNumber])
  }, [orderNumber])

  const labelInfoID = useSelector((state) =>
    shippingLabelInfoIDForOrderAndLabelTypeSelector(state, {
      orderNumber,
      labelType: 'shipping',
    }),
  )
  const order = useSelector((state) => orderSelector(state, {orderNumber}))
  const currencySymbol = useSelector(currencySymbolSelector)
  const isPackable = useSelector((state) =>
    isPackableOrderSelector(state, {orderNumber}),
  )
  const canUseReturnOrders = useSelector(canUseReturnOrdersSelector)
  const useManualTracking = useSelector(useManualTrackingSelector)

  if (getIsArchived(order)) {
    return (
      <div className="list list--order-data list--order-items">
        <Archived />
      </div>
    )
  } else if (getIsMerged(order)) {
    return (
      <div className="list list--order-data list--order-items">
        <Merged />
      </div>
    )
  }

  const isDropshipped = getIsDropshipped(order)
  const hasManualTracking = getIsManuallyTracked(order)
  const isMarkedAsShippped = getIsMarkedAsShipped(order)
  const isCancelled = getIsCancelled(order)

  const requestedShippingMethod = order.requested_shipping_method
  const hasNoShippingLabel =
    !order.shipping_info.has_label && !order.shipping_info.ship_date
  const hasShippingInfo = !!order.shipping_info.ship_date
  const hasShippingLabel = order.shipping_info.has_label
  const hasReturnLabel = order.return_shipping_info.has_label

  return (
    <div className="list list--order-data list--order-items">
      {isCancelled && <Cancelled key="cancelled" orderNumber={orderNumber} />}
      {isDropshipped && (
        <Dropshipment
          key="Dropshipment"
          order={order}
          isCancelled={isCancelled}
        />
      )}
      {hasNoShippingLabel && !isDropshipped && (
        <NoShippingLabel
          key="NoShippingLabel"
          orderNumber={orderNumber}
          labelInfoID={labelInfoID}
          isCancelled={isCancelled}
          isExpanded={isExpanded}
          parentView={parentView}
          requestedShippingMethod={requestedShippingMethod}
          showShippingLabelTab={showShippingLabelTab}
        />
      )}
      {hasShippingLabel && (
        <ShippingLabel
          key="ShippingLabel"
          orderNumber={orderNumber}
          order={order}
          isCancelled={isCancelled}
          isExpanded={isExpanded}
          requestedShippingMethod={requestedShippingMethod}
          parentView={parentView}
          showShippingLabelTab={showShippingLabelTab}
          deleteShippingLabel={() =>
            showConfirmLabelDeleteModal(orderNumber, 'shipping')
          }
        />
      )}
      {hasManualTracking && (
        <ManualTracking
          key="ManualTracking"
          order={order}
          isCancelled={isCancelled}
          isExpanded={isExpanded}
          orderNumber={orderNumber}
          currencySymbol={currencySymbol}
        />
      )}
      {!hasManualTracking && isMarkedAsShippped && (
        <MarkedAsShipped
          key="MarkedAsShipped"
          order={order}
          isCancelled={isCancelled}
          isExpanded={isExpanded}
          orderNumber={orderNumber}
        />
      )}
      {hasReturnLabel && (
        <ReturnLabel
          key="ReturnLabel"
          order={order}
          isCancelled={isCancelled}
          showReturnLabelTab={showReturnLabelTab}
          deleteReturnLabel={() =>
            showConfirmLabelDeleteModal(orderNumber, 'return')
          }
        />
      )}
      {useManualTracking && !hasShippingInfo && !isCancelled && (
        <ListItem className="divider--top">
          <ButtonLink onClick={() => showEditTrackingNumberModal(orderNumber)}>
            Enter tracking info
          </ButtonLink>
        </ListItem>
      )}
      {canUseReturnOrders ? (
        <ListItem key="CreateReturnOrder" className="divider--top">
          <LinkedRMAList orderNumber={orderNumber} />
          <ButtonLink onClick={() => initiateReturn(orderNumber)}>
            Initiate a return (RMA)
          </ButtonLink>
        </ListItem>
      ) : (
        !hasReturnLabel &&
        !isCancelled &&
        !isDropshipped &&
        parentView === 'ExpandedRow' && (
          <ListItem key="CreateReturnLabel" className="divider--top">
            <ButtonLink onClick={showReturnLabelTab}>
              Create a return label
            </ButtonLink>
          </ListItem>
        )
      )}
      {!isDropshipped &&
        !isCancelled &&
        isExpanded &&
        parentView === 'ExpandedRow' && (
          <ListItem key="DropshipButton" className="divider--top">
            <ButtonLink
              onClick={() => {
                setOrderActionPanelCurrentTab(DROPSHIP_TAB)
                setSelectedOrderNumbers([orderNumber])
              }}
            >
              Send to supplier/FBA for dropshipment
            </ButtonLink>
          </ListItem>
        )}
      {isPackable && !isCancelled && parentView !== 'PackShipPage' && (
        <>
          <ListItem key="PackOrderButton" className="divider--top">
            <ButtonLink onClick={() => showPackingOrderModal(orderNumber)}>
              Scan/verify package contents
            </ButtonLink>
          </ListItem>
          <ListItem key="PickOrderButton" className="divider--top">
            <ButtonLink onClick={() => showPickOrdersModal([orderNumber])}>
              Scan/pick package contents
            </ButtonLink>
          </ListItem>
        </>
      )}
    </div>
  )
}

OrderData.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  parentView: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
}
