import {Component} from 'react'

import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from './ReconcileForm.js'
import EditForm from './EditForm.js'
import actions from '../../../../redux/actions/ui/dataImport/strategies/addressStrategy.js'

export default class AddressStrategy extends Component {
  render() {
    return (
      <BaseStrategy
        {...this.props}
        actions={actions}
        EditForm={EditForm}
        ReconcileForm={ReconcileForm}
      />
    )
  }
}

AddressStrategy.title = actions.title
