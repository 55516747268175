import PropTypes from 'prop-types'
import isBefore from 'date-fns/isBefore'
import subDays from 'date-fns/subDays'

import {NEW_ID} from '../../../../../common/constants/index.js'
import {ShipperFormShape} from '../../../../../common/PropTypes.js'
import Checkbox from '../../../Common/Checkbox.js'
import ButtonPrimary from '../../../../../common/components/Button/ButtonPrimary.js'
import ButtonSecondary from '../../../../../common/components/Button/ButtonSecondary.js'
import {shipperFormSelector} from '../../../../../redux/selectors/ui/settings/shippers/index.js'
import {areAnyWarehousesConfiguredSelector} from '../../../../../data/warehouses.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../../redux/actions/ui/settings/shippers/index.js'
import Archive from '../Archive.js'
import Nickname from '../Nickname.js'
import ShippingMethods from '../ShippingMethods.js'
import {showUPSRegistrationModal} from './UPSRegistrationModal.js'
import {dispatch, getState, useSelector} from '../../../../../store.js'
import api from '../../../../../common/api.js'
import {showGlobalError} from '../../../../GlobalErrorMessage.js'
import {setShipper} from '../../../../../data/shippers.js'
import {UPS} from '../../../../../common/constants/ShipperNames.js'
import {getRealDate} from '../../../../../common/date.js'
import {redirect} from '../../../../../common/location.js'
import {showWarehouseModal} from '../../../../Modals/WarehouseModal.js'

export function authorize(shipperID) {
  const form = shipperFormSelector(getState(), {
    shipperID,
  })

  const params = {
    uses_discounted_rates: form.uses_discounted_rates,
  }

  if (shipperID && shipperID !== NEW_ID) {
    params.shipper_id = shipperID
  }

  if (form.shipperNumber) {
    params.shipper_number = form.shipperNumber
  }

  return redirect(null, ['verde', 'ups', 'start'], params)
}

export function errorsSelector() {
  return {}
}

export function setupFormSelector(state, {shipper}) {
  shipper = shipper || {id: NEW_ID, vendor: UPS}
  const vendorConfig = shipper.vendor_config || {}

  return {
    id: shipper.id,
    vendor: shipper.vendor,
    name: shipper.name || '',
    hidden_shipping_methods: shipper.hidden_shipping_methods || [],
    use_metric_units: vendorConfig.use_metric_units || false,
    cost_center: vendorConfig.cost_center || '',
    shipperNumber: vendorConfig.shipper_number || '',
    uses_discounted_rates: !!vendorConfig.uses_discounted_rates,
    billing_email: vendorConfig.billing_email || '',
    dapAccountJustCreated: shipper.created
      ? isBefore(subDays(getRealDate(), 3), new Date(shipper.created))
      : false,
    isSaving: false,
    needsInitialAuth: !vendorConfig.access_token,
  }
}

export async function saveShipperUPS(shipperID) {
  if (shipperID === NEW_ID) {
    return
  }

  const {name, hidden_shipping_methods, use_metric_units, cost_center} =
    shipperFormSelector(getState(), {
      shipperID,
    })

  const params = {
    name,
    hidden_shipping_methods,
    use_metric_units,
    cost_center,
  }

  dispatch(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} = await api.put(`/shipper/${shipperID}/`, params)

    dispatch(goToShipperSettings())

    setShipper(json)
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error while saving shipper.',
      },
      err,
    )
  } finally {
    dispatch(updateForm(shipperID, {isSaving: false}))
  }
}

export default function UPSForm({form, cancel}) {
  const {
    id,
    use_metric_units,
    cost_center,
    shipperNumber,
    uses_discounted_rates,
    billing_email,
    dapAccountJustCreated,
    needsInitialAuth,
  } = form
  const areAnyWarehousesConfigured = useSelector(
    areAnyWarehousesConfiguredSelector,
  )

  if (!areAnyWarehousesConfigured) {
    return (
      <div className="alert alert--warning-lt alert--lg align-center margin-top-20 w-65">
        <div
          className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-5"
          aria-hidden="true"
        />
        <p className="fs-01 margin-bottom-15">
          <strong>
            You need to set a ship-from/warehouse address before you can connect
            a UPS account to Ordoro.
          </strong>
        </p>
        <div>
          <ButtonPrimary alt size="md" onClick={() => showWarehouseModal()}>
            Set a Ship-From Address
          </ButtonPrimary>
        </div>
      </div>
    )
  }

  return (
    <div className="settings-list-item-form-wrap clear-both">
      <div className="medium-6 columns">
        {id !== NEW_ID && (
          <fieldset>
            <ul className="form-list margin-bottom-0">
              <Nickname shipperID={id} />
              {uses_discounted_rates &&
                (shipperNumber ? (
                  <>
                    <li className="form-list-item fs-00 margin-top-10">
                      <strong>Account Number:</strong> {shipperNumber}
                    </li>
                    {billing_email && (
                      <li className="form-list-item fs-00 margin-top-5">
                        <strong>Billing Email:</strong> {billing_email}
                      </li>
                    )}
                  </>
                ) : (
                  <div className="margin-top-20">
                    <ButtonPrimary
                      size="md"
                      className={`btn--primary-alt ${
                        uses_discounted_rates ? '' : 'btn--md'
                      }`}
                      onClick={() => showUPSRegistrationModal(id)}
                    >
                      Finish DAP setup
                    </ButtonPrimary>
                  </div>
                ))}
              {id !== NEW_ID && (
                <div className="alert alert--warning-lt alert--lg margin-top-25 margin-bottom-15 align-center">
                  <p className="fs-01 lh-md margin-bottom-15">
                    <strong>
                      {needsInitialAuth ? 'Authorize' : 'Reauthorize'} your
                      account.
                    </strong>
                  </p>

                  <p className="fs-00 lh-md margin-bottom-20">
                    You will be directed to UPS Login to{' '}
                    {needsInitialAuth ? 'authorize' : 'reauthorize'} Ordoro to
                    use your UPS account to print shipping labels.
                  </p>
                  <div className="margin-bottom-0">
                    <ButtonPrimary size="sm" onClick={() => authorize(id)}>
                      {needsInitialAuth ? 'Authorize' : 'Reauthorize'}
                    </ButtonPrimary>
                  </div>
                </div>
              )}
              <ShippingMethods shipperID={id} />
              <li className="form-list-item bigger margin-top-20 margin-bottom-20 divider--top">
                <dl className="list margin-bottom-15">
                  <dt className="list__title fs-00">
                    Do you want to convert to metric units when creating labels?
                  </dt>
                  <dd className="list__item fs-n0">
                    In Ordoro, weight and dimensions are displayed (and stored)
                    in imperial units. We can, however, convert weights and
                    dimensions to metric units while requesting rates and labels
                    from UPS. Selecting the following checkbox will make this
                    conversion happen behind-the-scenes (units will still be
                    displayed in imperial).
                  </dd>
                </dl>
                <Checkbox
                  label="Convert to metric units"
                  id="use_metric_units"
                  checked={use_metric_units}
                  onChange={() =>
                    dispatch(
                      updateForm(id, {use_metric_units: !use_metric_units}),
                    )
                  }
                />
              </li>
              <li className="list__item bigger list__item--no-style padding-bottom-5 divider--top">
                <label htmlFor="cost_center">
                  Mail Innovations Cost Center
                </label>
                <input
                  type="text"
                  id="cost_center"
                  value={cost_center}
                  onChange={(event) =>
                    dispatch(updateForm(id, {cost_center: event.target.value}))
                  }
                />
              </li>
              <li
                className={`list__item list__item--no-style margin-bottom-20 ${
                  uses_discounted_rates
                    ? 'padding-top-0'
                    : 'padding-bottom-20 divider--bottom'
                }`}
              >
                <ButtonPrimary
                  size="sm"
                  className={`btn--primary-alt ${
                    uses_discounted_rates ? '' : 'btn--sm'
                  }`}
                  onClick={() => saveShipperUPS(id)}
                >
                  Update
                </ButtonPrimary>
                {uses_discounted_rates && (
                  <ButtonSecondary size="sm" onClick={() => cancel()}>
                    Cancel
                  </ButtonSecondary>
                )}
              </li>
            </ul>
          </fieldset>
        )}
        {id === NEW_ID && (
          <ButtonPrimary
            onClick={() =>
              showUPSRegistrationModal(id === NEW_ID ? undefined : id)
            }
          >
            {id === NEW_ID ? 'Create' : 'Reauthorize'}
          </ButtonPrimary>
        )}
      </div>

      {id !== NEW_ID && (
        <div className="medium-5 columns">
          <div className="alert margin-bottom-10 alert--standard">
            <Archive shipperID={id} />
          </div>
          {!uses_discounted_rates ? (
            <div className="alert margin-top-20 alert--neutral">
              <dl className="list">
                <dt className="list__title fs-00 lh-md margin-bottom-5">
                  Book a Virtual Consultation with a UPS Solutions Expert
                </dt>
                <dd className="list__item fs-n0 margin-bottom-10">
                  Get the most out of your UPS account by meeting with one of
                  their logistics experts. They’ll analyze your supply chain,
                  tell you about new programs, and might be able to offer you
                  even better rates for your account.
                </dd>
                <dd className="list__item fs-n0 margin-bottom-0">
                  <a
                    className="btn btn--primary btn--primary-ol btn--sm meta-ups-consultation"
                    href="https://solutions.ups.com/virtual-consultation-main-ussp-page.html?WT.mc_id=ONLINE_WEB_Ordoro_Ready%7CMktgVirtualConsultationMainPage_104036"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Schedule a UPS Consultation →</strong>
                  </a>
                </dd>
              </dl>
            </div>
          ) : dapAccountJustCreated ? (
            <div className="alert margin-top-20 alert--neutral">
              <p className="fs-00 lh-x-md margin-bottom-15">
                <strong>What happens next?</strong>
              </p>
              <p className="list__item fs-00 margin-bottom-15">
                Once the account is created, UPS will send an email with your
                account number titled, “Your New UPS Account is Ready to Use.”
                Please note that UPS will bill you weekly for any labels
                created.
              </p>
              <p className="fs-00 lh-md margin-bottom-10">
                If you need further assistance, reference this handy{' '}
                <a
                  href="https://support.ordoro.com/who-are-ordoros-discounted-shipping-carriers/#ordoro-ups"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  support article{' '}
                </a>
                or contact our{' '}
                <a href="mailto:support@ordoro.com">Support Team</a>.
              </p>
            </div>
          ) : null}
          <div className="alert margin-top-20 alert--neutral">
            <p className="fs-00 lh-x-md margin-bottom-5">
              <strong>Various Terms and Conditions</strong>
            </p>
            <ul className="list list--square">
              <li className="list__item fs-00 margin-bottom-5">
                <a
                  className="btn--link mid"
                  href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/privacy-notice.page"
                >
                  UPS Privacy Notice
                </a>{' '}
                →
              </li>
              {uses_discounted_rates && (
                <li className="list__item fs-00 margin-bottom-5">
                  <a
                    className="btn--link mid"
                    href="https://www.ups.com/assets/resources/media/en_US/Ground-Saver.pdf"
                  >
                    UPS Terms and Conditions of Service
                  </a>{' '}
                  →
                </li>
              )}
              <li className="list__item fs-00 margin-bottom-0">
                <a
                  className="btn--link mid"
                  href="https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page"
                >
                  UPS Prohibited Items List
                </a>{' '}
                →
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

UPSForm.propTypes = {
  form: ShipperFormShape,
  cancel: PropTypes.func.isRequired,
}
