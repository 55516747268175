import PropTypes from 'prop-types'
import {useCallback, useEffect} from 'react'
import get from 'lodash/get.js'
import addBusinessDays from 'date-fns/addBusinessDays'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  dispatch,
  useSelector,
  useForm,
  setFormValue,
  addFormArrayElement,
  removeFormArrayElement,
} from '../../../store.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import apiverson from '../../../common/apiverson.js'
import {setBatch} from '../../../data/batches.js'
import Checkbox from '../../../common/components/Checkbox.js'
import Select from '../../../common/components/Select.js'
import {
  warehouseNameSelector,
  warehouseOptionsSelector,
} from '../../../data/warehouses.js'
import WeightInput from '../../../common/components/Form/WeightInput.js'
import {
  boxShapeSelector,
  boxShapeOptionsSelector,
  canHaveInsuranceSelector,
  dimensionHasError,
  getDimensionsErrorMessage,
  getDimensionsWarningMessage,
  includeInsuranceSelector,
  insuredValueSelector,
  labelConfigSelector,
  parcelSelector,
  removeLabelInfo,
  setLabelInfos,
  labelShipperIDSelector,
  labelShipperSelector,
  labelShipperTypeSelector,
  updateLabelConfig,
  updateParcel,
} from '../../../data/labelInfos/index.js'
import {
  shipperNameSelector,
  shipperOptionsSelector,
} from '../../../data/shippers.js'
import {DEFAULT_BOX_SHAPES} from '../../../common/constants/LabelConfig.js'
import Insurance from '../../LabelConfig/Fields/Insurance/Insurance.js'
import {DHL, SENDLE, UPS} from '../../../common/constants/ShipperNames.js'
import ForwardDate, {
  PICKUP_DATE_VALID_SHIPPER_TYPES,
  SHIP_DATE_VALID_SHIPPER_TYPES,
} from '../../LabelConfig/Fields/ForwardDate.js'
import DimensionsInput from '../../../common/components/Form/DimensionsInput.js'
import PackingList from '../../LabelConfig/Fields/PackingList.js'
import {usePresetsSelector} from '../../../data/company.js'
import PresetSelect from '../../settings/Rules/RuleAction/PresetSelect.js'
import {presetsSelector} from '../../../data/presets.js'
import ButtonIcon from '../../../common/components/Button/ButtonIcon.js'
import {boxShapeNameSelector} from '../../../data/shipperOptions.js'
import {packingListNameSelector} from '../../../redux/selectors/data/packingLists.js'
import {
  BULK_UPDATE_APPLY_PRESETS_COMMENT,
  BULK_UPDATE_DIMENSIONS_COMMENT,
  BULK_UPDATE_LABEL_INFO_CONFIG_COMMENT,
  BULK_UPDATE_PACKING_LIST_COMMENT,
  BULK_UPDATE_SHIP_DATE_COMMENT,
  BULK_UPDATE_WAREHOUSE_COMMENT,
  BULK_UPDATE_WEIGHT_COMMENT,
  BULK_UPDATE_COMMENT,
} from '../../../common/constants/Comments.js'
import LabelConfigContext, {
  useLabelConfigContext,
} from '../../LabelConfig/LabelConfigContext.js'

const MODAL_FORM = 'BULK_UPDATE_MODAL'
export const BATCH_UPDATE_LABEL_ID = 'BATCH_UPDATE_LABEL_ID'

export function showBulkUpdateModal(referenceIDs) {
  const warehouseOptions = warehouseOptionsSelector(getState())

  setForm(MODAL_FORM, {
    referenceIDs,
    includePresets: false,
    presetIDs: [''],
    includeShipFrom: false,
    warehouseID: warehouseOptions[0].value,
    includeWeight: false,
    weightOz: 0,
    includeShippingConfig: false,
    includeDimensions: false,
    includeShipDate: false,
    includePackingList: false,
    isSaving: false,
    serverError: null,
  })
}

export function setBatchLabelConfig() {
  setLabelInfos([
    {
      id: BATCH_UPDATE_LABEL_ID,
      config: {
        shipper_id: null,
        packages: [
          {
            ...DEFAULT_BOX_SHAPES,
          },
        ],
      },
    },
  ])
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function batchUpdatePayloadSelector(state) {
  let {
    includePresets,
    presetIDs,
    includeShipFrom,
    warehouseID,
    includeWeight,
    weightOz,
    includeShippingConfig,
    includeDimensions,
    includeShipDate,
    includePackingList,
  } = modalFormSelector(state)
  const presets = presetsSelector(state)
  const labelConfig = labelConfigSelector(state, {
    labelInfoID: BATCH_UPDATE_LABEL_ID,
  })

  const params = {
    action: {type: 'mult-stage-processing'},
    actions: [],
  }
  const comment = {type: BULK_UPDATE_COMMENT, actions: []}

  const labelConfigUpdates = {}

  presetIDs = presetIDs.filter((presetID) => presetID)
  if (includePresets && presetIDs.length) {
    params.actions.push({
      type: 'apply_presets',
      data: {preset_ids: presetIDs, preset_type: 'shipment_preset'},
    })

    comment.actions.push({
      type: BULK_UPDATE_APPLY_PRESETS_COMMENT,
      presets: presetIDs.map((presetID) => presets[presetID].name),
    })
  }

  if (includeShipFrom) {
    params.actions.push({
      type: 'endpoint',
      data: {
        method: 'put',
        url_parts: ['warehouse'],
        payload: {
          warehouse_id: warehouseID,
        },
      },
    })

    comment.actions.push({
      type: BULK_UPDATE_WAREHOUSE_COMMENT,
      warehouse_name: warehouseNameSelector(state, {warehouseID}),
    })
  }

  if (includeWeight) {
    labelConfigUpdates.packages = [{weight: weightOz}]

    comment.actions.push({
      type: BULK_UPDATE_WEIGHT_COMMENT,
      weight: weightOz,
    })
  }

  if (includeShippingConfig) {
    const shipperType = labelShipperTypeSelector(state, {
      labelInfoID: BATCH_UPDATE_LABEL_ID,
    })
    const boxShape = boxShapeSelector(state, {
      labelInfoID: BATCH_UPDATE_LABEL_ID,
      shipperType,
      packagesIndex: 0,
    })

    const commentAction = {
      type: BULK_UPDATE_LABEL_INFO_CONFIG_COMMENT,
      shipper_name: shipperNameSelector(state, {
        shipperID: labelConfig.shipper_id,
      }),
      box_shape_name: boxShapeNameSelector(state, {shipperType, boxShape}),
    }

    labelConfigUpdates.shipper_id = labelConfig.shipper_id
    labelConfigUpdates.packages = [
      {
        ...(labelConfigUpdates.packages || [])[0],
        [`${shipperType}__box_shape`]: boxShape,
      },
    ]

    const canHaveInsurance = canHaveInsuranceSelector(state, {
      labelInfoID: BATCH_UPDATE_LABEL_ID,
      shipperType,
    })

    if (
      canHaveInsurance &&
      labelConfig.include_insurance &&
      labelConfig.insured_value
    ) {
      labelConfigUpdates.include_insurance = labelConfig.include_insurance
      labelConfigUpdates.insured_value = labelConfig.insured_value

      commentAction.include_insurance = labelConfig.include_insurance
      commentAction.insured_value = Number(labelConfig.insured_value)
    }

    if ([SENDLE, DHL].includes(shipperType) && labelConfig.pickup_date) {
      labelConfigUpdates.pickup_date = labelConfig.pickup_date

      commentAction.pickup_date = labelConfig.pickup_date
    }

    if (shipperType === UPS && labelConfig.is_mail_innovations) {
      labelConfigUpdates.is_mail_innovations = labelConfig.is_mail_innovations

      commentAction.is_mail_innovations = labelConfig.is_mail_innovations
    }

    comment.actions.push(commentAction)
  }

  if (includeDimensions) {
    const {length, width, height} = parcelSelector(state, {
      labelInfoID: BATCH_UPDATE_LABEL_ID,
      packagesIndex: 0,
    })

    if (
      !(
        dimensionHasError(length) ||
        dimensionHasError(width) ||
        dimensionHasError(height)
      )
    ) {
      labelConfigUpdates.packages = [
        {
          ...(labelConfigUpdates.packages || [])[0],
          length,
          width,
          height,
        },
      ]

      comment.actions.push({
        type: BULK_UPDATE_DIMENSIONS_COMMENT,
        length: Number(length),
        width: Number(width),
        height: Number(height),
      })
    }
  }

  if (includeShipDate && labelConfig.ship_date) {
    labelConfigUpdates.ship_date = labelConfig.ship_date

    comment.actions.push({
      type: BULK_UPDATE_SHIP_DATE_COMMENT,
      ship_date: labelConfig.ship_date,
    })
  }

  if (includePackingList) {
    labelConfigUpdates.packing_list_id = labelConfig.packing_list_id

    comment.actions.push({
      type: BULK_UPDATE_PACKING_LIST_COMMENT,
      packing_list_name: packingListNameSelector(state, {
        packingListID: labelConfig.packing_list_id,
      }),
    })
  }

  if (Object.keys(labelConfigUpdates).length > 0) {
    params.actions.push({
      type: 'endpoint',
      data: {
        method: 'patch',
        url_parts: ['label_info'],
        payload: labelConfigUpdates,
      },
    })
  }

  if (params.actions.length) {
    params.actions.push({
      type: 'fetch_rates',
      data: {},
    })
  }

  return {params, comment: JSON.stringify(comment)}
}

export async function batchUpdate(referenceID) {
  const payload = batchUpdatePayloadSelector(getState())

  await apiverson.post(
    `/batch/${encodeURIComponent(referenceID)}/action`,
    payload,
  )

  const {json} = await apiverson.get(
    `/batch/${encodeURIComponent(referenceID)}`,
  )

  setBatch(json)
}

export async function batchUpdateAll() {
  const {referenceIDs} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true})

    await Promise.all(
      referenceIDs.map((referenceID) => batchUpdate(referenceID)),
    )

    dispatch(checkRunningTasks())

    showMessageToast(
      plural(referenceIDs.length)`A task was started to update orders for ${
        referenceIDs.length
      } batch${['es']}.`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error updating orders for batches: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function BatchPresets() {
  const form = useForm(MODAL_FORM)
  const presets = useSelector(presetsSelector)

  return (
    <div className="wrap--nested-form outer-wrap--batch-preset-bulk-update">
      {form.presetIDs.map((presetID, index) => {
        const preset = presets[presetID]
        return (
          <div
            key={index}
            className="flex inner-wrap--batch-preset-bulk-update"
          >
            <PresetSelect
              dropdown={`BATCH_PRESET_${index}`}
              label={preset ? preset.name : 'Select a preset to apply'}
              onChange={(presetID) => {
                setFormValue(MODAL_FORM, ['presetIDs', index], presetID || '')

                if (presetID && index + 1 === form.presetIDs.length) {
                  addFormArrayElement(MODAL_FORM, ['presetIDs'], '')
                }
              }}
              excludePresetIDs={form.presetIDs.filter(
                (id) => !!id && id !== presetID,
              )}
            />
            {index + 1 !== form.presetIDs.length && (
              <ButtonIcon
                iconClassName="i-trash top-zero"
                size="xx-sm"
                onClick={() =>
                  removeFormArrayElement(MODAL_FORM, ['presetIDs'], index)
                }
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

function BatchShippingConfiguration() {
  const shipperOptions = useSelector(shipperOptionsSelector)
  const shipperID = useSelector((state) =>
    labelShipperIDSelector(state, {
      labelInfoID: BATCH_UPDATE_LABEL_ID,
    }),
  )

  useEffect(() => {
    if (!shipperID) {
      updateLabelConfig(BATCH_UPDATE_LABEL_ID, {
        shipper_id: get(shipperOptions, '[0].value') || null,
      })
    }
  }, [shipperID, shipperOptions])

  if (!shipperID) {
    return null
  }

  return (
    <div className="wrap--nested-form wrap--bulk-update-shipping-config">
      <div className="margin-bottom-15">
        <Select
          id="shipper"
          label="Carrier"
          value={shipperID}
          onChange={(shipperID) =>
            updateLabelConfig(BATCH_UPDATE_LABEL_ID, {
              shipper_id: Number(shipperID) || null,
            })
          }
        >
          {shipperOptions.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </Select>
      </div>
      <div className="margin-bottom-15">
        <BatchPackageType />
      </div>
      <div className="fs-00 margin-bottom-15">
        <BatchInsurance />
      </div>
      <div className="margin-bottom-15">
        <BatchPickupDate />
      </div>
      <div className="margin-bottom-15">
        <BatchMailInnovations />
      </div>
    </div>
  )
}

function BatchPackageType() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const boxShape = useSelector((state) =>
    boxShapeSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    }),
  )
  const disabled = useSelector(
    (state) => !labelShipperSelector(state, {labelInfoID}),
  )
  const options = useSelector((state) =>
    boxShapeOptionsSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )

  const onChange = useCallback(
    (value) =>
      updateParcel(labelInfoID, 0, {
        [`${shipperType}__box_shape`]: value,
      }),
    [shipperType],
  )

  useEffect(() => {
    if (!boxShape && options.length > 0) {
      onChange(get(options, '[0].value'))
    }
  }, [boxShape, options])

  return (
    <Select
      label="Package Type*"
      value={boxShape}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map(({value, display}) => (
        <option key={value} value={value}>
          {display}
        </option>
      ))}
    </Select>
  )
}

function BatchInsurance() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const includeInsurance = useSelector((state) =>
    includeInsuranceSelector(state, {
      labelInfoID,
    }),
  )
  const insuredValue = useSelector((state) =>
    insuredValueSelector(state, {
      labelInfoID,
    }),
  )
  const canHaveInsurance = useSelector((state) =>
    canHaveInsuranceSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )

  if (!canHaveInsurance) {
    return null
  }

  return (
    <ul className="list">
      <Insurance
        includeInsurance={includeInsurance}
        toggle={() => {
          updateLabelConfig(labelInfoID, {
            include_insurance: !includeInsurance,
          })
        }}
        insuredValue={insuredValue}
        setInsuredValue={(value) => {
          updateLabelConfig(labelInfoID, {insured_value: value})
        }}
        insuranceCost={null}
      />
    </ul>
  )
}

function BatchPickupDate() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const pickupDateStr = useSelector(
    (state) => labelConfigSelector(state, {labelInfoID}).pickup_date,
  )
  const show = [SENDLE, DHL].includes(shipperType)
  const nextBusinessDay = shipperType === SENDLE

  useEffect(() => {
    if (!show || pickupDateStr) {
      return
    }
    const date = nextBusinessDay ? addBusinessDays(new Date(), 1) : new Date()

    updateLabelConfig(labelInfoID, {pickup_date: date.toISOString()})
  }, [show, pickupDateStr])

  if (!show) {
    return null
  }

  return (
    <ul className="list">
      <ForwardDate
        label="Pickup Date"
        labelProperty="pickup_date"
        nextBusinessDay={nextBusinessDay}
        validShipperTypes={PICKUP_DATE_VALID_SHIPPER_TYPES}
      />
    </ul>
  )
}

function BatchMailInnovations() {
  const {labelInfoID, shipperType} = useLabelConfigContext()
  const isMailInnovations = useSelector(
    (state) =>
      !!labelConfigSelector(state, {
        labelInfoID,
      }).is_mail_innovations,
  )

  if (shipperType !== UPS) {
    return null
  }

  return (
    <div className="list__item--shipping-options">
      <label
        className="margin-bottom-0 meta-bulklabelconfigform-input-mailinnovations"
        htmlFor="mail_innovations"
      >
        <input
          type="checkbox"
          className="margin-right-5"
          id="mail_innovations"
          checked={isMailInnovations}
          onChange={(event) => {
            updateLabelConfig(labelInfoID, {
              is_mail_innovations: event.target.checked,
            })
          }}
        />
        <span>Mail Innovations</span>
      </label>
    </div>
  )
}

function BatchDimensions() {
  const {labelInfoID} = useLabelConfigContext()
  const {length, width, height} = useSelector((state) =>
    parcelSelector(state, {
      labelInfoID,
      packagesIndex: 0,
    }),
  )
  const errorMessage = getDimensionsErrorMessage({length, width, height})
  const warningMessage = getDimensionsWarningMessage({length, width, height})

  const errors = {
    hasLengthError: dimensionHasError(length),
    hasWidthError: dimensionHasError(width),
    hasHeightError: dimensionHasError(height),
  }
  const {hasLengthError, hasWidthError, hasHeightError} = {
    ...(errors.hasLengthError && errors.hasWidthError && errors.hasHeightError
      ? {
          hasLengthError: false,
          hasWidthError: false,
          hasHeightError: false,
        }
      : errors),
  }

  return (
    <div className="wrap--nested-form">
      <DimensionsInput
        length={length || ''}
        width={width || ''}
        height={height || ''}
        setLength={(value) => updateParcel(labelInfoID, 0, {length: value})}
        setWidth={(value) => updateParcel(labelInfoID, 0, {width: value})}
        setHeight={(value) => updateParcel(labelInfoID, 0, {height: value})}
        errorMessage={errorMessage}
        hasLengthError={hasLengthError}
        hasWidthError={hasWidthError}
        hasHeightError={hasHeightError}
        warningMessage={warningMessage}
      />
    </div>
  )
}

function BulkUpdateModal({form}) {
  const labelInfoID = BATCH_UPDATE_LABEL_ID
  const warehouseOptions = useSelector(warehouseOptionsSelector)
  const labelConfig = useSelector((state) =>
    labelConfigSelector(state, {labelInfoID}),
  )
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const usePresets = useSelector(usePresetsSelector)

  useEffect(() => {
    setBatchLabelConfig()

    return () => removeLabelInfo(labelInfoID)
  }, [])

  if (!labelConfig) {
    return null
  }

  return (
    <ConfirmModal
      title="Bulk Update"
      onConfirm={() => batchUpdateAll()}
      onCancel={() => closeModal()}
      confirmText={plural(form.referenceIDs)`Update Batch${['es']}`}
      cancelText="Cancel"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <LabelConfigContext.Provider value={{labelInfoID, shipperType}}>
        <p className="fs-01 lh-md">
          <strong>
            <PluralBlock array={form.referenceIDs}>
              Select the options to update <Count /> batch
              <Plural p="es" /> with.
            </PluralBlock>
          </strong>
        </p>
        {usePresets && (
          <div className="margin-bottom-10">
            <Checkbox
              id="include_preset"
              label="Presets"
              checked={form.includePresets}
              onChange={(checked) => updateModalForm({includePresets: checked})}
            />
            {form.includePresets && <BatchPresets />}
          </div>
        )}
        <div className="margin-bottom-10">
          <Checkbox
            id="include_ship_from"
            label="Ship From"
            checked={form.includeShipFrom}
            onChange={(checked) => updateModalForm({includeShipFrom: checked})}
          />
          {form.includeShipFrom && (
            <div className="wrap--nested-form">
              <Select
                id="ship_from"
                value={form.warehouseID}
                onChange={(warehouseID) =>
                  updateModalForm({warehouseID: Number(warehouseID) || null})
                }
              >
                {warehouseOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div className="margin-bottom-10">
          <Checkbox
            id="include_weight"
            label="Weight"
            checked={form.includeWeight}
            onChange={(checked) => updateModalForm({includeWeight: checked})}
          />
          {form.includeWeight && (
            <div className="wrap--nested-form">
              <WeightInput
                idLb="weight_lb"
                weightOz={form.weightOz}
                setWeightOz={(weightOz) => updateModalForm({weightOz})}
                showScaleControl
              />
            </div>
          )}
        </div>
        <div className="margin-bottom-10">
          <Checkbox
            id="include_shipping_config"
            label="Shipping Configuration"
            checked={form.includeShippingConfig}
            onChange={(checked) =>
              updateModalForm({includeShippingConfig: checked})
            }
          />
          {form.includeShippingConfig && <BatchShippingConfiguration />}
        </div>
        <div className="margin-bottom-10">
          <Checkbox
            id="include_dimensions"
            label="Dimensions"
            checked={form.includeDimensions}
            onChange={(checked) =>
              updateModalForm({includeDimensions: checked})
            }
          />
          {form.includeDimensions && <BatchDimensions />}
        </div>
        <div className="margin-bottom-10">
          <Checkbox
            id="include_ship_date"
            label="Ship Date"
            checked={form.includeShipDate}
            onChange={(checked) => updateModalForm({includeShipDate: checked})}
          />
          {form.includeShipDate && (
            <div className="wrap--nested-form w-65">
              <ForwardDate
                label=""
                labelProperty="ship_date"
                validShipperTypes={SHIP_DATE_VALID_SHIPPER_TYPES}
              />
            </div>
          )}
        </div>
        <div>
          <Checkbox
            id="include_packing_list"
            label="Profile"
            checked={form.includePackingList}
            onChange={(checked) =>
              updateModalForm({includePackingList: checked})
            }
          />
          {form.includePackingList && (
            <div className="wrap--nested-form wrap--bulk-update-profile w-65">
              <PackingList />
            </div>
          )}
        </div>
      </LabelConfigContext.Provider>
    </ConfirmModal>
  )
}

BulkUpdateModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    includePresets: PropTypes.bool.isRequired,
    includeShipFrom: PropTypes.bool.isRequired,
    warehouseID: PropTypes.number.isRequired,
    includeWeight: PropTypes.bool.isRequired,
    weightOz: PropTypes.number.isRequired,
    includeShippingConfig: PropTypes.bool.isRequired,
    includeDimensions: PropTypes.bool.isRequired,
    includeShipDate: PropTypes.bool.isRequired,
    includePackingList: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(BulkUpdateModal, modalFormSelector)
