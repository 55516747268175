import {updateLabelConfig} from '../../../../data/labelInfos/index.js'
import {DHL} from '../../../../common/constants/ShipperNames.js'
import ConfigTextInput from '../ConfigTextInput.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'federal_tax_id'
const VALID_SHIPPER_TYPES = [DHL]

export default function FederalTaxID() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigTextInput
      label="Federal Tax ID"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
