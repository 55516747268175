import PropTypes from 'prop-types'

import {updateForm, useForm, useSelector} from '../../../store.js'
import TextInput from '../Common/TextInput.js'
import FormListItem from '../Common/FormListItem.js'
import {cartFormErrorsSelector} from './salesChannelsSelectors.js'

export default function NameInput({className, formName}) {
  const {name, hasChanged_name} = useForm(formName)
  const errors = useSelector((state) =>
    cartFormErrorsSelector(state, {formName}),
  )

  return (
    <FormListItem className={className}>
      <TextInput
        id={`${formName}_name`}
        label="Sales Channel Name"
        required
        placeholder="My Wonderful Sales Channel"
        value={name || ''}
        errorMessage={hasChanged_name && errors.name}
        onChange={(event) =>
          updateForm(formName, {
            name: event.target.value,
            hasChanged_name: true,
          })
        }
      />
    </FormListItem>
  )
}

NameInput.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
}
