import {tabsSelector} from './headerSelectors.js'
import {companyIDSelector} from '../../data/company.js'
import {emailSelector, logout} from '../../data/me.js'
import {useSelector} from '../../store.js'

export default function NavDropDown() {
  const tabs = useSelector(tabsSelector)
  const companyID = useSelector(companyIDSelector)
  const email = useSelector(emailSelector)

  return (
    <div className="wrap--nav-sm">
      <ul className="list--nav-sm">
        {tabs.map((tab) => (
          <li key={tab.display} className="list__item--nav-sm">
            <a className="link--nav-sm" href={tab.url} title={tab.display}>
              {tab.display}
            </a>
          </li>
        ))}
        <li className="list__item--nav-sm">
          <a
            className="link--nav-sm"
            href="#/settings/general"
            title="Configure general Ordoro settings"
          >
            Settings
          </a>
        </li>
        <li className="list__item--nav-sm">
          <a
            className="link--nav-sm"
            href="//support.ordoro.com"
            target="_blank"
            rel="noopener noreferrer"
            title="Get help in our Support Center"
          >{`Help (Support Pin: ${companyID})`}</a>
        </li>
        <li className="list__item--nav-sm">
          <button
            className="btn link--nav-sm"
            title="Logout"
            onClick={() => logout()}
          >
            Logout <strong>{email}</strong>
          </button>
        </li>
      </ul>
    </div>
  )
}
