import {useEffect, useState} from 'react'

import {
  getPrintNodeData,
  setSelectedScaleID,
  scaleEnabledSelector,
  scalesSelector,
  isLoadingScalesSelector,
  printNodeSelector,
  updatePrintNodeConfig,
} from '../../../data/printNode.js'
import {hasPrintNodeAPIKeySelector} from '../../../data/company.js'

import PrintNodeHelp from '../Printing/PrintNodeHelp.js'
import EditPrintNodeAPIKey from '../Printing/EditPrintNodeAPIKey.js'
import SettingsPanel from '../SettingsPanel.js'
import Scale from './Scale.js'
import {useSelector} from '../../../store.js'

export default function Scales() {
  const scales = useSelector(scalesSelector)
  const selectedScaleID = useSelector(printNodeSelector).selectedScaleID
  const hasAPIKey = useSelector(hasPrintNodeAPIKeySelector)
  const scaleEnabled = useSelector(scaleEnabledSelector)
  const isLoadingScales = useSelector(isLoadingScalesSelector)

  const [scaleEnabledLocal, setScaleEnabledLocal] = useState(false)

  useEffect(() => {
    setScaleEnabledLocal(scaleEnabled)
  }, [scaleEnabled])

  useEffect(() => {
    if (hasAPIKey) {
      getPrintNodeData()
    }
  }, [])

  return (
    <SettingsPanel
      header="Scales Preferences"
      subHeader={
        <div>
          <p className="fs-01 margin-bottom-20">
            Get the precise weights of your parcels by connecting a USB scale to
            Ordoro.
          </p>
          <hr className="margin-bottom-0" />
        </div>
      }
    >
      <div className="row margin-bottom-20">
        <div className="medium-7 columns">
          <div className="flex align-items-center">
            <label
              className="fs-02 flex align-items-center margin-right-7 margin-bottom-0"
              htmlFor="enable_usb_scale"
            >
              <input
                id="enable_usb_scale"
                className="checkbox v-align-middle margin-bottom-0 margin-right-7"
                type="checkbox"
                checked={scaleEnabledLocal}
                onChange={(event) => {
                  const scale_enabled = event.target.checked

                  setScaleEnabledLocal(scale_enabled)

                  updatePrintNodeConfig({scale_enabled})
                }}
              />
              <span>Enable USB Scale</span>
            </label>
            <a
              className="btn btn--link no-underline lighter"
              href="https://support.ordoro.com/do-you-support-usb-scales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="flex i-help fs-02 lh-xl" />
            </a>
          </div>
        </div>
      </div>
      {scaleEnabledLocal && !hasAPIKey && <PrintNodeHelp />}

      {isLoadingScales && scaleEnabledLocal && hasAPIKey && (
        <div className="row">
          <div className="medium-6 medium-centered columns">
            <div className="loading margin-top-25 margin-bottom-30">
              <span className="list-processing animate-spin v-align-middle" />
              <strong className="inline-block v-align-middle fs-02 op-75 margin-left-10">
                Loading scales...
              </strong>
            </div>
          </div>
        </div>
      )}
      {scaleEnabledLocal && hasAPIKey && (
        <div>
          <div className="row">
            <div className="medium-10 columns">
              <div className="wrap--printnode-list">
                <p className="fs-01 margin-bottom-10">
                  <strong>Available Scales:</strong>
                </p>
                <table className="table margin-bottom-25">
                  <thead>
                    <tr>
                      <th className="table__th table__th--top-border th-width-3">
                        &nbsp;
                      </th>
                      <th className="table__th table__th--top-border">
                        Product
                      </th>
                      <th className="table__th table__th--top-border">
                        Vendor
                      </th>
                      <th className="table__th table__th--top-border">
                        Computer
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table__tbody--lines table__tbody--v-align-middle">
                    {scales.map((scale) => (
                      <Scale
                        key={scale.id}
                        scale={scale}
                        checked={selectedScaleID === scale.id}
                        onChange={(scaleID) => setSelectedScaleID(scaleID)}
                      />
                    ))}

                    {!isLoadingScales && scales.length === 0 && (
                      <tr>
                        <td
                          className="align-center padding-left-0 padding-right-0 padding-top-25 padding-bottom-0"
                          colSpan="4"
                        >
                          <div className="alert alert--error alert--lg full-border align-center">
                            <p className="fs-01 error margin-bottom-0">
                              <strong>No scales were detected</strong>
                            </p>
                          </div>
                          <hr className="margin-bottom-0" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <EditPrintNodeAPIKey />
        </div>
      )}
    </SettingsPanel>
  )
}
