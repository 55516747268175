import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {MO_SORT_OPTIONS} from '../../common/constants/MOs.js'
import Sort from '../../common/components/List/Sort.js'
import {setSort} from './moListActions.js'
import {moListQuerySelector} from './moListSelectors.js'

export function MOSort({sort}) {
  return (
    <Sort
      items={MO_SORT_OPTIONS}
      value={sort}
      onChange={(value) => setSort(value)}
    />
  )
}

MOSort.propTypes = {
  sort: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    sort: moListQuerySelector(state).sort,
  }
}

export default connect(mapStateToProps)(MOSort)
