import UnusableWarnings from '../../../common/components/List/ActionPanel/UnusableWarnings.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {needsWarehouseSelector} from '../../../data/warehouses.js'
import {needsShipperSelector} from '../../../data/shippers.js'
import {deselectReturnOrders} from '../returnOrderListActions.js'
import {
  returnOrderListFormSelector,
  labelInfoIDsThatCanHaveLabelsSelector,
  referenceIDsThatHaveLabelsSelector,
} from '../returnOrderListSelectors.js'
import LabelConfig from '../../LabelConfig/index.js'
import HasLabelPane from './HasLabelPane.js'
import ReturnOrderSpeedbumps from './ReturnOrderSpeedbumps.js'
import {validBulkShipperIDsSelector} from '../../../data/labelInfos/index.js'
import {useRMALabelsSelector} from '../../../data/company.js'
import {dispatch, useSelector} from '../../../store.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'

export default function ActionPanel() {
  const labelInfoIDsThatCanHaveLabels = useSelector(
    labelInfoIDsThatCanHaveLabelsSelector,
  )

  const form = useSelector(returnOrderListFormSelector)
  const referenceIDsThatHaveLabels = useSelector(
    referenceIDsThatHaveLabelsSelector,
  )
  const needsWarehouse = useSelector(needsWarehouseSelector)
  const needsShipper = useSelector(needsShipperSelector)
  const noValidShippers = useSelector(
    (state) =>
      validBulkShipperIDsSelector(state, {
        labelInfoIDs: labelInfoIDsThatCanHaveLabels,
      }).length === 0,
  )
  const useRMALabels = useSelector(useRMALabelsSelector)

  const warningMessages =
    labelInfoIDsThatCanHaveLabels.length !== 0 &&
    referenceIDsThatHaveLabels.length !== 0
      ? [
          {
            ids: referenceIDsThatHaveLabels,
            singleMessage: `1 RMA already has a label`,
            pluralMessage: `${referenceIDsThatHaveLabels.length} RMAs that already have labels`,
          },
        ]
      : []
  const singleWarningTitle = 'A return label will not be created for:'
  const pluralWarningTitle = 'Return labels will not be created for:'

  return (
    <div className="medium-4 columns">
      {form.selectedReferenceIDs.length ? (
        <div className="margin-top-20">
          <div className="alert alert--standard margin-bottom-15">
            <h4 className="fs-01 lh-sm margin-top-0 margin-bottom-0 align-center">
              <strong>
                {form.selectedReferenceIDs.length === 1
                  ? form.selectedReferenceIDs[0]
                  : `${form.selectedReferenceIDs.length} RMAs`}{' '}
                Selected
              </strong>
            </h4>
          </div>
          <div className="panel--actions panel--order-actions">
            {!useRMALabels ? (
              <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
                <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
                  <p className="align-center fs-01 lh-md  margin-bottom-15">
                    <strong>Create Return Labels for Your Customers!</strong>
                  </p>
                  <p className="fs-00 lh-md margin-bottom-15">
                    A great returns experience goes a long way for customer
                    satisfaction and retention.
                  </p>

                  <p className="fs-00 lh-lg margin-bottom-20">
                    To gain access to Return Labels, you need to be on the{' '}
                    <strong>Premium Plan of the Shipping App</strong>.
                  </p>

                  <div>
                    <ButtonPrimary
                      size="x-md"
                      className="meta--feature-lock-rma-label-panel"
                      alt
                      onClick={() => showUpgradeModal()}
                    >
                      Manage Your Apps
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            ) : needsShipper || needsWarehouse || noValidShippers ? (
              <ReturnOrderSpeedbumps
                needsWarehouse={needsWarehouse}
                needsShipper={needsShipper}
                noValidShippers={noValidShippers}
              />
            ) : labelInfoIDsThatCanHaveLabels.length ? (
              <>
                <UnusableWarnings
                  warningMessages={warningMessages}
                  singleTitle={singleWarningTitle}
                  pluralTitle={pluralWarningTitle}
                  showDeselect
                  deselect={(ids) => dispatch(deselectReturnOrders(ids))}
                />
                <ul className="list--tabs flex-wrap clearfix">
                  <li className="list__item--tab active">
                    <button className="btn btn--tab" type="button">
                      Return Label
                    </button>
                  </li>
                </ul>
                <LabelConfig labelInfoIDs={labelInfoIDsThatCanHaveLabels} />
              </>
            ) : referenceIDsThatHaveLabels.length ? (
              <HasLabelPane referenceIDs={referenceIDsThatHaveLabels} />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="panel--actions panel--order-actions panel--unselected margin-top-20">
          <p className="margin-bottom-0 align-center fs-00 op-50">
            <strong>Select an RMA in the list to take action on it</strong>
          </p>
        </div>
      )}
    </div>
  )
}
