import {useSelector} from 'react-redux'

import SelectAllButton from '../../../../../common/components/List/SelectAllButton.js'
import {setSelectedOrderNumbers} from '../../../../OrderListPage/orderListActions.js'
import {
  visibleOrderNumbersSelector,
  indeterminateSelectedSelector,
  allSelectedSelector,
} from '../../../../OrderListPage/orderListSelectors.js'

export default function OrderSelectAllButton() {
  const visibleOrderNumbers = useSelector(visibleOrderNumbersSelector)
  const indeterminate = useSelector(indeterminateSelectedSelector)
  const checked = useSelector(allSelectedSelector)

  return (
    <SelectAllButton
      checked={checked}
      indeterminate={indeterminate}
      onChange={(value) =>
        setSelectedOrderNumbers(value ? visibleOrderNumbers : [])
      }
    />
  )
}
