import PropTypes from 'prop-types'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'

import {ErrorsShape} from '../../../common/PropTypes.js'
import {
  isNonZeroPositiveInteger,
  isNonZeroPositiveNumeric,
} from '../../../common/utils.js'
import {TIME_ZONE_OPTIONS} from '../../../common/constants/TimeZones.js'
import Select from '../../../common/components/Select.js'
import {formSelector, updateForm, useForm} from '../../../store.js'
import ReportInterval from './ReportInterval.js'
import DateRange from './DateRange.js'
import IntervalSummary from './IntervalSummary.js'
import {getTimeZonedDate} from '../../../common/date.js'
import {REPORT_INTERVAL_MONTH} from '../../../common/constants/Reports.js'
import className from '../../../common/className.js'

export function dateFilterErrorsSelector(state, {formName}) {
  const {interval_type, interval_amount, start_date, end_date} = formSelector(
    state,
    {
      formName,
    },
  )
  const errors = {}

  if (interval_type) {
    if (
      interval_type === REPORT_INTERVAL_MONTH &&
      !isNonZeroPositiveInteger(interval_amount)
    ) {
      errors.interval_amount = 'Amount must be a positive whole number'
      errors.preventSave = true
    } else if (
      interval_type !== REPORT_INTERVAL_MONTH &&
      !isNonZeroPositiveNumeric(interval_amount)
    ) {
      errors.interval_amount = 'Amount must be a positive number'
      errors.preventSave = true
    }
  } else {
    if (!start_date) {
      errors.start_date = 'Date is required'
      errors.preventSave = true
    }

    if (!end_date) {
      errors.end_date = 'Date is required'
      errors.preventSave = true
    }

    if (start_date > end_date) {
      errors.end_date = 'End date must be after start date'
      errors.preventSave = true
    }
  }

  return errors
}

export function dateFilterPayloadParamsSelector(state, {formName}) {
  const {
    interval_type,
    interval_amount,
    interval_trunc,
    start_date,
    end_date,
    timezone,
  } = formSelector(state, {formName})

  const params = {timezone}

  if (interval_type) {
    params.interval_type = interval_type
    params.interval_amount = Number(interval_amount)
    params.interval_trunc = interval_trunc
  } else {
    params.start_date = startOfDay(
      getTimeZonedDate(start_date, timezone),
    ).toISOString()
    params.end_date = endOfDay(
      getTimeZonedDate(end_date, timezone),
    ).toISOString()
  }

  return params
}

export default function DateFilters({formName, errors, dividerButton}) {
  dividerButton = dividerButton === false ? false : true
  const form = useForm(formName)

  return (
    <>
      <ReportInterval formName={formName} errors={errors} />
      <DateRange formName={formName} errors={errors} />
      <IntervalSummary formName={formName} errors={errors} />
      <li
        className={className`list__item--form list__item--no-style ${
          dividerButton && 'divider--bottom'
        } margin-bottom-15 padding-bottom-20`}
      >
        <div>
          <label htmlFor="id_time_zone">Time Zone</label>
          <Select
            className="w-100 margin-bottom-0"
            id="id_timezone"
            value={form.timezone}
            onChange={(timezone) =>
              updateForm(formName, {timezone}, {stickyProps: ['timezone']})
            }
          >
            {TIME_ZONE_OPTIONS.map(({value, display}) => (
              <option value={value} key={value}>
                {display}
              </option>
            ))}
          </Select>
        </div>
      </li>
    </>
  )
}

DateFilters.propTypes = {
  formName: PropTypes.string.isRequired,
  errors: ErrorsShape.isRequired,
  dividerButton: PropTypes.bool,
}
