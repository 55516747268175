import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import isEmpty from 'lodash/isEmpty.js'
import cloneDeep from 'lodash/cloneDeep.js'

import {isEmptyValue} from '../../../common/utils.js'
import {
  APPLY_TAGS,
  APPLY_PRESETS,
  ASSIGN_TO_WAREHOUSE,
  DROPSHIP_TO,
  SHIPPING_PRESET,
  RETURN_PRESET,
  SHIPPING_AND_RETURN,
  NEW_RULE_ID,
  CONDITIONS_JOINED_WITH_ALL,
  CONDITIONS_JOINED_WITH_ANY,
  ACTION_DEFAULTS,
} from '../../../common/constants/Rules.js'
import {debouncePromise} from '../../../common/debounce.js'
import {
  reorderRules,
  rulesSortedByIndexSelector,
  deleteRule,
  saveRule,
} from '../../../data/rules.js'
import {
  formSelector,
  getState,
  updateForm,
  setFormValue,
  updateFormObject,
  addFormArrayElement,
  removeFormArrayElement,
} from '../../../store.js'
import {showEditTagModal} from '../../../common/components/Modal/EditTagModal.js'
import {TAG_TYPE__ORDER} from '../../../common/constants/Tags.js'
import {showGlobalError} from '../../GlobalErrorMessage.js'
import {
  getConditionOption,
  getConditions,
  setConditions,
} from './RuleCondition/conditionalOptions.js'

export const SETTINGS_RULES = 'SETTINGS_RULES'

export function getRuleFormName(ruleID) {
  return `${SETTINGS_RULES}_${ruleID}`
}

export function setupSettingsRulesForm() {
  return {
    formName: SETTINGS_RULES,
    initialForm: {
      indices: [],
    },
  }
}

export function setupRuleIndicies() {
  updateSettingsRulesForm({
    indices: rulesSortedByIndexSelector(getState()).map(({id, index}) => ({
      id,
      index,
    })),
  })
}

export function settingsRulesFormSelector(state) {
  return formSelector(state, {formName: SETTINGS_RULES})
}

export function ruleFormSelector(state, {ruleID}) {
  return formSelector(state, {
    formName: getRuleFormName(ruleID),
  })
}

export function updateSettingsRulesForm(...args) {
  updateForm(SETTINGS_RULES, ...args)
}

export function updateRuleForm(ruleID, ...args) {
  updateForm(getRuleFormName(ruleID), ...args)
}

export function setRuleFormValue(ruleID, ...args) {
  setFormValue(getRuleFormName(ruleID), ...args)
}

export function updateRuleFormObject(ruleID, ...args) {
  updateFormObject(getRuleFormName(ruleID), ...args)
}

export function addRuleFormArrayElement(ruleID, ...args) {
  addFormArrayElement(getRuleFormName(ruleID), ...args)
}

export function removeRuleFormArrayElement(ruleID, ...args) {
  removeFormArrayElement(getRuleFormName(ruleID), ...args)
}

export function ruleActionSelector(state, {ruleID, index}) {
  const rule = ruleFormSelector(state, {ruleID})
  return get(rule, ['action', index], null)
}

export function ruleActionsSelector(state, {ruleID}) {
  const rule = ruleFormSelector(state, {ruleID})

  return rule.action.reduce((prev, action) => {
    if (action.type === APPLY_PRESETS) {
      const presetID = action.data.preset_ids[0]
      const prevAction = prev[prev.length - 1]

      // if the previous action is also a preset action with the same preset ID
      if (
        prevAction &&
        prevAction.type === APPLY_PRESETS &&
        presetID === prevAction.data.preset_ids[0] &&
        ((prevAction.data.preset_type === RETURN_PRESET &&
          action.data.preset_type === SHIPPING_PRESET) ||
          (prevAction.data.preset_type === SHIPPING_PRESET &&
            action.data.preset_type === RETURN_PRESET))
      ) {
        // combine into a single action (Jesus save us from ourselves)
        prev[prev.length - 1] = cloneDeep(prevAction)
        prev[prev.length - 1].data.preset_type = SHIPPING_AND_RETURN

        prev.push({...action, hide: true})

        return prev
      }
    }

    prev.push(action)

    return prev
  }, [])
}

export function ruleActionTypeSelector(state, {ruleID, index}) {
  const ruleAction = ruleActionSelector(state, {ruleID, index})
  return (ruleAction && ruleAction.type) || null
}

export const ruleErrorsSelector = createSelector(ruleFormSelector, (rule) => ({
  conditions: rule ? getConditionError(rule.condition) : [],
  actions: get(rule, 'action', []).map(getActionError),
}))

export const ruleCanBeSavedSelector = createSelector(
  ruleErrorsSelector,
  (errors) => errors.conditions.every(isEmpty) && errors.actions.every(isEmpty),
)

function getConditionError(condition) {
  const [key, value] = Object.entries(condition)[0]
  const conditions = [
    CONDITIONS_JOINED_WITH_ALL,
    CONDITIONS_JOINED_WITH_ANY,
  ].includes(key)
    ? value
    : [condition]

  return conditions.map((condition) => {
    const matchingOptions = getConditionOption(condition)

    if (matchingOptions) {
      const validationError = matchingOptions.validate(condition)
      if (validationError) {
        return {conditionValue: validationError}
      }
    } else {
      return {conditionValue: 'Invalid rule variable'}
    }

    return null
  })
}

function getActionError(action) {
  if (action.type === APPLY_TAGS && isEmptyValue(action.data.tag_ids[0])) {
    return {action: 'You must select a tag.'}
  }

  if (
    action.type === APPLY_PRESETS &&
    isEmptyValue(action.data.preset_ids[0])
  ) {
    return {action: 'You must select a preset.'}
  }

  return {}
}

export function getUIRuleFromDataRule(rule) {
  rule = cloneDeep(rule)

  return {
    id: rule.id,
    index: rule.index,
    active: rule.active,
    latch: rule.latch,
    condition: rule.condition,
    action: rule.action,
    internal_notes: rule.internal_notes,
    isEditing: false,
  }
}

export async function moveRuleCommit() {
  try {
    const {indices} = settingsRulesFormSelector(getState())

    const newIndices = indices.map((uiRule) => ({
      rule_id: uiRule.id,
      index: uiRule.index,
    }))

    await reorderRules(newIndices)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error changing rule order.',
      },
      err,
    )
  }
}

export const debouncedRuleCommit = debouncePromise(moveRuleCommit, 1500)

export async function removeRuleFromList(ruleID) {
  if (await deleteRule(ruleID)) {
    const {indices} = settingsRulesFormSelector(getState())

    updateSettingsRulesForm({indices: indices.filter(({id}) => ruleID !== id)})
  }
}

export async function settingsSaveRule(ruleID) {
  try {
    const rule = ruleFormSelector(getState(), {ruleID})

    const conditions = getConditions(rule).map((condition) =>
      getConditionOption(condition).getCondition(condition),
    )

    rule.condition = setConditions(rule, conditions)

    const updatedDataRule = await saveRule(rule)

    if (!updatedDataRule) {
      return
    }

    if (ruleID === NEW_RULE_ID) {
      updateSettingsRulesForm({isAddingNew: false})
    } else {
      updateRuleForm(ruleID, {isEditing: false})
    }

    setupRuleIndicies()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error saving rule.',
      },
      err,
    )
  }
}

export function addRuleAction(ruleID) {
  const {action} = ruleFormSelector(getState(), {ruleID})

  addRuleFormArrayElement(ruleID, ['action'], {...action[action.length - 1]})
}

export function removeRuleAction(ruleID, index) {
  removeRuleFormArrayElement(ruleID, ['action'], index)
}

export function setRuleAction(ruleID, index, action) {
  setRuleFormValue(ruleID, ['action', index], action)
}

export function setRuleActionType(ruleID, index, type) {
  const actions = ruleActionsSelector(getState(), {ruleID})
  setRuleAction(ruleID, index, ACTION_DEFAULTS[type])

  if (actions[index + 1] && actions[index + 1].hide) {
    // a hidden apply_preset action
    // remove the next action
    removeRuleAction(ruleID, index + 1)
  }
}

export function handleRemoveRuleAction(ruleID, index) {
  const actions = ruleActionsSelector(getState(), {ruleID})
  removeRuleAction(ruleID, index)

  if (actions[index + 1] && actions[index + 1].hide) {
    // a hidden apply_preset action
    // remove the next (same index number because we just removed the previous one)
    removeRuleAction(ruleID, index)
  }
}

export function updateRuleAction(ruleID, index, updates) {
  updateRuleFormObject(ruleID, ['action', index], updates)
}

export function addRuleCondition(ruleID) {
  const {condition} = ruleFormSelector(getState(), {ruleID})
  const [key, value] = Object.entries(condition)[0]

  const isAlreadyList = [
    CONDITIONS_JOINED_WITH_ALL,
    CONDITIONS_JOINED_WITH_ANY,
  ].includes(key)

  const values = isAlreadyList ? value : [condition]

  setRuleFormValue(ruleID, ['condition'], {
    [isAlreadyList ? key : CONDITIONS_JOINED_WITH_ALL]: [
      ...values,
      values[values.length - 1],
    ],
  })
}

export function removeRuleCondition(ruleID, index) {
  const {condition} = ruleFormSelector(getState(), {ruleID})

  const [key, value] = Object.entries(condition)[0]

  const isList = [
    CONDITIONS_JOINED_WITH_ALL,
    CONDITIONS_JOINED_WITH_ANY,
  ].includes(key)

  if (!isList) {
    return
  }

  const values = value.filter((_, i) => i !== index)

  setRuleFormValue(
    ruleID,
    ['condition'],
    values.length === 1 ? values[0] : {[key]: values},
  )
}

export function setRuleCondition(ruleID, index, condition) {
  const rule = ruleFormSelector(getState(), {ruleID})

  const [key, value] = Object.entries(rule.condition)[0]

  const isList = [
    CONDITIONS_JOINED_WITH_ALL,
    CONDITIONS_JOINED_WITH_ANY,
  ].includes(key)

  setRuleFormValue(
    ruleID,
    ['condition'],
    isList
      ? {[key]: value.map((c, i) => (i === index ? condition : c))}
      : condition,
  )
}

export function setRuleConditionsJoinedWith(ruleID, joinedWith) {
  const {condition} = ruleFormSelector(getState(), {ruleID})
  const value = Object.values(condition)[0]

  setRuleFormValue(ruleID, ['condition'], {
    [joinedWith]: value,
  })
}

export function setRuleApplyTag(ruleID, index, tagID) {
  const ruleAction = {
    type: APPLY_TAGS,
    data: {tag_ids: [tagID]},
  }

  updateRuleAction(ruleID, index, ruleAction)
}

export function setRuleApplyPresetPresetType(
  ruleID,
  index,
  preset_type = SHIPPING_PRESET,
) {
  const rule = ruleFormSelector(getState(), {ruleID})
  const actions = ruleActionsSelector(getState(), {ruleID})
  const action = actions[index]

  // was combined and now isn't
  if (
    action.data.preset_type === SHIPPING_AND_RETURN &&
    preset_type !== SHIPPING_AND_RETURN
  ) {
    const updates = cloneDeep(action)
    updates.data.preset_type = preset_type

    updateRuleAction(ruleID, index, updates)
    removeRuleAction(ruleID, index + 1)

    return
  }

  // was not combined and now needs to be
  if (preset_type === SHIPPING_AND_RETURN) {
    updateRuleForm(ruleID, {
      action: rule.action.reduce((prev, action, i) => {
        if (index === i) {
          prev.push(action)
          const newAction = cloneDeep(action)

          if (newAction.data.preset_type === RETURN_PRESET) {
            newAction.data.preset_type = SHIPPING_PRESET
          } else if (newAction.data.preset_type === SHIPPING_PRESET) {
            newAction.data.preset_type = RETURN_PRESET
          }
          prev.push(newAction)
        } else {
          prev.push(action)
        }

        return prev
      }, []),
    })

    return
  }

  updateRuleAction(ruleID, index, {
    type: APPLY_PRESETS,
    data: {...rule.action[index].data, preset_type},
  })
}

export function setRuleApplyPresetPresetID(ruleID, index, presetID) {
  const rule = ruleFormSelector(getState(), {ruleID})
  const actions = ruleActionsSelector(getState(), {ruleID})
  const action = actions[index]

  if (action.data.preset_type === SHIPPING_AND_RETURN) {
    updateRuleAction(ruleID, index + 1, {
      type: APPLY_PRESETS,
      data: {...rule.action[index + 1].data, preset_ids: [presetID]},
    })
  }

  updateRuleAction(ruleID, index, {
    type: APPLY_PRESETS,
    data: {...rule.action[index].data, preset_ids: [presetID]},
  })
}

export function setRuleAssignToWarehouse(ruleID, index, warehouseID) {
  const ruleAction = {
    type: ASSIGN_TO_WAREHOUSE,
    data: {warehouse_id: warehouseID},
  }

  updateRuleAction(ruleID, index, ruleAction)
}

export function createAndApplyTagToRule(ruleID, index) {
  showEditTagModal(TAG_TYPE__ORDER, null, {
    addTagToRuleID: ruleID,
    index,
  })
}

export function setRuleDropshipTo(ruleID, index, supplier_id) {
  updateRuleAction(ruleID, index, {
    type: DROPSHIP_TO,
    data: {supplier_id},
  })
}
