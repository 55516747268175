import PropTypes from 'prop-types'
import uniq from 'lodash/uniq.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  dispatch,
  useSelector,
} from '../../../store.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import apiverson from '../../../common/apiverson.js'
import {setBatch} from '../../../data/batches.js'
import Select from '../../../common/components/Select.js'
import {BATCH_SORT_COMMENT} from '../../../common/constants/Comments.js'

const MODAL_FORM = 'SORT_BATCH_MODAL'

const BATCH_SORT_OPTIONS = [
  {value: '', display: 'Select an option...'},
  {value: '-total_units', display: 'Total Quantity (Highest)'},
  {value: 'total_units', display: 'Total Quantity (Lowest)'},
  {value: '-total_lines', display: 'Line Count (Highest)'},
  {value: 'total_lines', display: 'Line Count (Lowest)'},
  {value: 'sku', display: 'SKU (A→Z)'},
  {value: '-sku', display: 'SKU (Z→A)'},
  {value: 'location_in_warehouse', display: 'Location in Warehouse (A→Z)'},
  {value: '-location_in_warehouse', display: 'Location in Warehouse (Z→A)'},
  {value: 'warehouse_name', display: 'Warehouse Name (A→Z)'},
  {value: '-warehouse_name', display: 'Warehouse Name (Z→A)'},
]

export function showSortBatchModal(referenceIDs) {
  setForm(MODAL_FORM, {
    referenceIDs,
    sorts: [BATCH_SORT_OPTIONS[0].value],
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates, meta) {
  updateForm(MODAL_FORM, updates, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export function updateSorts(sort, index) {
  let {sorts} = modalFormSelector(getState())
  sorts = [...sorts]

  sorts[index] = sort

  updateModalForm({sorts}, {stickyProps: ['sorts']})
}

export function addSort() {
  let {sorts} = modalFormSelector(getState())

  updateModalForm({sorts: [...sorts, '']}, {stickyProps: ['sorts']})
}

export function removeSort(index) {
  let {sorts} = modalFormSelector(getState())

  sorts = sorts.filter((_, i) => i !== index)

  updateModalForm({sorts}, {stickyProps: ['sorts']})
}

export function errorsSelector(state) {
  const sorts = sortsParamsSelector(state)
  const errors = {}

  if (sorts.length === 0) {
    errors.preventSave = true
  }

  return errors
}

export function sortsParamsSelector(state) {
  const {sorts} = modalFormSelector(state)

  return uniq(sorts)
    .filter((sort) => sort)
    .map((sort) => {
      const [_, isReverse, type] = sort.match(/([-])?(.*)/)

      return {
        type,
        ...(isReverse ? {options: {reverse: true}} : undefined),
      }
    })
}

export function sortBatchPayloadSelector(state) {
  const sortsParams = sortsParamsSelector(state)

  const params = {
    action: {
      type: 'batch_sort',
      data: {
        sorts: sortsParams,
      },
    },
  }

  const {sorts} = modalFormSelector(state)
  const comment = {
    type: BATCH_SORT_COMMENT,
    sorts: uniq(sorts)
      .filter((sort) => sort)
      .map((sort) => {
        return BATCH_SORT_OPTIONS.find(({value}) => value === sort).display
      }),
  }

  return {params, comment: JSON.stringify(comment)}
}

export async function sortBatch(referenceID, payload) {
  await apiverson.post(
    `/batch/${encodeURIComponent(referenceID)}/action`,
    payload,
  )

  const {json} = await apiverson.get(
    `/batch/${encodeURIComponent(referenceID)}`,
  )

  setBatch(json)
}

export async function sortBatchAll() {
  const {referenceIDs} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true})

    const payload = sortBatchPayloadSelector(getState())

    await Promise.all(
      referenceIDs.map((referenceID) => sortBatch(referenceID, payload)),
    )

    dispatch(checkRunningTasks())

    showMessageToast(
      plural(referenceIDs.length)`A task was started to sort the orders of ${
        referenceIDs.length
      } batch${['es']}.`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error sorting orders for batches: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function SortBatchModal({form}) {
  const errors = useSelector(errorsSelector)

  return (
    <ConfirmModal
      title="Sort Batch Orders"
      onConfirm={() => sortBatchAll()}
      confirmText="Sort"
      onCancel={() => closeModal()}
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <p className="fs-01 lh-md margin-bottom-20">
        <strong>
          <PluralBlock array={form.referenceIDs}>
            How would you like to sort the orders for <Count />{' '}
            <Plural word="batch" p="es" />?
          </PluralBlock>
        </strong>
      </p>
      <div className="row">
        <div className="medium-9 columns">
          <ul className="list list--nested-items margin-bottom-0">
            {form.sorts.map((sort, index) => (
              <li key={index} className="list__item margin-bottom-20">
                <div className="flex margin-bottom-3">
                  <div className="margin-right-5">
                    <Select
                      label={
                        index === 0
                          ? 'Primary Sort'
                          : 'Additional Sub-Level Sort'
                      }
                      id={`sort_${index}`}
                      value={sort}
                      onChange={(value) => updateSorts(value, index)}
                      options={BATCH_SORT_OPTIONS}
                    />
                  </div>
                  {form.sorts.length - 1 === index && index !== 0 && (
                    <button
                      className="btn btn--link no-underline select__delete-icon"
                      type="button"
                      title="Delete sort"
                      onClick={() => removeSort(index)}
                    >
                      <span className="i-trash fs-01" aria-hidden="true" />
                    </button>
                  )}
                </div>
                {form.sorts.length - 1 === index && index < 4 && (
                  <div>
                    <button
                      className="btn btn--link fs-n0"
                      type="button"
                      onClick={() => addSort()}
                    >
                      Add sub-level sorting
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ConfirmModal>
  )
}

SortBatchModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    sorts: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(SortBatchModal, modalFormSelector)
