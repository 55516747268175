import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {NEW_ID} from '../../../common/constants/index.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {navigateToPackShip} from '../../../redux/actions/ui/packShipPage/index.js'
import {showPackingOrderModal} from '../../PackShipPage/Modals/PackingOrderModal.js'
import {showWarehouseModal} from '../../Modals/WarehouseModal.js'
import {defaultWarehouseSelector} from '../../../data/warehouses.js'
import {canUsePackShipSelector} from '../../../data/company.js'
import {dispatch, useSelector} from '../../../store.js'
import {deselectOrders} from '../orderListActions.js'

export default function ShippingSpeedbumps({
  needsWarehouse,
  needsShipper,
  needsAmazonSFP,
  orderNumbersNotReadyToCreateLabel,
  noValidShippers,
}) {
  orderNumbersNotReadyToCreateLabel = orderNumbersNotReadyToCreateLabel || []
  const canUsePackShip = useSelector(canUsePackShipSelector)
  const firstWarehouseID = get(useSelector(defaultWarehouseSelector), 'id')

  if (
    !needsWarehouse &&
    !needsShipper &&
    !needsAmazonSFP &&
    orderNumbersNotReadyToCreateLabel.length === 0 &&
    !noValidShippers
  ) {
    return null
  }

  return (
    <div className="alert alert--lg alert--warning alert--tally margin-bottom-10">
      <p className="align-center fs-01 lh-lg margin-top-5 margin-bottom-0">
        <strong>
          Shipping labels cannot be created until the following has been
          completed:
        </strong>
      </p>
      {needsWarehouse ? (
        <div className="alert alert--lg alert--warning-lt align-center margin-top-20 margin-bottom-10 speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <ButtonPrimary
                alt
                title="Configure your ship-from address"
                onClick={() =>
                  showWarehouseModal({
                    warehouseID: firstWarehouseID,
                    openedFrom: 'setup',
                  })
                }
              >
                Add a Ship-From Address
              </ButtonPrimary>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <ButtonLink
                className="mid no-underline align-center lh-initial"
                title="Configure your ship-from address"
                onClick={() =>
                  showWarehouseModal({
                    warehouseID: firstWarehouseID,
                    openedFrom: 'setup',
                  })
                }
              >
                Plug in a ship-from or warehouse location to calculate shipping
                rates and establish a return address for your labels &#8594;
              </ButtonLink>
            </dd>
          </dl>
        </div>
      ) : needsShipper ? (
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure at least one carrier account"
              >
                Add a Carrier Account
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline lh-initial"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure at least one carrier account"
              >
                Connect a carrier/shipper account — like USPS or FedEx — to
                start creating shipping labels. &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : needsAmazonSFP ? (
        <div className="alert alert--lg alert--warning-lt margin-top-20 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md ws-normal"
                href="#/settings/saleschannels"
                title="Enable Amazon Buy Shipping"
              >
                Add Amazon Buy Shipping
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline lh-initial"
                href="#/settings/saleschannels"
                title="Configure at least one carrier account"
              >
                Enable Amazon Buy Shipping in your Amazon sales channel &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : noValidShippers ? (
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <a
                className="btn btn--primary btn--primary-alt btn--md"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure a different carrier account"
              >
                Add a Compatible Carrier Account
              </a>
            </dt>
            <dd className="list__item fs-00 margin-bottom-0">
              <a
                className="btn--link mid no-underline lh-initial"
                href={`#/settings/shipper/${NEW_ID}`}
                title="Configure a different carrier account"
              >
                Connect a different carrier/shipper account to create labels for
                this type of order &#8594;
              </a>
            </dd>
          </dl>
        </div>
      ) : null}
      {orderNumbersNotReadyToCreateLabel.length > 0 && (
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-5 align-center speedbump-pulse">
          <dl className="list list--onboarding">
            <dt className="list__title fs-01 margin-bottom-10">
              <ButtonPrimary
                className="btn--primary-alt ws-normal"
                onClick={() =>
                  canUsePackShip
                    ? dispatch(
                        navigateToPackShip(orderNumbersNotReadyToCreateLabel),
                      )
                    : showPackingOrderModal(
                        orderNumbersNotReadyToCreateLabel[0],
                      )
                }
              >
                Verify Order Contents
                {!canUsePackShip && orderNumbersNotReadyToCreateLabel.length > 1
                  ? ' of First Order'
                  : ''}
              </ButtonPrimary>
            </dt>
            <dd className="fs-00 margin-bottom-0">
              <ButtonLink
                onClick={() =>
                  deselectOrders(orderNumbersNotReadyToCreateLabel)
                }
              >
                Deselect
              </ButtonLink>
            </dd>
          </dl>
        </div>
      )}
    </div>
  )
}

ShippingSpeedbumps.propTypes = {
  needsWarehouse: PropTypes.bool,
  needsShipper: PropTypes.bool,
  needsAmazonSFP: PropTypes.bool,
  orderNumbersNotReadyToCreateLabel: PropTypes.arrayOf(PropTypes.string),
  noValidShippers: PropTypes.bool,
}
