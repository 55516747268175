import omit from 'lodash/omit.js'
import fpSet from 'lodash/fp/set.js'
import fpUnset from 'lodash/fp/unset.js'
import get from 'lodash/get.js'
import isEmpty from 'lodash/isEmpty.js'

import INITIAL_STATE from '../INITIAL_STATE.js'
import {OPEN_MODAL, CLOSE_MODAL} from '../../actions/ui/index.js'
import {
  SET_FORM,
  UPDATE_FORM,
  SET_FORM_VALUE,
  REMOVE_FORM_VALUE,
  UPDATE_FORM_OBJECT,
  ADD_FORM_ARRAY_ELEMENT,
  REMOVE_FORM_ARRAY_ELEMENT,
  UPDATE_FORMS,
  REMOVE_FORM,
} from '../../actions/ui/forms.js'

export default function reducer(state = INITIAL_STATE.ui.forms, action = {}) {
  if (action.type === SET_FORM) {
    const {key, form} = action.payload

    return fpSet([key], form, state)
  }

  if (action.type === UPDATE_FORM) {
    const {key, form} = action.payload
    const oldForm = get(state, [key])

    if (oldForm) {
      return fpSet([key], {...oldForm, ...form}, state)
    }
  }

  if (action.type === SET_FORM_VALUE) {
    const {key, path, value} = action.payload

    const oldForm = get(state, [key])

    if (!oldForm) {
      return state
    }

    if (Array.isArray(path)) {
      return fpSet([key, ...path], value, state)
    }

    return fpSet(`${key}.${path}`, value, state)
  }

  if (action.type === REMOVE_FORM_VALUE) {
    const {key, path} = action.payload

    const oldForm = get(state, [key])

    if (!oldForm) {
      return state
    }

    if (Array.isArray(path)) {
      return fpUnset([key, ...path], state)
    }

    return fpUnset(`${key}.${path}`, state)
  }

  if (action.type === UPDATE_FORM_OBJECT) {
    const {key, path, value} = action.payload

    const oldForm = get(state, [key])

    if (!oldForm) {
      return state
    }

    const oldValue = get(oldForm, path)

    if (oldValue === undefined) {
      return state
    }

    if (Array.isArray(path)) {
      return fpSet([key, ...path], {...oldValue, ...value}, state)
    }

    return fpSet(`${key}.${path}`, {...oldValue, ...value}, state)
  }

  if (action.type === ADD_FORM_ARRAY_ELEMENT) {
    const {key, path, element} = action.payload

    const oldForm = get(state, [key])

    if (!oldForm) {
      return state
    }

    const oldArray = get(oldForm, path)

    if (oldArray === undefined) {
      return state
    }

    const newArray = [...oldArray, element]

    if (Array.isArray(path)) {
      return fpSet([key, ...path], newArray, state)
    }

    return fpSet(`${key}.${path}`, newArray, state)
  }

  if (action.type === REMOVE_FORM_ARRAY_ELEMENT) {
    const {key, path, index} = action.payload

    const oldForm = get(state, [key])

    if (!oldForm) {
      return state
    }

    const oldArray = get(oldForm, path)

    if (oldArray === undefined) {
      return state
    }

    const newArray = oldArray.filter((_, i) => i !== index)

    if (Array.isArray(path)) {
      return fpSet([key, ...path], newArray, state)
    }

    return fpSet(`${key}.${path}`, newArray, state)
  }

  if (action.type === UPDATE_FORMS) {
    const {forms} = action.payload

    const updatedForms = Object.keys(forms).reduce((prev, key) => {
      const oldForm = get(state, [key])

      if (oldForm) {
        prev[key] = {...oldForm, ...forms[key]}
      }

      return prev
    }, {})

    if (!isEmpty(updatedForms)) {
      return {...state, ...updatedForms}
    }
  }

  if (action.type === REMOVE_FORM) {
    const {key} = action.payload

    return omit(state, key)
  }

  if (action.type === OPEN_MODAL && action.payload.meta.form) {
    const {form} = action.payload.meta

    return fpSet([form.key], form, state)
  }

  if (action.type === CLOSE_MODAL && action.payload.meta.form) {
    const {form} = action.payload.meta

    return omit(state, form.key)
  }

  return state
}
