import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
} from '../../../store.js'
import api from '../../../common/api.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  CUSTOM_INTEGRATION,
  MANUAL,
  VENDOR_PORTAL,
} from '../../../common/constants/CartVendorOptions.js'
import {
  setCart,
  activeCartsSortedByNameSelector,
  isAmazonCartSelector,
  autoSyncOrdersSelector,
  autoSyncProductsSelector,
  autoSyncProductImagesSelector,
  autoSyncInventorySelector,
  autoSyncFBAInventorySelector,
  autoSyncFBAOrdersSelector,
  associatedCartWarehouseSelector,
  usesNewOrderImportProcessSelector,
  canImportProductImagesSelector,
  preventWriteBackSettingsAdjustmentSelector,
  useWebhooksSelector,
  canImportOrdersSelector,
  canImportProductsSelector,
  canExportProductsSelector,
  isFBACartSelector,
  getPreventWriteBackSettingsAdjustment,
  getAutoSyncInventory,
} from '../../../data/carts.js'
import {
  useInventoryWritebackSelector,
  usesInventorySelector,
} from '../../../data/company.js'
import {createTasks} from '../../../redux/actions/data/tasks.js'
import {
  hasOrderImportFromCartPermissionSelector,
  hasProductImportFromCartPermissionSelector,
  hasProductWritebackInventoryPermissionSelector,
  isSuperuserSelector,
} from '../../../data/me.js'

export const MODAL_FORM = 'CART_SYNC_MODAL'
const SYNC_ORDERS_KEY = 'syncOrders'
const SYNC_PRODUCTS_KEY = 'syncProducts'
const SYNC_PRODUCT_IMAGES_KEY = 'syncProductImages'
const SYNC_INVENTORY_KEY = 'syncInventory'
const SYNC_FBA_INVENTORY_KEY = 'syncFBAInventory'
const SYNC_FBA_ORDERS_KEY = 'syncFBAOrders'
const CART_FORM_KEYS = [
  SYNC_ORDERS_KEY,
  SYNC_PRODUCTS_KEY,
  SYNC_PRODUCT_IMAGES_KEY,
  SYNC_INVENTORY_KEY,
  SYNC_FBA_INVENTORY_KEY,
  SYNC_FBA_ORDERS_KEY,
]
const CART_FORM_KEYS_SANS_INVENTORY = [
  SYNC_ORDERS_KEY,
  SYNC_PRODUCTS_KEY,
  SYNC_PRODUCT_IMAGES_KEY,
  SYNC_FBA_INVENTORY_KEY,
  SYNC_FBA_ORDERS_KEY,
]

export function showCartSyncModal() {
  setForm(MODAL_FORM, {
    isSaving: false,
    isSyncing: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function updateCartForm(cartID, updates) {
  const cartForm = cartFormSelector(getState(), {cartID})

  updateModalForm({[cartID]: {...cartForm, ...updates}})
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function cartSyncModalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const cartsForSyncModalSelector = createSelector(
  activeCartsSortedByNameSelector,
  (carts) =>
    carts.filter(
      (cart) =>
        [CUSTOM_INTEGRATION, MANUAL, VENDOR_PORTAL].includes(cart.vendor) ===
        false,
    ),
)

export function allowCartSyncModalSelector(state) {
  return cartsForSyncModalSelector(state).length > 0
}

export function cartFormSelector(state, {cartID}) {
  const form = cartSyncModalFormSelector(state)

  return get(form, cartID, {})
}

export function syncOrdersSelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncOrders = autoSyncOrdersSelector(state, {cartID})

  return get(cartForm, SYNC_ORDERS_KEY, autoSyncOrders)
}

export function syncProductsSelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncProducts = autoSyncProductsSelector(state, {cartID})

  return get(cartForm, SYNC_PRODUCTS_KEY, autoSyncProducts)
}

export function syncProductImagesSelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncProductImages = autoSyncProductImagesSelector(state, {cartID})

  return get(cartForm, SYNC_PRODUCT_IMAGES_KEY, autoSyncProductImages)
}

export function syncInventorySelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncInventory = autoSyncInventorySelector(state, {cartID})

  return get(cartForm, SYNC_INVENTORY_KEY, autoSyncInventory)
}

export function syncFBAInventorySelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncFBAInventory = autoSyncFBAInventorySelector(state, {cartID})

  return get(cartForm, SYNC_FBA_INVENTORY_KEY, autoSyncFBAInventory)
}

export function syncFBAOrdersSelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const autoSyncFBAOrders = autoSyncFBAOrdersSelector(state, {cartID})

  return get(cartForm, SYNC_FBA_ORDERS_KEY, autoSyncFBAOrders)
}

export function needsFulfillmentLatencySelector(state, {cartID}) {
  const cartForm = cartFormSelector(state, {cartID})
  const isAmazonCart = isAmazonCartSelector(state, {cartID})
  const autoSyncInventory = autoSyncInventorySelector(state, {cartID})
  const syncInventory = get(cartForm, SYNC_INVENTORY_KEY, autoSyncInventory)

  return syncInventory && isAmazonCart
}

export function cartSyncUpdatesSelector(state, {cartID}) {
  const usesNewOrderImportProcess = usesNewOrderImportProcessSelector(state, {
    cartID,
  })
  const useWebhooks = useWebhooksSelector(state, {cartID})
  const canImportProductImages = canImportProductImagesSelector(state, {cartID})
  const canImportOrders = canImportOrdersSelector(state, {cartID})
  const canImportProducts = canImportProductsSelector(state, {cartID})
  const canExportProducts = canExportProductsSelector(state, {cartID})
  const isFBACart = isFBACartSelector(state, {cartID})
  const isAmazonCart = isAmazonCartSelector(state, {cartID})
  const isSuperuser = isSuperuserSelector(state)
  const usesInventory = usesInventorySelector(state)
  const preventWriteBackSettingsAdjustment =
    preventWriteBackSettingsAdjustmentSelector(state, {cartID})
  const base = `/cart/${cartID}/autosync`
  const updates = []

  const syncOrders = syncOrdersSelector(state, {cartID})
  const autoSyncOrders = autoSyncOrdersSelector(state, {cartID})
  if (canImportOrders && syncOrders !== autoSyncOrders) {
    updates.push({
      url: `${base}/import_orders_from_cart`,
      params: {
        active: syncOrders,
        ...(useWebhooks ? {frequency: 'daily'} : null),
      },
    })
  }

  const syncProducts = syncProductsSelector(state, {cartID})
  const autoSyncProducts = autoSyncProductsSelector(state, {cartID})
  if (
    canImportProducts &&
    syncProducts !== autoSyncProducts &&
    usesNewOrderImportProcess
  ) {
    updates.push({
      url: `${base}/import_products_from_cart`,
      params: {active: syncProducts},
    })
  }

  const syncProductImages = syncProductImagesSelector(state, {cartID})
  const autoSyncProductImages = autoSyncProductImagesSelector(state, {cartID})
  if (canImportProductImages && syncProductImages !== autoSyncProductImages) {
    updates.push({
      url: `${base}/import_images_from_cart`,
      params: {active: syncProductImages},
    })
  }

  const syncInventory = syncInventorySelector(state, {cartID})
  const autoSyncInventory = autoSyncInventorySelector(state, {cartID})
  if (
    usesInventory &&
    canExportProducts &&
    syncInventory !== autoSyncInventory &&
    (isSuperuser || !preventWriteBackSettingsAdjustment)
  ) {
    updates.push({
      url: `${base}/export_products_to_cart`,
      params: {active: syncInventory},
    })
  }

  const syncFBAInventory = syncFBAInventorySelector(state, {cartID})
  const autoSyncFBAInventory = autoSyncFBAInventorySelector(state, {cartID})
  if (
    isFBACart &&
    canImportProducts &&
    syncFBAInventory !== autoSyncFBAInventory
  ) {
    updates.push({
      url: `${base}/import_fba_inventory`,
      params: {active: syncFBAInventory},
    })
  }

  const syncFBAOrders = syncFBAOrdersSelector(state, {cartID})
  const autoSyncFBAOrders = autoSyncFBAOrdersSelector(state, {cartID})
  if (isAmazonCart && canImportOrders && syncFBAOrders !== autoSyncFBAOrders) {
    updates.push({
      url: `${base}/import_orders_fba_from_cart`,
      params: {active: syncFBAOrders},
    })
  }

  return updates
}

export function cartSyncBulkTasksSelector(state, {freakFlag} = {}) {
  const hasOrderImportFromCartPermission =
    hasOrderImportFromCartPermissionSelector(state)
  const hasProductImportFromCartPermission =
    hasProductImportFromCartPermissionSelector(state)
  const hasProductWritebackInventoryPermission =
    hasProductWritebackInventoryPermissionSelector(state)
  const usesInventory = usesInventorySelector(state)

  const carts = cartsForSyncModalSelector(state)

  return carts.reduce((prev, {id: cartID}) => {
    const syncOrders = syncOrdersSelector(state, {cartID})
    const syncProducts = syncProductsSelector(state, {cartID})
    const syncProductImages = syncProductImagesSelector(state, {cartID})
    const syncInventory = syncInventorySelector(state, {cartID})
    const syncFBAInventory = syncFBAInventorySelector(state, {cartID})
    const syncFBAOrders = syncFBAOrdersSelector(state, {cartID})
    const warehouse = associatedCartWarehouseSelector(state, {cartID})
    const usesNewOrderImportProcess = usesNewOrderImportProcessSelector(state, {
      cartID,
    })
    const canImportOrders = canImportOrdersSelector(state, {cartID})
    const canImportProducts = canImportProductsSelector(state, {cartID})
    const canExportProducts = canExportProductsSelector(state, {cartID})
    const canImportProductImages = canImportProductImagesSelector(state, {
      cartID,
    })
    const isFBACart = isFBACartSelector(state, {cartID})
    const isAmazonCart = isAmazonCartSelector(state, {cartID})

    let isSyncingOrders = false

    if (canImportOrders && syncOrders && hasOrderImportFromCartPermission) {
      prev.push({
        cart: cartID,
        type: 'import_orders_from_cart',
      })
      isSyncingOrders = true
    }

    if (
      canImportProducts &&
      syncProducts &&
      hasProductImportFromCartPermission &&
      (usesNewOrderImportProcess ? true : !isSyncingOrders)
    ) {
      prev.push({
        cart: cartID,
        type: freakFlag
          ? 'force_import_products_from_cart'
          : 'import_products_from_cart',
      })
    }

    if (
      canImportProductImages &&
      syncProductImages &&
      hasProductImportFromCartPermission
    ) {
      prev.push({
        cart: cartID,
        type: freakFlag
          ? 'force_import_images_from_cart'
          : 'import_images_from_cart',
      })
    }

    if (
      usesInventory &&
      canExportProducts &&
      syncInventory &&
      hasProductWritebackInventoryPermission
    ) {
      prev.push({cart: cartID, type: 'export_products_to_cart'})
    }

    if (
      isFBACart &&
      canImportProducts &&
      syncFBAInventory &&
      warehouse &&
      hasProductImportFromCartPermission
    ) {
      prev.push({
        cart: cartID,
        type: 'import_fba_inventory',
        params: {warehouse_id: warehouse.id},
      })
    }

    if (
      isAmazonCart &&
      canImportOrders &&
      syncFBAOrders &&
      hasOrderImportFromCartPermission
    ) {
      prev.push({cart: cartID, type: 'import_orders_fba_from_cart'})
    }

    return prev
  }, [])
}

export const cartIDsWithoutDisabledSyncInventorySelector = createSelector(
  cartsForSyncModalSelector,
  isSuperuserSelector,
  useInventoryWritebackSelector,
  (carts, isSuperuser, useInventoryWriteback) =>
    !useInventoryWriteback
      ? []
      : carts
          .filter(
            (cart) =>
              !(
                !isSuperuser &&
                getPreventWriteBackSettingsAdjustment(cart) &&
                !getAutoSyncInventory(cart)
              ),
          )
          .map(({id}) => id),
)

export const checkedCountSelector = createSelector(
  cartSyncModalFormSelector,
  cartsForSyncModalSelector,
  cartIDsWithoutDisabledSyncInventorySelector,
  (form, carts, cartIDsWithoutDisabledSyncInventory) =>
    carts.reduce((prev, {id}) => {
      const trueValues = (
        cartIDsWithoutDisabledSyncInventory.includes(id)
          ? CART_FORM_KEYS
          : CART_FORM_KEYS_SANS_INVENTORY
      ).filter((key) => get(form, [id, key]))

      return prev + trueValues.length
    }, 0),
)

export const isAllCheckedSelector = createSelector(
  checkedCountSelector,
  cartsForSyncModalSelector,
  cartIDsWithoutDisabledSyncInventorySelector,
  (checkedCount, carts, cartIDsWithoutDisabledSyncInventory) =>
    checkedCount ===
    carts.length * CART_FORM_KEYS_SANS_INVENTORY.length +
      cartIDsWithoutDisabledSyncInventory.length,
)

export function toggleSelectAll() {
  const carts = cartsForSyncModalSelector(getState())
  const cartIDsWithoutDisabledSyncInventory =
    cartIDsWithoutDisabledSyncInventorySelector(getState())
  const allTrue = isAllCheckedSelector(getState())

  const update = carts.reduce((prev, {id}) => {
    prev[id] = CART_FORM_KEYS.reduce((prev, key) => {
      prev[key] = allTrue
        ? false
        : key === SYNC_INVENTORY_KEY
          ? cartIDsWithoutDisabledSyncInventory.includes(id)
          : true

      return prev
    }, {})
    return prev
  }, {})

  updateModalForm(update)
}

export async function startTasks({freakFlag} = {}) {
  const bulkTasks = cartSyncBulkTasksSelector(getState(), {freakFlag})

  await createTasks(bulkTasks)
}

export async function updateCartSync(cartID) {
  const updates = cartSyncUpdatesSelector(getState(), {cartID})

  await Promise.all(updates.map(({url, params}) => api.put(url, params)))

  const {json} = await api.get(`/cart/${cartID}/`)

  setCart(json)
}

export async function saveCartSyncSettings() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const carts = cartsForSyncModalSelector(getState())

    await Promise.all(carts.map(({id}) => updateCartSync(id)))

    await startTasks()

    closeModal()

    showMessageToast(
      'We’re also syncing your selected carts. This could take a few minutes to complete, so keep an eye on the Activity panel for the status of the processes that are being run.',
    )
  } catch (err) {
    updateModalForm({
      serverError: `There was an error saving sync settings: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

export async function syncOnceCartSyncSettings({freakFlag} = {}) {
  try {
    updateModalForm({isSyncing: true, serverError: null})

    await startTasks({freakFlag})

    closeModal()

    showMessageToast(
      'This could take a few minutes to complete, so keep an eye on the Activity panel for the status of the processes that are being run.',
    )
  } catch (err) {
    updateModalForm({
      serverError: `There was an error starting sync task: ${
        err.message || err.error_message
      }`,
      isSyncing: false,
    })
  }
}
