import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {TagsShape} from '../../../common/PropTypes.js'
import {
  showImgsProductListSelector,
  usesInventorySelector,
} from '../../../data/company.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  nameSelector,
  createTagsSelector,
  productComponentsSelector,
  isKitSelector,
  isBOMSelector,
  isArchivedSelector,
  imageURLSelector,
} from '../../../data/products.js'
import {
  productDetailURLSelector,
  getProductDetailURL,
} from '../../ProductDetailPage/productDetailSelectors.js'
import {showProductTextFieldModal} from '../Modals/ProductTextFieldModal.js'

import AdditionalInfo from './AdditionalInfo/index.js'
import ProductTags from '../ProductTags.js'
import PopoverImage from '../../../common/components/PopoverImage.js'

export function NameCell({
  sku,
  isExpanded,
  name,
  productDetailURL,
  productComponents,
  isKit,
  isBOM,
  isArchived,
  imageURL,
  usesInventory,
  tags,
  showImgsProductList,
}) {
  const tagComponent =
    tags.length > 0 ? (
      <div
        className={
          isExpanded
            ? 'wrap--po-addl-info list list--order-data'
            : 'wrap--summary-tags-table wrap--summary-tags-inline'
        }
      >
        <ProductTags sku={sku} tags={tags} />
      </div>
    ) : null

  if (!usesInventory) {
    return (
      <td className="table__td table__td--product-list-name img-enabled">
        <div className="flex">
          {showImgsProductList && <PopoverImage src={imageURL} alt={name} />}
          <div>
            <div className="wrap--product-name">
              <LinkButton
                className="darkest lh-md margin-right-5"
                href={productDetailURL}
              >
                <strong>{name}</strong>
              </LinkButton>
              {isArchived && (
                <div className="label__callout label__callout--grey inline-block margin-right-5">
                  <strong>ARCHIVED</strong>
                </div>
              )}
              <ButtonLink
                className="btn--edit-product-name no-underline"
                onClick={() =>
                  showProductTextFieldModal({
                    sku,
                    value: name,
                    display: 'Name',
                    apiProp: 'name',
                    isRequired: true,
                    needsProductListRefresh: true,
                  })
                }
              >
                <span
                  className="i-pencil fs-00 lh-sm v-align-middle"
                  aria-hidden="true"
                />
              </ButtonLink>
            </div>
            {tagComponent}
          </div>
        </div>
      </td>
    )
  }

  return (
    <td
      className={`table__td table__td--product-list-name ${
        showImgsProductList ? 'img-enabled' : ''
      }`}
    >
      <div className="flex">
        {showImgsProductList && <PopoverImage src={imageURL} alt={name} />}
        <div>
          <div className="wrap--product-name">
            <LinkButton
              className="darkest lh-md margin-right-5"
              href={productDetailURL}
            >
              <strong>{name}</strong>
            </LinkButton>
            {isKit && (
              <LinkButton
                className="label__callout label__callout--grey no-underline margin-right-5"
                href={`${productDetailURL}/kitting`}
              >
                <strong>KIT</strong>
              </LinkButton>
            )}
            {isBOM && (
              <LinkButton
                className="label__callout label__callout--grey no-underline margin-right-5"
                href={`${productDetailURL}/bom`}
              >
                <strong>BOM</strong>
              </LinkButton>
            )}
            {isArchived && (
              <div className="label__callout label__callout--grey inline-block margin-right-5">
                <strong>ARCHIVED</strong>
              </div>
            )}
            <ButtonLink
              className="btn--edit-product-name no-underline"
              onClick={() =>
                showProductTextFieldModal({
                  sku,
                  value: name,
                  display: 'Name',
                  apiProp: 'name',
                  isRequired: true,
                  needsProductListRefresh: true,
                })
              }
            >
              <span
                className="i-pencil fs-00 lh-sm v-align-middle"
                aria-hidden="true"
              />
            </ButtonLink>
          </div>
          {isExpanded && productComponents.length > 0 && (
            <AdditionalInfo>
              {productComponents.map((component) => {
                const item = (
                  <AdditionalInfo.Item key={component.id}>
                    <AdditionalInfo.ItemDisplay>
                      <LinkButton
                        className="darkest"
                        href={getProductDetailURL(component.sku)}
                      >
                        <span className="unbold">{component.name}</span>
                      </LinkButton>
                      <div className="text--lt-grey unbold fs-n2">
                        ({component.total_on_hand} on hand)
                      </div>
                    </AdditionalInfo.ItemDisplay>
                    <AdditionalInfo.ItemValue>
                      <span>{component.quantity}</span>
                    </AdditionalInfo.ItemValue>
                  </AdditionalInfo.Item>
                )

                return showImgsProductList ? (
                  <div
                    className="flex wrap__inner--product-addl-info-list"
                    key={component.id}
                  >
                    <PopoverImage
                      src={component.image_url}
                      alt={component.name}
                    />
                    {item}
                  </div>
                ) : (
                  item
                )
              })}
            </AdditionalInfo>
          )}
          {tagComponent}
        </div>
      </div>
    </td>
  )
}

NameCell.propTypes = {
  sku: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  tags: TagsShape.isRequired,
  productDetailURL: PropTypes.string.isRequired,
  productComponents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      total_on_hand: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isKit: PropTypes.bool.isRequired,
  isBOM: PropTypes.bool.isRequired,
  isArchived: PropTypes.bool.isRequired,
  imageURL: PropTypes.string,
  usesInventory: PropTypes.bool.isRequired,
  showImgsProductList: PropTypes.bool.isRequired,
}

function mapStateToProps(state, {sku}) {
  const tagsSelector = createTagsSelector(sku)

  return (state) => ({
    name: nameSelector(state, {sku}),
    tags: tagsSelector(state),
    productDetailURL: productDetailURLSelector(state, {sku}),
    productComponents: productComponentsSelector(state, {sku}),
    isKit: isKitSelector(state, {sku}),
    isBOM: isBOMSelector(state, {sku}),
    isArchived: isArchivedSelector(state, {sku}),
    imageURL: imageURLSelector(state, {sku}),
    usesInventory: usesInventorySelector(state),
    showImgsProductList: showImgsProductListSelector(state),
  })
}

export default connect(mapStateToProps)(NameCell)
