import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import TextInput from '../../../../common/components/Form/TextInput.js'
import Row from '../../../../common/components/Form/Row.js'
import Select from '../../../../common/components/Form/Select.js'
import {
  getSupplierName,
  suppliersSortedByNameSelector,
} from '../../../../data/suppliers.js'
import BaseEditForm from '../BaseEditForm.js'

function EditForm(props) {
  const data = props.data

  return (
    <BaseEditForm className="medium-6 columns" {...props}>
      <Row>
        <Select
          value={data.payload.supplierID}
          errorMessage={data.errors.supplierID}
          name="supplierID"
          label="Supplier"
          className="columns medium-12 wrap--full-select"
          selectClassName="full-width"
          onChange={(value) =>
            props.updatePayload(data.index, {supplierID: Number(value)})
          }
        >
          <option value="-1"></option>
          {props.suppliersSortedByName.map((supplier) => (
            <option key={supplier.id} value={supplier.id}>
              {getSupplierName(supplier)}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <TextInput
          value={data.payload.sku}
          errorMessage={data.errors.sku}
          name="sku"
          label="SKU"
          className="medium-12"
          onChange={(value) => props.updatePayload(data.index, {sku: value})}
        />
      </Row>
    </BaseEditForm>
  )
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired,
  updatePayload: PropTypes.func.isRequired,
  suppliersSortedByName: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    suppliersSortedByName: suppliersSortedByNameSelector(state),
  }
}

export default connect(mapStateToProps)(EditForm)
