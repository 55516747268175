export const BASE = 'ordoro/ui/dataImport/data'
export const SET_DATA = `${BASE}/SET_DATA`
export const SET_ERRORS = `${BASE}/SET_ERRORS`
export const SET_IS_SAVED = `${BASE}/SET_IS_SAVED`
export const SET_IS_DELETED = `${BASE}/SET_IS_DELETED`
export const SET_PAYLOAD = `${BASE}/SET_PAYLOAD`

export function setData(value) {
  return {
    type: SET_DATA,
    payload: value,
  }
}

export function setErrors(index, value) {
  return {
    type: SET_ERRORS,
    index,
    payload: value,
  }
}

export function setIsSaved(index, value) {
  return {
    type: SET_IS_SAVED,
    index,
    payload: value,
  }
}

export function setIsDeleted(index, value) {
  return {
    type: SET_IS_DELETED,
    index,
    payload: value,
  }
}

export function setPayload(index, value) {
  return {
    type: SET_PAYLOAD,
    index,
    payload: value,
  }
}
