import get from 'lodash/get.js'

import {getState, setForm, updateForm, removeForm} from '../../../../store.js'
import api from '../../../../common/api.js'
import {formatCurrency} from '../../../../common/components/Currency.js'
import {PURCHASE_ORDER_LIST_PAGE} from '../../../../common/constants/Pages.js'
import {showMessageToast} from '../../../Header/Toast/index.js'
import {activeNonFBASupplierOptionsSelector} from '../../../../data/suppliers.js'
import {
  nonFBAWarehouseOptionsSelector,
  defaultWarehouseSelector,
} from '../../../../data/warehouses.js'
import {setProductOnPOS, productSelector} from '../../../../data/products.js'
import {refreshPurchaseOrderList} from '../../../PurchaseOrderListPage/purchaseOrderListActions.js'
import {currentPageSelector} from '../../../../redux/selectors/ui/index.js'
import {
  CREATE_PO_MODAL,
  createPOModalFormSelector,
} from './createPOModalSelectors.js'

export function showCreatePOModal(skus) {
  setForm(CREATE_PO_MODAL, {
    lines: skus.map((sku) => setupCreatePOLine(sku)),
    isSaving: false,
    serverError: null,
  })
}

export function updateCreatePOModalForm(updates) {
  updateForm(CREATE_PO_MODAL, updates)
}

export function closeCreatePOModal() {
  removeForm(CREATE_PO_MODAL)
}

export function updateCreatePOLine(index, updates) {
  const form = createPOModalFormSelector(getState()) || {}
  const lines = [...form.lines]

  lines[index] = {...lines[index], ...updates}

  updateCreatePOModalForm({lines})
}

export function addCreatePOLine() {
  const {lines} = createPOModalFormSelector(getState())

  updateCreatePOModalForm({
    lines: [
      ...lines,
      {
        sku: null,
        supplierID: -1,
        warehouseID: -1,
        quantity: '1',
        unitPrice: '0',
      },
    ],
  })
}

export function getInitialSupplierValues(product, selectedSupplierID) {
  const selectedSupplier = product.suppliers.find(
    (supplier) => supplier.id === selectedSupplierID,
  )

  const quantity = selectedSupplier ? selectedSupplier.min_order_qty : 1
  const unitPrice = get(selectedSupplier, 'supplier_price', 0)

  return {
    quantity: `${quantity}`,
    unitPrice: formatCurrency(unitPrice, '', {
      useGrouping: false,
      maximumFractionDigits: 10,
    }),
  }
}

export function setupCreatePOLine(sku) {
  const product = productSelector(getState(), {sku})
  const supplierOptions = activeNonFBASupplierOptionsSelector(getState())
  const warehouseOptions = nonFBAWarehouseOptionsSelector(getState())
  const defaultWarehouse = defaultWarehouseSelector(getState())
  const defaultSupplier = product.suppliers.find(
    (supplier) => supplier.is_default,
  )
  const selectedSupplierID = defaultSupplier
    ? defaultSupplier.id
    : get(supplierOptions, [0, 'value'])
  const selectedWarehouseID = defaultWarehouse
    ? defaultWarehouse.id
    : get(warehouseOptions, [0, 'value'])

  return {
    sku: product.sku,
    supplierID: selectedSupplierID,
    warehouseID: selectedWarehouseID,
    ...getInitialSupplierValues(product, selectedSupplierID),
  }
}

export function initializeCreatePOLine(index, product) {
  updateCreatePOLine(index, setupCreatePOLine(product.sku))
}

export function removeCreatePOLine(index) {
  let {lines} = createPOModalFormSelector(getState())

  lines = lines.filter((_, i) => i !== index)

  updateCreatePOModalForm({lines})
}

export async function buyProduct(index) {
  const {sku, supplierID, warehouseID, quantity, unitPrice} =
    createPOModalFormSelector(getState()).lines[index]
  const url = `/product/${encodeURIComponent(sku)}/buy/${supplierID}/`
  const params = {
    warehouse: warehouseID,
    quantity: Number(quantity),
    unit_price: Number(unitPrice),
  }

  await api.post(url, params)

  const product = productSelector(getState(), {sku})

  setProductOnPOS({
    sku,
    onPOs: product.on_pos + params.quantity,
  })
}

export async function createPO() {
  try {
    const {lines} = createPOModalFormSelector(getState())

    updateCreatePOModalForm({
      isSaving: true,
      serverError: null,
    })

    for (let index = 0; index < lines.length; index++) {
      await buyProduct(index)
    }

    closeCreatePOModal()

    showMessageToast('POs were updated with the products you want to reorder.')

    const currentPage = currentPageSelector(getState())

    if (currentPage === PURCHASE_ORDER_LIST_PAGE) {
      await refreshPurchaseOrderList()
    }
  } catch (err) {
    updateCreatePOModalForm({
      serverError: `Error creating purchase order: ${
        err.error_message || err.message
      }`,
    })
  }

  updateCreatePOModalForm({
    isSaving: false,
  })
}
