import Checkbox from '../../../../common/components/Checkbox.js'
import {AWAITING_FULFILLMENT} from '../../../../common/constants/Orders.js'
import {useInventoryAllocationSelector} from '../../../../data/company.js'
import {useSelector} from '../../../../store.js'
import {allocationStatiiSelector} from '../../orderListSelectors.js'
import {
  modalFormSelector,
  updateModalForm,
} from './orderListFilterModalFunctions.js'

export default function AllocationFilterPanel() {
  const form = useSelector(modalFormSelector)
  const allocationStatii = useSelector(allocationStatiiSelector)
  const useInventoryAllocation = useSelector(useInventoryAllocationSelector)

  if (
    !(
      useInventoryAllocation &&
      (form.status.length === 0 || form.status.includes(AWAITING_FULFILLMENT))
    )
  ) {
    return null
  }

  return (
    <div className="wrap--modal-filters wrap--modal-filters-allocation">
      <div className="wrap__modal-subheader margin-bottom-25">
        <h4 className="fs-01 uppercase margin-top-0 margin-bottom-0">
          <strong>Allocation</strong>
        </h4>
      </div>
      <ul className="list list--no-style list--modal-filters flex flex-wrap">
        {allocationStatii.map(({slug, name}) => (
          <li key={slug} className="list__item list__item--modal-filters">
            <Checkbox
              mode="fancy"
              id={`allocation_${slug}`}
              checked={form.allocationFilters.includes(slug)}
              onChange={() => {
                const allocationFilters = form.allocationFilters.filter(
                  (value) => value !== slug,
                )

                if (
                  allocationFilters.length === form.allocationFilters.length
                ) {
                  allocationFilters.push(slug)
                }

                updateModalForm({allocationFilters})
              }}
              label={name}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
