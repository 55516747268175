import PropTypes from 'prop-types'
import classNames from 'classnames'

function getHint(shippability) {
  if (shippability === 'partially_shippable') {
    return 'Some lines are out of stock'
  }
  if (shippability === 'unshippable') {
    return 'All lines are out of stock'
  }
  return null
}

export default function ShippabilityIcon({shippability, className}) {
  if (shippability === 'shippable' || shippability === 'shipped') {
    return null
  }

  return (
    <div
      className={classNames('wrap--order-shippability', className, {
        'partially-shippable': shippability === 'partially_shippable',
        unshippable: shippability === 'unshippable',
      })}
    >
      <div className="tooltip--top" data-hint={getHint(shippability)}>
        <div className="i--shippability fs-00" aria-hidden="true" />
      </div>
    </div>
  )
}

ShippabilityIcon.propTypes = {
  shippability: PropTypes.string.isRequired,
  className: PropTypes.string,
}
