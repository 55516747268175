import PropTypes from 'prop-types'
import {useEffect} from 'react'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
  dispatch,
} from '../../../store.js'
import {isPresent} from '../../../common/utils.js'
import apiversion from '../../../common/apiverson.js'
import {toEmailList, validateEmailList} from '../../../common/email.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReport, reportSelector} from '../../../data/reports.js'
import {
  REPORTS_BY_TYPE,
  REPORT_SCHEDULE_FREQUENCY_OPTIONS,
  REPORT_INTERVALS,
} from '../../../common/constants/Reports.js'
import Select from '../../../common/components/Select.js'
import ReportForms from '../ReportForms/index.js'
import Checkbox from '../../../common/components/Checkbox.js'
import api from '../../../common/api.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {getRealDate, getTimeZone} from '../../../common/date.js'
import {refreshReportActivity} from '../reportsPageActions.js'
import {
  schedulableReportsSelector,
  singleRunReportsSelector,
} from '../reportsPageSelectors.js'

export const MODAL_FORM = 'EDIT_REPORT_MODAL'

export function showEditReportModal({
  reportID,
  reportType,
  autoFocusOn = 'name',
  singleRun = false,
  interval_type,
  start_date,
  end_date,
  ...params
} = {}) {
  const hideReportSelection = !!reportID || !!reportType
  const report = reportID
    ? reportSelector(getState(), {reportID})
    : {to_emails: [], schedules: [], params: {}}
  const schedulableReports = schedulableReportsSelector(getState())
  const singleRunReports = singleRunReportsSelector(getState())
  reportType =
    report.type ||
    reportType ||
    (singleRun ? singleRunReports[0] : schedulableReports[0])
  const schedule = report.schedules[0] || {
    frequency: '',
    active: true,
  }

  const now = getRealDate()
  if (!start_date && !end_date) {
    start_date = subMonths(now, 1)
    end_date = now
  } else if (start_date && !end_date) {
    end_date = addMonths(start_date, 1)

    if (end_date > now) {
      end_date = now
    }
  } else if (!start_date && end_date) {
    start_date = subMonths(end_date, 1)
  }

  interval_type =
    interval_type !== undefined ? interval_type : report.params.interval_type
  if (!REPORT_INTERVALS.includes(interval_type)) {
    interval_type = singleRun ? '' : REPORT_INTERVALS[0]
  }

  setForm(MODAL_FORM, {
    reportID,
    name: report.name || '',
    to_emails: report.to_emails.join('\n') || '',
    subject: report.subject || '',
    is_alert: report.is_alert || false,
    scheduleID: schedule.id,
    frequency: schedule.frequency,
    active: schedule.active,
    reportType,
    autoFocusOn,
    singleRun,
    hideReportSelection,
    reportForm: null,
    interval_type,
    interval_amount: String(Number(report.params.interval_amount) || '1'),
    interval_trunc: !!(report.params.interval_trunc || false),
    start_date,
    end_date,
    timezone: report.params.timezone || getTimeZone(),
    isSaving: false,
    serverError: null,
    ...params,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function errorsSelector(state) {
  const {name, to_emails, singleRun, reportForm} = modalFormSelector(state)
  let errors = {}

  if (!isPresent(name) && !singleRun) {
    errors.name = 'Name is required'
    errors.preventSave = true
  }

  const {invalidEmails} = validateEmailList(toEmailList(to_emails))
  if (invalidEmails.length) {
    errors.to_emails = `“${invalidEmails[0]}” is not a valid email address`
    errors.preventSave = true
  }

  if (reportForm && reportForm.errorsSelector) {
    errors = {
      ...errors,
      ...reportForm.errorsSelector(state, {formName: MODAL_FORM}),
    }
  }

  return errors
}

export function setReportForm() {
  const form = modalFormSelector(getState())

  const reportForm = ReportForms[form.reportType]

  updateModalForm({
    ...(reportForm ? {...reportForm.setupForm(form), reportForm} : null),
  })
}

export function toggleSingleRun() {
  const {singleRun} = modalFormSelector(getState())

  updateModalForm({
    singleRun: !singleRun,
  })

  setReportForm()
}

async function saveReport(reportID, payload) {
  if (reportID) {
    delete payload.type
  }

  const {json} = await (reportID
    ? apiversion.put(`/report/${reportID}`, payload)
    : apiversion.post('/report', payload))

  return json
}

async function saveSchedule(reportID, scheduleID, payload) {
  const {json} = await (scheduleID
    ? payload
      ? apiversion.put(`/report/${reportID}/schedule/${scheduleID}`, payload)
      : apiversion.delete(`/report/${reportID}/schedule/${scheduleID}`)
    : payload
      ? apiversion.post(`/report/${reportID}/schedule`, payload)
      : {})

  return json
}

export async function confirmTaskStart() {
  try {
    const {to_emails, subject, reportForm} = modalFormSelector(getState())

    const task =
      reportForm && reportForm.payloadSelector
        ? reportForm.payloadSelector(getState(), {formName: MODAL_FORM})
        : null

    if (!task) {
      return
    }

    if (to_emails) {
      task.params = task.params || {}
      task.params.to_emails = toEmailList(to_emails)
      task.params.delivery_method = 'email'
      task.params.subject = subject
    }

    updateModalForm({serverError: null, isSaving: true})

    await api.post('/new_task', task)

    dispatch(checkRunningTasks())

    showMessageToast(
      'We’re generating your CSV file. Check the Reports Activity Log to download it.',
    )

    closeModal()

    await refreshReportActivity()
  } catch (err) {
    updateModalForm({
      serverError: err.error_message || err.message,
      isSaving: false,
    })
  }
}

export async function confirmReportSave() {
  try {
    const {
      reportID,
      name,
      to_emails,
      subject,
      is_alert,
      scheduleID,
      frequency,
      active,
      reportForm,
    } = modalFormSelector(getState())

    updateModalForm({serverError: null, isSaving: true})

    const payload = {
      name,
      to_emails: toEmailList(to_emails),
      subject,
      is_alert,
      ...(reportForm && reportForm.payloadSelector
        ? reportForm.payloadSelector(getState(), {formName: MODAL_FORM})
        : null),
    }
    const schedulePayload = frequency
      ? {
          frequency,
          active,
          to_emails: payload.to_emails,
        }
      : null

    const report = await saveReport(reportID, payload)

    await saveSchedule(report.id, scheduleID, schedulePayload)

    const {json} = await apiversion.get(`/report/${report.id}`)

    setReport(json)

    showMessageToast(
      `Report was successfully ${reportID ? 'updated' : 'created'}.`,
    )

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditReportModal({form}) {
  const report = useSelector((state) =>
    reportSelector(state, {reportID: form.reportID}),
  )
  const errors = useSelector(errorsSelector)
  const schedulableReports = useSelector(schedulableReportsSelector)
  const singleRunReports = useSelector(singleRunReportsSelector)

  useEffect(() => {
    setReportForm()
  }, [form.reportType])

  return (
    <ConfirmModal
      title={
        form.singleRun
          ? REPORTS_BY_TYPE[form.reportType].display
          : `${form.reportID ? 'Edit' : 'Create'} a Report`
      }
      modalSize="sm-md"
      onConfirm={
        form.singleRun ? () => confirmTaskStart() : () => confirmReportSave()
      }
      MiddleButtons={
        form.reportForm && schedulableReports.includes(form.reportType)
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isLoading={form.isSaving}
                onClick={() => toggleSingleRun()}
              >
                {form.singleRun ? 'Schedule' : 'Single Run'}
              </ButtonPrimary>
            )
          : null
      }
      onCancel={() => closeModal()}
      confirmText={form.singleRun ? 'Export' : 'Save'}
      cancelText="Cancel"
      isDisabled={errors.preventSave}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {!form.singleRun && (
          <>
            {report && (
              <li className="list__item--form list__item--no-style divider--bottom">
                <div className="fs-01">
                  <strong>Updating {report.name}</strong>
                </div>
              </li>
            )}
            <li className="list__item list__item--form">
              <TextInput
                label="Name"
                id="report_name"
                className="margin-bottom-0"
                value={form.name}
                onChange={(name) => updateModalForm({name})}
                errorMessage={errors.name}
                autoFocus={form.autoFocusOn === 'name'}
              />
            </li>
          </>
        )}
        <li className="list__item list__item--form">
          <TextArea
            className="textarea textarea--mh-auto"
            rows="4"
            label={`Recipient Email Addresses${
              form.singleRun ? ' (Optional)' : ''
            }`}
            id="details"
            value={form.to_emails}
            onChange={(to_emails) => updateModalForm({to_emails})}
            errorMessage={errors.to_emails}
            autoFocus={form.autoFocusOn === 'to_emails'}
          />
        </li>
        {form.to_emails && (
          <li className="list__item list__item--form">
            <TextInput
              label="Email Subject Line"
              id="subject"
              className="margin-bottom-0"
              value={form.subject}
              onChange={(subject) => updateModalForm({subject})}
              errorMessage={errors.subject}
              autoFocus={form.autoFocusOn === 'subject'}
            />
          </li>
        )}
        {!form.singleRun && (
          <>
            <li className="list__item list__item--form margin-bottom-25">
              <Checkbox
                mode="fancy"
                label="Send email only if data is reported"
                id="is_alert"
                className="margin-bottom-0"
                checked={form.is_alert}
                onChange={(is_alert) => updateModalForm({is_alert})}
                errorMessage={errors.is_alert}
                autoFocus={form.autoFocusOn === 'is_alert'}
              />
            </li>
            <li className="list__item list__item--form">
              <Select
                label="Frequency"
                id="frequency"
                className="margin-bottom-0"
                value={form.frequency}
                onChange={(frequency) => updateModalForm({frequency})}
                errorMessage={errors.frequency}
                autoFocus={form.autoFocusOn === 'frequency'}
              >
                <option value="">Not Scheduled</option>
                {REPORT_SCHEDULE_FREQUENCY_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.display}
                  </option>
                ))}
              </Select>
            </li>
            {form.scheduleID && form.frequency && (
              <li className="list__item list__item--form">
                <Checkbox
                  mode="fancy"
                  label="Enabled"
                  id="active"
                  className="margin-bottom-0"
                  checked={form.active}
                  onChange={(active) => updateModalForm({active})}
                  errorMessage={errors.active}
                  autoFocus={form.autoFocusOn === 'active'}
                />
              </li>
            )}
          </>
        )}
        {!form.hideReportSelection && form.reportType && (
          <li className="list__item list__item--form margin-top-20">
            <Select
              label="Report Type"
              value={form.reportType}
              onChange={(reportType) => updateModalForm({reportType})}
            >
              {(form.singleRun ? singleRunReports : schedulableReports).map(
                (reportType) => (
                  <option
                    key={REPORTS_BY_TYPE[reportType].value}
                    value={REPORTS_BY_TYPE[reportType].value}
                  >
                    {REPORTS_BY_TYPE[reportType].display}
                  </option>
                ),
              )}
            </Select>
          </li>
        )}
        {form.reportForm && (
          <li className="list__item list__item--form">
            <form.reportForm.Component
              formName={MODAL_FORM}
            ></form.reportForm.Component>
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditReportModal.propTypes = {
  form: PropTypes.shape({
    reportID: PropTypes.number,
    reportType: PropTypes.string,
    name: PropTypes.string.isRequired,
    to_emails: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    is_alert: PropTypes.bool.isRequired,
    scheduleID: PropTypes.number,
    active: PropTypes.bool.isRequired,
    frequency: PropTypes.string.isRequired,
    autoFocusOn: PropTypes.string.isRequired,
    singleRun: PropTypes.bool.isRequired,
    hideReportSelection: PropTypes.bool.isRequired,
    reportForm: PropTypes.shape({
      Component: PropTypes.any.isRequired,
      errorsSelector: PropTypes.func.isRequired,
      payloadSelector: PropTypes.func.isRequired,
    }),
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditReportModal, modalFormSelector)
