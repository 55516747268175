import PropTypes from 'prop-types'
import classNames from 'classnames'

import {IntegrationShape} from '../../../common/PropTypes.js'
import Forms from './Forms/index.js'

export default function EditForm(props) {
  const {integration, editID} = props

  const Form = Forms[integration.vendor]
  const href = `#/settings/payments/${integration.id}`
  const isExpanded = (editID && Number(editID) === integration.id) || true

  return (
    <li
      className={classNames('list__item--settings-wrap clearfix', {
        expanded: isExpanded,
      })}
    >
      <dl className="list--settings">
        <dt className="medium-4 columns list__title-logo">
          <div className={classNames('wrap--logo', integration.vendor)} />
        </dt>
        <dd className="make-medium-grey medium-5 columns">
          {integration.name}
        </dd>
        <dd className="medium-2 columns right-aligned-text right">
          <a
            className="inline-text-button settings-list-item-button edit-url"
            href={href}
          >
            <span
              className="icon icon-edit icon-block x-sm margin-right-2"
              aria-hidden="true"
            />
            <span className="button-text">Edit</span>
          </a>
        </dd>
      </dl>
      {isExpanded && <Form isNew={false} integrationID={integration.id} />}
    </li>
  )
}

EditForm.propTypes = {
  integration: IntegrationShape.isRequired,
  editID: PropTypes.string,
}
