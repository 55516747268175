import PropTypes from 'prop-types'
import {useCallback, useRef} from 'react'
import debounce from 'lodash/debounce.js'
import classNames from 'classnames'

import {getState, updateForm, formsSelector, useAutoForm} from '../../store.js'
import TextInput from './TextInput.js'
import Zropdown, {useZropdown} from './Zropdown.js'
import apiverson from '../apiverson.js'
import {setBatch} from '../../data/batches.js'

export function getInitialForm() {
  return {
    text: '',
    results: [],
    selectedIndex: 0,
    hasFoundNothing: false,
    isLoading: false,
    serverError: null,
  }
}

export async function searchBatches(
  dropdown,
  excludedReferenceIDs = [],
  queryParams = {},
) {
  const taskQueue = searchBatches.taskQueue || {}
  searchBatches.taskQueue = taskQueue

  taskQueue[dropdown] = {}

  try {
    const form = formsSelector(getState())[dropdown]

    if (!form) {
      return
    }

    const text = form.text.trim()

    if (!text) {
      updateForm(dropdown, {
        results: [],
        selectedIndex: 0,
        hasFoundNothing: false,
        isLoading: false,
        serverError: null,
      })

      return
    }

    updateForm(dropdown, {isLoading: true, serverError: null})

    const limit = 15 + excludedReferenceIDs.length

    const {
      json: {batch: batches},
    } = await apiverson.get('/batch', {
      limit,
      search: form.text,
      active: true,
      ...queryParams,
    })

    if (searchBatches.taskQueue[dropdown] !== taskQueue[dropdown]) {
      return
    }

    const results = batches.filter(
      ({reference_id}) => !excludedReferenceIDs.includes(reference_id),
    )

    updateForm(dropdown, {
      results,
      selectedIndex: 0,
      hasFoundNothing: results.length === 0,
      isLoading: false,
    })
  } catch (err) {
    updateForm(dropdown, {
      results: [],
      selectedIndex: 0,
      serverError: err.message,
      isLoading: false,
    })
  } finally {
    if (searchBatches.taskQueue[dropdown] === taskQueue[dropdown]) {
      delete taskQueue[dropdown]
    }
  }
}

export default function BatchFilter({
  label,
  placeholder,
  dropdown,
  onSelect,
  excludedReferenceIDs,
  queryParams,
  autoFocus,
  onBlur,
}) {
  const id = `${dropdown}__input`
  placeholder = placeholder || 'Search for a batch...'

  const debouncedSearchBatches = useCallback(
    debounce(
      () => searchBatches(dropdown, excludedReferenceIDs, queryParams),
      400,
    ),
    [dropdown, excludedReferenceIDs, queryParams],
  )

  const ref = useRef()

  const form = useAutoForm(dropdown, getInitialForm())

  const {onChangeInternal, onSelectInternal, onConfirm, onUp, onDown} =
    useZropdown({
      dropdown,
      form,
      onChange: () => debouncedSearchBatches(),
      onSelect: (batch) => {
        setBatch(batch)

        onSelect(batch)

        onChangeInternal('')
      },
    })

  return (
    <div className="outerwrap--batch-search-zropdown">
      {label && (
        <label className="label--batch-search" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="wrap--batch-search" ref={ref}>
        <label className="label--input-prefix label--search" htmlFor={id}>
          <TextInput
            className="input--search input--lighter inline-block v-align-middle"
            id={id}
            value={form.text}
            placeholder={placeholder}
            onChange={(value) => onChangeInternal(value)}
            onBlur={(event) => onBlur && onBlur(event)}
            autoFocus={autoFocus}
          />
        </label>
      </div>
      <Zropdown
        parentRef={ref}
        dropdown={dropdown}
        form={form}
        onConfirm={onConfirm}
        onUp={onUp}
        onDown={onDown}
      >
        {form.isLoading && (
          <li className="list__item--dropdown list__item--dropdown-empty align-center">
            <span className="spinner--sm v-align-middle" />
            <strong className="inline-block v-align-middle fs-00 op-75 margin-left-5">
              Loading...
            </strong>
          </li>
        )}
        {form.results.map((batch, index) => {
          return (
            <li
              key={batch.reference_id}
              className="list__item list__item--dropdown"
            >
              <button
                key={batch.id}
                className={classNames(
                  'btn--link list__link list__link--dropdown flex--justify-nowrap',
                  {
                    'list__link--dropdown-arrow-focus':
                      index === form.selectedIndex,
                  },
                )}
                onClick={() => onSelectInternal(batch)}
              >
                <div>
                  <strong className="block">{batch.reference_id}</strong>
                  <span className="block unbold">
                    Order Count: {batch.order_numbers.length}
                  </span>
                </div>
              </button>
            </li>
          )
        })}
        {form.hasFoundNothing && (
          <li className="list__item--dropdown list__item--dropdown-empty align-center">
            <em>No batches were found</em>
          </li>
        )}
      </Zropdown>
    </div>
  )
}

BatchFilter.propTypes = {
  label: PropTypes.node,
  placeholder: PropTypes.string,
  dropdown: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  excludedReferenceIDs: PropTypes.arrayOf(PropTypes.string),
  queryParams: PropTypes.object,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  form: PropTypes.shape({
    text: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedIndex: PropTypes.number.isRequired,
    hasFoundNothing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}
