import PropTypes from 'prop-types'
import {useEffect} from 'react'
import isBefore from 'date-fns/isBefore'
import subMonths from 'date-fns/subMonths'

import {TaskShape} from '../../common/PropTypes.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import {useSelector} from '../../store.js'
import {
  refreshReportActivity,
  setLimit,
  setOffset,
} from './reportsPageActions.js'
import {
  reportPageFormSelector,
  reportsActivityQuerySelector,
} from './reportsPageSelectors.js'
import Footer from '../../common/components/List/Footer/index.js'
import {sanitize} from '../../common/sanitize.js'
import {getTaskStatus} from '../../redux/selectors/data/tasks.js'
import {formatAPIURL} from '../../common/api.js'
import {isSuperuserSelector} from '../../data/me.js'
import {REPORTS_BY_TYPE} from '../../common/constants/Reports.js'

function TaskActivityRow({task, showLogLink}) {
  const taskIsTooOld = isBefore(
    new Date(task.created),
    subMonths(new Date(), '1'),
  )
  const showResultLink =
    task.table_key === 'export_task' && task.complete && task.success

  return (
    <tr>
      <td className="table__td">
        <strong className="fs-n0 lh-md">
          <FormattedDate value={task.created} format="MMM D, YYYY [at] h:mma" />
        </strong>
      </td>
      <td className="table__td">
        <div className="fs-n0 lh-md">
          {REPORTS_BY_TYPE[task.type]
            ? REPORTS_BY_TYPE[task.type].display
            : task.type}
        </div>
      </td>

      <td className="table__td">
        {task.params.to_emails && (
          <div className="fs-n0 lh-md">{task.params.to_emails.join(', ')}</div>
        )}
      </td>
      <td className="table__td align-center">
        <div className="fs-n0 lh-md">
          {!task.complete && <em>In progress...</em>}
          {task.success ? (
            <>
              <div
                className="i-check fs-01 margin-right-3 text--green v-align-middle"
                aria-hidden="true"
              />
            </>
          ) : task.complete ? (
            <>
              <div
                className="i-close fs-01 margin-right-3 error v-align-middle"
                aria-hidden="true"
              />
            </>
          ) : (
            ''
          )}
        </div>
      </td>

      <td className="table__td">
        <div
          className="fs-n0 lh-md"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              getTaskStatus(task).replace(
                'An error occurred. Please contact Ordoro support.',
                '',
              ),
            ),
          }}
        />
      </td>
      <td className="table__td">
        {showResultLink &&
          (taskIsTooOld ? (
            <em className="fs-n0 lh-md op-50">Unavailable</em>
          ) : (
            <div className="fs-n0 lh-md">
              <a
                className="btn btn--primary btn--primary-ol btn--x-sm w-100"
                href={formatAPIURL(task._linkdata)}
              >
                <strong className="op-75 v-align-middle">⇣</strong>
                <strong className="v-align-middle">CSV</strong>
              </a>
            </div>
          ))}
      </td>
      {showLogLink && (
        <td className="table__td">
          <a
            className="fs-n0 lh-md"
            href={task._logurl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Log</strong>
          </a>
        </td>
      )}
    </tr>
  )
}

TaskActivityRow.propTypes = {
  task: TaskShape,
  showLogLink: PropTypes.bool,
}

export default function ReportActivityPanel() {
  const params = useSelector(reportsActivityQuerySelector)
  const {tasks, taskCount, isRequestingTasks} = useSelector(
    reportPageFormSelector,
  )
  const isSuperuser = useSelector(isSuperuserSelector)

  useEffect(() => {
    refreshReportActivity()
  }, [params])

  return (
    <>
      {isRequestingTasks ? (
        <div className="loading align-center margin-top-0 margin-bottom-25">
          <span className="list-processing animate-spin v-align-middle" />
          <strong className="inline-block v-align-middle fs-02 op-75 margin-left-7">
            Loading...
          </strong>
        </div>
      ) : tasks.length === 0 ? (
        <p className="fs-02 lh-md margin-top-10 margin-bottom-30 align-center">
          <strong>No tasks have been run.</strong>
        </p>
      ) : (
        <table className="table fs-00">
          <thead>
            <tr className="fs-n0">
              <th className="table__th w-20">Date Created</th>
              <th className="table__th w-20">Task Type</th>
              <th className="table__th w-15">Email Recipients</th>
              <th className="table__th w-10 align-center">Status</th>
              <th className="table__th w-20">Details</th>
              <th className="table__th w-5">Download</th>
              {isSuperuser && <th className="table__th w-5">&nbsp;</th>}
            </tr>
          </thead>
          <tbody className="table__tbody table__tbody--lines">
            {tasks.map((task) => (
              <TaskActivityRow
                key={task.id}
                task={task}
                showLogLink={isSuperuser}
              />
            ))}
          </tbody>
        </table>
      )}
      <Footer
        isLoading={isRequestingTasks}
        limit={params.limit}
        offset={params.offset}
        count={taskCount}
        setLimit={setLimit}
        setOffset={setOffset}
        preventResetOffset
      />
    </>
  )
}
