import PropTypes from 'prop-types'

import cn from '../../../common/className.js'
import TextInput from '../../../common/components/TextInput.js'
import {labelConfigSelector} from '../../../data/labelInfos/index.js'
import EnableLabelProperty from './EnableLabelProperty.js'
import useLabelProperty, {formatMetaClassName} from './useLabelProperty.js'
import {useSelector} from '../../../store.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

export default function ConfigTextInput({
  className,
  id,
  label,
  Label,
  value,
  hide,
  disabled,
  errorMessage,
  onChange,
  validShipperTypes,
  labelProperty,
  labelPropertiesFunc,
  labelPropertyCheckedSelector,
  dependantPropertiesFunc,
  validLabelProperties,
}) {
  const {labelInfoID, shipperType, isLabelEnableMode} = useLabelConfigContext()

  labelProperty = labelProperty || ''
  id = id || `${labelInfoID}__${labelProperty}`

  const {isLabelPropertyEnabled, ...rest} = useLabelProperty({
    labelProperty,
    labelPropertiesFunc,
    validShipperTypes,
    dependantPropertiesFunc,
    validLabelProperties,
  })

  validShipperTypes = rest.validShipperTypes
  labelPropertiesFunc = rest.labelPropertiesFunc
  dependantPropertiesFunc = rest.dependantPropertiesFunc

  value = useSelector((state) =>
    value === undefined
      ? labelConfigSelector(state, {labelInfoID})[labelProperty] || ''
      : value,
  )

  hide = hide || (!!shipperType && !validShipperTypes.includes(shipperType))

  if (hide) {
    return null
  }

  return (
    <li
      className={cn`list__item--shipping-options btm-offset-7 ${formatMetaClassName(
        labelProperty,
      )} ${className}`}
    >
      <div className="wrap--edit-preset-form-input wrap--edit-preset-form-input-md">
        {Label ? <Label /> : null}
        <TextInput
          labelClassName="label--bold"
          label={label}
          id={id}
          onChange={(newValue) => onChange(newValue)}
          value={value}
          disabled={disabled || (isLabelEnableMode && !isLabelPropertyEnabled)}
          errorMessage={errorMessage}
        />
      </div>
      <EnableLabelProperty
        labelProperty={labelProperty}
        checkedSelector={labelPropertyCheckedSelector}
      />
    </li>
  )
}

ConfigTextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  Label: PropTypes.func,
  value: PropTypes.any,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validShipperTypes: PropTypes.arrayOf(PropTypes.string),
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelPropertiesFunc: PropTypes.func,
  labelPropertyCheckedSelector: PropTypes.func,
  dependantPropertiesFunc: PropTypes.func,
  validLabelProperties: PropTypes.arrayOf(PropTypes.string),
}
