import {combineReducers} from 'redux'

import INITIAL_STATE from '../INITIAL_STATE.js'
import {
  SET_APP_MESSAGES,
  SET_DISMISSED_APP_MESSAGE_IDS,
} from '../../actions/data/commonAppMessage.js'

export function appMessages(
  state = INITIAL_STATE.data.commonAppMessage.appMessages,
  action = {},
) {
  if (action.type === SET_APP_MESSAGES) {
    return action.payload
  }

  return state
}

export function dismissedAppMessageIDs(
  state = INITIAL_STATE.data.commonAppMessage.dismissedAppMessageIDs,
  action = {},
) {
  if (action.type === SET_DISMISSED_APP_MESSAGE_IDS) {
    return action.payload
  }

  return state
}

export default combineReducers({
  appMessages,
  dismissedAppMessageIDs,
})
