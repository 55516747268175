import {useEffect} from 'react'

import {CALL_UP_PAGE} from '../../common/constants/Pages.js'
import {setCurrentPage} from '../../redux/actions/ui/index.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {dispatch, useSelector} from '../../store.js'
import {
  CALL_UP_URI_COMPONENT,
  setupCallUpForm,
  removeCallUpForm,
  callUpQuerySelector,
  applyCallUpContext,
} from './index.js'

export default function CallUpLocationListener() {
  const location = useSelector(locationSelector)
  const callUpQuery = useSelector(callUpQuerySelector)

  const {
    pathComponents: [resource],
  } = location
  const isThisPage = resource === CALL_UP_URI_COMPONENT

  useEffect(() => {
    if (!isThisPage) {
      return
    }

    setupCallUpForm()

    dispatch(setCurrentPage(CALL_UP_PAGE))

    return () => removeCallUpForm()
  }, [isThisPage])

  useEffect(() => {
    if (!callUpQuery.context) {
      return
    }

    applyCallUpContext(callUpQuery.context)
  }, [callUpQuery])

  return null
}
