import PropTypes from 'prop-types'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {
  setOrderStateAndEnsureProductsLoaded,
  canNotifyCartAboutTrackingDeletesSelector,
  orderSelector,
} from '../../../data/orders.js'
import NotifyCartAboutTrackingDeleteCheckbox from '../../../common/components/Order/NotifyCartAboutTrackingDeleteCheckbox.js'
import {Plural, PluralBlock} from '../../../common/components/Plural.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'

export const MODAL_FORM = 'DELETE_TRACKING_MODAL'

export function showDeleteTrackingModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    notifyCart: false,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates, meta) {
  updateForm(MODAL_FORM, updates, meta)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function deleteTracking(orderNumber, notify_cart) {
  const order = orderSelector(getState(), {orderNumber})

  if (!order.shipping_info.created_date) {
    return
  }

  const {json} = await apiverson.delete(
    `/order/${encodeURIComponent(orderNumber)}/shipping_info`,
    {notify_cart},
  )

  await setOrderStateAndEnsureProductsLoaded(json)
}

export async function deleteTrackings() {
  try {
    const {orderNumbers, notifyCart} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const canNotifyCart = canNotifyCartAboutTrackingDeletesSelector(
      getState(),
      {
        orderNumbers,
        labelType: 'shipping',
      },
    )

    await Promise.all(
      orderNumbers.map((orderNumber) =>
        deleteTracking(orderNumber, canNotifyCart && notifyCart),
      ),
    )

    closeModal()

    showMessageToast('Tracking info deleted.')
  } catch (err) {
    updateModalForm({
      serverError: `Error deleting tracking: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function DeleteTrackingModal({form}) {
  return (
    <ConfirmModal
      title="Delete Tracking Info"
      onConfirm={() => deleteTrackings()}
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <PluralBlock array={form.orderNumbers}>
        <p className="fs-01 lh-md margin-bottom-0">
          <strong>
            Are you sure you want to delete the tracking info associated with{' '}
            <Plural s="this order" p="these orders" />?
          </strong>
        </p>
        <NotifyCartAboutTrackingDeleteCheckbox
          orderNumbers={form.orderNumbers}
          labelType="shipping"
          checked={form.notifyCart}
          onChange={() =>
            updateModalForm(
              {notifyCart: !form.notifyCart},
              {stickyProps: ['notifyCart']},
            )
          }
        />
      </PluralBlock>
    </ConfirmModal>
  )
}

DeleteTrackingModal.propTypes = {
  form: PropTypes.shape({
    orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
    notifyCart: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(DeleteTrackingModal, modalFormSelector)
