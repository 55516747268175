import PropTypes from 'prop-types'

import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
} from '../../store.js'
import {
  saveUserSettings,
  updateUserSettings,
  userTabsSelector,
} from '../../data/me.js'
import {
  WELCOME_TAB_ID,
  hasWelcomeTabSelector,
} from '../Header/headerSelectors.js'
import {showMessageToast} from '../Header/Toast/index.js'
import {WELCOME_PAGE} from '../../common/constants/Pages.js'
import {navigate} from '../../common/location.js'
import {DASHBOARD_URI_COMPONENT} from '../DashboardPage/dashboardFunctions.js'
import {currentPageSelector} from '../../redux/selectors/ui/index.js'

const MODAL_FORM = 'WELCOME_TAB_MODAL'

export async function showWelcomeTabModal() {
  const hasWelcomeTab = hasWelcomeTabSelector(getState())

  setForm(MODAL_FORM, {
    hasWelcomeTab,
    serverError: null,
    isSaving: false,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

async function toggleWelcomeTab() {
  try {
    updateModalForm({isSaving: true, serverError: null})

    const currentPage = currentPageSelector(getState())
    const {hasWelcomeTab} = modalFormSelector(getState())
    let tabs = userTabsSelector(getState())
    const tabIndex = tabs.findIndex(({id}) => id === WELCOME_TAB_ID)

    if (hasWelcomeTab) {
      if (tabIndex !== -1) {
        // already exists so update tab
        tabs[tabIndex].hide = true
      } else {
        // otherwise add it
        tabs.push({id: WELCOME_TAB_ID, hide: true})
      }
    } else {
      const tab = tabs[tabIndex]

      if (Object.keys(tab).length === 2 && tab.hide === true) {
        // it was just hiding the tab so remove it
        tabs = tabs.filter(({id}) => id !== WELCOME_TAB_ID)
      } else {
        // otherwise just remove `hide` property
        delete tab.hide
      }
    }

    if (currentPage === WELCOME_PAGE) {
      navigate([DASHBOARD_URI_COMPONENT])
    }

    updateUserSettings({tabs})

    await saveUserSettings()

    closeModal()

    showMessageToast(
      `Welcome tab is now ${hasWelcomeTab ? 'hidden.' : 'shown.'}`,
    )
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function WelcomeTabModal({form}) {
  return (
    <ConfirmModal
      title={
        form.hasWelcomeTab ? 'Hide the Welcome Tab' : 'Show the Welcome Tab'
      }
      modalSize="sm"
      onConfirm={toggleWelcomeTab}
      onCancel={closeModal}
      confirmText={form.hasWelcomeTab ? 'Hide' : 'Show'}
      cancelText="Cancel"
      error={form.serverError}
      isSaving={form.isSaving}
    >
      {form.hasWelcomeTab ? (
        <>
          <p className="fs-01 lh-md margin-bottom-20">
            Are you sure you want to hide the Welcome tab from the main
            navigation?
          </p>
          <p className="fs-00 lh-md margin-bottom-5">
            <em>
              It can be toggled back on in{' '}
              <a
                className="btn--link darkest no-padding border-underline"
                target="_blank"
                rel="noopener noreferrer"
                href="#/settings/general"
              >
                General Settings
              </a>
              .
            </em>
          </p>
        </>
      ) : (
        <p className="fs-01 lh-md margin-bottom-20">
          Are you sure you want to show the Welcome tab in the main navigation?
        </p>
      )}
    </ConfirmModal>
  )
}

WelcomeTabModal.propTypes = {
  form: PropTypes.shape({
    hasWelcomeTab: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(WelcomeTabModal, modalFormSelector)
