import {useEffect} from 'react'
import analytics from '../../common/analytics/index.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {INVENTORY_APP_PANEL, showUpgradeModal} from '../Modals/UpgradeModal.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import {usesAppsSelector} from '../../data/company.js'
import {useSelector} from '../../store.js'
import {INVENTORY_APP_PREMIUM} from '../../common/constants/Apps.js'

export default function POFeaturePage() {
  const usesApps = useSelector(usesAppsSelector)
  useEffect(() => {
    analytics.trackEvent('po_feature_lock')
  }, [])

  return (
    <>
      <div className="row margin-top-30">
        <div className="medium-9 medium-centered columns align-center margin-top-30">
          <h3 className="fs-03 lh-md margin-top-5 margin-bottom-5">
            <strong>Reorder Stock and Manage Suppliers With Ease</strong>
          </h3>
          <p className="fs-02 lh-md">
            Utilize low inventory thresholds to avoid stockouts, set-up
            suppliers in bulk, and analyze inventory costs — all in one place!
          </p>
        </div>
      </div>
      <div className="row margin-top-30 flex">
        <div className="medium-7 columns">
          <img
            className="screenshot"
            src="https://storage.googleapis.com/ordoro-pappy-assets/public/pos-feature-lock.png"
            alt="Purchase Orders Demo"
          />
        </div>
        <div className="medium-5 columns flex--justify-col">
          <div className="margin-bottom-15">
            {usesApps ? (
              <p className="fs-01 lh-lg margin-bottom-25">
                To gain access to{' '}
                <a
                  className="btn btn--link darker border-underline v-align-base"
                  href="https://support.ordoro.com/how-to-create-and-manage-purchase-orders/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Purchase Orders</span>
                </a>
                , you need to be on the{' '}
                <strong>Premium Plan of our Inventory App</strong>.
              </p>
            ) : (
              <p className="fs-01 lh-lg margin-bottom-25">
                Upgrade your account with our{' '}
                <strong>Inventory Management Module</strong> to gain access to{' '}
                <a
                  className="btn btn--link darker border-underline v-align-base"
                  href="https://support.ordoro.com/how-to-create-and-manage-purchase-orders/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Purchase Orders</span>
                </a>{' '}
                and other restocking features.
              </p>
            )}

            <div>
              {usesApps ? (
                <ButtonPrimary
                  size="x-md"
                  className="meta--feature-lock-bom"
                  alt
                  onClick={() =>
                    showUpgradeModal({
                      panel: INVENTORY_APP_PANEL,
                      selectedInventoryApp: INVENTORY_APP_PREMIUM,
                    })
                  }
                >
                  Enable Inventory (Premium)
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  size="x-md"
                  className="meta--feature-lock-bom"
                  alt
                  onClick={() => showFeatureLockedModal('inventory')}
                >
                  Get the Inventory Management Module
                </ButtonPrimary>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
