import PropTypes from 'prop-types'

export default function TextInput({
  errorMessage,
  label,
  type = 'text',
  name = 'unnamed_text_input',
  id = `id_${name}`,
  required,
  className = '',
  tall,
  width = 100,
  ...props
}) {
  tall = tall === false ? false : true

  return (
    <>
      {label && (
        <label className="fs-00 margin-bottom-3" htmlFor={id}>
          <span>{label}</span>
          {required && <span className="required">*</span>}
        </label>
      )}
      <input
        type={type}
        className={`${tall ? 'input--tall' : ''} w-${width} ${className}`}
        id={id}
        name={name}
        {...props}
      />
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}

TextInput.propTypes = {
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  tall: PropTypes.bool,
  width: PropTypes.number,
}
