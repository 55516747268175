import {updateLabelConfig} from '../../../../data/labelInfos/index.js'
import {FEDEX} from '../../../../common/constants/ShipperNames.js'
import ConfigTextInput from '../ConfigTextInput.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

const LABEL_PROPERTY = 'delivery_instructions'
const VALID_SHIPPER_TYPES = [FEDEX]

export default function DeliveryInstructions() {
  const {labelInfoID, onChange} = useLabelConfigContext()

  return (
    <ConfigTextInput
      label="Delivery Instructions"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {[LABEL_PROPERTY]: value})

        onChange()
      }}
      labelProperty={LABEL_PROPERTY}
      validShipperTypes={VALID_SHIPPER_TYPES}
    />
  )
}
