import api from '../../../../../common/api.js'
import productArchiveStrategy from './productArchiveStrategy.js'

export default class productUnarchiveStrategy extends productArchiveStrategy {
  static title = 'Unarchive Products'

  static getExampleRows() {
    return () =>
      api.get('/product/', {limit: 5, active: false}).then(({json: data}) => {
        const rows = [['SKU *Req']]

        data.product.forEach((product) => {
          rows.push([product.sku])
        })

        return rows
      })
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const url = `/product/${sku}/unarchive/`

      return api.post(url, {})
    }
  }
}
