import {all, takeEvery, put, call, select} from 'redux-saga/effects'

import {PITNEY_PRESORT} from '../../../../../../common/constants/ShipperNames.js'
import {NEW_ID} from '../../../../../../common/constants/index.js'
import api from '../../../../../../common/api.js'
import {setGlobalError} from '../../../../../actions/ui/index.js'
import {setShipper} from '../../../../../../data/shippers.js'
import {
  updateForm,
  goToShipperSettings,
} from '../../../../../actions/ui/settings/shippers/index.js'
import {shipperFormSelector} from '../../../../../selectors/ui/settings/shippers/index.js'
import {SAVE_SHIPPER_PITNEY_PRESORT} from '../../../../../actions/ui/settings/shippers/forms/pitneyPresort.js'

export function* saveShipperPitneyPresortWorker({payload: {shipperID}}) {
  const {
    name,
    hidden_shipping_methods,
    pb_shipment_group_id,
    pb_integrator_carrier_id,
  } = yield select(shipperFormSelector, {
    shipperID,
  })

  const params = {
    name: shipperID === NEW_ID ? undefined : name,
    hidden_shipping_methods:
      shipperID === NEW_ID ? undefined : hidden_shipping_methods,
    pb_shipment_group_id,
    pb_integrator_carrier_id,
  }

  yield put(updateForm(shipperID, {isSaving: true}))

  try {
    const {json} =
      shipperID !== NEW_ID
        ? yield call(api.put, `/shipper/${shipperID}/`, params)
        : yield call(api.post, '/shipper/', {...params, vendor: PITNEY_PRESORT})

    yield put(goToShipperSettings())

    yield call(setShipper, json)
  } catch (err) {
    yield put(
      setGlobalError(
        {
          summary: 'There was an error while saving shipper.',
        },
        err,
      ),
    )

    yield put(updateForm(shipperID, {isSaving: false}))
  }
}

export default function* pitneyPresortSaga() {
  yield all([
    takeEvery(SAVE_SHIPPER_PITNEY_PRESORT, saveShipperPitneyPresortWorker),
  ])
}
