import PropTypes from 'prop-types'
import cn from '../../className.js'

export default function Link({className, children, newWindow, ...props}) {
  if (newWindow) {
    props.target = '_blank'
    props.rel = 'noopener noreferrer'
  }

  return (
    <a className={cn`btn--link ${className}`} {...props}>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  newWindow: PropTypes.bool,
}
