import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {NO_WAREHOUSE_SELECTED} from '../../../../common/constants/CreateInventoryTransferModal.js'
import formConnect from '../../../../common/formConnect.js'
import ProductFilter from '../../../../common/components/ProductFilter.js'
import {navigate} from '../../../../redux/actions/ui/index.js'
import {
  createInventoryTransfer,
  closeCreateInventoryTransferModal,
  updateCreateInventoryTransferModalForm,
} from './createInventoryTransferModalActions.js'
import {nonFBAWarehouseOptionsSelector} from '../../../../data/warehouses.js'
import {
  createInventoryTransferModalFormSelector,
  hasErrorsSelector,
  warehouseSelectionIsValidSelector,
} from './createInventoryTransferModalSelectors.js'

import ConfirmModal from '../../../../common/components/Modal/ConfirmModal.js'
import {SelectOptionsShape} from '../../../../common/PropTypes.js'

import ProductRow from './ProductRow.js'

function CreateInventoryTransferModal({
  form,
  hasErrors,
  warehouseOptions,
  warehouseSelectionIsValid,
  ...props
}) {
  return (
    <ConfirmModal
      title={`Transfer Inventory`}
      modalSize="x-lg"
      isSaving={form.isSaving}
      isDisabled={hasErrors}
      confirmText="Transfer"
      cancelText="Cancel"
      onConfirm={() => props.createInventoryTransfer()}
      onCancel={() => props.closeCreateInventoryTransferModal()}
      error={form.serverError}
    >
      <>
        <p className="fs-01 margin-bottom-20">
          <strong>
            Select the warehouses that will be involved in the transfer
          </strong>
        </p>
        <div className="flex margin-bottom-25">
          <div>
            <label className="fs-00" htmlFor="id_from_warehouse">
              Sending
            </label>
            <div className="flex">
              <select
                className="select select--tall"
                name="from_warehouse"
                id="id_from_warehouse"
                value={form.sendingWarehouseID}
                onChange={(event) => {
                  const sendingWarehouseID = Number(event.target.value)

                  if (sendingWarehouseID === form.receivingWarehouseID) {
                    props.updateCreateInventoryTransferModalForm({
                      receivingWarehouseID: form.sendingWarehouseID,
                      sendingWarehouseID,
                    })
                  } else {
                    props.updateCreateInventoryTransferModalForm({
                      sendingWarehouseID,
                    })
                  }
                }}
              >
                <option
                  className="placeholder"
                  disabled
                  value={NO_WAREHOUSE_SELECTED}
                >
                  Select sending warehouse...
                </option>
                {warehouseOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </select>
              <div className="flex--justify-col">
                <strong className="fs-04 lh-sm op-50 margin-left-10 margin-right-10">
                  ⤏
                </strong>
              </div>
            </div>
          </div>
          <div className="margin-right-20">
            <label className="fs-00" htmlFor="id_to_warehouse">
              Receiving
            </label>
            <select
              className="select select--tall"
              name="to_warehouse"
              id="id_to_warehouse"
              value={form.receivingWarehouseID}
              onChange={(event) => {
                const receivingWarehouseID = Number(event.target.value)

                if (receivingWarehouseID === form.sendingWarehouseID) {
                  props.updateCreateInventoryTransferModalForm({
                    sendingWarehouseID: form.receivingWarehouseID,
                    receivingWarehouseID,
                  })
                } else {
                  props.updateCreateInventoryTransferModalForm({
                    receivingWarehouseID,
                  })
                }
              }}
            >
              <option
                className="placeholder"
                disabled
                value={NO_WAREHOUSE_SELECTED}
              >
                Select receiving warehouse...
              </option>
              {warehouseOptions.map(({value, display}) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
            </select>
          </div>
          {warehouseSelectionIsValid && (
            <div className="w-40 divider--left margin-left-0 padding-left-20">
              <label className="fs-00" htmlFor="id_to_warehouse">
                Transfer Notes
              </label>
              <textarea
                className="textarea margin-bottom-10"
                value={form.transfer_notes}
                onChange={(event) =>
                  props.updateCreateInventoryTransferModalForm({
                    transfer_notes: event.target.value,
                  })
                }
              />
            </div>
          )}
        </div>
      </>
      {warehouseSelectionIsValid && (
        <>
          <hr />

          <div>
            <p className="fs-01 margin-bottom-20">
              <strong>Select the products to be transferred</strong>
            </p>
            <table className="table margin-top-10 margin-bottom-25">
              <thead>
                <tr>
                  <th
                    rowSpan="2"
                    className="table__th table__th--sm th-width-15"
                  >
                    Product/SKU
                  </th>
                  <th
                    colSpan="2"
                    className="table__th table__th--top-border table__th--sm border-left--dark align-center"
                  >
                    Sending Warehouse
                  </th>
                  <th
                    rowSpan="2"
                    className="table__th table__th--sm border-left--dark align-center"
                  >
                    Transfer Quantity
                  </th>
                  <th
                    colSpan="2"
                    className="table__th table__th--top-border table__th--sm border-left--dark align-center"
                  >
                    Receiving Warehouse
                  </th>
                  <th
                    rowSpan="2"
                    className="table__th table__th--sm border-left--dark"
                  >
                    Line Notes
                  </th>
                  <th rowSpan="2" className="table__th table__th--sm" />
                </tr>
                <tr>
                  <th className="table__th table__th--sm border-left--dark align-center">
                    Committed
                  </th>
                  <th className="table__th table__th--sm border-left--dark align-center">
                    Physical On Hand
                  </th>
                  <th className="table__th table__th--sm border-left--dark align-center">
                    Committed
                  </th>
                  <th className="table__th table__th--sm border-left--dark align-center">
                    Physical On Hand
                  </th>
                </tr>
              </thead>
              <tbody className="table__tbody--lines">
                {form.skus.map((sku, index) => (
                  <ProductRow
                    key={sku}
                    sku={sku}
                    autoFocus={
                      form.afterAddingProduct
                        ? form.skus.length - 1 === index
                        : index === 0
                    }
                  />
                ))}
                <tr>
                  <td className="table__td padding-left-0" colSpan="2">
                    <ProductFilter
                      label="Add a product to be transferred"
                      dropdown="INVENTORY_TRANSFER_PRODUCT_FILTER"
                      onSelect={({sku}) => {
                        const willAdd = !form.skus.includes(sku)

                        props.updateCreateInventoryTransferModalForm({
                          skus: willAdd ? [...form.skus, sku] : form.skus,
                          afterAddingProduct: willAdd,
                        })
                      }}
                    />
                  </td>
                  <td className="table__td" colSpan="8" />
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </ConfirmModal>
  )
}

CreateInventoryTransferModal.propTypes = {
  form: PropTypes.shape({
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    sendingWarehouseID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    receivingWarehouseID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    transfer_notes: PropTypes.string,
    serverError: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    afterAddingProduct: PropTypes.bool.isRequired,
  }),
  hasErrors: PropTypes.bool.isRequired,
  warehouseSelectionIsValid: PropTypes.bool.isRequired,
  warehouseOptions: SelectOptionsShape.isRequired,
  closeCreateInventoryTransferModal: PropTypes.func.isRequired,
  updateCreateInventoryTransferModalForm: PropTypes.func.isRequired,
  createInventoryTransfer: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    hasErrors: hasErrorsSelector(state),
    needsWarehouses: nonFBAWarehouseOptionsSelector(state).length < 2,
    warehouseOptions: nonFBAWarehouseOptionsSelector(state),
    warehouseSelectionIsValid: warehouseSelectionIsValidSelector(state),
  }
}

const mapDispatchToProps = {
  closeCreateInventoryTransferModal,
  createInventoryTransfer,
  navigate,
  updateCreateInventoryTransferModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(CreateInventoryTransferModal),
  createInventoryTransferModalFormSelector,
)
