import PropTypes from 'prop-types'
import {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'
import get from 'lodash/get.js'

import copyTextToClipboard from '../../../common/copyTextToClipboard.js'
import {APIKeyShape} from '../../../common/PropTypes.js'
import {NEW_ID} from '../../../common/constants/index.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  setAPIKeyForm,
  updateAPIKeyForm,
  removeAPIKeyForm,
  saveAPIKey,
  archiveAPIKey,
} from '../../../redux/actions/ui/settings/apiKeys.js'
import {
  apiKeyEditFormSelector,
  apiKeyEditFormErrors,
} from '../../../redux/selectors/ui/settings/apiKeys.js'

export class Form extends Component {
  static propTypes = {
    apiKeyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    apiKey: APIKeyShape,
    form: PropTypes.shape({
      name: PropTypes.string.isRequired,
      isSaving: PropTypes.bool.isRequired,
      serverError: PropTypes.string,
      showArchiveConfirm: PropTypes.bool.isRequired,
      isArchiving: PropTypes.bool.isRequired,
      archiveError: PropTypes.string,
    }).isRequired,
    errors: PropTypes.objectOf(PropTypes.string).isRequired,
    hasErrors: PropTypes.bool.isRequired,
    editID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isNew: PropTypes.bool.isRequired,
    setAPIKeyForm: PropTypes.func.isRequired,
    updateAPIKeyForm: PropTypes.func.isRequired,
    removeAPIKeyForm: PropTypes.func.isRequired,
    saveAPIKey: PropTypes.func.isRequired,
    archiveAPIKey: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.setAPIKeyForm(this.props.apiKeyID, {
      name: get(this.props.apiKey, 'name', 'API Key'),
      isSaving: false,
      serverError: null,
      showArchiveConfirm: false,
      isArchiving: false,
      archiveError: null,
    })
  }

  componentWillUnmount() {
    this.props.removeAPIKeyForm(this.props.apiKeyID)
  }

  render() {
    const {apiKeyID, apiKey, form, errors, hasErrors, isNew, ...props} =
      this.props

    return (
      <form
        className="settings-list-item-form-wrap padding-bottom-10 clear-both"
        onSubmit={(event) => {
          event.preventDefault()

          props.saveAPIKey(apiKeyID)
        }}
      >
        <fieldset>
          {form.serverError && (
            <div className="row">
              <ul className="medium-8 columns error-list">
                <li>{form.serverError}</li>
              </ul>
            </div>
          )}
          <ul className="form-list medium-8 columns margin-left-0">
            <li
              className={classNames('form-list-item bigger margin-bottom-20', {
                error: errors.name,
              })}
            >
              <label htmlFor="name">
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={(event) =>
                  props.updateAPIKeyForm(apiKeyID, {name: event.target.value})
                }
              />
              {errors.name && (
                <small className="error error-message">{errors.name}</small>
              )}
            </li>
            {!isNew && (
              <>
                <li className="form-list-item bigger margin-bottom-20">
                  <label htmlFor="user_name">Client ID</label>
                  <div className="inline-block lh-sm">{apiKey.client_id}</div>
                  <button
                    type="button"
                    className="btn btn--primary btn--primary-ol fs-n2 btn--x-sm margin-left-5"
                    onClick={() => {
                      copyTextToClipboard(apiKey.client_id)

                      showMessageToast('Client ID copied to clipboard')
                    }}
                  >
                    Copy
                  </button>
                </li>
                <li className="form-list-item bigger margin-bottom-25">
                  <label htmlFor="user_name">Client Secret</label>
                  <div className="inline-block lh-sm">
                    {apiKey.client_secret}
                  </div>
                  <button
                    type="button"
                    className="btn btn--primary btn--primary-ol fs-n2 btn--x-sm margin-left-5"
                    onClick={() => {
                      copyTextToClipboard(apiKey.client_secret)

                      showMessageToast('Client Secret copied to clipboard')
                    }}
                  >
                    Copy
                  </button>
                </li>
                <li className="form-list-item bigger margin-bottom-20" />
              </>
            )}
            <li className="list__item list__item--no-style">
              <button
                type="submit"
                className={classNames('btn btn--primary', {
                  'btn--disabled': hasErrors,
                  'btn--processing': form.isSaving,
                })}
                disabled={hasErrors || form.isSaving}
              >
                {isNew ? 'Create New API Key' : 'Save'}
              </button>
              {!isNew && (
                <button
                  type="button"
                  className="btn btn--primary btn--primary-ol margin-left-15"
                  onClick={() =>
                    props.updateAPIKeyForm(apiKeyID, {showArchiveConfirm: true})
                  }
                >
                  Delete
                </button>
              )}
              {form.showArchiveConfirm && (
                <ConfirmModal
                  title="Delete API Key"
                  isSaving={form.isArchiving}
                  error={form.archiveError}
                  onConfirm={() => props.archiveAPIKey(apiKeyID)}
                  onCancel={() =>
                    props.updateAPIKeyForm(apiKeyID, {
                      showArchiveConfirm: false,
                    })
                  }
                >
                  Do you want to delete this API Key?
                </ConfirmModal>
              )}
              <a
                href="#/settings/api_key"
                className="btn btn--secondary"
                type="button"
              >
                Cancel
              </a>
            </li>
          </ul>
        </fieldset>
      </form>
    )
  }
}

function mapStateToProps(state, {apiKeyID}) {
  const errors = apiKeyEditFormErrors(state, {apiKeyID})

  return {
    form: apiKeyEditFormSelector(state, {apiKeyID}),
    errors,
    hasErrors: Object.keys(errors).length > 0,
    isNew: apiKeyID === NEW_ID,
  }
}

const mapDispatchToProps = {
  setAPIKeyForm,
  updateAPIKeyForm,
  removeAPIKeyForm,
  saveAPIKey,
  archiveAPIKey,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
