import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import {isLoadingAnalyticsSelector} from './analyticsSelectors.js'
import {topCustomersSelector} from './customerSelectors.js'
import Panel from './Panel.js'
import Header from './Header.js'
import Filters from './Filters.js'
import CartFilter from './CartFilter.js'
import DateFilter from './DateFilter.js'
import CartActiveFilters from './CartActiveFilters.js'
import TablePane from './TablePane.js'
import {PRIVACY_MASK} from '../../common/analytics/index.js'

export function CustomerPanel({topCustomers, isLoading}) {
  return (
    <Panel>
      <Header>Top Customers</Header>
      <Filters>
        <CartFilter />
        <DateFilter />
      </Filters>
      <CartActiveFilters />
      <div className="row margin-top-25">
        <div className="medium-12 columns">
          <TablePane title="Top Customers" isLoading={isLoading} hasFilters>
            {!isLoading && topCustomers && topCustomers.buckets.length > 0 && (
              <>
                <thead>
                  <tr>
                    <th className="th-width-1 super-small-screen-hide">
                      <span className="column-name first">&nbsp;</span>
                    </th>
                    <th className="th-width-20">
                      <span className="column-name">Customer Name</span>
                    </th>
                    <th className="th-width-25">
                      <span className="column-name">Email</span>
                    </th>
                    <th className="th-width-15">
                      <span className="column-name">Total Spent</span>
                    </th>
                    <th className="th-width-15">
                      <span className="column-name">Orders</span>
                    </th>
                    <th className="th-width-15">
                      <span className="column-name">SKUs Ordered</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topCustomers.buckets.map((bucket, index) => (
                    <tr key={index}>
                      <td className="super-small-screen-hide">
                        <span className="table__rank">{`${index + 1}.`}</span>
                      </td>
                      <td>
                        <strong className={`word-breaker ${PRIVACY_MASK}`}>
                          {bucket.name}
                        </strong>
                      </td>
                      <td>
                        <span className={`word-breaker ${PRIVACY_MASK}`}>
                          {bucket.email}
                        </span>
                      </td>
                      <td>
                        <Currency value={bucket.total_spent} />
                      </td>
                      <td>
                        <Quantity value={bucket.order_count} />
                      </td>
                      <td>
                        <Quantity value={bucket.sku_count} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </TablePane>
        </div>
      </div>
    </Panel>
  )
}

CustomerPanel.propTypes = {
  topCustomers: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        total_spent: PropTypes.number.isRequired,
        order_count: PropTypes.number.isRequired,
        sku_count: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
}

function mapStateToProp(state) {
  return {
    topCustomers: topCustomersSelector(state),
    isLoading: isLoadingAnalyticsSelector(state),
  }
}

export default connect(mapStateToProp)(CustomerPanel)
