import round from 'lodash/round.js'

export const stripeCurrenciesOptions = [
  {value: 'usd', display: 'USD', symbol: '$'},
  {value: 'aed', display: 'AED'},
  {value: 'afn', display: 'AFN'},
  {value: 'all', display: 'ALL'},
  {value: 'amd', display: 'AMD'},
  {value: 'ang', display: 'ANG'},
  {value: 'aoa', display: 'AOA'},
  {value: 'ars', display: 'ARS'},
  {value: 'aud', display: 'AUD'},
  {value: 'awg', display: 'AWG'},
  {value: 'azn', display: 'AZN'},
  {value: 'bam', display: 'BAM'},
  {value: 'bbd', display: 'BBD'},
  {value: 'bdt', display: 'BDT'},
  {value: 'bgn', display: 'BGN'},
  {value: 'bif', display: 'BIF', zeroValue: true},
  {value: 'bmd', display: 'BMD'},
  {value: 'bnd', display: 'BND'},
  {value: 'bob', display: 'BOB'},
  {value: 'brl', display: 'BRL'},
  {value: 'bsd', display: 'BSD'},
  {value: 'bwp', display: 'BWP'},
  {value: 'bzd', display: 'BZD'},
  {value: 'cad', display: 'CAD', symbol: '$'},
  {value: 'cdf', display: 'CDF'},
  {value: 'chf', display: 'CHF'},
  {value: 'clp', display: 'CLP', zeroValue: true},
  {value: 'cny', display: 'CNY', symbol: '¥'},
  {value: 'cop', display: 'COP'},
  {value: 'crc', display: 'CRC'},
  {value: 'cve', display: 'CVE'},
  {value: 'czk', display: 'CZK'},
  {value: 'djf', display: 'DJF', zeroValue: true},
  {value: 'dkk', display: 'DKK'},
  {value: 'dop', display: 'DOP'},
  {value: 'dzd', display: 'DZD'},
  {value: 'egp', display: 'EGP'},
  {value: 'etb', display: 'ETB'},
  {value: 'eur', display: 'EUR', symbol: '€'},
  {value: 'fjd', display: 'FJD'},
  {value: 'fkp', display: 'FKP'},
  {value: 'gbp', display: 'GBP', symbol: '£'},
  {value: 'gel', display: 'GEL'},
  {value: 'gip', display: 'GIP'},
  {value: 'gmd', display: 'GMD'},
  {value: 'gnf', display: 'GNF', zeroValue: true},
  {value: 'gtq', display: 'GTQ'},
  {value: 'gyd', display: 'GYD'},
  {value: 'hkd', display: 'HKD'},
  {value: 'hnl', display: 'HNL'},
  {value: 'hrk', display: 'HRK'},
  {value: 'htg', display: 'HTG'},
  {value: 'huf', display: 'HUF'},
  {value: 'idr', display: 'IDR'},
  {value: 'ils', display: 'ILS'},
  {value: 'inr', display: 'INR'},
  {value: 'isk', display: 'ISK'},
  {value: 'jmd', display: 'JMD'},
  {value: 'jpy', display: 'JPY', symbol: '¥', zeroValue: true},
  {value: 'kes', display: 'KES'},
  {value: 'kgs', display: 'KGS'},
  {value: 'khr', display: 'KHR'},
  {value: 'kmf', display: 'KMF', zeroValue: true},
  {value: 'krw', display: 'KRW', symbol: '₩', zeroValue: true},
  {value: 'kyd', display: 'KYD'},
  {value: 'kzt', display: 'KZT'},
  {value: 'lak', display: 'LAK'},
  {value: 'lbp', display: 'LBP'},
  {value: 'lkr', display: 'LKR'},
  {value: 'lrd', display: 'LRD'},
  {value: 'lsl', display: 'LSL'},
  {value: 'mad', display: 'MAD'},
  {value: 'mdl', display: 'MDL'},
  {value: 'mga', display: 'MGA', zeroValue: true},
  {value: 'mkd', display: 'MKD'},
  {value: 'mmk', display: 'MMK'},
  {value: 'mnt', display: 'MNT'},
  {value: 'mop', display: 'MOP'},
  {value: 'mro', display: 'MRO'},
  {value: 'mur', display: 'MUR'},
  {value: 'mvr', display: 'MVR'},
  {value: 'mwk', display: 'MWK'},
  {value: 'mxn', display: 'MXN', symbol: '$'},
  {value: 'myr', display: 'MYR'},
  {value: 'mzn', display: 'MZN'},
  {value: 'nad', display: 'NAD'},
  {value: 'ngn', display: 'NGN'},
  {value: 'nio', display: 'NIO'},
  {value: 'nok', display: 'NOK'},
  {value: 'npr', display: 'NPR'},
  {value: 'nzd', display: 'NZD'},
  {value: 'pab', display: 'PAB'},
  {value: 'pen', display: 'PEN'},
  {value: 'pgk', display: 'PGK'},
  {value: 'php', display: 'PHP'},
  {value: 'pkr', display: 'PKR'},
  {value: 'pln', display: 'PLN'},
  {value: 'pyg', display: 'PYG', zeroValue: true},
  {value: 'qar', display: 'QAR'},
  {value: 'ron', display: 'RON'},
  {value: 'rsd', display: 'RSD'},
  {value: 'rub', display: 'RUB'},
  {value: 'rwf', display: 'RWF', zeroValue: true},
  {value: 'sar', display: 'SAR'},
  {value: 'sbd', display: 'SBD'},
  {value: 'scr', display: 'SCR'},
  {value: 'sek', display: 'SEK'},
  {value: 'sgd', display: 'SGD'},
  {value: 'shp', display: 'SHP'},
  {value: 'sll', display: 'SLL'},
  {value: 'sos', display: 'SOS'},
  {value: 'srd', display: 'SRD'},
  {value: 'std', display: 'STD'},
  {value: 'svc', display: 'SVC'},
  {value: 'szl', display: 'SZL'},
  {value: 'thb', display: 'THB'},
  {value: 'tjs', display: 'TJS'},
  {value: 'top', display: 'TOP'},
  {value: 'try', display: 'TRY'},
  {value: 'ttd', display: 'TTD'},
  {value: 'twd', display: 'TWD', symbol: '$'},
  {value: 'tzs', display: 'TZS'},
  {value: 'uah', display: 'UAH'},
  {value: 'ugx', display: 'UGX'},
  {value: 'uyu', display: 'UYU'},
  {value: 'uzs', display: 'UZS'},
  {value: 'vnd', display: 'VND', zeroValue: true},
  {value: 'vuv', display: 'VUV', zeroValue: true},
  {value: 'wst', display: 'WST'},
  {value: 'xaf', display: 'XAF', zeroValue: true},
  {value: 'xcd', display: 'XCD'},
  {value: 'xof', display: 'XOF', zeroValue: true},
  {value: 'xpf', display: 'XPF', zeroValue: true},
  {value: 'yer', display: 'YER'},
  {value: 'zar', display: 'ZAR'},
  {value: 'zmw', display: 'ZMW'},
]

export function formatAmount(amount, currency) {
  const item =
    stripeCurrenciesOptions.find(({value}) => value === currency) || {}

  const symbol = item.symbol || `${currency} `
  const multiplier = item.zeroValue ? 1 : 100

  return `${symbol}${round(amount / multiplier, 2)}`
}

export function parseAmount(amount, currency) {
  const item =
    stripeCurrenciesOptions.find(({value}) => value === currency) || {}

  const multiplier = item.zeroValue ? 1 : 100

  return Math.floor(Number(amount) * multiplier)
}
