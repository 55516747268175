import {createSelector} from 'reselect'
import get from 'lodash/get.js'

import {
  PO_DETAIL_ITEMS_PANEL,
  PO_DETAIL_PANELS,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
} from '../../common/constants/PurchaseOrders.js'
import {
  EXPAND_MODE_COLLAPSED,
  EXPAND_MODE_EXPANDED,
} from '../../common/components/List/ExpandAllButton.js'
import {
  itemsSelector,
  activeGoodsReceiptsSelector,
  canUsePurchaseOrdersSelector,
} from '../../data/pos.js'
import {formsSelector} from '../../redux/selectors/ui/forms.js'
import {poListQuerySelector} from '../PurchaseOrderListPage/purchaseOrderListSelectors.js'
import {DETAIL_MODE} from '../../common/constants/index.js'

export const PO_DETAIL_FORM = 'PO_DETAIL'

export const poDetailFormSelector = createSelector(
  formsSelector,
  (forms) => forms[PO_DETAIL_FORM],
)

export function selectedItemIDsSelector(state) {
  return get(poDetailFormSelector(state), 'selectedItemIDs', [])
}

export function expandedGoodsReceiptIDsSelector(state) {
  return get(poDetailFormSelector(state), 'expandedGoodsReceiptIDs', [])
}

export function goodsReceiptsExpandModeSelector(state) {
  return get(
    poDetailFormSelector(state),
    'goodsReceiptsExpandMode',
    EXPAND_MODE_EXPANDED,
  )
}

export const poItemsSelector = createSelector(
  (state) => {
    const poID = poIDSelector(state)

    return itemsSelector(state, {poID})
  },
  (poItems) => poItems,
)

export const poItemIDsSelector = createSelector(poItemsSelector, (poItems) =>
  poItems.map(({id}) => id),
)

export const poGoodsReceiptsSelector = createSelector(
  (state) => {
    const poID = poIDSelector(state)

    return activeGoodsReceiptsSelector(state, {poID})
  },
  (poGoodsReceipts) => poGoodsReceipts,
)

export const poGoodsReceiptIDsSelector = createSelector(
  poGoodsReceiptsSelector,
  (poGoodsReceipts) => poGoodsReceipts.map(({id}) => id),
)

export function poDetailURLSelector(state, {poID}) {
  return `#/${PURCHASE_ORDER_SINGLE_URI_COMPONENT}/${encodeURIComponent(poID)}`
}

export const poIDSelector = createSelector(poListQuerySelector, (query) => {
  if (query.mode !== DETAIL_MODE) {
    return null
  }

  return query.po_id
})

export const currentPanelSelector = createSelector(
  poListQuerySelector,
  (query) => {
    if (query.mode !== DETAIL_MODE) {
      return null
    }

    return PO_DETAIL_PANELS.includes(query.panel)
      ? query.panel
      : PO_DETAIL_ITEMS_PANEL
  },
)

export const purchaseOrderDetailHashSelector = createSelector(
  poIDSelector,
  currentPanelSelector,
  canUsePurchaseOrdersSelector,
  (poID, currentPanel, canUsePurchaseOrders) => {
    if (!canUsePurchaseOrders || !poID) {
      return null
    }

    const hash = `#/${PURCHASE_ORDER_SINGLE_URI_COMPONENT}/${encodeURIComponent(
      poID,
    )}`

    if (currentPanel && currentPanel !== PO_DETAIL_ITEMS_PANEL) {
      return `${hash}/${currentPanel}`
    }

    return hash
  },
)

export const areAllItemsSelectedSelector = createSelector(
  poItemsSelector,
  selectedItemIDsSelector,
  (poItems, selected) =>
    poItems.length > 0 && poItems.length === selected.length,
)

export const areIndeterminateItemsSelectedSelector = createSelector(
  areAllItemsSelectedSelector,
  selectedItemIDsSelector,
  (allSelected, selected) => !allSelected && selected.length > 0,
)

export const createIsGoodsReceiptExpandedSelector = (goodsReceiptID) =>
  createSelector(
    expandedGoodsReceiptIDsSelector,
    goodsReceiptsExpandModeSelector,
    (expandedReferenceIDs, expandMode) => {
      if (expandMode === EXPAND_MODE_COLLAPSED) {
        return expandedReferenceIDs.includes(goodsReceiptID)
      }
      if (expandMode === EXPAND_MODE_EXPANDED) {
        return !expandedReferenceIDs.includes(goodsReceiptID)
      }
      return false
    },
  )
