import PropTypes from 'prop-types'
import get from 'lodash/get.js'

import {
  formSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {refreshOrderListAndCounts} from '../orderListActions.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import OrderNumberOrCount from '../../../common/components/OrderNumberOrCount.js'
import {
  labelInfosSelector,
  updateLabelConfig,
  labelConfigSelector,
  dimensionsSelector,
  dimensionHasError,
} from '../../../data/labelInfos/index.js'
import DimensionsInput from '../../../common/components/Form/DimensionsInput.js'
import {getBulkRates} from '../../LabelConfig/BulkLabelConfigForm/bulkLabelConfigActions.js'
import {getRates} from '../../../data/labelInfos/rateRequest.js'

export const MODAL_FORM = 'EDIT_ORDER_DIMENSIONS_MODAL'

export async function showEditOrderDimensionsModal(labelInfoIDs) {
  const dimensions = dimensionsSelector(getState(), {
    labelInfoID: labelInfoIDs[0],
    packagesIndex: 0,
  })

  setForm(MODAL_FORM, {
    labelInfoIDs,
    length: dimensions.length,
    width: dimensions.width,
    height: dimensions.height,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formSelector(state, {formName: MODAL_FORM})
}

export function errorsSelector(state) {
  const {length, width, height} = modalFormSelector(state)

  const errors = {}

  if (dimensionHasError(length)) {
    errors.length = 'Invalid dimension'
    errors.preventSave = true
  }

  if (dimensionHasError(width)) {
    errors.width = 'Invalid dimension'
    errors.preventSave = true
  }

  if (dimensionHasError(height)) {
    errors.height = 'Invalid dimension'
    errors.preventSave = true
  }

  return errors
}

export function orderNumbersSelector(state) {
  const {labelInfoIDs} = modalFormSelector(state)
  const labelInfos = labelInfosSelector(state)

  return labelInfoIDs.reduce((prev, labelInfoID) => {
    const orderNumber = get(labelInfos, [labelInfoID, 'orders', 0])

    if (orderNumber && !prev.includes(orderNumber)) {
      prev.push(orderNumber)
    }

    return prev
  }, [])
}

export async function saveDimensions() {
  const {labelInfoIDs, length, width, height} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true, serverError: null})

    for (const labelInfoID of labelInfoIDs) {
      const labelConfig = labelConfigSelector(getState(), {labelInfoID})

      const packages = labelConfig.packages.map((parcel) => ({
        ...parcel,
        length,
        width,
        height,
      }))

      updateLabelConfig(labelInfoID, {packages})
    }

    showMessageToast('Dimensions have been updated')

    closeModal()

    await refreshOrderListAndCounts()

    await getBulkRates(labelInfoIDs)

    await getRates(labelInfoIDs)
  } catch (error) {
    updateModalForm({
      serverError: error.message || error.error_message,
      isSaving: false,
    })
  }
}

function EditOrderDimensionsModal({form}) {
  const errors = useSelector(errorsSelector)
  const orderNumbers = useSelector(orderNumbersSelector)

  return (
    <ConfirmModal
      title="Edit Dimensions"
      confirmText="Save"
      cancelText="Cancel"
      modalSize="x-sm"
      isDisabled={errors.preventSave}
      isSaving={form.isSaving}
      onConfirm={() => saveDimensions()}
      onCancel={() => closeModal()}
      error={form.serverError}
    >
      <ul className="list">
        <li className="list__item--form list__item--no-style fs-n0 text--md-grey">
          <strong>
            Editing <OrderNumberOrCount orderNumbers={orderNumbers} />
          </strong>
        </li>
        <li className="list__item--form list__item--no-style flex">
          <DimensionsInput
            length={form.length}
            width={form.width}
            height={form.height}
            setLength={(length) => updateModalForm({length})}
            setWidth={(width) => updateModalForm({width})}
            setHeight={(height) => updateModalForm({height})}
            errorMessage={errors.height || errors.length || errors.width}
            hasLengthError={!!errors.length}
            hasWidthError={!!errors.width}
            hasHeightError={!!errors.height}
            onEnterKeyPress={() => !errors.preventSave && saveDimensions()}
            autoFocus
          />
        </li>
      </ul>
    </ConfirmModal>
  )
}

EditOrderDimensionsModal.propTypes = {
  form: PropTypes.shape({
    labelInfoIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditOrderDimensionsModal, modalFormSelector)
