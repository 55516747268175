import {ensureArray} from '../../common/ensure.js'
import {
  SingleAddressLayouts,
  MultipleAddressLayouts,
} from '../../common/constants/AddressLabelLayouts.js'
import Select from '../../common/components/Form/Select.js'
import NumberInput from '../../common/components/Form/NumberInput.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showEditMockBarcodeValuesModal} from './Modals/EditMockBarcodeValuesModal.js'
import {showEditMockBarcodeTextsModal} from './Modals/EditMockBarcodeTextsModal.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'

export default function MockAbodeBarcodeOptions() {
  const query = useSelector(querySelector)
  const values = ensureArray(query.value)
  const texts = ensureArray(query.text)

  return (
    <>
      <div>
        <ButtonLink onClick={() => showEditMockBarcodeValuesModal()}>
          {values.length === 0
            ? 'No Values'
            : values.length === 1
              ? values[0]
              : `${values.length} values`}
        </ButtonLink>
      </div>
      <div>
        <ButtonLink onClick={() => showEditMockBarcodeTextsModal()}>
          {texts.length === 0
            ? 'No Texts'
            : texts.length === 1
              ? texts[0]
              : `${texts.length} texts`}
        </ButtonLink>
      </div>
      <Select
        label="Layout"
        name="mock_abode__layout"
        value={query.layout}
        onChange={(value) => mockAbodeUpdateResourceQuery({layout: value})}
        className="margin-bottom-20"
      >
        {[...SingleAddressLayouts, ...MultipleAddressLayouts].map(
          ({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ),
        )}
      </Select>
      <div>
        <label htmlFor="mock_abode__copy_count">
          <span>Copy Count</span>
        </label>
        <NumberInput
          id="mock_abode__copy_count"
          value={query.copyCount}
          min={1}
          onChange={(value) => mockAbodeUpdateResourceQuery({copyCount: value})}
        />
      </div>
    </>
  )
}
