import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {setForm, updateForm, formsSelector} from '../store.js'
import {fetchAllApiverson} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const REPORTS = 'REPORTS'

export function reportsSelector(state) {
  return formsSelector(state)[REPORTS] || reportsSelector.default
}
reportsSelector.default = {}

export function reportsHaveLoadedSelector(state) {
  return !!formsSelector(state)[REPORTS]
}

export function reportSelector(state, {reportID}) {
  return reportsSelector(state)[reportID]
}

export const reportsSortedByNameSelector = createSelector(
  reportsSelector,
  (reports) => sortBy(reports, 'name'),
)

export function setReports(reports) {
  setForm(REPORTS, keyBy(reports, 'id'))
}

export function setReport(report) {
  updateForm(REPORTS, {[report.id]: report})
}

export async function getReports() {
  try {
    const reports = await fetchAllApiverson('/report', 'report')

    setReports(reports)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting reports.',
        details: err.message || err.error_message,
      },
      `Error getting reports. ${err.error_message || err.message}`,
      err,
    )

    setReports([])
  }
}
