import {isExactly} from './utils.js'

export function memOutcome(
  func,
  outcomeCompare = isExactly,
  compareFuncs = isExactly,
) {
  const cache = {args: []}

  compareFuncs = Array.isArray(compareFuncs) ? compareFuncs : [compareFuncs]

  return (...args) => {
    let hasArgsChanged = false
    for (var i = 0; i < args.length; i++) {
      const compareFunc =
        compareFuncs[i] || compareFuncs[compareFuncs.length - 1]

      if (compareFunc(args[i], cache.args[i]) === false) {
        hasArgsChanged = true

        break
      }
    }

    if (!hasArgsChanged && 'outcome' in cache) {
      return cache.outcome
    }

    cache.args = args

    const outcome = func(...args)

    if (outcomeCompare(outcome, cache.outcome)) {
      return cache.outcome
    }

    cache.outcome = outcome

    return cache.outcome
  }
}

export function remem(
  obj,
  data,
  outcomeCompare = isExactly,
  compare = isExactly,
) {
  let needsRecompute = false

  if (!obj.cache) {
    obj.cache = {data: {}, value: undefined}

    needsRecompute = true
  }

  for (const [key, value] of Object.entries(data)) {
    const isSame = compare(obj.cache.data[key], value)

    obj.cache.data[key] = value

    if (!isSame) {
      needsRecompute = true
    }
  }

  return (func) => {
    const value = obj.cache.value

    if (needsRecompute) {
      const newValue = func(data)

      if (!outcomeCompare(value, newValue)) {
        obj.cache.value = newValue
      }
    }

    return obj.cache.value
  }
}
