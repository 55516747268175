import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'

import {
  MODAL_NAME,
  confirm,
} from '../../../redux/actions/ui/modals/confirmArchiveProductModal.js'
import BaseConfirmModal from './BaseConfirmModal.js'

class ConfirmArchiveProductModal extends Component {
  static propTypes = {
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    willArchive: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    confirm: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  confirm = () => {
    this.props.confirm(true)
  }

  cancel = () => {
    this.props.confirm(false)
  }

  render() {
    const {skus, willArchive, label} = this.props

    return (
      <BaseConfirmModal
        title={`You sure you want to ${willArchive ? 'archive' : 'unarchive'} ${
          skus.length
        } ${label}?`}
        modalSize="md"
        modalName={MODAL_NAME}
        onConfirm={this.confirm}
        onCancel={this.cancel}
        confirmText={willArchive ? 'Archive' : 'Unarchive'}
        cancelText={'Cancel'}
      >
        {willArchive
          ? `The ${label} will be made inactive, but you can always unarchive ${
              skus.length > 1 ? 'them' : 'it'
            } later.`
          : `The ${label} will be made active again.`}
      </BaseConfirmModal>
    )
  }
}

function mapStateToProps(state) {
  const {skus, willArchive} = state.ui.modals.confirmArchiveProductModal

  return {
    skus,
    willArchive,
    label: skus.length > 1 ? 'products' : 'product',
  }
}

const mapDispatchToProps = {
  confirm,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmArchiveProductModal)
