import {useEffect} from 'react'

import {useSelector} from '../../store.js'
import Loading from '../../common/components/List/Loading.js'
import {refreshBatchList} from './batchListActions.js'
import {
  batchListQuerySelector,
  isLoadingSelector,
} from './batchListSelectors.js'
import BatchListFooter from './BatchListFooter.js'
import Table from './Table.js'

export default function List() {
  const params = useSelector(batchListQuerySelector)
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    refreshBatchList()
  }, [params])

  return (
    <>
      {isLoading ? (
        <div className="medium-12 columns list--empty-content margin-top-40 margin-bottom-40">
          <Loading />
        </div>
      ) : (
        <div className="medium-12 columns">
          <Table />
          <BatchListFooter />
        </div>
      )}
    </>
  )
}
