import {updateLabelConfig} from '../../../data/labelInfos/index.js'
import ConfigSelect from './ConfigSelect.js'
import {DHL_ECOMMERCE} from '../../../common/constants/ShipperNames.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const VALID_SHIPPER_TYPES = [DHL_ECOMMERCE]

function labelPropertiesFunc(shipperType) {
  return VALID_SHIPPER_TYPES.includes(shipperType)
    ? [`${shipperType}__service_endorsement`]
    : []
}

export default function ServiceEndorsement() {
  const {labelInfoID, shipperType, onChange} = useLabelConfigContext()
  const [labelProperty] = labelPropertiesFunc(shipperType)

  return (
    <ConfigSelect
      label="Service Endorsement"
      onChange={(value) => {
        updateLabelConfig(labelInfoID, {
          [labelProperty]: value,
        })

        onChange()
      }}
      labelProperty={labelProperty}
      labelPropertiesFunc={labelPropertiesFunc}
      validShipperTypes={VALID_SHIPPER_TYPES}
      shipperOptionsProperty="service_endorsement"
    />
  )
}
