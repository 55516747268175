import api from '../../../../../common/api.js'
import baseStrategy from './baseStrategy.js'

import {setColumnsToDataMap} from '../../dataImport/index.js'

export default class bomStrategy extends baseStrategy {
  static title = 'Manage BOMs'
  static requiredColumns(columnsToDataMap) {
    const columns = ['sku']

    if (
      columnsToDataMap.remove_child_sku === -1 &&
      columnsToDataMap.add_child_sku === -1
    ) {
      columns.push('add_child_sku')
      columns.push('remove_child_sku')
      columns.push('quantity')
    }

    if (
      columnsToDataMap.remove_child_sku === -1 &&
      columnsToDataMap.add_child_sku !== -1
    ) {
      columns.push('add_child_sku')
      columns.push('quantity')
    }

    return columns
  }

  static getExampleRows() {
    return async () => {
      const {json: data} = await api.get('/product/', {
        limit: 20,
        manufactured: false,
        kit: false,
      })
      const rows = [
        ['SKU *Req', 'Add Child SKU', 'Remove Child SKU', 'Quantity'],
      ]

      for (var i = 0; i < data.product.length; i += 2) {
        const parent = data.product[i]
        const child = data.product[i + 1]

        if (!parent || !child) {
          break
        }

        rows.push([parent.sku, child.sku, '', String(i + 1)])
      }

      return rows
    }
  }

  static guessAtColumnDataMap(columns) {
    function find(regex) {
      return columns.findIndex((column) => column.search(regex) !== -1)
    }

    const columnsToDataMap = {
      sku: find(/^sku$/i),
      add_child_sku: find(/^((add|update|create).)?child.sku$/i),
      remove_child_sku: find(/^(remove|delete).child.sku$/i),
      quantity: find(/quantity/i),
    }

    return setColumnsToDataMap(columnsToDataMap)
  }

  static transformRowToData(row, columnsByName) {
    const data = this.getNewData()
    const add_child_sku = row[columnsByName.add_child_sku]
    const remove_child_sku = row[columnsByName.remove_child_sku] || ''
    const quantity = row[columnsByName.quantity]

    data.payload = {
      sku: row[columnsByName.sku],
      add_child_sku: !remove_child_sku ? add_child_sku : '',
      quantity: !remove_child_sku ? quantity : '',
      remove_child_sku,
    }

    data.errors = this.determineDataErrors(data)

    return data
  }

  static determineDataErrors(data) {
    const payload = data.payload
    const errors = {...data.errors}

    this.required(payload, errors, 'sku', 'SKU is required')

    if (payload.remove_child_sku) {
      delete errors.add_child_sku
      delete errors.quantity
    } else {
      this.required(
        payload,
        errors,
        'add_child_sku',
        'Add Child SKU is required',
      )

      this.requiredNumber(
        payload,
        errors,
        'quantity',
        'Quantity must be a number',
      )
    }

    return errors
  }

  static save(data) {
    return () => {
      const sku = encodeURIComponent(data.payload.sku)
      const url = `/product/${sku}/manufacturing/`

      return data.payload.add_child_sku
        ? api.post(url, {
            child_sku: data.payload.add_child_sku,
            quantity: data.payload.quantity,
          })
        : api.delete(url, {
            child_sku: data.payload.remove_child_sku,
          })
    }
  }
}
