import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import {
  setPageTopMargin,
  setPageLeftMargin,
} from '../../../../../redux/actions/ui/labelViewForm/index.js'

import NumberInput from './NumberInput.js'

export class PageMargins extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    pageTopMargin: PropTypes.string,
    pageLeftMargin: PropTypes.string,
    setPageTopMargin: PropTypes.func.isRequired,
    setPageLeftMargin: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.setPageTopMargin = (event) =>
      this.props.setPageTopMargin(event.target.value)
    this.setPageLeftMargin = (event) =>
      this.props.setPageLeftMargin(event.target.value)
  }

  render() {
    return (
      <div className="flex-item">
        <label className="label--bold">{this.props.label}</label>
        <div className="flex flex-wrap-margin-10">
          <NumberInput
            text="Top"
            id="margin-top"
            value={this.props.pageTopMargin}
            onChange={this.setPageTopMargin}
          />
          <NumberInput
            text="Left"
            id="margin-left"
            value={this.props.pageLeftMargin}
            onChange={this.setPageLeftMargin}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  pageTopMargin: (state) => state.ui.labelViewForm.pageTopMargin || '',
  pageLeftMargin: (state) => state.ui.labelViewForm.pageLeftMargin || '',
})

const mapDispatchToProps = {
  setPageTopMargin,
  setPageLeftMargin,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageMargins)
