import {createSelector} from 'reselect'
import cloneDeep from 'lodash/cloneDeep.js'
import isEmpty from 'lodash/isEmpty.js'

import {formsSelector, formSelector} from '../../../store.js'
import {isPresent, isPositiveNumeric, isNumeric} from '../../../common/utils.js'
import {cartVendorsByName} from '../../../common/constants/CartVendorOptions.js'
import {cartSelector} from '../../../data/carts.js'
import {
  SETTINGS,
  SALES_CHANNELS,
  SALES_CHANNEL_SETTINGS_LINK,
  NEW_ID,
} from '../../../common/constants/SettingsPanels.js'
import {locationSelector} from '../../../redux/selectors/ui/location.js'
import {SETTINGS_SALES_CHANNELS} from './salesChannelsActions.js'

export function salesChannelsFormSelector(state) {
  return (
    formsSelector(state)[SETTINGS_SALES_CHANNELS] ||
    salesChannelsFormSelector.default
  )
}
salesChannelsFormSelector.default = {}

export const settingsSalesChannelCartIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === SALES_CHANNELS &&
      isPositiveNumeric(pathComponents[2])
    ) {
      return Number(pathComponents[2])
    }

    return null
  },
)

export const settingsSalesChannelAddSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    return (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === SALES_CHANNELS &&
      pathComponents[2] === 'add'
    )
  },
)

export const salesChannelsHashSelector = createSelector(
  settingsSalesChannelCartIDSelector,
  settingsSalesChannelAddSelector,
  (cartID, isNew) => {
    if (isNew) {
      return `${SALES_CHANNEL_SETTINGS_LINK}/add`
    }

    if (cartID) {
      return `${SALES_CHANNEL_SETTINGS_LINK}/${cartID}/edit`
    }

    return SALES_CHANNEL_SETTINGS_LINK
  },
)

export function cartFormErrorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  let errors = {}

  if (!form || !form.errorsSelector) {
    return errors
  }

  if (
    form.serverError &&
    form.serverError.param &&
    form.serverParamErrorsSelector
  ) {
    errors = form.serverParamErrorsSelector(state, {formName})
  }

  if (form.serverError && isEmpty(errors)) {
    errors.serverError = `${
      form.serverError.error_message || form.serverError.message
    }${form.serverError.param ? `: ${form.serverError.param}` : ''}`
  }

  return {
    ...errors,
    ...(form.errorsSelector ? form.errorsSelector(state, {formName}) : null),
  }
}

export function baseCartErrorsSelector(state, {formName}) {
  const form = formSelector(state, {formName})
  const errors = {}

  if (!form) {
    return errors
  }

  if (!isPresent(form.name)) {
    errors.name = 'Name is required'
    errors.preventSave = true
  }

  return errors
}

export function baseEditCartParamsSelector(state, {cartID}) {
  const cart = cartSelector(state, {cartID})

  const revision_config = cart.revision_config || {
    lines_changed: [],
    lines_added: false,
    lines_removed: false,
    auto_apply: false,
  }

  if (!revision_config.lines_changed) {
    revision_config.lines_changed = []
  }

  return {
    id: cart.id,
    vendor: cart.vendor,
    name: cart.name,
    defaultPackingListID: `${cart.default_packing_list_id || ''}`,
    cweb: cloneDeep(cart.cweb),
    prevent_write_back_settings_adjustment:
      cart.prevent_write_back_settings_adjustment || false,
    default_product_sync: cart.default_product_sync === false ? false : true,
    default_min_export_qty: isPresent(cart.default_min_export_qty)
      ? String(cart.default_min_export_qty)
      : '',
    import_zero_qty_lines: cart.import_zero_qty_lines,
    revision_config,
  }
}

export function baseNewCartParamsSelector(state, {vendor}) {
  return {
    id: NEW_ID,
    name: `${cartVendorsByName[vendor].display} Cart`,
    vendor,
  }
}

export function baseSaveParamsSelector(state, {formName}) {
  const form = formSelector(state, {formName})

  if (form.id === NEW_ID) {
    return {
      name: form.name,
    }
  }

  const defaultPackingListID =
    form.defaultPackingListID === '' ? null : Number(form.defaultPackingListID)

  return {
    name: form.name,
    default_packing_list_id: defaultPackingListID,
    prevent_write_back_settings_adjustment:
      form.prevent_write_back_settings_adjustment,
    default_product_sync: form.default_product_sync,
    default_min_export_qty: isNumeric(form.default_min_export_qty)
      ? Number(form.default_min_export_qty)
      : null,
    import_zero_qty_lines: form.import_zero_qty_lines,
    revision_config: form.revision_config,
  }
}
