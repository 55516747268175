import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ListItemsCount from '../../common/components/List/ListItemsCount.js'
import {
  perPageSelector,
  currentPageSelector,
  countSelector,
} from './returnOrderListSelectors.js'

export function ReturnOrderListItemsCount({perPage, currentPage, count}) {
  return (
    <li className="inline-block margin-right-3">
      <ListItemsCount
        limit={perPage}
        offset={(currentPage - 1) * perPage}
        count={count}
        thing={`RMA${count === 1 ? '' : 's'}`}
      />
    </li>
  )
}

ReturnOrderListItemsCount.propTypes = {
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {
    perPage: perPageSelector(state),
    currentPage: currentPageSelector(state),
    count: countSelector(state),
  }
}

export default connect(mapStateToProps)(ReturnOrderListItemsCount)
