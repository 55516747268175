import PropTypes from 'prop-types'
import {createSelector} from 'reselect'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import apiverson from '../../../common/apiverson.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import DatePicker from '../../../common/components/DatePicker.js'
import Select from '../../../common/components/Select.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshBatchList} from '../batchListActions.js'
import {setBatch, batchSelector} from '../../../data/batches.js'
import {batchReferenceIDSelector} from '../../OrderListPage/orderListSelectors.js'
import {navigateOrderList} from '../../OrderListPage/orderListActions.js'

export const MODAL_FORM = 'DIT_BATCH_PARAM_MODAL'

export function showBatchReferenceIDModal(referenceID) {
  showEditBatchParamModal({
    referenceID,
    value: referenceID,
    display: 'Batch ID',
    apiProp: 'reference_id',
    inputType: 'text',
    needsBatchListRefresh: true,
    isRequired: true,
  })
}

export function showBatchInternalNotesModal(referenceID) {
  const batch = batchSelector(getState(), {referenceID})

  showEditBatchParamModal({
    referenceID,
    value: batch.internal_notes,
    display: 'Internal Notes',
    apiProp: 'internal_notes',
    inputType: 'textarea',
  })
}

export function defaultCast(value) {
  return value
}

export function dateCast(value) {
  return value ? value.toISOString() : null
}

export function numberCast(value) {
  return Number(value)
}

export function showEditBatchParamModal({
  referenceID,
  value,
  display,
  apiProp,
  isRequired,
  needsBatchListRefresh,
  inputType,
  typeCast,
  SubComponent,
  optionsSelector,
}) {
  inputType = inputType || 'text'

  setForm(MODAL_FORM, {
    referenceID,
    apiProp,
    value:
      inputType === 'date'
        ? value
          ? new Date(value)
          : null
        : isPresent(value)
          ? value
          : '',
    display,
    isRequired: !!isRequired,
    needsBatchListRefresh: !!needsBatchListRefresh,
    inputType,
    typeCast:
      typeCast ||
      (inputType === 'date'
        ? dateCast
        : inputType === 'currency'
          ? numberCast
          : defaultCast),
    isSaving: false,
    serverError: null,
    SubComponent,
    optionsSelector,
  })
}

export function updateModalForm(...args) {
  return updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  return removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({value, display, isRequired, inputType}) => {
    const errors = {}

    if (isRequired && !isPresent(value)) {
      errors.value = `${display} is required`
      errors.preventSave = true
    }

    if (inputType === 'currency' && !isNumeric(value)) {
      errors.value = `${display} must be a number`
      errors.preventSave = true
    }

    return errors
  },
)

export async function saveBatchParam() {
  try {
    const {
      referenceID,
      value,
      display,
      apiProp,
      needsBatchListRefresh,
      typeCast,
    } = modalFormSelector(getState())
    const batchReferenceID = batchReferenceIDSelector(getState())

    const params = {
      [apiProp]: typeCast(value),
    }

    updateModalForm({isSaving: true, serverError: null})

    const {json} = await apiverson.put(`/batch/${referenceID}`, params)

    setBatch(json)

    closeModal()

    showMessageToast(`Saved ${display}.`)

    if (apiProp === 'reference_id' && batchReferenceID) {
      await navigateOrderList({batch_reference_id: value})
    } else if (needsBatchListRefresh) {
      await refreshBatchList()
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditBatchParamModal({form}) {
  const errors = useSelector(errorsSelector)
  const options = useSelector(form.optionsSelector || (() => []))

  return (
    <ConfirmModal
      title={`Edit ${form.display}`}
      modalSize="sm"
      onConfirm={() => saveBatchParam()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
      preventInnerScroll
    >
      <div className="list__item--form list__item--no-style margin-bottom-20">
        <div className="fs-01">
          <strong>{form.referenceID}</strong>
        </div>
      </div>
      {form.inputType === 'text' ? (
        <TextInput
          className="margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'textarea' ? (
        <TextArea
          className="fs-00 lh-md margin-bottom-0"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'date' ? (
        <DatePicker
          id="batch_param_modal"
          selected={form.value}
          onChange={(value) =>
            updateModalForm({
              value,
            })
          }
        />
      ) : form.inputType === 'currency' ? (
        <CurrencyInput
          className="biggie margin-bottom-0"
          width="sm"
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        />
      ) : form.inputType === 'select' ? (
        <Select
          value={form.value}
          onChange={(value) => updateModalForm({value})}
          errorMessage={errors.value}
          autoFocus
        >
          {options.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </Select>
      ) : null}
      {form.SubComponent && <form.SubComponent form={form} />}
    </ConfirmModal>
  )
}

EditBatchParamModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    value: PropTypes.any,
    display: PropTypes.string.isRequired,
    apiProp: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf([
      'text',
      'textarea',
      'date',
      'currency',
      'select',
    ]).isRequired,
    typeCast: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    SubComponent: PropTypes.func,
    optionsSelector: PropTypes.func,
  }).isRequired,
}

export default onlyIfForm(EditBatchParamModal, modalFormSelector)
