import actions from '../../../../redux/actions/ui/dataImport/strategies/productImageStrategy.js'
import BaseStrategy from '../BaseStrategy.js'
import ReconcileForm from './ReconcileForm.js'
import EditForm from './EditForm.js'

export default function ProductImageStrategy(props) {
  return (
    <BaseStrategy
      {...props}
      actions={actions}
      EditForm={EditForm}
      ReconcileForm={ReconcileForm}
    />
  )
}

ProductImageStrategy.title = actions.title
