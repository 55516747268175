import PropTypes from 'prop-types'
import classNames from 'classnames'

import Checkbox from '../Checkbox.js'

export default function FormCheckbox({
  className,
  name,
  errorMessage,
  disabled,
  label,
  checked,
  header,
  children,
  ...props
}) {
  const allClassName = classNames(`container_${name}`, className, {
    error: !!errorMessage,
  })

  return (
    <div className={allClassName}>
      <Checkbox
        label={label}
        name={name}
        id={`id_${name}`}
        checked={checked}
        disabled={disabled}
        errorMessage={errorMessage}
        {...props}
      />
      {header}
      {checked && children && (
        <div className="wrap--nested-form">{children}</div>
      )}
    </div>
  )
}

FormCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
