import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {SelectOptionsShape, SelectOptionTokens} from '../../common/PropTypes.js'
import FilterTagFilter from '../../common/components/List/FilterTagFilter/index.js'
import {hasReturnOrderManageTagPermissionSelector} from '../../data/me.js'
import {
  returnOrderTagOptionsSelector,
  returnOrderTagOptionTokensSelector,
} from '../../data/returnOrderTags.js'
import {
  createAndApplyTagToReturnOrders,
  setTags,
  setUntagged,
  setTagFilterBy,
} from './returnOrderListActions.js'
import {
  selectedReferenceIDsSelector,
  returnOrderTagFiltersSelector,
  returnOrderUntaggedFilterSelector,
  returnOrderTagFilterBySelector,
} from './returnOrderListSelectors.js'

function ReturnOrderTagFiltersDropdown({
  referenceIDs,
  returnOrderTagOptions,
  returnOrderTagOptionTokens,
  returnOrderTagFilters,
  untaggedSelected,
  hasReturnOrderManageTagPermission,
  filterOperator,
  ...props
}) {
  return (
    <FilterTagFilter
      dropdown="FILTER_RETURN_ORDER_TAG_FILTER"
      selectedTags={returnOrderTagFilters}
      tagOptions={returnOrderTagOptions}
      tagOptionTokens={returnOrderTagOptionTokens}
      untaggedSelected={untaggedSelected}
      filterOperator={filterOperator}
      hasManageTagPermission={hasReturnOrderManageTagPermission}
      willApplyCreatedTag={referenceIDs.length !== 0}
      onCreate={() => createAndApplyTagToReturnOrders(referenceIDs)}
      onSelect={(tag) => {
        const tags = returnOrderTagFilters.filter((name) => name !== tag.name)

        if (!returnOrderTagFilters.includes(tag.name)) {
          tags.push(tag.name)
        }

        props.setTags(tags)
      }}
      onToggleUntaggedFilter={(isSelected) => props.setUntagged(isSelected)}
      onTagFilterOperatorChange={(operator) => props.setTagFilterBy(operator)}
    />
  )
}

ReturnOrderTagFiltersDropdown.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  returnOrderTagOptions: SelectOptionsShape.isRequired,
  returnOrderTagOptionTokens: SelectOptionTokens.isRequired,
  returnOrderTagFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  untaggedSelected: PropTypes.bool.isRequired,
  hasReturnOrderManageTagPermission: PropTypes.bool.isRequired,
  filterOperator: PropTypes.string.isRequired,
  setTags: PropTypes.func.isRequired,
  setUntagged: PropTypes.func.isRequired,
  setTagFilterBy: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    referenceIDs: selectedReferenceIDsSelector(state),
    returnOrderTagOptions: returnOrderTagOptionsSelector(state),
    returnOrderTagOptionTokens: returnOrderTagOptionTokensSelector(state),
    returnOrderTagFilters: returnOrderTagFiltersSelector(state),
    untaggedSelected: returnOrderUntaggedFilterSelector(state),
    hasReturnOrderManageTagPermission:
      hasReturnOrderManageTagPermissionSelector(state),
    filterOperator: returnOrderTagFilterBySelector(state),
  }
}

const mapDispatchToProps = {
  setTags,
  setUntagged,
  setTagFilterBy,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReturnOrderTagFiltersDropdown)
