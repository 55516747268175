import PropTypes from 'prop-types'

import {
  formattedDimensionsSelector,
  isMultiboxSelector,
  invalidDimensionsSelector,
  showDimensionsSelector,
  labelShipperTypeSelector,
} from '../../../../../../../data/labelInfos/index.js'
import {showEditOrderDimensionsModal} from '../../../../../../OrderListPage/Modals/EditOrderDimensionsModal.js'

import EditForm from './EditForm.js'
import {useSelector} from '../../../../../../../store.js'
import {useLabelConfigContext} from '../../../../../../LabelConfig/LabelConfigContext.js'

export default function EditDimensions({disabled}) {
  const {labelInfoID} = useLabelConfigContext()
  const shipperType = useSelector((state) =>
    labelShipperTypeSelector(state, {labelInfoID}),
  )
  const showDimensions = useSelector((state) =>
    showDimensionsSelector(state, {
      labelInfoID,
      shipperType,
    }),
  )
  const formattedDimensions = useSelector((state) =>
    formattedDimensionsSelector(state, {
      labelInfoID,
      packagesIndex: 0,
    }),
  )
  const isInvalid = useSelector((state) =>
    invalidDimensionsSelector(state, {labelInfoID, shipperType}),
  )
  const isMultibox = useSelector((state) =>
    isMultiboxSelector(state, {labelInfoID, shipperType}),
  )

  const value = showDimensions ? formattedDimensions : 'N/A'

  return (
    <li className="list__item--order-data list__item--shipping-info meta-orderlistrow-expanded-wrap-edit-dimensions">
      <EditForm
        label="Dimensions"
        labelInfoID={labelInfoID}
        value={value}
        disabled={disabled || (!showDimensions && !isMultibox)}
        isInvalid={isInvalid}
        isMultibox={isMultibox}
        shipperType={shipperType}
        onEditClick={() => showEditOrderDimensionsModal([labelInfoID])}
      />
    </li>
  )
}

EditDimensions.propTypes = {
  disabled: PropTypes.bool,
}
