import {showCreateMOModal} from './Modals/CreateMOModal.js'
import {showExportMOModal} from './Modals/ExportMOModal.js'

export default function Actions() {
  return (
    <>
      <li className="inline-block v-align-middle fs-n0 divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => showCreateMOModal()}
        >
          Initiate a MFG Order
        </button>
      </li>
      <li className="inline-block v-align-middle fs-n0 margin-left-10">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => showExportMOModal()}
        >
          Export MFG Orders
        </button>
      </li>
    </>
  )
}
