import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import formConnect from '../../../common/formConnect.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'

const MODAL_FORM = 'CANCEL_RETURN_ORDERS_MODAL_FORM'
export const CANCEL_RETURN_ORDERS = 'CANCEL_RETURN_ORDERS'

export function showCancelReturnOrdersModal(referenceIDs) {
  return setForm(MODAL_FORM, {
    referenceIDs,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function cancelReturnOrders() {
  return {
    type: CANCEL_RETURN_ORDERS,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* cancelReturnOrder(id) {
  const {json} = yield call(
    apiverson.post,
    `/return_order/${encodeURIComponent(id)}/cancel`,
  )

  yield put(setReturnOrder(json))
}

export function* cancelReturnOrdersWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceIDs} = yield select(modalFormSelector)
    const roCount = referenceIDs.length

    yield all(referenceIDs.map((id) => call(cancelReturnOrder, id)))

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${roCount} RMA${roCount !== 1 ? 's were' : ' was'} cancelled`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error canceling RMA: ${err.message || err.error_message}`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function CancelReturnOrdersModal({form, ...props}) {
  const roCount = form.referenceIDs.length

  return (
    <ConfirmModal
      title="Cancel RMA"
      onConfirm={() => props.cancelReturnOrders()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      Cancel {roCount} RMA{roCount !== 1 ? 's' : ''}?
    </ConfirmModal>
  )
}

CancelReturnOrdersModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  cancelReturnOrders: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  cancelReturnOrders,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(null, mapDispatchToProps)(CancelReturnOrdersModal),
  modalFormSelector,
)
