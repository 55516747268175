import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  INITIATED_STATUS,
  ACTIVE_STATUS,
  FINISHED_STATUS,
  CANCELLED_STATUS,
  ALL_STATUS,
} from '../../common/constants/MOs.js'
import {moListQuerySelector, isFilteredSelector} from './moListSelectors.js'

export function EmptyList({status, isFiltered, colSpan}) {
  return (
    <tr className="table__tr">
      <td className="table__td" colSpan={colSpan}>
        <div className="padding-top-25 padding-bottom-25 align-center">
          <strong className="text--md-grey fs-02 lh-md">
            {isFiltered
              ? 'No MFG Orders match the current search terms.'
              : (status === INITIATED_STATUS &&
                  'There are no initiated MFG Orders. You can create one using the “Initiate a MFG Order” button.') ||
                (status === ACTIVE_STATUS &&
                  'None of your MFG Orders are active.') ||
                (status === FINISHED_STATUS &&
                  'None of your MFG Orders are finished.') ||
                (status === CANCELLED_STATUS &&
                  'None of your MFG Orders are cancelled.') ||
                (status === ALL_STATUS && 'There aren’t any MFG Orders.')}
          </strong>
        </div>
      </td>
    </tr>
  )
}

EmptyList.propTypes = {
  status: PropTypes.string.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  colSpan: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
  return {
    status: moListQuerySelector(state).status,
    isFiltered: isFilteredSelector(state),
  }
}

export default connect(mapStateToProps)(EmptyList)
