import PropTypes from 'prop-types'

import {updateForm, useForm} from '../../../store.js'
import {ErrorsShape} from '../../../common/PropTypes.js'
import {REPORT_INTERVAL_OPTIONS} from '../../../common/constants/Reports.js'
import {plural} from '../../../common/components/Plural.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import Select from '../../../common/components/Select.js'

export default function ReportInterval({formName, errors}) {
  const form = useForm(formName)

  return (
    <>
      <li className="list__item--form list__item--no-style margin-bottom-0">
        <label htmlFor="interval_type">Interval</label>
      </li>
      <li className="list__item--form list__item--no-style flex">
        {form.interval_type && (
          <NumberInput
            containerClassName="margin-right-10 wrap--weight-input"
            id="interval_amount"
            value={form.interval_amount}
            onChange={(value) =>
              updateForm(formName, {interval_amount: String(value)})
            }
            isInvalid={!!errors.interval_amount}
          />
        )}
        <div>
          <Select
            id="interval_type"
            value={form.interval_type}
            onChange={(interval_type) => updateForm(formName, {interval_type})}
          >
            {form.singleRun && <option value="">Specific Date Range</option>}
            {REPORT_INTERVAL_OPTIONS.map(({value, display}) => (
              <option key={value} value={value}>
                {plural(Number(form.interval_amount) || 0, 's', '', display)}
              </option>
            ))}
          </Select>
        </div>
      </li>
    </>
  )
}

ReportInterval.propTypes = {
  formName: PropTypes.string.isRequired,
  errors: ErrorsShape.isRequired,
}
