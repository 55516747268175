import reduce from 'lodash/reduce.js'
import map from 'lodash/map.js'
import sortBy from 'lodash/sortBy.js'
import ShipperOptions from '@ordoro/shipper-options'

// keys are just the lowercase versions of the values because the API will lowercase
// any value we give them, so this is how we play ball.
// See https://github.com/ordoro/ordoro/issues/4775
export const SPACED_SHIPPER_NAMES = {
  other: 'Other',
  'australia post': 'Australia Post',
  'canada post': 'Canada Post',
  dhl: 'DHL',
  usps: 'USPS',
  fastway: 'Fastway',
  fedex: 'FedEx',
  ontrac: 'OnTrac',
  parcelforce: 'Parcelforce',
  purolator: 'Purolator',
  ups: 'UPS',
  'royal mail': 'Royal Mail',
  'china post': 'China Post',
}

export const SNAKED_SHIPPER_NAMES = reduce(
  ShipperOptions,
  (prev, value, key) => ({
    ...prev,
    [key]: value.display_name,
  }),
  {
    other: 'Other',
  },
)

export const SHIPPER_NAMES = {
  ...SPACED_SHIPPER_NAMES,
  ...SNAKED_SHIPPER_NAMES,
}

export const toSnake = (shipper) =>
  ({
    'canada post': 'canada_post',
    'australia post': 'australia_post',
    'royal mail': 'royal_mail',
  })[shipper] || shipper

export const AMAZON_SFP = 'amazon'
export const AMAZON_SHIPPER = 'amazon_shipper'
export const AUSTRALIA_POST = 'australia_post'
export const CANADA_POST = 'canada_post'
export const DHL = 'dhl'
export const DHL_ECOMMERCE = 'dhl_ecommerce'
export const ENDICIA = 'endicia'
export const FEDEX = 'fedex'
export const NEWGISTICS = 'newgistics'
export const PITNEY = 'pitney'
export const PITNEY_CBDS = 'pitney_cbds'
export const PITNEY_MERCHANT = 'pitney_merchant'
export const PITNEY_PRESORT = 'pitney_presort'
export const SENDLE = 'sendle'
export const UPS = 'ups'
export const VISIBLE_USPS = 'visible_usps'
export const X_DELIVERY = 'onelivex'

export const USPS_SHIPPERS = [
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  VISIBLE_USPS,
]

export const SHIPPER_VENDORS = [
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  PITNEY_CBDS,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
]

export const ORDORO_SHIPPERS = [
  // UPS, // DAP Only
  PITNEY, // use_pitney
  SENDLE,
  PITNEY_CBDS,
  NEWGISTICS,
  PITNEY_MERCHANT, // pitney_merchant
  PITNEY_PRESORT,
]

export const BYOA_SHIPPERS = [
  FEDEX,
  // UPS, // Non-DAP Only
  CANADA_POST,
  DHL,
  DHL_ECOMMERCE,
  X_DELIVERY,
]

export const MANUAL_TRACKING_SHIPPER_NAMES = sortBy(
  map(ShipperOptions, ({display_name}, value) => ({
    value,
    display: display_name,
  })),
  'display',
).filter(({value}) => USPS_SHIPPERS.includes(value) === false)

export function mapShipperToTrackingCarrier(shipperName) {
  if (!shipperName) {
    return null
  }

  shipperName = shipperName.toLowerCase()

  shipperName = toSnake(shipperName)

  if (
    [ENDICIA, PITNEY, PITNEY_MERCHANT, PITNEY_PRESORT, VISIBLE_USPS].includes(
      shipperName,
    )
  ) {
    return 'usps'
  }

  return (
    MANUAL_TRACKING_SHIPPER_NAMES.find(({value}) => value === shipperName) || {
      value: 'other',
    }
  ).value
}

export const SHIPPERS_WITH_POSTAGE_BALANCES = [
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
]

export const SHIPPER_OPTIONS = [
  {value: AMAZON_SHIPPER, display: 'Amazon Shipping'},
  {value: AUSTRALIA_POST, display: 'Australia Post'},
  {value: CANADA_POST, display: 'Canada Post'},
  {value: PITNEY_CBDS, display: 'CBDS via Pitney'},
  {value: DHL, display: 'DHL'},
  {value: DHL_ECOMMERCE, display: 'DHL eCommerce'},
  {value: FEDEX, display: 'FedEx'},
  {value: NEWGISTICS, display: 'PB Standard'},
  {value: SENDLE, display: 'Sendle'},
  {value: UPS, display: 'UPS'},
  {value: PITNEY, display: 'USPS'},
  {value: PITNEY_MERCHANT, display: 'USPS'},
  {value: VISIBLE_USPS, display: 'USPS'},
  {value: PITNEY_PRESORT, display: 'USPS Presort'},
  {value: X_DELIVERY, display: 'X Delivery'},
]
