import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function FormList({className, children}) {
  return <ul className={classNames('list--form', className)}>{children}</ul>
}

FormList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
