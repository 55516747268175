import {dispatch, useSelector} from '../../store.js'
import HeaderDropdown from '../../common/components/List/HeaderDropdown.js'
import DropdownItemButton from '../../common/components/Dropdown/DropdownItemButton.js'
import {showCreateProductModal} from './Modals/CreateProductModal.js'
import {
  showCartSyncModal,
  allowCartSyncModalSelector,
} from '../Modals/CartSyncModal/cartSyncModalFunctions.js'
import {showCreateInventoryTransferModal} from './Modals/CreateInventoryTransferModal/createInventoryTransferModalActions.js'
import {canTransferInventorySelector} from '../../data/company.js'
import {
  hasMOPermissionSelector,
  hasProductImportFromCartPermissionSelector,
  hasProductManageTagPermissionSelector,
} from '../../data/me.js'
import {showEditReportModal} from '../ReportsPage/Modals/EditReportModal.js'
import {
  REPORT_EXPORT_PRODUCTS,
  REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
  REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
  REPORT_EXPORT_SKU_SALES,
} from '../../common/constants/Reports.js'
import {hasMOFeatureSelector} from '../../data/mos.js'
import {showCreateMOModal} from '../MOListPage/Modals/CreateMOModal.js'
import {TAG_TYPE__PRODUCT} from '../../common/constants/Tags.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {
  countSelector,
  productListQueryParamsSelector,
} from './productListFunctions.js'

export default function Actions() {
  const hasProductImportFromCartPermission = useSelector(
    hasProductImportFromCartPermissionSelector,
  )
  const hasProductManageTagPermission = useSelector(
    hasProductManageTagPermissionSelector,
  )
  const canTransferInventory = useSelector(canTransferInventorySelector)
  const allowCartSyncModal = useSelector(allowCartSyncModalSelector)
  const hasMOFeature = useSelector(hasMOFeatureSelector)
  const hasMOPermission = useSelector(hasMOPermissionSelector)
  const productQuery = useSelector(productListQueryParamsSelector)
  const queryTotalCount = useSelector(countSelector)

  return (
    <>
      {allowCartSyncModal && hasProductImportFromCartPermission && (
        <li className="inline-block fs-n0 v-align-middle margin-left-15 divider--left">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showCartSyncModal()}
          >
            Import Products
          </button>
        </li>
      )}
      {hasProductManageTagPermission && (
        <li className="inline-block fs-n0 margin-left-15">
          <button
            className="btn btn--action v-align-base"
            onClick={() => showEditTagModal(TAG_TYPE__PRODUCT)}
          >
            Create Tag
          </button>
        </li>
      )}
      <HeaderDropdown
        label="Other Actions"
        className="margin-left-15"
        dropdown="DROPDOWN_PRODUCT_LIST_OTHER_ACTIONS_FOR_SELECTED"
      >
        {canTransferInventory && (
          <DropdownItemButton
            onClick={() => dispatch(showCreateInventoryTransferModal())}
          >
            Transfer Inventory
          </DropdownItemButton>
        )}
        <DropdownItemButton onClick={() => showCreateProductModal()}>
          Create a Product
        </DropdownItemButton>
        {hasMOFeature && hasMOPermission && (
          <DropdownItemButton onClick={() => showCreateMOModal()}>
            Initiate a MFG Order
          </DropdownItemButton>
        )}
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PRODUCTS,
              singleRun: true,
              productQuery,
              queryTotalCount,
            })
          }
        >
          Export SKU Inventory Info
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_SKU_SALES,
              singleRun: true,
            })
          }
        >
          Export Sales by SKU Info
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PRODUCTS_SALES_CHANNELS,
              singleRun: true,
              productQuery,
              queryTotalCount,
            })
          }
        >
          Export Product/Sales Channel Bridge
        </DropdownItemButton>
        <DropdownItemButton
          onClick={() =>
            showEditReportModal({
              reportType: REPORT_EXPORT_PRODUCTS_FULFILLMENT_SPEED,
              singleRun: true,
            })
          }
        >
          Export Fulfillment Speed
        </DropdownItemButton>
      </HeaderDropdown>
    </>
  )
}
