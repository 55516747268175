import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import {
  setLabelWidth,
  setLabelHeight,
} from '../../../../../redux/actions/ui/labelViewForm/index.js'

import NumberInput from './NumberInput.js'

export class LabelDimensions extends Component {
  static propTypes = {
    labelWidth: PropTypes.string,
    labelHeight: PropTypes.string,
    setLabelWidth: PropTypes.func.isRequired,
    setLabelHeight: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.setLabelWidth = (event) => this.props.setLabelWidth(event.target.value)
    this.setLabelHeight = (event) =>
      this.props.setLabelHeight(event.target.value)
  }

  render() {
    return (
      <div className="flex-item">
        <label className="label--bold" htmlFor="label-width">
          Label Dimensions
        </label>
        <div className="flex flex-wrap-margin-10">
          <NumberInput
            text="Width"
            id="label-width"
            value={this.props.labelWidth}
            onChange={this.setLabelWidth}
          />
          <NumberInput
            text="Height"
            id="label-height"
            value={this.props.labelHeight}
            onChange={this.setLabelHeight}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  labelWidth: (state) => state.ui.labelViewForm.labelWidth || '',
  labelHeight: (state) => state.ui.labelViewForm.labelHeight || '',
})

const mapDispatchToProps = {
  setLabelWidth,
  setLabelHeight,
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelDimensions)
