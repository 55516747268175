import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {plural} from '../../common/components/Plural.js'
import ListItemsCount from '../../common/components/List/ListItemsCount.js'
import {moListQuerySelector, countSelector} from './moListSelectors.js'

export function MOListItemsCount({limit, offset, count}) {
  return (
    <li className="inline-block margin-right-3">
      <ListItemsCount
        limit={limit}
        offset={offset}
        count={count}
        thing={plural(count)`MFG Order${['s']}`}
      />
    </li>
  )
}

MOListItemsCount.propTypes = {
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  const params = moListQuerySelector(state)

  return {
    limit: params.limit,
    offset: params.offset,
    count: countSelector(state),
  }
}

export default connect(mapStateToProps)(MOListItemsCount)
