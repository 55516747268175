import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import {createStructuredSelector} from 'reselect'

import {
  MODAL_NAME,
  confirm,
} from '../../../redux/actions/ui/modals/confirmDropshipProductModal.js'
import BaseConfirmModal from './BaseConfirmModal.js'

class ConfirmDropshipProductModal extends Component {
  static propTypes = {
    productCount: PropTypes.number.isRequired,
    confirm: PropTypes.func.isRequired,
  }

  static modalName = MODAL_NAME

  confirmYes = () => this.props.confirm(true)

  confirmNo = () => this.props.confirm(false)

  render() {
    const {productCount} = this.props

    return (
      <BaseConfirmModal
        title={`Set Product${
          productCount > 1 ? 's' : ''
        } to Dropship Automatically?`}
        modalName={MODAL_NAME}
        onConfirm={this.confirmYes}
        onCancel={this.confirmNo}
        modalSize="sm-md"
      >
        <div className="margin-bottom-10">
          <strong className="fs-01 lh-md">
            <a
              href="https://support.ordoro.com/fully-automated-dropshipping/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Prepare yourself for awesomeness.
            </a>
          </strong>
        </div>
        <div className="fs-00 margin-bottom-10">
          Orders containing an automatically dropshipped product will be{' '}
          <strong>INSTANTLY</strong> sent to the assigned supplier during
          import. You won’t have to do a thing.
        </div>
        <div className="fs-00">Are you ready to start saving some time?</div>
      </BaseConfirmModal>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  productCount: (state) =>
    state.ui.modals.confirmDropshipProductModal.productCount,
})

const mapDispatchToProps = {
  confirm,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDropshipProductModal)
