import {
  setForm,
  removeForm,
  formsSelector,
  onlyIfForm,
} from '../../../../../store.js'
import ConfirmModal from '../../../../../common/components/Modal/ConfirmModal.js'

const MODAL_FORM = 'UPS_REGISTRATION_COMPLETE_MODAL'

export function showUPSRegistrationCompleteModal() {
  setForm(MODAL_FORM, {})
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function UPSRegistrationCompleteModal() {
  return (
    <ConfirmModal
      title="Your UPS Account was Created!"
      onCancel={() => closeModal()}
      cancelText="Close"
    >
      <p className="fs-00 lh-x-md margin-bottom-15">
        <strong>What happens next?</strong>
      </p>
      <p className="list__item fs-00 margin-bottom-15">
        UPS will send an email with your account number titled, “Your New UPS
        Account is Ready to Use.” Please note that UPS will bill you weekly for
        any labels created.
      </p>
      <p className="fs-00 lh-md margin-bottom-10">
        If you need further assistance, reference this handy{' '}
        <a
          href="https://support.ordoro.com/who-are-ordoros-discounted-shipping-carriers/#ordoro-ups"
          rel="noopener noreferrer"
          target="_blank"
        >
          support article{' '}
        </a>
        or contact our <a href="mailto:support@ordoro.com">Support Team</a>.
      </p>
    </ConfirmModal>
  )
}

export default onlyIfForm(UPSRegistrationCompleteModal, modalFormSelector)
