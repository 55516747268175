import {useSelector} from '../../../../store.js'
import {LabelInfoIDsShape} from '../../../../common/PropTypes.js'
import {OT_RETURN_ORDER} from '../../../../common/constants/OrderTypes.js'
import {showEditAddressModal} from '../../../Modals/EditAddressModal/index.js'
import {
  updateLabelConfig,
  bulkShipFromOptionsSelector,
  getShipFromFromValue,
  commonLabelTypeFromLabelConfigsSelector,
  orderTypeSelector,
} from '../../../../data/labelInfos/index.js'
import {
  setShowShipFrom,
  setShipFromValue,
  setBulkReturnToAddress,
  applyShipFromBulk,
} from '../bulkLabelConfigActions.js'
import {
  bulkShowShipFromSelector,
  bulkShipFromValueSelector,
  bulkShipFromLoadingSelector,
  bulkReturnToAddressSelector,
} from '../bulkLabelConfigSelectors.js'

import UpdateOrdersButton from './UpdateOrdersButton.js'
import Select from '../../../../common/components/Select.js'
import {formatMetaClassName} from '../../Fields/useLabelProperty.js'
import className from '../../../../common/className.js'
import {useLabelConfigContext} from '../../LabelConfigContext.js'

export function showRMABulkReturnToAddressModal(bulkReturnToAddress) {
  showEditAddressModal({
    confirmText: 'Update',
    hasAddressShortcuts: true,
    address: bulkReturnToAddress,
    onSave: (address) => setBulkReturnToAddress(address),
  })
}

export default function BulkShipFrom({labelInfoIDs}) {
  const {labelInfoID} = useLabelConfigContext()
  const showShipFrom = useSelector(bulkShowShipFromSelector)
  const shipFromValue = useSelector(bulkShipFromValueSelector)
  const loading = useSelector(bulkShipFromLoadingSelector)
  const shipFromOptions = useSelector((state) =>
    bulkShipFromOptionsSelector(state, {labelInfoIDs}),
  )
  const labelType = useSelector((state) =>
    commonLabelTypeFromLabelConfigsSelector(state, {labelInfoIDs}),
  )
  const orderType = useSelector((state) =>
    orderTypeSelector(state, {labelInfoID: labelInfoIDs[0]}),
  )
  const bulkReturnToAddress = useSelector(bulkReturnToAddressSelector)

  const label = labelType === 'return' ? 'Return To' : 'Ship From'

  return (
    <dd className="list__item meta-labelconfigform-bulk-ship_from">
      <label htmlFor="bulk_ship_from">
        <input
          type="checkbox"
          id="bulk_ship_from"
          onChange={() => setShowShipFrom(!showShipFrom)}
          checked={showShipFrom}
        />
        <span className="margin-left-5">{label}</span>
      </label>
      {showShipFrom && (
        <div className="wrap--nested-form">
          <div className="list--shipping-options">
            {orderType === OT_RETURN_ORDER ? (
              <>
                <div className="fs-n0 lh-sm margin-bottom-1">
                  {(bulkReturnToAddress &&
                    (bulkReturnToAddress.company ||
                      bulkReturnToAddress.name)) || (
                    <em>No address specified</em>
                  )}
                </div>
                <button
                  id="return_to"
                  className="btn btn--link margin-bottom-3 fs-n1"
                  type="button"
                  onClick={() =>
                    showRMABulkReturnToAddressModal(bulkReturnToAddress)
                  }
                >
                  <span>Edit</span>
                </button>
              </>
            ) : (
              <Select
                className={className`list__item--shipping-options w-100 flex-grow ${formatMetaClassName(
                  'ship_from',
                )}`}
                onChange={(value) => {
                  setShipFromValue(value)

                  const {supplier_id} = getShipFromFromValue(value)

                  updateLabelConfig(labelInfoID, {
                    do_not_ship_from_supplier: !supplier_id,
                  })
                }}
                options={shipFromOptions}
                value={shipFromValue}
              />
            )}
            <UpdateOrdersButton
              labelInfoIDs={labelInfoIDs}
              disabled={!shipFromValue && orderType !== OT_RETURN_ORDER}
              onClick={() => {
                const {warehouse_id} = getShipFromFromValue(shipFromValue)

                applyShipFromBulk(labelInfoIDs, warehouse_id)
              }}
              loading={loading}
            />
            {shipFromOptions.length === 0 && (
              <div className="alert alert--warning alert--warning-lt">
                <p className="fs-n0 margin-bottom-15">
                  <strong>
                    There are no common “{label}” locations for the selected
                    orders
                  </strong>
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </dd>
  )
}

BulkShipFrom.propTypes = {
  labelInfoIDs: LabelInfoIDsShape.isRequired,
}
