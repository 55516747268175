import PropTypes from 'prop-types'
import get from 'lodash/get.js'
import round from 'lodash/round.js'
import {createSelector} from 'reselect'
import classNames from 'classnames'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  useSelector,
  onlyIfForm,
} from '../../../store.js'
import apiverson from '../../../common/apiverson.js'
import {isPresent, isNumeric} from '../../../common/utils.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import CurrencyInput from '../../../common/components/CurrencyInput.js'
import TextArea from '../../../common/components/TextArea.js'
import Select from '../../../common/components/Select.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {
  ensureReturnOrder,
  setReturnOrderStateAndEnsureProductsLoaded,
} from '../../../redux/actions/data/returnOrders.js'
import {
  getProduct,
  productsSelector,
  getProductName,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  linesSelector,
  returnOrderLineSelector,
} from '../../../redux/selectors/data/returnOrders.js'
import {nonFBAWarehouseOptionsSelector} from '../../../data/warehouses.js'
import {returnOrderCodesSortedByCodeSelector} from '../../../redux/selectors/data/returnOrderCodes.js'

const EDIT_RMA_LINE_MODAL = 'EDIT_RMA_LINE_MODAL'

export function showEditRMALineModal(
  referenceID,
  lineIDs = [],
  autoFocusOn = null,
) {
  const lines = linesSelector(getState(), {referenceID})

  autoFocusOn =
    autoFocusOn || (lineIDs.length === 0 ? 'sku' : 'expected_quantity')

  const firstRMALine = lines.find(({line_id}) => line_id === lineIDs[0])

  setForm(EDIT_RMA_LINE_MODAL, {
    referenceID,
    lineIDs,
    autoFocusOn,
    isSingleInputEdit: lineIDs.length > 1,
    sku: null,
    expected_quantity: String(get(firstRMALine, 'expected_quantity') || 1),
    total_price: String(get(firstRMALine, 'total_price') || 0),
    code: get(firstRMALine, 'code.code') || null,
    restock_warehouse_id: get(firstRMALine, 'restock_warehouse_id') || null,
    line_notes: get(firstRMALine, 'line_notes') || '',
    product_serial_numbers: (
      get(firstRMALine, 'product_serial_numbers') || []
    ).join('\n'),
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(EDIT_RMA_LINE_MODAL, ...args)
}

export function closeModal() {
  removeForm(EDIT_RMA_LINE_MODAL)
}

export function modalFormSelector(state) {
  return formsSelector(state)[EDIT_RMA_LINE_MODAL]
}

export const errorsSelector = createSelector(
  modalFormSelector,
  ({lineIDs, sku, expected_quantity, total_price}) => {
    const errors = {}

    if (lineIDs.length === 0 && !sku) {
      errors.sku = 'Product is required'
      errors.preventSave = true
    }

    if (!isNumeric(expected_quantity)) {
      errors.expected_quantity = 'Quantity must be a number'
      errors.preventSave = true
    }

    if (!isPresent(expected_quantity)) {
      errors.expected_quantity = 'Quantity is required'
      errors.preventSave = true
    }

    if (!isNumeric(total_price)) {
      errors.total_price = 'Total Price must be a number'
      errors.preventSave = true
    }

    if (!isPresent(total_price)) {
      errors.total_price = 'Total Price is required'
      errors.preventSave = true
    }

    return errors
  },
)

export function updatedParamsSelector(state, {lineID}) {
  const {
    sku,
    expected_quantity,
    total_price,
    code,
    restock_warehouse_id,
    line_notes,
    product_serial_numbers,
    autoFocusOn,
    isSingleInputEdit,
  } = modalFormSelector(state)

  const params = {}

  if (!lineID) {
    params.sku = sku
  }

  if (!isSingleInputEdit || autoFocusOn === 'expected_quantity') {
    params.expected_quantity = Number(expected_quantity)
  }

  if (!isSingleInputEdit || autoFocusOn === 'total_price') {
    params.total_price = round(Number(total_price), 2)
  }

  if (!isSingleInputEdit || autoFocusOn === 'code') {
    params.code = code || null
  }

  if (!isSingleInputEdit || autoFocusOn === 'restock_warehouse_id') {
    params.restock_warehouse_id = restock_warehouse_id || null
  }

  if (!isSingleInputEdit || autoFocusOn === 'line_notes') {
    params.line_notes = line_notes || (lineID ? null : '')
  }

  if (!isSingleInputEdit || autoFocusOn === 'product_serial_numbers') {
    params.product_serial_numbers = product_serial_numbers
      .split(/[ \n\t]+/)
      .filter((v) => v)
  }

  return params
}

export function updateSKU(product) {
  updateModalForm({
    sku: product.sku,
    total_price: product.price.toFixed(2),
  })
}

export function updateExpectedQuantity(quantityString) {
  const form = modalFormSelector(getState())
  const updates = {expected_quantity: quantityString}
  const expected_quantity = Number(quantityString)
  const itemPrice =
    Number(form.total_price) / (Number(form.expected_quantity) || 1)

  if (!isNaN(itemPrice) && !isNaN(expected_quantity)) {
    updates.total_price = (expected_quantity * itemPrice).toFixed(2)
  }

  updateModalForm(updates)
}

export function updateTotalPrice(totalPriceString) {
  const updates = {total_price: totalPriceString}

  updateModalForm(updates)
}

export async function updateSingleRMALine(lineID) {
  const {referenceID, sku} = modalFormSelector(getState())

  const params = updatedParamsSelector(getState(), {lineID})

  const {json: rma} = await (lineID
    ? apiverson.put(
        `/return_order/${encodeURIComponent(referenceID)}/line/${lineID}/`,
        params,
      )
    : apiverson.post(
        `/return_order/${encodeURIComponent(referenceID)}/line`,
        params,
      ))

  const updatedRMALine = rma.lines.find((line) => line.line_id === lineID)

  getProduct(updatedRMALine ? updatedRMALine.sku : sku)

  return rma
}

export async function updateRMALine({addAnother} = {}) {
  try {
    const {referenceID, lineIDs} = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    let rma

    const rmaShapes = await Promise.all(
      lineIDs.length === 0
        ? [updateSingleRMALine()]
        : lineIDs.map((lineID) => updateSingleRMALine(lineID)),
    )

    // We need to get the final rma shape and we don't know which promise that is, so if we have
    // multiple requests then just ask for the rma again
    rma =
      rmaShapes.length === 1
        ? rmaShapes[0]
        : await ensureReturnOrder(referenceID, {reload: true})

    await setReturnOrderStateAndEnsureProductsLoaded(rma)

    closeModal()

    showMessageToast(
      lineIDs.length === 0
        ? 'Added RMA line'
        : `Saved RMA line${lineIDs.length === 1 ? '' : 's'}`,
    )

    if (addAnother) {
      showEditRMALineModal(referenceID)
    }
  } catch (err) {
    updateModalForm({
      serverError: err.message || err.error_message,
      isSaving: false,
    })
  }
}

function EditRMALineModal({form}) {
  const errors = useSelector(errorsSelector)
  const firstRMALine = useSelector((state) =>
    returnOrderLineSelector(state, {
      referenceID: form.referenceID,
      lineID: form.lineIDs[0],
    }),
  )
  const productsBySKU = useSelector(productsSelector)
  const warehouseOptions = useSelector(nonFBAWarehouseOptionsSelector)
  const codeOptions = useSelector((state) => {
    const codes = returnOrderCodesSortedByCodeSelector(state)

    return codes.map(({code, description}) => ({
      value: code,
      display: `${code}${description ? ` (${description})` : ''}`,
    }))
  })

  return (
    <ConfirmModal
      title={
        form.lineIDs.length === 0 ? 'Add RMA Line' : 'Edit RMA Line Values'
      }
      modalSize="sm"
      onConfirm={() => updateRMALine()}
      onCancel={() => closeModal()}
      confirmText="Save"
      cancelText="Cancel"
      MiddleButtons={
        form.lineIDs.length === 0
          ? () => (
              <ButtonPrimary
                alt
                className="margin-right-10"
                isDisabled={errors.preventSave || form.isSaving}
                isLoading={form.isSaving}
                onClick={() => updateRMALine({addAnother: true})}
              >
                Create and Add Another
              </ButtonPrimary>
            )
          : null
      }
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <ul className="list list--no-style">
        {form.lineIDs.length === 0 ? (
          <>
            <li className="list__item--form list__item--no-style list__item--product-filter-stacked divider--bottom">
              {form.sku ? (
                <ButtonLink
                  className="darker align-left"
                  onClick={() => updateModalForm({sku: null})}
                >
                  <div className="lh-md margin-bottom-3">
                    <strong className="fs-01 margin-right-5">
                      {getProductName(productsBySKU[form.sku])}
                    </strong>
                    <span className="unbold">({form.sku})</span>
                  </div>
                  <div className="fs-n0 lh-md text--lt-grey">Edit</div>
                </ButtonLink>
              ) : (
                <ProductFilter
                  label="Select a Product to Add"
                  dropdown="EDIT_RMA_LINE_PRODUCT_FILTER"
                  onSelect={(product) => updateSKU(product)}
                  autoFocus={form.autoFocusOn === 'sku'}
                />
              )}
            </li>
          </>
        ) : (
          <li className="list__item--form list__item--no-style divider--bottom">
            <div className="fs-01">
              {form.lineIDs.length === 1 ? (
                <>
                  <strong>Updating 1 Line:</strong>
                  <div>{firstRMALine.sku}</div>
                </>
              ) : (
                <strong>Updating {form.lineIDs.length} Lines</strong>
              )}
            </div>
          </li>
        )}

        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'expected_quantity') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
              <label className="fs-00" htmlFor="expected_quantity">
                Expected Quantity
              </label>
            </li>
            <li
              className={classNames('list__item list__item--form', {
                'divider--bottom padding-bottom-15': form.lineIDs.length === 1,
              })}
            >
              <NumberInput
                id="expected_quantity"
                value={form.expected_quantity}
                onChange={(value) => updateExpectedQuantity(String(value))}
                isInvalid={!!errors.expected_quantity}
                autoFocus={
                  (!!form.sku && form.autoFocusOn === 'sku') ||
                  form.autoFocusOn === 'expected_quantity'
                }
                min={0}
              />
              {errors.expected_quantity && (
                <small className="error">{errors.expected_quantity}</small>
              )}
            </li>
          </>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'total_price') && (
          <li className="list__item list__item--form">
            <CurrencyInput
              label="Total Price"
              className="biggie margin-bottom-0"
              width="sm"
              id="total_price"
              value={form.total_price}
              onChange={(value) => updateTotalPrice(String(value))}
              errorMessage={errors.total_price}
              autoFocus={form.autoFocusOn === 'total_price'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'code') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
              <label className="fs-00" htmlFor="code">
                Return Code
              </label>
            </li>
            <li className="list__item list__item--form">
              <Select
                className="w-100"
                id="code"
                value={form.code || ''}
                onChange={(value) => updateModalForm({code: value || null})}
              >
                <option value="">Do not apply return code</option>
                {codeOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </li>
          </>
        )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'restock_warehouse_id') && (
          <>
            <li className="list__item--form list__item--no-style fs-00 margin-bottom-3">
              <label className="fs-00" htmlFor="restock_warehouse_id">
                Restock Warehouse
              </label>
            </li>
            <li className="list__item list__item--form">
              <Select
                className="w-100"
                id="restock_warehouse_id"
                value={form.restock_warehouse_id || ''}
                onChange={(value) => {
                  value = value ? Number(value) : null

                  updateModalForm({restock_warehouse_id: value})
                }}
              >
                <option value="">None specified</option>
                {warehouseOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </li>
          </>
        )}
        {(!form.isSingleInputEdit || form.autoFocusOn === 'line_notes') && (
          <li className="list__item list__item--form">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="7"
              label="Notes"
              id="line_notes"
              value={form.line_notes}
              onChange={(value) => updateModalForm({line_notes: value})}
              errorMessage={errors.line_notes}
              autoFocus={form.autoFocusOn === 'line_notes'}
            />
          </li>
        )}
        {(!form.isSingleInputEdit ||
          form.autoFocusOn === 'product_serial_numbers') && (
          <li className="list__item list__item--form">
            <TextArea
              className="textarea textarea--mh-auto"
              rows="4"
              label="Serial Numbers (for multiple, separate with line breaks)"
              id="product_serial_numbers"
              value={form.product_serial_numbers}
              onChange={(value) =>
                updateModalForm({product_serial_numbers: value})
              }
              errorMessage={errors.product_serial_numbers}
              autoFocus={form.autoFocusOn === 'product_serial_numbers'}
            />
          </li>
        )}
      </ul>
    </ConfirmModal>
  )
}

EditRMALineModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    lineIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    autoFocusOn: PropTypes.string,
    sku: PropTypes.string,
    expected_quantity: PropTypes.string.isRequired,
    total_price: PropTypes.string.isRequired,
    code: PropTypes.string,
    restock_warehouse_id: PropTypes.number,
    line_notes: PropTypes.string.isRequired,
    product_serial_numbers: PropTypes.string.isRequired,
    isSingleInputEdit: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }).isRequired,
}

export default onlyIfForm(EditRMALineModal, modalFormSelector)
