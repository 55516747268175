import AddressLabelModal from './AddressLabelModal.js'
import AddOrderCommentModal from './AddOrderCommentModal.js'
import BatchModals from '../../BatchListPage/Modals/index.js'
import ConfirmLabelDeleteModal from './ConfirmLabelDeleteModal.js'
import ConfirmMarkAsShippedModal from './ConfirmMarkAsShippedModal.js'
import ConfirmOrderCancelModal from './ConfirmOrderCancelModal.js'
import ConfirmOrderLineDeleteModal from './ConfirmOrderLineDeleteModal.js'
import ConfirmTrackingWritebackModal from './ConfirmTrackingWritebackModal.js'
import CreateReturnOrderModal from '../../ReturnOrderListPage/Modals/CreateReturnOrderModal/index.js'
import CustomsModal from './CustomsModal/index.js'
import DeleteTrackingModal from './DeleteTrackingModal.js'
import EditAdditionalOptionsModal from '../../LabelConfig/Modals/EditAdditionalOptionsModal.js'
import EditOrderDimensionsModal from './EditOrderDimensionsModal.js'
import EditOrderLineModal from './EditOrderLineModal.js'
import EditOrderWarehouseModal from './EditOrderWarehouseModal.js'
import EditOrderWeightModal from './EditOrderWeightModal.js'
import EditTrackingNumberModal from './EditTrackingNumberModal.js'
import HazmatModal from './HazmatModal.js'
import HoldAtLocationModal from '../../LabelConfig/Modals/HoldAtLocationModal.js'
import ImporterOfRecordModal from './ImporterOfRecordModal.js'
import InsuranceModal from './InsuranceModal.js'
import MarkAsAwaitingFulfillmentModal from './MarkAsAwaitingFulfillmentModal.js'
import MergeOrdersModal from './MergeOrdersModal.js'
import MultiboxModal from './MultiboxModal/index.js'
import OrderRevisionModal from './OrderRevisionModal.js'
import OrderTextFieldModal from './OrderTextFieldModal.js'
import PackingOrderModal from '../../PackShipPage/Modals/PackingOrderModal.js'
import ParentOrderModal from './ParentOrderModal.js'
import PickOrdersModal from '../../PackShipPage/Modals/PickOrdersModal.js'
import PickupModal from './PickupModal/index.js'
import ProcessDropshipModal from './ProcessDropshipModal.js'
import QuickShipModal from './QuickShipModal/index.js'
import RemoveHazmatItemModal from './RemoveHazmatItemModal.js'
import RestoreOrderModal from './RestoreOrderModal.js'
import ScanFormModal from './ScanFormModal/index.js'
import SendInvoiceModal from './SendInvoiceModal.js'
import SplitOrderModal from './SplitOrderModal.js'
import TaxInfoModal from '../../LabelConfig/Modals/TaxInfoModal.js'
import ETDPreshipmentDocsModal from '../../LabelConfig/Modals/ETDPreshipmentDocsModal.js'

export default function Modals() {
  return (
    <>
      <AddressLabelModal />
      <AddOrderCommentModal />
      <BatchModals />
      <ConfirmLabelDeleteModal />
      <ConfirmMarkAsShippedModal />
      <ConfirmOrderCancelModal />
      <ConfirmOrderLineDeleteModal />
      <ConfirmTrackingWritebackModal />
      <CreateReturnOrderModal />
      <CustomsModal />
      <DeleteTrackingModal />
      <EditAdditionalOptionsModal />
      <EditOrderDimensionsModal />
      <EditOrderLineModal />
      <EditOrderWarehouseModal />
      <EditOrderWeightModal />
      <EditTrackingNumberModal />
      <ETDPreshipmentDocsModal />
      <HazmatModal />
      <HoldAtLocationModal />
      <ImporterOfRecordModal />
      <InsuranceModal />
      <MarkAsAwaitingFulfillmentModal />
      <MergeOrdersModal />
      <MultiboxModal />
      <OrderRevisionModal />
      <OrderTextFieldModal />
      <PackingOrderModal />
      <ParentOrderModal />
      <PickOrdersModal />
      <PickupModal />
      <ProcessDropshipModal />
      <QuickShipModal />
      <RemoveHazmatItemModal />
      <RestoreOrderModal />
      <ScanFormModal />
      <SendInvoiceModal />
      <SplitOrderModal />
      <TaxInfoModal />
    </>
  )
}
